import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import {Hexagon, HexGrid, Layout, Text} from "react-hexgrid";

type Props = {
    gameName : string
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ gameName, isOpen, handleClose }: Props) => {
    if(gameName === "ವರ್ಡಲ್ಲ") {
    return (
        <BaseModal title="ಆಡುವುದು ಹೇಗೆ" isOpen={isOpen} handleClose={handleClose}>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                8 ಪ್ರಯತ್ನದಲ್ಲಿ ಸರಿಯಾದ ಪದ ಗುರುತಿಸಬೇಕು.  <a href="https://alar.ink">alar.ink</a>  ನಿಘಂಟಿನಲ್ಲಿ ಇರುವ ಪದಗಳನ್ನು ಮಾತ್ರ ಪರಿಗಣಿಸುತ್ತದೆ. ಕೆಳಗೆ ತಿಳಿಸಿರುವ ಹಾಗೆ, ಪ್ರತಿ ಬಣ್ಣವು ಒಂದು ಸುಳಿವು ನೀಡುತ್ತದೆ
            </p>

            <div className="flex justify-center mb-1 mt-4">
                <Cell value="ಸ್ನಾ"  />
                <Cell value="ತ" />
                <Cell value="ಕೋ" status="correct"/>
                <Cell value="ತ್ತ" />
                <Cell value="ರ" />
            </div>
            <p className="text-sm text-gray-500">
                3ನೇ ಮನೆಯಲ್ಲಿ ಸರಿಯಾದ ವ್ಯಂಜನವಿದೆ, ಒತ್ತು ಮತ್ತು ಕಾಗುಣಿತ ಕೂಡ ಸರಿಯಿದೆ
            </p>

            <div className="flex justify-center mb-1 mt-4">
                <Cell value="ಅ" status="inplace" />
                <Cell value="ನ" />
                <Cell value="ವ"  />
                <Cell value="ರ" />
                <Cell value="ತ" />
            </div>
            <p className="text-sm text-gray-500">
                1ನೇ ಮನೆಯಲ್ಲಿ ಇರುವ ಸ್ವರ/ವ್ಯಂಜನ ಸರಿ, ಅದರ ಒತ್ತು ಅಥವಾ ಕಾಗುಣಿತ ತಪ್ಪು
            </p>

            <div className="flex justify-center mb-1 mt-4">
                <Cell value="ಕ" />
                <Cell value="ವ" />
                <Cell value="ಲು" />
                <Cell value="ದಾ"status="present" />
                <Cell value="ರಿ" />
            </div>
            <p className="text-sm text-gray-500">
                4ನೇ ಮನೆಯಲ್ಲಿ ಇರುವ ವ್ಯಂಜನ ಪದದಲ್ಲಿ ಇದೆ ಆದರೆ ಆ ಮನೆಯಲ್ಲಿ ಅಲ್ಲ
            </p>

            <div className="flex justify-center mb-1 mt-4">
                <Cell value="ದೇ" status="absent" swaraStatus="correct"/>
                <Cell value="ಶ್ಯ" />
                <Cell value="ಕಾಂ" status="present" swaraStatus="correct" />
                <Cell value="ಭೋ"  />
                <Cell value="ಜಿ" status="inplace" swaraStatus="correct" />
            </div>
            <p className="text-sm text-gray-500">
                1ನೇ ಮನೆಯಲ್ಲಿ ಇರುವ ವ್ಯಂಜನ ಪದದಲ್ಲಿ ಇಲ್ಲ, ಆದರೆ ೇ-ಕಾರವು ಆ ಸ್ಥಾನದಲ್ಲಿದೆ.
                3ನೇ ಮನೆಯಲ್ಲಿ ಇರುವ ವ್ಯಂಜನ ಪದದಲ್ಲಿ ಆ ಸ್ಥಾನದಲ್ಲಿ ಇಲ್ಲ. ಹಾಗೆ, ಆಂ ಕಾರವು ಆ ಸ್ಥಾನದಲ್ಲಿದೆ.
                5ನೇ ಮನೆಯಲ್ಲಿ ಇರುವ ವ್ಯಂಜನ ಮತ್ತು ಸ್ವರ ಸರಿ. ಒತ್ತಕ್ಷರ ತಪ್ಪು.
            </p>
        </BaseModal>)
    } else {
        return (
          <BaseModal
            title="ಆಡುವುದು ಹೇಗೆ"
            isOpen={isOpen}
            handleClose={handleClose}
          >
            <p className="text-justify  text-sm text-gray-500 dark:text-gray-300">
              ಈ ದುಂbee-ಗೂಡಾಕಾರದಲ್ಲಿ ಇರುವ ಏಳು ಅಕ್ಷರಗಳನ್ನು ಬಳಸಿ ಪದಗಳನ್ನು ನೀಡಬೇಕು.
              ಮಧ್ಯದ ಅಕ್ಷರ ಪ್ರತಿ ಪದದಲ್ಲೂ ಇರಬೇಕು. ಪ್ರತಿ ಪದಕ್ಕೂ ಮೂರು ಗೂಡನ್ನಾದರೂ
              ಬಳಸಬೇಕು.
            </p>
              <p className="mt-3 text-justify text-sm text-gray-500 dark:text-gray-300">
                  ಎಲ್ಲಾ ಗೂಡನ್ನು ಬಳಸುವ ಒಂದಾದರೂ ಪದವಿರುತ್ತದೆ.
              </p>
              <p className="mt-3 text-justify text-sm text-gray-500 dark:text-gray-300">
                ಸ್ವರಗಳನ್ನು ಗುಣಿತಾಕ್ಷರ ಮಾಡುವುದಕ್ಕೆ ಬಳಸಬಹುದು. ಒಂದು ಅಕ್ಷರವನ್ನು ಹಲವು ಸಾರಿ ಬಳಸಬಹುದು.
              </p>
            <div className="relative flex items-center justify-center">
              <HexGrid width={200} height={200} viewBox="-35 -35 70 70">
                <Layout
                  size={{ x: 10, y: 10 }}
                  flat={true}
                  spacing={1.1}
                  origin={{ x: 0, y: 0 }}
                >
                  <Hexagon
                    className="fill-blue-200 hover:fill-blue-300 active:fill-blue-400 dark:fill-blue-700 dark:hover:fill-blue-800 dark:active:fill-blue-900"
                    q={0}
                    r={0}
                    s={0}
                  >
                    <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
                      ರ
                    </Text>
                  </Hexagon>
                  <Hexagon
                    className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
                    q={0}
                    r={-1}
                    s={1}
                  >
                    <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
                      ಮ
                    </Text>
                  </Hexagon>
                  <Hexagon
                    className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
                    q={0}
                    r={1}
                    s={-1}
                  >
                    <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
                      ಊ
                    </Text>
                  </Hexagon>
                  <Hexagon
                    className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
                    q={-1}
                    r={1}
                    s={0}
                  >
                    <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
                      ಪ
                    </Text>
                  </Hexagon>
                  <Hexagon
                    className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
                    q={-1}
                    r={0}
                    s={1}
                  >
                    <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
                      ನ
                    </Text>
                  </Hexagon>
                  <Hexagon
                    className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
                    q={1}
                    r={0}
                    s={0}
                  >
                    <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
                      ಆ
                    </Text>
                  </Hexagon>
                  <Hexagon
                    className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
                    q={1}
                    r={-1}
                    s={0}
                  >
                    <Text className="select-none fill-black items-center justify-center rounded dark:fill-white text-[9px]">
                      ದ
                    </Text>
                  </Hexagon>
                </Layout>
              </HexGrid>
            </div>

            <p className="text-sm text-gray-500 dark:text-gray-300">
              ಉದಾಹರಣೆಗೆ ಮೇಲಿನ ಆಟದಲ್ಲಿ,
            </p>
            <p className="mt-1 text-left text-sm text-gray-500 dark:text-gray-300">
              <span className="text-red-700">ದರ</span> : ಎರಡು ಗೂಡನ್ನು ಮಾತ್ರ
              ಬಳಸುತ್ತದೆ, ತಪ್ಪು
            </p>
            <p className="text-left text-sm text-gray-500 dark:text-gray-300">
              <span className="text-red-700">ಪಾದ</span> : ಮೂರು ಗೂಡ ಬಳಸಿದರೂ, "ರ"
              ಅಕ್ಷರವಿಲ್ಲ, ತಪ್ಪು
            </p>
            <p className="mt-2 text-left text-sm text-gray-500 dark:text-gray-300">
              <span className="text-green-700">ದೂರ</span> : ಸರಿಯಾಗಿದೆ
            </p>
            <p className="text-left text-sm text-gray-500 dark:text-gray-300">
              <span className="text-green-700">ಮಾಮರ</span> : ಸರಿಯಾಗಿದೆ
            </p>
              <p className="mt-2 text-left text-sm text-gray-500 dark:text-gray-300">
                  <span className="text-blue-500">ದೂರಮಾಪನ</span> : ಎಲ್ಲಾ ಅಕ್ಷರವನ್ನು ಒಂದು ಸಲವಾದರೂ ಬಳಸುವ ಪದ
              </p>
          </BaseModal>
        )
    }
}

import { HexGrid, Layout, Hexagon, Text } from 'react-hexgrid'
import { useEffect } from 'react'
import { enToKnMap, enToKnVowelMap } from '../../lib/statuses'
type Props = {
  centerLetter: string
  letters: string[]
  onHexChar: (value: string) => void
  onHexCharDelete: () => void
  handleEnterWord: () => void
}

export const HexGridBox = ({
  centerLetter,
  letters,
  onHexChar,
  onHexCharDelete,
  handleEnterWord,
}: Props) => {
  // @ts-ignore
  const handleClick = (event, h) => {
    onHexChar(h)
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Backspace') {
        onHexCharDelete()
      } else if (e.code === 'Enter') {
        handleEnterWord()
      } else {
        if (enToKnVowelMap.has(e.key)) {
          onHexChar(enToKnVowelMap.get(e.key))
        } else if (enToKnMap.has(e.key)) {
          onHexChar(enToKnMap.get(e.key))
        }
      }
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [onHexChar, onHexCharDelete, handleEnterWord])

  return (
    <div className="relative flex items-center justify-center">
      <HexGrid width={250} height={250} viewBox="-35 -35 70 70">
        <Layout
          size={{ x: 10, y: 10 }}
          flat={true}
          spacing={1.1}
          origin={{ x: 0, y: 0 }}
        >
          <Hexagon
            className="fill-blue-200 hover:fill-blue-300 active:fill-blue-400 dark:fill-blue-700 dark:hover:fill-blue-800 dark:active:fill-blue-900"
            q={0}
            r={0}
            s={0}
            onClick={(e, h) => handleClick(e, centerLetter)}
          >
            <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
              {centerLetter}
            </Text>
          </Hexagon>
          <Hexagon
            className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
            q={0}
            r={-1}
            s={1}
            onClick={(e, h) => handleClick(e, letters[0])}
          >
            <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
              {letters[0]}
            </Text>
          </Hexagon>
          <Hexagon
            className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
            q={0}
            r={1}
            s={-1}
            onClick={(e, h) => handleClick(e, letters[1])}
          >
            <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
              {letters[1]}
            </Text>
          </Hexagon>
          <Hexagon
            className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
            q={-1}
            r={1}
            s={0}
            onClick={(e, h) => handleClick(e, letters[2])}
          >
            <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
              {letters[2]}
            </Text>
          </Hexagon>
          <Hexagon
            className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
            q={-1}
            r={0}
            s={1}
            onClick={(e, h) => handleClick(e, letters[3])}
          >
            <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
              {letters[3]}
            </Text>
          </Hexagon>
          <Hexagon
            className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
            q={1}
            r={0}
            s={0}
            onClick={(e, h) => handleClick(e, letters[4])}
          >
            <Text className="select-none fill-black w-14 h-14 flex items-center justify-center text-[9px] rounded dark:fill-white">
              {letters[4]}
            </Text>
          </Hexagon>
          <Hexagon
            className="fill-gray-300 hover:fill-gray-400 active:fill-gray-500 dark:fill-gray-500 dark:hover:fill-gray-600 dark:active:fill-gray-700"
            q={1}
            r={-1}
            s={0}
            onClick={(e, h) => handleClick(e, letters[5])}
          >
            <Text className="select-none fill-black items-center justify-center rounded dark:fill-white text-[9px]">
              {letters[5]}
            </Text>
          </Hexagon>
        </Layout>
      </HexGrid>
    </div>
  )
}


export const WORDS4 = [
'ಬೇಟೆಗಾರ',//24-1 [Day 0]
'ಪರಿಮಳ',//25-1
'ಸಾಮಾಜಿಕ',//26-1
'ಬಹುಮಾನ',//27-1
'ಕಲರವ',//28-1
'ಪರಿಸರ',//29-1
'ಸಾಯಂಕಾಲ',//30-1
'ಆವರಣ',//31-1
'ತರಗತಿ',//1-2
'ಅವಕಾಶ',//2-2
'ವರಮಾನ',//3-2
'ಸಹಕಾರ',//4-2
'ಅನುಕಂಪ',//5-2
'ಬಡಾವಣೆ',//6-2
'ದೇವಾಲಯ',//7-2
'ಉಪದೇಶ',//8-2
'ರಾಜಕೀಯ',//9-2
'ಅವತಾರ',//10-2
'ತರಕಾರಿ',//11-2
'ಕಲಾವಿದ',//12-2
'ಗಡಿಯಾರ',//13-2
'ಸಮಾಚಾರ', //14-2
'ಕಚಗುಳಿ',//15-2
'ಸಮುದಾಯ',
'ಅನುವಾದ',
'ಯುವರಾಣಿ',
'ಇತಿಹಾಸ',
'ತರಬೇತಿ',
'ಉಳಿತಾಯ',//20-2
'ಬಿಡುಗಡೆ',
'ಉಪಯುಕ್ತ',
'ವಿವರಣೆ',
'ಅಧಿಕಾರ',
'ತಿಂಡಿಪೋತ',//25-2
'ಕೋಮುವಾದ',
'ರಾಜಧಾನಿ',
'ಜೇನುಗೂಡು',//28-2
'ಪ್ರಯೋಜನ',//1-3
'ದೂರವಾಣಿ',
'ಕಿರುತೆರೆ',
"ಭಾವಚಿತ್ರ",
"ಜನಪದ",
"ಯುವರಾಜ",
"ವಹಿವಾಟು",
"ಪುಣ್ಯವಂತ",
"ಪುರಾತನ",
"ಉಗಿಬಂಡಿ",
"ಪ್ರದರ್ಶನ",
"ಫಲಿತಾಂಶ",
"ಜಾಣತನ",
"ಉಪಚಾರ",
"ಆರೋಹಣ",
"ಉಪಾಹಾರ",
"ಕಿರುಚಿತ್ರ",
"ಉಪನದಿ",
"ಗೆಳೆತನ",
"ಆಶೀರ್ವಾದ",
"ಕಾಮಗಾರಿ",
"ಅಲಂಕಾರ",
"ಅನುಮತಿ",
"ಸಂಚಲನ",
"ಅಂಚೆಚೀಟಿ",
"ಆಶಾವಾದ",
"ಉಪನ್ಯಾಸ",
"ಉಪಕಾರಿ",
"ಅವಮಾನ",
"ಗಿಳಿಪಾಠ",
"ಶ್ರೀಮಂತಿಕೆ",
"ಗುಣವಂತ",
"ಚಕ್ರವರ್ತಿ",
"ಉತ್ತೇಜನ",
"ಬಲಿದಾನ",
"ಮುಜುಗರ",
"ಅವಸರ",
"ಸಸ್ಯಾಹಾರ",
"ಶಕ್ತಿಶಾಲಿ",
"ಸಂಗ್ರಹಣ",
"ಆಲೋಚನೆ",
"ರಣರಂಗ",
"ವೈದ್ಯಾಲಯ",
"ಆವಿಷ್ಕಾರ",
"ಉಪವಾಸ",
"ರಸಿಕತೆ",
"ವ್ಯವಹಾರ",
"ಜೇನುತುಪ್ಪ",
"ರಂಗವಲ್ಲಿ",
"ರಥೋತ್ಸವ",
"ಸಂದರ್ಶನ",
"ಕಿತಾಪತಿ",
"ಗಣತಂತ್ರ",
"ಭಾಗಾಕಾರ",
"ಭ್ರಷ್ಟಾಚಾರ",
"ಮನೋಹರ",
"ಪ್ರಾಧಿಕಾರ",
"ಅತಿಶಯ",
"ಪಡುವಣ",
"ದಬಾಯಿಸು",
"ಜಾಗತಿಕ", // 1-5
"ಕರಣಿಕ",
"ಬಳಲಿಕೆ",
"ನವಗ್ರಹ",
"ಬಳೆಗಾರ",
"ದುರುಗುಟ್ಟು",
"ಕಾತರತೆ",
"ಎದುರೇಟು",
"ರಂಗಭೂಮಿ",
"ಬಿಕ್ಕಳಿಕೆ",
"ಜಾನುವಾರು",
"ಅನಾಮಿಕ",
"ಬೂಟಾಟಿಕೆ",
"ಹಗರಣ",
"ತಡಕಾಟ",
"ಪದಕೋಶ",
"ನೀರಡಿಕೆ",
"ಚಪ್ಪರಿಸು",
"ಕಾಗುಣಿತ",
"ನರಳಾಟ",
"ಅನಾಚಾರ",
"ಜಾದೂಗಾರ",
"ಬೆಂಕಿಕಡ್ಡಿ",
"ಬೆಂಬಲಿಗ",
"ಕುಪ್ಪಳಿಸು",
"ಅಧ್ಯಾಪಕ",
"ಬಿಚ್ಚುಗತ್ತಿ",
"ಜಾಗರಣೆ",
"ಅನುಕೂಲ",
"ಪುರಸೊತ್ತು",
"ಅಭಿನಯ",
"ಲೋಕಸಭೆ",
"ಸದಾಚಾರ",
"ಕೆಟ್ಟತನ",
"ಹುಡುಗಾಟ",
"ಉಳಿಗಾಲ",
"ಕೆಲವರು",
"ವಕಾಲತ್ತು",
"ಅನಿಸಿಕೆ",
"ಹೆಣಗಾಟ",
"ಗುಣಾಕಾರ",
"ಕರಾವಳಿ",
"ವಿಕಸನ",
"ವರ್ಣಮಾಲೆ",
"ಆಡಳಿತ",
"ಉಪಾಸನೆ",
"ಕುಶಲತೆ",
"ಸಂಘಟನೆ",
"ವಿಚಲಿತ",
"ಅನಾಹುತ",
"ಉದಾರತೆ",
"ರಿಯಾಯತಿ",
"ಕಳೇಬರ",
"ಕೋಲಾಹಲ",
"ಅನುಬಂಧ",
"ಕಾದಂಬರಿ",
"ಜನಪ್ರಿಯ",
"ಅಪಕಾರ",
"ಹವಣಿಸು",
"ಕೀಲಿಮಣೆ",
"ಸಂಪಾದನೆ",
"ಸಹಾಯಕ",
"ಚಮತ್ಕಾರ",
"ಅಹಂಕಾರ",
"ಅನುರಾಗ",
"ತಯಾರಿಕೆ",
"ಆಪಾದನೆ",
"ಅನಿವಾರ್ಯ",
"ಕೀಳರಿಮೆ",
"ಗಡಿಪಾರು",
"ಸಮಾನತೆ",
"ಲಂಚಕೋರ",
"ವನವಾಸ",
"ಚಿದಂಬರ",
"ಕಿರುಕುಳ",
"ಕಿರುಚಾಟ",
"ರಾಮಾಯಣ",
"ಅರಮನೆ",
"ಸರೋವರ",
"ಜಾನಪದ",
"ಉಡುಗರೆ",
"ಗಣರಾಜ್ಯ",
"ಕೋಮಲತೆ",
"ಅಡಿಪಾಯ",
"ಅಧಿಕೃತ",
"ಗಣನೀಯ",
"ಅನಿಕೇತ",
"ಆದರಣೆ",
"ಗುರುಕುಲ",
"ಅಪಚಾರ",
"ಚುನಾವಣೆ",
"ಅರಚಾಟ",
"ಆತುರತೆ",
"ಶೇಖರಣೆ",
"ಅಸಹಜ",
"ಕೈಬರಹ",
"ಕಾರಾಗೃಹ",
"ರೋಮಾಂಚನ",
"ಸಂಕ್ರಮಣ",
"ಕ್ರಿಯಾಶೀಲ",
"ಆಚರಣೆ",
"ಸಹೋದರಿ",
"ಹಾನಿಕರ",
"ರಾಜಯೋಗ",
"ಜಲಪಾತ",
"ಗರಗಸ",
"ಕ್ರೀಡಾಂಗಣ",
"ಗತಿಗೆಡು",
"ಹೊಂದಾಣಿಕೆ",
"ಚತುರತೆ",
"ಅವಿರತ",
"ವಿಚಾರಣೆ",
"ಹಿಂಬಾಲಕ",
"ಸಂಜೀವಿನಿ",
"ಗಾರುಡಿಗ",
"ಶೋಚನೀಯ",
"ಅನಾಸಕ್ತಿ",
"ಕುತೂಹಲ",
"ಕರಗತ",
"ಉಚ್ಚಾರಣೆ",
"ವಿನಾಯತಿ",
"ಸಂಯೋಜನೆ",
"ಸಹಚರ",
"ಸಡಗರ",
"ರೇಖಾಚಿತ್ರ", //1-9
"ಗುಪ್ತಚರ",
"ಕೊಲೆಗಾರ",
"ಖಡತರ",
"ಕಾರ್ಯಾಗಾರ",
"ಕಿರಾತಕ",
"ತಪಾಸಣೆ",
"ಕೈಗನ್ನಡಿ",
"ಗಮನಿಸು",
"ಕರುನಾಡು",
"ಚಿಗುರೆಲೆ",
"ಆಟಗಾರ",
"ಕನಿಕರ",
"ಅನುದಿನ",
"ಚಿತ್ರಪಟ",
"ಕಾಡುಗಿಚ್ಚು",
"ಜೀವದಾನ",
"ಒಡನಾಡಿ",
"ಕರತಲ",
"ಪರಮಾತ್ಮ",
"ಎದುರಾಳಿ",
"ಒಕ್ಕೊರಲು",
"ದಶಮಾನ",
"ತಾಕಲಾಟ",
"ಕತೆಗಾರ",
"ಆಕಳಿಕೆ",
"ನಿವಾರಣೆ",
"ಒಳಗಡೆ",
"ಅನುಪಮ",
"ಕಡೆಗಾಲ",
"ಪರಿಣಾಮ", // 1-10
"ಆಕರ್ಷಕ",
"ಕೆಟ್ಟದಾರಿ",
"ಕುಂಡಲಿನಿ",
"ಕೈಗಾರಿಕೆ",
"ಗಾಳಿಪಟ",
"ಚಾರಿತ್ರಿಕ",
"ತಡೆಗೋಡೆ",
"ಕಿವಿಗೊಡು",
"ನಿದರ್ಶನ",
"ಆತ್ಮಶಕ್ತಿ",
"ನತದೃಷ್ಟ",
"ಅಂಗೀಕಾರ",
"ಆರೋಪಣೆ",
"ಬಂಡವಾಳ",
"ಕಡಿವಾಣ",
"ದೇವಸ್ಥಾನ",
"ಜನ್ಮದಿನ",
"ಕೆಣಕಾಟ",
"ಜಲಾಶಯ",
"ಜಾಹೀರಾತು",
"ಪಾದರಕ್ಷೆ",
"ಕಟ್ಟೆಚ್ಚರ",
"ಅಭಿಮಾನ",
"ಗಟ್ಟಿಮುಟ್ಟು",
"ತತ್ತರಿಸು",
"ಅಗಲಿಕೆ",
"ಕ್ಷಮಾದಾನ",
"ಇಳುವರಿ",
"ಕನ್ನಡಕ",
"ಕಾರ್ಯಕ್ರಮ",
"ಚಲಾವಣೆ", // 1-11
"ಇಳಿಜಾರು",
"ಅಂಗಲಾಚು",
"ಕಾಮಧೇನು",
"ಒಳನೋಟ",
"ನೆರೆಮನೆ",
"ಅವಾಂತರ",
"ಕಿರುನಗೆ",
"ಕೊರಕಲು",
"ಪ್ರಕಟಣೆ",
"ಉಪಟಳ",
"ಚಪಲತೆ",
"ಅಸಮಾನ",
"ಉತ್ಪಾದನೆ",
"ಜವಾಬ್ದಾರಿ",
"ಕಾರ್ಯಾಲಯ",
"ಕ್ಷಮಾಪಣೆ",
"ಒಗ್ಗೂಡಿಸು",
"ಅಲೆದಾಟ",
"ಕಿಡಿಗೇಡಿ",
"ಕಳ್ಳತನ",
"ತಕರಾರು",
"ಗುಣನಾಮ",
"ಕುಲವಧು",
"ತಂಗುದಾಣ",
"ಕಾಲಮಾನ",
"ಅಗಣಿತ",
"ತರಗೆಲೆ",
"ಜಂಘಾಬಲ",
"ಜೊತೆಗಾರ",
"ಗ್ರಹಚಾರ", //1-12
"ತಿರುಗಾಟ",
"ಜನ್ಮಾಂತರ",
"ತಾಪಮಾನ",
"ಪರಿಹಾರ",
"ಪ್ರಕರಣ",
"ಕೆಂದಾವರೆ",
"ಅಡಚಣೆ",
"ಜನಬಲ",
"ಸಮನ್ವಯ",
"ಮತದಾನ",
"ವರ್ತಮಾನ",
"ವಿಪರೀತ",
"ಧನ್ಯವಾದ",
"ಮನಸಾರೆ",
"ಬಡಿದಾಟ",
"ಅಪರಾಧ",
"ಅತಿರಥ",
"ಮಮಕಾರ",
"ಪದಬಂಧ",
"ಬಲಿಪಶು",
"ದಿನಚರಿ",
"ಮಾಟಗಾರ",
"ಅನಾರೋಗ್ಯ",
"ಚೂಡಾಮಣಿ",
"ಧನಬಲ",
"ರಾಜಹಂಸ",
"ಬಯಲಾಟ",
"ಅವನತಿ",
"ಹಟಮಾರಿ",
"ಮಾಯಾಜಾಲ",
"ವಿನಿಮಯ", // 1-1-23
"ಪರದಾಟ",
"ಭಿನ್ನಮತ",
"ಮೂಲಭೂತ",
"ನಿಯೋಜನೆ",
"ಪಡಸಾಲೆ",
"ಪುರಸ್ಕಾರ",
"ಬೆಂಗಾವಲು",
"ಧಾರಾವಾಹಿ",
"ನಿಷೇಧಿತ",
"ದೇಶಭಕ್ತ",
"ಜೀವರಾಶಿ",
"ಬಹಿಷ್ಕಾರ",
"ಮೃಗಾಲಯ",
"ಪಿಸುಮಾತು",
"ಪರವಶ",
"ಕಾಲಾವಧಿ",
"ಅನಗತ್ಯ",
"ನಮಸ್ಕಾರ",
"ಆಧುನಿಕ",
"ಪಾದರಸ",
"ಹೆದರಿಕೆ",
"ಅಮಾಯಕ",
"ತರುವಾಯ",
"ಸೂತ್ರಧಾರ",
"ವಿನಿಯೋಗ",
"ಉಪಯೋಗ",
"ಸಾಮರಸ"
]
// @ts-ignore
export const WORDS = [
'ಮನರಂಜನೆ',
'ರಾಜಕುಮಾರ',
'ಉದಾಹರಣೆ',
'ಬದಲಾವಣೆ',//24-1
'ಚಿತ್ರಮಂದಿರ',//25-1
'ಬೆಳವಣಿಗೆ',//26-1
'ಚಟುವಟಿಕೆ',//27-1
'ಮದುವೆಮನೆ',//28-1
'ವಾತಾವರಣ',//29-1
'ಅಪಾಯಕಾರಿ',//30-1
'ಜನಗಣತಿ',//31-1
'ಕನಸುಗಾರ',//1-2
'ವಾಯುಮಾಲಿನ್ಯ',//2-2
'ಸಚಿವಾಲಯ',//4-2
'ಜಲಜನಕ',//5-2
'ಪ್ರತಿಭಟನೆ',//6-2
'ಹಳಗನ್ನಡ',//7-2
'ಸಹಾನುಭೂತಿ',//8-2
'ಚಿಕಿತ್ಸಾಲಯ',//9-2
'ವಿಧಾನಸಭೆ',//10-2
'ಅಸಮಾಧಾನ',//11-2
'ಪ್ರಧಾನಮಂತ್ರಿ',//12-2
'ಸಾರ್ವಜನಿಕ',//13-2
'ಮನವರಿಕೆ', //14-2
'ನಾಗರಿಕತೆ',//15-2
'ಅನಾವರಣ',//16-2
'ರಾಜಕಾರಣ',
'ಚಲನಚಿತ್ರ',
'ಉಪಕರಣ',
'ಮೆರವಣಿಗೆ',//20-2
'ಆಧುನಿಕತೆ',
'ಗಣಿಗಾರಿಕೆ',
'ವರದಕ್ಷಿಣೆ',
'ಆಮ್ಲಜನಕ',
'ಸಮಾಜವಾದಿ',//25-2
'ಗಣಕಯಂತ್ರ',
'ಅನುಕೂಲತೆ',
'ಚಂಡಮಾರುತ',//28-2
'ಅಂಚೆಪೆಟ್ಟಿಗೆ',//1-3
'ಪತ್ರಿಕೋದ್ಯಮ',
'ಔಷಧಾಲಯ',
'ವಿಚಾರವಾದಿ',
"ಕನವರಿಕೆ",//5-3
"ಅಗ್ನಿಪರ್ವತ",
"ಆಕಾಶವಾಣಿ",
"ಶಿಲಾಬಾಲಿಕೆ",
"ವಾಯುಮಂಡಲ",
"ವಶೀಕರಣ",
"ವಾರಪತ್ರಿಕೆ",
"ಸಾರಜನಕ",
"ಸರಳತನ",
"ಕಲಬೆರಕೆ",
"ಸುಂಟರಗಾಳಿ",//15-3
"ಆರೋಗ್ಯಕರ",
"ಮಾಸಪತ್ರಿಕೆ",
"ಹೊಸಗನ್ನಡ",
"ಹಣೆಬರಹ",
"ಸದವಕಾಶ",
"ಅಜಾತಶತ್ರು",
"ಮರಳುಗಾಡು",
"ಮುದ್ರಣಾಲಯ",
"ವಾಚನಾಲಯ",
"ಉಕ್ತಲೇಖನ",
"ವಿಚಾರವಂತ",
"ಕಸಪೊರಕೆ",
"ಮುಂದುವರಿಕೆ",
"ಗೃಹರಕ್ಷಕ",
"ವೃತ್ತಪತ್ರಿಕೆ",
"ಶಿಲಾಶಾಸನ",//31-3
"ಸುಗಂಧರಾಜ",//1-4
"ದಂಡನಾಯಕ",
"ಮೌಲ್ಯಮಾಪನ",
"ಕೂಪಮಂಡೂಕ",
"ವಾಯುಸಂಚಾರ",
"ಮಹಾನುಭಾವ",
"ಜನಪ್ರಿಯತೆ",
"ರಣಕಹಳೆ",
"ಅಗ್ನಿಶಾಮಕ",
"ಯಜಮಾನಿಕೆ",
"ನಾಟಕರಂಗ",
"ಆತ್ಮವಿಶ್ವಾಸ",
"ಅಪರಿಮಿತ",
"ಗುಡಿಗೋಪುರ",
"ಗಗನಸಖಿ",//15-4
"ಸ್ವಯಂಸೇವಕ",
"ಮಂಗಳಕರ",
"ರತ್ನಗಂಬಳಿ",
"ಸಹಕರಿಸು",
"ವಾರಸುದಾರ",
"ವರದಿಗಾರ",
"ಅನುವಾದಕ",
"ಒಂಟಿಸಲಗ",
"ಕತ್ತಿವರಸೆ",
"ಅಕ್ಷಯಪಾತ್ರೆ", //25-4
"ಜಟಕಾಬಂಡಿ",
"ಸಮಾಜಶಾಸ್ತ್ರ",
"ಭೂತಗನ್ನಡಿ",
"ಶುದ್ಧೀಕರಣ",
"ಉಪಸಂಹಾರ", // 1-5
"ಅಚ್ಚಗನ್ನಡ",
"ಅನವರತ",
"ಬಹುವಚನ",
"ಉಪನ್ಯಾಸಕಿ",
"ಪ್ರಾಮಾಣಿಕತೆ",
"ಜನಗಣನೆ",
"ಗಾಳಿಗೋಪುರ",
"ಗುರುದಕ್ಷಿಣೆ",
"ನಿರಾಕರಣೆ",
"ನಾಮಕರಣ",
"ಕಾಕತಾಳೀಯ",
"ಸಮುದ್ರಯಾನ",
"ನಾಗಮಂಡಲ",
"ಬಚ್ಚಲುಮನೆ",
"ಉದ್ಯಾನವನ",
"ಮೂಢನಂಬಿಕೆ",
"ಛಾಯಾಗ್ರಾಹಕ",
"ಧರ್ಮಸಂಕಟ",
"ನರಪೇತಲ",
"ಪ್ರತಿಪಾದಕ",
"ಪರಿಕಲ್ಪನೆ",
"ಸಂಚುಗಾರಿಕೆ",
"ಸೃಜನಶೀಲ",
"ನಗರಸಭೆ",
"ಅಂಕಿತನಾಮ",
"ಸಮಕಾಲೀನ",
"ಧ್ವನಿಸುರುಳಿ",
"ಔಪಚಾರಿಕ",
"ಕೇಶವಿನ್ಯಾಸ",
"ಪ್ರಾಣಸಂಕಟ",
"ಪುಳಿಯೋಗರೆ",
"ಪಠ್ಯಪುಸ್ತಕ", // 31-5
"ನ್ಯಾಯಾಧಿಪತಿ",
"ಅನುಕರಣೆ",
"ಸಂಪಾದಕೀಯ",
"ಅವಗಣನೆ",
"ಪರಿಚಾರಕ",
"ಅವರೋಹಣ",
"ಸ್ನಾತಕೋತ್ತರ",
"ಎದೆಗಾರಿಕೆ",
"ವಿವೇಕಶಾಲಿ",
"ಆಶಾಕಿರಣ",
"ಸಿರಿವಂತಿಕೆ",
"ಅನಕ್ಷರತೆ",
"ಪೂರ್ಣವಿರಾಮ",
"ಗಜಕೇಸರ",
"ಸಭಾಮಂಟಪ",
"ಸಹನಶೀಲ",
"ಅನಿರೀಕ್ಷಿತ",
"ವೈಯಾಕರಣ",
"ಕನಿಕರಿಸು",
"ವೈಚಾರಿಕತೆ",
"ಒಣಜಗಳ",
"ವಿಶ್ವಮಾನವ",
"ಶರಣಾಗತಿ",
"ಅಡಿಗೆಮನೆ",
"ಆಜಾನುಬಾಹು",
"ಹೇಸರಗತ್ತೆ",
"ಅನುಭವಿಸು",
"ಉದಾಸೀನತೆ",
"ಸಂಗ್ರಹಾಲಯ",
"ಕರುಬುತನ",
"ಕ್ರಿಮಿನಾಶಕ", // 1-7
"ನಾಗಾಭರಣ",
"ಕರತಾಡನ",
"ಪದ್ಮಭೂಷಣ",
"ಅಮಾನವೀಯ",
"ಸಂಶಯಾಸ್ಪದ",
"ಅವಲೋಕನ",
"ಪಟ್ಟಾಭಿಷೇಕ",
"ಅನುನಾಸಿಕ",
"ಸಮೀಕರಣ",
"ಸಂಜೆಗತ್ತಲು",
"ನಾಚಿಕೆಗೇಡು",
"ತಿರಸ್ಕರಿಸು",
"ಪರವಾನಗಿ",
"ಅಪರಿಚಿತ",
"ಉಪದೇಶಿಸು",
"ಪ್ರಭಾವಶಾಲಿ",
"ವಿಷಾದನೀಯ",
"ಸುಮನೋಹರ",
"ಉದಯರಾಗ",
"ಕಾವ್ಯಾಲಂಕಾರ",
"ಧ್ವನಿಮುದ್ರಣ",
"ಶಶಿಕಿರಣ",
"ದಿನಾಚರಣೆ",
"ಸಮಾಲೋಚನೆ",
"ಅನುಗ್ರಹಿಸು",
"ಅನುಮತಿಸು",
"ಸಾರ್ವಕಾಲಿಕ",
"ಸಮತೋಲನ",
"ನಿರುಪಯುಕ್ತ",
"ಸಾಂಪ್ರದಾಯಿಕ", // 1-8
"ಏಕೀಕರಣ",
"ಪರಾಗಸ್ಪರ್ಶ",
"ಪಾರದರ್ಶಕ",
"ಪರಿಗಣನೆ",
"ಒಳ್ಳೆಯತನ",
"ತಲೆಬುರುಡೆ",
"ಸೇನಾಧಿಪತಿ",
"ಅಪಶಕುನ",
"ಸ್ವಾವಲಂಬನೆ",
"ಸವಿನೆನಪು",
"ನಿರಹಂಕಾರ",
"ಪರಿಶೋಧನೆ",
"ಸಹೃದಯತೆ",
"ಪದವೀಧರ",
"ದುರಭಿಮಾನ",
"ಶಾಸನಸಭೆ",
"ಹಿತವಚನ",
"ಶರಪಂಜರ",
"ಸಮಜಾಯಿಷಿ",
"ಸಿಡುಕುತನ",
"ದೂರದರ್ಶನ",
"ಸುಡುಬಿಸಿಲು",
"ಸರಳರೇಖೆ",
"ಸಮಾರಾಧನೆ",
"ಪರಾಂಬರಿಸು",
"ಹೊಣೆಗಾರಿಕೆ",
"ತಾರಾಮಂಡಲ",
"ಪರಿಪಾಲನೆ",
"ಅಭಿವಂದನೆ",
"ಸೃಜನಾತ್ಮಕ",
"ಕುಟಿಲತನ", //1-9
"ಕರಾರುವಾಕ್ಕು",
"ಅಕ್ಕಸಾಲಿಗ",
"ಅಂಗನವಾಡಿ",
"ಕಲ್ಲುಸಕ್ಕರೆ",
"ಬರಹಗಾರ",
"ಕಿಲಾಡಿತನ",
"ಕೊಲೆಗಡಿಕ",
"ದಕ್ಷಿಣಾಯನ",
"ರಚನಕಾರ",
"ತಿಳಿಗನ್ನಡ",
"ಚಿಂತನಶೀಲ",
"ಕುಲದೇವತೆ",
"ಪರಿಶೀಲನೆ",
"ನೈತಿಕಬಲ",
"ಕೆಲಸಗಾರ",
"ಕರುಣಾರಸ",
"ಕವಿಸಮಯ",
"ನಿರತಿಶಯ",
"ಆಚಾರವಂತ",
"ಕಾರ್ಯಾಚರಣೆ",
"ಮುಗುಳುನಗೆ",
"ದುರಹಂಕಾರ",
"ದೃಢೀಕರಣ",
"ಅಭಿನಂದನೆ",
"ಜಿಲ್ಲಾಧಿಕಾರಿ",
"ಜನಜಾಗೃತಿ",
"ನೆರವೇರಿಸು",
"ಅಳವಡಿಕೆ",
"ಚಂಪಕಮಾಲೆ", //1-10
"ಉಪಚಾರಿಸು",
"ಅನವಸರ",
"ಸರ್ವಾಧಿಕಾರಿ",
"ದುರುಪಯೋಗ",
"ಪಟ್ಟಣವಾಸಿ",
"ಆದಿಪುರಾಣ",
"ವರ್ಗೀಕರಣ",
"ಪ್ರಗತಿಪರ",
"ಆಕಾಶದೀಪ",
"ಗೌರವಾನ್ವಿತ",
"ನಯವಂತಿಕೆ",
"ವೀಣಾವಾದನ",
"ಕನ್ನಡತನ",
"ಗ್ರಾಮದೇವತೆ",
"ನಿರಾಭರಣ",
"ಗೊಮ್ಮಟೇಶ್ವರ",
"ಜಿಪುಣತನ",
"ಕೆಂಡಸಂಪಗೆ",
"ಅನುಮೋದನೆ",
"ಕುಹಕತನ",
"ಉಷ್ಣಮಾಪಕ",
"ಗುತ್ತಿಗೆದಾರ",
"ಅನನುಕೂಲ",
"ಗುಲಾಮಗಿರಿ",
"ಕುಸುಮಾಂಜಲಿ",
"ಅಂಜುಬುರುಕ",
"ಪರಮಹಂಸ",
"ಜನಬಳಕೆ",
"ಗಿಡಮೂಲಿಕೆ",
"ನಡವಳಿಕೆ",
"ಮಹಾಪುರುಷ", //1-11
"ಕಪಟತನ",
"ಖಳನಾಯಕ",
"ಕರಿಮೆಣಸು",
"ಪಾರಿತೋಷಕ",
"ಅಪಹರಣ",
"ಒಳಹರಿವು",
"ಜಗಳಗಂಟ",
"ಕಾಗೆಬಂಗಾರ",
"ಕಡುಬಿಸಿಲು",
"ಕಾರ್ಯಕಲಾಪ",
"ಅಸಮಾನತೆ",
"ಕ್ಷೀರಸಾಗರ",
"ಅನಾದಿಕಾಲ",
"ಪರಿವರ್ತನೆ",
"ಜಂಬೂಸವಾರಿ",
"ವ್ಯವಹಾರಿಕ",
"ಆತಂಕವಾದಿ",
"ಅಗರುಬತ್ತಿ",
"ಭಾಗವತಿಕೆ",
"ಎದುರುಗಡೆ",
"ಉಪಕರಿಸು",
"ನವೀಕರಣ",
"ಅಕ್ಷರಮಾಲೆ",
"ಕಣ್ಣಾಮುಚ್ಚಾಲೆ",
"ಕಂಕಣಬಲ",
"ಕೈಗಡಿಯಾರ",
"ಅಸಹಕಾರ",
"ಬಾಯಿಬಡಕ",
"ಚತುರೋಪಾಯ",
"ಕಾಲಕ್ರಮೇಣ",
"ಜನಾನುರಾಗ", //1-12
"ಅವಕಲನ",
"ದೈವಪುರುಷ",
"ಗೃಹಬಂಧನ",
"ತೂಗುಸೇತುವೆ",
"ಉಪದೇಶಕ",
"ಅಗ್ನಿಪ್ರವೇಶ",
"ನೆಲಮಾಳಿಗೆ",
"ಉಪನ್ಯಾಸಕ",
"ಆಕಾಶಬುಟ್ಟಿ",
"ಪರಿತಪಿಸು",
"ಸರಬರಾಜು",
"ಮಹಾನಗರ",
"ಬಳಕೆದಾರ",
"ವಿಜಯಶಾಲಿ",
"ಹೃದಯಾಘಾತ",
"ಆನುವಂಶಿಕ",
"ಸರಿಪಡಿಸು",
"ಗತವೈಭವ",
"ಪಶುಪಾಲನೆ",
"ಗರಡಿಮನೆ",
"ಉಪನಗರ",
"ಮನೋರಂಜನೆ",
"ಕಡೆಗಾಣಿಸು",
"ಕಿವುಡುತನ",
"ವಸತಿಗೃಹ",
"ಅರೆನಿಮಿಷ",
"ಆಶುಕವಿತೆ",
"ನಿರಾಕರಿಸು",
"ಅಲ್ಪವಿರಾಮ",
"ಕಲೋಪಾಸಕ", // 1-1-23
"ಮಾರ್ಗದರ್ಶನ",
"ಸಹೃದಯತೆ",
"ಪರಿಪಾಲಿಸು",
"ಅತಿಥಿಗೃಹ",
"ಚಕ್ರಾಧಿಪತಿ",
"ಆಸೆಬುರುಕ",
"ಕುಲಾಧಿಪತಿ",
"ಪರಿಷ್ಕರಣೆ",
"ಕಾವಲುಗಾರ",
"ಸರಿಸಮಾನ",
"ದಯಮಾಡಿಸು",
"ತಲೆಹರಟೆ",
"ಅಂದಗೊಳಿಸು",
"ನೆಲಗಡಲೆ",
"ಏಕಾಂಗಿತನ",
"ದಿನಬಳಕೆ",
"ತೋಟಗಾರಿಕೆ",
"ದನಿಗೂಡಿಸು",
"ಮರುದಿವಸ",
"ಪಾಳೆಗಾರಿಕೆ",
"ಅಧಿವೇಶನ",
"ವಿಚಾರಧಾರೆ",
"ಕಾಲಾನುಸಾರ",
"ಅತಿಮಾನುಷ",
"ದುರಾಲೋಚನೆ",
"ಹಾಡುಗಾರಿಕೆ",
"ಉಪಚರಿಸು"
]



export const VALIDGUESSES4 = [
'ಅಂಕಂಗಾರ',
'ಅಂಕಂಗುಡು',
'ಅಂಕಂಗೊಳ್',
'ಅಂಕಕಾತಿ',
'ಅಂಕಕಾರ',
'ಅಂಕಕಾರ್ತಿ',
'ಅಂಕಕಾಱ',
'ಅಂಕಗಾಱ',
'ಅಂಕಚೇಯ',
'ಅಂಕಜೇಯ',
'ಅಂಕಝಂಕೆ',
'ಅಂಕಟಂಕ',
'ಅಂಕಟೆಂಕೆ',
'ಅಂಕಡೊಂಕ',
'ಅಂಕತಳ',
'ಅಂಕತಿಕೆ',
'ಅಂಕದೆರೆ',
'ಅಂಕಪಟ್ಟಿ',
'ಅಂಕಪಟ್ಟೆ',
'ಅಂಕಪಾಲಿ',
'ಅಂಕಪಾಶ',
'ಅಂಕಪೀಠ',
'ಅಂಕಮಾಲೆ',
'ಅಂಕಮುಖ',
'ಅಂಕರಣಿ',
'ಅಂಕರಿಕೆ',
'ಅಂಕಲಿಗ',
'ಅಂಕಲಿಗೆ',
'ಅಂಕಲಿಪಿ',
'ಅಂಕವಣ',
'ಅಂಕವಣಿ',
'ಅಂಕವಣೆ',
'ಅಂಕವರೆ',
'ಅಂಕವಲ',
'ಅಂಕವಲೆ',
'ಅಂಕವಱೆ',
'ಅಂಕವಾಡಿ',
'ಅಂಕವಾತು',
'ಅಂಕವೀರ',
'ಅಂಕವೇರು',
'ಅಂಕಶಾಲೆ',
'ಅಂಕಸುಂಕ',
'ಅಂಕಿತಗೈ',
'ಅಂಕುಡೊಂಕು',
'ಅಂಕುರಣ',
'ಅಂಕುರತ್ವ',
'ಅಂಕುರಾಂಗ',
'ಅಂಕುರಿತ',
'ಅಂಕುರಿಸು',
'ಅಂಕುಶಕ',
'ಅಂಕೆಗೆಯ್',
'ಅಂಕೆಟಂಕ',
'ಅಂಕೆದೋಱು',
'ಅಂಕೆಮೀರು',
'ಅಂಕೆಸಂಖ್ಯೆ',
'ಅಂಗಂಗೊಳ್',
'ಅಂಗಂದುಂಬು',
'ಅಂಗಂಬಡೆ',
'ಅಂಗಕ್ರಿಯೆ',
'ಅಂಗಕ್ಷಯ',
'ಅಂಗಗುಣ',
'ಅಂಗಚಿತ್ತ',
'ಅಂಗಚೇಷ್ಟೆ',
'ಅಂಗಚ್ಛವಿ',
'ಅಂಗಜನ್ಮ',
'ಅಂಗಜಾತ',
'ಅಂಗಜಾರಿ',
'ಅಂಗಜಾಲ',
'ಅಂಗಜಾವ',
'ಅಂಗಜೀವಿ',
'ಅಂಗಜೋಡು',
'ಅಂಗತನೆ',
'ಅಂಗತ್ತನೆ',
'ಅಂಗತ್ರಾಣ',
'ಅಂಗದಂಡಿ',
'ಅಂಗದಟ್ಟ',
'ಅಂಗದಟ್ಟು',
'ಅಂಗದಾಳ',
'ಅಂಗದಾರ',
'ಅಂಗದಾಢ್ರ್ಯ',
'ಅಂಗನೂಲು',
'ಅಂಗನ್ಯಾಸ',
'ಅಂಗಪಾತ',
'ಅಂಗಪಾಳಿ',
'ಅಂಗಪೂಜೆ',
'ಅಂಗಪ್ರಭೆ',
'ಅಂಗಫಲ',
'ಅಂಗಭಂಗ',
'ಅಂಗಭವ',
'ಅಂಗಭಿತ್ತಿ',
'ಅಂಗಭೇದ',
'ಅಂಗಭೋಗ',
'ಅಂಗಮಚ್ಚ',
'ಅಂಗಮಣಿ',
'ಅಂಗಮನ',
'ಅಂಗಮರ್ದ',
'ಅಂಗಮರ್ದಿ',
'ಅಂಗಮರ್ಷ',
'ಅಂಗಮಾರಿ',
'ಅಂಗಮುದ್ರೆ',
'ಅಂಗಯಷ್ಟಿ',
'ಅಂಗಯಿಸು',
'ಅಂಗಯ್ಮುಟ್ಟು',
'ಅಂಗರಕ್ಕ',
'ಅಂಗರಕ್ಕೆ',
'ಅಂಗರಕ್ಷ',
'ಅಂಗರಕ್ಷೆ',
'ಅಂಗರಖಾ',
'ಅಂಗರಸ',
'ಅಂಗರಾಗ',
'ಅಂಗರಿಕ',
'ಅಂಗರುಚ',
'ಅಂಗರುಚಿ',
'ಅಂಗರುಹ',
'ಅಂಗರೇಕು',
'ಅಂಗರೇಖು',
'ಅಂಗರೇಖೆ',
'ಅಂಗಲತಾ',
'ಅಂಗಲಾಚು',
'ಅಂಗಲಾಪ',
'ಅಂಗಲಾಪು',
'ಅಂಗಲಾರ್ಚು',
'ಅಂಗವಟೆ',
'ಅಂಗವಟ್ಟ',
'ಅಂಗವಟ್ಟೆ',
'ಅಂಗವಡಿ',
'ಅಂಗವಡೆ',
'ಅಂಗವಣೆ',
'ಅಂಗವರ್ಮ',
'ಅಂಗವಲ್ಲಿ',
'ಅಂಗವಸ್ತ್ರ',
'ಅಂಗವಾನು',
'ಅಂಗವಿದ್ಯೆ',
'ಅಂಗವಿಸು',
'ಅಂಗಶಾಸ್ತ್ರ',
'ಅಂಗಶುದ್ಧಿ',
'ಅಂಗಶೋಫ',
'ಅಂಗಸಂಗ',
'ಅಂಗಸಂಗಿ',
'ಅಂಗಸಂಜ್ಞೆ',
'ಅಂಗಸತ್ವ',
'ಅಂಗಸಸಿ',
'ಅಂಗಸಾಕ್ಷಿ',
'ಅಂಗಸುಖ',
'ಅಂಗಸೂತ್ರ',
'ಅಂಗಸೋಂಕು',
'ಅಂಗಸ್ಥಲ',
'ಅಂಗಹಾರ',
'ಅಂಗಹೀನ',
'ಅಂಗಹೀನೆ',
'ಅಂಗಳಿಸು',
'ಅಂಗಾಂಗಿಕ',
'ಅಂಗಾರಕ',
'ಅಂಗಾರಾಮ್ಲ',
'ಅಂಗಾರಿಕ',
'ಅಂಗಾರ್ಚನೆ',
'ಅಂಗಾಶ್ರಮ',
'ಅಂಗೀಕರ್ತ',
'ಅಂಗೀಕಾರ',
'ಅಂಗೀಕೃತ',
'ಅಂಗೀಕೃತಿ',
'ಅಂಗುಲಿಕ',
'ಅಂಗುಲಿನಿ',
'ಅಂಗುಲೀಯ',
'ಅಂಗುಸ್ತಾನಿ',
'ಅಂಗುಸ್ತಾನು',
'ಅಂಗುಳ್ಮುಳ್ಳು',
'ಅಂಗೈಗೆರೆ',
'ಅಂಗೈತಳ',
'ಅಂಗೈನೆಲ್ಲಿ',
'ಅಂಗೈಬುತ್ತಿ',
'ಅಂಗೈಲಿಂಗ',
'ಅಂಗೈಹುಣ್ಣು',
'ಅಂಗೋತ್ಪತ್ತಿ',
'ಅಂಗೋಪಾಂಗ',
'ಅಂಘರಿಕ',
'ಅಂಘವಣೆ',
'ಅಂಘವಿಸು',
'ಅಂಘಾಬಳ',
'ಅಂಘೋಬಳ',
'ಅಂಘ್ರಿತಲ',
'ಅಂಘ್ರಿತ್ರಾಣ',
'ಅಂಘ್ರಿಫಲ',
'ಅಂಚಿರಿಸು',
'ಅಂಚುಕಟ್ಟು',
'ಅಂಚುಗಟ್ಟು',
'ಅಂಚುಗಾಱ',
'ಅಂಚೆಕಟ್ಟೆ',
'ಅಂಚೆಗಾರ',
'ಅಂಚೆಚೀಟಿ',
'ಅಂಚೆಚೀಲ',
'ಅಂಚೆಡಬ್ಬ',
'ಅಂಚೆಡಬ್ಬಿ',
'ಅಂಚೆದಾರ',
'ಅಂಚೆದೇರ',
'ಅಂಚೆನಡೆ',
'ಅಂಚೆಬಸ್ತು',
'ಅಂಚೆಮತ',
'ಅಂಚೆಮನೆ',
'ಅಂಚೆಮಾರ್ಗ',
'ಅಂಚೆಯಾಳು',
'ಅಂಚೆಯಿಡು',
'ಅಂಚೆವಱಿ',
'ಅಂಚೆವಾಸು',
'ಅಂಚೆವಿಂಡು',
'ಅಂಚೆವೆಚ್ಚ',
'ಅಂಚೆವೆಣ್ಣು',
'ಅಂಚೆಹುಲ್ಲು',
'ಅಂಜನಿಕೆ',
'ಅಂಜಲಿಸು',
'ಅಂಜವಲೆ',
'ಅಂಜಿಹುಲ್ಲು',
'ಅಂಜುಕುಳಿ',
'ಅಂಜುಗುಳಿ',
'ಅಂಟಕಾಸು',
'ಅಂಟಕುಂಟ',
'ಅಂಟಗಾಸು',
'ಅಂಟರಳೆ',
'ಅಂಟರಿಕೆ',
'ಅಂಟರಿಸು',
'ಅಂಟವಾಳ',
'ಅಂಟಸುಂಟಿ',
'ಅಂಟುಕಣಿ',
'ಅಂಟುಕೊಗ್ಗೆ',
'ಅಂಟುಕೊಡು',
'ಅಂಟುಕೋಲು',
'ಅಂಟುಗಳ',
'ಅಂಟುಗಳೆ',
'ಅಂಟುಗೊಳ್',
'ಅಂಟುಗೋಡೆ',
'ಅಂಟುಜಾಡ್ಯ',
'ಅಂಟುಪಟ್ಟಿ',
'ಅಂಟುಬೂಷ್ಟು',
'ಅಂಟುಭಾಷೆ',
'ಅಂಟುಮನೆ',
'ಅಂಟುಮಳೆ',
'ಅಂಟುಮುಟ್ಟು',
'ಅಂಟುರೋಗ',
'ಅಂಟುರೋಗಿ',
'ಅಂಟುವಕ್ಕು',
'ಅಂಟುವಲೆ',
'ಅಂಟುವಾಳ',
'ಅಂಟುವೇಂಟೆ',
'ಅಂಟುಸರಿ',
'ಅಂಟುಸುರು',
'ಅಂಡಕೋಶ',
'ಅಂಡಗೋಲ',
'ಅಂಡಬಂಡ',
'ಅಂಡಧಾರಿ',
'ಅಂಡನಾಳ',
'ಅಂಡರಿಕೆ',
'ಅಂಡಲಿಸು',
'ಅಂಡಲೆತ',
'ಅಂಡಲೆವು',
'ಅಂಡವಾತ',
'ಅಂಡವಾಯು',
'ಅಂಡವೃತ್ತ',
'ಅಂಡವೃದ್ಧಿ',
'ಅಂಡಶಾಸ್ತ್ರ',
'ಅಂಡಶ್ವೇತ',
'ಅಂಡಾಕಾರ',
'ಅಂಡಾಶಯ',
'ಅಂಡುಕೊಕ್ಕೆ',
'ಅಂಡುಗಚ್ಚೆ',
'ಅಂಡುಗೊಳ್',
'ಅಂಡುಗೋಡೆ',
'ಅಂಡುವನ',
'ಅಂತಃಕೃತ',
'ಅಂತಃಕೇಂದ್ರ',
'ಅಂತಃಕ್ರಿಯೆ',
'ಅಂತಃಖೇದ',
'ಅಂತಃಪಟ',
'ಅಂತಃಪಾತಿ',
'ಅಂತಃಪುರ',
'ಅಂತಃಪ್ರಜ್ಞೆ',
'ಅಂತಃಶಕ್ತಿ',
'ಅಂತಃಶಾಂತ',
'ಅಂತಃಶಾಂತಿ',
'ಅಂತಃಶುದ್ಧಿ',
'ಅಂತಃಶೂನ್ಯ',
'ಅಂತಃಶ್ರಮ',
'ಅಂತಃಸತ್ವ',
'ಅಂತಃಸತ್ವೆ',
'ಅಂತಃಸಾಕ್ಷಿ',
'ಅಂತಃಸಾರ',
'ಅಂತಃಸ್ಥಲಿ',
'ಅಂತಃಸ್ಮೃತಿ',
'ಅಂತಃಸ್ರಾವ',
'ಅಂತಃಸ್ವೇದ',
'ಅಂತಗಾಣ್',
'ಅಂತಪ್ಪಳ್',
'ಅಂತಪ್ರಾಸ',
'ಅಂತಮೂಲ',
'ಅಂತರಂಗ',
'ಅಂತರಂಗೆ',
'ಅಂತರಸ್ಥ',
'ಅಂತರಾಟ',
'ಅಂತರಾತ್ಮ',
'ಅಂತರಾಯ',
'ಅಂತರಾಲ',
'ಅಂತರಾಳ',
'ಅಂತರಿಕೆ',
'ಅಂತರಿಕ್ಷ',
'ಅಂತರಿತ',
'ಅಂತರಿತೆ',
'ಅಂತರಿಸು',
'ಅಂತರೀಪ',
'ಅಂತರೀಯ',
'ಅಂತರ್ಗಡು',
'ಅಂತರ್ಗತ',
'ಅಂತರ್ಗುಹೆ',
'ಅಂತರ್ಗೃಹ',
'ಅಂತರ್ಜಂತು',
'ಅಂತರ್ಜನ್ಯ',
'ಅಂತರ್ಜಲ',
'ಅಂತರ್ಜೀವಿ',
'ಅಂತಜ್ರ್ಯೋತಿ',
'ಅಂತರ್ದಾಯ',
'ಅಂತರ್ದಾಯಿ',
'ಅಂತರ್ದಾಹ',
'ಅಂತರ್ದೃಷ್ಟಿ',
'ಅಂತರ್ದೇಹ',
'ಅಂತರ್ದ್ರವ',
'ಅಂತರ್ದ್ವಾರ',
'ಅಂತದ್ರ್ವೀಪ',
'ಅಂತರ್ಧನ',
'ಅಂತರ್ಧಾನ',
'ಅಂತರ್ಧೂಪ',
'ಅಂತರ್ನಿಷ್ಠ',
'ಅಂತರ್ನಿಷ್ಠೆ',
'ಅಂತರ್ಬದ್ಧ',
'ಅಂತರ್ಬೀಜ',
'ಅಂತರ್ಬೋಧೆ',
'ಅಂತರ್ಭಾವ',
'ಅಂತರ್ಭಾವಿ',
'ಅಂತರ್ಭಾಸ',
'ಅಂತರ್ಭೂತ',
'ಅಂತರ್ಭೇದ',
'ಅಂತಭ್ರ್ರಮ',
'ಅಂತರ್ಮನ',
'ಅಂತರ್ಮಲ',
'ಅಂತರ್ಮುಖ',
'ಅಂತರ್ಮುಖಿ',
'ಅಂತರ್ಯಾಮಿ',
'ಅಂತರ್ಯುದ್ಧ',
'ಅಂತರ್ಲಾಗ',
'ಅಂತರ್ಲಾಪಿ',
'ಅಂತರ್ಲಿಂಗ',
'ಅಂತರ್ಲೀನ',
'ಅಂತರ್ಲೇಪ',
'ಅಂತರ್ವಂಶ',
'ಅಂತರ್ವಣ',
'ಅಂತರ್ವತ್ನಿ',
'ಅಂತರ್ವರ್ತಿ',
'ಅಂತರ್ವಾಣಿ',
'ಅಂತರ್ವಾಸ',
'ಅಂತರ್ವಾಹ',
'ಅಂತರ್ವಾಹಿ',
'ಅಂತರ್ವೀಕ್ಷೆ',
'ಅಂತರ್ವೃತ್ತ',
'ಅಂತರ್ವೃದ್ಧಿ',
'ಅಂತರ್ವೇದಿ',
'ಅಂತರ್ವ್ಯಥೆ',
'ಅಂತರ್ವ್ಯಾಪ್ತಿ',
'ಅಂತರ್ಹಾಸ',
'ಅಂತರ್ಹಿತ',
'ಅಂತರ್ಹಿತೆ',
'ಅಂತವಂತ',
'ಅಂತವಾಸ',
'ಅಂತವುರ',
'ಅಂತವೆಯ್ದು',
'ಅಂತಶ್ಚಕ್ಷು',
'ಅಂತಶ್ಶಾಂತ',
'ಅಂತಶ್ಶಾಯಿ',
'ಅಂತಶ್ಶುದ್ಧಿ',
'ಅಂತಶ್ಶೂನ್ಯ',
'ಅಂತಸ್ತಾಪ',
'ಅಂತಸ್ಥತೆ',
'ಅಂತಸ್ಥಿತ',
'ಅಂತಸ್ಸತ್ವ',
'ಅಂತಸ್ಸತ್ವೆ',
'ಅಂತಸ್ಸಾರ',
'ಅಂತಱಿಂದಂ',
'ಅಂತಱಿಂದೆ',
'ಅಂತಾಕ್ಷರಿ',
'ಅಂತಿಬಲೆ',
'ಅಂತಿರಿಕೆ',
'ಅಂತುವರಂ',
'ಅಂತೆಕಂತೆ',
'ಅಂತೆಗೆಯ್',
'ಅಂತೆವೊಲ್',
'ಅಂತೆವೋಲ್',
'ಅಂತೆವಾಸಿ',
'ಅಂತ್ಯಗೊಳ್ಳು',
'ಅಂತ್ಯಕರ್ಮ',
'ಅಂತ್ಯಕಾಲ',
'ಅಂತ್ಯಕ್ರಿಯೆ',
'ಅಂತ್ಯಜನ್ಮ',
'ಅಂತ್ಯಜಾತ',
'ಅಂತ್ಯಜಾತಿ',
'ಅಂತ್ಯಪ್ರಾಸ',
'ಅಂತ್ಯಲೋಪ',
'ಅಂತ್ಯವರ್ಣ',
'ಅಂತ್ಯಶೈವ',
'ಅಂತ್ಯಾಕ್ಷರ',
'ಅಂತ್ಯಾಕ್ಷರಿ',
'ಅಂತ್ಯಾರಂಭಿ',
'ಅಂತ್ರಪುಚ್ಚ',
'ಅಂತ್ರಮಾಡು',
'ಅಂತ್ರವೃದ್ಧಿ',
'ಅಂದಂಬಡೆ',
'ಅಂದಂಬೆಱು',
'ಅಂದಕಾಱ',
'ಅಂದಗಾತಿ',
'ಅಂದಗಾರ',
'ಅಂದಗಾರ್ತಿ',
'ಅಂದಗಾಱ',
'ಅಂದಗೆಡು',
'ಅಂದಗೆಯ್',
'ಅಂದಗೇಡಿ',
'ಅಂದಗೊಳ್ಳು',
'ಅಂದಣಿಗ',
'ಅಂದವಡೆ',
'ಅಂದವಳಿ',
'ಅಂದವಿಗ',
'ಅಂದವಿಡು',
'ಅಂದವಿಸು',
'ಅಂದವೇಱು',
'ಅಂದಾದುಂದಿ',
'ಅಂದಿಗರ್ಚು',
'ಅಂದಿಸಿಕೊ',
'ಅಂದಿಹುಳು',
'ಅಂಧಕಾರ',
'ಅಂಧಕೂಪ',
'ಅಂಧತಮ',
'ಅಂಧಬಿಂದು',
'ಅಂಧಯಷ್ಟಿ',
'ಅಂಧಶ್ರದ್ಧೆ',
'ಅಂಧಾದುಂದಿ',
'ಅಂಧಾವಸ್ಥೆ',
'ಅಂಧಾಸುರ',
'ಅಂಬಕಳ',
'ಅಂಬಕಾಂಬು',
'ಅಂಬಗಾಲು',
'ಅಂಬರಿಕೆ',
'ಅಂಬರೀಷ',
'ಅಂಬಸೋಲು',
'ಅಂಬಳಕ',
'ಅಂಬಳಿಕು',
'ಅಂಬಳಿಕೆ',
'ಅಂಬಳಿಕ್ಕು',
'ಅಂಬಾಕಾಂತ',
'ಅಂಬಾಚರಿ',
'ಅಂಬಾಪತಿ',
'ಅಂಬಾರುಣ್ಣಿ',
'ಅಂಬಿಕೇಯ',
'ಅಂಬಿಗಾರ',
'ಅಂಬಿಗಿತ್ತಿ',
'ಅಂಬುಕಂಡಿ',
'ಅಂಬುಕಣ',
'ಅಂಬುಕೀಶ',
'ಅಂಬುಕೂರ್ಮ',
'ಅಂಬುಗಂಡಿ',
'ಅಂಬುಗಡಿ',
'ಅಂಬುಗರೆ',
'ಅಂಬುಗಱೆ',
'ಅಂಬುಗಾರ',
'ಅಂಬುಗುಳ್ಳೆ',
'ಅಂಬುಗೂಡು',
'ಅಂಬುಚರ',
'ಅಂಬುಜಜ',
'ಅಂಬುಜನ್ಮ',
'ಅಂಬುಜಾಕ್ಷ',
'ಅಂಬುಜಾಕ್ಷಿ',
'ಅಂಬುದರ್ತು',
'ಅಂಬುದಾಭ',
'ಅಂಬುದೊಡು',
'ಅಂಬುಧರ',
'ಅಂಬುನಾಗ',
'ಅಂಬುನಿಧಿ',
'ಅಂಬುಪಾನ',
'ಅಂಬುಪಾಯಿ',
'ಅಂಬುಭೃತ್',
'ಅಂಬುರಾಶಿ',
'ಅಂಬುರುಹ',
'ಅಂಬುವಾಹ',
'ಅಂಬುವೀಡು',
'ಅಂಬುವುಗು',
'ಅಂಬುವುಣ್',
'ಅಂಬೂಕೃತ',
'ಅಂಬೂದ್ಭೂತ',
'ಅಂಬೆಗರೆ',
'ಅಂಬೆಗಾಲ್',
'ಅಂಬೆಗಾಲು',
'ಅಂಬೇಗಾಲು',
'ಅಂಬೋರಾಶಿ',
'ಅಂಭಃಕಣ',
'ಅಂಭಃಕರಿ',
'ಅಂಭಃಪೂರ',
'ಅಂಭಃಪ್ಲವ',
'ಅಂಭಶ್ಚರ',
'ಅಂಭೋಗಿನಿ',
'ಅಂಭೊಜಾತ',
'ಅಂಭೋಜಿನಿ',
'ಅಂಭೋದಾದ್ವ',
'ಅಂಭೋನಿಧಿ',
'ಅಂಭೋರಾಶಿ',
'ಅಂಭೋರಾಸಿ',
'ಅಂಭೋರುಹ',
'ಅಂಭೋಲವ',
'ಅಂಶಕರ್ತ',
'ಅಂಶಗಣ',
'ಅಂಶಚಕ್ರ',
'ಅಂಶದಾನ',
'ಅಂಶಪತಿ',
'ಅಂಶಪತ್ರ',
'ಅಂಶಭಾಗಿ',
'ಅಂಶಸ್ವರ',
'ಅಂಶಾಂಕನ',
'ಅಂಶುಜಾಲ',
'ಅಂಶುಧರ',
'ಅಂಶುನಾಭಿ',
'ಅಂಶುಪಟ್ಟ',
'ಅಂಶುಪತಿ',
'ಅಂಶುಮಂತ',
'ಅಂಶುಮತಿ',
'ಅಂಶುಮಾಲಿ',
'ಅಂಶುಮಾಲೆ',
'ಅಂಶುಹಸ್ತ',
'ಅಂಶೋದ್ಭವ',
'ಅಂಸಕಳ',
'ಅಂಸಕೂಟ',
'ಅಂಸರೋಳಿ',
'ಅಕಂಟಕ',
'ಅಕಂಪನ',
'ಅಕಂಪಿತ',
'ಅಕರಣ',
'ಅಕರಣಿ',
'ಅಕರಾಸ್ತೆ',
'ಅಕರಾಳ',
'ಅಕರುಣ',
'ಅಕರ್ತನ',
'ಅಕರ್ತವ್ಯ',
'ಅಕರ್ತಾರ',
'ಅಕರ್ತೃಕ',
'ಅಕರ್ದಮ',
'ಅಕರ್ಮಕ',
'ಅಕಲಂಕ',
'ಅಕಲಂಕೆ',
'ಅಕಲತ್ರ',
'ಅಕಲಾಯ',
'ಅಕಲಾಸ',
'ಅಕಲುಷ',
'ಅಕಲುಷೆ',
'ಅಕಲೆಕ',
'ಅಕಲ್ಕಾರೆ',
'ಅಕಲ್ಪಿತ',
'ಅಕಲ್ಮಷ',
'ಅಕಲ್ಯಾಣ',
'ಅಕಶೇರು',
'ಅಕಸಾಲ',
'ಅಕಸಾಲೆ',
'ಅಕಸ್ಮಾತ್',
'ಅಕಸ್ಮಾತ್ತು',
'ಅಕಳಂಕ',
'ಅಕಳತ್ರ',
'ಅಕಾಡೆಮಿ',
'ಅಕಾಮಿಕ',
'ಅಕಾರಣ',
'ಅಕಾರಣಂ',
'ಅಕಾರಾಂತ',
'ಅಕಾರಾದಿ',
'ಅಕಾಲಿಕ',
'ಅಕಾಲೀನ',
'ಅಕಿಂಚನ',
'ಅಕಿಂಚನ್ಯ',
'ಅಕುಂಠಿತ',
'ಅಕುಟಿಲ',
'ಅಕುಟಿಲೆ',
'ಅಕುಪಿತ',
'ಅಕುಪಿತೆ',
'ಅಕುಲಜ',
'ಅಕುಲಜೆ',
'ಅಕುಲೀನ',
'ಅಕುಲೀನೆ',
'ಅಕುಶಲ',
'ಅಕೂಪಾರ',
'ಅಕೂಬಾರ',
'ಅಕೃತಕ',
'ಅಕೃತಘ್ನ',
'ಅಕೃತಘ್ನೆ',
'ಅಕೃತಜ್ಞ',
'ಅಕೃತಜ್ಞೆ',
'ಅಕೃತಾರ್ಥ',
'ಅಕೃತ್ರಿಮ',
'ಅಕೇಂದ್ರೀಯ',
'ಅಕೈತವ',
'ಅಕೋನಕ',
'ಅಕೌಶಲ',
'ಅಕ್ಕಕ್ಕನೆ',
'ಅಕ್ಕಚ್ಚಲು',
'ಅಕ್ಕಟಿಕೆ',
'ಅಕ್ಕದಾನಿ',
'ಅಕ್ಕದಾಮ',
'ಅಕ್ಕದಾಳ',
'ಅಕ್ಕಪಕ್ಕ',
'ಅಕ್ಕಬರ್ಲು',
'ಅಕ್ಕಬಿಜ್ಜು',
'ಅಕ್ಕಮಾಲೆ',
'ಅಕ್ಕಮಿಕ',
'ಅಕ್ಕರತೆ',
'ಅಕ್ಕರಾಸ್ತೆ',
'ಅಕ್ಕರಿಕೆ',
'ಅಕ್ಕರಿಗ',
'ಅಕ್ಕಸಾಲ',
'ಅಕ್ಕಸಾಲಿ',
'ಅಕ್ಕಸಾಲೆ',
'ಅಕ್ಕಳಿಕೆ',
'ಅಕ್ಕಳಿಸು',
'ಅಕ್ಕಳ್ಮಗ',
'ಅಕ್ಕಱಿತೆ',
'ಅಕ್ಕಱಿಕೆ',
'ಅಕ್ಕಾಂತಗೈ',
'ಅಕ್ಕಾಡಿಸು',
'ಅಕ್ಕಿಗಂಜಿ',
'ಅಕ್ಕಿಗಚ್ಚು',
'ಅಕ್ಕಿಗರ್ಚು',
'ಅಕ್ಕಿಗುಟ್ಟು',
'ಅಕ್ಕಿಗೆಡ್ಡೆ',
'ಅಕ್ಕಿಚಿಟ್ಟೆ',
'ಅಕ್ಕಿತರಿ',
'ಅಕ್ಕಿದಳಿ',
'ಅಕ್ಕಿನುಚ್ಚು',
'ಅಕ್ಕಿಪಡಿ',
'ಅಕ್ಕಿಪಿಲ್ಲಿ',
'ಅಕ್ಕಿಬಿಕ್ಕಿ',
'ಅಕ್ಕಿಬೋಜ',
'ಅಕ್ಕಿಯಿಡು',
'ಅಕ್ಕಿರಡ್ಡಿ',
'ಅಕ್ಕಿವುಲ್ಲು',
'ಅಕ್ಕಿವೋಲೆ',
'ಅಕ್ಕಿಸರಿ',
'ಅಕ್ಕಿಸಱಿ',
'ಅಕ್ಕಿಹಿಟ್ಟು',
'ಅಕ್ಕಿಹುಲ್ಲು',
'ಅಕ್ಕಿಹೆಡೆ',
'ಅಕ್ಕಿಹೊಡೆ',
'ಅಕ್ಕುಡಿಸು',
'ಅಕ್ಕುಮಿಕ್ಕು',
'ಅಕ್ಕುರಳಿ',
'ಅಕ್ಕುಲಜ',
'ಅಕ್ಕುಲಜೆ',
'ಅಕ್ಕುಲಿಗ',
'ಅಕ್ಕುಲಿಸು',
'ಅಕ್ಕುಹುಲ್ಲು',
'ಅಕ್ಕುಳಿಕೆ',
'ಅಕ್ಕುಳಿಸು',
'ಅಕ್ಕೆಗೆಯ್',
'ಅಕ್ಕೆಗೊಳ್',
'ಅಕ್ಕೆಬೊಟ್ಟು',
'ಅಕ್ರವ್ಯಾದ',
'ಅಕ್ಷಕ್ರೀಡೆ',
'ಅಕ್ಷತ್ರಿಯ',
'ಅಕ್ಷದಾಮ',
'ಅಕ್ಷದೇವಿ',
'ಅಕ್ಷಧೂರ್ತ',
'ಅಕ್ಷಪಾದ',
'ಅಕ್ಷಫಲ',
'ಅಕ್ಷಮಣಿ',
'ಅಕ್ಷಮತೆ',
'ಅಕ್ಷಮಾಲೆ',
'ಅಕ್ಷರಜ್ಞ',
'ಅಕ್ಷರಜ್ಞೆ',
'ಅಕ್ಷರತೆ',
'ಅಕ್ಷರಶಃ',
'ಅಕ್ಷರಸ್ಥ',
'ಅಕ್ಷರಸ್ಥೆ',
'ಅಕ್ಷರಾರ್ಥ',
'ಅಕ್ಷರಿಕೆ',
'ಅಕ್ಷರೇಖೆ',
'ಅಕ್ಷಲೀಲೆ',
'ಅಕ್ಷವತಿ',
'ಅಕ್ಷವಿದ್ಯೆ',
'ಅಕ್ಷಸೂತ್ರ',
'ಅಕ್ಷಾವಳಿ',
'ಅಕ್ಷಿಕರ್ಣ',
'ಅಕ್ಷಿಗತ',
'ಅಕ್ಷಿಜಲ',
'ಅಕ್ಷಿತಾರೆ',
'ಅಕ್ಷಿಪಕ್ಷ',
'ಅಕ್ಷಿಪಕ್ಷ್ಮ',
'ಅಕ್ಷಿಪಟ',
'ಅಕ್ಷಿಪಯ',
'ಅಕ್ಷಿಭ್ರಮ',
'ಅಕ್ಷಿಮೂಲ',
'ಅಕ್ಷಿಯವ',
'ಅಕ್ಷಿರೋಗ',
'ಅಕ್ಷಿರೋಮ',
'ಅಕ್ಷಿಶೂಲೆ',
'ಅಕ್ಷಿಶ್ರುತಿ',
'ಅಕ್ಷಿಸ್ಪಂದ',
'ಅಕ್ಷಿಹೀನ',
'ಅಕ್ಷಿಹೀನೆ',
'ಅಕ್ಷುಬ್ಧತೆ',
'ಅಕ್ಷುಭಿತ',
'ಅಕ್ಷೇಶ್ರವ',
'ಅಕ್ಷೋಹಿಣಿ',
'ಅಕ್ಷೌಹಿಣಿ',
'ಅಖಂಡಣ',
'ಅಖಂಡತೆ',
'ಅಖಂಡನ',
'ಅಖಂಡಿತ',
'ಅಖಚಿತ',
'ಅಖಿಲಾಂಡ',
'ಅಗಂಗೊಳ್',
'ಅಗಚಾಟು',
'ಅಗಚಾಟ್ಲು',
'ಅಗಚೊಪ್ಪು',
'ಅಗಜಾತೆ',
'ಅಗಜೇಂದ್ರ',
'ಅಗಜೇಶ',
'ಅಗಡಿಸು',
'ಅಗಡಾಗು',
'ಅಗಣಿತ',
'ಅಗತಿಕ',
'ಅಗತಿಕೆ',
'ಅಗತುಕೊ',
'ಅಗತ್ಯತೆ',
'ಅಗಧರ',
'ಅಗಪಡು',
'ಅಗಭೇದಿ',
'ಅಗಮುಡಿ',
'ಅಗಮ್ಯತೆ',
'ಅಗರಿಪು',
'ಅಗರ್ಹಿತ',
'ಅಗಲಾಡು',
'ಅಗಲಿಕೆ',
'ಅಗಲಿಡು',
'ಅಗಲಿತು',
'ಅಗಲಿತ್ತು',
'ಅಗಲಿಸು',
'ಅಗಲುರ',
'ಅಗಲುಹ',
'ಅಗಲೆತ್ತು',
'ಅಗಲೊಗೆ',
'ಅಗಲೊತ್ತು',
'ಅಗಲ್ತರ್',
'ಅಗವಡು',
'ಅಗವೈರಿ',
'ಅಗಸತಿ',
'ಅಗಸಾಲ',
'ಅಗಸಾಲೆ',
'ಅಗಸಿಗ',
'ಅಗಸಿತಿ',
'ಅಗಸುತೆ',
'ಅಗಸುದ್ದಿ',
'ಅಗಸುದ್ದೆ',
'ಅಗಸೆವೂ',
'ಅಗಸೆಳ್ಳು',
'ಅಗಹರ',
'ಅಗಳಂತಿ',
'ಅಗಳತೆ',
'ಅಗಳಾಡು',
'ಅಗಳಿತ',
'ಅಗಳಿಸು',
'ಅಗೞತ',
'ಅಗೞಿಸು',
'ಅಗಾಧಿಪ',
'ಅಗಿತಿನ್ನು',
'ಅಗಿಬಿಗಿ',
'ಅಗಿಲಿಲಿ',
'ಅಗುಂದಲೆ',
'ಅಗುಂಬಿಸು',
'ಅಗುಣಿತ',
'ಅಗುರಿಸು',
'ಅಗುರ್ಬಿಸು',
'ಅಗುರ್ವಿಸು',
'ಅಗುರ್ವೆಱು',
'ಅಗುಳಂತಿ',
'ಅಗುಳಿಸು',
'ಅಗುೞಿಸು',
'ಅಗುೞಿಸು2',
'ಅಗುೞೂಟ',
'ಅಗುೞೂಡು',
'ಅಗೂಢತೆ',
'ಅಗೆಕಟ್ಟು',
'ಅಗೆದೋರು',
'ಅಗೆಬಿಡು',
'ಅಗೆಯಂತ್ರ',
'ಅಗೆಯಿಕ್ಕು',
'ಅಗೆಯಿಸು',
'ಅಗೆಯುಡಿ',
'ಅಗೆಯೆತ್ತು',
'ಅಗೆಯೇೞ್',
'ಅಗೆಯೊಡೆ',
'ಅಗೆವಗೆ',
'ಅಗೆವಡೆ',
'ಅಗೆವೊಯ್',
'ಅಗೇಂದ್ರಜೆ',
'ಅಗೋಚರ',
'ಅಗೌಕಸ',
'ಅಗೌರವ',
'ಅಗ್ಗಂಬೆಱು',
'ಅಗ್ಗಡಲ್',
'ಅಗ್ಗಡೆಯ',
'ಅಗ್ಗಣಗಿ',
'ಅಗ್ಗಪಾಡು',
'ಅಗ್ಗಯಿಲೆ',
'ಅಗ್ಗರಜ',
'ಅಗ್ಗರಣೆ',
'ಅಗ್ಗರಿಸು',
'ಅಗ್ಗಲಿಸು',
'ಅಗ್ಗವಣಿ',
'ಅಗ್ಗವಳ',
'ಅಗ್ಗವಳಿ',
'ಅಗ್ಗಸುಗ್ಗಿ',
'ಅಗ್ಗಳಿಕೆ',
'ಅಗ್ಗಳಿಕ್ಕೆ',
'ಅಗ್ಗಳಿಸು',
'ಅಗ್ಗಳೆಯ',
'ಅಗ್ಗೞಿಕೆ',
'ಅಗ್ಗಾಯಿತಿ',
'ಅಗ್ಗಾಯಿಲೆ',
'ಅಗ್ಗಿಕೊಂಡ',
'ಅಗ್ಗಿಗಣ್ಣು',
'ಅಗ್ಗಿಗೊಂಡ',
'ಅಗ್ಗಿಟಿಕೆ',
'ಅಗ್ಗಿಟಿಗೆ',
'ಅಗ್ಗಿಟ್ಟಿಕೆ',
'ಅಗ್ಗಿಮೊಗ',
'ಅಗ್ಗಿಷ್ಟಕೆ',
'ಅಗ್ಗಿಷ್ಟಗೆ',
'ಅಗ್ಗಿಷ್ಟಿಕೆ',
'ಅಗ್ಗಿಷ್ಟಿಗೆ',
'ಅಗ್ಗಿಸಿಕೊ',
'ಅಗ್ಘಟಿತ',
'ಅಗ್ಘವಣಿ',
'ಅಗ್ನಿಕಣ',
'ಅಗ್ನಿಕಾರ್ಯ',
'ಅಗ್ನಿಕಾಷ್ಠ',
'ಅಗ್ನಿಕುಂಡ',
'ಅಗ್ನಿಕುಲ',
'ಅಗ್ನಿಕೋಣ',
'ಅಗ್ನಿಕ್ರಿಯೆ',
'ಅಗ್ನಿಖಂಡ',
'ಅಗ್ನಿಗಣ್ಣ',
'ಅಗ್ನಿಗಣ್ಣು',
'ಅಗ್ನಿಗರ್ಭ',
'ಅಗ್ನಿಗೆಯ್ಯ',
'ಅಗ್ನಿಗೋಳ',
'ಅಗ್ನಿಚಿತ್ತು',
'ಅಗ್ನಿಜಿಹ್ವೆ',
'ಅಗ್ನಿಜ್ವಾಲೆ',
'ಅಗ್ನಿತಾರೆ',
'ಅಗ್ನಿತ್ರಯ',
'ಅಗ್ನಿದಿಕ್ಕು',
'ಅಗ್ನಿದಿವ್ಯ',
'ಅಗ್ನಿದೇವೆ',
'ಅಗ್ನಿನಕ್ರ',
'ಅಗ್ನಿನೃತ್ಯ',
'ಅಗ್ನಿನೇತ್ರ',
'ಅಗ್ನಿಪರ್ಣಿ',
'ಅಗ್ನಿಪಳ',
'ಅಗ್ನಿಪುಟ',
'ಅಗ್ನಿಪುತ್ರಿ',
'ಅಗ್ನಿಪೂಜೆ',
'ಅಗ್ನಿಫಲ',
'ಅಗ್ನಿಬಲ',
'ಅಗ್ನಿಬಳ್ಳಿ',
'ಅಗ್ನಿಬಾಣ',
'ಅಗ್ನಿಭವ',
'ಅಗ್ನಿಮಂಥ',
'ಅಗ್ನಿಮಣಿ',
'ಅಗ್ನಿಮಾಂದ್ಯ',
'ಅಗ್ನಿಮಿತ್ರ',
'ಅಗ್ನಿಮುಖ',
'ಅಗ್ನಿಮುಖಿ',
'ಅಗ್ನಿಮೂಲೆ',
'ಅಗ್ನಿಯಂತ್ರ',
'ಅಗ್ನಿರಕ್ಷೆ',
'ಅಗ್ನಿರಸ',
'ಅಗ್ನಿಲಿಪಿ',
'ಅಗ್ನಿವಿಮೆ',
'ಅಗ್ನಿವೀರ್ಯ',
'ಅಗ್ನಿವೈದ್ಯ',
'ಅಗ್ನಿಶಾಲೆ',
'ಅಗ್ನಿಶಿಖ',
'ಅಗ್ನಿಶಿಖೆ',
'ಅಗ್ನಿಶಿಲೆ',
'ಅಗ್ನಿಶೌಚ',
'ಅಗ್ನಿಷ್ಟಗೆ',
'ಅಗ್ನಿಷ್ಟೋಮ',
'ಅಗ್ನಿಷ್ಠಗೆ',
'ಅಗ್ನಿಸಖ',
'ಅಗ್ನಿಸರ್ಪ',
'ಅಗ್ನಿಸಾಕ್ಷಿ',
'ಅಗ್ನಿಸೂತ್ರ',
'ಅಗ್ನಿಸ್ತಂಭ',
'ಅಗ್ನಿಸ್ಫೋಟ',
'ಅಗ್ನಿಹೋತ್ರ',
'ಅಗ್ನಿಹೋತ್ರಿ',
'ಅಗ್ನ್ಯಾಧಾನ',
'ಅಗ್ರಕಾರ್ಯ',
'ಅಗ್ರಗಣ್ಯ',
'ಅಗ್ರಗಣ್ಯೆ',
'ಅಗ್ರಗಾಮಿ',
'ಅಗ್ರಗೃಹ',
'ಅಗ್ರಜನ್ಮ',
'ಅಗ್ರಜಾತ',
'ಅಗ್ರಜಾತೆ',
'ಅಗ್ರಜೇಶ',
'ಅಗ್ರದೆಲೆ',
'ಅಗ್ರದೇಶ',
'ಅಗ್ರಪಂಕ್ತಿ',
'ಅಗ್ರಪಟ್ಟ',
'ಅಗ್ರಪೀಠ',
'ಅಗ್ರಪುತ್ರ',
'ಅಗ್ರಪೂಜೆ',
'ಅಗ್ರಪೂಜ್ಯ',
'ಅಗ್ರಪೂಜ್ಯೆ',
'ಅಗ್ರಫಲ',
'ಅಗ್ರಬಲ',
'ಅಗ್ರಭವ',
'ಅಗ್ರಮಾಂಸ',
'ಅಗ್ರಮುಖ',
'ಅಗ್ರವರ್ತಿ',
'ಅಗ್ರವರ್ಧಿ',
'ಅಗ್ರವೀಳ್ಯ',
'ಅಗ್ರಶಾಖೆ',
'ಅಗ್ರಶಿಷ್ಯ',
'ಅಗ್ರಸಣಿ',
'ಅಗ್ರಸರ',
'ಅಗ್ರಸಾಲೆ',
'ಅಗ್ರಸುತ',
'ಅಗ್ರಸ್ಥಾನ',
'ಅಗ್ರಹಣ',
'ಅಗ್ರಹಸ್ತ',
'ಅಗ್ರಹಾರ',
'ಅಗ್ರಾಮ್ಯತೆ',
'ಅಗ್ರಾಮ್ಯತ್ವ',
'ಅಗ್ರಾಸನ',
'ಅಗ್ರಾಸೀನ',
'ಅಗ್ರಾಸೀನೆ',
'ಅಗ್ರಾಹವ',
'ಅಗ್ರಾಹಾರ',
'ಅಗ್ರೇಶ್ವರ',
'ಅಗ್ರೇಸರ',
'ಅಗ್ರೋದಕ',
'ಅಗ್ಲನಾಜ',
'ಅಗ್ಲಪಿತ',
'ಅಘಚ್ಛಿದ',
'ಅಘಟನ',
'ಅಘಟಿತ',
'ಅಘಪಡು',
'ಅಘಮರ್ಷ',
'ಅಘರಿಪು',
'ಅಘವಡು',
'ಅಘಹರ',
'ಅಘಹಾರಿ',
'ಅಘೋರಾಸ್ತ್ರ',
'ಅಘೋಷಿತ',
'ಅಚಂಚಲ',
'ಅಚತುರ',
'ಅಚತುರೆ',
'ಅಚಪಲ',
'ಅಚಬಾಬು',
'ಅಚರತ್ವ',
'ಅಚಲತೆ',
'ಅಚಲತ್ವ',
'ಅಚಲಿತ',
'ಅಚಲಿತೆ',
'ಅಚಲೇಂದ್ರ',
'ಅಚಳಿತ',
'ಅಚಳಿತೆ',
'ಅಚಳಿಯಂ',
'ಅಚಾತುರ್ಯ',
'ಅಚಾನಕ',
'ಅಚಿಕ್ಕಣ',
'ಅಚಿನ್ನಿದ್ರೆ',
'ಅಚಿರತೆ',
'ಅಚಿರಾಂಶು',
'ಅಚುಂಬಿತ',
'ಅಚುಂಬಿತೆ',
'ಅಚುಮೋದ',
'ಅಚೇತನ',
'ಅಚೇಲಕ',
'ಅಚೇಲತ್ವ',
'ಅಚೈತನ್ಯ',
'ಅಚ್ಚಂಬೊಯ್',
'ಅಚ್ಚಕಪ್ಪು',
'ಅಚ್ಚಕೆಂಪು',
'ಅಚ್ಚಕೆಚ್ಚು',
'ಅಚ್ಚಕ್ಷರ',
'ಅಚ್ಚಗಂಪು',
'ಅಚ್ಚಗಂಬಿ',
'ಅಚ್ಚಗಡೆ',
'ಅಚ್ಚಗಪ್ಪು',
'ಅಚ್ಚಗೆಂಡ',
'ಅಚ್ಚಗೆಂಪು',
'ಅಚ್ಚಗೊಂಡ',
'ಅಚ್ಚಚರ್ಮ',
'ಅಚ್ಚಣಿಸು',
'ಅಚ್ಚನಂಗೈ',
'ಅಚ್ಚನನೆ',
'ಅಚ್ಚನಾಭಿ',
'ಅಚ್ಚಪುಳು',
'ಅಚ್ಚಬಣ್ಣ',
'ಅಚ್ಚಬಲ',
'ಅಚ್ಚಭಲ್ಲ',
'ಅಚ್ಚಮಣೆ',
'ಅಚ್ಚರಸಿ',
'ಅಚ್ಚರಸೆ',
'ಅಚ್ಚರಳ್',
'ಅಚ್ಚರಿಯ',
'ಅಚ್ಚರಿಸಿ',
'ಅಚ್ಚರಿಸು',
'ಅಚ್ಚಲರ್',
'ಅಚ್ಚಲಿಂಗ',
'ಅಚ್ಚಲಿಕೆ',
'ಅಚ್ಚಲಿಪಿ',
'ಅಚ್ಚವಡಿ',
'ಅಚ್ಚವಣ್',
'ಅಚ್ಚವಣ್ಣ',
'ಅಚ್ಚವಳಿ',
'ಅಚ್ಚವಾವು',
'ಅಚ್ಚವಿಷ',
'ಅಚ್ಚಸರ',
'ಅಚ್ಚಸವಿ',
'ಅಚ್ಚಸೊಗ',
'ಅಚ್ಚಹಾದಿ',
'ಅಚ್ಚಹಾಲು',
'ಅಚ್ಚಳಿಕೆ',
'ಅಚ್ಚಳಿಸು',
'ಅಚ್ಚೞಿಸು',
'ಅಚ್ಚಾಗಿಸು',
'ಅಚ್ಚಾಡಿಸು',
'ಅಚ್ಚಾಯಿಗ',
'ಅಚ್ಚಾವಿಚ್ಚಿ',
'ಅಚ್ಚಾವುಚ್ಚಿ',
'ಅಚ್ಚಾಳಿಕೆ',
'ಅಚ್ಚಾಳಿಗೆ',
'ಅಚ್ಚಿಕೊಡು',
'ಅಚ್ಚಿಬರು',
'ಅಚ್ಚುಕಟ್ಟು',
'ಅಚ್ಚುಕಾಱ',
'ಅಚ್ಚುಕೂಟ',
'ಅಚ್ಚುಗಂಬ',
'ಅಚ್ಚುಗಟ್ಟು',
'ಅಚ್ಚುಗಲ್ಲು',
'ಅಚ್ಚುಗಾರ',
'ಅಚ್ಚುಗಾಱ',
'ಅಚ್ಚುಗೂಟ',
'ಅಚ್ಚುಗೊಳ್',
'ಅಚ್ಚುಗೋಲು',
'ಅಚ್ಚುತುಂಡು',
'ಅಚ್ಚುತೇರು',
'ಅಚ್ಚುತೊಲೆ',
'ಅಚ್ಚುಪಡಿ',
'ಅಚ್ಚುಬೆಲ್ಲ',
'ಅಚ್ಚುಮಟ್ಟ',
'ಅಚ್ಚುಮನೆ',
'ಅಚ್ಚುಮರ',
'ಅಚ್ಚುಮಾಡು',
'ಅಚ್ಚುಮೆಚ್ಚು',
'ಅಚ್ಚುಮೊಳೆ',
'ಅಚ್ಚುವಡಿ',
'ಅಚ್ಚುವಿಡಿ',
'ಅಚ್ಚುಹಾಕು',
'ಅಚ್ಚುಳಾಯ್ತ',
'ಅಚ್ಚುಳಾಯ್ಲ',
'ಅಚ್ಚುಳಿಗ',
'ಅಚ್ಚುಳುಗ',
'ಅಚ್ಚೆಪಂಗ',
'ಅಚ್ಚೆಸಳ್',
'ಅಚ್ಚೆಸಳು',
'ಅಚ್ಛಭಲ್ಲ',
'ಅಚ್ಛಾಯತ್ವ',
'ಅಚ್ಛೋಟನ',
'ಅಚ್ಛೋದಕ',
'ಅಚ್ಯುತಕ',
'ಅಚ್ಯುತತ್ವ',
'ಅಚ್ಯುತೇಂದ್ರ',
'ಅಜಕವ',
'ಅಜಕಾವ',
'ಅಜಕುಕ್ಷಿ',
'ಅಜಗಂಧಿ',
'ಅಜಗರ',
'ಅಜಗವ',
'ಅಜಗಾವ',
'ಅಜಗುಜಿ',
'ಅಜಜಟೆ',
'ಅಜದಂಡೆ',
'ಅಜದೊಂಡೆ',
'ಅಜನಿಸು',
'ಅಜಪಾಳಿ',
'ಅಜಪೋತ',
'ಅಜಪ್ರಾಸ',
'ಅಜಬೀಜ',
'ಅಜಮಾಷಿ',
'ಅಜಮಾಸು',
'ಅಜಮೋದ',
'ಅಜರಂಧ್ರ',
'ಅಜವಾನ',
'ಅಜಶೃಂಗಿ',
'ಅಜಾಗರ',
'ಅಜಾಗೃತ',
'ಅಜಾಗ್ರತೆ',
'ಅಜಾಜೀವ',
'ಅಜಾಜೀವಿ',
'ಅಜಾನೇಯ',
'ಅಜಾಮೂಲ',
'ಅಜಿಗಿಜಿ',
'ಅಜಿವಾನ',
'ಅಜಿಹ್ಮಗ',
'ಅಜೀಗರ್ತ',
'ಅಜೀಬಾತು',
'ಅಜೀರ್ಣಾಂಶ',
'ಅಜುಗಿಜಿ',
'ಅಜುಮೋದ',
'ಅಜೈವಿಕ',
'ಅಜ್ಜಂಪಟ್ಟೆ',
'ಅಜ್ಜಗಳ್ಳ',
'ಅಜ್ಜಗಾಪು',
'ಅಜ್ಜಗಿತ್ತಿ',
'ಅಜ್ಜತನ',
'ಅಜ್ಜಪಜ್ಜ',
'ಅಜ್ಜಪಾಲು',
'ಅಜ್ಜಪಿಜ್ಜ',
'ಅಜ್ಜಮಂತು',
'ಅಜ್ಜಿಕಥೆ',
'ಅಜ್ಜಿಗಂಟು',
'ಅಜ್ಜಿಗಟ್ಟು',
'ಅಜ್ಜಿಗಡ್ಡೆ',
'ಅಜ್ಜಿಗಿಜ್ಜಿ',
'ಅಜ್ಜಿಗಿಡ',
'ಅಜ್ಜಿಬಜ್ಜಿ',
'ಅಜ್ಜಿಮುಳ್ಳು',
'ಅಜ್ಜುಹಾಕು',
'ಅಜ್ಜುಗಾರ',
'ಅಜ್ಜುಗುಜ್ಜು',
'ಅಜ್ಞತನ',
'ಅಜ್ಞಾನತೆ',
'ಅಜ್ಞಾನಾಂಧ',
'ಅಜ್ಞೇಯತೆ',
'ಅಟಕಟ',
'ಅಟಕಾವು',
'ಅಟಕಾಳಿ',
'ಅಟಕಿಸು',
'ಅಟಗೋಲು',
'ಅಟಟಾಂಗ',
'ಅಟಣಿಸು',
'ಅಟತಾಳ',
'ಅಟನೆಗೈ',
'ಅಟಪಟ',
'ಅಟಮಟ',
'ಅಟರುಷ',
'ಅಟರೂಷ',
'ಅಟವಟ',
'ಅಟವಣೆ',
'ಅಟವ್ಯವಿ',
'ಅಟಾಟೋಪ',
'ಅಟಾಪಿಸು',
'ಅಟೋಕಾಟ',
'ಅಟ್ಟಂಬಟ್ಟಂ',
'ಅಟ್ಟಕಳಿ',
'ಅಟ್ಟಕೞಿ',
'ಅಟ್ಟಗುಣ',
'ಅಟ್ಟಗೊಂಬು',
'ಅಟ್ಟಗೋಲು',
'ಅಟ್ಟಟ್ಟಿಕೆ',
'ಅಟ್ಟಡವಿ',
'ಅಟ್ಟಡಿಗೆ',
'ಅಟ್ಟಣಿಕೆ',
'ಅಟ್ಟಣಿಗೆ',
'ಅಟ್ಟತಾಳ',
'ಅಟ್ಟತೆಂಗು',
'ಅಟ್ಟದಾಟ',
'ಅಟ್ಟದೆಱೆ',
'ಅಟ್ಟಬೆಟ್ಟ',
'ಅಟ್ಟಲಾಟ',
'ಅಟ್ಟವಡಿ',
'ಅಟ್ಟವಣೆ',
'ಅಟ್ಟಹಾಸ',
'ಅಟ್ಟಳಿಗೆ',
'ಅಟ್ಟಳಿಸು',
'ಅಟ್ಟಾಟಿಕೆ',
'ಅಟ್ಟಾಡಿಸು',
'ಅಟ್ಟಾಮುಟ್ಟ',
'ಅಟ್ಟಾಮುಟ್ಟೆ',
'ಅಟ್ಟಾಲಕ',
'ಅಟ್ಟಾಲಿಕೆ',
'ಅಟ್ಟಾವಣೆ',
'ಅಟ್ಟಾಸುರ',
'ಅಟ್ಟಾಳಕ',
'ಅಟ್ಟಿದಿರು',
'ಅಟ್ಟಿಮಿಟ್ಟಿ',
'ಅಟ್ಟುಂಗಾಳೆ',
'ಅಟ್ಟುಂಬರಿ',
'ಅಟ್ಟುಂಬೞಿ',
'ಅಟ್ಟುಂಬೆಸ',
'ಅಟ್ಟುಂಬೊಲ',
'ಅಟ್ಟುಕಲ್ಲು',
'ಅಟ್ಟುಕಾಱ',
'ಅಟ್ಟುಕುಳಿ',
'ಅಟ್ಟುಗಲ',
'ಅಟ್ಟುಗಾಱ',
'ಅಟ್ಟುಗುಣಿ',
'ಅಟ್ಟುಗುಳಿ',
'ಅಟ್ಟುಗೂಳು',
'ಅಟ್ಟುಗೊಳ್ಳು',
'ಅಟ್ಟುಗೋಲು',
'ಅಟ್ಟುದೆರೆ',
'ಅಟ್ಟುಪಡು',
'ಅಟ್ಟುಪೆಱು',
'ಅಟ್ಟುಮಾಣಿ',
'ಅಟ್ಟುವಳಿ',
'ಅಟ್ಟುವೆಱು',
'ಅಟ್ಟುಳಿಗ',
'ಅಟ್ಟೆಕಟ್ಟು',
'ಅಟ್ಟೆಗಾಲು',
'ಅಟ್ಟೆಗೋಲು',
'ಅಟ್ಟೆಮುಟ್ಟೆ',
'ಅಟ್ಟೆಯಿಡು',
'ಅಟ್ಟೊಳಿಗೆ',
'ಅಟ್ಟೋಗರ',
'ಅಟ್ಠವಣೆ',
'ಅಟ್ರಾಕಣಿ',
'ಅಟ್ಲುಗದ್ದೆ',
'ಅಠವಣೆ',
'ಅಠಾವಣೆ',
'ಅಡಂಗಲ್',
'ಅಡಂಗಾಯ್ತ',
'ಅಡಂಗಿಸು',
'ಅಡಂಗೆಡೆ',
'ಅಡಂಗೊತ್ತು',
'ಅಡಂಬರ್',
'ಅಡಂಬಳ್ಳಿ',
'ಅಡಕತ್ತಿ',
'ಅಡಕಲು',
'ಅಡಕಿಲ್',
'ಅಡಕಿಲು',
'ಅಡಕೆಲೆ',
'ಅಡಕೊತ್ತಿ',
'ಅಡಕೊತ್ತು',
'ಅಡಕೋತು',
'ಅಡಗಟ್ಟು',
'ಅಡಗಡಿ',
'ಅಡಗಡು',
'ಅಡಗತ್ತಿ',
'ಅಡಗಾಳು',
'ಅಡಗಿಂಡೆ',
'ಅಡಗಿಸು',
'ಅಡಗುಣ್',
'ಅಡಗುಣಿ',
'ಅಡಗೂಟ',
'ಅಡಗೆಡೆ',
'ಅಡಗೊಚ್ಚು',
'ಅಡಗೊತ್ತು',
'ಅಡಗೋಂಟೆ',
'ಅಡಗೋಡೆ',
'ಅಡಚಣೆ',
'ಅಡಜಾತಿ',
'ಅಡಣಿಗೆ',
'ಅಡತಟ್ಟೆ',
'ಅಡತಡೆ',
'ಅಡತರ',
'ಅಡತಾಳ',
'ಅಡತಾಳೆ',
'ಅಡಥಳಾ',
'ಅಡದಿಗೆ',
'ಅಡದಿನ್',
'ಅಡದೋಱು',
'ಅಡನಾಡಿ',
'ಅಡನುಡಿ',
'ಅಡಪಾಳಿ',
'ಅಡಪಿಗ',
'ಅಡಪಿಡು',
'ಅಡಬಡೆ',
'ಅಡಬಳ',
'ಅಡಬೀಳು',
'ಅಡಮಾನ',
'ಅಡಮುಖ',
'ಅಡಮುಟ್ಟ',
'ಅಡಮುರಿ',
'ಅಡಯಾಲ',
'ಅಡರಾಟ',
'ಅಡರಾಸು',
'ಅಡರಾಳಿ',
'ಅಡರಿಚು',
'ಅಡರಿಸು',
'ಅಡಲ್ಸೀಗೆ',
'ಅಡವಟ್ಟೆ',
'ಅಡವಾಯ್',
'ಅಡವಾರ',
'ಅಡವಿಕ್ಕು',
'ಅಡವಿಡು',
'ಅಡವಿವೂ',
'ಅಡವುಳಿ',
'ಅಡವೆಟ್ಟ',
'ಅಡವೊಡ್ಡು',
'ಅಡವೊಯ್ಲು',
'ಅಡಸಟ್ಟೆ',
'ಅಡಸರಿ',
'ಅಡಸಲ',
'ಅಡಸಲು',
'ಅಡಸಾರ',
'ಅಡಸಾಲ',
'ಅಡಹಡಿ',
'ಅಡಹಾಯ್',
'ಅಡಹಾಯಿ',
'ಅಡಹಾಯ್ಸು',
'ಅಡಹಿಕೆ',
'ಅಡಹಿಕ್ಕು',
'ಅಡಹೊಗು',
'ಅಡಹೊಯ್ಲು',
'ಅಡಾಯಿಲ',
'ಅಡಾಯಿಸು',
'ಅಡಾಯುಧ',
'ಅಡಾವಣಿ',
'ಅಡಾವುಜ',
'ಅಡಾವುಡಿ',
'ಅಡಿಕಚ್ಚು',
'ಅಡಿಕಡ್ಡಿ',
'ಅಡಿಕಾಸು',
'ಅಡಿಕಿಲ್',
'ಅಡಿಕಿಲ',
'ಅಡಿಕಿಲಿ',
'ಅಡಿಕಿಲು',
'ಅಡಿಕೋಲು',
'ಅಡಿಗಂಟು',
'ಅಡಿಗಂಡಿ',
'ಅಡಿಗಚ್ಚು',
'ಅಡಿಗಟ್ಟು',
'ಅಡಿಗಡಿ',
'ಅಡಿಗಣ್ಣು',
'ಅಡಿಗಬು',
'ಅಡಿಗಬ್ಬು',
'ಅಡಿಗರ್ಚು',
'ಅಡಿಗಲ್',
'ಅಡಿಗಲ್ಲು',
'ಅಡಿಗಾಣ್',
'ಅಡಿಗಾರ',
'ಅಡಿಗಾಲು',
'ಅಡಿಗಿಡು',
'ಅಡಿಗೀಟು',
'ಅಡಿಗುಟ್ಟು',
'ಅಡಿಗುಪ್ಪೆ',
'ಅಡಿಗೂಂಟ',
'ಅಡಿಗೂಟ',
'ಅಡಿಗೆಜ್ಜೆ',
'ಅಡಿಗೆಡು',
'ಅಡಿಗೆಡೆ',
'ಅಡಿಗೆಪ್ಪು',
'ಅಡಿಗೆರೆ',
'ಅಡಿಗೆಲು',
'ಅಡಿಗೇಡು',
'ಅಡಿಗೊಳ್',
'ಅಡಿಗೋಂಟೆ',
'ಅಡಿಗೋಲು',
'ಅಡಿತಟ್ಟೆ',
'ಅಡಿತಳ',
'ಅಡಿತುಂಡು',
'ಅಡಿದಳ',
'ಅಡಿದಾಸ',
'ಅಡಿದೀಪ',
'ಅಡಿದೆಱೆ',
'ಅಡಿದೊಳೆ',
'ಅಡಿದೋಱು',
'ಅಡಿನೀರು',
'ಅಡಿನೆಲ',
'ಅಡಿಪಟ್ಟೆ',
'ಅಡಿಪಾಯ',
'ಅಡಿಬಂಡ',
'ಅಡಿಬೀಳು',
'ಅಡಿಮಂಡೆ',
'ಅಡಿಮಣೆ',
'ಅಡಿಮದ್ದು',
'ಅಡಿಮಲೆ',
'ಅಡಿಮಾಡು',
'ಅಡಿಮುಖ',
'ಅಡಿಮುಟ್ಟ',
'ಅಡಿಮುಟ್ಟು',
'ಅಡಿಮುಡಿ',
'ಅಡಿಮೆಟ್ಟು',
'ಅಡಿಮೇಲು',
'ಅಡಿಯಳ್',
'ಅಡಿಯೞಿ',
'ಅಡಿಯಾಡು',
'ಅಡಿಯಾಳು',
'ಅಡಿಯಿಟ್ಟಿ',
'ಅಡಿಯಿಡು',
'ಅಡಿಯೂಟ',
'ಅಡಿಯೂಡು',
'ಅಡಿಯೂರು',
'ಅಡಿಯೂಱು',
'ಅಡಿಯೆತ್ತು',
'ಅಡಿಯೇಳ್',
'ಅಡಿಯೊತ್ತು',
'ಅಡಿರಜ',
'ಅಡಿರಾಶಿ',
'ಅಡಿರಾಸಿ',
'ಅಡಿರುಚಿ',
'ಅಡಿವಜ್ಜೆ',
'ಅಡಿವಡೆ',
'ಅಡಿವಣಿ',
'ಅಡಿವಣೆ',
'ಅಡಿವಳಿ',
'ಅಡಿವಿಡಿ',
'ಅಡಿವೊತ್ತು',
'ಅಡಿಸಡಿ',
'ಅಡಿಸೇರು',
'ಅಡಿಸ್ಥಳ',
'ಅಡಿಹತ್ತು',
'ಅಡಿಹುಡಿ',
'ಅಡಿಹೊಟ್ಟೆ',
'ಅಡೀಸೇರು',
'ಅಡುಕಲ್',
'ಅಡುಕಳ',
'ಅಡುಕುಳಿ',
'ಅಡುಗಬು',
'ಅಡುಗಬ್ಬು',
'ಅಡುಗರಿ',
'ಅಡುಗರ್ಬು',
'ಅಡುಗಲ',
'ಅಡುಗಾಯಿ',
'ಅಡುಗಿಗ',
'ಅಡುಗೂರ್',
'ಅಡುಗೂಲಿ',
'ಅಡುಗೂಳ್',
'ಅಡುಗೂೞ್',
'ಅಡುಪಾಡು',
'ಅಡುಪಾತ್ರೆ',
'ಅಡುಪಾಯ',
'ಅಡುಪಾಯಿ',
'ಅಡುಮದ್ದು',
'ಅಡುಮನೆ',
'ಅಡುವಲ',
'ಅಡುವಲಿ',
'ಅಡುವಳ',
'ಅಡುವಳ್ಳ',
'ಅಡುಸೋಗೆ',
'ಅಡೆಕಟ್ಟು',
'ಅಡೆಕಲು',
'ಅಡೆಕುಣಿ',
'ಅಡೆಕೋಲು',
'ಅಡೆಗಟ್ಟು',
'ಅಡೆಗಲ್',
'ಅಡೆಗಲ್ಲು',
'ಅಡೆಗೆಡೆ',
'ಅಡೆತಡೆ',
'ಅಡೆತಿಪ್ಪೆ',
'ಅಡೆತೆಗೆ',
'ಅಡೆದೆಱೆ',
'ಅಡೆಪಟ್ಟಿ',
'ಅಡೆಮನೆ',
'ಅಡೆಯಾಳ',
'ಅಡೆಯೊಡ್ಡು',
'ಅಡೆಯೊತ್ತು',
'ಅಡೆರಾಶಿ',
'ಅಡೆವಣ',
'ಅಡೆವೊತ್ತು',
'ಅಡೆಹಬ್ಬ',
'ಅಡೆಹಾಕು',
'ಅಡ್ಡಂಗಟ್ಟು',
'ಅಡ್ಡಂಗೆಡೆ',
'ಅಡ್ಡಂತಿಡ್ಡ',
'ಅಡ್ಡಂಪಾಱು',
'ಅಡ್ಡಂಬರ್',
'ಅಡ್ಡಂಬರಿ',
'ಅಡ್ಡಂಬಾಱು',
'ಅಡ್ಡಂಬೀೞ್',
'ಅಡ್ಡಕಂಬಿ',
'ಅಡ್ಡಕಟ್ಟು',
'ಅಡ್ಡಕಟ್ಟೆ',
'ಅಡ್ಡಕಥೆ',
'ಅಡ್ಡಕಲ್ಲು',
'ಅಡ್ಡಕಾಲು',
'ಅಡ್ಡಕೀಲಿ',
'ಅಡ್ಡಕುಂಟೆ',
'ಅಡ್ಡಕೂಸು',
'ಅಡ್ಡಕೆಯ್',
'ಅಡ್ಡಕೊಯ್ತ',
'ಅಡ್ಡಕೋಲು',
'ಅಡ್ಡಗಂಬಿ',
'ಅಡ್ಡಗಟ್ಟು',
'ಅಡ್ಡಗಟ್ಟೆ',
'ಅಡ್ಡಗಣೆ',
'ಅಡ್ಡಗಲ',
'ಅಡ್ಡಗಲು',
'ಅಡ್ಡಗಲ್ಲು',
'ಅಡ್ಡಗಾಲು',
'ಅಡ್ಡಗಿಸು',
'ಅಡ್ಡಗೀಚು',
'ಅಡ್ಡಗೀಟು',
'ಅಡ್ಡಗೀರು',
'ಅಡ್ಡಗೂಸು',
'ಅಡ್ಡಗೆಡೆ',
'ಅಡ್ಡಗೆಯ್',
'ಅಡ್ಡಗೆರೆ',
'ಅಡ್ಡಗೆಲ್ಲು',
'ಅಡ್ಡಗೊಂಬು',
'ಅಡ್ಡಗೋಡೆ',
'ಅಡ್ಡಗೋಲು',
'ಅಡ್ಡಚಾಟು',
'ಅಡ್ಡಚಾಳಿ',
'ಅಡ್ಡಚಿನ್ನ',
'ಅಡ್ಡಚ್ಛೇದ',
'ಅಡ್ಡಜಡ್ಡು',
'ಅಡ್ಡಜಾತಿ',
'ಅಡ್ಡಜ್ಞಾನ',
'ಅಡ್ಡಣಾಯ್ತ',
'ಅಡ್ಡಣಿಗೆ',
'ಅಡ್ಡತರ',
'ಅಡ್ಡತಲೆ',
'ಅಡ್ಡತಳಿ',
'ಅಡ್ಡತಾಕು',
'ಅಡ್ಡತಿಡ್ಡ',
'ಅಡ್ಡತೊಲೆ',
'ಅಡ್ಡತೋಳು',
'ಅಡ್ಡದಂಡಿ',
'ಅಡ್ಡದಂಡೆ',
'ಅಡ್ಡದಲೆ',
'ಅಡ್ಡದಾರಿ',
'ಅಡ್ಡದಿಮ್ಮಿ',
'ಅಡ್ಡದುಡ್ಡು',
'ಅಡ್ಡದೊಡ್ಡ',
'ಅಡ್ಡದೊಲೆ',
'ಅಡ್ಡದೋಳು',
'ಅಡ್ಡದೋಱು',
'ಅಡ್ಡನಗೆ',
'ಅಡ್ಡನಾಡಿ',
'ಅಡ್ಡನಾಮ',
'ಅಡ್ಡನಿಗೆ',
'ಅಡ್ಡನುಡಿ',
'ಅಡ್ಡಪಂಕ್ತಿ',
'ಅಡ್ಡಪಂತಿ',
'ಅಡ್ಡಪಟ್ಟಿ',
'ಅಡ್ಡಪಳಿ',
'ಅಡ್ಡಪುರಿ',
'ಅಡ್ಡಪ್ರಶ್ನೆ',
'ಅಡ್ಡಬಟ್ಟೆ',
'ಅಡ್ಡಬಡ್ಡಿ',
'ಅಡ್ಡಬರ್',
'ಅಡ್ಡಬರು',
'ಅಡ್ಡಬಾಯಿ',
'ಅಡ್ಡಬಾರು',
'ಅಡ್ಡಬೀಳ್',
'ಅಡ್ಡಬೀಳು',
'ಅಡ್ಡಬೂದಿ',
'ಅಡ್ಡಬೊಟ್ಟು',
'ಅಡ್ಡಮರ',
'ಅಡ್ಡಮಳೆ',
'ಅಡ್ಡಮಾತು',
'ಅಡ್ಡಮಾರ್ಗ',
'ಅಡ್ಡಮಿರ್',
'ಅಡ್ಡಮುಖ',
'ಅಡ್ಡಮುಡಿ',
'ಅಡ್ಡಮೊಗ',
'ಅಡ್ಡಮೋರೆ',
'ಅಡ್ಡಮೋಱೆ',
'ಅಡ್ಡಯಿಸು',
'ಅಡ್ಡಯ್ಸುಹ',
'ಅಡ್ಡರಸು',
'ಅಡ್ಡರಸ್ತೆ',
'ಅಡ್ಡರಾಸು',
'ಅಡ್ಡರುಚಿ',
'ಅಡ್ಡಲಾಗಿ',
'ಅಡ್ಡಲಾಗು',
'ಅಡ್ಡಲಾನ್',
'ಅಡ್ಡಲಸ',
'ಅಡ್ಡಲಾಕು',
'ಅಡ್ಡಲಿಕ್ಕು',
'ಅಡ್ಡಲಿಡು',
'ಅಡ್ಡಲೋವೆ',
'ಅಡ್ಡವಟ್ಟೆ',
'ಅಡ್ಡವಡೆ',
'ಅಡ್ಡವಣೆ',
'ಅಡ್ಡವರ್',
'ಅಡ್ಡವರಿ',
'ಅಡ್ಡವಳಿ',
'ಅಡ್ಡವಾಯ್',
'ಅಡ್ಡವಾರಿ',
'ಅಡ್ಡವಿಸು',
'ಅಡ್ಡವಿಱಿ',
'ಅಡ್ಡವೆಟ್ಟ',
'ಅಡ್ಡವೇಷ',
'ಅಡ್ಡವೇಳೆ',
'ಅಡ್ಡವೊಡ್ಡು',
'ಅಡ್ಡವೊಯ್ಲು',
'ಅಡ್ಡವೊಱೆ',
'ಅಡ್ಡಸರ',
'ಅಡ್ಡಸರ್ಪ',
'ಅಡ್ಡಸಲ',
'ಅಡ್ಡಸಾರ್ಚು',
'ಅಡ್ಡಸಾಲು',
'ಅಡ್ಡಸುತ್ತು',
'ಅಡ್ಡಸುಳಿ',
'ಅಡ್ಡಸುೞಿ',
'ಅಡ್ಡಸೇರು',
'ಅಡ್ಡಸೋಗು',
'ಅಡ್ಡಹಚ್ಚು',
'ಅಡ್ಡಹಾಕು',
'ಅಡ್ಡಹಾದಿ',
'ಅಡ್ಡಹಾಯ್',
'ಅಡ್ಡಹೇರು',
'ಅಡ್ಡಹೊಗು',
'ಅಡ್ಡಹೊತ್ತು',
'ಅಡ್ಡಹೊಯ್',
'ಅಡ್ಡಹೊಱೆ',
'ಅಡ್ಡಳತೆ',
'ಅಡ್ಡಾಡಿಸು',
'ಅಡ್ಡಾತಿಡ್ಡ',
'ಅಡ್ಡಾತಿಡ್ಡಿ',
'ಅಡ್ಡಾದಿಡ್ಡಿ',
'ಅಡ್ಡಾದುಡ್ಡಿ',
'ಅಡ್ಡಾಯದ',
'ಅಡ್ಡಾಯಿದ',
'ಅಡ್ಡಾಯಿಲ',
'ಅಡ್ಡಾಯುಧ',
'ಅಡ್ಡಾವುಜ',
'ಅಡ್ಡಿತರು',
'ಅಡ್ಡಿಮಾಡು',
'ಅಡ್ಡಿಸಡು',
'ಅಡ್ಡಿಸಡ್ಡಿ',
'ಅಡ್ಡುಗುಲು',
'ಅಡ್ಡೆಡಬ್ಬೆ',
'ಅಡ್ಡೆವೊಱೆ',
'ಅಡ್ಡೆಸಗು',
'ಅಡ್ಡೆಸುಗೆ',
'ಅಡ್ಡೆಹೊರು',
'ಅಡ್ಡೆಳೆತ',
'ಅಡ್ಡೊಕ್ಕಲು',
'ಅಡ್ಡೊರಗು',
'ಅಡ್ವೊಕೇಟು',
'ಅಣಕಾಟ',
'ಅಣಕಿಗ',
'ಅಣಕಿಸು',
'ಅಣಕುವೆ',
'ಅಣಗಿಸು',
'ಅಣಗೊತ್ತು',
'ಅಣದಿಗೆ',
'ಅಣಬಾರ',
'ಅಣಮಣ್ಣೆ',
'ಅಣಿಕಟ್ಟು',
'ಅಣಿಕಲ್ಲು',
'ಅಣಿಕಾರ',
'ಅಣಿಕೀಲು',
'ಅಣಿಕೂಸು',
'ಅಣಿಕೋಲು',
'ಅಣಿಗಂಟು',
'ಅಣಿಗಟ್ಟು',
'ಅಣಿಗಾರ',
'ಅಣಿಗಿಡು',
'ಅಣಿಗೆಡು',
'ಅಣಿಗೆಯ್',
'ಅಣಿಚಿಲ್',
'ಅಣಿಜಾಣ',
'ಅಣಿಜಾಣೆ',
'ಅಣಿತುಳಿ',
'ಅಣಿದಪ್ಪು',
'ಅಣಿದಬ್ಬೆ',
'ಅಣಿದಾರ',
'ಅಣಿದೋಱು',
'ಅಣಿನೆರೆ',
'ಅಣಿಪಾಡು',
'ಅಣಿಮಾಡು',
'ಅಣಿಮಿಣಿ',
'ಅಣಿಮೀನು',
'ಅಣಿಮುಱಿ',
'ಅಣಿಮೆಟ್ಟು',
'ಅಣಿಯರ',
'ಅಣಿಯರಂ',
'ಅಣಿವರಿ',
'ಅಣಿವಿಣ',
'ಅಣಿವಿಣಿ',
'ಅಣಿಹಗ್ಗ',
'ಅಣಿಹಚ್ಚು',
'ಅಣುಕೇಳು',
'ಅಣುಗಾಳ್',
'ಅಣುಗಿಱಿ',
'ಅಣುಗುಂಡು',
'ಅಣುಗೂಸು',
'ಅಣುಜೀವಿ',
'ಅಣುತೂಕ',
'ಅಣುತ್ತರೆ',
'ಅಣುಪ್ರಾಣಿ',
'ಅಣುಬಾಂಬು',
'ಅಣುಬ್ರತ',
'ಅಣುಭಾರ',
'ಅಣುಮಣಿ',
'ಅಣುವಂತ',
'ಅಣುವಾದ',
'ಅಣುವಾದಿ',
'ಅಣುವ್ರತ',
'ಅಣುಶಕ್ತಿ',
'ಅಣುಸಸ್ಯ',
'ಅಣುಸೂತ್ರ',
'ಅಣೂತ್ತರೆ',
'ಅಣೆಕಟ್ಟು',
'ಅಣೆಕಟ್ಟೆ',
'ಅಣೆಕಲ್ಲು',
'ಅಣೆಗಟ್ಟು',
'ಅಣೆಗಡಿ',
'ಅಣೆಯಿಡು',
'ಅಣೆಯಿಸು',
'ಅಣ್ಕೆಗೆಯ್',
'ಅಣ್ಣಗಾರ',
'ಅಣ್ಣಲೆಗ',
'ಅಣ್ಣವಳಿ',
'ಅಣ್ಣಾಲಿಗೆ',
'ಅಣ್ಣೆಕಲ್',
'ಅಣ್ಣೆಕಲ್ಲು',
'ಅಣ್ಣೆನೂಲು',
'ಅಣ್ಣೆಪಲ್ಯ',
'ಅಣ್ಣೆವಾಲ್',
'ಅಣ್ಣೆವಾಲು',
'ಅಣ್ಣೆಸೊಪ್ಪು',
'ಅಣ್ಪುಗೆಯ್',
'ಅಣ್ಪುವಿಡಿ',
'ಅಣ್ಮುಕಾತಿ',
'ಅಣ್ಮುಕಾಱ',
'ಅಣ್ಮುಗಾತಿ',
'ಅಣ್ಮುಗಿಡು',
'ಅಣ್ಮುಗುಂದು',
'ಅಣ್ಮುಟ್ಟಾಗು',
'ಅತರ್ಕಿತ',
'ಅತಾಂತ್ರಿಕ',
'ಅತಾರ್ಕಿಕ',
'ಅತಾಲವ್ಯ',
'ಅತಿಕರ',
'ಅತಿಕರ್ಮ',
'ಅತಿಕರ್ಮಿ',
'ಅತಿಕಾಯ',
'ಅತಿಕಾಯೆ',
'ಅತಿಕಾರ',
'ಅತಿಕಾರಿ',
'ಅತಿಕಾರ್ಶ್ಯ',
'ಅತಿಕೃತಿ',
'ಅತಿಕ್ರಮ',
'ಅತಿಕ್ರಮಿ',
'ಅತಿಕ್ರಮ್ಯ',
'ಅತಿಕ್ರಾಂತ',
'ಅತಿಗಂಡ',
'ಅತಿಗಂಧ',
'ಅತಿಗಳೆ',
'ಅತಿಗೞಿ',
'ಅತಿಚರೆ',
'ಅತಿಚಾರ',
'ಅತಿಚ್ಛತ್ರ',
'ಅತಿಚ್ಛತ್ರೆ',
'ಅತಿಜಾಣ',
'ಅತಿಜಾಣೆ',
'ಅತಿಜೋಲಿ',
'ಅತಿತಪ್ತ',
'ಅತಿತಾರ',
'ಅತಿತೃಷ್ಣೆ',
'ಅತಿದಾಹ',
'ಅತಿದೀನ',
'ಅತಿದೀನೆ',
'ಅತಿಧೃತಿ',
'ಅತಿನಯ',
'ಅತಿನಿದ್ರೆ',
'ಅತಿನೀಲ',
'ಅತಿನೇತ್ರೆ',
'ಅತಿಪಾಡು',
'ಅತಿಪಾತ',
'ಅತಿಬಜೆ',
'ಅತಿಬಲ',
'ಅತಿಬಲ್ಪು',
'ಅತಿಬಳ',
'ಅತಿಭವ',
'ಅತಿಮಾತ್ರ',
'ಅತಿಮಾನ',
'ಅತಿಮಾರ್ಗ',
'ಅತಿಮಿತಿ',
'ಅತಿಮುಕ್ತ',
'ಅತಿಮೂತ್ರ',
'ಅತಿಮೋಹ',
'ಅತಿರಕ್ತ',
'ಅತಿರಕ್ತೆ',
'ಅತಿರಥ',
'ಅತಿರಮ್ಯ',
'ಅತಿರಸ',
'ಅತಿರಾಗ',
'ಅತಿರಾಗಿ',
'ಅತಿರಾತ್ರ',
'ಅತಿರಾವ',
'ಅತಿರಿಕ್ತ',
'ಅತಿರುಜೆ',
'ಅತಿರೂಢ',
'ಅತಿರೇಕ',
'ಅತಿಲೇಕ',
'ಅತಿವರ್ತಿ',
'ಅತಿವಸ್ತು',
'ಅತಿವಳೆ',
'ಅತಿವಾಕ್ಯ',
'ಅತಿವಾದ',
'ಅತಿವಾದಿ',
'ಅತಿವಿಷೆ',
'ಅತಿವೃತ್ತ',
'ಅತಿವೃಷ್ಟಿ',
'ಅತಿವೇಲ',
'ಅತಿವ್ಯಕ್ತ',
'ಅತಿವ್ಯಾಪ್ತಿ',
'ಅತಿಶಯ',
'ಅತಿಶೀತ',
'ಅತಿಸಾಂಗ',
'ಅತಿಸಾರ',
'ಅತಿಸುಪ್ತಿ',
'ಅತಿಸೂಕ್ಷ್ಮ',
'ಅತಿಸ್ನೇಹ',
'ಅತೀಂದ್ರಿಯ',
'ಅತೀತತೆ',
'ಅತುರಾಕೆ',
'ಅತುಲಿತ',
'ಅತುಳಿತ',
'ಅತೃಪ್ತತೆ',
'ಅತೋನಾತ',
'ಅತ್ತಪರ',
'ಅತ್ತರಣ',
'ಅತ್ತರಿಸು',
'ಅತ್ತಲಗ್ಗ',
'ಅತ್ತಳಗ',
'ಅತ್ತಳಗಂ',
'ಅತ್ತಿಕಾಯ್',
'ಅತ್ತಿಕಾಯಿ',
'ಅತ್ತಿರಸ',
'ಅತ್ತಿವಣ್',
'ಅತ್ತಿಶಯ',
'ಅತ್ತೆತನ',
'ಅತ್ತೆಮನೆ',
'ಅತ್ಯಂತೀನ',
'ಅತ್ಯಗತ್ಯ',
'ಅತ್ಯಧಮ',
'ಅತ್ಯಧಿಕ',
'ಅತ್ಯಪೂರ್ವ',
'ಅತ್ಯಲಘು',
'ಅತ್ಯಾಕಾರ',
'ಅತ್ಯಾಕಾಶ',
'ಅತ್ಯಾಚಾರ',
'ಅತ್ಯಾಚಾರಿ',
'ಅತ್ಯಾದರ',
'ಅತ್ಯಾಧಾನ',
'ಅತ್ಯಾಯತ',
'ಅತ್ಯಾಯಾಮ',
'ಅತ್ಯಾಶ್ಚರ್ಯ',
'ಅತ್ಯಾಸನ್ನ',
'ಅತ್ಯಾಸುರ',
'ಅತ್ಯಾಸ್ವಾದಿ',
'ಅತ್ಯಾಹಿತ',
'ಅತ್ಯುಜ್ಜ್ವಲ',
'ಅತ್ಯುಜ್ವಲ',
'ಅತ್ಯುತ್ಕಟ',
'ಅತ್ಯುತ್ಕೃಷ್ಟ',
'ಅತ್ಯುತ್ತಮ',
'ಅತ್ಯುತ್ಸಾಹ',
'ಅತ್ಯುತ್ಸಾಹಿ',
'ಅತ್ಯುತ್ಸುಕ',
'ಅತ್ಯುದ್ದಾಮ',
'ಅತ್ಯುನ್ನತ',
'ಅತ್ಯುಪಧ',
'ಅತ್ಯೂರ್ಜಿತ',
'ಅತ್ರಿಪುತ್ರ',
'ಅತ್ವವಿಧಿ',
'ಅಥರ್ವಣ',
'ಅದಂತತೆ',
'ಅದಂತ್ಯತೆ',
'ಅದಕಿಸು',
'ಅದಕ್ಷತೆ',
'ಅದಗುಂತಿ',
'ಅದಟತೆ',
'ಅದಟಲೆ',
'ಅದಟಾನು',
'ಅದಟಿಗ',
'ಅದಮಾನಿ',
'ಅದಮ್ಯತೆ',
'ಅದರಟ್ಟು',
'ಅದರಿಂದ',
'ಅದರಿಸು',
'ಅದರ್ಶನ',
'ಅದಲಿಸು',
'ಅದಱಿಂದ',
'ಅದಾತಾರ',
'ಅದಾಲತ',
'ಅದಾಲತು',
'ಅದಾಲತ್ತು',
'ಅದಾವತಿ',
'ಅದಾವತ್ತು',
'ಅದಿಗುದಿ',
'ಅದಿತಿಜ',
'ಅದಿಬದಿ',
'ಅದಿಯರ್',
'ಅದಿರಾಟ',
'ಅದಿರಾಸು',
'ಅದಿರಿಸು',
'ಅದಿರ್ಗಂಚಿ',
'ಅದಿರ್ಗಂತಿ',
'ಅದಿರ್ಗುಂತೆ',
'ಅದಿರ್ಗೋಲ್',
'ಅದಿರ್ಮುತ್ತೆ',
'ಅದಿವರಿ',
'ಅದೀಪನ',
'ಅದುಕುಳಿ',
'ಅದುಗಿಸು',
'ಅದುಬುತ',
'ಅದುಭುತ',
'ಅದುರುಗ',
'ಅದುವದು',
'ಅಧೃಢತೆ',
'ಅದೃಶ್ಯತೆ',
'ಅದೆಕೊಳ್',
'ಅದೆಬದೆ',
'ಅದ್ದಕಾಣಿ',
'ಅದ್ದಗಣಿ',
'ಅದ್ದಗಾಣಿ',
'ಅದ್ದಯಿಸು',
'ಅದ್ದರಣೆ',
'ಅದ್ದರಿಸು',
'ಅದ್ದಲಿಸು',
'ಅದ್ದಳಂಗೈ',
'ಅದ್ದಳಿಸು',
'ಅದ್ದುಗಾಣಿ',
'ಅದ್ಧಾಪಲ್ಯ',
'ಅದ್ಭುತತೆ',
'ಅದ್ಯತನ',
'ಅದ್ಯಾವತ್ತು',
'ಅದ್ರವ್ಯತೆ',
'ಅದ್ರಿಕನ್ಯೆ',
'ಅದ್ರಿಕೀಲೆ',
'ಅದ್ರಿಜಾತ',
'ಅದ್ರಿಧರ',
'ಅದ್ರಿರಾಜ',
'ಅದ್ರಿಶತ್ರು',
'ಅದ್ರಿಸಾರ',
'ಅದ್ಲೆಪಟ್ಟೆ',
'ಅದ್ವಂತಿಕೆ',
'ಅದ್ವಯಿತ',
'ಅದ್ವಿತಯ',
'ಅದ್ವಿತೀಯ',
'ಅದ್ವಿತೀಯೆ',
'ಅಧಃಕೃತ',
'ಅಧಃಕ್ಷಿಪ್ತ',
'ಅಧಃಕ್ಷೇಪ',
'ಅಧಃಪಾತ',
'ಅಧಃಸ್ಥಲ',
'ಅಧಃಸ್ಥಳ',
'ಅಧಃಸ್ಥಿತ',
'ಅಧನದ',
'ಅಧಮರ್ಣ',
'ಅಧರತೆ',
'ಅಧರಿತ',
'ಅಧರಿಸು',
'ಅಧರೋಷ್ಠ',
'ಅಧಶ್ಚರ',
'ಅಧಸ್ತನ',
'ಅಧಸ್ಸ್ಥಲ',
'ಅಧಸ್ಸ್ಥಳ',
'ಅಧಾರ್ಮಿಕ',
'ಅಧಿಕತೆ',
'ಅಧಿಕರ',
'ಅಧಿಕರ್ತ',
'ಅಧಿಕರ್ತೆ',
'ಅಧಿಕರ್ಧಿ',
'ಅಧಿಕರ್ಮ',
'ಅಧಿಕಾಂಗ',
'ಅಧಿಕಾಂಶ',
'ಅಧಿಕಾರ',
'ಅಧಿಕಾರಿ',
'ಅಧಿಕಾರ್ತಿ',
'ಅಧಿಕೃತ',
'ಅಧಿಕೇಂದ್ರ',
'ಅಧಿಕೋಷ್ಣ',
'ಅಧಿಕ್ರಮ',
'ಅಧಿಕ್ಷಿಪ್ತ',
'ಅಧಿಕ್ಷೇಪ',
'ಅಧಿಗತ',
'ಅಧಿಗಮ',
'ಅಧಿಗೃಹ',
'ಅಧಿಚಕ್ರ',
'ಅಧಿಜಿಹ್ವೆ',
'ಅಧಿತ್ಯಕ',
'ಅಧಿತ್ಯಕೆ',
'ಅಧಿದೇವಿ',
'ಅಧಿದೈವ',
'ಅಧಿನಾಥ',
'ಅಧಿನೇತೃ',
'ಅಧಿಪಟ್ಟ',
'ಅಧಿಪತಿ',
'ಅಧಿಪತ್ರ',
'ಅಧಿಪಾಲ',
'ಅಧಿಭಾರ',
'ಅಧಿಭಾಷೆ',
'ಅಧಿಭೂತ',
'ಅಧಿಭೌಮ',
'ಅಧಿಮಾಂಸ',
'ಅಧಿಮಾಸ',
'ಅಧಿಯಜ್ಞ',
'ಅಧಿರಾಜ',
'ಅಧಿರೂಢ',
'ಅಧಿವಾಸ',
'ಅಧಿವಿನ್ನೆ',
'ಅಧಿವೃಕ್ಕ',
'ಅಧಿವೃತ್ತ',
'ಅಧಿವೇತ್ತ',
'ಅಧಿಷ್ಠಾತ್ರಿ',
'ಅಧಿಷ್ಠಾನ',
'ಅಧಿಷ್ಠಿತ',
'ಅಧಿಷ್ಠಿಸು',
'ಅಧೀಕ್ಷಕ',
'ಅಧೀಕ್ಷಣ',
'ಅಧೀನತೆ',
'ಅಧೀನಸ್ಥ',
'ಅಧೀನಸ್ಥೆ',
'ಅಧೀರತೆ',
'ಅಧೀವಾಸ',
'ಅಧೀಶ್ವರ',
'ಅಧೀಶ್ವರಿ',
'ಅಧೋಂಶುಕ',
'ಅಧೋಕ್ಷಜ',
'ಅಧೋಗಂತೃ',
'ಅಧೋಗತ',
'ಅಧೋಗತಿ',
'ಅಧೋಗಾಮಿ',
'ಅಧೋಬಿಂದು',
'ಅಧೋಭೋಗ',
'ಅಧೋಭೂಮಿ',
'ಅಧೋಮಾಯೆ',
'ಅಧೋಮಾರ್ಗ',
'ಅಧೋಮುಖ',
'ಅಧೋಮುಖಿ',
'ಅಧೋಮೂಲಿ',
'ಅಧೋರೋಮ',
'ಅಧೋಲೋಕ',
'ಅಧೋವರ್ದಿ',
'ಅಧೋವಾಯು',
'ಅಧ್ಯಕ್ಷತೆ',
'ಅಧ್ಯಕ್ಷಿಣಿ',
'ಅಧ್ಯಕ್ಷೀಯ',
'ಅಧ್ಯಯನ',
'ಅಧ್ಯರ್ಪಣ',
'ಅಧ್ಯಾತ್ಮಕ',
'ಅಧ್ಯಾತ್ಮಿಕ',
'ಅಧ್ಯಾದೇಶ',
'ಅಧ್ಯಾಪಕ',
'ಅಧ್ಯಾಪಕಿ',
'ಅಧ್ಯಾಪನ',
'ಅಧ್ಯಾಪಿಕೆ',
'ಅಧ್ಯಾಪಿಸು',
'ಅಧ್ಯಾರೋಪ',
'ಅಧ್ಯಾಸನ',
'ಅಧ್ಯಾಸಿತ',
'ಅಧ್ಯಾಹಾರ',
'ಅಧ್ಯಾಹಾರ್ಯ',
'ಅಧ್ಯಾಹೃತ',
'ಅಧ್ಯುಷಿತ',
'ಅಧ್ಯೇಷಣೆ',
'ಅಧ್ರುವತ್ವ',
'ಅಧ್ವಕೃತಿ',
'ಅಧ್ವನೀನ',
'ಅಧ್ವರಿಯ',
'ಅಧ್ವಶ್ರಮ',
'ಅಧ್ವಸಹ',
'ಅನಂಗತೆ',
'ಅನಂಗಾಗ್ನಿ',
'ಅನಂಗೇಭ',
'ಅನಂಜನ',
'ಅನಂಜನೆ',
'ಅನಂತತೆ',
'ಅನಂತತ್ವ',
'ಅನಂತರ',
'ಅನಂತರಂ',
'ಅನಂತಾಂಶ',
'ಅನಂತಾಖ್ಯ',
'ಅನಂತೇಶ',
'ಅನಕರ',
'ಅನಕ್ಷರ',
'ಅನಗಡ',
'ಅನಗತ್ಯ',
'ಅನಗಾರ',
'ಅನಡುಹ',
'ಅನಡುಹಿ',
'ಅನಡ್ವಾಹ',
'ಅನಡ್ವಾಹಿ',
'ಅನತಂತ್ರ',
'ಅನಧೀನ',
'ಅನಧ್ಯಕ್ಷ',
'ಅನಧ್ಯಾಯ',
'ಅನನಸ',
'ಅನನಾಸು',
'ಅನನೀಕ',
'ಅನನ್ಯಜ',
'ಅನನ್ಯತೆ',
'ಅನನ್ಯತ್ವ',
'ಅನನ್ವಯ',
'ಅನನ್ವಿತ',
'ಅನಪತ್ಯ',
'ಅನಪತ್ಯೆ',
'ಅನಪಾಯ',
'ಅನಪಾಯಿ',
'ಅನಪೇಕ್ಷೆ',
'ಅನಪೇಕ್ಷ್ಯ',
'ಅನಪೇತ',
'ಅನಪೇಯ',
'ಅನಭಿಜ್ಞ',
'ಅನಭಿಜ್ಞೆ',
'ಅನಭ್ಯಸ್ಥ',
'ಅನಭ್ಯಾಸ',
'ಅನಮ್ಯತೆ',
'ಅನರೇಂದ್ರ',
'ಅನರ್ಗಲ',
'ಅನರ್ಗಳ',
'ಅನರ್ಥಕ',
'ಅನರ್ಥಕ್ಯ',
'ಅನರ್ಪಿತ',
'ಅನರ್ಹತೆ',
'ಅನಲಜೆ',
'ಅನಲಾಕ್ಷ',
'ಅನಲಾರ್ಚಿ',
'ಅನಲಾಸ್ತ್ರ',
'ಅನಲೋಷ್ಮ',
'ಅನವದ್ಯ',
'ಅನವದ್ಯೆ',
'ಅನವಧಿ',
'ಅನವಲ್ಯ',
'ಅನವಶ್ಯ',
'ಅನವಸ್ಥೆ',
'ಅನವ್ರಯ',
'ಅನಶನ',
'ಅನಶ್ವರ',
'ಅನಸೂಯ',
'ಅನಸೂಯೆ',
'ಅನಸ್ತಿತ್ವ',
'ಅನಹತ',
'ಅನಹಿತ',
'ಅನಾಕಾರ',
'ಅನಾಕುಲ',
'ಅನಾಕುಲಂ',
'ಅನಾಕುಲೆ',
'ಅನಾಕುಳ',
'ಅನಾಕುಳಂ',
'ಅನಾಕುಳೆ',
'ಅನಾಖ್ಯೇಯ',
'ಅನಾಗತ',
'ಅನಾಗತಂ',
'ಅನಾಘಾತ',
'ಅನಾಚಾರ',
'ಅನಾಚಾರಿ',
'ಅನಾಚಾರೆ',
'ಅನಾತಪ',
'ಅನಾತುಮ',
'ಅನಾತ್ಮಜ್ಞ',
'ಅನಾಥತ್ವ',
'ಅನಾದರ',
'ಅನಾದರ್ಶ',
'ಅನಾದಿಷ್ಟ',
'ಅನಾದೃತ',
'ಅನಾದೇಯ',
'ಅನಾದೇಶ',
'ಅನಾಧಾರ',
'ಅನಾಧೇಯ',
'ಅನಾನಂದ',
'ಅನಾನಸ',
'ಅನಾನಸು',
'ಅನಾನಾಸು',
'ಅನಾಪತ್ತಿ',
'ಅನಾಮಕ',
'ಅನಾಮತ',
'ಅನಾಮತು',
'ಅನಾಮತ್ತು',
'ಅನಾಮಯ',
'ಅನಾಮಿಕ',
'ಅನಾಮಿಕೆ',
'ಅನಾಯಕ',
'ಅನಾಯತ',
'ಅನಾಯಸ',
'ಅನಾಯಾಸ',
'ಅನಾಯುಧ',
'ಅನಾರಂಭ',
'ಅನಾರತ',
'ಅನಾರತಂ',
'ಅನಾರಸ',
'ಅನಾರೋಗ್ಯ',
'ಅನಾರ್ಜಿತ',
'ಅನಾರ್ತವೆ',
'ಅನಾದ್ರ್ರತೆ',
'ಅನಾರ್ಯತಿ',
'ಅನಾರ್ಯತೆ',
'ಅನಾರ್ಷತೆ',
'ಅನಾಲಸ್ಯ',
'ಅನಾವರ',
'ಅನಾವರ್ತ',
'ಅನಾವಿಲ',
'ಅನಾವಿಳ',
'ಅನಾವೃತ',
'ಅನಾವೃಷ್ಟಿ',
'ಅನಾವ್ಯಯ',
'ಅನಾವ್ರಯ',
'ಅನಾಸಕ್ತ',
'ಅನಾಸಕ್ತಿ',
'ಅನಾಸಕ್ತೆ',
'ಅನಾಹತ',
'ಅನಾಹತಂ',
'ಅನಾಹಾರ',
'ಅನಾಹುತ',
'ಅನಾಹೂತ',
'ಅನಿಂದಿತ',
'ಅನಿಂದಿತೆ',
'ಅನಿಂದ್ರಿಯ',
'ಅನಿಃಸೃತ',
'ಅನಿಕೇತ',
'ಅನಿಗಾಲ',
'ಅನಿತರ',
'ಅನಿತ್ಯತೆ',
'ಅನಿತ್ಯತ್ವ',
'ಅನಿಧನ',
'ಅನಿಧನೆ',
'ಅನಿಬದ್ಧ',
'ಅನಿಬರ್',
'ಅನಿಬರು',
'ಅನಿಮಿತ್ತ',
'ಅನಿಮಿತ್ತಂ',
'ಅನಿಮಿಷ',
'ಅನಿಮಿಸ',
'ಅನಿಮೇಷ',
'ಅನಿಯತ',
'ಅನಿಯೋಗ',
'ಅನಿರೀಕ್ಷೆ',
'ಅನಿರುದ್ಧ',
'ಅನಿರ್ಜಿತ',
'ಅನಿರ್ಣಯ',
'ಅನಿರ್ಣೀತ',
'ಅನಿರ್ಣೇಯ',
'ಅನಿರ್ದಿಷ್ಟ',
'ಅನಿರ್ಧಾರ',
'ಅನಿರ್ಬಂಧ',
'ಅನಿರ್ಬದ್ಧ',
'ಅನಿರ್ಮಲ',
'ಅನಿರ್ವಾಚ್ಯ',
'ಅನಿರ್ವಾಹ',
'ಅನಿಲಕ',
'ಅನಿಲಜ',
'ಅನಿಲಾಪ್ತ',
'ಅನಿವರ್ತಿ',
'ಅನಿವಾರ',
'ಅನಿವಾರ್ಯ',
'ಅನಿವಾಸಿ',
'ಅನಿವಿಸು',
'ಅನಿವೃತ್ತ',
'ಅನಿವೃತ್ತಿ',
'ಅನಿಶ್ಚಯ',
'ಅನಿಶ್ಚಯಿ',
'ಅನಿಶ್ಚಿತ',
'ಅನಿಷೇಕ',
'ಅನಿಷ್ಕೃಷ್ಟ',
'ಅನಿಷ್ಟತೆ',
'ಅನಿಷ್ಟಾರ್ಥ',
'ಅನಿಷ್ಪಂದ',
'ಅನಿಸಿಕೆ',
'ಅನಿಳಾಧ್ವ',
'ಅನೀಕಸ್ಥ',
'ಅನೀಕಿನಿ',
'ಅನೀಪ್ಸಿತ',
'ಅನೀಶತೆ',
'ಅನೀಶ್ವರ',
'ಅನೀಹಿತ',
'ಅನುಂಗೊಲೆ',
'ಅನುಕಂಪ',
'ಅನುಕಂಪೆ',
'ಅನುಕರ',
'ಅನುಕರ್ತ',
'ಅನುಕರ್ತೃ',
'ಅನುಕರ್ಷ',
'ಅನುಕಲ್ಪ',
'ಅನುಕಾಂತ',
'ಅನುಕಾರ',
'ಅನುಕಾರಿ',
'ಅನುಕಾರ್ಯ',
'ಅನುಕಾಲ',
'ಅನುಕೂಲ',
'ಅನುಕೂಲೆ',
'ಅನುಕೂಳ',
'ಅನುಕೃತ',
'ಅನುಕೃತಿ',
'ಅನುಕೆಯ್',
'ಅನುಕ್ರಮ',
'ಅನುಕ್ರಮಿ',
'ಅನುಕ್ರಿಯೆ',
'ಅನುಕ್ರೋಶ',
'ಅನುಕ್ಷಣ',
'ಅನುಗತ',
'ಅನುಗತಿ',
'ಅನುಗಮ',
'ಅನುಗಾಣ್',
'ಅನುಗಾಮಿ',
'ಅನುಗಾಲ',
'ಅನುಗಿಡು',
'ಅನುಗುಂದು',
'ಅನುಗುಣ',
'ಅನುಗೂಡು',
'ಅನುಗೆಡು',
'ಅನುಗೆಯ್',
'ಅನುಗೆಯ್ತ',
'ಅನುಗೆಯ್ಮೆ',
'ಅನುಗೇಡು',
'ಅನುಗೊಡು',
'ಅನುಗೊಳ್',
'ಅನುಗೊಳ್ಳು',
'ಅನುಗ್ರಹ',
'ಅನುಗ್ರಾಮ',
'ಅನುಗ್ರಾಹ್ಯ',
'ಅನುಗ್ರಾಹ್ಯೆ',
'ಅನುಘಂಟ',
'ಅನುಚಣ',
'ಅನುಚರ',
'ಅನುಚರಿ',
'ಅನುಚಿತ',
'ಅನುಚಿತೆ',
'ಅನುಚ್ಛಿಷ್ಟ',
'ಅನುಚ್ಛೇದ',
'ಅನುಜನ್ಮ',
'ಅನುಜನ್ಯ',
'ಅನುಜಾತ',
'ಅನುಜಾತೆ',
'ಅನುಜಾಯ್',
'ಅನುಜಾಯಿ',
'ಅನುಜೀವ',
'ಅನುಜೀವಿ',
'ಅನುಜ್ಞಿಸು',
'ಅನುತರ್ಷ',
'ಅನುತಾಪ',
'ಅನುತಾಪಿ',
'ಅನುತಾಳ',
'ಅನುತ್ಕಟ',
'ಅನುತ್ಕರ್ಷ',
'ಅನುತ್ಕಷ್ರ್ಯ',
'ಅನುತ್ತಮ',
'ಅನುತ್ತರ',
'ಅನುತ್ತರೆ',
'ಅನುತ್ತೀರ್ಣ',
'ಅನುತ್ರಿಜ್ಯ',
'ಅನುದಾತ್ತ',
'ಅನುದಾನ',
'ಅನುದಾರ',
'ಅನುದಾಸ',
'ಅನುದಿತ',
'ಅನುದಿನ',
'ಅನುದಿನಂ',
'ಅನುದಿಶ',
'ಅನುದಿಶೆ',
'ಅನುದೀಪ್ತ',
'ಅನುದೀಪ್ತಿ',
'ಅನುದೇಶ',
'ಅನುದೇಶಿ',
'ಅನುದೋಱು',
'ಅನುದ್ಗತ',
'ಅನುದ್ದಿಶೆ',
'ಅನುದ್ದಿಷ್ಟ',
'ಅನುದ್ದಿಸೆ',
'ಅನುದ್ಧತ',
'ಅನುದ್ಭೂತ',
'ಅನುದ್ಯುತಿ',
'ಅನುದ್ಯೋಗ',
'ಅನುದ್ರುತ',
'ಅನುದ್ವಿಗ್ನ',
'ಅನುದ್ವಂಜ್ಯ',
'ಅನುಧರ್ಮ',
'ಅನುನಯ',
'ಅನುನಾದ',
'ಅನುನಿತ್ಯ',
'ಅನುನೀತ',
'ಅನುನೀತಿ',
'ಅನುನೈಸು',
'ಅನುಪತ್ಯ',
'ಅನುಪಥ್ಯ',
'ಅನುಪದಂ',
'ಅನುಪದಿ',
'ಅನುಪಮ',
'ಅನುಪಮೆ',
'ಅನುಪಮ್ಮ',
'ಅನುಪಾತ',
'ಅನುಪಾನ',
'ಅನುಪಾಲ',
'ಅನುಪೂರ್ವ',
'ಅನುಪ್ರತಿ',
'ಅನುಪ್ರಭೆ',
'ಅನುಪ್ರಾಸ',
'ಅನುಪ್ರೇಕ್ಷೆ',
'ಅನುಬಂಧ',
'ಅನುಬಂಧಿ',
'ಅನುಬದ್ಧ',
'ಅನುಬಲ',
'ಅನುಬಿಂಬ',
'ಅನುಬೋಧ',
'ಅನುಭವ',
'ಅನುಭವಿ',
'ಅನುಭಾಗ',
'ಅನುಭಾವ',
'ಅನುಭಾವಿ',
'ಅನುಭುಕ್ತ',
'ಅನುಭೂತ',
'ಅನುಭೂತಿ',
'ಅನುಭೋಕ್ತೃ',
'ಅನುಭೋಗ',
'ಅನುಭೋಗಿ',
'ಅನುಮಂದ್ರ',
'ಅನುಮಜ್ಜೆ',
'ಅನುಮತ',
'ಅನುಮತಿ',
'ಅನುಮನ',
'ಅನುಮಾಡು',
'ಅನುಮಾನ',
'ಅನುಮಾರ್ಗ',
'ಅನುಮಿತ',
'ಅನುಮಿತಿ',
'ಅನುಮಿಸು',
'ಅನುಮೇಯ',
'ಅನುಮೈಸು',
'ಅನುಮೋದ',
'ಅನುಮೋದಿ',
'ಅನುಯಾತ',
'ಅನುಯಾತ್ರೆ',
'ಅನುಯಾಯಿ',
'ಅನುಯೋಗ',
'ಅನುರಕ್ತ',
'ಅನುರಕ್ತಿ',
'ಅನುರಕ್ತೆ',
'ಅನುರಾಗ',
'ಅನುರಾಗಿ',
'ಅನುರಾಧೆ',
'ಅನುರೂಪ',
'ಅನುರೂಪಿ',
'ಅನುರೋಧ',
'ಅನುರೋಳಿ',
'ಅನುಲಕ್ಷ್ಯ',
'ಅನುಲಾಪ',
'ಅನುಲಾಸಿ',
'ಅನುಲೇಪ',
'ಅನುಲೋಮ',
'ಅನುಲೋಮಿ',
'ಅನುಲ್ಲಾಸ',
'ಅನುವಂಶ',
'ಅನುವಡೆ',
'ಅನುವರ',
'ಅನುವರಿ',
'ಅನುವರ್ಣ',
'ಅನುವರ್ತ',
'ಅನುವರ್ತಿ',
'ಅನುವಶ',
'ಅನುವಶೆ',
'ಅನುವಸ',
'ಅನುವಳಿ',
'ಅನುವಱಿ',
'ಅನುವಾಕ',
'ಅನುವಾಗು',
'ಅನುವಾತು',
'ಅನುವಾದ',
'ಅನುವಾದಿ',
'ಅನುವಾನ್',
'ಅನುವಾರ',
'ಅನುವಿಡಿ',
'ಅನುವಿದ್ಧ',
'ಅನುವಿಸು',
'ಅನುವೀಡು',
'ಅನುವೃತ್ತ',
'ಅನುವೃತ್ತಿ',
'ಅನುವೃದ್ಧಿ',
'ಅನುವೆಱು',
'ಅನುವೇರು',
'ಅನುವೇಱು',
'ಅನುವ್ರಜ್ಯೆ',
'ಅನುವ್ರತ',
'ಅನುವ್ರತೆ',
'ಅನುಶಕ್ತ',
'ಅನುಶಕ್ತಿ',
'ಅನುಶಕ್ತೆ',
'ಅನುಶಯ',
'ಅನುಶಾಸ್ಯ',
'ಅನುಶೂಕಿ',
'ಅನುಶೈವ',
'ಅನುಶ್ರುತ',
'ಅನುಶ್ರುತೆ',
'ಅನುಶ್ರೇಣಿ',
'ಅನುಷಂಗ',
'ಅನುಷಂಗಿ',
'ಅನುಷಕ್ತ',
'ಅನುಷ್ಟುಪು',
'ಅನುಷ್ಟುಪ್ಪು',
'ಅನುಷ್ಟುಭು',
'ಅನುಷ್ಠಾನ',
'ಅನುಷ್ಠಾನಿ',
'ಅನುಷ್ಠಿತ',
'ಅನುಷ್ಠಿಸು',
'ಅನುಷ್ಠೇಯ',
'ಅನುಸಂಧಿ',
'ಅನುಸಕ್ತಿ',
'ಅನುಸಮ',
'ಅನುಸರ',
'ಅನುಸರಿ',
'ಅನುಸಾರ',
'ಅನುಸಾರಂ',
'ಅನುಸಾರಿ',
'ಅನುಸುತೆ',
'ಅನುಸೂಚಿ',
'ಅನುಸೃಷ್ಟಿ',
'ಅನುಸೌರ',
'ಅನುಸ್ಮೃತ',
'ಅನುಸ್ಯೂತ',
'ಅನುಸ್ವರ',
'ಅನುಸ್ವಾರ',
'ಅನುಹಾರ',
'ಅನುಹಾರ್ಯ',
'ಅನೂಚಾನ',
'ಅನೂನಕ',
'ಅನೂನಾಂಗಿ',
'ಅನೂರಾಧ',
'ಅನೂರಾಧೆ',
'ಅನೂರ್ಜಿತ',
'ಅನೂಷರ',
'ಅನೂಹ್ಯತೆ',
'ಅನೃಜುತ್ವ',
'ಅನೆಯಕ',
'ಅನೇಕತೆ',
'ಅನೇಕತ್ವ',
'ಅನೇಕಪ',
'ಅನೇಕಾಂತ',
'ಅನೇಕಾಗ್ರ',
'ಅನೇಕೋಕ್ತಿ',
'ಅನೈಕ್ಯತೆ',
'ಅನೈಚ್ಛಿಕ',
'ಅನೈತಿಕ',
'ಅನೈರ್ಮಲ್ಯ',
'ಅನೋಕಹ',
'ಅನೌಚಿತ್ಯ',
'ಅನೌದಾರ್ಯ',
'ಅನೌಪಮ್ಯ',
'ಅನೌರಸ',
'ಅನ್ನಂಬಡೆ',
'ಅನ್ನಂಬರಂ',
'ಅನ್ನಕರ',
'ಅನ್ನಕ್ಕರ',
'ಅನ್ನಗತ',
'ಅನ್ನಚತ್ರ',
'ಅನ್ನಛತ್ರ',
'ಅನ್ನದಟ್ಟು',
'ಅನ್ನದಾತ',
'ಅನ್ನದಾನ',
'ಅನ್ನದಾನಿ',
'ಅನ್ನದ್ರೋಹ',
'ಅನ್ನದ್ವೇಷ',
'ಅನ್ನನಾಳ',
'ಅನ್ನನೀರು',
'ಅನ್ನಪಕ್ಷಿ',
'ಅನ್ನಪಡಿ',
'ಅನ್ನಪಾನ',
'ಅನ್ನಪೂರ್ಣೆ',
'ಅನ್ನಬರ',
'ಅನ್ನಬರೆ',
'ಅನ್ನಭೇದಿ',
'ಅನ್ನಮದ',
'ಅನ್ನಮಯ',
'ಅನ್ನಮಾನಿ',
'ಅನ್ನರಸ',
'ಅನ್ನವರ',
'ಅನ್ನವಸ್ತ್ರ',
'ಅನ್ನವಾಸ',
'ಅನ್ನಶಾಂತಿ',
'ಅನ್ನಶುದ್ಧಿ',
'ಅನ್ನಶ್ರಾದ್ಧ',
'ಅನ್ನಸತ್ರ',
'ಅನ್ನಾಭಾವ',
'ಅನ್ನಾರಕ್ಕೆ',
'ಅನ್ನಾಶಯ',
'ಅನ್ನುಮಾನ',
'ಅನ್ನೆಕಾರ',
'ಅನ್ನೆಬರ',
'ಅನ್ನೆವರ',
'ಅನ್ನೆವರಂ',
'ಅನ್ನೆವರೆ',
'ಅನ್ಯಕರ್ತೃ',
'ಅನ್ಯಕಾರಿ',
'ಅನ್ಯಚಿತ್ತ',
'ಅನ್ಯಜಾತ',
'ಅನ್ಯಜಾತಿ',
'ಅನ್ಯತರ',
'ಅನ್ಯದಾರ',
'ಅನ್ಯದೃಷ್ಟಿ',
'ಅನ್ಯದೇಶ್ಯ',
'ಅನ್ಯದೈವ',
'ಅನ್ಯಧರ್ಮ',
'ಅನ್ಯನಾರಿ',
'ಅನ್ಯಪಾಕ',
'ಅನ್ಯಪುಷ್ಟ',
'ಅನ್ಯಭವ',
'ಅನ್ಯಭಾವ',
'ಅನ್ಯಭೃತ',
'ಅನ್ಯಮಾರ್ಗ',
'ಅನ್ಯಲಿಂಗಿ',
'ಅನ್ಯವಧು',
'ಅನ್ಯವರ್ಣ',
'ಅನ್ಯವಾದಿ',
'ಅನ್ಯವಿತ್ತ',
'ಅನ್ಯಶಿಲೆ',
'ಅನ್ಯಾಂಗನೆ',
'ಅನ್ಯಾಚಾರ',
'ಅನ್ಯಾದೃಶ',
'ಅನ್ಯೂನತೆ',
'ಅನ್ಯೇಶ್ವರ',
'ಅನ್ಯೋನ್ಯತೆ',
'ಅನ್ವಯಿಸು',
'ಅನ್ವರ್ಥಕ',
'ಅನ್ವರ್ಥತೆ',
'ಅನ್ವಾಧಾನ',
'ಅನ್ವಾಸನ',
'ಅನ್ವಾಹಾರ್ಯ',
'ಅನ್ವಾಹಿತ',
'ಅನ್ವಿತಾರ್ಥ',
'ಅನ್ವೀಕ್ಷಣ',
'ಅನ್ವೀಕ್ಷಣೆ',
'ಅನ್ವೇಷಕ',
'ಅನ್ವೇಷಕಿ',
'ಅನ್ವೇಷಣ',
'ಅನ್ವೇಷಣೆ',
'ಅನ್ವೇಷಿತ',
'ಅನ್ವೇಷಿಸು',
'ಅಪಕರ್ಷ',
'ಅಪಕಾರ',
'ಅಪಕಾರಿ',
'ಅಪಕೀರ್ತಿ',
'ಅಪಕೃತ',
'ಅಪಕೃತಿ',
'ಅಪಕೃತ್ಯ',
'ಅಪಕೃಷ್ಟ',
'ಅಪಕ್ರಮ',
'ಅಪಕ್ರಾಂತ',
'ಅಪಕ್ರಿಯೆ',
'ಅಪಕ್ವತೆ',
'ಅಪಕ್ಷಯ',
'ಅಪಕ್ಷಾತಿ',
'ಅಪಕ್ಷ್ಮತೆ',
'ಅಪಖ್ಯಾತಿ',
'ಅಪಗಂಟು',
'ಅಪಗತ',
'ಅಪಗತಿ',
'ಅಪಗಮ',
'ಅಪಗಾಮಿ',
'ಅಪಘನ',
'ಅಪಘಾತ',
'ಅಪಘಾತಿ',
'ಅಪಚನ',
'ಅಪಚಯ',
'ಅಪಚಾರ',
'ಅಪಚಿತ',
'ಅಪಚಿತಿ',
'ಅಪಚ್ಛೇದ',
'ಅಪಜಯ',
'ಅಪಜಿಹ್ಮ',
'ಅಪಜ್ಞಾನ',
'ಅಪತಿಕ',
'ಅಪತೃಷ್ಣೆ',
'ಅಪತ್ನೀಕ',
'ಅಪತ್ಯತೆ',
'ಅಪತ್ರಪ',
'ಅಪತ್ರಪೆ',
'ಅಪದಶೆ',
'ಅಪದಸ್ಥ',
'ಅಪದಾನ',
'ಅಪದೂರು',
'ಅಪದೂಱು',
'ಅಪದೆಸೆ',
'ಅಪದೇಶ',
'ಅಪದೋಷ',
'ಅಪದ್ವಾರ',
'ಅಪಧೈರ್ಯ',
'ಅಪಧ್ಯಾನ',
'ಅಪಧ್ವಂಸ',
'ಅಪಧ್ವಂಸಿ',
'ಅಪಧ್ವಸ್ತ',
'ಅಪನತಿ',
'ಅಪನಯ',
'ಅಪನಿಂದೆ',
'ಅಪನೀತ',
'ಅಪನೀತಿ',
'ಅಪನೋದ',
'ಅಪಪದ',
'ಅಪಪಾಠ',
'ಅಪಪ್ರಥೆ',
'ಅಪಭದ್ರ',
'ಅಪಭ್ರಂಶ',
'ಅಪಭ್ರಷ್ಟ',
'ಅಪಮತಿ',
'ಅಪಮಾನ',
'ಅಪಮಾರ್ಗ',
'ಅಪಮಿತ',
'ಅಪಮಿತ್ಯ',
'ಅಪಮೂರ್ಧ',
'ಅಪಮೃತಿ',
'ಅಪಮೃತ್ಯು',
'ಅಪಮೌಲ್ಯ',
'ಅಪಯಶ',
'ಅಪಯಾನ',
'ಅಪಯೋಗ',
'ಅಪರಂಜಿ',
'ಅಪರಗೆ',
'ಅಪರಜ',
'ಅಪರವಿ',
'ಅಪರಾಗ',
'ಅಪರಾಜ',
'ಅಪರಾತ್ರಿ',
'ಅಪರಾದ್ರಿ',
'ಅಪರಾಧ',
'ಅಪರಾಧಿ',
'ಅಪರಾಬ್ಧಿ',
'ಅಪರಾರ್ಕ',
'ಅಪರಾರ್ಧ',
'ಅಪರಾಶೆ',
'ಅಪರಾಸ್ತ',
'ಅಪರಾಹ್ಣ',
'ಅಪರಾಹ್ನ',
'ಅಪರೀಕ್ಷ್ಯ',
'ಅಪರುಚಿ',
'ಅಪರುದ್ಧ',
'ಅಪರೂಪ',
'ಅಪರೂಪು',
'ಅಪರೋಕ್ಷ',
'ಅಪರ್ಯಂತ',
'ಅಪರ್ಯಾಪ್ತ',
'ಅಪರ್ಯಾಪ್ತಿ',
'ಅಪಲಗ್ನ',
'ಅಪಲಾಪ',
'ಅಪವರ್ಗ',
'ಅಪವತ್ರ್ಯ',
'ಅಪವಸ್ತು',
'ಅಪವಾದ',
'ಅಪವಾಹ',
'ಅಪವಾಹಿ',
'ಅಪವಿತ್ರ',
'ಅಪವಿದ್ಧ',
'ಅಪವ್ಯಯ',
'ಅಪಶಕ್ತಿ',
'ಅಪಶದ',
'ಅಪಶದಿ',
'ಅಪಶಬ್ದ',
'ಅಪಶ್ರುತಿ',
'ಅಪಸದ',
'ಅಪಸರ',
'ಅಪಸರಿ',
'ಅಪಸರ್ಪ',
'ಅಪಸವ್ಯ',
'ಅಪಸಹ್ಯ',
'ಅಪಸಾಕ್ಷಿ',
'ಅಪಸಾತು',
'ಅಪಸಾರ',
'ಅಪಸೌಖ್ಯ',
'ಅಪಸ್ನಾನ',
'ಅಪಸ್ಮಾರ',
'ಅಪಸ್ಮಾರಿ',
'ಅಪಸ್ವರ',
'ಅಪಹತ',
'ಅಪಹರ',
'ಅಪಹಸ್ತ',
'ಅಪಹಾತ',
'ಅಪಹಾರ',
'ಅಪಹಾರಿ',
'ಅಪಹಾಸ',
'ಅಪಹಾಸಿ',
'ಅಪಹಾಸ್ಯ',
'ಅಪಹಿಂಸ',
'ಅಪಹಿಂಸೆ',
'ಅಪಹೃತ',
'ಅಪಹೃತಿ',
'ಅಪಹೃತೆ',
'ಅಪಹ್ನವ',
'ಅಪಹ್ನುತಿ',
'ಅಪಾಂಗಾಂಶು',
'ಅಪಾಂಪತಿ',
'ಅಪಾಂಸುಲೆ',
'ಅಪಾಕೃತ',
'ಅಪಾಕ್ಷಿಕ',
'ಅಪಾಚೀನ',
'ಅಪಾಟವ',
'ಅಪಾತ್ರತೆ',
'ಅಪಾದಾನ',
'ಅಪಾಪೋಲಿ',
'ಅಪಾಮಾರ್ಗ',
'ಅಪಾರ್ಥಿವ',
'ಅಪಾಲಾಪ',
'ಅಪಾವಸ್ಥೆ',
'ಅಪಾವೃತ',
'ಅಪಾಶ್ರಯ',
'ಅಪಾಸಂಗ',
'ಅಪಾಸನ',
'ಅಪಿದಾನ',
'ಅಪಿನದ್ಧ',
'ಅಪಿಪಾಸ',
'ಅಪುತ್ರಕ',
'ಅಪುತ್ರತೆ',
'ಅಪುತ್ರಿಕ',
'ಅಪುತ್ರಿಕೆ',
'ಅಪುರಂಧ್ರಿ',
'ಅಪುರೂಪು',
'ಅಪುಷ್ಪಿಕೆ',
'ಅಪುಷ್ಪಿಣಿ',
'ಅಪೂರಣಿ',
'ಅಪೂರುವ',
'ಅಪೂರ್ಣತೆ',
'ಅಪೂರ್ಣಾಂಕ',
'ಅಪೂರ್ಣಾಂಶ',
'ಅಪೂರ್ಬಾಯ',
'ಅಪೂರ್ವಕ',
'ಅಪೂರ್ವತೆ',
'ಅಪೂರ್ವಾಯ',
'ಅಪೂರ್ವಿಗ',
'ಅಪೇಕ್ಷಣಿ',
'ಅಪೇಕ್ಷತೆ',
'ಅಪೇಕ್ಷಿತ',
'ಅಪೇಕ್ಷಿಸು',
'ಅಪೋಗಂಡ',
'ಅಪೋಹನ',
'ಅಪೋಹಿಸು',
'ಅಪ್ಪತನ',
'ಅಪ್ಪಯಣ',
'ಅಪ್ಪಯಿಸು',
'ಅಪ್ಪರಿಸು',
'ಅಪ್ಪರೂಪ',
'ಅಪ್ಪವಂತ',
'ಅಪ್ಪಳಿಕೆ',
'ಅಪ್ಪಳಿಸು',
'ಅಪ್ಪಿಕೊಳ್',
'ಅಪ್ಪಿತಪ್ಪಿ',
'ಅಪ್ಪಿಮಿಡಿ',
'ಅಪ್ಪುಕಣ',
'ಅಪ್ಪುಕೆಯ್',
'ಅಪ್ಪುಗಿಡ',
'ಅಪ್ಪುಗೆಯ್',
'ಅಪ್ಪುನಿಧಿ',
'ಅಪ್ಪುಮನ',
'ಅಪ್ಪುರಿತ',
'ಅಪ್ಪೆಸೊಪ್ಪು',
'ಅಪ್ರಕಟ',
'ಅಪ್ರಕಟಂ',
'ಅಪ್ರಕಾಂಡ',
'ಅಪ್ರಕಾಶ',
'ಅಪ್ರಕೃತ',
'ಅಪ್ರಕೇತ',
'ಅಪ್ರಗಲ್ಭ',
'ಅಪ್ರಗಲ್ಭೆ',
'ಅಪ್ರಗುಣ',
'ಅಪ್ರಚಿಂತ್ಯ',
'ಅಪ್ರಚುರ',
'ಅಪ್ರಣಾಶ',
'ಅಪ್ರತಕ್ರ್ಯ',
'ಅಪ್ರತಾಪ',
'ಅಪ್ರತಾಪಿ',
'ಅಪ್ರತಿಭ',
'ಅಪ್ರತಿಮ',
'ಅಪ್ರತಿಷ್ಠ',
'ಅಪ್ರತಿಷ್ಠೆ',
'ಅಪ್ರತೀತ',
'ಅಪ್ರತೀತಿ',
'ಅಪ್ರತ್ಯಕ್ಷ',
'ಅಪ್ರತ್ಯೂಹ',
'ಅಪ್ರಧಾನ',
'ಅಪ್ರಪಂಚ',
'ಅಪ್ರಬುದ್ಧ',
'ಅಪ್ರಬೋಧ',
'ಅಪ್ರಮತ್ತ',
'ಅಪ್ರಮತ್ತೆ',
'ಅಪ್ರಮಾಣ',
'ಅಪ್ರಮಿತ',
'ಅಪ್ರಮುಖ',
'ಅಪ್ರಮೇಯ',
'ಅಪ್ರಯತ್ನ',
'ಅಪ್ರಯಾಸ',
'ಅಪ್ರಯುಕ್ತ',
'ಅಪ್ರರೂಪಿ',
'ಅಪ್ರಲಂಬ',
'ಅಪ್ರವೃತ್ತಿ',
'ಅಪ್ರಶಸ್ತ',
'ಅಪ್ರಸಕ್ತ',
'ಅಪ್ರಸಕ್ತಿ',
'ಅಪ್ರಸನ್ನ',
'ಅಪ್ರಸಾದಿ',
'ಅಪ್ರಸಿದ್ಧ',
'ಅಪ್ರಸಿದ್ಧಿ',
'ಅಪ್ರಸ್ತುತ',
'ಅಪ್ರಹತ',
'ಅಪ್ರಾಕಟ',
'ಅಪ್ರಾಕೃತ',
'ಅಪ್ರಾಪ್ತರ್ಧಿ',
'ಅಪ್ರಾಮಾಣ್ಯ',
'ಅಪ್ರಾರ್ಥಿತ',
'ಅಪ್ರಾಸುಕ',
'ಅಪ್ರೌಢತೆ',
'ಅಪ್ಸರಸಿ',
'ಅಪ್ಸರಸೆ',
'ಅಪ್ಸೆಣಬು',
'ಅಫಳಿಯ',
'ಅಬಂದರ',
'ಅಬಂದರೆ',
'ಅಬಂಧುರ',
'ಅಬಕಾರಿ',
'ಅಬದ್ಧತೆ',
'ಅಬನಸ',
'ಅಬನಾಶಿ',
'ಅಬನಾಸ',
'ಅಬನಾಸಿ',
'ಅಬರಂಗ',
'ಅಬಾಗೇಡಿ',
'ಅಬಾಧಿತ',
'ಅಬಾಧಿತೆ',
'ಅಬಾಧಿತ್ವ',
'ಅಬಾಸಿಗ',
'ಅಬಿಂದುಕ',
'ಅಬಿಲಾಸೆ',
'ಅಬೀಜೀಯ',
'ಅಬುಕಾರ',
'ಅಬುಕಾರಿ',
'ಅಬುಜಾಂಡ',
'ಅಬುಜಿನಿ',
'ಅಬೌದ್ಧಿಕ',
'ಅಬ್ಜಕರ',
'ಅಬ್ಜಗಂಧಿ',
'ಅಬ್ಜಗರ್ಭ',
'ಅಬ್ಜಜಾತ',
'ಅಬ್ಜದಲ',
'ಅಬ್ಜದಳ',
'ಅಬ್ಜಬಂಧು',
'ಅಬ್ಜಭವ',
'ಅಬ್ಜಮಿತ್ರ',
'ಅಬ್ಜಮುಖಿ',
'ಅಬ್ಜಯೋನಿ',
'ಅಬ್ಜರಜ',
'ಅಬ್ಜವನ',
'ಅಬ್ಜವೈರಿ',
'ಅಬ್ಜಸರ',
'ಅಬ್ಜಷಂಡ',
'ಅಬ್ಜಸಖ',
'ಅಬ್ಜಹಿತ',
'ಅಬ್ಜಾಕರ',
'ಅಬ್ಜಾನನೆ',
'ಅಬ್ಜಾಸನ',
'ಅಬ್ಜಾಸನೆ',
'ಅಬ್ಜಾಹಿತ',
'ಅಬ್ಜೋದರ',
'ಅಬ್ಜೋದ್ಭವ',
'ಅಬ್ದಕಾಲ',
'ಅಬ್ದರವ',
'ಅಬ್ದಾಗಿರಿ',
'ಅಬ್ಧಿಕಫ',
'ಅಬ್ಧಿಶಾಯಿ',
'ಅಬ್ಬರಣೆ',
'ಅಬ್ಬರಿಗ',
'ಅಬ್ಬರಿಸು',
'ಅಬ್ಬಲಿಗ',
'ಅಬ್ಬಲಿಗೆ',
'ಅಬ್ಬಳಕೆ',
'ಅಬ್ಬಳಿಕೆ',
'ಅಬ್ಬಳಿಸು',
'ಅಬ್ಬೆಗಬ್ಬ',
'ಅಬ್ಬೇಪಾರಿ',
'ಅಬ್ಬೊಲಿಗೆ',
'ಅಬ್ರಹ್ಮಣ್ಯ',
'ಅಬ್ರಾಹ್ಮಣ',
'ಅಬ್ರುದಾರ',
'ಅಬ್ರುಗೇಡಿ',
'ಅಬ್ರೂಗೇಡಿ',
'ಅಭಂಗುರ',
'ಅಭದ್ರತೆ',
'ಅಭಯಮೀ',
'ಅಭಯಸ್ತ',
'ಅಭಾಗಿನಿ',
'ಅಭಾಜನ',
'ಅಭಾವಿಸು',
'ಅಭಾಷಣ',
'ಅಭಿಕರ್ತ',
'ಅಭಿಕರ್ತೆ',
'ಅಭಿಕಾಂಕ್ಷೆ',
'ಅಭಿಕೃತಿ',
'ಅಭಿಕೇಂದ್ರ',
'ಅಭಿಕ್ರಮ',
'ಅಭಿಖ್ಯಾನ',
'ಅಭಿಗಾಮಿ',
'ಅಭಿಗಾರ',
'ಅಭಿಗೀತ',
'ಅಭಿಗ್ರಸ್ತ',
'ಅಭಿಗ್ರಹ',
'ಅಭಿಘಾತ',
'ಅಭಿಘಾತಿ',
'ಅಭಿಘಾರ',
'ಅಭಿಚರ',
'ಅಭಿಚಾರ',
'ಅಭಿಚಾರಿ',
'ಅಭಿಜನ',
'ಅಭಿಜಾತ',
'ಅಭಿಜಿತು',
'ಅಭಿಜಿತ್ತು',
'ಅಭಿಜ್ಞತೆ',
'ಅಭಿಜ್ಞಾನ',
'ಅಭಿತಾಪ',
'ಅಭಿಧಾತೃ',
'ಅಭಿಧಾನ',
'ಅಭಿಧಾಯಿ',
'ಅಭಿಧೇಯ',
'ಅಭಿನತ',
'ಅಭಿನತಿ',
'ಅಭಿನಯ',
'ಅಭಿನವ',
'ಅಭಿನಾಳಿ',
'ಅಭಿನೀತ',
'ಅಭಿನುತ',
'ಅಭಿನುತಿ',
'ಅಭಿನೂತ',
'ಅಭಿನೂತೆ',
'ಅಭಿನೇತೃ',
'ಅಭಿನೇತ್ರಿ',
'ಅಭಿನೇಯ',
'ಅಭಿನ್ನತೆ',
'ಅಭಿನ್ನಾಂಗಿ',
'ಅಭಿಪನ್ನ',
'ಅಭಿಪಾಕ',
'ಅಭಿಪಾತ',
'ಅಭಿಪಾದಿ',
'ಅಭಿಪ್ರಾಣಿ',
'ಅಭಿಪ್ರಾಯ',
'ಅಭಿಪ್ರೇತ',
'ಅಭಿಭವ',
'ಅಭಿಭೂತ',
'ಅಭಿಭೂತಿ',
'ಅಭಿಮತ',
'ಅಭಿಮತಿ',
'ಅಭಿಮರ',
'ಅಭಿಮಾನ',
'ಅಭಿಮಾನಿ',
'ಅಭಿಮುಖ',
'ಅಭಿಮುಖಿ',
'ಅಭಿಯಾತಿ',
'ಅಭಿಯುಕ್ತ',
'ಅಭಿಯುಕ್ತೆ',
'ಅಭಿಯೋಕ್ತ',
'ಅಭಿಯೋಕ್ತೆ',
'ಅಭಿಯೋಗ',
'ಅಭಿಯೋಗಿ',
'ಅಭಿಯೋಗ್ಯ',
'ಅಭಿಯೋಜ್ಯ',
'ಅಭಿಯೋಜ್ಯೆ',
'ಅಭಿರಕ್ಷೆ',
'ಅಭಿರತ',
'ಅಭಿರತಿ',
'ಅಭಿರಾಮ',
'ಅಭಿರಾಮೆ',
'ಅಭಿರುಚಿ',
'ಅಭಿರೂಪ',
'ಅಭಿಲಾಪ',
'ಅಭಿಲಾವ',
'ಅಭಿಲಾಷಿ',
'ಅಭಿಲಾಷೆ',
'ಅಭಿಲೇಖ',
'ಅಭಿವಂದ್ಯ',
'ಅಭಿವಂದ್ಯೆ',
'ಅಭಿವಾಂಛೆ',
'ಅಭಿವಾದ',
'ಅಭಿವಾದ್ಯ',
'ಅಭಿವಾಹ',
'ಅಭಿವಾಹಿ',
'ಅಭಿವಿೞ್ದಿ',
'ಅಭಿವೃತ್ತಿ',
'ಅಭಿವೃದ್ಧ',
'ಅಭಿವೃದ್ಧಿ',
'ಅಭಿವ್ಯಕ್ತ',
'ಅಭಿವ್ಯಕ್ತಿ',
'ಅಭಿವ್ಯಾಪ್ತಿ',
'ಅಭಿಶಂಕೆ',
'ಅಭಿಶಪ್ತ',
'ಅಭಿಶಸ್ತ',
'ಅಭಿಶಸ್ತಿ',
'ಅಭಿಶಾಪ',
'ಅಭಿಷಂಗ',
'ಅಭಿಷವ',
'ಅಭಿಷಿಕ್ತ',
'ಅಭಿಷೇಕ',
'ಅಭಿಷೇಧ',
'ಅಭಿಷ್ಟುತ',
'ಅಭಿಷ್ಟುತಿ',
'ಅಭಿಷ್ವಂಗ',
'ಅಭಿಸಂಧಿ',
'ಅಭಿಸಾರ',
'ಅಭಿಸಾರಿ',
'ಅಭಿಸ್ತುತ',
'ಅಭಿಸ್ತುತಿ',
'ಅಭಿಹತ',
'ಅಭಿಹತಿ',
'ಅಭಿಹಾರ',
'ಅಭಿಹಿತ',
'ಅಭೀಕ್ಷಣ',
'ಅಭೀಕ್ಷಿಸು',
'ಅಭೀತಾತ್ಮ',
'ಅಭೀತ್ಯುಕ್ತಿ',
'ಅಭೀಪ್ಸಿತ',
'ಅಭೀಷ್ಟಶ್ರೀ',
'ಅಭೀಷ್ಟಿತ',
'ಅಭೇದ್ಯತೆ',
'ಅಭೌತಿಕ',
'ಅಭ್ಯಂಗನ',
'ಅಭ್ಯಂಗಿಸು',
'ಅಭ್ಯಂಜನ',
'ಅಭ್ಯಂತರ',
'ಅಭ್ಯಧಿಕ',
'ಅಭ್ಯನುಜ್ಞೆ',
'ಅಭ್ಯರ್ಚನ',
'ಅಭ್ಯರ್ಚನೆ',
'ಅಭ್ಯರ್ಚಿಸು',
'ಅಭ್ಯರ್ಥನ',
'ಅಭ್ಯರ್ಥನೆ',
'ಅಭ್ಯರ್ಹಿತ',
'ಅಭ್ಯಸನ',
'ಅಭ್ಯಸಿಸು',
'ಅಭ್ಯಾಕುಲ',
'ಅಭ್ಯಾಖ್ಯಾನ',
'ಅಭ್ಯಾಗತ',
'ಅಭ್ಯಾಗತೆ',
'ಅಭ್ಯಾಗಮ',
'ಅಭ್ಯಾರೂಢ',
'ಅಭ್ಯಾರೂಢೆ',
'ಅಭ್ಯಾಸಂಗ',
'ಅಭ್ಯಾಸಿತ',
'ಅಭ್ಯಾಸಿಸು',
'ಅಭ್ಯಾಹಾರ',
'ಅಭ್ಯುಕ್ಷಣ',
'ಅಭ್ಯುಕ್ಷಿತ',
'ಅಭ್ಯುಕ್ಷಿಸು',
'ಅಭ್ಯುತ್ಥಾನ',
'ಅಭ್ಯುದಯ',
'ಅಭ್ಯುದಿತ',
'ಅಭ್ರಂಕಷ',
'ಅಭ್ರಂಲಿಹ',
'ಅಭ್ರಕಾಯ',
'ಅಭ್ರಕಾಯೆ',
'ಅಭ್ರಕುಳ',
'ಅಭ್ರಕೇಶ',
'ಅಭ್ರಗಂಗೆ',
'ಅಭ್ರಗಜ',
'ಅಭ್ರಗಾಮಿ',
'ಅಭ್ರಘಟೆ',
'ಅಭ್ರಚರ',
'ಅಭ್ರಚ್ಛವಿ',
'ಅಭ್ರತಳ',
'ಅಭ್ರನಾದಿ',
'ಅಭ್ರಪತಿ',
'ಅಭ್ರಪಥ',
'ಅಭ್ರಪುರ',
'ಅಭ್ರಪುಷ್ಪ',
'ಅಭ್ರಭೇದಿ',
'ಅಭ್ರಮಣಿ',
'ಅಭ್ರಮಾರ್ಗ',
'ಅಭ್ರಶ್ಯಾಮ',
'ಅಭ್ರಸ್ಥಾಳಿ',
'ಅಭ್ರಾಪಗೆ',
'ಅಮಂಗಲ',
'ಅಮಂಗಲೆ',
'ಅಮಂಗಳ',
'ಅಮಂಗಳೆ',
'ಅಮಂಗುರ',
'ಅಮಕಿರೆ',
'ಅಮಕೀರೆ',
'ಅಮಕ್ಕಳ',
'ಅಮತ್ಸರ',
'ಅಮತ್ಸರೆ',
'ಅಮನಸ್ಕ',
'ಅಮನ್ನಣೆ',
'ಅಮಪುರಿ',
'ಅಮಮಾಂಗ',
'ಅಮರ್ದಪ್ಪು',
'ಅಮರ್ದಾಡು',
'ಅಮರತೆ',
'ಅಮರತ್ವ',
'ಅಮರದ',
'ಅಮರಸ್ತ್ರೀ',
'ಅಮರಾದ್ರಿ',
'ಅಮರಾಧ್ವ',
'ಅಮರಾಯಿ',
'ಅಮರಾರಿ',
'ಅಮರಿಕೆ',
'ಅಮರಿಚು',
'ಅಮರಿಸು',
'ಅಮರೇಂದ್ರ',
'ಅಮರೇಜ್ಯ',
'ಅಮರೇಶ',
'ಅಮರ್ಚಿಸು',
'ಅಮರ್ತ್ಯಾಧ್ವ',
'ಅಮರ್ದುಂಡ',
'ಅಮರ್ದುಂಬ',
'ಅಮರ್ದುಗ',
'ಅಮರ್ದುಣಿ',
'ಅಮರ್ಯಾದೆ',
'ಅಮರ್ಷಣ',
'ಅಮಲತೆ',
'ಅಮಲಾಂಶು',
'ಅಮಲಾತ',
'ಅಮಲಿತ',
'ಅಮಲಿನ',
'ಅಮಲೇರು',
'ಅಮಲ್ಜಾರಿ',
'ಅಮಲ್ದಾರ',
'ಅಮವಾಲ್',
'ಅಮವಾಲು',
'ಅಮವಾಸಿ',
'ಅಮವಾಸೆ',
'ಅಮಸಣಿ',
'ಅಮಸೋಲ',
'ಅಮಸೋಲು',
'ಅಮಳಾಂಶು',
'ಅಮಳಿತ',
'ಅಮಳಿನ',
'ಅಮಳೈಕ್ಯ',
'ಅಮಳೋಕ್ಯ',
'ಅಮಳ್ಗಂಬ',
'ಅಮಳ್ಗದ',
'ಅಮಳ್ಗೊಡೆ',
'ಅಮಳ್ಜಂತ್ರ',
'ಅಮಳ್ದಲೆ',
'ಅಮಳ್ದೆರೆ',
'ಅಮಳ್ದೋಳ್',
'ಅಮಳ್ಪುಟ್ಟು',
'ಅಮಳ್ಮಿಂಚು',
'ಅಮಳ್ವಕ್ಕಿ',
'ಅಮಳ್ವಡಿ',
'ಅಮಳ್ವಾಸು',
'ಅಮಳ್ವೆದೆ',
'ಅಮಳ್ವೆಱು',
'ಅಮಾದಾನ',
'ಅಮಾನತು',
'ಅಮಾನತ್ತು',
'ಅಮಾನಸ್ಯ',
'ಅಮಾನಿತ',
'ಅಮಾನಿಸು',
'ಅಮಾನುಷ',
'ಅಮಾನುಷಿ',
'ಅಮಾನ್ಯತೆ',
'ಅಮಾಯಕ',
'ಅಮಾವಸೆ',
'ಅಮಾವಸ್ಯೆ',
'ಅಮಾವಾಸೆ',
'ಅಮಾವಾಸ್ಯೆ',
'ಅಮಿತಾಭ',
'ಅಮಿಲ್ದಾರ',
'ಅಮುಕಾನ',
'ಅಮುಖ್ಯತೆ',
'ಅಮೂರ್ತತೆ',
'ಅಮೂಲ್ಯತೆ',
'ಅಮೂಲ್ಯತ್ವ',
'ಅಮೃಣಾಲ',
'ಅಮೃಣಾಳ',
'ಅಮೃತತ್ವ',
'ಅಮೃತಾಂಶು',
'ಅಮೃತಾಕ್ಷಿ',
'ಅಮೃತಾನ್ನ',
'ಅಮೃತಾಬ್ಜ',
'ಅಮೃತಾಬ್ಧಿ',
'ಅಮೃತಾರ್ಚಿ',
'ಅಮೃತಾಶಿ',
'ಅಮೃತೋದ',
'ಅಮೌಲ್ಯಕ',
'ಅಮ್ಮಂಡಲ',
'ಅಮ್ಮಣ್ಣಿಗ',
'ಅಮ್ಮರುತ',
'ಅಮ್ಮಿಕಲ್ಲು',
'ಅಮು್ಮುಗೆ',
'ಅಮ್ಲಪಿತ್ತ',
'ಅಯಂತ್ರಿತ',
'ಅಯಃಕಾಂತ',
'ಅಯಃಪಿಂಡ',
'ಅಯಥಾರ್ಥ',
'ಅಯನಾಂಶ',
'ಅಯನಿಸು',
'ಅಯಮಾರ್ಗ',
'ಅಯಶ್ಶೂಲ',
'ಅಯಸ್ಕಾಂತ',
'ಅಯಸ್ಕಾರ',
'ಅಯಶಸ್ವಿ',
'ಅಯಾಚಿತ',
'ಅಯಿಗಯಿ',
'ಅಯಿಗುಳ',
'ಅಯಿಗುಳಿ',
'ಅಯಿಜನ್',
'ಅಯಿದಾರೆ',
'ಅಯಿನಾತಿ',
'ಅಯಿನೂಱು',
'ಅಯಿಮೂಲೆ',
'ಅಯಿರಣೆ',
'ಅಯಿಲಪು',
'ಅಯಿವಜು',
'ಅಯಿವತು',
'ಅಯಿವತ್ತು',
'ಅಯಿವಳ್ಳ',
'ಅಯುಕ್ತತೆ',
'ಅಯುಕ್ತಿಕ',
'ಅಯೋಗ್ಯತೆ',
'ಅಯೋಘನ',
'ಅಯೋನಿಜ',
'ಅಯೋನಿಜೆ',
'ಅಯೋಮಯ',
'ಅಯೋಮಲ',
'ಅಯೋಮುಖ',
'ಅಯ್ಕಿಲ್ವೆಟ್ಟ',
'ಅಯ್ಕಿಲ್ವೆಟ್ಟು',
'ಅಯ್ಗಂಡುಗ',
'ಅಯ್ಗಣೆಯ',
'ಅಯ್ಗಾವುದ',
'ಅಯ್ಗೊರಲ',
'ಅಯ್ಗೊಳಗ',
'ಅಯ್ತರವು',
'ಅಯ್ದಲೆಯ',
'ಅಯ್ದೆತನ',
'ಅಯ್ದೆತಾಳಿ',
'ಅಯ್ದೆವಸ',
'ಅಯ್ಬೆರಳು',
'ಅಯ್ಮುಸುಡ',
'ಅಯ್ಯಂಗಾರ್',
'ಅಯ್ಯಗಲಿ',
'ಅಯ್ಯಗೊಡೆ',
'ಅಯ್ಯರೆಗ',
'ಅಯ್ಯಾಚಾರ',
'ಅಯ್ಯೋಪಾಪ',
'ಅಯ್ರದಾಳಿ',
'ಅಯ್ರುಗಾಲು',
'ಅಯ್ವಗಲ್',
'ಅಯ್ವಡಿಸು',
'ಅಯ್ವಾಗಿಲು',
'ಅಯ್ಸಂಬರ',
'ಅಯ್ಸರಲ',
'ಅಯ್ಸಾಮಂತ',
'ಅಯ್ಸಾವಿರ',
'ಅಯ್ಸಾಸಿರ',
'ಅಯ್ಸರವ',
'ಅರಂತಲ',
'ಅರಂಬಳಿ',
'ಅರಕಟ್ಟು',
'ಅರಕಳಿ',
'ಅರಕಾಣಿ',
'ಅರಕಿಲ್ಲೆ',
'ಅರಕುಡಿ',
'ಅರಕುಲ',
'ಅರಕೋರು',
'ಅರಕ್ಷಣ',
'ಅರಕ್ಷಿತ',
'ಅರಗಚ್ಚು',
'ಅರಗಚ್ಚೆ',
'ಅರಗಜ',
'ಅರಗಟ್ಟ',
'ಅರಗಣ್ಣು',
'ಅರಗಲ್ಲು',
'ಅರಗಿಣಿ',
'ಅರಗಿಸು',
'ಅರಗಿಳಿ',
'ಅರಗೀಲ್',
'ಅರಗೀಲು',
'ಅರಗುರಿ',
'ಅರಗುಲಿ',
'ಅರಗೂಲಿ',
'ಅರಗೆಱೆ',
'ಅರಗೇಡಿ',
'ಅರಗೇಡು',
'ಅರಗೊಡ್ಡಿ',
'ಅರಗೋರು',
'ಅರಘಟ್ಟ',
'ಅರಚಣ್ಣ',
'ಅರಚನೆ',
'ಅರಚರೆ',
'ಅರಚಲ',
'ಅರಚಲು',
'ಅರಚಾಟ',
'ಅರಚಾಟು',
'ಅರಚಾಡು',
'ಅರಜಲ',
'ಅರಟಾಳ',
'ಅರಡಾಳ',
'ಅರಣ್ಯಾನಿ',
'ಅರತಾವು',
'ಅರತೆಱೆ',
'ಅರದಳ',
'ಅರದಾಲ',
'ಅರದಾಲಿ',
'ಅರದಾಳ',
'ಅರದೇಗು',
'ಅರದೇಶಿ',
'ಅರದೇಸಿ',
'ಅರನಟ್ಟು',
'ಅರನಡೆ',
'ಅರನಾರಿ',
'ಅರನಿದ್ದೆ',
'ಅರನಿರಿ',
'ಅರನೆಲೆ',
'ಅರನೆಲ್ಲಿ',
'ಅರಪಕ್ಷಿ',
'ಅರಪಟ್ಟ',
'ಅರಪಣ',
'ಅರಪಾವು',
'ಅರಪುಡಿ',
'ಅರಬೀದಿ',
'ಅರಬೂತ',
'ಅರಬೇವು',
'ಅರಮಗ',
'ಅರಮಣ್',
'ಅರಮಣೆ',
'ಅರಮನೆ',
'ಅರಮರೆ',
'ಅರಮರ್ದ',
'ಅರಮಾವು',
'ಅರಮುಡಿ',
'ಅರಮ್ಮನೆ',
'ಅರಲಂಬ',
'ಅರಲಂಬು',
'ಅರಲಾನ್',
'ಅರಲಿಚ',
'ಅರಲಿಸು',
'ಅರಲುಣಿ',
'ಅರಲೆಲೆ',
'ಅರಲೇಱಿ',
'ಅರಲ್ಗೊಳ',
'ಅರಲ್ತರ್',
'ಅರಲ್ದಂಡೆ',
'ಅರಲ್ದೋಂಟ',
'ಅರಲ್ನನೆ',
'ಅರಲ್ಮಳೆ',
'ಅರಲ್ಮೊಲೆ',
'ಅರಲ್ವಕ್ಕಿ',
'ಅರಲ್ವಸೆ',
'ಅರಲ್ವಳೆ',
'ಅರಲ್ವೞೆ',
'ಅರಲ್ವಾಸು',
'ಅರಲ್ವೋಗು',
'ಅರವಟೆ',
'ಅರವಟ್ಟು',
'ಅರವತ್ತು',
'ಅರವಲ',
'ಅರವೞಿ',
'ಅರವಾವು',
'ಅರವಿಂದ',
'ಅರವೀಸ',
'ಅರವುಡಿ',
'ಅರವೆಣ್',
'ಅರವೆಱೆ',
'ಅರಸಂಕ',
'ಅರಸಂಚೆ',
'ಅರಸಾಂಗ',
'ಅರಸಾಟ',
'ಅರಸಾಣೆ',
'ಅರಸಾನಿ',
'ಅರಸಾಳ್',
'ಅರಸಾಳು',
'ಅರಸಾಳ್ಕೆ',
'ಅರಸಿಂಗ',
'ಅರಸಿಕ',
'ಅರಸಿಕೆ',
'ಅರಸಿಗ',
'ಅರಸಿಣ',
'ಅರಸಿತಿ',
'ಅರಸಿನ',
'ಅರಸುಬ್ಬು',
'ಅರಸೆಕ್ಕೆ',
'ಅರಸೇರು',
'ಅರಸ್ಥಾನ',
'ಅರಹಂತ',
'ಅರಹಾವು',
'ಅರಹೀರೆ',
'ಅರಹುಚ್ಚ',
'ಅರಹುಚ್ಚಿ',
'ಅರಹುಡಿ',
'ಅರಹೊಟ್ಟೆ',
'ಅರಳಂಬ',
'ಅರಳಂಬು',
'ಅರಳಿಗ',
'ಅರಳಿಚು',
'ಅರಳಿಟ್ಟು',
'ಅರಳಿಸು',
'ಅರಳುಂಡೆ',
'ಅರಳುಣ್ಣು',
'ಅರಳೆಲೆ',
'ಅರಳೇಳ್',
'ಅರಳ್ಗಣ್',
'ಅರಳ್ಗಣ್ಣು',
'ಅರಳ್ಗಱೆ',
'ಅರಳ್ಗೂಡು',
'ಅರಳ್ಬಿಲ್ಲು',
'ಅರಳ್ಮಳೆ',
'ಅರಳ್ಮುಡಿ',
'ಅರಳ್ವಾಸು',
'ಅರಾಜಕ',
'ಅರಾಜಿಕ',
'ಅರಾಷ್ಟ್ರಕ',
'ಅರಿಂಜಯ',
'ಅರಿಂದಮ',
'ಅರಿಕಟ್ಟು',
'ಅರಿಕೆಲು',
'ಅರಿಕೋಲು',
'ಅರಿಗಂಟು',
'ಅರಿಗಣ್ಣು',
'ಅರಿಗಲ್ಲು',
'ಅರಿಘಟೆ',
'ಅರಿಟಣ',
'ಅರಿಟಾಳ',
'ಅರಿದಲ',
'ಅರಿದಲೆ',
'ಅರಿದಳ',
'ಅರಿದಾಳ',
'ಅದಿದೋಟಿ',
'ಅರಿಧೀರ',
'ಅರಿಪದ',
'ಅರಿಪೀಠ',
'ಅರಿಬಲ',
'ಅರಿಬಳ',
'ಅರಿಭಟ',
'ಅರಿಮಣ',
'ಅರಿಮಣಿ',
'ಅರಿಮೇದ',
'ಅರಿಯಮ',
'ಅರಿಯಿಸು',
'ಅರಿರಾಯ',
'ಅರಿಲುಂಬ',
'ಅರಿಲ್ವಟ್ಟೆ',
'ಅರಿವಡೆ',
'ಅರಿವರ್ಗ',
'ಅರಿವಾಳ್',
'ಅರಿವಿಕೆ',
'ಅರಿವಿಲಿ',
'ಅರಿವೇಣಿ',
'ಅರಿಶಿಣ',
'ಅರಿಷಟ್ಕ',
'ಅರಿಷ್ಟಾಂಶ',
'ಅರಿಸಿಣ',
'ಅರಿಸಿನ',
'ಅರಿಹಂತ',
'ಅರಿಹುಲ್ಲು',
'ಅರುಂಗಳ',
'ಅರುಂತುದ',
'ಅರುಂಧತಿ',
'ಅರುಕಡ್ಡಿ',
'ಅರುಗಿಸು',
'ಅರುಟಾಲ',
'ಅರುಟಾಳ',
'ಅರುಠಾಳ',
'ಅರುಣತೆ',
'ಅರುಣಾಂಗ',
'ಅರುಣಾಂಬು',
'ಅರುಣಾಕ್ಷಿ',
'ಅರುಣಾಬ್ಜ',
'ಅರುಣಾಶ್ಮ',
'ಅರುಣಿತ',
'ಅರುಣಿಮೆ',
'ಅರುಣಿಸು',
'ಅರುನೂರು',
'ಅರುಪಿಸು',
'ಅರುಮಾಸಿ',
'ಅರುವಡಿ',
'ಅರುವಣ',
'ಅರುವತ್ತು',
'ಅರುವಾರ',
'ಅರುಷ್ಕರ',
'ಅರುಹಂತ',
'ಅರುಳಾಳು',
'ಅರೆಕಟ್ಟು',
'ಅರೆಕದ',
'ಅರೆಕಲ್ಲು',
'ಅರೆಕಾಣಿ',
'ಅರೆಕಾರ',
'ಅರೆಕಾಲ್',
'ಅರೆಕಾಲು',
'ಅರೆಕಾಸು',
'ಅರೆಕೆಂಪು',
'ಅರೆಕೊರೆ',
'ಅರೆಗಂಜಿ',
'ಅರೆಗಂಟು',
'ಅರೆಗಂದು',
'ಅರೆಗಚ್ಚು',
'ಅರೆಗಟ್ಟು',
'ಅರೆಗಡಿ',
'ಅರೆಗಡೆ',
'ಅರೆಗಣ್',
'ಅರೆಗಣ್ಣು',
'ಅರೆಗದ',
'ಅರೆಗಪ್ಪು',
'ಅರೆಗರಿ',
'ಅರೆಗಲ್',
'ಅರೆಗಲ್ಲು',
'ಅರೆಗಾಣಿ',
'ಅರೆಗಾಯ',
'ಅರೆಗಾಲ',
'ಅರೆಗಾವು',
'ಅರೆಗಿರಿ',
'ಅರೆಗುಂದು',
'ಅರೆಗುದಿ',
'ಅರೆಗೆಯ್',
'ಅರೆಗೆಱೆ',
'ಅರೆಗೇಣ್',
'ಅರೆಗೇಣು',
'ಅರೆಗೊಡು',
'ಅರೆಗೊಯ್',
'ಅರೆಗೊಲೆ',
'ಅರೆಗೊರೆ',
'ಅರೆಚಡ್ಡಿ',
'ಅರೆಚಣ',
'ಅರೆಜಾವ',
'ಅರೆತುಂಬು',
'ಅರೆತೆರೆ',
'ಅರೆತ್ರುಟಿ',
'ಅರೆದಿಟ್ಟಿ',
'ಅರೆದೆಱೆ',
'ಅರೆನಗು',
'ಅರೆನಗೆ',
'ಅರೆನಚ್ಚು',
'ಅರೆನಾರಿ',
'ಅರೆನಿದ್ದೆ',
'ಅರೆನುಡಿ',
'ಅರೆನೂಂಕು',
'ಅರೆನೆಲೆ',
'ಅರೆನೆಲ್ಲಿ',
'ಅರೆನೋಟ',
'ಅರೆಪಾದ',
'ಅರೆಪಾಲು',
'ಅರೆಪಾವು',
'ಅರೆಬದ',
'ಅರೆಬರ್',
'ಅರೆಬರೆ',
'ಅರೆಬಳಿ',
'ಅರೆಬಿರಿ',
'ಅರೆಬೇವು',
'ಅರೆಬ್ಬಾಯಿ',
'ಅರೆಭಾಷೆ',
'ಅರೆಭೋಗ',
'ಅರೆಮತ್ತು',
'ಅರೆಮರೆ',
'ಅರೆಮಾನ',
'ಅರೆಮುಗಿ',
'ಅರೆಮುಚ್ಚು',
'ಅರೆಮೂಕ',
'ಅರೆಮೆಯ್',
'ಅರೆಮೇವು',
'ಅರೆಯಟ್ಟು',
'ಅರೆಯಾಗು',
'ಅರೆಯಿಸು',
'ಅರೆಯುಗಿ',
'ಅರೆಯುಡು',
'ಅರೆಯುಣ್ಣು',
'ಅರೆಯೆಡ್ಡ',
'ಅರೆಯೆಡ್ಡಿ',
'ಅರೆಯೇಱು',
'ಅರೆಯೊತ್ತು',
'ಅರೆವಣ್',
'ಅರೆವಣ್ಣು',
'ಅರೆವಲ್ಲು',
'ಅರೆವಳ',
'ಅರೆವಳೆ',
'ಅರೆವಾಗ',
'ಅರೆವಾತು',
'ಅರೆವಾವು',
'ಅರೆವಾಶಿ',
'ಅರೆವಾಸಿ',
'ಅರೆವಿರಿ',
'ಅರೆವೀರೆ',
'ಅರೆವೀಸ',
'ಅರೆವುಳಿ',
'ಅರೆವೆಣ್',
'ಅರೆವೆಣ',
'ಅರೆವೆಣ್ಣ',
'ಅರೆವೆಣ್ಣು',
'ಅರೆವೆಸ',
'ಅರೆವೆಱೆ',
'ಅರೆವೊತ್ತು',
'ಅರೆವೊಯ್ಲು',
'ಅರೆಸಂಜೆ',
'ಅರೆಸಮೆ',
'ಅರೆಹಾನೆ',
'ಅರೆಹಾವು',
'ಅರೆಹುಚ್ಚ',
'ಅರೆಹುಚ್ಚಿ',
'ಅರೆಹುಚ್ಚು',
'ಅರೆಹೊಟ್ಟೆ',
'ಅರೆಹೊಡೆ',
'ಅರೇಖೀಯ',
'ಅರೋಚಕ',
'ಅರೋಚಕಿ',
'ಅರೋಮಕ',
'ಅರ್ಕಕರ',
'ಅರ್ಕಕುಳ',
'ಅರ್ಕಕ್ಷೀರ',
'ಅರ್ಕಗ್ರಾವ',
'ಅರ್ಕಜಾಂಬು',
'ಅರ್ಕದಳ',
'ಅರ್ಕಪರ್ಣ',
'ಅರ್ಕಪುಷ್ಪ',
'ಅರ್ಕಬಂಧು',
'ಅರ್ಕಭಕ್ತ',
'ಅರ್ಕರೋಚಿ',
'ಅರ್ಕಲ್ಕುಮ್ರಿ',
'ಅರ್ಕವಾರ',
'ಅರ್ಕಶಾಲೆ',
'ಅರ್ಕಸಾಲೆ',
'ಅರ್ಕಾನಿಲ',
'ಅರ್ಕಾವಳಿ',
'ಅರ್ಕಾವತ್ತು',
'ಅರ್ಗಲಿಕೆ',
'ಅರ್ಗಳಿಸು',
'ಅರ್ಘಕ್ಷಯ',
'ಅರ್ಘವಾಣಿ',
'ಅರ್ಘ್ಯಂಗುಡು',
'ಅರ್ಘ್ಯಕರ',
'ಅರ್ಘ್ಯಜಲ',
'ಅರ್ಘ್ಯದ್ರವ್ಯ',
'ಅರ್ಘ್ಯಪಾತ್ರೆ',
'ಅರ್ಘ್ಯಲಾಜ',
'ಅರ್ಘ್ಯಹಸ್ತ',
'ಅರ್ಚಕತ್ವ',
'ಅರ್ಚಿಃಕಣ',
'ಅರ್ಚಿಕೆಯ್',
'ಅರ್ಚಿಷ್ಮಂತ',
'ಅರ್ಚಿಷ್ಮತಿ',
'ಅರ್ಜಿದಾರ',
'ಅರ್ಣವಿಸು',
'ಅರ್ತಗಲ',
'ಅರ್ತಿಕಾಱ',
'ಅರ್ತಿಗಾಱ',
'ಅರ್ತಿಗುಂದು',
'ಅರ್ತಿಗೆಡು',
'ಅರ್ತಿಬಡು',
'ಅರ್ತಿವಡು',
'ಅರ್ಥಕರಿ',
'ಅರ್ಥಕಾರಿ',
'ಅರ್ಥಕೃತಿ',
'ಅರ್ಥಗತಿ',
'ಅರ್ಥಗುಣ',
'ಅರ್ಥಗ್ರಾಹಿ',
'ಅರ್ಥಚಯ',
'ಅರ್ಥದುಷ್ಟ',
'ಅರ್ಥದೃಕ್ಕು',
'ಅರ್ಥದೃಷ್ಟಿ',
'ಅರ್ಥದೋಷ',
'ಅರ್ಥಧಾರಿ',
'ಅರ್ಥನಯ',
'ಅರ್ಥನಾಥ',
'ಅರ್ಥನೀಯ',
'ಅರ್ಥಪತಿ',
'ಅರ್ಥಪೋಷ',
'ಅರ್ಥಯತಿ',
'ಅರ್ಥಯುಕ್ತಿ',
'ಅರ್ಥಯುತ',
'ಅರ್ಥಯುತೆ',
'ಅರ್ಥರೇಖೆ',
'ಅರ್ಥಲಂಬಿ',
'ಅರ್ಥಲೋಪ',
'ಅರ್ಥವಂತ',
'ಅರ್ಥವತ್ತು',
'ಅರ್ಥವಾದ',
'ಅರ್ಥವಿಕೆ',
'ಅರ್ಥವಿಸು',
'ಅರ್ಥವೃತ್ತಿ',
'ಅರ್ಥವೇಳು',
'ಅರ್ಥವ್ಯಕ್ತ',
'ಅರ್ಥವ್ಯಕ್ತಿ',
'ಅರ್ಥವ್ಯಾಪ್ತಿ',
'ಅರ್ಥಶಾಸ್ತ್ರ',
'ಅರ್ಥಶೂನ್ಯ',
'ಅರ್ಥಶೌಚ',
'ಅರ್ಥಶ್ಲೇಷ',
'ಅರ್ಥಸಿದ್ಧಿ',
'ಅರ್ಥಹೀನ',
'ಅರ್ಥಹೀನೆ',
'ಅರ್ಥಳಂಬಿ',
'ಅರ್ಥಾಂತರ',
'ಅರ್ಥಾಗಮ',
'ಅರ್ಥಾತ್ಮಕ',
'ಅರ್ಥಾಪತ್ತಿ',
'ಅರ್ಥಾಭಾಸ',
'ಅರ್ಥಾವೃತ್ತಿ',
'ಅರ್ಥಿಕಾಱ',
'ಅರ್ಥಿಕುಲ',
'ಅರ್ಥಿಗಣ',
'ಅರ್ಥಿಜನ',
'ಅರ್ಥಿಬಡು',
'ಅರ್ಥಿವಡು',
'ಅರ್ಥಿಸಾರ್ಥ',
'ಅರ್ಥೇಷಣ',
'ಅರ್ಥೋತ್ಕರ',
'ಅರ್ಧಂಬರ್ಧ',
'ಅರ್ಧಗುಚ್ಚ',
'ಅರ್ಧಗೋಲ',
'ಅರ್ಧಗೋಳ',
'ಅರ್ಧಚಂದ್ರ',
'ಅರ್ಧತನ',
'ಅರ್ಧನಾರಿ',
'ಅರ್ಧಮಾನ್ಯ',
'ಅರ್ಧರಥ',
'ಅರ್ಧರಾತ್ರಿ',
'ಅರ್ಧಸಮ',
'ಅರ್ಧಸ್ವರ',
'ಅರ್ಧಹಾರ',
'ಅರ್ಧಾಂಗಿನಿ',
'ಅರ್ಧಾಸನ',
'ಅರ್ಧಾಹಾರ',
'ಅರ್ಧೋರುಕ',
'ಅರ್ಪಣಗೈ',
'ಅರ್ಬುದಾಂಶ',
'ಅರ್ಲುಗಷ್ಟ',
'ಅರ್ವಕ್ಕೂಲ',
'ಅರ್ವಾಚೀನ',
'ಅರ್ಹತ್ಪದ',
'ಅರ್ಹದ್ದೀಕ್ಷೆ',
'ಅರ್ಹದ್ದೇವ',
'ಅರ್ಹನ್ಮತ',
'ಅರ್ಹನ್ಮಾರ್ಗ',
'ಅರ್ಹಲ್ಲಕ್ಷ್ಮಿ',
'ಅಲಂಕರ್ತೃ',
'ಅಲಂಕಾರ',
'ಅಲಂಕೃತ',
'ಅಲಂಕೃತಿ',
'ಅಲಂಕೃತೆ',
'ಅಲಂಕ್ರಿಯೆ',
'ಅಲಂಘನ',
'ಅಲಂಘ್ಯತೆ',
'ಅಲಂಘ್ಯತ್ವ',
'ಅಲಂಜರ',
'ಅಲಂಪಾನ್',
'ಅಲಂಪಾನು',
'ಅಲಂಪಿಕ್ಕು',
'ಅಲಂಪೋಡು',
'ಅಲಂಬುದ್ಧಿ',
'ಅಲಂಬುಸೆ',
'ಅಲಕ್ಕನೆ',
'ಅಲಕ್ಕಾಗು',
'ಅಲಕ್ತಕ',
'ಅಲಕ್ಷಣ',
'ಅಲಕ್ಷಿತ',
'ಅಲಕ್ಷಿಸು',
'ಅಲಗಂಬು',
'ಅಲಗರ್ಜಿ',
'ಅಲಗರ್ದ',
'ಅಲಗಿಕ್ಕು',
'ಅಲಗೇಱು',
'ಅಲಚಲು',
'ಅಲತಗೆ',
'ಅಲತಿಗೆ',
'ಅಲಪಟೆ',
'ಅಲಪದ್ಮ',
'ಅಲಪಾಕು',
'ಅಲಪಾಟು',
'ಅಲಬತ್',
'ಅಲಭ್ಯತೆ',
'ಅಲಮರು',
'ಅಲಮಾರು',
'ಅಲರಂಬ',
'ಅಲರಂಬು',
'ಅಲರಾಯ್',
'ಅಲರಿಕೆ',
'ಅಲರಿಚ',
'ಅಲರಿಚು',
'ಅಲರಿಪು',
'ಅಲರಿಸು',
'ಅಲರುಣಿ',
'ಅಲರೆಲೆ',
'ಅಲರೇಱಿ',
'ಅಲರೇಱು',
'ಅಲರೊದ್ದೆ',
'ಅಲರೋಳಿ',
'ಅಲರ್ಗಂಪು',
'ಅಲರ್ಗಟ್ಟು',
'ಅಲರ್ಗಣ್',
'ಅಲರ್ಗಣೆ',
'ಅಲರ್ಗಣ್ಣ',
'ಅಲರ್ಗಣ್ಣಿ',
'ಅಲರ್ಗಣ್ಣು',
'ಅಲರ್ಗಯ್ದು',
'ಅಲರ್ಗಳಂ',
'ಅಲರ್ಗಱೆ',
'ಅಲರ್ಗುಡಿ',
'ಅಲರ್ಗೂಡು',
'ಅಲರ್ಗೂಳ',
'ಅಲರ್ಗೊಂಡೆ',
'ಅಲರ್ಗೊಳ',
'ಅಲರ್ಗೋಲ್',
'ಅಲರ್ಗೋಲ',
'ಅಲರ್ಗೋಲು',
'ಅಲರ್ಚಿಸು',
'ಅಲರ್ಜೊಂಪ',
'ಅಲರ್ದಂಡೆ',
'ಅಲರ್ದಟ್ಟೆ',
'ಅಲರ್ದಳೆ',
'ಅಲರ್ದಿಱಿ',
'ಅಲರ್ದೊಂಬೆ',
'ಅಲರ್ದೋಂಟ',
'ಅಲರ್ದೋಟ',
'ಅಲರ್ಮಳೆ',
'ಅಲರ್ಮೞೆ',
'ಅಲರ್ಮಾಲೆ',
'ಅಲರ್ಮುಡಿ',
'ಅಲರ್ವಂಡು',
'ಅಲರ್ವಕ್ಕಿ',
'ಅಲರ್ವಚ್ಚ',
'ಅಲರ್ವಡೆ',
'ಅಲರ್ವಸೆ',
'ಅಲರ್ವಳೆ',
'ಅಲರ್ವಳ್ಳಿ',
'ಅಲರ್ವೞೆ',
'ಅಲರ್ವಾಸು',
'ಅಲರ್ವಿಲ್',
'ಅಲರ್ವಿಲ್ಲ',
'ಅಲರ್ವುಡಿ',
'ಅಲರ್ವೋಗು',
'ಅಲವಂಗ',
'ಅಲವಣ',
'ಅಲವರು',
'ಅಲವಱು',
'ಅಲವಾನ',
'ಅಲವಾಲ',
'ಅಲಸಂಡೆ',
'ಅಲಸಂದಿ',
'ಅಲಸಂದೆ',
'ಅಲಸಕ',
'ಅಲಸಟೆ',
'ಅಲಸತೆ',
'ಅಲಸತ್ವ',
'ಅಲಸಾಟ',
'ಅಲಸಿಕೆ',
'ಅಲಸಿಗ',
'ಅಲಸಿಗೆ',
'ಅಲಸಿಸು',
'ಅಲಸುಕೆ',
'ಅಲಸುಗೆ',
'ಅಲಱಿಸು',
'ಅಲಾಘವ',
'ಅಲಾಯಿದ',
'ಅಲಾಯಿದಾ',
'ಅಲಿಂಜರ',
'ಅಲಿಂದಕ',
'ಅಲಿಕಾಕ್ಷ',
'ಅಲಿಖಿತ',
'ಅಲಿಪ್ತತೆ',
'ಅಲಿಮನೆ',
'ಅಲಿಮಾರಿ',
'ಅಲಿಮಿಲಿ',
'ಅಲುಂಕಿಸು',
'ಅಲುಗಾಟ',
'ಅಲುಗಾಡು',
'ಅಲುಗಿಸು',
'ಅಲುಬ್ಧತ್ವ',
'ಅಲುಮಾರು',
'ಅಲೆಕುಳಿ',
'ಅಲೆಗಾರ',
'ಅಲೆಗೆಯ್',
'ಅಲೆತಡೆ',
'ಅಲೆದಾಟ',
'ಅಲೆದಾಡು',
'ಅಲೆಮಾಡು',
'ಅಲೆಮಾನ್',
'ಅಲೆಮಾರಿ',
'ಅಲೆಯಿಸು',
'ಅಲೆಯೇಳು',
'ಅಲೆಯೇೞು',
'ಅಲೇಮಾನ್',
'ಅಲೇಮಾನಿ',
'ಅಲೈಂಗಿಕ',
'ಅಲೋಕನ',
'ಅಲೌಕಿಕ',
'ಅಲ್ಪತಂತ್ರ',
'ಅಲ್ಪತನ',
'ಅಲ್ಪತನು',
'ಅಲ್ಪತೃಪ್ತ',
'ಅಲ್ಪತೃಪ್ತೆ',
'ಅಲ್ಪದಂಡ',
'ಅಲ್ಪದಿನ',
'ಅಲ್ಪಪ್ರಾಣ',
'ಅಲ್ಪಬುದ್ಧಿ',
'ಅಲ್ಪಭೇದಿ',
'ಅಲ್ಪಮತಿ',
'ಅಲ್ಪವಚೆ',
'ಅಲ್ಪವಯಿ',
'ಅಲ್ಪವಿದ',
'ಅಲ್ಪವೃಷ್ಟಿ',
'ಅಲ್ಪಶಂಕೆ',
'ಅಲ್ಪಶೀಲ',
'ಅಲ್ಪಶೀಲೆ',
'ಅಲ್ಪಶ್ರುತ',
'ಅಲ್ಪಸರ',
'ಅಲ್ಪಸಾರ',
'ಅಲ್ಪಸುಖ',
'ಅಲ್ಪಸೂನು',
'ಅಲ್ಪಾಕ್ಷರ',
'ಅಲ್ಪಾನಲ್ಪ',
'ಅಲ್ಪಾಯುಷ',
'ಅಲ್ಪಾಯುಷಿ',
'ಅಲ್ಪಾಯುಷ್ಯ',
'ಅಲ್ಪಾವಧಿ',
'ಅಲ್ಪೋನ್ಮಾದ',
'ಅಲ್ಬಿದಾಯ',
'ಅಲ್ಲಗಳೆ',
'ಅಲ್ಲಣಿಗೆ',
'ಅಲ್ಲಬೀಜ',
'ಅಲ್ಲಮಂದ',
'ಅಲ್ಲಾಡಿಸು',
'ಅಲ್ಲಿದಳ್',
'ಅಲ್ಲಿಯಣ',
'ಅಲ್ಲಿವರಂ',
'ಅಲ್ಲೀಕೇರಿ',
'ಅಲ್ಲೀಪಾಕ',
'ಅವಂದಿರ್',
'ಅವಂದಿರು',
'ಅವಕರ',
'ಅವಕಾಶ',
'ಅವಕಿಲ',
'ಅವಕಿಳ',
'ಅವಕೀರ್ಣ',
'ಅವಕೀರ್ಣಿ',
'ಅವಕೂರ',
'ಅವಕೃಪೆ',
'ಅವಕೃಷ್ಟ',
'ಅವಕೆಂಪು',
'ಅವಕೇಶಿ',
'ಅವಕ್ರಯ',
'ಅವಕ್ರಿಯೆ',
'ಅವಕ್ಷೇಪ',
'ಅವಗಂಧ',
'ಅವಗಡ',
'ಅವಗತ',
'ಅವಗತಿ',
'ಅವಗಮ',
'ಅವಗಮ್ಯ',
'ಅವಗಯ್ಸು',
'ಅವಗಳೆ',
'ಅವಗೞಿ',
'ಅವಗಾಢ',
'ಅವಗಾಹ',
'ಅವಗೀತ',
'ಅವಗೀತೆ',
'ಅವಗುಣ',
'ಅವಗುಣಿ',
'ಅವಗೂದೆ',
'ಅವಗ್ರಹ',
'ಅವಗ್ರಾಹ',
'ಅವಘಡ',
'ಅವಘಾತ',
'ಅವಚಟ',
'ಅವಚಯ',
'ಅವಚರು',
'ಅವಚಱ',
'ಅವಚಱು',
'ಅವಚಿತ',
'ಅವಚಿತ್ತ',
'ಅವಚೂಡ',
'ಅವಚೂಲ',
'ಅವಚ್ಛನ್ನ',
'ಅವಚ್ಛಿನ್ನ',
'ಅವಚ್ಛೇದ',
'ಅವಜ್ಞತೆ',
'ಅವಜ್ಞಾತ',
'ಅವಟಯ್ಸು',
'ಅವಟೈಸು',
'ಅವಡೀನ',
'ಅವತಂಸ',
'ಅವತರ',
'ಅವತಷ್ಟ',
'ಅವತಳ',
'ಅವತಾರ',
'ಅವತಾರಿ',
'ಅವತೀರ್ಣ',
'ಅವತೋಕೆ',
'ಅವದಂಶ',
'ಅವದಶೆ',
'ಅವದಳ',
'ಅವದಾತ',
'ಅವದಾನ',
'ಅವದಾಹ',
'ಅವದಿರು',
'ಅವದೀರ್ಣ',
'ಅವದೆಶೆ',
'ಅವಧಾತೃ',
'ಅವಧಾನ',
'ಅವಧಾನಿ',
'ಅವಧಾರು',
'ಅವಧಾರ್ಯ',
'ಅವಧಿಕ',
'ಅವಧೂತ',
'ಅವಧೃತ',
'ಅವಧ್ವಸ್ತ',
'ಅವನತ',
'ಅವನತಿ',
'ಅವನತೆ',
'ಅವನದ್ಧ',
'ಅವನಮ್ರ',
'ಅವನಾಯ',
'ಅವನಿಂದೆ',
'ಅವನಿಜ',
'ಅವನಿಜೆ',
'ಅವನಿಪ',
'ಅವನಿಸು',
'ಅವನೀಜ',
'ಅವನೀತ',
'ಅವನೀಶ',
'ಅವಪಥ್ಯ',
'ಅವಪಾತ',
'ಅವಬದ್ಧ',
'ಅವಬಾಧೆ',
'ಅವಬೋಧ',
'ಅವಬೋಧೆ',
'ಅವಭಾಸ',
'ಅವಭೂಮಿ',
'ಅವಭೃತ',
'ಅವಭೃಥ',
'ಅವಮತ',
'ಅವಮತಿ',
'ಅವಮರ್ದ',
'ಅವಮರ್ಶ',
'ಅವಮಾನ',
'ಅವಮಾನ್ಯ',
'ಅವಮಾನ್ಯೆ',
'ಅವಮೌಲ್ಯ',
'ಅವಯವ',
'ಅವಯವಿ',
'ಅವಯೋಗ',
'ಅವರಜ',
'ಅವರಜೆ',
'ಅವರತಿ',
'ಅವರಾತ್ರಿ',
'ಅವರಿಷ್ಠ',
'ಅವರುದ್ಧ',
'ಅವರೋಧ',
'ಅವರೋಧಿ',
'ಅವರೋಹ',
'ಅವರೋಹಿ',
'ಅವರ್ಗಳ್',
'ಅವರ್ಗೀಯ',
'ಅವರ್ಷಣ',
'ಅವಲಂಬ',
'ಅವಲಂಬಿ',
'ಅವಲಕ್ಕಿ',
'ಅವಲಕ್ಷ್ಮಿ',
'ಅವಲಕ್ಷ್ಯ',
'ಅವಲಗ್ನ',
'ಅವಲಿಪ್ತ',
'ಅವಲಿಪ್ತೆ',
'ಅವಲೀಢ',
'ಅವಲೀಢೆ',
'ಅವಲೀನ',
'ಅವಲುಪ್ತ',
'ಅವಲೇಪ',
'ಅವಲೇಹ',
'ಅವಲೋಕ',
'ಅವಲೋಹ',
'ಅವಲ್ಮಾಡು',
'ಅವಶಿತ್ವ',
'ಅವಶಿಷ್ಟ',
'ಅವಶೇಷ',
'ಅವಶೋಷ್ಯ',
'ಅವಶೌಚ',
'ಅವಶ್ಯಾಯ',
'ಅವಷ್ಟಂಭ',
'ಅವಷ್ಟಬ್ಧ',
'ಅವಸಕ್ತ',
'ಅವಸಥ',
'ಅವಸನ್ನ',
'ಅವಸನ್ನೆ',
'ಅವಸರ',
'ಅವಸರ್ಪ',
'ಅವಸಾದ',
'ಅವಸಾದಿ',
'ಅವಸಾನ',
'ಅವಸಿಗ',
'ಅವಸಿತ',
'ಅವಸಿತಿ',
'ಅವಸೇಕ',
'ಅವಸ್ಕಂದ',
'ಅವಸ್ಕರ',
'ಅವಸ್ಥಾನ',
'ಅವಸ್ಥಾಯಿ',
'ಅವಸ್ಥಿತ',
'ಅವಸ್ಪಂದ',
'ಅವಹಿತ',
'ಅವಹಿತ್ಥ',
'ಅವಹಿತ್ಥೆ',
'ಅವಳಂಬ',
'ಅವಳಿಸು',
'ಅವಳ್ದಿರ್',
'ಅವಾಂತರ',
'ಅವಾಕ್ಕಾಗು',
'ಅವಾಕ್ಛ್ರುತಿ',
'ಅವಾಗ್ಭವ',
'ಅವಾಗ್ಭಾಗ',
'ಅವಾಙ್ಮುಖ',
'ಅವಾಙ್ಮುಖಿ',
'ಅವಾಚಕ',
'ಅವಾಚೀನ',
'ಅವಾಢವ್ಯ',
'ಅವಾರಿತ',
'ಅವಾರಿತಂ',
'ಅವಾಸ್ತವ',
'ಅವಾಹಕ',
'ಅವಿಕಲ',
'ಅವಿಕಲೆ',
'ಅವಿಕಲ್ಪ',
'ಅವಿಕಲ್ಪಂ',
'ಅವಿಕಳ',
'ಅವಿಕಳೆ',
'ಅವಿಕಾರ',
'ಅವಿಕಾರಿ',
'ಅವಿಕಾರ್ಯ',
'ಅವಿಕೃತ',
'ಅವಿಕೃತಿ',
'ಅವಿಗೊಳ್',
'ಅವಿಗೋಪ',
'ಅವಿಗ್ರಹ',
'ಅವಿಚಲ',
'ಅವಿಚಳ',
'ಅವಿಚಾರ',
'ಅವಿಚಾರಿ',
'ಅವಿಚಾರ್ಯ',
'ಅವಿಚ್ಛಿನ್ನ',
'ಅವಿಚ್ಛೇದ',
'ಅವಿಜ್ಞೇಯ',
'ಅವಿತಥ',
'ಅವಿತಕ್ರ್ಯ',
'ಅವಿದಿತ',
'ಅವಿದೂರ',
'ಅವಿದ್ಯಕ',
'ಅವಿಧೇಯ',
'ಅವಿನಯ',
'ಅವಿನಶ್ಯ',
'ಅವಿನಾಣ',
'ಅವಿನಾಶ',
'ಅವಿನಾಶಿ',
'ಅವಿನೀತ',
'ಅವಿನೀತೆ',
'ಅವಿನ್ನಾಣ',
'ಅವಿಫಲ',
'ಅವಿಭಕ್ತ',
'ಅವಿಭಾಗ',
'ಅವಿಭಾಜ್ಯ',
'ಅವಿಭಾವ್ಯ',
'ಅವಿಭೇದಿ',
'ಅವಿಮುಕ್ತ',
'ಅವಿಮೃಷ್ಟ',
'ಅವಿಯಿಸು',
'ಅವಿರತ',
'ಅವಿರತಂ',
'ಅವಿರತಿ',
'ಅವಿರಲ',
'ಅವಿರಳ',
'ಅವಿರುದ್ಧ',
'ಅವಿರೋಧ',
'ಅವಿರೋಧಿ',
'ಅವಿಲಂಬ',
'ಅವಿಲಂಬಂ',
'ಅವಿಲಣ',
'ಅವಿವೃತ್ತ',
'ಅವಿವೇಕ',
'ಅವಿವೇಕಿ',
'ಅವಿಶಾಲ',
'ಅವಿಶಿಷ್ಟ',
'ಅವಿಶೇಷ',
'ಅವಿಶ್ರಾಂತ',
'ಅವಿಶ್ವಾಸ',
'ಅವಿಶ್ವಾಸಿ',
'ಅವಿಷಮ',
'ಅವಿಷಯ',
'ಅವಿಸಂಗ',
'ಅವಿಸೋಢ',
'ಅವಿಸ್ತಾರ',
'ಅವಿಸ್ಪಷ್ಟ',
'ಅವಿಹಿತ',
'ಅವಿಳಂಬ',
'ಅವಿಳಂಬಂ',
'ಅವುಂಕಿಸು',
'ಅವುಂಕುಹ',
'ಅವುಂಗುರು',
'ಅವುಂಡಲ',
'ಅವುಕಾಟ',
'ಅವುಕಿಸು',
'ಅವುಕುಳಿ',
'ಅವುಡಲ',
'ಅವುತಣ',
'ಅವುತನ',
'ಅವೆತ್ತಲು',
'ಅವೇಕ್ಷಣ',
'ಅವೇಕ್ಷಣೆ',
'ಅವೇದಕ',
'ಅವೇದನೆ',
'ಅವೈದಿಕ',
'ಅವೈರಾಗ್ಯ',
'ಅವ್ಯಕ್ತತೆ',
'ಅವ್ಯಗ್ರತೆ',
'ಅವ್ಯಪೇತ',
'ಅವ್ಯಯತೆ',
'ಅವ್ಯವಸ್ಥೆ',
'ಅವ್ಯಸನ',
'ಅವ್ಯಸನಿ',
'ಅವ್ಯಾಕುಲ',
'ಅವ್ಯಾಕುಲಂ',
'ಅವ್ಯಾಕುಳ',
'ಅವ್ಯಾಕುಳಂ',
'ಅವ್ಯಾಕುಲೆ',
'ಅವ್ಯಾಕೃತ',
'ಅವ್ಯಾಪಕ',
'ಅವ್ಯಾಪಾರ',
'ಅವ್ಯಾಪ್ಯತೆ',
'ಅವ್ಯಾಬಾಧ',
'ಅವ್ಯಾಹತ',
'ಅವ್ಯುತ್ಪತ್ತಿ',
'ಅವ್ಯುತ್ಪನ್ನ',
'ಅವ್ವಡಿಸು',
'ಅವ್ವರಣಿ',
'ಅವ್ವಳಿಸು',
'ಅಶಂಕಿತ',
'ಅಶಕ್ತತೆ',
'ಅಶಕ್ಯತೆ',
'ಅಶನಾಯೆ',
'ಅಶನಾರ್ಥಿ',
'ಅಶರಣ',
'ಅಶರೀರ',
'ಅಶರೀರಿ',
'ಅಶಲ್ಯತೆ',
'ಅಶಾಂತತೆ',
'ಅಶಾಬ್ಧಿಕ',
'ಅಶಾಶ್ವತ',
'ಅಶಾಸ್ತ್ರೀಯ',
'ಅಶಿಕ್ಷಿತ',
'ಅಶಿಕ್ಷಿತೆ',
'ಅಶಿಥಿಲ',
'ಅಶಿಶಿರ',
'ಅಶಿಷ್ಟತೆ',
'ಅಶುಚಿತ್ವ',
'ಅಶುದ್ಧತೆ',
'ಅಶೋಕಿತ',
'ಅಶೋಧಿತ',
'ಅಶೋಭಿತ',
'ಅಶ್ಮಂತಕ',
'ಅಶ್ಮಕಾರ',
'ಅಶ್ಮಕುಟ್ಟ',
'ಅಶ್ಮಗರ್ಭ',
'ಅಶ್ಮಪುಷ್ಪ',
'ಅಶ್ಮಯುಗ',
'ಅಶ್ಮಯೋನಿ',
'ಅಶ್ಮಸಾರ',
'ಅಶ್ಮಾತಕ',
'ಅಶ್ರಕೀಯ',
'ಅಶ್ರಾವ್ಯತೆ',
'ಅಶ್ರುಕಣ',
'ಅಶ್ರುಗ್ರಂಥಿ',
'ಅಶ್ರುಜಲ',
'ಅಶ್ರುನಾಳ',
'ಅಶ್ರುಬೀಜ',
'ಅಶ್ರುಭರ',
'ಅಶ್ರುರಸ',
'ಅಶ್ರುವಾಯು',
'ಅಶ್ರುವಾರಿ',
'ಅಶ್ರುವೃಷ್ಟಿ',
'ಅಶ್ರುಸ್ನಾತ',
'ಅಶ್ರುಸ್ರವೆ',
'ಅಶ್ರೇಯಸ್ಸು',
'ಅಶ್ಲೀಲತೆ',
'ಅಶ್ವಕರ್ಣ',
'ಅಶ್ವಗಂಧ',
'ಅಶ್ವಗಂಧಿ',
'ಅಶ್ವಗಂಧೆ',
'ಅಶ್ವಗತಿ',
'ಅಶ್ವಗ್ರೀವ',
'ಅಶ್ವಜಿತ',
'ಅಶ್ವತರ',
'ಅಶ್ವತರಿ',
'ಅಶ್ವತ್ಥಾಮ',
'ಅಶ್ವದಳ',
'ಅಶ್ವಪತಿ',
'ಅಶ್ವಪಾಲ',
'ಅಶ್ವಪ್ರಾಸ',
'ಅಶ್ವಪ್ರಾಸು',
'ಅಶ್ವಪ್ರೇಮಿ',
'ಅಶ್ವಬಲ',
'ಅಶ್ವಮಾರ',
'ಅಶ್ವಮುಖ',
'ಅಶ್ವಮೇಧ',
'ಅಶ್ವಯಜ್ಞ',
'ಅಶ್ವಯುಜ',
'ಅಶ್ವರಥ',
'ಅಶ್ವರಾಜ',
'ಅಶ್ವಲಕ್ಷ್ಮಿ',
'ಅಶ್ವವೈದ್ಯ',
'ಅಶ್ವಶಕ್ತಿ',
'ಅಶ್ವಶಾಲೆ',
'ಅಶ್ವಶಾಸ್ತ್ರ',
'ಅಶ್ವಶಿಕ್ಷೆ',
'ಅಶ್ವಸ್ತನ',
'ಅಶ್ವಾರೂಢ',
'ಅಶ್ವಾರೋಹ',
'ಅಶ್ವಾರೋಹಿ',
'ಅಷಡ್ಡಾಳ',
'ಅಷಡ್ಢಾಳ',
'ಅಷರಫಿ಼',
'ಅಷರಾಫ಼್',
'ಅಷ್ಟಕರಿ',
'ಅಷ್ಟಕರ್ಮ',
'ಅಷ್ಟಕಷ್ಟ',
'ಅಷ್ಟಕಾಂಗ',
'ಅಷ್ಟಕೋಣ',
'ಅಷ್ಟಗಂಧ',
'ಅಷ್ಟಗುಣ',
'ಅಷ್ಟಗ್ರಾಮ',
'ಅಷ್ಟತನು',
'ಅಷ್ಟತಾಲ',
'ಅಷ್ಟತಾಳ',
'ಅಷ್ಟತೂರ್ಯ',
'ಅಷ್ಟದಲ',
'ಅಷ್ಟದಳ',
'ಅಷ್ಟದಿಕ್ಕು',
'ಅಷ್ಟದೇಹಿ',
'ಅಷ್ಟದ್ರವ್ಯ',
'ಅಷ್ಟನಾಗ',
'ಅಷ್ಟಪತ್ರ',
'ಅಷ್ಟಪದಿ',
'ಅಷ್ಟಪಾದ',
'ಅಷ್ಟಪಾದಿ',
'ಅಷ್ಟಪಾಶ',
'ಅಷ್ಟಪೈಲು',
'ಅಷ್ಟಫಣಿ',
'ಅಷ್ಟಭಕ್ತಿ',
'ಅಷ್ಟಭಾಗ್ಯ',
'ಅಷ್ಟಭುಜ',
'ಅಷ್ಟಭೋಗ',
'ಅಷ್ಟಮದ',
'ಅಷ್ಟಮಲ',
'ಅಷ್ಟಮಾಂಶ',
'ಅಷ್ಟಮೀಂದು',
'ಅಷ್ಟಮುಖಿ',
'ಅಷ್ಟಮೂರ್ತಿ',
'ಅಷ್ಟವರ್ಗ',
'ಅಷ್ಟಸಿದ್ಧಿ',
'ಅಷ್ಟಾಕ್ಷರಿ',
'ಅಷ್ಟಾದಶ',
'ಅಷ್ಟಾಪದ',
'ಅಷ್ಟಾವಂಕ',
'ಅಷ್ಟಾವಕ್ರ',
'ಅಷ್ಟಾಶೀತಿ',
'ಅಷ್ಟಾಹ್ನಿಕ',
'ಅಷ್ಟುಪಾಸ',
'ಅಷ್ಟುಪಾಸಿ',
'ಅಷ್ಟೈಶ್ವರ್ಯ',
'ಅಷ್ಟೋತ್ತರ',
'ಅಷ್ಠಿಫಲ',
'ಅಸಂಕೀರ್ಣ',
'ಅಸಂಕ್ಲುಪ್ತ',
'ಅಸಂಖ್ಯಾಕ',
'ಅಸಂಖ್ಯಾತ',
'ಅಸಂಖ್ಯೇಯ',
'ಅಸಂಗತ',
'ಅಸಂಗತಿ',
'ಅಸಂಚಿತ',
'ಅಸಂತತ',
'ಅಸಂತುಷ್ಟ',
'ಅಸಂತುಷ್ಟಿ',
'ಅಸಂತೃಪ್ತ',
'ಅಸಂತೃಪ್ತಿ',
'ಅಸಂತೋಷ',
'ಅಸಂದರ್ಭ',
'ಅಸಂದಿಗ್ಧ',
'ಅಸಂಧೇಯ',
'ಅಸಂಪಾತ',
'ಅಸಂಪೂರ್ಣ',
'ಅಸಂಬಂಧ',
'ಅಸಂಬದ್ಧ',
'ಅಸಂಬಳ',
'ಅಸಂಬಾಧ',
'ಅಸಂಭವ',
'ಅಸಂಭಾವ್ಯ',
'ಅಸಂಯತ',
'ಅಸಂಯಮ',
'ಅಸಂಯಮಿ',
'ಅಸಂಯುಕ್ತ',
'ಅಸಂಯುತ',
'ಅಸಂವೇದಿ',
'ಅಸಂಶಯ',
'ಅಸಂಸಕ್ತ',
'ಅಸಂಸಾರಿ',
'ಅಸಂಸ್ಕಾರ',
'ಅಸಂಸ್ಕಾರಿ',
'ಅಸಂಸ್ಕೃತ',
'ಅಸಂಸ್ಕೃತಿ',
'ಅಸಂಸ್ಕೃತೆ',
'ಅಸಂಹತ',
'ಅಸಕಳಿ',
'ಅಸಕೞಿ',
'ಅಸಕೊಳ್ಳು',
'ಅಸಗೞಿ',
'ಅಸಗಿತಿ',
'ಅಸಗೆಯ್',
'ಅಸಗೊಳ್ಳು',
'ಅಸಜ್ಜನ',
'ಅಸಡಾಳ',
'ಅಸಡ್ಡಾಳ',
'ಅಸಡ್ಡಾಳು',
'ಅಸಡ್ಡಿಸು',
'ಅಸಡ್ಡೆಗೈ',
'ಅಸಡ್ಢಾಳ',
'ಅಸತ್ಕಥೆ',
'ಅಸತ್ಕರ್ಮ',
'ಅಸತ್ಕಾರ',
'ಅಸತ್ಕಾರ್ಯ',
'ಅಸತ್ಕೃತಿ',
'ಅಸತ್ಯತೆ',
'ಅಸದರ್ಥ',
'ಅಸದಳ',
'ಅಸದಳಂ',
'ಅಸದೃಶ',
'ಅಸದ್ವ್ಯಯ',
'ಅಸದ್ವಾದ',
'ಅಸನರಿ',
'ಅಸಫಲ',
'ಅಸಬಡಿ',
'ಅಸಭ್ಯತೆ',
'ಅಸಮಗ್ರ',
'ಅಸಮತೆ',
'ಅಸಮಯ',
'ಅಸಮರ್ಥ',
'ಅಸಮರ್ಥೆ',
'ಅಸಮಸೆ',
'ಅಸಮಸ್ತ',
'ಅಸಮಾಂಗ',
'ಅಸಮಾಕ್ಷ',
'ಅಸಮಾನ',
'ಅಸಮಾಪ್ತ',
'ಅಸಮಾಪ್ತಿ',
'ಅಸಮಾಸ',
'ಅಸಮಾಸ್ತ್ರ',
'ಅಸಮಿಸಿ',
'ಅಸಮೀಕ್ಷೆ',
'ಅಸಮ್ಮತ',
'ಅಸಮ್ಮತಿ',
'ಅಸರಂತ',
'ಅಸರಫಿ',
'ಅಸವನೆ',
'ಅಸವರ್ಣ',
'ಅಸವಸ',
'ಅಸವಸೆ',
'ಅಸವಳಿ',
'ಅಸಹಜ',
'ಅಸಹನ',
'ಅಸಹನೆ',
'ಅಸಹಾಯ',
'ಅಸಹಾಯೆ',
'ಅಸಹಿಷ್ಣು',
'ಅಸಹಿಸು',
'ಅಸಹ್ಯತೆ',
'ಅಸಹ್ಯೋಕ್ತಿ',
'ಅಸಾಂಗತ್ಯ',
'ಅಸಾಂಘಿಕ',
'ಅಸಾಂಪ್ರತ',
'ಅಸಾಕಲ್ಯ',
'ಅಸಾಕ್ಷಿಕ',
'ಅಸಾದೃಶ್ಯ',
'ಅಸಾಧುತೆ',
'ಅಸಾಧ್ಯತೆ',
'ಅಸಾಪೇಕ್ಷ',
'ಅಸಾಫಲ್ಯ',
'ಅಸಾಮಥ್ರ್ಯ',
'ಅಸಾಮಾನ್ಯ',
'ಅಸಾಮಾನ್ಯೆ',
'ಅಸಾರತೆ',
'ಅಸಾದ್ರ್ರ್ರತೆ',
'ಅಸಾವೇರಿ',
'ಅಸಿಂಧುತ್ವ',
'ಅಸಿಕಲೆ',
'ಅಸಿಕಲ್ಲು',
'ಅಸಿಕಾಯ',
'ಅಸಿಗಲ್ಲು',
'ಅಸಿತತೆ',
'ಅಸಿತಾಶ್ಮ',
'ಅಸಿತಾಹಿ',
'ಅಸಿದನಿ',
'ಅಸಿಧಾರೆ',
'ಅಸಿಧೇನು',
'ಅಸಿನಡು',
'ಅಸಿಪತ್ರ',
'ಅಸಿಪರ್ಣಿ',
'ಅಸಿಪುತ್ರಿ',
'ಅಸಿಮಸೆ',
'ಅಸಿಮಿಸಿ',
'ಅಸಿಯರೆ',
'ಅಸಿಯಿಡು',
'ಅಸಿಯೇಱು',
'ಅಸಿಯೊಱೆ',
'ಅಸಿವಸಿ',
'ಅಸಿವ್ರತ',
'ಅಸಿಹೇತಿ',
'ಅಸುಂಗೊಳ್',
'ಅಸುಂದರ',
'ಅಸುಂದೊಱೆ',
'ಅಸುಗತಿ',
'ಅಸುಗಳೆ',
'ಅಸುಗುಡಿ',
'ಅಸುಗೆಡು',
'ಅಸುಗೊಳ್',
'ಅಸುಗೊಳ್ಳು',
'ಅಸುದೆಗೆ',
'ಅಸುದೊಱೆ',
'ಅಸುಮೋಕ್ಷ',
'ಅಸುವಡೆ',
'ಅಸುವಳಿ',
'ಅಸುವೞಿ',
'ಅಸುವಿಡಿ',
'ಅಸುವಿಡು',
'ಅಸುವೆಳೆ',
'ಅಸುಹೃತ್ತು',
'ಅಸುಹೃದ',
'ಅಸೂಯಕ',
'ಅಸೃಕ್ಕಣ',
'ಅಸೃಗ್ದರ',
'ಅಸೃಗ್ಧರ',
'ಅಸೃಗ್ಧರೆ',
'ಅಸೆಕಲ್ಲು',
'ಅಸೇಚಕ',
'ಅಸೇಚನ',
'ಅಸೌಕರ್ಯ',
'ಅಸೌಹಾರ್ದ',
'ಅಸ್ಖಲಿತ',
'ಅಸ್ಖಲಿತಂ',
'ಅಸ್ಖಳಿತ',
'ಅಸ್ಖಳಿತಂ',
'ಅಸ್ತಂಗತ',
'ಅಸ್ತಂಗಿಸು',
'ಅಸ್ತಗತಿ',
'ಅಸ್ತಗಾಮಿ',
'ಅಸ್ತಗಿರಿ',
'ಅಸ್ತಗೃಹ',
'ಅಸ್ತದವ',
'ಅಸ್ತನಗ',
'ಅಸ್ತಪ್ರಾಪ್ತಿ',
'ಅಸ್ತಬೆಸ್ತ',
'ಅಸ್ತಮಯ',
'ಅಸ್ತಮಾನ',
'ಅಸ್ತಮಿತ',
'ಅಸ್ತಮಿಸು',
'ಅಸ್ತರಾಗ',
'ಅಸ್ತರಾಶಿ',
'ಅಸ್ತವ್ಯಸ್ತ',
'ಅಸ್ತಶೈಲ',
'ಅಸ್ತಾಕ್ಷರ',
'ಅಸ್ತಾಗಾರ',
'ಅಸ್ತಾಚಲ',
'ಅಸ್ತಾಚಳ',
'ಅಸ್ತಾವಸ್ತೆ',
'ಅಸ್ತಿಕಾಯ',
'ಅಸ್ತಿಭಾರ',
'ಅಸ್ತಿಮಿತ',
'ಅಸ್ತಿವಳ್ಳಿ',
'ಅಸ್ತಿವಾರ',
'ಅಸ್ತುಗುಟ್ಟು',
'ಅಸ್ತುಗೊಡು',
'ಅಸ್ತುಬಡು',
'ಅಸ್ತ್ಯರ್ಥಕ',
'ಅಸ್ತ್ರಗ್ರಾಮ',
'ಅಸ್ತ್ರಜಾಳ',
'ಅಸ್ತ್ರಜೀವ',
'ಅಸ್ತ್ರಜೀವಿ',
'ಅಸ್ತ್ರತತಿ',
'ಅಸ್ತ್ರರುಚಿ',
'ಅಸ್ತ್ರವಿದ್ಯೆ',
'ಅಸ್ತ್ರಶಾಲೆ',
'ಅಸ್ತ್ರಾಗಾರ',
'ಅಸ್ಥಾಯಿತ್ವ',
'ಅಸ್ಥಿಗತ',
'ಅಸ್ಥಿಚರ್ಮಿ',
'ಅಸ್ಥಿಪಟ್ಟಿ',
'ಅಸ್ಥಿಬಂಧ',
'ಅಸ್ಥಿಭಂಗ',
'ಅಸ್ಥಿಮತ್ಸ್ಯ',
'ಅಸ್ಥಿರಜ್ಜು',
'ಅಸ್ಥಿರತೆ',
'ಅಸ್ಥಿರತ್ವ',
'ಅಸ್ಥಿವಳ್ಳಿ',
'ಅಸ್ಥಿಶಾಸ್ತ್ರ',
'ಅಸ್ಥಿಸಾರ',
'ಅಸ್ಥಿಸ್ರಾವ',
'ಅಸ್ನಿಗ್ಧತೆ',
'ಅಸ್ಪಂದತೆ',
'ಅಸ್ಪಷ್ಟತೆ',
'ಅಸ್ಪೃಶ್ಯತೆ',
'ಅಸ್ಫಟಿಕ',
'ಅಸ್ಫುಟತೆ',
'ಅಸ್ಫುರಿತ',
'ಅಸ್ಮದೀಯ',
'ಅಸ್ಮರಣ',
'ಅಸ್ವಚ್ಛಂದ',
'ಅಸ್ವತಂತ್ರ',
'ಅಸ್ವಪ್ನಾದ್ರಿ',
'ಅಸ್ವಸ್ಥತೆ',
'ಅಸ್ವಾಧೀನ',
'ಅಸ್ವಾಧ್ಯಾಯ',
'ಅಸ್ವಾರಸ್ಯ',
'ಅಸ್ವೀಕಾರ',
'ಅಸ್ವೀಕೃತ',
'ಅಸ್ವೈರಿಣಿ',
'ಅಹಂಕಾರ',
'ಅಹಂಕಾರಿ',
'ಅಹಂಕೃತಿ',
'ಅಹಂಕ್ರಿಯೆ',
'ಅಹಂತವ್ಯ',
'ಅಹಂಪೂರ್ವಂ',
'ಅಹಂಭಾವ',
'ಅಹಂಮತಿ',
'ಅಹಂಮದಿ',
'ಅಹಂಮನ್ಯ',
'ಅಹಂವಾದ',
'ಅಹಂವಾದಿ',
'ಅಹಃಪತಿ',
'ಅಹಮಿಂದ್ರ',
'ಅಹಮಿಕೆ',
'ಅಹಮ್ಮಿಸು',
'ಅಹರಹ',
'ಅಹರಹಂ',
'ಅಹರ್ನಿಶ',
'ಅಹರ್ನಿಶಂ',
'ಅಹರ್ನಿಶಿ',
'ಅಹರ್ಪತಿ',
'ಅಹರ್ಮುಖ',
'ಅಹಲ್ಲಾದ',
'ಅಹವಾಲ್',
'ಅಹವಾಲು',
'ಅಹಷಾಮ್',
'ಅಹಸ್ಕರ',
'ಅಹಸ್ಪತಿ',
'ಅಹಿಕಾಂತ',
'ಅಹಿಕುಲ',
'ಅಹಿಗೃಹ',
'ಅಹಿತತ್ವ',
'ಅಹಿಭಯ',
'ಅಹಿಭುಕ್ಕು',
'ಅಹಿಮಾಂಶು',
'ಅಹಿರಣಿ',
'ಅಹಿರ್ಬುಧ್ನ್ಯ',
'ಅಹೀರಣಿ',
'ಅಹುದಾಗು',
'ಅಹುದೆನು',
'ಅಹುದೆನ್ನು',
'ಅಹೇತುಕ',
'ಅಹೋಧ್ವಾನ',
'ಅಹೋರಾತ್ರ',
'ಅಹೋರಾತ್ರಂ',
'ಅಹೋರಾತ್ರಿ',
'ಅಹೋರಾತ್ರೆ',
'ಅಹ್ರಿಮನ್',
'ಅಳಂಕೃತ',
'ಅಳಂಕೃತಿ',
'ಅಳಂಕ್ರಿಯೆ',
'ಅಳಕಲು',
'ಅಳಕಿಸು',
'ಅಳಗಾರ',
'ಅಳದಕ್ಕಿ',
'ಅಳಬಳ',
'ಅಳರೆಲೆ',
'ಅಳಲಾಡು',
'ಅಳಲಿಕೆ',
'ಅಳಲಿಗ',
'ಅಳಲಿಸು',
'ಅಳಲುರಿ',
'ಅಳಲ್ಗಿಚ್ಚು',
'ಅಳಲ್ವೆಂಕೆ',
'ಅಳವಡು',
'ಅಳವರಿ',
'ಅಳವಳ',
'ಅಳವಳಿ',
'ಅಳವಳ್ಳ',
'ಅಳವಱಿ',
'ಅಳವೞಿ',
'ಅಳವಾನು',
'ಅಳವಿಗ',
'ಅಳಸಂದೆ',
'ಅಳಱಿಸು',
'ಅಳಿಂದಕ',
'ಅಳಿಕಾಕ್ಷ',
'ಅಳಿಕಿಸು',
'ಅಳಿಕೂಸು',
'ಅಳಿಕೊಂಡಿ',
'ಅಳಿಗಂಡು',
'ಅಳಿಗಣ್',
'ಅಳಿಗಣ್ಣ',
'ಅಳಿಗಣ್ಣು',
'ಅಳಿಗಪ್ಪು',
'ಅಳಿಗರ್ವ',
'ಅಳಿಗವಿ',
'ಅಳಿಗಾಲ',
'ಅಳಿಗುಳಿ',
'ಅಳಿಗೆರೆ',
'ಅಳಿಜಸ',
'ಅಳಿನುಡಿ',
'ಅಳಿನೆತ್ತಿ',
'ಅಳಿಪಳಿ',
'ಅಳಿಪಾತ್ರೆ',
'ಅಳಿಪಾನ',
'ಅಳಿಬಾಳ್',
'ಅಳಿಬಾಳು',
'ಅಳಿಬೀಳು',
'ಅಳಿಬೇನೆ',
'ಅಳಿಮನ',
'ಅಳಿಮಾಳು',
'ಅಳಿಯಮ್ಮ',
'ಅಳಿಯಾಸೆ',
'ಅಳಿಯಾಳು',
'ಅಳಿಯಿಸು',
'ಅಳಿಯೂಟ',
'ಅಳಿಯೂರು',
'ಅಳಿರ್ವಳ್ಕು',
'ಅಳಿಲುಳಿ',
'ಅಳಿವಡೆ',
'ಅಳಿವಣ್ಣ',
'ಅಳಿವಱಿ',
'ಅಳಿವೆಣ್',
'ಅಳಿವೆದೆ',
'ಅಳಿಶೌರ್ಯ',
'ಅಳುಂದಾರೆ',
'ಅಳುಂಬಿಸು',
'ಅಳುಕಿಸು',
'ಅಳುಕುಳಿ',
'ಅಳುಗುಣಿ',
'ಅಳುಗುನ್ನಿ',
'ಅಳುಗುಳಿ',
'ಅಳುಗೂಸು',
'ಅಳುದನಿ',
'ಅಳುಬೇನೆ',
'ಅಳುಮುಂಜಿ',
'ಅಳುಮುಖ',
'ಅಳುಮೊಗ',
'ಅಳುಮೋರೆ',
'ಅಳೆಗಳೆ',
'ಅಳೆಗೊಳು',
'ಅಳೆಪಾಯ',
'ಅಳೆಬೀಯ',
'ಅಳೆಯಿಸು',
'ಅಳ್ಕೆಕುತ್ತು',
'ಅಳ್ಗೆಸಱ್',
'ಅಳ್ತಿಕಾಱ',
'ಅಳ್ತಿವಂದಿ',
'ಅಳ್ತಿವಡು',
'ಅಳ್ಳಬರು',
'ಅಳ್ಳಾಡಿಸು',
'ಅಳ್ಳಿಕುಂಚ',
'ಅಳ್ಳಿಬಲೆ',
'ಅಳ್ಳಿಱಿಸು',
'ಅಳ್ಳೆಕುಡಿ',
'ಅಳ್ಳೆಗುಕ್ಕು',
'ಅಳ್ಳೆಗುತ್ತು',
'ಅಳ್ಳೆಗೊಂಬು',
'ಅಳ್ಳೆಜೊಳ್ಳು',
'ಅಳ್ಳೆಪರೆ',
'ಅಳ್ಳೆಪೊರೆ',
'ಅಳ್ಳೆಬಾಱು',
'ಅಳ್ಳೆವಿಳ್ಳೆ',
'ಅಳ್ಳೆವೊಯ್',
'ಅಳ್ಳೆಹೊಯ್',
'ಅಳ್ಳೆಹೊಯ್ಕ',
'ಅಳ್ಳೊಱತೆ',
'ಅಱಗಂಜಿ',
'ಅಱಗುಲಿ',
'ಅಱಗೆಱೆ',
'ಅಱಗೇಡು',
'ಅಱಚಲು',
'ಅಱಚಾಟ',
'ಅಱಚಾಡು',
'ಅಱಮಗ',
'ಅಱಮಾನ್ಯ',
'ಅಱಗೊಳ್',
'ಅಱಲ್ಗೊಳ್',
'ಅಱವಟ್ಟು',
'ಅಱವಟ್ಟೆ',
'ಅಱವಿಡು',
'ಅಱವುಳ್ಳಿ',
'ಅಱಿಕೆಗೈ',
'ಅಱಿಗುಱಿ',
'ಅಱಿಯಿಸು',
'ಅಱಿಲ್ವಟ್ಟೆ',
'ಅಱಿವಗೆ',
'ಅಱಿವಣ',
'ಅಱಿವಿಡಿ',
'ಅಱಿಹಿಸು',
'ಅಱುಕಾಱ',
'ಅಱುಕುಳಿ',
'ಅಱುಗಯ್',
'ಅಱುಗೆಯ್',
'ಅಱುಗೆಱೆ',
'ಅಱುಗೊಳ್',
'ಅಱುನೀರ್',
'ಅಱುನೂರು',
'ಅಱುಮೊಗ',
'ಅಱುಮೊೞ',
'ಅಱುವಗೆ',
'ಅಱುವಡಿ',
'ಅಱುವತ್ತು',
'ಅಱುವರ್',
'ಅಱುವುಳ್ಳಿ',
'ಅಱುಹಿಗ',
'ಅಱುಹಿಸು',
'ಅಱೆಗಲ್',
'ಅಱೆಗಲ್ಲು',
'ಅಱೆಗುಂಡು',
'ಅಱೆಗೇಡು',
'ಅಱೆಗೊಲೆ',
'ಅಱೆಯಟ್ಟು',
'ಅಱೆವಳೆ',
'ಅಱೆವೊಯ್',
'ಅಱೆವೊಯ್ಲು',
'ಅಱೆವೊಱೆ',
'ಅೞಲಿಗ',
'ಅೞಲಿಸು',
'ಅೞವೞ',
'ಅೞಿಗಂಡ',
'ಅೞಿಗಂಡು',
'ಅೞಿಗಜ್ಜ',
'ಅೞಿಗಣ್',
'ಅೞಿಗಣ್ಣ',
'ಅೞಿಗಣ್ಣು',
'ಅೞಿಗಬ್ಬ',
'ಅೞಿಗವಿ',
'ಅೞಿಗಾಲ',
'ಅೞಿಗೆಯ್ತ',
'ಅೞಿಗೆಯ್ಮೆ',
'ಅೞಿಚಟ್ಟು',
'ಅೞಿಚಾಗಿ',
'ಅೞಿಜಪ',
'ಅೞಿಜಸ',
'ಅೞಿದೆಸೆ',
'ಅೞಿನುಡಿ',
'ಅೞಿಪಾಡು',
'ಅೞಿಪಾಲು',
'ಅೞಿಪೂಜೆ',
'ಅೞಿಬಂಟು',
'ಅೞಿಬರ್ದ',
'ಅೞಿಭವ',
'ಅೞಿಮಂತ್ರ',
'ಅೞಿಮನ',
'ಅೞಿಮೋಹ',
'ಅೞಿಯಾಸೆ',
'ಅೞಿಯಾಳ್',
'ಅೞಿಯಿಸು',
'ಅೞಿಯೂರ್',
'ಅೞಿವಗೆ',
'ಅೞಿವಗ್ಗು',
'ಅೞಿವಡೆ',
'ಅೞಿವಾಡ',
'ಅೞಿವಾದ',
'ಅೞಿವಿಧಿ',
'ಅೞಿವೆಜ್ಜ',
'ಅೞಿವೆಣ್',
'ಅೞಿಸುಖ',
'ಅೞಿಸೊಗ',
'ಅೞ್ಕರ್ತಳ್',
'ಅೞ್ಕಾಡಿಸು',
'ಅೞ್ಕಿಮೆೞ್ಕು',
'ಅೞ್ಗುತೞ್ಗು',
'ಅೞ್ಗುಮೞ್ಗು',
'ಅೞ್ತಿಕಾರ್ತಿ',
'ಅೞ್ತಿಕಾಱ',
'ಅೞ್ತಿಗುಂದು',
'ಅೞ್ತಿವಡು',
'ಅೞ್ತಿವಡೆ',
'ಆಂಕೆಗಂಭ',
'ಆಂಕೆಗುಡು',
'ಆಂಕೆಗೆಯ್',
'ಆಂಕೆಗೊಡು',
'ಆಂಕೆಗೊಳ್',
'ಆಂಕೆಮಾಡು',
'ಆಂಕೆಯಾಳ್',
'ಆಂಕೆಯಾಳು',
'ಆಂಗಿರಸ',
'ಆಂಗೀರಸ',
'ಅಂಗೊಳಗು',
'ಆಂಗ್ಲಭಾಷೆ',
'ಆಂಜನೇಯ',
'ಆಂಡಶ್ವೇತ',
'ಆಂತರಿಕ',
'ಆಂತರಿಕೆ',
'ಆಂತರ್ಯಕ',
'ಆಂತ್ರರಸ',
'ಆಂತ್ರವಾಯು',
'ಆಂತ್ರಸ್ರಜ',
'ಆಂದಿಗಲ್',
'ಆಂದುಗಲ್',
'ಆಂದೋಲಕ',
'ಆಂದೋಲನ',
'ಆಂದೋಲಿಕ',
'ಆಂದೋಲಿತ',
'ಆಂದೋಲಿಸು',
'ಆಂದೋಳನ',
'ಆಂದೋಳಿಕ',
'ಆಂದೋಳಿಕೆ',
'ಆಂದೋಳಿತ',
'ಆಂದೋಳಿಸು',
'ಆಂಧಸಿಕ',
'ಆಂಶಿಕತೆ',
'ಆಕಂಪನ',
'ಆಕಂಪಿತ',
'ಆಕಂಪಿಸು',
'ಆಕರಣ',
'ಆಕರಣೆ',
'ಆಕರಿಕೆ',
'ಆಕರಿಸು',
'ಆಕರಿಸು4',
'ಆಕರ್ಣಕ',
'ಆಕರ್ಣನ',
'ಆಕರ್ಣಾಂತ',
'ಆಕರ್ಣಾಂತಂ',
'ಆಕರ್ಣಿಸು',
'ಆಕರ್ಷಕ',
'ಆಕರ್ಷಣ',
'ಆಕರ್ಷಣೆ',
'ಆಕರ್ಷಿತ',
'ಆಕರ್ಷಿಸು',
'ಆಕಲನ',
'ಆಕಲನೆ',
'ಆಕಲಿತ',
'ಆಕಲಿಸು',
'ಆಕಸ್ಮಿಕ',
'ಆಕಸ್ಮಿಕಂ',
'ಆಕಸ್ಮೀಕ',
'ಆಕಳಾಸ',
'ಆಕಳಿಕೆ',
'ಆಕಳಿತ',
'ಆಕಳಿಸು',
'ಆಕಳೇರು',
'ಆಕಾಂಕ್ಷಣ',
'ಆಕಾಂಕ್ಷಿತ',
'ಆಕಾಂಕ್ಷಿಸು',
'ಆಕಾರಣ',
'ಆಕಾರಣೆ',
'ಆಕಾರಿಕ',
'ಆಕಾಲಿಕಿ',
'ಆಕಾಶಸ್ಥ',
'ಆಕಾಳಿಕಿ',
'ಆಕಿಂಚನ್ಯ',
'ಆಕೀರ್ಣತೆ',
'ಆಕುಂಚನ',
'ಆಕುಂಚಿತ',
'ಆಕುಂಚಿಸು',
'ಆಕುಲಕ',
'ಆಕುಲತೆ',
'ಆಕುಲತ್ತೆ',
'ಆಕುಲಿತ',
'ಆಕುಲಿತೆ',
'ಆಕುಳಕ',
'ಆಕುಳಿಕೆ',
'ಅಕುಳಿತ',
'ಅಕುಳಿಸು',
'ಆಕೃತಿಮಾ',
'ಆಕೃತೀಯ',
'ಆಕೆವಾಳ',
'ಆಕೇಕರ',
'ಆಕ್ರಂದನ',
'ಆಕ್ರಂದಿತ',
'ಆಕ್ರಂದಿಸು',
'ಆಕ್ರಮಣ',
'ಆಕ್ರಮಣೆ',
'ಆಕ್ರಮಿತ',
'ಆಕ್ರಮಿಸು',
'ಆಕ್ರಾಮಕ',
'ಆಕ್ರೋಶನ',
'ಆಕ್ರೋಶಿಸು',
'ಆಕ್ಷೇಪಕ',
'ಆಕ್ಷೇಪಣ',
'ಆಕ್ಷೇಪಣೆ',
'ಆಕ್ಷೇಪಾರ್ಹ',
'ಆಕ್ಷೇಪಿತ',
'ಆಕ್ಷೇಪಿಸು',
'ಆಖಂಡಲ',
'ಆಖಂಡಳ',
'ಆಖುದ್ವಿಷ',
'ಆಖುಭುಕ್ಕು',
'ಆಖುಭುಜ',
'ಆಖುರಥ',
'ಆಖುಶಾಬ',
'ಆಖೇಟಕ',
'ಆಖೇಟಕಿ',
'ಆಖ್ಯಾತಕ',
'ಆಖ್ಯಾತಿಸು',
'ಆಖ್ಯಾನಕ',
'ಆಖ್ಯಾಯಕ',
'ಆಖ್ಯಾಯಿಕೆ',
'ಆಗಂತುಕ',
'ಆಗಗಾಡಿ',
'ಆಗಡಿಗ',
'ಆಗಭೋಗ',
'ಆಗಮಜ್ಞ',
'ಆಗಮಜ್ಞೆ',
'ಆಗಮನ',
'ಆಗಮಿಕ',
'ಆಗಮಿಸು',
'ಆಗಮೀಕ',
'ಆಗಮೋಕ್ತ',
'ಆಗಮೋಕ್ತಿ',
'ಆಗಲೀಸು',
'ಆಗಸ್ಕರ',
'ಆಗಳಿಕೆ',
'ಆಗಳಿಸು',
'ಆಗಾಂತುಕ',
'ಆಗಾಮಿಕ',
'ಆಗುಂಚೇಗಿ',
'ಆಗುಚೇಗೆ',
'ಆಗುಛೇಗೆ',
'ಆಗುವಳಿ',
'ಆಗುಹೋಗು',
'ಆಗುಳದು',
'ಆಗುಳಿಕೆ',
'ಆಗುಳಿಕ್ಕೆ',
'ಆಗುಳಿರ್ಕೆ',
'ಆಗುಳಿಸು',
'ಆಗ್ನೇಯಾಸ್ತ್ರ',
'ಆಗ್ರಹಿಸು',
'ಆಘಟಿತ',
'ಆಘೂರ್ಮಿಸು',
'ಆಘೋಷಣ',
'ಆಘೋಷಿಸು',
'ಆಘ್ರಾಣಿತ',
'ಆಘ್ರಾಣಿಸು',
'ಆಘ್ರಾಯಿಸು',
'ಆಚಂದ್ರಾರ್ಕ',
'ಆಚಂದ್ರಾರ್ಕಂ',
'ಆಚಮನ',
'ಆಚಮಿಸು',
'ಆಚಯನ',
'ಆಚರಣ',
'ಆಚರಣೆ',
'ಆಚರಿತ',
'ಆಚರಿಸು',
'ಆಚಾರತೆ',
'ಆಚಾರಾಂಗ',
'ಆಚಾರಿಗ',
'ಆಚಾರಿಯ',
'ಆಚಾರ್ಯತೆ',
'ಆಚಾರ್ಯತ್ವ',
'ಆಚಾರ್ಯಾಣಿ',
'ಆಚಾರ್ಯಾನಿ',
'ಆಚುಪೇಚು',
'ಆಚೆಮೊನ್ನೆ',
'ಆಚ್ಛಾದನ',
'ಆಚ್ಛಾದಿತ',
'ಆಚ್ಛಾದಿಸು',
'ಆಚ್ಛುರಿತ',
'ಆಚ್ಛೇದಿಸು',
'ಆಚ್ಛೋಟನ',
'ಆಚ್ಛೋದನ',
'ಆಜವಾನ',
'ಆಜಾನೇಯ',
'ಆಜಿರಂಗ',
'ಆಜಿಶ್ರಾಂತ',
'ಆಜೀವಕ',
'ಆಜೀವಿಕೆ',
'ಆಜುಬಾಜು',
'ಆಜೂಬಾಜು',
'ಆಜುಮೋದ',
'ಆಜ್ಞಾಕ್ಷರ',
'ಆಜ್ಞಾಚಕ್ರ',
'ಆಜ್ಞಾದೀಕ್ಷೆ',
'ಆಜ್ಞಾಧರ',
'ಆಜ್ಞಾಧಾರಿ',
'ಆಜ್ಞಾಪತ್ರ',
'ಆಜ್ಞಾಪನ',
'ಆಜ್ಞಾಪನೆ',
'ಆಜ್ಞಾಪಿಸು',
'ಆಜ್ಞಾಬದ್ಧ',
'ಆಜ್ಞಾಭಂಗ',
'ಆಜ್ಞಾವರ್ತಿ',
'ಆಜ್ಞಾಹಸ್ತ',
'ಆಜ್ಞೆಗೊಳ್ಳು',
'ಆಜ್ಞೆಮಾಡು',
'ಆಜ್ಞೆಮೀರು',
'ಆಜ್ಪಸಾಲೆ',
'ಆಜ್ಯಂಬೊಯ್',
'ಆಜ್ಯಗಟ್ಟಿ',
'ಆಜ್ಯದೀಪ',
'ಆಜ್ಯಪಾತ್ರೆ',
'ಆಜ್ಯಭಾಂಡ',
'ಆಜ್ಯಸ್ಥಾಲಿ',
'ಅಜ್ಯಾಹುತಿ',
'ಆಟಂಗುಳಿ',
'ಆಟಂಗೊಳ್',
'ಆಟಂದರ್',
'ಆಟಂದಲೆ',
'ಆಟಂದಿಕ್ಕು',
'ಆಟಂದಿಱಿ',
'ಆಟಂದೊತ್ತು',
'ಆಟಕಟ್ಟು',
'ಆಟಕಾರ',
'ಆಟಕಾರ್ತಿ',
'ಆಟಕಾಱ',
'ಆಟಕೂಳಿ',
'ಆಟಕೂಟ',
'ಆಟಗಟ್ಟು',
'ಆಟಗಾತಿ',
'ಆಟಗಾಯಿ',
'ಆಟಗಾರ',
'ಆಟಗಾರಿ',
'ಆಟಗಾರ್ತಿ',
'ಆಟಗಾಱ',
'ಆಟಗೀತಿ',
'ಆಟಗೀತೆ',
'ಆಟಗುಳಿ',
'ಆಟಗೂಳಿ',
'ಆಟತಾಳ',
'ಆಟನಾಣಿ',
'ಆಟಪಾಟ',
'ಆಟಮಾಟ',
'ಆಟರಾಗೆ',
'ಆಟರೂಷ',
'ಆಟಲಾಣಿ',
'ಆಟವಿಕ',
'ಆಟವಿಕೆ',
'ಆಟವಿಗ',
'ಆಟವಿಟ',
'ಆಟಹೂಡು',
'ಆಟಿಗದ್ದೆ',
'ಆಟಿಗೊಂಬೆ',
'ಆಟುರಕ',
'ಆಟುಳಿಕ',
'ಆಟೋಪಿಸು',
'ಆಟ್ಟತನ',
'ಆಟ್ಟುವೆಣ್',
'ಆಠವಣೆ',
'ಆಡಂಬರ',
'ಆಡಂಬಳ್ಳಿ',
'ಆಡಯಾಲ',
'ಆಡಲಬು',
'ಆಡಸಾಲ',
'ಆಡಸೋಗೆ',
'ಆಡಳತೆ',
'ಆಡಳಿತ',
'ಆಡಳಿತೆ',
'ಆಡಿಟರು',
'ಆಡುಂಗಾಯ್ತ',
'ಆಡುಂಗಾಱ',
'ಆಡುಂಗುಳಿ',
'ಆಡುಂದೊಲೆ',
'ಆಡುಂಬೊಲ',
'ಆಡುಕುಳಿ',
'ಆಡುಗಬ್ಬ',
'ಆಡುಗಾಡು',
'ಆಡುದೆಱೆ',
'ಆಡುನುಡಿ',
'ಆಡುಬಳ್ಳಿ',
'ಆಡುಬೆಣೆ',
'ಆಡುಭಾಷೆ',
'ಆಡುಮನೆ',
'ಆಡುಮಳೆ',
'ಆಡುಮಾತು',
'ಆಡುಮೊಗ',
'ಆಡುವಳ',
'ಆಡುವೊಲ',
'ಆಡುಸೋಗೆ',
'ಆಡುಳಿಚ',
'ಆಢಕೀನ',
'ಆಣಗಜ್ಜಿ',
'ಆಣತಿಗೈ',
'ಆಣವಿಸು',
'ಆಣವೀನ',
'ಆಣಿಕಲ್ಲು',
'ಆಣಿಕಾರ',
'ಆಣಿಕಾಱ',
'ಆಣಿಗಲ್ಲು',
'ಆಣಿಗಳೆ',
'ಆಣಿಗಾಱ',
'ಆಣಿಮಣಿ',
'ಆಣಿಮೀನು',
'ಆಣಿಮುತ್ತು',
'ಆಣಿವೆಱು',
'ಆಣೆಕಲ್ಲು',
'ಆಣೆಗಲ್ಲು',
'ಆಣೆತಿನ್ನು',
'ಆಣೆದೊಡು',
'ಆಣೆಬಿಡು',
'ಆಣೆಮಾಡು',
'ಆಣೆಮೀಱು',
'ಆಣೆಯಿಕ್ಕು',
'ಆಣೆಯಿಡು',
'ಆಣೆಯೋಲೆ',
'ಆಣೆರಕ',
'ಆಣೆವಣಿ',
'ಆಣೆವಾರಿ',
'ಆಣ್ಪರಿಜು',
'ಆತಂಕಿತ',
'ಆತಂಕಿಸು',
'ಆತಂಚನ',
'ಆತತಜ್ಯ',
'ಆತತಾಯಿ',
'ಆತಪತ್ರ',
'ಆತಿಗುವಿ',
'ಆತಿಥೇಯ',
'ಆತಿಥೇಯಿ',
'ಆತುಕುಡಿ',
'ಆತುರತೆ',
'ಆತುರಿಯ',
'ಆತುರಿಸು',
'ಆತೋದ್ಯಾಂಗ',
'ಆತ್ತಗರ್ವ',
'ಆತ್ತಚಿತ್ತ',
'ಆತ್ತಚಿತ್ತೆ',
'ಆತ್ತಬಳ',
'ಆತ್ತಮನ',
'ಆತ್ಮಂಭರಿ',
'ಆತ್ಮಕಥೆ',
'ಆತ್ಮಕೃತ',
'ಆತ್ಮಕೃತ್ಯ',
'ಆತ್ಮಕೃಷಿ',
'ಆತ್ಮಕ್ಲೇಶ',
'ಆತ್ಮಕ್ಷೇಮ',
'ಆತ್ಮಗತ',
'ಆತ್ಮಗುಣ',
'ಆತ್ಮಗುತ್ತೆ',
'ಆತ್ಮಗುಪ್ತೆ',
'ಆತ್ಮಗ್ಲಾನಿ',
'ಆತ್ಮಘಾತ',
'ಆತ್ಮಘೋಷ',
'ಆತ್ಮಚಿಂತೆ',
'ಆತ್ಮಜನ್ಮ',
'ಆತ್ಮಜಳ',
'ಆತ್ಮಜಾತ',
'ಆತ್ಮಜಾತೆ',
'ಆತ್ಮಜ್ಞತೆ',
'ಆತ್ಮಜ್ಞಾನ',
'ಆತ್ಮಜ್ಞಾನಿ',
'ಆತ್ಮಜ್ಯೋತಿ',
'ಆತ್ಮತತ್ತ್ವ',
'ಆತ್ಮತುಷ್ಟಿ',
'ಆತ್ಮತೃಪ್ತಿ',
'ಆತ್ಮತೃಷ್ಣೆ',
'ಆತ್ಮತೇಜ',
'ಆತ್ಮತ್ಯಾಗ',
'ಆತ್ಮತ್ರಯ',
'ಆತ್ಮದರ್ಶಿ',
'ಆತ್ಮದ್ರೋಹ',
'ಆತ್ಮದ್ರೋಹಿ',
'ಆತ್ಮಧ್ಯಾನ',
'ಆತ್ಮಧ್ಯಾನಿ',
'ಆತ್ಮನಿಂದೆ',
'ಆತ್ಮನಿಷ್ಠ',
'ಆತ್ಮನಿಷ್ಠೆ',
'ಆತ್ಮಪರ',
'ಆತ್ಮಪುಣ್ಯ',
'ಆತ್ಮಪೂಜೆ',
'ಆತ್ಮಪ್ರಜ್ಞೆ',
'ಆತ್ಮಪ್ರಭೆ',
'ಆತ್ಮಪ್ರೀತಿ',
'ಆತ್ಮಬಲ',
'ಆತ್ಮಬೋಧ',
'ಆತ್ಮಭವ',
'ಆತ್ಮಭವೆ',
'ಆತ್ಮಭಾವಿ',
'ಆತ್ಮಮಲ',
'ಆತ್ಮಯೋಗ',
'ಆತ್ಮಯೋಗಿ',
'ಆತ್ಮರಕ್ಷೆ',
'ಆತ್ಮರತಿ',
'ಆತ್ಮರಾಗ',
'ಆತ್ಮರೂಪ',
'ಆತ್ಮಲಾಭ',
'ಆತ್ಮಲಿಂಗ',
'ಆತ್ಮವಂತ',
'ಆತ್ಮವಂತೆ',
'ಆತ್ಮವಸ್ತು',
'ಆತ್ಮವಿದ',
'ಆತ್ಮವಿದೆ',
'ಆತ್ಮವಿದ್ಯೆ',
'ಆತ್ಮವೀರ',
'ಆತ್ಮವೃತ್ತ',
'ಆತ್ಮಶಕ್ತಿ',
'ಆತ್ಮಶುದ್ಧಿ',
'ಆತ್ಮಶ್ರಾದ್ಧ',
'ಆತ್ಮಸಂಗ',
'ಆತ್ಮಸಮ',
'ಆತ್ಮಸಾಕ್ಷಿ',
'ಆತ್ಮಸಿದ್ಧಿ',
'ಆತ್ಮಸುಖ',
'ಆತ್ಮಸುಖಿ',
'ಆತ್ಮಸ್ತವ',
'ಆತ್ಮಸ್ತುತಿ',
'ಆತ್ಮಸ್ಥೈರ್ಯ',
'ಆತ್ಮಹತ್ಯ',
'ಆತ್ಮಹತ್ಯೆ',
'ಆತ್ಮಹಿತ',
'ಆತ್ಮಾಧೀನ',
'ಆತ್ಮಾನಂದ',
'ಆತ್ಮಾಮಿಷ',
'ಆತ್ಮಾಯತ್ತ',
'ಆತ್ಮಾರತ',
'ಆತ್ಮಾರಾಮ',
'ಆತ್ಮಾರ್ಥಕ',
'ಆತ್ಮಾರ್ಪಣ',
'ಆತ್ಮಾರ್ಪಣೆ',
'ಆತ್ಮಾರ್ಪಿತ',
'ಆತ್ಮಾಸ್ತಿತ್ವ',
'ಆತ್ಮಾಹುತಿ',
'ಆತ್ಮೀಕೃತ',
'ಆತ್ಮೀಭಾವ',
'ಆತ್ಮೀಯತೆ',
'ಆತ್ಮೈಶ್ವರ್ಯ',
'ಆತ್ಮೋದಯ',
'ಆತ್ಮೋದ್ಧಾರ',
'ಆತ್ಮೋದ್ಭವ',
'ಆತ್ಮೋದ್ಭವೆ',
'ಆತ್ಮೋದ್ಯೋಗ',
'ಆತ್ಮೋನ್ನತಿ',
'ಆತ್ಯಂತಿಕ',
'ಆತ್ರೇಯಿಕೆ',
'ಆಥರ್ವಣ',
'ಆದಮಾನುಂ',
'ಆದರಣೆ',
'ಆದರಿಸು',
'ಆದರ್ಪಿಸು',
'ಆದರ್ಶಕ',
'ಆದರ್ಶನ',
'ಆದಲಿಕೆ',
'ಆದಾವತಿ',
'ಆದಾವುಚ',
'ಆದಿಕಥೆ',
'ಆದಿಕವಿ',
'ಆದಿಕಾಂಡ',
'ಆದಿಕಾವ್ಯ',
'ಆದಿಕ್ರೋಢ',
'ಆದಿಗಿರಿ',
'ಆದಿಚಕ್ರಿ',
'ಆದಿಜಿನ',
'ಆದಿತಾಳ',
'ಆದಿತೇಯ',
'ಆದಿದೇವ',
'ಆದಿಧೌರ್ಯ',
'ಆದಿನಾಥ',
'ಆದಿಪರ್ವ',
'ಆದಿಪ್ರಾಸ',
'ಆದಿಬ್ರಹ್ಮ',
'ಆದಿಮಾರ್ಗ',
'ಆದಿಮುಕ್ತ',
'ಆದಿಮೂರ್ತಿ',
'ಆದಿಮೂಲ',
'ಆದಿಯುಗ',
'ಆದಿರಂಗ',
'ಆದಿರಾಜ',
'ಆದಿರುದ್ರ',
'ಆದಿಲಕ್ಷ್ಮಿ',
'ಆದಿಲಿಂಗ',
'ಆದಿವಸು',
'ಆದಿವಸ್ತು',
'ಆದಿವಾರ',
'ಆದಿವಾಸಿ',
'ಆದಿಶಕ್ತಿ',
'ಆದಿಶೇಷ',
'ಆದಿಶೈವ',
'ಆದಿಷ್ಟಣ',
'ಆದೀನವ',
'ಅದೀಶ್ವರ',
'ಆದುಣಿಗ',
'ಆದೇಶತೆ',
'ಆದೇಶತ್ವ',
'ಆದೇಶನ',
'ಆದೇಶಿಕೆ',
'ಆದೇಶಿಸು',
'ಆದೊರೆತು',
'ಆದೊರೆಯ',
'ಆದೋಹಳ',
'ಆದ್ದರಿಂದ',
'ಆದ್ಯಕವಿ',
'ಆದ್ಯಕಾವ್ಯ',
'ಆದ್ಯಕ್ಷರ',
'ಆದ್ಯತನ',
'ಆದ್ಯಶ್ರಾದ್ಧ',
'ಆದ್ಯೋಪಾಂತ',
'ಆಧರಣೆ',
'ಆಧರಿಸು',
'ಆಧಾರಕ',
'ಆಧಾರಸ್ಥ',
'ಆಧಾರಿತ',
'ಆಧಿಪತ್ಯ',
'ಆಧಿರಾಜ್ಯ',
'ಆಧಿವ್ಯಾಧಿ',
'ಆಧಿಹೇತು',
'ಆಧುನಿಕ',
'ಆಧೋರಣ',
'ಆಧ್ಮಾನಿಸು',
'ಆಧ್ಯಾತ್ಮಿಕ',
'ಆನಂದನ',
'ಆನಂದಾಶ್ರು',
'ಆನಂದಿತ',
'ಆನಂದಿನಿ',
'ಆನಂದಿಸು',
'ಆನಮಿಸು',
'ಆನಯನ',
'ಆನರ್ಥಕ್ಯ',
'ಆನಿಸಿಡು',
'ಆನೀಕಿನಿ',
'ಆನುಕೂಲ್ಯ',
'ಆನುಗುಣ್ಯ',
'ಆನುಗೋಲು',
'ಆನುಪೂರ್ವಿ',
'ಆನುಪೂರ್ವ್ಯ',
'ಆನುವಂಶ',
'ಆನೃಶಂಸ',
'ಆನೆಕಂಭ',
'ಆನೆಕಜ್ಜಿ',
'ಆನೆಕಲ್ಲು',
'ಆನೆಕಾಲು',
'ಆನೆಕಾಸು',
'ಆನೆಕಿವಿ',
'ಆನೆಕುಷ್ಟ',
'ಆನೆಕೊಂಬು',
'ಆನೆಗಂಭ',
'ಆನೆಗಜ್ಜಿ',
'ಆನೆಗಲ್',
'ಆನೆಗಲ್ಲು',
'ಆನೆಗಾಱ',
'ಆನೆಗುಲಿ',
'ಆನೆಗೊಲೆ',
'ಆನೆಗೊಳ್ಳ',
'ಆನೆಜಂತು',
'ಆನೆಜಾಜಿ',
'ಆನೆಜೊಂಡು',
'ಆನೆತುಂತು',
'ಆನೆತೂಬು',
'ಆನೆತೊಂಡ',
'ಆನೆದಲೆ',
'ಆನೆದಿಂಬ',
'ಆನೆದಿಡ್ಡಿ',
'ಆನೆದಿನ್',
'ಆನೆದಿನಿ',
'ಆನೆದುಡ್ಡು',
'ಆನೆನಿಂಬೆ',
'ಆನೆನೂಂಕು',
'ಆನೆಪಾದ',
'ಆನೆಬೇಲ',
'ಅನೆಮಯ್ಲಿ',
'ಆನೆಮಲೆ',
'ಆನೆಮೀನು',
'ಆನೆಮುಂಗು',
'ಆನೆಮುಗ',
'ಆನೆಮೆಟ್ಟು',
'ಆನೆಮೊಗ',
'ಆನೆಯಾಳ್',
'ಆನೆಯಾಳು',
'ಆನೆರದ',
'ಆನೆವರಿ',
'ಆನೆವಱಿ',
'ಆನೆವಱೆ',
'ಆನೆವಾಂಗು',
'ಆನೆವಾಲು',
'ಆನೆವೆಣ',
'ಆನೆಸಟ್ಟೆ',
'ಆನೆಸತ್ತೆ',
'ಆನೆಸಾಲೆ',
'ಆನೆಸೂಜಿ',
'ಆನೆಸೊಳ್ಳೆ',
'ಆನೆಸೌತೆ',
'ಆನೆಹಲ್ಲು',
'ಆನೆಹುಲ್ಲು',
'ಆನೆಹೆಜ್ಜೆ',
'ಆನೇದುಡ್ಡು',
'ಆನ್ವಯಿಕ',
'ಆನ್ವೀಕ್ಷಿಕಿ',
'ಆಪಣಿಕ',
'ಆಪತ್ಕಾಲ',
'ಆಪತ್ತಿಗ',
'ಆಪತ್ಸಖ',
'ಆಪದ್ಧನ',
'ಆಪದ್ಧರ್ಮ',
'ಆಪದ್ಬಂಧು',
'ಆಪದ್ವಾರ್ತೆ',
'ಆಪಸಾತು',
'ಆಪಾಂಡುರ',
'ಆಪಾತತಃ',
'ಆಪಾದಕ',
'ಆಪಾದನೆ',
'ಆಪಾದಿತ',
'ಆಪಾದಿತೆ',
'ಆಪಾದಿಸು',
'ಆಪಾಳಿತ',
'ಆಪೀಡನ',
'ಆಪೂಪಿಕ',
'ಆಪೂರಿತ',
'ಆಪೂರ್ಣತೆ',
'ಆಪೃಚ್ಛನ',
'ಆಪೇಕ್ಷಿಕ',
'ಆಪೇಕ್ಷಿತ',
'ಆಪೋಕ್ಲೀಬ',
'ಆಪೋಶನ',
'ಆಪೋಶಿಸು',
'ಆಪೋಶಣ',
'ಆಪ್ತಚರ',
'ಆಪ್ತಮಿತ್ರ',
'ಆಪ್ತಬಂಧು',
'ಆಪ್ತವಂತ',
'ಆಪ್ತವರ್ತಿ',
'ಆಪ್ತವಾಕ್ಯ',
'ಆಪ್ತವಿಕೆ',
'ಆಪ್ತಾಗಮ',
'ಆಪ್ತಾಗಿರಿ',
'ಆಪ್ತೇಷ್ಟರು',
'ಆಪ್ಯಾಯನ',
'ಆಪ್ಯಾಯಿತ',
'ಆಪ್ಯಾಯಿನಿ',
'ಆಪ್ರಚ್ಛನ',
'ಆಪ್ರಪದಂ',
'ಆಪ್ರೇಕ್ಷಣ',
'ಆಪ್ಲವನ',
'ಆಪ್ಲಾವಿತ',
'ಆಪ್ಸರೇಯ',
'ಆಫೀಸರು',
'ಆಫುಖುದ್ದು',
'ಆಬಂಧನ',
'ಆಬಾಗೇಡಿ',
'ಆಬಾಧಿಕ',
'ಆಬುಕಾರಿ',
'ಆಭರಣ',
'ಆಭರಿತ',
'ಆಭಾಷಣ',
'ಆಭಾಸ್ವರ',
'ಆಭಿಚಾರ',
'ಆಭಿಜಾತ್ಯ',
'ಆಭಿಯೋಗ',
'ಆಭೀಳತೆ',
'ಆಭ್ಯಂತರ',
'ಆಮಂತಣ',
'ಆಮಂತ್ರಕ',
'ಆಮಂತ್ರಣ',
'ಆಮಂತ್ರಿತ',
'ಆಮಂತ್ರಿತೆ',
'ಆಮಂತ್ರಿಸು',
'ಆಮಗಂಧ',
'ಆಮಗಂಧಿ',
'ಆಮಗರಿ',
'ಆಮದಾನಿ',
'ಆಮನಸ್ಯ',
'ಆಮಯಾವಿ',
'ಆಮರಕ್ತ',
'ಆಮರಣ',
'ಆಮರಸ',
'ಆಮರ್ದನ',
'ಆಮಲಕ',
'ಆಮಲಕಿ',
'ಆಮವಡೆ',
'ಆಮಶಂಕೆ',
'ಆಮಶ್ರಾದ್ಧ',
'ಆಮಸೋಲು',
'ಆಮಳಕ',
'ಆಮಳಕಿ',
'ಆಮಾಶಯ',
'ಆಮಿಲಿತ',
'ಆಮಿಷಾಶಿ',
'ಆಮಿಳಿತ',
'ಆಮೀಲನ',
'ಆಮೀಳನ',
'ಆಮುತ್ರಿಕ',
'ಆಮುದಿತ',
'ಆಮುಷ್ಮಿಕ',
'ಆಮುಸೂಲು',
'ಆಮುಸೋಲು',
'ಆಮೂಲಾಗ್ರ',
'ಆಮೂಲಾಗ್ರಂ',
'ಆಮೆದೆಱೆ',
'ಆಮೇಲಿನ',
'ಆಮೋದತೆ',
'ಆಮೋದಿತ',
'ಆಮೋದಿಸು',
'ಆಮ್ರರಸ',
'ಆಮ್ರವಣ',
'ಆಮ್ರವನ',
'ಆಮ್ರವಲ್ಲಿ',
'ಆಮ್ರಾತಕ',
'ಆಮ್ರೇಡಿತ',
'ಆಮ್ಲಪರ್ಣಿ',
'ಆಮ್ಲಪಿತ್ತ',
'ಆಮ್ಲಪೀತ',
'ಆಮ್ಲವತಿ',
'ಆಮ್ಲಸೂಚಿ',
'ಆಮ್ಲೀಯತೆ',
'ಆಯಂಗಿಡು',
'ಆಯಂದಪ್ಪು',
'ಆಯಕಟ್ಟು',
'ಆಯಕಾಱ',
'ಆಯಗಾಣು',
'ಆಯಗಾರ',
'ಆಯಗಾರ್ತಿ',
'ಆಯಗಾಲು',
'ಆಯಗಾಱ',
'ಆಯಗೊಳ್',
'ಆಯಛಲ',
'ಆಯತನ',
'ಆಯತಪ್ಪು',
'ಆಯತಾಕ್ಷ',
'ಆಯತಾಕ್ಷಿ',
'ಆಯತಿಕೆ',
'ಆಯತಿಕ್ಕೆ',
'ಆಯತುತ್ತು',
'ಆಯಪಾಣಿ',
'ಆಯಮೇರ',
'ಆಯರಾಶಿ',
'ಆಯಲ್ಲಕ',
'ಆಯವರಿ',
'ಆಯವಱಿ',
'ಆಯವಿಡು',
'ಆಯವಿದ್ಯೆ',
'ಆಯವ್ಯಯ',
'ಆಯಸಾಮ್ಯ',
'ಆಯಸಾಯ',
'ಆಯಸ್ಕರ',
'ಆಯಸ್ವಾಮ್ಯ',
'ಆಯಾಮಿಕ',
'ಆಯಾಳಿಸು',
'ಆಯಿಕುಳಿ',
'ಆಯಿದೆನೆ',
'ಆಯುಃಕರ್ಮ',
'ಆಯುಕ್ತಕ',
'ಆಯುಧಿಕ',
'ಆಯುಧೀಯ',
'ಆಯುರೇಖೆ',
'ಆಯುರ್ಜ್ಞಾನ',
'ಆಯುರ್ದಸೆ',
'ಆಯುರ್ದಾಯ',
'ಆಯುರ್ಮಾನ',
'ಆಯುರ್ಮಿತಿ',
'ಆಯುರ್ವಂತ',
'ಆಯುರ್ವಂತೆ',
'ಆಯುರ್ವೃದ್ಧಿ',
'ಆಯುರ್ವೇದ',
'ಆಯುರ್ವೇದಿ',
'ಆಯುರ್ಹೀನ',
'ಆಯುರ್ಹೀನೆ',
'ಆಯುರ್ಹೋಮ',
'ಆಯುವಂತ',
'ಆಯುವಂತೆ',
'ಆಯುವೊಯ್',
'ಆಯುಶ್ಚ್ಯುತಿ',
'ಆಯುಶ್ಶೇಷ',
'ಆಯುಷ್ಕರ್ಮ',
'ಆಯುಷ್ಕಾಮ',
'ಆಯುಷ್ಮಂತ',
'ಆಯುಷ್ಮಂತೆ',
'ಆಯುಷ್ಮತಿ',
'ಆಯುಷ್ಮಾನ್',
'ಆಯುಷ್ಯಾಂತ',
'ಆಯುಹೀನ',
'ಆಯುಹೀನೆ',
'ಆಯೋಜಕ',
'ಆಯೋಜಕಿ',
'ಆಯೋಜನ',
'ಆಯೋಜನೆ',
'ಆಯೋಜಿಸು',
'ಆಯೋಧನ',
'ಆಯ್ಕೆಗಾರ',
'ಆಯ್ಕೆಗೊಳು',
'ಆಯ್ಕೆಗೊಳ್ಳು',
'ಆರಂಬಸ್ಥ',
'ಆರಂಬಿಗ',
'ಆರಂಭಕ',
'ಆರಂಭಣ',
'ಆರಂಭಿಕ',
'ಆರಂಭಿಗ',
'ಆರಂಭಿಸು',
'ಆರಕ್ತತೆ',
'ಆರಕ್ಷಕ',
'ಆರಗಿನ',
'ಆರಗ್ವಧ',
'ಆರಣೀಂದ್ರ',
'ಆರಣ್ಯಕ',
'ಆರನಾಲ',
'ಆರನಾಳ',
'ಆರಪಾರ',
'ಆರಭಟಿ',
'ಆರಭಟೆ',
'ಆರಭಾರ',
'ಆರಮೆಕ್ಕೆ',
'ಆರಯಿಕೆ',
'ಆರಯಿಸು',
'ಆರವಾರ',
'ಆರಾಘಾತ',
'ಆರಾಜಿತ',
'ಆರಾಜಿಸು',
'ಆರಾತಿಯ',
'ಆರಾತೀಯ',
'ಆರಾತ್ರಿಕ',
'ಆರಾಧಕ',
'ಆರಾಧಕಿ',
'ಆರಾಧನ',
'ಆರಾಧನೆ',
'ಆರಾಧಿಸು',
'ಆರಾಭಾರ',
'ಆರಾಮತೆ',
'ಆರಾಲಿಕ',
'ಆರಾಳಿಕ',
'ಆರಿಕಲ್ಲು',
'ಆರಿದಾಳ್ಕೆ',
'ಆರಿವಾಳ',
'ಆರೀಮಣಿ',
'ಆರುಕಾರು',
'ಆರುಕೋರು',
'ಆರುಗಾಲ',
'ಆರುಗೋಲು',
'ಆರುಪಾರ',
'ಆರುಬಟಿ',
'ಆರುಬಟೆ',
'ಆರುಭಟ',
'ಆರುಭಟೆ',
'ಆರುಮೊಗ',
'ಆರುವಾರ',
'ಆರುವಾರಿ',
'ಆರುಷಣ',
'ಆರುಷ್ಕರ',
'ಆರುಹತ',
'ಆರೆಕಾರ',
'ಆರೆಕಾಱ',
'ಆರೆಗಾಱ',
'ಆರೆಗೊಂಬು',
'ಆರೆಗೋಲು',
'ಆರೆಲುಬು',
'ಆರೊಗಿಸು',
'ಆರೋಗಣ',
'ಆರೋಗಣೆ',
'ಆರೋಗಿಸು',
'ಆರೋಚಕ',
'ಆರೋಪಣ',
'ಆರೋಪಣೆ',
'ಆರೋಪಿತ',
'ಆರೋಪಿಸು',
'ಆರೋಹಕ',
'ಆರೋಹಣ',
'ಆರೋಹಿಣಿ',
'ಆರೋಹಿಸು',
'ಆರ್ಕ್ಯವಾರ',
'ಆರ್ತಂಗಿಡು',
'ಆರ್ತಂಗೊಳ್',
'ಆರ್ತಗಲ',
'ಆರ್ತಗಳ',
'ಆರ್ತಜನ',
'ಆರ್ತಧ್ಯಾನ',
'ಆರ್ತಧ್ವನಿ',
'ಆರ್ತನಾದ',
'ಆರ್ತರೌದ್ರ',
'ಆರ್ತಸ್ವರ',
'ಆರ್ಥಿಕತೆ',
'ಆರ್ದರಕ',
'ಆರ್ದ್ರಕ್ಷತ',
'ಆರ್ದ್ರಜಲ',
'ಆರ್ದ್ರದೇಶಿ',
'ಆರ್ದ್ರಭಾವ',
'ಆರ್ದ್ರಮತಿ',
'ಆರ್ದ್ರಾಂಬರಿ',
'ಆರ್ದ್ರಾಕ್ಷತ',
'ಆರ್ದ್ರಾಶಯ',
'ಆರ್ದ್ರೀಭೂತ',
'ಆರ್ಪುಗಾರ',
'ಆರ್ಪುಗುಂದು',
'ಆರ್ಪುಗೆಯ್ತ',
'ಆರ್ಬಟಿಸು',
'ಆರ್ಭಟಿಸು',
'ಆರ್ಭಡಿಸು',
'ಆರ್ಯಕರ್ಮ',
'ಆರ್ಯಪುತ್ರ',
'ಆರ್ಯಮುಖ',
'ಆರ್ಯವೇಲ',
'ಆರ್ಯವೇಷ',
'ಆರ್ಯಾಗೀತಿ',
'ಆರ್ಯಾವರ್ತ',
'ಆರ್ಯಾವೃತ್ತ',
'ಆರ್ಷದೃಷ್ಟಿ',
'ಆರ್ಷಮತ',
'ಆರ್ಷವಾಣಿ',
'ಆರ್ಷೇಯಾರ್ಥ',
'ಆಲಂಗಿರಿ',
'ಆಲಂಗಿಸು',
'ಆಲಂಬಕ',
'ಆಲಂಬನ',
'ಆಲಂಬಿತ',
'ಆಲಂಬಿಸು',
'ಆಲಂಭನ',
'ಆಲಜಾಲ',
'ಆಲಪರಿ',
'ಆಲಪಿಸು',
'ಆಲಮರ್',
'ಆಲಮರ',
'ಆಲಮರು',
'ಆಲಯಿಸು',
'ಆಲವಟ',
'ಆಲವಟ್ಟ',
'ಆಲವಣ್',
'ಆಲವರಿ',
'ಆಲವರು',
'ಆಲವಾಲ',
'ಆಲಸಿಕೆ',
'ಆಲಾಜೀಲಿ',
'ಆಲಾಜೋಲಿ',
'ಆಲಾಪನೆ',
'ಆಲಾಪಿಸು',
'ಆಲಾವರ್ತ',
'ಆಲಿಂಗನ',
'ಆಲಿಂಗಿತ',
'ಆಲಿಂಗಿಸು',
'ಆಲಿಕಲ್',
'ಆಲಿಕಲ್ಲು',
'ಆಲಿಕೋಡು',
'ಆಲಿಗಣ್',
'ಆಲಿಗಣ್ಣು',
'ಆಲಿಗಲ್',
'ಆಲಿಗುಡು',
'ಆಲಿಗೊಳ್',
'ಆಲಿಗೋಡು',
'ಆಲಿದೆಱೆ',
'ಆಲಿನೀರ್',
'ಆಲಿನೀರು',
'ಆಲಿಪರಿ',
'ಆಲಿಬಚ್ಚು',
'ಆಲಿಮೞೆ',
'ಆಲಿವೞೆ',
'ಆಲುಗಡ್ಡೆ',
'ಆಲೂಗಡ್ಡೆ',
'ಆಲೂಗೆಡ್ಡೆ',
'ಆಲೆದೆಱೆ',
'ಆಲೆಮನೆ',
'ಆಲೆಮಾರ',
'ಆಲೆಯಾಡು',
'ಆಲೆಹಗ್ಗ',
'ಆಲೇಖಿಸು',
'ಆಲೇಪನ',
'ಆಲೇಪಿಸು',
'ಆಲೇಮನೆ',
'ಆಲೋಕನ',
'ಆಲೋಕಿತ',
'ಆಲೋಕಿಸು',
'ಆಲೋಚಕ',
'ಆಲೋಚನ',
'ಆಲೋಚನೆ',
'ಆಲೋಚಿಸು',
'ಆಲೋಡನ',
'ಆಲೋಡನೆ',
'ಆಲೋಡಿತ',
'ಆಲೋಡಿಸು',
'ಆಲೋಲಿಸು',
'ಆವಗಲು',
'ಆವಟಿಗ',
'ಆವಪನ',
'ಆವರಕ',
'ಆವರಣ',
'ಆವರಿಕೆ',
'ಆವರಿಸು',
'ಆವರ್ಜನ',
'ಆವರ್ಜಿಸು',
'ಆವರ್ತಕ',
'ಆವರ್ತನ',
'ಆವರ್ತನಿ',
'ಆವರ್ತನೆ',
'ಆವರ್ತಾಂಕ',
'ಆವರ್ತಿತ',
'ಆವರ್ತಿನಿ',
'ಆವರ್ತಿಸು',
'ಆವರ್ಹಿತ',
'ಆವಲಿಕ',
'ಆವಲಿತ',
'ಆವಲ್ಗಂಪು',
'ಆವಲ್ಗೊಳ',
'ಆವಶ್ಯಕ',
'ಆವಸಥ',
'ಆವಸಥ್ಯ',
'ಆವಸಿತ',
'ಆವಹನ',
'ಆವಹಿಸು',
'ಆವಳಿಕ',
'ಆವಳಿಕೆ',
'ಆವಳಿಸು',
'ಆವಾಗಲೂ',
'ಆವಾಗಳು',
'ಆವಾಗಳುಂ',
'ಆವಾಗಳೂ',
'ಆವಾಪಕ',
'ಆವಾಹನ',
'ಆವಾಹನೆ',
'ಆವಾಹಿತ',
'ಆವಾಹಿಸು',
'ಆವಿಕಣ್ಣು',
'ಆವಿಗಣ್ಣು',
'ಆವಿಯಿಸ್ತ್ರಿ',
'ಆವಿರ್ಭಾವ',
'ಆವಿರ್ಭೂತ',
'ಆವಿಷ್ಕಾರ',
'ಆವಿಷ್ಕೃತ',
'ಆವಿಷ್ಟತೆ',
'ಆವೀಕ್ಷಿತ',
'ಆವುಂಡಿಗೆ',
'ಆವುಜಿಗ',
'ಆವೆಕಲ್ಲು',
'ಆವೆಚಿಪ್ಪು',
'ಆವೆಮಣ್ಣು',
'ಆವೆವೋಡು',
'ಆವೇದನ',
'ಆವೇಶನ',
'ಆವೇಶಿಕ',
'ಆವೇಶಿಕೆ',
'ಆವೇಷ್ಟಕ',
'ಆವೇಷ್ಟನ',
'ಆವೇಷ್ಟಿತ',
'ಆವೇಷ್ಟಿಸು',
'ಆಶಂಕಿತ',
'ಆಶಂಸನ',
'ಆಶಂಸಾರ್ಥ',
'ಆಶಂಸಿತೃ',
'ಆಶಾಂಬರ',
'ಆಶಾಕರಿ',
'ಆಶಾಗಜ',
'ಆಶಾಚಕ್ರ',
'ಆಶಾದಂತಿ',
'ಆಶಾದೀಪ',
'ಆಶಾಧಿಪ',
'ಆಶಾಧೀನ',
'ಆಶಾಪತಿ',
'ಆಶಾಭಂಗ',
'ಆಶಾರುಚಿ',
'ಆಶಾವರ್ಗ',
'ಆಶಾವಾದ',
'ಆಶಾವಾದಿ',
'ಆಶಾವೇಶ',
'ಆಶಾಷ್ಟಕ',
'ಆಶಾಸನ',
'ಆಶಾಸಿಸು',
'ಆಶೀರ್ವಚ',
'ಆಶೀರ್ವಾದ',
'ಆಶೀರ್ವಿಷ',
'ಆಶೀವಿಷ',
'ಆಶೀಸ್ತವ',
'ಆಶುಕವಿ',
'ಆಶುಗತಿ',
'ಆಶುಗತ್ವ',
'ಆಶುಗಾಮಿ',
'ಆಶುಗಾಸ',
'ಆಶುಪತ್ರಿ',
'ಆಶುವಂತ',
'ಆಶೆಗೊಳ್ಳು',
'ಆಶೆಪಡು',
'ಆಶೆವಂತ',
'ಆಶೆವಡು',
'ಆಶೋತ್ತರ',
'ಆಶ್ಚರಿಯ',
'ಆಶ್ಚೋತನ',
'ಆಶ್ರಯಣ',
'ಆಶ್ರಯಾಶ',
'ಆಶ್ರಯಿಸು',
'ಆಶ್ರವಿಸು',
'ಆಶ್ವಯುಜ',
'ಆಶ್ವಾಸನ',
'ಆಶ್ವಾಸನೆ',
'ಆಶ್ವಾಸಿಸು',
'ಆಶ್ವಿನೇಯ',
'ಆಸಕಳಂ',
'ಆಸಕುತ',
'ಆಸಕುತಿ',
'ಆಸಕ್ತತೆ',
'ಆಸನ್ನತೆ',
'ಆಸನ್ನಿಸು',
'ಆಸಪತ್ರಿ',
'ಆಸಪಾಕ',
'ಆಸಪಾಸ',
'ಆಸಪಾಸು',
'ಆಸಮಾನಿ',
'ಆಸರಿಕೆ',
'ಆಸರಿಸು',
'ಆಸರ್ಗಳೆ',
'ಆಸವನ',
'ಆಸವಾರಿ',
'ಆಸಱಿಕೆ',
'ಆಸಾದನ',
'ಆಸಾಧಿತ',
'ಆಸುಕರ',
'ಆಸುಪಾಸು',
'ಆಸುರಾಸ್ತ್ರ',
'ಆಸೆಕಾರ',
'ಆಸೆಕಾಱ',
'ಆಸೆಗಾಣ್',
'ಆಸೆಗಾರ',
'ಆಸೆಗಾರ್ತಿ',
'ಆಸೆಗಾಱ',
'ಆಸೆಗೆಯ್',
'ಆಸೆಗೇಡು',
'ಆಸೆಗೊಳು',
'ಆಸೆಗೊಳ್ಳು',
'ಆಸೆದೊಱೆ',
'ಆಸೆದೋಱು',
'ಆಸೆಪಡು',
'ಆಸೆಪಾಸೆ',
'ಆಸೆಬಾಕ',
'ಆಸೆಮಾರಿ',
'ಆಸೆವಡು',
'ಆಸೆವಾತು',
'ಆಸೆವಾಸಿ',
'ಆಸೇಚನ',
'ಆಸೇಚಿಸು',
'ಆಸೇವನ',
'ಆಸೇವಿತ',
'ಆಸೇವೆಗೈ',
'ಆಸ್ಕಂದನ',
'ಆಸ್ಕಂದಿತ',
'ಆಸ್ತರಣ',
'ಆಸ್ತರಿಸು',
'ಆಸ್ತಿಕತೆ',
'ಆಸ್ತಿಕತ್ವ',
'ಆಸ್ತಿಗಾರ',
'ಆಸ್ತಿಮಿತ',
'ಆಸ್ತಿವಂತ',
'ಆಸ್ತಿವಂತೆ',
'ಆಸ್ಥಾನಿಕ',
'ಆಸ್ಥಾಯಿಕೆ',
'ಆಸ್ಪಂದನ',
'ಆಸ್ಫಾಲನ',
'ಆಸ್ಫಾಲಿತ',
'ಆಸ್ಫಾಲಿಸು',
'ಆಸ್ಫಾಳನ',
'ಆಸ್ಫಾಳಿಸು',
'ಆಸ್ಫುರಿತ',
'ಆಸ್ಫೋಟಕ',
'ಆಸ್ಫೋಟನ',
'ಆಸ್ಫೋಟನಿ',
'ಆಸ್ಫೋಟಿತ',
'ಆಸ್ಫೋಟಿನಿ',
'ಆಸ್ಫೋಟಿಸು',
'ಆಸ್ಯಪತ್ರ',
'ಆಸ್ಯಪಾಕ',
'ಆಸ್ಯಲೋಮ',
'ಆಸ್ಯಾನಿಲ',
'ಆಸ್ಯಾನಿಳ',
'ಆಸ್ಯಾಸವ',
'ಆಸ್ವಾದನ',
'ಆಸ್ವಾದನೆ',
'ಆಸ್ವಾದಿಸು',
'ಆಹಮಿಸು',
'ಆಹರಣೆ',
'ಆಹರಿಸು',
'ಆಹವಾಗ್ರ',
'ಆಹಾರಕ',
'ಆಹಾರಿಸು',
'ಆಹಾರ್ಯಕ',
'ಆಹಿತಾಗ್ನಿ',
'ಆಹುತಾತ್ಮ',
'ಆಹ್ಲಾದನ',
'ಆಹ್ಲಾದಿಸು',
'ಆಹ್ವಾನಿತ',
'ಆಹ್ವಾನಿತೆ',
'ಆಹ್ವಾನಿಸು',
'ಆಳಂಬನ',
'ಆಳಜಾಲ',
'ಆಳಮಯ',
'ಆಳಮಾಳ',
'ಆಳಮೇಳ',
'ಆಳರಸ',
'ಆಳವಾಡು',
'ಆಳವಾಳ',
'ಆಳವಿಣೆ',
'ಆಳವಿಸು',
'ಆಳಾಪಾಳಿ',
'ಆಳಾಪಿಕೆ',
'ಆಳಾಪಿಗ',
'ಆಳಾಪಿಸು',
'ಆಳಿಂದಕಿ',
'ಆಳಿಕಾರ',
'ಆಳಿಕಾಱ',
'ಆಳಿಗಟ್ಟು',
'ಆಳಿಗಳೆ',
'ಆಳಿಗೆಯ್',
'ಆಳಿಗೊಳ್',
'ಆಳಿಗೊಳು',
'ಆಳಿಯಾಟ',
'ಆಳೀಜನ',
'ಆಳುಕಾಳು',
'ಆಳುಗಾರ್ತಿ',
'ಆಳುಗುಂಡಿ',
'ಆಳುಗೆಯ್ತ',
'ಆಳುಗೊಳ್',
'ಆಳುತನ',
'ಆಳುದೋಟಿ',
'ಆಳುದೋರು',
'ಆಳುದೋಱು',
'ಆಳುಭಾಷೆ',
'ಆಳುಮಂದಿ',
'ಆಳುಮಗ',
'ಆಳುಮಟ್ಟ',
'ಆಳುಮಾಡು',
'ಆಳುವಾರು',
'ಆಳುವಾಸಿ',
'ಆಳುವಿಕೆ',
'ಆಳುವೆಸ',
'ಆಳುವೇರಿ',
'ಆಳೋಕನ',
'ಆಳೋಕಿಸು',
'ಆಳೋಚನ',
'ಆಳೋಚನೆ',
'ಆಳೋಚಿಸು',
'ಆಳ್ಕೆಗಡೆ',
'ಆಳ್ಕೆವಾಡ',
'ಆಳ್ಕೆವೆಱು',
'ಆಳ್ಗೆಲಸ',
'ಆಱಡಿಗ',
'ಆಱುದಲೆ',
'ಆಱುಮಡಿ',
'ಆಱುಮುಖ',
'ಆೞವಡು',
'ಆೞಾೞಿಗ',
'ಆೞಿಕಾಱ',
'ಆೞಿಗುಟ್ಟು',
'ಆೞಿಗೊಡು',
'ಆೞಿಗೊಳ್',
'ಆೞಿಜನ',
'ಇಂಕೆವರ್',
'ಇಂಗಡಲ್',
'ಇಂಗಡಲು',
'ಇಂಗಣೆಯ',
'ಇಂಗದಿರ್',
'ಇಂಗದಿರ',
'ಇಂಗರೀಜ',
'ಇಂಗರೆಜಿ',
'ಇಂಗಲಿಕ',
'ಇಂಗಲೀಕ',
'ಇಂಗಲೀಷು',
'ಇಂಗಳಕಿ',
'ಇಂಗಳಾರ',
'ಇಂಗಳಿಕೆ',
'ಇಂಗಳೀಕ',
'ಇಂಗಾಲಾಮ್ಲ',
'ಇಂಗಿತಜ್ಞ',
'ಇಂಗಿತಜ್ಞೆ',
'ಇಂಗಿಲೀಷು',
'ಇಂಗಿಹೋಗು',
'ಇಂಗುಲಿಕ',
'ಇಂಗುಲೀಕ',
'ಇಂಗುಳಿಕ',
'ಇಂಗುಳೀಕ',
'ಇಂಗೊರಲ್',
'ಇಂಗೊರಳ್',
'ಇಂಗೋಲ್ವಿಲ್',
'ಇಂಗೋಲ್ವಿಲ್ಲ',
'ಇಂಘಾರಿಗೆ',
'ಇಂಚರಮೀ',
'ಇಂಚುಪಟ್ಟಿ',
'ಇಂಚೆವೋಗು',
'ಇಂಜೆಕ್ಷನ್ನು',
'ಇಂಡೆಗಟ್ಟು',
'ಇಂಡೆಗೊಳ್',
'ಇಂಡೆದಂಡೆ',
'ಇಂದಿಂದಿರ',
'ಇಂದಿರಾಜ',
'ಇಂದೀವರ',
'ಇಂದೀವರಿ',
'ಇಂದುಕರ',
'ಇಂದುಕಳೆ',
'ಇಂದುಕಾಂತ',
'ಇಂದುಕಾಂತಿ',
'ಇಂದುಚೂಡ',
'ಇಂದುಜೂಟ',
'ಇಂದುದ್ಯುತಿ',
'ಇಂದುಧರ',
'ಇಂದುಮಣಿ',
'ಇಂದುಮತಿ',
'ಇಂದುಮೌಲಿ',
'ಇಂದುಮೌಳಿ',
'ಇಂದುರುಚಿ',
'ಇಂದುರೇಖೆ',
'ಇಂದುರೋಚಿ',
'ಇಂದುಲೇಖೆ',
'ಇಂದುವಾರ',
'ಇಂದುವ್ರತ',
'ಇಂದುಹಾಸ',
'ಇಂದೂಪಲ',
'ಇಂದೂಪಳ',
'ಇಂದೊವರ್',
'ಇಂದ್ರಕಲೆ',
'ಇಂದ್ರಕೇತು',
'ಇಂದ್ರಕೋಶ',
'ಇಂದ್ರಗೋಚೆ',
'ಇಂದ್ರಗೋಪ',
'ಇಂದ್ರಚಾಪ',
'ಇಂದ್ರಚ್ಛಂದ',
'ಇಂದ್ರಜವ',
'ಇಂದ್ರಜವಿ',
'ಇಂದ್ರಜಾಲ',
'ಇಂದ್ರಜೆವಿ',
'ಇಂದ್ರತ್ಯಾಗ',
'ಇಂದ್ರದಿಕ್ಕು',
'ಇಂದ್ರದ್ವಿಪ',
'ಇಂದ್ರನಗ',
'ಇಂದ್ರನೀಲ',
'ಇಂದ್ರಪುರ',
'ಇಂದ್ರಪುಷ್ಪ',
'ಇಂದ್ರಮಣಿ',
'ಇಂದ್ರಯವ',
'ಇಂದ್ರಲುಪ್ತ',
'ಇಂದ್ರಲೋಕ',
'ಇಂದ್ರವಂಶ',
'ಇಂದ್ರವಜ್ರ',
'ಇಂದ್ರವಧು',
'ಇಂದ್ರಶೂದ್ರ',
'ಇಂದ್ರಸಿಂಧು',
'ಇಂದ್ರಾಂಗನೆ',
'ಇಂದ್ರಾಣಿಕೆ',
'ಇಂದ್ರಾಯುಧ',
'ಇಂದ್ರಿಯಾರ್ಥ',
'ಇಂಪಡರ್',
'ಇಂಪಡರು',
'ಇಂಪುಗಾಣ್',
'ಇಂಪುಗೊಳು',
'ಇಂಪುದೋಱು',
'ಇಂಪುವಡೆ',
'ಇಂಪುವತಿ',
'ಇಂಪುವಾಳೆ',
'ಇಂಪುವೆಱು',
'ಇಂಬಳಿಸು',
'ಇಂಬಾಗಿಸು',
'ಇಂಬಿಡಿಸು',
'ಇಂಬುಕೆಯ್',
'ಇಂಬುಕೆಯ್ಸು',
'ಇಂಬುಕೈಸು',
'ಇಂಬುಕೊಡು',
'ಇಂಬುಗಟ್ಟು',
'ಇಂಬುಗಾಣ್',
'ಇಂಬುಗಿಡು',
'ಇಂಬುಗುಡು',
'ಇಂಬುಗೆಡು',
'ಇಂಬುಗೆಯ್',
'ಇಂಬುಗೊಡು',
'ಇಂಬುಗೊಳ್',
'ಇಂಬುದಳೆ',
'ಇಂಬುದುಂಬು',
'ಇಂಬುಮಾಡು',
'ಇಂಬುವಡೆ',
'ಇಂಬುವರ್',
'ಇಂಬುವೆಱು',
'ಇಂಬೆಳಸು',
'ಇಂಬೊಸರ್',
'ಇಕಾರಾಂತ',
'ಇಕ್ಕಂಡುಗ',
'ಇಕ್ಕಡಿಕೆ',
'ಇಕ್ಕಡಿಗೈ',
'ಇಕ್ಕಡಿಸು',
'ಇಕ್ಕತ್ತಿಗೆ',
'ಇಕ್ಕರಿಸು',
'ಇಕ್ಕರ್ತರು',
'ಇಕ್ಕಲಿಸು',
'ಇಕ್ಕವಲು',
'ಇಕ್ಕುಂಗೂೞ್',
'ಇಕ್ಕುಗುತ್ತ',
'ಇಕ್ಕುತೆಕ್ಕೆ',
'ಇಕ್ಕುಪಡು',
'ಇಕ್ಕುಮದ್ದು',
'ಇಕ್ಕುವಡು',
'ಇಕ್ಕುಳಿಕೆ',
'ಇಕ್ಕುೞಿಸು',
'ಇಕ್ಕೆಗೊಳ್',
'ಇಕ್ಕೆದಾಣ',
'ಇಕ್ಕೆವನೆ',
'ಇಕ್ಕೆಹಕ್ಕೆ',
'ಇಕ್ಕೈಯಿಗ',
'ಇಕ್ಕೈವರೆ',
'ಇಕ್ಕೊಳಗ',
'ಇಕ್ಷುಕಾಂಡ',
'ಇಕ್ಷುಖಂಡ',
'ಇಕ್ಷುಗಂಧೆ',
'ಇಕ್ಷುಚಾಪ',
'ಇಕ್ಷುಜಂತ್ರ',
'ಇಕ್ಷುದಂಡ',
'ಇಕ್ಷುದೋಟ',
'ಇಕ್ಷುಧನ್ವ',
'ಇಕ್ಷುಪಯ',
'ಇಕ್ಷುಪುಷ್ಪ',
'ಇಕ್ಷುಯಂತ್ರ',
'ಇಕ್ಷುರಕ',
'ಇಕ್ಷುರಸ',
'ಇಕ್ಷುವಾಟ',
'ಇಕ್ಷುವಾಟಿ',
'ಇಕ್ಷ್ವಾಲಿಕೆ',
'ಇಖ್ಖಂಡುಗ',
'ಇಗ್ಗೆಲಸ',
'ಇಚಕಿಲ',
'ಇಚ್ಚಕಾಱ',
'ಇಚ್ಚಗಾರ್ತಿ',
'ಇಚ್ಚಣಿಗೆ',
'ಇಚ್ಚಯಿಸು',
'ಇಚ್ಚವಡಿ',
'ಇಚ್ಚುಮೀನು',
'ಇಚ್ಚೆಕಾರ್ತಿ',
'ಇಚ್ಚೆಕಾಱ',
'ಇಚ್ಚೆಗಾತಿ',
'ಇಚ್ಚೆಗಾರ',
'ಇಚ್ಚೆಗಾರ್ತಿ',
'ಇಚ್ಚೆಗಾಱ',
'ಇಚ್ಚೆಗೆಡು',
'ಇಚ್ಚೆಗೆಯ್',
'ಇಚ್ಚೆಬೋನ',
'ಇಚ್ಚೆಯಱಿ',
'ಇಚ್ಚೆಯಾಣ್ಮ',
'ಇಚ್ಚೆವಡು',
'ಇಚ್ಚೆಸಾವು',
'ಇಚ್ಚೆಱಗು',
'ಇಚ್ಛಯಿಸು',
'ಇಚ್ಛಾಕಾರ',
'ಇಚ್ಛಾದಾಸ್ಯ',
'ಇಚ್ಛಾನುಡಿ',
'ಇಚ್ಛಾಪತ್ರ',
'ಇಚ್ಛಾಪೂರ್ತಿ',
'ಇಚ್ಛಾಬಲ',
'ಇಚ್ಛಾರೂಪಿ',
'ಇಚ್ಛಾವಂತ',
'ಇಚ್ಛಾವತಿ',
'ಇಚ್ಛಾಶಕ್ತಿ',
'ಇಚ್ಛಾಸಿರ',
'ಇಚ್ಛೆಕಾರ್ತಿ',
'ಇಚ್ಛೆಗಾರ್ತಿ',
'ಇಛ್ಛಾಸಿರ',
'ಇಜಾರ್ದಾರ್',
'ಇಜ್ಜಮರ',
'ಇಜ್ಯಾಕಾಲ',
'ಇಜ್ಯಾಶೀಲ',
'ಇಟಕರ',
'ಇಟಗಾಣಿ',
'ಇಟ್ಟಣಿಕೆ',
'ಇಟ್ಟಣಿಸು',
'ಇಟ್ಟಳಿಸು',
'ಇಟ್ಟಿಗೊಳ್',
'ಇಟ್ಟಿಗೋಲು',
'ಇಟ್ಟಿದಿರು',
'ಇಟ್ಟಿಯಾಳು',
'ಇಟ್ಟಿವೀಸು',
'ಇಟ್ಟುಬೀಸು',
'ಇಡಗಂಟು',
'ಇಡಪಿಡು',
'ಇಡಬೋಲ',
'ಇಡರೆತ್ತು',
'ಇಡಿಕಾಸು',
'ಇಡಿಕಿರಿ',
'ಇಡಿಕಿಱಿ',
'ಇಡಿಕ್ಕಾಯಿ',
'ಇಡಿಗಂಟು',
'ಇಡಿಗಟ್ಟು',
'ಇಡಿಗಪ್ಪು',
'ಇಡಿಗಾಯ್',
'ಇಡಿಗಾಯಿ',
'ಇಡಿಗಾಳು',
'ಇಡಿಗಿಚ್ಚು',
'ಇಡಿಗಿರಿ',
'ಇಡಿಗಿಸು',
'ಇಡಿಗಿಱಿ',
'ಇಡಿಗುಟ್ಟು',
'ಇಡಿಗೋಡೆ',
'ಇಡಿಜಡಿ',
'ಇಡಿತನ',
'ಇಡಿತುಂಬು',
'ಇಡಿತೇಜಿ',
'ಇಡಿಪುಡಿ',
'ಇಡಿಯಪ್ಪ',
'ಇಡಿಹಾರೆ',
'ಇಡುಕಿಱಿ',
'ಇಡುಕುರು',
'ಇಡುಕುಱು',
'ಇಡುಕೈದು',
'ಇಡುಗಂಟು',
'ಇಡುಗಲ್',
'ಇಡುಗಾಣ',
'ಇಡುಗಾಯ್',
'ಇಡುಗಾಯಿ',
'ಇಡುಗಿಚ್ಚು',
'ಇಡುಗುಂಡು',
'ಇಡುಗುಡ್ಡೆ',
'ಇಡುತೊಡು',
'ಇಡುದಡಿ',
'ಇಡುಮದ್ದು',
'ಇಡುವಗೆ',
'ಇಡುವರ್ಣ',
'ಇಡುವಲೆ',
'ಇಡುವಹಿ',
'ಇಡುವೞಿ',
'ಇಡುಸೆಂಡು',
'ಇಡುಸೇಸೆ',
'ಇಡೆಯಾಡು',
'ಇಡೆವೆಟ್ಟ',
'ಇಡ್ಡರಿಗೆ',
'ಇಡ್ಡಲಿಗೆ',
'ಇಡ್ಡಿಲಿಗೆ',
'ಇಣಕಲು',
'ಇಣಿಗೊಳ್',
'ಇಣುಕಿಕ್ಕು',
'ಇತಃಪರ',
'ಇತಃಪರಂ',
'ಇತಬಾರಿ',
'ಇತಮಾಮು',
'ಇತರತೆ',
'ಇತರತ್ರ',
'ಇತರಾರ್ಥ',
'ಇತಸ್ತತ',
'ಇತಿಗತಿ',
'ಇತಿಮಿತಿ',
'ಇತಿವೃತ್ತ',
'ಇತಿಹಾಸ',
'ಇತುಬಾರು',
'ಇತ್ತಕಡೆ',
'ಇತ್ತಲಾಗಿ',
'ಇತ್ತಲೆಗ',
'ಇತ್ತಾಪಾರ',
'ಇತ್ಯರ್ಥಿಸು',
'ಇತ್ವರಿಕೆ',
'ಇತ್ವವೃತ್ತಿ',
'ಇದಮಿತ್ಥಂ',
'ಇದಿಮಾಯಿ',
'ಇದಿರಾಗು',
'ಇದಿರಾಡು',
'ಇದಿರಾನ್',
'ಇದಿರಿಕ್ಕು',
'ಇದಿರಿಡು',
'ಇದಿರಿಸು',
'ಇದಿರೀಸು',
'ಇದಿರೆಡೆ',
'ಇದಿರೆತ್ತು',
'ಇದಿರೇಳು',
'ಇದಿರೇಱು',
'ಇದಿರೇೞ್',
'ಇದಿರೊಂದು',
'ಇದಿರೊಡ್ಡು',
'ಇದಿರ್ಗೊಳ್',
'ಇದಿರ್ನಿಲ್',
'ಇದಿರ್ನೋಡು',
'ಇದಿರ್ಪೋಗು',
'ಇದಿರ್ವರ್',
'ಇದಿರ್ವೀೞ್',
'ಇದಿರ್ವೋಗು',
'ಇದುಕಾರೆ',
'ಇದೆಕೊಳ್',
'ಇದ್ದಲಿಗ',
'ಇದ್ದಲೊಲೆ',
'ಇದ್ದಿರಿಕೆ',
'ಇನಕರ',
'ಇನರಶ್ಮಿ',
'ಇನರಿಪು',
'ಇನವಾರ',
'ಇನಾಂಗ್ರಾಮ',
'ಇನಾಂದಾರ',
'ಇನಾಮತಿ',
'ಇನಾಮತ್ತಿ',
'ಇನಾಯತ',
'ಇನಾಯತು',
'ಇನಾಸರ',
'ಇನಿಕೆಯ್',
'ಇನಿಗಂಪು',
'ಇನಿಗಬ್ಬ',
'ಇನಿಗಲೆ',
'ಇನಿಗಾಲ',
'ಇನಿಗೆನ್ನ',
'ಇನಿಗೆಯ್ತ',
'ಇನಿಗೊಳ',
'ಇನಿಗೋಲ್',
'ಇನಿಗೋಲ',
'ಇನಿಪರಿ',
'ಇನಿಬರ್',
'ಇನಿಬರು',
'ಇನಿಬರುಂ',
'ಇನಿಯಳ್',
'ಇನಿಯಾಣ್ಮ',
'ಇನಿರಸ',
'ಇನಿವಂಡೀ',
'ಇನಿವಂಡು',
'ಇನಿವಣ್',
'ಇನಿವಣ್ಣು',
'ಇನಿವರ್',
'ಇನಿವಾತು',
'ಇನಿವಾಲ್',
'ಇನಿವಿಲ್',
'ಇನಿವಿಲ್ಲ',
'ಇನಿವಿಲ್ಲು',
'ಇನಿವೊೞ್ತು',
'ಇನಿಸಾನುಂ',
'ಇನ್ನಬರ',
'ಇನ್ನುಣಿಸು',
'ಇನ್ನೂರ್ವರ್',
'ಇನ್ನೆಬರ',
'ಇನ್ನೆವರ',
'ಇನ್ನೆವರಂ',
'ಇನ್ನೆವರೆ',
'ಇನ್ನೊಬ್ಬಳು',
'ಇಪ್ಪದರ',
'ಇಪ್ಪಯಣ',
'ಇಪ್ಪಸರ',
'ಇಪ್ಪುರುಷ',
'ಇಪ್ಪೆಂಡಿರ್',
'ಇಪ್ಪೆಜಿಡ್ಡು',
'ಇಪ್ಪೆಯೆಣ್ಣೆ',
'ಇಪ್ಪೆಹಿಂಡಿ',
'ಇಬ್ಬಕುಲ',
'ಇಬ್ಬಕುಳ',
'ಇಬ್ಬಗಿಗೈ',
'ಇಬ್ಬಡಗ',
'ಇಬ್ಬಡಲು',
'ಇಬ್ಬಳಕ್ಕಿ',
'ಇಬ್ಬಳಿಗೆ',
'ಇಬ್ಬಿದಿರು',
'ಇಬ್ಬೆರಳ್',
'ಇಬ್ಬೆರಳು',
'ಇಬ್ಭಾಗಿಸು',
'ಇಭಗತಿ',
'ಇಭಘಟೆ',
'ಇಭತತಿ',
'ಇಭರಿಪು',
'ಇಭಹಸ್ತ',
'ಇಭಾಯಿತೆ',
'ಇಭ್ಯಕುಲ',
'ಇಭ್ಯಾನ್ವಯ',
'ಇಮರಿಕೆ',
'ಇಮರಿಸು',
'ಇಮಾರತಿ',
'ಇಮಾರತು',
'ಇಮ್ಮಡಿಕೆ',
'ಇಮ್ಮಡಿಸು',
'ಇಮ್ಮತ್ತರ್',
'ಇಮ್ಮತ್ತರು',
'ಇಮ್ಮತ್ತಲು',
'ಇಮ್ಮನಸ್ಸು',
'ಇಮ್ಮಿಗಿಲು',
'ಇಮ್ಮಿದಳ್',
'ಇಮ್ಮುಮಮ್ಮು',
'ಇಮ್ಮೆಯ್ಗಾಣ್',
'ಇಮ್ಮೆಯ್ಗೊಳ್',
'ಇಮ್ಮೆಯ್ಜೋಡು',
'ಇಮ್ಮೈಗಾಣ್',
'ಇಮ್ಮೈಜೋಡು',
'ಇಮ್ಮೊನೆಗ',
'ಇರಕಳ',
'ಇರಕಳಿ',
'ಇರಕಿಸು',
'ಇರಕುಳಿ',
'ಇರಕ್ಷಿಚ್ಚು',
'ಇರಚಲು',
'ಇರಮ್ಮದ',
'ಇರವಂತಿ',
'ಇರವಳಿ',
'ಇರವಱಿ',
'ಇರವಾನ್',
'ಇರವಾರ',
'ಇರಸಲು',
'ಇರಸಾಲು',
'ಇರಸ್ತಿಕೆ',
'ಇರಿಕಿಸು',
'ಇರಿಗತ್ತಿ',
'ಇರಿನುಡಿ',
'ಇರಿಮೊಲ್ಲೆ',
'ಇರಿವಿಡು',
'ಇರಿಸಲು',
'ಇರಿಸಿಡು',
'ಇರಿಸಿಸು',
'ಇರಿಸುಲ',
'ಇರಿಸೆಡ್ಲು',
'ಇರುಂಕಿಸು',
'ಇರುಂಬಳ್ಳಿ',
'ಇರುಕಳ',
'ಇರುಕಳು',
'ಇರುಕಿಸು',
'ಇರುಗಡೆ',
'ಇರುವಂತಿ',
'ಇರುವತ್ತು',
'ಇರುವರ್',
'ಇರುವಾಯಿ',
'ಇರುವಾರ',
'ಇರುವಿಕೆ',
'ಇರುಸಾಲು',
'ಇರುಳಕ್ಕಿ',
'ಇರುಳಾಣ್ಮ',
'ಇರುಳೆಲೆ',
'ಇರುಳ್ಗಣ್ಣ',
'ಇರುಳ್ಗಳಿ',
'ಇರುಳ್ಗಳ್ಳ',
'ಇರುಳ್ಗಾಪು',
'ಇರ್ಕಂಡುಗ',
'ಇರ್ಕಡಿಗೈ',
'ಇರ್ಕರ್ತರು',
'ಇರ್ಕೞನಿ',
'ಇರ್ಕುಮರ',
'ಇರ್ಕುೞಿಸು',
'ಇರ್ಕೆಮಾಡು',
'ಇರ್ಕೆದಾಣ',
'ಇರ್ಕೆವನೆ',
'ಇರ್ಕೆವೀಡು',
'ಇರ್ಕೆವೆಟ್ಟು',
'ಇರ್ಕೊಲಿತು',
'ಇರ್ಕೊಳಿಸು',
'ಇರ್ಖಂಡುಗ',
'ಇರ್ಚವಡಿ',
'ಇರ್ಚಾವಡಿ',
'ಇರ್ಚೆಱಂಗು',
'ಇಚ್ರ್ಛಾಸಿರ',
'ಇರ್ಛಾಸಿರ',
'ಇರ್ತಡವೆ',
'ಇರ್ತಿಂಗಳ್',
'ಇರ್ತುಂಡಿಸು',
'ಇರ್ನಯನ',
'ಇರ್ಪುಗುಂದು',
'ಇರ್ಪುರುಷ',
'ಇರ್ಪುವಡೆ',
'ಇರ್ಪುವೆಱು',
'ಇರ್ಪುವೊರೆ',
'ಇರ್ಪೆಂಡಿರ್',
'ಇರ್ಪೋಡಿಸು',
'ಇರ್ಬೆರಲ್',
'ಇರ್ಮಡಿಕೆ',
'ಇರ್ಮಡಿಸು',
'ಇರ್ಮತ್ತರ್',
'ಇರ್ಮೆಯ್ಗಾಣ್',
'ಇರ್ವೆರಳು',
'ಇಲಕಂಠಿ',
'ಇಲವಂಗ',
'ಇಲವಿಂದ',
'ಇಲಾಯಿಚಿ',
'ಇಲಿಕಿವಿ',
'ಇಲಿಗಿವಿ',
'ಇಲಿಗುಲಿ',
'ಇಲಿದೇರ',
'ಇಲಿನಂಜು',
'ಇಲಿಬಲೆ',
'ಇಲಿಬೋನು',
'ಇಲಿಮಿಂಚಿ',
'ಇಲಿಮಿಚ್ಚಿ',
'ಇಲಿಮೀನು',
'ಇಲಿಮುಖ',
'ಇಲಿಯಾಲ',
'ಇಲಿಯಿಂದೆ',
'ಇಲಿವಾಲ',
'ಇಲಿವಾಳ',
'ಇಲಿಸೊಪ್ಪು',
'ಇಲುಮಚಿ',
'ಇಲುಮಟ',
'ಇಲುಮುಚ್ಚೆ',
'ಇಲ್ಲಾಮಲ್ಲಿ',
'ಇಲ್ಲಿಯಣ',
'ಇವಂದಿರ್',
'ಇವದಿರು',
'ಇಷೀಕಾಸ್ತ್ರ',
'ಇಷುಗತಿ',
'ಇಷುಯೋಗ',
'ಇಷುವಿದ್ಯೆ',
'ಇಷೂದ್ಭವ',
'ಇಷ್ಟಕಾರಿ',
'ಇಷ್ಟಗಂಧ',
'ಇಷ್ಟಗುಣಿ',
'ಇಷ್ಟಗೋಷ್ಠಿ',
'ಇಷ್ಟತಮ',
'ಇಷ್ಟದಾನಿ',
'ಇಷ್ಟದೈವ',
'ಇಷ್ಟಪಡು',
'ಇಷ್ಟಪೂರ್ತಿ',
'ಇಷ್ಟಪ್ರಾಪ್ತಿ',
'ಇಷ್ಟಫಲ',
'ಇಷ್ಟಮಿತ್ರ',
'ಇಷ್ಟಯೋಗ',
'ಇಷ್ಟಲಿಂಗ',
'ಇಷ್ಟಸಿದ್ಧಿ',
'ಇಷ್ಟಾಪೂರ್ತ',
'ಇಷ್ಟಾಪೂರ್ತಿ',
'ಇಷ್ಟಾಲಾಪ',
'ಇಷ್ಟಾವಾಪ್ತಿ',
'ಇಷ್ವಾಕಾರ',
'ಇಸಂವಾರ್',
'ಇಸಂವಾರು',
'ಇಸಂಗೋಲು',
'ಇಸಕೊಳ್ಳು',
'ಇಸಗಲೆ',
'ಇಸಗೂಳು',
'ಇಸಗೊಳ್ಳು',
'ಇಸಿಕೊಳ್',
'ಇಸುಕೊಳ್',
'ಇಸುಕೊಳ್ಳು',
'ಇಸುವೆಸ',
'ಇಸ್ತಿಹಾರ್',
'ಇಸ್ತಿಹಾರು',
'ಇಸ್ಮಿಸಾಲು',
'ಇಹತನ',
'ಇಹಪರ',
'ಇಹಭವ',
'ಇಹಲೋಕ',
'ಇಳಕಲ',
'ಇಳಕಲು',
'ಇಳಕೊಳ್ಳು',
'ಇಳವರಿ',
'ಇಳವಾರೆ',
'ಇಳಾಕಾಂತ',
'ಇಳಾಚಕ್ರ',
'ಇಳಾತಳ',
'ಇಳಾಧಿಪ',
'ಇಳಾಧೀಶ',
'ಇಳಾಭೃತ್ತು',
'ಇಳಾಮರ',
'ಇಳಾವರ',
'ಇಳಾವೃಕ',
'ಇಳಿಕಟ್ಟೆ',
'ಇಳಿಕಯ್',
'ಇಳಿಕಲು',
'ಇಳಿಕೆಗೈ',
'ಇಳಿಕೆಯ್',
'ಇಳಿಕೋರೆ',
'ಇಳಿಗಡೆ',
'ಇಳಿಗಣ್ಣು',
'ಇಳಿಗತಿ',
'ಇಳಿಗಾಲ',
'ಇಳಿಗಾವು',
'ಇಳಿಗಾಱ',
'ಇಳಿಗಿಣ್ಪು',
'ಇಳಿಗಿವಿ',
'ಇಳಿಗೊಂಬು',
'ಇಳಿಜಾರು',
'ಇಳಿತರ್',
'ಇಳಿತರ',
'ಇಳಿತಾಯ',
'ಇಳಿತೆಗೆ',
'ಇಳಿದನಿ',
'ಇಳಿದಾಣ',
'ಇಳಿದೆಗೆ',
'ಇಳಿದೆರೆ',
'ಇಳಿನಿಧಿ',
'ಇಳಿನೀರು',
'ಇಳಿಪಾಟು',
'ಇಳಿಪಿಸು',
'ಇಳಿಪ್ರಾಯ',
'ಇಳಿಬಾವಿ',
'ಇಳಿಬಿಡು',
'ಇಳಿಬೀಳು',
'ಇಳಿಬೆಲೆ',
'ಇಳಿಭೋಗ್ಯ',
'ಇಳಿಮೀನು',
'ಇಳಿಮುಖ',
'ಇಳಿಮುಡಿ',
'ಇಳಿಮೇಜು',
'ಇಳಿಮೇಡು',
'ಇಳಿಮೊಗ',
'ಇಳಿಮೋರೆ',
'ಇಳಿಯಂಬು',
'ಇಳಿಯಿಕ್ಕು',
'ಇಳಿವತ್ತೆ',
'ಇಳಿವರಿ',
'ಇಳಿವಾಯ್ಕು',
'ಇಳಿವಿಡಿ',
'ಇಳಿವಿಡು',
'ಇಳಿವೀಳ್',
'ಇಳಿವೀಳು',
'ಇಳಿವೋಗು',
'ಇಳಿವೋರೆ',
'ಇಳಿಶಿಂಗಿ',
'ಇಳಿಹೊತ್ತು',
'ಇಳುಕಲು',
'ಇಳುಗಡೆ',
'ಇಳುಜಾರು',
'ಇಳುಪಿಡು',
'ಇಳುವರಿ',
'ಇಳುಹಿಸು',
'ಇಳೆದೇರ',
'ಇಳೆಯಾಣ್ಮ',
'ಇಳೆಸೋಂಕು',
'ಇಳ್ದುಕೊಳ್',
'ಇಱಸಲ್',
'ಇಱಿಕುಳಿ',
'ಇಱಿಗಾಱ',
'ಇಱಿಗೂಳಿ',
'ಇಱಿನೋವು',
'ಇಱಿಮುಱಿ',
'ಇಱಿಯಿಸು',
'ಇಱಿವಾಳ್',
'ಇಱಿವಿಟ್ಟಿ',
'ಇಱಿಸಲ್',
'ಇಱಿಸಿಲ್',
'ಇಱಿಸಿಲು',
'ಇಱುಕುಳಿ',
'ಇಱುಸಲ',
'ಇೞಿಕಲು',
'ಇೞಿಗೋಡ',
'ಇೞಿತರ್',
'ಇೞಿನೇಹ',
'ಇೞಿವೊಲ',
'ಇೞಿವೋಗು',
'ಇೞ್ಚಾಸಿರ',
'ಇೞ್ಛಾಸಿರ',
'ಇೞ್ದುಕೊಳ್',
'ಈಂಗಡಲ್',
'ಈಂಗೊರಲ್',
'ಈಂಚುವೋಗು',
'ಈಕಾರಾಂತ',
'ಈಕ್ಷಣಿಕ',
'ಈಕ್ಷಣಿಕೆ',
'ಈಕ್ಷ್ಯಕೃತಿ',
'ಈಗಡಿನ',
'ಈಚಲ್ವುೞು',
'ಈಚುವೋಗು',
'ಈಜಾಡಿಸು',
'ಈಜಿಮುಳ್ಳು',
'ಈಜುಕೊಳ',
'ಈಜುಗಾಯಿ',
'ಈಜುಗಾರ',
'ಈಜುಗಾರ್ತಿ',
'ಈಜುರೆಕ್ಕೆ',
'ಈಟರಾಗ',
'ಈಟಿಗಣೆ',
'ಈಟಿಗಾಳ',
'ಈಡಾಡಿಸು',
'ಈಡಿಗಿತಿ',
'ಈಡಿಗಿತ್ತಿ',
'ಈಡಿಗ್ಗೌಡ',
'ಈಡುಕಾರ',
'ಈಡುಗಡಿ',
'ಈಡುಗಾಣು',
'ಈಡುಗಾಯಿ',
'ಈಡುಗಾರ',
'ಈಡುಗೋಡೆ',
'ಈಡೇರಿಕೆ',
'ಈಡೇರಿಸು',
'ಈಡೇಱಿಸು',
'ಈಡೊರಸು',
'ಈತಿಬಾಧೆ',
'ಈದೊರೆತು',
'ಈದೊರೆಯ',
'ಈಪ್ಸಿತಾರ್ಥ',
'ಈರಣಿಗೆ',
'ಈರರಿಲ್',
'ಈರಿರುಳು',
'ಈರುದಲೆ',
'ಈರೆರಡು',
'ಈರೆೞ್ಪತ್ತು',
'ಈರೇೞ್ಗಾಲ',
'ಈರೈನೂಱು',
'ಈರೊಂಬತು',
'ಈರ್ಮಕಾಱ',
'ಈರ್ಯಾಪಥ',
'ಈರ್ಯಾಮಾರ್ಗ',
'ಈವರೆಗೆ',
'ಈಶಗಿರಿ',
'ಈಶಪ್ರಿಯ',
'ಈಶಾನೇಂದ್ರ',
'ಈಶ್ವರತ್ವ',
'ಈಶ್ವರೀಯ',
'ಈಷತ್ಪಾಂಡು',
'ಈಷದಪಿ',
'ಈಸುಕಾಯಿ',
'ಈಸುಗಾಯಿ',
'ಈಹಲೋಕ',
'ಈಹಾಮೃಗ',
'ಈಳ್ದುಕೊಳ್',
'ಉಂಗುರಿಸು',
'ಉಂಗ್ರುಡಿಕೆ',
'ಉಂಚಜೀವಿ',
'ಉಂಛವೃತ್ತಿ',
'ಉಂಛಶಿಲ',
'ಉಂಟುಮಾಡು',
'ಉಂಡಲಕ್ಕು',
'ಉಂಡಲಿಗೆ',
'ಉಂಡಾಡಿಗ',
'ಉಂಡಿಲಿಗೆ',
'ಉಂಡೆಕಟ್ಟು',
'ಉಂಡೆಕ್ಕಳಿ',
'ಉಂಡೆಗಟ್ಟು',
'ಉಂಡೆದಲೆ',
'ಉಂಡೆಮೀನು',
'ಉಂಡೆಲೆಗ್ಗೆ',
'ಉಂಡೆಸುತ್ತು',
'ಉಂತಪ್ಪನ್',
'ಉಂಬಳಿಗೆ',
'ಉಂಬಳಿತ',
'ಉಂಬುದಾರ',
'ಉಕಪ್ರಿಯ',
'ಉಕವಾಣಿ',
'ಉಕಾರಾಂತ',
'ಉಕುತಿಸು',
'ಉಕ್ಕರಿಸು',
'ಉಕ್ಕಲಿಕೆ',
'ಉಕ್ಕಲಿಸು',
'ಉಕ್ಕಳಿಕೆ',
'ಉಕ್ಕಳಿಸು',
'ಉಕ್ಕುಂದೊಱೆ',
'ಉಕ್ಕುಡಿಸು',
'ಉಕ್ಕುಬಾವಿ',
'ಉಕ್ಕುಸುಣ್ಣ',
'ಉಕ್ಕುಸೊಕ್ಕು',
'ಉಕ್ಕೆಹೊಡೆ',
'ಉಕ್ಕೆವಿಸು',
'ಉಕ್ಕೆಸರು',
'ಉಕ್ತಿಪ್ರೌಢ',
'ಉಕ್ತಿಪ್ರೌಢೆ',
'ಉಕ್ತಿಭಂಗಿ',
'ಉಕ್ತಿವಿದ',
'ಉಕ್ತ್ಯುತ್ಪನ್ನ',
'ಉಕ್ಷಕೇತ',
'ಉಕ್ಷತರ',
'ಉಕ್ಷಧ್ವಜ',
'ಉಕ್ಷಪತಿ',
'ಉಕ್ಷರಾಜ',
'ಉಕ್ಷಾಸನ',
'ಉಕ್ಷೇಶ್ವರ',
'ಉಕ್ಷೋದ್ಭವ',
'ಉಗಮಿಸು',
'ಉಗರಿಸು',
'ಉಗಾಭೋಗ',
'ಉಗಿಕಾವು',
'ಉಗಿಕಾಸು',
'ಉಗಿಕೊಡು',
'ಉಗಿಗೊಳ್',
'ಉಗಿಚುಳ್ಳಿ',
'ಉಗಿಪಟ',
'ಉಗಿಪದ',
'ಉಗಿಬಂಡಿ',
'ಉಗಿಬಗಿ',
'ಉಗಿಯಂತ್ರ',
'ಉಗಿವಡೆ',
'ಉಗಿಹಂಡೆ',
'ಉಗುತರ್',
'ಉಗುಬೆಲ್ಲ',
'ಉಗುರಂಟು',
'ಉಗುರಿಸು',
'ಉಗುರುಳಿ',
'ಉಗುರೆಣ್ಣೆ',
'ಉಗುರೊತ್ತು',
'ಉಗುರ್ಗಲೆ',
'ಉಗುರ್ಗೊನೆ',
'ಉಗುರ್ವಲ್ಲಿ',
'ಉಗುರ್ವಿಸಿ',
'ಉಗುರ್ವೆಳ್ಪು',
'ಉಗುರ್ವೊಯ್ಲು',
'ಉಗುಳಿಕೆ',
'ಉಗುಳಿಚು',
'ಉಗುಳಿಸು',
'ಉಗುಳ್ನೀರು',
'ಉಗುೞಿಸು',
'ಉಗುೞ್ತರ್',
'ಉಗೆವಾಡ',
'ಉಗ್ಗಡಣೆ',
'ಉಗ್ಗಡಿಸು',
'ಉಗ್ಗಳಿಸು',
'ಉಗ್ಗುಮಾತು',
'ಉಗ್ಗುವಾತು',
'ಉಗ್ಘಡಣೆ',
'ಉಗ್ಘಡಿಸು',
'ಉಗ್ಘಳಿಸು',
'ಉಗ್ರಕರ',
'ಉಗ್ರಕರ್ಮ',
'ಉಗ್ರಗಂಧ',
'ಉಗ್ರಗಂಧಿ',
'ಉಗ್ರಗಂಧೆ',
'ಉಗ್ರಗಾಮಿ',
'ಉಗ್ರಘೋಷ',
'ಉಗ್ರತಿಮಿ',
'ಉಗ್ರತೇಜ',
'ಉಗ್ರದಂಡ',
'ಉಗ್ರಧನ್ವ',
'ಉಗ್ರನಖ',
'ಉಗ್ರಪಕ್ಷ',
'ಉಗ್ರಪಾಣಿ',
'ಉಗ್ರಬಲ',
'ಉಗ್ರವಾದಿ',
'ಉಗ್ರಸತ್ವ',
'ಉಗ್ರಸತ್ವೆ',
'ಉಗ್ರಾಂಬಕ',
'ಉಗ್ರಾಣಿಕ',
'ಉಗ್ರಾಣಿಸು',
'ಉಚಾಪತಿ',
'ಉಚಾಯಿಸು',
'ಉಚಿತಜ್ಞ',
'ಉಚಿತಜ್ಞೆ',
'ಉಚಿತೋಕ್ತಿ',
'ಉಚಿತ್ತಾರ',
'ಉಚ್ಚಂಬರಿ',
'ಉಚ್ಚಘ್ರಾಣ',
'ಉಚ್ಚಟಿಗೆ',
'ಉಚ್ಚತಮ',
'ಉಚ್ಚಪುಚ್ಛ',
'ಉಚ್ಚರಣ',
'ಉಚ್ಚರಣೆ',
'ಉಚ್ಚರಿತ',
'ಉಚ್ಚರಿಸು',
'ಉಚ್ಚಲಾಟ',
'ಉಚ್ಚಲಿತ',
'ಉಚ್ಚಲಿಸು',
'ಉಚ್ಚವಿಸು',
'ಉಚ್ಚವೇಣಿ',
'ಉಚ್ಚಸ್ತನಿ',
'ಉಚ್ಚಸ್ಥಾನ',
'ಉಚ್ಚಸ್ಥಿತಿ',
'ಉಚ್ಚಳಿತ',
'ಉಚ್ಚಳಿಸು',
'ಉಚ್ಚಾಟಕ',
'ಉಚ್ಚಾಟನ',
'ಉಚ್ಚಾಟನೆ',
'ಉಚ್ಚಾಟಿತ',
'ಉಚ್ಚಾಟಿಸು',
'ಉಚ್ಚಾಪತಿ',
'ಉಚ್ಚಾಯಿಸು',
'ಉಚ್ಚಾರಕ',
'ಉಚ್ಚಾರಕಿ',
'ಉಚ್ಚಾರಣ',
'ಉಚ್ಚಾರಣೆ',
'ಉಚ್ಚಾರಿತ',
'ಉಚ್ಚಾರಿಸು',
'ಉಚ್ಚಾವಚ',
'ಉಚ್ಚಾಸನ',
'ಉಚ್ಚುಗಂಟು',
'ಉಚ್ಚುರೋಗ',
'ಉಚ್ಚೆಕಟ್ಟು',
'ಉಚ್ಚೆಕಾಯಿ',
'ಉಚ್ಚೆಗುಣಿ',
'ಉಚ್ಚೆಚೀಲ',
'ಉಚ್ಚೆಪೀಡೆ',
'ಉಚ್ಚೆಮಲ್ಲಿ',
'ಉಚ್ಚೆಮುೂಳಿ',
'ಉಚ್ಚೆರೋಗ',
'ಉಚ್ಚೈರ್ಘುಷ್ಟ',
'ಉಚ್ಚೈರ್ಘೋಷ',
'ಉಚ್ಚೈಧ್ರ್ವನಿ',
'ಉಚ್ಚೈಧ್ರ್ವಾನ',
'ಉಚ್ಚೈಶ್ರವ',
'ಉಚ್ಚೈಸ್ತರ',
'ಉಚ್ಛವಿಸು',
'ಉಚ್ಛಳಿಸು',
'ಉಚ್ಛಾದನ',
'ಉಚ್ಛಾಹಿಸು',
'ಉಚ್ಛೀರ್ಷಕ',
'ಉಚ್ಛೃಂಖಲ',
'ಉಚ್ಛೃಂಖಲೆ',
'ಉಚ್ಛೃಂಖಳ',
'ಉಚ್ಛೇದನ',
'ಉಚ್ಛ್ವಸಿತ',
'ಉಚ್ಛ್ವಾಸಿಸು',
'ಉಛಾಯಿಸು',
'ಉಜಕರ್ಣಿ',
'ಉಜರಿಡು',
'ಉಜಳಣಿ',
'ಉಜಾಡೇಳು',
'ಉಜ್ಜನಿತ',
'ಉಜ್ಜಮಿಸು',
'ಉಜ್ಜವಣೆ',
'ಉಜ್ಜವಿಸು',
'ಉಜ್ಜಳಿಕೆ',
'ಉಜ್ಜಳಿಕ್ಕೆ',
'ಉಜ್ಜಳಿಸು',
'ಉಜ್ಜಾಸನ',
'ಉಜ್ಜೀವನ',
'ಉಜ್ಜೀವಿಸು',
'ಉಜ್ಜುಕಲ್ಲು',
'ಉಜ್ಜುಗಗೈ',
'ಉಜ್ಜುಗಲ್ಲು',
'ಉಜ್ಜುಗಸ್ಥ',
'ಉಜ್ಜುಗಾಯ',
'ಉಜ್ಜುಗಾರ್ತ',
'ಉಜ್ಜುಗಾರ್ತಿ',
'ಉಜ್ಜುಗಿಸು',
'ಉಜ್ಜುಗೋರಿ',
'ಉಜ್ಜುಗೋಲು',
'ಉಜ್ಜುತೈಲ',
'ಉಜ್ಜೃಂಭಿತ',
'ಉಜ್ಜೃಂಭಿಸು',
'ಉಜ್ಜ್ವಲತೆ',
'ಉಜ್ಜ್ವಲಿತ',
'ಉಜ್ಜ್ವಲಿಸು',
'ಉಜ್ಜ್ವಳಿತ',
'ಉಜ್ಜ್ವಳಿಸು',
'ಉಜ್ವಲತೆ',
'ಉಜ್ವಲಿಸು',
'ಉಜ್ವಳಾಂಗಿ',
'ಉಜ್ವಳಿಕೆ',
'ಉಜ್ವಳಿಸು',
'ಉಟಾಯಿಸು',
'ಉಟಿಹಬ್ಬ',
'ಉಟ್ಟಾವಳಿ',
'ಉಠಾವಣೆ',
'ಉಠಾವುಠಿ',
'ಉಡಮೊಗ',
'ಉಡಯಾರ್',
'ಉಡವಣಿ',
'ಉಡವಾೞ್',
'ಉಡವೀೞ್',
'ಉಡಾಪಿಸು',
'ಉಡಾಯಿಸು',
'ಉಡಾವಣೆ',
'ಉಡಿಕತ್ತಿ',
'ಉಡಿಗಂಟು',
'ಉಡಿಗಂಟೆ',
'ಉಡಿಗಲ್ಲಿ',
'ಉಡಿಗೂಸು',
'ಉಡಿಗೆಜ್ಜೆ',
'ಉಡಿಘಂಟೆ',
'ಉಡಿಜಲ್ಲಿ',
'ಉಡಿಝಲ್ಲಿ',
'ಉಡಿತುಂಬು',
'ಉಡಿದದ್ದು',
'ಉಡಿದಲ್ಲಿ',
'ಉಡಿದಾರ',
'ಉಡಿನೂಲ್',
'ಉಡಿನೂಲು',
'ಉಡಿಮಣಿ',
'ಉಡಿಮರ',
'ಉಡಿಯಕ್ಕಿ',
'ಉಡಿಯಿಸು',
'ಉಡಿವಣಿ',
'ಉಡಿವರ',
'ಉಡಿಸುತ್ತು',
'ಉಡುಕರ',
'ಉಡುಕಿಸು',
'ಉಡುಕುಳಿ',
'ಉಡುಗಣ',
'ಉಡುಗಣೆ',
'ಉಡುಗರೆ',
'ಉಡುಗಱೆ',
'ಉಡುಗಿಸು',
'ಉಡುಗುಣಿ',
'ಉಡುಗೆರೆ',
'ಉಡುಗೊರೆ',
'ಉಡುಗೊಱೆ',
'ಉಡುಗೋಲು',
'ಉಡುತತಿ',
'ಉಡುದಾರ',
'ಉಡುದೇವ',
'ಉಡುನಾಥ',
'ಉಡುಪತಿ',
'ಉಡುಪಥ',
'ಉಡುಬೆಂಚೆ',
'ಉಡುಮಾರ್ಗ',
'ಉಡುಮೊಗ',
'ಉಡುರಾಜ',
'ಉಡುವರ',
'ಉಡುವಲಿ',
'ಉಡುವಳ್ಳಿ',
'ಉಡುಸೀರೆ',
'ಉಡೆಗಂಟೆ',
'ಉಡೆದಾರ',
'ಉಡೆದೋಲ್',
'ಉಡೆನಿಱಿ',
'ಉಡೆನೂಲ್',
'ಉಡೆನೂಲು',
'ಉಡೆನೇಣ್',
'ಉಡೆಮಣಿ',
'ಉಡೆಯುೞ್ಚು',
'ಉಡೆವಣಿ',
'ಉಡೆಸುತ್ತು',
'ಉಡೆಹೀನ',
'ಉಡ್ಡಯನ',
'ಉಡ್ಡಾಡಿಸು',
'ಉಡ್ಡಾಣಗೈ',
'ಉಡ್ಡಿಯಾಣ',
'ಉಣಾಮಿಣಿ',
'ಉಣುಗೋಲ್',
'ಉಣುಗೋಲು',
'ಉಣುಮನೆ',
'ಉಣ್ಣಗುಳಿ',
'ಉಣ್ಣಪಡು',
'ಉಣ್ಣವಳಿ',
'ಉಣ್ಣೆಜುಂಗು',
'ಉತಳಗಿ',
'ಉತಾರಿಸು',
'ಉತಾವಳಿ',
'ಉತಾವಿಳ',
'ಉತುಕೋಚ',
'ಉತುಕೋಚಿ',
'ಉತುಪತಿ',
'ಉತುಪಾತ',
'ಉತ್ಕಂಠತೆ',
'ಉತ್ಕಂಠಿತ',
'ಉತ್ಕಂಠಿತೆ',
'ಉತ್ಕಂಪಿತ',
'ಉತ್ಕಟತೆ',
'ಉತ್ಕಟತ್ವ',
'ಉತ್ಕಟಿಸು',
'ಉತ್ಕರಣ',
'ಉತ್ಕರುಷ',
'ಉತ್ಕರ್ಷಕ',
'ಉತ್ಕರ್ಷಣೆ',
'ಉತ್ಕರ್ಷತೆ',
'ಉತ್ಕರ್ಷಿಸು',
'ಉತ್ಕಲಿಕೆ',
'ಉತ್ಕಲ್ಲೋಲ',
'ಉತ್ಕಲ್ಲೋಳ',
'ಉತ್ಕಳಿಕೆ',
'ಉತ್ಕಾಂಕ್ಷಿಸು',
'ಉತ್ಕೀರ್ಣಿಸು',
'ಉತ್ಕೀಲಿಸು',
'ಉತ್ಕೃಷ್ಟತೆ',
'ಉತ್ಕೇಂದ್ರತೆ',
'ಉತ್ಕೇಂದ್ರೀಯ',
'ಉತ್ಕ್ರಮಣ',
'ಉತ್ಕ್ರಮಿಸು',
'ಉತ್ಕ್ಷೇಪಕ',
'ಉತ್ಖನನ',
'ಉತ್ತಂಭಿತ',
'ಉತ್ತಂಸಕ',
'ಉತ್ತಂಸಿತ',
'ಉತ್ತಮತೆ',
'ಉತ್ತಮರ್ಣ',
'ಉತ್ತಮಾಂಗ',
'ಉತ್ತಮಾಗ್ರ',
'ಉತ್ತಮಿಕೆ',
'ಉತ್ತರಂಗ',
'ಉತ್ತರಣ',
'ಉತ್ತರಣಿ',
'ಉತ್ತರಣೆ',
'ಉತ್ತರವೀ',
'ಉತ್ತರಸ',
'ಉತ್ತರಳ',
'ಉತ್ತರಾಂಗ',
'ಉತ್ತರಾಂಶ',
'ಉತ್ತರಾಜಿ',
'ಉತ್ತರಾಣಿ',
'ಉತ್ತರಾದಿ',
'ಉತ್ತರಾಧಿ',
'ಉತ್ತರಾಯಿ',
'ಉತ್ತರಾರ್ಥ',
'ಉತ್ತರಾರ್ಧ',
'ಉತ್ತರಾಸ',
'ಉತ್ತರಿಕೆ',
'ಉತ್ತರಿಗೆ',
'ಉತ್ತರಿಸು',
'ಉತ್ತರೀಯ',
'ಉತ್ತರೇಣಿ',
'ಉತ್ತವಳ',
'ಉತ್ತಳಿಸು',
'ಉತ್ತಾಡಿತ',
'ಉತ್ತಾನಕ',
'ಉತ್ತಾನತೆ',
'ಉತ್ತಾನಿತ',
'ಉತ್ತಾಪಕ',
'ಉತ್ತಾಯಕ',
'ಉತ್ತಾರಕ',
'ಉತ್ತಾರಣ',
'ಉತ್ತಾರಣೆ',
'ಉತ್ತಾರಿತ',
'ಉತ್ತಾರಿಸು',
'ಉತ್ತಾಳತೆ',
'ಉತ್ತೀರ್ಣತೆ',
'ಉತ್ತುಂಗತೆ',
'ಉತ್ತೇಜಕ',
'ಉತ್ತೇಜಕಿ',
'ಉತ್ತೇಜನ',
'ಉತ್ತೇಜಿತ',
'ಉತ್ತೇಜಿಸು',
'ಉತ್ತೇರಿತ',
'ಉತ್ತೋರಣ',
'ಉತ್ಥಾನತೆ',
'ಉತ್ಥಾಪಕ',
'ಉತ್ಥಾಪನ',
'ಉತ್ಥಾಪನೆ',
'ಉತ್ಥಾಪಿತ',
'ಉತ್ಪತನ',
'ಉತ್ಪತಾಕ',
'ಉತ್ಪತಿಷ್ಣು',
'ಉತ್ಪನ್ನಕ',
'ಉತ್ಪನ್ನತೆ',
'ಉತ್ಪಲಾಪ್ತ',
'ಉತ್ಪಲಿನಿ',
'ಉತ್ಪವನ',
'ಉತ್ಪಳಿನಿ',
'ಉತ್ಪಾಟನ',
'ಉತ್ಪಾಟಿತ',
'ಉತ್ಪಾತಕ',
'ಉತ್ಪಾದಕ',
'ಉತ್ಪಾದಕಿ',
'ಉತ್ಪಾದನ',
'ಉತ್ಪಾದನೆ',
'ಉತ್ಪಾದಿಸು',
'ಉತ್ಪಿಂಜಲ',
'ಉತ್ಪುಲಕ',
'ಉತ್ಪುಳಕ',
'ಉತ್ಪ್ರೇಕ್ಷಕ',
'ಉತ್ಪ್ರೇಕ್ಷಣ',
'ಉತ್ಪ್ರೇಕ್ಷಿಸು',
'ಉತ್ಪ್ರೇರಕ',
'ಉತ್ಪ್ರೇರಣೆ',
'ಉತ್ಪ್ರೇಷಣ',
'ಉತ್ಪ್ಲವನ',
'ಉತ್ಫಣಿತ',
'ಉತ್ಸಂಗಸ್ಥೆ',
'ಉತ್ಸರ್ಜಕ',
'ಉತ್ಸರ್ಜನ',
'ಉತ್ಸರ್ಜನೆ',
'ಉತ್ಸರ್ಜಿತ',
'ಉತ್ಸರ್ಜಿಸು',
'ಉತ್ಸರ್ಪಣ',
'ಉತ್ಸರ್ಪಿಣಿ',
'ಉತ್ಸವಿಸು',
'ಉತ್ಸಹಿಸು',
'ಉತ್ಸಳಿಸು',
'ಉತ್ಸಾದಕ',
'ಉತ್ಸಾದನ',
'ಉತ್ಸಾಯಕ',
'ಉತ್ಸಾರಕ',
'ಉತ್ಸಾರಿತ',
'ಉತ್ಸಾಹಕ',
'ಉತ್ಸಾಹನ',
'ಉತ್ಸಾಹಿಸು',
'ಉತ್ಸುಕತೆ',
'ಉತ್ಸುಕತ್ವ',
'ಉತ್ಸುಕಿಸು',
'ಉದಂಚನ',
'ಉದಂಚಿತ',
'ಉದಂತತೆ',
'ಉದಕುಂಭ',
'ಉದಗ್ಗತಿ',
'ಉದಗ್ಭವ',
'ಉದಗ್ಭೂಮ',
'ಉದಗ್ಭೂಮಿ',
'ಉದಙ್ಮುಖ',
'ಉದಧಿಪ',
'ಉದನ್ಮುಖ',
'ಉದನ್ವ್ವಂತ',
'ಉದನ್ವತ್ತು',
'ಉದಪಾನ',
'ಉದಬಿಂದು',
'ಉದಮದ',
'ಉದಯಸ್ಥ',
'ಉದಯಾದ್ರಿ',
'ಉದಯಿಸು',
'ಉದರಾಂತ',
'ಉದರಾಗ್ನಿ',
'ಉದರಾರ್ಥಿ',
'ಉದವಾಸ',
'ಉದಶ್ವಿತ್ತು',
'ಉದಾಗಸ',
'ಉದಾತ್ತತೆ',
'ಉದಾನಿಸು',
'ಉದಾರತೆ',
'ಉದಾರತ್ವ',
'ಉದಾಸಿನ',
'ಉದಾಸಿಸು',
'ಉದಾಸೀನ',
'ಉದಾಸ್ಥಿತ',
'ಉದಾಹಾರ',
'ಉದಾಹೃತ',
'ಉದಾಹೃತಿ',
'ಉದಿತಾರ್ಥ',
'ಉದಿರಿಸು',
'ಉದೀಚೀನ',
'ಉದೀರಣ',
'ಉದೀರಿತ',
'ಉದೀರಿಸು',
'ಉದೀರ್ಣತೆ',
'ಉದುಂಬರ',
'ಉದುಭವ',
'ಉದುಮದ',
'ಉದುರಿಚು',
'ಉದುರಿಸು',
'ಉದುರ್ಗುಟ್ಟು',
'ಉದೂಖಲ',
'ಉದ್ಗಮನ',
'ಉದ್ಗಮಾಸ್ತ್ರ',
'ಉದ್ಗಮಿಸು',
'ಉದ್ಗರಣೆ',
'ಉದ್ಗರಿಸು',
'ಉದ್ಗಲಿತ',
'ಉದ್ಗಳಿತ',
'ಉದ್ಗಾಯನ',
'ಉದ್ಗಾರಿಸು',
'ಉದ್ಗಿರಣ',
'ಉದ್ಗ್ರಾಹಕ',
'ಉದ್ಘಕೀರ್ತಿ',
'ಉದ್ಘಕೃತಿ',
'ಉದ್ಘಚಾರಿ',
'ಉದ್ಘಟನ',
'ಉದ್ಘಟಿಸು',
'ಉದ್ಘಟ್ಟನ',
'ಉದ್ಘಾಚಾರ',
'ಉದ್ಘಾಟಕ',
'ಉದ್ಘಾಟಕಿ',
'ಉದ್ಘಾಟನ',
'ಉದ್ಘಾಟನೆ',
'ಉದ್ಘಾಟಿಸು',
'ಉದ್ಘೋಷಕ',
'ಉದ್ಘೋಷಣ',
'ಉದ್ಘೋಷಣೆ',
'ಉದ್ಘೋಷಿಸು',
'ಉದ್ದಂಡತೆ',
'ಉದ್ದಂಡಿಕೆ',
'ಉದ್ದಂಡಿಸು',
'ಉದ್ದಂನುಡಿ',
'ಉದ್ದಂನೆಗೆ',
'ಉದ್ದಂಬರ್',
'ಉದ್ದಗಲ',
'ಉದ್ದಧ್ವನಿ',
'ಉದ್ದನೀರು',
'ಉದ್ದಮಿಸು',
'ಉದ್ದಮುರಿ',
'ಉದ್ದಮೇಱು',
'ಉದ್ದವಣ',
'ಉದ್ದವರಿ',
'ಉದ್ದವೇಱು',
'ಉದ್ದಳನ',
'ಉದ್ದಳಿತ',
'ಉದ್ದಾಮತೆ',
'ಉದ್ದಾಲಕ',
'ಉದ್ದೀಪಕ',
'ಉದ್ದೀಪನ',
'ಉದ್ದೀಪಿತ',
'ಉದ್ದೀಪಿಸು',
'ಉದ್ದುಮೆಗೈ',
'ಉದ್ದುರುಟ',
'ಉದ್ದುರುಟು',
'ಉದ್ದುವಾರೆ',
'ಉದ್ದೇಶಿತ',
'ಉದ್ದೇಶಿಸು',
'ಉದ್ಧತಾರಿ',
'ಉದ್ಧತಿಕೆ',
'ಉದ್ಧತಿಕ್ಕೆ',
'ಉದ್ಧರಣ',
'ಉದ್ಧರಣೆ',
'ಉದ್ಧರಾಗ್ನಿ',
'ಉದ್ಧರಿಸು',
'ಉದ್ಧಾರಕ',
'ಉದ್ಧಾರಿಸು',
'ಉದ್ಧುಲಿಸು',
'ಉದ್ಧುಳಿಸು',
'ಉದ್ಧುಷಣ',
'ಉದ್ಧೂಲನ',
'ಉದ್ಧೂಲಿತ',
'ಉದ್ಧೂಲಿಸು',
'ಉದ್ಧೂಳನ',
'ಉದ್ಧೂಳಿತ',
'ಉದ್ಧೂಳಿಸು',
'ಉದ್ಬೋಧಕ',
'ಉದ್ಭವಂಗೈ',
'ಉದ್ಭವನ',
'ಉದ್ಭವಿಸು',
'ಉದ್ಭಾವಕ',
'ಉದ್ಭಾವನ',
'ಉದ್ಭಾವಿಸು',
'ಉದ್ಭಾಷಿಸು',
'ಉದ್ಭಾಸಿತ',
'ಉದ್ಭಾಸಿನಿ',
'ಉದ್ಭಾಸಿಸು',
'ಉದ್ಭ್ರಮಿತ',
'ಉದ್ಭ್ರಮಿತೆ',
'ಉದ್ಭ್ರಮಿಸು',
'ಉದ್ಭ್ರಾಮಿತ',
'ಉದ್ಯಚ್ಚಕ್ರ',
'ಉದ್ಯಚ್ಚಕ್ರಿ',
'ಉದ್ಯವನ',
'ಉದ್ಯಾಪನ',
'ಉದ್ಯಾಪನೆ',
'ಉದ್ಯಾಪಿಸು',
'ಉದ್ಯುಕುತ',
'ಉದ್ಯುಕ್ತನ',
'ಉದ್ಯೋಗಸ್ಥ',
'ಉದ್ಯೋಗಸ್ಥೆ',
'ಉದ್ಯೋಗಿಸು',
'ಉದ್ಯೋತಿತ',
'ಉದ್ಯೋತಿಸು',
'ಉದ್ರಿಕ್ತತೆ',
'ಉದ್ರೇಕಿಸು',
'ಉದ್ವರ್ತನ',
'ಉದ್ವರ್ತಿಸು',
'ಉದ್ವಹನ',
'ಉದ್ವಾಯನ',
'ಉದ್ವಾರ್ಚನೆ',
'ಉದ್ವಾಸನ',
'ಉದ್ವಾಸಿತ',
'ಉದ್ವಾಸಿಸು',
'ಉದ್ವಾಹಕ',
'ಉದ್ವಾಹನ',
'ಉದ್ವಾಹಿಕ',
'ಉದ್ವಾಹಿತ',
'ಉದ್ವಾಹಿಸು',
'ಉದ್ವಿಗ್ನತೆ',
'ಉದ್ವೀಕ್ಷಣ',
'ಉದ್ವೃತ್ತತೆ',
'ಉದ್ವೇಗಿಸು',
'ಉದ್ವೇಜನ',
'ಉದ್ವೇಜಿಸು',
'ಉನಾಮಕ',
'ಉನಿಯಿಸು',
'ಉನುಮನಿ',
'ಉನ್ನತತ್ವ',
'ಉನ್ನತಾಂಶ',
'ಉನ್ನತಿಕೆ',
'ಉನ್ನತಿಕ್ಕೆ',
'ಉನ್ನತಿರ್ಕೆ',
'ಉನ್ನತೇಚ್ಛ',
'ಉನ್ನತೇಶ',
'ಉನ್ನಮಿತ',
'ಉನ್ನಯನ',
'ಉನ್ನಯಿಸು',
'ಉನ್ನವಿಸು',
'ಉನ್ನಿದ್ರತೆ',
'ಉನ್ನೆವರಂ',
'ಉನ್ಮಜ್ಜನ',
'ಉನ್ಮತ್ತತೆ',
'ಉನ್ಮದನ',
'ಉನ್ಮದಿಷ್ಣು',
'ಉನ್ಮಧುಕೆ',
'ಉನ್ಮನಳು',
'ಉನ್ಮಹಿಮ',
'ಉನ್ಮಾದನ',
'ಉನ್ಮಾದಿನಿ',
'ಉನ್ಮಾರ್ಗತೆ',
'ಉನ್ಮಾರ್ಜನ',
'ಉನ್ಮಿಷಿತ',
'ಉನ್ಮೀಲನ',
'ಉನ್ಮೀಲಿತ',
'ಉನ್ಮೀಲಿಸು',
'ಉನ್ಮೀಳನ',
'ಉನ್ಮೀಳಿತ',
'ಉನ್ಮೀಳಿಸು',
'ಉನ್ಮೂಲನ',
'ಉನ್ಮೂಲಿತ',
'ಉನ್ಮೂಲಿಸು',
'ಉನ್ಮೂಳಿತ',
'ಉನ್ಮೂಳಿಸು',
'ಉಪಕಂಠ',
'ಉಪಕಥೆ',
'ಉಪಕರ',
'ಉಪಕಲ್ಪ',
'ಉಪಕಾರ',
'ಉಪಕಾರಿ',
'ಉಪಕಾರ್ಯ',
'ಉಪಕುಲ್ಯೆ',
'ಉಪಕುಶ',
'ಉಪಕೂಪ',
'ಉಪಕೃತ',
'ಉಪಕೃತಿ',
'ಉಪಕೆಯ',
'ಉಪಕೇಂದ್ರ',
'ಉಪಕ್ರಮ',
'ಉಪಕ್ರಮಿ',
'ಉಪಕ್ರೋಶ',
'ಉಪಕ್ಷಮ',
'ಉಪಕ್ಷಯ',
'ಉಪಕ್ಷೇಪ',
'ಉಪಖಂಡ',
'ಉಪಖ್ಯಾತ',
'ಉಪಗಣ',
'ಉಪಗತ',
'ಉಪಗಮ',
'ಉಪಗಿರಿ',
'ಉಪಗೃಹ',
'ಉಪಗ್ರಹ',
'ಉಪಗ್ರಾಮ',
'ಉಪಗ್ರಾಹ್ಯ',
'ಉಪಘಾತ',
'ಉಪಚಯ',
'ಉಪಚರ',
'ಉಪಚರ್ಯೆ',
'ಉಪಚಾಯ್ಯ',
'ಉಪಚಾರ',
'ಉಪಚಾರಿ',
'ಉಪಚಾರ್ಯ',
'ಉಪಚಿತ',
'ಉಪಚಿತ್ರೆ',
'ಉಪಜವ',
'ಉಪಜಾತ',
'ಉಪಜಾತಿ',
'ಉಪಜಾಪ',
'ಉಪಜಾಪ್ಯ',
'ಉಪಜಾಹ',
'ಉಪಜಿಹ್ವೆ',
'ಉಪಜೀವಿ',
'ಉಪಜೀವ್ಯ',
'ಉಪಜೋಷ',
'ಉಪಜ್ಞತೆ',
'ಉಪಟಲ',
'ಉಪಟಳ',
'ಉಪಟಾಳಿ',
'ಉಪತಂಡ',
'ಉಪತಾಪ',
'ಉಪತಾಯ',
'ಉಪತ್ಯಕ',
'ಉಪತ್ಯಕೆ',
'ಉಪದಂಶ',
'ಉಪದಾನ',
'ಉಪದಿಗ್ಧ',
'ಉಪದಿಷ್ಟ',
'ಉಪದಿಷ್ಟೆ',
'ಉಪದೀಕೆ',
'ಉಪದೇಶ',
'ಉಪದೇಶಿ',
'ಉಪದ್ರವ',
'ಉಪದ್ರಿಸು',
'ಉಪದ್ರುತ',
'ಉಪದ್ವೀಪ',
'ಉಪದ್ವ್ಯಾಪ',
'ಉಪದ್ವ್ಯಾಪಿ',
'ಉಪಧಾನ',
'ಉಪಧೃತಿ',
'ಉಪನತ',
'ಉಪನದಿ',
'ಉಪನಯ',
'ಉಪನಾಮ',
'ಉಪನಾಹ',
'ಉಪನಿಧಿ',
'ಉಪನೀತ',
'ಉಪನೇತ್ರ',
'ಉಪನ್ಯಸ್ತ',
'ಉಪನ್ಯಾಸ',
'ಉಪಪತಿ',
'ಉಪಪತ್ತಿ',
'ಉಪಪತ್ನಿ',
'ಉಪಪತ್ರ',
'ಉಪಪದ',
'ಉಪಪನ್ನ',
'ಉಪಪಾತ',
'ಉಪಪಾದ',
'ಉಪಪಾದ್ಯ',
'ಉಪಪ್ರಜ್ಞೆ',
'ಉಪಪ್ರಶ್ನೆ',
'ಉಪಪ್ಲವ',
'ಉಪಬಂಧ',
'ಉಪಬರ್ಹ',
'ಉಪಬಿಯ',
'ಉಪಭಕ್ತಿ',
'ಉಪಭಾಗ',
'ಉಪಭಾಷೆ',
'ಉಪಭುಕ್ತ',
'ಉಪಭೃತ್ತು',
'ಉಪಭೋಕ್ತೃ',
'ಉಪಭೋಗ',
'ಉಪಭೋಗಿ',
'ಉಪಭೋಗ್ಯ',
'ಉಪಮಂತ್ರಿ',
'ಉಪಮರ್ದ',
'ಉಪಮಾಗು',
'ಉಪಮಾತೃ',
'ಉಪಮಾತೆ',
'ಉಪಮಾನ',
'ಉಪಮಿತ',
'ಉಪಮಿಸು',
'ಉಪಮೇಯ',
'ಉಪಯಮ',
'ಉಪಯಾಮ',
'ಉಪಯುಕ್ತ',
'ಉಪಯೋಗ',
'ಉಪಯೋಗ್ಯ',
'ಉಪರಕ್ತ',
'ಉಪರಣೆ',
'ಉಪರತ',
'ಉಪರತಿ',
'ಉಪರತ್ನ',
'ಉಪರಸ್ತೆ',
'ಉಪರಾಂತಿ',
'ಉಪರಾಗ',
'ಉಪರಾಟಿ',
'ಉಪರಿಮ',
'ಉಪರುಚಿ',
'ಉಪರೋಧ',
'ಉಪಲಬ್ಧ',
'ಉಪಲಬ್ಧಿ',
'ಉಪಲಭ್ಯ',
'ಉಪಲಾಭ',
'ಉಪಲಿಂಗ',
'ಉಪಲಿಕ',
'ಉಪಲಿಗ',
'ಉಪಲಿಪ್ತ',
'ಉಪಲೇಟ',
'ಉಪಲೋಹ',
'ಉಪವಂಶ',
'ಉಪವನ',
'ಉಪವರ',
'ಉಪವರ್ಗ',
'ಉಪವಸ್ತ',
'ಉಪವಸ್ತು',
'ಉಪವಾಸ',
'ಉಪವಾಸಿ',
'ಉಪವಾಹ್ಯ',
'ಉಪವಿದ್ಧ',
'ಉಪವಿಧಿ',
'ಉಪವಿಷೆ',
'ಉಪವಿಷ್ಟ',
'ಉಪವೀತ',
'ಉಪವೃತ್ತಿ',
'ಉಪವೇದ',
'ಉಪವೇಶ',
'ಉಪಶಮ',
'ಉಪಶಮೆ',
'ಉಪಶಯ',
'ಉಪಶಲ್ಯ',
'ಉಪಶಾಂತ',
'ಉಪಶಾಂತಿ',
'ಉಪಶಾಂತೆ',
'ಉಪಶಾಖೆ',
'ಉಪಶಾಯ',
'ಉಪಶಿಷ್ಟ',
'ಉಪಶ್ರುತ',
'ಉಪಶ್ರುತಿ',
'ಉಪಶ್ಲೋಕ',
'ಉಪಷ್ಟಂಭ',
'ಉಪಸನ್ನ',
'ಉಪಸಮ',
'ಉಪಸರ',
'ಉಪಸರ್ಗ',
'ಉಪಸರ್ಪ',
'ಉಪಸರ್ಯೆ',
'ಉಪಸಾಕ್ಷಿ',
'ಉಪಸೃಷ್ಟಿ',
'ಉಪಸೇವೆ',
'ಉಪಸ್ಕರ',
'ಉಪಸ್ತರ',
'ಉಪಸ್ಥಿತ',
'ಉಪಸ್ಥಿತಿ',
'ಉಪಸ್ಪರ್ಶ',
'ಉಪಸ್ಮೃತಿ',
'ಉಪಸ್ವರ',
'ಉಪಸ್ವರ್ಗ',
'ಉಪಹತ',
'ಉಪಹತಿ',
'ಉಪಹಾರ',
'ಉಪಹಾಸ',
'ಉಪಹಾಸಿ',
'ಉಪಹಾಸ್ಯ',
'ಉಪಹಿತ',
'ಉಪಹೃತ',
'ಉಪಹ್ವರ',
'ಉಪಾಕರ್ಮ',
'ಉಪಾಕೃತ',
'ಉಪಾಖ್ಯಾನ',
'ಉಪಾಗಮ',
'ಉಪಾತ್ಯಯ',
'ಉಪಾದಾನ',
'ಉಪಾದಿಕೆ',
'ಉಪಾದೆಯ',
'ಉಪಾದೇಯ',
'ಉಪಾದೇಶ',
'ಉಪಾಧಿಕ',
'ಉಪಾಧ್ಯಕ್ಷ',
'ಉಪಾಧ್ಯಕ್ಷೆ',
'ಉಪಾಧ್ಯಾಯ',
'ಉಪಾಧ್ಯಾಯಿ',
'ಉಪಾಧ್ಯಾಯೆ',
'ಉಪಾನತಿ',
'ಉಪಾನಹ',
'ಉಪಾಯನ',
'ಉಪಾರೂಢ',
'ಉಪಾರ್ಚನ',
'ಉಪಾರ್ಚಿಸು',
'ಉಪಾರ್ಜನ',
'ಉಪಾರ್ಜನೆ',
'ಉಪಾರ್ಜಿತ',
'ಉಪಾರ್ಜಿಸು',
'ಉಪಾಲಂಭ',
'ಉಪಾವಸ್ತೆ',
'ಉಪಾವಸ್ಥೆ',
'ಉಪಾವಾಸ',
'ಉಪಾವೃತ್ತ',
'ಉಪಾಶ್ರಯ',
'ಉಪಾಶ್ರಿತ',
'ಉಪಾಸಂಗ',
'ಉಪಾಸಕ',
'ಉಪಾಸನ',
'ಉಪಾಸನೆ',
'ಉಪಾಸಿಕೆ',
'ಉಪಾಸಿತ',
'ಉಪಾಸಿಸು',
'ಉಪಾಸ್ತಿಗೈ',
'ಉಪಾಹಾರ',
'ಉಪಾಹಿತ',
'ಉಪಾಹೃತ',
'ಉಪೇಕ್ಷಿತ',
'ಉಪೇಕ್ಷಿಸು',
'ಉಪೋತ್ಪನ್ನ',
'ಉಪೋದಕಿ',
'ಉಪೋದ್ಘಾತ',
'ಉಪೋಷಣ',
'ಉಪೋಷಿತ',
'ಉಪೋಷಿತೆ',
'ಉಪ್ತಕೃಷ್ಟ',
'ಉಪ್ಪಗಚಿ',
'ಉಪ್ಪಗರಿ',
'ಉಪ್ಪಯಣ',
'ಉಪ್ಪರಟಿ',
'ಉಪ್ಪರಣೆ',
'ಉಪ್ಪರಾಂತಿ',
'ಉಪ್ಪರಿಕೆ',
'ಉಪ್ಪರಿಗೆ',
'ಉಪ್ಪರಿಸು',
'ಉಪ್ಪಲಿಕ',
'ಉಪ್ಪಲಿಗ',
'ಉಪ್ಪಲಿಗೆ',
'ಉಪ್ಪಲೀಕ',
'ಉಪ್ಪವಡ',
'ಉಪ್ಪವರೆ',
'ಉಪ್ಪಳಿಗೆ',
'ಉಪ್ಪಳಿಸು',
'ಉಪ್ಪಾರತಿ',
'ಉಪ್ಪಾರಿಕೆ',
'ಉಪ್ಪಾರಿತ',
'ಉಪ್ಪಿಲಕ',
'ಉಪ್ಪುಕಂಡ',
'ಉಪ್ಪುಕಡ್ಡಿ',
'ಉಪ್ಪುಕಾರ',
'ಉಪ್ಪುಗಡ್ಡಿ',
'ಉಪ್ಪುಗಾಯ್',
'ಉಪ್ಪುಗಾಯಿ',
'ಉಪ್ಪುಗಾವು',
'ಉಪ್ಪುಗಾಳು',
'ಉಪ್ಪುಗೂಚಿ',
'ಉಪ್ಪುನೀರ್',
'ಉಪ್ಪುನೀರು',
'ಉಪ್ಪುನೆಲ',
'ಉಪ್ಪುಮಡಿ',
'ಉಪ್ಪುಮಣ್ಣು',
'ಉಪ್ಪುಮೀನು',
'ಉಪ್ಪುರೇವು',
'ಉಪ್ಪುವಡ್ಡ',
'ಉಪ್ಪುಹುಲ್ಲು',
'ಉಪ್ಪೆಗಚಿ',
'ಉಪ್ಪೆಸರು',
'ಉಬ್ಬಡಿಗ',
'ಉಬ್ಬಣಿಸು',
'ಉಬ್ಬದಿಗ',
'ಉಬ್ಬರಣೆ',
'ಉಬ್ಬರಿಕೆ',
'ಉಬ್ಬರಿಗ',
'ಉಬ್ಬರಿಸು',
'ಉಬ್ಬಸರೆ',
'ಉಬ್ಬಳಿಕೆ',
'ಉಬ್ಬಳಿಸು',
'ಉಬ್ಬಾಳಿಕೆ',
'ಉಬ್ಬಾಳಿಗ',
'ಉಬ್ಬಾಳಿಸು',
'ಉಬ್ಬಿಕ್ಕಿಸು',
'ಉಬ್ಬಿಱಿತ',
'ಉಬ್ಬುಕೊಬ್ಬು',
'ಉಬ್ಬುಗಣ್ಣು',
'ಉಬ್ಬುಗಲ್ಲ',
'ಉಬ್ಬುಗುಂದು',
'ಉಬ್ಬುಗೆಡು',
'ಉಬ್ಬುಗೆನ್ನೆ',
'ಉಬ್ಬುಗೊಬ್ಬು',
'ಉಬ್ಬುಚಿತ್ರ',
'ಉಬ್ಬುಡುಗು',
'ಉಬ್ಬುಮೀನು',
'ಉಬ್ಬುರೊಟ್ಟಿ',
'ಉಬ್ಬುವಾತು',
'ಉಬ್ಬುಹಣೆ',
'ಉಬ್ಬುಹಲ್ಲ',
'ಉಬ್ಬುಹಲ್ಲಿ',
'ಉಬ್ಬುಹಲ್ಲು',
'ಉಬ್ಬುಹೊಳೆ',
'ಉಬ್ಬೆಕುಳ್ಳ',
'ಉಬ್ಬೆಮನೆ',
'ಉಭಯತ್ರ',
'ಉಭಯತ್ವ',
'ಉಭಯಾಯಿ',
'ಉಭಯೋಷ್ಠ್ಯ',
'ಉಮರಾವ',
'ಉಮರಾವು',
'ಉಮಾಧವ',
'ಉಮಾವರ',
'ಉಮುವಿಧಿ',
'ಉಮ್ಮಗುರು',
'ಉಮ್ಮಚ್ಚರ',
'ಉಮ್ಮಡಿಕೆ',
'ಉಮ್ಮತ್ತಾಟ್',
'ಉಮ್ಮಳಿಕೆ',
'ಉಮ್ಮಳಿಗೆ',
'ಉಮ್ಮಳಿಸು',
'ಉಮ್ಮಿಗುಡ್ಡೆ',
'ಉಮ್ಮುಗಿಸು',
'ಉಮ್ಮುಗೊಯ್',
'ಉಮ್ಮುಳಿಗೆ',
'ಉಯಿಕುಟ್ಟು',
'ಉಯಿಗುಟ್ಟು',
'ಉಯ್ಯಲಾಟ',
'ಉಯ್ಯಲಾಡು',
'ಉಯ್ಯಲಿಡು',
'ಉಯ್ಯಲೇಱು',
'ಉಯ್ಯಾಪರೆ',
'ಉಯ್ಯಾಳಿಗ',
'ಉರಃಸೆಲ್ಲ',
'ಉರಗಿಣಿ',
'ಉರಗೆಜ್ಜೆ',
'ಉರಗೇಂದ್ರ',
'ಉರಚಲ್ಲಿ',
'ಉರಟಣೆ',
'ಉರಟಾಣೆ',
'ಉರಣಾಕ್ಷ',
'ಉರಪಾಶ',
'ಉರಮಟ್ಟ',
'ಉರಮಾಲೆ',
'ಉರವಣಿ',
'ಉರವಣೆ',
'ಉರವಣ್ಣಿ',
'ಉರವರಿ',
'ಉರವಲ',
'ಉರಶ್ಛದ',
'ಉರಸಿಜ',
'ಉರಸಿಲ',
'ಉರಸ್ತ್ರಾಣ',
'ಉರಸ್ಸ್ಥಲ',
'ಉರಾವುರೇ',
'ಉರಿಕಜ್ಜಿ',
'ಉರಿಕಾಸು',
'ಉರಿಕಿಡಿ',
'ಉರಿಕೆಂಡ',
'ಉರಿಗಂಟು',
'ಉರಿಗಣ್',
'ಉರಿಗಣೆ',
'ಉರಿಗಣ್ಣ',
'ಉರಿಗಣ್ಣು',
'ಉರಿಗಯ್ಯ',
'ಉರಿಗಾಳಿ',
'ಉರಿಗಿಚ್ಚು',
'ಉರಿಗಿಡಿ',
'ಉರಿಗೆಂಡ',
'ಉರಿಗೆಯ್',
'ಉರಿಗೆಯ್ಯ',
'ಉರಿಗೇಸ',
'ಉರಿಗೊಂಡ',
'ಉರಿಗೊಳ್',
'ಉರಿಗೊಳು',
'ಉರಿಗೊಳ್ಳಿ',
'ಉರಿಗೋಲ್',
'ಉರಿಚಳಿ',
'ಉರಿಚೇಳು',
'ಉರಿಜ್ವರ',
'ಉರಿನಂಜು',
'ಉರಿನೀರ್',
'ಉರಿನೀರು',
'ಉರಿಪಡು',
'ಉರಿಬತ್ತಿ',
'ಉರಿಬಳ್ಳಿ',
'ಉರಿಬಾಣ',
'ಉರಿಬೆಂಕಿ',
'ಉರಿಮನೆ',
'ಉರಿಮಾರಿ',
'ಉರಿಮುಖ',
'ಉರಿಮೂತ್ರ',
'ಉರಿಮೇಷ',
'ಉರಿಮೊಗ',
'ಉರಿಯಂಬು',
'ಉರಿಯುಚ್ಚೆ',
'ಉರಿಯೂತ',
'ಉರಿಯೆಣ್ಣೆ',
'ಉರಿಯೇಳು',
'ಉರಿಯೊಗು',
'ಉರಿಲಿಂಗ',
'ಉರಿವತ್ತಿ',
'ಉರಿವನೆ',
'ಉರಿವರಿ',
'ಉರಿವಾತು',
'ಉರಿವುರಿ',
'ಉರಿವೊಗು',
'ಉರಿಶಿಂಗಿ',
'ಉರಿಶೀತ',
'ಉರಿಸಿಂಗ',
'ಉರಿಸಿಂಗಿ',
'ಉರಿಸೀಗೆ',
'ಉರುಂಡಿಸು',
'ಉರುಗಜ್ಜ',
'ಉರುಗಣ್ಣು',
'ಉರುಗಲಿ',
'ಉರುಗುರ',
'ಉರುಟಣೆ',
'ಉರುಟಾಡು',
'ಉರುಟಾಟ',
'ಉರುಟಾಣಿ',
'ಉರುಟಿಸು',
'ಉರುಡಾಟ',
'ಉರುಡಾಡು',
'ಉರುಡಿಸು',
'ಉರುತರ',
'ಉರುತಳ್ಪ',
'ಉರುಪಾಪ',
'ಉರುಪಾರ',
'ಉರುಬಿಸು',
'ಉರುಬೂಕ',
'ಉರುಬೇಗ',
'ಉರುಮತಿ',
'ಉರುಮಾರಿ',
'ಉರುಲೊಡ್ಡು',
'ಉರುಲ್ಗೊಲೆ',
'ಉರುವಣಿ',
'ಉರುವಣೆ',
'ಉರುವಲ',
'ಉರುವಲು',
'ಉರುವೇಲು',
'ಉರುಸತ್ವ',
'ಉರುಸರ',
'ಉರುಸೊಪ್ಪು',
'ಉರುಳಕ್ಕಿ',
'ಉರುಳಣೆ',
'ಉರುಳಾಣಿ',
'ಉರುಳಿಕೆ',
'ಉರುಳಿಕ್ಕು',
'ಉರುಳಿಚು',
'ಉರುಳಿಡು',
'ಉರುಳಿಸು',
'ಉರುಳುಕ',
'ಉರುಳೊಡ್ಡು',
'ಉರುಳ್ಗಣೆ',
'ಉರುಳ್ಗಣ್ಣಿ',
'ಉರುಳ್ಗೊನೆ',
'ಉರುಳ್ಚಿಸು',
'ಉರುಳ್ವಲೆ',
'ಉರೋರುಹ',
'ಉರ್ಕಳಿಸು',
'ಉರ್ಕುಂದೊಱೆ',
'ಉರ್ಕುಗೆಡು',
'ಉರ್ಕುಡಿಸು',
'ಉರ್ಕುಡುಗು',
'ಉರ್ಕುದೊಱೆ',
'ಉರ್ಕುಮುರಿ',
'ಉರ್ಗವುಂಡು',
'ಉರ್ಗುಗೊಳ್',
'ಉರ್ಚಾಟಿಸು',
'ಉರ್ಚೆಪಡು',
'ಉರ್ಜುಗಿಸು',
'ಉರ್ಪುಗಾಯ್',
'ಉರ್ಬೀಪಾಳ',
'ಉರ್ಬುಗುಂದು',
'ಉರ್ಬುಗೆಡು',
'ಉರ್ಬೇೞಿಸು',
'ಉರ್ಲಜಾಡ್ಯ',
'ಉರ್ವಾರುಕ',
'ಉರ್ವಿತಳ',
'ಉರ್ವಿಧರ',
'ಉರ್ವೀಚಕ್ರ',
'ಉರ್ವೀಚರ',
'ಉರ್ವೀಧರ',
'ಉರ್ವೀಭರ',
'ಉರ್ವೀರುಹ',
'ಉರ್ವೀಹರ್ಷ',
'ಉರ್ವುಗೊಳ್',
'ಉಲಕೋಚಿ',
'ಉಲಿಗುಡು',
'ಉಲಿಮಿರಿ',
'ಉಲಿವಕ್ಕಿ',
'ಉಲುಕಿಸು',
'ಉಲುಕೋಚಿ',
'ಉಲುಗಿತಿ',
'ಉಲೂಖಲ',
'ಉಲ್ಕಾಕಲ್ಪ',
'ಉಲ್ಕಾಪಾತ',
'ಉಲ್ಕಾಪಿಂಡ',
'ಉಲ್ಕಾಮುಖ',
'ಉಲ್ಕಾವಳಿ',
'ಉಲ್ಕಾವೃಷ್ಟಿ',
'ಉಲ್ಕಾಶಿಲೆ',
'ಉಲ್ಟಾಪಲ್ಟಿ',
'ಉಲ್ಬಣಿಸು',
'ಉಲ್ಲಂಘನ',
'ಉಲ್ಲಂಘನೆ',
'ಉಲ್ಲಂಘಿಸು',
'ಉಲ್ಲಟಿಸು',
'ಉಲ್ಲಸನ',
'ಉಲ್ಲಸಿತ',
'ಉಲ್ಲಸಿತೆ',
'ಉಲ್ಲಸಿಸು',
'ಉಲ್ಲಾಸಿನಿ',
'ಉಲ್ಲಾಸಿಸು',
'ಉಲ್ಲಿಖಿತ',
'ಉಲ್ಲಿಯಣ',
'ಉಲ್ಲುಳಿತ',
'ಉಲ್ಲೇಖನ',
'ಉಲ್ಲೇಖಿತ',
'ಉಲ್ಲೇಖಿಸು',
'ಉಲ್ಲೋಲಿಸು',
'ಉಷಃಕಾಲ',
'ಉಷಃಪಾನ',
'ಉಷಃಸ್ಮಿತ',
'ಉಷರ್ಬುಧ',
'ಉಷಾಗಾನ',
'ಉಷಾಸೂಕ್ತ',
'ಉಷ್ಟ್ರಕಂಟ',
'ಉಷ್ಟ್ರತುಂಡ',
'ಉಷ್ಟ್ರಪಕ್ಷಿ',
'ಉಷ್ಣಕ್ರಿಯೆ',
'ಉಷ್ಣಗಣ್ಣು',
'ಉಷ್ಣಜನ್ಯ',
'ಉಷ್ಣಜಲ',
'ಉಷ್ಣಜ್ವರ',
'ಉಷ್ಣದೀಪ್ತಿ',
'ಉಷ್ಣಮಾನ',
'ಉಷ್ಣಮಾಪಿ',
'ಉಷ್ಣಯಂತ್ರ',
'ಉಷ್ಣಯುಗ್ಮ',
'ಉಷ್ಣರಶ್ಮಿ',
'ಉಷ್ಣರೋಗ',
'ಉಷ್ಣರೋಚಿ',
'ಉಷ್ಣವಾಯು',
'ಉಷ್ಣಾಭೇದ್ಯ',
'ಉಷ್ಣೋದಕ',
'ಉಸಾಬರಿ',
'ಉಸಿಕನೆ',
'ಉಸಿರಾಟ',
'ಉಸಿರಾಡು',
'ಉಸಿರಿಕ್ಕು',
'ಉಸಿರಿಡು',
'ಉಸಿರೆಳೆ',
'ಉಸಿರ್ಗರೆ',
'ಉಸಿರ್ಗಿಚ್ಚು',
'ಉಸಿರ್ದಾಣ',
'ಉಸಿರ್ದೆಗೆ',
'ಉಸಿರ್ನೆಲೆ',
'ಉಸಿರ್ವೆಣ್',
'ಉಸಿರ್ವೊಯ್',
'ಉಸುಮುನಿ',
'ಉಸ್ತವಾರಿ',
'ಉಸ್ತಿಕಾಯಿ',
'ಉಸ್ತುವಾರಿ',
'ಉಳಗುನ್ನಿ',
'ಉಳಿಕಾರ',
'ಉಳಿಗಡೆ',
'ಉಳಿಗಾಲ',
'ಉಳಿಗಾಳು',
'ಉಳಿಚೆಂಡು',
'ಉಳಿತಾಯ',
'ಉಳಿಬಳೆ',
'ಉಳಿಮಿಂಡ',
'ಉಳಿಯಂಬು',
'ಉಳಿಯಾಳು',
'ಉಳಿಯಿಡು',
'ಉಳಿಸೆಂಡು',
'ಉಳುಕಲು',
'ಉಳುಪಡಿ',
'ಉಳುಬಾಳು',
'ಉಳುವರಿ',
'ಉಳುವಲು',
'ಉಳ್ಳಂಜಿಸು',
'ಉಳ್ಳರಲ್',
'ಉಳ್ಳರಿತ',
'ಉಳ್ಳಲರ್',
'ಉಳ್ಳಲರ್ಚು',
'ಉಳ್ಳಳುಕು',
'ಉಳ್ಳಾಗೆಡ್ಡೆ',
'ಉಳ್ಳಿಗಡ್ಡ',
'ಉಳ್ಳಿಗೆಡ್ಡೆ',
'ಉಳ್ಳಿಮಾವು',
'ಉಳ್ಳಿಶಿಂಡ',
'ಉಳ್ಳುಗುರ್',
'ಉಱುಗಿಸು',
'ಉಱುಮಾಱು',
'ಉಱುಮಿಕೆ',
'ಉಱುವಾಱು',
'ಉಱುವಾೞ್',
'ಉಱುವಾೞು',
'ಉಱುವೆಸ',
'ಉಱುಸೆಱೆ',
'ಉಱುಹಿಸು',
'ಉಱೆವೊಯ್ಲು',
'ಉೞಿಕಲ್',
'ಉೞಿಕೞಿ',
'ಉೞುಗಿಸು',
'ಊಕಾರಾಂತ',
'ಊಗುರಿಸು',
'ಊಚನೀಚ',
'ಊಚಬೆಳ್ಳಿ',
'ಊಚಿಹುಲ್ಲು',
'ಊಜಿನೊಣ',
'ಊಟಗಾರ',
'ಊಟಗಾರ್ತಿ',
'ಊಟಬೈಸು',
'ಊಟವಿಡು',
'ಊಟೆವರಿ',
'ಊಡುಗೊಳ್',
'ಊತಕೋಲು',
'ಊತಗೋಲು',
'ಊದಕಡ್ಡಿ',
'ಊದಬತ್ತಿ',
'ಊದಿಮರ',
'ಊದುಕಡ್ಡಿ',
'ಊದುಗಂಡಿ',
'ಊದುಗಡ್ಡಿ',
'ಊದುಗಲ್ಲ',
'ಊದುಗಾಲು',
'ಊದುಗಿಚ್ಚು',
'ಊದುದಾನಿ',
'ಊದುದಾನು',
'ಊದುಬಟ್ಟಿ',
'ಊದುಬತ್ತಿ',
'ಊದುಹೊಟ್ಟೆ',
'ಊನಗಣ',
'ಊನಮನ',
'ಊನಮಾನ',
'ಊನಾಬ್ಧಿಕ',
'ಊಪೋದಿಕೆ',
'ಊಬುಹುಲ್ಲು',
'ಊಮಾನಿಸ',
'ಊರಗಲ',
'ಊರಗಸೆ',
'ಊರಗಾಯಿ',
'ಊರಗುಣಿ',
'ಊರಗುಸೆ',
'ಊರಗೂಧ',
'ಊರಡಿಕೆ',
'ಊರಡೆಯ',
'ಊರಬತ್ತಿ',
'ಊರವಂಕ',
'ಊರಱಿಕೆ',
'ಊರೞಿವು',
'ಊರಾಳಿಸು',
'ಊರೀಕೃತ',
'ಊರುಂಬಳಿ',
'ಊರುಕಟ್ಟೆ',
'ಊರುಕೇರಿ',
'ಊರುಕೋಲು',
'ಊರುಗಂಬ',
'ಊರುಗಲಿ',
'ಊರುಗಾಯಿ',
'ಊರುಗಾಲಿ',
'ಊರುಗುಬ್ಬಿ',
'ಊರುಗೋಡೆ',
'ಊರುಗೋಲು',
'ಊರುದಂಡ',
'ಊರುದಘ್ನ',
'ಊರುದೊರೆ',
'ಊರುಧನಿ',
'ಊರುನರಿ',
'ಊರುಪರ್ವ',
'ಊರುಬೇರು',
'ಊರುಮಗ',
'ಊರುಮರ್ಜಿ',
'ಊರುಯುಗ',
'ಊರುಯುಗ್ಮ',
'ಊರುವಂದಿ',
'ಊರುಸಿಂಗ',
'ಊರುಹಾಕು',
'ಊರೆಕಟ್ಟು',
'ಊರೆಕೊಡು',
'ಊರೆಗೊಡು',
'ಊರೆಗೋಲು',
'ಊರೊಕ್ಕಲು',
'ಊರೊಡೆಯ',
'ಊರ್ಕೆಡುಕ',
'ಊರ್ಗಾಮುಂಡು',
'ಊರ್ಗಾವುಂಡು',
'ಊರ್ಜಸ್ವಲ',
'ಊರ್ಜಸ್ವಿತೆ',
'ಊರ್ಜಿತತ್ವ',
'ಊರ್ಜಿತೋಕ್ತಿ',
'ಊರ್ಡಂಗುರ',
'ಊರ್ಣಕೇಶ',
'ಊರ್ಣನಾಭ',
'ಊರ್ಣನಾಭಿ',
'ಊರ್ಣವಸ್ತ್ರ',
'ಊರ್ಧ್ವಂಗಮ',
'ಊರ್ಧ್ವಕ್ರಿಯೆ',
'ಊರ್ಧ್ವಗತ',
'ಊರ್ಧ್ವಗತಿ',
'ಊರ್ಧ್ವಗಮ',
'ಊರ್ಧ್ವಗಾಂಶು',
'ಊರ್ಧ್ವಗಾಮಿ',
'ಊರ್ಧ್ವಜಾನು',
'ಊರ್ಧ್ವಪಾತ',
'ಊರ್ಧ್ವಪಿಂಡ',
'ಊರ್ಧ್ವಪುಂಡ್ರ',
'ಊರ್ಧ್ವಮುಖ',
'ಊರ್ಧ್ವರೇತ',
'ಊರ್ಧ್ವರೋಮ',
'ಊರ್ಧ್ವಲೋಕ',
'ಊರ್ಧ್ವವರ್ಧಿ',
'ಊರ್ಧ್ವಶ್ವಾಸ',
'ಊರ್ಮಿಮಾಲೆ',
'ಊರ್ವಾನಳ',
'ಊರ್ವೀಧರ',
'ಊಷರಾಂಬು',
'ಊಷ್ಮತೃಷ್ಣೆ',
'ಊಷ್ಮಧ್ವನಿ',
'ಊಷ್ಮೋಚ್ಚಾರ',
'ಊಹನೀಯ',
'ಊಹಾತೀತ',
'ಊಹಾಪೋಹ',
'ಊಹಾಪೋಹೆ',
'ಊಹಾಬಲ',
'ಊಹಿಸುಹ',
'ಊಹೆಕಡ್ಡಿ',
'ಊಹೆಗೆಡು',
'ಊಹೆಮಿಗು',
'ಊಳಿಗಗೈ',
'ಊಳಿಗಾಳಿ',
'ಊಱಿತೋಱು',
'ಊಱಿಸಾಱು',
'ಊಱುಂಗೋಲು',
'ಊಱುಗೋಲು',
'ಋಕಾರಾಂತ',
'ಋಕ್ಛಂದಸ್ಸು',
'ಋಕ್ಷಗಂಧೆ',
'ಋಕ್ಷಪತಿ',
'ಋಕ್ಷಭಲ್ಲ',
'ಋಕ್ಷಾಕೀರ್ಣ',
'ಋಙ್ನಿಕಾಯ',
'ಋಜುದೃಷ್ಟಿ',
'ಋಜುಮತಿ',
'ಋಜುಮಾರ್ಗ',
'ಋಜುರೇಖೆ',
'ಋಜುಲಂಬಿ',
'ಋಜುವೃತ್ತಿ',
'ಋಜ್ವಾಗತ',
'ಋಣಂಗುಡು',
'ಋಣಗಾರ',
'ಋಣಗೂಳು',
'ಋಣಚಿಹ್ನೆ',
'ಋಣತ್ರಯ',
'ಋಣಧ್ರುವ',
'ಋಣಪತ್ರ',
'ಋಣಬಾಧೆ',
'ಋಣಭಯ',
'ಋಣಭಾರ',
'ಋಣಮುಕ್ತ',
'ಋಣಮುಕ್ತಿ',
'ಋಣಮುಕ್ತೆ',
'ಋಣಸಂಖ್ಯೆ',
'ಋಣಹರಿ',
'ಋಣಾತ್ಮಕ',
'ಋಣಾದಾನ',
'ಋತಂಭರ',
'ಋತಂಭರೆ',
'ಋತಚಿತ್ತು',
'ಋತಜುಷ್ಟ',
'ಋತದರ್ಶಿ',
'ಋತಮಾರ್ಗ',
'ಋತವಾಕ್ಯ',
'ಋತಶಕ್ತಿ',
'ಋತುಕಾಲ',
'ಋತುಗೊಳ್',
'ಋತುಚಕ್ರ',
'ಋತುದಾನ',
'ಋತುಧರ್ಮ',
'ಋತುಭೇದ',
'ಋತುಮತಿ',
'ಋತುವಡೆ',
'ಋತುವಾಗು',
'ಋತುವೃತ್ತಿ',
'ಋತುಶಾಂತಿ',
'ಋತುಸ್ನಾತೆ',
'ಋತುಸ್ನಾನ',
'ಋತುಸ್ರಾವ',
'ಋದ್ಧಸತ್ವ',
'ಋದ್ಧಿಪ್ರಾಪ್ತ',
'ಋದ್ಧಿಪ್ರಾಪ್ತಿ',
'ಋದ್ಧಿಮಂತ',
'ಋಶ್ಯಕೇತು',
'ಋಶ್ಯಶೃಂಗ',
'ಋಷಭಕ',
'ಋಷಿಕಲ್ಪ',
'ಋಷಿಧುರ್ಯ',
'ಋಷಿಪುತ್ರಿ',
'ಋಷಿಪ್ರಿಯ',
'ಋಷಿವಧು',
'ಋಷಿವಾಣಿ',
'ಋಷಿವಾಸ',
'ಋಷಿಶಕ್ತಿ',
'ಋಷ್ಯಕೇತು',
'ಋಷ್ಯಪ್ರೋಕ್ತೆ',
'ಋಷ್ಯಶೃಂಗ',
'ಎಂಗಿಬೀಳು',
'ಎಂಗಿನಾಣ್ಯ',
'ಎಂಜಲಿಗ',
'ಎಂಜಲಿಸು',
'ಎಂಜಲೆಲೆ',
'ಎಂಜಲ್ಗೂೞ್',
'ಎಂಜಲ್ವಾತು',
'ಎಂಟಡಿಗ',
'ಎಂಟುಗೆಯ್',
'ಎಂಟುಹಿಟ್ಟು',
'ಎಂಟೆರಡು',
'ಎಂಟೊಡಲ',
'ಎಂಬುಕೆಯ್',
'ಎಂಬುವಿಕೆ',
'ಎಕತ್ಯಾರು',
'ಎಕನಾತಿ',
'ಎಕರಾರು',
'ಎಕಾರಾಂತ',
'ಎಕ್ಕಚರ',
'ಎಕ್ಕಛಾಯ',
'ಎಕ್ಕಟಾಗೆ',
'ಎಕ್ಕಟಿಗ',
'ಎಕ್ಕಟಿಗೈ',
'ಎಕ್ಕಡಿಗ',
'ಎಕ್ಕತಕ್ಕು',
'ಎಕ್ಕತಳ',
'ಎಕ್ಕತಾಣ',
'ಎಕ್ಕತಾಳ',
'ಎಕ್ಕತಾಳಿ',
'ಎಕ್ಕತುಳ',
'ಎಕ್ಕತುಳಂ',
'ಎಕ್ಕತೂಳ',
'ಎಕ್ಕದಾಣ',
'ಎಕ್ಕದಾಳೆ',
'ಎಕ್ಕನಾತಿ',
'ಎಕ್ಕನಾದಿ',
'ಎಕ್ಕಬಾಗೆ',
'ಎಕ್ಕರಿಸು',
'ಎಕ್ಕವಡ',
'ಎಕ್ಕವತ್ತ',
'ಎಕ್ಕವತ್ತಿ',
'ಎಕ್ಕವಳಿ',
'ಎಕ್ಕವಿಂಡು',
'ಎಕ್ಕಸಕ್ಕ',
'ಎಕ್ಕಸಕ್ಕೆ',
'ಎಕ್ಕಸರ',
'ಎಕ್ಕಸರ5',
'ಎಕ್ಕಸಿಕ್ಕ',
'ಎಕ್ಕಸಿರ್ಕ',
'ಎಕ್ಕಸೆಕ್ಕ',
'ಎಕ್ಕಸೊಕ್ಕಿ',
'ಎಕ್ಕಸೊಕ್ಕು',
'ಎಕ್ಕಹಾಲೆ',
'ಎಕ್ಕಳಿಸು',
'ಎಕ್ಕಳುಸು',
'ಎಕ್ಕಾಗಾಡಿ',
'ಎಕ್ಕಾಗಾಣ',
'ಎಕ್ಕಾವಳಿ',
'ಎಕ್ಕುಜಾರು',
'ಎಕ್ಕೆಗುಟ್ಟು',
'ಎಕ್ಕೆಮಾಲೆ',
'ಎಕ್ಕೆಯುಲಿ',
'ಎಕ್ಕೆವಾಯ್',
'ಎಕ್ಕೆವಾಱು',
'ಎಗಚಿಗ',
'ಎಗತಾಳಿ',
'ಎಗರಿಕೆ',
'ಎಗರಿಸು',
'ಎಗುಮತಿ',
'ಎಗ್ಗಡಿಗ',
'ಎಗ್ಗತನ',
'ಎಗ್ಗಾಮುಗ್ಗಾ',
'ಎಚ್ಚರಿಕೆ',
'ಎಚ್ಚರಿರು',
'ಎಚ್ಚರಿಸು',
'ಎಚ್ಚವಿಕ್ಕು',
'ಎಚ್ಚಱಿಕೆ',
'ಎಚ್ಚಱಿಸು',
'ಎಚ್ಚಿಕೊಳ್',
'ಎಚ್ಚುಪಾಯ್',
'ಎಜ್ಜವಿಕ್ಕು',
'ಎಟಕಿಸು',
'ಎಟ್ಟಿತನ',
'ಎಟ್ಟಿನುಡಿ',
'ಎಟ್ಟಿಮರ',
'ಎಡಂಗುಡು',
'ಎಡಂದೊಡು',
'ಎಡಂಪಿಸು',
'ಎಡಂಬಡು',
'ಎಡಂಬಡೆ',
'ಎಡಂಮಾಡು',
'ಎಡಗಡೆ',
'ಎಡಗಣ್',
'ಎಡಗಣ್ಣು',
'ಎಡಗಯ್',
'ಎಡಗಯ್ಯ',
'ಎಡಗಱೆ',
'ಎಡಗಾಲ್',
'ಎಡಗಾಲ',
'ಎಡಗಾಲು',
'ಎಡಗೆಯ್',
'ಎಡಗೆಲ',
'ಎಡಗೂಸು',
'ಎಡಗೋಲು',
'ಎಡಚಾರಿ',
'ಎಡಚಿಲ್',
'ಎಡಚೋರಿ',
'ಎಡತರಿ',
'ಎಡತಾಕು',
'ಎಡತಿಗೆ',
'ಎಡದುಂಬಿ',
'ಎಡದೆಸೆ',
'ಎಡದೊಡು',
'ಎಡದೊಡೆ',
'ಎಡದೋಳು',
'ಎಡಪಂಥ',
'ಎಡಪಕ್ಷ',
'ಎಡಬಲ',
'ಎಡಮಡ್ಡ',
'ಎಡಮುರಿ',
'ಎಡರ್ಗೇಡಿ',
'ಎಡರ್ಪಡು',
'ಎಡವಂಕ',
'ಎಡವಕ್ಕ',
'ಎಡವಗ',
'ಎಡವಟ್ಟ',
'ಎಡವಟ್ಟು',
'ಎಡವಾೞ್',
'ಎಡವಿಕೆ',
'ಎಡಸಾಗು',
'ಎಡಸುತ್ತು',
'ಎಡಹಂಚ',
'ಎಡಹಿಸು',
'ಎಡಹುಹ',
'ಎಡಹೊತ್ತು',
'ಎಡುಗೋಲು',
'ಎಡೆಕಾಲ',
'ಎಡೆಕಾಸು',
'ಎಡೆಕುಂಟೆ',
'ಎಡೆಕೊಡು',
'ಎಡೆಗಜ್ಜ',
'ಎಡೆಗಟ್ಟು',
'ಎಡೆಗಟ್ಟೆ',
'ಎಡೆಗಡಿ',
'ಎಡೆಗಱೆ',
'ಎಡೆಗಾಣ್',
'ಎಡೆಗಾಲ',
'ಎಡೆಗಾಳ',
'ಎಡೆಗಿಡು',
'ಎಡೆಗಿಱಿ',
'ಎಡೆಗುಡು',
'ಎಡೆಗುತ್ತಿ',
'ಎಡೆಗೆಡು',
'ಎಡೆಗೆಡೆ',
'ಎಡೆಗೆಯ್',
'ಎಡೆಗೊಡು',
'ಎಡೆಗೊಳ್',
'ಎಡೆಗೋರು',
'ಎಡೆಗೋಲ್',
'ಎಡೆಗೋಲು',
'ಎಡೆಜರಿ',
'ಎಡೆತಡೆ',
'ಎಡೆತರ',
'ಎಡೆತಾಕು',
'ಎಡೆತಾಗು',
'ಎಡೆತಿಟ್ಟು',
'ಎಡೆತೊಱೆ',
'ಎಡೆದಾಣ',
'ಎಡೆದೆಱೆ',
'ಎಡೆದೊಱೆ',
'ಎಡೆನಿಚ್ಚ',
'ಎಡೆನುಡಿ',
'ಎಡೆನೆಱೆ',
'ಎಡೆನೋಡು',
'ಎಡೆಪಡು',
'ಎಡೆಪ್ರಾಯ',
'ಎಡೆಬಾವು',
'ಎಡೆಬಿಡು',
'ಎಡೆಮಟ್ಟು',
'ಎಡೆಮಣೆ',
'ಎಡೆಮನೆ',
'ಎಡೆಮಾಡು',
'ಎಡೆಮಾತು',
'ಎಡೆಮಾರು',
'ಎಡೆಮುರಿ',
'ಎಡೆಮುಳ್ಳು',
'ಎಡೆಮುಱಿ',
'ಎಡೆಮೃತ್ಯು',
'ಎಡೆಯಣ',
'ಎಡೆಯಲೆ',
'ಎಡೆಯಱಿ',
'ಎಡೆಯಾಟ',
'ಎಡೆಯಾಡು',
'ಎಡೆಯಾಯ್ಲ',
'ಎಡೆಯಾಱು',
'ಎಡೆಯಿಡು',
'ಎಡೆಯೊಡ್ಡು',
'ಎಡೆಯೊತ್ತು',
'ಎಡೆವಟ್ಟೆ',
'ಎಡೆವಡು',
'ಎಡೆವಡೆ',
'ಎಡೆವನೆ',
'ಎಡೆವರ್',
'ಎಡೆವರ',
'ಎಡೆವರಿ',
'ಎಡೆವರ್ಣ',
'ಎಡೆವಱ',
'ಎಡೆವಱಿ',
'ಎಡೆವಱೆ',
'ಎಡೆವಾತು',
'ಎಡೆವಾಯ್',
'ಎಡೆವಿಡಿ',
'ಎಡೆವಿಡು',
'ಎಡೆವುಗು',
'ಎಡೆವೆಱು',
'ಎಡೆವೇಡು',
'ಎಡೆವೇೞ್',
'ಎಡೆವೊಗು',
'ಎಡೆವೋಗು',
'ಎಡೆಸಂದು',
'ಎಡೆಸಾರ್',
'ಎಡೆಸೆಳೆ',
'ಎಡೆಸೋಲು',
'ಎಡೆಹಾಯ್',
'ಎಡೆಹಿಡಿ',
'ಎಡೆಹೊಗು',
'ಎಡೆಹೊಡೆ',
'ಎಡೆಹೊತ್ತು',
'ಎಡ್ಡಂತಿಡ್ಡ',
'ಎಡ್ಡಂತಿಡ್ಡಂ',
'ಎಡ್ಡಂಬಡೆ',
'ಎಡ್ಡಕಜ್ಜ',
'ಎಡ್ಡತನ',
'ಎಡ್ಡತಾಣ',
'ಎಡ್ಡಮಿಂಡಿ',
'ಎಡ್ಡವಣೆ',
'ಎಡ್ಡಾದಿಡ್ಡಿ',
'ಎಣಗಂಟು',
'ಎಣಗೊಣ',
'ಎಣಗೋಣ',
'ಎಣಮಣಿ',
'ಎಣಿಕೆಗೈ',
'ಎಣಿಕೊಳ್',
'ಎಣಿಸಿಕೆ',
'ಎಣೆಕಟ್ಟು',
'ಎಣೆಗಂಟು',
'ಎಣೆಗಳೆ',
'ಎಣೆಗಾಣು',
'ಎಣೆಗೂಡು',
'ಎಣೆಗೊಣ',
'ಎಣೆಗೊಳ್',
'ಎಣೆಗೋಣ',
'ಎಣೆತೊಣೆ',
'ಎಣೆಬಾಳೆ',
'ಎಣೆಬೆಟ್ಟು',
'ಎಣೆಯಾಡು',
'ಎಣೆಯೊಡ್ಡು',
'ಎಣೆವಕ್ಕಿ',
'ಎಣೆವಡೆ',
'ಎಣೆವರ್',
'ಎಣೆಸಾವು',
'ಎಣ್ಗಂಡುಗ',
'ಎಣ್ಗಾವುದ',
'ಎಣ್ಛಾಸಿರ',
'ಎಣ್ಣೂರಿಗೆ',
'ಎಣ್ಣೆಕಾಳು',
'ಎಣ್ಣೆಗಂಟು',
'ಎಣ್ಣೆಗಪ್ಪು',
'ಎಣ್ಣೆಗಾಯಿ',
'ಎಣ್ಣೆಗಾಸು',
'ಎಣ್ಣೆಗೆಂಪು',
'ಎಣ್ಣೆಗೊಡ',
'ಎಣ್ಣೆತೆಂಗು',
'ಎಣ್ಣೆನೂಲು',
'ಎಣ್ಣೆಪುಂಡಿ',
'ಎಣ್ಣೆಬಟ್ಟೆ',
'ಎಣ್ಣೆಬೀಜ',
'ಎಣ್ಣೆಮಣಿ',
'ಎಣ್ಣೆಮರ',
'ಎಣ್ಣೆಮಾನ್ಯ',
'ಎಣ್ಣೆವಣಿ',
'ಎಣ್ಣೆಶಾಸ್ತ್ರ',
'ಎಣ್ಣೆಹುಲ್ಲು',
'ಎಣ್ಣೋರಿಗೆ',
'ಎಣ್ಪರಿಜು',
'ಎಣ್ಬಗಲ್',
'ಎತಿತನ',
'ಎತ್ತಂಗಡಿ',
'ಎತ್ತಡಿಗೆ',
'ಎತ್ತರಿಸು',
'ಎತ್ತುಂಗೋಲ್',
'ಎತ್ತುಂಗೋಳ್',
'ಎತ್ತುಂಗೋಳ',
'ಎತ್ತುಕಂಬಿ',
'ಎತ್ತುಗಡೆ',
'ಎತ್ತುಗಲ್',
'ಎತ್ತುಗಲ್ಲು',
'ಎತ್ತುಗಾಣ',
'ಎತ್ತುಗುಂಡು',
'ಎತ್ತುಗುಡಿ',
'ಎತ್ತುಗೂಳು',
'ಎತ್ತುಗೆಯ್',
'ಎತ್ತುಗೈದು',
'ಎತ್ತುಗೊಳ್ಳು',
'ಎತ್ತುಗೋಲು',
'ಎತ್ತುನೀರು',
'ಎತ್ತುಪಟ್ಟಿ',
'ಎತ್ತುಮತಿ',
'ಎತ್ತುಯಂತ್ರ',
'ಎತ್ತುವಳಿ',
'ಎತ್ತುಗುದಿ',
'ಎದವೀಳೆ',
'ಎದಿರಿಗೆ',
'ಎದಿರಿಸು',
'ಎದಿರೇಡು',
'ಎದಿರ್ಗೊಳ್ಳು',
'ಎದುರಕ್ಕಿ',
'ಎದುರಡೆ',
'ಎದುರಾಡು',
'ಎದುರಾಳಿ',
'ಎದುರಿಗೆ',
'ಎದುರಿಕ್ಕು',
'ಎದುರಿಡು',
'ಎದುರಿಸು',
'ಎದುರುಲಿ',
'ಎದುರೇಟು',
'ಎದುರೇರು',
'ಎದುರ್ಗೊಳ್ಳು',
'ಎದುರ್ಚೀಟು',
'ಎದುರ್ದನಿ',
'ಎದುರ್ನುಡಿ',
'ಎದುರ್ನೋಡು',
'ಎದುರ್ಬೀಳು',
'ಎದುರ್ವಳ್ಳಿ',
'ಎದೆಕಟ್ಟು',
'ಎದೆಕಾಪು',
'ಎದೆಕೆಚ್ಚು',
'ಎದೆಕೊಡು',
'ಎದೆಗತ್ತಿ',
'ಎದೆಗದ',
'ಎದೆಗಾಣು',
'ಎದೆಗಾತಿ',
'ಎದೆಗಾಪು',
'ಎದೆಗಾರ',
'ಎದೆಗಾರ್ತಿ',
'ಎದೆಗಾಱ',
'ಎದೆಗಿಚ್ಚು',
'ಎದೆಗುಂಡಿ',
'ಎದೆಗುಂತ',
'ಎದೆಗುಂದು',
'ಎದೆಗುಕ್ಕು',
'ಎದೆಗುದಿ',
'ಎದೆಗುಮ್ಮು',
'ಎದೆಗೂಡು',
'ಎದೆಗೆಡು',
'ಎದೆಗೇಡಿ',
'ಎದೆಗೇಡು',
'ಎದೆಗೊಂತ',
'ಎದೆಗೊಡು',
'ಎದೆಗೊಯ್ಕ',
'ಎದೆಗೊಳ್',
'ಎದೆಚಕ್ಕೆ',
'ಎದೆಚಿಪ್ಪು',
'ಎದೆಡೋಱು',
'ಎದೆತಟ್ಟು',
'ಎದೆತುಂಬು',
'ಎದೆತೊಟ್ಟು',
'ಎದೆದುಂಬು',
'ಎದೆದೆರೆ',
'ಎದೆನೀವು',
'ಎದೆನೋವು',
'ಎದೆಪಟ್ಟಿ',
'ಎದೆಬಾವು',
'ಎದೆಬಿರಿ',
'ಎದೆಭಾರ',
'ಎದೆಭಾವ',
'ಎದೆಮಟ್ಟ',
'ಎದೆಮುಚ್ಚು',
'ಎದೆಮುರಿ',
'ಎದೆಮೂಳೆ',
'ಎದೆಮೊಳೆ',
'ಎದೆಯಗ್ಗಿ',
'ಎದೆಯಾರು',
'ಎದೆಯಾಶೆ',
'ಎದೆಯಾಸೆ',
'ಎದೆಯಿರಿ',
'ಎದೆಯಿಳಿ',
'ಎದೆಯುಕ್ಕು',
'ಎದೆಯುದ್ದ',
'ಎದೆಯುಬ್ಬು',
'ಎದೆಯುರಿ',
'ಎದೆಯೊಡೆ',
'ಎದೆರವ',
'ಎದೆವಯ್ಯ',
'ಎದೆಶೂಲೆ',
'ಎದೆಸೋಲು',
'ಎದೆಹಾರು',
'ಎದೆಹಾಲು',
'ಎದೆಹಾಱು',
'ಎದೆಹುಯ್ಲು',
'ಎದೆಹೊಯ್ಲು',
'ಎದ್ದಾಸರು',
'ಎದ್ದುಕಾಣು',
'ಎದ್ದುಮದ್ದ',
'ಎದ್ದುಮದ್ದು',
'ಎದ್ದುಮೊದ್ದ',
'ಎನಿಬರ್',
'ಎನಿಬರು',
'ಎನಿಸಿಕೆ',
'ಎನ್ನಬರ',
'ಎನ್ನೆಬರ',
'ಎನ್ನೆವರಂ',
'ಎಪ್ಪಲಾನಿ',
'ಎಪ್ಪೆಬೀಳು',
'ಎಪ್ಪೆಹಾಕು',
'ಎಬಡಿಸು',
'ಎಬರೇಸಿ',
'ಎಮಪುರ',
'ಎಮೆಗೊಂಕು',
'ಎಮೆಯಾಟ',
'ಎಮೆಯಿಕ್ಕು',
'ಎಮೆಯಿಡು',
'ಎಮೆಯಿರಿ',
'ಎಮ್ಮೆಕಪ್ಪೆ',
'ಎಮ್ಮೆಗುಂಡು',
'ಎಮ್ಮೆಗೊಬ್ಬು',
'ಎಮ್ಮೆಚೇಳು',
'ಎಮ್ಮೆಚೇೞು',
'ಎಮ್ಮೆತಮ್ಮ',
'ಎಮ್ಮೆದೇಳು',
'ಎಮ್ಮೆಬಳ್ಳಿ',
'ಎಮ್ಮೆಬಾರು',
'ಎಮ್ಮೆವಳ್ಳಿ',
'ಎಮ್ಮೆವೋರಿ',
'ಎಯ್ಯಮುಳ್ಳು',
'ಎಯಿತರು',
'ಎಯ್ತರವು',
'ಎಯ್ದೆತನ',
'ಎಯ್ಯಪಂದಿ',
'ಎಯ್ಯಮಿಗ',
'ಎಯ್ಯಮೃಗ',
'ಎರಂಗುಡು',
'ಎರಗಾಡು',
'ಎರಗಿವಿ',
'ಎರಗಿಸು',
'ಎರಗುಹ',
'ಎರಚಲು',
'ಎರಚಾಡು',
'ಎರಡಚ್ಚಿ',
'ಎರಡನೆ',
'ಎರಡನೇ',
'ಎರಡಯ್ದು',
'ಎರಡಱಿ',
'ಎರಡಾಗು',
'ಎರಡಿಡು',
'ಎರಡೇಳು',
'ಎರದುಂಬೆ',
'ಎರಲುಣಿ',
'ಎರಲ್ವಟ್ಟೆ',
'ಎರವಣ್ಣು',
'ಎರವಲು',
'ಎರವಳಿ',
'ಎರವಿಕ್ಕು',
'ಎರವಿಗ',
'ಎರವುರಿ',
'ಎರಿಮಾಲು',
'ಎರಿಲದ್ದು',
'ಎರುಂಬಳ್ಳಿ',
'ಎರೆಕಾಡು',
'ಎರೆಗದ್ದೆ',
'ಎರೆಗಿಱು',
'ಎರೆಗುಲಿ',
'ಎರೆಗೆಯ್',
'ಎರೆಗೊಳ್',
'ಎರೆಚೀಲ',
'ಎರೆಬುಳು',
'ಎರೆಭೂಮಿ',
'ಎರೆಮಣ್ಣು',
'ಎರೆಮಿಗ',
'ಎರೆಯಪ್ಪ',
'ಎರೆಯಾಡು',
'ಎರೆಯಿಕ್ಕು',
'ಎರೆಯಿಸು',
'ಎರೆಯುಗಿ',
'ಎರೆವಚ್ಚು',
'ಎರೆವಟ್ಟು',
'ಎರೆವೆಱು',
'ಎರೆಹುಳು',
'ಎರೆಹೊರೆ',
'ಎರೆಹೊಲ',
'ಎರ್ತುಗಾಣ',
'ಎರ್ದೆಕೊಳ್',
'ಎರ್ದೆಗದ',
'ಎರ್ದೆಗಱೆ',
'ಎರ್ದೆಗಾಯ್',
'ಎರ್ದೆಗಿಚ್ಚು',
'ಎರ್ದೆಗಿಡು',
'ಎರ್ದೆಗುಂದು',
'ಎರ್ದೆಗುಡು',
'ಎರ್ದೆಗೆಡು',
'ಎರ್ದೆಗೆಚ್ಚು',
'ಎರ್ದೆಗೇಡಿ',
'ಎರ್ದೆಗೇಡು',
'ಎರ್ದೆಗೊಡು',
'ಎರ್ದೆಗೊಳ್',
'ಎರ್ದೆತೆರು',
'ಎರ್ದೆದಾಣ',
'ಎರ್ದೆದೆಱೆ',
'ಎರ್ದೆದೋಱು',
'ಎರ್ದೆನೋವು',
'ಎರ್ದೆಬೆಂಕೆ',
'ಎರ್ದೆಯಂಟು',
'ಎರ್ದೆಯಾಣ್ಮ',
'ಎರ್ದೆಯಾರು',
'ಎರ್ದೆಯಾಶೆ',
'ಎರ್ದೆಯಾಱು',
'ಎರ್ದೆಯಿಕ್ಕು',
'ಎರ್ದೆಯುಗಿ',
'ಎರ್ದೆಯುರ್ಚು',
'ಎರ್ದೆರಕ್ಕೆ',
'ಎರ್ದೆವಡಿ',
'ಎರ್ದೆವತ್ತು',
'ಎರ್ದೆವರ್',
'ಎರ್ದೆವುಗು',
'ಎರ್ದೆವೇೞ್',
'ಎರ್ದೆವೊತ್ತು',
'ಎರ್ಬಟ್ಟಿಸು',
'ಎರ್ಮೆಗುಂಡು',
'ಎರ್ಮೆವೋರಿ',
'ಎರ್ರಾಬಿರ್ರಿ',
'ಎರ್ರಿಬಿರ್ರಿ',
'ಎಲಗುಡಿ',
'ಎಲಡಿಕೆ',
'ಎಲತ್ತಿಕೆ',
'ಎಲರುಣಿ',
'ಎಲರ್ಪಟ',
'ಎಲರ್ವಟ್ಟೆ',
'ಎಲರ್ವೊಯ್ಲು',
'ಎಲವಡ',
'ಎಲವಲ',
'ಎಲವಸ್ತ್ರ',
'ಎಲಹುರಿ',
'ಎಲಾವರೆ',
'ಎಲಿಬಾಲ',
'ಎಲಿಯಾಲ',
'ಎಲುಕಪ್ಪಿ',
'ಎಲುಗಂತಿ',
'ಎಲುಗಟ್ಟು',
'ಎಲುಗಯ್ಯ',
'ಎಲುಗುಳಿ',
'ಎಲುದಲೆ',
'ಎಲುದೋಱು',
'ಎಲುಬುಗೈ',
'ಎಲುಮಡ',
'ಎಲುಮಾಲೆ',
'ಎಲುಮುಂಚಿ',
'ಎಲುವಡೆ',
'ಎಲೆಕಳ್ಳಿ',
'ಎಲೆಕಾವು',
'ಎಲೆಕಿವಿ',
'ಎಲೆಕೀಟ',
'ಎಲೆಕೊಟ್ಟು',
'ಎಲೆಕೋಸು',
'ಎಲೆಗಟ್ಟು',
'ಎಲೆಗಳ್ಳಿ',
'ಎಲೆಗಾರ',
'ಎಲೆಗಾರ್ತಿ',
'ಎಲೆಗುಳಿ',
'ಎಲೆಗೊೞೆ',
'ಎಲೆಗೋಮೆ',
'ಎಲೆಚಂಚಿ',
'ಎಲೆಚೇಳು',
'ಎಲೆತುಂಬು',
'ಎಲೆತುಕ್ಕು',
'ಎಲೆತೊಟ್ಟು',
'ಎಲೆತೋಟ',
'ಎಲೆತೋಟ್ಟ',
'ಎಲೆದಟ್ಟೆ',
'ಎಲೆದೋಂಟ',
'ಎಲೆದೋಟ',
'ಎಲೆದೋಱು',
'ಎಲೆಪಟ್ಟಿ',
'ಎಲೆಪಿಂಡಿ',
'ಎಲೆಪಿಲ್ಲಿ',
'ಎಲೆಪೆಂಡಿ',
'ಎಲೆಬತ್ತಿ',
'ಎಲೆಬಳ್ಳಿ',
'ಎಲೆಬಾಳೆ',
'ಎಲೆಮದ್ದು',
'ಎಲೆಮನೆ',
'ಎಲೆಮಿಂಚು',
'ಎಲೆಯಪ್ಪ',
'ಎಲೆಯಿಕ್ಕು',
'ಎಲೆಯಿಡು',
'ಎಲೆಲೆಗ',
'ಎಲೆವಡೆ',
'ಎಲೆವನಿ',
'ಎಲೆವನೆ',
'ಎಲೆವಸ್ತ್ರ',
'ಎಲೆವಳ್ಳಿ',
'ಎಲೆವೞ್ಕೆ',
'ಎಲೆವಾಸು',
'ಎಲೆವುಲಿ',
'ಎಲೆವುಳಿ',
'ಎಲೆವೇಱು',
'ಎಲೆಹಂಬು',
'ಎಲೆಹಾವು',
'ಎಲೆಹುರಿ',
'ಎಲೆಹುಲಿ',
'ಎಲೆಹುಳು',
'ಎಲೆಹೇಱು',
'ಎಲೆಹೊನ್ನೆ',
'ಎಲ್ಲಾಗಲೂ',
'ಎಲ್ಲಾಗಳೂ',
'ಎಲ್ಲಿದಳ್',
'ಎಲ್ಲಿಯಣ',
'ಎಲ್ಲೆಕಟ್ಟು',
'ಎಲ್ಲೆಗಟ್ಟು',
'ಎಲ್ಲೆಗಲ್ಲು',
'ಎಲ್ಲೆಗೆಡು',
'ಎಲ್ಲೆಗೆರೆ',
'ಎಲ್ಲೆಗೊಳ್ಳು',
'ಎಲ್ಲೆವೊಲ',
'ಎವೆಗುಟ್ಟು',
'ಎವೆದೆಱೆ',
'ಎವೆಯಾಟ',
'ಎವೆಯಿಕ್ಕು',
'ಎವೆಯಿಡು',
'ಎವೆಯೂತ',
'ಎವೆವೊಯ್',
'ಎವೆಹೊತ್ತು',
'ಎಷ್ಟಾದರೂ',
'ಎಸಗಲ',
'ಎಸಗಳೆ',
'ಎಸಗಿಲೆ',
'ಎಸಗಿಸು',
'ಎಸರಿಡು',
'ಎಸರ್ಗೊಳ್',
'ಎಸರ್ಮುಚ್ಚೆ',
'ಎಸವಂತ',
'ಎಸಳೊಡೆ',
'ಎಸಳ್ಗಣೆ',
'ಎಸಳ್ಗಣ್ಣು',
'ಎಸಳ್ಜಾಪೆ',
'ಎಸಳ್ವಸೆ',
'ಎಸೞ್ಗಣ್',
'ಎಸೞ್ವೞಿ',
'ಎಸೞ್ವಾಸು',
'ಎಸೞ್ವೊರೆ',
'ಎಸುವಡೆ',
'ಎಸುವೆಱು',
'ಎಸೆಗುಂಡು',
'ಎಸೆಚಕ್ರ',
'ಎಸೆಯೀಟಿ',
'ಎಸೆವಾರ',
'ಎಳಕಂದು',
'ಎಳಕರು',
'ಎಳಕುಳಿ',
'ಎಳಕೊಳು',
'ಎಳಗಂತಿ',
'ಎಳಗಂದಿ',
'ಎಳಗಂಪು',
'ಎಳಗಣ್',
'ಎಳಗರು',
'ಎಳಗಱು',
'ಎಳಗಾಯ್',
'ಎಳಗಾರ್',
'ಎಳಗಾವಿ',
'ಎಳಗಾವು',
'ಎಳಗಾಳಿ',
'ಎಳಗುಡಿ',
'ಎಳಗುತ್ತಿ',
'ಎಳಗುರಿ',
'ಎಳಗೂಸು',
'ಎಳಗೆಂಪು',
'ಎಳಗೊಂಬು',
'ಎಳಗೌಂಗು',
'ಎಳಜೊನ್ನ',
'ಎಳತಟ',
'ಎಳತನ',
'ಎಳತರ್',
'ಎಳತೆಗೆ',
'ಎಳದಾಟ',
'ಎಳದುಂಬಿ',
'ಎಳದೂಲ',
'ಎಳದೆಂಗು',
'ಎಳದೆನೆ',
'ಎಳದೋಂಟ',
'ಎಳದೋಟ',
'ಎಳನಗೆ',
'ಎಳನನೆ',
'ಎಳನಾಗ',
'ಎಳನೀರ್',
'ಎಳನೀರು',
'ಎಳನೀರ್ವೂ',
'ಎಳನೀಲ',
'ಎಳಮಗ',
'ಎಳಮನ',
'ಎಳಮಿಂಚು',
'ಎಳಮೆಯ್',
'ಎಳಲಿಕ್ಕು',
'ಎಳಲಿಡು',
'ಎಳಲಿಸು',
'ಎಳವಕ್ಕಿ',
'ಎಳವರ',
'ಎಳವರೆ',
'ಎಳವರ್ದು',
'ಎಳವಳ್ಳಿ',
'ಎಳವಱಿ',
'ಎಳವಾರ',
'ಎಳವಾಸು',
'ಎಳವಾಸೆ',
'ಎಳವಾಳೆ',
'ಎಳವಾೞೆ',
'ಎಳವಿಡು',
'ಎಳವುಲ್',
'ಎಳವುಲ್ಲು',
'ಎಳವುಲ್ಲೆ',
'ಎಳವೆಣ್',
'ಎಳವೆಣ್ಣು',
'ಎಳವೆರೆ',
'ಎಳವೆಱೆ',
'ಎಳಸಸಿ',
'ಎಳಸಿಂಗ',
'ಎಳಸಿಕೆ',
'ಎಳಸುಯ್',
'ಎಳಹೂಟ',
'ಎಳಹೂಟಿ',
'ಎಳಹೂಟೆ',
'ಎಳೆಕಟ್ಟು',
'ಎಳೆಕುಳಿ',
'ಎಳೆಕೊಳು',
'ಎಳೆಕೌಂಗು',
'ಎಳೆಗಂತಿ',
'ಎಳೆಗಂದಿ',
'ಎಳೆಗನ್ನ',
'ಎಳೆಗಪ್ಪು',
'ಎಳೆಗರ್ಪು',
'ಎಳೆಗಬ್ಬ',
'ಎಳೆಗಲ್ಲು',
'ಎಳೆಗರು',
'ಎಳೆಗಱು',
'ಎಳೆಗಾಯ್',
'ಎಳೆಗಾರ್',
'ಎಳೆಗಾಳಿ',
'ಎಳೆಗೂಸು',
'ಎಳೆಗೆಂಪು',
'ಎಳೆಗೊಂಬು',
'ಎಳೆಗೊನೆ',
'ಎಳೆಗೊಳ್ಳು',
'ಎಳೆಗೌಂಗು',
'ಎಳೆಜೀವ',
'ಎಳೆಜೊನ್ನ',
'ಎಳೆತಟ',
'ಎಳೆತನ',
'ಎಳೆತರ್',
'ಎಳೆತೆಗೆ',
'ಎಳೆದಾಟ',
'ಎಳೆದಾಡು',
'ಎಳೆದುಂಬಿ',
'ಎಳೆದೇರ',
'ಎಳೆನಗೆ',
'ಎಳೆನಡು',
'ಎಳೆನಾಗ',
'ಎಳೆನಾರಿ',
'ಎಳೆನಾರು',
'ಎಳೆನಿಂಬೆ',
'ಎಳೆನೀರ್',
'ಎಳೆನೀರು',
'ಎಳೆನೀಲ',
'ಎಳೆನೂಲು',
'ಎಳೆಪಾಕ',
'ಎಳೆಪ್ರಾಯ',
'ಎಳೆಬಣ್ಣ',
'ಎಳೆಬತ್ತಿ',
'ಎಳೆಬಲೆ',
'ಎಳೆಮನ',
'ಎಳೆಮರಿ',
'ಎಳೆಮಿಂಚು',
'ಎಳೆಮೀಸೆ',
'ಎಳೆಯಂಚೆ',
'ಎಳೆಯಿಸು',
'ಎಳೆವಲ್',
'ಎಳೆವಲೆ',
'ಎಳೆವಳ್ಳಿ',
'ಎಳೆವಱಿ',
'ಎಳೆವಾತು',
'ಎಳೆವಾರ',
'ಎಳೆವಾಳೆ',
'ಎಳೆವಾೞೆ',
'ಎಳೆವೀಲಿ',
'ಎಳೆವುಲ್',
'ಎಳೆವುಲ್ಲೆ',
'ಎಳೆವೆಟ್ಟು',
'ಎಳೆವೆಣ್',
'ಎಳೆವೆಣ್ಣು',
'ಎಳೆವೆಱೆ',
'ಎಳೆಸಸಿ',
'ಎಳೆಹದ',
'ಎಳೆಹಬ್ಬ',
'ಎಳೆಹೀಚು',
'ಎಳೆಹೂಟ',
'ಎಳೆಹೂಟೆ',
'ಎಳ್ಚರಿಸು',
'ಎಳ್ಚಱಿಕೆ',
'ಎಳ್ಚಱಿಸು',
'ಎಳ್ಳಗಸೆ',
'ಎಳ್ಳನಿತು',
'ಎಳ್ಳಮಾಸೆ',
'ಎಳ್ಳ್ಳಿದಿಕೆ',
'ಎಳ್ಳೆಣಿಸು',
'ಎಱಂಗುಹ',
'ಎಱಕಲು',
'ಎಱಗಿಸು',
'ಎಱಗುಹ',
'ಎಱಚಾಡು',
'ಎಱಯಪ',
'ಎಱವಟ್ಟು',
'ಎಱವಿಕ್ಕು',
'ಎಱವೆಟ್ಟು',
'ಎಱೆಗೋಲು',
'ಎಱೆತನ',
'ಎಱೆತ್ತನ',
'ಎಱೆಯಪ',
'ಎಱೆಯಪ್ಪ',
'ಎಱೆಯಿಸು',
'ಎಱೆವಟ್ಟು',
'ಎಱೆವಡು',
'ಎಱೆವಾಲು',
'ಎಱೆವೆಟ್ಟು',
'ಎಱೆವೆಸ',
'ಎೞಕುಳಿ',
'ಎೞಲಿಕ್ಕು',
'ಎೞಲ್ತರ್',
'ಎೞಲ್ಮುಡಿ',
'ಎೞವಾಡು',
'ಎೞವಿಸು',
'ಎೞೆಕುಳಿ',
'ಎೞೆತರ್',
'ಎೞೆಯಿಸು',
'ಎೞ್ಚರಿಕೆ',
'ಎೞ್ಚರಿಸು',
'ಎೞ್ತರವು',
'ಎೞ್ದೆಗಿಡು',
'ಎೞ್ಬೆಸನ',
'ಏಕಕಂಠ',
'ಏಕಕಣ',
'ಏಕಕಾಲ',
'ಏಕಕೂಟ',
'ಏಕಕೋಶ',
'ಏಕಕೋಶಿ',
'ಏಕಕ್ರಿಯೆ',
'ಏಕಗುರು',
'ಏಕಗ್ರಣಿ',
'ಏಕಗ್ರಾಹಿ',
'ಏಕಚಕ್ರ',
'ಏಕಚರ',
'ಏಕಚಿತ್ತ',
'ಏಕಚ್ಛತ್ರ',
'ಏಕಚ್ಛತ್ರಿ',
'ಏಕಚ್ಛಾಯ',
'ಏಕಛತ್ರ',
'ಏಕಜಾತಿ',
'ಏಕಜೀವ',
'ಏಕಟಾಕಿ',
'ಏಕಠಾಣ',
'ಏಕತಂತ್ರ',
'ಏಕತಂತ್ರಿ',
'ಏಕತಾನ',
'ಏಕತಾರ',
'ಏಕತಾರಿ',
'ಏಕತಾರೆ',
'ಏಕತಾಲ',
'ಏಕತಾಳ',
'ಏಕದಂಡಿ',
'ಏಕದಂತ',
'ಏಕದಲ',
'ಏಕದಳ',
'ಏಕದಾಡ',
'ಏಕದಾರಿ',
'ಏಕದೃಕ್ಕು',
'ಏಕದೃಷ್ಟಿ',
'ಏಕದೇಶ',
'ಏಕದೇಶಿ',
'ಏಕದೇಹ',
'ಏಕಧುರ',
'ಏಕನಾಥ',
'ಏಕನಾದ',
'ಏಕನಾಮು',
'ಏಕನಿಷ್ಠ',
'ಏಕಪಕ್ಷ',
'ಏಕಪತಿ',
'ಏಕಪತ್ನ',
'ಏಕಪತ್ನಿ',
'ಏಕಪತ್ರ',
'ಏಕಪದಂ',
'ಏಕಪದಿ',
'ಏಕಪಾಠಿ',
'ಏಕಪಾದ',
'ಏಕಪೀಠ',
'ಏಕಭಾವ',
'ಏಕಭಾಷಿ',
'ಏಕಭುಕ್ತ',
'ಏಕಭುಕ್ತಿ',
'ಏಕಭೋಗ',
'ಏಕಭೋಗ್ಯ',
'ಏಕಮತ',
'ಏಕಮತಿ',
'ಏಕಮತ್ಯ',
'ಏಕಮಾತ್ರ',
'ಏಕಮಾನ',
'ಏಕಮುಖ',
'ಏಕಮುಖಿ',
'ಏಕಮೂಲ',
'ಏಕಮೇವ',
'ಏಕಯಷ್ಟಿ',
'ಏಕರತ್ನಿ',
'ಏಕರಸ',
'ಏಕರಾಜ',
'ಏಕರಾತ್ರ',
'ಏಕರೂಪ',
'ಏಕರೂಪಿ',
'ಏಕಲಡಿ',
'ಏಕಲಿಂಗ',
'ಏಕಲಿಂಗಿ',
'ಏಕವರ್ಣ',
'ಏಕವರ್ಣಿ',
'ಏಕವಸ್ತು',
'ಏಕವಾಕ್ಯ',
'ಏಕವಾರ',
'ಏಕವಾಸ',
'ಏಕವೀರ',
'ಏಕವೇಣಿ',
'ಏಕಶಾಖಿ',
'ಏಕಶೃಂಗಿ',
'ಏಕಶೇಷ',
'ಏಕಸಂಧಿ',
'ಏಕಸತ್ತೆ',
'ಏಕಸರ',
'ಏಕಸರ್ಗ',
'ಏಕಸಾನು',
'ಏಕಸಿಕ್ಥ',
'ಏಕಸ್ಥಲ',
'ಏಕಸ್ಥಾನ',
'ಏಕಸ್ಥಾನಿ',
'ಏಕಸ್ಥಿತಿ',
'ಏಕಸ್ವರ',
'ಏಕಸ್ವಾಮ್ಯ',
'ಏಕಹಸ್ತ',
'ಏಕಾಂಗಿನಿ',
'ಏಕಾಂತತೆ',
'ಏಕಾಂತಿಕ',
'ಏಕಾಕಿನಿ',
'ಏಕಾಕ್ಷರ',
'ಏಕಾಕ್ಷರಿ',
'ಏಕಾಕ್ಷೀಯ',
'ಏಕಾಗ್ರತೆ',
'ಏಕಾತ್ಮಕ',
'ಏಕಾತ್ಮಜ',
'ಏಕಾದಶ',
'ಏಕಾದಶಿ',
'ಏಕಾಮಿಷ',
'ಏಕಾಯತ',
'ಏಕಾಯತ್ತ',
'ಏಕಾಯನ',
'ಏಕಾಯೇಕಿ',
'ಏಕಾರತಿ',
'ಏಕಾರ್ಣವ',
'ಏಕಾವಲಿ',
'ಏಕಾವಳಿ',
'ಏಕಾಶ್ರಯ',
'ಏಕೀಕೃತ',
'ಏಕೀಭಾವ',
'ಏಕೀಭೂತ',
'ಏಕೇಂದ್ರಿಯ',
'ಏಕೇಶ್ವರ',
'ಏಕೋತ್ತರ',
'ಏಕೋದಕ',
'ಏಕೋದರ',
'ಏಕೋದೇವ',
'ಏಕೋನಿತ',
'ಏಕೋಭಾವ',
'ಏಕೋಮಾರ್ಗ',
'ಏಗಡಿಸು',
'ಏಗೊಳಿಸು',
'ಏಗೋತ್ತರ',
'ಏಚುಪೇಚು',
'ಏಟವಿಟ',
'ಏಟವೀಟ',
'ಏಟುಗಾರ',
'ಏಟುಪಟ್ಟು',
'ಏಡಮೂಕ',
'ಏಡಿಕಾಯಿ',
'ಏಡಿಗಂತಿ',
'ಏಡಿಸಾಡು',
'ಏಡಿಹುಣ್ಣು',
'ಏಣಗೋಣ',
'ಏಣಧರ',
'ಏಣಾಂಕಾಂಕ',
'ಏಣುಬಾಗೆ',
'ಏಣುಬಾಳೆ',
'ಏಣುಮಾತು',
'ಏತಕೋಲು',
'ಏತನ್ಮಧ್ಯೆ',
'ಏತರಹ',
'ಏತಹಾಕು',
'ಏತಿಪ್ರೇತಿ',
'ಏದುಪಂದಿ',
'ಏದುಮುಳ್ಳು',
'ಏದುಮೃಗ',
'ಏದುಸಿರು',
'ಏದುಸುರು',
'ಏದೊರೆತು',
'ಏನೋಹರ',
'ಏಬರಾಸಿ',
'ಏಬರೇಸಿ',
'ಏಮಾರಿಕೆ',
'ಏಮಾರಿಸು',
'ಏಯಾರಸೆ',
'ಏಯೋದಸಿ',
'ಏರಗಟ್ಟು',
'ಏರಣಿಗ',
'ಏರಹಾಕು',
'ಏರಹೊತ್ತು',
'ಏರಾಟಿಕೆ',
'ಏರಿಕಟ್ಟು',
'ಏರಿಪಟ್ಟಿ',
'ಏರಿಯೆಲೆ',
'ಏರಿಹೊಡೆ',
'ಏರಿಳಿತ',
'ಏರಿಳಿವು',
'ಏರುಹಾಕು',
'ಏರುಂಬಳ್ಳಿ',
'ಏರುಕಟ್ಟು',
'ಏರುಗಚ್ಚೆ',
'ಏರುಗಟ್ಟು',
'ಏರುಗಡಿ',
'ಏರುಗಾಣ್',
'ಏರುಗಾವು',
'ಏರುಗೋಲ್',
'ಏರುಜೀರು',
'ಏರುತೆರೆ',
'ಏರುದಾಣ',
'ಏರುದಿಣ್ಣೆ',
'ಏರುಪೇರು',
'ಏರುಪ್ರಾಯ',
'ಏರುಬಾಚು',
'ಏರುಬೆಟ್ಟ',
'ಏರುಬ್ಬಸ',
'ಏರುಮಣೆ',
'ಏರುಮಣ್ಣು',
'ಏರುಮಾರು',
'ಏರುಮೊಳೆ',
'ಏರುವಡೆ',
'ಏರುವೋರೆ',
'ಏರುಸುಂಕ',
'ಏರುಸುತ್ತು',
'ಏರುಹತ್ತು',
'ಏರೇರಿಕೆ',
'ಏರ್ಜವ್ವನೆ',
'ಏರ್ಪಡಿಸು',
'ಏರ್ಬೆಸನ',
'ಏರ್ಮಳಲು',
'ಏರ್ವೆಸನ',
'ಏಲಾಗೀತೆ',
'ಏಲಾಪದ',
'ಏಲ್ಲಸಿತ',
'ಏವಂಗೊಳ್',
'ಏವಂಬಡೆ',
'ಏವಯಿಸು',
'ಏಷಣಿಕೆ',
'ಏಸುವಡೆ',
'ಏಸುವಾರ್',
'ಏಸುವೆಸ',
'ಏಸುವೆಱು',
'ಏಳಾಗಂಧ',
'ಏಳಾವನ',
'ಏಳಿದಿಕೆ',
'ಏಳಿದಿಕ್ಕೆ',
'ಏಳಿದಿಟ',
'ಏಳಿಬ್ಬಳ್ಳ',
'ಏಳೆಲೆಗ',
'ಏಳ್ಗಡಲು',
'ಏಳ್ಗೆವೆಱು',
'ಏಳ್ಜಡಧಿ',
'ಏಳ್ಬೆಸನ',
'ಏಱಕಟ್ಟು',
'ಏಱಕಾಸು',
'ಏಱಗಟ್ಟು',
'ಏಱದೆಗೆ',
'ಏಱನೂಂಕು',
'ಏಱಾಟಿಕೆ',
'ಏಱುಂಬೆಱೆ',
'ಏಱುಂಮನ',
'ಏಱುಗಾಣ್',
'ಏಱುಗೊಳ್',
'ಏಱುಮಾಱು',
'ಏಱುವಡೆ',
'ಏೞ್ಕಡಲ್',
'ಏೞ್ಗೆಗುಂದು',
'ಏೞ್ಗೆಗೆಡು',
'ಏೞ್ಗೆವಡೆ',
'ಏೞ್ಗೆವೆಱು',
'ಏೞ್ತರವು',
'ಏೞ್ಬೆಸನ',
'ಐಂಗಾಲಿಕ',
'ಐಂದವಾೞೆ',
'ಐಂದ್ರಜಾಲ',
'ಐಂದ್ರದಿಶೆ',
'ಐಂದ್ರಶರ',
'ಐಂದ್ರಿಯಕ',
'ಐಂದ್ರೋತ್ಸವ',
'ಐಕಮತ್ಯ',
'ಐಕಿಲ್ವೆಟ್ಟು',
'ಐಕ್ಯಭಾವ',
'ಐಕ್ಯಮತ್ಯ',
'ಐಕ್ಯವರ್ಣ',
'ಐಕ್ಯಸೂತ್ರ',
'ಐಕ್ಯಸ್ಥಲ',
'ಐಕ್ಯಸ್ಥಳ',
'ಐಕ್ಯಸ್ಥಿತಿ',
'ಐಗಂಡುಗ',
'ಐಗಣೆಯ',
'ಐಗಾವುದ',
'ಐಚ್ಛಿಕತೆ',
'ಐಟಂವಾರು',
'ಐಟದಾರ',
'ಐಡವಿಡ',
'ಐಡವಿಲ',
'ಐತರವು',
'ಐದಲೆಯ',
'ಐದುಧಾರೆ',
'ಐದುನೀರು',
'ಐದೆಗಿತ್ತಿ',
'ಐದೆತನ',
'ಐದೆದಾಳಿ',
'ಐದೆವಸ',
'ಐನ್‍ಜಮಾ',
'ಐನಹರೆ',
'ಐನುಜಮಾ',
'ಐನುಜಮೆ',
'ಐನುತಾಳಿ',
'ಐನೂರ್ವರ್',
'ಐಬಟ್ಟಲು',
'ಐಮುಸುಡ',
'ಐಯಂಗಾರ್',
'ಐರದಾಲಿ',
'ಐರದಾಳಿ',
'ಐರಾವಣ',
'ಐರಾವತ',
'ಐರಾವತಿ',
'ಐರೆದಾಲಿ',
'ಐರೆದಾಳಿ',
'ಐಲಪೈಲ',
'ಐಲಬಿಲ',
'ಐಲವಿಲ',
'ಐಲುಗಾರ',
'ಐಲುತನ',
'ಐಲುಪೈಲು',
'ಐಲುಮೀನು',
'ಐಲೇಯಕ',
'ಐವಗಲ್',
'ಐವಡಿಸು',
'ಐವಾಯ್ಪುಲಿ',
'ಐಶ್ವರಿಯ',
'ಐಷಾರಾಮ',
'ಐಷೀಕಾಸ್ತ್ರ',
'ಐಸಂಬರ',
'ಐಸರಲ',
'ಐಸರಲಿ',
'ಐಸರಳ',
'ಐಸಾವಿರ',
'ಐಸಾಸಿರ',
'ಐಸಿರಿಯ',
'ಐಸುಫೈಸು',
'ಐಳಬಿಳ',
'ಐಳೆತನ',
'ಒಂಟಿಕೋಣೆ',
'ಒಂಟಿಗಣ್ಣು',
'ಒಂಟಿಗಾರ',
'ಒಂಟಿಗಾಲು',
'ಒಂಟಿಗಿತ್ತಿ',
'ಒಂಟಿಗೆಯ್ಯ',
'ಒಂಟಿಜೀವ',
'ಒಂಟಿತನ',
'ಒಂಟಿಬಾಳು',
'ಒಂಟಿಬಾೞ್',
'ಒಂಟಿಶೂರ',
'ಒಂಟಿಸರ',
'ಒಂಟಿಹುಟ್ಟು',
'ಒಂಟುತಪ್ಪು',
'ಒಂಟೆಲುವು',
'ಒಂಟೆಹುಳು',
'ಒಂಡೆಹಿಡಿ',
'ಒಂದವಿಂದ',
'ಒಂದವಿಂದಿ',
'ಒಂದಾಗಿಸು',
'ಒಂದಾವಣೆ',
'ಒಂದುಗುಂದು',
'ಒಂದುಗೂಡು',
'ಒಂದುತನ',
'ಒಂದುಮಾಡು',
'ಒಂದೆನಿಸು',
'ಒಂದೆಲಗ',
'ಒಂಬಯ್ನೂರು',
'ಒಂಭೈನೂರು',
'ಒಕತನ',
'ಒಕ್ಕಂಡುಗ',
'ಒಕ್ಕಣಿಕೆ',
'ಒಕ್ಕಣಿಸು',
'ಒಕ್ಕತನ',
'ಒಕ್ಕಯಿಸು',
'ಒಕ್ಕರಣೆ',
'ಒಕ್ಕರಿಸು',
'ಒಕ್ಕಲಡಿ',
'ಒಕ್ಕಲಾಗು',
'ಒಕ್ಕಲಿಕ್ಕು',
'ಒಕ್ಕಲಿಗ',
'ಒಕ್ಕಲಿತಿ',
'ಒಕ್ಕಲಿರು',
'ಒಕ್ಕಲೆತ್ತು',
'ಒಕ್ಕಲೇಳು',
'ಒಕ್ಕಲ್ತನ',
'ಒಕ್ಕಲ್ದೆಱೆ',
'ಒಕ್ಕಲ್ವೋಗು',
'ಒಕ್ಕಿಲಿಕ್ಕು',
'ಒಕ್ಕುಗೋಲು',
'ಒಕ್ಕುಡಿತೆ',
'ಒಕ್ಕುಡುತೆ',
'ಒಕ್ಕುತಿಳಿ',
'ಒಕ್ಕುಮಿಗು',
'ಒಕ್ಕುಮಿಗೆ',
'ಒಕ್ಕೊರಲು',
'ಒಕ್ಕೊರಳು',
'ಒಕ್ಕೊಳಗ',
'ಒಕ್ಖಾಣಿಸು',
'ಒಗಡಿಕೆ',
'ಒಗಡಿಸು',
'ಒಗತನ',
'ಒಗಸುಗ',
'ಒಗಸುಗಂ',
'ಒಗುಮಿಗು',
'ಒಗುಮಿಗೆ',
'ಒಗೆತನ',
'ಒಗೆತರ್',
'ಒಗೆಯಿಸು',
'ಒಗ್ಗಡಿಸು',
'ಒಗ್ಗರಣೆ',
'ಒಗ್ಗರಿಸು',
'ಒಗ್ಗಳಿಗೆ',
'ಒಗ್ಗುಗೆಡು',
'ಒಗ್ಗುಗೊಳ್',
'ಒಗ್ಗುಡಿಸು',
'ಒಗ್ಗುವಡೆ',
'ಒಗ್ಗುವಿಡಿ',
'ಒಗ್ಗೂಡಿಕೆ',
'ಒಗ್ಗೂಡಿಸು',
'ಒಗ್ಗೊಡಸು',
'ಒಗ್ಗೊರಲ್',
'ಒಗ್ಘರಣೆ',
'ಒಗ್ಘರಿಸು',
'ಒಚ್ಚಗುಣ',
'ಒಚ್ಚವಡಿ',
'ಒಚ್ಚಿಹೊತ್ತು',
'ಒಚ್ಚೇರಿಸು',
'ಒಜ್ಜರಿಸು',
'ಒಟಕರು',
'ಒಟಗುಟ್ಟು',
'ಒಟ್ಟಂಬಾರ',
'ಒಟ್ಟಜಿಕೆ',
'ಒಟ್ಟಪ್ಪಣೆ',
'ಒಟ್ಟಯಿಸು',
'ಒಟ್ಟರಿಸು',
'ಒಟ್ಟವಿಸು',
'ಒಟ್ಟುಗೂಡು',
'ಒಟ್ಟುಜಾತಿ',
'ಒಟ್ಟುರಾಶಿ',
'ಒಟ್ಟುಲೆಕ್ಕ',
'ಒಟ್ಟೆಬೀಳು',
'ಒಟ್ಟೆಬುಲ್ಲು',
'ಒಟ್ಟೆಮೃಗ',
'ಒಡಂಗೊಳ್',
'ಒಡಂಬಡ',
'ಒಡಂಬಡು',
'ಒಡಕಲ',
'ಒಡಕಲು',
'ಒಡಗಚ್ಚು',
'ಒಡಗತೆ',
'ಒಡಗಥೆ',
'ಒಡಗವಿ',
'ಒಡಗಾದು',
'ಒಡಗೂಟ',
'ಒಡಗೂಡು',
'ಒಡಗೊಳ್',
'ಒಡಗೊಳ್ಳು',
'ಒಡತಣ',
'ಒಡತರು',
'ಒಡತರೆ',
'ಒಡತುಳಿ',
'ಒಡನಾಟ',
'ಒಡನಾಡಿ',
'ಒಡನಾಡು',
'ಒಡನಿಡು',
'ಒಡನಿರ್',
'ಒಡನುಡಿ',
'ಒಡನೆಗೆ',
'ಒಡನೆರೆ',
'ಒಡನೋದಿ',
'ಒಡನೋದು',
'ಒಡಪಡು',
'ಒಡಪಾಟ',
'ಒಡಪಿಡು',
'ಒಡಪುಟ್ಟು',
'ಒಡಬಡು',
'ಒಡಬಾಳು',
'ಒಡಬೀಳ್',
'ಒಡಬೆಸು',
'ಒಡಮುರಿ',
'ಒಡಮೂಡು',
'ಒಡರಿಚು',
'ಒಡರಿಸು',
'ಒಡರ್ಚಿಸು',
'ಒಡಲಾಗು',
'ಒಡಲಿಚ',
'ಒಡಲಿಡು',
'ಒಡಲಿಲಿ',
'ಒಡಲುರಿ',
'ಒಡಲೂಟ',
'ಒಡಲ್ಗಂದು',
'ಒಡಲ್ಗಿಚ್ಚು',
'ಒಡವಡು',
'ಒಡವರ್',
'ಒಡವರ',
'ಒಡವರಿ',
'ಒಡವರು',
'ಒಡವರೆ',
'ಒಡವಳೆ',
'ಒಡವಾಯ್',
'ಒಡವಾರ',
'ಒಡವಾಱು',
'ಒಡವಾೞ್',
'ಒಡವುಗು',
'ಒಡವುಟ್ಟಿ',
'ಒಡವುಟ್ಟು',
'ಒಡವೆರೆ',
'ಒಡವೆರ್ಚು',
'ಒಡವೆಳೆ',
'ಒಡವೋಗು',
'ಒಡಸಲ್',
'ಒಡಸಲ್ಲು',
'ಒಡಸಾಯ್',
'ಒಡಹಾಯ್',
'ಒಡಹಾಯು',
'ಒಡಹಾಯ್ಸು',
'ಒಡಹುಟ್ಟು',
'ಒಡಿಗಟ್ಟು',
'ಒಡಿಯಾಣ',
'ಒಡೆಕುಟ್ಟು',
'ಒಡೆಗಂಚು',
'ಒಡೆಗಚ್ಚು',
'ಒಡೆಗಲ್ಲು',
'ಒಡೆಗಾರ್ತಿ',
'ಒಡೆಗುಟ್ಟು',
'ಒಡೆಜಾಲಿ',
'ಒಡೆತನ',
'ಒಡೆತಿವಿ',
'ಒಡೆತುಳಿ',
'ಒಡೆದಿವಿ',
'ಒಡೆದುಳಿ',
'ಒಡೆನೂಕು',
'ಒಡೆಬಡಿ',
'ಒಡೆಬಿಗಿ',
'ಒಡೆಮೀಟು',
'ಒಡೆಮುರಿ',
'ಒಡೆಮುಱಿ',
'ಒಡೆಮೆಟ್ಟು',
'ಒಡೆಯಳ್',
'ಒಡೆಯಳು',
'ಒಡೆಯಿಕ್ಕು',
'ಒಡೆಯಿಡು',
'ಒಡೆಯಿಸು',
'ಒಡೆಯಿಱಿ',
'ಒಡೆಯುಚ್ಚು',
'ಒಡೆಯೆಸು',
'ಒಡೆಯೇಳ್',
'ಒಡೆಯೊತ್ತು',
'ಒಡೆವರಿ',
'ಒಡೆವಾಯ್',
'ಒಡೆವೊಯ್',
'ಒಡೆಸೀಳ್',
'ಒಡೆಸೀಳು',
'ಒಡೆಹಾಯ್',
'ಒಡೆಹೊಮ್ಮು',
'ಒಡೆಹೊಯ್',
'ಒಡ್ಡತನ',
'ಒಡ್ಡದೆಱೆ',
'ಒಡ್ಡಯಿಸು',
'ಒಡ್ಡರಾಗಿ',
'ಒಡ್ಡವಣೆ',
'ಒಡ್ಡವಿಸು',
'ಒಡ್ಡಳಿಸು',
'ಒಡ್ಡುಂಗಯ್',
'ಒಡ್ಡುಂಗಲ್',
'ಒಡ್ಡುಂಗಾಡಿ',
'ಒಡ್ಡುಗಟ್ಟು',
'ಒಡ್ಡುಗಲ್',
'ಒಡ್ಡುಗಲ್ಲು',
'ಒಡ್ಡುಗೆಡು',
'ಒಡ್ಡುಗೊಳ್',
'ಒಡ್ಡುಗೋಡೆ',
'ಒಡ್ಡುದಾರಿ',
'ಒಡ್ಡುದೋಱು',
'ಒಡ್ಡೋಲಗ',
'ಒಣಕಟ್ಟೆ',
'ಒಣಕಲು',
'ಒಣಕುತ್ತ',
'ಒಣಕೆಮ್ಮು',
'ಒಣಕೆಯ್',
'ಒಣಗಣ್ಣು',
'ಒಣಗಲ್',
'ಒಣಗಿಕ್ಕು',
'ಒಣಗಿಲ',
'ಒಣಗಿಲು',
'ಒಣಗಿಸು',
'ಒಣಚರ್ಚೆ',
'ಒಣಜಂಭ',
'ಒಣಜ್ಞಾನ',
'ಒಣತರ್ಕ',
'ಒಣತಿಂಡಿ',
'ಒಣದಿನ',
'ಒಣದುರ್ಗಿ',
'ಒಣನಗು',
'ಒಣನಗೆ',
'ಒಣನುಡಿ',
'ಒಣನೆಲ',
'ಒಣನೆವ',
'ಒಣಪಡಿ',
'ಒಣಬಟ್ಟೆ',
'ಒಣಬಾಯಿ',
'ಒಣಬಾಳು',
'ಒಣಮಾತು',
'ಒಣಮುಖ',
'ಒಣಮೇವು',
'ಒಣಮೋರೆ',
'ಒಣರೆಂಬೆ',
'ಒಣರೊಟ್ಟಿ',
'ಒಣವಾದ',
'ಒಣವಾಯ್',
'ಒಣವುಲ್',
'ಒಣಶುಂಠಿ',
'ಒಣಶೊಂಠಿ',
'ಒಣಸೋಗು',
'ಒಣಹುಲ್ಲು',
'ಒಣಹೆಮ್ಮೆ',
'ಒತ್ತಂಬರ',
'ಒತ್ತಂಬರಿ',
'ಒತ್ತಂಬಿಸು',
'ಒತ್ತಕ್ಕರ',
'ಒತ್ತಕ್ಷರ',
'ಒತ್ತಡವೆ',
'ಒತ್ತಬರ',
'ಒತ್ತಮಿಸು',
'ಒತ್ತರಿಸು',
'ಒತ್ತಲಿಕ್ಕು',
'ಒತ್ತವಿಸು',
'ಒತ್ತಾಯಿಸು',
'ಒತ್ತಾಳಿಕೆ',
'ಒತ್ತಿರಿಸು',
'ಒತ್ತುಂಗುರ',
'ಒತ್ತುಂಬಲ',
'ಒತ್ತುಕೊಡು',
'ಒತ್ತುಗಂಟು',
'ಒತ್ತುಗಂಬ',
'ಒತ್ತುಗಲ್ಲು',
'ಒತ್ತುಗಾಱ',
'ಒತ್ತುಗುಂಡಿ',
'ಒತ್ತುಗುಡು',
'ಒತ್ತುಗೊಡು',
'ಒತ್ತುಗೊಳ್',
'ಒತ್ತುಗೋಡೆ',
'ಒತ್ತುಬರ್',
'ಒತ್ತುಬೇಲಿ',
'ಒತ್ತುಮಾಡು',
'ಒತ್ತುಯಂತ್ರ',
'ಒತ್ತುವಣ್ಣು',
'ಒತ್ತುವರಿ',
'ಒತ್ತುವಿಡಿ',
'ಒತ್ತುವೋಗು',
'ಒತ್ತುಹುಣ್ಣು',
'ಒತ್ತುಹೆಣೆ',
'ಒತ್ತೆಗೊಡು',
'ಒತ್ತೆಗೊಳ್',
'ಒತ್ತೆಗೊಳ್ಳು',
'ಒತ್ತೆದಾರ',
'ಒತ್ತೆಪೋಕ',
'ಒತ್ತೆಮಣ್',
'ಒತ್ತೆಯಾಳು',
'ಒತ್ತೆಯಿಡು',
'ಒತ್ತೆವಿಡಿ',
'ಒತ್ತೆವೆಣ್',
'ಒತ್ತೆವೋಗು',
'ಒತ್ತೆಸೂಳೆ',
'ಒತ್ತೆಹಿಡಿ',
'ಒತ್ತೊರಳು',
'ಒತ್ತೊಸಗೆ',
'ಒದಗಣೆ',
'ಒದಗಿಸು',
'ಒದರಾಟ',
'ಒದರಾಡು',
'ಒದವಳೆ',
'ಒದವಿನ',
'ಒದವಿಸು',
'ಒದಹಿಸು',
'ಒದಱಿಸು',
'ಒದೆಕಲು',
'ಒದೆಗಂಬ',
'ಒದೆಗಲ್ಲು',
'ಒದೆಮರ',
'ಒದೆವಡೆ',
'ಒದ್ದೆಕಟ್ಟು',
'ಒದ್ದೆಹಿಂಡು',
'ಒನಗೂಡು',
'ಒನಮಾಲೆ',
'ಒನರಿಸು',
'ಒನಲಿಸು',
'ಒನೆಕಲ್ಲು',
'ಒಪ್ಪವಾಗು',
'ಒಪ್ಪಂಗಿಡು',
'ಒಪ್ಪಂಗುಂದು',
'ಒಪ್ಪಂಗೆಯ್',
'ಒಪ್ಪಂದಳೆ',
'ಒಪ್ಪಂಬಡು',
'ಒಪ್ಪಂಬಡೆ',
'ಒಪ್ಪಂಬೂಸು',
'ಒಪ್ಪಂಬೆಱು',
'ಒಪ್ಪಕಾಱ',
'ಒಪ್ಪಗಾತಿ',
'ಒಪ್ಪಗಿಸು',
'ಒಪ್ಪಗುಂದು',
'ಒಪ್ಪಗೆಡು',
'ಒಪ್ಪಗೊಡು',
'ಒಪ್ಪಗೊಳ್',
'ಒಪ್ಪಮಿಡು',
'ಒಪ್ಪಯಿಸು',
'ಒಪ್ಪವಡೆ',
'ಒಪ್ಪವಳಿ',
'ಒಪ್ಪವಿಕ್ಕು',
'ಒಪ್ಪವಿಡಿ',
'ಒಪ್ಪವಿಡು',
'ಒಪ್ಪವೆಱು',
'ಒಪ್ಪಹಾಕು',
'ಒಪ್ಪಾಚಾರ',
'ಒಪ್ಪಿರಿಸು',
'ಒಪ್ಪುಗೊಡು',
'ಒಪ್ಪುಗೊಳ್',
'ಒಪ್ಪುಗೊಳು',
'ಒಪ್ಪುಗೊಳ್ಳು',
'ಒಪ್ಪುದಾಳ್',
'ಒಪ್ಪುನುಡಿ',
'ಒಪ್ಪುರುಷ',
'ಒಪ್ಪುವಡೆ',
'ಒಪ್ಪುವಿಕೆ',
'ಒಪ್ಪುವೀಳ್ಯ',
'ಒಪ್ಪುವೆಱು',
'ಒಬ್ಬಂಟಿಗ',
'ಒಬ್ಬುಳಿಕೆ',
'ಒಬ್ಬುಳಿಕ್ಕು',
'ಒಬ್ಬುಳಿಸು',
'ಒಬ್ಬೆರಲ್',
'ಒಬ್ಬೆರಳು',
'ಒಬ್ಬೊಂಟಿಗ',
'ಒಮ್ಮಗ್ಗಲು',
'ಒಮ್ಮಗ್ಗುಲು',
'ಒಮ್ಮತ್ತರ್',
'ಒಮ್ಮತ್ತಲು',
'ಒಮ್ಮನಸ್ಕ',
'ಒಮ್ಮನಸ್ಸು',
'ಒಮ್ಮಾಲಾಗು',
'ಒಮ್ಮಾಳಿಗೆ',
'ಒಮ್ಮುಕ್ಕುಳ್',
'ಒಯ್ಯನಾಗು',
'ಒಯ್ಯಾರಿಸು',
'ಒಯ್ಯಾಳಿಗ',
'ಒರಗಾಣಿ',
'ಒರಗಿಸು',
'ಒರಟಾಡು',
'ಒರಲಿಕೆ',
'ಒರಲಿಡು',
'ಒರಲಿಸು',
'ಒರವಾರೆ',
'ಒರಸಿಸು',
'ಒರಸುಹ',
'ಒರಳಕ್ಕಿ',
'ಒರುವಾರ',
'ಒರೆಕಡ್ಡಿ',
'ಒರೆಗಟ್ಟು',
'ಒರೆಗಲ್',
'ಒರೆಗಲ್ಲು',
'ಒರೆಗಳೆ',
'ಒರೆಗಾಱ',
'ಒರೆಗೊಳ್',
'ಒರೆದೊರೆ',
'ಒರೆಮಣೆ',
'ಒರೆಯಿಡು',
'ಒರೆಯಿಸು',
'ಒರೆಯುಗಿ',
'ಒರೆವರ್',
'ಒರೆವುಡಿ',
'ಒರೆಹಚ್ಚು',
'ಒರೇಯನ್',
'ಒರ್ಕಂಡುಗ',
'ಒರ್ಕುಡಿತೆ',
'ಒರ್ಕೆಯ್ಯನ್ನ',
'ಒರ್ಕೊರಲ್',
'ಒರ್ಖಂಡುಗ',
'ಒರ್ಗುಡಿಸು',
'ಒರ್ಗೂಡಿಸು',
'ಒರ್ಚವಡಿ',
'ಒರ್ಪನೆಯ',
'ಒರ್ಬರಿಸ',
'ಒರ್ಬಳಸು',
'ಒರ್ಬುಳಿಕೆ',
'ಒರ್ಬುಳಿಸು',
'ಒರ್ಮತ್ತರ್',
'ಒರ್ಮತ್ತಲ್',
'ಒರ್ಮೊದಲ್',
'ಒರ್ಮೊದಲೆ',
'ಒರ್ವಗಲ್',
'ಒರ್ವನಿತೆ',
'ಒರ್ವೆರಲ್',
'ಒರ್ವೆರಳು',
'ಒಲಕಾಡು',
'ಒಲಣಿಗೆ',
'ಒಲವರ',
'ಒಲವಾನ್',
'ಒಲವುಣ್ಮು',
'ಒಲವೆರು',
'ಒಲವೆರೆ',
'ಒಲವೆಱು',
'ಒಲವೇರು',
'ಒಲವೊಂದು',
'ಒಲಿಯಿಸು',
'ಒಲುನಗೆ',
'ಒಲೆಕಟ್ಟೆ',
'ಒಲೆಗುಪ್ಪೆ',
'ಒಲೆಸರ',
'ಒಲ್ಮೆನೋಟ',
'ಒಲ್ಮೆವೆಱು',
'ಒಲ್ಲಡೆಣ್ಣೆ',
'ಒಲ್ಲಣಿಗೆ',
'ಒಲ್ಲಯಿಸು',
'ಒಸರಾಱು',
'ಒಸರಿಕೆ',
'ಒಸರಿಸು',
'ಒಸರ್ಗಣ್',
'ಒಸರ್ಗಣ್ಣಿ',
'ಒಸರ್ಗಲ್',
'ಒಸರ್ತರ್',
'ಒಸರ್ದಾಣ',
'ಒಸವಂತ',
'ಒಸೆಯಿಸು',
'ಒಳಕಡೆ',
'ಒಳಕಣ್',
'ಒಳಕಥೆ',
'ಒಳಕಯ್',
'ಒಳಕಾಲು',
'ಒಳಕಿಣ್ವ',
'ಒಳಕಿಸೆ',
'ಒಳಕುರು',
'ಒಳಕೆಯ್',
'ಒಳಕೆಯ್ಸು',
'ಒಳಕೇರಿ',
'ಒಳಕೈಸು',
'ಒಳಕೊಳ್',
'ಒಳಕೋಂಟೆ',
'ಒಳಕೋಣೆ',
'ಒಳಕೋಹು',
'ಒಳಗಂಟು',
'ಒಳಗಡಿ',
'ಒಳಗಡೆ',
'ಒಳಗಣ್',
'ಒಳಗಣ್ಣು',
'ಒಳಗಱಿ',
'ಒಳಗಾಗು',
'ಒಳಗಾಣ್',
'ಒಳಗಿವಿ',
'ಒಳಗುಗೈ',
'ಒಳಗುಟ್ಟು',
'ಒಳಗುಡಿ',
'ಒಳಗುದಿ',
'ಒಳಗುದ್ದು',
'ಒಳಗೃಹ',
'ಒಳಗೆರೆ',
'ಒಳಗೆಱೆ',
'ಒಳಗೊಳ್',
'ಒಳಗೋಟೆ',
'ಒಳಗೋಣೆ',
'ಒಳಚಂದ',
'ಒಳಚರ್ಮ',
'ಒಳಚಾಚು',
'ಒಳಚಿತ್ತ',
'ಒಳಚುಚ್ಚು',
'ಒಳಜೇಬು',
'ಒಳಜ್ವರ',
'ಒಳಡೊಂಕು',
'ಒಳತಂತ್ರ',
'ಒಳತೆಗೆ',
'ಒಳತೋಟಿ',
'ಒಳದನಿ',
'ಒಳದಾರಿ',
'ಒಳದಿಶೆ',
'ಒಳದೂಡು',
'ಒಳದೆಗೆ',
'ಒಳದೇಶ',
'ಒಳದೊಂಡು',
'ಒಳದೋಟಿ',
'ಒಳನಂಟ',
'ಒಳನಡೆ',
'ಒಳನಾಟು',
'ಒಳನಾಡು',
'ಒಳನಾರು',
'ಒಳನಿಟ್ಟು',
'ಒಳನುಡಿ',
'ಒಳನೂಕು',
'ಒಳನೆಪ',
'ಒಳನೆರೆ',
'ಒಳನೋಟ',
'ಒಳಪಟ್ಟು',
'ಒಳಪಡಿ',
'ಒಳಪಡು',
'ಒಳಪಿಡಿ',
'ಒಳಪುಗು',
'ಒಳಪೊಗು',
'ಒಳಪೊಯ್',
'ಒಳಪೊರೆ',
'ಒಳಬಗೆ',
'ಒಳಬಟ್ಟೆ',
'ಒಳಬದಿ',
'ಒಳಬಾಗು',
'ಒಳಬಾಳು',
'ಒಳಬೀಳ್',
'ಒಳಬೀಳು',
'ಒಳಬೇಟ',
'ಒಳಬೊಳ್ಳೆ',
'ಒಳಭಾಗ',
'ಒಳಭಿತ್ತಿ',
'ಒಳಮಟ್ಟ',
'ಒಳಮಟ್ಟು',
'ಒಳಮನೆ',
'ಒಳಮಬ್ಬು',
'ಒಳಮರ್ಮ',
'ಒಳಮಾಡು',
'ಒಳಮಾತು',
'ಒಳಮಾರು',
'ಒಳಮುಖ',
'ಒಳಮುಯ್ಪು',
'ಒಳಮೆಯ್',
'ಒಳಮೊಗ',
'ಒಳಮೊಳೆ',
'ಒಳಮೋಸ',
'ಒಳಯಿಂಕೆ',
'ಒಳಯಿಕೆ',
'ಒಳಯಿಕ್ಕೆ',
'ಒಳಯುದ್ಧ',
'ಒಳರಸ',
'ಒಳರೋಗಿ',
'ಒಳಲಂಚ',
'ಒಳಲೂಟಿ',
'ಒಳಲೊಟ್ಟೆ',
'ಒಳವಕ್ಕ',
'ಒಳವಗೆ',
'ಒಳವರ್',
'ಒಳವರಿ',
'ಒಳವಸ್ತ್ರ',
'ಒಳವಾಯ್',
'ಒಳವಾಲ',
'ಒಳವಾಱು',
'ಒಳವಿಡಿ',
'ಒಳವೀಳ್',
'ಒಳವುಗೈ',
'ಒಳವೊಗು',
'ಒಳವೊಯ್ಲು',
'ಒಳಶುಂಠಿ',
'ಒಳಸಂಚು',
'ಒಳಸರಿ',
'ಒಳಸಱೆ',
'ಒಳಸಾರ್',
'ಒಳಸಾರ',
'ಒಳಸಾಲ',
'ಒಳಸಾಲು',
'ಒಳಸಾಲೆ',
'ಒಳಸೀರೆ',
'ಒಳಸುಂಕ',
'ಒಳಸುದ್ದಿ',
'ಒಳಸುರ್ಕು',
'ಒಳಸುಳಿ',
'ಒಳಸೂತ್ರ',
'ಒಳಸೂಱೆ',
'ಒಳಸೇದು',
'ಒಳಸೇರು',
'ಒಳಸೊಗ',
'ಒಳಸೋರ್',
'ಒಳಹರಿ',
'ಒಳಹಾದಿ',
'ಒಳಹುಗು',
'ಒಳಹುಯ್',
'ಒಳಹುರಿ',
'ಒಳಹೊಗು',
'ಒಳಹೊಟ್ಟೆ',
'ಒಳಹೊಯ್',
'ಒಳಱಿಸು',
'ಒಳಾಂಗಣ',
'ಒಳುಗಂಪು',
'ಒಳುಗಾಡು',
'ಒಳುಗಾರ್ತಿ',
'ಒಳುಗುಂದು',
'ಒಳುಗುಣ',
'ಒಳುಗೂಟ',
'ಒಳುಗೊಂಬು',
'ಒಳುನುಡಿ',
'ಒಳುನೋಟ',
'ಒಳುಬಾಳು',
'ಒಳುಮಾಟ',
'ಒಳುಮೊಗ',
'ಒಳುವಣ್ಣ',
'ಒಳುವರ್ಣ',
'ಒಳುವಾತು',
'ಒಳುವೆಣ್',
'ಒಳೆಹೆಂಚು',
'ಒಳ್ಗದಿರ್',
'ಒಳ್ಗನಸು',
'ಒಳ್ಗನ್ನಡ',
'ಒಳ್ಗುಡುತೆ',
'ಒಳ್ಗುವರ',
'ಒಳ್ಗೂಡಾರ',
'ಒಳ್ಗೊಂಚಲ್',
'ಒಳ್ತಳಿರ್',
'ಒಳ್ದಳಿರ್',
'ಒಳ್ನಡಕ',
'ಒಳ್ಪಡರ್',
'ಒಳ್ಪರಿಜು',
'ಒಳ್ಪಿಡೆಯ',
'ಒಳ್ಪುಗಿಡು',
'ಒಳ್ಪುಗೆಯ್',
'ಒಳ್ಪುಗೊಳ್',
'ಒಳ್ಪುನುಡಿ',
'ಒಳ್ಪುವಡೆ',
'ಒಳ್ಪುವೆಱು',
'ಒಳ್ಬೆಳಸು',
'ಒಳ್ಮಿದುಳ್',
'ಒಳ್ಮುಗುಳ್',
'ಒಳ್ವರಕೆ',
'ಒಳ್ವರಲ್',
'ಒಳ್ವರಿಜು',
'ಒಳ್ವಾತುಗ',
'ಒಳ್ವಾನಸ',
'ಒಳ್ವಾವುಗೆ',
'ಒಳ್ವೆಸನ',
'ಒಳ್ವೆಸರ್',
'ಒಳ್ವೆಳಗು',
'ಒಳ್ವೊನಲ್',
'ಒಳ್ಳಡಗು',
'ಒಳ್ಳರಲ್',
'ಒಳ್ಳಲಗು',
'ಒಳ್ಳಲರ್',
'ಒಳ್ಳಱಿವು',
'ಒಳ್ಳಾಗರ',
'ಒಳ್ಳಿಕೆಯ್',
'ಒಳ್ಳಿದನು',
'ಒಳ್ಳಿದಳು',
'ಒಳ್ಳಿಹನು',
'ಒಳ್ಳುಗುರ್',
'ಒಳ್ಳುಣಿಸು',
'ಒಳ್ಳುರಿಚ',
'ಒಳ್ಳುಲಿಪು',
'ಒಳ್ಳೂರಿಗ',
'ಒಳ್ಳೆಕಾರೆ',
'ಒಳ್ಳೆತನ',
'ಒಳ್ಳೆತುಂಬೆ',
'ಒಳ್ಳೆನಂಗು',
'ಒಳ್ಳೆನೀಲಿ',
'ಒಳ್ಳೆಪೊದೆ',
'ಒಳ್ಳೆಬೇವು',
'ಒಳ್ಳೆಸೞ್',
'ಒಳ್ಳೆಸೀಗೆ',
'ಒಳ್ಳೆಹೊನ್ನೆ',
'ಒಱಂಗಿಸು',
'ಒಱಗಿಸು',
'ಒಱಲಾನು',
'ಒಱಲಿಡು',
'ಒಱಲಿಸು',
'ಒಱವೇಳ್',
'ಒಱೆಗಟ್ಟು',
'ಒಱೆಗಲ್ಲು',
'ಒಱೆಗಳೆ',
'ಒಱೆಗಾರ್ತಿ',
'ಒಱೆಗಾಱ',
'ಒಱೆದೆಗೆ',
'ಒಱೆಯಾವು',
'ಒಱೆಯಿಸು',
'ಒಱೆವನಿ',
'ಒಱೆವಾಯ್',
'ಒಱೆವೋಗು',
'ಓಂಕರಿಸು',
'ಓಂಪ್ರಥಮ',
'ಓಕರಣೆ',
'ಓಕರಿಕೆ',
'ಓಕರಿಸು',
'ಓಗಂಪಾಡು',
'ಓಗಂಬಾಡು',
'ಓಗಡಿಕೆ',
'ಓಗಡಿಸು',
'ಓಗರವೂ',
'ಓಗಾಯಿತ',
'ಓಜಗಾಱ',
'ಓಜರಾಶಿ',
'ಓಜರೇಖೆ',
'ಓಜಾರೇಖೆ',
'ಓಜಾಸೂತ್ರ',
'ಓಜೆಗಾತಿ',
'ಓಜೆಗಾಱ',
'ಓಜೆಗೆಡು',
'ಓಜೆಗೆಯ್',
'ಓಜೆಗೆಯ್ಸು',
'ಓಜೆಗೊಳ್',
'ಓಜೆದಪ್ಪು',
'ಓಜೆವಚ್ಚ',
'ಓಜೆವೆಱು',
'ಓಜೋಗುಣ',
'ಓಜೋನ್ವಿತ',
'ಓಜೋನ್ವಿತೆ',
'ಓಟಂಗೊಳ್',
'ಓಟಗಾತಿ',
'ಓಟಗಾರ',
'ಓಟಗುಳಿ',
'ಓಟವಿಕ್ಕು',
'ಓಡಗಾರ',
'ಓಡಾಡಿಸು',
'ಓಡುಕುಳಿ',
'ಓಡುಗಲ್ಲು',
'ಓಡುಗಾಲಿ',
'ಓಡುಗುಳಿ',
'ಓಡುಗೆಯ್ಯ',
'ಓಡುಬಿಲ',
'ಓಡುಭಾಗ',
'ಓಡುಮಾಡು',
'ಓಡುಹುಳು',
'ಓಡೆಬಾವಿ',
'ಓಣಿಯೋಗು',
'ಓತಪ್ರೋತ',
'ಓತಿಕಾಟ',
'ಓತಿಕೇತ',
'ಓತೀಕಾಟ',
'ಓದಾಣಿಕೆ',
'ಓದುಂಗಾಱ',
'ಓದುಕುಳಿ',
'ಓದುಗಬ್ಬ',
'ಓದುಗಲಿ',
'ಓದುಗಾರ',
'ಓದುಗಾರ್ತಿ',
'ಓದುಗಾಱ',
'ಓದುಗಿತಿ',
'ಓದುಗಿತ್ತಿ',
'ಓದುಗುಳಿ',
'ಓದುವೞಿ',
'ಓದುವೇೞ್',
'ಓದೊಡೆಯ',
'ಓಬಳಾಡು',
'ಓಬಿಕಾಲ',
'ಓಬಿರಾಯ',
'ಓಬೀಕಾಲ',
'ಓಬೀರಾಯ',
'ಓಮುಕಾಳು',
'ಓಯಾರಿಸು',
'ಓರಂಡಲ',
'ಓರಂತರ',
'ಓರಂತಿರ್',
'ಓರಂತಾಗು',
'ಓರಗಿತ್ತಿ',
'ಓರಗೆವೂ',
'ಓರಣಿಕೆ',
'ಓರಣಿಗೆ',
'ಓರಣಿಸು',
'ಓರಾಸನ',
'ಓರಿಗಾಡು',
'ಓರಿಚೇಳು',
'ಓರೆಂಜಲ್',
'ಓರೆಕಿವಿ',
'ಓರೆಕೆಯ್',
'ಓರೆಕೋರೆ',
'ಓರೆಗಣ್ಣು',
'ಓರೆಗೆಡೆ',
'ಓರೆನುಡಿ',
'ಓರೆನೋಟ',
'ಓರೆಪೀರ',
'ಓರೆಪೀರೆ',
'ಓರೆಪೋರೆ',
'ಓರೆಮಾಡು',
'ಓರೆಮಾತು',
'ಓರೆಮೋರೆ',
'ಓರೆಯಾಗು',
'ಓರೆಯಿಸು',
'ಓರೆವರಿ',
'ಓರೆವೊಯ್ಲು',
'ಓರೆವೋಗು',
'ಓರ್ಗುಡಿಸು',
'ಓಲಗವೀ',
'ಓಲಗಾರ್ತ',
'ಓಲಗಿಗ',
'ಓಲಗಿಸು',
'ಓಲಪಾತ',
'ಓಲಯಿಸು',
'ಓಲವಿಸು',
'ಓಲಸರಿ',
'ಓಲಾಡಿಸು',
'ಓಲಿತರು',
'ಓಲುಕೊಡು',
'ಓಲುಗಾರ',
'ಓಲುಗಾರ್ತಿ',
'ಓಲೆಕಾತಿ',
'ಓಲೆಕಾರ',
'ಓಲೆಕಾಱ',
'ಓಲೆಕೊಡೆ',
'ಓಲೆಕೊಪ್ಪು',
'ಓಲೆಗರಿ',
'ಓಲೆಗಾತಿ',
'ಓಲೆಗುಡು',
'ಓಲೆಗೊಡೆ',
'ಓಲೆಗ್ರಂಥ',
'ಓಲೆಬೆಲ್ಲ',
'ಓಲೆಭಾಗ್ಯ',
'ಓಲೆಯೊಯ್',
'ಓಲೆವಾಗ್ಯ',
'ಓಲೈಸಿಸು',
'ಓವಗಿಸು',
'ಓವಡಿಸು',
'ಓವನಿಗೆ',
'ಓವರಿಗೆ',
'ಓವಳಿಸು',
'ಓವುಕಾಳು',
'ಓಷಧೀಶ',
'ಓಷ್ಠಪಾನ',
'ಓಷ್ಠಬಂಬು',
'ಓಷ್ಠಾಗತ',
'ಓಷ್ಠ್ಯಸ್ವರ',
'ಓಸರಣೆ',
'ಓಸರಿಕೆ',
'ಓಸರಿಗೆ',
'ಓಸರಿಸು',
'ಓಸುಕರ',
'ಓಸುಗರ',
'ಓಹಡಿಸು',
'ಓಹರಿಕೆ',
'ಓಳಿಕೊಳ್',
'ಓಳಿಗೊಳ್',
'ಓಳಿಯಿಸು',
'ಓಳಿವಡು',
'ಓಳಿವಡೆ',
'ಓಳಿವಾಡು',
'ಓಳಿವಿಡಿ',
'ಓಳಿವೀಡು',
'ಓಳಿವೆಳ್ಪು',
'ಓಳಿವೆಱು',
'ಓಱೆಗಾಱ',
'ಓಱೆಯಾವು',
'ಔಂಡುಗರ್ಚು',
'ಔಡಲೆಣ್ಣೆ',
'ಔಡುಗಚ್ಚು',
'ಔಡುಗಡಿ',
'ಔಡುಗರ್ಚು',
'ಔತಣಿಸು',
'ಔತ್ತರೇಯ',
'ಔದನಿಕ',
'ಔದರಿಕ',
'ಔದಾರಿಕ',
'ಔದಾರಿಯ',
'ಔದಾಸೀನ',
'ಔದಾಸೀನ್ಯ',
'ಔದುಂಬರ',
'ಔದ್ಯಮಿಕ',
'ಔದ್ಯೋಗಿಕ',
'ಔದ್ವಾಹಿಕ',
'ಔಪದಾಹ್ಯ',
'ಔಪಯಿಕ',
'ಔಪವಾಹ್ಯ',
'ಔಪಾಖ್ಯಾನ',
'ಔಪಾಧಿಕ',
'ಔಪಾಸನ',
'ಔಪಾಸನೆ',
'ಔರಭ್ರಕ',
'ಔರಸತ್ವ',
'ಔರ್ವಾನಲ',
'ಔಷಧಿಕ',
'ಔಷಧಿಸು',
'ಔಷಧೀಯ',
'ಔಷ್ಠ್ಯವರ್ಣ',
'ಕಂಕಟಕ',
'ಕಂಕಣಕೈ',
'ಕಂಕಣಿಕೆ',
'ಕಂಕತಿಕೆ',
'ಕಂಕಪತ್ರ',
'ಕಂಕಪುಳ್ಳೆ',
'ಕಂಕಬಲೆ',
'ಕಂಕಮಾಲೆ',
'ಕಂಕಮುಖ',
'ಕಂಕರಿಕೆ',
'ಕಂಕವಕ್ಕಿ',
'ಕಂಕಾರಕ',
'ಕಂಕಾರಿಸು',
'ಕಂಕಾಲತೆ',
'ಕಂಕಾಳಿಕೆ',
'ಕಂಕುಣಿಕೆ',
'ಕಂಖಾರಿಸು',
'ಕಂಗದಿರು',
'ಕಂಗಱುಬು',
'ಕಂಗಾಣಿಕೆ',
'ಕಂಗಾಣಿಸು',
'ಕಂಗಿಡಿಸು',
'ಕಂಗುಮಾಡು',
'ಕಂಗುರುಡ',
'ಕಂಗುರುಡು',
'ಕಂಗುಱುಹು',
'ಕಂಗೆಡಿಕೆ',
'ಕಂಗೆಡಿಸು',
'ಕಂಗೊಳಿಸು',
'ಕಂಚಗಾರ್ತಿ',
'ಕಂಚಗಾಱ',
'ಕಂಚನಾರ',
'ಕಂಚವಾಳ',
'ಕಂಚಳಿಗೆ',
'ಕಂಚಿನಿಂಬೆ',
'ಕಂಚಿಮೇಕೆ',
'ಕಂಚಿಮೊಗ್ಗೆ',
'ಕಂಚಿವಾಳ',
'ಕಂಚಿಸಿಂಬು',
'ಕಂಚಿಹಸು',
'ಕಂಚಿಹುಲ್ಲು',
'ಕಂಚುಕಿತ',
'ಕಂಚುಕೀಂದ್ರ',
'ಕಂಚುಗರ್ಚು',
'ಕಂಚುಗಾರ',
'ಕಂಚುಗಾಱ',
'ಕಂಚುಗುಳಿ',
'ಕಂಚುತನ',
'ಕಂಚುಪ್ರಾಂತಿ',
'ಕಂಚುರಳಿ',
'ಕಂಚುವಱೆ',
'ಕಂಚುವಾಳ',
'ಕಂಚುಳಿಕ',
'ಕಂಚುಳಿಕೆ',
'ಕಂಜಗರ್ಭ',
'ಕಂಜಜಾತ',
'ಕಂಜನಾಭ',
'ಕಂಜನಾಲ',
'ಕಂಜನಾಳ',
'ಕಂಜನೇತ್ರ',
'ಕಂಜನೇತ್ರೆ',
'ಕಂಜಪತ್ರ',
'ಕಂಜಪ್ರಿಯ',
'ಕಂಜಬಾಣ',
'ಕಂಜಭವ',
'ಕಂಜಮುಖಿ',
'ಕಂಜರಜ',
'ಕಂಜರೀಟ',
'ಕಂಜವೈರಿ',
'ಕಂಜಶರ',
'ಕಂಜಸಖ',
'ಕಂಜಸದ್ಮೆ',
'ಕಂಜಾಕರ',
'ಕಂಜಾಲಯ',
'ಕಂಜಾಸನ',
'ಕಂಜೇಕ್ಷಣೆ',
'ಕಂಜೊಡರ್',
'ಕಂಜೋದಯ',
'ಕಂಜೋದರ',
'ಕಂಜೋದ್ಭವ',
'ಕಂಟಕಟಿ',
'ಕಂಟಕಾರಿ',
'ಕಂಟಕಿತ',
'ಕಂಟಣಿಸು',
'ಕಂಟಪುಚ್ಚೆ',
'ಕಂಟಮೊನೆ',
'ಕಂಟವಣ',
'ಕಂಟಳಿಸು',
'ಕಂಟಿರಾಯಿ',
'ಕಂಟೆರುಗು',
'ಕಂಠಕಾರಿ',
'ಕಂಠಕುಬ್ಜ',
'ಕಂಠಗತ',
'ಕಂಠಗ್ರಹ',
'ಕಂಠಣಿಸು',
'ಕಂಠತ್ರಾಣ',
'ಕಂಠದಾನ',
'ಕಂಠನಾಳ',
'ಕಂಠಪಟ್ಟಿ',
'ಕಂಠಪತ್ರ',
'ಕಂಠಪಾಠ',
'ಕಂಠಪೂರ್ತಿ',
'ಕಂಠಬಂಧ',
'ಕಂಠಭೂಷೆ',
'ಕಂಠಮಣಿ',
'ಕಂಠಮಾಲೆ',
'ಕಂಠರುತಿ',
'ಕಂಠಲಗ್ನ',
'ಕಂಠವಣ',
'ಕಂಠವಸ್ತ್ರ',
'ಕಂಠಶುದ್ಧಿ',
'ಕಂಠಶೋಷ',
'ಕಂಠಸೂತ್ರ',
'ಕಂಠಿಸರ',
'ಕಂಠೀರವ',
'ಕಂಠೀರಾಯಿ',
'ಕಂಠೀಸರ',
'ಕಂಡಣಿಸು',
'ಕಂಡಪಟ',
'ಕಂಡಪಿಳ್ಳೆ',
'ಕಂಡಬಟ್ಟೆ',
'ಕಂಡಬತ್ತ',
'ಕಂಡಭೂಮಿ',
'ಕಂಡರಣೆ',
'ಕಂಡರಿಸು',
'ಕಂಡವಡ',
'ಕಂಡವರು',
'ಕಂಡವಿಡು',
'ಕಂಡಳಿಸು',
'ಕಂಡಾಪಟ್ಟೆ',
'ಕಂಡಾಬಟ್ಟೆ',
'ಕಂಡಿಬೀೞ್',
'ಕಂಡಿವಲ್',
'ಕಂಡಿವಲೆ',
'ಕಂಡುಕಟ್ಟು',
'ಕಂಡುವೋಗು',
'ಕಂಡೂಯನ',
'ಕಂಡೂಹೃತಿ',
'ಕಂಡೆಕುತ್ತೆ',
'ಕಂತರಿಸು',
'ಕಂತುಕುಂತ',
'ಕಂತುಕೇಳಿ',
'ಕಂತುಚೇತ',
'ಕಂತುಧೇನು',
'ಕಂತುಪಿತ',
'ಕಂತೆಭಿಕ್ಷ',
'ಕಂತೆವೊರೆ',
'ಕಂದಗಡ್ಡೆ',
'ಕಂದಗೆಡ್ಡೆ',
'ಕಂದಣಿಕೆ',
'ಕಂದನಾಡಿ',
'ಕಂದಮುೂಲ',
'ಕಂದರಾಲ',
'ಕಂದರೊಳ',
'ಕಂದರ್ಪಕ',
'ಕಂದಳಿಕೆ',
'ಕಂದಳಿತ',
'ಕಂದಳಿಸು',
'ಕಂದಾಚಾರ',
'ಕಂದಾವುಜ',
'ಕಂದುಕುಂದು',
'ಕಂದುಬೆಳ್ಳಿ',
'ಕಂದುಮೋಱೆ',
'ಕಂದುವೆಳ್ಳಿ',
'ಕಂದೆರವಿ',
'ಕಂದೆಱಪು',
'ಕಂದೆಱವಿ',
'ಕಂದೆಱವು',
'ಕಂದೆಱವೆ',
'ಕಂದೆಱಸು',
'ಕಂದೊಳಲ್',
'ಕಂದೊಳಲಿ',
'ಕಂದೊಳಲು',
'ಕಂದೊಳಸು',
'ಕಂಪಂಗೊಳ್',
'ಕಂಪಕಾರಿ',
'ಕಂಪಣಿಗ',
'ಕಂಪನಾಂಕ',
'ಕಂಪಲರ್',
'ಕಂಪವಾತ',
'ಕಂಪವೇಱು',
'ಕಂಪಾಡಗ',
'ಕಂಪಿಡಿಗು',
'ಕಂಪುಗಾರ್ತಿ',
'ಕಂಪುಗಾಳಿ',
'ಕಂಪುಗಾಱ',
'ಕಂಪುಗೊಳ್',
'ಕಂಪುಗೋಲ್',
'ಕಂಪುಗೋಲ',
'ಕಂಪುಣುಬ',
'ಕಂಪುದೀವು',
'ಕಂಪುನೀರ್',
'ಕಂಪುವಡೆ',
'ಕಂಪುವಳ',
'ಕಂಪುವಿಡಿ',
'ಕಂಪುವೆಱು',
'ಕಂಪುವೇಱು',
'ಕಂಬಗಟ್ಟು',
'ಕಂಬಪೂಜೆ',
'ಕಂಬವಲಿ',
'ಕಂಬಶಾಸ್ತ್ರ',
'ಕಂಬಾರಿಕೆ',
'ಕಂಬಾವತಿ',
'ಕಂಬಿಕಾತಿ',
'ಕಂಬಿಕಾಱ',
'ಕಂಬಿಪಂಚೆ',
'ಕಂಬಿಪೇಟ',
'ಕಂಬಿಮೇಣ',
'ಕಂಬಿಯಚ್ಚು',
'ಕಂಬಿವಾರು',
'ಕಂಬಿಸೀರೆ',
'ಕಂಬುಕಂಠ',
'ಕಂಬುಕಂಠಿ',
'ಕಂಬುಕಂಠೆ',
'ಕಂಬುಗಲ',
'ಕಂಬುಗಳ',
'ಕಂಬುಗ್ರೀವ',
'ಕಂಬುಗ್ರೀವೆ',
'ಕಂಬುಧರ',
'ಕಂಬೆಳಕು',
'ಕಂಬೆಳಗು',
'ಕಂಬೊಣರ್',
'ಕಂಭಕುಂಭ',
'ಕಂಭಗಟ್ಟು',
'ಕಂಭಪೂಜೆ',
'ಕಂಭವತಿ',
'ಕಂಭವಲಿ',
'ಕಂಭಶಕ್ತಿ',
'ಕಂಭಾವತಿ',
'ಕಂಸತಾಲ',
'ಕಂಸತಾಳ',
'ಕಂಸಾರಕ',
'ಕಂಸಾಳಕ',
'ಕಃಪದಾರ್ಥ',
'ಕಕಂಟಕ',
'ಕಕಬಿಕ',
'ಕಕಮಕ',
'ಕಕಮುಖ',
'ಕಕಲಾತಿ',
'ಕಕಲಾತೆ',
'ಕಕವಕ',
'ಕಕುಂದರ',
'ಕಕುಂದಱಿ',
'ಕಕುತ್ಸ್ಥಜ',
'ಕಕುದ್ಗಿರಿ',
'ಕಕುದ್ಮತಿ',
'ಕಕುಲತೆ',
'ಕಕುಲಾತಿ',
'ಕಕುಲಾತೆ',
'ಕಕುಳತೆ',
'ಕಕೋಂಟಕಿ',
'ಕಕ್ಕರನೆ',
'ಕಕ್ಕರಿಕೆ',
'ಕಕ್ಕಲಾತಿ',
'ಕಕ್ಕವಡ',
'ಕಕ್ಕವಿಕ್ಕಿ',
'ಕಕ್ಕಸತೆ',
'ಕಕ್ಕಾಬಿಕಿ',
'ಕಕ್ಕಾಬಿಕ್ಕ',
'ಕಕ್ಕಾಬಿಕ್ಕಿ',
'ಕಕ್ಕಾಬಿಕ್ಕು',
'ಕಕ್ಕಾವಾಡೆ',
'ಕಕ್ಕಾವಿಕ್ಕಿ',
'ಕಕ್ಕುಂದಱಿ',
'ಕಕ್ಕುಂಬಳ',
'ಕಕ್ಕುಗಡಿ',
'ಕಕ್ಕುಗತ್ತಿ',
'ಕಕ್ಕುಗುರು',
'ಕಕ್ಕುದಱಿ',
'ಕಕ್ಕುಬಿಕ್ಕು',
'ಕಕ್ಕುಲತೆ',
'ಕಕ್ಕುಲಾತಿ',
'ಕಕ್ಕುಲಾತೆ',
'ಕಕ್ಕುಲಿತೆ',
'ಕಕ್ಕುಲಿಸು',
'ಕಕ್ಕೆಕೂಳು',
'ಕಕ್ಕೊರಲೆ',
'ಕಕ್ಕೊರಳೆ',
'ಕಕ್ಕೋರಲೆ',
'ಕಕ್ಕೋಲಕ',
'ಕಕ್ಖವಡ',
'ಕಕ್ಷಕಾರ',
'ಕಕ್ಷಪಾಲ',
'ಕಕ್ಷಪಾಳ',
'ಕಕ್ಷಪುಟ',
'ಕಕ್ಷಮೂಲ',
'ಕಕ್ಷಾಂತರ',
'ಕಕ್ಷಾಪಟ',
'ಕಕ್ಷಿಗಾರ',
'ಕಕ್ಷ್ಯಾಂತರ',
'ಕಗ್ಗಂಬಳಿ',
'ಕಗ್ಗತ್ತಲ್',
'ಕಗ್ಗತ್ತಲು',
'ಕಗ್ಗತ್ತಲೆ',
'ಕಗ್ಗದಾರಿ',
'ಕಗ್ಗಬುದ್ಧಿ',
'ಕಗ್ಗರಿಕು',
'ಕಗ್ಗಾವಳ',
'ಕಗ್ಗುಂಬಳ',
'ಕಗ್ಗೊರಲೆ',
'ಕಗ್ಗೊಲೆಯ',
'ಕಗ್ಗೊಱಡು',
'ಕಗ್ಗೊಱಲೆ',
'ಕಚಕಚ',
'ಕಚಕುಳಿ',
'ಕಚಕ್ಕನೆ',
'ಕಚಗುಳಿ',
'ಕಚಗ್ರಹ',
'ಕಚಪಕ್ಷ',
'ಕಚಪಚ',
'ಕಚಪಾಶ',
'ಕಚಪಿಚಿ',
'ಕಚಬಂಧ',
'ಕಚಭರ',
'ಕಚಭಾರ',
'ಕಚಮಾಲ',
'ಕಚವಾಸು',
'ಕಚಾಕಚಿ',
'ಕಚಾಯಿಸು',
'ಕಚಾರಿಕೆ',
'ಕಚಾವಳಿ',
'ಕಚ್ಚಕುಳಿ',
'ಕಚ್ಚಗುಡ',
'ಕಚ್ಚಗುಳಿ',
'ಕಚ್ಚಲಿಗೆ',
'ಕಚ್ಚೞಿಕೆ',
'ಕಚ್ಚುಗಾಲಿ',
'ಕಚ್ಚುತುಟಿ',
'ಕಚ್ಚುನೀರು',
'ಕಚ್ಚುಮಾತು',
'ಕಚ್ಚುಹಣ',
'ಕಚ್ಚುಹಾಕು',
'ಕಚ್ಚೆಗಟ್ಟು',
'ಕಚ್ಚೆನಿಸು',
'ಕಚ್ಚೆಪಂಚೆ',
'ಕಚ್ಚೆಶುದ್ಧಿ',
'ಕಚ್ಛಾವಾಲ',
'ಕಜಗಟ್ಲೆ',
'ಕಜಗೆಡು',
'ಕಜೆಅಕ್ಕಿ',
'ಕಜೆಗೆಡು',
'ಕಜೆಹಿಡಿ',
'ಕಜ್ಜಗಲಿ',
'ಕಜ್ಜಗುಟ್ಟು',
'ಕಜ್ಜಲಾದ್ರಿ',
'ಕಜ್ಜವೆಣ್ಣು',
'ಕಜ್ಜಿಗಟ್ಟು',
'ಕಜ್ಜಿಗಿಡ',
'ಕಜ್ಜಿಗುತ್ತ',
'ಕಜ್ಜಿಗೋಳಿ',
'ಕಜ್ಜಿಹುಳು',
'ಕಟಂಜನ',
'ಕಟಂಬರೆ',
'ಕಟಂಭರೆ',
'ಕಟಕಟ',
'ಕಟಕಟಾ',
'ಕಟಕಟಿ',
'ಕಟಕಟೆ',
'ಕಟಕಾಂಬ',
'ಕಟಕಾರ',
'ಕಟಕಿಗ',
'ಕಟಕಿಸು',
'ಕಟಗುಟ್ಟು',
'ಕಟಗೋಲು',
'ಕಟತಟ',
'ಕಟಪಟಿ',
'ಕಟಪಟೆ',
'ಕಟಪಯ',
'ಕಟಪಿಟಿ',
'ಕಟಬಾಯಿ',
'ಕಟವಾಣಿ',
'ಕಟವಾಯ್',
'ಕಟವಾಯಿ',
'ಕಟಾಂಜನ',
'ಕಟಾಕಟಿ',
'ಕಟಾಕ್ಷಿಸು',
'ಕಟಾಯಿಸು',
'ಕಟಿಗಣ್ಮು',
'ಕಟಿಚಲ್ಲಿ',
'ಕಟಿಜೀವ',
'ಕಟಿತಟ',
'ಕಟಿದಘ್ನ',
'ಕಟಿನರ',
'ಕಟಿಪಟಿ',
'ಕಟಿಪಿಟಿ',
'ಕಟಿಬಂಧ',
'ಕಟಿಲಿಂಗ',
'ಕಟಿಲ್ಲಕ',
'ಕಟಿವಸ್ತ್ರ',
'ಕಟಿವಾತ',
'ಕಟಿವೃತ್ತ',
'ಕಟಿಶಿರ',
'ಕಟಿಶೀರ್ಷ',
'ಕಟಿಸಾನು',
'ಕಟಿಸೂತ್ರ',
'ಕಟಿಸ್ನಾನ',
'ಕಟಿಹಸ್ತ',
'ಕಟಿಹಸ್ತೆ',
'ಕಟುಂಬಣ',
'ಕಟುಂಭರೆ',
'ಕಟುಕಲು',
'ಕಟುಗೂಳು',
'ಕಟುತುಂಬಿ',
'ಕಟುತ್ರಯ',
'ಕಟುಫಲ',
'ಕಟುವಿಷ',
'ಕಟ್ಟಂಪಾಡು',
'ಕಟ್ಟಂಬಲಿ',
'ಕಟ್ಟಕಡೆ',
'ಕಟ್ಟಕೊನೆ',
'ಕಟ್ಟಕ್ಕರು',
'ಕಟ್ಟಕ್ಕರೆ',
'ಕಟ್ಟಕ್ಕಱ್',
'ಕಟ್ಟಚ್ಚರಿ',
'ಕಟ್ಟಡಕ',
'ಕಟ್ಟಡವಿ',
'ಕಟ್ಟಡಸು',
'ಕಟ್ಟಣಕ',
'ಕಟ್ಟದಟು',
'ಕಟ್ಟನೀತಿ',
'ಕಟ್ಟಪುಣಿ',
'ಕಟ್ಟಪ್ಪಣೆ',
'ಕಟ್ಟಪ್ಪುಣಿ',
'ಕಟ್ಟಮಸ್ತು',
'ಕಟ್ಟರಣ್ಯ',
'ಕಟ್ಟರಸ',
'ಕಟ್ಟರಸು',
'ಕಟ್ಟವತ್ತಿ',
'ಕಟ್ಟವರೆ',
'ಕಟ್ಟವಾಳಿ',
'ಕಟ್ಟಶನ',
'ಕಟ್ಟಹುಣಿ',
'ಕಟ್ಟಳಲು',
'ಕಟ್ಟಳವಿ',
'ಕಟ್ಟಳಿಪು',
'ಕಟ್ಟಳುಕು',
'ಕಟ್ಟಳ್ಕಱ್',
'ಕಟ್ಟಱವೆ',
'ಕಟ್ಟಱಿಕೆ',
'ಕಟ್ಟೞ್ಕರ್',
'ಕಟ್ಟಾಚಾರ',
'ಕಟ್ಟಾಡಿಕೆ',
'ಕಟ್ಟಾಡಿಸು',
'ಕಟ್ಟಾಣತಿ',
'ಕಟ್ಟಾಯತ',
'ಕಟ್ಟಾಯತಿ',
'ಕಟ್ಟಾಯಸ',
'ಕಟ್ಟಾಸರು',
'ಕಟ್ಟಾಸಱು',
'ಕಟ್ಟಾಸುರ',
'ಕಟ್ಟಾಳ್ತನ',
'ಕಟ್ಟಿದಿರ್',
'ಕಟ್ಟಿದಿರು',
'ಕಟ್ಟಿನಿಯ',
'ಕಟ್ಟಿರಿತ',
'ಕಟ್ಟಿರುವೆ',
'ಕಟ್ಟಿರುಳು',
'ಕಟ್ಟಿಸುಗೆ',
'ಕಟ್ಟಿಱುಂಪೆ',
'ಕಟ್ಟಿಱುಪೆ',
'ಕಟ್ಟಿಱುವೆ',
'ಕಟ್ಟಿಱುಹೆ',
'ಕಟ್ಟುಂಗರೆ',
'ಕಟ್ಟುಂಗೂಳ್',
'ಕಟ್ಟುಂಜೋಳ',
'ಕಟ್ಟುಂದೆಱು',
'ಕಟ್ಟುಂಪಡು',
'ಕಟ್ಟುಂಬಗೆ',
'ಕಟ್ಟುಂಬಣ',
'ಕಟ್ಟುಕಂಬ',
'ಕಟ್ಟುಕಡೆ',
'ಕಟ್ಟುಕತೆ',
'ಕಟ್ಟುಕಥೆ',
'ಕಟ್ಟುಗಂಭ',
'ಕಟ್ಟುಗಾಣ',
'ಕಟ್ಟುಗಾಳ',
'ಕಟ್ಟುಗುಂಡು',
'ಕಟ್ಟುಗುಣ',
'ಕಟ್ಟುಗೂಳಿ',
'ಕಟ್ಟುಗೂಳು',
'ಕಟ್ಟುಗೆಡೆ',
'ಕಟ್ಟುಗೊತ್ತು',
'ಕಟ್ಟುಗೊಳ್',
'ಕಟ್ಟುಗೊಳ್ಳು',
'ಕಟ್ಟುಗ್ಗರ',
'ಕಟ್ಟುತೆಱು',
'ಕಟ್ಟುತ್ತರ',
'ಕಟ್ಟುತ್ತಾರ',
'ಕಟ್ಟುದಾರ',
'ಕಟ್ಟುದೆರೆ',
'ಕಟ್ಟುನಿಟ್ಟು',
'ಕಟ್ಟುನೇಣು',
'ಕಟ್ಟುಪಡು',
'ಕಟ್ಟುಪಡೆ',
'ಕಟ್ಟುಪಾಟು',
'ಕಟ್ಟುಪಾಡು',
'ಕಟ್ಟುಪಾಸ',
'ಕಟ್ಟುಬಣ್ಣ',
'ಕಟ್ಟುಬಾಕಿ',
'ಕಟ್ಟುಬಾಳು',
'ಕಟ್ಟುಬೀಳು',
'ಕಟ್ಟುಬುತ್ತಿ',
'ಕಟ್ಟುಬ್ಬಟೆ',
'ಕಟ್ಟುಬ್ಬರ',
'ಕಟ್ಟುಬ್ಬಸ',
'ಕಟ್ಟುಬ್ಬೆಗ',
'ಕಟ್ಟುಮರ',
'ಕಟ್ಟುಮಸ್ತಿ',
'ಕಟ್ಟುಮಸ್ತು',
'ಕಟ್ಟುಮಾನ್ಯ',
'ಕಟ್ಟುಮುಟ್ಟು',
'ಕಟ್ಟುಮುಡಿ',
'ಕಟ್ಟುಮೆಟ್ಟು',
'ಕಟ್ಟುರಸ',
'ಕಟ್ಟುರೋಗ',
'ಕಟ್ಟುರ್ಬಟೆ',
'ಕಟ್ಟುಲುಹು',
'ಕಟ್ಟುವಡು',
'ಕಟ್ಟುವಡೆ',
'ಕಟ್ಟುವಣ್ಣ',
'ಕಟ್ಟುವರ್',
'ಕಟ್ಟುವಳಿ',
'ಕಟ್ಟುವಾಸ',
'ಕಟ್ಟುವೆಱು',
'ಕಟ್ಟುಸಿರು',
'ಕಟ್ಟುಹಾವು',
'ಕಟ್ಟುಹುರಿ',
'ಕಟ್ಟುಹೋರಿ',
'ಕಟ್ಟುಱುಕ',
'ಕಟ್ಟೂಳಿಗ',
'ಕಟ್ಟೆಗಟ್ಟು',
'ಕಟ್ಟೆಚ್ಚರ',
'ಕಟ್ಟೆಚ್ಚಱು',
'ಕಟ್ಟೆಪಡು',
'ಕಟ್ಟೆಮನೆ',
'ಕಟ್ಟೆರಕ',
'ಕಟ್ಟೆರಕೆ',
'ಕಟ್ಟೆವಲೆ',
'ಕಟ್ಟೆಸಕ',
'ಕಟ್ಟೆಸಗು',
'ಕಟ್ಟೆಸರ್',
'ಕಟ್ಟೆಸುಗೆ',
'ಕಟ್ಟೆಹಾಯ್',
'ಕಟ್ಟೆಹೋಗು',
'ಕಟ್ಟೆಱಕ',
'ಕಟ್ಟೆಱಗು',
'ಕಟ್ಟೇಕಾಂತ',
'ಕಟ್ಟೇರ್ವಡೆ',
'ಕಟ್ಟೊಟ್ಟಜೆ',
'ಕಟ್ಟೊಡತಿ',
'ಕಟ್ಟೊಡೆಯ',
'ಕಟ್ಟೊಣಗು',
'ಕಟ್ಟೊತ್ತಂಬ',
'ಕಟ್ಟೊತ್ತರ',
'ಕಟ್ಟೊತ್ತಾಯ',
'ಕಟ್ಟೊನಲ್',
'ಕಟ್ಟೊಲುಮೆ',
'ಕಟ್ಟೊಱತೆ',
'ಕಟ್ಟೊಱಲ್',
'ಕಟ್ಟೋಗರ',
'ಕಟ್ಟೋಲಗ',
'ಕಟ್ಟೋವತ',
'ಕಟ್ಲೆಜ್ವರ',
'ಕಠರಿಗೆ',
'ಕಠಿಣತೆ',
'ಕಠಿನತೆ',
'ಕಠಿನತ್ವ',
'ಕಠಿಲ್ಲಕ',
'ಕಠೋರತೆ',
'ಕಡಂಗರ',
'ಕಡಂಗಿಸು',
'ಕಡಂಗುಡು',
'ಕಡಂಗೊಡು',
'ಕಡಂಗೊಳ್',
'ಕಡಂದುರು',
'ಕಡಂದುಱು',
'ಕಡಂಬಡೆ',
'ಕಡಂಬಳ',
'ಕಡಂಮಾಡು',
'ಕಡಕಟ್ಟು',
'ಕಡಕತ್ತಿ',
'ಕಡಕಲ್',
'ಕಡಕಲು',
'ಕಡಕೋಲ',
'ಕಡಗಟ್ಟು',
'ಕಡಗತ್ತಿ',
'ಕಡಗದ',
'ಕಡಗಲು',
'ಕಡಗಾರ',
'ಕಡಗುಡು',
'ಕಡಗೊಡು',
'ಕಡಗೊಳ್',
'ಕಡಗೋಲು',
'ಕಡಚಲು',
'ಕಡಚ್ಚಿಲು',
'ಕಡಜಾಜಿ',
'ಕಡತೂಸು',
'ಕಡತೇರು',
'ಕಡದುಱು',
'ಕಡಬಡ್ಡಿ',
'ಕಡಲಹೂ',
'ಕಡಲಾಡಿ',
'ಕಡಲಾಣ್ಮ',
'ಕಡಲಾನೆ',
'ಕಡಲಾಮೆ',
'ಕಡಲಿಡು',
'ಕಡಲಿಳಿ',
'ಕಡಲುರಿ',
'ಕಡಲೋಟ',
'ಕಡಲ್ಕರೆ',
'ಕಡಲ್ಗಟ್ಟು',
'ಕಡಲ್ಗರೆ',
'ಕಡಲ್ಗಳ್ಳ',
'ಕಡಲ್ಗಿಚ್ಚು',
'ಕಡಲ್ತಡಿ',
'ಕಡಲ್ತೆಂಗು',
'ಕಡಲ್ವರಿ',
'ಕಡಲ್ವುಗು',
'ಕಡವಂಚಿ',
'ಕಡವಡ',
'ಕಡವರ',
'ಕಡವಲ್',
'ಕಡವಲ',
'ಕಡವಸ',
'ಕಡವಳ',
'ಕಡವಾಯ್',
'ಕಡವಾಲ',
'ಕಡವಾಳ',
'ಕಡವುಲ್',
'ಕಡಸಿಗೆ',
'ಕಡಹಾಲೆ',
'ಕಡಾಖಡಿ',
'ಕಡಾಯಿಸು',
'ಕಡಾವಿಗೆ',
'ಕಡಿಕಂಡ',
'ಕಡಿಕತ್ತಿ',
'ಕಡಿಕೆಯ್',
'ಕಡಿಕೊಂಬು',
'ಕಡಿಖಂಡ',
'ಕಡಿಗಂಟು',
'ಕಡಿಗಂಡ',
'ಕಡಿಗಡಿ',
'ಕಡಿಗತ್ತಿ',
'ಕಡಿಗಲ್',
'ಕಡಿಗಳೆ',
'ಕಡಿಗೆಯ್',
'ಕಡಿಗೊಂಬು',
'ಕಡಿಗೊಳ್',
'ಕಡಿತಲೆ',
'ಕಡಿತಿನ್',
'ಕಡಿದಬ್ಬೆ',
'ಕಡಿದಲೆ',
'ಕಡಿದಾಬೆ',
'ಕಡಿದೋಳು',
'ಕಡಿನುಚ್ಚು',
'ಕಡಿಪೆಂಡೆ',
'ಕಡಿಮಾಡು',
'ಕಡಿಮೆಟ್ಟು',
'ಕಡಿಯಕ್ಕಿ',
'ಕಡಿಯಣ',
'ಕಡಿಯಾಣ',
'ಕಡಿಯಾಣಿ',
'ಕಡಿಯಾಲ',
'ಕಡಿಯಿಸು',
'ಕಡಿವಡೆ',
'ಕಡಿವಣ',
'ಕಡಿವಾಗ',
'ಕಡಿವಾಡಿ',
'ಕಡಿವಾಣ',
'ಕಡಿವಾರು',
'ಕಡಿವಾಳ',
'ಕಡಿವಾಱು',
'ಕಡಿವೆಱು',
'ಕಡಿವೋಗು',
'ಕಡುಂಬುಟ್ಟು',
'ಕಡುಕಂಪು',
'ಕಡುಕಷ್ಟ',
'ಕಡುಕಳ್ಳ',
'ಕಡುಕಾಯ್',
'ಕಡುಕುರು',
'ಕಡುಕೆಂಕ',
'ಕಡುಕೆಯ್',
'ಕಡುಕೊರ್ಬು',
'ಕಡುಕೋಪ',
'ಕಡುಗಂಟು',
'ಕಡುಗಂದಿ',
'ಕಡುಗಂದು',
'ಕಡುಗಂಪು',
'ಕಡುಗಜ್ಜ',
'ಕಡುಗಟ್ಟ',
'ಕಡುಗಯ್ಮೆ',
'ಕಡುಗರ್ಪು',
'ಕಡುಗಲಿ',
'ಕಡುಗಳ್ಳ',
'ಕಡುಗಾಂಪ',
'ಕಡುಗಾಡಿ',
'ಕಡುಗಾಪು',
'ಕಡುಗಾಯ್',
'ಕಡುಗಾಯಿ',
'ಕಡುಗಾಯ್ಪು',
'ಕಡುಗಾಸು',
'ಕಡುಗಾಹು',
'ಕಡುಗಾಳ',
'ಕಡುಗಾಳಿ',
'ಕಡುಗಿಚ್ಚು',
'ಕಡುಗುಜ್ಜ',
'ಕಡುಗುಜ್ಜು',
'ಕಡುಗುಣ್ಪು',
'ಕಡುಗುತ್ತ',
'ಕಡುಗುದಿ',
'ಕಡುಗುರು',
'ಕಡುಗೂಪ',
'ಕಡುಗೂರ್',
'ಕಡುಗೂರ್ಪ',
'ಕಡುಗೂರ್ಪು',
'ಕಡುಗೂರ್ಮೆ',
'ಕಡುಗೆಂಟು',
'ಕಡುಗೆಂಪು',
'ಕಡುಗೆಡೆ',
'ಕಡುಗೆಯ್',
'ಕಡುಗೆಯ್ಯ',
'ಕಡುಗೇಡಿ',
'ಕಡುಗೇಡು',
'ಕಡುಗೊಬ್ಬು',
'ಕಡುಗೊರ್ಬು',
'ಕಡುಗೊರ್ವು',
'ಕಡುಗೋಡು',
'ಕಡುಗೋಪ',
'ಕಡುಗೋಪಿ',
'ಕಡುಚಳಿ',
'ಕಡುಚಾಗ',
'ಕಡುಚಾಗಿ',
'ಕಡುಚಿಬ್ಬು',
'ಕಡುಚೆನ್ನ',
'ಕಡುಚೆನ್ನೆ',
'ಕಡುಚೆಲ್ವ',
'ಕಡುಚೆಲ್ವು',
'ಕಡುಚೆಲ್ವೆ',
'ಕಡುಜಡ',
'ಕಡುಜರ',
'ಕಡುಜಲ',
'ಕಡುಜವ',
'ಕಡುಜಾಣ್',
'ಕಡುಜಾಣ',
'ಕಡುಜಾಣೆ',
'ಕಡುಜಾಣ್ಮೆ',
'ಕಡುಜ್ವರ',
'ಕಡುಝಳ',
'ಕಡುಟಕ್ಕು',
'ಕಡುತಣ್ಪು',
'ಕಡುತಪ',
'ಕಡುತಿಣ್ಣ',
'ಕಡುದಣ್ಪು',
'ಕಡುದನಿ',
'ಕಡುದರ್ಪ',
'ಕಡುದಿಟ',
'ಕಡುದಿಟ್ಟ',
'ಕಡುದಿಟ್ಟೆ',
'ಕಡುದೀರ್ಘ',
'ಕಡುದುಷ್ಟ',
'ಕಡುದುಷ್ಟೆ',
'ಕಡುದುಱು',
'ಕಡುದೆಳ್ಪು',
'ಕಡುದೇಸಿ',
'ಕಡುದೈನ್ಯ',
'ಕಡುನಂಜು',
'ಕಡುನಂಟ',
'ಕಡುನನ್ನಿ',
'ಕಡುನಾಣ್',
'ಕಡುನಾಣ್ಚು',
'ಕಡುನಿಂದೆ',
'ಕಡುನಿದ್ದೆ',
'ಕಡುನಿದ್ರೆ',
'ಕಡುನೀಱ',
'ಕಡುನೀಱೆ',
'ಕಡುನುಡಿ',
'ಕಡುನುಣ್ಪು',
'ಕಡುನೆಂಟ',
'ಕಡುನೆಱ',
'ಕಡುನೇಮ',
'ಕಡುನೇಹ',
'ಕಡುನೋವು',
'ಕಡುಪಂಥ',
'ಕಡುಪಾಪ',
'ಕಡುಪಾಪಿ',
'ಕಡುಪಾಱು',
'ಕಡುಪೆಂಪು',
'ಕಡುಪೆರ್ಚು',
'ಕಡುಪೊಲ್ಲ',
'ಕಡುಬಂಜೆ',
'ಕಡುಬಂಡೆ',
'ಕಡುಬಲಿ',
'ಕಡುಬಲ್ಮೆ',
'ಕಡುಬಿಣ್ಪು',
'ಕಡುಬಿಸಿ',
'ಕಡುಬೇಗ',
'ಕಡುಬೇನೆ',
'ಕಡುಭರ',
'ಕಡುಭಾಷೆ',
'ಕಡುಮದ',
'ಕಡುಮನ',
'ಕಡುಮಾನಿ',
'ಕಡುಮುನಿ',
'ಕಡುಮುಪ್ಪು',
'ಕಡುಮುಳಿ',
'ಕಡುಮೂರ್ಖ',
'ಕಡುಮೂರ್ಖೆ',
'ಕಡುಮೆಚ್ಚು',
'ಕಡುಮೇಳ',
'ಕಡುಮೊನೆ',
'ಕಡುಮೋನ',
'ಕಡುಮೋಹ',
'ಕಡುರಯ್ಯ',
'ಕಡುರಾಗ',
'ಕಡುರಾಗಿ',
'ಕಡುರೂಪು',
'ಕಡುಲಲ್ಲೆ',
'ಕಡುಲಾಗು',
'ಕಡುಲುಳಿ',
'ಕಡುಲೇಸು',
'ಕಡುಲೋಭ',
'ಕಡುಲೋಭಿ',
'ಕಡುವಂದೆ',
'ಕಡುವಗೆ',
'ಕಡುವಳೆ',
'ಕಡುವೞೆ',
'ಕಡುವಾಳ್',
'ಕಡುವಿಡಿ',
'ಕಡುವಿಣ್ಪು',
'ಕಡುವಿಲ್ಲ',
'ಕಡುವಿಷ',
'ಕಡುವೀೞು',
'ಕಡುವೃದ್ಧ',
'ಕಡುವೃದ್ಧೆ',
'ಕಡುವೆಂಕೆ',
'ಕಡುವೆರ್ಚು',
'ಕಡುವೆಸ',
'ಕಡುವೆಳೆ',
'ಕಡುವೆಳ್ಪು',
'ಕಡುವೇಗ',
'ಕಡುವೇಗಿ',
'ಕಡುವೇನೆ',
'ಕಡುವೈರ',
'ಕಡುವೈರಿ',
'ಕಡುವೊಂಗು',
'ಕಡುವೊತ್ತು',
'ಕಡುಶಿಶು',
'ಕಡುಶೋಕ',
'ಕಡುಶೌರ್ಯ',
'ಕಡುಸನ್ನಿ',
'ಕಡುಸವಿ',
'ಕಡುಸಾರೆ',
'ಕಡುಸಾಸ',
'ಕಡುಸಿಗ್ಗು',
'ಕಡುಸುಖ',
'ಕಡುಸುರ್ಕು',
'ಕಡುಸೇದೆ',
'ಕಡುಸೊಂದಿ',
'ಕಡುಸೊಂಪು',
'ಕಡುಸೊಕ್ಕು',
'ಕಡುಸೊಗ',
'ಕಡುಸೊರ್ಕು',
'ಕಡುಸೋಲ',
'ಕಡುಹಂಚಿ',
'ಕಡುಹಾಕು',
'ಕಡುಹಿಮ',
'ಕಡೆಕಂಜಿ',
'ಕಡೆಕಳ',
'ಕಡೆಕಾಲ',
'ಕಡೆಕಾಲು',
'ಕಡೆಕೊಡಿ',
'ಕಡೆಗಂತಿ',
'ಕಡೆಗಂದಿ',
'ಕಡೆಗಟ್ಟು',
'ಕಡೆಗಟ್ಲು',
'ಕಡೆಗಡಿ',
'ಕಡೆಗಣ್',
'ಕಡೆಗಣಿ',
'ಕಡೆಗಣ್ಚು',
'ಕಡೆಗಣ್ಣಿ',
'ಕಡೆಗಣ್ಣು',
'ಕಡೆಗಳ',
'ಕಡೆಗಾಣ್',
'ಕಡೆಗಾಣು',
'ಕಡೆಗಾಯು',
'ಕಡೆಗಾಲ್',
'ಕಡೆಗಾಲ',
'ಕಡೆಗಿರ್ಚು',
'ಕಡೆಗೀಲ್',
'ಕಡೆಗೀಲು',
'ಕಡೆಗುಂಡು',
'ಕಡೆಗೂಸು',
'ಕಡೆಗೆಡು',
'ಕಡೆಗೆನ್ನೆ',
'ಕಡೆಗೆಯ್',
'ಕಡೆಗೊಳ್',
'ಕಡೆಗೊಳ್ಳಿ',
'ಕಡೆಗೊಳ್ಳು',
'ಕಡೆಗೋಡಿ',
'ಕಡೆಗೋಡು',
'ಕಡೆಗೋಲ್',
'ಕಡೆಗೋಲು',
'ಕಡೆಚಲು',
'ಕಡೆಚೋರಿ',
'ಕಡೆಚ್ಚಿಲು',
'ಕಡೆತೇರು',
'ಕಡೆದಾವು',
'ಕಡೆದೆಗೆ',
'ಕಡೆದೋಱು',
'ಕಡೆನಾಡು',
'ಕಡೆನುಡಿ',
'ಕಡೆನೇಣ್',
'ಕಡೆನೋಟ',
'ಕಡೆಪಟ್ಟು',
'ಕಡೆಪಡು',
'ಕಡೆಪರಿ',
'ಕಡೆಪೊಳ್ತು',
'ಕಡೆಬಾಳು',
'ಕಡೆಮಾಡು',
'ಕಡೆಮಿಣಿ',
'ಕಡೆಮುಕ್ಕು',
'ಕಡೆಮುಟ್ಟ',
'ಕಡೆಮುಟ್ಟು',
'ಕಡೆಮೂಲೆ',
'ಕಡೆಯಾಣಿ',
'ಕಡೆಯಿಕ್ಕು',
'ಕಡೆಯಿಸು',
'ಕಡೆಯೆಯ್ದು',
'ಕಡೆವಡು',
'ಕಡೆವಲಿ',
'ಕಡೆವಲ್ಲು',
'ಕಡೆವಳ್ಳಿ',
'ಕಡೆವಾಯ್',
'ಕಡೆವಾಯಿ',
'ಕಡೆವೀಡು',
'ಕಡೆವುಟ್ಟು',
'ಕಡೆವೊನ್',
'ಕಡೆಸರಿ',
'ಕಡೆಸಲ್',
'ಕಡೆಸಾರ್',
'ಕಡೆಸಾಲ್',
'ಕಡೆಸಿಡಿ',
'ಕಡೆಸಿಲ್ಕು',
'ಕಡೆಹರಿ',
'ಕಡೆಹಾಯು',
'ಕಡೆಹಾಸು',
'ಕಡೆಹುಟ್ಟು',
'ಕಡೆಹೋಗು',
'ಕಡ್ಡಣಿಗೆ',
'ಕಡ್ಡಣಿಸು',
'ಕಡ್ಡತನ',
'ಕಡ್ಡಯಿಸು',
'ಕಡ್ಡವಣೆ',
'ಕಡ್ಡಿಉಪ್ಪು',
'ಕಡ್ಡಿಕೀಟ',
'ಕಡ್ಡಿಕುತ್ತು',
'ಕಡ್ಡಿಗೀರು',
'ಕಡ್ಡಿಗೊಳ್',
'ಕಡ್ಡಿಪುಡಿ',
'ಕಡ್ಡಿಬತ್ತಿ',
'ಕಡ್ಡಿಮಟ್ಟ',
'ಕಡ್ಡಿವಲೆ',
'ಕಡ್ಡಿಹಿಡಿ',
'ಕಡ್ಡಿಹುಳು',
'ಕಡ್ಡುವರ್',
'ಕಡ್ರಬಡ್ರ',
'ಕಡ್ರುಮುಡ್ರು',
'ಕಡ್ಲೆನಾರು',
'ಕಡ್ಲೆಮುಳ್ಳು',
'ಕಣಕಡ್ಡಿ',
'ಕಣಕಣ',
'ಕಣಕಣಿ',
'ಕಣಕಾಲ್',
'ಕಣಕಾಲು',
'ಕಣಕಿಸು',
'ಕಣಕೋಱ',
'ಕಣಗಲ',
'ಕಣಗಲು',
'ಕಣಗಿಲ್',
'ಕಣಗಿಲಿ',
'ಕಣಗಿಲು',
'ಕಣಗಿಲೆ',
'ಕಣತಲೆ',
'ಕಣಪಾದ',
'ಕಣಬಲಿ',
'ಕಣಶಿಲೆ',
'ಕಣಾವಳಿ',
'ಕಣಿಕಣಿ',
'ಕಣಿಕಾಱ',
'ಕಣಿಗಾತಿ',
'ಕಣಿಗಾರ',
'ಕಣಿಗಾರ್ತಿ',
'ಕಣಿಗಿಲ್',
'ಕಣಿಗಿಲ',
'ಕಣಿಗಿಲೆ',
'ಕಣಿಪೆಲೆ',
'ಕಣಿವೇಳು',
'ಕಣಿವೇೞ್',
'ಕಣಿಹಾಕು',
'ಕಣಿಹೇಳು',
'ಕಣುಕಟ್ಟು',
'ಕಣುಗಾಣ್',
'ಕಣುಗಿಡಿ',
'ಕಣುಗೆಡು',
'ಕಣುದಣಿ',
'ಕಣುದಪ್ಪು',
'ಕಣುಮಿಂಚು',
'ಕಣುವಳೆ',
'ಕಣುಶೂಲೆ',
'ಕಣುಸನ್ನೆ',
'ಕಣೆಕಾಲ್',
'ಕಣೆಗಱೆ',
'ಕಣೆಗಿಲೆ',
'ಕಣೆಗೊಳ್',
'ಕಣೆಗೊಳ್ಳು',
'ಕಣೆದುಡು',
'ಕಣೆದೊಡು',
'ಕಣೆಯಿಸು',
'ಕಣೆಯೇಱು',
'ಕಣೆವೋಗು',
'ಕಣೆಹಂದಿ',
'ಕಣ್ಕದಿರು',
'ಕಣ್ಕಪಟ',
'ಕಣ್ಕಪ್ಪಡಿ',
'ಕಣ್ಕಪ್ಪಡೆ',
'ಕಣ್ಕಾಡಿಗೆ',
'ಕಣ್ಕಾಣಿಕೆ',
'ಕಣ್ಕಿಸುರ್',
'ಕಣ್ಕಿಸುರ',
'ಕಣ್ಕಿಸುರು',
'ಕಣ್ಕುಟಕ',
'ಕಣ್ಕುಟಿಕೆ',
'ಕಣ್ಕುಟಿಗೆ',
'ಕಣ್ಕುಟಿಲ',
'ಕಣ್ಕುಟಿಲೆ',
'ಕಣ್ಕುಣಿಕೆ',
'ಕಣ್ಕೆರಿಶಿ',
'ಕಣ್ಕೋರೈಸು',
'ಕಣ್ಗದಿರ್',
'ಕಣ್ಗಱುಬು',
'ಕಣ್ಗಾಣಿಸು',
'ಕಣ್ಗಾವಲು',
'ಕಣ್ಗಿಸುರ್',
'ಕಣ್ಗಿಸುರು',
'ಕಣ್ಗುಣಿಕೆ',
'ಕಣ್ಗುರುಡ',
'ಕಣ್ಗುರುಡಿ',
'ಕಣ್ಗುಳಿಕೆ',
'ಕಣ್ಗೆಡಿಸು',
'ಕಣ್ಗೆದಱು',
'ಕಣ್ಗೆವರ್',
'ಕಣ್ಗೊಳಿಸು',
'ಕಣ್ಚಿವುಟು',
'ಕಣ್ಜೊಡರ್',
'ಕಣ್ಣಂಜಿಕೆ',
'ಕಣ್ಣಡಕ',
'ಕಣ್ಣಡಿಗ',
'ಕಣ್ಣಡಿಯ',
'ಕಣ್ಣರಳು',
'ಕಣ್ಣರಿಕೆ',
'ಕಣ್ಣವಲ್ಲಿ',
'ಕಣ್ಣಸೆಳೆ',
'ಕಣ್ಣಳತೆ',
'ಕಣ್ಣಳವಿ',
'ಕಣ್ಣಳವು',
'ಕಣ್ಣಳಿಸು',
'ಕಣ್ಣಱಸು',
'ಕಣ್ಣಾಮುಚ್ಚಿ',
'ಕಣ್ಣಾಮುಚ್ಚೆ',
'ಕಣ್ಣಾಸರು',
'ಕಣ್ಣಿಗಣ್',
'ಕಣ್ಣಿವಲೆ',
'ಕಣ್ಣೀರಿಡು',
'ಕಣ್ಣುಕಟ್ಟು',
'ಕಣ್ಣುಕಪ್ಪು',
'ಕಣ್ಣುಕಾಣು',
'ಕಣ್ಣುಕಿಸಿ',
'ಕಣ್ಣುಕುಕ್ಕು',
'ಕಣ್ಣುಗಂಡಿ',
'ಕಣ್ಣುಗಾಣು',
'ಕಣ್ಣುಗುಡ್ಡು',
'ಕಣ್ಣುಗುಡ್ಡೆ',
'ಕಣ್ಣುಗೊಂಬೆ',
'ಕಣ್ಣುಚೂಟಿ',
'ಕಣ್ಣುಜಾಡು',
'ಕಣ್ಣುಜಾಡ್ಯ',
'ಕಣ್ಣುತುಂಬು',
'ಕಣ್ಣುತೆರೆ',
'ಕಣ್ಣುದಕ',
'ಕಣ್ಣುನೂತಿ',
'ಕಣ್ಣುಪಟ್ಟಿ',
'ಕಣ್ಣುಪಟ್ಟು',
'ಕಣ್ಣುಪಡೆ',
'ಕಣ್ಣುಪರೆ',
'ಕಣ್ಣುಪಿಚ್ಚು',
'ಕಣ್ಣುಪುಟ್ಟಿ',
'ಕಣ್ಣುಪೂತಿ',
'ಕಣ್ಣುಬಡಿ',
'ಕಣ್ಣುಬಿಡು',
'ಕಣ್ಣುಬೇನೆ',
'ಕಣ್ಣುಬೊಟ್ಟು',
'ಕಣ್ಣುಮಂಜು',
'ಕಣ್ಣುಮಣಿ',
'ಕಣ್ಣುಮುಚ್ಚು',
'ಕಣ್ಣುಮುಟ್ಟ',
'ಕಣ್ಣುಮೆತ್ತು',
'ಕಣ್ಣುರುಪು',
'ಕಣ್ಣುಶೂಲೆ',
'ಕಣ್ಣುಸನ್ನೆ',
'ಕಣ್ಣುಸಿಳ್ಳು',
'ಕಣ್ಣುಸೆಳೆ',
'ಕಣ್ಣುಸೋಗೆ',
'ಕಣ್ಣುಹೂವು',
'ಕಣ್ಣೆಂಜಲ್',
'ಕಣ್ಣೆಂಜಲ',
'ಕಣ್ಣೆಂಜಲು',
'ಕಣ್ಣೆಸರು',
'ಕಣ್ಣೆಱಕ',
'ಕಣ್ತಡಿಕೆ',
'ಕಣ್ತಪ್ಪಿಸು',
'ಕಣ್ತಿಮಿರ್',
'ಕಣ್ದಣಿವು',
'ಕಣ್ದಿಳುವು',
'ಕಣ್ದೆರಪು',
'ಕಣ್ದೆರೆತ',
'ಕಣ್ದೆಱಪು',
'ಕಣ್ದೆಱವಿ',
'ಕಣ್ದೆಱವು',
'ಕಣ್ದೆಱವೆ',
'ಕಣ್ದೊಡವು',
'ಕಣ್ದೊಳಲ್',
'ಕಣ್ದೊಳಲು',
'ಕಣ್ದೊೞಲಿ',
'ಕಣ್ಬಡಿಕ',
'ಕಣ್ಬಡಿಗ',
'ಕಣ್ಬಡಿಸು',
'ಕಣ್ಬರಿವು',
'ಕಣ್ಬಸಿವು',
'ಕಣ್ಬುೞುಂಕು',
'ಕಣ್ಬೆಳಕು',
'ಕಣ್ಬೆಳಗು',
'ಕಣ್ಬೊಣರ್',
'ಕಣ್ಬೊಳೆಪು',
'ಕಣ್ಮಗುೞ್',
'ಕಣ್ಮಲರ್',
'ಕಣ್ಮಲರು',
'ಕಣ್ಮಾಲಿಸು',
'ಕಣ್ಮಿಟುಕು',
'ಕಣ್ಮಿಡುಕ',
'ಕಣ್ಮುಚ್ಚಲೆ',
'ಕಣ್ವಾಯ್ವಿಡು',
'ಕತವಾಸು',
'ಕತಹಿಡಿ',
'ಕತಿಗೊಳ್',
'ಕತಿಪಯ',
'ಕತಿಯಿಸು',
'ಕತೆಕಟ್ಟು',
'ಕತೆಗಾರ',
'ಕತೆಗಾರ್ತಿ',
'ಕತೆಗುಟ್ಟು',
'ಕತೆಮುಗಿ',
'ಕತೆವೇೞ್',
'ಕತ್ತಗಿನಿ',
'ಕತ್ತಗಿರಿ',
'ಕತ್ತರಣೆ',
'ಕತ್ತರಿಕೆ',
'ಕತ್ತರಿಗೆ',
'ಕತ್ತರಿಗೈ',
'ಕತ್ತರಿಸು',
'ಕತ್ತಲಿಡು',
'ಕತ್ತಲಿಸು',
'ಕತ್ತಳಿಕೆ',
'ಕತ್ತಿಗೀಳು',
'ಕತ್ತಿಮತ',
'ಕತ್ತಿಮಾತು',
'ಕತ್ತಿಮೀನು',
'ಕತ್ತುಪಟ್ಟಿ',
'ಕತ್ತುಮೂಳಿ',
'ಕತ್ತೆಗರೆ',
'ಕತ್ತೆಗರ್ಬ',
'ಕತ್ತೆಗಾಲ',
'ಕತ್ತೆಗಾಹಿ',
'ಕತ್ತೆಗಿವಿ',
'ಕತ್ತೆಬಾಲ',
'ಕಥಕಳಿ',
'ಕಥಕ್ಕಲಿ',
'ಕಥಕ್ಕಳಿ',
'ಕಥನಗೈ',
'ಕಥಯಿಸು',
'ಕಥಾಂತರ',
'ಕಥಾನಕ',
'ಕಥಾನ್ವಯ',
'ಕಥಾಪತಿ',
'ಕಥಾಭಿತ್ತಿ',
'ಕಥಾಮುಖ',
'ಕಥಾಲಾಪ',
'ಕಥಾವಣಿ',
'ಕಥಾಸಾರ',
'ಕಥಾಳಾಪ',
'ಕಥಿಯಿಸು',
'ಕಥೆಗೇಳ್',
'ಕಥೆವೇೞ್',
'ಕದಂದೆಗೆ',
'ಕದಂಬಕ',
'ಕದಡಿಕೆ',
'ಕದಡಿಸು',
'ಕದಡೇಳ್',
'ಕದಡೇೞ್',
'ಕದನಾರ್ಥಿ',
'ಕದನಿಸು',
'ಕದಬದ',
'ಕದಬೊದ',
'ಕದರಿಸು',
'ಕದರ್ಥನ',
'ಕದರ್ಥಿಸು',
'ಕದಲಿಸು',
'ಕದಲೊಡೆ',
'ಕದವಿಕ್ಕು',
'ಕದವಿಡು',
'ಕದಳಿಕೆ',
'ಕದಾಚಿತ್',
'ಕದಿರೇಱು',
'ಕದಿರ್ಗಂಪು',
'ಕದಿರ್ಜೊನ್ನ',
'ಕದಿರ್ವರಿ',
'ಕದುಕಿಸು',
'ಕದುಕಿಱಿ',
'ಕದುಬಿಸು',
'ಕದ್ದಳಿಗ',
'ಕದ್ದಾಲಿಕೆ',
'ಕದ್ದಿಂಗಳು',
'ಕದ್ದಿಕಾಱ',
'ಕದ್ದೆಯಿಸು',
'ಕನ್‍ಕುಟ್ಲು',
'ಕನಂಬರಿ',
'ಕನಕಾಂಗಿ',
'ಕನಕಾದ್ರಿ',
'ಕನಕಾಯ',
'ಕನಗಾಣ್',
'ಕನರ್ಗಾಯ್',
'ಕನಲಿಕೆ',
'ಕನಲಿಸು',
'ಕನಲ್ಗಿಡಿ',
'ಕನವರ',
'ಕನವರಿ',
'ಕನಸಾನ್',
'ಕನಸಿಗ',
'ಕನಸುಗ',
'ಕನಸುಣಿ',
'ಕನಿಕರ',
'ಕನಿಕಿಸು',
'ಕನಿಮನೆ',
'ಕನಿಷ್ಠತೆ',
'ಕನಿಷ್ಠಿಕೆ',
'ಕನೀನಿಕೆ',
'ಕನೀಯಸ',
'ಕನೀಯಸಿ',
'ಕನೀಯಾಂಸ',
'ಕನ್ನಕಂಡಿ',
'ಕನ್ನಗಂಡಿ',
'ಕನ್ನಗತ್ತಿ',
'ಕನ್ನಗಳ್ಳ',
'ಕನ್ನಗಾರ',
'ಕನ್ನಗೊಯ್ಕ',
'ಕನ್ನಡಕ',
'ಕನ್ನಡಿಗ',
'ಕನ್ನಡಿಚ',
'ಕನ್ನಡಿತಿ',
'ಕನ್ನಡಿಸು',
'ಕನ್ನಪುರ',
'ಕನ್ನಮಿಕ್ಕು',
'ಕನ್ನಮಿಡು',
'ಕನ್ನವಚ್ಚ',
'ಕನ್ನವಟ್ಟ',
'ಕನ್ನವಡ',
'ಕನ್ನವಿಕ್ಕು',
'ಕನ್ನವಿಡು',
'ಕನ್ನವಿಲು',
'ಕನ್ನವುರ',
'ಕನ್ನಾಗರು',
'ಕನ್ನಾಟಕ',
'ಕನ್ನೆಗಳ್ಳ',
'ಕನ್ನೆಗಾಲ',
'ಕನ್ನೆಗಿಡ',
'ಕನ್ನೆಗ್ಗಿಲು',
'ಕನ್ನೆತನ',
'ಕನ್ನೆತೊಟ್ಟಿ',
'ಕನ್ನೆತ್ತರ್',
'ಕನ್ನೆನೆಲ',
'ಕನ್ನೆಮಾಡ',
'ಕನ್ನೆಯಾಡು',
'ಕನ್ನೆಯಾವು',
'ಕನ್ನೆಯ್ದಿಲ್',
'ಕನ್ನೆಯ್ದಿಲು',
'ಕನ್ನೆವಾಡ',
'ಕನ್ನೆವೆಣ್',
'ಕನ್ನೆವೆಣ್ಣು',
'ಕನ್ನೆವೆಱೆ',
'ಕನ್ನೆವೇಟ',
'ಕನ್ನೆಸೊಪ್ಪು',
'ಕನ್ನೈದಿಲ್',
'ಕನ್ನೈದಿಲು',
'ಕನ್ನೈದಿಲೆ',
'ಕನ್ಯಾದಾನ',
'ಕನ್ಯಾಧನ',
'ಕನ್ಯಾಬ್ರತ',
'ಕನ್ಯಾರತ್ನ',
'ಕನ್ಯಾರಾಶಿ',
'ಕನ್ಯಾವಜ್ರ',
'ಕನ್ಯಾವ್ರತ',
'ಕನ್ಯಾಶುಲ್ಕ',
'ಕನ್ಯಾಸೆರೆ',
'ಕನ್ಸುರಗಿ',
'ಕಪಕಪ',
'ಕಪಟತ್ವ',
'ಕಪಟಸ್ಥ',
'ಕಪಟಸ್ಥೆ',
'ಕಪಟಾತ್ಮ',
'ಕಪಟಿಕ',
'ಕಪಟಿಸು',
'ಕಪರ್ದಕ',
'ಕಪರ್ದಿಕ',
'ಕಪಾಲಿನಿ',
'ಕಪಿಂಜಲ',
'ಕಪಿಂಜಳ',
'ಕಪಿಕಚ್ಚು',
'ಕಪಿಕೇತು',
'ಕಪಿಚೇಷ್ಟೆ',
'ಕಪಿತಾನ್',
'ಕಪಿಧ್ವಜ',
'ಕಪಿಬಲೆ',
'ಕಪಿಮುಷ್ಟಿ',
'ಕಪಿವಲ್ಲಿ',
'ಕಪಿವೃಕ್ಷ',
'ಕಪಿಶೀರ್ಷ',
'ಕಪೀತನ',
'ಕಪೋತಕ',
'ಕಪೋತಾಂಘ್ರಿ',
'ಕಪೋತಿಕೆ',
'ಕಪ್ಪಂಗವಿ',
'ಕಪ್ಪಂಗುಡು',
'ಕಪ್ಪಂಗೊಳ್',
'ಕಪ್ಪಕಾಣ್ಕೆ',
'ಕಪ್ಪಕುರು',
'ಕಪ್ಪಗಸೆ',
'ಕಪ್ಪಗೊಡು',
'ಕಪ್ಪಡರು',
'ಕಪ್ಪಡಿಗ',
'ಕಪ್ಪನೆಯ',
'ಕಪ್ಪರಳ್ಳಿ',
'ಕಪ್ಪರಿಸು',
'ಕಪ್ಪಲ್ನರಿ',
'ಕಪ್ಪುಕರ್ಣಿ',
'ಕಪ್ಪುಕುರ್ಣಿ',
'ಕಪ್ಪುಗಣ್',
'ಕಪ್ಪುಗಾಡು',
'ಕಪ್ಪುಗುೞಿ',
'ಕಪ್ಪುಜಾಲಿ',
'ಕಪ್ಪುದೋಱು',
'ಕಪ್ಪುಬೊಬ್ಳಿ',
'ಕಪ್ಪುರಾಳ',
'ಕಪ್ಪುವಡೆ',
'ಕಪ್ಪುವೀಳ್',
'ಕಪ್ಪುಸಂತೆ',
'ಕಪ್ಪುಹಣ',
'ಕಪ್ಪೆಕೀಲಿ',
'ಕಪ್ಪೆಕೆಮ್ಮು',
'ಕಪ್ಪೆಗಿಡ',
'ಕಪ್ಪೆಗೂಡು',
'ಕಪ್ಪೆಗೋಲು',
'ಕಪ್ಪೆಚಿಪ್ಪು',
'ಕಪ್ಪೆಬೀಗ',
'ಕಪ್ಪೆಹುಣ್ಣು',
'ಕಬಕ್ಕನೆ',
'ಕಬರಿಸು',
'ಕಬಲನ',
'ಕಬಲಿಕ್ಕು',
'ಕಬಲಿತ',
'ಕಬಳನ',
'ಕಬಳಿತ',
'ಕಬಳಿಸು',
'ಕಬಾಡಿಗ',
'ಕಬಿಲೆತ್ತಿ',
'ಕಬೂಲಾತಿ',
'ಕಬೆಕ್ಕೋಲು',
'ಕಬ್ಬಗಣ್',
'ಕಬ್ಬಡವಿ',
'ಕಬ್ಬರಿಸು',
'ಕಬ್ಬಲಸು',
'ಕಬ್ಬಲಿಗ',
'ಕಬ್ಬಸುರಿ',
'ಕಬ್ಬಿಕೋಲು',
'ಕಬ್ಬಿಗಿತಿ',
'ಕಬ್ಬಿಲತಿ',
'ಕಬ್ಬಿಲಿಗ',
'ಕಬ್ಬಿಲಿತಿ',
'ಕಬ್ಬಿಸಿಲು',
'ಕಬ್ಬುಗುಳ್',
'ಕಬ್ಬುದೋಂಟ',
'ಕಬ್ಬುಬಿಲ್ಲ',
'ಕಬ್ಬುಬೆಕ್ಕು',
'ಕಬ್ಬುರಗ',
'ಕಬ್ಬುಲಿಗ',
'ಕಬ್ಬುವಿಲ್',
'ಕಬ್ಬುವಿಲು',
'ಕಬ್ಬುವಿಲ್ಲ',
'ಕಬ್ಬುವಿಲ್ಲೆ',
'ಕಬ್ಬುಳಿಗ',
'ಕಬ್ಬೆಗಲ್',
'ಕಬ್ಹಲಸು',
'ಕಮಂಡಲ',
'ಕಮಂಡಲು',
'ಕಮಕಾಳ',
'ಕಮತಿಗ',
'ಕಮನೀಯ',
'ಕಮನೀಯೆ',
'ಕಮರಕ',
'ಕಮರಾಕ್ಷಿ',
'ಕಮರಿಕೆ',
'ಕಮರಿಸು',
'ಕಮಲಜ',
'ಕಮಲಾಕ್ಷ',
'ಕಮಲಾಕ್ಷಿ',
'ಕಮಲಾಪ್ತ',
'ಕಮಲಾರಿ',
'ಕಮಲಾಳಿ',
'ಕಮಲಿನಿ',
'ಕಮಲೇಶ',
'ಕಮಾಯಿಷಿ',
'ಕಮಾಯಿಸು',
'ಕಮ್ಮಂಗಣೆ',
'ಕಮ್ಮಂಗೋಲ್',
'ಕಮ್ಮಂಗೋಲ',
'ಕಮ್ಮಗಂಧಿ',
'ಕಮ್ಮಗಗ್ರೆ',
'ಕಮ್ಮಗಾರ',
'ಕಮ್ಮಗೋಲ್',
'ಕಮ್ಮಗೋಲ',
'ಕಮ್ಮಟಿಗ',
'ಕಮ್ಮತಿಗ',
'ಕಮ್ಮನಾಗು',
'ಕಮ್ಮಯಿಸು',
'ಕಮ್ಮರಲ್',
'ಕಮ್ಮರಲು',
'ಕಮ್ಮರಿಗೆ',
'ಕಮ್ಮಲರ್',
'ಕಮ್ಮಲರು',
'ಕಮ್ಮಲ್ಲಿಗೆ',
'ಕಮ್ಮವಟ್ಟಿ',
'ಕಮ್ಮವಟ್ಟೆ',
'ಕಮ್ಮವಷ್ಟಿ',
'ಕಮ್ಮವಳ',
'ಕಮ್ಮವಿಲ್ಲ',
'ಕಮ್ಮಸರ',
'ಕಮ್ಮಱಿಕೆ',
'ಕಮ್ಮಱಿತಿ',
'ಕಮ್ಮಾಯಿಲ',
'ಕಮ್ಮಾರತಿ',
'ಕಮ್ಮಾರಿಕೆ',
'ಕಮ್ಮಾರಿಣಿ',
'ಕಮ್ಮಾಱಿಕೆ',
'ಕಮ್ಮೆಲರ್',
'ಕಯಕೋಡು',
'ಕಯಿಧಾರೆ',
'ಕಯಿನೀರ್',
'ಕಯಿಪಾಡಿ',
'ಕಯಿರೊಕ್ಕ',
'ಕಯ್ದುಳಿಗ',
'ಕಯ್ಪೆಸರ',
'ಕಯ್ಪೆಸೊರೆ',
'ಕಯ್ಪೆಸೋರೆ',
'ಕಯ್ಬರ್ದುಕು',
'ಕಯ್ಯೊಡನೆ',
'ಕರಂಗೊಳ್',
'ಕರಂಗೋಲು',
'ಕರಂಜಕ',
'ಕರಂಜಿಕೆ',
'ಕರಂಜಿಗೆ',
'ಕರಂಡಕ',
'ಕರಂಡಗೆ',
'ಕರಂಡಿಕೆ',
'ಕರಂಡಿಗೆ',
'ಕರಂಬಳ',
'ಕರಂಬಿಡಿ',
'ಕರಂಭಕ',
'ಕರಂಭತ್ತ',
'ಕರಕಂದು',
'ಕರಕಣಿ',
'ಕರಕನ್ನ',
'ಕರಕರ',
'ಕರಕರಿ',
'ಕರಕರೆ',
'ಕರಕಷ್ಟ',
'ಕರಕಾನಿ',
'ಕರಕೂನ',
'ಕರಕೆಲೆ',
'ಕರಗಂಧ',
'ಕರಗತ',
'ಕರಗನ್ನೆ',
'ಕರಗಲ',
'ಕರಗಲು',
'ಕರಗಲೆ',
'ಕರಗಸ',
'ಕರಗಹಿ',
'ಕರಗಳ್ಳ',
'ಕರಗಾಣ',
'ಕರಗಿಸು',
'ಕರಗುಹ',
'ಕರಚುರಿ',
'ಕರಜಾಂಶು',
'ಕರಜಾತ',
'ಕರಜಿಗೆ',
'ಕರಟಕ',
'ಕರಟಲು',
'ಕರಡಕ',
'ಕರಡಕ್ಕಿ',
'ಕರಡಗೆ',
'ಕರಡಚ್ಚು',
'ಕರಡಿಕೆ',
'ಕರಡಿಗೆ',
'ಕರಡೋಲೆ',
'ಕರಣಿಕ',
'ಕರಣಿಗ',
'ಕರಣಿಗೆ',
'ಕರಣೀಕ',
'ಕರಣೀಯ',
'ಕರತನ',
'ಕರತಲ',
'ಕರತಳ',
'ಕರತಾಲ',
'ಕರತಾಳ',
'ಕರತಾಳಿ',
'ಕರತಾಳೆ',
'ಕರತ್ರಾಣ',
'ಕರದಂಟು',
'ಕರದಂಡ',
'ಕರದಮ',
'ಕರದೀಪ',
'ಕರದುಂದು',
'ಕರಧೈರ್ಯ',
'ಕರನಾಶ',
'ಕರನ್ಯಾಸ',
'ಕರಪತ್ರ',
'ಕರಪಲ',
'ಕರಪಾಣಿ',
'ಕರಪಾತ್ರ',
'ಕರಪಾತ್ರೆ',
'ಕರಪಾದಿ',
'ಕರಪಾಲ',
'ಕರಪೀಠ',
'ಕರಪುಟ',
'ಕರಪುರಿ',
'ಕರಬಾಲ',
'ಕರಬಾಳೆ',
'ಕರಬೂಜ',
'ಕರಬೂಜು',
'ಕರಬೋನ',
'ಕರಭೂಜ',
'ಕರಮರೆ',
'ಕರಮರ್ದ',
'ಕರಮರ್ದಿ',
'ಕರಮಾನ್ಯ',
'ಕರಮುಂಡು',
'ಕರಮುಕ್ತ',
'ಕರಯಿಸು',
'ಕರರುಹ',
'ಕರಲಾಗು',
'ಕರಲೇಸು',
'ಕರವಂಡಿ',
'ಕರವಂದ',
'ಕರವಟ್ಟಿ',
'ಕರವತ',
'ಕರವತಿ',
'ಕರವತ್ತ',
'ಕರವತ್ತಿ',
'ಕರವದಿ',
'ಕರವಸ್ತ್ರ',
'ಕರವಳ',
'ಕರವಳಿ',
'ಕರವಾಯಿ',
'ಕರವಾಲ',
'ಕರವಾಳ್',
'ಕರವಾಳ',
'ಕರವಾಳಿ',
'ಕರವಾಳು',
'ಕರವಿಡಿ',
'ಕರವೀರ',
'ಕರವೇೞಿ',
'ಕರಶಾಖೆ',
'ಕರಸಾಣೆ',
'ಕರಸಾಲ',
'ಕರಸ್ಥಲ',
'ಕರಸ್ಥಳ',
'ಕರಹಟ',
'ಕರಹಟ್ಟಿ',
'ಕರಹಡ',
'ಕರಹತ್ತ',
'ಕರಹಾಟ',
'ಕರಹಾಡ',
'ಕರಳಿಕೆ',
'ಕರೞ್ಮಣಿ',
'ಕರಾಂಗುಲಿ',
'ಕರಾಂಗುಳಿ',
'ಕರಾಚೂರಿ',
'ಕರಾಚೂರು',
'ಕರಾಜಿನ',
'ಕರಾಡಸ್ಥ',
'ಕರಾತಳ',
'ಕರಾಧಾನ',
'ಕರಾಮತಿ',
'ಕರಾಮತ್ತು',
'ಕರಾವಳಿ',
'ಕರಾಳತೆ',
'ಕರಿಂಕೇಳ್',
'ಕರಿಂಕೇೞ್',
'ಕರಿಂಚಿಲ್ಲು',
'ಕರಿಕಕ್ಕೆ',
'ಕರಿಕಾಚಿ',
'ಕರಿಕಾಯಿ',
'ಕರಿಕಾರೆ',
'ಕರಿಕಾಳಿ',
'ಕರಿಕೃತ್ತಿ',
'ಕರಿಕೇೞ್',
'ಕರಿಕೊಮ್ಮೆ',
'ಕರಿಕೊಳ್ಳಿ',
'ಕರಿಗಂದು',
'ಕರಿಗಬ್ಬು',
'ಕರಿಗಾಡು',
'ಕರಿಗಾಲ',
'ಕರಿಗಾಲು',
'ಕರಿಗಿಡ',
'ಕರಿಗಿಡ್ಡ',
'ಕರಿಗುಡ್ಡು',
'ಕರಿಗುಳ್ಳ',
'ಕರಿಗೇರು',
'ಕರಿಗೈಯ',
'ಕರಿಗೊನ್ನೆ',
'ಕರಿಗೊಳ್',
'ಕರಿಗೊಳ್ಳಿ',
'ಕರಿಗೊಳ್ಳು',
'ಕರಿಗೋಣಿ',
'ಕರಿಘಟೆ',
'ಕರಿಚಳ್ಳೆ',
'ಕರಿಚಿಕ್ಕು',
'ಕರಿಚುಳ್ಳಿ',
'ಕರಿಚೆಂಡು',
'ಕರಿಚೇಳು',
'ಕರಿಜಾಣ',
'ಕರಿಜಾಲಿ',
'ಕರಿಜಿಗಿ',
'ಕರಿಜೊಂಡು',
'ಕರಿಜ್ವರ',
'ಕರಿಟಳ',
'ಕರಿತಾಳೆ',
'ಕರಿತುಂಬೆ',
'ಕರಿದಂತ',
'ಕರಿದಳ',
'ಕರಿದಿನ',
'ಕರಿದೋಱು',
'ಕರಿಧೂಪ',
'ಕರಿನೀರು',
'ಕರಿನೀಲಿ',
'ಕರಿನೆಕ್ಕಿ',
'ಕರಿನೆರೆ',
'ಕರಿನೆಲ',
'ಕರಿನೆಲ್ಲಿ',
'ಕರಿಬಂಟ',
'ಕರಿಬಟ್ಟು',
'ಕರಿಬಳ್ಳಿ',
'ಕರಿಬಾಯಿ',
'ಕರಿಬಾಳ',
'ಕರಿಬಾಳೆ',
'ಕರಿಬೀಜ',
'ಕರಿಬುಜ',
'ಕರಿಬೇಲ',
'ಕರಿಬೇವು',
'ಕರಿಬೋಳ',
'ಕರಿಮಣಿ',
'ಕರಿಮತ್ತಿ',
'ಕರಿಮರ',
'ಕರಿಮೀನು',
'ಕರಿಮುಖ',
'ಕರಿಮುರಿ',
'ಕರಿಮುಸ್ತೆ',
'ಕರಿಮೊಗ',
'ಕರಿಮೊಳೆ',
'ಕರಿಯಾಲಿ',
'ಕರಿಯಾಲೆ',
'ಕರಿಲಕ್ಕಿ',
'ಕರಿಲೊಕ್ಕಿ',
'ಕರಿವಕ್ಕಿ',
'ಕರಿವೆಟ್ಟು',
'ಕರಿವೋಗು',
'ಕರಿವ್ಯಾಧಿ',
'ಕರಿಸಾಲು',
'ಕರಿಸಾವೆ',
'ಕರಿಹಂಚಿ',
'ಕರಿಹಂಬು',
'ಕರಿಹತ್ತಿ',
'ಕರಿಹಾವು',
'ಕರಿಹಾಸು',
'ಕರಿಹಿಂಡಿ',
'ಕರಿಹುಲ್ಲು',
'ಕರಿಹೂಲಿ',
'ಕರಿಹೊನ್ನೆ',
'ಕರೀರಕ',
'ಕರುಕಂಭ',
'ಕರುಕಲು',
'ಕರುಕಿಸು',
'ಕರುಗಟ್ಟು',
'ಕರುಗಲ್ಲು',
'ಕರುಗಾಣ',
'ಕರುಗೊಳ್',
'ಕರುಗೋಷ್ಟಿ',
'ಕರುಟಲು',
'ಕರುಣಾಂಬು',
'ಕರುಣಾರ್ತ',
'ಕರುಣಾಲು',
'ಕರುಣಾಳು',
'ಕರುಣಿಸು',
'ಕರುನಾಡು',
'ಕರುನಾಣ',
'ಕರುಮಾಡ',
'ಕರುವಾಡ',
'ಕರುವಾಡಿ',
'ಕರುವಾಡು',
'ಕರುವಿಕ್ಕು',
'ಕರುವಿಡು',
'ಕರುವೀನ್',
'ಕರುವೆಸ',
'ಕರುವೆಱು',
'ಕರುಷಕ',
'ಕರುಷಿತ',
'ಕರುಹಟ್ಟಿ',
'ಕರುಹಾಕು',
'ಕರುಹಾಲೆ',
'ಕರುಳಿರಿ',
'ಕರುಳಿಱಿ',
'ಕರೆಕಟ್ಟು',
'ಕರೆಕರೆ',
'ಕರೆಕಾಡು',
'ಕರೆಗಂಟೆ',
'ಕರೆಗಣ',
'ಕರೆಗಲ್ಲು',
'ಕರೆಗಾರ',
'ಕರೆಗೊಯ್',
'ಕರೆಚೆಂಡು',
'ಕರೆದುಡ್ಡು',
'ಕರೆನಾಡು',
'ಕರೆಬಂಟ',
'ಕರೆಬರೆ',
'ಕರೆಮರ',
'ಕರೆಮರೆ',
'ಕರೆಮುರೆ',
'ಕರೆಯಟ್ಟು',
'ಕರೆಯಿಸು',
'ಕರೆಯೋಲೆ',
'ಕರೆವಿಡಿ',
'ಕರೆಶೂಲಿ',
'ಕರೆಹಟ್ಟಿ',
'ಕರೇತರ',
'ಕರೇವ್ಯಾಡಿ',
'ಕರ್ಕಟಕ',
'ಕರ್ಕಟಿಕೆ',
'ಕರ್ಕರೇಟು',
'ಕರ್ಕರೋಗ',
'ಕರ್ಕವೃತ್ತ',
'ಕರ್ಕಶತೆ',
'ಕರ್ಕಶಾಂಗ',
'ಕರ್ಕಸೈಲ',
'ಕರ್ಕಾಟಕ',
'ಕರ್ಕಿಸೆಲ್ಲಿ',
'ಕರ್ಕೇತನ',
'ಕರ್ಕೋಟಕ',
'ಕರ್ಕೋಟಕಿ',
'ಕರ್ಕೋಲಕ',
'ಕರ್ಗತ್ತಲೆ',
'ಕರ್ಗಳ್ತಲೆ',
'ಕರ್ಗೞ್ತಲೆ',
'ಕರ್ಗುಂಬಳ',
'ಕರ್ಗೊರಳ',
'ಕರ್ಗೋಡಗ',
'ಕರ್ಚಿಕಾಯಿ',
'ಕರ್ಚುಂಗಲ್',
'ಕರ್ಚುನೀರ್',
'ಕರ್ಚೂರಕ',
'ಕರ್ಚೆಮಂಗ',
'ಕರ್ಜಿಕಾಯಿ',
'ಕರ್ಜೀರಿಗೆ',
'ಕರ್ಣಕೀಟ',
'ಕರ್ಣಗತ',
'ಕರ್ಣಚ್ಛೇದ',
'ಕರ್ಣಜಪ',
'ಕರ್ಣಜಾಪ್ಯ',
'ಕರ್ಣತಾಳ',
'ಕರ್ಣಧಾರ',
'ಕರ್ಣನಾಳ',
'ಕರ್ಣಪತ್ರ',
'ಕರ್ಣಪಥ',
'ಕರ್ಣಪಾತ್ರ',
'ಕರ್ಣಪಾಶ',
'ಕರ್ಣಪೂರ',
'ಕರ್ಣಬಿಂದು',
'ಕರ್ಣಮಂತ್ರ',
'ಕರ್ಣಮಲ',
'ಕರ್ಣಮೂಲ',
'ಕರ್ಣಮೋಟಿ',
'ಕರ್ಣರೇಖೆ',
'ಕರ್ಣರೋಗ',
'ಕರ್ಣವೃತ್ತಿ',
'ಕರ್ಣವೇಧ',
'ಕರ್ಣಶಂಖ',
'ಕರ್ಣಶೂಲ',
'ಕರ್ಣಶೂಲೆ',
'ಕರ್ಣಾಕರ್ಣಿ',
'ಕರ್ಣಾಟಕ',
'ಕರ್ಣಾಟಾಶ್ವ',
'ಕರ್ಣಾಮೃತ',
'ಕರ್ಣಾಯತ',
'ಕರ್ಣಾವಧಿ',
'ಕರ್ಣಿಕಾರ',
'ಕರ್ಣೀರಥ',
'ಕರ್ಣೇಂದ್ರಿಯ',
'ಕರ್ಣೇಜಪ',
'ಕರ್ಣೋತ್ತಂಸ',
'ಕರ್ಣೋತ್ಪಲ',
'ಕರ್ತರಿಸು',
'ಕರ್ತಲಿಸು',
'ಕರ್ತೃಪದ',
'ಕರ್ದಟಕಿ',
'ಕರ್ದಡಿಗ',
'ಕರ್ದಮಿತ',
'ಕರ್ದಾದಾರ',
'ಕರ್ದಿಂಗಳ್',
'ಕರ್ನಾಟಕ',
'ಕರ್ನೆತ್ತರ್',
'ಕರ್ನೆಯ್ದಿಲ್',
'ಕರ್ನೈದಿಲ',
'ಕರ್ನೈದಿಲು',
'ಕರ್ಪಟಿಕ',
'ಕರ್ಪುವೀಱು',
'ಕರ್ಪುವೆಱು',
'ಕರ್ಪೇಱಿಸು',
'ಕರ್ಬಟ್ಟೆಗ',
'ಕರ್ಬರಲ್',
'ಕರ್ಬಸಿರು',
'ಕರ್ಬಸುರಿ',
'ಕರ್ಬಿತ್ತರ',
'ಕರ್ಬುಗರ್ದೆ',
'ಕರ್ಬುಗುಳ್',
'ಕರ್ಬುದೋಂಟ',
'ಕರ್ಬುರತ್ವ',
'ಕರ್ಬುರಿತ',
'ಕರ್ಬುರಿಸು',
'ಕರ್ಬುವಿಲ್',
'ಕರ್ಬುವಿಲ್ಲ',
'ಕರ್ಬೊಂಗಲ್',
'ಕರ್ಬೊಗರ್',
'ಕರ್ಬೊನ್ಗಲ್',
'ಕರ್ಮಕರ',
'ಕರ್ಮಕರಿ',
'ಕರ್ಮಕಾಂಡ',
'ಕರ್ಮಕಾಂಡಿ',
'ಕರ್ಮಕಾರ',
'ಕರ್ಮಕ್ಷಮ',
'ಕರ್ಮಕ್ಷಯ',
'ಕರ್ಮಗತಿ',
'ಕರ್ಮಚಾರ',
'ಕರ್ಮಜೀವಿ',
'ಕರ್ಮಠತೆ',
'ಕರ್ಮತ್ಯಾಗ',
'ಕರ್ಮತ್ರಯ',
'ಕರ್ಮದಾನ',
'ಕರ್ಮದೋಷ',
'ಕರ್ಮಧಾರೆ',
'ಕರ್ಮಪದ',
'ಕರ್ಮಪಾಕ',
'ಕರ್ಮಫಲ',
'ಕರ್ಮಫಳ',
'ಕರ್ಮಬಂದಿ',
'ಕರ್ಮಬಂಧ',
'ಕರ್ಮಭೂಮಿ',
'ಕರ್ಮಮಾರ್ಗ',
'ಕರ್ಮಯೋಗ',
'ಕರ್ಮಯೋಗಿ',
'ಕರ್ಮಲ್ಲಿಗೆ',
'ಕರ್ಮವಲೆ',
'ಕರ್ಮವಶ',
'ಕರ್ಮವಾದ',
'ಕರ್ಮವಾದಿ',
'ಕರ್ಮವೀರ',
'ಕರ್ಮಶಾಲೆ',
'ಕರ್ಮಶಾಸ್ತ್ರ',
'ಕರ್ಮಶೀಲ',
'ಕರ್ಮಶೀಲೆ',
'ಕರ್ಮಶೂರ',
'ಕರ್ಮಸಾಕ್ಷಿ',
'ಕರ್ಮಸಾನ',
'ಕರ್ಮಸಿದ್ಧ',
'ಕರ್ಮಸಿದ್ಧಿ',
'ಕರ್ಮಸ್ಥಾನ',
'ಕರ್ಮಸ್ಥಿತಿ',
'ಕರ್ಮಾಂತರ',
'ಕರ್ಮಾಂತಿಕ',
'ಕರ್ಮಾಯತ್ತ',
'ಕರ್ಮಾಲಯ',
'ಕರ್ಮುಗಿಲ್',
'ಕರ್ಮುಗಿಲು',
'ಕರ್ಮೇಂದ್ರಿಯ',
'ಕರ್ರಿಮರ',
'ಕರ್ಲುಮಾಳ',
'ಕರ್ವಸುರಿ',
'ಕರ್ವುಗೋಲ',
'ಕರ್ವುದೋಂಟ',
'ಕರ್ವುದೋಟ',
'ಕರ್ವುವಿಲ್',
'ಕರ್ವುವಿಲ್ಲ',
'ಕರ್ವುವಿಲ್ಲು',
'ಕರ್ವೆಳಗು',
'ಕರ್ವೊನಲು',
'ಕರ್ಷಣೀಯ',
'ಕರ್ಷಫಲ',
'ಕರ್ಷೋಪಲ',
'ಕರ್ಹಾಡಸ್ಥ',
'ಕಲಂಕಿತ',
'ಕಲಂಕಿತೆ',
'ಕಲಂಕಿನಿ',
'ಕಲಂಗಡಿ',
'ಕಲಂದಾನಿ',
'ಕಲಂಧರ',
'ಕಲಂಬಡೆ',
'ಕಲಕಂಠ',
'ಕಲಕಂಠಿ',
'ಕಲಕಲ',
'ಕಲಕಾಂಚಿ',
'ಕಲಕಾಟ',
'ಕಲಕಾಡು',
'ಕಲಕಾಲ',
'ಕಲಕಿಸು',
'ಕಲಕೇತ',
'ಕಲಕೇತಿ',
'ಕಲಗಂಚಿ',
'ಕಲಗಂಚು',
'ಕಲಗಚ್ಚು',
'ಕಲಗರ್ಚು',
'ಕಲಗಲ',
'ಕಲಗಾತಿ',
'ಕಲಗೆಯ್',
'ಕಲತಾನ',
'ಕಲತಾನಿ',
'ಕಲಧೌತ',
'ಕಲನೆಗೈ',
'ಕಲಬತ್ತು',
'ಕಲಬಿಲಿ',
'ಕಲಮಲ',
'ಕಲರವ',
'ಕಲವರ',
'ಕಲವಾಣಿ',
'ಕಲವಿಂಕ',
'ಕಲಸನ್ನ',
'ಕಲಹಂಸ',
'ಕಲಹಂಸೆ',
'ಕಲಹಟಿ',
'ಕಲಹಡಿ',
'ಕಲಹಾರ್ಥಿ',
'ಕಲಹಿಸು',
'ಕಲಾಂತರ',
'ಕಲಾಕಾಂತ',
'ಕಲಾಕಾರ',
'ಕಲಾಕೃತಿ',
'ಕಲಾಜೀವಿ',
'ಕಲಾತ್ಮಕ',
'ಕಲಾದಕ',
'ಕಲಾಧರ',
'ಕಲಾನಿಧಿ',
'ಕಲಾನ್ವಿತ',
'ಕಲಾನ್ವಿತೆ',
'ಕಲಾಪಕ',
'ಕಲಾಪತಿ',
'ಕಲಾಪತ್ತು',
'ಕಲಾಪಿಸ್ತ್ರೀ',
'ಕಲಾಬತು',
'ಕಲಾಬತ್ತು',
'ಕಲಾಭಿಜ್ಞ',
'ಕಲಾಭಿಜ್ಞೆ',
'ಕಲಾಮಾಲಿ',
'ಕಲಾವಂತ',
'ಕಲಾವತಿ',
'ಕಲಾವಿದ',
'ಕಲಾವಿದೆ',
'ಕಲಾವೇದಿ',
'ಕಲಾಶಾಸ್ತ್ರ',
'ಕಲಾಶಿಕ್ಷೆ',
'ಕಲಾಹೀನ',
'ಕಲಾಹೀನೆ',
'ಕಲಿಂಜಕ',
'ಕಲಿಂದಜೆ',
'ಕಲಿಕಾಲ',
'ಕಲಿಗಂಟು',
'ಕಲಿಗಲ',
'ಕಲಿಗಳೆ',
'ಕಲಿಗಾಲ',
'ಕಲಿಗೋಂಟೆ',
'ಕಲಿಜೋದ',
'ಕಲಿತನ',
'ಕಲಿದ್ರುಮ',
'ಕಲಿಮನ',
'ಕಲಿಮಾಡು',
'ಕಲಿಯುಗ',
'ಕಲಿಯಿಸು',
'ಕಲಿಯೇರು',
'ಕಲಿಯೇಱು',
'ಕಲಿಲಾರಿ',
'ಕಲಿಸಿಂಗ',
'ಕಲಿಹುಟ್ಟು',
'ಕಲುಂಬೇಱು',
'ಕಲುಕಟ್ಟು',
'ಕಲುಕತ್ತಿ',
'ಕಲುಗುಂಡು',
'ಕಲುದಲೆ',
'ಕಲುನೆಲ',
'ಕಲುಬೆಜ್ಜ',
'ಕಲುಮಕ್ಕಿ',
'ಕಲುಮದ',
'ಕಲುಮನ',
'ಕಲುಮಸ',
'ಕಲುಮಳೆ',
'ಕಲುಮಾವು',
'ಕಲುಮಿಷ',
'ಕಲುವಳೆ',
'ಕಲುವಾಲೆ',
'ಕಲುವಾಳೆ',
'ಕಲುವಾೞೆ',
'ಕಲುವೆಸ',
'ಕಲುಷತೆ',
'ಕಲುಷಿತ',
'ಕಲುಹಾರ',
'ಕಲೆಗಟ್ಟು',
'ಕಲೆಗಾರ',
'ಕಲೆಗುಂದು',
'ಕಲೆಗೂಡು',
'ಕಲೆದೋಱು',
'ಕಲೆಬೆರೆ',
'ಕಲೆಮುಟ್ಟು',
'ಕಲೆವೆಱು',
'ಕಲೆಹಾಕು',
'ಕಲೇವರ',
'ಕಲ್ಕಂಗೂಡು',
'ಕಲ್ಕಿಸರ',
'ಕಲ್ಕುಟಿಕ',
'ಕಲ್ಕುಟಿಗ',
'ಕಲ್ಕುಡಕ',
'ಕಲ್ಗಡಿಗೆ',
'ಕಲ್ಗರಿಕೆ',
'ಕಲ್ಗರಿಗೆ',
'ಕಲ್ಗೊರವಿ',
'ಕಲ್ಚಾವಡಿ',
'ಕಲ್ಜೊಡರಿ',
'ಕಲ್ನಿಱಿಸು',
'ಕಲ್ಪಕತೆ',
'ಕಲ್ಪಕಾಲ',
'ಕಲ್ಪಕುಜ',
'ಕಲ್ಪತರು',
'ಕಲ್ಪದ್ರುಮ',
'ಕಲ್ಪರಟೆ',
'ಕಲ್ಪಲತೆ',
'ಕಲ್ಪವೃಕ್ಷ',
'ಕಲ್ಪಾಂಘ್ರಿಪ',
'ಕಲ್ಪಾಂತರ',
'ಕಲ್ಪಾನಲ',
'ಕಲ್ಪಾಮರ',
'ಕಲ್ಪಾಳಿಸು',
'ಕಲ್ಪುಂಜಿಕೆ',
'ಕಲ್ಪೇಶ್ವರ',
'ಕಲ್ಬಸರಿ',
'ಕಲ್ಮರಿಗೆ',
'ಕಲ್ಮುತ್ತುಗ',
'ಕಲ್ಮೆವೆಱು',
'ಕಲ್ಮೊರಡಿ',
'ಕಲ್ಲಂಗಡಿ',
'ಕಲ್ಲಕಾಯಿ',
'ಕಲ್ಲಚಾರಿ',
'ಕಲ್ಲಡಿಕೆ',
'ಕಲ್ಲಣಂಬೆ',
'ಕಲ್ಲಣಬೆ',
'ಕಲ್ಲಣುಗಿ',
'ಕಲ್ಲನಾರು',
'ಕಲ್ಲಮೂಕ',
'ಕಲ್ಲರಳಿ',
'ಕಲ್ಲರಳೆ',
'ಕಲ್ಲರಿಗೆ',
'ಕಲ್ಲವಲ್ಲಿ',
'ಕಲ್ಲವಿಲ',
'ಕಲ್ಲಸೆರೆ',
'ಕಲ್ಲಹೂವು',
'ಕಲ್ಲಾಕಲ್ಲಿ',
'ಕಲ್ಲಾಬಿಲ್ಲಿ',
'ಕಲ್ಲಾರತಿ',
'ಕಲ್ಲಾವಿಲ್ಲಿ',
'ಕಲ್ಲಿಗೂಳು',
'ಕಲ್ಲಿದ್ದಲು',
'ಕಲ್ಲಿದ್ದಿಲು',
'ಕಲ್ಲಿಬೋನ',
'ಕಲ್ಲಿಮೀಸೆ',
'ಕಲ್ಲೀಚಲು',
'ಕಲ್ಲುಉದಿ',
'ಕಲ್ಲುಕಟ್ಟು',
'ಕಲ್ಲುಕುಂಟೆ',
'ಕಲ್ಲುಕುಟ್ಟಿ',
'ಕಲ್ಲುಕೂವೆ',
'ಕಲ್ಲುಗಣಿ',
'ಕಲ್ಲುಗಾರೆ',
'ಕಲ್ಲುಗುಂಡು',
'ಕಲ್ಲುತೊಟ್ಟಿ',
'ಕಲ್ಲುದುರೆ',
'ಕಲ್ಲುನಾರು',
'ಕಲ್ಲುಬಂಡೆ',
'ಕಲ್ಲುಬಾಂದು',
'ಕಲ್ಲುಬಾಳೆ',
'ಕಲ್ಲುಬೆಟ್ಟ',
'ಕಲ್ಲುಮದ',
'ಕಲ್ಲುಮಾಡ',
'ಕಲ್ಲುಮಾವು',
'ಕಲ್ಲುಮುಳ್ಳು',
'ಕಲ್ಲುರಾಳ',
'ಕಲ್ಲುರಿಗ',
'ಕಲ್ಲುರಿವೆ',
'ಕಲ್ಲುರುಣಿ',
'ಕಲ್ಲುಶುಂಠಿ',
'ಕಲ್ಲುಸಾರ',
'ಕಲ್ಲುಸುಂಟಿ',
'ಕಲ್ಲುಸೋರೆ',
'ಕಲ್ಲುಹಾಲೆ',
'ಕಲ್ಲುಹಾವು',
'ಕಲ್ಲುಹೂವು',
'ಕಲ್ಲುಹೆಟ್ಟೆ',
'ಕಲ್ಲುಹೊನ್ನೆ',
'ಕಲ್ಲೆದೆಗ',
'ಕಲ್ಲೆರ್ದೆಗ',
'ಕಲ್ಲೆರ್ದೆಯ',
'ಕಲ್ಲೊನಕೆ',
'ಕಲ್ಲೊರಳ್',
'ಕಲ್ಲೊರಳು',
'ಕಲ್ಲೋಲಿತ',
'ಕಲ್ವಾನಸ',
'ಕಲ್ವಾನಸೆ',
'ಕಲ್ಸಱಡು',
'ಕವಕವ',
'ಕವಕ್ಕನೆ',
'ಕವಚಿತ',
'ಕವಚಿಸು',
'ಕವಡಂಚು',
'ಕವಡಂಬು',
'ಕವಡಿಕ',
'ಕವಡಿಕೆ',
'ಕವಡಿಗ',
'ಕವಡಿಸು',
'ಕವಡುಗ',
'ಕವಣಾಯ',
'ಕವನಿಸು',
'ಕವಯಿತ್ರಿ',
'ಕವರಗಿ',
'ಕವರಿಸು',
'ಕವರ್ಗಟ್ಟು',
'ಕವಲಂಬು',
'ಕವಲಗೆ',
'ಕವಲಿಱಿ',
'ಕವಲೊತ್ತು',
'ಕವಲ್ಗಣೆ',
'ಕವಲ್ಗಿಡಿ',
'ಕವಲ್ಗುಡಿ',
'ಕವಲ್ಗೊಂಬು',
'ಕವಲ್ಗೋಲ್',
'ಕವಲ್ದಲೆ',
'ಕವಲ್ದೊಱೆ',
'ಕವಲ್ಬಟ್ಟೆ',
'ಕವಲ್ಬನ್ನ',
'ಕವಲ್ವಟ್ಟೆ',
'ಕವಲ್ವರಿ',
'ಕವಳಿಕ',
'ಕವಳಿಕೆ',
'ಕವಳಿಗೆ',
'ಕವಳಿಸು',
'ಕವಳೀಕ',
'ಕವಳ್ಮಿಂಚು',
'ಕವೞ್ಗೊಲೆ',
'ಕವಾಡಿಗ',
'ಕವಾಯತು',
'ಕವಾಯತ್ತು',
'ಕವಾಯಿತ',
'ಕವಾಯಿತು',
'ಕವಿಂಜಳ',
'ಕವಿಗೊಳ್',
'ಕವಿಗೋಷ್ಠಿ',
'ಕವಿತರ್',
'ಕವಿತರು',
'ಕವಿಯಿಸು',
'ಕವಿಯೇಱು',
'ಕವಿಲ್ಗೆಂಪು',
'ಕವಿಲ್ವಣ್ಣ',
'ಕವಿಳಾಸ',
'ಕವುಂಕುೞ್',
'ಕವುಜಗ',
'ಕವುತುಕ',
'ಕವುಸ್ತಭ',
'ಕವುಳಿಗ',
'ಕವುಳಿಗೆ',
'ಕವುಳುಡೆ',
'ಕವುಱಿಡು',
'ಕವುಱೇಳ್',
'ಕವೆಕಡ್ಡಿ',
'ಕವೆಗಡ್ಡಿ',
'ಕವೆಗೋಲ್',
'ಕವೆಗೋಲು',
'ಕವೆಯರ',
'ಕವೆಯಾರ',
'ಕವ್ವರಿಸು',
'ಕಶಾಘಾತ',
'ಕಶಾದಂಡ',
'ಕಶಿಕಟ್ಟು',
'ಕಶೇರುಕ',
'ಕಶೇರುಕೆ',
'ಕಷಾಯಾಕ್ಷ',
'ಕಷಾಯಿತ',
'ಕಷೋಪಲ',
'ಕಷ್ಟಂಬಡು',
'ಕಷ್ಟಕಾರ',
'ಕಷ್ಟಕಾರಿ',
'ಕಷ್ಟಕೃತಿ',
'ಕಷ್ಟಜಾತಿ',
'ಕಷ್ಟತನ',
'ಕಷ್ಟತಿಕೆ',
'ಕಷ್ಟತಿಕ್ಕೆ',
'ಕಷ್ಟತೆಗೆ',
'ಕಷ್ಟದೆಸೆ',
'ಕಷ್ಟಪಡು',
'ಕಷ್ಟಬಡು',
'ಕಷ್ಟಮಾಡು',
'ಕಷ್ಟವಸ್ತ್ರ',
'ಕಷ್ಟಸಂಧಿ',
'ಕಷ್ಟಸಾಧ್ಯ',
'ಕಷ್ಟಾರ್ಜಿತ',
'ಕಷ್ಟಾಶ್ರಯ',
'ಕಸಂದೆಗೆ',
'ಕಸಕಡ್ಡಿ',
'ಕಸಕಣ್ಣು',
'ಕಸಕಸಿ',
'ಕಸಕಸೆ',
'ಕಸಕಿಲ್',
'ಕಸಕಿಲು',
'ಕಸಕುಪ್ಪೆ',
'ಕಸಗಣ್ಣು',
'ಕಸಗಳೆ',
'ಕಸಗುಪ್ಪೆ',
'ಕಸಗೊಟ್ಟಿ',
'ಕಸದಾರ',
'ಕಸನೀರು',
'ಕಸಬಿಣಿ',
'ಕಸರತ್ತು',
'ಕಸರಿಕೆ',
'ಕಸರಿಸು',
'ಕಸವರ',
'ಕಸವಿಸಿ',
'ಕಸಾಕಸಿ',
'ಕಸಾರಿಕೆ',
'ಕಸಿಕಟ್ಟು',
'ಕಸಿಕಲ್',
'ಕಸಿಕಿಲ್',
'ಕಸಿಕಿಸಿ',
'ಕಸಿಕೊಂಬೆ',
'ಕಸಿಗಾಯಿ',
'ಕಸಿಗುಂದು',
'ಕಸಿಬಿಸಿ',
'ಕಸಿಯಂಟು',
'ಕಸಿವಿಸಿ',
'ಕಸುಕಾಯಿ',
'ಕಸುಗಾಯ್',
'ಕಸುಗಾಯಿ',
'ಕಸುಡುಗೆ',
'ಕಸುರ್ಗಾಯ್',
'ಕಸೆಕಟ್ಟು',
'ಕಸೆಗೊಳ್',
'ಕಸೆಗೋಲ್',
'ಕಸೆಗೋಲು',
'ಕಸೊಡಿಗೆ',
'ಕಸ್ತಿಪಡು',
'ಕಸ್ತಿಬೀಳು',
'ಕಸ್ತುಕಾಱ',
'ಕಸ್ತುಗಾರ',
'ಕಸ್ತುಗೆಯ್',
'ಕಸ್ತೂರಿಕೆ',
'ಕಹಕಹ',
'ಕಹಿತುಂಬೆ',
'ಕಹಿದೊಂಡೆ',
'ಕಹಿನನ್ನಿ',
'ಕಹಿಬೇವು',
'ಕಹಿಬ್ರಾಹ್ಮಿ',
'ಕಹಿಮೆಕ್ಕೆ',
'ಕಹಿಸೋರೆ',
'ಕಹಿಹಾಲೆ',
'ಕಹಿಹೀರೆ',
'ಕಹಿಹುಲ್ಲು',
'ಕಳಂಕಿತ',
'ಕಳಂಕಿನಿ',
'ಕಳಂಬರ್',
'ಕಳಂಬುಗು',
'ಕಳಂಬೇೞ್',
'ಕಳಕಂಠ',
'ಕಳಕಂಠಿ',
'ಕಳಕಳ',
'ಕಳಕಳಿ',
'ಕಳಕುಳ',
'ಕಳಗರ್ಚು',
'ಕಳತಿಸು',
'ಕಳತೆಱೆ',
'ಕಳಧೌತ',
'ಕಳನೇಱು',
'ಕಳಪಿಳಿ',
'ಕಳಪಿಳೆ',
'ಕಳಬಳ',
'ಕಳಮಾವು',
'ಕಳಮೂಕ',
'ಕಳಮೊಗೆ',
'ಕಳರುತಿ',
'ಕಳಲಿಕೆ',
'ಕಳಲಿಚು',
'ಕಳಲಿಸು',
'ಕಳವಲ್',
'ಕಳವಲಿ',
'ಕಳವಲು',
'ಕಳವಳ',
'ಕಳವಳಿ',
'ಕಳವಿಂಕ',
'ಕಳವಿಗೆ',
'ಕಳವೂಟ',
'ಕಳವೇಱು',
'ಕಳವೇೞ್',
'ಕಳವೋಲೆ',
'ಕಳಸಿಗ',
'ಕಳಸಿಗೆ',
'ಕಳಹಂಸ',
'ಕಳಹಂಸಿ',
'ಕಳಹಂಸೆ',
'ಕಳಹೂಟ',
'ಕಳಹೊನ್ನೆ',
'ಕಳಾಂತರ',
'ಕಳಾಕುಳ',
'ಕಳಾಗಿಡ',
'ಕಳಾತೀತ',
'ಕಳಾತೀತೆ',
'ಕಳಾಧರ',
'ಕಳಾನಿಧಿ',
'ಕಳಾನ್ವಿತ',
'ಕಳಾನ್ವಿತೆ',
'ಕಳಾಪಕ',
'ಕಳಾಪಿನಿ',
'ಕಳಾಭಾವ',
'ಕಳಾಭಿಜ್ಞ',
'ಕಳಾಭಿಜ್ಞೆ',
'ಕಳಾರುಂದ್ರ',
'ಕಳಾವತಿ',
'ಕಳಾವರ್',
'ಕಳಾವರ',
'ಕಳಾವಿದ',
'ಕಳಾಸಿಕೆ',
'ಕಳಾಸಿಸು',
'ಕಳಾಹೀನ',
'ಕಳಾಹೀನೆ',
'ಕಳಿಂದಜೆ',
'ಕಳಿಕಳ',
'ಕಳಿಗೂಟ',
'ಕಳಿತಲೆ',
'ಕಳಿನುಡಿ',
'ಕಳಿನೊಗ',
'ಕಳಿಮಾವು',
'ಕಳಿಲೇಱು',
'ಕಳಿವಣ್',
'ಕಳಿವಣ್ಣು',
'ಕಳಿವದ',
'ಕಳಿವಱಿ',
'ಕಳಿಹಿಸು',
'ಕಳುಗುಡಿ',
'ಕಳುಬಳ್ಳಿ',
'ಕಳುವೂಟ',
'ಕಳುಹಣ',
'ಕಳುಹಿಸು',
'ಕಳೆಕಟ್ಟು',
'ಕಳೆಕೀಳು',
'ಕಳೆಗಟ್ಟು',
'ಕಳೆಗಳೆ',
'ಕಳೆಗೀಳ್',
'ಕಳೆಗೀಳು',
'ಕಳೆಗುಂದು',
'ಕಳೆಗೆಡು',
'ಕಳೆಗೇಡಿ',
'ಕಳೆಗೊಡು',
'ಕಳೆಗೊಳ್',
'ಕಳೆಜೂಟ',
'ಕಳೆದುಂಬು',
'ಕಳೆದೆಗೆ',
'ಕಳೆದೋಱು',
'ಕಳೆಬರ',
'ಕಳೆಮೆಳೆ',
'ಕಳೆಯಿಸು',
'ಕಳೆಯಿಳಿ',
'ಕಳೆಯೇರು',
'ಕಳೆಯೇಱು',
'ಕಳೆವರ',
'ಕಳೆವೆಱು',
'ಕಳೆವೆಱೆ',
'ಕಳೇಬರ',
'ಕಳೇವರ',
'ಕಳೋನ್ನತ',
'ಕಳ್ಕುಡಿಹಿ',
'ಕಳ್ಗತ್ತಲೆ',
'ಕಳ್ಗುಡಿಕ',
'ಕಳ್ಗುಡಿಕಿ',
'ಕಳ್ಗುಡಿಪಿ',
'ಕಳ್ಗುಡಿಹ',
'ಕಳ್ಗುಡಿಹಿ',
'ಕಳ್ಗುಡಿಹು',
'ಕಳ್ಗುಡುಹಿ',
'ಕಳ್ತಲಿಸು',
'ಕಳ್ಪಕುಜ',
'ಕಳ್ಪಬ್ರಿಕ್ಷ',
'ಕಳ್ಪವೃಕ್ಷ',
'ಕಳ್ವಡಿಗ',
'ಕಳ್ಳಂಗಡಿ',
'ಕಳ್ಳಕಣ್ಣು',
'ಕಳ್ಳಕಿಂಡಿ',
'ಕಳ್ಳಕೊಲೆ',
'ಕಳ್ಳಗಂಟು',
'ಕಳ್ಳಗಂಡಿ',
'ಕಳ್ಳಗುಪ್ಪೆ',
'ಕಳ್ಳಗೊಡು',
'ಕಳ್ಳಚೀಟು',
'ಕಳ್ಳಜುಟ್ಟು',
'ಕಳ್ಳಜ್ವರ',
'ಕಳ್ಳತನ',
'ಕಳ್ಳದನ',
'ಕಳ್ಳದನಿ',
'ಕಳ್ಳದಮ್ಮ',
'ಕಳ್ಳದಾರಿ',
'ಕಳ್ಳದಾಳ',
'ಕಳ್ಳದಾೞಿ',
'ಕಳ್ಳನಾಣ್ಯ',
'ಕಳ್ಳನೆವ',
'ಕಳ್ಳನೋಟ',
'ಕಳ್ಳಪತ್ರ',
'ಕಳ್ಳಪೇಟೆ',
'ಕಳ್ಳಬಂಟ',
'ಕಳ್ಳಬಟ್ಟಿ',
'ಕಳ್ಳಭಂಟ',
'ಕಳ್ಳಭಟ್ಟಿ',
'ಕಳ್ಳಮನೆ',
'ಕಳ್ಳಮಾತು',
'ಕಳ್ಳಮಾಲು',
'ಕಳ್ಳರುಜು',
'ಕಳ್ಳವಣ',
'ಕಳ್ಳವತಿ',
'ಕಳ್ಳವಳ',
'ಕಳ್ಳವಾಳ',
'ಕಳ್ಳವೆಣ್',
'ಕಳ್ಳವೆಳ್ಳಿ',
'ಕಳ್ಳವೊಗು',
'ಕಳ್ಳಸಂತೆ',
'ಕಳ್ಳಸಾಕ್ಷಿ',
'ಕಳ್ಳಸಿಗ',
'ಕಳ್ಳಹಣ',
'ಕಳ್ಳಹಾದಿ',
'ಕಳ್ಳಹುಂಡಿ',
'ಕಳ್ಳಹುಳ',
'ಕಳ್ಳಹೆಜ್ಜೆ',
'ಕಳ್ಳಹೊತ್ತು',
'ಕಳ್ಳಹೊನ್ನು',
'ಕಳ್ಳಿಪೀರ',
'ಕಳ್ಳಿವೆಟ್ಟು',
'ಕಳ್ಳುಕೊಡ',
'ಕಳ್ಳುಗುಡಿ',
'ಕಳ್ಳುಗೊತ್ತು',
'ಕಳ್ಳುಸುಗು',
'ಕಳ್ಳುಸುಬು',
'ಕಳ್ಳೆಮಳ್ಳೆ',
'ಕಳ್ಳೊಡಮೆ',
'ಕಱಕಟ',
'ಕಱಿಗಂದು',
'ಕಱಿಗಲ್',
'ಕಱುಗಂದಿ',
'ಕಱುಗಾಯ್',
'ಕಱುಗಾಹಿ',
'ಕಱುಪೇಳ್',
'ಕಱುಭೂಮಿ',
'ಕಱುವಳ',
'ಕಱುಹಟ್ಟಿ',
'ಕಱೆಇಲ್',
'ಕಱೆಕಂಠ',
'ಕಱೆಕಟ್ಟು',
'ಕಱೆಗಂದು',
'ಕಱೆಗಟ್ಟು',
'ಕಱೆಗರ್ಚು',
'ಕಱೆಗಳೆ',
'ಕಱೆಗಱು',
'ಕಱೆಗೊಳ್',
'ಕಱೆತರ್',
'ಕಱೆಬೋನ',
'ಕಱೆಮನ',
'ಕಱೆಮಱೆ',
'ಕಱೆವೆಟ್ಟು',
'ಕಱಱನೆ',
'ಕೞಕೞ',
'ಕೞಕುೞ',
'ಕೞಲಿಸು',
'ಕೞಲುಲಿ',
'ಕೞಲುಳಿ',
'ಕೞಿಯುಣ್',
'ಕೞಿಯೇಱ್',
'ಕೞಿಯೋದು',
'ಕೞಿವಣ್',
'ಕೞಿವದ',
'ಕೞಿವರಿ',
'ಕೞ್ತಲಿಸು',
'ಕೞ್ತೆಗಿರಿ',
'ಕೞ್ದಿಂಗಳ್',
'ಕೞ್ಪಭೂಜ',
'ಕಾಂಚನಾದ್ರಿ',
'ಕಾಂಚನಾಲ',
'ಕಾಂಚನಿಸು',
'ಕಾಂಚಿವಾಳ',
'ಕಾಂಚೀದಾಮ',
'ಕಾಂಚೀಪದ',
'ಕಾಂಜಿವಾರ',
'ಕಾಂಡಜಜ',
'ಕಾಂಡತಿಕ್ತೆ',
'ಕಾಂಡಪಟ',
'ಕಾಂಡಪೃಷ್ಠ',
'ಕಾಂಡರಂಧ್ರ',
'ಕಾಂಡರುಹೆ',
'ಕಾಂಡವೃಕ್ಷ',
'ಕಾಂಡಸ್ಪೃಷ್ಟ',
'ಕಾಂಡಾಸನ',
'ಕಾಂತಕಾಮಿ',
'ಕಾಂತಕ್ಷೇತ್ರ',
'ಕಾಂತಗುಣ',
'ಕಾಂತಗೋಳ',
'ಕಾಂತಧ್ರುವ',
'ಕಾಂತಪುಷ್ಪ',
'ಕಾಂತಲಕ',
'ಕಾಂತಶಕ್ತಿ',
'ಕಾಂತಸದ್ಮ',
'ಕಾಂತಸೂಚಿ',
'ಕಾಂತಳಗಿ',
'ಕಾಂತಾಂತಿಕ',
'ಕಾಂತಾಜಾಲ',
'ಕಾಂತಾತತಿ',
'ಕಾಂತಾದರ್ಶಿ',
'ಕಾಂತಾಮಣಿ',
'ಕಾಂತಾರಕ',
'ಕಾಂತಾಭಿಕ್ಷೆ',
'ಕಾಂತಿಗುಂದು',
'ಕಾಂತಿಗೂಡು',
'ಕಾಂತಿಗೆಡು',
'ಕಾಂತಿದೋಱು',
'ಕಾಂತಿಪರ',
'ಕಾಂತಿಭಾಸ',
'ಕಾಂತಿಮಂತ',
'ಕಾಂತಿಯಿಡು',
'ಕಾಂತಿವಂತ',
'ಕಾಂತಿವಿಡಿ',
'ಕಾಂತಿಸ್ತಂಭ',
'ಕಾಂತಿಹೀನ',
'ಕಾಂತಿಹೀನೆ',
'ಕಾಂತ್ಯಾಕರ',
'ಕಾಂತ್ಯಾಸ್ಪದ',
'ಕಾಂದವಿಕ',
'ಕಾಂದಾಭಜಿ',
'ಕಾಂದಿಶೀಕ',
'ಕಾಂದಿಸೀಕ',
'ಕಾಂಪೌಂಡರು',
'ಕಾಂಬವಿಕ',
'ಕಾಂಭೋಜಕ',
'ಕಾಂಸ್ಯತಾಲ',
'ಕಾಂಸ್ಯತಾಳ',
'ಕಾಂಸ್ಯಯುಗ',
'ಕಾಕಂಗೆಡು',
'ಕಾಕಕಂಠ',
'ಕಾಕಚಿಂಚಿ',
'ಕಾಕಚೋರ',
'ಕಾಕಜಂಘೆ',
'ಕಾಕಜಾತ',
'ಕಾಕಜ್ವರ',
'ಕಾಕತನ',
'ಕಾಕತುಂಡ',
'ಕಾಕತುಂಡಿ',
'ಕಾಕದಂತ',
'ಕಾಕದೃಷ್ಟಿ',
'ಕಾಕದೊಂಡೆ',
'ಕಾಕಧ್ವಜ',
'ಕಾಕನಿಶೆ',
'ಕಾಕಪಕ್ಷ',
'ಕಾಕಪದ',
'ಕಾಕಪರ್ಣಿ',
'ಕಾಕಪಾದ',
'ಕಾಕಪಿಂಡ',
'ಕಾಕಪಿಟ್ಲ',
'ಕಾಕಪುಷ್ಟ',
'ಕಾಕಪುಷ್ಟಿ',
'ಕಾಕಪೇಯ',
'ಕಾಕಪೋಕ',
'ಕಾಕಬಂಜೆ',
'ಕಾಕಬಲಿ',
'ಕಾಕಬುದ್ಧಿ',
'ಕಾಕಮಂಚಿ',
'ಕಾಕಮರ',
'ಕಾಕಮಾಚಿ',
'ಕಾಕಮಾರಿ',
'ಕಾಕಮುಂಚಿ',
'ಕಾಕಮುಖ',
'ಕಾಕಮುದ್ಗೆ',
'ಕಾಕರತ್ನ',
'ಕಾಕರವ',
'ಕಾಕರಿಪು',
'ಕಾಕಲಿಪಿ',
'ಕಾಕವೃದ್ಧಿ',
'ಕಾಕಸತ್ವೆ',
'ಕಾಕಸ್ನಾನ',
'ಕಾಕಾಹೊಡೆ',
'ಕಾಕಿಸೊಪ್ಪು',
'ಕಾಕಿಹಣ್ಣು',
'ಕಾಕೀಮುಖ',
'ಕಾಕುಗೀತ',
'ಕಾಕುತನ',
'ಕಾಕುದೈವ',
'ಕಾಕುನುಡಿ',
'ಕಾಕುಪುಷ್ಟಿ',
'ಕಾಕುಪೋಕು',
'ಕಾಕುಬಲ',
'ಕಾಕುಬುದ್ಧಿ',
'ಕಾಕುಭಟ',
'ಕಾಕುಮತಿ',
'ಕಾಕುಮದ್ದು',
'ಕಾಕುಮನ',
'ಕಾಕುಮಾತು',
'ಕಾಕುಮೂಳ',
'ಕಾಕುಮೂಳಿ',
'ಕಾಕುರುಳು',
'ಕಾಕುಲತೆ',
'ಕಾಕುಲಾತಿ',
'ಕಾಕುಸೇವೆ',
'ಕಾಕೆಹಣ್ಣು',
'ಕಾಕೋದರ',
'ಕಾಗದಹೂ',
'ಕಾಗದಳಿ',
'ಕಾಗದಾಳಿ',
'ಕಾಗುಣಿತ',
'ಕಾಗುದುರೆ',
'ಕಾಗುರುಳು',
'ಕಾಗೆಕೂಟ',
'ಕಾಗೆಗಣ್ಣು',
'ಕಾಗೆಗಬ್ಬು',
'ಕಾಗೆಗೊಳ್ಳಿ',
'ಕಾಗೆಜೋಳ',
'ಕಾಗೆದಬ್ಬೆ',
'ಕಾಗೆದರ್ಭೆ',
'ಕಾಗೆದೊಂಡೆ',
'ಕಾಗೆಪಿಂಡ',
'ಕಾಗೆಬಂಜೆ',
'ಕಾಗೆಮಾಯಿ',
'ಕಾಗೆಮಾರಿ',
'ಕಾಗೆಮೋರೆ',
'ಕಾಗೆರೋಗ',
'ಕಾಗೆಸೊಪ್ಪು',
'ಕಾಗೆಸ್ನಾನ',
'ಕಾಗೆಹಣ್ಣು',
'ಕಾಗೆಹುಲ್ಲು',
'ಕಾಚಗಣ್ಣು',
'ಕಾಚತೊಂಡೆ',
'ಕಾಚದ್ರವ',
'ಕಾಚದ್ರವ್ಯ',
'ಕಾಚಮಣಿ',
'ಕಾಚರಿಕೆ',
'ಕಾಚಶಿಲೆ',
'ಕಾಚಸ್ಥಾಲಿ',
'ಕಾಚಿಮಾಚಿ',
'ಕಾಚಿಹುಲ್ಲು',
'ಕಾಚುಗಾವಿ',
'ಕಾಜಗಾಱ',
'ಕಾಜವಾರ',
'ಕಾಜಿನ್ಯಾಯ',
'ಕಾಜಿವಾರ',
'ಕಾಜಿವಾಳ',
'ಕಾಜುವೀೞ್',
'ಕಾಟಕಾಯ',
'ಕಾಟಕಾಯಿ',
'ಕಾಟಕುಳಿ',
'ಕಾಟಕೋಣ',
'ಕಾಟಕೋನ',
'ಕಾಟಗಾಯಿ',
'ಕಾಟಾಚಾರ',
'ಕಾಟುದನ',
'ಕಾಟುನಾಯಿ',
'ಕಾಡಕಬ್ಬು',
'ಕಾಡಕಳ್ಳ',
'ಕಾಡಕಳ್ಳಿ',
'ಕಾಡಕಾಗೆ',
'ಕಾಡಕೆಸು',
'ಕಾಡಗಸೆ',
'ಕಾಡಗೋಳಿ',
'ಕಾಡಗೌಟೆ',
'ಕಾಡಚೆಂಬೆ',
'ಕಾಡಡವಿ',
'ಕಾಡಡಿಕೆ',
'ಕಾಡತುಂಬೆ',
'ಕಾಡತುಸ',
'ಕಾಡತೂಸು',
'ಕಾಡದಟು',
'ಕಾಡದೊಂಡೆ',
'ಕಾಡನಿಂಬೆ',
'ಕಾಡನೊಣ',
'ಕಾಡನೊಳ',
'ಕಾಡಪಾಪ',
'ಕಾಡಬಜ',
'ಕಾಡಬಜೆ',
'ಕಾಡಬಾಳೆ',
'ಕಾಡಬೇಲ',
'ಕಾಡಮಟೆ',
'ಕಾಡಮರ',
'ಕಾಡಮಿಕ',
'ಕಾಡರಸ',
'ಕಾಡರಳಿ',
'ಕಾಡರಳೆ',
'ಕಾಡಲಿಂಬೆ',
'ಕಾಡಲಿಟ್ಟಿ',
'ಕಾಡವರೆ',
'ಕಾಡವಿಟ್ಟಿ',
'ಕಾಡವೋಮ',
'ಕಾಡಶಹ',
'ಕಾಡಸಾವೆ',
'ಕಾಡಸಿಂಬೆ',
'ಕಾಡಸೌತೆ',
'ಕಾಡಹಾಲೆ',
'ಕಾಡಾಕಳು',
'ಕಾಡಾಕಾಡಿ',
'ಕಾಡಾರಂಬ',
'ಕಾಡಾರಂಭ',
'ಕಾಡಿಕಾರ',
'ಕಾಡೀಕಾರ',
'ಕಾಡೀಖಾರ',
'ಕಾಡೀನೀರು',
'ಕಾಡೀಮರ',
'ಕಾಡೀರುಳ್ಳಿ',
'ಕಾಡುಅತ್ತಿ',
'ಕಾಡುಇಪ್ಪೆ',
'ಕಾಡುಈಜಿ',
'ಕಾಡುಉದ್ದು',
'ಕಾಡುಎಮ್ಮೆ',
'ಕಾಡುಎಳ್ಳು',
'ಕಾಡುಓಮ',
'ಕಾಡುಕಂಚಿ',
'ಕಾಡುಕಗ್ಗ',
'ಕಾಡುಕಬ್ಬು',
'ಕಾಡುಕಲ್ಲು',
'ಕಾಡುಕಾಕೆ',
'ಕಾಡುಕಾಗೆ',
'ಕಾಡುಕಾಫಿ',
'ಕಾಡುಕಿಚ್ಚು',
'ಕಾಡುಕುರಿ',
'ಕಾಡುಕೇತಿ',
'ಕಾಡುಕೋಣ',
'ಕಾಡುಕೋಳಿ',
'ಕಾಡುಕೌಟೆ',
'ಕಾಡುಗಂಧ',
'ಕಾಡುಗಟ್ಟೆ',
'ಕಾಡುಗಣ್ಣು',
'ಕಾಡುಗತ್ತೆ',
'ಕಾಡುಗಳ್ಳ',
'ಕಾಡುಗಿಚ್ಚು',
'ಕಾಡುಗಿಡ',
'ಕಾಡುಗುಱಿ',
'ಕಾಡುಗೂಬೆ',
'ಕಾಡುಗೇರು',
'ಕಾಡುಗೊಂಡೆ',
'ಕಾಡುಗೊಬ್ಳಿ',
'ಕಾಡುಗೊಲ್ಲ',
'ಕಾಡುಗೋಡು',
'ಕಾಡುಗೋಣಿ',
'ಕಾಡುಗೋಧಿ',
'ಕಾಡುಗೋಳಿ',
'ಕಾಡುಗೌಟೆ',
'ಕಾಡುಚಳ್ಳೆ',
'ಕಾಡುಜನ',
'ಕಾಡುಜಾಜಿ',
'ಕಾಡುಜಾಣೆ',
'ಕಾಡುಜಿಂಕೆ',
'ಕಾಡುಜೋಳ',
'ಕಾಡುತನ',
'ಕಾಡುತುಂಬೆ',
'ಕಾಡುತುತ್ತಿ',
'ಕಾಡುತೆಂಗು',
'ಕಾಡುದಾರಿ',
'ಕಾಡುದೊಂಬ',
'ಕಾಡುದ್ರಾಕ್ಷಿ',
'ಕಾಡುನಾಯಿ',
'ಕಾಡುನಿಂಬೆ',
'ಕಾಡುನೀಲಿ',
'ಕಾಡುನುಗ್ಗೆ',
'ಕಾಡುನುಡಿ',
'ಕಾಡುನೆಕ್ಕಿ',
'ಕಾಡುನೊಣ',
'ಕಾಡುನೊಳ',
'ಕಾಡುಪಂದಿ',
'ಕಾಡುಪಾಪ',
'ಕಾಡುಪಾಲು',
'ಕಾಡುಪಿಂಡಿ',
'ಕಾಡುಬಂಬೆ',
'ಕಾಡುಬಕ',
'ಕಾಡುಬಜೆ',
'ಕಾಡುಬಳ್ಳಿ',
'ಕಾಡುಬಾಗೆ',
'ಕಾಡುಬಾತು',
'ಕಾಡುಬಾಳೆ',
'ಕಾಡುಬಿಕ್ಕಿ',
'ಕಾಡುಬುಡ್ಡೆ',
'ಕಾಡುಬೆಂಡೆ',
'ಕಾಡುಬೆಕ್ಕು',
'ಕಾಡುಬೇಲ',
'ಕಾಡುಬೇವು',
'ಕಾಡುಭಾಷೆ',
'ಕಾಡುಮರ',
'ಕಾಡುಮಾವು',
'ಕಾಡುಮಿಗ',
'ಕಾಡುಮೃಗ',
'ಕಾಡುಮೆಂತ್ಯ',
'ಕಾಡುಮೇಕೆ',
'ಕಾಡುಮೇಡು',
'ಕಾಡುಮೈನ',
'ಕಾಡುಮೊಲ್ಲೆ',
'ಕಾಡುರುಳಿ',
'ಕಾಡುರೂಢಿ',
'ಕಾಡುರೋಗ',
'ಕಾಡುಲಿಂಬೆ',
'ಕಾಡುವಾಯ್',
'ಕಾಡುವಾಮೆ',
'ಕಾಡುಶುಂಠಿ',
'ಕಾಡುಸಾಮೆ',
'ಕಾಡುಸಾವೆ',
'ಕಾಡುಸೀಗೆ',
'ಕಾಡುಸುಂಡೆ',
'ಕಾಡುಸೆಂಬೆ',
'ಕಾಡುಸೇಬು',
'ಕಾಡುಸೊಂಡೆ',
'ಕಾಡುಸೊಳ್ಳೆ',
'ಕಾಡುಸೋಂಪು',
'ಕಾಡುಸೌತೆ',
'ಕಾಡುಸ್ಥಿತಿ',
'ಕಾಡುಹಂದಿ',
'ಕಾಡುಹಂಬು',
'ಕಾಡುಹತ್ತಿ',
'ಕಾಡುಹಳ್ಳಿ',
'ಕಾಡುಹಿಪ್ಪೆ',
'ಕಾಡುಹೀರೆ',
'ಕಾಡುಹುಲ್ಲು',
'ಕಾಡುಹುಳ್ಳಿ',
'ಕಾಡುಹೋರೆ',
'ಕಾಡೆರಳೆ',
'ಕಾಡೆಹಕ್ಕಿ',
'ಕಾಡೊಡಮೆ',
'ಕಾಡೊಡೆಯ',
'ಕಾಣಒತ್ತೆ',
'ಕಾಣಭುಜ',
'ಕಾಣಕಾಣ',
'ಕಾಣಿಕಾಱ',
'ಕಾಣಿಕೆಯ',
'ಕಾಣಿಕೆಯೀ',
'ಕಾಣಿಕೊಂಕು',
'ಕಾಣಿತೂಕ',
'ಕಾಣಿಪೇರು',
'ಕಾಣಿಯಾಚಿ',
'ಕಾಣಿಯಾಲ',
'ಕಾಣಿಲ್ಲಮೆ',
'ಕಾಣ್ಕೆಗೊಡು',
'ಕಾಣ್ಕೆವೊಲ',
'ಕಾಣ್ಪಡಿಸು',
'ಕಾಣ್ಬಡಿಸು',
'ಕಾಣ್ವಶಾಖೆ',
'ಕಾತಕರಿ',
'ಕಾತಘಾತ',
'ಕಾತರತೆ',
'ಕಾತರಿಕೆ',
'ಕಾತರಿಕ್ಕೆ',
'ಕಾತರಿಗ',
'ಕಾತರಿತ',
'ಕಾತರಿಸು',
'ಕಾತಲಿಸು',
'ಕಾತಳಿಗ',
'ಕಾತಳಿಸು',
'ಕಾತುರತೆ',
'ಕಾತ್ಯಾಯನಿ',
'ಕಾದಂಬರಿ',
'ಕಾದಂಬಿನಿ',
'ಕಾದಕರೆ',
'ಕಾದರಾಯಿ',
'ಕಾದಲಿಸು',
'ಕಾದಾಳ್ತನ',
'ಕಾದ್ರವೇಯ',
'ಕಾನ್‍ಮಿಗ',
'ಕಾನತೇರು',
'ಕಾನನಾರಿ',
'ಕಾನಮರ',
'ಕಾನಸೊಪ್ಪು',
'ಕಾನುತೋಟ',
'ಕಾಪಟಿಕ',
'ಕಾಪಟಿಗ',
'ಕಾಪಾಟಿಕ',
'ಕಾಪಾಡಿಸು',
'ಕಾಪಾಲಿಕ',
'ಕಾಪಾಳಿಕ',
'ಕಾಪಿಚಟ್ಟು',
'ಕಾಪಿರಿಸು',
'ಕಾಪುಕಲಿ',
'ಕಾಪುಗಾಡು',
'ಕಾಪುಗೊಡು',
'ಕಾಪುಗೊಳ್',
'ಕಾಪುತಡೆ',
'ಕಾಪುರಾಪು',
'ಕಾಪುರುಷ',
'ಕಾಪುವಡೆ',
'ಕಾಫಿದಾನಿ',
'ಕಾಬುಱಲೆ',
'ಕಾಮಂಡಲಿ',
'ಕಾಮಕಥೆ',
'ಕಾಮಕರ',
'ಕಾಮಕಲೆ',
'ಕಾಮಕಾರ',
'ಕಾಮಕೂಟ',
'ಕಾಮಕೇರಿ',
'ಕಾಮಕೇಲಿ',
'ಕಾಮಕೇಳಿ',
'ಕಾಮಕ್ಲಿಷ್ಟೆ',
'ಕಾಮಗಣ',
'ಕಾಮಗಾಮಿ',
'ಕಾಮಗಾರ',
'ಕಾಮಗಾರಿ',
'ಕಾಮಗಿರಿ',
'ಕಾಮಗೀತೆ',
'ಕಾಮಗ್ರಹ',
'ಕಾಮಚರ್ಯೆ',
'ಕಾಮಚಾಪ',
'ಕಾಮಚಾರಿ',
'ಕಾಮಚೇಷ್ಟೆ',
'ಕಾಮಚ್ಛತ್ರ',
'ಕಾಮಜೇನಿ',
'ಕಾಮಜ್ವರ',
'ಕಾಮತಂತ್ರ',
'ಕಾಮತೃಷೆ',
'ಕಾಮತೃಷ್ಣೆ',
'ಕಾಮದಾರ',
'ಕಾಮದುಘ',
'ಕಾಮದುಘೆ',
'ಕಾಮದೃಷ್ಟಿ',
'ಕಾಮದೇವ',
'ಕಾಮಧನು',
'ಕಾಮಧೇನು',
'ಕಾಮಧ್ವಂಸಿ',
'ಕಾಮಧ್ವಜ',
'ಕಾಮಪತ್ನಿ',
'ಕಾಮಪಾಲ',
'ಕಾಮಪಾಳ',
'ಕಾಮಬಾಣ',
'ಕಾಮಭಕ್ಷ',
'ಕಾಮಯಿತೃ',
'ಕಾಮರಾಗ',
'ಕಾಮರೂಪ',
'ಕಾಮರೂಪಿ',
'ಕಾಮರೂಪೆ',
'ಕಾಮಲತೆ',
'ಕಾಮವಟ್ಟ',
'ಕಾಮವಾದ',
'ಕಾಮವೃದ್ಧಿ',
'ಕಾಮವೈರಿ',
'ಕಾಮಶಾಸ್ತ್ರ',
'ಕಾಮಶುದ್ಧಿ',
'ಕಾಮಸನ್ನಿ',
'ಕಾಮಸೂತ್ರ',
'ಕಾಮಹತ',
'ಕಾಮಹತೆ',
'ಕಾಮಹರ',
'ಕಾಮಾಂಕಿಯ',
'ಕಾಮಾಂಕುಶ',
'ಕಾಮಾಂತಕ',
'ಕಾಮಾಂಧಕ',
'ಕಾಮಾಂಧತೆ',
'ಕಾಮಾಟಿಕೆ',
'ಕಾಮಾಟಿಗ',
'ಕಾಮಾತುರ',
'ಕಾಮಾತುರೆ',
'ಕಾಮಾಸಕ್ತ',
'ಕಾಮಾಸಕ್ತೆ',
'ಕಾಮಿತಾರ್ಥ',
'ಕಾಮುಕತೆ',
'ಕಾಮುರಳಿ',
'ಕಾಮೋತ್ಕಟ',
'ಕಾಮೋದ್ಭವ',
'ಕಾಮ್ಯಕರ್ಮ',
'ಕಾಮ್ಯದಾನ',
'ಕಾಯಂಗುತ್ತ',
'ಕಾಯಕಟ್ಟು',
'ಕಾಯಕಲ್ಪ',
'ಕಾಯಕಷ್ಟ',
'ಕಾಯಕಾಂತಿ',
'ಕಾಯಕಾರ',
'ಕಾಯಕ್ಲೇಶ',
'ಕಾಯಗಟ್ಟು',
'ಕಾಯಗುಣ',
'ಕಾಯಗುಪ್ತಿ',
'ಕಾಯಗೊಳ್',
'ಕಾಯಜಾತ',
'ಕಾಯಜಾರಿ',
'ಕಾಯಡಕೆ',
'ಕಾಯತೊಟ್ಟಿ',
'ಕಾಯಪಲ್ಲೆ',
'ಕಾಯಪುಸಿ',
'ಕಾಯಫಳ',
'ಕಾಯಬಲ',
'ಕಾಯಭವ',
'ಕಾಯಮಲ',
'ಕಾಯಮಾನ',
'ಕಾಯಮಾಱಿ',
'ಕಾಯಮುಕ್ತ',
'ಕಾಯವಿಡಿ',
'ಕಾಯವ್ಯೂಹ',
'ಕಾಯಸಂಗ',
'ಕಾಯಸಿದ್ಧಿ',
'ಕಾಯಸುಖ',
'ಕಾಯಸ್ಥಿತಿ',
'ಕಾಯಿಕಟ್ಟು',
'ಕಾಯಿಕತ್ತ',
'ಕಾಯಿಗಟ್ಟು',
'ಕಾಯಿಗಣ್ಣು',
'ಕಾಯಿಧೂಪ',
'ಕಾಯಿಪಲ್ಯ',
'ಕಾಯಿಪಲ್ಲೆ',
'ಕಾಯಿರಸ',
'ಕಾಯಿಸೊಪ್ಪು',
'ಕಾಯಿಸೋರೆ',
'ಕಾಯಿಹಾಲು',
'ಕಾಯಿಹುಳಿ',
'ಕಾಯೋತ್ಸರ್ಗ',
'ಕಾಯ್ಪುಗೊಳ್',
'ಕಾಯ್ಪುಡುಗು',
'ಕಾಯ್ಪುವೆಱು',
'ಕಾಯ್ಬಿನದ',
'ಕಾರಂಡವ',
'ಕಾರಕತ್ವ',
'ಕಾರಕರ್ದಿ',
'ಕಾರಕಾಂಶ',
'ಕಾರಕೂನ',
'ಕಾರಕೂನಿ',
'ಕಾರಕೆಯ್',
'ಕಾರಖಾನೆ',
'ಕಾರಗದ್ದೆ',
'ಕಾರಗಪ್ಪೆ',
'ಕಾರಗಾರ',
'ಕಾರಗಿಲ್',
'ಕಾರಗಿಲು',
'ಕಾರಡವಿ',
'ಕಾರಣತೆ',
'ಕಾರಣತ್ವ',
'ಕಾರಣವ',
'ಕಾರಣಿಕ',
'ಕಾರಣಿಕೆ',
'ಕಾರಣೀಕ',
'ಕಾರದಾಣಿ',
'ಕಾರನೊಚ್ಚಿ',
'ಕಾರಪಕ',
'ಕಾರಪಾಸ',
'ಕಾರಬಾರ',
'ಕಾರಬಾರು',
'ಕಾರಭತ್ತ',
'ಕಾರಭಾರ',
'ಕಾರಭಾರಿ',
'ಕಾರಭಾರು',
'ಕಾರಮಣಿ',
'ಕಾರಮಳೆ',
'ಕಾರಮಿಂಚು',
'ಕಾರಮೇಘ',
'ಕಾರಯಿತೃ',
'ಕಾರಯಿತ್ರೀ',
'ಕಾರರಳು',
'ಕಾರವಾನ್',
'ಕಾರವಾನ',
'ಕಾರವೇಲ್ಲ',
'ಕಾರಸ್ಥಾನ',
'ಕಾರಸ್ಥಾನಿ',
'ಕಾರಹಕ',
'ಕಾರಹಬ್ಬ',
'ಕಾರಹುಲ್ಲು',
'ಕಾರಳಗ',
'ಕಾರಱಿವು',
'ಕಾರಾಕಳ್',
'ಕಾರಾಗಾರ',
'ಕಾರಾಗಾರಿ',
'ಕಾರಾಗೃಹ',
'ಕಾರಾದಂಡ',
'ಕಾರಾಮಣಿ',
'ಕಾರಾಲಿಯ',
'ಕಾರಾವರ',
'ಕಾರಾವಾಸ',
'ಕಾರಾಸೇವೆ',
'ಕಾರಾಳುಮೆ',
'ಕಾರಿದ್ದಲು',
'ಕಾರಿದ್ದಿಲು',
'ಕಾರಿಯಗ',
'ಕಾರಿರುಳ್',
'ಕಾರಿರುಳು',
'ಕಾರುಕರ್ಮ',
'ಕಾರುಕಲ್ಲು',
'ಕಾರುಕಾರ್ಯ',
'ಕಾರುಕೃತ್ಯ',
'ಕಾರುಖಾನೆ',
'ಕಾರುಗದ್ದೆ',
'ಕಾರುಗಲ್ಲು',
'ಕಾರುಗೃಹ',
'ಕಾರುಣಾತ್ಮ',
'ಕಾರುಣಿಕ',
'ಕಾರುನೀಲಿ',
'ಕಾರುನೆಲ್ಲು',
'ಕಾರುಬಾರಿ',
'ಕಾರುಬಾರು',
'ಕಾರುಬೆಳೆ',
'ಕಾರುಭತ್ತ',
'ಕಾರುಭಾರ',
'ಕಾರುಭಾರಿ',
'ಕಾರುಭಾರು',
'ಕಾರುಭೂಮಿ',
'ಕಾರುಮೋಡ',
'ಕಾರುಶರ',
'ಕಾರುಸೇಸೆ',
'ಕಾರೆಕಾಡು',
'ಕಾರೆಮಟ್ಟು',
'ಕಾರೆರಕೆ',
'ಕಾರೇಕಾರ',
'ಕಾರೇಗಾರ',
'ಕಾರೊಕ್ಕಲ್',
'ಕಾರೊಡಲ್',
'ಕಾರೊಡಲ',
'ಕಾರೊಡಲು',
'ಕಾರೊಡ್ಡಣ',
'ಕಾರೊಸಡು',
'ಕಾರೋತ್ತರ',
'ಕಾರೋಪಕ',
'ಕಾರ್ಕೋಟಕ',
'ಕಾರ್ಗತ್ತಲು',
'ಕಾರ್ಗತ್ತಲೆ',
'ಕಾರ್ಗಲಸು',
'ಕಾರ್ಗುಂತಳ',
'ಕಾರ್ಗುಂತಳೆ',
'ಕಾರ್ಗುರುಳು',
'ಕಾರ್ಗುರುಳೆ',
'ಕಾರ್ಗೆಣಸು',
'ಕಾರ್ಗೊಳಿಸು',
'ಕಾರ್ಡುದಾರ',
'ಕಾರ್ತಸ್ವರ',
'ಕಾರ್ತಾಂತಿಕ',
'ಕಾರ್ತಿಕಾಯಿ',
'ಕಾರ್ತಿಕಿಕ',
'ಕಾರ್ತಿಕೇಯ',
'ಕಾರ್ಪಟಿಕ',
'ಕಾರ್ಪಾಸಿಕ',
'ಕಾರ್ಬಟ್ಟೆಗ',
'ಕಾರ್ಬೊನ್ಗಲ್',
'ಕಾರ್ಮಮಲ',
'ಕಾರ್ಮುಂಬನಿ',
'ಕಾರ್ಮುಗಿಲ್',
'ಕಾರ್ಮುಗಿಲು',
'ಕಾರ್ಮೊಳಗು',
'ಕಾರ್ಯಕಾರಿ',
'ಕಾರ್ಯಕ್ರಮ',
'ಕಾರ್ಯಕ್ಷಮ',
'ಕಾರ್ಯಕ್ಷೇತ್ರ',
'ಕಾರ್ಯಗತ',
'ಕಾರ್ಯಗೇಡಿ',
'ಕಾರ್ಯತಂತ್ರ',
'ಕಾರ್ಯತಂತ್ರಿ',
'ಕಾರ್ಯದಕ್ಷ',
'ಕಾರ್ಯದಕ್ಷೆ',
'ಕಾರ್ಯಧಾರೆ',
'ಕಾರ್ಯಧೀರ',
'ಕಾರ್ಯಧೀರೆ',
'ಕಾರ್ಯನೀತಿ',
'ಕಾರ್ಯಪಟು',
'ಕಾರ್ಯಭಂಗ',
'ಕಾರ್ಯಭರ',
'ಕಾರ್ಯಭಾಗ',
'ಕಾರ್ಯಭಾರಿ',
'ಕಾರ್ಯಮಂತ',
'ಕಾರ್ಯಮಗ್ನ',
'ಕಾರ್ಯಮಗ್ನೆ',
'ಕಾರ್ಯರಂಗ',
'ಕಾರ್ಯಲಗ್ನ',
'ಕಾರ್ಯವಾಸಿ',
'ಕಾರ್ಯವಾಹಿ',
'ಕಾರ್ಯವೀರ',
'ಕಾರ್ಯಶಾಲೆ',
'ಕಾರ್ಯಸಖ',
'ಕಾರ್ಯಸಖಿ',
'ಕಾರ್ಯಸಿದ್ಧಿ',
'ಕಾರ್ಯಸೂಚಿ',
'ಕಾರ್ಯಾಂತರ',
'ಕಾರ್ಯಾಗಾರ',
'ಕಾರ್ಯಾತುರ',
'ಕಾರ್ಯಾತುರೆ',
'ಕಾರ್ಯಾತ್ಮಕ',
'ಕಾರ್ಯಾಲಯ',
'ಕಾರ್ವಗಲ್',
'ಕಾರ್ವಟ್ಟೆಗ',
'ಕಾರ್ಷಾಪಣ',
'ಕಾಲಂಕೆಯ್',
'ಕಾಲಂಗಾಣ್',
'ಕಾಲಂಗುಲಿ',
'ಕಾಲಂಗೆಯ್',
'ಕಾಲಂದಿಗೆ',
'ಕಾಲಂದುಗೆ',
'ಕಾಲಕಂಠ',
'ಕಾಲಕಡು',
'ಕಾಲಕಣ್ಣಿ',
'ಕಾಲಕರ್ಮ',
'ಕಾಲಕಲ್ಪ',
'ಕಾಲಕಳೆ',
'ಕಾಲಕಾಲ',
'ಕಾಲಕೀರ್ದಿ',
'ಕಾಲಕೂಟ',
'ಕಾಲಕ್ರಮ',
'ಕಾಲಕ್ಷೇಪ',
'ಕಾಲಕ್ಷೇಪಿ',
'ಕಾಲಖಂಡ',
'ಕಾಲಗತಿ',
'ಕಾಲಗರ್ಭ',
'ಕಾಲಗುಣ',
'ಕಾಲಗೆಜ್ಜೆ',
'ಕಾಲಚಕ್ರ',
'ಕಾಲಜಿತು',
'ಕಾಲಜ್ಞಾನ',
'ಕಾಲಜ್ಞಾನಿ',
'ಕಾಲತಳ',
'ಕಾಲತ್ರಯ',
'ಕಾಲದಂಡ',
'ಕಾಲದಂಷ್ಟ್ರ',
'ಕಾಲದರ್ಶಿ',
'ಕಾಲದೂತ',
'ಕಾಲದ್ರವ್ಯ',
'ಕಾಲಧರ್ಮ',
'ಕಾಲನಿಷ್ಠೆ',
'ಕಾಲಪಥ',
'ಕಾಲಪಾಶ',
'ಕಾಲಪೃಷ್ಠ',
'ಕಾಲಪ್ರಜ್ಞೆ',
'ಕಾಲಬದ್ಧ',
'ಕಾಲಬೀಟೆ',
'ಕಾಲಬೇಗ',
'ಕಾಲಭೇದ',
'ಕಾಲಭ್ರಮೆ',
'ಕಾಲಮಾನ',
'ಕಾಲಮಿತಿ',
'ಕಾಲಮುಖ',
'ಕಾಲಮೂಲ',
'ಕಾಲಮೃತ್ಯು',
'ಕಾಲಮೇಘ',
'ಕಾಲಮೇಷಿ',
'ಕಾಲಯಂತ್ರ',
'ಕಾಲಯಮ',
'ಕಾಲರಳಿ',
'ಕಾಲರಾಜ',
'ಕಾಲರಾತ್ರ',
'ಕಾಲರಾತ್ರಿ',
'ಕಾಲರಾಯ',
'ಕಾಲರುದ್ರ',
'ಕಾಲಲಬ್ಧಿ',
'ಕಾಲಲೀಲೆ',
'ಕಾಲವಶ',
'ಕಾಲವಾಚಿ',
'ಕಾಲವೇದಿ',
'ಕಾಲವೈರಿ',
'ಕಾಲವ್ಯಾಪಿ',
'ಕಾಲಶಾಕ',
'ಕಾಲಶಿವ',
'ಕಾಲಶ್ರಾದ್ಧ',
'ಕಾಲಸರ್ಪ',
'ಕಾಲಸೂತ್ರ',
'ಕಾಲಸ್ಕಂದ',
'ಕಾಲಹರ',
'ಕಾಲಹಾಕು',
'ಕಾಲಾಂಜನ',
'ಕಾಲಾಂತಕ',
'ಕಾಲಾಂತರ',
'ಕಾಲಾಗರು',
'ಕಾಲಾಗುರು',
'ಕಾಲಾಚಾರ',
'ಕಾಲಾಡಿಸು',
'ಕಾಲಾಧೀನ',
'ಕಾಲಾನಲ',
'ಕಾಲಾನಳ',
'ಕಾಲಾಪಾನಿ',
'ಕಾಲಾಮುಖ',
'ಕಾಲಾಯಸ',
'ಕಾಲಾವದಿ',
'ಕಾಲಾವಧಿ',
'ಕಾಲಿರಿಸು',
'ಕಾಲೀಯಕ',
'ಕಾಲುಂಗರ',
'ಕಾಲುಂಗುರ',
'ಕಾಲುಕಟ್ಟು',
'ಕಾಲುಕಣ್ಣಿ',
'ಕಾಲುಕೀಳು',
'ಕಾಲುಕೆಡು',
'ಕಾಲುಕೆರೆ',
'ಕಾಲುಗಂಟು',
'ಕಾಲುಗಚ್ಚು',
'ಕಾಲುಗಣ್ಣಿ',
'ಕಾಲುಗಾಡಿ',
'ಕಾಲುಗಾಲು',
'ಕಾಲುಗಾಹು',
'ಕಾಲುಗುಣ',
'ಕಾಲುಗುರ್',
'ಕಾಲುಗೆಡು',
'ಕಾಲುಗೊಲೆ',
'ಕಾಲುಚಾಚು',
'ಕಾಲುಚೀಲ',
'ಕಾಲುಜಾರು',
'ಕಾಲುಜ್ವರ',
'ಕಾಲುಡುಗು',
'ಕಾಲುತೆಗೆ',
'ಕಾಲುತೊಳೆ',
'ಕಾಲುದಳ',
'ಕಾಲುದಾರಿ',
'ಕಾಲುದೀಪ',
'ಕಾಲುದುಳಿ',
'ಕಾಲುದೂಳಿ',
'ಕಾಲುದೂಳು',
'ಕಾಲುದೊತ್ತು',
'ಕಾಲುನಡೆ',
'ಕಾಲುನೆಕ್ಕು',
'ಕಾಲುಪಚ್ಚೆ',
'ಕಾಲುಪಳ್ಳಿ',
'ಕಾಲುಪ್ಪರ',
'ಕಾಲುಫಳಿ',
'ಕಾಲುಬಲ',
'ಕಾಲುಬಳೆ',
'ಕಾಲುಬೀದಿ',
'ಕಾಲುಬೀಳು',
'ಕಾಲುಮಡಿ',
'ಕಾಲುಮಣೆ',
'ಕಾಲುಮರೆ',
'ಕಾಲುಮಾಡು',
'ಕಾಲುಮೂಳಿ',
'ಕಾಲುಮೆಟ್ಟು',
'ಕಾಲುರಂಜು',
'ಕಾಲುರಿಚ',
'ಕಾಲುರಿಚೆ',
'ಕಾಲುವಟ್ಟೆ',
'ಕಾಲುವಲೆ',
'ಕಾಲುವಳಿ',
'ಕಾಲುವಳ್ಳಿ',
'ಕಾಲುವಾಡ',
'ಕಾಲುವಾಮೆ',
'ಕಾಲುವಾಸಿ',
'ಕಾಲುವಿಡಿ',
'ಕಾಲುವೇಗ',
'ಕಾಲುವೊಳೆ',
'ಕಾಲುಸರ',
'ಕಾಲುಸುತ್ತು',
'ಕಾಲುಹಳ್ಳಿ',
'ಕಾಲುಹಾಕು',
'ಕಾಲುಹಾದಿ',
'ಕಾಲುಹಾರು',
'ಕಾಲುಹಿಡಿ',
'ಕಾಲುಹೊಳೆ',
'ಕಾಲೂರಿಗ',
'ಕಾಲೂಳಿಗ',
'ಕಾಲೇಯಕ',
'ಕಾಲೊರಸು',
'ಕಾಲೋಚಿತ',
'ಕಾಲೋರಗ',
'ಕಾಲೋಹಣ',
'ಕಾಲ್ಕುಪ್ಪಸ',
'ಕಾಲ್ಕೆದರು',
'ಕಾಲ್ಗಟ್ಟಿಗೆ',
'ಕಾಲ್ಗಡಗ',
'ಕಾಲ್ಗಡೆಯ',
'ಕಾಲ್ಗರ್ಚಿಸು',
'ಕಾಲ್ಗಿಡಿಸು',
'ಕಾಲ್ಗುಪ್ಪಸ',
'ಕಾಲ್ಗುಱುಹು',
'ಕಾಲ್ಗೆಡಿಸು',
'ಕಾಲ್ಗೆರಹು',
'ಕಾಲ್ಗೊರವಿ',
'ಕಾಲ್ಗೊರಸು',
'ಕಾಲ್ಗೊಳಿಸು',
'ಕಾಲ್ತುಳಿತ',
'ಕಾಲ್ತೆಗಿಸು',
'ಕಾಲ್ತೊಡಕು',
'ಕಾಲ್ತೊಡರ್',
'ಕಾಲ್ದುಳಿತ',
'ಕಾಲ್ದೊಡಕು',
'ಕಾಲ್ದೊಡರ್',
'ಕಾಲ್ದೊಡಿಗೆ',
'ಕಾಲ್ದೊಳಸು',
'ಕಾಲ್ನಿಗಳ',
'ಕಾಲ್ಪನಿಕ',
'ಕಾಲ್ಬೆರಳು',
'ಕಾಲ್ಮಗುೞ್ಚು',
'ಕಾಲ್ಮುರುಕ',
'ಕಾಲ್ಯಕೃತ್ಯ',
'ಕಾಲ್ವಲಗೆ',
'ಕಾಲ್ವಲ್ಲಿದ',
'ಕಾಲ್ವಿಡಿತ',
'ಕಾಲ್ವೊನಲ್',
'ಕಾವಕೂಲ',
'ಕಾವಕುಳ್ಳ',
'ಕಾವಕೂಳ',
'ಕಾವಚಿಕ',
'ಕಾವದೇವ',
'ಕಾವಧ್ವಂಸಿ',
'ಕಾವರಾಯ',
'ಕಾವರಿಸು',
'ಕಾವಲಿಕ್ಕು',
'ಕಾವಲಿಗ',
'ಕಾವಲಿಗೆ',
'ಕಾವಲ್ಗುಡು',
'ಕಾವಲ್ಜನ',
'ಕಾವಳಿಸು',
'ಕಾವಾಕುಲ',
'ಕಾವಾಕುಳ',
'ಕಾವಾಕೂಲ',
'ಕಾವಾಕೂಳ',
'ಕಾವಿಕಲ್ಲು',
'ಕಾವಿಖಣಿ',
'ಕಾವಿಗಲ್',
'ಕಾವಿಟ್ಟಿಗೆ',
'ಕಾವುಕಟ್ಟು',
'ಕಾವುಗಣ್ಣು',
'ಕಾವುಜೇಡು',
'ಕಾವುತೆರೆ',
'ಕಾವೇರಿಕೆ',
'ಕಾವ್ಯಕರ್ತೃ',
'ಕಾವ್ಯಕೃತಿ',
'ಕಾವ್ಯಗೋಷ್ಠಿ',
'ಕಾವ್ಯನಾಮ',
'ಕಾವ್ಯನ್ಯಾಯ',
'ಕಾವ್ಯಪ್ರಜ್ಞೆ',
'ಕಾವ್ಯಬಂಧ',
'ಕಾವ್ಯಮಯ',
'ಕಾವ್ಯಮಲ',
'ಕಾವ್ಯಮರ್ಮ',
'ಕಾವ್ಯರೇಖೆ',
'ಕಾವ್ಯಲಿಂಗ',
'ಕಾವ್ಯವಸ್ತು',
'ಕಾವ್ಯವಿದ',
'ಕಾವ್ಯವಿದೆ',
'ಕಾವ್ಯಾನಂದ',
'ಕಾವ್ಯಾಭಾಸ',
'ಕಾಶಕಬ್ಬು',
'ಕಾಶಗಬ್ಬು',
'ಕಾಶಾಂಬರ',
'ಕಾಶಾರ್ಪಟ',
'ಕಾಶಿಕುಪ್ಪೆ',
'ಕಾಶಿತಾಲಿ',
'ಕಾಶಿತುಂಬೆ',
'ಕಾಶಿನಾಥ',
'ಕಾಶಿಪಟ್ಟೆ',
'ಕಾಶಿಬಿಂದು',
'ಕಾಶಿಹುಲ್ಲು',
'ಕಾಶೀಯಾತ್ರೆ',
'ಕಾಶ್ಮರಿಯ',
'ಕಾಶ್ಮೀರಜ',
'ಕಾಷ್ಠಕರ್ಮ',
'ಕಾಷ್ಠಜ್ಯೋತಿ',
'ಕಾಷ್ಠನಲ್ಲಿ',
'ಕಾಷ್ಠಬಾೞೆ',
'ಕಾಷ್ಠಭಾರ',
'ಕಾಷ್ಠಾಂಬರ',
'ಕಾಷ್ಠಾಗುರು',
'ಕಾಷ್ಠಾಘಾತ',
'ಕಾಸಗಾರ',
'ಕಾಸಜಿತ್ತು',
'ಕಾಸದಾರ',
'ಕಾಸಮರ್ದ',
'ಕಾಸರಿಕೆ',
'ಕಾಸರಿಗ',
'ಕಾಸರ್ಕಣ',
'ಕಾಸಲಿಕೆ',
'ಕಾಸಾದಂಡ',
'ಕಾಸಾವೀಶಿ',
'ಕಾಸಾವೀಸಿ',
'ಕಾಸಿಹುಲ್ಲು',
'ಕಾಸುದಾರ',
'ಕಾಸುವೀಸ',
'ಕಾಸೆಗಟ್ಟು',
'ಕಾಹುರತೆ',
'ಕಾಹೊನಲ್',
'ಕಾಳಂಗೆಯ್',
'ಕಾಳಕಂಠ',
'ಕಾಳಕೂಟ',
'ಕಾಳತನ',
'ಕಾಳದಂಡ',
'ಕಾಳನಿದ್ರೆ',
'ಕಾಳಬುದ್ಧಿ',
'ಕಾಳಮತ್ರ್ಯ',
'ಕಾಳಮಾಳೆ',
'ಕಾಳಮುೂತ್ರ',
'ಕಾಳಮೇಘ',
'ಕಾಳಯಮ',
'ಕಾಳಯುಕ್ತಿ',
'ಕಾಳರಸು',
'ಕಾಳರಾಜ',
'ಕಾಳರಾತ್ರಿ',
'ಕಾಳವಟ್ಟ',
'ಕಾಳಸಂಚು',
'ಕಾಳಸಂತೆ',
'ಕಾಳಸರ್ಪ',
'ಕಾಳಸ್ಕಂಧ',
'ಕಾಳಹಸ್ತ',
'ಕಾಳಹೊಗೆ',
'ಕಾಳಾಂಜನ',
'ಕಾಳಾಂಜನಿ',
'ಕಾಳಾಂಜಿಗೆ',
'ಕಾಳಾಕಳು',
'ಕಾಳಾಗರು',
'ಕಾಳಾಗಿಸು',
'ಕಾಳಾಗುರು',
'ಕಾಳಾನಲ',
'ಕಾಳಾಪಾಣಿ',
'ಕಾಳಾಮುಖ',
'ಕಾಳಾಯಸ',
'ಕಾಳಾಸಿಸು',
'ಕಾಳಿಂಗದ',
'ಕಾಳಿನಾಥ',
'ಕಾಳಿಸೊಪ್ಪು',
'ಕಾಳುಕಡಿ',
'ಕಾಳುಕಡ್ಡಿ',
'ಕಾಳುಕಾಳು',
'ಕಾಳುಕಿಚ್ಚು',
'ಕಾಳುಗಟ್ಟು',
'ಕಾಳುಗಿಚ್ಚು',
'ಕಾಳುಗೆಡು',
'ಕಾಳುಗೆಡೆ',
'ಕಾಳುತನ',
'ಕಾಳುದೇಹ',
'ಕಾಳುದೊತ್ತು',
'ಕಾಳುನುಡಿ',
'ಕಾಳುಮತಿ',
'ಕಾಳುಮಾಡು',
'ಕಾಳುಮಾತು',
'ಕಾಳುಮಾರ್ಗ',
'ಕಾಳುಮುಂಡೆ',
'ಕಾಳುಮೂಳ',
'ಕಾಳುಯತ್ಲೆ',
'ಕಾಳುಶಾಪ',
'ಕಾಳೆಮಾಳೆ',
'ಕಾಳೆವಾಳೆ',
'ಕಾಳೋರಗ',
'ಕಾಳ್ಕತ್ತಲೆ',
'ಕಾಳ್ಕರಡಿ',
'ಕಾಳ್ಗಳಪ',
'ಕಾಳ್ಗೆಡಹು',
'ಕಾಳ್ಗೆಲಸ',
'ಕಾಳ್ಪಲಸು',
'ಕಾಳ್ಪೊನಲ್',
'ಕಾಳ್ಬದುಕು',
'ಕಾಳ್ಬಿಯದ',
'ಕಾಳ್ಬೇಡಿತಿ',
'ಕಾಳ್ಮಾನಿಸ',
'ಕಾಳ್ವೊನಲ್',
'ಕಾಳ್ವೊನಲು',
'ಕಾಱುಗಿಡಿ',
'ಕಾಱುದೆಱೆ',
'ಕಾಱುಮದ್ದು',
'ಕಾೞುಕಿಚ್ಚು',
'ಕಾೞುಗಬ್ಬು',
'ಕಾೞುಮಾಡು',
'ಕಾೞುಮೂೞ',
'ಕಾೞ್ಕುರುಳ್',
'ಕಾೞ್ಗರಡ',
'ಕಾೞ್ಜೀರಗೆ',
'ಕಾೞ್ಪಲಸು',
'ಕಾೞ್ಪೆಸರ್',
'ಕಾೞ್ಪೊನಲ್',
'ಕಾೞ್ಬೆಱಣಿ',
'ಕಾೞ್ಬೆಸನ',
'ಕಾೞ್ಮೆಣಸು',
'ಕಾೞ್ವೊನಲ್',
'ಕಿಂಕರಣ',
'ಕಿಂಕರತೆ',
'ಕಿಂಕರತ್ವ',
'ಕಿಂಕಿರಾತ',
'ಕಿಂಕಿಲಿಸು',
'ಕಿಂಕುರ್ವಾಣ',
'ಕಿಂಖಲಿಸು',
'ಕಿಂಗಹನ',
'ಕಿಂಚಿಜ್ಞತ್ವ',
'ಕಿಂಚಿತ್ಕಾರ',
'ಕಿಂಚಿದೂನ',
'ಕಿಂಚುಲಕ',
'ಕಿಂಚುಳಕ',
'ಕಿಂಜಳಕ',
'ಕಿಂಡಿಹಲ್ಲು',
'ಕಿಂಪಚಾನ',
'ಕಿಂಪುರುಷ',
'ಕಿಂಬುರಣಿ',
'ಕಿಂಬಹುನಾ',
'ಕಿಂವದಂತಿ',
'ಕಿಕೀದಿವ',
'ಕಿಕೀದಿವಿ',
'ಕಿಕ್ಕಣ್ಣಿಡು',
'ಕಿಕ್ಕರಿಸು',
'ಕಿಕ್ಕಿರಿತ',
'ಕಿಕ್ಕಿಲಿಕೆ',
'ಕಿಕ್ಕಿಱಿತ',
'ಕಿಕ್ಕಿಱಿಸು',
'ಕಿಗ್ಗಠಾರಿ',
'ಕಿಗ್ಗಡಲು',
'ಕಿಗ್ಗಣ್ಣಿಕ್ಕು',
'ಕಿಗ್ಗಾರಣೆ',
'ಕಿಗ್ಗಿಱಿಯ',
'ಕಿಗ್ಗುಲಜ',
'ಕಿಚಗುಟ್ಟು',
'ಕಿಚಾಯಿಸು',
'ಕಿಚಿಕಿಲ್',
'ಕಿಚಿಗುಟ್ಟು',
'ಕಿಚುಗುಟ್ಟು',
'ಕಿಚ್ಚಚ್ಚಿಗ',
'ಕಿಚ್ಚಿಕ್ಕಿಸು',
'ಕಿಚ್ಚುಗಣ್',
'ಕಿಚ್ಚುಗಣ್ಣ',
'ಕಿಚ್ಚುಗಣ್ಣು',
'ಕಿಚ್ಚುಗಾರ',
'ಕಿಚ್ಚುಗಿಡಿ',
'ಕಿಚ್ಚುಗುತ್ತು',
'ಕಿಚ್ಚುಗೋಂಟೆ',
'ಕಿಚ್ಚುಗೋಲ್',
'ಕಿಚ್ಚುಪೊಗು',
'ಕಿಚ್ಚುಮಿಂಚು',
'ಕಿಚ್ಚುವುಗು',
'ಕಿಚ್ಚುವೊಗು',
'ಕಿಜಮತ್ತು',
'ಕಿಟಾರನೆ',
'ಕಿಟಿಕಿಟ',
'ಕಿಟಿಕಿಟಿ',
'ಕಿಟಿಕೀಟಿ',
'ಕಿಟಿಪಿಟಿ',
'ಕಿಟಿಮಿಟಿ',
'ಕಿಟ್ಟಕೀಳ',
'ಕಿಟ್ಟಗಟ್ಟು',
'ಕಿಟ್ಟಗೂಡು',
'ಕಿಟ್ಟಾಲಯ',
'ಕಿಡಿಂಜೆಲು',
'ಕಿಡಿಗಂಡಿ',
'ಕಿಡಿಗಣ್',
'ಕಿಡಿಗಣ್ಣಿ',
'ಕಿಡಿಗಣ್ಣ',
'ಕಿಡಿಗಣ್ಣು',
'ಕಿಡಿಗರೆ',
'ಕಿಡಿಗಱೆ',
'ಕಿಡಿಗುಟ್ಟು',
'ಕಿಡಿಗೇಡಿ',
'ಕಿಡಿನುಡಿ',
'ಕಿಡಿಬೆಂಕಿ',
'ಕಿಡಿಬೆಣೆ',
'ಕಿಡಿಯಾಡು',
'ಕಿಡಿಯಿಡು',
'ಕಿಡಿಯೊಡೆ',
'ಕಿಡಿವಣ್ಣ',
'ಕಿಡಿವಿಡು',
'ಕಿಡಿವೀೞ್',
'ಕಿಡಿವೆಱು',
'ಕಿಡಿಸುರಿ',
'ಕಿಡಿಸೂಸು',
'ಕಿಡುಕುಟ್ಟು',
'ಕಿಡುಗುಟ್ಟು',
'ಕಿಡುಗೇಡಿ',
'ಕಿಡುತರ್',
'ಕಿಡುದೆಸೆ',
'ಕಿಡುನೋಟ',
'ಕಿಟುವುಳ',
'ಕಿಡುವೋಗು',
'ಕಿಣಕರ',
'ಕಿಣಿಗುಟ್ಟು',
'ಕಿಣಿಮಿಣಿ',
'ಕಿಣಿವಲ್',
'ಕಿತಗೆಯ್',
'ಕಿತಾಪತಿ',
'ಕಿತಾಪತ್ತು',
'ಕಿತ್ತಲಾಟ',
'ಕಿತ್ತಲಾಡು',
'ಕಿತ್ತಾನಾರು',
'ಕಿತ್ತಾವಾರ',
'ಕಿತ್ತುಮಿಣಿ',
'ಕಿತ್ತುಸಿರ್',
'ಕಿತ್ತೆನಾರು',
'ಕಿತ್ತೆಸಳ್',
'ಕಿನ್‍ಕಾಪು',
'ಕಿನಕಾಪು',
'ಕಿನಕಾಬು',
'ಕಿನಖಾಪು',
'ಕಿನಖಾಫ಼',
'ಕಿನಸಿಗೆ',
'ಕಿನಿಕಿನಿ',
'ಕಿನಿವಲ್ಲು',
'ಕಿನಿಸಾನು',
'ಕಿನಿಸಾಳು',
'ಕಿನಿಸಿಗೆ',
'ಕಿನಿಸೇಱು',
'ಕಿನ್ನದನಿ',
'ಕಿನ್ನಿಗೋಳಿ',
'ಕಿನ್ನೀರ್ಗಾಣ್',
'ಕಿಪಣಾಸಿ',
'ಕಿಪಾಯತ',
'ಕಿಫಾಯತಿ',
'ಕಿಫಾಯತು',
'ಕಿಬ್ಬಸಿರು',
'ಕಿಬ್ಬಸಿಱ್',
'ಕಿಬ್ಬಸಿಱು',
'ಕಿಬ್ಬಸುರ್',
'ಕಿಬ್ಬಸುರು',
'ಕಿಬ್ಬಸುಱ್',
'ಕಿಬ್ಬಸುಱು',
'ಕಿಬ್ಬೆರಳು',
'ಕಿಬ್ಬೊನಲು',
'ಕಿಮುಳ್ದುಳಿ',
'ಕಿಮುೞ್ದುೞಿ',
'ಕಿರಕುಳ',
'ಕಿರಗಣಿ',
'ಕಿರಗಣೆ',
'ಕಿರಗುಣಿ',
'ಕಿರಣಾಳಿ',
'ಕಿರಣಿಸು',
'ಕಿರಬಳ್ಳಿ',
'ಕಿರಮಂಜಿ',
'ಕಿರಮಿಂಜಿ',
'ಕಿರಸ್ತಾನ',
'ಕಿರಾತಕ',
'ಕಿರಾತಕಿ',
'ಕಿರಾಯತಿ',
'ಕಿರಿಂಚಿಲ್ಲು',
'ಕಿರಿಕಂಕ',
'ಕಿರಿಕಾನ',
'ಕಿರಿಕಾಳಾ',
'ಕಿರಿಕಿರಿ',
'ಕಿರಿಕೊಂಬು',
'ಕಿರಿಗಂಚಿ',
'ಕಿರಿಗಂಟೆ',
'ಕಿರಿಗುಳ್ಳ',
'ಕಿರಿಗೂಬೆ',
'ಕಿರಿಗೊಂಬು',
'ಕಿರಿಗೋಣಿ',
'ಕಿರಿಗೋಳಿ',
'ಕಿರಿಚಳ್ಳೆ',
'ಕಿರಿಚಾಟ',
'ಕಿರಿಚಾಡು',
'ಕಿರಿಜಾಲಿ',
'ಕಿರಿಜಿಡ್ಡಿ',
'ಕಿರಿತಗ್ಗಿ',
'ಕಿರಿತನ',
'ಕಿರಿದಾರಿ',
'ಕಿರಿನಳ್ಳು',
'ಕಿರಿನುಡಿ',
'ಕಿರಿನೆಲ್ಲಿ',
'ಕಿರಿನೋಟ',
'ಕಿರಿಪಂಚೆ',
'ಕಿರಿಪಾರಿ',
'ಕಿರಿಪಿಲ್ಲಿ',
'ಕಿರಿಪಿಲ್ಲೆ',
'ಕಿರಿಬನ್ನಿ',
'ಕಿರಿಬೆಟ್ಟು',
'ಕಿರಿಬೊಟ್ಟು',
'ಕಿರಿಮಾಣಿ',
'ಕಿರಿಯಬ್ಬೆ',
'ಕಿರಿಯಮ್ಮ',
'ಕಿರಿಯಯ್ಯ',
'ಕಿರಿಯಾತು',
'ಕಿರಿಯಿಸು',
'ಕಿರಿಯೋಡು',
'ಕಿರಿವಾತು',
'ಕಿರಿಸೀಸ',
'ಕಿರಿಸ್ತಾನ',
'ಕಿರಿಹಾಲೆ',
'ಕಿರಿಹೊಟ್ಟೆ',
'ಕಿರಿಹೊನ್ನೆ',
'ಕಿರುಕಣ್',
'ಕಿರುಕುಣಿ',
'ಕಿರುಕುಳ',
'ಕಿರುಕೊಳ',
'ಕಿರುಕೋಣೆ',
'ಕಿರುಗಕ್ಕೆ',
'ಕಿರುಗಣ್ಣು',
'ಕಿರುಗತ್ತಿ',
'ಕಿರುಗಬ್ಬ',
'ಕಿರುಗಾಳಿ',
'ಕಿರುಗುಟ್ಟು',
'ಕಿರುಗುಡಿ',
'ಕಿರುಗುಡು',
'ಕಿರುಗುಣಿ',
'ಕಿರುಗುಡ್ಡ',
'ಕಿರುಗುಳ್ಳ',
'ಕಿರುಗೂಸು',
'ಕಿರುಗೆಯ್ಮೆ',
'ಕಿರುಗೋಣಿ',
'ಕಿರುಗೋಣೆ',
'ಕಿರುಗೋಳಿ',
'ಕಿರುಚಲು',
'ಕಿರುಚಾಟ',
'ಕಿರುಚಾಡು',
'ಕಿರುಚಿತ್ರ',
'ಕಿರುಚಿಸು',
'ಕಿರುಟಿಗ',
'ಕಿರುಟಿಗೆ',
'ಕಿರುಡಕ್ಕೆ',
'ಕಿರುಡೊಳ್ಳು',
'ಕಿರುತಾಳೆ',
'ಕಿರುತೆರೆ',
'ಕಿರುತೊರೆ',
'ಕಿರುದಂಗಿ',
'ಕಿರುದಂಗೆ',
'ಕಿರುದನಿ',
'ಕಿರುದಾರಿ',
'ಕಿರುದಿನ್ನೆ',
'ಕಿರುದೆಂಗು',
'ಕಿರುದೆರೆ',
'ಕಿರುದೆಱೆ',
'ಕಿರುದೇಂಗು',
'ಕಿರುದೇಗು',
'ಕಿರುದೊಡೆ',
'ಕಿರುದೊಱೆ',
'ಕಿರುನಗೆ',
'ಕಿರುನುಡಿ',
'ಕಿರುನೆಲ್ಲಿ',
'ಕಿರುನೋಟ',
'ಕಿರುಪಿಲ್ಲಿ',
'ಕಿರುಪುಟ',
'ಕಿರುಬಂಡಿ',
'ಕಿರುಬತ್ತ',
'ಕಿರುಬಲ್ಲಿ',
'ಕಿರುಬುಲಿ',
'ಕಿರುಬೆಟ್ಟು',
'ಕಿರುಬೇರು',
'ಕಿರುಬ್ರಾಹ್ಮಿ',
'ಕಿರುಮಂಜಿ',
'ಕಿರುಮನೆ',
'ಕಿರುವಡಿ',
'ಕಿರುವಣೆ',
'ಕಿರುವೆಟ್ಟು',
'ಕಿರುಸುತ್ತು',
'ಕಿರುಸೆಜ್ಜೆ',
'ಕಿರುಹಾಲೆ',
'ಕಿರುಹುಲಿ',
'ಕಿರ್ಕಿಜೋಡು',
'ಕಿರ್ಗಣ್ಣಿಕ್ಕು',
'ಕಿರ್ಗಯ್ಯಾಗು',
'ಕಿರ್ಗಸಾಲೆ',
'ಕಿರ್ಚುಂಗೋಲ್',
'ಕಿರ್ಚುಗಣ್ಣ',
'ಕಿರ್ಚುವೊಗು',
'ಕಿರ್ಬಸಿಱ್',
'ಕಿರ್ಮೀರತೆ',
'ಕಿಲಕಿಲ',
'ಕಿಲಬಿಲ',
'ಕಿಲಿಂಜಕ',
'ಕಿಲಿಕಿಲಿ',
'ಕಿಲಿಬಿಲಿ',
'ಕಿಲುಂಬೇಱು',
'ಕಿಲುಬಿಡಿ',
'ಕಿಲುಬಿಸು',
'ಕಿಲೆದಾರ',
'ಕಿಲೊಗ್ರಾಮು',
'ಕಿಲೊವ್ಯಾಟು',
'ಕಿಲ್ಬಿಷಿಕ',
'ಕಿಲ್ಮಿಷಿಕ',
'ಕಿಲ್ಲೆದಾರ',
'ಕಿಲ್ಲೆದಾರಿ',
'ಕಿಲ್ಲೇದಾರ',
'ಕಿಲ್ವಿಷಿಕ',
'ಕಿವರಕ',
'ಕಿವಿಕಚ್ಚು',
'ಕಿವಿಕುಣಿ',
'ಕಿವಿಕೊಡು',
'ಕಿವಿಗಚ್ಚು',
'ಕಿವಿಗಟ್ಟು',
'ಕಿವಿಗಡಿ',
'ಕಿವಿಗಡೆ',
'ಕಿವಿಗಡ್ಡೆ',
'ಕಿವಿಗೊಡು',
'ಕಿವಿಗೊಪ್ಪು',
'ಕಿವಿಗೊಳ್',
'ಕಿವಿಚಟ್ಟೆ',
'ಕಿವಿಚಾಚು',
'ಕಿವಿಚಾಟು',
'ಕಿವಿಚುಚ್ಚು',
'ಕಿವಿದಾಗು',
'ಕಿವಿದಾರೆ',
'ಕಿವಿದೆರೆ',
'ಕಿವಿದೆಱೆ',
'ಕಿವಿನುಡಿ',
'ಕಿವಿಬಿರಿ',
'ಕಿವಿಬೇನೆ',
'ಕಿವಿಮಾತು',
'ಕಿವಿಮೂಳ',
'ಕಿವಿಮೂಳಿ',
'ಕಿವಿಮೂಳೆ',
'ಕಿವಿಯಾರ್',
'ಕಿವಿಯೂದು',
'ಕಿವಿಯೇಱು',
'ಕಿವಿಯೊಂಟಿ',
'ಕಿವಿಯೊಗು',
'ಕಿವಿಯೊಡ್ಡು',
'ಕಿವಿಯೋಲೆ',
'ಕಿವಿವಚ್ಚ',
'ಕಿವಿವಚ್ಚು',
'ಕಿವಿವರ್ಚು',
'ಕಿವಿವಾಲೆ',
'ಕಿವಿವುಗು',
'ಕಿವಿವೆಱು',
'ಕಿವಿವೇಟ',
'ಕಿವಿವೊಗು',
'ಕಿವಿಶೂಲೆ',
'ಕಿವಿಸವಿ',
'ಕಿವಿಹಿಂಡು',
'ಕಿವಿಹಿಡಿ',
'ಕಿವುಡಿಕೆ',
'ಕಿವುಡುಗ',
'ಕಿವುಡೆಸೆ',
'ಕಿವುಡೇಳು',
'ಕಿವುೞ್ಕೇಳ್',
'ಕಿವುೞ್ಗೇಳ್',
'ಕಿಶೋರಕ',
'ಕಿಸಕದ',
'ಕಿಸಕ್ಕನೆ',
'ಕಿಸಗಾಲು',
'ಕಿಸಲಯ',
'ಕಿಸಲಾಟ',
'ಕಿಸವಾಯ್',
'ಕಿಸವಾಯ',
'ಕಿಸಾಮತಿ',
'ಕಿಸಿಕಿಲ್',
'ಕಿಸಿಗಾಲು',
'ಕಿಸಿಬಾಯಿ',
'ಕಿಸಿವಾಯ್',
'ಕಿಸಿವಾಯ',
'ಕಿಸುಕಟ್ಟು',
'ಕಿಸುಕಣ್ಚು',
'ಕಿಸುಕಾಡು',
'ಕಿಸುಕಾಯಿ',
'ಕಿಸುಕಾರೆ',
'ಕಿಸುಕುರಿ',
'ಕಿಸುಕುಳ',
'ಕಿಸುಕುಳಿ',
'ಕಿಸುಕೆಯ್',
'ಕಿಸುಗಟ್ಟು',
'ಕಿಸುಗಣ್',
'ಕಿಸುಗಣಿ',
'ಕಿಸುಗಣ್ಚು',
'ಕಿಸುಗಣ್ಣ',
'ಕಿಸುಗಣ್ಣು',
'ಕಿಸುಗಲ್',
'ಕಿಸುಗಲ್ಲು',
'ಕಿಸುಗಾಡು',
'ಕಿಸುಗಾರ',
'ಕಿಸುಗಾರೆ',
'ಕಿಸುಗಾಲು',
'ಕಿಸುಗುಟ್ಟು',
'ಕಿಸುಗುಳ',
'ಕಿಸುಗುಳಿ',
'ಕಿಸುಗುಳೆ',
'ಕಿಸುದೋಱು',
'ಕಿಸುಪೇಱು',
'ಕಿಸುಬಾಯಿ',
'ಕಿಸುಭಾಗ್ಯ',
'ಕಿಸುಮೊಗೆ',
'ಕಿಸುರಾನ್',
'ಕಿಸುಮಂತ್ರ',
'ಕಿಸುರೇರು',
'ಕಿಸುರ್ವಡು',
'ಕಿಸುರ್ವೇರ್',
'ಕಿಸುವಂಚೆ',
'ಕಿಸುವಣ್',
'ಕಿಸುವಣ್ಣ',
'ಕಿಸುವಣ್ಣು',
'ಕಿಸುವಾಯ್',
'ಕಿಸುವಾಯ',
'ಕಿಸುವೊನ್',
'ಕಿಸುಸಂಜೆ',
'ಕಿಸುಸೆರೆ',
'ಕಿಸೆಗಳ್ಳ',
'ಕಿಸ್ತುಬಂದಿ',
'ಕಿಸ್ತುಬಾಕಿ',
'ಕಿಸ್ಸುರಗಿ',
'ಕಿಳಕಿಳ',
'ಕಿಳನಿಲ',
'ಕಿಳಿಂಜಕ',
'ಕಿಳಿಕಿಳಿ',
'ಕಿಳುವಳಿ',
'ಕಿಳುಹೊಲ',
'ಕಿಳ್ಕಬ್ಬಿಗ',
'ಕಿಳ್ದಾಳಿಗೆ',
'ಕಿಳ್ಪಡಿಸು',
'ಕಿಳ್ಸೆರಗು',
'ಕಿಳ್ಳಿಕೇತ',
'ಕಿಳ್ಳೀಕೇತ',
'ಕಿಳ್ಳೇಕೇತ',
'ಕಿಱಿಕಿಱಿ',
'ಕಿಱಿಗುಳ್ಳ',
'ಕಿಱಿಗೂಸು',
'ಕಿಱಿಗೆಯ್',
'ಕಿಱಿದಾನುಂ',
'ಕಿಱಿದೇಗು',
'ಕಿಱಿನೋಟ',
'ಕಿಱಿಪಿಲ್ಲಿ',
'ಕಿಱಿಮಾತು',
'ಕಿಱಿಯಬ್ಬೆ',
'ಕಿಱಿಯಮ್ಮ',
'ಕಿಱಿಯಯ್ಯ',
'ಕಿಱಿವಂಚ',
'ಕಿಱಿವೀೞು',
'ಕಿಱಿವೆಂಚೆ',
'ಕಿಱೀಚಲ್',
'ಕಿಱುಕುಡ',
'ಕಿಱುಕುಣಿ',
'ಕಿಱುಕುಲ',
'ಕಿಱುಕುಳ',
'ಕಿಱುಗಂದ',
'ಕಿಱುಗಂಬ',
'ಕಿಱುಗಟ್ಟು',
'ಕಿಱುಗಣ್',
'ಕಿಱುಗಣ್ಚು',
'ಕಿಱುಗತ್ತಿ',
'ಕಿಱುಗನ್ನೆ',
'ಕಿಱುಗಲ್',
'ಕಿಱುಗಲ್ಲು',
'ಕಿಱುಗಾಯ್',
'ಕಿಱುಗಾಲ',
'ಕಿಱುಗಾಳೆ',
'ಕಿಱುಗುಂಬ',
'ಕಿಱುಗುಣಿ',
'ಕಿಱುಗುಳ್ಳ',
'ಕಿಱುಗೂಸು',
'ಕಿಱುಗೂಳಿ',
'ಕಿಱುಗೆಮ್ಮು',
'ಕಿಱುಗೆಯ್ಮೆ',
'ಕಿಱುಗೆಱೆ',
'ಕಿಱುಗೇಣ್',
'ಕಿಱುಗೊಂಬು',
'ಕಿಱುಗೋಣಿ',
'ಕಿಱುಚೇಣ',
'ಕಿಱುಜವೆ',
'ಕಿಱುಡೊಳ್ಳು',
'ಕಿಱುತೆರೆ',
'ಕಿಱುತೇಗು',
'ಕಿಱುದಂಗಿ',
'ಕಿಱುದಂಗೆ',
'ಕಿಱುದಾಲಿ',
'ಕಿಱುದಾೞ್',
'ಕಿಱುದಿನ',
'ಕಿಱುದೂಳು',
'ಕಿಱುದೆಂಗು',
'ಕಿಱುದೆರೆ',
'ಕಿಱುದೆಱೆ',
'ಕಿಱುದೇಗ',
'ಕಿಱುದೇಗು',
'ಕಿಱುದೊಡೆ',
'ಕಿಱುದೊಱೆ',
'ಕಿಱುದೋಟ',
'ಕಿಱುನಗೆ',
'ಕಿಱುನಡು',
'ಕಿಱುನನೆ',
'ಕಿಱುನಾಯ್',
'ಕಿಱುನಿಱಿ',
'ಕಿಱುನೆಕ್ಕು',
'ಕಿಱುನೆಲ್',
'ಕಿಱುನೆಲ್ಲಿ',
'ಕಿಱುನೋಟ',
'ಕಿಱುಪಿಲ್ಲಿ',
'ಕಿಱುಬಟ್ಟೆ',
'ಕಿಱುಬನ್ನಿ',
'ಕಿಱುಬುದ್ಧಿ',
'ಕಿಱುಬುಲಿ',
'ಕಿಱುಬೆಟ್ಟು',
'ಕಿಱುಬೆಳ್ಪು',
'ಕಿಱುಬೋರಿ',
'ಕಿಱುಮನೆ',
'ಕಿಱುಮುಷ್ಟಿ',
'ಕಿಱುಮೃಗ',
'ಕಿಱುಮೆಯ್',
'ಕಿಱುಮೆಯ್ಯ',
'ಕಿಱುವಂಡಿ',
'ಕಿಱುವಗು',
'ಕಿಱುವಟ್ಟೆ',
'ಕಿಱುವಡಿ',
'ಕಿಱುವನಿ',
'ಕಿಱುವಳೆ',
'ಕಿಱುವಳ್ಳಿ',
'ಕಿಱುವಱಿ',
'ಕಿಱುವುಲಿ',
'ಕಿಱುವೆಂಚೆ',
'ಕಿಱುವೆಟ್ಟು',
'ಕಿಱುವೆಣ್',
'ಕಿಱುವೆಸ',
'ಕಿಱುವೆಱೆ',
'ಕಿಱುವೇಂಟೆ',
'ಕಿೞ್ಗೈವೆಸ',
'ಕಿೞ್ಪಡಿಸು',
'ಕಿೞ್ಬಯಲ್',
'ಕಿೞ್ಬಸಿಱ್',
'ಕಿೞ್ಸೆಱಂಗು',
'ಕೀಕಾಟವಿ',
'ಕೀಕಾರಣ್ಯ',
'ಕೀಚುಗಾಯಿ',
'ಕೀಚುಬಾಲ',
'ಕೀಚುಸಿಳ್ಳು',
'ಕೀಟಮಾರಿ',
'ಕೀಟಶಾಸ್ತ್ರ',
'ಕೀಟಾಹಾರಿ',
'ಕೀಣಿವಲ್ಲು',
'ಕೀನ್‍ಕಾಪು',
'ಕೀನಿವಲ್',
'ಕೀನಿವಲ್ಲು',
'ಕೀಯಾವಾಡಿ',
'ಕೀರಬೆಕ್ಕು',
'ಕೀರರವ',
'ಕೀರವಱಿ',
'ಕೀರವಾಣಿ',
'ಕೀರಾಲಾಪೆ',
'ಕೀರೋತ್ಕಾರ',
'ಕೀರ್ತನೀಯ',
'ಕೀರ್ತಿಕಾಮ',
'ಕೀರ್ತಿದೇಹಿ',
'ಕೀರ್ತಿಪರ',
'ಕೀರ್ತಿಮುಖ',
'ಕೀರ್ತಿವಂತ',
'ಕೀರ್ತಿವಂತೆ',
'ಕೀರ್ತಿವಡೆ',
'ಕೀರ್ತಿವಿದ',
'ಕೀರ್ತಿವಿದೆ',
'ಕೀರ್ತಿವೀರ',
'ಕೀರ್ತಿವೆಱು',
'ಕೀರ್ತಿಶಾಲಿ',
'ಕೀರ್ತಿಶೇಷ',
'ಕೀರ್ತಿಸ್ತಂಭ',
'ಕೀರ್ತ್ಯನ್ವಿತ',
'ಕೀರ್ತ್ಯನ್ವಿತೆ',
'ಕೀಲಗುಳಿ',
'ಕೀಲಜ್ಞಾನ',
'ಕೀಲಣಿಕೆ',
'ಕೀಲಣಿಗೆ',
'ಕೀಲಣೆಗೈ',
'ಕೀಲಬೊಂಬೆ',
'ಕೀಲಾಯುಧ',
'ಕೀಲಾರಿಗ',
'ಕೀಲಾಲಜ',
'ಕೀಲಾಲಪ',
'ಕೀಲಿಕೆಯ್',
'ಕೀಲಿಕೊಡು',
'ಕೀಲಿಮಣೆ',
'ಕೀಲುಕುಳಿ',
'ಕೀಲುಕೊಡು',
'ಕೀಲುಗಲ್ಲು',
'ಕೀಲುಗೊಂಬೆ',
'ಕೀಲುಬೊಂಬೆ',
'ಕೀಲೆಣಿಕೆ',
'ಕೀಲ್ಕುಂಚಿಕೆ',
'ಕೀಲ್ಗೊಳಿಸು',
'ಕೀವಡಿಗೆ',
'ಕೀವುಗಟ್ಟು',
'ಕೀವುನಂಜು',
'ಕೀಶಪತಿ',
'ಕೀಶಪರ್ಣಿ',
'ಕೀಸರಿಡು',
'ಕೀಸುಮಂಡೆ',
'ಕೀಸುವಣೆ',
'ಕೀಳಕುಲ',
'ಕೀಳಜನ',
'ಕೀಳರಿಮೆ',
'ಕೀಳಾರಂಭ',
'ಕೀಳಿಡಿಸು',
'ಕೀಳುಕರ್ಮ',
'ಕೀಳುಕಳೆ',
'ಕೀಳುಗಣ್ಣು',
'ಕೀಳುಗಯ್ಯು',
'ಕೀಳುಗಾಳಿ',
'ಕೀಳುಜನ',
'ಕೀಳುಜಾತಿ',
'ಕೀಳುತನ',
'ಕೀಳುದನಿ',
'ಕೀಳುದರ್ಜೆ',
'ಕೀಳುದಲೆ',
'ಕೀಳುದಸೆ',
'ಕೀಳುದಾನ',
'ಕೀಳುದೆಸೆ',
'ಕೀಳುನಾಡು',
'ಕೀಳುನಿಲ್',
'ಕೀಳುನುಡಿ',
'ಕೀಳುಪಚ್ಚೆ',
'ಕೀಳುಪೈರು',
'ಕೀಳುಫಲ',
'ಕೀಳುಬಗೆ',
'ಕೀಳುಮಂದಿ',
'ಕೀಳುಮಟ್ಟ',
'ಕೀಳುಮಾಡು',
'ಕೀಳುಮಾತು',
'ಕೀಳುಮೇಲು',
'ಕೀಳುಲೋಕ',
'ಕೀಳುವಳಿ',
'ಕೀಳುವಾಸಿ',
'ಕೀಳೂಳಿಗ',
'ಕೀಳೊಕ್ಕಲು',
'ಕೀಳೊಡಲು',
'ಕೀಳ್ಕೆಳಗು',
'ಕೀಳ್ಪಡಿಸು',
'ಕೀಳ್ಬಯಕೆ',
'ಕೀಳ್ಬಯಲು',
'ಕೀಳ್ಮಾನಿಸ',
'ಕೀಳ್ವಡಿಸು',
'ಕೀಳ್ವರಿಜು',
'ಕೀಳ್ವಾನಿಸ',
'ಕೀಳ್ವೆಳಗು',
'ಕೀಱುಗಂಧ',
'ಕೀೞರಸು',
'ಕೀೞಾಗಿಸು',
'ಕೀೞೊಕ್ಕಲ್',
'ಕೀೞ್ಪಡಿಸು',
'ಕೀೞ್ವಡಿಸು',
'ಕೀೞ್ವದುವ',
'ಕುಂಕಿಆನೆ',
'ಕುಂಕುಮಾಂಕ',
'ಕುಂಚಂಬಿಡಿ',
'ಕುಂಚಟಿಗ',
'ಕುಂಚಡಿಗ',
'ಕುಂಚಣಿಗೆ',
'ಕುಂಚನ್ಮಧ್ಯ',
'ಕುಂಚನ್ಮೂಲ',
'ಕುಂಚಿಟಿಗ',
'ಕುಂಜಪಕ್ಷಿ',
'ಕುಂಜರಾರಿ',
'ಕುಂಜವಕ್ಕಿ',
'ಕುಂಜವನ',
'ಕುಂಟಂಗಿಲ',
'ಕುಂಟಂಪಿಲ್ಲಿ',
'ಕುಂಟಕಾರಿ',
'ಕುಂಟಣಿಕೆ',
'ಕುಂಟಣಿಗ',
'ಕುಂಟಲಪಿ',
'ಕುಂಟಲಿಗ',
'ಕುಂಟಸುದ್ದಿ',
'ಕುಂಟುಕಾಲು',
'ಕುಂಟುಗಾಲ್',
'ಕುಂಟುಗಾಲ',
'ಕುಂಟುಗಾಲು',
'ಕುಂಟುತನ',
'ಕುಂಟುದನಿ',
'ಕುಂಟುದಿಟ್ಟಿ',
'ಕುಂಟುನಡೆ',
'ಕುಂಟುನೆಪ',
'ಕುಂಟುನೆವ',
'ಕುಂಟುಪಂಕ್ತಿ',
'ಕುಂಟುಬಿಲ್ಲೆ',
'ಕುಂಟುಮದ್ದು',
'ಕುಂಟುಸುದ್ದಿ',
'ಕುಂಟುಹಂತಿ',
'ಕುಂಟೆಕೋಣ',
'ಕುಂಟೆಣಿಕೆ',
'ಕುಂಟೆಬಿಲ್ಲೆ',
'ಕುಂಠಭಾವ',
'ಕುಂಡಕೃಷಿ',
'ಕುಂಡಕ್ರಿಯೆ',
'ಕುಂಡರಿಸು',
'ಕುಂಡಲಿಗ',
'ಕುಂಡಲಿತ',
'ಕುಂಡಲಿನಿ',
'ಕುಂಡಲೀಶ',
'ಕುಂಡಲೇಷ್ಟಿ',
'ಕುಂಡಳಿತ',
'ಕುಂಡೆಚಣ್ಣ',
'ಕುಂಡೋದರ',
'ಕುಂತಗಾಱ',
'ಕುಂತಹಸ್ತ',
'ಕುಂತಳಿಕೆ',
'ಕುಂತಾಕುಂತಿ',
'ಕುಂತಾಹತಿ',
'ಕುಂತಿಹುಲ್ಲು',
'ಕುಂದಗಂಧಿ',
'ಕುಂದಗಾಲು',
'ಕುಂದಚ್ಛಾಯೆ',
'ಕುಂದಣಿಕೆ',
'ಕುಂದಣಿಗೆ',
'ಕುಂದಳಿಗೆ',
'ಕುಂದುಕೇರು',
'ಕುಂದುಗಳೆ',
'ಕುಂದುಗಾಣ್',
'ಕುಂದುಗುಲ',
'ಕುಂದುಗೊಳ್',
'ಕುಂದುರುಕ',
'ಕುಂದುರುಕಿ',
'ಕುಂದುರುಜ',
'ಕುಂದುರುಷ್ಕ',
'ಕುಂದುವಡೆ',
'ಕುಂಪ್ಟಹತ್ತಿ',
'ಕುಂಬಗಾಱ',
'ಕುಂಬತಳ',
'ಕುಂಬದಳ',
'ಕುಂಬರಿತಿ',
'ಕುಂಬಸಾಕ',
'ಕುಂಬಱಿತಿ',
'ಕುಂಬಾರತಿ',
'ಕುಂಬಾರಿಕೆ',
'ಕುಂಬಾರಿತಿ',
'ಕುಂಬಿಕ್ಕಿಸು',
'ಕುಂಭಕರ್ಣ',
'ಕುಂಭಕಾರ',
'ಕುಂಭಕಾರಿ',
'ಕುಂಭಕುಚ',
'ಕುಂಭಕೂಳು',
'ಕುಂಭಕೋಣ',
'ಕುಂಭಗಾಱ',
'ಕುಂಭತಟ',
'ಕುಂಭತಳ',
'ಕುಂಭದಾಸಿ',
'ಕುಂಭದ್ರೋಣ',
'ಕುಂಭಮೇಳ',
'ಕುಂಭಯೋಗ',
'ಕುಂಭರಾಶಿ',
'ಕುಂಭವಾದ್ಯ',
'ಕುಂಭಶಾಖ',
'ಕುಂಭಶಿರ',
'ಕುಂಭಸ್ಥಲ',
'ಕುಂಭಸ್ಥಳ',
'ಕುಂಭಾನಸ',
'ಕುಂಭಿಪಾಕ',
'ಕುಂಭೀಧಾನ್ಯ',
'ಕುಂಭೀನಸ',
'ಕುಂಭೀಪಾಕ',
'ಕುಂಭೋದಯ',
'ಕುಂಭೋದ್ಯಮ',
'ಕುಂಯ್‍ಗುಟ್ಟು',
'ಕುಂಯಿಗುಟ್ಟು',
'ಕುಂಯಿಗುಡು',
'ಕುಕಿಲಿಡು',
'ಕುಕಿಲಿಱಿ',
'ಕುಕುಂದರ',
'ಕುಕುರಾದ್ರ',
'ಕುಕ್ಕಟಿಗ',
'ಕುಕ್ಕನಾಯ್',
'ಕುಕ್ಕರಿಸು',
'ಕುಕ್ಕಿಬಳ್ಳಿ',
'ಕುಕ್ಕಿಲಿಡು',
'ಕುಕ್ಕಿಲಿಱಿ',
'ಕುಕ್ಕಿಲಿಲೆ',
'ಕುಕ್ಕುಟಾಂಡ',
'ಕುಕ್ಕುಮಾಯಿ',
'ಕುಕ್ಕುಮುಳ್ಳು',
'ಕುಕ್ಕುರಿಸು',
'ಕುಕ್ಕುಲೋಟ',
'ಕುಕ್ಕುಸುಡು',
'ಕುಕ್ಕುಳಿಸು',
'ಕುಕ್ಕುಳ್ಕುದಿ',
'ಕುಕ್ಕುಳ್ಗುದಿ',
'ಕುಕ್ಕುಱಿಸು',
'ಕುಕ್ಕೂಬಾಳ',
'ಕುಕ್ಕೆಬಳ್ಳಿ',
'ಕುಕ್ಷಿಂಭರಿ',
'ಕುಕ್ಷಿರೋಗ',
'ಕುಗ್ಗುಬಡಿ',
'ಕುಚಂದನ',
'ಕುಚಮುಖ',
'ಕುಚಾನನ',
'ಕುಚುಕುಚು',
'ಕುಚೇಷ್ಟಕ',
'ಕುಚ್ಚಣಿಕೆ',
'ಕುಚ್ಚಣಿಸು',
'ಕುಚ್ಚಲಕ್ಕಿ',
'ಕುಚ್ಚಿಲಕ್ಕಿ',
'ಕುಚ್ಚುಗಟ್ಟು',
'ಕುಚ್ಚುಮಟ್ಟೆ',
'ಕುಚ್ಚುಮೀನು',
'ಕುಚ್ಚುಹುಳು',
'ಕುಜದೋಷ',
'ಕುಜನತೆ',
'ಕುಜನ್ಮಕ',
'ಕುಜರಾಜಿ',
'ಕುಜವಾರ',
'ಕುಜುಬುಜು',
'ಕುಜ್ಜಮುಖ',
'ಕುಟಜಕ',
'ಕುಟನ್ನಟ',
'ಕುಟಹಾರಿ',
'ಕುಟಾಯಿಸು',
'ಕುಟಿಲತೆ',
'ಕುಟಿಲತ್ವ',
'ಕುಟಿಲಾಂಗ',
'ಕುಟೀಚಕ',
'ಕುಟೀರಕ',
'ಕುಟುಂಗಕ',
'ಕುಟುಂಬಕ',
'ಕುಟುಂಬಸ್ಥ',
'ಕುಟುಂಬಿಕ',
'ಕುಟುಂಬಿಗ',
'ಕುಟುಂಬಿನಿ',
'ಕುಟುಕಿಸು',
'ಕುಟ್ಟಗೇರು',
'ಕುಟ್ಟಣಿಗೆ',
'ಕುಟ್ಟಮಿತ',
'ಕುಟ್ಟಿಚಾತ',
'ಕುಟ್ಟಿದಾಣೆ',
'ಕುಟ್ಟಿದೊಣ್ಣೆ',
'ಕುಟ್ಟುವನಿ',
'ಕುಟ್ಟುಹಿಡಿ',
'ಕುಟ್ಟೆಗೊಳ್',
'ಕುಟ್ಟೆವುೞು',
'ಕುಟ್ಟೆಹುಳು',
'ಕುಟ್ಟೊರಳು',
'ಕುಟ್ಮಳಿತ',
'ಕುಠಾರಕ',
'ಕುಠೇರಕ',
'ಕುಡಗಲೆ',
'ಕುಡಗೂಸು',
'ಕುಡವಲೆ',
'ಕುಡಸಾಲು',
'ಕುಡಿಗಟ್ಟು',
'ಕುಡಿಗಣ್',
'ಕುಡಿಗಣ್ಣು',
'ಕುಡಿಗಪ್ಪು',
'ಕುಡಿಗರ್ಪು',
'ಕುಡಿಗೆಡು',
'ಕುಡಿಗೊಳ್',
'ಕುಡಿಚಾಟಿ',
'ಕುಡಿತನ',
'ಕುಡಿದೋಱು',
'ಕುಡಿನಗು',
'ಕುಡಿನಿಂಕಾ',
'ಕುಡಿನೀರ್',
'ಕುಡಿನೀರು',
'ಕುಡಿನೇಣ್',
'ಕುಡಿನೋಟ',
'ಕುಡಿಬತ್ತಿ',
'ಕುಡಿಬಾಳೆ',
'ಕುಡಿಮಗ',
'ಕುಡಿಮಿಂಚು',
'ಕುಡಿಮೀಸೆ',
'ಕುಡಿಯಿಕ್ಕು',
'ಕುಡಿಯಿಡು',
'ಕುಡಿಯಿತಿ',
'ಕುಡಿಯಿಱಿ',
'ಕುಡಿಯಿಸು',
'ಕುಡಿಯೊಡೆ',
'ಕುಡಿವಡೆ',
'ಕುಡಿವರಿ',
'ಕುಡಿವಲೆ',
'ಕುಡಿವಳ್ಳೆ',
'ಕುಡಿವಾಲ್',
'ಕುಡಿವಾಲ',
'ಕುಡಿವಾಲು',
'ಕುಡಿವಾಳ್',
'ಕುಡಿವಿಡು',
'ಕುಡಿವುರ್ವು',
'ಕುಡಿಹುಬ್ಬು',
'ಕುಡುಂಗಕ',
'ಕುಡುಂಬಳ',
'ಕುಡುಕಿಕ್ಕು',
'ಕುಡುಗತ್ತಿ',
'ಕುಡುಗರ್ಬು',
'ಕುಡುಗಲ್',
'ಕುಡುಗಲು',
'ಕುಡುಗೂಸು',
'ಕುಡುಗೆಯ್',
'ಕುಡುಗೆಯ್ತ',
'ಕುಡುಗೋಲ್',
'ಕುಡುಗೋಲು',
'ಕುಡುತಲೆ',
'ಕುಡುದಡಿ',
'ಕುಡುದಾಡೆ',
'ಕುಡುನೋಟ',
'ಕುಡುಬಿಲ್ಲು',
'ಕುಡುಮಿಂಚು',
'ಕುಡುವಲೆ',
'ಕುಡುವಾಳ್',
'ಕುಡುವಿಲ್',
'ಕುಡುವಿಲ್ಲ',
'ಕುಡುವುರ್ವು',
'ಕುಡುವೆಱೆ',
'ಕುಡುಹುಬ್ಬು',
'ಕುಡ್ಡಬುರ್ಲಿ',
'ಕುಣಸಿಡು',
'ಕುಣಿಕಾಲು',
'ಕುಣಿಕಿಲ್',
'ಕುಣಿಕೋಲ್',
'ಕುಣಿಕೋಲು',
'ಕುಣಿಗಣ್ಣು',
'ಕುಣಿಗಲ್ಲ',
'ಕುಣಿಗಾಳು',
'ಕುಣಿತರ್',
'ಕುಣಿನರಿ',
'ಕುಣಿನೀರು',
'ಕುಣಿಯಿಸು',
'ಕುಣಿವೇರು',
'ಕುಣುಕುಣು',
'ಕುತಕುತ',
'ಕುತಾರ್ಕಿಕ',
'ಕುತುಕಲು',
'ಕುತೂಹಲ',
'ಕುತೂಹಲಿ',
'ಕುತೂಹಳ',
'ಕುತ್ತಂಗುಳಿ',
'ಕುತ್ತಂಗೊಳ್',
'ಕುತ್ತಂಬಡು',
'ಕುತ್ತಕಂಡೆ',
'ಕುತ್ತಗುಳಿ',
'ಕುತ್ತಗೊಳ್',
'ಕುತ್ತರಿಸು',
'ಕುತ್ತವಳ',
'ಕುತ್ತಿಕೋಲು',
'ಕುತ್ತಿಮರಿ',
'ಕುತ್ತಿಸಿಱು',
'ಕುತ್ತುಂಗಱಿ',
'ಕುತ್ತುಂಗುಳಿ',
'ಕುತ್ತುಕೋವಿ',
'ಕುತ್ತುಗೋಲು',
'ಕುತ್ತುದಲೆ',
'ಕುತ್ತುಪೆನ',
'ಕುತ್ತುವಡೆ',
'ಕುತ್ತುವಾರೆ',
'ಕುತ್ತುಸಿರು',
'ಕುತ್ತುಸುರು',
'ಕುತ್ತೆಸರ್',
'ಕುತ್ತೆಸರು',
'ಕುತ್ತೆಸಳ್',
'ಕುತ್ತೆಸಳು',
'ಕುತ್ತೆಸೞ್',
'ಕುತ್ನಿದಿಂಬು',
'ಕುತ್ಸಿತತ್ವ',
'ಕುದರತ್ತು',
'ಕುದರಾಳ',
'ಕುದರ್ಶನ',
'ಕುದಿಗಣ್ಣು',
'ಕುದಿಗಾಸು',
'ಕುದಿಗುಟ್ಟು',
'ಕುದಿಗುಣಿ',
'ಕುದಿಗೂಳು',
'ಕುದಿಗೊಳ್',
'ಕುದಿಗೊಳು',
'ಕುದಿಗೊಳ್ಳು',
'ಕುದಿಜ್ವರ',
'ಕುದಿನೀರು',
'ಕುದಿಪಲ್',
'ಕುದಿಪಾತ್ರೆ',
'ಕುದಿಬಿಂದು',
'ಕುದಿಬೇನೆ',
'ಕುದಿಯಿಸು',
'ಕುದಿಸುಣ್ಣ',
'ಕುದಿಹಿಟ್ಟು',
'ಕುದುಂಬಳು',
'ಕುದುಕಲ್',
'ಕುದುಕಲು',
'ಕುದುಕುಳಿ',
'ಕುದುಗುಳಿ',
'ಕುದುಪಲ್',
'ಕುದುರಾಜಿ',
'ಕುದುರಿಸು',
'ಕುದುಲಕ್ಕಿ',
'ಕುದೃಷ್ಟಾಂತ',
'ಕುದ್ದಾಲಕ',
'ಕುದ್ರಗೇಡಿ',
'ಕುಧರಜೆ',
'ಕುನಾಶಕ',
'ಕುನಿಕಿಲ್',
'ಕುನಿಕಿಲು',
'ಕುನುಗಿಸು',
'ಕುನ್ನಗೆಯ್',
'ಕುನ್ನಿಜನ',
'ಕುನ್ನಿನರಿ',
'ಕುನ್ನಿಮೂೞ',
'ಕುನ್ನೇರಳೆ',
'ಕುನ್ನೇರಿಳೆ',
'ಕುಪ್ಪಕುಱಿ',
'ಕುಪ್ಪಡಿಗೆ',
'ಕುಪ್ಪರಿಸು',
'ಕುಪ್ಪಸಿಗ',
'ಕುಪ್ಪಳಿಕೆ',
'ಕುಪ್ಪಳಿಸು',
'ಕುಪ್ಪಿಮಾತ್ರೆ',
'ಕುಪ್ಪುಳಿಸು',
'ಕುಪ್ಪೆಗೆಡೆ',
'ಕುಪ್ಪೆಗೆಯ್',
'ಕುಪ್ಪೆಗೊಳ್',
'ಕುಪ್ಪೆತೊಟ್ಟಿ',
'ಕುಪ್ಪೆಮಣಿ',
'ಕುಪ್ಪೆವಾಯ್',
'ಕುಪ್ರತರ್ಕ',
'ಕುಪ್ರಬೋಧೆ',
'ಕುಪ್ರಸಿದ್ಧ',
'ಕುಪ್ರಸಿದ್ಧಿ',
'ಕುಬಿಬಿಱಿ',
'ಕುಬುಜಿಸು',
'ಕುಬುಬಿಱಿ',
'ಕುಬೋಧಕ',
'ಕುಬೋಧನೆ',
'ಕುಬ್ಜತನು',
'ಕುಭಾಷಿಣಿ',
'ಕುಭೃಜ್ಜಾತೆ',
'ಕುಮಂಜನ',
'ಕುಮಂದಾನ',
'ಕುಮಚಟು',
'ಕುಮಾನುಷ್ಯ',
'ಕುಮಾರಕ',
'ಕುಮಾರತಿ',
'ಕುಮಾರತೆ',
'ಕುಮಾರಿಕೆ',
'ಕುಮಾರಿತಿ',
'ಕುಮುದಾರಿ',
'ಕುಮುದಿಕೆ',
'ಕುಮುದಿನಿ',
'ಕುಮುದ್ವತಿ',
'ಕುಮುಲಿಕೆ',
'ಕುಮ್ಮಚಟ್ಟು',
'ಕುಮ್ಮರಿಸು',
'ಕುಮ್ಮಿಯಾಟ',
'ಕುಯಂತ್ರಕ',
'ಕುಯಿಕೀರೆ',
'ಕುಯಿಗುಟ್ಟು',
'ಕುಯಿಲಾಳು',
'ಕುಯ್ಲುಗಾರ',
'ಕುಯ್ಲುಗಾಲ',
'ಕುರಂಗಕ',
'ಕುರಂಟಕ',
'ಕುರಂಟಿಕ',
'ಕುರಂಟಿಕೆ',
'ಕುರಂಟಿಗೆ',
'ಕುರಂಡಿಕೆ',
'ಕುರಕಿಸು',
'ಕುರಕುರಿ',
'ಕುರಟಿಕೆ',
'ಕುರಟಿಗೆ',
'ಕುರಪುಟ',
'ಕುರಮಾವು',
'ಕುರವಂಜಿ',
'ಕುರವಕ',
'ಕುರಿಕಿಸು',
'ಕುರಿಕುರಿ',
'ಕುರಿಗಾಯಿ',
'ಕುರಿಗಿಡ',
'ಕುರಿಗೆಲೆ',
'ಕುರಿಗೊಲೆ',
'ಕುರಿಟಗ',
'ಕುರಿತನ',
'ಕುರಿದರಿ',
'ಕುರಿದೊಡ್ಡಿ',
'ಕುರಿಬಳ್ಳಿ',
'ಕುರಿಬುದ್ಧಿ',
'ಕುರಿಬೆಳ್ಳಿ',
'ಕುರಿಮೀನು',
'ಕುರಿಯಾದಿ',
'ಕುರುಂಕುರುಂ',
'ಕುರುಂಜಿಗೆ',
'ಕುರುಂಬಳೆ',
'ಕುರುಂಬಾಳೆ',
'ಕುರುಂಬಿಕೆ',
'ಕುರುಂಬುಲ್ಲು',
'ಕುರುಕಲು',
'ಕುರುಕುರಿ',
'ಕುರುಕುರು',
'ಕುರುಕ್ಷೇತ್ರ',
'ಕುರುಗಂಟು',
'ಕುರುಗಡ್ಡ',
'ಕುರುಗಣ್',
'ಕುರುಗುಟ್ಟು',
'ಕುರುಗುಡಿ',
'ಕುರುಚಲು',
'ಕುರುಚಿಗೆ',
'ಕುರುಜಾತು',
'ಕುರುಜಿಗೆ',
'ಕುರುಟಿಕೆ',
'ಕುರುಟಿಗ',
'ಕುರುಟಿಗೆ',
'ಕುರುಡಕ್ಕಿ',
'ಕುರುಡಾಗು',
'ಕುರುಡಾವು',
'ಕುರುಡಿಗೆ',
'ಕುರುಡುಗೆ',
'ಕುರುಡೇಟು',
'ಕುರುನಾಣ',
'ಕುರುಪದ',
'ಕುರುಪಾಟ',
'ಕುರುಬಾನಿ',
'ಕುರುಬಿತಿ',
'ಕುರುಬಿತ್ತಿ',
'ಕುರುಮಾಮ',
'ಕುರುವಂಜಿ',
'ಕುರುವಕ',
'ಕುರುವಾಯಿ',
'ಕುರುವಾಳು',
'ಕುರುವಿಂಗಿ',
'ಕುರುವಿಂದ',
'ಕುರುವಿಸ್ತ',
'ಕುರುವೇರು',
'ಕುರುವೇಲು',
'ಕುರುಸಾನಿ',
'ಕುರುಳಿಡು',
'ಕುರುಳ್ಕಟ್ಟು',
'ಕುರುಳ್ಗೊಯ್',
'ಕುರುಳ್ತಿದ್ದು',
'ಕುರುಳ್ದಲೆ',
'ಕುರೂಪತೆ',
'ಕುರ್ಕುಟಾಹಿ',
'ಕುರ್ಕುರೊಟ್ಟಿ',
'ಕುರ್ಚಿಮಂಚ',
'ಕುರ್ಜುಗಲ್ಲು',
'ಕುರ್ನಿಸಾತ್',
'ಕುರ್ನೀಸಾತು',
'ಕುರ್ಬಾನೀಸ್',
'ಕುರ್ರಮಾಮ',
'ಕುರ್ವಾಣತೆ',
'ಕುಲಂಕರ',
'ಕುಲಂಗೆಡು',
'ಕುಲಂಜನ',
'ಕುಲಕರ',
'ಕುಲಕರ್ಣಿ',
'ಕುಲಕೋಟಿ',
'ಕುಲಕ್ಷತಿ',
'ಕುಲಕ್ಷಯ',
'ಕುಲಗಡಿ',
'ಕುಲಗಿರಿ',
'ಕುಲಗುರು',
'ಕುಲಗೃಹ',
'ಕುಲಗೆಡು',
'ಕುಲಗೇಡಿ',
'ಕುಲಗೇಹ',
'ಕುಲಗೋತ್ರ',
'ಕುಲಚರ್ಯೆ',
'ಕುಲತ್ಥಿಕೆ',
'ಕುಲದೇವಿ',
'ಕುಲದೈವ',
'ಕುಲಧನ',
'ಕುಲಧರ',
'ಕುಲಧರ್ಮ',
'ಕುಲಧ್ವಜ',
'ಕುಲನಗ',
'ಕುಲನಾಮ',
'ಕುಲನಾರಿ',
'ಕುಲಪತಿ',
'ಕುಲಪುತ್ರ',
'ಕುಲಪುತ್ರಿ',
'ಕುಲಭೇದ',
'ಕುಲಭ್ರಷ್ಟ',
'ಕುಲಭ್ರಷ್ಟೆ',
'ಕುಲಮಗ',
'ಕುಲಮದ',
'ಕುಲಮಾರೆ',
'ಕುಲಯುತ',
'ಕುಲರಾಜ',
'ಕುಲವಧು',
'ಕುಲವಳಿ',
'ಕುಲವಱಿ',
'ಕುಲವೞಿ',
'ಕುಲವಾಡಿ',
'ಕುಲವಿದ್ಯೆ',
'ಕುಲವೀರ',
'ಕುಲವೃದ್ಧ',
'ಕುಲವೆಣ್',
'ಕುಲವೈರಿ',
'ಕುಲವ್ರತ',
'ಕುಲಶೈಲ',
'ಕುಲಶ್ರೇಷ್ಠಿ',
'ಕುಲಸತಿ',
'ಕುಲಸ್ಥಾನ',
'ಕುಲಹೀನ',
'ಕುಲಹೀನೆ',
'ಕುಲಾಂಗನೆ',
'ಕುಲಾಂಗಾರ',
'ಕುಲಾಂತಕ',
'ಕುಲಾಂತರ',
'ಕುಲಾಚಲ',
'ಕುಲಾಚಾರ',
'ಕುಲಾಚಾರ್ಯ',
'ಕುಲಾಯಿಸು',
'ಕುಲಾವಧಿ',
'ಕುಲಿಂಜನ',
'ಕುಲಿಕುಲಿ',
'ಕುಲಿಲಿಡು',
'ಕುಲಿಶಾಸ್ತ್ರ',
'ಕುಲೀನತೆ',
'ಕುಲೀನತ್ವ',
'ಕುಲುಕಣೆ',
'ಕುಲುಕಾಟ',
'ಕುಲುಕಿಸು',
'ಕುಲುಕುಲು',
'ಕುಲಕೋಟ',
'ಕುಲೆಸೊಪ್ಪು',
'ಕುಲೋದ್ದಾಮ',
'ಕುಲೋದ್ಭವ',
'ಕುಲೋದ್ಭೂತ',
'ಕುಲೋನ್ನತಿ',
'ಕುಲ್ಲತನ',
'ಕುವಧೂಕ',
'ಕುವಲಯ',
'ಕುವಳಯ',
'ಕುವಾಕ್ಛ್ರುತ',
'ಕುವಿಂದಕ',
'ಕುವಿಚಾರ',
'ಕುವಿಚಾರಿ',
'ಕುವಿಲಾಸಿ',
'ಕುವಿಳಾಸಿ',
'ಕುವೇರಕ',
'ಕುವೇರಾಕ್ಷಿ',
'ಕುವ್ವೆಹಿಟ್ಟು',
'ಕುಶಕುಸ',
'ಕುಶಗ್ರಂಥಿ',
'ಕುಶತಂತ್ರ',
'ಕುಶದಳ',
'ಕುಶಪಿಷ್ಟೆ',
'ಕುಶಲತೆ',
'ಕುಶಲತ್ವ',
'ಕುಶಲಹೆ',
'ಕುಶಲಿಕೆ',
'ಕುಶಲಿಸು',
'ಕುಶಸೂಚಿ',
'ಕುಶಾಂಕುರ',
'ಕುಶಾಗ್ರತೆ',
'ಕುಶಾಗ್ರೀಯ',
'ಕುಶಾಮತ್',
'ಕುಶಾಮತ್ತು',
'ಕುಶಾಸನ',
'ಕುಶೀಲಕ',
'ಕುಶೀಲವ',
'ಕುಶೇಶಯ',
'ಕುಷರಥ',
'ಕುಷ್ಟೆವೀೞ್',
'ಕುಷ್ಠಗಂಧಿ',
'ಕುಷ್ಠರೋಗ',
'ಕುಷ್ಠರೋಗಿ',
'ಕುಷ್ಮಾಂಡಕ',
'ಕುಸಂಸ್ಕಾರ',
'ಕುಸಕುಸ',
'ಕುಸಗುಟ್ಟು',
'ಕುಸಮಯ',
'ಕುಸಲಕ್ಕಿ',
'ಕುಸಲಿಸು',
'ಕುಸಿಕಿಱಿ',
'ಕುಸಿಗಾರ',
'ಕುಸಿಗುಟ್ಟು',
'ಕುಸಿದಲೆ',
'ಕುಸಿಬಿಷ್ಟಿ',
'ಕುಸಿಬೀಳು',
'ಕುಸಿಯಾಗು',
'ಕುಸಿಯಿಸು',
'ಕುಸಿವಿಷ್ಟೆ',
'ಕುಸಿವೆಱು',
'ಕುಸಿಹಾಲೆ',
'ಕುಸೀದಕ',
'ಕುಸೀದಿಕ',
'ಕುಸುಂಕಱು',
'ಕುಸುಂಕುಱು',
'ಕುಸುಂಬರಿ',
'ಕುಸುಕಱು',
'ಕುಸುಕಿಱಿ',
'ಕುಸುಕುರು',
'ಕುಸುಕುಸು',
'ಕುಸುಕುಱು',
'ಕುಸುಗುಟ್ಟು',
'ಕುಸುಬಲು',
'ಕುಸುಬಿಷ್ಟೆ',
'ಕುಸುಮಾಭ',
'ಕುಸುಮಾರೆ',
'ಕುಸುಮಾಸ್ತ್ರ',
'ಕುಸುಮಿತ',
'ಕುಸುಮಿಸು',
'ಕುಸುಮೇಷು',
'ಕುಸುರೆಳ್ಳು',
'ಕುಸೆಗಾತಿ',
'ಕುಸ್ತರಿಸು',
'ಕುಸ್ತಿಬಿಡು',
'ಕುಸ್ತಿಯಾಡು',
'ಕುಸ್ತಿಹಿಡಿ',
'ಕುಸ್ತುಂಬರಿ',
'ಕುಸ್ತುಂಬುರು',
'ಕುಸ್ತುಂಭರಿ',
'ಕುಹಕತೆ',
'ಕುಹರಣ',
'ಕುಹಿಮಾವು',
'ಕುಹುಯೋಗ',
'ಕುಹೂಯೋಗ',
'ಕುಹೂರಟ',
'ಕುಹೂರವ',
'ಕುಹೇತುಕ',
'ಕುಹೇಲಿಕೆ',
'ಕುಳದಗ್ಗು',
'ಕುಳಧರ',
'ಕುಳನಷ್ಟ',
'ಕುಳಪಾಲು',
'ಕುಳವಡೆ',
'ಕುಳವಧು',
'ಕುಳವಾಡಿ',
'ಕುಳವಾರ್',
'ಕುಳವಾರಿ',
'ಕುಳವಾರು',
'ಕುಳಶೈಲ',
'ಕುಳಸ್ಥಲ',
'ಕುಳಸ್ಥಳ',
'ಕುಳಾಂತಕ',
'ಕುಳಾಚಲ',
'ಕುಳಿಕಾಹಿ',
'ಕುಳಿತಟ್ಟೆ',
'ಕುಳಿಬೀಳು',
'ಕುಳಿಮಾವು',
'ಕುಳಿರಿಸು',
'ಕುಳಿರ್ಕಾಂತಿ',
'ಕುಳಿರ್ಕೂಟ',
'ಕುಳಿರ್ಕೊಳ್',
'ಕುಳಿರ್ಕೋಡು',
'ಕುಳಿರ್ಕೋಳು',
'ಕುಳಿರ್ಗಯ್',
'ಕುಳಿರ್ಗಯ್ಯ',
'ಕುಳಿರ್ಗಲ್',
'ಕುಳಿರ್ಗಾಳಿ',
'ಕುಳಿರ್ಗಲ್ಲು',
'ಕುಳಿರ್ಗೆಯ್',
'ಕುಳಿರ್ಗೆಯ್ಯ',
'ಕುಳಿರ್ಗೊಳ್',
'ಕುಳಿರ್ಗೊಳ',
'ಕುಳಿರ್ಚಳಿ',
'ಕುಳಿರ್ನೋಟ',
'ಕುಳಿರ್ಪನಿ',
'ಕುಳಿರ್ಪಾಱು',
'ಕುಳಿರ್ವನಿ',
'ಕುಳಿರ್ವನೆ',
'ಕುಳಿರ್ವೆಟ್ಟ',
'ಕುಳಿರ್ವೆಟ್ಟು',
'ಕುಳಿವುಗು',
'ಕುಳಿಶಾಶ್ಮ',
'ಕುಳಿಶಾಸ್ತ್ರ',
'ಕುಳಿಸರ್ಪ',
'ಕುಳಿಹೋಗು',
'ಕುಳೀರಕ',
'ಕುಳುತ್ಥಕ',
'ಕುಳುವಾಡಿ',
'ಕುಳುವಾರು',
'ಕುಳ್ಳನೆಯ',
'ಕುಳ್ಳಿರಿಸು',
'ಕುಱಿಗೊಳ್',
'ಕುಱಿತನ',
'ಕುಱಿದಱಿ',
'ಕುಱಿದೆಱೆ',
'ಕುಱಿಪಟ್ಟಿ',
'ಕುಱಿಪಿಡು',
'ಕುಱಿಮನ',
'ಕುಱಿಮಾಡು',
'ಕುಱಿವಿಂಡು',
'ಕುಱಿಹಿಕ್ಕೆ',
'ಕುಱುಂಬಿತಿ',
'ಕುಱುಂಬುಲ್',
'ಕುಱುಕಲು',
'ಕುಱುಕುಳಿ',
'ಕುಱುಕುಱು',
'ಕುಱುಕುಱುಂ',
'ಕುಱುಗಂಟು',
'ಕುಱುಗಡ್ಡ',
'ಕುಱುಗಣ್',
'ಕುಱುಗಣಿ',
'ಕುಱುಗಣ್ಣು',
'ಕುಱುಗಯ್',
'ಕುಱುಗಯ್ಯ',
'ಕುಱುಗಲಿ',
'ಕುಱುಗಾಡು',
'ಕುಱುಗಿಡ',
'ಕುಱುಗಿಣ್ಣು',
'ಕುಱುಗುಣಿ',
'ಕುಱುಗೆಯ್',
'ಕುಱುಗೆಯ್ಯ',
'ಕುಱುಗೆಯ್ಯಿ',
'ಕುಱುಗೋಲು',
'ಕುಱುಚಾಳ್',
'ಕುಱುಜಂಗೆ',
'ಕುಱುಜಂಘೆ',
'ಕುಱುದಡಿ',
'ಕುಱುದಡ್ಡಿ',
'ಕುಱುದಱಿ',
'ಕುಱುದಾರಿ',
'ಕುಱುದೆಱೆ',
'ಕುಱುದೊಡೆ',
'ಕುಱುಪಾಟ',
'ಕುಱುಪಿಡು',
'ಕುಱುಪೊಂದು',
'ಕುಱುಬಿತಿ',
'ಕುಱುಬಿತ್ತಿ',
'ಕುಱುಮನೆ',
'ಕುಱುವಜ್ಜೆ',
'ಕುಱುವಡಿ',
'ಕುಱುವಡೆ',
'ಕುಱುವಳ್ಳ',
'ಕುಱುವಿಡಿ',
'ಕುಱುಹಂದಿ',
'ಕುಱುಹಿಡು',
'ಕುೞಿಕಣ್',
'ಕುೞಿಗೊಳ್',
'ಕುೞಿವಳ್ಳ',
'ಕುೞಿವುಗು',
'ಕುೞಿವೊಗು',
'ಕೂಕತನ',
'ಕೂಕೂಹಕ್ಕಿ',
'ಕೂಗಳತೆ',
'ಕೂಗಿಡಿಸು',
'ಕೂಗುದಾರಿ',
'ಕೂಗುಬಂಡೆ',
'ಕೂಗುಮಾರಿ',
'ಕೂಗುಹಾಕು',
'ಕೂಚಂಭಟ್ಟ',
'ಕೂಚಗಲ್ಲು',
'ಕೂಚಿಪತ್ರ',
'ಕೂಚಿಪುಡಿ',
'ಕೂಚಿಭಟ್ಟ',
'ಕೂಚುಕೋಲು',
'ಕೂಚುಗೋಲು',
'ಕೂಚುಪುಡಿ',
'ಕೂಚುಭಟ್ಟ',
'ಕೂಚುಭೇರಿ',
'ಕೂಚೆಯಾಗು',
'ಕೂಟಕುಳಿ',
'ಕೂಟಕೂಡು',
'ಕೂಟಕ್ರಿಯೆ',
'ಕೂಟಗಾನ',
'ಕೂಟಗೇರು',
'ಕೂಟಣಿಗ',
'ಕೂಟತರ್ಕ',
'ಕೂಟತಾನ',
'ಕೂಟದಳೆ',
'ಕೂಟನಾಟ್ಯ',
'ಕೂಟನೀತಿ',
'ಕೂಟಪತ್ರ',
'ಕೂಟಪದ್ಯ',
'ಕೂಟಪ್ರಶ್ನೆ',
'ಕೂಟಮಾನ',
'ಕೂಟಯಂತ್ರ',
'ಕೂಟಯುಕ್ತಿ',
'ಕೂಟಯುದ್ಧ',
'ಕೂಟಯೋಗ',
'ಕೂಟಯೋಧಿ',
'ಕೂಟಲೇಖ',
'ಕೂಟವಡೆ',
'ಕೂಟವಾದ',
'ಕೂಟಸಾಕ್ಷಿ',
'ಕೂಟಸಾಕ್ಷ್ಯ',
'ಕೂಟಸ್ಥಲ',
'ಕೂಟಸ್ಥಾನ',
'ಕೂಟಾಗಾರ',
'ಕೂಟಾಚಾರ',
'ಕೂಟಾವಳಿ',
'ಕೂಟಿಗೇರು',
'ಕೂಟೆಗೇರು',
'ಕೂಟೋಪಾಯ',
'ಕೂಡಕಂಜಿ',
'ಕೂಡಕ್ಷರ',
'ಕೂಡರಿವು',
'ಕೂಡಲಿಕ್ಕು',
'ಕೂಡಲೊಟ್ಟು',
'ಕೂಡಾವಳಿ',
'ಕೂಡಾಳಿಕೆ',
'ಕೂಡಿರಿಸು',
'ಕೂಡುಂಬಳಿ',
'ಕೂಡುಕೊಂಡಿ',
'ಕೂಡುಗಂಬಿ',
'ಕೂಡುಗಚ್ಚು',
'ಕೂಡುಗಟ್ಟು',
'ಕೂಡುಗಣ್ಣು',
'ಕೂಡುಗೆರೆ',
'ಕೂಡುಜೀವಿ',
'ಕೂಡುದಲೆ',
'ಕೂಡುದಾರಿ',
'ಕೂಡುದೊಡ್ಡಿ',
'ಕೂಡುನುಡಿ',
'ಕೂಡುಪಾಲು',
'ಕೂಡುಪೊರೆ',
'ಕೂಡುಪ್ರತಿ',
'ಕೂಡುಬೆಣೆ',
'ಕೂಡುಮಣೆ',
'ಕೂಡುಮಾತು',
'ಕೂಡುಮೊಳೆ',
'ಕೂಡುರಸ್ತೆ',
'ಕೂಡುವಳಿ',
'ಕೂಡುಹೊಳೆ',
'ಕೂಡೆವರ್',
'ಕೂಡೊಕ್ಕಲು',
'ಕೂಣಿಮೀನ್',
'ಕೂತೂಹಲ',
'ಕೂನದೇಹ',
'ಕೂನಬೆನ್',
'ಕೂನುಗಯ್',
'ಕೂನುಬೆನ್ನು',
'ಕೂಪತನ',
'ಕೂಬೆಹಿಟ್ಟು',
'ಕೂಮೆಮರ',
'ಕೂರಂಕುಶ',
'ಕೂರಂಕುಸ',
'ಕೂರದಂಟು',
'ಕೂರಪಂದಿ',
'ಕೂರಮಾಡು',
'ಕೂರಮಾವು',
'ಕೂರಲಗು',
'ಕೂರಳಲ್',
'ಕೂರಾಯುಧ',
'ಕೂರಾಳ್ತನ',
'ಕೂರುಗಣೆ',
'ಕೂರುಗುರ್',
'ಕೂರುಗುರು',
'ಕೂರುಗೊಳ್',
'ಕೂರುಬಾಯಿ',
'ಕೂರ್ಗತ್ತಿಗೆ',
'ಕೂರ್ಗಲಹ',
'ಕೂರ್ಗೆಲಸ',
'ಕೂರ್ಗೊಳಗು',
'ಕೂರ್ಚರೋಗ',
'ಕೂರ್ಚರೋಮ',
'ಕೂರ್ಚಶೀರ್ಷ',
'ಕೂರ್ಚಾಸನ',
'ಕೂರ್ನಾಲಗೆ',
'ಕೂರ್ಪಾಸಕ',
'ಕೂರ್ಪುಗಿಡು',
'ಕೂರ್ಪುಡುಗು',
'ಕೂರ್ಪೇಱಿಸು',
'ಕೂರ್ಮಗತಿ',
'ಕೂರ್ಮಚಕ್ರ',
'ಕೂರ್ಮಪುಟ',
'ಕೂರ್ಮರೋಮ',
'ಕೂರ್ಮವಾಯು',
'ಕೂರ್ಮವೀಣೆ',
'ಕೂರ್ಮಾಸನ',
'ಕೂರ್ಮೆಗೆಡು',
'ಕೂರ್ವಿಸಿಲ್',
'ಕೂರ್ಸರಲ್',
'ಕೂರ್ಸರಳು',
'ಕೂಲಂಕಷ',
'ಕೂಲಂಕಷೆ',
'ಕೂಲದೇಶ',
'ಕೂಲಬಡು',
'ಕೂಲವತಿ',
'ಕೂಲಾಂತರ',
'ಕೂಲಿಕಾತಿ',
'ಕೂಲಿಕಾರ',
'ಕೂಲಿಕಾರ್ತಿ',
'ಕೂಲಿಕಾಱ',
'ಕೂಲಿಗಾರ',
'ಕೂಲಿಗೊಳ್',
'ಕೂಲಿಜನ',
'ಕೂಲಿಮಠ',
'ಕೂಲುಕಂಬ',
'ಕೂಲುದುಂಬು',
'ಕೂಲುಬಾವಿ',
'ಕೂಲುವೆಜ್ಜ',
'ಕೂವಕಂಬ',
'ಕೂವಕಂಭ',
'ಕೂವೆಗಂಬ',
'ಕೂವೆಗಿಡ',
'ಕೂವೆಮರ',
'ಕೂವೆಹಿಟ್ಟು',
'ಕೂಷ್ಮಾಂಡಿನಿ',
'ಕೂಸಂಭಟ್ಟ',
'ಕೂಸಾಡಿಸು',
'ಕೂಸುಕೋಣೆ',
'ಕೂಸುಗುಡು',
'ಕೂಸುಗೊಡು',
'ಕೂಸುತನ',
'ಕೂಸುಮರಿ',
'ಕೂಸುಮೃಗ',
'ಕೂಳತನ',
'ಕೂಳಬಕ್ಕ',
'ಕೂಳಬಾಕ',
'ಕೂಳುಗೇಡಿ',
'ಕೂಳುಗೇಡು',
'ಕೂಳುಭಂಡ',
'ಕೂಳುವ್ಯಾಧಿ',
'ಕೂಳೆಕಾಯಿ',
'ಕೂಳೆಗಿಡ',
'ಕೂಳೆಬೆಳೆ',
'ಕೂಳ್ಬಡಕ',
'ಕೂಳ್ಬಡಿಕ',
'ಕೂಳ್ಬಣಿಗ',
'ಕೂಳ್ವಣಿಗ',
'ಕೂಱುಗೊಳ್',
'ಕೂೞವುೞ್',
'ಕೂೞ್ಬಡಿಕ',
'ಕೂೞ್ಬಸವು',
'ಕೂೞ್ವಣಿಗ',
'ಕೃಕಲಾಸ',
'ಕೃಕವಾಕ',
'ಕೃಕವಾಕು',
'ಕೃಕಾಟಿಕ',
'ಕೃಕಾಟಿಕೆ',
'ಕೃತಕತೆ',
'ಕೃತಕರ್ಮ',
'ಕೃತಕಲ್ಪ',
'ಕೃತಕಾದ್ರಿ',
'ಕೃತಕಾಮ',
'ಕೃತಕಾರ್ಯ',
'ಕೃತಕೃತ್ಯ',
'ಕೃತಗೇಡಿ',
'ಕೃತಘ್ನತೆ',
'ಕೃತಜ್ಞತೆ',
'ಕೃತಜ್ಞತ್ವ',
'ಕೃತನಾಶಿ',
'ಕೃತಪುಂಖ',
'ಕೃತಪುಣ್ಯ',
'ಕೃತಪುಣ್ಯೆ',
'ಕೃತಮತಿ',
'ಕೃತಮಾಲ',
'ಕೃತಮಾಳ',
'ಕೃತಮುಖ',
'ಕೃತಯುಗ',
'ಕೃತವಿದ್ಯ',
'ಕೃತವೇದಿ',
'ಕೃತಸ್ವರ',
'ಕೃತಹಸ್ತ',
'ಕೃತಾಂಜಲಿ',
'ಕೃತಾಂತಕ',
'ಕೃತಾರ್ಥತೆ',
'ಕೃತಾರ್ಥಾತ್ಮ',
'ಕೃತಿಕಾಯ',
'ಕೃತಿಕರ್ತೃ',
'ಕೃತಿಕಾರ',
'ಕೃತಿಗೆಯ್',
'ಕೃತಿಚೋರ',
'ಕೃತಿಚೌರ್ಯ',
'ಕೃತಿದೋಷ',
'ಕೃತಿಪತಿ',
'ಕೃತಿಭರ್ತ',
'ಕೃತಿಮತ',
'ಕೃತಿಯುಕ್ತ',
'ಕೃತಿಲೋಕ',
'ಕೃತಿವಂತ',
'ಕೃತಿವೇಳು',
'ಕೃತಿವೇೞ್',
'ಕೃತಿಸ್ವಾಮ್ಯ',
'ಕೃತ್ತಕ್ಲೇಶ',
'ಕೃತ್ತಿಧರ',
'ಕೃತ್ತಿವಸ್ತ್ರ',
'ಕೃತ್ತಿವಾಸ',
'ಕೃತ್ಯಶೇಷ',
'ಕೃತ್ಯಾಕೃತ್ಯ',
'ಕೃತ್ಯೋದಾರ',
'ಕೃತ್ರಿಮತೆ',
'ಕೃತ್ಸ್ನಸುಖ',
'ಕೃಪಣತೆ',
'ಕೃಪಣತ್ವ',
'ಕೃಪಾಕರ',
'ಕೃಪಾದೃಷ್ಟಿ',
'ಕೃಪಾನಿಧಿ',
'ಕೃಪಾಪಾಂಗ',
'ಕೃಪಾಲೋಕ',
'ಕೃಪಾವತಿ',
'ಕೃಪಾಶ್ರಯ',
'ಕೃಪಾಹೀನ',
'ಕೃಪಾಹೀನೆ',
'ಕೃಪಾಳುತೆ',
'ಕೃಪೆವಡೆ',
'ಕೃಮಿರೋಗ',
'ಕೃಶಕಾಯ',
'ಕೃಶಕಾಯೆ',
'ಕೃಶಗಾತ್ರ',
'ಕೃಶಮಧ್ಯೆ',
'ಕೃಶರಾಗ',
'ಕೃಶರಾನ್ನ',
'ಕೃಶರೋಗ',
'ಕೃಶಶರ',
'ಕೃಶೋದರ',
'ಕೃಶೋದರಿ',
'ಕೃಷಿಕರ್ಮ',
'ಕೃಷೀವಲ',
'ಕೃಷ್ಟಪಾಕ',
'ಕೃಷ್ಣಕಥೆ',
'ಕೃಷ್ಣಕರ್ಮ',
'ಕೃಷ್ಣಕಾಕ',
'ಕೃಷ್ಣಕೀರ್ತಿ',
'ಕೃಷ್ಣಗಳ',
'ಕೃಷ್ಣಗೊಲ್ಲ',
'ಕೃಷ್ಣಜೇನು',
'ಕೃಷ್ಣದ್ರಾಕ್ಷೆ',
'ಕೃಷ್ಣನೆಲ್ಲಿ',
'ಕೃಷ್ಣಪಕ್ಷ',
'ಕೃಷ್ಣಪಶು',
'ಕೃಷ್ಣಫಣಿ',
'ಕೃಷ್ಣಫಲೆ',
'ಕೃಷ್ಣಭೂಮ',
'ಕೃಷ್ಣಭೂಮಿ',
'ಕೃಷ್ಣಭೇದಿ',
'ಕೃಷ್ಣಮತಿ',
'ಕೃಷ್ಣಮಾರ್ಗ',
'ಕೃಷ್ಣಮುಖ',
'ಕೃಷ್ಣಮುನಿ',
'ಕೃಷ್ಣಮೃಗ',
'ಕೃಷ್ಣಲೀಲೆ',
'ಕೃಷ್ಣಲೇಶ್ಯೆ',
'ಕೃಷ್ಣಲೋಹ',
'ಕೃಷ್ಣವಕ್ತ್ರ',
'ಕೃಷ್ಣವರ್ಣ',
'ಕೃಷ್ಣವರ್ಣೆ',
'ಕೃಷ್ಣವತ್ರ್ಮ',
'ಕೃಷ್ಣವೃಂತೆ',
'ಕೃಷ್ಣವೃಕ್ಷ',
'ಕೃಷ್ಣವೇಣಿ',
'ಕೃಷ್ಣಸನ್ನಿ',
'ಕೃಷ್ಣಸರ್ಪ',
'ಕೃಷ್ಣಸಾರ',
'ಕೃಷ್ಣಾಂಜನ',
'ಕೃಷ್ಣಾಂಜಿನ',
'ಕೃಷ್ಣಾಂಬರ',
'ಕೃಷ್ಣಾಗರು',
'ಕೃಷ್ಣಾಜಿನ',
'ಕೃಷ್ಣಾರ್ಪಣ',
'ಕೃಷ್ಣಾಷ್ಟಮಿ',
'ಕೃಷ್ಣೋರಗ',
'ಕೃಸರಾನ್ನ',
'ಕೆಂಕಂಬಡೆ',
'ಕೆಂಕರಿಕೆ',
'ಕೆಂಕರಿಸು',
'ಕೆಂಕವಣಿ',
'ಕೆಂಗಣ್ವಕ್ಕಿ',
'ಕೆಂಗದಿರ್',
'ಕೆಂಗದಿರ',
'ಕೆಂಗಲಿಸು',
'ಕೆಂಗಲ್ಗರು',
'ಕೆಂಗಳವೆ',
'ಕೆಂಗೞವೆ',
'ಕೆಂಗಾಂಚಾಳ',
'ಕೆಂಗಿಡಿಯ',
'ಕೆಂಗುರುಳ್',
'ಕೆಂಗುರುಳು',
'ಕೆಂಗೂದಲ್',
'ಕೆಂಗೊನರ್',
'ಕೆಂಗೋಡಗ',
'ಕೆಂಗೋರಟೆ',
'ಕೆಂಗೋಲ್ಗೊಳ್',
'ಕೆಂಚಣಿಲು',
'ಕೆಂಚದಲೆ',
'ಕೆಂಚುದಲೆ',
'ಕೆಂಜಿಗುರ್',
'ಕೆಂಜೊಡರ್',
'ಕೆಂಡಕಾರು',
'ಕೆಂಡಗಣ್',
'ಕೆಂಡಗಣ್ಣ',
'ಕೆಂಡಗಣ್ಣು',
'ಕೆಂಡಗೆಂಪು',
'ಕೆಂಡರೊಟ್ಟಿ',
'ಕೆಂಡವಕ್ಕಿ',
'ಕೆಂಡವೞೆ',
'ಕೆಂಡಸೇವೆ',
'ಕೆಂದಣಿಲು',
'ಕೆಂದಲಸು',
'ಕೆಂದಲೆಗ',
'ಕೆಂದಲೆಯ',
'ಕೆಂದಳಿರ್',
'ಕೆಂದಳಿರು',
'ಕೆಂದಳಿಲು',
'ಕೆಂದಾಮರೆ',
'ಕೆಂದಾವರೆ',
'ಕೆಂಧೂಳಿಡು',
'ಕೆಂಪಡರ್',
'ಕೆಂಪಡರು',
'ಕೆಂಪಡಸು',
'ಕೆಂಪರಗು',
'ಕೆಂಪಿರುವೆ',
'ಕೆಂಪುಕಲ್ಲು',
'ಕೆಂಪುಕಾಚಿ',
'ಕೆಂಪುಕೂವೆ',
'ಕೆಂಪುಕೊಗ್ಗೆ',
'ಕೆಂಪುಕೊಮ್ಮೆ',
'ಕೆಂಪುಗಂಧ',
'ಕೆಂಪುಗಂಧಿ',
'ಕೆಂಪುಗುಳ್ಳ',
'ಕೆಂಪುಗೂಡು',
'ಕೆಂಪುಗೇರು',
'ಕೆಂಪುಗೊಂಡೆ',
'ಕೆಂಪುಗೊದ್ದ',
'ಕೆಂಪುಚಳ್ಳೆ',
'ಕೆಂಪುಜನ',
'ಕೆಂಪುಜಾಲಿ',
'ಕೆಂಪುತಾಳೆ',
'ಕೆಂಪುತೈಲಿ',
'ಕೆಂಪುದಾಳೆ',
'ಕೆಂಪುದುಂಬಿ',
'ಕೆಂಪುದೋಱು',
'ಕೆಂಪುನರಿ',
'ಕೆಂಪುನೆಲ್ಲು',
'ಕೆಂಪುನೊಳ',
'ಕೆಂಪುಪಟ್ಟಿ',
'ಕೆಂಪುಮಲೆ',
'ಕೆಂಪುಮೀನು',
'ಕೆಂಪುರತ್ನ',
'ಕೆಂಪುವಾಸು',
'ಕೆಂಪುವೀಱು',
'ಕೆಂಪುಸೀಗೆ',
'ಕೆಂಪುಹತ್ತಿ',
'ಕೆಂಪುಹಾಳೆ',
'ಕೆಂಪುಹೂಲಿ',
'ಕೆಂಪುಹೊನ್ನೆ',
'ಕೆಂಪೆಳಸು',
'ಕೆಂಪೊದವು',
'ಕೆಂಬಟಿಕೆ',
'ಕೆಂಬರಲ್',
'ಕೆಂಬರಲು',
'ಕೆಂಬರವು',
'ಕೆಂಬರಳು',
'ಕೆಂಬರಿಗೆ',
'ಕೆಂಬರುಹೆ',
'ಕೆಂಬಲಗೆ',
'ಕೆಂಬಲರ್',
'ಕೆಂಬವಳ',
'ಕೆಂಬಸುರ್',
'ಕೆಂಬಱುಹೆ',
'ಕೆಂಬಾಸುಳ್',
'ಕೆಂಬಿಸಿಲ್',
'ಕೆಂಬೆಳಕು',
'ಕೆಂಬೆಳಗು',
'ಕೆಂಬೊಗರ್',
'ಕೆಂಬೊನಲ್',
'ಕೆಂಬೊಳಪು',
'ಕೆಕ್ಕರಿಕೆ',
'ಕೆಕ್ಕರಿಗೆ',
'ಕೆಕ್ಕರಿಸು',
'ಕೆಕ್ಕಳಿಸು',
'ಕೆಗನಿಕೆ',
'ಕೆಚ್ಚುಗಟ್ಟು',
'ಕೆಚ್ಚುಗೆಂಪು',
'ಕೆಚ್ಚುಗೊಳ್',
'ಕೆಚ್ಚುಮನ',
'ಕೆಚ್ಚುಱುಬು',
'ಕೆಚ್ಚೇಱಿಸು',
'ಕೆಟ್ಟತನ',
'ಕೆಟ್ಟದಾರಿ',
'ಕೆಟ್ಟನುಡಿ',
'ಕೆಟ್ಟಹಾವು',
'ಕೆಟ್ಟಾಡಿತ',
'ಕೆಟ್ಟೆಣಿಸು',
'ಕೆಟ್ಟೊಡಲ್',
'ಕೆಡಗೆಯ್',
'ಕೆಡಪಿಸು',
'ಕೆಡಮೆಟ್ಟು',
'ಕೆಡವಿಸು',
'ಕೆಡಹಿಸು',
'ಕೆಡುವಟ್ಟೆ',
'ಕೆಡುಸೊಲ್',
'ಕೆಡೆಕಟ್ಟು',
'ಕೆಡೆಗುತ್ತು',
'ಕೆಡೆತಿವಿ',
'ಕೆಡೆನುಡಿ',
'ಕೆಡೆನೂಂಕು',
'ಕೆಡೆನೂಕು',
'ಕೆಡೆಬಗೆ',
'ಕೆಡೆಬಡಿ',
'ಕೆಡೆಬೀಳು',
'ಕೆಡೆಮೆಟ್ಟು',
'ಕೆಡೆಯಿಕ್ಕು',
'ಕೆಡೆಯಿಸು',
'ಕೆಡೆಯಿಱಿ',
'ಕೆಡೆಯೆಸು',
'ಕೆಡೆಯೊದೆ',
'ಕೆಡೆವೊಡೆ',
'ಕೆಡೆವೊಯ್',
'ಕೆಡೆಹೊಡೆ',
'ಕೆಡೆಹೊಯ್',
'ಕೆಣಕಾಟ',
'ಕೆಣಕಿಸು',
'ಕೆತ್ತವಾರಿ',
'ಕೆತ್ತುಗುಂದು',
'ಕೆತ್ತುಗೆಯ್',
'ಕೆತ್ತುವುಳಿ',
'ಕೆತ್ತೆಬಜೆ',
'ಕೆದರ್ಗಿಡಿ',
'ಕೆದರ್ತಲೆ',
'ಕೆದರ್ದಲೆ',
'ಕೆದರ್ಬಿರ್ಚು',
'ಕೆದಱಿಕೆ',
'ಕೆದಱಿಸು',
'ಕೆದಱೇೞ್',
'ಕೆನೆಕಟ್ಟು',
'ಕೆನೆಗಟ್ಟಿ',
'ಕೆನೆಗಟ್ಟು',
'ಕೆನೆಗೊಳ್',
'ಕೆನೆವಾಲ್',
'ಕೆನೆವಾಲು',
'ಕೆನೆಹಾಲು',
'ಕೆನ್ನಗಸೆ',
'ಕೆನ್ನವಿಕ್ಕು',
'ಕೆನ್ನವಿರ್',
'ಕೆನ್ನಾಬಲು',
'ಕೆನ್ನಾಲಗೆ',
'ಕೆನ್ನಾಲಿಗೆ',
'ಕೆನ್ನೀರಾಡು',
'ಕೆನ್ನೀರ್ಗುಡಿ',
'ಕೆನ್ನೀರ್ಮುತ್ತು',
'ಕೆನ್ನೀಲಿಕೆ',
'ಕೆನ್ನೆಗ್ಗಿಲು',
'ಕೆನ್ನೆತ್ತರ್',
'ಕೆನ್ನೆತ್ತರು',
'ಕೆನ್ನೆಯೋರೆ',
'ಕೆನ್ನೆಯ್ದಿಲೆ',
'ಕೆನ್ನೇರಳೆ',
'ಕೆನ್ನೈದಿಲೆ',
'ಕೆನ್ನೌಡಲ',
'ಕೆಪ್ಪುಗಟ್ಟು',
'ಕೆಬ್ಬರಿಸು',
'ಕೆಬ್ಬೆತೋಟ',
'ಕೆಬ್ಬೆರೋಗ',
'ಕೆಬ್ಬೆವಣ್',
'ಕೆಮ್ಮುಗಿಲ್',
'ಕೆಯಿಗಂಪು',
'ಕೆಯಿಮನೆ',
'ಕೆಯಿಸುಂಕ',
'ಕೆಯ್ಕುಸುರಿ',
'ಕೆಯ್ಕೊಳಿಸು',
'ಕೆಯ್ಕೊಳುಹ',
'ಕೆಯ್ಗಟ್ಟಳೆ',
'ಕೆಯ್ಗಾಣಿಕೆ',
'ಕೆಯ್ಗಾವಲಿ',
'ಕೆಯ್ಗಾವಲು',
'ಕೆಯ್ಗುಡುತೆ',
'ಕೆಯ್ಗೂಡಿಸು',
'ಕೆಯ್ಗೊಳಿಸು',
'ಕೆಯ್ಜೋಡಿಸು',
'ಕೆಯ್ಜೋಳಿಸು',
'ಕೆಯ್ದೀವಿಗೆ',
'ಕೆಯ್ದುಕಾರ',
'ಕೆಯ್ದುಕೆಯ್',
'ಕೆಯ್ದುಗೆಯ್',
'ಕೆಯ್ದುಗೊಳ್',
'ಕೆಯ್ದುಡುಕು',
'ಕೆಯ್ದುವಿಡಿ',
'ಕೆಯ್ದುವೊಱು',
'ಕೆಯ್ನೀರೆಱೆ',
'ಕೆಯ್ನೆರವು',
'ಕೆಯ್ಪಾವಡ',
'ಕೆಯ್ಮಸಕ',
'ಕೆಯ್ಮೀಱುಹ',
'ಕೆಯ್ಯಳವಿ',
'ಕೆಯ್ಯಳವು',
'ಕೆಯ್ವಱೆಗ',
'ಕೆಯ್ವಿಡಿತೆ',
'ಕೆಯ್ವೆರಸು',
'ಕೆಯ್ವೊಲಂಗಾ',
'ಕೆಯ್ವೊಲಗಾ',
'ಕೆಯ್ಸುರಿಗೆ',
'ಕೆರಕಲು',
'ಕೆರಗಾಱ',
'ಕೆರಬೆಕ್ಕು',
'ಕೆರಹುಳಿ',
'ಕೆರಳಿಕೆ',
'ಕೆರಳಿಚು',
'ಕೆರಳಿಸು',
'ಕೆರಳ್ಚಿಸು',
'ಕೆರೆಕಟ್ಟೆ',
'ಕೆರೆಬೆಂಡು',
'ಕೆರೆಮಣೆ',
'ಕೆರೆಯೊಡೆ',
'ಕೆರ್ಚುವೀೞ್',
'ಕೆರ್ಪುಗಳೆ',
'ಕೆರ್ಪುಗಾಲ್',
'ಕೆಲಂದೆಗೆ',
'ಕೆಲಂದೋಱು',
'ಕೆಲಂಬರ್',
'ಕೆಲಂಬರು',
'ಕೆಲಂಬಿಡಿ',
'ಕೆಲಂಬೊಗು',
'ಕೆಲಂಬೊಳ್ತು',
'ಕೆಲಕಡೆ',
'ಕೆಲಚಾಚು',
'ಕೆಲಬರ್',
'ಕೆಲಬರು',
'ಕೆಲಬಲ',
'ಕೆಲಯಿಸು',
'ಕೆಲವರಿ',
'ಕೆಲವರು',
'ಕೆಲವಷ್ಟು',
'ಕೆಲವಾರು',
'ಕೆಲವೊಮ್ಮೆ',
'ಕೆಲಸರಿ',
'ಕೆಲಸಾರ್',
'ಕೆಲಸಾರು',
'ಕೆಲಸಾರ್ಚು',
'ಕೆಲಸಿಗ',
'ಕೆಲಸಿಡಿ',
'ಕೆಲಸೂಸು',
'ಕೆಲ್ಲಯಿಸು',
'ಕೆಲ್ಲವಿಸು',
'ಕೆಲ್ಲೆಗಣ್',
'ಕೆಲ್ಲೆಗೆಡೆ',
'ಕೆಲ್ಲೆನೋಟ',
'ಕೆಸರಂಕ',
'ಕೆಸರ್ಗಚ್ಚು',
'ಕೆಸರ್ಗಲ್',
'ಕೆಸರ್ಗೂಡು',
'ಕೆಸರ್ತೂಣ',
'ಕೆಸರ್ನೀರ್',
'ಕೆಸರ್ಮಾಡು',
'ಕೆಸಱಂಕ',
'ಕೆಸಱಿಕ್ಕು',
'ಕೆಸಱಿಗೆ',
'ಕೆಸಱಿಡು',
'ಕೆಸಱೇೞು',
'ಕೆಸುಕಾಡು',
'ಕೆಸುಮಣ್ಣು',
'ಕೆಹೆಕಬ್ಬು',
'ಕೆಳಂದಲೆ',
'ಕೆಳಗಡೆ',
'ಕೆಳಗಣ',
'ಕೆಳಗಿವಿ',
'ಕೆಳತಟ್ಟು',
'ಕೆಳದಾಡೆ',
'ಕೆಳದಿಕ್ಕೆ',
'ಕೆಳಮನೆ',
'ಕೆಳಯಿಂಕೆ',
'ಕೆಳಯಿಕೆ',
'ಕೆಳಯಿಕ್ಕೆ',
'ಕೆಳರಿಚು',
'ಕೆಳಲೋಕ',
'ಕೆಳವಾಡಿ',
'ಕೆಳಸಾಗು',
'ಕೆಳಸಾರ್',
'ಕೆಳೆಗೊಳ್',
'ಕೆಳೆಗೊಳು',
'ಕೆಳೆತನ',
'ಕೆಳೆಯಿಂಕೆ',
'ಕೆಱೆವೊಗು',
'ಕೆೞಗಡೆ',
'ಕೆೞಗಿವಿ',
'ಕೇಂಕರಿಸು',
'ಕೇಂದ್ರಬಿಂದು',
'ಕೇಂದ್ರವ್ಯಕ್ತಿ',
'ಕೇಂದ್ರಾತಿಗ',
'ಕೇಂದ್ರೀಕೃತ',
'ಕೇಕರಿಕೆ',
'ಕೇಕರಿಸು',
'ಕೇಕಾರವ',
'ಕೇಕಾಸ್ವರ',
'ಕೇಕಿಯಂತ್ರ',
'ಕೇಕಿವ್ರಜ',
'ಕೇಡಾಕುಳಿ',
'ಕೇಡಿತನ',
'ಕೇಡುಗಾಣು',
'ಕೇಡುಗಾಲ',
'ಕೇಡುಸುದ್ದಿ',
'ಕೇಡೆಯ್ದಿಸು',
'ಕೇಣಂಗೊಳ್',
'ಕೇಣಂಬಡೆ',
'ಕೇಣಕಾಱ',
'ಕೇಣಗೊಳ್',
'ಕೇಣಸರ',
'ಕೇಣಿಕಾಱ',
'ಕೇಣಿಗಾಱ',
'ಕೇಣಿಗೊಳ್',
'ಕೇತಿಮಾರಿ',
'ಕೇತುದಂಡ',
'ಕೇತುಪಟ',
'ಕೇದರಿಸಿ',
'ಕೇಪುತೊಟ್ಟು',
'ಕೇರಡಕೆ',
'ಕೇರಡಿಕೆ',
'ಕೇರಬೀಜ',
'ಕೇರಮರ',
'ಕೇರಮಾವು',
'ಕೇರಿಗೊಳ್',
'ಕೇರುಬೀಜ',
'ಕೇರೆತುಪ್ಪ',
'ಕೇರೆಮೀನು',
'ಕೇರೆಹಾವು',
'ಕೇಲಿಕಿಲ',
'ಕೇಲಿಗೃಹ',
'ಕೇಲೀವನ',
'ಕೇವಟಿಗೆ',
'ಕೇವಣಿಸು',
'ಕೇವಲತ್ವ',
'ಕೇವಲಿತ್ವ',
'ಕೇವಳಿಸು',
'ಕೇಶಪಕ್ಷ',
'ಕೇಶಪಾಶ',
'ಕೇಶಬಂಧ',
'ಕೇಶಬಾರಿ',
'ಕೇಶಭೂಷೆ',
'ಕೇಶರಾಜ',
'ಕೇಶರಾಜಿ',
'ಕೇಶರಾಶಿ',
'ಕೇಶವೇಶ',
'ಕೇಶಹಸ್ತ',
'ಕೇಶಾಕೇಶಿ',
'ಕೇಶೋತ್ಕರ',
'ಕೇಸಡಗು',
'ಕೇಸಡಿಯ',
'ಕೇಸರಲ್',
'ಕೇಸರಿಕ',
'ಕೇಸರಿಕೆ',
'ಕೇಸರಿಣಿ',
'ಕೇಸರಿಸು',
'ಕೇಸಲರ್',
'ಕೇಸಳಗ',
'ಕೇಸಾಕೇಸಿ',
'ಕೇಸಾರಕ',
'ಕೇಸಿರುವೆ',
'ಕೇಸೆರುಗು',
'ಕೇಸೊಡಲ್',
'ಕೇಸೊಡಲ',
'ಕೇಳಮೇಳ',
'ಕೇಳವಣ',
'ಕೇಳಿಕೊಟ್ಟು',
'ಕೇಳಿಗೃಹ',
'ಕೇಳಿಲೀಲೆ',
'ಕೇಳಿವಾತು',
'ಕೇಳಿಶೀಳ',
'ಕೇಳೀಲೋಲ',
'ಕೇಳೀಲೋಲೆ',
'ಕೇಳೀವನ',
'ಕೇಳೀಶೈಲ',
'ಕೇಳೀಸ್ಥಾನ',
'ಕೈಕಡಗ',
'ಕೈಕರಣ',
'ಕೈಕರಣೆ',
'ಕೈಕಸಬು',
'ಕೈಕಸುಬು',
'ಕೈಕಾಗದ',
'ಕೈಕಾಣಿಕೆ',
'ಕೈಕಾಳಗ',
'ಕೈಕುಂಚಿಗೆ',
'ಕೈಕುದುರು',
'ಕೈಕುಪ್ಪಸ',
'ಕೈಕುರುವೆ',
'ಕೈಕುಲುಕು',
'ಕೈಕೂಡಿಸು',
'ಕೈಕೆಲಸ',
'ಕೈಕೆಳಗು',
'ಕೈಕೊಡಹು',
'ಕೈಕೊಸರು',
'ಕೈಕೊಳಿಸು',
'ಕೈಕೊಳುಹ',
'ಕೈಗಂದುಗ',
'ಕೈಗಟ್ಟಿಗೆ',
'ಕೈಗಡಿಕ',
'ಕೈಗಡಿಕಿ',
'ಕೈಗಡಿಗೆ',
'ಕೈಗಡಿಯ',
'ಕೈಗದಕ',
'ಕೈಗನ್ನಡಿ',
'ಕೈಗಲಸು',
'ಕೈಗವಸ',
'ಕೈಗವಸು',
'ಕೈಗಳಸ',
'ಕೈಗಳಸು',
'ಕೈಗಳಿವು',
'ಕೈಗೞಿವು',
'ಕೈಗಾಢಿಕೆ',
'ಕೈಗಾಣಿಕೆ',
'ಕೈಗಾರಿಕೆ',
'ಕೈಗಾವಲು',
'ಕೈಗುಂಡಿಗೆ',
'ಕೈಗುಂದಿಸು',
'ಕೈಗುಟಕ',
'ಕೈಗುಟಿಕ',
'ಕೈಗುಡಿತೆ',
'ಕೈಗುದಿಗೆ',
'ಕೈಗುದ್ದಲಿ',
'ಕೈಗುರುತು',
'ಕೈಗೂಡಿಕೆ',
'ಕೈಗೂಡಿಸು',
'ಕೈಗೆಯಿಸು',
'ಕೈಗೆಲಸ',
'ಕೈಗೈವೞಿ',
'ಕೈಗೊಂಬಾಳ',
'ಕೈಗೊಡತಿ',
'ಕೈಗೊಡಲಿ',
'ಕೈಗೊಡಹು',
'ಕೈಗೊಳಿಸು',
'ಕೈಘಟ್ಟಿಗ',
'ಕೈಚಪ್ಪರ',
'ಕೈಚಪ್ಪಳೆ',
'ಕೈಚಪ್ಪಾಳೆ',
'ಕೈಚಮತ',
'ಕೈಚಮತು',
'ಕೈಚಳಕ',
'ಕೈಚಳಕು',
'ಕೈಚಿಟಿಕೆ',
'ಕೈಚಿಟುಂಕೆ',
'ಕೈಚಿಟುಕು',
'ಕೈಜಾಡಿಸು',
'ಕೈಜೋಡಿಸು',
'ಕೈಝಾಡಿಸು',
'ಕೈತಡವು',
'ಕೈತಪಾಳೆ',
'ಕೈತಪ್ಪಳೆ',
'ಕೈತವಕ',
'ಕೈತಳುಕು',
'ಕೈತೊಡಪು',
'ಕೈತೋರಿಸು',
'ಕೈದಪ್ಪಣಿ',
'ಕೈದಾಗಿಸು',
'ಕೈದಾಟಿಸು',
'ಕೈದಾರಕ',
'ಕೈದಾರಿಕ',
'ಕೈದೀವಿಗೆ',
'ಕೈದುಕಾರ',
'ಕೈದುಕಾಱ',
'ಕೈದುಕೊಳ್',
'ಕೈದುಖಾನೆ',
'ಕೈದುಗಾಱ',
'ಕೈದುಗುತ್ತು',
'ಕೈದುಗೆಯ್',
'ಕೈದುಗೆಯ್ಮೆ',
'ಕೈದುಗೊಳ್',
'ಕೈದುಡುಂಕು',
'ಕೈದುಡುಕು',
'ಕೈದುವಲಿ',
'ಕೈದುವಿಕ್ಕು',
'ಕೈದುವಿಡಿ',
'ಕೈದುವೊಱು',
'ಕೈದೆರವು',
'ಕೈದೆರಹು',
'ಕೈದೊಡಿಗೆ',
'ಕೈದೊಡಿಸು',
'ಕೈದೊಳಸು',
'ಕೈದೋಱಿಕೆ',
'ಕೈದೋಱಿಸು',
'ಕೈನಂಬುಗೆ',
'ಕೈನಡುಗು',
'ಕೈನಿಲುಕು',
'ಕೈನೀರೆಱೆ',
'ಕೈನೆಗಪು',
'ಕೈನೆಗಹು',
'ಕೈನೆರವು',
'ಕೈನೆಲಹು',
'ಕೈಪಟ್ಟೆಯ',
'ಕೈಪಲಗೆ',
'ಕೈಪಳಗು',
'ಕೈಪುರುಳ್',
'ಕೈಪುಸ್ತಕ',
'ಕೈಪೆಟ್ಟಿಗೆ',
'ಕೈಪೆದೊಂಡೆ',
'ಕೈಪೆಮಾಡು',
'ಕೈಪೆವಾತು',
'ಕೈಪೆವೀರೆ',
'ಕೈಪೆಸರ',
'ಕೈಪೆಸೊರೆ',
'ಕೈಪೆಸೋರೆ',
'ಕೈಫಿಯತ್ತು',
'ಕೈಫೀಯತ್ತು',
'ಕೈಬಟ್ಟಲು',
'ಕೈಬದಲು',
'ಕೈಬರಹ',
'ಕೈಬರೆಹ',
'ಕೈಬರ್ದುಕು',
'ಕೈಬಳಕೆ',
'ಕೈಬಳಿಚು',
'ಕೈಬುದ್ದಲಿ',
'ಕೈಭಂಟಿಕೆ',
'ಕೈಮಗುಚು',
'ಕೈಮಡಗು',
'ಕೈಮಸಕ',
'ಕೈಮಸಕು',
'ಕೈಮಸುಕು',
'ಕೈಮಾಟಿಗ',
'ಕೈಮಾಟೆಗ',
'ಕೈಮಾರಾಟ',
'ಕೈಮೀಱುಹ',
'ಕೈಮುಗಿಹ',
'ಕೈಮುರಿಗೆ',
'ಕೈಮುರಿತ',
'ಕೈಮೆಗಾಱ',
'ಕೈಮೇಲಾಗು',
'ಕೈಮೈತನ',
'ಕೈಯಕ್ಷರ',
'ಕೈಯಡಕ',
'ಕೈಯಡಿಗೆ',
'ಕೈಯಳವಿ',
'ಕೈಯಳವು',
'ಕೈಯಾಡಿಸು',
'ಕೈಯಾರೈಕೆ',
'ಕೈಯಿಕ್ಕಿಸು',
'ಕೈಯುಗುರ್',
'ಕೈಯೊಡನೆ',
'ಕೈಯೊಡಮೆ',
'ಕೈಯೊಡವೆ',
'ಕೈಯೋಗರ',
'ಕೈರಂತಱಿ',
'ಕೈರಂದಱಿ',
'ಕೈರಂಪಣಿ',
'ಕೈರವಿಣಿ',
'ಕೈಲಾಘವ',
'ಕೈಲಾಸೇಂದ್ರ',
'ಕೈವಟ್ಟಲ್',
'ಕೈವಟ್ಟಲು',
'ಕೈವಟ್ಟಿಲ್',
'ಕೈವತ್ತಿಸು',
'ಕೈವತ್ತೆಗೆ',
'ಕೈವರ್ತಿಕೆ',
'ಕೈವರ್ತಿಸು',
'ಕೈವಳಸು',
'ಕೈವಳಿಸು',
'ಕೈವಾರಿಸು',
'ಕೈವಿಡಿತೆ',
'ಕೈವಿಡಿಸು',
'ಕೈವೀಳೆಯ',
'ಕೈವುರುಳ್',
'ಕೈವೆರಲ್',
'ಕೈವೆರಸು',
'ಕೈವೆರಳ್',
'ಕೈಸಟ್ಟುಗ',
'ಕೈಸದರ',
'ಕೈಸದ್ದಕ',
'ಕೈಸನ್ನಿಸು',
'ಕೈಸರಿಸ',
'ಕೈಸಾರಿಸು',
'ಕೈಸುರಗಿ',
'ಕೈಸುರಿಗೆ',
'ಕೈಸೆರೆಗೈ',
'ಕೈಸೆಳವೆ',
'ಕೈಸೇರಿಸು',
'ಕೈಸೇವಗೆ',
'ಕೈಸೊಡರ್',
'ಕೈಸೊಡರು',
'ಕೈಸೋದಿಸು',
'ಕೈಹತಾರ',
'ಕೈಹತ್ತುಗೆ',
'ಕೈಹಿಡಿತ',
'ಕೈಹಿಡಿತೆ',
'ಕೈಹಿಡಿಸು',
'ಕೈಹಿಸೋರೆ',
'ಕೈಹೊತ್ತಗೆ',
'ಕೈಹೊಸಕು',
'ಕೈಱಾಟಣ',
'ಕೈಱಾಟಳ',
'ಕೊಂಕಣಸ್ಥ',
'ಕೊಂಕಣಸ್ಥೆ',
'ಕೊಂಕಣಿಕೆ',
'ಕೊಂಕಣಿಗ',
'ಕೊಂಕುಂಡಾಣೆ',
'ಕೊಂಕುಂಬನೆ',
'ಕೊಂಕುಕೋಡು',
'ಕೊಂಕುಗೆಯ್',
'ಕೊಂಕುಗೆರೆ',
'ಕೊಂಕುಗೊಂಬು',
'ಕೊಂಕುಡಾಣೆ',
'ಕೊಂಕುನಗೆ',
'ಕೊಂಕುನುಡಿ',
'ಕೊಂಕುನೋಟ',
'ಕೊಂಕುಪ್ರಶ್ನೆ',
'ಕೊಂಕುಮಾತು',
'ಕೊಂಕುವಂಕು',
'ಕೊಂಕುವರಿ',
'ಕೊಂಕುವಿಲ್',
'ಕೊಂಕುವಿಲ್ಲ',
'ಕೊಂಕುವುರ್ವು',
'ಕೊಂಕುವೆರೆ',
'ಕೊಂಕುವೆಱು',
'ಕೊಂಕೊಸರು',
'ಕೊಂಕೊಳಲೆ',
'ಕೊಂಗನಾಡು',
'ಕೊಂಗಮಂಗ',
'ಕೊಂಗಲ್ನಾಡು',
'ಕೊಂಗಳ್ನಾಡು',
'ಕೊಂಗಿತನ',
'ಕೊಂಗುಧರೆ',
'ಕೊಂಗುಮನ',
'ಕೊಂಚಗಾತಿ',
'ಕೊಂಚಗಾಱ',
'ಕೊಂಚೆಗಟ್ಟು',
'ಕೊಂಚೆವಕ್ಕಿ',
'ಕೊಂಚೆವೆಟ್ಟು',
'ಕೊಂಟಿಸುದ್ದಿ',
'ಕೊಂಡಕೋತಿ',
'ಕೊಂಡಗುರಿ',
'ಕೊಂಡಗುಱಿ',
'ಕೊಂಡಗೊಱಿ',
'ಕೊಂಡಬಂಡಿ',
'ಕೊಂಡಮಲ್ಲೆ',
'ಕೊಂಡಮಾಮ',
'ಕೊಂಡಮಾವು',
'ಕೊಂಡಮುಸು',
'ಕೊಂಡವಾಡ',
'ಕೊಂಡವಾಡೆ',
'ಕೊಂಡಹಬ್ಬ',
'ಕೊಂಡಾಡಿಸು',
'ಕೊಂಡಿಗಾರ',
'ಕೊಂಡಿತನ',
'ಕೊಂಡಿಮೀನು',
'ಕೊಂಡುಗುಱಿ',
'ಕೊಂಡೆಗಾರ',
'ಕೊಂಡೆಗಿತ್ತಿ',
'ಕೊಂಡೆತನ',
'ಕೊಂಡೆಮಲ್ಲಿ',
'ಕೊಂಡೆಮಲ್ಲೆ',
'ಕೊಂಡೆಮಾತು',
'ಕೊಂತಂಗೊಳ್',
'ಕೊಂತಕಾಱ',
'ಕೊಂತಗಾಱ',
'ಕೊಂತಗೆಯ್ಯ',
'ಕೊಂತಗೈಯ',
'ಕೊಂಬಚ್ಚೇಳು',
'ಕೊಂಬಣಸು',
'ಕೊಂಬುಕಾಳೆ',
'ಕೊಂಬುಗತ್ತಿ',
'ಕೊಂಬುಗಲ್ಲು',
'ಕೊಂಬುಗೊಡು',
'ಕೊಂಬುಗೊಳ್',
'ಕೊಂಬುಚೇರು',
'ಕೊಂಬುವಲೆ',
'ಕೊಂಬುವೆರೆ',
'ಕೊಂಬೆಗಲ್ಲು',
'ಕೊಂಬೆಯೊಡೆ',
'ಕೊಂಯಿಕಿಡು',
'ಕೊಕ್ಕರತೆ',
'ಕೊಕ್ಕರಿಕೆ',
'ಕೊಕ್ಕರಿಕ್ಕೆ',
'ಕೊಕ್ಕರಿಸು',
'ಕೊಕ್ಕಳಿಕೆ',
'ಕೊಕ್ಕುದೆಱೆ',
'ಕೊಕ್ಕೆಕೋಲು',
'ಕೊಕ್ಕೆಚಿಕ್ಕ',
'ಕೊಕ್ಕೆತಾತಿ',
'ಕೊಕ್ಕೆಮಾತು',
'ಕೊಕ್ಕೆಮುೂಗು',
'ಕೊಕ್ಕೆಮೊಳೆ',
'ಕೊಕ್ಕೆಹುಳು',
'ಕೊಗ್ಗದನಿ',
'ಕೊಗ್ಗೆಪುಕ್ಕ',
'ಕೊಗ್ಗೆಸರು',
'ಕೊಗ್ಗೆಸಱ್',
'ಕೊಗ್ಗೆಸಱು',
'ಕೊಚವಕ್ಕಿ',
'ಕೊಚ್ಚರಟೆ',
'ಕೊಚ್ಚುಗೊಲ್ಟಿ',
'ಕೊಚ್ಚುದಱಿ',
'ಕೊಚ್ಚುಬಲೆ',
'ಕೊಟಕಿಡು',
'ಕೊಟಗಾಱ',
'ಕೊಟಾಯಿಸು',
'ಕೊಟಾರಿಕೆ',
'ಕೊಟೆಗಾರ',
'ಕೊಟ್ಟಗಾಱ',
'ಕೊಟ್ಟತನ',
'ಕೊಟ್ಟಪಾಲಿ',
'ಕೊಟ್ಟವಳ',
'ಕೊಟ್ಟಾಂಗಚ್ಚಿ',
'ಕೊಟ್ಟಾಂಪುಳಿ',
'ಕೊಟ್ಟಾರಿಗ',
'ಕೊಟ್ಟಿತನ',
'ಕೊಟ್ಟೆಕಟ್ಟು',
'ಕೊಟ್ಟೆಕಡ್ಡಿ',
'ಕೊಟ್ಟೆಗೋಳಿ',
'ಕೊಟ್ಟೆದೊನ್ನೆ',
'ಕೊಟ್ಟೆಮಣೆ',
'ಕೊಟ್ಟೆಮುಳ್ಳು',
'ಕೊಠಾರಿಕೆ',
'ಕೊಡಂಗೆಯ್',
'ಕೊಡಂಗೆಯಿ',
'ಕೊಡಕೊಳು',
'ಕೊಡಕೋಲು',
'ಕೊಡಗತ್ತಿ',
'ಕೊಡಗಿತಿ',
'ಕೊಡಗಿವಿ',
'ಕೊಡಗೂಸು',
'ಕೊಡಗೆಯ್',
'ಕೊಡಚಗ',
'ಕೊಡನಾರಿ',
'ಕೊಡಪಾನ',
'ಕೊಡಪಾನೆ',
'ಕೊಡಪಿಸು',
'ಕೊಡಮಳೆ',
'ಕೊಡಮಾವು',
'ಕೊಡಮುರ್ಕ',
'ಕೊಡಮೂಕ',
'ಕೊಡಲಿಯ',
'ಕೊಡವತಿ',
'ಕೊಡವಿಸು',
'ಕೊಡಸಗ',
'ಕೊಡಸಾರಿ',
'ಕೊಡಸಾಲೆ',
'ಕೊಡಸಿಂಗಿ',
'ಕೊಡಸಿಕೆ',
'ಕೊಡಸಿಗ',
'ಕೊಡಸಿಗೆ',
'ಕೊಡಸೀಗೆ',
'ಕೊಡಸೋಗೆ',
'ಕೊಡಿಕಲ್ಲು',
'ಕೊಡಿತನ',
'ಕೊಡಿಮರ',
'ಕೊಡಿಯಾಲ',
'ಕೊಡಿಯೆಲೆ',
'ಕೊಡುಕೊಳೆ',
'ಕೊಡುಕೊಳ್ಳಿ',
'ಕೊಡುಕೊಳ್ಳು',
'ಕೊಡುಗಯ್',
'ಕೊಡುಗೂಸು',
'ಕೊಡುವಳಿ',
'ಕೊಡುವಾಯಿ',
'ಕೊಡೆಕಲ್ಲು',
'ಕೊಡೆಗಟ್ಟಿ',
'ಕೊಡೆಜಾಲಿ',
'ಕೊಡೆನೆಗೆ',
'ಕೊಡೆಮರ',
'ಕೊಡೆಯಾಲ',
'ಕೊಡೆಯೆಲೆ',
'ಕೊಡೆವರ',
'ಕೊಡೆಹುಲ್ಲು',
'ಕೊಣಕಿಡು',
'ಕೊಣಬಿಗ',
'ಕೊಣಬಿಗೆ',
'ಕೊಣಮಿಗೆ',
'ಕೊಣವಿಗೆ',
'ಕೊತಂಬರಿ',
'ಕೊತಕೊತ',
'ಕೊತಗುಡು',
'ಕೊತಮರಿ',
'ಕೊತವಾಲ',
'ಕೊತುಂಬರಿ',
'ಕೊತ್ತಂಬರಿ',
'ಕೊತ್ತಮಲ್ಲಿ',
'ಕೊತ್ತವಾಲ',
'ಕೊತ್ತಳಿಕೆ',
'ಕೊತ್ತಳಿಗೆ',
'ಕೊತ್ತಿಗಡ್ಡೆ',
'ಕೊತ್ತಿಗೆಂಡೆ',
'ಕೊತ್ತಿಗೆಡ್ಡೆ',
'ಕೊತ್ತಿಬೀಜ',
'ಕೊತ್ತುಂಬರಿ',
'ಕೊತ್ತುಂಬುರಿ',
'ಕೊತ್ತುಮರಿ',
'ಕೊತ್ತುಮುರಿ',
'ಕೊನಕಾಱ',
'ಕೊನಕೋಲು',
'ಕೊನಮರ',
'ಕೊನರಿಡು',
'ಕೊನರಿಸು',
'ಕೊನರೇಱು',
'ಕೊನರೊಡೆ',
'ಕೊನರ್ಮೀಸೆ',
'ಕೊನರ್ವೆರ್ಚು',
'ಕೊನಾಳಿಗೆ',
'ಕೊನೆಕಾಱ',
'ಕೊನೆಗಣ್',
'ಕೊನೆಗಣ್ಚು',
'ಕೊನೆಗತ್ತಿ',
'ಕೊನೆಗರ್ಚು',
'ಕೊನೆಗಾಣ್',
'ಕೊನೆಗಾಣು',
'ಕೊನೆಗಾಲ',
'ಕೊನೆಗೊಳ್ಳು',
'ಕೊನೆಗೋಲು',
'ಕೊನೆದೋಱು',
'ಕೊನೆನೋಟ',
'ಕೊನೆಬಾಳು',
'ಕೊನೆಬಿಕ್ಕು',
'ಕೊನೆಮಟ್ಟಿ',
'ಕೊನೆಮೀಸೆ',
'ಕೊನೆಮುಟ್ಟ',
'ಕೊನೆಮುಟ್ಟು',
'ಕೊನೆಮುಳ್',
'ಕೊನೆಯಚ್ಚು',
'ಕೊನೆವರಿ',
'ಕೊನೆವಲ್',
'ಕೊನೆವಲ್ಲ',
'ಕೊನೆವಾಡು',
'ಕೊನೆವಾಲ',
'ಕೊನೆವಾಸೆ',
'ಕೊನೆವಾಳೆ',
'ಕೊನೆವೆರ್ಚು',
'ಕೊನೆಸಾಗು',
'ಕೊನೆಸಾರ್',
'ಕೊನೆಸೊಕ್ಕು',
'ಕೊನೆಹಲ್ಲು',
'ಕೊನೆಹಾಳೆ',
'ಕೊಪ್ಪರಿಕೆ',
'ಕೊಪ್ಪರಿಗೆ',
'ಕೊಪ್ಪರಿಸು',
'ಕೊಬ್ಬುದೆಕ್ಕೆ',
'ಕೊಮಟಿಗ',
'ಕೊಮರಕ',
'ಕೊಮರಿಕೆ',
'ಕೊಮಾರತಿ',
'ಕೊಮಾರತ್ತಿ',
'ಕೊಯಲಾಳಿ',
'ಕೊಯಿಕೀರೆ',
'ಕೊಯೊಗೀರೆ',
'ಕೊಯಿಗುಱಿ',
'ಕೊಯಿಲಾಳಿ',
'ಕೊಯಿಲಾಳು',
'ಕೊಯ್ಕತನ',
'ಕೊಯ್ತೆವೋಗು',
'ಕೊಯ್ಯಮುಂಜಿ',
'ಕೊಯ್ಯುಕೊರೆ',
'ಕೊಯ್ಯುರುಳೆ',
'ಕೊಯ್ಲುಗಾರ',
'ಕೊರಂಗಲ',
'ಕೊರಕಂಚಿ',
'ಕೊರಕಂಚೆ',
'ಕೊರಕಲ್',
'ಕೊರಕಲ',
'ಕೊರಕಲು',
'ಕೊರಕಲ್ಲು',
'ಕೊರಕೊಂಚಿ',
'ಕೊರಕೊಳ್ಳಿ',
'ಕೊರಗಣೆ',
'ಕೊರಗತಿ',
'ಕೊರಗಿಲು',
'ಕೊರಗಿಸು',
'ಕೊರಗುಟ್ಟು',
'ಕೊರಚತಿ',
'ಕೊರಚಲ',
'ಕೊರಚಾಟ',
'ಕೊರಚಾಡು',
'ಕೊರಟಿಗೆ',
'ಕೊರನಾರಿ',
'ಕೊರಪಣೆ',
'ಕೊರಪುಳ್ಳಿ',
'ಕೊರಮತಿ',
'ಕೊರಮತ್ತಿ',
'ಕೊರಮಾವು',
'ಕೊರಮುಟ್ಟು',
'ಕೊರಲುದ್ದ',
'ಕೊರಲುದ್ದಂ',
'ಕೊರಲುಲಿ',
'ಕೊರಲೌಂಕು',
'ಕೊರಲ್ಗೊಲೆ',
'ಕೊರಲ್ಗೊಱೆ',
'ಕೊರಲ್ದಂಡೆ',
'ಕೊರಲ್ವಲೆ',
'ಕೊರಲ್ವಾಗು',
'ಕೊರವಂಜಿ',
'ಕೊರವಿತ್ತಿ',
'ಕೊರಸಾಟ',
'ಕೊರಸಿಗ',
'ಕೊರಳಾರ',
'ಕೊರಳಿಸು',
'ಕೊರಳುದ್ದ',
'ಕೊರಳುಲಿ',
'ಕೊರಳೇರು',
'ಕೊರಳ್ಕಾಪು',
'ಕೊರಿಗೊರಿ',
'ಕೊರಿಪಿಡಿ',
'ಕೊರಿಪೋಗು',
'ಕೊರಿಯಿಸು',
'ಕೊರೆಕಂಬಿ',
'ಕೊರೆಗೆಯ್',
'ಕೊರೆದಿನ',
'ಕೊರೆನೋಟ',
'ಕೊರೆಪ್ರಾಣ',
'ಕೊರೆಬೀಳು',
'ಕೊರೆಯಚ್ಚು',
'ಕೊರೆಯಿಸು',
'ಕೊರೆಯುಳಿ',
'ಕೊರೆವಾಯಿ',
'ಕೊರೆಸೂಜಿ',
'ಕೊರ್ಬುಗಳೆ',
'ಕೊರ್ಬುಗುಡು',
'ಕೊರ್ಬುವಡೆ',
'ಕೊರ್ವುವಡೆ',
'ಕೊಲೆಕೋಣೆ',
'ಕೊಲೆಗಾರ',
'ಕೊಲೆಗಾರ್ತಿ',
'ಕೊಲೆಗಾಱ',
'ಕೊಲೆಗುತ್ತ',
'ಕೊಲೆಗೇಡಿ',
'ಕೊಲೆಗೊಳ್',
'ಕೊಲೆಗೊಳ್ಳು',
'ಕೊಲೆಪಡು',
'ಕೊಲೆಪಾತ',
'ಕೊಲೆಬಲೆ',
'ಕೊಲೆಬೀೞ್',
'ಕೊಲೆಮನೆ',
'ಕೊಲೆಸಾಯ್',
'ಕೊಲ್ಲಗೊಲೆ',
'ಕೊಲ್ಲಟಿಗ',
'ಕೊಲ್ಲಣಿಗೆ',
'ಕೊಲ್ಲಣಿಸು',
'ಕೊಲ್ಲಿತನ',
'ಕೊಲ್ಲಿನೋಟ',
'ಕೊಲ್ಲಿಯಾವು',
'ಕೊಲ್ಲಿವಲೆ',
'ಕೊಲ್ಲುಗೊಲೆ',
'ಕೊಲ್ಲೆನೋಟ',
'ಕೊಸಕಿಲು',
'ಕೊಸಗಿಜೆ',
'ಕೊಸಗಿಲೆ',
'ಕೊಸರಾಟ',
'ಕೊಸರಾಡು',
'ಕೊಸರಿಕೆ',
'ಕೊಸರಿಡು',
'ಕೊಸಱಿಕೆ',
'ಕೊಸಾಕುಳಿ',
'ಕೊಸಾರನೆ',
'ಕೊಸಿಅಕ್ಕಿ',
'ಕೊಸೆಗೊಳ್',
'ಕೊಳಂಗಟ್ಟು',
'ಕೊಳಂಗೊಳ್',
'ಕೊಳಂಜನ',
'ಕೊಳಗಾಹಿ',
'ಕೊಳಗುಳ',
'ಕೊಳಗೊಳ್',
'ಕೊಳಮಾವು',
'ಕೊಳರ್ವಕ್ಕಿ',
'ಕೊಳವಂಕೆ',
'ಕೊಳವಕ್ಕಿ',
'ಕೊಳವನ',
'ಕೊಳವಾಳ',
'ಕೊಳವಿಗೆ',
'ಕೊಳವೆಹೂ',
'ಕೊಳವೇರ್',
'ಕೊಳವೇರು',
'ಕೊಳುಕಂಠ',
'ಕೊಳುಕೊಡು',
'ಕೊಳುಕೊಡೆ',
'ಕೊಳುಗಿಡಿ',
'ಕೊಳುಗುಳ',
'ಕೊಳುಗೊಳ',
'ಕೊಳುಮಿಗ',
'ಕೊಳುವಡೆ',
'ಕೊಳೆಗಡ್ಡ',
'ಕೊಳೆಗಣ್ಣು',
'ಕೊಳೆಗೂಡು',
'ಕೊಳೆಗೇರಿ',
'ಕೊಳೆಗೇರು',
'ಕೊಳೆತಿನಿ',
'ಕೊಳೆನಾತ',
'ಕೊಳೆನಾರ್',
'ಕೊಳೆನೀರ್',
'ಕೊಳೆಬೀಳು',
'ಕೊಳೆಮಾತು',
'ಕೊಳೆಮಾವು',
'ಕೊಳೆಯಿಸು',
'ಕೊಳೆರೋಗ',
'ಕೊಳೆಹಾಕು',
'ಕೊಳೆಹೋಗು',
'ಕೊಳ್ಕೊಳಿಸು',
'ಕೊಳ್ಗೆಸಱ್',
'ಕೊಳ್ಮಗಳ್',
'ಕೊಳ್ಮಿದುಳ್',
'ಕೊಳ್ಮಿದುಳು',
'ಕೊಳ್ವುಕೊಡೆ',
'ಕೊಳ್ಳಿಕಳ್ಳ',
'ಕೊಳ್ಳಿಕೊಡು',
'ಕೊಳ್ಳಿಗೊಳು',
'ಕೊಳ್ಳಿದೆವ್ವ',
'ಕೊಳ್ಳಿಪುಳಿ',
'ಕೊಳ್ಳಿಬೀಜ',
'ಕೊಳ್ಳಿಯಿಕ್ಕು',
'ಕೊಳ್ಳಿವಣ್ಣ',
'ಕೊಳ್ಳಿವಾಲ',
'ಕೊಳ್ಳಿವೀಸು',
'ಕೊಳ್ಳಿಹೇದೆ',
'ಕೊಳ್ಳುಕೊಡೆ',
'ಕೊಳ್ಳೆಗಾರ',
'ಕೊಳ್ಳೆಗೊಳ್',
'ಕೊಳ್ಳೆಬಾಕ',
'ಕೊಳ್ಳೆಹೊಡೆ',
'ಕೊಳ್ಳೆಹೋಗು',
'ಕೊಱಚಾಟ',
'ಕೊಱಚಾಡು',
'ಕೊಱಡೇಱು',
'ಕೊಱವಂಜಿ',
'ಕೊಱವಜ್ಜಿ',
'ಕೊಱವತಿ',
'ಕೊಱವಿತಿ',
'ಕೊಱಿಗೊಳಿ',
'ಕೊಱೆಕೂಲಿ',
'ಕೊಱೆಕೂಳು',
'ಕೊಱೆಗೊಳ್ಳಿ',
'ಕೊಱೆದಱಿ',
'ಕೊಱೆಪಲ್',
'ಕೊಱೆಯಕ್ಕಿ',
'ಕೊಱೆವಲ್',
'ಕೊಱೆವೋಗು',
'ಕೊಱೆಯಿಸು',
'ಕೊೞೆಗಂಪು',
'ಕೊೞೆಗಣ್',
'ಕೊೞೆಗಬ್ಬ',
'ಕೊೞೆಗೊಳ್',
'ಕೊೞೆದುಪ್ಪ',
'ಕೊೞೆದೆಯ್ಯ',
'ಕೊೞೆನಾಱು',
'ಕೊೞೆನೀರ್',
'ಕೊೞೆವಾವು',
'ಕೊೞ್ಗೆಸಱ್',
'ಕೊೞ್ಮಿದುಳ್',
'ಕೋಂಟೆಕಾಱ',
'ಕೋಂಟೆವಱೆ',
'ಕೋಕನದ',
'ಕೋಕಶಾಸ್ತ್ರ',
'ಕೋಕಿಲಾಕ್ಷ',
'ಕೋಕಿಲಾಕ್ಷಿ',
'ಕೋಚಿಮಾಡು',
'ಕೋಚುಬಂಡೆ',
'ಕೋಚುಭಟ್ಟ',
'ಕೋಚುಮಾಡು',
'ಕೋಟಗಾಱ',
'ಕೋಟಲಿಗ',
'ಕೋಟಸಾಲೆ',
'ಕೋಟಾಯಂತ್ರ',
'ಕೋಟಾವಳಿ',
'ಕೋಟಿಖೋಲಿ',
'ಕೋಟಿನಾಳು',
'ಕೋಟಿಪತ್ರ',
'ಕೋಟಿಪಾತ್ರ',
'ಕೋಟಿವರ್ಷೆ',
'ಕೋಟುಗಸ್ತಿ',
'ಕೋಟುಗಸ್ತು',
'ಕೋಟೆಗಾಱ',
'ಕೋಟೆತೆನೆ',
'ಕೋಟೆದೆನೆ',
'ಕೋಟೆಮನೆ',
'ಕೋಟೆಮಾನ್ಯ',
'ಕೋಟ್ಟವಳ',
'ಕೋಟ್ಯಂತರ',
'ಕೋಟ್ಯಧೀಶ',
'ಕೋಠೀಖೋಲಿ',
'ಕೋಡಂಬಳ್ಳಿ',
'ಕೋಡಕಯ್',
'ಕೋಡಕೆಯ್',
'ಕೋಡಣಸು',
'ಕೋಡಬಳೆ',
'ಕೋಡಬಿಕ್ಕಿ',
'ಕೋಡಮುರು',
'ಕೋಡವೀಸ',
'ಕೋಡಸಿಂಗೆ',
'ಕೋಡಾಡಿಸು',
'ಕೋಡಿಕಳೆ',
'ಕೋಡಿಕಾಣು',
'ಕೋಡಿಗಟ್ಟು',
'ಕೋಡಿಗಳೆ',
'ಕೋಡಿಗಾಣು',
'ಕೋಡಿಗೊಳ್',
'ಕೋಡಿನೀರ್',
'ಕೋಡಿಬೀಳು',
'ಕೋಡಿಮನ',
'ಕೋಡಿವರಿ',
'ಕೋಡುಂಗಲ್',
'ಕೋಡುಂಗಲ್ಲು',
'ಕೋಡುಂತಳೆ',
'ಕೋಡುಕಂಚು',
'ಕೋಡುಕಳ್ಳಿ',
'ಕೋಡುಕಾಯಿ',
'ಕೋಡುಕುಟ್ಟು',
'ಕೋಡುಗಲ್',
'ಕೋಡುಗಲ್ಲು',
'ಕೋಡುಗಳ್ಳಿ',
'ಕೋಡುಗುಟ್ಟು',
'ಕೋಡುಗೊಳ್',
'ಕೋಡುಬಳೆ',
'ಕೋಡುವೀಸ',
'ಕೋಡುವೆಟ್ಟು',
'ಕೋಡೆಗದ್ದೆ',
'ಕೋಡೆಮಳೆ',
'ಕೋಡೆಱಂಕೆ',
'ಕೋಡೆಱಗು',
'ಕೋಣತ್ರಯ',
'ಕೋತಂಬರಿ',
'ಕೋತಿಬುದ್ಧಿ',
'ಕೋದ್ರಹರಿ',
'ಕೋನಮಟ್ಟ',
'ಕೋನಮಾನ',
'ಕೋನಶಿಲೆ',
'ಕೋನಾಂತರ',
'ಕೋನೀಯತೆ',
'ಕೋನುಗೋಳು',
'ಕೋಪಗರ್ಭ',
'ಕೋಪಗಾತಿ',
'ಕೋಪಗಾರ',
'ಕೋಪಗೃಹ',
'ಕೋಪದಾಳು',
'ಕೋಪನಾಳಿ',
'ಕೋಪರಸ',
'ಕೋಪವಂತ',
'ಕೋಪಸ್ತಂಭ',
'ಕೋಪಾಗಾರ',
'ಕೋಪಾಟೋಪ',
'ಕೋಪಾರಕ್ತ',
'ಕೋಪಾರುಣ',
'ಕೋಪಾಲೋಕಿ',
'ಕೋಪುಗೊಳ್',
'ಕೋಪೋದ್ರಿಕ್ತ',
'ಕೋಪೋದ್ರಿಕ್ತೆ',
'ಕೋಪೋದ್ರೇಕ',
'ಕೋಮಟಿಗ',
'ಕೋಮಲತೆ',
'ಕೋಮಲತ್ವ',
'ಕೋಮಲಾಂಗ',
'ಕೋಮಲಾಂಗಿ',
'ಕೋಮಲಿಕೆ',
'ಕೋಮಲಿಸು',
'ಕೋಮಳತೆ',
'ಕೋಮಳಾಂಗಿ',
'ಕೋಮಳಿಕೆ',
'ಕೋಮುವಾದ',
'ಕೋಮುವಾದಿ',
'ಕೋಮುವಾರು',
'ಕೋಯಟ್ಟಿಗ',
'ಕೋಯಷ್ಟಿಕ',
'ಕೋರಕಿತ',
'ಕೋರಕಿಸು',
'ಕೋರಡಿಗ',
'ಕೋರಡಿಗೆ',
'ಕೋರದೂಷ',
'ಕೋರಯಿಸು',
'ಕೋರವಿಸು',
'ಕೋರಾಸಾನಿ',
'ಕೋರಿತನ',
'ಕೋರಿಭಿಕ್ಷ',
'ಕೋರಿಮರ',
'ಕೋರಿಯಂಗಿ',
'ಕೋರಿವೆಣ್ಣು',
'ಕೋರಿಶೆಟ್ಟಿ',
'ಕೋರಿಸೆಟ್ಟಿ',
'ಕೋರಿಹಣ್ಣು',
'ಕೋರಿಹುಳ',
'ಕೋರಿಹೊಲ',
'ಕೋರುಗಾರ',
'ಕೋರುಪಾಲು',
'ಕೋರೆಗೊಂಬು',
'ಕೋರೆದಾಡೆ',
'ಕೋರೆನೋಟ',
'ಕೋರೆಪೇಟ',
'ಕೋರೆಮೀಸೆ',
'ಕೋರೆಹಲ್ಲು',
'ಕೋಲಂಬಕ',
'ಕೋಲಕಟ್ಟು',
'ಕೋಲಗಟ್ಟು',
'ಕೋಲಗಾರ',
'ಕೋಲಚಾರಿ',
'ಕೋಲದಲ',
'ಕೋಲಪೊನ್ನೆ',
'ಕೋಲಬಲಿ',
'ಕೋಲಬಳಿ',
'ಕೋಲಮಾಡು',
'ಕೋಲಮುಕ್ಕ',
'ಕೋಲಮೂಗ',
'ಕೋಲರಗು',
'ಕೋಲಲಬು',
'ಕೋಲವಲ್ಲಿ',
'ಕೋಲಳವಿ',
'ಕೋಲಾಕೋಲಿ',
'ಕೋಲಾಟಕ',
'ಕೋಲಾಹಲ',
'ಕೋಲುಕಳ್ಳಿ',
'ಕೋಲುಕಾರ',
'ಕೋಲುಕಾಱ',
'ಕೋಲುಗಲ್ಲಿ',
'ಕೋಲುಗಳ್ಳಿ',
'ಕೋಲುಗಿಡ',
'ಕೋಲುಗುರು',
'ಕೋಲುಗೊನೆ',
'ಕೋಲುಗೊಳ್',
'ಕೋಲುಜೇನು',
'ಕೋಲುತಾತ',
'ಕೋಲುತೇಗ',
'ಕೋಲುನೇಣು',
'ಕೋಲುಪದ',
'ಕೋಲುಬಲೆ',
'ಕೋಲುಮಂಚ',
'ಕೋಲುಮೀನು',
'ಕೋಲುಮುಕ್ಕ',
'ಕೋಲುಮುಕ್ಕು',
'ಕೋಲುಮುತ್ತು',
'ಕೋಲುವಲೆ',
'ಕೋಲುವಿಡಿ',
'ಕೋಲುಹಾಕು',
'ಕೋಲುಹೊಟ್ಟೆ',
'ಕೋಲುಹೊನ್ನೆ',
'ಕೋಲುಹೊಯ್ಯು',
'ಕೋಲ್ಗದಿರು',
'ಕೋಲ್ತಗಸಿ',
'ಕೋಲ್ದುಡಿಸು',
'ಕೋಲ್ದೊಡಿಸು',
'ಕೋವಟಿಗ',
'ಕೋವಿಕಾರ',
'ಕೋವಿಕಾಱ',
'ಕೋವಿಗಂಡಿ',
'ಕೋವಿಗಾರ',
'ಕೋವಿಚೀಲ',
'ಕೋವಿದಾರ',
'ಕೋವೆಹಲ್ಲು',
'ಕೋಶಗೃಹ',
'ಕೋಶನಾಥ',
'ಕೋಶಪಾನ',
'ಕೋಶಫಲ',
'ಕೋಶಮಾವು',
'ಕೋಶಾಗಾರ',
'ಕೋಶಾತಕಿ',
'ಕೋಶಾಧ್ಯಕ್ಷ',
'ಕೋಶಾವಸ್ಥೆ',
'ಕೋಷ್ಠಾಗಾರ',
'ಕೋಸಂಬರಿ',
'ಕೋಸುಂಬರಿ',
'ಕೋಸುಗಡ್ಡೆ',
'ಕೋಸುಗಣ್ಣ',
'ಕೋಸುಗಣ್ಣು',
'ಕೋಸುಗೆಡ್ಡೆ',
'ಕೋಸುವಲೆ',
'ಕೋಹಳಿಕೆ',
'ಕೋಹಿನೂರ್',
'ಕೋಳಕುಕ್ಕಿ',
'ಕೋಳದಂಡ',
'ಕೋಳವಿಡಿ',
'ಕೋಳಸುಂದ',
'ಕೋಳಾಹಳ',
'ಕೋಳಿಕಟ್ಟ',
'ಕೋಳಿಕಟ್ಟು',
'ಕೋಳಿಕರಿ',
'ಕೋಳಿಕಳ್ಳ',
'ಕೋಳಿಕಾರ',
'ಕೋಳಿಕಾಲು',
'ಕೋಳಿಕುಕ್ಕೆ',
'ಕೋಳಿಕೂಗು',
'ಕೋಳಿಗೂಡು',
'ಕೋಳಿಜುಟ್ಟು',
'ಕೋಳಿನಿದ್ದೆ',
'ಕೋಳಿಬೆಂಡೆ',
'ಕೋಳಿಮೊಟ್ಟೆ',
'ಕೋಳಿಹುಂಜ',
'ಕೋಳೀಜನ',
'ಕೋಳುಕೊಳ್',
'ಕೋಳುಗೊಡು',
'ಕೋಳುಗೊಳ್',
'ಕೋಳುಪೋಗು',
'ಕೋಳುವಿಡಿ',
'ಕೋಳುವೋಗು',
'ಕೋಳುಹುಯ್',
'ಕೋಳುಹೊಯ್',
'ಕೋಳುಹೋಗು',
'ಕೋಳೊಡವೆ',
'ಕೋಳ್ಕೆಸಱ್',
'ಕೋಳ್ಗೆಸಱ್',
'ಕೋಳ್ಮಸಗು',
'ಕೋಱೆದಾಡೆ',
'ಕೋಱೆಬಾಯ್',
'ಕೋಱೆವಲ್',
'ಕೋೞಿವಾದ',
'ಕೋೞಿವಾಲ',
'ಕೋೞ್ಕೆಸಱ್',
'ಕೋೞ್ಗೆಸಱ್',
'ಕೋೞ್ಮಿದುಳ್',
'ಕೌಂಗುಮರ',
'ಕೌಕ್ಕುಟಿಕ',
'ಕೌಕ್ಷೇಯಕ',
'ಕೌಟತಕ್ಷ',
'ಕೌಟಿಲ್ಯಜ್ಞ',
'ಕೌಟುಂಬರ',
'ಕೌಟುಂಬಿಕ',
'ಕೌಡಯಾಲ',
'ಕೌಡಿಕಾರ',
'ಕೌಣಕಡ್ಡಿ',
'ಕೌತೂಹಲ',
'ಕೌತೂಹಳ',
'ಕೌದ್ರವೀಣ',
'ಕೌಪುಗಟ್ಟು',
'ಕೌಪುದಳೆ',
'ಕೌಮೋದಕಿ',
'ಕೌಮೋದಿಕ',
'ಕೌರುಗಂಪು',
'ಕೌಲಟೇಯ',
'ಕೌಲಟೇರ',
'ಕೌಲಾಚಾರ',
'ಕೌಲೇಯಕ',
'ಕೌಲೇಯಕಿ',
'ಕೌಶಲ್ಯಾರ್ಥಿ',
'ಕೌಶೀತಕಿ',
'ಕೌಸಲೇಯ',
'ಕೌಸೃತಿಕ',
'ಕೌಳುಗೋಲ್',
'ಕೌಳೇಯಕ',
'ಕೌಱುನಾಱು',
'ಕ್ಯಾಕರಿಸು',
'ಕ್ರತುಕರ್ತೃ',
'ಕ್ರತುಧ್ವಂಸಿ',
'ಕ್ರತುಪತಿ',
'ಕ್ರತುಬುದ್ಧಿ',
'ಕ್ರತುಭುಗ',
'ಕ್ರತುಭುಜ',
'ಕ್ರತುಮಯ',
'ಕ್ರತುಮೂರ್ತಿ',
'ಕ್ರತುರಂಗ',
'ಕ್ರತುಶಕ್ತಿ',
'ಕ್ರತುಹರ',
'ಕ್ರಮಂದಪ್ಪು',
'ಕ್ರಮಗೆಡು',
'ಕ್ರಮಗೊಳ್ಳು',
'ಕ್ರಮತಪ್ಪು',
'ಕ್ರಮದಪ್ಪು',
'ಕ್ರಮಪ್ರಾಪ್ತ',
'ಕ್ರಮಪ್ರಾಪ್ತಿ',
'ಕ್ರಮಬದ್ಧ',
'ಕ್ರಮಭಂಗ',
'ಕ್ರಮಭ್ರಷ್ಟ',
'ಕ್ರಮಭ್ರಷ್ಟೆ',
'ಕ್ರಮಮುಕ್ತಿ',
'ಕ್ರಮಯಿತ',
'ಕ್ರಮಲೋಪ',
'ಕ್ರಮವಳಿ',
'ಕ್ರಮವಾಚಿ',
'ಕ್ರಮವಿಡಿ',
'ಕ್ರಮಸಂಖ್ಯೆ',
'ಕ್ರಮಾಗತ',
'ಕ್ರಮಾಧಿಕ',
'ಕ್ರಮೇಣಕ',
'ಕ್ರಮೇಲಕ',
'ಕ್ರಮೇಳಕ',
'ಕ್ರಯಂಗೊಳ್',
'ಕ್ರಯಚೀಟು',
'ಕ್ರಯದಾರ',
'ಕ್ರಯಪಟ್ಟಿ',
'ಕ್ರಯರೂಪ',
'ಕ್ರಯಶಕ್ತಿ',
'ಕ್ರಾಂತದರ್ಶಿ',
'ಕ್ರಾಂತದೃಷ್ಟಿ',
'ಕ್ರಾಂತಿಕಾತಿ',
'ಕ್ರಾಂತಿಕಾರ',
'ಕ್ರಾಂತಿಕಾರಿ',
'ಕ್ರಾಂತಿಕಾಲ',
'ಕ್ರಾಂತಿಜೀವಿ',
'ಕ್ರಾಂತಿಪಕ್ಷ',
'ಕ್ರಾಂತಿಪಥ',
'ಕ್ರಾಂತಿಯುಗ',
'ಕ್ರಾಂತಿವಾದಿ',
'ಕ್ರಾಂತಿವೃತ್ತ',
'ಕ್ರಿಮಿಕುಲ',
'ಕ್ರಿಮಿದಂತ',
'ಕ್ರಿಮಿನಾಶಿ',
'ಕ್ರಿಮಿಬಂಧ',
'ಕ್ರಿಮಿರಾಗ',
'ಕ್ರಿಮಿರೋಗ',
'ಕ್ರಿಮಿಶಾಸ್ತ್ರ',
'ಕ್ರಿಮಿಸಂಘ',
'ಕ್ರಿಯಾಋದ್ಧಿ',
'ಕ್ರಿಯಾಕರ್ಮ',
'ಕ್ರಿಯಾಕಾರ',
'ಕ್ರಿಯಾಕಾಲ',
'ಕ್ರಿಯಾಗುಪ್ತ',
'ಕ್ರಿಯಾಚಾರ',
'ಕ್ರಿಯಾಜ್ಞಾನ',
'ಕ್ರಿಯಾತ್ಮಕ',
'ಕ್ರಿಯಾದೀಕ್ಷೆ',
'ಕ್ರಿಯಾದ್ವೇಷ',
'ಕ್ರಿಯಾದ್ವೇಷಿ',
'ಕ್ರಿಯಾದ್ವೈತ',
'ಕ್ರಿಯಾಪಟು',
'ಕ್ರಿಯಾಪದ',
'ಕ್ರಿಯಾಪಾದ',
'ಕ್ರಿಯಾಫಲ',
'ಕ್ರಿಯಾಭಾಗ',
'ಕ್ರಿಯಾಭೋಗಿ',
'ಕ್ರಿಯಾಭ್ರಮೆ',
'ಕ್ರಿಯಾಭ್ರಷ್ಟ',
'ಕ್ರಿಯಾಭ್ರಷ್ಟೆ',
'ಕ್ರಿಯಾಮಂತ್ರ',
'ಕ್ರಿಯಾಮಾರ್ಗ',
'ಕ್ರಿಯಾಮಾಲೆ',
'ಕ್ರಿಯಾಯೋಗ',
'ಕ್ರಿಯಾರೂಪ',
'ಕ್ರಿಯಾರ್ಥಕ',
'ಕ್ರಿಯಾಲಿಂಗ',
'ಕ್ರಿಯಾಲೋಪ',
'ಕ್ರಿಯಾವಂತ',
'ಕ್ರಿಯಾವತಿ',
'ಕ್ರಿಯಾವತ್ವ',
'ಕ್ರಿಯಾವಾಚಿ',
'ಕ್ರಿಯಾವಾಹಿ',
'ಕ್ರಿಯಾವೃತ್ತಿ',
'ಕ್ರಿಯಾವೇತ್ತ',
'ಕ್ರಿಯಾಶಕ್ತಿ',
'ಕ್ರಿಯಾಶಬ್ದ',
'ಕ್ರಿಯಾಶೀಲ',
'ಕ್ರಿಯಾಶೀಲೆ',
'ಕ್ರಿಯಾಶುದ್ಧಿ',
'ಕ್ರಿಯಾಶೂನ್ಯ',
'ಕ್ರಿಯಾಶೂನ್ಯೆ',
'ಕ್ರಿಯಾಸಕ್ತ',
'ಕ್ರಿಯಾಸಕ್ತಿ',
'ಕ್ರಿಯಾಸಕ್ತೆ',
'ಕ್ರಿಯಾಸಿದ್ಧಿ',
'ಕ್ರಿಯಾಹೀನ',
'ಕ್ರಿಯಾಹೀನೆ',
'ಕ್ರಿಯೆಗೆಯ್',
'ಕ್ರಿಯೆಗೆಯ್ಸು',
'ಕ್ರಿಯೆವೆಱು',
'ಕ್ರಿಶ್ಚಿಯನ್',
'ಕ್ರಿಸ್ತಪೂರ್ವ',
'ಕ್ರಿಸ್ತಮತ',
'ಕ್ರಿಸ್ತಶಕ',
'ಕ್ರೀಡಮಾನ',
'ಕ್ರೀಡಾಂಗಣ',
'ಕ್ರೀಡಾಕುಳಿ',
'ಕ್ರೀಡಾಗೃಹ',
'ಕ್ರೀಡಾಚಳ',
'ಕ್ರೀಡಾಪಟು',
'ಕ್ರೀಡಾರಥ',
'ಕ್ರೀಡಾಲೋಕ',
'ಕ್ರೀಡಾವನ',
'ಕ್ರೀಡಾಶಾಲೆ',
'ಕ್ರೀಡಾಶೀಲ',
'ಕ್ರೀಡಾಶೀಲೆ',
'ಕ್ರೀಡಾಶೈಲ',
'ಕ್ರೀಡಾಸಕ್ತ',
'ಕ್ರೀಡಾಸಕ್ತಿ',
'ಕ್ರೀಡಾಸಕ್ತೆ',
'ಕ್ರೀತದಾಸ',
'ಕ್ರೀತದಾಸಿ',
'ಕ್ರೀಶುದ್ಧಾತ್ಮ',
'ಕ್ರೀಶುದ್ಧಾತ್ಮೆ',
'ಕ್ರುದ್ಧಮುದ್ರೆ',
'ಕ್ರೂರಕರ್ಮ',
'ಕ್ರೂರಕರ್ಮಿ',
'ಕ್ರೂರಗ್ರಾಹ್ಯ',
'ಕ್ರೂರಚಿತ್ತ',
'ಕ್ರೂರಚಿತ್ತೆ',
'ಕ್ರೂರತನ',
'ಕ್ರೂರಮಾವು',
'ಕ್ರೈಸ್ತಧರ್ಮ',
'ಕ್ರೈಸ್ತಮತ',
'ಕ್ರೋಡಪತ್ರ',
'ಕ್ರೋಡವಸೆ',
'ಕ್ರೋಡಶಾಲೆ',
'ಕ್ರೋಡೀಕೃತ',
'ಕ್ರೋಧವಾಣಿ',
'ಕ್ರೋಧೋನ್ಮತ್ತ',
'ಕ್ರೋಧೋನ್ಮತ್ತೆ',
'ಕ್ರೋಷ್ಟುವಿನೆ',
'ಕ್ರೋಷ್ಟುವಿನ್ನೆ',
'ಕ್ರೌಂಚಪದ',
'ಕ್ರೌಂಚವ್ಯೂಹ',
'ಕ್ರೌಂಚಾರಾತಿ',
'ಕ್ರೌಂಚಾಸನ',
'ಕ್ಲಪ್ತಕಾಲ',
'ಕ್ಲಪ್ತವೃತ್ತ',
'ಕ್ಲಿಕ್‍ದುಂಬಿ',
'ಕ್ಲಿಶ್ಯಮಾನ',
'ಕ್ಲೀಬತನ',
'ಕ್ಲೀಬವರ್ಣ',
'ಕ್ಲೀಬಸ್ವರ',
'ಕ್ಲುಪ್ತಕಾಲ',
'ಕ್ಲೇಶಂಬಡು',
'ಕ್ಲೇಶಕ್ಷತ',
'ಕ್ಲೇಶಕ್ಷತೆ',
'ಕ್ಲೇಶಗೊಳ್',
'ಕ್ಲೇಶಪಡು',
'ಕ್ಲೋರೋಫಾರಂ',
'ಕ್ಲೋರೋಫಾರ್ಮು',
'ಕ್ವಾಥರಸ',
'ಕ್ವಾಥೋದ್ಭವ',
'ಕ್ವಿನಾಯಿನ್',
'ಕ್ಷಕಿರಣ',
'ಕ್ಷಣಗಂಡ',
'ಕ್ಷಣಪ್ರಭೆ',
'ಕ್ಷಣಭಂಗಿ',
'ಕ್ಷಣಮಾತ್ರ',
'ಕ್ಷಣರುಚಿ',
'ಕ್ಷಣವಂತ',
'ಕ್ಷಣವೇಧಿ',
'ಕ್ಷಣಿಕತೆ',
'ಕ್ಷಣಿಕತ್ವ',
'ಕ್ಷತತ್ರಾಣ',
'ಕ್ಷತವ್ರತ',
'ಕ್ಷತಿಪಾಲ',
'ಕ್ಷತ್ರಚಕ್ರ',
'ಕ್ಷತ್ರತೇಜ',
'ಕ್ಷತ್ರಧರ್ಮ',
'ಕ್ಷತ್ರಧಾಮ',
'ಕ್ಷತ್ರಪತಿ',
'ಕ್ಷತ್ರಪುತ್ರ',
'ಕ್ಷತ್ರವೃತ್ತಿ',
'ಕ್ಷತ್ರವೇದ',
'ಕ್ಷತ್ರಾಧಮ',
'ಕ್ಷತ್ರಿಯತ್ವ',
'ಕ್ಷತ್ರಿಯಾಣಿ',
'ಕ್ಷತ್ರಿಯಿತಿ',
'ಕ್ಷಪಕತ್ವ',
'ಕ್ಷಪಣಕ',
'ಕ್ಷಪಾಕರ',
'ಕ್ಷಪಾನಾಥ',
'ಕ್ಷಪಿಯಿಸು',
'ಕ್ಷಮಾಕರ',
'ಕ್ಷಮಾಗುಣ',
'ಕ್ಷಮಾತಳ',
'ಕ್ಷಮಾದಾನ',
'ಕ್ಷಮಾಧರ',
'ಕ್ಷಮಾಪಣ',
'ಕ್ಷಮಾಪಣೆ',
'ಕ್ಷಮಾಪರ',
'ಕ್ಷಮಾಭಿಕ್ಷೆ',
'ಕ್ಷಮಾರುಹ',
'ಕ್ಷಮಾಶೀಲ',
'ಕ್ಷಮಾಶೀಲೆ',
'ಕ್ಷಮಿಯಿಸು',
'ಕ್ಷಮೆಗೊಳ್',
'ಕ್ಷಯಕರ',
'ಕ್ಷಯಖಾಸ',
'ಕ್ಷಯತಿಥಿ',
'ಕ್ಷಯಪಕ್ಷ',
'ಕ್ಷಯಮಾಸ',
'ಕ್ಷಯರೋಗ',
'ಕ್ಷಯವ್ಯಾಧಿ',
'ಕ್ಷಾತ್ರತೇಜ',
'ಕ್ಷಾತ್ರಧರ್ಮ',
'ಕ್ಷಾತ್ರಪೂರ್ಣ',
'ಕ್ಷಾತ್ರಭಾರ',
'ಕ್ಷಾತ್ರವೀರ',
'ಕ್ಷಾತ್ರಶಕ್ತಿ',
'ಕ್ಷಾಮಬಾಧೆ',
'ಕ್ಷಾರಕೂಪ',
'ಕ್ಷಾರದ್ರವ್ಯ',
'ಕ್ಷಾರವಾರಿ',
'ಕ್ಷಾರವಿಧಿ',
'ಕ್ಷಾರಾಂಬುಧಿ',
'ಕ್ಷಾರೋದಕ',
'ಕ್ಷಾರೋದಧಿ',
'ಕ್ಷಿತಿಕಂಪ',
'ಕ್ಷಿತಿಕಾಂತ',
'ಕ್ಷಿತಿಚಕ್ರ',
'ಕ್ಷಿತಿಜಾತ',
'ಕ್ಷಿತಿಧರ',
'ಕ್ಷಿತಿನಾಗ',
'ಕ್ಷಿತಿನಾಥ',
'ಕ್ಷಿತಿಪತಿ',
'ಕ್ಷಿತಿಪಾಲ',
'ಕ್ಷಿತಿರಸ',
'ಕ್ಷಿತಿರುಹ',
'ಕ್ಷಿತಿವರ',
'ಕ್ಷಿತಿಸುತೆ',
'ಕ್ಷಿತಿಸುರ',
'ಕ್ಷಿತ್ಯಧಿಪ',
'ಕ್ಷಿಪ್ರಹಸ್ತ',
'ಕ್ಷಿಪ್ರಹಸ್ತೆ',
'ಕ್ಷೀಣಕಾಂತಿ',
'ಕ್ಷೀಣಧನ',
'ಕ್ಷೀಣಧಾತು',
'ಕ್ಷೀಣಪುಣ್ಯ',
'ಕ್ಷೀಣಪುಣ್ಯೆ',
'ಕ್ಷೀಣಬಲ',
'ಕ್ಷೀರಕಂದ',
'ಕ್ಷೀರಕ್ಷೇತ್ರ',
'ಕ್ಷೀರಗೌರ',
'ಕ್ಷೀರನೀರ',
'ಕ್ಷೀರಪಥ',
'ಕ್ಷೀರಪಾಕ',
'ಕ್ಷೀರಪೂರ',
'ಕ್ಷೀರವತಿ',
'ಕ್ಷೀರವೃಕ್ಷ',
'ಕ್ಷೀರವೃತ್ತಿ',
'ಕ್ಷೀರಶುಕ್ಲೆ',
'ಕ್ಷೀರಶುದ್ಧಿ',
'ಕ್ಷೀರಾಂಬುಧಿ',
'ಕ್ಷೀರಾಗಾರ',
'ಕ್ಷೀರಾಗಾರಿ',
'ಕ್ಷೀರಾರ್ಣವ',
'ಕ್ಷೀರೋದಕ',
'ಕ್ಷುತ್ಪಿಪಾಸೆ',
'ಕ್ಷುದ್ರಗಾತಿ',
'ಕ್ಷುದ್ರಗಾರ',
'ಕ್ಷುದ್ರಗ್ರಹ',
'ಕ್ಷುದ್ರಜಂತು',
'ಕ್ಷುದ್ರಜನ',
'ಕ್ಷುದ್ರತನ',
'ಕ್ಷುದ್ರಧಾನ್ಯ',
'ಕ್ಷುದ್ರಪತ್ರ',
'ಕ್ಷುದ್ರಪಾತ್ರ',
'ಕ್ಷುದ್ರಮೃಗ',
'ಕ್ಷುದ್ರವೃತ್ತಿ',
'ಕ್ಷುದ್ರಶಂಖ',
'ಕ್ಷುದ್ರಹೇಳು',
'ಕ್ಷುದ್ರೋಪದ್ರ',
'ಕ್ಷುಧಾಶಾಂತಿ',
'ಕ್ಷುಭಿಯಿಸು',
'ಕ್ಷುರಕರ್ಮ',
'ಕ್ಷುರಧಾರೆ',
'ಕ್ಷುರಭಾಂಡ',
'ಕ್ಷುರಮರ್ದಿ',
'ಕ್ಷುರಿಕಾಸ್ತ್ರ',
'ಕ್ಷುಲ್ಲಕಾಮ್ಲೆ',
'ಕ್ಷುಲ್ಲತನ',
'ಕ್ಷೇತ್ರಕಾರ್ಯ',
'ಕ್ಷೇತ್ರಪಾಲ',
'ಕ್ಷೇತ್ರಫಲ',
'ಕ್ಷೇತ್ರಭೇದ',
'ಕ್ಷೇತ್ರವ್ಯಾಪ್ತಿ',
'ಕ್ಷೇತ್ರಾಕರ್ಷ',
'ಕ್ಷೇತ್ರಾಜೀವ',
'ಕ್ಷೇತ್ರಾಜೀವಿ',
'ಕ್ಷೇಮಂಕರ',
'ಕ್ಷೇಮಕರ',
'ಕ್ಷೇಮಕಾರಿ',
'ಕ್ಷೇಮಗೆಯ್',
'ಕ್ಷೇಮಗೊಳ್',
'ಕ್ಷೇಮದ್ರವ್ಯ',
'ಕ್ಷೇಮನಿಧಿ',
'ಕ್ಷೋಣಿಧರ',
'ಕ್ಷೋಣಿಭೃತ್ತು',
'ಕ್ಷೋಣೀಪತಿ',
'ಕ್ಷೋಣೀಭಾಗ',
'ಕ್ಷೋಣೀಶ್ವರ',
'ಕ್ಷೋದಕ್ಷಮ',
'ಕ್ಷೋದನೀಯ',
'ಕ್ಷೋಭಕಾರಿ',
'ಕ್ಷೌಮಾಂಬರ',
'ಕ್ಷೌರಗೃಹ',
'ಖಂಜರೀಟ',
'ಖಂಜಾಖಂಜ',
'ಖಂಜೀಕೃತ',
'ಖಂಡಂಗೊಯ್',
'ಖಂಡಕಥೆ',
'ಖಂಡಕಾವ್ಯ',
'ಖಂಡಗಟ್ಟು',
'ಖಂಡಚಂದ್ರ',
'ಖಂಡತುಂಡ',
'ಖಂಡತುಂಡು',
'ಖಂಡನೀಯ',
'ಖಂಡಪ್ರಾಸ',
'ಖಂಡಭಂಡ',
'ಖಂಡಮೇಘ',
'ಖಂಡರಿಸು',
'ಖಂಡವಿಧು',
'ಖಂಡವೀಣೆ',
'ಖಂಡವೃದ್ಧ',
'ಖಂಡಶಶಿ',
'ಖಂಡಸೂಚಿ',
'ಖಂಡಸ್ವಪ್ನ',
'ಖಂಡಾಂತರ',
'ಖಂಡಾಭ್ರಕ',
'ಖಂಡಿಗಳೆ',
'ಖಂಡಿತೋಕ್ತಿ',
'ಖಂಡುತುಂಡು',
'ಖಂಡೇರಾವು',
'ಖಗಪತಿ',
'ಖಗಬಂಧ',
'ಖಗರಾಜ',
'ಖಗಲಿಪಿ',
'ಖಗವರ',
'ಖಗವಿಷ್ಠೆ',
'ಖಗಾಧಿಪ',
'ಖಗೇಶ್ವರ',
'ಖಗೋಳಜ್ಞ',
'ಖಚಕ್ಕನೆ',
'ಖಚರೇಂದ್ರ',
'ಖಚರೇಶ',
'ಖಚಿತತೆ',
'ಖಚಿಯಿಸು',
'ಖಜ್ಜಾಖೋರ',
'ಖಟಪಟಿ',
'ಖಟಬಾಕಿ',
'ಖಟಾಟೋಪ',
'ಖಟಿಪಿಟಿ',
'ಖಟ್ಟಖರೆ',
'ಖಡಖಡ',
'ಖಡಖೋಡು',
'ಖಡಗಾಯಿ',
'ಖಡತರ',
'ಖಡಾಖಡಿ',
'ಖಡಾಖೋಡು',
'ಖಡಾಜಂಗಿ',
'ಖಡಾವಿಗೆ',
'ಖಡ್ಗಕರ',
'ಖಡ್ಗಧಾರೆ',
'ಖಡ್ಗಫಲ',
'ಖಡ್ಗಮುದ್ರೆ',
'ಖಡ್ಗಮುಷ್ಟಿ',
'ಖಡ್ಗಮೃಗ',
'ಖಡ್ಗರತ್ನ',
'ಖಡ್ಗವಿದ್ಯ',
'ಖಡ್ಗವಿದ್ಯೆ',
'ಖಡ್ಗಹಸ್ತ',
'ಖಡ್ಗಾಖಡ್ಗಿ',
'ಖಡ್ಗಾಸನ',
'ಖಡ್ಗಿಮೃಗ',
'ಖಡ್ಡಣಿಗೆ',
'ಖಡ್ಡತನ',
'ಖಡ್ಡಿಬತ್ತಿ',
'ಖಡ್ಡಿವಲೆ',
'ಖಣಖಣ',
'ಖಣತ್ಕಾರ',
'ಖಣತ್ಕೃತಿ',
'ಖಣಿಗುಟ್ಟು',
'ಖತಮಾಲ',
'ಖತಾಯತಿ',
'ಖತಾಯಿತಿ',
'ಖತಾವಣಿ',
'ಖತಾವಣೆ',
'ಖತಿಗಳೆ',
'ಖತಿಗೊಳ್',
'ಖತಿಗೊಳ್ಳು',
'ಖತಿನೆಡು',
'ಖತಿಲಕ',
'ಖತೆನೆಡು',
'ಖದಿರಕ',
'ಖದ್ದಿಕಾಱ',
'ಖಪ್ರವಾಹ',
'ಖಬರ್ದಾರ್',
'ಖಯಖೋಡಿ',
'ಖರಕರ',
'ಖರಕಲ',
'ಖರಖುರ',
'ಖರಗಂಧ',
'ಖರಣಸ',
'ಖರತನ',
'ಖರದಂಡ',
'ಖರಪತ್ರ',
'ಖರಪಾಕ',
'ಖರಪುಷ್ಪ',
'ಖರಬುಜ',
'ಖರಬೂಜ',
'ಖರರುಚಿ',
'ಖರವಳಿ',
'ಖರಶಾಣ',
'ಖರಸತ್ವ',
'ಖರಸಾಣೆ',
'ಖರಸಿಂಗ',
'ಖರಾತ್ಮಕ',
'ಖರಾಮತ್',
'ಖರಾಮತಿ',
'ಖರಾಮತ್ತು',
'ಖರಾಲಿಕ',
'ಖರೀದಿಸು',
'ಖರೆಗೊಳ್',
'ಖರೆತನ',
'ಖರೆಲೇಸು',
'ಖರ್ಚುದಾರ',
'ಖರ್ಚುಪಟ್ಟಿ',
'ಖರ್ಜಿಕಾಯಿ',
'ಖರ್ವಶಾಖ',
'ಖಲಧಾನ',
'ಖಲಧಾನ್ಯ',
'ಖಲನ್ಯಾಯ',
'ಖಲಪಾತ್ರ',
'ಖಲಬತ್ತು',
'ಖಲಮತ',
'ಖಲಿಂದರ',
'ಖಲಿಂಧರ',
'ಖಲೂರಿಕೆ',
'ಖಲೇವಾಲಿ',
'ಖಲೋತ್ಕಾಲಿ',
'ಖಲ್ಮೊಱಡಿ',
'ಖವಖವ',
'ಖವಳಿಸು',
'ಖಸಖಸಿ',
'ಖಸರತ್ತು',
'ಖಸ್ವಸ್ತಿಕ',
'ಖಳಕರ್ಮ',
'ಖಳಕೃತಿ',
'ಖಳಖಳ',
'ಖಳಧಾನ',
'ಖಳನ್ಯಾಯ',
'ಖಳಪತಿ',
'ಖಳಫಳ',
'ಖಳವಹ್ನಿ',
'ಖಳಾಗ್ರಣಿ',
'ಖಳಿಲನೆ',
'ಖಱಱನೆ',
'ಖೞ್ಗಧಾರೆ',
'ಖೞ್ಗಧೇನು',
'ಖೞ್ಗರುಚಿ',
'ಖೞ್ಗಾಖೞ್ಗಿ',
'ಖಾಜಿನ್ಯಾಯ',
'ಖಾಡಾಖಾಡಿ',
'ಖಾಡಾಜಂಗಿ',
'ಖಾಣಂಗಟ್ಟು',
'ಖಾಣವಿಧಿ',
'ಖಾತಿಗೆಡು',
'ಖಾತಿಗೊಳ್',
'ಖಾತೆಕಿರ್ದಿ',
'ಖಾತೆಮಾಡು',
'ಖಾತೇದಾರ',
'ಖಾತೇವಾರು',
'ಖಾತ್ರಿಜನ',
'ಖಾದ್ಯತೈಲ',
'ಖಾನಾಜಂಗಿ',
'ಖಾನಾವಳಿ',
'ಖಾಯಂಗುತ್ತಾ',
'ಖಾರದಾಣಿ',
'ಖಾರಬಟ್ಟೆ',
'ಖಾರಬೇಳೆ',
'ಖಾರೀವಾಪ',
'ಖಾಲಸಾತ',
'ಖಾಲಸಾತಿ',
'ಖಾಲಸಾತು',
'ಖಾಲಿಸಾತ',
'ಖಾಸಗತ',
'ಖಾಸಗತಿ',
'ಖಾಸಪಾಗೆ',
'ಖಾಸಬಂಗ್ಲೆ',
'ಖಾಸರೋಗ',
'ಖಾಸವ್ಯಾಧಿ',
'ಖಾಸಾಕೋಣೆ',
'ಖಾಸಾಪಾಗ',
'ಖಾಸಾಬಾರು',
'ಖಾಸಾಬೇಗಂ',
'ಖಾಸಾಸ್ವಾರಿ',
'ಖಾಸ್ಸವಾರಿ',
'ಖಿಜಮತ್ತು',
'ಖಿನ್ನಮುಖಿ',
'ಖಿಫಾಯತಿ',
'ಖಿಫಾಯಿತ',
'ಖಿರ್ದಿಚಿಟ್ಟಿ',
'ಖಿಲಕ್ಷೇತ್ರ',
'ಖಿಲ್ಲೇದಾರ',
'ಖಿಸಮಿಸ',
'ಖಿಸ್ತುಬಂದಿ',
'ಖುತ್ತಚಾರಿ',
'ಖುದಾವಂದ್',
'ಖುದ್ರಗೇಡಿ',
'ಖುರಗತಿ',
'ಖುರಣಸ',
'ಖುರಪುಟ',
'ಖುರವಿಡು',
'ಖುರಾಸಾಣಿ',
'ಖುರಾಸಾನಿ',
'ಖುರಾಹತಿ',
'ಖುರೇಸಾನಿ',
'ಖುರ್ದ್‍ಬರ್ದ್',
'ಖುರ್ದುಫರ್ದು',
'ಖುರ್ದುಬರ್ದು',
'ಖುಲಾಯಿಸು',
'ಖುಲಿಖುಲಿ',
'ಖುಲ್ಲತನ',
'ಖುಷಾಮತಿ',
'ಖುಷಾಮತು',
'ಖುಷಿಗಾರ',
'ಖುಷಿಪಡು',
'ಖುಷ್ಕರೀದಿ',
'ಖುಷ್ಕಿಪೈರು',
'ಖುಳಖುಳ',
'ಖುಳುಖುಳು',
'ಖೂನದೋರು',
'ಖೂನವಱಿ',
'ಖೂನಿಗಾರ',
'ಖೂಳತನ',
'ಖೇಕರಿಸು',
'ಖೇಚರತ್ವ',
'ಖೇಚರಾಹಿ',
'ಖೇಟಗತಿ',
'ಖೇಡಕುಳಿ',
'ಖೇಡತನ',
'ಖೇಡಾಕುಳಿ',
'ಖೇಡಾಕುೞಿ',
'ಖೇಣಿಕಾಱ',
'ಖೇತಬಾಡಿ',
'ಖೇದಗೊಳ್',
'ಖೇದಪಡು',
'ಖೇದಶ್ರಮ',
'ಖೇದಾಂಧತೆ',
'ಖೇಳಮೇಳ',
'ಖೈದುವಾರು',
'ಖೈರ್‍ಕಾಯಿ',
'ಖೈರಂದಱಿ',
'ಖೈರಿಯತ್ತು',
'ಖೈಸೂರಣ',
'ಖೊಟ್ಟಿತನ',
'ಖೊಟ್ಟಿನಾಣ್ಯ',
'ಖೊಟ್ಟಿಮುತ್ತು',
'ಖೊಟ್ಟಿರೊಟ್ಟಿ',
'ಖೊಪ್ಪರಿಸು',
'ಖೊಳಂಬಿಸು',
'ಖೋಟಾನಾಣ್ಯ',
'ಖೋಡಿಗಳೆ',
'ಖೋಡಿಗಾಣ್',
'ಖೋಡಿಚಾಳಿ',
'ಖೋಡಿತನ',
'ಖೋಡಿದೈವ',
'ಖೋಡಿಯೋಗು',
'ಖೋರಾಸಾನಿ',
'ಖ್ಯಾತನಾಮ',
'ಖ್ಯಾತಯಶ',
'ಖ್ಯಾತಿವಂತ',
'ಖ್ಯಾತಿವಂತೆ',
'ಖ್ಯಾತಿವಡೆ',
'ಗಂಗಪಾಣಿ',
'ಗಂಗಮಾಳ',
'ಗಂಗವಾಣಿ',
'ಗಂಗಸಾರ',
'ಗಂಗಾಜಲ',
'ಗಂಗಾಜಳ',
'ಗಂಗಾಜೂಟ',
'ಗಂಗಾದೇವಿ',
'ಗಂಗಾದ್ವಾರ',
'ಗಂಗಾಧರ',
'ಗಂಗಾಧೀಶ',
'ಗಂಗಾನದಿ',
'ಗಂಗಾನ್ವಯ',
'ಗಂಗಾಪುತ್ರ',
'ಗಂಗಾಫಲ',
'ಗಂಗಾಮಾಳ',
'ಗಂಗಾಮೃತ',
'ಗಂಗಾಮೌಲಿ',
'ಗಂಗಾಯಾತ್ರೆ',
'ಗಂಗಾವನ',
'ಗಂಗಾವಾರ್ಧಿ',
'ಗಂಗೆಕುಲ',
'ಗಂಗೆತಾಲಿ',
'ಗಂಗೆದೊಱೆ',
'ಗಂಗೆದೋಲು',
'ಗಂಗೆಮತ',
'ಗಂಗೆವಾರಿ',
'ಗಂಗೆವಾಳೆ',
'ಗಂಗೋದಕ',
'ಗಂಜಣಿಕೆ',
'ಗಂಜಳಿಕೆ',
'ಗಂಜಿಕಸ',
'ಗಂಜಿಕಾಳು',
'ಗಂಜಿಕಾಯಿ',
'ಗಂಜಿಗುಡಿ',
'ಗಂಜಿಣಿಕೆ',
'ಗಂಜಿಬಟ್ಟೆ',
'ಗಂಜಿಮಠ',
'ಗಂಜಿಮಡಿ',
'ಗಂಜಿಮಾಡು',
'ಗಂಜಿಮುಳ್ಳು',
'ಗಂಜಿಯನ್ನ',
'ಗಂಟಾಪಾತ್ರೆ',
'ಗಂಟಿಕ್ಕಿಸು',
'ಗಂಟಿಚೌಡಿ',
'ಗಂಟುಕಟ್ಟು',
'ಗಂಟುಕಳ್ಳ',
'ಗಂಟುಕಾಚಿ',
'ಗಂಟುಕಾಲು',
'ಗಂಟುಕೊಯ್ಕ',
'ಗಂಟುಗಟ್ಟು',
'ಗಂಟುಗಳ್ಳ',
'ಗಂಟುಗಾಲು',
'ಗಂಟುಗೆಯ್',
'ಗಂಟುಗೊಯ್ಕ',
'ಗಂಟುಗೊಳ್',
'ಗಂಟುಣಿಗ',
'ಗಂಟುತುಂಬೆ',
'ಗಂಟುನೊಣ',
'ಗಂಟುಪುರ್ಬು',
'ಗಂಟುಬಿಚ್ಚು',
'ಗಂಟುಬೀಳು',
'ಗಂಟುಬೇನೆ',
'ಗಂಟುಮರ',
'ಗಂಟುಮಾಡು',
'ಗಂಟುಮುಖ',
'ಗಂಟುಮೂಗು',
'ಗಂಟುಮೂಟೆ',
'ಗಂಟುಮೊಟ್ಟೆ',
'ಗಂಟುಮೋರೆ',
'ಗಂಟುವ್ಯಾಜ್ಯ',
'ಗಂಟುಹಾಕು',
'ಗಂಟುಹುಣ್ಣು',
'ಗಂಟುಹುಬ್ಬು',
'ಗಂಟುಹೊಡೆ',
'ಗಂಟೆಚೋಡಿ',
'ಗಂಟೆಮುಸ್ತೆ',
'ಗಂಟೆಸರ',
'ಗಂಡಕಾರಿ',
'ಗಂಡಕಾಲಿ',
'ಗಂಡಕ್ಷರ',
'ಗಂಡಗಚ್ನೆ',
'ಗಂಡಗತ್ತಿ',
'ಗಂಡಗರ್ವ',
'ಗಂಡಗಲಿ',
'ಗಂಡಗಾಡಿ',
'ಗಂಡಗುಣ',
'ಗಂಡಗೂಸು',
'ಗಂಡಡಿಕೆ',
'ಗಂಡತನ',
'ಗಂಡದೀಪ',
'ಗಂಡಬೀರಿ',
'ಗಂಡಭಾಗ',
'ಗಂಡಭಿತ್ತಿ',
'ಗಂಡಮಾತು',
'ಗಂಡಮಾಲೆ',
'ಗಂಡಮೃಗ',
'ಗಂಡಯೋಗ',
'ಗಂಡರಾಮಿ',
'ಗಂಡವಳ್ತಿ',
'ಗಂಡವಾತು',
'ಗಂಡಶಿಲೆ',
'ಗಂಡಶೈಲ',
'ಗಂಡಸೂಚಿ',
'ಗಂಡಸ್ಥಲ',
'ಗಂಡಸ್ಥಳ',
'ಗಂಡಸ್ಥಳಿ',
'ಗಂಡಸ್ಥಾನ',
'ಗಂಡಾಂತರ',
'ಗಂಡಾಗುಂಡಿ',
'ಗಂಡಾಮಾಲೆ',
'ಗಂಡಾಮಿಗ',
'ಗಂಡಾಮೃಗ',
'ಗಂಡಾಳ್ತನ',
'ಗಂಡಿಗಿತಿ',
'ಗಂಡುಕಚ್ಚೆ',
'ಗಂಡುಕಣೆ',
'ಗಂಡುಕಾಚಿ',
'ಗಂಡುಕಾರೆ',
'ಗಂಡುಕಾವ್ಯ',
'ಗಂಡುಕೂಸು',
'ಗಂಡುಕೊನೆ',
'ಗಂಡುಗಚ್ಚೆ',
'ಗಂಡುಗರ್ವ',
'ಗಂಡುಗಲಿ',
'ಗಂಡುಗಾಡಿ',
'ಗಂಡುಗಾರೆ',
'ಗಂಡುಗಿಡು',
'ಗಂಡುಗುಂದು',
'ಗಂಡುಗುಡಿ',
'ಗಂಡುಗುಣ',
'ಗಂಡುಗೂಸು',
'ಗಂಡುಗೆಡು',
'ಗಂಡುಗೆಡೆ',
'ಗಂಡುಗೆಯ್',
'ಗಂಡುಗೆಲೆ',
'ಗಂಡುಗೊಳ್',
'ಗಂಡುಗೊಳ್ಳು',
'ಗಂಡುಜಂಭ',
'ಗಂಡುಜನ',
'ಗಂಡುಜನ್ಮ',
'ಗಂಡುಜಾತಿ',
'ಗಂಡುಜೋಹ',
'ಗಂಡುಡಿಗೆ',
'ಗಂಡುಡುಗು',
'ಗಂಡುಡುಗೆ',
'ಗಂಡುತನ',
'ಗಂಡುತೊರೆ',
'ಗಂಡುದೊತ್ತು',
'ಗಂಡುದೊಳ್ತು',
'ಗಂಡುದೊಱೆ',
'ಗಂಡುದೊೞ್ತು',
'ಗಂಡುದೋಱು',
'ಗಂಡುಧೈರ್ಯ',
'ಗಂಡುನದಿ',
'ಗಂಡುನರ',
'ಗಂಡುಪಾಳ್ಯ',
'ಗಂಡುಪಿಂಡ',
'ಗಂಡುಪಿಳ್ಳೆ',
'ಗಂಡುಪೋರ',
'ಗಂಡುಬತ್ತ',
'ಗಂಡುಬೀಡು',
'ಗಂಡುಬೀರಿ',
'ಗಂಡುಬೆಸ',
'ಗಂಡುಭತ್ತ',
'ಗಂಡುಭಾಷೆ',
'ಗಂಡುಭೂಮಿ',
'ಗಂಡುಮಗ',
'ಗಂಡುಮಗು',
'ಗಂಡುಮಿಗು',
'ಗಂಡುಮೀರು',
'ಗಂಡುಮೆಟ್ಟು',
'ಗಂಡುರಾಮೆ',
'ಗಂಡುರೊಟ್ಟಿ',
'ಗಂಡುವಳಿ',
'ಗಂಡುವಾಳ',
'ಗಂಡುವಿಕ್ಕೆ',
'ಗಂಡುವೀಡು',
'ಗಂಡುವೆಸ',
'ಗಂಡುಶಿಲೆ',
'ಗಂಡುಶೈಲಿ',
'ಗಂಡುಸೂಳೆ',
'ಗಂಡುಹಂಜೆ',
'ಗಂಡುಹುಳು',
'ಗಂಡುಹೂವು',
'ಗಂಡುಹೊಳೆ',
'ಗಂಡೂಪದ',
'ಗಂಡೂಪದಿ',
'ಗಂಡೂಷಂಗೈ',
'ಗಂಡೋದ್ಗಾರಿ',
'ಗಂಡೌಷಧ',
'ಗಂತಿಬೇರು',
'ಗಂದವಟ್ಟೆ',
'ಗಂದವಣ',
'ಗಂದವುಡಿ',
'ಗಂದಿಗಿತಿ',
'ಗಂದೆಯೇಳು',
'ಗಂದೆಯೇೞು',
'ಗಂದೊಟಿಕೆ',
'ಗಂಧಕಲ್ಲು',
'ಗಂಧಕಾಮ್ಲ',
'ಗಂಧಕುಟಿ',
'ಗಂಧಗಜ',
'ಗಂಧಗಿರಿ',
'ಗಂಧತೋಯ',
'ಗಂಧದ್ರವ್ಯ',
'ಗಂಧದ್ವಿಪ',
'ಗಂಧನಕ',
'ಗಂಧನಾಳಿ',
'ಗಂಧಪತ್ರ',
'ಗಂಧಪುಡಿ',
'ಗಂಧಪುಷ್ಪ',
'ಗಂಧಫಲಿ',
'ಗಂಧಬೇವು',
'ಗಂಧಮಾಂಸಿ',
'ಗಂಧಮುಖ',
'ಗಂಧಮುರೆ',
'ಗಂಧಮೂಷಿ',
'ಗಂಧಯುಕ್ತಿ',
'ಗಂಧರಸ',
'ಗಂಧರಾಜ',
'ಗಂಧರ್ವಕ',
'ಗಂಧರ್ವಾಂಶ',
'ಗಂಧವಣ',
'ಗಂಧವತಿ',
'ಗಂಧವಹ',
'ಗಂಧವಹೆ',
'ಗಂಧವಾರಿ',
'ಗಂಧವಾಹ',
'ಗಂಧವೀಡ',
'ಗಂಧವುಡಿ',
'ಗಂಧಶಾಲಿ',
'ಗಂಧಶಾಳಿ',
'ಗಂಧಸಾರ',
'ಗಂಧಸಾಲಿ',
'ಗಂಧಸಾಲೆ',
'ಗಂಧಹಸ್ತಿ',
'ಗಂಧಹುಡಿ',
'ಗಂಧಾಂಧತೆ',
'ಗಂಧಾಕುಳ',
'ಗಂಧಾಕ್ಷತೆ',
'ಗಂಧಾಪಣ',
'ಗಂಧೋತ್ತಮೆ',
'ಗಂಧೋದಕ',
'ಗಂಧೋದ್ಗಾರಿ',
'ಗಂಧೋಪಲ',
'ಗಂಪೆಧಾರಿ',
'ಗಂಭೀರತೆ',
'ಗಕ್ಕುಪಾಡು',
'ಗಗನಾಧ್ವ',
'ಗಗನಾಹಿ',
'ಗಗ್ಗನಾತ',
'ಗಗ್ಗರಿಕೆ',
'ಗಗ್ಗರಿಕ್ಕೆ',
'ಗಗ್ಗರಿಸು',
'ಗಚಕ್ಕನೆ',
'ಗಚ್ಚರಿಸು',
'ಗಚ್ಚುಕುಟ್ಟು',
'ಗಚ್ಚುಗಟ್ಟು',
'ಗಚ್ಚೊರಸು',
'ಗಜಕಡ್ಡಿ',
'ಗಜಕರ್ಣ',
'ಗಜಕರ್ಣಿ',
'ಗಜಗಡ್ಡಿ',
'ಗಜಗರ್ಭ',
'ಗಜಘಟೆ',
'ಗಜಚರ್ಮ',
'ಗಜಜೀವ',
'ಗಜಜೀವಿ',
'ಗಜಜ್ಞಾನ',
'ಗಜತಂತ್ರ',
'ಗಜದಂತ',
'ಗಜದಳ',
'ಗಜದಾನ',
'ಗಜದುಳಿ',
'ಗಜದ್ವಿಷ',
'ಗಜಧ್ವಜ',
'ಗಜನಿಂಬು',
'ಗಜನಿಂಬೆ',
'ಗಜಪಗೆ',
'ಗಜಪತಿ',
'ಗಜಪುಟ',
'ಗಜಪ್ರಾಸ',
'ಗಜಪ್ರಿಯೆ',
'ಗಜಬಜ',
'ಗಜಬಜಿ',
'ಗಜಬಜೆ',
'ಗಜಬೀರ',
'ಗಜಬೇರು',
'ಗಜಭಕ್ಷೆ',
'ಗಜಮದ',
'ಗಜಮುಖ',
'ಗಜಯೋಧ',
'ಗಜರಾಜ',
'ಗಜರಿಪು',
'ಗಜಲಕ್ಷ್ಮಿ',
'ಗಜಲಿಂಬೆ',
'ಗಜವಶೆ',
'ಗಜವೈದ್ಯ',
'ಗಜವೈರಿ',
'ಗಜವ್ರಜ',
'ಗಜಶಾಲೆ',
'ಗಜಶಾಸ್ತ್ರ',
'ಗಜಶಿಕ್ಷೆ',
'ಗಜಸ್ನಾನ',
'ಗಜಹಸ್ತ',
'ಗಜಾಜೀವ',
'ಗಜಾನನ',
'ಗಜಾರೋಹ',
'ಗಜಾಲಿಕೆ',
'ಗಜಾವಳಿ',
'ಗಜಿಬಿಜಿ',
'ಗಜೆಬಜೆ',
'ಗಜ್ಜವೃಷ್ಟಿ',
'ಗಜ್ಜಿಹಲ್ಲು',
'ಗಟಕ್ಕನೆ',
'ಗಟವರಿ',
'ಗಟವಾಣಿ',
'ಗಟಾಂಗಟಿ',
'ಗಟಿವಾಣಿ',
'ಗಟ್ಟಿಕಾಳು',
'ಗಟ್ಟಿಕುಳ',
'ಗಟ್ಟಿಗಡ್ಡೆ',
'ಗಟ್ಟಿಗಣ್ಣು',
'ಗಟ್ಟಿಗಾತಿ',
'ಗಟ್ಟಿಗಾರ',
'ಗಟ್ಟಿಗಿತ್ತಿ',
'ಗಟ್ಟಿಗೆಡ್ಡೆ',
'ಗಟ್ಟಿಗೊಳ್',
'ಗಟ್ಟಿಗೊಳ್ಳು',
'ಗಟ್ಟಿತನ',
'ಗಟ್ಟಿದುಡ್ಡು',
'ಗಟ್ಟಿನಾಣ್ಯ',
'ಗಟ್ಟಿಪಿಂಡ',
'ಗಟ್ಟಿಮುಟ್ಟು',
'ಗಟ್ಟಿಯಾಳು',
'ಗಟ್ಟಿವಳ',
'ಗಟ್ಟಿವಳ್ತಿ',
'ಗಟ್ಟಿವಾಳ',
'ಗಟ್ಟಿವಾಳಿ',
'ಗಡಂಗಣ',
'ಗಡಕರಿ',
'ಗಡಗಂಚಿ',
'ಗಡಗಡ',
'ಗಡಗಡೆ',
'ಗಡಗುಟ್ಟು',
'ಗಡಚಿಕ್ಕು',
'ಗಡಣಿಗ',
'ಗಡಣಿಸು',
'ಗಡಪಾರೆ',
'ಗಡಬಡ',
'ಗಡಬಡಿ',
'ಗಡಬಡೆ',
'ಗಡಬಾರೆ',
'ಗಡಸರ್ಪ',
'ಗಡಹಾರೆ',
'ಗಡಾಯಿಸು',
'ಗಡಾವಣೆ',
'ಗಡಿಕಲ್ಲು',
'ಗಡಿಕಾರ',
'ಗಡಿಕೊರೆ',
'ಗಡಿಗಟ್ಟು',
'ಗಡಿಗಾಡು',
'ಗಡಿಗೆಡು',
'ಗಡಿಗೆಮ್ಮ',
'ಗಡಿತಡಿ',
'ಗಡಿನಾಡು',
'ಗಡಿಪಾರು',
'ಗಡಿಬಿಡಿ',
'ಗಡಿಭತ್ತ',
'ಗಡಿಮೀರು',
'ಗಡಿಯಾರ',
'ಗಡಿಯಾಲ',
'ಗಡಿಯಾಳ',
'ಗಡಿಯೊತ್ತು',
'ಗಡಿರೇಖೆ',
'ಗಡಿವಾಡ',
'ಗಡೀಪಾರು',
'ಗಡೀಭತ್ತ',
'ಗಡುಗಂಚಿ',
'ಗಡುತರ',
'ಗಡುವಿಡು',
'ಗಡೆಕಾರ',
'ಗಡ್ಡೆಕಟ್ಟು',
'ಗಡ್ಡೆಗಟ್ಟು',
'ಗಡ್ಡೆಜ್ವರ',
'ಗಡ್ಡೆನೆಲ',
'ಗಡ್ಡೆರೋಗ',
'ಗಡ್ಡೆಹುಲ್ಲು',
'ಗಢಾವಣೆ',
'ಗಣಕಿಗ',
'ಗಣಕೂಟ',
'ಗಣಗಂಟೆ',
'ಗಣಗಣ',
'ಗಣಗಳ',
'ಗಣಗಿಲು',
'ಗಣಜಲ',
'ಗಣಜಲಿ',
'ಗಣಜಲೆ',
'ಗಣಜಿಲು',
'ಗಣಜಿಲೆ',
'ಗಣತಂತ್ರ',
'ಗಣತಿಗೆ',
'ಗಣದಿಗೆ',
'ಗಣದ್ರವ್ಯ',
'ಗಣಧರ',
'ಗಣನಾಥ',
'ಗಣನೀಯ',
'ಗಣಪತಿ',
'ಗಣಪದ',
'ಗಣಪರ್ವ',
'ಗಣಬರು',
'ಗಣಮಗ',
'ಗಣಮುಖ',
'ಗಣಯಿಸು',
'ಗಣರಾಜ',
'ಗಣರಾಜ್ಯ',
'ಗಣರಾತ್ರ',
'ಗಣರಾತ್ರೆ',
'ಗಣರೂಪ',
'ಗಣವೇಷ',
'ಗಣವ್ರಿತ್ತಿ',
'ಗಣಹೋಮ',
'ಗಣಾಕ್ಷರ',
'ಗಣಾಗ್ರಣಿ',
'ಗಣಾಚಾರ',
'ಗಣಾಚಾರಿ',
'ಗಣಾಧಿಪ',
'ಗಣಾಧೀಶ',
'ಗಣಾರ್ಚನೆ',
'ಗಣಿಕಾರಿ',
'ಗಣಿಗಲು',
'ಗಣಿಗಲೆ',
'ಗಣಿಗಿಲು',
'ಗಣಿಜಿಲೆ',
'ಗಣಿತಜ್ಞ',
'ಗಣಿತಿಗ',
'ಗಣಿತಿಸು',
'ಗಣಿದಿಗ',
'ಗಣಿದಿಸು',
'ಗಣಿಯಿಸು',
'ಗಣೆಗಾರ',
'ಗಣೆಯೋದು',
'ಗಣೇಶ್ವರ',
'ಗತಕಾಲ',
'ಗತದೋಷಿ',
'ಗತಪಾರ',
'ಗತಪ್ರಜ್ಞ',
'ಗತಬಲ',
'ಗತಭೀತ',
'ಗತಭಾಗ್ಯ',
'ಗತಭ್ರಾಂತಿ',
'ಗತರಾಗ',
'ಗತಲೇಪ',
'ಗತವರ್ಷ',
'ಗತವೇದ',
'ಗತಶ್ರೀಕ',
'ಗತಾಂಬಕ',
'ಗತಾಗತ',
'ಗತಾಗತಿ',
'ಗತಿಕಾಣು',
'ಗತಿಕೆಡು',
'ಗತಿಕೊಡು',
'ಗತಿಗಾಣು',
'ಗತಿಗಿಡು',
'ಗತಿಗುಂದು',
'ಗತಿಗುಡು',
'ಗತಿಗೆಡು',
'ಗತಿಗೇಡಿ',
'ಗತಿಗೇಡು',
'ಗತಿಗೊಡು',
'ಗತಿಗೊಳ್',
'ಗತಿಭಂಗ',
'ಗತಿಮುಟ್ಟು',
'ಗತಿಯುದ್ಧ',
'ಗತಿವಡೆ',
'ಗತಿವಿಡಿ',
'ಗತಿಶೀಲ',
'ಗತಿಶೀಲೆ',
'ಗತಿಶೂನ್ಯ',
'ಗತಿಶೂನ್ಯೆ',
'ಗತಿಸ್ತಂಭ',
'ಗತಿಹೀನ',
'ಗತಿಹೀನೆ',
'ಗತೋದಕ',
'ಗತ್ತುಕಾರ',
'ಗತ್ತುಗಾರ',
'ಗತ್ತುಹೂಡು',
'ಗತ್ಯಂತರ',
'ಗತ್ಯಾಖ್ಯಾನ',
'ಗತ್ಯಾತ್ಮಕ',
'ಗದಗಂಪ',
'ಗದಗಾಪು',
'ಗದಗುಟ್ಟು',
'ಗದರಿಕೆ',
'ಗದರಿಸು',
'ಗದಾಧರ',
'ಗದಾಮುದ್ರೆ',
'ಗದಾಯುದ್ಧ',
'ಗದುಗದ',
'ಗದೆಗೊಳ್',
'ಗದೆವಿಡಿ',
'ಗದ್ಗದಗೈ',
'ಗದ್ಗದತೆ',
'ಗದ್ಗದತ್ವ',
'ಗದ್ಗದಿಕೆ',
'ಗದ್ಗದಿತ',
'ಗದ್ಗದಿಸು',
'ಗದ್ಗದುಕೆ',
'ಗದ್ದಕಟ್ಟು',
'ಗದ್ದಬಾವು',
'ಗದ್ದರಣೆ',
'ಗದ್ದರಿಕೆ',
'ಗದ್ದರಿಸು',
'ಗದ್ದಲಗೈ',
'ಗದ್ದಲಿಸು',
'ಗದ್ದುಗೆಗೈ',
'ಗದ್ದೆಕಂಟ',
'ಗದ್ದೆಕಟ್ಟು',
'ಗದ್ದೆಕಾನೆ',
'ಗದ್ದೆಗಳೆ',
'ಗದ್ದೆಗುಮ್ಮ',
'ಗದ್ದೆತಾಕು',
'ಗದ್ದೆಬದು',
'ಗದ್ದೆಮಡಿ',
'ಗದ್ದೆಮರ',
'ಗದ್ಯಕಾವ್ಯ',
'ಗದ್ಯಜಾತಿ',
'ಗದ್ಯತನ',
'ಗನಗಲು',
'ಗನಿಜೆಲೆ',
'ಗನ್ನಂಗೊಳ್',
'ಗನ್ನಕಾತಿ',
'ಗನ್ನಕಾರ',
'ಗನ್ನಕಾಱ',
'ಗನ್ನಗಾತಿ',
'ಗನ್ನಗಾಱ',
'ಗನ್ನಘಾತ',
'ಗನ್ನತನ',
'ಗನ್ನವಾತು',
'ಗನ್ನವೇಱು',
'ಗನ್ನಾಪೈರು',
'ಗನ್ನೇರಳೆ',
'ಗಪ್‍ಚಿಪ್',
'ಗಪಗಪ',
'ಗಪ್ಪಳಾಸು',
'ಗಪ್ಪುಗಾರು',
'ಗಪ್ಪುಚಿಪ್ಪು',
'ಗಬಕ್ಕನೆ',
'ಗಬಗಬ',
'ಗಬಲಿಕ್ಕು',
'ಗಬ್ಬಗರ',
'ಗಬ್ಬರಣೆ',
'ಗಬ್ಬರಿಸು',
'ಗಬ್ಬವನೆ',
'ಗಬ್ಬವಿಕ್ಕು',
'ಗಬ್ಬಾಳಿಕೆ',
'ಗಬ್ಬಿಗಲಿ',
'ಗಬ್ಬಿತನ',
'ಗಬ್ಬಿಲಾಯಿ',
'ಗಬ್ಬುಚಕ್ಕೆ',
'ಗಬ್ಬುಜಾಲಿ',
'ಗಬ್ಬುನಾತ',
'ಗಬ್ಬುನಾರು',
'ಗಬ್ಬುಸೊಪ್ಪು',
'ಗಬ್ಬುಹಿಡಿ',
'ಗಬ್ಬುಹೊಡೆ',
'ಗಭೀರತೆ',
'ಗಭೀರೋಕ್ತಿ',
'ಗಮಕಿತೆ',
'ಗಮಕಿಸು',
'ಗಮಗಮ',
'ಗಮನಾರ್ಹ',
'ಗಮನಿಕೆ',
'ಗಮನಿಸು',
'ಗಮನೀಯ',
'ಗಮಸಾಲೆ',
'ಗಮಾಡಿಸು',
'ಗಮಾಯಿಸು',
'ಗಮಿಯಿಸು',
'ಗಮ್ಮಕುಚ್ಚು',
'ಗಮ್ಮಗಾವು',
'ಗಮ್ಯವಡು',
'ಗಮ್ಯಸ್ಥಾನ',
'ಗಯಾವಳ',
'ಗಯಾವಾಳ',
'ಗರಕ್ಕನೆ',
'ಗರಗಟೆ',
'ಗರಗಟ್ಟಿ',
'ಗರಗಟ್ಟು',
'ಗರಗತ್ತಿ',
'ಗರಗರ',
'ಗರಗರಿ',
'ಗರಗಸ',
'ಗರಟಿಗೆ',
'ಗರಧರ',
'ಗರಬಡಿ',
'ಗರಬಲ',
'ಗರಬಾಡು',
'ಗರವಟ',
'ಗರವಟಿ',
'ಗರವಟ್ಟ',
'ಗರವರ',
'ಗರವೊಡೆ',
'ಗರವೊಯ್',
'ಗರಹಿಡಿ',
'ಗರಹೊಡೆ',
'ಗರಹೊಯ್',
'ಗರಳುಲಿ',
'ಗರಳೊಡೆ',
'ಗರಿಕಟ್ಟು',
'ಗರಿಕಟ್ಟೆ',
'ಗರಿಗಂಟಿ',
'ಗರಿಗಂಟೆ',
'ಗರಿಗಟ್ಟು',
'ಗರಿಗಣ್',
'ಗರಿಗೆಡು',
'ಗರಿಗೊಳ್ಳು',
'ಗರಿಟೋಪಿ',
'ಗರಿತುಂಬು',
'ಗರಿತೆಗೆ',
'ಗರಿಬಿಚ್ಚು',
'ಗರಿಮರಿ',
'ಗರಿಮುರಿ',
'ಗರಿಮೂಡು',
'ಗರಿಯಿಕ್ಕು',
'ಗರಿಯೊಡೆ',
'ಗರಿಲನೆ',
'ಗರಿಹೊಯ್',
'ಗರುಗಲು',
'ಗರುಗೊಡೆ',
'ಗರುಡಾಂಕ',
'ಗರುಡಾಸ್ತ್ರ',
'ಗರುತ್ಮಂತ',
'ಗರುದ್ಯೋಧಿ',
'ಗರುವಲಿ',
'ಗರುವಾಯ್',
'ಗರುವಾಯ',
'ಗರುವಾಯಿ',
'ಗರುವಾಯ್ಲ',
'ಗರುವಾಹಿ',
'ಗರುವಿಕೆ',
'ಗರುವಿತ',
'ಗರುವಿತೆ',
'ಗರುವಿಸು',
'ಗರುಹಣ',
'ಗರುಹಣೆ',
'ಗರ್ಗರಿಕೆ',
'ಗರ್ಗರಿಸು',
'ಗರ್ಘರಿಕೆ',
'ಗರ್ದತೋಯ',
'ಗರ್ದಭಾಂಡ',
'ಗರ್ಬಾನೃತ್ಯ',
'ಗರ್ಭಕಟ್ಟು',
'ಗರ್ಭಕೋಶ',
'ಗರ್ಭಗುಡಿ',
'ಗರ್ಭಗೃಹ',
'ಗರ್ಭಚಿಹ್ನ',
'ಗರ್ಭಚಿಹ್ನೆ',
'ಗರ್ಭಚೀಲ',
'ಗರ್ಭಚ್ಛೇದ',
'ಗರ್ಭದಾಸಿ',
'ಗರ್ಭದೀಕ್ಷೆ',
'ಗರ್ಭದೋಷ',
'ಗರ್ಭದ್ವಾರ',
'ಗರ್ಭನಿಲ್ಲು',
'ಗರ್ಭಪದಿ',
'ಗರ್ಭಪಾತ',
'ಗರ್ಭಪಿಂಡ',
'ಗರ್ಭಲಿಂಗ',
'ಗರ್ಭವತಿ',
'ಗರ್ಭವಾಸ',
'ಗರ್ಭವಿಕ್ಕು',
'ಗರ್ಭವಿಳಿ',
'ಗರ್ಭವ್ಯೂಹ',
'ಗರ್ಭಶಾಸ್ತ್ರ',
'ಗರ್ಭಶೂಲೆ',
'ಗರ್ಭಷಷ್ಠಿ',
'ಗರ್ಭಸಂಧಿ',
'ಗರ್ಭಸೂತ್ರ',
'ಗರ್ಭಸ್ಥಿತ',
'ಗರ್ಭಸ್ರಾವ',
'ಗರ್ಭಾಂಕುರ',
'ಗರ್ಭಾಂಗಣ',
'ಗರ್ಭಾಗಾರ',
'ಗರ್ಭಾಧಾನ',
'ಗರ್ಭಾಶಯ',
'ಗರ್ಭಾಷ್ಟಕ',
'ಗರ್ಭಾಷ್ಟಮ',
'ಗರ್ಭೀಕೃತ',
'ಗರ್ಭೇಶ್ವರ',
'ಗರ್ಭೇಶ್ವರಿ',
'ಗರ್ವಪಡು',
'ಗರ್ವಭಂಗ',
'ಗರ್ವವ್ಯಾಲೆ',
'ಗರ್ವವ್ಯಾಳಿ',
'ಗರ್ವಹೀನ',
'ಗರ್ವೋದ್ಧತ',
'ಗರ್ಹಣೀಯ',
'ಗಹ್ರ್ಯವಾದಿ',
'ಗಹ್ರ್ಯಾಧೀನ',
'ಗಹ್ರ್ಯಾಧೀನೆ',
'ಗಲಂತಿಕೆ',
'ಗಲಗಂಡ',
'ಗಲಗಲ',
'ಗಲತಿಗೆ',
'ಗಲಧ್ವನಿ',
'ಗಲಪಟ್ಟಿ',
'ಗಲಬಲಿ',
'ಗಲಬಿಲಿ',
'ಗಲಮೀಸೆ',
'ಗಲರವ',
'ಗಲವಾರ',
'ಗಲಹಸ್ತ',
'ಗಲಿಬಿಲಿ',
'ಗಲಿಯಿಸು',
'ಗಲೋದ್ದೇಶ',
'ಗಲ್ಗಲನೆ',
'ಗಲ್ಲಂಬಿಡಿ',
'ಗಲ್ಲಗಿವಿ',
'ಗಲ್ಲಗುಳಿ',
'ಗಲ್ಲಟಕ',
'ಗಲ್ಲತಗ್ಗು',
'ಗಲ್ಲಮೀಸೆ',
'ಗಲ್ಲವಿಡಿ',
'ಗಲ್ಲಹಿಡಿ',
'ಗಲ್ಲಾಗುಲ್ಲಿ',
'ಗಲ್ಲಿಮಣೆ',
'ಗಲ್ಲಿಮೀಸೆ',
'ಗಲ್ಲುಗಂಬ',
'ಗಲ್ಲುಗಾರ',
'ಗಲ್ಲುಮಣೆ',
'ಗಲ್ಲುಮರ',
'ಗಲ್ಲೆಗಟ್ಟು',
'ಗಲ್ಲೆಗೆಡೆ',
'ಗಲ್ಲೆಯೊಡೆ',
'ಗವಡಿಗ',
'ಗವರಿಗ',
'ಗವರ್ನರ್',
'ಗವರ್ಮೆಂಟು',
'ಗವಲಿಸು',
'ಗವಸಣಿ',
'ಗವಳಿಗ',
'ಗವಱಿಗ',
'ಗವಱೆಗ',
'ಗವಾಂಭೋಧಿ',
'ಗವಾಧಿಪ',
'ಗವಾಮಿಷ',
'ಗವಾಸ್ಪದ',
'ಗವಿಗೊಳ್',
'ಗವಿಲಿಪಿ',
'ಗವುಂಡಿಕೆ',
'ಗವುಜುಗ',
'ಗವುಡಿಕೆ',
'ಗವುಡಿತಿ',
'ಗವುರ್ನರ್',
'ಗವೇಧುಕೆ',
'ಗವೇಷಣೆ',
'ಗವೇಷಿತ',
'ಗವ್ವಳಿಕೆ',
'ಗಸಗಸ',
'ಗಸಗಸೆ',
'ಗಸಿಕೊಡು',
'ಗಸಿಗಟ್ಟು',
'ಗಸಿತೆಗೆ',
'ಗಸ್ತಿಗಾರ',
'ಗಸ್ತುಕೊಡು',
'ಗಸ್ತುಹೊಡೆ',
'ಗಹಗಹ',
'ಗಹನತೆ',
'ಗಳಂತಿಕೆ',
'ಗಳಂತಿಗೆ',
'ಗಳಕನೆ',
'ಗಳಕ್ಕನೆ',
'ಗಳಗಂಟೆ',
'ಗಳಗಂಡ',
'ಗಳಗಳ',
'ಗಳಗಾಣ',
'ಗಳಗಾಳ',
'ಗಳಗ್ರಂಥಿ',
'ಗಳಗ್ರಹ',
'ಗಳತಿಗ',
'ಗಳತಿಗೆ',
'ಗಳದಘ್ನ',
'ಗಳಧ್ವನಿ',
'ಗಳಪೂರ',
'ಗಳಬಳ',
'ಗಳರವ',
'ಗಳರುತಿ',
'ಗಳರೋಗ',
'ಗಳಶುಂಡಿ',
'ಗಳಹತ್ತ',
'ಗಳಹಸ್ತ',
'ಗಳಹಿಕೆ',
'ಗಳಹಿಸು',
'ಗಳಿತಾಶ್ರು',
'ಗಳಿಬಿಳಿ',
'ಗಳಿಯಾರ',
'ಗಳಿಯಿಸು',
'ಗಳಿಲನೆ',
'ಗಳಿಲಾನ್',
'ಗಳಿಸಾಳಿ',
'ಗಳಿಹಾರ',
'ಗಳೆಕಾರ',
'ಗಳೆಹೂಡು',
'ಗಳೆಹೊಡೆ',
'ಗಳೋದ್ದೇಶ',
'ಗಳ್ದೆಗಳೆ',
'ಗಱಿಗಂಟಿ',
'ಗಱಿಗಟ್ಟು',
'ಗಱಿಗಣೆ',
'ಗಱಿಗೋಲ್',
'ಗಱಿಮೀನ್',
'ಗಱಿಮೂಡು',
'ಗಱಿಯಿಡು',
'ಗಱಿವೊಯ್',
'ಗೞಗೞ',
'ಗೞಿಯಿಸು',
'ಗೞಿಸಾಳಿ',
'ಗೞೆಕಾರ',
'ಗೞೆಗೂಸು',
'ಗೞೆಗೂೞ್',
'ಗೞೆಯಿಕ್ಕು',
'ಗೞೆಯೆತ್ತು',
'ಗಾಂಗತೋಯ',
'ಗಾಂಗೇಯಕ',
'ಗಾಂಗೇರುಕ',
'ಗಾಂಗೇರುಕಿ',
'ಗಾಂಧರ್ವಜ್ಞ',
'ಗಾಂಧರ್ವಿಗ',
'ಗಾಂಧಿಟೋಪಿ',
'ಗಾಂಧಿದರ್ಜೆ',
'ಗಾಂಪತನ',
'ಗಾಂವಠಾಣೆ',
'ಗಾಜುಗಾರ',
'ಗಾಜುಗೋಜು',
'ಗಾಜುಬುಡ್ಡಿ',
'ಗಾಜುಮಣಿ',
'ಗಾಜುಲೇಪ',
'ಗಾಜುವಳೆ',
'ಗಾಟಳಿಸು',
'ಗಾಠಾಯಿಸು',
'ಗಾಡಿಕಟ್ಟು',
'ಗಾಡಿಕಾತಿ',
'ಗಾಡಿಕಾರ',
'ಗಾಡಿಕಾರ್ತಿ',
'ಗಾಡಿಕಾಱ',
'ಗಾಡಿಖಾನೆ',
'ಗಾಡಿಗಾತಿ',
'ಗಾಡಿಗಾರ',
'ಗಾಡಿಗಾಱ',
'ಗಾಡಿಚಟ್ಟ',
'ಗಾಡಿದಾರಿ',
'ಗಾಡಿನತ್ತು',
'ಗಾಡಿವಡೆ',
'ಗಾಡಿವತಿ',
'ಗಾಡಿವೆಱು',
'ಗಾಢನಿದ್ರೆ',
'ಗಾಢಾಭ್ಯಾಸ',
'ಗಾಢಾಯಿಲ',
'ಗಾಣಕಾರ್ತಿ',
'ಗಾಣಕಾಱ',
'ಗಾಣಗಾತಿ',
'ಗಾಣಗಿತ್ತಿ',
'ಗಾಣಗೊಂದೆ',
'ಗಾಣಪತ್ಯ',
'ಗಾಣಬಿಜ್ಜೆ',
'ಗಾಣರಾಣಿ',
'ಗಾಣವಾಡು',
'ಗಾಣವಿಕ್ಕು',
'ಗಾಣವೆಣ್',
'ಗಾಣಸಾಸ್ತ್ರ',
'ಗಾಣಹಾಕು',
'ಗಾಣಾಪತ್ಯ',
'ಗಾಣಿಗಿತಿ',
'ಗಾಣಿಗಿತ್ತಿ',
'ಗಾತಗಳೆ',
'ಗಾನಕಲೆ',
'ಗಾನಲೋಲ',
'ಗಾನಶಯ್ಯೆ',
'ಗಾಬರಿಸು',
'ಗಾಬಿಚುಕ್ಕೆ',
'ಗಾಮಗಾನೆ',
'ಗಾಮಾಹಾರ',
'ಗಾಯಂಬಡೆ',
'ಗಾಯಕತೆ',
'ಗಾಯಗೊಳು',
'ಗಾಯಗೊಳ್ಳು',
'ಗಾಯದೆರಿ',
'ಗಾಯಪಡು',
'ಗಾಯಪಡೆ',
'ಗಾಯವಡೆ',
'ಗಾಯಿಬಳ್ಳಿ',
'ಗಾಯಿಮೋರೆ',
'ಗಾರಿಟ್ಟಿಗೆ',
'ಗಾರುಗಲ್ಲು',
'ಗಾರುಗಾರು',
'ಗಾರುಗೆಡು',
'ಗಾರುಗೊಳ್ಳು',
'ಗಾರುಡಕ',
'ಗಾರುಡಿಕ',
'ಗಾರುಡಿಗ',
'ಗಾರುಡಿಸು',
'ಗಾರುತ್ಮತ',
'ಗಾರುಧ್ವನಿ',
'ಗಾರುಬೊಕ್ಕೆ',
'ಗಾರುಹಸ್ತ',
'ಗಾರುಹಸ್ತ್ಯ',
'ಗಾರೆಕಲ್ಲು',
'ಗಾರೆಕಾರ',
'ಗಾರೆಗಾರ',
'ಗಾರೆಮಣೆ',
'ಗಾರ್ಹಪತ್ಯ',
'ಗಾಲಾರಿಗ',
'ಗಾಲಿಕುರ್ಚಿ',
'ಗಾಲಿಪಲ್',
'ಗಾಲಿವಂಟಿ',
'ಗಾಲಿವಕ್ಕಿ',
'ಗಾಲುಮೇಲು',
'ಗಾವಕಾರ',
'ಗಾವಕೋಸು',
'ಗಾವಗಾನೆ',
'ಗಾವಠಾಣ',
'ಗಾವಣಿಗ',
'ಗಾವದಿಗ',
'ಗಾವರಸ',
'ಗಾವರಿಸು',
'ಗಾವಸಿಂಗ',
'ಗಾವಳಿಗ',
'ಗಾವಳಿಸು',
'ಗಾವಿಲತ್ವ',
'ಗಾವಿಲಿಕೆ',
'ಗಾವುಂಡಿಕೆ',
'ಗಾವುಂಡಿಗೆ',
'ಗಾವುಂಡಿತಿ',
'ಗಾವುಗುರಿ',
'ಗಾವುಗುಱಿ',
'ಗಾವುಮರಿ',
'ಗಾಸಿಗೆಯ್',
'ಗಾಸಿಗೊಳ್ಳು',
'ಗಾಸಿಯಾಗು',
'ಗಾಸಿವಡೆ',
'ಗಾಹುಕಂಡಿ',
'ಗಾಹುಕಾಱ',
'ಗಾಗುಗಳ್ಳ',
'ಗಾಹುಗಾತಿ',
'ಗಾಹುಗೊಳ್',
'ಗಾಳಗಾರ',
'ಗಾಳಹಾಕು',
'ಗಾಳಿಕೂಳ',
'ಗಾಳಿಕೊಡೆ',
'ಗಾಳಿಕೋಣೆ',
'ಗಾಳಿಕೋವಿ',
'ಗಾಳಿಕೋಳಿ',
'ಗಾಳಿಗಂಟು',
'ಗಾಳಿಗುದ್ದು',
'ಗಾಳಿಗೂಡು',
'ಗಾಳಿಗೂಳ',
'ಗಾಳಿಗೊಳ್',
'ಗಾಳಿಚೆಂಡು',
'ಗಾಳಿತೇರು',
'ಗಾಳಿದೇರ್',
'ಗಾಳಿಪಟ',
'ಗಾಳಿಪೂಜೆ',
'ಗಾಳಿಬಡಿ',
'ಗಾಳಿಬಾಳೆ',
'ಗಾಳಿಬೀಸು',
'ಗಾಳಿಬುಡ್ಡೆ',
'ಗಾಳಿಮರ',
'ಗಾಳಿಮಾತು',
'ಗಾಳಿಮೆಟ್ಟು',
'ಗಾಳಿಯಂತ್ರ',
'ಗಾಳಿಯಮ್ಮ',
'ಗಾಳಿಯಿಕ್ಕು',
'ಗಾಳಿವಟ್ಟೆ',
'ಗಾಳಿವರು',
'ಗಾಳಿವಾತು',
'ಗಾಳಿವಾರ',
'ಗಾಳಿವಿಡಿ',
'ಗಾಳಿವೇಗ',
'ಗಾಳಿಶಂಕೆ',
'ಗಾಳಿಸುದ್ದಿ',
'ಗಾಳಿಸೆಖೆ',
'ಗಾಳಿಸೊಂಕು',
'ಗಾಳಿಸೋಂಕು',
'ಗಾಳಿಹಾಕು',
'ಗಾಳಿಹಿಡಿ',
'ಗಾಳಿಹೋಗು',
'ಗಾಳುಗಣ್',
'ಗಾಳುಗೂೞ',
'ಗಾಳುಗೆಡು',
'ಗಾಳುತನ',
'ಗಾಳುಮಂದಿ',
'ಗಾಳುಮುೂಳ',
'ಗಾಳುಮೇಳು',
'ಗಾಳುವರಿ',
'ಗಾಳುವೆಣ್',
'ಗಾಱಾಗಾಱಿ',
'ಗಾಱುಂಗೆಡು',
'ಗಾಱುಗತ',
'ಗಾಱುಗಲಿ',
'ಗಾಱುಗಿಡು',
'ಗಾಱುಗೆಡು',
'ಗಾಱುಗೆಡೆ',
'ಗಾಱುತನ',
'ಗಾಱುನುಡಿ',
'ಗಾಱುಪಟ್ಟು',
'ಗಾಱುವಡೆ',
'ಗಾಱೇೞಿಸು',
'ಗಿಂಚುಹಾಕು',
'ಗಿಂಡಿಮಾಣಿ',
'ಗಿಂಡಿವಿಡಿ',
'ಗಿಂಡಿವೆಣ್',
'ಗಿಚಕಳಿ',
'ಗಿಚ್ಚಿಹೊಡೆ',
'ಗಿಜಗಾರ್ಲು',
'ಗಿಜವಾಯಿ',
'ಗಿಜಿಗಿಜಿ',
'ಗಿಜಿಗಿಡಿ',
'ಗಿಜಿಗುಟ್ಟು',
'ಗಿಜಿಬಿಜಿ',
'ಗಿಜಿವಾಯಿ',
'ಗಿಜುಗಾರ',
'ಗಿಟಗಿರಿ',
'ಗಿಟ್ಟಗಿರಿ',
'ಗಿಟ್ಟಿಗಿರಿ',
'ಗಿಡಗಂಟಿ',
'ಗಿಡಗಂಟೆ',
'ಗಿಡಗಾರ',
'ಗಿಡಗುನಿ',
'ಗಿಡಪಕ್ಕೆ',
'ಗಿಡಬೀಳು',
'ಗಿಡಮಂಗ',
'ಗಿಡಮಾಡು',
'ಗಿಡಮೆಳೆ',
'ಗಿಡಸೀಮೆ',
'ಗಿಡಹೇನು',
'ಗಿಡಿಕಿರಿ',
'ಗಿಡಿಗಿರಿ',
'ಗಿಡಿಬಡಿ',
'ಗಿಡಿಬಿಡಿ',
'ಗಿಡುಂಗಡಿ',
'ಗಿಡುಗಡಿ',
'ಗಿಡುಗಳ್ಳಿ',
'ಗಿಡುಗಾಡು',
'ಗಿಡುತಿಂಗಿ',
'ಗಿಡುಬಿಡಿ',
'ಗಿಡ್ಡಂಗಡಿ',
'ಗಿಡ್ಡಕಾಳು',
'ಗಿಡ್ಡತನ',
'ಗಿಡ್ಡದುಡ್ಡು',
'ಗಿಡ್ಡರಾಗಿ',
'ಗಿಣಿಗಿಣಿ',
'ಗಿಣಿದೇರ',
'ಗಿಣಿನುಡಿ',
'ಗಿಣಿಪಾಠ',
'ಗಿಣಿಮಾವು',
'ಗಿಣಿಮೂಗ',
'ಗಿಣಿಮೂಗಿ',
'ಗಿಣಿಮೂಗು',
'ಗಿಣಿಮೂತಿ',
'ಗಿಣಿರಾಮ',
'ಗಿಣಿವೋದು',
'ಗಿಣ್ಣುಗೊಳ್',
'ಗಿಣ್ಣುವಾಲು',
'ಗಿಣ್ಣುಹಂಚಿ',
'ಗಿಣ್ಣುಹಾಲು',
'ಗಿದ್ದಗಾಣಿ',
'ಗಿನಿಹುಲ್ಲು',
'ಗಿಮಿಗಿಮಿ',
'ಗಿರಕಾಲು',
'ಗಿರಕಾಲೆ',
'ಗಿರಕ್ಕನೆ',
'ಗಿರಗಟೆ',
'ಗಿರಗಟ್ಟೆ',
'ಗಿರಪಾವು',
'ಗಿರಬ್ದಾರಿ',
'ಗಿರಮಿಟ್',
'ಗಿರಹಿಡಿ',
'ಗಿರಾಯಿಕ',
'ಗಿರಾಯಿಸು',
'ಗಿರಿಕನ್ನೆ',
'ಗಿರಿಕರ್ಣಿ',
'ಗಿರಿಕುಲ',
'ಗಿರಿಕೂಟ',
'ಗಿರಿಗಟ',
'ಗಿರಿಗಿರಿ',
'ಗಿರಿಚರ',
'ಗಿರಿಜನ',
'ಗಿರಿಜಾತೆ',
'ಗಿರಿಜೇಶ',
'ಗಿರಿತಲ',
'ಗಿರಿದುರ್ಗ',
'ಗಿರಿಧನ್ವ',
'ಗಿರಿಧರ',
'ಗಿರಿಧಾಮ',
'ಗಿರಿಮಲ್ಲ',
'ಗಿರಿಮಿಟ್ಟು',
'ಗಿರಿಯಜ್ಞ',
'ಗಿರಿರಾಜ',
'ಗಿರಿರಿಪು',
'ಗಿರಿವರ',
'ಗಿರಿಶೃಂಗ',
'ಗಿರಿಶ್ರೇಣಿ',
'ಗಿರಿಸಾನು',
'ಗಿರಿಸಾರ',
'ಗಿರಿಸುತ',
'ಗಿರಿಸುತೆ',
'ಗಿರುಕಿಸು',
'ಗಿರುಗಟೆ',
'ಗಿರೇಬಾಜ',
'ಗಿರ್ಕಿಮೆಟ್ಟು',
'ಗಿರ್ಲುಮೀಸೆ',
'ಗಿಲಗಂಚಿ',
'ಗಿಲಗಂಜಿ',
'ಗಿಲಗಿಜಿ',
'ಗಿಲಿಗಿಂಚಿ',
'ಗಿಲಿಗಿಂಚೆ',
'ಗಿಲಿಗಿಚ್ಚಿ',
'ಗಿಲಿಗಿಲಿ',
'ಗಿಲಿಗಿಸು',
'ಗಿಲಿಬಿಲಿ',
'ಗಿಳಂಕೃತ',
'ಗಿಳಿಗರ್ಚು',
'ಗಿಳಿದೇರ',
'ಗಿಳಿನುಡಿ',
'ಗಿಳಿಪಾಠ',
'ಗಿಳಿಮಾತು',
'ಗಿಳಿಮಾವು',
'ಗಿಳಿಮೂಗ',
'ಗಿಳಿಮೂಗಿ',
'ಗಿಳಿಮೂಗು',
'ಗಿಳಿಯೋದು',
'ಗಿಳಿರಾಮ',
'ಗಿಳಿವಣ್ಣ',
'ಗಿಳಿವಱಿ',
'ಗಿಳಿವಾತು',
'ಗಿಳಿವೆಣ್',
'ಗಿಱಿಗಿಱಿ',
'ಗಿಱಱಗೆ',
'ಗಿಱಱನೆ',
'ಗಿಱೆಱನ್',
'ಗೀಕುಚಾಪೆ',
'ಗೀಗೀಪದ',
'ಗೀಚುಗೆರೆ',
'ಗೀಜುಗಣ್ಣು',
'ಗೀಜುಗಾರ',
'ಗೀಜುವಾಯ',
'ಗೀತಕಾರ',
'ಗೀತಕ್ರಮ',
'ಗೀತಗೋಷ್ಠಿ',
'ಗೀತವಾತು',
'ಗೀತಿಕಾವ್ಯ',
'ಗೀತಿವಿದ',
'ಗೀರುಕೋಲು',
'ಗೀರುಗಂಧ',
'ಗೀರುಬಳೆ',
'ಗೀರ್ಕಡಗ',
'ಗೀರ್ವಾಣತ್ವ',
'ಗೀಳುಹಿಡಿ',
'ಗುಂಕರಿಸು',
'ಗುಂಗಿಮಾವು',
'ಗುಂಗಿಹುಳು',
'ಗುಂಗುಣಿಸು',
'ಗುಂಗುಮಿಸು',
'ಗುಂಗೆಮಾವು',
'ಗುಂಗೆಹುಳು',
'ಗುಂಜಗಾನ',
'ಗುಂಜತ್ಕುಂಜ',
'ಗುಂಜರಿಸು',
'ಗುಂಜಾಫಳ',
'ಗುಂಜಾಯಿಷಿ',
'ಗುಂಜಾರವ',
'ಗುಂಜಾಲಯ',
'ಗುಂಜಾಲಾಡು',
'ಗುಂಜಿಬೀಜ',
'ಗುಂಜಿಸರಿ',
'ಗುಂಜುಗಂಟು',
'ಗುಂಜುಬೀಳು',
'ಗುಂಟವಲೆ',
'ಗುಂಡಕಲ್ಲು',
'ಗುಂಡಕ್ರಿಯೆ',
'ಗುಂಡಬಾವಿ',
'ಗುಂಡಲಿಗೆ',
'ಗುಂಡಳಿಗೆ',
'ಗುಂಡಾಂತರ',
'ಗುಂಡಾಕ್ರಿಯೆ',
'ಗುಂಡಾಗಿರಿ',
'ಗುಂಡಾಗುಂಡಿ',
'ಗುಂಡಾಡಿಸು',
'ಗುಂಡಾಸಿನಿ',
'ಗುಂಡಾಳಿಸು',
'ಗುಂಡಿಕಾಯಿ',
'ಗುಂಡುಕಲ್ಲು',
'ಗುಂಡುಗಲ್',
'ಗುಂಡುಗಲ್ಲು',
'ಗುಂಡುಗೊಲೆ',
'ಗುಂಡುಗೊಳ್',
'ಗುಂಡುಗೋವಿ',
'ಗುಂಡುಜಾಜಿ',
'ಗುಂಡುತೋಪು',
'ಗುಂಡುದೀವು',
'ಗುಂಡುನಲ್ಲೆ',
'ಗುಂಡುಪಿನ್ನು',
'ಗುಂಡುವಡೆ',
'ಗುಂಡುವಲೆ',
'ಗುಂಡುಸೂಜಿ',
'ಗುಂಡುಹಾಕು',
'ಗುಂಡುಹುಲ್ಲು',
'ಗುಂಡುಹೊಡೆ',
'ಗುಂಡೋದರ',
'ಗುಂದ್ರಯಿಸು',
'ಗುಂಪುಕಟ್ಟು',
'ಗುಂಪುಕೂಡು',
'ಗುಂಪುಗಾರ',
'ಗುಂಪುಗುಳಿ',
'ಗುಂಪುಗೂಡು',
'ಗುಂಪುಗೊಳ್ಳು',
'ಗುಂಪುದೆಗೆ',
'ಗುಂಪುವಡೆ',
'ಗುಂಪುಸೇರು',
'ಗುಂಬಕಟ್ಟು',
'ಗುಂಬಗುೞಿ',
'ಗುಂಬಮಾತು',
'ಗುಂಬವಾದ್ಯ',
'ಗುಂಬಹೋಗು',
'ಗುಂಬಳಕಿ',
'ಗುಂಬಾರತಿ',
'ಗುಂಭಮೌನ',
'ಗುಂಭಾವನೆ',
'ಗುಂಯಿಗುಟ್ಟು',
'ಗುಂಯಿಗುಡು',
'ಗುಂಯ್‍ಗುಡು',
'ಗುಕುಗುಕು',
'ಗುಕ್ಕುಹಿಡಿ',
'ಗುಗ್ಗುಟುರು',
'ಗುಗ್ಗುರಿಸು',
'ಗುಗ್ಗುಳಕ',
'ಗುಚ್ಛಕಂದ',
'ಗುಚ್ಛಪತ್ರ',
'ಗುಚ್ಛಬಂಧ',
'ಗುಚ್ಛಹೊಂಗೆ',
'ಗುಚ್ಛಹೊನ್ನೆ',
'ಗುಜಗುಜ',
'ಗುಜರಣೆ',
'ಗುಜರಾತ',
'ಗುಜರಾತು',
'ಗುಜರಾಥಿ',
'ಗುಜರಾನ್',
'ಗುಜರಾನು',
'ಗುಜಿಗುಜಿ',
'ಗುಜುಗುಜಿ',
'ಗುಜುಗುಜು',
'ಗುಜುಗುಟ್ಟು',
'ಗುಜುಱೇಳ್',
'ಗುಜ್ಜತನ',
'ಗುಜ್ಜಬಾಳೆ',
'ಗುಜ್ಜರಾತಿ',
'ಗುಜ್ಜರಾಷ್ಟ್ರ',
'ಗುಜ್ಜುಕತ್ತಿ',
'ಗುಜ್ಜುಕಾಲು',
'ಗುಜ್ಜುಕೋಲು',
'ಗುಜ್ಜುಗರೆ',
'ಗುಜ್ಜುಗಾಲ್',
'ಗುಜ್ಜುಗೆಜ್ಜೆ',
'ಗುಜ್ಜುಗೊಂಬು',
'ಗುಜ್ಜುಗೊಂಬೆ',
'ಗುಜ್ಜುಗೊಳ್',
'ಗುಜ್ಜುಗೋಲು',
'ಗುಜ್ಜನಡೆ',
'ಗುಜ್ಜುಬಂಕ',
'ಗುಜ್ಜುಮೆಟ್ಟು',
'ಗುಜ್ಜುವಜ್ಜೆ',
'ಗುಜ್ಜುವಡೆ',
'ಗುಜ್ಜುಹೆಜ್ಜೆ',
'ಗುಟಾಯಿಸು',
'ಗುಟುಕಿಡು',
'ಗುಟುಕಿಸು',
'ಗುಟುಕ್ಕನೆ',
'ಗುಟುಗುಟು',
'ಗುಟರಿಕ್ಕು',
'ಗುಟುರಿಡು',
'ಗುಟ್ಟರಿಕೆ',
'ಗುಟ್ಟಿಗಟ್ಟು',
'ಗುಟ್ಟುಗಟ್ಟು',
'ಗುಟ್ಟುಗಾರ',
'ಗುಟ್ಟುಗೊಲೆ',
'ಗುಟ್ಟುನೆರೆ',
'ಗುಟ್ಟುಮಾಡು',
'ಗುಟ್ಟುಮಾತು',
'ಗುಡಗುಡಿ',
'ಗುಡಪಾಕ',
'ಗುಡಪಾನ',
'ಗುಡಪಾಲ',
'ಗುಡಪುಷ್ಪ',
'ಗುಡಫಲ',
'ಗುಡವಾಣ',
'ಗುಡಹಾಲೆ',
'ಗುಡಾಕೇಶ',
'ಗುಡಾಳಕ',
'ಗುಡಿಕಟ್ಟು',
'ಗುಡಿಕಾರ',
'ಗುಡಿಗಟ್ಟು',
'ಗುಡಿಗಾರ',
'ಗುಡಿಗುಡಿ',
'ಗುಡಿಗುಮ್ಮ',
'ಗುಡಿಗೂಱು',
'ಗುಡಿಗೊಳ್',
'ಗುಡಿಚಾಪೆ',
'ಗುಡಿದೇವ',
'ಗುಡಿಬಿಡು',
'ಗುಡಿಮೊನೆ',
'ಗುಡಿಯಿಸು',
'ಗುಡಿಯಿಱಿ',
'ಗುಡಿವಣ',
'ಗುಡಿವಿಡಿ',
'ಗುಡಿವ್ರಜ',
'ಗುಡಿಸಲ್',
'ಗುಡಿಸಲು',
'ಗುಡಿಸಿಲ್',
'ಗುಡಿಸಿಲು',
'ಗುಡಿಸೆಟ್ಟಿ',
'ಗುಡಿಹುಲ್ಲು',
'ಗುಡುಗಾಟ',
'ಗುಡುಗಾಡು',
'ಗುಡುಗಿಸು',
'ಗುಡುಗುಟ್ಟು',
'ಗುಡುಗುಡಿ',
'ಗುಡುಗುಡು',
'ಗುಡುಗೂರು',
'ಗುಡುಗೂಱು',
'ಗುಡುವಣ',
'ಗುಡುವನ',
'ಗುಡುಸಲು',
'ಗುಡುಸೆಟ್ಟಿ',
'ಗುಡೋದಕ',
'ಗುಡ್ಡಕಾಡು',
'ಗುಡ್ಡಗಾಡು',
'ಗುಡ್ಡಗೊಂಬು',
'ಗುಡ್ಡದುಂಬೆ',
'ಗುಡ್ಡರೆಂಜೆ',
'ಗುಡ್ಡವೋಮ',
'ಗುಡ್ಡಸೋಂಪು',
'ಗುಡ್ಡೆಗಣ್ಣು',
'ಗುಡ್ಡೆಣಿಕೆ',
'ಗುಡ್ಡೆಣಿಸು',
'ಗುಣಂಗೆಡು',
'ಗುಣಂಗೊಳ್',
'ಗುಣಕರ',
'ಗುಣಕಾರ',
'ಗುಣಕಾರಿ',
'ಗುಣಗರ್ಭ',
'ಗುಣಗಾನ',
'ಗುಣಗುಟ್ಟು',
'ಗುಣಗೇಡಿ',
'ಗುಣಗ್ರಾಹಿ',
'ಗುಣತ್ರಯ',
'ಗುಣಧರ್ಮ',
'ಗುಣಧಾಮ',
'ಗುಣಧಾಮೆ',
'ಗುಣನಾಮ',
'ಗುಣನಿಧಿ',
'ಗುಣಪಡು',
'ಗುಣಪೃಷ್ಠ',
'ಗುಣಪ್ರಭ',
'ಗುಣಭಾಜ',
'ಗುಣಮಣಿ',
'ಗುಣಮುಖ',
'ಗುಣಮೂಲಿ',
'ಗುಣಯುತ',
'ಗುಣಯುತೆ',
'ಗುಣರತ್ನ',
'ಗುಣಲಬ್ಛ',
'ಗುಣವಂತ',
'ಗುಣವಂತಿ',
'ಗುಣವಂತೆ',
'ಗುಣವತಿ',
'ಗುಣವರ್ಮ',
'ಗುಣವಾಚಿ',
'ಗುಣವಾದ',
'ಗುಣವೃತ್ತಿ',
'ಗುಣವೃದ್ಧಿ',
'ಗುಣವ್ಯಾಪ್ತಿ',
'ಗುಣಶಾಲಿ',
'ಗುಣಸಂಗಿ',
'ಗುಣಸಂಧಿ',
'ಗುಣಸಾಂದ್ರ',
'ಗುಣಸ್ತವ',
'ಗುಣಹೀನ',
'ಗುಣಹೀನೆ',
'ಗುಣಾಂತರ',
'ಗುಣಾಕಾರ',
'ಗುಣಾಕೃತಿ',
'ಗುಣಾತೀತ',
'ಗುಣಾತೀತೆ',
'ಗುಣಾತ್ಮಕ',
'ಗುಣಾಧಿಕ',
'ಗುಣಾಧೇಯ',
'ಗುಣಾಮರ',
'ಗುಣಾಷ್ಟಕ',
'ಗುಣಿಕಟ್ಟು',
'ಗುಣಿಗಣ್ಣು',
'ಗುಣಿಗಲ್ಲ',
'ಗುಣಿಗಲ್ಲು',
'ಗುಣಿಯಿಸು',
'ಗುಣಿಶಾಸ್ತ್ರ',
'ಗುಣಿಹೊಗು',
'ಗುಣಿಹೊಡೆ',
'ಗುಣೀಕೃತ',
'ಗುಣೀಭೂತ',
'ಗುಣುಗುಟ್ಟು',
'ಗುಣುಗುಣು',
'ಗುಣೋತ್ಕರ',
'ಗುಣೋದಯ',
'ಗುಣೋದೀರ್ಣ',
'ಗುಣ್ಪುಗೆಡು',
'ಗುಣ್ಪುವಡೆ',
'ಗುಣ್ಪುವೆಱು',
'ಗುತ್ತಾದಾರ',
'ಗುತ್ತುಮಾಲೆ',
'ಗುತ್ತೆದಾರ',
'ಗುದಕಾಮ',
'ಗುದಕಾಸ',
'ಗುದದ್ವಾರ',
'ಗುದನಾಳ',
'ಗುದಭ್ರಂಶ',
'ಗುದವಾತ',
'ಗುದಸ್ಥಾನ',
'ಗುದಾಂಕುರ',
'ಗುದಿಕಟ್ಟು',
'ಗುದಿಕಾಲು',
'ಗುದಿಗಾಲ್',
'ಗುದಿಗಾಲು',
'ಗುದಿಗೆಯ್',
'ಗುದ್ದರಿಸು',
'ಗುದ್ದಲಿಸು',
'ಗುದ್ದುಕಂಬ',
'ಗುದ್ದುಗಂಬ',
'ಗುದ್ದುಗಣ್ಣು',
'ಗುದ್ದುಮಳಿ',
'ಗುದ್ದುಮೊಳ',
'ಗುಧಂಬುಳು',
'ಗುನಾಹದ್ದು',
'ಗುನಿಗಡಿ',
'ಗುನಿಗಂಜಿ',
'ಗುನಿದೊಣ್ಣೆ',
'ಗುನುಗುನು',
'ಗುನ್ನಂಪಟ್ಟಿ',
'ಗುನ್ನಂಪಟ್ಟೆ',
'ಗುನ್ನಾಂಪಟ್ಟಿ',
'ಗುನ್ನಾಂಪಟ್ಟೆ',
'ಗುನ್ನೆಗಾರಿ',
'ಗುನ್ನೇಗಾರ',
'ಗುನ್ನೇದಾರ',
'ಗುನ್ಹೇಗಾರ',
'ಗುಪ್ತಕಾಂಡ',
'ಗುಪ್ತಕೂಟ',
'ಗುಪ್ತಗಂಧಿ',
'ಗುಪ್ತಗಾಮಿ',
'ಗುಪ್ತಚರ',
'ಗುಪ್ತಚಾರ',
'ಗುಪ್ತಜಲ',
'ಗುಪ್ತದ್ವಾರ',
'ಗುಪ್ತನಾಮ',
'ಗುಪ್ತನಿಧಿ',
'ಗುಪ್ತಪುಷ್ಪಿ',
'ಗುಪ್ತಭಾಷೆ',
'ಗುಪ್ತರೋಗ',
'ಗುಪ್ತರೋಮ',
'ಗುಪ್ತಲಿಪಿ',
'ಗುಪ್ತವೇಷ',
'ಗುಪ್ತವೇಷಿ',
'ಗುಪ್ತಹೇರ',
'ಗುಪ್ತಿಗೆಯ್',
'ಗುಪ್ತಿತ್ರಯ',
'ಗುಪ್ತಿವಾದ',
'ಗುಪ್ಪೆಗೊಳ್',
'ಗುಬರಿಸು',
'ಗುಬಲಕ್ಕಿ',
'ಗುಬುಕಟ್ಟು',
'ಗುಬುಗುಬು',
'ಗುಬ್ಬಚ್ಚಿಹೂ',
'ಗುಬ್ಬಳಿಕೆ',
'ಗುಬ್ಬಳಿಸು',
'ಗುಬ್ಬಿಕದ',
'ಗುಬ್ಬಿಗೂಡು',
'ಗುಬ್ಬಿಚಟ್ಟು',
'ಗುಬ್ಬಿದೀಪ',
'ಗುಬ್ಬಿನಖ',
'ಗುಬ್ಬಿಬಳೆ',
'ಗುಬ್ಬಿಬಾಳೆ',
'ಗುಬ್ಬಿಬೇಳೆ',
'ಗುಬ್ಬಿಮನೆ',
'ಗುಬ್ಬಿಮುಳ್ಳು',
'ಗುಬ್ಬಿಮೊಳೆ',
'ಗುಬ್ಬಿಸೌತೆ',
'ಗುಬ್ಬಿಹಕ್ಕಿ',
'ಗುಬ್ಬಿಹುಲ್ಲು',
'ಗುಮಚಕ್ಕಿ',
'ಗುಮಾನಿಸು',
'ಗುಮಾಸ್ತಿಕೆ',
'ಗುಮಿಗೂಡು',
'ಗುಮುಗುಮು',
'ಗುಮ್ಮಟಿಗ',
'ಗುಮ್ಮಟೇಶ',
'ಗುಮ್ಮತನ',
'ಗುಮ್ಮವಕ್ಕಿ',
'ಗುಮ್ಮಳಿಸು',
'ಗುಮ್ಮಿಮನೆ',
'ಗುಮ್ಮುಗಟ್ಟು',
'ಗುಮ್ಮುಹಾಕು',
'ಗುರವಡಿ',
'ಗುರಿಕಟ್ಟು',
'ಗುರಿಕಾರ',
'ಗುರಿಗಾರ',
'ಗುರಿಗುತ್ತು',
'ಗುರಿತನ',
'ಗುರಿತಪ್ಪು',
'ಗುರಿತಾಕು',
'ಗುರಿನೋಡು',
'ಗುರಿಬಿಡು',
'ಗುರಿಮಾಡು',
'ಗುರಿಮುಟ್ಟು',
'ಗುರಿಯಾಗು',
'ಗುರಿಯಿಕ್ಕು',
'ಗುರಿಯಿಡು',
'ಗುರಿಯೆಳ್ಳು',
'ಗುರಿವೆರು',
'ಗುರಿಹಿಡಿ',
'ಗುರಿಹೊಡೆ',
'ಗುರುಋಣ',
'ಗುರುಕಿಡು',
'ಗುರುಕುಚ',
'ಗುರುಕುಚೆ',
'ಗುರುಕುಲ',
'ಗುರುಕ್ರಮ',
'ಗುರುಗಂಜಿ',
'ಗುರುಗಡೆ',
'ಗುರುಗಾತ್ರ',
'ಗುರುಗುಂಜಿ',
'ಗುರುಗುಂಜು',
'ಗುರುಗುಂಜೆ',
'ಗುರುಗುಟ್ಟು',
'ಗುರುಗುಮ್ಮ',
'ಗುರುಗುಲು',
'ಗುರುಚರ',
'ಗುರುಚಿಹ್ನೆ',
'ಗುರುಜನ',
'ಗುರುತನ',
'ಗುರುತರ',
'ಗುರುತಲ್ಪ',
'ಗುರುತಿಡು',
'ಗುರುತಿಸು',
'ಗುರುತುಗ',
'ಗುರುತ್ವಾಂಕ',
'ಗುರುದ್ರೋಹ',
'ಗುರುದ್ರೋಹಿ',
'ಗುರುದ್ವಾರ',
'ಗುರುದ್ವೇಷ',
'ಗುರುಪಥ',
'ಗುರುಪಾಡೆ',
'ಗುರುಬಂಧು',
'ಗುರುಬಲ',
'ಗುರುಬ್ರುವ',
'ಗುರುಭಾರ',
'ಗುರುಮಂತ್ರ',
'ಗುರುಮಠ',
'ಗುರುಮಣಿ',
'ಗುರುಮನೆ',
'ಗುರುಮುಖ',
'ಗುರುರಾಯ',
'ಗುರುಲಿಂಗ',
'ಗುರುವರ್ಯ',
'ಗುರುವರ್ಯೆ',
'ಗುರುವಲೆ',
'ಗುರುವಾಡೆ',
'ಗುರುವಾರ',
'ಗುರುಶೇಷ',
'ಗುರುಸೇವೆ',
'ಗುರೂಪಧೆ',
'ಗುರೂಪಾಂತ',
'ಗುರ್ಜುಗೊಳ್',
'ಗುಲಂದಾಜ',
'ಗುಲಕಂದ',
'ಗುಲಗಂಜಿ',
'ಗುಲಗಂಜೆ',
'ಗುಲಗುಂಜಿ',
'ಗುಲಗುಂಜೆ',
'ಗುಲಬಾಕ್ಷಿ',
'ಗುಲಿಕಲು',
'ಗುಲಿಗಂಜಿ',
'ಗುಲಿಮಾವು',
'ಗುಲುಂಛನ',
'ಗುಲುಗಂಜಿ',
'ಗುಲುಗುಂಜಿ',
'ಗುಲ್ಮಗಂಧಿ',
'ಗುಲ್ಮಜ್ವರ',
'ಗುಲ್ಮಮುಖ',
'ಗುಲ್ಮವೃದ್ಧಿ',
'ಗುಲ್ಮಶೂಲೆ',
'ಗುಲ್ಮಹರ್',
'ಗುಲ್ಮೊಹರ್',
'ಗುಲ್ಮೊಹೊರ್',
'ಗುಲ್ಮೊಹೋರ್',
'ಗುಲ್ಲಕಂಚಿ',
'ಗುಲ್ಲೆಬ್ಬಿಸು',
'ಗುಸುಗಾಟ',
'ಗುಸುಗುಂಪು',
'ಗುಸುಗುಟ್ಟು',
'ಗುಸುಗುಸು',
'ಗುಸುನಗೆ',
'ಗುಸುಮುಸಿ',
'ಗುಹಾಂಗಣ',
'ಗುಹಾಂತರ',
'ಗುಹಾಧ್ವನಿ',
'ಗುಹಾಲಯ',
'ಗುಹಾಲಿಪಿ',
'ಗುಹಾಲೇಖ',
'ಗುಹಾಶಾಸ್ತ್ರ',
'ಗುಹಾಹಿತ',
'ಗುಹಿಲಿಕ್ಕು',
'ಗುಹ್ನೇಗಾರ',
'ಗುಹ್ಯರೋಗ',
'ಗುಳಕವ್ವ',
'ಗುಳದಾಳಿ',
'ಗುಳಪುಟ್ಟಿ',
'ಗುಳವತ್ತಿ',
'ಗುಳಿಗಣ್ಣು',
'ಗುಳಿಗದ್ದೆ',
'ಗುಳಿಬೀಳು',
'ಗುಳಿಮಣೆ',
'ಗುಳುಂಗಾರ',
'ಗುಳುಕನೆ',
'ಗುಳುಕ್ಕನೆ',
'ಗುಳುಗುಳು',
'ಗುಳುದಾಳಿ',
'ಗುಳೂರಿಗೆ',
'ಗುಳೆಕಟ್ಟು',
'ಗುಳೆಕಾರ',
'ಗುಳೆಕೀಳು',
'ಗುಳೆಗಟ್ಟು',
'ಗುಳೆತೆಗೆ',
'ಗುಳೆಬೀಳು',
'ಗುಳೆಯೇಳು',
'ಗುಳೆಹೋಗು',
'ಗುಳ್ಳಗಂಜಿ',
'ಗುಳ್ಳಪಟೆ',
'ಗುಳ್ಳಿಗಣ್ಣ',
'ಗುಳ್ಳಿಗಣ್ಣು',
'ಗುಳ್ಳುಗಾವಿ',
'ಗುಳ್ಳೆಕಂಕ',
'ಗುಳ್ಳೆಗಣ್ಣ',
'ಗುಳ್ಳೆಗಣ್ಣು',
'ಗುಳ್ಳೆಗೊಟ್ಟಿ',
'ಗುಳ್ಳೆನರಿ',
'ಗುಳ್ಳೆಮುತ್ತು',
'ಗುಳ್ಳೆರೋಗ',
'ಗುಳ್ಳೆಶಂಖ',
'ಗುಳ್ಳೋರಿಗೆ',
'ಗುಱಿಕಾಱ',
'ಗುಱಿಗಾಣ್',
'ಗುಱಿಮಾಡು',
'ಗುಱಿಯಾಗು',
'ಗುಱಿಯಾಳ್',
'ಗುಱಿಯಾಳು',
'ಗುಱಿಯಿಕ್ಕು',
'ಗುಱಿಯೆಸು',
'ಗುಱಿಯೆಸೆ',
'ಗುಱಿಯೇಸು',
'ಗುಱಿಯೊಡ್ಡು',
'ಗುಱಿವಡೆ',
'ಗುಱುಕಿಡು',
'ಗುಱುಗುಟ್ಟು',
'ಗುೞಿಗಣ್',
'ಗುೞುಗುೞು',
'ಗುೞುಮ್ಮನೆ',
'ಗೂಂಕರಿಸು',
'ಗೂಂಟವಲೆ',
'ಗೂಂಡಾಗಿರಿ',
'ಗೂಗರಿಸು',
'ಗೂಗೆಹೂವು',
'ಗೂಟಕೋಲು',
'ಗೂಟಗಳ್ಳಿ',
'ಗೂಟನಾಮ',
'ಗೂಟವಲೆ',
'ಗೂಟೆಗೇರು',
'ಗೂಡಾಡಿಸು',
'ಗೂಡಿರಿಸು',
'ಗೂಡುಕಟ್ಟು',
'ಗೂಡುಗಟ್ಟು',
'ಗೂಡುಗೀಲು',
'ಗೂಡುಗೊಳ್',
'ಗೂಡುದೀಪ',
'ಗೂಡುಪುಷ್ಪ',
'ಗೂಡುಬಡಿ',
'ಗೂಡುಮಾಡು',
'ಗೂಡುಮಿಟ್ಲಿ',
'ಗೂಡುಮುರಿ',
'ಗೂಡುಮುರ್ಕಿ',
'ಗೂಡುವಲೆ',
'ಗೂಡುವಾಯ್',
'ಗೂಡುವೋಗು',
'ಗೂಡುಹುಳು',
'ಗೂಡೆತೆಪ್ಪ',
'ಗೂಡೆಯೆಱೆ',
'ಗೂಢಗೆಡು',
'ಗೂಢಚರ',
'ಗೂಢಚರ್ಯೆ',
'ಗೂಢಚಾರ',
'ಗೂಢಚಾರಿ',
'ಗೂಢಜತ್ರು',
'ಗೂಢಪದ',
'ಗೂಢಪಾದ',
'ಗೂಢಭಾಷೆ',
'ಗೂಢಲಿಪಿ',
'ಗೂಢಲೇಖ',
'ಗೂಢಸಂಧಿ',
'ಗೂಧದ್ವಾರ',
'ಗೂನುಬೆನ್ನು',
'ಗೂಬೆಗಣ್ಣು',
'ಗೂಬೆತಡೆ',
'ಗೂಬೆಬಾಳು',
'ಗೂಲಿಮಾವು',
'ಗೂಳಿಹೋರಿ',
'ಗೂಳೆಕಾರ',
'ಗೂಳೆಮೀನು',
'ಗೂಳೆಯೇಳು',
'ಗೂಱುಮಾಱು',
'ಗೃಧ್ರದೃಷ್ಟಿ',
'ಗೃಹಕೃತ್ಯ',
'ಗೃಹಕ್ಷಯ',
'ಗೃಹಖಾತೆ',
'ಗೃಹಗಾಹಿ',
'ಗೃಹಚರ',
'ಗೃಹಚ್ಛಿದ್ರ',
'ಗೃಹತ್ಯಾಗ',
'ಗೃಹದಾಸಿ',
'ಗೃಹಧರ್ಮ',
'ಗೃಹಪತಿ',
'ಗೃಹಪಿಕ',
'ಗೃಹಭಾನು',
'ಗೃಹಭೂಮಿ',
'ಗೃಹಭೇದಿ',
'ಗೃಹಮಂತ್ರಿ',
'ಗೃಹಮಾತೆ',
'ಗೃಹಮುಟ್ಟು',
'ಗೃಹಮೇಧ',
'ಗೃಹಮೇಧಿ',
'ಗೃಹರಕ್ಷೆ',
'ಗೃಹಲಕ್ಷ್ಮಿ',
'ಗೃಹವನ',
'ಗೃಹವಾಟಿ',
'ಗೃಹವೇದಿ',
'ಗೃಹವೈದ್ಯ',
'ಗೃಹಶಾಂತಿ',
'ಗೃಹಶಿಲ್ಪಿ',
'ಗೃಹಶೋಭೆ',
'ಗೃಹಸ್ಥಿಕೆ',
'ಗೃಹಸ್ಥೂಣ',
'ಗೃಹಾಂಗಣ',
'ಗೃಹಾಜಿರ',
'ಗೃಹಾಧಿಪ',
'ಗೃಹಾಧ್ಯಕ್ಷ',
'ಗೃಹಾರಾಮ',
'ಗೃಹಾಶ್ರಮ',
'ಗೃಹ್ಯಸೂತ್ರ',
'ಗೆಂಟಳವಿ',
'ಗೆಂಟಾಗಿಸು',
'ಗೆಂಡೆಮೀನ್',
'ಗೆಂಡೆಮೀನು',
'ಗೆಂಡೆಹೊಟ್ಟೆ',
'ಗೆಜ್ಜೆಕಟ್ಟು',
'ಗೆಜ್ಜೆಗಟ್ಟು',
'ಗೆಜ್ಜೆಗಾರ',
'ಗೆಜ್ಜೆಗಾಱ',
'ಗೆಜ್ಜೆಗಿಡ',
'ಗೆಜ್ಜೆಗುದಿ',
'ಗೆಜ್ಜೆಗೋಲು',
'ಗೆಜ್ಜೆಟಿಕೆ',
'ಗೆಜ್ಜೆಟಿಗೆ',
'ಗೆಜ್ಜೆಟೀಕೆ',
'ಗೆಜ್ಜೆಪಂತಿ',
'ಗೆಜ್ಜೆಪಟ್ಟಿ',
'ಗೆಜ್ಜೆಪಿಲ್ಲಿ',
'ಗೆಜ್ಜೆಪೂಜೆ',
'ಗೆಜ್ಜೆಬತ್ತಿ',
'ಗೆಜ್ಜೆಮೆಟ್ಟು',
'ಗೆಜ್ಜೆವಸ್ತ್ರ',
'ಗೆಜ್ಜೆಸರ',
'ಗೆಜ್ಜೆಹುಲ್ಲು',
'ಗೆಡೆಗಾಱ',
'ಗೆಡೆಗೂಡು',
'ಗೆಡೆಗೊಳ್',
'ಗೆಡೆಯಾಟ',
'ಗೆಡೆಯಿಡು',
'ಗೆಡೆವಕ್ಕಿ',
'ಗೆಡೆವಸು',
'ಗೆಡೆವೆಚ್ಚು',
'ಗೆಡ್ಡೆಗಟ್ಟು',
'ಗೆಣರವ',
'ಗೆಣಸಂಗು',
'ಗೆಣಸಿಂಗ',
'ಗೆಣೆಕಾತಿ',
'ಗೆಣೆಕಾರ',
'ಗೆಣೆಗಾರ',
'ಗೆಣೆತನ',
'ಗೆಣೆವಕ್ಕಿ',
'ಗೆಣೆಹಕ್ಕಿ',
'ಗೆದಲಿಗೆ',
'ಗೆಪ್ಪೆಸಸಿ',
'ಗೆಲಂಬಡೆ',
'ಗೆಲವಾನ್',
'ಗೆಲವಾಳ್',
'ಗೆಲವೆಣ್',
'ಗೆಲವೆಱು',
'ಗೆಲುಗಯ್ಯ',
'ಗೆಲುಮುಖ',
'ಗೆಲುಮೊಗ',
'ಗೆಲುವಡೆ',
'ಗೆಲ್ಲಂಗುಡು',
'ಗೆಲ್ಲಂಗೊಳ್',
'ಗೆಲ್ಲಂಬಡೆ',
'ಗೆಲ್ಲಕಾಱ',
'ಗೆಲ್ಲಗಂಬ',
'ಗೆಲ್ಲಗಾರ್ತಿ',
'ಗೆಲ್ಲಗಾಱ',
'ಗೆಲ್ಲಗುಳಿ',
'ಗೆಲ್ಲಗೈಯ',
'ಗೆಲ್ಲಗೊಳ್',
'ಗೆಲ್ಲತನ',
'ಗೆಲ್ಲವಱೆ',
'ಗೆಲ್ಲವಾಱು',
'ಗೆಲ್ಲವೆಣ್',
'ಗೆಲ್ಲಸೇಸೆ',
'ಗೆಲ್ಲುಗಂಬ',
'ಗೆಳೆಗಾರ್ತಿ',
'ಗೆಳೆಗೊಳ್',
'ಗೆಳೆಜೀವ',
'ಗೆಳೆತನ',
'ಗೆಳೆವಕ್ಕಿ',
'ಗೆಱೆಗೊಯ್',
'ಗೆಱೆಯಾಣೆ',
'ಗೇಂಡಾಮೃಗ',
'ಗೇಣಿಕಾಱ',
'ಗೇಣಿಗದ್ದೆ',
'ಗೇಣಿಗಾರ',
'ಗೇಣಿಚೀಟಿ',
'ಗೇಣಿಚೀಟು',
'ಗೇಣಿದಾರ',
'ಗೇಣುಕಾಱ',
'ಗೇಧಾಪದಿ',
'ಗೇಬೆಕಟ್ಟು',
'ಗೇಯರಸ',
'ಗೇಯವೃತ್ತ',
'ಗೇರಗೊಟ್ಟೆ',
'ಗೇರಾಯಿಸು',
'ಗೇರಿಹೊಡೆ',
'ಗೇರುಕಲ್ಲು',
'ಗೇರುಕುಕ್ಕೆ',
'ಗೇರುಕೊಮ್ಮೆ',
'ಗೇರುತೈಲ',
'ಗೇರುಪಪ್ಪು',
'ಗೇರುಬೀಜ',
'ಗೇರಮಾವು',
'ಗೇಹಕ್ರೀಡೆ',
'ಗೇಹಾಂಗಣ',
'ಗೇಹಾಜಿರ',
'ಗೇಹಾಶ್ರಮ',
'ಗೈರ್‍ವಿಲೆ',
'ಗೈರಖರ್ಚು',
'ಗೈರಮರ್ಜಿ',
'ಗೈರಶಿಸ್ತ',
'ಗೈರುಖರ್ಚು',
'ಗೈರುವಿಲೆ',
'ಗೊಂಕುಱಿಡು',
'ಗೊಂಚಲಿಸು',
'ಗೊಂಚಲ್ಮಿಂಚು',
'ಗೊಂಚಿಗಾರ',
'ಗೊಂಚುಗಾರ',
'ಗೊಂಟುಗೊಳ್',
'ಗೊಂಟುವೊಗು',
'ಗೊಂಡವನ',
'ಗೊಂಡಾರಣ್ಯ',
'ಗೊಂಡೆಚೌರಿ',
'ಗೊಂಡೆಹುಳು',
'ಗೊಂತುಕಂಬಿ',
'ಗೊಂತುಗಾರ',
'ಗೊಂತುಗೊಳ್',
'ಗೊಂತುಮಾಡು',
'ಗೊಂದಣಿಸು',
'ಗೊಂದಲಿಗ',
'ಗೊಂದಲಿಸು',
'ಗೊಂದಹಣ್ಣು',
'ಗೊಂದಳಿಸು',
'ಗೊಂದೆಬೊಂದಿ',
'ಗೊಂಬೆಯಾಟ',
'ಗೊಂಬೆರಂಭ',
'ಗೊಂಬೆರಾಮ',
'ಗೊಗ್ಗೇಶ್ವರ',
'ಗೊಜಗುಟ್ಟು',
'ಗೊಜಗೊಜ',
'ಗೊಜಿಬಿಜಿ',
'ಗೊಟಗುಣಿ',
'ಗೊಟಗೊಟ',
'ಗೊಟರ್ಗುಡು',
'ಗೊಟಾಯಿಸು',
'ಗೊಟ್ಟಂಗುಡಿ',
'ಗೊಟ್ಟಂಗೊಱೆ',
'ಗೊಟ್ಟಕೆರೆ',
'ಗೊಟ್ಟಗುಣಿ',
'ಗೊಟ್ಟಿಗಾಣ',
'ಗೊಟ್ಟಿಗಾಱ',
'ಗೊಟ್ಟಿಗೆಯ್',
'ಗೊಟ್ಟಿಗೇಯ',
'ಗೊಟ್ಟುಕಾಲ',
'ಗೊಟ್ಟುಗಟ್ಟು',
'ಗೊಟ್ಟುಗುಳಿ',
'ಗೊಟ್ಟುಗುೞಿ',
'ಗೊಟ್ಟುವಳ್ಳ',
'ಗೊಡಣಿಸು',
'ಗೊಡೆಗುಟ್ಟು',
'ಗೊಡೆಗೊಡೆ',
'ಗೊಡ್ಡಕಾರ',
'ಗೊಡ್ಡಕಾರ್ತಿ',
'ಗೊಡ್ಡಮಟೆ',
'ಗೊಡ್ಡಾಕಳ್',
'ಗೊಡ್ಡಾಕಳು',
'ಗೊಡ್ಡಾಚಾರ',
'ಗೊಡ್ಡೀಚಲು',
'ಗೊಡ್ಡುಗೇರು',
'ಗೊಡ್ಡುಮಂಜಿ',
'ಗೊಡ್ಡುಮಿಟ್ಲೆ',
'ಗೊಡ್ಡುಸಾರು',
'ಗೊಡ್ಡುಹುಳಿ',
'ಗೊಡ್ಡೆಱಗು',
'ಗೊಣಗಾಟ',
'ಗೊಣಗಾಡು',
'ಗೊಣಗುಟ್ಟು',
'ಗೊಣಗುಡು',
'ಗೊಣಗೊಣ',
'ಗೊಣ್ಣೆವುೞು',
'ಗೊಣ್ಣೆಹುಳು',
'ಗೊತ್ತುಕೂರು',
'ಗೊತ್ತುಗಾತಿ',
'ಗೊತ್ತುಗಾರ',
'ಗೊತ್ತುಗುರಿ',
'ಗೊತ್ತುಗೆಡು',
'ಗೊತ್ತುಗೆಯ್',
'ಗೊತ್ತುಪಡಿ',
'ಗೊತ್ತುಪಾಡು',
'ಗೊತ್ತುಮಾಡು',
'ಗೊತ್ತುಮುಟ್ಟು',
'ಗೊತ್ತುಮೆಟ್ಟು',
'ಗೊತ್ತುವಳಿ',
'ಗೊತ್ತುಹಾಕು',
'ಗೊದಮಟ್ಟೆ',
'ಗೊದಮೊಟ್ಟೆ',
'ಗೊದೆಕಟ್ಟು',
'ಗೊನಜಾಲಿ',
'ಗೊನಮರ',
'ಗೊನುಮರ',
'ಗೊನೆಕಾರ',
'ಗೊನೆಗಾರ',
'ಗೊನೆಗೊಳ್',
'ಗೊನೆಫಲ',
'ಗೊನೆಮಿಂಚು',
'ಗೊನೆವಣ್',
'ಗೊನೋರಿಯ',
'ಗೊನ್ನಿಹುಳು',
'ಗೊಮ್ಮಟೇಶ',
'ಗೊಯ್‍ಗುಟ್ಟು',
'ಗೊರಕಲು',
'ಗೊರಗುಡು',
'ಗೊರಟಿಗೆ',
'ಗೊರವಂಕ',
'ಗೊರವಂಕೆ',
'ಗೊರವಡಿ',
'ಗೊರವತಿ',
'ಗೊರವಬ್ಬೆ',
'ಗೊರವಿಕೆ',
'ಗೊರವಿತಿ',
'ಗೊಲಗಂಡಿ',
'ಗೊಲೆಗೆಯ್',
'ಗೊಲೆಗೊಳ್',
'ಗೊಲೆನೂಂಕು',
'ಗೊಲ್ಲಗಿತ್ತಿ',
'ಗೊಲ್ಲಣಿಗೆ',
'ಗೊಲ್ಲತನ',
'ಗೊಲ್ಲಭಾಮ',
'ಗೊಹೆಗುಟ್ಟು',
'ಗೊಳಗೊಂಡೆ',
'ಗೋಕಂಟಕ',
'ಗೋಕ್ಷುರಕ',
'ಗೋಗರೆತ',
'ಗೋಗಹಳೆ',
'ಗೋಗಿನಾರು',
'ಗೋಗ್ರಹಣ',
'ಗೋಚರಾವು',
'ಗೋಚರಿಯೆ',
'ಗೋಚರಿಸು',
'ಗೋಜಿಹ್ವಿಕೆ',
'ಗೋಟಡಕೆ',
'ಗೋಟಡಿಕೆ',
'ಗೋಟಾಡಿಸು',
'ಗೋಟುವಾದ್ಯ',
'ಗೋಡುಮಣ್ಣು',
'ಗೋಡುವಲೆ',
'ಗೋಡೆಗನ್ನ',
'ಗೋಣಂನಾಳಿ',
'ಗೋಣಿಚೀಲ',
'ಗೋಣಿತಟ್ಟು',
'ಗೋಣಿತಾಟು',
'ಗೋಣಿಪಟ್ಟೆ',
'ಗೋಣಿಯಾನ್',
'ಗೋಣಿಸೊಪ್ಪು',
'ಗೋಣುಗೊಯ್ಕ',
'ಗೋಣುಗೊಯ್ಕೆ',
'ಗೋಣುಮಾಱಿ',
'ಗೋಣುಮುರಿ',
'ಗೋಣ್ಣವಿರ್',
'ಗೋಣ್ಮೊಳಗು',
'ಗೋತಹಾಕು',
'ಗೋತಹೊಡೆ',
'ಗೋತಿತಿನ್ನು',
'ಗೋತಿಹೊಡೆ',
'ಗೋತುಹಾಕು',
'ಗೋತ್ರಧರ',
'ಗೋತ್ರಪತಿ',
'ಗೋತ್ರಭಿತ್ತು',
'ಗೋತ್ರವಧೆ',
'ಗೋತ್ರವೈರಿ',
'ಗೋತ್ರಸ್ಕಂಧ',
'ಗೋತ್ರಹರ',
'ಗೋತ್ರಾಂಕಿತ',
'ಗೋತ್ರಿತಯ',
'ಗೋದಣಿಕೆ',
'ಗೋದಣಿಗೆ',
'ಗೋದನಿಕೆ',
'ಗೋದರುಡು',
'ಗೋದಲಿಕೆ',
'ಗೋದಾರಣ',
'ಗೋದಾವರಿ',
'ಗೋದಿಗೆಂಪು',
'ಗೋದೋಹನ',
'ಗೋಧಾಪದಿ',
'ಗೋಧಿಕೃತ',
'ಗೋಧಿಸರ',
'ಗೋನಮರ',
'ಗೋನಿಕಾಯ',
'ಗೋನಿಯೋಗ',
'ಗೋನಿವಾಸಿ',
'ಗೋಪಟುತ್ವ',
'ಗೋಪರಸ',
'ಗೋಪರಾಗ',
'ಗೋಪರಿನಿ',
'ಗೋಪವಲ್ಲಿ',
'ಗೋಪಾನಸಿ',
'ಗೋಪಾಯನ',
'ಗೋಪಾಯಿತ',
'ಗೋಪಾಲಕ',
'ಗೋಪಾಲನೆ',
'ಗೋಪಾಳಕ',
'ಗೋಪಿತಾರ್ಥ',
'ಗೋಪುಕಾರ',
'ಗೋಪುಹೆಣೆ',
'ಗೋಪ್ರದಾನ',
'ಗೋಮಂಡಲ',
'ಗೋಮಂಡಳ',
'ಗೋಮಜ್ಜಿಗೆ',
'ಗೋಮನಾಳಿ',
'ಗೋಮೂತ್ರಕ',
'ಗೋಮೂತ್ರಿಕೆ',
'ಗೋಮೇದಕ',
'ಗೋಮೇದಿಕ',
'ಗೋಮೇಧಿಕ',
'ಗೋರಕ್ಷಕ',
'ಗೋರಕ್ಷಣ',
'ಗೋರಿಕಲ್',
'ಗೋರಿಕಲ್ಲು',
'ಗೋರಿಕಾಯಿ',
'ಗೋರಿಗಲ್',
'ಗೋರಿಗೊಳ್',
'ಗೋರಿಗೊಳು',
'ಗೋರಿಪಿಡಿ',
'ಗೋರಿಬಲೆ',
'ಗೋರಿಬೇಂಟೆ',
'ಗೋರಿಯಿಕ್ಕು',
'ಗೋರಿವಲೆ',
'ಗೋರಿವೇಂಟೆ',
'ಗೋರುಕೋಲು',
'ಗೋರುಮಣೆ',
'ಗೋರೋಚನ',
'ಗೋರೋಜನ',
'ಗೋರ್ಗುರಿಸು',
'ಗೋಲಂದಾಜ',
'ಗೋಲಂದಾಜು',
'ಗೋಲಂದಾಸ',
'ಗೋಲರಸ',
'ಗೋಲಸರ',
'ಗೋಲಾಂಗೂಲ',
'ಗೋಲಾಂಗೂಳ',
'ಗೋಲಾಕಾರ',
'ಗೋಲಿತೇವು',
'ಗೋಲಿಬಾರು',
'ಗೋಲೀಬಾರು',
'ಗೋಲೋಮಿಕೆ',
'ಗೋವಂದನಿ',
'ಗೋವಗೆಡ್ಡೆ',
'ಗೋವರ್ಧನ',
'ಗೋವಳತಿ',
'ಗೋವಳಿಗ',
'ಗೋವಳಿತಿ',
'ಗೋವುಳಿಗ',
'ಗೋವೆಬೀಜ',
'ಗೋಷ್ಠಾಗಾರ',
'ಗೋಷ್ಠಾಧ್ಯಕ್ಷ',
'ಗೋಷ್ಠಿಗಾನ',
'ಗೋಷ್ಠಿಗೆಯ್',
'ಗೋಸಂಪಿಗೆ',
'ಗೋಸಂಭವ',
'ಗೋಸಮಾಜ',
'ಗೋಸಮಿತಿ',
'ಗೋಸಮೃದ್ಧಿ',
'ಗೋಸಾಸಿಗ',
'ಗೋಸುಗಲಿ',
'ಗೋಸ್ಥಾನಕ',
'ಗೋಳಕಾಯಿ',
'ಗೋಳಜಾತ',
'ಗೋಳಯೋಗ',
'ಗೋಳಾಂಗೂಳ',
'ಗೋಳಾಕಾರ',
'ಗೋಳಾಗೋಳಿ',
'ಗೋಳಾಡಿಸು',
'ಗೋಳಾಯಿಲ',
'ಗೋಳಿಡಿಸು',
'ಗೋಳಿಪಲ್ಯ',
'ಗೋಳಿಪಲ್ಲೆ',
'ಗೋಳಿಸುಂಡೆ',
'ಗೋಳಿಸೊಪ್ಪು',
'ಗೋಳೀಬಾರು',
'ಗೋಳುಕರೆ',
'ಗೋಳುಗರೆ',
'ಗೋಳುಗುಟ್ಟು',
'ಗೋಳುಹುಯ್',
'ಗೋಳುಹೊಯ್',
'ಗೋಳ್ಗರೆತ',
'ಗೋಳ್ಗುಟ್ಟಿಸು',
'ಗೋಳ್ಗುಡಿಸು',
'ಗೋೞಯಿಲ',
'ಗೋೞಾಯಿಲ',
'ಗೋೞಿಡಿಸು',
'ಗೋೞೆಯಿಲ',
'ಗೌಂಡುಂಬಳಿ',
'ಗೌಡಪಟ್ಟು',
'ಗೌಡಮಾರ್ಗ',
'ಗೌಡರೀತಿ',
'ಗೌಡೀರೀತಿ',
'ಗೌಡುಂಬಳಿ',
'ಗೌಡುಮಾನ್ಯ',
'ಗೌದಾನಿಕ',
'ಗೌದಾಳಿಕ',
'ಗೌರವರ್ಣ',
'ಗೌರವಸ್ಥ',
'ಗೌರವಸ್ಥೆ',
'ಗೌರವಾರ್ಹ',
'ಗೌರವಾರ್ಹೆ',
'ಗೌರವಿಸು',
'ಗೌರಿಕುಲ',
'ಗೌರಿಜಡೆ',
'ಗೌರಿನೋಂಪಿ',
'ಗೌರಿಬಳ್ಳಿ',
'ಗೌರಿಬೀಜ',
'ಗೌರಿಮರ',
'ಗೌರಿಮುಡಿ',
'ಗೌರಿಹಬ್ಬ',
'ಗೌರೀಧವ',
'ಗೌರೀವೀಣೆ',
'ಗೌವುಂಡಿಕೆ',
'ಗೌಸಣಿಕೆ',
'ಗೌಸಣಿಗೆ',
'ಗೌಳಪಂತು',
'ಗೌಳಸರ',
'ಗೌಳಿಗಿತ್ತಿ',
'ಗೌಳಿಪಂತು',
'ಗೌಱುಕಾಳೆ',
'ಗೌಱುಗಾಳೆ',
'ಗ್ರಂಥಕರ್ತ',
'ಗ್ರಂಥಕರ್ತೃ',
'ಗ್ರಂಥಕಾರ',
'ಗ್ರಂಥಪರ್ಣಿ',
'ಗ್ರಂಥಪಾತ',
'ಗ್ರಂಥಪಾಲ',
'ಗ್ರಂಥಲಿಪಿ',
'ಗ್ರಂಥಸೂಚಿ',
'ಗ್ರಂಥಸ್ವಾಮ್ಯ',
'ಗ್ರಂಥಾಕ್ಷರ',
'ಗ್ರಂಥಾಲಯ',
'ಗ್ರಂಥಿದಳ',
'ಗ್ರಂಥಿಪರ್ಣ',
'ಗ್ರಂಥಿಶೂಲೆ',
'ಗ್ರಥಿಯಿಸು',
'ಗ್ರಹಕಲ್ಪ',
'ಗ್ರಹಕೂಟ',
'ಗ್ರಹಗತಿ',
'ಗ್ರಹಗ್ರಸ್ತ',
'ಗ್ರಹಗ್ರಸ್ತೆ',
'ಗ್ರಹಗ್ರಾಮ',
'ಗ್ರಹಚಾರ',
'ಗ್ರಹಪತಿ',
'ಗ್ರಹಪಥ',
'ಗ್ರಹಪೀಡೆ',
'ಗ್ರಹಬಲ',
'ಗ್ರಹಬಾಧೆ',
'ಗ್ರಹಭೀತ',
'ಗ್ರಹಭೀತೆ',
'ಗ್ರಹಮೈತ್ರ',
'ಗ್ರಹಮೈತ್ರಿ',
'ಗ್ರಹಯೋಗ',
'ಗ್ರಹಶಂಕೆ',
'ಗ್ರಹಶಾಂತಿ',
'ಗ್ರಹಸ್ವರ',
'ಗ್ರಾಮಕೂಟ',
'ಗ್ರಾಮಚಾರ',
'ಗ್ರಾಮಠಾಣೆ',
'ಗ್ರಾಮತಕ್ಕ',
'ಗ್ರಾಮತಕ್ಷ',
'ಗ್ರಾಮದಾನ',
'ಗ್ರಾಮದೆರೆ',
'ಗ್ರಾಮಬ್ರಯ',
'ಗ್ರಾಮಮೃಗ',
'ಗ್ರಾಮರಾಗ',
'ಗ್ರಾಮರುಹ',
'ಗ್ರಾಮವಳಿ',
'ಗ್ರಾಮವಾಸಿ',
'ಗ್ರಾಮಶಾಂತಿ',
'ಗ್ರಾಮಸಾರ',
'ಗ್ರಾಮಸಿಂಹ',
'ಗ್ರಾಮಾಂತರ',
'ಗ್ರಾಮಾಂತಿಕ',
'ಗ್ರಾಮಾದಾಯ',
'ಗ್ರಾಮಾಧಿಪ',
'ಗ್ರಾಮಾಫೋನು',
'ಗ್ರಾಮಾಹಾರ',
'ಗ್ರಾಮೇಯಕ',
'ಗ್ರಾಮೇಶ್ವರ',
'ಗ್ರಾಮೋದ್ಯೋಗ',
'ಗ್ರಾಮ್ಯಧರ್ಮ',
'ಗ್ರಾಮ್ಯಪಶು',
'ಗ್ರಾಮ್ಯಾಳಾಪ',
'ಗ್ರಾಸಗೊಳ್',
'ಗ್ರಾಸಗೊಳು',
'ಗ್ರಾಸವಾಸಿ',
'ಗ್ರಾಹಕತ್ವ',
'ಗ್ರಾಹಮರ',
'ಗ್ರೀಷ್ಮಋತು',
'ಗ್ರೀಷ್ಮಕಾಲ',
'ಗ್ರೀಷ್ಮಾವಾಸ',
'ಗ್ರೈವೇಯಕ',
'ಗ್ರೈಷ್ಮಾತಪ',
'ಘಂಟಾಘೋಷ',
'ಘಂಟಾನಾದ',
'ಘಂಟಾಪಥ',
'ಘಂಟಾಮಣಿ',
'ಘಂಟಾರವ',
'ಘಂಟಾರವೆ',
'ಘಂಟಾರುತಿ',
'ಘಟಕ್ಷುಧೆ',
'ಘಟಗೃಹ',
'ಘಟಚಕ್ರ',
'ಘಟಚೇಟಿ',
'ಘಟಜೀವಿ',
'ಘಟಫಣಿ',
'ಘಟಶ್ರಾದ್ಧ',
'ಘಟಸರ್ಪ',
'ಘಟಸ್ಫೋಟ',
'ಘಟಾಕಾಶ',
'ಘಟಾಟೋಪ',
'ಘಟಾನಳ',
'ಘಟಿಚಕ್ರ',
'ಘಟಿತಾರ್ಥ',
'ಘಟಿಯಾಱ',
'ಘಟಿಯಿಸು',
'ಘಟೀಯಂತ್ರ',
'ಘಟ್ಟವರೆ',
'ಘಟ್ಟಾಘಟ್ಟಿ',
'ಘಟ್ಟಿಗೊಳ್',
'ಘಟ್ಟಿತನ',
'ಘಟ್ಟಿವಳ',
'ಘಟ್ಟಿವಳ್ತಿ',
'ಘಟ್ಟಿವಳ್ಳ',
'ಘಟ್ಟಿವಾಳ',
'ಘಟ್ಟಿವಾಳ್ತಿ',
'ಘಟ್ಟಿವಾಳ್ತೆ',
'ಘಡಣಿಸು',
'ಘಡಾವಣೆ',
'ಘಣಘಣ',
'ಘನಂದಾರಿ',
'ಘನಕಲ್ಪ',
'ಘನಕಾಲ',
'ಘನಗ್ರಾವ',
'ಘನಘಟ್ಟಿ',
'ಘನಘೋಷ',
'ಘನತನ',
'ಘನತರ',
'ಘನದೀಪ್ತಿ',
'ಘನದೃಷ್ಟಿ',
'ಘನಧ್ವನಿ',
'ಘನಪಥ',
'ಘನಪಾಟಿ',
'ಘನಪಾಠ',
'ಘನಪಾಠಿ',
'ಘನಪುಷ್ಪ',
'ಘನಫಲ',
'ಘನಮಾರ್ಗ',
'ಘನಮೂಲ',
'ಘನರಸ',
'ಘನರಾಗ',
'ಘನವಂತ',
'ಘನವಾದಿ',
'ಘನವೇದ್ಯ',
'ಘನಶೃಂಗ',
'ಘನಶ್ಯಾಮ',
'ಘನಸಾರ',
'ಘನಸ್ತಿಕೆ',
'ಘನಸ್ವನ',
'ಘನಾಕೃತಿ',
'ಘನಾಗಮ',
'ಘನಾಘನ',
'ಘನಾನಂದ',
'ಘನಾನಕ',
'ಘನೀಕೃತ',
'ಘನೀಭಾವ',
'ಘನೀಭೂತ',
'ಘನೋದಧಿ',
'ಘನೋದ್ದೇಶ',
'ಘನೋಪಲ',
'ಘನೋಪಳ',
'ಘಮಘಮ',
'ಘಮಾಯಿಸು',
'ಘರಟೆಗೆ',
'ಘರವಟಿ',
'ಘರವಟ್ಟ',
'ಘರಿಕ್ಕನೆ',
'ಘರಿಘರಿ',
'ಘರಿಯಲ್',
'ಘರ್ಘರಿಕೆ',
'ಘರ್ಘರಿಸು',
'ಘರ್ಮಕಣ',
'ಘರ್ಮಕಾಲ',
'ಘರ್ಮಜಲ',
'ಘರ್ಮಬಿಂದು',
'ಘರ್ಮರುಚಿ',
'ಘರ್ಮವಾರಿ',
'ಘರ್ಮಾಂಭಸ',
'ಘರ್ಮೋದಕ',
'ಘಲ್‍ಘಲ್',
'ಘಲಕನೆ',
'ಘಸಕ್ಕನೆ',
'ಘಳಘಳ',
'ಘಳಿಯಾರ',
'ಘಳಿಯಿಸು',
'ಘಳಿಲನೆ',
'ಘಳಿಶಾಸ',
'ಘಳಿಸಾಸ',
'ಘಳಿಸಾಳಿ',
'ಘಱಘಱ್',
'ಘಱಿಘಱಿ',
'ಘಱಱನೆ',
'ಘೞಿಯಿಸು',
'ಘೞಿಲನೆ',
'ಘಾಟಳಿಸು',
'ಘಾಡತನ',
'ಘಾತಗಳೆ',
'ಘಾತಳಿಕೆ',
'ಘಾತಾಘಾತಿ',
'ಘಾತಾಳಿಕೆ',
'ಘಾತಿಕರ್ಮ',
'ಘಾತಿಕ್ಷಯ',
'ಘಾಬರಿಸು',
'ಘಾಯವಡೆ',
'ಘಾರಾಘಾರಿ',
'ಘಿಣಿಘಿಣಿ',
'ಘಿಲಘಿಲ',
'ಘೀಂಕರಿಸು',
'ಘೀಳಿಡಿಸು',
'ಘುಡುಘುಡು',
'ಘುರುಘುರು',
'ಘುಳುಮ್ಮನೆ',
'ಘುೞುಮ್ಮನೆ',
'ಘೂಂಕರಿಸು',
'ಘೂತ್ಕರಿಸು',
'ಘೂರ್ಣನೀಯ',
'ಘೃಣಾನಿಧಿ',
'ಘೃತಾಹುತಿ',
'ಘೇಂಡಾಮೃಗ',
'ಘೇರಾಯಿಸು',
'ಘೊರಗುಡು',
'ಘೋಕಂಪಟ್ಟಿ',
'ಘೋಕಹಾಕು',
'ಘೋಕುಹಾಕು',
'ಘೋಕುಹೊಡೆ',
'ಘೋಡಾಬೋಳಿ',
'ಘೋಣಾಂಕುಶ',
'ಘೋಣಾರವ',
'ಘೋಷಣಿಸು',
'ಘೋಷಬಾರೆ',
'ಘೋಷವತ್ತು',
'ಘೋಷವರ್ಣ',
'ಘೋಷಸ್ಪರ್ಶ',
'ಘೋಷಾಕರ',
'ಘೋಳಾಘೋಳಿ',
'ಘೋಳಾಯಿಲ',
'ಘೋಳಿಡಿಸು',
'ಘೋಳ್ಗುಟ್ಟಿಸು',
'ಘೋೞಯಿಲ',
'ಘೋೞಾಯಿಲ',
'ಘ್ರಾಣೇಂದ್ರಿಯ',
'ಚಂಕ್ರಮಣ',
'ಚಂಗದಿರ್',
'ಚಂಗದಿರ',
'ಚಂಗಲವೆ',
'ಚಂಗಳವೆ',
'ಚಂಗುಲಾಬಿ',
'ಚಂಗುವಾಱು',
'ಚಂಚತ್ಪುಟ',
'ಚಂಚರೀಕ',
'ಚಂಚಲತೆ',
'ಚಂಚಲತ್ವ',
'ಚಂಚಲಾಕ್ಷಿ',
'ಚಂಚಲಿತ',
'ಚಂಚಲಿಸು',
'ಚಂಚಸೊಪ್ಪು',
'ಚಂಚುಘಾತ',
'ಚಂಚುಪುಟ',
'ಚಂಚುಮೂಗು',
'ಚಂಚುರತೆ',
'ಚಂಚೂಪುಟ',
'ಚಂಡಕನ್ನೆ',
'ಚಂಡಕರ',
'ಚಂಡದೈವ',
'ಚಂಡನಂಗೈ',
'ಚಂಡಮಾರಿ',
'ಚಂಡರುಚಿ',
'ಚಂಡವಾಳ',
'ಚಂಡಾತಕ',
'ಚಂಡಾಮಯ',
'ಚಂಡಾಲತ್ವ',
'ಚಂಡಾವುರ',
'ಚಂಡಿಗುನ್ನಿ',
'ಚಂಡಿಗೊಳ್',
'ಚಂಡಿತನ',
'ಚಂಡಿನಾಯಿ',
'ಚಂಡಿಪೋರು',
'ಚಂಡಿವಳ',
'ಚಂಡಿವಾಳ',
'ಚಂಡಿಹಿಡಿ',
'ಚಂಡುಹೂವು',
'ಚಂಡೆಮುಳ್ಳು',
'ಚಂದಂಬೆಱು',
'ಚಂದಕಲ',
'ಚಂದಕಾಣು',
'ಚಂದಕಾರ',
'ಚಂದಕಾಱ',
'ಚಂದಗಲ್',
'ಚಂದಗೆಡು',
'ಚಂದಗೇಡಿ',
'ಚಂದಮರ',
'ಚಂದಮಾಮ',
'ಚಂದಮಾವ',
'ಚಂದವರಿ',
'ಚಂದವಳಿ',
'ಚಂದಸಿಗ',
'ಚಂದಳಿರ್',
'ಚಂದಾದಾರ',
'ಚಂದಿಗೋಡೆ',
'ಚಂದುಮಾಮ',
'ಚಂದುವಟ್ಟು',
'ಚಂದ್ರಕಲೆ',
'ಚಂದ್ರಕಳೆ',
'ಚಂದ್ರಕಾಂತ',
'ಚಂದ್ರಕಾಂತಿ',
'ಚಂದ್ರಕಾಳಿ',
'ಚಂದ್ರಕಿತ',
'ಚಂದ್ರಕೋರೆ',
'ಚಂದ್ರಕೋಲು',
'ಚಂದ್ರಖಂಡ',
'ಚಂದ್ರಗತಿ',
'ಚಂದ್ರಗಲ್',
'ಚಂದ್ರಗಾವಿ',
'ಚಂದ್ರಗೃಹ',
'ಚಂದ್ರಚೂಡ',
'ಚಂದ್ರಜೋತಿ',
'ಚಂದ್ರಜ್ಯೋತಿ',
'ಚಂದ್ರನಾಡಿ',
'ಚಂದ್ರನೀಚ',
'ಚಂದ್ರಪಾದ',
'ಚಂದ್ರಪುಷ್ಪ',
'ಚಂದ್ರಬಲ',
'ಚಂದ್ರಬಾಲೆ',
'ಚಂದ್ರಬಾಳಿ',
'ಚಂದ್ರಬಾಳೆ',
'ಚಂದ್ರಬಿಂಬ',
'ಚಂದ್ರಮಾವು',
'ಚಂದ್ರಮುರು',
'ಚಂದ್ರಮೂಲ',
'ಚಂದ್ರಮೂಲಿ',
'ಚಂದ್ರಮೌಲಿ',
'ಚಂದ್ರಮೌಳಿ',
'ಚಂದ್ರಯಾನ',
'ಚಂದ್ರರಾಗ',
'ಚಂದ್ರರಿಷ್ಟ',
'ಚಂದ್ರರೋಚಿ',
'ಚಂದ್ರಲೇಖೆ',
'ಚಂದ್ರಲೋಕ',
'ಚಂದ್ರವಂಕಿ',
'ಚಂದ್ರವತ್ರ್ಮ',
'ಚಂದ್ರವಾದ್ಯ',
'ಚಂದ್ರವೀಧಿ',
'ಚಂದ್ರಶಾಲ',
'ಚಂದ್ರಶಾಲೆ',
'ಚಂದ್ರಶಿಲೆ',
'ಚಂದ್ರಸಾಲೆ',
'ಚಂದ್ರಸೀಬೆ',
'ಚಂದ್ರಸ್ವರ',
'ಚಂದ್ರಹಾರ',
'ಚಂದ್ರಹಾಸ',
'ಚಂದ್ರಹಿಟ್ಟು',
'ಚಂದ್ರಾಚೂಡ',
'ಚಂದ್ರಾತಪ',
'ಚಂದ್ರಾಯುಧ',
'ಚಂದ್ರಾಲೋಕ',
'ಚಂದ್ರಾವರ್ತ',
'ಚಂದ್ರೋದಯ',
'ಚಂದ್ರೋಪಲ',
'ಚಂದ್ರೋಪಳ',
'ಚಕಚಕ',
'ಚಕಬಂದಿ',
'ಚಕಮಕ',
'ಚಕಮಕಿ',
'ಚಕಮುಕಿ',
'ಚಕರಾಲಿ',
'ಚಕಾರಿಸು',
'ಚಕೂಸಿನ',
'ಚಕೋರಕ',
'ಚಕೋರಿಕೆ',
'ಚಕ್ಕಪಾರೆ',
'ಚಕ್ಕಮಕ್ಕಿ',
'ಚಕ್ಕಮುಕ್ಕಿ',
'ಚಕ್ಕವಕ್ಕಿ',
'ಚಕ್ಕವತ್ತ',
'ಚಕ್ಕವತ್ತಿ',
'ಚಕ್ಕಾಚೂರು',
'ಚಕ್ಕುದಱಿ',
'ಚಕ್ಕುಬಂದಿ',
'ಚಕ್ಕುಮೊಕ್ಕು',
'ಚಕ್ಕುಹುಂಡಿ',
'ಚಕ್ಕೆಗಲ್ಲು',
'ಚಕ್ರಗತಿ',
'ಚಕ್ರಗಾಲು',
'ಚಕ್ರಗುತ್ತಿ',
'ಚಕ್ರಗೋಷ್ಠಿ',
'ಚಕ್ರತೀರ್ಥ',
'ಚಕ್ರದೃಶ್ಯ',
'ಚಕ್ರಧರ',
'ಚಕ್ರಧಾರಿ',
'ಚಕ್ರಧಾರೆ',
'ಚಕ್ರನಾಥ',
'ಚಕ್ರನಾಭಿ',
'ಚಕ್ರನೇಮಿ',
'ಚಕ್ರಪಕ್ಷಿ',
'ಚಕ್ರಪಾಣಿ',
'ಚಕ್ರಬಂಧ',
'ಚಕ್ರಬಡ್ಡಿ',
'ಚಕ್ರಬಾಣ',
'ಚಕ್ರಬಿಂಬ',
'ಚಕ್ರಭೃತ್ತು',
'ಚಕ್ರಭೃತ್ತ್ವ',
'ಚಕ್ರಮರ್ದ',
'ಚಕ್ರಮುದ್ರೆ',
'ಚಕ್ರಯಾನ',
'ಚಕ್ರಯುಗ',
'ಚಕ್ರಯೋಗ',
'ಚಕ್ರರತ್ನ',
'ಚಕ್ರಲಿಪಿ',
'ಚಕ್ರವರ್ತ',
'ಚಕ್ರವರ್ತಿ',
'ಚಕ್ರವಲೆ',
'ಚಕ್ರವಾಕ',
'ಚಕ್ರವಾಡ',
'ಚಕ್ರವಾತ',
'ಚಕ್ರವಾಲ',
'ಚಕ್ರವಾಳ',
'ಚಕ್ರವೀೞ್',
'ಚಕ್ರವೃದ್ಧಿ',
'ಚಕ್ರವ್ಯೂಹ',
'ಚಕ್ರಶ್ಲೋಕ',
'ಚಕ್ರಾಂಕಣ',
'ಚಕ್ರಾಂಕನ',
'ಚಕ್ರಾಣಿಕ',
'ಚಕ್ರಾಣಿಕೆ',
'ಚಕ್ರಾತ್ಮಕ',
'ಚಕ್ರಾಧಾರ',
'ಚಕ್ರಾಧೀಶ',
'ಚಕ್ರಾಯುಧ',
'ಚಕ್ರಿಪದ',
'ಚಕ್ರಿಶಾಯಿ',
'ಚಕ್ರೀವತ್ತು',
'ಚಕ್ರೇಶತ್ವ',
'ಚಕ್ರೇಶ್ವರ',
'ಚಕುಃಪ್ರೀತಿ',
'ಚಕ್ಷುಃಶ್ರವ',
'ಚಕ್ಷುಗ್ರ್ರಾಹ್ಯ',
'ಚಕ್ಷುಶ್ರೋತ್ರ',
'ಚಕ್ಷುಷ್ಮಂತ',
'ಚಕ್ಷೂರಾಗ',
'ಚಗತಳಿ',
'ಚಗತಿಳಿ',
'ಚಗಳಿಸು',
'ಚಚ್ಚಕಾರ',
'ಚಚ್ಚಗೊಟ್ಟಿ',
'ಚಚ್ಚಗೋಷ್ಟಿ',
'ಚಚ್ಚರಿಕೆ',
'ಚಚ್ಚರಿಗ',
'ಚಚ್ಚಹುಚ್ಚು',
'ಚಚ್ಚುಮಳೆ',
'ಚಟಕ್ಕನೆ',
'ಚಟಗಾರ',
'ಚಟಗೊಳ್',
'ಚಟಚಟ',
'ಚಟಪಟ',
'ಚಟಮಾರಿ',
'ಚಟವಟ',
'ಚಟಿದಾರ',
'ಚಟುಲತೆ',
'ಚಟುವಟ',
'ಚಟುಳತೆ',
'ಚಟುಳಿತ',
'ಚಟೂರಿಸು',
'ಚಟೆಕಾರ',
'ಚಟ್ಟಕಟ್ಟು',
'ಚಟ್ಟಗೆಯಿ',
'ಚಟ್ಟವ್ರಿತ್ತಿ',
'ಚಟ್ಟಿಹುಲ್ಲು',
'ಚಟ್ಟುಬಡಿ',
'ಚಟ್ಟುಮಾಡು',
'ಚಟ್ಟುಹೊಡೆ',
'ಚಟ್ಟೆಕಾರ',
'ಚಟ್ಟೆಗಾರ',
'ಚಟ್ಣಿಪುಡಿ',
'ಚಟ್ನಿಸೊಪ್ಪು',
'ಚಡ್‍ಸಾಲ್',
'ಚಡಪಡ',
'ಚಡಾಯಿಸು',
'ಚಡಾಳತೆ',
'ಚಡಾಳಿಕೆ',
'ಚಡಾಳಿಸು',
'ಚಡಾಳೈಸು',
'ಚಡಿಫೌಜು',
'ಚಡಿಮರಿ',
'ಚಡ್ಡಣಿಸು',
'ಚಡ್ಡೆಗೊಳ್',
'ಚಢಾಳಿಸು',
'ಚಣಕಾಲ',
'ಚತುಃಪದಿ',
'ಚತುರಂಗ',
'ಚತುರಂತ',
'ಚತುರಣ',
'ಚತುರತೆ',
'ಚತುರತ್ವ',
'ಚತುರಶ್ರ',
'ಚತುರಸ',
'ಚತುರಸ್ರ',
'ಚತುರಾಕ್ಷ',
'ಚತುರಾತ್ಮ',
'ಚತುರಾಶಿ',
'ಚತುರಾಸ್ಯ',
'ಚತುರಿಕೆ',
'ಚತುರೋಕ್ತಿ',
'ಚತುರ್ಗತಿ',
'ಚತುರ್ಗುಣ',
'ಚತುರ್ಗ್ರಾಮ',
'ಚತುರ್ಥಾಂಶ',
'ಚತುರ್ದಂಡಿ',
'ಚತುರ್ದಂತ',
'ಚತುರ್ದಶ',
'ಚತುರ್ದಶಿ',
'ಚತುರ್ದಿಕ್ಕು',
'ಚತುರ್ಬಲ',
'ಚತುರ್ಭಕ್ತಿ',
'ಚತುರ್ಭದ್ರ',
'ಚತುರ್ಭುಜ',
'ಚತುರ್ಭುಜೆ',
'ಚತುರ್ಮಡಿ',
'ಚತುರ್ಮುಖ',
'ಚತುರ್ಯುಗ',
'ಚತುರ್ವಕ್ತ್ರ',
'ಚತುರ್ವರ್ಗ',
'ಚತುರ್ವರ್ಣ',
'ಚತುರ್ವಲ',
'ಚತುರ್ವಿಧ',
'ಚತುರ್ವೇದ',
'ಚತುರ್ವೇದಿ',
'ಚತುಶ್ಶಾಲೆ',
'ಚತುಷ್ಕಾಲ',
'ಚತುಷ್ಕೋಣ',
'ಚತುಷ್ಟಯ',
'ಚತುಷ್ಪಥ',
'ಚತುಷ್ಪದ',
'ಚತುಷ್ಪದಿ',
'ಚತುಷ್ಪಾಠಿ',
'ಚತುಷ್ಪಾದ',
'ಚತುಷ್ಪಾದಿ',
'ಚತುಷ್ಷಷ್ಟಿ',
'ಚತುಸ್ಸೀಮೆ',
'ಚತೂರಸ',
'ಚತ್ತರಿಕೆ',
'ಚತ್ತರಿಗೆ',
'ಚದರಡಿ',
'ಚದರಾಸಿ',
'ಚದರಿಸು',
'ಚದಿಹಿಡಿ',
'ಚದುಂಪಿಸು',
'ಚದುಪಿಸು',
'ಚದುರಂಗ',
'ಚದುರತೆ',
'ಚದುರಸ',
'ಚದುರಿಕೆ',
'ಚದುರಿಗ',
'ಚದುರಿಸು',
'ಚದುರೋಕ್ತಿ',
'ಚದುರ್ಗಿಡು',
'ಚನ್ನೆಗ್ಗಿಲು',
'ಚನ್ನೆಮಣೆ',
'ಚನ್ನೆಯ್ದಿಲ್',
'ಚನ್ನೈದಿಲು',
'ಚಪಗಾಲು',
'ಚಪರಾಶಿ',
'ಚಪರಾಸಿ',
'ಚಪಲತೆ',
'ಚಪಲತ್ವ',
'ಚಪಲಾಕ್ಷಿ',
'ಚಪಳತೆ',
'ಚಪಳತ್ವ',
'ಚಪಳಾಕ್ಷಿ',
'ಚಪಳಿಕೆ',
'ಚಪಳಿಗ',
'ಚಪಾಡಿಸು',
'ಚಪೇಟಕ',
'ಚಪ್ಪಂಗಾಲು',
'ಚಪ್ಪಗೋಡು',
'ಚಪ್ಪಡಿಸು',
'ಚಪ್ಪರಣೆ',
'ಚಪ್ಪರಿಕೆ',
'ಚಪ್ಪರಿಸು',
'ಚಪ್ಪಲರ್',
'ಚಪ್ಪಲರು',
'ಚಪ್ಪಳಿಕ್ಕು',
'ಚಪ್ಪಳಿಸು',
'ಚಪ್ಪೆಗಲ್ಲು',
'ಚಪ್ಪೆಜಾಡ್ಯ',
'ಚಪ್ಪೆರೋಗ',
'ಚಬಕಾರ',
'ಚಬುಕಾರ',
'ಚಬುಕಿಸು',
'ಚಮಕಿತ',
'ಚಮಕಿಸು',
'ಚಮಗಾರ',
'ಚಮತ್ಕಾರ',
'ಚಮತ್ಕಾರಿ',
'ಚಮತ್ಕೃತ',
'ಚಮತ್ಕೃತಿ',
'ಚಮರಜ',
'ಚಮರಿಕ',
'ಚಮರೀಜ',
'ಚಮೂನಾಥ',
'ಚಮೂಪತಿ',
'ಚಮ್ಮಖಿಳ್ಳ',
'ಚಮ್ಮಟಿಕೆ',
'ಚಮ್ಮಟಿಗೆ',
'ಚಮ್ಮಟ್ಟಿಗೆ',
'ಚಮ್ಮಡಿಕೆ',
'ಚಮ್ಮರಿಗೆ',
'ಚಮ್ಮಳಗೆ',
'ಚಮ್ಮಳಿಕಿ',
'ಚಮ್ಮಾವುಗೆ',
'ಚಮ್ಮಾಳಿಗೆ',
'ಚಮ್ಮೋಳಗೆ',
'ಚಯನಿಸು',
'ಚರಚರ',
'ಚರಮಾಂಗ',
'ಚರಮೂರ್ತಿ',
'ಚರಯಿಸು',
'ಚರಲಿಂಗ',
'ಚರಲೋಕ',
'ಚರವಿಗೆ',
'ಚರಸೇವೆ',
'ಚರಸ್ವತ್ತು',
'ಚರಾಚರ',
'ಚರಾದಾಯ',
'ಚರಾರ್ಚನೆ',
'ಚರಿಚಲಿ',
'ಚರಿಜಲಿ',
'ಚರಿತಾರ್ಥ',
'ಚರಿತಾರ್ಥೆ',
'ಚರಿಯಿಸು',
'ಚರುಕಲು',
'ಚರುವಿಗೆ',
'ಚರ್ಚನೆಗೈ',
'ಚರ್ಚಾಕೂಟ',
'ಚರ್ಚಾತೀತ',
'ಚರ್ಚಾತೀತೆ',
'ಚರ್ಚಾಸ್ಪದ',
'ಚರ್ಪಟಿಸು',
'ಚರ್ಮಕಶೆ',
'ಚರ್ಮಕಸ',
'ಚರ್ಮಕಾರ',
'ಚರ್ಮಕೃತ್ತು',
'ಚರ್ಮಗತ',
'ಚರ್ಮಚಕ್ಷು',
'ಚರ್ಮದಂಡ',
'ಚರ್ಮಪಾಶ',
'ಚರ್ಮಯಷ್ಪಿ',
'ಚರ್ಮರಂಗ',
'ಚರ್ಮರತ್ನ',
'ಚರ್ಮರಿಕ',
'ಚರ್ಮವಾದ್ಯ',
'ಚರ್ಮಶಾಸ್ತ್ರ',
'ಚರ್ಮಶಿಲ್ಪ',
'ಚರ್ಮಶಿಲ್ಪಿ',
'ಚರ್ಮಾಂಬರ',
'ಚರ್ಮಾವುಗೆ',
'ಚರ್ಮೇಂದ್ರಿಯ',
'ಚಲಂಬಿಡಿ',
'ಚಲಗತಿ',
'ಚಲಗಾರ',
'ಚಲಗಾತಿ',
'ಚಲಚಿತ್ತ',
'ಚಲಚಿತ್ತೆ',
'ಚಲಚಿತ್ರ',
'ಚಲತನ',
'ಚಲನಕ',
'ಚಲನಾಂಗ',
'ಚಲನಾಟ',
'ಚಲನೆಗೈ',
'ಚಲಪಿಲ್ಲಿ',
'ಚಲಮಲ',
'ಚಲಮೂರ್ತಿ',
'ಚಲಯುತ',
'ಚಲವಾದಿ',
'ಚಲವಿಡಿ',
'ಚಲವಿತ್ತ',
'ಚಲವೀಣೆ',
'ಚಲಾಯಿಸು',
'ಚಲಾವಣೆ',
'ಚಲಿಂದಲ',
'ಚಲಿಕಿಸು',
'ಚಲಿಯಿಸು',
'ಚಲುಕಾಡು',
'ಚಲುವತಿ',
'ಚಲುವನೆ',
'ಚಲುವಳಿ',
'ಚಲುವಾದಿ',
'ಚಲುವಾನ್',
'ಚಲುವಿಕೆ',
'ಚಲುವೆಱು',
'ಚಲ್ಲಕಾತಿ',
'ಚಲ್ಲಕಾರ್ತಿ',
'ಚಲ್ಲಗಣ್',
'ಚಲ್ಲಗಾಯ್',
'ಚಲ್ಲಗಾರ್ತಿ',
'ಚಲ್ಲಪಿಲ್ಲಿ',
'ಚಲ್ಲವಂತ',
'ಚಲ್ಲವತ್ತ',
'ಚಲ್ಲವರ್ತ',
'ಚಲ್ಲವಾಡು',
'ಚಲ್ಲವೊತ್ತ',
'ಚಲ್ಲಾಪಿಲ್ಲಿ',
'ಚಲ್ಲಿಪಿಲ್ಲಿ',
'ಚಲ್ಲುಚಾಲು',
'ಚಲ್ಲುತನ',
'ಚಲ್ಲುಬಡಿ',
'ಚಲ್ಲುವಡು',
'ಚಲ್ಲೆಗಣ್',
'ಚಲ್ಲೆಗಾರ್ತಿ',
'ಚಲ್ಲೆನೋಟ',
'ಚವಡಿಕಿ',
'ಚವಡಿಕೆ',
'ಚವರಂಗ',
'ಚವರಿಗೆ',
'ಚವಲಂಬ',
'ಚವುಕಳಿ',
'ಚವುಕಿಗೆ',
'ಚವುಕುಳಿ',
'ಚವುಗಾವೆ',
'ಚವುಡಂಗಿ',
'ಚವುಥಾಯ',
'ಚವುದಂತ',
'ಚವುದರಿ',
'ಚವುಪದಿ',
'ಚವುಪರ್ಣ',
'ಚವುಬಾರ',
'ಚವುರಸ',
'ಚವುರಾಶಿ',
'ಚವುರಿಸು',
'ಚವುವಟ್ಟೆ',
'ಚವುಷಷ್ಟಿ',
'ಚವುಸರ',
'ಚವುಳುಪ್ಪು',
'ಚವ್ವಂಗುಲ',
'ಚವ್ವಗೆಡು',
'ಚವ್ವಲಿಸು',
'ಚಹಾಪಾಣಿ',
'ಚಹಾಪಾನ',
'ಚಳಕಿಗೆ',
'ಚಳಗತಿ',
'ಚಳಚಳ',
'ಚಳಚಿತ್ತ',
'ಚಳಚಿತ್ತೆ',
'ಚಳತುಂಬು',
'ಚಳತೊಂಬು',
'ಚಳಪಳ',
'ಚಳಮತಿ',
'ಚಳಮಾಲೆ',
'ಚಳಯಿಸು',
'ಚಳವಳ',
'ಚಳವಳಿ',
'ಚಳಿಕಿಗೆ',
'ಚಳಿಕೊಡು',
'ಚಳಿಗಾಲ',
'ಚಳಿಗಿರಿ',
'ಚಳಿಗೊಳ್',
'ಚಳಿಜ್ವರ',
'ಚಳಿನಾಡು',
'ಚಳಿಯಿಸು',
'ಚಳಿಲನೆ',
'ಚಳಿಲ್ಲನೆ',
'ಚಳಿವೆಟ್ಟ',
'ಚಳಿವೆಟ್ಟು',
'ಚಳಿವೆಱು',
'ಚಳುಕಿಡು',
'ಚಳುವಳಿ',
'ಚಳೆಗೊಡು',
'ಚಳೆಹಾಕು',
'ಚಳೆಹೊಡೆ',
'ಚಳ್ಳಪಿಳ್ಳಿ',
'ಚಳ್ಳವರೆ',
'ಚಳ್ಳುಗುರ್',
'ಚಳ್ಳುಗುರು',
'ಚಳ್ಳೆಪಿಳ್ಳೆ',
'ಚಾಂಡಾಲತ್ವ',
'ಚಾಂಡಾಲಿಕೆ',
'ಚಾಂಡಾಳಿಸು',
'ಚಾಂದಸಿಕೆ',
'ಚಾಂದ್ರಜ್ಞಾನಿ',
'ಚಾಂದ್ರಮಸ',
'ಚಾಂದ್ರಮಾನ',
'ಚಾಂದ್ರಮಾಸ',
'ಚಾಂದ್ರಾಯಣ',
'ಚಾಕಚಕ್ಯ',
'ಚಾಕಲೆಟ್',
'ಚಾಕಲೇಟು',
'ಚಾಕುಗಳ್ಳಿ',
'ಚಾಗಂಗೆಯ್',
'ಚಾಗಮಟ್ಟಿ',
'ಚಾಗವಿಕ್ಕು',
'ಚಾಚುಬಂಕ',
'ಚಾಟಕೈರ',
'ಚಾಟಣಕ',
'ಚಾಟವಿದ್ಯೆ',
'ಚಾಟಿಕೋಲು',
'ಚಾಟಿಗೋಲು',
'ಚಾಟುಕಾತಿ',
'ಚಾಟುಕಾರ',
'ಚಾಟುಕಾಱ',
'ಚಾಟುಗಾಲು',
'ಚಾಟುಗೋಷ್ಟಿ',
'ಚಾಟುತನ',
'ಚಾಟುಪದ್ಯ',
'ಚಾಟುಮಾತು',
'ಚಾಟುವೆಣ್',
'ಚಾಡಗಾರ',
'ಚಾಡಿಕಾರ',
'ಚಾಡಿಕೋರ',
'ಚಾಡಿಕೋರಿ',
'ಚಾಡಿಖೋರ',
'ಚಾಡಿಗಾರ',
'ಚಾಡಿಗಾರ್ತಿ',
'ಚಾಡಿಬಾಕ',
'ಚಾಡಿಮಾತು',
'ಚಾಡಿಯೋಲೆ',
'ಚಾಡಿವಾತು',
'ಚಾಣಾಕ್ಷತೆ',
'ಚಾತರಾಸಿ',
'ಚಾತುಃಶ್ರುತಿ',
'ಚಾತುಃಸ್ವರ',
'ಚಾತುರಂಗ',
'ಚಾತುರಿಯ',
'ಚಾತುರ್ಥಿಕ',
'ಚಾತುರ್ದಂತ',
'ಚಾತುರ್ಬಲ',
'ಚಾತುರ್ಬಳ',
'ಚಾತುರ್ಮಾಸ',
'ಚಾತುರ್ಮಾಸ್ಯ',
'ಚಾತುರ್ಮುಕ್ತಿ',
'ಚಾತುರ್ವರ್ಗ',
'ಚಾತುರ್ವರ್ಣ',
'ಚಾತುರ್ವಣ್ರ್ಯ',
'ಚಾಪಕ್ಷೇತ್ರ',
'ಚಾಪಟಿಯ',
'ಚಾಪಟೆಯ',
'ಚಾಪತಂತ್ರ',
'ಚಾಪಯೋಗ',
'ಚಾಪರಿಕೆ',
'ಚಾಪಲತೆ',
'ಚಾಪವಿದ್ದೆ',
'ಚಾಪವೇದ',
'ಚಾಪಾಗಮ',
'ಚಾಪುಗಲ್ಲು',
'ಚಾಪುಗಾಲು',
'ಚಾಪೆಹಿಡಿ',
'ಚಾಮರಿಗ',
'ಚಾಮರಿಯ',
'ಚಾಮರಿಸು',
'ಚಾಮಿಕರ',
'ಚಾಮೀಕರ',
'ಚಾಯಬೇರು',
'ಚಾಯಮೀನು',
'ಚಾಯಿಬೇರು',
'ಚಾರಕ್ಷೇತ್ರ',
'ಚಾರಚಕ್ಷು',
'ಚಾರಚಿಪ್ಪಿ',
'ಚಾರಚಿಬ್ಬಿ',
'ಚಾರಣತ್ವ',
'ಚಾರಪಾಯ',
'ಚಾರಪಾಯಿ',
'ಚಾರಾಖರ್ಚು',
'ಚಾರಿತ್ರಕ',
'ಚಾರಿತ್ರಿಕ',
'ಚಾರಿವರಿ',
'ಚಾರುಕೇಶಿ',
'ಚಾರುಗಣ್ಣು',
'ಚಾರುಗಾತ್ರ',
'ಚಾರುತರ',
'ಚಾರುವೃತ್ತ',
'ಚಾರುಹಾಸ',
'ಚಾರ್ವಾಕತೆ',
'ಚಾಲವರಿ',
'ಚಾಲುಗೇಣಿ',
'ಚಾಲುವರಿ',
'ಚಾಲುವರೆ',
'ಚಾಲ್ತಿಖಾತೆ',
'ಚಾಲ್ತಿಸಾಲು',
'ಚಾವಟೆಯ',
'ಚಾವಲಿಸು',
'ಚಾವಳಿಸು',
'ಚಾಷಗತಿ',
'ಚಾಳಯಿಸು',
'ಚಾಳವಿಸು',
'ಚಾಳಿತನ',
'ಚಾಳ್ಕತನ',
'ಚಾೞಬಟ್ಟ',
'ಚಿಂಚಪಟ್ಟಿ',
'ಚಿಂತನಾಂಶ',
'ಚಿಂತನೀಯ',
'ಚಿಂತಾಕುಲ',
'ಚಿಂತಾಕುಲೆ',
'ಚಿಂತಾಕುಳ',
'ಚಿಂತಾಕುಳೆ',
'ಚಿಂತಾಕ್ರಾಂತ',
'ಚಿಂತಾಕ್ರಾಂತೆ',
'ಚಿಂತಾಪರ',
'ಚಿಂತಾಭರ',
'ಚಿಂತಾಮಗ್ನ',
'ಚಿಂತಾಮಗ್ನೆ',
'ಚಿಂತಾಮಣಿ',
'ಚಿಂತಾಯಕ',
'ಚಿಂತಾರತ',
'ಚಿಂತಾರತಿ',
'ಚಿಂತಾರತ್ನ',
'ಚಿಂತಾಲೋಲ',
'ಚಿಂತಾಲೋಲೆ',
'ಚಿಂತೆಗೆಯ್',
'ಚಿಂತೆಗೊಳ್ಳು',
'ಚಿಂತೆದಾಳ್',
'ಚಿಂತೆವಡು',
'ಚಿಂತೆವಿಡಿ',
'ಚಿಂತೆವೆಱು',
'ಚಿಂತ್ಯಾಗಮ',
'ಚಿಂದಿಬಟ್ಟೆ',
'ಚಿಕಿತ್ಸಕ',
'ಚಿಕಿತ್ಸಾಂಗ',
'ಚಿಕಿತ್ಸಿತ',
'ಚಿಕ್ಕಗುಂಜಿ',
'ಚಿಕ್ಕಣಿಕೆ',
'ಚಿಕ್ಕತಂದೆ',
'ಚಿಕ್ಕತನ',
'ಚಿಕ್ಕತಾಯಿ',
'ಚಿಕ್ಕನುಡಿ',
'ಚಿಕ್ಕಪ್ರಾಯ',
'ಚಿಕ್ಕಬಳ್ಳೆ',
'ಚಿಕ್ಕಬೇವು',
'ಚಿಕ್ಕಬ್ರಹ್ಮಿ',
'ಚಿಕ್ಕಮಾತು',
'ಚಿಕ್ಕರಿವೆ',
'ಚಿಕ್ಕವೂಸಿ',
'ಚಿಕ್ಕಸೊಂಡೆ',
'ಚಿಕ್ಕಹಣ',
'ಚಿಕ್ಕಹೀರೆ',
'ಚಿಕ್ಕಿಹಾವು',
'ಚಿಕ್ಕೀಚಲು',
'ಚಿಕ್ಕುಡಿಪ್ಪೆ',
'ಚಿಕ್ಕೂಳಿಗ',
'ಚಿಕ್ಕೇಲಕ್ಕಿ',
'ಚಿಗುರಾಸೆ',
'ಚಿಗುರಿಕ್ಕು',
'ಚಿಗುರಿಡು',
'ಚಿಗುರಿಸು',
'ಚಿಗುರೆತ್ತು',
'ಚಿಗುರೆಲೆ',
'ಚಿಗುರೊಡೆ',
'ಚಿಚ್ಛಾಸಕ',
'ಚಿಟಕಿಸು',
'ಚಿಟಗಾಣಿ',
'ಚಿಟಗಾಳಿ',
'ಚಿಟಗುಟ್ಟು',
'ಚಿಟಗುಬ್ಬಿ',
'ಚಿಟಣೀಸ',
'ಚಿಟನೀಸ',
'ಚಿಟಪಾಗು',
'ಚಿಟಿಕಿಡು',
'ಚಿಟಿಕಿಸು',
'ಚಿಟಿಕಿಱಿ',
'ಚಿಟಿಚಿಟಿ',
'ಚಿಟುಂಕಿಸು',
'ಚಿಟುಕಿಕ್ಕು',
'ಚಿಟುಕಿಡು',
'ಚಿಟುಕಿಸು',
'ಚಿಟುಕೆನ್',
'ಚಿಟುಕೊತ್ತು',
'ಚಿಟುಗುಟ್ಟು',
'ಚಿಟುಬಾಳೆ',
'ಚಿಟುಮಿಟಿ',
'ಚಿಟ್ಟಗುಬ್ಬಿ',
'ಚಿಟ್ಟಜೇನು',
'ಚಿಟ್ಟಬಳ',
'ಚಿಟ್ಟಬಿಲ್ಲು',
'ಚಿಟ್ಟಮುಟ್ಟಿ',
'ಚಿಟ್ಟರಳು',
'ಚಿಟ್ಟಹುಲ್ಲು',
'ಚಿಟ್ಟಾಮುಟ್ಟಿ',
'ಚಿಟ್ಟಿಜೇನು',
'ಚಿಟ್ಟಿತಾಂಡ್ರಿ',
'ಚಿಟ್ಟುಕೋಳಿ',
'ಚಿಟ್ಟುತಂತ್ರೆ',
'ಚಿಟ್ಟುತಂದ್ರಿ',
'ಚಿಟ್ಟುತಾಂಡ್ರಿ',
'ಚಿಟ್ಟುಬಾಳೆ',
'ಚಿಟ್ಟುಮುರಿ',
'ಚಿಟ್ಟುಹಿಡಿ',
'ಚಿಟ್ಟುಳುಕ',
'ಚಿಟ್ಟೂಳಿಗ',
'ಚಿಟ್ಟೆಕತೆ',
'ಚಿಟ್ಟೆಕಥೆ',
'ಚಿಟ್ಟೆತಾನ',
'ಚಿಟ್ಟೆತಾಳ',
'ಚಿಟ್ಟೆಬೆಲ್ಲ',
'ಚಿಟ್ಟೆಮುಟ್ಟಿ',
'ಚಿಟ್ಟೆಸ್ವರ',
'ಚಿಟ್ಟೆಹುಲಿ',
'ಚಿಟ್ಟೌಡಲ',
'ಚಿಡಾಯಿಸು',
'ಚಿಣಿಕೋಲ್',
'ಚಿಣಿಕೋಲು',
'ಚಿಣಿದಾಂಡು',
'ಚಿಣಿಪಣಿ',
'ಚಿಣಿಫಣಿ',
'ಚಿಣ್ಣತನ',
'ಚಿಣ್ಣವೆಱೆ',
'ಚಿಣ್ಣಿಕೋಲು',
'ಚಿಣ್ಣಿದಾಂಡು',
'ಚಿತಾಭಸ್ಮ',
'ಚಿತಾಯಿಸು',
'ಚಿತಾವಣೆ',
'ಚಿತಿಶಕ್ತಿ',
'ಚಿತೆವೆಂಕಿ',
'ಚಿತ್ತಂಗುಡು',
'ಚಿತ್ತಂಗೊಳ್',
'ಚಿತ್ತಂಬಡೆ',
'ಚಿತ್ತಂಬುಗು',
'ಚಿತ್ತಂಬೆಱು',
'ಚಿತ್ತಕ್ಲೇಶ',
'ಚಿತ್ತಕ್ಷೋಭ',
'ಚಿತ್ತಕ್ಷೋಭೆ',
'ಚಿತ್ತಗೊಡು',
'ಚಿತ್ತಗ್ಲಾನಿ',
'ಚಿತ್ತಜನ್ಮ',
'ಚಿತ್ತಜಾತ',
'ಚಿತ್ತಜಾರಿ',
'ಚಿತ್ತಜ್ಞಾನ',
'ಚಿತ್ತಣಿಸು',
'ಚಿತ್ತಭವ',
'ಚಿತ್ತಭ್ರಮೆ',
'ಚಿತ್ತಭ್ರಾಂತಿ',
'ಚಿತ್ತಯಿಸು',
'ಚಿತ್ತರಗೈ',
'ಚಿತ್ತರಟೆ',
'ಚಿತ್ತರಟ್ಟೆ',
'ಚಿತ್ತರಿಗ',
'ಚಿತ್ತವಂತ',
'ಚಿತ್ತವಿಡು',
'ಚಿತ್ತವಿಸು',
'ಚಿತ್ತವೃತ್ತಿ',
'ಚಿತ್ತವೇದ್ಯ',
'ಚಿತ್ತಶಯ',
'ಚಿತ್ತಶುದ್ಧ',
'ಚಿತ್ತಶುದ್ಧಿ',
'ಚಿತ್ತಸ್ಥೈರ್ಯ',
'ಚಿತ್ತಸ್ವಾಸ್ಥ್ಯ',
'ಚಿತ್ತಹತ್ಯ',
'ಚಿತ್ತಳಿಸು',
'ಚಿತ್ತಾಗಾರ',
'ಚಿತ್ತಾಭೋಗ',
'ಚಿತ್ತಾರಿಗ',
'ಚಿತ್ತೋದ್ಭವ',
'ಚಿತ್ತೋದ್ರೇಕ',
'ಚಿತ್ತೋದ್ವೇಗ',
'ಚಿತ್ಪುರುಷ',
'ಚಿತ್ರಂಬರೆ',
'ಚಿತ್ರಕಂಠ',
'ಚಿತ್ರಕಥೆ',
'ಚಿತ್ರಕರ',
'ಚಿತ್ರಕರ್ಮ',
'ಚಿತ್ರಕಲೆ',
'ಚಿತ್ರಕಾಯ',
'ಚಿತ್ರಕಾರ',
'ಚಿತ್ರಕಾವ್ಯ',
'ಚಿತ್ರಕುಟಿ',
'ಚಿತ್ರಗತಿ',
'ಚಿತ್ರಗಾರ',
'ಚಿತ್ರಗೀತೆ',
'ಚಿತ್ರಗುಪ್ತ',
'ಚಿತ್ರಗೃಹ',
'ಚಿತ್ರನ್ಯಸ್ತ',
'ಚಿತ್ರಪಟ',
'ಚಿತ್ರಪಟ್ಟು',
'ಚಿತ್ರಪತ್ರ',
'ಚಿತ್ರಪದ',
'ಚಿತ್ರಪರ್ಣಿ',
'ಚಿತ್ರಪುಂಖ',
'ಚಿತ್ರಭಾನು',
'ಚಿತ್ರಭಿತ್ತಿ',
'ಚಿತ್ರಮಾಲೆ',
'ಚಿತ್ರಮೂಲ',
'ಚಿತ್ರಮೃಗ',
'ಚಿತ್ರರಥ',
'ಚಿತ್ರರೂಪಿ',
'ಚಿತ್ರರೂಪು',
'ಚಿತ್ರಲತೆ',
'ಚಿತ್ರಲಿಪಿ',
'ಚಿತ್ರಲೀನ',
'ಚಿತ್ರವಧೆ',
'ಚಿತ್ರವರ್ತ',
'ಚಿತ್ರವಸ್ತ್ರ',
'ಚಿತ್ರವಿಧ',
'ಚಿತ್ರವೀಣೆ',
'ಚಿತ್ರವೃತ್ತಿ',
'ಚಿತ್ರವೇಂಟೆ',
'ಚಿತ್ರಶಾಲೆ',
'ಚಿತ್ರಶಿಲ್ಪಿ',
'ಚಿತ್ರಸೂತ್ರ',
'ಚಿತ್ರಸೃಷ್ಟಿ',
'ಚಿತ್ರಹಿಂಸೆ',
'ಚಿತ್ರಾಂಬರ',
'ಚಿತ್ರಾಕ್ಷರಿ',
'ಚಿತ್ರಾಭಾಸ',
'ಚಿತ್ರಾರ್ಪಿತ',
'ಚಿತ್ರಾವತಿ',
'ಚಿತ್ರಾವಹ',
'ಚಿತ್ರಾವಳಿ',
'ಚಿತ್ರಾವೞಿ',
'ಚಿತ್ರಾಸನ',
'ಚಿತ್ರಾಹುತಿ',
'ಚಿತ್ರೋತ್ತರ',
'ಚಿದಂಬರ',
'ಚಿದಾನಂದ',
'ಚಿದಾಭಾಸ',
'ಚಿದ್ದರೂಪ',
'ಚಿನಕಡಿ',
'ಚಿನಕಾಳೆ',
'ಚಿನಗಡಿ',
'ಚಿನಗಾಳೆ',
'ಚಿನಪುಳಿ',
'ಚಿನವಾರ',
'ಚಿನಿಕಂಡ',
'ಚಿನಿಖಂಡ',
'ಚಿನಿಗಡಿ',
'ಚಿನಿಚಿದ್ರ',
'ಚಿನಿಪಾಲು',
'ಚಿನಿವರ',
'ಚಿನಿವಲ',
'ಚಿನಿವಾನ',
'ಚಿನಿವಾರ',
'ಚಿನಿವಾಲ್',
'ಚಿನಿವಾಲ',
'ಚಿನುಕುಲಿ',
'ಚಿನುಚಂಪೆ',
'ಚಿನುಮಯ',
'ಚಿನ್ನಂಗಾಳೆ',
'ಚಿನ್ನಕಾರ',
'ಚಿನ್ನಗಾಳೆ',
'ಚಿನ್ನಗಿರಿ',
'ಚಿನ್ನಗೆಯ್ಕ',
'ಚಿನ್ನದಾರೆ',
'ಚಿನ್ನಧಾರೆ',
'ಚಿನ್ನರೂಪ',
'ಚಿನ್ನವಾರ',
'ಚಿನ್ನವಾಲ',
'ಚಿನ್ನಾಟಿಕೆ',
'ಚಿನ್ನಿಕೋಲು',
'ಚಿನ್ನಿದಾಂಡು',
'ಚಿನ್ನಿವಾರ',
'ಚಿನ್ನೆದೋರು',
'ಚಿನ್ನೆದೋಱು',
'ಚಿಪಿಟಕ',
'ಚಿಪಿಟಾಸ್ಯ',
'ಚಿಪ್ಪಲಿಗ',
'ಚಿಪ್ಪಿಗಿತಿ',
'ಚಿಪ್ಪುಕುಂಟೆ',
'ಚಿಪ್ಪುಗಟ್ಟು',
'ಚಿಪ್ಪುಹಂದಿ',
'ಚಿಬಕಳಿ',
'ಚಿಬಟಿಗ',
'ಚಿಬ್ಬಂಬೊಯ್',
'ಚಿಬ್ಬುದೋರು',
'ಚಿಬ್ಬುದೋಱು',
'ಚಿಬ್ಬುವೊಯ್',
'ಚಿಮಕಲು',
'ಚಿಮಟಿಗೆ',
'ಚಿಮಿಚಿಮಿ',
'ಚಿಮುಕಿಸು',
'ಚಿಮುಟಾಂಗ',
'ಚಿಮುಟಿಗೆ',
'ಚಿಮ್ಮಟಿಗೆ',
'ಚಿಮ್ಮುಗಾಲ',
'ಚಿಮ್ಮುಗೊಳ್ಳು',
'ಚಿಮ್ಮುಗೋವಿ',
'ಚಿರಋಣಿ',
'ಚಿರಂಜೀವ',
'ಚಿರಂಜೀವಿ',
'ಚಿರಂಟಿಕೆ',
'ಚಿರಂತನ',
'ಚಿರಕಾಲ',
'ಚಿರಜೀವಿ',
'ಚಿರನಿದ್ರೆ',
'ಚಿರನಿಧಿ',
'ಚಿರಪೋಟಾ',
'ಚಿರಬಿಲ್ವ',
'ಚಿರರಾತ್ರ',
'ಚಿರರಾತ್ರೆ',
'ಚಿರವಿಲ್ವ',
'ಚಿರಶಾಂತಿ',
'ಚಿರಸೂತೆ',
'ಚಿರಸ್ಥಾಯಿ',
'ಚಿರಾತೀತ',
'ಚಿರಾಯತ',
'ಚಿರಾಯಿತ',
'ಚಿರಾಯುಷ್ಯ',
'ಚಿರಾಸಾಣಿ',
'ಚಿರಿಗುಟ್ಟು',
'ಚಿರಿಬೇರು',
'ಚಿರಿವೇರು',
'ಚಿರುಕುಟ',
'ಚಿರುಗುಳ್ಳ',
'ಚಿಲಕಡಿ',
'ಚಿಲಕತ್ತು',
'ಚಿಲಕೀರೆ',
'ಚಿಲಕೂಲಿ',
'ಚಿಲಖತ್ತು',
'ಚಿಲವಾನ್',
'ಚಿಲಿಕೀರೆ',
'ಚಿಲಿಚಿಮ',
'ಚಿಲಿಪಾಲು',
'ಚಿಲಿಪಿಲಿ',
'ಚಿಲಿಮಿಲಿ',
'ಚಿಲಿವಾಲು',
'ಚಿಲುಕಳಿ',
'ಚಿಲುಕಿಸು',
'ಚಿಲುಕೀರೆ',
'ಚಿಲುಕೂಲಿ',
'ಚಿಲುತಲಿ',
'ಚಿಲುಪಾಲ್',
'ಚಿಲುಪಾಲು',
'ಚಿಲುಮಷ',
'ಚಿಲುಮುರಿ',
'ಚಿಲುವಾನ',
'ಚಿಲುವಾನು',
'ಚಿಲುಹಾಲು',
'ಚಿಲ್ಕರಿವೆ',
'ಚಿಲ್ಲಗೊಟ್ಟೆ',
'ಚಿಲ್ಲತನ',
'ಚಿಲ್ಲಬಿತ್ತು',
'ಚಿಲ್ಲಬೀಜ',
'ಚಿಲ್ಲಹರಿ',
'ಚಿಲ್ಲುಗುಂಡು',
'ಚಿವಲ್ಲಿಕೆ',
'ಚಿವುಕಳಿ',
'ಚಿವುಕುಲಿ',
'ಚಿವುಗಣ್ಣು',
'ಚಿವುಗುಡು',
'ಚಿವುಡಿಕೆ',
'ಚಿಳಿಚಿಮ',
'ಚಿಳ್ಳೆಕೇತ',
'ಚಿಱುಗುಳ್ಳ',
'ಚಿಱುಬಾಳೆ',
'ಚಿಱುವೇರು',
'ಚೀಕಟಿಕೆ',
'ಚೀಕೊಳವೆ',
'ಚೀಟಿಬಟ್ಟೆ',
'ಚೀಣಾಂಬರ',
'ಚೀಣಿಕಾಯಿ',
'ಚೀತ್ಕರಣ',
'ಚೀತ್ಕರಣೆ',
'ಚೀತ್ಕರಿಸು',
'ಚೀನಚೇಳ',
'ಚೀನಾಂಬರ',
'ಚೀನಾಂಶುಕ',
'ಚೀನಾಸೋಂಪು',
'ಚೀನಿಕಾಯಿ',
'ಚೀನೀಜೇಡಿ',
'ಚೀನೀಶಿಲೆ',
'ಚೀಪುತೊಟ್ಟು',
'ಚೀಬಿಗಿಡ',
'ಚೀರಗಟ್ಟು',
'ಚೀರೀರವ',
'ಚೀರುಮಣ್ಣು',
'ಚೀರ್ಕೊಳವಿ',
'ಚೀರ್ಕೊಳವೆ',
'ಚೀರ್ಕೊೞವೆ',
'ಚೀರ್ಗೊಳವೆ',
'ಚೀಲಕೀಲಿ',
'ಚೀಲಣಿಸು',
'ಚೀಲಾಯುಧ',
'ಚುಂಗುನಾತ',
'ಚುಂಗುರಿಸು',
'ಚುಂಚುಕಾಲು',
'ಚುಂಚುದಲೆ',
'ಚುಂಚುಪತ್ರ',
'ಚುಂಚುಮೀಸೆ',
'ಚುಂಚುರುಚು',
'ಚುಂಚುಲೋವೆ',
'ಚುಂಬಕತ್ವ',
'ಚುಕಾಯಿಸು',
'ಚುಕುಚುಕು',
'ಚುಕ್ಕಮನ',
'ಚುಕ್ಕಾಣಿಗ',
'ಚುಕ್ಕಿಪಾಲ',
'ಚುಕ್ಕಿವಟ್ಟೆ',
'ಚುಕ್ಕಿಸೊಪ್ಪು',
'ಚುಕ್ಕುತಟ್ಟು',
'ಚುಕ್ಕುಬಡಿ',
'ಚುಕ್ಕೆಗಳೆ',
'ಚುಕ್ಕೆಜಿಂಕೆ',
'ಚುಕ್ಕೆದುಪ್ಪಿ',
'ಚುಕ್ಕೆಬೆಕ್ಕು',
'ಚುಕ್ಕೆಸೊಪ್ಪು',
'ಚುಕ್ತಿಪಟ್ಟಿ',
'ಚುಚುಂದರ',
'ಚುಚುಂದರಿ',
'ಚುಚ್ಚುಗಲೆ',
'ಚುಚ್ಚುನುಡಿ',
'ಚುಚ್ಚುನೋಟ',
'ಚುಚ್ಚುಮದ್ದು',
'ಚುಚ್ಚುಮಾತು',
'ಚುಚ್ಚುರೊಟ್ಟಿ',
'ಚುಡಾಯಿಸು',
'ಚುಣಚುಣಿ',
'ಚುದ್ರತನ',
'ಚುನಮರಿ',
'ಚುನಾಮುರಿ',
'ಚುನಾಯಿಕೆ',
'ಚುನಾಯಿತ',
'ಚುನಾಯಿತೆ',
'ಚುನಾಯಿಸು',
'ಚುನಾವಣೆ',
'ಚುನಿಚುನಿ',
'ಚುನ್ನಂಗೆಯ್',
'ಚುನ್ನಗಾರ್ತಿ',
'ಚುನ್ನನುಡಿ',
'ಚುಬುಕಿರಿ',
'ಚುಬುಕಿಱಿ',
'ಚುಮಚುಮ',
'ಚುಮುಕಿಸು',
'ಚುಮುಚುಮು',
'ಚುಮ್ಮುಚುಮ್ಮು',
'ಚುರಮರಿ',
'ಚುರಮುರಿ',
'ಚುರಿತಕ',
'ಚುರಿಮುರಿ',
'ಚುರುಕೆನ್',
'ಚುರುಗುಟ್ಟು',
'ಚುರುಗುಡು',
'ಚುರುಚಂಗಿ',
'ಚುರುಚುರು',
'ಚುರುಮರಿ',
'ಚುರುಮುರಿ',
'ಚುರ್ಕಿಗಾರೆ',
'ಚುರ್ಚುಂಗೊಳ್ಳಿ',
'ಚುರ್ಚುಗೊಳ್ಳಿ',
'ಚುಳುಕಾಡು',
'ಚುಳ್ಕೋದಕ',
'ಚುಳ್ಳಿಮುಳ್ಳಿ',
'ಚುಱಱನೆ',
'ಚುಱೆಱನ್',
'ಚೂಟಿಕಾತಿ',
'ಚೂಟುಗಾರ',
'ಚೂಡಾಕರ್ಮ',
'ಚೂಡಾಮಣಿ',
'ಚೂಡಾರತ್ನ',
'ಚೂಡೋದಯ',
'ಚೂಣಿನೋಟ',
'ಚೂಣಿಯಾಳ್',
'ಚೂತಕುಜ',
'ಚೂತಡಿಂಭ',
'ಚೂಪುಗೋಲು',
'ಚೂರಣಿಸು',
'ಚೂರಿಪಾನ',
'ಚೂರ್ಣಂಗೆಯ್',
'ಚೂರ್ಣಗಡ್ಡೆ',
'ಚೂರ್ಣಗೆಡ್ಡೆ',
'ಚೂರ್ಣನೀಯ',
'ಚೂರ್ಣಮಾಡು',
'ಚೂರ್ಣಯೋಗ',
'ಚೂರ್ಣವಾಸ',
'ಚೂರ್ಣಾಳಕ',
'ಚೂಳಾಕರ್ಮ',
'ಚೂಳಾಮಣಿ',
'ಚೂಳಿಗಾಳಿ',
'ಚೂಱಕಾರ',
'ಚೆಂಗದಿರ್',
'ಚೆಂಗದಿರ',
'ಚೆಂಗಲವೆ',
'ಚೆಂಗುಲಾಬಿ',
'ಚೆಂಗೂದಲು',
'ಚೆಂಟಕಣ್ಣಿ',
'ಚೆಂಡಲರು',
'ಚೆಂಡಿಕಸ',
'ಚೆಂಡಿಗೊಳ್ಳು',
'ಚೆಂಡುಕಾರೆ',
'ಚೆಂಡುಚೀಣಿ',
'ಚೆಂದಂಗೆಡು',
'ಚೆಂದಂಬಡೆ',
'ಚೆಂದಕಾಣು',
'ಚೆಂದಗಲೆ',
'ಚೆಂದಗೇಡಿ',
'ಚೆಂದಮಿೞ್',
'ಚೆಂದವೇರು',
'ಚೆಂದಳಿರ್',
'ಚೆಂದಳಿರು',
'ಚೆಂದಳಿರ್ಗೈ',
'ಚೆಂದಾವರೆ',
'ಚೆಂದೆಂಗಾಯ್',
'ಚೆಂದೊಂಗಲ್',
'ಚೆಂದ್ರಕಾಳಿ',
'ಚೆಂಬದುವ',
'ಚೆಂಬವಳ',
'ಚೆಂಬಳದಿ',
'ಚೆಂಬಿಸಲ್',
'ಚೆಂಬಿಸಿಲ',
'ಚೆಂಬಿಸಿಲು',
'ಚೆಂಬುಗೆಂಪು',
'ಚೆಂಬುರುಳ್',
'ಚೆಂಬೆಳಕು',
'ಚೆಂಬೆಳಗು',
'ಚೆಂಬೊಗರ್',
'ಚೆಂಬೊಳಪು',
'ಚೆಕ್ಕವತ್ತ',
'ಚೆಕ್ಕುದಱಿ',
'ಚೆಕ್ಕೆವತ್ತ',
'ಚಿಗತಳಿ',
'ಚೆಚ್ಚರಿಕೆ',
'ಚೆಚ್ಚರಿಗ',
'ಚೆಚ್ಚರಿತ',
'ಚೆಚ್ಚರಿಸು',
'ಚೆಜಕರ',
'ಚೆದರಿಸು',
'ಚೆದುಱಿಸು',
'ಚೆನ್ನಗಂಡ',
'ಚೆನ್ನನಾಯಿ',
'ಚೆನ್ನಪೊಂಗ',
'ಚೆನ್ನುಗೆಡು',
'ಚೆನ್ನೆಕಾಯಿ',
'ಚೆನ್ನೆಗ್ಗಿಲು',
'ಚೆನ್ನೆನಪು',
'ಚೆನ್ನೆಮಣೆ',
'ಚೆನ್ನೆಯ್ದಿಲ್',
'ಚೆನ್ನೇಸಱ್',
'ಚೆನ್ನೇಸಱು',
'ಚೆನ್ನೈದಿಲ್',
'ಚೆನ್ನೈದಿಲು',
'ಚೆನ್ನೈದಿಲೆ',
'ಚೆನ್ನೌಡಲ',
'ಚೆಪ್ಪೆಜಾಡ್ಯ',
'ಚೆಪ್ಪೆರೋಗ',
'ಚೆಪ್ಪೆವಡೆ',
'ಚೆಮ್ಮಣಿಗೆ',
'ಚೆಮ್ಮಾವುಗೆ',
'ಚೆಯ್ಮುಂಡಾಡು',
'ಚೆರುಪೊನ್ನೆ',
'ಚೆರುಹೊನ್ನೆ',
'ಚೆರೆಮದ್ದು',
'ಚೆಲಬಾಗೆ',
'ಚೆಲಯುದಿ',
'ಚೆಲುವಳಿ',
'ಚೆಲುವಾನ್',
'ಚೆಲುವಿಕೆ',
'ಚೆಲುವೆಣ್ಣು',
'ಚೆಲ್ಲಕಾತಿ',
'ಚೆಲ್ಲಗಣ್',
'ಚೆಲ್ಲಗಾತಿ',
'ಚೆಲ್ಲಗಾರ್ತಿ',
'ಚೆಲ್ಲಪಿಲ್ಲಿ',
'ಚೆಲ್ಲವತ್ತ',
'ಚೆಲ್ಲವಾಡು',
'ಚೆಲ್ಲವೊತ್ತ',
'ಚೆಲ್ಲಾಡಿಸು',
'ಚೆಲ್ಲಾಪಿಲ್ಲಿ',
'ಚೆಲ್ಲಿಪಿಲ್ಲಿ',
'ಚೆಲ್ಲುತನ',
'ಚೆಲ್ಲುಮಲ್ಲಿ',
'ಚೆಲ್ಲೆಗಣ್',
'ಚೆಲ್ಲೆಗಾತಿ',
'ಚೆಲ್ಲೆನೋಟ',
'ಚೆಲ್ವಕಾಱ',
'ಚೆಲ್ವತನ',
'ಚೆಲ್ವಪಿಳ್ಳೆ',
'ಚೆಲ್ವರಾಯ',
'ಚೆಲ್ವುದೋಱು',
'ಚೆಲ್ವುವಡೆ',
'ಚೆಲ್ವೆಳವೆ',
'ಚೆಲ್ವೆಳಸು',
'ಚೆಳವರಿ',
'ಚೆಳಾವರಿ',
'ಚೆಳ್ಳಕಾಯಿ',
'ಚೆಳ್ಳಮರ',
'ಚೆಳ್ಳುಗುರ್',
'ಚೆಳ್ಳುಗುರು',
'ಚೆಳ್ಳೆಗಾಯ್',
'ಚೇಗುಗಡ್ಡೆ',
'ಚೇಡುಬೀರ',
'ಚೇಣಿಕಬ್ಬು',
'ಚೇತನತ್ವ',
'ಚೇತನಿಸು',
'ಚೇತರಣೆ',
'ಚೇತರಾಶಿ',
'ಚೇತರಿಕೆ',
'ಚೇತರಿಸು',
'ಚೇತೋಜಾತ',
'ಚೇತೋರಥ',
'ಚೇತೋವೃತ್ತಿ',
'ಚೇತೋಹಾರಿ',
'ಚೇಪುಬಿಡು',
'ಚೇರಣಿಗ',
'ಚೇರಮಾನಿ',
'ಚೇರುಕುಟಿ',
'ಚೇಲಬಾಗೆ',
'ಚೇಲವಾಡಿ',
'ಚೇಲಾಚಾರ',
'ಚೇಷ್ಟಾಕೃತ',
'ಚೇಷ್ಟೆಕೋರ',
'ಚೇಷ್ಟೆಖೋರ',
'ಚೇಷ್ಟೆಗಾರ',
'ಚೇಷ್ಟೆಗೆಡು',
'ಚೇಳಕಡೆ',
'ಚೇಳುಕೊಂಡಿ',
'ಚೇಳುಬಾಣ',
'ಚೇಳುಬಾಲ',
'ಚೇಳುಮಣಿ',
'ಚೇಳುವಾಲ',
'ಚೇಳುವಾಲಿ',
'ಚೇಳುವಾಳಿ',
'ಚೈತನ್ಯಸ್ಥ',
'ಚೈತನ್ಯಾತ್ಮ',
'ಚೈತ್ಯಗೃಹ',
'ಚೈತ್ಯಗೇಹ',
'ಚೈತ್ಯಾಲಯ',
'ಚೈತ್ಯಾವಾಸ',
'ಚೈತ್ಯಾಳಯ',
'ಚೈತ್ರದೂತ',
'ಚೈತ್ರದೂತಿ',
'ಚೈತ್ರರಥ',
'ಚೈತ್ರಾಗಮ',
'ಚೈಮುಂಡಾಡು',
'ಚೈರಂತನ',
'ಚೊಂದಕಪ್ಪೆ',
'ಚೊಕ್ಕಣಿಕೆ',
'ಚೊಕ್ಕತನ',
'ಚೊಕ್ಕರೆಯ',
'ಚೊಕ್ಕಳಿಕೆ',
'ಚೊಕ್ಕಳಿಕ್ಕೆ',
'ಚೊಕ್ಕುತನ',
'ಚೊಟ್ಟಿಗೆಯ್ಯ',
'ಚೋಕುಮರ',
'ಚೋದ್ಯಂದಳೆ',
'ಚೋದ್ಯಂಬಡು',
'ಚೋದ್ಯಕರ',
'ಚೋದ್ಯಪಡು',
'ಚೋದ್ಯಮಯ',
'ಚೋದ್ಯವಡು',
'ಚೋದ್ಯವಡೆ',
'ಚೋದ್ಯಾವಹ',
'ಚೋಪದಾರ',
'ಚೋಪುದಾರ',
'ಚೋರಕಂಡಿ',
'ಚೋರಗಂಡಿ',
'ಚೋರತನ',
'ಚೋರದಿಡ್ಡಿ',
'ಚೋರಪುಷ್ಪಿ',
'ಚೋರವಿದ್ಯೆ',
'ಚೋರೆಕುರಿ',
'ಚೌಕಂಗೊಳ್ಳು',
'ಚೌಕದಾಯ',
'ಚೌಕದುಗ',
'ಚೌಕಬಂಧ',
'ಚೌಕಲಾಣಿ',
'ಚೌಕವರ್ಣ',
'ಚೌಕಸುದ್ದ',
'ಚೌಕಳಿಸು',
'ಚೌಕಾಬಾರ',
'ಚೌಕಾಬಾರಾ',
'ಚೌಕಿದಾರ',
'ಚೌಕಿಮನೆ',
'ಚೌಕೀದಾರ',
'ಚೌಕೀದಾರಿ',
'ಚೌಡಾಳಿಸು',
'ಚೌತರಫು',
'ಚೌದಲೆಯ',
'ಚೌಪದನ',
'ಚೌಪದಸ್ಥ',
'ಚೌಪದಿಗ',
'ಚೌಪಳಿಕೆ',
'ಚೌಪಳಿಗೆ',
'ಚೌಪಾಳಿಗೆ',
'ಚೌಬಾಗಿಲು',
'ಚೌಬಾಜಾರ',
'ಚೌರಶೀತಿ',
'ಚೌರಾಯಿಸಿ',
'ಚೌರಾಶೀತಿ',
'ಚೌರಾಸೀತಿ',
'ಚೌರಿಂದ್ರಿಯ',
'ಚೌರಿಕೊಟ್ಟು',
'ಚೌರಿಮೀಸೆ',
'ಚೌಲಕರ್ಮ',
'ಚೌವಂಗುಲ',
'ಚೌವಳಿಗೆ',
'ಚೌಳುನೀರು',
'ಚೌಳುನೆಲ',
'ಚೌಳುಭೂಮಿ',
'ಚೌಳುಮಣ್ಣು',
'ಚ್ಯುತಕ್ರಮ',
'ಛಂದಂಬಡೆ',
'ಛಂದಶ್ಶಾಸ್ತ್ರ',
'ಛಂದಸಿಗ',
'ಛಂದೋಗತಿ',
'ಛಂದೋಭಂಗ',
'ಛಂದೋವಿದ್ಯೆ',
'ಛಂದೋವೃತ್ತ',
'ಛಗಭಕ್ಷ',
'ಛಗಲಕ',
'ಛಗಲಾಂಡಿ',
'ಛಗಲಾಂತ್ರಿ',
'ಛಗಲಾಸ್ತಿ',
'ಛಗಳಕ',
'ಛಡಸಾಲ್',
'ಛಢಾಛಡಿ',
'ಛಢಾಯಿಸು',
'ಛಢಾಳತೆ',
'ಛಢಾಳಿಕೆ',
'ಛಢಾಳಿಸು',
'ಛತ್ತರಿಗೆ',
'ಛತ್ರಪತಿ',
'ಛತ್ರಪರ್ಣಿ',
'ಛತ್ರಭಂಗ',
'ಛತ್ರಭವ',
'ಛತ್ರಯೋಗ',
'ಛತ್ರರತ್ನ',
'ಛತ್ರರೇಖೆ',
'ಛತ್ರಿಮರ',
'ಛದ್ಮರೂಪ',
'ಛದ್ಮವೇಷ',
'ಛನ್ನವೀರ',
'ಛಪಾಯಿಸು',
'ಛಪಾವಣೆ',
'ಛಪ್ಪರಿಸು',
'ಛರ್ದಿಗೆಯ್',
'ಛಲಗಾರ',
'ಛಲಗಾರ್ತಿ',
'ಛಲಗೆಡು',
'ಛಲಗೇಡಿ',
'ಛಲಮದ',
'ಛಲವಾದಿ',
'ಛಲವಿಡಿ',
'ಛಲವೇಳು',
'ಛಲಿಪದ',
'ಛವಿವಡೆ',
'ಛಳಿಗಾಣ್',
'ಛಳಿಗಾಲ',
'ಛಳಿಜ್ವರ',
'ಛಳಿದೊತ್ತು',
'ಛಳಿವೆಟ್ಟು',
'ಛಾಂದಸಿಕ',
'ಛಾಂದಸಿಕ್ಕೆ',
'ಛಾಂದಸಿಗ',
'ಛಾತಿವಂತ',
'ಛಾತ್ರಾಲಯ',
'ಛಾಪಖಾನೆ',
'ಛಾಯರಂಗ',
'ಛಾಯಾಗೌಳ',
'ಛಾಯಾಗೃಹ',
'ಛಾಯಾಗ್ರಾಹಿ',
'ಛಾಯಾಚಿತ್ರ',
'ಛಾಯಾತೋಡಿ',
'ಛಾಯಾದೇವಿ',
'ಛಾಯಾನಟ',
'ಛಾಯಾನಾಟಿ',
'ಛಾಯಾಪತಿ',
'ಛಾಯಾಪಥ',
'ಛಾಯಾಬೇರು',
'ಛಾಯಾಯಂತ್ರ',
'ಛಾಯಾಲಕ್ಷ್ಯ',
'ಛಾಯಾವತಿ',
'ಛಾಯಾವಿದ್ಯೆ',
'ಛಾಯಾವೇರು',
'ಛಾಯೆಗೆಡು',
'ಛಿದ್ರಾನ್ವಿತ',
'ಛಿದ್ರಾನ್ವೇಷಿ',
'ಛಿನ್ನಭಕ್ತ',
'ಛಿನ್ನರುಹೆ',
'ಛಿನ್ನಾಗ್ರತೆ',
'ಛುಚ್ಚುಂದರ',
'ಜಂಗಮತಿ',
'ಜಂಗಮತೆ',
'ಜಂಗಮಿಸು',
'ಜಂಗಳಿಸು',
'ಜಂಗೀಕುಸ್ತಿ',
'ಜಂಗುಜರಿ',
'ಜಂಗುಳಿಕ',
'ಜಂಗುಳಿಗ',
'ಜಂಗುಳಿಸು',
'ಜಂಘಾಬಲ',
'ಜಂಘಾವರ್ತ',
'ಜಂಜಣಿಸು',
'ಜಂಝಪೂಗ',
'ಜಂಝಾನಿಲ',
'ಜಂಝಾನಿಳ',
'ಜಂಝಾವಾತ',
'ಜಂಟಿಕಲ್ಲು',
'ಜಂಟಿಗಾರ',
'ಜಂಟಿಪಟ್ಟೆ',
'ಜಂಡರಿಗೆ',
'ಜಂತಗುಂಟೆ',
'ಜಂತಣಿಗೆ',
'ಜಂತುಫಲ',
'ಜಂತುರೋಗ',
'ಜಂತುಹುಳು',
'ಜಂತ್ರಬಿಜ್ಜೆ',
'ಜಂತ್ರವಾಹ',
'ಜಂತ್ರಹಾಲೆ',
'ಜಂಪಲಿಕ್ಕು',
'ಜಂಪೆತಾಳ',
'ಜಂಬಕೊಚ್ಚು',
'ಜಂಬಗಾರ',
'ಜಂಬಗಿತ್ತಿ',
'ಜಂಬಾಲತೆ',
'ಜಂಬಿಟ್ಟಗೆ',
'ಜಂಬಿಟ್ಟಿಕೆ',
'ಜಂಬಿಟ್ಟಿಗೆ',
'ಜಂಬಿಸಾರಿ',
'ಜಂಬೀರಕ',
'ಜಂಬುಕಾನ',
'ಜಂಬುಖಾನ',
'ಜಂಬುದ್ವೀಪ',
'ಜಂಬುಮೀನು',
'ಜಂಬೂಘೃತ',
'ಜಂಬೂದ್ವೀಪ',
'ಜಂಬೂಫಲ',
'ಜಂಬೂಫಳ',
'ಜಕ್ಕಂದಱಿ',
'ಜಕ್ಕಂದೊಳ',
'ಜಕ್ಕಂದೊೞ',
'ಜಕ್ಕಂದೋೞ',
'ಜಕ್ಕವಕ್ಕಿ',
'ಜಕ್ಕಸರ',
'ಜಕ್ಕುಗೊಳ್',
'ಜಕ್ಕುಲಿತೆ',
'ಜಕ್ಕುಲಿಸು',
'ಜಕ್ಕುಳಿಕೆ',
'ಜಕ್ಕುಳಿಸು',
'ಜಗಂಜ್ಯೋತಿ',
'ಜಗಗನ್ನ',
'ಜಗಚ್ಚಕ್ಕ್ಷು',
'ಜಗಜಂಪ',
'ಜಗಜಂಪು',
'ಜಗಜಗ',
'ಜಗಜಟ್ಟಿ',
'ಜಗಜಾಲಿ',
'ಜಗಜೆಟ್ಟಿ',
'ಜಗಜ್ಜ್ಯೋತಿ',
'ಜಗಜ್ಝಂಪ',
'ಜಗಜ್ಝಂಪಿ',
'ಜಗಝಂಪ',
'ಜಗತಿಪ',
'ಜಗತ್ಕರ್ತ',
'ಜಗತ್ಕರ್ತೃ',
'ಜಗತ್ತರ್ಪಿ',
'ಜಗತ್ಪ್ರಾಣ',
'ಜಗತ್ಸರ್ಗ',
'ಜಗದಂಡ',
'ಜಗದಂಬೆ',
'ಜಗದಳ',
'ಜಗದಾಳ',
'ಜಗದಿಂಡೆ',
'ಜಗದಿಟ್ಟಿ',
'ಜಗದೀಶ',
'ಜಗದ್ಗುರು',
'ಜಗದ್ದಳ',
'ಜಗದ್ಧರೆ',
'ಜಗದ್ಧಾತ್ರಿ',
'ಜಗನ್ನಾಥ',
'ಜಗನ್ನುತ',
'ಜಗನ್ನುತೆ',
'ಜಗನ್ನೂತ',
'ಜಗನ್ಮಯ',
'ಜಗನ್ಮಾತೆ',
'ಜಗಬಂಡ',
'ಜಗಬಂಡಿ',
'ಜಗಭಂಡ',
'ಜಗಭಂಡಿ',
'ಜಗಮಗ',
'ಜಗಮೊಂಡ',
'ಜಗಮೊಂಡಿ',
'ಜಗಮೊಂಡು',
'ಜಗವತ್ತಿ',
'ಜಗವರ್ತಿ',
'ಜಗಳಾಟ',
'ಜಗುಳಿಕೆ',
'ಜಗುಳಿತ',
'ಜಗುಳಿಸು',
'ಜಗುೞ್ತರ್',
'ಜಗ್ಗಲಿಗೆ',
'ಜಗ್ಗುಗಾತಿ',
'ಜಗ್ಗುಬೀಳು',
'ಜಘನ್ಯಜ',
'ಜಜ್ಜರಗೈ',
'ಜಜ್ಜರಿತ',
'ಜಜ್ಜರಿಸು',
'ಜಜ್ಜುಗಂತೆ',
'ಜಜ್ಜುಗಂಥೆ',
'ಜಜ್ಜುಗಾಯ',
'ಜಜ್ಜುಗಟ್ಟು',
'ಜಜ್ಝರಿತ',
'ಜಝ್ಝರಿತ',
'ಜಟಕಿಸು',
'ಜಟಪಟ',
'ಜಟಮಟ',
'ಜಟಮಾಂಸಿ',
'ಜಟಾಜೂಟ',
'ಜಟಾಧರ',
'ಜಟಾಧಾರಿ',
'ಜಟಾಪಟಿ',
'ಜಟಾಬಂಧ',
'ಜಟಾಭರ',
'ಜಟಾಮಾಂಸಿ',
'ಜಟಿಲತೆ',
'ಜಟಿಲಿತ',
'ಜಟೆಕಟ್ಟು',
'ಜಠರಾಗ್ನಿ',
'ಜಡಕರ್ಮಿ',
'ಜಡಕ್ರಿಯ',
'ಜಡಗ್ರಾಹಿ',
'ಜಡಜಂತು',
'ಜಡಜಗ',
'ಜಡಜಜ',
'ಜಡಜನ',
'ಜಡಜೀವ',
'ಜಡಜೀವಿ',
'ಜಡತನ',
'ಜಡತೂಕ',
'ಜಡದೇಹ',
'ಜಡದ್ರವ್ಯ',
'ಜಡಧರ',
'ಜಡಭಾವ',
'ಜಡಭಾವಿ',
'ಜಡಮತಿ',
'ಜಡವಾದ',
'ಜಡವಾದಿ',
'ಜಡಾಕರ',
'ಜಡಾಯಿಸು',
'ಜಡಾವಸ್ಥೆ',
'ಜಡಿಕೆಯ್',
'ಜಡಿನುಡಿ',
'ಜಡಿಮಳೆ',
'ಜಡಿವಳೆ',
'ಜಡೀಕೃತ',
'ಜಡೀಭಾವ',
'ಜಡೆಕಟ್ಟು',
'ಜಡೆಕಳ್ಳಿ',
'ಜಡೆಕುಚ್ಚು',
'ಜಡೆಗಟ್ಟು',
'ಜಡೆಗಳ್ಳಿ',
'ಜಡೆಗೊಂಡೆ',
'ಜಡೆಗೊಳ್',
'ಜಡೆದಲೆ',
'ಜಡೆದೇವ',
'ಜಡೆಬಂಧ',
'ಜಡೆಬತ್ತಿ',
'ಜಡೆಬಿಲ್ಲೆ',
'ಜಡೆಮುಡಿ',
'ಜಡೆಮುನಿ',
'ಜಡೆವಾಳ',
'ಜಡೆವೆಣೆ',
'ಜಡೆಸರ',
'ಜಡೆಹತ್ತಿ',
'ಜಡೆಹೆಣೆ',
'ಜಡ್ಡಮತಿ',
'ಜಡ್ಡುಕಟ್ಟು',
'ಜಡ್ಡುಗಟ್ಟು',
'ಜಡ್ಡುದೇಹ',
'ಜಡ್ಡುಮತಿ',
'ಜಡ್ಡುವಕ್ಕಿ',
'ಜಡ್ಡುಸೀರೆ',
'ಜಣಜಣ',
'ಜತನಿಗ',
'ಜತನಿಸು',
'ಜತಿಗುಡು',
'ಜತಿಗೊಡು',
'ಜತಿಮೆಟ್ಟು',
'ಜತಿರಾಯ',
'ಜತಿವಗೆ',
'ಜತಿವೆಱು',
'ಜತಿಸ್ವರ',
'ಜತುಕೃತ್ತು',
'ಜತುಮುದ್ರೆ',
'ಜತುರಸ',
'ಜತೆಕಟ್ಟು',
'ಜತೆಗಟ್ಟು',
'ಜತೆಗಾತಿ',
'ಜತೆಗಾರ',
'ಜತೆಗಾರ್ತಿ',
'ಜತೆಗೂಡು',
'ಜತೆಬಿಡು',
'ಜತ್ತಕುಱ',
'ಜತ್ತಕುಱು',
'ಜತ್ತರಟ್ಟ',
'ಜತ್ತಾಯತ',
'ಜತ್ತಾಯಿತ',
'ಜತ್ತಕಱು',
'ಜತ್ತುಕುಱು',
'ಜತ್ತುಗೊಳ್ಳು',
'ಜನಂಗಮ',
'ಜನಕತೆ',
'ಜನಜಾತ್ರೆ',
'ಜನಜಾಲ',
'ಜನತಂತ್ರ',
'ಜನನಾಂಗ',
'ಜನನಾಂಧ',
'ಜನನುತ',
'ಜನಪದ',
'ಜನಪ್ರಿಯ',
'ಜನಬಲ',
'ಜನಯಿತ್ರಿ',
'ಜನಯಿಸು',
'ಜನರಲ್ಲು',
'ಜನರಾಲ್',
'ಜನರ್ಲೋಕ',
'ಜನಲೋಕ',
'ಜನವಡೆ',
'ಜನವರ',
'ಜನವಶ್ಯ',
'ಜನವಾಣಿ',
'ಜನವಾದ',
'ಜನವಾರ್ತೆ',
'ಜನವೇದಿ',
'ಜನಶ್ರುತಿ',
'ಜನಸಂಗ',
'ಜನಸಂಧಿ',
'ಜನಸೇವೆ',
'ಜನಸ್ಥಾನ',
'ಜನಹಿತ',
'ಜನಾಂಗೀಯ',
'ಜನಾಂತಿಕ',
'ಜನಾರ್ದನ',
'ಜನಾವರ',
'ಜನಾಶ್ರಯ',
'ಜನಿಯಿಸು',
'ಜನಿವಾರ',
'ಜನೋದಯ',
'ಜನ್ನಂಗೆಯ್',
'ಜನ್ನಕೊಂಡ',
'ಜನ್ನಗೂಳ',
'ಜನ್ನಗೂೞ',
'ಜನ್ನಗೊಂಡ',
'ಜನ್ನದಾರ',
'ಜನ್ನವನೆ',
'ಜನ್ನವರ',
'ಜನ್ನವಳ',
'ಜನ್ನವಿರ್',
'ಜನ್ನವಿರ',
'ಜನ್ನಶಾಲೆ',
'ಜನ್ನಿಗೆಯ',
'ಜನ್ನಿವರ',
'ಜನ್ನಿವಾರ',
'ಜನ್ನಿವಾಸ',
'ಜನ್ಮಕ್ಷೇತ್ರ',
'ಜನ್ಮಚಕ್ರ',
'ಜನ್ಮತಾರೆ',
'ಜನ್ಮದಾತ',
'ಜನ್ಮದಾತೆ',
'ಜನ್ಮದಿನ',
'ಜನ್ಮನಾಮ',
'ಜನ್ಮವೆತ್ತು',
'ಜನ್ಮವ್ರತ',
'ಜನ್ಮಸ್ಥಳ',
'ಜನ್ಮಾಂತರ',
'ಜನ್ಮಾಂತ್ರಿಯ',
'ಜನ್ಮಾಂಧಕಿ',
'ಜನ್ಮಾವಳಿ',
'ಜನ್ಮೋತ್ಸವ',
'ಜನ್ಯಭಾಷೆ',
'ಜನ್ಯರಾಗ',
'ಜನ್ಯವರ್ಣ',
'ಜಪಗುಟ್ಟು',
'ಜಪಮಣಿ',
'ಜಪಮಾಲೆ',
'ಜಪಸರ',
'ಜಪಾಪುಷ್ಪ',
'ಜಪಿಯಿಸು',
'ಜಬರಿಸು',
'ಜಬರ್ದಸ್ತಿ',
'ಜಬರ್ದಸ್ತು',
'ಜಬ್ಬರಿಸು',
'ಜಬ್ಬುದುಳಿ',
'ಜಮಖಾನ',
'ಜಮಖಾನೆ',
'ಜಮಜಮ',
'ಜಮದಂಡ',
'ಜಮದಂಡೆ',
'ಜಮದಗ್ನಿ',
'ಜಮದಾಡೆ',
'ಜಮಶೇರಿ',
'ಜಮಾಕಟ್ಟು',
'ಜಮಾಮಾಡು',
'ಜಮಾಖರ್ಚು',
'ಜಮಾದಾರ',
'ಜಮಾಬಂದಿ',
'ಜಮಾಯತ್ತು',
'ಜಮಾಯಿಸು',
'ಜಮಾವಟ್ಟು',
'ಜಮಾವಣೆ',
'ಜಮಿಯತು',
'ಜಮಿಯಿತ್ತು',
'ಜಮೀನ್ದಾರ',
'ಜಮೀನ್ದಾರಿ',
'ಜಮೀಯತ್',
'ಜಮೀಯತು',
'ಜಮೇದಾರ',
'ಜಮ್ಮಗಾರ',
'ಜಮ್ಮಡಿಸು',
'ಜಮ್ಮಿಟ್ಟಿಗೆ',
'ಜಯಕಾರ',
'ಜಯಗಂಟೆ',
'ಜಯಗಾಥ',
'ಜಯಗಾಥೆ',
'ಜಯಘಂಟೆ',
'ಜಯಘೋಷ',
'ಜಯಬಾಡಿ',
'ಜಯಭೇರಿ',
'ಜಯಮುಖ',
'ಜಯವಂತ',
'ಜಯವಡೆ',
'ಜಯವಿಡಿ',
'ಜಯಶಾಲಿ',
'ಜಯಶೀಲ',
'ಜಯಸ್ತಂಭ',
'ಜರಕಟ್ಟು',
'ಜರಕಾಟಿ',
'ಜರಕ್ಕನೆ',
'ಜರಗಿಸು',
'ಜರಗುಂಡೆ',
'ಜರಣಕ',
'ಜರತಾರ',
'ಜರತಾರಿ',
'ಜರದಾಲು',
'ಜರದಾಳು',
'ಜರದ್ವಧು',
'ಜರಹಾಯ್',
'ಜರಾಯತ',
'ಜರಾಯುಜ',
'ಜರಾರ್ದಿತ',
'ಜರಿಕೊಳ್ಳು',
'ಜರಿಗಿಡ',
'ಜರಿಗುಟ್ಟು',
'ಜರಿಯಿಸು',
'ಜರಿಹುಲ್ಲು',
'ಜರುಗಿಸು',
'ಜರೆನೆರೆ',
'ಜರೆಪಡು',
'ಜರೆವಡು',
'ಜರ್ಗುವೀೞ್',
'ಜರ್ಜರತೆ',
'ಜರ್ಜರಿತ',
'ಜರ್ಜರಿಸು',
'ಜರ್ಜಾರವ',
'ಜರ್ಝರಿತ',
'ಜರ್ಝರಿಸು',
'ಜಲಕಂಠ',
'ಜಲಕನೆ',
'ಜಲಕನ್ನೆ',
'ಜಲಕನ್ಯೆ',
'ಜಲಕರಿ',
'ಜಲಕಾಕ',
'ಜಲಕುಲ್ಯ',
'ಜಲಕೂರ್ಮ',
'ಜಲಕೇಳಿ',
'ಜಲಕ್ಕಗೆ',
'ಜಲಕ್ಕನೆ',
'ಜಲಕ್ರೀಡೆ',
'ಜಲಕ್ಲಿನ್ನ',
'ಜಲಕ್ಲೇದ',
'ಜಲಗಂಡ',
'ಜಲಗಜ',
'ಜಲಗಡಿ',
'ಜಲಗರ',
'ಜಲಗಾಡು',
'ಜಲಗಾರ',
'ಜಲಗಾಱ',
'ಜಲಗೊಟ್ಟೆ',
'ಜಲಗೋಟಿ',
'ಜಲಗೋಳ',
'ಜಲಗ್ರಹ',
'ಜಲಘಟ',
'ಜಲಘಡಿ',
'ಜಲಚಕ್ರ',
'ಜಲಚರ',
'ಜಲಚಿಹ್ನೆ',
'ಜಲಜಂತು',
'ಜಲಜಂತ್ರ',
'ಜಲಜಿನಿ',
'ಜಲದಾನ',
'ಜಲದಾರು',
'ಜಲದಿವ್ಯ',
'ಜಲದುರ್ಗ',
'ಜಲದೋಷ',
'ಜಲಧರ',
'ಜಲನದಿ',
'ಜಲನಾಡು',
'ಜಲನಿಧಿ',
'ಜಲನೀಲಿ',
'ಜಲಪಕ್ಷಿ',
'ಜಲಪನ',
'ಜಲಪಾತ',
'ಜಲಪಿಸು',
'ಜಲಪುಷ್ಪ',
'ಜಲಪ್ರಾಂತ',
'ಜಲಪ್ರಾಣಿ',
'ಜಲಪ್ರಾಯ',
'ಜಲಫಣಿ',
'ಜಲಬಂಧ',
'ಜಲಬಾಧೆ',
'ಜಲಬ್ರಾಹ್ಮಿ',
'ಜಲಭಂಗ',
'ಜಲಭೃತ್ತು',
'ಜಲಭ್ರಮ',
'ಜಲಮಂತ್ರ',
'ಜಲಮಯ',
'ಜಲಮಲಿ',
'ಜಲಮಾರ್ಗ',
'ಜಲಮುಚ',
'ಜಲಯಂತ್ರ',
'ಜಲಯಾತ್ರೆ',
'ಜಲಯಾನ',
'ಜಲಯುದ್ಧ',
'ಜಲರಂಕು',
'ಜಲರಂದ',
'ಜಲರಾಶಿ',
'ಜಲರಾಸಿ',
'ಜಲರುಹ',
'ಜಲಲವ',
'ಜಲಲಿಪಿ',
'ಜಲಲೇಖ',
'ಜಲವಟೆ',
'ಜಲವರ್ಣ',
'ಜಲವಾಹ',
'ಜಲವೈದ್ಯ',
'ಜಲವ್ಯಾಲ',
'ಜಲಶಂಕೆ',
'ಜಲಶಿಲೆ',
'ಜಲಶಿಲ್ಪಿ',
'ಜಲಶುಕ್ತಿ',
'ಜಲಶೂಕ',
'ಜಲಸಂದು',
'ಜಲಸಂಧಿ',
'ಜಲಸೂತ್ರ',
'ಜಲಸೇಕ',
'ಜಲಸ್ತಂಭ',
'ಜಲಸ್ಪರ್ಶ',
'ಜಲಹರಿ',
'ಜಲಾಂಜಲಿ',
'ಜಲಾಕರ',
'ಜಲಾಧಾರ',
'ಜಲಾಯಿಸು',
'ಜಲಾವರ್ತ',
'ಜಲಾಶಯ',
'ಜಲಾಶ್ರಯ',
'ಜಲೋಚ್ಛ್ವಾಸ',
'ಜಲೋದರ',
'ಜಲೋಪಲ',
'ಜಲೌಕಸ',
'ಜಲ್ಲಮಲ',
'ಜಲ್ಲಿಕಲ್ಲು',
'ಜಲ್ಲಿಗಾರೆ',
'ಜಲ್ಲಿದುಂಬಿ',
'ಜಲ್ಲಿಪಟ್ಟೆ',
'ಜಲ್ಲಿಬೇರು',
'ಜಲ್ಲಿಮಿಗ',
'ಜಲ್ಲಿಮೃಗ',
'ಜಲ್ಲುಗೋಡಿ',
'ಜಲ್ಲುಪಾಗು',
'ಜಲ್ಲೆಗೋಡು',
'ಜಲ್ಲೆವಲೆ',
'ಜವಂಗಿಡು',
'ಜವಂಗುಂದು',
'ಜವಂಗೆಡು',
'ಜವಂಜವ',
'ಜವಂಬೆಱು',
'ಜವಕಣೆ',
'ಜವಕಾರ',
'ಜವಗತಿ',
'ಜವಗಾತಿ',
'ಜವಗಿಡು',
'ಜವಗುಂದು',
'ಜವಗೆಡು',
'ಜವಚರ',
'ಜವಜವ',
'ಜವಣಿಗೆ',
'ಜವತೂಣ',
'ಜವದಂಡ',
'ಜವದಂಡೆ',
'ಜವದಾಡೆ',
'ಜವನಿಕೆ',
'ಜವಪುರ',
'ಜವಪುರಿ',
'ಜವರಾಜ',
'ಜವರಾಯ',
'ಜವವಳಿ',
'ಜವವೇಳು',
'ಜವಹುರ',
'ಜವಹುರಿ',
'ಜವಳಾಗು',
'ಜವಳಿಕ',
'ಜವಳಿಗ',
'ಜವಳಿಸು',
'ಜವಾಬ್ದಾರ',
'ಜವಾಬ್ದಾರಿ',
'ಜವಾಯಿಲ',
'ಜವಾಹಿರ',
'ಜವಾಹಿರಿ',
'ಜವಾಹಿರು',
'ಜವಾಹೀರ',
'ಜವಾಹೀರು',
'ಜವಿವಲೆ',
'ಜವುಗಿಡು',
'ಜವುಗೇಳ್',
'ಜವುಗೇೞ್',
'ಜವುಳೇಳು',
'ಜವೆಗೊಂಕು',
'ಜವೆಗೋದಿ',
'ಜವೆಗೋಧಿ',
'ಜವೆಬೂದಿ',
'ಜವ್ವನಿಕೆ',
'ಜವ್ವನಿಗ',
'ಜವ್ವನಿಗೆ',
'ಜವ್ವರಿಸು',
'ಜಸಂದಳೆ',
'ಜಸಂಬಡೆ',
'ಜಸಯುತ',
'ಜಸವಂತ',
'ಜಸವಡು',
'ಜಸವಡೆ',
'ಜಸವೆಱು',
'ಜಹಂಗೀರು',
'ಜಹಗೀರಿ',
'ಜಹಗೀರು',
'ಜಹಪನಾ',
'ಜಹಾಂಗೀರು',
'ಜಹಾಪನಾ',
'ಜಳಂಬಾಯ್ತ',
'ಜಳಕಂಗೈ',
'ಜಳಕನೆ',
'ಜಳಕಾಕ',
'ಜಳಕಿಸು',
'ಜಳಕೇಳಿ',
'ಜಳಕ್ಕನೆ',
'ಜಳಕ್ರೀಡೆ',
'ಜಳಚರ',
'ಜಳಜಂತ್ರ',
'ಜಳಜಿನಿ',
'ಜಳದಾನ',
'ಜಳದುರ್ಗ',
'ಜಳಧರ',
'ಜಳನಿಧಿ',
'ಜಳಪಕ್ಷಿ',
'ಜಳಪಿಸು',
'ಜಳಯಂತ್ರ',
'ಜಳಿಪಿಸು',
'ಜಳುಪಿಸು',
'ಜಳೂಕಿಕೆ',
'ಜಳ್ಳುಕಾಳು',
'ಜಳ್ಳುಜಂಭ',
'ಜಳ್ಳುತೆನೆ',
'ಜಳ್ಳುಮಾತು',
'ಜಳ್ಳುವೋಗು',
'ಜಱಿಗೊಳ್',
'ಜಱುಚಾಡು',
'ಜಱಱನೆ',
'ಜಾಂಗಲಿಕ',
'ಜಾಂಗಳಿಕ',
'ಜಾಂಗುಲಿಕ',
'ಜಾಂಗುಳಿಕ',
'ಜಾಂಡಲಕೆ',
'ಜಾಂಡಾಹೊಡೆ',
'ಜಾಂಬಾಯರ',
'ಜಾಂಬೂನದ',
'ಜಾಗಂಬೊಯ್',
'ಜಾಗಗೆಡ್ಡೆ',
'ಜಾಗಜೂಗ',
'ಜಾಗತಿಕ',
'ಜಾಗರಗೈ',
'ಜಾಗರಣ',
'ಜಾಗರಣೆ',
'ಜಾಗರಿಗ',
'ಜಾಗರಿತ',
'ಜಾಗರಿಸು',
'ಜಾಗರೂಕ',
'ಜಾಗೀರ್ದಾರ್',
'ಜಾಗುಮತಿ',
'ಜಾಗುಹಾಕು',
'ಜಾಗೃತಿಸು',
'ಜಾಗ್ರಾವಸ್ಥೆ',
'ಜಾಜಿಕಾಯ್',
'ಜಾಜಿಕಾಯಿ',
'ಜಾಜಿಪತ್ರೆ',
'ಜಾಟಕಾತಿ',
'ಜಾಟಗಾತಿ',
'ಜಾಟಗಾರ',
'ಜಾಟಿಗಾತಿ',
'ಜಾಡಗಿತ್ತಿ',
'ಜಾಡಬಲೆ',
'ಜಾಡಬುಡ',
'ಜಾಡಬೂಡ',
'ಜಾಡಮಾಲಿ',
'ಜಾಡಿಬೀಡಿ',
'ಜಾಡುಹಿಡಿ',
'ಜಾಡ್ಯತನ',
'ಜಾಡ್ಯಬುದ್ಧಿ',
'ಜಾಣಗಾತಿ',
'ಜಾಣಗಿತಿ',
'ಜಾಣತನ',
'ಜಾಣಪಣ',
'ಜಾಣವಕ್ಕಿ',
'ಜಾಣವಿಕೆ',
'ಜಾಣವೆಣ್',
'ಜಾಣವೆಣ್ಣು',
'ಜಾಣಾಯಿಲ',
'ಜಾಣೊಡೆಯ',
'ಜಾಣ್ಮರುಳ್',
'ಜಾಣ್ಮರುಳ',
'ಜಾತಕರ್ಮ',
'ಜಾತಕೇಳಿ',
'ಜಾತಗೈಸು',
'ಜಾತಪುಣ್ಯ',
'ಜಾತಪ್ರಿಯ',
'ಜಾತಮೂರ್ಖ',
'ಜಾತರಂಗ',
'ಜಾತರಾಸಿ',
'ಜಾತರೂಪ',
'ಜಾತವೇದ',
'ಜಾತಶಂಕಿ',
'ಜಾತಹರ್ಷ',
'ಜಾತಾಪತ್ಯೆ',
'ಜಾತಾಬಾಕಿ',
'ಜಾತಾಶೌಚ',
'ಜಾತಿಕರ್ಮ',
'ಜಾತಿಕಾರ',
'ಜಾತಿಕೋಶ',
'ಜಾತಿಗತ',
'ಜಾತಿಗಳ್ಳ',
'ಜಾತಿಗಾದೆ',
'ಜಾತಿಗಾರ',
'ಜಾತಿಗಾಱ',
'ಜಾತಿಗೊಯ್ಕ',
'ಜಾತಿದ್ವೇಷ',
'ಜಾತಿಧರ್ಮ',
'ಜಾತಿಪತ್ರೆ',
'ಜಾತಿಫಲ',
'ಜಾತಿಬೆಳ್',
'ಜಾತಿಭ್ರಷ್ಟ',
'ಜಾತಿಭ್ರಷ್ಟೆ',
'ಜಾತಿವಂತ',
'ಜಾತಿವಂತೆ',
'ಜಾತಿವಳ',
'ಜಾತಿವಾದ',
'ಜಾತಿವಾದಿ',
'ಜಾತಿವಾಸ',
'ಜಾತಿವೈರ',
'ಜಾತಿವ್ಯಾಪ್ತಿ',
'ಜಾತಿಸ್ಮರ',
'ಜಾತಿಸ್ಮರೆ',
'ಜಾತಿಹೀನ',
'ಜಾತೀಪತ್ರ',
'ಜಾತೀಫಲ',
'ಜಾತೀಫಳ',
'ಜಾತೀಯತೆ',
'ಜಾತೀಲತೆ',
'ಜಾತುಚಿತು',
'ಜಾತೋತ್ಸವ',
'ಜಾತ್ಯಂತರ',
'ಜಾತ್ಯಂಧಕ',
'ಜಾತ್ಯಂಧಕಿ',
'ಜಾತ್ಯತೀತ',
'ಜಾತ್ರೆಮಾಳ',
'ಜಾತ್ರೆಯೋಗು',
'ಜಾದಿಕಾಯ್',
'ಜಾದುಕಲ್',
'ಜಾದುಗಲ್',
'ಜಾದುಗಲ್ಲು',
'ಜಾದುಗಾರ',
'ಜಾದೂಕೋಲು',
'ಜಾದೂಗಾರ',
'ಜಾದೂಗಾರ್ತಿ',
'ಜಾದೂಗಿರಿ',
'ಜಾನಪದ',
'ಜಾನವಾರು',
'ಜಾನಶಾಲೆ',
'ಜಾನಸಾಲೆ',
'ಜಾನುದಘ್ನ',
'ಜಾನುವಾರು',
'ಜಾಪತ್ಕಾಲ',
'ಜಾಪುಗಾಲು',
'ಜಾಪುಹಾಕು',
'ಜಾಪ್ಯಗಾರ',
'ಜಾಬುಕಟ್ಟು',
'ಜಾಮಿತೀಯ',
'ಜಾಮೀನ್ದಾರ',
'ಜಾಯಮಾನ',
'ಜಾಯಾಜೀವ',
'ಜಾಯಾಜೀವಿ',
'ಜಾಯಾಸ್ಥಾನ',
'ಜಾಯಿಕಾಯಿ',
'ಜಾಯಿಪತ್ರ',
'ಜಾಯಿಪತ್ರೆ',
'ಜಾಯಿಪಳ',
'ಜಾಯಿಫಲ',
'ಜಾಯಿಫಳ',
'ಜಾರಕರ್ಮಿ',
'ಜಾರಜಾತ',
'ಜಾರಣಂಗೈ',
'ಜಾರತನ',
'ಜಾರನಾರಿ',
'ಜಾರವೆಣ್',
'ಜಾರಿಇನಾಂ',
'ಜಾರುಗಂಟು',
'ಜಾರುಗಣ್ಣು',
'ಜಾರುಗಲ್ಲು',
'ಜಾರುಗಾಡಿ',
'ಜಾರುಗುಂಡಿ',
'ಜಾರುಗುಪ್ಪೆ',
'ಜಾರುದಾರಿ',
'ಜಾರುಬಂಡಿ',
'ಜಾರುಬಂಡೆ',
'ಜಾರುಮೆಟ್ಟು',
'ಜಾರ್ರಾಮರ',
'ಜಾಲಂದರ',
'ಜಾಲಂಧರ',
'ಜಾಲಕಾಱ',
'ಜಾಲಗಾತಿ',
'ಜಾಲಗಾರ',
'ಜಾಲಗಾರ್ತಿ',
'ಜಾಲಗಾಱ',
'ಜಾಲತನ',
'ಜಾಲಪಾದ',
'ಜಾಲಪಾದಿ',
'ಜಾಲಬಂಕೆ',
'ಜಾಲಾಂತರ',
'ಜಾಲಿಬಂಕೆ',
'ಜಾಲೆಗಾಱ',
'ಜಾಲ್ಮಜಲ್ಪ',
'ಜಾವಜ್ಜೀವ',
'ಜಾವಳಿಗ',
'ಜಾವಳಿಸು',
'ಜಾವಾಅತ್ತಿ',
'ಜಾಸುದ್ದಾರ',
'ಜಾಹಿರಾತು',
'ಜಾಹಿರ್ನಾಮ',
'ಜಾಹೀರಾತಿ',
'ಜಾಹೀರಾತು',
'ಜಾಳಂದರ',
'ಜಾಳಂಧರ',
'ಜಾಳಗಪ್ಪೆ',
'ಜಾಳಮಾರ್ಗ',
'ಜಾಳರಿಗೆ',
'ಜಾಳವಣೆ',
'ಜಾಳವಿಸು',
'ಜಾಳಾಂದರ',
'ಜಾಳಾಂಧರ',
'ಜಾಳುಜೀವ',
'ಜಾಳುತನ',
'ಜಾಳುದೈವ',
'ಜಾಳುನುಡಿ',
'ಜಾಳುಮಾತು',
'ಜಾಳುವಲೆ',
'ಜಾಳುವೆಣ್',
'ಜಾಱುಂಗಲ್',
'ಜಾಱುಕಲ್',
'ಜಾಱುಗಲ್ಲು',
'ಜಾಱುವಲೆ',
'ಜಾಱುವೊಯ್ಲು',
'ಜಾಱುವೋಗು',
'ಜಿಂಕರಿಸು',
'ಜಿಂಕುವರಿ',
'ಜಿಂಜರಗಿ',
'ಜಿಂದಗಾನಿ',
'ಜಿಂದಾಬಾದ್',
'ಜಿಂಹಭಾವ',
'ಜಿಕಿಜಿಕಿ',
'ಜಿಗಿದೋಱು',
'ಜಿಗಿಬಾಲ',
'ಜಿಜ್ಞಾಸನ',
'ಜಿಟಿಜಿಟಿ',
'ಜಿಡಿಮಳೆ',
'ಜಿಡ್ಡುಗಂಬ',
'ಜಿಡ್ಡುಗಟ್ಟು',
'ಜಿಡ್ಡುಮುಳ್ಳು',
'ಜಿತಕರ್ಮ',
'ಜಿತಕಾಮ',
'ಜಿತಕಾಮೆ',
'ಜಿತಕಾಶಿ',
'ಜಿತಪಡು',
'ಜಿತಬುದ್ಧಿ',
'ಜಿತಮಾಯ',
'ಜಿತಾಕ್ಷರ',
'ಜಿತೇಂದ್ರಿಯ',
'ಜಿದ್ದುಕಟ್ಟು',
'ಜಿನಗಾಳೆ',
'ಜಿನಗೇಹ',
'ಜಿನಚೈತ್ಯ',
'ಜಿನಧರ್ಮ',
'ಜಿನಮತ',
'ಜಿನಾಗಮ',
'ಜಿನಿಗಿಸು',
'ಜಿನಿಜಿನಿ',
'ಜಿನುಗಿಸು',
'ಜಿನ್ನೀವಾಸ',
'ಜಿಪುಣತೆ',
'ಜಿಬಟಿಗ',
'ಜಿಬ್ಬಳಿಕೆ',
'ಜಿಮಿಗುಡು',
'ಜಿರಗುಟ್ಟು',
'ಜಿರಾಪತಿ',
'ಜಿರಾಯತ',
'ಜಿರಾಯತಿ',
'ಜಿರಾಯಿತ',
'ಜಿರಾಯಿತಿ',
'ಜಿರಾಯಿತು',
'ಜಿರಾವರ್ತಿ',
'ಜಿರುದುಂಬಿ',
'ಜಿಲಿಬಿಲಿ',
'ಜಿಲಿಬಿಲ್ಲಿ',
'ಜಿಲ್ಲೆದಾರ',
'ಜಿವಿಹಿಡಿ',
'ಜಿಷ್ಣುಚಾಪ',
'ಜಿಹ್ವಾಬಂಧ',
'ಜಿಹ್ವಾಮೂಲ',
'ಜಿಹ್ವಾಲಸ',
'ಜಿಹ್ವಸ್ತಂಭ',
'ಜೀಂಕರಿಸು',
'ಜೀಕಹೊಡೆ',
'ಜೀಕೊಳವಿ',
'ಜೀಕೊಳವೆ',
'ಜೀಟುಗಾಲು',
'ಜೀಟುಗೋಲು',
'ಜೀತಕಾರ',
'ಜೀತಗಾರ',
'ಜೀನಗಾರ',
'ಜೀನತನ',
'ಜೀನವೆಸ',
'ಜೀನಶಾಲೆ',
'ಜೀನಹಂಕ',
'ಜೀನುಗಾರ',
'ಜೀನುಹಂಕ',
'ಜೀಪುಕೊಡು',
'ಜೀಪುಗಾಲು',
'ಜೀಪುಗೊಡು',
'ಜೀರಗಂಡಿ',
'ಜೀರದಂಡ',
'ಜೀರುಗೂಡು',
'ಜೀರುಜಿಂಬೆ',
'ಜೀರುದುಂಬಿ',
'ಜೀರ್ಕೊಳವಿ',
'ಜೀರ್ಕೊಳವೆ',
'ಜೀರ್ಕೊೞವಿ',
'ಜೀರ್ಕೊೞವೆ',
'ಜೀರ್ಗುಣಿಕೆ',
'ಜೀರ್ಗೊಳವಿ',
'ಜೀರ್ಗೊಳವೆ',
'ಜೀರ್ಣಕಾರಿ',
'ಜೀರ್ಣಕೋಶ',
'ಜೀರ್ಣಚ್ಛದ',
'ಜೀರ್ಣಜ್ವರ',
'ಜೀರ್ಣತನ',
'ಜೀರ್ಣರಸ',
'ಜೀರ್ಣಾವಸ್ಥೆ',
'ಜೀರ್ಣೋದ್ಧಾರ',
'ಜೀವಂಜೀವ',
'ಜೀವಂತತೆ',
'ಜೀವಂತಿಕೆ',
'ಜೀವಂಬಡೆ',
'ಜೀವಂಬೊಯ್',
'ಜೀವಕಣ',
'ಜೀವಕಲೆ',
'ಜೀವಕಳೆ',
'ಜೀವಕಾಲ',
'ಜೀವಕೊಡು',
'ಜೀವಕೋಟಿ',
'ಜೀವಕೋಶ',
'ಜೀವಕ್ರಿಯೆ',
'ಜೀವಗಳ್ಳ',
'ಜೀವಗುಣ',
'ಜೀವಗೇಹ',
'ಜೀವಗೊಳು',
'ಜೀವಗೋಳ',
'ಜೀವಗ್ರಾಹ',
'ಜೀವಘಾತ',
'ಜೀವಚಕ್ರ',
'ಜೀವಚಯ',
'ಜೀವಚ್ಛವ',
'ಜೀವಜಾತ',
'ಜೀವತತ್ತ್ವ',
'ಜೀವತರು',
'ಜೀವತುಂಬು',
'ಜೀವತೆಗೆ',
'ಜೀವತೇಯು',
'ಜೀವತೋಕೆ',
'ಜೀವತ್ತೋಕೆ',
'ಜೀವತ್ಪತಿ',
'ಜೀವತ್ಪಿತೃ',
'ಜೀವತ್ಯಾಗ',
'ಜೀವದಯೆ',
'ಜೀವದಾತ',
'ಜೀವದಾತೆ',
'ಜೀವದಾನ',
'ಜೀವದ್ಭಾಷೆ',
'ಜೀವದ್ರವ್ಯ',
'ಜೀವಧನ',
'ಜೀವಧಾತ್ರಿ',
'ಜೀವಧಾನಿ',
'ಜೀವಧಾರೆ',
'ಜೀವನದಿ',
'ಜೀವನಾಂಶ',
'ಜೀವನಾಡಿ',
'ಜೀವನಾರ್ಥ',
'ಜೀವನಾಲ',
'ಜೀವನಿಧಿ',
'ಜೀವನೀಯ',
'ಜೀವನೀಯೆ',
'ಜೀವನ್ಮುಕ್ತ',
'ಜೀವನ್ಮುಕ್ತಿ',
'ಜೀವನ್ಮತ',
'ಜೀವನ್ಮತೆ',
'ಜೀವಪಚ್ಚೆ',
'ಜೀವಪಡೆ',
'ಜೀವಪತ್ನಿ',
'ಜೀವಪೃಷ್ಠ',
'ಜೀವಭದ್ರ',
'ಜೀವಭಾವ',
'ಜೀವಭಾವಿ',
'ಜೀವಭ್ರಮೆ',
'ಜೀವಭ್ರಾಂತಿ',
'ಜೀವಮಾನ',
'ಜೀವರಕ್ಕೆ',
'ಜೀವರಕ್ತ',
'ಜೀವರಕ್ಷೆ',
'ಜೀವರತ್ನ',
'ಜೀವರಸ',
'ಜೀವರಾಶಿ',
'ಜೀವವಧೆ',
'ಜೀವವಾಣಿ',
'ಜೀವವಾರ',
'ಜೀವವಿಮೆ',
'ಜೀವವ್ರಯ',
'ಜೀವವ್ರಾತ',
'ಜೀವಶಂಖ',
'ಜೀವಶಾಸ್ತ್ರ',
'ಜೀವಶೂನ್ಯ',
'ಜೀವಶ್ರವ',
'ಜೀವಸಖ',
'ಜೀವಸತ್ವ',
'ಜೀವಸಮ',
'ಜೀವಸಾಕ್ಷಿ',
'ಜೀವಸಿದ್ಧಿ',
'ಜೀವಸ್ಥಾನ',
'ಜೀವಸ್ಫೂರ್ತಿ',
'ಜೀವಸ್ಮತಿ',
'ಜೀವಹತಿ',
'ಜೀವಹಾನಿ',
'ಜೀವಹಾಲ',
'ಜೀವಹಾಲೆ',
'ಜೀವಹಿಂಡು',
'ಜೀವಹಿಂಸೆ',
'ಜೀವಾಂತಕ',
'ಜೀವಾಕ್ಷರ',
'ಜೀವಾತುಮ',
'ಜೀವಾಧಾರ',
'ಜೀವಾವಧಿ',
'ಜೀವಿಕುಡಿ',
'ಜೀವಿತೇಶ',
'ಜೀವಿಧರ್ಮ',
'ಜೀವಿಸುಹ',
'ಜೀವುಂಡಿಗೆ',
'ಜುಂಜುಕೇಶ',
'ಜುಂಜುಗೇಶ',
'ಜುಂಜುದಲೆ',
'ಜುಂಜುಮಳೆ',
'ಜುಂಜುಮೀಸೆ',
'ಜುಂಜುರ್ದಲೆ',
'ಜುಂಜುವಳೆ',
'ಜುಂಜುಱಿಸು',
'ಜುಕಾಯಿಸು',
'ಜುಗುಪ್ಸನ',
'ಜುಗುಪ್ಸಿತ',
'ಜುಗ್ಗತನ',
'ಜುಗ್ಗುತನ',
'ಜುಜಾವಂತಿ',
'ಜುಟ್ಟುಕಟ್ಟು',
'ಜುಟ್ಟುಗಂಟು',
'ಜುಟ್ಟುಬಿಡು',
'ಜುಣುಗಿಸು',
'ಜುಣುಗುಟ್ಟು',
'ಜುಮುಗುಟ್ಟು',
'ಜುಮ್ಮುಗಾರ',
'ಜುಮ್ಮುಗುಟ್ಟು',
'ಜುಮ್ಮುದಟ್ಟು',
'ಜುಮ್ಮುಹಿಡಿ',
'ಜುಮ್ಲೆದಾರ',
'ಜುರಮಾನು',
'ಜುರುಮಾನೆ',
'ಜುಲುಮಾನೆ',
'ಜುವ್ವಾಕೋರ',
'ಜುಳುಜುಳು',
'ಜುಱುಜುಱು',
'ಜೂಕರಿಸು',
'ಜೂಗರಿಸು',
'ಜೂಗಳಿಸು',
'ಜೂಜಾಟಿಕೆ',
'ಜೂಜಾಡಿಗ',
'ಜೂಜಾಡಿಸು',
'ಜೂಜುಂಗಾಱ',
'ಜೂಜುಕಟ್ಟು',
'ಜೂಜುಕಟ್ಟೆ',
'ಜೂಜುಕೋರ',
'ಜೂಜುಗಾರ',
'ಜೂಜುಗಾರ್ತಿ',
'ಜೂಜುಗಾಱ',
'ಜೂಜುದೋಣಿ',
'ಜೂಟಬಂಧ',
'ಜೂಟುನುಡಿ',
'ಜೂಟುರೇಷ್ಮೆ',
'ಜೂಡಿದಾರ',
'ಜೂದುಗಾರ',
'ಜೂದುಗಾಱ',
'ಜೂದುಜಾಣ',
'ಜೂಲುನಾಯಿ',
'ಜೂಳಿಗಿಂಡಿ',
'ಜೃಂಭೆಗೆಯ್',
'ಜೆಂಬುದೀವಿ',
'ಜೆಕರ್ಮದ',
'ಜೆಟ್ಟಿಕೋೞಿ',
'ಜೆಟ್ಟಿದೆಱೆ',
'ಜೆಡೆಕಳ್ಳಿ',
'ಜೆಡೆಗಟ್ಟು',
'ಜೆಡೆಗೊಳ್',
'ಜೆಡೆದಲೆ',
'ಜೆಡೆಮುಡಿ',
'ಜೆಡ್ಡುಗಟ್ಟು',
'ಜೆಡ್ಡುಬೆತ್ತ',
'ಜೇಂಕರಿಸು',
'ಜೇಡಬುಡ',
'ಜೇಡಿಮಣ್ಣು',
'ಜೇಣಶಾಲೆ',
'ಜೇನವರೆ',
'ಜೇನಶಾಲೆ',
'ಜೇನಸಾಲೆ',
'ಜೇನುಂಬುಳು',
'ಜೇನುಕಡ್ಡಿ',
'ಜೇನುಕೃಷಿ',
'ಜೇನುಗೂಡು',
'ಜೇನುತುಪ್ಪ',
'ಜೇನುನೊಣ',
'ಜೇನುಬಾಳೆ',
'ಜೇನುಮನೆ',
'ಜೇನುಮೇಣ',
'ಜೇನುವುಟ್ಟಿ',
'ಜೇನುಹಲ್ಲೆ',
'ಜೇನುಹುಟ್ಟಿ',
'ಜೇನುಹುಟ್ಟು',
'ಜೇನುಹುಳು',
'ಜೇನ್ಗನಸು',
'ಜೇಬುಗಳ್ಳ',
'ಜೇರುಗಂಡಿ',
'ಜೇರುಗಿಂಡಿ',
'ಜೇರುಗೆಯ್',
'ಜೇರುಬಂದ',
'ಜೇರುಬಂದು',
'ಜೇಲಖಾನೆ',
'ಜೇವಡಿಸು',
'ಜೇವಡೆಗೈ',
'ಜೇವಣಿಕೆ',
'ಜೇವಣಿಗೆ',
'ಜೇಷ್ಟಮಧು',
'ಜೈತ್ರಯಾತ್ರೆ',
'ಜೈತ್ರಲಕ್ಷ್ಮಿ',
'ಜೈನತನ',
'ಜೈನಧರ್ಮ',
'ಜೈನಮತ',
'ಜೈನವೃತ್ತಿ',
'ಜೈನಾಗಮ',
'ಜೈಮಿನೀಯ',
'ಜೈವಕ್ರಿಯೆ',
'ಜೈವಾತೃಕ',
'ಜೊಂಡಗರ್ಬು',
'ಜೊಂಡೆಗರ್ಬು',
'ಜೊತೆಗಾತಿ',
'ಜೊತೆಗಾರ',
'ಜೊತೆಗಾರ್ತಿ',
'ಜೊತೆಗೂಡು',
'ಜೊತೆಬಿಡು',
'ಜೊತೆವಾಡು',
'ಜೊತೆಹಾಕು',
'ಜೊತ್ತಗಲ್',
'ಜೊತ್ತಗಾಱ',
'ಜೊತ್ತುಗಾರ',
'ಜೊತ್ತುಗಾಱ',
'ಜೊನ್ನರಸ',
'ಜೊನ್ನವಕ್ಕಿ',
'ಜೊನ್ನೊಡಲ',
'ಜೊಮ್ಮುತಟ್ಟು',
'ಜೊಮ್ಮುವಿಡಿ',
'ಜೊಲ್ಲುತನ',
'ಜೊಳಿಪಿಳಿ',
'ಜೊಳ್ಳುಗಾಳು',
'ಜೋಕುಗಾರ',
'ಜೋಕುಗಾರ್ತಿ',
'ಜೋಕುಮಾರ',
'ಜೋಕುವರ',
'ಜೋಕೆಗಾಱ',
'ಜೋಕೆಗುಟ್ಟು',
'ಜೋಕೆಗೊಳ್',
'ಜೋಕೆವಿಡಿ',
'ಜೋಕೆವೆಱು',
'ಜೋಕೊವರ',
'ಜೋಗಂಬೋಗು',
'ಜೋಗಮಾಯಿ',
'ಜೋಗಯಿಸು',
'ಜೋಗರಿಸು',
'ಜೋಗವಟ್ಟೆ',
'ಜೋಗವಿಸು',
'ಜೋಗವೋಗು',
'ಜೋಗಿಜೆಡೆ',
'ಜೋಗಿಣಿತಿ',
'ಜೋಗುಗೊಳ್',
'ಜೋಡಗಲ್',
'ಜೋಡಗಲು',
'ಜೋಡಗಿರಿ',
'ಜೋಡತನ',
'ಜೋಡವಣೆ',
'ಜೋಡಾಣಿಕೆ',
'ಜೋಡಿಗೆಡೆ',
'ಜೋಡಿಗ್ರಾಮ',
'ಜೋಡಿತೆಂಗು',
'ಜೋಡಿದಾರ',
'ಜೋಡಿದಾರಿ',
'ಜೋಡುಕಟ್ಟು',
'ಜೋಡುಕಾರ್ತಿ',
'ಜೋಡುಖಾತೆ',
'ಜೋಡುಗೂಡು',
'ಜೋಡುಗೊಡು',
'ಜೋಡುನುಡಿ',
'ಜೋಡುವಕ್ಕಿ',
'ಜೋಡುವಲೆ',
'ಜೋಡುಶಬ್ದ',
'ಜೋಡೆಗೆಯ್ತ',
'ಜೋತಿಕಾಷ್ಠ',
'ಜೋತಿಷ್ಮತಿ',
'ಜೋತೀಸ್ವರ',
'ಜೋನಿಗರೆ',
'ಜೋನಿಬೆಲ್ಲ',
'ಜೋನೆಬೆಲ್ಲ',
'ಜೋಪಾಯನ',
'ಜೋಪಾಸನ',
'ಜೋಪಾಸನೆ',
'ಜೋಮುಹಿಡಿ',
'ಜೋಯಿಸಿಗ',
'ಜೋರಾವರಿ',
'ಜೋರುದಾರ',
'ಜೋರುದಾರಿ',
'ಜೋರುಮುಡಿ',
'ಜೋಲಿಕೊಡು',
'ಜೋಲಿಗಾರ',
'ಜೋಲಿತಪ್ಪು',
'ಜೋಲಿತಿನ್ನು',
'ಜೋಲಿಹಿಡಿ',
'ಜೋಲಿಹೊಡೆ',
'ಜೋಲಿಹೋಗು',
'ಜೋಲುಗಾಲು',
'ಜೋಲುಗಿವಿ',
'ಜೋಲುಬಿಡು',
'ಜೋಲುಬೀಳು',
'ಜೋಲುಮುಖ',
'ಜೋಲುಮೋರೆ',
'ಜೋಷವಾಕ್ಯ',
'ಜೋಹಡಿಗ',
'ಜೋಹರಿಸು',
'ಜೋಹಾರುಗೈ',
'ಜೋಳಗಳ್ಳ',
'ಜೋಳಮೈಲಿ',
'ಜೋಳವಾಳಿ',
'ಜೋಳವಾೞಿ',
'ಜೋಳವಿಸು',
'ಜೋಳಿಗಟ್ಟು',
'ಜೋಳಿದೆಗೆ',
'ಜೋೞವಾಳಿ',
'ಜೌಗುನೆಲ',
'ಜೌಗುಭೂಮಿ',
'ಜೌಗೇೞಿಸು',
'ಜ್ಞಾತಿಕೋಶ',
'ಜ್ಞಾತಿವೈರ',
'ಜ್ಞಾತೃನಿಷ್ಠ',
'ಜ್ಞಾನಕಾಂಡ',
'ಜ್ಞಾನಕಾಂಡಿ',
'ಜ್ಞಾನಗಮ್ಯ',
'ಜ್ಞಾನಚಕ್ಷು',
'ಜ್ಞಾನಚೂರ್ಣ',
'ಜ್ಞಾನತಂತು',
'ಜ್ಞಾನತಪ್ಪು',
'ಜ್ಞಾನದಾಹ',
'ಜ್ಞಾನದಾಹಿ',
'ಜ್ಞಾನದೃಷ್ಟಿ',
'ಜ್ಞಾನಧನ',
'ಜ್ಞಾನಪಂಥ',
'ಜ್ಞಾನಪಂಥಿ',
'ಜ್ಞಾನಬರು',
'ಜ್ಞಾನಭಿಕ್ಷು',
'ಜ್ಞಾನಮದ',
'ಜ್ಞಾನಮಯ',
'ಜ್ಞಾನಮಾರ್ಗ',
'ಜ್ಞಾನಯಜ್ಞ',
'ಜ್ಞಾನಯೋಗ',
'ಜ್ಞಾನಯೋಗಿ',
'ಜ್ಞಾನಲೇಪಿ',
'ಜ್ಞಾನವಂತ',
'ಜ್ಞಾನವಂತೆ',
'ಜ್ಞಾನವಾಹಿ',
'ಜ್ಞಾನವೃದ್ಧ',
'ಜ್ಞಾನಶೂನ್ಯ',
'ಜ್ಞಾನಶೂನ್ಯೆ',
'ಜ್ಞಾನಹೀನ',
'ಜ್ಞಾನಹೀನೆ',
'ಜ್ಞಾನೇಂದ್ರಿಯ',
'ಜ್ಞಾನೋದಯ',
'ಜ್ಞಾಪಕಾರ್ಥ',
'ಜ್ಞೇಯನಿಷ್ಠ',
'ಜ್ಞೇಯಾನಂದ',
'ಜ್ಯಾಮಂಡಲ',
'ಜ್ಯೋತಿಃಪ್ರಭೆ',
'ಜ್ಯೋತಿರಥ',
'ಜ್ಯೋತಿರ್ಗಣ',
'ಜ್ಯೋತಿಜ್ರ್ಞಾನ',
'ಜ್ಯೋತಿರ್ಮೂರ್ತಿ',
'ಜ್ಯೋತಿರ್ಮೇಘ',
'ಜ್ಯೋತಿರ್ಲತೆ',
'ಜ್ಯೋತಿರ್ಲಿಂಗ',
'ಜ್ಯೋತಿರ್ಲೋಕ',
'ಜ್ಯೋತಿರ್ವರ್ಷ',
'ಜ್ಯೋತಿರ್ವಿದ',
'ಜ್ಯೋತಿಶ್ಚಕ್ರ',
'ಜ್ಯೋತಿಶ್ಶಾಸ್ತ್ರ',
'ಜ್ಯೋತಿಷಿಕ',
'ಜ್ಯೋತಿಷ್ಟೋಮ',
'ಜ್ಯೋತಿಷ್ಮತಿ',
'ಜ್ಯೌತಿಷಿಕ',
'ಜ್ವರಯಿಸು',
'ಜ್ವಲನದ',
'ಜ್ವಾಲಾಗ್ರಾಹಿ',
'ಜ್ವಾಲಾಮುಖಿ',
'ಝಂಕರಿಸು',
'ಝಂಕಾರಿತ',
'ಝಂಝಾನಿಲ',
'ಝಂಝಾವಾತ',
'ಝಂಪಳಿಸು',
'ಝಕ್ಕುಳಿಸು',
'ಝಟಪಟ',
'ಝಡಪಿಸು',
'ಝಣತ್ಕಾರ',
'ಝಣತ್ಕೃತಿ',
'ಝಪಝಪ',
'ಝಲ್ಲಿಮಿಗ',
'ಝಲ್ಲಿಮೃಗ',
'ಝಲ್ಲುಗೊಳ್',
'ಝಸಂಬಡೆ',
'ಝಳಂಪಿಸು',
'ಝಳಂಬಾಯ್ತು',
'ಝಳಪಿಸು',
'ಝಳುಂಪಿಸು',
'ಝಳುಂಬಕ',
'ಝಳುಪಿಸು',
'ಝಾಂಡಹೂಡು',
'ಝಾಂಡಾಹಾಕು',
'ಝಾಡಮಾಲಿ',
'ಝಾಡುಮಾಲಿ',
'ಝಾಪುಗಾಲು',
'ಝುರಮತ್ತು',
'ಝುಲುಮ್ಮನೆ',
'ಝೇಂಕರಿಸು',
'ಝೋಲಿತಪ್ಪು',
'ಝೋಲಿಹೊಡೆ',
'ಟಂಕಕ್ಷತ',
'ಟಂಕಮುದ್ರೆ',
'ಟಂಕರಿಸು',
'ಟಂಕಶಾಲೆ',
'ಟಂಕಸಾಲೆ',
'ಟಂಕಾರಿಸು',
'ಟಂಟಣಿಸು',
'ಟಂಠಣಿಸು',
'ಟಕಮಕ',
'ಟಕಾಯಿಸು',
'ಟಕ್ಕುಟೌಳಿ',
'ಟಕ್ಕುತನ',
'ಟಕ್ಕುದೋಱು',
'ಟಕ್ಕುಹಿಡಿ',
'ಟಣಕಲಿ',
'ಟಪಾರಿಕೆ',
'ಟಪ್ಪೆನಿಲ್ಲು',
'ಟಪ್ಪೆಯಿಡು',
'ಟರಾಯಿಸು',
'ಟರ್ಕಿಕೋಳಿ',
'ಟರ್ಪಂಟೈನು',
'ಟಲಾಯಿಸು',
'ಟವಟವ',
'ಟವಾಳಿಸು',
'ಟಳಾಯಿಸು',
'ಟಾಂಗಾವಾಲ',
'ಟಾಕಾಪೂರ್ತಿ',
'ಟಾಕುಟೀಕು',
'ಟಾಕೋಟಾಕು',
'ಟಾಣಕೋಲು',
'ಟಾಣಾಂತರ',
'ಟಾಪರಿಸು',
'ಟಾಪುಟೀಪು',
'ಟಾಪುಶಿಕ್ಷೆ',
'ಟಾಪೂಶಿಕ್ಷೆ',
'ಟಾಳಿಕಾಱ',
'ಟಿಂವ್‍ಗುಡು',
'ಟಿಕಾಯಿಸು',
'ಟಿಟ್ಟಿಭಕ',
'ಟಿಪಾಯಿಸು',
'ಟಿಪ್ಪಣಿಸು',
'ಟಿಬ್ಬರಿಸು',
'ಟಿಸಲೊಡೆ',
'ಟಿಸಿಲೊಡೆ',
'ಟೀಕಾಕಾರ',
'ಟೀಕಾಚಿತ್ರ',
'ಟೀಕುವರೆ',
'ಟೀಕೆಬರೆ',
'ಟೂನಮರ',
'ಟೆಂಕದೆಸೆ',
'ಟೆಂಟಣಿಸು',
'ಟೆಂಟೆಣಿಸು',
'ಟೆಂಠಣಿಸು',
'ಟೆಂಠಿಣಿಸು',
'ಟೆಂಠೆಣಿಸು',
'ಟೆಪಾರಿಕೆ',
'ಟೆಪ್ಪರಿಸು',
'ಟೆಬ್ಬರಿಸು',
'ಟೆಲಿಗ್ರಾಮ್',
'ಟೆಲಿಗ್ರಾಮು',
'ಟೆಲಿಗ್ರಾಫ್',
'ಟೆಲಿಗ್ರಾಫು',
'ಟೆಲಿಫೋನು',
'ಟೆಲಿಸ್ಕೋಪು',
'ಟೊಂಕಕಟ್ಟು',
'ಟೊಂಕಹಾಕು',
'ಟೊಂಕಾಯಿಸು',
'ಟೊಳ್ಳುಹಂಚಿ',
'ಟೋಕುತಾಳೆ',
'ಟೋಪಿಕಾರ',
'ಟೋಲನಾಕೆ',
'ಟೋಲುಗೇಟು',
'ಟೋಳಿಕಟ್ಟು',
'ಟೌಳಿಕಾರ',
'ಟೌಳಿಕಾಱ',
'ಟೌಳಿತನ',
'ಟ್ಯೂಬುರೈಲು',
'ಟ್ಯೂಬುಲೈಟು',
'ಟ್ರಾನ್ಸಿಸ್ಟರು',
'ಠಂಕರಿಸು',
'ಠಂಕಸಾಲೆ',
'ಠಕಾಯಿಸು',
'ಠಕಾರಿಕೆ',
'ಠಕ್ಕತನ',
'ಠಕ್ಕವಿದ್ಯೆ',
'ಠಕ್ಕುಕಾತಿ',
'ಠಕ್ಕುಗಾರ',
'ಠಕ್ಕುಗಾರ್ತಿ',
'ಠಕ್ಕುಗಾಱ',
'ಠಕ್ಕುಗೊಳ್',
'ಠಕ್ಕುತನ',
'ಠಕ್ಕುಮತ',
'ಠಕ್ಕುವೀೞ್',
'ಠಣತ್ಕಾರ',
'ಠಮಾಳಿಸು',
'ಠರಾಯಿಸು',
'ಠಲಾಯಿಸು',
'ಠವಣಿಸು',
'ಠವಾಳಿಸು',
'ಠಳಾಯಿಸು',
'ಠಾಕ್‍ಠೀಕ್',
'ಠಾಕುಟೀಕು',
'ಠಾಕುಠೀಕು',
'ಠಾಕೋಠಾಕು',
'ಠಾಕೋಠೀಕು',
'ಠಾಣಗಾಱ',
'ಠಾಣದಾರ',
'ಠಾಣವಿಕ್ಕು',
'ಠಾಣಾಂತರ',
'ಠಾಣಾಂದರ',
'ಠಾಣಾದೀಪ',
'ಠಾಣೆಗಾರ',
'ಠಾಣೆಗೋಲು',
'ಠಾಣೆದಾರ',
'ಠಾಣೇದಾರಿ',
'ಠಾನೆದಾರ',
'ಠಾವುಗಾಣ್',
'ಠಾವುಗೈಸು',
'ಠಾವುಗೊಳ್',
'ಠೀಕುಗಾರ',
'ಠೇಂಕರಿಸು',
'ಠೇವಣಾತಿ',
'ಠೋಕತಾಳೆ',
'ಠೌಳಿಕಾಱ',
'ಠೌಳಿಗಾರ',
'ಡಂಕುಹಿಡಿ',
'ಡಂಗಕೂರು',
'ಡಂಗಹಾಕು',
'ಡಂಗಹೊಡೆ',
'ಡಂಗಾಯಿತ',
'ಡಂಗುರಿಗ',
'ಡಂಗುರಿಸು',
'ಡಂಗೆಗೊಳ್',
'ಡಂಢಣಿಸು',
'ಡಂಬಗಾರ',
'ಡಂಬವೆಣ್ಣು',
'ಡಂಬಾಚಾರ',
'ಡಂಬಾಚಾರಿ',
'ಡಂಬಾಟಿಕೆ',
'ಡಂಭತನ',
'ಡಂಭಾಚಾರ',
'ಡಂಭಾಚಾರಿ',
'ಡಕಾಯತಿ',
'ಡಕಾಯಿತ',
'ಡಕಾಯಿತಿ',
'ಡಕ್ಕಾಡಿಕ್ಕಿ',
'ಡಕ್ಕುಗೊಳ್',
'ಡಕ್ಕೆಬಲಿ',
'ಡಕ್ಕೆವಾಯ್',
'ಡಗೆವೊಯ್',
'ಡಗೆಹೊಯ್',
'ಡಗ್ಗುತ್ತಿಗೆ',
'ಡಗ್ರಿಹಗ್ಗ',
'ಡಣತ್ಕಾರ',
'ಡಣಾಕೃತಿ',
'ಡಣಾಯಕ',
'ಡಣ್ಣಾಯಕ',
'ಡಪ್ಪುಗಾರ',
'ಡಪ್ಪುಬಡಿ',
'ಡಬಗಳ್ಳಿ',
'ಡಬ್ಬಗಳ್ಳಿ',
'ಡಬ್ಬುಗಳ್ಳಿ',
'ಡಬ್ಬುಗಾತಿ',
'ಡಬ್ಬುಗಾರ',
'ಡಬ್ಬುಹಂಚು',
'ಡಬ್ಬುಹಾಕು',
'ಡಮರುಕ',
'ಡಮರುಗ',
'ಡಲಾಯತ',
'ಡಲಾಯಿತ',
'ಡವಗುಟ್ಟು',
'ಡವರಿಗ',
'ಡವರಿಸು',
'ಡವರುಗ',
'ಡವಮಾನ',
'ಡಳಮಳ',
'ಡಳಾಯಿತ',
'ಡಾಂಬರೆಣ್ಣೆ',
'ಡಾಕ್ಟರಿಕೆ',
'ಡಾಗದೊರೆ',
'ಡಾಣಾಡಾಣಿ',
'ಡಾಮರ್ಬಂಕೆ',
'ಡಾಲುಹೊಡೆ',
'ಡಾವಪೇಚು',
'ಡಾವರಿಗ',
'ಡಾವರಿಸು',
'ಡಾವುಪೇಚು',
'ಡಾಳಯಿಸು',
'ಡಾಳವಿಡಿ',
'ಡಾಳವಿಸು',
'ಡಿಂಗರಿಗ',
'ಡಿಂಗಿದೋಣಿ',
'ಡಿಂಬಯುದ್ಧ',
'ಡಿಂಬಾವಹ',
'ಡಿಕಮಲಿ',
'ಡಿಕ್ಕಿಕೊಡು',
'ಡಿಕ್ಕಿಮಾಲೆ',
'ಡಿಕ್ಕಿಹೊಡೆ',
'ಡಿಕ್ರಿದಾರ',
'ಡಿಪ್ತೀರಿಯ',
'ಡಿವಿಡಿವಿ',
'ಡಿಳ್ಳಯಿಸು',
'ಡಿಳ್ಳವಾಱು',
'ಡಿಳ್ಳವೋಗು',
'ಡಿಳ್ಳುಹೋಗು',
'ಡುಗಡುಗಿ',
'ಡುಗುಡುಗಿ',
'ಡುಬಾಯಿಸು',
'ಡುಬ್ಬಕೆತ್ತು',
'ಡುರುಕಿಡು',
'ಡೆಂಕಣಿಕೈ',
'ಡೆಂಕಣಿಗೈ',
'ಡೆಂಕಣೆಗೈ',
'ಡೆಂಕೆಬರ',
'ಡೆಂಡಣಿಸು',
'ಡೆಂಡೆಣಿಸು',
'ಡೆಂಢಣಿಸು',
'ಡೆಂಢಳಿಸು',
'ಡೆಂಢೆಣಿಸು',
'ಡೆಕ್ಕರಿಕ್ಕೆ',
'ಡೆಗ್ಗೆವರ',
'ಡೇಗೆಕಣ್ಣು',
'ಡೇಗೆಬೇಗ',
'ಡೈನಾಮೈಟ್',
'ಡೊಂಕತನ',
'ಡೊಂಕಬಂಕ',
'ಡೊಂಕುಗಾಲು',
'ಡೊಂಕುತನ',
'ಡೊಂಕುನಡೆ',
'ಡೊಂಕುವೆಱು',
'ಡೊಂಕುಹೋಗು',
'ಡೊಂಬಕಾಗೆ',
'ಡೊಂಬರಗೆ',
'ಡೊಂಬವಱೆ',
'ಡೊಂಬವಿದ್ದೆ',
'ಡೊಂಬವಿದ್ಯೆ',
'ಡೊಂಬಿಗಾರ',
'ಡೊಂಬಿದೈವ',
'ಡೊಂಬಿಮಾಡು',
'ಡೊಂಬಿವರಿ',
'ಡೊಂಬುಮಾಡು',
'ಡೊಕ್ಕರಣೆ',
'ಡೊಕ್ಕರಿಸು',
'ಡೊಗ್ಗಾಲಿಕ್ಕು',
'ಡೊಗ್ಗಾಲೂರು',
'ಡೊಣೆಗಣ್ಣು',
'ಡೊಣೆಗತ್ತಿ',
'ಡೊಣೆಯುಪ್ಪು',
'ಡೊಣೆವೋಗು',
'ಡೊಣ್ಣೆಕಾಟ',
'ಡೊಣ್ಣೆಕೇತ',
'ಡೊಣ್ಣೆಮುಳ್ಳು',
'ಡೊಣ್ಣೆಮೂಗು',
'ಡೊಣ್ಣೆಹುಳು',
'ಡೊಳುಹೊಟ್ಟೆ',
'ಡೊಳ್ಳುಹೊಟ್ಟೆ',
'ಡೊಳ್ಳೊಡಲು',
'ಡೋಕಾರಿಗ',
'ಡೋಗಿಬರ',
'ಡೋಣಿವಟ್ಟೆ',
'ಡೋಣಿವೀೞ್',
'ಡೋಣಿವೋಗು',
'ಡೋಬಿಕಜ್ಜಿ',
'ಡೋರಿಗೊರೆ',
'ಡೋಲಪಾದ',
'ಡೋಲಫಲ',
'ಡೋಲಾಗೃಹ',
'ಡೋಲೋತ್ಸವ',
'ಡೋಳಾಕೇಳಿ',
'ಡೋಳೋತ್ಸವ',
'ಡೋಱಿಗಳೆ',
'ಡೋಱಿಗೊಱೆ',
'ಡೋಱಿವೋಗು',
'ಡೋಱುಗಳೆ',
'ಡೋಱುಗೊಱೆ',
'ಡೋಱುವೋಗು',
'ಡೋಱೆನಿಸು',
'ಡೌಗಿಬರ',
'ಡೌಡೆವಱೆ',
'ಢಕಾಯಿತ',
'ಢಕಾಯಿತಿ',
'ಢಕ್ಕಾಮುಕ್ಕಿ',
'ಢಗೆವಡೆ',
'ಢಣತ್ಕಾರ',
'ಡಣತ್ಕೃತ',
'ಢಣಿಲನೆ',
'ಢಮಢಮ',
'ಢಮಾರನೆ',
'ಢಲಾಯಿತ',
'ಢವಳಿಸು',
'ಢಳಾಯತ',
'ಢಳಾಯಿತ',
'ಢಾವರಿಸು',
'ಢಾಳಂಬಡೆ',
'ಢಾಳಯಿಸು',
'ಢಾಳವಿಸು',
'ಢಿಕ್ಕಿಯಾಡು',
'ಢಿಕ್ಕಿಹೊಡೆ',
'ಢೆಂಢೆಣಿಸು',
'ಣಮೋಕಾರ',
'ತಂಗಡಲೆ',
'ತಂಗದಿರ್',
'ತಂಗದಿರ',
'ತಂಗರಗ',
'ತಂಗಳನ್ನ',
'ತಂಗುದಾಣ',
'ತಂಗುದೊಡ್ಡಿ',
'ತಂಗುಪಟ್ಟಿ',
'ತಂಗುಮನೆ',
'ತಂಚಾವಂಚ',
'ತಂಟೆಕೋರ',
'ತಂಟೆಗಾರ',
'ತಂಡಂಗೊಳ್',
'ತಂಡಂಬೆಱು',
'ತಂಡನಾಮೆ',
'ತಂಡರಸಿ',
'ತಂಡವಾಳ',
'ತಂಡಾಮುಂಡಾ',
'ತಂಡುಂಮುಂಡು',
'ತಂಡುಮುಂಡು',
'ತಂಡುಲೀಯ',
'ತಂಡೋಮುಂಡೊ',
'ತಂತಿಕಟ್ಟು',
'ತಂತಿಕೊಡು',
'ತಂತಿಬಲೆ',
'ತಂತಿಬಿಡು',
'ತಂತಿಮೆತ್ತೆ',
'ತಂತಿಮೇಹ',
'ತಂತಿವಾದ್ಯ',
'ತಂತಿವಾರ್ತೆ',
'ತಂತುಕಾರ',
'ತಂತುಕುಳ',
'ತಂತುಕ್ರಿಯೆ',
'ತಂತುಗಟ್ಟು',
'ತಂತುಗಾರ',
'ತಂತುಚಿತ',
'ತಂತುನಾಗ',
'ತಂತುನಾಭ',
'ತಂತುಪಟ',
'ತಂತುಪಾಕ',
'ತಂತುಮೇಹ',
'ತಂತುಯಂತ್ರ',
'ತಂತುವಾಯ',
'ತಂತುಸ್ನಾಯು',
'ತಂತೋತಂತು',
'ತಂತ್ರಗಾರ',
'ತಂತ್ರಜ್ಞಾನ',
'ತಂತ್ರಪಾಲ',
'ತಂತ್ರಪಾಳ',
'ತಂತ್ರಲಿಪಿ',
'ತಂತ್ರವೇದಿ',
'ತಂತ್ರಶಾಸ್ತ್ರ',
'ತಂತ್ರಶುದ್ಧಿ',
'ತಂತ್ರಹಾಳ',
'ತಂತ್ರಹೂಡು',
'ತಂತ್ರಾಧ್ಯಕ್ಷ',
'ತಂಥಳಿಸು',
'ತಂದನಾನ',
'ತಂದನ್ನತಾ',
'ತಂದ್ರಾವಸ್ಥೆ',
'ತಂದ್ರೀಭೂತ',
'ತಂಪರಲೆ',
'ತಂಪುಗೊಳ್',
'ತಂಪುತೀಡು',
'ತಂಪುವಡೆ',
'ತಂಪುವೆಟ್ಟ',
'ತಂಪುವೆಱು',
'ತಂಪುಹೊತ್ತು',
'ತಂಬಟಿಗ',
'ತಂಬಟೆಗ',
'ತಂಬಹಾಲು',
'ತಂಬುಲಿಗ',
'ತಂಬೆರಲ್',
'ತಂಬೆಲರ್',
'ತಂಬೆಲರು',
'ತಂಬೆಳಕು',
'ತಂಬೆಳಗ',
'ತಂಬೆಳಗು',
'ತಕತಕ',
'ತಕಪಕ',
'ತಕಯೂದ',
'ತಕರಾರ್',
'ತಕರಾರು',
'ತಕರಾಲು',
'ತಕಲೀಪು',
'ತಕಶೀರು',
'ತಕ್ಕಡಿಗ',
'ತಕ್ಕಯಿಸು',
'ತಕ್ಕವಲ',
'ತಕ್ಕವಿಸು',
'ತಕ್ಕಸೈಲ',
'ತಕ್ಕಳಿಸು',
'ತಕ್ಕಾಬಿಕ್ಕಿ',
'ತಕ್ಕಿಬಿಕ್ಕಿ',
'ತಕ್ಕುಂಗೆಡು',
'ತಕ್ಕುಗಿಡು',
'ತಕ್ಕುಗುಡು',
'ತಕ್ಕುಗೆಡು',
'ತಕ್ಕುಗೊಡು',
'ತಕ್ಕೆಗಟ್ಟು',
'ತಕ್ಕೆಗೊಳ್',
'ತಕ್ಕೆಬೀಳು',
'ತಕ್ಕೆಮುಕ್ಕೆ',
'ತಕ್ಕೆವರ್',
'ತಕ್ಕೋಲಕ',
'ತಕ್ಕೋಲಿಕ',
'ತಕ್ರತೈಲ',
'ತಕ್ರಬಲಿ',
'ತಕ್ಷರತ್ನ',
'ತಗಡಕ',
'ತಗಡತಿ',
'ತಗಡುರಿ',
'ತಗಪಡು',
'ತಗಲಿಸು',
'ತಗಲೂಪಿ',
'ತಗಲೂಫಿ',
'ತಗಹಿಕ್ಕು',
'ತಗಹಿಡು',
'ತಗಿಬಿಗಿ',
'ತಗುತ್ತೆಂಗು',
'ತಗುಬಗೆ',
'ತಗುಲಿಸು',
'ತಗುಳಿಸು',
'ತಗುಳ್ಚಿಸು',
'ತಗೆಬಗೆ',
'ತಗ್ಗುಕಾಲ',
'ತಗ್ಗುಗುಟ್ಟು',
'ತಗ್ಗುದನಿ',
'ತಗ್ಗುಬೀಳು',
'ತಗ್ಗುಮುಗ್ಗು',
'ತಗ್ಗುಶ್ರುತಿ',
'ತಗ್ಗುಸಿರು',
'ತಜವಿಜಿ',
'ತಜವೀಜಿ',
'ತಜವೀಜು',
'ತಜುಬಿಜಿ',
'ತಟಂಕಷ',
'ತಟಂಬಿಡಿ',
'ತಟಕನೆ',
'ತಟಕಾವು',
'ತಟಕ್ಕನೆ',
'ತಟಗುಟ್ಟು',
'ತಟಗುಡು',
'ತಟಪಟ',
'ತಟಬಂದಿ',
'ತಟಮಟ',
'ತಟವಟ',
'ತಟವಾಣಿ',
'ತಟವುಚ್ಚು',
'ತಟಸ್ಥತೆ',
'ತಟಹಾಯ್',
'ತಟಹಾಯು',
'ತಟಾಯಿಸು',
'ತಟಾರನೆ',
'ತಟಿತ್ಪೃಭೆ',
'ತಟಿನ್ನಾದ',
'ತಟಿಲ್ಲತೆ',
'ತಟ್ಟತಳ',
'ತಟ್ಟಾಮಾಲೆ',
'ತಟ್ಟಾಱಿಸು',
'ತಟ್ಟಿಗೋಡೆ',
'ತಟ್ಟಿಬಲೆ',
'ತಟ್ಟಿರಾಯ',
'ತಟ್ಟಿವಲೆ',
'ತಟ್ಟಿವೆಣೆ',
'ತಟ್ಟಿಹುಚ್ಚ',
'ತಟ್ಟುಂಗೆಡೆ',
'ತಟ್ಟುಂತಾಱುಂ',
'ತಟ್ಟುಗುಟ್ಟು',
'ತಟ್ಟುಗೆಡೆ',
'ತಟ್ಟುಬಳೆ',
'ತಟ್ಟುಬೀಳು',
'ತಟ್ಟುಮಟ್ಟಿ',
'ತಟ್ಟುಮಣೆ',
'ತಟ್ಟುಮುಟ್ಟು',
'ತಟ್ಟುಮೆಟ್ಟು',
'ತಟ್ಟುವೀೞ್',
'ತಟ್ಟೆಕಾಲು',
'ತಟ್ಟೆಗಲ್ಲು',
'ತಡಂಗಡಿ',
'ತಡಂಗಲು',
'ತಡಂಗಾಲ್',
'ತಡಂಗಾಲು',
'ತಡಂಗುಂಡಿ',
'ತಡಂಗೂಡು',
'ತಡಂಗೆಡೆ',
'ತಡಂನುಡಿ',
'ತಡಂಬಡ',
'ತಡಂಬಡು',
'ತಡಂಬಡೆ',
'ತಡಂಬರ್',
'ತಡಂಬರ',
'ತಡಂಬರೆ',
'ತಡಂಬಾಯ್',
'ತಡಂಬುಗು',
'ತಡಂಬೊಗು',
'ತಡಂಬೊಯ್',
'ತಡಂಮಾಡು',
'ತಡಂಮೆಟ್ಟು',
'ತಡಕಯ್',
'ತಡಕಸ',
'ತಡಕಾಟ',
'ತಡಕಾಡು',
'ತಡಕಿಲು',
'ತಡಕಿಸು',
'ತಡಕೆಯ್',
'ತಡಗಡಿ',
'ತಡಗಣಿ',
'ತಡಗಸ',
'ತಡಗಾಲ್',
'ತಡಗುಣಿ',
'ತಡಗೆಯ್',
'ತಡಗೊಳ್',
'ತಡಚಲ್ಲಿ',
'ತಡಜಾಣ',
'ತಡದಡ',
'ತಡಬಡ',
'ತಡಬಡು',
'ತಡಮಾರಿ',
'ತಡಮಿಕ್ಕು',
'ತಡಮೆಟ್ಟು',
'ತಡವರ್',
'ತಡವಳಿ',
'ತಡವಾಯ್',
'ತಡವಾಯು',
'ತಡವಿಕ್ಕು',
'ತಡವಿಡು',
'ತಡವಿರ್',
'ತಡಸಲ',
'ತಡಸಲಿ',
'ತಡಸಲು',
'ತಡಹರ',
'ತಡಹಾಯು',
'ತಡಹಿಡು',
'ತಡಾಯಿಸು',
'ತಡಿಗಾಣ್',
'ತಡಿಗೂಡು',
'ತಡಿಗೊಳ್',
'ತಡಿದೆರೆ',
'ತಡಿಮೀಱು',
'ತಡಿಮೆಟ್ಟು',
'ತಡಿಯಾರು',
'ತಡಿವಲೆ',
'ತಡಿವಿಡಿ',
'ತಡಿಸಾರ್',
'ತಡಿಹುಡಿ',
'ತಡುಗಣಿ',
'ತಡೆಕಟ್ಟು',
'ತಡೆಗಂಬಿ',
'ತಡೆಗಟ್ಟು',
'ತಡೆಗಟ್ಟೆ',
'ತಡೆಗಡಿ',
'ತಡೆಗಡೆ',
'ತಡೆಗಾಲ್',
'ತಡೆಗಾಲು',
'ತಡೆಗಾಱ',
'ತಡೆಗಿಡು',
'ತಡೆಗೆಡು',
'ತಡೆಗೆಡೆ',
'ತಡೆಗೆಯ್',
'ತಡೆಗೊಳ್',
'ತಡೆಗೋಡೆ',
'ತಡೆನಡೆ',
'ತಡೆಬಡೆ',
'ತಡೆಬಿಡು',
'ತಡೆಮಂತ್ರ',
'ತಡೆಯಿಡು',
'ತಡೆಯಿಸು',
'ತಡೆಯೊಡ್ಡು',
'ತಡೆಯೋಟ',
'ತಡೆರಾಜ್ಯ',
'ತಡೆವಡು',
'ತಡೆವಡೆ',
'ತಡೆವರ್',
'ತಡೆವಲೆ',
'ತಡೆವಿಡು',
'ತಡೆವೇಂಟೆ',
'ತಡೆವೇಟೆ',
'ತಡೆಹಡೆ',
'ತಡೆಹಿಡಿ',
'ತಣಕಲಿ',
'ತಣಾಪತ್ತಿ',
'ತಣಿಯಿಸು',
'ತಣಿಯುಣ್',
'ತಣಿಯೂಡು',
'ತಣಿಯೆಱೆ',
'ತಣಿವೇಱು',
'ತಣ್ಗದಿರ್',
'ತಣ್ಗದಿರ',
'ತಣ್ಜೊಡರ್',
'ತಣ್ಣಗಮ್ಮ',
'ತಣ್ಣನೆಯ',
'ತಣ್ಣಸಿಗ',
'ತಣ್ಣೀರೆರೆ',
'ತಣ್ಣುಣುಕ',
'ತಣ್ಣುಸಿರು',
'ತಣ್ಣೆಮಡೆ',
'ತಣ್ಣೆರಲ್',
'ತಣ್ಣೆಲರ್',
'ತಣ್ಪುಗೂಡು',
'ತಣ್ಪುಗೆಯ್',
'ತಣ್ಪುದರ್',
'ತಣ್ಪುವಡೆ',
'ತಣ್ಪುವೀಱು',
'ತಣ್ಪುವೆಸ',
'ತಣ್ಪುವೆಱು',
'ತಣ್ಪೂಳಿಗ',
'ತಣ್ಪೇರಿಸು',
'ತಣ್ಪೊದವು',
'ತತುಕ್ಷಣ',
'ತತ್ಕಾಲೀನ',
'ತತ್ತಕಾರ',
'ತತ್ತಪಣ',
'ತತ್ತರಣಿ',
'ತತ್ತರಾಣಿ',
'ತತ್ತರಿಸು',
'ತತ್ತವಣೆ',
'ತತ್ತಳಗೈ',
'ತತ್ತಳಿಕೆ',
'ತತ್ತಳಿಸು',
'ತತ್ತುಗೊಳ್',
'ತತ್ತುಪಣ',
'ತತ್ತ್ವಜ್ಞಾನ',
'ತತ್ತ್ವಜ್ಞಾನಿ',
'ತತ್ತ್ವದರ್ಶಿ',
'ತತ್ತ್ವದೃಷ್ಟಿ',
'ತತ್ತ್ವಮಸಿ',
'ತತ್ತ್ವವೇದಿ',
'ತತ್ತ್ವಶಾಸ್ತ್ರ',
'ತತ್ತ್ವಾತೀತ',
'ತತ್ತ್ವಾತೀತೆ',
'ತತ್ತ್ವಾಲೋಕ',
'ತತ್ಥಳಿಸು',
'ತತ್ಪರತೆ',
'ತತ್ಪುರುಷ',
'ತತ್ವಜ್ಞಾನ',
'ತತ್ವಜ್ಞಾನಿ',
'ತತ್ವದೃಷ್ಟಿ',
'ತತ್ವಪದ',
'ತತ್ವಮಸಿ',
'ತತ್ವವಿದ',
'ತತ್ವವಿದೆ',
'ತತ್ವವೇತ್ತ',
'ತತ್ವವೇದಿ',
'ತತ್ವಶಾಸ್ತ್ರ',
'ತತ್ವಾತ್ಮಕ',
'ತತ್ವಾಲೋಕ',
'ತಥಪಣೆ',
'ತಥಾಗತ',
'ತದಕಲ್',
'ತದಕುಗ',
'ತದಾತನ',
'ತದಾರಭ್ಯ',
'ತದಿಗಿಣ',
'ತದಿಗಿಲು',
'ತದುಕಿಲು',
'ತದುಗತ',
'ತದೆವಡೆ',
'ತದ್ಧಿತಾಂತ',
'ತದ್ವಿರುದ್ಧ',
'ತನಕರ',
'ತನಕರಂ',
'ತನಕಲಿ',
'ತನನನ',
'ತನಮನಿ',
'ತನಯಳ್',
'ತನಿಖಿಸು',
'ತನಿಗರ್ಪು',
'ತನಿಗಲಿ',
'ತನಿಗುದಿ',
'ತನಿಗುಳ್ಳ',
'ತನಿಗೆಂಡ',
'ತನಿಗೆಚ್ಚು',
'ತನಿಗೆಡು',
'ತನಿಗೆಡೆ',
'ತನಿಗೆತ್ತು',
'ತನಿಗೊಳ್ಳು',
'ತನಿಚೂಣಿ',
'ತನಿಜಡಿ',
'ತನಿಬಾಳೆ',
'ತನಿಬಿಡು',
'ತನಿಮಾವು',
'ತನಿಯನ್',
'ತನಿಯೆರೆ',
'ತನಿವಗೆ',
'ತನಿವಣ್',
'ತನಿವಣ್ಣು',
'ತನಿವರಿ',
'ತನಿಸೊಕ್ಕು',
'ತನಿಸೊರ್ಕು',
'ತನಿಹೊಟ್ಟು',
'ತನುಗಾತ್ರ',
'ತನುಗಾತ್ರಿ',
'ತನುಗೆಡು',
'ತನುಗೊಳ್',
'ತನುಜನ್ಮ',
'ತನುಜಾತ',
'ತನುಜಾತೆ',
'ತನುತರ',
'ತನುತಲ',
'ತನುತಾಪ',
'ತನುತ್ರಯ',
'ತನುತ್ರಾಣ',
'ತನುದಂಡ',
'ತನುಧರ್ಮ',
'ತನುಭವ',
'ತನುಭವೆ',
'ತನುಮಧ್ಯ',
'ತನುಮಧ್ಯೆ',
'ತನುರುಹ',
'ತನುವಾತ',
'ತನುವಿಡಿ',
'ತನುವೆಳೆ',
'ತನುವೇರು',
'ತನುಸಿದ್ಧ',
'ತನುಸ್ಥಾನ',
'ತನೂಜಾತ',
'ತನೂಜಾತೆ',
'ತನೂದರ',
'ತನೂದರಿ',
'ತನೂಭವ',
'ತನೂರುಹ',
'ತನೆತುಂಬು',
'ತನೆನಿಲ್',
'ತನೆಬಲಿ',
'ತನೆಯಿರು',
'ತನ್ನತನ',
'ತನ್ನವಿಕೆ',
'ತನ್ನಾಕಾರ',
'ತನ್ಮಯತೆ',
'ತನ್ಮಯತ್ವ',
'ತನ್ಮೂಲಕ',
'ತಪಂಗೆಯ್',
'ತಪಂಗೊಳ್',
'ತಪಂಬಡು',
'ತಪಃಕ್ಲಾಂತ',
'ತಪಕೀರ',
'ತಪಕ್ಕನೆ',
'ತಪಗುಟ್ಟು',
'ತಪಗುಡು',
'ತಪಗೆಯ್',
'ತಪಗೇಡಿ',
'ತಪನೀಯ',
'ತಪಮಾನ್',
'ತಪಮಿರ್',
'ತಪರಾಕಿ',
'ತಪರಾಕು',
'ತಪರಾಕೆ',
'ತಪರ್ಲೋಕ',
'ತಪಶೀಲ',
'ತಪಶೀಲು',
'ತಪಶ್ಚರ್ಯೆ',
'ತಪಸಿಗ',
'ತಪಸಿರ್',
'ತಪಸೀಲು',
'ತಪಸ್ತಪ್ತ',
'ತಪಸ್ವಿನಿ',
'ತಪಾಸಣಿ',
'ತಪಾಸಣೆ',
'ತಪಾಸಿಸು',
'ತಪೋಕಾಮ',
'ತಪೋಧನ',
'ತಪೋಧನೆ',
'ತಪೋಬಲ',
'ತಪೋಮಾಸ',
'ತಪೋಲೋಕ',
'ತಪ್ತಮಾಷ',
'ತಪ್ಪಂಗಾಯಿ',
'ತಪ್ಪಡಿಸು',
'ತಪ್ಪಳಿಕ್ಕು',
'ತಪ್ಪಿತಸ್ಥ',
'ತಪ್ಪಿತಸ್ಥೆ',
'ತಪ್ಪುಕೊಡು',
'ತಪ್ಪುಗಂಟು',
'ತಪ್ಪುಗಾಣ್',
'ತಪ್ಪುಗಾರ',
'ತಪ್ಪುಗಾಲು',
'ತಪ್ಪುಗಾಱ',
'ತಪ್ಪುಗೊಳ್',
'ತಪ್ಪುತಡ',
'ತಪ್ಪುತಡೆ',
'ತಪ್ಪುತಾಕು',
'ತಪ್ಪುತೆರು',
'ತಪ್ಪುದಂಡ',
'ತಪ್ಪುದಾರಿ',
'ತಪ್ಪುನಡೆ',
'ತಪ್ಪುವೞಿ',
'ತಪ್ಪುವಿಡಿ',
'ತಪ್ಪುಹಚ್ಚು',
'ತಪ್ಪುಹಿಡಿ',
'ತಪ್ಪುಹೆಜ್ಜೆ',
'ತಪ್ಪುಹೊರು',
'ತಪ್ಪುಹೊಱು',
'ತಪ್ಪೆಣಿಕೆ',
'ತಪ್ಪೊಪ್ಪಿಗೆ',
'ತಫರೀಕು',
'ತಫಾವತು',
'ತಬಲಕು',
'ತಬೀಯತು',
'ತಬ್ಬರಿಸು',
'ತಬ್ಬಿಬ್ಬಾಟ',
'ತಮಃಪ್ರಭೆ',
'ತಮಪ್ರಭೆ',
'ತಮರೂರ್',
'ತಮರ್ಮನೆ',
'ತಮವಿಡಿ',
'ತಮಸೂಕು',
'ತಮಸ್ತಮ',
'ತಮಸ್ವಿನಿ',
'ತಮಿಳಿತ್ತಿ',
'ತಮೋಗುಣ',
'ತಮೋಜ್ಞತೆ',
'ತಮೋಪಹ',
'ತಮೋಮಣಿ',
'ತಮೋಮಯ',
'ತಮೋಲೋಕ',
'ತಮ್ಮಿನುಗ',
'ತಮ್ಮೆಗಿಡ',
'ತಯಾರಕ',
'ತಯಾರಿಕೆ',
'ತಯಾರಿಸು',
'ತಯಿಮಾಸ',
'ತರಂಗಾಭ',
'ತರಂಗಿಣಿ',
'ತರಂಗಿತ',
'ತರಂಗಿಸು',
'ತರಂಗೊಳ್',
'ತರಂಬಿಡಿ',
'ತರಂಬೆಱು',
'ತರಕಟ',
'ತರಕಲು',
'ತರಕಷ್',
'ತರಕಷಿ',
'ತರಕಸ',
'ತರಕಾರಿ',
'ತರಗಚೆ',
'ತರಗಡಿ',
'ತರಗಡೆ',
'ತರಗತಿ',
'ತರಗಸಿ',
'ತರಗಾರ',
'ತರಗುಟ್ಟು',
'ತರಗೆಲೆ',
'ತರಚಲು',
'ತರಚೀಟಿ',
'ತರಣಕ',
'ತರತಮ',
'ತರತರ',
'ತರತೀಪು',
'ತರದೂದು',
'ತರಪಡಿ',
'ತರಪಣ್ಯ',
'ತರಪೇತಿ',
'ತರಪೇತು',
'ತರಬೇತಿ',
'ತರಬೇತು',
'ತರಲತೆ',
'ತರಲಾಕ್ಷಿ',
'ತರಲಿತ',
'ತರವಂಗಿ',
'ತರವರಿ',
'ತರವಳಿ',
'ತರವಾರ',
'ತರವಾರಿ',
'ತರವಿಡಿ',
'ತರವೀಸ',
'ತರಹರ',
'ತರಹರಿ',
'ತರಳತೆ',
'ತರಳಿತ',
'ತರಾಂತುರಿ',
'ತರಾತುರಿ',
'ತರಾವರಿ',
'ತರಾವಾರು',
'ತರಿಗೆಂಡ',
'ತರಿತೀಪು',
'ತರಿಪೈರು',
'ತರಿಯಿಸು',
'ತರಿವಿಂಡಿ',
'ತರಿಸಲ್',
'ತರಿಸಲ',
'ತರಿಸಲ್ಕೆ',
'ತರುಕುಟಿ',
'ತರುಚಯ',
'ತರುಚರ',
'ತರುಟಿಗಿ',
'ತರುಟೆಗೆ',
'ತರುಣತೆ',
'ತರುಣತ್ವ',
'ತರುಣಿಮ',
'ತರುಬಿಸು',
'ತರುಮಗು',
'ತರುರುಹ',
'ತರುವಂಗಿ',
'ತರುವರಿ',
'ತರುವಲಿ',
'ತರುವಳಿ',
'ತರುವಾಯ',
'ತರುವಾಯಿ',
'ತರುವಾಯೆ',
'ತರುವೋಜ',
'ತರುಶಿಫೆ',
'ತರುಸಾರ',
'ತರ್ಕತಂತ್ರ',
'ತರ್ಕಬದ್ಧ',
'ತರ್ಕಯಿಸು',
'ತರ್ಕವಿದ್ಯೆ',
'ತರ್ಕವಿಸು',
'ತರ್ಕಶಾಸ್ತ್ರ',
'ತರ್ಕಾಭಾಸ',
'ತರ್ಜನೋಕ್ತಿ',
'ತರ್ಬಿಯತ್ತು',
'ತಲಪರೆ',
'ತಲಪಿಸು',
'ತಲಬಾಲು',
'ತಲಮುಖ',
'ತಲವಾರ್',
'ತಲವಾಸಿ',
'ತಲಸ್ಪರ್ಶಿ',
'ತಲಾತಲ',
'ತಲಾರಿಕೆ',
'ತಲುಪಣೆ',
'ತಲುಪಿಸು',
'ತಲುವರಿ',
'ತಲುವುರಿ',
'ತಲೆಕಟ್ಟು',
'ತಲೆಕಾಣ್',
'ತಲೆಕಾಯ್',
'ತಲೆಕಾಯಿ',
'ತಲೆಕಾಯು',
'ತಲೆಕುತ್ತು',
'ತಲೆಕೂಗು',
'ತಲೆಕೆಡು',
'ತಲೆಕೆರೆ',
'ತಲೆಕೇಣಿ',
'ತಲೆಕೊಟ್ಟು',
'ತಲೆಕೊಡು',
'ತಲೆಕೊಳ್ಳಿ',
'ತಲೆಕೋವೆ',
'ತಲೆಗಂಟು',
'ತಲೆಗಟ್ಟು',
'ತಲೆಗರೆ',
'ತಲೆಗವಿ',
'ತಲೆಗಳ್ಳ',
'ತಲೆಗಾಣ್',
'ತಲೆಗಾಪು',
'ತಲೆಗಾಯ್',
'ತಲೆಗಾಯಿ',
'ತಲೆಗಿರಿ',
'ತಲೆಗುಡು',
'ತಲೆಗುಡ್ಡೆ',
'ತಲೆಗುತ್ತ',
'ತಲೆಗುತ್ತು',
'ತಲೆಗುಸಿ',
'ತಲೆಗೆಡು',
'ತಲೆಗೇಡು',
'ತಲೆಗೇರಿ',
'ತಲೆಗೊಡ',
'ತಲೆಗೊಡು',
'ತಲೆಗೊಯ್',
'ತಲೆಗೊಯ್ಕ',
'ತಲೆಗೊಳ್',
'ತಲೆಗೊಳ್ಳಿ',
'ತಲೆಗೊಱೆ',
'ತಲೆಚಿಂದಿ',
'ತಲೆಚಿಟ್ಟು',
'ತಲೆಚೀಟಿ',
'ತಲೆಛಳಿ',
'ತಲೆಠೌಳಿ',
'ತಲೆತಪ್ಪು',
'ತಲೆತಾಕು',
'ತಲೆತಾಗು',
'ತಲೆತಿಕ್ಕು',
'ತಲೆತಿನ್ನು',
'ತಲೆತೂಗು',
'ತಲೆತೆಗೆ',
'ತಲೆತೊನೆ',
'ತಲೆದಂಡ',
'ತಲೆದರ್ಗು',
'ತಲೆದಾಗು',
'ತಲೆದಿಂಬು',
'ತಲೆದೂಂಕು',
'ತಲೆದೂಕ',
'ತಲೆದೂಗು',
'ತಲೆದೂಳು',
'ತಲೆದೆಗೆ',
'ತಲೆದೊನೆ',
'ತಲೆದೊಱೆ',
'ತಲೆದೋರು',
'ತಲೆದೋಱ್',
'ತಲೆದೋಱು',
'ತಲೆನಿಲ್ಲು',
'ತಲೆನೀರ್',
'ತಲೆನೀರು',
'ತಲೆನೀರ್ಮೀ',
'ತಲೆನೋವು',
'ತಲೆಪಂಕ್ತಿ',
'ತಲೆಪಟ್ಟಿ',
'ತಲೆಪಟ್ಟು',
'ತಲೆಪಡು',
'ತಲೆಪರಿ',
'ತಲೆಪಾಗು',
'ತಲೆಪೂಸು',
'ತಲೆಪೊರು',
'ತಲೆಪೊಱೆ',
'ತಲೆಬಂಟ',
'ತಲೆಬಾಗು',
'ತಲೆಬಾಚು',
'ತಲೆಬೀಸು',
'ತಲೆಬೀಳು',
'ತಲೆಬೆಲೆ',
'ತಲೆಬೇನೆ',
'ತಲೆಭಾರ',
'ತಲೆಮಂಡೆ',
'ತಲೆಮಕಿ',
'ತಲೆಮಗ',
'ತಲೆಮಡು',
'ತಲೆಮಣಿ',
'ತಲೆಮಱೆ',
'ತಲೆಮಾಡು',
'ತಲೆಮಾತು',
'ತಲೆಮಾರು',
'ತಲೆಮಾಲೆ',
'ತಲೆಮಾಱಿ',
'ತಲೆಮಿಗು',
'ತಲೆಮುಚ್ಚು',
'ತಲೆಮುಟ್ಟ',
'ತಲೆಮುಡಿ',
'ತಲೆಮೆಟ್ಟು',
'ತಲೆಮೆರೆ',
'ತಲೆಮೆಱೆ',
'ತಲೆಮೊರೆ',
'ತಲೆಮೊಱೆ',
'ತಲೆಯರಿ',
'ತಲೆಯಾರ್',
'ತಲೆಯಾರು',
'ತಲೆಯಾಳ್',
'ತಲೆಯಾಳು',
'ತಲೆಯಿಡು',
'ತಲೆಯುಡೆ',
'ತಲೆಯುದ್ದ',
'ತಲೆಯುರ್ಚು',
'ತಲೆಯೂರ್',
'ತಲೆಯೂರು',
'ತಲೆಯೂಱು',
'ತಲೆಯೆತ್ತು',
'ತಲೆಯೊಡೆ',
'ತಲೆಯೊಡ್ಡು',
'ತಲೆಯೊತ್ತು',
'ತಲೆಯೊಲೆ',
'ತಲೆಯೋಟೆ',
'ತಲೆಯೋಡು',
'ತಲೆರನ್ನ',
'ತಲೆರನ್ನೆ',
'ತಲೆಲಕ್ಷ್ಮಿ',
'ತಲೆವಡು',
'ತಲೆವಣ',
'ತಲೆವಣಿ',
'ತಲೆವಸ್ತ್ರ',
'ತಲೆವಳ್ಳಿ',
'ತಲೆವಱಿ',
'ತಲೆವಾಗು',
'ತಲೆವಾಯ್',
'ತಲೆವಾಶಿ',
'ತಲೆವಿಕ್ಕು',
'ತಲೆವಿಡಿ',
'ತಲೆವೀದಿ',
'ತಲೆವೀಸು',
'ತಲೆವೂರು',
'ತಲೆವೂಸು',
'ತಲೆವೆಣ್',
'ತಲೆವೇನೆ',
'ತಲೆವೊಂದು',
'ತಲೆವೊಡೆ',
'ತಲೆವೊತ್ತು',
'ತಲೆವೊಯ್ಕ',
'ತಲೆವೊರು',
'ತಲೆವೊರೆ',
'ತಲೆವೊಲ',
'ತಲೆವೊಱು',
'ತಲೆವೊಱೆ',
'ತಲೆವೋಕ',
'ತಲೆಶೂಲೆ',
'ತಲೆಸರ',
'ತಲೆಸಿಂಬಿ',
'ತಲೆಸುತ್ತು',
'ತಲೆಸೂಡು',
'ತಲೆಸೇಸೆ',
'ತಲೆಹಂಚು',
'ತಲೆಹತ್ತು',
'ತಲೆಹಾಕು',
'ತಲೆಹಾಯು',
'ತಲೆಹಿಡಿ',
'ತಲೆಹುಳಿ',
'ತಲೆಹುಳು',
'ತಲೆಹುೞಿ',
'ತಲೆಹೊಯ್ಕ',
'ತಲೆಹೊರೆ',
'ತಲೆಹೊಱೆ',
'ತಲೆಹೋಕ',
'ತಲೆಹೋಗು',
'ತಲೋದರಿ',
'ತಲೋದ್ವೃತ್ತ',
'ತಲ್ಲಣಿಕೆ',
'ತಲ್ಲಣಿಗ',
'ತಲ್ಲಣಿಸು',
'ತಲ್ಲಳಿಸು',
'ತಲ್ಲೞಿಸು',
'ತಲ್ಲೀನತೆ',
'ತವಕಿಗ',
'ತವಕಿಸು',
'ತವಕೀರ',
'ತವಕೀಲು',
'ತವಕ್ಷೀರ',
'ತವಡಿಕೆ',
'ತವತವ',
'ತವನಿಧಿ',
'ತವರಾಜ',
'ತವರೂರು',
'ತವರ್ತನ',
'ತವರ್ದೆಸೆ',
'ತವರ್ನಾಡು',
'ತವರ್ಮನೆ',
'ತವಲಾಯ',
'ತವಲಾಯಿ',
'ತವಸಿಗ',
'ತವಸಿಣೀ',
'ತವಿಯಿಸು',
'ತವಿಲಿಲಿ',
'ತವುಂಕಲ್',
'ತವುಕಲ್',
'ತವುಡಂಗಿ',
'ತವುತರ್',
'ತವುದಲೆ',
'ತವುದಾಯ',
'ತಶಬೀರು',
'ತಸಕಲು',
'ತಸಕ್ಕನೆ',
'ತಸದೀಕು',
'ತಸಬೀರ',
'ತಸಬೀರು',
'ತಸರೀಫ್',
'ತಸಲೀಮು',
'ತಸವೀರು',
'ತಸ್ಕರಿಸು',
'ತಹಕೀತು',
'ತಹಕೂಫ್',
'ತಹಕೂಬ',
'ತಹತಹ',
'ತಹನಾಮೆ',
'ತಹಪತ್ರ',
'ತಹಬಂದು',
'ತಹರೀರು',
'ತಹಸೀಲಿ',
'ತಹಸೀಲು',
'ತಹಸೀಲೆ',
'ತಹಾನಾಮೆ',
'ತಹಿಕಾಱ',
'ತಳಂಗೊಳ್',
'ತಳಕಂಭ',
'ತಳಕಟ್ಟು',
'ತಳಕಿತ್ತು',
'ತಳಕೀಳು',
'ತಳಕ್ಕನೆ',
'ತಳಗಂಬ',
'ತಳಗಟ್ಟು',
'ತಳಗಡೆ',
'ತಳಗಲ್ಲು',
'ತಳಚಿಪ್ಪೆ',
'ತಳಚ್ಛಂದ',
'ತಳಚ್ಛವಿ',
'ತಳತಂತ್ರ',
'ತಳತಳ',
'ತಳತಾಳ',
'ತಳಥಳ',
'ತಳಪಟ',
'ತಳಪಟ್ಟು',
'ತಳಪಾಯ',
'ತಳಪೆತ್ತು',
'ತಳಮಳ',
'ತಳಮೀನು',
'ತಳಮುಟ್ಟ',
'ತಳಮೇಲು',
'ತಳರಡಿ',
'ತಳರಿಸು',
'ತಳರುಜೆ',
'ತಳರೋಗ',
'ತಳರ್ನಡೆ',
'ತಳರ್ನುಡಿ',
'ತಳಲುರಿ',
'ತಳಲೋಕ',
'ತಳವಡು',
'ತಳವರ',
'ತಳವಳ',
'ತಳವಱ',
'ತಳವಾರ',
'ತಳವಾಱ',
'ತಳವಿಡಿ',
'ತಳವಿತ್ತಿ',
'ತಳವಿರ್ತಿ',
'ತಳವೂರು',
'ತಳವೃತ್ತಿ',
'ತಳವ್ರಿತ್ತಿ',
'ತಳಸಂಚ',
'ತಳಸಂಚು',
'ತಳಸಾರ್',
'ತಳಸಿಂಗ',
'ತಳಸುಂಕ',
'ತಳಸೋಸು',
'ತಳಹಗ್ಗ',
'ತಳಹಚ್ಚು',
'ತಳಹತ್ತ',
'ತಳಹತ್ತು',
'ತಳಹದಿ',
'ತಳಹಸಿ',
'ತಳಹಳ',
'ತಳಹಿಡಿ',
'ತಳಹೊಳ',
'ತಳಾತಳ',
'ತಳಾರಿಕೆ',
'ತಳಾರಿಸು',
'ತಳಾಱಿಕೆ',
'ತಳಿಕೀರೆ',
'ತಳಿಗೂಳು',
'ತಳಿಮಳೆ',
'ತಳಿಯಿಸು',
'ತಳಿರಡಿ',
'ತಳಿರಿಡಿ',
'ತಳಿರಿಡು',
'ತಳಿರಿಸು',
'ತಳಿರುಡೆ',
'ತಳಿರೆಲೆ',
'ತಳಿರೊಡೆ',
'ತಳಿರ್ಗಯ್',
'ತಳಿರ್ಗಾಲ',
'ತಳಿರ್ಗಾವಿ',
'ತಳಿರ್ಗಾಸೆ',
'ತಳಿರ್ಗೆಂಪು',
'ತಳಿರ್ಗೊಂಬು',
'ತಳಿರ್ದುಟಿ',
'ತಳಿರ್ದೊಂಬೆ',
'ತಳಿರ್ವಜ್ಜೆ',
'ತಳಿರ್ವಟ್ಟೆ',
'ತಳಿರ್ವಡೆ',
'ತಳಿರ್ವಣ್ಣ',
'ತಳಿರ್ವನೆ',
'ತಳಿರ್ವಸೆ',
'ತಳಿರ್ವಾಸು',
'ತಳಿರ್ವೋಗು',
'ತಳಿವರ',
'ತಳಿಶಾಸ್ತ್ರ',
'ತಳುಕಿಕ್ಕು',
'ತಳುವಿಕ್ಕು',
'ತಳೆಯಿಕ್ಕು',
'ತಳೆವಿಡಿ',
'ತಳೋದರಿ',
'ತಳ್ಕರಿಸು',
'ತಳ್ಕವಿಸು',
'ತಳ್ಕೆಗೆಯ್',
'ತಳ್ತಳನೆ',
'ತಳ್ತಳಿಸು',
'ತಳ್ವುಗೆಯ್',
'ತಳ್ಳಂಕಿಸು',
'ತಳ್ಳಬಾಱು',
'ತಳ್ಳಮಳ',
'ತಳ್ಳವಾರು',
'ತಳ್ಳವಾಱು',
'ತಳ್ಳಳಿಸು',
'ತಳ್ಳಿಕಾರ',
'ತಳ್ಳಿಕಾಱ',
'ತಳ್ಳಿಕೋರ',
'ತಳ್ಳಿಯರ್ಜಿ',
'ತಳ್ಳಿವರಿ',
'ತಳ್ಳಿವಲೆ',
'ತಳ್ಳುಗಾಡಿ',
'ತಳ್ಳುಪಡಿ',
'ತಳ್ಳುಬಂಡಿ',
'ತಳ್ಳುಬಾಱು',
'ತಳ್ಳುವಾಱು',
'ತಱಕಟ',
'ತಱಗೆಲೆ',
'ತಱಗೇಳ್',
'ತಱತಱ',
'ತಱತಱಂ',
'ತಱವಳ',
'ತಱವಾಳ',
'ತಱಿಗಲ್ಲು',
'ತಱಿಗೆಂಡ',
'ತಱಿಮಿಂಡಿ',
'ತಱಿಯಿಸು',
'ತಱಿಸಲ್',
'ತಱಿಸಲ',
'ತಱಿಸಲು',
'ತಱುಪೆರ್ಮೆ',
'ತಱುಬುಲ್ಲು',
'ತಱುಮಿಂಡಿ',
'ತಱುವಾಯ್',
'ತಱುವಾಯ',
'ತಱುವಾಯಿ',
'ತಱುವಾಯು',
'ತೞತೞ',
'ತೞಪೞ',
'ತೞಿಕಿಕ್ಕು',
'ತೞಿಗೋಂಟೆ',
'ತೞುಂದಾೞೆ',
'ತೞೆವಿಡಿ',
'ತೞ್ಕಯಿಸು',
'ತೞ್ಕೆಗೆಯ್',
'ತೞ್ಕೆಗೊಳ್',
'ತಾಂಡವಗೈ',
'ತಾಂಡವಾಡು',
'ತಾಂಡವಾಳ',
'ತಾಂಡವಿಸು',
'ತಾಂಬೂಲಿಕ',
'ತಾಂಬೂಲಿಗ',
'ತಾಂಬ್ರಚೂಡ',
'ತಾಂಬ್ರಪರ್ಣಿ',
'ತಾಕಲಾಟ',
'ತಾಕಲಾಡು',
'ತಾಗುಥಟ್ಟು',
'ತಾಗುದಾಣ',
'ತಾಗುವಾಯ್',
'ತಾಜಾಕಲಂ',
'ತಾಟಗಿತ್ತಿ',
'ತಾಟಾಡಿಸು',
'ತಾಟಾಪೋಟಿ',
'ತಾಟಿನುಂಗು',
'ತಾಟಿಪೋಟಿ',
'ತಾಟಿಬೆಲ್ಲ',
'ತಾಟುಗಾಲು',
'ತಾಟುಪೋಟು',
'ತಾಟುಮಂಡಿ',
'ತಾಟುಹೋಟು',
'ತಾಡನಗೈ',
'ತಾಡನೆಗೈ',
'ತಾಡಪತ್ರ',
'ತಾಡಪತ್ರಿ',
'ತಾಡವಾಲೆ',
'ತಾಡವೋಲೆ',
'ತಾಣಂದರ',
'ತಾಣಬಟ್ಟ',
'ತಾಣಬೀಡು',
'ತಾಣಾಂತರ',
'ತಾತಪಾದ',
'ತಾತಿವರ',
'ತಾತ್ಕಾಲಿಕ',
'ತಾತ್ತಿ್ವಕ',
'ತಾತ್ಪರಿಯ',
'ತಾತ್ಪರಿಸು',
'ತಾತ್ಪರ್ಯಾರ್ಥ',
'ತಾತ್ಪೂರ್ತಿಕ',
'ತಾನಪತಿ',
'ತಾನಬಾಜಿ',
'ತಾನಮಾನ',
'ತಾನವರ್ಣ',
'ತಾಪಕಾರಿ',
'ತಾಪಜ್ವರ',
'ತಾಪತ್ರಯ',
'ತಾಪಪಡು',
'ತಾಪಮಾನ',
'ತಾಪವಡೆ',
'ತಾಪಶಕ್ತಿ',
'ತಾಪಸಿಗ',
'ತಾಪಹರ',
'ತಾಪೆಕಟ್ಟು',
'ತಾಪೇದಾರ',
'ತಾಪೇದಾರಿ',
'ತಾಫೆಕಟ್ಟು',
'ತಾಫೇದಾರಿ',
'ತಾಬಿನೇರೆ',
'ತಾಬೆದಾರ',
'ತಾಬೆದಾರಿ',
'ತಾಬೇದಾರ',
'ತಾಬೇದಾರಿ',
'ತಾಮರಸ',
'ತಾಮಲಕಿ',
'ತಾಮಸಿಕ',
'ತಾಮಸಿಕೆ',
'ತಾಮಸಿಗ',
'ತಾಮೆಗಿಡ',
'ತಾಮ್ರಕರ್ಣಿ',
'ತಾಮ್ರಕಾರ',
'ತಾಮ್ರಚೂಡ',
'ತಾಮ್ರಚೂಳ',
'ತಾಮ್ರತುಂಡ',
'ತಾಮ್ರಪಟ',
'ತಾಮ್ರಪಟ್ಟ',
'ತಾಮ್ರಪತ್ರ',
'ತಾಮ್ರಪರ್ಣಿ',
'ತಾಮ್ರಪುಷ್ಪಿ',
'ತಾಮ್ರಭಸ್ಮ',
'ತಾಮ್ರಮಯ',
'ತಾಮ್ರಮುಖ',
'ತಾಮ್ರಮುಖಿ',
'ತಾಮ್ರವಲ್ಲಿ',
'ತಾಮ್ರಶಿಖಿ',
'ತಾಮ್ರಶಿರ',
'ತಾಯಾಳಿಕೆ',
'ತಾಯಿಗಂಪು',
'ತಾಯಿಗ್ರಾಮ',
'ತಾಯಿತನ',
'ತಾಯಿನಾಡು',
'ತಾಯಿನುಡಿ',
'ತಾಯಿಬೇರು',
'ತಾಯಿಭಂಡ',
'ತಾಯಿಮನೆ',
'ತಾಯಿಮಾತು',
'ತಾಯಿರೆಕ್ಕೆ',
'ತಾಯಿವನೆ',
'ತಾಯಿವಳ',
'ತಾಯಿವಳ್ಳ',
'ತಾಯಿವೇರು',
'ತಾಯೀಚಲು',
'ತಾಯ್ಗರುಳು',
'ತಾಯ್ದೊಟ್ಟಿಲ್',
'ತಾಯ್ಮಣಲ್',
'ತಾಯ್ಮಳಲ್',
'ತಾಯ್ಮಳಲು',
'ತಾಯ್ಮೞಲ್',
'ತಾರಕಣೆ',
'ತಾರಕಿತ',
'ತಾರಕಿಸು',
'ತಾರಗಂಟು',
'ತಾರಗ್ರೀವ',
'ತಾರತಮ್ಯ',
'ತಾರಮಾರ',
'ತಾರಮ್ಮಯ್ಯ',
'ತಾರಯಿಸು',
'ತಾರವಿಸ',
'ತಾರವಿಸು',
'ತಾರಷಡ್ಜ',
'ತಾರಸರ',
'ತಾರಸ್ಥಾಯಿ',
'ತಾರಸ್ವರ',
'ತಾರಹಾರ',
'ತಾರಾಕೂಟ',
'ತಾರಾಗರ್ಭ',
'ತಾರಾಡಿಸು',
'ತಾರಾಪುಂಜ',
'ತಾರಾಬಲ',
'ತಾರಾಮಾರು',
'ತಾರಾಮೃಗ',
'ತಾರಾಮೈತ್ರಿ',
'ತಾರಾಯಂತ್ರ',
'ತಾರಿಸೇವೆ',
'ತಾರುಂಬಳಿ',
'ತಾರುಗೂಡು',
'ತಾರುತಟ್ಟು',
'ತಾರುಮಾರು',
'ತಾರುಯಂತ್ರ',
'ತಾರುಸುದ್ದಿ',
'ತಾರ್ಕಣಸು',
'ತಾರ್ಕಣಿಸು',
'ತಾರ್ಕಿಕತೆ',
'ತಾರ್ಕ್ಷ್ಯಶೈಲ',
'ತಾಲಪತ್ರ',
'ತಾಲಪರ್ಣಿ',
'ತಾಲಬದ್ಧ',
'ತಾಲಮಧು',
'ತಾಲಮಾನ',
'ತಾಲವೃಂತ',
'ತಾಲಿಪಿಟ್ಟು',
'ತಾಲೀಪಿಟ್ಟು',
'ತಾಲುಗ್ರಂಥಿ',
'ತಾಲುಜಿಹ್ವ',
'ತಾಲುಪಾಕ',
'ತಾಲುಶೋಫ',
'ತಾವಂಗೊಳ್',
'ತಾವಕೀನ',
'ತಾವಡಿಸು',
'ತಾವುಗಾಣ್',
'ತಾಶೀಲ್ದಾರ',
'ತಾಸಿಲ್ದಾರ',
'ತಾಳಂಗುಟ್ಟು',
'ತಾಳಗತಿ',
'ತಾಳಗುಟ್ಟು',
'ತಾಳಗೆಡು',
'ತಾಳತಂತ್ರ',
'ತಾಳದಂಡ',
'ತಾಳದಳ',
'ತಾಳಪತ್ರ',
'ತಾಳಬದ್ಧ',
'ತಾಳಮಾನ',
'ತಾಳಮೇಳ',
'ತಾಳವಟ್ಟ',
'ತಾಳವಾದ್ಯ',
'ತಾಳವಾರ',
'ತಾಳವೃಂತ',
'ತಾಳವೃತ್ತ',
'ತಾಳಹಾಕು',
'ತಾಳಹಿಡಿ',
'ತಾಳಿಕಟ್ಟು',
'ತಾಳಿಗಟ್ಟು',
'ತಾಳಿಬೊಟ್ಟು',
'ತಾಳಿಭಾಗ್ಯ',
'ತಾಳಿಮಿಕೆ',
'ತಾಳುಂತಟ್ಟುಂ',
'ತಾಳುತಟ್ಟು',
'ತಾಳುಬೀಳು',
'ತಾಳುವಂಶ',
'ತಾಳುವಾರ',
'ತಾಳೆಗರಿ',
'ತಾಳೆಛತ್ರ',
'ತಾಳೆಬೀಳು',
'ತಾಳೆಬೆಕ್ಕು',
'ತಾಳೆಬೆಲ್ಲ',
'ತಾಳೆಯೋಲೆ',
'ತಾಳೆವಾಲೆ',
'ತಾಳೆವೋಲೆ',
'ತಾಳೆಸೊಪ್ಪು',
'ತಾಳೆಹಾಕು',
'ತಾಳೆಕಟ್ಟು',
'ತಾಳೇಬಂದು',
'ತಾಳ್ಕೆವಂತ',
'ತಾಳ್ಮೆಗೆಡು',
'ತಾಳ್ಮೆತಪ್ಪು',
'ತಾಳ್ಮೆವಂತ',
'ತಾಱವಱು',
'ತಾಱುಂತಟ್ಟು',
'ತಾಱುಂತಟ್ಟುಂ',
'ತಾಱುಂತೀರು',
'ತಾಱುಂಬರಿ',
'ತಾಱುಂಬಳೆ',
'ತಾಱುಂಬೞಿ',
'ತಾಱುತಟ್ಟು',
'ತಾಱುತಟ್ಟುಂ',
'ತಾಱುಥಟ್ಟು',
'ತಾಱುಮಾಱು',
'ತಾಱುವಾಱು',
'ತಾಱುಹಂಗ',
'ತಿಂಗಣಿಗೆ',
'ತಿಂಗಳಿಗೆ',
'ತಿಂಗಳೋಡು',
'ತಿಂಗಳ್ಗಲ್',
'ತಿಂಗಳ್ವಕ್ಕಿ',
'ತಿಂಡಿಪೋತ',
'ತಿಂಡಿಬಾಕ',
'ತಿಂಡಿಹೋತ',
'ತಿಂತಿಡಿಕ',
'ತಿಂತಿಡೀಕ',
'ತಿಂತಿಣಿಸು',
'ತಿಂತ್ರಿಣೀಕ',
'ತಿಂಪುಗೆಡು',
'ತಿಕ್ಕಲಾಟ',
'ತಿಕ್ಕುವರಿ',
'ತಿಕ್ತಪರ್ವ',
'ತಿಕ್ತರೂಢ',
'ತಿಕ್ತಶಾಕ',
'ತಿಕ್ತಾಲಾಬು',
'ತಿಗಮರಿ',
'ತಿಗಮಾರಿ',
'ತಿಗಳಾಣ್ಯ',
'ತಿಗಳಾರಿ',
'ತಿಗಳಿತಿ',
'ತಿಗಳಿತ್ತಿ',
'ತಿಗುಣಿಸು',
'ತಿಗುರಿಕ್ಕು',
'ತಿಗುರಿಡು',
'ತಿಗುರಿಸು',
'ತಿಗುಳಾಣ್ಯ',
'ತಿಗುಳಿತ್ತಿ',
'ತಿಗ್ಮಕರ',
'ತಿಟಿತಿಟಿ',
'ತಿಟಿಮಿಟಿ',
'ತಿಟ್ಟಮಿಡು',
'ತಿಟ್ಟವಡು',
'ತಿಟ್ಟವಿಕ್ಕು',
'ತಿಟ್ಟವಿಡು',
'ತಿಟ್ಟುವಾಯ್',
'ತಿಣಂಗಿಱು',
'ತಿಣಿಕಿಸು',
'ತಿಣುಕಾಟ',
'ತಿಣುಕಾಡು',
'ತಿಣ್ಣಂಬೆಱು',
'ತಿಣ್ಣಗೆಡು',
'ತಿಣ್ಣಮಿಡು',
'ತಿಣ್ಣವಡೆ',
'ತಿಣ್ಣವಲೆ',
'ತಿದಿಯುಗಿ',
'ತಿದಿಯೂದು',
'ತಿದಿಯೊತ್ತು',
'ತಿದ್ದುಗಡೆ',
'ತಿದ್ದುಗಾರ',
'ತಿದ್ದುಪಡಿ',
'ತಿದ್ದುಪಡೆ',
'ತಿದ್ದುಪಾಟು',
'ತಿನಸಿಗ',
'ತಿನಿಸುಗ',
'ತಿನೆಪಡು',
'ತಿನ್ನಬಾಕ',
'ತಿನ್ನಾಸಕ',
'ತಿನ್ನಾಸಕಿ',
'ತಿಪಲಾಟ',
'ತಿಪ್ಪೆಗುಂಡಿ',
'ತಿಪ್ಪೆವಳ್ಳ',
'ತಿಪ್ಪೆಸುಂಕ',
'ತಿಬ್ಬಂಮಾಡು',
'ತಿಬ್ಬಿವೈದ್ಯ',
'ತಿಮಿಂಗಿಲ',
'ತಿಮಿಂಗಿಳ',
'ತಿಮಿಕಾರೆ',
'ತಿಮಿರಾಕ್ಷ',
'ತಿಮಿರಾಸ್ತ್ರ',
'ತಿರಗಂಚಿ',
'ತಿರಗಾಸು',
'ತಿರಜೀವಿ',
'ತಿರಶ್ಚೀನ',
'ತಿರಸಟ',
'ತಿರಸಷ್ಟ',
'ತಿರಸ್ಕರ',
'ತಿರಸ್ಕಾರ',
'ತಿರಸ್ಕೃತ',
'ತಿರಸ್ಕೃತಿ',
'ತಿರಸ್ಕೃತೆ',
'ತಿರಾಯತ',
'ತಿರಿಕತ್ವ',
'ತಿರಿಕಲ್ಲು',
'ತಿರಿಕುಳಿ',
'ತಿರಿಕೂಳು',
'ತಿರಿಕೂೞ್',
'ತಿರಿಕೊರೆ',
'ತಿರಿಕೊಱೆ',
'ತಿರಿಗಟ್ಟು',
'ತಿರಿಗೂಳು',
'ತಿರಿತರ್',
'ತಿರಿತರು',
'ತಿರಿತಿನ್',
'ತಿರಿದಾನ',
'ತಿರಿನಾಳ್',
'ತಿರಿಮನೆ',
'ತಿರಿಮುರಿ',
'ತಿರಿಯಂಚ',
'ತಿರಿಯಕ್ಕು',
'ತಿರಿಸುತ್ತು',
'ತಿರುಂಗಿಸು',
'ತಿರುಕುಳಿ',
'ತಿರುಕೋಲು',
'ತಿರುಗಣಿ',
'ತಿರುಗಣೆ',
'ತಿರುಗಾಟ',
'ತಿರುಗಾಡು',
'ತಿರುಗಾಣಿ',
'ತಿರುಗಾಸ್',
'ತಿರುಗಿಸು',
'ತಿರುಗುಣಿ',
'ತಿರುಗುತ್ತ',
'ತಿರುಗುಪ್ಪು',
'ತಿರುಗುಹ',
'ತಿರುಗುಳಿ',
'ತಿರುಗೋಲು',
'ತಿರುಚೂರ್ಣ',
'ತಿರುಣಾಳ್',
'ತಿರುದಳ',
'ತಿರುನಾಮ',
'ತಿರುನಾಳ್',
'ತಿರುನಾಳು',
'ತಿರುನೀರು',
'ತಿರುನೀಱು',
'ತಿರುಪಣಿ',
'ತಿರುಪತಿ',
'ತಿರುಪಳಿ',
'ತಿರುಪಾಟ',
'ತಿರುಪಾಟು',
'ತಿರುಪಾಟ್ಟು',
'ತಿರುಪಾಡು',
'ತಿರುಪುಹೂ',
'ತಿರುಪುಳಿ',
'ತಿರುಪ್ಪಣ್ಣಿ',
'ತಿರುಪ್ಪಾಯಿ',
'ತಿರುಬೊಬ್ಬೆ',
'ತಿರುಬೋಕಿ',
'ತಿರುಮಂತ್ರ',
'ತಿರುಮಣ್',
'ತಿರುಮಣಿ',
'ತಿರುಮಣ್ಣು',
'ತಿರುಮಲೆ',
'ತಿರುಮಾಲೆ',
'ತಿರುಮೀಸೆ',
'ತಿರುಮುಡಿ',
'ತಿರುಮುರಿ',
'ತಿರುಮೂರ್ತಿ',
'ತಿರುವಡಿ',
'ತಿರುವದಿ',
'ತಿರುವಳಿ',
'ತಿರುವಾಯ್',
'ತಿರುವಿಡು',
'ತಿರುವಿಸು',
'ತಿರುವೀಥಿ',
'ತಿರುವೆಟ್ಟ',
'ತಿರುವೆಡೆ',
'ತಿರುವೊಡೆ',
'ತಿರುವೊಯ್',
'ತಿರುವೊಯ್ಲು',
'ತಿರುಹಾಡು',
'ತಿರುಹಿಸು',
'ತಿರುಳಳಿ',
'ತಿರುಳಿಗೆ',
'ತಿರುಳೆತ್ತು',
'ತಿರುಳೆಲೆ',
'ತಿರೋಧಾನ',
'ತಿರೋಭಾವ',
'ತಿರೋಹಿತ',
'ತಿರೋಹಿಸು',
'ತಿರ್ದುವಡು',
'ತಿರ್ದುವರ್',
'ತಿರ್ಪೆಸುಂಕ',
'ತಿರ್ಯಕ್ಪುಂಡ್ರ',
'ತಿರ್ಯಗ್ಗತಿ',
'ತಿರ್ಯಗ್ಜಂತು',
'ತಿರ್ಯಗ್ಜಾತಿ',
'ತಿರ್ಯಗ್ಡೀನ',
'ತಿರ್ಯಗ್ಯೋನಿ',
'ತಿರ್ಯಙ್ಮುಖ',
'ತಿರ್ಯಾಹಿತ',
'ತಿಲಕಿತ',
'ತಿಲಕಿಸು',
'ತಿಲತೈಲ',
'ತಿಲಪರ್ಣಿ',
'ತಿಲಪಿಂಜ',
'ತಿಲಪೇಜ',
'ತಿಲಯಂತ್ರ',
'ತಿಲವಿಷ್ಟೆ',
'ತಿಲಸ್ಮಾತಿ',
'ತಿಲಾಂಜಲಿ',
'ತಿಲುವನಿ',
'ತಿಲೋತ್ತಮೆ',
'ತಿಲೋದಕ',
'ತಿಲೌದನ',
'ತಿಲ್ಲಾಣಿಸು',
'ತಿವಟಿಗೆ',
'ತಿವದಿಗೆ',
'ತಿವಿಗತ್ತಿ',
'ತಿವಿಗುಳು',
'ತಿವಿಗೋಲು',
'ತಿವಿನುಡಿ',
'ತಿವುಟಿಗೆ',
'ತಿವುೞ್ನಾಡು',
'ತಿಷ್ಯಫಲ',
'ತಿಷ್ಯಫಲೆ',
'ತಿಸುಗುಟ್ಟು',
'ತಿಳಕಿಸು',
'ತಿಳತೈಳ',
'ತಿಳಿಗಂಪು',
'ತಿಳಿಗಚ್ಚು',
'ತಿಳಿಗಣ್ಣು',
'ತಿಳಿಗಳ್',
'ತಿಳಿಗಾಸು',
'ತಿಳಿಗೇಡಿ',
'ತಿಳಿಗೊಳು',
'ತಿಳಿಗೊಳ್ಳು',
'ತಿಳಿತಳ',
'ತಿಳಿನಗೆ',
'ತಿಳಿನಿದ್ರೆ',
'ತಿಳಿನೀರ್',
'ತಿಳಿನೀರು',
'ತಿಳಿನೀಲಿ',
'ತಿಳಿನುಡಿ',
'ತಿಳಿನೋಟ',
'ತಿಳಿಬಗೆ',
'ತಿಳಿಬಣ್ಣ',
'ತಿಳಿಮಣ್ಣು',
'ತಿಳಿಯಿಡು',
'ತಿಳಿಯೆಣ್ಣೆ',
'ತಿಳಿವಡೆ',
'ತಿಳಿವಿಕೆ',
'ತಿಳಿವಿಗ',
'ತಿಳಿವುಟ್ಟು',
'ತಿಳಿಹೇಳು',
'ತಿಳುಪೇಱು',
'ತಿಳುವಿಕೆ',
'ತಿಳುಹಿಸು',
'ತಿಱಿಕಲ್',
'ತಿಱಿಕಲ್ಲು',
'ತಿಱಿಕುಳಿ',
'ತಿಱಿಕೊಱೆ',
'ತಿಱಿತರ್',
'ತಿಱಱಗೆ',
'ತಿಱಱನೆ',
'ತೀಕ್ಷ್ಣದೃಷ್ಟಿ',
'ತೀಕ್ಷ್ಣಧಾರೆ',
'ತೀಕ್ಷ್ಣಬುದ್ಧಿ',
'ತೀಕ್ಷ್ಣಮತಿ',
'ತೀಕ್ಷ್ಣಾಪಾಂಗ',
'ತೀಕ್ಷ್ಣೋಪಾಯ',
'ತೀಕ್ಷ್ಣೌಷಧ',
'ತೀಟೆಗಾತಿ',
'ತೀಟೆಗಾರ',
'ತೀನತೇರ',
'ತೀನುತೇರಾ',
'ತೀನ್ಮಸಗು',
'ತೀಬ್ರಪಾತ',
'ತೀಯಕಾರ್ತಿ',
'ತೀರರುಹ',
'ತೀರುಕಣೆ',
'ತೀರುಗಡೆ',
'ತೀರುಗಾಲ',
'ತೀರುಪಡಿ',
'ತೀರುವಳಿ',
'ತೀರ್ಕಣಿಸು',
'ತೀರ್ಗಣಿಸು',
'ತೀರ್ಥಂಕರ',
'ತೀರ್ಥಕರ',
'ತೀರ್ಥಕ್ಷೇತ್ರ',
'ತೀರ್ಥಜಲ',
'ತೀರ್ಥಜಳ',
'ತೀರ್ಥನಾಥ',
'ತೀರ್ಥಪಾದ',
'ತೀರ್ಥಯಾತ್ರೆ',
'ತೀರ್ಥಯಾನ',
'ತೀರ್ಥರೂಪ',
'ತೀರ್ಥರೂಪು',
'ತೀರ್ಥವಿಧ',
'ತೀರ್ಥಶ್ರಾದ್ಧ',
'ತೀರ್ಥಸ್ನಾನ',
'ತೀರ್ಪುಗಾರ',
'ತೀರ್ಮಾನಿಸು',
'ತೀವಟಿಗೆ',
'ತೀವರಿಸು',
'ತೀವ್ರಗಾಮಿ',
'ತೀವ್ರಗೊಳ್',
'ತೀವ್ರಗೊಳ್ಳು',
'ತೀವ್ರಪಾತ',
'ತುಂಗಗಡ್ಡೆ',
'ತುಂಗತನ',
'ತುಂಗಭದ್ರ',
'ತುಂಗಭದ್ರೆ',
'ತುಂಗಮುಸ್ತೆ',
'ತುಂಗೆಗಡ್ಡೆ',
'ತುಂಗೆಹುಲ್ಲು',
'ತುಂಟತನ',
'ತುಂಟನಗೆ',
'ತುಂಟನೋಟ',
'ತುಂಟುಗಾತಿ',
'ತುಂಡಕೇರಿ',
'ತುಂಡತನ',
'ತುಂಡರಸ',
'ತುಂಡರಸು',
'ತುಂಡರಿಸು',
'ತುಂಡಾಡಿಗ',
'ತುಂಡಿಕೇರಿ',
'ತುಂಡಿಕೇಶಿ',
'ತುಂಡುಗಡಿ',
'ತುಂಡುಗಿಡಿ',
'ತುಂಡುಗೆಯ್',
'ತುಂಡುತನ',
'ತುಂಡುದೊರೆ',
'ತುಂಡುಪಂಚೆ',
'ತುಂಡುಪದ',
'ತುಂಡುಮುೂಳ',
'ತುಂಡುಮುೂಳಿ',
'ತುಂಡುಲಂಗ',
'ತುಂಡುವಡೆ',
'ತುಂಡುಸೀರೆ',
'ತುಂತುರ್ಗಿಡಿ',
'ತುಂತುರ್ಪನಿ',
'ತುಂತುರ್ವನಿ',
'ತುಂದವಡೆ',
'ತುಂದಶೂಲೆ',
'ತುಂಬಿಬಾಳು',
'ತುಂಬಿಮೊರೆ',
'ತುಂಬಿವಾಡು',
'ತುಂಬಿವೆದೆ',
'ತುಂಬೀಫಲ',
'ತುಂಬುಗಣ',
'ತುಂಬುಗಾಯಿ',
'ತುಂಬುಗೆಡು',
'ತುಂಬುಗೆಯ್',
'ತುಂಬುಗೊಳ್ಳು',
'ತುಂಬುಗೋಡಿ',
'ತುಂಬುತನ',
'ತುಂಬುರುಸು',
'ತುಕ್ಕಡಿಸು',
'ತುಕ್ಕುಗೊಳ್',
'ತುಕ್ಕುಹಿಡಿ',
'ತುಚ್ಚತನ',
'ತುಜಾವಂತು',
'ತುಟಾಗ್ರತೆ',
'ತುಟಾಯಿಸು',
'ತುಟಾರಿಸು',
'ತುಟಿಕಚ್ಚು',
'ತುಟಿಕಡಿ',
'ತುಟಿಗಚ್ಚು',
'ತುಟಿಗೊಂಕು',
'ತುಟಿತೆರೆ',
'ತುಟಿದೆರೆ',
'ತುಟಿಬಿಚ್ಚು',
'ತುಟಿಮುತ್ತು',
'ತುಟಿರಂಗು',
'ತುಟಿಸನ್ನೆ',
'ತುಟ್ಟತುದಿ',
'ತುಟ್ಟಿಭತ್ತೆ',
'ತುಟ್ಟಿಭತ್ಯ',
'ತುಟ್ಟಿವಡೆ',
'ತುಡುಂಗುಣಿ',
'ತುಡುಕಿಸು',
'ತುಡುಗಾಡು',
'ತುಡುಗುಣ್',
'ತುಡುಗುಣಿ',
'ತುಡುಗೊಳ್',
'ತುಡುತಿನ್',
'ತುಡುತಿನಿ',
'ತುಡುವೋಗು',
'ತುಡೆಚುಳ್ಳಿ',
'ತುಣತುಣಿ',
'ತುತಿಗೆಯ್ಯು',
'ತುತಿಯಿಸು',
'ತುತ್ತತುದಿ',
'ತುತ್ತರಾಟು',
'ತುತ್ತುಗೂಳ್',
'ತುತ್ತುಗೆಯ್ಯು',
'ತುತ್ತುಗೊಳ್',
'ತುತ್ತುಗೊಳ್ಳು',
'ತುದಿಕಳಿ',
'ತುದಿಕಾಲ್',
'ತುದಿಕಾಲು',
'ತುದಿಗಾಣು',
'ತುದಿಗಾಲ್',
'ತುದಿಗಾಲು',
'ತುದಿಗುಂಡು',
'ತುದಿಮುಟ್ಟ',
'ತುದಿಮುಟ್ಟು',
'ತುದಿಮುಟ್ಟೆ',
'ತುದಿವರಂ',
'ತುನ್ನವಾಯ',
'ತುಪ್ಪಕೀರೆ',
'ತುಪ್ಪಗಾಯ್',
'ತುಪ್ಪಗೀರೆ',
'ತುಪ್ಪಡರ್',
'ತುಪ್ಪಹೀರೆ',
'ತುಪ್ಪಹೊಂಗೆ',
'ತುಪ್ಪೋಗರ',
'ತುಬ್ಬರಿಕೆ',
'ತುಬ್ಬುಕಾಱ',
'ತುಬ್ಬುಗಾರ',
'ತುಬ್ಬುಗೊಡು',
'ತುಬ್ಬುವಳ',
'ತುಬ್ಬೇರಿಸು',
'ತುರಂಗತ್ವ',
'ತುರಂಗಮ',
'ತುರಗಾಟ',
'ತುರಿಕಡ್ಡಿ',
'ತುರಿಗಜ್ಜಿ',
'ತುರಿಮಣೆ',
'ತುರುಕಳ್ಳಿ',
'ತುರುಕಾಣ್ಯ',
'ತುರುಕಾರ',
'ತುರುಕಿತಿ',
'ತುರುಕಿಸು',
'ತುರುಕುಡಿ',
'ತುರುಗಲು',
'ತುರುಗಾಡು',
'ತುರುಗಾಲು',
'ತುರುಗಾಹಿ',
'ತುರುತುರು',
'ತುರುಪಟ್ಟಿ',
'ತುರುಪಿಸು',
'ತುರುಮಿಂಡಿ',
'ತುರುವಲು',
'ತುರುವಳ',
'ತುರುವಳ್ತಿ',
'ತುರುಹಟ್ಟಿ',
'ತುರ್ಪುಹೂಡು',
'ತುರ್ಯಾತೀತ',
'ತುರ್ಯಾರೂಢ',
'ತುರ್ಯಾಶ್ರಮ',
'ತುಲಾಕೋಟಿ',
'ತುಲಾದಂಡ',
'ತುಲಾಭಾರ',
'ತುಲಾಮಾಸ',
'ತುಲಾಯನ',
'ತುಲಾಸೂತ್ರ',
'ತುಲಾಪಾನ',
'ತುವರಹ',
'ತುವರಿಕೆ',
'ತುಷಗಳೆ',
'ತುಷಪರ್ಣ',
'ತುಷಪರ್ಣಿ',
'ತುಷಮಷ',
'ತುಷಾನಲ',
'ತುಷೋದಕ',
'ತುಷ್ಟಿಕರ',
'ತುಷ್ಟಿಬಡು',
'ತುಷ್ಟಿವಡು',
'ತುಷ್ಟಿವಡೆ',
'ತುಸಪುಸ',
'ತುಹಿನತೆ',
'ತುಹಿನರ್ತು',
'ತುಳಕಲು',
'ತುಳಾಧರ',
'ತುಳಿಯಿಸು',
'ತುಳಿಲಾಳ್',
'ತುಳಿಲಾಳು',
'ತುಳಿಲ್ಗೆಯ್',
'ತುಳಿಲ್ಗೊಳ್',
'ತುಳಿಲ್ಗೊಳು',
'ತುಳುಂಕಾಡು',
'ತುಳುಕಾಡು',
'ತುಳುಕಿಸು',
'ತುಳುಗತ್ತಿ',
'ತುಳುನಾಡು',
'ತುಱಿಹಿಡಿ',
'ತುಱುಂಗಲ್',
'ತುಱುಕಾರ್ತಿ',
'ತುಱುಕಾಱ',
'ತುಱುಗಲ್',
'ತುಱುಗಲು',
'ತುಱುಗಾಡು',
'ತುಱುಗಾಯ್',
'ತುಱುಗಾಯಿ',
'ತುಱುಗಾರ್ತಿ',
'ತುಱುಗಾಹಿ',
'ತುಱುಗಾಱ',
'ತುಱುಗೋಳ್',
'ತುಱುನೆಲೆ',
'ತುಱುಪಟ್ಟಿ',
'ತುಱುಮಂದೆ',
'ತುಱುಮಿಂಡಿ',
'ತುಱುವಟ್ಟಿ',
'ತುಱುವಳ',
'ತುಱುವಳ್ತಿ',
'ತುಱುವಳ್ಳಿ',
'ತುಱುವಾಳ',
'ತುಱುವಿಂಡು',
'ತುಱುಹಟ್ಟಿ',
'ತುೞಿಕಲ್',
'ತುೞಿಕಲ',
'ತುೞಿಯಿಸು',
'ತುೞಿಲಾಳ್',
'ತುೞಿಲ್ಗೆಯ್',
'ತುೞಿಲ್ಗೊಳು',
'ತುೞಿಲ್ಸಲ್',
'ತೂಂಕಂಗೊಳ್',
'ತೂಂಕಡಿಕೆ',
'ತೂಂಕಡಿಸು',
'ತೂಂತುಗೊಱೆ',
'ತೂಂತುವೋಗು',
'ತೂಕಗುಂದು',
'ತೂಕಡಿಕೆ',
'ತೂಕಡಿಸು',
'ತೂಕರಿಸು',
'ತೂಗಡಿಕೆ',
'ತೂಗಾಡಿಸು',
'ತೂಗುಕೋಲು',
'ತೂಗುಗುಂಡು',
'ತೂಗುತೊಲೆ',
'ತೂಗುದೀಪ',
'ತೂಗುದೊಲೆ',
'ತೂಗುನೀರು',
'ತೂಗುಬಿಡು',
'ತೂಗುಮಂಚ',
'ತೂಗುಮಣಿ',
'ತೂಗುಮಣೆ',
'ತೂಗುಮರ',
'ತೂಗುಯ್ಯಲೆ',
'ತೂಗುಯ್ಯಾಲೆ',
'ತೂಗುವಣೆ',
'ತೂಗುವರ',
'ತೂಟೆದಾರ',
'ತೂಣಗೊಳ್ಳು',
'ತೂಣಮನ',
'ತೂತಪ್ಪಚ್ಚಿ',
'ತೂತುಕೊರೆ',
'ತೂತುಬಾಯ್',
'ತೂತುಬಾಯಿ',
'ತೂತುವೋಗು',
'ತೂನಿಹುಳ',
'ತೂಬರಕ',
'ತೂರಣಿಗೆ',
'ತೂರುಗಂಡಿ',
'ತೂರುಗಾಳಿ',
'ತೂರ್ಯಸ್ಥಿತಿ',
'ತೂಲತಲ್ಪ',
'ತೂಲತಳ್ಪ',
'ತೂಷ್ಣಿಭಾವ',
'ತೂಷ್ಣೀಂಭಾವ',
'ತೂಷ್ಣೀಂಭೂತ',
'ತೂಷ್ಣೀಂಶೀಲ',
'ತೂಷ್ಣೀಭಾವ',
'ತೂಳತಲ್ಪ',
'ತೂಳವೇಳು',
'ತೂಳುಕಾರ',
'ತೂಳುವಱೆ',
'ತೃಣಕಲ್ಪ',
'ತೃಣಕುಟಿ',
'ತೃಣಗ್ರಾಹಿ',
'ತೃಣಜ್ಯೋತಿ',
'ತೃಣದರ್ಭೆ',
'ತೃಣದ್ರುಮ',
'ತೃಣಧಾನ್ಯ',
'ತೃಣಧ್ವಜ',
'ತೃಣಪ್ರತಿ',
'ತೃಣಪ್ರಾಯ',
'ತೃಣಮಣಿ',
'ತೃಣಮಾತ್ರ',
'ತೃಣರಾಜ',
'ತೃಣವೃಕ್ಷ',
'ತೃಣಶೂನ್ಯ',
'ತೃಣೀಕೃತ',
'ತೃತೀಯತೆ',
'ತೃತೀಯತ್ವ',
'ತೃಪ್ತಿಕರ',
'ತೃಪ್ತಿಗೊಳ್ಳು',
'ತೃಪ್ತಿಪಡು',
'ತೃಪ್ತಿಲಿಂಗ',
'ತೃಪ್ತಿವಡು',
'ತೃಷಾಪರ',
'ತೃಷಾಪರೆ',
'ತೃಷ್ಣೆಗೊಳ್',
'ತೆಂಕವಂಕ',
'ತೆಂಕವಕ್ಕ',
'ತೆಂಗುಗಾಯ್',
'ತೆಂಬೆರಲ್',
'ತೆಂಬೆಲರ್',
'ತೆಂಬೆಲರು',
'ತೆಕಿಮುಕ್ಕಿ',
'ತೆಕ್ಕಾಮುಕ್ಕೆ',
'ತೆಕ್ಕಿಮುಕ್ಕಿ',
'ತೆಕ್ಕೆಗಟ್ಟು',
'ತೆಕ್ಕೆಗೆಡೆ',
'ತೆಕ್ಕೆಗೆಯ್',
'ತೆಕ್ಕೆಗೈಸು',
'ತೆಕ್ಕೆಗೊಳ್',
'ತೆಕ್ಕೆಗೊಳ್ಳು',
'ತೆಕ್ಕೆಗೋಲ್',
'ತೆಕ್ಕೆಬಡಿ',
'ತೆಕ್ಕೆಬೀಳು',
'ತೆಕ್ಕೆಯಾಡು',
'ತೆಕ್ಕೆಯಿಸು',
'ತೆಕ್ಕೆವರಿ',
'ತೆಕ್ಕೆವೆಱು',
'ತೆಕ್ಕೆಹಾಯ್',
'ತೆಕ್ಕೆಹಾಯು',
'ತೆಗಳಿಕೆ',
'ತೆಗಳಿಗೆ',
'ತೆಗಳಿಸು',
'ತೆಗೞಿಸು',
'ತೆಗೆತರ್',
'ತೆಗೆನೇಣು',
'ತೆಗೆಬಗೆ',
'ತೆಗೆಬಿಗಿ',
'ತೆಗೆಮೆಟ್ಟು',
'ತೆಗೆಯಿಸು',
'ತೆಗ್ಗುಬೀಳು',
'ತೆತ್ತಗಣ್',
'ತೆತ್ತುತನ',
'ತೆನೆಗಟ್ಟು',
'ತೆನೆಗಾಯಿ',
'ತೆನೆಗಿಡ',
'ತೆನೆಗೊನೆ',
'ತೆನೆಗೊಳ್',
'ತೆನೆತೀವು',
'ತೆನೆತುಂಬು',
'ತೆನೆನಿಲ್ಲು',
'ತೆನೆಬಲಿ',
'ತೆನೆಬಿಡು',
'ತೆನೆಯಾವು',
'ತೆನೆವಳಿ',
'ತೆನೆವಾಲ್',
'ತೆನೆವೋಗು',
'ತೆನೆಹಾಲು',
'ತೆನೆಹುಲ್ಲು',
'ತೆನ್ನಮರ',
'ತೆಪ್ಪತೇರು',
'ತೆಪ್ಪರಿಗೆ',
'ತೆಪ್ಪರಿಸು',
'ತೆಪ್ಪಹಾಂಸೆ',
'ತೆಪ್ಪೋತ್ಸವ',
'ತೆಬ್ಬಱಿಸು',
'ತೆಮಲಿಸು',
'ತೆಮ್ಮೂಡಲು',
'ತೆರಂಬಿಡು',
'ತೆರಕೊಡು',
'ತೆರಳಿಕೆ',
'ತೆರಳಿಚು',
'ತೆರಳಿಸು',
'ತೆರಿಮೆದ್ದು',
'ತೆರುಗಡೆ',
'ತೆರುವಣ',
'ತೆರೆಗಟ್ಟು',
'ತೆರೆಗಣ್ಣು',
'ತೆರೆಗಯ್',
'ತೆರೆಗೂಡು',
'ತೆರೆಗೆಯ್ಸು',
'ತೆರೆಗೊನೆ',
'ತೆರೆಗೊಳ್',
'ತೆರೆಯಿಸು',
'ತೆರೆಸೀರೆ',
'ತೆರೆಸೇಲೆ',
'ತೆರ್ಕೆಗೊಳ್',
'ತೆಲುಂಗಿತಿ',
'ತೆಲುಗಿತಿ',
'ತೆಲ್ಲಂಟಿಗೆ',
'ತೆಲ್ಲಟಿಗ',
'ತೆಲ್ಲಟಿಗೆ',
'ತೆಲ್ಲಪುನಿ',
'ತೆಲ್ಲಯಿಸು',
'ತೆಲ್ಲವಣ್ಣ',
'ತೆಲ್ಲಿಗಿತಿ',
'ತೆವಟಿಗೆ',
'ತೆವರಿಕೆ',
'ತೆವರಿಸು',
'ತೆವಳಾಟ',
'ತೆಳಗಣ',
'ತೆಳುಗಾಳಿ',
'ತೆಳುನಿದ್ರೆ',
'ತೆಳುಮೆಯ್',
'ತೆಳ್ಗನ್ನಡ',
'ತೆಳ್ವೆಳಗ',
'ತೆಳ್ವೆಳಗು',
'ತೆಱಕಾಱ',
'ತೆಱಕೊಡು',
'ತೆಱಗೊಳ್',
'ತೆಱವಿಡು',
'ತೆಱುಗಡೆ',
'ತೆಱುಮಾನ್ಯ',
'ತೆಱೆಗಯ್',
'ತೆಱೆಗೊಳ್',
'ತೆಱೆದೆಱು',
'ತೆಱೆಯಿಸು',
'ತೇಂಕಾಡಿಸು',
'ತೇಂಕುದಾಣ',
'ತೇಂಗೊಳಲು',
'ತೇಕಾಡಿಸು',
'ತೇಜಂಗಿಡು',
'ತೇಜಂಗುಂದು',
'ತೇಜಂಗೆಡು',
'ತೇಜಃಕಣ',
'ತೇಜಃಕ್ಷಯ',
'ತೇಜಃಪಿಂಡ',
'ತೇಜಃಪುಂಜ',
'ತೇಜನಕ',
'ತೇಜಪುಂಜ',
'ತೇಜಫಲ',
'ತೇಜವಂತ',
'ತೇಜಶ್ಚಕ್ರ',
'ತೇಜಸಾಮ್ಯ',
'ತೇಜಸ್ತತ್ವ',
'ತೇಜಸ್ಪತಿ',
'ತೇಜಸ್ವಾಮ್ಯ',
'ತೇಜಸ್ವಿತೆ',
'ತೇಜಿಮಂದಿ',
'ತೇಜಿವಾರು',
'ತೇಜೀಮಂದಿ',
'ತೇಜೋಜೀವಿ',
'ತೇಜೋಭಂಗ',
'ತೇಜೋವಧೆ',
'ತೇಟಗೀತೆ',
'ತೇದಿರೇಖೆ',
'ತೇನತೇನ',
'ತೇನತೇನಂ',
'ತೇಮಗೊಳು',
'ತೇರಯಿಸು',
'ತೇರವಾದ',
'ತೇರುಕಟ್ಟು',
'ತೇರುತೆಕ್ಕೆ',
'ತೇರುಬೀದಿ',
'ತೇರುಹಬ್ಬ',
'ತೇರೆಸಗು',
'ತೇರ್ಗುದುರೆ',
'ತೇಲಾಡಿಸು',
'ತೇಲುಗಣ್ಣ',
'ತೇಲುಗಣ್ಣು',
'ತೇಲುನೋಟ',
'ತೇಲುಪಟ್ಟಿ',
'ತೇಲುಮನೆ',
'ತೇಲುಮಾತು',
'ತೇವಗೊಳ್ಳು',
'ತೇವಟಿಗೆ',
'ತೇೞ್ಕುಟಿಗ',
'ತೈತ್ತರೀಯ',
'ತೈರುವಡೆ',
'ತೈಲಕಾರ',
'ತೈಲಚಿತ್ರ',
'ತೈಲಧಾರೆ',
'ತೈಲಪರ್ಣಿ',
'ತೈಲಪಾಕ',
'ತೈಲಯಂತ್ರ',
'ತೈಲರಂಧ್ರ',
'ತೈಲವರ್ಣ',
'ತೈಲವರ್ತಿ',
'ತೊಂಗಲಿಸು',
'ತೊಂಗಲ್ಗೊಳ್',
'ತೊಂಚವಂಚ',
'ತೊಂಚಾವಂಚ',
'ತೊಂಡತನ',
'ತೊಂಡಲಿಪಿ',
'ತೊಂಡಲ್ವಚ್ಚ',
'ತೊಂಡುಗೆಡೆ',
'ತೊಂಡುಗೆಯ್',
'ತೊಂಡುಗೇಡು',
'ತೊಂಡುಗೊಳ್',
'ತೊಂಡುತನ',
'ತೊಂಡುಬಂಡು',
'ತೊಂಡುಬೀಳು',
'ತೊಂಡುವಾಡೆ',
'ತೊಂಡುಹೋಗು',
'ತೊಂಡರಸಿ',
'ತೊಂಡೆಕೇತ',
'ತೊಗರತ್ತಿ',
'ತೊಗಲ್ವೇಱು',
'ತೊಟಕಿಕ್ಕು',
'ತೊಟಗುಟ್ಟು',
'ತೊಟ್ಟಿಮನೆ',
'ತೊಟ್ಟಿಲಿಕ್ಕು',
'ತೊಟ್ಟಿಲಿಗ',
'ತೊಟ್ಟಿಲಿಡು',
'ತೊಟ್ಟುಬಿಡು',
'ತೊಟ್ಟುವಾಯ್',
'ತೊಟ್ಟುಳಿಕೆ',
'ತೊಟ್ಟುೞಿಕೆ',
'ತೊಟ್ಟೆಮುಳ್ಳು',
'ತೊಡಂಕಿಕ್ಕು',
'ತೊಡಂಗಟ್ಟು',
'ತೊಡಂದೊಡು',
'ತೊಡಕಾಲು',
'ತೊಡಕಿಸು',
'ತೊಡಗಿಸು',
'ತೊಡಗುಹ',
'ತೊಡರಿಕ್ಕು',
'ತೊಡರಿಚು',
'ತೊಡರಿಸು',
'ತೊಡುಗೊರೆ',
'ತೊಡುವಡ್ಡಿ',
'ತೊಡೆಗಟ್ಟ',
'ತೊಡೆಗಟ್ಟಿ',
'ತೊಡೆತಟ್ಟು',
'ತೊಡೆದಟ್ಟು',
'ತೊಡೆಮಡ',
'ತೊಡೆಯಿಸು',
'ತೊಡೆವಾಳ',
'ತೊಡೆವಾಳೆ',
'ತೊಡೆವೊಯ್',
'ತೊಡೆಸಕ್ಕಿ',
'ತೊಡೆಸಕ್ಕೆ',
'ತೊಡೆಸನ್ನೆ',
'ತೊಡೆಸೋಂಕು',
'ತೊಣೆಗಾಣು',
'ತೊಣೆವಕ್ಕಿ',
'ತೊಣೆವರ್',
'ತೊಣೆವೆಱು',
'ತೊಣೆಹೋಗು',
'ತೊತ್ತುಗೆಯ್ಸು',
'ತೊತ್ತುಗೈಸು',
'ತೊತ್ತುಗೊಡು',
'ತೊತ್ತುಗೊಳ್',
'ತೊತ್ತುತನ',
'ತೊತ್ತುಬುದ್ಧಿ',
'ತೊತ್ತುಮಗ',
'ತೊತ್ತುವಗ',
'ತೊತ್ತುವೆಸ',
'ತೊತ್ತುವೇಟ',
'ತೊತ್ತುವೊಗು',
'ತೊತ್ತುವೋಗು',
'ತೊದಲಾಟ',
'ತೊದಲಿಸು',
'ತೊದಲುಲಿ',
'ತೊದಲ್ನುಡಿ',
'ತೊದಲ್ವಾತು',
'ತೊದಳಿಲಿ',
'ತೊದಳಿಸು',
'ತೊದಳುಲಿ',
'ತೊದಳ್ನುಡಿ',
'ತೊದಳ್ಮಾಡು',
'ತೊದಳ್ವಾತು',
'ತೊನೆಯಿಸು',
'ತೊನ್ನುಗೊಳ್',
'ತೊನ್ನುವಿಡಿ',
'ತೊನ್ನುಹಿಡಿ',
'ತೊಪತೊಪ',
'ತೊಮ್ಮೆವಡೆ',
'ತೊರಂಗಲು',
'ತೊರೆಕರೆ',
'ತೊರೆಗರೆ',
'ತೊರೆಬಿಡು',
'ತೊರೆಮತ್ತಿ',
'ತೊರೆಮಾವು',
'ತೊರೆಮೆಂತಿ',
'ತೊರೆಮೆಂತೆ',
'ತೊರೆಯಿಸು',
'ತೊರೆಯೊಳ್ಕು',
'ತೊರೆಹಾಲು',
'ತೊರ್ತುವೆಸ',
'ತೊಲಂಗಿಸು',
'ತೊಲಗಿಕೆ',
'ತೊಲಗಿಸು',
'ತೊಲೆಗೋಲ್',
'ತೊಲೆಗೋಲು',
'ತೊವರೇಱು',
'ತೊವಲಿಕ್ಕು',
'ತೊವಲ್ಗೊಳ್',
'ತೊವಲ್ವಲೆ',
'ತೊವಲ್ವಿಡಿ',
'ತೊಳಕಲು',
'ತೊಳಲಾಟ',
'ತೊಳಲಾಡು',
'ತೊಳಲಿಕೆ',
'ತೊಳಲಿಸು',
'ತೊಳಸಾಟ',
'ತೊಳಸುಗ',
'ತೊಳೆಯಿಸು',
'ತೊಳ್ತುಗೆಯ್',
'ತೊಳ್ತುಗೆಯ್ಮೆ',
'ತೊಳ್ತುಗೊಡು',
'ತೊಳ್ತುಗೊಳ್',
'ತೊಳ್ತುಬಾಳ್',
'ತೊಳ್ಳೆಮೀನು',
'ತೊಱುವಳ್ಳ',
'ತೊಱುವಾಳ್ಳ',
'ತೊಱೆಗಟ್ಟು',
'ತೊಱೆಗರೆ',
'ತೊಱೆದಡಿ',
'ತೊಱೆನಾಯ್',
'ತೊಱೆಮತ್ತಿ',
'ತೊಱೆಮಾವು',
'ತೊಱೆಯಿಸು',
'ತೊಱೆವರಿ',
'ತೊಱೆವೇಂಟೆ',
'ತೊೞಕಲ್',
'ತೊೞ್ತುಗೊಡು',
'ತೊೞ್ತುಗೊಳ್',
'ತೊೞ್ತುತನ',
'ತೊೞ್ತುವೆಸ',
'ತೋಂಟವಾಳ',
'ತೋಂಟವೆಸ',
'ತೋಂಟಿಗೊಳ್',
'ತೋಕೆಗೋಧಿ',
'ತೋಚಿಕೊಳ್ಳು',
'ತೋಟಗಟ್ಟು',
'ತೋಟಗಾರ',
'ತೋಟದೂರ',
'ತೋಟಪಟ್ಟಿ',
'ತೋಟವಳ',
'ತೋಟವಾಳ',
'ತೋಟಿಕಾಱ',
'ತೋಟಿಗಾಱ',
'ತೋಟಿಗಿತಿ',
'ತೋಟಿಗೊಳು',
'ತೋಟ್ಟಪಟ್ಟು',
'ತೋಡುಂಗಿಱು',
'ತೋಡುದಾರಿ',
'ತೋಡುದೋಣಿ',
'ತೋತಾಪುರಿ',
'ತೋಪುಖಾನಿ',
'ತೋಫಖಾನೆ',
'ತೋಯನಿಧಿ',
'ತೋಯಪಾನ',
'ತೋಯರಾಶಿ',
'ತೋಯೋದ್ದೇಶ',
'ತೋಯೌಕಸ',
'ತೋರಡವು',
'ತೋರಮಲ್ಲಿ',
'ತೋರಹತ್ತ',
'ತೋರುಗಣ್ಣು',
'ತೋರುಮನೆ',
'ತೋರುಮರ',
'ತೋರುವಳಿ',
'ತೋರುವೊತ್ತೆ',
'ತೋರೆಮತ್ತಿ',
'ತೋರ್ಕೆಗಿಡು',
'ತೋರ್ಕೆಗುಡು',
'ತೋರ್ಕೆಗೊಡು',
'ತೋರ್ಕೆಗೊಳ್',
'ತೋರ್ಕೆವಡೆ',
'ತೋರ್ಕೆವರ್',
'ತೋರ್ಕೆವೆಱು',
'ತೋರ್ಕೊಳಿಸು',
'ತೋರ್ಪಡಿಸು',
'ತೋರ್ಪುಗೆಡು',
'ತೋಲುತಪ್ಪು',
'ತೋಲ್ವಾವಲ್',
'ತೋಶಿಕಾನೆ',
'ತೋಷಖಾನೆ',
'ತೋಷಿಖಾನೆ',
'ತೋಷೀಖಾನೆ',
'ತೋಷೇಖಾನೆ',
'ತೋಸಂಗೊಳ್',
'ತೋಹಡಿಕೆ',
'ತೋಹಮತ್ತು',
'ತೋಹಮಾತು',
'ತೋಹುಕಾಱ',
'ತೋಹುಗೊಳ್',
'ತೋಹುವಿಡಿ',
'ತೋಹುವೇಂಟೆ',
'ತೋಳವಟ್ಟ',
'ತೋಳುಬಂದಿ',
'ತೋಳುಮರ',
'ತೋಳ್ಕೈಗೊಡು',
'ತೋಳ್ಗೈಗೊಡು',
'ತೋಳ್ಮೊದಲ್',
'ತೋಳ್ಮೊದಲು',
'ತೋಳ್ವಲಯ',
'ತೌಡುಕುಟ್ಟು',
'ತೌರುಮನೆ',
'ತೌರ್ಯತ್ರಿಕ',
'ತೌಲನಿಕ',
'ತೌಲಿಗೇಹ',
'ತ್ಯಜಯಿಸು',
'ತ್ಯಾಗಪತ್ರ',
'ತ್ಯಾಗಮಯಿ',
'ತ್ಯಾಗಮಾನ',
'ತ್ಯಾಗಶೀಲ',
'ತ್ಯಾಗಶೀಲೆ',
'ತ್ರಪಾಹೀನ',
'ತ್ರಪಾಹೀನೆ',
'ತ್ರಯೋದಶ',
'ತ್ರಯೋದಶಿ',
'ತ್ರಯೋಭಾಗ',
'ತ್ರಸಕಾಯ',
'ತ್ರಸಘಾತ',
'ತ್ರಸಜೀವ',
'ತ್ರಸಜೀವಿ',
'ತ್ರಸರೇಣು',
'ತ್ರಸ್ತಾಲಸ',
'ತ್ರಾಣಂಗೆಡು',
'ತ್ರಾಣಗುಂದು',
'ತ್ರಾಣಗೊಳ್ಳು',
'ತ್ರಾಣಪುಷ್ಟಿ',
'ತ್ರಾಯಮಾಣೆ',
'ತ್ರಾಸಂಗೊಳ್',
'ತ್ರಾಸಕೊಡು',
'ತ್ರಾಸಗೊಳ್ಳು',
'ತ್ರಿಕಂಟಕ',
'ತ್ರಿಕಟುಕ',
'ತ್ರಿಕರಣ',
'ತ್ರಿಕಾಲಜ್ಞ',
'ತ್ರಿಕಾಲಜ್ಞೆ',
'ತ್ರಿಕೋಣಸ್ಥ',
'ತ್ರಿಗಾರವ',
'ತ್ರಿಗುಣಿಸು',
'ತ್ರಿಜಾತಕ',
'ತ್ರಿಜ್ಯಾಮಿತಿ',
'ತ್ರಿಣಯನ',
'ತ್ರಿದಶತ್ವ',
'ತ್ರಿನಯನ',
'ತ್ರಿಪಟಿಕ',
'ತ್ರಿಪತಾಕ',
'ತ್ರಿಪತ್ರಕ',
'ತ್ರಿಪತ್ರಿಣಿ',
'ತ್ರಿಪದಿಕೆ',
'ತ್ರಿಪಿಟಕ',
'ತ್ರಿಪುಂಡ್ರಕ',
'ತ್ರಿಭುವನ',
'ತ್ರಿಮತಸ್ಥ',
'ತ್ರಿಮಾತ್ರಕ',
'ತ್ರಿಯಂಬಕ',
'ತ್ರಿಯಕ್ಷರ',
'ತ್ರಿವಿಕ್ರಮ',
'ತ್ರಿವಿಷ್ಟಪ',
'ತ್ರಿವೇಣುಕ',
'ತ್ರಿಷವಣ',
'ತ್ರುಟಿತಾಂಗ',
'ತ್ರೇತಾಯುಗ',
'ತ್ರೈಕಾಲಿಕ',
'ತ್ರೈಮಾಸಿಕ',
'ತ್ರೈರಾಶಿಕ',
'ತ್ರೈರಾಸಿಕ',
'ತ್ರೈವರ್ಣಿಕ',
'ತ್ರೈವಿಕ್ರಮ',
'ತ್ರ್ಯಶ್ರೀಕೃತ',
'ತ್ವಗಿಂದ್ರಿಯ',
'ತ್ವಚಿಸಾರ',
'ತ್ವರಿತತೆ',
'ತ್ವರೆಪಡು',
'ತ್ವಾಟದೂರ',
'ತ್ವಿಷಾಂಪತಿ',
'ಥಕಥಾಕು',
'ಥಕಪಕ',
'ಥಟಕ್ಕನೆ',
'ಥಟ್ಟಯಿಸು',
'ಥಟ್ಟುಗೆಡು',
'ಥಟ್ಟುಗೆಡೆ',
'ಥಟ್ಟುತಾಱು',
'ಥರಂಬಿಡಿ',
'ಥರಗುಟ್ಟು',
'ಥರಥರ',
'ಥರವಿಡಿ',
'ಥರಾವರಿ',
'ಥಳಥಳ',
'ಥಳಪಳ',
'ಥಾಲೀಪಿಟ್ಟು',
'ಥಾಳಥಳ್ಯ',
'ಥಾಱುಥಟ್ಟು',
'ಥೂಕರಿಸು',
'ಥೇರಕಪ್ಪ',
'ದಂಗಾಮಸ್ತಿ',
'ದಂಗುಬಡಿ',
'ದಂಗುಬಡು',
'ದಂಗುವಡೆ',
'ದಂಗೆಕೋರ',
'ದಂಗೆಗಾರ',
'ದಂಗೆಯೇಳು',
'ದಂಟುಬೇರು',
'ದಂಡಂಗೊಳ್',
'ದಂಡಂದೆಱು',
'ದಂಡಕಾಂತ',
'ದಂಡಕೋಲು',
'ದಂಡಖಂಡ',
'ದಂಡತೆರು',
'ದಂಡದಾಯ',
'ದಂಡದೋಸ',
'ದಂಡದೋಷ',
'ದಂಡದ್ರವ್ಯ',
'ದಂಡಧರ',
'ದಂಡಧಾರ',
'ದಂಡನಾಥ',
'ದಂಡನೀತಿ',
'ದಂಡಪಕ್ಷ',
'ದಂಡಪಾಣಿ',
'ದಂಡಪಾದ',
'ದಂಡಪಿಂಡ',
'ದಂಡಭ್ರಮ',
'ದಂಡಯಾತ್ರೆ',
'ದಂಡರತ್ನ',
'ದಂಡವಂತ',
'ದಂಡವಳ',
'ದಂಡವಿಡು',
'ದಂಡಾದಂಡಿ',
'ದಂಡಾಧಿಪ',
'ದಂಡಾಧೀಶ',
'ದಂಡಾನ್ವಯ',
'ದಂಡಾಯುಧ',
'ದಂಡಾವಳಿ',
'ದಂಡಾಸನ',
'ದಂಡಾಹತ',
'ದಂಡಿಕಾಱ',
'ದಂಡಿಕೋಲು',
'ದಂಡಿದಾಸ',
'ದಂಡಿಯಾಸೆ',
'ದಂಡಿವಲೆ',
'ದಂಡಿಸರ',
'ದಂಡುದಾಳಿ',
'ದಂಡುಪಾಳ್ಯ',
'ದಂಡುಬಿಡು',
'ದಂಡುವೋಗು',
'ದಂಡುಳಿಗ',
'ದಂಡುಱುಂಬೆ',
'ದಂಡುೞಿಗ',
'ದಂಡೆಕಟ್ಟು',
'ದಂಡೆಗಟ್ಟು',
'ದಂಡೆಗಲ್ಲು',
'ದಂಡೆಗುಡು',
'ದಂಡೆಗೊಡು',
'ದಂಡೆಗೊಳ್',
'ದಂಡೆಗೊಳು',
'ದಂಡೆಗೊಳ್ಳು',
'ದಂಡೋತ್ಪಲ',
'ದಂಡೋತ್ಪಳ',
'ದಂತಕತೆ',
'ದಂತಕಥೆ',
'ದಂತಕಾಷ್ಠ',
'ದಂತಕೂಟ',
'ದಂತಕ್ಷತ',
'ದಂತಚೂರ್ಣ',
'ದಂತಚ್ಛದ',
'ದಂತನೂತಿ',
'ದಂತಪಂಕ್ತಿ',
'ದಂತಪತ್ರ',
'ದಂತಮಲ',
'ದಂತರಂಗ',
'ದಂತವಾಸ',
'ದಂತವೇಷ್ಟ',
'ದಂತವೈದ್ಯ',
'ದಂತವೈದ್ಯೆ',
'ದಂತಶಠ',
'ದಂತಶಠೆ',
'ದಂತಸಾರ',
'ದಂತಹತಿ',
'ದಂತಹರ್ಷ',
'ದಂತಾಂಕುರ',
'ದಂತಾಂಶುಕ',
'ದಂತಾದಂತಿ',
'ದಂತಾಬಲ',
'ದತಾರ್ಬುದ',
'ದಂತಾವಲ',
'ದಂತಾವಳ',
'ದಂತುರಿತ',
'ದಂದಶೂಕ',
'ದಂಭಾಚಾರ',
'ದಂಷ್ಟ್ರಾಯುಧ',
'ದಕೋದರ',
'ದಕ್ಕಡತಿ',
'ದಕ್ಕಡಿಗ',
'ದಕ್ಕಡೆಯ',
'ದಕ್ಕಣಿಸು',
'ದಕ್ಕುಂದಲೆ',
'ದಕ್ಕುಕೊಡು',
'ದಕ್ಕುಗೊಳ್',
'ದಕ್ಕುಬೀಳು',
'ದಕ್ಕೆಬಲಿ',
'ದಕ್ಷಬ್ರಹ್ಮ',
'ದಕ್ಷಸ್ಮೃತಿ',
'ದಕ್ಷಿಣಸ್ಥ',
'ದಕ್ಷಿಣಾಗ್ನಿ',
'ದಕ್ಷಿಣಾದಿ',
'ದಕ್ಷಿಣಾರ್ಥ',
'ದಗದಗ',
'ದಗಲ್ಬಾಜಿ',
'ದಗಾಕೋರ',
'ದಗಾಖೋರ',
'ದಗಾಬಾಜಿ',
'ದಗ್ಗುದುಳಿ',
'ದಗ್ಧವಾಣಿ',
'ದಚ್ಚಿಬಾರ',
'ದಟದಟ',
'ದಟ್ಟಂದರಿ',
'ದಟ್ಟಣಿಸು',
'ದಟ್ಟಯಿಸು',
'ದಟ್ಟವಿಸು',
'ದಟ್ಟಿಗಟ್ಟು',
'ದಟ್ಟಿಪಂಚೆ',
'ದಟ್ಟಿಸೀರೆ',
'ದಡಕಟ್ಟು',
'ದಡಗಲಿ',
'ದಡಗುಟ್ಟು',
'ದಡಬಡ',
'ದಡಬಡಿ',
'ದಡಲನೆ',
'ದಡಸಲು',
'ದಡಸೂಸು',
'ದಡಾರನೆ',
'ದಡಿಕಾಱ',
'ದಡಿಗಯ್ಯ',
'ದಡಿಗೆಯ್ಯ',
'ದಡಿಗೈಯ',
'ದಡಿಗೋಲು',
'ದಡಿದೋತ್ರ',
'ದಡಿವಲೆ',
'ದಡಿಸೀರೆ',
'ದಡೆಕಟ್ಟು',
'ದಡ್ಡಕ್ಕರ',
'ದಡ್ಡತನ',
'ದಡ್ಡುಕಟ್ಟು',
'ದಡ್ಡುಬೀಳು',
'ದಡ್ಡುವೀೞ್',
'ದಢಕ್ಕನೆ',
'ದಢೀರನೆ',
'ದಢುವತಿ',
'ದಣದಣ',
'ದಣಾಯಕ',
'ದಣಾವಟ್ಠಿ',
'ದಣಿಯಿಸು',
'ದಣಿಯುಣ್',
'ದಣಿವಟ್ಟೆ',
'ದಣಿವಾಗು',
'ದಣಿವೈದು',
'ದಣುವಟ್ಟಿ',
'ದಣುವಟ್ಟೆ',
'ದಣುವಿಕೆ',
'ದಣುವೊಟ್ಟಿ',
'ದಣ್ಣಾಯಕ',
'ದತ್ತಗೇಡಿ',
'ದತ್ತಾಶಿಕ್ಷ',
'ದತ್ತುಗೊಳ್',
'ದತ್ತುಪುತ್ರ',
'ದತ್ತುಪುತ್ರಿ',
'ದತ್ತುಮಗ',
'ದತ್ವವಿಧಿ',
'ದದ್ದರಿಸು',
'ದದ್ದಾಲದ',
'ದದ್ದುಹಿಡಿ',
'ದಧಿತೈಲ',
'ದಧಿಪುಷ್ಪಿ',
'ದಧಿಫಲ',
'ದಧಿಫಳ',
'ದಧಿಮಂಡ',
'ದಧಿಮಂಥ',
'ದಧಿಮುಖ',
'ದಧಿವಡೆ',
'ದಧಿಸಕ್ತು',
'ದಧಿಸಾರ',
'ದಧಿಸೃಷ್ಟ',
'ದಧ್ಯೋದನ',
'ದನಕರು',
'ದನಕಱು',
'ದನಗಜ್ಜಿ',
'ದನಗಬ್ಬು',
'ದನಗಳ್ಳ',
'ದನಗಾಯಿ',
'ದನಗಾರ',
'ದನಗಾವಿ',
'ದನಗಾಹಿ',
'ದನಗಾಹು',
'ದನಗುರಿ',
'ದನಗೋಳು',
'ದನಬಳ',
'ದನಿಕೂರು',
'ದನಿಗುಟ್ಟು',
'ದನಿಗುಡು',
'ದನಿಗೂಡು',
'ದನಿಗೆಡು',
'ದನಿಗೆಯ್',
'ದನಿಗೊಡು',
'ದನಿದೋರು',
'ದನಿದೋಱು',
'ದನಿಮೀಟು',
'ದನಿಯಿಡು',
'ದನಿಯೆತ್ತು',
'ದನಿಸಂತೆ',
'ದನುಕುಲ',
'ದಪಗೊಳ್ಳು',
'ದಪ್ಪಗಟ್ಟು',
'ದಪ್ಪವಳಿ',
'ದಫೇದಾರ',
'ದಫೇದಾರಿ',
'ದಬಕ್ಕನೆ',
'ದಬದಬ',
'ದಬದಬೆ',
'ದಬಾದಬಿ',
'ದಬಾದುಬಿ',
'ದಬಾಯಿಸು',
'ದಬಾವಣೆ',
'ದಬೆದಬೆ',
'ದಬ್ಬಗಳ್ಳಿ',
'ದಬ್ಬಾಳಿಕೆ',
'ದಬ್ಬುದಲೆ',
'ದಬ್ಬುಹಾಕು',
'ದಮನಕ',
'ದಮ್ಮಗುತ್ತಿ',
'ದಮ್ಮಗುರು',
'ದಮ್ಮದತ್ತಿ',
'ದಮ್ಮುಕಟ್ಟು',
'ದಮ್ಮುಗಟ್ಟು',
'ದಮ್ಮುಗೊಳ್ಳು',
'ದಮ್ಮುಹತ್ತು',
'ದಮ್ಮುಹೊಡೆ',
'ದಯಂಗೆಯ್',
'ದಯಕಾರ',
'ದಯಕಾಱ',
'ದಯಗೆಡು',
'ದಯಗೆಯ್',
'ದಯದೃಷ್ಟಿ',
'ದಯದೋಱು',
'ದಯನೀಯ',
'ದಯಮಾಡು',
'ದಯವಿಡು',
'ದಯಾಂಬುಧಿ',
'ದಯಾಕರ',
'ದಯಾನಿಧಿ',
'ದಯಾರಸ',
'ದಯಾವಂತ',
'ದಯಾವಂತೆ',
'ದಯಾವತಿ',
'ದಯೆಗಿಡು',
'ದಯೆಗೆಡು',
'ದಯೆಗೆಯ್',
'ದರಕಟ್ಟು',
'ದರಕಾರ',
'ದರಕಾರು',
'ದರಕಾಸ್ತು',
'ದರಖಾಸ್ತ್',
'ದರಖಾಸ್ತು',
'ದರಗಾಹ',
'ದರದಟ್ಟೆ',
'ದರದರ',
'ದರದಿನ',
'ದರದುರ',
'ದರಪಣ',
'ದರಬಾರ',
'ದರಬಾರಿ',
'ದರಬಾರು',
'ದರಬೇಸಿ',
'ದರಮಹಾ',
'ದರಮಾಹೆ',
'ದರವಂದ',
'ದರವಡೆ',
'ದರವಾನ',
'ದರವಾರು',
'ದರವುರ',
'ದರವೇಶಿ',
'ದರವೇಸಿ',
'ದರಶನ',
'ದರಸಾರೆ',
'ದರಸ್ಮಿತ',
'ದರಸ್ಮೇರ',
'ದರಹಸ',
'ದರಹಾಸ',
'ದರಿಕಂದ',
'ದರಿಗೊಳ್',
'ದರಿದ್ರತೆ',
'ದರಿದ್ರತ್ವ',
'ದರಿಧೂಳ',
'ದರಿಮುಷ್ಟಿ',
'ದರಿಯಾಪ್ತು',
'ದರಿಯಾಫ್ತಿ',
'ದರಿಶನ',
'ದರಿಸನ',
'ದರಿಸಿನ',
'ದರುವಕ್ಕಿ',
'ದರುಶನ',
'ದರುಸನ',
'ದರೆಗುಂಬ',
'ದರೋದರ',
'ದರೋಬಸ್ತು',
'ದರ್ಕುಂದಲೆ',
'ದರ್ಜಿಗಿತ್ತಿ',
'ದರ್ಪಂಗಿಡು',
'ದರ್ಪಂಗೆಡು',
'ದರ್ಪಮುಖ',
'ದರ್ಭಾಂಕುರ',
'ದರ್ಮಗಾಳೆ',
'ದರ್ಮಗೆಯ್',
'ದರ್ವೀಕರ',
'ದರ್ಶನೀಯ',
'ದರ್ಶಶ್ರಾದ್ಧ',
'ದಲಪತಿ',
'ದಲವಾದ್ಯ',
'ದಲಾಯತ್',
'ದಲಾಯಿತ',
'ದಲಾಲಿಕೆ',
'ದವಡಿಗ',
'ದವಡಿತಿ',
'ದವದಾಹ',
'ದವಬಂಗ',
'ದವಲತ್',
'ದವಲತ್ತು',
'ದವಶಿಖಿ',
'ದವಸಾಯ',
'ದವಸಿಗ',
'ದವಳಾರ',
'ದವಾಖಾನೆ',
'ದವಾನಲ',
'ದಶಕೂಟ',
'ದಶಕೋಟಿ',
'ದಶಗುಣ',
'ದಶದಿಕ್ಕು',
'ದಶದಿಶೆ',
'ದಶದಿಸೆ',
'ದಶಧ್ರುವ',
'ದಶಪಾದಿ',
'ದಶಬಂಧ',
'ದಶಮಾಂಶ',
'ದಶಮಾನ',
'ದಶಮೀಸ್ಥ',
'ದಶಮೂಲ',
'ದಶರಥಿ',
'ದಶರೂಪ',
'ದಶಲಕ್ಷ',
'ದಶವಂದ',
'ದಶವಂಧ',
'ದಶವನ್ನ',
'ದಶಸಾರ',
'ದಶಾವಂತ',
'ದಶಾವಂತೆ',
'ದಶಾಹೀನ',
'ದಶಾಹೀನೆ',
'ದಷ್ಟದಂತ',
'ದಷ್ಟಪುಷ್ಟ',
'ದಸಕತು',
'ದಸಕತ್ತು',
'ದಸಗುಡು',
'ದಸಮಾಹೆ',
'ದಸವಂದ',
'ದಸಿಕೋಲ್',
'ದಸಿಗೋಲ್',
'ದಸಿವೆಟ್ಟು',
'ದಸ್ತಕತ್ತು',
'ದಸ್ತಗಿರಿ',
'ದಸ್ತಾವೇಜು',
'ದಸ್ತೇವಾಜು',
'ದಸ್ತೈವಜು',
'ದಸ್ತೈವೇಜು',
'ದಸ್ಯುದಾರಿ',
'ದಹನಾಶೆ',
'ದಳಂಬಡೆ',
'ದಳದುಳ',
'ದಳದುಳಿ',
'ದಳಪತಿ',
'ದಳಬಳ',
'ದಳಮಳ',
'ದಳವಡೆ',
'ದಳವಯ',
'ದಳವರಿ',
'ದಳವಾಡ',
'ದಳವಾಯ್',
'ದಳವಾಯಿ',
'ದಳವಿಡು',
'ದಳವುಳ',
'ದಳವುಳಿ',
'ದಳವೆತ್ತು',
'ದಳವೇರು',
'ದಳವೇಱು',
'ದಳಸರ',
'ದಳಾದುಳಿ',
'ದಳಿಕೋಣೆ',
'ದಳಿಯಿಸು',
'ದಳಿವಿಡಿ',
'ದಳೆಕಟ್ಟು',
'ದಳೆಯಿಕ್ಕು',
'ದಳೆಹಾಕು',
'ದಳ್ದಳಿಸು',
'ದಳ್ಳಾಳ್ತನ',
'ದಳ್ಳುಬಾಱು',
'ದಱದಱ',
'ದೞದುೞ',
'ದೞಮುಖ',
'ದೞವಡು',
'ದೞವಾಯ್',
'ದೞವಾಯಿ',
'ದೞವುೞ',
'ದಾಂಟುಂಗುಡಿ',
'ದಾಂಟುಗಳೆ',
'ದಾಂಟುದಾಣ',
'ದಾಂಟುಮರ',
'ದಾಂಟುವರ',
'ದಾಂಡಗಾಯಿ',
'ದಾಂಡಪಟೆ',
'ದಾಂಡಪಟ್ಟೆ',
'ದಾಂಭಿಕತೆ',
'ದಾಕುದಾರ',
'ದಾಕುಹಾಕು',
'ದಾಕ್ಷಾಯಣಿ',
'ದಾಕ್ಷಿಣಾತ್ಯ',
'ದಾಕ್ಷಿಣಾತ್ಯೆ',
'ದಾಖಲಾತಿ',
'ದಾಖಲಾತು',
'ದಾಖಲಾತೆ',
'ದಾಖಲಿಸು',
'ದಾಗದೂಜಿ',
'ದಾಗುದೂಜಿ',
'ದಾಟುಕಥೆ',
'ದಾಟುಗಾಲು',
'ದಾಟುಗೆರೆ',
'ದಾಟುತೆಗೆ',
'ದಾಟುದೀಟು',
'ದಾಟುಬೊಕ್ಕೆ',
'ದಾಟುಮರ',
'ದಾಟುಸ್ವರ',
'ದಾಡಾಬಂಧ',
'ದಾಡೆಗಡಿ',
'ದಾಡೆಗಳೆ',
'ದಾಡೆಗುಟ್ಟು',
'ದಾಡೆಬಂಧ',
'ದಾಡೆಯಾಡು',
'ದಾತ್ಯೂಹಕ',
'ದಾದಾಗಿರಿ',
'ದಾದಿಕಾಱ',
'ದಾದಿತನ',
'ದಾನಂಗುಡು',
'ದಾನಂಗೊಡು',
'ದಾನಗುಣ',
'ದಾನಪಟ್ಟೆ',
'ದಾನಪತ್ರ',
'ದಾನಪಾತ್ರ',
'ದಾನಮುದ್ರೆ',
'ದಾನಮೂಲ',
'ದಾನವಹಿ',
'ದಾನವಿಕ',
'ದಾನಶಾಲೆ',
'ದಾನಶೀಲ',
'ದಾನಶೀಲೆ',
'ದಾನಶೂರ',
'ದಾನಶೌಂಡ',
'ದಾನಸಾಲೆ',
'ದಾಪುಗಾಲು',
'ದಾಪುಹಾಕು',
'ದಾಪುಹೆಜ್ಜೆ',
'ದಾಮದೂಮ',
'ದಾಮರಿಗ',
'ದಾಮಾಶಯ',
'ದಾಮಾಶಾಯಿ',
'ದಾಮೋದರ',
'ದಾಯಂಗೊಡು',
'ದಾಯಂಬಡೆ',
'ದಾಯಕಾತಿ',
'ದಾಯಗಾಱ',
'ದಾಯಗೆಡು',
'ದಾಯಗೊಡು',
'ದಾಯಗೊಳ್',
'ದಾಯದೋಱು',
'ದಾಯಬೀಳು',
'ದಾಯಭಾಗ',
'ದಾಯವಾದಿ',
'ದಾಯವಾದ್ಯ',
'ದಾಯಾದತ್ವ',
'ದಾರಗಟ್ಟು',
'ದಾರಗೊಂಡೆ',
'ದಾರವಂದ',
'ದಾರವಟ್ಟ',
'ದಾರವಾಜ',
'ದಾರಿಕಟ್ಟು',
'ದಾರಿಕಾಯು',
'ದಾರಿಕಾರ',
'ದಾರಿಕಾಱ',
'ದಾರಿಕೆಳೆ',
'ದಾರಿಗಳ್ಳ',
'ದಾರಿಗಾಣ್',
'ದಾರಿಗಾರ',
'ದಾರಿಗೆಡು',
'ದಾರಿಗೊಳ್',
'ದಾರಿಗೊಳ್ಳು',
'ದಾರಿತಪ್ಪು',
'ದಾರಿತುಳಿ',
'ದಾರಿತೆಗೆ',
'ದಾರಿತೋರ್',
'ದಾರಿತೋರು',
'ದಾರಿತೋಱು',
'ದಾರಿದಪ್ಪು',
'ದಾರಿದೀಪ',
'ದಾರಿದೆಗೆ',
'ದಾರಿದೋಱು',
'ದಾರಿನಡೆ',
'ದಾರಿನೋಡು',
'ದಾರಿಬಡಿ',
'ದಾರಿಬಿಡು',
'ದಾರಿಮಾಡು',
'ದಾರಿಯಾಗು',
'ದಾರಿಸವೆ',
'ದಾರಿಹೋಕ',
'ದಾರುಕರ್ಮ',
'ದಾರುಣತೆ',
'ದಾರುಹಸ್ತ',
'ದಾರೆಕಟ್ಟು',
'ದಾರೆಕಡ್ಡಿ',
'ದಾರೆಗತಿ',
'ದಾರೆಗಿಂಡಿ',
'ದಾರೆಹುಳಿ',
'ದಾರೊಕ್ಕಲು',
'ದಾರ್ವಾಘಾಟ',
'ದಾರ್ಶನಿಕ',
'ದಾಷ್ಟಾಂತಿಕ',
'ದಾಲಚಿನಿ',
'ದಾಲಚಿನ್ನಿ',
'ದಾಲಚಿನ್ನೆ',
'ದಾವಡಸು',
'ದಾವಣಿಗೆ',
'ದಾವದಗ್ಧ',
'ದಾವರಿಸು',
'ದಾವಶಿಖಿ',
'ದಾವಾದಾರ',
'ದಾವಾನಲ',
'ದಾವಾನಳ',
'ದಾವೆದಾರ',
'ದಾಶಮಿಕ',
'ದಾಶಿವಾಳ',
'ದಾಸಕಬ್ಬು',
'ದಾಸಕೂಟ',
'ದಾಸಗಿತ್ತಿ',
'ದಾಸಗೌಡ',
'ದಾಸದೀಕ್ಷೆ',
'ದಾಸಪಂಕ್ತಿ',
'ದಾಸಪಂಥ',
'ದಾಸಭಾವ',
'ದಾಸಮಾರ್ಗ',
'ದಾಸವಣ',
'ದಾಸವರ್ಣ',
'ದಾಸವಾಣ',
'ದಾಸವಾಳ',
'ದಾಸಾವಿರ',
'ದಾಸಿವಣ',
'ದಾಸಿವಾಣ',
'ದಾಸಿವಾಳ',
'ದಾಸೇರಕ',
'ದಾಸ್ತಾನಿಡು',
'ದಾಹಗೊಳ್ಳು',
'ದಾಹಜ್ವರ',
'ದಾಹೋತ್ತರ',
'ದಾಳಾದಾಳಿ',
'ದಾಳಾದೂಳಿ',
'ದಾಳಾಧೂಳಿ',
'ದಾಳಿಂಬರ',
'ದಾಳಿಂಬರಿ',
'ದಾಳಿಂಬರೆ',
'ದಾಳಿಕಾರ',
'ದಾಳಿಕಾಱ',
'ದಾಳಿಮಾಡು',
'ದಾಳಿಯಿಡು',
'ದಾಳಿವರಿ',
'ದಾಳಿಹೋಗು',
'ದಾಳೆಸಿಂಪು',
'ದಾೞಾದಾೞಿ',
'ದಾೞಿಕಾಱ',
'ದಾೞಿಪೊಗು',
'ದಾೞಿಯಿಡು',
'ದಾೞಿವೋಗು',
'ದಿಂಕಿಡಿಸು',
'ದಿಂಕುವಾಱು',
'ದಿಂಡತನ',
'ದಿಂಡೀಯಾತ್ರೆ',
'ದಿಂಡುಕಟ್ಟು',
'ದಿಂಡುಕಾಱ',
'ದಿಂಡುಗಲ್ಲು',
'ದಿಂಡುಗವಿ',
'ದಿಂಡುಗೆಡು',
'ದಿಂಡುಗೆಡೆ',
'ದಿಂಡುಡಿಗೆ',
'ದಿಂಡುತನ',
'ದಿಂಡುದರಿ',
'ದಿಂಡುದಱಿ',
'ದಿಂಡುರುಳ್',
'ದಿಂಡುರುಳು',
'ದಿಂಡುರುಳ್ಚು',
'ದಿಂಡುವಾಯ್',
'ದಿಂಡುವಿಡಿ',
'ದಿಂಡೆಗಾರ',
'ದಿಂಡೆಗಾಱ',
'ದಿಂಡೆತನ',
'ದಿಕಾಮಲಿ',
'ದಿಕ್ಕಾಪಾಲು',
'ದಿಕ್ಕಾಮಲ್ಲಿ',
'ದಿಕ್ಕಾಮುಕ್ಕು',
'ದಿಕ್ಕುಗೆಡು',
'ದಿಕ್ಕುಗೇಡಿ',
'ದಿಕ್ಕುಗೇಡು',
'ದಿಕ್ಕುತಪ್ಪು',
'ದಿಕ್ಕುತೋಚು',
'ದಿಕ್ಕುವಾಯ್',
'ದಿಕ್ಕುಸೂಚಿ',
'ದಿಕ್ಪಾಲಕ',
'ದಿಕ್ಸೂಚನೆ',
'ದಿಕ್ಸ್ವಸ್ತಿಕ',
'ದಿಗಂತಗ',
'ದಿಗಂಬರ',
'ದಿಗಂಬರಿ',
'ದಿಗಂಬರೆ',
'ದಿಗಿಲಾನ್',
'ದಿಗಿಲಿಸು',
'ದಿಗಿಲೆನ್',
'ದಿಗುತಟ',
'ದಿಗುಪಾಲ',
'ದಿಗುಬಲಿ',
'ದಿಗುಭ್ರಮೆ',
'ದಿಗುಮತಿ',
'ದಿಗುಮುಖ',
'ದಿಗ್ದರ್ಶಕ',
'ದಿಗ್ದರ್ಶಕಿ',
'ದಿಗ್ದರ್ಶನ',
'ದಿಗ್ಬಂಧನ',
'ದಿಗ್ಭ್ರಾಮಿತ',
'ದಿಗ್ರದನಿ',
'ದಿಗ್ವಾಚಕ',
'ದಿಗ್ವಿಜಯ',
'ದಿಙ್ಮಂಡಲ',
'ದಿಙ್ಮೂಢತೆ',
'ದಿಟಪುಟ',
'ದಿಟವಂತ',
'ದಿಟ್ಟಗಾರ',
'ದಿಟ್ಟತನ',
'ದಿಟ್ಟವಾಳ',
'ದಿಟ್ಟಿಗರ್ಪು',
'ದಿಟ್ಟಿಗೆಡು',
'ದಿಟ್ಟಿಗೊನೆ',
'ದಿಟ್ಟಿಗೊಳ್',
'ದಿಟ್ಟಿಗೊಳ್ಳು',
'ದಿಟ್ಟಿತಾಗು',
'ದಿಟ್ಟಿತೀವು',
'ದಿಟ್ಟಿದೋಸ',
'ದಿಟ್ಟಿನಡು',
'ದಿಟ್ಟಿಬಿಸ',
'ದಿಟ್ಟಿಬೊಂಬೆ',
'ದಿಟ್ಟಿಬೊಟ್ಟು',
'ದಿಟ್ಟಿವಿಸ',
'ದಿಟ್ಟಿಸರ',
'ದಿಟ್ಟಿಹತ್ತು',
'ದಿಡಂದಳೆ',
'ದಿಡಗೊಳ್ಳು',
'ದಿನಕರ',
'ದಿನಕರ್ಮ',
'ದಿನಕಳೆ',
'ದಿನಕೂಟ',
'ದಿನಗಂಡ',
'ದಿನಗಳಿ',
'ದಿನಗಳೆ',
'ದಿನಗೂಲಿ',
'ದಿನಚರಿ',
'ದಿನಚರ್ಯ',
'ದಿನಪತಿ',
'ದಿನಬಾಳ್ವೆ',
'ದಿನಬಾೞ್ವೆ',
'ದಿನಭತ್ಯ',
'ದಿನಮಣಿ',
'ದಿನಮಧ್ಯ',
'ದಿನಮಾನ',
'ದಿನಮುಖ',
'ದಿನವಹಿ',
'ದಿಬ್ಬಣಿಗ',
'ದಿಮಿಕಿಟ',
'ದಿಮಿಗುಡು',
'ದಿಮಿಸಾಲೆ',
'ದಿಮ್ಮರಂಗ',
'ದಿಮ್ಮಿಸಾಲು',
'ದಿಮ್ಮುಹತ್ತು',
'ದಿಮ್ಮುಹಿಡಿ',
'ದಿವಂಗತ',
'ದಿವಕುಜ',
'ದಿವರಾಜ',
'ದಿವರಾತ್ರಿ',
'ದಿವರಾತ್ರೆ',
'ದಿವಸಾಂತ',
'ದಿವಾಂಧಕಿ',
'ದಿವಾಂಧತೆ',
'ದಿವಾಕರ',
'ದಿವಾಕೀರ್ತಿ',
'ದಿವಾಚರ',
'ದಿವಾನಾಡಿ',
'ದಿವಾಬಲಿ',
'ದಿವಾಮಧ್ಯ',
'ದಿವಾರಾತ್ರ',
'ದಿವಾರಾತ್ರಿ',
'ದಿವಾಸ್ವಾಪ',
'ದಿವಿಜತೆ',
'ದಿವಿದಿವಿ',
'ದಿವೌಕಸ',
'ದಿವ್ಯಚಕ್ಷು',
'ದಿವ್ಯದೃಷ್ಟಿ',
'ದಿವ್ಯಮಣಿ',
'ದಿಶಾಕರಿ',
'ದಿಶಾಧಿಪ',
'ದಿಶಾಪಟ',
'ದಿಶಾಪಟ್ಟ',
'ದಿಶಾಬಲಿ',
'ದಿಶಾಮೂಢ',
'ದಿಷ್ಟಿಬೊಟ್ಟು',
'ದಿಸಾಪಟ್ಟ',
'ದೀಕ್ಷಾಗುರು',
'ದೀಕ್ಷಾವಸ್ತ್ರ',
'ದೀಕ್ಷಾಸ್ನಾನ',
'ದೀಕ್ಷೆಗುಡು',
'ದೀಕ್ಷೆಗೆಯ್',
'ದೀಕ್ಷೆಗೊಳ್',
'ದೀಕ್ಷೆಪಡೆ',
'ದೀಕ್ಷೆಬಿಡು',
'ದೀಕ್ಷೆಬೀಳ್',
'ದೀಕ್ಷೆಯಿಕ್ಕು',
'ದೀಡುಪತ್ರ',
'ದೀನ್‍ದಾರ್',
'ದೀನತನ',
'ದೀಪಂಕರ',
'ದೀಪದ್ರಾಕ್ಷಿ',
'ದೀಪದ್ರಾಕ್ಷೆ',
'ದೀಪಧಾರಿ',
'ದೀಪನಗೈ',
'ದೀಪವರ್ತಿ',
'ದೀಪಶಿಖೆ',
'ದೀಪಸ್ತಂಭ',
'ದೀಪಾಂಕುರ',
'ದೀಪಾನನ',
'ದೀಪಾರತಿ',
'ದೀಪಾವಳಿ',
'ದೀಪಾಳಿಗೆ',
'ದೀಪೋತ್ಸವ',
'ದೀಪ್ತಾಗಮ',
'ದೀಪ್ತಿನ್ಯಾಯ',
'ದೀಪ್ತೌಷಧಿ',
'ದೀರ್ಘಕಂಠ',
'ದೀರ್ಘಕೋಶಿ',
'ದೀರ್ಘಜೀವಿ',
'ದೀರ್ಘತುಂಡಿ',
'ದೀರ್ಘದರ್ಶಿ',
'ದೀರ್ಘದೃಷ್ಟಿ',
'ದೀರ್ಘನಿದ್ರೆ',
'ದೀರ್ಘಪತ್ರೆ',
'ದೀರ್ಘಪೃಷ್ಠ',
'ದೀರ್ಘಪ್ರಜ್ಞ',
'ದೀರ್ಘವೃತ್ತ',
'ದೀರ್ಘಸೂತ್ರ',
'ದೀರ್ಘಸೂತ್ರಿ',
'ದೀರ್ಘಸ್ವರ',
'ದೀರ್ಘಾಕ್ಷರ',
'ದೀರ್ಘಾಯುಷಿ',
'ದೀರ್ಘಾಯುಷ್ಯ',
'ದೀರ್ಘಾಯುಸ್ಸು',
'ದೀರ್ಘಾವಧಿ',
'ದೀವಗಾಱ',
'ದೀವಟಿಗ',
'ದೀವಟಿಗೆ',
'ದೀವಟ್ಟಿಗ',
'ದೀವರಿಗೆ',
'ದೀವಸಿಗ',
'ದೀವಳಿಗೆ',
'ದೀವಾಳಿಗೆ',
'ದೀಹಳಿಗೆ',
'ದುಂಡರಿಸು',
'ದುಂಡಾವರ್ತಿ',
'ದುಂಡುಕರ',
'ದುಂಡುಕಳ್ಳಿ',
'ದುಂಡುಗಳ್ಳಿ',
'ದುಂಡುರುಪೆ',
'ದುಂಡುಱುಂಬೆ',
'ದುಂದಕಾರ',
'ದುಂದುಗಾರ',
'ದುಂದುಗಾರ್ತಿ',
'ದುಂದುತನ',
'ದುಂದುಬೀಳು',
'ದುಂದುಮಿಸು',
'ದುಂದುರುಪೆ',
'ದುಂದುವೆಚ್ಚ',
'ದುಂದುಱುಂಬೆ',
'ದುಂಪದೂಳಿ',
'ದುಂಪರಾಸ್ಮೆ',
'ದುಂಪಾದೂಳಿ',
'ದುಂಬಾದುಂಬಿ',
'ದುಃಕಲಹ',
'ದುಃಖಂಗೆಯ್',
'ದುಃಖಂಬಡು',
'ದುಃಖಕರ',
'ದುಃಖಗೊಳ್',
'ದುಃಖಗ್ರಸ್ತ',
'ದುಃಖಗ್ರಸ್ತೆ',
'ದುಃಖಪಡು',
'ದುಃಖವಾದ',
'ದುಃಖಾನ್ವಿತ',
'ದುಃಖಾನ್ವಿತೆ',
'ದುಃಪ್ರತೀತಿ',
'ದುಕ್ಕಡಿಸು',
'ದುಕ್ಕಪಡು',
'ದುಕ್ಕುಳಿಸು',
'ದುಖಾಪತು',
'ದುಗವಾತು',
'ದುಗಿದುಗಿ',
'ದುಗುಡಿಸು',
'ದುಗುಣಿಸು',
'ದುಗುದುಗಿ',
'ದುಗ್ಗಣಿಸು',
'ದುಗ್ಧಕಂಠ',
'ದುಗ್ಧಕಣ',
'ದುಗ್ಧಗಂತಿ',
'ದುಗ್ಧಗ್ರಂಥಿ',
'ದುಗ್ಧತರು',
'ದುಗ್ಧನಾಳ',
'ದುಗ್ಧಪಥ',
'ದುಗ್ಧಫೇನ',
'ದುಗ್ಧಫೇನಿ',
'ದುಟ್ಟತನ',
'ದುಡಿಮೆಟ್ಟು',
'ದುಡಿಸೀರೆ',
'ದುಡುಂಬಿಱಿ',
'ದುಡುಗುಟ್ಟು',
'ದುಡುದುಡು',
'ದುಡುಮ್ಮನೆ',
'ದುಡುಮ್ಮಿಱಿ',
'ದುಢುಂದಾಳಿ',
'ದುಢುಂಬಿಱಿ',
'ದುಢುಮಿಱಿ',
'ದುಢುಮ್ಮನೆ',
'ದುಣಕಲು',
'ದುತ್ತಂಡಿಕೆ',
'ದುಪುದುಪು',
'ದುಫಸಲ್',
'ದುಬ್ಧಿಗೊಳ್ಳು',
'ದುಬ್ಧಿಪಡು',
'ದುಬ್ಬೆಸನ',
'ದುಮಗುಟ್ಟು',
'ದುಮುಗುಟ್ಟು',
'ದುಮುದುಮು',
'ದುಮ್ಮಜಲು',
'ದುಮ್ಮರಾಸ್ಮೆ',
'ದುಮ್ಮಸಾಲೆ',
'ದುಮ್ಮಾನಿಸು',
'ದುಮ್ಮಿಸಲೆ',
'ದುಮ್ಮಿಸಾಲು',
'ದುಮ್ಮಿಸಾಲೆ',
'ದುಮ್ಮೆಸಗು',
'ದುರಂತರ',
'ದುರಕ್ಷರ',
'ದುರಗಲಿ',
'ದುರಗುಟ್ಟು',
'ದುರತ್ಯಯ',
'ದುರದುರ',
'ದುರದೃಷ್ಟ',
'ದುರದೃಷ್ಟೆ',
'ದುರಪದ',
'ದುರಭರ',
'ದುರಭ್ಯಾಸ',
'ದುರಭ್ಯಾಸಿ',
'ದುರವಸ್ಥೆ',
'ದುರವಾಪ',
'ದುರವಾರ್ತೆ',
'ದುರಾಕೃತ',
'ದುರಾಗತ',
'ದುರಾಗ್ರಹ',
'ದುರಾಗ್ರಹಿ',
'ದುರಾಚಾರ',
'ದುರಾಚಾರಿ',
'ದುರಾಚಾರೆ',
'ದುರಾತ್ಮಕ',
'ದುರಾತ್ಮಿಕೆ',
'ದುರಾದೃಷ್ಟ',
'ದುರಾದರ್ಷ',
'ದುರಾಪಾಸ್ತ',
'ದುರಾರಾಧ್ಯ',
'ದುರಾರಾಧ್ಯೆ',
'ದುರಾರೋಹ',
'ದುರಾಲಂಭ',
'ದುರಾಲಭ',
'ದುರಾಲಭೆ',
'ದುರಾಲಾಪ',
'ದುರಾಸದ',
'ದುರಿಯಶ',
'ದುರೀಷಣೆ',
'ದುರುಗುಟ್ಟು',
'ದುರುದುಂಡಿ',
'ದುರುದುಂಬಿ',
'ದುರುದುಂಬೆ',
'ದುರುದ್ದೇಶ',
'ದುರುಬುದ್ಧಿ',
'ದುರುಮತಿ',
'ದುರುಮದ',
'ದುರುಯಶ',
'ದುರುಹಿಕೆ',
'ದುರುಳತ್ವ',
'ದುರೂಹತೆ',
'ದುರೇಷಣೆ',
'ದುರೋದರ',
'ದುರೌಷಧ',
'ದುರ್ಗತಿಕ',
'ದುರ್ಗರತ',
'ದುರ್ಗಾಷ್ಟಮಿ',
'ದುರ್ಘಟನೆ',
'ದುರ್ಜನತೆ',
'ದುರ್ಜನಿಕ್ಕೆ',
'ದುರ್ಜನಿಸು',
'ದುರ್ಜ್ಞೇಯತೆ',
'ದುರ್ದಮತೆ',
'ದುರ್ದೇವತೆ',
'ದುರ್ಧರುಷ',
'ದುರ್ನಡತೆ',
'ದುರ್ನಾಮಕ',
'ದುರ್ನಾಲಗೆ',
'ದುರ್ನಾಲಿಗೆ',
'ದುರ್ನಿಮಿತ್ತ',
'ದುರ್ನೀರೀಕ್ಷ್ಯ',
'ದುರ್ನಿವಾರ',
'ದುರ್ನಿವಾರ್ಯ',
'ದುರ್ಬಲತೆ',
'ದುರ್ಬುತನ',
'ದುರ್ಬೆಸನಿ',
'ದುರ್ಬೋಧಕ',
'ದುರ್ಬೋಧನೆ',
'ದುರ್ಭಗತ್ವ',
'ದುರ್ಭಾವನೆ',
'ದುರ್ಭಾಷಣ',
'ದುರ್ಭೋಜನ',
'ದುರ್ಮನೀಷಿ',
'ದುರ್ಮರಣ',
'ದುರ್ಮರ್ಷಣ',
'ದುರ್ಮಲಿನ',
'ದುರ್ಮುಹೂರ್ತ',
'ದುರ್ಯಶಸ್ಸು',
'ದುರ್ಲಕ್ಷಣ',
'ದುರ್ಲಕ್ಷಿಸು',
'ದುರ್ಲಲಿತ',
'ದುರ್ಲಿಖಿತ',
'ದುರ್ವಚನ',
'ದುರ್ವರ್ತನೆ',
'ದುರ್ವಾಸನೆ',
'ದುರ್ವಿಕಾರ',
'ದುರ್ವಿದಗ್ಧ',
'ದುರ್ವಿದಗ್ಧೆ',
'ದುರ್ವಿನೀತ',
'ದುರ್ವಿನೀತೆ',
'ದುರ್ವಿಪತ್ತಿ',
'ದುರ್ವಿಪಾಕ',
'ದುರ್ವಿಮೋಹ',
'ದುರ್ವಿಷಯ',
'ದುರ್ವ್ಯವಸ್ಥೆ',
'ದುರ್ವ್ಯಸನ',
'ದುರ್ವ್ಯಸನಿ',
'ದುರ್ವ್ಯಸನೆ',
'ದುರ್ವ್ಯಾಪಾರ',
'ದುರ್ವ್ಯಾಪಾರಿ',
'ದುರ್ವ್ಯಾಮೋಹ',
'ದುವಂಗುಲ',
'ದುವಟ್ಟಿಗೆ',
'ದುವಾಡಿಸು',
'ದುವ್ವಳಿಸು',
'ದುವ್ವಾಳಿಗ',
'ದುವ್ವಾಳಿಸು',
'ದುಶ್ಚರಿತ',
'ದುಶ್ಚರಿತೆ',
'ದುಶ್ಚರಿತ್ರ',
'ದುಶ್ಚರಿತ್ರೆ',
'ದುಶ್ಚಾರಿತ್ರ',
'ದುಶ್ಚೇಷ್ಟಿತ',
'ದುಶ್ಚ್ವವನ',
'ದುಶ್ಶಕುನ',
'ದುಶ್ಶಠಣ',
'ದುಶ್ಶಾಸನ',
'ದುಶ್ಶ್ವಾಪದ',
'ದುಷ್ಕಂಟಕ',
'ದುಷ್ಕವಿತೆ',
'ದುಷ್ಕೀರಿತಿ',
'ದುಷ್ಕುಲೀನ',
'ದುಷ್ಟತನ',
'ದುಷ್ಟಪಾಠ',
'ದುಷ್ಟಾತ್ಮಕ',
'ದುಷ್ಪ್ರತೀತಿ',
'ದುಷ್ಪ್ರಭಾವ',
'ದುಷ್ಪ್ರೇರಣೆ',
'ದುಸುಮಾನ',
'ದುಸುಮುಸಿ',
'ದುಸುಮುಸು',
'ದುಸ್ತರತೆ',
'ದುಸ್ತಾಮಸ',
'ದುಸ್ವರಗೈ',
'ದುಸ್ಸಂತತಿ',
'ದುಸ್ಸಂಧಾನ',
'ದುಸ್ಸರಣಿ',
'ದುಸ್ಸಹಸ',
'ದುಸ್ಸಾಹಸ',
'ದುಸ್ಸಾಹಸಿ',
'ದುಹಿತಾರ',
'ದುಱಿದುಂಬಿ',
'ದುಱುದುಂಡಿ',
'ದುಱುದುಂಬ',
'ದುಱುದುಂಬಿ',
'ದೂಟಾಡಿಸು',
'ದೂಡುಗಾಡಿ',
'ದೂತಕಾರ್ಯ',
'ದೂತತನ',
'ದೂತಾವಾಸ',
'ದೂದಪೇಡಾ',
'ದೂದಫೇಣಿ',
'ದೂದುಗಾರ',
'ದೂದುಗಾಱ',
'ದೂಪಾರತಿ',
'ದೂಮುಧಾಮು',
'ದೂರಂಬೋಗು',
'ದೂರಂಮಾಡು',
'ದೂರಗತ',
'ದೂರಗಾಮಿ',
'ದೂರಗೆಯ್ಯು',
'ದೂರದರ್ಶಿ',
'ದೂರದೃಷ್ಟಿ',
'ದೂರಪಾತಂ',
'ದೂರಪೋಗು',
'ದೂರಪ್ರತಿ',
'ದೂರಪ್ರಾಚ್ಯ',
'ದೂರಮಾಡು',
'ದೂರಲೇಖ',
'ದೂರವಂದೆ',
'ದೂರವಾಣಿ',
'ದೂರವುಳಿ',
'ದೂರಹೋಗು',
'ದೂರಾನ್ವಯ',
'ದೂರಾನ್ವಿತ',
'ದೂರಾರಿಷ್ಟ',
'ದೂರೀಕೃತ',
'ದೂರೀಭೂತ',
'ದೂವಟ್ಟಿಗೆ',
'ದೂವಡಿಗೆ',
'ದೂವಾಳಿಸು',
'ದೂವೆಕಿಚ್ಚು',
'ದೂವೆಗಿಚ್ಚು',
'ದೂವೆಗಿರ್ಚು',
'ದೂವೆಯಿಡು',
'ದೂಷಣಿಕ',
'ದೂಷಣೀಯ',
'ದೂಸರಿಗ',
'ದೂಸೆಯಕ್ಕಿ',
'ದೂಹೆಗಾಡು',
'ದೂಳಕ್ಕರ',
'ದೂಳಕ್ಷರ',
'ದೂಳಿಗೋಂಟೆ',
'ದೂಳಿಗೋಟೆ',
'ದೂಳುವಡೆ',
'ದೂಳ್ವಡಿಸು',
'ದೂಱುಕಾಱ',
'ದೂಱುಗಾಱ',
'ದೃಕ್ಕುಗೊಂಬೆ',
'ದೃಕ್ಕುಬೊಂಬೆ',
'ದೃಕ್ಪರಿಧಿ',
'ದೃಕ್ಸಿದ್ಧಾಂತ',
'ದೃಗಂತರ',
'ದೃಗುಜಲ',
'ದೃಗುಪಥ',
'ದೃಗುಮಧ್ಯ',
'ದೃಗುಶಿಖಿ',
'ದೃಗುಸನ್ನೆ',
'ದೃಗ್ಗಣಿತ',
'ದೃಗ್ಗೋಚರ',
'ದೃಢಕಾಯ',
'ದೃಢಗೊಳ್ಳು',
'ದೃಢಚರ್ಯ',
'ದೃಢಚಿತ್ತ',
'ದೃಢಚಿತ್ತೆ',
'ದೃಢಪಡು',
'ದೃಢಬ್ರತ',
'ದೃಢಬ್ರತೆ',
'ದೃಢಮೂಲ',
'ದೃಢವಾಕ್ಯ',
'ದೃಢವಿಡಿ',
'ದೃಢವ್ರತ',
'ದೃಢವ್ರತೆ',
'ದೃಢಸಂಧಿ',
'ದೃಢೀಕೃತ',
'ದೃಶ್ಯಕಾವ್ಯ',
'ದೃಷ್ಟಿಕೋಣ',
'ದೃಷ್ಟಿಕೋನ',
'ದೃಷ್ಟಿಗ್ರಾಹ್ಯ',
'ದೃಷ್ಟಿದಾನ',
'ದೃಷ್ಟಿದೋಷ',
'ದೃಷ್ಟಿಪಥ',
'ದೃಷ್ಟಿಪಾತ',
'ದೃಷ್ಟಿಪುಷ್ಪ',
'ದೃಷ್ಟಿಭೇದ',
'ದೃಷಿಮಾಂದ್ಯ',
'ದೃಷ್ಟಿಯುದ್ಧ',
'ದೃಷ್ಟಿರೋಷ',
'ದೃಷ್ಟಿವಾಳ',
'ದೃಷ್ಟಿವಿಷ',
'ದೃಷ್ಟಿಶಾಸ್ತ್ರ',
'ದೃಷ್ಟಿಶೂನ್ಯ',
'ದೃಷ್ಟಿಶೂನ್ಯೆ',
'ದೃಷ್ಟಿಹೀನ',
'ದೃಷ್ಟಿಹೀನೆ',
'ದೆಖ್ಖಾದೆಖ್ಖಿ',
'ದೆಖ್ಖಾಳಿಸು',
'ದೆಗ್ಗೆರಳೆ',
'ದೆಯ್ವಬಲ',
'ದೆವಸಕ್ಕಂ',
'ದೆವ್ವಂಗುಣಿ',
'ದೆವ್ವತುಂಬೆ',
'ದೆವ್ವದುಂಬೆ',
'ದೆವ್ವಬಾಳೆ',
'ದೆವ್ವಹತ್ತಿ',
'ದೆಶೆಗೇಡಿ',
'ದೆಶೆಯಾನೆ',
'ದೆಸೆಗಟ್ಟು',
'ದೆಸೆಗಡೆ',
'ದೆಸೆಗಾಣ್',
'ದೆಸೆಗಾರ',
'ದೆಸೆಗಿಡು',
'ದೆಸೆಗೆಡು',
'ದೆಸೆಗೇಡಿ',
'ದೆಸೆದಪ್ಪು',
'ದೆಸೆದಿರಿ',
'ದೆಸೆಬಿದ್ದಂ',
'ದೆಸೆಯಾನೆ',
'ದೆಸೆವಂತ',
'ದೆಸೆವಡೆ',
'ದೆಸೆವರಿ',
'ದೆಸೆವಲಿ',
'ದೆಸೆವಳ',
'ದೆಸೆವಾನ',
'ದೆಸೆವಿದ್ದಂ',
'ದೆಸೆಹೊದ್ದು',
'ದೇಖರಖಿ',
'ದೇಖರೇಖಿ',
'ದೇಖಾವಳಿ',
'ದೇಗುಲಿಗ',
'ದೇಡಸೀರೆ',
'ದೇಣಾಲೇಣ',
'ದೇಣೇದಾರ',
'ದೇವಂಗೆಯ್',
'ದೇವಕನ್ಯೆ',
'ದೇವಕಾರ್ಯ',
'ದೇವಕುಜ',
'ದೇವಕುಲ',
'ದೇವಕುಳ',
'ದೇವಕೂಟ',
'ದೇವಕೃಚ್ಛ್ರ',
'ದೇವಕೃತ',
'ದೇವಕ್ರಿಯೆ',
'ದೇವಖಾತ',
'ದೇವಗಂಟೆ',
'ದೇವಗಣ',
'ದೇವಗತಿ',
'ದೇವಗುಪ್ತ',
'ದೇವಗುರು',
'ದೇವಗೃಹ',
'ದೇವಗೇಹ',
'ದೇವಗ್ರಹ',
'ದೇವಚಾಪ',
'ದೇವಚ್ಛಂದ',
'ದೇವಜಾತಿ',
'ದೇವಜ್ಯೇಷ್ಠ',
'ದೇವಡಿಗ',
'ದೇವಡಿತಿ',
'ದೇವತನ',
'ದೇವತರು',
'ದೇವತಾಡ',
'ದೇವತಾಣ',
'ದೇವತಾಲ',
'ದೇವತಾಳ',
'ದೇವತ್ರಯ',
'ದೇವದಂತಿ',
'ದೇವದತ್ತ',
'ದೇವದತ್ತಿ',
'ದೇವದಾನ',
'ದೇವದಾಯ',
'ದೇವದಾರಿ',
'ದೇವದಾರು',
'ದೇವದಾಸಿ',
'ದೇವದಾಳಿ',
'ದೇವದುಂಬೆ',
'ದೇವದೂತ',
'ದೇವದೇಯ',
'ದೇವದೇವ',
'ದೇವಧೂಪ',
'ದೇವನಗ',
'ದೇವನದಿ',
'ದೇವನಾಲ',
'ದೇವನಾಳ',
'ದೇವಪತಿ',
'ದೇವಪತ್ರ',
'ದೇವಪಥ',
'ದೇವಪುರ',
'ದೇವಪೂಜೆ',
'ದೇವಬಾಳೆ',
'ದೇವಭಕ್ತ',
'ದೇವಭಕ್ತಿ',
'ದೇವಭಕ್ತೆ',
'ದೇವಭಾಗ',
'ದೇವಭಾಷೆ',
'ದೇವಭೂಯ',
'ದೇವಭೋಗ',
'ದೇವಭೋಳೆ',
'ದೇವಮಣಿ',
'ದೇವಮನ್ಯ',
'ದೇವಮಾತೆ',
'ದೇವಮಾನ್ಯ',
'ದೇವಮಾರ್ಗ',
'ದೇವಮುಖ',
'ದೇವಮುನಿ',
'ದೇವಮುಳ್ಳು',
'ದೇವಯಜ್ಞ',
'ದೇವಯಾನ',
'ದೇವಯುಗ',
'ದೇವಯೋನಿ',
'ದೇವರಂಜಿ',
'ದೇವರಮ್ಯ',
'ದೇವರಾಜ',
'ದೇವರಾಯಿ',
'ದೇವರುಷಿ',
'ದೇವಲಕ',
'ದೇವಲಭ',
'ದೇವಲಾಲಿ',
'ದೇವಲಿಪಿ',
'ದೇವಲೋಕ',
'ದೇವವಂದ್ಯ',
'ದೇವವಧು',
'ದೇವವಸ್ತ್ರ',
'ದೇವವಾಣಿ',
'ದೇವವ್ರತ',
'ದೇವಸಾಳಿ',
'ದೇವಸುಷಿ',
'ದೇವಸ್ಥಾನ',
'ದೇವಸ್ನಾನ',
'ದೇವಾಂಗನೆ',
'ದೇವಾಗಾರ',
'ದೇವಾಜೀವ',
'ದೇವಾದಾಯ',
'ದೇವಾಲಯ',
'ದೇವಾಲೆಯ',
'ದೇವಿತನ',
'ದೇವಿಹಾಕು',
'ದೇವುಳಿಗ',
'ದೇವೆಡಿತ್ತಿ',
'ದೇವೇಂದ್ರತ್ವ',
'ದೇವೋನ್ಮಾದ',
'ದೇಶಗತಿ',
'ದೇಶಗಾಹು',
'ದೇಶಗೌಳ',
'ದೇಶತ್ಯಾಗ',
'ದೇಶದ್ರೋಹ',
'ದೇಶದ್ರೋಹಿ',
'ದೇಶಧರ್ಮ',
'ದೇಶನಾಡು',
'ದೇಶಪಾಂಡೆ',
'ದೇಶಪ್ರೇಮ',
'ದೇಶಬಂಧ',
'ದೇಶಭಕ್ತ',
'ದೇಶಭಕ್ತಿ',
'ದೇಶಭಕ್ತೆ',
'ದೇಶಭಾಷೆ',
'ದೇಶಭ್ರಷ್ಟ',
'ದೇಶಭ್ರಷ್ಟೆ',
'ದೇಶಮುಕಿ',
'ದೇಶಮುಖ',
'ದೇಶರೂಪ',
'ದೇಶವ್ಯಾಪ್ತಿ',
'ದೇಶವ್ರತ',
'ದೇಶವ್ರತಿ',
'ದೇಶಶಾಖೆ',
'ದೇಶಸಾತ್ಮ್ಯ',
'ದೇಶಸೇವೆ',
'ದೇಶಾಂತರ',
'ದೇಶಾಂತರಿ',
'ದೇಶಾಚಾರ',
'ದೇಶಾಟಣ',
'ದೇಶಾಟನ',
'ದೇಶಾಟನೆ',
'ದೇಶಾಯಿಕೆ',
'ದೇಶಾವರ',
'ದೇಶಾವರಿ',
'ದೇಶಾವಾರ',
'ದೇಶಿಕಾತಿ',
'ದೇಶಿಗಿತಿ',
'ದೇಶಿಗಿತ್ತಿ',
'ದೇಶಿಪರಿ',
'ದೇಶೀತೋಡಿ',
'ದೇಶೋದ್ಧಾರ',
'ದೇಶ್ಯಕಾಪಿ',
'ದೇಶ್ಯಗೌಳ',
'ದೇಶ್ಯತೋಡಿ',
'ದೇಸಗತಿ',
'ದೇಸಗತ್ತಿ',
'ದೇಸಾಯಣಿ',
'ದೇಸಾಯಿಕೆ',
'ದೇಸಾಯಿತಿ',
'ದೇಸಿಕಾತಿ',
'ದೇಸಿಕಾರ',
'ದೇಸಿಕಾರ್ತಿ',
'ದೇಸಿಕಾಱ',
'ದೇಸಿಗಾತಿ',
'ದೇಸಿಗಾರ್ತಿ',
'ದೇಸಿಗಾಱ',
'ದೇಸಿಗಿತಿ',
'ದೇಸಿಪರಿ',
'ದೇಸಿವಡೆ',
'ದೇಸಿವೆಱು',
'ದೇಸಿಸೆಟ್ಟಿ',
'ದೇಸೆಕಾರ್ತಿ',
'ದೇಸೆಗಾರ್ತಿ',
'ದೇಸೆಗಾಱ',
'ದೇಸೆಯಱಿ',
'ದೇಸೆವಡೆ',
'ದೇಸೆವೆಱು',
'ದೇಹಗುಣ',
'ದೇಹಝಾಡೆ',
'ದೇಹತ್ಯಾಗ',
'ದೇಹತ್ರಯ',
'ದೇಹದಾಢ್ರ್ಯ',
'ದೇಹಧರ್ಮ',
'ದೇಹಧಾರಿ',
'ದೇಹನ್ಯಾಸ',
'ದೇಹಪುಷ್ಟಿ',
'ದೇಹಬಾಧೆ',
'ದೇಹಬಿಡು',
'ದೇಹಭಾನ',
'ದೇಹಭಾವ',
'ದೇಹಮಬ್ಬು',
'ದೇಹಮುಕ್ತ',
'ದೇಹಮೋಹ',
'ದೇಹಯಾತ್ರೆ',
'ದೇಹಲೇಪ',
'ದೇಹವಿಡಿ',
'ದೇಹವಿಡು',
'ದೇಹಶುದ್ಧಿ',
'ದೇಹಶೇಷ',
'ದೇಹಸಾತ್ಮ್ಯ',
'ದೇಹಸಾಮ್ಯ',
'ದೇಹಸುಖ',
'ದೇಹಸ್ಥಿತಿ',
'ದೇಹಾಂತರ',
'ದೇಹಾತುರ',
'ದೇಹಾಯಾಸ',
'ದೇಹಾರೈಕೆ',
'ದೇಹಾಲಸ್ಯ',
'ದೇಹೋತ್ಥಾನ',
'ದೇಹೋದ್ಭವ',
'ದೈತ್ಯಗುರು',
'ದೈನಂದಿನ',
'ದೈನ್ಯಂಬಡು',
'ದೈನ್ಯಭಾವ',
'ದೈನ್ಯವಡು',
'ದೈನ್ಯವೃತ್ತಿ',
'ದೈವಕಾರ್ತಿ',
'ದೈವಕಾಱ',
'ದೈವಕೃತ',
'ದೈವಕೃತಿ',
'ದೈವಗತಿ',
'ದೈವಗೇಡಿ',
'ದೈವದತ್ತ',
'ದೈವದೂರ',
'ದೈವದ್ರೋಹ',
'ದೈವದ್ರೋಹಿ',
'ದೈವಪರ',
'ದೈವಬಲ',
'ದೈವಬಳ',
'ದೈವಭಕ್ತಿ',
'ದೈವಮಾಯೆ',
'ದೈವಯಂತ್ರ',
'ದೈವಯಜ್ಞ',
'ದೈವಯೋಗ',
'ದೈವರೇಷೆ',
'ದೈವವಂತ',
'ದೈವವಶ',
'ದೈವವಾಣಿ',
'ದೈವವಾದ',
'ದೈವವಿದ',
'ದೈವವಿದೆ',
'ದೈವಶಾಲಿ',
'ದೈವಸೂತ್ರ',
'ದೈವಸೃಷ್ಟಿ',
'ದೈವಹೀನ',
'ದೈವಹೀನೆ',
'ದೈವಹೊಡೆ',
'ದೈವಾಧೀನ',
'ದೈವಾಪರ',
'ದೈವಾಪರೆ',
'ದೈವಾಯತ',
'ದೈವಾಯತ್ತ',
'ದೈವಿಕತೆ',
'ದೈವೀಕೃಪೆ',
'ದೈವೀಶಕ್ತಿ',
'ದೈವೀಸ್ಫೂರ್ತಿ',
'ದೈವೋದಯ',
'ದೊಂದಿಮೀಸೆ',
'ದೊಂದುಳಿಸು',
'ದೊಂಬಿಗಾರ',
'ದೊಂಬಿಗೂಡು',
'ದೊಂಬಿದಾಸ',
'ದೊಂಬಿಯುದ್ಧ',
'ದೊಂಬಿಸಾಕ್ಷಿ',
'ದೊಕ್ಕರಿಸು',
'ದೊಗ್ಗಾಲಿಕ್ಕು',
'ದೊಗ್ಗಾಲೂರು',
'ದೊಠಾರಿಸು',
'ದೊಡ್ಡಕಾರೆ',
'ದೊಡ್ಡಕುಶೆ',
'ದೊಡ್ಡಕೂವೆ',
'ದೊಡ್ಡಕೊಟ್ಟೆ',
'ದೊಡ್ಡಕ್ಷರ',
'ದೊಡ್ಡಗಟ್ಟು',
'ದೊಡ್ಡಗೂಗಿ',
'ದೊಡ್ಡಗೂಗೆ',
'ದೊಡ್ಡಗೂಬೆ',
'ದೊಡ್ಡಗೋಣಿ',
'ದೊಡ್ಡಚಂಚು',
'ದೊಡ್ಡಚಳ್ಳೆ',
'ದೊಡ್ಡಚಿಲ್ಲ',
'ದೊಡ್ಡಚುಂಚು',
'ದೊಡ್ಡಜಡ',
'ದೊಡ್ಡಜೀವ',
'ದೊಡ್ಡಜೋಳ',
'ದೊಡ್ಡಜ್ವರ',
'ದೊಡ್ಡತಂದೆ',
'ದೊಡ್ಡತನ',
'ದೊಡ್ಡತಾಯಿ',
'ದೊಡ್ಡತುಂಬೆ',
'ದೊಡ್ಡತೊಪ್ಪೆ',
'ದೊಡ್ಡದನ',
'ದೊಡ್ಡಧರ್ಭೆ',
'ದೊಡ್ಡನಾಯಿ',
'ದೊಡ್ಡನಿಂಬೆ',
'ದೊಡ್ಡನೀಲಿ',
'ದೊಡ್ಡನೂಲು',
'ದೊಡ್ಡನೆಲ್ಲಿ',
'ದೊಡ್ಡಪತ್ರೆ',
'ದೊಡ್ಡಬಂಗು',
'ದೊಡ್ಡಬಾಗೆ',
'ದೊಡ್ಡಬುಡ್ಡೆ',
'ದೊಡ್ಡಬೆಂಡೆ',
'ದೊಡ್ಡಬೆತ್ತ',
'ದೊಡ್ಡಬೇನೆ',
'ದೊಡ್ಡಬೇವು',
'ದೊಡ್ಡಮದ್ದು',
'ದೊಡ್ಡಮನೆ',
'ದೊಡ್ಡಮಬ್ಬು',
'ದೊಡ್ಡಮರ',
'ದೊಡ್ಡಮಾತು',
'ದೊಡ್ಡಮಾರ್ಗ',
'ದೊಡ್ಡಮಾವು',
'ದೊಡ್ಡಮೆಕ್ಕೆ',
'ದೊಡ್ಡಮೇದೆ',
'ದೊಡ್ಡರಾಗಿ',
'ದೊಡ್ಡರೋಗ',
'ದೊಡ್ಡಲಕ್ಕಿ',
'ದೊಡ್ಡವೂಸಿ',
'ದೊಡ್ಡಶರ',
'ದೊಡ್ಡಸಾಮೆ',
'ದೊಡ್ಡಸುದ್ದಿ',
'ದೊಡ್ಡಸೊಳ್ತಿ',
'ದೊಡ್ಡಸೋಂಪು',
'ದೊಡ್ಡಸೋರೆ',
'ದೊಡ್ಡಸ್ತನ',
'ದೊಡ್ಡಸ್ತಿಕೆ',
'ದೊಡ್ಡಹತ್ತಿ',
'ದೊಡ್ಡಹಲ್ಲಿ',
'ದೊಡ್ಡಹೀರೆ',
'ದೊಡ್ಡಹುಳಿ',
'ದೊಡ್ಡಹೊಂಗೆ',
'ದೊಡ್ಡಹೊಟ್ಟೆ',
'ದೊಡ್ಡಹೊನ್ನೆ',
'ದೊಡ್ಡಿಗೂಡು',
'ದೊಡ್ಡಿಗೊಳ್',
'ದೊಡ್ಡಿತನ',
'ದೊಡ್ಡಿದಾರ',
'ದೊಡ್ಡಿಪತ್ರೆ',
'ದೊಡ್ಡಿಮರ',
'ದೊಡ್ಡಿಸ್ತನ',
'ದೊಡ್ಡೀಚಲು',
'ದೊಡ್ಡೆಲಚಿ',
'ದೊಡ್ಡೇಲಕ್ಕಿ',
'ದೊಡ್ಡೊಕ್ಕಲು',
'ದೊಣೆವೋಗು',
'ದೊಣ್ಣೆಕಾಟ',
'ದೊಣ್ಣೆಮುಳ್ಳು',
'ದೊಣ್ಣೆಮೂಗು',
'ದೊದ್ದೆಕಾಱ',
'ದೊದ್ದೆವೋಗು',
'ದೊನ್ನೆಕಿವಿ',
'ದೊಪ್ಪೆಗಿವಿ',
'ದೊಮ್ಮಳಿಸು',
'ದೊಮ್ಮೇಜಾಡ್ಯ',
'ದೊರಕಿಸು',
'ದೊರೆಕೊಳ್',
'ದೊರೆಕೊಳ್ಳು',
'ದೊರೆಗಾಣ್',
'ದೊರೆಗಿಡು',
'ದೊರೆಗೆಡು',
'ದೊರೆಗೊಳ್',
'ದೊರೆತನ',
'ದೊರೆತಾಯಿ',
'ದೊರೆಮಗ',
'ದೊರೆಮೋಡಿ',
'ದೊರೆಯಿಸು',
'ದೊರೆವಕ್ಕಿ',
'ದೊರೆವಟ್ಟೆ',
'ದೊರೆವಡು',
'ದೊರೆವಡೆ',
'ದೊರೆವರ್',
'ದೊರೆವಾಡು',
'ದೊರೆವೆಣ್ಣು',
'ದೊರೆವೆಱು',
'ದೊರೆಸಾನಿ',
'ದೊರೆಹುಲ್ಲು',
'ದೊಲಗೊಂಡಿ',
'ದೊಲ್ಲಯಿಸು',
'ದೊಳ್ಳೆಗಣ್ಣ',
'ದೊಳ್ಳೆತನ',
'ದೋಂಟಿಗೋಲು',
'ದೋಂಟಿವಲೆ',
'ದೊಂಟೆಕಾಲು',
'ದೋಟಿಕೋಲು',
'ದೋಟಿಗೋಲು',
'ದೋಟಿಯಿಕ್ಕು',
'ದೋಟಿವಲೆ',
'ದೋಣಿಕೊಂಡ',
'ದೋಣಿಗಾರ',
'ದೋಣಿಗೊಂಡ',
'ದೋಣಿದಳ',
'ದೋಣಿದಾಟು',
'ದೋಣಿಯೋಗು',
'ದೋಣಿವಲೆ',
'ದೋಣಿವೀೞ್',
'ದೋಣಿವೋಗು',
'ದೋಣುಹೊಟ್ಟೆ',
'ದೋಬಿಖಾನೆ',
'ದೋಬಿಘಾಟ್',
'ದೋಬೀಪಾಟ್',
'ದೋಮದೆರೆ',
'ದೋಮೆತೆರೆ',
'ದೋರಗಾಯಿ',
'ದೋರಪಾಲ',
'ದೋರಹಣ್ಣು',
'ದೋರೆಕಾಯಿ',
'ದೋರೆಗಾಯ್',
'ದೋರೆಗಾಯಿ',
'ದೋರೆಪಣ್',
'ದೋರೆವಣ್',
'ದೋರೆಹಣ್ಣು',
'ದೋರ್ವಳಯ',
'ದೋರ್ವೀಕ್ರಾಂತ',
'ದೋಲಯಿಸು',
'ದೋಲಾಯಂತ್ರ',
'ದೋಲೋತ್ಸವ',
'ದೋಷಂಗೆಯ್',
'ದೋಷಕರ',
'ದೋಷಕಾರಿ',
'ದೋಷಗ್ರಾಹಿ',
'ದೋಷಜ್ವರ',
'ದೋಷತಟ್ಟು',
'ದೋಷತ್ರಯ',
'ದೋಷದೂರ',
'ದೋಷದೂರೆ',
'ದೋಷದೃಷ್ಟಿ',
'ದೋಷಮುಕ್ತ',
'ದೋಷಮುಕ್ತೆ',
'ದೋಷಯುಕ್ತ',
'ದೋಷವಿಡು',
'ದೋಷಹಚ್ಚು',
'ದೋಷಹೊರು',
'ದೋಷಾಕರ',
'ದೋಷಾಕರೆ',
'ದೋಷಾಚರ',
'ದೋಷಾಪತಿ',
'ದೋಷಾಭಾಸ',
'ದೋಷಾರೋಪ',
'ದೋಷಾವೇಶ',
'ದೋಷಾಶ್ರಯ',
'ದೋಸವಂತ',
'ದೋಸ್ತದಾರ',
'ದೋಸ್ತಿಗಾರ',
'ದೋಸ್ತುದಾರ',
'ದೋಸ್ಸಾಹಸ',
'ದೋಹಂಬಡೆ',
'ದೋಹಳಗೈ',
'ದೋಱುವೀೞ್',
'ದೋಱುವೋಗು',
'ದೌಡಾಯಿಸು',
'ದೌತಿಮಣೆ',
'ದೌಮಾಷಾಯ',
'ದೌರಿತಕ',
'ದೌರ್ಮನಸ್ಯ',
'ದೌಲಾಚ್ಛಾದ',
'ದೌವಾರಿಕ',
'ದೌವಾರಿಕೆ',
'ದ್ಯುತಿಗರೆ',
'ದ್ಯುತಿಗುಂದು',
'ದ್ಯುತಿಮಾಲಿ',
'ದ್ಯುತಿವೀಱು',
'ದ್ಯುನಗೃಹ',
'ದ್ಯೂತಕರ',
'ದ್ಯೂತಕಾರ',
'ದ್ಯೂತಕೃತ್ತು',
'ದ್ಯೂತಕೇಳಿ',
'ದ್ಯೂತವರ್ತಿ',
'ದ್ಯೂನಗೇಹ',
'ದ್ರವಗಲ್ಲು',
'ದ್ರವಗಾಳಿ',
'ದ್ರವತ್ರಪು',
'ದ್ರವರೂಪ',
'ದ್ರವವಸ್ತು',
'ದ್ರವವಸ್ತ್ರ',
'ದ್ರವವಾಯು',
'ದ್ರವಾಶಯ',
'ದ್ರವಿಣೇಂದ್ರ',
'ದ್ರವಿಯಿಸು',
'ದ್ರವೀಕೃತ',
'ದ್ರವ್ಯತಪ',
'ದ್ರವ್ಯಭ್ರಮೆ',
'ದ್ರವ್ಯಮೋಕ್ಷ',
'ದ್ರವ್ಯಯಜ್ಞ',
'ದ್ರವ್ಯಲಿಂಗಿ',
'ದ್ರವ್ಯವಂತ',
'ದ್ರವ್ಯಶಕ್ತಿ',
'ದ್ರವ್ಯಹೀನ',
'ದ್ರವ್ಯಹೀನೆ',
'ದ್ರವ್ಯಾಂಜನ',
'ದ್ರವ್ಯಾದ್ವೈತ',
'ದ್ರವ್ಯಾಯಿಲ',
'ದ್ರವ್ಯೇಂದ್ರಿಯ',
'ದ್ರಹಿಭೇದ',
'ದ್ರಾಕ್ಷಾಪಾಕ',
'ದ್ರಾಕ್ಷಾರಸ',
'ದ್ರಾಕ್ಷಾರಿಷ್ಟ',
'ದ್ರಾಕ್ಷಾವಲ್ಲಿ',
'ದ್ರಾಕ್ಷಾಸವ',
'ದ್ರಾಕ್ಷಿಚೂರ್ಣ',
'ದ್ರಾವಣೀಯ',
'ದ್ರಾವಿಡಕ',
'ದ್ರುಕಿಲಮ',
'ದ್ರುತಪದ',
'ದ್ರುತವಿಡು',
'ದ್ರುಮೋತ್ಪಲ',
'ದ್ರುಹಿಣಾಂಡ',
'ದ್ರೋಣಕಾಕ',
'ದ್ರೋಣಮುಖ',
'ದ್ರೋಣಾಮುಖ',
'ದ್ರೋಹಿತನ',
'ದ್ವಂದ್ವಕರ್ಮ',
'ದ್ವಂದ್ವಜ್ವರ',
'ದ್ವಂದ್ವಮೇಹ',
'ದ್ವಂದ್ವಯುದ್ಧ',
'ದ್ವಂದ್ವಯೋಗ',
'ದ್ವಂದ್ವರೋಗ',
'ದ್ವಂದ್ವಾರ್ಥತೆ',
'ದ್ವಯಭಾವ',
'ದ್ವಯವಿಡಿ',
'ದ್ವಯಾತಿಗ',
'ದ್ವಾತ್ರಿಂಶತಿ',
'ದ್ವಾತ್ರಿಂಶತ್ತು',
'ದ್ವಾರಗೊಲ್ಲ',
'ದ್ವಾರಪಟ್ಟ',
'ದ್ವಾರಪಾಲ',
'ದ್ವಾರಬಂಧ',
'ದ್ವಾರವಟ್ಟ',
'ದ್ವಾರಾಪೇಕ್ಷೆ',
'ದ್ವಾವಿಂಶತಿ',
'ದ್ವಿಕರ್ಮಕ',
'ದ್ವಿಗುಣಿತ',
'ದ್ವಿಗುಣಿಸು',
'ದ್ವಿಚರಮ',
'ದ್ವಿಚಾರಿಣಿ',
'ದ್ವಿಜವಂತಿ',
'ದ್ವಿಜಹತಿ',
'ದ್ವಿಜಾವಂತಿ',
'ದ್ವಿತ್ವವೃತ್ತಿ',
'ದ್ವಿತ್ವಾಕ್ಷರ',
'ದ್ವಿಧಾಕೃತ',
'ದ್ವಿಧಾಗತಿ',
'ದ್ವಿಧಾಭಾವ',
'ದ್ವಿಧಾವೃತ್ತಿ',
'ದ್ವಿಪಕ್ಷೀಯ',
'ದ್ವಿಭಾಗಿಸು',
'ದ್ವಿಭಾಷಿತ್ವ',
'ದ್ವಿರಸನ',
'ದ್ವಿರಾಷ್ಟ್ರೀಯ',
'ದ್ವಿವಚನ',
'ದ್ವಿವ್ಯಕ್ತಿತ್ವ',
'ದ್ವಿಶಿಖರ',
'ದ್ವೀಪಕಲ್ಪ',
'ದ್ವೀಪಖಂಡ',
'ದ್ವೀಪದ್ರಾಕ್ಷಿ',
'ದ್ವೀಪದ್ರಾಕ್ಷೆ',
'ದ್ವೀಪವತಿ',
'ದ್ವೈಗುಣಿಕ',
'ದ್ವೈತಭಕ್ತಿ',
'ದ್ವೈತಮತ',
'ದ್ವೈತಮಾರ್ಗ',
'ದ್ವೈತವಾದ',
'ದ್ವೈತಾದ್ವೈತ',
'ದ್ವೈತೀಯಕ',
'ದ್ವೈಧೀಭಾವ',
'ದ್ವೈಪಾಯನ',
'ದ್ವೈಮಾತುರ',
'ದ್ವೈಹಾಯನ',
'ದ್ವ್ಯಧಿಕತೆ',
'ಧಕಲ್ಪಟ್ಟಿ',
'ಧಕ್ಕಡಿಗ',
'ಧಕ್ಕಡೆಯ',
'ಧಕ್ಕಾಧಿಂಗಿ',
'ಧಕ್ಕಾಮುಕ್ಕಿ',
'ಧಕ್ಕಿಮುಕ್ಕಿ',
'ಧಕ್ಕೆಬಲಿ',
'ಧಕ್ಕೆಮುಕ್ಕೆ',
'ಧಗಮೆನ್',
'ಧಗಲಿಡು',
'ಧಡಕಸ',
'ಧಡಕ್ಕನೆ',
'ಧಡಧಡಿ',
'ಧಡಪಡ',
'ಧಡಲ್ಬಾಜಿ',
'ಧಡಾರನೆ',
'ಧಣಗರಿ',
'ಧಣುವಟ್ಟ',
'ಧಣುವಟ್ಟಿ',
'ಧನಂಗುಳಿ',
'ಧನಂಜಯ',
'ಧನಂಬಡೆ',
'ಧನಕೋಶ',
'ಧನಕ್ಷಯ',
'ಧನಗರ',
'ಧನಗಾಹಿ',
'ಧನಚಿಹ್ನೆ',
'ಧನಬಲ',
'ಧನಬಳ',
'ಧನಮದ',
'ಧನರೇಖೆ',
'ಧನಲಕ್ಷ್ಮಿ',
'ಧನಲಾಭ',
'ಧನವಂತ',
'ಧನವಂತ್ರಿ',
'ಧನಸ್ಥಾನ',
'ಧನಹರಿ',
'ಧನಹಾರಿ',
'ಧನಹೀನ',
'ಧನಾದೇಶ',
'ಧನುಕೋಟಿ',
'ಧನುಧಾರಿ',
'ಧನುರ್ಗತಿ',
'ಧನುರ್ಗುಣ',
'ಧನುಗ್ರ್ರಹ',
'ಧನುರ್ಧರ',
'ಧನುರ್ಧಾರಿ',
'ಧನುರ್ಮಾಸ',
'ಧನುರ್ಮುಷ್ಟಿ',
'ಧನುರ್ವಂಶ',
'ಧನುರ್ವರ್ಮ',
'ಧನುರ್ವಾತ',
'ಧನುರ್ವಾಯು',
'ಧನುರ್ವಿದ್ಯೆ',
'ಧನುರ್ವೀಣೆ',
'ಧನುರ್ವೇದ',
'ಧನುರ್ವೇದಿ',
'ಧನುಷ್ಕೋಟಿ',
'ಧನುಷ್ಪಟ',
'ಧನೂರಾಶಿ',
'ಧನ್ಯವಾದ',
'ಧನ್ವಂತರಿ',
'ಧನ್ವಾಯಾಸ',
'ಧಬಧಬೆ',
'ಧಮಣಿಗ',
'ಧಮ್ಮಪದ',
'ಧಮ್ಮಮಗ್ಗ',
'ಧರಣಿಜ',
'ಧರಣಿಪ',
'ಧರಧುರ',
'ಧರಭೋಗಿ',
'ಧರಸೋಡಿ',
'ಧರಾಮರ',
'ಧರಿಯಿಸು',
'ಧರ್ಮಂಗೆಯ್',
'ಧರ್ಮಂಬೆಱು',
'ಧರ್ಮಕರ',
'ಧರ್ಮಕರ್ತ',
'ಧರ್ಮಕರ್ಮ',
'ಧರ್ಮಕಾರ್ಯ',
'ಧರ್ಮಕ್ಷೇತ್ರ',
'ಧರ್ಮಗತಿ',
'ಧರ್ಮಗಳ್',
'ಧರ್ಮಗಳೆ',
'ಧರ್ಮಗಾಮಿ',
'ಧರ್ಮಗಾಳೆ',
'ಧರ್ಮಗುಣ',
'ಧರ್ಮಗುತ್ತಿ',
'ಧರ್ಮಗುರು',
'ಧರ್ಮಗೇಹ',
'ಧರ್ಮಗೊಡ್ಡು',
'ಧರ್ಮಗೋಷ್ಠಿ',
'ಧರ್ಮಗ್ರಂಥ',
'ಧರ್ಮಗ್ಲಾನಿ',
'ಧರ್ಮಚಕ್ರ',
'ಧರ್ಮಚತ್ರ',
'ಧರ್ಮಚಾರಿ',
'ಧರ್ಮಚ್ಯುತ',
'ಧರ್ಮಚ್ಯುತಿ',
'ಧರ್ಮಚ್ಯುತೆ',
'ಧರ್ಮಛತ್ರ',
'ಧರ್ಮಜ್ಞತೆ',
'ಧರ್ಮದರ್ಶಿ',
'ಧರ್ಮದೀಕ್ಷೆ',
'ಧರ್ಮದೂರ',
'ಧರ್ಮದೂರೆ',
'ಧರ್ಮದ್ರವ್ಯ',
'ಧರ್ಮದ್ರೋಹ',
'ಧರ್ಮದ್ರೋಹಿ',
'ಧರ್ಮಧ್ಯಾನ',
'ಧರ್ಮನಿಷ್ಠ',
'ಧರ್ಮನಿಷ್ಠೆ',
'ಧರ್ಮಪತ್ನಿ',
'ಧರ್ಮಪೀಠ',
'ಧರ್ಮಬೋಧೆ',
'ಧರ್ಮಭ್ರಷ್ಟ',
'ಧರ್ಮಭ್ರಷ್ಟೆ',
'ಧರ್ಮಮೂಢ',
'ಧರ್ಮಮೂಢೆ',
'ಧರ್ಮಯುದ್ಧ',
'ಧರ್ಮಲಂಡ',
'ಧರ್ಮವಂತ',
'ಧರ್ಮವತಿ',
'ಧರ್ಮಶಾಲೆ',
'ಧರ್ಮಶಾಸ್ತ್ರ',
'ಧರ್ಮಶೀಲ',
'ಧರ್ಮಶೀಲೆ',
'ಧರ್ಮಸಂತೆ',
'ಧರ್ಮಸಂಥೆ',
'ಧರ್ಮಸಂಸ್ಥೆ',
'ಧರ್ಮಸತ್ರ',
'ಧರ್ಮಸಭೆ',
'ಧರ್ಮಸಾಕ್ಷಿ',
'ಧರ್ಮಸೂಕ್ಷ್ಮ',
'ಧರ್ಮಸೂತ್ರ',
'ಧರ್ಮಸೆರೆ',
'ಧರ್ಮಾಂತರ',
'ಧರ್ಮಾಂಧತೆ',
'ಧರ್ಮಾಚಾರ್ಯ',
'ಧರ್ಮಾದಾಯ',
'ಧರ್ಮಾಧ್ಯಕ್ಷ',
'ಧರ್ಮಾಪೋಹ',
'ಧರ್ಮಾಯತ್ತ',
'ಧರ್ಮೋದಕ',
'ಧರ್ಮೋಪಮೆ',
'ಧವಗೇಡಿ',
'ಧವಲತೆ',
'ಧವಲಿತ',
'ಧವಲಿಮ',
'ಧವಳಕ',
'ಧವಳತೆ',
'ಧವಳಾರ',
'ಧವಳಿತ',
'ಧವಳಿಮ',
'ಧವಳಿಸು',
'ಧವಳೇಕ್ಷು',
'ಧವಾದಿನಿ',
'ಧಸಕ್ಕೆನ್ನು',
'ಧಸವಂಧ',
'ಧಾಂಡಿಗಿತ್ತಿ',
'ಧಾತುಕ್ಷಯ',
'ಧಾತುಗತ',
'ಧಾತುಗರ್ಭ',
'ಧಾತುಗುಂದು',
'ಧಾತುಗೆಡು',
'ಧಾತುಗೊಳ್',
'ಧಾತುತಪ್ಪು',
'ಧಾತುನಷ್ಟ',
'ಧಾತುನಾಶ',
'ಧಾತುಪಾಠ',
'ಧಾತುಪುಷ್ಟಿ',
'ಧಾತುಮಲ',
'ಧಾತುಮಳ',
'ಧಾತುರಸ',
'ಧಾತುರೇಣು',
'ಧಾತುವಾದ',
'ಧಾತುವೃದ್ಧಿ',
'ಧಾತುವೈರಿ',
'ಧಾತ್ರೀಫಲ',
'ಧಾತ್ರೀಫಳ',
'ಧಾತ್ರೇಯಿಕ',
'ಧಾನುಷ್ಕತೆ',
'ಧಾನುಷ್ಮತೆ',
'ಧಾನ್ಯಪುಟ',
'ಧಾನ್ಯಮದ್ಯ',
'ಧಾನ್ಯರೇಖೆ',
'ಧಾನ್ಯಸುಂಕ',
'ಧಾನ್ಯಾಗಾರ',
'ಧಾನ್ಯಾಗುರು',
'ಧಾಮಧೂಮು',
'ಧಾಮನಿಧಿ',
'ಧಾಮಾರ್ಗವ',
'ಧಾರಯಿಸು',
'ಧಾರವಾಡಿ',
'ಧಾರವೀರೆ',
'ಧಾರಾಕಾರ',
'ಧಾರಾಗೃಹ',
'ಧಾರಾದತ್ತ',
'ಧಾರಾಧರ',
'ಧಾರಾಪಾತ',
'ಧಾರಾಪಾತ್ರೆ',
'ಧಾರಾಪೂರ್ವಂ',
'ಧಾರಾವರ್ತ',
'ಧಾರಾವರ್ಷ',
'ಧಾರಾವಾಹಿ',
'ಧಾರಾಸಾರ',
'ಧಾರಾಳತೆ',
'ಧಾರಾಳತ್ವ',
'ಧಾರೆಗಟ್ಟು',
'ಧಾರಗಱೆ',
'ಧಾರೆಗಾಳೆ',
'ಧಾರೆಗೊಳ್',
'ಧಾರೆನೀರು',
'ಧಾರೆಯೆರೆ',
'ಧಾರೆಯೆಱೆ',
'ಧಾರೆಹುಳಿ',
'ಧಾರ್ಮಿಕತೆ',
'ಧಾಳಾಧೂಳಿ',
'ಧಾಳಿಕಾರ',
'ಧಾಳಿಗೊಳ್',
'ಧಾಳಿಯಿಡು',
'ಧಾಳಿವರಿ',
'ಧಾೞಾಧಾೞಿ',
'ಧಾೞಿವರಿ',
'ಧಿಕ್ಕರಣೆ',
'ಧಿಕ್ಕರಿಸು',
'ಧಿಕೃತಿಗೈ',
'ಧಿಗಿಲನೆ',
'ಧಿಟ್ಟತನ',
'ಧಿಮಿಕಿಟ',
'ಧಿಮ್ಮಿಕಿಟ',
'ಧಿಷ್ಣ್ಯನೇತ್ರ',
'ಧಿಷ್ಣ್ಯವರ',
'ಧೀಮಂತಿಕೆ',
'ಧೀಮೂಢತೆ',
'ಧೀರತನ',
'ಧೀರಶಾಂತ',
'ಧೀರೋದಾತ್ತ',
'ಧೀರೋದಾತ್ತೆ',
'ಧೀವರ್ತನೆ',
'ಧೀವಸಿಗ',
'ಧೀಸಚಿವ',
'ಧುಂಧುಮಾರ',
'ಧುಡುಮಿಸು',
'ಧುಡುಮ್ಮಿಱಿ',
'ಧುನಿಬಾವಾ',
'ಧುಮಶ್ಚಕ್ರಿ',
'ಧುರಂಧರ',
'ಧುರಂಧರಿ',
'ಧುರಂಧರೆ',
'ಧುರಂಧುರಿ',
'ಧುರಗಲಿ',
'ಧುರಧರ',
'ಧುರಧರೆ',
'ಧುರೀಣತೆ',
'ಧುರೀಣತ್ವ',
'ಧುಸುಮುಸಿ',
'ಧುಸುಮುಸು',
'ಧೂಢಸೆಲ',
'ಧೂಪಂಗುಡು',
'ಧೂಪಂಗೊಡು',
'ಧೂಪಂದೋಱು',
'ಧೂಪಘಟ',
'ಧೂಪದಾನಿ',
'ಧೂಪಧೂಮ',
'ಧೂಪಧೂಮ್ರ',
'ಧೂಪಪಾತ್ರೆ',
'ಧೂಪಮಿಕ್ಕು',
'ಧೂಪರಾಸ್ಮೆ',
'ಧೂಪವರ್ತಿ',
'ಧೂಪವಿಕ್ಕು',
'ಧೂಪಹಾಕು',
'ಧೂಪಳಿಸು',
'ಧೂಪಾಮೋದ',
'ಧೂಪಾಯಿತ',
'ಧೂಪಾರತಿ',
'ಧೂಮಕರ',
'ಧೂಮಕೇತು',
'ಧೂಮಧ್ವಜ',
'ಧೂಮಪಟ',
'ಧೂಮಪಾನ',
'ಧೂಮಪಾನಿ',
'ಧೂಮಪ್ರಭೆ',
'ಧೂಮಮಾರ್ಗ',
'ಧೂಮಯೋನಿ',
'ಧೂಮಲಿಪಿ',
'ಧೂಮಲಿಸು',
'ಧೂಮವರ್ಣ',
'ಧೂಮ್ರಚ್ಛದ',
'ಧೂಮ್ರಪತ್ರ',
'ಧೂಮ್ರಪತ್ರೆ',
'ಧೂಮ್ರಪಾನ',
'ಧೂಮ್ರಪಾನಿ',
'ಧೂರ್ತತನ',
'ಧೂರ್ತವಿದ್ಯೆ',
'ಧೂಸರಿತ',
'ಧೂಳಿಕೇಳಿ',
'ಧೂಳಿಡಿಸು',
'ಧೂಳಿಪಟ',
'ಧೂಳಿಪಟ್ಟ',
'ಧೂಳಿಮುಕ್ಕು',
'ಧೂಳಿವಿಡು',
'ಧೂಳೀಪಟ',
'ಧೂಳೀಪಟ್ಟ',
'ಧೂಳುಭೂಮ',
'ಧೂಳುಮಱೆ',
'ಧೂಳೆಬ್ಬಿಸು',
'ಧೂಳೆರಚು',
'ಧೃತರಾಷ್ಟ್ರ',
'ಧೃತಸತ್ವೆ',
'ಧೃತಿಗಿಡು',
'ಧೃತಿಗುಂದು',
'ಧೃತಿಗೆಡು',
'ಧೃತಿತ್ರಯ',
'ಧೃತಿಯುಕ್ತ',
'ಧೃತಿಯುಕ್ತೆ',
'ಧೃತಿಯುತ',
'ಧೃತಿಯುತೆ',
'ಧೃತಿಯೋಗ',
'ಧೃತಿವೆಱು',
'ಧೃತಿಹೀನ',
'ಧೃತಿಹೀನೆ',
'ಧೇನುಕುಚ',
'ಧೈರ್ಯಂಗಿಡು',
'ಧೈರ್ಯಂಗೆಡು',
'ಧೈರ್ಯಕ್ಷತಿ',
'ಧೈರ್ಯಗಾರ',
'ಧೈರ್ಯಗುಂದು',
'ಧೈರ್ಯಗೆಡು',
'ಧೈರ್ಯಗೊಳ್ಳು',
'ಧೈರ್ಯಯುತ',
'ಧೈರ್ಯಯುತೆ',
'ಧೈರ್ಯವಂತ',
'ಧೈರ್ಯವಂತೆ',
'ಧೈರ್ಯಶಾಲಿ',
'ಧೊಪ್ಪಳಿಸು',
'ಧೊರೆಪೂಜೆ',
'ಧೌರಿತಕ',
'ಧೌರೇಯಕ',
'ಧ್ಯಾನಂಬೆಱು',
'ಧ್ಯಾನಗಮ್ಯ',
'ಧ್ಯಾನಗಮ್ಯೆ',
'ಧ್ಯಾನಪರ',
'ಧ್ಯಾನಪರೆ',
'ಧ್ಯಾನಮಗ್ನ',
'ಧ್ಯಾನಮಗ್ನೆ',
'ಧ್ಯಾನಮುದ್ರೆ',
'ಧ್ಯಾನಯೋಗ',
'ಧ್ಯಾನಯೋಗಿ',
'ಧ್ಯಾನಶೀಲ',
'ಧ್ಯಾನಶೀಲೆ',
'ಧ್ಯಾನಾತೀತ',
'ಧ್ಯಾನಾತೀತೆ',
'ಧ್ಯಾನಾರೂಢ',
'ಧ್ಯಾನಾರೂಢೆ',
'ಧ್ಯೇಯಜೀವಿ',
'ಧ್ಯೇಯವಸ್ತು',
'ಧ್ಯೇಯವಾದ',
'ಧ್ಯೇಯವಾದಿ',
'ಧ್ರುವಕ್ಷೇತ್ರ',
'ಧ್ರುವಗತಿ',
'ಧ್ರುವಜಲ',
'ಧ್ರುವತಾರೆ',
'ಧ್ರುವತಾಳ',
'ಧ್ರುವನಿಧಿ',
'ಧ್ರುವಪೈರು',
'ಧ್ರುವಫಲ',
'ಧ್ರುವಬೇರ',
'ಧ್ರುವಮೃತ್ಯು',
'ಧ್ರುವಯಷ್ಟಿ',
'ಧ್ರುವಲೋಕ',
'ಧ್ರುವವೀಣೆ',
'ಧ್ರುವವೃತ್ತ',
'ಧ್ರುವಸ್ಥಾನ',
'ಧ್ರುವೀಯತೆ',
'ಧ್ವಂಸನೀಯ',
'ಧ್ವಜಗಂಭ',
'ಧ್ವಜದ್ರುಮ',
'ಧ್ವಜಪಟ',
'ಧ್ವಜಭಂಗ',
'ಧ್ವಜಭೂಮಿ',
'ಧ್ವಜಮುದ್ರೆ',
'ಧ್ವಜಸ್ತಂಭ',
'ಧ್ವಜಾದಂಡ',
'ಧ್ವನಿಕಾರ',
'ಧ್ವನಿಕಾವ್ಯ',
'ಧ್ವನಿಗಯ್',
'ಧ್ವನಿಗುಡು',
'ಧ್ವನಿಗೆಯ್',
'ಧ್ವನಿಗೊಡು',
'ಧ್ವನಿಜಾರು',
'ಧ್ವನಿತಂತು',
'ಧ್ವನಿತತ್ತ್ವ',
'ಧ್ವನಿತತ್ವ',
'ಧ್ವನಿತಾರ್ಥ',
'ಧ್ವನಿತೆಗೆ',
'ಧ್ವನಿಭ್ರಮೆ',
'ಧ್ವನಿಮತ',
'ಧ್ವನಿಯಿಸು',
'ಧ್ವನಿವಾದ',
'ಧ್ವನಿಶಾಸ್ತ್ರ',
'ಧ್ವನ್ಯಂತರ',
'ಧ್ವನ್ಯಕ್ಷರ',
'ಧ್ವನ್ಯಾತ್ಮಕ',
'ನಂಗುಲಿಕ',
'ನಂಜೆನೆಲ',
'ನಂಜವಟ್ಟು',
'ನಂಜುಗಣ್ಣು',
'ನಂಜುಜೋಗಿ',
'ನಂಜುಜ್ವರ',
'ನಂಜುರುಳೆ',
'ನಂಜುಹಿಡಿ',
'ನಂಟತನ',
'ನಂಟರ್ತನ',
'ನಂಟಸ್ತನ',
'ನಂಟಸ್ತಿಕೆ',
'ನಂಟುತನ',
'ನಂಟುಭಕ್ತಿ',
'ನಂಟುಮೊಱೆ',
'ನಂಟುವಳಿ',
'ನಂದಯಂತಿ',
'ನಂದವರ್ತಿ',
'ನಂದವಿಡು',
'ನಂದಾದೀಪ',
'ನಂದಾದೀಪ್ತಿ',
'ನಂದಾದ್ಯುತಿ',
'ನಂದಾಬೆಂಕಿ',
'ನಂದಾವನ',
'ನಂದಾವಳ',
'ನಂದಾವುಳ',
'ನಂದಿಕಂಬ',
'ನಂದಿಕೇಶ',
'ನಂದಿಕೋಲು',
'ನಂದಿಧ್ವಜ',
'ನಂದಿಲಿಪಿ',
'ನಂದಿವಟ್ಟ',
'ನಂದಿವಾಳ',
'ನಂದಿಹಾಳ',
'ನಂದೀಮುಖಿ',
'ನಂದೀಶ್ವರ',
'ನಂದುಗಳ್ಳಿ',
'ನಂದ್ಯಾವರ್ತ',
'ನಂಬಿಕಸ್ತ',
'ನಂಬಿಕಸ್ತೆ',
'ನಂಬಿಗಸ್ತ',
'ನಂಬಿಸುಹ',
'ನಂಬುಗೆಯೀ',
'ನಂಬುಗೊಳ್',
'ನಕಮಕ',
'ನಕಾರಿಸು',
'ನಕುಲೇಷ್ಟೆ',
'ನಕ್ಕಬಡಿ',
'ನಕ್ಕಬಳಿ',
'ನಕ್ತಂಚರ',
'ನಕ್ತಂಚರಿ',
'ನಕ್ತಕರ',
'ನಕ್ತಜೀವ',
'ನಕ್ತಮಾಲ',
'ನಕ್ತವ್ರತ',
'ನಕ್ರನಿದ್ರೆ',
'ನಕ್ಷತ್ರಕ',
'ನಕ್ತ್ರಗುಚ್ಛ',
'ನಕ್ಷತ್ರಿಕ',
'ನಕ್ಷತ್ರೀಕ',
'ನಕ್ಷತ್ರೇಷ್ಟಿ',
'ನಖಂಪಚ',
'ನಖಕ್ಷತ',
'ನಖಗಂಧೆ',
'ನಖಪುಟ',
'ನಖಾನಖಿ',
'ನಖಾಯುಧ',
'ನಗಧರ',
'ನಗಧರೆ',
'ನಗಧ್ವನಿ',
'ನಗನಟ್ಟು',
'ನಗನಾಣ್ಯ',
'ನಗಯಜ್ಞ',
'ನಗರಕ',
'ನಗರತ್ತ',
'ನಗರಾಜ',
'ನಗರಿಗ',
'ನಗಲಿಕ',
'ನಗವೆಣ್ಣಿ',
'ನಗಸಾರ',
'ನಗಾರ್ಖಾನೆ',
'ನಗಾರ್ಚನೆ',
'ನಗುಗೇಡು',
'ನಗುಪಾಟು',
'ನಗುಪಾಡು',
'ನಗುಮುಖ',
'ನಗುಮೊಗ',
'ನಗುಸಾರ',
'ನಗೆಕಾರ',
'ನಗೆಕಾರ್ತಿ',
'ನಗೆಕಾಱ',
'ನಗೆಗಣ್',
'ನಗೆಗಣ್ಣು',
'ನಗೆಗಾರ',
'ನಗೆಗಾರ್ತಿ',
'ನಗೆಗಾಱ',
'ನಗೆಗೇಡಿ',
'ನಗೆಗೇಡು',
'ನಗೆಚಾಟ',
'ನಗೆಚೆಲ್ಲು',
'ನಗೆಚೇಷ್ಟೆ',
'ನಗೆದೋಱು',
'ನಗೆಬೀರು',
'ನಗೆಮಾಡು',
'ನಗೆಮಾತು',
'ನಗೆಮುಖ',
'ನಗೆಮೊಗ',
'ನಗೆಯಾಟ',
'ನಗೆಯಾಡು',
'ನಗೆಯೆಳ್ಳು',
'ನಗೆವಾತು',
'ನಗೆವೀಱು',
'ನಗವೆಣ್',
'ನಗೆವೆಣ್ಣು',
'ನಗೆವೆಱು',
'ನಗೆಸಾರ',
'ನಗೆಹನಿ',
'ನಗೆಹಾಡು',
'ನಗೆಹೆಣ್ಣು',
'ನಗೆಹೆಣ್ಣೆ',
'ನಗೋತ್ಸಂಗ',
'ನಗೌಕಸ',
'ನಗ್ಗೆತ್ತಿಸು',
'ನಗ್ನಸತ್ಯ',
'ನಗ್ನಾವಸ್ಥೆ',
'ನಚ್ಚಣಿಗ',
'ನಚ್ಚಣಿಗೆ',
'ನಚ್ಚಣಿಸು',
'ನಚ್ಚುಗಿಡು',
'ನಚ್ಚುಗೆಡು',
'ನಚ್ಚುಜ್ವರ',
'ನಚ್ಚುಮಚ್ಚು',
'ನಚ್ಚುಮಾತು',
'ನಚ್ಚುಮೆಚ್ಚು',
'ನಚ್ಚುವೋಗು',
'ನಚ್ಚುಹಾಕು',
'ನಜರಾಣ',
'ನಜರಾಣಿ',
'ನಜರಾಣೆ',
'ನಜರಾನ',
'ನಜರಾನೆ',
'ನಜರಿರು',
'ನಜರೀಯು',
'ನಟರಾಜ',
'ನಟರುದ್ರ',
'ನಟವರ',
'ನಟವಿಗ',
'ನಟಸೂತ್ರ',
'ನಟಿಯಿಸು',
'ನಟುವಾಂಗ',
'ನಟುವಿಗ',
'ನಟ್ಟಗಾರ',
'ನಟ್ಟಗೊಂಬು',
'ನಟ್ಟಡವಿ',
'ನಟ್ಟನಡು',
'ನಟ್ಟವನೆ',
'ನಟ್ಟವಳ',
'ನಟ್ಟವಿಗ',
'ನಟ್ಟಿಗೊಳ್',
'ನಟ್ಟಿರುಳ್',
'ನಟ್ಟಿರುಳು',
'ನಟ್ಟುಕಡಿ',
'ನಟ್ಟುಮುರಿ',
'ನಟ್ಟುವರ',
'ನಟ್ಟುವಿಗ',
'ನಟ್ಟುವಿತಿ',
'ನಟ್ಟೆಗೊಂಬು',
'ನಟ್ಟೆಲಬು',
'ನಟ್ಟೆವನೆ',
'ನಟ್ಟೆವಾನ್',
'ನಡತರ',
'ನಡನಡ',
'ನಡಪತಿ',
'ನಡಪಾಡು',
'ನಡಪ್ರಾಯ',
'ನಡಬಾವಿ',
'ನಡಭಾವಿ',
'ನಡಮೀನ',
'ನಡಯಿಸು',
'ನಡವಣಿ',
'ನಡವಳಿ',
'ನಡಾವಳಿ',
'ನಡುಕಟ್ಟು',
'ನಡುಕಲ',
'ನಡುಗಂತಿ',
'ನಡುಗಟ್ಟು',
'ನಡುಗಡ್ಡೆ',
'ನಡುಗಾಡು',
'ನಡುಗಾಲ',
'ನಡುಗಿಸು',
'ನಡುಗೊಂಬು',
'ನಡುತರ',
'ನಡುತಲೆ',
'ನಡುದಲೆ',
'ನಡುದಾರ',
'ನಡುದಾರಿ',
'ನಡುನಿಲ್',
'ನಡುನೀರ್',
'ನಡುನೀರು',
'ನಡುನೆತ್ತಿ',
'ನಡುನೆಲೆ',
'ನಡುನೋಟ',
'ನಡುಪಟ್ಟಿ',
'ನಡುಪಾಯ್',
'ನಡುಪ್ರಾಯ',
'ನಡುಬಾನು',
'ನಡುಬೀಡು',
'ನಡುಬೆಟ್ಟು',
'ನಡುಬೊತ್ತು',
'ನಡುಮಂಡೆ',
'ನಡುಮಟ್ಟ',
'ನಡುಮಣ್ಣು',
'ನಡುಮಧ್ಯ',
'ನಡುಮಧ್ಯೆ',
'ನಡುಮನೆ',
'ನಡುಮುರಿ',
'ನಡುಮೂಗು',
'ನಡುರಂಗ',
'ನಡುರಾತ್ರಿ',
'ನಡುರಾತ್ರೆ',
'ನಡುವಣ',
'ನಡುವನ',
'ನಡುವನೆ',
'ನಡುವರಿ',
'ನಡುವಲೆ',
'ನಡುವಳಿ',
'ನಡುವಾಯ್',
'ನಡುವಾಯಿ',
'ನಡುವಾಯು',
'ನಡುವೊಗು',
'ನಡುಸಾಲೆ',
'ನಡುಹಾಳೆ',
'ನಡುಹೊತ್ತು',
'ನಡೆಕೋಲು',
'ನಡೆಗಲ್',
'ನಡೆಗಲಿ',
'ನಡೆಗೞ್ತೆ',
'ನಡೆಗಾಣ್',
'ನಡೆಗಿಡು',
'ನಡೆಗುಱಿ',
'ನಡೆಗೆಡು',
'ನಡೆಗೇಡು',
'ನಡೆಗೊಳ್',
'ನಡೆಗೊಳು',
'ನಡೆಗೋಂಟೆ',
'ನಡೆಗೋಟೆ',
'ನಡೆಗೋಲು',
'ನಡೆಗೋಳ',
'ನಡೆಜಂತ್ರ',
'ನಡೆಜಾಣ',
'ನಡೆಜಾಣ್ಮೆ',
'ನಡೆತರ್',
'ನಡೆತರು',
'ನಡೆದಾಣ',
'ನಡೆದುಳಿ',
'ನಡೆಪಾಡು',
'ನಡೆಬಾವಿ',
'ನಡೆಭಾವಿ',
'ನಡೆಮಡಿ',
'ನಡೆಮಾಡ',
'ನಡೆಮುಡಿ',
'ನಡೆಯಿಸು',
'ನಡೆಯೂಡು',
'ನಡೆಲಿಂಗ',
'ನಡೆವಕ್ಕಿ',
'ನಡೆವಡು',
'ನಡೆವಲೆ',
'ನಡೆವಳಿ',
'ನಡೆವೆಣ',
'ನಡೆಸರ',
'ನಡೆಸಾರ',
'ನಡೆಸೇತು',
'ನಡ್ಡಿಮೂಗು',
'ನಣ್ಪುಗೆಯ್',
'ನಣ್ಪುವೆಱು',
'ನತಕೋನ',
'ನತಜನ',
'ನತದೃಷ್ಟ',
'ನತದೃಷ್ಟೆ',
'ನತಿಗೆಯ್',
'ನತೋನ್ನತ',
'ನತ್ಕುಟಕ',
'ನದರಿಡು',
'ನದರಿಸು',
'ನದಿಮೊಗ',
'ನದಿವಾಸಿ',
'ನದೀಚರ',
'ನದೀಪೂರ',
'ನದೀಬೂಡು',
'ನದೀಮುಖ',
'ನದೀಮೂಲ',
'ನದೀಸರ್ಜ',
'ನದೀಹ್ರದ',
'ನನೆಕೊನೆ',
'ನನೆಗುದಿ',
'ನನೆಗೊಯ್',
'ನನೆದಂಡೆ',
'ನನೆದೋಱು',
'ನನೆನಗೆ',
'ನನೆನಾಱು',
'ನನೆಯಕ್ಕಿ',
'ನನೆಯಿಕ್ಕು',
'ನನೆಯೇರು',
'ನನೆಯೇಱು',
'ನನೆಯೊತ್ತು',
'ನನೆವೆರು',
'ನನೆವೆಱು',
'ನನೆವೊಯ್',
'ನನೆವೋಗು',
'ನನ್ನಾನನ್ನಿ',
'ನನ್ನಾನಿನ್ನಿ',
'ನನ್ನಿಕಾರ',
'ನನ್ನಿಕಾಱ',
'ನನ್ನಿಗೆಡು',
'ನನ್ನಿಗೆಯ್',
'ನನ್ನಿಗೊಡು',
'ನನ್ನಿಗೊಳ್',
'ನನ್ನಿದಪ್ಪು',
'ನನ್ನಿನುಡಿ',
'ನನ್ನಿವಂತ',
'ನನ್ನಿವಾತು',
'ನನ್ನಿವೇೞ್',
'ನಪುಂಸಕ',
'ನಪುಂಸಕಿ',
'ನಭಃಫಲ',
'ನಭಶ್ಚರ',
'ನಭಶ್ಚಲಿ',
'ನಭಶ್ಚುಂಬಿ',
'ನಭಸ್ಥಲ',
'ನಭಸ್ಥಳ',
'ನಭೋಗತಿ',
'ನಭೋನೌಕೆ',
'ನಭೋಮಣಿ',
'ನಭೋಮಾಸ',
'ನಭೋಯಾನ',
'ನಭೋವಾಣಿ',
'ನಮಸಿತ',
'ನಮಸಿತೆ',
'ನಮಸ್ಕಾರ',
'ನಮಸ್ಕಾರಿ',
'ನಮಸ್ಕಾರ್ಯ',
'ನಮಸ್ಕೃತಿ',
'ನಮಸ್ಯಿತ',
'ನಮಿಯಿಸು',
'ನಮೀಕೃತ',
'ನಮೂದಿಸು',
'ನಮೆಯಿಸು',
'ನಮೋಸ್ತುಗೈ',
'ನಮ್ರಭಾವ',
'ನಮ್ರಮೂರ್ತಿ',
'ನಯಂಗುಂದು',
'ನಯಂಗೆಡು',
'ನಯಂಬಡೆ',
'ನಯಂಬೆಱು',
'ನಯಕಾರ',
'ನಯಗಾತಿ',
'ನಯಗಾರ',
'ನಯಗಾರೆ',
'ನಯಗಾಱ',
'ನಯಗುಂದು',
'ನಯಗೆಡು',
'ನಯಚಿತ್ತ',
'ನಯಚಿತ್ತೆ',
'ನಯದಪ್ಪು',
'ನಯದಾಳ್',
'ನಯದೃಷ್ಟಿ',
'ನಯಯುಕ್ತ',
'ನಯಯುಕ್ತೆ',
'ನಯವಂತ',
'ನಯವಂತೆ',
'ನಯವಡೆ',
'ನಯವಾದ',
'ನಯವಿದ',
'ನಯವಿದೆ',
'ನಯಶಾಲಿ',
'ನಯಶಾಸ್ತ್ರ',
'ನಯಹೀನ',
'ನಯಹೀನೆ',
'ನರಕವಿ',
'ನರಕೀಟ',
'ನರಕುರಿ',
'ನರಕುಱಿ',
'ನರಗಟ್ಟು',
'ನರಗತಿ',
'ನರಗುಂಜಿ',
'ನರಗುರಿ',
'ನರಗುಱಿ',
'ನರಚಲು',
'ನರಜಂತು',
'ನರಜನ್ಮ',
'ನರಜೀವ',
'ನರಜೀವಿ',
'ನರಟಿಸು',
'ನರಧರ್ಮ',
'ನರಪತಿ',
'ನರಪಶು',
'ನರಪಿಳ್ಳೆ',
'ನರಪೇತ',
'ನರಪೇತು',
'ನರಪೇಲು',
'ನರಪೋತ',
'ನರಪ್ರಾಣಿ',
'ನರಪ್ರೇತ',
'ನರಬಲ',
'ನರಬಲಿ',
'ನರಮಾಲೆ',
'ನರಮೃಗ',
'ನರಮೇಧ',
'ನರಯಜ್ಞ',
'ನರಯಣ',
'ನರರೋಗ',
'ನರರೋಗಿ',
'ನರಲಿಸು',
'ನರಲೋಕ',
'ನರವಲಿ',
'ನರವಲು',
'ನರವಾಹ',
'ನರವುಲಿ',
'ನರವೆಣ್',
'ನರವೇಷ',
'ನರವೈದ್ಯ',
'ನರವ್ಯಾಧಿ',
'ನರವ್ಯೂಹ',
'ನರಸಿಂಗ',
'ನರಸಿಂಹ',
'ನರಸ್ಫೋಟ',
'ನರಹತಿ',
'ನರಹತ್ಯ',
'ನರಹತ್ಯೆ',
'ನರಹರಿ',
'ನರಹುರಿ',
'ನರಹುಲಿ',
'ನರಹುಳು',
'ನರಳಾಟ',
'ನರಳಾಡು',
'ನರಳಿಕೆ',
'ನರಳಿಸು',
'ನರಿಕೊಂತ',
'ನರಿಕೊಂಬು',
'ನರಿಗಣ್ಣು',
'ನರಿಗೊಂಬು',
'ನರಿಚೆಂಡು',
'ನರಿಬಾಲ',
'ನರಿಬಿಕ್ಕಿ',
'ನರಿಬೇಂಟೆ',
'ನರಿಬೇಟೆ',
'ನರಿಮೊಗ',
'ನರಿವಾಲ',
'ನರಿಹೊನ್ನೆ',
'ನರುಗಂಪು',
'ನರುಚಲು',
'ನರುವಂಜಿ',
'ನರುವಲ',
'ನರುವಲು',
'ನರೆತನ',
'ನರೆದಲೆ',
'ನರೆದೊಡು',
'ನರೆದೋಱು',
'ನರೆಯೇಱು',
'ನರೇಯಣ',
'ನರ್ಕುಟಕ',
'ನರ್ಮಗೋಷ್ಠಿ',
'ನರ್ಮಸಖ',
'ನಲಂದಳೆ',
'ನಲಮೀನ',
'ನಲವತ್ತು',
'ನಲವರಿ',
'ನಲವಾನ್',
'ನಲವೆಚ್ಚು',
'ನಲವೆರೆ',
'ನಲವೇರು',
'ನಲವೇಱು',
'ನಲಿದಾಡು',
'ನಲಿನಜ',
'ನಲಿಯಿಸು',
'ನಲಿವರಿ',
'ನಲಿವಾಱು',
'ನಲುಗಂಟಿ',
'ನಲುಗಿಡು',
'ನಲುಗಿಸು',
'ನಲ್ಮೆಕಾರ',
'ನಲ್ಮೆಕಾಱ',
'ನಲ್ಮೆಗಾತಿ',
'ನಲ್ಮೆಗಾರ್ತಿ',
'ನಲ್ಲಗನ್ನೆ',
'ನಲ್ಲತನ',
'ನಲ್ಲಪಾಲ್',
'ನಲ್ಲಿಱುಂಪೆ',
'ನವಕರಿ',
'ನವಕರ್ಮ',
'ನವಗರ',
'ನವಗ್ರಹ',
'ನವಜ್ವರ',
'ನವಣಕ್ಕಿ',
'ನವತಾಲ',
'ನವದ್ವಾರ',
'ನವಧಾನ್ಯ',
'ನವನಾಗ',
'ನವನಿಧಿ',
'ನವನೀತ',
'ನವಪತ್',
'ನವಪತ್ತು',
'ನವಬತ್ತು',
'ನವಬ್ರಹ್ಮ',
'ನವಮಣಿ',
'ನವರಂಗ',
'ನವರಂಧ್ರ',
'ನವರತ್ನ',
'ನವರಸ',
'ನವಸೂತಿ',
'ನವಾಂಶಕ',
'ನವಾಗತ',
'ನವಾಯತ್',
'ನವಾಯತ',
'ನವಾಯಿತ',
'ನವಾಯಿಸು',
'ನವಾಸಾರ',
'ನವಿರುಡೆ',
'ನವಿರೆಳೆ',
'ನವಿರೇರು',
'ನವಿರೇಱು',
'ನವಿರೇಳು',
'ನವಿರೇೞ್',
'ನವಿರ್ಮೂಡು',
'ನವಿಲಡಿ',
'ನವಿಲಾಟ',
'ನವಿಲಾಡಿ',
'ನವಿಲಾದಿ',
'ನವಿಲ್ಕೋಲ್',
'ನವಿಲ್ದೇರ',
'ನವಿಲ್ದೇರು',
'ನವಿಲ್ಮುಡಿ',
'ನವೀಕೃತ',
'ನವೀನತೆ',
'ನವುಕರ',
'ನವುಕರಿ',
'ನವುಬತ್ತು',
'ನವುಲಾದಿ',
'ನವೆಯಿಸು',
'ನವೋದಯ',
'ನವೋದಿತ',
'ನವೋದ್ಧೃತ',
'ನವೋನವ',
'ನವ್ಯಕಾವ್ಯ',
'ನವ್ಯಪ್ರಜ್ಞೆ',
'ನಶನಶೆ',
'ನಶಿಹತ್ತು',
'ನಶ್ಯಂಗೆಯ್ಯು',
'ನಶ್ವರತೆ',
'ನಷ್ಟಜೀವಿ',
'ನಷ್ಟೇಂದ್ರಿಯ',
'ನಷ್ಟೋದ್ಧಾರ',
'ನಸನಸೆ',
'ನಸಗುನ್ನಿ',
'ನಸರಾಣಿ',
'ನಸರ್ಬಂದಿ',
'ನಸಿಗುತ್ತ',
'ನಸಿಬೇನೆ',
'ನಸಿಮೆಯ್',
'ನಸೀಯತ್ತು',
'ನಸುಕುನ್ನಿ',
'ನಸುಗಾಯ್',
'ನಸುಗುನ್ನಿ',
'ನಸುಗುನ್ನೆ',
'ನಸುನಗು',
'ನಸುನಗೆ',
'ನಸುವಕ್ಕೆ',
'ನಸುವರೆ',
'ನಸುವಾಗು',
'ನಸುವಾಡು',
'ನಸುವಿರಿ',
'ನಸುವೆಸೆ',
'ನಸುಸುಯ್',
'ನಸೆಗಾರ',
'ನಸೆಗಾರ್ತಿ',
'ನಸೆಗಾಱ',
'ನಸೆಗೆಡು',
'ನಸೆನಸೆ',
'ನಸೆಹಚ್ಚು',
'ನಸ್ಯಂಗುಡು',
'ನಸ್ಯಂಗೆಯ್',
'ನಸ್ಯಕರ್ಮ',
'ನಸ್ಯವಿಧಿ',
'ನಸ್ಯವೆಱೆ',
'ನಸ್ಯಸೇದು',
'ನಳಪಾಕ',
'ನಳಮೀನ',
'ನಳಯೋಗ',
'ನಳಿನಜ',
'ನಳ್ಳಿಮೂಗು',
'ನಳ್ಳುನೆಲ',
'ನಱುಗಂಪು',
'ನಱುವಂಜಿ',
'ನಱುವಲ್',
'ನಱುವಲ',
'ನಱುವಲು',
'ನಱೆಗೊಡು',
'ನಱೆಯಿಕ್ಕು',
'ನೞ್ಗವುಂಡು',
'ನಾಂಗಲಿಕ',
'ನಾಂಗುರಿಕ',
'ನಾಂಗುಲಿಕ',
'ನಾಂಗುಲೀಕ',
'ನಾಂಟುಗೊಳ್',
'ನಾಂದೀಕರ',
'ನಾಂದೀಪದ',
'ನಾಂದೀಪದ್ಯ',
'ನಾಂದೀಮುಖ',
'ನಾಂದೀವಾದಿ',
'ನಾಂದೀವಿಧಿ',
'ನಾಂದೀಶ್ರಾದ್ಧ',
'ನಾಕಚಾಪು',
'ನಾಕಚ್ಯುತ',
'ನಾಕನಾರಿ',
'ನಾಕಬಲಿ',
'ನಾಕಾಬಂದಿ',
'ನಾಕಾರಿಸು',
'ನಾಕೆಬಂದಿ',
'ನಾಗಂಡುಗ',
'ನಾಗಂದಿಗೆ',
'ನಾಗಕನ್ನೆ',
'ನಾಗಕನ್ಯೆ',
'ನಾಗಕುಷ್ಠ',
'ನಾಗಚೌತಿ',
'ನಾಗಠಾಣ',
'ನಾಗದಂತಿ',
'ನಾಗದಲೆ',
'ನಾಗದಾಳ',
'ನಾಗದಾಳಿ',
'ನಾಗದಿವ್ಯ',
'ನಾಗಧ್ವನಿ',
'ನಾಗನೃತ್ಯ',
'ನಾಗಪಾಶ',
'ನಾಗಪುರಿ',
'ನಾಗಪುಷ್ಪ',
'ನಾಗಬಂಧ',
'ನಾಗಬಲಿ',
'ನಾಗಬಲೆ',
'ನಾಗಬೆತ್ತ',
'ನಾಗಭಸ್ಮ',
'ನಾಗಮುತ್ತು',
'ನಾಗಮುರಿ',
'ನಾಗಮುಷ್ಠಿ',
'ನಾಗಮೋಡಿ',
'ನಾಗರಂಗ',
'ನಾಗರಕ',
'ನಾಗರಜ್ಜು',
'ನಾಗರತೆ',
'ನಾಗರತ್ನ',
'ನಾಗರಸ್ತ್ರೀ',
'ನಾಗರಾಜ',
'ನಾಗರಿಕ',
'ನಾಗರಿಗ',
'ನಾಗರಿಪು',
'ನಾಗರೀಕ',
'ನಾಗಲತೆ',
'ನಾಗಲಿಂಗ',
'ನಾಗಲಿಕ',
'ನಾಗಲೀಕ',
'ನಾಗಲೋಕ',
'ನಾಗವಂದಿ',
'ನಾಗವಟ್ಟೆ',
'ನಾಗವಲಿ',
'ನಾಗವಲ್ಲಿ',
'ನಾಗವಳ್ಳಿ',
'ನಾಗವಾಯು',
'ನಾಗವಾಲಿ',
'ನಾಗವಾಸ',
'ನಾಗವಾಸಿ',
'ನಾಗವೀಣ',
'ನಾಗವೀಣೆ',
'ನಾಗವೇಣಿ',
'ನಾಗಸತ್ವ',
'ನಾಗಸರ',
'ನಾಗಸ್ಥಾನ',
'ನಾಗಸ್ವರ',
'ನಾಗಾರತಿ',
'ನಾಗಾರ್ಜುನಿ',
'ನಾಗಾಲೋಟ',
'ನಾಗುಲಿಕ',
'ನಾಗೊಂದಿಗೆ',
'ನಾಗೊಳಗ',
'ನಾಜನಾಮ',
'ನಾಟಕೀಯ',
'ನಾಟಡಿಕೆ',
'ನಾಟಿಗದ್ದೆ',
'ನಾಟುಕೋಲು',
'ನಾಟುನುಡಿ',
'ನಾಟ್ಯಗಾತಿ',
'ನಾಟ್ಯಗೃಹ',
'ನಾಟ್ಯಛಟ',
'ನಾಟ್ಯಧರ್ಮ',
'ನಾಟ್ಯರಂಗ',
'ನಾಟ್ಯವಾಡು',
'ನಾಟ್ಯವಿದ',
'ನಾಟ್ಯವಿದೆ',
'ನಾಟ್ಯವಿದ್ಯೆ',
'ನಾಟ್ಯವೇದ',
'ನಾಟ್ಯಶಾಲೆ',
'ನಾಟ್ಯಶಾಸ್ತ್ರ',
'ನಾಟ್ಯಶ್ರುತಿ',
'ನಾಟ್ಯಾಗಮ',
'ನಾಟ್ಯಾಲಯ',
'ನಾಡಗಾರ',
'ನಾಡಬಾಳೆ',
'ನಾಡಾಡಿಕೆ',
'ನಾಡಾಡಿಗ',
'ನಾಡಿಂಧಮ',
'ನಾಡಿಗಿತ್ತಿ',
'ನಾಡೀಕೂಟ',
'ನಾಡೀಯಂತ್ರ',
'ನಾಡೀವ್ರಣ',
'ನಾಡುನುಡಿ',
'ನಾಡುಹೆಂಚು',
'ನಾಡೆಱೆಯ',
'ನಾಡೊವಜ',
'ನಾಣಿಲಿಗ',
'ನಾಣುಗೆಡು',
'ನಾಣುದಾಣ',
'ನಾಣುನುಡಿ',
'ನಾಣೊಡೆಯ',
'ನಾಣ್ಗಿಡಿಸು',
'ನಾಣ್ಗೆಡಿಕ',
'ನಾಣ್ಗೆಡಿಸು',
'ನಾಣ್ಯಗಾರ',
'ನಾಣ್ಯವಂತ',
'ನಾಣ್ಯವಟ್ಟ',
'ನಾಣ್ಯವತಿ',
'ನಾಣ್ಯವಾರು',
'ನಾಣ್ಯವಿದ್ಯೆ',
'ನಾತಂಬಿಡಿ',
'ನಾತವಡೆ',
'ನಾತಹಿಡಿ',
'ನಾತಹೂವು',
'ನಾತಿದೂರ',
'ನಾಥದೀಕ್ಷೆ',
'ನಾಥವತಿ',
'ನಾದಕಟ್ಟು',
'ನಾದಚಕ್ರ',
'ನಾದತ್ರಯ',
'ನಾದದಂಡ',
'ನಾದಧ್ವನಿ',
'ನಾದಪೂರ್ಣ',
'ನಾದಬ್ರಹ್ಮ',
'ನಾದಮಯೆ',
'ನಾದಮೂರ್ತಿ',
'ನಾದವತಿ',
'ನಾದವೇತ್ತ',
'ನಾದವೇದಿ',
'ನಾದಶುದ್ಧಿ',
'ನಾದಸ್ತಂಭ',
'ನಾದಸ್ವರ',
'ನಾದಾತ್ಮಕ',
'ನಾದುರಸ್ತ',
'ನಾದುರಸ್ತಿ',
'ನಾದುರಸ್ತು',
'ನಾದೋತ್ತರಿ',
'ನಾನಡ್ಡಿಕೆ',
'ನಾನಾದೇಶಿ',
'ನಾನಾದೇಸಿ',
'ನಾನಾಲಿಂಗ',
'ನಾಪಿತಾನಿ',
'ನಾಭಿಕೂಪ',
'ನಾಭಿಗೋಲ್',
'ನಾಭಿತಾನ',
'ನಾಭಿದಘ್ನ',
'ನಾಭಿನಗ',
'ನಾಭಿನಾಲ',
'ನಾಭಿನಾಳ',
'ನಾಭಿಮೂಲ',
'ನಾಭಿಮೂಳ',
'ನಾಭಿಶೂಲೆ',
'ನಾಭಿಸೂತ್ರ',
'ನಾಭಿಸ್ಥಾನ',
'ನಾಭೀಕೂಪ',
'ನಾಮಂಜೂರ್',
'ನಾಮಂಜೂರಿ',
'ನಾಮಂಜೂರು',
'ನಾಮಕರ್ಮ',
'ನಾಮಗ್ರಹ',
'ನಾಮದಾರ',
'ನಾಮದೇಶಿ',
'ನಾಮಧಾರಿ',
'ನಾಮಧೇಯ',
'ನಾಮಪತ್ರ',
'ನಾಮಪದ',
'ನಾಮರೂಢಿ',
'ನಾಮಲಿಂಗ',
'ನಾಮಶೂನ್ಯ',
'ನಾಮಶೇಷ',
'ನಾಮಸೂಚಿ',
'ನಾಮಾಂಕಿತ',
'ನಾಮಾಂತರ',
'ನಾಮುಹುಳ',
'ನಾಯಕತ್ವ',
'ನಾಯಕಿಣಿ',
'ನಾಯಕಿತಿ',
'ನಾಯಕಿತ್ತಿ',
'ನಾಯನಾರ್',
'ನಾಯನ್ಮಾರ',
'ನಾಯಬೇಳ',
'ನಾಯಮಾರ',
'ನಾಯಮ್ಮಾರ',
'ನಾಯಹತ್ತಿ',
'ನಾಯಿಕುನ್ನಿ',
'ನಾಯಿಕೂಟೆ',
'ನಾಯಿಕುಳಿ',
'ನಾಯಿಕೆಮ್ಮು',
'ನಾಯಿಕೊಡೆ',
'ನಾಯಿಗಣ್ಣು',
'ನಾಯಿಗಳ್ಳಿ',
'ನಾಯಿಗೆಮ್ಮು',
'ನಾಯಿಚತ್ರಿ',
'ನಾಯಿಚುಂಚ',
'ನಾಯಿಜನ್ಮ',
'ನಾಯಿತನ',
'ನಾಯಿತೇಗ',
'ನಾಯಿನಾರ್',
'ನಾಯಿನಿದ್ದೆ',
'ನಾಯಿಪಾಡು',
'ನಾಯಿಪಾಲು',
'ನಾಯಿಪೆಲ್ಲ',
'ನಾಯಿಬಾಲ',
'ನಾಯಿಬಾಳು',
'ನಾಯಿಬೀಟೆ',
'ನಾಯಿಬೆಂಡೆ',
'ನಾಯಿಬೆತ್ತ',
'ನಾಯಿಬೇಲ',
'ನಾಯಿಬೇಲಿ',
'ನಾಯಿಬೇಳ',
'ನಾಯಿಮಾರ',
'ನಾಯಿಯುಳ್ಳಿ',
'ನಾಯಿರಾಗಿ',
'ನಾಯಿವೇಳ',
'ನಾಯಿಸಂತೆ',
'ನಾಯಿಹುಲಿ',
'ನಾಯಿಸೆಡೆ',
'ನಾಯಿಸೊಪ್ಪು',
'ನಾಯಿಹಾಲೆ',
'ನಾಯಿಹೊನ್ನೆ',
'ನಾಯೆಲವ',
'ನಾಯೊಡಲ್',
'ನಾಯೊಳಲೆ',
'ನಾಯ್ಚಿಲಿಕೆ',
'ನಾಯ್ದಱಿಯ',
'ನಾಯ್ನೊಳಲೆ',
'ನಾಯ್ಬದುಕು',
'ನಾರಂಬೇಳೆ',
'ನಾರದೀಯ',
'ನಾರವಳ್ಳಿ',
'ನಾರಸಿಂಗ',
'ನಾರಸಿಂಹ',
'ನಾರಾಯಣ',
'ನಾರಾಯಣಿ',
'ನಾರಿಂಬಳ್ಳಿ',
'ನಾರಿಕೇರ',
'ನಾರಿಕೇಲ',
'ನಾರಿಕೇಲಿ',
'ನಾರಿಕೇಳ',
'ನಾರಿಯಳಿ',
'ನಾರಿವಣ',
'ನಾರಿವಾಣ',
'ನಾರಿವಾಳ',
'ನಾರಿವೆಲೆ',
'ನಾರೀಕೇಲ',
'ನಾರೀಕೇಳ',
'ನಾರುಂಬಾಳೆ',
'ನಾರುಂಬೇಳೆ',
'ನಾರುಬಟ್ಟೆ',
'ನಾರುಬೇರು',
'ನಾರುಬೇಳೆ',
'ನಾರುಮಡಿ',
'ನಾರುಮೋಡ',
'ನಾರುಹುಣ್ಣು',
'ನಾರ್ಗವುಂಡು',
'ನಾರ್ಗಾಮುಂಡ',
'ನಾರ್ಗಾಮುಂಡು',
'ನಾರ್ಗಾವುಂಡ',
'ನಾರ್ಗಾವುಂಡು',
'ನಾಲಕಟ್ಟು',
'ನಾಲಗಾರ',
'ನಾಲಬಂದ',
'ನಾಲಬಂದಿ',
'ನಾಲಾಯಕ್',
'ನಾಲಾಯಕು',
'ನಾಲಾಯಕ್ಕು',
'ನಾಲಾಯಖ್',
'ನಾಲಾಯಖ',
'ನಾಲಾಯಖು',
'ನಾಲಾಹಿಕ್',
'ನಾಲಿಕೇರ',
'ನಾಲೀಜಂಘ',
'ನಾಲುಕಟ್ಟು',
'ನಾಲುಬಂದಿ',
'ನಾಲುಬಂದು',
'ನಾಲುಮಡಿ',
'ನಾಲ್ಕನೆಯ',
'ನಾಲ್ಛಾಸಿರ',
'ನಾಲ್ಮಡಿಸು',
'ನಾಲ್ವಡಿಸು',
'ನಾವಲಿಗ',
'ನಾವಳಿಗ',
'ನಾವಾಡಿಗ',
'ನಾವಾರೋಹ',
'ನಾವಾಸನ',
'ನಾವಿದಿತಿ',
'ನಾಶಹೊಂದು',
'ನಾಶೀಪುಡಿ',
'ನಾಸವಾತ',
'ನಾಸಾಂತರ',
'ನಾಸಾಮಣಿ',
'ನಾಸಾವಂಶ',
'ನಾಸಾವಿರ',
'ನಾಸಿಕಾಗ್ರ',
'ನಾಸೀಪುಡಿ',
'ನಾಸ್ತಿಕತೆ',
'ನಾಸ್ತಿಕತ್ವ',
'ನಾಳಕಿವಿ',
'ನಾಳಬಂದಿ',
'ನಾಳವ್ಯಾಸ',
'ನಾಳಿಂಧಮ',
'ನಾಳಿಕೇರ',
'ನಾಳಿಪತ್ರ',
'ನಾಳಿಪುಣ್',
'ನಾಳಿಬೀೞ್',
'ನಾಳಿಯಂಬು',
'ನಾಳಿವಿಲ್',
'ನಾಳಿವಿಲು',
'ನಾಳಿವಿಲ್ಲು',
'ನಾಳಿವುಣ್',
'ನಾಳಿವೋಗು',
'ನಾಳೀಜಂಘ',
'ನಾಳೀಪತ್ರ',
'ನಾಳೀವ್ರಣ',
'ನಾಳ್ಗಾಮಿಗ',
'ನಾಳ್ಗಾಮುಂಡ',
'ನಾಳ್ಗಾವುಂಡ',
'ನಾಳ್ಗಾವುಂಡು',
'ನಾಱುಂಬೇಳೆ',
'ನಾಱುಬೇಳೆ',
'ನಾೞ್ಗಮುಂಡು',
'ನಾೞ್ಗಾಮಿಗ',
'ನಾೞ್ಗಾಮುಂಡ',
'ನಾೞ್ಗಾಮುಂಡು',
'ನಾೞ್ಗಾವುಂಡ',
'ನಾೞ್ಗಾವುಂಡು',
'ನಾೞ್ವರ್ತನ',
'ನಾೞ್ವಿಳಾಸ',
'ನಿಂಗವಂತ',
'ನಿಂಗಾಯತ',
'ನಿಂದಾಸ್ತುತಿ',
'ನಿಂದಿರಿಕೆ',
'ನಿಂದಿರಿಸು',
'ನಿಂದೆಗಾಱ',
'ನಿಂದೋಪಮೆ',
'ನಿಂಬಾವಳಿ',
'ನಿಂಬೆಚೊಟ್ಟು',
'ನಿಂಬೆಹುಲ್ಲು',
'ನಿಃಕಂಟಕ',
'ನಿಃಕರುಣ',
'ನಿಃಕರುಣಿ',
'ನಿಃಕಳಂಕ',
'ನಿಃಕಾಂಕ್ಷತೆ',
'ನಿಃಕಾಂಕ್ಷಿತ',
'ನಿಃಕ್ರೀವಂತ',
'ನಿಃಪಸಿವು',
'ನಿಃಪ್ರತ್ಯೂಹ',
'ನಿಃಪ್ರಪಂಚ',
'ನಿಃಪ್ರಪಂಚಿ',
'ನಿಃಪ್ರಪಂಚು',
'ನಿಃಶಂಕತೆ',
'ನಿಃಶರಣ',
'ನಿಃಶ್ರೇಯಸ',
'ನಿಃಸಂಪರ್ಕ',
'ನಿಃಸರಣ',
'ನಿಕಟಸ್ಥ',
'ನಿಕರಿಸು',
'ನಿಕರ್ತನ',
'ನಿಕರ್ಷಣ',
'ನಿಕಷಜ್ಞ',
'ನಿಕಷಣ',
'ನಿಕಾಮಿನಿ',
'ನಿಕಾರಣ',
'ನಿಕಾರಿಸು',
'ನಿಕಾಲಸು',
'ನಿಕುಂಚಕ',
'ನಿಕುಂಚಿತ',
'ನಿಕುಟ್ಟಕ',
'ನಿಕುಟ್ಟನ',
'ನಿಕುರುಂಬ',
'ನಿಕೃಷ್ಟತೆ',
'ನಿಕೇತನ',
'ನಿಕೋಚಕ',
'ನಿಕ್ಕರುಣ',
'ನಿಕ್ಕರುಣಿ',
'ನಿಕ್ಕಳಿಕೆ',
'ನಿಕ್ಕಾರಣ',
'ನಿಕ್ಕುಳಿಸು',
'ನಿಕ್ವಣಿಸು',
'ನಿಕ್ಷಿಪಿತ',
'ನಿಕ್ಷೇಪಣ',
'ನಿಕ್ಷೇಪಿಸು',
'ನಿಖಂಡಿತ',
'ನಿಖರತೆ',
'ನಿಗಠಣೆ',
'ನಿಗಡಿತ',
'ನಿಗದಿತ',
'ನಿಗದಿಸು',
'ನಿಗಮನ',
'ನಿಗಮಾಂತ',
'ನಿಗಮಿತ',
'ನಿಗರಣ',
'ನಿಗರಳ',
'ನಿಗರಾಣಿ',
'ನಿಗರ್ವತೆ',
'ನಿಗರ್ವಿತೆ',
'ನಿಗಾಬಾನಿ',
'ನಿಗಾವಣೆ',
'ನಿಗಾವಾನಿ',
'ನಿಗಿರಿಸು',
'ನಿಗುಂಬಿಸು',
'ನಿಗುರಿಕೆ',
'ನಿಗುರಿಸು',
'ನಿಗುರುಳ್ಳೆ',
'ನಿಗೂಢತೆ',
'ನಿಗೂಢತ್ವ',
'ನಿಗೂಹನ',
'ನಿಗೃಹೀತ',
'ನಿಗೆವಾನಿ',
'ನಿಗ್ಗರಿಸು',
'ನಿಗ್ರಂಥನ',
'ನಿಗ್ರಹಿಸು',
'ನಿಘರ್ಷಣ',
'ನಿಘರ್ಷಿಸು',
'ನಿಚೋಲಕ',
'ನಿಚೋಳಕ',
'ನಿಚ್ಚಟತ್ವ',
'ನಿಚ್ಚಟಿಕೆ',
'ನಿಚ್ಚಟಿಕ್ಕೆ',
'ನಿಚ್ಚಣಿಕೆ',
'ನಿಚ್ಚಣಿಗೆ',
'ನಿಚ್ಚಯಿಸು',
'ನಿಚ್ಚವಚ್ಚ',
'ನಿಚ್ಚವಳ',
'ನಿಚ್ಚಳತೆ',
'ನಿಚ್ಚಳಾಗು',
'ನಿಚ್ಚಳಿತ',
'ನಿಚ್ಚಳಿಸು',
'ನಿಜಕರ್ತೃ',
'ನಿಜಗಾರ',
'ನಿಜಗುಣ',
'ನಿಜಚರ್ಮ',
'ನಿಜಪದ',
'ನಿಜಬಿಂದು',
'ನಿಜವೆಱು',
'ನಿಜ್ಜವಣೆ',
'ನಿಜ್ಜಾಡಿಸು',
'ನಿಟ್ಟಯಿಸು',
'ನಿಟ್ಟರಿಸು',
'ನಿಟ್ಟಿರುಳು',
'ನಿಟ್ಟುಪಾಸ',
'ನಿಟ್ಟುರಗೈ',
'ನಿಟ್ಟುರಿಸು',
'ನಿಟ್ಟುರುಹು',
'ನಿಟ್ಟುವಡು',
'ನಿಟ್ಟುವರಿ',
'ನಿಟ್ಟುವೊಟ್ಟು',
'ನಿಟ್ಟುಸಿರ್',
'ನಿಟ್ಟುಸಿರಿ',
'ನಿಟ್ಟುಸಿರು',
'ನಿಟ್ಟುಸುರ್',
'ನಿಟ್ಟುಸುರು',
'ನಿಟ್ಟೆಕಾಱ',
'ನಿಟ್ಟೆಗೊಳ್',
'ನಿಟ್ಟೆಗೊಳ್ಳು',
'ನಿಟ್ಟೆಪಡು',
'ನಿಟ್ಟೆವಡು',
'ನಿಟ್ಟೆಸಳ್',
'ನಿಟ್ಟೆಸಳು',
'ನಿಟ್ಟೆಸೞ್',
'ನಿಟ್ಟೇರಿಸು',
'ನಿಟ್ಟೊರಸು',
'ನಿಡಿನಿಲ್',
'ನಿಡುಂಬರೆ',
'ನಿಡುಗಡಿ',
'ನಿಡುಗಾಲ',
'ನಿಡುಗೆಡೆ',
'ನಿಡುಸುಯ್',
'ನಿಡುಸುಯ್ಯು',
'ನಿಡುಸುಯ್ಲು',
'ನಿತಂಬಿನಿ',
'ನಿತ್ತರಣೆ',
'ನಿತ್ತರಿಸು',
'ನಿತ್ತಿರಿಕೆ',
'ನಿತ್ಯಕರ್ಮ',
'ನಿತ್ಯಗಟ್ಲೆ',
'ನಿತ್ಯಜೀವಿ',
'ನಿತ್ಯಪದ',
'ನಿತ್ಯಪುಷ್ಪ',
'ನಿತ್ಯಬಿಂದು',
'ನಿತ್ಯಮಹ',
'ನಿತ್ಯಮುಕ್ತ',
'ನಿತ್ಯಯಾತ್ರೆ',
'ನಿತ್ಯವಂತ',
'ನಿತ್ಯವಾದ',
'ನಿತ್ಯವಿಧಿ',
'ನಿತ್ಯಸೂರಿ',
'ನಿತ್ಯಸ್ಥಿತಿ',
'ನಿತ್ರಾಣಿಸು',
'ನಿದರ್ಶನ',
'ನಿದರ್ಶಿಸು',
'ನಿದಾನಸ್ತ',
'ನಿದಾನಿಸು',
'ನಿದಾರುಣ',
'ನಿದಿಗ್ಧಿಕೆ',
'ನಿದಿಧ್ಯಾಸ',
'ನಿದ್ದಕಟ್ಟು',
'ನಿದ್ದೆಕಣ್ಣು',
'ನಿದ್ದೆಗಣ್ಣು',
'ನಿದ್ದೆಗರೆ',
'ನಿದ್ದೆಗೆಡು',
'ನಿದ್ದೆಗೆಯ್',
'ನಿದ್ದೆಗೇಡು',
'ನಿದ್ದೆಗೊಳ್',
'ನಿದ್ದೆತಿಳಿ',
'ನಿದ್ದೆದಿಳಿ',
'ನಿದ್ದೆನೆಲೆ',
'ನಿದ್ದೆಬರು',
'ನಿದ್ದೆಮಾಡು',
'ನಿದ್ದೆಮೊಬ್ಬು',
'ನಿದ್ದೆವೋಗು',
'ನಿದ್ದೆಹೋಕ',
'ನಿದ್ದೆಹೋಗು',
'ನಿದ್ರಾಕುಲ',
'ನಿದ್ರಾಕುಲೆ',
'ನಿದ್ರಾಕುಳ',
'ನಿದ್ರಾಕುಳೆ',
'ನಿದ್ರಾನಿದ್ರೆ',
'ನಿದ್ರಾಭಂಗ',
'ನಿದ್ರಾಭರ',
'ನಿದ್ರಾಭಾರ',
'ನಿದ್ರಾರೂಢ',
'ನಿದ್ರಾರೂಢೆ',
'ನಿದ್ರಾಲಾಪ',
'ನಿದ್ರೆಗೆಡು',
'ನಿದ್ರೆಗೆಯ್',
'ನಿದ್ರೆಗೆಯ್ಸು',
'ನಿದ್ರೆಗೇಡು',
'ನಿದ್ರೆತಿಳಿ',
'ನಿಧನಸ್ಥ',
'ನಿಧಾನಿಸು',
'ನಿಧಿಗಾಣ್',
'ನಿಧಿಗೊಳು',
'ನಿಧಿತೆಗೆ',
'ನಿಧಿದಾಣ',
'ನಿಧಿದೆಗೆ',
'ನಿಧಿವಾಸ',
'ನಿಧುವನ',
'ನಿನದಿಸು',
'ನಿಪತನ',
'ನಿಪತಿತ',
'ನಿಪಾತಕ',
'ನಿಪಾತನ',
'ನಿಪಾತಿಸು',
'ನಿಪೀಡನ',
'ನಿಪೀಡಿತ',
'ನಿಪೀಡಿಸು',
'ನಿಪುಣತೆ',
'ನಿಪುಣತ್ವ',
'ನಿಪುಣಿಸು',
'ನಿಪುತ್ರಿಕ',
'ನಿಪ್ಪತಿಕೆ',
'ನಿಪ್ಪಯಣ',
'ನಿಪ್ಪಸರ',
'ನಿಪ್ಪಾಳೆಯ',
'ನಿಪ್ಪೊಸತು',
'ನಿಬಂಧನ',
'ನಿಬಂಧನೆ',
'ನಿಬದ್ಧಿಸು',
'ನಿಬರ್ಹಣ',
'ನಿಬಾಯಿಸು',
'ನಿಬಿಡತೆ',
'ನಿಬಿಡತ್ವ',
'ನಿಬಿಡಲ್ಲೆ',
'ನಿಬಿಡಿತ',
'ನಿಬಿಡೋಕ್ತಿ',
'ನಿಬಿರೀಸ',
'ನಿಬ್ಬಂದಿಗ',
'ನಿಬ್ಬಡವ',
'ನಿಬ್ಬಣಿಗ',
'ನಿಬ್ಬಯಲು',
'ನಿಬ್ಬರಣೆ',
'ನಿಬ್ಬರಿಸು',
'ನಿಬ್ಬೆರಗು',
'ನಿಬ್ಬೆರಸು',
'ನಿಬ್ಬೆಳಕು',
'ನಿಬ್ಬೆಱಗು',
'ನಿಬ್ಯಸನ',
'ನಿಭಾಯಿಸು',
'ನಿಭಾಲನ',
'ನಿಭಾಳನ',
'ನಿಮಂತ್ರಣ',
'ನಿಮಂತ್ರಣೆ',
'ನಿಮಂತ್ರಿತ',
'ನಿಮಂತ್ರಿಸು',
'ನಿಮಗ್ನತೆ',
'ನಿಮಜ್ಜಕ',
'ನಿಮಜ್ಜನ',
'ನಿಮಜ್ಜರ',
'ನಿಮಾನಿಮಿ',
'ನಿಮಿತ್ತಕ',
'ನಿಮಿತ್ತಜ್ಞ',
'ನಿಮಿತ್ಯಕ',
'ನಿಮಿರಿಸು',
'ನಿಮಿರ್ವೆಱು',
'ನಿಮೀಲನ',
'ನಿಮೀಲಿಕೆ',
'ನಿಮೀಲಿತ',
'ನಿಮೀಲಿಸು',
'ನಿಮೀಳನ',
'ನಿಮೀಳಿತ',
'ನಿಮುರಿಚು',
'ನಿಮೇಷಿತ',
'ನಿಮೋನಿಯ',
'ನಿಮ್ನವರ್ಗ',
'ನಿಮ್ಮಳಿಕೆ',
'ನಿಯಂತ್ರಕ',
'ನಿಯಂತ್ರಣ',
'ನಿಯಂತ್ರಿತ',
'ನಿಯಂತ್ರಿಸು',
'ನಿಯತಾಂಕ',
'ನಿಯಮಜ್ಞ',
'ನಿಯಮನ',
'ನಿಯಮಸ್ಥ',
'ನಿಯಮಿಗ',
'ನಿಯಮಿತ',
'ನಿಯಮಿಸು',
'ನಿಯಾಮಕ',
'ನಿಯಾಮಿಸು',
'ನಿಯೋಗಿಸು',
'ನಿಯೋಜಕ',
'ನಿಯೋಜನ',
'ನಿಯೋಜನೆ',
'ನಿಯೋಜಿತ',
'ನಿಯೋಜಿಸು',
'ನಿರಂಕುಶ',
'ನಿರಂಕುಶೆ',
'ನಿರಂಜನ',
'ನಿರಂತರ',
'ನಿರಂತರಂ',
'ನಿರಂಬರ',
'ನಿರಂಬರೆ',
'ನಿರಂಬಳ',
'ನಿರಕ್ಕರ',
'ನಿರಕ್ಕರಿ',
'ನಿರಕ್ಷರ',
'ನಿರಕ್ಷರಿ',
'ನಿರಗುಂಡಿ',
'ನಿರಘತೆ',
'ನಿರಜಪ್ಪೆ',
'ನಿರತತ್ವ',
'ನಿರಧಿಕ',
'ನಿರಧಿಕೆ',
'ನಿರನ್ವಯ',
'ನಿರನ್ವೇಷ',
'ನಿರಪಾಯ',
'ನಿರಪೇಕ್ಷ',
'ನಿರಪೇಕ್ಷಿ',
'ನಿರಪೇಕ್ಷೆ',
'ನಿರಮಿಸು',
'ನಿರಯನ',
'ನಿರರುತಿ',
'ನಿರರ್ಗಲ',
'ನಿರರ್ಗಳ',
'ನಿರರ್ಥಕ',
'ನಿರರ್ಥಕಂ',
'ನಿರಲಸ',
'ನಿರವದ್ಯ',
'ನಿರವದ್ಯೆ',
'ನಿರವಧಿ',
'ನಿರವಯ',
'ನಿರವಲ್',
'ನಿರವಸ್ಥ',
'ನಿರವಾಣಿ',
'ನಿರವಿಸು',
'ನಿರಶನ',
'ನಿರಸನ',
'ನಿರಸನಿ',
'ನಿರಸನೆ',
'ನಿರಸಲ',
'ನಿರಸಿತ',
'ನಿರಸೂಯ',
'ನಿರಸೂಯೆ',
'ನಿರಹಂತೆ',
'ನಿರಹಮ್ಮು',
'ನಿರಾಕಾಂಕ್ಷೆ',
'ನಿರಾಕಾರ',
'ನಿರಾಕುಲ',
'ನಿರಾಕುಲಂ',
'ನಿರಾಕುಲೆ',
'ನಿರಾಕುಳ',
'ನಿರಾಕುಳಂ',
'ನಿರಾಕೃತ',
'ನಿರಾಕೃತಿ',
'ನಿರಾಕೃತೆ',
'ನಿರಾಕ್ಷೇಪ',
'ನಿರಾಗಮ',
'ನಿರಾಗಸ',
'ನಿರಾಗಸೆ',
'ನಿರಾತಂಕ',
'ನಿರಾತ್ಮಜ',
'ನಿರಾದರ',
'ನಿರಾದಾನ',
'ನಿರಾಧಾರ',
'ನಿರಾಧಾರಿ',
'ನಿರಾಧಾರೆ',
'ನಿರಾನಂದ',
'ನಿರಾಪದ',
'ನಿರಾಪೇಕಂ',
'ನಿರಾಪೇಕ್ಷ',
'ನಿರಾಬಾಧ',
'ನಿರಾಬಾಧೆ',
'ನಿರಾಭಾರಿ',
'ನಿರಾಭಾಸಿ',
'ನಿರಾಮಯ',
'ನಿರಾಮಿಷ',
'ನಿರಾಯಸ',
'ನಿರಾಯಾಸ',
'ನಿರಾಯುಧ',
'ನಿರಾಲಂಬ',
'ನಿರಾಲಸ್ಯ',
'ನಿರಾಲೋಚಂ',
'ನಿರಾವಾಸ',
'ನಿರಾಶಕ',
'ನಿರಾಶ್ರಯ',
'ನಿರಾಶ್ರಿತ',
'ನಿರಾಶ್ರಿತೆ',
'ನಿರಾಸಕ',
'ನಿರಾಸಕ್ತ',
'ನಿರಾಸಕ್ತಿ',
'ನಿರಾಸಕ್ತೆ',
'ನಿರಾಸ್ರವ',
'ನಿರಾಹಾರ',
'ನಿರಾಹಾರೆ',
'ನಿರಾಹಾರಿ',
'ನಿರಾಳಂಬ',
'ನಿರಾಳತ್ವ',
'ನಿರಿಂದ್ರಿಯ',
'ನಿರಿಬಿಗಿ',
'ನಿರಿಯಣ',
'ನಿರಿಯಾಣ',
'ನಿರಿಯಿಡು',
'ನಿರಿವಿಡಿ',
'ನಿರಿಹಿಡಿ',
'ನಿರೀಕ್ಷಣ',
'ನಿರೀಕ್ಷಣೆ',
'ನಿರೀಕ್ಷಿತ',
'ನಿರೀಕ್ಷಿಸು',
'ನಿರೀಶ್ವರ',
'ನಿರೀಹಿತ',
'ನಿರುಂಬಳ',
'ನಿರುಕಿಸು',
'ನಿರುಕ್ತಿಸು',
'ನಿರುಚ್ಛ್ವಾಸ',
'ನಿರುತ್ತರ',
'ನಿರುತ್ತರೆ',
'ನಿರುತ್ಸಾಹ',
'ನಿರುತ್ಸಾಹಿ',
'ನಿರುದಯ',
'ನಿರುದ್ಯೋಗ',
'ನಿರುದ್ಯೋಗಿ',
'ನಿರುದ್ವಿಗ್ನ',
'ನಿರುದ್ವಿಗ್ನೆ',
'ನಿರುಪದ್ರ',
'ನಿರುಪಮ',
'ನಿರುಪಮೆ',
'ನಿರುಪಾಧಿ',
'ನಿರುಪಾಯ',
'ನಿರುಪಾಯೆ',
'ನಿರುಮ್ಮಳ',
'ನಿರುವಾಣ',
'ನಿರುಹಣ',
'ನಿರುಹಾದ',
'ನಿರೂಪಕ',
'ನಿರೂಪಕಿ',
'ನಿರೂಪಣ',
'ನಿರೂಪಣೆ',
'ನಿರೂಪಿತ',
'ನಿರೂಪಿಸು',
'ನಿರೂಹಣ',
'ನಿರೋಧಕ',
'ನಿರೋಧಿಸು',
'ನಿರೋಹಣ',
'ನಿರ್ಋತಿ',
'ನಿರ್ಕಲಿಸು',
'ನಿರ್ಗತಿಕ',
'ನಿರ್ಗತಿಕೆ',
'ನಿರ್ಗಮನ',
'ನಿರ್ಗಮನಿ',
'ನಿರ್ಗಮಿಸು',
'ನಿರ್ಗರ್ವತೆ',
'ನಿರ್ಗಲಿತ',
'ನಿರ್ಗಹನ',
'ನಿರ್ಗಳಿತ',
'ನಿರ್ಗಾವಿಲ',
'ನಿರ್ಗುಣತೆ',
'ನಿರ್ಗುಣತ್ವ',
'ನಿರ್ಗ್ರಂಥತ್ವ',
'ನಿರ್ಗ್ರಂಥನ',
'ನಿರ್ಘಾತನ',
'ನಿರ್ಘಾತಿಸು',
'ನಿರ್ಘೋಷಣ',
'ನಿರ್ಘೋಷಿಸು',
'ನಿರ್ಜಂತುಕ',
'ನಿರ್ಜರಣ',
'ನಿರ್ಜರತ್ವ',
'ನಿರ್ಜರಿಸು',
'ನಿರ್ಜಾಡಿಸು',
'ನಿರ್ಜೀವತೆ',
'ನಿರ್ಝರಿಣಿ',
'ನಿರ್ಣಯಿಸು',
'ನಿರ್ಣಾಯಕ',
'ನಿರ್ಣೇಜಕ',
'ನಿರ್ಣೇಜನ',
'ನಿರ್ಣೇಜಿತ',
'ನಿರ್ದಲನ',
'ನಿರ್ದಹಿಸು',
'ನಿರ್ದಳಿತ',
'ನಿರ್ದಾಕ್ಷಿಣ್ಯ',
'ನಿರ್ದಾಟಿಸು',
'ನಿರ್ದಾಯಕ',
'ನಿರ್ದಾಯಾದ್ಯ',
'ನಿರ್ದಾರಕ',
'ನಿರ್ದಾರಣ',
'ನಿರ್ದಾರಿತ',
'ನಿರ್ದಾರಿಸು',
'ನಿರ್ದಾಲನ',
'ನಿರ್ದಾಳನ',
'ನಿರ್ದಾಳಿಸು',
'ನಿರ್ದಿಗಂತ',
'ನಿರ್ದಿಗ್ಧಿಕೆ',
'ನಿರ್ದಿಷ್ಟತೆ',
'ನಿರ್ದಿಷ್ಟಿತ',
'ನಿರ್ದುರಿತ',
'ನಿರ್ದುರಿತೆ',
'ನಿರ್ದುಷ್ಟತೆ',
'ನಿರ್ದೇಶಕ',
'ನಿರ್ದೇಶಕಿ',
'ನಿರ್ದೇಶನ',
'ನಿರ್ದೇಶಿಸು',
'ನಿರ್ದೋಷತೆ',
'ನಿದ್ರ್ವಂದ್ವಕ',
'ನಿರ್ಧನತ್ವ',
'ನಿರ್ಧನಿಕ',
'ನಿರ್ಧರಂಗೈ',
'ನಿರ್ಧರಿಸು',
'ನಿರ್ಧಾಟಣ',
'ನಿರ್ಧಾಟಿಸು',
'ನಿರ್ಧಾರಕ',
'ನಿರ್ಧಾರಣ',
'ನಿರ್ಧಾರಣೆ',
'ನಿರ್ಧಾರಿತ',
'ನಿರ್ಧಾವನ',
'ನಿರ್ಧೂನನ',
'ನಿರ್ನಂದನ',
'ನಿರ್ನವಿಸು',
'ನಿರ್ನಾಮಿಕೆ',
'ನಿರ್ನಾಯಕ',
'ನಿರ್ನಿದ್ರತೆ',
'ನಿರ್ನಿಮಿತ್ತ',
'ನಿರ್ನಿಮಿತ್ತಂ',
'ನಿರ್ನಿಮೇಷ',
'ನಿರ್ನೀರದ',
'ನಿರ್ಬಂಧನ',
'ನಿರ್ಬಂಧಿಕ',
'ನಿರ್ಬಂಧಿಗ',
'ನಿರ್ಬಂಧಿತ',
'ನಿರ್ಬಂಧಿಸು',
'ನಿರ್ಬನಿಸು',
'ನಿರ್ಬಯಲು',
'ನಿರ್ಬಹಣ',
'ನಿರ್ಬಾಧಕ',
'ನಿರ್ಬಾಧಿತ',
'ನಿರ್ಭಯತೆ',
'ನಿರ್ಭಯತ್ವ',
'ನಿರ್ಭರತೆ',
'ನಿರ್ಭರಿತ',
'ನಿರ್ಭರ್ತ್ಸನ',
'ನಿರ್ಭರ್ತ್ಸನೆ',
'ನಿರ್ಭರ್ತ್ಸಿತ',
'ನಿರ್ಭಾಗಿನಿ',
'ನಿರ್ಭಾವಿಕ',
'ನಿರ್ಭಿನ್ನತೆ',
'ನಿರ್ಭೇದತೆ',
'ನಿರ್ಭೇದಿಸು',
'ನಿರ್ಮತ್ಸರ',
'ನಿರ್ಮತ್ಸರೆ',
'ನಿರ್ಮಥನ',
'ನಿರ್ಮಥಿತ',
'ನಿರ್ಮಥಿಸು',
'ನಿರ್ಮಮತೆ',
'ನಿರ್ಮರಣ',
'ನಿರ್ಮಲತೆ',
'ನಿರ್ಮಲತ್ವ',
'ನಿರ್ಮಲಿತ',
'ನಿರ್ಮಲಿನ',
'ನಿರ್ಮಲಿಸು',
'ನಿರ್ಮಳತೆ',
'ನಿರ್ಮಳತ್ವ',
'ನಿರ್ಮಳಿಕೆ',
'ನಿರ್ಮಳಿನ',
'ನಿರ್ಮಾತ್ಸರ್ಯ',
'ನಿರ್ಮಾನುಷ',
'ನಿರ್ಮಾನುಷ್ಯ',
'ನಿರ್ಮಾಪಕ',
'ನಿರ್ಮಾಪಕಿ',
'ನಿರ್ಮಾಪಣ',
'ನಿರ್ಮುಕುತ',
'ನಿರ್ಮೂಲಕ',
'ನಿರ್ಮೂಲನ',
'ನಿರ್ಮೂಲಿತ',
'ನಿರ್ಮೂಲಿಸು',
'ನಿರ್ಮೂಳನ',
'ನಿರ್ಮೂಳಿಸು',
'ನಿರ್ಯಂತ್ರಣ',
'ನಿರ್ಯಾತನ',
'ನಿರ್ಯಾಮಕ',
'ನಿರ್ಯೋಚನೆ',
'ನಿರ್ಲಕ್ಷಿಸು',
'ನಿರ್ಲಜ್ಜಕ',
'ನಿರ್ಲಜ್ಜಿಕೆ',
'ನಿರ್ಲಪಿತ',
'ನಿರ್ಲಿಖಿತ',
'ನಿರ್ಲಿಪ್ತತೆ',
'ನಿರ್ಲೇಪಕ',
'ನಿರ್ಲೇಪಗೈ',
'ನಿರ್ಲೋಟಣ',
'ನಿರ್ಲೋಟಿಸು',
'ನಿಲ್ರ್ವಯನಿ',
'ನಿರ್ವಂಚಕ',
'ನಿರ್ವಂಚನೆ',
'ನಿರ್ವಚನ',
'ನಿರ್ವಚಿಸು',
'ನಿರ್ವದಿಸು',
'ನಿರ್ವನಿಸು',
'ನಿರ್ವಪಣ',
'ನಿರ್ವಯಲು',
'ನಿರ್ವರ್ಣನ',
'ನಿರ್ವರ್ಣಿಸು',
'ನಿರ್ವರ್ತಕ',
'ನಿರ್ವರ್ತನ',
'ನಿರ್ವರ್ತನೆ',
'ನಿರ್ವರ್ತಿತ',
'ನಿರ್ವರ್ತಿಸು',
'ನಿರ್ವಸ್ತುಕ',
'ನಿರ್ವಹಣ',
'ನಿರ್ವಹಣೆ',
'ನಿರ್ವಹಿಸು',
'ನಿರ್ವಳಿತ',
'ನಿರ್ವಾಚಕ',
'ನಿರ್ವಾಚಕಿ',
'ನಿರ್ವಾಚನ',
'ನಿರ್ವಾಚಿತ',
'ನಿರ್ವಾಚಿಸು',
'ನಿರ್ವಾಪಣ',
'ನಿರ್ವಾಪಿತ',
'ನಿರ್ವಾರಿಸು',
'ನಿರ್ವಾಸನ',
'ನಿರ್ವಾಸನೆ',
'ನಿರ್ವಾಸಿತ',
'ನಿರ್ವಾಹಕ',
'ನಿರ್ವಾಹಕಿ',
'ನಿರ್ವಾಹಿಸು',
'ನಿರ್ವಿಕಲ್ಪ',
'ನಿರ್ವಿಕಾರ',
'ನಿರ್ವಿಕಾರಿ',
'ನಿರ್ವಿಘ್ನತೆ',
'ನಿರ್ವಿಚಾರ',
'ನಿರ್ವಿಣ್ಣತೆ',
'ನಿರ್ವಿನಿಸು',
'ನಿರ್ವಿಭವ',
'ನಿರ್ವಿರಲ',
'ನಿರ್ವಿರಳ',
'ನಿರ್ವಿವಾದ',
'ನಿರ್ವಿಶಿಷ್ಟ',
'ನಿರ್ವಿಶೇಷ',
'ನಿರ್ವಿಷಯ',
'ನಿರ್ವಿಷಯಿ',
'ನಿರ್ವೇದನಿ',
'ನಿರ್ವೇಧನ',
'ನಿರ್ವೈಕಾರ',
'ನಿವ್ರ್ಯಗ್ರತೆ',
'ನಿವ್ರ್ಯಥನ',
'ನಿವ್ರ್ಯಸನ',
'ನಿವ್ರ್ಯಸನಿ',
'ನಿವ್ರ್ಯಾಕುಲ',
'ನಿವ್ರ್ಯಾಕುಲೆ',
'ನಿವ್ರ್ಯಾಕುಳ',
'ನಿವ್ರ್ಯಾಕುಳೆ',
'ನಿವ್ರ್ಯಾಜತೆ',
'ನಿವ್ರ್ಯಾಧಿತ',
'ನಿವ್ರ್ಯಾಪಾರ',
'ನಿವ್ರ್ಯಾಪಾರಿ',
'ನಿರ್ಹರಣ',
'ನಿರ್ಹೇತುಕ',
'ನಿಲಂಬನ',
'ನಿಲಕಾಲಂ',
'ನಿಲಗಾಲ',
'ನಿಲಬಾಕಿ',
'ನಿಲವರ',
'ನಿಲವಹಿ',
'ನಿಲಹಾಸ',
'ನಿಲುಂಬಿಸು',
'ನಿಲುಕಡೆ',
'ನಿಲುಕಿಸು',
'ನಿಲುಗಂಬ',
'ನಿಲುಗಡೆ',
'ನಿಲುಗಾಲು',
'ನಿಲುಬಗೆ',
'ನಿಲುಮನೆ',
'ನಿಲುವಂಗಿ',
'ನಿಲುವಳಿ',
'ನಿಲ್ಲುಮನೆ',
'ನಿಲ್ಲುವನೆ',
'ನಿವಡಿಕೆ',
'ನಿವಡಿಸು',
'ನಿವರ್ತಕ',
'ನಿರ್ವತಕ',
'ನಿವರ್ತನ',
'ನಿವರ್ತನೆ',
'ನಿವರ್ತಿತ',
'ನಿವರ್ತಿಸು',
'ನಿವರ್ಹಣ',
'ನಿವಸತ',
'ನಿವಸನ',
'ನಿವಳಿತ',
'ನಿವಳಿಸು',
'ನಿವಾಡಿಸು',
'ನಿವಾರಕ',
'ನಿವಾರಣ',
'ನಿವಾರಣೆ',
'ನಿವಾರಿತ',
'ನಿವಾರಿಸು',
'ನಿವಾಸಕ',
'ನಿವಾಸಿನಿ',
'ನಿವಾಸಿಸು',
'ನಿವಾಳಿಸು',
'ನಿವಿಡೀಶ',
'ನಿವಿರೀಶ',
'ನಿವೇದಕ',
'ನಿವೇದನ',
'ನಿವೇದನೆ',
'ನಿವೇದಿತ',
'ನಿವೇದಿಸು',
'ನಿವೇಶಕ',
'ನಿವೇಶಣ',
'ನಿವೇಶನ',
'ನಿವೇಶಿತ',
'ನಿವೇಸಣ',
'ನಿಶಮನ',
'ನಿಶರಣ',
'ನಿಶಾಂಧತೆ',
'ನಿಶಾಕರ',
'ನಿಶಾಖೋರ',
'ನಿಶಾಗಂಧಿ',
'ನಿಶಾಚರ',
'ನಿಶಾಚರಿ',
'ನಿಶಾಚೂರ್ಣ',
'ನಿಶಾಟನ',
'ನಿಶಾದರ್ಶಿ',
'ನಿಶಾರಣ',
'ನಿಶಿಕರ',
'ನಿಶಿಚರ',
'ನಿಶಿಧಿಗೆ',
'ನಿಶೀಥಿನಿ',
'ನಿಶೀಧಿಕೆ',
'ನಿಶೀಧಿಗೆ',
'ನಿಶುಂಭನ',
'ನಿಶ್ಚಂಚಲ',
'ನಿಶ್ಚಯಿಸು',
'ನಿಶ್ಚರತೆ',
'ನಿಶ್ಚಲತೆ',
'ನಿಶ್ಚಲತ್ವ',
'ನಿಶ್ಚಲನೆ',
'ನಿಶ್ಚಲಿತ',
'ನಿಶ್ಚಳತೆ',
'ನಿಶ್ಚಳಿತ',
'ನಿಶ್ಚಿಂತತೆ',
'ನಿಶ್ಚಿತಾರ್ಥ',
'ನಿಶ್ಚೇತನ',
'ನಿಶ್ಚೇತನೆ',
'ನಿಶ್ಚೇಷ್ಟತೆ',
'ನಿಶ್ಚೇಷ್ಟಿತ',
'ನಿಶ್ಚೇಷ್ಟಿತೆ',
'ನಿಶ್ಚೈತನ್ಯ',
'ನಿಶ್ಛಿದ್ರತೆ',
'ನಿಶ್ರೇಯಸ',
'ನಿಶ್ರೇಯಸ್ಸು',
'ನಿಶ್ವಸಿತ',
'ನಿಶ್ಶಂಕತೆ',
'ನಿಶ್ಶಂಕಿತ',
'ನಿಶ್ಶಂಕಿತ್ವ',
'ನಿಶ್ಶಬ್ದತೆ',
'ನಿಶ್ಶಲಾಕ',
'ನಿಶ್ಶಳಾಕ',
'ನಿಶ್ಶೇಷಿಸು',
'ನಿಶ್ಶ್ರೇಣಿಕ',
'ನಿಶ್ಶ್ರೇಯಸ',
'ನಿಷದ್ವರ',
'ನಿಷಾಖೋರ',
'ನಿಷಾದಿನಿ',
'ನಿಷಿದಿಗೆ',
'ನಿಷಿಧಿಗೆ',
'ನಿಷೂದನ',
'ನಿಷೇಧಕ',
'ನಿಷೇಧಾಜ್ಞೆ',
'ನಿಷೇಧಾರ್ಥ',
'ನಿಷೇಧಿತ',
'ನಿಷೇಧಿಸು',
'ನಿಷೇವಕ',
'ನಿಷೇವಣ',
'ನಿಷೇವಿತ',
'ನಿಷ್ಕಂಟಕ',
'ನಿಷ್ಕಂಪಿತ',
'ನಿಷ್ಕಪಟ',
'ನಿಷ್ಕಪಟಿ',
'ನಿಷ್ಕರಿಸು',
'ನಿಷ್ಕರುಣ',
'ನಿಷ್ಕರುಣಿ',
'ನಿಷ್ಕರುಣೆ',
'ನಿಷ್ಕರ್ಷಿಸು',
'ನಿಷ್ಕಲಂಕ',
'ನಿಷ್ಕಲಿತ',
'ನಿಷ್ಕಲ್ಮಷ',
'ನಿಷ್ಕಲ್ಮಷೆ',
'ನಿಷ್ಕವಚ',
'ನಿಷ್ಕಷಾಯ',
'ನಿಷ್ಕಷಾಯಂ',
'ನಿಷ್ಕಳಂಕ',
'ನಿಷ್ಕಳಿತ',
'ನಿಷ್ಕಾಂಕ್ಷಿತ',
'ನಿಷ್ಕಾಪಟ್ಯ',
'ನಿಷ್ಕಾರಣ',
'ನಿಷ್ಕಾರಣಂ',
'ನಿಷ್ಕಾರುಣ್ಯ',
'ನಿಷ್ಕಾಸಿತ',
'ನಿಷ್ಕಾಳಜಿ',
'ನಿಷ್ಕಾಳಿಸು',
'ನಿಷ್ಕಿಂಚನ',
'ನಿಷ್ಕುಟಿಲ',
'ನಿಷ್ಕೂಜಿತ',
'ನಿಷ್ಕೃಷ್ಟತೆ',
'ನಿಷ್ಕೇವಲ',
'ನಿಷ್ಕೇವಳ',
'ನಿಷ್ಕೈತವ',
'ನಿಷ್ಕ್ರಮಣ',
'ನಿಷ್ಕ್ರಮಿಸು',
'ನಿಷ್ಕ್ರಿಯತೆ',
'ನಿಷ್ಕ್ರಿಯತ್ವ',
'ನಿಷ್ಖಲಿತ',
'ನಿಷ್ಠಾಪಿಸು',
'ನಿಷ್ಠಾವಂತ',
'ನಿಷ್ಠಾವಂತೆ',
'ನಿಷ್ಠೀವನ',
'ನಿಷ್ಠುರತೆ',
'ನಿಷ್ಠುರತ್ವ',
'ನಿಷ್ಠೆಗೆಡು',
'ನಿಷ್ಠೆವಿಡಿ',
'ನಿಷ್ಠೇವನ',
'ನಿಷ್ಣಾತತೆ',
'ನಿಷ್ಪಂದಿತೆ',
'ನಿಷ್ಪತಿತ',
'ನಿಷ್ಪನ್ನತೆ',
'ನಿಷ್ಪಾಕ್ಷಿಕ',
'ನಿಷ್ಪಾದನ',
'ನಿಷ್ಪಾದಿತ',
'ನಿಷ್ಪಾಪತೆ',
'ನಿಷ್ಪಾವಕ',
'ನಿಷ್ಪೀಡನ',
'ನಿಷ್ಪೀಡನೆ',
'ನಿಷ್ಪೀಡಿತ',
'ನಿಷ್ಪುರುಷೆ',
'ನಿಷ್ಪೇಷಣ',
'ನಿಷ್ಪೇಷಿತ',
'ನಿಷ್ಪ್ರಕಂಪ',
'ನಿಷ್ಪ್ರತ್ಯೂಹ',
'ನಿಷ್ಪ್ರಪಂಚ',
'ನಿಷ್ಪ್ರವರ',
'ನಿಷ್ಪ್ರವಾಣಿ',
'ನಿಷ್ಪ್ರವಾಳ',
'ನಿಷ್ಫಲತೆ',
'ನಿಷ್ಯಂದಿಸು',
'ನಿಸದಿಗೆ',
'ನಿಸಿದಿಗೆ',
'ನಿಸಿಧಿಗೆ',
'ನಿಸೀಧಿಕೆ',
'ನಿಸೂದನ',
'ನಿಸೃಷ್ಟಾರ್ಥ',
'ನಿಸೇವಣೆ',
'ನಿಸೇವಿತ',
'ನಿಸ್ಖಲಿತ',
'ನಿಸ್ತರಂಗ',
'ನಿಸ್ತರಣ',
'ನಿಸ್ತರಿಸು',
'ನಿಸ್ತರ್ಹಣ',
'ನಿಸ್ತಾರಕ',
'ನಿಸ್ತಾರಿಸು',
'ನಿಸ್ತೇಜಿತ',
'ನಿಸ್ತ್ರಿಂಶಕ',
'ನಿಸ್ತ್ರಿಂಶತೆ',
'ನಿಸ್ತ್ರಿಗುಣ',
'ನಿಸ್ತ್ರೈಗುಣ್ಯ',
'ನಿಸ್ಪೃಹತೆ',
'ನಿಸ್ವೇದತ್ವ',
'ನಿಸ್ಸಂಕಲ್ಪ',
'ನಿಸ್ಸಂಕಲ್ಪೆ',
'ನಿಸ್ಸಂಕೋಚ',
'ನಿಸ್ಸಂಗತೆ',
'ನಿಸ್ಸಂಗತ್ವ',
'ನಿಸ್ಸಂತತಿ',
'ನಿಸ್ಸಂತಾನ',
'ನಿಸ್ಸಂತಾನಿ',
'ನಿಸ್ಸಂದಿಗ್ಧ',
'ನಿಸ್ಸಂದೇಹ',
'ನಿಸ್ಸಂದೇಹಿ',
'ನಿಸ್ಸಂಶಯ',
'ನಿಸ್ಸಂಶಯಂ',
'ನಿಸ್ಸಂಸಾರ',
'ನಿಸ್ಸಪತ್ನ',
'ನಿಸ್ಸರಣ',
'ನಿಸ್ಸಹಣ',
'ನಿಸ್ಸಹಾಯ',
'ನಿಸ್ಸಹಾಯೆ',
'ನಿಸ್ಸಾರತೆ',
'ನಿಸ್ಸಾಹಣ',
'ನಿಸ್ಸಾಹಳ',
'ನಿಸ್ಸಾಳಯ',
'ನಿಹಂಚಿತ',
'ನಿಹತಾರ್ಥ',
'ನಿಹನನ',
'ನಿಹಿಂಸನ',
'ನಿಱಿಗೊಳ್',
'ನಿಱಿದಪ್ಪು',
'ನಿಱಿವಿಡಿ',
'ನಿಱಿಸುಗೆ',
'ನಿಱುಂಗಲ್ಲು',
'ನಿಱುಕಲ್ಲು',
'ನಿಱುಗಲ್',
'ನೀಕರಿಸು',
'ನೀಚತನ',
'ನೀಚದೈವ',
'ನೀಚಪಂಥಿ',
'ನೀಚಪಾತ್ರ',
'ನೀಚಬುದ್ಧಿ',
'ನೀಚಮಾರ್ಗ',
'ನೀಚರಾಶಿ',
'ನೀಚವಾಕ್ಯ',
'ನೀಚವೃತ್ತಿ',
'ನೀಟುಗಾತಿ',
'ನೀಟುಗಾರ',
'ನೀಟುಗಾಱ',
'ನೀಟುತನ',
'ನೀಟುದಾರಿ',
'ನೀಟುದೋರು',
'ನೀಡುಂಪೊೞ್ತು',
'ನೀಡುಂಮಾಡು',
'ನೀಡುಮಾಡು',
'ನೀತಿಕಥೆ',
'ನೀತಿಕಾರ',
'ನೀತಿಕಾಱ',
'ನೀತಿಕ್ಷತಿ',
'ನೀತಿಗಳೆ',
'ನೀತಿಗಾಮಿ',
'ನೀತಿಗೆಡು',
'ನೀತಿಜಡ',
'ನೀತಿದಪ್ಪು',
'ನೀತಿದೂರ',
'ನೀತಿದೂರೆ',
'ನೀತಿಪಥ',
'ನೀತಿಪಾಠ',
'ನೀತಿಬಾಹ್ಯ',
'ನೀತಿಬೋಧೆ',
'ನೀತಿಭ್ರಷ್ಟ',
'ನೀತಿಭ್ರಷ್ಟೆ',
'ನೀತಿಮತಿ',
'ನೀತಿಮತ್ತೆ',
'ನೀತಿಮಾರ್ಗ',
'ನೀತಿಯುತ',
'ನೀತಿಯುತೆ',
'ನೀತಿವಂತ',
'ನೀತಿವಂತೆ',
'ನೀತಿವಾದಿ',
'ನೀತಿವಿಡಿ',
'ನೀತಿವಿದ',
'ನೀತಿವಿದೆ',
'ನೀತಿಶಾಸ್ತ್ರ',
'ನೀತಿಸೂತ್ರ',
'ನೀತಿಹೀನ',
'ನೀತಿಹೀನೆ',
'ನೀಮಾಚಾಮ',
'ನೀರಂಜನಿ',
'ನೀರಂಜಿಸು',
'ನೀರಕಿಚ್ಚು',
'ನೀರಕ್ಕರ',
'ನೀರಗಳು',
'ನೀರಗಿಚ್ಚು',
'ನೀರಗುಂಡಿ',
'ನೀರಗೋಣಿ',
'ನೀರಡಕ',
'ನೀರಡಕಿ',
'ನೀರಡಕು',
'ನೀರಡಕೆ',
'ನೀರಡಸು',
'ನೀರಡಿಕೆ',
'ನೀರಡಿಸು',
'ನೀರಡುಕು',
'ನೀರತೃಣ',
'ನೀರಬಟ್ಟೆ',
'ನೀರಬಳ್ಳೆ',
'ನೀರಬೊಮ್ಮಿ',
'ನೀರವಂಜಿ',
'ನೀರವತೆ',
'ನೀರವಳ',
'ನೀರವಾಳಿ',
'ನೀರಸತೆ',
'ನೀರಸತ್ವ',
'ನೀರಸನಿ',
'ನೀರಸನ್ನಿ',
'ನೀರಸಿಂಬಿ',
'ನೀರಹಬ್ಬೆ',
'ನೀರಱಕೆ',
'ನೀರಾಂಜನ',
'ನೀರಾಜನ',
'ನೀರಾಜಿತ',
'ನೀರಾಜಿನಿ',
'ನೀರಾಜಿಸು',
'ನೀರಾರಂಬ',
'ನೀರಾರಂಭ',
'ನೀರಾವರಿ',
'ನೀರಾವಳಿ',
'ನೀರಾಹಾರ',
'ನೀರಿಳಿಸು',
'ನೀರಿೞಿಪು',
'ನೀರಿೞಿವು',
'ನೀರಿೞಿಹ',
'ನೀರುಂಬಳಿ',
'ನೀರುಕಕ್ಕೆ',
'ನೀರುಕಟ್ಟು',
'ನೀರುಕಟ್ಟೆ',
'ನೀರುಕಾಗೆ',
'ನೀರುಕಾಯಿ',
'ನೀರುಕಾರು',
'ನೀರುಕುಡಿ',
'ನೀರುಕೂಲಿ',
'ನೀರುಕೊಡು',
'ನೀರುಕೋಳಿ',
'ನೀರುಗಂಟಿ',
'ನೀರುಗಂಡಿ',
'ನೀರುಗಣ್ಣಿ',
'ನೀರುಗಣ್ಣು',
'ನೀರುಗಾಗೆ',
'ನೀರುಗಾಯಿ',
'ನೀರುಗಿಚ್ಚು',
'ನೀರುಗಿಳಿ',
'ನೀರುಗುಡಿ',
'ನೀರುಗುಳ್ಳೆ',
'ನೀರುಗೇಡಿ',
'ನೀರುಗೇಡು',
'ನೀರುಗೋಣಿ',
'ನೀರುಗೋಲಿ',
'ನೀರುಗೋಳಿ',
'ನೀರುಗೋೞಿ',
'ನೀರುಜಪ್ಲೆ',
'ನೀರುಣಿಸು',
'ನೀರುತಿದಿ',
'ನೀರುತುಂಬು',
'ನೀರುತೊಟ್ಟಿ',
'ನೀರುದಂಟು',
'ನೀರುದಗೆ',
'ನೀರುದಾರಿ',
'ನೀರುದಾಳಿ',
'ನೀರುದುರೆ',
'ನೀರುದೆಗೆ',
'ನೀರುದೊಣೆ',
'ನೀರುದೋಷ',
'ನೀರುನಾಯಿ',
'ನೀರುನಿಲ್ಲು',
'ನೀರುನೆತ್ತಿ',
'ನೀರುಪಕ್ಷಿ',
'ನೀರುಪಲ್ಲೆ',
'ನೀರುಬಳ್ಳಿ',
'ನೀರುಬಳ್ಳೆ',
'ನೀರುಬಿಡು',
'ನೀರುಬೆಂಡೆ',
'ನೀರುಬೆಕ್ಕು',
'ನೀರುಬೆತ್ತ',
'ನೀರುಬೇಳ',
'ನೀರುಬೊಕ್ಕೆ',
'ನೀರುಬ್ಬರ',
'ನೀರುಬ್ರಹ್ಮಿ',
'ನೀರುಬ್ರಾಹ್ಮಿ',
'ನೀರುಮಂಜಿ',
'ನೀರುಮಣ್ಣು',
'ನೀರುಮತ್ತಿ',
'ನೀರುಮನೆ',
'ನೀರುಮುಟ್ಟು',
'ನೀರುರುಳಿ',
'ನೀರುವಂಜಿ',
'ನೀರುವಕ್ಕಿ',
'ನೀರುವರಿ',
'ನೀರುವಲೆ',
'ನೀರುವಾಟೆ',
'ನೀರುವಾವು',
'ನೀರುಸಲ್ಲೆ',
'ನೀರುಸಿರಿ',
'ನೀರುಹಂದಿ',
'ನೀರುಹಂಬು',
'ನೀರುಹಕ್ಕಿ',
'ನೀರುಹಚ್ಚೆ',
'ನೀರುಹನಿ',
'ನೀರುಹಬ್ಬೆ',
'ನೀರುಹಾದಿ',
'ನೀರುಹಾವು',
'ನೀರುಹಿಪ್ಪೆ',
'ನೀರುಹೊಗೆ',
'ನೀರೂಡಿಸು',
'ನೀರೂಳಿಗ',
'ನೀರೆರಚು',
'ನೀರೆಸರು',
'ನೀರೇಜಿನಿ',
'ನೀರೇರಿಸು',
'ನೀರೇಱಿಸು',
'ನೀರೊಟ್ಟಿಲ್',
'ನೀರೊಟ್ಟಿಲು',
'ನೀರೊಡಲ',
'ನೀರೊಡೆತ',
'ನೀರೊತಿಗೆ',
'ನೀರೊರತೆ',
'ನೀರೊಱತೆ',
'ನೀರೋಗತೆ',
'ನೀರೋಗರ',
'ನೀರ್ಕುಟಿಕ',
'ನೀರ್ಕುಟಿಗ',
'ನೀರ್ಕುಡಿಹ',
'ನೀರ್ಗಡವ',
'ನೀರ್ಗಡವು',
'ನೀರ್ಗರುಳ',
'ನೀರ್ಗಾದಿಗೆ',
'ನೀರ್ಗಾರಿಗೆ',
'ನೀರ್ಗಾಲುವೆ',
'ನೀರ್ಗುಟಿಕ',
'ನೀರ್ಗುಟಿಗ',
'ನೀರ್ಗುಡಿಸು',
'ನೀರ್ದಂದಲ್',
'ನೀರ್ದಳಿಪ',
'ನೀರ್ದಿಗುರಿ',
'ನೀರ್ದುಮುಕು',
'ನೀರ್ದುಳುಕು',
'ನೀರ್ನೆರಳು',
'ನೀರ್ಪಡಿಸು',
'ನೀರ್ಮಗಳ್',
'ನೀರ್ಮಜ್ಜಿಗೆ',
'ನೀರ್ಮನ್ನೆಯ',
'ನೀರ್ಮುಳುಗ',
'ನೀರ್ಮುಳುಗು',
'ನೀರ್ವಟಿಗ',
'ನೀರ್ವತ್ತಿಗೆ',
'ನೀರ್ವನೆಯ',
'ನೀರ್ವುಗಿಲು',
'ನೀರ್ವೊಟ್ಟಣ',
'ನೀಲಕಂಠ',
'ನೀಲಕಂಠಿ',
'ನೀಲಕಾಂತಿ',
'ನೀಲಗಂಧಿ',
'ನೀಲಗಣ್ಣು',
'ನೀಲಗಳ',
'ನೀಲಗಾರ',
'ನೀಲಗಿರಿ',
'ನೀಲಗ್ರೀವ',
'ನೀಲಚುಂಬಿ',
'ನೀಲಚೇಲ',
'ನೀಲತಂತ್ರ',
'ನೀಲತುತ್ತೆ',
'ನೀಲನಕ್ಷೆ',
'ನೀಲಪಟ',
'ನೀಲಪಿಟ',
'ನೀಲಪುಷ್ಪ',
'ನೀಲಪ್ರತಿ',
'ನೀಲಮಣಿ',
'ನೀಲಮಲ್ಲಿ',
'ನೀಲರತ್ನ',
'ನೀಲವಣ್ಣ',
'ನೀಲವರ್ಣ',
'ನೀಲವೇಣಿ',
'ನೀಲಾಂಜನ',
'ನೀಲಾಂಬರ',
'ನೀಲಾಂಬರಿ',
'ನೀಲಾಂಬುಜ',
'ನೀಲಾಲಕಿ',
'ನೀಲಾವಳಿ',
'ನೀಲಾಳಕಿ',
'ನೀಲಾಳಕೆ',
'ನೀಲಿಗಂಧ',
'ನೀಲಿಗಟ್ಟು',
'ನೀಲಿಗಣ್',
'ನೀಲಿಗಣ್ಣು',
'ನೀಲಿಗಾಱ',
'ನೀಲಿಬಳ್ಳಿ',
'ನೀಲಿಮರ',
'ನೀಲಿಮಾತು',
'ನೀಲಿವಾರ್ತೆ',
'ನೀಲೋತ್ಪಲ',
'ನೀವರಣ',
'ನೀವಾಳಿಸು',
'ನೀವಿಬಂಧ',
'ನೀವೀಬಂಧ',
'ನೀವೀಭರ',
'ನೀಹಾರಕ',
'ನೀಹಾರಿಕೆ',
'ನೀಳಂಬಿಸು',
'ನೀಳಕಾಯ',
'ನೀಳಗಳ',
'ನೀಳಗಿರಿ',
'ನೀಳಚೇಳ',
'ನೀಳಪಟ',
'ನೀಳಮಣಿ',
'ನೀಳರತ್ನ',
'ನೀಳಾಳಕಿ',
'ನೀಳಾಳಕೆ',
'ನೀಳೋತ್ಪಳ',
'ನೀಳ್ಕರಿಸು',
'ನೀಳ್ಗವನ',
'ನೀಳ್ಗವಿತೆ',
'ನೀಳ್ಪುವಡೆ',
'ನೀಳ್ಪುವೆಱು',
'ನೀಱತನ',
'ನೀಱೆತನ',
'ನೀೞ್ಕರಿಸು',
'ನುಂಗುಂಗಣ್ಣು',
'ನುಂಪಡರ್',
'ನುಕಸಾನಿ',
'ನುಕಸಾನು',
'ನುಗುಳಿಸು',
'ನುಗ್ಗುಗುಟ್ಟು',
'ನುಗ್ಗುಗೆಯ್',
'ನುಗ್ಗುನುರಿ',
'ನುಗ್ಗುನುಸಿ',
'ನುಗ್ಗುನುಱಿ',
'ನುಗ್ಗುನೆರೆ',
'ನುಗ್ಗುಮೊನೆ',
'ನುಗ್ಗುವೊಯ್',
'ನುಚ್ಚುಗಳ್ಳ',
'ನುಚ್ಚುಗುಟ್ಟು',
'ನುಚ್ಚುಗೂಳು',
'ನುಚ್ಚುಗೂೞ್',
'ನುಚ್ಚುಗೋಣಿ',
'ನುಚ್ಚುಗೋಳಿ',
'ನುಚ್ಚುದಱಿ',
'ನುಚ್ಚುನುರಿ',
'ನುಚ್ಚುನುಱಿ',
'ನುಚ್ಚುನೂರು',
'ನುಚ್ಚುನೂಱು',
'ನುಚ್ಚುಬಡಿ',
'ನುಡಿಕಟ್ಟು',
'ನುಡಿಕಾರ',
'ನುಡಿಕಾರ್ತಿ',
'ನುಡಿಕಾಱ',
'ನುಡಿಕೊಡು',
'ನುಡಿಗಟ್ಟು',
'ನುಡಿಗಣ್ಣು',
'ನುಡಿಗಬ್ಬ',
'ನುಡಿಗಲ್',
'ನುಡಿಗಲಿ',
'ನುಡಿಗಾರ',
'ನುಡಿಗಾಱ',
'ನುಡಿಗುಂದು',
'ನುಡಿಗುಡು',
'ನುಡಿಗೆಡು',
'ನುಡಿಗೇಡು',
'ನುಡಿಗೇಳ್',
'ನುಡಿಗೇಳು',
'ನುಡಿಗೊಡು',
'ನುಡಿಜಾಣ',
'ನುಡಿಜಾಣೆ',
'ನುಡಿಜಾಣ್ಮೆ',
'ನುಡಿತಟ್ಟೆ',
'ನುಡಿದಪ್ಪು',
'ನುಡಿದೊಡು',
'ನುಡಿಯಾಟ',
'ನುಡಿಯಾಡು',
'ನುಡಿಯಿಸು',
'ನುಡಿಲಿಂಗ',
'ನುಡಿವಕ್ಕಿ',
'ನುಡಿವಡೆ',
'ನುಡಿವಲ್ಮೆ',
'ನುಡಿವಳಿ',
'ನುಡಿವಳ್ಳ',
'ನುಡಿವಾಳ',
'ನುಡಿವೆಣ್',
'ನುಡಿವೆಣ',
'ನುಡಿವೆಣ್ಣು',
'ನುಡಿವೇಟ',
'ನುಡಿಸಲ್ಗೆ',
'ನುಡಿಸುಹ',
'ನುಡಿಹೀನ',
'ನುಣಾಮರ',
'ನುಣ್ಗಟಕಿ',
'ನುಣ್ಗೊರಲ್',
'ನುಣ್ಗೊರಲ',
'ನುಣ್ಗೊರಲು',
'ನುಣ್ಗೊರಳ್',
'ನುಣ್ಗೊರಳ',
'ನುಣ್ಗೊರಳು',
'ನುಣ್ಣನೆಯ',
'ನುಣ್ಣಮಾತು',
'ನುಣ್ಪಡರ್',
'ನುಣ್ಪಡಸು',
'ನುಣ್ಪುವಡು',
'ನುಣ್ಪುವಡೆ',
'ನುಣ್ಪುವೆಱು',
'ನುತಿಗೆಯ್',
'ನುತಿಗೊಡು',
'ನುತಿಯಿಸು',
'ನುತಿವಡೆ',
'ನುತಿವಾದ',
'ನುತಿವೆಱು',
'ನುರಿನುಳ್ಗು',
'ನುರಿವಡೆ',
'ನುರ್ಗುಗುಟ್ಟು',
'ನುರ್ಗುನುಱಿ',
'ನುರ್ಗುನೂಂಕು',
'ನುರ್ಚುನುರಿ',
'ನುರ್ಚುನುಱಿ',
'ನುರ್ಚುನೂಱು',
'ನುಲಿಗಪ್ಪ',
'ನುಲಿಗೊಳ್ಳು',
'ನುಲಿಮಂಚ',
'ನುಲುವಾಲೆ',
'ನುಸಿಗೊಳ್',
'ನುಸಿಬಲೆ',
'ನುಸುಳಿಸು',
'ನುಸುಳ್ಗಂಡಿ',
'ನುಱುಕಿಸು',
'ನುಱುಗಿಸು',
'ನೂಂಕುದಳಿ',
'ನೂಂಕುದೞಿ',
'ನೂಕರಿಸು',
'ನೂಕಲಾಟ',
'ನೂಕುಬಂಡಿ',
'ನೂತಿಗಣ್ಣು',
'ನೂತಿವ್ರಣ',
'ನೂತಿಹುಣ್ಣು',
'ನೂರುಕಾಲ',
'ನೂರುಮಡಿ',
'ನೂಚ್ರ್ಛಾಸಿರ',
'ನೂರ್ಛಾಸಿರ',
'ನೂರ್ಮಡಿಸು',
'ನೂಲುಗಟ್ಟು',
'ನೂಲುಗುಂಡು',
'ನೂಲುಬಟ್ಟೆ',
'ನೂಲುಮಟ್ಟ',
'ನೂಲುಯಂತ್ರ',
'ನೂಲುವಸ್ತ್ರ',
'ನೂಲುವಾಲೆ',
'ನೂಲುವೊಯ್',
'ನೂಲೆಳೆಗ',
'ನೂಲ್ತೊಂಗಲ್',
'ನೂಱುಮಡಿ',
'ನೃತ್ತಹಸ್ತ',
'ನೃತ್ಯಕಲೆ',
'ನೃತ್ಯಗಾತಿ',
'ನೃತ್ಯಗಾರ',
'ನೃತ್ಯಗೀತೆ',
'ನೃತ್ಯಗೃಹ',
'ನೃತ್ಯಭಂಗಿ',
'ನೃತ್ಯವಿದ್ಯೆ',
'ನೃಪಧರ್ಮ',
'ನೃಪನೀತಿ',
'ನೃಪಭಾಗ',
'ನೃಪಲಕ್ಷ್ಮ',
'ನೃಪಲಕ್ಷ್ಮಿ',
'ನೃಪವಿದ್ಯೆ',
'ನೃಪಶಾಸ್ತ್ರ',
'ನೃಪಾಲಕ',
'ನೃಪಾಳಕ',
'ನೃವಾಹನ',
'ನೃಶಂಸತೆ',
'ನೆಂಟತನ',
'ನೆಂಟಸ್ತನ',
'ನೆಂಟಸ್ತಿಕೆ',
'ನೆಂಟಸ್ಥಿಕೆ',
'ನೆಂಟುತನ',
'ನೆಗಳಿಕೆ',
'ನೆಗಳಿಚು',
'ನೆಗಳಿಸು',
'ನೆಗಳ್ಚಿಸು',
'ನೆಗಳ್ದೇರ',
'ನೆಗಳ್ವಾಯಿ',
'ನೆಗೞಿಸು',
'ನೆಗೞ್ದೇರ',
'ನೆಗೆತರ್',
'ನೆಗೆಯಿಸು',
'ನೆಗೆವರಿ',
'ನೆಗೆವಾವು',
'ನೆಗೆವೆಣ್ಣೆ',
'ನೆಗೆಹೆಣ್ಣು',
'ನೆಚ್ಚುಕಡ್ಡಿ',
'ನೆಚ್ಚುಗೆಡು',
'ನೆಚ್ಚುಮೆಚ್ಚು',
'ನೆಜರಿಡು',
'ನೆಟ್ಟಿದೆಗೆ',
'ನೆಟ್ಟಿಮುರಿ',
'ನೆಟ್ಟೆಲುವು',
'ನೆಡುಗುರಿ',
'ನೆಡುತೋಪು',
'ನೆಡುಬಗೆ',
'ನೆಣಗೊಬ್ಬು',
'ನೆಣವಸೆ',
'ನೆತ್ತಕಾರ',
'ನೆತ್ತಗಾರ',
'ನೆತ್ತಗಾಱ',
'ನೆತ್ತಪಡಿ',
'ನೆತ್ತರ್ಗೆಂಪು',
'ನೆತ್ತರ್ಪಟ್ಟೆ',
'ನಿತ್ತರ್ಪಡಿ',
'ನೆತ್ತಸಾರಿ',
'ನೆತ್ತಿಗಣ್',
'ನೆತ್ತಿಗಣ್ಣ',
'ನೆತ್ತಿಬಾಯಿ',
'ನೆತ್ತಿಮುತ್ತು',
'ನೆತ್ತಿಯೊತ್ತು',
'ನೆತ್ತಿವಂದ',
'ನೆತ್ತಿವಣಿ',
'ನೆತ್ತಿವತ್ತು',
'ನೆತ್ತಿಹತ್ತು',
'ನೆದರಿಡು',
'ನೆದರೊಡೆ',
'ನೆನಪಿಡು',
'ನೆನಪಿಸು',
'ನೆನಪುಗ',
'ನೆನಹಳಿ',
'ನೆನೆಗಟ್ಟು',
'ನೆನೆಗುದಿ',
'ನೆನೆಯಕ್ಕಿ',
'ನೆನೆಯಿಕ್ಕು',
'ನೆನೆಯಿಡು',
'ನೆನೆಯಿಸು',
'ನೆನೆಹಾಕು',
'ನೆಪಗೆಯ್',
'ನೆಪತೆಗೆ',
'ನೆಪವೊಡ್ಡು',
'ನೆಪಹೇಳು',
'ನೆಪ್ಪುಗಾತಿ',
'ನೆಪ್ಪುಗಾರ',
'ನೆಪ್ಪುಗೊಳ್',
'ನೆಪ್ಪುದೋಱು',
'ನೆಪ್ಪುವಡೆ',
'ನೆಮ್ಮೆದಿಗ',
'ನೆಯಕಾರ',
'ನೆಯಿಕಾರ',
'ನೆಯಿದಲೆ',
'ನೆಯಿದಿಲು',
'ನೆಯ್ಕಾರಿಕೆ',
'ನೆಯ್ಗಡಲೆ',
'ನೆಯ್ಗೆಕಾಱ',
'ನೆಯ್ಗೆಗೊಳ್',
'ನೆಯ್ಯಿಳಿಸು',
'ನೆಯ್ಯೇರಿಸು',
'ನೆಯ್ಯೇಱಿಸು',
'ನೆರಂಗೆಯ್',
'ನೆರಂಬಡೆ',
'ನೆರಂಬರ್',
'ನೆರಂಬಾರ್',
'ನೆರಂಬಿಡಿ',
'ನೆರಂಬೆಱು',
'ನೆರಮನೆ',
'ನೆರವರ್',
'ನೆರವಲ್',
'ನೆರವಲ',
'ನೆರವಲು',
'ನೆರವಳೆ',
'ನೆರವಾನ್',
'ನೆರವಿಗ',
'ನೆರವೀಯು',
'ನೆರವುಟ್ಟು',
'ನೆರವೇರು',
'ನೆರಹಿಸು',
'ನೆರಿಗಂಟು',
'ನೆರಿಪಿಡಿ',
'ನೆರಿಹಿಡಿ',
'ನೆರೆಕೋಲು',
'ನೆರೆಗೊಳು',
'ನೆರೆತರ್',
'ನೆರೆದಲೆ',
'ನೆರೆನಂಬು',
'ನೆರೆನೀರು',
'ನೆರೆಮನೆ',
'ನೆರೆಯಿರ್',
'ನೆರೆಯಿಸು',
'ನೆರೆಯೂರ್',
'ನೆರೆಯೂರು',
'ನೆರೆವನೆ',
'ನೆರೆವರಿ',
'ನೆರೆವಾೞ್',
'ನೆರೆವೀಡು',
'ನೆರೆವೆರೆ',
'ನೆರೆಹೊರೆ',
'ನೆರೆಹೊಳೆ',
'ನೆರ್ಮುಗಂಭ',
'ನೆಲಕಂಚಿ',
'ನೆಲಕಚ್ಚು',
'ನೆಲಕಟ್ಟು',
'ನೆಲಕರ',
'ನೆಲಕಲ',
'ನೆಲಗಂಪು',
'ನೆಲಗಚ್ಚು',
'ನೆಲಗಟ್ಟು',
'ನೆಲಗತ',
'ನೆಲಗನ್ನ',
'ನೆಲಗಪ್ಪು',
'ನೆಲಗವಿ',
'ನೆಲಗಾಡು',
'ನೆಲಗಾಳಿ',
'ನೆಲಗುಣ',
'ನೆಲಗುಮ್ಮ',
'ನೆಲಗುಮ್ಮೆ',
'ನೆಲಗುಳ್ಳ',
'ನೆಲಗೂಡು',
'ನೆಲಗೂಲಿ',
'ನೆಲಗೆಡು',
'ನೆಲಗೊಟ್ಟಿ',
'ನೆಲಗೊದ್ದ',
'ನೆಲಗೋಣಿ',
'ನೆಲತಾಟಿ',
'ನೆಲತಾಯ್',
'ನೆಲತಾಯಿ',
'ನೆಲತಾಳಿ',
'ನೆಲತಾಳೆ',
'ನೆಲತೆಂಗು',
'ನೆಲತೋಳ',
'ನೆಲತೆರ',
'ನೆಲದಳ',
'ನೆಲದಳೆ',
'ನೆಲದಾಯ್',
'ನೆಲದಾಯಿ',
'ನೆಲದಾರು',
'ನೆಲದಾಳ',
'ನೆಲದಾಳಿ',
'ನೆಲದಾಳು',
'ನೆಲದಾಳೆ',
'ನೆಲದಾೞ್',
'ನೆಲದಿಟ್ಟಿ',
'ನೆಲದಿಡ್ಡಿ',
'ನೆಲದೆಂಗು',
'ನೆಲದೆಱೆ',
'ನೆಲದೊಂಡೆ',
'ನೆಲದೊಟ್ಟಿ',
'ನೆಲಧಾರೆ',
'ನೆಲಧೊರೆ',
'ನೆಲನಳ್ಳಿ',
'ನೆಲನೆಲ್ಲಿ',
'ನೆಲಪಡು',
'ನೆಲಪತಿ',
'ನೆಲಬರೆ',
'ನೆಲಬಾಗೆ',
'ನೆಲಬಾವಿ',
'ನೆಲಬಾಳ್',
'ನೆಲಬೇರು',
'ನೆಲಬೇವು',
'ನೆಲಮನೆ',
'ನೆಲಮರೆ',
'ನೆಲಮಾಟ',
'ನೆಲಮಾರ್ಗ',
'ನೆಲಮಾವು',
'ನೆಲಮುಕ್ಕ',
'ನೆಲಮುಕ್ಕು',
'ನೆಲಮುಟ್ಟು',
'ನೆಲಮೂಗಿ',
'ನೆಲಯಿಪ್ಪೆ',
'ನೆಲವಕ್ಕೆ',
'ನೆಲವಟ್ಟ',
'ನೆಲವತ್ತಿ',
'ನೆಲವನೆ',
'ನೆಲವರ',
'ನೆಲವಳಿ',
'ನೆಲವೞ್ಕೆ',
'ನೆಲವಾಸು',
'ನೆಲವಾಳ್',
'ನೆಲವಾಳು',
'ನೆಲವಿಡಿ',
'ನೆಲವೀಡು',
'ನೆಲವುಟ್ಟು',
'ನೆಲವುತ್ತು',
'ನೆಲವೆಂಬು',
'ನೆಲವೆಣ್',
'ನೆಲವೆಣ್ಣು',
'ನೆಲವೊಱೆ',
'ನೆಲಸಣ',
'ನೆಲಸಮ',
'ನೆಲಸರಿ',
'ನೆಲಸಿಂದ',
'ನೆಲಸಿಗ',
'ನೆಲಸುಗೆ',
'ನೆಲಹಾಗ',
'ನೆಲಹಾದಿ',
'ನೆಲಹಾಸು',
'ನೆಲಹಿಡಿ',
'ನೆಲಹೊನ್ನೆ',
'ನೆಲಾವರೆ',
'ನೆಲಿಂಗಳ',
'ನೆಲೆಕಟ್ಟು',
'ನೆಲೆಕೊಳ್',
'ನೆಲೆಗಂಡು',
'ನೆಲೆಗಟ್ಟು',
'ನೆಲೆಗಳೆ',
'ನೆಲೆಗಾಣ್',
'ನೆಲೆಗಾಣು',
'ನೆಲೆಗಿಡು',
'ನೆಲೆಗುಂದು',
'ನೆಲೆಗೆಡು',
'ನೆಲೆಗೆಯ್',
'ನೆಲೆಗೊಡು',
'ನೆಲೆಗೊಳ್',
'ನೆಲೆಗೊಳು',
'ನೆಲೆಗೊಳ್ಳು',
'ನೆಲೆತಪ್ಪು',
'ನೆಲೆದಪ್ಪು',
'ನೆಲೆದಾಣ',
'ನೆಲೆದೀಪ',
'ನೆಲೆದೆಗೆ',
'ನೆಲೆದೋಱು',
'ನೆಲೆನಿಲ್',
'ನೆಲೆನಿಲ್ಲು',
'ನೆಲೆಬಾೞ್',
'ನೆಲೆಬೀಡು',
'ನೆಲೆಬೀಳು',
'ನೆಲೆಬೆಲೆ',
'ನೆಲೆಮನೆ',
'ನೆಲೆಮಾಡ',
'ನೆಲೆಮುರಿ',
'ನೆಲೆಮೂಡು',
'ನೆಲೆಯರಿ',
'ನೆಲೆಯಱಿ',
'ನೆಲೆಯಾಂದೆ',
'ನೆಲೆಯಾಸೆ',
'ನೆಲೆಯೂರು',
'ನೆಲೆವಂತ',
'ನೆಲೆವಡೆ',
'ನೆಲೆವತ್ತು',
'ನೆಲೆವನೆ',
'ನೆಲೆವರು',
'ನೆಲೆವಾಡ',
'ನೆಲೆವಾತು',
'ನೆಲೆವಾಸು',
'ನೆಲೆವಾಳ್',
'ನೆಲೆವಾಳ್ತೆ',
'ನೆಲೆವಾೞ್',
'ನೆಲೆವಾೞ್ತೆ',
'ನೆಲೆವಿಡಿ',
'ನೆಲೆವೀಡು',
'ನೆಲೆವೆರ್ಚು',
'ನೆಲೆವೆಲೆ',
'ನೆಲೆವೆಱು',
'ನೆಲೆಹತ್ತು',
'ನೆಲೆಹೊಲ',
'ನೆಲೌಡಲ',
'ನೆಲ್ಲರಳು',
'ನೆಲ್ಲವರೆ',
'ನೆಲ್ಲಿಗೆಡ್ಡೆ',
'ನೆಲ್ಲಿಚಟ್ಟು',
'ನೆಲ್ಲಿಚೆಟ್ಟು',
'ನೆಲ್ಲಿಚೊಟ್ಟು',
'ನೆಲ್ಲಿಸರ',
'ನೆಲ್ಲುಗದ್ದೆ',
'ನೆಲ್ಲುಗೆಯ್',
'ನೆಲ್ಲುಮಡಿ',
'ನೆಲ್ಲೋಗರ',
'ನೆವಂಗೊಳ್',
'ನೆವಂಬಡೆ',
'ನೆವತೆಗೆ',
'ನೆವನೋಡು',
'ನೆವಮಿಕ್ಕು',
'ನೆವಮಿಡು',
'ನೆವವಂತ',
'ನೆವವಿಡು',
'ನೆವಹೇಳು',
'ನೆವೇಸಣ',
'ನೆಳದಾಳ',
'ನೆಳಲಂಚೆ',
'ನೆಳಲಿಸು',
'ನೆಱಗೊಳ್',
'ನೆಱವಟ್ಟೆ',
'ನೆಱಿವಿಡಿ',
'ನೆಱೆಕೊಳ್',
'ನೆಱೆಗೊಳ್',
'ನೆಱೆಯಿಸು',
'ನೆಱೆವೆಱೆ',
'ನೆೞಲಂಚೆ',
'ನೆೞಲ್ಪಚ್ಚೆ',
'ನೇಕರಿಸು',
'ನೇಕಾರಿತಿ',
'ನೇಗಿಲ್ವಾಯ್',
'ನೇಜಿನಡು',
'ನೇಜಿನೆಡು',
'ನೇಟುಸುದ್ದಿ',
'ನೇಣುಕಾಱ',
'ನೇಣುಕಾರ್ತಿ',
'ನೇಣುಗಂಬ',
'ನೇಣುಗಾರ',
'ನೇಣುಗಾಱ',
'ನೇಣುಗೊಲೆ',
'ನೇಣುರುಳು',
'ನೇಣುಶಿಕ್ಷೆ',
'ನೇಣುಹಗ್ಗ',
'ನೇಣುಹಾಕು',
'ನೇತಿಗಳೆ',
'ನೇತ್ರಗ್ರಾಹ್ಯ',
'ನೇತ್ರಚ್ಛದ',
'ನೇತ್ರತ್ರಯ',
'ನೇತ್ರಪಟ್ಟ',
'ನೇತ್ರಪೞಿ',
'ನೇತ್ರಬಂಧ',
'ನೇತ್ರಮೂಲಿ',
'ನೇತ್ರರುಜೆ',
'ನೇತ್ರರೋಗ',
'ನೇತ್ರವಟ್ಟೆ',
'ನೇತ್ರವಳಿ',
'ನೇತ್ರವಾಯು',
'ನೇತ್ರವೈದ್ಯ',
'ನೇತ್ರವೈದ್ಯೆ',
'ನೇತ್ರವ್ಯಾಧಿ',
'ನೇತ್ರಸೌಖ್ಯ',
'ನೇತ್ರಾಂಬರ',
'ನೇತ್ರಾತುರ',
'ನೇತ್ರಾವಳಿ',
'ನೇತ್ರಾವೞಿ',
'ನೇಪಿಲಗು',
'ನೇಮಂಬಡೆ',
'ನೇಮಂಬೊಱು',
'ನೇಮಕಾತಿ',
'ನೇಮಗಲೆ',
'ನೇಮಗಾರ',
'ನೇಮಗಾಱ',
'ನೇಮಗೊಳು',
'ನೇಮಣುಕಿ',
'ನೇಮಣೂಕ',
'ನೇಮಣೂಕಿ',
'ನೇಮಣೂಕು',
'ನೇಮದಟ್ಟು',
'ನೇಮದಳೆ',
'ನೇಮದಾಣ',
'ನೇಮವಂತ',
'ನೇಮವಳಿ',
'ನೇಮವೇಳು',
'ನೇಮಸೀಮೆ',
'ನೇಮಸುತ್ತು',
'ನೇಯಿವಾಕು',
'ನೇರಣಿಗ',
'ನೇರ್ಗೊಳಿಸು',
'ನೇರ್ಪಡಿಸು',
'ನೇರ್ಪುಗೊಳ್',
'ನೇರ್ಪುಗೊಳ್ಳು',
'ನೇರ್ಪುವಡು',
'ನೇರ್ಪುವಡೆ',
'ನೇರ್ಪುವೆಱು',
'ನೇರ್ವಡಿಸು',
'ನೇವರಿಕೆ',
'ನೇವರಿಸು',
'ನೇಸರ್ಗಲ್',
'ನೇಸರ್ಬಟ್ಟು',
'ನೇಹಂದಳೆ',
'ನೇಹಂಬಡೆ',
'ನೇಹಕಟ್ಟು',
'ನೇಹಗೆಯ್',
'ನೇಹದೋಟಿ',
'ನೇಹಾಸ್ಪದ',
'ನೇಹಾಸ್ಪದೆ',
'ನೈಚ್ಯಭಾವ',
'ನೈತಿಕತೆ',
'ನೈದಿಧ್ಯಾಸ',
'ನೈಮಿತ್ತಿಕ',
'ನೈಯಗ್ರೋಧ',
'ನೈಯಾಯಿಕ',
'ನೈಯೇರಿಸು',
'ನೈರಂತರ್ಯ',
'ನೈರ್ಋತ',
'ನೈರ್ಋತಿ',
'ನೈರ್ಋತ್ಯ',
'ನೈಲಂಗಲ',
'ನೈಶ್ರೇಯಸ',
'ನೈಸರ್ಗಿಕ',
'ನೈಸ್ತ್ರಿಂಶಿಕ',
'ನೊಂದಾಯಿಸು',
'ನೊಗಗೊಳ್',
'ನೊಗಪಟ್ಟಿ',
'ನೊಗಭಾರ',
'ನೊಗವರಿ',
'ನೊಗವಿಕ್ಕು',
'ನೊಗವೊರು',
'ನೊಗವೊಱು',
'ನೊಗಹಣ',
'ನೊಗೆಕೂಲಿ',
'ನೊಚ್ಚನಡೆ',
'ನೊಜೆಹುಲ್ಲು',
'ನೊಣಕಸ',
'ನೊಣಗೇಡಿ',
'ನೊಣಬುಲಿ',
'ನೊಣರೋಗ',
'ನೊಣಸುತ್ತ',
'ನೊಣೆನೆಕ್ಕು',
'ನೊತೆಹುಲ್ಲು',
'ನೊದೆಹುಲ್ಲು',
'ನೊರಕಾಟ',
'ನೊರಕಿಸು',
'ನೊರೆಕಾಯಿ',
'ನೊರೆಗಟ್ಟು',
'ನೊರೆಯಾರು',
'ನೊರೆಯಾಱು',
'ನೊರೆಯಿಡು',
'ನೊರೆವಾಲ್',
'ನೊರೆವಾಲು',
'ನೊರೆವೆರೆ',
'ನೊರೆವೇಱು',
'ನೊರೆಹಾಲು',
'ನೊಸಗೊನ್ನೆ',
'ನೊಸಲಿಕ್ಕು',
'ನೊಸಲ್ಗಣ್',
'ನೊಸಲ್ಗಣ್ಣ',
'ನೊಸೆಕಲ್',
'ನೊಸೆಹುಲ್ಲು',
'ನೋಂದಾಯಿಸು',
'ನೋಕನೀಯ',
'ನೋಟಂದೆಗೆ',
'ನೋಟಕಾತಿ',
'ನೋಟಕೂಟ',
'ನೋಟಗಾತಿ',
'ನೋಟಗಾರ',
'ನೋಟಬೇಟ',
'ನೋಟವಟ್ಟೆ',
'ನೋಟವಣ',
'ನೋಟಸನ್ನೆ',
'ನೋಟುಬುಕ್ಕು',
'ನೋಡುಗಂಡಿ',
'ನೋಣಿಸರ',
'ನೋಸಂಸಾರ',
'ನೌಕಾಂಗಣ',
'ನೌಕಾಗಾರ',
'ನೌಕಾದಂಡ',
'ನೌಕಾಪಡೆ',
'ನೌಕಾಬಲ',
'ನೌಕಾಯಾನ',
'ನೌಕಾರೋಹಿ',
'ನೌಕಾಶ್ರಯ',
'ನೌಕಾಸ್ಫೋಟ',
'ನೌವಾಲಾದಿ',
'ನೌಸಾಧನ',
'ನ್ಯಕ್ಕರಿಸು',
'ನ್ಯಸ್ತಭರ',
'ನ್ಯಾಪ್‍ಚಾಕ್',
'ನ್ಯಾಮದಡಿ',
'ನ್ಯಾಯಕಾರ',
'ನ್ಯಾಯಖಾತೆ',
'ನ್ಯಾಯಗಂಟ',
'ನ್ಯಾಯದರ್ಶಿ',
'ನ್ಯಾಯದಾತ',
'ನ್ಯಾಯದೂರ',
'ನ್ಯಾಯನಿಷ್ಠ',
'ನ್ಯಾಯಪೀಠ',
'ನ್ಯಾಯಬೆಲೆ',
'ನ್ಯಾಯಮಾರ್ಗ',
'ನ್ಯಾಯಮೂರ್ತಿ',
'ನ್ಯಾಯರಕ್ತ',
'ನ್ಯಾಯವಂತ',
'ನ್ಯಾಯವರ್ತಿ',
'ನ್ಯಾಯವಾಕ್ಯ',
'ನ್ಯಾಯವಾದಿ',
'ನ್ಯಾಯವಿದ',
'ನ್ಯಾಯವಿದ್ಯೆ',
'ನ್ಯಾಯಶಾಲೆ',
'ನ್ಯಾಯಶಾಸ್ತ್ರ',
'ನ್ಯಾಯಸ್ಥಾನ',
'ನ್ಯಾಯಾಧೀಶ',
'ನ್ಯಾಯಾಧ್ಯಕ್ಷ',
'ನ್ಯಾಯಾನ್ವಿತ',
'ನ್ಯಾಯಾರ್ಜಿತ',
'ನ್ಯಾಯಾಲಯ',
'ನ್ಯಾಯಾಸ್ಥಾನ',
'ನ್ಯಾಯ್ಯಯುಕ್ತ',
'ನ್ಯಾರೆಬತ್ತ',
'ನ್ಯಾವಣಿಸು',
'ನ್ಯಾವರಿಸು',
'ನ್ಯಾಸಕಾರ',
'ನ್ಯಾಸಕಾರಿ',
'ನ್ಯಾಸಧಾರಿ',
'ನ್ಯಾಸವಸ್ತು',
'ನ್ಯಾಸವಿದ್ಯೆ',
'ನ್ಯಾಸಸ್ವರ',
'ನ್ಯಾಸಾರ್ಪಣ',
'ನ್ಯುಮೋನಿಯ',
'ನ್ಯೂನಗಂಡ',
'ನ್ಯೂನಪದ',
'ನ್ಯೂನಪೂರ್ತಿ',
'ನ್ಯೂನಶ್ರುತಿ',
'ನ್ಯೂನಾಕ್ಷರ',
'ಪಂಕಜಾಕ್ಷ',
'ಪಂಕಜಿನಿ',
'ಪಂಕಪ್ರಭೆ',
'ಪಂಕರುಹ',
'ಪಂಕಿಲತೆ',
'ಪಂಕಿಳಿಸು',
'ಪಂಕೇರುಹ',
'ಪಂಕ್ತಿಭೇದ',
'ಪಂಕ್ತಿಭ್ರಷ್ಟ',
'ಪಂಕ್ತಿಯೋಗ್ಯ',
'ಪಂಗತಪ್ಪು',
'ಪಂಗನಾಮ',
'ಪಂಗಾವಣ',
'ಪಂಚಕಚ್ಚೆ',
'ಪಂಚಕರ್ಣ',
'ಪಂಚಕರ್ಮ',
'ಪಂಚಕಸೆ',
'ಪಂಚಕೃತ್ಯ',
'ಪಂಚಕೋಣ',
'ಪಂಚಕೋಲ',
'ಪಂಚಕೋಶ',
'ಪಂಚಕ್ಲೇಶ',
'ಪಂಚಕ್ಷೇತ್ರ',
'ಪಂಚಗಟ್ಟೆ',
'ಪಂಚಗವ್ಯ',
'ಪಂಚಗಾರ್ತಿ',
'ಪಂಚಗಾಲು',
'ಪಂಚಗುಪ್ತ',
'ಪಂಚಗುಲ್ಮ',
'ಪಂಚಗೌಡ',
'ಪಂಚಗೌಳ',
'ಪಂಚಘಂಟೆ',
'ಪಂಚಜನ',
'ಪಂಚತಂತ್ರ',
'ಪಂಚತತ್ತ್ವ',
'ಪಂಚತತ್ವ',
'ಪಂಚತಾರೆ',
'ಪಂಚತಿಕ್ತ',
'ಪಂಚದಶ',
'ಪಂಚದಶಿ',
'ಪಂಚದಾರ',
'ಪಂಚಧಾರೆ',
'ಪಂಚನಾಮೆ',
'ಪಂಚಪತ್ರ',
'ಪಂಚಪತ್ರೆ',
'ಪಂಚಪರ್ಬ',
'ಪಂಚಪರ್ವ',
'ಪಂಚಪಾತ್ರೆ',
'ಪಂಚಪಾಳೆ',
'ಪಂಚಪಿತೃ',
'ಪಂಚಪೀರ',
'ಪಂಚಪ್ರಮೆ',
'ಪಂಚಪ್ರಾಣ',
'ಪಂಚಬಲಿ',
'ಪಂಚಬಾಣ',
'ಪಂಚಬಾಬು',
'ಪಂಚಭಕ್ಷ',
'ಪಂಚಭಕ್ಷ್ಯ',
'ಪಂಚಭುಜ',
'ಪಂಚಭುಜಿ',
'ಪಂಚಭೂತ',
'ಪಂಚಮಲ',
'ಪಂಚಮಾಯೆ',
'ಪಂಚಮಿಗ',
'ಪಂಚಮಿಸೆ',
'ಪಂಚಮುಖ',
'ಪಂಚಮುಖಿ',
'ಪಂಚಮೂಲ',
'ಪಂಚಯಜ್ಞ',
'ಪಂಚರಂಗ',
'ಪಂಚರಂಗಿ',
'ಪಂಚರತ್ನ',
'ಪಂಚರಸ',
'ಪಂಚರಸಿ',
'ಪಂಚರಾಶಿ',
'ಪಂಚಲಿಂಗ',
'ಪಂಚಲೋಭಿ',
'ಪಂಚಲೋಹ',
'ಪಂಚವಟಿ',
'ಪಂಚವಣ್ಣ',
'ಪಂಚವರ್ಗ',
'ಪಂಚವರ್ಣ',
'ಪಂಚವಳ್ಳಿ',
'ಪಂಚವಾಯು',
'ಪಂಚವಾಳ',
'ಪಂಚವೃತ್ತಿ',
'ಪಂಚಶಾಖ',
'ಪಂಚಶಾಖೆ',
'ಪಂಚಶೀಲ',
'ಪಂಚಶೂಲೆ',
'ಪಂಚಸೂನ',
'ಪಂಚಾಂಗುಲ',
'ಪಂಚಾಕ್ಷರ',
'ಪಂಚಾಕ್ಷರಿ',
'ಪಂಚಾಚಾರ್ಯ',
'ಪಂಚಾನನ',
'ಪಂಚಾಮೃತ',
'ಪಂಚಾಯತ',
'ಪಂಚಾಯತಿ',
'ಪಂಚಾಯಿತ',
'ಪಂಚಾಯಿತಿ',
'ಪಂಚಾರತಿ',
'ಪಂಚಾರಿಸು',
'ಪಂಚಾಲಿಕೆ',
'ಪಂಚೀಕೃತ',
'ಪಂಚೀಕೃತಿ',
'ಪಂಚೇಂದ್ರಿಯ',
'ಪಂಜಂಹಿಸ್ಸೆ',
'ಪಂಜರಿಸು',
'ಪಂಜರುಳ್ಳಿ',
'ಪಂಜುಬಳೆ',
'ಪಂಜುರುಳಿ',
'ಪಂಜುಸೇವೆ',
'ಪಂಜುಹಚ್ಚು',
'ಪಂಜೆತನ',
'ಪಂಜೇವಾರ್',
'ಪಂಟುನುಡಿ',
'ಪಂಟುಬಡಿ',
'ಪಂಡುಬಡೆ',
'ಪಂಡುಹಚ್ಚು',
'ಪಂಟುಹೊಡೆ',
'ಪಂಡಾಪೂರ್ವ',
'ಪಂಡಿತತ್ವ',
'ಪಂಡಿತಿಕೆ',
'ಪಂಡಿತಿಕ್ಕೆ',
'ಪಂತಂಗಟ್ಟು',
'ಪಂತಕಟ್ಟು',
'ಪಂತಗಾರ',
'ಪಂತಗಾರ್ತಿ',
'ಪಂತಗಾಱ',
'ಪಂತಪಾಡು',
'ಪಂತಮಣೆ',
'ಪಂತವೊಡ್ಡು',
'ಪಂತಹಾಕು',
'ಪಂತಿಕಾರ',
'ಪಂತಿಕಾಱ',
'ಪಂತಿಗಟ್ಟು',
'ಪಂತಿಗೊಡು',
'ಪಂತಿಗೊಳ್',
'ಪಂತಿಯೂಟ',
'ಪಂತಿವಿಡಿ',
'ಪಂಥಗಾರ',
'ಪಂಥಗಾಱ',
'ಪಂಥತೊಡು',
'ಪಂಥಪಾಡು',
'ಪಂಥವಾಡು',
'ಪಂದರಿಕ್ಕು',
'ಪಂದರಿಸು',
'ಪಂದಲಿಡು',
'ಪಂದಲಿಸು',
'ಪಂದಳಿಗೆ',
'ಪಂದಳಿರ್',
'ಪಂದಿಗಡ್ಡೆ',
'ಪಂದಿದಲೆ',
'ಪಂದಿಪುಟ್ಟು',
'ಪಂದಿಪೊಟ್ಟು',
'ಪಂದಿಪೋಟು',
'ಪಂದಿಮೀನ್',
'ಪಂದೆತನ',
'ಪಂದೊಗಲ್',
'ಪಂದೊವಲ್',
'ಪಂದ್ಯಕಟ್ಟು',
'ಪಂಬಲಿಸು',
'ಪಂಬಸಲೆ',
'ಪಕಾಯಿಸು',
'ಪಕ್ಕಂಬಿಡಿ',
'ಪಕ್ಕಗಂಟೆ',
'ಪಕ್ಕಗೊಳ್',
'ಪಕ್ಕಘಂಟೆ',
'ಪಕ್ಕಜಲ್ಲಿ',
'ಪಕ್ಕರಕ್ಕೆ',
'ಪಕ್ಕರಗೆ',
'ಪಕ್ಕರಿಕೆ',
'ಪಕ್ಕರಿಗೆ',
'ಪಕ್ಕರಿಸು',
'ಪಕ್ಕವಣಿ',
'ಪಕ್ಕವಾದ್ಯ',
'ಪಕ್ಕಶೂಲೆ',
'ಪಕ್ಕಸಾರಿ',
'ಪಕ್ಕಸಾರೆ',
'ಪಕ್ಕಾವಹಿ',
'ಪಕ್ಕಿದೇರ',
'ಪಕ್ಕಿವಣೆ',
'ಪಕ್ಕುಗಾಣ್',
'ಪಕ್ಕುಗುಡು',
'ಪಕ್ಕುಗೊಡು',
'ಪಕ್ಕುಮಾಡು',
'ಪಕ್ಕುವಣಿ',
'ಪಕ್ಕುವಣೆ',
'ಪಕ್ಕುವಾರು',
'ಪಕ್ಕುವಿಡಿ',
'ಪಕ್ಕುವೆಱು',
'ಪಕ್ಕೂವಣಿ',
'ಪಕ್ಕೆದಲೆ',
'ಪಕ್ಕೆಮನೆ',
'ಪಕ್ಕೆರಕ್ಕೆ',
'ಪಕ್ಕೆವನೆ',
'ಪಕ್ಕೆವಾಡ',
'ಪಕ್ಕೆವೆಟ್ಟ',
'ಪಕ್ಕೆವೆಟ್ಟು',
'ಪಕ್ಕೆಶೂಲೆ',
'ಪಕ್ಕೆಸಾರಿ',
'ಪಕ್ಕೆಸಾರೆ',
'ಪಕ್ಖಾಳನ',
'ಪಕ್ವಂದಾಳ್',
'ಪಕ್ವಂಬೆಱು',
'ಪಕ್ವಗೊಳ್ಳು',
'ಪಕ್ವಜ್ವರ',
'ಪಕ್ವಫಲ',
'ಪಕ್ವಮತಿ',
'ಪಕ್ವರಕ್ತ',
'ಪಕ್ವಸ್ಥಿತಿ',
'ಪಕ್ವಾವಸ್ಥೆ',
'ಪಕ್ವಾಶಯ',
'ಪಕ್ಷಂಗೆಡು',
'ಪಕ್ಷಕಾರ',
'ಪಕ್ಷತ್ಯಾಗ',
'ಪಕ್ಷದ್ಯೋತ',
'ಪಕ್ಷದ್ವಯ',
'ಪಕ್ಷದ್ವಾರ',
'ಪಕ್ಷಧರ',
'ಪಕ್ಷಪಾತ',
'ಪಕ್ಷಪಾತಿ',
'ಪಕ್ಷಭೇದ',
'ಪಕ್ಷಭ್ರಮೆ',
'ಪಕ್ಷಮುಖ',
'ಪಕ್ಷಮೂಲ',
'ಪಕ್ಷರಕ್ಷೆ',
'ಪಕ್ಷವಧ',
'ಪಕ್ಷವಾತ',
'ಪಕ್ಷವಾಸ',
'ಪಕ್ಷಶೂಲೆ',
'ಪಕ್ಷಸಿದ್ಧಿ',
'ಪಕ್ಷಾಂತರ',
'ಪಕ್ಷಾವೇಶಿ',
'ಪಕ್ಷಿಗ್ರಹ',
'ಪಕ್ಷಿಜ್ಯೇಷ್ಠ',
'ಪಕ್ಷಿನೋಟ',
'ಪಕ್ಷಿಯಾನ',
'ಪಕ್ಷಿರಥ',
'ಪಕ್ಷಿರಾಜ',
'ಪಕ್ಷೀಕರ',
'ಪಕ್ಷ್ಮಪಾಳಿ',
'ಪಕ್ಷ್ಮಮಾಲೆ',
'ಪಗಂಡಿಱಿ',
'ಪಗಂಡೇೞ್',
'ಪಗದಸ್ತು',
'ಪಗರಣ',
'ಪಗರಣೆ',
'ಪಗಲಾಣ್ಮ',
'ಪಗಲುಳ್ಕ',
'ಪಗಲುಳ್ಕು',
'ಪಗಲ್ಗಳ್ಳ',
'ಪಗಲ್ವಕ್ಕಿ',
'ಪಗೆಕಾಱ',
'ಪಗೆಗಟ್ಟು',
'ಪಗೆಗಾಱ',
'ಪಗೆಗೊಳ್',
'ಪಗೆತನ',
'ಪಗೆವಡೆ',
'ಪಗೆವಾಡಿ',
'ಪಗೆಸುಯ್',
'ಪಚಂಪಚೆ',
'ಪಚನಗೈ',
'ಪಚನಿಸು',
'ಪಚಾರಿಸು',
'ಪಚಿಯಿಸು',
'ಪಚ್ಚಕ್ಖಾಣ',
'ಪಚ್ಚಖ್ಖಾಣ',
'ಪಚ್ಚಗಾಱ',
'ಪಚ್ಚಡಕೆ',
'ಪಚ್ಚಡಿಕೆ',
'ಪಚ್ಚಡಿಸು',
'ಪಚ್ಚನೆಯ',
'ಪಚ್ಚಪಸಿ',
'ಪಚ್ಚಬಾಳೆ',
'ಪಚ್ಚಯಿಲ',
'ಪಚ್ಚರಿಸು',
'ಪಚ್ಚವಡ',
'ಪಚ್ಚವಣ',
'ಪಚ್ಚವಳ',
'ಪಚ್ಚಳಿತಿ',
'ಪಚ್ಚಾಯಿಲ',
'ಪಚ್ಚುಕುಡು',
'ಪಚ್ಚುಕೊಡು',
'ಪಚ್ಚುಕೊಳ್',
'ಪಚ್ಚುಗುಡು',
'ಪಚ್ಚುಗೂಟ',
'ಪಚ್ಚುಗೊಡು',
'ಪಚ್ಚುಗೊಳ್',
'ಪಚ್ಚುನುಡಿ',
'ಪಚ್ಚೆಕಾಯಿ',
'ಪಚ್ಚೆಗಂಬಿ',
'ಪಚ್ಚೆಗಾಯ್',
'ಪಚ್ಚೆಗಾಱ',
'ಪಚ್ಚೆತೈಲ',
'ಪಚ್ಚೆಬಾಳೆ',
'ಪಚ್ಚೆವಿಕೆ',
'ಪಚ್ಚೇಕಾಯಿ',
'ಪಚ್ಚೇತೆನೆ',
'ಪಜ್ಜಳಿಕೆ',
'ಪಜ್ಜಳಿಸು',
'ಪಜ್ಜೆಕಾಱ',
'ಪಜ್ಜೆಗೆಡು',
'ಪಜ್ಜೆದೋಱು',
'ಪಜ್ಜೆಯಿಡು',
'ಪಜ್ಜೆವಟ್ಟೆ',
'ಪಜ್ಜೆವೞಿ',
'ಪಜ್ಜೆವಿಡಿ',
'ಪಜ್ಜೆಹಾಕು',
'ಪಜ್ಝಟಿಕೆ',
'ಪಟಂಗೊಳ್',
'ಪಟಕಣ್ಣಿ',
'ಪಟಕಾರ',
'ಪಟಕಾರು',
'ಪಟಕಾಸು',
'ಪಟಕಿಸು',
'ಪಟಕುಟಿ',
'ಪಟಕೋಶಿ',
'ಪಟಕ್ಕನೆ',
'ಪಟಗಣಿ',
'ಪಟಗಣ್ಣಿ',
'ಪಟಗಾರ',
'ಪಟಗಾಱ',
'ಪಟಗೃಹ',
'ಪಟಚ್ಚರ',
'ಪಟತ್ಕಾರ',
'ಪಟಲಕ',
'ಪಟಲಾಂತ',
'ಪಟಲಿಕೆ',
'ಪಟವಾಸ',
'ಪಟವಿದ್ದೆ',
'ಪಟವಿದ್ಯೆ',
'ಪಟವೇಂಟೆ',
'ಪಟಶಾಲೆ',
'ಪಟಸಾಲೆ',
'ಪಟಸ್ತಂಭ',
'ಪಟಹಾರಿ',
'ಪಟಳಕ',
'ಪಟಳಿಕೆ',
'ಪಟಾಂಗಣ',
'ಪಟಾಟೋಪ',
'ಪಟಾಟೋಪು',
'ಪಟಾಪಟಿ',
'ಪಟಾಯಿತ',
'ಪಟಾಯಿಸು',
'ಪಟಾರನೆ',
'ಪಟಾಲಮು',
'ಪಟಾಲಮ್ಮ',
'ಪಟಾಲಮ್ಮು',
'ಪಟಾವಳಿ',
'ಪಟಾವುಜ',
'ಪಟಿಕಾರ',
'ಪಟಿಗಾರ',
'ಪಟೀರನೆ',
'ಪಟುಗೆಯ್',
'ಪಟುತನ',
'ಪಟುಪರ್ಣಿ',
'ಪಟುಭಟ',
'ಪಟುಮತಿ',
'ಪಟುವೆರು',
'ಪಟೇಲಿಕೆ',
'ಪಟೋಲಿಕೆ',
'ಪಟೋಳಿಕೆ',
'ಪಟ್ಟಂಗಟ್ಟು',
'ಪಟ್ಟಂಗೊಳ್',
'ಪಟ್ಟಂಪಾಱು',
'ಪಟ್ಟಂಬಾಱು',
'ಪಟ್ಟಕಟ್ಟು',
'ಪಟ್ಟಕರ್ಮ',
'ಪಟ್ಟಕಾರ',
'ಪಟ್ಟಕ್ರಮ',
'ಪಟ್ಟಗಟ್ಟು',
'ಪಟ್ಟಗಾಱ',
'ಪಟ್ಟಗುಚ್ಛ',
'ಪಟ್ಟಗೊಱೆ',
'ಪಟ್ಟಣಿಗ',
'ಪಟ್ಟಣಿಗೆ',
'ಪಟ್ಟಬಂಧ',
'ಪಟ್ಟಭದ್ರ',
'ಪಟ್ಟಮಾರ್ಗ',
'ಪಟ್ಟರಾಜ',
'ಪಟ್ಟವಣೆ',
'ಪಟ್ಟವಸ್ತ್ರ',
'ಪಟ್ಟವಳಿ',
'ಪಟ್ಟವೞಿ',
'ಪಟ್ಟವಾಳಿ',
'ಪಟ್ಟವೇರು',
'ಪಟ್ಟವೇಱು',
'ಪಟ್ಟಶರ',
'ಪಟ್ಟಶಾಲಿ',
'ಪಟ್ಟಶಾಲೆ',
'ಪಟ್ಟಶಾಳೆ',
'ಪಟ್ಟಶಿಷ್ಯ',
'ಪಟ್ಟಸರ',
'ಪಟ್ಟಸಾಲೆ',
'ಪಟ್ಟಸಾಳಿ',
'ಪಟ್ಟಸೂತ್ರ',
'ಪಟ್ಟಳಿಗೆ',
'ಪಟ್ಟಾಂಬರ',
'ಪಟ್ಟಾಂಶುಕ',
'ಪಟ್ಟಾದಾರ್',
'ಪಟ್ಟಾಧ್ವರ',
'ಪಟ್ಟಾಯಿಲ',
'ಪಟ್ಟಾವಳಿ',
'ಪಟ್ಟಾವೞಿ',
'ಪಟ್ಟಾವುಜ',
'ಪಟ್ಟಾವೊಳಿ',
'ಪಟ್ಟಿಕಾಱ',
'ಪಟ್ಟಿವೀಳ್ಯ',
'ಪಟ್ಟಿಶಕ',
'ಪಟ್ಟಿಹೊಡೆ',
'ಪಟ್ಟುಕಂಥೆ',
'ಪಟ್ಟುಕಾಱ',
'ಪಟ್ಟುಗಂಥೆ',
'ಪಟ್ಟುಗಟ್ಟು',
'ಪಟ್ಟುದಲೆ',
'ಪಟ್ಟುನೂಲು',
'ಪಟ್ಟುವಸ್ತ್ರ',
'ಪಟ್ಟುಹಾಕು',
'ಪಟ್ಟುಹಿಡಿ',
'ಪಟ್ಟೆಗಾರ',
'ಪಟ್ಟೆಗಾಱ',
'ಪಟ್ಟೆಜಲ್ಲಿ',
'ಪಟ್ಟೆಝಲ್ಲಿ',
'ಪಟ್ಟೆದಾರ',
'ಪಟ್ಟೆಮಡಿ',
'ಪಟ್ಟೆವಣೆ',
'ಪಟ್ಟೆವಾಲಾ',
'ಪಟ್ಟೆವುಲಿ',
'ಪಟ್ಟೆವೊಯ್',
'ಪಟ್ಟೆಶಾಲು',
'ಪಟ್ಟೆಶಾಲೆ',
'ಪಟ್ಟೆಸಾಲೆ',
'ಪಟ್ಟೆಸೀರೆ',
'ಪಟ್ಟೆಹಾವು',
'ಪಟ್ಟೆಹುಲಿ',
'ಪಟ್ಠಾವಳಿ',
'ಪಠಾವಳಿ',
'ಪಠಿಯಿಸು',
'ಪಠಿಲ್ಲನೆ',
'ಪಠ್ಠಾವಳಿ',
'ಪಠ್ಯಕ್ರಮ',
'ಪಡಕಣ',
'ಪಡಕಾನೆ',
'ಪಡಖಾನೆ',
'ಪಡಡಕ್ಕೆ',
'ಪಡಢಕ್ಕೆ',
'ಪಡಣಿಗೆ',
'ಪಡತರ',
'ಪಡನೆಲ',
'ಪಡಪಾಳಿ',
'ಪಡಪುೞಿ',
'ಪಡಪೋಶಿ',
'ಪಡಪೋಷಿ',
'ಪಡಪೋಸಿ',
'ಪಡಬೀಳು',
'ಪಡಭೂಮಿ',
'ಪಡಮೂಲೆ',
'ಪಡಲಗೆ',
'ಪಡಲಿಕೆ',
'ಪಡಲಿಗೆ',
'ಪಡಲಿಡು',
'ಪಡಲ್ಪಡು',
'ಪಡಲ್ವಡು',
'ಪಡವಂದ',
'ಪಡವಲ',
'ಪಡವಲಿ',
'ಪಡವಲು',
'ಪಡವಳ',
'ಪಡವಳಿ',
'ಪಡವಳ್ತಿ',
'ಪಡವಳ್ಳ',
'ಪಡಶಾಲೆ',
'ಪಡಸಾದ',
'ಪಡಸಾಲೆ',
'ಪಡಸಾಳಿ',
'ಪಡಳಿಕೆ',
'ಪಡಾವಲ',
'ಪಡಾವಳ',
'ಪಡಿಕಟ್ಟು',
'ಪಡಿಕರ',
'ಪಡಿಕಲ್ಲು',
'ಪಡಿಕೂಳು',
'ಪಡಿಗಟ್ಟು',
'ಪಡಿಗದ',
'ಪಡಿಗಾಣ್',
'ಪಡಿಗಿಱು',
'ಪಡಿಗುಂಜಿ',
'ಪಡಿಗೊಳ್',
'ಪಡಿಗ್ಗಹ',
'ಪಡಿಚಂದ',
'ಪಡಿಚರ',
'ಪಡಿಚಾಯೆ',
'ಪಡಿಛಂದ',
'ಪಡಿತನ',
'ಪಡಿತರ',
'ಪಡಿತಳ',
'ಪಡಿತಾಳ',
'ಪಡಿತೊತ್ತು',
'ಪಡಿದಂಡಿ',
'ಪಡಿದನಿ',
'ಪಡಿದೞ',
'ಪಡಿದೆಗೆ',
'ಪಡಿದೆಱೆ',
'ಪಡಿದೊತ್ತು',
'ಪಡಿದೊರೆ',
'ಪಡಿನುಡಿ',
'ಪಡಿನೋಡು',
'ಪಡಿಪಚ್ಚ',
'ಪಡಿಪಚ್ಛ',
'ಪಡಿಪತ್ರ',
'ಪಡಿಪಾಟ',
'ಪಡಿಪಾಟು',
'ಪಡಿಪಾಟ್ಲು',
'ಪಡಿಪಾಡು',
'ಪಡಿಪುಚ್ಚ',
'ಪಡಿಪುಚ್ಛ',
'ಪಡಿಬಂದ',
'ಪಡಿಬಲ',
'ಪಡಿಬಳ',
'ಪಡಿಬಿಂಬ',
'ಪಡಿಭತ್ಯ',
'ಪಡಿಮದ್ದು',
'ಪಡಿಮಾಟ',
'ಪಡಿಮಾತು',
'ಪಡಿಮಿಡಿ',
'ಪಡಿಮುಖ',
'ಪಡಿಮೂಡು',
'ಪಡಿಮೇಲ್',
'ಪಡಿಮೊಗ',
'ಪಡಿಯಚ್ಚು',
'ಪಡಿಯರ',
'ಪಡಿಯಳೆ',
'ಪಡಿಯಱ',
'ಪಡಿಯಾಳು',
'ಪಡಿಯಾಱ',
'ಪಡಿಯಿಡು',
'ಪಡಿಯೊರೆ',
'ಪಡಿರೂಪ',
'ಪಡಿರೂಪು',
'ಪಡಿವಂದ',
'ಪಡಿವಡೆ',
'ಪಡಿವರ್',
'ಪಡಿವಳ',
'ಪಡಿವಾಘೆ',
'ಪಡಿವಾತು',
'ಪಡಿವಾವಿ',
'ಪಡಿವಿಡಿ',
'ಪಡಿವೆಸ',
'ಪಡಿವೊನ್',
'ಪಡಿಸಣ',
'ಪಡಿಸಣಿ',
'ಪಡಿಸನ್ನೆ',
'ಪಡಿಸಾದ',
'ಪಡಿಸಾಳ',
'ಪಡಿಸೂಳು',
'ಪಡಿಹರಿ',
'ಪಡಿಹಱ',
'ಪಡಿಹಱಿ',
'ಪಡಿಹಾರಿ',
'ಪಡಿಹಾಱ',
'ಪಡಿಹಾಱಿ',
'ಪಡಿಹೊನ್ನು',
'ಪಡುಕುಳಿ',
'ಪಡುಕೋಣೆ',
'ಪಡುಗಡೆ',
'ಪಡುತರ್',
'ಪಡುದೆಸೆ',
'ಪಡುಪಾಟು',
'ಪಡುಪಾಟ್ಲು',
'ಪಡುಪಾಡು',
'ಪಡುಬೀಳು',
'ಪಡುಭೂಮಿ',
'ಪಡುಮಲೆ',
'ಪಡುಮಿಗ',
'ಪಡುವಣ್',
'ಪಡುವಣ',
'ಪಡುವಯ್',
'ಪಡುವಲ',
'ಪಡುವಾಯ್',
'ಪಡುವೆಟ್ಟ',
'ಪಡುವೆಟ್ಟು',
'ಪಡುಶಿಲೆ',
'ಪಡುಸಾಲೆ',
'ಪಡುಸಿಲೆ',
'ಪಡೆಕಳಿ',
'ಪಡೆಗವಿ',
'ಪಡೆಗೆಯ್',
'ಪಡೆಢಕ್ಕೆ',
'ಪಡೆನುಡಿ',
'ಪಡೆಪಾಳಿ',
'ಪಡೆಮಾತು',
'ಪಡೆಯಿಲ',
'ಪಡೆಯಿಸು',
'ಪಡೆವಳ',
'ಪಡೆವಳ್ತಿ',
'ಪಡೆವಳ್ಳ',
'ಪಡೆವಾತು',
'ಪಡೆವಾಳ',
'ಪಡ್ಡಲಿಗೆ',
'ಪಡ್ಡಳಿಗೆ',
'ಪಣಂಗುಳಿ',
'ಪಣಕಟ್ಟು',
'ಪಣಕಾಂತೆ',
'ಪಣಕ್ಕನೆ',
'ಪಣಗೊಳ್',
'ಪಣತೀಸ',
'ಪಣತೊಡು',
'ಪಣವಕ',
'ಪಣವಧು',
'ಪಣವೊಡ್ಡು',
'ಪಣಾಯಿತ',
'ಪಣಾರ್ಪಣ',
'ಪಣಿಕಟ್ಟು',
'ಪಣಿತವ್ಯ',
'ಪಣಿದರ',
'ಪಣಿಯಾರ',
'ಪಣಿಯಾಲ',
'ಪಣಿವಾರ',
'ಪಣೆಕಟ್ಟು',
'ಪಣೆಗಟ್ಟು',
'ಪಣೆಗಣ್',
'ಪಣೆಗಣ್ಣ',
'ಪಣೆಗಣ್ಣು',
'ಪಣೆಮಣಿ',
'ಪಣೆಯಕ್ಕಿ',
'ಪಣೆಯಿಕ್ಕು',
'ಪಣೆಯಿಡು',
'ಪಣ್ಗೊಳಿಸು',
'ಪಣ್ಣುದೋಱು',
'ಪಣ್ಯಕರ್ಮ',
'ಪಣ್ಯಕಾವ್ಯ',
'ಪಣ್ಯದ್ರವ್ಯ',
'ಪಣ್ಯನಾರಿ',
'ಪಣ್ಯವಧು',
'ಪಣ್ಯವಸ್ತು',
'ಪಣ್ಯವೀಥಿ',
'ಪಣ್ಯವೀಧಿ',
'ಪಣ್ಯಾಂಗನೆ',
'ಪಣ್ಯಾಜೀವ',
'ಪಣ್ಯಾಜೀವಿ',
'ಪತಂಗಿಕೆ',
'ಪತಂಗಿಸು',
'ಪತದ್ಗ್ರಹ',
'ಪತಯಾಲು',
'ಪತಯಾಳು',
'ಪತಾಕಿಕೆ',
'ಪತಾಕಿನಿ',
'ಪತಿಂವರೆ',
'ಪತಿಕರ',
'ಪತಿತತ್ವ',
'ಪತಿಬಿಂಬ',
'ಪತಿಬ್ರತೆ',
'ಪತಿಭಕ್ತಿ',
'ಪತಿಭಕ್ತೆ',
'ಪತಿರಾಯ',
'ಪತಿವಂತೆ',
'ಪತಿವತ್ನಿ',
'ಪತಿವ್ರತ',
'ಪತಿವ್ರತೆ',
'ಪತ್ಕರಿಸು',
'ಪತ್ತನಾಜೆ',
'ಪತ್ತವಂದ',
'ಪತ್ತಳಿಕೆ',
'ಪತ್ತಳಿಗ',
'ಪತ್ತಿಗಾರ',
'ಪತ್ತಿವರ್ಗ',
'ಪತ್ತುಗಾರ',
'ಪತ್ತುಗುಡು',
'ಪತ್ತುಗೇಡು',
'ಪತ್ತುಗೊಳ್',
'ಪತ್ತುವಳಿ',
'ಪತ್ತುವಿಡಿ',
'ಪತ್ತುವಿಡು',
'ಪತ್ತೆದಾರ',
'ಪತ್ತೆದಾರಿ',
'ಪತ್ತೆಪಾಯ್',
'ಪತ್ತೆವಿಡಿ',
'ಪತ್ತೆವೊಯ್',
'ಪತ್ತೆಸಾರ್',
'ಪತ್ತೇಮಾರಿ',
'ಪತ್ನೀಭಾಗ',
'ಪತ್ನೀಶಾಲೆ',
'ಪತ್ರಕರ್ತ',
'ಪತ್ರಕರ್ತೆ',
'ಪತ್ರಚ್ಛದ',
'ಪತ್ರಚ್ಛೇದ',
'ಪತ್ರಚ್ಛೇದ್ಯ',
'ಪತ್ರಪಡಿ',
'ಪತ್ರಪಾಲಿ',
'ಪತ್ರಪಾಳಿ',
'ಪತ್ರಪುಟ',
'ಪತ್ರಭಂಗ',
'ಪತ್ರರಥ',
'ಪತ್ರರೇಖೆ',
'ಪತ್ರಲೇಖೆ',
'ಪತ್ರವತಿ',
'ಪತ್ತವಲ್ಲಿ',
'ಪತ್ರವಾದ್ಯ',
'ಪತ್ರಾಂಕುರ',
'ಪತ್ರಾಂಗುಲಿ',
'ಪತ್ರಾಂಗುಳಿ',
'ಪತ್ರಾಂಜನ',
'ಪತ್ರಾಗಾರ',
'ಪತ್ರಾಶ್ರಯ',
'ಪತ್ರಿಪದ',
'ಪಥಭ್ರಾಂತ',
'ಪಥಭ್ರಾಂತಿ',
'ಫಥಶಿಲ್ಪಿ',
'ಪಥಶ್ರಮ',
'ಪಥಶ್ರಾಂತ',
'ಪಥಸಂಖ್ಯೆ',
'ಪಥಾಸನ',
'ಪಥಿಶ್ರಮ',
'ಪದಂಗಾಣ್',
'ಪದಂಗಾಸು',
'ಪದಂಗುಟ್ಟು',
'ಪದಂಗುಡು',
'ಪದಂಗೆಡು',
'ಪದಂಗೊಡು',
'ಪದಂಗೊಳ್',
'ಪದಂದಪ್ಪು',
'ಪದಂಬಡೆ',
'ಪದಕೋಶ',
'ಪದಕ್ರಮ',
'ಪದಗತಿ',
'ಪದಗರ್ಭ',
'ಪದಗಾಣ್',
'ಪದಗಾಯಿ',
'ಪದಗಾರ',
'ಪದಗಾರ್ತಿ',
'ಪದಗುಡು',
'ಪದಗೆಡು',
'ಪದಗೆಯ್',
'ಪದಗೊಡು',
'ಪದಗೊಳ್',
'ಪದಗೊಳ್ಳು',
'ಪದಘಾತ',
'ಪದಚಾರಿ',
'ಪದಚ್ಛೇದ',
'ಪದಚ್ಯುತ',
'ಪದಚ್ಯುತಿ',
'ಪದಚ್ಯುತೆ',
'ಪದಡಕ್ಕೆ',
'ಪದಢಕ್ಕೆ',
'ಪದತಲ',
'ಪದತಳ',
'ಪದತ್ಯಾಗ',
'ಪದತ್ರಾಣ',
'ಪದದಪ್ಪು',
'ಪದದೋಷ',
'ಪದಧಾರಿ',
'ಪದಧೂಲಿ',
'ಪದಧೂಳಿ',
'ಪದನಖ',
'ಪದನತ',
'ಪದನರಿ',
'ಪದನಱಿ',
'ಪದನಿಸು',
'ಪದನ್ಯಾಸ',
'ಪದಪಾಂಸು',
'ಪದಪಾಕ',
'ಪದಪಾಠ',
'ಪದಪಾತ',
'ಪದಪೀಠ',
'ಪದಬಂಧ',
'ಪದಬಲ',
'ಪದಭ್ರಷ್ಟ',
'ಪದಭ್ರಷ್ಟೆ',
'ಪದಭ್ರಾಂತಿ',
'ಪದಮಿಕ್ಕು',
'ಪದಮಿಡು',
'ಪದಮುಖ',
'ಪದಮೆಟ್ಟು',
'ಪದಮೇಳ',
'ಪದಮೈತ್ರಿ',
'ಪದಯಿದ',
'ಪದಯಿಲ',
'ಪದಯಿಸು',
'ಪದರಕ್ಷೆ',
'ಪದರಜ',
'ಪದರಾಟ',
'ಪದರಾಡು',
'ಪದರಿಸು',
'ಪದರೇಣು',
'ಪದವಡು',
'ಪದವಡೆ',
'ಪದವರ್ಣ',
'ಪದವಿಡಿ',
'ಪದವಿಡು',
'ಪದವಿದ್ಯೆ',
'ಪದವಿಧಿ',
'ಪದವೆಱು',
'ಪದಶಕ್ತಿ',
'ಪದಶಬ್ದ',
'ಪದಶಯ್ಯೆ',
'ಪದಶಾಸ್ತ್ರ',
'ಪದಶಿಲೆ',
'ಪದಸ್ಥಿಕೆ',
'ಪದಹತ',
'ಪದಹತಿ',
'ಪದಹಾಡು',
'ಪದಹೇಳು',
'ಪದಾಂತರ',
'ಪದಾಕ್ರಾಂತ',
'ಪದಾಗಮ',
'ಪದಾಘಾತ',
'ಪದಾತಿಕ',
'ಪದಾತಿನಿ',
'ಪದಾನತ',
'ಪದಾಯುಧ',
'ಪದಾರ್ಪಣ',
'ಪದಾರ್ಪಣೆ',
'ಪದಾವಧಿ',
'ಪದಾವಾಸ',
'ಪದಾವೃತ್ತಿ',
'ಪದಿಕೊಳ',
'ಪದಿತೂಪು',
'ಪದಿನಯ್ದು',
'ಪದಿನಾಲ್ಕು',
'ಪದಿನಾರು',
'ಪದಿನಾಱು',
'ಪದಿನೆಂಟು',
'ಪದಿನೇಳು',
'ಪದಿನೇೞ್',
'ಪದಿನೇೞು',
'ಪದಿನೈದು',
'ಪದಿಮೂರು',
'ಪದಿಮೂಱು',
'ಪದಿರಿಕ್ಕು',
'ಪದಿರ್ಕಳ',
'ಪದಿರ್ಕೊಳ',
'ಪದಿರ್ಮಡಿ',
'ಪದುಮಜ',
'ಪದುಮಾಕ್ಷ',
'ಪದುಮಾಕ್ಷಿ',
'ಪದುಮಿನಿ',
'ಪದುಳತೆ',
'ಪದುಳಿಕೆ',
'ಪದುಳಿಗ',
'ಪದುಳಿದ',
'ಪದುಳಿರು',
'ಪದುಳಿಸು',
'ಪದೆಯಿಸು',
'ಪದೇಪದೇ',
'ಪದೋದಕ',
'ಪದೋನ್ನತಿ',
'ಪದ್ದುದೇರ',
'ಪದ್ಧತಿಕೆ',
'ಪದ್ಮಕಾಷ್ಟ',
'ಪದ್ಮಕಾಷ್ಠ',
'ಪದ್ಮಕೋಶ',
'ಪದ್ಮಗಂಧಿ',
'ಪದ್ಮಗಣ',
'ಪದ್ಮಜನಿ',
'ಪದ್ಮತಂತ್ರ',
'ಪದ್ಮನಾಭ',
'ಪದ್ಮನಾಲ',
'ಪದ್ಮನಾಳ',
'ಪದ್ಮಪತ್ರ',
'ಪದ್ಮಪಾಣಿ',
'ಪದ್ಮಪೀಠ',
'ಪದ್ಮಪ್ರಭ',
'ಪದ್ಮಬೀಜ',
'ಪದ್ಮಭವ',
'ಪದ್ಮಮುಖಿ',
'ಪದ್ಮಮುದ್ರೆ',
'ಪದ್ಮರಾಗ',
'ಪದ್ಮರಿಪು',
'ಪದ್ಮರೇಖೆ',
'ಪದ್ಮಲತೆ',
'ಪದ್ಮವಾಸೆ',
'ಪದ್ಮವೃಕ್ಷ',
'ಪದ್ಮವ್ಯೂಹ',
'ಪದ್ಮಸಖ',
'ಪದ್ಮಸದ್ಮ',
'ಪದ್ಮಸದ್ಮೆ',
'ಪದ್ಮಸಾಲೆ',
'ಪದ್ಮಸಾಳಿ',
'ಪದ್ಮಹ್ರದ',
'ಪದ್ಮಾಕರ',
'ಪದ್ಮಾಗಮ',
'ಪದ್ಮಾಲಯೆ',
'ಪದ್ಮಾಸನ',
'ಪದ್ಮಾಸೀನ',
'ಪದ್ಯಕಾವ್ಯ',
'ಪದ್ಯಗಂಧಿ',
'ಪದ್ಯಬಂಧ',
'ಪನಸಾರೆ',
'ಪನಾಯಿತ',
'ಪನಿಗೊಳ್',
'ಪನಿತರ್',
'ಪನಿನೀರ್',
'ಪನಿನೀರು',
'ಪನಿಪುಲ್',
'ಪನಿಪೊಳ್ತು',
'ಪನಿವಾರ',
'ಪನಿಹೊತ್ತು',
'ಪನೆಗರ್ಚು',
'ಪನೆಬೆಲ್ಲ',
'ಪನ್ನಂಬರ',
'ಪನ್ನಗುಡಿ',
'ಪನ್ನತಿಕೆ',
'ಪನ್ನವಣ',
'ಪನ್ನಸಾದು',
'ಪನ್ನಸಿಗ',
'ಪನ್ನಿನುಡಿ',
'ಪನ್ನಿರಂಡು',
'ಪನ್ನೀರೆಲೆ',
'ಪನ್ನೆತಿದ್ದು',
'ಪನ್ನೆಮೀಸೆ',
'ಪನ್ನೆರಂಡು',
'ಪನ್ನೆರಡು',
'ಪನ್ನೇರಲು',
'ಪನ್ನೇರಲೆ',
'ಪನ್ನೇರಳು',
'ಪನ್ನೇರಳೆ',
'ಪನ್ನೇರಿಳೆ',
'ಪನ್ನೇಱಳೆ',
'ಪಪ್ಪಂಗಾಯಿ',
'ಪಪ್ಪಲಸು',
'ಪಯಂಬಡೆ',
'ಪಯಃಕಣ',
'ಪಯಃಕೇಳಿ',
'ಪಯಣಗೈ',
'ಪಯಣಿಗ',
'ಪಯಣಿಸು',
'ಪಯದಳ',
'ಪಯನಾವು',
'ಪಯಪಾಡು',
'ಪಯಶ್ಚರ',
'ಪಯಸ್ವಿನಿ',
'ಪಯಸ್ಸದ್ಮ',
'ಪಯಿಕರ',
'ಪಯಿನ್ನೂಱು',
'ಪಯಿಲ್ವಾನ್',
'ಪಯೋಜಿನಿ',
'ಪಯೋಧರ',
'ಪಯ್ಸರಿಸು',
'ಪರಂಚಾಟ',
'ಪರಂಜಯ',
'ಪರಂಜೋತಿ',
'ಪರಂಜ್ಯೋತಿ',
'ಪರಂತಪ',
'ಪರಂಧಾಮ',
'ಪರಂಪದ',
'ಪರಂಪರ',
'ಪರಂಪರೆ',
'ಪರಂಪೋಕು',
'ಪರಂಬೋಕು',
'ಪರಕಟ್ಟೆ',
'ಪರಕರ',
'ಪರಕರು',
'ಪರಕರ್ತೃ',
'ಪರಕಾಯ',
'ಪರಕಾರ',
'ಪರಕಾರ್ಯ',
'ಪರಕಾಲ',
'ಪರಕಾಳೆ',
'ಪರಕೀಯ',
'ಪರಕೀಯೆ',
'ಪರಕೆಗೈ',
'ಪರಗಣ',
'ಪರಗಣೆ',
'ಪರಗತಿ',
'ಪರಗೃಹ',
'ಪರಗೇಹ',
'ಪರಘಾತ',
'ಪರಚಕ್ರ',
'ಪರಚಿಂತೆ',
'ಪರಜಾತ',
'ಪರಜ್ಞಾನ',
'ಪರಡಕ್ಕೆ',
'ಪರಡಿಗೆ',
'ಪರತಂತ್ರ',
'ಪರಂತ್ರತೆ',
'ಪರತಂತ್ರಿ',
'ಪರತಟ',
'ಪರತತ್ತ್ವ',
'ಪರತತ್ವ',
'ಪರದಾಟ',
'ಪರದಾಡು',
'ಪರದಾಯಿ',
'ಪರದಾರ',
'ಪರದಾಸ್ಯ',
'ಪರದಿಕೆ',
'ಪರದಿತಿ',
'ಪರದುಂಗೈ',
'ಪರದುಗೈ',
'ಪರದೇಶ',
'ಪರದೇಶಿ',
'ಪರದೇಸ',
'ಪರದೇಸಿ',
'ಪರದೈವ',
'ಪರನಾರಿ',
'ಪರನಿಂದೆ',
'ಪರಪಂಚ',
'ಪರಪತಿ',
'ಪರಪದ',
'ಪರಪಾಟು',
'ಪರಪಾಟ್ಲು',
'ಪರಪಿಂಡಿ',
'ಪರಪೀಡೆ',
'ಪರಪುಟ್ಟ',
'ಪರಪುಷ್ಟ',
'ಪರಪುಷ್ಟಿ',
'ಪರಪೇಲೆ',
'ಪರಪೋಷ್ಯ',
'ಪರಪ್ರೇಷ್ಯ',
'ಪರಬಲ',
'ಪರಬಳ',
'ಪರಬೊಮ್ಮ',
'ಪರಬ್ರಹ್ಮ',
'ಪರಭಂಡ',
'ಪರಭಕ್ತಿ',
'ಪರಭಾಗ',
'ಪರಭಾರಿ',
'ಪರಭಾರೆ',
'ಪರಭಾಷೆ',
'ಪರಭೃತ',
'ಪರಮಾಣು',
'ಪರಮಾತ್ಮ',
'ಪರಮಾನ',
'ಪರಮಾನ್ನ',
'ಪರಮಾಯು',
'ಪರಮಾರ್ಥ',
'ಪರಮಾರ್ಥಂ',
'ಪರಮಾರ್ಥಿ',
'ಪರಮಾಶಿ',
'ಪರಮುಖ',
'ಪರಮೇಶ',
'ಪರಮೇಷ್ಠಿ',
'ಪರಮೋಧ್ರ್ವ',
'ಪರಮೋಶಿ',
'ಪರಯಿಸು',
'ಪರಲುಗ',
'ಪರಲೋಕ',
'ಪರಲೋಕಿ',
'ಪರಲ್ವಿಲ್',
'ಪರವಧು',
'ಪರವರಿ',
'ಪರವರ್ಷ',
'ಪರವರ್ಷಿ',
'ಪರವಶ',
'ಪರವಶೆ',
'ಪರವಸ',
'ಪರವಸ್ತು',
'ಪರವಾದಿ',
'ಪರವಾನಾ',
'ಪರವಾನು',
'ಪರವಾನೆ',
'ಪರವಾಸ',
'ಪರವಿದ್ಧ',
'ಪರವಿದ್ಯೆ',
'ಪರವೋಗು',
'ಪರಶಕ್ತಿ',
'ಪರಶಿವ',
'ಪರಶ್ವಧ',
'ಪರಸಂಗ',
'ಪರಸತಿ',
'ಪರಸತ್ಯ',
'ಪರಸನ',
'ಪರಸನೆ',
'ಪರಸನ್ನ',
'ಪರಸಾಂಗು',
'ಪರಸಾದ',
'ಪರಸೇವೆ',
'ಪರಸೊಮ್ಮು',
'ಪರಸ್ಥಲ',
'ಪರಸ್ಥಳ',
'ಪರಸ್ಥಾನ',
'ಪರಸ್ಪರ',
'ಪರಸ್ವತ್ತು',
'ಪರಹತ್ತ',
'ಪರಹಸ್ತ',
'ಪರಹಿಂಸೆ',
'ಪರಹಿತ',
'ಪರಳಿಗ',
'ಪರಳ್ಗಲ್ಲು',
'ಪರಳ್ಗಾಯಿ',
'ಪರಾಂಗನೆ',
'ಪರಾಕಾಷ್ಠ',
'ಪರಾಕಿಸು',
'ಪರಾಕ್ರಮ',
'ಪರಾಕ್ರಮಿ',
'ಪರಾಖ್ಯಾನ',
'ಪರಾಗತಿ',
'ಪರಾಗ್ರೂಪ',
'ಪರಾಙ್ಮುಖ',
'ಪರಾಚೀನ',
'ಪರಾಜಯ',
'ಪರಾಜಿತ',
'ಪರಾಜ್ಞಾನ',
'ಪರಾತ್ಪರ',
'ಪರಾಧೀನ',
'ಪರಾಧೀನೆ',
'ಪರಾಭವ',
'ಪರಾಭೂತ',
'ಪರಾಭೂತಿ',
'ಪರಾಭೇದ್ಯ',
'ಪರಾಮರ್ಶ',
'ಪರಾಮರ್ಶೆ',
'ಪರಾಮೃಷ್ಟ',
'ಪರಾಮೋಶಿ',
'ಪರಾಯಣ',
'ಪರಾಯಣಿ',
'ಪರಾಯತ',
'ಪರಾಯತ್ತ',
'ಪರಾರ್ಜಿತ',
'ಪರಾವರ್ತ',
'ಪರಾವಾಕ್ಕು',
'ಪರಾವಿದ್ಯೆ',
'ಪರಾವೃತ್ತ',
'ಪರಾವೃತ್ತಿ',
'ಪರಾಶಕ್ತಿ',
'ಪರಾಶರ',
'ಪರಾಶರಿ',
'ಪರಾಶ್ರಯ',
'ಪರಾಶ್ರಿತ',
'ಪರಾಶ್ರಿತೆ',
'ಪರಾಸನ',
'ಪರಾಸ್ಕಂದ',
'ಪರಾಸ್ಕಂದಿ',
'ಪರಿಕಣಿ',
'ಪರಿಕಥೆ',
'ಪರಿಕರ',
'ಪರಿಕರ್ಮ',
'ಪರಿಕರ್ಮಿ',
'ಪರಿಕಲ್ಪ',
'ಪರಿಕಾಂಕ್ಷೆ',
'ಪರಿಕಾರ',
'ಪರಿಕಾಲ್',
'ಪರಿಕಾಲ್ವೆ',
'ಪರಿಕಾಱ',
'ಪರಿಕಿಸು',
'ಪರಿಕೀರ್ಣ',
'ಪರಕೂಟ',
'ಪರಿಕೃತ',
'ಪರಿಕೊಳೆ',
'ಪರಿಕ್ರಮ',
'ಪರಿಕ್ರಿಯೆ',
'ಪರಿಕ್ರುದ್ಧ',
'ಪರಿಕ್ಲಿನ್ನ',
'ಪರಿಕ್ಲೇಶ',
'ಪರಿಕ್ಷಯ',
'ಪರಿಕ್ಷಿಪ್ತ',
'ಪರಕ್ಷೀಣ',
'ಪರಕ್ಷುಬ್ಧ',
'ಪರಿಕ್ಷೇಪ',
'ಪರಿಖಾತ',
'ಪರಿಖೇದ',
'ಪರಿಖ್ಯಾತಿ',
'ಪರಿಗತ',
'ಪರಿಗಿಡು',
'ಪರಿಗೀತ',
'ಪರಿಗೂಢ',
'ಪರಿಗೆಡು',
'ಪರಿಗೊಳ್',
'ಪರಿಗ್ರಹ',
'ಪರಿಗ್ರಾಹ್ಯ',
'ಪರಿಘಾತ',
'ಪರಿಘೋಷ',
'ಪರಿಚಯ',
'ಪರಿಚರ',
'ಪರಿಚರ್ಯ',
'ಪರಿಚರ್ಯೆ',
'ಪರಿಚಾಯ್ಯ',
'ಪರಿಚಾರ',
'ಪರಿಚಿತ',
'ಪರಿಚಿತಿ',
'ಪರಿಚಿತೆ',
'ಪರಿಚ್ಛದ',
'ಪರಿಚ್ಛನ್ನ',
'ಪರಿಚ್ಛಿತ್ತಿ',
'ಪರಿಚ್ಛಿನ್ನ',
'ಪರಿಚ್ಛೇದ',
'ಪರಿಚ್ಛೇದಿ',
'ಪರಿಚ್ಯುತ',
'ಪರಿಚ್ಯುತೆ',
'ಪರಿಜನ',
'ಪರಿಣಯ',
'ಪರಿಣಾಮ',
'ಪರಿಣಾಮಿ',
'ಪರಿಣಾಯ',
'ಪರಿಣಾಹ',
'ಪರಿಣೀತ',
'ಪರಿತಪ್ತ',
'ಪರಿತಪ್ತೆ',
'ಪರಿತರ್',
'ಪರಿತರು',
'ಪರಿತಾಪ',
'ಪರಿತುಷ್ಟ',
'ಪರಿತುಷ್ಟಿ',
'ಪರಿತುಷ್ಟೆ',
'ಪರಿತೃಪ್ತ',
'ಪರಿತೃಪ್ತಿ',
'ಪರಿತೃಪ್ತೆ',
'ಪರಿತೋಷ',
'ಪರಿತೋಷಿ',
'ಪರಿತ್ಯಕ್ತ',
'ಪರಿತ್ಯಕ್ತೆ',
'ಪರಿತ್ಯಾಗ',
'ಪರಿತ್ಯಾಗಿ',
'ಪರಿತ್ಯಾಜ್ಯ',
'ಪರಿತ್ರಾಣ',
'ಪರಿತ್ರಾಸ',
'ಪರಿದರ',
'ಪರಿದಾನ',
'ಪರಿದಾರಿ',
'ಪರಿದೇವ',
'ಪರಿಧಾನ',
'ಪರಿಧಾವಿ',
'ಪರಿಧಿಸ್ಥ',
'ಪರಧೂತ',
'ಪರಿಧೌತ',
'ಪರಿನಾಳ',
'ಪರಿನೀರ್',
'ಪರಿನ್ಯಸ್ತ',
'ಪರಿಪಂತಿ',
'ಪರಿಪಂಥಿ',
'ಪರಿಪಕ್ವ',
'ಪರಿಪಟ್ಟ',
'ಪರಿಪಡು',
'ಪರಿಪಣ',
'ಪರಿಪತ್ರ',
'ಪರಿಪಾಂಡು',
'ಪರಿಪಾಂಸು',
'ಪರಿಪಾಕ',
'ಪರಿಪಾಟ',
'ಪರಿಪಾಟಿ',
'ಪರಿಪಾಟು',
'ಪರಿಪಾಟ್ಲು',
'ಪರಿಪಾಠ',
'ಪರಿಪಾಠಿ',
'ಪರಿಪಾಡಿ',
'ಪರಿಪಾರ್',
'ಪರಿಪಾಶ್ರ್ವ',
'ಪರಿಪಾಲ',
'ಪರಿಪಾಲ್ಯ',
'ಪರಿಪಾಳಿ',
'ಪರಿಪಾೞಿ',
'ಪರಿಪೀತ',
'ಪರಿಪುಷ್ಟ',
'ಪರಿಪುಷ್ಟಿ',
'ಪರಿಪೂತ',
'ಪರಿಪೂರ',
'ಪರಿಪೂರ್ಣ',
'ಪರಿಪೂರ್ಣೆ',
'ಪರಿಪೂರ್ತಿ',
'ಪರಿಪೋಗು',
'ಪರಿಪೋಷ',
'ಪರಿಪ್ರಶ್ನ',
'ಪರಿಪ್ರಶ್ನೆ',
'ಪರಿಪ್ಲವ',
'ಪರಿಪ್ಲುತ',
'ಪರಿಪ್ಲುಷ್ಟ',
'ಪರಿಬದ್ಧ',
'ಪರಿಬರ್ಹ',
'ಪರಿಬೃಢ',
'ಪರಿಬ್ರಿಡ',
'ಪರಿಭಂಗ',
'ಪರಿಭವ',
'ಪರಿಭಾಗೆ',
'ಪರಿಭಾವ',
'ಪರಿಭಾಷೆ',
'ಪರಿಭಾಸಿ',
'ಪರಿಭಿನ್ನ',
'ಪರಿಭೂತ',
'ಪರಿಭೂತಿ',
'ಪರಿಭೃತ',
'ಪರಿಭೇದ',
'ಪರಿಭೋಗ',
'ಪರಿಭ್ರಂಶ',
'ಪರಿಭ್ರಮ',
'ಪರಿಮಲ',
'ಪರಿಮಳ',
'ಪರಿಮಾಣ',
'ಪರಿಮಿತ',
'ಪರಿಮಿತಿ',
'ಪರಿಮಿಶ್ರ',
'ಪರಿಮುಕ್ತ',
'ಪರಿಮುತ್ತು',
'ಪರಿಮುತ್ತೆ',
'ಪರಿಮೃತ',
'ಪರಿಮೃಷ್ಟ',
'ಪರಿಮೇಯ',
'ಪರಿಮೋಷ',
'ಪರಿಮೋಷಿ',
'ಪರಿಮ್ಲಾನ',
'ಪರಿಯಂಕ',
'ಪರಿಯಂತ',
'ಪರಿಯಂತಂ',
'ಪರಿಯಂತ್ರ',
'ಪರಿಯಣ',
'ಪರಯರ',
'ಪರಿಯಷ್ಟಿ',
'ಪರಿಯಳ',
'ಪರಿಯಾಣ',
'ಪರಿಯಾನ',
'ಪರಿಯಾಪ್ತ',
'ಪರಿಯಾಪ್ತಿ',
'ಪರಿಯಾಯ',
'ಪರಿಯಾರ',
'ಪರಿಯಿಡು',
'ಪರಿಯಿಸು',
'ಪರಿಯುಕ್ತ',
'ಪರಿಯೋಗ',
'ಪರಿರಂಭ',
'ಪರಿರಕ್ತ',
'ಪರಿರಕ್ಷ',
'ಪರಿರಕ್ಷೆ',
'ಪರಿರುಂದ್ರ',
'ಪರಿಲಂಬಿ',
'ಪರಿಲಗ್ನ',
'ಪರಿಲಬ್ಧ',
'ಪರಿಲಿಪ್ತ',
'ಪರಿಲೂನ',
'ಪರಿವಟ್ಟ',
'ಪರಿವಟ್ಟು',
'ಪರಿವಡಿ',
'ಪರಿವಡೆ',
'ಪರಿವರ್ತ',
'ಪರಿವರ್ಹ',
'ಪರಿವಹ',
'ಪರಿವಾಣ',
'ಪರಿವಾದ',
'ಪರಿವಾಪ',
'ಪರಿವಾರ',
'ಪರಿವಾಸ',
'ಪರಿವಾಹ',
'ಪರಿವಿಡಿ',
'ಪರಿವಿಡು',
'ಪರಿವಿತ್ತಿ',
'ಪರಿವಿತ್ತು',
'ಪರಿವಿಷ್ಟ',
'ಪರಿವೀತ',
'ಪರಿವೃಢ',
'ಪರಿವೃತ',
'ಪರಿವೃತ್ತ',
'ಪರಿವೃತ್ತಿ',
'ಪರಿವೃದ್ಧಿ',
'ಪರಿವೆಸ',
'ಪರಿವೇಣ',
'ಪರಿವೇತೃ',
'ಪರಿವೇತ್ತ',
'ಪರಿವೇದಿ',
'ಪರಿವೇಲ',
'ಪರಿವೇಶ',
'ಪರಿವೇಷ',
'ಪರಿವೇಷ್ಟಿ',
'ಪರಿವೇಷ್ಟೃ',
'ಪರಿವ್ಯಾಧ',
'ಪರಿವ್ರಜ್ಯೆ',
'ಪರಿವ್ರಾಜ',
'ಪರಿಶಂಕೆ',
'ಪರಿಶಿಷ್ಟ',
'ಪರಿಶುದ್ಧ',
'ಪರಿಶುದ್ಧಿ',
'ಪರಿಶುದ್ಧೆ',
'ಪರಿಶುಷ್ಕ',
'ಪರಿಶೇಷ',
'ಪರಿಶೋಕ',
'ಪರಿಶೋಧ',
'ಪರಿಶೋಭೆ',
'ಪರಿಶ್ರಮ',
'ಪರಿಶ್ರಾಂತ',
'ಪರಿಶ್ರಾಂತಿ',
'ಪರಿಶ್ರಾಂತೆ',
'ಪರಿಶ್ರುತ',
'ಪರಿಷತ್ತು',
'ಪರಿಷಿಕ್ತ',
'ಪರಿಷೇಕ',
'ಪರಿಷ್ಕಾರ',
'ಪರಿಷ್ಕೃತ',
'ಪರಿಷ್ಕೃತಿ',
'ಪರಿಷ್ಕೃತೆ',
'ಪರಿಷ್ವಂಗ',
'ಪರಿಸಂಖ್ಯೆ',
'ಪರಿಸಂಗ',
'ಪರಿಸನ',
'ಪರಿಸರ',
'ಪರಿಸರ್ಪ',
'ಪರಿಸರ್ಯೆ',
'ಪರಿಸೀಮೆ',
'ಪರಿಸುಪ್ತಿ',
'ಪರಿಸೂತ್ರ',
'ಪರಿಸೇಕ',
'ಪರಿಸ್ಕಂದ',
'ಪರಿಸ್ತೋಮ',
'ಪರಿಸ್ಥಿತಿ',
'ಪರಿಸ್ಪಂದ',
'ಪರಿಸ್ಫುಟ',
'ಪರಿಸ್ಫುಟಂ',
'ಪರಿಸ್ಯಂದ',
'ಪರಿಸ್ರುತ',
'ಪರಿಸ್ರುತೆ',
'ಪರಿಹರ',
'ಪರಿಹಾರ',
'ಪರಿಹಾರ್ಯ',
'ಪರಿಹಾಸ',
'ಪರಿಹಾಸಿ',
'ಪರಿಹಾಸ್ಯ',
'ಪರಿಹೃತ',
'ಪರಿಹೃತಿ',
'ಪರೀಕ್ಷಕ',
'ಪರೀಕ್ಷಣ',
'ಪರೀಕ್ಷಣೆ',
'ಪರೀಕ್ಷಾರ್ಥಿ',
'ಪರೀಕ್ಷಿತ',
'ಪರೀಕ್ಷಿಸು',
'ಪರೀತಾಪ',
'ಪರೀಧಾವಿ',
'ಪರೀಭಾವ',
'ಪರೀವರ್ತ',
'ಪರೀವಾದ',
'ಪರೀವಾಹ',
'ಪರೀಷಹ',
'ಪರೀಸಾರ',
'ಪರೀಹಾರ',
'ಪರೀಹಾಸ',
'ಪರುಕಿಸು',
'ಪರುಟವ',
'ಪರುಠವ',
'ಪರುವತ',
'ಪರುಶನ',
'ಪರುಷಣ',
'ಪರುಷನ',
'ಪರುಷೋಕ್ತಿ',
'ಪುರುಷೋಕ್ತೆ',
'ಪರುಸನ',
'ಪರೂಷಕ',
'ಪರಿಗಡಿ',
'ಪರಿಗಣ್ಣು',
'ಪರೆಗಳೆ',
'ಪರೆಗುಡು',
'ಪರೆಬಿಡು',
'ಪರೆಯಿಸು',
'ಪರೆಯುಚ್ಚು',
'ಪರೇಂಗಿತ',
'ಪರೇಶ್ವರ',
'ಪರೇಷ್ಟುಕೆ',
'ಪರೈಧಿತ',
'ಪರೋತ್ತಮ',
'ಪರ್ಚುಕೊಳ್',
'ಪರ್ಚುಗುಡು',
'ಪರ್ಚುಗೂಂಟ',
'ಪರ್ಚುಗೊಡು',
'ಪರ್ಚುಗೊಳ್',
'ಪರ್ಜನ್ಯಾಸ್ತ್ರ',
'ಪರ್ಣಕುಟಿ',
'ಪರ್ಣಗೃಹ',
'ಪರ್ಣದ್ರುಮ',
'ಪರ್ಣಪುಟ',
'ಪರ್ಣಲಘು',
'ಪರ್ಣಶಾಲೆ',
'ಪರ್ಣಾಹಾರ',
'ಪರ್ದುದೇರ',
'ಪರ್ದುವೇರ್',
'ಪರ್ನಕುಟಿ',
'ಪರ್ನಶಾಲೆ',
'ಪರ್ಪಟಕ',
'ಪರ್ಪರಿಕೆ',
'ಪರ್ಪರೀಕ',
'ಪರ್ಪಾಟಕ',
'ಪರ್ಪಾಷ್ಟಕ',
'ಪರ್ಬುಗೆಡು',
'ಪರ್ಬುಗೊಳ್',
'ಪರ್ಯಂಕಿಕೆ',
'ಪರ್ಯಂತರ',
'ಪರ್ಯಂತರಂ',
'ಪರ್ಯಟಕ',
'ಪರ್ಯಟನ',
'ಪರ್ಯಟನೆ',
'ಪರ್ಯಯಣ',
'ಪರ್ಯವಸ್ಥೆ',
'ಪರ್ಯಸ್ತಕ',
'ಪರ್ಯಸ್ತಿಕ',
'ಪರ್ಯಸ್ತಿಕೆ',
'ಪರ್ಯಾಕುಲ',
'ಪರ್ಯಾಣಕ',
'ಪರ್ಯಾತೀತ',
'ಪರ್ಯಾಪ್ತಕ',
'ಪರ್ಯಾಪ್ತತೆ',
'ಪರ್ಯಾಯಸ್ಥ',
'ಪರ್ಯಾಯೋಕ್ತ',
'ಪರ್ಯಾಯೋಕ್ತಿ',
'ಪರ್ಯಾಹಾರ',
'ಪರ್ಯುತ್ಸುಕ',
'ಪರ್ಯುಪಾಸ್ತಿ',
'ಪರ್ಯುಪಾಸ್ಯೆ',
'ಪರ್ಯುಷಿತ',
'ಪರ್ಯೂಷಣ',
'ಪರ್ಯೇಷಣೆ',
'ಪರ್ವಕಾಲ',
'ಪರ್ವಸಂಧಿ',
'ಪರ್ವಳಿಸು',
'ಪರ್ವುಗೆಡು',
'ಪರ್ವುಗೊಳ್',
'ಪಲಂಕಷ',
'ಪಲಂಕಷೆ',
'ಪಲಕಾಲ',
'ಪಲಕಾಲಂ',
'ಪಲಕೀರೆ',
'ಪಲಗಂಡ',
'ಪಲಗಣ್',
'ಪಲಗಣ್ಣ',
'ಪಲಗಣ್ಣು',
'ಪಲಗೋರು',
'ಪಲಟಣ',
'ಪಲತೀಗೆ',
'ಪಲವಲ',
'ಪಲಸಿಗೆ',
'ಪಲಹಾರ',
'ಪಲಾಯನ',
'ಪಲಾಯಿಗ',
'ಪಲಾಯಿತ',
'ಪಲಿಕಿನಿ',
'ಪಲಿಯಿಸು',
'ಪಲುಕಿಸು',
'ಪಲುಗಿರಿ',
'ಪಲುಗುಣ',
'ಪಲುಚೊಟ್ಟೆ',
'ಪಲುಮೃಗ',
'ಪಲುಮೊರೆ',
'ಪಲುಸುಲಿ',
'ಪಲ್ಗಿರಿಸು',
'ಪಲ್ಟಾಯಿಸು',
'ಪಲ್ಮಾಣಿಕ',
'ಪಲ್ಯಯನ',
'ಪಲ್ಯೋಪಮ',
'ಪಲ್ಲಚೀಲ',
'ಪಲ್ಲಟಿಸು',
'ಪಲ್ಲಣಿಸು',
'ಪಲ್ಲಯಿಸು',
'ಪಲ್ಲವಕ',
'ಪಲ್ಲವಿಕ',
'ಪಲ್ಲವಿಕೆ',
'ಪಲ್ಲವಿತ',
'ಪಲ್ಲವಿಸು',
'ಪಲ್ಲಿಸರ',
'ಪಲ್ಲುಗಿರಿ',
'ಪಲ್ಲುದಿನು',
'ಪಲ್ಲುಮೊರೆ',
'ಪಲ್ವರಸೆ',
'ಪಲ್ವರಿಸೆ',
'ಪವಡಿಸು',
'ಪವಣಱಿ',
'ಪವಣಿಕೆ',
'ಪವಣಿಗೆ',
'ಪವಣಿಸು',
'ಪವನಜ',
'ಪವನಜೆ',
'ಪವನಾಧ್ವ',
'ಪವನಾಶ',
'ಪವನಾಶಿ',
'ಪವಮಾನ',
'ಪವರಣೆ',
'ಪವಳಿಸು',
'ಪವಿಘಾತ',
'ಪವಿತ್ರಕ',
'ಪವಿತ್ರತೆ',
'ಪವಿತ್ರಾತ್ಮ',
'ಪವಿತ್ರಿತ',
'ಪವಿಧರ',
'ಪವಿಮೂರ್ತಿ',
'ಪವ್ವಳಿಸು',
'ಪಶುಕರ್ಮ',
'ಪಶುಕರ್ಮಿ',
'ಪಶುಪತಿ',
'ಪಶುಪಾಲ',
'ಪಶುಬಲಿ',
'ಪಶುಬುದ್ಧಿ',
'ಪಶುಮತ',
'ಪಶುಯಜ್ಞ',
'ಪಶುವಿಧಿ',
'ಪಶುವೃತ್ತಿ',
'ಪಶುವೈದ್ಯ',
'ಪಶುಸ್ತನಿ',
'ಪಶ್ಚಾತ್ಕಾಲ',
'ಪಶ್ಚಾತ್ತಾಪ',
'ಪಶ್ಚಿಮಾದ್ರಿ',
'ಪಸಂಗೊಳ್',
'ಪಸಗಂದು',
'ಪಸಗೆಡು',
'ಪಸಗೆಯ್ಮೆ',
'ಪಸದನ',
'ಪಸಯಿತ',
'ಪಸರಣ',
'ಪಸರಾಯ',
'ಪಸರಾಯಿ',
'ಪಸರಿಕೆ',
'ಪಸರಿಗೆ',
'ಪಸರಿಸು',
'ಪಸವಡು',
'ಪಸವಳಿ',
'ಪಸಾಯತ',
'ಪಸಾಯನ',
'ಪಸಾಯಿತ',
'ಪಸಾಯಿತೆ',
'ಪಸಿರ್ಗಲ್',
'ಪಸಿವೆಱು',
'ಪಸುಂಗುರಿ',
'ಪಸುಗೂಸು',
'ಪಸುಪತಿ',
'ಪಸುಪೊನ್',
'ಪಸುಮಗ',
'ಪಸುಮಗು',
'ಪಸುರ್ಗಲ್',
'ಪಸುರ್ಗಲ್ಲು',
'ಪಸುರ್ಗೂಳ್',
'ಪಸುರ್ಗೂಳು',
'ಪಸುರ್ಮಣಿ',
'ಪಸುರ್ವಕ್ಕಿ',
'ಪಸುರ್ವಡು',
'ಪಸುರ್ವಡೆ',
'ಪಸುರ್ವಣಿ',
'ಪಸುರ್ವಾಸೆ',
'ಪಸುರ್ವಿಡಿ',
'ಪಸುರ್ವೊನ್',
'ಪಸುವೊನ್',
'ಪಸೆಗೆಯ್',
'ಪಸೆನಿಲ್',
'ಪಸೆನೀರು',
'ಪಸೆಯಿಡು',
'ಪಸೆಯಿರ್',
'ಪಸೆವಣೆ',
'ಪಸೆವಾಸು',
'ಪಸೆವಿಡಿ',
'ಪಸೆವೆಣ್',
'ಪಹರಣೆ',
'ಪಹಿಲ್ವಾನ',
'ಪಳಂಚಲೆ',
'ಪಳಂಚಾಡು',
'ಪಳಂಚಿಸು',
'ಪಳಕ್ಕನೆ',
'ಪಳಗಂಡ',
'ಪಳಗಲು',
'ಪಳಗಾಟ',
'ಪಳಗಿಸು',
'ಪಳಗೆಂಪು',
'ಪಳಚನೆ',
'ಪಳಚ್ಚನೆ',
'ಪಳಚ್ಚಿಸು',
'ಪಳನುಡಿ',
'ಪಳಮಾತು',
'ಪಳಮೊರೆ',
'ಪಳಯಿಗೆ',
'ಪಳಯಿಸು',
'ಪಳವಾತು',
'ಪಳವಿಗೆ',
'ಪಳವಿಸು',
'ಪಳಹರ',
'ಪಳಾಯಿಗ',
'ಪಳಿಕೆಯ್',
'ಪಳಿಗುಡು',
'ಪಳಿಗೊಡು',
'ಪಳಿನುಡಿ',
'ಪಳಿಯಱ',
'ಪಳಿಹತ',
'ಪಳಿಹತ್ತ',
'ಪಳಿಹಸ್ತ',
'ಪಳುವಟ್ಟೆ',
'ಪಳೆಗಾಲ',
'ಪಳೆಗೋಱಿ',
'ಪಳೆಗೌದಿ',
'ಪಳೆನುಡಿ',
'ಪಳೆವಾತು',
'ಪಳ್ಕೆವನೆ',
'ಪಳ್ಳಿಕಾಱ',
'ಪಳ್ಳಿಕೂಟ',
'ಪಳ್ಳಿವರ್',
'ಪಳ್ಳಿವೋಗು',
'ಪಱಿಕೊಳ್',
'ಪಱಿಗಡ್ಡ',
'ಪಱಿಗಲ್',
'ಪಱಿಗೋಲ್',
'ಪಱಿಗೋಲು',
'ಪಱಿತಿನ್',
'ಪಱಿದಲೆ',
'ಪಱಿನಂಟ',
'ಪಱಿಪಡು',
'ಪಱಿಪಾಟು',
'ಪಱಿಮಱಿ',
'ಪಱಿಯಿಡು',
'ಪಱಿಯಿಸು',
'ಪಱಿಯೆಸು',
'ಪಱಿವಡು',
'ಪಱಿವಡೆ',
'ಪಱಿವಱಿ',
'ಪಱಿವಾಯ್',
'ಪಱಿವಾಯ',
'ಪಱಿವೆಱು',
'ಪಱಿಸೀರೆ',
'ಪಱುಗಲ್',
'ಪಱುಗೋಲ್',
'ಪಱುಗೋಲು',
'ಪಱೆಕಾಱ',
'ಪಱೆಗುಟ್ಟು',
'ಪಱೆಗುಲ',
'ಪೞನುಡಿ',
'ಪೞಯಿಗೆ',
'ಪೞವಾಡ',
'ಪೞವಾತು',
'ಪೞವಿಗೆ',
'ಪೞಿಕಯ್',
'ಪೞಿಕೆಯ್',
'ಪೞಿಗೞಿ',
'ಪೞಿನುಡಿ',
'ಪೞಿಯತ',
'ಪೞಿಯತ್ತ',
'ಪೞಿಯಱ',
'ಪೞಿವೊಂದು',
'ಪೞಿಹತ',
'ಪೞಿಹತ್ತ',
'ಪೞುಗೞಿ',
'ಪೞುವಗೆ',
'ಪೞುವಡು',
'ಪೞುವೞಿ',
'ಪೞ್ಕೆಗೆಡು',
'ಪೞ್ಕೆಗೆಯ್',
'ಪೞ್ಕೆವನೆ',
'ಪೞ್ಕೆವೆಟ್ಟ',
'ಪೞ್ಕೆಸಾರ್',
'ಪೞ್ಪೞನೆ',
'ಪಾಂಗುವಡೆ',
'ಪಾಂಗುವೆಱು',
'ಪಾಂಚಜನ್ಯ',
'ಪಾಂಚರಾತ್ರ',
'ಪಾಂಚಾಲಿಕೆ',
'ಪಾಂಚಾಳಿಕೆ',
'ಪಾಂಡುಗಾತ್ರ',
'ಪಾಂಡುಚ್ಛತ್ರ',
'ಪಾಂಡುಪಲ್ಲಿ',
'ಪಾಂಡುಪಿಂಡ',
'ಪಾಂಡುಭೂಮಿ',
'ಪಾಂಡುರಂಗ',
'ಪಾಂಡುರತೆ',
'ಪಾಂಡುರಿತ',
'ಪಾಂಡುರೋಗ',
'ಪಾಂಡುರೋಗಿ',
'ಪಾಂಡುಸಂಧಿ',
'ಪಾಂಥಶಾಲೆ',
'ಪಾಕಂಪೊಪ್ಪು',
'ಪಾಕಗೂಳು',
'ಪಾಕಗೊಳ್',
'ಪಾಕನಾಥ',
'ಪಾಕನೇಮಿ',
'ಪಾಕಯಜ್ಞ',
'ಪಾಕಶಾಲೆ',
'ಪಾಕಶಾಸ್ತ್ರ',
'ಪಾಕಸ್ಥಾನ',
'ಪಾಕುಳಿಗ',
'ಪಾಗಿವಾಳ',
'ಪಾಗುಪಟ್ಟೆ',
'ಪಾಚಕತ್ವ',
'ಪಾಚವಾರಿ',
'ಪಾಚಿಬಳ್ಳಿ',
'ಪಾಚಿಹುಲ್ಲು',
'ಪಾಜೆಗಟ್ಟಿ',
'ಪಾಟಕಾಱ',
'ಪಾಟಗಣಿ',
'ಪಾಟಲಿತ',
'ಪಾಟಲಿಮ',
'ಪಾಟಲೂನು',
'ಪಾಟಲೋಣ',
'ಪಾಟಲೋನು',
'ಪಾಟಳಿತ',
'ಪಾಟಳಿಮ',
'ಪಾಟಿಗೆಡು',
'ಪಾಟಿಹೆಂಡ',
'ಪಾಟುಗೆಡು',
'ಪಾಟುಪಡು',
'ಪಾಟುಬಡು',
'ಪಾಠಕಲಿ',
'ಪಾಠಕಾಱ',
'ಪಾಠಕ್ರಮ',
'ಪಾಠಗ್ರಂಥಿ',
'ಪಾಠದೋಷ',
'ಪಾಠಭೇದ',
'ಪಾಠಭ್ರಾಂತಿ',
'ಪಾಠಯಿಸು',
'ಪಾಠಶಾಲೆ',
'ಪಾಠಹೇಳು',
'ಪಾಠಾಂತರ',
'ಪಾಡಿೞಿಸು',
'ಪಾಡುಂಗಾಱ',
'ಪಾಡುಕಾಯಿ',
'ಪಾಡುಕಾಱ',
'ಪಾಡುಕುಳಿ',
'ಪಾಡುಗಬ್ಬ',
'ಪಾಡುಗಾಯಿ',
'ಪಾಡುಗಾರ',
'ಪಾಡುಗಾಱ',
'ಪಾಡುಗೆಯ್',
'ಪಾಡುಗೊಳ್',
'ಪಾಡುದಪ್ಪು',
'ಪಾಡುಪಡು',
'ಪಾಡುಬೀಳು',
'ಪಾಡುಬೀೞ್',
'ಪಾಣವಿಕ',
'ಪಾಣಿತಲ',
'ಪಾಣಿತಳ',
'ಪಾಣಿದಂಡ',
'ಪಾಣಿಪಂಚೆ',
'ಪಾಣಿಪತ್ತು',
'ಪಾಣಿಪಾಡು',
'ಪಾಣಿಪಾತ್ರ',
'ಪಾಣಿಪಾತ್ರೆ',
'ಪಾಣಿಪೀಠ',
'ಪಾಣಿಪುಟ',
'ಪಾಣಿಮುಕ್ತ',
'ಪಾಣಿಮೂಲ',
'ಪಾಣಿವಟ',
'ಪಾಣಿವಟ್ಟ',
'ಪಾಣಿವಟ್ಟು',
'ಪಾಣಿವಾದ',
'ಪಾಣಿಶಾಖೆ',
'ಪಾಣ್ಬತನ',
'ಪಾತಂಜಲ',
'ಪಾತಶಹ',
'ಪಾತಷಹ',
'ಪಾತಷಾಹ',
'ಪಾತಷಾಹಿ',
'ಪಾತಿವ್ರತೆ',
'ಪಾತಿವ್ರತ್ಯ',
'ಪಾತೀದಾರ',
'ಪಾತುಶಾಹ',
'ಪಾತ್ರಂಬಡೆ',
'ಪಾತ್ರಂಸೂಳೆ',
'ಪಾತ್ರಚಾರಿ',
'ಪಾತ್ರದತ್ತಿ',
'ಪಾತ್ರದಾನ',
'ಪಾತ್ರದಾನಿ',
'ಪಾತ್ರಧರ',
'ಪಾತ್ರಧಾರಿ',
'ಪಾತ್ರವಾಡು',
'ಪಾತ್ರವೆಣ್',
'ಪಾತ್ರಸೃಷ್ಟಿ',
'ಪಾತ್ರೀಭೂತ',
'ಪಾಥೋದಂತಿ',
'ಪಾಥೋದರ',
'ಪಾಥೋನಿಧಿ',
'ಪಾಥೋರುಹ',
'ಪಾದಕೃಚ್ಛೃ',
'ಪಾದಗಲ್ಲು',
'ಪಾದಗಾಣ್ಕೆ',
'ಪಾದಘಾತ',
'ಪಾದಚಾರಿ',
'ಪಾದಚ್ಛಾಯೆ',
'ಪಾದತಲ',
'ಪಾದತಳ',
'ಪಾದತ್ರಾಣ',
'ಪಾದದಾಹ',
'ಪಾದಧೂಳಿ',
'ಪಾದನ್ಯಾಸ',
'ಪಾದಪಟ್ಟ',
'ಪಾದಪಾತ',
'ಪಾದಪಾಶ',
'ಪಾದಪೀಠ',
'ಪಾದಪೂಜೆ',
'ಪಾದಬಂಧ',
'ಪಾದಮಾರ್ಗ',
'ಪಾದಮೂಲ',
'ಪಾದಮೂಲಿ',
'ಪಾದಮೂಳ',
'ಪಾದರಕ್ಕೆ',
'ಪಾದರಕ್ಷೆ',
'ಪಾದರಜ',
'ಪಾದರಸ',
'ಪಾದರಿಗ',
'ಪಾದರಿಗೆ',
'ಪಾದರಿಸು',
'ಪಾದವಟ್ಟ',
'ಪಾದವಾಸ',
'ಪಾದಶಾಖೆ',
'ಪಾದಶಾಯಿ',
'ಪಾದಶಾಹ',
'ಪಾದಶಿಲೆ',
'ಪಾದಶೂಲೆ',
'ಪಾದಷಹ',
'ಪಾದಷಹಾ',
'ಪಾದಷಾಹ',
'ಪಾದಸರ',
'ಪಾದಸಾಲೆ',
'ಪಾದಾಂಗದ',
'ಪಾದಾಘಾತ',
'ಪಾದಾತಿಕ',
'ಪಾದಾರ್ಚನೆ',
'ಪಾದಾವರ್ತ',
'ಪಾದಾಶ್ರಿತ',
'ಪಾದಾಶ್ರಿತೆ',
'ಪಾದಾಹತಿ',
'ಪಾದಿಲೋಣ',
'ಪಾದುಶಾಹ',
'ಪಾದುಷಹ',
'ಪಾದೂಯುಗ್ಮ',
'ಪಾದೋದಕ',
'ಪಾದೋದಕಿ',
'ಪಾದ್ಯದ್ರವ್ಯ',
'ಪಾದ್ಯವಿಧಿ',
'ಪಾನ್‍ದಾನು',
'ಪಾನ್‍ಪಟ್ಟಿ',
'ಪಾನಕೇಳಿ',
'ಪಾನಕ್ರೀಡೆ',
'ಪಾನಗೋಷ್ಠಿ',
'ಪಾನದಾನು',
'ಪಾನಪಟ್ಟಿ',
'ಪಾನಪಾತ್ರ',
'ಪಾನಪಾತ್ರೆ',
'ಪಾನವಾಟಿ',
'ಪಾನಿಪತ್ತು',
'ಪಾನೀಯಜ',
'ಪಾಪಕರ್ತ',
'ಪಾಪಕರ್ಮ',
'ಪಾಪಕೃತ್ಯ',
'ಪಾಪಕ್ಷಯ',
'ಪಾಪಗ್ರಹ',
'ಪಾಪಚಿತ್ತ',
'ಪಾಪಚೇಲಿ',
'ಪಾಪಟಿಕೆ',
'ಪಾಪಪ್ರಜ್ಞೆ',
'ಪಾಪಭೀತಿ',
'ಪಾಪಭೀರು',
'ಪಾಪರತ',
'ಪಾಪಸತ್ವ',
'ಪಾಪಹರ',
'ಪಾಪಹೃತ್ತು',
'ಪಾಪಾಂತಕ',
'ಪಾಪಾವಹ',
'ಪಾಪಾಸ್ರವ',
'ಪಾಪಿಯಸಿ',
'ಪಾಯಖಾನೆ',
'ಪಾಯಜಾಮ',
'ಪಾಯಿಜಾಮೆ',
'ಪಾಯದಳ',
'ಪಾಯದೞ',
'ಪಾಯದೂತ',
'ಪಾಯಬಂದ',
'ಪಾಯವಟ್ಟ',
'ಪಾಯಶುದ್ಧಿ',
'ಪಾಯಿಖಾನೆ',
'ಪಾಯಿಜಾಮ',
'ಪಾಯಿತರ್',
'ಪಾಯಿದಳ',
'ಪಾಯಿದೞ',
'ಪಾಯಿಪೋಸು',
'ಪಾಯಿವಟ್ಟ',
'ಪಾರಂಗತ',
'ಪಾರಂಗತೆ',
'ಪಾರಂಪರೆ',
'ಪಾರಂಪರ್ಯ',
'ಪಾರಂಪರ್ಯೆ',
'ಪಾರಗತ್ವ',
'ಪಾರಗಾಣು',
'ಪಾರಗೆಯ್',
'ಪಾರತಂತ್ರ್ಯ',
'ಪಾರತ್ರಯ',
'ಪಾರತ್ರಿಕ',
'ಪಾರದರ',
'ಪಾರದರ್ಶಿ',
'ಪಾರದಾರ',
'ಪಾರದಾರ್ಯ',
'ಪಾರದೃಶ್ಯ',
'ಪಾರದೃಶ್ವ',
'ಪಾರದ್ವಾರ',
'ಪಾರದ್ವಾರಿ',
'ಪಾರಪತ್ಯ',
'ಪಾರಪತ್ರ',
'ಪಾರಮಾರ್ಥ',
'ಪಾರಮೇಷ್ಠ್ಯ',
'ಪಾರಯಿಸು',
'ಪಾರವಶ್ಯ',
'ಪಾರವೆಯ್ದು',
'ಪಾರಶವ',
'ಪಾರಶಿಕ',
'ಪಾರಶೀಕ',
'ಪಾರಸವ',
'ಪಾರಸಿಕ',
'ಪಾರಸಿಗ',
'ಪಾರಸೀಕ',
'ಪಾರಳತೆ',
'ಪಾರಾಚೌಕಿ',
'ಪಾರಾಪತ',
'ಪಾರಾಯಣ',
'ಪಾರಾವತ',
'ಪಾರಾವಾರ',
'ಪಾರಾಶರ',
'ಪಾರಾಶರಿ',
'ಪಾರಿಕತ್ತು',
'ಪಾರಿಕಾಂಕ್ಷಿ',
'ಪಾರಿಕಾಯ',
'ಪಾರಿಖತ್ತು',
'ಪಾರಿಖಾಯ',
'ಪಾರಿಜಾತ',
'ಪಾರಿತಥ್ಯೆ',
'ಪಾರಿತೋಷ',
'ಪಾರಿಪಂಜು',
'ಪಾರಿಪಕ್ಷ',
'ಪಾರಿಪತ್ಯ',
'ಪಾರಿಪಾಟ್ಯ',
'ಪಾರಿಪ್ಲವ',
'ಪಾರಿಭದ್ರ',
'ಪಾರಿಭಾವ್ಯ',
'ಪಾರಿಯರ',
'ಪಾರಿಯಾತ್ರ',
'ಪಾರಿಯಾತ್ರೆ',
'ಪಾರಿರ್ಯಾರ',
'ಪಾರಿವಂಕ',
'ಪಾರಿವಟ್ಟೆ',
'ಪಾರಿವಾಣ',
'ಪಾರಿವಾಳ',
'ಪಾರಿವೇಂಟೆ',
'ಪಾರಿವ್ರಾಜ',
'ಪಾರಿವ್ರಾಜ್ಯ',
'ಪಾರಿಷದ',
'ಪಾರಿಹಾರ್ಯ',
'ಪಾರೀಕತ್ತು',
'ಪಾರೀಖತ್ತು',
'ಪಾರುಂಬಳೆ',
'ಪಾರುಖಾಣ',
'ಪಾರುಖಾಣೆ',
'ಪಾರುಗಡೆ',
'ಪಾರುಗಾಣು',
'ಪಾರುಗೆಯ್ತ',
'ಪಾರುಜಂಬು',
'ಪಾರುಪತ',
'ಪಾರುಪತ್ತೆ',
'ಪಾರುಪತ್ಯ',
'ಪಾರುವಾಣ',
'ಪಾರುವಿಳಿ',
'ಪಾರುವೇಟೆ',
'ಪಾರುಶದ',
'ಪಾರ್ಖವಣೆ',
'ಪಾರ್ಥಿವತೆ',
'ಪಾರ್ಥಿವತ್ವ',
'ಪಾರ್ಥೇನಿಯಂ',
'ಪಾರ್ಲಿಮೆಂಟು',
'ಪಾರ್ವತೇಯ',
'ಪಾರ್ಶ್ವಕ್ರಾಂತ',
'ಪಾರ್ಶ್ವಗತ',
'ಪಾರ್ಶ್ವಚರ',
'ಪಾರ್ಶ್ವಛೇದ',
'ಪಾರ್ಶ್ವನಾಥ',
'ಪಾರ್ಶ್ವವರ್ತಿ',
'ಪಾರ್ಶ್ವವಾಯು',
'ಪಾರ್ಶ್ವಶೂಲೆ',
'ಪಾರ್ಶ್ವಾಗತ',
'ಪಾಷ್ರ್ಣಿವಿದ್ಧ',
'ಪಾಲಕಾಪ್ಯ',
'ಪಾಲಡಕೆ',
'ಪಾಲಮನಿ',
'ಪಾಲಿವಾಣ',
'ಪಾಲುಗಾತಿ',
'ಪಾಲುಗಾರ',
'ಪಾಲುಗಾರ್ತಿ',
'ಪಾಲುಗುಣ',
'ಪಾಲುಗೂಡು',
'ಪಾಲುಗೊಳ್ಳು',
'ಪಾಲುಚೊಟ್ಟೆ',
'ಪಾಲುದಾರ',
'ಪಾಲುಮಾರು',
'ಪಾಲೋಗರ',
'ಪಾಲ್ಗಂದಲ್',
'ಪಾಲ್ಗಡಗ',
'ಪಾಲ್ಗಡಲ್',
'ಪಾಲ್ಗಡಲು',
'ಪಾಲ್ಗುಂಬಳ',
'ಪಾಲ್ಪಸುಳೆ',
'ಪಾವಟಿಕೆ',
'ಪಾವಟಿಗೆ',
'ಪಾವಡಿಗ',
'ಪಾವನತೆ',
'ಪಾವನತ್ವ',
'ಪಾವಮಾನಿ',
'ಪಾವರಣೆ',
'ಪಾವುಗುಲಿ',
'ಪಾವುಮೀನ್',
'ಪಾವುಮೆಕ್ಕೆ',
'ಪಾವುಸೇರು',
'ಪಾವೆಱೆಯ',
'ಪಾಶಪಾಣಿ',
'ಪಾಶಬಂಧ',
'ಪಾಶಯೋಗ',
'ಪಾಶವಾರ',
'ಪಾಶುವಾರು',
'ಪಾಶವಿಕ',
'ಪಾಶಹರ',
'ಪಾಶಹಸ್ತ',
'ಪಾಶಿಸಜಾ',
'ಪಾಶುಪತ',
'ಪಾಶುಪತಿ',
'ಪಾಶುಪಾಲ್ಯ',
'ಪಾಸುಂಪೊಕ್ಕುಂ',
'ಪಾಸುಂಬಲೆ',
'ಪಾಸುಗಲ್',
'ಪಾಸುಪೊಕ್ಕು',
'ಪಾಸುಪೊಕ್ಕುಂ',
'ಪಾಸುಪೋರ್ಟು',
'ಪಾಸುಮಂಚ',
'ಪಾಸುಮಣೆ',
'ಪಾಸುವಂಚ',
'ಪಾಸುವಲೆ',
'ಪಾಸುವೊಕ್ಕು',
'ಪಾಳಂಬಡು',
'ಪಾಳಡವಿ',
'ಪಾಳದಿವ್ಯ',
'ಪಾಳವಿಗ',
'ಪಾಳಿಗೆಡು',
'ಪಾಳಿದಪ್ಪು',
'ಪಾಳಿವೆಱು',
'ಪಾಳುಗೆಡೆ',
'ಪಾಳುಬೀಳು',
'ಪಾಳುಭೂಮಿ',
'ಪಾಳುಮನೆ',
'ಪಾಳೆಗಾರ',
'ಪಾಳೆನಿಸು',
'ಪಾಳೇಗಾರ',
'ಪಾಳ್ದೇಗುಲ',
'ಪಾಳ್ಯಗಾರ',
'ಪಾಳ್ಯಪಟ್ಟು',
'ಪಾಳ್ಯವಿಳಿ',
'ಪಾಳ್ಯೆಗಾರ',
'ಪಾಳ್ವಸಿಱ್',
'ಪಾಳ್ಳೆಗಾರ',
'ಪಾಱಿವೆಣ್',
'ಪಾಱುಂಗುಪ್ಪೆ',
'ಪಾಱುಂಬಳೆ',
'ಪಾಱುಗುಪ್ಪೆ',
'ಪಾಱುಗೆಡು',
'ಪಾಱುಗೆಯ್ತ',
'ಪಾಱುತನ',
'ಪಾೞಂಬಡು',
'ಪಾೞಿಕಾಱ',
'ಪಾೞಿಗಿಡು',
'ಪಾೞಿಗೆಡು',
'ಪಾೞುಗೃಹ',
'ಪಾೞುನೆಲ',
'ಪಾೞುಬಾವಿ',
'ಪಾೞೆನಿಸು',
'ಪಾೞ್ಗೆಡಪು',
'ಪಾೞ್ದೇಗುಲ',
'ಪಾೞ್ಮಸಗು',
'ಪಾೞ್ವಸಿಱ್',
'ಪಿಂಕಣಿಸು',
'ಪಿಂಕಳಕ',
'ಪಿಂಗಚಕ್ಷು',
'ಪಿಂಗಡಲು',
'ಪಿಂಗಳಿಕ',
'ಪಿಂಗಳಿಸು',
'ಪಿಂಗೊಳಿಸು',
'ಪಿಂಛಚ್ಛತ್ರ',
'ಪಿಂಜರಿತ',
'ಪಿಂಜರಿಸು',
'ಪಿಂಜಳಕ',
'ಪಿಂಜಾರಿಕೆ',
'ಪಿಂಜಾವಳಿ',
'ಪಿಂಡಂಗೆಯ್',
'ಪಿಂಡಗಲ್',
'ಪಿಂಡಗೊಳ್ಳು',
'ಪಿಂಡಚ್ಚನೆ',
'ಪಿಂಡಚ್ಛೇದ',
'ಪಿಂಡಪಾತ',
'ಪಿಂಡಪಾದ',
'ಪಿಂಡಮೂಲ',
'ಪಿಂಡರಂಗ',
'ಪಿಂಡರಿಕೆ',
'ಪಿಂಡಳಿಗೆ',
'ಪಿಂಡಾಕ್ಷರ',
'ಪಿಂಡಾದಾನ',
'ಪಿಂಡಾಯುಷ್ಯ',
'ಪಿಂಡಿಕಾಯಿ',
'ಪಿಂಡಿತಕ',
'ಪಿಂಡಿವಳ',
'ಪಿಂಡಿವಾಳ',
'ಪಿಂಡಿಶೂರ',
'ಪಿಂಡಿಸೊಪ್ಪು',
'ಪಿಂಡೀತಕ',
'ಪಿಂಡೀಶೂರ',
'ಪಿಂಡೀಸೊಪ್ಪು',
'ಪಿಂಡುಗೂಡು',
'ಪಿಂಡುಗೂೞ್',
'ಪಿಂಡುಗೊಳ್',
'ಪಿಂಡುಮಾತು',
'ಪಿಂಡೋತ್ಪತ್ತಿ',
'ಪಿಂತಿರಿಸು',
'ಪಿಂತಿರುಗು',
'ಪಿಂದಿರಿಸು',
'ಪಿಂದಿರುಗು',
'ಪಿಂದುಗಡೆ',
'ಪಿಂದುಗಳೆ',
'ಪಿಂದುಗೆಯ್',
'ಪಿಂದುಗೊಳ್',
'ಪಿಂಬಗಲ್',
'ಪಿಂಬಗಲು',
'ಪಿಂಬರೆಯ',
'ಪಿಂಬಾಲಿಸು',
'ಪಿಂಬೂಣಿಸು',
'ಪಿಕ್‍ನಿಕ್ಕು',
'ಪಿಕಜಾಲಿ',
'ಪಿಕನಿಕ್ಕು',
'ಪಿಕಲಾಟ',
'ಪಿಕಲಾರಿ',
'ಪಿಕಳಾರ',
'ಪಿಕಳಾರಿ',
'ಪಿಕೆಟಿಂಗು',
'ಪಿಕ್ಕಮಾತು',
'ಪಿಕ್ಕುಳಿಕ',
'ಪಿಕ್ಕುಳಿಕೆ',
'ಪಿಕ್ಕುಳಿಗ',
'ಪಿಗಕಿನ',
'ಪಿಚಂಡಿಲ',
'ಪಿಚಂಡಿಳ',
'ಪಿಚಕಾರಿ',
'ಪಿಚಕ್ಕನೆ',
'ಪಿಚಿಂಡಿಲ',
'ಪಿಚಿಗುಟ್ಟು',
'ಪಿಚುತೂಲ',
'ಪಿಚುಮಂದ',
'ಪಿಚುಮರ್ದ',
'ಪಿಚುಸೋದ',
'ಪಿಚ್ಚಂಬಕ',
'ಪಿಚ್ಚಕಾಯಿ',
'ಪಿಚ್ಚುಗಣ್ಣ',
'ಪಿಚ್ಚುಗಣ್ಣು',
'ಪಿಚ್ಚೆನ್ನಿಸು',
'ಪಿಚ್ಚಂದಕ',
'ಪಿಟಗುಟ್ಟು',
'ಪಿಟಿಪಿಟಿ',
'ಪಿಟುಗುಟ್ಟು',
'ಪಿಟ್ಟುಕಾರ',
'ಪಿಟ್ಟುಗುಟ್ಟು',
'ಪಿಟ್ಟುಗೆಯ್',
'ಪಿಡಿಕಟ್ಟು',
'ಪಿಡಿಕಲ್',
'ಪಿಡಿಕಿಸು',
'ಪಿಡಿಕುತ್ತು',
'ಪಿಡಿಕೆಯ್',
'ಪಿಡಿಕೆಯ್ಸು',
'ಪಿಡಿಕೈಸು',
'ಪಿಡಿಗಟ್ಟು',
'ಪಿಡಿಗಲ್',
'ಪಿಡಿಗುಂಡು',
'ಪಿಡಿಗುದ್ದು',
'ಪಿಡಿಗೋಲ್',
'ಪಿಡಿಗೋಲು',
'ಪಿಡಿತರ್',
'ಪಿಡಿನಡು',
'ಪಿಡಿನಡೆ',
'ಪಿಡಿಪಡು',
'ಪಿಡಿಪೆಱು',
'ಪಿಡಿಯಾಳ್',
'ಪಿಡಿಯಾಳು',
'ಪಿಡಿಯಿಸು',
'ಪಿಡಿವಗೆ',
'ಪಿಡಿವಡು',
'ಪಿಡಿವಡೆ',
'ಪಿಡಿವಲೆ',
'ಪಿಡಿವಿಡಿ',
'ಪಿಡಿವೆಱು',
'ಪಿಡಿಹಾರ',
'ಪಿಣಕುಟಿ',
'ಪಿಣಕುಟೆ',
'ಪಿಣಿಲಿಱಿ',
'ಪಿಣಿಲೇಱು',
'ಪಿಣಿಲ್ಗಂಡ',
'ಪಿತಲಾಟ',
'ಪಿತಾಮಹ',
'ಪಿತೃಋಣ',
'ಪಿತೃಕರ್ಮ',
'ಪಿತೃಕಾರ್ಯ',
'ಪಿತೃಗ್ರಹ',
'ಪಿತೃದಾನ',
'ಪಿತೃದಿನ',
'ಪಿತೃಧನ',
'ಪಿತೃಪಕ್ಷ',
'ಪಿತೃಪತಿ',
'ಪಿತೃಪ್ರಸು',
'ಪಿತೃಭಕ್ತಿ',
'ಪಿತೃಭೂಮಿ',
'ಪಿತೃಮೇಧ',
'ಪಿತೃಯಜ್ಞ',
'ಪಿತೃಯಾಣ',
'ಪಿತೃಯಾನ',
'ಪಿತೃರೇಖೆ',
'ಪಿತೃಲೋಕ',
'ಪಿತೃವನ',
'ಪಿತೃವಿತ್ತ',
'ಪಿತೃಶೇಷ',
'ಪಿತ್ತಕಲ್ಲು',
'ಪಿತ್ತಕೋಶ',
'ಪಿತ್ತಕ್ಷೋಭ',
'ಪಿತ್ತಜಿಹ್ವೆ',
'ಪಿತ್ತಜ್ವರ',
'ಪಿತ್ತದೋಷ',
'ಪಿತ್ತನಾಡಿ',
'ಪಿತ್ತರಸ',
'ಪಿತ್ತರೋಗ',
'ಪಿತ್ತರೋಗಿ',
'ಪಿತ್ತಲಾಟ',
'ಪಿತ್ತವೇಱು',
'ಪಿತ್ತಸನ್ನಿ',
'ಪಿತ್ತಹರ',
'ಪಿತ್ತಾಧಿಕ',
'ಪಿತ್ತಾಧಿಕ್ಯ',
'ಪಿತ್ತಾವಿಳ',
'ಪಿತ್ತಾಶಯ',
'ಪಿತ್ತೋದರ',
'ಪಿತ್ತೋದ್ರೇಕ',
'ಪಿತ್ರಾರ್ಜಿತ',
'ಪಿತ್ರುಧನ',
'ಪಿನಪಾಲ',
'ಪಿನಿಕ್ಷೀರ',
'ಪಿನಿವಾಲ',
'ಪಿನಿಹುಲ್ಲು',
'ಪಿನ್ನಿವಾಲ',
'ಪಿನ್ನೀಕ್ಷಿಸು',
'ಪಿನ್ನೆಣಿಕೆ',
'ಪಿನ್ನೇಕಾಯಿ',
'ಪಿನ್ನೇಸಱು',
'ಪಿಪಾಸಿತ',
'ಪಿಪಾಸಿತೆ',
'ಪಿಪೀಲಕ',
'ಪಿಪೀಲಿಕ',
'ಪಿಪೀಲಿಕೆ',
'ಪಿಪ್ಪಲಿಗ',
'ಪಿರಕಣೆ',
'ಪಿರಚಲು',
'ಪಿರಮಿಡ್ಡು',
'ಪಿರಿತನ',
'ಪಿರಿತಿನಿ',
'ಪಿರಿಯಕ್ಕ',
'ಪಿರಿಯಣ್ಣ',
'ಪಿರಿಯಬ್ಬೆ',
'ಪಿರಿಯಯ್ಯ',
'ಪಿರಿಯರ',
'ಪಿರಿಯಾದಿ',
'ಪಿರುಕಣೆ',
'ಪಿಲ್ಲಣಿಗೆ',
'ಪಿಶಂಗತೆ',
'ಪಿಶಂಗಾಶ್ಮ',
'ಪಿಶಂಗಿತ',
'ಪಿಶನಾರಿ',
'ಪಿಶಾಚಸ್ತ್ರೀ',
'ಪಿಶಾಚಿಕೆ',
'ಪಿಶಿತಾಶ',
'ಪಿಶಿತಾಶಿ',
'ಪಿಶುನತ್ವ',
'ಪಿಷ್ಟಕುಟ್ಟ',
'ಪಿಷ್ಟಚರ್ಚೆ',
'ಪಿಷ್ಟಾತಕ',
'ಪಿಸನಾರಿ',
'ಪಿಸಿತಾಂಗ',
'ಪಿಸಿಪಿಸಿ',
'ಪಿಸುಗುಟ್ಟು',
'ಪಿಸುಗುಡು',
'ಪಿಸುಣತೆ',
'ಪಿಸುಣತ್ವ',
'ಪಿಸುಣಾಡು',
'ಪಿಸುಣಿಗ',
'ಪಿಸುಣಿಸು',
'ಪಿಸುಣ್ಗೆಡೆ',
'ಪಿಸುಣ್ಬೇೞ್',
'ಪಿಸುದನಿ',
'ಪಿಸುನುಡಿ',
'ಪಿಸುಮಾತು',
'ಪಿಸುವಾತು',
'ಪಿಳಕಾರಿ',
'ಪಿಳಕಿಸು',
'ಪಿಳಚಲು',
'ಪಿಳಿಕಿಸು',
'ಪಿಳಿಪಿಳಿ',
'ಪಿಳುಕಿಸು',
'ಪಿಳ್ಪಳನೆ',
'ಪಿಳ್ಳಂಗೋವಿ',
'ಪಿಳ್ಳಂಜುಟ್ಟು',
'ಪಿಳ್ಳಗುಸೆ',
'ಪಿಳ್ಳುಜುಟ್ಟು',
'ಪಿಳ್ಳೆಕಾಲು',
'ಪಿಱಿತಿನಿ',
'ಪಿೞಿಯಿಸು',
'ಪೀಕಜಾಲಿ',
'ಪೀಕದಾಣಿ',
'ಪೀಕದಾನಿ',
'ಪೀಕಲಾಟ',
'ಪೀಕುದಾನಿ',
'ಪೀಚಕತ್ತಿ',
'ಪೀಠಮರ್ದ',
'ಪೀಡನಕ',
'ಪೀಡಿತಕ',
'ಪೀಡಿಸುಹ',
'ಪೀಡೆಗೆಯ್',
'ಪೀಡೆಗೊಳ್',
'ಪೀತಕಾಂತ',
'ಪೀತಗಂಧ',
'ಪೀತದಾರು',
'ಪೀತನಕ',
'ಪೀತಪುಷ್ಪ',
'ಪೀತಫೇನ',
'ಪೀತವಾಸ',
'ಪೀತಸಾರ',
'ಪೀತಸಾಲ',
'ಪೀತಾಂಬರ',
'ಪೀತಾಂಶುಕ',
'ಪೀನೋದರ',
'ಪೀರಖಾನಿ',
'ಪೀರಿಯಡ್ಡು',
'ಪೀಲಿಗಣ್',
'ಪೀಲಿಗೊಡೆ',
'ಪೀಲಿದಳೆ',
'ಪೀಲಿದೞೆ',
'ಪೀಲುಪರ್ಣಿ',
'ಪೀವರೋಧ್ನಿ',
'ಪೀಳ್ಕುದುರೆ',
'ಪುಂಜಗೊಳ್',
'ಪುಂಜಣಿಗ',
'ಪುಂಡಗಾತಿ',
'ಪುಂಡಗಾರ',
'ಪುಂಡಗಾರ್ತಿ',
'ಪುಂಡತನ',
'ಪುಂಡರೀಕ',
'ಪುಂಡಾಟಿಕೆ',
'ಪುಂಡುಗಾರ',
'ಪುಂಡುಗಾಱ',
'ಪುಂಡುತನ',
'ಪುಂಡ್ರಲಕ್ಷ್ಮ',
'ಪುಂಡ್ರಾಂಬುಜ',
'ಪುಂಸವನ',
'ಪುಂಸ್ತ್ರೀಲಿಂಗ',
'ಪುಕಪುಕ',
'ಪುಕಸಟ್ಟೆ',
'ಪುಕಸಾಟೆ',
'ಪುಕಾರಿಸು',
'ಪುಕ್ಕತನ',
'ಪುಕ್ಕಸಾಟೆ',
'ಪುಗಸಟ್ಟೆ',
'ಪುಗಸಾಟೆ',
'ಪುಗಿಯಿಸು',
'ಪುಗಿಲ್ಗರೆ',
'ಪುಗಿಲ್ವಟ್ಟೆ',
'ಪುಗಿಲ್ವಿಡು',
'ಪುಗುತರ್',
'ಪುಗುಳೇಳ್',
'ಪುಗುಳೇೞ್',
'ಪುಗುಳೊಡೆ',
'ಪುಗುಳ್ಗುತ್ತ',
'ಪುಗ್ಗಾಯಿಲ',
'ಪುಚ್ಚವಣ',
'ಪುಚ್ಚವಣೆ',
'ಪುಚ್ಚಳಿಸು',
'ಪುಚ್ಚೞಿಸು',
'ಪುಚ್ಚುಗುನ್ನಿ',
'ಪುಚ್ಚುಗೊಳ್',
'ಪುಚ್ಚುತನ',
'ಪುಟಂನೆಗೆ',
'ಪುಟಕೊಡು',
'ಪುಟಕೋಸಿ',
'ಪುಟಕ್ಕನೆ',
'ಪುಟಗೊಡು',
'ಪುಟಗೋಸಿ',
'ಪುಟದೋಷ',
'ಪುಟದೋಱು',
'ಪುಟನೆಗೆ',
'ಪುಟಪಡು',
'ಪುಟಪಾಕ',
'ಪುಟಬಾಳೆ',
'ಪುಟಬೀಜ',
'ಪುಟಭೇದ',
'ಪುಟಮಿಕ್ಕು',
'ಪುಟಮಿಗು',
'ಪುಟವಿಕ್ಕು',
'ಪುಟವಿಡು',
'ಪುಟವಿಳಿ',
'ಪುಟವೇಳ್',
'ಪುಟವೇಳು',
'ಪುಟವೇಱು',
'ಪುಟವೇೞ್',
'ಪುಟಿಯಿಸು',
'ಪುಟುಗುಟ್ಟು',
'ಪುಟ್ಟಬಾಳೆ',
'ಪುಟ್ಟವಳ',
'ಪುಟ್ಟಱಿವು',
'ಪುಟ್ಟಿಗೋಲ್',
'ಪುಟ್ಟಿಹಣ್ಣು',
'ಪುಟ್ಟುಂಗವಿ',
'ಪುಟ್ಟುಗಣಿ',
'ಪುಟ್ಟುವೆಟ್ಟು',
'ಪುಟ್ಟೇಹಣ್ಣು',
'ಪುಡವಿಪ',
'ಪುಡಿಕಾಸು',
'ಪುಡಿಖರ್ಚು',
'ಪುಡಿಗಾಸು',
'ಪುಡಿಗುಟ್ಟು',
'ಪುಡಿಗೆಯ್',
'ಪುಡಿಚಟ್ಣಿ',
'ಪುಡಿದುಡ್ಡು',
'ಪುಡಿಯಾಟ',
'ಪುಡಿಯಾಡು',
'ಪುಡಿಯಿಡು',
'ಪುಡಿಯೇಳು',
'ಪುಡಿವಡು',
'ಪುಡಿವಡೆ',
'ಪುಡಿವಿಟ್ಟು',
'ಪುಡಿವಿಡು',
'ಪುಡಿಹಣ',
'ಪುಡ್ತಕೇಶ',
'ಪುಢಾಕಾರ',
'ಪುಢಾರಿಕೆ',
'ಪುಣಿಚೇೞ್',
'ಪುಣಿವುಲ್',
'ಪುಣ್ಬಡಿಸು',
'ಪುಣ್ಬೊಲಸು',
'ಪುಣ್ಯಂಗೆಯ್',
'ಪುಣ್ಯಂಗೊಳ್',
'ಪುಣ್ಯಕರ್ಮ',
'ಪುಣ್ಯಕಾರಿ',
'ಪುಣ್ಯಕ್ಷೇತ್ರ',
'ಪುಣ್ಯಜನ',
'ಪುಣ್ಯತಿಥಿ',
'ಪುಣ್ಯದಿನ',
'ಪುಣ್ಯಫಲ',
'ಪುಣ್ಯಭೂಮಿ',
'ಪುಣ್ಯಲೋಕ',
'ಪುಣ್ಯವಂತ',
'ಪುಣ್ಯವಂತೆ',
'ಪುಣ್ಯವತಿ',
'ಪುಣ್ಯಶ್ಲೋಕ',
'ಪುಣ್ಯಶ್ಲೋಕೆ',
'ಪುಣ್ಯಾಂಗನೆ',
'ಪುಣ್ಯಾತ್ಗಿತ್ತಿ',
'ಪುಣ್ಯಾಸ್ರವ',
'ಪುತ್ತಲಿಕೆ',
'ಪುತ್ತಳಿಗೆ',
'ಪುತ್ತೞಿಗೆ',
'ಪುತ್ತುಂಗಾಲ್',
'ಪುತ್ಥಳಿಕೆ',
'ಪುತ್ರಂಜೀವ',
'ಪುತ್ರಂಜೀವಿ',
'ಪುತ್ರಂಬಡೆ',
'ಪುತ್ರಕಾಮ್ಯ',
'ಪುತ್ರಜೀವಿ',
'ಪುತ್ರದಾತ್ರಿ',
'ಪುತ್ರವಂತ',
'ಪುತ್ರವತಿ',
'ಪುತ್ರವಧು',
'ಪುದಿವಟು',
'ಪುದಿವಟ್ಟು',
'ಪುದಿವೆಟು',
'ಪುದಿವೊಟ್ಟು',
'ಪುದುಂಗಿಸು',
'ಪುದುಂಗೊಳ್',
'ಪುದುಗಿಸು',
'ಪುದುಗುಡು',
'ಪುದುಗೂಡು',
'ಪುದುಗೊಳ್',
'ಪುದುಮನೆ',
'ಪುದುವಟ್ಟು',
'ಪುದುವರ್',
'ಪುದುವಾಳ್',
'ಪುದುವಾಳು',
'ಪುದುವಾಳ್ಕೆ',
'ಪುದುವಾೞ್',
'ಪುದುವಾೞ್ಕೆ',
'ಪುದುವಿರ್',
'ಪುದುವುಗು',
'ಪುದುವೆಱು',
'ಪುದುವೋಗು',
'ಪುನರಪಿ',
'ಪುನರುಕ್ತ',
'ಪುನರುಕ್ತಿ',
'ಪುನರ್ಜನ್ಮ',
'ಪುನರ್ಜಾತ',
'ಪುನರ್ನವ',
'ಪುನರ್ನವಿ',
'ಪುನರ್ನವೆ',
'ಪುನರ್ಪುಳಿ',
'ಪುನರ್ಭವ',
'ಪುನರ್ವಸು',
'ಪುನ್ಪಯಿರು',
'ಪುಪ್ಪುಲವೆ',
'ಪುಮಪತ್ಯ',
'ಪುಯಲ್ದೋರು',
'ಪುಯಿಲಿಡು',
'ಪುಯ್ಯಲಿಕ್ಕು',
'ಪುಯ್ಯಲಿಗ',
'ಪುಯ್ಯಲಿಡು',
'ಪುಯ್ಯಲಿಸು',
'ಪುಯ್ಯಲ್ವರಿ',
'ಪುಯ್ಯಲ್ವೋಗು',
'ಪುಯ್ಯೆನಿಸು',
'ಪುಯ್ಯೇೞಿಸು',
'ಪುರಂದರ',
'ಪುರಃಸರ',
'ಪುರಕ್ಷಯ',
'ಪುರಗಣ',
'ಪುರಜನ',
'ಪುರತ್ರಯ',
'ಪುರದ್ರಮ್ಮ',
'ಪುರದ್ವಾರ',
'ಪುರರಿಪಿ',
'ಪುರರಿಪು',
'ಪುರವಂತ',
'ಪುರವಟೆ',
'ಪುರವಠೆ',
'ಪುರವಣಿ',
'ಪುರವರ್ಗ',
'ಪುರವಾಸಿ',
'ಪುರವೈರಿ',
'ಪುರಸತ್ತು',
'ಪುರಸಭೆ',
'ಪುರಸೊತ್ತು',
'ಪುರಸ್ಕರ್ತ',
'ಪುರಸ್ಕಾರ',
'ಪುರಸ್ಕೃತ',
'ಪುರಸ್ಕೃತೆ',
'ಪುರಸ್ಸರ',
'ಪುರಸ್ಸರಂ',
'ಪುರಹರ',
'ಪುರಾಂಗನೆ',
'ಪುರಾಕೃತ',
'ಪುರಾಟಿಕ',
'ಪುರಾಣಿಕ',
'ಪುರಾತತ್ವ',
'ಪುರಾತನ',
'ಪುರಾಭವ',
'ಪುರಾವೃತ್ತ',
'ಪುರಿಗಣೆ',
'ಪುರಿಗೆಯ್',
'ಪುರಿಗೊಳ್',
'ಪುರಿನೇಣ್',
'ಪುರಿಯಿಡು',
'ಪುರಿಯೇಱು',
'ಪುರಿಯೋಡು',
'ಪುರೀತತ್ತು',
'ಪುರುಚಲಿ',
'ಪುರುಚಲು',
'ಪುರುಡಿಗ',
'ಪುರುಡಿಸು',
'ಪುರುಪಕ್ಷ',
'ಪುರುಪಾತ್ರ',
'ಪುರುಪೃಷ್ಠ',
'ಪುರುವಳ್ಳಿ',
'ಪುರುಷತ್ವ',
'ಪುರುಷಾಂಗ',
'ಪುರುಷಾಣು',
'ಪುರುಷಾದ',
'ಪುರುಷಾರ್ಥ',
'ಪುರುಷಾರ್ಥಿ',
'ಪುರುಸತ್ತು',
'ಪುರುಸೊತ್ತು',
'ಪುರುಹೂತ',
'ಪುರುಳ್ಗಾಣ್',
'ಪುರುಳ್ಗೊಳ್',
'ಪುರೆಯಿಡು',
'ಪುರೋಖ್ತಾನಿ',
'ಪುರೋಗಮ',
'ಪುರೋಗಾಮಿ',
'ಪುರೋಡಾಶ',
'ಪುರೋಧಸ',
'ಪುರೋಭಾಗ',
'ಪುರೋಭಾಗಿ',
'ಪುರೋಹಿತ',
'ಪುರ್ಚುಗೊಳ್',
'ಪುರ್ಚುವೊಳೆ',
'ಪುರ್ಬೇರಿಸು',
'ಪುಲಕಿತ',
'ಪುಲಕಿತೆ',
'ಪುಲಕಿಸು',
'ಪುಲವಡು',
'ಪುಲಿಂಗಿಲ್',
'ಪುಲಿಂದಿನಿ',
'ಪುಲಿಕಲ್ಲು',
'ಪುಲಿಗಿಲ್',
'ಪುಲಿಗಿಲು',
'ಪುಲಿಗಿಲೆ',
'ಪುಲಿಚರ್ಮ',
'ಪುಲಿದೋಲ್',
'ಪುಲಿನಖ',
'ಪುಲಿಪಟು',
'ಪುಲಿಮುಖ',
'ಪುಲಿಮೊಗ',
'ಪುಲಿಯೂಟು',
'ಪುಲಿವಟ',
'ಪುಲಿವಟೆ',
'ಪುಲಿವಟ್ಟೆ',
'ಪುಲಿವಲೆ',
'ಪುಲಿವೆಟ್ಟು',
'ಪುಲಿವೇಟು',
'ಪುಲಿಸಮ್ಮ',
'ಪುಲುಗರ್ಚು',
'ಪುಲುಬೂತು',
'ಪುಲುಮನೆ',
'ಪುಲುಮೃಗ',
'ಪುಲುವಟ್ಟೆ',
'ಪುಲುವಿಲು',
'ಪುಲುವೀಡು',
'ಪುಲುಸೋಗು',
'ಪುಲ್ಮಾನಸ',
'ಪುಲ್ಲಡಕಿ',
'ಪುಲ್ಲವಢ',
'ಪುಲ್ಲವಿಸು',
'ಪುಲ್ಲಿಪಟ್ಟೆ',
'ಪುಲ್ಲಿವೆಟು',
'ಪುಲ್ಲಿವೇಟು',
'ಪುಲ್ವಾನಿಸ',
'ಪುಲ್ಸರವಿ',
'ಪುಷ್ಕರಜ',
'ಪುಷ್ಕರಿಣಿ',
'ಪುಷ್ಟಿಕರ',
'ಪುಷ್ಟಿಕಾರಿ',
'ಪುಷ್ಟಿಕೊಡು',
'ಪುಷ್ಟಿಮಾರ್ಗ',
'ಪುಷ್ಪಂಗತಿ',
'ಪುಷ್ಪಕೇತು',
'ಪುಷ್ಪದಂತ',
'ಪುಷ್ಪದಾಮ',
'ಪುಷ್ಪಪುಟ',
'ಪುಷ್ಪಫಲ',
'ಪುಷ್ಪಬಾಣ',
'ಪುಷ್ಪಮಾಲೆ',
'ಪುಷ್ಪಮಾಸ',
'ಪುಷ್ಪರಥ',
'ಪುಷ್ಪರಸ',
'ಪುಷ್ಪರಾಗ',
'ಪುಷ್ಪರಾಜ',
'ಪುಷ್ಪಲತೆ',
'ಪುಷ್ಪಲಾವ',
'ಪುಷ್ಪಲಾವಿ',
'ಪುಷ್ಪಲಿಹ',
'ಪುಷ್ಪವಂತ',
'ಪುಷ್ಪವತಿ',
'ಪುಷ್ಪವರ್ಷ',
'ಪುಷ್ಪವಸೆ',
'ಪುಷ್ಪವಾಟ',
'ಪುಷ್ಪವಾಟಿ',
'ಪುಷ್ಪವಾಸ',
'ಪುಷ್ಪವೃಷ್ಟಿ',
'ಪುಷ್ಪಶಯ್ಯೆ',
'ಪುಷ್ಪಸ್ರಜ',
'ಪುಷ್ಪಹೀನೆ',
'ಪುಷ್ಪಾಂಜನ',
'ಪುಷ್ಪಾಂಜಲಿ',
'ಪುಷ್ಪಾಂಜಳಿ',
'ಪುಷ್ಪಾಂಜುಲಿ',
'ಪುಷ್ಪಾಂಜುಳಿ',
'ಪುಷ್ಪಾಯುಧ',
'ಪುಷ್ಪಿತಾಗ್ರ',
'ಪುಷ್ಯರಾಗ',
'ಪುಸಕ್ಕನೆ',
'ಪುಸಲತ್ತು',
'ಪುಸಿಗಂಡು',
'ಪುಸಿಗಾಱ',
'ಪುಸಿಗೂರ್ಮೆ',
'ಪುಸಿಗೆಳೆ',
'ಪುಸಿದೈವ',
'ಪುಸಿಧರ್ಮ',
'ಪುಸಿನಡು',
'ಪುಸಿನಲ್ಮೆ',
'ಪುಸಿನುಡಿ',
'ಪುಸಿಬಾದಿ',
'ಪುಸಿಯಮೆ',
'ಪುಸಿಯಾಡು',
'ಪುಸಿಯೋತ',
'ಪುಸಿವಾತು',
'ಪುಸಿವುಗು',
'ಪುಸಿವೇಳ್',
'ಪುಸಿವೋಗು',
'ಪುಸುಕಲ',
'ಪುಸುಕಲು',
'ಪುಸ್ತಕರ್ಮ',
'ಪುಳಕಿತ',
'ಪುಳಕಿತೆ',
'ಪುಳಕಿಸು',
'ಪುಳಿಂದಕ',
'ಪುಳಿಂದಿನಿ',
'ಪುಳಿಕೊಟ್ಟೆ',
'ಪುಳಿಗರ್ಚು',
'ಪುಳಿಚಾರು',
'ಪುಳಿಚಾಱು',
'ಪುಳಿಚೇಳ್',
'ಪುಳಿಚೇೞ್',
'ಪುಳಿದೇಗು',
'ಪುಳಿದೊಡೆ',
'ಪುಳಿದೊಳೆ',
'ಪುಳಿಬೋರೆ',
'ಪುಳಿಮಾವು',
'ಪುಳಿಮೂಟೆ',
'ಪುಳಿಯನ್ನ',
'ಪುಳಿಯೋರೆ',
'ಪುಳಿವಿಳಿ',
'ಪುಳಿವುಲ್',
'ಪುಳಿಹೀರೆ',
'ಪುಳುಂಗಾಱ',
'ಪುಳುಕೊಂಡ',
'ಪುಳುಗುಂಡ',
'ಪುಳುಗೊಂಡ',
'ಪುಳುವಗೆ',
'ಪುಳ್ಳೆಮಾತು',
'ಪುೞಿಲ್ದಾಣ',
'ಪುೞುವಗೆ',
'ಪೂಕುತಿತ್ತಿ',
'ಪೂಗಣೆಯ',
'ಪೂಗನಸು',
'ಪೂಗಫಲ',
'ಪೂಗಫಳ',
'ಪೂಗವನ',
'ಪೂಗಿಫಲ',
'ಪೂಗುವರ',
'ಪೂಗೊಂಚಲ್',
'ಪೂಗೊಂಚಲು',
'ಪೂಜಕಾರಿ',
'ಪೂಜಾಗಾರ',
'ಪೂಜಾದ್ರವ್ಯ',
'ಪೂಜಾಫಲ',
'ಪೂಜಾಮದ',
'ಪೂಜಾರಿಕೆ',
'ಪೂಜಾವಿಧಿ',
'ಪೂಜೆಗಟ್ಟು',
'ಪೂಜೆಗೆಯ್',
'ಪೂಜೆಗೈಸು',
'ಪೂಜೆಗೊಳ್',
'ಪೂಜೆವಂತ',
'ಪೂಜೆವಡೆ',
'ಪೂಜ್ಯಪದ',
'ಪೂಜ್ಯಪಾದ',
'ಪೂಜ್ಯಭಾವ',
'ಪೂಜ್ಯವಂತ',
'ಪೂಣ್ಕೆಗೆಯ್',
'ಪೂಣ್ಕೆದಪ್ಪು',
'ಪೂತಿಕಾಷ್ಠ',
'ಪೂತಿಗಂಧ',
'ಪೂತಿಗಂಧಿ',
'ಪೂತಿಫಲಿ',
'ಪೂತಿಬೆಕ್ಕು',
'ಪೂತಿಮಾಂಸ',
'ಪೂತಿವಕ್ತ್ರ',
'ಪೂತಿವೆಕ್ಕು',
'ಪೂತ್ಕರಿಸು',
'ಪೂತ್ಕೃತಿಗೈ',
'ಪೂದಿಂಗಳ್',
'ಪೂದುಱುಂಬು',
'ಪೂದುಱುಬು',
'ಪೂದೊಂಗಲ್',
'ಪೂದೊಂಗಲು',
'ಪೂದೊಡಂಬೆ',
'ಪೂಲಪೊಕೆ',
'ಪೂಪುಟ್ಟಿಗೆ',
'ಪೂಪುಣುಂಬ',
'ಪೂಪುಣುಂಬು',
'ಪೂಪುರುಳ್',
'ಪೂಪೊಡೆಯ',
'ಪೂಮಲಗು',
'ಪೂರಂಗವಿ',
'ಪೂರಂಗೊಳ್',
'ಪೂರಯಿಕೆ',
'ಪೂರಯಿಸು',
'ಪೂರವಾರ',
'ಪೂರವಿಸು',
'ಪೂರಾಯತ',
'ಪೂರ್ಣಕಂಠ',
'ಪೂರ್ಣಕಾಮ',
'ಪೂರ್ಣಕುಂಭ',
'ಪೂರ್ಣಗೊಳ್ಳು',
'ಪೂರ್ಣಚಂದ್ರ',
'ಪೂರ್ಣಜ್ವರ',
'ಪೂರ್ಣದೃಷ್ಟಿ',
'ಪೂರ್ಣಪಾತ್ರ',
'ಪೂರ್ಣಪ್ರಜ್ಞ',
'ಪೂರ್ಣಫಲ',
'ಪೂರ್ಣಮಾಸ',
'ಪೂರ್ಣಮಾಸಿ',
'ಪೂರ್ಣಷಡ್ಜ',
'ಪೂರ್ಣಾಯುಸ್ಸು',
'ಪೂರ್ಣಾಹುತಿ',
'ಪೂರ್ಣಿಮಾಂತ',
'ಪೂರ್ಣೋಪಮೆ',
'ಪೂರ್ವಕಕ್ಷೆ',
'ಪೂರ್ವಕರ್ಮ',
'ಪೂರ್ವಕಾಲ',
'ಪೂರ್ವಕೃತ',
'ಪೂರ್ವಕೋಟಿ',
'ಪೂರ್ವಗತ',
'ಪೂರ್ವಗೌಳ',
'ಪೂರ್ವಗ್ರಹ',
'ಪೂರ್ವಜನ್ಮ',
'ಪೂರ್ವಜ್ಞಾನ',
'ಪೂರ್ವತತ್ತು',
'ಪೂರ್ವದಿಕ್ಕು',
'ಪೂರ್ವದಿನ',
'ಪೂರ್ವದೇವ',
'ಪೂರ್ವಧರ',
'ಪೂರ್ವನಾಡಿ',
'ಪೂರ್ವನೇತ್ರ',
'ಪೂರ್ವಪಂಕ್ತಿ',
'ಪೂರ್ವಪಕ್ಷ',
'ಪೂರ್ವಪಕ್ಷಿ',
'ಪೂರ್ವಪದ',
'ಪೂರ್ವಪುಣ್ಯ',
'ಪೂರ್ವಪ್ರಜ್ಞೆ',
'ಪೂರ್ವಭವ',
'ಪೂರ್ವಭಾಗ',
'ಪೂರ್ವಭಾವಿ',
'ಪೂರ್ವಭಾಷಿ',
'ಪೂರ್ವರಂಗ',
'ಪೂರ್ವರಾಗ',
'ಪೂರ್ವರಾತ್ರಿ',
'ಪೂರ್ವರಾತ್ರೆ',
'ಪೂರ್ವರೂಪ',
'ಪೂರ್ವವರ್ಣ',
'ಪೂರ್ವವಾದ',
'ಪೂರ್ವವಾದಿ',
'ಪೂರ್ವವೃತ್ತಿ',
'ಪೂರ್ವವೇದಿ',
'ಪೂರ್ವವೈರ',
'ಪೂರ್ವವೈರಿ',
'ಪೂರ್ವಶೈವ',
'ಪೂರ್ವಸಂಧ್ಯೆ',
'ಪೂರ್ವಸುರ',
'ಪೂರ್ವಸೂರಿ',
'ಪೂರ್ವಸ್ಥಿತಿ',
'ಪೂರ್ವಸ್ವರ',
'ಪೂರ್ವಹಗೆ',
'ಪೂರ್ವಾಚಲ',
'ಪೂರ್ವಾಚಳ',
'ಪೂರ್ವಾಚಾರ',
'ಪೂರ್ವಾಚಾರ್ಯ',
'ಪೂರ್ವಾದತ್ತ',
'ಪೂರ್ವಾಪರ',
'ಪೂರ್ವಾಪೂರ್ವ',
'ಪೂರ್ವಾಭಾದ್ರ',
'ಪೂರ್ವಾಭಾದ್ರೆ',
'ಪೂರ್ವಾಭ್ಯಾಸ',
'ಪೂರ್ವಾಮರ',
'ಪೂರ್ವಾರ್ಜಿತ',
'ಪೂರ್ವಾಶ್ರಮ',
'ಪೂರ್ವಾಷಾಢ',
'ಪೂರ್ವೇತರ',
'ಪೂರ್ವೋತ್ತರ',
'ಪೂರ್ವೋದಿತ',
'ಪೂವಡಿಗ',
'ಪೂವಣಿಗ',
'ಪೂವರಸೆ',
'ಪೂವಸರ',
'ಪೂವೊರ್ಕುೞ',
'ಪೂಸತನ',
'ಪೂಸರಲ್',
'ಪೂಸರಲ',
'ಪೂಸರಲು',
'ಪೂಸರಳ',
'ಪೂಸರಳು',
'ಪೂಸಿಮಾತು',
'ಪೃಥಕ್ಪರ್ಣಿ',
'ಪೃಥಗ್ಜನ',
'ಪೃಥಗ್ಭಾವ',
'ಪೃಥಿವಿಪ',
'ಪೃಥುಕತ್ವ',
'ಪೃಥುರೋಮ',
'ಪೃಥ್ವಿತತ್ವ',
'ಪೃಥ್ವಿಗೋಳ',
'ಪೃಥ್ವೀತತ್ವ',
'ಪೃಥ್ವೀಧವ',
'ಪೃಥ್ವೀಪಾಲ',
'ಪೃಥ್ವೀಶ್ವರ',
'ಪೃಶ್ನಿಪರ್ಣಿ',
'ಪೃಷದಶ್ವ',
'ಪೃಷ್ಠಗ್ರಂಥಿ',
'ಪೃಷ್ಠಚಕ್ಷು',
'ಪೃಷ್ಠದ್ವಾರ',
'ಪೃಷ್ಠನಾಳ',
'ಪೃಷ್ಠಭಾಗ',
'ಪೃಷ್ಠವಾಹ್ಯ',
'ಪೃಷ್ಠೋತ್ಕ್ಷೇಪ',
'ಪೆಂಕುಳಿಗ',
'ಪೆಂಗ್‍ನಾಮ',
'ಪೆಂಗಣಿಸು',
'ಪೆಂಗತನ',
'ಪೆಂಗಿನಾಮ',
'ಪೆಂಗೊಲೆಯ',
'ಪೆಂಟೆಗಟ್ಟು',
'ಪೆಂಟೆಬೆಲ್ಲ',
'ಪೆಂಡವಾಸ',
'ಪೆಂಡಾವಾಸ',
'ಪೆಂಪುಂಗಾಱ',
'ಪೆಂಪುಗಾಱ',
'ಪೆಂಪುಗಿಡು',
'ಪೆಂಪುಗೆಡು',
'ಪೆಂಪುಗೊಳ್',
'ಪೆಂಪುಗೊಳು',
'ಪೆಂಪುವಡೆ',
'ಪೆಂಪುವೆಱು',
'ಪೆಂಪೇಱಿಸು',
'ಪೆಕ್ಕಳಿಗ',
'ಪೆಕ್ಕಳಿಸು',
'ಪೆಗಲೇರು',
'ಪೆಗಲೇಱು',
'ಪೆಗಲ್ವೊಱೆ',
'ಪೆಗ್ಗೂರಿಡು',
'ಪೆಚ್ಚಳಿಸು',
'ಪೆಚ್ಚುತನ',
'ಪೆಜ್ಜೆಗೆಡು',
'ಪೆಜ್ಜೆವಿಡು',
'ಪೆಟಲಂಬು',
'ಪೆಟಲುಪ್ಪು',
'ಪೆಟಲ್ಗೋವಿ',
'ಪೆಟಾರಿಗೆ',
'ಪೆಟ್ಟಣಿಗೆ',
'ಪೆಟ್ಟುಮಣೆ',
'ಪೆಟ್ಟುವೆರ್ಚು',
'ಪೆಟ್ಟೆಮಣೆ',
'ಪೆಟ್ರೋಮ್ಯಾಕ್ಸು',
'ಪೆಡಂಗಯ್',
'ಪೆಡಂಗಾಲ್',
'ಪೆಡಂಗೆಯ್',
'ಪೆಡಂಗೈಸು',
'ಪೆಡಂದಲೆ',
'ಪೆಡಂಬಾಯ್',
'ಪೆಡಂಭೂತ',
'ಪೆಡಂಮೆಟ್ಟು',
'ಪೆಡಕಟ್ಟು',
'ಪೆಡಗಟ್ಟು',
'ಪೆಡಗಡೆ',
'ಪೆಡಗಯ್',
'ಪೆಡಗಾಲ್',
'ಪೆಡಗುಡಿ',
'ಪೆಡಗೆಯ್',
'ಪೆಡಗೊಳ್',
'ಪೆಡತಲೆ',
'ಪೆಡದಲೆ',
'ಪೆಡಪೌಜು',
'ಪೆಡಮೆಟ್ಟು',
'ಪೆಡಸರ',
'ಪೆಡಸಾರ್',
'ಪೆಡೆಗಟ್ಟು',
'ಪೆಡೆಗಲ್',
'ಪೆಡೆಮಣಿ',
'ಪೆಡೆಯಕ್ಕಿ',
'ಪೆಡೆವಣಿ',
'ಪೆಣಗಾಟ',
'ಪೆಣಗಿಸು',
'ಪೆಣದಿನಿ',
'ಪೆಣಲಿಕ್ಕು',
'ಪೆಣಱಿಸು',
'ಪೆಣೆಗೊಳ್',
'ಪೆಣ್ಮಾಣಿಕ',
'ಪೆದ್ದಂಭಟ್ಟ',
'ಪೆದ್ದಕಾಪು',
'ಪೆದ್ದಭಟ್ಟ',
'ಪೆದ್ದುತನ',
'ಪೆನಸಲು',
'ಪೆಪ್ಪರ್ಮಿಂಟು',
'ಪೆಪ್ಪರ್ಮೆಂಟ್',
'ಪೆಪ್ಪರ್ಮೆಂಟು',
'ಪೆಪ್ಪಳಿಸು',
'ಪೆಬ್ಬೞಲ್',
'ಪೆಮ್ಮನಡಿ',
'ಪೆಮ್ಮರುಳ್',
'ಪೆಮ್ಮಾನಡಿ',
'ಪೆರಗಿಕ್ಕು',
'ಪೆರದೆಗೆ',
'ಪೆರಸಾರು',
'ಪೆರುಮಾಳ್',
'ಪೆರುಮಾಳು',
'ಪೆರೆಗಳೆ',
'ಪೆರೆದಲೆ',
'ಪೆರೆಯಿಕ್ಕು',
'ಪೆರೆಯುಚ್ಚು',
'ಪೆರೆಯುರ್ಚು',
'ಪೆರೆಸುಲಿ',
'ಪೆರ್ಕಳಿಕೆ',
'ಪೆರ್ಕಳಿಸು',
'ಪೆರ್ಗಡಹು',
'ಪೆರ್ಗಡಿತಿ',
'ಪೆರ್ಗವತೆ',
'ಪೆರ್ಗುಡಿಹಿ',
'ಪೆರ್ಗೂರಿಕ್ಕು',
'ಪೆರ್ಗೂರಿಡು',
'ಪೆರ್ಗೊಸರ್ಕೆ',
'ಪೆರ್ಚುಗೆಡು',
'ಪೆರ್ಚುವಡೆ',
'ಪೆರ್ಚುವೆಱು',
'ಪೆರ್ಜೊಡರ್',
'ಪೆರ್ಬಡಿಕೆ',
'ಪೆರ್ಬಡಿಸು',
'ಪೆರ್ಬಸವು',
'ಪೆರ್ಬಾರವ',
'ಪೆರ್ಬಾವುಗ',
'ಪೆರ್ಬುಡಿಕೆ',
'ಪೆರ್ಬೊಡೆಯ',
'ಪೆರ್ಮಗಳ್',
'ಪೆರ್ಮಡಿಯ',
'ಪೆರ್ಮನಡಿ',
'ಪೆರ್ಮಱವೆ',
'ಪೆರ್ಮಾನಡಿ',
'ಪೆರ್ಮುಸುಂಡೆ',
'ಪೆರ್ಮೆಗಿಡು',
'ಪೆರ್ಮೆಗೆಡು',
'ಪೆರ್ಮೆಗೊಳ್',
'ಪೆರ್ಮೆವಡೆ',
'ಪೆರ್ಮೆವೆಱು',
'ಪೆರ್ವಡಹ',
'ಪೆರ್ವಡಿಕೆ',
'ಪೆರ್ವಡುಕೆ',
'ಪೆರ್ವಾರವ',
'ಪೆರ್ವೊಡೆಯ',
'ಪೆರ್ಸಂಪಗೆ',
'ಪೆಲ್ಲಯಿಸು',
'ಪೆವ್ವಳಿಸು',
'ಪೆಸರಾನ್',
'ಪೆಸರಿಡು',
'ಪೆಸರಿಲಿ',
'ಪೆಸರಿಸು',
'ಪೆಸರ್ಗೇಳ್',
'ಪೆಸರ್ಗೊಳ್',
'ಪೆಸರ್ವಡೆ',
'ಪೆಸರ್ವಾಸಿ',
'ಪೆಸರ್ವಿಡಿ',
'ಪೆಸರ್ವೆಱು',
'ಪೆಳರ್ಚಿಸು',
'ಪೆಳರ್ವಡು',
'ಪೆಳಱಿಸು',
'ಪೆಳ್ಪಳಿಸು',
'ಪೆಳ್ವಳಿಸು',
'ಪೆಳ್ಳಗಿಸು',
'ಪೆಱಕಾಲ್',
'ಪೆಱಗಣ್',
'ಪೆಱಗಣ',
'ಪೆಱಗನೆ',
'ಪೆಱಗಾಲ್',
'ಪೆಱಗಿಕ್ಕು',
'ಪೆಱಗಿಡು',
'ಪೆಱಗೆಡೆ',
'ಪೆಱತೆಗೆ',
'ಪೆಱದೆಗೆ',
'ಪೆಱನಡು',
'ಪೆಱಪಿಂಗು',
'ಪೆಱಭಾಗ',
'ಪೆಱಮೆಟ್ಟು',
'ಪೆಱಮೆಯ್',
'ಪೆಱವಕ್ಕ',
'ಪೆಱವುೞಿ',
'ಪೆಱವೋಗು',
'ಪೆಱಸಾರ್',
'ಪೆಱಸಾರು',
'ಪೆಱಸಾರ್ಚು',
'ಪೆಱಹೋಗು',
'ಪೆಱೆಗೊಂಕು',
'ಪೆಱೆದಲೆ',
'ಪೆಱೆಮುಖಿ',
'ಪೆಱೆಯಂಬು',
'ಪೆಱೆವಣೆ',
'ಪೆೞಗಾಲ್',
'ಪೇಡಿತನ',
'ಪೇದೆಮಾತು',
'ಪೇರಗಳು',
'ಪೇರಣಿಗ',
'ಪೇರಣಿಲ್',
'ಪೇರುಕಟ್ಟು',
'ಪೇರುಸಿರು',
'ಪೇರುಸುರು',
'ಪೇಲವತೆ',
'ಪೇಶವಾಯಿ',
'ಪೇಷಕಶಿ',
'ಪೇಷುಖಾನೆ',
'ಪೇಸುಪತ',
'ಪೇಸುಪಥ',
'ಪೇೞ್ಕೆಗೇಳ್',
'ಪೈಗಂಬರ್',
'ಪೈಗಂಬರ',
'ಪೈಜೆಗಾಱ',
'ಪೈಣಗತಿ',
'ಪೈತೃಪತಿ',
'ಪೈತ್ತಲಜ',
'ಪೈತ್ಯಗಾತಿ',
'ಪೈತ್ಯಗಾರ',
'ಪೈತ್ಯಜ್ವರ',
'ಪೈತ್ಯಾಧಿಕೆ',
'ಪೈಮಾಯಿಷಿ',
'ಪೈರುದಾರ',
'ಪೈಲವಾನ',
'ಪೈಶಂಗಿತ',
'ಪೈಶಾಚಕ',
'ಪೈಶಾಚಿಕ',
'ಪೈಸರಿಸು',
'ಪೊಂಕಂಗಿಡು',
'ಪೊಂಕಂಗೆಡು',
'ಪೊಂಗಾರುಕ',
'ಪೊಂಗುನೂಲ್',
'ಪೊಂಗುನೂಲು',
'ಪೊಂಗೆಲಸ',
'ಪೊಂಗೇದಗೆ',
'ಪೊಂತಕಾರಿ',
'ಪೊಂತಕಾಱಿ',
'ಪೊಂದಾಮರೆ',
'ಪೊಂದಾವರೆ',
'ಪೊಂದುಗುಂದು',
'ಪೊಂಪುೞಿಸು',
'ಪೊಂಬರದ',
'ಪೊಂಬಸಿಱ',
'ಪೊಂಬಸುಱ',
'ಪೊಂಬಿಸಿಲ್',
'ಪೊಂಬಿಸಿಲು',
'ಪೊಂಬೆಳಗು',
'ಪೊಂಬೊಡೆಯ',
'ಪೊಕ್ಕರಣಿ',
'ಪೊಕ್ಕರಣೆ',
'ಪೊಗದಸ್ತು',
'ಪೊಗರಳಿ',
'ಪೊಗರಾನ್',
'ಪೊಗರಿಡಿ',
'ಪೊಗರುಗು',
'ಪೊಗರೇರು',
'ಪೊಗರೇಱು',
'ಪೊಗರೇೞ್',
'ಪೊಗರೊಗು',
'ಪೊಗರೊಗೆ',
'ಪೊಗರ್ವಡು',
'ಪೊಗರ್ವಡೆ',
'ಪೊಗಳಿಕೆ',
'ಪೊಗಳಿಸು',
'ಪೊಗೞಿಸು',
'ಪೊಗುತರ್',
'ಪೊಗೆಗೊಳ್',
'ಪೊಗೆಧ್ವಜ',
'ಪೊಗೆಮೊಗ',
'ಪೊಗೆಯಿಡು',
'ಪೊಗೆವಂಡಿ',
'ಪೊಗೆವಣ',
'ಪೊಗೆವರಿ',
'ಪೊಗೆಸುತ್ತು',
'ಪೊಚ್ಚಂಬೋಗು',
'ಪೊಚ್ಚಱಿಸು',
'ಪೊಟ್ಟಣಿಸು',
'ಪೊಟ್ಟಳಿಗೆ',
'ಪೊಟ್ಟಳಿಸು',
'ಪೊಟ್ಟುಕತೆ',
'ಪೊಟ್ಟುಕಳೆ',
'ಪೊಟ್ಟುಗಳೆ',
'ಪೊಟ್ಟುಗೆಯ್',
'ಪೊಟ್ಟುಬಾವಿ',
'ಪೊಟ್ಟುವಡೆ',
'ಪೊಟ್ಟೆಕಿರ್ಚು',
'ಪೊಟ್ಟೆಬೇನೆ',
'ಪೊಟ್ಟೆವಂತ',
'ಪೊಟ್ಟೆವಾಯ್',
'ಪೊಟ್ಟೆವೋಗು',
'ಪೊಡಣಿಗೆ',
'ಪೊಡಪತ್ರ',
'ಪೊಡಪತ್ರೆ',
'ಪೊಡಮಡು',
'ಪೊಡವಡು',
'ಪೊಡವಿಚ',
'ಪೊಡವಿಪ',
'ಪೊಡವಿಸು',
'ಪೊಡವೀಶ',
'ಪೊಡಸೆಂಡು',
'ಪೊಡೆಗಿಚ್ಚು',
'ಪೊಡೆಗಿರ್ಚು',
'ಪೊಡೆಗೆಡೆ',
'ಪೊಡೆಚಂಡು',
'ಪೊಡೆಚೆಂಡು',
'ಪೊಡೆದುಂಬು',
'ಪೊಡೆಮಡು',
'ಪೊಡೆಯಿಸು',
'ಪೊಡೆವಡು',
'ಪೊಡೆವೆಱು',
'ಪೊಡೆಶಂಡು',
'ಪೊಡೆಸೆಂಡು',
'ಪೊಡ್ಡಣಿಗೆ',
'ಪೊಡ್ಡವಣೆ',
'ಪೊಣರೞಿ',
'ಪೊಣರಿಚು',
'ಪೊಣರಿಸು',
'ಪೊಣರ್ಚಿಸು',
'ಪೊಣರ್ವಕ್ಕಿ',
'ಪೊಣೆಕಾಱ',
'ಪೊಣೆಗಾರ',
'ಪೊತ್ತುಗಳೆ',
'ಪೊತ್ತುಪೋಕ',
'ಪೊದಕುಳಿ',
'ಪೊದಯಿಸು',
'ಪೊದರೊಡೆ',
'ಪೊದರ್ದಲೆ',
'ಪೊದರ್ವಡೆ',
'ಪೊದಱೇಳ್',
'ಪೊದಱೊಡೆ',
'ಪೊದೞ್ತರ್',
'ಪೊದೆಗಟ್ಟು',
'ಪೊದೆಯಿಸು',
'ಪೊದೆವಡು',
'ಪೊದೆಸೀರೆ',
'ಪೊನಲಿಗೆ',
'ಪೊನಲಿಡು',
'ಪೊನಲೇಳ್',
'ಪೊನಲ್ಗೊಳ್',
'ಪೊನಲ್ವರಿ',
'ಪೊನ್ನಂಬರ',
'ಪೊನ್ನಗಂಟಿ',
'ಪೊನ್ನಗನ್ನೆ',
'ಪೊನ್ನೆಱೆಯ',
'ಪೊನ್ನೊಡೆಯ',
'ಪೊಯಶಳ',
'ಪೊಯಸಳ',
'ಪೊಯಿನೀರು',
'ಪೊಯಿಲ್ವೆಱು',
'ಪೊರಗಣ',
'ಪೊರಮಡು',
'ಪೊರಯಿಂಕೆ',
'ಪೊರವಾರ್',
'ಪೊರವಾರ',
'ಪೊರವಾರು',
'ಪೊರಳಿಸು',
'ಪೊರಳ್ನುಡಿ',
'ಪೊರೆಗಳೆ',
'ಪೊರೆದೋಱು',
'ಪೊರೆಬಿಡು',
'ಪೊರೆಯಿಕ್ಕು',
'ಪೊರೆಯಿಡು',
'ಪೊರೆಯಿರ್',
'ಪೊರೆಯಿಸು',
'ಪೊರೆಯುರ್ಚು',
'ಪೊರೆಯೆತ್ತು',
'ಪೊರೆಯೇರು',
'ಪೊರೆಯೇಱು',
'ಪೊರೆಯೊತ್ತು',
'ಪೊರೆವಿಡಿ',
'ಪೊರೆವಿಡು',
'ಪೊರೆವೋಗು',
'ಪೊರೆಸಾರ್',
'ಪೊರೆಹುಲ್ಲು',
'ಪೊರ್ತುಗೀಜ',
'ಪೋರ್ಚುಗೀಜ',
'ಪೊಲಂಗೊಳ್',
'ಪೊಲಂಬಿಗ',
'ಪೊಲಗಾಪು',
'ಪೊಲಗಾಹಿ',
'ಪೊಲಗಿಡು',
'ಪೊಲಗೆಡು',
'ಪೊಲಗೇರಿ',
'ಪೊಲತಪ್ಪು',
'ಪೊಲಬಿಗ',
'ಪೊಲಮಚ್ಚು',
'ಪೊಲಮೆಚ್ಚು',
'ಪೊಲವಿಡಿ',
'ಪೊಲಸೇಳು',
'ಪೊಲೆಗೆಡು',
'ಪೊಲೆಗೆಯ್',
'ಪೊಲೆಗೇರಿ',
'ಪೊಲೆನುಡಿ',
'ಪೊಲೆಬೂತು',
'ಪೊಲೆಮನೆ',
'ಪೊಲೆವಗೆ',
'ಪೊಲೆವಟ್ಟು',
'ಪೊಲೆವಡು',
'ಪೊಲ್ಲಕೆಯ್',
'ಪೊಲ್ಲಗೆಯ್',
'ಪೊಲ್ಲದುಗೈ',
'ಪೊಲ್ಲಲಿಂಗಿ',
'ಪೊಸಂತಿಲ್',
'ಪೊಸಂತಿಲು',
'ಪೊಸಗಾರ್',
'ಪೊಸಗಾರು',
'ಪೊಸತಿಕ್ಕು',
'ಪೊಸತಿಲ್',
'ಪೊಸತಿಲು',
'ಪೊಸನೀರ್',
'ಪೊಸನೀರು',
'ಪೊಸಯಿಸು',
'ಪೊಸರಕ್ತ',
'ಪೊಸವಾಸಿ',
'ಪೊಸವೆಣ್',
'ಪೊಸೆಯಿಸು',
'ಪೊಳಕಿಸು',
'ಪೊಳಪೇಱು',
'ಪೊಳಯಿಸು',
'ಪೊಳಲಿಚ',
'ಪೊಳೆಪಿಡು',
'ಪೊಳೆಯಿಸು',
'ಪೊಳ್ಳಯಿಸು',
'ಪೊಳ್ಳುಗಳೆ',
'ಪೊಳ್ಳುಗೆಡೆ',
'ಪೊಱಂಗುಟ್ಟು',
'ಪೊಱಕಾಲ್',
'ಪೊಱಕುಟ್ಟು',
'ಪೊಱಕುಳಿ',
'ಪೊಱಕೆಯ್',
'ಪೊಱಗಡೆ',
'ಪೊಱಗಣ್',
'ಪೊಱಗಣ',
'ಪೊಱಗಯ್',
'ಪೊಱಗುಂಡ',
'ಪೊಱತೆಗೆ',
'ಪೊಱಪಾಯ್',
'ಪೊಱಪೊಣ್ಮು',
'ಪೊಱಬಿಗ',
'ಪೊಱಮಡು',
'ಪೊಱಮಾಱು',
'ಪೊಱಮಿಂಚು',
'ಪೊಱಮುಯ್ವು',
'ಪೊಱಮೆಯ್',
'ಪೊಱಯಿಂಕೆ',
'ಪೊಱವಂಡು',
'ಪೊಱವಡು',
'ಪೊಱವರ್',
'ಪೊಱವಾಯ್',
'ಪೊಱವಾಱ್',
'ಪೊಱವಾಱು',
'ಪೊಱವೇಱು',
'ಪೊಱಸಾರು',
'ಪೊಱಸುತ್ತು',
'ಪೊಱಸೂಸು',
'ಪೊಱೆಕಾಱ',
'ಪೊಱೆಗಟ್ಟು',
'ಪೊಱೆಯಾಳ್',
'ಪೊಱೆಯಾಳು',
'ಪೊೞಲಿಗ',
'ಪೊೞಲಿಚ',
'ಪೊೞೆಗಟ್ಟು',
'ಪೊೞೆದುಂಗೆ',
'ಪೊೞೆಮೆಟ್ಟು',
'ಪೊೞೆವಕ್ಕಿ',
'ಪೊೞ್ತುಗಳೆ',
'ಪೊೞ್ತುಪೋಗು',
'ಪೋಂಛಾವಣೆ',
'ಪೋಕತನ',
'ಪೋಕವಿದ್ಯೆ',
'ಪೋಕವೃತ್ತಿ',
'ಪೋಕಾಟಿಕೆ',
'ಪೋಕ್ತತನ',
'ಪೋಗಂಡಿಕೆ',
'ಪೋಗೊಳಿಸು',
'ಪೋಚಬುಕ್ಕು',
'ಪೋಟಗಲ',
'ಪೋಟಗಳ',
'ಪೋಟತನ',
'ಪೋಟಲೀಸು',
'ಪೋಟಾಧ್ಯಾಯ',
'ಪೋಟಾಪೋಟಿ',
'ಪೋಟಿಗಾರ',
'ಪೋಟಿಬೀಳು',
'ಪೋಟುಬೀಳು',
'ಪೋಟೆಬಾಗೆ',
'ಪೋಟೆಬೀಳು',
'ಪೋಟೆಹೋಗು',
'ಪೋಡುಂಗಾಱ',
'ಪೋಡುಗಾಱ',
'ಪೋಣಪಟ್ಟು',
'ಪೋತವಾಹ',
'ಪೋತವಾಹಿ',
'ಪೋಥೀಭಕ್ತಿ',
'ಪೋದೆವೋಗು',
'ಪೋರತನ',
'ಪೋರುಗೊಳ್',
'ಪೋರ್ಚುಗೀಸ್',
'ಪೋರ್ಚುಗೀಸ',
'ಪೋರ್ತಂಗೊಳ್',
'ಪೋಲಿಕಾಱ',
'ಪೋಲಿಗಾರ',
'ಪೋಲಿಗಾಱ',
'ಪೋಲಿತನ',
'ಪೋಲ್ಕೆಗೊಳ್',
'ಪೋಲ್ವೆವೆಱು',
'ಪೋವಣಿಕೆ',
'ಪೋಷ್ಯವರ್ಗ',
'ಪೋಹಣಿಗೆ',
'ಪೋಹಣಿಸು',
'ಪೋೞ್ಗೆದಱ್',
'ಪೌಂಚಾಯಿಸು',
'ಪೌಂಡರೀಕ',
'ಪೌಂಡಿಮನೆ',
'ಪೌಜುದಾರ',
'ಪೌಜುದಾರಿ',
'ಪೌಣಪಟ',
'ಪೌತಿತಃಖ್ತೆ',
'ಪೌದ್ಗಲಿಕ',
'ಪೌನಃಪುನ್ಯ',
'ಪೌನರುಕ್ತ್ಯ',
'ಪೌನರ್ಭವ',
'ಪೌರಂದರ',
'ಪೌರಕಾರ್ಯ',
'ಪೌರಜನ',
'ಪೌರಧರ್ಮ',
'ಪೌರನೀತಿ',
'ಪೌರಲೋಕ',
'ಪೌರಸಂಸ್ಥೆ',
'ಪೌರಸಭೆ',
'ಪೌರಾಣಿಕ',
'ಪೌರಾತನ',
'ಪೌರುಷಾಂಗ',
'ಪೌರುಷೇಯ',
'ಪೌರೋಗವ',
'ಪೌರೋಭಾಗ್ಯ',
'ಪೌರೋಹಿತ',
'ಪೌರೋಹಿತ್ಯ',
'ಪೌರ್ಣಮಾಸ',
'ಪೌರ್ಣಮಾಸಿ',
'ಪೌರ್ಣಮಾಸೆ',
'ಪೌರ್ವಾಪಾರ್ಯ',
'ಪೌರ್ವಾಹ್ನಿಕ',
'ಪೌಷ್ಟಿಕತೆ',
'ಪೌಳಿಂದಿನಿ',
'ಪೌಳಿಗೋಡೆ',
'ಪ್ಯಾದೆಮಾತು',
'ಪ್ರಕಂಪನ',
'ಪ್ರಕಂಪಿತ',
'ಪ್ರಕಟಗೈ',
'ಪ್ರಕಟಣೆ',
'ಪ್ರಕಟನ',
'ಪ್ರಕಟನೆ',
'ಪ್ರಕಟಿತ',
'ಪ್ರಕಟಿಸು',
'ಪ್ರಕರಣ',
'ಪ್ರಕರಣಿ',
'ಪ್ರಕಲಿತ',
'ಪ್ರಕಳಿತ',
'ಪ್ರಕಾಂಡಕ',
'ಪ್ರಕಾಶಕ',
'ಪ್ರಕಾಶಕಿ',
'ಪ್ರಕಾಶನ',
'ಪ್ರಕಾಶಿತ',
'ಪ್ರಕಾಶಿಸು',
'ಪ್ರಕೀರ್ಣಕ',
'ಪ್ರಕೀರ್ತನ',
'ಪ್ರಕೀರ್ತಿತ',
'ಪ್ರಕುಪಿತ',
'ಪ್ರಕೃತಿಸ್ಥ',
'ಪ್ರಕೋಪಿಸು',
'ಪ್ರಕ್ಷಾಲನ',
'ಪ್ರಕ್ಷಾಲಿತ',
'ಪ್ರಕ್ಷಾಲಿಸು',
'ಪ್ರಕ್ಷಾಳನ',
'ಪ್ರಕ್ಷಾಳನೆ',
'ಪ್ರಕ್ಷಾಳಿತ',
'ಪ್ರಕ್ಷಾಳಿಸು',
'ಪ್ರಕ್ಷುಭಿತ',
'ಪ್ರಕ್ಷೇಪಕ',
'ಪ್ರಕ್ಷೇಪಣ',
'ಪ್ರಕ್ಷೇಪಿತ',
'ಪ್ರಕ್ಷೇಪಿಸು',
'ಪ್ರಕ್ಷೋಭಿಸು',
'ಪ್ರಕ್ಷ್ವೇಡನ',
'ಪ್ರಖರತೆ',
'ಪ್ರಖ್ಯಾಪನ',
'ಪ್ರಗಣಿತ',
'ಪ್ರಗಲ್ಭತೆ',
'ಪ್ರಘಟ್ಟಕ',
'ಪ್ರಘಟ್ಟನ',
'ಪ್ರಘೋಷಣ',
'ಪ್ರಚರಿಸು',
'ಪ್ರಚಲನ',
'ಪ್ರಚಲಾಕ',
'ಪ್ರಚಲಾಕಿ',
'ಪ್ರಚಲಿತ',
'ಪ್ರಚಳಾಕ',
'ಪ್ರಚಳಿತ',
'ಪ್ರಚಾರಕ',
'ಪ್ರಚಾರಕಿ',
'ಪ್ರಚಾರತೆ',
'ಪ್ರಚುರತೆ',
'ಪ್ರಚೇತಸ',
'ಪ್ರಚೋದಕ',
'ಪ್ರಚೋದನ',
'ಪ್ರಚೋದನಿ',
'ಪ್ರಚೋದನೆ',
'ಪ್ರಚೋದಿತ',
'ಪ್ರಚೋದಿಸು',
'ಪ್ರಚ್ಛನ್ನತೆ',
'ಪ್ರಚ್ಛರ್ದಿಕೆ',
'ಪ್ರಚ್ಛಾದನ',
'ಪ್ರಚ್ಛಾದಿತ',
'ಪ್ರಚ್ಛಾದಿಸು',
'ಪ್ರಜನನ',
'ಪ್ರಜಾತಂತ್ರ',
'ಪ್ರಜಾಧರ್ಮ',
'ಪ್ರಜಾಧಿಪ',
'ಪ್ರಜಾನೀತಿ',
'ಪ್ರಜಾಪತಿ',
'ಪ್ರಜಾವತಿ',
'ಪ್ರಜಾಸತ್ತೆ',
'ಪ್ರಜಾಹಿತ',
'ಪ್ರಜೇಶ್ವರ',
'ಪ್ರಜೋತ್ಪತ್ತಿ',
'ಪ್ರಜ್ಞಾತೀತ',
'ಪ್ರಜ್ಞಾವಂತ',
'ಪ್ರಜ್ಞಾವಂತೆ',
'ಪ್ರಜ್ಞಾವಾದ',
'ಪ್ರಜ್ಞಾವಾದಿ',
'ಪ್ರಜ್ಞಾಶೂನ್ಯ',
'ಪ್ರಜ್ಞಾಶೂನ್ಯೆ',
'ಪ್ರಜ್ಞಾಹೀನ',
'ಪ್ರಜ್ಞಾಹೀನೆ',
'ಪ್ರಜ್ವಲನ',
'ಪ್ರಜ್ವಲಿತ',
'ಪ್ರಜ್ವಲಿಸು',
'ಪ್ರಜ್ವಳಿತ',
'ಪ್ರಜ್ವಳಿಸು',
'ಪ್ರಣಮನ',
'ಪ್ರಣಯಿನಿ',
'ಪ್ರಣವೋತ್ಥ',
'ಪ್ರಣಾಲಿಕೆ',
'ಪ್ರಣಾಳಿಕೆ',
'ಪ್ರಣಿಧಾನ',
'ಪ್ರಣಿಪತ್ತಿ',
'ಪ್ರಣಿಪಾತ',
'ಪ್ರಣಿಹಿತ',
'ಪ್ರಣುತಿಸು',
'ಪ್ರಣೇತಾರ',
'ಪ್ರತಪನ',
'ಪ್ರತಾಪಸ',
'ಪ್ರತಾಪೋಷ್ಮ',
'ಪ್ರತಾರಕ',
'ಪ್ರತಾರಣ',
'ಪ್ರತಾರಿಸು',
'ಪ್ರತಿಕಕ್ಷಿ',
'ಪ್ರತಿಕರ್ಮ',
'ಪ್ರತಿಕಾಯ',
'ಪ್ರತಿಕಾರ',
'ಪ್ರತಿಕೂಲ',
'ಪ್ರತಿಕೃತ',
'ಪ್ರತಿಕೃತಿ',
'ಪ್ರತಿಕೃಷ್ಟ',
'ಪ್ರತಿಕ್ರಿಯೆ',
'ಪ್ರತಿಕ್ಷಣಂ',
'ಪ್ರತಿಕ್ಷಿಪ್ತ',
'ಪ್ರತಿಗಾಮಿ',
'ಪ್ರತಿಗ್ರಹ',
'ಪ್ರತಿಗ್ರಾಹ',
'ಪ್ರತಿಘಾತ',
'ಪ್ರತಿಘೋಷ',
'ಪ್ರತಿಚಿತ್ರ',
'ಪ್ರತಿಚ್ಛಂದ',
'ಪ್ರತಿಚ್ಛನ್ನ',
'ಪ್ರತಿಚ್ಛಾಯ',
'ಪ್ರತಿಚ್ಛಾಯೆ',
'ಪ್ರತಿಜ್ಞಾತ',
'ಪ್ರತಿಜ್ಞಾನ',
'ಪ್ರತಿಜ್ಞೆಗೈ',
'ಪ್ರತಿತುನಿ',
'ಪ್ರತಿದಿನ',
'ಪ್ರತಿದಿನಂ',
'ಪ್ರತಿದೇಯ',
'ಪ್ರತಿದೋರು',
'ಪ್ರತಿದೋಱು',
'ಪ್ರತಿದ್ವಂದ್ವಿ',
'ಪ್ರತಿಧ್ವನಿ',
'ಪ್ರತಿಧ್ವಾನ',
'ಪ್ರತಿನಾದ',
'ಪ್ರತಿನಾಮ',
'ಪ್ರತಿನಿತ್ಯ',
'ಪ್ರತಿನಿಧಿ',
'ಪ್ರತಿಪಕ್ಷ',
'ಪ್ರತಿಪಕ್ಷಿ',
'ಪ್ರತಿಪತ್ತಿ',
'ಪ್ರತಿಪತ್ತು',
'ಪ್ರತಿಪತ್ನಿ',
'ಪ್ರತಿಪತ್ರ',
'ಪ್ರತಿಪದ',
'ಪ್ರತಿಪದಿ',
'ಪ್ರತಿಪದೆ',
'ಪ್ರತಿಪನ್ನ',
'ಪ್ರತಿಪಾಕ',
'ಪ್ರತಿಪಾತಿ',
'ಪ್ರತಿಪಾದ್ಯ',
'ಪ್ರತಿಪಾನ',
'ಪ್ರತಿಪಾಪ',
'ಪ್ರತಿಪಾಲ',
'ಪ್ರತಿಪೂಜೆ',
'ಪ್ರತಿಪ್ರಾಣ',
'ಪ್ರತಿಫಲ',
'ಪ್ರತಿಬಂದಿ',
'ಪ್ರತಿಬಂಧ',
'ಪ್ರತಿಬದ್ಧ',
'ಪ್ರತಿಬಲ',
'ಪ್ರತಿಬಿಂಬ',
'ಪ್ರತಿಬುದ್ಧ',
'ಪ್ರತಿಭಟ',
'ಪ್ರತಿಭಾನ',
'ಪ್ರತಿಭೂತ್ವ',
'ಪ್ರತಿಭೋಗ',
'ಪ್ರತಿಮಾತು',
'ಪ್ರತಿಮಾನ',
'ಪ್ರತಿಮಿತ',
'ಪ್ರತಿಮಿಸು',
'ಪ್ರತಿಮುಕ್ತ',
'ಪ್ರತಿಮುಖ',
'ಪ್ರತಿಯತ್ನ',
'ಪ್ರತಿಯಿಕ್ಕು',
'ಪ್ರತಿಯಿಡು',
'ಪ್ರತಿಯಿನ',
'ಪ್ರತಿಯೋಗ',
'ಪ್ರತಿಯೋಗಿ',
'ಪ್ರತಿರಕ್ಷೆ',
'ಪ್ರತಿರವ',
'ಪ್ರತಿರೂಪ',
'ಪ್ರತಿರೂಪು',
'ಪ್ರತಿರೋಧ',
'ಪ್ರತಿರೋಧಿ',
'ಪ್ರತಿಲಿಪಿ',
'ಪ್ರತಿಲೋಮ',
'ಪ್ರತಿವಸ್ತು',
'ಪ್ರತಿವಾದ',
'ಪ್ರತಿವಾದಿ',
'ಪ್ರತಿವಿದ್ಯೆ',
'ಪ್ರತಿವಿಷ',
'ಪ್ರತಿವಿಷೆ',
'ಪ್ರತಿವೆಱು',
'ಪ್ರತಿವ್ಯೂಹ',
'ಪ್ರತಿಶ್ಯಾಯ',
'ಪ್ರತಿಶ್ರಯ',
'ಪ್ರತಿಶ್ರವ',
'ಪ್ರತಿಷಿದ್ಧ',
'ಪ್ರತಿಷೇಧ',
'ಪ್ರತಿಷ್ಟಂಭ',
'ಪ್ರತಿಷ್ಠಾನ',
'ಪ್ರತಿಷ್ಠಿತ',
'ಪ್ರತಿಷ್ಠಿಸು',
'ಪ್ರತಿಸಂಧಿ',
'ಪ್ರತಿಸರ',
'ಪ್ರತಿಸರ್ಗ',
'ಪ್ರತಿಸೀರೆ',
'ಪ್ರತಿಸೂರ್ಯ',
'ಪ್ರತಿಸೃಷ್ಟಿ',
'ಪ್ರತಿಸ್ಪರ್ಧಿ',
'ಪ್ರತಿಹತ',
'ಪ್ರತಿಹರ್ತೃ',
'ಪ್ರತಿಹಸ್ತ',
'ಪ್ರತಿಹಾರ',
'ಪ್ರತಿಹಾರಿ',
'ಪ್ರತಿಹಾಸ',
'ಪ್ರತಿಹಾಸೆ',
'ಪ್ರತಿಹಿಂಸೆ',
'ಪ್ರತೀಕಾರ',
'ಪ್ರತೀಕಾಶ',
'ಪ್ರತೀಕ್ಷಣ',
'ಪ್ರತೀಕ್ಷ್ಷಿಸು',
'ಪ್ರತೀಚೀನ',
'ಪ್ರತೀವಾಪ',
'ಪ್ರತೀಹಾರ',
'ಪ್ರತೀಹಾರಿ',
'ಪ್ರತೀಹಾಸ',
'ಪ್ರತೋಳಿಕೆ',
'ಪ್ರತ್ಯಂಬರ',
'ಪ್ರತ್ಯಕ್ಛ್ರೇಣಿ',
'ಪ್ರತ್ಯಕ್ಪರ್ಣಿ',
'ಪ್ರತ್ಯಗಾತ್ಮ',
'ಪ್ರತ್ಯಗ್ಬೋಧ',
'ಪ್ರತ್ಯಗ್ಭೂಧ',
'ಪ್ರತ್ಯನೀಕ',
'ಪ್ರತ್ಯಭಿಜ್ಞೆ',
'ಪ್ರತ್ಯಯಾಂತ',
'ಪ್ರತ್ಯಯಿತ',
'ಪ್ರತ್ಯವಾಯ',
'ಪ್ರತ್ಯಾಕರ',
'ಪ್ರತ್ಯಾಕಾರ',
'ಪ್ರತ್ಯಖ್ಯಾತ',
'ಪ್ರತ್ಯಖ್ಯಾನ',
'ಪ್ರತ್ಯಾಗತ',
'ಪ್ರತ್ಯಾದಿಷ್ಟ',
'ಪ್ರತ್ಯಾದಿಷ್ಟೆ',
'ಪ್ರತ್ಯಾದೇಶ',
'ಪ್ರತ್ಯಾಪತ್ತಿ',
'ಪ್ರತ್ಯಾಮ್ನಾಯ',
'ಪ್ರತ್ಯಾಲೀಢ',
'ಪ್ರತ್ಯಾಸತ್ತಿ',
'ಪ್ರತ್ಯಾಸನ್ನ',
'ಪ್ರತ್ಯಾಸಾರ',
'ಪ್ರತ್ಯಾಹತ',
'ಪ್ರತ್ಯಾಹಾರ',
'ಪ್ರತ್ಯುತ್ಕ್ರಮ',
'ಪ್ರತ್ಯುತ್ತರ',
'ಪ್ರತ್ಯುತ್ಥಾನ',
'ಪ್ರತ್ಯುತ್ಪನ್ನ',
'ಪ್ರತ್ಯುದ್ಭವ',
'ಪ್ರತ್ಯುಪಾಯ',
'ಪ್ರತ್ಯೂರ್ಜಿತ',
'ಪ್ರತ್ಯೇಕತೆ',
'ಪ್ರತ್ಯೇಕಿಸು',
'ಪ್ರಥಮತಃ',
'ಪ್ರಥಮತ್ವ',
'ಪ್ರಥಮಾಂತ',
'ಪ್ರಥಮಾದ್ರಿ',
'ಪ್ರದಕ್ಷಿಣ',
'ಪ್ರದಕ್ಷಿಣೆ',
'ಪ್ರದರ್ಶಕ',
'ಪ್ರದರ್ಶಕಿ',
'ಪ್ರದರ್ಶನ',
'ಪ್ರದರ್ಶಿತ',
'ಪ್ರದರ್ಶಿಸು',
'ಪ್ರದಾಯಕ',
'ಪ್ರದಾಯಕಿ',
'ಪ್ರದಾರಿತ',
'ಪ್ರದೀಪಕ',
'ಪ್ರದೀಪನ',
'ಪ್ರದೇಶನ',
'ಪ್ರದೇಶನಿ',
'ಪ್ರದೇಶಿನಿ',
'ಪ್ರದ್ಯೋತನ',
'ಪ್ರಧಾನತೆ',
'ಪ್ರಧಾನಿಕೆ',
'ಪ್ರಧಾನಿತಿ',
'ಪ್ರಧ್ವಂಸನ',
'ಪ್ರಪಂಚತೆ',
'ಪ್ರಪಂಚನ',
'ಪ್ರಪಂಚಿಸು',
'ಪ್ರಪನ್ನತ್ವ',
'ಪ್ರಪಾಂಗನೆ',
'ಪ್ರಪಾಠಕ',
'ಪ್ರಪಾವಧು',
'ಪ್ರಪಾಶಾಲೆ',
'ಪ್ರಪುನಾಟ',
'ಪ್ರಪುನ್ನಾಟ',
'ಪ್ರಪುನ್ನಾಡ',
'ಪ್ರಪೂರಿತ',
'ಪ್ರಪ್ರಥಮ',
'ಪ್ರಫುಲ್ಲತೆ',
'ಪ್ರಫುಲ್ಲಿಸು',
'ಪ್ರಬಲಿತ',
'ಪ್ರಬಲಿಸು',
'ಪ್ರಬೋಧಕ',
'ಪ್ರಬೋಧನ',
'ಪ್ರಬೋಧಿತ',
'ಪ್ರಭಂಜನ',
'ಪ್ರಭವಿಷ್ಣು',
'ಪ್ರಭವಿಸು',
'ಪ್ರಭಾಕರ',
'ಪ್ರಭಾಚಕ್ರ',
'ಪ್ರಭಾವತೆ',
'ಪ್ರಭಾವನೆ',
'ಪ್ರಭಾವಳಿ',
'ಪ್ರಭಾವಳೆ',
'ಪ್ರಭಾವಿತ',
'ಪ್ರಭಾವಿತೆ',
'ಪ್ರಭಾವಿಸು',
'ಪ್ರಭುತ್ತಿಕೆ',
'ಪ್ರಭುಶಕ್ತಿ',
'ಪ್ರಭೆವೆಱು',
'ಪ್ರಭೇದಕ',
'ಪ್ರಭ್ರಷ್ಟಕ',
'ಪ್ರಮತ್ತತೆ',
'ಪ್ರಮಥತ್ವ',
'ಪ್ರಮಥನ',
'ಪ್ರಮದಾಳಿ',
'ಪ್ರಮಾಣತೆ',
'ಪ್ರಮಾಣನ',
'ಪ್ರಮಾಣಿಕ',
'ಪ್ರಮಾಣಿಸು',
'ಪ್ರಮಾತೃತ್ವ',
'ಪ್ರಮಾಪಣ',
'ಪ್ರಮಾರ್ಜನ',
'ಪ್ರಮಾರ್ಜಿತ',
'ಪ್ರಮುದಿತ',
'ಪ್ರಮುದಿತೆ',
'ಪ್ರಮೋದಿತ',
'ಪ್ರಮೋದೂತ',
'ಪ್ರಯತ್ನಿಸು',
'ಪ್ರಯಾಣಿಕ',
'ಪ್ರಯಾಣಿಸು',
'ಪ್ರಯೋಗಿಸು',
'ಪ್ರಯೋಜಕ',
'ಪ್ರಯೋಜನ',
'ಪ್ರರೂಪಣ',
'ಪ್ರರೂಪಿತ',
'ಪ್ರರೂಪಿಸು',
'ಪ್ರರೋಚನ',
'ಪ್ರರೋಚನೆ',
'ಪ್ರರೋಹಣ',
'ಪ್ರಲಂಪಟ',
'ಪ್ರಲಗ್ನಕ',
'ಪ್ರಲಪನ',
'ಪ್ರಲಪಿತ',
'ಪ್ರಲಪಿಸು',
'ಪ್ರಲಯಾಗ್ನಿ',
'ಪ್ರಲಾಪಿಸು',
'ಪ್ರಲೋಕಿತ',
'ಪ್ರಲೋಭನ',
'ಪ್ರಲೋಭನೆ',
'ಪ್ರವಚನ',
'ಪ್ರವಣತೆ',
'ಪ್ರವಯಣ',
'ಪ್ರವಯಸ್ಕ',
'ಪ್ರವರ್ತಕ',
'ಪ್ರವರ್ತಕಿ',
'ಪ್ರವರ್ತನೆ',
'ಪ್ರವರ್ತಿತ',
'ಪ್ರವರ್ತಿಸು',
'ಪ್ರವರ್ಧತೆ',
'ಪ್ರವರ್ಧನ',
'ಪ್ರವರ್ಧಿತ',
'ಪ್ರವರ್ಧಿಸು',
'ಪ್ರವಲ್ಹಿಕೆ',
'ಪ್ರವಹಣ',
'ಪ್ರವಹಿಸು',
'ಪ್ರವಾದಿಸು',
'ಪ್ರವಾರಣ',
'ಪ್ರವಾಸನ',
'ಪ್ರವಾಸಿಗ',
'ಪ್ರವಾಹಿಕೆ',
'ಪ್ರವಾಹಿನಿ',
'ಪ್ರವಾಹಿಸು',
'ಪ್ರವಿಖ್ಯಾತಿ',
'ಪ್ರವಿಚಾರ',
'ಪ್ರವಿತತ',
'ಪ್ರವಿದಿತ',
'ಪ್ರವಿಪುಳ',
'ಪ್ರವಿಭಕ್ತ',
'ಪ್ರವಿಭಗ್ನ',
'ಪ್ರವಿಭಾಗ',
'ಪ್ರವಿಭಾಸಿ',
'ಪ್ರವಿಮಲ',
'ಪ್ರವಿಮಳ',
'ಪ್ರವಿರಳ',
'ಪ್ರವಿಲಿಪ್ತ',
'ಪ್ರವಿಲೀನ',
'ಪ್ರವಿಲೋಮ',
'ಪ್ರವಿವೇಕ',
'ಪ್ರವೀಚಾರ',
'ಪ್ರವೀಣತೆ',
'ಪ್ರವುಡಿಕೆ',
'ಪ್ರವುಡಿಮೆ',
'ಪ್ರವೇಶಂಗೈ',
'ಪ್ರವೇಶಕ',
'ಪ್ರವೇಶಿಸು',
'ಪ್ರವೇಷ್ಟನ',
'ಪ್ರವೇಷ್ಟಿಸು',
'ಪ್ರವ್ರಜನ',
'ಪ್ರವ್ರಜಿತ',
'ಪ್ರವ್ರಜಿಸು',
'ಪ್ರಶಂಸಕ',
'ಪ್ರಶಂಸಿಸು',
'ಪ್ರಶಮನ',
'ಪ್ರಶಮಿತ',
'ಪ್ರಶಮಿತೆ',
'ಪ್ರಶಮಿಸು',
'ಪ್ರಶಾಸನ',
'ಪ್ರಶಿಕ್ಷಣ',
'ಪ್ರಶ್ನಕಾರ',
'ಪ್ರಶ್ನಚಿಹ್ನೆ',
'ಪ್ರಶ್ನಲಗ್ನ',
'ಪ್ರಶ್ನಾರ್ಥಕ',
'ಪ್ರಶ್ನಾವಳಿ',
'ಪ್ರಶ್ನೆಕೇಳು',
'ಪ್ರಶ್ನೆಗೇಳು',
'ಪ್ರಸಂಗತ',
'ಪ್ರಸಂಗತಿ',
'ಪ್ರಸಂಗಿಸು',
'ಪ್ರಸನ್ನತೆ',
'ಪ್ರಸರಣ',
'ಪ್ರಸರಣೆ',
'ಪ್ರಸರಿಸು',
'ಪ್ರಸರ್ಪಿತ',
'ಪ್ರಸವಾಂಗ',
'ಪ್ರಸವಿತೃ',
'ಪ್ರಸವಿಸು',
'ಪ್ರಸಾದನ',
'ಪ್ರಸಾದಿತ',
'ಪ್ರಸಾದಿಸು',
'ಪ್ರಸಾದೋಕ್ತಿ',
'ಪ್ರಸಾಧಕ',
'ಪ್ರಸಾಧನ',
'ಪ್ರಸಾಧನಿ',
'ಪ್ರಸಾಧಿತ',
'ಪ್ರಸಾರಣ',
'ಪ್ರಸಾರಣಿ',
'ಪ್ರಸಾರಾಂಗ',
'ಪ್ರಸಾರಿಣಿ',
'ಪ್ರಸಾರಿತ',
'ಪ್ರಸಾರಿಸು',
'ಪ್ರಸಿದ್ಧತೆ',
'ಪ್ರಸಿದ್ಧಾಂತ',
'ಪ್ರಸಿದ್ಧಾನ',
'ಪ್ರಸಿದ್ಧಿಸು',
'ಪ್ರಸೂಚಕ',
'ಪ್ರಸೂತಿಕೆ',
'ಪ್ರಸೂನಕ',
'ಪ್ರಸೇವಕ',
'ಪ್ರಸ್ಖಲಿತ',
'ಪ್ರಸ್ತರಣ',
'ಪ್ರಸ್ತರಿಸು',
'ಪ್ರಸ್ತಾಪಿಸು',
'ಪ್ರಸ್ತಾರಿಸು',
'ಪ್ರಸ್ತಾವನೆ',
'ಪ್ರಸ್ತಾವಿತ',
'ಪ್ರಸ್ತಾವಿಸು',
'ಪ್ರಸ್ಥಪುಷ್ಪ',
'ಪ್ರಸ್ಥಭೂಮಿ',
'ಪ್ರಸ್ಥಾಪಿಸು',
'ಪ್ರಸ್ಫುಟಿಸು',
'ಪ್ರಸ್ಫುರಿತ',
'ಪ್ರಸ್ಫುರಿಸು',
'ಪ್ರಸ್ಫೋಟನ',
'ಪ್ರಸ್ರವಣ',
'ಪ್ರಸ್ವಾಪನ',
'ಪ್ರಹತಿಸು',
'ಪ್ರಹರಣ',
'ಪ್ರಹರಣೆ',
'ಪ್ರಹರಿಣಿ',
'ಪ್ರಹರಿಸು',
'ಪ್ರಹರ್ಷಣ',
'ಪ್ರಹಸನ',
'ಪ್ರಹಸಿತ',
'ಪ್ರಹಾರಿಸು',
'ಪ್ರಹುಡಿಕೆ',
'ಪ್ರಹೇಲಿಕೆ',
'ಪ್ರಹೇಳಿಕೆ',
'ಪ್ರಳಯಾಗ್ನಿ',
'ಪ್ರಳಾಪಿಸು',
'ಪ್ರಾಂಜಲತೆ',
'ಪ್ರಾಂತಲೋಪ',
'ಪ್ರಾಂಶುಪಾಲ',
'ಪ್ರಾಕೃತಿಕ',
'ಪ್ರಾಕ್ಪ್ರಣೀತ',
'ಪ್ರಾಗವಸ್ಥೆ',
'ಪ್ರಾಗ್ವಾಸನೆ',
'ಪ್ರಾಘುಣಕ',
'ಪ್ರಾಚೀನತೆ',
'ಪ್ರಾಚೇತಸ',
'ಪ್ರಾಚ್ಯಶೈಲ',
'ಪ್ರಾಜಾಪತ್ಯ',
'ಪ್ರಾಜ್ಞಮತಿ',
'ಪ್ರಾಡ್ವಿವಾಕ',
'ಪ್ರಾಣಂಬಡೆ',
'ಪ್ರಾಣಂಬೊಯ್',
'ಪ್ರಾಣಕಾಂತ',
'ಪ್ರಾಣಕಾಂತೆ',
'ಪ್ರಾಣಕೊಡು',
'ಪ್ರಾಣಗುಣ',
'ಪ್ರಾಣಗೇಡಿ',
'ಪ್ರಾಣತೆಗೆ',
'ಪ್ರಾಣತ್ಯಾಗ',
'ಪ್ರಾಣದಾನ',
'ಪ್ರಾಣನಷ್ಟ',
'ಪ್ರಾಣನಾಥ',
'ಪ್ರಾಣನಾಥೆ',
'ಪ್ರಾಣಪಣ',
'ಪ್ರಾಣಪತಿ',
'ಪ್ರಾಣಪದ',
'ಪ್ರಾಣಪೂಜೆ',
'ಪ್ರಾಣಪ್ರಿಯ',
'ಪ್ರಾಣಪ್ರಿಯೆ',
'ಪ್ರಾಣಬಾಧೆ',
'ಪ್ರಾಣಬಿಡು',
'ಪ್ರಾಣಭಯ',
'ಪ್ರಾಣಮಿತ್ರ',
'ಪ್ರಾಣಲಿಂಗ',
'ಪ್ರಾಣಲಿಂಗಿ',
'ಪ್ರಾಣವಧೆ',
'ಪ್ರಾಣವಾಯು',
'ಪ್ರಾಣಸಖ',
'ಪ್ರಾಣಸಖಿ',
'ಪ್ರಾಣಸಮ',
'ಪ್ರಾಣಸಮೆ',
'ಪ್ರಾಣಸ್ಥಾನ',
'ಪ್ರಾಣಹತಿ',
'ಪ್ರಾಣಹತ್ಯೆ',
'ಪ್ರಾಣಹರ',
'ಪ್ರಾಣಾಂತಿಕ',
'ಪ್ರಾಣಾಪಾಯ',
'ಪ್ರಾಣಾಯಾಮ',
'ಪ್ರಾಣಾರ್ಪಣೆ',
'ಪ್ರಾಣಾವಧಿ',
'ಪ್ರಾಣಾಹುತಿ',
'ಪ್ರಾಣಿದ್ಯೂತ',
'ಪ್ರಾಣಿಧರ್ಮ',
'ಪ್ರಾಣಿವಧೆ',
'ಪ್ರಾಣಿಶಾಸ್ತ್ರ',
'ಪ್ರಾಣಿಹಿಂಸೆ',
'ಪ್ರಾಣೇಶ್ವರ',
'ಪ್ರಾಣೇಶ್ವರಿ',
'ಪ್ರಾತಃಕಾಲ',
'ಪ್ರಾತರಾಶ',
'ಪ್ರಾತರ್ವಿಧಿ',
'ಪ್ರಾತಿಕೂಲ್ಯ',
'ಪ್ರಾತಿನಿಧ್ಯ',
'ಪ್ರಾತಿಭಾವ್ಯ',
'ಪ್ರಾತಿವೇಶ್ಯ',
'ಪ್ರಾತಿಶಾಖ್ಯೆ',
'ಪ್ರಾತಿಹಾರ್ಯ',
'ಪ್ರಾತ್ಯಕ್ಷಿಕ',
'ಪ್ರಾತ್ಯಕ್ಷಿಕೆ',
'ಪ್ರಾತ್ಯಯಿಕ',
'ಪ್ರಾಥಮಿಕ',
'ಪ್ರಾದಕ್ಷಿಣ',
'ಪ್ರಾದುರ್ಭಾವ',
'ಪ್ರಾದೇಶನ',
'ಪ್ರಾದೇಶಿಕ',
'ಪ್ರಾದೋಷಿಕ',
'ಪ್ರಾಧಾನ್ಯತೆ',
'ಪ್ರಾಧಿಕಾರ',
'ಪ್ರಾಧಿಕಾರಿ',
'ಪ್ರಾಧ್ಯಾಪಕ',
'ಪ್ರಾಪಂಚಿಕ',
'ಪ್ರಾಪಣಿಕ',
'ಪ್ರಾಬೋಧಿಕ',
'ಪ್ರಾಭಂಜನ',
'ಪ್ರಾಭಾಕರ',
'ಪ್ರಾಭಾತಿಕ',
'ಪ್ರಾಮಾಣಿಕ',
'ಪ್ರಾಮುಖ್ಯತೆ',
'ಪ್ರಾಯಶ್ಚಿತ್ತ',
'ಪ್ರಾಯೋಗಿಕ',
'ಪ್ರಾಯೋಜಕ',
'ಪ್ರಾಯೋಜಿತ',
'ಪ್ರಾರಂಭಿಗ',
'ಪ್ರಾರಂಭಿಸು',
'ಪ್ರಾರಬುದ',
'ಪ್ರಾಲಂಬಕ',
'ಪ್ರಾಲಂಬಿಕೆ',
'ಪ್ರಾಲೇಯಾಂಶು',
'ಪ್ರಾವರಣ',
'ಪ್ರಾವಿರ್ಭೂತ',
'ಪ್ರಾವೀಣ್ಯತೆ',
'ಪ್ರಾವೃಟ್ಕಾಲ',
'ಪ್ರಾವೃಷೇಣ್ಯ',
'ಪ್ರಾಶಿ್ನಕ',
'ಪ್ರಾಸಂಗಿಕ',
'ಪ್ರಾಸಾಕ್ಷರ',
'ಪ್ರಾಸಾದಿಕ',
'ಪ್ರಾಸ್ತಾವಿಕ',
'ಪ್ರಿಯಂಕರಿ',
'ಪ್ರಿಯಕರ',
'ಪ್ರಿಯಜೀವ',
'ಪ್ರಿಯತಮ',
'ಪ್ರಿಯತಮೆ',
'ಪ್ರಿಯವತಿ',
'ಪ್ರಿಯವಲ್ಲಿ',
'ಪ್ರಿಯಶೈಲ',
'ಪ್ರೀತಿಂಕರ',
'ಪ್ರೀತಿಪಾತ್ರ',
'ಪ್ರೀತಿಪಾತ್ರೆ',
'ಪ್ರೀತಿಬಡು',
'ಪ್ರೀತಿವಡು',
'ಪ್ರೇಂಖೋಲನ',
'ಪ್ರೇಂಖೋಲಿತ',
'ಪ್ರೇಂಖೋಳನ',
'ಪ್ರೇಂಖೋಳಿತ',
'ಪ್ರೇಕ್ಷಣೀಯ',
'ಪ್ರೇಕ್ಷಾಗಾರ',
'ಪ್ರೇಕ್ಷಾಗೃಹ',
'ಪ್ರೇತಕರ್ಮ',
'ಪ್ರೇತಕಳೆ',
'ಪ್ರೇತಕಾರ್ಯ',
'ಪ್ರೇತನಾಥ',
'ಪ್ರೇತಪತಿ',
'ಪ್ರೇತಭೂಮಿ',
'ಪ್ರೇತಯಾತ್ರೆ',
'ಪ್ರೇತವನ',
'ಪ್ರೇತವಿದ್ಯೆ',
'ಪ್ರೇತಾವಾಸ',
'ಪ್ರೇಮಕಥೆ',
'ಪ್ರೇಮಗೀತ',
'ಪ್ರೇಮಗೀತೆ',
'ಪ್ರೇಮಪಾತ್ರ',
'ಪ್ರೇಮಭಂಗ',
'ಪ್ರೇರಣಿಸು',
'ಪ್ರೇರೇಪಕ',
'ಪ್ರೇರೇಪಕಿ',
'ಪ್ರೇರೇಪಣೆ',
'ಪ್ರೇರೇಪಿತ',
'ಪ್ರೇರೇಪಿಸು',
'ಪ್ರೋಚ್ಚಳಿತ',
'ಪ್ರೋಜ್ವಲಿಸು',
'ಪ್ರೋತ್ಕಲಿತ',
'ಪ್ರೋತ್ಪಾಟನ',
'ಪ್ರೋತ್ಪಿಹಿತ',
'ಪ್ರೋತ್ಸಾಹಕ',
'ಪ್ರೋತ್ಸಾಹಕಿ',
'ಪ್ರೋತ್ಸಾಹಿಸು',
'ಪ್ರೋನ್ಮಳಿತ',
'ಪ್ರೋಷ್ಠಪದ',
'ಪ್ರೌಢವತ್ಸೆ',
'ಪ್ರೌಢಶಾಲೆ',
'ಪ್ರೌಢಿವೆಱು',
'ಪ್ಲವಂಗಮ',
'ಪ್ಲೀಹಶತ್ರು',
'ಪ್ಲೀಹಾಪಹೆ',
'ಫಂಟತನ',
'ಫಕಫಕ',
'ಫಡಗಳ್ಳಿ',
'ಫಡಣೀಸ',
'ಫಡನೀಸ',
'ಫಡಪೋಶಿ',
'ಫಡಪೋಸಿ',
'ಫಣಕಟ್ಟು',
'ಫಣಭೃತ್ತು',
'ಫಣಮಣಿ',
'ಫಣಾಧರ',
'ಫಣಾಮಣಿ',
'ಫಣಾರತ್ನ',
'ಫಣಿಗೃಹ',
'ಫಣಿಗೇಹ',
'ಫಣಿಜ್ಝಕ',
'ಫಣಿಪಾಶ',
'ಫಣಿಭುಕ್ಕು',
'ಫಣಿಭೋಗ',
'ಫಣಿರ್ಜಕ',
'ಫಣಿಲೋಕ',
'ಫಣಿವೇಣಿ',
'ಫಣೆಕಟ್ಟು',
'ಫಣೆಗಣ್ಣು',
'ಫತ್ತೆಮಾರಿ',
'ಫತ್ತೇಮಾರಿ',
'ಫರಫರೆ',
'ಫರಮಾನಾ',
'ಫರಮಾನು',
'ಫರಮಾಶಿ',
'ಫರವಾನೆ',
'ಫರಮೋಷಿ',
'ಫರಾಶೀಸ',
'ಫರಾಷೀಸ',
'ಫರಾಸಿಸ',
'ಫರ್ಮಾಯಿಸು',
'ಫಲಂಗುಡು',
'ಫಲಂದೋಱು',
'ಫಲಕಾರಿ',
'ಫಲಕೊಡು',
'ಫಲಜೀವಿ',
'ಫಲಜ್ಞಾನ',
'ಫಲತ್ರಿಕ',
'ಫಲದಾಯಿ',
'ಫಲದೋಱು',
'ಫಲಪೂಜೆ',
'ಫಲಪೂರ',
'ಫಲಪ್ರದ',
'ಫಲಭದ್ರ',
'ಫಲವಂತ',
'ಫಲವತಿ',
'ಫಲವತ್ತು',
'ಫಲಶ್ರುತಿ',
'ಫಲಸಿದ್ಧಿ',
'ಫಲಹಾರ',
'ಫಲಹೀನ',
'ಫಲಾತನ್',
'ಫಲಾದನ',
'ಫಲಾಧ್ಯಕ್ಷ',
'ಫಲಾಹಾರ',
'ಫಲಿತಾಂಶ',
'ಫಲಿತಾರ್ಥ',
'ಫಲಿಯಿಸು',
'ಫಲಿಸಿಕೆ',
'ಫಲುಗುಣ',
'ಫಲೇರುಹ',
'ಫಸಾಯಿಸು',
'ಫಳಕಾರ',
'ಫಳವತಿ',
'ಫಳಹೀನ',
'ಫಳಿಯಿಸು',
'ಫಳಿಹತ',
'ಫಾಜೆಗಟ್ಟಿ',
'ಫಾಣಾಫಾಣ',
'ಫಾನಾಫಾನ',
'ಫಾರೀಕತ್ತು',
'ಫಾಲದಿವ್ಯ',
'ಫಾಲ್ಗುನಿಕ',
'ಫಾಸಿಕಂಬ',
'ಫಾಸಿಖಾನೆ',
'ಫಾಸಿಶಿಕ್ಷೆ',
'ಫಿನಾಯಿಲ್ಲು',
'ಫಿರಿಕಟ್ಟು',
'ಫಿರಿಯಾದಿ',
'ಫಿರಿಯಾದು',
'ಫುಟಾಫುಟಿ',
'ಫುಸಲತ್ತು',
'ಫೂಟುದೊರೆ',
'ಫೂತ್ಕರಿಸು',
'ಫೂಲ್‍ಸ್ಕೇಪು',
'ಫೂಲ್ಸ್‍ಕೇಪು',
'ಫೇಲಿಪಾತ್ರೆ',
'ಫೈಸಲಾತಿ',
'ಫೌಜದಾರ್',
'ಫೌಜದಾರ',
'ಫೌಜದಾರಿ',
'ಫೌಜುದಾರ',
'ಫೌಜುದಾರಿ',
'ಬಂಕಂಗಡಿ',
'ಬಂಕಗಾಲು',
'ಬಂಕದೆಱೆ',
'ಬಂಗರಳಿ',
'ಬಂಗಳಿಚ',
'ಬಂಗಿಮುಕ್ಕ',
'ಬಂಗುರಿಸು',
'ಬಂಜುಗೆಡು',
'ಬಂಜೆತನ',
'ಬಂಜೆವಾತು',
'ಬಂಟತನ',
'ಬಂಟಸಾಮಿ',
'ಬಂಟುಗೆಡು',
'ಬಂಟುಗೆಯ್',
'ಬಂಟುತನ',
'ಬಂಡತನ',
'ಬಂಡಬಾಳು',
'ಬಂಡಮಾತು',
'ಬಂಡವಲ',
'ಬಂಡವಲು',
'ಬಂಡವಳ',
'ಬಂಡವಾಲ',
'ಬಂಡವಾಳ',
'ಬಂಡವಾಳು',
'ಬಂಡಸಾಲೆ',
'ಬಂಡಳಿಸು',
'ಬಂಡಾರಿಗ',
'ಬಂಡಾರಿಸು',
'ಬಂಡಿಕಾರ',
'ಬಂಡಿಕಾಱ',
'ಬಂಡಿಬೋವ',
'ಬಂಡಿಮಾಳ',
'ಬಂಡಿಹೋಕ',
'ಬಂಡುಕೋರ',
'ಬಂಡುಖೋರ',
'ಬಂಡುಗಟ್ಟು',
'ಬಂಡುಗಾರ',
'ಬಂಡುಗೆಡೆ',
'ಬಂಡುಗೆಯ್',
'ಬಂಡುಗೆಲೆ',
'ಬಂಡುಣಿಸು',
'ಬಂಡುಬಾಳು',
'ಬಂಡುವಾತು',
'ಬಂಡುವೋಗು',
'ಬಂಡೆಕಲ್ಲು',
'ಬಂಡೆಗಲ್ಲು',
'ಬಂಡೆಬ್ಬಿಸು',
'ಬಂತಿಗಟ್ಟು',
'ಬಂದಂಬಿಡು',
'ಬಂದಣಿಕೆ',
'ಬಂದಣಿಗೆ',
'ಬಂದನಿಕೆ',
'ಬಂದನಿಕ್ಕಿ',
'ಬಂದಮಿಣಿ',
'ಬಂದರಿಕೆ',
'ಬಂದಲಿಕೆ',
'ಬಂದಳಿಕೆ',
'ಬಂದಾರಿಕೆ',
'ಬಂದಿಕಾಯಿ',
'ಬಂದಿಕಾರ',
'ಬಂದಿಕಾಱ',
'ಬಂದಿಕೋಲು',
'ಬಂದಿಖಾನ',
'ಬಂದಿಖಾನೆ',
'ಬಂದಿಗಟ್ಟು',
'ಬಂದಿಗಾಱ',
'ಬಂದಿಗೃಹ',
'ಬಂದಿಗೊಳ್',
'ಬಂದಿಗೋಲ್',
'ಬಂದಿವಡು',
'ಬಂದಿವನೆ',
'ಬಂದಿವರ್',
'ಬಂದಿವಾನ',
'ಬಂದಿವಾೞ್',
'ಬಂದಿವಾೞ್ಗೆ',
'ಬಂದಿವಿಡಿ',
'ಬಂದಿಶಾಲೆ',
'ಬಂದಿಹಿಡಿ',
'ಬಂದಿಹೊಗು',
'ಬಂದೀಖಾನೆ',
'ಬಂದೀವಾನ',
'ಬಂದೀವಾಸ',
'ಬಂದುಕಟ್ಟು',
'ಬಂದೋಬಸ್ತ್',
'ಬಂದೋಬಸ್ತಿ',
'ಬಂದೋಬಸ್ತು',
'ಬಂಧತಂತ್ರ',
'ಬಂಧವಿದ',
'ಬಂಧಸ್ತಂಭ',
'ಬಂಧಾಬಂಧಿ',
'ಬಂಧುಕೃತ್ಯ',
'ಬಂಧುಜನ',
'ಬಂಧುಜೀವ',
'ಬಂಧುತನ',
'ಬಂಧುರತೆ',
'ಬಂಧುರಿತ',
'ಬಂಧುವರ್ಗ',
'ಬಂಧುಸ್ಥಾನ',
'ಬಂಬಲಿಸು',
'ಬಂಬಲ್ಗೊಳ್',
'ಬಂಬಲ್ವರಿ',
'ಬಂಬಳಿಸು',
'ಬಂಭ್ರಮಿಸು',
'ಬಕಗ್ರೀವ',
'ಬಕತರ',
'ಬಕಧ್ಯಾನ',
'ಬಕವೃತ್ತಿ',
'ಬಕವ್ರತಿ',
'ಬಕಾಸನ',
'ಬಕ್ಕತಲೆ',
'ಬಕ್ಕದಲೆ',
'ಬಕ್ಕದಾಸ',
'ಬಕ್ಕನೆತ್ತಿ',
'ಬಕ್ಕಬೋಳು',
'ಬಕ್ಕರಿಸು',
'ಬಕ್ಕುಡೆಯ',
'ಬಕ್ರಬರ್',
'ಬಗದಳ',
'ಬಗದಾರೆ',
'ಬಗದಾಳ',
'ಬಗರಗೆ',
'ಬಗಲಂಗಿ',
'ಬಗಲಾಂಟ',
'ಬಗಸಿಗೆ',
'ಬಗಿಯೇಱು',
'ಬಗುಸಾಳೆ',
'ಬಗುಳಾಟ',
'ಬಗುಳಾಡು',
'ಬಗುಳಿಸು',
'ಬಗೆಕಾರ',
'ಬಗೆಕಾರ್ತಿ',
'ಬಗೆಕಾಱ',
'ಬಗೆಗಲ್',
'ಬಗೆಗಾಣ್',
'ಬಗೆಗಾಣು',
'ಬಗೆಗಾರ',
'ಬಗೆಗಾರ್ತಿ',
'ಬಗೆಗಾಱ',
'ಬಗೆಗಿಡು',
'ಬಗೆಗುಂದು',
'ಬಗೆಗುಡು',
'ಬಗೆಗುದಿ',
'ಬಗೆಗೆಡು',
'ಬಗೆಗೆಲ್',
'ಬಗೆಗೇಡು',
'ಬಗೆಗೊಳ್',
'ಬಗೆಗೊಳು',
'ಬಗೆಗೊಳ್ಳು',
'ಬಗೆತುಂಬು',
'ಬಗೆದಟ್ಟು',
'ಬಗೆದರ್',
'ಬಗೆದೀರ್',
'ಬಗೆದುಂಬು',
'ಬಗೆದೆರು',
'ಬಗೆದೋಱು',
'ಬಗೆಪೋಗು',
'ಬಗೆಬರ್',
'ಬಗೆಬಿಚ್ಚು',
'ಬಗೆಮುಟ್ಟು',
'ಬಗೆಯಾಣ್ಮ',
'ಬಗೆಯಿಡು',
'ಬಗೆಯಿಸು',
'ಬಗೆವಡೆ',
'ಬಗೆವರ್',
'ಬಗೆವಿಡಿ',
'ಬಗೆವುಗು',
'ಬಗೆವೆಚ್ಚು',
'ಬಗೆವೆರ್ಚು',
'ಬಗೆವೆಱು',
'ಬಗೆವೇಳ್',
'ಬಗೆವೇೞ್',
'ಬಗೆವೊಗು',
'ಬಗೆಹರಿ',
'ಬಗ್ಗಡೆಯ',
'ಬಗ್ಗಸಮ್ಮ',
'ಬಗ್ಗುಬಡಿ',
'ಬಗ್ಗುವೆಣ್',
'ಬಗ್ರಿಹಗ್ಗ',
'ಬಚಕಾನಿ',
'ಬಚನಾಗ',
'ಬಚಾಯಿಸು',
'ಬಚ್ಚಂಗಾಯಿ',
'ಬಚ್ಚಕಾನೆ',
'ಬಚ್ಚಖಾನೆ',
'ಬಚ್ಚಗಾನಿ',
'ಬಚ್ಚಣಿಸು',
'ಬಚ್ಚರಣೆ',
'ಬಚ್ಚಾಣಿಗೆ',
'ಬಚ್ಚುಬಾಯಿ',
'ಬಚ್ಚೆವೆಣ್',
'ಬಚ್ಚೆವೋಗು',
'ಬಜಾಯಿಸು',
'ಬಜಾವಣೆ',
'ಬಟಮಾನ್ಯ',
'ಬಟವಡೆ',
'ಬಟವಾಡೆ',
'ಬಟುಗಲ್ಲು',
'ಬಟ್ಟಗಲ್',
'ಬಟ್ಟಗಲ್ಲು',
'ಬಟ್ಟಗುತ್ತ',
'ಬಟ್ಟಗೆಯ್ದು',
'ಬಟ್ಟಗೈದು',
'ಬಟ್ಟಚಕ್ರ',
'ಬಟ್ಟತಲೆ',
'ಬಟ್ಟದಲೆ',
'ಬಟ್ಟಬೈಲು',
'ಬಟ್ಟಲುಹೂ',
'ಬಟ್ಟವಕ್ಕಿ',
'ಬಟ್ಟವಡೆ',
'ಬಟ್ಟಾಡಿಸು',
'ಬಟ್ಟಿಕಟ್ಟು',
'ಬಟ್ಟಿಜಾರು',
'ಬಟ್ಟಿಬೀಳು',
'ಬಟ್ಟುಗೆಯ್',
'ಬಟ್ಟುಗೆಯ್ದು',
'ಬಟ್ಟುವೆಱು',
'ಬಟ್ಟೆಗಟ್ಟು',
'ಬಟ್ಟೆಗಾಣ್',
'ಬಟ್ಟೆಗೆಡು',
'ಬಟ್ಟೆಗೊಡು',
'ಬಟ್ಟೆದಪ್ಪು',
'ಬಟ್ಟೆದೆಗೆ',
'ಬಟ್ಟೆದೋಱು',
'ಬಟ್ಟೆವಿಡಿ',
'ಬಟ್ಟೆವೋಗು',
'ಬಡಕಟ್ಟೆ',
'ಬಡಕಲ',
'ಬಡಕಲು',
'ಬಡಕಾಟಿ',
'ಬಡಕಾಣಿ',
'ಬಡಕಾಯ್',
'ಬಡಗಣ್',
'ಬಡಗಣ',
'ಬಡಗಲ್',
'ಬಡಗಲು',
'ಬಡಗಲೆ',
'ಬಡಗಲೈ',
'ಬಡಗಾಯ್',
'ಬಡತನ',
'ಬಡತರ್ಫ್',
'ಬಡತಿಗೆ',
'ಬಡನುಡಿ',
'ಬಡಪಟ್ಟು',
'ಬಡಪಡು',
'ಬಡಪತ್ತು',
'ಬಡಪಶು',
'ಬಡಪಾಯಿ',
'ಬಡಪೆಟ್ಟು',
'ಬಡಬಡ',
'ಬಡಬಾಗ್ನಿ',
'ಬಡಬಿಜ್ಜೆ',
'ಬಡಮತಿ',
'ಬಡಮನ',
'ಬಡಮಾತು',
'ಬಡವಡು',
'ಬಡವೆಣ್',
'ಬಡವೆಱೆ',
'ಬಡಹೆಱೆ',
'ಬಡಾಯಿಸು',
'ಬಡಾವಣೆ',
'ಬಡಿಕೊಳ್',
'ಬಡಿಕೋಲ್',
'ಬಡಿಕೋಲು',
'ಬಡಿಗಲ್ಲು',
'ಬಡಿಗುಂಡು',
'ಬಡಿಗೊಳ್',
'ಬಡಿಗೋಲ್',
'ಬಡಿಗೋಲು',
'ಬಡಿದಂಟು',
'ಬಡಿದಾಟ',
'ಬಡಿದಾಡು',
'ಬಡಿಮಣೆ',
'ಬಡಿವಡೆ',
'ಬಡಿವರ',
'ಬಡಿವಲೆ',
'ಬಡಿವಾರ',
'ಬಡಿವೆಱು',
'ಬಡಿವೋರಿ',
'ಬಡಿಹಡೆ',
'ಬಡಿಹೋರಿ',
'ಬಡೀಸೋಪು',
'ಬಡೇಜಾವು',
'ಬಡೇಸೋಪು',
'ಬಡ್ಡಿಕಾಱ',
'ಬಡ್ಡಿಗುಡು',
'ಬಡ್ಡಿಗೊಡು',
'ಬಡ್ಡಿಗೊಳ್',
'ಬಡ್ಡಿಮಗ',
'ಬಡ್ಡಿಯಿಕ್ಕು',
'ಬಡ್ಡಿಯುಣ್',
'ಬಡ್ಡಿವಡೆ',
'ಬಡ್ಡುಗಟ್ಟು',
'ಬಡ್ಡುಗತ್ತಿ',
'ಬಡ್ಡುಹಿಡಿ',
'ಬಡ್ಡೆಜಾಲಿ',
'ಬಣಂಜಿಗ',
'ಬಣಂಜಿಸು',
'ಬಣಕಾರ',
'ಬಣಗಾರ',
'ಬಣಗುಟ್ಟು',
'ಬಣಜಿಗ',
'ಬಣಜಿಸು',
'ಬಣತಿಗೆ',
'ಬಣದುರ್ಗಿ',
'ಬಣ್ಣಂಗುಂದು',
'ಬಣ್ಣಂಗೆಡು',
'ಬಣ್ಣಂಬಡೆ',
'ಬಣ್ಣಕಟ್ಟು',
'ಬಣ್ಣಗಂಬಿ',
'ಬಣ್ಣಗಟ್ಟು',
'ಬಣ್ಣಗತೆ',
'ಬಣ್ಣಗಬ್ಬ',
'ಬಣ್ಣಗಲ್ಲು',
'ಬಣ್ಣಗಾಣ್',
'ಬಣ್ಣಗಾರ',
'ಬಣ್ಣಗಾಱ',
'ಬಣ್ಣಗುಂದು',
'ಬಣ್ಣಗೂಡು',
'ಬಣ್ಣಗೂಳ್',
'ಬಣ್ಣಗೂಳು',
'ಬಣ್ಣಗೆಡು',
'ಬಣ್ಣಗೊಳ್',
'ಬಣ್ಣಗೋಲ್',
'ಬಣ್ಣಗೋಲ',
'ಬಣ್ಣದಳೆ',
'ಬಣ್ಣದೋರು',
'ಬಣ್ಣದೋಱು',
'ಬಣ್ಣಮೀನ್',
'ಬಣ್ಣವಡೆ',
'ಬಣ್ಣವಳಿ',
'ಬಣ್ಣವಳ್ಗು',
'ಬಣ್ಣವಾಡು',
'ಬಣ್ಣವಾತು',
'ಬಣ್ಣವಿಕ್ಕು',
'ಬಣ್ಣವಿಡು',
'ಬಣ್ಣವಿಳಿ',
'ಬಣ್ಣವುರ',
'ಬಣ್ಣವೆರು',
'ಬಣ್ಣವೆರ್ಚು',
'ಬಣ್ಣವೇರು',
'ಬಣ್ಣವೇಱು',
'ಬಣ್ಣವೋಡು',
'ಬಣ್ಣಸರ',
'ಬಣ್ಣಾಂತರ',
'ಬಣ್ಣಾಚಾರ',
'ಬತಗೆಡು',
'ಬತಗೇಡಿ',
'ಬತಣಿಕೆ',
'ಬತಾರೀಕು',
'ಬತ್ತಂಗುಟ್ಟು',
'ಬತ್ತಕುಟ್ಟು',
'ಬತ್ತಗುಟ್ಟು',
'ಬತ್ತಲಿಕೆ',
'ಬತ್ತಲಿಗ',
'ಬತ್ತಲಿರ್',
'ಬತ್ತಲಿರು',
'ಬತ್ತಲಿಸು',
'ಬತ್ತಲೆಗ',
'ಬತ್ತಳಿಕೆ',
'ಬತ್ತಳಿಗೆ',
'ಬತ್ತಿಕೋವಿ',
'ಬತ್ತುಂಬಯ',
'ಬತ್ತುಂಬೞಿ',
'ಬತ್ತುವಾಲ್',
'ಬದಗಿಗ',
'ಬದಜಾತ',
'ಬದಣಿಕೆ',
'ಬದನಾಮಿ',
'ಬದನಿಕೆ',
'ಬದಮಾಷ್',
'ಬದರಿಕೆ',
'ಬದಲಿಸು',
'ಬದಲುಗೈ',
'ಬದಸಾಟ',
'ಬದಸ್ತೂರ',
'ಬದಸ್ತೂರು',
'ಬದಿಪಾರು',
'ಬದಿಯೆಲು',
'ಬದಿಶೂಲೆ',
'ಬದಿಹುಲ್ಲು',
'ಬದುಕಿಸು',
'ಬದೆಹುಲ್ಲು',
'ಬದ್ದವಣ',
'ಬದ್ದೆವೆಣ್',
'ಬದ್ಧತಾನ',
'ಬದ್ಧದ್ವೇಷ',
'ಬದ್ಧದ್ವೇಷಿ',
'ಬದ್ಧಮಲ',
'ಬದ್ಧಮೂಲ',
'ಬದ್ಧರಸ',
'ಬದ್ಧರೂಪ',
'ಬದ್ಧವೈರ',
'ಬದ್ಧವೈರಿ',
'ಬದ್ಧಾಂಜಲಿ',
'ಬದ್ಧೇಂದ್ರಿಯ',
'ಬಧಿರತೆ',
'ಬಧಿರತ್ವ',
'ಬಧಿರಿತ',
'ಬನಗಾಹು',
'ಬನದುರ್ಗ',
'ಬನಪಶ್ಯ',
'ಬನವತಿ',
'ಬನವೆಂಕಿ',
'ಬನವೆಂಕೆ',
'ಬನಸಂಪ',
'ಬನಸಿರಿ',
'ಬನಾವಟ್',
'ಬನಾವಟ',
'ಬನಾವಟಿ',
'ಬನಾವಣೆ',
'ಬನಿಗೊಳ್ಳು',
'ಬನಿಯನ್ನು',
'ಬನ್ನಂಗಾಯಿ',
'ಬನ್ನಂಬಡು',
'ಬನ್ನಂಬಡೆ',
'ಬನ್ನಂಬೆಱು',
'ಬನ್ನಂಬೊಂದು',
'ಬನ್ನಗೆಡು',
'ಬನ್ನಣೆಗೈ',
'ಬನ್ನಪಡು',
'ಬನ್ನಬಡು',
'ಬನ್ನವಂತ',
'ಬನ್ನವಡು',
'ಬನ್ಸಿಗೋಧಿ',
'ಬನ್ಸಿರವೆ',
'ಬಮೆಗೊಳ್',
'ಬಮೋಜೀಬ್',
'ಬಯಂಗೊಳ್',
'ಬಯಕಾಱ',
'ಬಯಲಾಟ',
'ಬಯಲಿಗ',
'ಬಯಲುಗ',
'ಬಯಲುಗೈ',
'ಬಯಲೊಲ್ಮೆ',
'ಬಯಲ್ಕಡೆ',
'ಬಯಲ್ಗಾಳಿ',
'ಬಯಲ್ಗೆಡೆ',
'ಬಯಲ್ದೊರೆ',
'ಬಯಲ್ದೊಱೆ',
'ಬಯಲ್ನಾಡು',
'ಬಯಲ್ನುಡಿ',
'ಬಯಲ್ವುಗು',
'ಬಯಸಿಕೆ',
'ಬಯಸುಹ',
'ಬಯಸ್ಕೋಪು',
'ಬಯಾಸ್ಕೋಪು',
'ಬಯ್ಗುಂಬೊಳ್ತು',
'ಬಯ್ಗುಂಬೊೞ್ತು',
'ಬಯ್ಗುಕೆಂಪು',
'ಬಯ್ಗುವೊೞ್ತು',
'ಬಯ್ರಿಬತ್ತ',
'ಬರಕತ್ತು',
'ಬರಕಾಸ್ತು',
'ಬರಖತ್ತು',
'ಬರಖಾಸ್ತ',
'ಬರಖಾಸ್ತು',
'ಬರಗಲೆ',
'ಬರಗಾಡು',
'ಬರಗಾಲ',
'ಬರಗೆಡು',
'ಬರತರ್ಪು',
'ಬರತರ್ಫು',
'ಬರಬತ್ತ',
'ಬರಬರ',
'ಬರಮಪ್ಪ',
'ಬರಮೋಡ',
'ಬರವಣ',
'ಬರವಸ',
'ಬರವಿಗ',
'ಬರಷಣ',
'ಬರಾಬರಿ',
'ಬರಾಬ್ಬರಿ',
'ಬರಾಯಿಸು',
'ಬರಾವರ್ದು',
'ಬರಿಕೆಯ್',
'ಬರಿಕೋಲು',
'ಬರಿಗಂಡ',
'ಬರಿಗಾಲು',
'ಬರಿಗೆರೆ',
'ಬರಿದಲೆ',
'ಬರಿಮನ',
'ಬರಿಮಾತು',
'ಬರಿಯಾಟ',
'ಬರಿಯಿಸು',
'ಬರಿಯೆಲ್',
'ಬರಿಯೆಲ್ವು',
'ಬರಿವೊಯ್ಲ',
'ಬರುಗಯ್',
'ಬರುಗಲ್ಲು',
'ಬರುಗಾಲು',
'ಬರುದಲೆ',
'ಬರುವಳಿ',
'ಬರೆಕೋಲು',
'ಬರೆಗುಳ',
'ಬರೆಗೊಳ್',
'ಬರೆಗೋಲು',
'ಬರೆಯಿಡು',
'ಬರೆಯಿಸು',
'ಬರೆಯೆಳೆ',
'ಬರೋಬರಿ',
'ಬರೋಬ್ಬರ್',
'ಬರೋಬ್ಬರ',
'ಬರೋಬ್ಬರಿ',
'ಬರ್ಚಿಗಾರ',
'ಬರ್ತರಫ್',
'ಬರ್ದಿಲಾಣ್ಮ',
'ಬರ್ದಿಲಾಳ್ಮ',
'ಬರ್ದುಂಕಿಸು',
'ಬರ್ದುಕಾಡು',
'ಬರ್ದುಕಿಸು',
'ಬರ್ದುಗಾರ್ತಿ',
'ಬರ್ದುಗೆಡು',
'ಬರ್ದುನುಡಿ',
'ಬರ್ದುವೆಣ್',
'ಬರ್ಬರತೆ',
'ಬರ್ಹಿರ್ಮುಖ',
'ಬರ್ಹಿಷ್ಕೇಶ',
'ಬಲಋದ್ಧಿ',
'ಬಲಂಗೆಡು',
'ಬಲಂಗೊಳ್',
'ಬಲಂಬೆಱು',
'ಬಲಂಬೊಯ್',
'ಬಲಕೆಯ್',
'ಬಲಗಟ್ಟು',
'ಬಲಗಡೆ',
'ಬಲಗಯ್',
'ಬಲಗರ್ವ',
'ಬಲಗಾಲ್',
'ಬಲಗಾಲು',
'ಬಲಗುಂದು',
'ಬಲಗುಡು',
'ಬಲಗೂಡು',
'ಬಲಗೆಯ್',
'ಬಲಗೆಲ',
'ಬಲಗೊಳ್',
'ಬಲಗೊಳ್ಳು',
'ಬಲಗೊಳ್ಳು2',
'ಬಲಗೋಲು',
'ಬಲಗೋಳು',
'ಬಲಚೋರಿ',
'ಬಲಜ್ಯಾಯ',
'ಬಲತಮ್ಮ',
'ಬಲತಾಯಿ',
'ಬಲದಾಯ್',
'ಬಲದೃಪ್ತ',
'ಬಲದೊಡ್ಡಿ',
'ಬಲಭೇದಿ',
'ಬಲಮತ್ತ',
'ಬಲಮುರಿ',
'ಬಲಯುತ',
'ಬಲಯುತೆ',
'ಬಲರಾಕ್ಷಿ',
'ಬಲರೇಖೆ',
'ಬಲಲೊಡ್ಡಿ',
'ಬಲವಂತ',
'ಬಲವಂತೆ',
'ಬಲವರ್',
'ಬಲವರು',
'ಬಲಶಾಲಿ',
'ಬಲಸಂಕು',
'ಬಲಸುತ್ತು',
'ಬಲಸೊಸೆ',
'ಬಲಹಂಸ',
'ಬಲಹೀನ',
'ಬಲಹೀನೆ',
'ಬಲಾಚಾರ',
'ಬಲಾತೈಲ',
'ಬಲಾತ್ಕಾರ',
'ಬಲಾಧಿಕ',
'ಬಲಾಧಿಪ',
'ಬಲಾಧ್ಯಕ್ಷ',
'ಬಲಾನ್ವಿತ',
'ಬಲಾನ್ವಿತೆ',
'ಬಲಾರಾಕ್ಷಿ',
'ಬಲಾಹಕ',
'ಬಲಿಕರ್ಮ',
'ಬಲಿಕಲ್ಲು',
'ಬಲಿಕಾರ್ಯ',
'ಬಲಿಕೆಯ್',
'ಬಲಿಕೊಡು',
'ಬಲಿಗಂಬ',
'ಬಲಿಗಟ್ಟು',
'ಬಲಿಗಲ್ಲು',
'ಬಲಿಗುಡು',
'ಬಲಿಗೂೞ್',
'ಬಲಿಗೆಯ್',
'ಬಲಿಗೊಡು',
'ಬಲಿಗೊಳ್',
'ಬಲಿಗೊಳ್ಳು',
'ಬಲಿದಾನ',
'ಬಲಿಪಶು',
'ಬಲಿಪಾಡ್ಯ',
'ಬಲಿಪೀಠ',
'ಬಲಿಪುಷ್ಟ',
'ಬಲಿಪೂಜೆ',
'ಬಲಿಭುಕ್ಕು',
'ಬಲಿಭೋಜಿ',
'ಬಲಿಮುಖ',
'ಬಲಿಮೂರ್ತಿ',
'ಬಲಿಯಿಕ್ಕು',
'ಬಲಿಯಿಸು',
'ಬಲಿವಡೆ',
'ಬಲಿವಿಡಿ',
'ಬಲೀಮುಖ',
'ಬಲೀಮುಖಿ',
'ಬಲೀವರ್ದ',
'ಬಲುಕಂಬಿ',
'ಬಲುಕಣಿ',
'ಬಲುಗಡಿ',
'ಬಲುಗಯ್',
'ಬಲುಗಯ್ಯ',
'ಬಲುಗೆಯ್',
'ಬಲುಗೆಯ್ಯ',
'ಬಲುಗೈಯ',
'ಬಲುಹಳಿ',
'ಬಲೆಗಣ್',
'ಬಲೆಗಾರ',
'ಬಲೆಗಾಱ',
'ಬಲೆಬೆತ್ತ',
'ಬಲೆಹುಲ್ಲು',
'ಬಲೋದ್ಧತೆ',
'ಬಲೋದ್ಬಂದಿ',
'ಬಲೋನ್ಮತ್ತ',
'ಬಲ್ಕೆವೆಱು',
'ಬಲ್ದಡಿಗ',
'ಬಲ್ಪುಗಿಡು',
'ಬಲ್ಪುಗೆಡು',
'ಬಲ್ಮೆಕಾಱ',
'ಬಲ್ಮೆಗಾರ',
'ಬಲ್ಮೆಗಾಱ',
'ಬಲ್ಮೆವಂತ',
'ಬಲ್ಲಂತಿಕೆ',
'ಬಲ್ಲತನ',
'ಬಲ್ಲರಿಕೆ',
'ಬಲ್ಲವಿಕೆ',
'ಬಲ್ಲಾಳಿಕೆ',
'ಬಲ್ಲಾಳ್ತನ',
'ಬಲ್ವಲಿಕೆ',
'ಬವಣಿಗ',
'ಬವಣಿಗೆ',
'ಬವನಾಶಿ',
'ಬವನಾಸಿ',
'ಬವರಿಗ',
'ಬವರಿಸು',
'ಬವಸಿಗ',
'ಬವಳಿಕೆ',
'ಬಷ್ಕಯಣಿ',
'ಬಸಂಗೆಡು',
'ಬಸಂಗೆಯ್',
'ಬಸಕ್ಕನೆ',
'ಬಸಗಡಿ',
'ಬಸಗಿಡು',
'ಬಸಗೆಡು',
'ಬಸನಿಗ',
'ಬಸನಿಗೆ',
'ಬಸಮೞಿ',
'ಬಸಮುಱು',
'ಬಸಯಿಸು',
'ಬಸರಾಯಿ',
'ಬಸವಂತ',
'ಬಸವಂತಿ',
'ಬಸವಡು',
'ಬಸವಡೆ',
'ಬಸವಣ',
'ಬಸವಣ್ಣ',
'ಬಸವಳಿ',
'ಬಸವೞಿ',
'ಬಸವಿತ್ತಿ',
'ಬಸವೇಱು',
'ಬಸಿಗಲ್ಲು',
'ಬಸಿಗಳ್',
'ಬಸಿಮೂಗ',
'ಬಸಿರಾನು',
'ಬಸಿರಿಳಿ',
'ಬಸಿರ್ಕುತ್ತ',
'ಬಸಿವಾಯ',
'ಬಸಿಱಾನ್',
'ಬಸಿಱಿಳಿ',
'ಬಸಿಱಿೞಿ',
'ಬಸುಗುತ್ತ',
'ಬಸುರಿಳಿ',
'ಬಸ್ಕಿಹೊಡೆ',
'ಬಸ್ತಪಾಲ',
'ಬಹದರ್',
'ಬಹದರ',
'ಬಹದರಿ',
'ಬಹದರು',
'ಬಹದೂರಿ',
'ಬಹದ್ದರ',
'ಬಹದ್ದುರಿ',
'ಬಹದ್ದೂರ್',
'ಬಹದ್ದೂರ',
'ಬಹದ್ದೂರಿ',
'ಬಹಲಿಕೆ',
'ಬಹಳಿಕೆ',
'ಬಹಳಿತ',
'ಬಹಳಿಸು',
'ಬಹಾದರ್',
'ಬಹಾದರಿ',
'ಬಹಾದುರ್',
'ಬಹಾದುರೀ',
'ಬಹಾದ್ದರ್',
'ಬಹಾದ್ದರ',
'ಬಹಾದ್ದರಿ',
'ಬಹಾದ್ದುರ',
'ಬಹಾದ್ದೂರ್',
'ಬಹಾದ್ದೂರ',
'ಬಹಾದ್ದೂರಿ',
'ಬಹಿರಂಗ',
'ಬಹಿರಾತ್ಮ',
'ಬಹಿರಿಗೆ',
'ಬಹಿರ್ಗತ',
'ಬಹಿರ್ದೃಷ್ಟಿ',
'ಬಹಿರ್ದೆಸೆ',
'ಬಹಿರ್ದೇಶ',
'ಬಹಿದ್ರ್ವಾರ',
'ಬಹಿರ್ಮುಖ',
'ಬಹಿರ್ಮುಖಿ',
'ಬಹಿರ್ಲಾಪಿ',
'ಬಹಿರ್ಲೇಪ',
'ಬಹಿರ್ವಣ',
'ಬಹಿರ್ವನ',
'ಬಹಿರ್ವಾಸ',
'ಬಹಿರ್ವೇದಿ',
'ಬಹಿರ್ಸಂಕೆ',
'ಬಹಿಷ್ಕಾರ',
'ಬಹಿಷ್ಕಾರಿ',
'ಬಹಿಷ್ಕೃತ',
'ಬಹಿಷ್ಕೃತೆ',
'ಬಹುಕುಳ',
'ಬಹುಗ್ರಾಹಿ',
'ಬಹುಚಾರಿ',
'ಬಹುಚಾಳಿ',
'ಬಹುತೇಕ',
'ಬಹುದಾರಿ',
'ಬಹುದುಃಖ',
'ಬಹುಧಾನ್ಯ',
'ಬಹುನೂತ್ನ',
'ಬಹುಪಾದಿ',
'ಬಹುಪುಷ್ಪ',
'ಬಹುಪುಷ್ಪಿ',
'ಬಹುಪ್ರಜ',
'ಬಹುಭಾರ್ಯ',
'ಬಹುಭಾಷಿ',
'ಬಹುಮತ',
'ಬಹುಮತಿ',
'ಬಹುಮತೆ',
'ಬಹುಮಾನ',
'ಬಹುಮುಖ',
'ಬಹುಮುಖಿ',
'ಬಹುಮೂತ್ರ',
'ಬಹುರೂಪ',
'ಬಹುರೂಪಿ',
'ಬಹುರೂಪೆ',
'ಬಹುಲತೆ',
'ಬಹುಲತ್ವ',
'ಬಹುಲಿಮ',
'ಬಹುವಚ',
'ಬಹುವಿಕೆ',
'ಬಹುವ್ರೀಹಿ',
'ಬಹುಶಿಲ್ಪಿ',
'ಬಹುಶ್ರುತ',
'ಬಹುಸುತ',
'ಬಹುಸುತೆ',
'ಬಹುಸೂತಿ',
'ಬಹುಳತೆ',
'ಬಹುಳತ್ವ',
'ಬಹೂದಕ',
'ಬಹೂಪಮೆ',
'ಬಳಋದ್ಧಿ',
'ಬಳಂಜಿಗ',
'ಬಳಗಾರ',
'ಬಳಗಾಱ',
'ಬಳದಡಿ',
'ಬಳಬಳ',
'ಬಳಯಿಸು',
'ಬಳಯುತ',
'ಬಳಲಿಕೆ',
'ಬಳಲಿಸು',
'ಬಳಲ್ಮುಡಿ',
'ಬಳವಂತ',
'ಬಳವಿಗೆ',
'ಬಳಸಿಗ',
'ಬಳಸುಗ',
'ಬಳಹರಿ',
'ಬಳಾಧ್ಯಕ್ಷ',
'ಬಳಾಹಕ',
'ಬಳಿಕೋಲು',
'ಬಳಿಗೊಳ್',
'ಬಳಿದಪ್ಪು',
'ಬಳಿದೊತ್ತು',
'ಬಳಿದೊಳ್ತು',
'ಬಳಿನಡೆ',
'ಬಳಿನೀರ್',
'ಬಳಿನೀರು',
'ಬಳಿಬರು',
'ಬಳಿಭುಕ್ಕು',
'ಬಳಿಮಾರಿ',
'ಬಳಿಮುಖ',
'ಬಳಿಯಟ್ಟು',
'ಬಳಿಯಿಸು',
'ಬಳಿವರ್',
'ಬಳಿವರಿ',
'ಬಳಿವರು',
'ಬಳಿವಿಡಿ',
'ಬಳಿವೊರ್ದು',
'ಬಳಿಸಲ್',
'ಬಳಿಸಾರ್',
'ಬಳಿಸಾರು',
'ಬಳಿಸೇರು',
'ಬಳಿಸೇವೆ',
'ಬಳುಕಿಸು',
'ಬಳುವಟ್ಟೆ',
'ಬಳುವಲ',
'ಬಳುವಳ',
'ಬಳುವಳಿ',
'ಬಳೆಕಣ್',
'ಬಳೆಗಣ್',
'ಬಳೆಗಳೆ',
'ಬಳೆಗಾತಿ',
'ಬಳೆಗಾರ',
'ಬಳೆಗಾರ್ತಿ',
'ಬಳೆಗಾಱ',
'ಬಳೆತೊಡು',
'ಬಳೆದೊಡು',
'ಬಳೆಮುರಿ',
'ಬಳೆಯಿಸು',
'ಬಳೋನ್ಮತ್ತ',
'ಬಳ್ಕುವೋಗು',
'ಬಳ್ವಳನೆ',
'ಬಳ್ವಳಿಕೆ',
'ಬಳ್ವಳಿಸು',
'ಬಳ್ಳವಳ್ಳಿ',
'ಬಳ್ಳಿಗತ್ತಿ',
'ಬಳ್ಳಿಬರೆ',
'ಬಳ್ಳಿಬೀಟೆ',
'ಬಳ್ಳಿಮಾಡ',
'ಬಳ್ಳಿಮಿಂಚು',
'ಬಳ್ಳಿವರಿ',
'ಬಳ್ಳಿವಾಯ್',
'ಬಳ್ಳಿವೆರ್ಚು',
'ಬಳ್ಳಿsಸುಂಡೆ',
'ಬಳ್ಳಿಸೊಂಡೆ',
'ಬಳ್ಳಿಹಾಲೆ',
'ಬಳ್ಳುಗರೆ',
'ಬಳ್ಳುಗೆಡೆ',
'ಬಳ್ಳುಪಿಳ್ಳೆ',
'ಬಱಗಾಲ',
'ಬಱಗೇಡು',
'ಬಱಡಾವು',
'ಬಱಹಾಳೆ',
'ಬಱಿಕಯ್',
'ಬಱಿಕೆಯ್',
'ಬಱಿಗಂಟು',
'ಬಱಿಗಲ್ಲು',
'ಬಱಿಗಾಲು',
'ಬಱಿಗುಟ್ಟು',
'ಬಱಿಗೂೞ್',
'ಬಱಿಗೆಯ್',
'ಬಱಿಗೆಱೆ',
'ಬಱಿದಲೆ',
'ಬಱಿದೂಱು',
'ಬಱಿದೊಱೆ',
'ಬಱಿನುಡಿ',
'ಬಱಿಮಾತು',
'ಬಱಿಮಾಯೆ',
'ಬಱಿಮೆಯ್',
'ಬಱಿವಾತು',
'ಬಱಿಹುಂಡ',
'ಬಱುಕಟೆ',
'ಬಱುಗಯ್',
'ಬಱುಗಾಲು',
'ಬಱುಗೂೞ್',
'ಬಱುಗೆಯ್',
'ಬಱುಗೆಱೆ',
'ಬಱುಗೊಂದೆ',
'ಬಱುದಲೆ',
'ಬಱುದೂಱು',
'ಬಱುದೊಱೆ',
'ಬಱುನುಡಿ',
'ಬಱುಮಂಡೆ',
'ಬಱುಮನ',
'ಬಱುಮಾಟ',
'ಬಱುಮಾತು',
'ಬಱುವನೆ',
'ಬಱುವಾತು',
'ಬಱುವಾೞ್ಕೆ',
'ಬೞಲಿಕೆ',
'ಬೞಲಿಸು',
'ಬೞಲ್ಮುಡಿ',
'ಬೞಿಕೊಳ್',
'ಬೞಿಗೊಳ್',
'ಬೞಿದಪ್ಪು',
'ಬೞಿದೊೞ್ತು',
'ಬೞಿನೀರ್',
'ಬೞಿಯಟ್ಟು',
'ಬೞಿವರ್',
'ಬೞಿವೞಿ',
'ಬೞಿವಿಡಿ',
'ಬೞಿವಿಡು',
'ಬೞಿವೋಕ',
'ಬೞಿವೋಗು',
'ಬೞಿಸಲ್',
'ಬಾಂಗುಳಿಗೆ',
'ಬಾಂಗೂದಲ',
'ಬಾಂಜೊಡರ್',
'ಬಾಂಡವಲು',
'ಬಾಂದಲೆಯ',
'ಬಾಂದೀತನ',
'ಬಾಂದುಕಲ್ಲು',
'ಬಾಂಬಟ್ಟೆಗ',
'ಬಾಂಬಳಿಕು',
'ಬಾಕತನ',
'ಬಾಕಿದಾರ',
'ಬಾಕಿಮಾರು',
'ಬಾಕುಳಿಕ',
'ಬಾಕುಳಿಗ',
'ಬಾಗಡೋರು',
'ಬಾಗಧೂಪ',
'ಬಾಗರಣ',
'ಬಾಗವತ್ತ',
'ಬಾಗವಳಿ',
'ಬಾಗವಾನ',
'ಬಾಗಾಯತ',
'ಬಾಗಾಯಿತಿ',
'ಬಾಗಾಯಿತು',
'ಬಾಗಿಲಾಯ್ತ',
'ಬಾಗಿಲ್ಕೋಲು',
'ಬಾಗಿಲ್ಗಾಪು',
'ಬಾಗಿಲ್ಗಾಯ್',
'ಬಾಗಿಲ್ಮಾಡ',
'ಬಾಗಿಲ್ವಾಡ',
'ಬಾಗಿಲ್ವಾಡು',
'ಬಾಗಿಸೂತ್ರ',
'ಬಾಗುವಡೆ',
'ಬಾಗೇಶ್ವರಿ',
'ಬಾಚಣಿಗೆ',
'ಬಾಚಣಿಸು',
'ಬಾಚುಗಱು',
'ಬಾಚುವಲೆ',
'ಬಾಜವಣೆ',
'ಬಾಜಿಕಟ್ಟು',
'ಬಾಜೀಬಂದು',
'ಬಾಜುಬಂದ',
'ಬಾಜುಬಂದಿ',
'ಬಾಜುಬಂದು',
'ಬಾಜೂಬಂದು',
'ಬಾಟಾಮಾರ್ಗ',
'ಬಾಡಬಕ್ಕ',
'ಬಾಡಬಾಕ',
'ಬಾಡಬಾಕು',
'ಬಾಡಬಾಗ್ನಿ',
'ಬಾಡಬೇಯ',
'ಬಾಡವಾಗ್ನಿ',
'ಬಾಡುಕಟ್ಟು',
'ಬಾಡುಕಾಯ್',
'ಬಾಡುಕಾಯಿ',
'ಬಾಡುಬಕ್ಕೆ',
'ಬಾಡುಬುಕ್ಕೆ',
'ಬಾಣಂತನ',
'ಬಾಣಗಾರ',
'ಬಾಣಗಾಱ',
'ಬಾಣಗೇಹ',
'ಬಾಣಪೀಠ',
'ಬಾಣಬೆಟ್ಟು',
'ಬಾಣರಾಸಿ',
'ಬಾಣಲಿಂಗ',
'ಬಾಣವೃತ್ತ',
'ಬಾಣಸಗೈ',
'ಬಾಣಸಿಗ',
'ಬಾಣಸಿಗೆ',
'ಬಾಣಸುಗೈ',
'ಬಾಣಹತಿ',
'ಬಾಣಾಯಿತ',
'ಬಾಣಾರಸಿ',
'ಬಾಣಾಶ್ರಯ',
'ಬಾಣಾಸನ',
'ಬಾತಖಾನಿ',
'ಬಾತಾಖಾನಿ',
'ಬಾತಾಳಿಸು',
'ಬಾತುಕೋಳಿ',
'ಬಾತುಗಾಲು',
'ಬಾತುಗೋಳಿ',
'ಬಾತೆಗೆಡು',
'ಬಾತೆಗೇಡಿ',
'ಬಾತ್ಮಿದಾರ',
'ಬಾತ್ಮೀದಾರ',
'ಬಾದಣಿಸು',
'ಬಾದವಣ',
'ಬಾದಶಹ',
'ಬಾದಶಾಹ',
'ಬಾದಷಹ',
'ಬಾದಷಾಹಿ',
'ಬಾದಳಿಸು',
'ಬಾದುಗುಟ್ಟು',
'ಬಾದುಗೆಯ್',
'ಬಾಧಾಕರ',
'ಬಾಧ್ಯತನ',
'ಬಾನಗಡಿ',
'ಬಾನಗಿತ್ತಿ',
'ಬಾನಗೇಡು',
'ಬಾನಲ್ಗೊಳ್',
'ಬಾನಾವರ',
'ಬಾನ್ನವಿರ',
'ಬಾನ್ಬರೆಪ',
'ಬಾಬುಗಾರ',
'ಬಾಬುದಾರ',
'ಬಾಯಱಿಕೆ',
'ಬಾಯಾಚಾರ',
'ಬಾಯಾಡಿಸು',
'ಬಾಯಾರಿಕೆ',
'ಬಾಯಾರಿಸು',
'ಬಾಯಾಱಿಕೆ',
'ಬಾಯಾಱಿಸು',
'ಬಾಯಿಕಟ್ಟು',
'ಬಾಯಿಕಲು',
'ಬಾಯಿಕಲ್ಲಿ',
'ಬಾಯಿಕಲ್ಲು',
'ಬಾಯಿಕಾಲು',
'ಬಾಯಿಕುಕ್ಕೆ',
'ಬಾಯಿಕೊಚ್ಚು',
'ಬಾಯಿಕೊಬ್ಬು',
'ಬಾಯಿಕೋಲು',
'ಬಾಯಿಗರೆ',
'ಬಾಯಿಗೂಡು',
'ಬಾಯಿಚಿಕ್ಕ',
'ಬಾಯಿಜ್ವರ',
'ಬಾಯಿಡಬ್ಬು',
'ಬಾಯಿತಪ್ಪು',
'ಬಾಯಿತೀಟೆ',
'ಬಾಯಿತೆಗೆ',
'ಬಾಯಿತೆರೆ',
'ಬಾಯಿದುತ್ತು',
'ಬಾಯಿದೆಱೆ',
'ಬಾಯಿಧಾರೆ',
'ಬಾಯಿನೀರು',
'ಬಾಯಿಪಂತ',
'ಬಾಯಿಪಾಠ',
'ಬಾಯಿಬಡಿ',
'ಬಾಯಿಬಿಡು',
'ಬಾಯಿಬೀಗ',
'ಬಾಯಿಬೆಣ್ಣೆ',
'ಬಾಯಿಮಾತು',
'ಬಾಯಿಮುಚ್ಚು',
'ಬಾಯಿಲೆಕ್ಕ',
'ಬಾಯಿವಿಡು',
'ಬಾಯಿವೆಣ್ಣೆ',
'ಬಾಯಿಸವಿ',
'ಬಾಯಿಹಗ್ಗ',
'ಬಾಯಿಹುಣ್ಣು',
'ಬಾಯೂರಿಸು',
'ಬಾಯೆಣಿಕೆ',
'ಬಾಯೊದರು',
'ಬಾಯ್ಕುಟುಕು',
'ಬಾಯ್ಕೇಳಿಸು',
'ಬಾಯ್ಗಾಳೆಗ',
'ಬಾಯ್ಗುಟುಕು',
'ಬಾಯ್ಚಳಕ',
'ಬಾಯ್ದಂಬುಲ',
'ಬಾಯ್ದೆಱಸು',
'ಬಾಯ್ಬಡಕ',
'ಬಾಯ್ಬಡಿಕ',
'ಬಾಯ್ಬಡಿಕಿ',
'ಬಾಯ್ಬಡಿಕೆ',
'ಬಾಯ್ಬಿಯ್ಯಗ',
'ಬಾಯ್ಮರುಕ',
'ಬಾಯ್ಮಱುಕ',
'ಬಾಯ್ಮಿಡುಕು',
'ಬಾಯ್ಮುಚ್ಚಿಸು',
'ಬಾರಕಿನ',
'ಬಾರಗಿರಿ',
'ಬಾರಗೀರ',
'ಬಾರಗೀರು',
'ಬಾರಗುತ್ತು',
'ಬಾರಣಸಿ',
'ಬಾರಣಾಸಿ',
'ಬಾರದಾನ',
'ಬಾರದಾನು',
'ಬಾರನಿಸಿ',
'ಬಾರನೂಕ',
'ಬಾರಮಾಹೆ',
'ಬಾರಾಗೀರ',
'ಬಾರಾಮಾರ್ಗ',
'ಬಾರಾಸುಂಕ',
'ಬಾರಿಕಾರ',
'ಬಾರಿಯಿಕ್ಕು',
'ಬಾರಿಯಿಡು',
'ಬಾರುಕೋಲು',
'ಬಾರುಗುತ್ತ',
'ಬಾರುಗುತ್ತು',
'ಬಾರುಗೋಲು',
'ಬಾರುದಾರಿ',
'ಬಾರ್ತೆಗಿಡು',
'ಬಾರ್ತೆಗೆಡು',
'ಬಾರ್ತೆಗೆಯ್',
'ಬಾರ್ಸಿಪಾಯಿ',
'ಬಾರ್ಹಸ್ಪತ್ಯ',
'ಬಾಲಂಗಚ್ಚೆ',
'ಬಾಲಂಗೋಚಿ',
'ಬಾಲಂಗೋಸಿ',
'ಬಾಲಕಾಲ',
'ಬಾಲಕೇಲಿ',
'ಬಾಲಕೇಳಿ',
'ಬಾಲಕ್ರೀಡೆ',
'ಬಾಲಗಚ್ಚೆ',
'ಬಾಲಗೀಸು',
'ಬಾಲಗ್ರಹ',
'ಬಾಲಗ್ರ್ರಾಸ',
'ಬಾಲಚಂದ್ರ',
'ಬಾಲಚಮೂ',
'ಬಾಲಚಾರ',
'ಬಾಲಚಿಕ್ಕೆ',
'ಬಾಲಚುಕ್ಕಿ',
'ಬಾಲಚುಕ್ಕೆ',
'ಬಾಲಜ್ವರ',
'ಬಾಲತನ',
'ಬಾಲತಪ',
'ಬಾಲಪತ್ರ',
'ಬಾಲಪಾಶ್ಯೆ',
'ಬಾಲಪುಳು',
'ಬಾಲಬಂದು',
'ಬಾಲಬೋಧೆ',
'ಬಾಲಭಾವ',
'ಬಾಲಭಾಷಿ',
'ಬಾಲಭಾಷೆ',
'ಬಾಲರಕ್ಷೆ',
'ಬಾಲರಸ',
'ಬಾಲಲೀಲೆ',
'ಬಾಲವಂದ',
'ಬಾಲವಣ',
'ಬಾಲವತಿ',
'ಬಾಲಶಯ್ಯೆ',
'ಬಾಲಶಶಿ',
'ಬಾಲಶಿಕ್ಷೆ',
'ಬಾಲಸರ',
'ಬಾಲಹುಳು',
'ಬಾಲಾಖಾನೆ',
'ಬಾಲಾತಪ',
'ಬಾಲಾಭ್ಯಾಸ',
'ಬಾಲಾರಿಷ್ಟ',
'ಬಾಲಿಶತೆ',
'ಬಾಲಿಶತ್ವ',
'ಬಾಲೆಮರ',
'ಬಾಲೆವೆಣ್ಣು',
'ಬಾಲೋತ್ತರ',
'ಬಾಲ್ಯಮಿತ್ರ',
'ಬಾಲ್ಯಾವಸ್ಥೆ',
'ಬಾವಣಿಕೆ',
'ಬಾವಣಿಸು',
'ಬಾವನಂಟ',
'ಬಾವಿಗಪ್ಪು',
'ಬಾವುಂಜಿಗೆ',
'ಬಾವುಜಿಗೆ',
'ಬಾವೆಮಣ್ಣು',
'ಬಾಷ್ಪಜಲ',
'ಬಾಷ್ಪವಾರಿ',
'ಬಾಸಣಿಗೆ',
'ಬಾಸಣಿಸು',
'ಬಾಸಿಗಹೂ',
'ಬಾಸುಳೇಳು',
'ಬಾಸುೞೇೞ್',
'ಬಾಸೆಗೆಡು',
'ಬಾಸೆಗೊಡು',
'ಬಾಸೆದೋಱು',
'ಬಾಸೆಯಿಲಿ',
'ಬಾಸೆವಕ್ಕಿ',
'ಬಾಸೆವೆಣ್',
'ಬಾಸೆವೆಣ್ಣು',
'ಬಾಸೆಹೀನ',
'ಬಾಹತ್ತರ',
'ಬಾಹಾಬಲ',
'ಬಾಹಿರಂಗ',
'ಬಾಹಿರಾತ್ಮ',
'ಬಾಹುಜಿಗೆ',
'ಬಾಹುತಳ',
'ಬಾಹುದಣೆ',
'ಬಾಹಪುರಿ',
'ಬಾಹುಪೂರ',
'ಬಾಹುಬಲ',
'ಬಾಹುಬಲಿ',
'ಬಾಹುಬಳೆ',
'ಬಾಹುಮುರಿ',
'ಬಾಹುಮೂಲ',
'ಬಾಹುಯುದ್ಧ',
'ಬಾಹುರಕೆ',
'ಬಾಹುರಕ್ಕೆ',
'ಬಾಹುರಕ್ಷೆ',
'ಬಾಹುರಿಕೆ',
'ಬಾಹುಲೇಯ',
'ಬಾಹ್ಯತಪ',
'ಬಾಹ್ಯಮತಿ',
'ಬಾಹ್ಯರತ',
'ಬಾಹ್ಯರತಿ',
'ಬಾಹ್ಯಶೌಚ',
'ಬಾಳಕೇಳಿ',
'ಬಾಳಕ್ರೀಡೆ',
'ಬಾಳಗೞ್ಚು',
'ಬಾಳಚಂದ್ರ',
'ಬಾಳಪರ್ಣ',
'ಬಾಳಬಂಧು',
'ಬಾಳಮತಿ',
'ಬಾಳರಾಕ್ಷ',
'ಬಾಳವಂದ',
'ಬಾಳವಚ್ಚ',
'ಬಾಳವತಿ',
'ಬಾಳವೇರ್',
'ಬಾಳುಗೇಡಿ',
'ಬಾಳುಗೇಡು',
'ಬಾಳುತ್ತಾರ',
'ಬಾಳುದಲೆ',
'ಬಾಳುಮನೆ',
'ಬಾಳೆಕಂದು',
'ಬಾಳೆಕಂಬ',
'ಬಾಳೆಕಾಯಿ',
'ಬಾಳೆಕೊನೆ',
'ಬಾಳೆಗಂಬ',
'ಬಾಳೆಗಣ್',
'ಬಾಳೆಗೊನೆ',
'ಬಾಳೆದಿಂಡು',
'ಬಾಳೆಮೀನ್',
'ಬಾಳೆಮೀನು',
'ಬಾಳ್ಗನಸು',
'ಬಾಳ್ಗುಡುತೆ',
'ಬಾಳ್ಗೆಳೆಯ',
'ಬಾಳ್ತೆಗೆಯ್',
'ಬಾಳ್ಮೊದಲ್',
'ಬಾಳ್ವಂದರ',
'ಬಾೞ್ಕೆಗೊಡು',
'ಬಾೞ್ತೆಗೆಯ್',
'ಬಾೞ್ಮೊದಲ್',
'ಬಾೞ್ವಂದರ್',
'ಬಿಂಕಂಗುಂದು',
'ಬಿಂಕಂಗೆಡು',
'ಬಿಂಕಂಬಡೆ',
'ಬಿಂಕಂಬೆಱು',
'ಬಿಂಕಕಾತಿ',
'ಬಿಂಕಕಾರ್ತಿ',
'ಬಿಂಕಕಾಱ',
'ಬಿಂಕಗಾತಿ',
'ಬಿಂಕಗಾರ',
'ಬಿಂಕಗಾರ್ತಿ',
'ಬಿಂಕತನ',
'ಬಿಂಕಪಡು',
'ಬಿಂಗುಸುಬು',
'ಬಿಂಡಿವಾಲ',
'ಬಿಂಡಿವಾಳ',
'ಬಿಂದಂಗೊಳ್',
'ಬಿಂದಣಿಕೆ',
'ಬಿಂದುಮಂತ',
'ಬಿಂದುವಿಡು',
'ಬಿಂಬಗ್ರ್ರಾಹಿ',
'ಬಿಂಬಫಲ',
'ಬಿಂಬಫಳ',
'ಬಿಂಬಾಗಮ',
'ಬಿಂಬಾಧರ',
'ಬಿಂಬಾಧರೆ',
'ಬಿಕನಾಶಿ',
'ಬಿಕನಾಸಿ',
'ಬಿಕ್ಕಡಿಸು',
'ಬಿಕ್ಕಳಿಕೆ',
'ಬಿಕ್ಕಳಿಸು',
'ಬಿಕ್ಕುಂದನಿ',
'ಬಿಕ್ಕುಳಿಕು',
'ಬಿಕ್ಕುಳಿಕೆ',
'ಬಿಕ್ಕುಳಿಸು',
'ಬಿಕ್ರಿದಾರ',
'ಬಿಕ್ಲಂದಾರ',
'ಬಿಗಡಾವು',
'ಬಿಗಡಿಸು',
'ಬಿಗಿಕಟ್ಟು',
'ಬಿಗಿಕಲ್ಲು',
'ಬಿಗಿಗಟ್ಟು',
'ಬಿಗಿಮುಷ್ಟಿ',
'ಬಿಗಿಮೊಗ',
'ಬಿಗಿಯಿಸು',
'ಬಿಗಿಯುಡೆ',
'ಬಿಗಿವಡು',
'ಬಿಗಿವಡೆ',
'ಬಿಗಿವಾತು',
'ಬಿಗಿವಿಡಿ',
'ಬಿಗಿವೊಂದು',
'ಬಿಗಿಹಿಡಿ',
'ಬಿಗುನಡೆ',
'ಬಿಗುಪೇರು',
'ಬಿಗುಪೇಱು',
'ಬಿಗುಮಾನ',
'ಬಿಗುಮೊಗ',
'ಬಿಗುರಿಸು',
'ಬಿಗುರೇೞ್',
'ಬಿಗುರ್ಪಿೞಿ',
'ಬಿಗುಹಿಕ್ಕು',
'ಬಿಗುಹೇರು',
'ಬಿಚಾಯಿತ',
'ಬಿಚ್ಚಣಿಗೆ',
'ಬಿಚ್ಚತಿಕೆ',
'ಬಿಚ್ಚತಿಕ್ಕೆ',
'ಬಿಚ್ಚತಿಗ',
'ಬಿಚ್ಚತಿಗೆ',
'ಬಿಚ್ಚಳಿಕೆ',
'ಬಿಚ್ಚಳಿಸು',
'ಬಿಚ್ಚುಕಣ್ಣು',
'ಬಿಚ್ಚುಕತ್ತಿ',
'ಬಿಚ್ಚುಗಣ್',
'ಬಿಚ್ಚುಗಣ್ಣು',
'ಬಿಚ್ಚುಗತ್ತಿ',
'ಬಿಚ್ಚುಜಡೆ',
'ಬಿಚ್ಚುನುಡಿ',
'ಬಿಚ್ಚುಪೇಣಿ',
'ಬಿಚ್ಚುಫೇಣಿ',
'ಬಿಚ್ಚುಮಾತು',
'ಬಿಚ್ಚುರೊಕ್ಕ',
'ಬಿಚ್ಚೂರಿಗೆ',
'ಬಿಚ್ಚೆಕಾರ',
'ಬಿಚ್ಚೋಗರ',
'ಬಿಛಾಯತ',
'ಬಿಛಾಯಿತ',
'ಬಿಜಯಂಗೈ',
'ಬಿಜಯಿಸು',
'ಬಿಜವರ',
'ಬಿಜಾಗರಿ',
'ಬಿಜ್ಜಣಿಗ',
'ಬಿಜ್ಜಣಿಗೆ',
'ಬಿಜ್ಜಣಿಸು',
'ಬಿಜ್ಜವಳ',
'ಬಿಜ್ಜಳಿಗೆ',
'ಬಿಜ್ಜಾದರ',
'ಬಿಜ್ಜಾದರಿ',
'ಬಿಜ್ಜೆಗಲ್',
'ಬಿಜ್ಜೆಗಲಿ',
'ಬಿಜ್ಜೆವಳ',
'ಬಿಜ್ಜೆವಳ್ತಿ',
'ಬಿಜ್ಜೆವೆಣ್ಣು',
'ಬಿಜ್ಜೋದರ',
'ಬಿಜ್ಜೋದರಿ',
'ಬಿಟ್ಟಿಕೂಳು',
'ಬಿಟ್ಟಿಗೂಳು',
'ಬಿಟ್ಟಿಗೆಯ್',
'ಬಿಟ್ಟಿಗೆಯ್ಮೆ',
'ಬಿಟ್ಟಿಬೆಸ',
'ಬಿಟ್ಟಿಯಾಳ್',
'ಬಿಟ್ಟಿಯಾಳು',
'ಬಿಟ್ಟಿವಿಡಿ',
'ಬಿಟ್ಟಿವೆಸ',
'ಬಿಟ್ಟಿಹಿಡಿ',
'ಬಿಟ್ಟುಬಿಕ್ಕೆ',
'ಬಿಟ್ಟೆಗೆಯ್',
'ಬಿಡಾಡಿಗ',
'ಬಿಡಾರ್ವರ',
'ಬಿಡಿಕಾಸು',
'ಬಿಡಿಗಾಸು',
'ಬಿಡಿತನ',
'ಬಿಡಿದರ',
'ಬಿಡಿಬಿಲ್',
'ಬಿಡಿಹೂವು',
'ಬಿಡಿಹೋರೆ',
'ಬಿಡುಕುಳ',
'ಬಿಡುಗಂಡಿ',
'ಬಿಡುಗಟ್ಟು',
'ಬಿಡುಗಡೆ',
'ಬಿಡುಗಣ್',
'ಬಿಡುಗಣ್ಣ',
'ಬಿಡುಗಣ್ಣು',
'ಬಿಡುಗಯ್',
'ಬಿಡುಗಿವಿ',
'ಬಿಡುಗೇಶ',
'ಬಿಡುಗೊಡೆ',
'ಬಿಡುಗೊಳ್',
'ಬಿಡುಜಡೆ',
'ಬಿಡುತರ್',
'ಬಿಡುದಲೆ',
'ಬಿಡುದಾಣ',
'ಬಿಡುದಾರ',
'ಬಿಡುನಾಯ್',
'ಬಿಡುನುಡಿ',
'ಬಿಡುಪಡು',
'ಬಿಡುಬೀಸು',
'ಬಿಡುಮಂಡೆ',
'ಬಿಡುಮುಡಿ',
'ಬಿಡುವಡು',
'ಬಿಡುವಡೆ',
'ಬಿಡುವನೆ',
'ಬಿಡುವರಿ',
'ಬಿಡೌಜಸ',
'ಬಿತ್ತರಿಕೆ',
'ಬಿತ್ತರಿಗೆ',
'ಬಿತ್ತರಿಸು',
'ಬಿತ್ತುಬೀಜ',
'ಬಿತ್ತುಬೀೞ್',
'ಬಿದಾನಂದ',
'ಬಿದಾನದ',
'ಬಿದಿರಕ್ಕಿ',
'ಬಿದಿರಿಸು',
'ಬಿದಿರುಪ್ಪು',
'ಬಿದಿರ್ಮುತ್ತು',
'ಬಿದಿರ್ವಳ',
'ಬಿದಿವಸ',
'ಬಿದುಗಲ್',
'ಬಿದುಗಲ್ಲು',
'ಬಿದುರೆಲೆ',
'ಬಿದ್ದಿಕ್ಕಿಸು',
'ಬಿನದಿಸು',
'ಬಿನುಗಾಟ',
'ಬಿನ್ನಣಿಗ',
'ಬಿನ್ನಣಿಗೆ',
'ಬಿನ್ನಣಿಸು',
'ಬಿನ್ನಪಂಗೈ',
'ಬಿನ್ನಯಿಸು',
'ಬಿನ್ನವಿಸು',
'ಬಿನ್ನಹಗೈ',
'ಬಿನ್ನಾಣಿಗ',
'ಬಿನ್ನಾಣಿಸು',
'ಬಿಪ್ಪಂಡಿಸು',
'ಬಿಬ್ಬಚ್ಚಿಸು',
'ಬಿಭೀಷಿಕೆ',
'ಬಿಮ್ಮನಸೆ',
'ಬಿಮ್ಮನಿಸೆ',
'ಬಿಮ್ಮುಗಱೆ',
'ಬಿಮ್ಮುಗಿಡು',
'ಬಿಮ್ಮುಗೆಡು',
'ಬಿಮ್ಮೆನ್ನಿಸು',
'ಬಿಯಂಗೆಯ್',
'ಬಿಯಕಾರ್ತಿ',
'ಬಿಯಕಾಱ',
'ಬಿಯಸಂಗ',
'ಬಿಯ್ಯಂಗೆಯ್',
'ಬಿರಯಿಸು',
'ಬಿರಾಡಿಸು',
'ಬಿರಾದರ',
'ಬಿರಾದರಿ',
'ಬಿರಿಕುಂಟೆ',
'ಬಿರಿಯಾನಿ',
'ಬಿರಿಯಿಕ್ಕು',
'ಬಿರಿಯಿಸು',
'ಬಿರಿವಡು',
'ಬಿರುಕಿಸು',
'ಬಿರುಗಣ್ಣ',
'ಬಿರುಗಣ್ಣು',
'ಬಿರುಗಾಳಿ',
'ಬಿರುಗುಟ್ಟು',
'ಬಿರುದನಿ',
'ಬಿರುದಿಕ್ಕು',
'ಬಿರುನುಡಿ',
'ಬಿರುಮಳೆ',
'ಬಿರುಮಾತು',
'ಬಿರುವಳೆ',
'ಬಿರ್ದುಗೊಳ್',
'ಬಿರ್ದುವರ್',
'ಬಿಲ್‍ಕುಲ್',
'ಬಿಲ್‍ಖುಲ್',
'ಬಿಲಂದರ',
'ಬಿಲಂದರಿ',
'ಬಿಲಚರ',
'ಬಿಲವರ',
'ಬಿಲವಾರ',
'ಬಿಲಹರಿ',
'ಬಿಲಾವಲ್',
'ಬಿಲಿತರ್',
'ಬಿಲುಕುಡಿ',
'ಬಿಲುಕೊಡೆ',
'ಬಿಲುಗಡೆ',
'ಬಿಲುಗಾರ',
'ಬಿಲುಗಾಱ',
'ಬಿಲುಜಾಣ',
'ಬಿಲುದನಿ',
'ಬಿಲುದಿರು',
'ಬಿಲುಮುರಿ',
'ಬಿಲುರವ',
'ಬಿಲುವರ',
'ಬಿಲುವರೆ',
'ಬಿಲುವಿದ್ದೆ',
'ಬಿಲುವಿದ್ಯ',
'ಬಿಲುವಿದ್ಯೆ',
'ಬಿಲೇಶಯ',
'ಬಿಲ್ಕುನಿಕ',
'ಬಿಲ್ಗಾರಿಕೆ',
'ಬಿಲ್ಗಾಳೆಗ',
'ಬಿಲ್ಗಾಱಿಕೆ',
'ಬಿಲ್ಜಾಣಿಕೆ',
'ಬಿಲ್ದಿರುವು',
'ಬಿಲ್ಬಿನ್ನಣ',
'ಬಿಲ್ಲಪಳ್ಳಿ',
'ಬಿಲ್ಲವತ್ತೆ',
'ಬಿಲ್ಲಹಾವು',
'ಬಿಲ್ಲಾಳ್ತನ',
'ಬಿಲ್ಲುಗಾರ',
'ಬಿಲ್ಲುಗಾಱ',
'ಬಿಲ್ಲುರಿಗ',
'ಬಿಲ್ಲುವಿದ್ಯೆ',
'ಬಿಲ್ಲೆಸುಗೆ',
'ಬಿಲ್ಲೆಹುಳು',
'ಬಿಲ್ಲೆಱೆಯ',
'ಬಿಲ್ಲೊವಜ',
'ಬಿಲ್ವಪತ್ರೆ',
'ಬಿಲ್ವೆಸನ್',
'ಬಿಸಕಂಠ',
'ಬಿಸಣಿಗೆ',
'ಬಿಸದಳ',
'ಬಿಸನಾಳ',
'ಬಿಸನಿಧಿ',
'ಬಿಸರುಹ',
'ಬಿಸವಂದ',
'ಬಿಸಸನ',
'ಬಿಸಾಡಿಸು',
'ಬಿಸಿಗಣ್ಣ',
'ಬಿಸಿಗಣ್ಣು',
'ಬಿಸಿಗಮ್ಮ',
'ಬಿಸಿರಕ್ತ',
'ಬಿಸಿಲ್ದೊರೆ',
'ಬಿಸಿಲ್ಮೞೆ',
'ಬಿಸಿಲ್ವಕ್ಕಿ',
'ಬಿಸುಗಣ್',
'ಬಿಸುಗಣ್ಣ',
'ಬಿಸುಗಯ್ಯ',
'ಬಿಸುಗಲ್',
'ಬಿಸುಗಾಳಿ',
'ಬಿಸುಗುತ್ತ',
'ಬಿಸುಗುದಿ',
'ಬಿಸುಗೈಯ',
'ಬಿಸುಡಿಸು',
'ಬಿಸುಪೇರು',
'ಬಿಸುಪೇಱು',
'ಬಿಸುವಾತು',
'ಬಿಸುಸುಯ್',
'ಬಿಸುಸುಯ್ಯು',
'ಬಿಸುಸುಯ್ಲು',
'ಬಿಸುಳಿಗೆ',
'ಬಿಸೂರಿಗೆ',
'ಬಿಳಲ್ವರಿ',
'ಬಿಳವೃತ್ತಿ',
'ಬಿಳಿಅತ್ತಿ',
'ಬಿಳಿಉಪ್ಪಿ',
'ಬಿಳಿಎಕ್ಕ',
'ಬಿಳಿಕಡ್ಡಿ',
'ಬಿಳಿಕಲ್ಲು',
'ಬಿಳಿಕಸ',
'ಬಿಳಿಕೊಗ್ಗೆ',
'ಬಿಳಿಕೊಮ್ಮೆ',
'ಬಿಳಿಗಾರ',
'ಬಿಳಿಗುಳ್ಳ',
'ಬಿಳಿಗೆಡ್ಡೆ',
'ಬಿಳಿಗೋಳಿ',
'ಬಿಳಿಚಳ್ಳೆ',
'ಬಿಳಿಜಾಲಿ',
'ಬಿಳಿಜೋಳ',
'ಬಿಳಿತಾಳೆ',
'ಬಿಳಿತುಂಬೆ',
'ಬಿಳಿದಾಳೆ',
'ಬಿಳಿನಂದಿ',
'ಬಿಳಿನಾರು',
'ಬಿಳಿನೀರು',
'ಬಿಳಿನೆಕ್ಕಿ',
'ಬಿಳಿಪಲ್ಯ',
'ಬಿಳಿಪಾದ್ರಿ',
'ಬಿಳಿಬಂಟ',
'ಬಿಳಿಬಾಳ',
'ಬಿಳಿಬೀಟೆ',
'ಬಿಳಿಬೆಂಡು',
'ಬಿಳಿಬೋಗಿ',
'ಬಿಳಿಬೋವು',
'ಬಿಳಿಭೋಗಿ',
'ಬಿಳಿಭ್ರಂಗು',
'ಬಿಳಿಮತ್ತಿ',
'ಬಿಳಿಮೀನು',
'ಬಿಳಿಯಾನೆ',
'ಬಿಳಿಲಕ್ಕಿ',
'ಬಿಳಿವಾರ',
'ಬಿಳಿಶೂಲಿ',
'ಬಿಳಿಸೀರೆ',
'ಬಿಳಿಸೂಲಿ',
'ಬಿಳಿಸೊಂಡೆ',
'ಬಿಳಿಹಂಬು',
'ಬಿಳಿಹತ್ತಿ',
'ಬಿಳಿಹುಲಿ',
'ಬಿಳೀಹೂರಿ',
'ಬಿಳಿಹೂಲಿ',
'ಬಿಳುಗಾರ',
'ಬಿಳುಪೇರು',
'ಬಿಳುಪೇಱು',
'ಬಿಱುಗಾಣ್ಕೆ',
'ಬಿಱುಗಾಳಿ',
'ಬಿಱುಗುಟ್ಟು',
'ಬಿಱುಗೊಳ್ಳು',
'ಬಿಱುನುಡಿ',
'ಬಿಱುಬಿದ್ದ',
'ಬಿಱುಬಿಮ್ಮು',
'ಬಿಱುಬೇಗೆ',
'ಬಿಱುವಳೆ',
'ಬಿಱುವಾತು',
'ಬಿಱುಸರಿ',
'ಬಿಱಱನೆ',
'ಬಿೞವೃತ್ತಿ',
'ಬೀಗತನ',
'ಬೀಗಮುದ್ರೆ',
'ಬೀಜಕೋಶ',
'ಬೀಜಕೋಶಿ',
'ಬೀಜಗಾಣು',
'ಬೀಜಪೂರ',
'ಬೀಜಮಂತ್ರ',
'ಬೀಜವರಿ',
'ಬೀಜವೊನ್ನು',
'ಬೀಜಹೊನ್ನು',
'ಬೀಜಾಕೃತ',
'ಬೀಜಾಕ್ಷರ',
'ಬೀಜಾಪೂರ',
'ಬೀಜಾವಾಪ',
'ಬೀಟುರೂಟು',
'ಬೀಟೆಬಿರಿ',
'ಬೀಟೆಸೆಳೆ',
'ಬೀಡುಂದಾಣ',
'ಬೀಡುಕಾಱ',
'ಬೀಡುಕೊಳ್',
'ಬೀಡುಗೆಯ್',
'ಬೀಡುಗೊಳ್',
'ಬೀಡುಗೊಳು',
'ಬೀಡುದಾಣ',
'ಬೀಡುಬಿಡು',
'ಬೀಡುವಿಡು',
'ಬೀಡೆವಿಡು',
'ಬೀಡೊಡೆಯ',
'ಬೀಣೆಗಾಱ',
'ಬೀಣೆವಾಡ',
'ಬೀದಿಗೊಳ್',
'ಬೀದಿಪಾಲು',
'ಬೀದಿವರಿ',
'ಬೀದಿವಾಡ',
'ಬೀದಿವಾಡು',
'ಬೀದಿಸುದ್ದಿ',
'ಬೀದಿಹೋಕ',
'ಬೀಯಂಗೆಯ್',
'ಬೀಯಕಾಱ',
'ಬೀಯಗಿತ್ತಿ',
'ಬೀರಕಲ್',
'ಬೀರಗಚ್ಚೆ',
'ಬೀರಗಲು',
'ಬೀರಗಲ್ಲು',
'ಬೀರಗಾಸೆ',
'ಬೀರಗೂಳ್',
'ಬೀರಡೌಡೆ',
'ಬೀರತಣಿ',
'ಬೀರಮಂಡಿ',
'ಬೀರವಟ್ಟ',
'ಬೀರವಣ',
'ಬೀರವರ್ತಿ',
'ಬೀರಸಗ್ಗ',
'ಬೀವುಗಾರ್ತಿ',
'ಬೀವುಗಿತಿ',
'ಬೀಸಣಿಕೆ',
'ಬೀಸಣಿಗೆ',
'ಬೀಸರಿಗ',
'ಬೀಸರಿಸು',
'ಬೀಸುಂಬಲೆ',
'ಬೀಸುಗಣ್ಣಿ',
'ಬೀಸುಗಲ್',
'ಬೀಸುಗಾಲು',
'ಬೀಸುಗೋಲು',
'ಬೀಸುಬಲೆ',
'ಬೀಸುರಿಗೆ',
'ಬೀಸುವಲೆ',
'ಬೀಸೂರಿಗೆ',
'ಬೀಳವೃತ್ತಿ',
'ಬೀಳುಕಟ್ಟೆ',
'ಬೀಳುಕೊಡು',
'ಬೀಳುಕೊಳ್',
'ಬೀಳುಗಳೆ',
'ಬೀಳುಗುಂಡಿ',
'ಬೀಳುಗುಂಡು',
'ಬೀಳುಗೊಡು',
'ಬೀಳುಗೊಳ್',
'ಬೀಳುದಂಡ',
'ಬೀಳುನುಡಿ',
'ಬೀಳುಬಳ್ಳಿ',
'ಬೀಳುಬಿಡು',
'ಬೀಳುಭೂಮಿ',
'ಬೀಳುಮಾತು',
'ಬೀಳುವಲೆ',
'ಬೀಳುವಳ್ಳಿ',
'ಬೀಳುವೆಣ',
'ಬೀಳುವ್ರಿತ್ತಿ',
'ಬೀಳ್ಕೊಡಿಗೆ',
'ಬೀಳ್ಕೊಡಿಸು',
'ಬೀಳ್ಕೊಡುಗೆ',
'ಬೀಳ್ಕೊಳಿಸು',
'ಬೀಳ್ಗೆಡಹು',
'ಬೀಱುಗುಂಡು',
'ಬೀಱುಗೊಳ್',
'ಬೀಱುವಗ್ಗ',
'ಬೀೞಕ್ಕರ',
'ಬೀೞಲ್ಬಿಡು',
'ಬೀೞಲ್ವರಿ',
'ಬೀೞವೃತ್ತಿ',
'ಬೀೞುಂದಾಣ',
'ಬೀೞ್ಕೊಳಿಸು',
'ಬೀಳ್ಪಡಿಸು',
'ಬುಕ್ಕಿಹಿಟ್ಟು',
'ಬುಗಿಲನೆ',
'ಬುಗುಡಿಗೆ',
'ಬುಜಪತ್ರೆ',
'ಬುಜರೂಕ',
'ಬುಜ್ಜಣಿಗೆ',
'ಬುಜ್ಜಯಿಸು',
'ಬುಜ್ಜವಿಸು',
'ಬುಟೇದಾರಿ',
'ಬುಟ್ಟೆದಾರ',
'ಬುಟ್ಟೆದಾರಿ',
'ಬುಟ್ಟೇದಾರಿ',
'ಬುಡಕಟ್ಟು',
'ಬುಡಕ್ಕನೆ',
'ಬುಡಿಗನ್ನೆ',
'ಬುಡಿಗಾನೆ',
'ಬುಡ್ಡೆಜಾಲಿ',
'ಬುಡ್ಡೆಸೊಪ್ಪು',
'ಬುತ್ತಿಗೊಡು',
'ಬುತ್ತಿಯಿಡು',
'ಬುತ್ತಿರೊಟ್ಟಿ',
'ಬುತ್ತಿಹಿಡಿ',
'ಬುದಿಂಗನೆ',
'ಬುದುಬುದ',
'ಬುದ್ದಣಿಗೆ',
'ಬುದ್ದಲಿಕೆ',
'ಬುದ್ದಿವಂತ',
'ಬುದ್ಧಿಋದ್ಧಿ',
'ಬುದ್ಧಿಕಲಿ',
'ಬುದ್ಧಿಗಮ್ಯ',
'ಬುದ್ಧಿಗಲಿ',
'ಬುದ್ಧಿಗುಡು',
'ಬುದ್ಧಿಗೆಡು',
'ಬುದ್ಧಿಗೇಡಿ',
'ಬುದ್ಧಿಜೀವಿ',
'ಬುದ್ಧಿನಾಶ',
'ಬುದ್ಧಿಪೇಳ್',
'ಬುದ್ಧಿಬಲ',
'ಬುದ್ಧಿಭ್ರಂಶ',
'ಬುದ್ಧಿಭ್ರಮೆ',
'ಬುದ್ಧಿಭ್ರಾಂತಿ',
'ಬುದ್ಧಿಮತ್ತೆ',
'ಬುದ್ಧಿಮಾಂದ್ಯ',
'ಬುದ್ಧಿಮಾತು',
'ಬುದ್ಧಿವಂತ',
'ಬುದ್ಧಿವಂತೆ',
'ಬುದ್ಧಿವತಿ',
'ಬುದ್ಧಿವಾದ',
'ಬುದ್ಧಿವೇಳ್',
'ಬುದ್ಧಿವೇೞ್',
'ಬುದ್ಧಿಶಕ್ತಿ',
'ಬುದ್ಧಿಶಾಲಿ',
'ಬುದ್ಧಿಶೂನ್ಯ',
'ಬುದ್ಧಿಸ್ಥಾನ',
'ಬುದ್ಧಿಹೀನ',
'ಬುದ್ಧಿಹೀನೆ',
'ಬುದ್ಧಿಹೇಳು',
'ಬುದ್ಬುದಾಕ್ಷ',
'ಬುದ್ಬುದಿಸು',
'ಬುಧನಾಲು',
'ಬುಧವಾರ',
'ಬುಭುಕ್ಷಿತ',
'ಬುರಬುರಿ',
'ಬುರಬೂರಿ',
'ಬುರುಗಲು',
'ಬುರುಸಾಲು',
'ಬುರ್ರಕತೆ',
'ಬುರ್ರಕಥೆ',
'ಬುಲ್ಲಯಿಸು',
'ಬುಲ್ಲವಣೆ',
'ಬುಲ್ಲವಿಸು',
'ಬುಲ್ಲಿಕಾಯಿ',
'ಬುಸುಗರೆ',
'ಬುಸುಗುಟ್ಟು',
'ಬುಸುಗುಡು',
'ಬೂಜುಗಟ್ಟು',
'ಬೂಟಗಾರ',
'ಬೂಟಾಟಿಕೆ',
'ಬೂಟಾದಾರಿ',
'ಬೂಟೆದಾರಿ',
'ಬೂತಾಟಿಕೆ',
'ಬೂತುಗೆಡೆ',
'ಬೂತುಗೆಯ್',
'ಬೂತುಗೊಳ್',
'ಬೂತುತನ',
'ಬೂದಿಕಟ್ಟೆ',
'ಬೂದಿಗನ್ನೆ',
'ಬೂದಿತಾಳೆ',
'ಬೂದಿಬಾಳೆ',
'ಬೂದಿಬುಕ್ಕ',
'ಬೂದಿವೂಸು',
'ಬೂದಿಮೆಯ್ಯ',
'ಬೂದುಕಾರೆ',
'ಬೂದುಬಾಳೆ',
'ಬೂರವಡ',
'ಬೃಂದಗಾನ',
'ಬೃಂದಾರಕ',
'ಬೃಂದಾವನ',
'ಬೃಂದಾವನಿ',
'ಬೃಹಂದಳೆ',
'ಬೃಹತಿಕೆ',
'ಬೃಹದ್ಭಾನು',
'ಬೃಹನ್ನಡೆ',
'ಬೃಹನ್ನಳೆ',
'ಬೃಹವಾರ',
'ಬೃಹಸ್ಪತಿ',
'ಬೆಂಕಿಕಡ್ಡಿ',
'ಬೆಂಕಿಗಿಡ',
'ಬೆಂಕೆವೆಟ್ಟು',
'ಬೆಂಕೊಳಿಸು',
'ಬೆಂಗದಿರ್',
'ಬೆಂಗದಿರ',
'ಬೆಂಗದಿರು',
'ಬೆಂಗಳೂರು',
'ಬೆಂಗಾಣಿಸು',
'ಬೆಂಗಾರಿಸು',
'ಬೆಂಗಾವಲ್',
'ಬೆಂಗಾವಲು',
'ಬೆಂಗುಡಗಿ',
'ಬೆಂಗುಳೂರ್',
'ಬೆಂಗೆಡಿಸು',
'ಬೆಂಡುಕಸ',
'ಬೆಂಡುಗರೆ',
'ಬೆಂಡುಗಳೆ',
'ಬೆಂಡುಗಿಡ',
'ಬೆಂಡುನೆಗೆ',
'ಬೆಂಡುವೋಗು',
'ಬೆಂಡುಳಿಗೆ',
'ಬೆಂಡೇೞಿಸು',
'ಬೆಂದಗುಳ್',
'ಬೆಂಬಲಿಗ',
'ಬೆಂಬಲಿಸು',
'ಬೆಂಬಳಿಸು',
'ಬೆಂಬಾಲಿಸು',
'ಬೆಂಬಿತ್ತಿಲ್',
'ಬೆಂಬಿಸಿಲ್',
'ಬೆಕ್ಕರಿಸು',
'ಬೆಕ್ಕುಗಣ್ಣು',
'ಬೆಗಡಿಸು',
'ಬೆಗಡೊಂದು',
'ಬೆಗಸಿಲ್',
'ಬೆಗಸಿಲೆ',
'ಬೆಗೞ್ಗೆಡೆ',
'ಬೆಗೞ್ಗೊಳ್',
'ಬೆಚ್ಚರಿಕೆ',
'ಬೆಚ್ಚಳಿಸು',
'ಬೆಚ್ಚುನೀರ್',
'ಬೆಚ್ಚುನೀರು',
'ಬೆಜ್ಜಂಗೆಯ್',
'ಬೆಜ್ಜಗಜ್ಜ',
'ಬೆಜ್ಜತನ',
'ಬೆಜ್ಜರಿಕೆ',
'ಬೆಜ್ಜರಿಸು',
'ಬೆಜ್ಜುಗೆಯ್',
'ಬೆಜ್ಜುವಡೆ',
'ಬೆಟ್ಟಕಂಚಿ',
'ಬೆಟ್ಟಗೋಂಟೆ',
'ಬೆಟ್ಟಡಿಕೆ',
'ಬೆಟ್ಟತೆಂಗು',
'ಬೆಟ್ಟನೆಲ್ಲಿ',
'ಬೆಟ್ಟಬಾಗೆ',
'ಬೆಟ್ಟಬಾಳೆ',
'ಬೆಟ್ಟಬೆಂಡೆ',
'ಬೆಟ್ಟಬೇವು',
'ಬೆಟ್ಟಮಾವು',
'ಬೆಟ್ಟರಳಿ',
'ಬೆಟ್ಟರಾಗಿ',
'ಬೆಟ್ಟವಕ್ಕಿ',
'ಬೆಟ್ಟವಾೞೆ',
'ಬೆಟ್ಟಸೀಮೆ',
'ಬೆಟ್ಟಹೊನ್ನೆ',
'ಬೆಟ್ಟುಂಗಾಡು',
'ಬೆಟ್ಟುಂಬೊೞೆ',
'ಬೆಟ್ಟುಗತ್ತಿ',
'ಬೆಟ್ಟುಗದ್ದೆ',
'ಬೆಟ್ಟುಗಾಡು',
'ಬೆಟ್ಟುಬ್ಬಸ',
'ಬೆಟ್ಟುಮುರಿ',
'ಬೆಟ್ಟುವೊೞೆ',
'ಬೆಡಂಗೊಂದು',
'ಬೆಡಿಕಂಬ',
'ಬೆಣಕೇಱು',
'ಬೆಣತಿಗೆ',
'ಬೆಣ್ಣೆಗಲ್ಲು',
'ಬೆಣ್ಣೆಗಳೆ',
'ಬೆಣ್ಣೆತೆಂಗು',
'ಬೆಣ್ಣೆಮಾತು',
'ಬೆತಿರೇಕ',
'ಬೆತ್ತಲಿಗ',
'ಬೆತ್ತಲೆಗ',
'ಬೆದಕಾಟ',
'ಬೆದಕಿಸು',
'ಬೆದರಟ್ಟು',
'ಬೆದರಿಕೆ',
'ಬೆದರಿಸು',
'ಬೆದರ್ಗೊಂಬೆ',
'ಬೆದಱಟ್ಟು',
'ಬೆದಱಾಡಿ',
'ಬೆದಱಿಕೆ',
'ಬೆದಱಿಸು',
'ಬೆದೆಕಾಱ',
'ಬೆದೆಗದ್ದೆ',
'ಬೆದೆಗೞ್ದೆ',
'ಬೆದೆಗಾತಿ',
'ಬೆದೆಗಾರ್ತಿ',
'ಬೆದೆಗಾಲ',
'ಬೆದೆಗಾಱ',
'ಬೆದೆಗುದಿ',
'ಬೆದೆಗೂಡು',
'ಬೆದೆವಡೆ',
'ಬೆನ್ನುಕಟ್ಟು',
'ಬೆನ್ನುಕೊಡು',
'ಬೆನ್ನುಡಬ್ಬೆ',
'ಬೆನ್ನುಫಣಿ',
'ಬೆನ್ನುಬಲ',
'ಬೆನ್ನುಬೀಳು',
'ಬೆನ್ನುಮೂಳೆ',
'ಬೆನ್ನುಹಂಚು',
'ಬೆನ್ನುಹತ್ತು',
'ಬೆನ್ನುಹುಣ್ಣು',
'ಬೆನ್ನುಹುರಿ',
'ಬೆನ್ನೆಲುಬು',
'ಬೆನ್ನೆಲುವು',
'ಬೆಪ್ಪಳಿಸು',
'ಬೆಪ್ಪುತನ',
'ಬೆಬ್ಬರಿಸು',
'ಬೆಬ್ಬಳಿಗ',
'ಬೆಬ್ಬಳಿತ',
'ಬೆಬ್ಬಳಿಸು',
'ಬೆಮರಿಡು',
'ಬೆಮರಿಳಿ',
'ಬೆಮರ್ಸಾಲೆ',
'ಬೆಮೆಗೊಳ್',
'ಬೆಮೆಗೊಳು',
'ಬೆಮ್ಮಾರಲು',
'ಬೆರಲಚ್ಚು',
'ಬೆರಲೆತ್ತು',
'ಬೆರಲ್ಮಿಡಿ',
'ಬೆರಳಚ್ಚು',
'ಬೆರಳಿಚ',
'ಬೆರಳಿಡು',
'ಬೆರಳಿಸು',
'ಬೆರಳೆತ್ತು',
'ಬೆರಳ್ಮಿಡಿ',
'ಬೆರಳ್ಮುರಿ',
'ಬೆರೆಯಿಸು',
'ಬೆರ್ಚುಗಟ್ಟು',
'ಬೆರ್ಚುಗಣ್',
'ಬೆಲಗಸೆ',
'ಬೆಲವಕ',
'ಬೆಲೆಕಟ್ಟು',
'ಬೆಲೆಕೊಡು',
'ಬೆಲೆಗಟ್ಟು',
'ಬೆಲೆಗಾಣು',
'ಬೆಲೆಗುಡು',
'ಬೆಲೆಗೆಯ್',
'ಬೆಲೆಗೇಡು',
'ಬೆಲೆಗೊಡು',
'ಬೆಲೆಗೊಳ್',
'ಬೆಲೆಗೊಳ್ಳು',
'ಬೆಲೆಪಟ್ಟಿ',
'ಬೆಲೆಯಿಡು',
'ಬೆಲೆವಡೆ',
'ಬೆಲೆವಣಿ',
'ಬೆಲೆವೆಣ್',
'ಬೆಲೆವೆಣ್ಣು',
'ಬೆಲ್ಲಂಗೋಲ್',
'ಬೆಲ್ಲಗೂಳು',
'ಬೆಲ್ಲಗೆಡ್ಡೆ',
'ಬೆಲ್ಲಗೊಟ್ಟೆ',
'ಬೆಲ್ಲಪತ್ರೆ',
'ಬೆಲ್ಲವತ್ತ',
'ಬೆಲ್ಲವಾತು',
'ಬೆಲ್ಲವುದಿ',
'ಬೆಲ್ಲುಣಗಿ',
'ಬೆಲ್ಲುಣಿಗೆ',
'ಬೆವಕೂಫ್',
'ಬೆವಟಿಕ್ಕು',
'ಬೆವರಕ್ಕಿ',
'ಬೆವರಟ್ಟೆ',
'ಬೆವರಿಡು',
'ಬೆವರಿಸು',
'ಬೆವರಿಳಿ',
'ಬೆವಸಾಯ',
'ಬೆವಹಾರ',
'ಬೆವಹಾರಿ',
'ಬೆಸಂಗೆಯ್',
'ಬೆಸಂಬಡೆ',
'ಬೆಸಂಬೆಱು',
'ಬೆಸಂಬೇಳ್',
'ಬೆಸಂಬೇೞ್',
'ಬೆಸಕೆಯ್',
'ಬೆಸಕೆಯ್ಸು',
'ಬೆಸಕೇಳ್',
'ಬೆಸಕೈಸು',
'ಬೆಸಕೋಲು',
'ಬೆಸಗಲಿ',
'ಬೆಸಗುಡು',
'ಬೆಸಗೆಯ್',
'ಬೆಸಗೆಯ್ಸು',
'ಬೆಸಗೊಡು',
'ಬೆಸಗೊಳ್',
'ಬೆಸಗೊಳು',
'ಬೆಸಗೊಳ್ಳು',
'ಬೆಸದಪ್ಪು',
'ಬೆಸನಿಗ',
'ಬೆಸನಿಸು',
'ಬೆಸಮಗ',
'ಬೆಸಮುಟ್ಟು',
'ಬೆಸೆವಗ',
'ಬೆಸವಡೆ',
'ಬೆಸವೆಣ್',
'ಬೆಸವೆಣ್ಣು',
'ಬೆಸವೆಱು',
'ಬೆಸವೇಡು',
'ಬೆಸವೇಳ್',
'ಬೆಸವೇಳು',
'ಬೆಸವೇೞ್',
'ಬೆಸಳಿಗೆ',
'ಬೆಸೆಕೋಲ್',
'ಬೆಸೆಗಾರ',
'ಬೆಸೆಗಾಱ',
'ಬೆಳಂಗಿಸು',
'ಬೆಳಂತಿಗೆ',
'ಬೆಳಂದರು',
'ಬೆಳಕಂಡಿ',
'ಬೆಳಕಿಂಡಿ',
'ಬೆಳಕುಡಿ',
'ಬೆಳಗಾಯ್',
'ಬೆಳಗಾಯಿ',
'ಬೆಳಗಾರ',
'ಬೆಳಗಿಲಿ',
'ಬೆಳಗಿಸು',
'ಬೆಳಗೆಯ್',
'ಬೆಳತಿಗೆ',
'ಬೆಳದಲೆ',
'ಬೆಳರಿಸು',
'ಬೆಳರ್ವಕ್ಕಿ',
'ಬೆಳರ್ವಡು',
'ಬೆಳರ್ವಣ್ಣ',
'ಬೆಳವಲ',
'ಬೆಳವಾರ',
'ಬೆಳವಿಗೆ',
'ಬೆಳಿಕೊಂಡೆ',
'ಬೆಳಿಗಾರ',
'ಬೆಳುಗತ್ತೆ',
'ಬೆಳುಗಾಯ್',
'ಬೆಳುಗಾರ',
'ಬೆಳುಗೊಡೆ',
'ಬೆಳುಜೊನ್ನ',
'ಬೆಳುದಂಡ',
'ಬೆಳುದುರೆ',
'ಬೆಳುನಗೆ',
'ಬೆಳುನುಡಿ',
'ಬೆಳುನೆಲ್ಲಿ',
'ಬೆಳುಮಾತು',
'ಬೆಳುಮೀನ',
'ಬೆಳುವರಿ',
'ಬೆಳುವಲ',
'ಬೆಳುವಲು',
'ಬೆಳುವಲೆ',
'ಬೆಳುವಸೆ',
'ಬೆಳುಬಳ್ಳಿ',
'ಬೆಳುವಾಲ',
'ಬೆಳುವಾಲಿ',
'ಬೆಳುವೆಟ್ಟ',
'ಬೆಳುಹೂಲಿ',
'ಬೆಳೆಗಾಡು',
'ಬೆಳೆಗಾರ',
'ಬೆಳೆಗಾಱ',
'ಬೆಳೆಗೆಯ್',
'ಬೆಳೆಗೇಡು',
'ಬೆಳೆಗೊಡು',
'ಬೆಳೆತಾಯ',
'ಬೆಳೆದಾಳ್ದು',
'ಬೆಳೆನೀರು',
'ಬೆಳೆನೆಲ',
'ಬೆಳೆಬಡ್ಡಿ',
'ಬೆಳೆಯಿಸು',
'ಬೆಳೆವೊಲ',
'ಬೆಳೆಹೊಲ',
'ಬೆಳ್ಕರಿಸು',
'ಬೆಳ್ಗದಿರ್',
'ಬೆಳ್ಗದಿರ',
'ಬೆಳ್ಗನ್ನಡ',
'ಬೆಳ್ಗುಂಬಳ',
'ಬೆಳ್ದಾವರೆ',
'ಬೆಳ್ದಿಂಗಳ್',
'ಬೆಳ್ದಿಂಗಳು',
'ಬೆಳ್ದುಗುಲ',
'ಬೆಳ್ಪಡರ್',
'ಬೆಳ್ಪಡರು',
'ಬೆಳ್ಪಡಸು',
'ಬೆಳ್ಪಳಿಸು',
'ಬೆಳ್ಪುದೋಱು',
'ಬೆಳ್ಪುವಡೆ',
'ಬೆಳ್ಪುವೆಱು',
'ಬೆಳ್ಪೊಱಸು',
'ಬೆಳ್ಮಾನಿಸ',
'ಬೆಳ್ಮಾರಲ',
'ಬೆಳ್ಮುನಿಸು',
'ಬೆಳ್ವಳಿಸು',
'ಬೆಳ್ವಾಗಲ್',
'ಬೆಳ್ಳಗಸೆ',
'ಬೆಳ್ಳಚ್ಚರಿ',
'ಬೆಳ್ಳಡಿಯ',
'ಬೆಳ್ಳಾರ್ಪಟ',
'ಬೆಳ್ಳಿಜಾಲಿ',
'ಬೆಳ್ಳಿಬೆಟ್ಟ',
'ಬೆಳ್ಳಿಮಲೆ',
'ಬೆಳ್ಳಿಯುಗ',
'ಬೆಳ್ಳಿವೆಟ್ಟ',
'ಬೆಳ್ಳಿವೆಟ್ಟು',
'ಬೆಳ್ಳಿಹಬ್ಬ',
'ಬೆಳ್ಳೆರಲೆ',
'ಬೆಳ್ಳೆರಳೆ',
'ಬೆಱಗಿಡು',
'ಬೆಱಗೊಂದು',
'ಬೇಅಬರು',
'ಬೇಅಸಲ್',
'ಬೇಇಮಾನ',
'ಬೇಂಗಪಲ್ಲಿ',
'ಬೇಂಟಗೊಳ್',
'ಬೇಂಟಗಾಱ',
'ಬೇಂಟೆಕಾಱ',
'ಬೇಂಟೆಗಾರ್ತಿ',
'ಬೇಂಟೆಗಾಱ',
'ಬೇಂಟೆಗೋಲ್',
'ಬೇಂಟೆನಾಯ್',
'ಬೇಂಟೆಪುಲಿ',
'ಬೇಂಟೆಯಾಡು',
'ಬೇಂಟೆಸೋವು',
'ಬೇಕಾಬಿಟ್ಟಿ',
'ಬೇಗಡಿಸು',
'ಬೇಗುದಿಸು',
'ಬೇಗುರಿಕ',
'ಬೇಗೆಗಣ್',
'ಬೇಗೆಗಿಚ್ಚು',
'ಬೇಗೆಗಿರ್ಚು',
'ಬೇಗೆಗೊಳ್',
'ಬೇಗೆಪತ್ತು',
'ಬೇಗೆಯಾಱು',
'ಬೇಗೆವಡೆ',
'ಬೇಗೆವರಿ',
'ಬೇಗೆವೋಗು',
'ಬೇಚರಾಕ',
'ಬೇಚರಾಕು',
'ಬೇಚರಾಖ',
'ಬೇಚರಾಖು',
'ಬೇಟಕಾರ',
'ಬೇಟಕಾರ್ತಿ',
'ಬೇಟಕಾಱ',
'ಬೇಟಗೊಳ್',
'ಬೇಟಹೂಡು',
'ಬೇಟೆಕಾತಿ',
'ಬೇಟೆಕಾಱ',
'ಬೇಟೆಗಾರ',
'ಬೇಟೆಗಾಱ',
'ಬೇಟೆನಾಯಿ',
'ಬೇಟೆಯಾಡು',
'ಬೇಟೆವೆಸ',
'ಬೇಡಕೊಟ್ಟ',
'ಬೇಡಗೊಟ್ಟ',
'ಬೇಡವಟ್ಟ',
'ಬೇಡವಟ್ಟು',
'ಬೇದಬಿಜ್ಜೆ',
'ಬೇದಿರುಸು',
'ಬೇನೆಗುದಿ',
'ಬೇಯಯೞಿ',
'ಬೇನೆಯೆತ್ತು',
'ಬೇಫಿಕರ್',
'ಬೇಫಿಕೀರ',
'ಬೇಫೀಕೀರ',
'ಬೇಮಾಲುಮ್',
'ಬೇಮಾಲೂಮ್',
'ಬೇರಯಿಸು',
'ಬೇರುಗಾಣ್',
'ಬೇರುಗಿತಿ',
'ಬೇರುಗೊಲೆ',
'ಬೇರುಬಳ್ಳಿ',
'ಬೇರುಬಿಡು',
'ಬೇರುಮಾರಿ',
'ಬೇರುವರಿ',
'ಬೇರುರಿಸು',
'ಬೇರ್ಕೆಗೆಯ್',
'ಬೇರ್ಪಡಿಕೆ',
'ಬೇರ್ಪಡಿಸು',
'ಬೇರ್ಬಿಡಿಸು',
'ಬೇರ್ವೆರೆಸು',
'ಬೇಲಸಾರ',
'ಬೇಲಾಶಕ್',
'ಬೇಲಾಷಕ್',
'ಬೇಲಿಕಳ್ಳಿ',
'ಬೇಲಿಯಿಕ್ಕು',
'ಬೇಲಿವಳ್ಳಿ',
'ಬೇಲಿವೀಡು',
'ಬೇಲಿಸೌತೆ',
'ಬೇವಕೂಫ್',
'ಬೇವಾರಸಿ',
'ಬೇವಾರಸು',
'ಬೇಷರತ್ತು',
'ಬೇಸರಿಕೆ',
'ಬೇಸರಿಸು',
'ಬೇಸಱಿಕೆ',
'ಬೇಸಱಿಸು',
'ಬೇಸಱೆಯ್ದು',
'ಬೇಸ್ತವಾರ',
'ಬೇಸ್ತುಬೀಳು',
'ಬೇಹರಿಸು',
'ಬೇಹಾರಿಗ',
'ಬೇಹುಕಾತಿ',
'ಬೇಹುಕಾರ',
'ಬೇಹುಕಾಱ',
'ಬೇಹುಗಾರ',
'ಬೇಹುಗಾಱ',
'ಬೇಹುಗುಟ್ಟು',
'ಬೇಹುವಳ',
'ಬೇಹುಷಾರಿ',
'ಬೇಹುಷಾರು',
'ಬೇಳಂಬಿಗ',
'ಬೇಳುಗರೆ',
'ಬೇಳುಗೊಳ್',
'ಬೇಳುಗೊಳ್ಳು',
'ಬೇಳುನುಡಿ',
'ಬೇಳುಮಾತು',
'ಬೇಳುವಡು',
'ಬೇಳುವಡೆ',
'ಬೇಳೆಕಾಳು',
'ಬೇಳೆಗೆಯ್',
'ಬೇಳೆಮಣಿ',
'ಬೇಳ್ಮೆಮಾತು',
'ಬೇಳ್ವೆಮಾತು',
'ಬೇಱಿರಿಸು',
'ಬೇಱುಕೆಯ್',
'ಬೇಱುಪಡು',
'ಬೇಱುವೋಗು',
'ಬೈಕಂಗುಳಿ',
'ಬೈಕಂಗೊಳ್',
'ಬೈಕಂದಿರಿ',
'ಬೈಕಂಬೇಡು',
'ಬೈಕೆಗೊಡು',
'ಬೈತಲಿಕ್ಕು',
'ಬೈರವಾಸ',
'ಬೈರಾಗಿಣಿ',
'ಬೈರಾವಳಿ',
'ಬೈಲುಕಡೆ',
'ಬೈಲುಗದ್ದೆ',
'ಬೈಲುಭೂಮಿ',
'ಬೈಶಣಿಗೆ',
'ಬೈಸಣಿಗೆ',
'ಬೈಸನಿಗೆ',
'ಬೈಸಿಕಲ್ಲು',
'ಬೈಸುರಿಗೆ',
'ಬೊಂಕೆಮಣ್ಣು',
'ಬೊಂತೆಕಟ್ಟು',
'ಬೊಂತೆಕಳ್ಳಿ',
'ಬೊಂತೆಗಳ್ಳಿ',
'ಬೊಂದಬಾಳೆ',
'ಬೊಂದರಿಗೆ',
'ಬೊಂಬಾಳಿಸು',
'ಬೊಂಬೆಗಣ್ಣಿ',
'ಬೊಂಬೆಯಾಟ',
'ಬೊಕ್ಕಣಿಕೆ',
'ಬೊಕ್ಕತಲೆ',
'ಬೊಕ್ಕದಲೆ',
'ಬೊಕ್ಕನೆತ್ತಿ',
'ಬೊಕ್ಕಬಾಯಿ',
'ಬೊಕ್ಕಳಿಕೆ',
'ಬೊಕ್ಕೆಯೊಡೆ',
'ಬೊಗುಳಾಟ',
'ಬೊಗುಳಿಸು',
'ಬೊಗ್ಗಿಕಾಂಡ',
'ಬೊಚ್ಚುಬಾಯಿ',
'ಬೊಟ್ಟಸೊಪ್ಪು',
'ಬೊಟ್ಟಳಿಗ',
'ಬೊಟ್ಟಿಡಿಸು',
'ಬೊಟ್ಟುಗಳ್ಳಿ',
'ಬೊಟ್ಟುಗೊಳ್',
'ಬೊಟ್ಟುಮುರಿ',
'ಬೊಡಂಧರ',
'ಬೊಡ್ಡಿತನ',
'ಬೊಡ್ಡಿಮಗ',
'ಬೊನುಗಾರೆ',
'ಬೊನೆಗಾರೆ',
'ಬೊಬ್ಬಿಡಿಸು',
'ಬೊಬ್ಬಿಱಿತ',
'ಬೊಬ್ಬುಳಿಕೆ',
'ಬೊಬ್ಬುಳಿಸು',
'ಬೊಬ್ಬೆಗುಟ್ಟು',
'ಬೊಬ್ಬೆಗುಡು',
'ಬೊಬ್ಬೆಗೊಡು',
'ಬೊಬ್ಬೆಗೊಳ್',
'ಬೊಬ್ಬೆಯಿಕ್ಕು',
'ಬೊಬ್ಬೆಯಿಡು',
'ಬೊಮ್ಮಚರಿ',
'ಬೊಮ್ಮದಂಡೆ',
'ಬೊಮ್ಮಪಾಸ',
'ಬೊಮ್ಮಬೋಧೆ',
'ಬೊಮ್ಮರಲು',
'ಬೊಮ್ಮವಿದ',
'ಬೊಮ್ಮವಿದ್ಯೆ',
'ಬೊಮ್ಮಹತಿ',
'ಬೊಮ್ಮಹಾಸ',
'ಬೊಳ್ಳುಗರೆ',
'ಬೋಗಾಱಿಕೆ',
'ಬೋಗುಡಿಚೆ',
'ಬೋಜಗಾರ',
'ಬೋಜುಕಾರ',
'ಬೋಜುಗಾರ್ತಿ',
'ಬೋಡುಗಿಡ',
'ಬೋದಬೋಟೆ',
'ಬೋದೆಸರ',
'ಬೋಧಿಸತ್ತ್ವ',
'ಬೋಧಿಸತ್ವ',
'ಬೋನಂಗಾರೆ',
'ಬೋನಗಾರ',
'ಬೋನಗಾರೆ',
'ಬೋನಗಾಱ',
'ಬೋನಗಾರ್ತಿ',
'ಬೋನಗಿತ್ತಿ',
'ಬೋನಮಿಡು',
'ಬೋನಮೆತ್ತು',
'ಬೋನವೆತ್ತು',
'ಬೋನುಗಾರೆ',
'ಬೋರಮಾಳ',
'ಬೋರಮಾಳು',
'ಬೋರಲಿಡು',
'ಬೋರಿಸರ',
'ಬೋವಗಾಱ',
'ಬೋವಸೊಂಡೆ',
'ಬೋಸರಣೆ',
'ಬೋಸರಿಗ',
'ಬೋಸರಿಗೆ',
'ಬೋಸರಿಸು',
'ಬೋಹಣಿಸು',
'ಬೋಹರಿಗೆ',
'ಬೋಹರಿಸು',
'ಬೋಳಂದರು',
'ಬೋಳಕ್ಕಾರು',
'ಬೋಳತರ',
'ಬೋಳಮಟೆ',
'ಬೋಳಯಿಸು',
'ಬೋಳವಿಸು',
'ಬೋಳುಗರೆ',
'ಬೋಳುತಲೆ',
'ಬೋಳುದಲೆ',
'ಬೋಳುಮಂಡೆ',
'ಬೋೞತರ',
'ಬೋೞಿಸುಹ',
'ಬೋೞುಮಂಡೆ',
'ಬೌದ್ಧಮತ',
'ಬೌದ್ಧಿಕತೆ',
'ಬೃತಿಪಾತ',
'ಬೃತೀಪಾತ',
'ಬ್ಯವಸಾಯ',
'ಬ್ಯವಹಾರ',
'ಬ್ಯಾಕ್ಟೀರಿಯಾ',
'ಬ್ಯಾವರ್ಣಿಸು',
'ಬ್ರಹ್ಮಕಂಬಿ',
'ಬ್ರಹ್ಮಕರ್ಮ',
'ಬ್ರಹ್ಮಕಾಂಡ',
'ಬ್ರಹ್ಮಕಾಂತ',
'ಬ್ರಹ್ಮಗಂಟು',
'ಬ್ರಹ್ಮಗಂಬಿ',
'ಬ್ರಹ್ಮಗಣ',
'ಬ್ರಹ್ಮಗ್ರಂಥಿ',
'ಬ್ರಹ್ಮಚಕ್ರ',
'ಬ್ರಹ್ಮಚರ್ಯ',
'ಬ್ರಹ್ಮಚರ್ಯೆ',
'ಬ್ರಹ್ಮಚಾರಿ',
'ಬ್ರಹ್ಮಜಾತಿ',
'ಬ್ರಹ್ಮಜ್ಞಾನ',
'ಬ್ರಹ್ಮಜ್ಞಾನಿ',
'ಬ್ರಹ್ಮಜ್ಯೋತಿ',
'ಬ್ರಹ್ಮತತ್ತ್ವ',
'ಬ್ರಹ್ಮತೇಜ',
'ಬ್ರಹ್ಮದಂಡ',
'ಬ್ರಹ್ಮದಂಡಿ',
'ಬ್ರಹ್ಮದಂಡೆ',
'ಬ್ರಹ್ಮದರ್ಭೆ',
'ಬ್ರಹ್ಮದರ್ಶಿ',
'ಬ್ರಹ್ಮದಾಯ',
'ಬ್ರಹ್ಮದಾರು',
'ಬ್ರಹ್ಮದೇಯ',
'ಬ್ರಹ್ಮದ್ವೇಷ',
'ಬ್ರಹ್ಮದ್ವೇಷಿ',
'ಬ್ರಹ್ಮಧರ್ಮ',
'ಬ್ರಹ್ಮನಾಡಿ',
'ಬ್ರಹ್ಮನಾಳ',
'ಬ್ರಹ್ಮಪಟ್ಟ',
'ಬ್ರಹ್ಮಪಾಶ',
'ಬ್ರಹ್ಮಪುತ್ರ',
'ಬ್ರಹ್ಮಪುರ',
'ಬ್ರಹ್ಮಪುರಿ',
'ಬ್ರಹ್ಮಪ್ರಾಪ್ತಿ',
'ಬ್ರಹ್ಮಬಂಧು',
'ಬ್ರಹ್ಮಬಿಂದು',
'ಬ್ರಹ್ಮಬ್ರುವ',
'ಬ್ರಹ್ಮಮಾರ್ಗ',
'ಬ್ರಹ್ಮಯಜ್ಞ',
'ಬ್ರಹ್ಮಯೋಗ',
'ಬ್ರಹ್ಮರಂಧ್ರ',
'ಬ್ರಹ್ಮರ್ಷಿತ್ವ',
'ಬ್ರಹ್ಮಲಿಪಿ',
'ಬ್ರಹ್ಮಲೋಕ',
'ಬ್ರಹ್ಮವಂತ',
'ಬ್ರಹ್ಮವರ್ಷ',
'ಬ್ರಹ್ಮವಾದಿ',
'ಬ್ರಹ್ಮವಿತ್ತು',
'ಬ್ರಹ್ಮವಿದ',
'ಬ್ರಹ್ಮವಿದ್ಯೆ',
'ಬ್ರಹ್ಮವೀಣೆ',
'ಬ್ರಹ್ಮವೃಕ್ಷ',
'ಬ್ರಹ್ಮವೇತ್ತ',
'ಬ್ರಹ್ಮವ್ರತ',
'ಬ್ರಹ್ಮಶಿರ',
'ಬ್ರಹ್ಮಶೌಚ',
'ಬ್ರಹ್ಮಸಭೆ',
'ಬ್ರಹ್ಮಸೂತ್ರ',
'ಬ್ರಹ್ಮಸೃಷ್ಟಿ',
'ಬ್ರಹ್ಮಸ್ಥಾನ',
'ಬ್ರಹ್ಮಹತಿ',
'ಬ್ರಹ್ಮಹತ್ಯ',
'ಬ್ರಹ್ಮಹತ್ಯೆ',
'ಬ್ರಹ್ಮಾಂಜಲಿ',
'ಬ್ರಹ್ಮಾದಾಯ',
'ಬ್ರಹ್ಮಾದೇಯ',
'ಬ್ರಹ್ಮಾನಂದ',
'ಬ್ರಹ್ಮಾಮ್ಲಿಕ',
'ಬ್ರಹ್ಮಾರ್ಪಣ',
'ಬ್ರಹ್ಮಾವರ್ತ',
'ಬ್ರಹ್ಮಾಸನ',
'ಬ್ರಹ್ಮೈಕ್ಯತೆ',
'ಬ್ರಹ್ಮೋತ್ತರ',
'ಬ್ರಹ್ಮೋತ್ಸವ',
'ಬ್ರಾಹ್ಮಣತ್ವ',
'ಬ್ರಾಹ್ಮಣಾರ್ಥ',
'ಬ್ರಾಹ್ಮಣಿಕೆ',
'ಬ್ರಾಹ್ಮಣಿತಿ',
'ಬ್ರಾಹ್ಮೀಲಿಪಿ',
'ಬ್ರಾಹ್ಮೀsಸ್ಥಿತಿ',
'ಬ್ರಿಹವಾರ',
'ಬ್ರುಂಹಿತಾತ್ಮ',
'ಬ್ರೆಹವಾರ',
'ಭಂಗಂಬಡು',
'ಭಂಗಂಬಡೆ',
'ಭಂಗಂಬೆಱು',
'ಭಂಗಗಾಱ',
'ಭಂಗಗೊಳು',
'ಭಂಗತಾಳ',
'ಭಂಗಪಡು',
'ಭಂಗಬಡು',
'ಭಂಗವಡು',
'ಭಂಗವಡೆ',
'ಭಂಗಶ್ಲೇಷ',
'ಭಂಗಿಪಟ್ಟಿ',
'ಭಂಗಿಮುಕ್ಕ',
'ಭಂಗಿವಡೆ',
'ಭಂಗಿವೆಱು',
'ಭಂಜಣಿಗೆ',
'ಭಂಜವಣೆ',
'ಭಂಡಗೇಡು',
'ಭಂಡತನ',
'ಭಂಡಬಾಳು',
'ಭಂಡವಲ',
'ಭಂಡವಲು',
'ಭಂಡವಾಳ',
'ಭಂಡವಾಳು',
'ಭಂಡವಿದ್ಯೆ',
'ಭಂಡಸಾಲೆ',
'ಭಂಡಾರಕ',
'ಭಂಡಾರಿಗ',
'ಭಂಡಾರಿಸು',
'ಭಂಡಿಕಾರ',
'ಭಂಡಿಕಾಱ',
'ಭಂಡಿದೆರೆ',
'ಭಂಡುಗೆಯ್',
'ಭಕ್ತಸ್ಥಲ',
'ಭಕ್ತಿಗೀತ',
'ಭಕ್ತಿಗೀತೆ',
'ಭಕ್ತಿಪಂಥ',
'ಭಕ್ತಿಪಥ',
'ಭಕ್ತಿಭಾವ',
'ಭಕ್ತಿಮಾರ್ಗ',
'ಭಕ್ತಿಯೋಗ',
'ಭಕ್ತಿಯೋಗಿ',
'ಭಕ್ತಿರಸ',
'ಭಕ್ತಿಸ್ಥಲ',
'ಭಕ್ತಿಹೀನ',
'ಭಕ್ತಿಹೀನೆ',
'ಭಕ್ಷಕಾರಿ',
'ಭಕ್ಷ್ಯಕಾರ',
'ಭಗಂದರ',
'ಭಗದಾಳ',
'ಭಗಧ್ವಜ',
'ಭಗರೋಮ',
'ಭಗವಂತ',
'ಭಗವತಿ',
'ಭಗವನ್',
'ಭಗವಾನ್',
'ಭಗಾಂಕುರ',
'ಭಗಿನಿಜ',
'ಭಗೀರಥ',
'ಭಗ್ನಪಾಠ',
'ಭಗ್ನಮನ',
'ಭಜಿಯಿಸು',
'ಭಟಮಾನ್ಯ',
'ಭಟರಾಜು',
'ಭಟಾರಕ',
'ಭಟ್ಟಗೆಯಿ',
'ಭಟ್ಟಮತ',
'ಭಟ್ಟಮಾನ್ಯ',
'ಭಟ್ಟವೃತ್ತಿ',
'ಭಟ್ಟವ್ರಿತ್ತಿ',
'ಭಟ್ಟಾರಕ',
'ಭಟ್ಟಿಗೆಯ್',
'ಭಟ್ಟಿಸುಂಕ',
'ಭಡಕ್ಕನೆ',
'ಭಣಗುಟ್ಟು',
'ಭಣಗುಡು',
'ಭತ್ತಗಾವೆ',
'ಭತ್ತಗ್ರಾಮ',
'ಭದ್ರಕಾಲಿ',
'ಭದ್ರಕಾಳಿ',
'ಭದ್ರದಾರು',
'ಭದ್ರಪದೆ',
'ಭದ್ರಪರ್ಣಿ',
'ಭದ್ರಪೀಠ',
'ಭದ್ರಬಲೆ',
'ಭದ್ರಮುಸ್ತೆ',
'ಭದ್ರಯವ',
'ಭದ್ರಾಕಾರ',
'ಭದ್ರಾತಕ',
'ಭದ್ರಾಸನ',
'ಭಯಂಕರ',
'ಭಯಂಕಾರಿ',
'ಭಯಂಕೃತಿ',
'ಭಯಂಗೊಳ್',
'ಭಯಂದೋಱು',
'ಭಯಂಬಡು',
'ಭಯಂಬಡೆ',
'ಭಯಗೊಳ್',
'ಭಯಗ್ರಸ್ತ',
'ಭಯಗ್ರಸ್ತೆ',
'ಭಯಜ್ವರ',
'ಭಯಪಡು',
'ಭಯಬೀಳು',
'ಭಯರಸ',
'ಭಯವೊಂದು',
'ಭಯಾಕುಲ',
'ಭಯಾಕುಲೆ',
'ಭಯಾಕುಳ',
'ಭಯಾಕುಳೆ',
'ಭಯಾನಕ',
'ಭಯಾವಹ',
'ಭಯಿಮೂಗ',
'ಭಯೋತ್ಕಟ',
'ಭರಂಗೆಯ್',
'ಭರಗೊಳ್',
'ಭರಂಬಡು',
'ಭರಕತ್ತು',
'ಭರಕಾಸ್ತು',
'ಭರಖಾಸ್ತು',
'ಭರಗಚ್ಚು',
'ಭರಗೆಡು',
'ಭರಣಿಗ',
'ಭರಣಿಗೆ',
'ಭರತಜ್ಞ',
'ಭರತರ್ಫು',
'ಭರದಂಡು',
'ಭರದಾಂಡ್',
'ಭರದಾಂಡು',
'ಭರದ್ವಾಜ',
'ಭರಪೂರ',
'ಭರಭರ',
'ಭರಭರಿ',
'ಭರಭಾರ',
'ಭರಭಾರಿ',
'ಭರವಶ',
'ಭರವಸ',
'ಭರವಸೆ',
'ಭರಷಣ',
'ಭರಸಹ',
'ಭರಾಣ್ಮೌಲಿ',
'ಭರಾಭರಿ',
'ಭರಾಯಿಸು',
'ಭರಿಕರ',
'ಭರಿಕೆಯ್',
'ಭರೂಟಕ',
'ಭರ್ತಶಾಸ್ತ್ರ',
'ಭರ್ಮಕರ್ಮ',
'ಭರ್ಮಾಂಬರ',
'ಭಲ್ಲಾತಕ',
'ಭಲ್ಲಾತಕಿ',
'ಭಲ್ಲಾಸನ',
'ಭವಂಗಳೆ',
'ಭವಕರ್ಮ',
'ಭವಕ್ಷಯ',
'ಭವಗೆಡು',
'ಭವಗೇಡಿ',
'ಭವತ್ಕಾಲ',
'ಭವದೀಯ',
'ಭವದೂರ',
'ಭವನಾಂಗ',
'ಭವನಾಶಿ',
'ಭವನಿಕೆ',
'ಭವಪಾಶ',
'ಭವಬಂಧ',
'ಭವಬಡು',
'ಭವಬಾಧೆ',
'ಭವಮಾಲೆ',
'ಭವಮುಕ್ತ',
'ಭವಮುಕ್ತೆ',
'ಭವರಿಪು',
'ಭವರುಜೆ',
'ಭವರೋಗ',
'ಭವರೋಗಿ',
'ಭವಸ್ಮೃತಿ',
'ಭವಹರ',
'ಭವಹರೆ',
'ಭವಾಂತರ',
'ಭವಾವಳಿ',
'ಭವಿತನ',
'ಭವಿತವ್ಯ',
'ಭವಿಷಿಯ',
'ಭವಿಷ್ಯಂತಿ',
'ಭವಿಷ್ಯತ್ತು',
'ಭಸಣಿಗೆ',
'ಭಸಿತಾಂಗ',
'ಭಸ್ಮಧಾರಿ',
'ಭಸ್ಮಪುಟ',
'ಭಸ್ಮರಸ',
'ಭಸ್ಮಾಸುರ',
'ಭಾಂಕರಿಸು',
'ಭಾಂಡವಲ',
'ಭಾಂಡವಲು',
'ಭಾಂಡವಳು',
'ಭಾಂಡಾಗಾರ',
'ಭಾಗಂಗೊಳ್',
'ಭಾಗಂಗೊಳು',
'ಭಾಗಂಬೋಗು',
'ಭಾಗಗೊಳ್ಳು',
'ಭಾಗಧೇಯ',
'ಭಾಗಲಬ್ಧ',
'ಭಾಗವಂತ',
'ಭಾಗವಡೆ',
'ಭಾಗವತ',
'ಭಾಗಹಾರ',
'ಭಾಗಾಕಾರ',
'ಭಾಗಾವಳಿ',
'ಭಾಗಾಹಾರ',
'ಭಾಗಿನೇಯ',
'ಭಾಗಿಸುಹ',
'ಭಾಗೀದಾರ',
'ಭಾಗೀರಥಿ',
'ಭಾಗೆಕಾಱ',
'ಭಾಗೆಗೊಡು',
'ಭಾಗೇದಾರ',
'ಭಾಗ್ಯದೈವ',
'ಭಾಗ್ಯಲಕ್ಷ್ಮಿ',
'ಭಾಗ್ಯವಂತ',
'ಭಾಗ್ಯವಂತೆ',
'ಭಾಗ್ಯವತಿ',
'ಭಾಗ್ಯಶಾಲಿ',
'ಭಾಗ್ಯಹೀನ',
'ಭಾಗ್ಯಹೀನೆ',
'ಭಾಗ್ಯೋದಯ',
'ಭಾಟಾಮಾರ್ಗ',
'ಭಾಟೆಮಾರ್ಗ',
'ಭಾಟ್ಟಮತ',
'ಭಾಣಸಿಗೆ',
'ಭಾದ್ರಪದ',
'ಭಾದ್ರಪದೆ',
'ಭಾನಗಡಿ',
'ಭಾನಗೇಡಿ',
'ಭಾನಾಮತಿ',
'ಭಾನುಮಂತ',
'ಭಾನುಮತಿ',
'ಭಾನುವಾರ',
'ಭಾಪುಗೊಳ್',
'ಭಾಮಂಡಲ',
'ಭಾಮಂಡಳ',
'ಭಾರಕರ್ತ',
'ಭಾರಕ್ಷಮ',
'ಭಾರತಿಕ',
'ಭಾರತೀಯ',
'ಭಾರತೇಯ',
'ಭಾರದಡಿ',
'ಭಾರದ್ವಾಜ',
'ಭಾರದ್ವಾಜಿ',
'ಭಾರಯಷ್ಟಿ',
'ಭಾರಯಿಸು',
'ಭಾರವಣೆ',
'ಭಾರವಹ',
'ಭಾರವಾಸಿ',
'ಭಾರವಾಹ',
'ಭಾರವಾಹಿ',
'ಭಾರವಿಸು',
'ಭಾರಸಂಖ್ಯೆ',
'ಭಾರಾಮಾರ್ಗ',
'ಭಾರಿಮಾಡು',
'ಭಾಲಪಟ್ಟ',
'ಭಾಲಾಂಬಕ',
'ಭಾವಕತ್ವ',
'ಭಾವಗಮ್ಯ',
'ಭಾವಗೀತ',
'ಭಾವಗೀತೆ',
'ಭಾವಚಿತ್ರ',
'ಭಾವಚೇಷ್ಟೆ',
'ಭಾವಜೀವಿ',
'ಭಾವಜ್ಞತೆ',
'ಭಾವತನ',
'ಭಾವನಾಮ',
'ಭಾವನಿಷ್ಠ',
'ಭಾವನೆಗೈ',
'ಭಾವಪುಷ್ಟಿ',
'ಭಾವಪೂರ್ಣ',
'ಭಾವಫಲ',
'ಭಾವಮೈದ',
'ಭಾವಮೋಕ್ಷ',
'ಭಾವಯಿತ್ರೀ',
'ಭಾವಯೋಗ',
'ಭಾವಲಿಂಗ',
'ಭಾವಲಿಪಿ',
'ಭಾವಲೇಶ್ಯೆ',
'ಭಾವವಾಚಿ',
'ಭಾವವಿದ',
'ಭಾವವಿದೆ',
'ಭಾವಶಾಂತಿ',
'ಭಾವಶುದ್ಧ',
'ಭಾವಶುದ್ಧಿ',
'ಭಾವಶೂನ್ಯ',
'ಭಾವಸಂಧಿ',
'ಭಾವಾತ್ಮಕ',
'ಭಾವಾಭಾಸ',
'ಭಾವಾವಿಷ್ಟ',
'ಭಾವಾವಿಷ್ಟೆ',
'ಭಾವಾವೇಗ',
'ಭಾವಾವೇಶ',
'ಭಾವಿಕತೆ',
'ಭಾವಿಕಾಲ',
'ಭಾವಿತಾತ್ಮ',
'ಭಾವುಕತೆ',
'ಭಾವೂರಾಯ',
'ಭಾವೋದ್ವಿಗ್ನ',
'ಭಾವೋದ್ವಿಗ್ನೆ',
'ಭಾವೋನ್ಮತ್ತ',
'ಭಾವೋನ್ಮತ್ತೆ',
'ಭಾವೋನ್ಮೇಷ',
'ಭಾಷಾಂತರ',
'ಭಾಷಾಪತ್ರ',
'ಭಾಷಾರೂಢಿ',
'ಭಾಷಾವಾರು',
'ಭಾಷಾವಿದ',
'ಭಾಷಾವಿದೆ',
'ಭಾಷಾಶಾಸ್ತ್ರ',
'ಭಾಷೆಕಾಱ',
'ಭಾಷೆಕೊಡು',
'ಭಾಷೆಗಳ್ಳ',
'ಭಾಷೆಗೆಡು',
'ಭಾಷೆಗೊಡು',
'ಭಾಷೆವಂತ',
'ಭಾಷ್ಯಕಾರ',
'ಭಾಹದರು',
'ಭಾಳನೇತ್ರ',
'ಭಾಳಪಟ್ಟ',
'ಭಾಳಲಿಪಿ',
'ಭಾಳಾಂತರ',
'ಭಾಳಾಕ್ಷರ',
'ಭಾಳೇಕ್ಷಣ',
'ಭಿಂಡಿಮಾಲ',
'ಭಿಂಡಿವಾಲ',
'ಭಿಂಡಿವಾಳ',
'ಭಿಂದಪಾಲ',
'ಭಿಕ್ಷಾಂದೇಹಿ',
'ಭಿಕ್ಷಾಟನ',
'ಭಿಕ್ಷಾಟನೆ',
'ಭಿಕ್ಷಾಪಾತ್ರ',
'ಭಿಕ್ಷಾಪಾತ್ರೆ',
'ಭಿಕ್ಷಾಶನ',
'ಭಿತ್ತಿಚಿತ್ರ',
'ಭಿತ್ತಿದೋಷ',
'ಭಿತ್ತಿಪತ್ರ',
'ಭಿದುರತೆ',
'ಭಿನ್ನಂಗೆಯ್',
'ಭಿನ್ನಕರ್ತೃ',
'ಭಿನ್ನಗಣ್ಣು',
'ಭಿನ್ನತಾನ',
'ಭಿನ್ನಪದ',
'ಭಿನ್ನಪಾಠ',
'ಭಿನ್ನಭಾವ',
'ಭಿನ್ನಮತ',
'ಭಿನ್ನಮುಖ',
'ಭಿನ್ನಯಿಸು',
'ಭಿನ್ನರಾಶಿ',
'ಭಿನ್ನರುಚಿ',
'ಭಿನ್ನವಿದ್ಯ',
'ಭಿನ್ನವಿಸು',
'ಭಿನ್ನವೃತ್ತ',
'ಭಿನ್ನಷಡ್ಜ',
'ಭಿನ್ನೋದರ',
'ಭಿರಿಂಗನೆ',
'ಭಿಲ್ಲಪಲ್ಲಿ',
'ಭಿಲ್ಲಪಳ್ಳಿ',
'ಭಿಷಗ್ವರ',
'ಭೀಕರಿಸು',
'ಭೀತಿಗೊಳು',
'ಭೀತಿಗೊಳ್ಳು',
'ಭೀತಿಯುಕ್ತ',
'ಭೀತಿಯುಕ್ತೆ',
'ಭೀತಿವಡು',
'ಭೀಮಕಾಯ',
'ಭೀಮಪಾಕ',
'ಭೀಮರಥಿ',
'ಭೀಮರಾಜ',
'ಭೀರುತನ',
'ಭುಂಕರಿಸು',
'ಭುಕ್ತಶಾಲೆ',
'ಭುಕ್ತಶೇಷ',
'ಭುಕ್ತಿಗೆಯ್',
'ಭುಕ್ತಿಗೆಯ್ಸು',
'ಭುಕ್ತೋಚ್ಛಿಷ್ಟ',
'ಭುಗಿಲನೆ',
'ಭುಗಿಲಿಡಿ',
'ಭುಗಿಲಿಡು',
'ಭುಗಿಲೆನ್',
'ಭುಗಿಲೆನ್ನು',
'ಭುಗಿಲ್ಗರೆ',
'ಭುಜಂಗಮ',
'ಭುಜಂಗಾಕ್ಷಿ',
'ಭುಜಕೀರ್ತಿ',
'ಭುಜಪತ್ರ',
'ಭುಜಪತ್ರೆ',
'ಭುಜಬಲ',
'ಭುಜಬಲಿ',
'ಭುಜಬಳ',
'ಭುಜಭವ',
'ಭುಜಮಧ್ಯ',
'ಭುಜವೊಯ್',
'ಭುಜಶಿರ',
'ಭುದಿಂಗನೆ',
'ಭುಲತಾಪಿ',
'ಭುಲತಾಪೆ',
'ಭುಲ್ಲಯಿಸು',
'ಭುಲ್ಲವಣಿ',
'ಭುಲ್ಲವಣೆ',
'ಭುಲ್ಲವಿಸು',
'ಭುವನಜ',
'ಭುವರ್ಲೋಕ',
'ಭುಸುಗುಟ್ಟು',
'ಭುಸುಗುಡು',
'ಭೂಔಷ್ಣಿಕ',
'ಭೂಕಂದಾಯ',
'ಭೂಕಂಪನ',
'ಭೂಕಡವ',
'ಭೂಖರ್ಜೂರ',
'ಭೂಗುಗ್ಗಳ',
'ಭೂಗುಗ್ಗುಳು',
'ಭೂಗೋಚರ',
'ಭೂಚರತ್ವ',
'ಭೂಚ್ಛೇದಕ',
'ಭೂತಕಾಲ',
'ಭೂತಕೇಶ',
'ಭೂತಕೇಶಿ',
'ಭೂತಕೋಲ',
'ಭೂತಗಣ',
'ಭೂತಗಾಜು',
'ಭೂತಚೇಷ್ಟೆ',
'ಭೂತದಯ',
'ಭೂತದಯೆ',
'ಭೂತದೇಹಿ',
'ಭೂತಧಾತ್ರಿ',
'ಭೂತನಾಥ',
'ಭೂತನ್ಯೂನ',
'ಭೂತಪತಿ',
'ಭೂತಪದಿ',
'ಭೂತಪುಣ್ಯ',
'ಭೂತಪೂಜೆ',
'ಭೂತಬಲಿ',
'ಭೂತಭಾಗ್ಯ',
'ಭೂತಯಜ್ಞ',
'ಭೂತರೂಪ',
'ಭೂತಲೀಲೆ',
'ಭೂತವತಿ',
'ಭೂತವಾದಿ',
'ಭೂತವಾಸ',
'ಭೂತವೇಶಿ',
'ಭೂತವೇಷಿ',
'ಭೂತಶಂಕೆ',
'ಭೂತಶುದ್ಧಿ',
'ಭೂತಸಿದ್ಧಿ',
'ಭೂತಸ್ಥಾನ',
'ಭೂತಹಿತ',
'ಭೂತಾಂಕುಶ',
'ಭೂತಾಂಜನ',
'ಭೂತಾಕಾರ',
'ಭೂತಾಗ್ರಣಿ',
'ಭೂತಾಮರ',
'ಭೂತಾವಾಸ',
'ಭೂತಿಪಟ್ಟ',
'ಭೂತೊಳಸಿ',
'ಭೂದಿವಿಜ',
'ಭೂಭಾರಕ',
'ಭೂಭ್ರಮಣ',
'ಭೂಮಂಡಲ',
'ಭೂಮಂಡಳ',
'ಭೂಮಾಲೀಕ',
'ಭೂಮಿಕಂಪ',
'ಭೂಮಿಗೃಹ',
'ಭೂಮಿಚ್ಛಿದ್ರ',
'ಭೂಮಿರುಹ',
'ಭೂಮಿಸ್ಪೃಶ',
'ಭೂಯೋಪಾಧ್ಯ',
'ಭೂಯೋವಿದ್ಯ',
'ಭೂರಿಗುಡು',
'ಭೂರಿಫೇನ',
'ಭೂರಿಮಾಯ',
'ಭೂರ್ಜತ್ವಕ್ಕು',
'ಭೂರ್ಜಪತ್ರ',
'ಭೂವಲಯ',
'ಭೂವಳಯ',
'ಭೂವಿಜ್ಞಾನ',
'ಭೂವಿಜ್ಞಾನಿ',
'ಭೂಶೋಧನೆ',
'ಭೂಷಣಾಂಗ',
'ಭೂಸಕ್ಕರೆ',
'ಭೂಸವೆತ',
'ಭೂಸಾರತೆ',
'ಭೂಸ್ಥಾಪನೆ',
'ಭೃಂಗಗುಣ',
'ಭೃಂಗರಾಜ',
'ಭೃಂಗವಲ್ಲಿ',
'ಭೃಂಗಾಲಕ',
'ಭೃಂಗಾಳಕ',
'ಭೃಂಗಿರಿಟಿ',
'ಭೃಗುವಾರ',
'ಭೃತವಂಶ',
'ಭೃತಿಭುಜ',
'ಭೃತ್ಯಭಾವ',
'ಭೃತ್ಯಾಚಾರ',
'ಭೃತ್ಯಾಚಾರಿ',
'ಭೇಕಾಸನ',
'ಭೇದಂಬಡು',
'ಭೇದಂಬಡೆ',
'ಭೇದಗೆಡು',
'ಭೇದತಂತ್ರ',
'ಭೇದದೃಷ್ಟಿ',
'ಭೇದಬುದ್ಧಿ',
'ಭೇದಭಾವ',
'ಭೇದಶ್ರುತಿ',
'ಭೇದಾಭೇದ',
'ಭೇದಿಸೊಪ್ಪು',
'ಭೇದೋಪಾಯ',
'ಭೇನಚೂಡ',
'ಭೇಶಚೂಡ',
'ಭೈಕ್ಷಶುದ್ಧಿ',
'ಭೈತ್ರಕಾಱ',
'ಭೈಷಜ್ಯಕ',
'ಭೋಂಕರಿಸು',
'ಭೋಗಪಟ್ಟಿ',
'ಭೋಗಪಟ್ಟೆ',
'ಭೋಗಮಾನ್ಯ',
'ಭೋಗರತ್ನ',
'ಭೋಗಾಭೋಗ',
'ಭೋಗಿಭೋಗ',
'ಭೋಗ್ಯಡವು',
'ಭೋಗ್ಯದಾರ',
'ಭೋಗ್ಯಪತ್ರ',
'ಭೋಜಕತ್ವ',
'ಭೋಜನಾಂಗ',
'ಭೋಜಪತ್ರ',
'ಭೋಜಪತ್ರೆ',
'ಭೋಷವಂತ',
'ಭೋಷವಂತೆ',
'ಭೋಳೆತನ',
'ಭೌಗೋಳಿಕ',
'ಭೌತವಸ್ತು',
'ಭೌತವಾದ',
'ಭೌತವಾದಿ',
'ಭೌತಶಾಸ್ತ್ರ',
'ಭೌತಿಕತೆ',
'ಭೌಮಚಾರಿ',
'ಭೌಮವಾರ',
'ಭೌಮಾಷ್ಟಮಿ',
'ಭೌಮಿಕತೆ',
'ಭ್ಯವಹಾರ',
'ಭ್ರಮರಕ',
'ಭ್ರಮಿತನ',
'ಭ್ರಮಿಯಿಸು',
'ಭ್ರಮೆಗವಿ',
'ಭ್ರಮೆಗೊಳ್',
'ಭ್ರಮೆಗೊಳ್ಳು',
'ಭ್ರಷ್ಟಗೊಳ್ಳು',
'ಭ್ರಷ್ಟಾಚಾರ',
'ಭ್ರಷ್ಟಾಚಾರಿ',
'ಭ್ರಷ್ಟೆಬ್ಬಿಸು',
'ಭ್ರಾಂತಿಗೊಳ್',
'ಭ್ರಾಂತಿಗೊಳ್ಳು',
'ಭ್ರಾಂತಿಜನ್ಯ',
'ಭ್ರಾಂತಿಯೋಗ',
'ಭ್ರಾಂತಿವಡೆ',
'ಭ್ರಾಂತಿವಿಡಿ',
'ಭ್ರಾಂತುಮಾರಿ',
'ಭ್ರಾಂತುವಿಡಿ',
'ಭ್ರಾತೃರೇಖೆ',
'ಭ್ರಾಮಕಾಸ್ತ್ರ',
'ಭ್ರಾಮರಿಸು',
'ಭ್ರೂಣಶಾಸ್ತ್ರ',
'ಭ್ರೂಣಹತ್ಯ',
'ಭ್ರೂಣಹತ್ಯೆ',
'ಭ್ರೂಭ್ರುಕುಟಿ',
'ಭ್ರೂವಿಕ್ಷೇಪ',
'ಭ್ರೂವಿಭ್ರಮ',
'ಭ್ರೂವಿಲಾಸ',
'ಮಂಕುಕವಿ',
'ಮಂಕುತನ',
'ಮಂಕುದಿಣ್ಣೆ',
'ಮಂಕುಬಡಿ',
'ಮಂಕುಬೂದಿ',
'ಮಂಕುಮಳೆ',
'ಮಂಕುವೊಯ್',
'ಮಂಕುಹಿಡಿ',
'ಮಂಗಚೇಷ್ಟೆ',
'ಮಂಗತನ',
'ಮಂಗಮಾಯ',
'ಮಂಗಮಾರಿ',
'ಮಂಗಮುಷ್ಟಿ',
'ಮಂಗರೋಳಿ',
'ಮಂಗಲ್ಯಕ',
'ಮಂಗಳತೆ',
'ಮಂಗಳಾಂಗ',
'ಮಂಗಳಾಂಗಿ',
'ಮಂಗಳ್ಯಕ',
'ಮಂಗಾಟಿಕೆ',
'ಮಂಗಾಡಿಸು',
'ಮಂಚಟಿಕೆ',
'ಮಂಚಪತ್ರಿ',
'ಮಂಚಲ್ಪಿಡಿ',
'ಮಂಚಲ್ವಿಡಿ',
'ಮಂಚಿಟಿಕೆ',
'ಮಂಚಿಟಿಗೆ',
'ಮಂಚಿಪತ್ರ',
'ಮಂಚಿಪತ್ರೆ',
'ಮಂಜರಿಕೆ',
'ಮಂಜರಿಗ',
'ಮಂಜರಿಗೆ',
'ಮಂಜರಿಸು',
'ಮಂಜಳಿಕೆ',
'ಮಂಜಳೆಣ್ಣೆ',
'ಮಂಜಿಟಿಗೆ',
'ಮಂಜಿಟ್ಟಿಗೆ',
'ಮಂಜಿಡಿಕೆ',
'ಮಂಜಿಪತ್ರೆ',
'ಮಂಜಿಷ್ಟಿಗೆ',
'ಮಂಜೀರಕ',
'ಮಂಜೀರಿಕೆ',
'ಮಂಜುಗಡ್ಡೆ',
'ಮಂಜುಗಣ್ಣು',
'ಮಂಜುಗಾಲ',
'ಮಂಜುಗಿರಿ',
'ಮಂಜುಗೆಡ್ಡೆ',
'ಮಂಜುಗೈಯ',
'ಮಂಜುಘೋಷ',
'ಮಂಜುಘೋಷೆ',
'ಮಂಜುಪತ್ರೆ',
'ಮಂಜುಬೆಟ್ಟ',
'ಮಂಜುಲಾಸ್ಯ',
'ಮಂಜುವೆಟ್ಟ',
'ಮಂಜುವೆಟ್ಟು',
'ಮಂಜುಹುಲ್ಲು',
'ಮಂಜುಳತೆ',
'ಮಂಜೂರಾತಿ',
'ಮಂಜೂಷಿಕೆ',
'ಮಂಟಪಲು',
'ಮಂಟಬೆಕ್ಕು',
'ಮಂಟವಲ್',
'ಮಂಡಕತ್ತಿ',
'ಮಂಡಗಳ್ಳಿ',
'ಮಂಡಣಿಸು',
'ಮಂಡಧೂಪ',
'ಮಂಡರಳು',
'ಮಂಡಲಕ',
'ಮಂಡಲಾಗ್ರ',
'ಮಂಡಲಿಕ',
'ಮಂಡಲಿಸು',
'ಮಂಡಲೀಕ',
'ಮಂಡಲೇಶ',
'ಮಂಡವಳ್ಳಿ',
'ಮಂಡವಿಗೆ',
'ಮಂಡಸೀಗೆ',
'ಮಂಡಳಾಂಕ',
'ಮಂಡಳಾಗ್ರ',
'ಮಂಡಳಿಕ',
'ಮಂಡಳಿಸು',
'ಮಂಡಳೀಕ',
'ಮಂಡಿಗಾಲು',
'ಮಂಡಿಚಡ್ಡಿ',
'ಮಂಡೀಚಲು',
'ಮಂಡೆಕತೆ',
'ಮಂಡೆಕಾಗೆ',
'ಮಂಡೆಗೈಯ',
'ಮಂಡೆದೂಗು',
'ಮಂಡೆದೋಱು',
'ಮಂಡೆನೋವು',
'ಮಂಡೆಬೇನೆ',
'ಮಂಡೆಮಣಿ',
'ಮಂಡೆಮಾರಿ',
'ಮಂಡೆವಣಿ',
'ಮಂಡೆವೇನೆ',
'ಮಂಡೆಶೂಲೆ',
'ಮಂಡೆಸೀಗೆ',
'ಮಂಡೆಸುತ್ತು',
'ಮಂಡೆಹುಣ್ಣು',
'ಮಂಡೋದಕ',
'ಮಂತಣಗೈ',
'ಮಂತರಿಸು',
'ಮಂತುಗೋಲು',
'ಮಂತೇಹುಳಿ',
'ಮಂತ್ರಕರ್ತ',
'ಮಂತ್ರಗಾರ',
'ಮಂತ್ರಗಾರ್ತಿ',
'ಮಂತ್ರಗುಪ್ತಿ',
'ಮಂತ್ರಗೋಪ್ಯ',
'ಮಂತ್ರಘೋಷ',
'ಮಂತ್ರಜಲ',
'ಮಂತ್ರಜಾಪ್ಯ',
'ಮಂತ್ರದಂಡ',
'ಮಂತ್ರದಾತ',
'ಮಂತ್ರದೀಕ್ಷೆ',
'ಮಂತ್ರದ್ರಷ್ಟ',
'ಮಂತ್ರಪಿಂಡ',
'ಮಂತ್ರಪುಷ್ಪ',
'ಮಂತ್ರಪೂತ',
'ಮಂತ್ರಪೂತೆ',
'ಮಂತ್ರಮುಕ್ತ',
'ಮಂತ್ರಮುಗ್ಧ',
'ಮಂತ್ರಮುಗ್ಧೆ',
'ಮಂತ್ರಮೂರ್ತಿ',
'ಮಂತ್ರಯೋಗ',
'ಮಂತ್ರವಾದ',
'ಮಂತ್ರವಾದಿ',
'ಮಂತ್ರವಿದ',
'ಮಂತ್ರವಿದೆ',
'ಮಂತ್ರವೈದ್ಯ',
'ಮಂತ್ರಶಕ್ತಿ',
'ಮಂತ್ರಶಾಲೆ',
'ಮಂತ್ರಸಾನಿ',
'ಮಂತ್ರಸಿದ್ಧ',
'ಮಂತ್ರಸಿದ್ದಿ',
'ಮಂತ್ರಾಕ್ಷತೆ',
'ಮಂತ್ರಾಕ್ಷರ',
'ಮಂತ್ರಾಗಮ',
'ಮಂತ್ರಾಗಾರ',
'ಮಂತ್ರಾಲಯ',
'ಮಂತ್ರಾವಾಸ',
'ಮಂತ್ರಿತನ',
'ಮಂತ್ರೋದಕ',
'ಮಂದಕಾಲಿ',
'ಮಂದಗತಿ',
'ಮಂದಗಳ್ಳಿ',
'ಮಂದಗಾತಿ',
'ಮಂದಗಾಮಿ',
'ಮಂದತನ',
'ಮಂದದೋಷ',
'ಮಂದಧೂಪ',
'ಮಂದಫಲ',
'ಮಂದಬುದ್ಧಿ',
'ಮಂದಭಾಗ್ಯ',
'ಮಂದಭಾಗ್ಯೆ',
'ಮಂದಮತಿ',
'ಮಂದಯಾನ',
'ಮಂದಯಿಸು',
'ಮಂದರಾಗ',
'ಮಂದಲಿಗೆ',
'ಮಂದಲಿಸು',
'ಮಂದವಾರ',
'ಮಂದವಿಸು',
'ಮಂದವೇಗ',
'ಮಂದವೇಗಿ',
'ಮಂದಸೀಗೆ',
'ಮಂದಸ್ಮಿತ',
'ಮಂದಸ್ಮಿತೆ',
'ಮಂದಸ್ಮೇರ',
'ಮಂದಹಾಸ',
'ಮಂದಾಕಿನಿ',
'ಮಂದಾಕ್ರಾಂತ',
'ಮಂದಾನಿಲ',
'ಮಂದಾನಿಳ',
'ಮಂದಾರಿಕ',
'ಮಂದಾಸನ',
'ಮಂದಿವಾಳ',
'ಮಂದಿವಾಳ್ಕೆ',
'ಮಂದೀಭೂತ',
'ಮಂದುರಿಗ',
'ಮಂದೆವಳ',
'ಮಂದೆವಾಳ',
'ಮಕಮಕ',
'ಮಕಮಲ್ಲು',
'ಮಕರಂದ',
'ಮಕರಂದಿ',
'ಮಕರಾಂಕ',
'ಮಕರಿಕೆ',
'ಮಕರಿಣಿ',
'ಮಕರಿಸು',
'ಮಕರೇಶ',
'ಮಕಾಬಲಿ',
'ಮಕ್ಕಾಮಕ್ಕಿ',
'ಮಕ್ಕಿಗದ್ದೆ',
'ಮಕ್ಕಿಭೂಮಿ',
'ಮಕ್ಕುಹಿಡಿ',
'ಮಕ್ತೆದಾರ',
'ಮಕ್ತೆಹಣ',
'ಮಕ್ತೇದಾರ',
'ಮಕ್ಷುರಕ',
'ಮಖಧ್ವಂಸಿ',
'ಮಖಮಲ್ಲು',
'ಮಖಶಾಲೆ',
'ಮಖಶಿಖಿ',
'ಮಗಂಬಡೆ',
'ಮಗದೊಂದು',
'ಮಗದೊಮ್ಮೆ',
'ಮಗಮಗ',
'ಮಗರಿಗೆ',
'ಮಗರೂರಿ',
'ಮಗಿಮಾವು',
'ಮಗುಗೊಲೆ',
'ಮಗುಚುಕೈ',
'ಮಗುದೊಂದು',
'ಮಗುದೊಮ್ಮೆ',
'ಮಗುಳಿಚು',
'ಮಗ್ಗದೆರೆ',
'ಮಗ್ಗದೆಱೆ',
'ಮಗ್ಗುಲಿಕ್ಕು',
'ಮಗ್ಗುಲಿಸು',
'ಮಗ್ಗುಲೂರು',
'ಮಘಮಘ',
'ಮಚಾಯಿಸು',
'ಮಚ್ಚಗಾಱ',
'ಮಚ್ಚರಿಗ',
'ಮಚ್ಚರಿಸು',
'ಮಚ್ಚುಕತ್ತಿ',
'ಮಚ್ಚುಗತ್ತಿ',
'ಮಚ್ಚುಗಾರ',
'ಮಚ್ಚುಗುಡು',
'ಮಚ್ಚುಗೊಡು',
'ಮಚ್ಚುನುಡಿ',
'ಮಚ್ಚುವಡೆ',
'ಮಚ್ಚುವೆಱು',
'ಮಜಕೂರು',
'ಮಜಬೂತು',
'ಮಜಲೀಸು',
'ಮಜೆದಾರ',
'ಮಜ್ಜಾಸಾರ',
'ಮಟಪತಿ',
'ಮಟಬೆಕ್ಕು',
'ಮಟಮಟ',
'ಮಟಮಾಯ',
'ಮಟಮಾಯೆ',
'ಮಟಾಮಾಯ',
'ಮಟ್ಟಕೋಲು',
'ಮಟ್ಟಗೋಲು',
'ಮಟ್ಟತರ',
'ಮಟ್ಟಮೀರು',
'ಮಟ್ಟಮೀಱು',
'ಮಟ್ಟಯಿಸು',
'ಮಟ್ಟಿಕಾಗೆ',
'ಮಟ್ಟಿಮಾವು',
'ಮಟ್ಟುಮೀರು',
'ಮಟ್ಟುಮೀಱು',
'ಮಟ್ಟೆಗಟ್ಟು',
'ಮಟ್ಟೆದೋಱು',
'ಮಟ್ಟೆಸುಂಕ',
'ಮಠದೆಱೆ',
'ಮಠಪತಿ',
'ಮಠವೃತ್ತಿ',
'ಮಠಾಧೀಶ',
'ಮಠ್ಯತಾಳ',
'ಮಡಕಾಲ್',
'ಮಡಗಾಲ್',
'ಮಡಗಿಡು',
'ಮಡಗಿಸು',
'ಮಡಗೂಳ್',
'ಮಡಗೂೞ್',
'ಮಡಮುರಿ',
'ಮಡಲಿಡು',
'ಮಡಲಿಱಿ',
'ಮಡಲ್ಗೊಳ್',
'ಮಡವಾಯ್',
'ಮಡಸ್ತನ',
'ಮಡಿಕಟ್ಟು',
'ಮಡಿಕೆಗೈ',
'ಮಡಿಕೋಲು',
'ಮಡಿಗದ್ದೆ',
'ಮಡಿಗಿಂಟ',
'ಮಡಿಗೊಳು',
'ಮಡಿಯಿಸು',
'ಮಡಿಯೊತ್ತು',
'ಮಡಿಲಕ್ಕಿ',
'ಮಡಿವಂತ',
'ಮಡಿವಂತೆ',
'ಮಡಿವರ್ಗ',
'ಮಡಿವಸೆ',
'ಮಡಿವಳ',
'ಮಡಿವಳ್ತಿ',
'ಮಡಿವಳ್ಳ',
'ಮಡಿವಾಸು',
'ಮಡಿವಾಳ',
'ಮಡಿಸೀರೆ',
'ಮಡುಗಟ್ಟು',
'ಮಡುಗೊರ್ವು',
'ಮಡುಗೊಳ್',
'ಮಡುಗೊಳು',
'ಮಡುಗೊಳ್ಳು',
'ಮಡುಬೇವು',
'ಮಡುವಾಯಿ',
'ಮಡುಹಿಸು',
'ಮಡೆಗೂೞ್',
'ಮಡೆಭೂಮಿ',
'ಮಡೆಹಗ್ಗ',
'ಮಡೆಹಬ್ಬ',
'ಮಡ್ಡತನ',
'ಮಡ್ಡಿಚಕ್ಕೆ',
'ಮಡ್ಡಿತನ',
'ಮಡ್ಡಿಧೂಪ',
'ಮಡ್ಡಿಬನ್ನೆ',
'ಮಡ್ಡಿಹಂಬು',
'ಮಣಕಾಲು',
'ಮಣಮಣ',
'ಮಣಮಣಿ',
'ಮಣಮುಂಡೆ',
'ಮಣಲ್ದಿಂಟೆ',
'ಮಣಲ್ದಿಣ್ಣೆ',
'ಮಣಿಕಟ್ಟು',
'ಮಣಿಕಣ್ಣು',
'ಮಣಿಕಾರ',
'ಮಣಿಕಾಲ್',
'ಮಣಿಕಾಲು',
'ಮಣಿಕೂಟ',
'ಮಣಿಗಂಟು',
'ಮಣಿಗಟ್ಟು',
'ಮಣಿಗಣ್ಣ',
'ಮಣಿಗರ್ಭೆ',
'ಮಣಿಗಲ್ಲು',
'ಮಣಿಗಾರ',
'ಮಣಿಗಾರ್ತಿ',
'ಮಣಿಗಾಱ',
'ಮಣಿಗುಣ',
'ಮಣಿತುತ್ತಿ',
'ಮಣಿತೊಂಡೆ',
'ಮಣಿದೊಂಡೆ',
'ಮಣಿಪಾದ',
'ಮಣಿಪೂರ',
'ಮಣಿಬಂಧ',
'ಮಣಿಬಳ್ಳಿ',
'ಮಣಿಮಾಲೆ',
'ಮಣಿಯಿಸು',
'ಮಣಿಯೇಱು',
'ಮಣಿರಂಗ',
'ಮಣಿವಚ್ಚ',
'ಮಣಿವಾಳ',
'ಮಣಿವೆಸ',
'ಮಣಿಶಿಲೆ',
'ಮಣಿಶುಕ್ತಿ',
'ಮಣಿಸಿಲೆ',
'ಮಣಿಹಾಱ',
'ಮಣಿಹುಲ್ಲು',
'ಮಣಿಹೆಂಬೆ',
'ಮಣೆಗಾರ',
'ಮಣೆಗಾಱ',
'ಮಣೆಬಾಬು',
'ಮಣ್ಗಪ್ಪರ',
'ಮಣ್ಣಂಗಟ್ಟಿ',
'ಮಣ್ಣಡಿಕೆ',
'ಮಣ್ಣುಕೊಡು',
'ಮಣ್ಣುಗೂಡು',
'ಮಣ್ಣುಪಾಲು',
'ಮಣ್ಣುಮುಕ್ಕ',
'ಮಣ್ಣುಹಿಡಿ',
'ಮಣ್ಣುಹುಳ',
'ಮಣ್ಣುಹುಳು',
'ಮಣ್ಣುಳಿಗ',
'ಮಣ್ಣೂಡಿಸು',
'ಮಣ್ಮಣಸು',
'ಮಣ್ಮೞಿಸು',
'ಮತಂಗಜ',
'ಮತಕಟ್ಟೆ',
'ಮತಕ್ಷೇತ್ರ',
'ಮತಗಟ್ಟೆ',
'ಮತಚೀಟಿ',
'ಮತದಾನ',
'ಮತದಾರ',
'ಮತಪತ್ರ',
'ಮತಭೇದ',
'ಮತಭ್ರಷ್ಟ',
'ಮತಭ್ರಷ್ಟೆ',
'ಮತಲಪ್',
'ಮತಲಪು',
'ಮತಲಬ್',
'ಮತಲಬು',
'ಮತಾಂತರ',
'ಮತಾಂಧತೆ',
'ಮತಾಚಾರ',
'ಮತಿಗಿಡು',
'ಮತಿಗೆಡು',
'ಮತಿಗೇಡಿ',
'ಮತಿಜ್ಞಾನ',
'ಮತಿಭ್ರಷ್ಟ',
'ಮತಿಭ್ರಷ್ಟೆ',
'ಮತಿಮಂತ',
'ಮತಿಮಂದ',
'ಮತಿಮರೆ',
'ಮತಿಮಱೆ',
'ಮತಿವಂತ',
'ಮತಿವಂತೆ',
'ಮತಿವಿದ',
'ಮತಿವಿದೆ',
'ಮತಿಹೀನ',
'ಮತಿಹೀನೆ',
'ಮತೀಯತೆ',
'ಮತ್ತತನ',
'ಮತ್ತಮದ',
'ಮತ್ತಾಕ್ರೀಡ',
'ಮತ್ತಾಕ್ರೀಡೆ',
'ಮತ್ತಾಲಂಬ',
'ಮತ್ಸರಿಗ',
'ಮತ್ಸರಿಸು',
'ಮತ್ಸ್ಯಕೃಷಿ',
'ಮತ್ಸ್ಯಕ್ಷೇತ್ರ',
'ಮತ್ಸ್ಯನ್ಯಾಯ',
'ಮತ್ಸ್ಯಪಿತ್ತ',
'ಮತ್ಸ್ಯಬಂಧಿ',
'ಮತ್ಸ್ಯರೂಪ',
'ಮತ್ಸ್ಯರೇಖೆ',
'ಮತ್ಸ್ಯೋದ್ಯಮ',
'ಮಥನಿಸು',
'ಮಥಿತಾರ್ಥ',
'ಮಥಿಯಿಸು',
'ಮದಕಲ',
'ಮದಕಾರಿ',
'ಮದಗಂಧಿ',
'ಮದಗಡ',
'ಮದಗುಡು',
'ಮದಗೊಬ್ಬು',
'ಮದಜಲ',
'ಮದಜಳ',
'ಮದತೇಗ',
'ಮದನಕೈ',
'ಮದನಾಸ್ತ್ರ',
'ಮದನಿಕೆ',
'ಮದಮುಖ',
'ಮದರಂಗ',
'ಮದರಂಗಿ',
'ಮದರಸ',
'ಮದರಸಾ',
'ಮದರಾಸು',
'ಮದಲಿಂಗ',
'ಮದಲಿಂಗಿ',
'ಮದಲೇಖೆ',
'ಮದಲೋಲ',
'ಮದವಟ್ಟೆ',
'ಮದವಡೆ',
'ಮದವಳ್',
'ಮದವಳ',
'ಮದವಾದಿ',
'ಮದವಾರಿ',
'ಮದವೆಱು',
'ಮದವೇರು',
'ಮದವೇಱು',
'ಮದಸ್ಥಾನ',
'ಮದಾಂಧಕ',
'ಮದಾಂಧತೆ',
'ಮದಾತ್ಯಯ',
'ಮದಾಲಸ',
'ಮದಿಮಾಳು',
'ಮದಿರಾಕ್ಷಿ',
'ಮದುಮಗ',
'ಮದುಮನೆ',
'ಮದುರಿಕೆ',
'ಮದುರಿತು',
'ಮದುವಣ',
'ಮದುವಳ್',
'ಮದುವಳ',
'ಮದೆವಳ್',
'ಮದೋತ್ಸವ',
'ಮದೋದಕ',
'ಮದೋದ್ಧತ',
'ಮದೋದ್ಧತೆ',
'ಮದೋದ್ರಿಕ್ತ',
'ಮದೋದ್ರಿಕ್ತೆ',
'ಮದೋದ್ರೇಕ',
'ಮದೋದ್ರೇಕಿ',
'ಮದೋನ್ಮತ್ತ',
'ಮದೋನ್ಮತ್ತೆ',
'ಮದ್ದಲಿಸು',
'ಮದ್ದಳಿಗ',
'ಮದ್ದಳೆಗ',
'ಮದ್ದುಕತ್ತಿ',
'ಮದ್ದುಗಾರ',
'ಮದ್ದುಗಾರ್ತಿ',
'ಮದ್ಯಗೋಷ್ಠಿ',
'ಮದ್ಯಪಾನ',
'ಮದ್ಯಪಾಯಿ',
'ಮದ್ಯವ್ರತ',
'ಮದ್ಯಸಾರ',
'ಮದ್ಯಸೇವೆ',
'ಮಧುಕರ',
'ಮಧುಕರಿ',
'ಮಧುಕಾಕ್ಷಿ',
'ಮಧುಕೋಶ',
'ಮಧುಕ್ರಮ',
'ಮಧುಗೋಷ್ಠಿ',
'ಮಧುಗ್ರಂಥಿ',
'ಮಧುಘೃತ',
'ಮಧುಚಂದ್ರ',
'ಮಧುತೆಂಗು',
'ಮಧುದೂತ',
'ಮಧುದೂತಿ',
'ಮಧುದ್ರವ',
'ಮಧುದ್ರುಮ',
'ಮಧುನಾಶ',
'ಮಧುನಾಳ',
'ಮಧುಪರ್ಕ',
'ಮಧುಪರ್ಣಿ',
'ಮಧುಪಾತ್ರ',
'ಮಧುಪಾತ್ರೆ',
'ಮಧುಪಾನ',
'ಮಧುಪಾಯಿ',
'ಮಧುಪಿಚ',
'ಮಧುಪುಷ್ಪ',
'ಮಧುಪೂರ',
'ಮಧುಪ್ರಿಯ',
'ಮಧುಪ್ಲುತ',
'ಮಧುಫಲ',
'ಮಧುಭೇದ',
'ಮಧುಮಂಚ',
'ಮಧುಮತಿ',
'ಮಧುಮತ್ತ',
'ಮಧುಮತ್ತೆ',
'ಮಧುಮಾಸ',
'ಮಧುಮಿತ್ರ',
'ಮಧುಮೂತ್ರ',
'ಮಧುಮೇಹ',
'ಮಧುರಂಗ',
'ಮಧುರಂಗಿ',
'ಮಧುರಕ',
'ಮಧುರತೆ',
'ಮಧುರತ್ವ',
'ಮಧುರಸ',
'ಮಧುರಸೆ',
'ಮಧುರಿಕೆ',
'ಮಧುರಿಪು',
'ಮಧುವಲ್ಲಿ',
'ಮಧುವ್ರತ',
'ಮಧುಶಿಗ್ರು',
'ಮಧುಶ್ರೇಣಿ',
'ಮಧುಷ್ಠಿಲೆ',
'ಮಧುಷ್ಠೀವ',
'ಮಧುಸೇವೆ',
'ಮಧೂಚ್ಛಿಷ್ಟ',
'ಮಧೂಲಕ',
'ಮಧೂಲಿಕೆ',
'ಮಧ್ಯಂತರ',
'ಮಧ್ಯಂದಿನ',
'ಮಧ್ಯಗತ',
'ಮಧ್ಯಗತಿ',
'ಮಧ್ಯಗೇಹ',
'ಮಧ್ಯತ್ರಾಣ',
'ಮಧ್ಯದೇಶ',
'ಮಧ್ಯನಾಡಿ',
'ಮಧ್ಯಪ್ರಾಚ್ಯ',
'ಮಧ್ಯಬಿಂದು',
'ಮಧ್ಯಮತ್ವ',
'ಮಧ್ಯಮೀಯ',
'ಮಧ್ಯರಾತ್ರಿ',
'ಮಧ್ಯಲೋಕ',
'ಮಧ್ಯವರ್ತಿ',
'ಮಧ್ಯವೇಗ',
'ಮಧ್ಯವೇಗಿ',
'ಮಧ್ಯಸಂಚ',
'ಮಧ್ಯಸಂಚು',
'ಮಧ್ಯಸಂಧಿ',
'ಮಧ್ಯಸಂಧ್ಯೆ',
'ಮಧ್ಯಸ್ತಿಕೆ',
'ಮಧ್ಯಸ್ಥತೆ',
'ಮಧ್ಯಸ್ಥಿಕೆ',
'ಮಧ್ಯಾಜಿರ',
'ಮಧ್ವಮತ',
'ಮಧ್ವಾಸವ',
'ಮನ್‍ಸಬ್',
'ಮನಂಕಿಡು',
'ಮನಂಗಲ್',
'ಮನಂಗಲಿ',
'ಮನಂಗಾಣ್',
'ಮನಂಗಾಪು',
'ಮನಂಗಿಡು',
'ಮನಂಗುಂದು',
'ಮನಂಗುಡು',
'ಮನಂಗೂಡು',
'ಮನಂಗೆಡು',
'ಮನಂಗೆಯ್',
'ಮನಂಗೊಡು',
'ಮನಂಗೊಳ್',
'ಮನಂದಣಿ',
'ಮನಂದರ್',
'ಮನಂದೀವು',
'ಮನಂದೆಗೆ',
'ಮನಂದೋಱು',
'ಮನಂಬಡು',
'ಮನಂಬರ್',
'ಮನಂಬಸು',
'ಮನಂಬಿಡು',
'ಮನಂಬೀಱು',
'ಮನಂಬುಗು',
'ಮನಂಬೊಯ್',
'ಮನಂಬೊರೆ',
'ಮನಂಮಿಗು',
'ಮನಃಕಂಪ',
'ಮನಃಕ್ಲೇಶ',
'ಮನಃಕ್ಷತ',
'ಮನಃಕ್ಷತೆ',
'ಮನಃಕ್ಷೋಭೆ',
'ಮನಃಖೇದ',
'ಮನಃಪ್ರಿಯ',
'ಮನಃಪ್ರಿಯೆ',
'ಮನಃಸಾಕ್ಷಿ',
'ಮನಕತ',
'ಮನಗಲಿ',
'ಮನಗಳ್ಳ',
'ಮನಗಾಣ್',
'ಮನಗಾಣು',
'ಮನಗುಂದು',
'ಮನಗುಡು',
'ಮನಗುಣ',
'ಮನಗುದಿ',
'ಮನಗೂಡು',
'ಮನಗೊಡು',
'ಮನಗೊಳ್',
'ಮನತಣಿ',
'ಮನತರು',
'ಮನದಟ್ಟು',
'ಮನದಣಿ',
'ಮನದನ್ನ',
'ಮನದನ್ನೆ',
'ಮನದರ್',
'ಮನದೆಱೆ',
'ಮನಧರ್ಮ',
'ಮನನಿಸು',
'ಮನನೀಯ',
'ಮನಪೆಚ್ಚು',
'ಮನಪೆರ್ಚು',
'ಮನಬಿಚ್ಚು',
'ಮನಬೊಗು',
'ಮನಭಂಗ',
'ಮನಮಾನಿ',
'ಮನಮಾರ್',
'ಮನಮಾಱಿ',
'ಮನಮಿಗು',
'ಮನಮಿಡು',
'ಮನಮುಟ್ಟು',
'ಮನಮುರಿ',
'ಮನಮೆಚ್ಚು',
'ಮನಮೊಸೆ',
'ಮನವಾರ್',
'ಮನವಾರ್ತೆ',
'ಮನವಾೞ್ತೆ',
'ಮನವಿಕ್ಕು',
'ಮನವಿಡು',
'ಮನವಿಳಿ',
'ಮನವುಕ್ಕು',
'ಮನವುಬ್ಬು',
'ಮನವೂಱು',
'ಮನವೇಗ',
'ಮನವೊಂದು',
'ಮನವೊಪ್ಪು',
'ಮನವೊಲಿ',
'ಮನವೊಸೆ',
'ಮನವೋವು',
'ಮನಶ್ಶಾಸ್ತ್ರ',
'ಮನಶ್ಶಿಲೆ',
'ಮನಸಂಚು',
'ಮನಸರಿ',
'ಮನಸಲ್',
'ಮನಸಾರ್',
'ಮನಸಾರ',
'ಮನಸಾರೆ',
'ಮನಸಿಜ',
'ಮನಸುಬ',
'ಮನಸುಬೆ',
'ಮನಸೆಳೆ',
'ಮನಸೋಕ್ತ',
'ಮನಸೋಲ್',
'ಮನಸೋಲು',
'ಮನಸ್ಕಾರ',
'ಮನಸ್ತಾಪ',
'ಮನಸ್ಥಿತಿ',
'ಮನಸ್ಥೈರ್ಯ',
'ಮನಸ್ವಿನಿ',
'ಮನಸ್ಸರ್ವ',
'ಮನಸ್ಸಾಕ್ಷಿ',
'ಮನಹೀನ',
'ಮನಹೀನೆ',
'ಮನಹೇಡಿ',
'ಮನುಕುಲ',
'ಮನುಜತೆ',
'ಮನುಜತ್ವ',
'ಮನುಜಾತ',
'ಮನುಮತ',
'ಮನುಮಥ',
'ಮನುಮಾರ್ಗ',
'ಮನುವಂಶ',
'ಮನುಷ್ಯತ್ವ',
'ಮನುಸ್ಮೃತಿ',
'ಮನೆಕಟ್ಟು',
'ಮನೆಖರ್ಚು',
'ಮನೆಗಟ್ಟು',
'ಮನೆಗಡೆ',
'ಮನೆಗಾಪು',
'ಮನೆಗಾರ್ತಿ',
'ಮನೆಗೆಡು',
'ಮನೆತನ',
'ಮನೆತಪ್ಪು',
'ಮನೆತಾಣ',
'ಮನೆತುಂಬು',
'ಮನೆದಪ್ಪು',
'ಮನೆದಳ',
'ಮನೆದಾಣ',
'ಮನೆದುಂಬು',
'ಮನೆದೆಱೆ',
'ಮನೆದೈವ',
'ಮನೆದೊಂಡ',
'ಮನೆದೊತ್ತು',
'ಮನೆದೊೞ್ತು',
'ಮನೆಪಟ್ಟಿ',
'ಮನೆಪಾಠ',
'ಮನೆಮಗ',
'ಮನೆಮಾತು',
'ಮನೆಮುಟ್ಟು',
'ಮನೆಮೂಳ',
'ಮನೆಮೂಳಿ',
'ಮನೆಯಾಳ್',
'ಮನೆಯಾಳು',
'ಮನೆವಣ',
'ಮನೆವಾರ',
'ಮನೆವಾರ್ತೆ',
'ಮನೆವಾಳು',
'ಮನೆವಾಳ್ತೆ',
'ಮನೆವಾೞ್',
'ಮನೆವಾೞ್ತೆ',
'ಮನೆವುಗು',
'ಮನೆವೆಕ್ಕು',
'ಮನೆವೆಣ್',
'ಮನೆವೆಣ್ಣು',
'ಮನೆವೊಗು',
'ಮನೆಸುಂಕ',
'ಮನೆಹಣ',
'ಮನೆಹಾಳ',
'ಮನೆಹಾಳಿ',
'ಮನೆಹಾಳು',
'ಮನೆಹೊಗು',
'ಮನೋಗತ',
'ಮನೋಗತಿ',
'ಮನೋಗದ',
'ಮನೋಗುಪ್ತಿ',
'ಮನೋಗ್ಲಾನಿ',
'ಮನೋಜಯ',
'ಮನೋಜವ',
'ಮನೋಜಾಡ್ಯ',
'ಮನೋಜ್ಞತೆ',
'ಮನೋದಾಢ್ರ್ಯ',
'ಮನೋಧರ್ಮ',
'ಮನೋನ್ಮನಿ',
'ಮನೋಬಲ',
'ಮನೋಭಂಗ',
'ಮನೋಭವ',
'ಮನೋಭಾವ',
'ಮನೋಮುಕ್ತ',
'ಮನೋಮುಕ್ತಿ',
'ಮನೋಮುದ',
'ಮನೋಮೂರ್ತಿ',
'ಮನೋಯೋಗ',
'ಮನೋರಥ',
'ಮನೋರಮ',
'ಮನೋರಮೆ',
'ಮನೋರುಜೆ',
'ಮನೋರೋಗ',
'ಮನೋವೃತ್ತಿ',
'ಮನೋವೇಗ',
'ಮನೋವೇದ್ಯ',
'ಮನೋವ್ಯಥೆ',
'ಮನೋವ್ಯಾಧಿ',
'ಮನೋಹರ',
'ಮನೋಹರಿ',
'ಮನೋಹಾರಿ',
'ಮನ್ನಣೆಗೈ',
'ಮನ್ನೆಗಾಳೆ',
'ಮನ್ಯಪಣ',
'ಮನ್ಯಾಸ್ತಂಭ',
'ಮನ್ಯೂದ್ಗತ',
'ಮನ್ವಂತರ',
'ಮಬ್ಬಗಳ್ಳಿ',
'ಮಬ್ಬುಗಣ್ಣು',
'ಮಬ್ಬುಚರ',
'ಮಬ್ಬುವಿಡಿ',
'ಮಮಕಾರ',
'ಮಮತತ್ವ',
'ಮಮರೇಜು',
'ಮಮ್ಮಕ್ಕಳು',
'ಮಮ್ಮಗಳು',
'ಮಮ್ಮಲನೆ',
'ಮಮ್ಮುಳಿಕೆ',
'ಮಯಿಗಲಿ',
'ಮಯಿದುನ',
'ಮಯಿಭೋಗ',
'ಮಯಿಸಾಲ',
'ಮಯೂಖಕ',
'ಮಯೂರಕ',
'ಮಯೂರೋಲು',
'ಮಯ್ಲಿತೆಗೆ',
'ಮಯ್ಸೂರ್ನಾಡು',
'ಮರಂಗಡಿ',
'ಮರಂಬಡು',
'ಮರಕಟ',
'ಮರಕಟೆ',
'ಮರಕಟ್ಟು',
'ಮರಕತ',
'ಮರಕಬ್ಬು',
'ಮರಕಲ',
'ಮರಕಾಲ',
'ಮರಕಾಲು',
'ಮರಕಾಲ್ತಿ',
'ಮರಕಿಚ್ಚು',
'ಮರಕೆಸ',
'ಮರಕೊಡಿ',
'ಮರಕೋತಿ',
'ಮರಗಟ್ಟು',
'ಮರಗಡಿ',
'ಮರಗಡೆ',
'ಮರಗತ್ತಿ',
'ಮರಗಬ್ಬು',
'ಮರಗಯ್',
'ಮರಗಸ',
'ಮರಗಳ್ಳಿ',
'ಮರಗಾಡು',
'ಮರಗಾಣ',
'ಮರಗಾಲ್',
'ಮರಗಾಲು',
'ಮರಗಾಳಿ',
'ಮರಗಿಚ್ಚು',
'ಮರಗಿಣಿ',
'ಮರಗಿವಿ',
'ಮರಗುಬ್ಬಿ',
'ಮರಗುಲಿ',
'ಮರಗುಳಿ',
'ಮರಗೂಡು',
'ಮರಗೂಳ',
'ಮರಗೂೞ',
'ಮರಗೇಡಿ',
'ಮರಗೊಂಬು',
'ಮರಗೊನೆ',
'ಮರಗೋಡು',
'ಮರಚಟ್ಟಿ',
'ಮರಚಲು',
'ಮರಚಿನ',
'ಮರತಾಬೆ',
'ಮರತುತ್ತಿ',
'ಮರದಾನಿ',
'ಮರನಾಯಿ',
'ಮರನೆಲ್',
'ಮರನೆಲ್ಲಿ',
'ಮರನ್ಮಾಲೆ',
'ಮರಬಾಳೆ',
'ಮರಬಿಲ್ಲು',
'ಮರಮಳೆ',
'ಮರಮಿಟ್ಲೆ',
'ಮರಮುಟ್ಟು',
'ಮರಮೊಗ',
'ಮರಲ್ವಿಲ್ಲ',
'ಮರವಜ್ರ',
'ಮರವಡು',
'ಮರವಡೆ',
'ಮರವಣೆ',
'ಮರವಳಿ',
'ಮರವಾಯ್',
'ಮರವಾಲೆ',
'ಮರವಾವು',
'ಮರವಾಳ್',
'ಮರವಾೞೆ',
'ಮರವಿಲ್',
'ಮರವುಟ್ಟು',
'ಮರವುಣಿ',
'ಮರವೆಸ',
'ಮರಸಣಿ',
'ಮರಸದ',
'ಮರಸರ್ಪ',
'ಮರಸಲ್ಲು',
'ಮರಸುತ್ತು',
'ಮರಸೌತೆ',
'ಮರಹಟ್ಟ',
'ಮರಹತ',
'ಮರಹತ್ತಿ',
'ಮರಹಾಟ',
'ಮರಹಾಲೆ',
'ಮರಹಾಸ್ತಿ',
'ಮರಳಚ್ಚು',
'ಮರಳಿಕೆ',
'ಮರಳಿಚು',
'ಮರಳಿಸು',
'ಮರಳೊತ್ತು',
'ಮರಳ್ಗಾಡು',
'ಮರಾಮತ್ತು',
'ಮರಾಲಕ',
'ಮರಿಗಂಚಿ',
'ಮರಿಮಗ',
'ಮರಿಮೊಮ್ಮ',
'ಮರಯಾದಿ',
'ಮರಿಯಾದೆ',
'ಮರಿಯಿಕ್ಕು',
'ಮರೀಚಿಕೆ',
'ಮರುಕಳಿ',
'ಮರುಕೂಳೆ',
'ಮರುಕೆದೆ',
'ಮರುಕ್ಷಣ',
'ಮರುಗಿಸು',
'ಮರುಗೆರ್ದೆ',
'ಮರಚಣ',
'ಮರುಚಲು',
'ಮರುಚ್ಚಾಪ',
'ಮರುಚ್ಛಿಲ್ಪಿ',
'ಮರುಜನ್ಮ',
'ಮರುಜ್ಜವ',
'ಮರುತಕ',
'ಮರುತಾಸ್ತ್ರ',
'ಮರುತ್ಕುಜ',
'ಮರುತ್ತ್ರಿಕ',
'ಮರುತ್ಪಥ',
'ಮರುತ್ಸಖ',
'ಮರುದಿನ',
'ಮರುದಿಷ್ಟ',
'ಮರುದ್ಗಣ',
'ಮರುದ್ಗಿರಿ',
'ಮರುದ್ದಿಶೆ',
'ಮರುದ್ವರ',
'ಮರುದ್ವಾದ್ಯ',
'ಮರುಧರೆ',
'ಮರುನುಡಿ',
'ಮರುನ್ನದಿ',
'ಮರುನ್ಮಾರ್ಗ',
'ಮರುನ್ಮಾಲೆ',
'ಮರುಪತ್ರ',
'ಮರುಪಾಠ',
'ಮರುಬಾಳು',
'ಮರುಬೆಳೆ',
'ಮರುಭೂಮಿ',
'ಮರುಮಾತು',
'ಮರುವಕ',
'ಮರುವರ್ಷ',
'ಮರುವಳಿ',
'ಮರುವಾಳು',
'ಮರುವುಟ್ಟು',
'ಮರುಸ್ಥಳ',
'ಮರುಹುಟ್ಟು',
'ಮರುಳಡೆ',
'ಮರುಳಯ್ಯ',
'ಮರುಳಾಟ',
'ಮರುಳಾಡು',
'ಮರುಳಾಣ್ಮ',
'ಮರುಳಿಕ್ಕು',
'ಮರುಳಿಸು',
'ಮರುಳುಗೈ',
'ಮರುಳುಣ್',
'ಮರುಳ್ಗೆಡೆ',
'ಮರುಳ್ಗೆಯ್',
'ಮರುಳ್ಗೊಳ್',
'ಮರುಳ್ತನ',
'ಮರುಳ್ಬೂದಿ',
'ಮರುಳ್ವೋಗು',
'ಮರೆಗರೆ',
'ಮರೆಗುಳಿ',
'ಮರೆಗೆಯ್',
'ಮರೆಗೊಡು',
'ಮರೆಗೊಳ್',
'ಮರೆಗೊಳು',
'ಮರೆಗೋಡು',
'ಮರೆದೆಗೆ',
'ಮರೆನುಡಿ',
'ಮರೆಪಡೆ',
'ಮರೆಬೀಳು',
'ಮರೆಮಾಚು',
'ಮರೆಮಾಜು',
'ಮರೆವಲೆ',
'ಮರೆವಿಡಿ',
'ಮರೆವೆಣ್',
'ಮರೆವೋಗು',
'ಮರೆಸಲ್',
'ಮರೆಹೊಗು',
'ಮರೆಹೋಗು',
'ಮರ್ಕಟಕ',
'ಮತ್ರ್ಯಜನ್ಮ',
'ಮತ್ರ್ಯದೇಹ',
'ಮತ್ರ್ಯಲೋಕ',
'ಮರ್ದನಿಗ',
'ಮರ್ದುಂಗೂೞ್',
'ಮರ್ದುಗೂೞ್',
'ಮರ್ದುಣಿಸು',
'ಮರ್ಮಘಾತ',
'ಮರ್ಮಭೇದ',
'ಮರ್ಮಭೇದಿ',
'ಮರ್ಮರಿಸು',
'ಮರ್ಮರೋಗ',
'ಮಮಸ್ಥಳ',
'ಮರ್ಮಸ್ಪರ್ಶಿ',
'ಮರ್ಮಾಹತ',
'ಮರ್ಯಾದಸ್ತ',
'ಮರ್ಯಾದಸ್ತೆ',
'ಮರ್ಯಾದಸ್ಥ',
'ಮರ್ಯಾದಸ್ಥೆ',
'ಮರ್ಯಾದಿತ',
'ಮಲಅಣ್ಣ',
'ಮಲಂಗಿಸು',
'ಮಲಕಟ್ಟು',
'ಮಲಕಿಸು',
'ಮಲಕುಟ',
'ಮಲಕುಹ',
'ಮಲಗಟ್ಟು',
'ಮಲಗದೆ',
'ಮಲಗಿಸು',
'ಮಲಗ್ರ್ರಸ್ತ',
'ಮಲಗ್ರ್ರಸ್ತೆ',
'ಮಲತಂಗಿ',
'ಮಲತಂದೆ',
'ಮಲತಮ್ಮ',
'ಮಲತಾಯ್',
'ಮಲತಾಯಿ',
'ಮಲತ್ರಯ',
'ಮಲದಂದೆ',
'ಮಲದಾಯ್',
'ಮಲದಾಯಿ',
'ಮಲದೇಹಿ',
'ಮಲಧರ',
'ಮಲಧಾರಿ',
'ಮಲನೀರು',
'ಮಲಬಾಧೆ',
'ಮಲಮಂಡಿ',
'ಮಲಮಗ',
'ಮಲಮಲ',
'ಮಲಮಲಂ',
'ಮಲಮಲಿ',
'ಮಲಮಲ್ಲು',
'ಮಲಮಾಸ',
'ಮಲಮೈತ್ರ',
'ಮಲಯಜ',
'ಮಲಯಾಳ',
'ಮಲರಗ',
'ಮಲರಿಸು',
'ಮಲರೋಗ',
'ಮಲರೋಗಿ',
'ಮಲರ್ವಿಲ್ಲು',
'ಮಲವೇಗ',
'ಮಲವೇಱು',
'ಮಲಶುದ್ಧಿ',
'ಮಲಶೂಲೆ',
'ಮಲಸೀಮೆ',
'ಮಲಸುತ್ತು',
'ಮಲಸುರಿ',
'ಮಲಸೂತ್ರ',
'ಮಲಹೆಗ್ಗೆ',
'ಮಲಹರ',
'ಮಲಹರಿ',
'ಮಲಹಾರಿ',
'ಮಲಹೊನ್ನೆ',
'ಮಲಾಪಹ',
'ಮಲಾಭ್ರಕ',
'ಮಲಾಮತ್',
'ಮಲಾಮತಿ',
'ಮಲಾಮತು',
'ಮಲಾಮತ್ತು',
'ಮಲಿನತೆ',
'ಮಲಿನತ್ವ',
'ಮಲಿಮ್ಲುಚ',
'ಮಲೀಮಸ',
'ಮಲುಮಲ',
'ಮಲೆಕೋಡು',
'ಮಲೆಗೇರು',
'ಮಲೆಜೇನು',
'ಮಲೆತರ್',
'ಮಲೆದುರ್ಗ',
'ಮಲೆನಾಡು',
'ಮಲೆನೆಲ್ಲಿ',
'ಮಲೆಯಾಳ',
'ಮಲೆಯಾಳಿ',
'ಮಲೆಯಾಳು',
'ಮಲೆಯಿಸು',
'ಮಲೆಸೇನ',
'ಮಲೆಸೌತೆ',
'ಮಲೆಹೆಗ್ಗೆ',
'ಮಲೇರಿಯ',
'ಮಲ್ಲಂತಿಗೆ',
'ಮಲ್ಲಕಂಭ',
'ಮಲ್ಲಕಳ',
'ಮಲ್ಲಖಂಡ',
'ಮಲ್ಲಗಂಟು',
'ಮಲ್ಲಗಂಬ',
'ಮಲ್ಲಗಂಭ',
'ಮಲ್ಲಗಚ್ಚೆ',
'ಮಲ್ಲಗದ್ದೆ',
'ಮಲ್ಲಗಳ',
'ಮಲ್ಲಗಾಸೆ',
'ಮಲ್ಲಗಿಳಿ',
'ಮಲ್ಲಣಿಸು',
'ಮಲ್ಲತುಂಬಿ',
'ಮಲ್ಲದಾಳ',
'ಮಲ್ಲಯುದ್ಧ',
'ಮಲ್ಲರಂಗ',
'ಮಲ್ಲವಱೆ',
'ಮಲ್ಲವಾತು',
'ಮಲ್ಲವಿದ್ಯೆ',
'ಮಲ್ಲವೋರ್',
'ಮಲ್ಲವೋರ್ಕೆ',
'ಮಲ್ಲಶಾಲೆ',
'ಮಲ್ಲಶ್ರಮ',
'ಮಲ್ಲಸಾಲೆ',
'ಮಲ್ಲಳಿಸು',
'ಮಲ್ಲಾಗರು',
'ಮಲ್ಲಾಮಲ್ಲಿ',
'ಮಲ್ಲಿಕಾಕ್ಷ',
'ಮಲ್ಲಿನಾಥ',
'ಮಲ್ಲಿನಾಥಿ',
'ಮಲ್ಲೋಗರ',
'ಮವಕುಫ್',
'ಮವಲಂಗ',
'ಮಶಕಾರಿ',
'ಮಶವಳ',
'ಮಶವಾಳ',
'ಮಷಾಲಚಿ',
'ಮಷಿವಿದ್ಯೆ',
'ಮಷೀಚಿತ್ರ',
'ಮಸಗಿಸು',
'ಮಸಜೀದ',
'ಮಸಜೀದಿ',
'ಮಸಣಬ್ಬೆ',
'ಮಸಣಿಗೆ',
'ಮಸಣಿಸು',
'ಮಸಮಸ',
'ಮಸಲತ್',
'ಮಸಲತಿ',
'ಮಸಲತ್ತು',
'ಮಸವರಿ',
'ಮಸಿಕಟ್ಟು',
'ಮಸಿಕಡ್ಡಿ',
'ಮಸಿಗಟ್ಟು',
'ಮಸಿದೌತಿ',
'ಮಸಿಪಾತ್ರೆ',
'ಮಸಿಬುಡ್ಡಿ',
'ಮಸಿವಣ್ಣ',
'ಮಸಿವಾಳ',
'ಮಸಿಹೆಣ್ಣು',
'ಮಸುರಿಗೆ',
'ಮಸುಳಿಸು',
'ಮಸೂರಿಕ',
'ಮಸೂರಿಕೆ',
'ಮಸೂರಿಗೆ',
'ಮಸೃಣಿತ',
'ಮಸೆಕಲ್',
'ಮಸೆಕಲ್ಲು',
'ಮಸೆಗಾಣ್',
'ಮಸೆಗೆಡು',
'ಮಸೆಗೊಳ್',
'ಮಸೆದಾಟ',
'ಮಸೆದಾಡು',
'ಮಸೆದೋಱು',
'ಮಸೆಯಿಸು',
'ಮಸೆವಡು',
'ಮಸೆವಾಳ',
'ಮಸೆವೆಱು',
'ಮಸ್ಸಿವಾಳ',
'ಮಸ್ಸೀವಾರ',
'ಮಸ್ಸೀವಾಲ',
'ಮಹಜರ್',
'ಮಹಜರು',
'ಮಹಜಿದಿ',
'ಮಹತಾಪು',
'ಮಹತ್ಕಾರ್ಯ',
'ಮಹತ್ತತ್ತ್ವ',
'ಮಹತ್ತಮ',
'ಮಹತ್ತರ',
'ಮಹತ್ತರಿ',
'ಮಹತ್ವತೆ',
'ಮಹನಿೀಯ',
'ಮಹನೀಯ',
'ಮಹನೀಯೆ',
'ಮಹಬೂಬಿ',
'ಮಹಮನೆ',
'ಮಹಮೇರು',
'ಮಹರಾಜ',
'ಮಹರಾಯ',
'ಮಹರ್ಧಿಕ',
'ಮಹರ್ಲೋಕ',
'ಮಹಸೂಲು',
'ಮಹಾಂಬುಜ',
'ಮಹಾಕಂದ',
'ಮಹಾಕಲ್ಪ',
'ಮಹಾಕಾರೆ',
'ಮಹಾಕಾಲ',
'ಮಹಾಕಾಲಿ',
'ಮಹಾಕಾವ್ಯ',
'ಮಹಾಕಾಳ',
'ಮಹಾಕಾಳಿ',
'ಮಹಾಕ್ರತು',
'ಮಹಾಕ್ಷಿತಿ',
'ಮಹಾಕ್ಷೋಣಿ',
'ಮಹಾಕ್ಷೋಭ',
'ಮಹಾಖರ್ವ',
'ಮಹಾಗಣ',
'ಮಹಾಗನಿ',
'ಮಹಾಗಾರೆ',
'ಮಹಾಗ್ರಹ',
'ಮಹಾಗ್ರೀವ',
'ಮಹಾಘನ',
'ಮಹಾಚೀನ',
'ಮಹಾಚೀನಿ',
'ಮಹಾಚೂರ್ಣ',
'ಮಹಾಜನ',
'ಮಹಜವ',
'ಮಹಾಜಾಲಿ',
'ಮಹಾತರು',
'ಮಹಾತಲ',
'ಮಹಾತಳ',
'ಮಹಾತುಮ',
'ಮಹಾತೇಜ',
'ಮಹಾತ್ಮಕ',
'ಮಹಾದಾಹ',
'ಮಹಾದುಃಖ',
'ಮಹಾದೇವ',
'ಮಹಾದೇವಿ',
'ಮಹಾಧ್ವಜ',
'ಮಹಾನಂದ',
'ಮಹಾನದ',
'ಮಹಾನದಿ',
'ಮಹಾನಸ',
'ಮಹಾನಾಟ್ಯ',
'ಮಹಾನಾಡು',
'ಮಹಾನಾವಿ',
'ಮಹಾನಿದ್ರೆ',
'ಮಹಾನಿಧಿ',
'ಮಹಾನಿಲ',
'ಮಹಾನೀಲ',
'ಮಹಾನೀಲಿ',
'ಮಹಾಪಥ',
'ಮಹಾಪದ್ಮ',
'ಮಹಾಪಾತ್ರೆ',
'ಮಹಾಪುಷ್ಪ',
'ಮಹಾಪೂರ',
'ಮಹಾಪ್ರಭು',
'ಮಹಾಪ್ರಾಣ',
'ಮಹಾಫಣಿ',
'ಮಹಾಫಲ',
'ಮಹಾಬಲ',
'ಮಹಾಬಲೆ',
'ಮಹಾಬಿಲ',
'ಮಹಾಬೇವು',
'ಮಹಾಭಾಗ',
'ಮಹಾಭೂತ',
'ಮಹಾಮಂತ್ರಿ',
'ಮಹಾಮನೆ',
'ಮಹಾಮಹ',
'ಮಹಾಮಾತ್ಯ',
'ಮಹಾಮಾತ್ರ',
'ಮಹಾಮಾರಿ',
'ಮಹಾಮುನಿ',
'ಮಹಾಮುಸ್ತೆ',
'ಮಹಾಮೆಕ್ಕೆ',
'ಮಹಾಮೇದ',
'ಮಹಾಮೇದೆ',
'ಮಹಾಮೇಧ',
'ಮಹಾಮೋಹ',
'ಮಹಾಯಜ್ಞ',
'ಮಹಾಯಾನ',
'ಮಹಾಯೋಗಿ',
'ಮಹಾರತ್ನ',
'ಮಹಾರಥ',
'ಮಹಾರಾಜ',
'ಮಹಾರಾಜ್ಞಿ',
'ಮಹಾರಾಣಿ',
'ಮಹಾರಾಯ',
'ಮಹಾರಾಯ್ತಿ',
'ಮಹಾರಾಷ್ಟ್ರ',
'ಮಹಾರಾಷ್ಟ್ರೀ',
'ಮಹಾರೂಕ',
'ಮಹಾರೌಪ್ಯ',
'ಮಹಾರ್ಬುದ',
'ಮಹಾಲಕ್ಷ್ಮಿ',
'ಮಹಾಲತೆ',
'ಮಹಾಲಯ',
'ಮಹಾಲಿಂಗ',
'ಮಹಾವಲಿ',
'ಮಹಾವಾಕ್ಯ',
'ಮಹಾವಾತ',
'ಮಹಾವಾತ್ಯೆ',
'ಮಹಾವಿದ್ಯೆ',
'ಮಹಾವಿಷ',
'ಮಹಾವಿಷ್ಣು',
'ಮಹಾವೀರ',
'ಮಹಾವೃಕ್ಷ',
'ಮಹಾವೃದ್ಧಿ',
'ಮಹಾಶಂಖ',
'ಮಹಾಶಯ',
'ಮಹಾಶಾಲಿ',
'ಮಹಾಶಾಳಿ',
'ಮಹಾಶೂದ್ರ',
'ಮಹಾಶೂದ್ರಿ',
'ಮಹಾಶ್ವೇತೆ',
'ಮಹಾಸತಿ',
'ಮಹಾಸಭೆ',
'ಮಹಾಸಹೆ',
'ಮಹಾಸ್ವಾಮಿ',
'ಮಹಿಗೊಟ್ಟೆ',
'ಮಹಿಚಕ್ರ',
'ಮಹಿತಳ',
'ಮಹಿಧರ',
'ಮಹಿಪಾಲ',
'ಮಹಿಭುಜ',
'ಮಹಿಶೂರು',
'ಮಹಿಷಾಕ್ಷ',
'ಮಹಿಷಾಕ್ಷಿ',
'ಮಹಿಷಿಕ',
'ಮಹಿಸೂರು',
'ಮಹೀಗೃಹ',
'ಮಹೀಚಕ್ರ',
'ಮಹೀಚರ',
'ಮಹೀತಲ',
'ಮಹೀತಳ',
'ಮಹೀಧರ',
'ಮಹೀಪತಿ',
'ಮಹೀಪಾಲ',
'ಮಹೀಬುಧ',
'ಮಹೀಭುಜ',
'ಮಹೀರುಹ',
'ಮಹೀಲತೆ',
'ಮಹೀಶೂರು',
'ಮಹೀಸುರ',
'ಮಹೀಸ್ಪೃಶ',
'ಮಹೇರಣೆ',
'ಮಹೇಶ್ವರ',
'ಮಹೇಶ್ವರಿ',
'ಮಹೋಕ್ಷಗ',
'ಮಹೋತ್ಸವ',
'ಮಹೋದಯ',
'ಮಹೋದಯೆ',
'ಮಹೋದರ',
'ಮಹೋದಲೆ',
'ಮಹೋದ್ಯಮ',
'ಮಹೋದ್ಯಮಿ',
'ಮಹೋದ್ಯೋಗ',
'ಮಹೋನ್ನತ',
'ಮಹೋನ್ನತಿ',
'ಮಹೌಷಧ',
'ಮಹೌಷಧಿ',
'ಮಳಮಳ',
'ಮಳಮಳಿ',
'ಮಳಯಜ',
'ಮಳಲತ್ತಿ',
'ಮಳಲ್ಗೊಳ',
'ಮಳಲ್ದಿಂಟೆ',
'ಮಳಿನತೆ',
'ಮಳಿನತ್ವ',
'ಮಳಿಮ್ಲುಚ',
'ಮಳೀಮಸ',
'ಮಳೆಗರೆ',
'ಮಳೆಗಱೆ',
'ಮಳೆಗಾಡು',
'ಮಳೆಗಾಲ',
'ಮಳೆನೀರು',
'ಮಳೆಬಿಲ್ಲು',
'ಮಳೆಮರ',
'ಮಳೆಮಾಡು',
'ಮಳೆಮೋಡ',
'ಮಳಿಯಂಗಿ',
'ಮಳಯಾಲ',
'ಮಳೆಯಾಳ',
'ಮಳೆಯಾಳಿ',
'ಮಳೆರಾಯ',
'ಮಳೆವನಿ',
'ಮಳೆವಿಲ್ಲು',
'ಮಳೆಹನಿ',
'ಮಳೆಹುಳು',
'ಮಳ್ಳತನ',
'ಮಳ್ಳುಬಳ್ಳಿ',
'ಮಱಕೆಂದು',
'ಮಱಪಿಕ್ಕು',
'ಮಱಪಿಡು',
'ಮಱಲಿಱಿ',
'ಮಱಲುಂದು',
'ಮಱವಡು',
'ಮಱವಣಿ',
'ಮಱಸುಂದು',
'ಮಱಸುಹ',
'ಮಱಸೊಂದು',
'ಮಱಹಿಕ್ಕು',
'ಮಱಹಿಡು',
'ಮಱಹಿಸು',
'ಮಱಹೊಂದು',
'ಮಱಿಮಗ',
'ಮಱಿಯಾನೆ',
'ಮಱಿವಾಲು',
'ಮಱುಕಳಿ',
'ಮಱುಕುಳಿ',
'ಮಱುಕೂಳೆ',
'ಮಱುಗಿಸು',
'ಮಱುಗುತ್ತ',
'ಮಱುಗುಹ',
'ಮಱುಚಲು',
'ಮಱುದಲೆ',
'ಮಱುದಿನ',
'ಮಱುಭವ',
'ಮಱುಮಾತು',
'ಮಱುಮೆಯ್',
'ಮಱುಮೊನೆ',
'ಮಱುವಂಕ',
'ಮಱುವಕ್ಕ',
'ಮಱುವಾತು',
'ಮಱುವಾಳು',
'ಮಱುವಾೞ್',
'ಮಱುವಾೞ್ತೆ',
'ಮಱುವಿಡಿ',
'ಮಱುವುಟ್ಟು',
'ಮಱುಹುಟ್ಟು',
'ಮಱೆಕಾಱ',
'ಮಱೆಗಡೆ',
'ಮಱೆಗಳೆ',
'ಮಱೆಗಾಣ್',
'ಮಱೆಗೆಯ್',
'ಮಱೆಗೇಳ್',
'ಮಱೆಗೊಳ್',
'ಮಱೆಗೋಟೆ',
'ಮಱೆಜಾಣ',
'ಮಱೆನುಡಿ',
'ಮಱೆಮಾಂಜು',
'ಮಱೆಮಾಜು',
'ಮಱೆಮಾತು',
'ಮಱೆಮೆಯ್',
'ಮಱೆಯಿಸು',
'ಮಱೆಯುಗು',
'ಮಱೆಯೇಱು',
'ಮಱೆಯೊಗು',
'ಮಱೆಯೊಡ್ಡು',
'ಮಱೆವಟ್ಟೆ',
'ಮಱೆವಡು',
'ಮಱೆವಾತು',
'ಮಱೆವಾರ',
'ಮಱೆವಾಳ್',
'ಮಱೆವಾಳಿ',
'ಮಱೆವಾೞ್',
'ಮಱೆವಿಡಿ',
'ಮಱೆವುಗು',
'ಮಱೆವೆಱು',
'ಮಱೆವೊಗು',
'ಮಱೆಹುಗು',
'ಮಱೆಹೊಗು',
'ಮೞಮೞ',
'ಮೞೆಗಾಲ',
'ಮೞೆನೀರ್',
'ಮೞೆವನಿ',
'ಮಾಂಕರಣೆ',
'ಮಾಂಕರಿಸು',
'ಮಾಂಗಲಿಕ',
'ಮಾಂಗಲ್ಯಕ',
'ಮಾಂಗಳಿಕ',
'ಮಾಂಟಬೆಕ್ಕು',
'ಮಾಂಡಲಿಕ',
'ಮಾಂಡಲೀಕ',
'ಮಾಂಡಳಿಕ',
'ಮಾಂಡಳೀಕ',
'ಮಾಂದಳಿರ್',
'ಮಾಂದಳಿರು',
'ಮಾಂದುರಿಗ',
'ಮಾಂಸಖಂಡ',
'ಮಾಂಸಗಣ್ಣು',
'ಮಾಂಸಗ್ರಂಥಿ',
'ಮಾಂಸಚ್ಛದೆ',
'ಮಾಂಸಜೀವಿ',
'ಮಾಂಸತಾನ',
'ಮಾಂಸದ್ರಾವಿ',
'ಮಾಂಸಭೇದಿ',
'ಮಾಂಸಮೇಹ',
'ಮಾಂಸರೋಹಿ',
'ಮಾಂಸರೋಹೆ',
'ಮಾಂಸಳಿತ',
'ಮಾಂಸಾಶನ',
'ಮಾಂಸಾಶನೆ',
'ಮಾಂಸಾಹಾರ',
'ಮಾಂಸಾಹಾರಿ',
'ಮಾಕರಿಸು',
'ಮಾಘಮಾಸ',
'ಮಾಚಪತ್ರೆ',
'ಮಾಚಿಪತ್ರೆ',
'ಮಾಚಿಮುಳ್ಳು',
'ಮಾಟಕೂಟ',
'ಮಾಟಗಾತಿ',
'ಮಾಟಗಾರ',
'ಮಾಟಗಾರ್ತಿ',
'ಮಾಟಗೂಲಿ',
'ಮಾಟಮಾಯ',
'ಮಾಠಾಪತ್ಯ',
'ಮಾಡಬಲೆ',
'ಮಾಡವೃಕ್ಷ',
'ಮಾಡಶಾಲೆ',
'ಮಾಡಸಂದಿ',
'ಮಾಡಿಮನೆ',
'ಮಾಡುಗೇಡಿ',
'ಮಾಡುವಿಕೆ',
'ಮಾಣವಕ',
'ಮಾಣಿದ್ವಾರ',
'ಮಾಣಿಬಂಧ',
'ಮಾಣಿಮಂಥ',
'ಮಾಣಿವೆಣ್',
'ಮಾಣಿಸುಹ',
'ಮಾತಂಗಕ',
'ಮಾತಬರ',
'ಮಾತಬರಿ',
'ಮಾತರಿಶ್ವ',
'ಮಾತಾಡಿಸು',
'ಮಾತಾಮಹ',
'ಮಾತಾಮಹಿ',
'ಮಾತಾಮಾತ',
'ಮಾತುಂಗುಟ್ಟು',
'ಮಾತುಕೇಳು',
'ಮಾತುಕೊಡು',
'ಮಾತುಗಂಟಿ',
'ಮಾತುಗಳ್ಳ',
'ಮಾತುಗಾತಿ',
'ಮಾತುಗಾರ',
'ಮಾತುಗಾರ್ತಿ',
'ಮಾತುಗಾಱ',
'ಮಾತುಗಿತಿ',
'ಮಾತುಗುಟ್ಟು',
'ಮಾತುಗುಡು',
'ಮಾತುಗುಳಿ',
'ಮಾತುಗೂಳಿ',
'ಮಾತುಗೆಡು',
'ಮಾತುಗೇಳು',
'ಮಾತುತೆಗೆ',
'ಮಾತುತೋಱು',
'ಮಾತುದೆಗೆ',
'ಮಾತುದೋಱು',
'ಮಾತುಪರ',
'ಮಾತುಬರಿ',
'ಮಾತುಭಾರ',
'ಮಾತುಭಾರಿ',
'ಮಾತುಲಂಗ',
'ಮಾತುಲಕ',
'ಮಾತುಲಾನಿ',
'ಮಾತುಲಾಹಿ',
'ಮಾತುಲುಂಗ',
'ಮಾತುವೆಣ್',
'ಮಾತುಳಂಗ',
'ಮಾತುಳಾನಿ',
'ಮಾತುಳುಂಗ',
'ಮಾತೃಗಾಮಿ',
'ಮಾತೃದೀಕ್ಷೆ',
'ಮಾತೃಬಳ್ಳಿ',
'ಮಾತೃಭಾಷೆ',
'ಮಾತೃಭೂಮಿ',
'ಮಾತೃಮುಖ',
'ಮಾತೃಮೂಢ',
'ಮಾತೃವಾಹಿ',
'ಮಾತೃಷ್ವಸೃ',
'ಮಾತೃಸ್ಥಾನ',
'ಮಾತೃಹತ್ಯೆ',
'ಮಾತ್ರಾಂಗುಲ',
'ಮಾತ್ರಾಕಾಲ',
'ಮಾತ್ರಾಗಣ',
'ಮಾತ್ರಾಬಂಧ',
'ಮಾತ್ರಾವೃತ್ತ',
'ಮಾತ್ರೀಬಳ್ಳಿ',
'ಮಾತ್ಸರಿಯ',
'ಮಾತ್ಸ್ಯನ್ಯಾಯ',
'ಮಾದಕತೆ',
'ಮಾದರಿಕೆ',
'ಮಾದರ್ಚೋತ್',
'ಮಾದವರೆ',
'ಮಾದವಾಲಿ',
'ಮಾದವಾಳ',
'ಮಾದಳಿರ್',
'ಮಾದಱಿಕೆ',
'ಮಾದಾರಿಕೆ',
'ಮಾದಾಱಿಕೆ',
'ಮಾದಿಗಿತ್ತಿ',
'ಮಾದುಪಳ',
'ಮಾದುಫಲ',
'ಮಾದೂಫಲ',
'ಮಾದೆಂಗಿತಿ',
'ಮಾಧವಕ',
'ಮಾಧೀಫಲ',
'ಮಾಧುಕರ',
'ಮಾಧುಕರಿ',
'ಮಾಧೂಕರ',
'ಮಾಧೂಕರಿ',
'ಮಾಧ್ಯಂದಿನ',
'ಮಾಧ್ಯಮಿಕ',
'ಮಾಧ್ಯಸ್ಥಿಕೆ',
'ಮಾಧ್ಯಾಹ್ನಿಕ',
'ಮಾಧ್ವಮತ',
'ಮಾನಂಗಳೆ',
'ಮಾನಂಗೆಡು',
'ಮಾನಕರಿ',
'ಮಾನಕಳೆ',
'ಮಾನಕಾತಿ',
'ಮಾನಕ್ರಿಯೆ',
'ಮಾನಗಂಬ',
'ಮಾನಗಟ್ಟು',
'ಮಾನಗರ್ವ',
'ಮಾನಗೆಡು',
'ಮಾನಗೇಡಿ',
'ಮಾನಗೇಡು',
'ಮಾನಚಿತ್ರ',
'ಮಾನಚ್ಯುತಿ',
'ಮಾನದಂಡ',
'ಮಾನಧನ',
'ಮಾನಧರ',
'ಮಾನನಷ್ಟ',
'ಮಾನನಿಧಿ',
'ಮಾನನೀಯ',
'ಮಾನಪತ್ರ',
'ಮಾನಭಂಗ',
'ಮಾನವಂತ',
'ಮಾನವಂತೆ',
'ಮಾನವತಿ',
'ಮಾನವತೆ',
'ಮಾನವತ್ವ',
'ಮಾನವಮಿ',
'ಮಾನವಿಕ',
'ಮಾನವೀಯ',
'ಮಾನಶಾಲಿ',
'ಮಾನಸಜ',
'ಮಾನಸಿಕ',
'ಮಾನಸಿಕೆ',
'ಮಾನಸಿಕ್ಕೆ',
'ಮಾನಸಿಗ',
'ಮಾನಸೂತ್ರ',
'ಮಾನಸ್ತಂಭ',
'ಮಾನಸ್ತೋಕ',
'ಮಾನಸ್ಥಾನ',
'ಮಾನಹಾನಿ',
'ಮಾನಹೀನ',
'ಮಾನಹೀನೆ',
'ಮಾನಾಂಗುಲ',
'ಮಾನಾನ್ವಿತ',
'ಮಾನಾನ್ವಿತೆ',
'ಮಾನುಷತ್ವ',
'ಮಾನೆತಿದ್ದು',
'ಮಾನೋನ್ನತ',
'ಮಾನೋನ್ನತಿ',
'ಮಾನೋನ್ನತ್ತೆ',
'ಮಾನ್ಯಂಗುಡು',
'ಮಾನ್ಯಕಾಱ',
'ಮಾನ್ಯತನ',
'ಮಾನ್ಯದೆರೆ',
'ಮಾನ್ಯದೆಱೆ',
'ಮಾನ್ಯವಂತ',
'ಮಾಪುಗೊಳ್ಳು',
'ಮಾಪುಗೋಲು',
'ಮಾಪುರುಷ',
'ಮಾಮಲತಿ',
'ಮಾಮಲೇತಿ',
'ಮಾಮಸಕ',
'ಮಾಮಸಗು',
'ಮಾಮಿಲತ್ತು',
'ಮಾಮ್ಲೆದಾರ',
'ಮಾಯಂಬೋಗು',
'ಮಾಯಕಾತಿ',
'ಮಾಯಕಾರ',
'ಮಾಯಕಾರ್ತಿ',
'ಮಾಯಕಾಱ',
'ಮಾಯಗಾತಿ',
'ಮಾಯಗಾರ',
'ಮಾಯಾಚಾರ',
'ಮಾಯಾಜಾಲ',
'ಮಾಯಾಜೀವಿ',
'ಮಾಯಾತೀತ',
'ಮಾಯಾದಂಡ',
'ಮಾಯಾದೀಪ',
'ಮಾಯಾನಿದ್ರೆ',
'ಮಾಯಾಪಾಶ',
'ಮಾಯಾಫಲ',
'ಮಾಯಾಭ್ರಾಂತಿ',
'ಮಾಯಾಮಲ',
'ಮಾಯಾಮಾರ್ಗ',
'ಮಾಯಾಮುಖ',
'ಮಾಯಾಲಾಂದ್ರ',
'ಮಾಯಾವಾದ',
'ಮಾಯಾವಾದಿ',
'ಮಾಯಾವಿಗ',
'ಮಾಯಾವಿದ',
'ಮಾಯಾಶಕ್ತಿ',
'ಮಾಯಿಸಲೆ',
'ಮಾಯುಚ್ಚವ',
'ಮಾಯೆವಿಡಿ',
'ಮಾರಕತ',
'ಮಾರಕೇಳಿ',
'ಮಾರನೆಯ',
'ಮಾರಫತಿ',
'ಮಾರವಳಿ',
'ಮಾರವಾಡಿ',
'ಮಾರವಾಳಿ',
'ಮಾರಸಾಲ',
'ಮಾರಾಮಾರಿ',
'ಮಾರಾಯಿತಿ',
'ಮಾರಿಕಣ್ಣು',
'ಮಾರಿಕಬ್ಬೆ',
'ಮಾರಿಕವ್ವೆ',
'ಮಾರಿಗಾಲ',
'ಮಾರಿಗುತ್ತ',
'ಮಾರಿಡೋಲು',
'ಮಾರಿತನ',
'ಮಾರಿಪತ',
'ಮಾರಿಪತ್ತು',
'ಮಾರಿಫತ',
'ಮಾರಿಫತಿ',
'ಮಾರಿಫತು',
'ಮಾರಿಫತ್ತು',
'ಮಾರಿಬಲೆ',
'ಮಾರಿಬೇನೆ',
'ಮಾರಿರೋಗ',
'ಮಾರೀಚತೆ',
'ಮಾರೀಪತ',
'ಮಾರೀಪತ್ತು',
'ಮಾರೀಫತ',
'ಮಾರೀಫತ್ತು',
'ಮಾರುಕಟ್ಟೆ',
'ಮಾರುಕಾಣ್',
'ಮಾರುಗಾಲು',
'ಮಾರುಗೊಡು',
'ಮಾರುಗೊಳ್',
'ಮಾರುತೇಷ್ಟ',
'ಮಾರುತ್ತರ',
'ಮಾರುದನಿ',
'ಮಾರುನುಡಿ',
'ಮಾರುಪೇಟೆ',
'ಮಾರುಮಾತು',
'ಮಾರುವಾಡಿ',
'ಮಾರುವೇಷ',
'ಮಾರುವೋಗು',
'ಮಾರುಹೇಳು',
'ಮಾರುಹೋಗು',
'ಮಾರ್ಕೊರಲ್',
'ಮಾರ್ಗಂದಪ್ಪು',
'ಮಾರ್ಗಕಾರ',
'ಮಾರ್ಗಣಕ',
'ಮಾರ್ಗದರ್ಶಿ',
'ಮಾರ್ಗರಾಗ',
'ಮಾರ್ಗರುಚಿ',
'ಮಾರ್ಗವಿದ',
'ಮಾರ್ಗವಿದೆ',
'ಮಾರ್ಗಶಿರ',
'ಮಾರ್ಗಶೀರ್ಷ',
'ಮಾರ್ಗಶೈವ',
'ಮಾರ್ಗಶ್ರಮ',
'ಮಾರ್ಗಸುಂಕ',
'ಮಾರ್ದಂಗಿಕ',
'ಮಾರ್ದನಿಸು',
'ಮಾರ್ದವತೆ',
'ಮಾರ್ದಳಿಕ',
'ಮಾರ್ಪಡಿಸು',
'ಮಾರ್ಪುಗೊಳ್ಳು',
'ಮಾರ್ಪೊಣರ್',
'ಮಾರ್ಪೊಳಪು',
'ಮಾರ್ಪೊಳೆಪು',
'ಮಾರ್ಮಲೆಸು',
'ಮಾರ್ಮೊರಸು',
'ಮಾರ್ಮೊಳಗು',
'ಮಾರ್ಮೊೞಗು',
'ಮಾಲಕಾರ',
'ಮಾಲಕುಮಿ',
'ಮಾಲಗಣ್ಣು',
'ಮಾಲಗಾರ',
'ಮಾಲಗಾರ್ತಿ',
'ಮಾಲಗಾಱ',
'ಮಾಲಪುವಾ',
'ಮಾಲಮತ್ತೆ',
'ಮಾಲವಶ್ರೀ',
'ಮಾಲಾಕಂದ',
'ಮಾಲಾಕಾರ',
'ಮಾಲಾಯೋಗ',
'ಮಾಲಾರ್ಪಣ',
'ಮಾಲಾರ್ಪಣೆ',
'ಮಾಲಾವೃತ್ತ',
'ಮಾಲಿಖಾನೆ',
'ಮಾಲಿಗಾಱ',
'ಮಾಲುಗಣ್ಣು',
'ಮಾಲುಗಾಡಿ',
'ಮಾಲುಧಾನ',
'ಮಾಲುಮತ್ತೆ',
'ಮಾಲೆಕಾರ',
'ಮಾಲೆಗಟ್ಟು',
'ಮಾಲೆಗಾತಿ',
'ಮಾಲೆಗಾರ',
'ಮಾಲೆಗಾರ್ತಿ',
'ಮಾಲೆಗಾಱ',
'ಮಾಲೆಗೊಳ್',
'ಮಾಲೆವೂಡು',
'ಮಾಲೆಸೂಡು',
'ಮಾಲೋಪಮೆ',
'ಮಾಲ್ಕೀಹಕ್ಕು',
'ಮಾಲ್ಗುಜಾರಿ',
'ಮಾಲ್ಯಪುಷ್ಪ',
'ಮಾಲ್ಯವಂತ',
'ಮಾವಂತಿಗ',
'ಮಾವಟಿಗ',
'ಮಾವತನ',
'ಮಾವತಿಗ',
'ಮಾವಳಿಗ',
'ಮಾವಿಚೀಲ',
'ಮಾವುತಿಗ',
'ಮಾವುಲಿಗ',
'ಮಾಷಪತ್ರಿ',
'ಮಾಷಪರ್ಣಿ',
'ಮಾಷಮುಖ',
'ಮಾಷಾಪೂಪ',
'ಮಾಸರಿಕೆ',
'ಮಾಸರಿಕ್ಕೆ',
'ಮಾಸಲಾಣಿ',
'ಮಾಸವಳ',
'ಮಾಸವಾರಿ',
'ಮಾಸವಾಳ',
'ಮಾಸಾಶನ',
'ಮಾಸಿಪತ್ರೆ',
'ಮಾಸುಚೀಲ',
'ಮಾಸ್ತರಣಿ',
'ಮಾಸ್ತರತಿ',
'ಮಾಸ್ತರಿಕೆ',
'ಮಾಸ್ತರಿಣಿ',
'ಮಾಸ್ತಿಕಲ್',
'ಮಾಸ್ತಿಕಲ್ಲು',
'ಮಾಸ್ತಿಗಲ್ಲು',
'ಮಾಸ್ತಿಗುಡಿ',
'ಮಾಸ್ತಿಬೀಳು',
'ಮಾಹಜ್ಜನ',
'ಮಾಹತ್ತರ',
'ಮಾಹಿಷಿಕ',
'ಮಾಹೆಯಾನಾ',
'ಮಾಹೆವಾರಿ',
'ಮಾಹೇಶ್ವರ',
'ಮಾಹೇಶ್ವರಿ',
'ಮಾಳಂಗೊಳ್',
'ಮಾಳಜಿಗೆ',
'ಮಾಳಪಕ್ಷ',
'ಮಾಳಬೆಕ್ಕು',
'ಮಾಳವಶ್ರೀ',
'ಮಾಳವಿಕ',
'ಮಾಳಾಕಾರ',
'ಮಾಳಾವೃತ್ತ',
'ಮಾಳೆಸೋಗ',
'ಮಾಱಂಡಲ',
'ಮಾಱಂಡಳ',
'ಮಾಱುಂಗುಡು',
'ಮಾಱುಂಗೊಳ್',
'ಮಾಱುಕೊಳ್',
'ಮಾಱುಗುಡು',
'ಮಾಱುಗೊಡು',
'ಮಾಱುಗೊಳ್',
'ಮಾಱುತ್ತರ',
'ಮಾಱುದನಿ',
'ಮಾಱುನುಡಿ',
'ಮಾಱುಬರು',
'ಮಾಱುಬಲ',
'ಮಾಱುಮಲೆ',
'ಮಾಱುಮಾತು',
'ಮಾಱುವಡೆ',
'ಮಾಱುವೋಗು',
'ಮಾಱೊರಸು',
'ಮಿಂಚಂಭಟ್ಟ',
'ಮಿಂಚುಂಬುಳು',
'ಮಿಂಚುಂಬುೞು',
'ಮಿಂಚುಗಣ್',
'ಮಿಂಚುಗಣ್ಣು',
'ಮಿಂಚುಗೂಡು',
'ಮಿಂಚುನೋಟ',
'ಮಿಂಚುಬಳ್ಳಿ',
'ಮಿಂಚುಬುಳು',
'ಮಿಂಚುಬುೞು',
'ಮಿಂಚುವಡೆ',
'ಮಿಂಚುವೋಗು',
'ಮಿಂಚುಹುಳ',
'ಮಿಂಚುಹುಳು',
'ಮಿಂಟೆಯಂಬು',
'ಮಿಂಡಗಾತಿ',
'ಮಿಂಡಗಾರ',
'ಮಿಂಡಗಾಱ',
'ಮಿಂಡತನ',
'ಮಿಂಡಪ್ರಾಯ',
'ಮಿಂಡಾಟಿಕೆ',
'ಮಿಂಡಿತನ',
'ಮಿಂಡುವಡೆ',
'ಮಿಕಿಮಿಕಿ',
'ಮಿಗಗಯ್ಯ',
'ಮಿಗಗೆಯ್ಯ',
'ಮಿಗಗೈಯ',
'ಮಿಗದಾಣ',
'ಮಿಗದೇರ',
'ಮಿಗಪತಿ',
'ಮಿಗವಕ್ಕೆ',
'ಮಿಗವಗೆ',
'ಮಿಗವಟ್ಟೆ',
'ಮಿಗಸೊಕ್ಕು',
'ಮಿಗಿಲ್ಗಣ್',
'ಮಿಗುತಾಯ',
'ಮಿಗುವರಿ',
'ಮಿಗೆವರ್',
'ಮಿಗೆವರಿ',
'ಮಿಜಿಮಿಜಿ',
'ಮಿಟಕಿಸು',
'ಮಿಟಮಿಟ',
'ಮಿಟಿಕಿಸು',
'ಮಿಟಿಮಿಟಿ',
'ಮಿಟುಕಿಸು',
'ಮಿಟ್ಟಿಯಂಬು',
'ಮಿಟ್ಟೆಗಲ್ಲು',
'ಮಿಟ್ಟೆದುಡು',
'ಮಿಟ್ಟೆನೆಲ',
'ಮಿಟ್ಟೆಯಂಬು',
'ಮಿಟ್ಠಾದಾರ',
'ಮಿಟ್ಠಾದಾರಿ',
'ಮಿಡಮಿಡ',
'ಮಿಡಿಕಾಯಿ',
'ಮಿಡಿಗಾಯ್',
'ಮಿಡಿಗಾಯಿ',
'ಮಿಡಿನಾಗ',
'ಮಿಡಿಬಿಲ್',
'ಮಿಡಿಬಿಲ್ಲು',
'ಮಿಡಿಮಾವು',
'ಮಿಡಿಮಿಂಚು',
'ಮಿಡಿಯಿಸು',
'ಮಿಡಿವಟ್ಟು',
'ಮಿಡಿವಲೆ',
'ಮಿಡಿವಿಲ್',
'ಮಿಡಿವಿಲ್ಲು',
'ಮಿಡುಕಾಟ',
'ಮಿಡುಕಾಡು',
'ಮಿಡುಕಿಸು',
'ಮಿಡುಕುಱು',
'ಮಿಡುಗುಱು',
'ಮಿಣಮಿಣ',
'ಮಿಣಿಬಲೆ',
'ಮಿಣಿಮಿಟಿ',
'ಮಿಣಿಮಿಣಿ',
'ಮಿಣಿಯಾಟ',
'ಮಿಣಿವಲೆ',
'ಮಿಣುಕಿಸು',
'ಮಿಣುಗುಟ್ಟು',
'ಮಿತಪಂಚ',
'ಮಿತಕುಕ್ಷಿ',
'ಮಿತನುಡಿ',
'ಮಿತಭಾಷಿ',
'ಮಿತಭಾಷೆ',
'ಮಿತವಾಕ್ಯ',
'ಮಿತವ್ಯಯ',
'ಮಿತಾಹಾರ',
'ಮಿತಾಹಾರಿ',
'ಮಿತಿಗೆಯ್',
'ಮಿತಿಮೀರು',
'ಮಿತ್ತುಗರೆ',
'ಮಿತ್ರದ್ರೋಹ',
'ಮಿತ್ರದ್ರೋಹಿ',
'ಮಿತ್ರಭಾವ',
'ಮಿತ್ರಭೇದ',
'ಮಿಥುನತ್ವ',
'ಮಿಥುನಕ್ರ್ಷ',
'ಮಿಥೋಯುಕ್ತ',
'ಮಿಥ್ಯವಂತ',
'ಮಿಥ್ಯವಾದಿ',
'ಮಿಥ್ಯಾಚರ್ಯೆ',
'ಮಿಥ್ಯಾಚಾರ',
'ಮಿಥ್ಯಾಚಾರಿ',
'ಮಿಥ್ಯಾಜ್ಞಾನ',
'ಮಿಥ್ಯಾಜ್ಞಾನಿ',
'ಮಿಥ್ಯಾದೃಷ್ಟಿ',
'ಮಿಥ್ಯಾಧರ್ಮ',
'ಮಿಥ್ಯಾವಾದ',
'ಮಿಥ್ಯಾವಾದಿ',
'ಮಿದಿಕೂಲಿ',
'ಮಿದಿಗೂಲಿ',
'ಮಿದುಡಿಸು',
'ಮಿದುತನ',
'ಮಿನಿಮಿನಿ',
'ಮಿನುಗಿಸು',
'ಮಿನುಗುಟ್ಟು',
'ಮಿನುಮಿನು',
'ಮಿನ್ನುವಡೆ',
'ಮಿರಿಮಿರಿ',
'ಮಿರುಗಿಸು',
'ಮಿರುಗುಟ್ಟು',
'ಮಿರ್ಜಾಮುಳ್ಳು',
'ಮಿಲಾಖತು',
'ಮಿಲಾಖತ್ತು',
'ಮಿಲಾಯಿಸು',
'ಮಿಲಾವಣೆ',
'ಮಿಲಿಟರಿ',
'ಮಿಲಿಟೆರಿ',
'ಮಿಲುಕಾಟ',
'ಮಿಲುಕಾಡು',
'ಮಿಲುಗಾಡು',
'ಮಿಶ್ರಕಾಪಿ',
'ಮಿಶ್ರಕಾವ್ಯ',
'ಮಿಶ್ರತಳಿ',
'ಮಿಶ್ರಪ್ರತಿ',
'ಮಿಶ್ರಲೋಹ',
'ಮಿಶ್ರವರ್ಣ',
'ಮಿಸಲಯ',
'ಮಿಸಿರಿಕೆ',
'ಮಿಸುಕಾಟ',
'ಮಿಸುಕಾಡು',
'ಮಿಸುಗಿಸು',
'ಮಿಸುನಿಸು',
'ಮಿಸುಪೇಱು',
'ಮಿಸುಮಿಲ್ಲಿ',
'ಮಿಹರಬ್',
'ಮಿಹಿರಾಬು',
'ಮಿಳಕತ್',
'ಮಿಳಖತ್ತು',
'ಮಿಳಮಿಳ',
'ಮಿಳಿಮಿಳಿ',
'ಮಿಳಿಯಿಕ್ಕು',
'ಮಿಳುಮಿಳ',
'ಮಿಳ್ಮಿಳನೆ',
'ಮಿಳ್ಮಿಳಿಸು',
'ಮಿಱಪಿಗ',
'ಮಿಱಿಮಿಱಿ',
'ಮಿಱುಗಿಸು',
'ಮಿಱುಮಿಱು',
'ಮಿಱಱನೆ',
'ಮಿೞ್ತುಗರೆ',
'ಮಿೞ್ತುಗೊಡ್ಡ',
'ಮೀಂಗುಲಿಗ',
'ಮೀಂಗುಲಿಗೆ',
'ಮೀಂಗುಲಿತಿ',
'ಮೀಂಟುವರಿ',
'ಮೀಂಬುಲಿಗ',
'ಮೀಟುವಡೆ',
'ಮೀಟುವರಿ',
'ಮೀಟುವೆಣ್ಣು',
'ಮೀಟುಹುಳ',
'ಮೀನಂಬುೞು',
'ಮೀನಕಾಗೆ',
'ಮೀನಕಾರೆ',
'ಮೀನಕೊಂಕಿ',
'ಮೀನಕ್ಷೇತ್ರ',
'ಮೀನಖಂಡ',
'ಮೀನಾಲಯ',
'ಮೀನುಂಬುೞು',
'ಮೀನುಖಂಡ',
'ಮೀನುಗಾರ',
'ಮೀನುಬೆಕ್ಕು',
'ಮೀನುಮರ',
'ಮೀನುಮಾರಿ',
'ಮೀನ್ಬುಲಿಗ',
'ಮೀಮಾಂಸಕ',
'ಮೀರಾಕೋರ',
'ಮೀಸಲಳಿ',
'ಮೀಸಲಾತಿ',
'ಮೀಸಲಿಡು',
'ಮೀಸಲ್ಗಳೆ',
'ಮೀಸಲ್ದೆಗೆ',
'ಮೀಸಲ್ಪಡೆ',
'ಮೀಸೆಗಡಿ',
'ಮೀಸೆಗಾರ',
'ಮೀಹಂದಪ್ಪು',
'ಮುಂಕಣಿಸು',
'ಮುಂಕೊಳಿಸು',
'ಮುಂಗಂದಾಯ',
'ಮುಂಗಡಿಸು',
'ಮುಂಗತ್ತರಿ',
'ಮುಂಗತ್ತಲೆ',
'ಮುಂಗನಸು',
'ಮುಂಗರುಳು',
'ಮುಂಗರ್ತರಿ',
'ಮುಂಗವಚ',
'ಮುಂಗಳ್ತಲೆ',
'ಮುಂಗಾಣಿಕೆ',
'ಮುಂಗಾಣಿಸು',
'ಮುಂಗಾರಿಯ',
'ಮುಂಗಾರ್ಗಾಲ',
'ಮುಂಗಾವಲು',
'ಮುಂಗಾವಳ',
'ಮುಂಗಾಳೆಗ',
'ಮುಂಗುರುಡು',
'ಮುಂಗುರುತು',
'ಮುಂಗುರುಹು',
'ಮುಂಗುರುಳ್',
'ಮುಂಗುರುಳು',
'ಮುಂಗುರುಳ್ಚು',
'ಮುಂಗುರುಪು',
'ಮುಂಗುಱುಹು',
'ಮುಂಗೂದಲ್',
'ಮುಂಗೂದಲು',
'ಮುಂಗೆಡಿಸು',
'ಮುಂಗೆಲಸ',
'ಮುಂಗೈವಲ',
'ಮುಂಗೈಹೊಡೆ',
'ಮುಂಗೊರಲ್',
'ಮುಂಗೊರಳು',
'ಮುಂಗೊಳಿಸು',
'ಮುಂಚೆಕಡೆ',
'ಮುಂಜಕೇಶಿ',
'ಮುಂಜಕ್ಷಯ',
'ಮುಂಜಗಲಿ',
'ಮುಂಜಗುಲಿ',
'ಮುಂಜಾಗ್ರತೆ',
'ಮುಂಜಿಗಟ್ಟು',
'ಮುಂಜಿಹುಲ್ಲು',
'ಮುಂಜೆರಗು',
'ಮುಂಜೆಱಂಗು',
'ಮುಂಜೆಱಗು',
'ಮುಂಜೋಗಿಲ್',
'ಮುಂಡಕಾಱ',
'ಮುಂಡಗಳ್ಳಿ',
'ಮಂಡಮೋಪಿ',
'ಮುಂಡಲೋಹ',
'ಮುಂಡಾಸನ',
'ಮುಂಡಿಕಸ',
'ಮುಂಡೀಚಲು',
'ಮುಂಡುಕಾಱ',
'ಮುಂಡುಗಳ್ಳಿ',
'ಮುಂಡುವೋಗು',
'ಮುಂಡೆತನ',
'ಮುಂತಳರ್',
'ಮುಂತಾಸನ',
'ಮುಂತುಗಾಣ್',
'ಮುಂತುಗೆಡು',
'ಮುಂತುಗೊಳ್',
'ಮುಂದರಿವು',
'ಮುಂದರುಗು',
'ಮುಂದಳರ್',
'ಮುಂದಾಳಿಕೆ',
'ಮುಂದಾಳ್ತನ',
'ಮುಂದಿರಿಸು',
'ಮುಂದಿವಸ',
'ಮುಂದುಗಡೆ',
'ಮುಂದುಗಾಣ್',
'ಮುಂದುಗಾಣು',
'ಮುಂದುಗಾಣ್ಕೆ',
'ಮುಂದುಗೆಡು',
'ಮುಂದುಗೆಯ್',
'ಮುಂದುಗೊಳ್',
'ಮುಂದುಗೊಳ್ಳು',
'ಮುಂದುದೆಗೆ',
'ಮುಂದುದೋಱ್',
'ಮುಂದುದೋಱು',
'ಮುಂದುವಡು',
'ಮುಂದುವರಿ',
'ಮುಂದುವಾಯ್',
'ಮುಂದೆಣಿಕೆ',
'ಮುಂದೆಣಿಸು',
'ಮುಂದೊದಗು',
'ಮುಂಬಗಲ್',
'ಮುಂಬಗಲು',
'ಮುಂಬಡಿಸು',
'ಮುಂಬದನ್',
'ಮುಂಬರಿತ',
'ಮುಂಬರೆಯ',
'ಮುಂಬಸಿರು',
'ಮುಂಬಸಿಱು',
'ಮುಂಬಾಗಿಲ್',
'ಮುಂಬಾಗಿಲು',
'ಮುಂಬಿಸಿಲ್',
'ಮುಂಬಿಸಿಲು',
'ಮುಂಬುಗಳೆ',
'ಮುಂಬೆಳಕು',
'ಮುಂಬೆಳಗು',
'ಮುಂಬೆಳಸು',
'ಮುಂಬೆಱಗು',
'ಮುಕದಮಿ',
'ಮುಕದ್ದಮೆ',
'ಮುಕಾದಮ',
'ಮುಕುಡಕ',
'ಮುಕುರಿಕ್ಕು',
'ಮುಕುರಿಸು',
'ಮುಕುಲತೆ',
'ಮುಕುಲನ',
'ಮುಕುಲಿತ',
'ಮುಕುವಾಡು',
'ಮುಕುಳಿತ',
'ಮುಕುಳಿಸು',
'ಮುಕೂಡಲ್',
'ಮುಕ್ಕಂಡುಗ',
'ಮುಕ್ಕಂಧಾಯ',
'ಮುಕ್ಕಡಕ',
'ಮುಕ್ಕಡಪ್ಪು',
'ಮುಕ್ಕಡಲೆ',
'ಮುಕ್ಕರಿವೆ',
'ಮುಕ್ಕವಲು',
'ಮುಕ್ಕಳಿಸು',
'ಮುಕ್ಕಱಿಸು',
'ಮುಕ್ಕಾರಿಕೆ',
'ಮುಕ್ಕಾಲ್ವಾಡು',
'ಮುಕ್ಕುಗಳೆ',
'ಮುಕ್ಕುಟುಕ',
'ಮುಕ್ಕುಡಿತೆ',
'ಮುಕ್ಕುರಿಕ್ಕು',
'ಮುಕ್ಕುರಿಸು',
'ಮುಕ್ಕುರುಕು',
'ಮುಕ್ಕುವೋಗು',
'ಮುಕ್ಕುಳಿಸು',
'ಮುಕ್ಕುಱಿಕು',
'ಮುಕ್ಕುಱಿಕ್ಕು',
'ಮುಕ್ಕುಱಿಸು',
'ಮುಕ್ಕೂಡಲ್',
'ಮುಕ್ಕೂಡಲು',
'ಮುಕ್ಕೂಡಿಸು',
'ಮುಕ್ಕೊಡೆಯ',
'ಮುಕ್ತಕಂಠ',
'ಮುಕ್ತಕಾಲ',
'ಮುಕ್ತಕೇಶಿ',
'ಮುಕ್ತಛಂದ',
'ಮುಕ್ತಜಾನು',
'ಮುಕ್ತಬಂಧ',
'ಮುಕ್ತಾಗುಣ',
'ಮುಕ್ತಾದಾಮ',
'ಮುಕ್ತಾಫಲ',
'ಮುಕ್ತಾಫಳ',
'ಮುಕ್ತಾಮುಕ್ತ',
'ಮುಕ್ತಾವಲಿ',
'ಮುಕ್ತಾವಳಿ',
'ಮುಕ್ತಾಸಾರ',
'ಮುಕ್ತಾಸ್ಫೋಟ',
'ಮುಕ್ತಾಸ್ರಜ',
'ಮುಕ್ತಾಹಾರ',
'ಮುಕ್ತಿಕಲ್ಲು',
'ಮುಕ್ತಿಗಾಮಿ',
'ಮುಕ್ತಿಗೇಡಿ',
'ಮುಕ್ತಿಪಥ',
'ಮುಕ್ತಿಪದ',
'ಮುಕ್ತಿಲೋಕ',
'ಮುಕ್ತಿಸ್ಥಾನ',
'ಮುಕ್ತಿಹೇತು',
'ಮುಕ್ತೇಸರ',
'ಮುಖಕ್ಷೌರ',
'ಮುಖಗೇಡಿ',
'ಮುಖಗೊಡು',
'ಮುಖಚರ್ಯೆ',
'ಮುಖಚಾರಿ',
'ಮುಖಚಿಟ್ಟು',
'ಮುಖಚಿತ್ರ',
'ಮುಖಚೂರ್ಣ',
'ಮುಖಚೇಷ್ಟೆ',
'ಮುಖದೋರು',
'ಮುಖದೋಱು',
'ಮುಖದ್ವಾರ',
'ಮುಖಪಟ್ಟೆ',
'ಮುಖಪತ್ರ',
'ಮುಖಪಾಠ',
'ಮುಖಪುಟ',
'ಮುಖಬಿಲೆ',
'ಮುಖಬೆಲೆ',
'ಮುಖಭಂಗ',
'ಮುಖಭಂಗಿ',
'ಮುಖಭದ್ರ',
'ಮುಖಭಾವ',
'ಮುಖಮಣಿ',
'ಮುಖಮುದ್ರೆ',
'ಮುಖಮೂಲ್ಯ',
'ಮುಖರಂಬ',
'ಮುಖರತ',
'ಮುಖರತಿ',
'ಮುಖರತೆ',
'ಮುಖರಸ',
'ಮುಖರಾಗ',
'ಮುಖರಿತ',
'ಮುಖರಿಸು',
'ಮುಖರೋಗ',
'ಮುಖರೋಮ',
'ಮುಖಲೋಮಿ',
'ಮುಖವಸ್ತ್ರ',
'ಮುಖವಾಡ',
'ಮುಖವಾದ್ಯ',
'ಮುಖವಾರ್ತೆ',
'ಮುಖವಾಸ',
'ಮುಖವೀಣೆ',
'ಮುಖವೇಣು',
'ಮುಖಶಾಲೆ',
'ಮುಖಶುದ್ಧಿ',
'ಮುಖಸಾಲೆ',
'ಮುಖಸಿರಿ',
'ಮುಖಸುತ್ತು',
'ಮುಖಸ್ತುತಿ',
'ಮುಖಸ್ಥಾನ',
'ಮುಖಹೀನ',
'ಮುಖಹೀನೆ',
'ಮುಖಹೇಡಿ',
'ಮುಖಾಂತರ',
'ಮುಖಾಬಿಲೆ',
'ಮುಖಾಮುಖಿ',
'ಮುಖೋದ್ಗತ',
'ಮುಖ್ಯಪ್ರಾಣ',
'ಮುಖ್ಯಮಂತ್ರಿ',
'ಮುಖ್ಯರಸ್ತೆ',
'ಮುಗದಾಣ',
'ಮುಗಳಕ್ಕಿ',
'ಮುಗಿತಾಯ',
'ಮುಗಿಬೀಳು',
'ಮುಗಿಮಾವು',
'ಮುಗಿಯಿಸು',
'ಮುಗಿಲ್ಗಲ್',
'ಮುಗಿಲ್ಗಲ್ಲು',
'ಮುಗಿಲ್ಗಿಚ್ಚು',
'ಮುಗಿಲ್ದನಿ',
'ಮುಗಿಲ್ಬಟ್ಟೆ',
'ಮುಗಿಲ್ವಟ್ಟೆ',
'ಮುಗುದಾಣ',
'ಮುಗುವಳಿ',
'ಮುಗುಳಕ್ಕಿ',
'ಮುಗುಳಿಸು',
'ಮುಗುಳೇಱು',
'ಮುಗುಳೊತ್ತು',
'ಮುಗುಳ್ಗಣ್',
'ಮುಗುಳ್ಗಾಯ್',
'ಮುಗುಳ್ದೋಱ್',
'ಮುಗುಳ್ದೋಱು',
'ಮುಗುಳ್ನಗು',
'ಮುಗುಳ್ನಗೆ',
'ಮುಗುಳ್ವಡೆ',
'ಮುಗ್ಗರಿಸು',
'ಮುಗ್ಗುರಿಸು',
'ಮುಗ್ಧತನ',
'ಮುಗ್ಧಭಾವ',
'ಮುಚುಕುಂದ',
'ಮುಚ್ಚಂಬೋಗು',
'ಮುಚ್ಚಣಿಕೆ',
'ಮುಚ್ಚಲಿಕೆ',
'ಮುಚ್ಚವಡಿ',
'ಮುಚ್ಚಳಿಕೆ',
'ಮುಚ್ಚಳಿಗೆ',
'ಮುಚ್ಚುಂಗಣ್',
'ಮುಚ್ಚುಗಣ್',
'ಮುಚ್ಚುಗತ್ತಿ',
'ಮುಚ್ಚುಗಲ್ಲು',
'ಮುಚ್ಚುಗೊನೆ',
'ಮುಚ್ಚುಮರೆ',
'ಮುಚ್ಚುಮಱೆ',
'ಮುಚ್ಚುಮಾತು',
'ಮುಚ್ಚುವಲೆ',
'ಮುಚ್ಚುಳಿಕ್ಕು',
'ಮುಚ್ಚೆಗೊಳ್',
'ಮುಚ್ಚೆದಿಳಿ',
'ಮುಚ್ಚೆವೋಗು',
'ಮುಜಗರ',
'ಮುಜರಾಯ್',
'ಮುಜರಾಯಿ',
'ಮುಜುಗರ',
'ಮುಟ್ಟಯಿಸು',
'ಮುಟ್ಟಲತಿ',
'ಮುಟ್ಟಲ್ಗಾಣ್',
'ಮುಟ್ಟವಿಸು',
'ಮುಟ್ಟಹಾಳ',
'ಮುಟ್ಟಿವಿಡಿ',
'ಮುಟ್ಟುಂಗಾರ',
'ಮುಟ್ಟುಕಟ್ಟು',
'ಮುಟ್ಟುಕುಟ್ಟು',
'ಮುಟ್ಟುಗಿಡು',
'ಮುಟ್ಟುಗೆಡು',
'ಮುಟ್ಟುಗೋಲು',
'ಮುಟ್ಟುಗೋಳು',
'ಮುಟ್ಟುಚಟ್ಟು',
'ಮುಟ್ಟುಚಿಟ್ಟು',
'ಮುಟ್ಟುಚೆಟ್ಟು',
'ಮುಟ್ಟುಡುಗು',
'ಮುಟ್ಟುಪಡ',
'ಮುಟ್ಟುಪಡು',
'ಮುಟ್ಟುವಳಿ',
'ಮುಟ್ಟುಹಾಳ',
'ಮುಟ್ಟೂಳಿಗ',
'ಮುಡಕಲು',
'ಮುಡದಾರ',
'ಮುಡಿಕಟ್ಟು',
'ಮುಡಿಗಂಪು',
'ಮುಡಿಗಚ್ಚು',
'ಮುಡಿಗಟ್ಟು',
'ಮುಡಿಗಾಱ',
'ಮುಡಿಗೊಳ್',
'ಮುಡಿನಾಳಿ',
'ಮುಡಿಪಿಕ್ಕು',
'ಮುಡಿಪಿಡು',
'ಮುಡಿಬಳೆ',
'ಮುಡಿಯಾಳ',
'ಮುಡಿಯಿಕ್ಕು',
'ಮುಡಿಯಿಸು',
'ಮುಡಿಯೊಡೆ',
'ಮುಡಿವಕ್ಕಿ',
'ಮುಡಿವಣಿ',
'ಮುಡಿವಲೆ',
'ಮುಡಿವಳ',
'ಮುಡಿವಾಳ',
'ಮುಡಿವಿಡಿ',
'ಮುಡಿವೆಱೆ',
'ಮುಡಿಸರ',
'ಮುಡುಕಲು',
'ಮುಡುಗಿಸು',
'ಮುಡುಪಿಡು',
'ಮುಡುವಿಕ್ಕು',
'ಮುಡುಹಿಕ್ಕು',
'ಮುಣುಗಿಸು',
'ಮುತವರ್ಜಿ',
'ಮುತಾಬಕ್',
'ಮುತಾಲಿಕ',
'ಮುತುವರ್ಜಿ',
'ಮುತ್ತಡಸು',
'ಮುತ್ತಯಿದೆ',
'ಮುತ್ತಲ್ಲಿಕ',
'ಮುತ್ತಾಲಿಕ',
'ಮುತ್ತುಕೊಡು',
'ಮುತ್ತುಗಾರ',
'ಮುತ್ತುವಣಿ',
'ಮುತ್ತುವಸೆ',
'ಮುತ್ತುಸಾರೆ',
'ಮುದಂಗೆಡು',
'ಮುದಂದಳೆ',
'ಮುದಂಬಡು',
'ಮುದಂಬೆರು',
'ಮುದಂಬೆಱು',
'ಮುದಗೊಳ್ಳು',
'ಮುದದಳೆ',
'ಮುದಮುಱು',
'ಮುದವಡು',
'ಮುದವಡೆ',
'ಮುದವೂಡು',
'ಮುದವೆಱು',
'ಮುದವೇರು',
'ಮುದವೇಱು',
'ಮುದಿಕಾಗೆ',
'ಮುದಿಗೂಡು',
'ಮುದಿಗೂಬೆ',
'ಮುದಿಗೊಡ್ಡು',
'ಮುದಿಗೋಡ',
'ಮುದಿತನ',
'ಮುದಿಪ್ರಾಯ',
'ಮುದಿಭ್ರಾಂತಿ',
'ಮುದಿಯಳು',
'ಮುದಿವೆಣ್',
'ಮುದಿಹಾಲೆ',
'ಮುದುಗಣ್',
'ಮುದುಗಣ್ಣು',
'ಮುದುಗರ',
'ಮುದುಗಲ್ಲೆ',
'ಮುದುಗಾಗೆ',
'ಮುದುಗಿಸು',
'ಮುದುಗುಡ್ಡೆ',
'ಮುದುಗೂಗೆ',
'ಮುದುಡಿಸು',
'ಮುದುಮೊಗ',
'ಮುದುರಿಸು',
'ಮುದ್ಗಚೂರ್ಣ',
'ಮುದ್ಗಪರ್ಣಿ',
'ಮುದ್ದಾಡಿಸು',
'ಮುದ್ದುಕೊಡು',
'ಮುದ್ದುಗರೆ',
'ಮುದ್ದುಗಱೆ',
'ಮುದ್ದುಗಾತಿ',
'ಮುದ್ದುಗಾರ',
'ಮುದ್ದುಗಾಱ',
'ಮುದ್ದುಗೆಯ್',
'ಮುದ್ದುಗೆಯ್ತ',
'ಮುದ್ದುಗೊಡು',
'ಮುದ್ದುತನ',
'ಮುದ್ದುದೋಱು',
'ಮುದ್ದುಮಾಡು',
'ಮುದ್ದುವಡೆ',
'ಮುದ್ದುವೀಱು',
'ಮುದ್ದುಸೂಸು',
'ಮುದ್ದೆಕೋಸು',
'ಮುದ್ದೆಗೊಳ್ಳು',
'ಮುದ್ದೆಸೊಪ್ಪು',
'ಮುದ್ದೈಯಾಲೆ',
'ಮುದ್ರಸದ',
'ಮುದ್ರಾಂಕಿತ',
'ಮುದ್ರಾಕರ',
'ಮುದ್ರಾಕ್ಷರ',
'ಮುದ್ರಾಪಣ',
'ಮುದ್ರಾಪಣೆ',
'ಮುದ್ರಾಪ್ರಭು',
'ಮುದ್ರೆಕೋಲು',
'ಮುದ್ರೆಗಟ್ಟು',
'ಮುದ್ರೆಗೂಳಿ',
'ಮುದ್ರೆಪಡಿ',
'ಮುದ್ರೆಪ್ರಭು',
'ಮುದ್ರೆಯೊಡೆ',
'ಮುದ್ರೆಯೊತ್ತು',
'ಮುದ್ರೆಯೋಲೆ',
'ಮುದ್ರೆವೊರು',
'ಮುದ್ರೆಹಸ್ತ',
'ಮುದ್ಲಿಯಾರ್',
'ಮುನಸಿಪ',
'ಮುನಸಿಫ',
'ಮುನಸೀಫ್',
'ಮುನಸೀಫ',
'ಮುನಸುಫ್',
'ಮುನಸೂಫ್',
'ಮುನಸೂಫ',
'ಮುನಾಸಬು',
'ಮುನಿಮತ',
'ಮುನಿಯಿಸು',
'ಮುನಿವಧು',
'ಮುನಿವಾಳು',
'ಮುನಿವೃಕ್ಷ',
'ಮುನಿಸತ್ತ್ವ',
'ಮುನಿಸತ್ವ',
'ಮುನಿಸಾನು',
'ಮುನೀಶ್ವರ',
'ಮುನ್ನಡಸು',
'ಮುನ್ನಡಿಕೆ',
'ಮುನ್ನಡೆಸು',
'ಮುನ್ನರಿಕೆ',
'ಮುನ್ನಱಿಕೆ',
'ಮುನ್ನಿರವು',
'ಮುನ್ನಿರುಳ್',
'ಮುನ್ನಿರುಳು',
'ಮುನ್ನೀರ್ಗಂದ',
'ಮುನ್ನೀರ್ಗಿಚ್ಚು',
'ಮುನ್ನೀರ್ಮಗ',
'ಮುನ್ನೀರ್ವೆಂಕೆ',
'ಮುನ್ನುಸಿರ್',
'ಮುನ್ನುೞಿವು',
'ಮುನ್ನೆಗಪು',
'ಮುನ್ನೆಗೆತ',
'ಮುನ್ನೆಣಿಕೆ',
'ಮುನ್ನೇಸಱ್',
'ಮುನ್ನೇಸಱು',
'ಮುನ್ನೊಡಲ್',
'ಮುನ್ಸೂಚನೆ',
'ಮುನ್ಸೂಚಿಸು',
'ಮುಪ್ಪದರ',
'ಮುಪ್ಪುರಿಸು',
'ಮುಪ್ಪೊಳಲ್',
'ಮುಪ್ಪೊಳಲು',
'ಮುಪ್ಪೊೞಲ್',
'ಮುಪ್ಪೊೞಲು',
'ಮುಬದಲು',
'ಮುಬಾದಲೆ',
'ಮುಬಾದಿಲೆ',
'ಮುಬಾರಕ್',
'ಮುಮುಕ್ಷುತ್ವ',
'ಮುಮ್ಮಡಿಸು',
'ಮುಮ್ಮಳಿಸು',
'ಮುಮ್ಮೞಿಸು',
'ಮುಮ್ಮುಳಿತ',
'ಮುಮ್ಮುಳಿಸು',
'ಮುಯ್ಯಚ್ಚಿಗ',
'ಮುಯ್ಯಡಿಯ',
'ಮುಯ್ಯಿರ್ಮೊಗ',
'ಮುಯ್ಯೀರಡಿ',
'ಮುಯ್ಯೀರ್ಮೊಗ',
'ಮಯ್ಯೆರಡು',
'ಮುರಕಲಿ',
'ಮುರಕಲು',
'ಮುರಕಳಿ',
'ಮುರಕಿಲ',
'ಮುರಗಡೆ',
'ಮುರಗಲು',
'ಮುರಮುರ',
'ಮುರಮುರಿ',
'ಮುರರ್ಜಿಲ',
'ಮುರವತ್ತು',
'ಮುರಿಕಟ್ಟು',
'ಮುರಿಕಿಲು',
'ಮುರಿಕಿಸು',
'ಮುರಿಕೊಂಬು',
'ಮುರಿಗೆಡು',
'ಮುರಿಗೆಡೆ',
'ಮುರಿಗೇಶ',
'ಮುರಿತಾಪ',
'ಮುರಿದಿಟ್ಟಿ',
'ಮುರಿನೋಟ',
'ಮುರಿಪಾತ್ರೆ',
'ಮುರಿಬಡಿ',
'ಮುರಿವಡೆ',
'ಮುರಿವೋಗು',
'ಮುರುಂಟಿಸು',
'ಮುರುಕಲು',
'ಮುರುಕಳಿ',
'ಮುರುಕಾಟ',
'ಮುರುಕಿಲು',
'ಮುರುಗಡೆ',
'ಮುರುಗುಟ್ಟು',
'ಮುರುಟಿಸು',
'ಮುರುಟುಹ',
'ಮುರುಡಸೆ',
'ಮುರುಡಿಸು',
'ಮುರುತಾಪು',
'ಮುರುದಲೆ',
'ಮುರುಮುಚ್ಚಿ',
'ಮುರುಮುರಿ',
'ಮುರುವತ್ತು',
'ಮುರವಿಕ್ಕು',
'ಮುರವಿಡು',
'ಮುರ್ಗಿಕಾಯಿ',
'ಮುರ್ದಾರಿಕೆ',
'ಮುಲಾಖತು',
'ಮುಲಾಖತ್ತು',
'ಮುಲುಗುಟ್ಟು',
'ಮುಲುವುನ್ನೆ',
'ಮುಲ್ಕಿಖಾತೆ',
'ಮುಲ್ಲಶಾಸ್ತ್ರ',
'ಮುಲ್ಲಾಶಾಸ್ತ್ರ',
'ಮುವಜ್ಜಲ್',
'ಮುಶಲಿಕೆ',
'ಮುಶಾಯಿರಾ',
'ಮುಷಕರ',
'ಮುಷ್ಟಾಮುಷ್ಟಿ',
'ಮುಷ್ಟಿಕೀಲು',
'ಮುಷ್ಟಿಗೆಯ್',
'ಮುಷ್ಟಿಗ್ರಾಹ್ಯ',
'ಮುಷ್ಟಿಘಾತ',
'ಮುಷ್ಟಿಪಾತ',
'ಮುಷ್ಟಿಪ್ರಶ್ನೆ',
'ಮುಷ್ಟಿಬಂಧ',
'ಮುಷ್ಟಿಬಲ',
'ಮುಷ್ಟಿಯುದ್ಧ',
'ಮುಷ್ಟಿಶೂಲೆ',
'ಮುಸಲಿಕೆ',
'ಮುಸಲ್ಮಾನ್',
'ಮುಸಲ್ಮಾನ',
'ಮುಸಲ್ಮಾನಿ',
'ಮುಸಳಿಕೆ',
'ಮುಸಾಫರ',
'ಮುಸಾಫಿರ',
'ಮುಸಾಫಿರಿ',
'ಮುಸಿಗಾರ',
'ಮುಸಿನಗು',
'ಮುಸಿನಗೆ',
'ಮುಸಿಮನೆ',
'ಮುಸುಂಕಿಡು',
'ಮುಸುಂಬಿಡು',
'ಮುಸುಕಿಕ್ಕು',
'ಮುಸುಕಿಡು',
'ಮುಸುಕೆಳೆ',
'ಮುಸುಕೆೞೆ',
'ಮುಸುಕೊಡೆ',
'ಮುಸುಗರೆ',
'ಮುಸುಗಿಡು',
'ಮುಸುಗಿಸು',
'ಮುಸುಗುಕ್ಕೆ',
'ಮುಸುಗುಟ್ಟು',
'ಮುಸುಗುಡು',
'ಮುಸುಡಿಕ್ಕು',
'ಮುಸುಡಿಡು',
'ಮುಸುಮೊಗ',
'ಮುಸುರಿಡು',
'ಮುಸುಲುಮ',
'ಮುಸುಲ್ಮಾನ',
'ಮುಸುಱುಹ',
'ಮುಸ್ತಯಿದೆ',
'ಮುಸ್ತಾಕಂದ',
'ಮುಹರ್ರಮ್',
'ಮುಹುರ್ದೃಷ್ಟ',
'ಮುಹುರ್ಭಾಷೆ',
'ಮುಳಗಳ್ಳಿ',
'ಮುಳಜಾಲಿ',
'ಮುಳಿಕೆಯ್',
'ಮುಳಿಯಿಸು',
'ಮುಳಿಸಾಱು',
'ಮುಳಿಸುಗ',
'ಮುಳುಂಕಿಸು',
'ಮುಳುಂಗಿಸು',
'ಮುಳುಕಿಸು',
'ಮುಳುಗಡೆ',
'ಮುಳುಗತ್ತಿ',
'ಮುಳುಗಳ್ಳಿ',
'ಮುಳುಗಿಡು',
'ಮುಳುಗಿಸು',
'ಮುಳುಗೀಜು',
'ಮುಳುಗೊಗ್ಗಿ',
'ಮುಳುಗೋಲು',
'ಮುಳುಮುತ್ತ',
'ಮುಳುಮುಳು',
'ಮುಳುಮುಱಿ',
'ಮುಳುಮೆಟ್ಟು',
'ಮುಳುಮೊನೆ',
'ಮುಳುವಸೆ',
'ಮುಳುವೇಲಿ',
'ಮುಳುಸೌಂತೆ',
'ಮುಳುಸೌತೆ',
'ಮುಳ್ಗತ್ತಿಗೆ',
'ಮುಳ್ಮುತ್ತಕ',
'ಮುಳ್ಮುತ್ತಗ',
'ಮುಳ್ಮುತ್ತುಗ',
'ಮುಳ್ಮುೞ್ತುಗ',
'ಮುಳ್ವಾಗಿಲ್',
'ಮುಳ್ವೆರಸು',
'ಮುಳ್ಳಂಕೋಲೆ',
'ಮುಳ್ಳಕೀರೆ',
'ಮುಳ್ಳಗಸೆ',
'ಮುಳ್ಳಮುಂಜ',
'ಮುಳ್ಳರಿವೆ',
'ಮುಳ್ಳವೊಂಗೆ',
'ಮುಳ್ಳಾವಿಗೆ',
'ಮುಳ್ಳಿಚುಳ್ಳಿ',
'ಮುಳ್ಳುಅಂಬೆ',
'ಮುಳ್ಳುಅಬ್ಬೆ',
'ಮುಳ್ಳುಂಗೊಳ್ಕೆ',
'ಮುಳ್ಳುಕಂಟಿ',
'ಮುಳ್ಳುಕಾರೆ',
'ಮುಳ್ಳುಕೀರೆ',
'ಮುಳ್ಳುಕುರ',
'ಮುಳ್ಳುಕೊಗ್ಗಿ',
'ಮುಳ್ಳುಕೊಗ್ಗೆ',
'ಮುಳ್ಳುಕೋಲು',
'ಮುಳ್ಳುಕೋಲೆ',
'ಮುಳ್ಳುಗಳ್ಳಿ',
'ಮುಳ್ಳುಗಿಡ',
'ಮುಳ್ಳುಗೊಗ್ಗಿ',
'ಮುಳ್ಳುಗೋಲು',
'ಮುಳ್ಳುಚುಳ್ಳಿ',
'ಮುಳ್ಳುಜವೆ',
'ಮುಳ್ಳುತಂತಿ',
'ಮುಳ್ಳುತಾರೆ',
'ಮುಳ್ಳುದಂಟು',
'ಮುಳ್ಳುದುವೆ',
'ಮುಳ್ಳುನೆಲ್ಲಿ',
'ಮುಳ್ಳುಬಳ್ಳಿ',
'ಮುಳ್ಳುಬೀಜ',
'ಮುಳ್ಳುಬೇಂಗ',
'ಮುಳ್ಳುಬೇಲಿ',
'ಮುಳ್ಳುಮರ',
'ಮುಳ್ಳುಮುಖ',
'ಮುಳ್ಳುಮುರಿ',
'ಮುಳ್ಳುಮುಷ್ಟ',
'ಮುಳ್ಳುಮುಱಿ',
'ಮುಳ್ಳುಮೊನೆ',
'ಮುಳ್ಳುವಂದಿ',
'ಮುಳ್ಳುವಿಂಡು',
'ಮುಳ್ಳುವೇಲಿ',
'ಮುಳ್ಳುವೊಂಗೆ',
'ಮುಳ್ಳುವೊರೆ',
'ಮುಳ್ಳುಸೀಗೆ',
'ಮುಳ್ಳುಸುಂಡೆ',
'ಮುಳ್ಳುಸೌತೆ',
'ಮುಳ್ಳುಹಂದಿ',
'ಮುಳ್ಳುಹಣ್ಣು',
'ಮುಳ್ಳುಹುಳ',
'ಮುಳ್ಳುಹೊನ್ನೆ',
'ಮುಱಿಕಾಯಿ',
'ಮುಱಿಕಿಸು',
'ಮುಱಿಗಣ್',
'ಮುಱಿಗೆಡೆ',
'ಮುಱಿಗೆಯ್',
'ಮುಱಿನೂಕು',
'ಮುಱಿಯಿಸು',
'ಮುಱಿಯೆಸು',
'ಮುಱಿಯೊಡೆ',
'ಮುಱಿಯೊತ್ತು',
'ಮುಱಿಯೊದೆ',
'ಮುಱಿವಡೆ',
'ಮುಱುಂಕಿಸು',
'ಮುಱುಕಿಲ್',
'ಮುಱುಕಿಸು',
'ಮುಱುದೊಂಡೆ',
'ಮುೞುಂಗಿಸು',
'ಮುೞುಗಿಸು',
'ಮೂಕನೋವು',
'ಮೂಕಪಶು',
'ಮೂಕಬಲಿ',
'ಮೂಕಬ್ರಹ್ಮ',
'ಮೂಕರಿಕೆ',
'ಮೂಕರಿವೆ',
'ಮೂಕಶೆಖೆ',
'ಮೂಕಶೋಕ',
'ಮೂಕಸೆಖೆ',
'ಮೂಕಾಂಬಿಕೆ',
'ಮೂಕಾರತಿ',
'ಮೂಕಿಚಿತ್ರ',
'ಮೂಕೀಭಾವ',
'ಮೂಕೊರತಿ',
'ಮೂಕೊರೆಯ',
'ಮೂಕೊಱತಿ',
'ಮೂಕೊಱೆಗ',
'ಮೂಕೊಱೆಯ',
'ಮೂಗಂಡುಗ',
'ಮೂಗಂದಾಯ',
'ಮೂಗತ್ತಲೆ',
'ಮೂಗರಿಕಿ',
'ಮೂಗವಲ್',
'ಮೂಗಾವುದ',
'ಮೂಗುಗಾಯ',
'ಮೂಗುಜಾತಿ',
'ಮೂಗುದಾಣ',
'ಮೂಗುದಾರ',
'ಮೂಗುನಾಚಿ',
'ಮೂಗುನೇಣು',
'ಮೂಗುನೋವು',
'ಮೂಗುಬಟ್ಟು',
'ಮೂಗುಬೊಟ್ಟು',
'ಮೂಗುಬ್ಬಸ',
'ಮೂಗುಮಾರಿ',
'ಮೂಗುಮಾಱಿ',
'ಮೂಗುವಡು',
'ಮೂಗುಸನ್ನೆ',
'ಮೂಗುಸಿರ್',
'ಮೂಗುಸೆಕೆ',
'ಮೂಗೇಣಿಡು',
'ಮೂಗೇರಿಸು',
'ಮೂಗೊಪ್ಪಿಗೆ',
'ಮೂಟೆಕಟ್ಟು',
'ಮೂಟೆಸುಂಕ',
'ಮೂಡಗಡೆ',
'ಮೂಡವೆಟ್ಟ',
'ಮೂಡವೆಟ್ಟು',
'ಮೂಡುವೆಟ್ಟ',
'ಮೂಡುವೆಟ್ಟು',
'ಮೂಡೆಗಟ್ಟು',
'ಮೂಢತನ',
'ಮೂಢತ್ರಯ',
'ಮೂಢಭಕ್ತಿ',
'ಮೂಢಮತಿ',
'ಮೂಢಮರ',
'ಮೂತ್ರಕಟ್ಟು',
'ಮೂತ್ರಕಲ್ಲು',
'ಮೂತ್ರಕೂಪ',
'ಮೂತ್ರಕೃಚ್ಛ್ರ',
'ಮೂತ್ರಕೃಚ್ಛ್ರೆ',
'ಮೂತ್ರಕೋಶ',
'ಮೂತ್ರಕ್ಷಯ',
'ಮೂತ್ರಗ್ರಂಥ',
'ಮೂತ್ರಚೀಲ',
'ಮೂತ್ರತಡೆ',
'ಮೂತ್ರದೋಷ',
'ಮೂತ್ರದ್ವಾರ',
'ಮೂತ್ರನಾಳ',
'ಮೂತ್ರಪಿಂಡ',
'ಮೂತ್ರಪ್ಲೀಹ',
'ಮೂತ್ರಬಂಧ',
'ಮೂತ್ರರಕ್ತ',
'ಮೂತ್ರರೋಗ',
'ಮೂತ್ರವಸ್ತಿ',
'ಮೂತ್ರವಾತ',
'ಮೂತ್ರವ್ಯಾಧಿ',
'ಮೂತ್ರಶಂಕೆ',
'ಮೂತ್ರಶುಕ್ಲ',
'ಮೂತ್ರಶೂಲೆ',
'ಮೂತ್ರಸಾದ',
'ಮೂತ್ರಸಿಕ್ಕು',
'ಮೂತ್ರಾಘಾತ',
'ಮುತ್ರಾತೀತ',
'ಮುತ್ರಾಶಯ',
'ಮುತ್ರಾಷ್ಠೀಲ',
'ಮೂತ್ರೋತ್ಸಂಗ',
'ಮೂದಲಿಕೆ',
'ಮೂದಲಿಸು',
'ಮೂಬದಲು',
'ಮೂಬದಲೆ',
'ಮೂರಾಬಟ್ಟೆ',
'ಮೂರಿಗೊಳ್',
'ಮೂರಿಯಿಡು',
'ಮೂರಿವಿಡು',
'ಮೂರುಕಾಸು',
'ಮೂರುಸಂಜೆ',
'ಮೂರ್ಖತನ',
'ಮೂರ್ಖವೃತ್ತಿ',
'ಮೂಚ್ರ್ಛೆತಿಳಿ',
'ಮೂಚ್ರ್ಛೆವೋಗು',
'ಮೂರ್ಛೆತಿಳಿ',
'ಮೂರ್ಛೆದಿಳಿ',
'ಮೂರ್ಛೆವೋಗು',
'ಮೂರ್ಛೆಹೋಗು',
'ಮೂರ್ತಂಗೆಯ್',
'ಮೂರ್ತಗೊಳ್',
'ಮೂರ್ತಿಗೊಳ್',
'ಮೂರ್ತಿಗೊಳ್ಳು',
'ಮೂರ್ತಿತ್ರಯ',
'ಮೂರ್ತಿಪೂಜೆ',
'ಮೂರ್ತಿಬಲ',
'ಮೂರ್ತಿಮಂತ',
'ಮೂರ್ತಿಮತಿ',
'ಮೂರ್ತಿವೆರು',
'ಮೂರ್ತಿವೆಱು',
'ಮೂರ್ತಿಶಿಲ್ಪ',
'ಮೂರ್ತೆದಾರ',
'ಮೂರ್ಧಪಿಂಡ',
'ಮೂರ್ಧವ್ಯಾಧಿ',
'ಮೂರ್ಮಡಿಸು',
'ಮೂಲಕಂದ',
'ಮೂಲಕೃತಿ',
'ಮೂಲಗಂಟು',
'ಮೂಲಗಾರ',
'ಮೂಲಗಿಸು',
'ಮೂಲಗುಣ',
'ಮೂಲಗೇಣಿ',
'ಮೂಲಗ್ರಂಥ',
'ಮೂಲಚ್ಛೇದ',
'ಮೂಲತತ್ವ',
'ಮೂಲದಳ',
'ಮೂಲದ್ರವ್ಯ',
'ಮೂಲಧನ',
'ಮೂಲಧಾತು',
'ಮೂಲಪತ್ರ',
'ಮೂಲಪಾಠ',
'ಮೂಲಪ್ರತಿ',
'ಮೂಲಬಂಧ',
'ಮೂಲಬಲ',
'ಮೂಲಬೇರ',
'ಮೂಲಭಾಷೆ',
'ಮೂಲಭೂತ',
'ಮೂಲಮಂತ್ರ',
'ಮೂಲಮಾನ',
'ಮೂಲಮೂರ್ತಿ',
'ಮೂಲರಸ',
'ಮೂಲರೋಗ',
'ಮೂಲವರ್ಣ',
'ಮೂಲವಸ್ತು',
'ಮೂಲವ್ಯಾಧಿ',
'ಮೂಲಶಬ್ದ',
'ಮೂಲಸಂಘ',
'ಮೂಲಸ್ತಂಭ',
'ಮೂಲಸ್ಥಾನ',
'ಮೂಲಾಧಾರ',
'ಮೂಲಿಯಿಡು',
'ಮೂಲಿವರ್ಗ',
'ಮೂಲೆಗಂಬ',
'ಮೂಲೆಗಲ್ಲು',
'ಮೂಲೆಗುಂಪು',
'ಮೂಲೆಮಟ್ಟ',
'ಮೂಲೋತ್ಪಾಟ',
'ಮೂವಡಿಸು',
'ಮೂವಳಸು',
'ಮೂವಳಿಸು',
'ಮೂವೆರಲ್',
'ಮೂವೆರಲು',
'ಮೂವೆರಳ್',
'ಮೂವೆರಳು',
'ಮೂಷಪರ್ಣಿ',
'ಮೂಸಿಮರ',
'ಮೂಹೊಱಡೆ',
'ಮೂಳುಕಿವಿ',
'ಮೂಳುನಾಯಿ',
'ಮೂಳೆದುಪ್ಪ',
'ಮೂಳೆರೋಗ',
'ಮೂಱಡಿಗ',
'ಮೂಱುಸಂಜೆ',
'ಮೂೞಕಿವಿ',
'ಮೃಗಗೃಹ',
'ಮೃಗಜಲ',
'ಮೃಗತತಿ',
'ಮೃಗತೃಷ್ಣೆ',
'ಮೃಗದಂಶ',
'ಮೃಗಧರ',
'ಮೃಗಧೂರ್ತ',
'ಮೃಗನಾಭಿ',
'ಮೃಗನಾರಿ',
'ಮೃಗನೇತ್ರ',
'ಮೃಗನೇತ್ರೆ',
'ಮೃಗಪತಿ',
'ಮೃಗಪ್ಲುತ',
'ಮೃಗಮದ',
'ಮೃಗರಾಜ',
'ಮೃಗರಿಪು',
'ಮೃಗಲಕ್ಷ್ಮ',
'ಮೃಗಲಿಪಿ',
'ಮೃಗಶಿರ',
'ಮೃಗಶಿರೆ',
'ಮೃಗಶೀರ್ಷ',
'ಮೃಗಶೃಂಗ',
'ಮೃಗಾಜಿನ',
'ಮೃಗಾದನ',
'ಮೃಗಾರಾತಿ',
'ಮೃಗಾಲಯ',
'ಮೃಗಾಸನ',
'ಮೃಗೋದ್ಭವ',
'ಮೃಣಾಳಿಕೆ',
'ಮೃತಕಲ್ಪ',
'ಮೃತಪತ್ರ',
'ಮೃತಭಾಷೆ',
'ಮೃತಾಲಕ',
'ಮೃತಾಶೌಚ',
'ಮೃತಿಪತ್ರ',
'ಮೃತ್ಯುಂಜಯ',
'ಮೃತ್ಯುದೇವಿ',
'ಮೃತ್ಯುಪತ್ರ',
'ಮೃತ್ಯುಮುಖ',
'ಮೃತ್ಯುರಾಜ',
'ಮೃತ್ಯುಲೋಕ',
'ಮೃತ್ಯುಶಿಲೆ',
'ಮೃತ್ಯುಹರ',
'ಮೃತ್ಸ್ನಾಘಟ',
'ಮೃದುಗಂಧಿ',
'ಮೃದುಜಲ',
'ಮೃದುಪಾಕ',
'ಮೃದುಲತೆ',
'ಮೃದುವಪು',
'ಮೃದುವಾಕ್ಯ',
'ಮೃದುವೆಱು',
'ಮೃಷಾನಂದ',
'ಮೆಂತ್ಯಪಲ್ಯ',
'ಮೆಂತ್ಯಸೊಪ್ಪು',
'ಮೆಕ್ಕೆಜೋಳ',
'ಮೆಚ್ಚುಕಾಱ',
'ಮೆಚ್ಚುಕೆಯ್',
'ಮೆಚ್ಚುಗುಡು',
'ಮೆಚ್ಚುಗೊಡು',
'ಮೆಚ್ಚುಗೊಳ್',
'ಮೆಚ್ಚುದೋಱು',
'ಮೆಚ್ಚುವಡು',
'ಮೆಚ್ಚುವಡೆ',
'ಮೆಚ್ಚುವೆಣ್',
'ಮೆಟಗುಳಿ',
'ಮೆಟ್ಟಲಿಗೆ',
'ಮೆಟ್ಟಿಂಗಾಲು',
'ಮೆಟ್ಟುಂಗುಟ',
'ಮೆಟ್ಟುಕತ್ತಿ',
'ಮೆಟ್ಟುಕಲ್ಲು',
'ಮೆಟ್ಟುಗಟ್ಟೆ',
'ಮೆಟ್ಟುಗತ್ತಿ',
'ಮೆಟ್ಟುಗಲ್ಲು',
'ಮೆಟ್ಟುಗಾಲು',
'ಮೆಟ್ಟುಗೆಯ್',
'ಮೆಟ್ರೆಕಟ್ಟು',
'ಮೆಟ್ರೆಗಟ್ಟು',
'ಮೆಡಶಿರ',
'ಮೆಡ್ಡಗಣ್ಣ',
'ಮೆಡ್ಡಗಣ್ಣು',
'ಮೆಡ್ಡರಿಸು',
'ಮೆಣಸಿಕೆ',
'ಮೆಣಸಿಗ',
'ಮೆಣಸಿಗೆ',
'ಮೆತ್ತನೆಯ',
'ಮೆದುಗಲ',
'ಮೆದ್ದೆಸೊಪ್ಪು',
'ಮೆಯಿದೆಱೆ',
'ಮೆಯ್ಕೊಳಿಸು',
'ಮೆಯ್ಗಡಿತ',
'ಮೆಯ್ಗರಗು',
'ಮೆಯ್ಗಾಣಿಸು',
'ಮೆಯ್ಗಾವಲು',
'ಮೆಯ್ಗೆದಱು',
'ಮೆಯ್ಗೊಡಪು',
'ಮೆಯ್ದಡವು',
'ಮೆಯ್ದಡಹು',
'ಮೆಯ್ದುನಿಸು',
'ಮೆಯ್ದೋಱಿಸು',
'ಮೆಯ್ಮಱವೆ',
'ಮೆಯ್ಮಱೆಪು',
'ಮೆಯ್ಮುರಿಚು',
'ಮೆಯ್ಮುಱಿಕ',
'ಮೆಯ್ಯವುತೆ',
'ಮೆಯ್ಯಱಿವು',
'ಮೆಯ್ಯುಡುಗು',
'ಮೆರವಣಿ',
'ಮೆರುಗೆಣ್ಣೆ',
'ಮೆರೆಕೋಲು',
'ಮೆರಗೋಲು',
'ಮೆರೆಯಿಸು',
'ಮೆಲಕಾಡು',
'ಮೆಲುಕಾಟ',
'ಮೆಲುಕಾಡು',
'ಮೆಲುಕಿಡು',
'ಮೆಲುಕಿರಿ',
'ಮೆಲುಕಿಱಿ',
'ಮೆಲ್ಪುಗುಂದು',
'ಮೆಲ್ಪುಗೆಡು',
'ಮೆಲ್ಪುಗೊಳ್',
'ಮೆಲ್ಪುದೋಱು',
'ಮೆಲ್ಪುವೆರು',
'ಮೆಲ್ಪುವೆಱು',
'ಮೆಲ್ಲಡಿಯ',
'ಮೆಲ್ಲಿದಳ್',
'ಮೆಲ್ಲುಲಿಪು',
'ಮೆಲ್ಲೆರ್ದೆಗ',
'ಮೆಹನತು',
'ಮೆಹನತ್ತು',
'ಮೆಹರ್ಬಾನ್',
'ಮೆಹರ್ಬಾನು',
'ಮೆಹೆತರಿ',
'ಮೆಳಸಿಗೆ',
'ಮೆಳೆಗುತ್ತಿ',
'ಮೆಳ್ಳಗಣ್ಣ',
'ಮೆಳ್ಳಗಣ್ಣು',
'ಮೆಳ್ಳುಗಣ್ಣು',
'ಮೆಳ್ಳೆಗಣ್',
'ಮೆಳ್ಳೆಗಣ್ಣ',
'ಮೆಳ್ಳೆಗಣ್ಣು',
'ಮೆಱವಣಿ',
'ಮೆಱವಣೆ',
'ಮೆಱೆಕೋಲು',
'ಮೆಱೆಗಾಱ',
'ಮೆಱೆಗೋಲು',
'ಮೆಱೆಯಿಸು',
'ಮೆಱೆವಣಿ',
'ಮೆೞ್ಪಡಿತು',
'ಮೆೞ್ಪಡಿಸು',
'ಮೇಂದುಜ್ವರ',
'ಮೇಕುದೋಱ್',
'ಮೇಕುದೋಱು',
'ಮೇಗಾವಲು',
'ಮೇಗುಗಾಣ್',
'ಮೇಗುದೋಱ್',
'ಮೇಗುದೋಱು',
'ಮೇಗುವೇೞ್',
'ಮೇಗುಸಿರ್',
'ಮೇಘಕಾಲ',
'ಮೇಘಚುಂಬಿ',
'ಮೇಘಜ್ಯೋತಿ',
'ಮೇಘದ್ವಾರ',
'ಮೇಘನಾದ',
'ಮೇಘನಾದಿ',
'ಮೇಘಪುಷ್ಪ',
'ಮೇಘಮಾರ್ಗ',
'ಮೇಘರಂಜಿ',
'ಮೇಘಲೋಕ',
'ಮೇಘವರ್ಣ',
'ಮೇಘವಹ್ನಿ',
'ಮೇಘಶ್ಯಾಮ',
'ಮೇಘಾಗಮ',
'ಮೇಚಕತೆ',
'ಮೇಚಕಿತ',
'ಮೇಚಣಿಗೆ',
'ಮೇಜವಾಣಿ',
'ಮೇಜವಾನಿ',
'ಮೇಜಿಟ್ಟಿಗೆ',
'ಮೇಜುಕಟ್ಟು',
'ಮೇಜುವಾನಿ',
'ಮೇಟಿಕಂಬ',
'ಮೇಟಿಕಂಭ',
'ಮೇಟಿಗೂಟ',
'ಮೇಟಿಪಾಲು',
'ಮೇಟಿವಿದ್ಯ',
'ಮೇಟಿವಿದ್ಯೆ',
'ಮೇಣಗಟ್ಟು',
'ಮೇಣಬತ್ತಿ',
'ಮೇಣಿಪಾಲು',
'ಮೇಣಿಹಾಲು',
'ಮೇಣುಹುಣ್ಣು',
'ಮೇದದೆಱೆ',
'ಮೇದಿನಿಪ',
'ಮೇದೋಧಾತು',
'ಮೇಧಾಯುಕ್ತ',
'ಮೇಧಾಯುಕ್ತೆ',
'ಮೇಧಾವಂತ',
'ಮೇಧಾವಿತೆ',
'ಮೇಧಾವಿಳ',
'ಮೇಧಾಶಕ್ತಿ',
'ಮೇಪುಗಾಡು',
'ಮೇಪುಗೊಳ್',
'ಮೇಪುವಿಡು',
'ಮೇರುದಂಡ',
'ಮೇರುದಂಡೆ',
'ಮೇರುನಗ',
'ಮೇರುಮಾನಿ',
'ಮೇರುವೃತ್ತ',
'ಮೇರೆಗಟ್ಟು',
'ಮೇರೆಗಲ್ಲು',
'ಮೇರೆಗಳೆ',
'ಮೇರೆಗಿಡು',
'ಮೇರೆಗೆಡು',
'ಮೇರೆದಪ್ಪು',
'ಮೇರೆಮೀರು',
'ಮೇರೆಮೀಱು',
'ಮೇರೆವರಿ',
'ಮೇರೆಸಾಲು',
'ಮೇಲಂಕಣ',
'ಮೇಲಾಪಕ',
'ಮೇಲಾಪಿಸು',
'ಮೇಲಾರಯ್ಕೆ',
'ಮೇಲಾರೈಕೆ',
'ಮೇಲಾಳಿಕೆ',
'ಮೇಲುಕಟ್ಟು',
'ಮೇಲುಖರ್ಚು',
'ಮೇಲುಗಟ್ಟು',
'ಮೇಲುಗಡೆ',
'ಮೇಲುಗಣ್ಣು',
'ಮೇಲುಗವಿ',
'ಮೇಲುಗಾಣು',
'ಮೇಲುಗಾಹು',
'ಮೇಲುಗೆಯ್',
'ಮೇಲುಚೀಟಿ',
'ಮೇಲುಜಗ',
'ಮೇಲುಜೂಜು',
'ಮೇಲುಡುಪು',
'ಮೇಲುತನ',
'ಮೇಲುತರ',
'ಮೇಲುದಾಯ',
'ಮೇಲುದೋಱು',
'ಮೇಲುನಾಡು',
'ಮೇಲುನೆಲ',
'ಮೇಲುನೋಟ',
'ಮೇಲುಪಂಕ್ತಿ',
'ಮೇಲುಪೋಗು',
'ಮೇಲುಪ್ಪರ',
'ಮೇಲುಬಟ್ಟೆ',
'ಮೇಲುಬೀಯ',
'ಮೇಲುಬ್ಬಸ',
'ಮೇಲುಭಾಗ',
'ಮೇಲುಮಕ್ಕಿ',
'ಮೇಲುಮಚ್ಚು',
'ಮೇಲುಮಟ್ಟ',
'ಮೇಲುಮದ',
'ಮೇಲುಮಾಡು',
'ಮೇಲುಮುದ್ದೆ',
'ಮೇಲುವರಿ',
'ಮೇಲುವಾಯ್',
'ಮೇಲುವಾಸಿ',
'ಮೇಲುವಾಸು',
'ಮೇಲುವೆಚ್ಚ',
'ಮೇಲುಸಾಲು',
'ಮೇಲುಸಿರ್',
'ಮೇಲುಸಿರು',
'ಮೇಲುಸುರು',
'ಮೇಲುಹೊಳೆ',
'ಮೇಲೆತರು',
'ಮೇಲೆನೆಲ್ಲಿ',
'ಮೇಲೆಬರು',
'ಮೇಲೆಬೀಳ್',
'ಮೇಲೆಬೀಳು',
'ಮೇಲೆಬೀೞ್',
'ಮೇಲೆವರ್',
'ಮೇಲೆವರಿ',
'ಮೇಲೆವಾಯ್',
'ಮೇಲೆವೀೞ್',
'ಮೇಲೋಗರ',
'ಮೇಲ್ಗಾವಲು',
'ಮೇಲ್ಗೊಳಗ',
'ಮೇಲ್ಚಾವಣಿ',
'ಮೇಲ್ಛಾವಣಿ',
'ಮೇಲ್ತನಿಖೆ',
'ಮೇಲ್ತೆರಿಗೆ',
'ಮೇಲ್ತೋರಿಕೆ',
'ಮೇಲ್ದುಡುಗೆ',
'ಮೇಲ್ಮನವಿ',
'ಮೇಲ್ಮಾಳಿಗೆ',
'ಮೇಲ್ಮುಚ್ಚಳ',
'ಮೇಲ್ಮುಸುಕು',
'ಮೇಲ್ಮುಸುಗು',
'ಮೇಲ್ವಲಗೆ',
'ಮೇಲ್ವೊದಕೆ',
'ಮೇಲ್ವೊದಿಕೆ',
'ಮೇಲ್ಸಕ್ಕರೆ',
'ಮೇಲ್ಸೆರಗು',
'ಮೇಲ್ಸೆಱಗು',
'ಮೇಷಪಂಥ',
'ಮೇಷಮಾಸ',
'ಮೇಷಯುದ್ಧ',
'ಮೇಷಶೃಂಗಿ',
'ಮೇಹನತ್',
'ಮೇಹನತ್ತು',
'ಮೇಹರೋಗ',
'ಮೇಹುಗಾಡು',
'ಮೇಹುಗಾಱ',
'ಮೇಹುಗೊಳ್',
'ಮೇಳಂಗೆಯ್',
'ಮೇಳಂಗೊಳ್',
'ಮೇಳಕಟ್ಟು',
'ಮೇಳಗಟ್ಟು',
'ಮೇಳಗಾನ',
'ಮೇಳಗಾರ',
'ಮೇಳಗಾರ್ತಿ',
'ಮೇಳಗಾಱ',
'ಮೇಳಗೀತ',
'ಮೇಳಗೀತೆ',
'ಮೇಳಗೂಡು',
'ಮೇಳಗೊಳು',
'ಮೇಳಗೊಳ್ಳು',
'ಮೇಳಯಿಸು',
'ಮೇಳರಂಗ',
'ಮೇಳವಡೆ',
'ಮೇಳವಣೆ',
'ಮೇಳವಾಡು',
'ಮೇಳವಿಕೆ',
'ಮೇಳವಿಡಿ',
'ಮೇಳವಿಸು',
'ಮೇಳಾಪಕ',
'ಮೇಳಾಪಿಸು',
'ಮೇಳುಗೂಡು',
'ಮೈಂದವಾೞೆ',
'ಮೈಕಾಸುರ',
'ಮೈಕೆಬೇರು',
'ಮೈಕೊಡವು',
'ಮೈಕ್ರೋಸ್ಕೋಪು',
'ಮೈಗತ್ತಲೆ',
'ಮೈಗಾಣಿಸು',
'ಮೈಗಾವಲು',
'ಮೈಗೂಡಿಸು',
'ಮೈಗೊಡಹು',
'ಮೈಗೊಳಿಸು',
'ಮೈಜಂಗಳ',
'ಮೈತಿಮಿರ್',
'ಮೈತಿಮಿರ',
'ಮೈದಡವು',
'ಮೈದಡಹು',
'ಮೈದಿಗುರ್',
'ಮೈದೆಗಹು',
'ಮೈನಂಬಿಗೆ',
'ಮೈಮರವು',
'ಮೈಮರೆತ',
'ಮೈಮರೆವು',
'ಮೈಮರೆಸು',
'ಮೈಮರೆಹು',
'ಮೈಮಸಕ',
'ಮೈಮಱಸು',
'ಮೈಮೆಗಾಱ',
'ಮೈಮೆದಳೆ',
'ಮೈಮೆವಡೆ',
'ಮೈಮೆವೆಱು',
'ಮೈಯಿಳಿತ',
'ಮೈಯುಡುಗು',
'ಮೈಲತುತ್ತ',
'ಮೈಲವಣಿ',
'ಮೈಲವಣೆ',
'ಮೈಲಿಕಲ್ಲು',
'ಮೈಲಿಗಲ್ಲು',
'ಮೈಲಿತೆಗೆ',
'ಮೈಲುತುತ್ತ',
'ಮೈಲುತುತ್ತು',
'ಮೈಲುತುತ್ಥ',
'ಮೈಲುತೆಗೆ',
'ಮೈಲೆರೋಗ',
'ಮೈವಳಯ',
'ಮೈವಳಿಸು',
'ಮೈಸರಸ',
'ಮೈಸುನಾಡು',
'ಮೊಂಡಗಳ್ಳಿ',
'ಮೊಂಡತನ',
'ಮೊಂಡರಳು',
'ಮೊಂಡುಗರೆ',
'ಮೊಂಡುಗಳ್ಳಿ',
'ಮೊಂಡುತನ',
'ಮೊಂಡುವಾದ',
'ಮೊಕಚಿಟ್ಟು',
'ಮೊಕದ್ದಮೆ',
'ಮೊಕರಂಬ',
'ಮೊಕರಂಬೆ',
'ಮೊಕರರ',
'ಮೊಕರರು',
'ಮೊಕರಾರು',
'ಮೊಕರೂರು',
'ಮೊಕವಾಡ',
'ಮೊಕಾಬಲಾ',
'ಮೊಕಾಬಿಲೆ',
'ಮೊಕ್ತಿಯಾರಿ',
'ಮೊಕ್ತೇಸರ್',
'ಮೊಕ್ತೇಸರ',
'ಮೊಖದ್ದಮೆ',
'ಮೊಖಾಂತರ',
'ಮೊಖಾಬಿಲೆ',
'ಮೊಖ್ತಿಯಾರ್',
'ಮೊಖ್ತೇಸರ್',
'ಮೊಖ್ತೇಸರ',
'ಮೊಗಂಗಿಡು',
'ಮೊಗಂಗುಡು',
'ಮೊಗಂಗೊಡು',
'ಮೊಗಂದಿರಿ',
'ಮೊಗಂದೋಱು',
'ಮೊಗಂನೋಡು',
'ಮೊಗಂಬಡೆ',
'ಮೊಗಂಬುಗು',
'ಮೊಗಂಬೊಗು',
'ಮೊಗಂಮುರಿ',
'ಮೊಗಗುಡು',
'ಮೊಗಗೆಡು',
'ಮೊಗಗೊಡು',
'ಮೊಗತುತಿ',
'ಮೊಗದೆಗೆ',
'ಮೊಗದೆರೆ',
'ಮೊಗದೆಱೆ',
'ಮೊಗದೊಳೆ',
'ಮೊಗದೋರು',
'ಮೊಗದೋಱು',
'ಮೊಗಪಟ',
'ಮೊಗಬೀಣೆ',
'ಮೊಗಮರೆ',
'ಮೊಗಮಿಕ್ಕು',
'ಮೊಗಮಿಡು',
'ಮೊಗಮುಟ್ಟು',
'ಮೊಗಮುರಿ',
'ಮೊಗಮೋಟ',
'ಮೊಗರಂಬ',
'ಮೊಗರಸ',
'ಮೊಗಲಾಯ್',
'ಮೊಗಲಾಯಿ',
'ಮೊಗಲಿಂಗ',
'ಮೊಗವಚ್ಚ',
'ಮೊಗವಟ್ಟ',
'ಮೊಗವಡ',
'ಮೊಗವಾಡ',
'ಮೊಗವಾಸ',
'ಮೊಗವಾಳೆ',
'ಮೊಗವಿಕ್ಕು',
'ಮೊಗವಿಡು',
'ಮೊಗವೀಣೆ',
'ಮೊಗವೀರ',
'ಮೊಗಶಾಲೆ',
'ಮೊಗಸಾಲೆ',
'ಮೊಗಸಿರಿ',
'ಮೊಗಸುತ್ತು',
'ಮೊಗೆತರ್',
'ಮೊಗೆಬಲೆ',
'ಮೊಗೆವಲೆ',
'ಮೊಗೇರತಿ',
'ಮೊಗ್ಗರಣೆ',
'ಮೊಗ್ಗರಿಸು',
'ಮೊಚ್ಚೆಗಾರ',
'ಮೊಚ್ಚೆಗಾಱ',
'ಮೊಟಕಿಸು',
'ಮೊಟ್ಟಯಿಸು',
'ಮೊಟ್ಟಕಟ್ಟು',
'ಮೊಟ್ಟೆಕಾಱ',
'ಮೊಟ್ಟೆಕೋಳಿ',
'ಮೊಟ್ಟೆಗಟ್ಟು',
'ಮೊಟ್ಟೆಗಾರ',
'ಮೊಟ್ಟೆಗೂಳ್',
'ಮೊಡೆನಾತ',
'ಮೊಡನಾಱು',
'ಮೊಣಕಾಲ್',
'ಮೊಣಕಾಲು',
'ಮೊಣಕೆಯ್',
'ಮೊಣಗಂಟು',
'ಮೊಣಸಂದು',
'ಮೊತ್ತಂಗಟ್ಟು',
'ಮೊತ್ತಂಗೊಳ್',
'ಮೊತ್ತಂಬೆಱು',
'ಮೊತ್ತಕಾಱ',
'ಮೊತ್ತಗಟ್ಟು',
'ಮೊತ್ತವೆಱು',
'ಮೊದಲಚ್ಚು',
'ಮೊದಲನೆ',
'ಮೊದಲನೇ',
'ಮೊದಲಾರಿ',
'ಮೊದಲಿಕ್ಕು',
'ಮೊದಲಿಗ',
'ಮೊದಲಿಡು',
'ಮೊದಲಿಸು',
'ಮೊದಲೇರಿ',
'ಮೊದಲೇಱಿ',
'ಮೊದಲ್ಗಾಲ್',
'ಮೊದಲ್ಗಿಡು',
'ಮೊದಲ್ಗೆಡು',
'ಮೊದಲ್ಗೆಯ್',
'ಮೊದಲ್ಗೊಳ್',
'ಮೊದಲ್ಗೊಳು',
'ಮೊದೀಖಾನೆ',
'ಮೊದ್ದುಮಣಿ',
'ಮೊನೆಕಟ್ಟು',
'ಮೊನೆಕಾಱ',
'ಮೊನೆಗಯ್ಯಿ',
'ಮೊನೆಗಾತಿ',
'ಮೊನೆಗಾರ',
'ಮೊನೆಗಾರು',
'ಮೊನೆಗಾಲ್',
'ಮೊನೆಗಾಲು',
'ಮೊನೆಗಾಱ',
'ಮೊನೆಗೊಡು',
'ಮೊನೆಗೊಳ್ಳು',
'ಮೊನೆಗೋಲು',
'ಮೊನೆದೋರು',
'ಮೊನೆದೋಱ್',
'ಮೊನೆದೋಱು',
'ಮೊನೆಮಾತು',
'ಮೊನೆಮುರಿ',
'ಮುನೆಮುಱಿ',
'ಮೊನೆಯಾಳ್',
'ಮೊನೆಯಾಳು',
'ಮೊನೆಯಿಡು',
'ಮೊನೆಯೇಡು',
'ಮೊನೆಯೇರು',
'ಮೊನೆಯೇಱು',
'ಮೊನೆವಾತು',
'ಮೊಬಲಗು',
'ಮೊಮುಜಾಮೆ',
'ಮೊಮ್ಮಕ್ಕಳು',
'ಮೊಮ್ಮಗಳು',
'ಮೊರಗುಟ್ಟು',
'ಮೊರೆಜ್ವರ',
'ಮೊರೆಯಿಡು',
'ಮೊರೆಹೊಗು',
'ಮೊರೆಹೋಗು',
'ಮೊಲಕಿವಿ',
'ಮೊಲಗಿವಿ',
'ಮೊಲೆಕಟ್ಟು',
'ಮೊಲೆಕುಡಿ',
'ಮೊಲೆಕೂಸು',
'ಮೊಲೆಕೊಡು',
'ಮೊಲೆಗಟ್ಟು',
'ಮೊಲೆಗುಡಿ',
'ಮೊಲೆಗುಡು',
'ಮೊಲೆಗೂಸು',
'ಮೊಲೆಗೊಟ್ಟು',
'ಮೊಲೆಗೊಡು',
'ಮೊಲೆತುದಿ',
'ಮೊಲೆತೊರೆ',
'ಮೊಲೆದುದಿ',
'ಮೊಲೆದೊಟ್ಟು',
'ಮೊಲೆನಾಗ',
'ಮೊಲೆನೀರು',
'ಮೊಲೆಬರ್',
'ಮೊಲೆಬರು',
'ಮೊಲೆಬಾವು',
'ಮೊಲೆಮರಿ',
'ಮೊಲೆಯುಣ್',
'ಮೊಲೆಯುಣು',
'ಮೊಲೆಯುಣ್ಣು',
'ಮೊಲೆಯೂಡು',
'ಮೊಲೆವಾಲ್',
'ಮೊಲೆವಾಲು',
'ಮೊಲೆವಿಡಿ',
'ಮೊಲೆವೆಱು',
'ಮೊಲೆವೊಱು',
'ಮೊಲೆಹಾಲು',
'ಮೊಲ್ಲಾಗರ',
'ಮೊಸರಣ್ಕೆ',
'ಮೊಸರನ್ನ',
'ಮೊಸರೊಡೆ',
'ಮೊಸರ್ಗೂೞ್',
'ಮೊಹತರ್ಫ',
'ಮೊಹಬತ್',
'ಮೊಹಬತ್ತು',
'ಮೊಹಸಲೆ',
'ಮೊಳಕಯ್',
'ಮೊಳಕಾಲ್',
'ಮೊಳಕಾಲು',
'ಮೊಳಗಾಡು',
'ಮೊಳಗಿಸು',
'ಮೊಳಸಂದು',
'ಮೊಳೆಗತ್ತಿ',
'ಮೊಳೆಗದ',
'ಮೊಳೆಗೊಳ್ಳು',
'ಮೊಳೆದೋರು',
'ಮೊಳೆದೋಱು',
'ಮೊಳೆನಗೆ',
'ಮೊಳೆನುಡಿ',
'ಮೊಳೆಯಿಸು',
'ಮೊಳೆರೋಗ',
'ಮೊಳೆವಣೆ',
'ಮೊಳೆವಿತ್ತು',
'ಮೊಳೆವೊಯ್',
'ಮೊಳೆವೋಗು',
'ಮೊಱೆಕಾಱ',
'ಮೊಱೆಗಿಡು',
'ಮೊಱೆಗೆಡು',
'ಮೊಱೆಗೆಯ್',
'ಮೊಱೆಗೇಡಿ',
'ಮೊಱೆಗೊಡು',
'ಮೊಱೆಗೊಳ್',
'ಮೊಱೆದಪ್ಪು',
'ಮೊಱೆಪಾರ್',
'ಮೊಱೆಯಿಕ್ಕು',
'ಮೊಱೆಯಿಡು',
'ಮೊಱೆಯೊಗು',
'ಮೊಱೆಯೋಗು',
'ಮೊಱೆವುಗು',
'ಮೊಱೆವೇಳ್',
'ಮೊಱೆವೇೞ್',
'ಮೊಱೆಸರ',
'ಮೊಱೆಹೊಗು',
'ಮೊೞಂಗಾಲ್',
'ಮೊೞಕೆಯ್',
'ಮೊೞಕಾಲ್',
'ಮೊೞಗಿಸು',
'ಮೋಕಳೀಕ',
'ಮೋಕಳೀಕು',
'ಮೋಕ್ಷಕಾಲ',
'ಮೋಕ್ಷಗತಿ',
'ಮೋಕ್ಷಗಾಮಿ',
'ಮೋಕ್ಷಪದ',
'ಮೋಚಕರಿ',
'ಮೋಚಶ್ರಾವ',
'ಮೋಚಸಾರ',
'ಮೋಜುಗಾರ',
'ಮೋಜುಗಾಱ',
'ಮೋಟಕೆಯ್',
'ಮೋಟಗಯ್ಯಿ',
'ಮೋಟಗೆಯ್',
'ಮೋಟವಾದ',
'ಮೋಟುಗೆಯ್',
'ಮೋಟ್ಟಾಯಿತ',
'ಮೋಡಂಗಪ್ಪು',
'ಮೋಡಾಮೋಡಿ',
'ಮೋಡಿಕಾರ',
'ಮೋಡಿಕಾರ್ತಿ',
'ಮೋಡಿಗಾರ',
'ಮೋಡಿಗೊಳ್',
'ಮೋಡಿಗೊಳು',
'ಮೋಡಿಗೊಳ್ಳು',
'ಮೋತಾದಾರ',
'ಮೋತಿಖಾನೆ',
'ಮೋತಿಬಿಂದು',
'ಮೋತೀಖಾನೆ',
'ಮೋತೀಚೂರು',
'ಮೋದಂಬೆಱು',
'ಮೋದನೀಯ',
'ಮೋದಿಖಾನೆ',
'ಮೋದೀಖಾನೆ',
'ಮೋದುಗಟ್ಟು',
'ಮೋದುವೆಱು',
'ಮೋನಂಗೆಡು',
'ಮೋನಂಗೊಳ್',
'ಮೋನಗೊಳ್',
'ಮೋನಗೊಳ್ಳು',
'ಮೋನದಳೆ',
'ಮೋಬದಲಿ',
'ಮೋರಚೂತ',
'ಮೋರ್ಚೆಕಟ್ಟು',
'ಮೋಸಕಾಱ',
'ಮೋಸಕೂಪ',
'ಮೋಸಗಾತಿ',
'ಮೋಸಗಾರ',
'ಮೋಸಗಾರ್ತಿ',
'ಮೋಸಗೊಳು',
'ಮೋಸಗೊಳ್ಳು',
'ಮೋಸವಡೆ',
'ಮೋಹಕತೆ',
'ಮೋಹಗೊಳ್ಳು',
'ಮೋಹನಾಂಗ',
'ಮೋಹನಾಂಗಿ',
'ಮೋಹನಾಸ್ತ್ರ',
'ಮೋಹನಿದ್ರೆ',
'ಮೋಹನೀಯ',
'ಮೋಹಪಾಶ',
'ಮೋಹಭಾವ',
'ಮೋಹರಸ',
'ಮೋಹರಿಸು',
'ಮೋಹಲತೆ',
'ಮೋಹವೆಚ್ಚು',
'ಮೋಹವೆಣ್',
'ಮೋಹಶಾಸ್ತ್ರ',
'ಮೋಹಸಲೆ',
'ಮೋಹಳಿಸು',
'ಮೋಹಾತುರ',
'ಮೋಹಾತುರೆ',
'ಮೋಹಾಪೋಹ',
'ಮೋಹಾಲಸ್ಯ',
'ಮೋಹಿಚಕ',
'ಮೋಹಿವೆಣ್',
'ಮೋಹಿವೆಣ್ಣು',
'ಮೋಳಬೆಕ್ಕು',
'ಮೋಳೆಯೋಗು',
'ಮೌಂಜೀಬಂಧ',
'ಮೌನಂಗೊಳ್',
'ಮೌನಗೊಳ್',
'ಮೌನಗೌರಿ',
'ಮೌನಮುದ್ರೆ',
'ಮೌನವಡು',
'ಮೌನವ್ರತ',
'ಮೌರಜಿಕ',
'ಮೌರಜಿಗ',
'ಮೌರ್ವೀನಾದ',
'ಮೌಲಬಲ',
'ಮೌಲಿಕತೆ',
'ಮೌಲ್ಯಧನ',
'ಮೌಹಳಿಗ',
'ಮೌಹೂರ್ತಿಕ',
'ಮೌಳಿಕಾಱ',
'ಮ್ಯಾಜಿಸ್ತ್ರೇಟು',
'ಮ್ಯಾನೇಜರು',
'ಮ್ಯುಝಿಯಮ್ಮು',
'ಮ್ಲೇಂಛಖಂಡ',
'ಮ್ಲೇಚ್ಛಖಂಡ',
'ಮ್ಲೇಚ್ಛದೇಶ',
'ಮ್ಲೇಚ್ಛಭಾಷೆ',
'ಮ್ಲೇಚ್ಛಮುಖ',
'ಯಂತ್ರಕರ್ಮಿ',
'ಯಂತ್ರಕಲೆ',
'ಯಂತ್ರಕಾರ',
'ಯಂತ್ರಮುಕ್ತ',
'ಯಂತ್ರಯುಗ',
'ಯಂತ್ರಶಾಸ್ತ್ರ',
'ಯಂತ್ರಶಿಲ್ಪ',
'ಯಂತ್ರಶಿಲ್ಪಿ',
'ಯಂತ್ರಾಗಾರ',
'ಯಂತ್ರಾಪೀಡೆ',
'ಯಃಕಶ್ಚಿತ್',
'ಯಃಕಶ್ಚಿತ',
'ಯಕ್ಷಗಾನ',
'ಯಕ್ಷಗ್ರಹ',
'ಯಕ್ಷತರು',
'ಯಕ್ಷಧೂಪ',
'ಯಕ್ಷಪತಿ',
'ಯಕ್ಷಪ್ರಶ್ನೆ',
'ಯಕ್ಷರಾತ್ರಿ',
'ಯಕ್ಷಲೋಕ',
'ಯಕ್ಷವಾಸ',
'ಯಕ್ಷಸತ್ತ್ವ',
'ಯಕ್ಷಾಂದೋಳ',
'ಯಕ್ಷಾಂಶಕೆ',
'ಯಕ್ಷ್ಮರೋಗ',
'ಯಚ್ಚಾವತ್ತು',
'ಯಜಮಾನ',
'ಯಜಮಾನಿ',
'ಯಜುರಂಗ',
'ಯಜುರ್ವೇದ',
'ಯಜ್ಞಕುಂಡ',
'ಯಜ್ಞದೀಕ್ಷೆ',
'ಯಜ್ಞದ್ರವ್ಯ',
'ಯಜ್ಞಪತ್ನಿ',
'ಯಜ್ಞಪಶು',
'ಯಜ್ಞರಿಪು',
'ಯಜ್ಞವಾಟ',
'ಯಜ್ಞವಿದ್ಯೆ',
'ಯಜ್ಞವೇದಿ',
'ಯಜ್ಞಶಾಲೆ',
'ಯಜ್ಞಸೂತ್ರ',
'ಯಜ್ಞೇಶ್ವರ',
'ಯಜ್ಞೋತ್ತಾರ',
'ಯಣ್‍ಸಂಧಿ',
'ಯತನಗೈ',
'ಯತಾರಾಜಿ',
'ಯತಿಭಂಗ',
'ಯತಿಭ್ರಷ್ಟ',
'ಯತಿರಾಜ',
'ಯತ್ಕಿಂಚಿತ್ತು',
'ಯತ್ನಂಗೆಯ್',
'ಯತ್ನಶೀಲ',
'ಯತ್ನಶೀಲೆ',
'ಯಥಾಕಾಮಿ',
'ಯಥಾಕಾಲ',
'ಯಥಾಕ್ರಮ',
'ಯಥಾಖ್ಯಾತ',
'ಯಥಾಗತಿ',
'ಯಥಾಜಾತ',
'ಯಥಾತಥ',
'ಯಥಾತ್ಮಿಕ',
'ಯಥಾಪ್ರತಿ',
'ಯಥಾಭೂತ',
'ಯಥಾಮತಿ',
'ಯಥಾಯಥ',
'ಯಥಾಯಥಂ',
'ಯಥಾಯೋಗ್ಯ',
'ಯಥಾರೀತಿ',
'ಯಥಾರೂಪ',
'ಯಥಾರ್ಥಕಂ',
'ಯಥಾರ್ಥಜ್ಞ',
'ಯಥಾರ್ಥತ್ವ',
'ಯಥಾವತ್ತು',
'ಯಥಾವಿಧಿ',
'ಯಥಾಶಕ್ತಿ',
'ಯಥಾಸಂಖ್ಯ',
'ಯಥಾಸಂಖ್ಯೆ',
'ಯಥಾಸ್ಥಾನ',
'ಯಥಾಸ್ಥಿತ',
'ಯಥಾಸ್ಥಿತಿ',
'ಯಥೇಪ್ಸಿತ',
'ಯಥೋಚಿತ',
'ಯಥೋದಿತ',
'ಯಥೋದ್ಗತ',
'ಯಥೋದ್ದಿಷ್ಟ',
'ಯದ್ಭವಿಷ್ಯ',
'ಯದ್ವಾತದ್ವಾ',
'ಯಮಗೊಲೆ',
'ಯಮದಂಡ',
'ಯಮದೂತ',
'ಯಮಧರ್ಮ',
'ಯಮಪಾಶ',
'ಯಮಬಲ',
'ಯಮಭಾಧೆ',
'ಯಮಭಾರ',
'ಯಮಮಾಯೆ',
'ಯಮರಾಜ',
'ಯಮಲೋಕ',
'ಯಮಾನುಗ',
'ಯಲವಡಾ',
'ಯವಕಾರ',
'ಯವಕ್ಷಾರ',
'ಯವನಕ',
'ಯವನಾನಿ',
'ಯವನಾಲ',
'ಯವನಾಳ',
'ಯವನಿಕೆ',
'ಯವಫಲ',
'ಯವಯೋಗ',
'ಯವಹಿತ್ರೆ',
'ಯವಾಗ್ರಜ',
'ಯವಾನಿಕೆ',
'ಯವೀಯಾಂಸ',
'ಯವ್ವನಿಗ',
'ಯಶಂಬಡೆ',
'ಯಶಃಪಿಂಡ',
'ಯಶಸ್ಕರ',
'ಯಶಸ್ಕರಿ',
'ಯಶಸ್ಕೀರ್ತಿ',
'ಯಶಸ್ತಂಭ',
'ಯಶಸ್ವಿನಿ',
'ಯಶೋಧನ',
'ಯಶೋಧರ',
'ಯಶೋಧಿಕ',
'ಯಶೋನಿಧಿ',
'ಯಶೋಭಾಗಿ',
'ಯಶೋವಂತ',
'ಯಶೋವತಿ',
'ಯಷ್ಟಿಮಧು',
'ಯಾಂತ್ರಿಕತೆ',
'ಯಾಗಕುಂಡ',
'ಯಾಗಗೆಯ್',
'ಯಾಗದೀಕ್ಷೆ',
'ಯಾಗಶಾಲೆ',
'ಯಾಚಕತ್ವ',
'ಯಾಚನಕ',
'ಯಾಚಿತಕ',
'ಯಾಜಮಾನ್ಯ',
'ಯಾಜ್ಞಸೇನಿ',
'ಯಾತಯಾಮ',
'ಯಾತಾಯಾತ',
'ಯಾತುಧಾನ',
'ಯಾತ್ರಾದಾನ',
'ಯಾತ್ರಾಸ್ಥಳ',
'ಯಾಥಾಸಂಖ್ಯ',
'ಯಾದಃಪತಿ',
'ಯಾದಗಾರಿ',
'ಯಾದೃಚ್ಛಿಕ',
'ಯಾದೃಚ್ಛಿಕಂ',
'ಯಾದೋಜಾತಿ',
'ಯಾದೋನಾಥ',
'ಯಾನನಾಗ',
'ಯಾನಪಾತ್ರ',
'ಯಾನಶಾಲೆ',
'ಯಾನಹಸ್ತಿ',
'ಯಾಪನೀಯ',
'ಯಾಪ್ಯಯಾನ',
'ಯಾಮತೂರ್ಯ',
'ಯಾಮುನಕ',
'ಯಾಮ್ಯದಿಶೆ',
'ಯಾಮ್ಯದ್ರುಮ',
'ಯಾಯಜೂಕ',
'ಯಾಯವಾರ',
'ಯಾಯಾವರ',
'ಯಾಲಪದ',
'ಯಾವಜ್ಜೀವ',
'ಯಾವಜ್ಜೀವಂ',
'ಯಾವನಾಳ',
'ಯಾವಾಗಲು',
'ಯಾವಾಗಲೂ',
'ಯಾಳ್‍ಪಾಣ',
'ಯುಕ್ತರಸೆ',
'ಯುಕ್ತಾಯುಕ್ತ',
'ಯುಕ್ತಿಗೆಡು',
'ಯುಕ್ತಿಬಲ',
'ಯುಕ್ತಿವಂತ',
'ಯುಕ್ತಿವಂತೆ',
'ಯುಕ್ತಿವಾದ',
'ಯುಕ್ತಿಶಾಲಿ',
'ಯುಗಂಧರ',
'ಯುಗದ್ವಯ',
'ಯುಗಧರ್ಮ',
'ಯುಗಪತ್ತು',
'ಯುಗಯೋಗ',
'ಯುಗಸಂಧಿ',
'ಯುಗಳತೆ',
'ಯುಗಾಂತರ',
'ಯುದ್ಧಂಗೆಯ್',
'ಯುದ್ಧಭೂಮಿ',
'ಯುದ್ಧರಂಗ',
'ಯುದ್ಧವೀರ್ಯ',
'ಯುದ್ಧಾಜಿರ',
'ಯುಧಿಷ್ಠಿರ',
'ಯುವಜನ',
'ಯುವರಾಜ',
'ಯುವರಾಣಿ',
'ಯುವಾಧಿಪ',
'ಯೂಥನಾಥ',
'ಯೂಥಪತಿ',
'ಯೂಪಕಂಭ',
'ಯೂಪಸ್ತಂಭ',
'ಯೆಡತಾರಿ',
'ಯೋಗಂಗೊಳ್',
'ಯೋಗಕ್ಷೇಮ',
'ಯೋಗಚೂರ್ಣ',
'ಯೋಗತ್ರಯ',
'ಯೋಗದಂಡ',
'ಯೋಗದೃಷ್ಟಿ',
'ಯೋಗನಾಥ',
'ಯೋಗನಿದ್ರೆ',
'ಯೋಗಪಟ',
'ಯೋಗಪಟ್ಟ',
'ಯೋಗಪೀಠ',
'ಯೋಗಮಾರ್ಗ',
'ಯೋಗರೂಢ',
'ಯೋಗರೂಢಿ',
'ಯೋಗವಂತ',
'ಯೋಗವಾಹ',
'ಯೋಗಶಾಸ್ತ್ರ',
'ಯೋಗಸಿದ್ಧಿ',
'ಯೋಗಾಕ್ಷರ',
'ಯೋಗಾಚಾರ',
'ಯೋಗಾಭ್ಯಾಸ',
'ಯೋಗಾಯೋಗ',
'ಯೋಗಾರೂಢ',
'ಯೋಗಾರೂಢೆ',
'ಯೋಗಾವತಿ',
'ಯೋಗಾಸನ',
'ಯೋಗೀಶ್ವರ',
'ಯೋಗೇಶ್ವರ',
'ಯೋಗ್ಯತಿಕೆ',
'ಯೋಗ್ಯಾರಥ',
'ಯೋಚನೆಗೈ',
'ಯೋನಿದೋಷ',
'ಯೋನಿಧ್ವಜ',
'ಯೋನಿಮುದ್ರೆ',
'ಯೋನಿರಕ್ತ',
'ಯೋನಿಶೂಲೆ',
'ಯೋಷಿಜ್ಜನ',
'ಯೌವನಸ್ಥ',
'ಯೌವರಾಜ್ಯ',
'ರಂಗಂಬೊಗು',
'ರಂಗಕಾರ',
'ರಂಗಕಾಱ',
'ರಂಗಗಾಱ',
'ರಂಗಗೀತೆ',
'ರಂಗತಂತ್ರ',
'ರಂಗಪೀಠ',
'ರಂಗಪೂಜೆ',
'ರಂಗಭೂಮಿ',
'ರಂಗಭೋಗ',
'ರಂಗವಲಿ',
'ರಂಗವಲ್ಲಿ',
'ರಂಗವಾಲಿ',
'ರಂಗವಾಲೆ',
'ರಂಗಶಾಲೆ',
'ರಂಗಶಿಲ್ಪಿ',
'ರಂಗಶೀರ್ಷ',
'ರಂಗಸಾಲೆ',
'ರಂಗಸ್ಥಲ',
'ರಂಗಸ್ಥಳ',
'ರಂಗಳಿಸು',
'ರಂಗಾಜೀವ',
'ರಂಗಾವಲಿ',
'ರಂಗುಬಾಳೆ',
'ರಂಗುಬಾೞೆ',
'ರಂಗುಮಲಿ',
'ರಂಗುಮಾಲೆ',
'ರಂಗುವಡೆ',
'ರಂಗುವಾಲ',
'ರಂಗುವಿಡಿ',
'ರಂಗುವೆಱು',
'ರಂಚೆಗಾಣು',
'ರಂಜಕತೆ',
'ರಂಜಣಿಗೆ',
'ರಂಜನೆಗೈ',
'ರಂಜಳಿಗೆ',
'ರಂಡೆತನ',
'ರಂದಣಿಗ',
'ರಂಧ್ರಪಾತ್ರೆ',
'ರಂಧ್ರಾನ್ವೇಷಿ',
'ರಂಬಾರೂಟಿ',
'ರಂಭಾಲೂಟಿ',
'ರಂಭಾಸ್ತಂಭ',
'ರಕಂವಾರು',
'ರಕ್ಕೆಗಟ್ಟು',
'ರಕ್ಕೆಯಾಳ್',
'ರಕ್ಕೆವಣಿ',
'ರಕ್ಕೆವೊಟ್ಟು',
'ರಕ್ತಕಟ್ಟು',
'ರಕ್ತಕಣ',
'ರಕ್ತಕಾಸ',
'ರಕ್ತಕೃಚ್ಛ್ರ',
'ರಕ್ತಕೋಷ್ಠ',
'ರಕ್ತಕ್ಷಯ',
'ರಕ್ತಗತ',
'ರಕ್ತಗುಂಜಿ',
'ರಕ್ತಚಂಚು',
'ರಕ್ತಚ್ಛದ',
'ರಕ್ತತುಂಡ',
'ರಕ್ತದಾನ',
'ರಕ್ತದಾಹ',
'ರಕ್ತದೇಶಿ',
'ರಕ್ತಧಾತು',
'ರಕ್ತಧಾರೆ',
'ರಕ್ತನಾಳ',
'ರಕ್ತನೀರು',
'ರಕ್ತಪದಿ',
'ರಕ್ತಪಾತ',
'ರಕ್ತಪಾನ',
'ರಕ್ತಪಾಯಿ',
'ರಕ್ತಪಿತ್ತ',
'ರಕ್ತಪುಚ್ಛ',
'ರಕ್ತಪೈತ್ಯ',
'ರಕ್ತಬಸಿ',
'ರಕ್ತಬಾಳ',
'ರಕ್ತಬಾಳೆ',
'ರಕ್ತಬಿಂದು',
'ರಕ್ತಬೀಜ',
'ರಕ್ತಬೋಳ',
'ರಕ್ತಭೇದಿ',
'ರಕ್ತಮರ',
'ರಕ್ತಮುಖ',
'ರಕ್ತಮೂತ್ರ',
'ರಕ್ತಮೇಹ',
'ರಕ್ತಮೋಕ್ಷ',
'ರಕ್ತವಾತ',
'ರಕ್ತಶಾಲಿ',
'ರಕ್ತಶಾಳಿ',
'ರಕ್ತಶೂಲೆ',
'ರಕ್ತಸಿರೆ',
'ರಕ್ತಸೇಕ',
'ರಕ್ತಸ್ರಾವ',
'ರಕ್ತಹೊನ್ನೆ',
'ರಕ್ತಾಂಬರೆ',
'ರಕ್ತಾರ್ಜುನ',
'ರಕ್ತಾಶೋಕ',
'ರಕ್ತೋತ್ಪಲ',
'ರಕ್ತೋತ್ಪಳ',
'ರಕ್ಷಣೀಯ',
'ರಕ್ಷಾಂಕಿತ',
'ರಕ್ಷಾಂಜನ',
'ರಕ್ಷಾಪತ್ರ',
'ರಕ್ಷಾಬಂಧ',
'ರಕ್ಷಾಮಣಿ',
'ರಕ್ಷಾಮರಿ',
'ರಕ್ಷಾಯಂತ್ರ',
'ರಕ್ಷಾವಿಧಿ',
'ರಕ್ಷಿವರ್ಗ',
'ರಕ್ಷೆಗಟ್ಟು',
'ರಕ್ಷೆಬೊಟ್ಟು',
'ರಕ್ಷೆಯಿಕ್ಕು',
'ರಕ್ಷೆಯಿಡು',
'ರಕ್ಷೆವೆರು',
'ರಘುಪ್ರಿಯ',
'ರಚಯಿತ',
'ರಚಯಿಸು',
'ರಚ್ಚೆಕಟ್ಟೆ',
'ರಜಂಬೊರೆ',
'ರಜತಾದ್ರಿ',
'ರಜನೀಶ',
'ರಜಪುತ',
'ರಜಪುಷ್ಪಿ',
'ರಜಪೂತ',
'ರಜಸ್ವರ',
'ರಜಸ್ವಲೆ',
'ರಜಾಕಾರ',
'ರಜಾಮಂದಿ',
'ರಜಾವಂದಿ',
'ರಜೋಗುಣ',
'ರಜೋಗ್ರಹ',
'ರಟ್ಟುಕಟ್ಟು',
'ರಣಕಂಬ',
'ರಣಕಣ',
'ರಣಕಳ',
'ರಣಕಾಳಿ',
'ರಣಖೇಡ',
'ರಣಗಳ್ಳ',
'ರಣಗಾಳೆ',
'ರಣಗೀತೆ',
'ರಣಗುಟ್ಟು',
'ರಣಗುಡು',
'ರಣಗೇಡಿ',
'ರಣಘೋಷ',
'ರಣಚಂಡಿ',
'ರಣತೂರ್ಯ',
'ರಣಪದ್ದು',
'ರಣಪರ್ದು',
'ರಣಬಲಿ',
'ರಣಭೂಮಿ',
'ರಣಭೇರಿ',
'ರಣಮುಖ',
'ರಣರಂಗ',
'ರಣರಂಪ',
'ರಣರಣ',
'ರಣರಸ',
'ರಣವರೆ',
'ರಣವಱೆ',
'ರಣವೈದ್ಯ',
'ರಣಹದ್ದು',
'ರಣಹೇಡಿ',
'ರಣಹೊಡೆ',
'ರಣಾಂಗಣ',
'ರಣಾಜಿರ',
'ರಣಾರಂಪ',
'ರಣಾರಣಿ',
'ರತಕೇಳಿ',
'ರತಕ್ರೀಡೆ',
'ರತಖಿನ್ನ',
'ರತಖಿನ್ನೆ',
'ರತಜಲ',
'ರತಾರ್ಥಿನಿ',
'ರತಿಕಲೆ',
'ರತಿಕಳೆ',
'ರತಿಕಾತಿ',
'ರತಿಕೇಳಿ',
'ರತಿಕ್ರೀಡೆ',
'ರತಿಗಲೆ',
'ರತಿಗಾರ',
'ರತಿಗೃಹ',
'ರತಿಗೆಡು',
'ರತಿಗೆಯ್',
'ರತಿಗೇಹ',
'ರತಿಗೊಡು',
'ರತಿತಂತ್ರ',
'ರತಿರಸ',
'ರತಿರಾಗ',
'ರತಿರಾಗಿ',
'ರತಿವೆರು',
'ರತಿಶಾಸ್ತ್ರ',
'ರತಿಶ್ರಾಂತಿ',
'ರತಿಸುಖ',
'ರತಿಸೌಖ್ಯ',
'ರತಿಸ್ರುತೆ',
'ರತೋತ್ಕಂಠೆ',
'ರತೋತ್ಸವ',
'ರತ್ನಕರ್ಮ',
'ರತ್ನಗಂಧಿ',
'ರತ್ನಗರ್ಭ',
'ರತ್ನಗರ್ಭೆ',
'ರತ್ನತ್ರಯ',
'ರತ್ನಪಟ್ಟ',
'ರತ್ನಪಡಿ',
'ರತ್ನಪ್ರಭೆ',
'ರತ್ನಸಾನು',
'ರತ್ನಾಕರ',
'ರತ್ನಾವಳಿ',
'ರಥಂತರ',
'ರಥಕಲ್ಪ',
'ರಥಕಾರ',
'ರಥಗುಪ್ತಿ',
'ರಥಗೋಪ',
'ರಥಚಕ್ರ',
'ರಥದ್ರುಮ',
'ರಥರೇಣು',
'ರಥವಕ್ಕಿ',
'ರಥಶಾಲೆ',
'ರಥೋತ್ಸವ',
'ರಥೋದ್ಧತ',
'ರದಗಜ',
'ರದಚ್ಛದ',
'ರದ್ದಿಯಾತಿ',
'ರನ್ನವೆಸ',
'ರಫ್ತುಸುಂಕ',
'ರಬ್ಬಳಿಕೆ',
'ರಬ್ಬಳಿಗಿ',
'ರಬ್ಬಳಿಗೆ',
'ರಬ್ಬುದೋರು',
'ರಮಜಾನ್',
'ರಮಜಾನು',
'ರಮಡೋಲು',
'ರಮಣೀಯ',
'ರಮಲಜ್ಞ',
'ರಮಾಧವ',
'ರಮಾರಮಿ',
'ರಮಿಯಿಸು',
'ರಮ್ಮಜಾನು',
'ರಮ್ಯಂಬಡೆ',
'ರಮ್ಯವಡೆ',
'ರಯಪತಿ',
'ರಯ್ಯಂಬಡೆ',
'ರಯ್ಯವಡೆ',
'ರವಂಗುಡು',
'ರವಕುಳ',
'ರವದೋಱು',
'ರವನಿಸು',
'ರವರವ',
'ರವಳಿಗೆ',
'ರವಾನಿಸು',
'ರವಿಕಾಂತ',
'ರವಿಕ್ರಿಯೆ',
'ರವಿದುಗ್ಧ',
'ರವಿಪತ್ರ',
'ರವಿಪ್ರಭೆ',
'ರವಿಬಿಂಬ',
'ರವಿಭಕ್ತ',
'ರವಿವಾರ',
'ರವೆಗಾಲು',
'ರವೆಯಿಡ್ಲಿ',
'ರವೆವುಂಡೆ',
'ರಶ್ಮಿಶಿಕ್ಷ',
'ರಸಋಷಿ',
'ರಸಂಗೊಳ್',
'ರಸಂದೆಗೆ',
'ರಸಂಬಡೆ',
'ರಸಂಬೋಗು',
'ರಸಕಸಿ',
'ರಸಕಸೆ',
'ರಸಕಾವ್ಯ',
'ರಸಖಸಿ',
'ರಸಗಬ್ಬ',
'ರಸಗರ್ಭ',
'ರಸಗಸಿ',
'ರಸಗೀತ',
'ರಸಗುಂಡು',
'ರಸಗುಲ್ಲ',
'ರಸಗುಲ್ಲಾ',
'ರಸಗೆಡು',
'ರಸಗೆಯ್',
'ರಸಗೇಯ',
'ರಸಗೋಲ್',
'ರಸಚಿತ್ರ',
'ರಸಜ್ಞತೆ',
'ರಸಜ್ಞಾನಿ',
'ರಸತಂತ್ರ',
'ರಸತಾಳೆ',
'ರಸದಾನ',
'ರಸದಾಲೆ',
'ರಸದಾಳ',
'ರಸದಾಳಿ',
'ರಸದಾಳೆ',
'ರಸದುಂಬು',
'ರಸದೋಷ',
'ರಸದ್ರವ್ಯ',
'ರಸಧರ್ಮ',
'ರಸಧಾತು',
'ರಸಧಾನ್ಯ',
'ರಸಧ್ವನಿ',
'ರಸನತ್ವ',
'ರಸನಾಗ್ರ',
'ರಸಪಾಕ',
'ರಸಪುರಿ',
'ರಸಫಲ',
'ರಸಬಾಳೆ',
'ರಸಬೀಜ',
'ರಸಭಂಗ',
'ರಸಭಸ್ಮ',
'ರಸಭಾವ',
'ರಸಮಗ್ನ',
'ರಸಮಗ್ನೆ',
'ರಸಮಟ್ಟ',
'ರಸಮಾರ್ಗ',
'ರಸಮೆಟ್ಟು',
'ರಸಯೋನಿ',
'ರಸವಂತಿ',
'ರಸವಚ್ಚ',
'ರಸವಣಿ',
'ರಸವತಿ',
'ರಸವತ್ತು',
'ರಸವರ್ಗ',
'ರಸವಶ',
'ರಸವಶೆ',
'ರಸವಸ್ತು',
'ರಸವಾದ',
'ರಸವಾದಿ',
'ರಸವಾಳೆ',
'ರಸವಾೞೆ',
'ರಸವೃತ್ತಿ',
'ರಸಶಾಸ್ತ್ರ',
'ರಸಶೇಷ',
'ರಸಸಿದ್ಧ',
'ರಸಸಿದ್ಧಿ',
'ರಸಸ್ಯಂದಿ',
'ರಸಹಾರಿ',
'ರಸಹೀನ',
'ರಸಾಂಜನ',
'ರಸಾತಲ',
'ರಸಾತಳ',
'ರಸಾತ್ಮಕ',
'ರಸಾತ್ಮಿಕೆ',
'ರಸಾನಂದ',
'ರಸಾಭಾಸ',
'ರಸಾಯನ',
'ರಸಾರುಹ',
'ರಸಾಶ್ರಯ',
'ರಸಾಸ್ವಾದ',
'ರಸಿಕತೆ',
'ರಸಿಕತ್ವ',
'ರಸುಮಿಸು',
'ರಸೇಶ್ವರ',
'ರಸೋದ್ರೇಕ',
'ರಸೋನಕ',
'ರಸ್ತುಬಿಡು',
'ರಹದಾರಿ',
'ರಹವಾಸಿ',
'ರಹಸಿಯ',
'ರಹಿಗೆಡು',
'ರಹಿತಾಘ',
'ರಹಿವಾಸಿ',
'ರಾಂಡಬಾಜ',
'ರಾಕಾಚಂದ್ರ',
'ರಾಕ್ಷಸೀಯ',
'ರಾಗಂಗೆಡು',
'ರಾಗಂಬೆಱು',
'ರಾಗಂಬೊರೆ',
'ರಾಗಗೆಡು',
'ರಾಗಧರ',
'ರಾಗಭಾವ',
'ರಾಗರಸ',
'ರಾಗವಿಡಿ',
'ರಾಗಾಂಧತೆ',
'ರಾಗಾವಳಿ',
'ರಾಗಾವಿಲ',
'ರಾಗಾವಿಳ',
'ರಾಗಿಕಲ್ಲು',
'ರಾಚನಿಕ',
'ರಾಜಋಷಿ',
'ರಾಜಕನ್ಯೆ',
'ರಾಜಕರ',
'ರಾಜಕರ್ಮ',
'ರಾಜಕಲೆ',
'ರಾಜಕಳೆ',
'ರಾಜಕಾರ್ಯ',
'ರಾಜಕೀಯ',
'ರಾಜಕೀರ',
'ರಾಜಕೀರೆ',
'ರಾಜಕುಲ',
'ರಾಜಕ್ಷಯ',
'ರಾಜಗಾಮಿ',
'ರಾಜಗಿರಿ',
'ರಾಜಗಿಳಿ',
'ರಾಜಗೀರೆ',
'ರಾಜಗೃಹ',
'ರಾಜಗೇಹ',
'ರಾಜಗೋಳಿ',
'ರಾಜಚಿಹ್ನ',
'ರಾಜಚಿಹ್ನೆ',
'ರಾಜಠೀವಿ',
'ರಾಜತಂತ್ರ',
'ರಾಜತರು',
'ರಾಜದಂಡ',
'ರಾಜದೂತ',
'ರಾಜದ್ರವ್ಯ',
'ರಾಜದ್ರೋಹ',
'ರಾಜದ್ರೋಹಿ',
'ರಾಜದ್ವಾರ',
'ರಾಜಧನ',
'ರಾಜಧರ',
'ರಾಜಧರ್ಮ',
'ರಾಜಧಾನಿ',
'ರಾಜನೀತಿ',
'ರಾಜನೆಲ್ಲು',
'ರಾಜನ್ಯಕ',
'ರಾಜನ್ವತಿ',
'ರಾಜಪಟ್ಟ',
'ರಾಜಪಿಂಡ',
'ರಾಜಪಿಂಡೆ',
'ರಾಜಪುತ್ರ',
'ರಾಜಪುತ್ರಿ',
'ರಾಜಪೂಜ್ಯ',
'ರಾಜಪೂಜ್ಯೆ',
'ರಾಜಪೆಂಡಿ',
'ರಾಜಫಣಿ',
'ರಾಜಬಲೆ',
'ರಾಜಬಾಳೆ',
'ರಾಜಬೀಜಿ',
'ರಾಜಬೀದಿ',
'ರಾಜಬುರಿ',
'ರಾಜಬೋಯಿ',
'ರಾಜಬೋವ',
'ರಾಜಬ್ರುವ',
'ರಾಜಭಕ್ತಿ',
'ರಾಜಭತ್ತ',
'ರಾಜಭಾಷೆ',
'ರಾಜಭೋಗ',
'ರಾಜಮಂತ್ರ',
'ರಾಜಮಖ',
'ರಾಜಮಾತೆ',
'ರಾಜಮಾನ್ಯ',
'ರಾಜಮಾರ್ಗ',
'ರಾಜಮಾವು',
'ರಾಜಮಾಷ',
'ರಾಜಮುಡಿ',
'ರಾಜಮುದ್ರೆ',
'ರಾಜಯಕ್ಷ್ಮ',
'ರಾಜಯೋಗ',
'ರಾಜಯೋಗಿ',
'ರಾಜಯೋಗ್ಯ',
'ರಾಜರಸ್ತೆ',
'ರಾಜರಾಜ',
'ರಾಜರೋಗ',
'ರಾಜಲಕ್ಷ್ಮಿ',
'ರಾಜವಂಶ',
'ರಾಜವಂಶ್ಯ',
'ರಾಜವಟ',
'ರಾಜವಟ್ಟ',
'ರಾಜವತಿ',
'ರಾಜವಶ್ಯ',
'ರಾಜವಸು',
'ರಾಜವಾಡೆ',
'ರಾಜವಾಹ್ಯ',
'ರಾಜವಾಳೆ',
'ರಾಜವಿದ್ಯೆ',
'ರಾಜವೀಥಿ',
'ರಾಜವೀಧಿ',
'ರಾಜವೃಕ್ಷ',
'ರಾಜವೃತ್ತ',
'ರಾಜವೈದ್ಯ',
'ರಾಜಶುಕ',
'ರಾಜಶೂನ್ಯ',
'ರಾಜಶೂಲೆ',
'ರಾಜಶ್ರೇಷ್ಠಿ',
'ರಾಜಸಭೆ',
'ರಾಜಸರ್ಪ',
'ರಾಜಸಿಕ',
'ರಾಜಸಿಕೆ',
'ರಾಜಸುತ',
'ರಾಜಸುತೆ',
'ರಾಜಸೂಯ',
'ರಾಜಸೇವೆ',
'ರಾಜಸ್ವರ',
'ರಾಜಹಂಸ',
'ರಾಜಹಂಸಿ',
'ರಾಜಹಂಸೆ',
'ರಾಜಹಮ್ರ್ಯ',
'ರಾಜಹುಂಡಿ',
'ರಾಜಹುಣ್ಣು',
'ರಾಜಾಂಗಣ',
'ರಾಜಾಂಗನೆ',
'ರಾಜಾತಿಥ್ಯ',
'ರಾಜಾದನ',
'ರಾಜಾದಾಯ',
'ರಾಜಾದೇಶ',
'ರಾಜಾಧ್ಯಕ್ಷ',
'ರಾಜಾಧ್ವರ',
'ರಾಜಾನ್ವಯ',
'ರಾಜಾಪುರಿ',
'ರಾಜಾಯತ್ತ',
'ರಾಜಾರೋಷ',
'ರಾಜಾಲಯ',
'ರಾಜಾವರ್ತ',
'ರಾಜಾವರ್ತಿ',
'ರಾಜಾವಳಿ',
'ರಾಜಾಶ್ರಯ',
'ರಾಜಾಸ್ಥಾನ',
'ರಾಜಿನಾಮೆ',
'ರಾಜೀನಾಮೆ',
'ರಾಜೋಚಿತ',
'ರಾಜ್ಯಂಗೆಯ್',
'ರಾಜ್ಯಕಾರ್ಯ',
'ರಾಜ್ಯಕಾಲ',
'ರಾಜ್ಯನೀತಿ',
'ರಾಜ್ಯಪಟ್ಟ',
'ರಾಜ್ಯಪತ್ರ',
'ರಾಜ್ಯಪದ',
'ರಾಜ್ಯಪಾಲ',
'ರಾಜ್ಯಭರ',
'ರಾಜ್ಯಭಾರ',
'ರಾಜ್ಯಭಾಷೆ',
'ರಾಜ್ಯಭ್ರಂಶ',
'ರಾಜ್ಯಭ್ರಷ್ಟ',
'ರಾಜ್ಯಭ್ರಷ್ಟೆ',
'ರಾಜ್ಯಶಾಸ್ತ್ರ',
'ರಾಜ್ಯಶ್ರೀಕ',
'ರಾಜ್ಯಸಭೆ',
'ರಾಜ್ಯಸಿರಿ',
'ರಾಜ್ಯಸೂತ್ರ',
'ರಾಜ್ಯಾದಾಯ',
'ರಾಜ್ಯಾಧೀನ',
'ರಾಜ್ಯಾಧ್ವರ',
'ರಾಟವಾಣ',
'ರಾಟವಾಳ',
'ರಾಟವಾಳೆ',
'ರಾಟಳಕ',
'ರಾಟೆವಾಳೆ',
'ರಾಣದೆಱೆ',
'ರಾಣಾರಂಪು',
'ರಾಣಿಜೇನು',
'ರಾಣಿವಾಸ',
'ರಾಣಿಸಾಲು',
'ರಾಣೀವಾಸ',
'ರಾತಾರಾತ್ರಿ',
'ರಾತೋರಾತ್',
'ರಾತೋರಾತು',
'ರಾತೋರಾತ್ರಿ',
'ರಾತ್ರಾರಾತ್ರಿ',
'ರಾತ್ರಿಂಚರ',
'ರಾತ್ರಿಚರ',
'ರಾತ್ರಿಜಲ',
'ರಾತ್ರಿಜ್ವರ',
'ರಾತ್ರಿನಾಡಿ',
'ರಾತ್ರಿಮಣಿ',
'ರಾತ್ರಿಯುದ್ಧ',
'ರಾತ್ರಿರಾಣಿ',
'ರಾತ್ರೀಚರ',
'ರಾತ್ರೆರಾಣಿ',
'ರಾತ್ರೋರಾತ್ರಿ',
'ರಾತ್ರ್ಯಂಧತೆ',
'ರಾಪುಗೊಳ್',
'ರಾಪುದೋರು',
'ರಾಭಸಿಕ',
'ರಾಮಕಲಿ',
'ರಾಮಕ್ರಿಯೆ',
'ರಾಮಗಬ್ಬು',
'ರಾಮಗುಳ್ಳ',
'ರಾಮಗೋಟು',
'ರಾಮಚಂಗಿ',
'ರಾಮಟಂಕಿ',
'ರಾಮಟೆಂಕಿ',
'ರಾಮತನ',
'ರಾಮಧಾನ್ಯ',
'ರಾಮನಾಥ',
'ರಾಮಪಟ್ಟಿ',
'ರಾಮಪತ್ರೆ',
'ರಾಮಪ್ರಿಯ',
'ರಾಮಫಲ',
'ರಾಮಬಾಣ',
'ರಾಮರಕ್ಷೆ',
'ರಾಮರಸ',
'ರಾಮರಾಜ್ಯ',
'ರಾಮರಾಯಿ',
'ರಾಮಾನುಜ',
'ರಾಮಾಯಣ',
'ರಾಮಾರಕ್ತ',
'ರಾಮೇಶ್ವರ',
'ರಾಯಕಲ್ಲು',
'ರಾಯಗಜ್ಜ',
'ರಾಯಗಿಳಿ',
'ರಾಯಗೆಂಪು',
'ರಾಯಗೋಲು',
'ರಾಯತನ',
'ರಾಯನಿಂಬೆ',
'ರಾಯನೆಲ್ಲಿ',
'ರಾಯಬೀದಿ',
'ರಾಯಬೋರೆ',
'ರಾಯಭಾರ',
'ರಾಯಭಾರಿ',
'ರಾಯಭೇರಿ',
'ರಾಯಮನೆ',
'ರಾಯಮಾನ',
'ರಾಯರಿಸಿ',
'ರಾಯರೇಖೆ',
'ರಾಯವಾಡ',
'ರಾಯಸನ್ನಿ',
'ರಾಯಸಿಗ',
'ರಾಯಸೂಯ',
'ರಾಯ್ಯಂಗೆಯ್',
'ರಾಯ್ಯಗೆಯ್',
'ರಾರಾಜಿಸು',
'ರಾವಟಿಗೆ',
'ರಾವಸಾಬ್',
'ರಾವಳಿಸು',
'ರಾವುಗಣ್ಣು',
'ರಾವುಗಾಣು',
'ರಾವುಗೆಂಪು',
'ರಾವುಗೋಲು',
'ರಾವುತಟ್ಟು',
'ರಾವುತಿಕೆ',
'ರಾವುತೆಗೆ',
'ರಾವುಬಡಿ',
'ರಾವುಹಿಡಿ',
'ರಾವುಹೊಡೆ',
'ರಾಶಿಚಕ್ರ',
'ರಾಷ್ಟ್ರಕೂಟ',
'ರಾಷ್ಟ್ರಗೀತೆ',
'ರಾಷ್ಟ್ರಧ್ವಜ',
'ರಾಷ್ಟ್ರಪಕ್ಷಿ',
'ರಾಷ್ಟ್ರಪತಿ',
'ರಾಷ್ಟ್ರಪ್ರಜ್ಞೆ',
'ರಾಷ್ಟ್ರಾಧ್ಯಕ್ಷ',
'ರಾಷ್ಟ್ರೀಯತೆ',
'ರಾಸಕೇಳಿ',
'ರಾಸಕ್ರೀಡೆ',
'ರಾಸಗೋಷ್ಠಿ',
'ರಾಸಮನಿ',
'ರಾಸಲೀಲೆ',
'ರಾಸಿಗೆಯ್',
'ರಾಸಿಗೊಳ್',
'ರಾಸಿಹಬ್ಬ',
'ರಾಹುಕಾಲ',
'ರಾಹುಗ್ರಸ್ತ',
'ರಾಹುಬೀಜ',
'ರಾಳಧೂಪ',
'ರಿಕ್ತಪೂರ್ಣ',
'ರಿಕ್ತಹಸ್ತ',
'ರಿಗ್ಗವಣೆ',
'ರಿಗ್ಘವಣೆ',
'ರಿಚೆವೇೞ್',
'ರಿಜಿಸ್ಟರು',
'ರಿಣಗೂಳು',
'ರಿಣಗೇಡಿ',
'ರಿಪಿಗೇಡಿ',
'ರಿಪಿಜಿಡ್ಡು',
'ರಿಯಾಯತಿ',
'ರಿಯಾಯಿತಿ',
'ರಿಯಾಸತ',
'ರಿಯಾಸತ್ತು',
'ರಿಸಾಲ್ದಾರ್',
'ರಿಸಾಲ್ದಾರ',
'ರಿಸಿವೆಣ್',
'ರೀತಿಕೆಡು',
'ರೀತಿಗೆಡು',
'ರೀತಿಗೌಳ',
'ರೀತಿತಪ್ಪು',
'ರೀತಿಪುಷ್ಪ',
'ರುಂಡಮಾಲೆ',
'ರುಂಡಮಾಳೆ',
'ರುಕ್‍ಸತು',
'ರುಕ್ಮದಾನ',
'ರುಕ್ಮಾಂಗದ',
'ರುಗ್ಣಗೃಹ',
'ರುಗ್ಣಶಯ್ಯೆ',
'ರುಗ್ಣಾಲಯ',
'ರುಗ್ಣಾವಸ್ಥೆ',
'ರುಚಿಕಟ್ಟು',
'ರುಚಿಕತ್ರಿ',
'ರುಚಿಕರ',
'ರುಚಿಕಾರ',
'ರುಚಿಕೆಡು',
'ರುಚಿಕೊಡು',
'ರುಚಿಗೆಡು',
'ರುಚಿಗೆಯ್',
'ರುಚಿಗೊಡು',
'ರುಚಿತೋರು',
'ರುಚಿದೋಱು',
'ರುಚಿನೋಡು',
'ರುಚಿರತೆ',
'ರುಚಿರಾಮ',
'ರುಚಿವಡೆ',
'ರುಚಿವೀಱು',
'ರುಚಿವೆಱು',
'ರುಜುವತ್ತು',
'ರುಜುವಾತು',
'ರುಜುವೆರು',
'ರುಣಿತನ',
'ರುತುಕಾಲ',
'ರುದಮಿತ್ರ',
'ರುದ್ಧನೇತ್ರ',
'ರುದ್ರಕಾಂತ',
'ರುದ್ರಕಾವ್ಯ',
'ರುದ್ರಗಂಟಿ',
'ರುದ್ರಗಂಟು',
'ರುದ್ರಗಣ',
'ರುದ್ರಜಟೆ',
'ರುದ್ರಜಡೆ',
'ರುದ್ರಜೆಡೆ',
'ರುದ್ರನಾಟ್ಯ',
'ರುದ್ರನಾಡಿ',
'ರುದ್ರಪುಷ್ಪ',
'ರುದ್ರಭೂಮಿ',
'ರುದ್ರವಾಸ',
'ರುದ್ರವೀಣೆ',
'ರುದ್ರವೀರ್ಯ',
'ರುದ್ರಸೂಕ್ತ',
'ರುದ್ರಹೋಮ',
'ರುಬ್ಬುಕಲ್ಲು',
'ರುಬ್ಬುಗಲ್ಲು',
'ರುಬ್ಬುಗುಂಡು',
'ರುಮುಜುಮು',
'ರುಮುರುಮು',
'ರುರುವಂತಿ',
'ರುರುವಕ್ತ್ರ',
'ರುರುತ್ಕೀರ',
'ರುಷವತ್ತು',
'ರುಷುವತ್ತು',
'ರೂಕ್ಷತನ',
'ರೂಕ್ಷಾನಿಲ',
'ರೂಕ್ಷಾವರ್ತ',
'ರೂಚಿಪತ್ರ',
'ರೂಢನಾಮ',
'ರೂಢಬುದ್ಧಿ',
'ರೂಢಮೂಲ',
'ರೂಢವೈರ',
'ರೂಢಿಪತಿ',
'ರೂಢಿಮಾತು',
'ರೂಢಿವಡೆ',
'ರೂಢಿವಿಡಿ',
'ರೂಢಿವೆಱು',
'ರೂಪಂದಳೆ',
'ರೂಪಂಬೆಱು',
'ರೂಪಗೊಳ್ಳು',
'ರೂಪಚಿತ್ರ',
'ರೂಪಜೀವೆ',
'ರೂಪದ್ವಿತ್ವ',
'ರೂಪಧಾರಿ',
'ರೂಪಮದ',
'ರೂಪರೇಖೆ',
'ರೂಪರೇಷೆ',
'ರೂಪವಂತ',
'ರೂಪವಂತೆ',
'ರೂಪವತಿ',
'ರೂಪಸ್ಕಂಧ',
'ರೂಪಾಂತರ',
'ರೂಪಾಜೀವೆ',
'ರೂಪಾಧಿಕ',
'ರೂಪುಗರೆ',
'ರೂಪುಗಾಣ್',
'ರೂಪುಗಿಡು',
'ರೂಪುಗೂಡು',
'ರೂಪುಗೆಡು',
'ರೂಪುಗೇಡು',
'ರೂಪುಗೊಳ್',
'ರೂಪುಗೊಳ್ಳು',
'ರೂಪುಜೀವೆ',
'ರೂಪುದಳೆ',
'ರೂಪುದೋರು',
'ರೂಪುದೋಱು',
'ರೂಪುರೇಖೆ',
'ರೂಪುರೇಷೆ',
'ರೂಪುವಡೆ',
'ರೂಪುವಿಡಿ',
'ರೂಪುವೆರು',
'ರೂಪುವೆಱು',
'ರೂಪೋತ್ಕರ',
'ರೂಬ್‍ರೂಬ್',
'ರೂಬುರೂಬು',
'ರೂಲುದೊಣ್ಣೆ',
'ರೂವುಗಿಡು',
'ರೂಹುಗರೆ',
'ರೂಹುಗಳೆ',
'ರೂಹುಗೆಡು',
'ರೂಹುಗೇಡು',
'ರೂಹುಗೊಳ್',
'ರೂಹುದಳೆ',
'ರೂಹುದೋಱು',
'ರೂಹುವಡೆ',
'ರೂಹುವಿಡಿ',
'ರೆಕ್ಕೆಹುಳು',
'ರೆಜಿಸ್ಟರು',
'ರೆಪ್ಪೆಬಡಿ',
'ರೆಪ್ಪೆಮುಚ್ಚು',
'ರೆಪ್ಪೆಹೊಡೆ',
'ರೇಖಾಕೃತಿ',
'ರೇಖಾಚಿತ್ರ',
'ರೇಖಾಮಾತ್ರ',
'ರೇಖಾರೂಪ',
'ರೇಖಾಶಾಸ್ತ್ರ',
'ರೇಖೆಗೊಳ್',
'ರೇಖೆದೆಗೆ',
'ರೇಖೆದೋಱು',
'ರೇಜುಗಟ್ಟು',
'ರೇತಿದಾನಿ',
'ರೇಪಡಿಸು',
'ರೇರಿಹಾಣ',
'ರೇವಲ್ಚಿನ್ನಿ',
'ರೇವಾಚಿನ್ನ',
'ರೇವಾಚಿನ್ನಿ',
'ರೇವಾಚೀನಿ',
'ರೇಷಮಾನಿ',
'ರೇಷ್ಮೆಗೆಡ್ಡೆ',
'ರೇಷ್ಮೆಜ್ವರ',
'ರೇಷ್ಮೆಹುಳು',
'ರೈಲುಚೆಂಬು',
'ರೈಲುಚೊಂಬು',
'ರೈಲುಮಾರ್ಗ',
'ರೊಕ್ಕಮುಕ್ಕ',
'ರೊಚ್ಚುಗುಂಡಿ',
'ರೊಚ್ಚುಗೊಳ್ಳು',
'ರೊಚ್ಚೆಗುಂಡಿ',
'ರೊಚ್ಚೆಬ್ಬಿಸು',
'ರೊಟ್ಟಿಗಿಡ',
'ರೊಟ್ಟಿಹಣ್ಣು',
'ರೊಡ್ಡಕೆಯ್',
'ರೊಡ್ಡಗೈಯ',
'ರೋಗಗ್ರಾಹಿ',
'ರೋಗಜೀವ',
'ರೋಗಜ್ವರ',
'ರೋಗರಾಜ',
'ರೋಗಹಾರಿ',
'ರೋಚಮಾನ',
'ರೋಜನಾಮಾ',
'ರೋಜಮಾರಿ',
'ರೋಜಮೇಳ',
'ರೋಜಾಂಬರಿ',
'ರೋಜಾಕಡ್ಡಿ',
'ರೋಜುಗಾರ',
'ರೋಜುಗಾರಿ',
'ರೋಜುನಾಮ',
'ರೋಜುನಾಮೆ',
'ರೋಟಿಗಳೆ',
'ರೋಡಾಡಿಸು',
'ರೋಣುಗಲ್ಲು',
'ರೋಣುಹೊಡೆ',
'ರೋದನಿಸು',
'ರೋದಸ್ತಳ',
'ರೋದೋಂಗಣ',
'ರೋದೋವಾತ',
'ರೋಧಸ್ವಿನಿ',
'ರೋಧೋವಕ್ತ್ರೆ',
'ರೋಮಂಥಿಸು',
'ರೋಮಕಂದ',
'ರೋಮಕೂಪ',
'ರೋಮರಂಧ್ರ',
'ರೋಮಲತೆ',
'ರೋಮಹರ್ಷ',
'ರೋಮಾಂಚತೆ',
'ರೋಮಾಂಚನ',
'ರೋಮಾಂಚಿತ',
'ರೋಮೋದ್ಗಮ',
'ರೋಷಂದಟ್ಟು',
'ರೋಷಂಬಡೆ',
'ರೋಷಚ್ಯುತ',
'ರೋಷಚ್ಯುತೆ',
'ರೋಹಿತಕ',
'ರೋಹಿತಾಶ್ವ',
'ರೌಚನಿಕ',
'ರೌಡಿತನ',
'ರೌದ್ರಕಾಲ',
'ರೌದ್ರಧ್ಯಾನ',
'ರೌದ್ರರತಿ',
'ರೌದ್ರರಸ',
'ರೌದ್ರಾವಹ',
'ರೌದ್ರಾಹವ',
'ರೌಹಿಣೇಯ',
'ರ‍್ಯಾಕಟಿ',
'ರ‍್ಯಾಕಟೆ',
'ರ‍್ಯಾಕಡೆ',
'ರ‍್ಯಾಗಟೆ',
'ಲಂಕೋಪಿಗೆ',
'ಲಂಕೋಯಿಕೆ',
'ಲಂಘನೀಯ',
'ಲಂಚಂಗುಳಿ',
'ಲಂಚಂಗೊಡು',
'ಲಂಚಂಗೊಳ್',
'ಲಂಚಕೊಡು',
'ಲಂಚಕೋರ',
'ಲಂಚಕೋರಿ',
'ಲಂಚಖೋರ',
'ಲಂಚಗಾರ',
'ಲಂಚಗಾಱ',
'ಲಂಚಗಿತಿ',
'ಲಂಚಗುಂಡಿ',
'ಲಂಚಗುಳಿ',
'ಲಂಚವೊಗು',
'ಲಂಟವಾಣಿ',
'ಲಂಡತನ',
'ಲಂಡಪಾತಿ',
'ಲಂಡಪೆಣ್',
'ಲಂಡವೆಣ್',
'ಲಂಡಹೆಣ್ಣು',
'ಲಂದಣಿಗ',
'ಲಂಪಂಬೆಱು',
'ಲಂಪಟತೆ',
'ಲಂಪಟತ್ವ',
'ಲಂಪಳಿಕೆ',
'ಲಂಪಾಟಿಯ',
'ಲಂಬಣಿಗೆ',
'ಲಂಬಹಸ್ತ',
'ಲಂಬಳಿಗೆ',
'ಲಂಬಾಡಿಗ',
'ಲಂಬಾಣಿಗ',
'ಲಂಬಾಳಕ',
'ಲಂಬೋದರ',
'ಲಂಬೋದರಿ',
'ಲಕಮಕ',
'ಲಕಲಕ',
'ಲಕುಮುಕಿ',
'ಲಕುಲೀಶ',
'ಲಕುಳೀಶ',
'ಲಕ್ಕಚಣ',
'ಲಕ್ಕಣಿಕೆ',
'ಲಕ್ಕಿಮುಷ್ಟಿ',
'ಲಕ್ಷಕೊಡು',
'ಲಕ್ಷಗೊಡು',
'ಲಕ್ಷಾಂತರ',
'ಲಕ್ಷ್ಮಿಚೇಳು',
'ಲಕ್ಷ್ಮೀಕಾಂತ',
'ಲಕ್ಷ್ಮೀಧವ',
'ಲಕ್ಷ್ಮೀನೀಡ',
'ಲಕ್ಷ್ಮೀಪತಿ',
'ಲಕ್ಷ್ಮೀಪುತ್ರ',
'ಲಕ್ಷ್ಮೀಲೋಲ',
'ಲಕ್ಷ್ಯಕೊಡು',
'ಲಕ್ಷ್ಯಗೊಡು',
'ಲಕ್ಷ್ಯಚ್ಯುತ',
'ಲಕ್ಷ್ಯಚ್ಯುತೆ',
'ಲಕ್ಷ್ಯಭಾವ',
'ಲಕ್ಷ್ಯವಿಡು',
'ಲಕ್ಷ್ಯಸಿದ್ಧಿ',
'ಲಗಕ್ರಿಯೆ',
'ಲಗಡಿಸು',
'ಲಗತಿಡು',
'ಲಗತ್ತಿಸು',
'ಲಗಾಯಿಸು',
'ಲಗುಗೆಯ್',
'ಲಗುಡಿಗ',
'ಲಗುಬಗೆ',
'ಲಗ್ಗೆಗೆಯ್',
'ಲಗ್ಗೆಚಂಡು',
'ಲಗ್ಗೆಚೆಂಡು',
'ಲಗ್ಗೆಮಣೆ',
'ಲಗ್ಗವಲ್ಲೆ',
'ಲಗ್ಗೆವಱೆ',
'ಲಗ್ಗೆವಿಡಿ',
'ಲಗ್ಗೆಸೆಂಡು',
'ಲಗ್ನಂಬಡೆ',
'ಲಗ್ನಕಾಲ',
'ಲಘುಕರಿ',
'ಲಘುಕಾವ್ಯ',
'ಲಘುಗೀತೆ',
'ಲಘುದೋರು',
'ಲಘುದೋಷ',
'ಲಘುನೃತ್ತ',
'ಲಘುಪಾಳಿ',
'ಲಘುಪುಟ',
'ಲಘುಲಯ',
'ಲಘುಶಂಕೆ',
'ಲಘುಶೀತ',
'ಲಘುಶೀಲ',
'ಲಘುಹಸ್ತ',
'ಲಘೂಪದೆ',
'ಲಚ್ಚಣಿಸು',
'ಲಜ್ಜರಿಕೆ',
'ಲಜ್ಜಾವತಿ',
'ಲಜ್ಜಾಶೀಲ',
'ಲಜ್ಜಾಶೀಲೆ',
'ಲಜ್ಜಾಹೀನ',
'ಲಜ್ಜಾಹೀನೆ',
'ಲಜ್ಜೆಕಾತಿ',
'ಲಜ್ಜೆಗೆಡು',
'ಲಜ್ಜೆಗೇಡಿ',
'ಲಜ್ಜೆಗೇಡು',
'ಲಜ್ಜೆಗೊಳ್ಳು',
'ಲಜ್ಜೆಪಡು',
'ಲಜ್ಜೆಯೇಱು',
'ಲಟಕಟ',
'ಲಟಕ್ಕನೆ',
'ಲಟಪಟ',
'ಲಟಪಟಿ',
'ಲಟಪಟೆ',
'ಲಟಲಟ',
'ಲಟಾಪಟಿ',
'ಲಟುಕಿಕೆ',
'ಲಟ್ಟಣಿಗೆ',
'ಲಟ್ಟೆಕಾರ',
'ಲತಾಕರ',
'ಲತಾಕುಂಜ',
'ಲತಾಕ್ಷೇಪ',
'ಲತಾಗೃಹ',
'ಲತಾಬಂಧ',
'ಲತಾಲೀಢ',
'ಲತಾಹಸ್ತ',
'ಲತೆಯಿಱಿ',
'ಲತೆವನೆ',
'ಲತ್ತೆಗುಣಿ',
'ಲದ್ದಿಗುತ್ತ',
'ಲದ್ದಿಬೇನೆ',
'ಲಪಾಡಿಸು',
'ಲಬಲಬ',
'ಲಬೋಲಬೋ',
'ಲಬ್ಧವರ್ಣ',
'ಲಬ್ಧಿಬುದ್ಧ',
'ಲಯಂಗಿಡು',
'ಲಯಕರ',
'ಲಯಕರ್ತೃ',
'ಲಯಕರ್ಮಿ',
'ಲಯಕಾಲ',
'ಲಯಗತಿ',
'ಲಯಗ್ರಾಹಿ',
'ಲಯತ್ರಯ',
'ಲಯದೂರ',
'ಲಯಬದ್ಧ',
'ಲಯಮಿತ್ತು',
'ಲಯಮೂರ್ತಿ',
'ಲಯಯೋಗ',
'ಲಯಯೋಗಿ',
'ಲಯವಡು',
'ಲಯವಾದ್ಯ',
'ಲಯಾಂತಕ',
'ಲಯೋತ್ತರ',
'ಲರಿಲರಿ',
'ಲಲಂತಿಕೆ',
'ಲಲಾಟಕ',
'ಲಲಾಟಾಕ್ಷ',
'ಲಲಾಟಿಕೆ',
'ಲಲಾಮಕ',
'ಲಲಿತತೆ',
'ಲಲಿತಾಂಗ',
'ಲಲಿತಾಂಗಿ',
'ಲಲ್ಲಯಿಸು',
'ಲಲ್ಲೆಗರೆ',
'ಲಲ್ಲೆಗಱೆ',
'ಲಲ್ಲೆಗಾತಿ',
'ಲಲ್ಲೆಗಾರ್ತಿ',
'ಲಲ್ಲೆಗೆಯ್',
'ಲವಕುಟ',
'ಲವಣತೆ',
'ಲವಣಿಕೆ',
'ಲವಣಿಸು',
'ಲವಣೋದ',
'ಲವಲವ',
'ಲವಲವಿ',
'ಲವಳಿಗೆ',
'ಲವಾಜಮೆ',
'ಲವಾಜಮ್ಮು',
'ಲವೋದ್ಗಮ',
'ಲಸಕಿರಿ',
'ಲಸುಕರಿ',
'ಲಹರಿಕೆ',
'ಲಳಿತತೆ',
'ಲಳಿತಾಂಗ',
'ಲಳಿಯೇಳು',
'ಲಾಂಗಲಿಕ',
'ಲಾಂಗಲಿಕಿ',
'ಲಾಂಗುಲಿಕ',
'ಲಾಂಗೂಲಿಕ',
'ಲಾಕ್ಷಣಿಕ',
'ಲಾಕ್ಷಾಗೃಹ',
'ಲಾಕ್ಷಾದ್ರವ',
'ಲಾಕ್ಷಾಮುದ್ರೆ',
'ಲಾಕ್ಷಾರಸ',
'ಲಾಗವಡು',
'ಲಾಗವಾಡು',
'ಲಾಗಾಯತು',
'ಲಾಗಾಯತ್ತು',
'ಲಾಗಾಯಿತು',
'ಲಾಗುಕಾಱ',
'ಲಾಗುವಡು',
'ಲಾಗುವಾಡು',
'ಲಾಜಾಂಜಲಿ',
'ಲಾಜಾಂಜಳಿ',
'ಲಾಜಾಕ್ಷತೆ',
'ಲಾಜಾರೋಪ',
'ಲಾಜಾಹುತಿ',
'ಲಾಜಾಹೋಮ',
'ಲಾಠಿಹಲ್ಲೆ',
'ಲಾತಕೋಲು',
'ಲಾಭಂಬೆಱು',
'ಲಾಭಕರ',
'ಲಾಮಜ್ಜಕ',
'ಲಾಮಾಪಂಥ',
'ಲಾಯರಿಕೆ',
'ಲಾಯವಳ್ಳ',
'ಲಾಲ್‍ಬಾಗ್',
'ಲಾಲಬಂದಿ',
'ಲಾಲಬಾಗು',
'ಲಾಲಸಕ',
'ಲಾಲಾಗ್ರಂಥಿ',
'ಲಾಲಾಜಲ',
'ಲಾಲಾಜಳ',
'ಲಾಲಾಟಿಕಿ',
'ಲಾಲಾರಸ',
'ಲಾಲಿಪದ',
'ಲಾಲಿಹಾಡು',
'ಲಾವಣಿಕ',
'ಲಾವಣಿಗೆ',
'ಲಾವಣಿಸು',
'ಲಾವಳಿಗೆ',
'ಲಾವಾರಸ',
'ಲಾವುಕೋವು',
'ಲಾಳಕಟ್ಟು',
'ಲಾಳಗಾರ',
'ಲಾಳಬಂದಿ',
'ಲಾಳಲಿಪಿ',
'ಲಾಳವಟ್ಟೆ',
'ಲಾಳವಾಟೆ',
'ಲಾಳಾಜಳ',
'ಲಾಳಿಕೋಲು',
'ಲಿಂಗತತ್ವ',
'ಲಿಂಗತನು',
'ಲಿಂಗತೊಂಡೆ',
'ಲಿಂಗತ್ರಯ',
'ಲಿಂಗದಾಹ',
'ಲಿಂಗದೀಕ್ಷೆ',
'ಲಿಂಗದೂರ',
'ಲಿಂಗದೇಹ',
'ಲಿಂಗದೇಹಿ',
'ಲಿಂಗಧರ',
'ಲಿಂಗಧಾರಿ',
'ಲಿಂಗಪಥ',
'ಲಿಂಗಮುದ್ರೆ',
'ಲಿಂಗರೋಗ',
'ಲಿಂಗವಂತ',
'ಲಿಂಗವೃತ್ತಿ',
'ಲಿಂಗವ್ಯಾಧಿ',
'ಲಿಂಗಸ್ಥಲ',
'ಲಿಂಗಸ್ಥಳ',
'ಲಿಂಗಾಚಾರ',
'ಲಿಂಗಾತ್ಮಕ',
'ಲಿಂಗಾಯತ',
'ಲಿಂಗಾಯಿತ',
'ಲಿಂಗಾರ್ಪಿತ',
'ಲಿಂಬಕೋಳಿ',
'ಲಿಕಿಲಿಕಿ',
'ಲಿಖಾವಟ್ಟು',
'ಲಿಪಿಕರ',
'ಲಿಪಿಕರ್ತು',
'ಲಿಪಿಕಾರ',
'ಲಿಪಿಗಲ್ಲು',
'ಲಿಪಿಗಾರ',
'ಲಿಪಿಶಾಲೆ',
'ಲಿಪಿಶಾಸ್ತ್ರ',
'ಲಿಪ್ಯಂತರ',
'ಲಿಬಿಕಾರ',
'ಲಿಬಿಲಿಬಿ',
'ಲಿಸುಗುಟ್ಟು',
'ಲೀಲಾಕಾರ',
'ಲೀಲಾಜಾಲ',
'ಲೀಲಾಭಾವಿ',
'ಲೀಲಾಯಿತ',
'ಲೀಲಾವತಿ',
'ಲೀಲಾಳಕ',
'ಲೀಲೆಗಾತಿ',
'ಲೀಲೆಗಾರ್ತಿ',
'ಲೀಲೆಗಾಱ',
'ಲೀಲೆಗೆಡು',
'ಲೀಲೆದೋಱು',
'ಲೀಲೆವಡೆ',
'ಲೀಳಾವತಿ',
'ಲುಂಟಾಕಿನಿ',
'ಲುಕಸಾನ',
'ಲುಕಸಾನು',
'ಲುಕಸಾನೆ',
'ಲುಕುಸಾನು',
'ಲುಚ್ಚತನ',
'ಲುಚ್ಚಾಗಿರಿ',
'ಲುಚ್ಚಾತನ',
'ಲುಟಾಯಿಸು',
'ಲುಟುಲುಟು',
'ಲುಪ್ತಪಿಂಡ',
'ಲುಪ್ತೋಪಮೆ',
'ಲುಳಿದಾರ',
'ಲುಳಿಮನ',
'ಲುಳಿವಡೆ',
'ಲುಳಿವೆರು',
'ಲುಳಿವೆಱು',
'ಲೂನವಕ್ಷ',
'ಲೆಂಕತನ',
'ಲೆಂಕವಳಿ',
'ಲೆಂಕವಾಳ',
'ಲೆಂಕವಾಳಿ',
'ಲೆಂಡತನ',
'ಲೆಕ್ಕಂಗೊಳ್',
'ಲೆಕ್ಕಗೊಳು',
'ಲೆಕ್ಕಣಿಕೆ',
'ಲೆಕ್ಕಪತ್ರ',
'ಲೆಕ್ಕಮಿಡು',
'ಲೆಕ್ಕವಿಡು',
'ಲೆಕ್ಕಾಚಾರ',
'ಲೆಕ್ಖಾಚಾರ',
'ಲೆಖ್ಖಾಚಾರ',
'ಲೆಪ್ಪಗಾಱ',
'ಲೇಖಲಿಪಿ',
'ಲೇಖವಾಹ',
'ಲೇಖೀಹೊಣೆ',
'ಲೇಖ್ಯದೋಷ',
'ಲೇಣಿದೇಣಿ',
'ಲೇಣೆದೇಣೆ',
'ಲೇನೆದೇನೆ',
'ಲೇಪಾಳಿಗ',
'ಲೇಲಿಹಾನ',
'ಲೇವಾದೇವಿ',
'ಲೇಶ್ಯಾಶುದ್ಧಿ',
'ಲೇಸುಗಾತಿ',
'ಲೇಸುಗಾರ',
'ಲೇಸುಗಾಱ',
'ಲೇಸುಗೆಯ್',
'ಲೇಸುದಟ್ಟು',
'ಲೇಹ್ಯದ್ರವ್ಯ',
'ಲೇಹ್ಯಪಾಕ',
'ಲೈಂಗಿಕತೆ',
'ಲೊಕ್ಕಿಯಬೆ',
'ಲೊಕ್ಕಿಯಬ್ಬೆ',
'ಲೊಚಗರೆ',
'ಲೊಚಗುಟ್ಟು',
'ಲೊಚ್ಚುಗರೆ',
'ಲೊಟಕಲು',
'ಲೊಟಗುಟ್ಟು',
'ಲೊಟಪಟ',
'ಲೊಟಪಾಟ',
'ಲೊಟವಿಟಿ',
'ಲೊಟ್ಟಾಲೊಟ್ಟಿ',
'ಲೊಡಕಲು',
'ಲೊಡಲೊಟ್ಟೆ',
'ಲೊಡಲೊಡ',
'ಲೊಳಲೊಟ್ಟೆ',
'ಲೋಕಕರ್ತ',
'ಲೋಕಗತಿ',
'ಲೋಕಗುರು',
'ಲೋಕಚಕ್ಷು',
'ಲೋಕಜಿತ್ತು',
'ಲೋಕತಂತ್ರ',
'ಲೋಕದೂರ',
'ಲೋಕಧರ್ಮ',
'ಲೋಕನಾಥ',
'ಲೋಕನಿಷ್ಠ',
'ಲೋಕನೇತ್ರ',
'ಲೋಕಪತಿ',
'ಲೋಕಪಾಲ',
'ಲೋಕಪಿತ',
'ಲೋಕಭಿನ್ನ',
'ಲೋಕಮತ',
'ಲೋಕಮಾತೆ',
'ಲೋಕಮಾನ್ಯ',
'ಲೋಕಮಾನ್ಯೆ',
'ಲೋಕಮಿತ್ರ',
'ಲೋಕಮೂಢ',
'ಲೋಕಯಾತ್ರೆ',
'ಲೋಕರೂಢಿ',
'ಲೋಕವಶ್ಯ',
'ಲೋಕವಾದ',
'ಲೋಕವಾರ್ತೆ',
'ಲೋಕಸಭೆ',
'ಲೋಕಸಾಕ್ಷಿ',
'ಲೋಕಸೇವೆ',
'ಲೋಕಸ್ತುತ',
'ಲೋಕಸ್ತುತೆ',
'ಲೋಕಹಿತ',
'ಲೋಕಾಂತರ',
'ಲೋಕಾಂತಿಕ',
'ಲೋಕಾಕಾರ',
'ಲೋಕಾಕಾಶ',
'ಲೋಕಾಚಾರ',
'ಲೋಕಾಯತ',
'ಲೋಕಾರೂಢಿ',
'ಲೋಕಾಲೋಕ',
'ಲೋಕೋತ್ತರ',
'ಲೋಕೋದ್ಧಾರ',
'ಲೋಚುಗೆಯ್',
'ಲೋಧ್ರಪುಷ್ಪ',
'ಲೋಪಂಬೊರ್ದು',
'ಲೋಪಗೈಸು',
'ಲೋಪವೃತ್ತಿ',
'ಲೋಪಸಂಧಿ',
'ಲೋಭಿತನ',
'ಲೋಮನಾಳ',
'ಲೋಮಹರ್ಷ',
'ಲೋಯಿಸರ',
'ಲೋಲಜ್ಞಾನ',
'ಲೋಲಾಡಿಸು',
'ಲೋಲಾಸನ',
'ಲೋಲುಪತೆ',
'ಲೋಲುಪತ್ವ',
'ಲೋಲೇಕ್ಷಣ',
'ಲೋಲೇಕ್ಷಣೆ',
'ಲೋವಿಸರ',
'ಲೋಷ್ಟಭೇದ',
'ಲೋಹಕರ್ಮ',
'ಲೋಹಕಾರ',
'ಲೋಹಕಿಟ್ಟ',
'ಲೋಹಜಿತ್ತು',
'ಲೋಹಟಂಕ',
'ಲೋಹತೈಲ',
'ಲೋಹದ್ರವ',
'ಲೋಹನಿಧಿ',
'ಲೋಹಪೃಷ್ಠ',
'ಲೋಹಭಸ್ಮ',
'ಲೋಹಯುಗ',
'ಲೋಹವರ',
'ಲೋಹವಾರಿ',
'ಲೋಹಶಾಸ್ತ್ರ',
'ಲೋಹಾಸನ',
'ಲೋಹಿತಕ',
'ಲೋಹಿತಾಂಗ',
'ಲೋಹಿತಾಶ್ವ',
'ಲೋಳಸರ',
'ಲೋಳಿಸರ',
'ಲೋಳೆಮೀನು',
'ಲೋಳೆಸರ',
'ಲೌಕಿಕತೆ',
'ಲೌಕಿಕತ್ವ',
'ಲೌಡಿಗಾಱ',
'ಲೌಡಿಮಗ',
'ಲೌಲ್ಯಭಾವ',
'ಲೌಲ್ಯರಸ',
'ವಂಕದರ',
'ವಂಕದಾರ',
'ವಂಕಬಾರ',
'ವಂಗಡಿಸು',
'ವಂಗಭಸ್ಮ',
'ವಂಚನೆಗೈ',
'ವಂಚಿಸುಹ',
'ವಂಟಶಾಲೆ',
'ವಂತಕಾರಿ',
'ವಂದನೀಯ',
'ವಂದನೀಯೆ',
'ವಂದನೆಗೈ',
'ವಂದಿಗ್ರಹ',
'ವಂಧ್ಯಾಪಕ್ಷಿ',
'ವಂಧ್ಯಾಲಯ',
'ವಂಶಕರ',
'ವಂಶಚ್ಛೇದಿ',
'ವಂಶವೃಕ್ಷ',
'ವಂಶವೃದ್ಧಿ',
'ವಂಶಸ್ಥಲ',
'ವಂಶಾವಳಿ',
'ವಂಶಾಸನ',
'ವಂಶೋದ್ಧಾರ',
'ವಂಶೋದ್ಭವ',
'ವಂಸಕಾಱ',
'ವಕಾಲತ್',
'ವಕಾಲತಿ',
'ವಕಾಲತ್ತು',
'ವಕುಷ್ಟಕ',
'ವಕ್ಕಣಿಸು',
'ವಕ್ಕರಿಸು',
'ವಕ್ಖಣಿಸು',
'ವಕ್ಖಾಣಿಸು',
'ವಕ್ತ್ರಶ್ವಾಸ',
'ವಕ್ತ್ರಾಂತರ',
'ವಕ್ತ್ರೋದರ',
'ವಕ್ರಂಬರ್',
'ವಕ್ರಗಚ್ಛ',
'ವಕ್ರಗತಿ',
'ವಕ್ರಗ್ರೀವ',
'ವಕ್ರಚಾರ',
'ವಕ್ರತುಂಡ',
'ವಕ್ರದೃಷ್ಟಿ',
'ವಕ್ರದ್ವಾರ',
'ವಕ್ರಬರು',
'ವಕ್ರಬುದ್ಧಿ',
'ವಕ್ರವಾಕ್ಯ',
'ವಕ್ರಶೃಂಗೆ',
'ವಕ್ಷಸ್ಥಲ',
'ವಕ್ಷಸ್ಥಳ',
'ವಕ್ಷೋರುಹ',
'ವಖ್ಖಣಿಸು',
'ವಖ್ಖಾಣಿಸು',
'ವಚನಿಸು',
'ವಚನೀಯ',
'ವಚೋಗುಪ್ತಿ',
'ವಜಾಬಾಕಿ',
'ವಜಾವಟ್ಟ',
'ವಜೀರಿಕೆ',
'ವಜ್ರಕಾಯ',
'ವಜ್ರಕೀಟ',
'ವಜ್ರಕ್ಷಾರ',
'ವಜ್ರಗಚ್ಚು',
'ವಜ್ರಗಳ್ಳಿ',
'ವಜ್ರಗಾರೆ',
'ವಜ್ರಗಿರಿ',
'ವಜ್ರತನು',
'ವಜ್ರತುಂಡ',
'ವಜ್ರತೈಲ',
'ವಜ್ರದಂಡ',
'ವಜ್ರದಂತ',
'ವಜ್ರದಂತಿ',
'ವಜ್ರದುಂಬಿ',
'ವಜ್ರದೇಹ',
'ವಜ್ರದೇಹಿ',
'ವಜ್ರಧರ',
'ವಜ್ರನೀಲಿ',
'ವಜ್ರಪಾತ',
'ವಜ್ರಮುಷ್ಟಿ',
'ವಜ್ರಯೋಗ',
'ವಜ್ರಲೇಪ',
'ವಜ್ರಹಳಿ',
'ವಜ್ರಾಘಾತ',
'ವಜ್ರಾಯುಧ',
'ವಜ್ರಾಸನ',
'ವಜ್ರೋತ್ಸವ',
'ವಟಗುಟ್ಟು',
'ವಟಗುಡು',
'ವಟವಟ',
'ವಟವಟಿ',
'ವಟಾಯಿಸು',
'ವಟೃಸುಳಿ',
'ವಟ್ಟಪಟ್ಟಿ',
'ವಟ್ಟೃಸುಳಿ',
'ವಟ್ಟೃಸುೞಿ',
'ವಟ್ಟೆಬುಲ್ಲು',
'ವಡಗಣ',
'ವಡಗಯ್',
'ವಡಗಾಯ್',
'ವಡಬಾಗ್ನಿ',
'ವಡವಡ',
'ವಡಿಕತ್ತಿ',
'ವಡಿಬೀಸು',
'ವಡುವಾರ',
'ವಡ್ಡದಾರಿ',
'ವಡ್ಡರಾಗಿ',
'ವಡ್ಡವಾರ',
'ವಡ್ಡಾಚಾರ',
'ವಣಖಾಣ',
'ವಣಿಗ್ವಿದ್ಯೆ',
'ವಣಿಜಕ',
'ವತ್ತುಗಾಯಿ',
'ವತ್ಸಕಾಮೆ',
'ವತ್ಸಕುಲ',
'ವತ್ಸಗೋತ್ರ',
'ವತ್ಸತರ',
'ವತ್ಸನಾಭ',
'ವತ್ಸನಾಭಿ',
'ವತ್ಸಲತ್ವ',
'ವತ್ಸಾದನಿ',
'ವದಾನ್ಯತೆ',
'ವದಾನ್ಯತ್ವ',
'ವದಾವದ',
'ವಧಕಾರ',
'ವಧಿಯಿಸು',
'ವಧುತಾತ',
'ವಧೂಗೃಹ',
'ವಧೂಜನ',
'ವಧೂಬಂಧು',
'ವಧ್ಯಮಾಲೆ',
'ವಧ್ಯಶಿಲೆ',
'ವನಚರ',
'ವನಚರಿ',
'ವನಜಜ',
'ವನಜಾಕ್ಷ',
'ವನಜಾಕ್ಷಿ',
'ವನದುರ್ಗ',
'ವನದುರ್ಗೆ',
'ವನದೇವಿ',
'ವನನರ',
'ವನನಿಧಿ',
'ವನಪಾಲ',
'ವನಪ್ರಸ್ಥ',
'ವನಪ್ರಿಯ',
'ವನಮಾಲಿ',
'ವನಮಾಲೆ',
'ವನಮಾಳಿ',
'ವನಮಾಳೆ',
'ವನಮುದ್ಗ',
'ವನಮೃಗ',
'ವನರಾಶಿ',
'ವನರುಹ',
'ವನಲತೆ',
'ವನವಶಿ',
'ವನವಸಿ',
'ವನವಾಶಿ',
'ವನವಾಸ',
'ವನವಾಸಿ',
'ವನವಾಸೆ',
'ವನಶಿಖಿ',
'ವನಸುಮ',
'ವನಸೌತೆ',
'ವನಸ್ಥಲ',
'ವನಸ್ಥಲಿ',
'ವನಸ್ಥಳ',
'ವನಸ್ಥಳಿ',
'ವನಸ್ಥಿತ',
'ವನಸ್ಪತಿ',
'ವನಸ್ಪತ್ಯ',
'ವನಾಂತರ',
'ವನಾಯುಜ',
'ವನಾಲಯ',
'ವನಾಶ್ರಯ',
'ವನೀಪಕ',
'ವನೇಚರ',
'ವನೇಚರಿ',
'ವನೋರಣ',
'ವನೌಕಸ',
'ವನೌಷಧಿ',
'ವನ್ಯಮೃಗ',
'ವಪುಜಲ',
'ವಪುಷ್ಮಂತ',
'ವಪುಸಿದ್ಧಿ',
'ವಮನಿಸು',
'ವಮ್ರೀಕೂಟ',
'ವಯಃಪ್ರಾಪ್ತ',
'ವಯಃಪ್ರಾಪ್ತೆ',
'ವಯಿಸಾಖ',
'ವಯೋಗುಣ',
'ವಯೋಧರ್ಮ',
'ವಯೋಧಿಕ',
'ವಯೋವೃದ್ಧ',
'ವಯೋವೃದ್ಧೆ',
'ವರಂಗೊಡು',
'ವರಂಬಡೆ',
'ವರಕರ',
'ವರದಾನ',
'ವರದಾನಿ',
'ವರದಿಸು',
'ವರನಾಳಿ',
'ವರಪೂಜೆ',
'ವರಮಾನ',
'ವರಮುಖಿ',
'ವರಯಿತ',
'ವರಯೋಗ್ಯೆ',
'ವರರುಚಿ',
'ವರವಿಗೆ',
'ವರವಿಡಿ',
'ವರಸಾಮ್ಯ',
'ವರಹೀನ',
'ವರಾಂಗಕ',
'ವರಾಟಕ',
'ವರಾರೋಹ',
'ವರಾರೋಹೆ',
'ವರಿಯಿಸು',
'ವರಿವಸ್ಯೆ',
'ವರಿವಿಗೆ',
'ವರುಣಾನಿ',
'ವರುಣಾಶೆ',
'ವರುಣಾಸ್ತ್ರ',
'ವರೂಥಿನಿ',
'ವರ್ಕುಣಿಕೆ',
'ವರ್ಗಂಗೊಳ್',
'ವರ್ಗತ್ರಯ',
'ವರ್ಗದಾರ',
'ವರ್ಗಪ್ರಾಸ',
'ವರ್ಗಭೇದ',
'ವರ್ಗಮೂಲ',
'ವರ್ಗಾಕ್ಷರ',
'ವರ್ಗಾಯಿಸು',
'ವರ್ಗಾವಣೆ',
'ವರ್ಗಾವರ್ಗಿ',
'ವರ್ಗೋದಿತ',
'ವರ್ಜನೀಯ',
'ವರ್ಣಕರ್ಮ',
'ವರ್ಣಕಾರ',
'ವರ್ಣಕೃತಿ',
'ವರ್ಣಗಣ',
'ವರ್ಣಚಿತ್ರ',
'ವರ್ಣಚ್ಛವಿ',
'ವರ್ಣತಂತು',
'ವರ್ಣತ್ರಯ',
'ವರ್ಣದ್ವೇಷ',
'ವರ್ಣಧರ್ಮ',
'ವರ್ಣಪುಷ್ಪ',
'ವರ್ಣಪೂರ',
'ವರ್ಣಭೇದ',
'ವರ್ಣಮಾಲೆ',
'ವರ್ಣಲಾಭ',
'ವರ್ಣವೃತ್ತ',
'ವರ್ಣಶಿಲ್ಪ',
'ವರ್ಣಶಿಲ್ಪಿ',
'ವರ್ಣಶೂನ್ಯ',
'ವರ್ಣಾಂಧತೆ',
'ವರ್ಣಾಲ್ಪತೆ',
'ವರ್ಣಾವೃತ್ತಿ',
'ವರ್ಣಾಶ್ರಮ',
'ವರ್ಣೋತ್ತಮ',
'ವರ್ತಮಾನ',
'ವರ್ಧಮಾನ',
'ವರ್ಮಗೆಡು',
'ವರ್ಮವರ',
'ವರ್ಯಜನ',
'ವರ್ಷಂಪ್ರತಿ',
'ವರ್ಷಕಾರಿ',
'ವರ್ಷಕಾಲ',
'ವರ್ಷಗಂತಿ',
'ವರ್ಷಗಂದಿ',
'ವರ್ಷಧರ',
'ವರ್ಷಧಾರೆ',
'ವರ್ಷನಗ',
'ವರ್ಷಫಲ',
'ವರ್ಷವರ',
'ವರ್ಷಾಂತರ',
'ವರ್ಷಾಕಾಲ',
'ವರ್ಷಾಗಮ',
'ವರ್ಷಾಬ್ದಿಕ',
'ವರ್ಷಾವಾಸ',
'ವರ್ಷಾಶನ',
'ವರ್ಷೋಪಲ',
'ವಲಯಿತ',
'ವಲಸಿಗ',
'ವಲಸಿಗೆ',
'ವಲಿಮುಖ',
'ವಲೀಮುಖ',
'ವಲೀವರ',
'ವಲುಕಲ',
'ವಲುಮ್ಮೆನೆ',
'ವಲ್ಕಂದಳೆ',
'ವಲ್ಗನಗೈ',
'ವಲ್ಗುರೂಪೆ',
'ವಲ್ಲಭಿಕೆ',
'ವಲ್ಲಿಪಾಟು',
'ವಲ್ಲಿಮಾಟ',
'ವಲ್ಲಿವಾಟ',
'ವಶತಪ್ಪು',
'ವಶಪಡು',
'ವಶವರ್ತಿ',
'ವಶೀಕಾರ',
'ವಶೀಕೃತ',
'ವಶೀಕೃತೆ',
'ವಶ್ಯದೀಪ',
'ವಶ್ಯವಾಣಿ',
'ವಶ್ಯಸುಪ್ತಿ',
'ವಶ್ಯಾಂಜನ',
'ವಶ್ಯಾಕ್ಷರ',
'ವಷಟ್ಕಾರ',
'ವಷಟ್ಕೃತ',
'ವಷಟ್ಕೃತಿ',
'ವಷ್ಕಯಿಣಿ',
'ವಸಂತಕ',
'ವಸನಾಂಗ',
'ವಸವಂತ',
'ವಸಹಾತು',
'ವಸಾಹತ',
'ವಸಾಹತು',
'ವಸಾಹತ್ತು',
'ವಸಿಯಿಸು',
'ವಸುಂಧರೆ',
'ವಸುಕರ',
'ವಸುಕಾಂತಿ',
'ವಸುಗರ್ಭ',
'ವಸುಧಾರೆ',
'ವಸುಧೇಶ',
'ವಸುಭಟ',
'ವಸುಮಣಿ',
'ವಸುಮತಿ',
'ವಸುಮದ',
'ವಸುವೀರ್ಯ',
'ವಸುಹರ',
'ವಸುಹೀನ',
'ವಸೂಲಾತಿ',
'ವಸೂವಣಿ',
'ವಸ್ತಕಾರ',
'ವಸ್ತಿಕರ್ಮ',
'ವಸ್ತಿಕಾರ',
'ವಸ್ತಿಗಾಱ',
'ವಸ್ತಿಬಡು',
'ವಸ್ತಿಸ್ಥಾನ',
'ವಸ್ತುಕವಿ',
'ವಸ್ತುಕಾರ',
'ವಸ್ತುಕಾವ್ಯ',
'ವಸ್ತುಕೃತಿ',
'ವಸ್ತುಕೋಶ',
'ವಸ್ತುಗತ',
'ವಸ್ತುಗಾರ',
'ವಸ್ತುತಂತ್ರ',
'ವಸ್ತುತತ್ವ',
'ವಸ್ತುಧ್ವನಿ',
'ವಸ್ತುನಿಷ್ಠ',
'ವಸ್ತುಬಂಧ',
'ವಸ್ತುಬಾಹ್ಯ',
'ವಸ್ತುಭಂಡ',
'ವಸ್ತುಭಾವ',
'ವಸ್ತುವಿದ',
'ವಸ್ತುಸ್ತವ',
'ವಸ್ತುಸ್ಥಿತಿ',
'ವಸ್ತೂಪಮೆ',
'ವಸ್ತ್ರಮೃಗ',
'ವಸ್ತ್ರಾಸನ',
'ವಸ್ಸಾವಾಸ',
'ವಹಮಾನ',
'ವಹವಣಿ',
'ವಹಿತ್ರಕ',
'ವಹಿವಾಟ್',
'ವಹಿವಾಟ',
'ವಹಿವಾಟಿ',
'ವಹಿವಾಟು',
'ವಹ್ನಿಜ್ವಾಲೆ',
'ವಹ್ನಿಶಿಖ',
'ವಹ್ನಿಸಖ',
'ವಹ್ನಿಸೂಕ್ತ',
'ವಳಯಿತ',
'ವಳಾವಳಿ',
'ವಳಿತ್ರಯ',
'ವಳಿದೆರೆ',
'ವಳಿಯಿಸು',
'ವಳಿರೇಖೆ',
'ವಳ್ಳಿಯೋಗ',
'ವಾಂಗಿಭಾತು',
'ವಾಂಗೀಬಾತು',
'ವಾಂಗೀಭಾತು',
'ವಾಂಛನೀಯ',
'ವಾಂತಾಶನ',
'ವಾಂತಿಭೇದಿ',
'ವಾಂತಿಭ್ರಮೆ',
'ವಾಂತಿಭ್ರಾಂತಿ',
'ವಾಂಶಿಕತೆ',
'ವಾಃಕಣಿಕೆ',
'ವಾಕರಿಕೆ',
'ವಾಕರಿಸು',
'ವಾಕಳಿಕೆ',
'ವಾಕಳಿಸು',
'ವಾಕುಶಾಸ್ತ್ರ',
'ವಾಕುಸಿದ್ಧಿ',
'ವಾಕ್ಕಲಹ',
'ವಾಕ್ಕುಟಿಲ',
'ವಾಕ್ಕೋವಿದ',
'ವಾಕ್ಚತುರ',
'ವಾಕ್ಚಾತುರ್ಯ',
'ವಾಕ್ಚಾಪಲ್ಯ',
'ವಾಕ್ಛ್ರೀಯುತ',
'ವಾಕ್ಪಟಲ',
'ವಾಕ್ಪಾಟವ',
'ವಾಕ್ಪಾರುಷ್ಯ',
'ವಾಕ್ಪೌರುಷ',
'ವಾಕ್ಯದೋಷ',
'ವಾಕ್ಯವೃಂದ',
'ವಾಕ್ಯಶೇಷ',
'ವಾಕ್ಯಾನ್ವಯ',
'ವಾಕ್ಯೋಪಮೆ',
'ವಾಕ್ಸಂಪತ್ತು',
'ವಾಕ್ಸರಣಿ',
'ವಾಕ್ಸೌಂದರ್ಯ',
'ವಾಕ್ಸ್ವಾತಂತ್ರ್ಯ',
'ವಾಖಾಣಿಸು',
'ವಾಗಡಿಸು',
'ವಾಗಧಿಪ',
'ವಾಗನೀಸ',
'ವಾಗಿಂದ್ರಿಯ',
'ವಾಗೀಶ್ವರ',
'ವಾಗೀಶ್ವರಿ',
'ವಾಗುರಿಕ',
'ವಾಗುರಿಕೆ',
'ವಾಗ್ದರಿದ್ರ',
'ವಾಗ್ದೇವತೆ',
'ವಾಗ್ದೋಷಕ',
'ವಾಗ್ಬಂಧನ',
'ವಾಗ್ಯದಂಡೆ',
'ವಾಗ್ರಮಣ',
'ವಾಗ್ರಮಣಿ',
'ವಾಗ್ವಧೂಟಿ',
'ವಾಗ್ವನಿತೆ',
'ವಾಗ್ವಿಭವ',
'ವಾಗ್ವಿಲಾಸ',
'ವಾಗ್ವಿವಾದ',
'ವಾಗ್ವಿಸ್ತರ',
'ವಾಗ್ವಿಳಾಸ',
'ವಾಗ್ವೈಖರಿ',
'ವಾಗ್ವೈಭವ',
'ವಾಗ್ವ್ಯಾಪಾರ',
'ವಾಙ್ಮಹಿಮೆ',
'ವಾಙ್ಮಾಧುರ್ಯ',
'ವಾಚಂಯಮ',
'ವಾಚಂಯಮಿ',
'ವಾಚಕತ್ವ',
'ವಾಚಸ್ಪತಿ',
'ವಾಚಳಕ',
'ವಾಚಾಲಕ',
'ವಾಚಾಲತೆ',
'ವಾಚಾಲತ್ವ',
'ವಾಚಾಲಿತ',
'ವಾಚಾಳಕ',
'ವಾಚಾಳತೆ',
'ವಾಚ್ಯಲಿಂಗ',
'ವಾಚ್ಯಾಯನ',
'ವಾಜಪೇಯ',
'ವಾಜಿಕರಿ',
'ವಾಜಿಖುರ',
'ವಾಜಿಗಂಧ',
'ವಾಜಿತರ',
'ವಾಜಿನಾಮ',
'ವಾಜಿಬಳ',
'ವಾಜಿಮೇಧ',
'ವಾಜಿಶಾಲೆ',
'ವಾಜೀಕರ',
'ವಾಟಾಘಾಟ',
'ವಾಟಾಘಾಟಿ',
'ವಾಟಿಕಾರ',
'ವಾಟಿಹುಳಿ',
'ವಾಟೆಬಾಳೆ',
'ವಾಟ್ಯಾರಣಿ',
'ವಾಟ್ಯಾಲಕ',
'ವಾಡವಾಗ್ನಿ',
'ವಾಡವೇಯ',
'ವಾಣರಾಸಿ',
'ವಾಣಿಗಿತ್ತಿ',
'ವಾಣಜಿಕ',
'ವಾಣೀಪತಿ',
'ವಾತಾಂಕಣ',
'ವಾತಕ್ಷಯ',
'ವಾತಖಾಸ',
'ವಾತಗುಲ್ಮ',
'ವಾತಜನ್ಯ',
'ವಾತಜಿಹ್ವೆ',
'ವಾತಜ್ವರ',
'ವಾತದಂತ',
'ವಾತದೋಷ',
'ವಾತಧಾತು',
'ವಾತನಾಡಿ',
'ವಾತನೀಯ',
'ವಾತಪೋಥ',
'ವಾತಪ್ರಮಿ',
'ವಾತಫಲ',
'ವಾತಭುಕು',
'ವಾತಮೃಗ',
'ವಾತರೋಗ',
'ವಾತರೋಗಿ',
'ವಾತವಾಜಿ',
'ವಾತವೃಷೆ',
'ವಾತಸನ್ನಿ',
'ವಾತಸಾರ',
'ವಾತಾಜೀರ್ಣ',
'ವಾತಾಯನ',
'ವಾತಾಶನ',
'ವಾತಾಹತಿ',
'ವಾತಾಹಾರ',
'ವಾತೂಲಿಕೆ',
'ವಾತೂಳಿಕೆ',
'ವಾತೋದರ',
'ವಾತೋದರಿ',
'ವಾತ್ಸ್ಯಾಯನ',
'ವಾದಂಗೆಯ್',
'ವಾದಗೋಷ್ಠಿ',
'ವಾದಗ್ರಸ್ತ',
'ವಾದಿಸ್ವರ',
'ವಾದುಗುಟ್ಟು',
'ವಾದುಗೆಯ್',
'ವಾದ್ಯಕಾರ',
'ವಾದ್ಯಕೋಣ',
'ವಾದ್ಯಗಾರ',
'ವಾದ್ಯಗೋಷ್ಠಿ',
'ವಾದ್ಯಭಾಂಡ',
'ವಾದ್ಯಮೇಳ',
'ವಾದ್ರೀಣಸ',
'ವಾನಪ್ರಸ್ಥ',
'ವಾನಸ್ಪತ್ಯ',
'ವಾನಾಯುಜ',
'ವಾನೇಚರ',
'ವಾನ್ಪಯಿರು',
'ವಾಪರಿಸು',
'ವಾಪೀಮೂಲ',
'ವಾಮಕುಕ್ಷಿ',
'ವಾಮಕ್ಷೇಮ',
'ವಾಮದೇವ',
'ವಾಮನೇತ್ರೆ',
'ವಾಮಪಂಥ',
'ವಾಮಪಕ್ಷ',
'ವಾಮಮಾರ್ಗ',
'ವಾಮಲೂರ',
'ವಾಮವಿದ್ಧ',
'ವಾಮಸುಖ',
'ವಾಮಾಚಾರ',
'ವಾಮಾನಿಕೆ',
'ವಾಮಾರ್ಧಾಂಗ',
'ವಾಯವ್ಯಾಸ್ತ್ರ',
'ವಾಯಸಾರಿ',
'ವಾಯಸೋಲಿ',
'ವಾಯಿಧಾರೆ',
'ವಾಯುಕೋಷ್ಠ',
'ವಾಯುಗುಣ',
'ವಾಯುಗುಲ್ಮ',
'ವಾಯುಪಟ್ಟ',
'ವಾಯುಪಡೆ',
'ವಾಯುಪಥ',
'ವಾಯುಪ್ರಾಣಿ',
'ವಾಯುಬಲ',
'ವಾಯುಭಕ್ಷ',
'ವಾಯುಮಾರ್ಗ',
'ವಾಯುಮೃಗ',
'ವಾಯುಯಾನ',
'ವಾಯುರಯ',
'ವಾಯುರೋಗ',
'ವಾಯುಲಿಂಗ',
'ವಾಯುವೇಗ',
'ವಾಯುವೇಗಿ',
'ವಾಯುಸೇನೆ',
'ವಾಯುಸ್ತಂಭ',
'ವಾಯುಹತಿ',
'ವಾರಂವಾರ',
'ವಾರಕರಿ',
'ವಾರಕಾಂತೆ',
'ವಾರಗಾರ',
'ವಾರಗಿತ್ತಿ',
'ವಾರಣಶಿ',
'ವಾರಣಾಸಿ',
'ವಾರಣಾಸ್ಯ',
'ವಾರಣಿಸು',
'ವಾರನಾರಿ',
'ವಾರವಾಣ',
'ವಾರಶೂಲ',
'ವಾರಶೂಲೆ',
'ವಾರಸತಿ',
'ವಾರಾಂಗನೆ',
'ವಾರಾಕರ',
'ವಾರಾಣಸಿ',
'ವಾರಿಕಲ್',
'ವಾರಿಕಲ್ಲು',
'ವಾರಿಕಾಱ',
'ವಾರಿಕೇಳಿ',
'ವಾರಿಧರ',
'ವಾರಿನಾಥ',
'ವಾರಿನಿಧಿ',
'ವಾರಿಪರ್ಣಿ',
'ವಾರಿಫೇನ',
'ವಾರಿಭರ',
'ವಾರಿಯಂತ್ರ',
'ವಾರಿರಮ್ಯ',
'ವಾರಿರುಹ',
'ವಾರಿವಾಹ',
'ವಾರಿಶತ್ರ',
'ವಾರಿಶಿಲೆ',
'ವಾರಿಶೂಕ',
'ವಾರುಣಾಸ್ತ್ರ',
'ವಾರ್ತಾಚರ',
'ವಾರ್ತಾಭಾರ',
'ವಾರ್ತಾವಹ',
'ವಾರ್ತಾಹರ',
'ವಾರ್ತೆಗೇಳ್',
'ವಾರ್ಧಿವಿಭು',
'ವಾರ್ಧುಷಿಕ',
'ವಾರ್ಮಾನುಷಿ',
'ವಾಲಹಸ್ತ',
'ವಾವದೂಕ',
'ವಾಸಂತಿಕ',
'ವಾಸಂತಿಕೆ',
'ವಾಸಕಾಲು',
'ವಾಸಕಾಱ',
'ವಾಸಗಾಱ',
'ವಾಸಗೃಹ',
'ವಾಸತೇಯಿ',
'ವಾಸನಿಸು',
'ವಾಸಯಷ್ಟಿ',
'ವಾಸರವ',
'ವಾಸವತಿ',
'ವಾಸವಸ್ತ್ರೀ',
'ವಾಸವಾಶೆ',
'ವಾಸಸ್ಥಲ',
'ವಾಸಸ್ಥಳ',
'ವಾಸಸ್ಥಾನ',
'ವಾಸಾಗಾರ',
'ವಾಸಾಘೃತ',
'ವಾಸಾಹತು',
'ವಾಸಿತೋಱು',
'ವಾಸಿಬೀಳು',
'ವಾಸಿವಟ್ಟ',
'ವಾಸುದೇವ',
'ವಾಸುಪೂಜ್ಯ',
'ವಾಸೆದೋರು',
'ವಾಸೋದಕ',
'ವಾಸ್ತವಿಕ',
'ವಾಸ್ತುಕರ್ಮ',
'ವಾಸ್ತುಕಲೆ',
'ವಾಸ್ತುಕ್ರಮ',
'ವಾಸ್ತುಪತಿ',
'ವಾಸ್ತುವಿದ್ಯೆ',
'ವಾಸ್ತುಶಾಂತಿ',
'ವಾಸ್ತುಶಾಸ್ತ್ರ',
'ವಾಸ್ತುಶಿಲ್ಪ',
'ವಾಸ್ತುಶಿಲ್ಪಿ',
'ವಾಸ್ತೋಷ್ಪತಿ',
'ವಾಹಕತೆ',
'ವಾಹಕತ್ವ',
'ವಾಹಿನೀಕ',
'ವಾಹ್ಯಾಳಿಸು',
'ವಾಳದಂಡ',
'ವಾಳಾದಾನ',
'ವಾಳಿಕಾರ',
'ವಾಳಿಯಾವು',
'ವಾೞ್ತೆಗೊಳ್',
'ವಿಂಗಡಣೆ',
'ವಿಂಗಡಿಸು',
'ವಿಕಂಟಕ',
'ವಿಕಂಕತ',
'ವಿಕಂಪಿಸು',
'ವಿಕಚತೆ',
'ವಿಕಚನ',
'ವಿಕಚಿಸು',
'ವಿಕಜ್ಜಲ',
'ವಿಕಜ್ಜಳ',
'ವಿಕಟತೆ',
'ವಿಕಟಾಂಗ',
'ವಿಕಟಾಂಗಿ',
'ವಿಕತ್ಥನ',
'ವಿಕರಣ',
'ವಿಕರಾಲ',
'ವಿಕರಾಳ',
'ವಿಕರಿಸು',
'ವಿಕರ್ತನ',
'ವಿಕರ್ಷಕ',
'ವಿಕರ್ಷಣೆ',
'ವಿಕರ್ಷಿಸು',
'ವಿಕಲತೆ',
'ವಿಕಲಾಂಗ',
'ವಿಕಲಾಂಗಿ',
'ವಿಕಲಾಕ್ಷ',
'ವಿಕಲಿತ',
'ವಿಕಲಿತೆ',
'ವಿಕಲ್ಪಾರ್ಥ',
'ವಿಕಲ್ಪಿತ',
'ವಿಕಲ್ಪಿಸು',
'ವಿಕಸನ',
'ವಿಕಸಿತ',
'ವಿಕಸಿಸು',
'ವಿಕಸ್ವರ',
'ವಿಕಳತೆ',
'ವಿಕಳತ್ವ',
'ವಿಕಳಾಂಗ',
'ವಿಕಳಾಂಗಿ',
'ವಿಕಳಿತ',
'ವಿಕಳಿಸು',
'ವಿಕಾರತೆ',
'ವಿಕಾರಿಸು',
'ವಿಕಾಶಿಸು',
'ವಿಕಾಸನ',
'ವಿಕಾಸಿಸು',
'ವಿಕಾಳಿಸು',
'ವಿಕಿರಣ',
'ವಿಕೀರಣ',
'ವಿಕುದ್ರಕ',
'ವಿಕುರ್ವಾಣ',
'ವಿಕೂಣಿತ',
'ವಿಕೃತಾಂಗ',
'ವಿಕೃತಾಂಗಿ',
'ವಿಕ್ರಮಿಸು',
'ವಿಕ್ರಯಿಕ',
'ವಿಕ್ರಯಿಸು',
'ವಿಕ್ಷಿಪ್ತತೆ',
'ವಿಕ್ಷುಬ್ಧತೆ',
'ವಿಕ್ಷೇಪಣ',
'ವಿಕ್ಷೇಪಣಿ',
'ವಿಕ್ಷೋಭಿತ',
'ವಿಗಡಿಸು',
'ವಿಗಲಿತ',
'ವಿಗಳನ',
'ವಿಗಳಿತ',
'ವಿಗೞಿಗೆ',
'ವಿಗುರ್ಬಣೆ',
'ವಿಗುರ್ಬಿಸು',
'ವಿಗುರ್ವಣೆ',
'ವಿಗುರ್ವಿಸು',
'ವಿಗೂರ್ವಿಸು',
'ವಿಗ್ರಹಿಸು',
'ವಿಘಟನ',
'ವಿಘಟಿತ',
'ವಿಘಟಿಸು',
'ವಿಘಸಾಸಿ',
'ವಿಘಳಿಗೆ',
'ವಿಘೞಿಗೆ',
'ವಿಘಾತಕ',
'ವಿಘಾತಿಸು',
'ವಿಘೂರ್ಣನ',
'ವಿಘೂರ್ಣಿಸು',
'ವಿಘ್ನೇಶ್ವರ',
'ವಿಚಕಿಲ',
'ವಿಚಕಿಳ',
'ವಿಚಕ್ಷಣ',
'ವಿಚಕ್ಷಣೆ',
'ವಿಚಯನ',
'ವಿಚರ್ಚಿಕೆ',
'ವಿಚಲತೆ',
'ವಿಚಲಿತ',
'ವಿಚಲಿತೆ',
'ವಿಚಳತೆ',
'ವಿಚಳಿತ',
'ವಿಚಳಿತೆ',
'ವಿಚಾರಜ',
'ವಿಚಾರಣೆ',
'ವಿಚಾರಿಸು',
'ವಿಚಿಕಿತ್ಸೆ',
'ವಿಚಿತ್ರಾಂಗಿ',
'ವಿಚೂರ್ಣಿತ',
'ವಿಚ್ಛಾಯತೆ',
'ವಿಚ್ಛಾಯತ್ವ',
'ವಿಚ್ಛಿದುರ',
'ವಿಚ್ಛುರಿತ',
'ವಿಚ್ಛೇದನ',
'ವಿಚ್ಚೇದಿಸು',
'ವಿಜಪಿಲ',
'ವಿಜಯತೆ',
'ವಿಜಯಶ್ರೀ',
'ವಿಜಯಿಸು',
'ವಿಜಾತೀಯ',
'ವಿಜಾಲಕ',
'ವಿಜಿಗೀಷು',
'ವಿಜಿಗೀಷೆ',
'ವಿಜೃಂಭಣ',
'ವಿಜೃಂಭಣೆ',
'ವಿಜೃಂಭಿತ',
'ವಿಜೃಂಭಿಸು',
'ವಿಜ್ಜಣಿಗೆ',
'ವಿಜ್ಜಧರ',
'ವಿಜ್ಞವೇಷ',
'ವಿಜ್ಞಾನಾತ್ಮ',
'ವಿಜ್ಞಾನಿಕ',
'ವಿಜ್ಞಾಪನ',
'ವಿಜ್ಞಾಪನೆ',
'ವಿಜ್ಞಾಪಿತ',
'ವಿಜ್ಞಾಪಿಸು',
'ವಿಜ್ಞಾಸಾರ',
'ವಿಟಗಾತಿ',
'ವಿಟಗಾರ',
'ವಿಟಗಾರ್ತಿ',
'ವಿಟಚರ್ಯೆ',
'ವಿಟಚೇಷ್ಟೆ',
'ವಿಟತತ್ವ',
'ವಿಟತನ',
'ವಿಟಮಿನ್ನು',
'ವಿಟವೃತ್ತ',
'ವಿಟಾಳಿಸು',
'ವಿಟ್ಖದಿರ',
'ವಿಡಂಬಕ',
'ವಿಡಂಬನ',
'ವಿಡಂಬನೆ',
'ವಿಡಂಬರ',
'ವಿಡಂಬಿತ',
'ವಿಡಂಬಿಸು',
'ವಿಡಾಯಗ',
'ವಿಡೂರಜ',
'ವಿಡ್ವರಾಹ',
'ವಿಢಾಳಿಸು',
'ವಿತಂದ್ರತ್ವ',
'ವಿತರಣ',
'ವಿತರಣೆ',
'ವಿತರಿಸು',
'ವಿತರ್ಕಿತ',
'ವಿತರ್ದಿಕೆ',
'ವಿತಳ್ಳಂಕ',
'ವಿತಾಡಿತ',
'ವಿತಾನಕ',
'ವಿತಾಳಿಸು',
'ವಿತುನ್ನಕ',
'ವಿತ್ತಸ್ಥಾನ',
'ವಿತ್ತಾಧೀಶ',
'ವಿತ್ತಿಕಾಱ',
'ವಿತ್ತೇಷಣ',
'ವಿತ್ಪನ್ನತೆ',
'ವಿತ್ರಾಸಿತ',
'ವಿದಗ್ಧತೆ',
'ವಿದಲನ',
'ವಿದಲಿತ',
'ವಿದಳನ',
'ವಿದಳಾನ್ನ',
'ವಿದಳಿತ',
'ವಿದಳಿಸು',
'ವಿದಾರಕ',
'ವಿದಾರಣ',
'ವಿದಾರಿಕೆ',
'ವಿದಾರಿತ',
'ವಿದಾರಿಸು',
'ವಿದಿತಾತ್ಮ',
'ವಿದುರ್ಮಣಿ',
'ವಿದೂಷಕ',
'ವಿದೇಶಾಂಗ',
'ವಿದೇಶೀಯ',
'ವಿದ್ಧಂಬರೆ',
'ವಿದ್ಧಕರ್ಣಿ',
'ವಿದ್ಯಗೂಡು',
'ವಿದ್ಯಮಾನ',
'ವಿದ್ಯಾಕೇಂದ್ರ',
'ವಿದ್ಯಾಗರ್ವ',
'ವಿದ್ಯಾಗುರು',
'ವಿದ್ಯಾಚಣ',
'ವಿದ್ಯಾಚಾರ್ಯ',
'ವಿದ್ಯಾಚ್ಛೇದ',
'ವಿದ್ಯಾದಾನ',
'ವಿದ್ಯಾಧನ',
'ವಿದ್ಯಾಧರ',
'ವಿದ್ಯಾಧರಿ',
'ವಿದ್ಯಾನಿಧಿ',
'ವಿದ್ಯಾಪಾರ',
'ವಿದ್ಯಾಪೀಠ',
'ವಿದ್ಯಾಭ್ಯಾಸ',
'ವಿದ್ಯಾಮಂತ್ರಿ',
'ವಿದ್ಯಾಮದ',
'ವಿದ್ಯಾರತ',
'ವಿದ್ಯಾರತೆ',
'ವಿದ್ಯಾರೇಖೆ',
'ವಿದ್ಯಾರ್ಜನೆ',
'ವಿದ್ಯಾರ್ಥಿನಿ',
'ವಿದ್ಯಾಲಯ',
'ವಿದ್ಯಾವಂತ',
'ವಿದ್ಯಾವಂತೆ',
'ವಿದ್ಯಾವತಿ',
'ವಿದ್ಯಾವಿದ',
'ವಿದ್ಯಾಶಾಲೆ',
'ವಿದ್ಯಾಸತ್ರ',
'ವಿದ್ಯುಚ್ಛಕ್ತಿ',
'ವಿದ್ಯುತ್ಕಾಂತ',
'ವಿದ್ಯುತ್ಕೇಂದ್ರ',
'ವಿದ್ಯುತ್ತಂತಿ',
'ವಿದ್ಯುದ್ದೀಪ',
'ವಿದ್ಯುದ್ಭ್ರಾಂತ',
'ವಿದ್ಯುನ್ಮಾಲೆ',
'ವಿದ್ಯುಲ್ಲತೆ',
'ವಿದ್ಯುಲ್ಲೇಖೆ',
'ವಿದ್ಯೋದ್ಯೋಗ',
'ವಿದ್ರವಣ',
'ವಿದ್ರಾವಣ',
'ವಿದ್ರಾವಿತ',
'ವಿದ್ವಜ್ಜನ',
'ವಿದ್ವತ್ಪೂರ್ಣ',
'ವಿದ್ವತ್ಸಭೆ',
'ವಿದ್ವತ್ಸೇವೆ',
'ವಿದ್ವದ್ಗೋಷ್ಠಿ',
'ವಿದ್ವೇಷಣ',
'ವಿಧರ್ಷಿಣಿ',
'ವಿಧವತೆ',
'ವಿಧಾತಾರ',
'ವಿಧಾಯಕ',
'ವಿಧಾರಣ',
'ವಿಧಾವಂತ',
'ವಿಧಿಕೃತ',
'ವಿಧಿಕ್ರಿಯೆ',
'ವಿಧಿದಪ್ಪು',
'ವಿಧಿಪೂರ್ವ',
'ವಿಧಿಯಿಸು',
'ವಿಧಿರೂಪ',
'ವಿಧಿಲಿಪಿ',
'ವಿಧಿಲೀಲೆ',
'ವಿಧಿವಂತ',
'ವಿಧಿವಡು',
'ವಿಧಿವತ್ತು',
'ವಿಧಿವಶ',
'ವಿಧಿವಾಕ್ಯ',
'ವಿಧಿವಾದ',
'ವಿಧಿವಾದಿ',
'ವಿಧಿವೃತ್ತ',
'ವಿಧುಂತುದ',
'ವಿಧುಕಾಂತ',
'ವಿಧುಧರ',
'ವಿಧುಬಿಂಬ',
'ವಿಧುಮೌಲಿ',
'ವಿಧುಮೌಳಿ',
'ವಿಧುರತೆ',
'ವಿಧುರಿತ',
'ವಿಧುರಿತೆ',
'ವಿಧುವನ',
'ವಿಧುಶಿಲೆ',
'ವಿಧೂನನ',
'ವಿಧೇಯಕ',
'ವಿಧೇಯತೆ',
'ವಿಧೇಯತ್ವ',
'ವಿಧ್ಯರ್ಥಕ',
'ವಿಧ್ವಂಸಕ',
'ವಿಧ್ವಂಸನ',
'ವಿನಂತಿಸು',
'ವಿನಮನ',
'ವಿನಮಿತ',
'ವಿನಮಿತೆ',
'ವಿನಮಿಸು',
'ವಿನಯಾರ್ಥ',
'ವಿನಯಿತ',
'ವಿನಯೋಕ್ತಿ',
'ವಿನಶನ',
'ವಿನಾಯಕ',
'ವಿನಾಯತಿ',
'ವಿನಾಯಿತಿ',
'ವಿನಾಯಿಸು',
'ವಿನಾಶಕ',
'ವಿನಾಶಕಿ',
'ವಿನಾಶನ',
'ವಿನಾಶಿತ',
'ವಿನಿದ್ರಿತ',
'ವಿನಿದ್ರಿತೆ',
'ವಿನಿಮಯ',
'ವಿನಿಯತ',
'ವಿನಿಯೋಗ',
'ವಿನಿಯೋಗಿ',
'ವಿನಿರ್ಗತ',
'ವಿನಿರ್ಜರ',
'ವಿನಿರ್ಜಿತ',
'ವಿನಿರ್ಮಿತ',
'ವಿನಿರ್ಮಿಸು',
'ವಿನಿವೃತ್ತ',
'ವಿನಿವೃತ್ತಿ',
'ವಿನಿಶ್ಚಲ',
'ವಿನಿಶ್ಚಳ',
'ವಿನಿಶ್ಚಳಂ',
'ವಿನಿಶ್ಚಿತ',
'ವಿನಿಷ್ಕ್ರಾಮ',
'ವಿನಿಷ್ಠಿತ',
'ವಿನಿಹತ',
'ವಿನಿಹಿತ',
'ವಿನೀಲತೆ',
'ವಿನುತಿಸು',
'ವಿನೂತನ',
'ವಿನೇಯಾರ್ಥಿ',
'ವಿನೋದಿನಿ',
'ವಿನೋದಿಸು',
'ವಿನ್ಯಾಸಿಸು',
'ವಿಪಂಚಿಕೆ',
'ವಿಪಕ್ಷತ್ವ',
'ವಿಪಣಿಕ',
'ವಿಪತಾಕ',
'ವಿಪತ್ಕಾಲ',
'ವಿಪನ್ನತೆ',
'ವಿಪರೀತ',
'ವಿಪರ್ಯಯ',
'ವಿಪರ್ಯಸ್ತ',
'ವಿಪರ್ಯಾಸ',
'ವಿಪಶ್ಚಿತ್ತು',
'ವಿಪಾದಿಕೆ',
'ವಿಪುಲತೆ',
'ವಿಪುಲಾರ್ಯೆ',
'ವಿಪುಲಾಸ್ಯ',
'ವಿಪುಳಾರ್ಯೆ',
'ವಿಪ್ರಕರ್ಮ',
'ವಿಪ್ರಕರ್ಷ',
'ವಿಪ್ರಕಾರ',
'ವಿಪ್ರಕೀರ್ಣ',
'ವಿಪ್ರಕೃತ',
'ವಿಪ್ರಕೃಷ್ಟ',
'ವಿಪ್ರಬ್ರುವ',
'ವಿಪ್ರಯುಕ್ತೆ',
'ವಿಪ್ರಯೋಗ',
'ವಿಪ್ರಲಂಭ',
'ವಿಪ್ರಲಬ್ಧ',
'ವಿಪ್ರಲಬ್ಧೆ',
'ವಿಪ್ರಲಾಪ',
'ವಿಪ್ರಶ್ನಿಕೆ',
'ವಿಪ್ರಳಾಪ',
'ವಿಪ್ರಾವತಿ',
'ವಿಫಲತೆ',
'ವಿಬೋಧಿತ',
'ವಿಭಂಗಿತ',
'ವಿಭಂಗಿಸು',
'ವಿಭಜನ',
'ವಿಭಜನೆ',
'ವಿಭಜಿತ',
'ವಿಭಜಿಸು',
'ವಿಭಾಕರ',
'ವಿಭಾಗಿಸು',
'ವಿಭಾಡನ',
'ವಿಭಾಡಿಸು',
'ವಿಭಾತಿಕ',
'ವಿಭಾವತ್ವ',
'ವಿಭಾವನ',
'ವಿಭಾವನೆ',
'ವಿಭಾವರಿ',
'ವಿಭಾವಸು',
'ವಿಭಾವಿಸು',
'ವಿಭಾಸ್ಕರ',
'ವಿಭಿನ್ನತೆ',
'ವಿಭೀಕರ',
'ವಿಭೀತಕ',
'ವಿಭೀಷಣ',
'ವಿಭೂಷಣ',
'ವಿಭೇದಿಸು',
'ವಿಭೇಷಜ',
'ವಿಭ್ರಮಿಸು',
'ವಿಭ್ರಾಜಿತ',
'ವಿಭ್ರಾಜಿಸು',
'ವಿಮಂಡಿತ',
'ವಿಮನಸ್ಕ',
'ವಿಮರ್ದಿಸು',
'ವಿಮರ್ಶಕ',
'ವಿಮರ್ಶಕಿ',
'ವಿಮರ್ಶನ',
'ವಿಮರ್ಶಿಸು',
'ವಿಮಲತೆ',
'ವಿಮಲಿತ',
'ವಿಮಳತೆ',
'ವಿಮಳಿನ',
'ವಿಮಾದಾರ',
'ವಿಮಾನಿತ',
'ವಿಮಿಶ್ರಣ',
'ವಿಮುಂಡನ',
'ವಿಮುಕ್ತಕ',
'ವಿಮುಖತೆ',
'ವಿಮೆದಾರ',
'ವಿಮೋಚನ',
'ವಿಮೋಚನೆ',
'ವಿಮೋದನೆ',
'ವಿಮೋಹನ',
'ವಿಮೋಹಿತೆ',
'ವಿಮೋಹಿಸು',
'ವಿಯಚ್ಚಕ್ರ',
'ವಿಯಚ್ಚರ',
'ವಿಯಚ್ಚರಿ',
'ವಿಯಚ್ಚಾರಿ',
'ವಿಯತ್ತಲ',
'ವಿಯತ್ತಳ',
'ವಿಯತ್ಪಥ',
'ವಿಯತ್ಪುಷ್ಪ',
'ವಿಯದ್ಗಾಮಿ',
'ವಿಯೋಗಿನಿ',
'ವಿರಚನ',
'ವಿರಚನೆ',
'ವಿರಚಿತ',
'ವಿರಚಿಸು',
'ವಿರಜಾಜಿ',
'ವಿರಮಣ',
'ವಿರಮಿಸು',
'ವಿರಲತೆ',
'ವಿರಹಾಗ್ನಿ',
'ವಿರಹಿಣಿ',
'ವಿರಹಿತ',
'ವಿರಹಿತೆ',
'ವಿರಹಿಸು',
'ವಿರಳತೆ',
'ವಿರಳಿಸು',
'ವಿರಾಗಾರ್ಹ',
'ವಿರಾಜಿತ',
'ವಿರಾಜಿತೆ',
'ವಿರಾಜಿಸು',
'ವಿರಾಡಿಸು',
'ವಿರಾಡ್ಗೇಹ',
'ವಿರಾಡ್ರೂಪ',
'ವಿರಾಡ್ರೂಪು',
'ವಿರಾಣ್ಮನ',
'ವಿರಾಧಿಸು',
'ವಿರಿಂಚನ',
'ವಿರುದ್ದಾರ್ಥ',
'ವಿರುಪಾಕ್ಷ',
'ವಿರುವತ್ತು',
'ವಿರೂಕ್ಷಣ',
'ವಿರೂಕ್ಷಿಸು',
'ವಿರೂಪಕ',
'ವಿರೂಪಣ',
'ವಿರೂಪತೆ',
'ವಿರೂಪಾಕ್ಷ',
'ವಿರೇಕಿಸು',
'ವಿರೇಚಕ',
'ವಿರೇಚನ',
'ವಿರೇಚನೆ',
'ವಿರೋಚನ',
'ವಿರೋಧನ',
'ವಿರೋಧಿಸು',
'ವಿಲಂಘನ',
'ವಿಲಂಘಿಸು',
'ವಿಲಂಬನ',
'ವಿಲಂಬಿತ',
'ವಿಲಂಬಿಸು',
'ವಿಲಕ್ಷಣ',
'ವಿಲಕ್ಷಿತ',
'ವಿಲಗಿಗ',
'ವಿಲಜ್ಜಿತ',
'ವಿಲಪನ',
'ವಿಲಪಿಸು',
'ವಿಲಯಾಗ್ನಿ',
'ವಿಲವಿಲ',
'ವಿಲಸತ್ತು',
'ವಿಲಸನ',
'ವಿಲಸಿತ',
'ವಿಲಸಿತೆ',
'ವಿಲಸಿಸು',
'ವಿಲಾಪಿಕೆ',
'ವಿಲಾಪಿಸು',
'ವಿಲಾಯತಿ',
'ವಿಲಾಯಿತಿ',
'ವಿಲಾಸತೆ',
'ವಿಲಾಸಿಕೆ',
'ವಿಲಾಸಿತ',
'ವಿಲಾಸಿನಿ',
'ವಿಲಿಖಿತ',
'ವಿಲಿವಿಲಿ',
'ವಿಲೀನಿತ',
'ವಿಲುಲಿತ',
'ವಿಲುಳಿತ',
'ವಿಲೆವಾರಿ',
'ವಿಲೇಖನ',
'ವಿಲೇಪನ',
'ವಿಲೇಪಿಕೆ',
'ವಿಲೇಪಿತ',
'ವಿಲೇಪಿಸು',
'ವಿಲೇವಾಟ',
'ವಿಲೇವಾರಿ',
'ವಿಲೋಕನ',
'ವಿಲೋಕಿತ',
'ವಿಲೋಚನ',
'ವಿಲೋಪನ',
'ವಿಲೋಭನ',
'ವಿಲೋಲತೆ',
'ವಿಲೋಲುಪ',
'ವಿಲೋಹಿತ',
'ವಿವಂಚನೆ',
'ವಿವಂಚಿತ',
'ವಿವಕ್ಷಿತ',
'ವಿವಕ್ಷಿಸು',
'ವಿವರಣ',
'ವಿವರಣೆ',
'ವಿವರಿಸು',
'ವಿವರ್ಜಿತ',
'ವಿವರ್ಣತೆ',
'ವಿವರ್ಣಿಸು',
'ವಿವರ್ತನ',
'ವಿವರ್ತಿತ',
'ವಿವರ್ಧನ',
'ವಿವರ್ಧನೆ',
'ವಿವರ್ಧಿಸು',
'ವಿವಶತೆ',
'ವಿವಶಿಸು',
'ವಿವಸ್ವಂತ',
'ವಿವಸ್ವತ್ತು',
'ವಿವಹನ',
'ವಿವಳಿತ',
'ವಿವಾದಿಸು',
'ವಿವಾರಿಕ',
'ವಿವಾಹಿತ',
'ವಿವಾಹಿತೆ',
'ವಿವಿದಿಷೆ',
'ವಿವಿಧತೆ',
'ವಿವೃತೋಕ್ತಿ',
'ವಿವೇಕಜ್ಞ',
'ವಿವೇಕಜ್ಞೆ',
'ವಿವೇಕಿಸು',
'ವಿವೇಚನೆ',
'ವಿವೇಚಿಸು',
'ವಿಶಂಕಟ',
'ವಿಶದತೆ',
'ವಿಶದಿಮ',
'ವಿಶರಣ',
'ವಿಶರಾರು',
'ವಿಶಸನ',
'ವಿಶಾರದ',
'ವಿಶಾರದೆ',
'ವಿಶಾಲತೆ',
'ವಿಶಾಲಾಕ್ಷ',
'ವಿಶಾಲಾಕ್ಷಿ',
'ವಿಶಾಲಿತ',
'ವಿಶಾಳಿತ',
'ವಿಶುದ್ಧತೆ',
'ವಿಶೇಷಕ',
'ವಿಶೇಷಜ್ಞ',
'ವಿಶೇಷಜ್ಞೆ',
'ವಿಶೇಷಣ',
'ವಿಶೇಷತ್ವ',
'ವಿಶೇಷಾರ್ಥ',
'ವಿಶೇಷಿಸು',
'ವಿಶೇಷೋಕ್ತಿ',
'ವಿಶ್ರಮಣ',
'ವಿಶ್ರಮಿಸು',
'ವಿಶ್ರಾಣನ',
'ವಿಶ್ಲೇಷಣ',
'ವಿಶ್ಲೇಷಣೆ',
'ವಿಶ್ಲೇಷಿಸು',
'ವಿಶ್ವಂಭರ',
'ವಿಶ್ವಂಭರೆ',
'ವಿಶ್ವಕದ್ರು',
'ವಿಶ್ವಕರ್ತ',
'ವಿಶ್ವಕರ್ತೃ',
'ವಿಶ್ವಕರ್ಮ',
'ವಿಶ್ವಕೃತ್ತು',
'ವಿಶ್ವಕೋಶ',
'ವಿಶ್ವಗೋದ',
'ವಿಶ್ವಚಿಹ್ನ',
'ವಿಶ್ವದಾತ',
'ವಿಶ್ವದೃಷ್ಟಿ',
'ವಿಶ್ವನಾಥ',
'ವಿಶ್ವಭರ್ತ',
'ವಿಶ್ವರೂಪ',
'ವಿಶ್ವಸಿಸು',
'ವಿಶ್ವಸೃಕ್ಕು',
'ವಿಶ್ವಸ್ತಿಕೆ',
'ವಿಶ್ವಹರ್ತ',
'ವಿಶ್ವಾಧಾರ',
'ವಿಶ್ವಾಮಿತ್ರ',
'ವಿಶ್ವಾವಸು',
'ವಿಶ್ವಾಸಿಗ',
'ವಿಶ್ವಾಸಿನಿ',
'ವಿಶ್ವಾಸಿಸು',
'ವಿಶ್ವೇಕ್ಷಣ',
'ವಿಶ್ವೇದೇವ',
'ವಿಶ್ವೇಶ್ವರ',
'ವಿಶ್ವೋದರ',
'ವಿಶ್ವೋದ್ಧತಿ',
'ವಿಷಕಂಠ',
'ವಿಷಕಡ್ಡಿ',
'ವಿಷಕನ್ಯೆ',
'ವಿಷಕಲ್ಲು',
'ವಿಷಕೀಟೆ',
'ವಿಷಕ್ರಿಮಿ',
'ವಿಷಖದ್ದಿ',
'ವಿಷಗಳ',
'ವಿಷಗಾಳಿ',
'ವಿಷಗ್ರಂಥಿ',
'ವಿಷಗ್ರೀವ',
'ವಿಷಚಕ್ರ',
'ವಿಷಜಂತು',
'ವಿಷಜಿತ',
'ವಿಷಜ್ವರ',
'ವಿಷಣ್ಣತೆ',
'ವಿಷತೀಟೆ',
'ವಿಷದಿವ್ಯ',
'ವಿಷಧರ',
'ವಿಷನಾಗ',
'ವಿಷನಿಧಿ',
'ವಿಷನೇತ್ರ',
'ವಿಷಪಕ್ಷಿ',
'ವಿಷಪುಚ್ಚ',
'ವಿಷಪುಚ್ಛ',
'ವಿಷಫಲ',
'ವಿಷಬೀಜ',
'ವಿಷಬೊದ್ದಿ',
'ವಿಷಭೇದ',
'ವಿಷಮಗೋ',
'ವಿಷಮತೆ',
'ವಿಷಮತ್ವ',
'ವಿಷಮರ್ತು',
'ವಿಷಮಾಕ್ಷ',
'ವಿಷಮಾಗ್ನಿ',
'ವಿಷಮಾಸ್ತ್ರ',
'ವಿಷಮಿತ',
'ವಿಷಮಿಸು',
'ವಿಷಮುಷ್ಟಿ',
'ವಿಷಮೆಕ್ಕೆ',
'ವಿಷಯಕ',
'ವಿಷರೋಗ',
'ವಿಷಲತೆ',
'ವಿಷವಕ್ತ್ರ',
'ವಿಷವಲ್ಲಿ',
'ವಿಷವಹ್ನಿ',
'ವಿಷವಾಕ್ಕು',
'ವಿಷವಿಕ್ಕು',
'ವಿಷವೂಡು',
'ವಿಷವೃಕ್ಷ',
'ವಿಷವೈದ್ಯ',
'ವಿಷಸರ್ಪ',
'ವಿಷಹರ',
'ವಿಷಹಾರಿ',
'ವಿಷಹೀರೆ',
'ವಿಷಾಂಗನೆ',
'ವಿಷಾದಾಂತ',
'ವಿಷಾದಿಸು',
'ವಿಷಾನಲ',
'ವಿಷಾನಿಲ',
'ವಿಷಾಹ್ವಯ',
'ವಿಷುವತ್ತು',
'ವಿಷೂಚಿಕ',
'ವಿಷೂಚಿಕೆ',
'ವಿಷ್ಕಂಭಕ',
'ವಿಷ್ಕಂಭಿತ',
'ವಿಷ್ಟಂಭಿಸು',
'ವಿಷ್ಟಕಂದ',
'ವಿಷ್ಠಾಕೂಪ',
'ವಿಷ್ಣುಕಂದ',
'ವಿಷ್ಣುಕಾಂತ',
'ವಿಷ್ಣುಕ್ರಾಂತ',
'ವಿಷ್ಣುಕ್ರಾಂತಿ',
'ವಿಷ್ಣುಗಂಟಿ',
'ವಿಷ್ಣುಗಣ',
'ವಿಷ್ಣುಗೃಹ',
'ವಿಷ್ಣುಚಕ್ರ',
'ವಿಷ್ಣುಪದ',
'ವಿಷ್ಣುಪದಿ',
'ವಿಷ್ಣುಪಾದ',
'ವಿಷ್ಣುಮಾಯೆ',
'ವಿಷ್ಣುರಥ',
'ವಿಷ್ಣುಸ್ಮೃತಿ',
'ವಿಷ್ವಕ್ಷೇನ',
'ವಿಷ್ವಕ್ಸೇನೆ',
'ವಿಸಂಕಟ',
'ವಿಸಂಗತ',
'ವಿಸಂಗತಿ',
'ವಿಸಂಚಿಸು',
'ವಿಸಂಧಿಕ',
'ವಿಸಂಪರ್ಕ',
'ವಿಸಂವಾದ',
'ವಿಸದೃಶ',
'ವಿಸರಣ',
'ವಿಸರತ್ತು',
'ವಿಸರಿಸು',
'ವಿಸರುಹ',
'ವಿಸರ್ಜನ',
'ವಿಸರ್ಜನೆ',
'ವಿಸರ್ಜಿಸು',
'ವಿಸರ್ಪಕ',
'ವಿಸರ್ಪಣ',
'ವಿಸವಂತ',
'ವಿಸಸನ',
'ವಿಸುಮಯ',
'ವಿಸೃಷ್ಟಾರ್ಥ',
'ವಿಸ್ತರಣ',
'ವಿಸ್ತರಣೆ',
'ವಿಸ್ತರಿಗೆ',
'ವಿಸ್ತರಿತ',
'ವಿಸ್ತರಿಸು',
'ವಿಸ್ತಾರಕ',
'ವಿಸ್ತಾರಣ',
'ವಿಸ್ತಾರಣೆ',
'ವಿಸ್ತಾರಿತ',
'ವಿಸ್ತಾರಿಸು',
'ವಿಸ್ಫಾರಿತ',
'ವಿಸ್ಫಾರಿಸು',
'ವಿಸ್ಫಾಲನೆ',
'ವಿಸ್ಫಾಳನ',
'ವಿಸ್ಫುರಣ',
'ವಿಸ್ಫುರಣೆ',
'ವಿಸ್ಫುರತ್ತು',
'ವಿಸ್ಫುರಿತ',
'ವಿಸ್ಫುರಿಸು',
'ವಿಸ್ಫುಲಿಂಗ',
'ವಿಸ್ಫೂರ್ಜಿತ',
'ವಿಸ್ಫೋಟಿಸು',
'ವಿಸ್ಮರಣ',
'ವಿಸ್ಮರಣೆ',
'ವಿಹಂಗಪ',
'ವಿಹಂಗಮ',
'ವಿಹಂಗಿಕೆ',
'ವಿಹಗಪ',
'ವಿಹಗೇಂದ್ರ',
'ವಿಹರಣ',
'ವಿಹರಿಸು',
'ವಿಹಸಿತ',
'ವಿಹಾಪಿತ',
'ವಿಹಾಯನ',
'ವಿಹಾಯಸ',
'ವಿಹಾರಿಣಿ',
'ವಿಹಾರಿಸು',
'ವಿಹೇಟನ',
'ವಿಹ್ವಲತೆ',
'ವಿಹ್ವಲಿತ',
'ವಿಹ್ವಲಿತೆ',
'ವಿಹ್ವಲಿಸು',
'ವಿಹ್ವಳತೆ',
'ವಿಹ್ವಳಿತ',
'ವಿಹ್ವಳಿತೆ',
'ವಿಳಂಬಿಕ',
'ವಿಳಂಬಿತ',
'ವಿಳಂಬಿಸು',
'ವಿಳಸತ್ತು',
'ವಿಳಸನ',
'ವಿಳಸಿತ',
'ವಿಳಾಪಿಸು',
'ವಿಳಾಸತೆ',
'ವಿಳಾಸಿನಿ',
'ವಿಳಿಖಿತ',
'ವಿಳುಕಾಡು',
'ವಿಳೇಪಿತ',
'ವಿಳೋಕನ',
'ವಿಳೋಚನ',
'ವಿಳ್ಳೇದೆಲೆ',
'ವಿಳ್ಳೇಯೆಲೆ',
'ವೀಚುಗೆಡು',
'ವೀಚುವೋಗು',
'ವಿಣಾಕ್ವಣ',
'ವಿಣಾದಂಡ',
'ವೀಣಾಧ್ವನಿ',
'ವೀಣಾನಾದ',
'ವಿಣಾಪಾಣಿ',
'ವೀಣೆಗಾರ್ತಿ',
'ವೀಣೆಗಾಱ',
'ವೀತಭಯ',
'ವೀತಮಲ',
'ವೀತರಾಗ',
'ವೀತರಾಗಿ',
'ವೀತಾಂಕುಶ',
'ವೀತಿಹೋತ್ರ',
'ವೀಥಿಗೊಳ್',
'ವೀಥಿಮಾರ್ಗ',
'ವೀರಂಬೆಱು',
'ವೀರಕಚ್ಚೆ',
'ವೀರಕಾರ್ಯ',
'ವೀರಕಾವ್ಯ',
'ವೀರಕಾಶೆ',
'ವೀರಗಚ್ಚೆ',
'ವೀರಗಲ್ಲು',
'ವೀರಗಾಥೆ',
'ವೀರಗಾಲು',
'ವೀರಗಾಸೆ',
'ವೀರಚಕ್ರ',
'ವೀರಡೌಡೆ',
'ವೀರತನ',
'ವೀರತರ',
'ವೀರತರು',
'ವೀರತಿಕೆ',
'ವೀರಧರ್ಮ',
'ವೀರಪಟ್ಟ',
'ವೀರಪಟ್ಟೆ',
'ವೀರಪಣ',
'ವೀರಪಳಿ',
'ವೀರಪಾಣ',
'ವೀರಬಲ',
'ವೀರಭದ್ರ',
'ವೀರಮಂಡಿ',
'ವೀರಮುದ್ರೆ',
'ವೀರಯುಗ',
'ವೀರರಸ',
'ವೀರವಂದ',
'ವೀರವಟ್ಟ',
'ವೀರವಱೆ',
'ವೀರವೃತ್ತ',
'ವೀರವೃತ್ತಿ',
'ವೀರಶೈವ',
'ವೀರಸಗ್ಗ',
'ವೀರಸೇತು',
'ವೀರಸ್ವರ್ಗ',
'ವೀರಸ್ವಾಮಿ',
'ವೀರಾಂಗದ',
'ವೀರಾಯತ',
'ವೀರಾವೇಶ',
'ವೀರಾಸನ',
'ವೀರೇಶ್ವರ',
'ವೀರೋಚಿತ',
'ವೀರ್ಯವತಿ',
'ವೀರ್ಯವತ್ತು',
'ವೀರ್ಯವೃದ್ಧಿ',
'ವೀಸಪಾಲು',
'ವೀಳೆದೆಲೆ',
'ವೀಳ್ಯದೆಲೆ',
'ವೀಳ್ಯೆದೆಲೆ',
'ವೃಂತಫಲ',
'ವೃಂದಗಾನ',
'ವೃಂದಾರಕ',
'ವೃಂದಾವನ',
'ವೃಕತಿಕ್ತ',
'ವೃಕಧೂಪ',
'ವೃಕಪತ್ರಿ',
'ವೃಕೋದರ',
'ವೃಕ್ಷಧೂಪ',
'ವೃಕ್ಷಭೇದಿ',
'ವೃಕ್ಷಮೂಲ',
'ವೃಕ್ಷರುಹ',
'ವೃಕ್ಷರುಹೆ',
'ವೃಕ್ಷವೀರ್ಯ',
'ವೃಕ್ಷಾದನ',
'ವೃಕ್ಷಾದನಿ',
'ವೃಕ್ಷಾರೋಹೆ',
'ವೃಕ್ಷಾಸನ',
'ವೃಕ್ಷೋತ್ಪಲ',
'ವೃಕ್ಷೋತ್ಪಳ',
'ವೃತಗೋಮ',
'ವೃತರೇಫ',
'ವೃತ್ತಗಂಧಿ',
'ವೃತ್ತತರ',
'ವೃತ್ತನಾಮ',
'ವೃತ್ತಪತ್ರ',
'ವೃತ್ತಫಲ',
'ವೃತ್ತರೇಖೆ',
'ವೃತ್ತವೆಱು',
'ವೃತ್ತಾಕರ',
'ವೃತ್ತಿಕಾರ',
'ವೃತ್ತಿಕಾಱ',
'ವೃತ್ತಿಕ್ಷೇತ್ರ',
'ವೃತ್ತಿಗ್ರಂಥ',
'ವೃತ್ತಿಜ್ಞಾನ',
'ವೃತ್ತಿತಂತ್ರ',
'ವೃತ್ತಿದಾರ',
'ವೃತ್ತಿಲಾಭ',
'ವೃತ್ತಿಲೋಪ',
'ವೃತ್ತಿವಂತ',
'ವೃಥಾಕೃತ',
'ವೃಥಾಳಾಪ',
'ವೃಥೆಗಳೆ',
'ವೃಥೋತ್ಸಾಹಿ',
'ವೃದ್ಧರಾಜ್ಯ',
'ವೃದ್ಧಶ್ರವ',
'ವೃದ್ಧಸೇವೆ',
'ವೃದ್ಧಾಚಾರ',
'ವೃದ್ಧಾಶ್ರಮ',
'ವೃದ್ಧಿಂಗತ',
'ವೃದ್ಧಿಸಂಧಿ',
'ವೃದ್ಧ್ಯಾಜೀವ',
'ವೃದ್ಧ್ಯಾಜೀವಿ',
'ವೃಶ್ಚಿಕಾಳಿ',
'ವೃಷಕೋಷ್ಠ',
'ವೃಷಗಂತಿ',
'ವೃಷದಂಶ',
'ವೃಷದ್ವಿಪ',
'ವೃಷಧ್ವಜ',
'ವೃಷನಾಥ',
'ವೃಷಪಾದಿ',
'ವೃಷಪಾಳಿ',
'ವೃಷಭಕ',
'ವೃಷಭಾಕ್ಷಿ',
'ವೃಷಭಾದ್ರಿ',
'ವೃಷಭಾಯ',
'ವೃಷಮಾರ್ಗ',
'ವೃಷರಥ',
'ವೃಷರಾಶಿ',
'ವೃಷಸತ್ವ',
'ವೃಷಸ್ಯಂತಿ',
'ವೃಷಾಕಪಿ',
'ವೃಷಾವಾಸ',
'ವೃಷಾಹವ',
'ವೃಷ್ಣಿಕುಲ',
'ವೃಷ್ಯಗಂಧ',
'ವೃಷ್ಯರಸ',
'ವೆಂಕಟಾದ್ರಿ',
'ವೆಂಕಟೇಶ',
'ವೆಂಟಣಿಸು',
'ವೆಂಠಣಿಸು',
'ವೆಗಟಿಸು',
'ವೆಗ್ಗಳಿಕೆ',
'ವೆಗ್ಗಳಿಸು',
'ವೆಗ್ಗಳೆಯ',
'ವೆಜ್ಜಗೊಡು',
'ವೆಟ್ಟಿವೇರು',
'ವೆತಿರೇಕ',
'ವೆಲೆಕೊಳ್',
'ವೆಲ್ಲವಾಲೆ',
'ವೆವಹರ',
'ವೆವಹಾರ',
'ವೇಗಗತಿ',
'ವೇಗಗಾಮಿ',
'ವೇಗಗೂಡು',
'ವೇಗದೂರ',
'ವೇಗಾಯಿಲ',
'ವೇಡಯಿಸು',
'ವೇಡೆಗೊಳ್',
'ವೇಣೀಬಂಧ',
'ವೇಣುಕಾಂಡ',
'ವೇಣುಗಾನ',
'ವೇಣುಗೀತ',
'ವೇಣುದಂಡ',
'ವೇಣುನಾದ',
'ವೇಣುಪತ್ರ',
'ವೇಣುಪತ್ರಿ',
'ವೇಣುಬೀಜ',
'ವೇತಸೇಯ',
'ವೇತಾಳಕ',
'ವೇತ್ರಕೀಯ',
'ವೇತ್ರಗೀತ',
'ವೇತ್ರದಂಡ',
'ವೇತ್ರಧರ',
'ವೇತ್ರನಾಳಿ',
'ವೇತ್ರಲತೆ',
'ವೇತ್ರವತಿ',
'ವೇತ್ರಹಸ್ತ',
'ವೇತ್ರಹಸ್ತೆ',
'ವೇತ್ರಾಸನ',
'ವೇದಗರ್ಭ',
'ವೇದಘೋಷ',
'ವೇದನಿಂದೆ',
'ವೇದನಿಧಿ',
'ವೇದನೀಯ',
'ವೇದಬಾಹ್ಯ',
'ವೇದಭಾಷ್ಯ',
'ವೇದಮಂತ್ರ',
'ವೇದಮಾತೆ',
'ವೇದಮೂರ್ತಿ',
'ವೇದವಾಕ್ಯ',
'ವೇದವಾದಿ',
'ವೇದವಿತ್ತು',
'ವೇದವಿದ',
'ವೇದವೇತ್ತ',
'ವೇದವೇದ್ಯ',
'ವೇದವ್ಯಾಸ',
'ವೇದಸಿದ್ಧ',
'ವೇದಸೂಕ್ತ',
'ವೇದಹೀನ',
'ವೇದಾತೀತ',
'ವೇದಾಧ್ಯಾಯಿ',
'ವೇದಾಭ್ಯಾಸ',
'ವೇದಿತಜ್ಞ',
'ವೇದೀಸ್ಥಳ',
'ವೇದೋದ್ಧಾರ',
'ವೇಧನಿಕೆ',
'ವೇಪಮಾನ',
'ವೇಮದಂಡ',
'ವೇರಡಿಗ',
'ವೇಲಾಯೋಗ',
'ವೇಲಾವನ',
'ವೇಶ್ಯಾಂಗನೆ',
'ವೇಶ್ಯಾವಾಟ',
'ವೇಶ್ಯಾವಾಟಿ',
'ವೇಶ್ಯಾವೃತ್ತಿ',
'ವೇಷಕಟ್ಟು',
'ವೇಷಧಾರಿ',
'ವೇಷಾಂತರ',
'ವೇಷಾಡಂಭು',
'ವೇಸವಾರ',
'ವೇಳಕಾಱ',
'ವೇಳಾಚರ',
'ವೇಳಾಪಟ್ಟಿ',
'ವೇಳಾಯಿತ',
'ವೇಳಾವನ',
'ವೇಳಾವಳಿ',
'ವೇಳಾವುಳ',
'ವೇಳೆಕಳೆ',
'ವೇಳೆಕಾಱ',
'ವೇಳೆಗಳೆ',
'ವೇಳೆಗಾಯ್',
'ವೇಳೆಗೊಳ್',
'ವೇಳೆಗೊಳ್ಳು',
'ವೇಳೆತನ',
'ವೇಳೆಯಾಳು',
'ವೇಳೆವಾಳಿ',
'ವೇಳೆವಾೞಿ',
'ವೈಕಂಕತ',
'ವೈಕಕ್ಷಕ',
'ವೈಕಟಿಕ',
'ವೈಕರ್ತನ',
'ವೈಕಲ್ಪಿಕ',
'ವೈಕಲ್ಪಿತ',
'ವೈಕಾರಕ',
'ವೈಕಾರಿಕ',
'ವೈಕುಂಠಿಕೆ',
'ವೈಕುರ್ವಣ',
'ವೈಕ್ರಿಯಕ',
'ವೈಕ್ರಿಯಿಕ',
'ವೈಕ್ರೀಯಕ',
'ವೈಖಾನಸ',
'ವೈಚಾರಿಕ',
'ವೈಜನನ',
'ವೈಜನಾಥ',
'ವೈಜಯಂತ',
'ವೈಜಯಂತಿ',
'ವೈಜ್ಞಾನಿಕ',
'ವೈಡೂರಿಯ',
'ವೈಣವಿಕ',
'ವೈತಂಡಿಕ',
'ವೈತಂಸಿಕ',
'ವೈತನಿಕ',
'ವೈತರಣಿ',
'ವೈತಾಲಿಕ',
'ವೈತಾಲಿಕೆ',
'ವೈತಾಳಿಕ',
'ವೈತಾಳಿಕೆ',
'ವೈದಂಡಿಕ',
'ವೈದಿಕೋಕ್ತಿ',
'ವೈದೇಶಿಗ',
'ವೈದೇಹಕ',
'ವೈದ್ಯಕಾಣಿ',
'ವೈದ್ಯಕಾರ',
'ವೈದ್ಯಕೀಯ',
'ವೈದ್ಯಗಾರ',
'ವೈದ್ಯನಾಥ',
'ವೈದ್ಯಭಾಗ',
'ವೈದ್ಯಮಾತೃ',
'ವೈದ್ಯಮಾತೆ',
'ವೈದ್ಯರಾಜ',
'ವೈದ್ಯವಿದ',
'ವೈದ್ಯಶಾಲೆ',
'ವೈದ್ಯಶಾಸ್ತ್ರ',
'ವೈದ್ಯಾಲಯ',
'ವೈದ್ಯುತಾಗ್ನಿ',
'ವೈನಕಾಳು',
'ವೈನತೇಯ',
'ವೈನಯಿಕ',
'ವೈನಾಯಕ',
'ವೈನಾಶಿಕ',
'ವೈನೋದಿಕ',
'ವೈಪರೀತ್ಯ',
'ವೈಭಾತಿಕ',
'ವೈಭಾಷಿಕ',
'ವೈಮನಸ್ಯ',
'ವೈಮನಸ್ಸು',
'ವೈಮಾನಿಕ',
'ವೈಮಾಳಿಗೆ',
'ವೈಯಕ್ತಿಕ',
'ವೈಯಾಪೃತ್ಯ',
'ವೈಯಾಳಿಗೈ',
'ವೈರಂಗಿಕ',
'ವೈರಂಭಕ',
'ವೈರಂಭಣ',
'ವೈರಬಂಧ',
'ವೈರಭಾವ',
'ವೈರಮುಡಿ',
'ವೈರವಡೆ',
'ವೈರಶುದ್ಧಿ',
'ವೈರಿಂಚಾಸ್ತ್ರ',
'ವೈರಿದೂತ',
'ವೈರಿಪದ',
'ವೈರಿಬಲ',
'ವೈರೋಚನ',
'ವೈರೋಚನಿ',
'ವೈಲಕ್ಷಣ',
'ವೈಲಕ್ಷಣ್ಯ',
'ವೈವಧಿಕ',
'ವೈವಸ್ವತ',
'ವೈವಾಹಗೈ',
'ವೈವಾಹಿಕ',
'ವೈವಿಧ್ಯತೆ',
'ವೈಶಿಷ್ಟಿಕ',
'ವೈಶೇಷಿಕ',
'ವೈಶ್ಯದತ್ತಿ',
'ವೈಶ್ರವಣ',
'ವೈಶ್ವಂಭರ',
'ವೈಶ್ವದೇವ',
'ವೈಶ್ವದೇವಿ',
'ವೈಶ್ವರೀಯ',
'ವೈಶ್ವಾನರ',
'ವೈಷಯಿಕ',
'ವೈಷ್ಣವಾಸ್ತ್ರ',
'ವೈಸಣಿಗೆ',
'ವೈಸರಾಯ',
'ವೈಸರಾಯಿ',
'ವೈಸಾರಿಣ',
'ವೈಸ್ರಸಿಕ',
'ವೈಹಾಯಸ',
'ವೈಹಾಸಿಕ',
'ವೈಹಾಳಿಗ',
'ವೊಡ್ಡವಾರ',
'ವ್ಯಂಗ್ಯಚಿತ್ರ',
'ವ್ಯಂಗ್ಯನುಡಿ',
'ವ್ಯಂಜನಾಂಗ',
'ವ್ಯಂಜನಾಂತ',
'ವ್ಯಂತರಿಗ',
'ವ್ಯಕ್ತಪಡು',
'ವ್ಯಕ್ತಿಗತ',
'ವ್ಯಕ್ತಿನಿಷ್ಠ',
'ವ್ಯಕ್ತಿಪೂಜೆ',
'ವ್ಯಕ್ತಿವಾದ',
'ವ್ಯಗ್ರಚಿತ್ತ',
'ವ್ಯತಿಕರ',
'ವ್ಯತಿಕೀರ್ಣ',
'ವ್ಯತಿಕ್ರಮ',
'ವ್ಯತಿಪಾತ',
'ವ್ಯತಿರಿಕ್ತ',
'ವ್ಯತಿರೇಕ',
'ವ್ಯತಿಹಾರ',
'ವ್ಯತೀಪಾತ',
'ವ್ಯಥೆಗೊಳ್ಳು',
'ವ್ಯಪಗತ',
'ವ್ಯಪದೇಶ',
'ವ್ಯಪನೀತ',
'ವ್ಯಪಸರ್ಗ',
'ವ್ಯಪಾಕೃತ',
'ವ್ಯಪೇತತೆ',
'ವ್ಯಭಿಚಾರ',
'ವ್ಯಭಿಚಾರಿ',
'ವ್ಯಯಗೃಹ',
'ವ್ಯರ್ಥಾಲಾಪ',
'ವ್ಯರ್ಥಾಳಾಪ',
'ವ್ಯವಚ್ಛೇದ',
'ವ್ಯವಧಾನ',
'ವ್ಯವಸಾಯ',
'ವ್ಯವಸಾಯಿ',
'ವ್ಯವಸ್ಥಿತ',
'ವ್ಯವಹಾರ',
'ವ್ಯವಹಾರಿ',
'ವ್ಯವಹಾರ್ಯ',
'ವ್ಯವಹಿತ',
'ವ್ಯವಹೃತಿ',
'ವ್ಯಸನಾಳಿ',
'ವ್ಯಸ್ತಪದ',
'ವ್ಯಾಕರಣ',
'ವ್ಯಾಕರಣಿ',
'ವ್ಯಾಕೀರ್ಣಿಸು',
'ವ್ಯಾಕುಂಚಿತ',
'ವ್ಯಾಕುಲತೆ',
'ವ್ಯಾಕುಲಿತ',
'ವ್ಯಾಕುಲಿತೆ',
'ವ್ಯಾಕುಳತೆ',
'ವ್ಯಾಕುಳಿತ',
'ವ್ಯಾಕುಳಿತೆ',
'ವ್ಯಾಕೃತಿಸು',
'ವ್ಯಾಕೋಚನ',
'ವ್ಯಾಖ್ಯಾಕಾರ',
'ವ್ಯಾಖ್ಯಾನಿಸು',
'ವ್ಯಾಘ್ರನಖ',
'ವ್ಯಾಘ್ರಪಾದ',
'ವ್ಯಾಘ್ರಪುಚ್ಛ',
'ವ್ಯಾಘ್ರಮುಖ',
'ವ್ಯಾಘ್ರವಕ್ತ್ರ',
'ವ್ಯಾಘ್ರಾಜಿನ',
'ವ್ಯಾಘ್ರಾಯುಧ',
'ವ್ಯಾಘ್ರಾಸನ',
'ವ್ಯಾಜನಿಂದೆ',
'ವ್ಯಾಜಸ್ತುತಿ',
'ವ್ಯಾಜಾಂತರ',
'ವ್ಯಾಜೃಂಭಣ',
'ವ್ಯಾಧಿಗ್ರಸ್ತ',
'ವ್ಯಾಧಿಗ್ರಸ್ತೆ',
'ವ್ಯಾಧಿಘಾತ',
'ವ್ಯಾಪಕತೆ',
'ವ್ಯಾಪಕತ್ವ',
'ವ್ಯಾಪರಿಸು',
'ವ್ಯಾಪಾದಕ',
'ವ್ಯಾಪಾದನ',
'ವ್ಯಾಪಾದಿತ',
'ವ್ಯಾಪರಕ',
'ವ್ಯಾಪಾರಸ್ಥ',
'ವ್ಯಾಪಾರಿಗ',
'ವ್ಯಾಪಾರಿಸು',
'ವ್ಯಾಭಾಸಿತ',
'ವ್ಯಾಮೋಹಿತ',
'ವ್ಯಾಮೋಹಿತೆ',
'ವ್ಯಾಲಗಂಧಿ',
'ವ್ಯಾಲಾಯುಧ',
'ವ್ಯಾವರ್ಣನೆ',
'ವ್ಯಾವರ್ಣಿತ',
'ವ್ಯಾವರ್ಣಿಸು',
'ವ್ಯಾವರ್ತಕ',
'ವ್ಯಾವರ್ತಿತ',
'ವ್ಯಾಸಕೂಟ',
'ವ್ಯಾಸಧ್ವಜ',
'ವ್ಯಾಸಪೀಠ',
'ವ್ಯಾಸರೇಖೆ',
'ವ್ಯಾಸಸ್ಮೃತಿ',
'ವ್ಯಾಸಹಸ್ತ',
'ವ್ಯಾಳಗಜ',
'ವ್ಯಾಳನಖ',
'ವ್ಯಾಳಾಯುಧ',
'ವ್ಯೂಹಚ್ಛೇದ',
'ವ್ಯೂಹಬಂಧ',
'ವ್ಯೂಹೋತ್ಕರ',
'ವ್ಯೋಮಕೇಶ',
'ವ್ಯೋಮಚರ',
'ವ್ಯೋಮಯಾನ',
'ವ್ಯೋಮಾಪಗೆ',
'ವ್ರಜಭಾಷೆ',
'ವ್ರಣಜಾಡ್ಯ',
'ವ್ರಣರೋಗ',
'ವ್ರಣಾಶನ',
'ವ್ರತಕೊಳ್',
'ವ್ರತಗೆಡು',
'ವ್ರತಗೇಡಿ',
'ವ್ರತಚರ್ಯೆ',
'ವ್ರತಭಂಗ',
'ವ್ರತಭ್ರಷ್ಟ',
'ವ್ರತಭ್ರಷ್ಟೆ',
'ವ್ರತಹಾನಿ',
'ವ್ರತಹೀನ',
'ವ್ರತಹೀನೆ',
'ವ್ರತಾಚಾರ',
'ವ್ರತಿಪತಿ',
'ವ್ರಹ್ಮಾದೇಯ',
'ವ್ರೀಡಾಕರ',
'ಶಂಕತಾಳ',
'ಶಂಕಹುಲ್ಲೆ',
'ಶಂಕಾಕಾರಿ',
'ಶಂಕಾಕುಲ',
'ಶಂಕಾಕುಲೆ',
'ಶಂಕಾವಹ',
'ಶಂಕುಫಲ',
'ಶಂಕುಯಂತ್ರ',
'ಶಂಕೆಪಡು',
'ಶಂಖಧ್ವಾನ',
'ಶಂಖನಖ',
'ಶಂಖಪಾಯ',
'ಶಂಖಪಾಲ',
'ಶಂಖಪಾಳ',
'ಶಂಖಪುಷ್ಪ',
'ಶಂಖಪುಷ್ಪಿ',
'ಶಂಖಭಸ್ಮ',
'ಶಂಖಮುದ್ರೆ',
'ಶಂಖರೇಖೆ',
'ಶಂಖಲಿಪಿ',
'ಶಂಖವಾದ್ಯ',
'ಶಂಖೋದರ',
'ಶಂಬಪಾಣಿ',
'ಶಂಬಮಣಿ',
'ಶಂಬರಧಿ',
'ಶಂಬವತಿ',
'ಶಂಬಾಕೃತ',
'ಶಕಟಾಸ್ಯ',
'ಶಕವರ್ಷ',
'ಶಕುನಿಗ',
'ಶಕ್ತಿಗುಂದು',
'ಶಕ್ತಿಗೆಡು',
'ಶಕ್ತಿತ್ರಯ',
'ಶಕ್ತಿಧರ',
'ಶಕ್ತಿಪಾಣಿ',
'ಶಕ್ತಿಯೋಗ',
'ಶಕ್ತಿವಂತ',
'ಶಕ್ತಿಶಾಲಿ',
'ಶಕ್ತಿಹೀನ',
'ಶಕ್ತಿಹೀನೆ',
'ಶಕ್ತ್ಯಾಯುಧ',
'ಶಕ್ರಗೋಪ',
'ಶಕ್ರಚಾಪ',
'ಶಕ್ರಧನು',
'ಶಕ್ರಪಾದ',
'ಶಕ್ರಪುಷ್ಟಿ',
'ಶಟಗೋಪ',
'ಶಟುಕರ್ಮ',
'ಶಠಕೋಪ',
'ಶಠಗೋಪ',
'ಶಠತನ',
'ಶಡಗೋಪ',
'ಶಣಸೂತ್ರ',
'ಶತಕುಂಭ',
'ಶತಕೋಟಿ',
'ಶತಕ್ರತು',
'ಶತಖಂಡ',
'ಶತಚೂರ್ಣ',
'ಶತಚ್ಛಿದ್ರ',
'ಶತತಂತ್ರಿ',
'ಶತತಾರೆ',
'ಶತದಲ',
'ಶತದಳ',
'ಶತಧರ್ಮ',
'ಶತಧಾರ',
'ಶತಧೃತಿ',
'ಶತನಾಮೆ',
'ಶತಪತ್ರ',
'ಶತಪಥ',
'ಶತಪದ',
'ಶತಪದಿ',
'ಶತಪರ್ವ',
'ಶತಪುಷ್ಪಿ',
'ಶತಪುಷ್ಪೆ',
'ಶತಪ್ರಾಸ',
'ಶತಭಿಷೆ',
'ಶತಮಖ',
'ಶತಮನ್ಯು',
'ಶತಮಾನ',
'ಶತಮೂರ್ಖ',
'ಶತಮೂಲಿ',
'ಶತಮೊಂಡ',
'ಶತಮೊಂಡಿ',
'ಶತಯಜ್ಞ',
'ಶತರಂಜ',
'ಶತರಂಜಿ',
'ಶತವೀರ್ಯೆ',
'ಶತವೃದ್ಧ',
'ಶತವೇಧಿ',
'ಶತಸ್ಸಿದ್ಧ',
'ಶತಹ್ರದ',
'ಶತಹ್ರದೆ',
'ಶತಾಧಿಕ',
'ಶತಾಧ್ವರಿ',
'ಶತಾನಂದ',
'ಶತಾಯುಷಿ',
'ಶತಾರೇಂದ್ರ',
'ಶತಾವರಿ',
'ಶತೃಞಂತ',
'ಶತ್ರುಂತಪ',
'ಶತ್ರುಂದಮ',
'ಶನಿಕಾಟ',
'ಶನಿಗಸ',
'ಶನಿಗ್ರಹ',
'ಶನಿಬಲ',
'ಶನಿವಾರ',
'ಶನೀಶ್ವರ',
'ಶನೈಶ್ಚರ',
'ಶಪಿಯಿಸು',
'ಶಬಲತೆ',
'ಶಬಲಿತ',
'ಶಬಳತೆ',
'ಶಬಳಿತ',
'ಶಬಾಲಯ',
'ಶಬ್ದಂಗೆಡು',
'ಶಬ್ದಕೋಶ',
'ಶಬ್ದಗುಣ',
'ಶಬ್ದಗ್ರಹ',
'ಶಬ್ದಚಿತ್ರ',
'ಶಬ್ದಚ್ಛಲ',
'ಶಬ್ದದೋಷ',
'ಶಬ್ದದ್ರವ್ಯ',
'ಶಬ್ದಬ್ರಹ್ಮ',
'ಶಬ್ದರೂಪ',
'ಶಬ್ದವಿದ್ಯೆ',
'ಶಬ್ದವೃತ್ತಿ',
'ಶಬ್ದವೇದಿ',
'ಶಬ್ದವೇಧಿ',
'ಶಬ್ದಶಾಸ್ತ್ರ',
'ಶಬ್ದಶ್ಲೇಷ',
'ಶಬ್ದಸಂಖ್ಯೆ',
'ಶಬ್ದಸಿದ್ಧಿ',
'ಶಬ್ದಸುಖ',
'ಶಬ್ದಸ್ಮೃತಿ',
'ಶಮನಿಸು',
'ಶಮಶೇರಿ',
'ಶಯನಿಸು',
'ಶಯನೀಯ',
'ಶಯಾನಕ',
'ಶಯ್ಯಾಗಾರ',
'ಶಯ್ಯಾಗೃಹ',
'ಶಯ್ಯಾತಳ',
'ಶರಚ್ಛಂದ್ರ',
'ಶರಜನ್ಮ',
'ಶರಣಾರ್ತಿ',
'ಶರಣಾರ್ಥಿ',
'ಶರಣಿಸು',
'ಶರಣುಗೈ',
'ಶರಣ್ಗೊಳ್',
'ಶರಣ್ಬುಗು',
'ಶರಣ್ಬೊಗು',
'ಶರತ್ಕಾಲ',
'ಶರದೃತು',
'ಶರಧರ',
'ಶರಧೃತ',
'ಶರನಿಧಿ',
'ಶರಪುಂಖಿ',
'ಶರಪುಂಖೆ',
'ಶರಬತ್',
'ಶರಬತ್ತು',
'ಶರಭಂಗ',
'ಶರಮುಂಡಿ',
'ಶರರುಹ',
'ಶರವಣ',
'ಶರವರ್ಷ',
'ಶರವಳೆ',
'ಶರವಿದ್ಯೆ',
'ಶರವೃಷ್ಟಿ',
'ಶರವೇಗ',
'ಶರವೇದ',
'ಶರವೈರಿ',
'ಶರಶಲ್ಯ',
'ಶರಶಾಲಿ',
'ಶರಶಾಳಿ',
'ಶರಸೋನೆ',
'ಶರಸ್ತಂಬ',
'ಶರಹತಿ',
'ಶರಾರತ್ತು',
'ಶರಾರುಕ',
'ಶರಾವತಿ',
'ಶರಾಶ್ರಯ',
'ಶರಾಸನ',
'ಶರಾಸಾರ',
'ಶರೀರಜ',
'ಶರೀರಸ್ಥ',
'ಶರ್ಕರಿಲ',
'ಶಲಾಕಾಗ್ರ',
'ಶಲಾಟುಕ',
'ಶಲ್ಯತಂತ್ರ',
'ಶವಾಸನ',
'ಶವ್ವಾಪದ',
'ಶಶಕರ್ಣ',
'ಶಶಧರ',
'ಶಶಲಕ್ಷ್ಮ',
'ಶಶಶೃಂಗ',
'ಶಶಾದನ',
'ಶಶಿಕರ',
'ಶಶಿಕಲೆ',
'ಶಶಿಕಳೆ',
'ಶಶಿಕಾಂತ',
'ಶಶಿಕುಲ',
'ಶಶಿಖಂಡ',
'ಶಶಿಗೇಹ',
'ಶಶಿಚೂಡ',
'ಶಶಿಜೂಟ',
'ಶಶಿಧರ',
'ಶಶಿಬಿಂಬ',
'ಶಶಿಮಣಿ',
'ಶಶಿಮತ',
'ಶಶಿಮುಖಿ',
'ಶಶಿಮೌಲಿ',
'ಶಶಿಮೌಳಿ',
'ಶಶಿಲೇಖೆ',
'ಶಶಿವಂಶ',
'ಶಸ್ತ್ರಕರ್ಮ',
'ಶಸ್ತ್ರಕ್ರಿಯೆ',
'ಶಸ್ತ್ರಚ್ಯುತಿ',
'ಶಸ್ತ್ರಜೀವಿ',
'ಶಸ್ತ್ರತ್ಯಾಗ',
'ಶಸ್ತ್ರಧರ',
'ಶಸ್ತ್ರಧಾರಿ',
'ಶಸ್ತ್ರಪಾಣಿ',
'ಶಸ್ತ್ರಮಾರ್ಜ',
'ಶಸ್ತ್ರಯುದ್ಧ',
'ಶಸ್ತ್ರವಿದ',
'ಶಸ್ತ್ರವಿದ್ಯೆ',
'ಶಸ್ತ್ರವಿಧಿ',
'ಶಸ್ತ್ರವೈದ್ಯ',
'ಶಸ್ತ್ರವ್ರಣ',
'ಶಸ್ತ್ರಶಾಲೆ',
'ಶಸ್ತ್ರಶಾಸ್ತ್ರ',
'ಶಸ್ತ್ರಾಗಾರ',
'ಶಸ್ತ್ರಾಘಾತ',
'ಶಸ್ತ್ರಾಜೀವ',
'ಶಸ್ತ್ರಾಭ್ಯಾಸ',
'ಶಸ್ತ್ರಾಶಸ್ತ್ರಿ',
'ಶಹನಾಯ್',
'ಶಹನಾಯಿ',
'ಶಹಬಾಸ್',
'ಶಹಬಾಸು',
'ಶಹಭಾಸ್‍',
'ಶಹಾನಿಶಿ',
'ಶಹಾಪುರಿ',
'ಶಹಾಮೃಗ',
'ಶಹಾವಲಿ',
'ಶಾಂತಗುಣ',
'ಶಾಂತಪ್ರಾಸ',
'ಶಾಂತಮನ',
'ಶಾಂತಮನೆ',
'ಶಾಂತರಸ',
'ಶಾಂತಾಗಮ',
'ಶಾಂತಿಕರ್ಮ',
'ಶಾಂತಿಧನ',
'ಶಾಂತಿಪರ್ವ',
'ಶಾಂತಿಪಾಠ',
'ಶಾಂತಿಭಂಗ',
'ಶಾಂತಿಮಂತ್ರ',
'ಶಾಂತಿಮರ',
'ಶಾಂತಿಮುದ್ರೆ',
'ಶಾಂತಿಸೇನೆ',
'ಶಾಂತೇಕ್ಷಣ',
'ಶಾಂತ್ಯತೀತ',
'ಶಾಕಂಭರಿ',
'ಶಾಕಟಿಕ',
'ಶಾಕಪಾಕಿ',
'ಶಾಕವಂಗಿ',
'ಶಾಕಾಹಾರ',
'ಶಾಕಾಹಾರಿ',
'ಶಾಕುನಿಕ',
'ಶಾಕೋಟಕಿ',
'ಶಾಕ್ತಧರ್ಮ',
'ಶಾಕ್ತಮತ',
'ಶಾಕ್ತಾಗಮ',
'ಶಾಕ್ಯಮತ',
'ಶಾಖಾಚರ',
'ಶಾಖಾಧ್ಯಾಯಿ',
'ಶಾಖಾಪಥ',
'ಶಾಖಾಪುರ',
'ಶಾಖಾಮೃಗ',
'ಶಾಖಾರಂಡ',
'ಶಾಗಮಟ್ಟಿ',
'ಶಾಜೀರಿಗೆ',
'ಶಾಣಶಿಲೆ',
'ಶಾಣೆತನ',
'ಶಾಣೋಪಲ',
'ಶಾಣ್ಯಾತನ',
'ಶಾತಕುಂಭ',
'ಶಾತಕುಕ್ಷಿ',
'ಶಾತಮನ್ಯು',
'ಶಾತೋದರಿ',
'ಶಾತ್ತುಮೊರೆ',
'ಶಾನಬಾಗ',
'ಶಾನಬಾವ',
'ಶಾನಬೋವ',
'ಶಾನಭಾಗ',
'ಶಾನಭೋಗ',
'ಶಾನಭೋವ',
'ಶಾನುಭಾಗ',
'ಶಾನುಭಾವ',
'ಶಾನುಭೋಗ',
'ಶಾಪಂಗೊಡು',
'ಶಾಪಕೊಡು',
'ಶಾಪಗ್ರಸ್ತ',
'ಶಾಪಗ್ರsಸ್ತೆ',
'ಶಾಪಮೋಕ್ಷ',
'ಶಾಪಹತ',
'ಶಾಪಹತೆ',
'ಶಾಪಾಯುಧ',
'ಶಾಪಾರುಹ',
'ಶಾಪಾರುಹೆ',
'ಶಾಬೂದಾಣ',
'ಶಾಮಂತಿಗೆ',
'ಶಾಮಗೆಡ್ಡೆ',
'ಶಾಮಲತೆ',
'ಶಾಮಿಯಾನ',
'ಶಾಮಿಲಾತು',
'ಶಾರಪಪ್ಪು',
'ಶಾರೀರಕ',
'ಶಾರೀರಾಗ್ನಿ',
'ಶಾರೀರಿಕ',
'ಶಾರೆಗಣ್ಣು',
'ಶಾರ್ಙ್ಗಚಾಪ',
'ಶಾರ್ಙ್ಗಧನ್ವ',
'ಶಾರ್ಙ್ಗಧರ',
'ಶಾರ್ಙ್ಗಪಾಣಿ',
'ಶಾರ್ವರೀಶ',
'ಶಾಲಗ್ರಾಮ',
'ಶಾಲಪರ್ಣಿ',
'ಶಾಲಾಜಿರ',
'ಶಾಲಾವೃಕ',
'ಶಾಲಿಗ್ರಾಮ',
'ಶಾಲಿವನ',
'ಶಾಲಿಹೋತ್ರ',
'ಶಾಲೀನತೆ',
'ಶಾಲುನಾಮೆ',
'ಶಾಲ್ಯಕ್ಷತ',
'ಶಾಲ್ಯೋದನ',
'ಶಾವಂತಿಗೆ',
'ಶಾಶ್ವತತೆ',
'ಶಾಶ್ವತತ್ವ',
'ಶಾಸನಸ್ಥ',
'ಶಾಸನಿಕ',
'ಶಾಸನಿಗ',
'ಶಾಸ್ತಿಗೆಯ್',
'ಶಾಸ್ತ್ರಕಾರ',
'ಶಾಸ್ತ್ರಜ್ಞತೆ',
'ಶಾಸ್ತ್ರದಾನ',
'ಶಾಸ್ತ್ರಮಾರ್ಗ',
'ಶಾಸ್ತ್ರವಿದ',
'ಶಾಸ್ತ್ರಶಾಲೆ',
'ಶಾಸ್ತ್ರಸಿದ್ಧ',
'ಶಾಸ್ತ್ರಾಧ್ಯಾಯಿ',
'ಶಾಸ್ತ್ರಾನ್ವಿತ',
'ಶಾಸ್ತ್ರಾಭ್ಯಾಸ',
'ಶಾಸ್ತ್ರಾಭ್ಯಾಸಿ',
'ಶಾಸ್ತ್ರಿತನ',
'ಶಾಸ್ತ್ರೀಯತೆ',
'ಶಾಹಬಾಸು',
'ಶಾಹೀದುಡ್ಡು',
'ಶಾಹೂದುಡ್ಡು',
'ಶಾಹೂಪೈಸಾ',
'ಶಾಳಿಕ್ಷೇತ್ರ',
'ಶಾಳಿಗೋಪಿ',
'ಶಾಳಿಹೋತ್ರ',
'ಶಾಳೀವನ',
'ಶಿಂಗಣಿಕ',
'ಶಿಂಗರೂಪ',
'ಶಿಂಗಲೀಕ',
'ಶಿಂಗಳೀಕ',
'ಶಿಂಬಿಧಾನ್ಯ',
'ಶಿಂಶುಮಾರ',
'ಶಿಕ್ಮಿದಾರ',
'ಶಿಕ್ಯೋತ್ಸವ',
'ಶಿಕ್ಷಾಗುರು',
'ಶಿಕ್ಷಾಚಾರ್ಯ',
'ಶಿಕ್ಷಾಧ್ಯಕ್ಷ',
'ಶಿಕ್ಷಾಪದ',
'ಶಿಕ್ಷಾಲಯ',
'ಶಿಕ್ಷಾವಿದ',
'ಶಿಕ್ಷಾವ್ರತ',
'ಶಿಕ್ಷಾಶಾಸ್ತ್ರ',
'ಶಿಕ್ಷಾಸೂತ್ರ',
'ಶಿಕ್ಷೆಗೆಡು',
'ಶಿಕ್ಷೆಗೆಯ್',
'ಶಿಖಂಡಕ',
'ಶಿಖಂಡಿಕೆ',
'ಶಿಖರಣೆ',
'ಶಿಖರಿಣಿ',
'ಶಿಖಾಚಕ್ರ',
'ಶಿಖಾಮಣಿ',
'ಶಿಖಾವಟ್ಟು',
'ಶಿಖಾವಲ',
'ಶಿಖಾವಳ',
'ಶಿಖಾವುತ್ರ',
'ಶಿಖಾಸರ',
'ಶಿಖಾಸೇವೆ',
'ಶಿಖಾಸ್ರಜ',
'ಶಿಖಿಗ್ರೀವ',
'ಶಿಖಿನೇತ್ರ',
'ಶಿಖಿಶರ',
'ಶಿತಶೂಕ',
'ಶಿತಿಕಂಠ',
'ಶಿತಿಕಾಕ',
'ಶಿತಿಗಲ',
'ಶಿತಿಗಳ',
'ಶಿತಿಶ್ವೇತ',
'ಶಿಥಿಲತೆ',
'ಶಿಥಿಲತ್ವ',
'ಶಿಥಿಲಿಸು',
'ಶಿಪಾರಸು',
'ಶಿಪಾರಸ್ಸು',
'ಶಿಪಿಗೆಂಡೆ',
'ಶಿಪಿವಿಷ್ಟ',
'ಶಿಫಾರಸ್',
'ಶಿಫಾರಸು',
'ಶಿಫಾರಸ್ಸು',
'ಶಿರಂದೂಗು',
'ಶಿರಂಬರಂ',
'ಶಿರಃಕಂಪ',
'ಶಿರಚ್ಛೇದ',
'ಶಿರದೂಗು',
'ಶಿರಪೇಚ',
'ಶಿರಪೇಚು',
'ಶಿರಪೇಷು',
'ಶಿರಬಾಗು',
'ಶಿರಸಲ',
'ಶಿರಸಿಜ',
'ಶಿರಸ್ತೋದ',
'ಶಿರಸ್ತ್ರಾಣ',
'ಶಿರಸ್ಥಲ',
'ಶಿರಸ್ನಾನ',
'ಶಿರಿವರು',
'ಶಿರೋಗೃಹ',
'ಶಿರೋಗ್ರಂಥಿ',
'ಶಿರೋದಾಹ',
'ಶಿರೋದೇಶ',
'ಶಿರೋದ್ಗತ',
'ಶಿರೋಧರ',
'ಶಿರೋಧರೆ',
'ಶಿರೋಧಾರ್ಯ',
'ಶಿರೋನಾಮ',
'ಶಿರೋನಾಮೆ',
'ಶಿರೋಭ್ರಮೆ',
'ಶಿರೋಮಣಿ',
'ಶಿರೋರತ್ನ',
'ಶಿರೋರುಹ',
'ಶಿರೋಲಿಂಗ',
'ಶಿರೋವೇಷ್ಟಿ',
'ಶಿರೋವ್ಯಾಧಿ',
'ಶಿರೋವ್ಯೂಢ',
'ಶಿಲವೃತ್ತಿ',
'ಶಿಲಾಕರ್ಮ',
'ಶಿಲಾಜತು',
'ಶಿಲಾಜಿತು',
'ಶಿಲಾತಲ',
'ಶಿಲಾತಳ',
'ಶಿಲಾಧಾತು',
'ಶಿಲಾನ್ಯಾಸ',
'ಶಿಲಾಪಟ್ಟ',
'ಶಿಲಾಪಾತ್ರೆ',
'ಶಿಲಾಪೀಠ',
'ಶಿಲಾಭೇದಿ',
'ಶಿಲಾಯಂತ್ರ',
'ಶಿಲಾಯುಗ',
'ಶಿಲಾರಸ',
'ಶಿಲಾಲಿಪಿ',
'ಶಿಲಾಲೇಖ',
'ಶಿಲಾವಲ್ಕ',
'ಶಿಲಾವೃಷ್ಟಿ',
'ಶಿಲಾಸನ',
'ಶಿಲಾಸ್ತಂಭ',
'ಶಿಲೀಮುಖ',
'ಶಿಲೆದಾರ',
'ಶಿಲೇದಾರ',
'ಶಿಲೋಚ್ಚಯ',
'ಶಿಲ್ಪಕರ್ಮ',
'ಶಿಲ್ಪಕಲೆ',
'ಶಿಲ್ಪಕಾರ',
'ಶಿಲ್ಪವಿದ್ಯೆ',
'ಶಿಲ್ಪಶಾಸ್ತ್ರ',
'ಶಿವಂಕರ',
'ಶಿವಕುಲ',
'ಶಿವಕೂಟ',
'ಶಿವಗಣ',
'ಶಿವಗತಿ',
'ಶಿವಜ್ಞಾನ',
'ಶಿವಜ್ಞಾನಿ',
'ಶಿವತತ್ತ್ವ',
'ಶಿವತಾತಿ',
'ಶಿವದಾನ',
'ಶಿವದಾರ',
'ಶಿವದಾರು',
'ಶಿವದಿಡು',
'ಶಿವದೀಕ್ಷೆ',
'ಶಿವದೂತ',
'ಶಿವದ್ರೋಹಿ',
'ಶಿವಧರ್ಮ',
'ಶಿವಧಾತು',
'ಶಿವಧ್ಯಾನ',
'ಶಿವನಾಭಿ',
'ಶಿವನಿಲೆ',
'ಶಿವಪಥ',
'ಶಿವಪದ',
'ಶಿವಪುರ',
'ಶಿವಬ್ರತಿ',
'ಶಿವಭಕ್ತ',
'ಶಿವಭಕ್ತೆ',
'ಶಿವಮತ',
'ಶಿವಮಲ್ಲಿ',
'ಶಿವಯೋಗ',
'ಶಿವಯೋಗಿ',
'ಶಿವರಸ',
'ಶಿವರಾತ್ರಿ',
'ಶಿವರಾತ್ರೆ',
'ಶಿವಲಗ್ನ',
'ಶಿವಲಿಂಗ',
'ಶಿವಲಿಂಗಿ',
'ಶಿವಲೀಲೆ',
'ಶಿವಲೋಕ',
'ಶಿವವಿಪ್ರ',
'ಶಿವಶಕ್ತಿ',
'ಶಿವಸುಖ',
'ಶಿವಸೂತ್ರ',
'ಶಿವಸೊಮ್ಮು',
'ಶಿವಳಿಕೆ',
'ಶಿವಾಗಮ',
'ಶಿವಾಚಾರ',
'ಶಿವಾಚಾರಿ',
'ಶಿವಾಚಾರ್ಯ',
'ಶಿವಾಟಿಕೆ',
'ಶಿವಾದ್ವೈತ',
'ಶಿವಾಧೀನ',
'ಶಿವಾರ್ಪಿತ',
'ಶಿವಾಲಯ',
'ಶಿವಾಸ್ಪದ',
'ಶಿವೇತರ',
'ಶಿಶಿರಾಂಶು',
'ಶಿಶುಗೀತ',
'ಶಿಶುತನ',
'ಶಿಶುಪ್ರಾಸ',
'ಶಿಶುಮತಿ',
'ಶಿಶುಮಾರ',
'ಶಿಶುವಧ',
'ಶಿಶುಹತ್ಯೆ',
'ಶಿಷ್ಟತನ',
'ಶಿಷ್ಟಬಾಗೆ',
'ಶಿಷ್ಟಭಾಷೆ',
'ಶಿಷ್ಟಾಚಾರ',
'ಶಿಷ್ಯವೃತ್ತಿ',
'ಶಿಸ್ತುಗಾತಿ',
'ಶಿಸ್ತುಗಾರ',
'ಶಿಸ್ತುಗಾರ್ತಿ',
'ಶಿಸ್ತುಪಟ್ಟೆ',
'ಶಿಸ್ತುಬಿಡು',
'ಶಿಳೀಮುಖ',
'ಶಿಳೋಚ್ಚಯ',
'ಶಿಳ್ಳಿಹೊಡೆ',
'ಶಿಳ್ಳುಹಾಕು',
'ಶಿಳ್ಳೆಕೇತ',
'ಶೀಕರಣೆ',
'ಶೀಗೆಕಾಯಿ',
'ಶೀಘ್ರಕಾರಿ',
'ಶೀಘ್ರಕೋಪಿ',
'ಶೀಘ್ರಗಾಮಿ',
'ಶೀಘ್ರಲಿಪಿ',
'ಶೀತಕರ',
'ಶೀತಕಾಲ',
'ಶೀತಜ್ವರ',
'ಶೀತದಂತ',
'ಶೀತದ್ಯುತಿ',
'ಶೀತಬಾಧೆ',
'ಶೀತಭೀತ',
'ಶೀತಭೀರು',
'ಶೀತರುಚಿ',
'ಶೀತರೋಚಿ',
'ಶೀತವಾತ',
'ಶೀತಶಿವ',
'ಶೀತಶಿವೆ',
'ಶೀತಳಿಕೆ',
'ಶೀರ್ಷರತ್ನ',
'ಶೀರ್ಷಾಸನ',
'ಶೀಲಭಂಗ',
'ಶೀಲಭದ್ರ',
'ಶೀಲವಂತ',
'ಶೀಲವತಿ',
'ಶೀಲವ್ರತ',
'ಶೀವಿಗೆಡ್ಡೆ',
'ಶುಂಠತನ',
'ಶುಂಠಿಹುಲ್ಲು',
'ಶುಂಡಾದಂಡ',
'ಶುಂಡಾಪಾನ',
'ಶುಂಡೇಕಾಯಿ',
'ಶುಕತುಂಡ',
'ಶುಕದೇರ',
'ಶುಕನಾಸ',
'ಶುಕಲೂರ್ತಿ',
'ಶುಕವಾಣಿ',
'ಶುಕವೃತ್ತಿ',
'ಶುಕ್ತಿಪುಟ',
'ಶುಕ್ತಿಮುದ್ರೆ',
'ಶುಕ್ರದೆಶೆ',
'ಶುಕ್ರದೆಸೆ',
'ಶುಕ್ರಮೂಢ',
'ಶುಕ್ರವಾರ',
'ಶುಕ್ರೋದಯ',
'ಶುಕ್ಲಕೀಟ',
'ಶುಕ್ಲಧಾತು',
'ಶುಕ್ಲಧ್ಯಾನ',
'ಶುಕ್ಲಪಕ್ಷ',
'ಶುಕ್ಲಮೇಹ',
'ಶುಕ್ಲಮೇಹಿ',
'ಶುಕ್ಲಯೋಗ',
'ಶುಕ್ಲಲೇಶ್ಯೆ',
'ಶುಕ್ಲವತ್ರಿ',
'ಶುಕ್ಲವೃತ್ತಿ',
'ಶುಕ್ಲಾಂಬರ',
'ಶುಕ್ಲಾಪಾಂಗ',
'ಶುಙ್ಮಲಿನ',
'ಶುಙ್ಮಲಿನೆ',
'ಶುಚಿತನ',
'ಶುಚಿರ್ಭೂತ',
'ಶುಚಿರ್ಭೂತೆ',
'ಶುಚಿವಂತ',
'ಶುಚಿಸ್ವಾಂತೆ',
'ಶುಚೀಭೂತ',
'ಶುಚ್ಯಂಬಕ',
'ಶುದ್ಧಕ್ರಿಯಾ',
'ಶುದ್ಧತಾನ',
'ಶುದ್ಧದೇಶಿ',
'ಶುದ್ಧನೀರು',
'ಶುದ್ಧಪಾಠ',
'ಶುದ್ಧಪಾಷ್ರ್ಣಿ',
'ಶುದ್ಧಪ್ರತಿ',
'ಶುದ್ಧಯಿಸು',
'ಶುದ್ಧವೃತ್ತ',
'ಶುದ್ಧವೃತ್ತೆ',
'ಶುದ್ಧಶುಂಠಿ',
'ಶುದ್ಧಶೈವ',
'ಶುದ್ಧಾಕ್ಷರ',
'ಶುದ್ಧಾವಳಿ',
'ಶುದ್ಧಿಪತ್ರ',
'ಶುನಾಸೀರ',
'ಶುನಿಗಲು',
'ಶುಭಂಕರ',
'ಶುಭಕರ',
'ಶುಭಕೃತು',
'ಶುಭಗೇಡಿ',
'ಶುಭಲೇಶ್ಯೆ',
'ಶುಭವೃತ್ತ',
'ಶುಭವೃತ್ತೆ',
'ಶುಭಾವಹ',
'ಶುಭಾಶಯ',
'ಶುಭೇತರ',
'ಶುಭೇದಾರ',
'ಶುಭೇದಾರು',
'ಶುಭ್ರಕರ',
'ಶುಭ್ರಚ್ಛದ',
'ಶುಭ್ರವಣ್ಣ',
'ಶುಭ್ರವಿಡಿ',
'ಶುಲ್ವಸೂತ್ರ',
'ಶುಶ್ರೂಷಣ',
'ಶೂಕಕೀಟ',
'ಶೂಕಧಾನ್ಯ',
'ಶೂಕಲಾಶ್ವ',
'ಶೂಕಶಿಂಬಿ',
'ಶೂನ್ಯಗಾರ',
'ಶೂನ್ಯಗಾರ್ತಿ',
'ಶೂನ್ಯತತ್ತ್ವ',
'ಶೂನ್ಯದೃಷ್ಟಿ',
'ಶೂನ್ಯಪೀಠ',
'ಶೂನ್ಯಮಾಸ',
'ಶೂನ್ಯಲಿಂಗ',
'ಶೂನ್ಯವಾದ',
'ಶೂನ್ಯವಾದಿ',
'ಶೂನ್ಯವೇಳೆ',
'ಶೂರತನ',
'ಶೂರ್ಪಕರ್ಣ',
'ಶೂರ್ಪಶ್ರವ',
'ಶೂಲಧರ',
'ಶೂಲಪಾಣಿ',
'ಶೂಲಮುದ್ರೆ',
'ಶೂಲಯೋಗ',
'ಶೂಲಾಕೃತ',
'ಶೂಲಾಸನ',
'ಶೂಲೆಗಂಟು',
'ಶೂಲೆಗೂಡು',
'ಶೂಲೆರೋಗ',
'ಶೂಲೆವೇನೆ',
'ಶೂಲೋದ್ಭವ',
'ಶೃಂಖಲಕ',
'ಶೃಂಖಳಕ',
'ಶೃಂಗಬೇರ',
'ಶೃಂಗರಂಗೈ',
'ಶೃಂಗರಿಸು',
'ಶೃಂಗರುಹೆ',
'ಶೃಂಗವೇರ',
'ಶೃಂಗಸಭೆ',
'ಶೃಂಗಾಟಕ',
'ಶೃಂಗಾರಿಸು',
'ಶೆಂತಮಿಳ',
'ಶೆಂದಮಿಳ',
'ಶೆಟ್ಟಿಗಾರ',
'ಶೇಂಗಾದಾಣಿ',
'ಶೇಕದಾರ',
'ಶೇಕದಾರಿ',
'ಶೇಖದಾರ',
'ಶೇಖರಣೆ',
'ಶೇಖರಿಸು',
'ಶೇನಭಾವ',
'ಶೇನಭೋಗ',
'ಶೇಫಾಲಿಕೆ',
'ಶೇಫಾಳಿಕೆ',
'ಶೇರವಾನಿ',
'ಶೇವಂತಿಗೆ',
'ಶೇವಣಿಗೆ',
'ಶೇಷಶಾಯಿ',
'ಶೇಷಾಕ್ಷತ',
'ಶೇಷಾಕ್ಷತೆ',
'ಶೈಕ್ಷಣಿಕ',
'ಶೈಖರಿಕ',
'ಶೈತ್ಯಕಾರಿ',
'ಶೈತ್ಯಸಾಲೆ',
'ಶೈತ್ಯಾಗಾರ',
'ಶೈತ್ಯಾಲಯ',
'ಶೈಲಚಾಪ',
'ಶೈಲಪತಿ',
'ಶೈಲಪುತ್ರಿ',
'ಶೈಲರಾಜ',
'ಶೈಲಲಿಪಿ',
'ಶೈವಮತ',
'ಶೈವಲಿನಿ',
'ಶೈವಶಮಿ',
'ಶೈವಳಿನಿ',
'ಶೈವಾಗಮ',
'ಶೈಳಾಕ್ಷರ',
'ಶೊಂಟಿಕೊಂಬು',
'ಶೋಕಗೀತೆ',
'ಶೋಕರಸ',
'ಶೋಕಾಕುಲ',
'ಶೋಕಾಕುಲೆ',
'ಶೋಕಿಗಾರ',
'ಶೋಕಿಲಾಲ',
'ಶೋಕೋದ್ರೇಕ',
'ಶೋಚನೀಯ',
'ಶೋಣಮಣಿ',
'ಶೋಣಾಂಬುಜ',
'ಶೋಣೋತ್ಪಲ',
'ಶೋಬೆರೋಗಿ',
'ಶೋಭಕೃತು',
'ಶೋಭನಿಕ',
'ಶೋಭಸ್ಕರ',
'ಶೋಭಾಂಜನ',
'ಶೋಭಾಕರ',
'ಶೋಭಾಕ್ಷತೆ',
'ಶೋಭಾವಹ',
'ಶೋಭಿತಾಂಗ',
'ಶೋಭಿತಾಂಗಿ',
'ಶೋಭೆಗೊಳ್ಳು',
'ಶೋಭೆವಡೆ',
'ಶೋಭೆವೆಱು',
'ಶೋಷಂಬೆರು',
'ಶೌಕ್ತಿಕೇಯ',
'ಶೌಕ್ಲಿಕೇಯ',
'ಶೌಚಕೂಪ',
'ಶೌಚವಿಧಿ',
'ಶೌಚಾಚಾರ',
'ಶೌಚಾಲಯ',
'ಶೌರಸೇನ',
'ಶೌರಸೇನಿ',
'ಶೌರ್ಯಪಣ',
'ಶೌರ್ಯಮದ',
'ಶೌರ್ಯವಂತ',
'ಶೌರ್ಯವಡೆ',
'ಶ್ಮಶ್ರುಲೇಖ',
'ಶ್ಯಾಣ್ಯಾತನ',
'ಶ್ಯಾನಭಾಗ',
'ಶ್ಯಾನಭಾವ',
'ಶ್ಯಾನಭೋಗ',
'ಶ್ಯಾನುಭೋಗ',
'ಶ್ಯಾಮಂತಿಗೆ',
'ಶ್ಯಾಮಕಂಠ',
'ಶ್ಯಾಮಗಾತ್ರ',
'ಶ್ಯಾಮಪುಷ್ಟಿ',
'ಶ್ಯಾಮಲಾಂಗ',
'ಶ್ಯಾಮಲಾಂಗಿ',
'ಶ್ಯಾಮಲಿತ',
'ಶ್ಯಾಮಳಾಂಗ',
'ಶ್ಯಾಮಳಾಂಗಿ',
'ಶ್ಯಾವಂತಿಗೆ',
'ಶ್ಯಾವದಂತ',
'ಶ್ಯೇನವ್ಯೂಹ',
'ಶ್ರದ್ಧಾಂಜಲಿ',
'ಶ್ರದ್ಧಾಭಕ್ತಿ',
'ಶ್ರದ್ಧಾವಂತ',
'ಶ್ರಮಂಗಳಿ',
'ಶ್ರಮಗಾರ',
'ಶ್ರಮಗೃಹ',
'ಶ್ರಮಗೆಯ್',
'ಶ್ರಮಜೀವಿ',
'ಶ್ರಮಣಕ',
'ಶ್ರಮದಾನ',
'ಶ್ರಮಪಡು',
'ಶ್ರಮಸ್ಥಾನ',
'ಶ್ರಯಣೀಯ',
'ಶ್ರಯಣೀಯೆ',
'ಶ್ರಯಪಟ್ಟೆ',
'ಶ್ರವಣೀಯೆ',
'ಶ್ರವ್ಯಕಾವ್ಯ',
'ಶ್ರಾದ್ಧದೇವ',
'ಶ್ರಾದ್ಧಭೋಕ್ತೃ',
'ಶ್ರಾವಕತ್ವ',
'ಶ್ರಾವಣಿಕ',
'ಶ್ರಿತಜನ',
'ಶ್ರೀಕರಣ',
'ಶ್ರೀಗದಿತ',
'ಶ್ರೀತಾಳಿಕ',
'ಶ್ರಿತುಲಸಿ',
'ಶ್ರೀತುಳಸಿ',
'ಶ್ರೀತೊಲಸಿ',
'ಶ್ರೀತೊಳಸಿ',
'ಶ್ರೀದಯಿತ',
'ಶ್ರೀದಯಿತೆ',
'ಶ್ರೀನಂದನ',
'ಶ್ರೀನಿಲಯ',
'ಶ್ರೀನಿವಾಸ',
'ಶ್ರೀನಿಸರ್ಗ',
'ಶ್ರೀನಿಳಯ',
'ಶ್ರೀಪಂಚಮಿ',
'ಶ್ರೀಪರ್ಣಿಕೆ',
'ಶ್ರೀಪರ್ಬತ',
'ಶ್ರೀಪರ್ವತ',
'ಶ್ರೀಮಂತಿಕೆ',
'ಶ್ರೀಮಜ್ಜನ',
'ಶ್ರೀಮಾನಿನಿ',
'ಶ್ರೀರಂಜನಿ',
'ಶ್ರೀರಮಣ',
'ಶ್ರೀರಮಣಿ',
'ಶ್ರೀವತ್ಸಕಿ',
'ಶ್ರೀವನಿತೆ',
'ಶ್ರೀವಿಲಾಸ',
'ಶ್ರೀವೃಕ್ಷಕಿ',
'ಶ್ರೀವೈಷ್ಣವ',
'ಶ್ರೀಸಾಮಾನ್ಯ',
'ಶ್ರೀಹಸ್ತಿನಿ',
'ಶ್ರುತಕೀರ್ತಿ',
'ಶ್ರುತಜ್ಞಾನ',
'ಶ್ರುತದುಷ್ಟ',
'ಶ್ರುತದೇವಿ',
'ಶ್ರುತಮೂರ್ತಿ',
'ಶ್ರುತವಿದ್ಧ',
'ಶ್ರುತಹೀನ',
'ಶ್ರುತಿಕಟು',
'ಶ್ರುತಿಕಷ್ಟ',
'ಶ್ರುತಿಗರೆ',
'ಶ್ರುತಿಗಾನ',
'ಶ್ರುತಿಗಾರ',
'ಶ್ರುತಿಗೂಡು',
'ಶ್ರುತಿಪಥ',
'ಶ್ರುತಿಪೂರ',
'ಶ್ರುತಿಮಾರ್ಗ',
'ಶ್ರುತಿಮುಖ',
'ಶ್ರುತಿಮೂಲ',
'ಶ್ರುತಿವಿದ್ಧ',
'ಶ್ರುತಿಶೂನ್ಯ',
'ಶ್ರುತಿಶೂನ್ಯೆ',
'ಶ್ರುತಿಸಹ್ಯ',
'ಶ್ರುತಿಹೀನ',
'ಶ್ರುತ್ಯಾರೂಢ',
'ಶ್ರೇಣೀಕೃತ',
'ಶ್ರೇಯಸ್ಕರ',
'ಶ್ರೇಯೋಮಾರ್ಗ',
'ಶ್ರೇಷ್ಠಗೆಡು',
'ಶ್ರೇಷ್ಠತನ',
'ಶ್ರೇಷ್ಠಶಾಕ',
'ಶ್ರೇಷ್ಠಾಗಮ',
'ಶ್ರೋತ್ರನಾಶ',
'ಶ್ರೋತ್ರಪಥ',
'ಶ್ರೋತ್ರಸುಖ',
'ಶ್ರೋತ್ರೇಂದ್ರಿಯ',
'ಶ್ರೌತಕರ್ಮ',
'ಶ್ರೌತಪಥ',
'ಶ್ರೌತಸೂತ್ರ',
'ಶ್ಲಾಘನೀಯ',
'ಶ್ಲಿಷ್ಟಾಕ್ಷರ',
'ಶ್ಲೇಷೋಪಮೆ',
'ಶ್ಲೇಷ್ಮಕ್ಷಯ',
'ಶ್ಲೇಷ್ಮಖಾಸ',
'ಶ್ಲೇಷ್ಮಗುಲ್ಮ',
'ಶ್ಲೇಷ್ಮಜಿಹ್ವೆ',
'ಶ್ಲೇಷ್ಮಜ್ವರ',
'ಶ್ಲೇಷ್ಮನಾಡಿ',
'ಶ್ಲೇಷ್ಮರೋಗ',
'ಶ್ಲೇಷ್ಮಾತಕ',
'ಶ್ವಪಚಕ',
'ಶ್ವಾನಜ್ಞಾನ',
'ಶ್ವಾನಜ್ಞಾನಿ',
'ಶ್ವಾನವೃತ್ತಿ',
'ಶ್ವಾಸಕೋಶ',
'ಶ್ವಾಸಕ್ರಿಯೆ',
'ಶ್ವಾಸನಾಳ',
'ಶ್ವೇತಕುಷ್ಠ',
'ಶ್ವೇತಚ್ಛತ್ರ',
'ಶ್ವೇತಚ್ಛದ',
'ಶ್ವೇತದ್ವೀಪ',
'ಶ್ವೇತಪಕ್ಷ',
'ಶ್ವೇತಪಟ',
'ಶ್ವೇತಪತ್ರ',
'ಶ್ವೇತರಕ್ತ',
'ಶ್ವೇತರೋಚಿ',
'ಶ್ವೇತವಾಸ',
'ಶ್ವೇತಾಂಬರ',
'ಶ್ವೇತಾಕ್ಷತೆ',
'ಶ್ವೇತಾಗುರು',
'ಶ್ವೇತಾಭ್ರಕ',
'ಷಂಡತನ',
'ಷಂಢತಿಲ',
'ಷಂಢತಿಳ',
'ಷಟುತರ್ಕ',
'ಷಟುಸ್ಥಲ',
'ಷಟ್ಕಮಲ',
'ಷಟ್ಕಷಾಯ',
'ಷಟ್ಚರಣ',
'ಷಡಕ್ಷರ',
'ಷಡಕ್ಷರಿ',
'ಷಡಯನ',
'ಷಡಾಧಾರ',
'ಷಡಾನನ',
'ಷಡೀರಣ',
'ಷಡುಋತು',
'ಷಡುಖಂಡ',
'ಷಡುಗವ್ಯ',
'ಷಡುಚಕ್ರ',
'ಷಡುಪದ',
'ಷಡುರಸ',
'ಷಡುರುಚಿ',
'ಷಡುವರ್ಗ',
'ಷಡುಸ್ಥಲ',
'ಷಡುಸ್ಥಳ',
'ಷಡೂಷಣ',
'ಷಡ್ಗುಣಿಸು',
'ಷಡ್ಗ್ರಂಥಿಕೆ',
'ಷಡ್ದರ್ಶನ',
'ಷಡ್ವದನ',
'ಷಬಕೂನು',
'ಷರಬತ್ತು',
'ಷರಾಕಟ್ಟು',
'ಷರಾರತಿ',
'ಷರಿಯತ್ತು',
'ಷಷ್ಟಿಪೂರ್ತಿ',
'ಷಷ್ಠಭಾಗ',
'ಷಷ್ಠಾಷ್ಟಕ',
'ಷಹಜಾದ',
'ಷಹಜಾದೆ',
'ಷಹಸಾದೆ',
'ಷಾಃಪಸಂದ್',
'ಷಾಣ್ಮಾತುರ',
'ಷಾಣ್ಮಾಸಿಕ',
'ಷಾದಮಾನಿ',
'ಷಾಪಸಂದ',
'ಷಿಕ್ಮಿದಾರ',
'ಷೇಕ್‍ದಾರ',
'ಷೇಕ್ದಾರಿಕೆ',
'ಷೇರುದಾರ',
'ಷೋಕಿಲಾಲ',
'ಷೋಕೀದಾರ',
'ಸಂಕಟಕ',
'ಸಂಕಟಿಸು',
'ಸಂಕಪಾಲ',
'ಸಂಕರಾತ್ರಿ',
'ಸಂಕರಿಸು',
'ಸಂಕರುಷ',
'ಸಂಕರ್ಷಣ',
'ಸಂಕಲನ',
'ಸಂಕಲಿಕೆ',
'ಸಂಕಲಿತ',
'ಸಂಕಲಿಸು',
'ಸಂಕಲ್ಪಜ',
'ಸಂಕಲ್ಪಿತ',
'ಸಂಕಲ್ಪಿಸು',
'ಸಂಕವರಿ',
'ಸಂಕವಾಲಿ',
'ಸಂಕವಾಲೆ',
'ಸಂಕಳನ',
'ಸಂಕಳಿತ',
'ಸಂಕಳಿಸು',
'ಸಂಕಿರಣ',
'ಸಂಕೀರ್ಣಕ',
'ಸಂಕೀರ್ಣತೆ',
'ಸಂಕೀರ್ತನ',
'ಸಂಕೀರ್ತನೆ',
'ಸಂಕೀರ್ತಿಸು',
'ಸಂಕುಚನ',
'ಸಂಕುಚಿತ',
'ಸಂಕುಚಿಸು',
'ಸಂಕುಬುಲ್ಲು',
'ಸಂಕುಮದ',
'ಸಂಕುಲತೆ',
'ಸಂಕುವಿಡು',
'ಸಂಕುಸುಕ',
'ಸಂಕೆಗಿಡು',
'ಸಂಕೆವೆಱು',
'ಸಂಕೇತಕ',
'ಸಂಕೇತನ',
'ಸಂಕೇತಿಸು',
'ಸಂಕೇಶ್ವರಿ',
'ಸಂಕೋಚನ',
'ಸಂಕೋಚಿಸು',
'ಸಂಕ್ರಂದನ',
'ಸಂಕ್ರಮಣ',
'ಸಂಕ್ರಮಿತ',
'ಸಂಕ್ರಮಿಸು',
'ಸಂಕ್ಷುಭಿತ',
'ಸಂಕ್ಷೇಪಣ',
'ಸಂಕ್ಷೇಪಿಸು',
'ಸಂಕ್ಷೋಭಿತ',
'ಸಂಕ್ಷೋಭಿಸು',
'ಸಂಖ್ಯಾತೀತ',
'ಸಂಖ್ಯಾಬಲ',
'ಸಂಖ್ಯಾರುಚಿ',
'ಸಂಖ್ಯಾವಾಚಿ',
'ಸಂಖ್ಯಾಶಾಸ್ತ್ರ',
'ಸಂಗಗುಣ',
'ಸಂಗಟಿಸು',
'ಸಂಗಡತಿ',
'ಸಂಗಡಿಕೆ',
'ಸಂಗಡಿಗ',
'ಸಂಗಡಿತ',
'ಸಂಗಡಿತಿ',
'ಸಂಗಡಿಸು',
'ಸಂಗಣಿಸು',
'ಸಂಗತಿಗೈ',
'ಸಂಗದೋಷ',
'ಸಂಗಮಿಸು',
'ಸಂಗಲಿಪ್ತ',
'ಸಂಗಲಿಸು',
'ಸಂಗವಿಸು',
'ಸಂಗಸುಖ',
'ಸಂಗಳಿಕೆ',
'ಸಂಗಳಿಸು',
'ಸಂಗೀತಕ',
'ಸಂಗುಣಿತ',
'ಸಂಗೃಹೀತ',
'ಸಂಗೊಳಿಸು',
'ಸಂಗೋಪನ',
'ಸಂಗೋಪನೆ',
'ಸಂಗ್ರಥಿತ',
'ಸಂಗ್ರಹಣ',
'ಸಂಗ್ರಹಣಿ',
'ಸಂಗ್ರಹಿಣಿ',
'ಸಂಗ್ರಹಿಸು',
'ಸಂಗ್ರಾಣಿಸು',
'ಸಂಗ್ರಾಹಕ',
'ಸಂಘಟಕ',
'ಸಂಘಟಕಿ',
'ಸಂಘಟನ',
'ಸಂಘಟನೆ',
'ಸಂಘಟಿತ',
'ಸಂಘಟಿಸು',
'ಸಂಘಟ್ಟನ',
'ಸಂಘಟ್ಟಿತ',
'ಸಂಘಟ್ಟಿಸು',
'ಸಂಘರ್ಷಣ',
'ಸಂಘರ್ಷಣೆ',
'ಸಂಘರ್ಷಿಸು',
'ಸಂಘಾಯಿಮ',
'ಸಂಘಾರಾಮ',
'ಸಂಘೂರ್ಣಿತ',
'ಸಂಚಕರ',
'ಸಂಚಕಾರ',
'ಸಂಚಕಾರಿ',
'ಸಂಚಗಾರ',
'ಸಂಚಗಾರ್ತಿ',
'ಸಂಚಗೆಡು',
'ಸಂಚತಪ್ಪು',
'ಸಂಚಯನ',
'ಸಂಚಯಿಸು',
'ಸಂಚರಣ',
'ಸಂಚರಣೆ',
'ಸಂಚರಿಸು',
'ಸಂಚಲತೆ',
'ಸಂಚಲತ್ವ',
'ಸಂಚಲನ',
'ಸಂಚಲನೆ',
'ಸಂಚಲಿತ',
'ಸಂಚಲಿತೆ',
'ಸಂಚಲಿಸು',
'ಸಂಚವಱಿ',
'ಸಂಚಳತೆ',
'ಸಂಚಳತ್ವ',
'ಸಂಚಳನ',
'ಸಂಚಳಿತ',
'ಸಂಚಳಿತೆ',
'ಸಂಚಳಿಸು',
'ಸಂಚಾರಕ',
'ಸಂಚಾರತೆ',
'ಸಂಚಾರಿಕೆ',
'ಸಂಚಾರಿಸು',
'ಸಂಚಾಲಕ',
'ಸಂಚಾಲಕಿ',
'ಸಂಚಾಲನ',
'ಸಂಚಾಲನೆ',
'ಸಂಚುಕಾರ',
'ಸಂಚುಗಾಣು',
'ಸಂಚುಗಾತಿ',
'ಸಂಚುಗಾರ',
'ಸಂಚುಗಾರ್ತಿ',
'ಸಂಚುಗಾಱ',
'ಸಂಚುಗೂಡು',
'ಸಂಚುಗೆಯ್',
'ಸಂಚುತಪ್ಪು',
'ಸಂಚುನಾಡಿ',
'ಸಂಚುನೋಟ',
'ಸಂಚುವಱಿ',
'ಸಂಚುವಿಡಿ',
'ಸಂಚುವೆಱು',
'ಸಂಚುಹೂಡು',
'ಸಂಚೂರ್ಣಿತ',
'ಸಂಚೂಳಿಕೆ',
'ಸಂಛಾದನ',
'ಸಂಛಾದಿತ',
'ಸಂಛಾದಿಸು',
'ಸಂಜನನ',
'ಸಂಜನಿತ',
'ಸಂಜನಿಸು',
'ಸಂಜವನ',
'ಸಂಜಾಯಿತ',
'ಸಂಜೀವಕ',
'ಸಂಜೀವನ',
'ಸಂಜೀವನೆ',
'ಸಂಜೀವಿನಿ',
'ಸಂಜೀವಿಸು',
'ಸಂಜೂರಿಗೆ',
'ಸಂಜೆಗಣ್ಣು',
'ಸಂಜೆಗವಿ',
'ಸಂಜೆಗಳ್ಳ',
'ಸಂಜೆಬಾಳೆ',
'ಸಂಜೆಗೆಂಪು',
'ಸಂಜೆಮಂತ್ರ',
'ಸಂಜೆಮಟ',
'ಸಂಜೆಮಲ್ಲೆ',
'ಸಂಜೆವರಿ',
'ಸಂಜೆವಾಸ',
'ಸಂಜೆವೊೞ್ತು',
'ಸಂಜೆಹೊತ್ತು',
'ಸಂಜೋಗಿಸು',
'ಸಂಜೋಡಿಸು',
'ಸಂಜ್ಞಪನ',
'ಸಂಜ್ಞಿಜೀವ',
'ಸಂಜ್ವಲನ',
'ಸಂಜ್ವಲಿತ',
'ಸಂಜ್ವಳನ',
'ಸಂತಪನ',
'ಸಂತಪಿಸು',
'ಸಂತಮಸ',
'ಸಂತಮಿಡು',
'ಸಂತಮಿರ್',
'ಸಂತಮಿರು',
'ಸಂತಯಣ',
'ಸಂತಯಿಕೆ',
'ಸಂತಯಿಸು',
'ಸಂತಯ್ಸುಹ',
'ಸಂತರಣ',
'ಸಂತರಿಸು',
'ಸಂತರ್ಜನ',
'ಸಂತರ್ಜಿತ',
'ಸಂತರ್ಜಿಸು',
'ಸಂತರ್ಪಣ',
'ಸಂತರ್ಪಣೆ',
'ಸಂತರ್ಪಿತ',
'ಸಂತರ್ಪಿಸು',
'ಸಂತವಣ',
'ಸಂತವಣೆ',
'ಸಂತವಿಕ್ಕು',
'ಸಂತವಿಡು',
'ಸಂತವಿರು',
'ಸಂತವಿಸು',
'ಸಂತಸಿಕೆ',
'ಸಂತಸಿಗ',
'ಸಂತಾನಕ',
'ಸಂತಾನಸ್ಥ',
'ಸಂತಾನಿಕೆ',
'ಸಂತಾಪದ',
'ಸಂತಾಪನ',
'ಸಂತಾಪಿತ',
'ಸಂತಾಪಿಸು',
'ಸಂತುಷ್ಟಿಸು',
'ಸಂತೆಸೂಳೆ',
'ಸಂತೆಹೇಳು',
'ಸಂತೋಷಣ',
'ಸಂತೋಷಿತ',
'ಸಂತೋಷಿಸು',
'ಸಂತ್ರಾಸಕ',
'ಸಂತ್ವರಿತ',
'ಸಂದಟಿತ',
'ಸಂದಟಿಸು',
'ಸಂದಣಿಗ',
'ಸಂದಣಿಸು',
'ಸಂದರ್ಭಿತ',
'ಸಂದರ್ಭಿಸು',
'ಸಂದರ್ಶನ',
'ಸಂದರ್ಶಿತ',
'ಸಂದರ್ಶಿತೆ',
'ಸಂದರ್ಶಿಸು',
'ಸಂದಲಿಸು',
'ಸಂದವಣಿ',
'ಸಂದಳಿಸು',
'ಸಂದಾನಿತ',
'ಸಂದಾನಿಸು',
'ಸಂದಾಯಕ',
'ಸಂದಾಯಕಿ',
'ಸಂದಾಯತ',
'ಸಂದಾಯಿತ',
'ಸಂದಿಗ್ಧತೆ',
'ಸಂದಿಗ್ಧಾರ್ಥ',
'ಸಂದಿವಾತ',
'ಸಂದೀಪಿತ',
'ಸಂದುಕಟ್ಟು',
'ಸಂದುಕಡಿ',
'ಸಂದುಕೊಡು',
'ಸಂದುಗಟ್ಟು',
'ಸಂದುಗೊಡು',
'ಸಂದುಗೊಯ್ಕ',
'ಸಂದುಬಳ್ಳಿ',
'ಸಂದುಬೀಳು',
'ಸಂದುಬೇನೆ',
'ಸಂದುರೋಗ',
'ಸಂದುವಡೆ',
'ಸಂದುವಾತ',
'ಸಂದುವಿಡು',
'ಸಂದೆರಂಗು',
'ಸಂದೇಶಿಸು',
'ಸಂದೇಹಿಸು',
'ಸಂದೋಹಕ',
'ಸಂದೋಹನ',
'ಸಂಧರಣ',
'ಸಂಧರಿಸು',
'ಸಂಧಾನಿತ',
'ಸಂಧಾನಿಸು',
'ಸಂಧಾರಣ',
'ಸಂಧಾರಿತ',
'ಸಂಧಿಕಾರ್ಯ',
'ಸಂಧಿಕಾಲ',
'ಸಂಧಿಗೆಯ್',
'ಸಂಧಿದೋಷ',
'ಸಂಧಿಪದಿ',
'ಸಂಧಿಬಂಧ',
'ಸಂಧಿವಾತ',
'ಸಂಧಿಸ್ವರ',
'ಸಂಧುಕ್ಷಿತ',
'ಸಂಧ್ಯಕ್ಷರ',
'ಸಂಧ್ಯಾಕಾಲ',
'ಸಂಧ್ಯಾತಮ',
'ಸಂಧ್ಯಾತ್ರಯ',
'ಸಂಧ್ಯಾರಾಗ',
'ಸಂಧ್ಯಾರುಚಿ',
'ಸಂಧ್ಯಾರುಣ',
'ಸಂಧ್ಯಾವಳಿ',
'ಸಂಧ್ಯೋಪಾಸ್ತಿ',
'ಸಂನ್ಯಸನ',
'ಸಂನ್ಯಾಸಿನಿ',
'ಸಂಪದವಿ',
'ಸಂಪದ್ಯುಕ್ತ',
'ಸಂಪದ್ಯುಕ್ತೆ',
'ಸಂಪನ್ನತೆ',
'ಸಂಪನ್ನಿಕೆ',
'ಸಂಪನ್ಮೂಲ',
'ಸಂಪರಾಯ',
'ಸಂಪರ್ಕಿಸು',
'ಸಂಪಳಗ',
'ಸಂಪಳಿಸು',
'ಸಂಪಾತನ',
'ಸಂಪಾದಕ',
'ಸಂಪಾದಕಿ',
'ಸಂಪಾದನ',
'ಸಂಪಾದನೆ',
'ಸಂಪಾದಿತ',
'ಸಂಪಾದಿಸು',
'ಸಂಪಾಳಿಸು',
'ಸಂಪುಟಕ',
'ಸಂಪುಟಿಸು',
'ಸಂಪೂಜಿತ',
'ಸಂಪೂಜಿತೆ',
'ಸಂಪೂರಿಮ',
'ಸಂಪೂರ್ಣತೆ',
'ಸಂಪೂರ್ಣತ್ವ',
'ಸಂಪೂರ್ಣೇಂದು',
'ಸಂಪ್ರತ್ಯಯ',
'ಸಂಪ್ರಥಿತ',
'ಸಂಪ್ರದಾನ',
'ಸಂಪ್ರದಾಯ',
'ಸಂಪ್ರಮೋದ',
'ಸಂಪ್ರಯೋಗ',
'ಸಂಪ್ರಸರ',
'ಸಂಪ್ರಹಾರ',
'ಸಂಪ್ರಾಪ್ತಿಸು',
'ಸಂಪ್ರೋಕ್ಷಣ',
'ಸಂಪ್ರೋಕ್ಷಣೆ',
'ಸಂಪ್ರೋಕ್ಷಿಸು',
'ಸಂಪ್ಲವನ',
'ಸಂಪ್ಲವಿಸು',
'ಸಂಫುಲ್ಲಾಕ್ಷ',
'ಸಂಬಂಧಕ',
'ಸಂಬಂಧಿಕ',
'ಸಂಬಂಧಿತ',
'ಸಂಬಂದಿತ',
'ಸಂಬಂದಿತೆ',
'ಸಂಬಂಧಿಸು',
'ಸಂಬಯಸು',
'ಸಂಬರೇಣಿ',
'ಸಂಬಳಿಗ',
'ಸಂಬಳಿಗೆ',
'ಸಂಬಳಿಸು',
'ಸಂಬಾಳಿಸು',
'ಸಂಬಿಯಾರ',
'ಸಂಬುನಾಥ',
'ಸಂಬೆನೆಲ್ಲು',
'ಸಂಬೋಧನ',
'ಸಂಬೋಧನೆ',
'ಸಂಬೋಧಿಸು',
'ಸಂಭರಣ',
'ಸಂಭವಿಸು',
'ಸಂಭಾರಣೆ',
'ಸಂಭಾವನೆ',
'ಸಂಭಾವಿತ',
'ಸಂಭಾವಿತೆ',
'ಸಂಭಾವಿಸು',
'ಸಂಭಾವ್ಯತೆ',
'ಸಂಭಾಷಣ',
'ಸಂಭಾಷಣೆ',
'ಸಂಭಾಷಿಸು',
'ಸಂಭಾಳಿಕೆ',
'ಸಂಭಾಳಿಸು',
'ಸಂಭೋಗಿಸು',
'ಸಂಭ್ರಮಿತ',
'ಸಂಭ್ರಮಿತೆ',
'ಸಂಭ್ರಮಿಸು',
'ಸಂಯಮನ',
'ಸಂಯಮಿಸು',
'ಸಂಯೋಗಿಸು',
'ಸಂಯೋಜಕ',
'ಸಂಯೋಜಕಿ',
'ಸಂಯೋಜನ',
'ಸಂಯೋಜನೆ',
'ಸಂಯೋಜಿತ',
'ಸಂಯೋಜಿಸು',
'ಸಂರಕ್ಷಕ',
'ಸಂರಕ್ಷಕಿ',
'ಸಂರಕ್ಷಣೆ',
'ಸಂರಕ್ಷಿಸು',
'ಸಂರೇಚಕ',
'ಸಂಲಾಪಕ',
'ಸಂಲೀನತೆ',
'ಸಂವಚರ',
'ಸಂವಚಿಸು',
'ಸಂವಚ್ಚರ',
'ಸಂವಚ್ಛರ',
'ಸಂವಡ್ಡಿಯ',
'ಸಂವತ್ಸರ',
'ಸಂವದನ',
'ಸಂವನನ',
'ಸಂವರಣ',
'ಸಂವರಣೆ',
'ಸಂವರಿಸು',
'ಸಂವರ್ಣನೆ',
'ಸಂವರ್ಣಿಸು',
'ಸಂವರ್ತಕ',
'ಸಂವರ್ತನ',
'ಸಂವರ್ತಿಕೆ',
'ಸಂವರ್ಧಕ',
'ಸಂವರ್ಧನ',
'ಸಂವರ್ಧನೆ',
'ಸಂವಲನ',
'ಸಂವಲಿತ',
'ಸಂವಸಥ',
'ಸಂವಹನ',
'ಸಂವಹಿಸು',
'ಸಂವಳನ',
'ಸಂವಳಿತ',
'ಸಂವಳಿಸು',
'ಸಂವಾದಾರ್ಥಿ',
'ಸಂವಾದಿತ್ವ',
'ಸಂವಾದಿಸು',
'ಸಂವಾಹಕ',
'ಸಂವಾಹನ',
'ಸಂವಿಚ್ಛೂನ್ಯ',
'ಸಂವಿದಾನ',
'ಸಂವಿದಿತ',
'ಸಂವಿದ್ರುತ',
'ಸಂವಿದ್ರೂಪ',
'ಸಂವಿಧಾನ',
'ಸಂವಿಭಾಗ',
'ಸಂವೀಕ್ಷಣ',
'ಸಂವೀಕ್ಷಿಸು',
'ಸಂವೀಜಿತ',
'ಸಂವೃಜಿತ',
'ಸಂವೇದನ',
'ಸಂವೇದನೆ',
'ಸಂವೇದಿಸು',
'ಸಂವೇಶನ',
'ಸಂವೇಶಿಸು',
'ಸಂವೇಷ್ಟನ',
'ಸಂಶಪ್ತಕ',
'ಸಂಶಯಾತ್ಮ',
'ಸಂಶಯಿತ',
'ಸಂಶಯಿತೆ',
'ಸಂಶಯಿಸು',
'ಸಂಶೋಧಕ',
'ಸಂಶೋಧಕಿ',
'ಸಂಶೋಧನ',
'ಸಂಶೋಧನೆ',
'ಸಂಶೋಧಿಸು',
'ಸಂಶೋಭಿಸು',
'ಸಂಶೋಷಿತ',
'ಸಂಶ್ರಯಣ',
'ಸಂಶ್ಲೇಷಣ',
'ಸಂಶ್ಲೇಷಣೆ',
'ಸಂಶ್ಲೇಷಿಸು',
'ಸಂಸಂಗಿನಿ',
'ಸಂಸತ್ಸಭೆ',
'ಸಂಸದೀಯ',
'ಸಂಸಪ್ತಕ',
'ಸಂಸರಣ',
'ಸಂಸರಿಸು',
'ಸಂಸರ್ಗಿಸು',
'ಸಂಸರ್ಪತ್ತು',
'ಸಂಸಾರಕ',
'ಸಂಸಾರಸ್ಥ',
'ಸಂಸಾರಾತ್ಮ',
'ಸಂಸಾರಿಕ',
'ಸಂಸಾರಿಗ',
'ಸಂಸಾರಿಸು',
'ಸಂಸಿದ್ಧಿಸು',
'ಸಂಸೇವಿತ',
'ಸಂಸೇವಿತೆ',
'ಸಂಸ್ಕರಣ',
'ಸಂಸ್ಕರಣೆ',
'ಸಂಸ್ಕರಿಸು',
'ಸಂಸ್ಕಾರಿಸು',
'ಸಂಸ್ಕೃತಿಸು',
'ಸಂಸ್ಖಲಿತ',
'ಸಂಸ್ತವನ',
'ಸಂಸ್ತುತಿಸು',
'ಸಂಸ್ಥಾನಕ',
'ಸಂಸ್ಥಾನಿಕ',
'ಸಂಸ್ಥಾಪಕ',
'ಸಂಸ್ಥಾಪಕಿ',
'ಸಂಸ್ಥಾಪನ',
'ಸಂಸ್ಥಾಪನೆ',
'ಸಂಸ್ಥಾಪಿಸು',
'ಸಂಸ್ಪರ್ಶನ',
'ಸಂಸ್ಫಾಳಿತ',
'ಸಂಸ್ಮರಣ',
'ಸಂಸ್ಮರಣೆ',
'ಸಂಸ್ಮರಿಸು',
'ಸಂಹನನ',
'ಸಂಹರಣ',
'ಸಂಹರಿಸು',
'ಸಂಹಾರಕ',
'ಸಂಹಾರಿಸು',
'ಸಕಂಟಕ',
'ಸಕರ್ಮಕ',
'ಸಕಲಂಕ',
'ಸಕಲಜ್ಞ',
'ಸಕಲಜ್ಞೆ',
'ಸಕಲರ್ತು',
'ಸಕಲಾತಿ',
'ಸಕಲಾತು',
'ಸಕಲಾಯ',
'ಸಕಲೇಶ',
'ಸಕಷಾಯ',
'ಸಕಳಂಕ',
'ಸಕಳಜ್ಞ',
'ಸಕಳಜ್ಞೆ',
'ಸಕಳೇಶ',
'ಸಕಾರಣ',
'ಸಕಾಲಿಕ',
'ಸಕೀಲಕ',
'ಸಕುಟಿಲ',
'ಸಕುಟಿಳ',
'ಸಕುಟುಂಬ',
'ಸಕುಟುಂಬಿ',
'ಸಕುನಿಗ',
'ಸಕೃತ್ಪ್ರಜ',
'ಸಕೃದ್ಗ್ರಾಹಿ',
'ಸಕೋಟಕಿ',
'ಸಕ್ಕರಿಗ',
'ಸಕ್ಕರೆಗ',
'ಸಕ್ಖರೆಗ',
'ಸಕ್ತುಪಿಷ್ಟ',
'ಸಕ್ತುಫಲಿ',
'ಸಕ್ತುಫಲೆ',
'ಸಕ್ರಿಯತೆ',
'ಸಕ್ಷಮತೆ',
'ಸಗಣಿಗ',
'ಸಗರ್ವಿತ',
'ಸಗರ್ವಿತೆ',
'ಸಗಾಟಿಕೆ',
'ಸಗಾಟಿಸು',
'ಸಗಾಡಿಕೆ',
'ಸಗಾಢಿಕೆ',
'ಸಗಾಢಿಸು',
'ಸಗುತ್ತಿತ್ತೆ',
'ಸಗ್ಗದುರು',
'ಸಗ್ಗದುಱು',
'ಸಗ್ಗದೊರೆ',
'ಸಗ್ಗದೊಱೆ',
'ಸಗ್ಗಬಗ್ಗ',
'ಸಗ್ಗವೆಣ್',
'ಸಗ್ಗವೆಣ್ಣು',
'ಸಗ್ಗವೊಳೆ',
'ಸಗ್ಗವೊೞೆ',
'ಸಗ್ಗಹೆಣ್',
'ಸಘಾಟಿಕೆ',
'ಸಘಾಡಿಕೆ',
'ಸಘಾನತೆ',
'ಸಚಾಚರ',
'ಸಚಿವತೆ',
'ಸಚೇತಕ',
'ಸಚೇತನ',
'ಸಚೇತನೆ',
'ಸಚ್ಚರಿತ',
'ಸಚ್ಚರಿತೆ',
'ಸಚ್ಚರಿತ್ರ',
'ಸಚ್ಚೇತನ',
'ಸಜಲತೆ',
'ಸಜಾತೀಯ',
'ಸಜ್ಜಂಬಡೆ',
'ಸಜ್ಜನಿಕೆ',
'ಸಜ್ಜರಸ',
'ಸಜ್ಜಾತಿತ್ವ',
'ಸಜ್ಜಿಖಾರ',
'ಸಜ್ಜೀಕಾರ',
'ಸಜ್ಜೀಕೃತ',
'ಸಜ್ಜುಗೆಯ್',
'ಸಜ್ಜುಗೊಳ್',
'ಸಜ್ಜುಗೊಳ್ಳು',
'ಸಜ್ಜುವಡೆ',
'ಸಜ್ಜೆಮನೆ',
'ಸಜ್ಜೆವನೆ',
'ಸಜ್ಜೆವಳ',
'ಸಟಕ್ಕನೆ',
'ಸಟವಾಯಿ',
'ಸಟೆಗಾರ',
'ಸಟೆಗಾರ್ತಿ',
'ಸಟೆಮಾತು',
'ಸಟ್ಟಾಮಾರು',
'ಸಡಗರ',
'ಸಡಪುಡ',
'ಸಡಲಿಸು',
'ಸಡಿನುಡಿ',
'ಸಡಿಫಡ',
'ಸಡಿಲಾಳ್ಕೆ',
'ಸಡಿಲಿಕೆ',
'ಸಡಿಲಿಸು',
'ಸಡಿಲ್ವಿಡಿ',
'ಸಡುಗುಡ',
'ಸಡುಗುಡು',
'ಸಡ್ಡುಗಾಣ್',
'ಸಡ್ಡುಗೊಳ್ಳು',
'ಸಡ್ಡುಹೊಡೆ',
'ಸಡ್ಡೆಕೆಡು',
'ಸಡ್ಡೆಗಿಡು',
'ಸಡ್ಡೆಗೊಳ್',
'ಸಡ್ಡೆಗೊಳು',
'ಸಣಕಲ',
'ಸಣಕಲಿ',
'ಸಣಕಲು',
'ಸಣಕಲೆ',
'ಸಣಗಜ್ಜಿ',
'ಸಣಿವಾರ',
'ಸಣ್ಣಇಪ್ಪೆ',
'ಸಣ್ಣಊಗು',
'ಸಣ್ಣಕಾಚು',
'ಸಣ್ಣಕಾಡಿ',
'ಸಣ್ಣಕಾರೆ',
'ಸಣ್ಣಕೈಂಕ',
'ಸಣ್ಣಕ್ಷರ',
'ಸಣ್ಣಗಣ್ಣ',
'ಸಣ್ಣಗಾರೆ',
'ಸಣ್ಣಗಿರಿ',
'ಸಣ್ಣಗೋಜಿ',
'ಸಣ್ಣಜಾಜಿ',
'ಸಣ್ಣಣಿಲ್',
'ಸಣ್ಣತನ',
'ಸಣ್ಣನಿದ್ದೆ',
'ಸಣ್ಣನೆಯ',
'ಸಣ್ಣಬತ್ತ',
'ಸಣ್ಣಭತ್ತ',
'ಸಣ್ಣಮಾತು',
'ಸಣ್ಣಮುಡಿ',
'ಸಣ್ಣಲಬು',
'ಸಣ್ಣವಟ್ಟೆ',
'ಸಣ್ಣಸಸಿ',
'ಸಣ್ಣೀಚಲು',
'ಸಣ್ಣೇಲಕ್ಕಿ',
'ಸತತಕ',
'ಸತಾಯಿಸು',
'ಸತಿತನ',
'ಸತೀದೇವಿ',
'ಸತೀಧರ್ಮ',
'ಸತೀನಕ',
'ಸತೀಲಕ',
'ಸತುಗುಂದು',
'ಸತುಗೆಡು',
'ಸತ್ಕರಣೆ',
'ಸತ್ಕರಿಸು',
'ಸತ್ಕುಲಜ',
'ಸತ್ಕುಲಜೆ',
'ಸತ್ಕುಲತೆ',
'ಸತ್ಕುಲೀನ',
'ಸತ್ಕುಲೀನೆ',
'ಸತ್ಕ್ರಿವಂತ',
'ಸತ್ತುತೆಗೆ',
'ಸತ್ತ್ವಗುಣ',
'ಸತ್ತ್ವಶಾಲಿ',
'ಸತ್ಪುರುಷ',
'ಸತ್ಪ್ರಣೀತ',
'ಸತ್ಯಂಕಾರ',
'ಸತ್ಯಕಾಮ',
'ಸತ್ಯದೂರ',
'ಸತ್ಯದೃಷ್ಟಿ',
'ಸತ್ಯಪಾಠ',
'ಸತ್ಯಮಂತ',
'ಸತ್ಯಯುಗ',
'ಸತ್ಯರತ',
'ಸತ್ಯರತೆ',
'ಸತ್ಯಲೋಕ',
'ಸತ್ಯವಂತ',
'ಸತ್ಯವಂತೆ',
'ಸತ್ಯವತಿ',
'ಸತ್ಯವಾಕ್ಯ',
'ಸತ್ಯವಾದಿ',
'ಸತ್ಯವೇದಿ',
'ಸತ್ಯವ್ರತ',
'ಸತ್ಯಶೀಲ',
'ಸತ್ಯಶೀಲೆ',
'ಸತ್ಯಶುದ್ಧ',
'ಸತ್ಯಸಂಧ',
'ಸತ್ಯಸಂಧೆ',
'ಸತ್ಯಹೀನ',
'ಸತ್ಯಹೀನೆ',
'ಸತ್ಯಾಗ್ರಹ',
'ಸತ್ಯಾಗ್ರಹಿ',
'ಸತ್ಯಾನ್ವೇಷಿ',
'ಸತ್ಯಾಭಾಸ',
'ಸತ್ಯಾಶ್ರಯ',
'ಸತ್ರಯಾಗ',
'ಸತ್ರಶಾಲೆ',
'ಸತ್ರಾಣಿಸು',
'ಸತ್ವಗುಂದು',
'ಸತ್ವಗುಣ',
'ಸತ್ವಗೆಡು',
'ಸತ್ವಭಸ್ಮ',
'ಸತ್ವಶಾಲಿ',
'ಸತ್ವಹೀನ',
'ಸತ್ವಹೀನೆ',
'ಸತ್ವಾಧಿಕ',
'ಸತ್ವಾಪನ್ನ',
'ಸತ್ಸಂತಾನ',
'ಸತ್ಸಹಾಯ',
'ಸತ್ಸ್ವಭಾವ',
'ಸದಕುಪ್ಪಿ',
'ಸದಗುಟ್ಟು',
'ಸದಗುಪ್ಪೆ',
'ಸದಮದ',
'ಸದಮಲ',
'ಸದಮಲೆ',
'ಸದಮಳ',
'ಸದಮಳೆ',
'ಸದರಂಗಿ',
'ಸದರಹು',
'ಸದಸತ್ತು',
'ಸದಸ್ಯತ್ವ',
'ಸದಳಿಗ',
'ಸದಾಕಾಲ',
'ಸದಾಕಾಲಂ',
'ಸದಾಗತ',
'ಸದಾಗತಿ',
'ಸದಾಚಾರ',
'ಸದಾಚಾರಿ',
'ಸದಾನಂದ',
'ಸದಾನಂದಿ',
'ಸದಾಪುಷ್ಪ',
'ಸದಾಬರ್ತ್',
'ಸದಾರುಣ',
'ಸದಾವರ್ತು',
'ಸದಾವ್ರತ',
'ಸದಾಶಯ',
'ಸದಾಶಿವ',
'ಸದುಗತಿ',
'ಸದುಗುಣ',
'ಸದುಗುಣಿ',
'ಸದುಗುರು',
'ಸದುಗೋಷ್ಠಿ',
'ಸದಬೀೞ್',
'ಸದುಭಕ್ತ',
'ಸದುಭಕ್ತಿ',
'ಸದೃಶತೆ',
'ಸದೃಶತ್ವ',
'ಸದೆಗುಟ್ಟು',
'ಸದೆಗುಪ್ಪೆ',
'ಸದೆಬಡಿ',
'ಸದೆಯಿಸು',
'ಸದೋದಿತ',
'ಸದ್ಗಂಭೀರ',
'ಸದ್ಗುಣೈಕ್ಯ',
'ಸದ್ಗೃಹತ್ವ',
'ಸದ್ಗೈಹಸ್ಥ',
'ಸದ್ದಂಗಿಡು',
'ಸದ್ದಡಗು',
'ಸದ್ದಮುದ್ದು',
'ಸದ್ದಾಮುದ್ದಾ',
'ಸದ್ದಿವಾಲ',
'ಸದ್ದೀವಾಲ',
'ಸದ್ದುಗಿಡು',
'ಸದ್ದುಮುದ್ದು',
'ಸದ್ಧರ್ಮತೆ',
'ಸದ್ಧಾರ್ಮಿಕ',
'ಸದ್ಭಾವನ',
'ಸದ್ಭಾವನೆ',
'ಸದ್ಯಃಫಲ',
'ಸದ್ಯಕ್ಷಾರ',
'ಸದ್ಯಸ್ತನ',
'ಸದ್ಯೋಘೃತ',
'ಸದ್ಯೋಜಾತ',
'ಸದ್ಯೋನ್ಮುಕ್ತ',
'ಸದ್ಯೋನ್ಮುಕ್ತಿ',
'ಸದ್ಯೋನ್ಮುಕ್ತೆ',
'ಸದ್ಯೋಪಾಯ',
'ಸದ್ಯೋಮುಕ್ತಿ',
'ಸದ್ಯೋವಧು',
'ಸದ್ವಾಸನೆ',
'ಸದ್ವಿವೇಕ',
'ಸದ್ವ್ಯಾಪಾರ',
'ಸನಂದನ',
'ಸನಮಂತ',
'ಸನಾಡಿಕಾ',
'ಸನಾತನ',
'ಸನಾತನಿ',
'ಸನಾಥತೆ',
'ಸನಾಥತ್ವ',
'ಸನದಿಕಾ',
'ಸನಾಮಕ',
'ಸನಿಗೊಳ್',
'ಸನಿವಾರ',
'ಸನುನಯ',
'ಸನುಮಂತ',
'ಸನುಮತ',
'ಸನುಮಾನ',
'ಸನುರುಚಿ',
'ಸನೆಗಾರ',
'ಸನಯಗೈ',
'ಸನ್ನಡತೆ',
'ಸನ್ನದ್ಧತೆ',
'ಸನ್ನಯಿಸು',
'ಸನ್ನಲಿಗೆ',
'ಸನ್ನಹನ',
'ಸನ್ನಹಿತ',
'ಸನ್ನಹಿಸು',
'ಸನ್ನಿಕಟ',
'ಸನ್ನಿಕರ್ಷ',
'ಸನ್ನಿಕಾಶ',
'ಸನ್ನಿಕೃಷ್ಟ',
'ಸನ್ನಿಗಿಡ',
'ಸನ್ನಿಘೃಷ್ಟ',
'ಸನ್ನಿದೋಷ',
'ಸನ್ನಿಧಾನ',
'ಸನ್ನಿಪಾತ',
'ಸನ್ನಿಬಂಧ',
'ಸನ್ನಿವಡು',
'ಸನ್ನಿವಿಷ್ಟ',
'ಸನ್ನಿವೇಶ',
'ಸನ್ನಿಹಿತ',
'ಸನ್ನಿಹಿತೆ',
'ಸನ್ನುತಿಸು',
'ಸನ್ನುಮತ',
'ಸನ್ನೆಗಣ್',
'ಸನ್ನೆಗಾಳೆ',
'ಸನ್ನೆಗೂಗು',
'ಸನ್ನೆಗೆಡು',
'ಸನ್ನೆಗೆಯ್',
'ಸನ್ನೆಗೋಲು',
'ಸನ್ನೆದೋರು',
'ಸನ್ನೆನೋಟ',
'ಸನ್ನೆಮಾತು',
'ಸನ್ನೆವಱೆ',
'ಸನ್ನೆವಾತು',
'ಸನ್ನೊಸಗೆ',
'ಸನ್ಮಥನ',
'ಸನ್ಮಹಿತ',
'ಸನ್ಮಾನತೆ',
'ಸನ್ಮಾನಿತ',
'ಸನ್ಮಾನಿತೆ',
'ಸನ್ಮಾನಿಸು',
'ಸನ್ಮೋಹನ',
'ಸನ್ಮೋಹಿಸು',
'ಸನ್ಯಸನ',
'ಸನ್ಯಾಸನ',
'ಸನ್ಯಾಸಿನಿ',
'ಸಪತ್ನೀಕ',
'ಸಪುತಮಿ',
'ಸಪ್ತಋಷಿ',
'ಸಪ್ತಗುಣ',
'ಸಪ್ತಗುಪ್ತಿ',
'ಸಪ್ತಚ್ಛದ',
'ಸಪ್ತತಂತು',
'ಸಪ್ತತಂತ್ರಿ',
'ಸಪ್ತತತ್ತ್ವ',
'ಸಪ್ತದ್ವೀಪ',
'ಸಪ್ತಧಾತು',
'ಸಪ್ತನಾದ',
'ಸಪ್ತಪದಿ',
'ಸಪ್ತಪರ್ಣ',
'ಸಪ್ತಪರ್ಣಿ',
'ಸಪ್ತಪುರಿ',
'ಸಪ್ತಮದ',
'ಸಪ್ತಲೋಕ',
'ಸಪ್ತಶೈವ',
'ಸಪ್ತಶ್ರುತಿ',
'ಸಪ್ತಸಪ್ತಿ',
'ಸಪ್ತಸೂತ್ರ',
'ಸಪ್ತಸ್ವರ',
'ಸಪ್ತಾನೀಕ',
'ಸಪ್ತಾಹತ',
'ಸಪ್ತಾಹುತಿ',
'ಸಪ್ತೋಪಾಯ',
'ಸಪ್ಪಳಿಗ',
'ಸಪ್ಪೆಗರೆ',
'ಸಪ್ಪೆತನ',
'ಸಪ್ಪೆತುಪ್ಪ',
'ಸಪ್ಪೆದಾರ',
'ಸಪ್ಪೆದುಪ್ಪ',
'ಸಪ್ಪೆನಗೆ',
'ಸಪ್ಪೆನೂಲು',
'ಸಪ್ಪೆಮುಖ',
'ಸಪ್ಪೆಮೋರೆ',
'ಸಪ್ರಪಂಚಂ',
'ಸಪ್ರಮಾಣ',
'ಸಪ್ರಯಾಸ',
'ಸಪ್ರಯಾಸಂ',
'ಸಪ್ರಾಣಿಸು',
'ಸಫಲತೆ',
'ಸಫಲಿತ',
'ಸಬಕಾರ',
'ಸಬತಿಗೆ',
'ಸಬನೀಸು',
'ಸಬಳಿಗ',
'ಸಬಿಂದುಕ',
'ಸಬುಕಾರ',
'ಸಬ್ಜಾಗಿಡ',
'ಸಬ್ಬಗತ',
'ಸಬ್ಬನಿಸ',
'ಸಬ್ಬಲಗ್ಗೆ',
'ಸಬ್ಬಸಗಿ',
'ಸಬ್ಬಸಗೆ',
'ಸಬ್ಬಸಿಕೆ',
'ಸಬ್ಬಸಿಗೆ',
'ಸಬ್ಬಸೀಗೆ',
'ಸಬ್ಬೆಱೆಯ',
'ಸಭರಿತ',
'ಸಭರ್ತೃಕೆ',
'ಸಭಾಕಂಪ',
'ಸಭಾಗೃಹ',
'ಸಭಾಗೇಹ',
'ಸಭಾಜನ',
'ಸಭಾತ್ಯಾಗ',
'ಸಭಾಧ್ಯಕ್ಷ',
'ಸಭಾಪತಿ',
'ಸಭಾಪರ್ವ',
'ಸಭಾಪೂಜೆ',
'ಸಭಾವಿಷ್ಟ',
'ಸಭಾಶೂರ',
'ಸಭಾಸದ',
'ಸಭಾಸದೆ',
'ಸಭಾಸದ್ಮ',
'ಸಭಾಸೂರ',
'ಸಭಾಸ್ತಾರ',
'ಸಭಾಸ್ಥಳ',
'ಸಭೆಕೂಡು',
'ಸಭೆಗೂಡು',
'ಸಭೆಗೊಡು',
'ಸಭ್ಯತನ',
'ಸಮಂಗಾಣ್',
'ಸಮಂಚಿತ',
'ಸಮಂಜಸ',
'ಸಮಂತಿಡು',
'ಸಮಂದರ್',
'ಸಮಂನುಡಿ',
'ಸಮಂಬಡೆ',
'ಸಮಕಕ್ಷ',
'ಸಮಕಟ್ಟು',
'ಸಮಕಲು',
'ಸಮಕಾಣು',
'ಸಮಕಾಲ',
'ಸಮಕೂಟ',
'ಸಮಕೂಡು',
'ಸಮಕೃಷ್ಟ',
'ಸಮಕೊಳ್',
'ಸಮಕ್ಷಮ',
'ಸಮಗಾರ',
'ಸಮಗೊಳ್',
'ಸಮಗ್ರತೆ',
'ಸಮಚಿತ್ತ',
'ಸಮಚ್ಛಾಯೆ',
'ಸಮಜಾತಿ',
'ಸಮಜೀವಿ',
'ಸಮಜೂತಿ',
'ಸಮಜೂತು',
'ಸಮಜೋಡಿ',
'ಸಮಜೋಳಿ',
'ಸಮಜ್ವರ',
'ಸಮಟಿಗೆ',
'ಸಮಡೇರಾ',
'ಸಮಣ್ವಾೞ್',
'ಸಮತಟ್ಟು',
'ಸಮತಲ',
'ಸಮತಳ',
'ಸಮತೂಕ',
'ಸಮತೋಲ',
'ಸಮದಂಡಿ',
'ಸಮದರ್ಶಿ',
'ಸಮದೂಗು',
'ಸಮದೃಷ್ಟಿ',
'ಸಮದೋಷಿ',
'ಸಮಧಾತು',
'ಸಮಧಿಕ',
'ಸಮನಸ್ಕ',
'ಸಮನಿಕೆ',
'ಸಮನಿಸು',
'ಸಮನ್ವಯ',
'ಸಮನ್ವಿತ',
'ಸಮನ್ವೀತ',
'ಸಮಪಾದ',
'ಸಮಪಾಳಿ',
'ಸಮಪಾೞಿ',
'ಸಮಬಂಧ',
'ಸಮಬಂಧು',
'ಸಮಭಾವ',
'ಸಮಯತಿ',
'ಸಮಯಿಸು',
'ಸಮರಂಗ',
'ಸಮರತಿ',
'ಸಮರಥ',
'ಸಮರಸ',
'ಸಮರಾಣಿ',
'ಸಮರ್ಚನ',
'ಸಮರ್ಚನೆ',
'ಸಮರ್ಚಿಸು',
'ಸಮರ್ಥಕ',
'ಸಮರ್ಥಕಿ',
'ಸಮರ್ಥತೆ',
'ಸಮರ್ಥನ',
'ಸಮರ್ಥನೆ',
'ಸಮರ್ಥಿಕೆ',
'ಸಮರ್ಥಿಸು',
'ಸಮರ್ಪಕ',
'ಸಮರ್ಪಣ',
'ಸಮರ್ಪಣೆ',
'ಸಮರ್ಪಿತ',
'ಸಮರ್ಪಿಸು',
'ಸಮವಯ',
'ಸಮವರ್ತಿ',
'ಸಮವಸ್ತ್ರ',
'ಸಮವಾಕ್ಯ',
'ಸಮವಾಯ',
'ಸಮವಿಡು',
'ಸಮವೃತ್ತ',
'ಸಮವೃತ್ತಿ',
'ಸಮವೆಱು',
'ಸಮವೇತ',
'ಸಮಸಲ್',
'ಸಮಸೂಚಿ',
'ಸಮಸ್ಕಂಧ',
'ಸಮಳ್ತಡೆ',
'ಸಮಱಿಸು',
'ಸಮಱುಗೆ',
'ಸಮಾಂತರ',
'ಸಮಾಕಾರ',
'ಸಮಾಕೀರ್ಣ',
'ಸಮಾಕೃಷ್ಟ',
'ಸಮಾಗತ',
'ಸಮಾಗಮ',
'ಸಮಾಘಾತ',
'ಸಮಾಚಾರ',
'ಸಮಾಧಾನ',
'ಸಮಾಧಾನಿ',
'ಸಮಾಧಿಸ್ಥ',
'ಸಮಾನತೆ',
'ಸಮಾನತ್ವ',
'ಸಮಾನಸ್ಥ',
'ಸಮಾಪನ',
'ಸಮಾಪ್ತಿಸು',
'ಸಮಾಮ್ನಾಯ',
'ಸಮಾಯೋಗ',
'ಸಮಾರಂಭ',
'ಸಮಾರೂಢ',
'ಸಮಾರೂಢೆ',
'ಸಮಾರೋಪ',
'ಸಮಾರೋಹ',
'ಸಮಾರ್ಚನ',
'ಸಮಾಲಂಭ',
'ಸಮಾಲೋಕ',
'ಸಮಾವಾಸ',
'ಸಮಾವಿಷ್ಟ',
'ಸಮಾವೇಶ',
'ಸಮಾಶ್ರಿತ',
'ಸಮಾಸಿಸು',
'ಸಮಾಸೋಕ್ತಿ',
'ಸಮಾಹಾರ',
'ಸಮಾಹಿತ',
'ಸಮಾಹೃತ',
'ಸಮಾಹೃತಿ',
'ಸಮಾಹ್ವಯ',
'ಸಮಾಳಿಸು',
'ಸಮಿಂಧನ',
'ಸಮೀಕೃತ',
'ಸಮೀಕ್ಷಕ',
'ಸಮೀಕ್ಷಕಿ',
'ಸಮೀಕ್ಷಣ',
'ಸಮೀಕ್ಷಣೆ',
'ಸಮೀಕ್ಷಿಸು',
'ಸಮೀಚೀನ',
'ಸಮೀಪಗ',
'ಸಮೀಪಸ್ಥ',
'ಸಮೀಪಿಸು',
'ಸಮೀರಣ',
'ಸಮೀರಿತ',
'ಸಮೀಹಿತ',
'ಸಮುಚಿತ',
'ಸಮುಚ್ಚಯ',
'ಸಮುಚ್ಛಿನ್ನ',
'ಸಮುಚ್ಛ್ರಯ',
'ಸಮುಚ್ಛ್ರಿತ',
'ಸಮುಜ್ಜ್ವಲ',
'ಸಮುಜ್ಜ್ವಳ',
'ಸಮುಜ್ಝಿತ',
'ಸಮುಜ್ವಲ',
'ಸಮುಜ್ವಳ',
'ಸಮುತ್ಕಟ',
'ಸಮುತ್ಕೀರ್ಣ',
'ಸಮುತ್ಪಾತ',
'ಸಮುತ್ತಾಳ',
'ಸಮುತ್ತುಂಗ',
'ಸಮುತ್ಥಾಂಶು',
'ಸಮುತ್ಥಾನ',
'ಸಮುತ್ಥಿತ',
'ಸಮುತ್ಪತ್ತಿ',
'ಸಮುತ್ಪನ್ನ',
'ಸಮುತ್ಪ್ಲುತ',
'ಸಮುತ್ಫುಲ್ಲ',
'ಸಮುತ್ಸುಕ',
'ಸಮುದಗ್ರ',
'ಸಮುದಯ',
'ಸಮುದಾಯ',
'ಸಮುದಿತ',
'ಸಮುದ್ಗಕ',
'ಸಮುದ್ಗತ',
'ಸಮುದ್ದಂಡ',
'ಸಮುದ್ಧತ',
'ಸಮುದ್ಧೃತ',
'ಸಮುದ್ಭಟ',
'ಸಮುದ್ಭವ',
'ಸಮುದ್ಭೂತ',
'ಸಮುದ್ಯೋಗ',
'ಸಮುದ್ರಜ',
'ಸಮುದ್ರಾಂತ',
'ಸಮುದ್ರಾಂತೆ',
'ಸಮುದ್ವೃತ್ತ',
'ಸಮುನ್ನತ',
'ಸಮುನ್ನತಿ',
'ಸಮುನ್ನದ್ಧ',
'ಸಮುಪಾತ್ತ',
'ಸಮುಪೇತ',
'ಸಮುಲ್ಲಾಸ',
'ಸಮೃದ್ಧತೆ',
'ಸಮೆಗಲ್ಲು',
'ಸಮೆಗೋಲು',
'ಸಮೆತರ್',
'ಸಮೆಯಿಸು',
'ಸಮ್ಮಂಗುಂದು',
'ಸಮ್ಮಂಗೊಡು',
'ಸಮ್ಮಂದಿಸು',
'ಸಮ್ಮಂಧಿಗ',
'ಸಮ್ಮಕಾರ',
'ಸಮ್ಮಗಾತಿ',
'ಸಮ್ಮಗಾರ',
'ಸಮ್ಮಗಾಱ',
'ಸಮ್ಮಟಿಗೆ',
'ಸಮ್ಮತಿಸು',
'ಸಮ್ಮಥಿಸು',
'ಸಮ್ಮದಿಸು',
'ಸಮ್ಮನಿಸು',
'ಸಮ್ಮರ್ದನ',
'ಸಮ್ಮಾನಿಸು',
'ಸಮ್ಮಾರ್ಜನ',
'ಸಮ್ಮಾರ್ಜನಿ',
'ಸಮ್ಮಾರ್ಜನೆ',
'ಸಮ್ಮಾರ್ಜಿತ',
'ಸಮ್ಮಾರ್ಜಿಸು',
'ಸಮ್ಮಾವುಗೆ',
'ಸಮ್ಮಿಲನ',
'ಸಮ್ಮಿಲಿತ',
'ಸಮ್ಮಿಶ್ರಣ',
'ಸಮ್ಮಿಳನ',
'ಸಮ್ಮಿಳಿತ',
'ಸಮ್ಮುದಿತ',
'ಸಮ್ಮೂಚ್ರ್ಛನೆ',
'ಸಮ್ಮೂರ್ಛನ',
'ಸಮ್ಮೇಲನ',
'ಸಮ್ಮೇಳನ',
'ಸಮ್ಮೇಳವ',
'ಸಮ್ಮೇಳಿಸು',
'ಸಮ್ಮೋಹಕ',
'ಸಮ್ಮೋಹನ',
'ಸಮ್ಮೋಹಿನಿ',
'ಸಮ್ಮೋಹಿಸು',
'ಸಮ್ಕಕ್ಛೀಲ',
'ಸಮ್ಯಗ್ಜ್ಞಾನ',
'ಸಮ್ಯಗ್ಜ್ಞಾನಿ',
'ಸಮ್ಯಗ್ದೃಷ್ಟಿ',
'ಸಮ್ಯಗ್ಭಕ್ತಿ',
'ಸಯಂಬರ',
'ಸಯದಾನ',
'ಸಯಧಾನ',
'ಸಯಿದಾನ',
'ಸಯಿಧಾನ',
'ಸಯುಕ್ತಿಕ',
'ಸಯ್ಗೆಡಪು',
'ಸಯ್ಗೆಡಹು',
'ಸಯ್ತಿರಿಸು',
'ಸಯ್ತೆಗಿಡು',
'ಸಯ್ತೆಣಿಸು',
'ಸಯ್ವಳಿಸ',
'ಸಯ್ವೆರಗು',
'ಸಯ್ವೆಱಗು',
'ಸರ್‍ಖಾಜಿ',
'ಸರ್‍ಸುಭ',
'ಸರಂಗುಡು',
'ಸರಂಗೆಯ್',
'ಸರಂಗೇಳ್',
'ಸರಂಗೊಳ್',
'ಸರಂಜಾಮ',
'ಸರಂಜಾಮು',
'ಸರಂದೆಗೆ',
'ಸರಂದೋಱು',
'ಸರಕಲು',
'ಸರಕಾರ',
'ಸರಕಾರಿ',
'ಸರಕಿಸು',
'ಸರಕುಟ',
'ಸರಕೂಟ',
'ಸರಕ್ಕನೆ',
'ಸರಗಂಟು',
'ಸರಗಂಟೆ',
'ಸರಗಟ್ಟು',
'ಸರಗತೆ',
'ಸರಗರಂ',
'ಸರಗಸ್ತು',
'ಸರಗಾಣ್',
'ಸರಗುದ್ದು',
'ಸರಗೆಯ್',
'ಸರಗೆಯ್ಯು',
'ಸರಗೊಡು',
'ಸರಗೊಳು',
'ಸರಗೋವಿ',
'ಸರಜಂಗು',
'ಸರಣ್ಬುಗು',
'ಸರಣ್ಬೊಗು',
'ಸರತಪ್ಪು',
'ಸರತಾತಿ',
'ಸರದಪ್ಪು',
'ಸರದಾರ',
'ಸರದಾಲಿ',
'ಸರದಾಳಿ',
'ಸರದೀನಿ',
'ಸರದೀಪ',
'ಸರದುಂಬಿ',
'ಸರದೂಳಿ',
'ಸರದೋಱು',
'ಸರನಿಧಿ',
'ಸರನೇಜ',
'ಸರನೇಜೆ',
'ಸರಪಂಚ',
'ಸರಪಣಿ',
'ಸರಪಳಿ',
'ಸರಬನ್ನೆ',
'ಸರಬರ',
'ಸರಬಲು',
'ಸರಭರ',
'ಸರಭಸ',
'ಸರಭಸಂ',
'ಸರಮಾಲೆ',
'ಸರಮೀನ',
'ಸರಮುತ್ತು',
'ಸರರಾಸು',
'ಸರರುಹ',
'ಸರಲತೆ',
'ಸರಲತ್ವ',
'ಸರಲೆಕ್ಕ',
'ಸರವಂದ',
'ಸರವಕ್ಕಿ',
'ಸರವಟ್ಟಿ',
'ಸರವಣ',
'ಸರವಣಿ',
'ಸರವತಿ',
'ಸರವತ್ತು',
'ಸರವರ',
'ಸರವಲೆ',
'ಸರವಾದಿ',
'ಸರವಿಡು',
'ಸರವೆತ್ತು',
'ಸರಸತಿ',
'ಸರಸತೆ',
'ಸರಸನ್ನೆ',
'ಸರಸರ',
'ಸರಸಾತ್ಮ',
'ಸರಸಿಜ',
'ಸರಸೀಜ',
'ಸರಸೋಕ್ತಿ',
'ಸರಸ್ವತಿ',
'ಸರಹದಿ',
'ಸರಹದ್ದು',
'ಸರಹಸ್ಯ',
'ಸರಳತೆ',
'ಸರಳಿಗೆ',
'ಸರಳಿಸು',
'ಸರಳೇಱು',
'ಸರಳ್ಗಱೆ',
'ಸರಳ್ವಳೆ',
'ಸರಳ್ವೆಜ್ಜ',
'ಸರಾಗಿಸು',
'ಸರಾಸರಿ',
'ಸರಿಕಾಣು',
'ಸರಿಕಿಸು',
'ಸರಿಗಂಜಿ',
'ಸರಿಗಟ್ಟು',
'ಸರಿಗಣೆ',
'ಸರಿಗಾಣ್',
'ಸರಿಗಾದು',
'ಸರಿಗೂಡು',
'ಸರಿಗೆಯ್',
'ಸರಿಗೊಳ್',
'ಸರಿಜೀರು',
'ಸರಿತರ್',
'ಸರಿತೂಗು',
'ಸರಿತ್ಪತಿ',
'ಸರಿದೂಗು',
'ಸರಿದೊರೆ',
'ಸರಿದೋರು',
'ಸರಿದ್ವರ',
'ಸರಿನೂಲು',
'ಸರಿನೇಣು',
'ಸರಿನೋಡು',
'ಸರಿನ್ನಾಥ',
'ಸರಿಪಾಲು',
'ಸರಿಬರ್',
'ಸರಿಬರಿ',
'ಸರಿಬರು',
'ಸರಿಬೀಳು',
'ಸರಿಬೆಸ',
'ಸರಿಭಾಗ',
'ಸರಿಮಳೆ',
'ಸರಿಯಿಕ್ಕು',
'ಸರಿಯಿಡು',
'ಸರಿಯಿಸು',
'ಸರಿರಾತ್ರಿ',
'ಸರಿವದ್ದಿ',
'ಸರಿವರ್',
'ಸರಿವರಿ',
'ಸರಿವಳೆ',
'ಸರಿವೞೆ',
'ಸರಿವೊಗು',
'ಸರಿವೋಗು',
'ಸರಿಸಪ',
'ಸರಿಸಮ',
'ಸರಿಸಾಟಿ',
'ಸರಿಸೃಪ',
'ಸರಿಸೋನೆ',
'ಸರಿಸೋಲ',
'ಸರಿಹೊಂದು',
'ಸರಿಹೊತ್ತು',
'ಸರಿಹೋಗು',
'ಸರೀಸೃಪ',
'ಸರುಕಿಸು',
'ಸರುವದಾ',
'ಸರುವಿಡಿ',
'ಸರುಷಪ',
'ಸರುಸಪ',
'ಸರೋಚಕ',
'ಸರೋಜಾತ',
'ಸರೋಜಿನಿ',
'ಸರೋನಿಧಿ',
'ಸರೋಭವ',
'ಸರೋರುಹ',
'ಸರೋವನ',
'ಸರೋವರ',
'ಸರೋವಾರಿ',
'ಸರ್ಗಬಂಧ',
'ಸರ್ಜಕ್ಷಾರ',
'ಸರ್ಜಪಾರ್ಕೆ',
'ಸರ್ಜರಸ',
'ಸರ್ಪಗಂಧ',
'ಸರ್ಪಗಂಧಿ',
'ಸರ್ಪತನ',
'ಸರ್ಪದಷ್ಟ',
'ಸರ್ಪದಷ್ಟೆ',
'ಸರ್ಪಬಾಣ',
'ಸರ್ಪಭುಕ್ಕು',
'ಸರ್ಪಯೋಗ',
'ಸರ್ಪರಾಜ',
'ಸರ್ಪರಾಯಿ',
'ಸರ್ಪರೋಗ',
'ಸರ್ಪರೋಗಿ',
'ಸರ್ಪಶರ',
'ಸರ್ಪಸುತ್ತು',
'ಸರ್ಪಾಶನ',
'ಸರ್ಫರಾಜು',
'ಸರ್ಬಧಾರಿ',
'ಸರ್ಬಮಾನ್ಯ',
'ಸರ್ಬಲಗ್ಗೆ',
'ಸರ್ಬವಿದ',
'ಸರ್ಬಾಪದ',
'ಸರ್ವಂಕಷ',
'ಸರ್ವಂಸಹೆ',
'ಸರ್ವಕುಷ್ಠ',
'ಸರ್ವಕ್ಷಾರ',
'ಸರ್ವಗತ',
'ಸರ್ವಗ್ರಾಸ',
'ಸರ್ವಗ್ರಾಸಿ',
'ಸರ್ವಜಯ',
'ಸರ್ವಜಿತ',
'ಸರ್ವಜಿತು',
'ಸರ್ವಜ್ಞತೆ',
'ಸರ್ವಜ್ಞತ್ವ',
'ಸರ್ವಧಾರಿ',
'ಸರ್ವನಾಮ',
'ಸರ್ವನಾಶ',
'ಸರ್ವಪೃಷ್ಠ',
'ಸರ್ವಮಾನ್ಯ',
'ಸರ್ವರತ್ನ',
'ಸರ್ವರಸ',
'ಸರ್ವರ್ತುಕ',
'ಸರ್ವಲಗ್ಗೆ',
'ಸರ್ವವಿತ್ತು',
'ಸರ್ವವಿದ',
'ಸರ್ವವೇದ',
'ಸರ್ವವೇದ್ಯ',
'ಸರ್ವವ್ಯಾಪಿ',
'ಸರ್ವಶಕ್ತ',
'ಸರ್ವಶಕ್ತೆ',
'ಸರ್ವಸಾಕ್ಷಿ',
'ಸರ್ವಸಾಧು',
'ಸರ್ವಸಿದ್ಧಿ',
'ಸರ್ವಾಂಗೀಣ',
'ಸರ್ವಾರ್ಪಿತ',
'ಸರ್ವಾವಧಿ',
'ಸರ್ವೆಮರ',
'ಸರ್ವೇಂದ್ರಿಯ',
'ಸರ್ವೈವಾಕ್ಯ',
'ಸರ್ವೋತ್ತಮ',
'ಸಲಂಗೊಳ್',
'ಸಲಕಡೆ',
'ಸಲಕ್ಷಣ',
'ಸಲವಿಡು',
'ಸಲಹಿಸು',
'ಸಲಾಮತು',
'ಸಲಾಮತ್ತು',
'ಸಲಾಯಿಸು',
'ಸಲಿಲಜ',
'ಸಲಿಲಾಸ್ತ್ರ',
'ಸಲೀಲತೆ',
'ಸಲುವಳಿ',
'ಸಲೆದೊಳ್ತು',
'ಸಲೆಯಡಿ',
'ಸಲ್ಗೆದೋಱು',
'ಸಲ್ಗೆನುಡಿ',
'ಸಲ್ಗೆವಂತ',
'ಸಲ್ಗೆವಡೆ',
'ಸಲ್ಲಕ್ಷಣ',
'ಸಲ್ಲಗಳೆ',
'ಸಲ್ಲಗೆಡ್ಡೆ',
'ಸಲ್ಲಪನ',
'ಸಲ್ಲಪಿಸು',
'ಸಲ್ಲಲಿತ',
'ಸಲ್ಲವಿಡು',
'ಸಲ್ಲಾಗಾರ',
'ಸಲ್ಲಾಪಿಸು',
'ಸಲ್ಲಾಮುಗೈ',
'ಸಲ್ಲುವಳಿ',
'ಸಲ್ಲೆವಿಕ್ಕು',
'ಸಲ್ಲೇಖನ',
'ಸಲ್ಲೇಖನೆ',
'ಸವಕಟ್ಟು',
'ಸವಕಲು',
'ಸವಕಳಿ',
'ಸವಗಾಣ',
'ಸವಡಿಡು',
'ಸವಡಿಸು',
'ಸವಡೋಲು',
'ಸವಣಿಸು',
'ಸವಣೂರಿ',
'ಸವಣ್ಬಿಟ್ಟಿ',
'ಸವಣ್ಮನೆ',
'ಸವನಿಸು',
'ಸವನೆಲ',
'ಸವಬೆಸ',
'ಸವರಣೆ',
'ಸವರನ್',
'ಸವರನ್ನು',
'ಸವರಿಸು',
'ಸವರ್ಣೀಯ',
'ಸವಲಕ್ಕ',
'ಸವಲಕ್ಕಿ',
'ಸವಲಕ್ಕೆ',
'ಸವಲತ್ತು',
'ಸವಸರಿ',
'ಸವಸಲ್',
'ಸವಳದೆ',
'ಸವಳ್ತಡ',
'ಸವಳ್ತಡೆ',
'ಸವಱಿಸು',
'ಸವಾಲಕ್ಷ',
'ಸವಾಸೇರು',
'ಸವಿಕಲ್ಪ',
'ಸವಿಗಂಪು',
'ಸವಿಗರೆ',
'ಸವಿಗಾಣ್',
'ಸವಿಗಾಣು',
'ಸವಿಗಾರ',
'ಸವಿಗಾರ್ತಿ',
'ಸವಿಗಾಱ',
'ಸವಿಗೊಳ್',
'ಸವಿಗೊಳು',
'ಸವಿಗೋಲ್',
'ಸವಿಗೋಲ',
'ಸವಿತಾರ',
'ಸವಿದೋರು',
'ಸವಿದೋಱು',
'ಸವಿನಯ',
'ಸವಿನೀತ',
'ಸವಿನುಡಿ',
'ಸವಿನೋಡು',
'ಸವಿಯಿಸು',
'ಸವಿರಾಗ',
'ಸವಿಲಾಸಂ',
'ಸವಿವಡೆ',
'ಸವಿವಿಲ್',
'ಸವಿವಿಲ್ಲ',
'ಸವಿಶೇಷ',
'ಸವಿಶೇಷಂ',
'ಸವಿಸ್ತರ',
'ಸವಿಸ್ತರಂ',
'ಸವಿಸ್ತಾರ',
'ಸವುಡಂಗಿ',
'ಸವುಭಾಗ್ಯ',
'ಸವುರಣೆ',
'ಸವುರಿಸು',
'ಸವುಳುಪ್ಪು',
'ಸವೆಗಳೆ',
'ಸವೆಗೋಲು',
'ಸವೆಯಿಸು',
'ಸವ್ಯಪೇಕ್ಷ',
'ಸವ್ಯಸಾಚಿ',
'ಸವ್ಯಾಕುಲ',
'ಸವ್ಯಾಕುಲೆ',
'ಸವ್ವರಣೆ',
'ಸವ್ವರಿಸು',
'ಸವ್ವಾಸೇರು',
'ಸಶಂಕಿತ',
'ಸಶಂಕಿತೆ',
'ಸಶರೀರ',
'ಸಶಾಸ್ತ್ರೀಯ',
'ಸಸಂಭ್ರಮ',
'ಸಸಿಕಲೆ',
'ಸಸಿಕುಪ್ಪಿ',
'ಸಸಿಗಲ್',
'ಸಸಿಗಲೆ',
'ಸಸಿಗಾಣಿ',
'ಸಸಿಗುಪ್ಪೆ',
'ಸಸಿಗೆಱೆ',
'ಸಸಿದರ',
'ಸಸಿನಿಡು',
'ಸಸಿನಿರು',
'ಸಸಿನೆಗೈ',
'ಸಸಿಬಟ್ಟು',
'ಸಸಿಮಣಿ',
'ಸಸಿವಚ್ಚ',
'ಸಸಿವಟ್ಟು',
'ಸಸಿವಣಿ',
'ಸಸಿವಾರ',
'ಸಸಿವೆಱೆ',
'ಸಸಿಸೂಡಿ',
'ಸಸೇಮಿರ',
'ಸಸೇಮಿರಾ',
'ಸಸೇಮೀರ',
'ಸಸ್ಪೃಹತೆ',
'ಸಸ್ಫುಟತೆ',
'ಸಸ್ಯಜನ್ಯ',
'ಸಸ್ಯಜಾತಿ',
'ಸಸ್ಯರಸ',
'ಸಸ್ಯಶಾಸ್ತ್ರ',
'ಸಸ್ಯಶೂಕ',
'ಸಸ್ಯಹೇನು',
'ಸಸ್ಯಾಹಾರ',
'ಸಸ್ಯಾಹಾರಿ',
'ಸಹಂಪತಿ',
'ಸಹಕಾರ',
'ಸಹಕಾರಿ',
'ಸಹಕೃತಿ',
'ಸಹಚರ',
'ಸಹಚರಿ',
'ಸಹಚಾರಿ',
'ಸಹಛದ್ಮ',
'ಸಹಜತೆ',
'ಸಹಜನ್ಮ',
'ಸಹಜಾತ',
'ಸಹಜಾತೆ',
'ಸಹಜೀಕ',
'ಸಹದೇವ',
'ಸಹದೇವಿ',
'ಸಹಧರ್ಮಿ',
'ಸಹಪಂಕ್ತಿ',
'ಸಹಪಂತಿ',
'ಸಹಪಾಠಿ',
'ಸಹಬಾಳ್ವೆ',
'ಸಹಭವ',
'ಸಹಭವೆ',
'ಸಹಭಾಗಿ',
'ಸಹಭೋಜಿ',
'ಸಹಮತ',
'ಸಹಮತಿ',
'ಸಹಮೂಲ',
'ಸಹಯಿಗ',
'ಸಹಯೋಗ',
'ಸಹಯೋಗಿ',
'ಸಹವರ್ತಿ',
'ಸಹವಾಸ',
'ಸಹವಾಸಿ',
'ಸಹಸಿಗ',
'ಸಹಸಿಸು',
'ಸಹಸ್ರಕ',
'ಸಹಸ್ರಘ್ನಿ',
'ಸಹಸ್ರಾಂಶು',
'ಸಹಸ್ರಾಕ್ಷ',
'ಸಹಸ್ರಾರ',
'ಸಹಸ್ರೋಸ್ರ',
'ಸಹಾಧ್ಯಾಯಿ',
'ಸಹಾಯಕ',
'ಸಹಾಯಕಿ',
'ಸಹಾಯತೆ',
'ಸಹಾಯಾರ್ಥ',
'ಸಹಾಯಿಗ',
'ಸಹಿಷ್ಣುತೆ',
'ಸಹೃದಯ',
'ಸಹೃದಯೆ',
'ಸಹೇತುಕ',
'ಸಹೋತ್ಥಾಯಿ',
'ಸಹೋತ್ಪನ್ನ',
'ಸಹೋದರ',
'ಸಹೋದರಿ',
'ಸಹೋದ್ಯೋಗಿ',
'ಸಳ್ಳುಗುರು',
'ಸಱಕನೆ',
'ಸಱಕ್ಕನೆ',
'ಸಱಸಱ',
'ಸಱಿಕಲ್',
'ಸಱಿಗಲ್',
'ಸಱಿಗುಂಡು',
'ಸಱಿಗೊಳ್',
'ಸಱಿಮಿಂಡಿ',
'ಸಱಿಯೂದು',
'ಸಱುಸೈತು',
'ಸಱõÀ್ರóನೆ',
'ಸಾಂಕೇತಿಕ',
'ಸಾಂಕ್ರಾಮಿಕ',
'ಸಾಂಖ್ಯಮತ',
'ಸಾಂಖ್ಯಯೋಗ',
'ಸಾಂಖ್ಯಾಗಮ',
'ಸಾಂಗವೇದ',
'ಸಾಂಗೋಪಾಂಗ',
'ಸಾಂಗ್ರಾಮಿಕ',
'ಸಾಂದರ್ಭಿಕ',
'ಸಾಂದ್ರಪದ',
'ಸಾಂಪರಾಯ',
'ಸಾಂಪ್ರದಾಯ',
'ಸಾಂಯಾತ್ರಿಕ',
'ಸಾಂವತ್ಸರ',
'ಸಾಂಸಾರಿಕ',
'ಸಾಂಸಿದ್ಧಿಕ',
'ಸಾಂಸ್ಕೃತಿಕ',
'ಸಾಕಾಣಿಕೆ',
'ಸಾಕುತಂದೆ',
'ಸಾಕುತಾಯಿ',
'ಸಾಕುಪ್ರಾಣಿ',
'ಸಾಕುಮಗ',
'ಸಾಕುಮಗು',
'ಸಾಕ್ಷರತೆ',
'ಸಾಕ್ಷರಿಕ',
'ಸಾಕ್ಷಾತ್ಕಾರ',
'ಸಾಕ್ಷಿಕಟ್ಟೆ',
'ಸಾಕ್ಷಿಕಲ್ಲು',
'ಸಾಕ್ಷಿಗಾರ',
'ಸಾಕ್ಷಿಗೊಳು',
'ಸಾಕ್ಷಿದಾರ',
'ಸಾಕ್ಷಿಪ್ರಜ್ಞೆ',
'ಸಾಕ್ಷೀಭೂತ',
'ಸಾಖಾಚರ',
'ಸಾಗಮನ',
'ಸಾಗರಾಂತ',
'ಸಾಗವಣೆ',
'ಸಾಗವಾನಿ',
'ಸಾಗಾಣಿಕೆ',
'ಸಾಗುಅಕ್ಕಿ',
'ಸಾಗುಚೀಟು',
'ಸಾಗುಬಳಿ',
'ಸಾಗುವಳಿ',
'ಸಾಗುವಾಡ',
'ಸಾಗುವಾನಿ',
'ಸಾಗುಸಂತು',
'ಸಾಚೀಕೃತ',
'ಸಾಜಗಾರ',
'ಸಾಟಿಗಾಣು',
'ಸಾಡೇಸಾತ್',
'ಸಾಡೇಸಾತ',
'ಸಾಡೇಸಾತಿ',
'ಸಾಣಕತ್ತಿ',
'ಸಾಣಾಕತ್ತಿ',
'ಸಾಣಾಗತ್ತಿ',
'ಸಾಣಿಗಾಱ',
'ಸಾಣಿಬಳೆ',
'ಸಾಣುಕತ್ತಿ',
'ಸಾಣೆಕಲ್',
'ಸಾಣೆಕಲ್ಲು',
'ಸಾಣೆಗಟ್ಟು',
'ಸಾಣೆಗತ್ತಿ',
'ಸಾಣೆಗಲ್ಲು',
'ಸಾಣೆಗಾಣು',
'ಸಾಣೆಗಾರ',
'ಸಾಣೆಗಾಱ',
'ಸಾಣೆಚಕ್ರ',
'ಸಾಣೆಬಳೆ',
'ಸಾಣೆಯಿಕ್ಕು',
'ಸಾಣೆವಿಡಿ',
'ಸಾಣೆವೊಯ್',
'ಸಾಣೆಹಿಡಿ',
'ಸಾತಗಡಿ',
'ಸಾತಾಳಿಸು',
'ಸಾತಿರೇಕ',
'ಸಾತಿವಳೆ',
'ಸಾತಿಶಯ',
'ಸಾತ್ವಿಕತೆ',
'ಸಾದಣೆಗೈ',
'ಸಾದರಣ',
'ಸಾದರಣೆ',
'ಸಾದಿಲ್ವಾರು',
'ಸಾದುಗಪ್ಪು',
'ಸಾಧನಗೈ',
'ಸಾಧನೆಗೈ',
'ಸಾಧಾರಣ',
'ಸಾಧಾರಣೆ',
'ಸಾಧಿಷ್ಠಿತ',
'ಸಾಧಿಸುಹ',
'ಸಾಧುಕಾರ',
'ಸಾಧುಜನ',
'ಸಾಧುತನ',
'ಸಾಧುಪ್ರಾಣಿ',
'ಸಾಧ್ಯಭೂಮಿ',
'ಸಾಧ್ಯಯೋಗ',
'ಸಾಧ್ಯವೇಳೆ',
'ಸಾಧ್ವೀಮಣಿ',
'ಸಾನಂದಾಶ್ರು',
'ಸಾನುಕೂಲ',
'ಸಾನುತಳ',
'ಸಾನುನಯ',
'ಸಾನುಭವ',
'ಸಾನುಭಾವ',
'ಸಾನುಭೂತಿ',
'ಸಾನುರಾಗ',
'ಸಾನುವಂತ',
'ಸಾಪಂಗೊಳು',
'ಸಾಪಂದಳಿ',
'ಸಾಪತ್ನಕ',
'ಸಾಪತ್ನಿಕ',
'ಸಾಪರಾಧ',
'ಸಾಪುಗಾರ',
'ಸಾಪುನಾಣ್ಯ',
'ಸಾಪೇಕ್ಷಕ',
'ಸಾಪೇಕ್ಷತೆ',
'ಸಾಪ್ತಾಹಿಕ',
'ಸಾಫ್‍ಸೀದಾ',
'ಸಾಫಲ್ಯತ್ವ',
'ಸಾಬುಅಕ್ಕಿ',
'ಸಾಬುದಾಣ',
'ಸಾಬುದಾಣಿ',
'ಸಾಬುದಾನಿ',
'ಸಾಬೂದಾಣೆ',
'ಸಾಭಿಜ್ಞಾನ',
'ಸಾಭಿಪ್ರಾಯ',
'ಸಾಭಿಲಾಷ',
'ಸಾಭಿಲಾಷೆ',
'ಸಾಮಂಜಸ್ಯ',
'ಸಾಮಂತಿಕೆ',
'ಸಾಮಗಾನ',
'ಸಾಮಯಿಕ',
'ಸಾಮರಸ',
'ಸಾಮರಸ್ಯ',
'ಸಾಮರಾಗ',
'ಸಾಮರ್ಥ್ಯತೆ',
'ಸಾಮವಣ್ಣ',
'ಸಾಮವರ್ಣ',
'ಸಾಮವಾಕ್ಯ',
'ಸಾಮವೇದ',
'ಸಾಮವೇದಿ',
'ಸಾಮಸೂಮ',
'ಸಾಮಾಚಾರ',
'ಸಾಮಾಜಿಕ',
'ಸಾಮಾಧಿಕ',
'ಸಾಮಾನಿಕ',
'ಸಾಮಾನ್ಯತೆ',
'ಸಾಮಾಯಿಕ',
'ಸಾಮಿದೋಹಿ',
'ಸಾಮಿಪಕ್ವ್ಟ',
'ಸಾಮುದ್ರಕ',
'ಸಾಮುದ್ರಿಕ',
'ಸಾಮುದ್ರಿಕೆ',
'ಸಾಮೂಹಿಕ',
'ಸಾಮೆಯಕ್ಕಿ',
'ಸಾಮೆಯನ್ನ',
'ಸಾಮೆಹುಲ್ಲು',
'ಸಾಮೋಪಾಯ',
'ಸಾಮ್ಯವಾದ',
'ಸಾಮ್ಯವಾದಿ',
'ಸಾಯಂಕಾಲ',
'ಸಾಯಂತನ',
'ಸಾಯಂತರ',
'ಸಾಯಂಫೇರಿ',
'ಸಾಯಂಸಂಧ್ಯೆ',
'ಸಾಯಸಿಗ',
'ಸಾಯಿಗುತ್ತ',
'ಸಾರಂಗಟ್ಟು',
'ಸಾರಂಗಿಡು',
'ಸಾರಂಗೆಡು',
'ಸಾರಗಟ್ಟು',
'ಸಾರಗೆಡು',
'ಸಾರಣಿಗೆ',
'ಸಾರದಾರು',
'ಸಾರಭೂತ',
'ಸಾರಮತಿ',
'ಸಾರಮಾರಿ',
'ಸಾರಮೇಯ',
'ಸಾರರಸ',
'ಸಾರವಣೆ',
'ಸಾರವತಿ',
'ಸಾರವತ್ತು',
'ಸಾರವಸ್ತು',
'ಸಾರವಾನ',
'ಸಾರಸತ್ವ',
'ಸಾರಸನ',
'ಸಾರಸ್ವತ',
'ಸಾರಾಸಾರ',
'ಸಾರೋದ್ಧಾರ',
'ಸಾರ್ಕೆಗುಡು',
'ಸಾರ್ಕೆವರ್',
'ಸಾರ್ಚುದೊಲೆ',
'ಸಾರ್ಥಕತೆ',
'ಸಾರ್ಥನಾಥ',
'ಸಾರ್ಥನಾಮ',
'ಸಾರ್ಥಪತಿ',
'ಸಾರ್ಥವಾಹ',
'ಸಾರ್ಬಭೌಮ',
'ಸಾರ್ವಕಾಲ',
'ಸಾರ್ವತ್ರಿಕ',
'ಸಾರ್ವಭೌಮ',
'ಸಾರ್ವರ್ತುಕ',
'ಸಾರ್ವರ್ತುಕಂ',
'ಸಾಲ್‍ಜಾಡ',
'ಸಾಲ್‍ಜಾಡೆ',
'ಸಾಲ್‍ಝಾಡ',
'ಸಾಲಂಕಾರ',
'ಸಾಲಂಕೃತ',
'ಸಾಲಂಗುಳಿ',
'ಸಾಲಂಗೊಡು',
'ಸಾಲಂಗೊಳು',
'ಸಾಲಂಬಡು',
'ಸಾಲಂಮಿಶ್ರಿ',
'ಸಾಲಕೊಡು',
'ಸಾಲಗಾತಿ',
'ಸಾಲಗಾರ',
'ಸಾಲಗಾರ್ತಿ',
'ಸಾಲಗುಳಿ',
'ಸಾಲಗ್ರಾಮ',
'ಸಾಲಜೇಡು',
'ಸಾಲಪತ್ರ',
'ಸಾಲಪರ್ಣಿ',
'ಸಾಲಬಡು',
'ಸಾಲಬಾಗೆ',
'ಸಾಲವಿದ್ಯೆ',
'ಸಾಲಾಂಬಾಲ',
'ಸಾಲಾಖೈರು',
'ಸಾಲಾಮಿಸ್ರಿ',
'ಸಾಲಾವಳಿ',
'ಸಾಲಾವೃಕ',
'ಸಾಲಿಗ್ರಾಮ',
'ಸಾಲಿಗ್ರಾವ',
'ಸಾಲಿಯಾನ',
'ಸಾಲಿಯಾನಾ',
'ಸಾಲುಗಂಬ',
'ಸಾಲುಗಂಭ',
'ಸಾಲುಗಟ್ಟು',
'ಸಾಲುಗೊಳ್',
'ಸಾಲುಗೊಳ್ಳು',
'ಸಾಲುತೋಪು',
'ಸಾಲುಧೂಪ',
'ಸಾಲುಬಾಗೆ',
'ಸಾಲುಮಣೆ',
'ಸಾಲುಮರ',
'ಸಾಲುವಲೆ',
'ಸಾಲುವಳಿ',
'ಸಾಲುವಿಡಿ',
'ಸಾಲೆಹುಳು',
'ಸಾಲ್ಗೊಳಿಸು',
'ಸಾಲ್ಲೀಯಾನಾ',
'ಸಾವಂತಿಕೆ',
'ಸಾವಕಾತಿ',
'ಸಾವಕಾರ',
'ಸಾವಕಾರಿ',
'ಸಾವಕಾಶ',
'ಸಾವಗಿಸು',
'ಸಾವಡರ್',
'ಸಾವಡಸು',
'ಸಾವಧಾನ',
'ಸಾವಧಾನಿ',
'ಸಾವಭೃತ',
'ಸಾವಯವ',
'ಸಾವರಣೆ',
'ಸಾವರಿಸು',
'ಸಾವರ್ಣಿಕ',
'ಸಾವುಕಾರ',
'ಸಾವುಕಾರಿ',
'ಸಾವುಗಾಣ್',
'ಸಾವೆಗೆಡ್ಡೆ',
'ಸಾವೆಯಕ್ಕಿ',
'ಸಾಶ್ರುಪಾತ',
'ಸಾಷ್ಟಾಂಗತ',
'ಸಾಸನಿಗ',
'ಸಾಸವಾಸಿ',
'ಸಾಸಿರ್ಗಣ್ಣ',
'ಸಾಸಿರ್ಗೈಯ',
'ಸಾಸಿರ್ಮಡಿ',
'ಸಾಹಚರ್ಯ',
'ಸಾಹಣಿಗ',
'ಸಾಹಣಿತಿ',
'ಸಾಹಸಾಂಕ',
'ಸಾಹಸಿಕ',
'ಸಾಹಸಿಕೆ',
'ಸಾಹಸಿಗ',
'ಸಾಹಸೀಕ',
'ಸಾಹಿತಿಯ',
'ಸಾಹಿತ್ಯಕ',
'ಸಾಹಿತ್ಯಿಕ',
'ಸಾಹುಕಾರ',
'ಸಾಹುಕಾರಿ',
'ಸಾಹೇಬಕಿ',
'ಸಾಳಿಗ್ರಾವ',
'ಸಿಂಗಣಿಕ',
'ಸಿಂಗಣೀಕ',
'ಸಿಂಗನಾದ',
'ಸಿಂಗಪೀಠ',
'ಸಿಂಗಪ್ರಾಸ',
'ಸಿಂಗಬಲ',
'ಸಿಂಗಮುಯ್',
'ಸಿಂಗರಂಗೈ',
'ಸಿಂಗರಗೈ',
'ಸಿಂಗರಿಗ',
'ಸಿಂಗರಿಸು',
'ಸಿಂಗರೂಪ',
'ಸಿಂಗಲೀಕ',
'ಸಿಂಗವಣೆ',
'ಸಿಂಗಳಕ',
'ಸಿಂಗಳಿಕ',
'ಸಿಂಗಳೀಕ',
'ಸಿಂಗಾರಿಗ',
'ಸಿಂಗಾರಿಸು',
'ಸಿಂಗಾಸನ',
'ಸಿಂಗಿನಾದ',
'ಸಿಂಜಿನಿಗೈ',
'ಸಿಂಟಿಮರ',
'ಸಿಂಟೆಮರ',
'ಸಿಂಡರಿಸು',
'ಸಿಂತರಿಸು',
'ಸಿಂದುವಱಿ',
'ಸಿಂದುವಾರ',
'ಸಿಂಧುತನ',
'ಸಿಂಧುರಂಗ',
'ಸಿಂಧುರಾಜ',
'ಸಿಂಧುವಾರ',
'ಸಿಂಧೂದ್ಭವ',
'ಸಿಂಪಡಿಕೆ',
'ಸಿಂಪಡಿಸು',
'ಸಿಂಪರಣೆ',
'ಸಿಂಪರಿಸು',
'ಸಿಂಪಿಣಿಗೆ',
'ಸಿಂಬಡಿಸು',
'ಸಿಂಬಳಿಕೆ',
'ಸಿಂಬೆಗೊಳ್',
'ಸಿಂಹಕಟಿ',
'ಸಿಂಹಕ್ರೀಡ',
'ಸಿಂಹದ್ವಾರ',
'ಸಿಂಹಧ್ವನಿ',
'ಸಿಂಹನಾದ',
'ಸಿಂಹಪರ್ಷೆ',
'ಸಿಂಹಪಾಲು',
'ಸಿಂಹಪೀಠ',
'ಸಿಂಹಪುಚ್ಛಿ',
'ಸಿಂಹಪ್ರಾಸ',
'ಸಿಂಹಬಲ',
'ಸಿಂಹಭಾಗ',
'ಸಿಂಹರವ',
'ಸಿಂಹರಾಶಿ',
'ಸಿಂಹಲಗ್ನ',
'ಸಿಂಹಲೀಲ',
'ಸಿಂಹವರ್ಣ',
'ಸಿಂಹಸ್ವಪ್ನ',
'ಸಿಂಹಳೀಯ',
'ಸಿಂಹಾವಳಿ',
'ಸಿಂಹಾಸಂದಿ',
'ಸಿಂಹಾಸನ',
'ಸಿಂಹೀಮಧ್ಯೆ',
'ಸಿಕತಿಲ',
'ಸಿಕತಿಳ',
'ಸಿಕರಣೆ',
'ಸಿಕ್ಕಟಿಗೆ',
'ಸಿಕ್ಕಟ್ಟಿಗೆ',
'ಸಿಕ್ಕಣಿಗೆ',
'ಸಿಕ್ಕಾಪಟ್ಟೆ',
'ಸಿಕ್ಕಾಬಟ್ಟೆ',
'ಸಿಕ್ಕುದಕ್ಕು',
'ಸಿಕ್ಕುಮೂತ್ರ',
'ಸಿಕ್ಕುವಲೆ',
'ಸಿಕ್ಕೇದಾರ',
'ಸಿಗರೇಟು',
'ಸಿಗಳಿಕೆ',
'ಸಿಗುಡಿತೆ',
'ಸಿಗುಱೇಳು',
'ಸಿಗ್ಗುಡಿತೆ',
'ಸಿಚಯಕ',
'ಸಿಟ್ಟಿಪತಿ',
'ಸಿಟ್ಟುಗುಟ್ಟು',
'ಸಿಡಕ್ಕನೆ',
'ಸಿಡಿಗಂಟಿ',
'ಸಿಡಿಗನ್ನ',
'ಸಿಡಿಗುಂಡು',
'ಸಿಡಿಗೊಳ್ಳು',
'ಸಿಡಿಗೋಲು',
'ಸಿಡಿನುಡಿ',
'ಸಿಡಿಪಿಡಿ',
'ಸಿಡಿಬಲೆ',
'ಸಿಡಿಮದ್ದು',
'ಸಿಡಿಮಿಡಿ',
'ಸಿಡಿಯಳೆ',
'ಸಿಡಿಯಾಡು',
'ಸಿಡಿರೋಷ',
'ಸಿಡಿಲೇಳ್ಗೆ',
'ಸಿಡಿಲೇೞ್ಗೆ',
'ಸಿಡಿಲ್ವೊಡೆ',
'ಸಿಡಿವಲೆ',
'ಸಿಡಿಸಾಲೆ',
'ಸಿಡಿಸಿಡಿ',
'ಸಿಡಿಹಾಯ್',
'ಸಿಡುಕಲು',
'ಸಿಡುಗುಟ್ಟು',
'ಸಿತಕಂದ',
'ಸಿತಕರ',
'ಸಿತಕರಿ',
'ಸಿತಕೀರ್ತಿ',
'ಸಿತಕುಷ್ಠ',
'ಸಿತಚ್ಛದ',
'ಸಿತಪಕ್ಷ',
'ಸಿತಭಾನು',
'ಸಿತಮತಿ',
'ಸಿತಯಶ',
'ಸಿತರೋಚಿ',
'ಸಿತವಣ್ಣ',
'ಸಿತವರ್ಣ',
'ಸಿತಶಾಲಿ',
'ಸಿತಶಿವ',
'ಸಿತಶೂಕ',
'ಸಿತಹಯ',
'ಸಿತಿಕಂಠ',
'ಸಿತಿಗಳ',
'ಸಿತೇತರ',
'ಸಿತೋತ್ಪಲ',
'ಸಿದ್ದೆಗುಮ್ಮ',
'ಸಿದ್ಧಕ್ಷೇತ್ರ',
'ಸಿದ್ಧಘೃತ',
'ಸಿದ್ಧಚಕ್ರ',
'ಸಿದ್ಧಪತ್ರೆ',
'ಸಿದ್ಧಪದ',
'ಸಿದ್ಧರಸ',
'ಸಿದ್ಧಲಿಕೆ',
'ಸಿದ್ಧಲೇಪ',
'ಸಿದ್ಧವಸ್ತು',
'ಸಿದ್ಧವೈದ್ಯ',
'ಸಿದ್ಧಶಿಲೆ',
'ಸಿದ್ಧಶೇಷೆ',
'ಸಿದ್ಧಶೈಲ',
'ಸಿದ್ಧಸೇಸೆ',
'ಸಿದ್ಧಹಸ್ತ',
'ಸಿದ್ಧಾಂಜನ',
'ಸಿದ್ಧಾಂತಿಗ',
'ಸಿದ್ಧಾಂತಿಸು',
'ಸಿದ್ಧಾಗಮ',
'ಸಿದ್ಧಾದಾಯ',
'ಸಿದ್ಧಾಸನ',
'ಸಿದ್ಧಿಮಂತ್ರ',
'ಸಿದ್ಧಿಯೋಗ',
'ಸಿದ್ಧಿವರು',
'ಸಿದ್ಧಿವಾರ',
'ಸಿದ್ಧೌಷಧ',
'ಸಿನಿಕತೆ',
'ಸಿನಿಮೀಯ',
'ಸಿನೀವಾಲಿ',
'ಸಿಪಾದಾರ',
'ಸಿಪಾರಸು',
'ಸಿಪಿಗೆಂಡೆ',
'ಸಿಪಿವಿಷ್ಟ',
'ಸಿಪ್ಪೆಗಟ್ಟು',
'ಸಿಪ್ಪೆದೆಗೆ',
'ಸಿಪ್ಪೆಸುಲಿ',
'ಸಿಫಾರಸು',
'ಸಿಬ್ಬಂಬೊಯ್',
'ಸಿಮಹಿಡಿ',
'ಸಿಮಿಸಿಮಿ',
'ಸಿರಕಲು',
'ಸಿರಗತ್ತಿ',
'ಸಿರಗುಟ್ಟು',
'ಸಿರತಾಳಿ',
'ಸಿರಪೇಚು',
'ಸಿರಪೇಷ್',
'ಸಿರಪೊಟ್ಲ',
'ಸಿರಮಾಲೆ',
'ಸಿರಿಕಂಟ',
'ಸಿರಿಕಂಡ',
'ಸಿರಿಕನ್ನೆ',
'ಸಿರಿಕಾಳ',
'ಸಿರಿಖಂಡ',
'ಸಿರಿಗಂಧ',
'ಸಿರಿಗತ್ತಿ',
'ಸಿರಿಗೆಡು',
'ಸಿರಿಗೇಡಿ',
'ಸಿರಿತನ',
'ಸಿರಿತಾಳೆ',
'ಸಿರಿತುಪ್ಪೆ',
'ಸಿರಿದರ',
'ಸಿರಿದಾರ',
'ಸಿರಿದಿನ',
'ಸಿರಿದೇವಿ',
'ಸಿರಿಧರ',
'ಸಿರಿನುಡಿ',
'ಸಿರಿಪದ',
'ಸಿರಿಬೀಡು',
'ಸಿರಿಮರ',
'ಸಿರಿಮುರಿ',
'ಸಿರಿಮೆಯ್',
'ಸಿರಿಮೊಗ',
'ಸಿರಿಯಕ್ಕ',
'ಸಿರಿಯಡಿ',
'ಸಿರಿಯೇಱು',
'ಸಿರಿವಂತ',
'ಸಿರಿವಂತೆ',
'ಸಿರಿವಚ್ಚ',
'ಸಿರಿವಡೆ',
'ಸಿರಿವಣ್',
'ಸಿರಿವಣ್ಣ',
'ಸಿರಿವರ',
'ಸಿರಿವಾರ',
'ಸಿರಿವೀಡು',
'ಸಿರಿವೆಱು',
'ಸಿರಿವೊತ್ತು',
'ಸಿರಿಸಂಘ',
'ಸಿರಿಸಲ',
'ಸಿರಿಹಸ್ತ',
'ಸಿರಿಹೊತ್ತು',
'ಸಿರುಕೀರೆ',
'ಸಿರುಗಣ್ಣು',
'ಸಿರೋತಿಗೆ',
'ಸಿಲವಾರ',
'ಸಿಲುಕಿಸು',
'ಸಿಲೆದಾರ',
'ಸಿಲೇದಾರ',
'ಸಿಲ್ಕಾಸಿಲ್ಕಿ',
'ಸಿಲ್ಕುಗಾಣ್',
'ಸಿಲ್ಲಿಮಿಳ್ಳಿ',
'ಸಿವಚೆಳ್ಳೆ',
'ಸಿವದಿಡು',
'ಸಿವಳಿಗೆ',
'ಸಿವುಡಿಗೆ',
'ಸಿವುಱೇೞ್',
'ಸಿಸುತನ',
'ಸಿಸುಮಗ',
'ಸಿಸ್ತುಗಾತಿ',
'ಸಿಸ್ತುಗಾರ',
'ಸಿಹಿತೆನೆ',
'ಸಿಹಿತೊಂಡೆ',
'ಸಿಹಿದಟ್ಟು',
'ಸಿಹಿನಿದ್ದೆ',
'ಸಿಹಿನೀರು',
'ಸಿಹಿಪಾಲೆ',
'ಸಿಹಿಮಾತು',
'ಸಿಹಿಮಾವು',
'ಸಿಹಿಮೆಕ್ಕೆ',
'ಸಿಹಿವಾಲೆ',
'ಸಿಹಿವಿಲ್',
'ಸಿಹಿವಿಲ್ಲ',
'ಸಿಹಿಸಾರು',
'ಸಿಹಿಹಾಲೆ',
'ಸಿಳೀಮುಖ',
'ಸಿಳ್ಳಿಮಿಳ್ಳಿ',
'ಸಿಳ್ಳಿಹಾಕು',
'ಸಿಳ್ಳುಗಣ್ಣು',
'ಸಿಳ್ಳುನಾಯಿ',
'ಸಿಳ್ಳುಹಾಕು',
'ಸಿಳ್ಳುಹೊಡೆ',
'ಸಿಳ್ಳೆಕಾಯ',
'ಸಿಳ್ಳೆಕೇತ',
'ಸಿಳ್ಳೆಗಣ್ಣು',
'ಸಿಳ್ಳೆಯೂದು',
'ಸಿಳ್ಳೆಹಾಕು',
'ಸಿಱುಂಬುಳ್',
'ಸೀಕರಣೆ',
'ಸೀಕರಿಸು',
'ಸೀಕುಂಬಳ',
'ಸೀಗುಂಬಳ',
'ಸೀಗುಡಿತೆ',
'ಸೀಗುರಿಕೆ',
'ಸೀಗುರಿಸು',
'ಸೀಗೆಕಾಯಿ',
'ಸೀಗೆಗೌರಿ',
'ಸೀಗೆಣಸು',
'ಸೀಗೆಪುಡಿ',
'ಸೀಗೆಯೊಬ್ಬೆ',
'ಸೀಗೆವೆಳೆ',
'ಸೀಗೆಹುಳಿ',
'ಸೀಟುಗಾಲು',
'ಸೀತಾಫಲ',
'ಸೀತಾಶೃಂಗಿ',
'ಸೀತೆಹೂವು',
'ಸೀತ್ಕರಿಸು',
'ಸೀಬಿಗೆಡ್ಡೆ',
'ಸೀಮಂತಕ',
'ಸೀಮಂತಿನಿ',
'ಸೀಮಾಕರ',
'ಸಿಮಾರೇಖೆ',
'ಸೀಮಾರೇಷೆ',
'ಸೀಮಿತತೆ',
'ಸೀಮೆಅಕ್ಕಿ',
'ಸೀಮೆಅತ್ತಿ',
'ಸೀಮೆಕೋಳಿ',
'ಸೀಮೆಗಲ್ಲು',
'ಸೀಮೆಗಳಿ',
'ಸೀಮೆಗಳೆ',
'ಸೀಮೆಗಾಱ',
'ಸೀಮೆಗೆಡು',
'ಸೀಮೆತುತ್ತಿ',
'ಸೀಮೆತೆಂಗು',
'ಸೀಮೆದಳೆ',
'ಸೀಮೆನೆಲ್ಲಿ',
'ಸೀಮೆಬಾಗೆ',
'ಸೀಮೆಬೆಂಡೆ',
'ಸೀಮೆಮಾವು',
'ಸೀಮೆಯಕ್ಕಿ',
'ಸೀಮೆಯೆಣ್ಣೆ',
'ಸೀಮೆಸುಣ್ಣ',
'ಸೀಮೆಸೋಂಪು',
'ಸೀಮೆಹತ್ತಿ',
'ಸೀಮೆಹಸು',
'ಸೀಮೊಸರು',
'ಸೀಯದರ',
'ಸೀಯದಾರ',
'ಸೀಯಬಾರ',
'ಸೀಯಾವಳಿ',
'ಸೀರಣಿಗೆ',
'ಸೀರದನಿ',
'ಸೀರಧರ',
'ಸೀರಪಾಣಿ',
'ಸೀರಸನಿ',
'ಸೀರಹಸ್ತ',
'ಸೀರಾಯುಧ',
'ಸೀರಿಪ್ರಿಯೆ',
'ಸೀರುಡಿಕೆ',
'ಸೀರುಮಣ್ಣು',
'ಸೀರುಹೊನ್ನೆ',
'ಸೀರೆರೋಗ',
'ಸೀರ್ಪಡಿಸು',
'ಸೀಲಗುಣ',
'ಸೀಲವಂತ',
'ಸೀಲವಂತೆ',
'ಸೀಲಾಗಿಡ',
'ಸೀವಟ್ಟಣ',
'ಸೀವರಿಕೆ',
'ಸೀವರಿಸು',
'ಸೀವಳಿಗೆ',
'ಸೀಸಕಡ್ಡಿ',
'ಸೀಸಪತ್ರ',
'ಸೀಸಪದ್ಯ',
'ಸೀಸಭಸ್ಮ',
'ಸೀಳುಕಂಡಿ',
'ಸೀಳುಗಂಡಿ',
'ಸೀಳುದಾರಿ',
'ಸೀಳುನದಿ',
'ಸೀಳುನಾಯಿ',
'ಸೀಳುಹಾದಿ',
'ಸೀಱುಂಬಳ್',
'ಸೀಱುಂಬಾಳು',
'ಸೀಱುಂಬುಳ್',
'ಸೀಱುಂಬುಳು',
'ಸೀಱುದಲೆ',
'ಸೀಱುಮಣ್ಣು',
'ಸುಂಕಂಗೊಳ್',
'ಸುಂಕಂವಿಡು',
'ಸುಂಕಗಳ್ಳ',
'ಸುಂಕಠಾಣೆ',
'ಸುಂಕಪಾಳ',
'ಸುಂಕಮಾನ್ಯ',
'ಸುಂಕಿಗಿತಿ',
'ಸುಂಕಿನುಂಡೆ',
'ಸುಂಕುಬುಲ್',
'ಸುಂಕುಬುಲ್ಲು',
'ಸುಂಕುರೋಗ',
'ಸುಂಕೇಶ್ವರ',
'ಸುಂಟಿಹುಲ್ಲು',
'ಸುಂಡುತಲೆ',
'ಸುಂದರತೆ',
'ಸುಂದರಾಂಗ',
'ಸುಂದರಾಂಗಿ',
'ಸುಂದರಿಸು',
'ಸುಂದುವಡೆ',
'ಸುಕಂದಕ',
'ಸುಕಂಬಾೞ್',
'ಸುಕನಾತಿ',
'ಸುಕನಾಸಿ',
'ಸುಕಬಾಳು',
'ಸುಕಮಿರ್',
'ಸುಕರತೆ',
'ಸುಕರಾಜ',
'ಸುಕಾಲಿಗ',
'ಸುಕುಮಾರ',
'ಸುಕುಮಾರಿ',
'ಸುಕುಲೀನ',
'ಸುಕುಲೀನೆ',
'ಸುಕೇಸರ',
'ಸುಕ್ಕಾಲಿಗ',
'ಸುಕ್ಕಿನುಂಡೆ',
'ಸುಕ್ಕುಗಟ್ಟು',
'ಸುಕ್ಕೆಸೊಪ್ಪು',
'ಸುಕ್ರವಾರ',
'ಸುಖಂಗೆಯ್',
'ಸುಖಂದಳೆ',
'ಸುಖಂಬಡು',
'ಸುಖಂಬಡೆ',
'ಸುಖಂಬಾೞ್',
'ಸುಖಕರ',
'ಸುಖಕಾರಿ',
'ಸುಖಕೊಡು',
'ಸುಖಗೊಡು',
'ಸುಖಜಲ',
'ಸುಖದಳೆ',
'ಸುಖದಾಯಿ',
'ಸುಖನಾಸಿ',
'ಸುಖಪಡು',
'ಸುಖಬಡು',
'ಸುಖಬಾಳು',
'ಸುಖಮುಖ',
'ಸುಖಮುಖಿ',
'ಸುಖವಡು',
'ಸುಖವಡೆ',
'ಸುಖವಾಳು',
'ಸುಖಸಂಗಿ',
'ಸುಖಸನ್ನಿ',
'ಸುಖಸಾಧ್ಯ',
'ಸುಖಾಸನ',
'ಸುಖಾಸೀನ',
'ಸುಖಾಸೀನೆ',
'ಸುಖಿತನ',
'ಸುಖಿನುಂಡೆ',
'ಸುಖಿಯಿಸು',
'ಸುಖಿವುಂಡೆ',
'ಸುಖೀರಾಜ್ಯ',
'ಸುಗಂಧಿಕ',
'ಸುಗಂಧಿಗ',
'ಸುಗಂಧಿತೆ',
'ಸುಗಮತೆ',
'ಸುಗಿಗೊಳ್',
'ಸುಗಿಯಿಸು',
'ಸುಗಿವಡೆ',
'ಸುಗ್ಗಿಕಾಲ',
'ಸುಗ್ಗಿದೋರು',
'ಸುಗ್ಗಿವಡೆ',
'ಸುಗ್ಗಿವೆಱು',
'ಸುಗ್ರೀವಾಜ್ಞೆ',
'ಸುಚರಿತ',
'ಸುಚರಿತೆ',
'ಸುಚರಿತ್ರ',
'ಸುಚರಿತ್ರೆ',
'ಸುಚಾಯಿಸು',
'ಸುಚಾರಿತ್ರ',
'ಸುಜನತೆ',
'ಸುಜನತ್ವ',
'ಸುಜ್ಞಾನದ',
'ಸುಟಿಕರ',
'ಸುಟಿಯಳಿ',
'ಸುಟಿವಂತ',
'ಸುಟಿವೆಱು',
'ಸುಟ್ಟುಂದೋಱು',
'ಸುಟ್ಟುಂಬೆಕ್ಕು',
'ಸುಟ್ಟುದೋಱು',
'ಸುಡುಗರ',
'ಸುಡುಗಾಡು',
'ಸುಡುನುಡಿ',
'ಸುಡುಮಣ್ಣು',
'ಸುಡುಮಿಡು',
'ಸುಡುವುಣ್',
'ಸುಣ್ಣಕಟ್ಟು',
'ಸುಣ್ಣಕಲ್',
'ಸುಣ್ಣಕಲ್ಲು',
'ಸುಣ್ಣಕಾಯಿ',
'ಸುಣ್ಣಗಾರ',
'ಸುಣ್ಣಗಾರ್ತಿ',
'ಸುಣ್ಣಗಾಱ',
'ಸುಣ್ಣಗುದಿ',
'ಸುಣ್ಣಜಾಜ',
'ಸುಣ್ಣಪಾರೆ',
'ಸುಣ್ಣಬಳಿ',
'ಸುಣ್ಣಬೇವು',
'ಸುಣ್ಣಸೊದೆ',
'ಸುಣ್ಣಸೋತೆ',
'ಸುಣ್ಣಹಚ್ಚು',
'ಸುಣ್ಣಹುಯ್ಯು',
'ಸುಣ್ಣಹೊಡೆ',
'ಸುತಶ್ರೇಣಿ',
'ಸುತಿಗುಡು',
'ಸುತಿಗೂಡು',
'ಸುತ್ತಮುತ್ತ',
'ಸುತ್ತಲಿಸು',
'ಸುತ್ತಳತೆ',
'ಸುತ್ತಾಡಿಸು',
'ಸುತ್ತಾಲಯ',
'ಸುತ್ತುಂಗಾಱ',
'ಸುತ್ತುಂಗೊಳ್',
'ಸುತ್ತುಂಬರ್',
'ಸುತ್ತುಗಟ್ಟು',
'ಸುತ್ತುಗಾಱ',
'ಸುತ್ತುಗೊಳ್',
'ಸುತ್ತುಗೋಂಟೆ',
'ಸುತ್ತುಗೋಡೆ',
'ಸುತ್ತುಬರು',
'ಸುತ್ತುಬೇಲಿ',
'ಸುತ್ತುಮುತ್ತ',
'ಸುತ್ತುಮುತ್ತು',
'ಸುತ್ತುವರಿ',
'ಸುತ್ತುವರು',
'ಸುತ್ತುವಲೆ',
'ಸುತ್ತುವೀಳ್ಯ',
'ಸುತ್ತುವೇಲಿ',
'ಸುತ್ತುಹಾಕು',
'ಸುದರ್ಶನ',
'ಸುದೈವಜ್ಞ',
'ಸುದ್ದಯಿಸು',
'ಸುದ್ದಿಗಾರ',
'ಸುದ್ದಿಗಾರ್ತಿ',
'ಸುದ್ದಿಗೇಳ್',
'ಸುದ್ದಿಗೊಡು',
'ಸುದ್ದಿಬಡಿ',
'ಸುದ್ದೆಮಣ್ಣು',
'ಸುಧಾಂಬರ',
'ಸುಧಾಕರ',
'ಸುಧಾಕಾಂತ',
'ಸುಧಾಗೃಹ',
'ಸುಧಾಪಿಂಡ',
'ಸುಧಾರಕ',
'ಸುಧಾರಣೆ',
'ಸುಧಾರಸ',
'ಸುಧಾರಿತ',
'ಸುಧಾರಿಸು',
'ಸುಧಾರ್ಮಿಕ',
'ಸುಧಾಶನ',
'ಸುಧಾಸ್ಯಂದಿ',
'ಸುಧೀಮಣಿ',
'ಸುಧೀರತೆ',
'ಸುಧೆಗೂಳ',
'ಸುನಾಯಿತ',
'ಸುನಾವಣೆ',
'ಸುನಾಸೀರ',
'ಸುನಿಶ್ಚಿತ',
'ಸುನಿಷ್ಕೃತಿ',
'ಸುನ್ನವೆಸ',
'ಸುಪನಾತಿ',
'ಸುಪನಾಸಿ',
'ಸುಪರ್ಣಿಜ',
'ಸುಪಾಶ್ರ್ವಕ',
'ಸುಪ್ತಚಿತ್ತ',
'ಸುಪ್ತಪ್ರಜ್ಞೆ',
'ಸುಪ್ತಸ್ಥಿತಿ',
'ಸುಪ್ತಾವಸ್ಥೆ',
'ಸುಪ್ಪತಿಗೆ',
'ಸುಪ್ಪತ್ತಿಗೆ',
'ಸುಪ್ಪನಾತಿ',
'ಸುಪ್ಪರನ',
'ಸುಪ್ಪವಗು',
'ಸುಪ್ರಣೀತ',
'ಸುಪ್ರತಿಷ್ಠ',
'ಸುಪ್ರತಿಷ್ಠೆ',
'ಸುಪ್ರತೀಕ',
'ಸುಪ್ರಥಿತ',
'ಸುಪ್ರಬಂಧ',
'ಸುಪ್ರಬುದ್ಧ',
'ಸುಪ್ರಭಾತ',
'ಸುಪ್ರಮೇಯ',
'ಸುಪ್ರಸನ್ನ',
'ಸುಪ್ರಸಾದ',
'ಸುಪ್ರಸಾದಿ',
'ಸುಪ್ರಸಿದ್ಧ',
'ಸುಪ್ರಾಸುಕ',
'ಸುಬದ್ಧತೆ',
'ಸುಬದ್ಧಿಸು',
'ಸುಬಾದಾರ',
'ಸುಬೇದಾರ್',
'ಸುಬೇದಾರ',
'ಸುಬೇದಾರಿ',
'ಸುಬೇದಾರು',
'ಸುಬ್ಬಡರ್',
'ಸುಬ್ರಹ್ಮಣ್ಯ',
'ಸುಭಗತೆ',
'ಸುಭಗತ್ವ',
'ಸುಭಟತೆ',
'ಸುಭದಾರ್',
'ಸುಭದ್ರತೆ',
'ಸುಭಾಷಿಣಿ',
'ಸುಭಾಷಿತ',
'ಸುಭಿಕ್ಷತ್ವ',
'ಸುಭೇದಾರ',
'ಸುಮಂಗಲ',
'ಸುಮಂಗಲಿ',
'ಸುಮಂಗಲೆ',
'ಸುಮಂಗಳ',
'ಸುಮಂಗಳೆ',
'ಸುಮಧುರ',
'ಸುಮನಸ',
'ಸುಮನಸ್ವಿ',
'ಸುಮನಸ್ಸು',
'ಸುಮಬಾಣ',
'ಸುಮಾಲತಿ',
'ಸುಮುಹೂರ್ತ',
'ಸುಮ್ಮನಿಸು',
'ಸುಮ್ಮಹಿಮ',
'ಸುಮ್ಮಾನಿಸು',
'ಸುಯಿದಾನ',
'ಸುಯಿದಾನಿ',
'ಸುಯಿಧಾನ',
'ಸುಯಿಧಾನಿ',
'ಸುಯಿಲ್ಗರೆ',
'ಸುಯೋಧನ',
'ಸುಯ್ಯೆಲರ್',
'ಸುಯ್ವೊಡರ್',
'ಸುರಂಗಿತ',
'ಸುರಂಗಿಸು',
'ಸುರಕುಜ',
'ಸುರಕ್ಷಿತ',
'ಸುರಗಂಗೆ',
'ಸುರಗತಿ',
'ಸುರಗುರು',
'ಸುರಗೋಪ',
'ಸುರಚಾಪ',
'ಸುರತರು',
'ಸುರತಾಲ',
'ಸುರತಾಳ',
'ಸುರತಿಸು',
'ಸುರತ್ರಾಣ',
'ಸುರದಾರು',
'ಸುರದಿಕ್ಕು',
'ಸುರದ್ವಿಷ',
'ಸುರಧನು',
'ಸುರಧೂಪ',
'ಸುರಧೇನು',
'ಸುರಪದ',
'ಸುರಪರ್ಣಿ',
'ಸುರಪುರ',
'ಸುರಭೂಜ',
'ಸುರಮಣಿ',
'ಸುರಮಾಯಿ',
'ಸುರಮುನಿ',
'ಸುರವಾರಿ',
'ಸುರವಾಲ್',
'ಸುರವಾಲು',
'ಸುರವೊನ್ನೆ',
'ಸುರಹೊನ್ನೆ',
'ಸುರಳೀತ',
'ಸುರಾಕಾರ',
'ಸುರಾಖಾರ',
'ಸುರಾಮಂಡ',
'ಸುರಿತರ್',
'ಸುರಿತಾಣ',
'ಸುರಿತಾಳ',
'ಸುರಿತ್ರಾಣಿ',
'ಸುರಿಬಳ್ಳಿ',
'ಸುರಿಮಳೆ',
'ಸುರಿಮುಳ್ಳು',
'ಸುರುಂಟಿಸು',
'ಸುರುಚಿರ',
'ಸುರುಟಿಸು',
'ಸುರುತಾಳ',
'ಸುರುವಾತು',
'ಸುರುವಾರ',
'ಸುರುಸುರ್',
'ಸುರುಸುರು',
'ಸುರುಳಿಡು',
'ಸುರುಳಿಸು',
'ಸುರುಳ್ತರ್',
'ಸುರುಳ್ದೆಗೆ',
'ಸುರೆಗುಡಿ',
'ಸುರೇಂದ್ರತ್ವ',
'ಸುರೇಶ್ವರ',
'ಸುರ್ಕಿಗಾರೆ',
'ಸುರ್ಕುವೆಱು',
'ಸುರ್ಚುರಿಕೆ',
'ಸುರ್ಬಡರ್',
'ಸುರ್ಬುಗೊಳ್',
'ಸುರ್ವಡರ್',
'ಸುರ್ವುಗೊಳ್',
'ಸುಲಕ್ಷಣ',
'ಸುಲತಾನ',
'ಸುಲತಾನಿ',
'ಸುಲಭತೆ',
'ಸುಲಲಿತ',
'ಸುಲಲಿತೆ',
'ಸುಲಾಲಿತ',
'ಸುಲಾವಣೆ',
'ಸುಲಿತಿನ್',
'ಸುಲಿಪಡು',
'ಸುಲಿಯಿಸು',
'ಸುಲಿವಡೆ',
'ಸುಲಿವಲ್ಲ',
'ಸುಲೋಚನ',
'ಸುವರ್ಣಕ',
'ಸುವರ್ಣತ್ವ',
'ಸುವರ್ಲೋಕ',
'ಸುವಾಂಛಿತ',
'ಸುವಾರಾಹಿ',
'ಸುವಾಸನೆ',
'ಸುವಾಸಿತ',
'ಸುವಾಸಿನಿ',
'ಸುವಿಚಾರ',
'ಸುವಿದಾನ',
'ಸುವಿದಾನಿ',
'ಸುವಿದಿತ',
'ಸುವಿಧಾನ',
'ಸುವಿಧಾನಿ',
'ಸುವಿಶಾಲ',
'ಸುವಿಶಾಳ',
'ಸುವೃತ್ತತೆ',
'ಸುವೇದಿಸು',
'ಸುವ್ಯಕುತ',
'ಸುವ್ಯವಸ್ಥೆ',
'ಸುವ್ವಲಾಲೆ',
'ಸುವ್ವಿಗೆಯ್',
'ಸುಶಿಕ್ಷಿತ',
'ಸುಶಿಕ್ಷಿತೆ',
'ಸುಷೇಣಿಕೆ',
'ಸುಸಂಗತ',
'ಸುಸಂಬದ್ಧ',
'ಸುಸಂಸ್ಕೃತ',
'ಸುಸಂಸ್ಕೃತೆ',
'ಸುಸಜ್ಜಿತ',
'ಸುಸಜ್ಜಿತೆ',
'ಸುಸಮಯ',
'ಸುಸರಿಕೆ',
'ಸುಸಿಲಾಡು',
'ಸುಸಿಲುಣ್',
'ಸುಸ್ತಿಗಾರ',
'ಸುಸ್ತಿಗೊಳ್',
'ಸುಸ್ತಿದಾರ',
'ಸುಸ್ತಿಬಡ್ಡಿ',
'ಸುಸ್ಥಿರತೆ',
'ಸುಸ್ವಾಗತ',
'ಸುಹಾಸಿನಿ',
'ಸುಹೃಜ್ಜನ',
'ಸುಹೃದಯ',
'ಸುಹೃದಯೆ',
'ಸುಳಿಗಾಳಿ',
'ಸುಳಿಗೊಳ್',
'ಸುಳಿಗೊಳು',
'ಸುಳಿಘಾಳಿ',
'ಸುಳಿತರ್',
'ಸುಳಿದಲೆ',
'ಸುಳಿದೆಗೆ',
'ಸುಳಿನುಡಿ',
'ಸುಳಿವಳಿ',
'ಸುಳಿಸುತ್ತು',
'ಸುಳುದಾರಿ',
'ಸುಳ್ಳುಂಗಾಱ',
'ಸುಳ್ಳುಗಾತಿ',
'ಸುಳ್ಳುಗಾರ',
'ಸುಳ್ಳುತನ',
'ಸುಳ್ಳುಹೇಳು',
'ಸುಱುಕಿಡು',
'ಸುಱಱನೆ',
'ಸುೞಿಗಾಳಿ',
'ಸುೞಿಗೊಳ್',
'ಸುೞಿತರ್',
'ಸುೞಿದಲೆ',
'ಸುೞಿಯಿಸು',
'ಸುೞಿಯೇಱು',
'ಸುೞಿವಡೆ',
'ಸುೞಿವರ್',
'ಸೂಕಳತೆ',
'ಸೂಕಳತ್ವ',
'ಸೂಕಳಾಶ್ವ',
'ಸೂಕ್ತಿಕಾರ',
'ಸೂಕ್ತಿಗರ್ಭ',
'ಸೂಕ್ಷ್ಮಗ್ರಾಹಿ',
'ಸೂಕ್ಷ್ಮತತ್ವ',
'ಸೂಕ್ಷ್ಮತನು',
'ಸೂಕ್ಷ್ಮದರ್ಶಿ',
'ಸೂಕ್ಷ್ಮದೃಷ್ಟಿ',
'ಸೂಕ್ಷ್ಮಬುದ್ಧಿ',
'ಸೂಗುರಿಸು',
'ಸೂಚನೆಗೈ',
'ಸೂಚಿಕಲ್',
'ಸೂಚಿಕಲ್ಲು',
'ಸೂಚಿಪರ್ಣ',
'ಸೂಚಿಮುಖ',
'ಸೂಚೀಕರ್ಮ',
'ಸೂಚೀಘಾತ',
'ಸೂಚೀನಾಟ್ಯ',
'ಸೂಚೀಮುಖ',
'ಸೂಚೀಹಸ್ತ',
'ಸೂಜಿಕಲ್',
'ಸೂಜಿಕಲ್ಲು',
'ಸೂಜಿಗಣ್ಣು',
'ಸೂಜಿಗಲ್',
'ಸೂಜಿಗಲ್ಲು',
'ಸೂಜಿಗೊಳ್',
'ಸೂಜಿಚುಚ್ಚು',
'ಸೂಜಿಮದ್ದು',
'ಸೂಡಿಕಟ್ಟು',
'ಸೂಡುಕಟ್ಟು',
'ಸೂಡುದಱಿ',
'ಸೂಡುಬೆಂಕಿ',
'ಸೂತಕಾರಿ',
'ಸೂತಕೃತೆ',
'ಸೂತತನ',
'ಸೂತಿಮಾಸ',
'ಸೂತ್ರಕಾರ',
'ಸೂತ್ರಗೊಂಬೆ',
'ಸೂತ್ರಗೋಲು',
'ಸೂತ್ರಧರ',
'ಸೂತ್ರಧಾರ',
'ಸೂತ್ರಧಾರಿ',
'ಸೂತ್ರನಾಡಿ',
'ಸೂತ್ರನಾಭಿ',
'ಸೂತ್ರಪ್ರಾಯ',
'ಸೂತ್ರಬದ್ಧ',
'ಸೂತ್ರಬೊಂಬೆ',
'ಸೂದಕರ್ಮ',
'ಸೂನೆಗಾಱ',
'ಸೂಪಕಾತಿ',
'ಸೂಪಕಾರ',
'ಸೂಪರಣ',
'ಸೂಪಶಾಸ್ತ್ರ',
'ಸೂಯಿಯಾಣ',
'ಸೂರಬಿಂಬ',
'ಸೂರಳಿಡು',
'ಸೂರಿಜನ',
'ಸೂರಿಪಾನ',
'ಸೂರಿಪಾನಿ',
'ಸೂರಿಪಾನೆ',
'ಸೂರಿಹಣ್ಣು',
'ಸೂರುಕತ್ತಿ',
'ಸೂರುಗಲ್ಲು',
'ಸೂರುಳಿಡು',
'ಸೂರುಳಿಸು',
'ಸೂರುಳ್ಗೊಳ್',
'ಸೂರೆಕಾರ',
'ಸೂರೆಕೊಳ್',
'ಸೂರೆಗಳ್ಳ',
'ಸೂರಗೊಡು',
'ಸೂರೆಗೊಳ್',
'ಸೂರೆಗೊಳ್ಳು',
'ಸೂರೆಪಾನ',
'ಸೂರೆಹೊಡಿ',
'ಸೂರೆಹೋಗು',
'ಸೂರ್ತಿಖಾರ',
'ಸೂರ್ತಿಭಾರ',
'ಸೂರ್ತಿಮುತ್ತು',
'ಸೂರ್ತಿಹಣ',
'ಸೂರ್ಯಕಾಂತ',
'ಸೂರ್ಯಕಾಂತಿ',
'ಸೂರ್ಯಜ್ಯೋತಿ',
'ಸೂರ್ಯನಾಡಿ',
'ಸೂರ್ಯಪಾನ',
'ಸೂರ್ಯಪುಟ',
'ಸೂರ್ಯಬಂಧು',
'ಸೂರ್ಯಬಿಂಬ',
'ಸೂರ್ಯಭಕ್ತ',
'ಸೂರ್ಯಮುಖಿ',
'ಸೂರ್ಯಲೋಕ',
'ಸೂರ್ಯವಾದ್ಯ',
'ಸೂರ್ಯವೀಥಿ',
'ಸೂರ್ಯವೀಧಿ',
'ಸೂರ್ಯವೃಕ್ಷ',
'ಸೂರ್ಯಸ್ನಾನ',
'ಸೂರ್ಯಸ್ವರ',
'ಸೂರ್ಯಾತಪ',
'ಸೂರ್ಯಾವರ್ತ',
'ಸೂರ್ಯೋದಯ',
'ಸೂರ್ಯೋಪಲ',
'ಸೂಲಗಾತಿ',
'ಸೂಲಗಿತಿ',
'ಸೂಲಗಿತ್ತಿ',
'ಸೂಲಪಾಣಿ',
'ಸೂಲಮನೆ',
'ಸೂಲವೇಱು',
'ಸೂಲಳಲ್',
'ಸೂಲೆಯೇೞ್',
'ಸೂಲೆವೆಱು',
'ಸೂಲ್ಮುಲುಕು',
'ಸೂಸಲಾಡು',
'ಸೂಸುಮಣ್ಣು',
'ಸೂಸುವಲೆ',
'ಸೂಳಡಸು',
'ಸೂಳಪಾಣಿ',
'ಸೂಳಯಿಸು',
'ಸೂಳವಿಸು',
'ಸೂಳಾಯತ',
'ಸೂಳಾಯಿತ',
'ಸೂಳಾಯಿತ್ತ',
'ಸೂಳುಗರೆ',
'ಸೂಳುಗಾಱ',
'ಸೂಳುತ್ತರ',
'ಸೂಳುಪಾಳಿ',
'ಸೂಳುಪಾಳು',
'ಸೂಳೆಕೇರಿ',
'ಸೂಳೆಗಾರ',
'ಸೂಳೆಗಾಱ',
'ಸೂಳೆಗೆಯ್',
'ಸೂಳೆಗೆಯ್ತ',
'ಸೂಳೆಗೇರಿ',
'ಸೂಳೆತನ',
'ಸೂಳೆಬಳ್ಳಿ',
'ಸೂಳೆವಳ',
'ಸೂಳೆವಳ್ಳ',
'ಸೂಳೆವಾಳ',
'ಸೂಳೆವೆಣ್',
'ಸೂಳೆಸೊಪ್ಪು',
'ಸೂಱೆಕಾಱ',
'ಸೂಱೆಗಳೆ',
'ಸೂಱೆಗಾಱ',
'ಸೂಱೆಗೈಸು',
'ಸೂಱೆಗೊಡು',
'ಸೂಱೆಗೊಳ್',
'ಸೂಱೆಗೊಳು',
'ಸೂಱೆಯಾಡು',
'ಸೂಱೆವಿಡು',
'ಸೂಱೆವೋಗು',
'ಸೂಱೆಹೋಗು',
'ಸೂೞಾಯಿತ',
'ಸೂೞುಗರೆ',
'ಸೂೞುಗಾಳೆ',
'ಸೃಜಯಿಸು',
'ಸೃಜಿಯಿಸು',
'ಸೃಷ್ಟಿಕರ್ತ',
'ಸೃಷ್ಟಿಶೀಲ',
'ಸೃಷ್ಟಿಶೀಲೆ',
'ಸೆಂಡಾಡಿಸು',
'ಸೆಂಡುಕೋಲ್',
'ಸೆಂಡುಗೋಲ್',
'ಸೆಂದುಕೋಲ್',
'ಸೆಂಬೆಬತ್ತ',
'ಸೆಕೆಗಾಲ',
'ಸೆಕ್ಕೆಗಳೆ',
'ಸೆಕ್ಕೆವೋಗು',
'ಸೆಖೆಕಾಲ',
'ಸೆಗಳಿಕೆ',
'ಸೆಗಳಿಗೆ',
'ಸೆಜ್ಜಾಗೃಹ',
'ಸೆಜ್ಜೆಗೊಡು',
'ಸೆಜ್ಜೆಮನೆ',
'ಸೆಜ್ಜೆವನೆ',
'ಸೆಜ್ಜೆವಳ',
'ಸೆಜ್ಜೆವಳ್ತಿ',
'ಸೆಜ್ಜೆವಳ್ಳ',
'ಸೆಜ್ಜೆವಾಳ',
'ಸೆಟಕೊಳ್ಳು',
'ಸೆಟಗೊಳ್ಳು',
'ಸೆಟೆಗೊಳ್ಳು',
'ಸೆಟೆಬೇನೆ',
'ಸೆಟೆರೋಗ',
'ಸೆಟ್ಟಿಕಾತಿ',
'ಸೆಟ್ಟಿಕಾರ',
'ಸೆಟ್ಟಿಕಾಱ',
'ಸೆಟ್ಟಿಗುತ್ತ',
'ಸೆಟ್ಟವಟ್ಟ',
'ಸೆಟ್ಟವಾಳ',
'ಸೆಡೆವಿಡು',
'ಸೆಡ್ಡುಹೊಡೆ',
'ಸೆಣಸಾಟ',
'ಸೆಣಸಾಡು',
'ಸೆಣಸಿಕೆ',
'ಸೆಣಸುಗ',
'ಸೆರಗೊಡ್ಡು',
'ಸೆರೆಗಣ್ಣು',
'ಸೆರೆಬಿಡು',
'ಸೆರೆಮನೆ',
'ಸೆರೆಯಾಗು',
'ಸೆರೆಯಾಳ್',
'ಸೆರೆಯಾಳು',
'ಸೆರೆಯಿಂಗು',
'ಸೆರೆವಿಡಿ',
'ಸೆರೆಸಿಕ್ಕು',
'ಸೆರೆಹಿಡಿ',
'ಸೆಲರಿನ',
'ಸೆಲವಿಕ್ಕು',
'ಸೆಲೆಕೊಡು',
'ಸೆಲೆಯಾಡು',
'ಸೆಲೆಯೊಡೆ',
'ಸೆಲೆವೊಡೆ',
'ಸೆಳಕೊಳ್ಳು',
'ಸೆಳವಾಲ',
'ಸೆಳೆಕೊಳ್',
'ಸೆಳೆಖಾನೆ',
'ಸೆಳೆಗಾಲ',
'ಸೆಳೆಗೊಂಬು',
'ಸೆಳೆಗೊಕ್ಕೆ',
'ಸೆಳೆಗೊನೆ',
'ಸೆಳೆಗೊಳ್',
'ಸೆಳೆಗೋಲು',
'ಸೆಳೆತರು',
'ಸೆಳೆತೆಗೆ',
'ಸೆಳೆನೀರು',
'ಸೆಳೆಬೆತ್ತ',
'ಸೆಳೆಮಂಚ',
'ಸೆಳೆಮಿಂಚು',
'ಸೆಳ್ಳುಗುರ್',
'ಸೆಳ್ಳುಗುರು',
'ಸೆಱಗೊಡ್ಡು',
'ಸೆಱೆಕೊಳ್ಳು',
'ಸೆಱೆಕೋಲ್',
'ಸೆಱೆಗಟ್ಟು',
'ಸೆಱೆಗೆಯ್',
'ಸೆಱೆಗೊಳ್',
'ಸೆಱೆಗೋಲ್',
'ಸೆಱೆಮನೆ',
'ಸೆಱೆಯಾಳ್',
'ಸೆಱೆಯಾಳು',
'ಸೆಱೆಯಿಕ್ಕು',
'ಸೆಱೆಯಿಡು',
'ಸೆಱೆಯಿರ್',
'ಸೆಱೆಯೋಗು',
'ಸೆಱೆವನೆ',
'ಸೆಱೆವಿಡಿ',
'ಸೆಱೆವೊಯ್',
'ಸೆಱೆವೋಗು',
'ಸೆಱೆಸಾಲೆ',
'ಸೆಱೆಸಿಕ್ಕು',
'ಸೇಕಂಗೆಯ್',
'ಸೇಕಂಗೊಳ್',
'ಸೇಕಗೆಯ್',
'ಸೇಕಪಾತ್ರ',
'ಸೇಗುಡಿತೆ',
'ಸೇಗುಡುತೆ',
'ಸೇಚನಂಗೈ',
'ಸೇಡುಂಗೊಳ್',
'ಸೇಡುಗೊಳ್',
'ಸೇಡುಪಡು',
'ಸೇಡುವಡು',
'ಸೇಡುವಡೆ',
'ಸೇಣಿಗೊಳ್',
'ಸೇಣಿಪತಿ',
'ಸೇತಮಾಲೆ',
'ಸೇತುಗಟ್ಟು',
'ಸೇತುಬಂಧ',
'ಸೇದಿಕಲ್ಲು',
'ಸೇದುಬತ್ತಿ',
'ಸೇದುಬಾವಿ',
'ಸೇದೆಗಳೆ',
'ಸೇದೆಗಿಡು',
'ಸೇದೆಗೆಡು',
'ಸೇದೆದಳೆ',
'ಸೇದೆನೀರ್',
'ಸೇದೆನೀರು',
'ಸೇದೆವಡು',
'ಸೇದೆವಡೆ',
'ಸೇದೆವುಗು',
'ಸೇನಬೋಕೆ',
'ಸೇನಬೋಗ',
'ಸೇನಬೋವ',
'ಸೇನಭಾಗ',
'ಸೇನಭೂವ',
'ಸೇನಭೋಗ',
'ಸೇನಭೋವ',
'ಸೇನಾಧಿಪ',
'ಸೇನಾಪಟ್ಟ',
'ಸೇನಾಪತಿ',
'ಸೇನಾಪತ್ಯ',
'ಸೇನಾಮುಖ',
'ಸೇನುಬೋವ',
'ಸೇನುಭೋಗ',
'ಸೇನೆವಳ',
'ಸೇನೆವಳ್ಳ',
'ಸೇಪುಬಿಡು',
'ಸೇರುಗಾರ',
'ಸೇರುಪಡಿ',
'ಸೇರುಮಣ್ಣು',
'ಸೇರೆಗಣ್',
'ಸೇರೆಗಯ್',
'ಸೇರೆಗಾರ',
'ಸೇರೆಗೆಯ್',
'ಸೇರ್ಕೆಗೊಳ್ಳು',
'ಸೇರ್ಪಡಿಕೆ',
'ಸೇರ್ವೆಗಾರ',
'ಸೇವಂತಿಗೆ',
'ಸೇವತಿಗೆ',
'ಸೇವೆಗೆಯ್',
'ಸೇವೆಗೆಯ್ಯು',
'ಸೇಸೆಗಱೆ',
'ಸೇಸೆಗೊಡು',
'ಸೇಸೆಗೊಳ್',
'ಸೇಸೆದಳಿ',
'ಸೇಸೆಯಿಕ್ಕು',
'ಸೇಸೆಯಿಡು',
'ಸೈಂಹಿಕೇಯ',
'ಸೈಗೆಡಪು',
'ಸೈಗೆಡಹು',
'ಸೈತಕ್ಕರ',
'ಸೈತಿರಿಸು',
'ಸೈತುವೋಗು',
'ಸೈದ್ಧಾಂತಿಕ',
'ಸೈನಿಮಿರು',
'ಸೈನೆಗಳ್',
'ಸೈಪುಗಾಱ',
'ಸೈಪುದಳೆ',
'ಸೈಪುದೋಱು',
'ಸೈಮಱುಗು',
'ಸೈಯೇರಕ',
'ಸೈವಿಡಿಸು',
'ಸೈವೆರಗು',
'ಸೈವೆಳಗು',
'ಸೈವೆಱಗು',
'ಸೈಳಾಕ್ಷರ',
'ಸೊಂಕುರೋಗ',
'ಸೊಂಟಕಟ್ಟು',
'ಸೊಂಟಗಟ್ಟು',
'ಸೊಂಟದಟ್ಟಿ',
'ಸೊಂಟಪಟ್ಟಿ',
'ಸೊಂಟಮುರಿ',
'ಸೊಂಡ್ಲಗಿಡ',
'ಸೊಂಡ್ಲಿಗಿಡ',
'ಸೊಂದಿತನ',
'ಸೊಂಪುಂಗಾಱ',
'ಸೊಂಪುಗಾಱ',
'ಸೊಂಪುಗೆಡು',
'ಸೊಂಪುಗೊಳ್',
'ಸೊಂಪುತನ',
'ಸೊಂಪುವಡೆ',
'ಸೊಂಪುವೆರು',
'ಸೊಂಪುವೆಱು',
'ಸೊಕಮಿರು',
'ಸೊಕವಡೆ',
'ಸೊಕ್ಕುಗಳ್',
'ಸೊಕ್ಕುಮುರಿ',
'ಸೊಕ್ಕುಮುಱಿ',
'ಸೊಖನಾಸಿ',
'ಸೊಗಂಬಡು',
'ಸೊಗಂಬಡೆ',
'ಸೊಗಡಿಸು',
'ಸೊಗಡೇಱು',
'ಸೊಗದೋಱು',
'ಸೊಗಪಡು',
'ಸೊಗಬಾಳ್',
'ಸೊಗಮಿರ್',
'ಸೊಗಮುಣ್',
'ಸೊಗಯಿಸು',
'ಸೊಗವಡು',
'ಸೊಗವಾಳು',
'ಸೊಗವಾಳ್ತೆ',
'ಸೊಗವಾೞ್',
'ಸೊಗಸಿಕೆ',
'ಸೊಗಸಿತು',
'ಸೊಗಸೇರ್',
'ಸೊಗಸೇರು',
'ಸೊಟ್ಟಂಬಟ್ಟ',
'ಸೊಡಬಲಿ',
'ಸೊಡರಿಡು',
'ಸೊರಡೆತ್ತು',
'ಸೊಡರ್ಗಂಬ',
'ಸೊಡರ್ಗಂಭ',
'ಸೊಡರ್ಗೆಡು',
'ಸೊಡರ್ವಾವು',
'ಸೊಡರ್ವಿಡಿ',
'ಸೊತೆವಳಿ',
'ಸೊತ್ತುಗಾರ',
'ಸೊಥೆವೊಯ್',
'ಸೊದೆಗರೆ',
'ಸೊದೆಗೂಳು',
'ಸೊದೆಗೆಯ್',
'ಸೊದೆಯುಣಿ',
'ಸೊದೆವನೆ',
'ಸೊದೆವೞಿ',
'ಸೊನಗಾರ',
'ಸೊನಜಾಲಿ',
'ಸೊನೆಗಣ್ಣು',
'ಸೊನೆಗಾರ',
'ಸೊನೆಪಟ್ಟ',
'ಸೊನೆಹುಣ್ಣು',
'ಸೊನ್ನಗಾರ',
'ಸೊನ್ನಲಿಗೆ',
'ಸೊನ್ನೆಕೋಲು',
'ಸೊನ್ನೆಗಾರ',
'ಸೊನ್ನೆಗಾಱ',
'ಸೊನ್ನೆಟೆಂಕೆ',
'ಸೊನ್ನೆಸಾರ',
'ಸೊಪ್ಪಡಗು',
'ಸೊಪ್ಪಡಸು',
'ಸೊಪ್ಪುಕುರಿ',
'ಸೊಪ್ಪುಗತ್ತಿ',
'ಸೊಪ್ಪುಗಾಣ್',
'ಸೊಪ್ಪುಗಿಳಿ',
'ಸೊಪ್ಪುಗುಟ್ಟು',
'ಸೊಪ್ಪುಗುರಿ',
'ಸೊಪ್ಪುಗೊಲೆ',
'ಸೊಪ್ಪುಡಿಗೆ',
'ಸೊಪ್ಪುವಡು',
'ಸೊಪ್ಪುಳಿಡು',
'ಸೊಪ್ಪುಳ್ದೋಱು',
'ಸೊಮ್ಮುಗೊಳ್',
'ಸೊಮ್ಮುವೆರ್ಚು',
'ಸೊಯಂದಪ್ಪು',
'ಸೊಯಂಬರ',
'ಸೊಯಂವರ',
'ಸೊಯಗೆಡು',
'ಸೊರಬಿಡು',
'ಸೊರುಬಿಡು',
'ಸೊರೆಕಾಯಿ',
'ಸೊರೆಗಟ್ಟು',
'ಸೊರೆಗಾಯ್',
'ಸೊರೆಬಿಡು',
'ಸೊರೆವಾತು',
'ಸೊರ್ಕಾಳ್ತನ',
'ಸೊರ್ಕುನಿೀರ್',
'ಸೊಲ್ಲಡಗು',
'ಸೊಲ್ಲಣಗು',
'ಸೊಲ್ಲಣಿಗೆ',
'ಸೊಲ್ಲುಕಟ್ಟು',
'ಸೊಲ್ಲುಗಾತಿ',
'ಸೊಲ್ಲುಗೊಡು',
'ಸೊಲ್ಲುಜಾಣ',
'ಸೊಲ್ಲುದೋಱು',
'ಸೊಸೆತನ',
'ಸೊಳಸಗೆ',
'ಸೊಳಸಿಂಗೆ',
'ಸೊಳಸಿಗೆ',
'ಸೊಳ್ಳೆಬತ್ತಿ',
'ಸೋಂಕುರೋಗ',
'ಸೋಂಕುವಡೆ',
'ಸೋಂಬತನ',
'ಸೋಕ್ಷಮೋಕ್ಷ',
'ಸೋಗಲಕ್ಕಿ',
'ಸೋಗಲಾಡಿ',
'ಸೋಗಿಲಕ್ಕಿ',
'ಸೋಗುಗಾರ',
'ಸೋಗೆಕಣ್ಣು',
'ಸೋಗೆಗಣ್',
'ಸೋಗೆಗಣ್ಣು',
'ಸೋಗೆಗರಿ',
'ಸೋಗೆನಿಱಿ',
'ಸೋಗೆಮೊಗ',
'ಸೋಗೆವಿರ್ಚು',
'ಸೋಗೆವಿೀಲಿ',
'ಸೋಟಾಧಾರಿ',
'ಸೋಡಂಬಾಡ',
'ಸೋಡಂಬಾಡಂ',
'ಸೋಡಚೀಟಿ',
'ಸೋಡಚೀಟು',
'ಸೋಡಪತ್ರ',
'ಸೋಡಮುಂಜಿ',
'ಸೋಡಮುಂಜೆ',
'ಸೋತುವಲ್ಲಿ',
'ಸೋತೆವೆಸ',
'ಸೋತ್ಪಾದನ',
'ಸೋದರತೆ',
'ಸೋದರತ್ತೆ',
'ಸೋದರಿಕೆ',
'ಸೋದರಿಗ',
'ಸೋನಗಾರ',
'ಸೋನಾಮುಖಿ',
'ಸೋನೆಮಳೆ',
'ಸೋನೆವಳೆ',
'ಸೋಪದ್ರವ',
'ಸೋಪಸ್ಕರ',
'ಸೋಪಸ್ಕಾರ',
'ಸೋಪಹಾಸ',
'ಸೋಬಲಕ್ಕಿ',
'ಸೋಬಸ್ಕರ',
'ಸೋಮಘಂಟೆ',
'ಸೋಮಧರ',
'ಸೋಮಧಾರಿ',
'ಸೋಮನಾಥ',
'ಸೋಮಪಾನ',
'ಸೋಮಮಣಿ',
'ಸೋಮಯಜ್ಞ',
'ಸೋಮಯಾಗ',
'ಸೋಮಯಾಜಿ',
'ಸೋಮರಸ',
'ಸೋಮರಾಗ',
'ಸೋಮರಾಜಿ',
'ಸೋಮರೋಗ',
'ಸೋಮಲತೆ',
'ಸೋಮವಲ್ಕ',
'ಸೋಮವಲ್ಲಿ',
'ಸೋಮವಾರ',
'ಸೋಮವೀಥಿ',
'ಸೋಮವೀಧಿ',
'ಸೋಮಶಾಲಿ',
'ಸೋಮಸಾಲೆ',
'ಸೋಮೇಶ್ವರ',
'ಸೋಯಸ್ಕರ',
'ಸೋಯಿದರ',
'ಸೋಯಿಸ್ಕರ',
'ಸೋರುಗಂಡಿ',
'ಸೋರುಗಣ್ಣು',
'ಸೋರುಬಿಡು',
'ಸೋರುಮುಡಿ',
'ಸೋರೆಕಾಯಿ',
'ಸೋರೆಗಾಯಿ',
'ಸೋರೆಮೊಗ',
'ಸೋರ್ಗುರುಳು',
'ಸೋಲಂಬಡೆ',
'ಸೋಲಕಾರ್ತಿ',
'ಸೋಲಗಿತಿ',
'ಸೋಲುಮುಡಿ',
'ಸೋಲುವಡೆ',
'ಸೋಲೆಗಳೆ',
'ಸೋಲ್ತಯಿಸು',
'ಸೋವದಿಕೆ',
'ಸೋವನಿಗ',
'ಸೋವಾಸಿಣಿ',
'ಸೋವಿದರ',
'ಸೋಹಗೊಳ್ಳು',
'ಸೋಹುಮೃಗ',
'ಸೋಹುವೇಂಟೆ',
'ಸೌಂದರತೆ',
'ಸೌಂದರಿಯ',
'ಸೌಕುಮಾರ್ಯ',
'ಸೌಗಂಧಿಕ',
'ಸೌಗಂಧಿಸು',
'ಸೌಗವಲ್ಲಿ',
'ಸೌಚಾಚಾರ',
'ಸೌಜ್ಞೆಗೆಯ್',
'ಸೌದಾಗಾರ',
'ಸೌದಾಮನಿ',
'ಸೌದಾಮಿನಿ',
'ಸೌಧರ್ಮಿಕೆ',
'ಸೌಧರ್ಮೇಂದ್ರ',
'ಸೌನಂದಕ',
'ಸೌಮಂಗಲ್ಯ',
'ಸೌಮನಸ',
'ಸೌಮನಸ್ಯ',
'ಸೌಮನಸ್ಸು',
'ಸೌಮ್ಯವಾರ',
'ಸೌರಚಾಪ',
'ಸೌರಭೇಯ',
'ಸೌರಮಾನ',
'ಸೌರಮಾಸ',
'ಸೌರವರ್ಷ',
'ಸೌರವ್ಯೂಹ',
'ಸೌರಾಷ್ಟ್ರಕ',
'ಸೌರಿವಾರ',
'ಸೌರ್ಯಶಾಳಿ',
'ಸೌವತ್ಸರ',
'ಸೌವರಣೆ',
'ಸೌವರಿಸು',
'ಸೌವರ್ಚಲ',
'ಸೌವರ್ಚಳ',
'ಸೌವಸ್ತಿಕ',
'ಸೌವಿದಲ್ಲ',
'ಸೌಳಕಾರ',
'ಸೌಳುಗಿಡ',
'ಸೌಳುನೀರು',
'ಸೌಳುನೆಲ',
'ಸೌಳುಮಣ್ಣು',
'ಸೌಳುವಣ್ಣು',
'ಸ್ಕಂಧಸಂಧಿ',
'ಸ್ಕಂಧಾವಾರ',
'ಸ್ತಂಬಕರಿ',
'ಸ್ತಂಬೇರಮ',
'ಸ್ತಂಭನಗೈ',
'ಸ್ತಂಭೀಭೂತ',
'ಸ್ತಂಭೀಭೂತೆ',
'ಸ್ತನಂಧಯ',
'ಸ್ತನಪಾನ',
'ಸ್ತನಯಿತ್ನು',
'ಸ್ತನ್ಯಪಾನ',
'ಸ್ತಬ್ಧಚಿತ್ರ',
'ಸ್ತವಂಗೆಯ್',
'ಸ್ತವನೀಯ',
'ಸ್ತಿಮಿತಾಕ್ಷ',
'ಸ್ತಿರವಾರ',
'ಸ್ತಿರಾದಾಯ',
'ಸ್ತುತಿಗೆಯ್',
'ಸ್ತುತಿಯಿಸು',
'ಸ್ತುತಿವೆಱು',
'ಸ್ತೋಕಪುಣ್ಯ',
'ಸ್ತೋತ್ರಂಗೆಯ್',
'ಸ್ತೋಭಂಗೊಳ್',
'ಸ್ತ್ಯಾನಗೃದ್ಧಿ',
'ಸ್ತ್ರೀಯುಗಲ',
'ಸ್ತ್ರೀಯುಗಳ',
'ಸ್ತ್ರೀವಾಚಕ',
'ಸ್ತ್ರೀವ್ಯಸನ',
'ಸ್ತ್ರೈಣಸುಖ',
'ಸ್ಥಪುಟಿತ',
'ಸ್ಥಲಕಟ್ಟು',
'ಸ್ಥಲಚರ',
'ಸ್ಥಳಕಟ್ಟು',
'ಸ್ಥಳಚರ',
'ಸ್ಥಳಮನೆ',
'ಸ್ಥಳಯಾತ್ರೆ',
'ಸ್ಥಳಸುಂಕ',
'ಸ್ಥಳಾಂತರ',
'ಸ್ಥಾನಚ್ಯುತ',
'ಸ್ಥಾನಚ್ಯುತಿ',
'ಸ್ಥಾನಚ್ಯುತೆ',
'ಸ್ಥಾನಪತಿ',
'ಸ್ಥಾನಭ್ರಷ್ಟ',
'ಸ್ಥಾನಭ್ರಷ್ಟೆ',
'ಸ್ಥಾನಮಾನ',
'ಸ್ಥಾನಲಾಭ',
'ಸ್ಥಾನಶುದ್ಧಿ',
'ಸ್ಥಾನಾಚಾರ್ಯ',
'ಸ್ಥಾನಾಧಿಪ',
'ಸ್ಥಾಯಿಭಾವ',
'ಸ್ಥಾಯೀಭಾವ',
'ಸ್ಥಾಲೀಪಾಕ',
'ಸ್ಥಾವರತೆ',
'ಸ್ಥಿತಪ್ರಜ್ಞ',
'ಸ್ಥಿತಪ್ರಜ್ಞೆ',
'ಸ್ಥಿತರತಿ',
'ಸ್ಥಿತಿಕರ್ತ',
'ಸ್ಥಿತಿವಂತ',
'ಸ್ಥಿತಿವಂತೆ',
'ಸ್ಥಿತಿಸಾರ',
'ಸ್ಥಿತ್ಯಂತರ',
'ಸ್ಥಿರಕುಕ್ಷಿ',
'ಸ್ಥಿರಚಿತ್ತ',
'ಸ್ಥಿರಚಿತ್ತೆ',
'ಸ್ಥಿರಜೀವಿ',
'ಸ್ಥಿರಪಡು',
'ಸ್ಥಿರಪದ',
'ಸ್ಥಿರಬಾಳ್',
'ಸ್ಥಿರಬುದ್ಧಿ',
'ಸ್ಥಿರಬೋಧ',
'ಸ್ಥಿರವಾರ',
'ಸ್ಥಿರಸ್ವತ್ತು',
'ಸ್ಥಿರಹಸ್ತ',
'ಸ್ಥೂಲಕಾಯ',
'ಸ್ಥೂಲತನು',
'ಸ್ಥೂಲದೇಹ',
'ಸ್ಥೂಲದೇಹಿ',
'ಸ್ಥೂಲಪಾದ',
'ಸ್ಥೂಲಬುದ್ಧಿ',
'ಸ್ಥೂಲಲಕ್ಷ',
'ಸ್ಥೂಲೋಚ್ಚಯ',
'ಸ್ನಾನಘಟ್ಟ',
'ಸ್ನಿಗ್ಧಾಲೋಕ',
'ಸ್ನೇಹಪಾತ್ರ',
'ಸ್ಪರುಶಣ',
'ಸ್ಪರುಶನ',
'ಸ್ಪರ್ಶಕಾಲ',
'ಸ್ಪರ್ಶನೀಯ',
'ಸ್ಪರ್ಶಮಣಿ',
'ಸ್ಪರ್ಶವೇದಿ',
'ಸ್ಪರ್ಶೇಂದ್ರಿಯ',
'ಸ್ಪೃಹಣೀಯ',
'ಸ್ಪೃಹೆವೆಱು',
'ಸ್ಫಟಾಧರ',
'ಸ್ಫಟಾರತ್ನ',
'ಸ್ಫಟಿಕಾದ್ರಿ',
'ಸ್ಫಟಿಕಾಬ್ಜ',
'ಸ್ಪಟಿಕಾಶ್ಮ',
'ಸ್ಫಾಟಿಕಾದ್ರಿ',
'ಸ್ಫುಟಬಂಧ',
'ಸ್ಫುಟಹರ್ಷ',
'ಸ್ಫುಟಾಕ್ಷರ',
'ಸ್ಫುರದ್ರೂಪಿ',
'ಸ್ಫುರಿತಕ',
'ಸ್ಫುರಿಯಿಸು',
'ಸ್ಫೂರ್ತಿಗೆಡು',
'ಸ್ಫೋಟಗೊಳ್ಳು',
'ಸ್ಮರಕಲೆ',
'ಸ್ಮರಕಳೆ',
'ಸ್ಮರಗೃಹ',
'ಸ್ಮರಣೆಗೈ',
'ಸ್ಮರಯಂತ್ರ',
'ಸ್ಮರಿಯಿಸು',
'ಸ್ಮರೋದಕ',
'ಸ್ಮರೋನ್ಮಾದ',
'ಸ್ಮಾರ್ತಕರ್ಮ',
'ಸ್ಮಾರ್ತವಿಧಿ',
'ಸ್ಮೃತಿಕಾರ',
'ಸ್ಮೃತಿಪಥ',
'ಸ್ಮೃತಿಭ್ರಂಶ',
'ಸ್ಮೇರಮುಖ',
'ಸ್ಯಮಂತಕ',
'ಸ್ಯಾನಭೋಗ',
'ಸ್ರೋತಃಪತಿ',
'ಸ್ರೋತಸ್ವಿನಿ',
'ಸ್ವಗೃಹಸ್ಥ',
'ಸ್ವಚ್ಛಂದತೆ',
'ಸ್ವತಂತ್ರಿಕೆ',
'ಸ್ವತಂತ್ರಿಸು',
'ಸ್ವತಃಸಿದ್ಧ',
'ಸ್ವತಸ್ಸಿದ್ಧ',
'ಸ್ವದಸ್ತೂರ',
'ಸ್ವಧಾಕಾರ',
'ಸ್ಪಪ್ನಜೀವಿ',
'ಸ್ವಪ್ನತಂತ್ರ',
'ಸ್ವಪ್ನದೋಷ',
'ಸ್ವಪ್ನಲೋಕ',
'ಸ್ವಪ್ನಾವಸ್ಥೆ',
'ಸ್ವಪ್ರಕಾಶ',
'ಸ್ವಪ್ರತಿಷ್ಠೆ',
'ಸ್ವಪ್ರಯತ್ನ',
'ಸ್ವಪ್ರಶಂಸೆ',
'ಸ್ವಪ್ರೇರಣೆ',
'ಸ್ವಭಾವಜ್ಞ',
'ಸ್ವಭಾವಜ್ಞೆ',
'ಸ್ವಭಾವತಃ',
'ಸ್ವಭಾವೋಕ್ತಿ',
'ಸ್ವಯಂಕರ್ತೃ',
'ಸ್ವಯಂಕೃತ',
'ಸ್ವಯಂಪಾಕ',
'ಸ್ವಯಂಪಾಕಿ',
'ಸ್ವಯಂಪ್ರಭು',
'ಸ್ವಯಂಬರ',
'ಸ್ವಯಂಬುದ್ಧ',
'ಸ್ವಯಂವರ',
'ಸ್ವಯಂವರೆ',
'ಸ್ವಯಂವೇದ್ಯ',
'ಸ್ವಯಂಸಿದ್ಧ',
'ಸ್ವಯಂಸೇವೆ',
'ಸ್ವಯಲಿಂಗ',
'ಸ್ವಯಸುಖ',
'ಸ್ವಯಾನಂದ',
'ಸ್ವಯಾರ್ಜಿತ',
'ಸ್ವರಗೆಯ್',
'ಸ್ವರಜತಿ',
'ಸ್ವರಜಾಲ',
'ಸ್ವರಜ್ಞಾನ',
'ಸ್ವರದೋಷ',
'ಸ್ವರಧಾತು',
'ಸ್ವರಪಂಪ',
'ಸ್ವರಬತ್',
'ಸ್ವರಬತ್ತು',
'ಸ್ವರಬಾತ್',
'ಸ್ವರಭಂಗ',
'ಸ್ವರಭಕ್ತಿ',
'ಸ್ವರಭಾರ',
'ಸ್ವರಭೇದ',
'ಸ್ವರಮೇಳ',
'ಸ್ವರಮೈತ್ರಿ',
'ಸ್ವರಯೋಗ',
'ಸ್ವರಲಿಪಿ',
'ಸ್ವರವಾದಿ',
'ಸ್ವರವೀಣೆ',
'ಸ್ವರಶುದ್ಧಿ',
'ಸ್ವರಸಂಧಿ',
'ಸ್ವರಸಾದ',
'ಸ್ವರಾಂತರ',
'ಸ್ವರಾಕ್ಷರ',
'ಸ್ವರಾವಳಿ',
'ಸ್ವರಾಷ್ಟಕ',
'ಸ್ವರ್ಗಕಾಮ',
'ಸ್ವರ್ಗಲೋಕ',
'ಸ್ವರ್ಗವಾಸಿ',
'ಸ್ವರ್ಗಾಲಯ',
'ಸ್ವರ್ಣಕಾರ',
'ಸ್ವರ್ಣಕ್ಷೀರಿ',
'ಸ್ವರ್ಣಗೌರಿ',
'ಸ್ವರ್ಣನದಿ',
'ಸ್ವರ್ಣಪಾಲೆ',
'ಸ್ವರ್ಣಪುಷ್ಪಿ',
'ಸ್ವರ್ಣಮಾನ',
'ಸ್ವರ್ಣಯುಗ',
'ಸ್ವಸ್ತ್ಯಯನ',
'ಸ್ವಾಗತಿಸು',
'ಸ್ವಾತಿಮಳೆ',
'ಸ್ವಾತಿವರ್ಷ',
'ಸ್ವಾದನೀಯ',
'ಸ್ವಾದುರಸೆ',
'ಸ್ವಾಧಿಕಾರ',
'ಸ್ವಾಧಿಪತ್ಯ',
'ಸ್ವಾಧಿಷ್ಠಾನ',
'ಸ್ವಾಧೀನತೆ',
'ಸ್ವಾನುಭವ',
'ಸ್ವಾನುಭಾವ',
'ಸ್ವಾನುಭೂತಿ',
'ಸ್ವಾಪತೇಯ',
'ಸ್ವಾಪವಡೆ',
'ಸ್ವಾಭಾವಿಕ',
'ಸ್ವಾಭಿಮಾನ',
'ಸ್ವಾಭಿಮಾನಿ',
'ಸ್ವಾಮಿದ್ರೋಹ',
'ಸ್ವಾಮಿದ್ರೋಹಿ',
'ಸ್ವಾಮಿಭಕ್ತಿ',
'ಸ್ವಾಯಂಭುವ',
'ಸ್ವಾಯತ್ತತೆ',
'ಸ್ವಾರ್ಥಪರ',
'ಸ್ವಾರ್ಥಪರೆ',
'ಸ್ವಾವಲಂಬಿ',
'ಸ್ವಾಹಾಕಾರ',
'ಸ್ವಾಹಾಪತಿ',
'ಸ್ವೀಕರಣ',
'ಸ್ವೀಕರಿಸು',
'ಸ್ವೇಚ್ಛಾಚಾರ',
'ಸ್ವೇಚ್ಛಾಚಾರಿ',
'ಸ್ವೇಚ್ಛಾಮೃತ್ಯು',
'ಸ್ವೇಚ್ಛಾಲಾಪ',
'ಸ್ವೇಚ್ಚಾವೃತ್ತಿ',
'ಸ್ವೇದಜಲ',
'ಸ್ವೇದನಿಕೆ',
'ಸ್ವೈರಚರ',
'ಸ್ವೈರಚಾರ',
'ಸ್ವೈರವೃತ್ತಿ',
'ಸ್ವೈರಾಲಾಪ',
'ಸ್ವೋಪಜ್ಞತೆ',
'ಹಂಕಾರಿಸು',
'ಹಂಗನೂಲು',
'ಹಂಗರಲು',
'ಹಂಗರಿಕೆ',
'ಹಂಗರ್ಕಟ್ಟೆ',
'ಹಂಗುದೊರೆ',
'ಹಂಗುವಡು',
'ಹಂಗುಹರಿ',
'ಹಂಗುಹಱಿ',
'ಹಂಗುಳಿಕೆ',
'ಹಂಚಳಿಗೆ',
'ಹಂಚಿಕಡ್ಡಿ',
'ಹಂಚಿಬುಲ್ಲು',
'ಹಂಚಿಹುಲ್ಲು',
'ಹಂಚುಬಡಿ',
'ಹಂಜಮಾನ',
'ಹಂಜರಿಸು',
'ಹಂಜಾರಿಕೆ',
'ಹಂಜಿಗುಕ್ಕೆ',
'ಹಂಡಬಂಡ',
'ಹಂಡಬಂಡು',
'ಹಂಡಹಕ್ಕಿ',
'ಹಂಡಹುಂಡ',
'ಹಂತಿಕಟ್ಟು',
'ಹಂತಿಕಾಱ',
'ಹಂತಿಕುಂಟೆ',
'ಹಂತಿಗಟ್ಟು',
'ಹಂತಿಗಣ್ಣಿ',
'ಹಂತಿಗಾಱ',
'ಹಂತಿಗೊಳ್',
'ಹಂತಿಗೊಳ್ಳು',
'ಹಂತಿದಪ್ಪು',
'ಹಂತಿಯೂಟ',
'ಹಂತಿವಿಡಿ',
'ಹಂತಿಹೊಡೆ',
'ಹಂತುಗೊಳ್',
'ಹಂದರಹೂ',
'ಹಂದರಿಕ್ಕು',
'ಹಂದಿಕೀರೆ',
'ಹಂದಿಗಡ್ಡೆ',
'ಹಂದಿಗೀರ',
'ಹಂದಿಗೆಡ್ಡೆ',
'ಹಂದಿತುಪ್ಪ',
'ಹಂದಿದಾಟು',
'ಹಂದಿಬಳ್ಳಿ',
'ಹಂದಿಮೀನು',
'ಹಂದಿವಲೆ',
'ಹಂದೆತನ',
'ಹಂದೊಗಲ್',
'ಹಂಪುಗಾಲ',
'ಹಂಪುಪಡೆ',
'ಹಂಬಚಾವು',
'ಹಂಬಲಿಕೆ',
'ಹಂಬಲಿಸು',
'ಹಂಬುಕಳ್ಳಿ',
'ಹಂಬುಬಳ್ಳಿ',
'ಹಂಬುಸೊಂಡೆ',
'ಹಂಸಗತಿ',
'ಹಂಸಗೀತೆ',
'ಹಂಸಗ್ರೀವ',
'ಹಂಸಚ್ಛದ',
'ಹಂಸತೂಲ',
'ಹಂಸತೂಳ',
'ಹಂಸಧ್ವನಿ',
'ಹಂಸಪಟ್ಟೆ',
'ಹಂಸಪದ',
'ಹಂಸಪದಿ',
'ಹಂಸಪಾದ',
'ಹಂಸಪಾದಿ',
'ಹಂಸಬೆಂಡಿ',
'ಹಂಸಭೇದ',
'ಹಂಸಮಂತ್ರ',
'ಹಂಸಮತ್ತ',
'ಹಂಸಯಾನೆ',
'ಹಂಸರಾಜ',
'ಹಂಸರುತಿ',
'ಹಂಸವ್ಯೂಹ',
'ಹಂಸಾವಳಿ',
'ಹಂಸೀಭಾವ',
'ಹಂಹಗೆಯ್ತ',
'ಹಕನಾಕ',
'ಹಕಳಾಡು',
'ಹಕೀಕತ್',
'ಹಕೀಕತು',
'ಹಕೀಕತ್ತು',
'ಹಕ್ಕರಕಿ',
'ಹಕ್ಕರಕ್ಕೆ',
'ಹಕ್ಕರಿಕೆ',
'ಹಕ್ಕರಿಕ್ಕೆ',
'ಹಕ್ಕರಿಗೆ',
'ಹಕ್ಕಲಾಯು',
'ಹಕ್ಕಲಿಕೆ',
'ಹಕ್ಕಶಾಯಿ',
'ಹಕ್ಕಿಕಾರ',
'ಹಕ್ಕಿನೋಟ',
'ಹಕ್ಕಿಪಿಕ್ಕಿ',
'ಹಕ್ಕಿಬಿಕ್ಕಿ',
'ಹಕ್ಕಿರೋಗ',
'ಹಕ್ಕುಗಟ್ಟು',
'ಹಕ್ಕುದಾರ',
'ಹಕ್ಕುದಾರಿ',
'ಹಕ್ಕುಪತ್ರ',
'ಹಕ್ಕುಶಾಯಿ',
'ಹಕ್ಕೆಗೊಳ್',
'ಹಕ್ಕೆದಲೆ',
'ಹಕ್ಕೆದಾಣ',
'ಹಕ್ಕೆರಲು',
'ಹಕ್ಕೇರಲು',
'ಹಕ್ಕೊತ್ತಾಯ',
'ಹಕ್ಲುಗದ್ದೆ',
'ಹಗಣಾಟ',
'ಹಗಣಿಗ',
'ಹಗರಣ',
'ಹಗರಣಿ',
'ಹಗರಣೆ',
'ಹಗಲೇರು',
'ಹಗಱಿಕ್ಕು',
'ಹಗಱಿಡು',
'ಹಗೆಗಾರ',
'ಹಗೆಗೆಡು',
'ಹಗೆಗೊಳ್',
'ಹಗೆಗೊಳ್ಳು',
'ಹಗೆತನ',
'ಹಗೆವಡೆ',
'ಹಗೆಹರಿ',
'ಹಚ್ಚಡಿಕೆ',
'ಹಚ್ಚಡಿಸು',
'ಹಚ್ಚನೆಯ',
'ಹಚ್ಚವಡ',
'ಹಚ್ಚವಡಿ',
'ಹಚ್ಚಹಸಿ',
'ಹಚ್ಚುಕೊಳ್',
'ಹಚ್ಚೆಚುಚ್ಚು',
'ಹಚ್ಚೆಬಟ್ಟು',
'ಹಚ್ಚೆಬೊಟ್ಟು',
'ಹಚ್ಚೆಹೊಯ್',
'ಹಜರತ್',
'ಹಜರತು',
'ಹಜರತ್ತ',
'ಹಜಾಮತ್',
'ಹಜಾಮತಿ',
'ಹಜಾಮತ್ತು',
'ಹಜ್ಜೆದೆಗೆ',
'ಹಜ್ಜೆಯಿಡು',
'ಹಜ್ಜೆವಿಡಿ',
'ಹಜ್ಜೆಹಾಕು',
'ಹಟಗಾತಿ',
'ಹಟಗಾರ',
'ಹಟಮಾರಿ',
'ಹಟಯೋಗ',
'ಹಟಯೋಗಿ',
'ಹಟವಾದಿ',
'ಹಟವಿಡಿ',
'ಹಟಹಿಡಿ',
'ಹಟಾತ್ಕಾರ',
'ಹಟೆಕಾರ',
'ಹಟ್ಟಗಾಱ',
'ಹಟ್ಟಿಕಾರ',
'ಹಟ್ಟಿಕಾಱ',
'ಹಟ್ಟಿಕೊಟ್ಟ',
'ಹಟ್ಟಿಗಾಳೆ',
'ಹಟ್ಟಿಮನೆ',
'ಹಟ್ಟೆಗಾರ',
'ಹಠಗಾತಿ',
'ಹಠಗಾರ',
'ಹಠಮಾರಿ',
'ಹಠಯೋಗ',
'ಹಠಯೋಗಿ',
'ಹಠವೃತ್ತಿ',
'ಹಠಾತ್ಕಾರ',
'ಹಠಾತ್ತನೆ',
'ಹಡಕಿಗ',
'ಹಡಣಿಗೆ',
'ಹಡಪಾಳಿ',
'ಹಡಪಿಗ',
'ಹಡಲಗೆ',
'ಹಡಲಿಕೆ',
'ಹಡಲಿಗೆ',
'ಹಡವಳ',
'ಹಡವಳ್ತಿ',
'ಹಡಸಲೆ',
'ಹಡಸಾಲೆ',
'ಹಡಹಾಳಿ',
'ಹಡಳಿಗೆ',
'ಹಡಿಕಟ್ಟು',
'ಹಡಿಗಣ್ಣು',
'ಹಡಿಗಿಱು',
'ಹಡಿತಾರ',
'ಹಡಿದೆಱೆ',
'ಹಡಿಮುಖ',
'ಹಡಿಮೊಖ',
'ಹಡಿಯರ',
'ಹಡಿಯಱ',
'ಹಡಿಯಾಱ',
'ಹಡಿಹಾಱ',
'ಹಡುಕುಳಿ',
'ಹಡುಬೀಳು',
'ಹಡುಭೂಮಿ',
'ಹಡೆಮಾತು',
'ಹಡೆಯಿಸು',
'ಹಡೆವಳ',
'ಹಡ್ಡಲಗೆ',
'ಹಡ್ಡಲಿಗೆ',
'ಹಣಕಾಸು',
'ಹಣಗಟ್ಟು',
'ಹಣಗಾತಿ',
'ಹಣಗಾರ',
'ಹಣತೂಕ',
'ಹಣಬಿರಿ',
'ಹಣಮಂತ',
'ಹಣಮುಟ್ಟು',
'ಹಣರುಣ',
'ಹಣವಂತ',
'ಹಣವಡ',
'ಹಣವಡ್ಡ',
'ಹಣಾಹಣಿ',
'ಹಣಿಕಿಕ್ಕು',
'ಹಣಿಕಿಸು',
'ಹಣುಮಂತ',
'ಹಣೆಕಟ್ಟು',
'ಹಣೆಗಣ್ಣ',
'ಹಣೆಗಣ್ಣು',
'ಹಣೆಗುತ್ತಿ',
'ಹಣೆಚಾಚು',
'ಹಣೆಪಟ್ಟಿ',
'ಹಣೆಬಟ್ಟು',
'ಹಣೆಯಕ್ಕಿ',
'ಹಣೆಯಿಕ್ಕು',
'ಹಣೆಯೊಂದು',
'ಹಣೆಹಗ್ಗ',
'ಹಣೆಹಚ್ಚು',
'ಹಣ್ಗೊಳಿಸು',
'ಹಣ್ಣಱುವೆ',
'ಹಣ್ಣಿಗಿಡ',
'ಹಣ್ಣುಗಣ್ಣು',
'ಹಣ್ಣುಗಾಯ',
'ಹಣ್ಣುಗಾಯಿ',
'ಹಣ್ಣುನನ್ನೆ',
'ಹಣ್ಣುವರಿ',
'ಹತಪ್ರಭ',
'ಹತಬಲ',
'ಹತಬುದ್ಧ',
'ಹತಭಾಗ್ಯ',
'ಹತಮತಿ',
'ಹತಮನ',
'ಹತಾಶತೆ',
'ಹತಾಹತಿ',
'ಹತಿಗೆಯ್',
'ಹತಿಯಾರು',
'ಹತಿಯಿಸು',
'ಹತ್ತಂಬಳ್ಳಿ',
'ಹತ್ತಂಬಾಳ',
'ಹತ್ತಕಟ್ಟು',
'ಹತ್ತಗಟ್ಟು',
'ಹತ್ತಗಡೆ',
'ಹತ್ತರಿಕೆ',
'ಹತ್ತವಸ',
'ಹತ್ತಾಹತ್ತಿ',
'ಹತ್ತಿಕಾಯಿ',
'ಹತ್ತಿಕಾಳು',
'ಹತ್ತಿಹುಲ್ಲು',
'ಹತ್ತುಗಡೆ',
'ಹತ್ತುಮೀನು',
'ಹತ್ತೆಗಟ್ಟು',
'ಹತ್ತೆವಿಡಿ',
'ಹತ್ತೆಹಿಡಿ',
'ಹತ್ತೊಂಬತ್ತು',
'ಹತ್ಯಾಕಾಂಡ',
'ಹದಗಾತಿ',
'ಹದಗಾಯಿ',
'ಹದಗಾರ',
'ಹದಗಾಸು',
'ಹದಗಾಱ',
'ಹದಗಿಡು',
'ಹದಗುದಿ',
'ಹದಗೆಡು',
'ಹದಗೆಯ್',
'ಹದಗೊಡು',
'ಹದಗೊಳ್ಳು',
'ಹದತಪ್ಪು',
'ಹದದೋರೆ',
'ಹದನರಿ',
'ಹದನಱಿ',
'ಹದಬೆದೆ',
'ಹದಮೀರು',
'ಹದರಿಕ್ಕು',
'ಹದವರಿ',
'ಹದವಳಿ',
'ಹದವಱಿ',
'ಹದಿನಯ್ದು',
'ಹದಿನಾಕು',
'ಹದಿನಾರು',
'ಹದಿನಾಲ್ಕು',
'ಹದಿನಾಱು',
'ಹದಿನೆಂಟಿ',
'ಹದಿನೆಂಟು',
'ಹದಿನೇಳು',
'ಹದಿನೇೞು',
'ಹದಿನೈದು',
'ಹದಿಬತೆ',
'ಹದಿಬದೆ',
'ಹದಿಮೂರು',
'ಹದಿಮೂಱು',
'ಹದಿರಾಟ',
'ಹದಿರಿಕ್ಕು',
'ಹದಿರಿಸು',
'ಹದಿರ್ಗೊಳ್ಳು',
'ಹದಿವಡು',
'ಹದುಗಿಸು',
'ಹದುಳಿಗ',
'ಹದುಳಿದ',
'ಹದುಳಿರ್',
'ಹದುಳಿಸು',
'ಹದ್ದುಗಟ್ಟು',
'ಹದ್ದುಗಣ್ಣು',
'ಹದ್ದುಪಾರು',
'ಹದ್ದುಬಸ್ತು',
'ಹದ್ದುಮೀರು',
'ಹದ್ದುಮೀಱು',
'ಹನಗುಂದ',
'ಹನಿಕಪ್ಪೆ',
'ಹನಿಕಿಸು',
'ಹನಿಗಣ್ಣು',
'ಹನಿಗೂಡು',
'ಹನಿಬ್ಬರು',
'ಹನಿಮಳೆ',
'ಹನಿಹೊತ್ತು',
'ಹನುಕಲು',
'ಹನುಕಿಸು',
'ಹನುಚಕ್ರ',
'ಹನುಮಂತ',
'ಹನುಮತ್ತು',
'ಹನುಮಿಸು',
'ಹನುರೋಮ',
'ಹನೆಬೊಂಡ',
'ಹನೆಹೆಡೆ',
'ಹನ್ನವಣ',
'ಹನ್ನಿಬ್ಬರ್',
'ಹನ್ನಿಬ್ಬರು',
'ಹನ್ನಿರ್ಕಯಿ',
'ಹನ್ನುಸಿಗ',
'ಹನ್ನೆರಡು',
'ಹಪತಾಪ',
'ಹಪಹಪ',
'ಹಪಹಪಿ',
'ಹಪಾಪಿಸು',
'ಹಪ್ತೆಕೊಡು',
'ಹಪ್ಪಳಿಕೆ',
'ಹಪ್ಪಳಿಗೆ',
'ಹಪ್ಪುಕಳ',
'ಹಬೆಗಣ್ಣು',
'ಹಬ್ಬಸಿಗ',
'ಹಬ್ಬಹಾಡಿ',
'ಹಬ್ಬಿಸಿಗ',
'ಹಬ್ಬುಗುಳ್ಳ',
'ಹಬ್ಬುಗೊಳ್ಳು',
'ಹಬ್ಬುಗೋಳಿ',
'ಹಬ್ಬುಸುಂಡೆ',
'ಹಬ್ಬುಸೊಂಡೆ',
'ಹಮ್‍ಚೌಕು',
'ಹಮಚೌಕು',
'ಹಮರಹ',
'ಹಮಾಂಕೋಮು',
'ಹಮಾಕೋಮು',
'ಹಮ್ಮಚೌಕು',
'ಹಮ್ಮಯಿಸು',
'ಹಮ್ಮವಿಸು',
'ಹಮ್ಮಳಿಸು',
'ಹಮ್ಮುಗೆಡು',
'ಹಮ್ಮುಗೊಳು',
'ಹಮ್ಮುಬಿಮ್ಮು',
'ಹಮ್ಮುಹೆಮ್ಮೆ',
'ಹಯಗಂಧಿ',
'ಹಯಗ್ರೀವ',
'ಹಯದಳ',
'ಹಯದೞ',
'ಹಯಪ್ರಾಸ',
'ಹಯಮಾರ',
'ಹಯಮೇಧ',
'ಹಯರಕ್ಷೆ',
'ಹಯಶಾಸ್ತ್ರ',
'ಹಯಶಿಕ್ಷೆ',
'ಹರಂಗೆಯ್',
'ಹರಕತು',
'ಹರಕತ್ತು',
'ಹರಕೋಲು',
'ಹರಗಣ',
'ಹರಗಣೆ',
'ಹರಗಲು',
'ಹರಗಿರಿ',
'ಹರಗೋಲು',
'ಹರಚಿತ',
'ಹರಡಿಕೆ',
'ಹರಡಿಸು',
'ಹರತಾಳ',
'ಹರದಾಡು',
'ಹರದಾರಿ',
'ಹರದಾಳಿ',
'ಹರದಿಕೆ',
'ಹರದಿಗ',
'ಹರದೀಕ್ಷೆ',
'ಹರದೇಶಿ',
'ಹರದೇಸಿ',
'ಹರನಿಷ್ಠ',
'ಹರನೇತ್ರ',
'ಹರಲುಗ',
'ಹರವಂಚು',
'ಹರವಡಿ',
'ಹರವರಿ',
'ಹರವಸ',
'ಹರಸಾಲು',
'ಹರಳಿಗ',
'ಹರಳಿಸು',
'ಹರಳುಪ್ಪು',
'ಹರಳೆಣ್ಣೆ',
'ಹರಳ್ವಕ್ಕಿ',
'ಹರಾವರಿ',
'ಹರಿಒಲೆ',
'ಹರಿಕಂಠ',
'ಹರಿಕಣಿ',
'ಹರಿಕಥೆ',
'ಹರಿಕಾಂತ',
'ಹರಿಕಾರ',
'ಹರಿಕಾಱ',
'ಹರಿಕುಣಿ',
'ಹರಿಕುಶ',
'ಹರಿಕುಳಿ',
'ಹರಿಕೇಣಿ',
'ಹರಿಗಡಿ',
'ಹರಿಗಲು',
'ಹರಿಗುಟ್ಟು',
'ಹರಿಗೆಡು',
'ಹರಿಗೇಶ',
'ಹರಿಗೋಪ',
'ಹರಿಗೋಲು',
'ಹರಿಜನ',
'ಹರಿಜಾತೆ',
'ಹರಿಣಾಂಕ',
'ಹರಿಣಾಕ್ಷಿ',
'ಹರಿತಕ',
'ಹರಿತರ್',
'ಹರಿತರು',
'ಹರಿತಾನ್ನ',
'ಹರಿತಾಲ',
'ಹರಿತಾಲಿ',
'ಹರಿತಾಳ',
'ಹರಿದಂತ',
'ಹರಿದಶ್ವ',
'ಹರಿದಳ',
'ಹರಿದಾಟ',
'ಹರಿದಾಡು',
'ಹರಿದಾರಿ',
'ಹರಿದಾಸ',
'ಹರಿದಾಸಿ',
'ಹರಿದಾಳಿ',
'ಹರಿದಿನ',
'ಹರಿದ್ರತ್ನ',
'ಹರಿದ್ರಾಕ್ಷೆ',
'ಹರಿದ್ರುಮ',
'ಹರಿದ್ವಸು',
'ಹರಿದ್ವಾರ',
'ಹರಿನಾಳಿ',
'ಹರಿನೀರು',
'ಹರಿನೀಲ',
'ಹರಿನೀಳ',
'ಹರಿನೇತ್ರ',
'ಹರಿನ್ಮಣಿ',
'ಹರಿಪದ',
'ಹರಿಪೀಠ',
'ಹರಿಪ್ರಿಯೆ',
'ಹರಿಬಿಡು',
'ಹರಿಬೀಳು',
'ಹರಿಭೂಮ',
'ಹರಿಮಂಥ',
'ಹರಿಮಣಿ',
'ಹರಿಮುರಿ',
'ಹರಿಯಕ್ಷ',
'ಹರಿಯಣ',
'ಹರಿಯಾಗೆ',
'ಹರಿಯಾಣ',
'ಹರಿಯಿಡು',
'ಹರಿಯಿಸು',
'ಹರಿಯೊಲೆ',
'ಹರಿವಂಶ',
'ಹರಿವಡಿ',
'ಹರಿವಣ',
'ಹರಿವರಿ',
'ಹರಿವರ್ಷ',
'ಹರಿವಲೆ',
'ಹರಿವಾಣ',
'ಹರಿವಾಸ',
'ಹರಿವಾಹ',
'ಹರಿವಾಳ',
'ಹರಿವಿಡು',
'ಹರಿವೆಸ',
'ಹರಿವೇಗ',
'ಹರಿವೊಲೆ',
'ಹರಿಶೃಂಗಿ',
'ಹರಿಶ್ಚಂದ್ರ',
'ಹರಿಸಂಗ',
'ಹರಿಹಂಚು',
'ಹರಿಹಯ',
'ಹರಿಹರ',
'ಹರಿಹಾಯ್',
'ಹರಿಹಾಯಿ',
'ಹರಿಹಾಯು',
'ಹರೀತಕಿ',
'ಹರುಕಣಿ',
'ಹರುಕಲು',
'ಹರುಗೋಲು',
'ಹರುಷನ',
'ಹರುಷಾಶ್ರು',
'ಹರುಷಿತ',
'ಹರುಷಿತೆ',
'ಹರುಷಿಸು',
'ಹರೆಕಾರ',
'ಹರೆಕಾಱ',
'ಹರೆಗಡಿ',
'ಹರೆಗಳೆ',
'ಹರೆಯಿಸು',
'ಹರೇಣುಕ',
'ಹರ್ಷಂದಾಳು',
'ಹರ್ಷಗರ್ಭ',
'ಹರ್ಷಪಡು',
'ಹರ್ಷೋತ್ಕರ್ಷ',
'ಹರ್ಷೋದ್ಗಾರ',
'ಹಲಕಾಲ',
'ಹಲಗಾಲ',
'ಹಲದಂಡ',
'ಹಲಧರ',
'ಹಲಯೋಗ',
'ಹಲರೇಖೆ',
'ಹಲವರಿ',
'ಹಲವಷ್ಟು',
'ಹಲವಳಿ',
'ಹಲಸಿಗ',
'ಹಲಹಸ್ತ',
'ಹಲಾಯುಧ',
'ಹಲಾಸನ',
'ಹಲಾಹಲ',
'ಹಲಿನೀಲ',
'ಹಲಿಪ್ರಿಯ',
'ಹಲಿಪ್ರಿಯೆ',
'ಹಲಿಯುಳಿ',
'ಹಲಿವಳಿ',
'ಹಲಿವುಳಿ',
'ಹಲೀಮಕ',
'ಹಲುಕಡಿ',
'ಹಲುಕಡ್ಡಿ',
'ಹಲುಗಳೆ',
'ಹಲುಗಿರಿ',
'ಹಲುದಿನ್',
'ಹಲುದಿನು',
'ಹಲುದೆಱೆ',
'ಹಲುದೋಱು',
'ಹಲುಬಾಟ',
'ಹಲುಬೇನೆ',
'ಹಲುಮೊರೆ',
'ಹಲಸಂದೆ',
'ಹಲುಸಲ್ಲೆ',
'ಹಲೆವುಳಿ',
'ಹಲ್ಕಾತನ',
'ಹಲ್ಲಣಿಗೆ',
'ಹಲ್ಲಣಿಸು',
'ಹಲ್ಲೀಶಕ',
'ಹಲ್ಲೀಶಿಕ್ಕಾ',
'ಹಲ್ಲುಕಚ್ಚು',
'ಹಲ್ಲುಕಡಿ',
'ಹಲ್ಲುಕಡ್ಡಿ',
'ಹಲ್ಲುಕಪ್ಪು',
'ಹಲ್ಲುಕಿರಿ',
'ಹಲ್ಲುಕಿಸಿ',
'ಹಲ್ಲುಗಿಂಜು',
'ಹಲ್ಲುಗಿರಿ',
'ಹಲ್ಲುದ್ದಿಕೆ',
'ಹಲ್ಲುನುಲ್ಲಿ',
'ಹಲ್ಲುನೋವು',
'ಹಲ್ಲುಪುಡಿ',
'ಹಲ್ಲುಬಳ್ಳಿ',
'ಹಲ್ಲುಬೇನೆ',
'ಹಲ್ಲುಮಟ್ಟೆ',
'ಹಲ್ಲುಮಸೆ',
'ಹಲ್ಲುಮಿಡಿ',
'ಹಲ್ಲುಮುಡಿ',
'ಹಲ್ಲುಮುರಿ',
'ಹಲ್ಲುಮೊಟ್ಟೆ',
'ಹಲ್ಲುಶೂಲೆ',
'ಹಲ್ಲುಸುಂಡೆ',
'ಹಲ್ಲುಹಚ್ಚು',
'ಹಲ್ಲುಹುಳು',
'ಹಲ್ಲೆಬಳ್ಳಿ',
'ಹವಣರಿ',
'ಹವಣಱಿ',
'ಹವಣಿಕೆ',
'ಹವಣಿತು',
'ಹವಣಿತ್ತು',
'ಹವಣಿಸು',
'ಹವಣುಗ',
'ಹವಾಗುಣ',
'ಹವಾಮಾನ',
'ಹವಾಲತೆ',
'ಹವಾಲಿಸು',
'ಹವಾಲ್ದಾರ',
'ಹವಾಸೀರ',
'ಹವಿಗೊಳ್',
'ಹವಿನೇತ್ರ',
'ಹವಿರ್ಭಾಗ',
'ಹವಿರ್ಭುಜ',
'ಹವ್ಯವಾಹ',
'ಹವ್ಯಾಶನ',
'ಹವ್ವಳಿಸು',
'ಹಸಂಗೆಡು',
'ಹಸಂಗೆಯ್',
'ಹಸಂತಿಕೆ',
'ಹಸಂತಿಗೆ',
'ಹಸಗಾರ',
'ಹಸಗಾಲ',
'ಹಸಗೆಡು',
'ಹಸಗೆಯ್',
'ಹಸಗೊಳ್ಳು',
'ಹಸದನ',
'ಹಸನಗೈ',
'ಹಸನಿಗ',
'ಹಸನಿಸು',
'ಹಸನೀಲಿ',
'ಹಸನ್ಮುಖ',
'ಹಸನ್ಮುಖಿ',
'ಹಸರಣೆ',
'ಹಸರಾಣಿ',
'ಹಸರಿಕೆ',
'ಹಸರಿಗ',
'ಹಸರಿಸು',
'ಹಸರ್ವಾಣಿ',
'ಹಸವಳಿ',
'ಹಸಿದಡ್ಡ',
'ಹಸಿಬಸಿ',
'ಹಸಿಬಿಸಿ',
'ಹಸಿಯಕ್ಕಿ',
'ಹಸಿಯಲ್ಲ',
'ಹಸಿಯಳೆ',
'ಹಸಿಯಿಸು',
'ಹಸಿವಾಳೆ',
'ಹಸಿಶುಂಠಿ',
'ಹಸಿಸುಳ್ಳು',
'ಹಸಿಹಿಟ್ಟು',
'ಹಸಿಹುಲ್ಲು',
'ಹಸುಕೂಸು',
'ಹಸುಗೂಸು',
'ಹಸುತನ',
'ಹಸುಮಗ',
'ಹಸುಮಗು',
'ಹಸುಮೀನು',
'ಹಸುರಿಸು',
'ಹಸುರ್ಗಟ್ಟು',
'ಹಸುರ್ಗನ್ನೆ',
'ಹಸುರ್ಗೂಳು',
'ಹಸುವಾರ',
'ಹಸೆಗಲ್ಲು',
'ಹಸೆನಿಲ್',
'ಹಸೆನಿಲ್ಲು',
'ಹಸೆಮಣೆ',
'ಹಸೆವಣೆ',
'ಹಸೆಹಾಡು',
'ಹಸೆಹಿಡಿ',
'ಹಸ್ತಕಟ್ಟು',
'ಹಸ್ತಕ್ಷೇಪ',
'ಹಸ್ತಗಟ್ಟು',
'ಹಸ್ತಗತ',
'ಹಸ್ತಗುಣ',
'ಹಸ್ತಚ್ಛಟೆ',
'ಹಸ್ತತಲ',
'ಹಸ್ತತಳ',
'ಹಸ್ತನ್ಯಸ್ತ',
'ಹಸ್ತಪತ್ರ',
'ಹಸ್ತಪ್ರತಿ',
'ಹಸ್ತಬಿಂಬ',
'ಹಸ್ತಮುದ್ರೆ',
'ಹಸ್ತರೇಖೆ',
'ಹಸ್ತಲಾಗ',
'ಹಸ್ತಲಾಘ',
'ಹಸ್ತಶುದ್ಧಿ',
'ಹಸ್ತಸಂಜ್ಞೆ',
'ಹಸ್ತಸರ',
'ಹಸ್ತಸೂತ್ರ',
'ಹಸ್ತಾಂತರ',
'ಹಸ್ತಾಕ್ಷರ',
'ಹಸ್ತಾಹಸ್ತಿ',
'ಹಸ್ತಿಕಂದ',
'ಹಸ್ತಿಕರ್ಣಿ',
'ಹಸ್ತಿಗಂಧಿ',
'ಹಸಿಗನ್ನೆ',
'ಹಸ್ತಿಘಟೆ',
'ಹಸ್ತಿತಂತ್ರ',
'ಹಸ್ತಿದಂತ',
'ಹಸ್ತಿನಖ',
'ಹಸ್ತಿಪಕ',
'ಹಸ್ತಿಪಾದ',
'ಹಸ್ತಿಮೂರ್ಖ',
'ಹಸ್ತಿರದ',
'ಹಸ್ತಿಶಾಲೆ',
'ಹಸ್ತೋದಕ',
'ಹಸ್ತ್ಯಾರೋಹ',
'ಹಳಗಾಗು',
'ಹಳಗಿಸು',
'ಹಳಚಗೆ',
'ಹಳಚನೆ',
'ಹಳಚಿಕೆ',
'ಹಳಚಿಗೆ',
'ಹಳಚ್ಚಗೆ',
'ಹಳಧರ',
'ಹಳಮದೆ',
'ಹಳಮೂಕ',
'ಹಳಮೊರೆ',
'ಹಳಯಿಗೆ',
'ಹಳವಂಡ',
'ಹಳವಳ',
'ಹಳವಾತು',
'ಹಳವಾರ',
'ಹಳವಾರಿ',
'ಹಳವಿಗೆ',
'ಹಳವಿಸು',
'ಹಳಸಲು',
'ಹಳಹಳ',
'ಹಳಹಳಿ',
'ಹಳಹಳೆ',
'ಹಳಾಹಳಿ',
'ಹಳಿಬಂಡಿ',
'ಹಳಿಲನೆ',
'ಹಳುಮಯ',
'ಹಳುವಟೆ',
'ಹಳುವಡು',
'ಹಳುವರ',
'ಹಳುವಾಯಿ',
'ಹಳುವಾರ',
'ಹಳುಹಳು',
'ಹಳುಹಾಯ್',
'ಹಳೆತಲೆ',
'ಹಳೆದಿನ',
'ಹಳೆನಂಟು',
'ಹಳೆಪೈಕ',
'ಹಳೆಮಾತು',
'ಹಳೆಹುಲಿ',
'ಹಳೇಪೈಕ',
'ಹಳ್ಳಂಬರಿ',
'ಹಳ್ಳಿಕಾರ',
'ಹಳ್ಳಿಕಾಱ',
'ಹಳ್ಳಿಗಾಡ',
'ಹಳ್ಳಿಗಾಡು',
'ಹಳ್ಳಿಮುಕ್ಕ',
'ಹಳ್ಳಿವಾಡ',
'ಹಱಗೋಲು',
'ಹಱಿಕಾಱ',
'ಹಱಿಗಂಡು',
'ಹಱಿಗಡಿ',
'ಹಱಿಗವಿ',
'ಹಱಿಗೊಯಿ',
'ಹಱಿಗೋಲು',
'ಹಱಿತಿನ್',
'ಹಱಿದಲೆ',
'ಹಱಿಪಡು',
'ಹಱಿಮಣ್',
'ಹಱಿಮಾತು',
'ಹಱಿಮುಱಿ',
'ಹಱಿಮೂಲೆ',
'ಹಱಿಯೆಸು',
'ಹಱಿಯೊದೆ',
'ಹಱಿವಾಯ್',
'ಹಱಿಸೂಱೆ',
'ಹಱಿಹಂಚು',
'ಹಱುಗಲ್',
'ಹಱುಗಾಣು',
'ಹಱುಗೊಳ್',
'ಹಱುಗೋಲ್',
'ಹಱುಗೋಲು',
'ಹಱೆಕಾಱ',
'ಹಱೆಗಾಱ',
'ಹಱೆಗುಟ್ಟು',
'ಹೞಯಿಗೆ',
'ಹೞವಿಗೆ',
'ಹಾಗಪಾಲು',
'ಹಾಜರಾತಿ',
'ಹಾಡಿಮದ್ದು',
'ಹಾಡುಕಾರ',
'ಹಾಡುಕಾಱ',
'ಹಾಡುಗಬ್ಬ',
'ಹಾಡುಗಾಯಿ',
'ಹಾಡುಗಾರ',
'ಹಾಡುಗಾರ್ತಿ',
'ಹಾಣಾಹಣಿ',
'ಹಾಣಾಹಾಣಿ',
'ಹಾತಾಳಿಸು',
'ಹಾತಕುಂಟಿ',
'ಹಾತಭಾಣು',
'ಹಾತೆಹುಳು',
'ಹಾದರಿಗ',
'ಹಾದಿಕಾಯು',
'ಹಾದಿಕಾರ',
'ಹಾದಿಗೆಡು',
'ಹಾದಿಗೊಳ್',
'ಹಾದಿತಪ್ಪು',
'ಹಾದಿತೆಗೆ',
'ಹಾದಿತೋರು',
'ಹಾದಿದೆಗೆ',
'ಹಾದಿದೋರು',
'ಹಾದಿನಡೆ',
'ಹಾದಿನೋಡು',
'ಹಾದಿಬಿಡು',
'ಹಾದಿಸುದ್ದಿ',
'ಹಾದಿಹಿಡಿ',
'ಹಾದಿಹೋಕ',
'ಹಾದಿಹೋಕೆ',
'ಹಾನಿಕರ',
'ಹಾಯಗಡು',
'ಹಾಯಿಕಿಸು',
'ಹಾಯಿಗಡ',
'ಹಾಯಿಗೊಳ್',
'ಹಾಯಿಚಲ',
'ಹಾಯಿದೋಣಿ',
'ಹಾಯಿಪಟ',
'ಹಾಯೆನಿಸು',
'ಹಾರಂಗೊಡು',
'ಹಾರಗಾಲು',
'ಹಾರಬೆಕ್ಕು',
'ಹಾರಯಿಕೆ',
'ಹಾರಯಿಸು',
'ಹಾರವಾಣ',
'ಹಾರವಿತಿ',
'ಹಾರವಿತ್ತಿ',
'ಹಾರವಿಸು',
'ಹಾರಹನಿ',
'ಹಾರಹೂರ',
'ಹಾರಾಡಿಸು',
'ಹಾರಿಂಚರ',
'ಹಾರಿಕಾಯ',
'ಹಾರಿಕಾಯಿ',
'ಹಾರಿಗೊಳ್',
'ಹಾರಿದ್ರಕ',
'ಹಾರಿಯಾಳ',
'ಹಾರಿವಡೆ',
'ಹಾರಿವಾಣ',
'ಹಾರಿವಾಳ',
'ಹಾರೀತಕ',
'ಹಾರುಕೇರಿ',
'ಹಾರುಗಾಲು',
'ಹಾರುಗೋಲು',
'ಹಾರುಮೀನು',
'ಹಾರುಮೆಕ್ಕೆ',
'ಹಾರುವಯ್ಯ',
'ಹಾರುವಾಣ',
'ಹಾರುವಿತಿ',
'ಹಾರುವಿತ್ತಿ',
'ಹಾರುಸುದ್ದಿ',
'ಹಾರುಹೊಡೆ',
'ಹಾರೆಕೋಲು',
'ಹಾರೋಗಿಡ',
'ಹಾರ್ದಿಕತೆ',
'ಹಾಲಗೋಳಿ',
'ಹಾಲಡಕೆ',
'ಹಾಲಮಡ್ಡಿ',
'ಹಾಲರೊಡ್ಡಿ',
'ಹಾಲವಕ್ಕಿ',
'ಹಾಲವಾಣ',
'ಹಾಲವುಗ',
'ಹಾಲಹಕ್ಕಿ',
'ಹಾಲಹಲ',
'ಹಾಲಾವಲೆ',
'ಹಾಲಾಹಲ',
'ಹಾಲಿವಾಣ',
'ಹಾಲುಕಂಬ',
'ಹಾಲುಕರೆ',
'ಹಾಲುಕೀರು',
'ಹಾಲುಖೀರು',
'ಹಾಲುಖೋವಾ',
'ಹಾಲುಗಂಬ',
'ಹಾಲುಗಬ್ಬು',
'ಹಾಲುಗಲ್ಲ',
'ಹಾಲುಗಲ್ಲು',
'ಹಾಲುಗಾರೆ',
'ಹಾಲುಗಾಳು',
'ಹಾಲುಗಿಡ',
'ಹಾಲುಗಿಣ್ಣು',
'ಹಾಲುಗುನ್ನಿ',
'ಹಾಲುಗೆನ್ನೆ',
'ಹಾಲುಣಿಸು',
'ಹಾಲುತುಂಬು',
'ಹಾಲುಬತ್ತ',
'ಹಾಲುಬಳ್ಳಿ',
'ಹಾಲುಬಾಯಿ',
'ಹಾಲುಬುಡ್ಡಿ',
'ಹಾಲುಬೆತ್ತ',
'ಹಾಲುಬೆಳ್ಳಿ',
'ಹಾಲುಮಡ್ಡಿ',
'ಹಾಲುಮೆಕ್ಕೆ',
'ಹಾಲುರೊಡ್ಡಿ',
'ಹಾಲುಲಿಗೆ',
'ಹಾಲುವಕ್ಕಿ',
'ಹಾಲುವಾಣ',
'ಹಾಲುಸೋರೆ',
'ಹಾಲುಹಕ್ಕಿ',
'ಹಾಲೂಡಿಸು',
'ಹಾಲೆಮರ',
'ಹಾಲೆಹಕ್ಕಿ',
'ಹಾಲೊಡಪು',
'ಹಾಲೋಗರ',
'ಹಾಲ್ಕಾಗದ',
'ಹಾಲ್ಗಡಲು',
'ಹಾಲ್ಗುಂಬಳ',
'ಹಾವಡಿಗ',
'ಹಾವರಣ',
'ಹಾವರಣಿ',
'ಹಾವರಣೆ',
'ಹಾವಳಿಸು',
'ಹಾವಾಡಿಗ',
'ಹಾವುಗಾರ',
'ಹಾವುಮಕ್ಕಿ',
'ಹಾವುಮಾರಿ',
'ಹಾವುಮೀನು',
'ಹಾವುಮೆಕ್ಕೆ',
'ಹಾವುರಾಣಿ',
'ಹಾವುಲಿಗೆ',
'ಹಾಸುಎಳೆ',
'ಹಾಸುಕಲ್ಲು',
'ಹಾಸುಗಳೆ',
'ಹಾಸುಗೊಳ್',
'ಹಾಸುಗೋಲ್',
'ಹಾಸುಗೋಲು',
'ಹಾಸುನರಿ',
'ಹಾಸುನೂಲು',
'ಹಾಸುಬಂಡೆ',
'ಹಾಸುಮಂಚ',
'ಹಾಸುಮಡಿ',
'ಹಾಸುಮಣೆ',
'ಹಾಸುವಲೆ',
'ಹಾಸುಹೊಕ್ಕು',
'ಹಾಸುಹೊಯ್ಯು',
'ಹಾಸ್ಯಂಗೆಯ್',
'ಹಾಸ್ಯಗಾತಿ',
'ಹಾಸ್ಯಗಾರ',
'ಹಾಸ್ಯದೃಷ್ಟಿ',
'ಹಾಸ್ಯಪಾತ್ರ',
'ಹಾಸ್ಯಭಾರ',
'ಹಾಸ್ಯರಸ',
'ಹಾಸ್ಯವಾಕ್ಕು',
'ಹಾಸ್ಯಶೀಲ',
'ಹಾಸ್ಯಶೀಲೆ',
'ಹಾಸ್ಯಾಸ್ಪದ',
'ಹಾಹಾಕಾರ',
'ಹಾಹಾಕ್ರಂದ',
'ಹಾಹಾರವ',
'ಹಾಳಂಬಡು',
'ಹಾಳಹಳ',
'ಹಾಳಾಹಳ',
'ಹಾಳಿಗೆಡು',
'ಹಾಳಿಹದ್ದು',
'ಹಾಳುಕೊಂಪೆ',
'ಹಾಳುಗರೆ',
'ಹಾಳುಗಸ',
'ಹಾಳುಗೆಡು',
'ಹಾಳುಗೇಡಿ',
'ಹಾಳುಗೋಡೆ',
'ಹಾಳುಬಗೆ',
'ಹಾಳುಬಡಿ',
'ಹಾಳುಬಾವಿ',
'ಹಾಳುಬೀದಿ',
'ಹಾಳುಬೀಳು',
'ಹಾಳುಭಾವಿ',
'ಹಾಳುಮನೆ',
'ಹಾಳುಮೂಳ',
'ಹಾಳುಮೂಳಿ',
'ಹಾಳುಮೂಳು',
'ಹಾಳುಮೋರೆ',
'ಹಾಳುವರಿ',
'ಹಾಳುಸುರಿ',
'ಹಾಳುಹಂಪೆ',
'ಹಾಳುಹಣೆ',
'ಹಾಳುಹರಿ',
'ಹಾಳುಹೊಟ್ಟೆ',
'ಹಾಳೆಕಿಳ್ಳಿ',
'ಹಾಳೆಕೊಟ್ಟೆ',
'ಹಾಳೆಟೋಪಿ',
'ಹಾಳೆಹದ್ದು',
'ಹಾಳೇರಿಸು',
'ಹಾಳೊಡಲ್',
'ಹಾಱುಗುಪ್ಪೆ',
'ಹಾಱುವಲೆ',
'ಹಾೞಂಬಡು',
'ಹಿಂಗರಣೆ',
'ಹಿಂಗರ್ತರಿ',
'ಹಿಂಗುಕಣ್ಣು',
'ಹಿಂಗುಗಣ್ಣು',
'ಹಿಂಗುಪತ್ರ',
'ಹಿಂಗುಪತ್ರಿ',
'ಹಿಂಗುಪತ್ರೆ',
'ಹಿಂಗುರುಳು',
'ಹಿಂಜಗಿಸು',
'ಹಿಂಜರಿಕೆ',
'ಹಿಂಜರಿತ',
'ಹಿಂಡಿಲ್ಗಟ್ಟು',
'ಹಿಂಡಿಸೊಪ್ಪು',
'ಹಿಂಡುಕೌಪ',
'ಹಿಂಡುಗಟ್ಟು',
'ಹಿಂಡುಗೂಟ',
'ಹಿಂಡುಗೂಡು',
'ಹಿಂಡುಗೊಳ್',
'ಹಿಂಡುಮಾತು',
'ಹಿಂಡುವಕ್ಕಿ',
'ಹಿಂಡುವಲೆ',
'ಹಿಂಡುಳಿಕೆ',
'ಹಿಂಡೆಕೂಳು',
'ಹಿಂತಿರುಗು',
'ಹಿಂದಲೇಟು',
'ಹಿಂದಿರಿಸು',
'ಹಿಂದಿರುಗು',
'ಹಿಂದುಗಡೆ',
'ಹಿಂದುಗಳೆ',
'ಹಿಂದುಗೊಳ್',
'ಹಿಂದುಟಪಿ',
'ಹಿಂದುದೇಶ',
'ಹಿಂದುಸ್ತಾನಿ',
'ಹಿಂದುಸ್ಥಾನ',
'ಹಿಂದುಸ್ಥಾನಿ',
'ಹಿಂದುಳಿಸು',
'ಹಿಂದುಳುಹು',
'ಹಿಂದೂದೇಶ',
'ಹಿಂದೂಧರ್ಮ',
'ಹಿಂದೂಮತ',
'ಹಿಂದೂಸ್ಥಾನ',
'ಹಿಂದೂಸ್ಥಾನಿ',
'ಹಿಂದೆಯಾಡು',
'ಹಿಂದೆರಳು',
'ಹಿಂದೆಹೊನ್ನು',
'ಹಿಂಬಗಲು',
'ಹಿಂಬಡತಿ',
'ಹಿಂಬರಹ',
'ಹಿಂಬರಿಕೆ',
'ಹಿಂಬಾಗಿಲು',
'ಹಿಂಬಾಲಕ',
'ಹಿಂಬಾಲಿಸು',
'ಹಿಂಬೆಱಗು',
'ಹಿಂಸಾಕರ್ಮ',
'ಹಿಂಸಾಚಾರ',
'ಹಿಂಸಾತ್ಮಕ',
'ಹಿಂಸಾಪರ',
'ಹಿಂಸಾರತಿ',
'ಹಿಂಸಾರುಚಿ',
'ಹಿಂಸ್ರಜಂತು',
'ಹಿಂಸ್ರಪಶು',
'ಹಿಕಮತ್ತು',
'ಹಿಕ್ಕಣಿಕೆ',
'ಹಿಕ್ಕುಗೊಡು',
'ಹಿಗ್ಗಲಿಕೆ',
'ಹಿಗ್ಗಲಿಸು',
'ಹಿಗ್ಗಾಮುಗ್ಗ',
'ಹಿಗ್ಗಾಮುಗ್ಗಾ',
'ಹಿಗ್ಗುದೆಗೆ',
'ಹಿಗ್ಗುಮುಗ್ಗು',
'ಹಿಚುಕಾಟ',
'ಹಿಜಿಗಾಟ',
'ಹಿಟುಕಾರ',
'ಹಿಟುಕಾಱ',
'ಹಿಟ್ಟಗಾಣಿ',
'ಹಿಟ್ಟಡಿಗೆ',
'ಹಿಟ್ಟಲಿಕ',
'ಹಿಟ್ಟಲೀಕ',
'ಹಿಟ್ಟಳಿಕೆ',
'ಹಿಟ್ಟುಂಗುಟ್ಟು',
'ಹಿಟ್ಟುಕಾರ',
'ಹಿಟ್ಟುಕಾಱ',
'ಹಿಟ್ಟುಗಲ್ಲು',
'ಹಿಟ್ಟುಗಳೆ',
'ಹಿಟ್ಟುಗುಟ್ಟು',
'ಹಿಟ್ಟುದೆಱೆ',
'ಹಿಟ್ಟುದೋಱು',
'ಹಿಟ್ಟುನೆಲ್ಲಿ',
'ಹಿಟ್ಟುಮಟ್ಟಿ',
'ಹಿಟ್ಟುಳಿಕ',
'ಹಿಟ್ಟುಳಿಕೆ',
'ಹಿಟ್ಟುಳುಕ',
'ಹಿಟ್ಟೆಸರು',
'ಹಿಡಗಲು',
'ಹಿಡಗಿಲು',
'ಹಿಡಬಡಿ',
'ಹಿಡಿಕಟ್ಟು',
'ಹಿಡಿಕಳ',
'ಹಿಡಿಕಿಸು',
'ಹಿಡಿಕುಂಟೆ',
'ಹಿಡಿಕೆಯ್ಸು',
'ಹಿಡಿಕೈಸು',
'ಹಿಡಿಕೋಲು',
'ಹಿಡಿಗಲ್ಲು',
'ಹಿಡಿಗುಟ್ಟು',
'ಹಿಡಿಗೂಟ',
'ಹಿಡಿಗೆಯ್',
'ಹಿಡಿಗೊಡು',
'ಹಿಡಿಗೋಲು',
'ಹಿಡಿಚಂಡು',
'ಹಿಡಿಜೋಳ',
'ಹಿಡಿತಡೆ',
'ಹಿಡಿತರ್',
'ಹಿಡಿತರು',
'ಹಿಡಿನಾಯಿ',
'ಹಿಡಿಬಂದಿ',
'ಹಿಡಿಬಿಟ್ಟಿ',
'ಹಿಡಿಮಂದಿ',
'ಹಿಡಿಮೃಗ',
'ಹಿಡಿಯಾಳು',
'ಹಿಡಿಯಿಸು',
'ಹಿಡಿವಡೆ',
'ಹಿಡಿಶಾಪ',
'ಹಿಡಿಸೂಡಿ',
'ಹಿಡಿಹಡೆ',
'ಹಿಡಿಹತೆ',
'ಹಿಡಿಹಾರ',
'ಹಿಡುಗಲು',
'ಹಿಡುವಳಿ',
'ಹಿಣಿಲಿಱಿ',
'ಹಿತಕರ',
'ಹಿತಕಾರಿ',
'ಹಿತಗೊಳ್',
'ಹಿತತನ',
'ಹಿತವಂತ',
'ಹಿತವಂತೆ',
'ಹಿತವಾದಿ',
'ಹಿತವಾರೆ',
'ಹಿತವೇಳು',
'ಹಿತಶತ್ರು',
'ಹಿತಸಖ',
'ಹಿತಾವಹ',
'ಹಿತಾಶಂಸೆ',
'ಹಿತೈಷಿಣಿ',
'ಹಿನ್ನೆಳಲು',
'ಹಿಪ್ಪದಿಕೆ',
'ಹಿಪ್ಪರಿಕೆ',
'ಹಿಪ್ಪಲಿಕೆ',
'ಹಿಪ್ಪಾರರೆ',
'ಹಿಮಕರ',
'ಹಿಮಕೃತ್ತು',
'ಹಿಮಗಡ್ಡೆ',
'ಹಿಮಗಾಳಿ',
'ಹಿಮಗಿರಿ',
'ಹಿಮಘರ್ಮ',
'ಹಿಮನದಿ',
'ಹಿಮಪ್ರಭ',
'ಹಿಮಭಾನು',
'ಹಿಮಮಣಿ',
'ಹಿಮರಶ್ಮಿ',
'ಹಿಮರುಚಿ',
'ಹಿಮರೋಚಿ',
'ಹಿಮವಂತ',
'ಹಿಮವರ್ಷ',
'ಹಿಮವೆಟ್ಟ',
'ಹಿಮಶೈಲ',
'ಹಿಮಶೈಳ',
'ಹಿಮಾಚಲ',
'ಹಿಮಾಚಳ',
'ಹಿಮಾಲಯ',
'ಹಿಮೋಪಲ',
'ಹಿಮೋಪಳ',
'ಹಿಮ್ಮಗ್ಗಲು',
'ಹಿಮ್ಮರಿಕೆ',
'ಹಿಮ್ಮೆಟ್ಟಿಸು',
'ಹಿಯರಿಂಗು',
'ಹಿಯ್ಯಾಳಿಕೆ',
'ಹಿಯ್ಯಾಳಿಸು',
'ಹಿರಣ್ಮಯಿ',
'ಹಿರಾಕಸ',
'ಹಿರಾಕಾಸ',
'ಹಿರಾವಳಿ',
'ಹಿರಿಕೊಳ್',
'ಹಿರಿಚಳ್ಳೆ',
'ಹಿರಿಜೇಕು',
'ಹಿರಿತನ',
'ಹಿರಿಬೋಗಿ',
'ಹಿರಿಮಣಿ',
'ಹಿರಿಮದ್ದು',
'ಹಿರಿಮರ',
'ಹಿರಿಮೆಕ್ಕೆ',
'ಹಿರಿಯಕ್ಕ',
'ಹಿರಿಯಣ್ಣ',
'ಹಿರಿಯಪ್ಪ',
'ಹಿರಿಯಯ್ಯ',
'ಹಿರಿಯಿಸು',
'ಹಿರಿಯೇಲ',
'ಹಿರಿಹಬ್ಬೆ',
'ಹಿರಿಹಳ್ಳ',
'ಹಿರಿಹಿಗ್ಗು',
'ಹಿರೀಚಲು',
'ಹಿಲಮೋಚಿ',
'ಹಿಲ್ಲೋಲಿತ',
'ಹಿಸುಣತೆ',
'ಹಿಸುಣಾಡು',
'ಹಿಸುಣಿಕೆ',
'ಹಿಸುಣಿಗ',
'ಹಿಸ್ಸೆದಾರ',
'ಹಿಳಿಹೂಲಿ',
'ಹಿಳ್ಳಯಿಸು',
'ಹಿಳ್ಳಿಯೊಡೆ',
'ಹಿಳ್ಳೆಗಾಲ್',
'ಹಿಳ್ಳೆಗಾಲ',
'ಹಿಳ್ಳೆಗಾಲು',
'ಹಿಱಱನೆ',
'ಹೀಕರಣೆ',
'ಹೀಕರಸು',
'ಹೀಗಳೆತ',
'ಹೀಚುಗಾಯಿ',
'ಹೀನಚಳ್ಳೆ',
'ಹೀನತನ',
'ಹೀನಯಾನ',
'ಹೀನಯಿಸು',
'ಹೀನಸುಳಿ',
'ಹೀನಾಮಾನ',
'ಹೀನೋತ್ಪಾತ',
'ಹೀನೋಪಮೆ',
'ಹೀಯಾವಳಿ',
'ಹೀಯಾಳಿಕೆ',
'ಹೀಯಾಳಿಸು',
'ಹೀರರೇಖೆ',
'ಹೀರಾಯುಧ',
'ಹೀರಾವಳಿ',
'ಹೀಲಿಗರಿ',
'ಹೀಲಿಗೊಡೆ',
'ಹೀಲಿದಳೆ',
'ಹೀಹಾಳಿಕೆ',
'ಹೀಹಾಳಿಸು',
'ಹೀಹೇಳಿಕೆ',
'ಹುಂಕರಿಸು',
'ಹುಂಡವಣ್ಣ',
'ಹುಂಡಾವಣಿ',
'ಹುಂಡಾವಳಿ',
'ಹುಂಡಿಪತ್ರ',
'ಹುಂಡುಕೋಳಿ',
'ಹುಂಡೆಕರಿ',
'ಹುಂಡೇಕಾರ',
'ಹುಂಡೇಕಾರಿ',
'ಹುಂತಕಾರ',
'ಹುಂಬತನ',
'ಹುಕಮತು',
'ಹುಕುಂನಾಮೆ',
'ಹುಕುಮತ್ತು',
'ಹುಗುಳೇಳು',
'ಹುಚ್ಚಕಬ್ಬು',
'ಹುಚ್ಚಮಲ್ಲಿ',
'ಹುಚ್ಚಹತ್ತಿ',
'ಹುಚ್ಚಾಪಟ್ಟೆ',
'ಹುಚ್ಚಾಬಟ್ಟೆ',
'ಹುಚ್ಚಾಸ್ಪತ್ರೆ',
'ಹುಚ್ಚುಕಬ್ಬು',
'ಹುಚ್ಚುಕುನ್ನಿ',
'ಹುಚ್ಚುಗಟ್ಟು',
'ಹುಚ್ಚುಗುಂಜಿ',
'ಹುಚ್ಚುಗೊಳ್',
'ಹುಚ್ಚುಜೋಳ',
'ಹುಚ್ಚುತನ',
'ಹುಚ್ಚುತುಂಬೆ',
'ಹುಚ್ಚುತೊಂಡೆ',
'ಹುಚ್ಚುದುಂಬೆ',
'ಹುಚ್ಚುನಗೆ',
'ಹುಚ್ಚುನಾಯಿ',
'ಹುಚ್ಚುನಾರೆ',
'ಹುಚ್ಚುನಿಂಬೆ',
'ಹುಚ್ಚುಬಳ್ಳಿ',
'ಹುಚ್ಚುಬಿಡು',
'ಹುಚ್ಚುಬೇವು',
'ಹುಚ್ಚುಮಳೆ',
'ಹುಚ್ಚುಮುಂಡೆ',
'ಹುಚ್ಚುಮುಟ್ಟೆ',
'ಹುಚ್ಚುಸಂಬೆ',
'ಹುಚ್ಚುಸೊಂಡೆ',
'ಹುಚ್ಚುಹತ್ತಿ',
'ಹುಚ್ಚುಹತ್ತು',
'ಹುಚ್ಚುಹಿಡಿ',
'ಹುಚ್ಚುಹೀರೆ',
'ಹುಚ್ಚುಹೊಳೆ',
'ಹುಟ್ಟಡಗು',
'ಹುಟ್ಟಮೂಕ',
'ಹುಟ್ಟಮೂಕಿ',
'ಹುಟ್ಟರಿವು',
'ಹುಟ್ಟಳಿಸು',
'ಹುಟ್ಟುಅರೆ',
'ಹುಟ್ಟುಕವಿ',
'ಹುಟ್ಟುಗಟ್ಟು',
'ಹುಟ್ಟುಗಲ್ಲು',
'ಹುಟ್ಟುಗುಟ್ಟು',
'ಹುಟ್ಟುಗುಣ',
'ಹುಟ್ಟುಗೆಡು',
'ಹುಟ್ಟುಗೊಡ್ಡು',
'ಹುಟ್ಟುಡುಗೆ',
'ಹುಟ್ಟುಚಾಲಿ',
'ಹುಟ್ಟುಚಾಳಿ',
'ಹುಟ್ಟುತನ',
'ಹುಟ್ಟುಬುದ್ಧಿ',
'ಹುಟ್ಟುಮಚ್ಚೆ',
'ಹುಟ್ಟುಮನೆ',
'ಹುಟ್ಟುಮೂಕ',
'ಹುಟ್ಟುಮೂಕಿ',
'ಹುಟ್ಟುಮೆಟ್ಟು',
'ಹುಟ್ಟುವಣ',
'ಹುಟ್ಟುವಳಿ',
'ಹುಟ್ಟುಶ್ವಾಸ',
'ಹುಟ್ಟುಹಬ್ಬ',
'ಹುಟ್ಟುಹಾಕು',
'ಹುಡಹಾವು',
'ಹುಡಿಗಲ್ಲು',
'ಹುಡಿಗಳೆ',
'ಹುಡಿಗುಟ್ಟು',
'ಹುಡಿಗೆಯ್',
'ಹುಡಿಬಡಿ',
'ಹುಡಿಬಿಡು',
'ಹುಡಿಮಾತು',
'ಹುಡಿಮುಕ್ಕ',
'ಹುಡಿಯಾಟ',
'ಹುಡಿಯೇಳು',
'ಹುಡಿಹಾರು',
'ಹುಡಿಹಾಱು',
'ಹುಡುಕಪ್ಪ',
'ಹುಡುಕಾಟ',
'ಹುಡುಕಾಡು',
'ಹುಡುಕಿಸು',
'ಹುಡುಗಾಟ',
'ಹುಡುಗೊಲ್ಲಿ',
'ಹುಡುತುತು',
'ಹುಡುತುತ್ತು',
'ಹುಡುಹುಟ್ಟ',
'ಹುಣ್ಣುಬೇನೆ',
'ಹುತವಹ',
'ಹುತಾತ್ಮತೆ',
'ಹುತಾಶನ',
'ಹುತ್ತುಗಾಲ್',
'ಹುತ್ತುಗಾಲು',
'ಹುದಿಗೊಳ್ಳು',
'ಹುದುಕಾರ',
'ಹುದುಗಲು',
'ಹುದುಗಿಸು',
'ಹುದುನೆಲ',
'ಹುದುಭಾಗೆ',
'ಹುದುವನೆ',
'ಹುದುವಾಳ್',
'ಹುದ್ದೇದಾರ',
'ಹುನಗಲು',
'ಹುನಿಗಿಲು',
'ಹುಪ್ಪುಲವೆ',
'ಹುಬೇಹೂಬ್',
'ಹುಬೇಹೂಬ',
'ಹುಬ್ಬುಗಣ್ಣು',
'ಹುಬ್ಬೇರಿಸು',
'ಹುಯಸಲ',
'ಹುಯಿಲಿಡು',
'ಹುಯ್ಗಡುಬು',
'ಹುಯ್ಯಲಿಕ್ಕು',
'ಹುಯ್ಯಲಿಡು',
'ಹುಯ್ಲುಗಳೆ',
'ಹುರಕಲು',
'ಹುರಗಲು',
'ಹುರಮಂಜ',
'ಹುರಮಂಜಿ',
'ಹುರಮಂಜು',
'ಹುರಮುಂಜಿ',
'ಹುರಹುರ',
'ಹುರಿಕಟ್ಟು',
'ಹುರಿಕರಿ',
'ಹುರಿಕಲು',
'ಹುರಿಕಾತಿ',
'ಹುರಿಗಟ್ಟು',
'ಹುರಿಗಾತಿ',
'ಹುರಿಗಾಳು',
'ಹುರಿಗಿಲು',
'ಹುರಿಗುಂದು',
'ಹುರಿಗುಟ್ಟು',
'ಹುರಿಗೂಡು',
'ಹುರಿಗೆಡು',
'ಹುರಿಗೊಳ್',
'ಹುರಿಗೊಳ್ಳು',
'ಹುರಿದುಂಬು',
'ಹುರಿನೇಣು',
'ಹುರಿಬಲಿ',
'ಹುರಿಬಾಡು',
'ಹುರಿಬಿಡು',
'ಹುರಿಬುತ್ತಿ',
'ಹುರಿಮಂಜು',
'ಹುರಿಯಕ್ಕಿ',
'ಹುರಿಯಳಿ',
'ಹುರಿಯಾಳು',
'ಹುರಿಯಿಸು',
'ಹುರಿಯೇರು',
'ಹುರಿಯೇಱು',
'ಹುರಿಯೊಟ್ಟೆ',
'ಹುರಿಯೊಡೆ',
'ಹುರಿಯೋಡು',
'ಹುರಿಹಂಚು',
'ಹುರಿಹಿಟ್ಟು',
'ಹುರಿಹುಲಿ',
'ಹುರುಕಲು',
'ಹುರುಗಲು',
'ಹುರುಗಿಲು',
'ಹುರುಡಾಳಿ',
'ಹುರುಡಿಗ',
'ಹುರುಡಿಸು',
'ಹುರುಪಲಿ',
'ಹುರುಪಳಿ',
'ಹುರುಪೇರು',
'ಹುರುಮಂಜಿ',
'ಹುರುಮಂಜು',
'ಹುರುವಲ್ಲಿ',
'ಹುರುವಳ್ಳಿ',
'ಹುರುಹುರು',
'ಹುಲಗಲಿ',
'ಹುಲಗಿಲ್',
'ಹುಲಗಿಲು',
'ಹುಲಗೆಮ್ಮ',
'ಹುಲಬನ್ನಿ',
'ಹುಲಹತ್ತಿ',
'ಹುಲಿಕಲ್ಲು',
'ಹುಲಿಗಾಹು',
'ಹುಲಿಗಿಲ್',
'ಹುಲಿಗಿಲಿ',
'ಹುಲಿಗಿಲು',
'ಹುಲಿಗೆಮ್ಮ',
'ಹುಲಿಗೌರಿ',
'ಹುಲಿಚರ್ಮ',
'ಹುಲಿದೇವ',
'ಹುಲಿನಖ',
'ಹುಲಿನೀಲ',
'ಹುಲಿಬಲೆ',
'ಹುಲಿಬೆಂಡು',
'ಹುಲಿಬೆಕ್ಕು',
'ಹುಲಿಮುಖ',
'ಹುಲಿಮೊಗ',
'ಹುಲಿವಿಲಿ',
'ಹುಲಿವಿಲು',
'ಹುಲಿವೇಷ',
'ಹುಲಿಹುಣ್ಣು',
'ಹುಲೀಚೆಂಡು',
'ಹುಲುಗಾಣ್',
'ಹುಲುಗಿಚ್ಚು',
'ಹುಲುಬನ್ನಿ',
'ಹುಲುಮೀನು',
'ಹುಲುಮೃಗ',
'ಹುಲುವಿಡಿ',
'ಹುಲುವಿಲು',
'ಹುಲುವಿಲೆ',
'ಹುಲುಹತ್ತಿ',
'ಹುಲ್ಗುಡಿಲು',
'ಹುಲ್ಲಡಕ',
'ಹುಲ್ಲೀಚಲ',
'ಹುಲ್ಲುಕಡ್ಡಿ',
'ಹುಲ್ಲುಖಾಣ',
'ಹುಲ್ಲುಗದ್ದೆ',
'ಹುಲ್ಲುಗಬ್ಬು',
'ಹುಲ್ಲುಗಾಬು',
'ಹುಲ್ಲುಗುಂಚೆ',
'ಹುಲ್ಲುಗುದ್ನೆ',
'ಹುಲ್ಲುಗುರು',
'ಹುಲ್ಲುಗೊದ್ನೆ',
'ಹುಲ್ಲುಚಾಪೆ',
'ಹುಲ್ಲುಪಾಚಿ',
'ಹುಲ್ಲುಬನ್ನಿ',
'ಹುಲ್ಲುಭತ್ತ',
'ಹುಲ್ಲುಮಣಿ',
'ಹುಲ್ಲುಮನೆ',
'ಹುಲ್ಲುಮೀನು',
'ಹುಲ್ಲುಮೆದೆ',
'ಹುಲ್ಲುಹಣ',
'ಹುಲ್ಲುಹೊರೆ',
'ಹುಲ್ಲೆಕರು',
'ಹುಲ್ಲೆಕಾಯಿ',
'ಹುಲ್ಲೆನೆಗೆ',
'ಹುಲ್ಲೆವಾಱು',
'ಹುಲ್ಲೊಕ್ಕಲ್',
'ಹುವ್ವಾಡಿಗ',
'ಹುವ್ವುಲವೆ',
'ಹುಸಿಗಾಯಿ',
'ಹುಸಿಗುಂಡು',
'ಹುಸಿತನ',
'ಹುಸಿನಗು',
'ಹುಸಿನಗೆ',
'ಹುಸಿನಡು',
'ಹುಸಿನುಡಿ',
'ಹುಸಿಮಣ್ಣು',
'ಹುಸಿಯಾಡು',
'ಹುಸಿಹುಂಜಿ',
'ಹುಸಿಹುಂಡ',
'ಹುಸಿಹೋಗು',
'ಹುಳಿಇಪ್ಪೆ',
'ಹುಳಿಓಮ',
'ಹುಳಿಕುಡಿ',
'ಹುಳಿಗಂಚಿ',
'ಹುಳಿಗಟ್ಟು',
'ಹುಳಿಗಾಯ್',
'ಹುಳಿಗಾಯಿ',
'ಹುಳಿಗುಡಿ',
'ಹುಳಿಚಿಕ್ಕ',
'ಹುಳಿಚುಕ್ಕಿ',
'ಹುಳಿತೇಗು',
'ಹುಳಿತೊವ್ವೆ',
'ಹುಳಿತೋವೆ',
'ಹುಳಿದೇಗು',
'ಹುಳಿನಾಲೆ',
'ಹುಳಿಪಲ್ಯ',
'ಹುಳಿಬೇಲ',
'ಹುಳಿಯನ್ನ',
'ಹುಳಿಯಾಯ',
'ಹುಳಿಯೇರು',
'ಹುಳಿಸಾರು',
'ಹುಳಿಸೊಪ್ಪು',
'ಹುಳಿಸೌತೆ',
'ಹುಳಿಹಚ್ಚೆ',
'ಹುಳಿಹತ್ತಿ',
'ಹುಳಿಹಬ್ಬೆ',
'ಹುಳಿಹಿಂಡು',
'ಹುಳಿಹೀರೆ',
'ಹುಳಿಹೊದ್ದು',
'ಹುಳುಕಡ್ಡಿ',
'ಹುಳುಕುಡಿ',
'ಹುಳುಗಣ್ಣು',
'ಹುಳುಗೊಂಡ',
'ಹುಳುಚಿಕ್ಕ',
'ಹುಳುಬಾಳು',
'ಹುೞಿಗುಡಿ',
'ಹೂಅಗಿಲು',
'ಹೂಂಕರಣೆ',
'ಹೂಂಕರಿತ',
'ಹೂಂಕರಿಸು',
'ಹೂಗಣೆಯ',
'ಹೂಗನಸು',
'ಹೂಗಿರಣ',
'ಹೂಗೆಲಸಿ',
'ಹೂಗೊಂಚಲು',
'ಹೂಜೆಗಾರ್ತಿ',
'ಹೂಟಗಾರ',
'ಹೂಣಭಾಷೆ',
'ಹೂಣ್ಯವಂತ',
'ಹೂದಂಬುಲ',
'ಹೂದಿಂಗಣ',
'ಹೂದಿಂಗಳ್',
'ಹೂದಿಂಗಳು',
'ಹೂದುಱುಬು',
'ಹೂದೊಡಬೆ',
'ಹೂದೋರಣ',
'ಹೂನ್ಯವಂತ',
'ಹೂಪೊಡೆಯ',
'ಹೂಬಿಸಿಲು',
'ಹೂಬೇಹೂಬ್',
'ಹೂಬೇಹೂಬ',
'ಹೂಮುಡಿಸು',
'ಹೂಲಿಬೆಂಡು',
'ಹೂವಡಿಗ',
'ಹೂವರಸಿ',
'ಹೂವರಸೆ',
'ಹೂವರಳಿ',
'ಹೂವಾಡಿಗ',
'ಹೂವೀಳಯ',
'ಹೂವುಕಟ್ಟು',
'ಹೂವುಕೋಸು',
'ಹೂವುಗಣೆ',
'ಹೂವುಗಣ್ಣು',
'ಹೂವುತನ',
'ಹೂವುಬಾಳೆ',
'ಹೂವುಮುಡಿ',
'ಹೂಸತನ',
'ಹೂಸುದಗ್ಗಿ',
'ಹೂಸುಲಕ್ಕಿ',
'ಹೂಹಗುರ',
'ಹೃಜ್ಜನನ',
'ಹೃತ್ಕಲ್ಮಷ',
'ಹೃತ್ತಾಮಸ',
'ಹೃತ್ತಿಮಿರ',
'ಹೃತ್ಪೂರ್ವಕ',
'ಹೃತ್ಸಂತಾಪ',
'ಹೃತ್ಸಂವಾದ',
'ಹೃದಯಸ್ಥ',
'ಹೃದಯಾಗ್ನಿ',
'ಹೃದಯಾಲು',
'ಹೃದಯಾಳು',
'ಹೃದಯಿಸು',
'ಹೃದಯೇಶ',
'ಹೃದ್ಗೋಚರ',
'ಹೃಷೀಕೇಶ',
'ಹೆಂಗರಿಗ',
'ಹೆಂಗರುಳು',
'ಹೆಂಟೆಗೊದ್ದ',
'ಹೆಂಟೆಹೊಡೆ',
'ಹೆಂಡಗಾರ',
'ಹೆಂಡಗಾಱ',
'ಹೆಂಡಬಾಕ',
'ಹೆಂಡಿಕಸ',
'ಹೆಕ್ಕಳಿಕೆ',
'ಹೆಕ್ಕಳಿಸು',
'ಹೆಕ್ಕೌಡಲ',
'ಹೆಗಡಿಕೆ',
'ಹೆಗಲೆಣೆ',
'ಹೆಗಲೇರು',
'ಹೆಗಲೇಱು',
'ಹೆಗಲೊಡ್ಡು',
'ಹೆಗ್ಗಡಜ',
'ಹೆಗ್ಗಡತಿ',
'ಹೆಗ್ಗಡಲು',
'ಹೆಗ್ಗಡಿಕೆ',
'ಹೆಗ್ಗಡಿತಿ',
'ಹೆಗ್ಗತ್ತಲೆ',
'ಹೆಗ್ಗರಿಲು',
'ಹೆಗ್ಗರುಳು',
'ಹೆಗ್ಗಲಿಸು',
'ಹೆಗ್ಗವಣೆ',
'ಹೆಗ್ಗಳಪಿ',
'ಹೆಗ್ಗಳವಿ',
'ಹೆಗ್ಗಳಿಕೆ',
'ಹೆಗ್ಗಾದಿಗೆ',
'ಹೆಗ್ಗಾಲುವೆ',
'ಹೆಗ್ಗುಡಟಿ',
'ಹೆಗ್ಗುರುತು',
'ಹೆಗ್ಗೂರಿಡು',
'ಹೆಗ್ಗೆಣಸು',
'ಹೆಗ್ಗೋರಂಟೆ',
'ಹೆಗ್ಗೋರಟೆ',
'ಹೆಚ್ಚಳಿಕೆ',
'ಹೆಚ್ಚಳಿಸು',
'ಹೆಚ್ಚುಕೊಳ್ಳು',
'ಹೆಚ್ಚುಗಟ್ಲೆ',
'ಹೆಚ್ಚುಮಗ್ಗಿ',
'ಹೆಚ್ಚುವರಿ',
'ಹೆಜ್ಜವಿಡು',
'ಹೆಜ್ಜಾರಿಗೆ',
'ಹೆಜ್ಜಾಲಿಕೆ',
'ಹೆಜ್ಜಿಗಣೆ',
'ಹೆಜ್ಜೆಕೀಳು',
'ಹೆಜ್ಜೆಗಾಣ್',
'ಹೆಜ್ಜೆದೆಗೆ',
'ಹೆಜ್ಜೆಪಾಡು',
'ಹೆಜ್ಜೆಯಿಡು',
'ಹೆಜ್ಜೆವಿಡಿ',
'ಹೆಜ್ಜೆಹಾಕು',
'ಹೆಜ್ಜೇನ್ನೊಣ',
'ಹೆಜ್ಜೊಡರ್',
'ಹೆಟ್ಟಹೊಡೆ',
'ಹೆಡಕಟ್ಟು',
'ಹೆಡಕತ್ತು',
'ಹೆಡಗಟ್ಟು',
'ಹೆಡಗಯ್',
'ಹೆಡಗುಡಿ',
'ಹೆಡತಲೆ',
'ಹೆಡದಲೆ',
'ಹೆಡಮಂಡೆ',
'ಹೆಡಮುಡಿ',
'ಹೆಡಮುರಿ',
'ಹೆಡಮೆಟ್ಟು',
'ಹೆಡಲಿಗೆ',
'ಹೆಡಶಿರ',
'ಹೆಡಳಗೆ',
'ಹೆಡೆಗಳ್ಳಿ',
'ಹೆಡೆದೂಗು',
'ಹೆಡೆನಲಿ',
'ಹೆಡೆನಾಗ',
'ಹೆಡೆಬಿಡು',
'ಹೆಡೆಮಟ್ಟೆ',
'ಹೆಡೆಮಣಿ',
'ಹೆಡೆಮುಚ್ಚು',
'ಹೆಡೆಯೆತ್ತು',
'ಹೆಡೆವಣಿ',
'ಹೆಡ್ಡತನ',
'ಹೆಡ್ಡಯಿಸು',
'ಹೆಡ್ಡವೆಣ್',
'ಹೆಡ್ಡುತನ',
'ಹೆಡ್ಡುಬುದ್ದಿ',
'ಹೆಡ್ಮಾಸ್ಟರು',
'ಹೆಡ್ಮಾಸ್ತರು',
'ಹೆಡ್ಮುನಶಿ',
'ಹೆಣಗಾಟ',
'ಹೆಣಗಾಡು',
'ಹೆಣಗಿಸು',
'ಹೆಣದತ್ತ',
'ಹೆಣದಿನಿ',
'ಹೆಣನುಂಗಿ',
'ಹೆಣಬೀಳು',
'ಹೆಣಭಾರ',
'ಹೆಣಮೂಳ',
'ಹೆಣಮೋರೆ',
'ಹೆಣೆಕಟ್ಟು',
'ಹೆಣ್ಗರುಳು',
'ಹೆಣ್ಣುಕಣೆ',
'ಹೆಣ್ಣುಗೊಡ್ಡ',
'ಹೆಣ್ಣುಜನ್ಮ',
'ಹೆಣ್ಣುತನ',
'ಹೆಣ್ಣುನೀಲಿ',
'ಹೆಣ್ಣುನೋಡು',
'ಹೆಣ್ಣುಮತ್ತಿ',
'ಹೆಣ್ಣುಹೂವು',
'ಹೆಣ್ಮರುಳ',
'ಹೆತ್ತೊಡಲು',
'ಹೆದರಿಕೆ',
'ಹೆದರಿಸು',
'ಹೆದರೆದೆ',
'ಹೆದಱಿಸು',
'ಹೆದಱೆದೆ',
'ಹೆದೆಕಟ್ಟು',
'ಹೆದೆಗಟ್ಟು',
'ಹೆದ್ದಂಗಡಿ',
'ಹೆದ್ದರಣ',
'ಹೆದ್ದರಣೆ',
'ಹೆದ್ದುರಚೆ',
'ಹೆದ್ದುರುಹೆ',
'ಹೆದ್ದುಱುಚೆ',
'ಹೆದ್ದೆವರು',
'ಹೆನ್ನೆತ್ತರು',
'ಹೆನ್ನೆರೋಗ',
'ಹೆಪ್ಪರಿಕೆ',
'ಹೆಪ್ಪರಿಗೆ',
'ಹೆಪ್ಪಳಿಸು',
'ಹೆಪ್ಪಿಡಿಸು',
'ಹೆಪ್ಪುಕೊಡು',
'ಹೆಪ್ಪುಗಟ್ಟು',
'ಹೆಪ್ಪುಗೊಡು',
'ಹೆಪ್ಪುಗೊಳ್',
'ಹೆಪ್ಪುಗೊಳ್ಳು',
'ಹೆಬಾರುವ',
'ಹೆಬ್ಬಡಿಕೆ',
'ಹೆಬ್ಬಣಬೆ',
'ಹೆಬ್ಬದಣೆ',
'ಹೆಬ್ಬದನೆ',
'ಹೆಬ್ಬಯಕೆ',
'ಹೆಬ್ಬರಗ',
'ಹೆಬ್ಬಲಸು',
'ಹೆಬ್ಬಸಲೆ',
'ಹೆಬ್ಬಸುರಿ',
'ಹೆಬ್ಬಳುವ',
'ಹೆಬ್ಬಾಗಿಲ್',
'ಹೆಬ್ಬಾಗಿಲು',
'ಹೆಬ್ಬಾರುವ',
'ಹೆಬ್ಬಾವಲ್',
'ಹೆಬ್ಬಾಳಿಕೆ',
'ಹೆಬ್ಬಿದರು',
'ಹೆಬ್ಬಿದಱು',
'ಹೆಬ್ಬಿದಿರ್',
'ಹೆಬ್ಬಿದಿರು',
'ಹೆಬ್ಬಿಸಿಲು',
'ಹೆಬ್ಬುಗುಳ್ಳ',
'ಹೆಬ್ಬೆರಳು',
'ಹೆಬ್ಬೆಳಸು',
'ಹೆಬ್ಬೆಳೆಸು',
'ಹೆಬ್ಬೆಱಗು',
'ಹೆಬ್ಬೊತ್ತಗೆ',
'ಹೆಬ್ಬೊತ್ತಿಗೆ',
'ಹೆಮ್ಮಗಳು',
'ಹೆಮ್ಮಗಾರೆ',
'ಹೆಮ್ಮಗ್ಗಾರೆ',
'ಹೆಮ್ಮಡದಿ',
'ಹೆಮ್ಮಲಗು',
'ಹೆಮ್ಮಿಗಿಲು',
'ಹೆಮ್ಮಿಟಲಿ',
'ಹೆಮ್ಮಿಟಿಲ',
'ಹೆಮ್ಮಿಟಿಲಿ',
'ಹೆಮ್ಮುಗುಳು',
'ಹೆಮ್ಮುರುಕು',
'ಹೆಮ್ಮೆಕಾಱ',
'ಹೆಮ್ಮೆಗಾರ',
'ಹೆಮ್ಮೆಪಡು',
'ಹೆಮ್ಮೆವಂತ',
'ಹೆರತನ',
'ಹೆರದೆಗೆ',
'ಹೆರಮಚ್ಚು',
'ಹೆರಮಣೆ',
'ಹೆರಹಿಂಗು',
'ಹೆರೆಗಟ್ಟು',
'ಹೆರೆಗಳೆ',
'ಹೆರೆನಾಮ',
'ಹೆರೆಮಣೆ',
'ಹೆರೆಯಂಬು',
'ಹೆರೆಯುಚ್ಚು',
'ಹೆರೆಯುರ್ಚು',
'ಹೆರೆಯೆಣ್ಣೆ',
'ಹೆರೆಹೆಚ್ಚು',
'ಹೆರ್ಗಡಿತಿ',
'ಹೆರ್ವಾರುವ',
'ಹೆಸರಣಿ',
'ಹೆಸರಣೆ',
'ಹೆಸರಾನು',
'ಹೆಸರಿಡು',
'ಹೆಸರಿಸು',
'ಹೆಸರುಂಡೆ',
'ಹೆಸರೆಣೆ',
'ಹೆಸರ್ಗೊಳ್',
'ಹೆಱತೆಗೆ',
'ಹೆಱದೆಗೆ',
'ಹೆಱಮೆಟ್ಟು',
'ಹೆಱಸಾರ್',
'ಹೆಱಸಾರು',
'ಹೆಱಹಿಂಗು',
'ಹೆಱೆತೆಗೆ',
'ಹೆಱೆದಲೆ',
'ಹೆಱೆಯಂಬು',
'ಹೇಂಟೆಗೊದ್ದ',
'ಹೇಕಣಿಕೆ',
'ಹೇಕರಿಸು',
'ಹೇಗತನ',
'ಹೇಟಗಾಲು',
'ಹೇಡವಿಸು',
'ಹೇಡಿಗೊಳ್',
'ಹೇಡಿಗೊಳು',
'ಹೇಡಿತನ',
'ಹೇಡಿಮಣ್ಣು',
'ಹೇತುಕ್ರಿಯೆ',
'ಹೇತುದೋಷ',
'ಹೇತುಭೂತ',
'ಹೇತೂತ್ಪ್ರೇಕ್ಷೆ',
'ಹೇತೂಪಮೆ',
'ಹೇತ್ವಾಕ್ಷೇಪ',
'ಹೇತ್ವಾಭಾಸ',
'ಹೇಮಕುಕ್ಷಿ',
'ಹೇಮಗರ್ಭ',
'ಹೇಮಚೋರ',
'ಹೇಮಣಂಬಿ',
'ಹೇಮದುಗ್ಧ',
'ಹೇಮಪುಷ್ಪ',
'ಹೇಮಲಂಬ',
'ಹೇಮಾವತಿ',
'ಹೇಮಾಹೇಮಿ',
'ಹೇರಡವಿ',
'ಹೇರಣಿಲ್',
'ಹೇರಣಿಲೆ',
'ಹೇರಾಳಿಸು',
'ಹೇರಿಗಿತ್ತಿ',
'ಹೇರುಕಾರ',
'ಹೇರೊಡಲ್',
'ಹೇರೊಡಲ',
'ಹೇರೊಡಲು',
'ಹೇಲಾಗತಿ',
'ಹೇಲುಕಸ',
'ಹೇಲುಸುಂಡೆ',
'ಹೇವಗೇಡಿ',
'ಹೇವಣಿಗ',
'ಹೇವಮಾರಿ',
'ಹೇವಯಿಸು',
'ಹೇವರಿಕೆ',
'ಹೇವರಿಸು',
'ಹೇವಿಲಂಬಿ',
'ಹೇವಿಳಂಬಿ',
'ಹೇಷಾಕೃತ',
'ಹೇಷಾಘೋಷ',
'ಹೇಷಾಧ್ವನಿ',
'ಹೇಷಾರವ',
'ಹೇಸಿಗೊಳ್ಳು',
'ಹೇಸಿತನ',
'ಹೇಹದೋಱು',
'ಹೇಳನಂಗೈ',
'ಹೇಳಾಣಿಕೆ',
'ಹೇಱುಕಾಱ',
'ಹೇಱುವೆಱು',
'ಹೈಗನುಡಿ',
'ಹೈಮವತ',
'ಹೈಮವತಿ',
'ಹೈಮಾಂಬುಜ',
'ಹೊಂಗಡಲೆ',
'ಹೊಂಗನಸು',
'ಹೊಂಗರುಗ',
'ಹೊಂಗಱಿವೂ',
'ಹೊಂಗಾಯಕ',
'ಹೊಂಗೆಲಸ',
'ಹೊಂಗೇತಕಿ',
'ಹೊಂಗೇದಗೆ',
'ಹೊಂಗೋರಟೆ',
'ಹೊಂಚುಗಾರ',
'ಹೊಂಡೆಮರ',
'ಹೊಂತಕಾತಿ',
'ಹೊಂತಕಾರ',
'ಹೊಂತಕಾರಿ',
'ಹೊಂತಕಾಱ',
'ಹೊಂತಕಾಱಿ',
'ಹೊಂತಗಾರ',
'ಹೊಂತತನ',
'ಹೊಂದಾಣಿಕೆ',
'ಹೊಂದಾವರೆ',
'ಹೊಂದುಗೂಡು',
'ಹೊಂದುಗೆಡು',
'ಹೊಂಬಳದಿ',
'ಹೊಂಬಿಸಿಲು',
'ಹೊಂಬೆಳಕು',
'ಹೊಂಬೆಳಗು',
'ಹೊಕ್ಕರಣಿ',
'ಹೊಕ್ಕರಣೆ',
'ಹೊಕ್ಕುನೂಲು',
'ಹೊಕ್ಖರಣೆ',
'ಹೊಖರಣೆ',
'ಹೊಗರಳಿ',
'ಹೊಗರಾನ್',
'ಹೊಗರಿಡು',
'ಹೊಗರುಗು',
'ಹೊಗರೆತ್ತು',
'ಹೊಗರೇರು',
'ಹೊಗರೇಳ್',
'ಹೊಗರೇಳು',
'ಹೊಗರೇಱ್',
'ಹೊಗರೇಱು',
'ಹೊಗರೊಗು',
'ಹೊಗರೊಡೆ',
'ಹೊಗರ್ಗರೆ',
'ಹೊಗವಣೆ',
'ಹೊಗಳತೆ',
'ಹೊಗಳಾಟ',
'ಹೊಗಳಿಕೆ',
'ಹೊಗಳಿಸು',
'ಹೊಗುತರು',
'ಹೊಗುವನೆ',
'ಹೊಗೆಕಂಡಿ',
'ಹೊಗೆಕಡ್ಡಿ',
'ಹೊಗೆಕೊಡು',
'ಹೊಗೆಗಣ್ಣು',
'ಹೊಗೆಗಾಡಿ',
'ಹೊಗೆಗೂಡು',
'ಹೊಗೆದೋಱು',
'ಹೊಗೆಬಂಡಿ',
'ಹೊಗೆಬತ್ತಿ',
'ಹೊಗೆಭಂಡಿ',
'ಹೊಗೆಮೊಗ',
'ಹೊಗೆಯಾಡು',
'ಹೊಗೆಸೊಪ್ಪು',
'ಹೊಗೆಹಣ',
'ಹೊಗೆಹಿಡಿ',
'ಹೊಗೆಹುಡಿ',
'ಹೊಟ್ಟಳಿಸು',
'ಹೊಟ್ಟಿಹೋಗು',
'ಹೊಟ್ಟುಕುಟ್ಟು',
'ಹೊಟ್ಟುಗುಟ್ಟು',
'ಹೊಟ್ಟುಗೆಱೆ',
'ಹೊಟ್ಟುಬಾವಿ',
'ಹೊಟ್ಟುಹಂಬು',
'ಹೊಟ್ಟೆಉರಿ',
'ಹೊಟ್ಟೆಕಟ್ಟು',
'ಹೊಟ್ಟೆಕಡಿ',
'ಹೊಟ್ಟೆಕಳಿ',
'ಹೊಟ್ಟೆಕಳೆ',
'ಹೊಟ್ಟೆಕಿಚ್ಚು',
'ಹೊಟ್ಟೆಕುಟ್ಟು',
'ಹೊಟ್ಟೆಕೋಲು',
'ಹೊಟ್ಟೆಗಟ್ಟು',
'ಹೊಟ್ಟೆಗಿಚ್ಚು',
'ಹೊಟ್ಟೆಗೋದಿ',
'ಹೊಟ್ಟೆಜಾಲಿ',
'ಹೊಟ್ಟೆತುಂಬು',
'ಹೊಟ್ಟೆನುಲಿ',
'ಹೊಟ್ಟೆನೊಳ',
'ಹೊಟ್ಟೆನೋಯು',
'ಹೊಟ್ಟೆನೋವು',
'ಹೊಟ್ಟೆಪಾಡು',
'ಹೊಟ್ಟೆಬಾಕ',
'ಹೊಟ್ಟೆಬಾಕಿ',
'ಹೊಟ್ಟೆಬಾಗೆ',
'ಹೊಟ್ಟೆಬೇನೆ',
'ಹೊಟ್ಟೆಮರ',
'ಹೊಟ್ಟೆಯಿಳಿ',
'ಹೊಟ್ಟೆಯುರಿ',
'ಹೊಟ್ಟೆಶೂಲೆ',
'ಹೊಟ್ಟೆಹೊರೆ',
'ಹೊಟ್ಟೆಹೋಗು',
'ಹೊಡಕಣೆ',
'ಹೊಡಕಿಸು',
'ಹೊಡಬಾಳು',
'ಹೊಡಮಡು',
'ಹೊಡಮೊಗೆ',
'ಹೊಡವಂಡು',
'ಹೊಡವಡು',
'ಹೊಡಸೆಂಡು',
'ಹೊಡಹೊಮ್ಮು',
'ಹೊಡೆಕೆಡೆ',
'ಹೊಡೆಗಚ್ಚು',
'ಹೊಡೆಗಿಚ್ಚು',
'ಹೊಡೆಗುಳು',
'ಹೊಡೆಗೆಡೆ',
'ಹೊಡೆಗೊಳು',
'ಹೊಡೆಚಂಡು',
'ಹೊಡೆಚೆಂಡು',
'ಹೊಡೆಜಾಲಿ',
'ಹೊಡೆದಾಟ',
'ಹೊಡೆದಾಡು',
'ಹೊಡೆಬಳ್ಳಿ',
'ಹೊಡೆಬಾಳು',
'ಹೊಡೆಮಂಚ',
'ಹೊಡೆಮಗ್ಗು',
'ಹೊಡೆಮುರಿ',
'ಹೊಡೆಯಿಸು',
'ಹೊಡೆಯುರ್ಚು',
'ಹೊಡೆವಡು',
'ಹೊಡೆವಲೆ',
'ಹೊಡೆಸೆಂಡು',
'ಹೊಡೆಹಾಳೆ',
'ಹೊಡೆಹುಲ್',
'ಹೊಡೆಹುಲ್ಲು',
'ಹೊಣಕಿಗ',
'ಹೊಣಕಿಸು',
'ಹೊಣಕೆಗೈ',
'ಹೊಣೆಕಾರ',
'ಹೊಣೆಕಾಱ',
'ಹೊಣೆಗಾರ',
'ಹೊಣೆಗಾಱ',
'ಹೊಣೆಗೆಡು',
'ಹೊಣೆಗೇಡಿ',
'ಹೊಣೆಗೊಡು',
'ಹೊಣೆಗೊಳ್',
'ಹೊಣೆಹೊರು',
'ಹೊತ್ತಿರುಗ',
'ಹೊತ್ತುಕಳೆ',
'ಹೊತ್ತುಗಳೆ',
'ಹೊತ್ತುಗಳ್ಳ',
'ಹೊತ್ತುಹೋಕ',
'ಹೊತ್ತುಹೋಕು',
'ಹೊತ್ತುಹೋಗು',
'ಹೊದಕುಳ',
'ಹೊದಕುಳಿ',
'ಹೊದಕುಳು',
'ಹೊದಱೇಳ್',
'ಹೊದಱೊಡೆ',
'ಹೊದಿಯಿಸು',
'ಹೊದೆವುಡೆ',
'ಹೊನಗನೆ',
'ಹೊನಗನ್ನೆ',
'ಹೊನಗೊನೆ',
'ಹೊನಗೊನ್ನೆ',
'ಹೊನಗೋನೆ',
'ಹೊನಲಿಡು',
'ಹೊನಲೇರು',
'ಹೊನಲೇಳು',
'ಹೊನಲೇಱು',
'ಹೊನ್ನಗನ್ನೆ',
'ಹೊನ್ನಗಾಲೆ',
'ಹೊನ್ನಗೊನ್ನೆ',
'ಹೊನ್ನರಿಕೆ',
'ಹೊನ್ನವರಿ',
'ಹೊನ್ನಾವರೆ',
'ಹೊನ್ನೊಡಲ',
'ಹೊಪ್ಪಳಿಕೆ',
'ಹೊಪ್ಪಳಿಸು',
'ಹೊಮ್ಮಿಟಿಲ',
'ಹೊಮ್ಮುನುಡಿ',
'ಹೊಯಸಣ',
'ಹೊಯಸಳ',
'ಹೊಯಿಕುಟ್ಟು',
'ಹೊಯಿಗಲ',
'ಹೊಯಿಗಿಲ',
'ಹೊಯಿಸಣ',
'ಹೊಯಿಸಳ',
'ಹೊಯ್ಗಡುಬು',
'ಹೊರಕಟ್ಟು',
'ಹೊರಕಡೆ',
'ಹೊರಕಾಲು',
'ಹೊರಕೆಯ್ಮೆ',
'ಹೊರಗಟ್ಟು',
'ಹೊರಗಡೆ',
'ಹೊರಗಣ',
'ಹೊರಚಾಚು',
'ಹೊರಚಾಳಿ',
'ಹೊರಜೀಬಿ',
'ಹೊರಡಿಸು',
'ಹೊರತನ',
'ಹೊರತರ',
'ಹೊರತರು',
'ಹೊರತೆಗೆ',
'ಹೊರದೂಡು',
'ಹೊರದೆಗೆ',
'ಹೊರದ್ವಾರ',
'ಹೊರನಾಡು',
'ಹೊರನೂಕು',
'ಹೊರನೋಟ',
'ಹೊರಪಡು',
'ಹೊರಬರು',
'ಹೊರಬಿಗ',
'ಹೊರಬೀಳು',
'ಹೊರಬುದ್ಧಿ',
'ಹೊರಮಡು',
'ಹೊರಮಾರು',
'ಹೊರಮುನಿ',
'ಹೊರಮೊಳೆ',
'ಹೊರಯಿಂಕೆ',
'ಹೊರಯಿಕೆ',
'ಹೊರಲಿಗ',
'ಹೊರವಡು',
'ಹೊರವಾಱು',
'ಹೊರಸರಿ',
'ಹೊರಸಲು',
'ಹೊರಸೀರೆ',
'ಹೊರಸೂಸು',
'ಹೊರಹಾಕು',
'ಹೊರಹಾಯು',
'ಹೊರಹೊಂಡು',
'ಹೊರಹೊಮ್ಮು',
'ಹೊರಳಾಟ',
'ಹೊರಳಾಡು',
'ಹೊರಳಿಚು',
'ಹೊರಳಿಸು',
'ಹೊರಾಂಗಣ',
'ಹೊರಿಯೊಟ್ಟೆ',
'ಹೊರೆಕಟ್ಟು',
'ಹೊರೆಕಾರ',
'ಹೊರೆಕಾಱ',
'ಹೊರೆಗಳೆ',
'ಹೊರೆಗಾಣ್',
'ಹೊರೆಗುಂದು',
'ಹೊರೆದೋಱು',
'ಹೊರೆಬಿಡು',
'ಹೊರೆಯಾಳ್',
'ಹೊರೆಯಿಡು',
'ಹೊರೆಯೇರು',
'ಹೊರೆಯೇಱು',
'ಹೊರೆಹೆಚ್ಚು',
'ಹೊರೆಹೊಗು',
'ಹೊರೆಹೊರು',
'ಹೊಲಗಾಹಿ',
'ಹೊಲಗುಲ',
'ಹೊಲಗೆಡು',
'ಹೊಲಗೇರಿ',
'ಹೊಲದೆರೆ',
'ಹೊಲದೆಱೆ',
'ಹೊಲಬಳಿ',
'ಹೊಲಬಿಗ',
'ಹೊಲಮದ್ದು',
'ಹೊಲಸಂಗಿ',
'ಹೊಲಸೇಳ್',
'ಹೊಲಸೇಳು',
'ಹೊಲೆಗಂಪು',
'ಹೊಲೆಗಾಣ್',
'ಹೊಲೆಗೆಡು',
'ಹೊಲೆಗೆಱೆ',
'ಹೊಲೆಗೇರಿ',
'ಹೊಲೆಗೊಱೆ',
'ಹೊಲೆತಡೆ',
'ಹೊಲೆತನ',
'ಹೊಲೆದೆಱೆ',
'ಹೊಲೆಪಾಟು',
'ಹೊಲೆಪಾಡು',
'ಹೊಲೆಮಗ್ಗ',
'ಹೊಲೆಮನ',
'ಹೊಲೆಮನೆ',
'ಹೊಲೆಮಾತು',
'ಹೊಲೆಯಿಸು',
'ಹೊಲೆವನೆ',
'ಹೊಲೆಸುಂಕ',
'ಹೊಲೆಹುಟ್ಟು',
'ಹೊಲೆಹೊರೆ',
'ಹೊಲ್ಲಯಿಸು',
'ಹೊಲ್ಲೆತನ',
'ಹೊವಿಸಳ',
'ಹೊಸಂತಿಲ್',
'ಹೊಸಂತಿಲು',
'ಹೊಸಕಾಡು',
'ಹೊಸಕಾಲ',
'ಹೊಸಗಾಲ',
'ಹೊಸಗೆಯ್',
'ಹೊಸಜೀವ',
'ಹೊಸತನ',
'ಹೊಸತಿಲ್',
'ಹೊಸತಿಲು',
'ಹೊಸನೀರು',
'ಹೊಸಬಿಗ',
'ಹೊಸರಕ್ತ',
'ಹೊಸವರು',
'ಹೊಸೆಬಿಡು',
'ಹೊಳಕಿಸು',
'ಹೊಳಚಿಕೆ',
'ಹೊಳಪಿಸು',
'ಹೊಳಪೇರು',
'ಹೊಳಲಿಡು',
'ಹೊಳಲೇಳು',
'ಹೊಳವಳಿ',
'ಹೊಳೆಇಪ್ಪೆ',
'ಹೊಳೆಕೌವ',
'ಹೊಳೆಗರ',
'ಹೊಳೆಗಾರ',
'ಹೊಳೆಚುಳ್ಳಿ',
'ಹೊಳೆತುಂಬೆ',
'ಹೊಳೆತುತ್ತ',
'ಹೊಳೆತುಮ್ರಿ',
'ಹೊಳೆನಕ್ಕಿ',
'ಹೊಳೆನಗೆ',
'ಹೊಳೆನೆಕ್ಕಿ',
'ಹೊಳೆಬೋಸಿ',
'ಹೊಳೆಮತ್ತಿ',
'ಹೊಳೆಮೆಟ್ಟು',
'ಹೊಳೆಯಿಸು',
'ಹೊಳೆಲಕ್ಕಿ',
'ಹೊಳೆಸಾಲು',
'ಹೊಳೆಹಿಪ್ಪೆ',
'ಹೊಳೆಹೊನ್ನೆ',
'ಹೊಳ್ಳಿಱಿತ',
'ಹೊಳ್ಳುಗಳೆ',
'ಹೊಳ್ಳುವಾಱು',
'ಹೊಳ್ಳುಹಾಱು',
'ಹೊಳ್ಳುಹೊಟ್ಟೆ',
'ಹೊಱಕಟ್ಟು',
'ಹೊಱಕುಳಿ',
'ಹೊಱಕೆಯ್',
'ಹೊಱಗಡೆ',
'ಹೊಱಗಣ',
'ಹೊಱಗುಂಡ',
'ಹೊಱಗುಡಿ',
'ಹೊಱಡಿಸು',
'ಹೊಱಡುಮೈ',
'ಹೊಱದೆಗೆ',
'ಹೊಱದ್ವಾರ',
'ಹೊಱನೂಂಕು',
'ಹೊಱಬಿಗ',
'ಹೊಱಬೀಸು',
'ಹೊಱಬೀಳ್',
'ಹೊಱಬೀಳು',
'ಹೊಱಯಿಂಕೆ',
'ಹೊಱಯಿಕೆ',
'ಹೊಱವಂಡು',
'ಹೊಱವಡು',
'ಹೊಱವಾಱ್',
'ಹೊಱವಾಱು',
'ಹೊಱಸೂಸು',
'ಹೊಱಹಾಕು',
'ಹೊಱಹೊಮ್ಮು',
'ಹೊಱುಗಡೆ',
'ಹೊಱೆಕಾಱ',
'ಹೊಱೆಗಟ್ಟು',
'ಹೊಱೆಗಾಱ',
'ಹೊಱೆತಲೆ',
'ಹೊಱೆದಲೆ',
'ಹೊಱೆಯಾಳು',
'ಹೋಕಾಯಂತ್ರ',
'ಹೋಕುಳಿಸು',
'ಹೋಕೆತನ',
'ಹೋಗಾಡಿಸು',
'ಹೋಗುಎಳೆ',
'ಹೋಗೊಳಿಸು',
'ಹೋತಕರಿ',
'ಹೋಮಂಗೊಳ್',
'ಹೋಮಕುಂಡ',
'ಹೋಮದ್ರವ್ಯ',
'ಹೋಮಧೇನು',
'ಹೋಮಾನಲ',
'ಹೋಮುಗಲ',
'ಹೋರಾಯಂತ್ರ',
'ಹೋರಾಶಾಸ್ತ್ರ',
'ಹೋರಾಹೋರಿ',
'ಹೋರಿಮಿಗ',
'ಹೋರಿಯಡೆ',
'ಹೋರುಗಳೆ',
'ಹೋಷಿಯಾರ್',
'ಹೋಳಿಹಬ್ಬ',
'ಹೋಳುಗಡಿ',
'ಹೋಳುಗಳೆ',
'ಹೋಳುಗುಟ್ಟು',
'ಹ್ಯಾಪುಮೋರೆ',
'ಹ್ರಸ್ವಕಾಕು',
'ಹ್ರಸ್ವಮತಿ',
'ಹ್ರಸ್ವಸ್ವರ',
'ಹ್ರಸ್ವಾಕ್ಷರ',
]


export const WIN_MESSAGES = ['ಶಭಾಷ್!', 'ಭಲೇ!', 'ಅದ್ಭುತ!']
export const GAME_COPIED_MESSAGE = 'ಕಾಪಿ ಮಾಡಲಾಗಿದೆ'
export const ABOUT_GAME_MESSAGE = 'ಆಟದ ಬಗ್ಗೆ'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'ಅಕ್ಷರಗಳು ಸಾಲುತ್ತಿಲ್ಲ'
export const WORD_NOT_FOUND_MESSAGE = 'ವರ್ಡಲ್ಲ'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `ಸರಿಯಾದ ಉತ್ತರ "${solution}"`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'ಅಂಕಿ-ಅಂಶ'
export const GUESS_DISTRIBUTION_TEXT = 'ಗೆಲುವುಗಳು'
export const NEW_WORD_TEXT = 'ಹೊಸ ಪದ'
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'ಒಟ್ಟು ಪ್ರಯತ್ನಗಳು'
export const SUCCESS_RATE_TEXT = 'ಶೇ. ಗೆಲುವು'
export const CURRENT_STREAK_TEXT = 'ಗೆಲುವಿನ ಸರಣಿ'
export const BEST_STREAK_TEXT = 'ದಾಖಲೆ ಗೆಲುವಿನ ಸರಣಿ'

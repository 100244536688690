import { WORDS } from '../constants/wordlist'
import { WORDS4 } from '../constants/wordlist4'
import { VALIDGUESSES } from '../constants/validGuesses'
import { VALIDGUESSES4 } from '../constants/validGuesses4'
import _hexes from "../constants/hexes.json";

export interface Hexes {
  center : string
  letters : string[]
  words : string[]
}

const hexes = _hexes as Hexes[]

export const isWordInWordList = (word: string, wordLength: number) => {
  return (
    wordLength===5?VALIDGUESSES.includes(word):VALIDGUESSES4.includes(word)
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getWordOfDayIndex = () => {
  // January 1, 2022 Game Epoch IST
  const epoch = new Date(2022, 0)
  const start = new Date(epoch)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  let index = 0
  while (start < today) {
    index++
    start.setDate(start.getDate() + 1)
  }
  return (index - 22) % 371
}


export const getHexPuzzleIdx = () => {
  // January 1, 2022 Game Epoch IST
  const epoch = new Date(2023, 1, 21)
  const start = new Date(epoch)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  let index = 0
  while (start < today) {
    index++
    start.setDate(start.getDate() + 1)
  }
  return index
}

export const getTomorrow = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const nextDay = new Date(today)
  nextDay.setDate(today.getDate() + 1)
  return nextDay.valueOf()
}

export const getWordOfDay = () => {
  return solution
}

export const setWordOfDay = (wordLength:number) => {
  solution = wordLength===5?WORDS[solutionIndex]:WORDS4[solutionIndex]
}

export const setHexWord = () => {
  hexSolution = hexes[hexPuzzleIdx].words[0]
}

export const solutionIndex = getWordOfDayIndex()
export var solution = WORDS[solutionIndex]
export var hexPuzzleIdx = (getHexPuzzleIdx()+2) % _hexes.length
export var hexSolution = "solution"
export const tomorrow = getTomorrow()


import { Dispatch, Fragment, SetStateAction } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'

type Props = {
  games: { name: string }[]
  currGame: { name: string }
  setCurrGame: Dispatch<SetStateAction<{ name: string }>>
  clearGameState: () => void
}

export function GameOption({
  games,
  currGame,
  setCurrGame,
  clearGameState,
}: Props) {
  const handleChange = (value: { name: string }) => {
    setCurrGame(value)
  }
  return (
    <div className="relative w-24">
      <Listbox value={currGame} onChange={handleChange}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full rounded-lg text-left hover:border-1">
            {currGame.name}
            <span className="pointer-events-none absolute inset-y-1 right-0 items-center pr-1">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {games.map((game, gameIdx) => (
                <Listbox.Option
                  key={gameIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                    }`
                  }
                  value={game}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {game.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

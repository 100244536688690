import {
  InformationCircleIcon,
  ChartBarIcon,
  SunIcon,
  MenuIcon,
  BackspaceIcon,
} from '@heroicons/react/outline'

import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { GameOption } from './components/modals/GameOption'
import { StatsModal } from './components/modals/StatsModal'
import {
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  ABOUT_GAME_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
} from './constants/strings'
import { addStatsForCompletedGame, loadStats } from './lib/stats'

import {
  isWordInWordList,
  isWinningWord,
  solution,
  setWordOfDay,
  hexPuzzleIdx,
  hexSolution,
  setHexWord,
} from './lib/words'

import {
  getWordLengthFromLocalStorage,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  saveHexGameStateToLocalStorage,
  loadHexGameStateToLocalStorage,
} from './lib/localStorage'

import { knTokenize } from './lib/kannada'
import { isValid, vowelToVowelMap } from './lib/statuses'
import { SettingsModal } from './components/modals/SettingsModal'

import './App.css'
import _hexes from './constants/hexes.json'
import { HexGridBox } from './components/grid/HexGrid'

const ALERT_TIME_MS = 2000
const HEX_WINNING_FACTOR = 0.28 // Equals 28%, just a number :/

const games = [{ name: 'ವರ್ಡಲ್ಲ' }, { name: 'ದುಂbee' }]
export interface Hexes {
  center: string
  letters: string[]
  words: string[]
}

const hexes = _hexes as Hexes[]

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches
  const [currGame, setCurrGame] = useState(
    games[parseInt(localStorage.getItem('gameName') || '0')]
  )
  const [currentGuess, setCurrentGuess] = useState('')
  const [currentHexGuess, setCurrentHexGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(() => {
    var storedWordLength = getWordLengthFromLocalStorage()
    if (!storedWordLength) {
      return true
    } else {
      return false
    }
  })

  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isWordAlreadySeen, setIsWordAlreadySeen] = useState(false)
  const [isCenterLetterMissing, setIsCenterLetterMissing] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [successAlert, setSuccessAlert] = useState('')
  const [shiftPressed, setShiftPresser] = useState(false)
  const [wordLength, setWordLength] = useState(5)
  const [settingsWordLength, setSettingsWordLength] = useState(5)
  const [enabled, setEnabled] = useState(true)

  const [guesses, setGuesses] = useState<string[]>(() => {
    var storedWordLength = getWordLengthFromLocalStorage()
    if (storedWordLength === 4 || storedWordLength === 5) {
      setWordLength(storedWordLength)
      setWordOfDay(storedWordLength)
    }

    if (storedWordLength) {
      if (storedWordLength !== wordLength) {
        setWordLength(storedWordLength)
        setCurrentGuess('')
      }
      const loaded = loadGameStateFromLocalStorage(storedWordLength)
      const gameWasWon = loaded?.guesses.includes(solution)
      if (gameWasWon) {
        setIsGameWon(true)
      }
      if (loaded?.solution !== solution) {
        return []
      }
      return loaded.guesses
    }
    return []
  })

  const [hexGuesses, setHexGuesses] = useState<string[]>(() => {
    setHexWord()
    const loaded = loadHexGameStateToLocalStorage()
    if (!loaded) return []
    if (loaded?.hexSolution !== hexSolution) return []
    return loaded.hexGuesses
  })
  const [percentage, setPercentage] = useState(() => {
    var num =
      (hexGuesses?.length /
        Math.floor(hexes[hexPuzzleIdx].words.length * HEX_WINNING_FACTOR)) *
      100
    return num.toString() + '%'
  })

  const [stats, setStats] = useState(() => loadStats())

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution }, wordLength)
  })

  useEffect(() => {
    saveHexGameStateToLocalStorage({ hexGuesses, hexSolution })
  })

  const setCurrGameAndSaveState = (value: { name: string }) => {
    console.log(value.name, games[0].name)
    if (value.name === games[0].name) {
      localStorage.setItem('gameName', '0')
    } else {
      localStorage.setItem('gameName', '1')
    }
    setCurrGame(value)
  }

  useEffect(() => {
    if (currGame === games[0]) {
      if (isGameWon) {
        setSuccessAlert(
          WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
        )
        setTimeout(() => {
          setSuccessAlert('')
          setIsStatsModalOpen(true)
        }, ALERT_TIME_MS)
      }
      if (isGameLost) {
        setTimeout(() => {
          setIsStatsModalOpen(true)
        }, ALERT_TIME_MS)
      }
    }
  }, [isGameWon, isGameLost, currGame])

  const onChar = (value: string) => {
    if (
      knTokenize(currentGuess.concat(value)).length <= wordLength &&
      guesses.length < 8 &&
      isValid(currentGuess, value) &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onHexChar = (value: string) => {
    if (!(currentHexGuess.length === 0)) {
      value = vowelToVowelMap.has(value) ? vowelToVowelMap.get(value) : value
    }
    if (value) setCurrentHexGuess(`${currentHexGuess}${value}`)
  }

  const onHexCharDelete = () => {
    setCurrentHexGuess(currentHexGuess.slice(0, -1))
  }

  const clearGameState = () => {
    setCurrentHexGuess('')
  }

  const handleEnterWord = () => {
    console.log(hexGuesses, currentHexGuess)
    if (hexGuesses?.includes(currentHexGuess)) {
      setIsWordAlreadySeen(true)
      return setTimeout(() => {
        setIsWordAlreadySeen(false)
      }, ALERT_TIME_MS)
    }
    if (hexes[hexPuzzleIdx].words.includes(currentHexGuess)) {
      hexGuesses?.push(currentHexGuess)
      var num =
        (hexGuesses?.length /
          Math.floor(hexes[hexPuzzleIdx].words.length * HEX_WINNING_FACTOR)) *
        100
      setPercentage(num.toString() + '%')
      setHexGuesses(hexGuesses)
      if (currentHexGuess === hexes[hexPuzzleIdx].words[0]) {
        setSuccessAlert('ಈ ದಿನದ ಪದ!')
        setTimeout(() => {
          setSuccessAlert('')
        }, ALERT_TIME_MS)
      }
      setCurrentHexGuess('')
      if (
        hexGuesses?.length >=
        Math.floor(hexes[hexPuzzleIdx].words.length * HEX_WINNING_FACTOR)
      ) {
        setSuccessAlert('೧೦೦% ಗುರಿ ಮಟ್ಡಿದ್ದೀರಿ!')
        setTimeout(() => {
          setSuccessAlert('')
        }, ALERT_TIME_MS)
      }
    } else {
      if (currentHexGuess.length < 3) {
        setIsNotEnoughLetters(true)
        return setTimeout(() => {
          setIsNotEnoughLetters(false)
        }, ALERT_TIME_MS)
      }
      if (!currentHexGuess.includes(hexes[hexPuzzleIdx].center)) {
        setIsCenterLetterMissing(true)
        return setTimeout(() => {
          setIsCenterLetterMissing(false)
        }, ALERT_TIME_MS)
      }
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, ALERT_TIME_MS)
    }
  }

  const doColorPrint = (value: string) => {
    return knTokenize(value).map((letter, i) =>
      letter.slice(1).join('')[0] === hexes[hexPuzzleIdx].center[0] ? (
        <span className="text-blue-700 dark:text-blue-400">
          {' '}
          {letter.slice(1).join('')}
        </span>
      ) : (
        letter.slice(1).join('')
      )
    )
  }

  const onChange = () => {
    setEnabled(!enabled)
  }

  const changeWordLength = () => {
    if (wordLength !== settingsWordLength) {
      const loaded = loadGameStateFromLocalStorage(settingsWordLength)
      setWordLength(settingsWordLength)
      setWordOfDay(settingsWordLength)
      setIsGameWon(false)
      setIsGameLost(false)
      if (loaded) {
        if (loaded?.solution !== solution) {
          setGuesses([])
          return
        }
        if (loaded?.guesses.includes(solution)) {
          setGuesses(loaded?.guesses)
          setIsGameWon(true)
          return
        }
        setGuesses(loaded?.guesses)
      } else {
        setGuesses([])
      }
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onShift = () => {
    setShiftPresser(!shiftPressed)
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (!(knTokenize(currentGuess).length === wordLength)) {
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, ALERT_TIME_MS)
    }

    if (!isWordInWordList(currentGuess, wordLength) && enabled) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, ALERT_TIME_MS)
    }

    const winningWord = isWinningWord(currentGuess)

    if (
      knTokenize(currentGuess).length === wordLength &&
      guesses.length < 8 &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true)
      }

      if (guesses.length === 7) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsGameLost(true)
      }
    }
  }

  if (currGame === games[0]) {
    return (
      <div className="py-4 max-w-7xl mx-auto sm:px-2 lg:px-8">
        <div className="flex w-80 mx-auto items-center mb-4 mt-6">
          <h1 className="text-xl grow font-bold dark:text-white">
            <GameOption
              games={games}
              currGame={currGame}
              setCurrGame={setCurrGameAndSaveState}
              clearGameState={clearGameState}
            />
          </h1>
          <SunIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => handleDarkMode(!isDarkMode)}
          />

          <Alert
            message={WORD_NOT_FOUND_MESSAGE}
            isOpen={isWordNotFoundAlertOpen}
          />
          <Alert message={CORRECT_WORD_MESSAGE(solution)} isOpen={isGameLost} />

          <InformationCircleIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />

          <ChartBarIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsStatsModalOpen(true)}
          />

          <MenuIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
        <Grid
          guesses={guesses}
          currentGuess={currentGuess}
          wordLength={wordLength}
        />
        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          onShift={onShift}
          shiftPressed={shiftPressed}
          guesses={guesses}
          currentGuess={currentGuess}
        />
        <SettingsModal
          isOpen={isSettingsModalOpen}
          handleClose={() => {
            setIsSettingsModalOpen(false)
            changeWordLength()
          }}
          enabled={enabled}
          onChange={onChange}
          wordLength={settingsWordLength}
          setWordLength={setSettingsWordLength}
        />
        <InfoModal
          gameName="ವರ್ಡಲ್ಲ"
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
        <StatsModal
          isOpen={isStatsModalOpen}
          handleClose={() => setIsStatsModalOpen(false)}
          guesses={guesses}
          gameStats={stats}
          isGameLost={isGameLost}
          isGameWon={isGameWon}
          wordLength={wordLength}
          handleShare={() => {
            setSuccessAlert(GAME_COPIED_MESSAGE)
            return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
          }}
        />
        <AboutModal
          isOpen={isAboutModalOpen}
          handleClose={() => setIsAboutModalOpen(false)}
        />

        <button
          type="button"
          className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => window.open('https://alar.ink')}
        >
          ನಿಘಂಟು
        </button>

        <button
          type="button"
          className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
          onClick={() => setIsAboutModalOpen(true)}
        >
          {ABOUT_GAME_MESSAGE}
        </button>

        <Alert
          message={NOT_ENOUGH_LETTERS_MESSAGE}
          isOpen={isNotEnoughLetters}
        />
        <Alert
          message={WORD_NOT_FOUND_MESSAGE}
          isOpen={isWordNotFoundAlertOpen}
        />
        <Alert message={CORRECT_WORD_MESSAGE(solution)} isOpen={isGameLost} />
        <Alert
          message={successAlert}
          isOpen={successAlert !== ''}
          variant="success"
        />
      </div>
    )
  }
  return (
    <div className="py-4 max-w-7xl mx-auto sm:px-2 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-4 mt-6">
        <h1 className="text-xl grow font-bold dark:text-white">
          <GameOption
            games={games}
            currGame={currGame}
            setCurrGame={setCurrGameAndSaveState}
            clearGameState={clearGameState}
          />
        </h1>
        <SunIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => handleDarkMode(!isDarkMode)}
        />
        <InformationCircleIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
      </div>

      <div className="items-center flex">
        <button
          type="button"
          className="mx-auto mt-4 float-right px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
          onClick={() => clearGameState()}
        >
          Clear
        </button>
        <h1 className="text-[40px] h-16 w-60 dark:text-white items-center justify-center rounded flex">
          {doColorPrint(currentHexGuess)}
        </h1>
        <BackspaceIcon
          className="h-6 w-6 mt-4  flex relative float-right cursor-pointer dark:stroke-white"
          onClick={() => onHexCharDelete()}
        />
        <button
          type="button"
          className="mx-auto mt-4 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
          onClick={() => handleEnterWord()}
        >
          Enter
        </button>
      </div>

      <HexGridBox
        centerLetter={hexes[hexPuzzleIdx].center}
        letters={hexes[hexPuzzleIdx].letters}
        onHexChar={onHexChar}
        onHexCharDelete={onHexCharDelete}
        handleEnterWord={handleEnterWord}
      />

      <div className="flex items-center justify-center mb-4">
        <div className="w-40 bg-gray-200 h-1">
          <div className="bg-blue-600 h-1" style={{ width: percentage }}></div>
        </div>
        <h2 className="dark:text-white ml-2">
          {' '}
          {hexGuesses.length +
            '/' +
            Math.floor(
              hexes[hexPuzzleIdx].words.length * HEX_WINNING_FACTOR
            )}{' '}
        </h2>
      </div>

      <div className="items-center flex justify-center">
        <h2>
          {hexGuesses?.map((value, index, array) => (
            <p className="dark:text-white">{value}</p>
          ))}
        </h2>
      </div>

      <InfoModal
        gameName="ದುಂbee"
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />

      <Alert message={NOT_ENOUGH_LETTERS_MESSAGE} isOpen={isNotEnoughLetters} />
      <Alert message={'ಪದ ಕೊಟ್ಟಾಗಿದೆ'} isOpen={isWordAlreadySeen} />
      <Alert
        message={'"' + hexes[hexPuzzleIdx].center + '" ಅಕ್ಷರ ಪದದಲ್ಲಿ ಇಲ್ಲ'}
        isOpen={isCenterLetterMissing}
      />
      <Alert
        message={WORD_NOT_FOUND_MESSAGE}
        isOpen={isWordNotFoundAlertOpen}
      />
      <Alert message={CORRECT_WORD_MESSAGE(solution)} isOpen={isGameLost} />
      <Alert
        message={successAlert}
        isOpen={successAlert !== ''}
        variant="success"
      />
    </div>
  )
}

export default App


export const VALIDGUESSES = [
'ಅಂಕಕರಣ',
'ಅಂಕಕಹಳೆ',
'ಅಂಕಗಣಕ',
'ಅಂಕಗಣಿತ',
'ಅಂಕಗದ್ಯಾಣ',
'ಅಂಕಚಾರಣೆ',
'ಅಂಕಣಕಾರ',
'ಅಂಕಣಗಾತಿ',
'ಅಂಕಣಗಾರ',
'ಅಂಕಣದಾಯ',
'ಅಂಕಣಬಲಿ',
'ಅಂಕಣಿಯೊಕ್ಕು',
'ಅಂಕತ್ರಿಣೇತ್ರ',
'ಅಂಕದುವರ',
'ಅಂಕದೌಷದ',
'ಅಂಕಪಟ್ಟಿಕೆ',
'ಅಂಕಪರದೆ',
'ಅಂಕಮಂಡಣೆ',
'ಅಂಕವಿರಾಮ',
'ಅಂಕಾವತಾರ',
'ಅಂಕಿತಗೆಯ್',
'ಅಂಕಿತನಾಮ',
'ಅಂಕಿತವಾಗು',
'ಅಂಕಿತಹಾಕು',
'ಅಂಕುಪರದೆ',
'ಅಂಕುರವಡೆ',
'ಅಂಕುರವೇರು',
'ಅಂಕುರಾರ್ಪಣ',
'ಅಂಕುರಾರ್ಪಣೆ',
'ಅಂಕುಶಧರ',
'ಅಂಕುಶಹಾಕು',
'ಅಂಕುಸವಿಡು',
'ಅಂಕೊಲೆಗಾಲೆ',
'ಅಂಕೊಲ್ಲಜಲ',
'ಅಂಕೋಲಜಲ',
'ಅಂಕೋಲಸಾರ',
'ಅಂಕೋಲೆಗಾಲೆ',
'ಅಂಕೋಲೆವಿತ್ತು',
'ಅಂಗಚ್ಛದನ',
'ಅಂಗಚ್ಛೇದನ',
'ಅಂಗಜರಾಗ',
'ಅಂಗಜರೂಪು',
'ಅಂಗಜಾವಿಗೆ',
'ಅಂಗಜಾಹವ',
'ಅಂಗಝಡತಿ',
'ಅಂಗಡಿಕಾರ',
'ಅಂಗಡಿಕಾಱ',
'ಅಂಗಡಿಕೇರಿ',
'ಅಂಗಡಿಗಾಱ',
'ಅಂಗಡಿಗೇರಿ',
'ಅಂಗಡಿಚೀಟಿ',
'ಅಂಗಡಿದೆಱು',
'ಅಂಗಡಿದೆಱೆ',
'ಅಂಗಡಿಬಿಚ್ಚು',
'ಅಂಗಡಿಬೀದಿ',
'ಅಂಗಡಿಮಂಡ',
'ಅಂಗಡಿಮದ್ದು',
'ಅಂಗಡಿಮನೆ',
'ಅಂಗಡಿಯಿಕ್ಕು',
'ಅಂಗಡಿಯಿಡು',
'ಅಂಗಡಿಸಾಲ',
'ಅಂಗಡಿಸೂಱೆ',
'ಅಂಗಡಿಹಚ್ಚು',
'ಅಂಗಡಿಹಾಕು',
'ಅಂಗಡಿಹೂಡು',
'ಅಂಗಣವಾವಿ',
'ಅಂಗಣವೆಟ್ಟು',
'ಅಂಗತಂಗನೆ',
'ಅಂಗತಕ್ಕಲು',
'ಅಂಗತಕ್ಕಲೆ',
'ಅಂಗತಾಕನೆ',
'ಅಂಗದುಡಿಗೆ',
'ಅಂಗನವಾಡಿ',
'ಅಂಗನಾಜೀವಿ',
'ಅಂಗಪ್ರತ್ಯಂಗ',
'ಅಂಗಭ್ರಮಣೆ',
'ಅಂಗಮರ್ದಕ',
'ಅಂಗಮರ್ದಕಿ',
'ಅಂಗಮರ್ದನ',
'ಅಂಗಮರ್ದನೆ',
'ಅಂಗರಕ್ಷಕ',
'ಅಂಗರಕ್ಷಣೆ',
'ಅಂಗರಗಡ್ಡೆ',
'ಅಂಗರಚನೆ',
'ಅಂಗರಬೇರು',
'ಅಂಗರೋಳಿಗೆ',
'ಅಂಗಲಾಚಿಕೆ',
'ಅಂಗಲಾಚಿಸು',
'ಅಂಗವಿಕಲ',
'ಅಂಗವಿಕಲೆ',
'ಅಂಗವಿಕಾರ',
'ಅಂಗವಿಕಾರಿ',
'ಅಂಗವಿಕೃತಿ',
'ಅಂಗವಿಕ್ಷೇಪ',
'ಅಂಗವೈಕಲ್ಯ',
'ಅಂಗಶೋಧನೆ',
'ಅಂಗಸಂಸಾರ',
'ಅಂಗಸಂಸಾರಿ',
'ಅಂಗಸಂಸ್ಕಾರ',
'ಅಂಗಸಾಧಕ',
'ಅಂಗಸಾಧನೆ',
'ಅಂಗಸುಯ್ದಾನ',
'ಅಂಗಸ್ಫುರಣೆ',
'ಅಂಗಹೀನತೆ',
'ಅಂಗಳತಳಿ',
'ಅಂಗಳುಗುಪ್ಪಿ',
'ಅಂಗಾಂಗಿಭಾವ',
'ಅಂಗಾಂಶಕೃಷಿ',
'ಅಂಗಾರಕಟ್ಟು',
'ಅಂಗಾರಕಾರ',
'ಅಂಗಾರಗಂಧಿ',
'ಅಂಗಾರಗಡ್ಡೆ',
'ಅಂಗಾರಚೂಡ',
'ಅಂಗಾರಪರ್ಣಿ',
'ಅಂಗಾರಮಣಿ',
'ಅಂಗಾರವಲ್ಲಿ',
'ಅಂಗಾವಬದ್ಧ',
'ಅಂಗಾವಶೇಷ',
'ಅಂಗೀಕರಣ',
'ಅಂಗೀಕರಿಸು',
'ಅಂಗೀಕಾರಾರ್ಹ',
'ಅಂಗುಲಿಕ್ರಿಯೆ',
'ಅಂಗುಲಿಗಾಮಿ',
'ಅಂಗುಲಿತ್ರಾಣ',
'ಅಂಗುಲಿಪಾದಿ',
'ಅಂಗುಲಿಮುದ್ರೆ',
'ಅಂಗುಲೀಯಕ',
'ಅಂಗುಳಿಮುಳ್',
'ಅಂಗೆಯ್ಯ ನೆಲ್ಲಿ',
'ಅಂಘ್ರಿಪರ್ಣಿಕೆ',
'ಅಂಘ್ರಿವಲಯ',
'ಅಂಘ್ರಿವಲ್ಲಿಕೆ',
'ಅಂಚುಕಟ್ಟಿಸು',
'ಅಂಚುಕೂಡಿಸು',
'ಅಂಚೆ ಇಲಾಖೆ',
'ಅಂಚೆಕಚೇರಿ',
'ಅಂಚೆಕಾಗದ',
'ಅಂಚೆಗಮನೆ',
'ಅಂಚೆಗಾಮಿನಿ',
'ಅಂಚೆಜವಾನ',
'ಅಂಚೆಟಪಾಲು',
'ಅಂಚೆದಿಪ್ಪುಳ್',
'ಅಂಚೆದುಪ್ಪುಳ್',
'ಅಂಚೆನೌಕರ',
'ಅಂಚೆಪೆಟ್ಟಿಗೆ',
'ಅಂಚೆಮುತ್ಸದ್ದಿ',
'ಅಂಜನಕೇಶಿ',
'ಅಂಜನಕ್ರಿಯೆ',
'ಅಂಜನಗಾರ',
'ಅಂಜನಚೋರ',
'ಅಂಜನವಣ್ಣ',
'ಅಂಜನಸಿದ್ಧ',
'ಅಂಜನಸಿದ್ಧಿ',
'ಅಂಜನಾವತಿ',
'ಅಂಜಮೆಗೊಡು',
'ಅಂಜಲಿಪುಟ',
'ಅಂಜಲಿಬಂಧ',
'ಅಂಜಲಿಬದ್ಧ',
'ಅಂಜಲೀಬದ್ಧ',
'ಅಂಜುಪುರುಕ',
'ಅಂಜುಪುರುಕಿ',
'ಅಂಜುಬುರುಕ',
'ಅಂಜುಬುರುಕಿ',
'ಅಂಜೂರನೀಲಿ',
'ಅಂಟಿಡುವಲೆ',
'ಅಂಟುಕಲಿಕೆ',
'ಅಂಟುಕಲಿಗೆ',
'ಅಂಟುಕಾಮಿನಿ',
'ಅಂಟುಗಟ್ಟಿಗೆ',
'ಅಂಟುಗರಿಕೆ',
'ಅಂಟುಗೊಳಿಸು',
'ಅಂಟುಜನಕ',
'ಅಂಟುತೊಗರಿ',
'ಅಂಟುಪುರಲೆ',
'ಅಂಟುಬಂದುಗೆ',
'ಅಂಟುಬಾರಿಸು',
'ಅಂಟುಮುರುವು',
'ಅಂಡಕಕಲ್ಲು',
'ಅಂಡಕದಂಡ',
'ಅಂಡಕದ್ವಾರ',
'ಅಂಡಕಧರ',
'ಅಂಡಕಗರ್ಭ',
'ಅಂಡಖರ್ಬೂಜ',
'ಅಂಡಜಧ್ವಜ',
'ಅಂಡಜಾಧಿಪ',
'ಅಂಡಲೆವಡೆ',
'ಅಂಡವೃತ್ತಜ',
'ಅಂಡಶಾಸ್ತ್ರಜ್ಞ',
'ಅಂಡಶಾಸ್ತ್ರಜ್ಞೆ',
'ಅಂಡಿಗಾಣಿಸು',
'ಅಂಡೆಕುರುಬ',
'ಅಂಡೆಬಿದಿರು',
'ಅಂಡೆಹರಳು',
'ಅಂತಃಕರಣ',
'ಅಂತಃಕರಣಿ',
'ಅಂತಃಕರಿಸು',
'ಅಂತಃಕರೋಟಿ',
'ಅಂತಃಕಲಹ',
'ಅಂತಃಕಲುಷ',
'ಅಂತಃಕಳಂಕ',
'ಅಂತಃಕೋಪಕ',
'ಅಂತಃಕ್ಷೇಪಕ',
'ಅಂತಃಕ್ಷೇಪಿಸು',
'ಅಂತಃಪಟಲ',
'ಅಂತಃಪಾತಿತ್ವ',
'ಅಂತಃಪ್ರಭೇದ',
'ಅಂತಃಪ್ರೇರಣೆ',
'ಅಂತಃಶಲ್ಯತೆ',
'ಅಂತಃಸ್ಫಾರಿತ',
'ಅಂತಃಸ್ರಾವಕ',
'ಅಂತಕದೂತ',
'ಅಂತಕಮುಖ',
'ಅಂತಕವೈರಿ',
'ಅಂತಕಹರ',
'ಅಂತಕಾಂತಕ',
'ಅಂತಕಾನುಗ',
'ಅಂತಗಮನ',
'ಅಂತಗಾಣಿಸು',
'ಅಂತಯಮಕ',
'ಅಂತರಂತರ',
'ಅಂತರಂಗುಡು',
'ಅಂತರಕಾಲ',
'ಅಂತರಕೋನ',
'ಅಂತರಕ್ರಿಯೆ',
'ಅಂತರಗಂಗೆ',
'ಅಂತರಗಾಯಿ',
'ಅಂತರತಮ',
'ಅಂತರಪಟ',
'ಅಂತರಮಾಡು',
'ಅಂತರಮುಖ',
'ಅಂತರವಟ್ಟ',
'ಅಂತರವಲ್ಲಿ',
'ಅಂತರಶೈವ',
'ಅಂತರಶೌಚ',
'ಅಂತರಸ್ವರ',
'ಅಂತರಾಂತರ',
'ಅಂತರಾತ್ಮಕ',
'ಅಂತರಾಯಣ',
'ಅಂತರಾಳಿಕೆ',
'ಅಂತರಿಕ್ಷೀಯ',
'ಅಂತರೀಕ್ಷಣೆ',
'ಅಂತರ್ಗಮಕ',
'ಅಂತರ್ಜಠರ',
'ಅಂತರ್ಜನಿತ',
'ಅಂತರ್ಜಲೀಯ',
'ಅಂತರ್ಜಾತೀಯ',
'ಅಂತರ್ದರ್ಶಕ',
'ಅಂತರ್ದರ್ಶನ',
'ಅಂತರ್ದಹನ',
'ಅಂತರ್ದಾಹಕ',
'ಅಂತರ್ದೇಶೀಯ',
'ಅಂತರ್ನಾಟಕ',
'ಅಂತರ್ನಿಯಮ',
'ಅಂತರ್ನಿಯುಕ್ತ',
'ಅಂತರ್ನಿರ್ಮಿತ',
'ಅಂತರ್ನಿವಿಷ್ಠ',
'ಅಂತರ್ನಿಹಿತ',
'ಅಂತರ್ಬೀಜಾಣು',
'ಅಂತರ್ಬೋಧನೆ',
'ಅಂತರ್ಭಾಸಿತ',
'ಅಂತರ್ಮಂಡಲ',
'ಅಂತರ್ಮಂದಿರ',
'ಅಂತರ್ಮಥನ',
'ಅಂತರ್ಮನನ',
'ಅಂತರ್ಮಲಿನ',
'ಅಂತರ್ಮಲಿನೆ',
'ಅಂತರ್ಮಾನಸ',
'ಅಂತರ್ಮಿಥುನ',
'ಅಂತರ್ಮುಖತೆ',
'ಅಂತರ್ಮುಹೂರ್ತ',
'ಅಂತರ್ಯಾಮಿಸು',
'ಅಂತರ್ವಂಶಿಕ',
'ಅಂತರ್ವರ್ಧಿಷ್ಣು',
'ಅಂತರ್ವಲನ',
'ಅಂತರ್ವಲಿತ',
'ಅಂತರ್ವಹನ',
'ಅಂತರ್ವಿಕಾಸ',
'ಅಂತರ್ವಿರೋಧ',
'ಅಂತರ್ವೀಕ್ಷಣೆ',
'ಅಂತರ್ವೃತ್ತಜ',
'ಅಂತರ್ವೇಶನ',
'ಅಂತರ್ವೇಶಿಸು',
'ಅಂತಸ್ತಿಮಿತ',
'ಅಂತಸ್ತಿಮಿರ',
'ಅಂತಸ್ಥಶಿಲೆ',
'ಅಂತಸ್ಸರಣ',
'ಅಂತಸ್ಸ್ರಾವಕ',
'ಅಂತಾರಾಷ್ಟ್ರೀಯ',
'ಅಂತಿಮಯಾತ್ರೆ',
'ಅಂತಿರೆವೊಲ್',
'ಅಂತಿರೆವೋಲ್',
'ಅಂತೆವಾಸಿತ್ವ',
'ಅಂತ್ಯಗೊಳಿಸು',
'ಅಂತ್ಯಗೌರವ',
'ಅಂತ್ಯದರ್ಶನ',
'ಅಂತ್ಯಯಮಕ',
'ಅಂತ್ಯಸಂಸ್ಕಾರ',
'ಅಂತ್ಯಾದರ್ಶನ',
'ಅಂತ್ರಪಾಚಕ',
'ಅಂದಗಾರಿಕೆ',
'ಅಂದಗಾಱಿಕೆ',
'ಅಂದಗೆಡಿಸು',
'ಅಂದಗೊಳಿಸು',
'ಅಂದಣಗಿತ್ತಿ',
'ಅಂದಪಡಿಸು',
'ಅಂದಾಜುಕಟ್ಟು',
'ಅಂದಾಜುಗಾರ',
'ಅಂದಾಜುದಾರ',
'ಅಂದಾಜುಮಾಡು',
'ಅಂದಾಜುಪಟ್ಟಿ',
'ಅಂದಾಜುಪತ್ರ',
'ಅಂದಾಜುಬೆಲೆ',
'ಅಂದಾಜುಹಾಕು',
'ಅಂದಾದರ್ಬಾರು',
'ಅಂದಿಪುನಾರು',
'ಅಂಧಂತಮಸ್ಸು',
'ಅಂಧಕಾಸುರ',
'ಅಂಧತಮಸ',
'ಅಂಧತಮಿಸ್ರ',
'ಅಂಧಪಾಷಾಣ',
'ಅಂಧವಿಶ್ವಾಸ',
'ಅಂಧಾದರ್ಬಾರು',
'ಅಂಧಾಭಿಮಾನ',
'ಅಂಧಾಭಿಮಾನಿ',
'ಅಂಬಕದ್ಯುತಿ',
'ಅಂಬಕವಾರಿ',
'ಅಂಬಟೆಹುಲ್ಲು',
'ಅಂಬಟ್ಟಕಮ್ಮ',
'ಅಂಬಡಿಗಚ್ಚು',
'ಅಂಬರಚಕ್ರ',
'ಅಂಬರಚರ',
'ಅಂಬರಚುಂಬಿ',
'ಅಂಬರತಲ',
'ಅಂಬರಮಣಿ',
'ಅಂಬರವೀಧಿ',
'ಅಂಬರಾಂಬರ',
'ಅಂಬರಾಪಗೆ',
'ಅಂಬರೇಚರ',
'ಅಂಬಲಹೊಡೆ',
'ಅಂಬಲಿಮಣ್',
'ಅಂಬಲಿಮೀನು',
'ಅಂಬಲಿಹಬ್ಬ',
'ಅಂಬಾಡಿಯೆಲೆ',
'ಅಂಬಾಭವಾನಿ',
'ಅಂಬಾರಖಾನೆ',
'ಅಂಬಾರಮಣ',
'ಅಂಬಾರಿಹೊಡೆ',
'ಅಂಬಿಕಾಯೋಗ',
'ಅಂಬಿಗಗೆಯಿ',
'ಅಂಬಿರಿವಿಡು',
'ಅಂಬುಕಂಟಕ',
'ಅಂಬುಕಿರಾತ',
'ಅಂಬುಕುಕ್ಕುಟ',
'ಅಂಬುಗಾಣಿಸು',
'ಅಂಬುಜನಾಭ',
'ಅಂಬುಜನೇತ್ರ',
'ಅಂಬುಜನೇತ್ರೆ',
'ಅಂಬುಜಪಾಣಿ',
'ಅಂಬುಜಬಂಧು',
'ಅಂಬುಜಭವ',
'ಅಂಬುಜಮಿತ್ರ',
'ಅಂಬುಜಮುಖಿ',
'ಅಂಬುಜಸಖ',
'ಅಂಬುಜಾಂಬಕ',
'ಅಂಬುಜಾಕರ',
'ಅಂಬುಜಾನನೆ',
'ಅಂಬುಜಾಸನ',
'ಅಂಬುಜಾಸನೆ',
'ಅಂಬುಜೋದರ',
'ಅಂಬುಜೋದ್ಭವ',
'ಅಂಬುದಧ್ವನಿ',
'ಅಂಬುದಪಥ',
'ಅಂಬುದಫಲ',
'ಅಂಬುದಮಾರ್ಗ',
'ಅಂಬುದರಥ',
'ಅಂಬುದಾಗಮ',
'ಅಂಬುನಿಧಾನ',
'ಅಂಬುಪ್ರಸಾದ',
'ಅಂಬುರುಟ್ಪೀಠ',
'ಅಂಬುಲಾಕುಳ',
'ಅಂಬುವಾಹಿನಿ',
'ಅಂಬುವೇತಸ',
'ಅಂಬುಸಂಭವ',
'ಅಂಬುಸರಣ',
'ಅಂಬುಸರಣಿ',
'ಅಂಬೆಗಲಿಸು',
'ಅಂಬೆಗಾಲಿಕ್ಕು',
'ಅಂಬೆಗಾಲಿಡು',
'ಅಂಬೆಹಳದಿ',
'ಅಂಭಃಕುಂಭೀನ',
'ಅಂಭೋಜಜಾತ',
'ಅಂಭೋಜನೇತ್ರೆ',
'ಅಂಭೋಜಮಿತ್ರ',
'ಅಂಭೋಜಷಂಡ',
'ಅಂಭೋದಕಾಲ',
'ಅಂಭೋದಪಥ',
'ಅಂಭೋದಮಾರ್ಗ',
'ಅಂಭೋದಸ್ವನ',
'ಅಂಭೋಧಿಪಥ',
'ಅಂಶಕಫಲ',
'ಅಂಶಕುಂಡಲಿ',
'ಅಂಶಪುರುಷ',
'ಅಂಶಮಾಪಕ',
'ಅಂಶಾವತಾರ',
'ಅಂಶಿಸಮಾಸ',
'ಅಂಶೀಕರಣ',
'ಅಂಶೀಕರಿಸು',
'ಅಂಶುಮತ್ಫಲೆ',
'ಅಕಟಕಟ',
'ಅಕಟಕಟಾ',
'ಅಕಟಾಯಿಸು',
'ಅಕರಣೀಯ',
'ಅಕಲಂಕತೆ',
'ಅಕಲಾತ್ಮಕ',
'ಅಕಲುಗೇಡಿ',
'ಅಕಲುಹಾಕು',
'ಅಕಲ್ಪನೀಯ',
'ಅಕಶೇರುಕ',
'ಅಕಸಾಲಿಕೆ',
'ಅಕಸಾಲಿಗ',
'ಅಕಳಂಕತೆ',
'ಅಕಾಯಸ್ಥಲ',
'ಅಕಾರಾಂತತೆ',
'ಅಕಾರಾಂತತ್ವ',
'ಅಕಾಲಪ್ರೌಢ',
'ಅಕಾಲಪ್ರೌಢೆ',
'ಅಕಾಲಫಲ',
'ಅಕಾಲಬುದ್ಧಿ',
'ಅಕಾಲಮೃತ್ಯು',
'ಅಕಾಲವರ್ಷ',
'ಅಕಾಲವೃಷ್ಟಿ',
'ಅಕಾಲಿಕತೆ',
'ಅಕಿಂಚನತೆ',
'ಅಕಿಂಚನತ್ವ',
'ಅಕಿಂಚಿತ್ಕರ',
'ಅಕುಟಿಲತೆ',
'ಅಕುತೋಭಯ',
'ಅಕೃತಕರ್ತೃ',
'ಅಕೃಷ್ಟಪಚ್ಯ',
'ಅಕೃಷ್ಟಭೂಮಿ',
'ಅಕೃಷ್ಣಕರ್ಮ',
'ಅಕ್ಕಚ್ಚುನೀರು',
'ಅಕ್ಕಜಂಗೊಳ್',
'ಅಕ್ಕಜಂಬಡು',
'ಅಕ್ಕಜಂಬೊರೆ',
'ಅಕ್ಕಜಂ ಮಿಗು',
'ಅಕ್ಕಜಗೊಳ್',
'ಅಕ್ಕಜಬಡು',
'ಅಕ್ಕಜಮೆಯ್ದು',
'ಅಕ್ಕಜವಗೆ',
'ಅಕ್ಕಟಕ್ಕಟ',
'ಅಕ್ಕಟಕ್ಕಟಾ',
'ಅಕ್ಕಡಿಹಾಕು',
'ಅಕ್ಕಡಿಕಾಳು',
'ಅಕ್ಕಡಿಕೋಲು',
'ಅಕ್ಕಡಿಪೈರು',
'ಅಕ್ಕಡಿಸಡ್ಡೆ',
'ಅಕ್ಕನಚೌರಿ',
'ಅಕ್ಕರಂಬರೆ',
'ಅಕ್ಕರಂಬೊಯ್',
'ಅಕ್ಕರಗೊಟ್ಟಿ',
'ಅಕ್ಕರಜಾಣ',
'ಅಕ್ಕರಜಾಣೆ',
'ಅಕ್ಕರಮಾಲೆ',
'ಅಕ್ಕರವಿದ್ಯೆ',
'ಅಕ್ಕರಾಸ್ತಿಕೆ',
'ಅಕ್ಕರುಗಾರ್ತಿ',
'ಅಕ್ಕರುಗೊಳ್',
'ಅಕ್ಕಲಕರೆ',
'ಅಕ್ಕಲಕಾರ',
'ಅಕ್ಕಲಕೋಟೆ',
'ಅಕ್ಕವಣಿಗೆ',
'ಅಕ್ಕಸಗಾಱ',
'ಅಕ್ಕಸತನ',
'ಅಕ್ಕಸಬಡು',
'ಅಕ್ಕಸಮಾಡು',
'ಅಕ್ಕಸರಳಿ',
'ಅಕ್ಕಸಾಲಿಕೆ',
'ಅಕ್ಕಸಾಲಿಗ',
'ಅಕ್ಕಸಾಲಿತಿ',
'ಅಕ್ಕಸ್ತಿಬಟ್ಟು',
'ಅಕ್ಕಳಕರೆ',
'ಅಕ್ಕಳಗುಳಿ',
'ಅಕ್ಕಱುಗೊಳ್',
'ಅಕ್ಕಿಪತಂಗ',
'ಅಕ್ಕಿಸಜ್ಜಕ',
'ಅಕ್ಕಿಸಜ್ಜಿಗೆ',
'ಅಕ್ಕಿಹೆಡೆಯ',
'ಅಕ್ಕುವಣಿಗ',
'ಅಕ್ಕುಳಿಸಿಕೊ',
'ಅಕ್ಕೊತ್ತೆಗೊಳ್',
'ಅಕ್ಷತಂಗೊಳ್',
'ಅಕ್ಷತದಿಗೆ',
'ಅಕ್ಷತಧೈರ್ಯ',
'ಅಕ್ಷತಧೈರ್ಯೆ',
'ಅಕ್ಷತಯೋನಿ',
'ಅಕ್ಷದರ್ಶಕ',
'ಅಕ್ಷದೀಕ್ಷಿತ',
'ಅಕ್ಷಪಟಲ',
'ಅಕ್ಷಫಲಕ',
'ಅಕ್ಷಮಾಲಿಕೆ',
'ಅಕ್ಷಯಪದ',
'ಅಕ್ಷಯಪಾತ್ರೆ',
'ಅಕ್ಷಯಬೋಧ',
'ಅಕ್ಷಯವಟ',
'ಅಕ್ಷಯಸೌಖ್ಯ',
'ಅಕ್ಷಯಾಧಾನ',
'ಅಕ್ಷರಕಲ್',
'ಅಕ್ಷರಗಣ',
'ಅಕ್ಷರಚಣ',
'ಅಕ್ಷರಚುಂಚು',
'ಅಕ್ಷರಚ್ಯುತ',
'ಅಕ್ಷರಜೀವಿ',
'ಅಕ್ಷರಜ್ಞಾನ',
'ಅಕ್ಷರಮಾಲೆ',
'ಅಕ್ಷರರೂಪ',
'ಅಕ್ಷರರೇಖೆ',
'ಅಕ್ಷರಲೋಪ',
'ಅಕ್ಷರವಿದ್ಯೆ',
'ಅಕ್ಷರವೃತ್ತ',
'ಅಕ್ಷರವೃತ್ತಿ',
'ಅಕ್ಷರಶತ್ರು',
'ಅಕ್ಷರಸಂಜ್ಞೆ',
'ಅಕ್ಷರಾತ್ಮಕ',
'ಅಕ್ಷರಾಭ್ಯಾಸ',
'ಅಕ್ಷರುಚಕ',
'ಅಕ್ಷವಲಯ',
'ಅಕ್ಷವಳಯ',
'ಅಕ್ಷಹೃದಯ',
'ಅಕ್ಷಾಂಶರೇಖೆ',
'ಅಕ್ಷಾಂಶವೃತ್ತ',
'ಅಕ್ಷಿಕೂಟಕ',
'ಅಕ್ಷಿತಾರಕ',
'ಅಕ್ಷಿಪಟಲ',
'ಅಕ್ಷಿವಿಕ್ಷೇಪ',
'ಅಕ್ಷಿಶ್ರವಣ',
'ಅಕ್ಷಿಸ್ಪಂದನ',
'ಅಖಂಡದೀಪ',
'ಅಖಂಡಪತ್ರ',
'ಅಖಂಡಸುಖ',
'ಅಖಚಿತತೆ',
'ಅಖಿಳವೇದಿ',
'ಅಖಿಳಾದ್ವೈತ',
'ಅಖ್ಯಾತಿಕರ',
'ಅಖ್ಯಾತಿವಾದ',
'ಅಗಚಾಟಲು',
'ಅಗಚಾಟಲೆ',
'ಅಗಚಿಗದ್ದೆ',
'ಅಗಡತನ',
'ಅಗಡದತ್ತ',
'ಅಗಡುಮಾಡು',
'ಅಗಡುಗೊಳ್',
'ಅಗಡುತನ',
'ಅಗಡುದನ',
'ಅಗಡುಬೊಮ್ಮ',
'ಅಗಡುಮೋಱೆ',
'ಅಗಡುವಾತು',
'ಅಗಡುಹೇಳು',
'ಅಗತಿಕತೆ',
'ಅಗತೆಹೊಲ',
'ಅಗದಂಕಾರ',
'ಅಗದಕಾರ',
'ಅಗದತಂತ್ರ',
'ಅಗನಿವಾಸಿ',
'ಅಗರಂಗೆಯ್',
'ಅಗರುಗಂಧ',
'ಅಗರುಧೂಪ',
'ಅಗರುಬತ್ತಿ',
'ಅಗರು ಬೀಟೆ',
'ಅಗಲಂಬೆಱು',
'ಅಗಲಿಕ್ಕಿಸು',
'ಅಗಲುವಿಕೆ',
'ಅಗಲೆನೂಂಕು',
'ಅಗಲೆಪೋಳ್',
'ಅಗಲೆವೋಗು',
'ಅಗಲೌತಣ',
'ಅಗಲ್ಕೆಗಿಚ್ಚು',
'ಅಗಲ್ಕೆವೆಱು',
'ಅಗಲ್ದುರದ',
'ಅಗಸಗಲ್ಲು',
'ಅಗಸಗಿತ್ತಿ',
'ಅಗಸತನ',
'ಅಗಸವೆಟ್ಟು',
'ಅಗಸಹೊಯ್ಲು',
'ಅಗಸೆಗಿವಿ',
'ಅಗಸೆಗೆಯ್',
'ಅಗಸೆನಾರು',
'ಅಗಸೆಯೆಣ್ಣೆ',
'ಅಗಸೆವಟ್ಟೆ',
'ಅಗಹರಣ',
'ಅಗಹಾರಣ',
'ಅಗಳಸುಂಟಿ',
'ಅಗಳಾಡಿಸು',
'ಅಗಳಿಚಣ್ಣ',
'ಅಗಳುಗಡ್ಡೆ',
'ಅಗಳುಶುಂಠಿ',
'ಅಗಾಧಮನ',
'ಅಗಿಲುಗಂಧ',
'ಅಗಿಲುಚಕ್ಕೆ',
'ಅಗಿಲುಶುಂಠಿ',
'ಅಗಿಹುಣ್ಣಿಮೆ',
'ಅಗುಂತಿವಡೆ',
'ಅಗುತುಕೊ(ಳ್ಳು)',
'ಅಗುರುಧೂಪ',
'ಅಗುರುಶುಂಠಿ',
'ಅಗುರ್ಬುವಡೆ',
'ಅಗುರ್ವುಗಿಡು',
'ಅಗುರ್ವುಗೊಳ್',
'ಅಗುರ್ವುವಡೆ',
'ಅಗುರ್ವುವೆಱು',
'ಅಗುಳಿಗನ್ನ',
'ಅಗುಳುಶುಂಠಿ',
'ಅಗೇಲುಹಾಕು',
'ಅಗೋಚರತೆ',
'ಅಗ್ಗಲಗಣ್',
'ಅಗ್ಗಲಗಣ್ಣ',
'ಅಗ್ಗಳಗಣ್',
'ಅಗ್ಗಳಗಣ್ಣ',
'ಅಗ್ಗಳವಸ್ತು',
'ಅಗ್ಗಾಡಿಕಾರ',
'ಅಗ್ಗಿಮುಸುಡ',
'ಅಗ್ಗಿವೆಗಡು',
'ಅಗ್ನಿಕಂಟಕ',
'ಅಗ್ನಿಕುಕ್ಕುಟ',
'ಅಗ್ನಿಚಯನ',
'ಅಗ್ನಿದೀಪನ',
'ಅಗ್ನಿಧಾರಣೆ',
'ಅಗ್ನಿನಯನ',
'ಅಗ್ನಿಪರೀಕ್ಷೆ',
'ಅಗ್ನಿಪರ್ವತ',
'ಅಗ್ನಿಪುರಾಣ',
'ಅಗ್ನಿಪುರುಷ',
'ಅಗ್ನಿಪ್ರತಿಷ್ಠೆ',
'ಅಗ್ನಿಪ್ರವೇಶ',
'ಅಗ್ನಿಪ್ರಳಯ',
'ಅಗ್ನಿಭಕ್ಷಕ',
'ಅಗ್ನಿಮಂಡಲ',
'ಅಗ್ನಿಮಂಥನ',
'ಅಗ್ನಿಮಕರ',
'ಅಗ್ನಿಮಥನ',
'ಅಗ್ನಿಮಾಪಕ',
'ಅಗ್ನಿಮುಖಾಸ್ತ್ರ',
'ಅಗ್ನಿವರ್ಧಕ',
'ಅಗ್ನಿಶಾಮಕ',
'ಅಗ್ನಿಸಂಸ್ಕಾರ',
'ಅಗ್ನಿಸಾಕ್ಷಿಕ',
'ಅಗ್ನಿಸ್ತಂಭನ',
'ಅಗ್ನಿಸ್ತಂಭನಿ',
'ಅಗ್ನ್ಯಭಾಧಿತ',
'ಅಗ್ರಗಮನ',
'ಅಗ್ರಗಾಮಿತ್ವ',
'ಅಗ್ರಘರಟ್ಟ',
'ಅಗ್ರಚರಿತ',
'ಅಗ್ರತನೂಜ',
'ಅಗ್ರತಾಂಬೂಲ',
'ಅಗ್ರಪಟಲ',
'ಅಗ್ರಪದವಿ',
'ಅಗ್ರಪದಾತಿ',
'ಅಗ್ರಮಹಿಷಿ',
'ಅಗ್ರಲೇಖನ',
'ಅಗ್ರಹಾಯಣ',
'ಅಗ್ರಹಾರಿಕ',
'ಅಗ್ರಹಾರಿಕೆ',
'ಅಗ್ರಾರವಾಸಿ',
'ಅಗ್ರೇದಿಧಿಷು',
'ಅಘಟಮಾನ',
'ಅಘಮರ್ಷಣ',
'ಅಘಹರಣ',
'ಅಘಾತಿಕರ್ಮ',
'ಅಘೋರಪಂಥ',
'ಅಚಪಲತೆ',
'ಅಚಮತ್ಕಾರ',
'ಅಚಲತಾರೆ',
'ಅಚಲಪದ',
'ಅಚಲಪ್ರದ',
'ಅಚಲರಿಪು',
'ಅಚಲಸುತೆ',
'ಅಚಿಂತನೀಯ',
'ಅಚಿರದ್ಯುತಿ',
'ಅಚಿರಪ್ರಭೆ',
'ಅಚೇತನತೆ',
'ಅಚ್ಚಂಗರುಕೆ',
'ಅಚ್ಚಕನ್ನಡ',
'ಅಚ್ಚಗಗೊಳ್',
'ಅಚ್ಚಗಡಲೆ',
'ಅಚ್ಚಗದಿರು',
'ಅಚ್ಚಗನ್ನಡ',
'ಅಚ್ಚಗನ್ನಿಕೆ',
'ಅಚ್ಚಗಱುಂಕೆ',
'ಅಚ್ಚಜೌವನೆ',
'ಅಚ್ಚದಾವರೆ',
'ಅಚ್ಚದಿಂಗಳ್',
'ಅಚ್ಚದುಗುಲ',
'ಅಚ್ಚದೊಗರಿ',
'ಅಚ್ಚದೊವರಿ',
'ಅಚ್ಚಪ್ರಸಾದ',
'ಅಚ್ಚಪ್ರಸಾದಿ',
'ಅಚ್ಚಯಗೋಲ್',
'ಅಚ್ಚರವೆಣ್',
'ಅಚ್ಚರವೆಣ್ಣು',
'ಅಚ್ಚರಿಗವಿ',
'ಅಚ್ಚರಿಗಾಣ್',
'ಅಚ್ಚರಿಗೊಳ್ಳು',
'ಅಚ್ಚರಿಮೂಡು',
'ಅಚ್ಚರಿಮೊಳೆ',
'ಅಚ್ಚರಿಯೊಂದು',
'ಅಚ್ಚರಿವಡು',
'ಅಚ್ಚರಿವಡೆ',
'ಅಚ್ಚರಿವೀಱು',
'ಅಚ್ಚರಿವೆಣ್',
'ಅಚ್ಚರಿವೆಱು',
'ಅಚ್ಚರಿಹದ',
'ಅಚ್ಚಲಿಂಗೈಕ್ಯ',
'ಅಚ್ಚವಳಿಕು',
'ಅಚ್ಚಶಿವೈಕ್ಯ',
'ಅಚ್ಚಿಗಂಗೊಳ್',
'ಅಚ್ಚಿಗಂಬಡು',
'ಅಚ್ಚಿಗೊಳಿಸು',
'ಅಚ್ಚಿಮುಗುಳ್ಚು',
'ಅಚ್ಚುಗಂಬಡೆ',
'ಅಚ್ಚುಗಗೊಳ್',
'ಅಚ್ಚುಗಬಡು',
'ಅಚ್ಚುಗವಡು',
'ಅಚ್ಚುಪನ್ನಾಯ',
'ಅಚ್ಚುಮಾಡಿಸು',
'ಅಚ್ಚುರಾಟಣ',
'ಅಚ್ಚುವಣಿಗ',
'ಅಚ್ಚುವಳಿಗ',
'ಅಚ್ಚುಹಲಗೆ',
'ಅಚ್ಚುಹಾಕಿಸು',
'ಅಚ್ಚುಳಕೋಲ್',
'ಅಚ್ಚುಳಾಯಿತ',
'ಅಚ್ಚುಳಾಯಿಲ',
'ಅಚ್ಛಮಲ್ಲಿಕೆ',
'ಅಚ್ಛಸ್ಫಟಿಕ',
'ಅಚ್ಛಿನ್ನಭಕ್ತ',
'ಅಚ್ಛಿನ್ನಭಕ್ತಿ',
'ಅಚ್ಯುತಕಲ್ಪ',
'ಅಚ್ಯುತರಾಯಿ',
'ಅಚ್ಯುತಸೌಖ್ಯ',
'ಅಜಗಂಧಿಕೆ',
'ಅಜಡದ್ರವ್ಯ',
'ಅಜಪಾಮಂತ್ರ',
'ಅಜಪಾಯೋಗ',
'ಅಜಮಾಯಿಶಿ',
'ಅಜಮಾಯಿಷಿ',
'ಅಜರನುಲಿ',
'ಅಜರಾಮರ',
'ಅಜಲ್ ಮುಡಿ',
'ಅಜಾಗರೂಕ',
'ಅಜಾಜೀವನ',
'ಅಜಾತಪೂರ್ವ',
'ಅಜಾತರಿಪು',
'ಅಜಾತಶತ್ರು',
'ಅಜಾರಿಬೀಳು',
'ಅಜಿನಧಾರಿ',
'ಅಜಿನಪತ್ರೆ',
'ಅಜಿನಯೋನಿ',
'ಅಜಿನಾಂಬರ',
'ಅಜೀವಜನ್ಯ',
'ಅಜೀವತತ್ವ',
'ಅಜೀವಯುಗ',
'ಅಜೀವರತ್ನ',
'ಅಜುರನೀಲಿ',
'ಅಜ್ಜಗಾವಲು',
'ಅಜ್ಜನಪಟ್ಟೆ',
'ಅಜ್ಜಿಪುರಾಣ',
'ಅಜ್ಞಾತವಾಸ',
'ಅಜ್ಞಾನತ್ರಯ',
'ಅಜ್ಞೇಯವಾದ',
'ಅಜ್ಞೇಯವಾದಿ',
'ಅಟಕಟಿಸು',
'ಅಟಕಾಯಿಸು',
'ಅಟಕೊಬ್ಬರಿ',
'ಅಟಖೊಬ್ಬರಿ',
'ಅಟಮಟಂಗೈ',
'ಅಟಮಟಿಗ',
'ಅಟಮಟಿಸು',
'ಅಟಮಾರಿಸು',
'ಅಟರೂಷಕ',
'ಅಟವಟಿಸು',
'ಅಟವಾಳಿಗೆ',
'ಅಟವಿಚರ',
'ಅಟವಿಚರೆ',
'ಅಟವೀಚರ',
'ಅಟವೀಚರೆ',
'ಅಟವೀತಟ',
'ಅಟವೀಪಾಳಿ',
'ಅಟ್ಟಕುಂಬಳ',
'ಅಟ್ಟಗವಲ್',
'ಅಟ್ಟಟ್ಟಿಯಟ್ಟು',
'ಅಟ್ಟಮಡಿಸು',
'ಅಟ್ಟಹಾಸಕ',
'ಅಟ್ಟಹೆರಳೆ',
'ಅಟ್ಟಳವಾಱು',
'ಅಗ್ಗಿಗಾಲಿಗ',
'ಅಟ್ಟುಗೂಳಾಟ',
'ಅಟ್ಟುಗೂಳಾಡು',
'ಅಟ್ಟುಮಣಿಹ',
'ಅಟ್ಟುಳಿಗಾರ',
'ಅಟ್ಟುಳಿದೋಱು',
'ಅಟ್ಟುಳಿಯಾಳು',
'ಅಟ್ಟೆಗಾಲಿಗ',
'ಅಟ್ಟೆಯಾಡಿಸು',
'ಅಟ್ಟೆವತ್ತುಗೆ',
'ಅಟ್ಠಾಣಾಂತರ',
'ಅಠರಾಖಾನೆ',
'ಅಠಾರಖಾನೆ',
'ಅಡಂಗಕಾಱ',
'ಅಡಂಗಾಯತ',
'ಅಡಂಗಾಯಿತ',
'ಅಡಂಗಿಕೊಳ್',
'ಅಡಕಂಗೆಯ್',
'ಅಡಕಗೆಯ್',
'ಅಡಕತ್ತರಿ',
'ಅಡಕನಗೆ',
'ಅಡಕಪುಟ್ಟ',
'ಅಡಕಂಮಾಡು',
'ಅಡಕಲಿಡು',
'ಅಡಕವಂತ',
'ಅಡಕಿಲಾಗು',
'ಅಡಕಿಲಿಡು',
'ಅಡಕಿಲ್ಕೂಟ',
'ಅಡಕಿಲ್ಗಿರಿ',
'ಅಡಕಿಲ್ಗೊಡೆ',
'ಅಡಕಿಲ್ಗೊಳ್',
'ಅಡಕಿಲ್ದೆರೆ',
'ಅಡಕಿಲ್ವಾಸು',
'ಅಡಕಿಲ್ವುಣ್',
'ಅಡಕಿಲ್ವೆಳ್ಪು',
'ಅಡಕಿಲ್ವೊಂದು',
'ಅಡಕುಪಾತ್ರೆ',
'ಅಡಕೆಗತ್ತಿ',
'ಅಡಕೆಚೆಟ್ಟು',
'ಅಡಕೆಜಪ್ಲೆ',
'ಅಡಕೆದೋಂಟ',
'ಅಡಕೆದೋಟ',
'ಅಡಕೆಪುಟ್ಟ',
'ಅಡಕೆಬಾಳೆ',
'ಅಡಕೆಬೇರು',
'ಅಡಕೆಹಂಗು',
'ಅಡಕ್ಕೆದೋಂಟ',
'ಅಡಗಟ್ಟಿಸು',
'ಅಡಗಾಣಿಸು',
'ಅಡಗಿಸುಹ',
'ಅಡಗುಗಳ್ಳ',
'ಅಡಗುಣಿಸು',
'ಅಡಗುತಿನ್',
'ಅಡಗುದಱಿ',
'ಅಡಗುದಾಣ',
'ಅಡಗುದಿನ್',
'ಅಡಗುದಿನಿ',
'ಅಡಗುದುಳಿ',
'ಅಡಗುಮೆಟ್ಟು',
'ಅಡಗುಸೇಕ',
'ಅಡಗೆಡಹು',
'ಅಡಗೌತಣ',
'ಅಡಜಂಬರ',
'ಅಡದಲಿಕಿ',
'ಅಡನಿಕೊಡ್ಡು',
'ಅಡಪದವ',
'ಅಡಪಂಬಿಡಿ',
'ಅಡಪಗಿತ್ತಿ',
'ಅಡಪವಳ',
'ಅಡಪವಳ್ತಿ',
'ಅಡಪವಳ್ಳ',
'ಅಡಬಡಿಸು',
'ಅಡಬರಿಸು',
'ಅಡಬಹಳ',
'ಅಡಬಳಿಗ',
'ಅಡಮುಸುಕು',
'ಅಡರಿಗೊಳ್',
'ಅಡರುಗಚ್ಚೆ',
'ಅಡರುಗಟ್ಟು',
'ಅಡರುಗಾಲು',
'ಅಡರುಬಳ್ಳಿ',
'ಅಡರುಮಾಡು',
'ಅಡರುಹತ್ತು',
'ಅಡಲುಹುಳು',
'ಅಡವಡಿಕೆ',
'ಅಡವಡಿಸು',
'ಅಡವರಿಸು',
'ಅಡವಳಿಸು',
'ಅಡವಿ ಅತ್ತಿ',
'ಅಡವಿಕಾಗೆ',
'ಅಡವಿಕಿಚ್ಚು',
'ಅಡವಿಕೋಣ',
'ಅಡವಿ ಕೋಳಿ',
'ಅಡವಿಗಿಚ್ಚು',
'ಅಡವಿಗಿರ್ಚು',
'ಅಡವಿಗುಱಿ',
'ಅಡವಿಚಳ್ಳೆ',
'ಅಡವಿದಾಣ',
'ಅಡವಿನಿಂಬೆ',
'ಅಡವಿನೆಲ್ಲಿ',
'ಅಡವಿಪಾಲು',
'ಅಡವಿಬಿಕ್ಕೆ',
'ಅಡವಿಬೀಳು',
'ಅಡವಿಬೆಂಡೆ',
'ಅಡವಿಬೆಕ್ಕು',
'ಅಡವಿಮೃಗ',
'ಅಡವಿಮೊಲ್ಲೆ',
'ಅಡವಿಯಾಡು',
'ಅಡವಿಯೊಗು',
'ಅಡವಿರಾಗಿ',
'ಅಡವಿರಿಸು',
'ಅಡವಿವೆಣ್ಣು',
'ಅಡವಿಸೀಗೆ',
'ಅಡವಿಹತ್ತಿ',
'ಅಡವಿಹುಳಿ',
'ಅಡವಿಹೆಣ',
'ಅಡವಿಸಿಂಗ',
'ಅಡವುಚೀಟಿ',
'ಅಡವುದಾರ',
'ಅಡವುಹಾಕು',
'ಅಡಸರಣೆ',
'ಅಡಹಡಿಸು',
'ಅಡಹಾಯಿಸು',
'ಅಡಹುಗಾರ',
'ಅಡಕಿಲಿಕ್ಕು',
'ಅಡಿಕಿಲ್ವೆಱು',
'ಅಡಿಕಿಲ್ಗೊಳ್',
'ಅಡಿಕಿಲ್ವಾಸು',
'ಅಡಿಕೆಕಸ',
'ಅಡಿಕೆಬಾಳೆ',
'ಅಡಿಗಂಟಲ್',
'ಅಡಿಗಡಿಗೆ',
'ಅಡಿಗರಟ',
'ಅಡಿಗಲ್ಲಿಕ್ಕು',
'ಅಡಿಗಾಲುವೆ',
'ಅಡಿಗಿಡಿಸು',
'ಅಡಿಗೂದಲು',
'ಅಡಿಗೆ ಉಪ್ಪು',
'ಅಡಿಗೆಕಲ್ಲು',
'ಅಡಿಗೆಗಾರ',
'ಅಡಿಗೆಗಾರ್ತಿ',
'ಅಡಿಗೆಜನ',
'ಅಡಿಗೆಡಪು',
'ಅಡಿಗೆನೀರು',
'ಅಡಿಗೆಭಟ್ಟ',
'ಅಡಿಗೆಮನೆ',
'ಅಡಿಗೆಮಾಡು',
'ಅಡಿಗೆಯುಪ್ಪು',
'ಅಡಿಗೆವಜ್ರ',
'ಅಡಿಗೆವಳ',
'ಅಡಿಗೆಶಾಲೆ',
'ಅಡಿಗೆಸಾಲೆ',
'ಅಡಿಗೆಸೋಡ',
'ಅಡಿಗೊರಡು',
'ಅಡಿಗೊಳಂಗು',
'ಅಡಿಟಿಪ್ಪಣಿ',
'ಅಡಿದಳಿರ್',
'ಅಡಿದಳಿರು',
'ಅಡಿದಾವರೆ',
'ಅಡಿನೆರಳು',
'ಅಡಿನೆಳಲು',
'ಅಡಿಪತ್ರಿಕೆ',
'ಅಡಿಬರಹ',
'ಅಡಿಬೆಸನ',
'ಅಡಿಮಂಚಿಕೆ',
'ಅಡಿಮಗುಚು',
'ಅಡಿಮದ್ದಿಡು',
'ಅಡಿಮಲರ್',
'ಅಡಿಮಳಲು',
'ಅಡಿಮಿಡುಕು',
'ಅಡಿಮುರುಂಟು',
'ಅಡಿಮೇಲಾಗು',
'ಅಡಿಮೊದಲ್',
'ಅಡಿಯಾಡಿಸು',
'ಅಡಿಯೂಳಿಗ',
'ಅಡಿವಡಿಕೆ',
'ಅಡಿವಣಲ್',
'ಅಡಿವಣಿಗೆ',
'ಅಡಿವತ್ತಳಿ',
'ಅಡಿವೊಣರ್',
'ಅಡುಂಕುವೆಣ',
'ಅಡುಕುಂಗಲ್',
'ಅಡುಕುಂಗಾಲ್',
'ಅಡುಗಟ್ಟಿಗೆ',
'ಅಡುಗರಿಸು',
'ಅಡುಗುಲಜ್ಜಿ',
'ಅಡುಗುಳಿಗ',
'ಅಡುಗೂಲಜ್ಜಿ',
'ಅಡುಗೂಳಜ್ಜಿ',
'ಅಡುಗೆ ಉಪ್ಪು',
'ಅಡುಗೆ ಭಟ್ಟ',
'ಅಡುಗೆ ಮನೆ',
'ಅಡುಗೆ ಸೋಡ',
'ಅಡುಗೋಲಜ್ಜಿ',
'ಅಡುಪಾಯ್ಬೇಗೆ',
'ಅಡುಬಾಣಲೆ',
'ಅಡುವುೞಿಗ',
'ಅಡೆಗೆಡಹು',
'ಅಡೆಮಂಚಿಕೆ',
'ಅಡೆಯೊಡ್ಡಿಸು',
'ಅಡ್ಡಂಗೆಡಪು',
'ಅಡ್ಡಂ ತಿಗಟಂ',
'ಅಡ್ಡಂಬುಡುಕ',
'ಅಡ್ಡಂಬುಡುಕೆ',
'ಅಡ್ಡಕಟ್ಟಿಗೆ',
'ಅಡ್ಡಕಪ್ಪಟ',
'ಅಡ್ಡಕಸಬಿ',
'ಅಡ್ಡಕಸಬು',
'ಅಡ್ಡಕಸುಬು',
'ಅಡ್ಡಕಾಂತತೆ',
'ಅಡ್ಡಕಾಂತೀಯ',
'ಅಡ್ಡಕಾಲುವೆ',
'ಅಡ್ಡಕಾವಡಿ',
'ಅಡ್ಡಕಾವಲಿ',
'ಅಡ್ಡಕೆಲಸ',
'ಅಡ್ಡಗವಿತೆ',
'ಅಡ್ಡಗಾಲಿಕ್ಕು',
'ಅಡ್ಡಗೆಡಹು',
'ಅಡ್ಡಗೋಲಿಕ್ಕು',
'ಅಡ್ಡಣದೊಲೆ',
'ಅಡ್ಡಣಾಯತ',
'ಅಡ್ಡಣಾಯಿತ',
'ಅಡ್ಡಣಿಗೆಯ್',
'ಅಡ್ಡಣೆಕಂಡಿ',
'ಅಡ್ಡತಿನಿಸು',
'ಅಡ್ಡತಿರುಗು',
'ಅಡ್ಡತಿರುವು',
'ಅಡ್ಡತೊಡರು',
'ಅಡ್ಡತೊಲಗು',
'ಅಡ್ಡದಂಡಿಗೆ',
'ಅಡ್ಡತುರುಬು',
'ಅಡ್ಡನಾಲಗೆ',
'ಅಡ್ಡನಿಲುವು',
'ಅಡ್ಡಪಟ್ಟಿಗೆ',
'ಅಡ್ಡಪಲ್ಲಕ್ಕಿ',
'ಅಡ್ಡಪಾಲಕಿ',
'ಅಡ್ಡಪಿಡ್ಡಗೆ',
'ಅಡ್ಡಪೆಟ್ಟಿಗೆ',
'ಅಡ್ಡಬಲಿಪ',
'ಅಡ್ಡಬಲಿಪ್ಪ',
'ಅಡ್ಡಬೀಳಿಕು',
'ಅಡ್ಡಬೆಲಗು',
'ಅಡ್ಡಮುಸುಕು',
'ಅಡ್ಡಮುಸುಡು',
'ಅಡ್ಡಯಿಸುಹ',
'ಅಡ್ಡಯೋಜನೆ',
'ಅಡ್ಡಲಾಕಿಡು',
'ಅಡ್ಡಲಾಕೊಡೆ',
'ಅಡ್ಡಲುವೊಡ್ಡು',
'ಅಡ್ಡವಂತಿಕೆ',
'ಅಡ್ಡವರಿಗೆ',
'ಅಡ್ಡವಲಗೆ',
'ಅಡ್ಡವಾಸನೆ',
'ಅಡ್ಡವೊರಗು',
'ಅಡ್ಡಸವಾಲು',
'ಅಡ್ಡಸೆರಗು',
'ಅಡ್ಡಸೇರಿಸು',
'ಅಡ್ಡಸೇರುವೆ',
'ಅಡ್ಡಹರವು',
'ಅಡ್ಡಹಿಡಿತ',
'ಅಡ್ಡಹೆಸರು',
'ಅಡ್ಡಹೊಡೆತ',
'ಅಡ್ಡಾಬುಡುಕ',
'ಅಡ್ಡಿಪಡಿಸು',
'ಅಡ್ಡೆಬಡಕ',
'ಅಡ್ಡೇಟುಗಾರ',
'ಅಡ್ನಾಡಿವೇಳೆ',
'ಅಡ್ನಾಡಿಹೊತ್ತು',
'ಅಢಾವೆ ಪತ್ರ',
'ಅಣಕಚಿತ್ರ',
'ಅಣಕದೋಱು',
'ಅಣಕನುಡಿ',
'ಅಣಕವಾಡು',
'ಅಣಕವಾತು',
'ಅಣಚಿತನ',
'ಅಣಬೆರೋಗ',
'ಅಣಮಶೂಲೆ',
'ಅಣಸುಗಲ್ಲ',
'ಅಣಿಗೆಲಸ',
'ಅಣಿಗೊಳಿಸು',
'ಅಣಿಮಣಿಕೆ',
'ಅಣುಗದಮ್ಮ',
'ಅಣುಗನಿಭ',
'ಅಣುಗಮಗ',
'ಅಣುಚಟನ',
'ಅಣುಸಿದ್ಧಾಂತ',
'ಅಣೆಕಲ್ಲಾಟ',
'ಅಣೆಕಲ್ಲಾಡು',
'ಅಣೋರಣೀಯ',
'ಅಣ್ಣೆಕಲ್ಲಾಟ',
'ಅಣ್ಣೆಕಲ್ಲಾಡು',
'ಅಣ್ಣೆಕಲ್ಲೊಡ್ಡು',
'ಅಣ್ಣೆವಾಲೆರೆ',
'ಅತನುವೈರಿ',
'ಅತನುಹರ',
'ಅತನೂದರ',
'ಅತಲಜಲ',
'ಅತಲಸ್ಪರ್ಶ',
'ಅತಳಸ್ಪರ್ಶ',
'ಅತಿಕರಿಸು',
'ಅತಿಕ್ರಮಣ',
'ಅತಿಕ್ರಮಿಸು',
'ಅತಿಗಮನ',
'ಅತಿಗಮಿಸು',
'ಅತಿಗಳೆಯ',
'ಅತಿಚದುರ',
'ಅತಿಚದುರೆ',
'ಅತಿಜಗತಿ',
'ಅತಿಜಾಗರ',
'ಅತಿತಪ್ತತೆ',
'ಅತಿತರಲ',
'ಅತಿಥಿಗೃಹ',
'ಅತಿಥಿಧರ್ಮ',
'ಅತಿಥಿಪೂಜೆ',
'ಅತಿದವಡ',
'ಅತಿದುಷ್ಷಮ',
'ಅತಿಧವಳ',
'ಅತಿನೇರಿಳೆ',
'ಅತಿಪಾರ್ಥಿಸು',
'ಅತಿಪೀಡನ',
'ಅತಿಪೂರಣ',
'ಅತಿಪ್ಲುತತೆ',
'ಅತಿಬಯಸು',
'ಅತಿಮಂಜುಲ',
'ಅತಿಮಂಥನ',
'ಅತಿಮಧುರ',
'ಅತಿಮರ್ಯಾದ',
'ಅತಿಮಾನವ',
'ಅತಿಮಾನಸ',
'ಅತಿಮಾನುಷ',
'ಅತಿಮುಕ್ತಕ',
'ಅತಿರಂಜಿತ',
'ಅತಿರಂಜಿಸು',
'ಅತಿರಮ್ಯತೆ',
'ಅತಿರಾಗತೆ',
'ಅತಿರಾಜಿಸು',
'ಅತಿರೂಢಾರ್ಥ',
'ಅತಿವರ್ತಿತ',
'ಅತಿವಾಹಕ',
'ಅತಿಶಕ್ವರಿ',
'ಅತಿಶಯತೆ',
'ಅತಿಶಯಿತ',
'ಅತಿಶಯಿಸು',
'ಅತಿಶಯೋಕ್ತಿ',
'ಅತಿಸಂಧಾನ',
'ಅತಿಸಂಧಿತೆ',
'ಅತಿಸರ್ಜನ',
'ಅತಿಸಾರಕಿ',
'ಅತೀಂದ್ರಿಯತೆ',
'ಅತೀತಕಾಲ',
'ಅತೀತಕೇಂದ್ರ',
'ಅತೀತಕ್ರಿಯೆ',
'ಅತೀತಗ್ರಹ',
'ಅತೀತಜನ್ಮ',
'ಅತೀತಭವ',
'ಅತೀತಮಠ',
'ಅತೀತಸಂಖ್ಯೆ',
'ಅತೀತಾವಸ್ಥೆ',
'ಅತುಚ್ಛಬಾಣ',
'ಅತುಳಭಾಷೆ',
'ಅತೃಪ್ತಿಕರ',
'ಅತ್ತರದಾನಿ',
'ಅತ್ತರದಾನು',
'ಅತ್ತರುತಟ್ಟಿ',
'ಅತ್ತರುದಾನಿ',
'ಅತ್ತಲಬಾಯಿ',
'ಅತ್ತಲಿಂ ಮುನ್ನ',
'ಅತ್ತಿಗೆತನ',
'ಅತ್ತೆವಕ್ಕಲು',
'ಅತ್ತೆವೊಕ್ಕಲು',
'ಅತ್ಯಂತಗಾಮಿ',
'ಅತ್ಯಂತಾಭಾವ',
'ಅತ್ಯಗತ್ಯತೆ',
'ಅತ್ಯವೇಕ್ಷಣ',
'ಅತ್ಯಾದರಿಸು',
'ಅತ್ಯಾಧುನಿಕ',
'ಅತ್ಯಾವಶ್ಯಕ',
'ಅತ್ಯುತ್ಕಟತೆ',
'ಅತ್ಯುತ್ಕೃಷ್ಟತೆ',
'ಅತ್ಯುತ್ತಮತೆ',
'ಅತ್ಯುತ್ಸುಕತೆ',
'ಅತ್ಯುದ್ಗಾರಿಸು',
'ಅತ್ಯುಪಚಾರ',
'ಅತ್ರಪಾತ್ಮಿಕೆ',
'ಅತ್ರಿಗೋತ್ರಜ',
'ಅದಟಂಗಳ್ಳ',
'ಅದಟತನ',
'ಅದಟರಸ',
'ಅದಬ್ ಸಲಾಂ',
'ಅದರಗಂಚಿ',
'ಅದವೞಲ್',
'ಅದಿರುಗಂತಿ',
'ಅದಿರುಘಾಯ',
'ಅದಿರುದುಟಿ',
'ಅದಿರೆಗಟ್ಟು',
'ಅದಿರೆದನ',
'ಅದಿರ್ಪುಗೆಯ್',
'ಅದಿಶಕ್ಷೇತ್ರ',
'ಅದುಕಾರಣ',
'ಅದುರುಗಂಚಿ',
'ಅದುರುಪೆಟ್ಟು',
'ಅದುರುರೋಗ',
'ಅದುರುವಾಯು',
'ಅದುರುಗಂತಿ',
'ಅದೂರದರ್ಶಿ',
'ಅದೃಶ್ಯವಾಗು',
'ಅದೃಶ್ಯಂದಳೆ',
'ಅದೃಶ್ಯಮಸಿ',
'ಅದೃಶ್ಯರೂಪ',
'ಅದೃಷ್ಟಪೂರ್ವ',
'ಅದೃಷ್ಟಫಲ',
'ಅದೃಷ್ಟರೇಖೆ',
'ಅದೃಷ್ಟವಂತ',
'ಅದೃಷ್ಟವಂತೆ',
'ಅದೃಷ್ಟವಶ',
'ಅದೃಷ್ಟವಾದ',
'ಅದೃಷ್ಟವಾದಿ',
'ಅದೃಷ್ಟಶಕ್ತಿ',
'ಅದೃಷ್ಟಶಾಲಿ',
'ಅದೃಷ್ಟಸಂಧಿ',
'ಅದೃಷ್ಟಹೀನ',
'ಅದೃಷ್ಟಹೀನೆ',
'ಅದ್ದಳವೇಳು',
'ಅದ್ದೇಕೆಲಸ',
'ಅದ್ದೇಚಾಕರಿ',
'ಅದ್ಭುತಚಿತ್ತ',
'ಅದ್ಭುತರಸ',
'ಅದ್ಭುತಾಕೃತಿ',
'ಅದ್ಭುತೋಪಮೆ',
'ಅದ್ರಿತನಯೆ',
'ಅದ್ರಿದಳನ',
'ಅದ್ರಿಭೇದನ',
'ಅದ್ವಯವಾದ',
'ಅದ್ವಯವಾದಿ',
'ಅದ್ವೈತಭಕ್ತಿ',
'ಅದ್ವೈತಮತಿ',
'ಅದ್ವೈತವಾದ',
'ಅಧಃಕರಣ',
'ಅಧಃಕರಿಸು',
'ಅಧಃಪತನ',
'ಅಧಃಪಾತಾಳ',
'ಅಧಃಪಾತಿಸು',
'ಅಧಃಪ್ರಭಾವ',
'ಅಧಃಸ್ಪರ್ಶಕ',
'ಅಧಃಸ್ರಂಸನ',
'ಅಧಟುದೋಱು',
'ಅಧಮಕಾವ್ಯ',
'ಅಧಮಕುಲ',
'ಅಧಮತನ',
'ಅಧಮೋಪಮೆ',
'ಅಧರಂ ಮಾಡು',
'ಅಧರಪಾನ',
'ಅಧರರಾಗ',
'ಅಧರಾಮೃತ',
'ಅಧರೀಕೃತ',
'ಅಧರ್ಮದ್ರವ್ಯ',
'ಅಧರ್ಮನಿಷ್ಠ',
'ಅಧಿಕಕೋಣ',
'ಅಧಿಕತಮ',
'ಅಧಿಕತರ',
'ಅಧಿಕದಿನ',
'ಅಧಿಕಪದ',
'ಅಧಿಕಪಾಠ',
'ಅಧಿಕಮಾಸ',
'ಅಧಿಕರಣ',
'ಅಧಿಕರಿಸು',
'ಅಧಿಕರ್ಮಿಕ',
'ಅಧಿಕವರ್ಷ',
'ಅಧಿಕಾಕ್ಷರ',
'ಅಧಿಕಾರಸ್ಥ',
'ಅಧಿಕಾರಸ್ಥೆ',
'ಅಧಿಕಾರಿಣಿ',
'ಅಧಿಕಾರಿತಿ',
'ಅಧಿಕೃತತೆ',
'ಅಧಿಕೋಪಮೆ',
'ಅಧಿಕೋಷ್ಣಕ',
'ಅಧಿಗಣಿತ',
'ಅಧಿಗಮನ',
'ಅಧಿಜ್ಯಧನು',
'ಅಧಿದೇವತೆ',
'ಅಧಿದೈವತ',
'ಅಧಿನಾಯಕ',
'ಅಧಿನಾಯಕಿ',
'ಅಧಿನಾಯಿಕಿ',
'ಅಧಿನಿಯಮ',
'ಅಧಿಮಾನವ',
'ಅಧಿಮಾನಸ',
'ಅಧಿರೋಹಣ',
'ಅಧಿರೋಹಿಣಿ',
'ಅಧಿವಾಸನ',
'ಅಧಿವಾಸಿತ',
'ಅಧಿವಾಸಿಸು',
'ಅಧಿವೃತ್ತಜ',
'ಅಧಿವೇದನ',
'ಅಧಿವೇಶನ',
'ಅಧಿವ್ಯಾಪನೆ',
'ಅಧಿವ್ಯಾಪಿಸು',
'ಅಧಿಶೋಷಕ',
'ಅಧಿಶೋಷಣೆ',
'ಅಧಿಶ್ರಯಣಿ',
'ಅಧಿಷ್ಠಾಯಕ',
'ಅಧಿಸಂಸ್ಕೃತಿ',
'ಅಧಿಸೂಚನೆ',
'ಅಧಿಸೂಚಿತ',
'ಅಧೀನಕ್ರಿಯೆ',
'ಅಧೀನವಾಕ್ಯ',
'ಅಧುನಾತನ',
'ಅಧೈರ್ಯಂಗೊಳ್',
'ಅಧೈರ್ಯಂಗೊಳ್ಳು',
'ಅಧೋಗಮನ',
'ಅಧೋಗಮನೆ',
'ಅಧೋಜಿಹ್ವಿಕೆ',
'ಅಧೋವದನೆ',
'ಅಧ್ಯಕ್ಷತನ',
'ಅಧ್ಯಕ್ಷಾಲಯ',
'ಅಧ್ಯವಸಾನ',
'ಅಧ್ಯವಸಾಯ',
'ಅಧ್ಯವಸಾಯಿ',
'ಅಧ್ಯಾತ್ಮಗುಣ',
'ಅಧ್ಯಾತ್ಮಧ್ಯಾನ',
'ಅಧ್ಯಾತ್ಮಯೋಗ',
'ಅಧ್ಯಾತ್ಮರಸ',
'ಅಧ್ಯಾತ್ಮವಾದ',
'ಅಧ್ಯಾತ್ಮವಾದಿ',
'ಅಧ್ಯಾತ್ಮವಿದ್ಯೆ',
'ಅಧ್ಯಾತ್ಮಶಾಸ್ತ್ರ',
'ಅಧ್ಯಾತ್ಮಿಕತೆ',
'ಅಧ್ಯಾಪಕತ್ವ',
'ಅಧ್ಯಾರೋಪಣ',
'ಅಧ್ಯಾರೋಪಿಸು',
'ಅಧ್ಯಾವರಣ',
'ಅಧ್ಯಾಹರಣ',
'ಅಧ್ವಗಜನ',
'ಅಧ್ವಗಮನ',
'ಅಧ್ವಜಕ್ಲೇಶ',
'ಅಧ್ವರರಕ್ಷೆ',
'ಅಧ್ವರಶಾಲೆ',
'ಅಧ್ವರಹರ',
'ಅಧ್ವರೋಚಿತ',
'ಅನಂಗಪಾಶ',
'ಅನಂಗಮುದ್ರೆ',
'ಅನಂಗರಾಗ',
'ಅನಂಗರಾಜ್ಯ',
'ಅನಂಗರಿಪು',
'ಅನಂಗಲೋಕ',
'ಅನಂಗವೈರಿ',
'ಅನಂಗಸಂಗಿ',
'ಅನಂಗಸುಖ',
'ಅನಂಗೋತ್ಸವ',
'ಅನಂತಕಾಂತಿ',
'ಅನಂತಕಾಯ',
'ಅನಂತಗಣ',
'ಅನಂತಗುಣ',
'ಅನಂತಜ್ಞಾನ',
'ಅನಂತಪೀಠ',
'ಅನಂತಭೋಗ',
'ಅನಂತಮಡಿ',
'ಅನಂತಮಣಿ',
'ಅನಂತಮರ',
'ಅನಂತಮೂಲ',
'ಅನಂತರತ್ನ',
'ಅನಂತರೂಪ',
'ಅನಂತವಿಧ',
'ಅನಂತವೀರ್ಯ',
'ಅನಂತಶ್ರೇಣಿ',
'ಅನಂತಸುಖ',
'ಅನಂತಸೂಕ್ಷ್ಮ',
'ಅನಂತಸೌಖ್ಯ',
'ಅನಂತಾತ್ಮಕ',
'ಅನಂತಾಧಾರ',
'ಅನಂತಾನಂತ',
'ಅನಕ್ಷರತೆ',
'ಅನಕ್ಷರಸ್ಥ',
'ಅನಕ್ಷರಸ್ಥೆ',
'ಅನಘಸ್ಥಾನ',
'ಅನಡ್ವಜಿಹ್ವೆ',
'ಅನಡ್ವವಾಹ',
'ಅನತಿಕಾಲ',
'ಅನತಿಕ್ರಮ್ಯ',
'ಅನತಿದೂರ',
'ಅನತಿಶಯ',
'ಅನದ್ಯತನ',
'ಅನಧಿಕಾರ',
'ಅನಧಿಕಾರಿ',
'ಅನಧಿಕೃತ',
'ಅನಧೀನತೆ',
'ಅನಧ್ಯಯನ',
'ಅನನುಕೂಲ',
'ಅನನುಗಾಮಿ',
'ಅನನುಭವ',
'ಅನನುಭವಿ',
'ಅನನುಭೂತ',
'ಅನನ್ಯಭಾವ',
'ಅನನ್ಯವೃತ್ತಿ',
'ಅನನ್ಯಸಾಧ್ಯ',
'ಅನನ್ಯಾಸಕ್ತಿ',
'ಅನಪಕಾರಿ',
'ಅನಪರಾಧ',
'ಅನಪರಾಧಿ',
'ಅನಪರಾಧೆ',
'ಅನಪವರ್ತಿ',
'ಅನಪಾಯಕ',
'ಅನಪಾಯಿನಿ',
'ಅನಪೇಕ್ಷಕ',
'ಅನಪೇಕ್ಷಿತ',
'ಅನಫಾಯೋಗ',
'ಅನಭಿಜ್ಞತೆ',
'ಅನಭಿಜ್ಞಾತ',
'ಅನಭಿದೇಯ',
'ಅನಭಿಮಾನ',
'ಅನಭಿಲಪ್ಯ',
'ಅನಭಿಷಕ್ತ',
'ಅನಭಿಷ್ವಂಗ',
'ಅನಭ್ಯುದಯ',
'ಅನರೇಂದ್ರೇಜ್ಯ',
'ಅನರ್ಗಳತೆ',
'ಅನರ್ಘರತ್ನ',
'ಅನರ್ಘಾಕಾರ',
'ಅನರ್ಘ್ಯಮಣಿ',
'ಅನರ್ಘ್ಯರತ್ನ',
'ಅನರ್ಥಕರ',
'ಅನರ್ಥಕಾರಿ',
'ಅನರ್ಥಕ್ರಾಂತಿ',
'ಅನರ್ಥದಂಡ',
'ಅನರ್ಥಾಯಾಸ',
'ಅನಲಂಕೃತ',
'ಅನಲಗಣ್',
'ಅನಲಗರ್ಭ',
'ಅನಲನೇತ್ರ',
'ಅನಲಮುಖ',
'ಅನಲಶರ',
'ಅನವಕಾರ್ಯ',
'ಅನವಗಮ',
'ಅನವಚ್ಛಿನ್ನ',
'ಅನವದ್ಯತ್ವ',
'ಅನವಧಾನ',
'ಅನವಧಾರ್ಯ',
'ಅನವಧಿಕ',
'ಅನವಬೋಧ',
'ಅನವರತ',
'ಅನವರತಂ',
'ಅನವಶ್ಯಕ',
'ಅನವಸರ',
'ಅನವಸಾದ',
'ಅನವಸ್ಕರ',
'ಅನವಸ್ಥಿತ',
'ಅನವಸ್ಥಿತಿ',
'ಅನವಹಿತ',
'ಅನವೇಕ್ಷಣ',
'ಅನಶ್ವರತೆ',
'ಅನಸೂಯಕ',
'ಅನಸೊರಳಿ',
'ಅನಹಂಕಾರ',
'ಅನಹಂಕಾರಿ',
'ಅನಹಂಕೃತಿ',
'ಅನಹಂವಾದ',
'ಅನಹಂವಾದಿ',
'ಅನಾಕರ್ಷಕ',
'ಅನಾಕುಳತೆ',
'ಅನಾಕ್ರಮಿತ',
'ಅನಾಗತಜ್ಞ',
'ಅನಾಗರಿಕ',
'ಅನಾಘ್ರಾಣಿತ',
'ಅನಚ್ಛಾದಿತ',
'ಅನಾಡಂಬರ',
'ಅನಾತ್ಮಜ್ಞತ್ವ',
'ಅನಾತ್ಮವಸ್ತು',
'ಅನಾತ್ಮವಾದ',
'ಅನಾತ್ಮವಾದಿ',
'ಅನಾಥತಂತ್ರ',
'ಅನಾಥಬಂಧು',
'ಅನಾಥಾಲಯ',
'ಅನಾಥಾಶ್ರಮ',
'ಅನಾದರಣ',
'ಅನಾದರಣೆ',
'ಅನಾದರತ್ವ',
'ಅನಾದರಿಸು',
'ಅನಾದರ್ಶಿತ',
'ಅನಾದವಿಧ',
'ಅನಾದಿಕರ್ಮ',
'ಅನಾದಿಕವಿ',
'ಅನಾದಿಕಸ',
'ಅನಾದಿಕಾಲ',
'ಅನಾದಿಬೋಧ',
'ಅನಾದಿಮುಕ್ತ',
'ಅನಾದಿಮೂರ್ತಿ',
'ಅನಾದಿವಾರ್ತೆ',
'ಅನಾದಿಶೈವ',
'ಅನಾದಿಸಾಂತ',
'ಅನಾದಿಸಿದ್ಧ',
'ಅನಾದ್ಯತನ',
'ಅನಾಧಾರತೆ',
'ಅನಾನುಕೂಲ್ಯ',
'ಅನಾಭಿಲಾಷೆ',
'ಅನಾಮಧೇಯ',
'ಅನಾಮಿಕತೆ',
'ಅನಾಯಕತ್ವ',
'ಅನಾರ್ಯತಿಕ್ತ',
'ಅನಾಲೋಚಿತ',
'ಅನಾವರಣ',
'ಅನಾವರ್ತನೆ',
'ಅನಾವಶ್ಯಕ',
'ಅನಾವಿರ್ಭಾವ',
'ಅನಾಹಾರಕ',
'ಅನಿಂದ್ಯಗುಣ',
'ಅನಿಕೇತನ',
'ಅನಿಮಿಷತೆ',
'ಅನಿಮಿಷತ್ವ',
'ಅನಿಮಿಷಾರಿ',
'ಅನಿಮಿಷೇಂದ್ರ',
'ಅನಿಮ್ನನಾಭಿ',
'ಅನಿಯಂತ್ರಣ',
'ಅನಿಯಂತ್ರಿತ',
'ಅನಿಯತತೆ',
'ಅನಿಯಮಿತ',
'ಅನಿರೀಕ್ಷಿತ',
'ಅನಿರ್ಣಾಯಕ',
'ಅನಿರ್ದಿಷ್ಟತೆ',
'ಅನಿರ್ದೇಶನ',
'ಅನಿರ್ದೇಶಿತ',
'ಅನಿರ್ಧಾರಿತ',
'ಅನಿರ್ಬಂಧಿತ',
'ಅನಿರ್ಬಾಧಿತ',
'ಅನಿರ್ವಾಚ್ಯತೆ',
'ಅನಿಲಕ್ಷೇತ್ರ',
'ಅನಿಲಗಂಧಿ',
'ಅನಿಲಗರ್ಭ',
'ಅನಿಲದ್ವಾರ',
'ಅನಿಲಪಥ',
'ಅನಿಲಪ್ರಿಯ',
'ಅನಿಲಮಧ್ಯ',
'ಅನಿಲಮಾರ್ಗ',
'ಅನಿಲಯೋಗ',
'ಅನಿಲವೇಗ',
'ಅನಿಲಸ್ಥಿತಿ',
'ಅನಿಲಾತ್ಮಜ',
'ಅನಿಲಾಭೇದ್ಯ',
'ಅನಿಲಾವಳಿ',
'ಅನಿಲಾಹತ',
'ಅನಿವಂಗೆಯ್',
'ಅನಿವಾರಣ',
'ಅನಿವಾರಿತ',
'ಅನಿವಾರ್ಯತೆ',
'ಅನಿವಿರಿದು',
'ಅನಿಶ್ಚಿತತೆ',
'ಅನಿಷ್ಕೃಷ್ಟತೆ',
'ಅನಿಷ್ಟಕರ',
'ಅನಿಷ್ಟಕಾರಿ',
'ಅನಿಷ್ಟದಿನ',
'ಅನಿಷ್ಟಪುಣ್ಯ',
'ಅನಿಷ್ಟಪುಷ್ಟಿ',
'ಅನಿಷ್ಟಲಕ್ಷ್ಮಿ',
'ಅನೀತಿಯುತ',
'ಅನುಕಂಪನ',
'ಅನುಕರಣ',
'ಅನುಕರಣೆ',
'ಅನುಕರಿಸು',
'ಅನುಕರುಷ',
'ಅನುಕಲಜ',
'ಅನುಕಲನ',
'ಅನುಕಲಿಸು',
'ಅನುಕಲ್ಪನೆ',
'ಅನುಕಾಂತೀಯ',
'ಅನುಕಾಮೀನ',
'ಅನುಕೀರ್ತನೆ',
'ಅನುಕೂಲತೆ',
'ಅನುಕೂಲಸ್ಥ',
'ಅನುಕೂಲಸ್ಥೆ',
'ಅನುಕೂಲಸ್ತ್ರೀ',
'ಅನುಕೂಲಿಗ',
'ಅನುಕೂಲಿನಿ',
'ಅನುಕೂಲಿಸು',
'ಅನುಕೊಳಿಸು',
'ಅನುಕ್ತವಾಣಿ',
'ಅನುಕ್ತಸಿದ್ಧ',
'ಅನುಕ್ತಸೂತ್ರ',
'ಅನುಕ್ರಮಣ',
'ಅನುಕ್ರಮಿಸು',
'ಅನುಕ್ರಮೋಕ್ತಿ',
'ಅನುಗತಿಕ',
'ಅನುಗಪ್ಪುರ',
'ಅನುಗಮನ',
'ಅನುಗಮಿಸು',
'ಅನುಗೂಡಿಸು',
'ಅನುಗೊಳಿಸು',
'ಅನುಗ್ರಹಿಸು',
'ಅನುಗ್ರಹೀತ',
'ಅನುಗ್ರಹೀತೆ',
'ಅನುಗ್ರಾಹಕ',
'ಅನುಗ್ರಾಹಿಣಿ',
'ಅನುಗ್ರಾಹಿಸು',
'ಅನುಘಟನೆ',
'ಅನುಚಿಂತನೆ',
'ಅನುಚಿತತೆ',
'ಅನುಚಿತಾರ್ಥ',
'ಅನುಜನನ',
'ಅನುತರ್ಷಣ',
'ಅನುತ್ಕಟತೆ',
'ಅನುತ್ಕರ್ಷಿತ',
'ಅನುತ್ಪಾದಕ',
'ಅನುತ್ತರೀಯ',
'ಅನುತ್ತೀರ್ಣತೆ',
'ಅನುದಾತ್ತತೆ',
'ಅನುದಾನಿತ',
'ಅನುದಾರತೆ',
'ಅನುದ್ವಿಗ್ನತೆ',
'ಅನುಧಾವನ',
'ಅನುನಯಿಸು',
'ಅನುನಾಯಕ',
'ಅನುನಾಸಿಕ',
'ಅನುಪದೀನ',
'ಅನುಪಪತ್ತಿ',
'ಅನುಪಪನ್ನ',
'ಅನುಪಮಾನ',
'ಅನುಪಮಿತ',
'ಅನುಪಯುಕ್ತ',
'ಅನುಪಯೋಗ',
'ಅನುಪಯೋಗಿ',
'ಅನುಪಲಬ್ಧ',
'ಅನುಪಲಬ್ಧಿ',
'ಅನುಪಲ್ಲವಿ',
'ಅನುಪಸ್ಥಿತ',
'ಅನುಪಸ್ಥಿತಿ',
'ಅನುಪಸ್ಥಿತೆ',
'ಅನುಪಾತನ',
'ಅನುಪಾತ್ಯಯ',
'ಅನುಪಾದಾನ',
'ಅನುಪಾದೇಯ',
'ಅನುಪಾಲಕ',
'ಅನುಪಾಲನ',
'ಅನುಪಾಲಿಸು',
'ಅನುಪಿಗಲು',
'ಅನುಪುಮಾನ್ಯ',
'ಅನುಪುಸುಂಕ',
'ಅನುಪೂರಕ',
'ಅನುಪೂರ್ವಕ',
'ಅನುಪ್ರಯುಜ್ಯ',
'ಅನುಪ್ರವೇಶ',
'ಅನುಬಂಧನ',
'ಅನುಬಂಧಿಕೆ',
'ಅನುಬಂಧಿತ',
'ಅನುಬಂಧಿಸು',
'ಅನುಬದ್ಧತೆ',
'ಅನುಬಿಂಬಿಸು',
'ಅನುಭವಣಿ',
'ಅನುಭವಣೆ',
'ಅನುಭವಸ್ಥ',
'ಅನುಭವಸ್ಥೆ',
'ಅನುಭವಿಕ',
'ಅನುಭವಿಸು',
'ಅನುಭವೈಕ',
'ಅನುಭಾವಿಸು',
'ಅನುಭಾಷಣ',
'ಅನುಭಾಷಿಗ',
'ಅನುಭೋಕ್ತವ್ಯ',
'ಅನುಭೋಗಿಸು',
'ಅನುಮತಿಸು',
'ಅನುಮನಸು',
'ಅನುಮರಣ',
'ಅನುಮಸ್ತಿಷ್ಕ',
'ಅನುಮಾಡಿಸು',
'ಅನುಮಾನಿತ',
'ಅನುಮಾನಿಸು',
'ಅನುಮಾನೋಕ್ತಿ',
'ಅನುಮಾಪನ',
'ಅನುಮಾಪಿಸು',
'ಅನುಮಿತಿಸು',
'ಅನುಮೇಯಿಸು',
'ಅನುಮೋದಕ',
'ಅನುಮೋದಕಿ',
'ಅನುಮೋದನ',
'ಅನುಮೋದನೆ',
'ಅನುಮೋದಿತ',
'ಅನುಮೋದಿಸು',
'ಅನುಯೋಜನೆ',
'ಅನುಯೋಜಿಸು',
'ಅನುರಕ್ತತೆ',
'ಅನುರಕ್ತಿಸು',
'ಅನುರಣಕ',
'ಅನುರಣನ',
'ಅನುರಣಿತ',
'ಅನುರಣಿಸು',
'ಅನುರಾಗತೆ',
'ಅನುರಾಗಿಸು',
'ಅನುರಾಗಿಣಿ',
'ಅನುರೂಪತೆ',
'ಅನುರೋಧಿಸು',
'ಅನುಲಕ್ಷಿಸು',
'ಅನುಲಾಪಿಸು',
'ಅನುಲಿಂಗಾಣು',
'ಅನುಲೇಪಕ',
'ಅನುಲೇಪನ',
'ಅನುಲೇಪಿಸು',
'ಅನುವಂಗೆಯ್',
'ಅನುವಂಶೀಯ',
'ಅನುವಡಿಸು',
'ಅನುವದನ',
'ಅನುವದಿಸು',
'ಅನುವರಿಸು',
'ಅನುವರ್ಣನ',
'ಅನುವರ್ಣಿಸು',
'ಅನುವರ್ತನ',
'ಅನುವರ್ತನೆ',
'ಅನುವರ್ತಿಸು',
'ಅನುವಸಥ',
'ಅನುವಳಿಕ',
'ಅನುವಳಿಕೆ',
'ಅನುವಾಚಿಸು',
'ಅನುವಾದಕ',
'ಅನುವಾದಕಿ',
'ಅನುವಾದಿತ',
'ಅನುವಾದಿಸು',
'ಅನುವಾಪತ್ತು',
'ಅನುವಾಸರಂ',
'ಅನುವಿಧಿಸು',
'ಅನುವೃತ್ತಿಗೈ',
'ಅನುವೇದನೆ',
'ಅನುವೇಷ್ಟನೆ',
'ಅನುವೇಷ್ಟಿಸು',
'ಅನುಶಾಸಕ',
'ಅನುಶಾಸಕಿ',
'ಅನುಶಾಸನ',
'ಅನುಶಾಸಿಸು',
'ಅನುಶೀಲನ',
'ಅನುಶೀಲನೆ',
'ಅನುಶೋಣಿತ',
'ಅನುಸಂಜಾತೆ',
'ಅನುಸಂಧಾನ',
'ಅನುಸಂಧಿಸು',
'ಅನುಸಂಬಂಧ',
'ಅನುಸರಣ',
'ಅನುಸರಣೆ',
'ಅನುಸರಿಸು',
'ಅನುಸಾರಿಣಿ',
'ಅನುಸಿದ್ಧತೆ',
'ಅನುಸೂಚಿತ',
'ಅನುಸೂಚಿಸು',
'ಅನುಸ್ಥಾಪನೆ',
'ಅನುಸ್ಥಾಪಿಸು',
'ಅನುಸ್ಮರಣ',
'ಅನುಸ್ಮರಣೆ',
'ಅನೂನಗುಣ',
'ಅನೂನಗುಣೆ',
'ಅನೂನಪುಣ್ಯ',
'ಅನೂನಪುಣ್ಯೆ',
'ಅನೂನಫಲ',
'ಅನೂಪದೇಶ',
'ಅನೂರ್ಜಿತತೆ',
'ಅನೃತಸ್ಪರ್ಧಿ',
'ಅನೇಕಜಿಹ್ವ',
'ಅನೇಕತರ',
'ಅನೇಕಭಂಗಿ',
'ಅನೇಕಭವ',
'ಅನೇಕಮುಖ',
'ಅನೇಕವಾರಂ',
'ಅನೇಕವಿಧ',
'ಅನೇಕವೇಳೆ',
'ಅನೇಕಾಕ್ಷರ',
'ಅನೇಕಾಗ್ರತೆ',
'ಅನೇಕಾತ್ಮಕ',
'ಅನೇಕಾರ್ಥಕ',
'ಅನೇಕಾವರ್ತಿ',
'ಅನೇಕಾವೃತ್ತಿ',
'ಅನೇಡಮೂಕ',
'ಅನೈಕಮತ್ಯ',
'ಅನೈಕಾಂತಿಕ',
'ಅನೈಚ್ಛಿಕತೆ',
'ಅನೈತಿಕತೆ',
'ಅನೈಪುಣೋಕ್ತಿ',
'ಅನೈಸರ್ಗಿಕ',
'ಅನೋಸಂಸಾರ',
'ಅನೌಪಾಧಿಕ',
'ಅನ್ನಕಂಟಕ',
'ಅನ್ನಕಲ್ಪಕ',
'ಅನ್ನಗದಕ',
'ಅನ್ನಗ್ರಹಣ',
'ಅನ್ನಪಚನ',
'ಅನ್ನಪಚನೆ',
'ಅನ್ನಪದಾರ್ಥ',
'ಅನ್ನಪ್ರಾಶನ',
'ಅನ್ನಮುಹೂರ್ತ',
'ಅನ್ನವಾಟಿಕೆ',
'ಅನ್ನಸಂಸ್ಕಾರ',
'ಅನ್ನಾಥಬಂಧು',
'ಅನ್ನಾಭಿಷೇಕ',
'ಅನ್ನಾರಕಂಗೆ',
'ಅನ್ನಿಸಿಕೊಳ್ಳು',
'ಅನ್ನೆಯಕಾರ',
'ಅನ್ನೆವರೆಗಂ',
'ಅನ್ಯತ್ರಸ್ಥಿತಿ',
'ಅನ್ಯಥಾಖ್ಯಾತಿ',
'ಅನ್ಯಥಾಜ್ಞಾನಿ',
'ಅನ್ಯಥಾವಾಸ',
'ಅನ್ಯಥಾಸಿದ್ಧ',
'ಅನ್ಯದೇಶೀಯ',
'ಅನ್ಯನರೇಂದ್ರ',
'ಅನ್ಯಪುರುಷ',
'ಅನ್ಯಪೂರ್ವಿಕೆ',
'ಅನ್ಯಮನಸ್ಕ',
'ಅನ್ಯಮನಸ್ಕೆ',
'ಅನ್ಯವಿಷಯ',
'ಅನ್ಯಸಮಯ',
'ಅನ್ಯಸಮಯಿ',
'ಅನ್ಯಾಪದೇಶ',
'ಅನ್ಯಾಯಕಾರ',
'ಅನ್ಯಾಯಕಾರ್ತಿ',
'ಅನ್ಯಾಯವೃತ್ತಿ',
'ಅನ್ಯಾಯಾರ್ಜಿತ',
'ಅನ್ಯಾವನಿಪ',
'ಅನ್ಯೂನಪಾದ',
'ಅನ್ಯೋನ್ಯಪ್ರೀತಿ',
'ಅನ್ಯೋನ್ಯಭಾವ',
'ಅನ್ಯೋನ್ಯಯುದ್ಧ',
'ಅನ್ಯೋನ್ಯಸ್ಪೃಷ್ಟ',
'ಅನ್ಯೋನ್ಯಾಭಾವ',
'ಅನ್ಯೋನ್ಯಾಶ್ರಯ',
'ಅನ್ಯೋನ್ಯಾಶ್ರಿತ',
'ಅನ್ಯೋನ್ಯೋಪಮೆ',
'ಅನ್ಯೋಪಗಮ',
'ಅನ್ಯೋರ್ವೀಶ್ವರ',
'ಅನ್ವಯಕ್ರಮ',
'ಅನ್ವಯಕ್ಷಯ',
'ಅನ್ವಯದತ್ತಿ',
'ಅನ್ವಯವೃದ್ಧಿ',
'ಅನ್ವಯವ್ಯಾಪ್ತಿ',
'ಅನ್ವಯಾಗತ',
'ಅನ್ವಯೋಚಿತ',
'ಅನ್ವರ್ಥನಾಮ',
'ಅನ್ವಾದಿದಾರ',
'ಅಪಕರ್ಷಕ',
'ಅಪಕರ್ಷಣ',
'ಅಪಕರ್ಷಣೆ',
'ಅಪಕರ್ಷಿಸು',
'ಅಪಕಾರಕ',
'ಅಪಕೀರಿತಿ',
'ಅಪಕೇಂದ್ರಕ',
'ಅಪಕ್ರಮಣ',
'ಅಪಕ್ವಸ್ಥಿತಿ',
'ಅಪಕ್ಷ್ಮಪಾತ',
'ಅಪಕ್ಷ್ಮಸ್ಪಂದ',
'ಅಪಗತತ್ವ',
'ಅಪಗಮನ',
'ಅಪಗಮಿತ',
'ಅಪಗಮಿಸು',
'ಅಪಘಾತಕ',
'ಅಪಚರಿಸು',
'ಅಪಚಿತಿಗೈ',
'ಅಪಛಂದಸ್ಸು',
'ಅಪತ್ಯಪ್ರೇಮ',
'ಅಪತ್ಯೋದಯ',
'ಅಪತ್ರಪಿಷ್ಣು',
'ಅಪದಗಾಮಿ',
'ಅಪದಾಂತರ',
'ಅಪದೃಕ್ಸಾರ',
'ಅಪಧಮನಿ',
'ಅಪನಂಬಿಕೆ',
'ಅಪನಂಬಿಗೆ',
'ಅಪನಂಬುಗೆ',
'ಅಪನಯನ',
'ಅಪನೋದನ',
'ಅಪನೋದಿತ',
'ಅಪಪ್ರಚಾರ',
'ಅಪಪ್ರಯೋಗ',
'ಅಪಭ್ರಂಶತೆ',
'ಅಪಭ್ರಮಣ',
'ಅಪಮರ್ಯಾದೆ',
'ಅಪಮಾನಿತ',
'ಅಪಮಾನಿತೆ',
'ಅಪಮಾನಿಸು',
'ಅಪಮಾರ್ಜನ',
'ಅಪಮಿಶ್ರಣ',
'ಅಪರಂಪಾರ',
'ಅಪರಕರ್ಮ',
'ಅಪರಕ್ರಿಯೆ',
'ಅಪರಗಿರಿ',
'ಅಪರಜ್ಞಾನ',
'ಅಪರದಿಕ್ಕು',
'ಅಪರಪಕ್ಷ',
'ಅಪರಬ್ರಹ್ಮ',
'ಅಪರಭಾಗ',
'ಅಪರರಾತ್ರಿ',
'ಅಪರವಾರ್ಧಿ',
'ಅಪರಸಂಧ್ಯೆ',
'ಅಪರಾಂಬುಧಿ',
'ಅಪರಾಜಿತ',
'ಅಪರಾಜಿತೆ',
'ಅಪರಾದಿತ್ಯ',
'ಅಪರಾಧಿತೆ',
'ಅಪರಾಧಿನಿ',
'ಅಪರಾಧೀನ',
'ಅಪರಾವಿದ್ಯೆ',
'ಅಪರಿಗ್ರಹ',
'ಅಪರಿಚಿತ',
'ಅಪರಿಚಿತೆ',
'ಅಪರಿಚ್ತ್ವಿನ್ನ',
'ಅಪರಿಣತ',
'ಅಪರಿಣತೆ',
'ಅಪರಿಣಾಮ',
'ಅಪರಿತ್ರಾಣ',
'ಅಪರಿಪಕ್ವ',
'ಅಪರಿಪಾತ್ರ',
'ಅಪರಿಪೂರ್ಣ',
'ಅಪರಿಭಿನ್ನ',
'ಅಪರಿಮಿತ',
'ಅಪರಿಮೇಯ',
'ಅಪರಿವರ್ತಿ',
'ಅಪರಿವತ್ರ್ಯ',
'ಅಪರಿಶುದ್ಧ',
'ಅಪರಿಷ್ಕೃತ',
'ಅಪರಿಹಾರ್ಯ',
'ಅಪರೋಧನ',
'ಅಪರ್ಣಾನ್ವಿತ',
'ಅಪರ್ಯಾಪ್ತಕ',
'ಅಪರ್ಯಾಪ್ತತೆ',
'ಅಪಲಪನ',
'ಅಪಲಪಿಸು',
'ಅಪಲಾಪನ',
'ಅಪಲಾಪನೆ',
'ಅಪಲಾಪಿಸು',
'ಅಪಲಿಖಿತ',
'ಅಪವರಕ',
'ಅಪವರ್ಜನ',
'ಅಪವರ್ತಕ',
'ಅಪವರ್ತನ',
'ಅಪವರ್ತಿಸು',
'ಅಪವಾರಣ',
'ಅಪವಾರಿತ',
'ಅಪವಿತ್ರತೆ',
'ಅಪಶಕುನ',
'ಅಪಸಂಸ್ಕಾರ',
'ಅಪಸನ್ಮುಗ್ಧ',
'ಅಪಸರಣ',
'ಅಪಸರಣೆ',
'ಅಪಸರ್ಪಣ',
'ಅಪಸಹಾಯ',
'ಅಪಸಾಮಾನ್ಯ',
'ಅಪಸಾರಿತ',
'ಅಪಸಿದ್ಧಾಂತ',
'ಅಪಸುಬಾಳು',
'ಅಪಸೆಣಬು',
'ಅಪಸ್ಮಾರಕ',
'ಅಪಹರಣ',
'ಅಪಹರಣೆ',
'ಅಪಹರಿಸು',
'ಅಪಹಸನ',
'ಅಪಹಸಿತ',
'ಅಪಹಸ್ತಿತ',
'ಅಪಹಾತಳು',
'ಅಪಹಾರಕ',
'ಅಪಹಾರಕಿ',
'ಅಪಾಂಗದೇಶ',
'ಅಪಾಂಗದ್ಯುತಿ',
'ಅಪಾಂಗದ್ಯೋತಿ',
'ಅಪಾಂಗರೋಚಿ',
'ಅಪಾಕರಣ',
'ಅಪಾತೋಚಿತ',
'ಅಪಾತ್ರದಾನ',
'ಅಪಾತ್ರಪಾತ್ರ',
'ಅಪಾನವಾಯು',
'ಅಪಾನಾನಿಲ',
'ಅಪಾಯಕರ',
'ಅಪಾಯಕಾರಿ',
'ಅಪಾರದರ್ಶಿ',
'ಅಪಾರವೀರ್ಯ',
'ಅಪಿನಿಕೂಟ',
'ಅಪೀಲುದಾರ',
'ಅಪೀಲುಮಾಡು',
'ಅಪುನರ್ಜಾತ',
'ಅಪುನರ್ಭವ',
'ಅಪೂರ್ವತೆರ',
'ಅಪೂರ್ವಭಿತ್ತಿ',
'ಅಪೂರ್ವರೂಪ',
'ಅಪೂರ್ವರೂಪೆ',
'ಅಪೇಕ್ಷಣೀಯ',
'ಅಪೇಕ್ಷೆಗೆಯ್',
'ಅಪೇತದೋಷ',
'ಅಪೌರುಷೇಯ',
'ಅಪ್ಪಂತತನ',
'ಅಪ್ಪಣೆಚೀಟಿ',
'ಅಪ್ಪಣೆವಡೆ',
'ಅಪ್ಪಣೆವೀಳ್ಯ',
'ಅಪ್ಪಯಗೆಡ್ಡೆ',
'ಅಪ್ಪಳಿಗೊಳ್',
'ಅಪ್ಪಿಕೊಳುಹ',
'ಅಪ್ಪಕಣಿಸು',
'ಅಪ್ಪುಗಾಣಿಸು',
'ಅಪ್ಪುಗೆವಿಡಿ',
'ಅಪ್ಪುಗೈದೋಟಿ',
'ಅಪ್ಪುಗೈಮೋಡಿ',
'ಅಪ್ರಕಟಿತ',
'ಅಪ್ರಕಾಶಿತ',
'ಅಪ್ರಗಲ್ಭತೆ',
'ಅಪ್ರಚಲಿತ',
'ಅಪ್ರಚೋದಿತ',
'ಅಪ್ರತಿಕರ',
'ಅಪ್ರತಿಘಾತ',
'ಅಪ್ರತಿಪತ್ತಿ',
'ಅಪ್ರತಿಬದ್ಧ',
'ಅಪ್ರತಿಬಲ',
'ಅಪ್ರತಿಭಟ',
'ಅಪ್ರತಿಮಲ್ಲ',
'ಅಪ್ರತಿಮಾನ',
'ಅಪ್ರತಿರಥ',
'ಅಪ್ರತಿವಾದ',
'ಅಪ್ರತಿಷೇದ',
'ಅಪ್ರತಿಷ್ಠಿತ',
'ಅಪ್ರತಿಹತ',
'ಅಪ್ರತಿಹತೆ',
'ಅಪ್ರತಿಹಸ್ತ',
'ಅಪ್ರತ್ಯಾಖ್ಯಾನ',
'ಅಪ್ರದಕ್ಷಿಣ',
'ಅಪ್ರದಕ್ಷಿಣೆ',
'ಅಪ್ರಧಾನತೆ',
'ಅಪ್ರನಮಿತ',
'ಅಪ್ರಬುದ್ಧತೆ',
'ಅಪ್ರಬುದ್ಧಿಕೆ',
'ಅಪ್ರಮತ್ತತೆ',
'ಅಪ್ರಮೇಯಕ',
'ಅಪ್ರಯೋಜಕ',
'ಅಪ್ರಯೋಜಕಿ',
'ಅಪ್ರವಿಲಕ್ಷ್ಯ',
'ಅಪ್ರಸಕ್ತತೆ',
'ಅಪ್ರಸನ್ನತೆ',
'ಅಪ್ರಸ್ತುತತೆ',
'ಅಪ್ರಾಕೃತಿಕ',
'ಅಪ್ರಾತ್ಯಕ್ಷಿಕ',
'ಅಪ್ರಾಪ್ತಪೂರ್ವ',
'ಅಪ್ರಾಪ್ತವಸ್ಕೆ',
'ಅಪ್ರಾಮಾಣಿಕ',
'ಅಪ್ರಾಸಂಗಿಕ',
'ಅಪ್ಸರೆಕೀಟ',
'ಅಪ್ಸರೋಗಣ',
'ಅಫೀಮುಖೋರ',
'ಅಫೀಮುಬತ್ತಿ',
'ಅಬದ್ಧಖೋರ',
'ಅಬದ್ಧಗಾರ',
'ಅಬದ್ಧಮುಖ',
'ಅಬದ್ಧಸಾಕ್ಷಿ',
'ಅಬರುಗೇಡಿ',
'ಅಬರೂಗೇಡಿ',
'ಅಬಾಡುಕಟ್ಟು',
'ಅಬಾಡುವಜ್ಜೆ',
'ಅಬಾಧಿತತೆ',
'ಅಬಿಳವಾಲು',
'ಅಬುಜಭವ',
'ಅಬುಜವ್ಯೂಹ',
'ಅಬುಜಾನನೆ',
'ಅಬುಜಾಸನ',
'ಅಬುಜಾಸನೆ',
'ಅಬುಜೋದರ',
'ಅಬುಜೋದ್ಭವ',
'ಅಬೌದ್ಧಿಕತೆ',
'ಅಬ್ಜಕರ್ಣಿಕೆ',
'ಅಬ್ಜಜಠರ',
'ಅಬ್ಜಪ್ರಸರ',
'ಅಬ್ಜಬಾಂಧವ',
'ಅಬ್ಜಭವಾಂಡ',
'ಅಬ್ಜವದನೆ',
'ಅಬ್ಜವಾಸಿನಿ',
'ಅಬ್ಜವಾಹನ',
'ಅಬ್ಜವಿರೋಧಿ',
'ಅಬ್ಜವಿಷ್ಟರ',
'ಅಬ್ಜಸಂಭವ',
'ಅಬ್ಜಿನೀಕಾಂತ',
'ಅಬ್ಜಿನೀಪತಿ',
'ಅಬ್ಜಿನೀವರ',
'ಅಬ್ದ್ರವ್ಯಪಣ್ಯ',
'ಅಬ್ಧಿನಂದನ',
'ಅಬ್ಧಿಪರೀತ',
'ಅಬ್ಧಿಶಯನ',
'ಅಬ್ಬರಂಗೆಯ್',
'ಅಬ್ಬರಂಬರಿ',
'ಅಬ್ಬರಗೂಡು',
'ಅಬ್ಬರತಾಳ',
'ಅಬ್ಬರಮಾಡು',
'ಅಬ್ಬಿಗೊಟ್ಟಗೆ',
'ಅಬ್ಬುಗರಿಕೆ',
'ಅಬ್ಬುಡಂಪೋಗು',
'ಅಬ್ಬೂಜಿಗೂಡು',
'ಅಭಯಂಗುಡು',
'ಅಭಯದಾತ',
'ಅಭಯದಾತೆ',
'ಅಭಯದಾನ',
'ಅಭಯದೀಪ',
'ಅಭಯಧಾಮ',
'ಅಭಯಪ್ರದ',
'ಅಭಯಪ್ರದೆ',
'ಅಭಯಮನ',
'ಅಭಯಮನೆ',
'ಅಭಯಮುದ್ರೆ',
'ಅಭಯಹಸ್ತ',
'ಅಭಯಾರಣ್ಯ',
'ಅಭವಾಗಮ',
'ಅಭಾವಾತ್ಮಕ',
'ಅಭಿಕರಣ',
'ಅಭಿಕಾಂಕ್ಷಿಸು',
'ಅಭಿಕೇಂದ್ರಕ',
'ಅಭಿಕ್ರಮಣ',
'ಅಭಿಕ್ಷಮತೆ',
'ಅಭಿಗಮನ',
'ಅಭಿಗಮಿಸು',
'ಅಭಿಗರಿಸು',
'ಅಭಿಗೀತಾರ್ಥ',
'ಅಭಿಗೃಹೀತ',
'ಅಭಿಗ್ರಹಣ',
'ಅಭಿಗ್ರಾಹಕ',
'ಅಭಿಚಾರಕ',
'ಅಭಿಜ್ಞಮತ',
'ಅಭಿತಂದ್ರಿಸು',
'ಅಭಿದೃಶ್ಯಕ',
'ಅಭಿಧಮನಿ',
'ಅಭಿಧಾವೃತ್ತಿ',
'ಅಭಿಧೇಯಾರ್ಥ',
'ಅಭಿನಂದನ',
'ಅಭಿನಂದನೆ',
'ಅಭಿನಂದಿತ',
'ಅಭಿನಂದಿಸು',
'ಅಭಿನಮನ',
'ಅಭಿನಮಿಸು',
'ಅಭಿನಯಿಸು',
'ಅಭಿನಲಿಸು',
'ಅಭಿನವಿಸು',
'ಅಭಿನಿರ್ಮುಕ್ತ',
'ಅಭಿನಿರ್ಯಾಣ',
'ಅಭಿನಿವೇಶ',
'ಅಭಿನುತಿಸು',
'ಅಭಿನೇಯಾರ್ಥ',
'ಅಭಿನ್ನಾಚಾರ',
'ಅಭಿಪ್ರೇರಕ',
'ಅಭಿಪ್ರೇರಕಿ',
'ಅಭಿಪ್ರೇರಣೆ',
'ಅಭಿಪ್ರೇರಿಸು',
'ಅಭಿಭವಿಸು',
'ಅಭಿಭಾವಕ',
'ಅಭಿಭಾಷಣ',
'ಅಭಿಮಂತ್ರಣ',
'ಅಭಿಮಂತ್ರಿತ',
'ಅಭಿಮಂತ್ರಿಸು',
'ಅಭಿಮತಾರ್ಥ',
'ಅಭಿಮತಿಸು',
'ಅಭಿಮಾನತೆ',
'ಅಭಿಮಾನಿಸು',
'ಅಭಿಮುಖತೆ',
'ಅಭಿಯೋಜಕ',
'ಅಭಿಯೋಜನೆ',
'ಅಭಿಯೋಜಿಸು',
'ಅಭಿರಂಜಕ',
'ಅಭಿರಂಜನೆ',
'ಅಭಿರಂಜಿಸು',
'ಅಭಿರಕ್ಷಕ',
'ಅಭಿರಕ್ಷಣೆ',
'ಅಭಿರಕ್ಷಿಸು',
'ಅಭಿರಮಿಸು',
'ಅಭಿರಾಜಿಸು',
'ಅಭಿರಾಮತೆ',
'ಅಭಿಲಷಿತ',
'ಅಭಿಲಾಷಿತ',
'ಅಭಿಲಾಷಿಸು',
'ಅಭಿಲಾಷುಕ',
'ಅಭಿಲೇಖಕ',
'ಅಭಿಲೇಖನ',
'ಅಭಿವಂಚಿಸು',
'ಅಭಿವಂದನ',
'ಅಭಿವಂದನೆ',
'ಅಭಿವಂದಿತ',
'ಅಭಿವಂದಿತೆ',
'ಅಭಿವಂದಿಸು',
'ಅಭಿವಚನ',
'ಅಭಿವರ್ಣನ',
'ಅಭಿವರ್ಣನೆ',
'ಅಭಿವರ್ಣಿಸು',
'ಅಭಿವರ್ಧಕ',
'ಅಭಿವರ್ಧನ',
'ಅಭಿವರ್ಧನೆ',
'ಅಭಿವರ್ಧಿಸು',
'ಅಭಿವಾಂಛನೆ',
'ಅಭಿವಾಂಛಿತ',
'ಅಭಿವಾಂಛಿಸು',
'ಅಭಿವಾದಕ',
'ಅಭಿವಾದಕಿ',
'ಅಭಿವಾದನ',
'ಅಭಿವಾದನೆ',
'ಅಭಿವಾದಿಸು',
'ಅಭಿವಿನುತ',
'ಅಭಿವೀಕ್ಷಣ',
'ಅಭಿವೀಕ್ಷಿಸು',
'ಅಭಿವೇಷ್ಟಿತ',
'ಅಭಿವ್ಯಂಜಕ',
'ಅಭಿವ್ಯಂಜನ',
'ಅಭಿವ್ಯಂಜನೆ',
'ಅಭಿವ್ಯಂಜಿಸು',
'ಅಭಿವ್ಯಾಪಕ',
'ಅಭಿವ್ಯಾಪನೆ',
'ಅಭಿವ್ಯಾಪಿಸು',
'ಅಭಿಶಂಸನ',
'ಅಭಿಶಪನ',
'ಅಭಿಶಪಿತ',
'ಅಭಿಶೀಲನ',
'ಅಭಿಶೀಲಿಸು',
'ಅಭಿಷವಣ',
'ಅಭಿಷವಾಂಬು',
'ಅಭಿಷೇಕಿಸು',
'ಅಭಿಷೇಚನ',
'ಅಭಿಷೇಧಿಸು',
'ಅಭಿಸಂಧಿಕೆ',
'ಅಭಿಸಂಪಾತ',
'ಅಭಿಸರಣ',
'ಅಭಿಸರಿಸು',
'ಅಭಿಸಾರಣ',
'ಅಭಿಸಾರಿಕೆ',
'ಅಭಿಸಾರಿಗೆ',
'ಅಭಿಸಾರಿಣಿ',
'ಅಭಿಸ್ವೀಕಾರ',
'ಅಭೀತಚೇತ',
'ಅಭೀತಮನ',
'ಅಭೀತಾತ್ಮಕ',
'ಅಭೀಷ್ಟಫಲ',
'ಅಭೀಷ್ಟರಸ',
'ಅಭೀಷ್ಟಸಿದ್ಧಿ',
'ಅಭುಕ್ತಮೂಲೆ',
'ಅಭೂತಪೂರ್ವ',
'ಆಭೂತಭಯ',
'ಅಭೂತೋಪಮೆ',
'ಅಭೇದವಾದ',
'ಅಭೇದವಾದಿ',
'ಅಭೇದಶ್ರುತಿ',
'ಅಭೇದಾತ್ಮತೆ',
'ಅಭೇದ್ಯಲಿಂಗ',
'ಅಭೇದ್ಯಸ್ಥಲ',
'ಅಭೌತಿಕತೆ',
'ಅಭ್ಯಂಗಸ್ನಾನ',
'ಅಭ್ಯಂತರಿಸು',
'ಅಭ್ಯಮಿತ್ರೀಣ',
'ಅಭ್ಯರ್ಹಿತತ್ವ',
'ಅಭ್ಯವಹಾರ',
'ಅಭ್ಯವಹಾರಿ',
'ಅಭ್ಯವಹೃತ',
'ಅಭ್ಯವಹೃತಿ',
'ಅಭ್ಯಸನೀಯ',
'ಅಭ್ಯಾಸಬಲ',
'ಅಭ್ಯಾಸಯೋಗ',
'ಅಭ್ಯುದಯಿಕ',
'ಅಭ್ಯುದ್ಗಮನ',
'ಅಭ್ಯುಪಗಮ',
'ಅಭ್ಯುಪಪತ್ತಿ',
'ಅಭ್ಯುಪಮಾನ',
'ಅಭ್ಯುಪಮಿತ',
'ಅಭ್ಯುಪಮೋಕ್ತಿ',
'ಅಭ್ರಂಕಷತೆ',
'ಅಭ್ರಗಪತಿ',
'ಅಭ್ರಗಮನ',
'ಅಭ್ರಗಮನೆ',
'ಅಭ್ರಗರ್ಜನೆ',
'ಅಭ್ರಗರ್ಜಿತ',
'ಅಭ್ರತಿಮಿರ',
'ಅಭ್ರನಿಭಾಂಗ',
'ಅಭ್ರಪಟಲ',
'ಅಭ್ರಮಂಡಲ',
'ಅಭ್ರಮಾತಂಗ',
'ಅಭ್ರವಿಭ್ರಮಿ',
'ಅಮಂದಧ್ವನಿ',
'ಅಮಂದರುಚಿ',
'ಅಮಂದಸ್ನೇಹ',
'ಅಮಂದಸ್ನೇಹಿ',
'ಅಮಂದಾನಂದ',
'ಅಮತ್ಸರತ್ವ',
'ಅಮದವೃತ್ತಿ',
'ಅಮದಾಲಸ',
'ಅಮನ್ಯಮಾನ',
'ಅಮರಕರಿ',
'ಅಮರಕುಜ',
'ಅಮರಕೋಶ',
'ಅಮರಗಂಗೆ',
'ಅಮರಗಂಧಿ',
'ಅಮರಗಣ',
'ಅಮರಗಾರ',
'ಅಮರಗಿರಿ',
'ಅಮರಗುರು',
'ಅಮರಗ್ರಾಮ',
'ಅಮರಣತ್ವ',
'ಅಮರತರು',
'ಅಮರತೂರ್ಯ',
'ಅಮರಧನು',
'ಅಮರಧುನಿ',
'ಅಮರಧೇನು',
'ಅಮರನಗ',
'ಅಮರನದಿ',
'ಅಮರಪತಿ',
'ಅಮರಪದ',
'ಅಮರಬಳ್ಳಿ',
'ಅಮರಭೂಜ',
'ಅಮರಮಣಿ',
'ಅಮರಮುನಿ',
'ಅಮರಲೋಕ',
'ಅಮರವಧು',
'ಅಮರವಳ್ಳಿ',
'ಅಮರವೈರಿ',
'ಅಮರಸಿಂಧು',
'ಅಮರಸೀಮೆ',
'ಅಮರಾಂಘ್ರಿಪ',
'ಅಮರಾಚಲ',
'ಅಮರಾಪಗೆ',
'ಅಮರಾಲಯ',
'ಅಮರಾವತಿ',
'ಅಮರಾವಳಿ',
'ಅಮರುಗಾರ',
'ಅಮರೋರ್ವಿಜ',
'ಅಮರ್ಕೆಗಿಡು',
'ಅಮರ್ಕೆವಡೆ',
'ಅಮರ್ಕೆವೆಱು',
'ಅಮರ್ಚಿಕೊಳ್',
'ಅಮರ್ತ್ಯದೀಪ್ತಿ',
'ಅಮರ್ತ್ಯಮಾರ್ಗ',
'ಅಮರ್ದುಕಳೆ',
'ಅಮರ್ದುಕೆಯ್',
'ಅಮರ್ದುಗಯ್ಯ',
'ಅಮರ್ದುಗಱೆ',
'ಅಮರ್ದುಗೂಳ್',
'ಅಮರ್ದುಗೆಯ್',
'ಅಮರ್ದುಗೆಯ್ಯ',
'ಅಮರ್ದುಗೈಯ್ಯ',
'ಅಮರ್ದುಣಿಗ',
'ಅಮರ್ದುಣಿಸ',
'ಅಮರ್ದುಣಿಸಿ',
'ಅಮರ್ದುಣಿಸು',
'ಅಮರ್ದುನುಡಿ',
'ಅಮರ್ದುಮರ್ದು',
'ಅಮರ್ದುವಳೆ',
'ಅಮರ್ದುವಳ್ಳಿ',
'ಅಮರ್ದುವೆಣ್',
'ಅಮರ್ದುಸೋನೆ',
'ಅಮಲದಾರ',
'ಅಮಲಾತಕ',
'ಅಮಲುಗಾರ',
'ಅಮಲುಜಾರಿ',
'ಅಮಲುದಾರ',
'ಅಮಲ್ದಾರಿಕೆ',
'ಅಮಳ್ದೇವರ್',
'ಅಮಳಮಿಂಚು',
'ಅಮಳಸ್ವಾಂತ',
'ಅಮಳಾಳೋಕ',
'ಅಮಳ್ದಲೆಯ',
'ಅಮಳ್ದಾವರೆ',
'ಅಮಳ್ದೊಂಗಲ್',
'ಅಮಳ್ಬೆರಳು',
'ಅಮಳ್ವೆಸರ್',
'ಅಮಳ್ವೆತ್ತರ್',
'ಅಮಾಂತಮಾಸ',
'ಅಮಾನವೀಯ',
'ಅಮಾನಸಭೆ',
'ಅಮಾನಿಕೆರೆ',
'ಅಮಾನಿಗ್ರಾಮ',
'ಅಮಾನಿದಾರ',
'ಅಮಾನುಷತೆ',
'ಅಮಾಯಕತೆ',
'ಅಮಾರ್ಜನೀಯ',
'ಅಮಿತಭಗ',
'ಅಮಿತಾಯುಷ್ಯ',
'ಅಮಿತಾಯುಸ್ಸು',
'ಅಮಿತಾಳಾಪ',
'ಅಮುಖ್ಯಕರ್ಮ',
'ಅಮುಷ್ಯಾಯಣ',
'ಅಮೂಢದೃಷ್ಟಿ',
'ಅಮೂರ್ತರೂಪ',
'ಅಮೃತಂಬಡೆ',
'ಅಮೃತಕರ',
'ಅಮೃತಕಲ್ಪ',
'ಅಮೃತಕುಂಭ',
'ಅಮೃತಗಣ',
'ಅಮೃತಗರ್ಭ',
'ಅಮೃತಗಿರಿ',
'ಅಮೃತತತ್ತ್ವ',
'ಅಮೃತತೀರ್ಥ',
'ಅಮೃತದೃಷ್ಟಿ',
'ಅಮೃತದ್ಯುತಿ',
'ಅಮೃತದ್ರವ',
'ಅಮೃತಧಾಮ',
'ಅಮೃತಪಡಿ',
'ಅಮೃತಪದ',
'ಅಮೃತಪಿಂಡ',
'ಅಮೃತಫಲ',
'ಅಮೃತಫಳ',
'ಅಮೃತಬಳ್ಳಿ',
'ಅಮೃತಭಕ್ಷ್ಯ',
'ಅಮೃತಭವ',
'ಅಮೃತಮಯ',
'ಅಮೃತರಶ್ಮಿ',
'ಅಮೃತರೇಖೆ',
'ಅಮೃತಲೋಕ',
'ಅಮೃತವರ್ಷ',
'ಅಮೃತವಲ್ಲಿ',
'ಅಮೃತವಳ್ಳಿ',
'ಅಮೃತವಾಕ್ಯ',
'ಅಮೃತವಾಣಿ',
'ಅಮೃತವಾರ್ಧಿ',
'ಅಮೃತವೃಷ್ಟಿ',
'ಅಮೃತಶಿಲೆ',
'ಅಮೃತಸಂಧಿ',
'ಅಮೃತಸ್ಯಂದಿ',
'ಅಮೃತಸ್ವರ',
'ಅಮೃತಹಸ್ತ',
'ಅಮೃತಹಸ್ತೆ',
'ಅಮೃತಾಂಜನ',
'ಅಮೃತಾಂಧಸ',
'ಅಮೃತಾಕಾರ',
'ಅಮೃತಾಕ್ಷರ',
'ಅಮೃತಾಧರೆ',
'ಅಮೃತಾಯತ',
'ಅಮೃತಾವಹ',
'ಅಮೃತಾವಾಸ',
'ಅಮೃತಾಶನ',
'ಅಮೃತಾಸಾರ',
'ಅಮೃತೋದ್ಭವ',
'ಅಮೋಘವರ್ಷ',
'ಅಮ್ಮಂಗೊಡಲಿ',
'ಅಮ್ಮಾಲೆಯಾಡು',
'ಅಮ್ಮಾಲೆಬೀಸು',
'ಅಮ್ಲಲೋಣಿಕೆ',
'ಅಮ್ಲವೇತಸ',
'ಅಮ್ಲಹರಿದ್ರೆ',
'ಅಯಃಪುತ್ರಿಕೆ',
'ಅಯಕರಣ',
'ಅಯತ್ನಕೃತ',
'ಅಯತ್ನಸುಖ',
'ಅಯಥಾರ್ಥತೆ',
'ಅಯನವೃತ್ತ',
'ಅಯಶಸ್ಕೀರ್ತಿ',
'ಅಯಶ್ಶಲಾಕೆ',
'ಅಯಶ್ಶೂಲಿಕ',
'ಅಯಾಚಿತತೆ',
'ಅಯಿಗಂಡುಗ',
'ಅಯಿರಾವತ',
'ಅಯೋಗ್ರಾಹ್ಯತೆ',
'ಅಯೋಲಲನೆ',
'ಅಯ್ಕಿಲೆಲರ್',
'ಅಯ್ಕಿಲ್ಗದಿರ',
'ಅಯ್ಕಿಲ್ವೆಳಗ',
'ಅಯ್ದಾಮರಿಗ',
'ಅಯ್ದೆಮಿನುಗು',
'ಅಯ್ದೆಲೆನಾಗ',
'ಅಯ್ರಗಡಗ',
'ಅಯ್ರಣೆಗೊಡ',
'ಅಯ್ರಾಣಿಕುಂಭ',
'ಅಯ್ವತ್ತಿಬ್ಬರು',
'ಅಯ್ವದಿಂಬರ್',
'ಅಯ್ವಾಯಪುಲಿ',
'ಅಯ್ಸಾಸಿರ್ವರ್',
'ಅರಕಂಚಟ್ಟಿ',
'ಅರಕಾಣಿಕೆ',
'ಅರಕುಮಾರ',
'ಅರಕ್ಷಿತತೆ',
'ಅರಗಂಡುಗ',
'ಅರಗಳಕ',
'ಅರಗಳಿಗೆ',
'ಅರಗುಗಲ್ಲು',
'ಅರಗುಮಸಿ',
'ಅರಗುವರ',
'ಅರಗುಹುಣ್ಣು',
'ಅರಗೆಲಸಿ',
'ಅರಗೊರತೆ',
'ಅರಘಟಕ',
'ಅರಘಟ್ಟಕ',
'ಅರಘವಾಳ',
'ಅರಘಳಿಗೆ',
'ಅರಚಲ್ಲಣ',
'ಅರಣಿಪುತ್ರ',
'ಅರಣ್ಯನ್ಯಾಯ',
'ಅರಣ್ಯಪಟ್ಟೆ',
'ಅರಣ್ಯಾಂತರ',
'ಅರಣ್ಯ ಶಾಸ್ತ್ರ',
'ಅರತಳಾಱ',
'ಅರನೇಱಿಲ',
'ಅರಪಿಚ್ಚಗೆ',
'ಅರಪುಕನ್ನೆ',
'ಅರಬದ್ದಗಿ',
'ಅರಬಸ್ಥಾನ',
'ಅರಬಿದೇಶ',
'ಅರಬುಗಾಡು',
'ಅರಬೊಜಂಗ',
'ಅರಬ್ಬಿಸ್ತಾನ್',
'ಅರಮಗಳ್',
'ಅರಮರಲು',
'ಅರಮರಳು',
'ಅರಮರಿಕೆ',
'ಅರಮಾದಲ',
'ಅರಮಾದಳ',
'ಅರರಿಪುಟ',
'ಅರಲುಗುಂದು',
'ಅರಲುಗೆಂಡ',
'ಅರಲ್ಗುಂದಿಸು',
'ಅರಲ್ತೊಡಂಬೆ',
'ಅರಲ್ದೊಂಗಲ್',
'ಅರಲ್ಪೊಡೆಯ',
'ಅರಲ್ವಡಿಗ',
'ಅರಲ್ವೊಡೆಯ',
'ಅರವಂಟಿಗೆ',
'ಅರವಗಿತ್ತಿ',
'ಅರವಟಿಗೆ',
'ಅರವಟ್ಟಿಗೆ',
'ಅರವಡ್ಡಗಿ',
'ಅರವರಿಸು',
'ಅರವಸಿರು',
'ಅರವಿಂದಾಕ್ಷ',
'ಅರವಿಂದಾಸ್ಯೆ',
'ಅರವಿಂದಿನಿ',
'ಅರವಿವರ',
'ಅರವುರನೆ',
'ಅರವೆಂಡಿರ್',
'ಅರವೊಱಸು',
'ಅರಸಗಿಳಿ',
'ಅರಸತೂಕ',
'ಅರಸರಿಕೆ',
'ಅರಸಾಳಿಕೆ',
'ಅರಸಾಳ್ತನ',
'ಅರಸಿಕತೆ',
'ಅರಸಿತನ',
'ಅರಸಿನ ಹೂ',
'ಅರಸಿವಟ್ಟ',
'ಅರಸುಗಾಣ್',
'ಅರಸುಗಾದೆ',
'ಅರಸುಗಿಡು',
'ಅರಸುಗಿಳಿ',
'ಅರಸುಗುಣ',
'ಅರಸುಗುಲ',
'ಅರಸುಗೆಯ್',
'ಅರಸುಗೆಯ್ತ',
'ಅರಸುಗೆಯ್ಮೆ',
'ಅರಸುಗೊಡು',
'ಅರಸುಠೀವಿ',
'ಅರಸುತಕ್ಕು',
'ಅರಸುತನ',
'ಅರಸುತೂಕ',
'ಅರಸುತೋಟ',
'ಅರಸುಪೆಂಡಿ',
'ಅರಸುಬೀದಿ',
'ಅರಸುಮಗ',
'ಅರಸುಮರ್ಜಿ',
'ಅರಸುಮಿಗ',
'ಅರಸುವಣ್ಣು',
'ಅರಸುಹುಣ್ಣು',
'ಅರಸೂಳಿಗ',
'ಅರಸೊಡವೆ',
'ಅರಸೊತ್ತಿಗೆ',
'ಅರಸೊಲ್ಲಗೆ',
'ಅರಹೊಱಸು',
'ಅರಳಲರ್',
'ಅರಳಿಯೆಲೆ',
'ಅರಳುಗಣ್',
'ಅರಳುಗಣ್ಣು',
'ಅರಳುದದ್ದು',
'ಅರಳುವಾಸು',
'ಅರಳೆಬಾತು',
'ಅರಳೆಬೇನೆ',
'ಅರಳ್ಮಲರು',
'ಅರಾಜಕತೆ',
'ಅರಾತಿಸ್ಥಾನ',
'ಅರಾತಿಹರ',
'ಅರಾಬಿಗೋಡೆ',
'ಅರಾಷ್ಟ್ರಕತೆ',
'ಅರಾಷ್ಟ್ರಿಕತೆ',
'ಅರಿಕಟಕ',
'ಅರಿಕುಠಾರ',
'ಅರಿಕೆಗೊಳ್',
'ಅರಿಕೇಸರಿ',
'ಅರಿಟ್ಟನೇಮಿ',
'ಅರಿದಲ್ಲಣ',
'ಅರಿಧ್ವಜಿನಿ',
'ಅರಿಬಿರಿದು',
'ಅರಿಬಿರುದ',
'ಅರಿಭಾರಣೆ',
'ಅರಿಮರಿಸು',
'ಅರಿಯೆಪಡು',
'ಅರಿಲಭೇಡಿ',
'ಅರಿವಳಿಕ',
'ಅರಿವಳಿಕೆ',
'ಅರಿವಾಹಿನಿ',
'ಅರಿವಿದಾರ',
'ಅರಿವಿರಾಮ',
'ಅರಿವುಗೇಡಿ',
'ಅರಿವುಗೊಡು',
'ಅರಿವುದಪ್ಪು',
'ಅರಿವೆಕಾಲು',
'ಅರಿಷಡ್ವರ್ಗ',
'ಅರಿಷ್ಟಗೃಹ',
'ಅರಿಷ್ಟಗೇಡಿ',
'ಅರಿಷ್ಟನೇಮಿ',
'ಅರಿಷ್ಟಪತಿ',
'ಅರಿಷ್ಟಶಾಂತಿ',
'ಅರಿಷ್ಟಸದ್ಮ',
'ಅರಿಸಮಾಸ',
'ಅರಿಸಿನೆಣ್ಣೆ',
'ಅರಿಹಕೊಱ',
'ಅರುಂಪೆಱಲ',
'ಅರುಕಾಣಿಕೆ',
'ಅರುಗುಮಾಡು',
'ಅರುಟಾಳಕ',
'ಅರುಣಚ್ಛವಿ',
'ಅರುಣಜಲ',
'ಅರುಣಜಳ',
'ಅರುಣಮುಳ್ಳು',
'ಅರುಣಮೂಲ',
'ಅರುಣರಿಪು',
'ಅರುಣವಸ್ತ್ರ',
'ಅರುಣವಾರಿ',
'ಅರುಣಶಾಲಿ',
'ಅರುಣಾಧರ',
'ಅರುಣಾಧರೆ',
'ಅರುಣೀಭೂತ',
'ಅರುಣೋದಯ',
'ಅರುಣೋಪಲ',
'ಅರುಣೋಪಳ',
'ಅರುದಿಂಗಳ್',
'ಅರುಳಪಾಡು',
'ಅರುಳುದೋರು',
'ಅರೆಕಸುಬಿ',
'ಅರೆಕಾಲ್ಚಿಟ್ಟೆ',
'ಅರೆಗಣ್ಬೀಡು',
'ಅರೆಗಣ್ಮುಗಿ',
'ಅರೆಗವಚ',
'ಅರೆಗಳಿಗೆ',
'ಅರೆಗಳ್ತಲೆ',
'ಅರೆಗೞಿಗೆ',
'ಅರೆಗಾವುದ',
'ಅರೆಗುಂಡಿಗೆ',
'ಅರೆಗುರಿಕ',
'ಅರೆಗೆಲಸ',
'ಅರೆಗೆಲಸಿ',
'ಅರೆಗೊರಡು',
'ಅರೆಗೊರತೆ',
'ಅರೆತಿಳಿವು',
'ಅರೆದಪ್ಪಲು',
'ಅರೆದಿಂಗಳು',
'ಅರೆದುಱುಗು',
'ಅರೆನಿಮಿಷ',
'ಅರೆನೆರಳು',
'ಅರೆಪಲ್ದೆಱೆ',
'ಅರೆಪಿಸ್ಕಟೆ',
'ಅರೆಪುರುಷ',
'ಅರೆಪೆರೆಯ',
'ಅರೆಪೊರೆಕ',
'ಅರೆಬಳವಿ',
'ಅರೆಬೆತ್ತಲೆ',
'ಅರೆಮಡಲು',
'ಅರೆಮತ್ತರು',
'ಅರೆಮನಸ್ಸು',
'ಅರೆಮರಳು',
'ಅರೆಮರುಳ',
'ಅರೆಮರುಳು',
'ಅರೆಮರೆವು',
'ಅರೆಮಾತ್ರಿಕೆ',
'ಅರೆಮಾದಲ',
'ಅರೆಮಾನಿಸ',
'ಅರೆಮಾಫಲ',
'ಅರೆಮುಗಿಸು',
'ಅರೆಮುಗುಳ್',
'ಅರೆಮುಚ್ಚುಳ',
'ಅರೆಮುಸುಕು',
'ಅರೆಮೆಯ್ಯಳ್',
'ಅರೆಮೆಯ್ವೆಣ್',
'ಅರೆಮೈವೆಣ್ಣ',
'ಅರೆಯಂಗಡಿ',
'ಅರೆಯಹಣ',
'ಅರೆಯಳವಿ',
'ಅರೆಯಿರುಳು',
'ಅರೆಯೆಸಳ್',
'ಅರೆಯೆಸಳು',
'ಅರೆಯೊಡಲ್',
'ಅರೆಯೊಡಲು',
'ಅರೆವಣ್ಣಾಗು',
'ಅರೆವತಲು',
'ಅರೆವತ್ತರು',
'ಅರೆವತ್ತಲು',
'ಅರೆವರಿಸು',
'ಅರೆವಾನಿಸ',
'ಅರೆವಾಹಕ',
'ಅರೆವೆಣ್ತನ',
'ಅರೆವೆಣ್ಮೆಯ್ಯ',
'ಅರೆವೆಱೆಯ',
'ಅರೆವೊನಲ್',
'ಅರ್ಕಮಂಡಲ',
'ಅರ್ಕಮರೀಚಿ',
'ಅರ್ಕಮೆವಡು',
'ಅರ್ಕವರ್ಧಿನಿ',
'ಅರ್ಘ್ಯೋಪಚಾರ',
'ಅರ್ಚನಾವಿಧಿ',
'ಅರ್ಚನಾಸಕ್ತ',
'ಅರ್ಚನೆಗುಣಿ',
'ಅರ್ಚನೆಗೆಯ್',
'ಅರ್ಚಾಕ್ರೀಡನ',
'ಅರ್ಚಾವತಾರ',
'ಅರ್ಜುನಕುಜ',
'ಅರ್ಜುನಗಣ್ಣು',
'ಅರ್ಜುನತೇಜ',
'ಅರ್ಜುನಶ್ಲೋಕ',
'ಅರ್ಣವಜಾತ',
'ಅರ್ತಿಕಾಱಿಕೆ',
'ಅರ್ತಿಗಾಳಗ',
'ಅರ್ತಿಗಾಱಿಕೆ',
'ಅರ್ತಿಗೆಡಿಸು',
'ಅರ್ತಿಗೆಡುಕ',
'ಅರ್ತಿಗೆಡುಕಿ',
'ಅರ್ಥಖಂಡನ',
'ಅರ್ಥಗರ್ಭಿತ',
'ಅರ್ಥಗ್ರಹಣ',
'ಅರ್ಥಘಟನೆ',
'ಅರ್ಥದೂಷಕ',
'ಅರ್ಥದೂಷಣೆ',
'ಅರ್ಥದ್ರಷ್ಟೃತ್ವ',
'ಅರ್ಥನಿಶ್ಚಯ',
'ಅರ್ಥಪಂಚಕ',
'ಅರ್ಥಪ್ರಕೃತಿ',
'ಅರ್ಥಪ್ರತೀತಿ',
'ಅರ್ಥಪ್ರಧಾನ',
'ಅರ್ಥಪ್ರಯೋಗ',
'ಅರ್ಥಪ್ರಾಶಸ್ತ್ಯ',
'ಅರ್ಥರಹಿತ',
'ಅರ್ಥರಾಹಿತ್ಯ',
'ಅರ್ಥವಂತಿಕೆ',
'ಅರ್ಥವರ್ತನ',
'ಅರ್ಥವಾದನ',
'ಅರ್ಥವಿಷಯ',
'ಅರ್ಥವಿಹೀನ',
'ಅರ್ಥವಹೀನೆ',
'ಅರ್ಥಶಾಸ್ತ್ರಜ್ಞ',
'ಅರ್ಥಸಂಚಯ',
'ಅರ್ಥಸಂಪತ್ತು',
'ಅರ್ಥಹೀನತೆ',
'ಅರ್ಥಾನುರೂಪ',
'ಅರ್ಥಾಲಂಕಾರ',
'ಅರ್ಥಾಲಂಕೃತಿ',
'ಅರ್ಥಿನಿಚಯ',
'ಅರ್ಥಿಸಮಿತಿ',
'ಅರ್ಥೋಪಾರ್ಜನ',
'ಅರ್ಧಕಪ್ಪಟೆ',
'ಅರ್ಧಗಪ್ಪಡ',
'ಅರ್ಧಚಂದ್ರಕ',
'ಅರ್ಧಪ್ರಸ್ತಾರ',
'ಅರ್ಧಭ್ರಮಣ',
'ಅರ್ಧಮಾಗಧಿ',
'ಅರ್ಧರೇಚಿತ',
'ಅರ್ಧವಿರಾಮ',
'ಅರ್ಧವಿಸರ್ಗ',
'ಅರ್ಧಾಂಗವಾಯು',
'ಅರ್ಧಾವಲಿಕ',
'ಅರ್ಧಾವಲೀಕ',
'ಅರ್ಧಾವಶೇಷ',
'ಅರ್ಧಾವಳೀಕ',
'ಅರ್ಧೇಂದುಮೌಳಿ',
'ಅರ್ಪಣವಿಧಿ',
'ಅರ್ಪಿಸಿಕೊಳ್ಳು',
'ಅರ್ವವದನ',
'ಅರ್ಹಚ್ಛಾಸನ',
'ಅರ್ಹತಾಪತ್ರ',
'ಅರ್ಹತ್ಪ್ರತಿಮೆ',
'ಅರ್ಹದಾಲಯ',
'ಅಲಂಕರಣ',
'ಅಲಂಕರಿಷ್ಣು',
'ಅಲಂಕರಿಸು',
'ಅಲಂಘನೀಯ',
'ಅಲಂಘ್ಯತೇಜ',
'ಅಲಂಘ್ಯಬಲ',
'ಅಲಂಪುಗಾಱ',
'ಅಲಂಪುಗೊಳ್',
'ಅಲಂಪುವಡೆ',
'ಅಲಂಪುವೋಗು',
'ಅಲಂಬುನಾಡಿ',
'ಅಲಕಪಾದಿ',
'ಅಲಕುಹೊಡೆ',
'ಅಲಕ್ತರಸ',
'ಅಲಗಣಸು',
'ಅಲಗಣಿಸು',
'ಅಲಗುಗಣೆ',
'ಅಲಗುಧಾರೆ',
'ಅಲಗುಮಱೆ',
'ಅಲಗುವಿಡಿ',
'ಅಲಗೆಗಟ್ಟು',
'ಆಲಘುತರ',
'ಅಲಘುಭುಜ',
'ಅಲಪಲ್ಲವ',
'ಅಲಪುಗಾರ',
'ಅಲಭ್ಯತರ',
'ಅಲಭ್ಯಲಾಭ',
'ಅಲರುಗಣೆ',
'ಅಲರುರ್ಗೋಲ',
'ಅಲರುರ್ಗೋಲು',
'ಅಲರುವಕ್ಕಿ',
'ಅಲರುವಿಲ್',
'ಅಲರುವಿಲು',
'ಅಲರುವಿಲ್ಲ',
'ಅಲರುಸರ',
'ಅಲರೆಸಳ್',
'ಅಲರ್ಕೆಗುಂದು',
'ಅಲರ್ಗಣೆಯ',
'ಅಲರ್ಗೊಂಚಲ್',
'ಅಲರ್ದಾವರೆ',
'ಅಲರ್ದೊಂಗಲ್',
'ಅಲರ್ದೋರಣ',
'ಅಲರ್ಪೊಡೆಯ',
'ಅಲರ್ವಡಿಗ',
'ಅಲರ್ವಿಜ್ಜಣ',
'ಅಲರ್ವಿಜ್ಜಳ',
'ಅಲರ್ವೊಕ್ಕುಳ',
'ಅಲರ್ವೊಡೆಯ',
'ಅಲವರಲು',
'ಅಲವರಿಕೆ',
'ಅಲವರಿಸು',
'ಅಲವಲಿಕೆ',
'ಅಲವಲಿಸು',
'ಅಲಸಂದಿಗೆ',
'ಅಲಸಂಬೆಱು',
'ಅಲಸಗಾಮಿ',
'ಅಲಸತನ',
'ಅಲಸಭಾವ',
'ಅಲಸಯಾನ',
'ಅಲಸುಂಗಾಱ',
'ಅಲಸುಗಾಱ',
'ಅಲಸೇಂಗಿತೆ',
'ಅಲಾತಚಕ್ರ',
'ಅಲಾಬಿಹುಲಿ',
'ಅಲಾಬುಸ್ತನ',
'ಅಲಾಭಂ ಮಾಡು',
'ಅಲಾಲ್‍ಟೋಪಿ',
'ಅಲಾಳನಾಥ',
'ಅಲಿಕನೇತ್ರ',
'ಅಲಿಕುಂತಲೆ',
'ಅಲಿಕೇಕ್ಷಣ',
'ಅಲಿಪ್ತನೀತಿ',
'ಅಲಿಪ್ತರಾಷ್ಟ್ರ',
'ಅಲಿವರ್ಧಿನಿ',
'ಅಲುಗಾಡಿಸು',
'ಅಲೆಗೂದಲು',
'ಅಲೆದಾಡಿಸು',
'ಅಲೆಪಡಿಸು',
'ಅಲೋಕದೃಷ್ಟಿ',
'ಅಲೋಕಾಕಾಶ',
'ಅಲೌಕಿಕತೆ',
'ಅಲ್ಪಕಾಣಿಕೆ',
'ಅಲ್ಪಕಾಲಿಕ',
'ಅಲ್ಪದೂಷಿತ',
'ಅಲ್ಪಪ್ರಕಾಶ',
'ಅಲ್ಪವಸುಧೆ',
'ಅಲ್ಪವಿರಾಮ',
'ಅಲ್ಪವ್ಯಾಹಾರಿ',
'ಅಲ್ಪಸಂಖ್ಯಾತ',
'ಅಲ್ಪಸರಸಿ',
'ಅಲ್ಪಸುಖಾಶಿ',
'ಅಲ್ಪಾಕಬಟ್ಟೆ',
'ಅಲ್ಪಾಚಮನ',
'ಅಲ್ಪುಮನಸು',
'ಅಲ್ಪೋಪಜಲ್ಪ',
'ಅಲ್ಯೂಮಿನಿಯಂ',
'ಅಲ್ಲಕಲ್ಲೊಳ',
'ಅಲ್ಲಕಲ್ಲೋಲ',
'ಅಲ್ಲಳಿವೋಗು',
'ಅಲ್ಲಾಳನಾಥ',
'ಅಲ್ಲಿಗಾರಿಗ',
'ಅಲ್ಲಿತನಕ',
'ಅವಂತಿಸೋಮ',
'ಅವಕರುಣೆ',
'ಅವಕರ್ಣನ',
'ಅವಕರ್ಣಿಸು',
'ಅವಕಲನ',
'ಅವಕುಂಚಿತ',
'ಅವಕುಂಠನ',
'ಅವಕುಂಠಿತ',
'ಅವಕ್ಷೇಪಕ',
'ಅವಗಡಿಗ',
'ಅವಗಡಿಸು',
'ಅವಗಡೆಯ',
'ಅವಗಣನೆ',
'ಅವಗಣಿತ',
'ಅವಗಣಿಸು',
'ಅವಗಮನ',
'ಅವಗಯಿಸು',
'ಅವಗವಿಸು',
'ಅವಗಹನ',
'ಅವಗಹಿಸು',
'ಅವಗಳಿಯ',
'ಅವಗೞಿಯ',
'ಅವಗಾಹನ',
'ಅವಗಾಹನೆ',
'ಅವಗಾಹಿತ',
'ಅವಗಾಹಿಸು',
'ಅವಗುಂಠನ',
'ಅವಗುಂಠಿತ',
'ಅವಗುಂಠಿತೆ',
'ಅವಗೂಹನ',
'ಅವಗೂಹಿತ',
'ಅವಗ್ರಹಣ',
'ಅವಗ್ರಹಿಸು',
'ಅವಗ್ರಾಹಕ',
'ಅವಘಟಿಸು',
'ಅವಘಟ್ಟನ',
'ಅವಘಡಿಸು',
'ಅವಘಾಟಕ',
'ಅವಚಂಬಡು',
'ಅವಚನೀಯ',
'ಅವಚವಿಸು',
'ಅವಚೂರ್ಣಿತ',
'ಅವಚ್ಛೇದಕ',
'ಅವಜ್ಞೆಗೆಯ್',
'ಅವಟಯಿಸು',
'ಅವಟವಿಸು',
'ಅವತಂಸಕ',
'ಅವತಮಸ',
'ಅವತರಣ',
'ಅವತರಣೆ',
'ಅವತರಿಸು',
'ಅವತಾರಣ',
'ಅವತಾರಿಕ',
'ಅವತಾರಿಕೆ',
'ಅವತಾರಿತ',
'ಅವತಾರಿಸು',
'ಅವದರಿಸು',
'ಅವದಾರಣ',
'ಅವದೇಹಲಿ',
'ಅವಧರಿಸು',
'ಅವಧಾನಿಸು',
'ಅವಧಾರಣ',
'ಅವಧಾರಣೆ',
'ಅವಧಾರಿತ',
'ಅವಧಾರಿಸು',
'ಅವಧಾರ್ಯತೆ',
'ಅವಧಿಜ್ಞಾನ',
'ಅವಧಿಜ್ಞಾನಿ',
'ಅವಧಿಬೋಧ',
'ಅವಧಿವಿಮೆ',
'ಅವಧಿಸಾಲ',
'ಅವಧೀರಣ',
'ಅವಧೀರಿತ',
'ಅವಧೀರಿಸು',
'ಅವನಿಚಕ್ರ',
'ಅವನಿಜಾತ',
'ಅವನಿತಳ',
'ಅವನಿದ್ವಿಜ',
'ಅವನಿಧರ',
'ಅವನಿಪತಿ',
'ಅವನಿರುಹ',
'ಅವನಿಸುತ',
'ಅವನಿಸುತೆ',
'ಅವನಿಸುರ',
'ಅವನೀಜಾತ',
'ಅವನೀತಳ',
'ಅವನೀದ್ವಿಜ',
'ಅವನೀದೇವ',
'ಅವನೀನಾಥ',
'ಅವನೀಪಕ',
'ಅವನೀಪತಿ',
'ಅವನೀಭರ್ತೃ',
'ಅವನೀಭಾಗ',
'ಅವನೀರುಹ',
'ಅವನೀಶ್ವರ',
'ಅವನೀಸುರ',
'ಅವನೀಸ್ವಾಮಿ',
'ಅವಪುತ್ರಿಕ',
'ಅವಪುತ್ರಿಕೆ',
'ಅವಬೋಧನ',
'ಅವಮತಿಗೈ',
'ಅವಮನ್ನಣೆ',
'ಅವಮರ್ಯಾದೆ',
'ಅವಮಾನಿತ',
'ಅವಮಾನಿತೆ',
'ಅವಮಾನಿಸು',
'ಅವಮೋದರ್ಯ',
'ಅವಯವತ್ವ',
'ಅವರವರ್ಣ',
'ಅವರೂಪಿತ',
'ಅವರೆಬಳ್ಳಿ',
'ಅವರೆಹಾಳು',
'ಅವರೋಧಕ',
'ಅವರೋಧನ',
'ಅವರೋಧಿಸು',
'ಅವರೋಹಣ',
'ಅವರೋಹಿಣಿ',
'ಅವರ್ಗೀಕೃತ',
'ಅವರ್ಣನೀಯ',
'ಅವರ್ಣವಾದ',
'ಅವರ್ಣಿಕಾಮ',
'ಅವಲ್‍ಮೋಸುಂ',
'ಅವಲಂಬಕ',
'ಅವಲಂಬನ',
'ಅವಲಂಬನೆ',
'ಅವಲಂಬಿತ',
'ಅವಲಂಬಿಸು',
'ಅವಲಕ್ಷಣ',
'ಅವಲರಗು',
'ಅವಲುಭೂಮಿ',
'ಅವಲುಮೀನು',
'ಅವಲೋಕನ',
'ಅವಲೋಕಿನಿ',
'ಅವಲೋಕಿಸು',
'ಅವಶಕುನ',
'ಅವಶೇಷಾಂಗ',
'ಅವಶೋಷಕ',
'ಅವಶೋಷಣ',
'ಅವಶೋಷಿಸು',
'ಅವಶೋಷ್ಯತೆ',
'ಅವಶ್ಯಂಭಾವಿ',
'ಅವಶ್ರವಣ',
'ಅವಷ್ಟಂಭಿತ',
'ಅವಷ್ಟಂಭಿಸು',
'ಅವಸರಣ',
'ಅವಸರಾಯ್ತ',
'ಅವಸರಾಯ್ತಿ',
'ಅವಸರಾಯ್ತೆ',
'ಅವಸರಿಸು',
'ಅವಸರ್ಪಣ',
'ಅವಸರ್ಪಣಿ',
'ಅವಸಾದಕ',
'ಅವಸಾದನ',
'ಅವಸಾಯಕ',
'ಅವಸಾಯನ',
'ಅವಸ್ಕಂದನ',
'ಅವಸ್ತುಭೂತ',
'ಅವಸ್ಥಾಂತರ',
'ಅವಸ್ಥಾತ್ರಯ',
'ಅವಸ್ಥಾಬೇಧ',
'ಅವಸ್ಥಾರೂಪ',
'ಅವಸ್ಥೆಗೊಳ್',
'ಅವಹಸಿಸು',
'ಅವಹೇಳನ',
'ಅವಹೇಳಿಸು',
'ಅವಳಂಬಿಸು',
'ಅವಳಿಗಿರಿ',
'ಅವಳಿಚುಕ್ಕಿ',
'ಅವಳಿಮೊಲೆ',
'ಅವಳೋಕಿನಿ',
'ಅವಳೋಕಿಸು',
'ಅವಾಕ್ಕಾಗಿಸು',
'ಅವಾಗವಾಗ',
'ಅವಾಗ್ಗೋಚರ',
'ಅವಾಗ್ವಿಷಯ',
'ಅವಾಚಿದಿಕ್ಕು',
'ಅವಾಚೀದ್ವಾರ',
'ಅವಾಜುಮಾಡು',
'ಅವಾಮವೃತ್ತಿ',
'ಅವಾಯುಜೀವಿ',
'ಅವಾರಿಚ್ಛತ್ರ',
'ಅವಾರಿಸತ್ರ',
'ಅವಾರ್ಯವೀರ್ಯ',
'ಅವಾಸ್ತವತೆ',
'ಅವಾಸ್ತವಿಕ',
'ಅವಿಕತ್ಥನ',
'ಅವಿಕಸನ',
'ಅವಿಕಸಿತ',
'ಅವಿಕಾರಿತ್ವ',
'ಅವಿಗಲಿತ',
'ಅವಿಚಲಿತ',
'ಅವಿಚಳಿತ',
'ಅವಿಚಾರಣೆ',
'ಅವಿಚಾರಿತ',
'ಅವಿಚಾಲಿತ',
'ಅವಿಚ್ಛಿನ್ನತೆ',
'ಅವಿದೆಯಿಡು',
'ಅವಿದ್ಧಕರ್ಣ',
'ಅವಿದ್ಧಕರ್ಣಿ',
'ಅವಿದ್ಯಾತೀತ',
'ಅವಿದ್ಯಾದೃಷ್ಟಿ',
'ಅವಿದ್ಯಾವಂತ',
'ಅವಿದ್ಯಾವಂತೆ',
'ಅವಿಧೇಯತೆ',
'ಅವಿನಶ್ವರ',
'ಅವಿನಾಭಾವ',
'ಅವಿನಾಭಾವಿ',
'ಅವಿನಾಶಿತ್ವ',
'ಅವಿಪರ್ಯಯ',
'ಅವಿಭಜಿತ',
'ಅವಿಭಾಜ್ಯತೆ',
'ಅವಿರಲಿತ',
'ಅವಿರಳಾಸ್ತ್ರ',
'ಅವಿರಳಿತ',
'ಅವಿಲಂಬನ',
'ಅವಿಲಂಬಿತಂ',
'ಅವಿವಕ್ಷಿತ',
'ಅವಿವಾಹಿತ',
'ಅವಿವಾಹಿತೆ',
'ಅವಿವೇಕತೆ',
'ಅವಿವೇಚನೆ',
'ಅವಿಶೇಷಣ',
'ಅವಿಶ್ವಾಸಿನಿ',
'ಅವಿಸಂವಾದ',
'ಅವಿಳಂಬನ',
'ಅವಿಳಂಬಿತಂ',
'ಅವುಂಡಲಗೆ',
'ಅವುಂಡುಗರ್ಚು',
'ಅವುಟುಗುಂಡು',
'ಅವುಡಲಕೆ',
'ಅವುಡಲಕ್ಕೆ',
'ಅವುಡಲಗೆ',
'ಅವುಡಲಿಕೆ',
'ಅವುಡುಗಚ್ಚು',
'ಅವುಡುಗಡಿ',
'ಅವುಡುಗರ್ಚು',
'ಅವುತಣಿಸು',
'ಅವುತಾಳಿಸು',
'ಅವುದುಂಬರ',
'ಅವುಪಾಸನ',
'ಅವೈಚಾರಿಕ',
'ಅವೈಜ್ಞಾನಿಕ',
'ಅವೈಯಕ್ತಿಕ',
'ಅವ್ಯಕ್ತಧ್ವನಿ',
'ಅವ್ಯಕ್ತರವ',
'ಅವ್ಯಗ್ರಮನ',
'ಅವ್ಯಪಗಮ',
'ಅವ್ಯಪದೇಶ್ಯ',
'ಅವ್ಯಭಿಚಾರ',
'ಅವ್ಯಭಿಚಾರಿ',
'ಅವ್ಯಯತತ್ತ್ವ',
'ಅವ್ಯಯಪದ',
'ಅವ್ಯಯಲಿಂಗ',
'ಅವ್ಯಯಲೋಕ',
'ಅವ್ಯಯೀಭಾವ',
'ಅವ್ಯವಚ್ಛಿನ್ನ',
'ಅವ್ಯವಧಾನ',
'ಅವ್ಯವಸಾಯ',
'ಅವ್ಯವಸಾಯಿ',
'ಅವ್ಯವಸ್ಥಿತ',
'ಅವ್ಯವಹಾರ',
'ಅವ್ಯವಹಾರಿ',
'ಅವ್ಯವಹಾರ್ಯ',
'ಅವ್ಯವಹಿತ',
'ಅವ್ಯಸನಾಳಿ',
'ಅವ್ಯಾಕರಣ',
'ಅವ್ಯಾಪಕತೆ',
'ಅವ್ಯಾಬಾಧತ್ವ',
'ಅವ್ರತಯೋಗ',
'ಅವ್ವಲ್ ಪುಡಿ',
'ಅವ್ವಾಳಿತನ',
'ಅಶಂಕರತ್ವ',
'ಅಶಕ್ತತನ',
'ಅಶಕ್ತಯಾನ',
'ಅಶನಪರ್ಣಿ',
'ಅಶನವೃತ್ತಿ',
'ಅಶನಾಯಿತ',
'ಅಶನಿಪಾತ',
'ಅಶರೀರತ್ವ',
'ಅಶಲ್ಯಭಾವ',
'ಅಶಲ್ಯಭಾವೆ',
'ಅಶಾಂತಮತಿ',
'ಅಶಾಬ್ಧಿಕತೆ',
'ಅಶಾಶ್ವತತೆ',
'ಅಶಾಸ್ತ್ರೀಯತೆ',
'ಆಶಿವರವ',
'ಅಶುಭಕರ',
'ಅಶುಭಕರ್ಮ',
'ಅಶುಭಲೇಶ್ಯೆ',
'ಅಶುಭಸ್ಥಾಯಿ',
'ಅಶೋಕವನ',
'ಅಶೋಕವೃತ್ತ',
'ಅಶ್ಮಕಾರಕ',
'ಅಶ್ಮರೀಶಾಸ್ತ್ರ',
'ಅಶ್ಮಾರೋಪಣ',
'ಅಶ್ರುಕಾರಕ',
'ಅಶ್ರುತಪೂರ್ವ',
'ಅಶ್ರುತರ್ಪಣ',
'ಅಶ್ರುಪ್ರಸರ',
'ಅಶ್ರೇಯಸ್ಕರ',
'ಅಶ್ವಕರ್ಣಕ',
'ಅಶ್ವತ್ಥಕಟ್ಟೆ',
'ಅಶ್ವಪರೀಕ್ಷೆ',
'ಅಶ್ವಮೇಧೀಯ',
'ಅಶ್ವಲಕ್ಷಣ',
'ಅಶ್ವಶಿಕ್ಷಕ',
'ಅಶ್ವಹೃದಯ',
'ಅಶ್ವಾರೋಹಕ',
'ಅಶ್ವಾರೋಹಣ',
'ಅಶ್ವಿನೀಮತ',
'ಅಷಡಕ್ಷೀಣ',
'ಅಷರಫು಼ಲ್',
'ಅಷ್ಟಕಮಲ',
'ಅಷ್ಟಕರ್ತೃತ್ವ',
'ಅಷ್ಟದರಿದ್ರ',
'ಅಷ್ಟದಾರಿದ್ರ್ಯ',
'ಅಷ್ಟದಿಕ್ಪಾಲ',
'ಅಷ್ಟದಿಕ್ಪಾಳ',
'ಅಷ್ಟದಿಗ್ಗಜ',
'ಅಷ್ಟದಿಗ್ಜಯ',
'ಅಷ್ಟನವತಿ',
'ಅಷ್ಟಪ್ರಕೃತಿ',
'ಅಷ್ಟಪ್ರಮಾಣ',
'ಅಷ್ಟಫಲಕ',
'ಅಷ್ಟಬಂಧನ',
'ಅಷ್ಟಭೈರವ',
'ಅಷ್ಟಮಂಗಲ',
'ಅಷ್ಟಮಂಗಳ',
'ಅಷ್ಟಮಚಂದ್ರ',
'ಅಷ್ಟವಿಂಶತಿ',
'ಅಷ್ಟವಿಭೂತಿ',
'ಅಷ್ಟಷಟ್ಪದಿ',
'ಅಷ್ಟಾಂಗಯೋಗ',
'ಅಷ್ಟಾಂಗಯೋಗಿ',
'ಅಷ್ಟಾವಕ್ರತೆ',
'ಅಷ್ಟಾವಧಾನ',
'ಅಷ್ಟಾವಧಾನಿ',
'ಅಷ್ಟಾವರಣ',
'ಅಷ್ಟೋಪವಾಸ',
'ಅಷ್ಟೋಪವಾಸಿ',
'ಅಸಂಕಲಿತ',
'ಅಸಂಕಲ್ಪಿತ',
'ಅಸಂಕೀರ್ಣತೆ',
'ಅಸಂಖ್ಯಾತಕ',
'ಅಸಂಗತಕ',
'ಅಸಂಗತತೆ',
'ಅಸಂಗತೋಕ್ತಿ',
'ಅಸಂಘಟಿತ',
'ಅಸಂಚಲಿತ',
'ಅಸಂದಿಗ್ಧತೆ',
'ಅಸಂಪೂರ್ಣತೆ',
'ಅಸಂಪ್ರಜ್ಞಾತ',
'ಅಸಂಬದ್ಧತೆ',
'ಅಸಂಭಾವಿತ',
'ಅಸಂಭಾವಿತೆ',
'ಅಸಂಭಾವ್ಯತೆ',
'ಅಸಂವೇದನೆ',
'ಅಸಂವೇದ್ಯತೆ',
'ಅಸಂಸಕ್ತತೆ',
'ಅಸಂಸದೀಯ',
'ಅಸಕೞಿಹ',
'ಅಸಗವಕ್ಕಿ',
'ಅಸಗವನೆ',
'ಅಸಗವರಿ',
'ಅಸಗವೊಯ್',
'ಅಸಗವೊಯ್ಲು',
'ಅಸಗಹೊಯ್ಲು',
'ಅಸಗೆಡಿಸು',
'ಅಸಡ್ಡೆಮಾಡು',
'ಅಸತಿಕೇಳಿ',
'ಅಸತುದೆಱ',
'ಅಸತ್ಯವಾದಿ',
'ಅಸದಳಿಸು',
'ಅಸದಾಖ್ಯಾತಿ',
'ಅಸದಾಗ್ರಹ',
'ಅಸದೃಶತೆ',
'ಅಸದ್ವಿಷಯ',
'ಅಸನಪರ್ಣಿ',
'ಅಸಫಲತೆ',
'ಅಸಭ್ಯತನ',
'ಅಸಮಂಜಸ',
'ಅಸಮಕಸಿ',
'ಅಸಮಗ್ರತೆ',
'ಅಸಮಚ್ಛದ',
'ಅಸಮಜಸ',
'ಅಸಮಜಸೆ',
'ಅಸಮತೋಲ',
'ಅಸಮನಿಟ್ಟು',
'ಅಸಮನೇತ್ರ',
'ಅಸಮನ್ವಯ',
'ಅಸಮನ್ವಿತ',
'ಅಸಮಬಲ',
'ಅಸಮಬೀಜಿ',
'ಅಸಮರ್ಥತೆ',
'ಅಸಮರ್ಪಕ',
'ಅಸಮವಗೆ',
'ಅಸಮಶರ',
'ಅಸಮಸಂಖ್ಯೆ',
'ಅಸಮಾಂಗತೆ',
'ಅಸಮಾಂಗತ್ವ',
'ಅಸಮಾಂಬಕ',
'ಅಸಮಾಖ್ಯಾತ',
'ಅಸಮಾಧಾನ',
'ಅಸಮಾನತೆ',
'ಅಸಮಾಯುಧ',
'ಅಸಮಾಸತೆ',
'ಅಸಮಾಸ್ತ್ರತೆ',
'ಅಸಮೇಕ್ಷಣ',
'ಅಸಮ್ಮತಿಸು',
'ಅಸಲ್ ಮಾಲು',
'ಅಸಲುಕ್ರಯ',
'ಅಸಲುಪ್ರತಿ',
'ಅಸಲುಬೆಲೆ',
'ಅಸಲೇಕಾರ',
'ಅಸಹಕಾರ',
'ಅಸಹಕಾರಿ',
'ಅಸಹಜತೆ',
'ಅಸಹನೀಯ',
'ಅಸಹಾಯಕ',
'ಅಸಹಾಯತೆ',
'ಅಸಹಿಷ್ಣುತೆ',
'ಅಸಹ್ಯಂಬಡು',
'ಅಸಹ್ಯಕರ',
'ಅಸಹ್ಯತನ',
'ಅಸಹ್ಯಪಡು',
'ಅಸಹ್ಯಭಾವ',
'ಅಸಹ್ಯಮಾನ',
'ಅಸಹ್ಯವಾಗು',
'ಅಸಹ್ಯವೃತ್ತಿ',
'ಅಸಹ್ಯಾಭಾವ',
'ಅಸಾಂಘಿಕತೆ',
'ಅಸಾಕ್ಷಿಕತೆ',
'ಅಸಾತಕರ್ಮ',
'ಅಸಾದೃಶ್ಯತೆ',
'ಅಸಾಧಾರಣ',
'ಅಸಾಧುಜನ',
'ಅಸಾಧುಪದ',
'ಅಸಾಪೇಕ್ಷತೆ',
'ಅಸಾಪೇಕ್ಷಿಕ',
'ಅಸಾಮಂಜಸ್ಯ',
'ಅಸಾಮಾಜಿಕ',
'ಅಸಾಮಾನ್ಯತೆ',
'ಅಸಾವಧಾನ',
'ಅಸಾಹಿತ್ಯಿಕ',
'ಅಸಿತಕಂಠ',
'ಅಸಿತಖಗ',
'ಅಸಿತಗಲ',
'ಅಸಿತಗಳ',
'ಅಸಿತಗ್ರೀವ',
'ಅಸಿತಪಕ್ಷ',
'ಅಸಿತರಕ್ತ',
'ಅಸಿತರಸೆ',
'ಅಸಿತರುಚಿ',
'ಅಸಿತಾಂಬರ',
'ಅಸಿತೋತ್ಪಲ',
'ಅಸಿಧಾವಕ',
'ಅಸಿಧೇನುಕ',
'ಅಸಿಪುತ್ರಿಕೆ',
'ಅಸುಂಗೊಳಿಸು',
'ಅಸುಖಕರ',
'ಅಸುಗಾವಲು',
'ಅಸುಗೊಳಿಸು',
'ಅಸುಧಾರಣೆ',
'ಅಸುಭೃದ್ವರ್ಗ',
'ಅಸುರಗುರು',
'ಅಸುರರಿಪು',
'ಅಸುರವೈರಿ',
'ಅಸುರಹರ',
'ಅಸುರಾಂತಕ',
'ಅಸುರಾರಾತಿ',
'ಅಸುರುಸುರು',
'ಅಸುವಲ್ಲಭ',
'ಅಸುವಲ್ಲಭೆ',
'ಅಸುವಳಿಸು',
'ಅಸುವಾಹಕ',
'ಅಸುವೆಕಲ್ಲು',
'ಅಸುವೊಸರ್',
'ಅಸುಹೃತ್ಸೇನೆ',
'ಅಸೂಯೆಗೊಳ್',
'ಅಸೇಚನಕ',
'ಅಸ್ತವಿಳಾಸ',
'ಅಸ್ತವ್ಯಸ್ತತೆ',
'ಅಸ್ತಸಮಯ',
'ಅಸ್ತಿತ್ವವಾದ',
'ಅಸ್ತಿತ್ವವಾದಿ',
'ಅಸ್ತಿಮಿತತೆ',
'ಅಸ್ತ್ರದೇವತೆ',
'ಅಸ್ಥಾನಪದ',
'ಅಸ್ಥಾಯಿಸೂಚಿ',
'ಅಸ್ಥಿಪಂಜರ',
'ಅಸ್ಥಿವಿಜ್ಞಾನ',
'ಅಸ್ಥಿಸಂಗ್ರಹ',
'ಅಸ್ಥಿಸಂಚಯ',
'ಅಸ್ಪಷ್ಟಾಕ್ಷರ',
'ಅಸ್ಪೃಶ್ಯಕಾರು',
'ಅಸ್ಪೃಷ್ಟಪಾಂಶು',
'ಅಸ್ಫಟಿಕತೆ',
'ಅಸ್ಮೃತಾಹಾರ',
'ಅಸ್ವಚ್ಛಂದತೆ',
'ಅಸ್ವತಂತ್ರತೆ',
'ಅಸ್ವಪ್ನಾನಕ',
'ಅಸ್ವಭಾವಿಕ',
'ಅಸ್ವರವಿಧಿ',
'ಅಸ್ವಾಭಾವಿಕ',
'ಅಸ್ಸಾಮಿಗುಳ್ಳ',
'ಅಹಂಕರಣ',
'ಅಹಂಕರಿಸು',
'ಅಹಂಪೂರ್ವಿಕೆ',
'ಅಹಂಬ್ರಹ್ಮತ್ವ',
'ಅಹಂಮನ್ಯತೆ',
'ಅಹಂಮಮತೆ',
'ಅಹಂಮಮತ್ವ',
'ಅಹಗಿಹಗೆ',
'ಅಹನ್ಯಹನಿ',
'ಅಹಮಿಂದ್ರತ್ವ',
'ಅಹಲೆಕಾರ',
'ಅಹಲೇಕಾರ',
'ಅಹಲ್ಲಾದಿಸು',
'ಅಹಲ್ಲೆಕಾರ',
'ಅಹಿಂಸಾಧರ್ಮ',
'ಅಹಿಂಸಾರತ',
'ಅಹಿಂಸಾವಾದ',
'ಅಹಿಂಸಾವಾದಿ',
'ಅಹಿಂಸಾವ್ರತ',
'ಅಹಿಂಸಾಶುದ್ಧಿ',
'ಅಹಿಂಸಾಸಿದ್ಧಿ',
'ಅಹಿಕಂಕಣ',
'ಅಹಿಕಟಕ',
'ಅಹಿಕುಂಡಲ',
'ಅಹಿಗರಳ',
'ಅಹಿತಕರ',
'ಅಹಿತಕಾರ',
'ಅಹಿತಕಾರಿ',
'ಅಹಿತಕುಲ',
'ಅಹಿತುಂಡಿಕ',
'ಅಹಿಮಕರ',
'ಅಹಿಮದ್ಯತಿ',
'ಅಹಿಮರುಚಿ',
'ಅಹಿಸಾಯಕ',
'ಅಹುದೆನಿಸು',
'ಅಳಂಕರಣ',
'ಅಳಂಕರಿಸು',
'ಅಳಂಕೆಗೆಯ್',
'ಅಳಂಕೆಗೊಳ್',
'ಅಳಂಕೆವೆಱು',
'ಅಳಕಗುಚ್ಚ',
'ಅಳಕೋದ್ಭಾಸಿ',
'ಅಳತೆಕಡ್ಡಿ',
'ಅಳತೆಗೆಡು',
'ಅಳತೆಗೋಲು',
'ಅಳತೆಪಟ್ಟಿ',
'ಅಳತಬದ್ದು',
'ಅಳತೆಮಾಡು',
'ಅಳತೆಮಾಪು',
'ಅಳತೆವಡು',
'ಅಳಬಳವು',
'ಅಳಲುಗಿಚ್ಚು',
'ಅಳಲುಗಿತಿ',
'ಅಳಲುಪಾಡು',
'ಅಳಲೆಪಥೈ',
'ಅಳವಡಿಕೆ',
'ಅಳವಡಿಸು',
'ಅಳವಳಿಕೆ',
'ಅಳವಳಿಸು',
'ಅಳವೞಿಸು',
'ಅಳವಿಗಳಿ',
'ಅಳವಿಗೞಿ',
'ಅಳವಿಗಾಣ್',
'ಅಳವಿಗಿಡು',
'ಅಳವಿಗೆಡು',
'ಅಳವಿಗೊಡು',
'ಅಳವಿದಪ್ಪು',
'ಅಳವಿಯಱಿ',
'ಅಳವುಗಿಡು',
'ಅಳವುಗುಂದು',
'ಅಳವುಗೆಡು',
'ಅಳವುಗೊಡು',
'ಅಳವುಗೊಳ್',
'ಅಳವುಗೋಲು',
'ಅಳಿಕನೇತ್ರ',
'ಅಳಿಕಲಭ',
'ಅಳಿಕಾಂಬಕ',
'ಅಳಿಕಾಬಂಧ',
'ಅಳಿಕುಂತಳ',
'ಅಳಿಕುಂತಳೆ',
'ಅಳಿಕೇಕ್ಷಣ',
'ಅಳಿಗುರುಳ್',
'ಅಳಿಗುರುಳು',
'ಅಳಿಜುಳುಕು',
'ಅಳಿತೇಜಸ',
'ಅಳಿನೀರುತಿ',
'ಅಳಿಯಕಟ್ಟು',
'ಅಳಿಯಕ್ಕರ',
'ಅಳಿಯಡಕೆ',
'ಅಳಿಯತನ',
'ಅಳಿಯಮೈಯ',
'ಅಳಿಲುಮೀನು',
'ಅಳಿಲುಸೇವೆ',
'ಅಳಿವಣಿಗೆ',
'ಅಳಿವರೆಪ',
'ಅಳಿವೆಸರ್',
'ಅಳಿಸುವಿಕೆ',
'ಅಳುಕುಳಿತ',
'ಅಳುಬುರಕ',
'ಅಳುಬುರುಕ',
'ಅಳುಬುರುಕಿ',
'ಅಳುರ್ಕೆಗಿಡು',
'ಅಳುರ್ಕೆಗುಂದು',
'ಅಳುರ್ಕೆಗೊಳ್',
'ಅಳುರ್ಕೆವೆಱು',
'ಅಳ್ಕಜಂಬಡು',
'ಅಳ್ಕಜಕಾಱ',
'ಅಳ್ಕಜಗಾರ',
'ಅಳ್ತಿಗಾಳಗ',
'ಅಳ್ಳಂಕಗೊಳ್',
'ಅಳ್ಳಕಮಾಡು',
'ಅಳ್ಳಕವಾಗು',
'ಅಳ್ಳಿಱಿದಾಡು',
'ಅಳ್ಳೆಜೊಳ್ಳೆಯ',
'ಅಳ್ಳೆದೆಗೊಳ್ಳು',
'ಅಳ್ಳೆರ್ದೆತನ',
'ಅಳ್ಳೆರ್ದೆವಡೆ',
'ಅಱಂಬುಗಱೆ',
'ಅಱಂಬುನೀರ್',
'ಅಱಕೆಕಾಱ',
'ಅಱಕೆಗೆಡು',
'ಅಱಕೆಗೊಳ್',
'ಅಱಕೆವಡು',
'ಅಱಗಡಲ್',
'ಅಱಗುಲಿಗ',
'ಅಱಬುಗಱೆ',
'ಅಱವಂಟಗೆ',
'ಅಱವಂಟಿಗ',
'ಅಱವಂಟಿಗೆ',
'ಅಱವಟಿಕೆ',
'ಅಱವಟಿಗೆ',
'ಅಱವಟ್ಟುಗ',
'ಅಱವಟ್ಟಿಗೆ',
'ಅಱವಟ್ಟೆಗ',
'ಅಱವಱಗು',
'ಅಱಿಕೆಮಾಡು',
'ಅಱಿಕೆಗುಡು',
'ಅಱಿಕೆದಾಣ',
'ಅಱಿಕೆವಡೆ',
'ಅಱಿಗುಱುಪು',
'ಅಱಿತಂಗಿಡು',
'ಅಱಿಪವೇಳ್',
'ಅಱಿಮರುಳ್',
'ಅಱಿಮರುಳ',
'ಅಱಿಮರುಳು',
'ಅಱಿಯಮಿಕೆ',
'ಅಱಿವರುಳ್',
'ಅಱಿವರುಳ',
'ಅಱಿವಳಿಕ',
'ಅಱಿವಳಿಕೆ',
'ಅಱಿವಿಂಗೋಜ',
'ಅಱಿವುಗೆಡು',
'ಅಱಿವುಗೇಡಿ',
'ಅಱಿವುಮಱೆ',
'ಅಱುಂಬುನೀರ್',
'ಅಱುಗಂಡುಗ',
'ಅಱುಗಾವುದ',
'ಅಱುದಿಂಗಳ್',
'ಅಱುನೀರ್ವಳ್ಳ',
'ಅಱುಲುಗದ್ದೆ',
'ಅಱುವತ್ತಿಗ',
'ಅಱುಹಿರಿಯ',
'ಅಱುಹುಗೆಡು',
'ಅಱೆಯಮಾಲ್',
'ಅಱೆವೊನಲ್',
'ಅೞಲ್ ಪಾಡು',
'ಅೞಲುದೊರೆ',
'ಅೞವೞಿಸು',
'ಅೞಿಗಾಳೆಗ',
'ಅೞಿಗೆವದ್ದ',
'ಅೞಿಜಗಳ',
'ಅೞಿನೆಗೞ್ತೆ',
'ಅೞಿಮಾನಸ',
'ಅೞಿವಾನಿಸ',
'ಅೞಿವೆಸನ',
'ಅೞಿವೊಡಲು',
'ಅೞಿವೊಡಲ',
'ಅೞ್ಕಜಂಬಡು',
'ಅೞ್ಕಮೆವಡು',
'ಅೞ್ಕಮೆವೆಱು',
'ಆಂತರಂಗಿಕ',
'ಆಂತರದೀಕ್ಷೆ',
'ಆಂತರವಸ್ತು',
'ಆಂತರಸುಖ',
'ಆಂತರಾಳಿಕ',
'ಆಂತರಿಕತೆ',
'ಆಂದಿಪುನರು',
'ಆಂದಿಪುನಾರು',
'ಆಂದೋಲಕೇಲಿ',
'ಆಂದೋಳಾಯಿತ',
'ಆಕಂಠಪೂರ',
'ಆಕಂಠಪೂರ್ತಿ',
'ಆಕಂಪಂಗೊಳ್',
'ಆಕರಗ್ರಂಥ',
'ಆಕರಿಸಣ',
'ಆಕರುಷಣ',
'ಆಕರುಷಿಸು',
'ಆಕರುಸಣ',
'ಆಕರುಸನ',
'ಆಕರ್ಣಕೃಷ್ಟ',
'ಆಕರ್ಣನೀಯ',
'ಆಕರ್ಣನೇತ್ರೆ',
'ಆಕರ್ಣಪೂರ',
'ಆಕರ್ಷಣೀಯ',
'ಆಕಲಕರೆ',
'ಆಕಸ್ಮಿಕತೆ',
'ಆಕಾರ್ ಬಂದ್',
'ಆಕಾರಂಗಿಡು',
'ಆಕಾರಂಬಡೆ',
'ಆಕಾರಗುಪ್ತಿ',
'ಆಕಾರಗೆಡು',
'ಆಕಾರಗೊಳ್ಳು',
'ಆಕಾರಪೂಜೆ',
'ಆಕಾರವಾದ',
'ಆಕಾರಶುದ್ಧಿ',
'ಆಕಾಶಕಾಯ',
'ಆಕಾಶಕೇಶ',
'ಆಕಾಶಗಂಗೆ',
'ಆಕಾಶಗಾಮಿ',
'ಆಕಾಶಗೂಡು',
'ಆಕಾಶದೀಪ',
'ಆಕಾಶದ್ರವ್ಯ',
'ಆಕಾಶನೀಲಿ',
'ಆಕಾಶಪುಷ್ಪ',
'ಆಕಾಶಬಳ್ಳಿ',
'ಆಕಾಶಬಾಣ',
'ಆಕಾಶಬಿಲ್ಲು',
'ಆಕಾಶಬುಟ್ಟಿ',
'ಆಕಾಶಮಧ್ಯೆ',
'ಆಕಾಶಯಾನ',
'ಆಕಾಶರಾಯ',
'ಆಕಾಶಲಿಂಗ',
'ಆಕಾಶವಲೆ',
'ಆಕಾಶವಲ್ಲಿ',
'ಆಕಾಶವಾಣಿ',
'ಆಕಾಶವೀಣೆ',
'ಆಕಾಶಸ್ವರ',
'ಆಕಾಸವನಿ',
'ಅಕುಳಿಗೊಳ್',
'ಆಕುಳೀಕೃತ',
'ಆಕೃತಿಗಿಡು',
'ಆಕೃತಿದಾಳ್',
'ಆಕೃತಿವಂತ',
'ಆಕೃಷ್ಟಿಮಂತ್ರ',
'ಆಕೆಯವಾಳ',
'ಆಕೆವಾಳತೆ',
'ಆಕೆವಾಳಿಕೆ',
'ಆಕ್ರಂದತ್ಸ್ವರ',
'ಆಕ್ರೋಶಮಾಡು',
'ಆಕ್ರೋಶಗೊಳ್',
'ಆಕ್ರೋಶಧ್ವನಿ',
'ಆಕ್ಷೇಪಂಗೆಯ್',
'ಆಕ್ಷೇಪಣೀಯ',
'ಆಖುವಾಹನ',
'ಆಖೈರುಸಾಲು',
'ಆಖ್ಯಾತಪದ',
'ಆಖ್ಯಾನಜಾತಿ',
'ಆಗಂತುಕತೆ',
'ಆಗಡಕಾಱ',
'ಆಗಮಪ್ರಿಯ',
'ಆಗಮಭಾಷೆ',
'ಆಗಮವಿತ್ತ್ವ',
'ಆಗಮವಿದ',
'ಆಗಮವಿದೆ',
'ಆಗಮವೇದಿ',
'ಆಗಮಸಂಧಿ',
'ಆಗಮಸ್ಥಾನ',
'ಆಗಮಾಡಿಕೆ',
'ಆಗರ್ಭಸುಖಿ',
'ಆಗಸಗೂಡು',
'ಆಗಸದಬ್ಬೆ',
'ಆಗಸದೊಱೆ',
'ಆಗಸಬೊಚ್ಚ',
'ಆಗಸಮಣಿ',
'ಆಗಸವಕ್ಕಿ',
'ಆಗಸವಟ್ಟೆ',
'ಆಗಸವಣಿ',
'ಆಗಸವೊಚ್ಚ',
'ಆಗಾತ್ಯಗಾರ',
'ಆಗಾಧಜಲ',
'ಆಗಾಮಿಕರ್ಮ',
'ಆಗಾಮಿಕಾರ್ಯ',
'ಆಗ್ನೇಯಸ್ನಾನ',
'ಆಗ್ರಹಂಗೆಯ್',
'ಆಗ್ರಹಮಾಗು',
'ಆಗ್ರಹಮಾಡು',
'ಆಗ್ರಹಾಯಣ',
'ಆಗ್ರಹಾಯಣಿ',
'ಆಗ್ರಹಾರಿಕ',
'ಆಘಾಟಶುದ್ಧಿ',
'ಆಚಂದ್ರತಾರಂ',
'ಆಚಂದ್ರಸ್ಥಾಯಿ',
'ಆಚಂದ್ರಾಯತ',
'ಆಚಮನಕ',
'ಆಚಮನೀಯ',
'ಆಚಾರಕಲಿ',
'ಆಚಾರಗೈಸು',
'ಆಚಾರತ್ರಯ',
'ಆಚಾರದೂರ',
'ಆಚಾರದ್ರೋಹ',
'ಆಚಾರಬಾಬು',
'ಆಚಾರಭ್ರಷ್ಟ',
'ಆಚಾರಭ್ರಷ್ಟೆ',
'ಆಚಾರಲಾಜ',
'ಆಚಾರಲಿಂಗ',
'ಆಚಾರವಂತ',
'ಆಚಾರವಂತೆ',
'ಆಚಾರವತಿ',
'ಆಚಾರಶಾಸ್ತ್ರ',
'ಆಚಾರಶೀಲ',
'ಆಚಾರಶೀಲೆ',
'ಆಚಾರ್ಯಕೃತಿ',
'ಆಚಾರ್ಯದೂರ',
'ಆಚಾರ್ಯನಿಷ್ಠೆ',
'ಆಚಾರ್ಯಮುಷ್ಠಿ',
'ಆಚೆನಾಡದ್ದು',
'ಆಚೆನಾಡಿದ್ದು',
'ಆಚ್ಛಾದ್ಯಮಾನ',
'ಆಚ್ಛುರಿತಕ',
'ಆಜನ್ಮಸಿದ್ಧ',
'ಆಜವಂಜವ',
'ಆಜಾನುಬಾಹು',
'ಆಜಿಲಂಪಟ',
'ಆಜೂಜುವಾರಿ',
'ಆಜ್ಞಾಧಾರಕ',
'ಆಜ್ಞಾಧಾರಕಿ',
'ಆಜ್ಞಾಧಾರಿಣಿ',
'ಆಜ್ಞಾನುವರ್ತಿ',
'ಆಜ್ಞಾಪಾಲಕ',
'ಆಜ್ಞಾಪಾಲಕಿ',
'ಆಜ್ಞಾಪಾಲನೆ',
'ಆಜ್ಞಾಲಂಘನ',
'ಆಜ್ಞಾವಚನ',
'ಆಜ್ಞಾವಿಚಯ',
'ಆಜ್ಞಾವಿಧೇಯ',
'ಆಜ್ಞೆನಿರ್ಣಯ',
'ಆಜ್ಞೆಪಾಲಿಸು',
'ಆಜ್ಯಾಭಿಷೇಕ',
'ಆಟಕಪಟಿ',
'ಆಟಕಾಱಿಕೆ',
'ಆಟಗಾರಿಕೆ',
'ಆಟರೂಷಕ',
'ಆಟಾಳಿತನ',
'ಆಟಿಕಳಂಜ',
'ಆಟಿಕಳಂಜೆ',
'ಆಟಿಕೊಡಂಜಿ',
'ಆಟೋಪಂಬೆಱು',
'ಆಡಂಬುಬಳ್ಳಿ',
'ಆಡಪಗಡಿ',
'ಆಡಳಿತಾಂಗ',
'ಆಡಿಕೆಗಾಣ',
'ಆಡಿಕೆಮಾತು',
'ಆಡುಕಿವಿಗ',
'ಆಣತಿಗೆಯ್',
'ಆಣತಿಗೆಯ್ಸು',
'ಆಣತಿಗೇಳು',
'ಆಣತಿಗೊಳ್',
'ಆಣತಿಮಾಡು',
'ಆಣತಿಯಿಡು',
'ಆಣತಿವಡೆ',
'ಆಣವಮಲ',
'ಆಣವಲಕ್ಕಿ',
'ಆಣಿಕಾಱಿಕೆ',
'ಆಣಿಗೆಗೊಳ್',
'ಆಣಿಚೆಲ್ವಿಕೆ',
'ಆಣಿಮೌಕ್ತಿಕ',
'ಆಣೆಗೋಸಣೆ',
'ಆಣೆಘೋಷಣೆ',
'ಆಣೆಬಿಡಿಸು',
'ಆಣೆಮಾಡಿಸು',
'ಆಣೆಯಿಡಿಸು',
'ಆಣೆವತ್ತಳೆ',
'ಆಣೆಹಾಕಿಸು',
'ಆತಂಕಗೊಳ್ಳು',
'ಆತಂಕಪಡು',
'ಆತಂಕವಾದ',
'ಆತಂಕವಾದಿ',
'ಆತತಕೀರ್ತಿ',
'ಆತತಪುಣ್ಯ',
'ಆತತಮತಿ',
'ಆತಪಪಕ್ಷಿ',
'ಆತಪಯೋಗ',
'ಆತಪಸ್ನಾತ',
'ಆತಿಥ್ಯಕಾರ',
'ಆತುಮಸುಖಿ',
'ಆತುರಗಾರ',
'ಆತುರಗಾರ್ತಿ',
'ಆತುರಪಡು',
'ಆತೋದ್ಯಘೋಷ',
'ಆತೋದ್ಯನಾದ',
'ಆತೋದ್ಯರವ',
'ಆತೋದ್ಯಸ್ವನ',
'ಆತ್ತಸಂಗರ',
'ಆತ್ಮಕೃಷಿಕ',
'ಆತ್ಮಕೌಶಲ',
'ಆತ್ಮಗೌರವ',
'ಆತ್ಮಘಾತಕ',
'ಆತ್ಮಘಾತಕಿ',
'ಆತ್ಮಘಾತುಕ',
'ಆತ್ಮಚರಿತೆ',
'ಆತ್ಮಚರಿತ್ರೆ',
'ಆತ್ಮಚೈತನ್ಯ',
'ಆತ್ಮತತ್ತ್ವಜ್ಞ',
'ಆತ್ಮತೇಜಸ್ಸು',
'ಆತ್ಮದಂಡನೆ',
'ಆತ್ಮದರ್ಶನ',
'ಆತ್ಮದರ್ಶನಿ',
'ಆತ್ಮದೇವತೆ',
'ಆತ್ಮನಿಕ್ಷೇಪ',
'ಆತ್ಮನಿರ್ಣಯ',
'ಆತ್ಮನಿರ್ಮಿತ',
'ಆತ್ಮನಿರ್ವಾಣ',
'ಆತ್ಮನೇಪದ',
'ಆತ್ಮನೈವೇದ್ಯ',
'ಆತ್ಮಪಾಲನ',
'ಆತ್ಮಪುತ್ರಕ',
'ಆತ್ಮಪ್ರತಿಷ್ಠೆ',
'ಆತ್ಮಪ್ರತ್ಯಯ',
'ಆತ್ಮಪ್ರವಾದ',
'ಆತ್ಮಪ್ರಶಂಸೆ',
'ಆತ್ಮರಕ್ಷಕ',
'ಆತ್ಮರಕ್ಷಣೆ',
'ಆತ್ಮವಂಚಕ',
'ಆತ್ಮವಂಚಕಿ',
'ಆತ್ಮವಂಚನೆ',
'ಆತ್ಮವಲ್ಲಭ',
'ಆತ್ಮವಿಕಾಸ',
'ಆತ್ಮವಿಜ್ಞಾನ',
'ಆತ್ಮವಿಮರ್ಶೆ',
'ಆತ್ಮವಿಲಾಸ',
'ಆತ್ಮವಿಶ್ವಾಸ',
'ಆತ್ಮವೀಕ್ಷಣೆ',
'ಆತ್ಮವೃತ್ತಾಂತ',
'ಆತ್ಮಶೋಧನೆ',
'ಆತ್ಮಶ್ಲಾಘನೆ',
'ಆತ್ಮಸಂಗತ್ಯ',
'ಆತ್ಮಸಂಗಾತ',
'ಆತ್ಮಸಂತುಷ್ಟ',
'ಆತ್ಮಸಂತುಷ್ಟಿ',
'ಆತ್ಮಸಂತುಷ್ಟೆ',
'ಆತ್ಮಸಂತೋಷ',
'ಆತ್ಮಸಂಧಾನ',
'ಆತ್ಮಸಂಯಮ',
'ಆತ್ಮಸಂಯಮಿ',
'ಆತ್ಮಸಂಸ್ಕಾರ',
'ಆತ್ಮಸಾಕ್ಷಿಕ',
'ಆತ್ಮಸಾಧಕ',
'ಆತ್ಮಸಾಧಕಿ',
'ಆತ್ಮಸಾಧನೆ',
'ಆತ್ಮಸ್ತವಕ',
'ಆತ್ಮಾನುಭವ',
'ಆತ್ಮಾನುಭೂತ',
'ಆತ್ಮಾನುಭೂತಿ',
'ಆತ್ಮಾಭಿಮಾನ',
'ಆತ್ಮಾಭಿಮಾನಿ',
'ಆತ್ಮೀಕರಣ',
'ಆತ್ಮೀಕರಿಸು',
'ಆತ್ಮೀಯಬಲ',
'ಆತ್ಮೋಪಾಸನೆ',
'ಆತ್ರೇಯಬಿಂಬ',
'ಆದಕಾರಣ',
'ಆದದ್ದರಿಂದ',
'ಆದರಮಾಡು',
'ಆದರಂಗೆಯ್',
'ಆದರಂಗೊಳ್',
'ಆದರಣೀಯ',
'ಆದರ್ಶಕತೆ',
'ಆದರ್ಶನೀಯ',
'ಆದರ್ಶಪ್ರಾಯ',
'ಆದರ್ಶರಾಜ್ಯ',
'ಆದರ್ಶವಾದ',
'ಆದರ್ಶವಾದಿ',
'ಆದಾಯಕರ',
'ಆದಿ ಅಯನ',
'ಆದಿಕಮಠ',
'ಆದಿಕಲ್ಯಾಣ',
'ಆದಿಕಾರಣ',
'ಆದಿಜಿನೇಂದ್ರ',
'ಆದಿತೀರ್ಥೇಶ',
'ಆದಿತ್ಯಪ್ರಭ',
'ಆದಿತ್ಯಬಲ',
'ಆದಿತ್ಯಭಕ್ತ',
'ಆದಿತ್ಯಭಕ್ತಿ',
'ಆದಿತ್ಯವಾರ',
'ಆದಿತ್ಯಸುತ',
'ಆದಿತ್ಯಾಲಯ',
'ಆದಿದ್ರಾವಿಡ',
'ಆದಿಪಂಚಮ',
'ಆದಿಪುರಾಣ',
'ಆದಿಪುರುಷ',
'ಆದಿಪ್ರರೂಪ',
'ಆದಿಪ್ರಸಾದಿ',
'ಆದಿಭೈರವ',
'ಆದಿಮಾನವ',
'ಆದಿಮೂರುತಿ',
'ಆದಿಯಮಕ',
'ಆದಿವರಾಳಿ',
'ಆದಿವಾಸರ',
'ಆದಿವಾಸಿತ್ವ',
'ಆದಿವಿಭಕ್ತಿ',
'ಆದುದರಿಂದ',
'ಆದೇಯರೂಪ',
'ಆದೇಶಂಗೆಯ್',
'ಆದೇಶಗಣ',
'ಆದೇಶಪತ್ರ',
'ಆದೇಶಸಂಧಿ',
'ಆದ್ಯಂತಪ್ರಾಸ',
'ಆದ್ಯಂ ಪುರುಷ',
'ಆದ್ಯಕರ್ತವ್ಯ',
'ಆದ್ಯನುಪ್ರಾಸ',
'ಆದ್ಯಪುರುಷ',
'ಆದ್ಯಮನುಷ್ಯ',
'ಆದ್ಯಮಾಷಕ',
'ಆಧಾರಗೆಯ್',
'ಆಧಾರಚಕ್ರ',
'ಆಧಾರಪತ್ರ',
'ಆಧಾರಭೂತ',
'ಆಧಾರರೇಖೆ',
'ಆಧಾರಲಿಂಗ',
'ಆಧಾರವರ್ಷ',
'ಆಧಾರವಾಕ್ಯ',
'ಆಧಾರಷಡ್ಜ',
'ಆಧಾರಸ್ತಂಭ',
'ಆಧಿಕಾರಿಕ',
'ಆಧಿದೈವಿಕ',
'ಆಧಿಭೌತಿಕ',
'ಆಧೀನಚಿತ್ತ',
'ಆಧೀನಚಿತ್ತೆ',
'ಆಧುನಿಕತೆ',
'ಆಧ್ಯಾತ್ಮಿಕತೆ',
'ಆನಂದಂಗಿಡು',
'ಆನಂದಂಬಡು',
'ಆನಂದಂಬೆಱು',
'ಆನಂದಕರ',
'ಆನಂದಜಲ',
'ಆನಂದಜಳ',
'ಆನಂದನೃತ್ಯ',
'ಆನಂದಪಡು',
'ಆನಂದಪುರ',
'ಆನಂದಭಾಷ್ಪ',
'ಆನಂದಭಕ್ತಿ',
'ಆನಂದಭೇರಿ',
'ಆನಂದಮಣಿ',
'ಆನಂದಮಯ',
'ಆನಂದಮಾರ್ಗ',
'ಆನಂದಮಾರ್ಗಿ',
'ಆನಂದಮುಖಿ',
'ಆನಂದಮೂರ್ತಿ',
'ಆನಂದಲೀಲ',
'ಆನಂದವಡೆ',
'ಆನಂದವಸ್ತ್ರ',
'ಆನಂದವಾಡು',
'ಆನಂದವಾದ',
'ಆನಂದವಾದಿ',
'ಆನಂದವಿಡಿ',
'ಆನಂದವೃಕ್ಷ',
'ಆನಂದವೇಱು',
'ಆನಂದಾಗರ',
'ಆನಂದೋನ್ಮತ್ತ',
'ಆನಂದೋನ್ಮತ್ತೆ',
'ಆನಕಧ್ವನಿ',
'ಆನಕಧ್ವಾನ',
'ಆನಕರವ',
'ಆನತಜನ',
'ಆನತಮುಖಿ',
'ಆನತಮೂರ್ಧ',
'ಆನನುಕೂಲ್ಯ',
'ಆನಮ್ರಾಮರ',
'ಆನಿಕೆಗಂಭ',
'ಆನುಂ ಪಾಸಱೆ',
'ಆನುಕೂಲಿಕ',
'ಆನುಕ್ರಮಿಕ',
'ಆನುಪಾತಿಕ',
'ಆನುಪೂರ್ವಕ',
'ಆನುಭಾವಿಕ',
'ಆನುಮಾನಿಕ',
'ಆನುಲೋಮಿಕ',
'ಆನುವಂಶಿಕ',
'ಆನುವಂಶಿಕೆ',
'ಆನುವಂಶೀಯ',
'ಆನುಶ್ರಾವಿಕ',
'ಆನುಷಂಗಿಕ',
'ಆನೂಪಕ್ಷೇತ್ರ',
'ಆನೆಕತ್ತಾಳೆ',
'ಆನೆಗದಗು',
'ಆನೆಗದುಗ',
'ಆನೆಗದುಗು',
'ಆನೆಗದುಪು',
'ಆನೆಗವರ್ತೆ',
'ಆನೆಗೈದೊಡೆ',
'ಆನೆಗೊಬ್ಬರ',
'ಆನೆಗೊಬ್ಬಳಿ',
'ಆನೆ ಚೊಗಟೆ',
'ಆನೆದಲೆಗ',
'ಆನೆದುಗ್ಗಾಣಿ',
'ಆನೆದೊಗಲು',
'ಆನೆನೆಗ್ಗಲು',
'ಆನೆನೆಗ್ಗಿಲ್',
'ಆನೆನೆಗ್ಗಿಲು',
'ಆನೆಬಾಗಿಲು',
'ಆನೆಬೊಬ್ಬುಲಿ',
'ಆನೆಮಲ್ಲಿಗೆ',
'ಆನೆಮಾಲತಿ',
'ಆನೆಮುಸುಡು',
'ಆನೆಮೊಸಳೆ',
'ಆನೆಯಪೊನ್',
'ಆನೆಯಪೊನ್ನು',
'ಆನೆಯಮದ',
'ಆನೆಯಸೇಸೆ',
'ಆನೆಯುಂಡಿಗೆ',
'ಆನೆರಕ್ಕಸ',
'ಆನೆವಿನ್ನಾಣಿ',
'ಆನೆವೆಡಂಗ',
'ಆನೆಸವಾರಿ',
'ಆನೆಸೊಗಟೆ',
'ಆನೆಹುಣಿಸೆ',
'ಆಪಗವಾರಿ',
'ಆಪಗಾಸ್ನಾನ',
'ಆಪತ್ಕಾಲಿಕ',
'ಆಪತ್ತುವಡು',
'ಆಪತ್ಸಂನ್ಯಾಸ',
'ಆಪದ್ಬಾಂಧವ',
'ಆಪದ್ವಿನೀತ',
'ಆಪನ್ನಸತ್ತ್ವೆ',
'ಆಪಮಿತ್ಯಕ',
'ಆಪರಾಧಿಕ',
'ಆಪರಾಹ್ನಿಕ',
'ಆಪಾತರೂಪ',
'ಆಪಾದನೀಯ',
'ಆಪೆಯಸೊಪ್ಪು',
'ಆಪ್ತ ಸಿಬ್ಬಂದಿ',
'ಆಪ್ತಾಬಗಿರಿ',
'ಆಪ್ತಾಲೋಚನೆ',
'ಆಪ್ತಾಳೋಚನೆ',
'ಆಪ್ಯಾಯಮಾನ',
'ಆಪ್ರಪದೀನ',
'ಆಪ್ಲವವ್ರತಿ',
'ಆಪ್ಲುತವ್ರತಿ',
'ಆಪ್ಸರೇಯತೆ',
'ಆಫಾಲಗುಲ್ಫಂ',
'ಆಬರುಗೇಡಿ',
'ಆಬಾಲವೃದ್ಧ',
'ಆಭಾಸವಾಗು',
'ಆಭಿಚಾರಕ',
'ಆಭಿರಾಮಿಕ',
'ಆಭೀರಪಲ್ಲಿ',
'ಆಭ್ಯಂತರಿತ',
'ಆಮಗಂಧಿಕ',
'ಆಮದುದಾರ',
'ಆಮದು ಸುಂಕ',
'ಆಮರಣಾಂತ',
'ಆಮಾತಿಸಾರ',
'ಆಮುಷ್ಯಾಯಣ',
'ಆಮೂಲಚೂಲ',
'ಆಮೂಲಚೂಲಂ',
'ಆಮೋದಖೇಲ',
'ಆಮೋದಚಿತ್ತ',
'ಆಮೋದಚಿತ್ತೆ',
'ಆಮ್ಲಕಾರಕ',
'ಆಮ್ಲಜನಕ',
'ಆಮ್ಲದಾಡಿಮ',
'ಆಮ್ಲಮಾಪಕ',
'ಆಮ್ಲಮಾಪನ',
'ಆಮ್ಲಲೋಣಿಕೆ',
'ಆಮ್ಲವೇತಸ',
'ಆಮ್ಲಸಹಿಷ್ಣು',
'ಆಮ್ಲೀಕರಣ',
'ಆಮ್ಲೀಕರಿಸು',
'ಆಮ್ಲೀಯವರ್ಣ',
'ಆಯಃಶೂಲಿಕ',
'ಆಯಗಾಣಿಸು',
'ಆಯತಂಗೆಯ್',
'ಆಯತಕರ',
'ಆಯತಕಾಲ',
'ಆಯತಕೀರ್ತಿ',
'ಆಯತತರ',
'ಆಯತಬಾಹು',
'ಆಯತಲಿಂಗ',
'ಆಯತವಡೆ',
'ಆಯತವಱಿ',
'ಆಯತವೃತ್ತ',
'ಆಯತವೆಱು',
'ಆಯತವೇಳೆ',
'ಆಯತಾಂಬಕ',
'ಆಯತಾಂಬಕಿ',
'ಆಯತಿಗಿಡು',
'ಆಯತಿಗೆಡು',
'ಆಯತಿವೆಱು',
'ಆಯತ್ತಂ ಮಾಡು',
'ಆಯತ್ತವೇಳೆ',
'ಆಯಶ್ಶೂಲಿಕ',
'ಆಯಶ್ಶೂಳಿಕ',
'ಆಯಸಂಗೊಳ್',
'ಆಯಸಂಬಡು',
'ಆಯಸಗೊಳ್',
'ಆಯಸಪಿಂಡ',
'ಆಯಸಬಡು',
'ಆಯಸವಡು',
'ಆಯಾತದಾರ',
'ಆಯಾಸಂಗೊಳ್',
'ಆಯಾಸಂಬಡು',
'ಆಯಾಸಕರ',
'ಆಯಾಸಗೊಳ್',
'ಆಯಾಸಪಡು',
'ಆಯಿಲಂಗೊಳ್',
'ಆಯುಃಪ್ರಮಾಣ',
'ಆಯುಧಧಾರಿ',
'ಆಯುಧಪಾಣಿ',
'ಆಯುಧಪೂಜೆ',
'ಆಯುಧಶಾಲೆ',
'ಆಯುಧಾಗಾರ',
'ಆಯುಧಾಧ್ಯಕ್ಷ',
'ಆಯುರವಧಿ',
'ಆಯುರ್ವರ್ಧಕ',
'ಆಯುರ್ವರ್ಧನ',
'ಆಯುವರ್ಧಕ',
'ಆಯುವರ್ಧನ',
'ಆಯುಷ್ಯರೇಖೆ',
'ಆಯುಷ್ಯಹೋಮ',
'ಆಯುಸ್ಥಾಪನ',
'ಆಯೋಗಂಗೊಳ್',
'ಆಯ್ಕುಳಿಗೊಳ್',
'ಆಯ್ಕುಳಿಮಾತು',
'ಆಯ್ಕುಳಿರಾಯ',
'ಆಯ್ಕುಳಿವಾತು',
'ಆರಂಬಂಗೆಯ್',
'ಆರಂಬಗಾರ',
'ಆರಂಭಂಗೆಯ್',
'ಆರಂಭಕಾಱ',
'ಆರಂಭಗಾರ',
'ಆರಂಭತ್ಯಾಗ',
'ಆರಂಭಶೂರ',
'ಆರಂಭೋತ್ಸವ',
'ಆರಕ್ತೇಕ್ಷಣ',
'ಆರಕ್ತೇಕ್ಷಣೆ',
'ಆರಟಗೊಳ್',
'ಆರಟಿಗೊಳ್',
'ಆರಡಿಗೊಳ್',
'ಆರಣ ಅಡ್ಡ',
'ಆರತಕ್ಷತೆ',
'ಆರತಿಹಾಡು',
'ಆರನಾಲಕ',
'ಆರನಾಳಕ',
'ಆರನಾಳಾಂಬು',
'ಆರಾಧನೀಯ',
'ಆರಾಮಕುರ್ಚಿ',
'ಆರಿದ್ರಪುಳು',
'ಆರಿನಿಪಟ್ಟಿ',
'ಆರೀಯವೇಗ',
'ಆರುಕೆಲಸ',
'ಆರುಗೆಲಸ',
'ಆರೂಢಕೋಪ',
'ಆರೂಢತಪ',
'ಆರೂಢಪಥ',
'ಆರೂಢಯೋಗಿ',
'ಆರೂಢವಿದ್ಯೆ',
'ಆರೂಢಸ್ಥಲ',
'ಆರೆಗಟ್ಟಿಗೆ',
'ಆರೈಕೆಗೊಳ್',
'ಆರೋಗ್ಯಕರ',
'ಆರೋಗ್ಯಕೇಂದ್ರ',
'ಆರೋಗ್ಯಗೃಹ',
'ಆರೋಗ್ಯಚಕ್ರ',
'ಆರೋಗ್ಯಧಾಮ',
'ಆರೋಗ್ಯಭಾಗ್ಯ',
'ಆರೋಗ್ಯಮಣಿ',
'ಆರೋಗ್ಯವಂತ',
'ಆರೋಗ್ಯವಂತೆ',
'ಆರೋಗ್ಯಶಾಸ್ತ್ರ',
'ಆರೋಗ್ಯಸ್ಥಾನ',
'ಆರೋಪ ಪಟ್ಟಿ',
'ಆರೋಹಿಗ್ರಹ',
'ಆರೋಹಿದಶೆ',
'ಆರೋಹಿಪಕ್ಷಿ',
'ಆರ್ಜನಶೀಲ',
'ಆರ್ಜನಶೀಲೆ',
'ಆರ್ಜವವೃತ್ತಿ',
'ಆರ್ಥಿಕಕ್ಷೋಭೆ',
'ಆರ್ಥಿಕನ್ಯಾಯ',
'ಆರ್ದ್ರತಾಭಾವ',
'ಆರ್ದ್ರದೇಶಕ',
'ಆರ್ದ್ರಹೃದಯ',
'ಆರ್ದ್ರಾಂತರಂಗ',
'ಆರ್ದ್ರೀಕರಣ',
'ಆರ್ದ್ರೀಕರಿಸು',
'ಆರ್ಭಟಂಗೊಡು',
'ಆರ್ಯಚರಿತ',
'ಆರ್ಯಷಟ್ಕರ್ಮ',
'ಆರ್ಯಸಮಾಜ',
'ಆರ್ಯೀಕರಣ',
'ಆರ್ಷದರ್ಶನ',
'ಆರ್ಷಸಂಪತ್ತು',
'ಆರ್ಷಸಂಹಿತೆ',
'ಆರ್ಷೇಯಕಲ್ಪ',
'ಆರ್ಷೇಯಯುಗ',
'ಆರ್ಹಂತ್ಯಕ್ರಿಯೆ',
'ಆರ್ಹಂತ್ಯಧರ್ಮ',
'ಆರ್ಹಂತ್ಯಲಕ್ಷ್ಮಿ',
'ಆರ್ಹತಮತ',
'ಆಲಂಕಾರಿಕ',
'ಆಲಂಬಮಾನ',
'ಆಲವಂಟಿಗೆ',
'ಆಲವಟ್ಟಲು',
'ಆಲವಟ್ಟಿಗ',
'ಆಲವಟ್ಟಿಗೆ',
'ಆಲವಣ್ದುಟಿ',
'ಆಲಸ್ಯಗಾಣ್',
'ಆಲಸ್ಯಗಾರ',
'ಆಲಾ ಅದನಾ',
'ಆಲಾನಸ್ತಂಭ',
'ಆಲಿಂಗನಾಂಗ',
'ಆಲಿನೀರ್ವನಿ',
'ಆಲಿಯಕ್ದಾರು',
'ಆಲಿವಟ್ಟಲು',
'ಆಲಿವರಲ್',
'ಆಲಿವರಲು',
'ಆಲಿವೆಳಗ',
'ಆಲೆಬಕೋರಾ',
'ಆಲೇಖ್ಯಗತೆ',
'ಆಲೇಖ್ಯಯಂತ್ರ',
'ಆಲೇಪಂಗೆಯ್',
'ಆಲೋಲೇಕ್ಷಣ',
'ಆಲೋಲೇಕ್ಷಣೆ',
'ಆವಗೆಗಲ',
'ಆವಗೆಗಿಚ್ಚು',
'ಆವಗೆಯುರಿ',
'ಆವಡಿಗೊಳ್',
'ಆವಯವಿಕ',
'ಆವರಣಿಸು',
'ಆವರೆಗೊಂಬು',
'ಆವರ್ತತೇಜ',
'ಆವರ್ತನಾಭಿ',
'ಆವರ್ತಮಾನ',
'ಆವರ್ತಯುಕ್ತ',
'ಆವರ್ತ ವೆಚ್ಚ',
'ಆವಲಲರ್',
'ಆವಶ್ಯಕತೆ',
'ಆವಾಹನೆಗೈ',
'ಆವಿರ್ಭವಿಸು',
'ಆವಿರ್ಭಾವಿತ',
'ಆವಿಷ್ಕರಣ',
'ಆವಿಷ್ಕರಿಸು',
'ಆವಿಷ್ಟಲಿಂಗ',
'ಆವುಂಡಲಗೆ',
'ಆವುಂಡಲಿಗೆ',
'ಆವುಗೆಕಿಚ್ಚು',
'ಆವುಗೆಗಿಚ್ಚು',
'ಆವುಗೆಮನೆ',
'ಆವುಜಕಾಱ',
'ಆವುಜಿಗಿತಿ',
'ಆವುಟಕಟ್ಟು',
'ಆವುಟಕಾರ',
'ಆವುಟಕಾತಿ',
'ಆವೃತಬೀಜಿ',
'ಆವೇಶಭಕ್ತಿ',
'ಆಶಂಕೆಗೊಳ್',
'ಆಶಾಕಿರಣ',
'ಆಶಾಗೋಪುರ',
'ಆಶಾಜನಕ',
'ಆಶಾದಾಯಕ',
'ಆಶಾದಾಯಕಿ',
'ಆಶಾರದನಿ',
'ಆಶಾವಲಯ',
'ಆಶಾವಲ್ಲಭ',
'ಆಶಾವಸನ',
'ಆಶಾವಾದಿನಿ',
'ಆಶಾವಾರಣ',
'ಆಶಿನಮರ',
'ಆಶಿಷಂಬೀಱು',
'ಆಶೀರ್ನಿನಾದ',
'ಆಶೀರ್ವಚನ',
'ಆಶೀರ್ವದಿಸು',
'ಆಶುಕವಿತೆ',
'ಆಶುಕವಿತ್ವ',
'ಆಶುಗಮನ',
'ಆಶುಗಸಖ',
'ಆಶುಗಾಶನ',
'ಆಶುಗಾಸನ',
'ಆಶುಭಾಷಣ',
'ಆಶುರಚನೆ',
'ಆಶುಶುಕ್ಷಣಿ',
'ಆಶೆಬುರುಕ',
'ಆಶೆಬುರುಕಿ',
'ಆಶೆವಂತಳು',
'ಆಶ್ಚರ್ಯಂಬಡು',
'ಆಶ್ಚರ್ಯಕರ',
'ಆಶ್ಚರ್ಯಪಡು',
'ಆಶ್ಚರ್ಯಭೂತ',
'ಆಶ್ರಮಧರ್ಮ',
'ಆಶ್ರಮವಾಸಿ',
'ಆಶ್ರಯಕೊಡು',
'ಆಶ್ರಯಗೊಳ್ಳು',
'ಆಶ್ರಯದಾತ',
'ಆಶ್ರಯದಾತೃ',
'ಆಶ್ರಯದಾತೆ',
'ಆಶ್ರಯವೆರೆ',
'ಆಶ್ರಯಸ್ಥಾನ',
'ಆಶ್ರಿತರಾಜ್ಯ',
'ಆಶ್ವಾಸನೀಯ',
'ಆಶ್ವಾಸನೆಯೀ',
'ಆಷಾಢಭೂತಿ',
'ಆಷಾಢಮಾಸ',
'ಆಷಾಢಿಬೇರು',
'ಆಷೂರ್ ಖಾನಾ',
'ಆಸಂದಧೀರ',
'ಆಸಕ್ತಿಹೀನ',
'ಆಸಕ್ತಿಹೀನೆ',
'ಆಸನಂದೋಱು',
'ಆಸನಕಂಪ',
'ಆಸನಕುರು',
'ಆಸನಕ್ರಮ',
'ಆಸನದ್ವಾರ',
'ಆಸನಬಾವು',
'ಆಸನಯೋಗ',
'ಆಸನರೋಗ',
'ಆಸನವ್ರಣ',
'ಅಸನಾಂಕುರ',
'ಆಸನ್ನಕಾಲ',
'ಆಸನ್ನಗತ',
'ಆಸನ್ನಭವ್ಯ',
'ಆಸನ್ನಭವ್ಯೆ',
'ಆಸನ್ನಮೃತ್ಯು',
'ಆಸನ್ನವರ್ತಿ',
'ಆಸಫಗಿರಿ',
'ಆಸಬದಾರ',
'ಆಸರಗೇಡಿ',
'ಆಸರೆಕಟ್ಟು',
'ಆಸರೆಗೊಡು',
'ಆಸರೆಗೊಳ್ಳು',
'ಆಸರೆಗೋಡೆ',
'ಆಸವಾಸಕ್ತ',
'ಆಸವಾಸಕ್ತಿ',
'ಆಸಾರಚಾರಿ',
'ಆಸೀನನಾಗು',
'ಆಸುತೀವಲ',
'ಆಸುತೀವಳ',
'ಆಸುರಕರ್ಮ',
'ಆಸುರತರ',
'ಆಸುರೀವೃತ್ತಿ',
'ಆಸೆಕದಿರು',
'ಆಸೆಗೊಳಿಸು',
'ಆಸೆತೋರಿಸು',
'ಆಸೆದೋಱಿಸು',
'ಆಸೆಬಡಕ',
'ಆಸೆಬಡಿಕ',
'ಆಸೆಬಡಿಕಿ',
'ಆಸೆಬುರಕ',
'ಆಸೆಬುರಕಿ',
'ಆಸೆಬುರುಕ',
'ಆಸೆಬುರುಕಿ',
'ಆಸೆವಡಿಸು',
'ಆಸೇಚನಕ',
'ಆಸ್ತಿ ತೆರಿಗೆ',
'ಆಸ್ಥಾನಕವಿ',
'ಆಸ್ಥಾನಗೇಹ',
'ಆಸ್ಯಲಾಂಗಲ',
'ಆಸ್ಯವೈರಸ್ಯ',
'ಆಸ್ರವತತ್ವ',
'ಆಸ್ವಾದನಂಗೈ',
'ಆಹರಂಗೊಡು',
'ಆಹವಂಗೊಡು',
'ಆಹವಕ್ಷಮ',
'ಆಹವಧರ್ಮ',
'ಆಹವನೀಯ',
'ಆಹವಭೀಮ',
'ಆಹವಭೀಷ್ಮ',
'ಆಹವಮಲ್ಲ',
'ಆಹವರಂಗ',
'ಆಹವಶೌಂಡ',
'ಆಹವಸಿಂಹ',
'ಆಹಾರಂಗೊಳ್',
'ಆಹಾರಂಬಡೆ',
'ಆಹಾರಗೊಳ್',
'ಆಹಾರದಾನ',
'ಆಹಾರಧಾನಿ',
'ಆಹಾರನಾಳ',
'ಆಹಾರಮುಖ',
'ಆಹಾರಶಾಸ್ತ್ರ',
'ಆಹಾರಶುದ್ಧಿ',
'ಆಹಿತುಂಡಿಕ',
'ಆಹಿರಿತೋಡಿ',
'ಆಹಿರಿನಾಟ',
'ಆಹುಗೊಳಿಸು',
'ಆಹುತಿಕೊಡು',
'ಆಹುತಿಗೆಯ್',
'ಆಹುತಿಗೊಡು',
'ಆಹುತಿಗೊಳ್',
'ಆಹೂತಿಗೆಯ್',
'ಆಹ್ಲಾದಕರ',
'ಆಹ್ಲಾದಕಾರಿ',
'ಆಹ್ವಯವಿಡಿ',
'ಆಹ್ವಾನಂಗೆಯ್',
'ಆಳಕಟ್ಟಲೆ',
'ಆಳತಿಗೂಡು',
'ಆಳವರಿಸು',
'ಆಳಾನಸ್ತಂಭ',
'ಆಳಾಪಂಗೆಯ್',
'ಆಳಾಪಂದೋಱು',
'ಆಳಿಕೆಗೊಡು',
'ಆಳಿಗೊಳಿಸು',
'ಆಳಿಗೊಳುಹ',
'ಆಳುಗೆಲಸ',
'ಆಳುಗೊಳಿಸು',
'ಆಳುಮಕ್ಕಳು',
'ಆಳುಮನುಷ್ಯ',
'ಆಳೋಚಂಗೆಯ್',
'ಆಳ್ತನಂಗೊಳ್',
'ಆಳ್ವೆಸಂಗೆಯ್',
'ಆಳ್ವೆಸಂಗೊಳ್',
'ಆಳ್ವೆಸಕೆಯ್',
'ಆಳ್ವೆಸಕೆಯ್ಸು',
'ಆಳ್ವೆಸಗೆಯ್',
'ಆಳ್ವೆಸಗೆಯ್ಸು',
'ಆಱಡಿಕಾಱ',
'ಆಱಡಿಗಳ್ಳ',
'ಆಱಡಿಗೆಯ್',
'ಆಱಡಿಗೊಳ್',
'ಆಱಡಿತನ',
'ಆಱಡಿದಾಣ',
'ಆಱಡಿದುಂಬಿ',
'ಆಱಡಿವಣ್ಣ',
'ಆಱಡಿವಱಿ',
'ಆಱಡಿವಿಂಡು',
'ಆಱಡಿವೆಣ್',
'ಆಱಡಿವೆದೆ',
'ಆಱುಗಂಡುಗ',
'ಇಂಕಾರ್ ಮಾಡು',
'ಇಂಗದಿರುಣಿ',
'ಇಂಗದಿರ್ಗಲ್',
'ಇಂಗದಿರ್ವಕ್ಕಿ',
'ಇಂಗದಿರ್ವಟ್ಟು',
'ಇಂಗಳಂಗೊಳ್',
'ಇಂಗಳಗಣ್',
'ಇಂಗಳಗಣ್ಣ',
'ಇಂಗಳಗಣ್ಣು',
'ಇಂಗಳಗಾಣ್',
'ಇಂಗಳಮಗ',
'ಇಂಗಳಾಂಬಕ',
'ಇಂಗಿತಕೊಡು',
'ಇಂಗಿತಗುಡು',
'ಇಂಗಿತಜ್ಞತೆ',
'ಇಂಗಿತಜ್ಞಾನ',
'ಇಂಗಿತವ್ಯಂಗ್ಯ',
'ಇಂಗಿತಾಕಾರ',
'ಇಂಗೋಲವಿಲ್',
'ಇಂಗ್ರೇಜಿಬಿಘೆ',
'ಇಂಚರಂಗಱೆ',
'ಇಂಜನಮಳೆ',
'ಇಂಜನೀಯರು',
'ಇಂಜಾನುಮಳೆ',
'ಇಂಟರ್‍ವಲ್ಲು',
'ಇಂಡೆಗೊಳಿಸು',
'ಇಂದನಕಂದ',
'ಇಂದಿರದಿಕ್ಕು',
'ಇಂದಿರವಿಲ್',
'ಇಂದಿರಾಜಾಸ್ತ್ರ',
'ಇಂದಿರಾವರ',
'ಇಂದುಜನಕ',
'ಇಂದುದೀಧಿತಿ',
'ಇಂದುಮಂಡಲ',
'ಇಂದುಮಂಡಳ',
'ಇಂದುಲಾಂಛನ',
'ಇಂದುಶೇಖರ',
'ಇಂದ್ರಜಾಲಿಕ',
'ಇಂದ್ರಜಾಲಿಗ',
'ಇಂದ್ರದಿಕ್ತಟ',
'ಇಂದ್ರನಿಲಯ',
'ಇಂದ್ರಲುಪ್ತಕ',
'ಇಂದ್ರವರ್ತುಲ',
'ಇಂದ್ರವಾರುಣಿ',
'ಇಂದ್ರಸರಿತ್ತು',
'ಇಂದ್ರಸಿಂಧುರ',
'ಇಂದ್ರಸುರಸ',
'ಇಂದ್ರಾನೋಕಹ',
'ಇಂದ್ರಾಭಿಷೇಕ',
'ಇಂದ್ರಾವತಾರ',
'ಇಂದ್ರಾವರಜ',
'ಇಂದ್ರಾವರುಣಿ',
'ಇಂದ್ರಿಯಗ್ರಾಮ',
'ಇಂದ್ರಿಯಗ್ರಾಹ್ಯ',
'ಇಂದ್ರಿಯಜೀವಿ',
'ಇಂದ್ರಿಯದೃಢ',
'ಇಂದ್ರಿಯದೃಢೆ',
'ಇಂದ್ರಿಯಲೌಲ್ಯ',
'ಇಂದ್ರಿಯಸುಪ್ತಿ',
'ಇಂದ್ರಿಯಸೌಖ್ಯ',
'ಇಂದ್ರಿಯಾತೀತ',
'ಇಂದ್ರೋಪಪಾತ',
'ಇಂಧನಕೋಶ',
'ಇಂಧನತೈಲ',
'ಇಂಪಣಮಾಡು',
'ಇಂಪುಗರ್ಪುರ',
'ಇಂಪುದಂಬುಲ',
'ಇಂಬುಗೊಳಿಸು',
'ಇಂಬುವೆರಸು',
'ಇಕ್ಕಡಿಗಡಿ',
'ಇಕ್ಕಡಿಗಳೆ',
'ಇಕ್ಕಡಿವಡೆ',
'ಇಕ್ಕಣ್ಣಿವಲೆ',
'ಇಕ್ಕುಮುಡಿಗೆ',
'ಇಕ್ಕುಳವಾಯ್',
'ಇಕ್ಕುಳಿಗೊಳ್',
'ಇಕ್ಕೇರಿವರ',
'ಇಕ್ಷುಕಾರ್ಮುಕ',
'ಇಕ್ಷುಕೋದಂಡ',
'ಇಕ್ಷುಸಮುದ್ರ',
'ಇಕ್ಷುಸಾಗರ',
'ಇಚ್ಚಕವಾಡು',
'ಇಚ್ಚಕತನ',
'ಇಚ್ಚಟವಣೆ',
'ಇಚ್ಚಡಿಬಟ್ಟೆ',
'ಇಚ್ಚಾರಿಗೊಳ್',
'ಇಚ್ಚಿಪ್ಪುಮೀನು',
'ಇಚ್ಚೆಗತನ',
'ಇಚ್ಚೆಗೊಳಿಸು',
'ಇಚ್ಚೆಟವಣೆ',
'ಇಚ್ಛಾಕಾರಗೈ',
'ಇಚ್ಛಾನುಸಾರ',
'ಇಚ್ಛಾಪೂರ್ವಕ',
'ಇಚ್ಛಾಪ್ರಸವ',
'ಇಚ್ಛಾಭೋಜನ',
'ಇಚ್ಛಾಮರಣ',
'ಇಚ್ಛಾಮರಣಿ',
'ಇಚ್ಛಾವಿಘಾತಿ',
'ಇಚ್ಛಾಸ್ವಾತಂತ್ರ್ಯ',
'ಇಜಾರಾದಾರ',
'ಇಟುಕುಮನೆ',
'ಇಟ್ಟಣಂಬಡೆ',
'ಇಟ್ಟವಟ್ಟಲ್',
'ಇಟ್ಟಳಂಗಟ್ಟು',
'ಇಟ್ಟಳಂಗೊಳ್',
'ಇಟ್ಟಳಮಿಡು',
'ಇಟ್ಟಿಗೆಗೂಡು',
'ಇಟ್ಟಿಗೆಗೊಳ್',
'ಇಟ್ಟೆಡೆಯಾಗು',
'ಇಟ್ಟೆಡೆಗೊಳ್',
'ಇಡಾವತ್ಸರ',
'ಇಡಿಕಿಚ್ಚಡಿ',
'ಇಡಿಕೆಗೊಳ್',
'ಇಡಿಗತ್ತಲೆ',
'ಇಡಿದಡಕು',
'ಇಡುಕುಂಗಬ್ಬ',
'ಇಡುಕುಂಬನಿ',
'ಇಡುಕುದೆರೆ',
'ಇಡುಗಟ್ಟಿಗೆ',
'ಇಡುಗತ್ತಲೆ',
'ಇಡುಗವಣೆ',
'ಇಡುಮುಡುಕು',
'ಇಣಿಕುಗಂಡಿ',
'ಇಣುಕಿಣುಕು',
'ಇಣುಕುದೀಪ',
'ಇಣುಕುನೋಟ',
'ಇತರಾಲಯ',
'ಇತರೇತರ',
'ಇತಿಕರ್ತವ್ಯ',
'ಇತಿಶ್ರೀಹಾಡು',
'ಇತಿಹಾಸಜ್ಞ',
'ಇತ್ತಂಡವಾದ',
'ಇತ್ತಂಡವಾದಿ',
'ಇತ್ತರಂಗೊಳ್',
'ಇತ್ತರಂಬಿಡಿ',
'ಇತ್ತರವಿಡಿ',
'ಇತ್ತಲೆಗುಂಡಿ',
'ಇತ್ತಲೆಮುಂಡ',
'ಇದಾನೀಂತನ',
'ಇದಿರಂನಡೆ',
'ಇದಿರಂನೋಡು',
'ಇದಿರಂಬಗೆ',
'ಇದಿರಂಬರ್',
'ಇದಿರಮನಂ',
'ಇದಿರುಹಱಿ',
'ಇದಿರೆನಡೆ',
'ಇದಿರುಗಟ್ಟು',
'ಇದಿರುಗಾಣ್',
'ಇದಿರುಗಾಣು',
'ಇದಿರುಗೊಳ್',
'ಇದಿರುತ್ತರ',
'ಇದಿರುದಂಡು',
'ಇದಿರುದಲೆ',
'ಇದಿರುನುಡಿ',
'ಇದಿರುಪಾಯ್',
'ಇದಿರುಮಲೆ',
'ಇದಿರುವರ್',
'ಇದಿರುಹೆಣ್ಣು',
'ಇದಿರೆಯ್ತರ್',
'ಇದಿರೆೞ್ತರ್',
'ಇದಿರೇಳುಹ',
'ಇದಿರೇಱಿಸು',
'ಇದಿರೊಡ್ಡಣ',
'ಇದಿರ್ಗೊಳಿಸು',
'ಇದಿರ್ಚಿದಿರ್ಚು',
'ಇದುಕಾರಣ',
'ಇದುನಿಮಿತ್ತಂ',
'ಇದುರುವಾದಿ',
'ಇದ್ದಲಿಯೊಲೆ',
'ಇನಕಿರಣ',
'ಇನಮಂಡಲ',
'ಇನವರ್ಧಿನಿ',
'ಇನಾಂಜಮೀನು',
'ಇನಾಮದಾರ',
'ಇನಾಮುದಾರ',
'ಇನಿಗಡಲ್',
'ಇನಿಗದಿರ್',
'ಇನಿಗನ್ನಡ',
'ಇನಿದುಗಱೆ',
'ಇನಿಯವಳ್',
'ಇನಿವಿರಿದು',
'ಇನಿವಿರಿಯ',
'ಇನಿವಿರಿದುಂ',
'ಇನಿಸಾನುಮಂ',
'ಇನಿಸಾನುವಂ',
'ಇನುಮಡಿಸು',
'ಇನ್ನೆವರೆಗಂ',
'ಇಪಿಕಾಕುನ್ಹ',
'ಇಪ್ಪಿಡಿವಿಡಿ',
'ಇಪ್ಪೆಗೞವೆ',
'ಇಪ್ಪೋಳುಗಳೆ',
'ಇಬ್ಬಂದಿಕಾರ',
'ಇಬ್ಬಂದಿತನ',
'ಇಬ್ಬಂದಿಯಾಟ',
'ಇಬ್ಬಗಿಯಾಗು',
'ಇಬ್ಬಗಿಗಳೆ',
'ಇಬ್ಬಗೆಗೊಳ್',
'ಇಬ್ಬಗೆಯಾಗು',
'ಇಬ್ಬಡಗಲು',
'ಇಬ್ಬತ್ತಿದೀಪ',
'ಇಬ್ಬಾಯಿಖಡ್ಗ',
'ಇಬ್ಬಾಯಿಹಕ್ಕಿ',
'ಇಬ್ರೀಯಭಾಷೆ',
'ಇಭಗಮನೆ',
'ಇಭವಾಹನ',
'ಇಭಶಿಕ್ಷಕ',
'ಇಭಾರೋಹಕ',
'ಇಮ್ಮೈಗನ್ನಡಿ',
'ಇಮ್ಮೈಗಾಣಿಕೆ',
'ಇಮ್ಮೊನೆದೋರು',
'ಇರಕುಮರ',
'ಇರವಂತಿಗೆ',
'ಇರಸರಿಕೆ',
'ಇರಿಕೆಹಾಕು',
'ಇರಿತಕಾರ',
'ಇರುವಂತಿಗೆ',
'ಇರುವತಿಗೆ',
'ಇರುವಳಿಕೆ',
'ಇರುವೆಬಾಕ',
'ಇರುವೆತಿನಿ',
'ಇರುಸರಿಕೆ',
'ಇರುಳರಸ',
'ಇರುಳಹಕ್ಕಿ',
'ಇರುಳುಕಳ್ಳ',
'ಇರುಳುಗಣ್ಣ',
'ಇರುಳುಗಣ್ಣು',
'ಇರುಳುಗನ್ನ',
'ಇರುಳುಗಳ್ಳ',
'ಇರುಳುಡುಪು',
'ಇರುಳುಮೆಯ್',
'ಇರುಳು ಹಕ್ಕಿ',
'ಇರುಳೆಱೆಯ',
'ಇರುಳೋಲಗ',
'ಇರುಳ್ಗಾಳೆಗ',
'ಇರುಳ್ಗುರುಡು',
'ಇರ್ಕಟ್ಟುಗಟ್ಟು',
'ಇರ್ಕಡಿಮಾಡು',
'ಇರ್ಕಡಿಗೆಯ್',
'ಇರ್ಕಣ್ಗುರುಡ',
'ಇರ್ಕವೆಗೋಲ್',
'ಇರ್ಕುಳಿಗೊಳ್',
'ಇರ್ತಡಿವಿಡಿ',
'ಇರ್ತಲೆಗುಯ್ಯ',
'ಇರ್ತಲೆವಕ್ಕಿ',
'ಇರ್ತಲೆವೊತ್ತು',
'ಇರ್ತಲೆಹಾವು',
'ಇರ್ನಿಷೇಚನ',
'ಇರ್ಪದಿಂಬರ್',
'ಇರ್ಬಗಿಮಾಡು',
'ಇರ್ಮೆಯ್ಗಾಣಿಸು',
'ಇರ್ಮೊನೆಗಾಣ್',
'ಇರ್ವಗಿಮಾಡು',
'ಇರ್ವಡಿಚಾಗಿ',
'ಇರ್ಸಾಲ್‍ಪಟ್ಟಿ',
'ಇಲಿಕತ್ತರಿ',
'ಇಲಿಕಿವಿಗ',
'ಇಲಿಕುಡೆಗ',
'ಇಲಿಗಿವಿಗ',
'ಇಲಿಗಿವಿಯ',
'ಇಲಿಪಾಷಾಣ',
'ಇಲ್ಲಾರಕಟ್ಟು',
'ಇಲ್ಲಾರಖಂಡಿ',
'ಇಶಾಡೀಮಾವು',
'ಇಷಾಡೀಮಾವು',
'ಇಷ್ಟಕಾಪಥ',
'ಇಷ್ಟದೇವತೆ',
'ಇಷ್ಟಮಧುಕ',
'ಇಷ್ಟವಯಸ್ಯೆ',
'ಇಷ್ಟವರಣ',
'ಇಷ್ಟವಿಯೋಗ',
'ಇಷ್ಟವಿರಹ',
'ಇಷ್ಟಾರ್ಥಕರ',
'ಇಷ್ಟಾರ್ಥಸಿದ್ಧಿ',
'ಇಸಕುಧಾರಿ',
'ಇಸಬಗೋಲು',
'ಇಸಬುಗೋಲು',
'ಇಸಮಲ್ಲಿಗೆ',
'ಇಸವಿಸನ್',
'ಇಸ್ತಕಪಾಲು',
'ಇಸ್ತಕಬಾಲ',
'ಇಸ್ತಕಬಾಲು',
'ಇಸ್ತಿಹಾರ್ನಾಮೆ',
'ಇಸ್ತ್ರಿಪೆಟ್ಟಿಗೆ',
'ಇಸ್ಪಾನೆಯುಲ್',
'ಇಹಗಿಹಗೆ',
'ಇಹಸಂಸಾರ',
'ಇಳಾವಳಯ',
'ಇಳಿಂಕೆಗೆಯ್',
'ಇಳಿಂಕೆಗೊಳ್',
'ಇಳಿಕೆಗಾಣ್',
'ಇಳಿಕೆಗೆಯ್',
'ಇಳಿಕೆಗೊಳ್',
'ಇಳಿಕೆವಡೆ',
'ಇಳಿಗಾಲುವೆ',
'ಇಳಿಗೂದಲು',
'ಇಳಿಗೊಳವೆ',
'ಇಳಿನೇಸರು',
'ಇಳಿಬಿಸಿಲು',
'ಇಳಿಬೆಳಕು',
'ಇಳಿಮುಳುಗು',
'ಇಳಿವಯಸ್ಸು',
'ಇಳಿಸಕ್ಕರೆ',
'ಇಳೆಯೆರೆಯ',
'ಇಳ್ಕುಳಿಗೊಳ್',
'ಇಱಿಗೆಲಸ',
'ಇಱಿತಕಾಱ',
'ಇಱಿತಗಾಱ',
'ಇಱಿಯೆಪಡು',
'ಇಱುಂಬುಗೊಳ್',
'ಇಱುಕುದಾರಿ',
'ಇಱುಕುವಾತು',
'ಇಱುಬುಗೊಳ್',
'ಇೞಿತವಿಲಿ',
'ಇೞಿಸುನೂಂಕು',
'ಇೞ್ಕುಳಿಗೊಳ್',
'ಈಂಟುಜಳಧಿ',
'ಈಕ್ಷಣಭದ್ರ',
'ಈಚಲುಹುಳ',
'ಈಚಲುಹುಳು',
'ಈಜುಬುರುಡೆ',
'ಈಡಿರಿದಾಡು',
'ಈದ್ ಮಿಲಾದ್',
'ಈರಡಿಮಾಡು',
'ಈರಪಲಸು',
'ಈರೆಲೆವೋಗು',
'ಈರ್ಬಾಚಣಿಗೆ',
'ಈರ್ಯಾವಿಶುದ್ಧಿ',
'ಈರ್ಯಾಸಮಿತಿ',
'ಈಲಿಯುರಿತ',
'ಈಶಶಾಸನ',
'ಈಶಾನಕಲ್ಪ',
'ಈಶ್ವರಗಣ',
'ಈಶ್ವರಬಳ್ಳಿ',
'ಈಶ್ವರಬೇರು',
'ಈಶ್ವರವಲ್ಲಿ',
'ಈಶ್ವರವಾದ',
'ಈಶ್ವರಶೈಲ',
'ಈಶ್ವರಸದ್ಮ',
'ಈಶ್ವರಿಬೇರು',
'ಈಶ್ವರಿಬಳ್ಳಿ',
'ಈಶ್ವರಿವಳ್ಳಿ',
'ಈಶ್ವರೀಬೇರು',
'ಈಷಣತ್ರಯ',
'ಈಷತ್ಖಂಡನ',
'ಈಷಾವಿದೂರ',
'ಈಸಗುಂಬಳ',
'ಈಸರಬಳ್ಳಿ',
'ಈಸುಕುಂಬಳ',
'ಈಸುಗುಂಬಳ',
'ಈಳಿಗೆಕತ್ತಿ',
'ಈಳಿಗೆಮಣೆ',
'ಈಳ್ಕುಳಿಗೊಳ್',
'ಈೞ್ಕುಳಿಗೊಳ್',
'ಉಂಗುಟವಿಡಿ',
'ಉಂಗುರವಿಡಿ',
'ಉಂಗುರವಿಡು',
'ಉಂಗುರುಡಿಕೆ',
'ಉಂಗುರುಡಿಗೆ',
'ಉಂಗುರುಡುಕೆ',
'ಉಂಗ್ರಗೂದಲು',
'ಉಂಜಲುತ್ಸವ',
'ಉಂಟಾದವನು',
'ಉಂಡಲಕಾಳು',
'ಉಂಡಾಡಿಗುಂಡ',
'ಉಂಡಾಡಿಭಟ್ಟ',
'ಉಂಡಿಗೆಗೊಳ್',
'ಉಂಡಿಗೆಗೋಲ್',
'ಉಂಡಿಗೆಯೊತ್ತು',
'ಉಂಡೆಗಡುಬು',
'ಉಂಬಳಿಕಾಱ',
'ಉಂಬಳಿಗಾಱ',
'ಉಂಬಳಿದಾರ',
'ಉಂಬಳಿಬಿಡು',
'ಉಕಾರಾಂತತೆ',
'ಉಕ್ಕಡಗಾಳೆ',
'ಉಕ್ಕಡಮನೆ',
'ಉಕ್ಕಡಹೊಲ',
'ಉಕ್ಕುಟೊಪ್ಪಿಗೆ',
'ಉಕ್ಕೆವಗೆಯ್ತ',
'ಉಕ್ಕೆವತನ',
'ಉಕ್ಕೆವತಪ',
'ಉಕ್ಕೆಸರಾಡು',
'ಉಕ್ತಪೂರ್ವಕಂ',
'ಉಕ್ತಲೇಖಕ',
'ಉಕ್ತಲೇಖನ',
'ಉಕ್ತಿಪ್ರೌಢಿಮೆ',
'ಉಕ್ತಿವಕ್ರ್ರಿಮ',
'ಉಕ್ಷಗಮನ',
'ಉಕ್ಷವಾಹನ',
'ಉಕ್ಷಸಲಕ್ಷ',
'ಉಗಿ ಆವಿಗೆ',
'ಉಗಿದಪ್ಪಲೆ',
'ಉಗಿಪಡುಹ',
'ಉಗಿಬಗಿಸು',
'ಉಗಿವಡಗ',
'ಉಗಿಹಡಗ',
'ಉಗುರಡ್ಡಿಗೆ',
'ಉಗುರದಿಟ್ಟಿ',
'ಉಗುರುಕಣ್ಣು',
'ಉಗುರುಗೊನೆ',
'ಉಗುರುನಂಜು',
'ಉಗುರುಬಿಸಿ',
'ಉಗುರುಸುತ್ತು',
'ಉಗುರ್ವೆಚ್ಚನೆ',
'ಉಗುರ್ವೆಳಗು',
'ಉಗುಳುಗಂಡಿ',
'ಉಗುಳುಧೂಪ',
'ಉಗುಳುನೀರು',
'ಉಗ್ಗಡಣಿಸು',
'ಉಗ್ಗಡಿಯಂತ್ರ',
'ಉಗ್ಘಡಿಯಂತ್ರ',
'ಉಗ್ರಗಾಮಿತ್ವ',
'ಉಗ್ರಲೋಚನ',
'ಉಗ್ರವಾದಿತ್ವ',
'ಉಗ್ರಶಾಸನ',
'ಉಚಕೊರಡು',
'ಉಚಿತವರ್ಣ',
'ಉಚಿತವಱಿ',
'ಉಚಿತವಿಧಿ',
'ಉಚಿತಾಕ್ಷರ',
'ಉಚ್ಚಂಡಕರ',
'ಉಚ್ಚಕಕುದ',
'ಉಚ್ಚಗೊಳಿವೆ',
'ಉಚ್ಚವವಡೆ',
'ಉಚ್ಚ ಶಿಕ್ಷಣ',
'ಉಚ್ಚಾಟನಂಗೈ',
'ಉಚ್ಚುಕೊರಡು',
'ಉಚ್ಚೆಬುರುಕ',
'ಉಚ್ಚೆಬುರುಕಿ',
'ಉಚ್ಚೆಬುರುಡೆ',
'ಉಚ್ಚೈಶ್ರವತ್ವ',
'ಉಚ್ಚೈಶ್ರವಸ್ಸು',
'ಉಚ್ಛಿಷ್ಟಪಕ್ವ',
'ಉಚ್ಛೃಂಖಲತೆ',
'ಉಚ್ಛ್ರಾಯಸ್ಥಿತಿ',
'ಉಜ್ಜುಕಂಬಳಿ',
'ಉಜ್ಜುಗಂಗೆಯ್',
'ಉಜ್ಜುಗಂದಳೆ',
'ಉಜ್ಜುಗಂಬಳಿ',
'ಉಜ್ಜುಗವೆಣ್',
'ಉಜ್ಜುಗವೆಱು',
'ಉಜ್ಜುಗೊರಡ',
'ಉಜ್ಜುಗೊರಡು',
'ಉಜ್ಜ್ವಳಂಮಾಡು',
'ಉಜ್ಜ್ವಳಕೇಶ',
'ಉಜ್ಜ್ವಳಬೇರ',
'ಉಜ್ಜ್ವಳೀಕೃತ',
'ಉಜ್ಝಿತಲಕ್ಷ್ಮ',
'ಉಜ್ಝಿತಸುಖ',
'ಉಜ್ವಲವೃತ್ತ',
'ಉಟಜಾಂಗಣ',
'ಉಟ್ಟರಗಂಟು',
'ಉಡಸಾರಣೆ',
'ಉಡಾಫೆಹೊಡೆ',
'ಉಡಾಳಟಪ್ಪು',
'ಉಡಾಳತನ',
'ಉಡಾಳಪೋರ',
'ಉಡಿಕೆಗಂಡ',
'ಉಡಿತುಂಬಿಸು',
'ಉಡಿದೊವಲ್',
'ಉಡಿಪ್ಪೆಬಳ್ಳಿ',
'ಉಡಿಪ್ಪೆಮರ',
'ಉಡಿಲುತುಂಬು',
'ಉಡಿಲೇವಳ',
'ಉಡಿವಸನ',
'ಉಡುಕುವಾದ್ಯ',
'ಉಡುಗೊಱೆಯೀ',
'ಉಡುತೊವಲ್',
'ಉಡುಪಖಂಡ',
'ಉಡುಪಮುಖಿ',
'ಉಡುಪುಗಾರ',
'ಉಡುರುಹಾಕು',
'ಉಡುವಸನ',
'ಉಡುಸಾರಣೆ',
'ಉಡೆದೊವಲ್',
'ಉಡ್ಡಾಣಮಾಡು',
'ಉಡ್ಡಿಗಜುಗ',
'ಉಡ್ಯಾಣಚೇಷ್ಟೆ',
'ಉಣ್ಣಪಡಿಸು',
'ಉತ್ಕಟವೃತ್ತಿ',
'ಉತ್ಕ್ರಮ ಶ್ರೇಣಿ',
'ಉತ್ಕ್ರಾಂತಿತತ್ತ್ವ',
'ಉತ್ತಮಪಾತ್ರ',
'ಉತ್ತಮಬೀಸು',
'ಉತ್ತಮಶ್ಲೋಕ',
'ಉತ್ತರಂಗುಡು',
'ಉತ್ತರಂಗೆಡು',
'ಉತ್ತರಂಗೊಡು',
'ಉತ್ತರಕರ್ಮ',
'ಉತ್ತರಕಾಲ',
'ಉತ್ತರಕುರು',
'ಉತ್ತರಕೊಡು',
'ಉತ್ತರಕೌಲ',
'ಉತ್ತರಕ್ರಿಯೆ',
'ಉತ್ತರಕ್ಷಣ',
'ಉತ್ತರಗೊಡು',
'ಉತ್ತರಜ್ನಾನಿ',
'ಉತ್ತರತಪ',
'ಉತ್ತರದಾತ',
'ಉತ್ತರದಾಯಿ',
'ಉತ್ತರಧ್ರುವ',
'ಉತ್ತರಪಕ್ಷ',
'ಉತ್ತರಪದ',
'ಉತ್ತರಪಾಣಿ',
'ಉತ್ತರಪೂಜೆ',
'ಉತ್ತರಭಾಗ',
'ಉತ್ತರಭಾರ',
'ಉತ್ತರಮಾರ್ಗ',
'ಉತ್ತರಮುಖಿ',
'ಉತ್ತರಮೇರು',
'ಉತ್ತರರಕ್ಷೆ',
'ಉತ್ತರವಾದಿ',
'ಉತ್ತರಶ್ವಾಸ',
'ಉತ್ತರಾಪಥ',
'ಉತ್ತರಾಭಾದ್ರೆ',
'ಉತ್ತರಾಭಾಸ',
'ಉತ್ತರಾಯಣ',
'ಉತ್ತರಾರಣಿ',
'ಉತ್ತರಾಷಾಢ',
'ಉತ್ತರಾಸಂಗ',
'ಉತ್ತರೀಯಕ',
'ಉತ್ತರೋತ್ತರ',
'ಉತ್ತವಳಿಕೆ',
'ಉತ್ತವಳಿಸು',
'ಉತ್ತಾನಗಾನ',
'ಉತ್ತಾನಶಯ',
'ಉತ್ತಾನಶಯ್ಯೆ',
'ಉತ್ತಾರಂಗೆಯ್ಸು',
'ಉತ್ತಾರಗೆಯ್ಸು',
'ಉತ್ತಾರರಾಗ',
'ಉತ್ತುಂಗಕುಚೆ',
'ಉತ್ತುಂಗಚೂಡ',
'ಉತ್ತುಂಗಭಂಗ',
'ಉತ್ತುಂಗಭುಜ',
'ಉತ್ತೇಜನೀಯ',
'ಉತ್ಪತ್ತಿಕಾರ',
'ಉತ್ಪತ್ತಿಸ್ಥಾನ',
'ಉತ್ಪನ್ನಮಾಡು',
'ಉತ್ಪನ್ನಕಾರ',
'ಉತ್ಪನ್ನದಾರ',
'ಉತ್ಪಲಪ್ರಿಯ',
'ಉತ್ಪಲಮಾಲೆ',
'ಉತ್ಪಾತವಾಗು',
'ಉತ್ಪಾತಕೇತು',
'ಉತ್ಪಾದಕತೆ',
'ಉತ್ಪಾದನಾಂಗ',
'ಉತ್ಪುಂಜ್ಯಮಾನ',
'ಉತ್ಸಂಸ್ಕರಣ',
'ಉತ್ಸವಕರ',
'ಉತ್ಸವಬಡು',
'ಉತ್ಸವಮೂರ್ತಿ',
'ಉತ್ಸವಶೀಲ',
'ಉತ್ಸವಶೀಲೆ',
'ಉತ್ಸವಾನಕ',
'ಉತ್ಸಾಹಂಗುಂದು',
'ಉತ್ಸಾಹಭಂಗ',
'ಉತ್ಸಾಹಲಯ',
'ಉತ್ಸಾಹವೃತ್ತ',
'ಉತ್ಸಾಹಶಕ್ತಿ',
'ಉತ್ಸಾಹಶೂನ್ಯ',
'ಉತ್ಸಾಹಶೂನ್ಯೆ',
'ಉತ್ಸಾಹಸದ್ಮ',
'ಉತ್ಸಾಹಹೀನ',
'ಉತ್ಸಾಹಹೀನೆ',
'ಉತ್ಸುಕಚಿತ್ತ',
'ಉತ್ಸುಕತನ',
'ಉತ್ಸೃಷ್ಟಭವ',
'ಉತ್ಸೃಷ್ಟಭವೆ',
'ಉದಕದೀವು',
'ಉದಕಮಂತ್ರ',
'ಉದಕವಾಸಿ',
'ಉದಕಶಾಂತಿ',
'ಉದಕಸದ್ಮ',
'ಉದಕಾರ್ಗಳ',
'ಉದಕೋತ್ಸವ',
'ಉದಗಯನ',
'ಉದಗಲಿಸು',
'ಉದಗ್ರಪೀಡೆ',
'ಉದಗ್ರಮೂರ್ತಿ',
'ಉದನ್ವದ್ವೀಚಿ',
'ಉದಯಂಗೆಯ್',
'ಉದಯಕಾಲ',
'ಉದಯಗಿರಿ',
'ಉದಯತಾಳು',
'ಉದಯಮಾನ',
'ಉದಯಮುಖ',
'ಉದಯರವಿ',
'ಉದಯರಾಗ',
'ಉದಯಶೈಲ',
'ಉದಯಾಚಲ',
'ಉದಯಾಚಳ',
'ಉದಯಾದಿತ್ಯ',
'ಉದಯಾರೂಢ',
'ಉದಯಾರೂಢೆ',
'ಉದಯೋನ್ಮುಖ',
'ಉದರಂಭರಿ',
'ಉದರಪದಿ',
'ಉದರಪೂರ',
'ಉದರಪೂರ್ತಿ',
'ಉದರಬಂಧ',
'ಉದರಯಾತ್ರೆ',
'ಉದರಶಿಖಿ',
'ಉದರಶೂಲೆ',
'ಉದವಸಿತ',
'ಉದಹರಿಸು',
'ಉದಾತ್ತಗೊಳ್',
'ಉದಾತ್ತಮತಿ',
'ಉದಾತ್ತಯಶ',
'ಉದಾತ್ತವೃತ್ತ',
'ಉದಾತ್ತವೃತ್ತಿ',
'ಉದಾತ್ತಸತ್ತ್ವ',
'ಉದಾನವಾಯು',
'ಉದಾರತೇಜ',
'ಉದಾರಮತಿ',
'ಉದಾರವಾದ',
'ಉದಾರವಾದಿ',
'ಉದಾರಶೀಲ',
'ಉದಾಸಕಳೆ',
'ಉದಾಸಭಾವ',
'ಉದಾಸಮುದ್ರೆ',
'ಉದಾಸೀನತೆ',
'ಉದಾಹರಣ',
'ಉದಾಹರಣೆ',
'ಉದಾಹರಿಸು',
'ಉದಿತಕೀರ್ತಿ',
'ಉದಿತರಾಗ',
'ಉದಿತಸ್ಮೇರ',
'ಉದಿತೋದಿತ',
'ಉದಿಯಮರ',
'ಉದಿರದೆಗೆ',
'ಉದೀಚೀಭವ',
'ಉದೀಚ್ಯಮಾರ್ಗ',
'ಉದುಗರಿಸು',
'ಉದುಗಲಿಸು',
'ಉದುಗ್ರಾಹಕ',
'ಉದುಭವಿಸು',
'ಉದುರುಗಾಯಿ',
'ಉದುರುಗುಟ್ಟು',
'ಉದುರುಗೊಂಬು',
'ಉದುರುರೊಟ್ಟಿ',
'ಉದುರೆವಡೆ',
'ಉದೆಯಮರ',
'ಉದ್ಗತಜೀವಿ',
'ಉದ್ಗಮಚಾಪ',
'ಉದ್ಗಮನೀಯ',
'ಉದ್ಗಮಬಾಣ',
'ಉದ್ಗಮಮಾಲೆ',
'ಉದ್ಗಮವಾದ',
'ಉದ್ಗಮವಾದಿ',
'ಉದ್ಗಮವೃಷ್ಟಿ',
'ಉದ್ಗಮಸಾರ',
'ಉದ್ಗಾರತೆಗೆ',
'ಉದ್ಗಾರವೆಱು',
'ಉದ್ಗುಣಯುತ',
'ಉದ್ದಂಡತನ',
'ಉದ್ದಟತನ',
'ಉದ್ದಮಿದಾರ',
'ಉದ್ದಮುರಿಸು',
'ಉದ್ದಾಮತನ',
'ಉದ್ದಾಮಧಾಮ',
'ಉದ್ದಿಮೆಗಾರ',
'ಉದ್ದಿಮೆದಾರ',
'ಉದ್ದೇಶಯುಕ್ತ',
'ಉದ್ಧಟತನ',
'ಉದ್ಧತಮಾಡು',
'ಉದ್ಧತವಾದಿ',
'ಉದ್ಧತವೃತ್ತ',
'ಉದ್ಧತವೃತ್ತೆ',
'ಉದ್ಧತಶೀಲ',
'ಉದ್ಧತಶೀಲೆ',
'ಉದ್ಧತಿಬಡು',
'ಉದ್ಧಾರಮಾಡು',
'ಉದ್ಧಾರವಾಗು',
'ಉದ್ಧೂತದೋಷ',
'ಉದ್ಧೂತದೋಷೆ',
'ಉದ್ಬದ್ಧಕುಕ್ಷಿ',
'ಉದ್ಬದ್ಧಜೂಟ',
'ಉದ್ಬೋಧಕೋಶ',
'ಉದ್ಭಟಭಟ',
'ಉದ್ಭಟವೃತ್ತ',
'ಉದ್ಭಟವೃತ್ತೆ',
'ಉದ್ಭವಕರ',
'ಉದ್ಭವಮೂರ್ತಿ',
'ಉದ್ಭಿಜ್ಜಶಾಸ್ತ್ರ',
'ಉದ್ಭಿದ್ಯಮಾನ',
'ಉದ್ಭೂತವಾಗು',
'ಉದ್ಯಮರತ',
'ಉದ್ಯಮಶೀಲ',
'ಉದ್ಯಮಶೀಲೆ',
'ಉದ್ಯಾನಕುಂಜ',
'ಉದ್ಯಾನವನ',
'ಉದ್ಯುಕ್ತಪಡು',
'ಉದ್ಯೋಗಂಗೆಯ್',
'ಉದ್ಯೋಗಗೇಹ',
'ಉದ್ಯೋಗದಾತ',
'ಉದ್ಯೋಗದಾರ',
'ಉದ್ಯೋಗಪತಿ',
'ಉದ್ಯೋಗಶೀಲ',
'ಉದ್ಯೋಗಶೀಲೆ',
'ಉದ್ಯೋಗಾರಂಭ',
'ಉದ್ರೇಕಕಾರಿ',
'ಉದ್ರೇಕಗೊಳ್ಳು',
'ಉದ್ವಾಹಗೇಹ',
'ಉದ್ವೇಗಗೊಳ್',
'ಉದ್ವೇಗಪರ',
'ಉನಿಯಹಾಕು',
'ಉನ್ನತಚ್ಛಾಯ',
'ಉನ್ನತಪೀಠ',
'ಉನ್ನತಪ್ರಶ್ನೆ',
'ಉನ್ನತಭೂಮಿ',
'ಉನ್ನತಯವ',
'ಉನ್ನತಾನತ',
'ಉನ್ನತಾಸನ',
'ಉನ್ನತಿಗಿಡು',
'ಉನ್ನತಿವಡೆ',
'ಉನ್ನತಿವೆಱು',
'ಉನ್ನತೋದರ',
'ಉನ್ನತೋದರೆ',
'ಉನ್ನೆವರೆಗಂ',
'ಉನ್ಮತ್ತತನ',
'ಉನ್ಮದಾಳಿನಿ',
'ಉನ್ಮನವಾಗು',
'ಉನ್ಮನಾವಸ್ಥೆ',
'ಉನ್ಮಾದಕಾರಿ',
'ಉನ್ಮಾದವಂತ',
'ಉನ್ಮಾದಾವಸ್ಥೆ',
'ಉನ್ಮಾರ್ಗಗಾಮಿ',
'ಉನ್ಮುಖವಾಗು',
'ಉನ್ಮುಖವಡೆ',
'ಉಪ-ಉತ್ಪನ್ನ',
'ಉಪಕರಣ',
'ಉಪಕರಣೆ',
'ಉಪಕರಿಸು',
'ಉಪಕಸಬು',
'ಉಪಕಾರಿಕೆ',
'ಉಪಕುಂಚಿಕೆ',
'ಉಪಕುಟುಂಬ',
'ಉಪಕುರ್ವಾಣ',
'ಉಪಕ್ರಮಿಸು',
'ಉಪಗಮನ',
'ಉಪಗಾರಣಿ',
'ಉಪಗುಣಿತ',
'ಉಪಗೂಹನ',
'ಉಪಗೂಹಿತ',
'ಉಪಗೃಹೀತ',
'ಉಪಗ್ರಹಣ',
'ಉಪಚರಂಗೈ',
'ಉಪಚರಣ',
'ಉಪಚರಣೆ',
'ಉಪಚರಿತ',
'ಉಪಚರಿಸು',
'ಉಪಚಾಯಿಕೆ',
'ಉಪಚಾರಕ',
'ಉಪಚಾರಣ',
'ಉಪಚಾರಿಸು',
'ಉಪಚಾರೋಕ್ತಿ',
'ಉಪಚಿತ್ರಿಕೆ',
'ಉಪಜಲಧಿ',
'ಉಪಜಾಗೃತ',
'ಉಪಜಿಹ್ವಿಕೆ',
'ಉಪಜೀವಕ',
'ಉಪಜೀವನ',
'ಉಪಜೀವಿಕೆ',
'ಉಪಜೀವಿತ',
'ಉಪಜೀವಿಸು',
'ಉಪಟೋಕನ',
'ಉಪಢೌಕಿತ',
'ಉಪತರಣಿ',
'ಉಪದೂಷಣ',
'ಉಪದೇವತೆ',
'ಉಪದೇಶಕ',
'ಉಪದೇಶಿಸು',
'ಉಪದೇಹಿಕೆ',
'ಉಪಧಾವನ',
'ಉಪಧಾವಿಸು',
'ಉಪಧಾಶುದ್ಧ',
'ಉಪಧಾಶುದ್ಧೆ',
'ಉಪಧ್ಮಾನೀಯ',
'ಉಪಧ್ವನಿಮಾ',
'ಉಪನಂದನ',
'ಉಪನಗರ',
'ಉಪನಯನ',
'ಉಪನಾಯಕ',
'ಉಪನಿಯಮ',
'ಉಪನಿವೇಶ',
'ಉಪನಿಷತ್ತು',
'ಉಪನಿಷ್ಕರ',
'ಉಪನ್ಯಸನ',
'ಉಪನ್ಯಸಿಸು',
'ಉಪನ್ಯಾಸಕ',
'ಉಪನ್ಯಾಸಕಿ',
'ಉಪಪತ್ನಿತ್ವ',
'ಉಪಪಾತಕ',
'ಉಪಪಾದಜ',
'ಉಪಪಾದನೆ',
'ಉಪಪಾದಿಸು',
'ಉಪಪಾದುಕ',
'ಉಪಪುರಾಣ',
'ಉಪಪ್ರಚ್ಛದ',
'ಉಪಪ್ರದಾನ',
'ಉಪಪ್ರಮೇಯ',
'ಉಪಬೃಂಹಣ',
'ಉಪಬೃಂಹಣೆ',
'ಉಪಭೋಗಿಸು',
'ಉಪಮರ್ದನ',
'ಉಪಮಸ್ತಿಷ್ಕ',
'ಉಪಮಾಕ್ಷೇಪ',
'ಉಪಮಾತೀತ',
'ಉಪಮಾದೀಕ್ಷೆ',
'ಉಪಮೆಗಾಣ್',
'ಉಪಮೆವೆಱು',
'ಉಪಮೋತ್ಪ್ರೇಕ್ಷ',
'ಉಪಯುಕ್ತತೆ',
'ಉಪಯೋಗಾಸ್ತ್ರ',
'ಉಪಯೋಗಿಸು',
'ಉಪರಕ್ಷಣ',
'ಉಪರಚಿತ',
'ಉಪರಾಟಿಗಿ',
'ಉಪರಿಚರ',
'ಉಪರಿಜೀವಿ',
'ಉಪರಿತನ',
'ಉಪರಿದೇಶ',
'ಉಪರಿಮತ',
'ಉಪರಿವೃತ್ತ',
'ಉಪರೋಧಕ',
'ಉಪರೋಧಿಸು',
'ಉಪರೋಪಿತ',
'ಉಪರೋಹಿತ',
'ಉಪಲಕ್ಷಣ',
'ಉಪಲಕ್ಷಿತ',
'ಉಪಲಕ್ಷಿಸು',
'ಉಪಲತಲ',
'ಉಪಲಬ್ಧಾರ್ಥ',
'ಉಪಲಸಿತ',
'ಉಪಲಾಲನೆ',
'ಉಪಲಾಲಿತ',
'ಉಪಲಾಲಿಸು',
'ಉಪಲಾಸಿತ',
'ಉಪಲೇಪಕ',
'ಉಪಲೇಪನ',
'ಉಪವರ್ಣನೆ',
'ಉಪವರ್ಣಿತ',
'ಉಪವರ್ತನ',
'ಉಪವಸಥ',
'ಉಪವಾದಕ',
'ಉಪವಾಸಿಗ',
'ಉಪವಾಸ್ತವ',
'ಉಪವಿಕಾರ',
'ಉಪವಿಭಾಗ',
'ಉಪವಿರೋಧಿ',
'ಉಪವೀಣಿತ',
'ಉಪವೇಶನ',
'ಉಪವೇಷ್ಟಿತ',
'ಉಪಶಮನ',
'ಉಪಶಮಿಸು',
'ಉಪಶಲ್ಯಕ',
'ಉಪಶೀರ್ಷಕ',
'ಉಪಶೀರ್ಷಿಕೆ',
'ಉಪಶೋಭಿತ',
'ಉಪಶ್ಲೋಕಿಸು',
'ಉಪಷ್ಟಂಭಕ',
'ಉಪಸಂಗ್ರಾಹ್ಯ',
'ಉಪಸಂಪನ್ನ',
'ಉಪಸಂವ್ಯಾನ',
'ಉಪಸಂಹಾರ',
'ಉಪಸಂಹೃತಿ',
'ಉಪಸಚಿವ',
'ಉಪಸಮಿತಿ',
'ಉಪಸಮುದ್ರ',
'ಉಪಸರಣ',
'ಉಪಸರ್ಜನ',
'ಉಪಸರ್ಪಣ',
'ಉಪಸರ್ಪಿಸು',
'ಉಪಸಾಗರ',
'ಉಪಸಾಧನ',
'ಉಪಸಿದ್ಧಾಂತ',
'ಉಪಸೂರ್ಯಕ',
'ಉಪಸೇವಿಸು',
'ಉಪಸ್ಕರಣಿ',
'ಉಪಸ್ಕಾರಕ',
'ಉಪಸ್ಥಾಪನ',
'ಉಪಸ್ಪರ್ಶನ',
'ಉಪಹರಣ',
'ಉಪಹರಿಸು',
'ಉಪಹಸಿತ',
'ಉಪಹಾಸಕ',
'ಉಪಾಂತಾಕ್ಷರ',
'ಉಪಾಂಶುಜಪ',
'ಉಪಾಂಶುವಧೆ',
'ಉಪಾಕರಣ',
'ಉಪಾಧಿವಂತ',
'ಉಪಾಧ್ಯತನ',
'ಉಪಾಧ್ಯಾಯತೆ',
'ಉಪಾಧ್ಯಾಯಾನಿ',
'ಉಪಾಧ್ಯಾಯಿನಿ',
'ಉಪಾಯಂಬಡೆ',
'ಉಪಾಯಗಾರ',
'ಉಪಾಯಗಾರ್ತಿ',
'ಉಪಾಯಗೆಡು',
'ಉಪಾಯಗೇಡಿ',
'ಉಪಾಯಪರ',
'ಉಪಾಯವಾದಿ',
'ಉಪಾಯಾಂತರ',
'ಉಪಾಲಂಭನ',
'ಉಪಾಸಂಪತ್ತು',
'ಉಪಾಸಾಸ್ತವ',
'ಉಪಾಸ್ತಿಗೆಯ್',
'ಉಪಾಸ್ತೆಗೆಯ್',
'ಉಪೇಂದ್ರಮತಿ',
'ಉಪೇಂದ್ರವಜ್ರ',
'ಉಪೇಕ್ಷಾದೋಷ',
'ಉಪೋತ್ಪಾದನ',
'ಉಪ್ಪಟ್ಟವರ್ಷೆ',
'ಉಪ್ಪಡಪದ',
'ಉಪ್ಪರಗುಡಿ',
'ಉಪ್ಪರಗೋಂಟೆ',
'ಉಪ್ಪರದಂಡೆ',
'ಉಪ್ಪರಮನೆ',
'ಉಪ್ಪರಮುಡಿ',
'ಉಪ್ಪರವಟ್ಟ',
'ಉಪ್ಪರವೊಯ್ಲು',
'ಉಪ್ಪರಸಂಚ',
'ಉಪ್ಪರಸಾಲೆ',
'ಉಪ್ಪವಡಿಸು',
'ಉಪ್ಪಳಗಂಪು',
'ಉಪ್ಪಳಗಣ್',
'ಉಪ್ಪಾರಮೋಳೆ',
'ಉಪ್ಪಿನಕಾಯ್',
'ಉಪ್ಪಿನಕಾಯಿ',
'ಉಪ್ಪಿನಾಗರ',
'ಉಪ್ಪಿಲಿಬಳ್ಳಿ',
'ಉಪ್ಪುಕಡಲು',
'ಉಪ್ಪುಕಡಲೆ',
'ಉಪ್ಪುಕಾಗದ',
'ಉಪ್ಪುಕೊರಚ',
'ಉಪ್ಪುಗಡಲ್',
'ಉಪ್ಪುಗಡಲೆ',
'ಉಪ್ಪುನೇರಳೆ',
'ಉಪ್ಪುನೇರಿಳೆ',
'ಉಪ್ಪುಬಾಳಕ',
'ಉಪ್ಪುರಗುಡಿ',
'ಉಪ್ಪುಳಗಣ್',
'ಉಬ್ಬಚ್ಚುಗಾರ',
'ಉಬ್ಬಟೆಗಾಣ್',
'ಉಬ್ಬರಂಬರಿ',
'ಉಬ್ಬರಗಿಡಿ',
'ಉಬ್ಬರಮುರಿ',
'ಉಬ್ಬರವರಿ',
'ಉಬ್ಬರವಾತು',
'ಉಬ್ಬಸಂಬಡು',
'ಉಬ್ಬಸಗಳಿ',
'ಉಬ್ಬಸಗೊಳ್',
'ಉಬ್ಬಸಪಡು',
'ಉಬ್ಬಸಬಡು',
'ಉಬ್ಬಸವಡು',
'ಉಬ್ಬಸವೆಱು',
'ಉಬ್ಬಾಳುತನ',
'ಉಬ್ಬುಗನ್ನಡಿ',
'ಉಬ್ಬುಗವಳ',
'ಉಬ್ಬುಗುದುರೆ',
'ಉಬ್ಬುಗೋಮಾಳೆ',
'ಉಬ್ಬೆಕಾಣಿಕೆ',
'ಉಬ್ಬೆಗಂಗೊಳ್',
'ಉಬ್ಬೆಗಂಬಡು',
'ಉಬ್ಬೇಱುವೊಯ್ಲು',
'ಉಬ್ಬೇಱುಹೊಯ್ಲು',
'ಉಭಯಕರ್ಮ',
'ಉಭಯಕವಿ',
'ಉಭಯಕುಲ',
'ಉಭಯಗುಣ',
'ಉಭಯಜೀವಿ',
'ಉಭಯತರು',
'ಉಭಯರ್ತರು',
'ಉಭಯತ್ರರು',
'ಉಭಯಪಕ್ಷ',
'ಉಭಯಪಾದಿ',
'ಉಭಯಪಾರ್ಶ್ವ',
'ಉಭಯಬಲ',
'ಉಭಯಭಾಷೆ',
'ಉಭಯಭ್ರಷ್ಟ',
'ಉಭಯಮುಖಿ',
'ಉಭಯರಾಶಿ',
'ಉಭಯಲಿಂಗ',
'ಉಭಯಲಿಂಗಿ',
'ಉಭಯಲೋಕ',
'ಉಭಯವಂಶ',
'ಉಭಯವರ್ತಿ',
'ಉಭಯವಾಸಿ',
'ಉಭಯಸಾಮ್ಯ',
'ಉಭಯಾಯತ್ತ',
'ಉಭಯಾರೂಢ',
'ಉಭಯಾರೂಢೆ',
'ಉಭಯೋನ್ನತ',
'ಉಮರುಬೊಕ್ಕೆ',
'ಉಮಾಭರಣ',
'ಉಮೇದವಾರಿ',
'ಉಮೇದುವಾರ',
'ಉಮೇದುವಾರಿ',
'ಉಮ್ಮಳಂಗೊಳ್',
'ಉಮ್ಮಳಂಬೆಱು',
'ಉಮ್ಮಳಗೊಳ್',
'ಉಮ್ಮಳವಡು',
'ಉಯ್ಯಲಾಡಿಸು',
'ಉಯ್ಯಲಮಣೆ',
'ಉಯ್ಯಲೇಱಿಸು',
'ಉಯ್ಯಾಲೆಬಳ್ಳಿ',
'ಉಯ್ಯಾಲೆಮಣೆ',
'ಉಯ್ಯಾಲೋತ್ಸವ',
'ಉರಃಕವಾಟ',
'ಉರಃಸೂತ್ರಿಕೆ',
'ಉರಗಗೇಹ',
'ಉರಗತರ',
'ಉರಗದಂಡ',
'ಉರಗಧರ',
'ಉರಗಪಕ್ಷಿ',
'ಉರಗಪತಿ',
'ಉರಗಭೋಜಿ',
'ಉರಗವರ',
'ಉರಗವಳ್ಳಿ',
'ಉರಗಶಯ್ಯೆ',
'ಉರಗಸ್ಪರ್ಶ',
'ಉರಗಹಾರ',
'ಉರಗಾಂಗನೆ',
'ಉರಟರಿವೆ',
'ಉರರೀಕೃತ',
'ಉರವಣಿಗೆ',
'ಉರವಣಿಸು',
'ಉರವರಿಸು',
'ಉರಿಗಡಲು',
'ಉರಿಗದಿರ್',
'ಉರಿಗದಿರ',
'ಉರಿಗುದುರೆ',
'ಉರಿಗೆದರು',
'ಉರಿಗೆದಱ್',
'ಉರಿಗೊಳಿಸು',
'ಉರಿಚುಗಟ್ಟು',
'ಉರಿನಾಲಗೆ',
'ಉರಿನಾಲಿಗೆ',
'ಉರಿನೆಳಲು',
'ಉರಿಬಣಂಬೆ',
'ಉರಿಬಿಸಿಲು',
'ಉರಿಮಂಡಲ',
'ಉರಿಮಣಲು',
'ಉರಿಯರಗು',
'ಉರಿಯುಸಿರು',
'ಉರಿವಿಸಿಲ್',
'ಉರಿವೆಳಗು',
'ಉರಿಸಣಿಕೆ',
'ಉರಿಹತ್ತಿಸು',
'ಉರುಕುಗೊಳ್',
'ಉರುಗಮಣಿ',
'ಉರುಗುಬಾಯಿ',
'ಉರುಟುಕಲ್ಲು',
'ಉರುಟುತನ',
'ಉರುಟುಮುಖ',
'ಉರುಡುಹಾಕು',
'ಉರುಣೆಗೆಯ್',
'ಉರುಬುದೆರೆ',
'ಉರುಬೆಕಾರ',
'ಉರುಲುವಲೆ',
'ಉರುವಣಿಗೆ',
'ಉರುವಣಿಸು',
'ಉರುಳಿಗೊಳ್',
'ಉರುಳಿವರಿ',
'ಉರುಳುಗಡಿ',
'ಉರುಳುಗಣ್ಣಿ',
'ಉರುಳುಗೆಡ್ಡೆ',
'ಉರುಳುನೂಕು',
'ಉರುಳುಬೇಟೆ',
'ಉರುಳುಸೇವೆ',
'ಉರುಳು ಹಕ್ಕಿ',
'ಉರುಳೆವರಿ',
'ಉರುಳೆವಾಯ್',
'ಉರ್ಕುಗಾಳೆಗ',
'ಉರ್ಜುಗಂಗೊಳ್',
'ಉರ್ಬುಜವ್ವನೆ',
'ಉರ್ವರಾಜಿತ',
'ಉರ್ವರಾಪಾಳ',
'ಉರ್ವರಾಮರ',
'ಉರ್ವರೇಶ್ವರ',
'ಉರ್ವೀಪಾಲಕ',
'ಉಲಿಪುಗೇಳ್',
'ಉಲುಕುಮಂತ್ರ',
'ಉಲುಹುಗೆಡು',
'ಉಲುಹುದೆಗೆ',
'ಉಲ್ಕಾಪ್ರವಾಹ',
'ಉಲ್ಬಣವಾಗು',
'ಉಲ್ಬಣಗೊಳ್',
'ಉಲ್ಲಂಘನೀಯ',
'ಉಲ್ಲಾಸಗಾರ',
'ಉಲ್ಲಾಸಗಾರ್ತಿ',
'ಉಲ್ಲಾಸವೆಱು',
'ಉಲ್ಲಾಸಶೀಲ',
'ಉಲ್ಲೇಖನೀಯ',
'ಉಷಃಮಾನವ',
'ಉಷ್ಣಗ್ರಹಣ',
'ಉಷ್ಣಗ್ರಾಹಕ',
'ಉಷ್ಣತಾಘಾತ',
'ಉಷ್ಣತಾಮಾಪಿ',
'ಉಷ್ಣದಾಯಕ',
'ಉಷ್ಣನಯನ',
'ಉಷ್ಣಪ್ರದೇಶ',
'ಉಷ್ಣಮಯೂಖ',
'ಉಷ್ಣಮಾಪಕ',
'ಉಷ್ಣಮಾಪನ',
'ಉಷ್ಣವಲಯ',
'ಉಷ್ಣವಹನ',
'ಉಷ್ಣವಾಹಕ',
'ಉಷ್ಣವೈತಾಳಿ',
'ಉಷ್ಣಶೋಷಣೆ',
'ಉಷ್ಣಾಪಾರಕ',
'ಉಷ್ಣೀಷಪಟ್ಟ',
'ಉಸಿಕಮಿರ್',
'ಉಸಿರುಚೀಲ',
'ಉಸಿರುಬ್ಬಸ',
'ಉಸಿರುವೆಣ',
'ಉಸಿರ್ಗಿಡಿಸು',
'ಉಸುರುಗಳೆ',
'ಉಸ್ತಾದಪಟ್ಟ',
'ಉಸ್ತುವಾರಿಕೆ',
'ಉಸ್ಮಾನಿ (ಹುಲ್ಲು)',
'ಉಳಬಸರಿ',
'ಉಳಮೆದಾರ',
'ಉಳಿಗೆಲಸ',
'ಉಳಿಯಲಗು',
'ಉಳುಂದೋಗರೆ',
'ಉಳುಕುಮಂತ್ರ',
'ಉಳುಕೆವಳೆ',
'ಉಳುವೊಕ್ಕಲ್',
'ಉಳ್ಪಂಜರಿಸು',
'ಉಱುಕುಗೊಳ್',
'ಉಱುಗುಟೊಂಕ',
'ಉಱುಬುದೆಱೆ',
'ಉಱುಬೆಕಾಱ',
'ಉಱುಬೆಗಣೆ',
'ಊಟಲೆಕೊಚ್ಚು',
'ಊಟವಣಿಗ',
'ಊಟವಸತಿ',
'ಊಟಳೆಕೊಚ್ಚು',
'ಊಟಿನಗದ್ದೆ',
'ಊಣಯಂದಳೆ',
'ಊಣಯಂಬುಗು',
'ಊಣಯವಿಕ್ಕು',
'ಊಣೆಯಂಬುಗು',
'ಊಣೆಯವಾಗು',
'ಊಣೆಯವಿಡಿ',
'ಊದುಕಾಮನಿ',
'ಊದುಕಾಮಾಲೆ',
'ಊದುಕುಲುಮೆ',
'ಊದುಕೊಳವೆ',
'ಊದುಗಾಮನಿ',
'ಊದುಗಾಮಾಲೆ',
'ಊದುಗಾಮಿಲೆ',
'ಊದುಗೊಳವಿ',
'ಊದುಗೊಳವೆ',
'ಊದುತುಪಾಕಿ',
'ಊನಮಾಸಿಕ',
'ಊಬಿನಗೋಧಿ',
'ಊರಬಸವಿ',
'ಊರುಗಾರಿಕೆ',
'ಊರುಗೆಡಿಸು',
'ಊರುಜವೃತ್ತಿ',
'ಊರುದೇವಮ್ಮ',
'ಊರುದ್ವಯಸ',
'ಊರುಬಸವಿ',
'ಊರುಬಾಗಿಲು',
'ಊರೆನಿಲ್ಲಿಸು',
'ಊರೆಯೆತ್ತಿಗೆ',
'ಊರೊಡೆಗೇರಿ',
'ಊರೊಡೆತನ',
'ಊರ್ಕಾವಲಿಗ',
'ಊರ್ಕೆಲಸಿಗ',
'ಊರ್ಜಿತವಾಗು',
'ಊರ್ಜಿತತೇಜ',
'ಊರ್ಜಿತಪುಣ್ಯ',
'ಊರ್ಜಿತಪುಣ್ಯೆ',
'ಊರ್ಜಿತಮತಿ',
'ಊರ್ಜಿತಯಶ',
'ಊರ್ಜಿತಸ್ಥಿತಿ',
'ಊರ್ಧ್ವಗಮನ',
'ಊರ್ಧ್ವಗಮನೆ',
'ಊರ್ಧ್ವಜ್ವಲನ',
'ಊರ್ಧ್ವದೇಹಿಕ',
'ಊರ್ಧ್ವಮುಖತೆ',
'ಊರ್ಧ್ವರೇತಸ್ಕ',
'ಊರ್ಧ್ವಸಂಸ್ಕಾರ',
'ಊರ್ಮಿಘಟ್ಟನ',
'ಊರ್ಮಿಮಾಳಿನಿ',
'ಊಷರಕ್ಷೇತ್ರ',
'ಊಷರಭೂಮಿ',
'ಊಸರವಳ್ಳಿ',
'ಊಸುರವಳ್ಳಿ',
'ಊಸುರುವಳ್ಳಿ',
'ಊಹಾಪೋಹನ',
'ಊಹಾಪ್ರತಿಜ್ಞೆ',
'ಊಹೆದೆಗಹು',
'ಊಳಿಗಗಿತ್ತಿ',
'ಊಳಿಗಗುಡು',
'ಊಳಿಗತನ',
'ಊಳಿಗಮಾನ್ಯ',
'ಊಳಿಗವರ್ಗ',
'ಊಳಿಗವಿಡಿ',
'ಊಳಿಗವೆಣ್',
'ಋಕ್ಷಗಂಧಿಕೆ',
'ಋಕ್ಷನಾಯಕ',
'ಋಜುತೆವರು',
'ಋಜುದೇವತೆ',
'ಋಜುರೋಹಿತ',
'ಋಜೂಕರಣ',
'ಋಣನಿಧಾನ',
'ಋಣನಿಬದ್ಧ',
'ಋಣಪಾತಕ',
'ಋಣವಿದ್ಯುತ್ತು',
'ಋಣವ್ಯಾಪಾರ',
'ಋಣಸಂಬಂಧ',
'ಋಣಸೂತಕ',
'ಋಣಾನುಬಂಧ',
'ಋತವಚನ',
'ಋತುಪ್ರತಾನ',
'ಋತುಯಾಪನ',
'ಋತುಸಮಯ',
'ಋದ್ಧಿಸಂಪನ್ನ',
'ಋಷಭಪ್ರಿಯ',
'ಋಷಿಪಂಚಮಿ',
'ಋಷಿರೂಪಕ',
'ಎಂಜಲುಗೂಳು',
'ಎಂಜಲುಗೋಮ',
'ಎಂಜಲುದುಡ್ಡು',
'ಎಂಜಲುರುಳು',
'ಎಂಜಲುವಾತು',
'ಎಂಜಲುಹಾಳೆ',
'ಎಂಜಲೆವುೞು',
'ಎಂಜಲೋದನ',
'ಎಂಜನಿಯರು',
'ಎಂಪರಾಡಿಸು',
'ಎಂಪಲಾಡಿಸು',
'ಎಂಪಾಲಾಡಿಸು',
'ಎಕಲುಗದ್ದೆ',
'ಎಕಾರವಿಧಿ',
'ಎಕ್ಕಟಗಲಿ',
'ಎಕ್ಕಟಿಕರೆ',
'ಎಕ್ಕಟಿಗರೆ',
'ಎಕ್ಕಮದ್ದಳೆ',
'ಎಕ್ಕಲಕುಂಡ',
'ಎಕ್ಕಲಗದ್ದೆ',
'ಎಕ್ಕಲಗಾಣ',
'ಎಕ್ಕಲದೇವಿ',
'ಎಕ್ಕಲಬಂಟ',
'ಎಕ್ಕಲರಾಯ',
'ಎಕ್ಕಲವಂದಿ',
'ಎಕ್ಕಲವಿರಿ',
'ಎಕ್ಕಲುಗದ್ದೆ',
'ಎಕ್ಕವತ್ತಿಗೆ',
'ಎಕ್ಕವದ್ದಳೆ',
'ಎಗಚುಮಾತು',
'ಎಗುರುಕಂಬಿ',
'ಎಗುರುಪಟ್ಟಿ',
'ಎಗ್ಗುಳಿತನ',
'ಎಚ್ಚರಗೆಡು',
'ಎಚ್ಚರಗೇಡಿ',
'ಎಚ್ಚರಗೊಳ್ಳು',
'ಎಚ್ಚರತಪ್ಪು',
'ಎಚ್ಚರದಪ್ಪು',
'ಎಚ್ಚರಪಡು',
'ಎಚ್ಚರಮರೆ',
'ಎಚ್ಚರುಗೇಳು',
'ಎಚ್ಚಾಳುತನ',
'ಎಜ್ಜಂಗೊಳಿಸು',
'ಎಟ್ಟಿಕರಲು',
'ಎಡಗಲಿಸು',
'ಎಡಗೆಯ್ಜಾತಿ',
'ಎಡಗೈಯವ',
'ಎಡಗೈಕುಲ',
'ಎಡಚತನ',
'ಎಡಪಂಥೀಯ',
'ಎಡಬರವು',
'ಎಡಬಿಡಂಗಿ',
'ಎಡಮಗ್ಗುಲು',
'ಎಡರ್ಗೇಡಿಗ',
'ಎಡವರಿಸು',
'ಎಡವುಗಲ್ಲು',
'ಎಡವೆಗಲ್',
'ಎಡಸಂಸಾರ',
'ಎಡಹುಗಲ್ಲು',
'ಎಡಹುಗುಳಿ',
'ಎಡಹುದಲೆ',
'ಎಡೆಗಲಸು',
'ಎಡೆಗಲಿಸು',
'ಎಡೆಗಿಡಿಸು',
'ಎಡೆಗೆಡಪು',
'ಎಡೆಗೆಡಹು',
'ಎಡೆಗೆಡಿಸು',
'ಎಡೆಗೆಣೆಯ',
'ಎಡೆಗೆಲಸ',
'ಎಡೆಗೊಳಿಸು',
'ಎಡೆತಾಕಿಸು',
'ಎಡೆದೆವರು',
'ಎಡೆದಱಪು',
'ಎಡೆದೆಱಹು',
'ಎಡೆನೆಱೆಹ',
'ಎಡೆಬಡಿಸು',
'ಎಡೆಮಡಗು',
'ಎಡೆಮಾಡಿಸು',
'ಎಡೆಮುಡುಕ್ಕು',
'ಎಡೆಯಕ್ಕರ',
'ಎಡೆಯಾಡಿಕೆ',
'ಎಡೆಯಾಡಿಸು',
'ಎಡೆಯುಡುಗು',
'ಎಡೆವಗಲ್',
'ಎಡೆವಟ್ಟಲ್',
'ಎಡೆವಡಿಸು',
'ಎಡೆವರಿಸು',
'ಎಡೆವರಿಹ',
'ಎಡೆವಿಡವು',
'ಎಡೆವಿಡಿವು',
'ಎಡೆವಿಡಿಸು',
'ಎಡೆವುಡುಗು',
'ಎಡೆವೆರಲ್',
'ಎಡೆವೊೞಲ್',
'ಎಡೆಸೊಣಂಗು',
'ಎಡೆಹಗಲು',
'ಎಡೆಹರಗು',
'ಎಡೆಹರೆಯ',
'ಎಣವಳಿಕೆ',
'ಎಣಿಕೆಗಳೆ',
'ಎಣಿಕೆಗೞಿ',
'ಎಣಿಕೆಗೆಡು',
'ಎಣಿಕೆಗೊಳ್',
'ಎಣಿಕೆದಪ್ಪು',
'ಎಣಿಕೆವಡು',
'ಎಣಿಕೆವೆಱು',
'ಎಣಿಕೆಹಾಕು',
'ಎಣೆಗಂಟಿಕ್ಕು',
'ಎಣೆಯಕ್ಕರ',
'ಎಣ್ಗೊಳವೆದೆ',
'ಎಣ್ಛಾಸಿರ್ವರ್',
'ಎಣ್ಣೆಗಂಟಿಕ್ಕು',
'ಎಣ್ಣೆಗಂಟಿಡು',
'ಎಣ್ಣೆಗಪ್ಪಡ',
'ಎಣ್ಣೆಗಮಟು',
'ಎಣ್ಣೆಬುಡಿಗೆ',
'ಎಣ್ಣೆಮಂಜರಿ',
'ಎಣ್ಣೆಮಜ್ಜನ',
'ಎಣ್ಣೆಮಣಕು',
'ಎಣ್ಣೆಯೂರಿಗೆ',
'ಎಣ್ಣೆಯೋಗರ',
'ಎಣ್ಣೆಹೂರಿಗೆ',
'ಎತ್ತುಂಗೊರಲ್',
'ಎತ್ತುಂಗೋಲಿಕ್ಕು',
'ಎತ್ತುಂಗೋಲ್ವೋಗು',
'ಎತ್ತುಗೈಯುದ್ದ',
'ಎತ್ತುನಾಲಗೆ',
'ಎತ್ತುನಾಲಿಗೆ',
'ಎತ್ತುವಾಡಗೆ',
'ಎದಿರುಗೊಳ್ಳು',
'ಎದುರಾನಿಸು',
'ಎದುರಾಯಿಸು',
'ಎದುರುಗಡೆ',
'ಎದುರುಗಣ್ಣು',
'ಎದುರುಗೊಳ್ಳು',
'ಎದುರುತ್ತರ',
'ಎದುರುದಾವೆ',
'ಎದುರುನುಡಿ',
'ಎದುರುನೋಡು',
'ಎದುರುಬರು',
'ಎದುರುಬೀಳು',
'ಎದುರುಮಾತು',
'ಎದುರುವಳಿ',
'ಎದುರುವಾದಿ',
'ಎದುರುವ್ಯಾಜ್ಯ',
'ಎದುರುಹೆಣ್ಣು',
'ಎದುರುಹೇಳು',
'ಎದುರೆರಗು',
'ಎದುರ್ಚಂಡಿಸು',
'ಎದುವರೆಗೆ',
'ಎದೆಕರಗು',
'ಎದೆಗಟುಕ',
'ಎದೆಗತ್ತರಿ',
'ಎದೆಗದಡು',
'ಎದೆಗಲಿಗ',
'ಎದೆಗವಚ',
'ಎದೆಗಾರಿಕೆ',
'ಎದೆಗುಂಡಿಕೆ',
'ಎದೆಗುಂಡಿಗೆ',
'ಎದೆಗುಂದಿಸು',
'ಎದೆಗುಟುಕು',
'ಎದೆಗುದಿಹ',
'ಎದೆಗುಪ್ಪಸ',
'ಎದೆಗುರುಡ',
'ಎದೆಗೆಡಿಸು',
'ಎದೆಗೆಳೆಯ',
'ಎದೆಗೊಯಿಕ',
'ಎದೆಗೊಳಿಸು',
'ಎದೆ ಝಲ್ಲೆನ್ನು',
'ಎದೆದಲ್ಲಣ',
'ಎದೆದಲ್ಲಳ',
'ಎದೆದುಡುಕು',
'ಎದೆನುಡಿತ',
'ಎದೆಪದಕ',
'ಎದೆಬಕ್ಕಣ',
'ಎದೆಬಡಿತ',
'ಎದೆಬಿರಿಸು',
'ಎದೆಮಿಡಿತ',
'ಎದೆಮುಳುವು',
'ಎದೆಯೆಲುಬು',
'ಎದೆಯೊಲವು',
'ಎದೆವತ್ತಿಗೆ',
'ಎದೆವಲಗೆ',
'ಎದೆವೊಡಕ',
'ಎದೆಸೆಟಿಸು',
'ಎದೆಸೆಳವು',
'ಎದೆಹಚ್ಚಿಗೆ',
'ಎದೆಹಾರಿಕೆ',
'ಎದ್ದಲಗಾಟ',
'ಎದ್ದುಕಾಣಿಸು',
'ಎನ್ನೆವರೆಗಂ',
'ಎಬಡತನ',
'ಎಬ್ಬಲಗಾಟ',
'ಎಮ್ಮೆತಮ್ಮಣ್ಣ',
'ಎಮ್ಮೆದಾಂಡಿಗ',
'ಎಮ್ಮೆ ಮಣಕ',
'ಎರಕಗಾರ',
'ಎರಕಸಾಲೆ',
'ಎರಕಹೊಯ್',
'ಎರಟಿಕುಟ್ಟಿ',
'ಎರಟೆಕುಟ್ಟಿ',
'ಎರಡನೆಯ',
'ಎರಡಯಿದು',
'ಎರಡುಗೆಡು',
'ಎರಡುನುಡಿ',
'ಎರಲುಣಿಗ',
'ಎರಲೆದಿಂಕು',
'ಎರಲೆಧೀಂಕು',
'ಎರಲ್ಗೆಳೆಯ',
'ಎರವರಿಸು',
'ಎರವಿಗೊಳ್',
'ಎರವುಗಾಣ್',
'ಎರವುಗೊಳ್',
'ಎರಳೆಕಣ್',
'ಎರಳೆಗಣ್',
'ಎರಳೆವಲೆ',
'ಎರೆಕಬ್ಬಿಣ',
'ಎರೆಗೆದಱು',
'ಎರೆವಚ್ಚಾಡು',
'ಎರ್ದೆಕೊಳಿಸು',
'ಎರ್ದೆಗರಗು',
'ಎರ್ದೆಗಳ್ತಲ್',
'ಎರ್ದೆಗಿಡಿಸು',
'ಎರ್ದೆಗೆಡಿಸು',
'ಎರ್ದೆಗೊಳಿಸು',
'ಎರ್ದೆದಲ್ಲಣ',
'ಎರ್ದೆವತ್ತುಗೆ',
'ಎರ್ದೆವೞಲ್',
'ಎಲಮುಗಿಲು',
'ಎಲರಣುಗ',
'ಎಲರುಣಿಗ',
'ಎಲರ್ಗೆಳೆಯ',
'ಎಲರ್ವಟ್ಟೆಗ',
'ಎಲವರಿಗೆ',
'ಎಲುಕಳಲು',
'ಎಲುಬುಗಂತಿ',
'ಎಲುಬುಮಜ್ಜೆ',
'ಎಲುಮುರುಟು',
'ಎಲುವುಸಂಧಿ',
'ಎಲೆ ಅಡಿಕೆ',
'ಎಲೆಕಣಿಕೆ',
'ಎಲೆಕಪ್ಪಲಿ',
'ಎಲೆಕಮ್ಮಿರ',
'ಎಲೆಗತ್ತರಿ',
'ಎಲೆಗಪ್ಪಲಿ',
'ಎಲೆಗುಂಪಲು',
'ಎಲೆಗುದ್ದಲಿ',
'ಎಲೆಗೊಬ್ಬರ',
'ಎಲೆಚುಜ್ಜಲು',
'ಎಲೆತಳಲ್',
'ಎಲೆನಾಗರ',
'ಎಲೆಪಸರ',
'ಎಲೆಬಿಜ್ಜಳ',
'ಎಲೆಮರಿಗೆ',
'ಎಲೆಮಿಡತೆ',
'ಎಲೆಯಡಕೆ',
'ಎಲೆಯಡಿಕೆ',
'ಎಲೆಯುಗುರು',
'ಎಲೆವರದ',
'ಎಲೆವಸರ',
'ಎಲೆವಾದುರ',
'ಎಲೆಸವುಟು',
'ಎಲ್ವಡಗಾಗು',
'ಎಲ್ವುದೊಡವು',
'ಎವೆಗೂದಲು',
'ಎವೆದೆಱೆಸು',
'ಎವೆಬಡಿಸು',
'ಎವೆಮಿಸುಕು',
'ಎವೆಯಲುಗು',
'ಎವೆಹಳಚು',
'ಎಷ್ಟರವನು',
'ಎಷ್ಟುಮಾತ್ರಕ್ಕೂ',
'ಎಸಕಂಗಾಯ್',
'ಎಸಕಂಗಿಡು',
'ಎಸಕಂಬಡೆ',
'ಎಸಕಂಬೆಱು',
'ಎಸಳುಗಣ್',
'ಎಸಳುಗಣೆ',
'ಎಸಳುಗಣ್ಣು',
'ಎಸಳ್ದೊಂಗಲ್',
'ಎಸಳ್ಬುಗಡಿ',
'ಎಸೞ್ಮಸಗು',
'ಎಸೞ್ಮಿಸುಗು',
'ಎಸಿಮುಚ್ಚಲು',
'ಎಸುಗೆಗುಂದು',
'ಎಸುಗೆವಡೆ',
'ಎಸೆಗುದ್ದಲಿ',
'ಎಳ ಅರಸ',
'ಎಳಕೊಳಿಸು',
'ಎಳಗವುಂಗು',
'ಎಳಗಳ್ತಲೆ',
'ಎಳಗಱುಂಕೆ',
'ಎಳಗಱುಕೆ',
'ಎಳಗೊನರ್',
'ಎಳಚೆಯ್ಯಾಟ',
'ಎಳಜವ್ವನೆ',
'ಎಳದಳಿರ್',
'ಎಳದಳಿಸು',
'ಎಳದಿಂಗಳ್',
'ಎಳದುಪ್ಪಳ',
'ಎಳದುವರಿ',
'ಎಳದೊಳಸಿ',
'ಎಳನಾಗರ',
'ಎಳನೇಸರು',
'ಎಳನೇಸಱ್',
'ಎಳಮೆಯ್ಯಳ್',
'ಎಳಮೊಳಗು',
'ಎಳವಗಲು',
'ಎಳವರೆಯ',
'ಎಳವಲಸು',
'ಎಳವಸಲೆ',
'ಎಳವಸಿಱ್',
'ಎಳವಳಿಕು',
'ಎಳವಿದಿರ್',
'ಎಳವಿಸಿಲ್',
'ಎಳವೆರಲ್',
'ಎಳವೆರಳು',
'ಎಳವೆಳಗು',
'ಎಳವೊನಲ್',
'ಎಳಸುತನ',
'ಎಳೆ ಅಷ್ಟಮಿ',
'ಎಳೆಕುಕಿಲು',
'ಎಳೆಕುಣಿಕೆ',
'ಎಳೆಗಱುಂಕೆ',
'ಎಳೆಗುರುಳು',
'ಎಳೆಗೊಳಿಸು',
'ಎಳೆತರಿಸು',
'ಎಳೆತಳಲ್',
'ಎಳೆತೊಗಲ್',
'ಎಳೆದಳಿರ್',
'ಎಳೆದಳಿಸು',
'ಎಳೆದಿಂಗಳು',
'ಎಳೆನಡಿಗೆ',
'ಎಳೆನಾಗರ',
'ಎಳೆನೇಸಱ್',
'ಎಳೆನೇಸಱು',
'ಎಳೆಬಿಸಿಲು',
'ಎಳೆಬಿಳುಪು',
'ಎಳೆಮೊಗರು',
'ಎಳೆಯ ರವಿ',
'ಎಳೆಯರಸ',
'ಎಳೆಯಸುಗೆ',
'ಎಳೆವಗಲು',
'ಎಳೆವಸಲೆ',
'ಎಳೆವಸಿಱ್',
'ಎಳೆವಿದಿರ್',
'ಎಳೆವಿಸಿಲ್',
'ಎಳೆಸಸಿಲೆ',
'ಎಳೆಹಗಲು',
'ಎಳೆಹದಿರು',
'ಎಳೆಹರೆಯ',
'ಎಳ್ಪದಿಂಬರ್',
'ಎಳ್ಳಮವಾಸ್ಯೆ',
'ಎಳ್ಳುಚಿಗಳಿ',
'ಎಳ್ಳುರೇವಡಿ',
'ಎಱಕದವ',
'ಎಱಕಂಬೆಱು',
'ಎಱಕುಮತಿ',
'ಎಱಕೆವೆಟ್ಟು',
'ಎಱತನಿಗ',
'ಎಱೆತನಿಗ',
'ಎಱೆಸಿಕೊಳ್',
'ಎಱೆವೆಸನ',
'ಎೞಲೆಬಿಡು',
'ಎೞಲೆವಿಡು',
'ಎೞವಿಕೊಳ್',
'ಎೞ್ಚಱುಗೆಡು',
'ಎೞ್ತರದಿಟ್ಟಿ',
'ಎೞ್ತುವೊಜಂಗ',
'ಎೞ್ಪದಿಂಬರ್',
'ಏಕಕರ್ತೃಕ',
'ಏಕಕವಾಟಿ',
'ಏಕಕಾಲಿಕ',
'ಏಕಕುಂಡಲ',
'ಏಕಕೇಂದ್ರೀಯ',
'ಏಕಕೇಸರ',
'ಏಕಕೇಸರಿ',
'ಏಕಕೋಶೀಯ',
'ಏಕಗುಣಿತ',
'ಏಕಚಿತ್ತತೆ',
'ಏಕತಾನತೆ',
'ಏಕತಾವಾದ',
'ಏಕದೇಶೀಯ',
'ಏಕಧ್ರುವೀಯ',
'ಏಕನಾದತೆ',
'ಏಕನಿಷ್ಠತೆ',
'ಏಕಪಕ್ಷೀಯ',
'ಏಕಪತಿತ್ವ',
'ಏಕಪತ್ನೀತ್ವ',
'ಏಕಪದೀಯ',
'ಏಕಪ್ರಕಾರ',
'ಏಕಫಲಕಿ',
'ಏಕಭಾಜನ',
'ಏಕಭೋಗಸ್ಥ',
'ಏಕಮನಸ್ಸು',
'ಏಕಮಾತ್ರಕ',
'ಏಕಮುಖತೆ',
'ಏಕಮೂಲಿಕೆ',
'ಏಕಯಷ್ಟಿಕೆ',
'ಏಕರಥಿಕ',
'ಏಕರದನ',
'ಏಕರಾಜಕ',
'ಏಕರೂಪತೆ',
'ಏಕರೇಖಿಕ',
'ಏಕರೇಖೀಯ',
'ಏಕವಚನ',
'ಏಕವಾಕ್ಯತೆ',
'ಏಕವಿಹಾರಿ',
'ಏಕಶಾಖೀಯ',
'ಏಕಸೂತ್ರತೆ',
'ಏಕಸ್ಥಾನೀಯ',
'ಏಕಸ್ವಧಾರಿ',
'ಏಕಸ್ವಾಮಿಕ',
'ಏಕಸ್ವಾಮ್ಯತೆ',
'ಏಕಹಾಯನ',
'ಏಕಹಾಯನಿ',
'ಏಕಾಂಗವೀರ',
'ಏಕಾಂಗಸಸ್ಯ',
'ಏಕಾಂಗಿತನ',
'ಏಕಾಂಗುಲೀಯ',
'ಏಕಾಂತಗೃಹ',
'ಏಕಾಂತದ್ರೋಹಿ',
'ಏಕಾಂತಭಕ್ತ',
'ಏಕಾಂತಭಕ್ತೆ',
'ಏಕಾಂತವಂತ',
'ಏಕಾಂತವಾಸ',
'ಏಕಾಂತವಾಸಿ',
'ಏಕಾಂತವೆಸ',
'ಏಕಾಕಿತನ',
'ಏಕಾಗ್ರಚಿತ್ತ',
'ಏಕಾಗ್ರಚಿತ್ತೆ',
'ಏಕಾಗ್ರಮನ',
'ಏಕಾಗ್ರಮುಖ',
'ಏಕಾಣುಜೀವಿ',
'ಏಕಾತಪತ್ರ',
'ಏಕಾತ್ಮಕತೆ',
'ಏಕಾದಶಸ್ಥ',
'ಏಕಾಧಿಪತಿ',
'ಏಕಾಧಿಪತ್ಯ',
'ಏಕಾಭಿಪ್ರಾಯ',
'ಏಕಾಭಿಮುಖ',
'ಏಕಾರ್ಥಗತಿ',
'ಏಕೀಕರಣ',
'ಏಕೀಕರಿಸು',
'ಏಕೀಭವಿಸು',
'ಏಕೋಕ್ತಿಗೀತ',
'ಏಟುಪಾವಡೆ',
'ಏಟುಪಾವುಡೆ',
'ಏಣನಯನೆ',
'ಏಣಲಾಂಛನ',
'ಏಣವಳಿಕೆ',
'ಏಣವಾಹನ',
'ಏಣಾಂಕಜೂಟ',
'ಏಣಾಂಕಧರ',
'ಏಣಿತುರುಬು',
'ಏಣುಜಾರಿಗೆ',
'ಏನಸ್ಸಂತಾನ',
'ಏನುಜಾರಿಗ',
'ಏರಂಡಕಿಟ್ಟ',
'ಏರುಂಬಿಸಿಲು',
'ಏರುಕಂದಾಯ',
'ಏರುಕಾಣಿಕೆ',
'ಏರುಗಡಲ್',
'ಏರುಗಾಣಿಕೆ',
'ಏರುಗೊರಳು',
'ಏರುಜವ್ವನ',
'ಏರುಜವ್ವನೆ',
'ಏರುಬಿಸಿಲು',
'ಏರ್ಪಸವಿಗ',
'ಏಲಕ್ಕಿಬಾಳೆ',
'ಏಲಾವಾಲುಕ',
'ಏಸುವೆಸನ',
'ಏಳಿಕೆಗೆಯ್',
'ಏಳಿಗೆತನ',
'ಏಳಿಗೆವೆಱು',
'ಏಳಿದಂಗೆಯ್',
'ಏಳಿದಂಬಗೆ',
'ಏಳಿದಂಮಾಡು',
'ಏಳಿಲಂಗೆಯ್',
'ಏಳಿಲಗೆಯ್',
'ಏಳುಬೆಸನ',
'ಏಳೆಲೆಬಾಳೆ',
'ಏಳೆಲೆವಾಳೆ',
'ಏಳ್ಗೆವಾಡಿವ',
'ಏಳ್ವರೆಲಕ್ಕೆ',
'ಏಱತಿರುಹು',
'ಏಱುಂಜವ್ವನ',
'ಏಱುಂಜವ್ವನೆ',
'ಏಱುಂಜೊಡರ್',
'ಏಱುಂಜೌವನ',
'ಏಱುಗಾಣಿಸು',
'ಏಱುಜವ್ವನ',
'ಏಱುದಂಡಿಗೆ',
'ಏಱುಮುಡಿಗೆ',
'ಏಱುಸೋಪಾನ',
'ಏೞೆಲೆವಾೞೆ',
'ಏೞ್ಗೆವಡಿಸು',
'ಏೞ್ಗೆವಾಡಿವ',
'ಐಂದವಕಲೆ',
'ಐಂದವಬಿಂಬ',
'ಐಂದ್ರಜಾಲಕ',
'ಐಂದ್ರಜಾಲಿಕ',
'ಐಂದ್ರಲುಪ್ತಿಕ',
'ಐಕಾಗಾರಿಕ',
'ಐಕಿಲೆಲರ್',
'ಐಕಿಲ್ಗದಿರ',
'ಐಕಿಲ್ವೆಳಗ',
'ಐಕ್ಯಕೂಟಸ್ಥ',
'ಐಕ್ಷವರಸ',
'ಐಚ್ಚಿಕಸ್ನಾಯು',
'ಐತಿಹಾಸಿಕ',
'ಐದಲರ್ಗೋಲು',
'ಐದೆಮಿನುಗು',
'ಐರಗಡಗ',
'ಐರಾವತೀಶ',
'ಐಲಕಸ್ಥಿತಿ',
'ಐವಜುದಾರ',
'ಐವದಿಂಬರ್',
'ಐಶಾನಕಲ್ಪ',
'ಐಶ್ವರ್ಯಗೇಡಿ',
'ಐಶ್ವರ್ಯಮತ್ತ',
'ಐಶ್ವರ್ಯಲೋಭ',
'ಐಶ್ವರ್ಯವಂತ',
'ಐಶ್ವರ್ಯವಂತೆ',
'ಐಶ್ವರ್ಯವಡೆ',
'ಐಶ್ವರ್ಯವತಿ',
'ಐಹಿಕಸೌಖ್ಯ',
'ಒಂಟಿಕಾಱಿಕೆ',
'ಒಂಟಿಕೋಡೊಲೆ',
'ಒಂಟಿಗತನ',
'ಒಂಟಿನಡಿಗೆ',
'ಒಂಟಿಮುರಿಗೆ',
'ಒಂಟಿರಿಗಾರ',
'ಒಂಟಿಸಲಗ',
'ಒಂಟಿಱಿಗಾಱ',
'ಒಂಟೆರಾವುತ',
'ಒಂದರಿಯಾಡು',
'ಒಂದವಿಂದಿಗ',
'ಒಂದುಗೂಡಿಸು',
'ಒಂದುಗೊಳಿಸು',
'ಒಂದುವೆರಸು',
'ಒಂದೆರಳ್ಮುರಿ',
'ಒಂದೆಲೆವೊನ್ನೆ',
'ಒಂದೆಲೆಹೊನ್ನೆ',
'ಒಂಬದಿಂಬರ್',
'ಒಂಬಯ್‍ನೂಱು',
'ಒಕ್ಕಣೆಯಂತ್ರ',
'ಒಕ್ಕಣ್ಣಡಕ',
'ಒಕ್ಕಣ್ಣಿವಲೆ',
'ಒಕ್ಕರಗಣ್ಣು',
'ಒಕ್ಕಲಗಿತ್ತಿ',
'ಒಕ್ಕಲತನ',
'ಒಕ್ಕಲಿಗಿತಿ',
'ಒಕ್ಕಲುಗಿತ್ತಿ',
'ಒಕ್ಕಲುಗೊಳ್ಳು',
'ಒಕ್ಕಲುತನ',
'ಒಕ್ಕಲುದೆಱೆ',
'ಒಕ್ಕಲುವೋಗು',
'ಒಕ್ಕಲುಹೋಗು',
'ಒಕ್ಕಲೇಳಿಸು',
'ಒಕ್ಕಲ್ಗೆಲಸ',
'ಒಗಂಟುವಡು',
'ಒಗಂಟುವೇೞ್',
'ಒಗಡುಗಿಡಿ',
'ಒಗರಡಕೆ',
'ಒಗರುಕಾಯಿ',
'ಒಗರುಜೋಳ',
'ಒಗುನೆತ್ತರು',
'ಒಗುಮಿಗಿಸು',
'ಒಗ್ಗದಧೂಪ',
'ಒಗ್ಗರಣೆಯೀ',
'ಒಗ್ಗಾಲಿತನ',
'ಒಚ್ಚತಂಗುಡು',
'ಒಚ್ಚತಂಗೊಡು',
'ಒಚ್ಚತಂಗೊಳ್',
'ಒಚ್ಚತಗೊಡು',
'ಒಚ್ಚತಗೊಳ್',
'ಒಚ್ಚತವಿರು',
'ಒಚ್ಚತವೋಗು',
'ಒಚ್ಚತಹೋಗು',
'ಒಚ್ಚರಮುತ್ತು',
'ಒಚ್ಚರೆಗಣ್',
'ಒಚ್ಚೆಯಗೊಡು',
'ಒಚ್ಚೆರೆಗಣ್',
'ಒಚ್ಚೇರೆಗಣ್',
'ಒಚ್ಚೇರೆಗಣ್ಣು',
'ಒಜ್ಜರಂಬರಿ',
'ಒಟ್ಟಜಂಗುಡು',
'ಒಟ್ಟಜವೆಱು',
'ಒಟ್ಟಜಿಕಾಱ',
'ಒಟ್ಟಜೆಕಾಱ',
'ಒಟ್ಟಜೆಗಿಡು',
'ಒಟ್ಟಜೆಗೆಡು',
'ಒಟ್ಟಲುಪಾತಿ',
'ಒಟ್ಟಿಲುಹೊಯ್',
'ಒಟ್ಟಿಸಿಕೊಳ್',
'ಒಟ್ಟುಕುಟುಂಬ',
'ಒಟ್ಟುಗಟ್ಟಲೆ',
'ಒಟ್ಟುಗಟ್ಟಳೆ',
'ಒಟ್ಟುಗುತ್ತಿಗೆ',
'ಒಟ್ಟುಗೂಡಿಕೆ',
'ಒಟ್ಟುಗೂಡಿಸು',
'ಒಟ್ಟುಬೇರೀಜು',
'ಒಟ್ಟು ವ್ಯಾಪಾರ',
'ಒಟ್ಟುಸಾಸುವೆ',
'ಒಟ್ಟೊಟ್ಟಿಗಿಡ',
'ಒಡಂಬಡಿಕೆ',
'ಒಡಂಬಡಿಸು',
'ಒಡಕುಕಾಸು',
'ಒಡಕುದನಿ',
'ಒಡಕುಬಾಯಿ',
'ಒಡಕುಮನೆ',
'ಒಡಕುಮಾತು',
'ಒಡಕುಹಣೆ',
'ಒಡಗಲಸು',
'ಒಡಗಲಿಸು',
'ಒಡಗೂಡಿಸು',
'ಒಡತಿರುಗು',
'ಒಡದಿರುಗು',
'ಒಡನಡಪು',
'ಒಡನಾಡಿಕೆ',
'ಒಡನಾಡಿಗ',
'ಒಡನಾಡಿಸು',
'ಒಡಪುಹಾಕು',
'ಒಡಬಡಿಕೆ',
'ಒಡಬಡಿಸು',
'ಒಡಮರುಕ',
'ಒಡಮಱುಕ',
'ಒಡಮಱುಗು',
'ಒಡಮುರಿಕ',
'ಒಡಮುರಿಸು',
'ಒಡಮೂಡಿಸು',
'ಒಡಮೆವಡೆ',
'ಒಡಲುಗಿಚ್ಚು',
'ಒಡಲುಗೊಳ್',
'ಒಡಲುದೋಱು',
'ಒಡಲುರಿಕ',
'ಒಡಲುರಿಸು',
'ಒಡಲುವಿಡಿ',
'ಒಡಲ್ಗೆಡುಕ',
'ಒಡಲ್ಗೊಳಿಸು',
'ಒಡಲ್ವೊಗರ್',
'ಒಡವರಿಸು',
'ಒಡವರೆಯ',
'ಒಡವೆಗೇಡು',
'ಒಡವೆರಸು',
'ಒಡವೆಳಸು',
'ಒಡಹಾಯಿಸು',
'ಒಡಿಪೊಡಿಸು',
'ಒಡುಗಂಪಲಿ',
'ಒಡೆಗಲಸು',
'ಒಡೆಗಾರಳು',
'ಒಡೆದರಿಗೆ',
'ಒಡೆದುಳಿಸು',
'ಒಡೆಮಗುಚು',
'ಒಡೆಮುರಿಚು',
'ಒಡೆಮುರುಚು',
'ಒಡೆಮುರುಹು',
'ಒಡೆಯಕಾರ',
'ಒಡೆಯವಚು',
'ಒಡಯವುಚು',
'ಒಡ್ಡಣೆಗೊಳ್',
'ಒಡ್ಡಿಗಜುಗ',
'ಒಡ್ಡುವಲಗೆ',
'ಒಡ್ಯಾಣಗಿತ್ತಿ',
'ಒಣಕೆಲಸ',
'ಒಣಗೆಮ್ಮಲು',
'ಒಣಚಲುವೆ',
'ಒಣಜಗಳ',
'ಒಣತಱಗು',
'ಒಣಪಾಂಡಿತ್ಯ',
'ಒಣಬರಲು',
'ಒಣಮೊಱಡಿ',
'ಒಣರಗಳೆ',
'ಒಣರವದಿ',
'ಒಣವಸಿಱ್',
'ಒಣವೇದಾಂತ',
'ಒಣಸಂಗತಿ',
'ಒಣಸಂಶಯ',
'ಒಣಸಗಣಿ',
'ಒಣಸಾಹಸ',
'ಒಣಹರಟೆ',
'ಒತ್ತಂಬರಿಸು',
'ಒತ್ತಕರಡು',
'ಒತ್ತಟ್ಟಿಗಿಡು',
'ಒತ್ತಬರಿಸು',
'ಒತ್ತರಂಗೊಳ್',
'ಒತ್ತರಗೊಳ್',
'ಒತ್ತಲೆ ನೋವು',
'ಒತ್ತಲೆ ಶೂಲೆ',
'ಒತ್ತಾಸೆಗಾರ',
'ಒತ್ತಾಸೆಗಾರ್ತಿ',
'ಒತ್ತುಂಗರಡೆ',
'ಒತ್ತುಕರಡು',
'ಒತ್ತುಕಾಗದ',
'ಒತ್ತುಗುಂದಣಿ',
'ಒತ್ತುಫಲಕ',
'ಒತ್ತುಬೆರಳು',
'ಒತ್ತುಸೇವಗೆ',
'ಒತ್ತುಹಂಜರ',
'ಒತ್ತೆಬಿಡುಹ',
'ಒತ್ತೆಬಿಡಿಸು',
'ಒತ್ತಿವಿಡಿಸು',
'ಒದಗುನಾಳ',
'ಒದರುತಲೆ',
'ಒದರುದಲೆ',
'ಒದರುವಿಕೆ',
'ಒದೆಕೊರಡು',
'ಒದೆಗೆಡಹು',
'ಒನಕೆಗಾರ',
'ಒನಕೆಗಾಱ',
'ಒನಕೆಪಡಿ',
'ಒನಕೆಪೆಟ್ಟು',
'ಒನಕೆಮಂಡಿ',
'ಒನಕೆವಾಡು',
'ಒನಕೆಹುಳು',
'ಒನಪುಗಾತಿ',
'ಒನಪುಗಾರ',
'ಒನಪುಗಾರ್ತಿ',
'ಒಪ್ಪ ಓರಣ',
'ಒಪ್ಪವಾಗಿಡು',
'ಒಪ್ಪಂಗಿಡಿಸು',
'ಒಪ್ಪಂಗೆಡಿಸು',
'ಒಪ್ಪಂಗೊಳಿಸು',
'ಒಪ್ಪಂದಗಾರ',
'ಒಪ್ಪಗಾಣಿಸು',
'ಒಪ್ಪಗೆಡಿಸು',
'ಒಪ್ಪಗೆಲಸ',
'ಒಪ್ಪಗೊಳಿಸು',
'ಒಪ್ಪವುಡುಗು',
'ಒಪ್ಪಸಲಗೆ',
'ಒಪ್ಪಸಲಿಗೆ',
'ಒಪ್ಪಿಗೆಯಾಗು',
'ಒಪ್ಪಿಗೆದಾರ',
'ಒಪ್ಪಿಗೆವಡೆ',
'ಒಪ್ಪಿಡಿನಡು',
'ಒಪ್ಪುಗೊಳಿಸು',
'ಒಬ್ಬುಳಿಮಾಡು',
'ಒಬ್ಬುಳಿಯಾಗು',
'ಒಬ್ಬುಳಿಗೂಡು',
'ಒಬ್ಬುಳಿಗೆಯ್',
'ಒಬ್ಬುಳಿಗೊಳ್',
'ಒಬ್ಬುಳಿಯಿಕ್ಕು',
'ಒಮ್ಮನನೇಮ',
'ಒಮ್ಮೊಗವೆಱು',
'ಒಯ್ಯರಂಗುಡು',
'ಒಯ್ಯರಂಬಡೆ',
'ಒಯ್ಯರವಾನ್',
'ಒಯ್ಯಾರಂಬಡೆ',
'ಒಯ್ಯಾರಗಾತಿ',
'ಒಯ್ಯಾರಗಾರ',
'ಒಯ್ಯಾರಗಿತ್ತಿ',
'ಒಯ್ಯಾರತನ',
'ಒರಗುದಿಂಬು',
'ಒರಗುಮೂಡೆ',
'ಒರಗುಲ್ಲಾರ',
'ಒರಚುಗಣ್ಣು',
'ಒರಟತನ',
'ಒರಟುಗಟ್ಟು',
'ಒರಟುತನ',
'ಒರಟುಹಿಡಿ',
'ಒರತೆನೀರು',
'ಒರಸುಹಣ',
'ಒರಳುಕಲ್ಲು',
'ಒರೆಗಳಚು',
'ಒರ್ಚೇರೆಗಣ್',
'ಒರ್ನುಡಿಕಾಱ',
'ಒರ್ಪಿಡಿಗೂೞ್',
'ಒರ್ಬುಳಿಗೂಡು',
'ಒರ್ಬುಳಿಗೊಳ್',
'ಒರ್ಬುಳಿನಿಲ್',
'ಒರ್ಮೊದಲಾಗು',
'ಒರ್ವುಳಿಗೆಯ್',
'ಒರ್ವುಳಿಗೊಳ್',
'ಒಲಪುಗಾತಿ',
'ಒಲಪುಗಾರ',
'ಒಲಪುಗಾರ್ತಿ',
'ಒಲಪುಗಾಱ',
'ಒಲವಡಿಸು',
'ಒಲವುದೋಱು',
'ಒಲುಮೆಕಟ್ಟು',
'ಒಲುಮೆದೋಱು',
'ಒಲುಮೆಮದ್ದು',
'ಒಲೆಯಡುಕು',
'ಒಲ್ಮೆವೆಂಡತಿ',
'ಒಲ್ಲವಣಿಗೆ',
'ಒವಜುಗೆಯ್',
'ಒವೞನೀರ್',
'ಒವೞನೆಲ',
'ಒಸಗೆಕಾಱ',
'ಒಸಗೆದೇರ್',
'ಒಸಗೆದ್ರಮ್ಮ',
'ಒಸಗೆನೀರ್',
'ಒಸಗೆನುಡಿ',
'ಒಸಗೆಯೋಲೆ',
'ಒಸಗೆವರ್',
'ಒಸಗೆವಱೆ',
'ಒಸಗೆವಾಡು',
'ಒಸಗೆವಾತು',
'ಒಸಗೆವೇಳ್',
'ಒಸಗೆವೇೞ್',
'ಒಸಗೆವೋಲೆ',
'ಒಸಗೆಹೇಳ್',
'ಒಸರಿಸುಹ',
'ಒಸರುನೀರು',
'ಒಳ ಅಂಗಣ',
'ಒಳ ಅಂಗಳ',
'ಒಳ ಅರಿವು',
'ಒಳ ಉಗ್ರಾಣ',
'ಒಳ ಉಡಿಗೆ',
'ಒಳ ಉಡುಪು',
'ಒಳ ಉಸುಲು',
'ಒಳ ಊಳಿಗ',
'ಒಳ ಎಳೆತ',
'ಒಳ ಓಲಗ',
'ಒಳ ಓವರಿ',
'ಒಳಕಂಪಣ',
'ಒಳಕಡಿತ',
'ಒಳಕತ್ತರ',
'ಒಳಕುಪ್ಪಸ',
'ಒಳಕುಪ್ಪುಸ',
'ಒಳಕುಱುವ',
'ಒಳಕೋಟಲೆ',
'ಒಳಗತ್ತರಿ',
'ಒಳಗದಡು',
'ಒಳಗರುಡಿ',
'ಒಳಗವತೆ',
'ಒಳಗಱಿವು',
'ಒಳಗೞ್ತಲೆ',
'ಒಳಗಾಗಿಸು',
'ಒಳಗುಗಾಣ್',
'ಒಳಗುಗುಡು',
'ಒಳಗುಗೆಯ್',
'ಒಳಗುಗೊಡು',
'ಒಳಗುಗೊಳ್',
'ಒಳಗುದೋಱು',
'ಒಳಗುಪ್ಪಸ',
'ಒಳಗುಬಡು',
'ಒಳಗುಮಾಡು',
'ಒಳಗುವರಿ',
'ಒಳಗುವೇಳು',
'ಒಳಗೆಲಸ',
'ಒಳಗೆಸರು',
'ಒಳಚರಂಡಿ',
'ಒಳಚಾವಡಿ',
'ಒಳಚೌಕಿಗೆ',
'ಒಳಜಗಳ',
'ಒಳಜಗುಲಿ',
'ಒಳತಿರುಗು',
'ಒಳತಿರುವು',
'ಒಳತೆರಿಗೆ',
'ಒಳದವಡೆ',
'ಒಳನಿಳಯ',
'ಒಳಪಂಗಡ',
'ಒಳಪಡಿಸು',
'ಒಳಪದರು',
'ಒಳಪಹರಿ',
'ಒಳಪಾವಡೆ',
'ಒಳಪಾವುಡೆ',
'ಒಳಪುಗಿಸು',
'ಒಳಪ್ರಚಾರ',
'ಒಳಪ್ರಾಂಗಣ',
'ಒಳಪ್ರಾಕಾರ',
'ಒಳಬಾಗಿಲು',
'ಒಳಬೆಳಕು',
'ಒಳಬೆಳಗು',
'ಒಳಬೊಕ್ಕಸ',
'ಒಳಭಾಂಡಾರ',
'ಒಳಮಗ್ಗಲು',
'ಒಳಮರುಮ',
'ಒಳಮರುಳ್',
'ಒಳಮಾಳಿಗೆ',
'ಒಳಮುಚ್ಚಕ',
'ಒಳಮುಚ್ಚಗ',
'ಒಳಮುಚ್ಚಿಗೆ',
'ಒಳಮುಚ್ಚುಗ',
'ಒಳರಾಣುವೆ',
'ಒಳವಸುಂಬೆ',
'ಒಳವಳಯ',
'ಒಳವಾಗಿಲ್',
'ಒಳವುಗಿಸು',
'ಒಳವುಗೊಡು',
'ಒಳಸುರಂಗ',
'ಒಳಸೂಱೆಗ',
'ಒಳಸೆರಗು',
'ಒಳಸೆಳೆತ',
'ಒಳಹಂದರ',
'ಒಳಹದುಳ',
'ಒಳಹರಿವು',
'ಒಳಹಾಯಿಕೆ',
'ಒಳಹುರುಳು',
'ಒಳಹೊಗಿಸು',
'ಒಳಹೊಡೆತ',
'ಒಳಹೊರಗು',
'ಒಳಹೊರಗೆ',
'ಒಳಹೊಱಗು',
'ಒಳಹೊಱಗೆ',
'ಒಳಾಡಳಿತ',
'ಒಳಾವರಣ',
'ಒಳುಗನ್ನಡ',
'ಒಳುಗೊರಳು',
'ಒಳುದಿವಸ',
'ಒಳುವಸರ',
'ಒಳುವೆಂಗೂಸು',
'ಒಳುವೆಣ್ಗೂಸು',
'ಒಳುವೆಸರ್',
'ಒಳ್ಗುದಿಗೊಳು',
'ಒಳ್ನುಡಿಕಾಱ',
'ಒಳ್ಪುವೆರಸು',
'ಒಳ್ಮಾನಸಿಕೆ',
'ಒಳ್ವೆಳೆವೊಲ',
'ಒಳ್ಳಕ್ಕರಿಗ',
'ಒಳ್ಳರಲೆಱೆ',
'ಒಳ್ಳರಳ್ದಿಱಿ',
'ಒಳ್ಳಿತುಗೆಯ್',
'ಒಳ್ಳಿತೆಸಕ',
'ಒಳ್ಳೆ ತಂಗಡಿ',
'ಒಳ್ಳೆ ತುಮರಿ',
'ಒಳ್ಳೆಮೆಣಸು',
'ಒಳ್ಳೆಯತನ',
'ಒಱಂಟುತನ',
'ಒಱಂಟುವೇೞ್',
'ಒಱಗುಮೂಡೆ',
'ಒಱಟ್ಟತನ',
'ಒಱತೆಯೇಳ್',
'ಒಱಲೆಗಣ್ಣು',
'ಒಱಲೆಗಾಲ್',
'ಒಱಲೆಗೊಳ್',
'ಒಱಲ್ಗಲಹ',
'ಒಱವುಗಱೆ',
'ಒಱವುದೋಡು',
'ಒಱಹುಗುಂದು',
'ಒಱೆಗೊಳಿಸು',
'ಒೞ್ಕುಡಿವೋಗು',
'ಓಂಕಾರಮೂರ್ತಿ',
'ಓಕರಿಗೊಳ್',
'ಓಕರಿಸುಹ',
'ಓಕಸೌಕಸ',
'ಓಕುಳಿಗುಂಡಿ',
'ಓಕುಳಿಯಾಡು',
'ಓಗರಗಂಪು',
'ಓಜಗೃಹಸ್ಥ',
'ಓಜಗೊಡಗಿ',
'ಓಜುಗಟ್ಟಿಗೆ',
'ಓಜೆಗೆಡಿಸು',
'ಓಜೆಗೊಳಿಸು',
'ಓಜೆವೆರಸು',
'ಓಜೋಲಕ್ಷಣ',
'ಓಟೆನೂಲಿಕೆ',
'ಓಡಮರಿಗೆ',
'ಓಡುಗರತಿ',
'ಓದಾಳಿತನ',
'ಓದಿಕೆವೇಳ್',
'ಓದಿಸಿಕೊಳ್',
'ಓದುಬರಹ',
'ಓದುಗಲಿಸು',
'ಓದುಗಾರಿಕೆ',
'ಓದುನಾಟಕ',
'ಓಬಳಿಗಾಯಿ',
'ಓಬುಳಿಗಾಯಿ',
'ಓರಗೆಗೊಳ್',
'ಓರಣಂಗೊಳ್',
'ಓರಣಂಬಿಡಿ',
'ಓರಣಗಾತಿ',
'ಓರಣಗೆಡು',
'ಓರಣಗೊಳ್',
'ಓರೆಗೊರಳು',
'ಓರೆಚಪ್ಪರ',
'ಓರೆದುಱುಬು',
'ಓರೆಮೈಯಾಗು',
'ಓರೆಹದಿರು',
'ಓಲಗಂಗುಡು',
'ಓಲಗಂಗೊಡು',
'ಓಲಗಂಪರೆ',
'ಓಲಗಕಾರ್ತಿ',
'ಓಲಗಕಾಱ',
'ಓಲಗಗೊಡು',
'ಓಲಗಗೊಳ್ಳು',
'ಓಲಗಮಿರ್',
'ಓಲಗವಾಗು',
'ಓಲಗವೆಣ್',
'ಓಲಗಶಾಲೆ',
'ಓಲಗಸಾಲೆ',
'ಓಲಗಿಕಾಱ',
'ಓಲೆಕಾರಿಕೆ',
'ಓಲೆಕಾಱಿಕೆ',
'ಓಲೆಚಂದ್ರಿಕೆ',
'ಓಲೆತಿರುಪು',
'ಓಲೆಯಕಾಱ',
'ಓಲೆಯಡಕು',
'ಓಲೆವತ್ತಳೆ',
'ಓಲೆಸರ್ಪಣಿ',
'ಓಷಧೀಶ್ವರ',
'ಓಷ್ಠೀಕರಣ',
'ಓಷ್ಠೀಕರಿಸು',
'ಓಸರಗೇಳು',
'ಓಸರಗೊಳ್',
'ಓಸಱಿಗೇಳ್',
'ಓಳಿಗುರುಳ್',
'ಓಳಿಗೊಳಿಸು',
'ಓಳಿದೋರಣ',
'ಓಳಿವೆಳಗು',
'ಓಱಗುಡಿಸು',
'ಓಱುಗುಡಿಸು',
'ಔಡವ ಗೀತ',
'ಔತಣಕೂಟ',
'ಔತಣವಿಕ್ಕು',
'ಔತ್ತಾನಪಾದ',
'ಔತ್ತಾನಪಾದಿ',
'ಔದಲೆಗಾಣ್',
'ಔಪಚಾರಿಕ',
'ಔಪನಿಷದ',
'ಔಪಯೋಗಿಕ',
'ಔಪರಿಷ್ಟಕ',
'ಔಪಶ್ಲೇಷಿಕ',
'ಔಪಹಾಸಿಕ',
'ಔಪಾಧಿಕತೆ',
'ಔರಂಗಾಬಾದಿ',
'ಔರಸಪುತ್ರ',
'ಔಧ್ರ್ವದೇಹಿಕ',
'ಔರ್ವಜ್ವಲನ',
'ಔರ್ವಜ್ವಳನ',
'ಔಷಧಂಗೊಳ್',
'ಔಷಧಶಾಸ್ತ್ರ',
'ಔಷಧಾಚಾರ್ಯ',
'ಔಷಧಾಲಯ',
'ಔಷಧಿಶಾಸ್ತ್ರ',
'ಕಂಕಣಕಟ್ಟು',
'ಕಂಕಣಗಟ್ಟು',
'ಕಂಕಣಗಾರ',
'ಕಂಕಣಗಾಳ',
'ಕಂಕಣಗೀಲು',
'ಕಂಕಣತೊಡು',
'ಕಂಕಣಪ್ರಾಪ್ತಿ',
'ಕಂಕಣಬದ್ಧ',
'ಕಂಕಣಬದ್ಧೆ',
'ಕಂಕಣಬಲ',
'ಕಂಕಣರೇಖೆ',
'ಕಂಕರಕಲ್ಲು',
'ಕಂಕರಿಗೋಲ್',
'ಕಂಕರಿಗೋಲು',
'ಕಂಕಾಲಧರ',
'ಕಂಕಾಲಮಾಲಿ',
'ಕಂಕಾಳದಂಡ',
'ಕಂಕೋಶಹುಳು',
'ಕಂಕೋಳಚಿನ್ನಿ',
'ಕಂಗಾಲತನ',
'ಕಂಗಾಲುತನ',
'ಕಂಗೆಂದಿಬೇರು',
'ಕಂಚಗಾಱಿಕೆ',
'ಕಂಚಳವಾೞೆ',
'ಕಂಚಿತೋರಣ',
'ಕಂಚುಕಗಳೆ',
'ಕಂಚುಕದಳೆ',
'ಕಂಚುಗನ್ನಡಿ',
'ಕಂಚುಗೊರಳ್',
'ಕಂಚುಮಿಂಚಾಗು',
'ಕಂಚುವಡಿಗ',
'ಕಂಜಜೋದರ',
'ಕಂಜಜೋದ್ಭವ',
'ಕಂಜಲೋಚನ',
'ಕಂಜಸಂಭವ',
'ಕಂಜಾತಪತ್ರ',
'ಕಂಜಾತಪೀಠ',
'ಕಂಜೂಷಿತನ',
'ಕಂಟಕಂಬಡೆ',
'ಕಂಟಕಚಕ್ರ',
'ಕಂಟಕಚರ್ಮಿ',
'ಕಂಟಕದ್ವಾರ',
'ಕಂಟಕನಾಶ',
'ಕಂಟಕಫಲ',
'ಕಂಟಕಭೂಪ',
'ಕಂಟಕರೂಪ',
'ಕಂಟಕಸ್ಥಿತಿ',
'ಕಂಟಕಾಂಕುರ',
'ಕಂಟಕಾರಿಕೆ',
'ಕಂಟಜವಾಬು',
'ಕಂಟಣೆಯೆತ್ತು',
'ಕಂಟಲೆಯೆತ್ತು',
'ಕಂಟಿಯದಾರ',
'ಕಂಟಿಯಬಾರ',
'ಕಂಟೆಯದರ',
'ಕಂಟೆಯದಾರ',
'ಕಂಟೆಯಬಾರ',
'ಕಂಠಕಚ್ಚರ',
'ಕಂಠಗವಾಯು',
'ಕಂಠಗತಾಸು',
'ಕಂಠಗರಲ',
'ಕಂಠಣೆಯೆತ್ತು',
'ಕಂಠತಾಲವ್ಯ',
'ಕಂಠಪಟ್ಟಿಕೆ',
'ಕಂಠಪಾವಡ',
'ಕಂಠಶಾಲೂಕ',
'ಕಂಠಶೋಷಣೆ',
'ಕಂಠಾಭರಣ',
'ಕಂಠಿಕಾಬಂಧ',
'ಕಂಠಿಕಾಳಾಯಿ',
'ಕಂಠ್ಯೀಕರಣ',
'ಕಂಡಗರಿಕೆ',
'ಕಂಡಡೊಕ್ಕರ',
'ಕಂಡಣೆಪತ್ರ',
'ಕಂಡಣೆಹಣ',
'ಕಂಡಸಕ್ಕರೆ',
'ಕಂಡಿತವಾಚೆ',
'ಕಂಡಿತವಾದಿ',
'ಕಂತುಕಕೇಳಿ',
'ಕಂತುಕಕ್ರೀಡೆ',
'ಕಂತುಕಳಂಬ',
'ಕಂತುಮಥನ',
'ಕಂತೆಕಾಯಿಕ',
'ಕಂತೆಪುರಾಣ',
'ಕಂತ್ರಾಟದಾರ',
'ಕಂತ್ರಾಟುದಾರ',
'ಕಂಥೆಗುಪ್ಪಸ',
'ಕಂದರಕ್ಕರೆ',
'ಕಂದರೋದರ',
'ಕಂದರ್ಪರಿಪು',
'ಕಂದರ್ಪಾಂಧಕ',
'ಕಂದವಕ್ಕರೆ',
'ಕಂದವಟ್ಟಿಗೆ',
'ಕಂದವಾಹನ',
'ಕಂದುಕಕೇಳಿ',
'ಕಂದುಕಕ್ರೀಡೆ',
'ಕಂದುಗೊರಲ್',
'ಕಂದುಗೊರಲ',
'ಕಂದುಮಲ್ಲಿಗೆ',
'ಕಂದುಹೃದಯ',
'ಕಂದುಹೃದಯೆ',
'ಕಂದೆಱಪುಗೈ',
'ಕಂಧರಬಂಧ',
'ಕಂಪನಗೊಳ್',
'ಕಂಪನಾವರ್ತ',
'ಕಂಪಲ್ಮಸಗು',
'ಕಂಪಾಯಮಾನ',
'ಕಂಪುಗೋಲಾರಿ',
'ಕಂಪುಜಿನಸು',
'ಕಂಬಚ್ಚುಗಜ್ಜ',
'ಕಂಬನಿಗಱೆ',
'ಕಂಬನಿಗಿಡಿ',
'ಕಂಬನಿದುಂಬು',
'ಕಂಬನಿದೊಡೆ',
'ಕಂಬನಿಯೊರೆ',
'ಕಂಬಲವಾಹ್ಯ',
'ಕಂಬಲಾಸನ',
'ಕಂಬಳಕೋರಿ',
'ಕಂಬಳಗದ್ದೆ',
'ಕಂಬಳಗಾರ',
'ಕಂಬಳಪಱೆ',
'ಕಂಬಳವಂತ',
'ಕಂಬಳಾಸನ',
'ಕಂಬಳಿಗಿಡ',
'ಕಂಬಳಿಗೊಪ್ಪೆ',
'ಕಂಬಳಿಮರ',
'ಕಂಬಳಿಹುಳು',
'ಕಂಬಾರಗಿತ್ತಿ',
'ಕಂಬಿಗಟ್ಟಿಸು',
'ಕಂಬುಕಂಧರ',
'ಕಂಬುಕಂಧರೆ',
'ಕಂಭದೀವಿಗೆ',
'ಕಂಭನಿಱುಗೆ',
'ಕಂಭಮುಹೂರ್ತ',
'ಕಂಸಾಳಗಿತ್ತಿ',
'ಕಂಸಾಳೆಪದ',
'ಕಕುಭದಂತಿ',
'ಕಕೋಕಶಾಸ್ತ್ರ',
'ಕಕ್ಕಡವಂದಿ',
'ಕಕ್ಕಡಾರತಿ',
'ಕಕ್ಕಡೆವಲೆ',
'ಕಕ್ಕಬಿಕ್ಕರಿ',
'ಕಕ್ಕರಗೆಯ್ತ',
'ಕಕ್ಕರವಕ್ಕಿ',
'ಕಕ್ಕರಸಂಜೆ',
'ಕಕ್ಕಸಂಬಡು',
'ಕಕ್ಕಸಂಬೆಱು',
'ಕಕ್ಕಸಗಾರ',
'ಕಕ್ಕಸಗಾರ್ತಿ',
'ಕಕ್ಕಸಗೊಳ್',
'ಕಕ್ಕಸಬಡು',
'ಕಕ್ಕಸವೆಱು',
'ಕಕ್ಕುಚಂಡೆಲೆ',
'ಕಕ್ಕುರಿಮಿಗು',
'ಕಕ್ಕೋಕಶಾಸ್ತ್ರ',
'ಕಕ್ಷಪಾಲಿಕೆ',
'ಕಕ್ಷಪಾಳಿಕೆ',
'ಕಕ್ಷಾಂತರಂಗ',
'ಕಕ್ಷ್ಯಾವಕಾಶ',
'ಕಗ್ಗೊಲೆಕಾಱ',
'ಕಗ್ಗೊಲೆವೊಯ್',
'ಕಚಕುಳಿಸು',
'ಕಚವಾಯಿಸು',
'ಕಚ್ಚಪವೀಣೆ',
'ಕಚ್ಚರಾಂಬರ',
'ಕಚ್ಚಾಕಬ್ಬಿಣ',
'ಕಚ್ಚುಕೂಡಿಸು',
'ಕಚ್ಚೆಹರುಕ',
'ಕಚ್ಚೆಹರುಕಿ',
'ಕಚ್ಛಕ್ಷುರಿಕ',
'ಕಚ್ಛಪವೀಣೆ',
'ಕಜರಗಳೆ',
'ಕಜ್ಜಲಗಿರಿ',
'ಕಜ್ಜಲರೇಖೆ',
'ಕಜ್ಜಳಂಬರೆ',
'ಕಜ್ಜಳಗಿರಿ',
'ಕಜ್ಜಳವಿಡು',
'ಕಜ್ಜಳವೆಚ್ಚು',
'ಕಜ್ಜಿಬುರುಕ',
'ಕಜ್ಜಿಬುರುಕಿ',
'ಕಟಕಟಿಸು',
'ಕಟಕರಿಸು',
'ಕಟಕರೋಣಿ',
'ಕಟಕಲೋಣಿ',
'ಕಟಕಸೇಸೆ',
'ಕಟಕಾಚಾರ್ಯ',
'ಕಟಕಾಯನ',
'ಕಟಗಡಕಿ',
'ಕಟಗರಿಸು',
'ಕಟಗುಟ್ಟಿಸು',
'ಕಟವರಿಗೆ',
'ಕಟಳೆವಟ',
'ಕಟಾಕ್ಷವಡೆ',
'ಕಟಾಕ್ಷೇಕ್ಷಣ',
'ಕಟಿಪಟ್ಟಿಕೆ',
'ಕಟಿರೇಚಿತ',
'ಕಟುಕತನ',
'ಕಟುಕತ್ರಯ',
'ಕಟುಕೊಡಗಿ',
'ಕಟುಕೊಡಿಗೆ',
'ಕಟುಗೊಡಗಿ',
'ಕಟುಗೊರಲು',
'ಕಟುಮಧುರ',
'ಕಟುರೋಹಿಣಿ',
'ಕಟುವಿಪಾಕ',
'ಕಟುವಿಪಾಕೆ',
'ಕಟ್ಟಂಬಕಳ',
'ಕಟ್ಟಕಡುಹು',
'ಕಟ್ಟಣ್ಮುಕಾಱ',
'ಕಟ್ಟಧಿಕಾರ',
'ಕಟ್ಟಬಡಿಸು',
'ಕಟ್ಟಭಿಮಾನ',
'ಕಟ್ಟಭಿಮಾನಿ',
'ಕಟ್ಟಲೆಜ್ವರ',
'ಕಟ್ಟವತ್ತಿಗೆ',
'ಕಟ್ಟವಸರ',
'ಕಟ್ಟಸವಸ',
'ಕಟ್ಟಳಲಿಕೆ',
'ಕಟ್ಟಳೆಜ್ವರ',
'ಕಟ್ಟಱೆಗಾಣ್',
'ಕಟ್ಟಾಗುಳಿಕೆ',
'ಕಟ್ಟಾಣಿಪೆಣ್',
'ಕಟ್ಟಾಣಿಮುತ್ತು',
'ಕಟ್ಟಾಣಿವೆಣ್',
'ಕಟ್ಟಾಣಿಸರ',
'ಕಟ್ಟಾಮನುಷ್ಯ',
'ಕಟ್ಟಾಯಕಾರ್ತಿ',
'ಕಟ್ಟಾಯಕಾಱ',
'ಕಟ್ಟಾಯವಡೆ',
'ಕಟ್ಟಾಯವೆಱು',
'ಕಟ್ಟಾವೈದಿಕ',
'ಕಟ್ಟಾಸೆವಡು',
'ಕಟ್ಟಾಳುತನ',
'ಕಟ್ಟಿಗೆಕಾರ',
'ಕಟ್ಟಿಗೆಕಾರ್ತಿ',
'ಕಟ್ಟಿಗೆಕಾಱ',
'ಕಟ್ಟಿಗೆಗಾತಿ',
'ಕಟ್ಟಿಗೆಗಾರ',
'ಕಟ್ಟಿಗೆಗಾರ್ತಿ',
'ಕಟ್ಟಿಗೆಗಾಱ',
'ಕಟ್ಟಿಗೆವಿಡಿ',
'ಕಟ್ಟಿಗೆವೆಣ್',
'ಕಟ್ಟಿಱಿಗಾಱ',
'ಕಟ್ಟುಂಗವಳ',
'ಕಟ್ಟುಂಜುರಿಗೆ',
'ಕಟ್ಟುಕಟ್ಟಲೆ',
'ಕಟ್ಟುಕಡಹ',
'ಕಟ್ಟುಕಣಜ',
'ಕಟ್ಟುಕಾಲುವೆ',
'ಕಟ್ಟುಕೊಡಗೆ',
'ಕಟ್ಟುಕೊಡಿಗೆ',
'ಕಟ್ಟುಗಡಸು',
'ಕಟ್ಟುಗಾರಿಕೆ',
'ಕಟ್ಟುಗಾವಲು',
'ಕಟ್ಟುಗುತ್ತಗೆ',
'ಕಟ್ಟುಗುತ್ತಿಗೆ',
'ಕಟ್ಟುಗೊಡಗಿ',
'ಕಟ್ಟುಗೊಡಗೆ',
'ಕಟ್ಟುಗೊಳಿಸು',
'ಕಟ್ಟುಜ್ಜುಗಿಸು',
'ಕಟ್ಟುತ್ತಮಿಕೆ',
'ಕಟ್ಟುಪಂಜರ',
'ಕಟ್ಟುಬೆವರು',
'ಕಟ್ಟುಮಂಡಿಗೆ',
'ಕಟ್ಟುಮೊಸರು',
'ಕಟ್ಟುವಂಜರ',
'ಕಟ್ಟೆಪುರಾಣ',
'ಕಟ್ಟೊಱಲಿಕೆ',
'ಕಠಾರಾಯಿತ',
'ಕಠಾರಿಕಚ್ಚೆ',
'ಕಠಾರಿಕಾಱ',
'ಕಠಾರಿದಾರ',
'ಕಠಾರಿರಾಯ',
'ಕಠಿಣಚರ್ಮಿ',
'ಕಠಿನಚಿತ್ತ',
'ಕಠಿನಚಿತ್ತೆ',
'ಕಠಿನಶಿಕ್ಷೆ',
'ಕಠೋರಗತಿ',
'ಕಠೋರತನ',
'ಕಠೋರತರ',
'ಕಠೋರವಾಣಿ',
'ಕಡಂಬರಸ',
'ಕಡಕಡಾಟ',
'ಕಡಜೀರಿಗೆ',
'ಕಡತೇರಿಸು',
'ಕಡಪಕತ್ತಿ',
'ಕಡಬರಸ',
'ಕಡಲಣುಗ',
'ಕಡಲಣುಗಿ',
'ಕಡಲಣುಗೆ',
'ಕಡಲಪಶು',
'ಕಡಲಸಿಂಹ',
'ಕಡಲಸೌತೆ',
'ಕಡಲಹಕ್ಕಿ',
'ಕಡಲಹಾವು',
'ಕಡಲಳಿಯ',
'ಕಡಲಿಬತ್ತಿ',
'ಕಡಲುಗಳ್ಳ',
'ಕಡಲುಘಂಟೆ',
'ಕಡಲುವರಿ',
'ಕಡಲುಸಿಂಹ',
'ಕಡಲುಹಂದಿ',
'ಕಡಲೆಕಾಯಿ',
'ಕಡಲೆನಾರು',
'ಕಡಲೆಬೇಳೆ',
'ಕಡಲೆಮುಳ್ಳು',
'ಕಡಲೆವಿಟ್ಟು',
'ಕಡಲೆಹಿಟ್ಟು',
'ಕಡಲೆಹುಳಿ',
'ಕಡಲೆಱೆಯ',
'ಕಡಲೊಡೆಯ',
'ಕಡಲ್ಗಾಲುವೆ',
'ಕಡಲ್ಗುದುರೆ',
'ಕಡಲ್ವರಿಸು',
'ಕಡವಾಲರ',
'ಕಡವುಗಾರ',
'ಕಡಾರಂಬಡೆ',
'ಕಡಿಕುವಲೆ',
'ಕಡಿಗೆಬೆಲ್ಲ',
'ಕಡಿತಕಾಱ',
'ಕಡಿದುಗಾರ',
'ಕಡುಕುಂದಱಿ',
'ಕಡುಕುವರ',
'ಕಡುಗಕ್ಕಸ',
'ಕಡುಗಟ್ಟಿಗ',
'ಕಡುಗಡಲು',
'ಕಡುಗಡಿಕ',
'ಕಡುಗಡಿದು',
'ಕಡುಗಡುಪು',
'ಕಡುಗತ್ತಲೆ',
'ಕಡುಗನಲು',
'ಕಡುಗರಿದು',
'ಕಡುಗರುಣ',
'ಕಡುಗರುಣಿ',
'ಕಡುಗರುವ',
'ಕಡುಗರುವೆ',
'ಕಡುಗಲ್ಲೆರ್ದೆ',
'ಕಡುಗವಿಲ',
'ಕಡುಗಳ್ತಲೆ',
'ಕಡುಗೞಪ',
'ಕಡುಗೞ್ತಲೆ',
'ಕಡುಗಾವಲ್',
'ಕಡುಗಾಹೇಱು',
'ಕಡುಗಾಳೆಗ',
'ಕಡುಗುದುರೆ',
'ಕಡುಗುರುಡ',
'ಕಡುಗೂರಿತು',
'ಕಡುಗೆಚ್ಚಲ್',
'ಕಡುಗೆಲಸ',
'ಕಡುಗೆಲಸಿ',
'ಕಡುಗೆಸಱ್',
'ಕಡುಗೇಡಿಗ',
'ಕಡುಚದುರ',
'ಕಡುಚದುರು',
'ಕಡುಚದುರೆ',
'ಕಡುಚಪಲ',
'ಕಡುಚಪಲೆ',
'ಕಡುಚೆಲ್ವಿಕೆ',
'ಕಡುಚೋದಿಗ',
'ಕಡುಜಾಣಾಯ್ಲ',
'ಕಡುತವಕ',
'ಕಡುದಣ್ಣನೆ',
'ಕಡುದಣ್ಣಸ',
'ಕಡುದಾರಿದ್ರ',
'ಕಡುದಾರಿದ್ರ್ಯ',
'ಕಡುದುಗುಡ',
'ಕಡುದುಮ್ಮಾನ',
'ಕಡುದುರುಳ',
'ಕಡುದುರುಳೆ',
'ಕಡುದೇಸಿಗ',
'ಕಡುದೊಡಂಕು',
'ಕಡುನಳಿದು',
'ಕಡುನಾಲಿಗೆ',
'ಕಡುನಿಡಿದು',
'ಕಡುನಿಡಿಯ',
'ಕಡುನಿದ್ದೆಗೈ',
'ಕಡುನಿಬ್ಬರ',
'ನಿಡುನಿಮಿರ್',
'ಕಡುಪಗಲ್',
'ಕಡುಪಾಗಿರು',
'ಕಡುಪಾತಕ',
'ಕಡುಪಾತಕಿ',
'ಕಡುಪಾಪಿಷ್ಠ',
'ಕಡುಪುಗೆಯ್',
'ಕಡುಬಡವ',
'ಕಡುಬಡವಿ',
'ಕಡುಬತ್ತಲು',
'ಕಡುಬಯಕೆ',
'ಕಡುಬರವು',
'ಕಡುಬಲ್ಲಿತ್ತು',
'ಕಡುಬಲ್ಲಿದ',
'ಕಡುಬಿನ್ನಣ',
'ಕಡುಬಿಲ್ಗಾಱ',
'ಕಡುಬಿಸಿಲ್',
'ಕಡುಬಿಸಿಲು',
'ಕಡುಬಿಸುಪು',
'ಕಡುಬಿಱಿಸು',
'ಕಡುಬೆಟ್ಟಿತ್ತು',
'ಕಡುಬೇಗಿಸು',
'ಕಡುಭೀಕರ',
'ಕಡುಮಂದೈಸು',
'ಕಡುಮಿಡುಕು',
'ಕಡುಮಿಸುಗು',
'ಕಡುಮುನಿಸು',
'ಕಡುಮುಳಿಸು',
'ಕಡುಮೋಹಿತ',
'ಕಡುವಿಣ್ಣಿತ್ತು',
'ಕಡುವಿತ್ತೆಗ',
'ಕಡುವಿನ್ನಣ',
'ಕಡುವಿನ್ನನೆ',
'ಕಡುವಿರಿದು',
'ಕಡುವಿಲಾಸ',
'ಕಡುವಿಸಿಲ್',
'ಕಡುವಿಳಿದು',
'ಕಡುವೆಟ್ಟಿತು',
'ಕಡುವೆಟ್ಟಿತ್ತು',
'ಕಡುವೆಸನ',
'ಕಡುವೆಳಗು',
'ಕಡುವೆಱಗು',
'ಕಡುವೇಸಗೆ',
'ಕಡುವೊತ್ತಾಗು',
'ಕಡುವೊಸತು',
'ಕಡುಸಂತಸ',
'ಕಡುಸಲುಗೆ',
'ಕಡುಸಹಸಿ',
'ಕಡುಸಿತಗ',
'ಕಡುಸೆಲ್ಲೆಹ',
'ಕಡುಸೊಗಸು',
'ಕಡುಹರುಷ',
'ಕಡುಹುಕಾತಿ',
'ಕಡುಹುಕಾಱ',
'ಕಡುಹುಗಳ್ಳ',
'ಕಡೆಗಂದಲ್',
'ಕಡೆಗಣಿಕೆ',
'ಕಡೆಗಣಿಸು',
'ಕಡೆಗಣ್ಣೇಱ್',
'ಕಡೆಗಾಣಿಕೆ',
'ಕಡೆಗಾಣಿಸು',
'ಕಡೆಗೊಳಿಸು',
'ಕಡೆಗೋಲ್ವಳ್ಳಿ',
'ಕಡೆದೆಗೆಸು',
'ಕಡೆಮಾಡಿಸು',
'ಕಡೆಮುಗಿಲ್',
'ಕಡೆಮುಗಿಲು',
'ಕಡೆಯಂಗುಡು',
'ಕಡೆಯೆಯ್ದಿಸು',
'ಕಡೆವಗಲ್',
'ಕಡೆವರೆಯ',
'ಕಡೆವೆಳಗು',
'ಕಡೆವೊಳಲು',
'ಕಡೆಸಾರಿಗೆ',
'ಕಡೆಸಿಡಿಲ್',
'ಕಡೆಹಾಯಿಸು',
'ಕಡೆಹೊಲ್ಲದು',
'ಕಡ್ಡಾಯಗಾರ',
'ಕಡ್ಡಿಕಸರು',
'ಕಡ್ಡಿಪುಸ್ತಕ',
'ಕಡ್ಡಿಪೆಟ್ಟಿಗೆ',
'ಕಡ್ಡಿಪೊಟ್ಟಣ',
'ಕಡ್ಡಿಯರಗು',
'ಕಡ್ಲೆಗಂಗಾರ',
'ಕಣಕಣಿಸು',
'ಕಣಯವಟ್ಟ',
'ಕಣವರಿಕೆ',
'ಕಣಿಕಟಿಲು',
'ಕಣುಕಪ್ಪಟೆ',
'ಕಣುಕಪ್ಪಡಿ',
'ಕಣುಗುಱುಹು',
'ಕಣುಮುಚ್ಚಾಟ',
'ಕಣೆಗೞ್ತಲೆ',
'ಕಣೆಗೆದಱು',
'ಕಣ್ಕುಟಗಲ್',
'ಕಣ್ಣಂದೊೞಲಿ',
'ಕಣ್ಣಕುಟಿಕೆ',
'ಕಣ್ಣರಳಿಸು',
'ಕಣ್ಣಾಮುಚ್ಚಾಲೆ',
'ಕಣ್ಣಿಟ್ಟಿಕಾತಿ',
'ಕಣ್ಣಿಲಿಮೊಗ',
'ಕಣ್ಣೀರ್ತಟಕು',
'ಕಣ್ಣುಕಪಟ',
'ಕಣ್ಣುಕಪ್ಪಡಿ',
'ಕಣ್ಣುಕಪ್ಪಡೆ',
'ಕಣ್ಣುಕಿಸುರು',
'ಕಣ್ಣುಕುಕ್ಕಿಸು',
'ಕಣ್ಣುಕುಟಿಕೆ',
'ಕಣ್ಣುಕುಟಿಗೆ',
'ಕಣ್ಣುಕುಣಿಕೆ',
'ಕಣ್ಣುಕುದುರು',
'ಕಣ್ಣುಕೆರಶಿ',
'ಕಣ್ಣುಗೊಳಿಸು',
'ಕಣ್ಣುಚಿಮುಟು',
'ಕಣ್ಣುಚಿವುಟು',
'ಕಣ್ಣುತಾಪಾಳ',
'ಕಣ್ಣುಪಿಸುರು',
'ಕಣ್ಣುಮಸಕು',
'ಕಣ್ಣುಮಿಡುಕು',
'ಕಣ್ಣುಮುಚ್ಚಾಲೆ',
'ಕಣ್ಣುಸಟ್ಟುಗ',
'ಕಣ್ಣುಸುಳುವು',
'ಕಣ್ಣೆಮೆಯಿಕ್ಕು',
'ಕಣ್ಣೆವೆಯಿಕ್ಕು',
'ಕಣ್ದುದಿಗೆರೆ',
'ಕಣ್ಬನಿವೞೆ',
'ಕಣ್ಬರಿಗೆಡು',
'ಕಣ್ಬೇಟಂಗೊಳ್',
'ಕಣ್ಬೇಹತನ',
'ಕತಕತನೆ',
'ಕತಕಬೀಜ',
'ಕತೆಗಾರಿಕೆ',
'ಕತ್ತರಗೊಡು',
'ಕತ್ತರಿಕಾಱ',
'ಕತ್ತರಿಗಂಡಿ',
'ಕತ್ತರಿಗಡಿ',
'ಕತ್ತರಿಗಳ್ಳ',
'ಕತ್ತರಿಗಾಳ',
'ಕತ್ತರಿಗಿಡ',
'ಕತ್ತರಿಗುಳ್ಳ',
'ಕತ್ತರಿಗೊಳ್',
'ಕತ್ತರಿಗೊಳ್ಳು',
'ಕತ್ತರಿಗೋಲು',
'ಕತ್ತರಿದಡಿ',
'ಕತ್ತರಿಪಟ್ಟು',
'ಕತ್ತರಿಬೀಳು',
'ಕತ್ತರಿರಸ್ತೆ',
'ಕತ್ತರಿವಣ',
'ಕತ್ತರಿವಾಳೆ',
'ಕತ್ತರಿವಾೞೆ',
'ಕತ್ತರಿವೀಳ್ಯ',
'ಕತ್ತರಿವೆಸ',
'ಕತ್ತರಿಹುಳು',
'ಕತ್ತಲಿಕಳ್ಳಿ',
'ಕತ್ತಲಿಡಿಸು',
'ಕತ್ತಲೆಗುದ್ದು',
'ಕತ್ತಲೆಗೆಯ್',
'ಕತ್ತಲೆಮನೆ',
'ಕತ್ತಲೆವಣಿ',
'ಕತ್ತಲೆಹರ',
'ಕತ್ತಿಕಾಳೆಗ',
'ಕತ್ತಿವರಸೆ',
'ಕತ್ತಿಸಾಧಕ',
'ಕತ್ತಿಸಾಧನ',
'ಕತ್ತುರಿಕಾಮ',
'ಕತ್ತುರಿಗಂಪು',
'ಕತ್ತುರಿನಾಮ',
'ಕತ್ತುರಿನೀರ್',
'ಕತ್ತುರಿಬೊಟ್ಟು',
'ಕತ್ತುರಿಮಿಗ',
'ಕತ್ತುರಿವಳೆ',
'ಕತ್ತುರಿವುಲ್ಲೆ',
'ಕತ್ತುರಿವೆಕ್ಕು',
'ಕತ್ತೆಕಾಗದ',
'ಕತ್ತೆಕಿರುಬ',
'ಕತ್ತೆಕುರುಬ',
'ಕತ್ತೆಕೆಲಸ',
'ಕತ್ತೆಗಿರುಬ',
'ಕತ್ತೆಗುರುವ',
'ಕತ್ತೆಚಾಕರಿ',
'ಕತ್ತೆಸಂಪಿಗೆ',
'ಕಥನಾಲಾಪ',
'ಕಥಾನಾಯಕ',
'ಕಥಾಪ್ರವಾಹ',
'ಕಥಾಪ್ರಸಂಗ',
'ಕಥಾಮಂಜರಿ',
'ಕಥಾವತಾರ',
'ಕಥಾವಸಾನ',
'ಕಥಿತಮೂಲ್ಯ',
'ಕದಂಬಲಿಪಿ',
'ಕದಕತನ',
'ಕದಕದಪು',
'ಕದಕದಿಸು',
'ಕದಕಿತನ',
'ಕದಕ್ಕದಿಸು',
'ಕದಡಾರತಿ',
'ಕದಡುಗಣ್',
'ಕದಡುಗಳೆ',
'ಕದಡುದುಃಖ',
'ಕದಡುದೋಱು',
'ಕದಡುನಗೆ',
'ಕದಡುನೀರ್',
'ಕದಡುಬಗೆ',
'ಕದಡುಮನ',
'ಕದನಕಣ',
'ಕದನಕೇಳಿ',
'ಕದನಕ್ಷೇತ್ರ',
'ಕದನಗಲಿ',
'ಕದನಪಥ',
'ಕದನರಾಗಿ',
'ಕದನಲಗ್ಗೆ',
'ಕದನಾನಕ',
'ಕದನಾಳಾಪ',
'ಕದನೋದ್ದಾಮ',
'ಕದನ್ನಭುಜ',
'ಕದಬದಂಗೈ',
'ಕದಬದಲ್',
'ಕದಬದಿಸು',
'ಕದಬಳಿಸು',
'ಕದಬಾಗಿಲು',
'ಕದರಕಸ',
'ಕದರಗಿಣಿ',
'ಕದರಡಕೆ',
'ಕದರುಕಡ್ಡಿ',
'ಕದರುಬತ್ತ',
'ಕದಲಾರತಿ',
'ಕದಲೀಪಾಕ',
'ಕದಳೀಗರ್ಭ',
'ಕದಳೀಘಾತ',
'ಕದಳೀದಳ',
'ಕದಳೀಪತ್ರ',
'ಕದಳೀವನ',
'ಕದಳೀಷಂಡ',
'ಕದಿರುಗಡೆ',
'ಕದಿರುಗಿಣಿ',
'ಕದೀಮತನ',
'ಕದುಕುನೋಟ',
'ಕದುಬುಗೂಡು',
'ಕದುಬುಗೊಳ್',
'ಕದ್ದಾಳವಳ್ಳಿ',
'ಕದ್ದೆಹಕಾಱ',
'ಕದ್ರ್ರೂಜವೇಣಿ',
'ಕನಕಕ್ಷೀರಿ',
'ಕನಕಗಿರಿ',
'ಕನಕನಗ',
'ಕನಕಪತ್ರ',
'ಕನಕಪೀಠ',
'ಕನಕರಿಸು',
'ಕನಕವೃಷ್ಟಿ',
'ಕನಕಾಂಬರ',
'ಕನಕಾಂಬರಿ',
'ಕನಕಾಚಲ',
'ಕನಕಾಚಳ',
'ಕನಕಾಧ್ಯಕ್ಷ',
'ಕನಕಾಲುಕೆ',
'ಕನಕಾವಳಿ',
'ಕನಕೋದರ',
'ಕನತ್ಕಾಂಚನ',
'ಕನರುಗಾಯ್',
'ಕನರ್ಗೊನರ್',
'ಕನವನಿಸು',
'ಕನವರಸು',
'ಕನವರಿಕೆ',
'ಕನವರಿಸು',
'ಕನಸುಂಗುಳಿ',
'ಕನಸುಗಾಣ್',
'ಕನಸುಗಾರ',
'ಕನಸುದೋಱು',
'ಕನಿಕರಿಸು',
'ಕನಿಷ್ಠವೃತ್ತಿ',
'ಕನುಸುರಗಿ',
'ಕನೆಕೆಂದಾಳ',
'ಕನ್ನಕತ್ತರಿ',
'ಕನ್ನಕಳವು',
'ಕನ್ನಗತ್ತರಿ',
'ಕನ್ನಗಳವು',
'ಕನ್ನಘಾತಕ',
'ಕನ್ನಡಗಬ್ಬ',
'ಕನ್ನಡಗೌಳ',
'ಕನ್ನಡತನ',
'ಕನ್ನಡವಕ್ಕಿ',
'ಕನ್ನಡವಾತು',
'ಕನ್ನಡವೀಣೆ',
'ಕನ್ನಡಶಾಖೆ',
'ಕನ್ನಡಹಕ್ಕಿ',
'ಕನ್ನಡಿಗಿತಿ',
'ಕನ್ನಡಿಗೋಡೆ',
'ಕನ್ನಡಿತೊಟ್ಟಿ',
'ಕನ್ನಡಿಬಳೆ',
'ಕನ್ನಡಿಯೆಲೆ',
'ಕನ್ನಡಿವಣ',
'ಕನ್ನಡಿವಿಡಿ',
'ಕನ್ನಡಿವೆಣ್',
'ಕನ್ನಡಿಹಾವು',
'ಕನ್ನಡೇತರ',
'ಕನ್ನಮಿಕ್ಕಿಸು',
'ಕನ್ನವಟ್ಟಿಗೆ',
'ಕನ್ನವಿಕ್ಕಿಸು',
'ಕನ್ನಿಕೋಂಬರೆ',
'ಕನ್ನೆಕುಮಾರಿ',
'ಕನ್ನೆಗೋಗಿಲೆ',
'ಕನ್ನೆಗೋಸಾಸ',
'ಕನ್ನೆವಸದಿ',
'ಕನ್ನೆವುಯಿಲ್',
'ಕನ್ಯಕಾಜಾತ',
'ಕನ್ಯಾಕುಮಾರಿ',
'ಕನ್ಯಾವರಣ',
'ಕಪಕಪನೆ',
'ಕಪಟಕೃತ್ಯ',
'ಕಪಟಕ್ರಿಯೆ',
'ಕಪಟಗಾರ',
'ಕಪಟಗಾರ್ತಿ',
'ಕಪಟತನ',
'ಕಪಟಪಟು',
'ಕಪಟವೇಷ',
'ಕಪಟವೇಷಿ',
'ಕಪಟೋಪಾಧ್ಯ',
'ಕಪರಬಾಳೆ',
'ಕಪರಸುಂಠಿ',
'ಕಪರ್ದಿಕಾಸು',
'ಕಪಾಲಕ್ರಿಯೆ',
'ಕಪಾಲಪಾಣಿ',
'ಕಪಾಲಭಾತಿ',
'ಕಪಾಲಶೂಲೆ',
'ಕಪಾಲಹಸ್ತ',
'ಕಪಾಳಮೋಕ್ಷ',
'ಕಪಾಳಶುದ್ಧಿ',
'ಕಪಿಕೇತನ',
'ಕಪಿತುರಚಿ',
'ಕಪಿತುರುಚೆ',
'ಕಪಿತ್ಥಹಸ್ತ',
'ಕಪಿತ್ಥಾಷ್ಟಕ',
'ಕಪಿಲಗೆಂಪು',
'ಕಪಿಲಚಟ್ಟಿ',
'ಕಪಿಲವರ್ಣ',
'ಕಪಿಲಾರಂಗು',
'ಕಪಿಲಾಷಷ್ಠಿ',
'ಕಪೋತಪಾಲಿ',
'ಕಪೋತಲೇಶ್ಯೆ',
'ಕಪೋತಹಸ್ತ',
'ಕಪೋತಾಂಜನ',
'ಕಪೋಲಪತ್ರ',
'ಕಪೋಲಪತ್ರೆ',
'ಕಪೋಲಾರ್ಬುದ',
'ಕಪೋಳಪತ್ರ',
'ಕಪ್ಪಕಾಣಿಕೆ',
'ಕಪ್ಪಡಿವೇಷ',
'ಕಪ್ಪರಚಟ್ಟಿ',
'ಕಪ್ಪರಚಿನ್ನಿ',
'ಕಪ್ಪರವಳ್ಳಿ',
'ಕಪ್ಪರಶೆಟ್ಟಿ',
'ಕಪ್ಪರಿತುತ್ತ',
'ಕಪ್ಪರಿತುತ್ಥ',
'ಕಪ್ಪಲಿತುತ್ತ',
'ಕಪ್ಪಲಿಸತ್ವ',
'ಕಪ್ಪಲುನರಿ',
'ಕಪ್ಪಲುಬೀಳು',
'ಕಪ್ಪಿಯೆತ್ತಿಗೆ',
'ಕಪ್ಪುಅಧ್ಯಾಯ',
'ಕಪ್ಪು ಅಭ್ರಕ',
'ಕಪ್ಪುಕಾಣಿಕೆ',
'ಕಪ್ಪುಕೊರಳ',
'ಕಪ್ಪುಗಾಣಿಕೆ',
'ಕಪ್ಪುಗೊರಲ',
'ಕಪ್ಪುಗೊರಳ',
'ಕಪ್ಪು ಬಸುರಿ',
'ಕಪ್ಪುಬೊಬ್ಬುಳಿ',
'ಕಪ್ಪುಮಂಕಾಳ',
'ಕಪ್ಪುಮೆಣಸು',
'ಕಪ್ಪುರಗಂಧ',
'ಕಪ್ಪುರಗಂಧಿ',
'ಕಪ್ಪುರಗಂಪು',
'ಕಪ್ಪುರದೀವ',
'ಕಪ್ಪುರಬೆಂಡೆ',
'ಕಪ್ಪುರವಟ್ಟು',
'ಕಪ್ಪುರವಳ್ಕು',
'ಕಪ್ಪುರವಳ್ಳಿ',
'ಕಪ್ಪುರವಾೞೆ',
'ಕಪ್ಪುರಶುಂಠಿ',
'ಕಪ್ಪುರಸೆಟ್ಟಿ',
'ಕಪ್ಪುಹಲಗೆ',
'ಕಪ್ಪೆಕುಪ್ಪುಳು',
'ಕಪ್ಪೆಯಸೊಪ್ಪು',
'ಕಬಂಧಬಾಹು',
'ಕಬರಸ್ತಾನ',
'ಕಬರಸ್ಥಾನ',
'ಕಬರಿಭರ',
'ಕಬರೀಭರ',
'ಕಬರೀಭಾರ',
'ಕಬರುದಾರು',
'ಕಬಳಂಗೊಳ್',
'ಕಬಳಗೆಯ್',
'ಕಬಳಗೊಳ್',
'ಕಬಳಾಹಾರ',
'ಕಬಳಿಗಿಡ',
'ಕಬಾಬಚಿನ್ನಿ',
'ಕಬುಲಾಯತಿ',
'ಕಬ್ಬಲಗಿತ್ತಿ',
'ಕಬ್ಬಿಗಗಳ್ಳ',
'ಕಬ್ಬಿಗವಂದಿ',
'ಕಬ್ಬಿಣಕಿಟ್ಟ',
'ಕಬ್ಬಿಲಗಿತ್ತಿ',
'ಕಬ್ಬಿಲದೆಱೆ',
'ಕಬ್ಬಿಲಿಗಿತ್ತಿ',
'ಕಬ್ಬುನಕೋಲು',
'ಕಬ್ಬುನಗಲ್',
'ಕಮಂದಮರ',
'ಕಮಗಗ್ಗರೆ',
'ಕಮಠಾಧಿಪ',
'ಕಮತಕಟ್ಟೆ',
'ಕಮತರತ',
'ಕಮನಂಬೆಱು',
'ಕಮನೀಯತೆ',
'ಕಮರಕಸ',
'ಕಮರದ್ರಾಕ್ಷಿ',
'ಕಮರಬಂದ',
'ಕಮರಬಂದು',
'ಕಮರಿಬಂದ',
'ಕಮರುತೇಗು',
'ಕಮರುಬೀಳು',
'ಕಮಲಗಂಧಿ',
'ಕಮಲಗರ್ಭ',
'ಕಮಲಜಾಂಡ',
'ಕಮಲನಾಭ',
'ಕಮಲಭವ',
'ಕಮಲಮಿತ್ರ',
'ಕಮಲವೈರಿ',
'ಕಮಲಷಂಡ',
'ಕಮಲಾಂಬಕ',
'ಕಮಲಾಕರ',
'ಕಮಲಾಗಾರ',
'ಕಮಲಾನನೆ',
'ಕಮಲಾಲಯೆ',
'ಕಮಲಾಸನ',
'ಕಮಲಾಸನೆ',
'ಕಮಲಾಹಣ್ಣು',
'ಕಮಲೇಕ್ಷಣ',
'ಕಮಲೋತ್ತರ',
'ಕಮಲೋದಯ',
'ಕಮಲೋದರ',
'ಕಮಱುದೇಗು',
'ಕಮಱುನಾತ',
'ಕಮಱುನಾಱು',
'ಕಮಾನುಕಂಡಿ',
'ಕಮಾನುಕೋಣೆ',
'ಕಮಾನುಗಾಡಿ',
'ಕಮಾನುಜುಟ್ಟು',
'ಕಮಿಲಾರೋಗ',
'ಕಮ್ಮಂಗಣೆಯ',
'ಕಮ್ಮಂಗಳವೆ',
'ಕಮ್ಮಂಗೞವೆ',
'ಕಮ್ಮಗಂಧಿನಿ',
'ಕಮ್ಮಗಣೆಯ',
'ಕಮ್ಮಗಳಮೆ',
'ಕಮ್ಮಗೞವೆ',
'ಕಮ್ಮರಿಯೋಜ',
'ಕಮ್ಮರಿವೀಳ್',
'ಕಮ್ಮರುಬಂದು',
'ಕಮ್ಮಲರಂಬ',
'ಕಮ್ಮಲರಂಬು',
'ಕಮ್ಮಲರ್ಗೋಲ್',
'ಕಮ್ಮಲರ್ಗೋಲ',
'ಕಮ್ಮಲರ್ಗೋಲು',
'ಕಮ್ಮಲರ್ಮಾಲೆ',
'ಕಮ್ಮಲರ್ವಕ್ಕಿ',
'ಕಮ್ಮಱಗೇರಿ',
'ಕಮ್ಮಱವಾಳ',
'ಕಮ್ಮಱವಿಟ್ಟಿ',
'ಕಮ್ಮಱಿಯೋಜ',
'ಕಮ್ಮಾರಗಾತಿ',
'ಕಮ್ಮಾರಗಿತ್ತಿ',
'ಕಮ್ಮಾರಸಾಲೆ',
'ಕಮ್ಮೆಲರ್ದೂಳಿ',
'ಕಯಿನೀರೆಱೆ',
'ಕಯ್ದುಳಿಗಿತಿ',
'ಕಯ್ಪಡವಲ',
'ಕಯ್ರಗುಳಿಗೆ',
'ಕಯ್ರದಂಬುಲ',
'ಕರಂಜಿಕಾಯ್',
'ಕರಂಜಿಕಾಯಿ',
'ಕರಕಕಾಯಿ',
'ಕರಕದರಿ',
'ಕರಕರಸು',
'ಕರಕರಿಸು',
'ಕರಕವೃಷ್ಟಿ',
'ಕರಕೋಪಲ',
'ಕರಗಂದುಗ',
'ಕರಗಂಬೊಱು',
'ಕರಗಪಡಿ',
'ಕರಗಬಳ',
'ಕರಗಮಾನ್ಯ',
'ಕರಗವಳ',
'ಕರಗವಿಡಿ',
'ಕರಗಸಿಗ',
'ಕರಗುಪಡಿ',
'ಕರಗ್ರಹಣ',
'ಕರಚಿಕಾಯಿ',
'ಕರಜಿಕಾಯಿ',
'ಕರಜೀಕಾಯಿ',
'ಕರಟುಕಾಯಿ',
'ಕರಟುಬೀಳು',
'ಕರಡಜ್ವರ',
'ಕರಡವುಲ್',
'ಕರಡಹುಲ್ಲು',
'ಕರಡಿಗೊಲ್ಲ',
'ಕರಡಿಮೇಳ',
'ಕರಡುಕಟ್ಟು',
'ಕರಡುಗಟ್ಟು',
'ಕರಡುಪ್ರತಿ',
'ಕರಡುಮುತ್ತು',
'ಕರಡುಯಾಕೆ',
'ಕರಡುಸಾಲ',
'ಕರಣಗ್ರಂಥ',
'ಕರಣಗ್ರಾಮ',
'ಕರಣತ್ರಯ',
'ಕರಣವೃತ್ತಿ',
'ಕರಣವೇದ್ಯ',
'ಕರಣವೇಳೆ',
'ಕರಣಶಾಲೆ',
'ಕರಣಶುದ್ಧಿ',
'ಕರಣಸಾಲೆ',
'ಕರಣಾಗ್ರಣಿ',
'ಕರಣಿಕತ್ವ',
'ಕರಣಿಕರ',
'ಕರಣೇಂದ್ರಿಯ',
'ಕರತರ್ಜನ',
'ಕರತಾಡನ',
'ಕರದೀವಿಗೆ',
'ಕರಪಲ್ಲವ',
'ಕರಪಾಲಿಕೆ',
'ಕರಪೀಡನ',
'ಕರಬದರ',
'ಕರಬಾಲಿಕೆ',
'ಕರಭೂಷಣ',
'ಕರಮರಿಕೆ',
'ಕರಮರ್ದಕ',
'ಕರಮರ್ದನ',
'ಕರಮಾಪಕ',
'ಕರವಂಡಿಗೆ',
'ಕರವಂದಿಗೆ',
'ಕರವಡಿಗೆ',
'ಕರವತಿಗೆ',
'ಕರವಳಯ',
'ಕರವಿಕೀರ್ಣ',
'ಕರವಕೀರ್ಣ',
'ಕರವೊಳ್ಳಿತು',
'ಕರಶೀಕರ',
'ಕರಸಂಪುಟ',
'ಕರಸೀಕರ',
'ಕರಹಟಿಗ',
'ಕರಹಡಿಗ',
'ಕರಹಡಿಚ',
'ಕರಹಡಿಚೆ',
'ಕರಹಾಟಕ',
'ಕರಾಕರಣೆ',
'ಕರಾನ್ವೇಷಣ',
'ಕರಾಭರಣ',
'ಕರಾರನಾಮೆ',
'ಕರಾರಪತ್ರ',
'ಕರಾರುನಾಮೆ',
'ಕರಾರುಪತ್ರ',
'ಕರಾರುವಾಕ್',
'ಕರಾರುವಾಕು',
'ಕರಾರುವಾಕ್ಕು',
'ಕರಾಲಂಬನ',
'ಕರಾಳಕೃತ್ಯ',
'ಕರಾಳಛಾಯೆ',
'ಕರಾಳದೃಷ್ಟಿ',
'ಕರಾಳಮತಿ',
'ಕರಾಳಾಕೃತಿ',
'ಕರಿಂಕುವರಿ',
'ಕರಿಂಕುವೀೞ್',
'ಕರಿಂಕುವೋಗು',
'ಕರಿಂಕೇೞಿಸು',
'ಕರಿಅರಳೆ',
'ಕರಿಇಬ್ಬಡಿ',
'ಕರಿಉದರೆ',
'ಕರಿಉಮ್ಮತ್ತಿ',
'ಕರಿಕವೋಗು',
'ಕರಿಕುವಡೆ',
'ಕರಿಕುವರಿ',
'ಕರಿಕೆಸವು',
'ಕರಿಕೊರಂಗು',
'ಕರಿಗಜ್ಜಿಗೆ',
'ಕರಿಗಡುಬು',
'ಕರಿಗಣಿಕೆ',
'ಕರಿಗವನ',
'ಕರಿಗೊಬ್ಬಳಿ',
'ಕರಿಗೊಳಿಸು',
'ಕರಿಗೊಱಡು',
'ಕರಿಗೋರಟೆ',
'ಕರಿಜೀನಂಗಿ',
'ಕರಿಜೀರಗೆ',
'ಕರಿಜೀರಿಗೆ',
'ಕರಿತುಮರಿ',
'ಕರಿತುಳಸಿ',
'ಕರಿದಾನವ',
'ಕರಿದಿಂಗಳು',
'ಕರಿದಿವಸ',
'ಕರಿನೇರಳೆ',
'ಕರಿಪುಗಾಱು',
'ಕರಿಬರಗು',
'ಕರಿಬಸುರಿ',
'ಕರಿಬಿದಿರು',
'ಕರಿಮುತ್ತಲ',
'ಕರಿಮುತ್ತುಗ',
'ಕರಿಮುರಿಕ',
'ಕರಿಮೆಣಸು',
'ಕರಿಯಿರುವೆ',
'ಕರಿಯಿಱುವೆ',
'ಕರಿಲಾಮಂಚ',
'ಕರಿಲಾವಂಚ',
'ಕರಿಲಾವಗ',
'ಕರಿವದನ',
'ಕರಿವುಮಗ',
'ಕರಿಶಿವನಿ',
'ಕರಿಸಾಸಿವೆ',
'ಕರಿಸೇವಗೆ',
'ಕರಿಹಿಪ್ಪಲಿ',
'ಕರಿಹುಳಿಪ',
'ಕರಿಹೆಸ್ಸರೆ',
'ಕರುಕುವಡೆ',
'ಕರುಗೊಳಿಸು',
'ಕರುಟುಬೀಳು',
'ಕರುಣಂಗೆಯ್',
'ಕರುಣಂಬಡು',
'ಕರುಣಕಥೆ',
'ಕರುಣದೋಱು',
'ಕರುಣಮೋಹ',
'ಕರುಣಾಂಬುಧಿ',
'ಕರುಣಾಕರ',
'ಕರುಣಾಧ್ವನಿ',
'ಕರುಣಾಪಾಂಗ',
'ಕರುಣಾಪಾತ್ರ',
'ಕರುಣಾಪಾತ್ರೆ',
'ಕರುಣಾರಸ',
'ಕರುಣಾವಂತ',
'ಕರುಣಾವಂತೆ',
'ಕರುಣಾಸಿಂಧು',
'ಕರುಣಾಸ್ಪದ',
'ಕರುಣಾಸ್ಪದೆ',
'ಕರುಣಿತನ',
'ಕರುಬುತನ',
'ಕರುಮಗತಿ',
'ಕರುವಗೞ್ದೆ',
'ಕರುವಗೆಯ್',
'ಕರುವಗೆಯಿ',
'ಕರವವನೆ',
'ಕರುವಿಕ್ಕಿಸು',
'ಕರುವಿಡಿಸು',
'ಕರುಳುಹುರಿ',
'ಕರುಳ್ತೊಡಕು',
'ಕರುಳ್ವಿಣಿಲ್',
'ಕರೆಕರೆಗ',
'ಕರೆಬಯಲು',
'ಕರೆವಟ್ಟಣ',
'ಕರೆಹಟ್ಟಿಗ',
'ಕರೋಟಿಮಾಲಿ',
'ಕರೋಟಿಮಾಲೆ',
'ಕರೋಡಗಿರಿ',
'ಕರೋಬಾಮಣಿ',
'ಕರ್ಕಟಶೃಂಗಿ',
'ಕರ್ಕಡವಂದಿ',
'ಕರ್ಕಶಕಾರಿ',
'ಕರ್ಕಶಗೊಳ್',
'ಕರ್ಕಶಯಿಸು',
'ಕರ್ಕಶಲೋಮ',
'ಕರ್ಕಶವಡೆ',
'ಕರ್ಕಶವೃತ್ತಿ',
'ಕರ್ಕಾಟಶೃಂಗಿ',
'ಕರ್ಗುಡಿವಡು',
'ಕರ್ಚೂರಿಗೆಡ್ಡೆ',
'ಕರ್ಣಕಟುಕ',
'ಕರ್ಣಕಠೋರ',
'ಕರ್ಣಕುಂಡಲ',
'ಕರ್ಣಕೋಟರ',
'ಕರ್ಣಚಾಲನ',
'ಕರ್ಣಜಲೌಕೆ',
'ಕರ್ಣತಾಟಂಕ',
'ಕರ್ಣತಾಡನ',
'ಕರ್ಣದೂಷಿಕೆ',
'ಕರ್ಣಧಾರಕ',
'ಕರ್ಣಧಾರತ್ವ',
'ಕರ್ಣಧಾವಕ',
'ಕರ್ಣನಳಿಕೆ',
'ಕರ್ಣಪಟಲ',
'ಕರ್ಣಪಟುತ್ವ',
'ಕರ್ಣಪಾಲಿಕೆ',
'ಕರ್ಣಪಿಶಾಚ',
'ಕರ್ಣಪಿಶಾಚಿ',
'ಕರ್ಣಭೂಷಣ',
'ಕರ್ಣವೇಷ್ಟನ',
'ಕರ್ಣಾಕರ್ಣಿಕೆ',
'ಕರ್ಣಾಭರಣ',
'ಕರ್ಣಾವತಂಸ',
'ಕರ್ಣಿಕಾಗ್ರಂಥಿ',
'ಕರ್ತಬಗಾರಿ',
'ಕರ್ತಬುಗಾರಿ',
'ಕರ್ತರಿಗಾಲು',
'ಕರ್ತರಿಮಂಡಿ',
'ಕರ್ತರೀಮುಖ',
'ಕರ್ತವ್ಯಚ್ಯುತ',
'ಕರ್ತವ್ಯಚ್ಯುತಿ',
'ಕರ್ತವ್ಯಚ್ಯುತೆ',
'ಕರ್ತವ್ಯನಿಷ್ಠ',
'ಕರ್ತವ್ಯನಿಷ್ಠೆ',
'ಕರ್ತವ್ಯಪ್ರಜ್ಞೆ',
'ಕರ್ತವ್ಯಭ್ರಷ್ಟ',
'ಕರ್ತವ್ಯಭ್ರಷ್ಟೆ',
'ಕರ್ತವ್ಯಮೂಢ',
'ಕರ್ತವ್ಯಮೂಢೆ',
'ಕರ್ತವ್ಯಲೋಪ',
'ಕರ್ತವ್ಯಸಾರ',
'ಕರ್ತೃತ್ವಶಾಲಿ',
'ಕರ್ದಮಂಗರ್ಚು',
'ಕರ್ದಮಂಬೊರೆ',
'ಕರ್ದಮೋದಕ',
'ಕರ್ಪುರಚಿನ್ನಿ',
'ಕರ್ಪುರವಳ್ಳಿ',
'ಕರ್ಪುರವಾಳೆ',
'ಕರ್ಪುರಶುಂಠಿ',
'ಕರ್ಪುರಸುಂಟಿ',
'ಕರ್ಪುವೆರಸು',
'ಕರ್ಪೂರಚಿನ್ನಿ',
'ಕರ್ಪೂರತೈಲ',
'ಕರ್ಪೂರಬಾಳೆ',
'ಕರ್ಪೂರಬೆಂಡೆ',
'ಕರ್ಪೂರಮಣಿ',
'ಕರ್ಪೂರವಳ್ಳಿ',
'ಕರ್ಪೂರವಾಳೆ',
'ಕರ್ಪೂರಶುಂಠಿ',
'ಕರ್ಪೂರಾರತಿ',
'ಕರ್ಬೊಂಜಲಾಕೆ',
'ಕರ್ಮಕೀಲಕ',
'ಕರ್ಮಕೌಶಲ',
'ಕರ್ಮಕ್ಷಪಣ',
'ಕರ್ಮಚಾಂಡಾಲ',
'ಕರ್ಮಚಾಂಡಾಳ',
'ಕರ್ಮಚಾಂಡಾಳೆ',
'ಕರ್ಮಠತನ',
'ಕರ್ಮಧಾರಯ',
'ಕರ್ಮನಿರ್ಜರೆ',
'ಕರ್ಮಬಂಧನ',
'ಕರ್ಮಯೋಗಿನಿ',
'ಕರ್ಮವರ್ಗಣೆ',
'ಕರ್ಮವಿದೂರ',
'ಕರ್ಮವಿದೂರೆ',
'ಕರ್ಮವಿಪಾಕ',
'ಕರ್ಮವ್ಯಸನಿ',
'ಕರ್ಮಸಂನ್ಯಾಸ',
'ಕರ್ಮಸಚಿವ',
'ಕರ್ಮಸನ್ಯಾಸ',
'ಕರ್ಮಸನ್ಯಾಸಿ',
'ಕರ್ಮಸಾಧಕ',
'ಕರ್ಮಾಧಿಕಾರ',
'ಕರ್ಮಾನುಷ್ಠಾನ',
'ಕರ್ವಣಿವಚ್ಚ',
'ಕರ್ವಿಲ್ಲುಗಾಱ',
'ಕಲಂಬಾಸನ',
'ಕಲಕಂಡುಗ',
'ಕಲಕಲಾಟ',
'ಕಲಕಲಿಸು',
'ಕಲಕುನೀರು',
'ಕಲಗರಿಕೆ',
'ಕಲಪರಟಿ',
'ಕಲಪರಟೆ',
'ಕಲಬೆರಕೆ',
'ಕಲಮುದಾನಿ',
'ಕಲಲಕಣ',
'ಕಲವರಿಕೆ',
'ಕಲವರಿಸು',
'ಕಲಶಕುಚ',
'ಕಲಶಕುಚೆ',
'ಕಲಶಗಿತ್ತಿ',
'ಕಲಶಪೂಜೆ',
'ಕಲಶಪ್ರಾಯ',
'ಕಲಶರೇಖೆ',
'ಕಲಶವಿಡು',
'ಕಲಶಸ್ನಾನ',
'ಕಲಶೋದಕ',
'ಕಲಸುಗೂಳ್',
'ಕಲಸುಗೂಳು',
'ಕಲಸುಗೂೞ್',
'ಕಲಸೋಗರ',
'ಕಲಹಂದಾಳ್',
'ಕಲಹಕಾರಿ',
'ಕಲಹಕೇತ',
'ಕಲಹಕೇತಿ',
'ಕಲಹಗಂಟಿ',
'ಕಲಹಗತಿ',
'ಕಲಯಪ್ರಿಯ',
'ಕಲಯಪ್ರಿಯೆ',
'ಕಲಹಾಶನ',
'ಕಲಹಾಳಿಗ',
'ಕಲಹೋದ್ಯುಕ್ತ',
'ಕಲಹೋದ್ಯುಕ್ತೆ',
'ಕಲಾಕಲಾಪ',
'ಕಲಾಕಲಿತ',
'ಕಲಾಕಲಿತೆ',
'ಕಲಾಕುಶಲ',
'ಕಲಾಕುಶಲೆ',
'ಕಲಾಕುಸುರಿ',
'ಕಲಾಕುಸುರು',
'ಕಲಾಕೋವಿದ',
'ಕಲಾಕೋವಿದೆ',
'ಕಲಾಕೌಶಲ',
'ಕಲಾಕೌಶಲ್ಯ',
'ಕಲಾತ್ಮಕತೆ',
'ಕಲಾದಹನ',
'ಕಲಾಪಂಡಿತ',
'ಕಲಾಪಮಾಲೆ',
'ಕಲಾಪಿವಾಹ',
'ಕಲಾಪ್ರವೀಣ',
'ಕಲಾಪ್ರವೀಣೆ',
'ಕಲಾಭಿಮಾನಿ',
'ಕಲಾಯಗಾರ',
'ಕಲಾಯಿಕಾರ',
'ಕಲಾಯಿಗಾರ',
'ಕಲಾವಂತಿಕೆ',
'ಕಲಾವಂತಿಗೆ',
'ಕಲಾವಂತಿನಿ',
'ಕಲಾವಿಜ್ಞಾನ',
'ಕಲಾವಿಲಾಸಿ',
'ಕಲಾಸಿಪಡೆ',
'ಕಲಾಹೀನತೆ',
'ಕಲಿಕಸ್ತೂರಿ',
'ಕಲಿಕಾರಕ',
'ಕಲಿಗೂಂಟಣಿ',
'ಕಲಿತವಿದ್ಯ',
'ಕಲಿನಾಶನ',
'ಕಲಿನಾಶಿನಿ',
'ಕಲಿಪುರುಷ',
'ಕಲಿಯೇರಿಸು',
'ಕಲಿಯೇಱಿಸು',
'ಕಲುಂಬುಗೊಳ್',
'ಕಲುಕರಕ',
'ಕಲುಕುಟಕ',
'ಕಲುಕುಟಿಕ',
'ಕಲುಕುಟಿಗ',
'ಕಲುಕುಟ್ಟಿಗ',
'ಕಲುಗವಣಿ',
'ಕಲುಗುಟಿಗ',
'ಕಲುಗೆಲಸ',
'ಕಲುದರಸಿ',
'ಕಲುಪರಟಿ',
'ಕಲುಮಾನಸ',
'ಕಲುಮಾನಸೆ',
'ಕಲುಮೊರಡಿ',
'ಕಲುವಾದರಿ',
'ಕಲುಹೃದಯ',
'ಕಲುಹೃದಯೆ',
'ಕಲೆಗಾರಿಕೆ',
'ಕಲೆಬೆರಕೆ',
'ಕಲೆವೆರಸು',
'ಕಲೋಪಾಸಕ',
'ಕಲೋಪಾಸಕಿ',
'ಕಲೋಪಾಸನೆ',
'ಕಲ್ಗುಂಡುವಳೆ',
'ಕಲ್ನಡುಗೊಡು',
'ಕಲ್ನಾಟುಗೊಡು',
'ಕಲ್ನಾಡುಗೊಡು',
'ಕಲ್ನಿಲೆನಿಲ್',
'ಕಲ್ನೆಲೆನಿಲ್',
'ಕಲ್ಪಕಲತೆ',
'ಕಲ್ಪಕುಜಾತ',
'ಕಲ್ಪಜಪತಿ',
'ಕಲ್ಪನಾಕುಬ್ಜ',
'ಕಲ್ಪಪಾದಪ',
'ಕಲ್ಪಭೂರುಹ',
'ಕಲ್ಪಮಹೀಜ',
'ಕಲ್ಪವಾಸಿಗ',
'ಕಲ್ಪಾಂತಕಾಲ',
'ಕಲ್ಪಾಂತಸ್ಥಾಯಿ',
'ಕಲ್ಪಾವನಿಜ',
'ಕಲ್ಪಾವನೀಜ',
'ಕಲ್ಪತೋಪಮೆ',
'ಕಲ್ಮುತ್ತುಬೀಜ',
'ಕಲ್ಯದರಸಿ',
'ಕಲ್ಯಾಣಕ್ಷಾರ',
'ಕಲ್ಯಾಣಗರ್ಭ',
'ಕಲ್ಯಾಣಘೃತ',
'ಕಲ್ಯಾಣಪೂರಿ',
'ಕಲ್ಯಾಣಬಾಳೆ',
'ಕಲ್ಯಾಣಮಿತ್ರ',
'ಕಲ್ಯಾಣಸೇವೆ',
'ಕಲ್ಲಂಗಡಲೆ',
'ಕಲ್ಲತಿಗಡು',
'ಕಲ್ಲಹಾವಸೆ',
'ಕಲ್ಲಹಾವುಸೆ',
'ಕಲ್ಲಿಯೋಗರ',
'ಕಲ್ಲುಕರಕ',
'ಕಲ್ಲುಕರಗ',
'ಕಲ್ಲುಕುಟರಿ',
'ಕಲ್ಲುಕುಟಿಕ',
'ಕಲ್ಲುಕುಟಿಗ',
'ಕಲ್ಲುಕುಟುಕ',
'ಕಲ್ಲುಕುಟ್ಟಿಗ',
'ಕಲ್ಲುಕುಣಿಕೆ',
'ಕಲ್ಲುಕೆಲಸ',
'ಕಲ್ಲುಗರಿಕೆ',
'ಕಲ್ಲುಗುಗ್ಗುಳ',
'ಕಲ್ಲುಗೌಜಲು',
'ಕಲ್ಲುಬಸರೆ',
'ಕಲ್ಲುಬಸುರಿ',
'ಕಲ್ಲುಮನಸ್ಸು',
'ಕಲ್ಲುಮರಗೆ',
'ಕಲ್ಲುಮರಿಗೆ',
'ಕಲ್ಲುವರದ',
'ಕಲ್ಲುಸಂಪಿಗೆ',
'ಕಲ್ಲುಸಕ್ಕರೆ',
'ಕಲ್ಲುಹೋಳಕ',
'ಕಲ್ಲೆರ್ದೆಗವಿ',
'ಕಲ್ಲೆರ್ದೆತನ',
'ಕಲ್ಲೋಜತನ',
'ಕಲ್ಲೋಲಮಾಲೆ',
'ಕವಕವಿಸು',
'ಕವಚಗ್ರಂಥ',
'ಕವಚದೊಡು',
'ಕವಚಹರ',
'ಕವಟೆಕಾಯಿ',
'ಕವಡಿಕಾಯಿ',
'ಕವಡುಗಾರ್ತಿ',
'ಕವಡುಗೊಲೆ',
'ಕವಡುಗೋಡು',
'ಕವಡುತನ',
'ಕವಡುನಡೆ',
'ಕವಡುನಿದ್ದೆ',
'ಕವಡುನುಡಿ',
'ಕವಡುಬಿಜ್ಜೆ',
'ಕವಡುಬೀಳ್',
'ಕವಡುಮಾತು',
'ಕವಡೆಕಾಯಿ',
'ಕವಡೆಕಾಸು',
'ಕವಡೆಗಟ್ಟು',
'ಕವಣೆಗಲ್',
'ಕವಣೆಗಲು',
'ಕವಣೆಗಲ್ಲು',
'ಕವಣೆಗೊಳ್',
'ಕವರುಕತ್ತಿ',
'ಕವರ್ತೆಗೊಳ್',
'ಕವರ್ತೆವಟ್ಟೆ',
'ಕವರ್ತೆವಡು',
'ಕವರ್ತೆವಡೆ',
'ಕವರ್ತೆವೋಗು',
'ಕವಲುಗಣೆ',
'ಕವಲುಗಿಡ',
'ಕವಲುಗೊಳ್ಳು',
'ಕವಲುಗೋಲು',
'ಕವಲುತೋಕೆ',
'ಕವಲುದಾರಿ',
'ಕವಲುದೋಱು',
'ಕವಲುಮನ',
'ಕವಲ್ನಂಬುಗೆ',
'ಕವಲ್ನಾಲಗೆ',
'ಕವಳಂಗೊಳ್',
'ಕವಳುಗೋಲ್',
'ಕವಾಟಪುಟ',
'ಕವಾಡಗಿತ್ತಿ',
'ಕವಿತಾಗುಣ',
'ಕವಿತಾಗೋಷ್ಠಿ',
'ಕವಿತಾಪ್ರೌಢ',
'ಕವಿತಾಪ್ರೌಢೆ',
'ಕವಿತ್ವಾಯಿಲ',
'ಕವಿಲಗೆಂಪು',
'ಕವಿಲೆದುಱು',
'ಕವಿಲೆವಣ್ಣ',
'ಕವಿಸಮಯ',
'ಕವಿಹೃದಯ',
'ಕವುಟೆಕಾಯಿ',
'ಕವುಲುಮರ',
'ಕವುಷಲಿಪಿ',
'ಕವುಳಗೋಲ್',
'ಕವ್ವರಿಗೊಳ್',
'ಕವ್ವರೆಗೊಳ್',
'ಕಶಾಂಗಧಾರಿ',
'ಕಶಾಪ್ರಹಾರ',
'ಕಶಾಭಿಘಾತ',
'ಕಶೆಪಾವುಡೆ',
'ಕಶೇರುಮಣಿ',
'ಕಷಾಯಕಂಠ',
'ಕಷಾಯತೋಯ',
'ಕಷಾಯವರ್ಣ',
'ಕಷಾಯೋದಕ',
'ಕಷ್ಟಪಡಿಸು',
'ಕಷ್ಟಬಡಿಸು',
'ಕಸಕಿಲುಗ',
'ಕಸಕಿಲ್ಗೆಯ್ಮೆ',
'ಕಸಗುಡಿಸು',
'ಕಸಗೂಡಿಸು',
'ಕಸಪರಕಿ',
'ಕಸಪರಿಕೆ',
'ಕಸಪೊರಕೆ',
'ಕಸಬರಲ್',
'ಕಸಬರಲು',
'ಕಸಬರಿಕೆ',
'ಕಸಬರಿಗೆ',
'ಕಸಬಳಿಸು',
'ಕಸಬುಗಾರ',
'ಕಸಬುದಾರ',
'ಕಸಬುದಾರಿ',
'ಕಸಬುಶಾಲೆ',
'ಕಸಮಸಗು',
'ಕಸಮುಡುಗೆ',
'ಕಸಮುಸುರೆ',
'ಕಸರುಗಾಯಿ',
'ಕಸಹೆಡಿಗೆ',
'ಕಸಾಯಿಖಾನೆ',
'ಕಸುಕುತನ',
'ಕಸುಗಾಯ್ತನ',
'ಕಸುಬುಗತ್ತಿ',
'ಕಸುಬುದಾರ',
'ಕಸುಬುದಾರಿ',
'ಕಸುರುಗಾಯಿ',
'ಕಸುವುಪಡೆ',
'ಕಸುವುಹುಲ್ಲು',
'ಕಸೆಪಾವಡೆ',
'ಕಸ್ತುತಗಾದೆ',
'ಕಸ್ತುರಿಬೊಟ್ಟು',
'ಕಸ್ತೂರಿಇಲಿ',
'ಕಸ್ತೂರಿಕಡ್ಡಿ',
'ಕಸ್ತೂರಿಗೊಬ್ಳಿ',
'ಕಸ್ತೂರಿಜಾಲಿ',
'ಕಸ್ತೂರಿಬೆಂಡೆ',
'ಕಸ್ತೂರಿಬೆಕ್ಕು',
'ಕಸ್ತೂರಿಬೊಟ್ಟು',
'ಕಸ್ತೂರಿಮಾವು',
'ಕಸ್ತೂರಿಮೃಗ',
'ಕಸ್ತೂರೀವರ್ಣ',
'ಕಹಕಹಿಸು',
'ಕಹಳಾರವ',
'ಕಹಳೆಕಾಱ',
'ಕಹಿಕೊಡ್ಸೀಗೆ',
'ಕಹಿಚಕ್ಕೋತ',
'ಕಹಿನಾಲಗೆ',
'ಕಹಿಮಣಿಕೆ',
'ಕಹಿಹಾಗಲು',
'ಕಳಂಬಾಸನ',
'ಕಳಕಳತೆ',
'ಕಳಕಳಾಪ',
'ಕಳಕಳಿಕೆ',
'ಕಳಕಳಿಸು',
'ಕಳತೆಗೆಯಿ',
'ಕಳತೆಯಾಗು',
'ಕಳಬಳಿಸು',
'ಕಳಮಕ್ಷೇತ್ರ',
'ಕಳಮಾಕ್ಷತ',
'ಕಳಮಾಕ್ಷತೆ',
'ಕಳಲುಕಟ್ಟು',
'ಕಳಲೋದನ',
'ಕಳವಂಜಳ',
'ಕಳವಸಿಗ',
'ಕಳವಳಿಕೆ',
'ಕಳವಳಿಗ',
'ಕಳವಳಿಸು',
'ಕಳವುಗೆಯ್',
'ಕಳವುವೋಗು',
'ಕಳವೆಗೂಳು',
'ಕಳವೆದೆನೆ',
'ಕಳಸಂಬೊಱು',
'ಕಳಸಗಾಣ್',
'ಕಳಸಗಿತ್ತಿ',
'ಕಳಸಪೂಜೆ',
'ಕಳಸಪ್ರಾಯ',
'ಕಳಸಮಿಡು',
'ಕಳಸವಿಡು',
'ಕಳಸವೇರು',
'ಕಳಹುವೂಟ',
'ಕಳಾಕಳಾಪ',
'ಕಳಾಕಳಿತ',
'ಕಳಾಕೋವಿದ',
'ಕಳಾಕೋವಿದೆ',
'ಕಳಾಕೌಶಲ',
'ಕಳಾಪಂಡಿತ',
'ಕಳಾಪಂಡಿತೆ',
'ಕಳಾಪ್ರಣೀತ',
'ಕಳಾಪ್ರಣೀತೆ',
'ಕಳಾಮಾಲಿನಿ',
'ಕಳಾಸಂಗೊಡು',
'ಕಳಾಹೀನತೆ',
'ಕಳಿಂಗರಾಸ್ಮೆ',
'ಕಳಿಂದಕನ್ಯೆ',
'ಕಳಿಂದಾತ್ಮಜೆ',
'ಕಳಿಯಡಕೆ',
'ಕಳೆಗಟ್ಟಿಸು',
'ಕಳೆಗುಂದಿಸು',
'ಕಳೆಗೆದಱು',
'ಕಳೆಗೊಳಿಸು',
'ಕಳೆನಾಶಕ',
'ಕಳೆವಂಜಳ',
'ಕಳೆವೆಳಗ',
'ಕಳ್ಗೊಲೆಗೊಳ್ಳು',
'ಕಳ್ಗೊಲೆವೊಯ್',
'ಕಳ್ತೆಗಿರುಬ',
'ಕಳ್ಳಂಕಡಲೆ',
'ಕಳ್ಳಂಗಡಲೆ',
'ಕಳ್ಳಕಾಗದ',
'ಕಳ್ಳಕುಣಿಕೆ',
'ಕಳ್ಳಕುಪ್ಪಸ',
'ಕಳ್ಳಕೆಸರು',
'ಕಳ್ಳಕೊರಚ',
'ಕಳ್ಳಕೊರಮ',
'ಕಳ್ಳಕೊರವ',
'ಕಳ್ಳಟಿಪ್ಪಣ',
'ಕಳ್ಳನಗಿಡ',
'ಕಳ್ಳನಚೌರಿ',
'ಕಳ್ಳನಚೌಲಿ',
'ಕಳ್ಳನಮೆಳೆ',
'ಕಳ್ಳನಹಂಬು',
'ಕಳ್ಳಮನಸ್ಸು',
'ಕಳ್ಳವತ್ತಿಗೆ',
'ಕಳ್ಳವರಿಕೆ',
'ಕಳ್ಳವಾಸಗೆ',
'ಕಳ್ಳವೆಳಱು',
'ಕಳ್ಳವ್ಯಾಪಾರ',
'ಕಳ್ಳಶರಾಬು',
'ಕಳ್ಳಸರಕು',
'ಕಳ್ಳಸಾಗಣೆ',
'ಕಳ್ಳಸಾಗಾಟ',
'ಕಳ್ಳಹಾದರ',
'ಕಳ್ಳಹುದಲು',
'ಕಳ್ಳಹುದುಲು',
'ಕಳ್ಳಹೃದಯ',
'ಕಳ್ಳೇಱುಕಾಱ',
'ಕಳ್ಳೇಱುಗಾಱ',
'ಕಱುಂಬುತನ',
'ಕಱುಕುಚಿಗ',
'ಕಱುಕುಟಿಗ',
'ಕಱುಗೊಳಗು',
'ಕಱುಬತನ',
'ಕಱುಬುತನ',
'ಕಱೆಕೊರಲ',
'ಕಱೆಕೊರಳ',
'ಕಱೆಗೊರಲ್',
'ಕಱೆಗೊರಲ',
'ಕಱೆಗೊರಳ',
'ಕಱೆಗೊರಳು',
'ಕಱೆದೊವಲ್',
'ಕಱೆವೆರಸು',
'ಕೞಕೞಿಸು',
'ಕೞಮಚೂರ್ಣ',
'ಕೞಲೆಗಟ್ಟು',
'ಕೞವೆಗಿಡು',
'ಕೞಿಯಲರ್',
'ಕೞಿವಗಲ್',
'ಕೞಿವೊಡಲ್',
'ಕೞ್ಗೊಲೆಗೊಳ್',
'ಕೞ್ತಲೆವಣಿ',
'ಕೞ್ತಲೆವಿಡು',
'ಕೞ್ತೆಕುಟಿಗ',
'ಕೞ್ಪಕುಜಾತ',
'ಕಾಂಚನಮಾಲೆ',
'ಕಾಂಚನಮೃಗ',
'ಕಾಂಚನವರ್ಣ',
'ಕಾಂಚನವೃಷ್ಟಿ',
'ಕಾಂಚನಾರಕ',
'ಕಾಂಚೀಕಳಾಪ',
'ಕಾಂಚೀಪಟ್ಟಿಕೆ',
'ಕಾಂತಚೋದನೆ',
'ಕಾಂತಪ್ರವಾಹ',
'ಕಾಂತಮಂಡಲ',
'ಕಾಂತಮಸೂರ',
'ಕಾಂತಮಾಪಕ',
'ಕಾಂತವಿದ್ಯುತ್',
'ಕಾಂತವಿದ್ಯುತ್ತು',
'ಕಾಂತಾಕದಂಬ',
'ಕಾಂತಾರಚರ್ಯೆ',
'ಕಾಂತಾಸಮ್ಮಿತ',
'ಕಾಂತಿಮುದ್ರಣ',
'ಕಾಂತಿವರ್ಧಕ',
'ಕಾಂತಿಹೀನತೆ',
'ಕಾಂದಸೀಕತೆ',
'ಕಾಂದೇನವಮಿ',
'ಕಾಂಸ್ಯತೋರಣ',
'ಕಾಕಂಬಿಸೀರೆ',
'ಕಾಕಡಸಿಂಗಿ',
'ಕಾಕತಾಲೀಯ',
'ಕಾಕತಾಳೀಯ',
'ಕಾಕತಿಂದುಕ',
'ಕಾಕತುಂಡಿಕೆ',
'ಕಾಕತುಂಬುರು',
'ಕಾಕನಾಸಿಕೆ',
'ಕಾಕಪತಾಕ',
'ಕಾಕಪೀಲುಕ',
'ಕಾಕಪೌರುಷ',
'ಕಾಕಭಾಜನ',
'ಕಾಕಭೋಜನ',
'ಕಾಕರಗಲ್ಲು',
'ಕಾಕರಲತೆ',
'ಕಾಕರಲಿಪಿ',
'ಕಾಕಸರಳಿ',
'ಕಾಕಾಕ್ಷಿನ್ಯಾಯ',
'ಕಾಕಿಣೀರತ್ನ',
'ಕಾಕಿಮಂಡಲಿ',
'ಕಾಕುತುಂಪರೆ',
'ಕಾಕುಮನುಜ',
'ಕಾಕುಮಾನವ',
'ಕಾಕುವೆಸರ್',
'ಕಾಕುವ್ಯಂಜನೆ',
'ಕಾಕೋದುಂಬರ',
'ಕಾಕೋಲಧರ',
'ಕಾಗದಹಾಳ',
'ಕಾಗಾರದನ',
'ಕಾಗೆತೂಬರೆ',
'ಕಾಗೆನೇರಳೆ',
'ಕಾಗೆಪುರಳೆ',
'ಕಾಗೆಬಂಗಾರ',
'ಕಾಗೆಬಿದಿರು',
'ಕಾಗೆಭಂಗಾರ',
'ಕಾಗೆಮಾಂಬಳ್ಳಿ',
'ಕಾಗೆಹೊಡಕ',
'ಕಾಚದೃಗ್ಯಂತ್ರ',
'ಕಾಚಭೂಷಣ',
'ಕಾಚವಲಯ',
'ಕಾಚವಳಯ',
'ಕಾಚುಲವಣ',
'ಕಾಜಕತ್ತರಿ',
'ಕಾಟಕತನ',
'ಕಾಟಕಸರು',
'ಕಾಟನಾಯಕ',
'ಕಾಟಿಗದ್ಯಾಣ',
'ಕಾಡಅಶ್ವತ್ಥ',
'ಕಾಡಕಚೋರ',
'ಕಾಡಕಠಾರಿ',
'ಕಾಡಕಡನೆ',
'ಕಾಡಕತ್ತರಿ',
'ಕಾಡಕದಳಿ',
'ಕಾಡಕಲ್ನಾರು',
'ಕಾಡಕಲ್ಲಟ್ಟಿ',
'ಕಾಡಕವಟೆ',
'ಕಾಡಕವಡೆ',
'ಕಾಡಕವಿಲೆ',
'ಕಾಡಕುಸುಬಿ',
'ಕಾಡಕುಸುಬೆ',
'ಕಾಡಖಚೋರ',
'ಕಾಡಗರ್ಗರಿ',
'ಕಾಡಗೊರವಿ',
'ಕಾಡಜರುಚು',
'ಕಾಡಜೀರಿಗೆ',
'ಕಾಡತುರುವೆ',
'ಕಾಡತುಳಸಿ',
'ಕಾಡತೊಳಸಿ',
'ಕಾಡಪಾಪಟೆ',
'ಕಾಡಪಾಪರೆ',
'ಕಾಡಪಾವಟೆ',
'ಕಾಡಪೆಸಱು',
'ಕಾಡಬಗಲೆ',
'ಕಾಡಬಗುಲಿ',
'ಕಾಡಬಸಲೆ',
'ಕಾಡಬೆಳಸು',
'ಕಾಡಮಲ್ಲಿಗೆ',
'ಕಾಡಮಾಗಳಿ',
'ಕಾಡಮಾದಲ',
'ಕಾಡರಸಿನ',
'ಕಾಡಸಂಪಗೆ',
'ಕಾಡಸೂರಣ',
'ಕಾಡಹರಳು',
'ಕಾಡಹಾಗಲ',
'ಕಾಡಹುರುಳಿ',
'ಕಾಡಹೆಸರು',
'ಕಾಡಾಡಿತನ',
'ಕಾಡಾಡುಬಳ್ಳಿ',
'ಕಾಡಿಗೆಗಣ್',
'ಕಾಡಿಗೆಗಣ್ಣು',
'ಕಾಡಿಗೆಗುಡಿ',
'ಕಾಡಿಗೆಗೂಡು',
'ಕಾಡಿಗೆಜೋಳ',
'ಕಾಡಿಗೆನೀರು',
'ಕಾಡಿಗೆಯಿಡು',
'ಕಾಡಿಗೆಯೆಚ್ಚು',
'ಕಾಡಿಗೆರೋಗ',
'ಕಾಡಿಗೆವಿಡಿ',
'ಕಾಡಿಗೆವೆಟ್ಟು',
'ಕಾಡಿಗ್ಗರಗೆ',
'ಕಾಡಿಗ್ಗೆರಗು',
'ಕಾಡಿಯರಗು',
'ಕಾಡುಅಂಬಾಡಿ',
'ಕಾಡುಅಗಸೆ',
'ಕಾಡುಅಜ್ವಾನ',
'ಕಾಡುಅಡಿಕೆ',
'ಕಾಡುಅಮಟೆ',
'ಕಾಡುಅರಳಿ',
'ಕಾಡುಅವರೆ',
'ಕಾಡುಅಶೋಕ',
'ಕಾಡುಅಶ್ವತ್ಥ',
'ಕಾಡುಈರುಳ್ಳಿ',
'ಕಾಡುಏಲಕ್ಕಿ',
'ಕಾಡುಕಡಲೆ',
'ಕಾಡುಕತ್ತರಿ',
'ಕಾಡುಕಮಲೆ',
'ಕಾಡುಕಲ್ನಾರು',
'ಕಾಡುಕವಡೆ',
'ಕಾಡುಕಸ್ತೂರಿ',
'ಕಾಡುಕಿತ್ತಲೆ',
'ಕಾಡುಕಿತ್ತಳೆ',
'ಕಾಡುಕುಂಬಳ',
'ಕಾಡುಕುದುರೆ',
'ಕಾಡುಕುರುಬ',
'ಕಾಡುಕುರುಳು',
'ಕಾಡುಕುಸುಬಿ',
'ಕಾಡುಕುಸುಬೆ',
'ಕಾಡುಕೊಂಕಿಣಿ',
'ಕಾಡುಕೊಕ್ಕರೆ',
'ಕಾಡು ಖರ್ಜೂರ',
'ಕಾಡುಗತ್ತಲೆ',
'ಕಾಡುಗರಿಕೆ',
'ಕಾಡುಗಳ್ತಲೆ',
'ಕಾಡುಗುಂಬಳ',
'ಕಾಡುಗುದುರೆ',
'ಕಾಡುಗುಬ್ಬಚ್ಚಿ',
'ಕಾಡುಗುರುಬ',
'ಕಾಡುಗುಲಾಬಿ',
'ಕಾಡುಗೆಣಸು',
'ಕಾಡುಗೊಬ್ಬಳಿ',
'ಕಾಡುಜನಾಂಗ',
'ಕಾಡುಜಾರಿಗೆ',
'ಕಾಡುಜೀರಕ',
'ಕಾಡುಜೀರಿಗೆ',
'ಕಾಡುತುಳಸಿ',
'ಕಾಡುತೊಗರಿ',
'ಕಾಡುದವನ',
'ಕಾಡುದಾಲ್ಚಿನ್ನಿ',
'ಕಾಡುನೇರಳೆ',
'ಕಾಡುಪತಂಗ',
'ಕಾಡುಪುದೀನ',
'ಕಾಡುಪುರಾಣ',
'ಕಾಡುಬಂದುಗೆ',
'ಕಾಡುಬದನೆ',
'ಕಾಡುಬಸಳೆ',
'ಕಾಡುಬಾದಾಮಿ',
'ಕಾಡುಬುಡುಮೆ',
'ಕಾಡುಬೂರುಗ',
'ಕಾಡುಬೆರಣಿ',
'ಕಾಡುಬೆಳ್ಳುಳ್ಳಿ',
'ಕಾಡುಮಂದಾರ',
'ಕಾಡುಮದರು',
'ಕಾಡುಮನುಷ್ಯ',
'ಕಾಡುಮಲ್ಲಿಗೆ',
'ಕಾಡುಮೆಣಸು',
'ಕಾಡುಯಾಲಕ್ಕಿ',
'ಕಾಡುರುದ್ರಾಕ್ಷಿ',
'ಕಾಡುಲವಂಗ',
'ಕಾಡುಲವಣ',
'ಕಾಡುವಾಗಲು',
'ಕಾಡುವೆಪ್ಪಲೆ',
'ಕಾಡುವೆಪ್ಪಾಲೆ',
'ಕಾಡುವೆಲ್ಲುಲ್ಲಿ',
'ಕಾಡುಶಾವಂತಿ',
'ಕಾಡುಸಂಪಗೆ',
'ಕಾಡುಸಂಪಿಗೆ',
'ಕಾಡುಸಾಸಿವೆ',
'ಕಾಡುಸೂರಣ',
'ಕಾಡುಸೆಣಬು',
'ಕಾಡುಹರಟೆ',
'ಕಾಡುಹರಳು',
'ಕಾಡುಹಲಸು',
'ಕಾಡುಹಾಗಲ',
'ಕಾಡುಹಾಗಲು',
'ಕಾಡುಹುರುಳಿ',
'ಕಾಡುಹುಲಿಗ',
'ಕಾಡುಹೆಸರು',
'ಕಾಡೆಮ್ಮೆವೋರಿ',
'ಕಾಡೊಡೆತನ',
'ಕಾಣಿಕೆಗೊಡು',
'ಕಾಣಿಕೆಗೊಳ್',
'ಕಾಣಿಕೆಯಿಕ್ಕು',
'ಕಾಣಿಕೆವಿಡಿ',
'ಕಾಣ್ಬಟ್ಟೆಗೆಡು',
'ಕಾತರಗೊಳ್ಳು',
'ಕಾತರಪಡು',
'ಕಾತ್ಯಾಯನಿಕೆ',
'ಕಾದಳಕಾಂಡ',
'ಕಾನಕಲ್ಲಟೆ',
'ಕಾನಕೆಂಟಾಲಿ',
'ಕಾನನಕೊಂಬು',
'ಕಾನನಚರ',
'ಕಾನನಾಂತಕ',
'ಕಾನನಾಂತರ',
'ಕಾನಬಾವಲಿ',
'ಕಾನು ಸುರಗಿ',
'ಕಾನೂನುತಜ್ಞ',
'ಕಾನೂನುತಜ್ಞೆ',
'ಕಾನೂನುದಾರ',
'ಕಾನೂನುಬದ್ಧ',
'ಕಾನೂನುಭ್ರಷ್ಟ',
'ಕಾನೂನುಭ್ರಷ್ಟೆ',
'ಕಾಪಾಲಧರ',
'ಕಾಪಾಲಮಾರ್ಗ',
'ಕಾಪಿಲಸ್ನಾನ',
'ಕಾಪಿಶಾಯನ',
'ಕಾಪುಗೊಳಿಸು',
'ಕಾಪುವೆರಸು',
'ಕಾಪುಹಲಸು',
'ಕಾಪೇಯತನ',
'ಕಾಪೋತಲೇಶ್ಯೆ',
'ಕಾಮಕಸ್ತೂರಿ',
'ಕಾಮಕಾತರ',
'ಕಾಮಕಾತರೆ',
'ಕಾಮಕಾಮಿನಿ',
'ಕಾಮಕಾರಿಕೆ',
'ಕಾಮಕೊಣತಿ',
'ಕಾಮಕೌಶಲಿ',
'ಕಾಮಜನಕ',
'ಕಾಮಜ್ವರಿತ',
'ಕಾಮಟಗಾರ',
'ಕಾಮಣಿಗಣ್ಣು',
'ಕಾಮದಹನ',
'ಕಾಮದಾಯಿನಿ',
'ಕಾಮನಿರ್ಜರೆ',
'ಕಾಮನೀಯಕ',
'ಕಾಮಪಟಿಗೆ',
'ಕಾಮಪಾಲಕ',
'ಕಾಮಪ್ರಧ್ವಂಸಿ',
'ಕಾಮಭರಿತ',
'ಕಾಮಭರಿತೆ',
'ಕಾಮಮಥನ',
'ಕಾಮಯಿತೃತ್ವ',
'ಕಾಮರೂಪಿತ್ವ',
'ಕಾಮಲಂಪಟ',
'ಕಾಮಲಂಪಟೆ',
'ಕಾಮಲದೋಷ',
'ಕಾಮವರ್ಧಕ',
'ಕಾಮವರ್ಧಿನಿ',
'ಕಾಮವಾಸನೆ',
'ಕಾಮವಿಕಾರ',
'ಕಾಮವಿಹ್ವಲ',
'ಕಾಮವಿಹ್ವಲೆ',
'ಕಾಮಸಂಚಾರ',
'ಕಾಮಾಂಚ ಹುಲ್ಲು',
'ಕಾಮಾಕ್ಷಿಹುಲ್ಲು',
'ಕಾಮಾತಪತ್ರ',
'ಕಾಮಾಲೆಕಣ್ಣು',
'ಕಾಮಾಲೆಪಾಂಡು',
'ಕಾಮಾಲೆಹಕ್ಕಿ',
'ಕಾಮಿಕಾಗಮ',
'ಕಾಮಿತಾರ್ಥದ',
'ಕಾಯಂಗುತ್ತಿಗೆ',
'ಕಾಯಂತರೀಕ',
'ಕಾಯಕಲಬ್ಧಿ',
'ಕಾಯಕವಿಡಿ',
'ಕಾಯಚಿಕಿತ್ಸೆ',
'ಕಾಯಜಪಿತ',
'ಕಾಯಜಾಂತಕ',
'ಕಾಯದೆಭಂಗ',
'ಕಾಯದೇಶೀರ',
'ಕಾಯವಂಚಕ',
'ಕಾಯವಿಕಾರಿ',
'ಕಾಯವೆರಸು',
'ಕಾಯಸಂಬಂಧ',
'ಕಾಯಸಂಬಂಧಿ',
'ಕಾಯಸಮಾಧಿ',
'ಕಾಯಸೂತಕ',
'ಕಾಯಸೂತಕಿ',
'ಕಾಯಿಕಡುಬು',
'ಕಾಯಿಕಸರು',
'ಕಾಯಿಕಸುರು',
'ಕಾಯಿಕಾವೃದ್ಧಿ',
'ಕಾಯಿದೇಶೀರ್',
'ಕಾಯಿಸ್‍ದಾರ್',
'ಕಾಯ್ದುಱುಗಲ್',
'ಕಾರಂಡವಕ್ಕಿ',
'ಕಾರಕಪದ',
'ಕಾರಕಭ್ರಮೆ',
'ಕಾರಕರಕಿ',
'ಕಾರಕವಶ',
'ಕಾರಕಿರಿದಿ',
'ಕಾರಕೂನಿಕೆ',
'ಕಾರಗತ್ತಲೆ',
'ಕಾರಗಲಸು',
'ಕಾರಗಲಿಸು',
'ಕಾರಗಿಲ್ದೂಳ್',
'ಕಾರಗೆಣಸು',
'ಕಾರಣಂಬೇೞ್',
'ಕಾರಣಕನ್ನೆ',
'ಕಾರಣಕರ್ತ',
'ಕಾರಣಕರ್ತೆ',
'ಕಾರಣತಂತ್ರ',
'ಕಾರಣತನು',
'ಕಾರಣಬೀಳು',
'ಕಾರಣಮೂರ್ತಿ',
'ಕಾರಣಯೋಗಿ',
'ಕಾರಣಾಂತರ',
'ಕಾರಣಾಗಮ',
'ಕಾರಣಾತ್ಮಕ',
'ಕಾರಣಿಕತ್ವ',
'ಕಾರಣೆಗೊಳ್',
'ಕಾರಮಜ್ಜಿಗೆ',
'ಕಾರಮುಗಿಲ್',
'ಕಾರಮುಗಿಲು',
'ಕಾರಲವಣ',
'ಕಾರಸಿಗೆಡ್ಡೆ',
'ಕಾರಾಲಭೋಗಿ',
'ಕಾರಿಕಾಕಾರ',
'ಕಾರಿಹುಣ್ಣಿಮೆ',
'ಕಾರುಕದೆಱೆ',
'ಕಾರುಕಸೇವೆ',
'ಕಾರುಗತ್ತಲು',
'ಕಾರುಗತ್ತಲೆ',
'ಕಾರುಗಲಸು',
'ಕಾರುಗಳ್ತಲೆ',
'ಕಾರುಗೊರಲ',
'ಕಾರುಣ್ಯಂಗೆಯ್',
'ಕಾರುಣ್ಯಂಬಡೆ',
'ಕಾರುಣ್ಯಚಿತ್ತ',
'ಕಾರುಣ್ಯನಿಧಿ',
'ಕಾರುಣ್ಯಾರ್ಗಳ',
'ಕಾರುತಿಂಗಳು',
'ಕಾರುಬಾರಿಕೆ',
'ಕಾರುಹುಣ್ಣಿಮೆ',
'ಕಾರೂಬಾಮಣಿ',
'ಕಾರೆಗಲಸು',
'ಕಾರ್ಖಾನನೀಸ',
'ಕಾರ್ಪಣ್ಯತರ',
'ಕಾರ್ಪಣ್ಯಪಡು',
'ಕಾರ್ಪಣ್ಯಭಾವ',
'ಕಾರ್ಪಣ್ಯವಕ್ತ್ರ',
'ಕಾರ್ಪಾಸಚಾಪ',
'ಕಾರ್ಮಿಕದಿನ',
'ಕಾರ್ಮಿಕಮಲ',
'ಕಾರ್ಮಿಕಸಂಘ',
'ಕಾರ್ಮುಕಕೋಟಿ',
'ಕಾರ್ಮುಕಯೋಗ',
'ಕಾರ್ಮುಗಿಲೊಡ್ಡು',
'ಕಾರ್ಮೇಘಾಂಜನ',
'ಕಾರ್ಯಕಲಾಪ',
'ಕಾರ್ಯಕಾರಿಣಿ',
'ಕಾರ್ಯಕಾರಿತ್ವ',
'ಕಾರ್ಯಕ್ಷಮತೆ',
'ಕಾರ್ಯಗೌರವ',
'ಕಾರ್ಯತತ್ಪರ',
'ಕಾರ್ಯತತ್ಪರೆ',
'ಕಾರ್ಯದಕ್ಷತೆ',
'ಕಾರ್ಯನಿಮಗ್ನ',
'ಕಾರ್ಯನಿಮಗ್ನೆ',
'ಕಾರ್ಯಪ್ರಣಾಳಿ',
'ಕಾರ್ಯಪ್ರವೃತ್ತ',
'ಕಾರ್ಯಪ್ರವೃತ್ತೆ',
'ಕಾರ್ಯಮಗ್ನತೆ',
'ಕಾರ್ಯಯೋಜನೆ',
'ಕಾರ್ಯವಾಹಕ',
'ಕಾರ್ಯವಾಹಕಿ',
'ಕಾರ್ಯವಿಘಾತ',
'ಕಾರ್ಯವಿಧಾನ',
'ಕಾರ್ಯಸಮಿತಿ',
'ಕಾರ್ಯಸಾಧಕ',
'ಕಾರ್ಯಸಾಧನ',
'ಕಾರ್ಯಸಾಧನೆ',
'ಕಾರ್ಯಾಚರಣೆ',
'ಕಾಲಕಂಠಕ',
'ಕಾಲಕಂಧರ',
'ಕಾಲಕುದುರೆ',
'ಕಾಲಕ್ರಮಣ',
'ಕಾಲಕ್ರಮಣೆ',
'ಕಾಲಕ್ರಮೇಣ',
'ಕಾಲಗಣನ',
'ಕಾಲಗಣನೆ',
'ಕಾಲಗಣಿಕೆ',
'ಕಾಲಗತ್ತಲೆ',
'ಕಾಲದರ್ಶಕ',
'ಕಾಲದಲನ',
'ಕಾಲಪಾಯಕ',
'ಕಾಲಪುರುಷ',
'ಕಾಲಪೆಂಡೆಯ',
'ಕಾಲಪ್ರವೃತ್ತಿ',
'ಕಾಲಪ್ರೇರಣೆ',
'ಕಾಲಭುಜಗ',
'ಕಾಲಭೈರವ',
'ಕಾಲಮರ್ಯಾದೆ',
'ಕಾಲಮಹಿಮೆ',
'ಕಾಲಮಾಪಕ',
'ಕಾಲಮಾಪನ',
'ಕಾಲಮುರಿಗೆ',
'ಕಾಲಮೇಷಿಕೆ',
'ಕಾಲಲೇಖನ',
'ಕಾಲವಾಚಕ',
'ಕಾಲವಿಳಂಬ',
'ಕಾಲಸಂಹರ',
'ಕಾಲಸರಣಿ',
'ಕಾಲಸೂದನ',
'ಕಾಲಹರಣ',
'ಕಾಲಾಗ್ನಿರುದ್ರ',
'ಕಾಲಾಡಿತನ',
'ಕಾಲಾತಿಕ್ರಮ',
'ಕಾಲಾಧಿಪತಿ',
'ಕಾಲಾನುಕಾಲ',
'ಕಾಲಾನುಕೂಲ',
'ಕಾಲಾನುಕ್ರಮ',
'ಕಾಲಾನುಪೂರ್ವಿ',
'ಕಾಲಾನುಸಾರ',
'ಕಾಲಾನುಸಾರ್ಯ',
'ಕಾಲಾಳುಗೂಡು',
'ಕಾಲಿಕಾದೇವಿ',
'ಕಾಲುಕಡಗ',
'ಕಾಲುಕುಪ್ಪುಸ',
'ಕಾಲುಕುಬುಸ',
'ಕಾಲುಕೆದರು',
'ಕಾಲುಕೊರಡು',
'ಕಾಲುಕೊಳಗ',
'ಕಾಲುಗಗ್ಗರ',
'ಕಾಲುಗವುಸು',
'ಕಾಲುಗೆಡಿಸು',
'ಕಾಲುಗೊಡವೆ',
'ಕಾಲುಗೊರಸು',
'ಕಾಲುಜರಸು',
'ಕಾಲುತೊಡರು',
'ಕಾಲುತೊಡಿಗೆ',
'ಕಾಲುಪಾಯಕ',
'ಕಾಲುಪೆಂಡೆಯ',
'ಕಾಲುಬೆಳೆಸು',
'ಕಾಲುಬೈರಿಗೆ',
'ಕಾಲುರಭತ್ತ',
'ಕಾಲುವೆಗಟ್ಟು',
'ಕಾಲುವೆದೆಗೆ',
'ಕಾಲ್ಕಣ್ಣಿವಲೆ',
'ಕಾಲ್ಕುಪ್ಪಳಿಸು',
'ಕಾಲ್ಗಚ್ಚುಗೊಳ್',
'ಕಾಲ್ಗಣ್ಣಿವಲೆ',
'ಕಾಲ್ದೊಡರ್ಗಟ್ಟು',
'ಕಾಲ್ಯಪ್ರಭವ',
'ಕಾಲ್ವರಿಯಿಸು',
'ಕಾಲ್ವೞಿಗೊಳ್',
'ಕಾಲ್ವೇಂಟೆಕಾಱ',
'ಕಾವಗಲಹ',
'ಕಾವಡಿಗಾಱ',
'ಕಾವರವೇರು',
'ಕಾವಲಿದೆಱೆ',
'ಕಾವಲಿಯೋಡು',
'ಕಾವಲುಕಟ್ಟೆ',
'ಕಾವಲುಗಾರ',
'ಕಾವಲುಗಾಱ',
'ಕಾವಲುದಳ',
'ಕಾವಲುನಾಯಿ',
'ಕಾವಲುಪಡೆ',
'ಕಾವವಗೆಯ',
'ಕಾವಳಗೈಸು',
'ಕಾವಳಗೊಳ್',
'ಕಾವುಕವುದಿ',
'ಕಾವುಪೆಟ್ಟಿಗೆ',
'ಕಾವುಬಚ್ಚಲು',
'ಕಾವುಬಚ್ಚಲೆ',
'ಕಾವುಳಗವಿ',
'ಕಾವ್ಯಕರ್ತಾರ',
'ಕಾವ್ಯಗಾಯನ',
'ಕಾವ್ಯಪ್ರಯೋಗ',
'ಕಾವ್ಯಪ್ರವೀಣ',
'ಕಾವ್ಯಮೀಮಾಂಸೆ',
'ಕಾವ್ಯರಸಿಕ',
'ಕಾವ್ಯವಾಚನ',
'ಕಾವ್ಯಸಮಯ',
'ಕಾವ್ಯಸಮಾಧಿ',
'ಕಾವ್ಯಾಂತರಾಳ',
'ಕಾವ್ಯಾನುಭವ',
'ಕಾವ್ಯಾರ್ಥಾಪತ್ತಿ',
'ಕಾವ್ಯಾಲಂಕಾರ',
'ಕಾವ್ಯಾಲಂಕೃತಿ',
'ಕಾವ್ಯಾವಲೋಕ',
'ಕಾಶಿಕಾಗದ',
'ಕಾಶ್ಮೀರಜಳ',
'ಕಾಶ್ಮೀರಭೂಮಿ',
'ಕಾಶ್ಮೀರರಾಗ',
'ಕಾಶ್ಮೀರಶೈವ',
'ಕಾಶ್ಮೀರಿಶಾಲು',
'ಕಾಶ್ಯಪಗೋತ್ರ',
'ಕಾಷಾಯಧಾರಿ',
'ಕಾಷಾಯವಸ್ತ್ರ',
'ಕಾಷ್ಠಕದಳಿ',
'ಕಾಷ್ಠಕುದ್ದಾಲ',
'ಕಾಷ್ಠತಕ್ಷಕ',
'ಕಾಷ್ಠತರಂಗ',
'ಕಾಷ್ಠವ್ಯಸನ',
'ಕಾಷ್ಠಾವ್ಯಸನ',
'ಕಾಷ್ಠಾಹರಣ',
'ಕಾಸರದೇವಿ',
'ಕಾಸಿಕಾಗಡಿ',
'ಕಾಸಿಕಾವಡಿ',
'ಕಾಸಿಗಪ್ಪಡ',
'ಕಾಸಿನಗಿಡ',
'ಕಾಸಿನಸರ',
'ಕಾಸಿನಿಗಿಡ',
'ಕಾಸೆಮೊಚ್ಚೆಯ',
'ಕಾಸ್ಮೀರಭೂಮಿ',
'ಕಾಳಂಕಧರ',
'ಕಾಳಕಂಧರ',
'ಕಾಳಕತ್ತಲೆ',
'ಕಾಳಕೃತಾಂತ',
'ಕಾಳಗತ್ತಲು',
'ಕಾಳಗತ್ತಲೆ',
'ಕಾಳಗದಾಯ',
'ಕಾಳಗವಾಡು',
'ಕಾಳಜಟ್ಟಿಗ',
'ಕಾಳಜೀರಿಗೆ',
'ಕಾಳತುಳಸಿ',
'ಕಾಳರಕ್ಕಸ',
'ಕಾಳರಕ್ಕಸಿ',
'ಕಾಳಸಂಬಡೆ',
'ಕಾಳಸಾವಿಗೆ',
'ಕಾಳಸೆಗೊಳ್',
'ಕಾಳಾಂಜಿಸೊಪ್ಪು',
'ಕಾಳಾಂಧಕಾರ',
'ಕಾಳಾಂಬುವಾಹ',
'ಕಾಳಾಗ್ನಿರುದ್ರ',
'ಕಾಳಾಹಿವೇಣಿ',
'ಕಾಳಿಗನಾಗ',
'ಕಾಳಿಯಮಡು',
'ಕಾಳುಗುಱಿಕೆ',
'ಕಾಳುಗೆಲಸ',
'ಕಾಳುಗೊಡವಿ',
'ಕಾಳುಮಾಡಿಸು',
'ಕಾಳುರಿಗೊಳ್',
'ಕಾಳುವಿಷಯ',
'ಕಾಳೆಗಂಗೆಯ್',
'ಕಾಳೆಗಂಗೊಡು',
'ಕಾಳೆಗವಱೆ',
'ಕಾಳೆಯಯುಗ',
'ಕಾಳೆವಿಡಿಸು',
'ಕಾಳೆಹೂಗಿಡ',
'ಕಾಳ್ಪುರವಿಡು',
'ಕಾಳ್ಬಟ್ಟೆಗೊಳ್',
'ಕಾೞ್ಗುದಿಗುದಿ',
'ಕಾೞ್ಗುದಿಗೊಳ್',
'ಕಾೞ್ಪುರಂಗೊಳ್',
'ಕಾೞ್ಪುರಂಬರಿ',
'ಕಾೞ್ಪುರವೇೞ್',
'ಕಿಂಕರಭಾವ',
'ಕಿಂಕರವಾಣ',
'ಕಿಂಕರವೃತ್ತಿ',
'ಕಿಂಕರವೆಸ',
'ಕಿಂಕಿಣೀದಾಮ',
'ಕಿಂಕಿಣೀರವ',
'ಕಿಂಕಿಣೀರುತಿ',
'ಕಿಂಕಿನಿವೋಗು',
'ಕಿಂಕಿರಿಯೊಗೆ',
'ಕಿಂಕಿರಿವೋಗು',
'ಕಿಂಕುರ್ವಾಣತೆ',
'ಕಿಂಪಾಕಫಲ',
'ಕಿಂಪಾಕಫಳ',
'ಕಿಕಿಯ ಹುಲ್ಲು',
'ಕಿಕ್ಕಿರಿಗೊಳ್',
'ಕಿಕ್ಕಿಲಿಕಿಲಿ',
'ಕಿಕ್ಕಿಱಿಗೊಳ್',
'ಕಿಗ್ಗಡಲಿಡು',
'ಕಿಗ್ಗಳದೈವ',
'ಕಿಚ್ಚುಗೊಲ್ವಣ',
'ಕಿಟ್ಟುಗಬ್ಬುನ',
'ಕಿಡಬುಡಿಕಿ',
'ಕಿಡಿಗೆದಱು',
'ಕಿಡಿಹೊರಳಿ',
'ಕಿಣ್ವಕಾರಕ',
'ಕಿತ್ತಳಿಗಾವಿ',
'ಕಿತ್ತಳೆಗಾವಿ',
'ಕಿತ್ತಳೆನಿಂಬೆ',
'ಕಿತ್ತುಳಿಗಾವಿ',
'ಕಿದ್ದಸೆಶೂಲೆ',
'ಕಿನಿಸುಮಾತು',
'ಕಿನಿಸುವೋದು',
'ಕಿನ್ನರಕಥೆ',
'ಕಿನ್ನರಗೇಯ',
'ಕಿನ್ನರಪತಿ',
'ಕಿನ್ನರಪಾದ',
'ಕಿನ್ನರಪಾಷ್ರ್ಣಿ',
'ಕಿನ್ನರಭಾವೆ',
'ಕಿನ್ನರವರ್ಷ',
'ಕಿನ್ನರವೀಣೆ',
'ಕಿನ್ನರಸ್ವರ',
'ಕಿನ್ನರಿಕಾಯಿ',
'ಕಿನ್ನರಿಕ್ರಿಯೆ',
'ಕಿನ್ನರಿಗಲ್',
'ಕಿನ್ನರಿಗಲ್ಲು',
'ಕಿನ್ನರಿವೀಣೆ',
'ಕಿಬ್ಬೊಟ್ಟೆಕಸ',
'ಕಿಮಿಗೆಡಿಸು',
'ಕಿರಕಸಾಲಿ',
'ಕಿರಗಸಾಲೆ',
'ಕಿರಣಕಾಯ',
'ಕಿರಣಾಗಮ',
'ಕಿರಣಾವಲಿ',
'ಕಿರಣಾವಳಿ',
'ಕಿರಲುಬೋಗಿ',
'ಕಿರಾತಕಡ್ಡಿ',
'ಕಿರಾತತಿಕ್ತ',
'ಕಿರಾತವಡೆ',
'ಕಿರಾತಸತಿ',
'ಕಿರಾಲುಬೋಗಿ',
'ಕಿರಿಈಚಲು',
'ಕಿರಿಕುಂಬಳ',
'ಕಿರಿಗಡಲೆ',
'ಕಿರಿಗನ್ನಿಕೆ',
'ಕಿರಿಗೊಳಿಸು',
'ಕಿರಿಗೊರಳು',
'ಕಿರಿಚರಿಪ್ಪೆ',
'ಕಿರಿಚಿರಿಪ್ಪೆ',
'ಕಿರಿಚುನಗೆ',
'ಕಿರಿತುಂಬುರು',
'ಕಿರಿತುರುಚಿ',
'ಕಿರಿನಾಲಗೆ',
'ಕಿರಿನೇರಳೆ',
'ಕಿರಿಪಡಲ',
'ಕಿರಿಬೆರಳು',
'ಕಿರಿಯಗುರು',
'ಕಿರಿಯತನ',
'ಕಿರಿಯನೆಲ್ಲಿ',
'ಕಿರಿಲ್‍ಬೋಗಿ',
'ಕಿರೀಟಪ್ರಾಯ',
'ಕಿರೀಟಯೋಗ',
'ಕಿರುಕರಂಜಿ',
'ಕಿರುಕಸಾಲೆ',
'ಕಿರುಕುಶಾಲೆ',
'ಕಿರುಕುಸಾಲೆ',
'ಕಿರುಗಂಜಣಿ',
'ಕಿರುಗತ್ತಲೆ',
'ಕಿರುಗಾಣಿಕೆ',
'ಕಿರುಗುಟ್ಟಾಟ',
'ಕಿರುಗುಟ್ಟಿಸು',
'ಕಿರುಜವ್ವನೆ',
'ಕಿರುತಾರಗೆ',
'ಕಿರುದುರುಚಿ',
'ಕಿರುನವಣೆ',
'ಕಿರುನಾಲಗೆ',
'ಕಿರುನಿಮಿತ್ತ',
'ಕಿರುಬಿದಿರು',
'ಕಿರುಬೆಮರ್',
'ಕಿರುಬೆರಳು',
'ಕಿರುಬೆವರು',
'ಕಿರುಮಿದುಳು',
'ಕಿರುಮೆಣಸು',
'ಕಿರುಮೊರಡಿ',
'ಕಿರುವರದ',
'ಕಿರುವರೆಯ',
'ಕಿರುಶಿವನಿ',
'ಕಿರುಸೊಡರ್',
'ಕಿರುಹರೆಯ',
'ಕಿರುಹೊತ್ತಗೆ',
'ಕಿರುಹೊತ್ತಿಗೆ',
'ಕಿಲಕಿಂಚಿತ',
'ಕಿಲಕಿಂಚಿತೆ',
'ಕಿಲಕಿಲನೆ',
'ಕಿಲಾಡಿತನ',
'ಕಿಲಿಕಿಂಚಿತ',
'ಕಿಲಿಬಿಲಿಸು',
'ಕಿಲುಂಬುಗಟ್ಟು',
'ಕಿಲುಂಬುಗೊಳ್',
'ಕಿಲುಬುಗಟ್ಟು',
'ಕಿಲುಬುಗುಂದು',
'ಕಿಲುಬುಹಿಡಿ',
'ಕಿಲೊಮೀಟರು',
'ಕಿಲೊಲೀಟರು',
'ಕಿಲೊಸೈಕಲ್ಲು',
'ಕಿಲ್ಲೆಕಛೇರಿ',
'ಕಿಲ್ಲೇದಾರಿಕೆ',
'ಕಿವಿಕೆಡಿಸು',
'ಕಿವಿಗೇಳಿಸು',
'ಕಿವಿಗೊಳಿಸು',
'ಕಿವಿತಮಟೆ',
'ಕಿವಿದಾಗಿಸು',
'ಕಿವಿದುಡಿಗೆ',
'ಕಿವಿದೆಱಪು',
'ಕಿವಿದೊಡವು',
'ಕಿವಿದೊಡಿಗೆ',
'ಕಿವಿಯಳತೆ',
'ಕಿವಿಲುತನ',
'ಕಿವಿಹರಕ',
'ಕಿವಿಹರಕಿ',
'ಕಿವುಂಡುಗೇಳ್',
'ಕಿವುಂಡುವಡು',
'ಕಿವುಚುರೊಟ್ಟಿ',
'ಕಿವುಡುಕೇಳ್',
'ಕಿವುಡುಗಾಯಿ',
'ಕಿವುಡುಗಿವಿ',
'ಕಿವುಡುಗೇಳು',
'ಕಿವುಡುಗೊಳ್',
'ಕಿವುಡುತನ',
'ಕಿವುಡುವಡು',
'ಕಿವುಡುವೀೞ್',
'ಕಿವುಡುವೋಗು',
'ಕಿವುಡೆಬ್ಬಿಸು',
'ಕಿವುೞೆದುೞಿ',
'ಕಿಶೋರಶಶಿ',
'ಕಿಶೋರಾವಸ್ಥೆ',
'ಕಿಸಮುವಾರು',
'ಕಿಸುಕುಳತ್ವ',
'ಕಿಸುಕುಳಿಗ',
'ಕಿಸುಗಣ್ಬಿಡು',
'ಕಿಸುಗಣ್ಬೀಡು',
'ಕಿಸುಗದಿರ್',
'ಕಿಸುಗುಳಿಗ',
'ಕಿಸುದಳಿರ್',
'ಕಿಸುಬಾಸುಳ್',
'ಕಿಸುರವಗು',
'ಕಿಸುರಳಿಪ',
'ಕಿಸುರುಗಣ್ಣು',
'ಕಿಸುರುಗಾರ್ತಿ',
'ಕಿಸುರುಗುಟ್ಟು',
'ಕಿಸುರುಗೊಳ್',
'ಕಿಸುರೊಡಲ್',
'ಕಿಸುವಲಗೆ',
'ಕಿಸ್ತುವಸೂಲಿ',
'ಕಿಳಕಿಂಚಿತ',
'ಕಿಳಿಕಿಂಚಿತ',
'ಕಿಳುವದುವ',
'ಕಿಳ್ಳಿಕೇತಾಟ',
'ಕಿಱಿನೇಱಿಲು',
'ಕಿಱಿಯಕ್ಕರ',
'ಕಿಱಿಯಗರು',
'ಕಿಱಿಯಗುಳ್ಳ',
'ಕಿಱಿಯಬನ್ನಿ',
'ಕಿಱಿಯಹಾಲೆ',
'ಕಿಱಿಯೀಚಲು',
'ಕಿಱಿಯೆಲಚಿ',
'ಕಿಱಿಯೆಲವ',
'ಕಿಱಿಯೇಲಕ್ಕಿ',
'ಕಿಱಿಯೊಡಲ್',
'ಕಿಱಿವೆಳಗು',
'ಕಿಱುಕಸಲೆ',
'ಕಿಱುಕುಣಿಕೆ',
'ಕಿಱುಕುಣಿಕ್ಕೆ',
'ಕಿಱುಕುಸಲೆ',
'ಕಿಱುಕುಸಾಲೆ',
'ಕಿಱುಕುಳಾಯ',
'ಕಿಱುಗಡಲ್',
'ಕಿಱುಗಳಮೆ',
'ಕಿಱುಗುಣಿಕೆ',
'ಕಿಱುಗೊನರ್',
'ಕಿಱುಜವ್ವನ',
'ಕಿಱುಜವ್ವನೆ',
'ಕಿಱುದಳಿರ್',
'ಕಿಱುದಿವಸ',
'ಕಿಱುದುಪ್ಪುಳು',
'ಕಿಱುದುಪ್ಪುೞ್',
'ಕಿಱುದುಱಚಿ',
'ಕಿಱುನವಣೆ',
'ಕಿಱುನಾಲಿಗೆ',
'ಕಿಱುನೇಸಱು',
'ಕಿಱುನೇಱಿಲ್',
'ಕಿಱುಬಾವಲು',
'ಕಿಱುಬೆಮರ್',
'ಕಿಱುಮಾನಿಸ',
'ಕಿಱುಮೊರಡಿ',
'ಕಿಱುವರಲ್',
'ಕಿಱುವರೆಯ',
'ಕಿಱುವಾಗಿಲು',
'ಕಿಱುವೆರಲ್',
'ಕಿಱುವೆರಲು',
'ಕಿಱುವೆರಳ್',
'ಕಿಱುವೆರಳು',
'ಕಿಱುವೆಲರ್',
'ಕಿಱುವೆಳಗು',
'ಕಿಱುಸುರಿಗೆ',
'ಕೀಚಕತನ',
'ಕೀಚಲುಗುಟ್ಟು',
'ಕೀಟಕತನ',
'ಕೀಟನಾಶಕ',
'ಕೀಟಭಕ್ಷಕ',
'ಕೀಟಲೆಮಾತು',
'ಕೀಟವಿಜ್ಞಾನ',
'ಕೀಟವಿಜ್ಞಾನಿ',
'ಕೀಟಶಾಸ್ತ್ರಜ್ಞ',
'ಕೀನಾಶನಾಶ',
'ಕೀನಾಶಪಾಶ',
'ಕೀರಕಸಾಲೆ',
'ಕೀರತಿವಡೆ',
'ಕೀರ್ತನಂಗೆಯ್',
'ಕೀರ್ತನಕಾರ',
'ಕೀರ್ತನಗಾರ',
'ಕೀರ್ತನಗೆಯ್',
'ಕೀರ್ತಿಲಾಲಸೆ',
'ಕೀರ್ತಿವಲ್ಲಭ',
'ಕೀರ್ತಿವಿಖ್ಯಾತ',
'ಕೀರ್ತಿವಿಖ್ಯಾತೆ',
'ಕೀರ್ತಿವಿಶಾಲ',
'ಕೀರ್ತಿವಿಹಾರ',
'ಕೀಲಕಡಗ',
'ಕೀಲಣೆಗೆಯ್',
'ಕೀಲಣೆಗೊಳ್',
'ಕೀಲಣೆವಡೆ',
'ಕೀಲಣೆವೆಱು',
'ಕೀಲಸಾಗರ',
'ಕೀಲಾಲಜಲ',
'ಕೀಲಾಲಜಾರಿ',
'ಕೀಲುಕುದುರೆ',
'ಕೀಲುಸೇತುವೆ',
'ಕೀಸರುಹಿಡಿ',
'ಕೀಸುಳಿವಡೆ',
'ಕೀಳುಗಾಲುವೆ',
'ಕೀಳುಗೊರವ',
'ಕೀಳುಗೊಳಿಸು',
'ಕೀಳುಹಱಿವೆ',
'ಕೀೞ್ಕೈದುಗೊಳ್',
'ಕುಂಕಿಜಮೀನು',
'ಕುಂಕುಮಪಂಕ',
'ಕುಂಕುಮಬಲ',
'ಕುಂಕುಮರಸ',
'ಕುಂಕುಮರಾಗ',
'ಕುಂಕುಮರೋಗ',
'ಕುಂಕುಮಶೈಲ',
'ಕುಂಕುಮೋದಕ',
'ಕುಂಕುವಬೇರ',
'ಕುಂಚವಡಿಗ',
'ಕುಂಚವಡುಗ',
'ಕುಂಚಿತಕೇಶ',
'ಕುಂಚಿತಾಂಗುಲಿ',
'ಕುಂಜರಕರ',
'ಕುಂಜರಕುಳ',
'ಕುಂಜರಾರಾತಿ',
'ಕುಂಜರಾಶನ',
'ಕುಂಜರಾಸನ',
'ಕುಂಟಗೆಣಸು',
'ಕುಂಟಚಾಕರಿ',
'ಕುಂಟಜವಾಬು',
'ಕುಂಟಣಗಿತ್ತಿ',
'ಕುಂಟಣಿಗಾತಿ',
'ಕುಂಟಣಿಗಿತ್ತಿ',
'ಕುಂಟಣಿಗೆಯ್',
'ಕುಂಟಣಿತನ',
'ಕುಂಟಣಿದೊೞ್ತು',
'ಕುಂಟಣಿನಡೆ',
'ಕುಂಟಣಿಮನೆ',
'ಕುಂಟಣಿಮಾತು',
'ಕುಂಟಣಿವನೆ',
'ಕುಂಟಣಿವಾತು',
'ಕುಂಟಣಿವಿದ್ಯೆ',
'ಕುಂಟಣಿವೋಗು',
'ಕುಂಟದೇವತೆ',
'ಕುಂಟನಹುಣ್ಣು',
'ಕುಂಟನೇರಳೆ',
'ಕುಂಟಮುರುವ',
'ಕುಂಟಲಗಿತ್ತಿ',
'ಕುಂಟಲತನ',
'ಕುಂಟಲಿಗಿತ್ತಿ',
'ಕುಂಟವೆರಲ್',
'ಕುಂಟಹಲಪಿ',
'ಕುಂಟಿಣಿಗಿತ್ತಿ',
'ಕುಂಟುನೇರಳೆ',
'ಕುಂಠಣಿತನ',
'ಕುಂಠಿತತನ',
'ಕುಂಠೀಕರಣ',
'ಕುಂಡಗೋಲಕ',
'ಕುಂಡಗೋಳಕ',
'ಕುಂಡಲಯುಗ',
'ಕುಂಡಲವಾಡಿ',
'ಕುಂಡಲಾಕೃತಿ',
'ಕುಂಡಲಿಶಕ್ತಿ',
'ಕುಂಡಲೀಶ್ವರ',
'ಕುಂಡಿಗೆಪಟ್ಟೆ',
'ಕುಂಡಿಗೆಮಟ್ಟೆ',
'ಕುಂಡಿಗೆಹಾಳೆ',
'ಕುಂಡುಕೇಪಲ',
'ಕುಂಡೆಕುಸುಕ',
'ಕುಂಡೆಹಡಕ',
'ಕುಂತಲಮೇಘ',
'ಕುಂತಲಹಸ್ತ',
'ಕುಂತಳದೇಶ',
'ಕುಂತಳಭರ',
'ಕುಂದಕುಟ್ಮಲ',
'ಕುಂದಕುಟ್ಮಳ',
'ಕುಂದಗನ್ನಡ',
'ಕುಂದಣಗಟ್ಟು',
'ಕುಂದಣಗಾರ',
'ಕುಂದಣವಾಳೆ',
'ಕುಂದಣವಿಡು',
'ಕುಂದರದನೆ',
'ಕುಂದರಮಾವು',
'ಕುಂಬಕಂಬಾಯ್',
'ಕುಂಬರವೆಸ',
'ಕುಂಬಳಕಾಯ್',
'ಕುಂಬಳಕಾಯಿ',
'ಕುಂಬಳಗಿಡ',
'ಕುಂಬಳಮರ',
'ಕುಂಬಳಸೊಟ್ಟೆ',
'ಕುಂಬಱಗೋಲ್',
'ಕುಂಬಾರಕ್ರಿಯೆ',
'ಕುಂಬಾರಗತ್ತಿ',
'ಕುಂಬಾರಗಿತ್ತಿ',
'ಕುಂಬಾರಗೇಣಿ',
'ಕುಂಬಾರಜಬ್ಬು',
'ಕುಂಬಾರತನ',
'ಕುಂಬಾರಮಾಳಿ',
'ಕುಂಬಾರವೆಸ',
'ಕುಂಬಾರಸಾಲೆ',
'ಕುಂಬಾರಹುಳ',
'ಕುಂಬಾರಹುಳು',
'ಕುಂಬಾಱಸಾಲೆ',
'ಕುಂಬಾಱುದೆಱೆ',
'ಕುಂಭಕಾಮಿಲೆ',
'ಕುಂಭಶ್ರವಣ',
'ಕುಂಭಾಭಿಷೇಕ',
'ಕುಂಭಿನಿಧರ',
'ಕುಂಭಿನೀಧರ',
'ಕುಂಭಿನೊಸಲು',
'ಕುಂಭಿಪಾತಕ',
'ಕುಕಂಬಿಗಾರ',
'ಕುಕುಮಾಲಂಗಿ',
'ಕುಕ್ಕರಗಾಲು',
'ಕುಕ್ಕರಬಡಿ',
'ಕುಕ್ಕರಹಾಕು',
'ಕುಕ್ಕುಟಪುಟ',
'ಕುಕ್ಕುಟಸರ್ಪ',
'ಕುಕ್ಕುಟಾಸನ',
'ಕುಕ್ಕುಟಾಹವ',
'ಕುಕ್ಕುಟೇಶ್ವರ',
'ಕುಕ್ಕುರುಕಾಲು',
'ಕುಕ್ಕುರುಗಾಲು',
'ಕುಕ್ಕುಳಂಗುದಿ',
'ಕುಕ್ಕುಳಗುದಿ',
'ಕುಕ್ಕುೞಂಗುದಿ',
'ಕುಕ್ಕುೞಗುದಿ',
'ಕುಕ್ಕೆಕೊರಮ',
'ಕುಕ್ಷಿಂಭರಿಕ',
'ಕುಕ್ಷಿನಿವಾಸ',
'ಕುಕ್ಷಿನಿವಾಸಿ',
'ಕುಕ್ಷಿವಿಕಾರ',
'ಕುಗುರುಗಣ್ಣು',
'ಕುಚಚೂಚುಕ',
'ಕುಚಬಂಧನಿ',
'ಕುಚರಬುದ್ಧಿ',
'ಕುಚಿತಬುದ್ಧಿ',
'ಕುಚಿತಮಾರ್ಗ',
'ಕುಚಿತ್ತಾಚಾರ',
'ಕುಚೋದ್ಯಗಾರ',
'ಕುಚ್ಚಿತದೈವ',
'ಕುಚ್ಚಿತಬುದ್ದಿ',
'ಕುಚ್ಚುಕುಪ್ಪಸ',
'ಕುಚ್ಚುಪತಂಗ',
'ಕುಜನಾರಿಷ್ಟ',
'ಕುಜನೋಪದ್ರ',
'ಕುಟಹಾರಿಕೆ',
'ಕುಟಿಕರಿಸು',
'ಕುಟಿಲಗರ್ಭ',
'ಕುಟಿಲಗಾಮಿ',
'ಕುಟಿಲಗಾರ',
'ಕುಟಿಲಗಾರ್ತಿ',
'ಕುಟಿಲತನ',
'ಕುಟಿಲಭಾವ',
'ಕುಟಿಲವಿದ್ಯ',
'ಕುಟಿಲಾಲಕ',
'ಕುಟಿಲಾಶಯ',
'ಕುಟಿಲಾಳಕಿ',
'ಕುಟಿಲೋದ್ಧಾರ',
'ಕುಟಿಳಾಶಯ',
'ಕುಟಿಳಾಳಕ',
'ಕುಟಿಳಾಳಕಿ',
'ಕುಟುಕುಗೊಳ್',
'ಕುಟುಕುಜೀವ',
'ಕುಟುಕುಮಾತು',
'ಕುಟುಕುಮುಳ್ಳು',
'ಕುಟುರವಕ್ಕಿ',
'ಕುಟ್ಟಹಾರಿಕೆ',
'ಕುಟ್ಟಿಮಂಬೊರೆ',
'ಕುಟ್ಟೆಫೈಸಲು',
'ಕುಟ್ಮಳೀಕೃತ',
'ಕುಠಾರಧರ',
'ಕುಠಾರಪ್ರಾಯ',
'ಕುಡಿಕತನ',
'ಕುಡಿಗರಿಕೆ',
'ಕುಡಿಗೊನರ್',
'ಕುಡಿತೆಗಣ್',
'ಕುಡಿತೆಗಣ್ಣು',
'ಕುಡಿತೆಗೊಳ್',
'ಕುಡಿದಳಿರ್',
'ಕುಡಿನಾಲಗೆ',
'ಕುಡಿನಾಲಿಗೆ',
'ಕುಡಿನಿಮಿರ್',
'ಕುಡಿನಿಮಿರು',
'ಕುಡಿಪಱೆಯ',
'ಕುಡಿಬಿದಿರ್',
'ಕುಡಿಮಗುಚು',
'ಕುಡಿಮುಸುಗು',
'ಕುಡಿಯಿಲ್ಲಣ',
'ಕುಡಿಯೊಕ್ಕಲ್',
'ಕುಡಿವೆರಲ್',
'ಕುಡಿವೆಳಗು',
'ಕುಡಿಹೊಳೆಹು',
'ಕುಡುಕುಂದಱಿ',
'ಕುಡುಕುಗೊಡು',
'ಕುಡುಕುಗೊಳ್',
'ಕುಡುಕುಗೊಳು',
'ಕುಡುಗುರುಳ್',
'ಕುಡುಗೊರಳು',
'ಕುಡುತೆಗಣ್',
'ಕುಡುಪುವಾಲ',
'ಕುಡುವಾವುಜ',
'ಕುಡುಹಿವೆಣ್',
'ಕುಡ್ಡಬೆಳ್ಳಕ್ಕಿ',
'ಕುಣಪಭೂಮಿ',
'ಕುಣಬಿಮಣಿ',
'ಕುಣಿಕೆಗಂಟು',
'ಕುಣಿಕೆಗಾಱ',
'ಕುಣಿಕೆನೋಟ',
'ಕುಣಿಕೆಹುರಿ',
'ಕುತಂತ್ರಗಾರ',
'ಕುತಕುತನೆ',
'ಕುತನೀಗಾದಿ',
'ಕುತಪಕಾಲ',
'ಕುತುಪಕಾಲ',
'ಕುತೂಹಲಿಕೆ',
'ಕುತೂಹಲಿತ',
'ಕುತೂಹಲಿತೆ',
'ಕುತೂಹಳಿಸು',
'ಕುತ್ತಕರಣ',
'ಕುತ್ತಗೊಳಿಸು',
'ಕುತ್ತಡಿಯಿಡು',
'ಕುತ್ತಪೊಡಿಯ',
'ಕುತ್ತವೆಳಗು',
'ಕುತ್ತಿಕಾಣಿಕೆ',
'ಕುತ್ತಿಗೆಕೊಯ್ಕ',
'ಕುತ್ತಿಗೆಗೊಯ್ಕ',
'ಕುತ್ತುನವಿರ್',
'ಕುತ್ತುಱುಗೊಳ್',
'ಕುತ್ತುಱುವೆಳೆ',
'ಕುತ್ಸಿತದೃಷ್ಟಿ',
'ಕುತ್ಸಿತಧರ್ಮ',
'ಕುತ್ಸಿತಯೋನಿ',
'ಕುತ್ಸಿತವೃತ್ತಿ',
'ಕುತ್ಸಿತಾಚಾರ',
'ಕುತ್ಸಿತಾಹಾರ',
'ಕುದಿಕತನ',
'ಕುದಿಗೊಳಿಸು',
'ಕುದಿತಮೆಟ್ಟು',
'ಕುದುಕಲಕ್ಕಿ',
'ಕುದುಕುವರಿ',
'ಕುದುಕುಳಿಗ',
'ಕುದುಪಲಕ್ಕಿ',
'ಕುದುರುಗೊಳ್',
'ಕುದುರುಪಾಟು',
'ಕುದುರೆಕಾಲ್',
'ಕುದುರೆಕಾಱ',
'ಕುದುರೆಗಂಡಿ',
'ಕುದುರೆಗಾಡಿ',
'ಕುದುರೆಗಾಪು',
'ಕುದುರೆಗಾಲ್',
'ಕುದುರೆಗಾಲು',
'ಕುದುರೆಗಾಹು',
'ಕುದುರೆಗಾಱ',
'ಕುದುರೆಜಮೆ',
'ಕುದುರೆಜವಿ',
'ಕುದುರೆಜೂಜು',
'ಕುದುರೆನಗೆ',
'ಕುದುರೆನೊಣ',
'ಕುದುರೆಪಂದ್ಯ',
'ಕುದುರೆಬಚ್ಚ',
'ಕುದುರೆಮಾಡು',
'ಕುದುರೆಮೀನು',
'ಕುದುರೆಮುಖ',
'ಕುದುರೆಮೊಟ್ಟೆ',
'ಕುದುರೆಯಾಟ',
'ಕುದುರೆಯಾಡು',
'ಕುದುರೇಬಳ್ಳಿ',
'ಕುದುವುಗಾರ',
'ಕುದ್ದರಗೇಡಿ',
'ಕುದ್ದಲಗೇಡಿ',
'ಕುನಯಕರ್ಮ',
'ಕುನರರೂಪು',
'ಕುನ್ನಿಮಾನವ',
'ಕುನ್ನೀರುಕೊಳ',
'ಕುಪಿತಮತಿ',
'ಕುಪ್ಪಲುಮಣ್ಣು',
'ಕುಪ್ಪಿಗೆಮಾತ್ರೆ',
'ಕುಪ್ಪೆಗೂಡಿಸು',
'ಕುಪ್ರತರ್ಕಿಗ',
'ಕುಪ್ರಬೋಧಾತ್ಮ',
'ಕುಬೇತಾಯಿತ',
'ಕುಬೇರಕಾಂತ',
'ಕುಬೇರಪುತ್ರ',
'ಕುಮಕಿಆನೆ',
'ಕುಮಕಿಹಕ್ಕು',
'ಕುಮತಿತನ',
'ಕುಮರಿಪೈರು',
'ಕುಮರಿಬೆತ್ತ',
'ಕುಮಾನುಷತ್ವ',
'ಕುಮಾರಕಾಲ',
'ಕುಮಾರಗುರು',
'ಕುಮಾರಸ್ವಾಮಿ',
'ಕುಮುದಕರ',
'ಕುಮುದಕ್ರಿಯಾ',
'ಕುಮುದಗಡ್ಡೆ',
'ಕುಮುದಗೆಡ್ಡೆ',
'ಕುಮುದಪ್ರಿಯ',
'ಕುಮುದವೈರಿ',
'ಕುಮುದಸಖ',
'ಕುಮುದಾಕರ',
'ಕುಮೆರಿಬೆತ್ತ',
'ಕುಮ್ಮಕ್ಕುದಾರ',
'ಕುಮ್ಮರಿಗಡಿ',
'ಕುಯಲುಗಾರ',
'ಕುಯುಕ್ತಿವಂತ',
'ಕುರಂಗಧರ',
'ಕುರಂಗನಾಭಿ',
'ಕುರಂಗನೇತ್ರೆ',
'ಕುರಂಗಮುದ್ರೆ',
'ಕುರಂಗರಿಪು',
'ಕುರಂಗಾಂಬಕಿ',
'ಕುರಂಗುಕಟ್ಟು',
'ಕುರಚಿಕಾಡು',
'ಕುರಚಿಗಿಡ',
'ಕುರತಿರುಗಿ',
'ಕುರಿತೆರಿಗೆ',
'ಕುರಿಮುಸುಡೆ',
'ಕುರುಂಟುಕಟ್ಟು',
'ಕುರುಕುತಿಂಡಿ',
'ಕುರುಕುಪದ',
'ಕುರುಕುರಿಸು',
'ಕುರುಚುಕಾಡು',
'ಕುರುಜುಮಾಡ',
'ಕುರುಡುಗಣ್ಣು',
'ಕುರುಡುಗಲ್ಲಿ',
'ಕುರುಡುಗಳೆ',
'ಕುರುಡುಗಾಜು',
'ಕುರುಡುಗಾಸು',
'ಕುರುಡುಗುರಿ',
'ಕುರುಡುಗೈಯು',
'ಕುರುಡುಚಿತ್ತೆ',
'ಕುರುಡುತನ',
'ಕುರುಡುನಂದಿ',
'ಕುರುಡುಪಾಠ',
'ಕುರುಡುಬಿಂದು',
'ಕುರುಡುಮೀನು',
'ಕುರುಡುಸನ್ನಿ',
'ಕುರುಡುಹುಳು',
'ಕುರುಣೆವಱಿ',
'ಕುರುಬಗಿತಿ',
'ಕುರುಬಗಿತ್ತಿ',
'ಕುರುಬತನ',
'ಕುರುಬಾಗಿಲು',
'ಕುರುವಗೆಯ್',
'ಕುರುವಿಂದಕ',
'ಕುರುಳಿಗೊಳ್',
'ಕುರುಳುಪಾದಿ',
'ಕುರುಳುವಳಿ',
'ಕುರಳ್ಗೂದಲ್',
'ಕುರೂಪಿತನ',
'ಕುರ್ಕುರಧ್ವನಿ',
'ಕುರ್ಕುರಿರೋಗ',
'ಕುಲಕಂಟಕ',
'ಕುಲಕಂಟಕಿ',
'ಕುಲಕರಣಿ',
'ಕುಲಕರ್ಕಟಿ',
'ಕುಲಕರ್ಣಿಕೆ',
'ಕುಲಕಸಬು',
'ಕುಲಕಸುಬು',
'ಕುಲಕಳಂಕ',
'ಕುಲಕಾಯಕ',
'ಕುಲಕುಧರ',
'ಕುಲಗಡಿಯ',
'ಕುಲಗೆಡಿಕ',
'ಕುಲಗೆಡಿಕೆ',
'ಕುಲಗೆಡಿಸು',
'ಕುಲಗೆಡುಕ',
'ಕುಲಗೆಡುಕಿ',
'ಕುಲಗೇಡಿಗ',
'ಕುಲಟಾಂಗನೆ',
'ಕುಲಟಾವಧು',
'ಕುಲತಿಲಕ',
'ಕುಲಥಾಂಜನ',
'ಕುಲದೀಪಕ',
'ಕುಲದೂಷಣ',
'ಕುಲದೇವತೆ',
'ಕುಲದೇವರು',
'ಕುಲನಾಶಕ',
'ಕುಲಪರ್ವತ',
'ಕುಲಪಾಂಸುಲ',
'ಕುಲಪಾಂಸುಲೆ',
'ಕುಲಪಾಂಸುಳೆ',
'ಕುಲಪಾಲನ',
'ಕುಲಪಾಲಿಕೆ',
'ಕುಲಪಾಳಿಕೆ',
'ಕುಲಪ್ರದೀಪ',
'ಕುಲಪ್ರಮಾಣ',
'ಕುಲಭೂಷಣ',
'ಕುಲಭೂಷಣೆ',
'ಕುಲಮಹಿಷಿ',
'ಕುಲಮಾನಿನಿ',
'ಕುಲರಸಿಕ',
'ಕುಲವನಿತೆ',
'ಕುಲವರ್ಧನ',
'ಕುಲವಾಡಿಕೆ',
'ಕುಲವಿನಾಶಿ',
'ಕುಲವಿಭ್ರಂಶೆ',
'ಕುಲವಿಹೀನ',
'ಕುಲವಿಹೀನೆ',
'ಕುಲಸಂಕರ',
'ಕುಲಸಂಭವ',
'ಕುಲಸಂಹಾರಿ',
'ಕುಲಸಮೃದ್ಧಿ',
'ಕುಲಸಾರಿಕೆ',
'ಕುಲಸೂತಕ',
'ಕುಲಾಧಿಪತಿ',
'ಕುಲಾಪಘಾತ',
'ಕುಲಾಪವಾದ',
'ಕುಲಾಲಚಕ್ರ',
'ಕುಲಿಶಗೊಳ್',
'ಕುಲಿಶಧರ',
'ಕುಲಿಶಧಾರಿ',
'ಕುಲಿಶಹತಿ',
'ಕುಲಿಶಹಸ್ತ',
'ಕುಲಿಶಾಯುಧ',
'ಕುಲೀನತಂತ್ರ',
'ಕುಲೀನವೃತ್ತಿ',
'ಕುಲುಕಾಡಿಸು',
'ಕುಲುಕುಗಾತಿ',
'ಕುಲುಕುಬಾತು',
'ಕುಲುಮೆಗಾಱ',
'ಕುಲುಲುಗುಟ್ಟು',
'ಕುಲೋದ್ಧಾರಕ',
'ಕುಲೋರ್ವೀಧರ',
'ಕುವಲಯಿತ',
'ಕುವಳಯಿತ',
'ಕುಶಲಕರ್ಮ',
'ಕುಶಲಕರ್ಮಿ',
'ಕುಶಲಕಲೆ',
'ಕುಶಲಕ್ರಿಯೆ',
'ಕುಶಲಗಾರ',
'ಕುಶಲಗಾರ್ತಿ',
'ಕುಶಲಗೆಡು',
'ಕುಶಲಚಿತ್ರ',
'ಕುಶಲತನ',
'ಕುಶಲನಡೆ',
'ಕುಶಲಪ್ರಶ್ನ',
'ಕುಶಲಪ್ರಶ್ನೆ',
'ಕುಶಲವಸ್ತು',
'ಕುಶಲವಾರ್ತೆ',
'ಕುಶಲವಿದ್ಯ',
'ಕುಶಲಹಸ್ತ',
'ಕುಶಲಹಸ್ತೆ',
'ಕುಶಶಯನ',
'ಕುಶಾಗ್ರಬುದ್ಧಿ',
'ಕುಶಾಗ್ರಮತಿ',
'ಕುಶಾಲುತೋಪು',
'ಕುಶಿಕೋದ್ಭವ',
'ಕುಶೂಲಧಾನ್ಯ',
'ಕುಸಲಂಬೆಱು',
'ಕುಸಿಗೊರಲ್',
'ಕುಸಿಗೊರಲ',
'ಕುಸಿಗೊರಲಿ',
'ಕುಸಿಗೊಳಿಸು',
'ಕುಸೀದಾಗಾರ',
'ಕುಸುಂಬೆಯೆಣ್ಣೆ',
'ಕುಸುಂಬೆವಟ್ಟೆ',
'ಕುಸುಬಲಕ್ಕಿ',
'ಕುಸುಮಕುಲ',
'ಕುಸುಮಧನ್ವ',
'ಕುಸುಮಧೂಳಿ',
'ಕುಸುಮಬಾಣ',
'ಕುಸುಮಭವ',
'ಕುಸುಮಮಾಲೆ',
'ಕುಸುಮಮಾಳೆ',
'ಕುಸುಮರಕ್ತ',
'ಕುಸುಮರಜ',
'ಕುಸುಮರಸ',
'ಕುಸುಮವರ್ಷ',
'ಕುಸುಮವೃಷ್ಟಿ',
'ಕುಸುಮಶರ',
'ಕುಸುಮಸಾರ',
'ಕುಸುಮಾಂಘ್ರಿಪ',
'ಕುಸುಮಾಂಜನ',
'ಕುಸುಮಾಂಜಲಿ',
'ಕುಸುಮಾಕರ',
'ಕುಸುಮಾಕೀರ್ಣ',
'ಕುಸುಮಾಮೋದ',
'ಕುಸುಮಾಯುಧ',
'ಕುಸುಮಾಸವ',
'ಕುಸುಮಾಸಾರ',
'ಕುಸುಮೆಜಾಡ್ಯ',
'ಕುಸುಮೋತ್ಕರ',
'ಕುಸುರಿಕಾಳು',
'ಕುಸುರಿಕಿಡಿ',
'ಕುಸುರಿಖಂಡ',
'ಕುಸುರಿಗಂಡ',
'ಕುಸುರಿಗಾಯಿ',
'ಕುಸುರಿತಿರಿ',
'ಕುಸುರಿದಱಿ',
'ಕುಸುರಿಮಾತು',
'ಕುಸುರಿವಾತು',
'ಕುಸುರಿವೆಸ',
'ಕುಸುರುಗಾಯಿ',
'ಕುಸುವಲಕ್ಕಿ',
'ಕುಸೂಲಗೃಹ',
'ಕುಸೃತಿಬಂಧ',
'ಕುಹಕತಂತ್ರ',
'ಕುಹಕತನ',
'ಕುಹಕಬುದ್ಧಿ',
'ಕುಹಕವಿದ್ಯೆ',
'ಕುಹಕೋಪಾಯ',
'ಕುಳಕರಣಿ',
'ಕುಳಕಾಳಾಂಚಿ',
'ಕುಳಕೂಡಿಸು',
'ಕುಳತಿಳಕ',
'ಕುಳತೆರಿಗೆ',
'ಕುಳತೆಱಿಗೆ',
'ಕುಳದೆಱಿಗೆ',
'ಕುಳಬಿರಾಡ',
'ಕುಳಭವನ',
'ಕುಳಭೂಷಣ',
'ಕುಳಭೂಷಣೆ',
'ಕುಳಮಂದಿರ',
'ಕುಳವರ್ತನೆ',
'ಕುಳವಾಡಿಕೆ',
'ಕುಳವಾಡಿಗ',
'ಕುಳವಾರ್ಚಿಟ್ಟೆ',
'ಕುಳವಾರ್ಚಿಟ್ಠ',
'ಕುಳವಾರ್ಪಟ್ಟಿ',
'ಕುಳವಾರ್ಪಟ್ಟೆ',
'ಕುಳಿಕೋರಗ',
'ಕುಳಿರುಕೋಳು',
'ಕುಳಿರುವೆಟ್ಟು',
'ಕುಳಿರೆಲರ್',
'ಕುಳಿರ್ಕೊಳಿಸು',
'ಕುಳಿರ್ಕೋಡಿಸು',
'ಕುಳಿರ್ಗದಿರ್',
'ಕುಳಿರ್ಗದಿರ',
'ಕುಳಿರ್ಗದಿರು',
'ಕುಳಿರ್ಗಲ್ಲಱೆ',
'ಕುಳಿರ್ಗೊಳಿಸು',
'ಕುಳಿರ್ದಿಂಗಳ್',
'ಕುಳಿರ್ಮಸಗು',
'ಕುಳಿರ್ವೆರಸು',
'ಕುಳಿರ್ವೆಳಗ',
'ಕುಳಿಶಧರ',
'ಕುಳಿಶಾಗಾರ',
'ಕುಳಿಶಾಯುಧ',
'ಕುಳುಂಪೆಗೊಳ್',
'ಕುಳುಮೆಕಾರ್ತಿ',
'ಕುಳ್ಳಕುಮ್ಮಟೆ',
'ಕುಳ್ಳಬಯಿನೆ',
'ಕುಱಿಂಬದೆಱೆ',
'ಕುಱಿದಲೆಗ',
'ಕುಱಿದಱಿಹಿ',
'ಕುಱಿದೆಱಿಗೆ',
'ಕುಱಿಪಟ್ಟಿಗೆ',
'ಕುಱಿವಡವು',
'ಕುಱಿವಾನಿಸ',
'ಕುಱುಂಬಕೋಟೆ',
'ಕುಱುಂಬುಗಾಱ',
'ಕುಱುಕುಣಿಕೆ',
'ಕುಱುಕುಮೆಟ್ಟು',
'ಕುಱುಪುಗೊಳ್',
'ಕುಱುಪುವೇೞ್',
'ಕುಱುಹುಗಾಣ್',
'ಕುಱುಹುಗೆಡು',
'ಕುಱುಹುಗೊಡು',
'ಕುಱುಹುಗೊಳ್',
'ಕುಱುಹುತೋಱು',
'ಕುಱುಹುದಾಣ',
'ಕುಱುಹುದೊಱೆ',
'ಕುಱುಹುದೋಱು',
'ಕುಱುಹುವಿಡಿ',
'ಕುೞಿತೆವಱು',
'ಕುೞಿನೊಸಲ್',
'ಕುೞಿವುರ್ಕುೞ್',
'ಕೂಂಟಣಿದೊೞ್ತು',
'ಕೂಕುಂಬಕಾಱ',
'ಕೂಕುಂಬಿಕಾಱ',
'ಕೂಗಟಿವಕ್ಕಿ',
'ಕೂಗಟೆಹುಣ್ಣು',
'ಕೂಚುಗೊಳಿಸು',
'ಕೂಚುನಗಾರಿ',
'ಕೂಟಚಾತುರ್ಯ',
'ಕೂಟನೀತಿಜ್ಞ',
'ಕೂಟನೀತಿಜ್ಞೆ',
'ಕೂಟಪಾಕಳ',
'ಕೂಟಪಾಲಕ',
'ಕೂಟಪ್ರಕರ',
'ಕೂಟಶಾಲ್ಮಲಿ',
'ಕೂಟಶಾಸನ',
'ಕೂಟಸಂಭವ',
'ಕೂಟಸಮಸ್ಯೆ',
'ಕೂಟಸ್ಥಸಾಕ್ಷಿ',
'ಕೂಡಲಿಸೊಪ್ಪು',
'ಕೂಡಲುಗೊಳ್',
'ಕೂಡುಕುಟುಂಬ',
'ಕೂಡುಗಲಕ',
'ಕೂಡುಗೆಲಸ',
'ಕೂಡುಗೆಳೆಯ',
'ಕೂಡುಜೀವನ',
'ಕೂಡುಲಗಾಮು',
'ಕೂಡುಹಿಡಿಕೆ',
'ಕೂತುವಾಹನ',
'ಕೂನಗೊರಡು',
'ಕೂಪಮಂಡೂಕ',
'ಕೂಪಾರಾರವ',
'ಕೂಪಾರೋದಿತ',
'ಕೂರಿಗೆನೆಲ್ಲು',
'ಕೂರುಗುರ್ಗಲೆ',
'ಕೂರುಗೆಂಜಿಗ',
'ಕೂರ್ಗಣೆವಳೆ',
'ಕೂರ್ಚಕಲಾಪ',
'ಕೂರ್ಚಕಳಾಪ',
'ಕೂರ್ಮಪುರಾಣ',
'ಕೂರ್ಮಸೆಯಿಡು',
'ಕೂರ್ಮಾವತಾರ',
'ಕೂರ್ಸರಲ್ಸರಿ',
'ಕೂಲಿಕೆಲಸ',
'ಕೂಲಿಕೊಳಗ',
'ಕೂವಕಂಬಿಗ',
'ಕೂಸುಬಿನ್ನಾಣ',
'ಕೂಳ್ಗುದಿಗುದಿ',
'ಕೂಳ್ಗುದಿಗೊಳ್',
'ಕೂೞ್ಕುದಿಗೊಳ್',
'ಕೂೞ್ಗುದಿಗೊಳ್',
'ಕೃಚ್ಛ್ರಜೀವನ',
'ಕೃಚ್ಛ್ರಸಾಧನೆ',
'ಕೃತಕ ಅಂಗ',
'ಕೃತಕಗಿರಿ',
'ಕೃತಕನಾಣ್ಯ',
'ಕೃತಕನಿದ್ರೆ',
'ಕೃತಕಪುತ್ರ',
'ಕೃತಕಭಾಷೆ',
'ಕೃತಕಮಳೆ',
'ಕೃತಕರೂಪು',
'ಕೃತಕರೇಷ್ಮೆ',
'ಕೃತಕವಾರ್ತೆ',
'ಕೃತಕಶೈಲ',
'ಕೃತಕಾಚಲ',
'ಕೃತಕಾಚಳ',
'ಕೃತಕೃತ್ಯತೆ',
'ಕೃತಘ್ನಭಾವ',
'ಕೃತಲಕ್ಷಣ',
'ಕೃತಸಮಯ',
'ಕೃತಾಂತಗೇಹ',
'ಕೃತಾನುಶಯ',
'ಕೃತಾಪರಾಧ',
'ಕೃತಾಪರಾಧೆ',
'ಕೃತಾಭಿಷೇಕ',
'ಕೃತಾರ್ಥವೃತ್ತಿ',
'ಕೃತಾರ್ಥೀಕೃತ',
'ಕೃತಾವಧಾನ',
'ಕೃತಿನಾಯಕ',
'ಕೃತಿನಿಂದಿತ',
'ಕೃತಿವಂತಿಕೆ',
'ಕೃತಿಸಮರ್ಥ',
'ಕೃತ್ತಿಕಂಠಿಕೆ',
'ಕೃತ್ತಿಕಾಕೃತಿ',
'ಕೃತ್ತಿಕಾಪುಂಜ',
'ಕೃತ್ತಿವಸನ',
'ಕೃತ್ಯಾವಿಗ್ರ್ರಹ',
'ಕೃತ್ರಿಮವಿಷ',
'ಕೃದಂತನಾಮ',
'ಕೃದಂತಾವ್ಯಯ',
'ಕೃಪಣತನ',
'ಕೃಪಣಮತಿ',
'ಕೃಪಾಕಟಾಕ್ಷ',
'ಕೃಪಾಣಪಾಣಿ',
'ಕೃಪಾಳುತನ',
'ಕೃಪೀಟಯೋನಿ',
'ಕೃಪೆವೆರಸು',
'ಕೃಮಿಕೋಶೋತ್ಥ',
'ಕೃಶಾನುಗಣ್ಣು',
'ಕೃಶಾನುನೇತ್ರ',
'ಕೃಶಾನುರೇತ',
'ಕೃಶಾನುಶಿಖೆ',
'ಕೃಶೀಕರಣ',
'ಕೃಷಿನಿರತ',
'ಕೃಷಿವಿಹೀನ',
'ಕೃಷ್ಣ ಅಗರು',
'ಕೃಷ್ಣಜಯಂತಿ',
'ಕೃಷ್ಣಜಿಹ್ವಕ',
'ಕೃಷ್ಣಜೀರಿಗೆ',
'ಕೃಷ್ಣತುಲಸಿ',
'ಕೃಷ್ಣತುಳಸಿ',
'ಕೃಷ್ಣಪಟಲ',
'ಕೃಷ್ಣಪಾಂಡುರ',
'ಕೃಷ್ಣಭುಜಗ',
'ಕೃಷ್ಣಮೃತ್ತಿಕೆ',
'ಕೃಷ್ಣರಸನ',
'ಕೃಷ್ಣರುಚಿತ್ವ',
'ಕೃಷ್ಣಲಾಂಗಲಿ',
'ಕೃಷ್ಣಲೋಹಿತ',
'ಕೃಷ್ಣಶಲಾಕೆ',
'ಕೃಷ್ಣಹೃದಯ',
'ಕೃಷ್ಣಹೃದಯೆ',
'ಕೃಷ್ಣಾಚರಣ',
'ಕೃಷ್ಣಾವತಾರ',
'ಕೆಂಗಣಿಗಲು',
'ಕೆಂಗಣಿಗಿಲೆ',
'ಕೆಂಗಣಿಜಿಲು',
'ಕೆಂಗಣ್ಣಪಕ್ಕಿ',
'ಕೆಂಗಣ್ಣವಕ್ಕಿ',
'ಕೆಂಗಣ್ಣುಜ್ವರ',
'ಕೆಂಗದಿರ್ವಣಿ',
'ಕೆಂಗಲೆಗಟ್ಟು',
'ಕೆಂಗಲೆವಡೆ',
'ಕೆಂಗಲೆಸುಂಡು',
'ಕೆಂಗಲ್ಮಸಗು',
'ಕೆಂಗವಸಣಿ',
'ಕೆಂಗಱಿಗಣ್',
'ಕೆಂಗಱಿಗೋಲ್',
'ಕೆಂಗಱಿಗೋಲು',
'ಕೆಂಗಿಡಿಗಣ್',
'ಕೆಂಗಿಡಿಗಱೆ',
'ಕೆಂಗಿಡಿಗೋಲ್',
'ಕೆಂಗೋಲ್ಮಸಗು',
'ಕೆಂಚಿಗೋಗಡ',
'ಕೆಂಜರಿಗಣ್ಣ',
'ಕೆಂಜುಟ್ಟುವಕ್ಕಿ',
'ಕೆಂಜುರಿಗಣ್',
'ಕೆಂಜೆರೆಗಣ್',
'ಕೆಂಡಕೆದಱು',
'ಕೆಂಡಗೆದಱು',
'ಕೆಂಡಮಂಡಳ',
'ಕೆಂಡಸಂಪಗೆ',
'ಕೆಂಡಸಂಪಿಗೆ',
'ಕೆಂದಲೆಹಾವು',
'ಕೆಂದಳಿರ್ಮಾವು',
'ಕೆಂದಳಿರ್ವಸೆ',
'ಕೆಂದಳಿರ್ವಾಸು',
'ಕೆಂದಾವರೆವೂ',
'ಕೆಂಪುಕಂಟಲ',
'ಕೆಂಪುಕಗ್ಗಲಿ',
'ಕೆಂಪುಕೆಂಜಿಗ',
'ಕೆಂಪುಕೊಕ್ಕರೆ',
'ಕೆಂಪುಗೆಣಸು',
'ಕೆಂಪುಗೋರಂಟೆ',
'ಕೆಂಪುಗೋರಟೆ',
'ಕೆಂಪುಚಂದನ',
'ಕೆಂಪುತುರಾಯಿ',
'ಕೆಂಪುದಳಿರ್',
'ಕೆಂಪುದಾವರೆ',
'ಕೆಂಪುಪುಂಡ್ರಿಕೆ',
'ಕೆಂಪುಬದನೆ',
'ಕೆಂಪುಬೂರಗ',
'ಕೆಂಪುಬೂರುಗ',
'ಕೆಂಪುಮಂದಾರ',
'ಕೆಂಪುಮಲ್ಲಿಗೆ',
'ಕೆಂಪುಮೂಲಂಗಿ',
'ಕೆಂಪುಮೆಣಸು',
'ಕೆಂಪುಲಾವಗ',
'ಕೆಂಪುವಾರಿಜ',
'ಕೆಂಪುಸಾಸಿವೆ',
'ಕೆಂಪುಹಂಡಲ',
'ಕೆಂಪುಹಾದರಿ',
'ಕೆಂಪೊಡರಿಸು',
'ಕೆಂಬರಲೋಲೆ',
'ಕೆಂಬರುಗಣ್ಣು',
'ಕೆಂಬಸದನ',
'ಕೆಂಬಳಯಿಗೆ',
'ಕೆಂಬೞವಿಗೆ',
'ಕೆಂಬಾಸುಳೇಳು',
'ಕೆಕ್ಕರಹಣ್ಣು',
'ಕೆಕ್ಕಲಗೆಲೆ',
'ಕೆಕ್ಕಸಕೆಲೆ',
'ಕೆಕ್ಕಸಗೆಲೆ',
'ಕೆಕ್ಕಳಗಣ್',
'ಕೆಚ್ಚಲುಬಾವು',
'ಕೆಟ್ಟಾಸೆಬೀಡು',
'ಕೆಡಕುತನ',
'ಕೆಡಕುಹುಳು',
'ಕೆಡಕೆನಿಸು',
'ಕೆಡಗನಸು',
'ಕೆಡುಕತನ',
'ಕೆಡುಕುತನ',
'ಕೆಡುವೊಡಲ್',
'ಕೆಡುವೊಡವೆ',
'ಕೆಡೆಹಾಯ್ಕಿಸು',
'ಕೆಣಕುಕಣ್ಣು',
'ಕೆಣಕುಗೊಡು',
'ಕೆಣಕುನಗೆ',
'ಕೆಣಕುವಡು',
'ಕೆತ್ತಕುಱುವ',
'ಕೆದರ್ಗೂದಲ್',
'ಕೆದಱುದಲೆ',
'ಕೆದಱುಮಂಡೆ',
'ಕೆದಱುಮೀಸೆ',
'ಕೆನೆಕೆಂದಾಳ',
'ಕೆನೆಮೊಸರ್',
'ಕೆನೆಮೊಸರು',
'ಕೆನೆವೆರಸು',
'ಕೆನ್ನಘಾತಕಿ',
'ಕೆಪ್ಪಟರಾಯ',
'ಕೆಯ್ಗಾಣ್ಕೆಗೆಯ್',
'ಕೆಯ್ಪಱೆಗುಟ್ಟು',
'ಕೆಯ್ಪಿಡಿಗೋಡೆ',
'ಕೆಯ್ವಸಗೊಳ್',
'ಕೆಯ್ಸನ್ನೆಗೆಯ್',
'ಕೆಯ್ಸೂಱೆಗೊಳ್',
'ಕೆಯ್ಸೆಱೆಗೊಳ್',
'ಕೆಯ್ಸೆಱೆವಿಡಿ',
'ಕೆರಂಕುಗಲ್ಲು',
'ಕೆರಕಲನ್ನ',
'ಕೆರಕುಗಲ್ಲು',
'ಕೆರವುಗಳೆ',
'ಕೆರಹುಗಳೆ',
'ಕೆರಹುಗಾಲ್',
'ಕೆರಹುಗಾಲು',
'ಕೆರೆಯೊಡಲು',
'ಕೆರೆಹಿಪ್ಪಲಿ',
'ಕೆಲಂದೊಲಗು',
'ಕೆಲಸಂಗೆಯ್',
'ಕೆಲಸಂಗೊಡು',
'ಕೆಲಸಂಬೊಗು',
'ಕೆಲಸಂಬೊಱು',
'ಕೆಲಸಕೊಡು',
'ಕೆಲಸಗತಿ',
'ಕೆಲಸಗಳ್ಳ',
'ಕೆಲಸಗಳ್ಳಿ',
'ಕೆಲಸಗಾರ',
'ಕೆಲಸಗಿತ್ತಿ',
'ಕೆಲಸಗೇಡಿ',
'ಕೆಲಸಗೇಡು',
'ಕೆಲಸಾರಿಸು',
'ಕೆಸರಕಲ್ಲು',
'ಕೆಸರುಕಾಪು',
'ಕೆಸರುತಡೆ',
'ಕೆಸರುಮೀನು',
'ಕೆಸರುಹುಣ್ಣು',
'ಕೆಸರ್ಮಲ್ಲಿಗೆ',
'ಕೆಸರ್ಮಸಗು',
'ಕೆಸಱುಕಲ್ಲು',
'ಕೆಸಱುಗಲ್ಲು',
'ಕೆಸಱುವಣ',
'ಕೆಳದವಡೆ',
'ಕೆಳದಿಮೀನು',
'ಕೆಳೆಗೊಳಿಸು',
'ಕೆಳೆಯತನ',
'ಕೆಳೆಸಂಕಲೆ',
'ಕೆಱೆಕೊಡಗಿ',
'ಕೆಱೆಗೊಡಂಗೆ',
'ಕೆಱೆಗೊಡಗಿ',
'ಕೆಱೆಗೊಡಗೆ',
'ಕೆಱೆಗೊಡಿಗೆ',
'ಕೇಂದ್ರಾಪಗಾಮಿ',
'ಕೇಂದ್ರಾಭಿಗಾಮಿ',
'ಕೇಂದ್ರಾವನತ',
'ಕೇಂದ್ರೀಕರಣ',
'ಕೇಂದ್ರೀಕರಿಸು',
'ಕೇಕರದ್ಯುತಿ',
'ಕೇಕರರುಚಿ',
'ಕೇಕಾನಿನಾದ',
'ಕೇಕಿತಾಂಡವ',
'ಕೇಕಿನರ್ತನ',
'ಕೇಕಿವಿಳಾಸ',
'ಕೇಡಿಗತನ',
'ಕೇಡುಗತನ',
'ಕೇತಕೀಪುಷ್ಪ',
'ಕೇತಾರಗೌಳ',
'ಕೇತುದರ್ಶಕ',
'ಕೇದಗೆಮೊಲೆ',
'ಕೇದಾರಗೌಳ',
'ಕೇದಾರವನ',
'ಕೇದಾರೇಶ್ವರ',
'ಕೇದಿಗೆಬೀಳು',
'ಕೇನಿಪಾತಕ',
'ಕೇರಮಂಡಲ',
'ಕೇಲಿಕಾಗೃಹ',
'ಕೇಲಿಮಂದಿರ',
'ಕೇಲೀನಿಲಯ',
'ಕೇವಲಜ್ಞಾನ',
'ಕೇವಲಜ್ಞಾನಿ',
'ಕೇವಲಬೋಧ',
'ಕೇವಲಬೋಧೆ',
'ಕೇವಲಮಿತ್ರ',
'ಕೇವಲಾವಸ್ಥೆ',
'ಕೇವಳಜ್ಞಾನ',
'ಕೇವಳಜ್ಞಾನಿ',
'ಕೇವಳಬೋಧ',
'ಕೇಶಕಲ್ಯಾಣ',
'ಕೇಶಬಂಧನ',
'ಕೇಶಮಾರ್ಜನ',
'ಕೇಶಮಾರ್ಜನಿ',
'ಕೇಶವಪನ',
'ಕೇಶವರ್ಧಕ',
'ಕೇಶವಿಧಾನ',
'ಕೇಶವಿನ್ಯಾಸ',
'ಕೇಸರದಂಡ',
'ಕೇಸರದ್ರುಮ',
'ಕೇಸರಧರ',
'ಕೇಸರಭಾತು',
'ಕೇಸರರಜ',
'ಕೇಸರಿದ್ವಿಷ',
'ಕೇಸರಿಪೀಠ',
'ಕೇಸರಿಬೇಳೆ',
'ಕೇಸರಿಭತ್ತ',
'ಕೇಸರಿಭಾತು',
'ಕೇಸರಿಮಧ್ಯೆ',
'ಕೇಸರಿಮಾವು',
'ಕೇಸರಿಹೂವು',
'ಕೇಸುರಿಗಣ್',
'ಕೇಸುರಿಗಣ್ಣು',
'ಕೇಸುರಿಗಱೆ',
'ಕೇಳಿಗೋಪಾಲ',
'ಕೇಳಿನಿವಾಸ',
'ಕೇಳಿವಟ್ಟಲ್',
'ಕೇಳೀಕಳಾಪ',
'ಕೇಳೀನಿಲಯ',
'ಕೇಳೀಪ್ರಾಸಾದ',
'ಕೇಳೀಭೂಧರ',
'ಕೈಕರಣಿಕೆ',
'ಕೈಕುಲುಕಾಟ',
'ಕೈಕುಲುಕಿಸು',
'ಕೈಕೂಲಿಕಾಱ',
'ಕೈಕೂಲಿತನ',
'ಕೈಕೊಕ್ಕರಿಸು',
'ಕೈಗಡಿಯಾರ',
'ಕೈಗರಡಿಗೆ',
'ಕೈಚಳಕಿಗ',
'ಕೈಚೀಲಗಾರ',
'ಕೈಜೀತಗಾರ',
'ಕೈತಲೆ-ಆಟ್',
'ಕೈತವಕಿಗ',
'ಕೈದಂಡೆಕೊಡು',
'ಕೈನೆಱವೇರು',
'ಕೈಪರೆಗುಟ್ಟು',
'ಕೈಪಳಗಿಸು',
'ಕೈಪಱೆಗುಟ್ಟು',
'ಕೈಪಱೆವೊಯ್',
'ಕೈಪಿಡಿಗೋಡೆ',
'ಕೈಪಿಡಿದಾಣ',
'ಕೈಬಿಸಿಯಾಟ',
'ಕೈಮುಗಿಯಿಸು',
'ಕೈಮುದ್ದೆಗೊಳ್',
'ಕೈಮೇಳವಿಸು',
'ಕೈಯಫಿಯತ್ತು',
'ಕೈಯಾಣೆಯಿಡು',
'ಕೈಯಿಚ್ಚೆಗೆಡು',
'ಕೈಯೆಡೆಗುಡು',
'ಕೈಯೆಡೆಗೊಡು',
'ಕೈಯೆಡೆಮಾಡು',
'ಕೈರಗುಳಿಗೆ',
'ಕೈರದಂಬುಲ',
'ಕೈರವಗಂಧಿ',
'ಕೈರವಮಿತ್ರ',
'ಕೈಲಾಗುಕೊಡು',
'ಕೈಲಾಗುಗೊಡು',
'ಕೈಲಾಗುವಿಡಿ',
'ಕೈಲಾಸಪುರಿ',
'ಕೈಲಾಸವಸ್ತ್ರ',
'ಕೈಲಾಸವಾಸಿ',
'ಕೈಲಾಸವೀಣೆ',
'ಕೈಲುಳಿದೋಱು',
'ಕೈವಲ್ಯಪತಿ',
'ಕೈವಲ್ಯಬಳ್ಳಿ',
'ಕೈವಲ್ಯಬೋಧ',
'ಕೈವಾರಂಗೊಳ್',
'ಕೈವೆಡೆಗೊಡು',
'ಕೈಶಿಕೀವೃತ್ತಿ',
'ಕೈಸನ್ನೆಗೆಯ್',
'ಕೈಸನ್ನೆಮಾಡು',
'ಕೈಸರಿಹೊತ್ತು',
'ಕೈಸವರಿಸು',
'ಕೈಸೂಱೆಗಾಱ',
'ಕೈಸೂಱೆಗೊಳ್',
'ಕೈಸೂಱೆವೋಗು',
'ಕೈಸೆರೆಯಾಗು',
'ಕೈಸೆಱೆಗೊಳ್',
'ಕೈಸೆಱೆವಿಡಿ',
'ಕೈಸೇವೆಗೊಳ್',
'ಕೈಳಾಸಶೈಲ',
'ಕೈಳಾಸಾಚಳ',
'ಕೊಂಕಣಿಗಿತಿ',
'ಕೊಂಕುಕೊಸರು',
'ಕೊಂಕುಕೊಱತೆ',
'ಕೊಂಕುಗುರುಳು',
'ಕೊಂಕುಗೂದಲ್',
'ಕೊಂಕುಗೊರಲ್',
'ಕೊಂಕುವೆರಸು',
'ಕೊಂಗರಿಗೀಟು',
'ಕೊಂಡಮುಸುಕು',
'ಕೊಂಡಮುಸುಡಿ',
'ಕೊಂಡಮುಸುವ',
'ಕೊಂಡಮುಸುವು',
'ಕೊಂಡಾಡುಹಣ',
'ಕೊಂಡೆಗಾರತಿ',
'ಕೊಂಡೆನಾಲಗೆ',
'ಕೊಂಡೆಯತನ',
'ಕೊಂಬುಕಹಳೆ',
'ಕೊಂಬುಗಹಳೆ',
'ಕೊಂಬೆಯಬಳ್ಳಿ',
'ಕೊಕ್ಕರಗೆಯ್',
'ಕೊಕ್ಕರೆಕಾಲು',
'ಕೊಕ್ಕರೆರೋಗ',
'ಕೊಕ್ಕಳಗೊಳ್',
'ಕೊಕ್ಕೞಗುದಿ',
'ಕೊಕ್ಕುಳಂಗುದಿ',
'ಕೊಕ್ಕುಳಮೀನು',
'ಕೊಕ್ಕೆಮನುಷ್ಯ',
'ಕೊಕ್ಕೆಯೆತ್ತಿಗೆ',
'ಕೊಕ್ಕೋಕಶಾಸ್ತ್ರ',
'ಕೊಗ್ಗುದಿಗೊಳ್',
'ಕೊಟ್ಟಜಂಗೊಡು',
'ಕೊಟ್ಟಣಂಗುಟ್ಟು',
'ಕೊಟ್ಟಣಗಿತ್ತಿ',
'ಕೊಟ್ಟಣಗೇರಿ',
'ಕೊಟ್ಟಿಗೆಯೆತ್ತು',
'ಕೊಟ್ಟಿಗೆಣಸು',
'ಕೊಟ್ಟಿಗೆಮನೆ',
'ಕೊಟ್ಟುಕೊಡ್ತಲೆ',
'ಕೊಡಂಗಗೆಯಿ',
'ಕೊಡಂಗೆಗೊಡು',
'ಕೊಡಗಸಾಲೆ',
'ಕೊಡಗಿಗದ್ದೆ',
'ಕೊಡಗಿದಾರ',
'ಕೊಡಗಿಮಾನು',
'ಕೊಡಗಿಮಾನ್ಯ',
'ಕೊಡಗುಕತ್ತಿ',
'ಕೊಡಗುಮಲೆ',
'ಕೊಡಗೆಚ್ಚಲ್',
'ಕೊಡಗೆಮಾನ್ಯ',
'ಕೊಡಗೆಹೊಲ',
'ಕೊಡತಿಕೀಟ',
'ಕೊಡತಿಬೆಟ್ಟು',
'ಕೊಡತಿಮನ್ಯ',
'ಕೊಡತಿಮೀನು',
'ಕೊಡತಿವಣ',
'ಕೊಡತಿವಲೆ',
'ಕೊಡತಿವುೞು',
'ಕೊಡಮುರುಕ',
'ಕೊಡರುಕೊಂಚೆ',
'ಕೊಡಲಿಕಾವು',
'ಕೊಡಲಿಕಾಱ',
'ಕೊಡಲಿಗಡಿ',
'ಕೊಡಲಿಗಾವು',
'ಕೊಡಲಿಮರ',
'ಕೊಡಿ ಅರಿವೆ',
'ಕೊಡಿಗಿ ಇನಾಂ',
'ಕೊಡಿಗೆದಾರ',
'ಕೊಡಿಗೆಮಾನ್ಯ',
'ಕೊಡುಗೈತನ',
'ಕೊಡುಗೈವೀರ',
'ಕೊಡಗೆದರು',
'ಕೊಡೆಯೊಡೆಯ',
'ಕೊಡೆಶಿಲೀಂಧ್ರ',
'ಕೊಣದಪ್ಪಲೆ',
'ಕೊಣಬುಗಾಸು',
'ಕೊಣಸುಮಱಿ',
'ಕೊತಕೊತನೆ',
'ಕೊತ್ತಿಯಗೆಡ್ಡೆ',
'ಕೊನಬುಗಾತಿ',
'ಕೊನಬುಗಾರ',
'ಕೊನಬುಗಾರ್ತಿ',
'ಕೊನಬುಗಾಱ',
'ಕೊನಬುತನ',
'ಕೊನಬುದೋಱು',
'ಕೊನರಿಡಿಸು',
'ಕೊನರೆಣಿಕೆ',
'ಕೊನರ್ವೆರ್ಚಿಸು',
'ಕೊನೆಗಾಣಿಸು',
'ಕೊನೆಗೊಡಂಕೆ',
'ಕೊನೆಗೊನರು',
'ಕೊನೆಗೊಳಿಸು',
'ಕೊನೆನಾಲಿಗೆ',
'ಕೊನೆಮುಟ್ಟಿಸು',
'ಕೊನೆಯುಸಿರು',
'ಕೊನೆವುಗುರ್',
'ಕೊನೆವೆರಲು',
'ಕೊನೆವೆರಳ್',
'ಕೊನೆವೆರಳು',
'ಕೊನ್ನಾರಿಗೆಡ್ಡೆ',
'ಕೊಬ್ಬುಜವ್ವನ',
'ಕೊಬ್ಬುಜವ್ವನೆ',
'ಕೊಮಾರಪೈಕ',
'ಕೊಯಿಕತನ',
'ಕೊರಂಟುದಲೆ',
'ಕೊರಕಾಪುಳಿ',
'ಕೊರಟುತಲೆ',
'ಕೊರಟುದಲೆ',
'ಕೊರತೆಪಡು',
'ಕೊರಲಿೞಿವು',
'ಕೊರಲುಬ್ಬಸ',
'ಕೊರಲುಲುಹು',
'ಕೊರಲೆಹುಲ್ಲು',
'ಕೊರವುಕಾರ',
'ಕೊರಳುಕೊಯ್ಕ',
'ಕೊರಳುಗಟ್ಟು',
'ಕೊರಳುಗಣ್ಣಿ',
'ಕೊರಳುಗೊಯ್ಕ',
'ಕೊರಳುಗೊಯ್ಕೆ',
'ಕೊರಳುಚೌಕ',
'ಕೊರಳುಪಟ್ಟಿ',
'ಕೊರಳುಬ್ಬಸ',
'ಕೊರೆಸಂಬಳ',
'ಕೊಲಂಬಾಬೇರು',
'ಕೊಲವಲಿಕೆ',
'ಕೊಲೆಗಡಕ',
'ಕೊಲೆಗಡಿಕ',
'ಕೊಲೆಗಡಿಕೆ',
'ಕೊಲೆಗಡಿಗ',
'ಕೊಲೆಗಡುಕ',
'ಕೊಲೆಗಾರಿಕೆ',
'ಕೊಲೆಗೆಲಸ',
'ಕೊಲೆಪಾತಕ',
'ಕೊಲೆಪಾತಕಿ',
'ಕೊಲ್ಲಗೆಡಹು',
'ಕೊಲ್ಲಟಗಿತ್ತಿ',
'ಕೊಲ್ಲಟಿಗಿತ್ತಿ',
'ಕೊಲ್ಲಾರಬಂಡಿ',
'ಕೊಲ್ಲಾರಭಂಡಿ',
'ಕೊಲ್ಲಾರಿಬಂಡಿ',
'ಕೊವ್ವರೆಗೊಳ್',
'ಕೊಸರುಮಾತು',
'ಕೊಳಕರಣಿ',
'ಕೊಳಕುತನ',
'ಕೊಳಕುನಗೆ',
'ಕೊಳಕುನಾರು',
'ಕೊಳಕುನಾಱು',
'ಕೊಳಕುನುಡಿ',
'ಕೊಳಕುಮಾತು',
'ಕೊಳಕುವೃತ್ತಿ',
'ಕೊಳಕೊಳಕೆ',
'ಕೊಳಗದಾಯ',
'ಕೊಳಗುಣಕೆ',
'ಕೊಳಗುಳಿಕೆ',
'ಕೊಳಗೊಳಿಕೆ',
'ಕೊಳಚೆನೀರ್',
'ಕೊಳಚೆನೀರು',
'ಕೊಳದಪ್ಪಲೆ',
'ಕೊಳಲಗುಬ್ಬಿ',
'ಕೊಳಲುಗೋಳಿ',
'ಕೊಳಲುವಕ್ಕಿ',
'ಕೊಳವಳಿಕ',
'ಕೊಳವಳಿಕೆ',
'ಕೊಳವಾಳಿಕೆ',
'ಕೊಳವಿಹೂಟ',
'ಕೊಳವೆಬಾವಿ',
'ಕೊಳವೆಭಾವಿ',
'ಕೊಳವೆಮಾರ್ಗ',
'ಕೊಳವೆಹುಳು',
'ಕೊಳಸಂಪಿಗೆ',
'ಕೊಳುಗುಳಿಕ',
'ಕೊಳುಗುಳಿಕೆ',
'ಕೊಳ್ಗುದಿಗೊಳ್',
'ಕೊಳ್ಗುಳಂಗೊಳ್',
'ಕೊಳ್ಗುಳಗೊಳ್',
'ಕೊಳ್ಮಗಗೊಳ್',
'ಕೊಳ್ಳಿಕವಕ',
'ಕೊಳ್ಳಿಕಾಳಗ',
'ಕೊಳ್ಳಿಪಿಶಾಚಿ',
'ಕೊಳ್ಳಿವೀಸಿಸು',
'ಕೊಳ್ಳಿವೆಳಗು',
'ಕೊಳ್ಳುಕೊಡುಹ',
'ಕೊಱಡುಗೊಳ್',
'ಕೊೞವಿಮೀಂಟೆ',
'ಕೊೞೆಗಬ್ಬಿಗ',
'ಕೊೞೆಸಕ್ಕದ',
'ಕೊೞೆಸುಸಿಲ್',
'ಕೋಂಟೆಕಾಳಗ',
'ಕೋಂಟೆಗಾವಲ್',
'ಕೋಂಟೆಗಾಳಗ',
'ಕೋಂಟೆಗಾಳೆಗ',
'ಕೋಕಕಟ್ಟಳೆ',
'ಕೋಕಿಲತಂತ್ರ',
'ಕೋಕಿಲಧ್ವನಿ',
'ಕೋಕಿಲಪ್ರಿಯ',
'ಕೋಕಿಲಸ್ವನ',
'ಕೋಕಿಲಾರವ',
'ಕೋಕಿಲಾಸನ',
'ಕೋಗಿಲೆಗಿಡ',
'ಕೋಗಿಲೆಪುಳು',
'ಕೋಗಿಲೆಬಾಳೆ',
'ಕೋಗಿಲೆವೆಣ್',
'ಕೋಗುರಿವಾಜು',
'ಕೋಟರಪುಷ್ಪಿ',
'ಕೋಟಲೆಗುಡು',
'ಕೋಟಲೆಗೊಳ್',
'ಕೋಟಲೆಪಡು',
'ಕೋಟಲೆಬಡು',
'ಕೋಟಲೆವಡು',
'ಕೋಟಿಕಶಿಲೆ',
'ಕೋಟಿಯೇರಿಸು',
'ಕೋಟಿಸ್ಪರ್ಶಕ',
'ಕೋಟಿಹೊರಿಸು',
'ಕೋಟೆಕೊತ್ತಳ',
'ಕೋಟೆಗಾಳಗ',
'ಕೋಟೆಗಾಳೆಗ',
'ಕೋಟ್ಯಧೀಶ್ವರ',
'ಕೋಟ್ಯನುಕೋಟಿ',
'ಕೋಡಂಗಿತನ',
'ಕೋಡಗಕಟ್ಟು',
'ಕೋಡಗಕೆಮ್ಮು',
'ಕೋಡಗಗಟ್ಟು',
'ಕೋಡಗವಾಡು',
'ಕೋಡಮುರಕ',
'ಕೋಡಮುರಕಿ',
'ಕೋಡಮುರುಕ',
'ಕೋಡವಣಿಗ',
'ಕೋಡಿಕೆಡಪು',
'ಕೋಡಿಗಾಣಿಸು',
'ಕೋಡಿಹರಿಸು',
'ಕೋಡುಗದಿರ',
'ಕೋಡುಗಮೊಗ',
'ಕೋಡುಗವಣೆ',
'ಕೋಣದಬಳ್ಳಿ',
'ಕೋಣನಕೊಂಬು',
'ಕೋಣಪದಿಕ್ಕು',
'ಕೋಣೆವುಗಿಸು',
'ಕೋದಂಡಕಾಂಡ',
'ಕೋದಂಡಪಾರ್ಥ',
'ಕೋದಂಡರುದ್ರ',
'ಕೋದಂಡಾಗಮ',
'ಕೋನನಿಮಿಷ',
'ಕೋನಮಾಪಕ',
'ಕೋನಮಾಪನ',
'ಕೋನೀಯ ವೇಗ',
'ಕೋನೀಯ ವ್ಯಾಸ',
'ಕೋಪನಿರ್ಭರ',
'ಕೋಪಪಾಟಳ',
'ಕೋಪವದನ',
'ಕೋಪವಿಕಾರ',
'ಕೋಪಸ್ಫುರಣ',
'ಕೋಪಾವಲೇಪ',
'ಕೋಮಟಿಗಿತ್ತಿ',
'ಕೋಮಟಿತನ',
'ಕೋಮಲಕಾಯ',
'ಕೋಮಲಕಾಯೆ',
'ಕೋಮಲದೇಹ',
'ಕೋಮಲವಡೆ',
'ಕೋಮಲವೃತ್ತಿ',
'ಕೋಮಲವೆಱು',
'ಕೋಮಳತಳ',
'ಕೋಮಳಾಳಾಪ',
'ಕೋರಕಗ್ರಂಥಿ',
'ಕೋರದೂಷಕ',
'ಕೋರಾನ್ನಭಿಕ್ಷೆ',
'ಕೋರಿಕಾಗದ',
'ಕೋರಿಜವ್ವನ',
'ಕೋರಿಹಲಗೆ',
'ಕೋರುಗುತ್ತಗೆ',
'ಕೋರುಹುಡುಗ',
'ಕೋರೆಕಾಗದ',
'ಕೋರೆದಿಂಗಳ್',
'ಕೋರೆರುಮಾಲು',
'ಕೋಲಕಾರಳೆ',
'ಕೋಲಮಾಡಿಸು',
'ಕೋಲಮುರಕ',
'ಕೋಲಸಂಪಿಗೆ',
'ಕೋಲಾರತಟ್ಟು',
'ಕೋಲಾವತಾರ',
'ಕೋಲಾಹಲಿಸು',
'ಕೋಲುಕುಟುಕ',
'ಕೋಲುಕುಟುಮ',
'ಕೋಲುಕುದುರೆ',
'ಕೋಲುಗುದ್ದಲಿ',
'ಕೋಲುತಗಸಿ',
'ಕೋಲುನೆಗೆತ',
'ಕೋಲುಮಲ್ಲಿಗೆ',
'ಕೋಲುಮುರಕ',
'ಕೋಲುಮುರುಕ',
'ಕೋಲುಮುಲುಕ',
'ಕೋಲುವರಸೆ',
'ಕೋಲುಸಂಪಿಗೆ',
'ಕೋಲುಳಿಯಂಬು',
'ಕೋಲೆಬಸವ',
'ಕೋವಿಗೊಳವಿ',
'ಕೋಶಪಂಚಕ',
'ಕೋಶವಿಭೂತಿ',
'ಕೋಸುಗುಲಾಬಿ',
'ಕೋಳಾಹಲಿತ',
'ಕೋಳಾಹಳಿಕೆ',
'ಕೋಳಾಹಳಿಸು',
'ಕೋಳಿಕಾಳಗ',
'ಕೋಳಿಕುಟುಮ',
'ಕೋಳಿಕೊಟ್ಟುಗ',
'ಕೋಳಿಗಾಳೆಗ',
'ಕೋಳಿಜಗಳ',
'ಕೋಳಿಹಲಿಗೆ',
'ಕೋಳ್ಗುದಿಗುದಿ',
'ಕೋಳ್ಗುದಿಗೊಳ್',
'ಕೋಳ್ಮಿಗವೇಂಟೆ',
'ಕೋೞಿಕುಟುಂಬ',
'ಕೋೞಿಕುಟುಮ',
'ಕೋೞ್ಮೊಗಗೊಳ್',
'ಕೌಂಸಲದಾರ',
'ಕೌಟಿಲ್ಯವೃತ್ತಿ',
'ಕೌತುಕಂಗೊಳ್',
'ಕೌತುಕಂದಾಳ್',
'ಕೌತುಕಂಬಡು',
'ಕೌತುಕಪಡು',
'ಕೌತುಕಯುಕ್ತಿ',
'ಕೌತುಕಸೃಷ್ಟಿ',
'ಕೌಪೀನಧಾರಿ',
'ಕೌಬಿನಹುಲ್ಲು',
'ಕೌಮಾರತಂತ್ರ',
'ಕೌಮಾರದೆಸೆ',
'ಕೌಮಾರಭೃತ್ಯ',
'ಕೌಮುದೀವರ್ಣ',
'ಕೌವರರೋಷ',
'ಕೌರುವಾಸನೆ',
'ಕೌರ್ಮಪುರಾಣ',
'ಕೌಲಟಿನೇಯ',
'ಕೌಲುಕರಾರು',
'ಕೌವರೆಗೊಳ್',
'ಕೌಶಲಂದೋಱು',
'ಕೌಶಲಮಯ',
'ಕೌಶಿಕಗುಪ್ತ',
'ಕೌಶಿಕದರ್ಭೆ',
'ಕೌಶಿಕಬ್ರಜ',
'ಕೌಸುಂಭರಾಗ',
'ಕೌಸ್ತುಭದ್ಯುತಿ',
'ಕೌಸ್ತುಭಮಣಿ',
'ಕೌಸ್ತುಭವಕ್ಷ',
'ಕೌಳಿಕಂದಾಯ',
'ಕ್ರಕರವಕ್ಕಿ',
'ಕ್ರತುಜೀವನ',
'ಕ್ರತುಪುರುಷ',
'ಕ್ರತುವಿನಾಶ',
'ಕ್ರಮಗಣನ',
'ಕ್ರಮಗಣನೆ',
'ಕ್ರಮಗೆಡಿಸು',
'ಕ್ರಮಗೊಳಿಸು',
'ಕ್ರಮಪಡಿಸು',
'ಕ್ರಮಬದ್ಧತೆ',
'ಕ್ರಮವಿಕಾಸ',
'ಕ್ರಮವಿಕ್ಷೇಪ',
'ಕ್ರಮವಿಹಿತ',
'ಕ್ರಮಸಂಧಾನ',
'ಕ್ರಮಾಲಂಕಾರ',
'ಕ್ರಯವಿಕ್ರಯ',
'ಕ್ರಾಂತದರ್ಶನ',
'ಕ್ರಾಂತದರ್ಶಿತ್ವ',
'ಕ್ರಾಂತಿಕಾರಕ',
'ಕ್ರಿಮಿನಾಶಕ',
'ಕ್ರಿಮಿನಾಶಿನಿ',
'ಕ್ರಿಮಿಶಾಸ್ತ್ರಜ್ಞ',
'ಕ್ರಿಮಿಶಾಸ್ತ್ರಜ್ಞೆ',
'ಕ್ರಿಮಿಹರಣ',
'ಕ್ರಿಯಾಕಾರಕ',
'ಕ್ರಿಯಾಕುಶಲ',
'ಕ್ರಿಯಾಕುಶಲೆ',
'ಕ್ರಿಯಾಖಂಡನ',
'ಕ್ರಿಯಾಗೋಪಕ',
'ಕ್ರಿಯಾತ್ಮಕತೆ',
'ಕ್ರಿಯಾನಿವೃತ್ತಿ',
'ಕ್ರಿಯಾಪೂರ್ವಕ',
'ಕ್ರಿಯಾಪ್ರಕೃತಿ',
'ಕ್ರಿಯಾಭಸಿತ',
'ಕ್ರಿಯಾಮಥನ',
'ಕ್ರಿಯಾವರ್ತನೆ',
'ಕ್ರಿಯಾವಾಚಕ',
'ಕ್ರಿಯಾವಾಹಿನಿ',
'ಕ್ರಿಯಾವಿಭಕ್ತಿ',
'ಕ್ರಿಯಾವಿಶೇಷ',
'ಕ್ರಿಯಾಶೀಲತೆ',
'ಕ್ರಿಯಾಶೂನ್ಯತೆ',
'ಕ್ರಿಯಾಸಕ್ತತೆ',
'ಕ್ರಿಯಾಸಮಾಸ',
'ಕ್ರಿಯಾಸಮಿತಿ',
'ಕ್ರಿಯಾಸಾಧಕ',
'ಕ್ರಿಯೆಬಟ್ಟಲು',
'ಕ್ರೀಡನಶೀಲ',
'ಕ್ರೀಡನಶೀಲೆ',
'ಕ್ರೀಡಾಗೋಳಕ',
'ಕ್ರೀಡಾಪರ್ವತ',
'ಕ್ರೀಡಾವಿನೋದ',
'ಕ್ರೀಡಾಶೀಲತೆ',
'ಕ್ರೀಡಾಶೀಲತ್ವ',
'ಕ್ರೀಡಾಸಕ್ತತೆ',
'ಕ್ರುಕರವಾಯು',
'ಕ್ರೂರದರ್ಶಿನಿ',
'ಕ್ರೂಶಾರೋಹಣ',
'ಕ್ರೋಡೀಕರಣ',
'ಕ್ರೋಡೀಕರಿಸು',
'ಕ್ರೋಧಚರ್ವಿತ',
'ಕ್ರೋಧಾತಿರೇಕ',
'ಕ್ರೋಧೋನ್ಮತ್ತತೆ',
'ಕ್ರೌಂಚದಾರಣ',
'ಕ್ಲೇಶಂಗೊಳಿಸು',
'ಕ್ಲೇಶಪಂಚಕ',
'ಕ್ಲೇಶರಹಿತ',
'ಕ್ಲೇಶರಹಿತೆ',
'ಕ್ಲೇಶಸಹಿಷ್ಣು',
'ಕ್ಲೇಶಾನುಬಂಧ',
'ಕ್ವಚಿತ್ಪ್ರಯೋಗ',
'ಕ್ವಣತ್ಕಂಕಣ',
'ಕ್ವಥನಬಿಂದು',
'ಕ್ವಯನಾಮರ',
'ಕ್ಷಣಗಭಸ್ತಿ',
'ಕ್ಷಣದಾಚರ',
'ಕ್ಷಣದಾಟನ',
'ಕ್ಷಣಪದವಿ',
'ಕ್ಷಣಭಂಗುರ',
'ಕ್ಷಣವಿಳಂಬ',
'ಕ್ಷಣವೀಕ್ಷಣ',
'ಕ್ಷಣಿಕಗುಣ',
'ಕ್ಷತವಿಕ್ಷತ',
'ಕ್ಷತ್ರತಾಮಸ',
'ಕ್ಷತ್ರತಿಮಿರ',
'ಕ್ಷತ್ರಿಯಕುಲ',
'ಕ್ಷತ್ರಿಯಜಾತಿ',
'ಕ್ಷತ್ರಿಯಾತ್ಮಜ',
'ಕ್ಷತ್ರಿಯಾತ್ಮಜೆ',
'ಕ್ಷತ್ರಿಯಾಧಮ',
'ಕ್ಷಪಕಶ್ರೇಣಿ',
'ಕ್ಷಪಣಕತ್ವ',
'ಕ್ಷಪಿತಕಾಯ',
'ಕ್ಷಮಾಪ್ರಾರ್ಥನೆ',
'ಕ್ಷಮಾಯಾಚನೆ',
'ಕ್ಷಮಾವಲ್ಲಭ',
'ಕ್ಷಮಾಶೀಲತೆ',
'ಕ್ಷಯರೋಗಾಣು',
'ಕ್ಷಯರೋಗಿಣಿ',
'ಕ್ಷಯವೃಜಿನ',
'ಕ್ಷಯೋಪಶಮ',
'ಕ್ಷರತ್ಕರಟ',
'ಕ್ಷಾತ್ರತೇಜಸ್ಸು',
'ಕ್ಷಾಯಿಕದೃಷ್ಟಿ',
'ಕ್ಷಾರದಶಕ',
'ಕ್ಷಾರಪಾನೀಯ',
'ಕ್ಷಾರಮೃತ್ತಿಕೆ',
'ಕ್ಷಿತಿಧರೇಂದ್ರ',
'ಕ್ಷಿತಿನಾಥತ್ವ',
'ಕ್ಷಿತಿಪಾಲಕ',
'ಕ್ಷಿತಿರಕ್ಷಕ',
'ಕ್ಷಿತಿರಮಣ',
'ಕ್ಷಿತಿವಲಯ',
'ಕ್ಷಿಪ್ರಕಾರಿತೆ',
'ಕ್ಷೀಣಕಷಾಯ',
'ಕ್ಷೀಣಶಕ್ತಿಕ',
'ಕ್ಷೀರಕಾಕೋಲಿ',
'ಕ್ಷೀರಕಾಕೋಳಿ',
'ಕ್ಷೀರಗೌರತ್ವ',
'ಕ್ಷೀರಪಯೋಧಿ',
'ಕ್ಷೀರಪ್ರವಾಹ',
'ಕ್ಷೀರಪ್ರವೃತ್ತಿ',
'ಕ್ಷೀರವಾರಾಶಿ',
'ಕ್ಷೀರವಾರಿಧಿ',
'ಕ್ಷೀರವಿದಾರಿ',
'ಕ್ಷೀರಶರ್ಕರ',
'ಕ್ಷೀರಸಮುದ್ರ',
'ಕ್ಷೀರಸಾಗರ',
'ಕ್ಷೀರಸ್ಫಟಿಕ',
'ಕ್ಷೀರಸ್ರಾವಿತ್ವ',
'ಕ್ಷೀರಾಭಿಷೇಕ',
'ಕ್ಷೀರೋದಭವ',
'ಕ್ಷುತಕರಣ',
'ಕ್ಷುತ್ಪಿಪಾಸಕ',
'ಕ್ಷುದ್ರಘಂಟಿಕೆ',
'ಕ್ಷುದ್ರತಂಡುಲ',
'ಕ್ಷುದ್ರದವನ',
'ಕ್ಷುದ್ರದೇವತೆ',
'ಕ್ಷುದ್ರನಾಸಿಕ',
'ಕ್ಷುದ್ರಪಕ್ಷಿಕ',
'ಕ್ಷುದ್ರಪ್ರಬಂಧ',
'ಕ್ಷುದ್ರವಾರ್ತಾಕಿ',
'ಕ್ಷುಧಾಪೋಷಕ',
'ಕ್ಷುಭಿತಚಿತ್ತ',
'ಕ್ಷುಭಿತಚಿತ್ತೆ',
'ಕ್ಷುಲ್ಲಕತನ',
'ಕ್ಷುಲ್ಲಕದ್ವಾರ',
'ಕ್ಷುಲ್ಲಕಭಾಷೆ',
'ಕ್ಷೇತ್ರಗಣಿತ',
'ಕ್ಷೇತ್ರಪಾಲಕ',
'ಕ್ಷೇತ್ರಪ್ರಾಧಾನ್ಯ',
'ಕ್ಷೇತ್ರಮಾಪನ',
'ಕ್ಷೇತ್ರರಕ್ಷಕ',
'ಕ್ಷೇತ್ರರಕ್ಷಣೆ',
'ಕ್ಷೇತ್ರಸಂಸ್ಕಾರ',
'ಕ್ಷೇತ್ರಾಧಿಕಾರ',
'ಕ್ಷೇಮಕರಣ',
'ಕ್ಷೇಮಗೊಳಿಸು',
'ಕ್ಷೋಣೀವಿಲಾಸ',
'ಕ್ಷೌದ್ರಪಟಲ',
'ಕ್ಷೌದ್ರಪಟಳ',
'ಖಂಡಗ್ರಹಣ',
'ಖಂಡಣಿಹಣ',
'ಖಂಡಣೆಗೆಯ್',
'ಖಂಡಣೆಹಣ',
'ಖಂಡನೀಯತೆ',
'ಖಂಡಪರಶು',
'ಖಂಡಮಂಡಲ',
'ಖಂಡಲಡ್ಡುಗೆ',
'ಖಂಡವಿಸ್ತಾರ',
'ಖಂಡಶರ್ಕರೆ',
'ಖಂಡಸಕ್ಕರೆ',
'ಖಂಡಸ್ಫುಟಿತ',
'ಖಂಡಿತಕಾಲ',
'ಖಂಡಿತಮಯ',
'ಖಂಡಿತಮಾಡು',
'ಖಂಡಿತರೂಪ',
'ಖಂಡಿತವಾದಿ',
'ಖಂಡುಗವೆದೆ',
'ಖಂಡೆಯಕಾರ',
'ಖಂಡೆಯಕಾಱ',
'ಖಂಡೆಯವೆಱು',
'ಖಂಡೇಂದುಧರ',
'ಖಂಡೇಂದುಮೌಳಿ',
'ಖಂಬೀರತನ',
'ಖಗಗಮನ',
'ಖಗಯುವತಿ',
'ಖಗರಾಜಾಸ್ತ್ರ',
'ಖಗರಾಜೇಂದ್ರ',
'ಖಗವಲ್ಲಭ',
'ಖಗಾಂಡಶಾಸ್ತ್ರ',
'ಖಗಾಧೀಶ್ವರ',
'ಖಗೋಳಜ್ಞಾನ',
'ಖಗೋಳಶಾಸ್ತ್ರ',
'ಖಚರಪತಿ',
'ಖಚರಪ್ಲುತ',
'ಖಚರಾಂಗನೆ',
'ಖಜಾನೆದಾರ',
'ಖಜಾನೆದ್ರವ್ಯ',
'ಖಜೀನದಾರಿ',
'ಖಜ್ಜೂರಿದಾರ',
'ಖಟ್ವಾಂಗಧರ',
'ಖಡಕತಿರಾ',
'ಖಡಖಡನೆ',
'ಖಡಖಡಾಟ',
'ಖಡಾಖಂಡಿತ',
'ಖಡುಗಧಾರೆ',
'ಖಡುಗಮಿಗ',
'ಖಡ್ಗಧೇನುಕ',
'ಖಡ್ಗಪಿಧಾನ',
'ಖಡ್ಗಬಿನ್ನಾಣ',
'ಖಡ್ಗಿಧೇನುಕ',
'ಖದಖದಿಸು',
'ಖದಿರರಾಗ',
'ಖದೀಮತನ',
'ಖದ್ದರಧಾರಿ',
'ಖದ್ಯೋತರಾಸಿ',
'ಖನನತಂತ್ರ',
'ಖನಿಜಜಲ',
'ಖನಿಜತೈಲ',
'ಖನಿಜಶಾಸ್ತ್ರ',
'ಖನೇಸುಮಾರಿ',
'ಖಬರದಾರ್',
'ಖಬರದಾರ',
'ಖಬರದಾರಿ',
'ಖಬರುದಾರಿ',
'ಖಬ್ಬರದಾರಿ',
'ಖಮಧ್ಯರೇಖೆ',
'ಖರಕಿರಣ',
'ಖರಮಂಜರಿ',
'ಖರಮರೀಚಿ',
'ಖರವಿಷಾಣ',
'ಖರಾರುವಾಕ್ಕು',
'ಖರೀದಿಪತ್ರ',
'ಖರೆಯತನ',
'ಖರ್ಪರಪುಷ್ಪ',
'ಖರ್ಪರಿತುತ್ಥ',
'ಖರ್ವಗೊಳಿಸು',
'ಖಲಕಪಟ',
'ಖಲಕರಣ',
'ಖಲನಾಯಕ',
'ಖಲನಾಯಕಿ',
'ಖವಖವಿಸು',
'ಖಸ್ತುವರಾತ',
'ಖಳಕಳಂಕ',
'ಖಳತಿಲಕ',
'ಖಳನಾಯಕ',
'ಖಳನಾಯಕಿ',
'ಖಳನೃಪಾಲ',
'ಖಳವಿನೀತ',
'ಖಳಸಂಯಮ',
'ಖಳಸಂಸೃತಿ',
'ಖಳಾಪಸ್ಮಾರ',
'ಖಾಂಡವವನ',
'ಖಾತರ್‍ಜಮಾ',
'ಖಾತರ್‍ಜಮೆ',
'ಖಾತರಿಪತ್ರ',
'ಖಾತಿರಜಮೆ',
'ಖಾತೆಪುಸ್ತಕ',
'ಖಾತ್ರಿಲಾಯಖು',
'ಖಾತ್ರಿಲಾಹಿಕ',
'ಖಾನೆಸುಮಾರಿ',
'ಖಾನೇಶುಮಾರಿ',
'ಖಾನೇಷುಮಾರಿ',
'ಖಾನೇಸುಮಾರಿ',
'ಖಾಯಂಗುತ್ತಿಗೆ',
'ಖಾರಗೆಣಸು',
'ಖಾರದಹುಲ್ಲು',
'ಖಾಸ್‍ಕಛೇರಿ',
'ಖಾಸ್‍ತಬೇಲೆ',
'ಖಾಸಕುದುರೆ',
'ಖಾಸಗಿಕ್ಷೇತ್ರ',
'ಖಾಸಗಿಪತ್ರ',
'ಖಾಸಗಿಲೆಕ್ಕ',
'ಖಾಸತಬೇಲಿ',
'ಖಾಸತೈನಾತಿ',
'ಖಾಸಬೊಕ್ಕಸ',
'ಖಾಸಮೊಹರು',
'ಖಾಸಸವಾರಿ',
'ಖಾಸಾತಬೇಲಿ',
'ಖಾಸಾಬೊಕ್ಕಸ',
'ಖಾಸಾಮೊಹರು',
'ಖಿಲಖಿಲನೆ',
'ಖುದ್ದರಗೇಡಿ',
'ಖುರಖಂಡಿತ',
'ಖುರಮಣಿಕೆ',
'ಖುಲಾಸವಾರ',
'ಖುಲಾಸೇವಾರ',
'ಖುಷಿಪಡಿಸು',
'ಖೇಚರಚಕ್ರಿ',
'ಖೇಚರರಾಜ',
'ಖೇಚರಿಮುದ್ರೆ',
'ಖೇಚರೀಮುದ್ರೆ',
'ಖೇಲನರಂಗ',
'ಖೇಲನಸ್ಥಳ',
'ಖೇಲನೌದಾರ್ಯ',
'ಖೈರಗುಳಿಗೆ',
'ಖೊಟ್ಟಿನಶೀಬ',
'ಖೊಟ್ಟಿನಸೀಬ',
'ಖೋಡಿಗಾಣಿಸು',
'ಖೋತಾಸೂಚನೆ',
'ಖ್ಯಾತಗರ್ಹಣ',
'ಗಂಗಗರಿಕೆ',
'ಗಂಗಡಿಕಾರ',
'ಗಂಗಡಿಕಾಱ',
'ಗಂಗನಮರ',
'ಗಂಗವಾಲುಕ',
'ಗಂಗಸರಾಯಿ',
'ಗಂಗಸಾಗರ',
'ಗಂಗಾಕಾವೇರಿ',
'ಗಂಗಾಜಮುನ',
'ಗಂಗಾಭವಾನಿ',
'ಗಂಗಾವತಂಸ',
'ಗಂಗಾವಾಳುಕ',
'ಗಂಗಾಸಾಗರ',
'ಗಂಗೆತೊಗಲು',
'ಗಂಗೆದೊಗಲ್',
'ಗಂಗೆದೊಗಲು',
'ಗಂಗೆದೊವಲ್',
'ಗಂಗೆದೊವಲು',
'ಗಂಗೆದೋವಲ್',
'ಗಂಗೆಮತಸ್ಥ',
'ಗಂಗೆವಾಳುಕ',
'ಗಂಜಿಗರಿಕೆ',
'ಗಂಜಿಗುಡಿಹಿ',
'ಗಂಜಿಗುಡುಕ',
'ಗಂಜಿಪರಾಕು',
'ಗಂಜಿಬದನೆ',
'ಗಂಜಿಯರಿವೆ',
'ಗಂಟರಿಷಣ',
'ಗಂಟಲುಕಟ್ಟು',
'ಗಂಟಲುಕೊಯ್ಕ',
'ಗಂಟಲುನಾಳ',
'ಗಂಟಲುನ್ಯಾಯ',
'ಗಂಟಲುಮಣಿ',
'ಗಂಟಲುಮಾರಿ',
'ಗಂಟಲುವ್ಯಾಜ್ಯ',
'ಗಂಟಿಚವುಡಿ',
'ಗಂಟುಗದಡಿ',
'ಗಂಟುಪರಂಗಿ',
'ಗಂಟುಬಾರಂಗಿ',
'ಗಂಟುಭಾರಂಗಿ',
'ಗಂಟುಮುಳುಕ',
'ಗಂಟೆಕಚೋರ',
'ಗಂಟೆಕಾಯಕ',
'ಗಂಟೆಬಟ್ಟಲು',
'ಗಂಡಕೇಸರ',
'ಗಂಡಕೊಡಲಿ',
'ಗಂಡಗತ್ತರಿ',
'ಗಂಡಗರಿಗೆ',
'ಗಂಡಗೊಡಲಿ',
'ಗಂಡಜಪ್ಪಲಿ',
'ಗಂಡದುಪ್ಪುಳು',
'ಗಂಡಪೆಂಡರ',
'ಗಂಡಪೆಂಡಾರ',
'ಗಂಡಪೆಂಡೇರ',
'ಗಂಡಭೇರುಂಡ',
'ಗಂಡಮೆಣಸು',
'ಗಂಡರಿಸಿನ',
'ಗಂಡಲಚ್ಚಣ',
'ಗಂಡವರಿಜು',
'ಗಂಡಸುತನ',
'ಗಂಡಸುಮಗ',
'ಗಂಡಾಳುತನ',
'ಗಂಡಿಕಾವಲಿ',
'ಗಂಡುಅಡಕೆ',
'ಗಂಡುಕಕೋರ್ಲ',
'ಗಂಡುಕಾಳಿಂಗ',
'ಗಂಡುಕೆಲಸ',
'ಗಂಡುಕೇಪಳ',
'ಗಂಡುಕೊಡಲಿ',
'ಗಂಡುಗಂಜಲ',
'ಗಂಡುಗತನ',
'ಗಂಡುಗತ್ತರಿ',
'ಗಂಡುಗರಟ',
'ಗಂಡುಗರಣೆ',
'ಗಂಡುಗಿಡಿಸು',
'ಗಂಡುಗುಂಟಣಿ',
'ಗಂಡುಗುರಗಿ',
'ಗಂಡುಗುವರ',
'ಗಂಡುಗೆಡಿಸು',
'ಗಂಡುಗೆದಱು',
'ಗಂಡುಗೆಲಸ',
'ಗಂಡುಗೊಡಲಿ',
'ಗಂಡುತಗಚೆ',
'ಗಂಡುತುರುಚೆ',
'ಗಂಡುತೊತ್ತಿಗ',
'ಗಂಡುದುಱುಚೆ',
'ಗಂಡುದೊತ್ತಾಳ್',
'ಗಂಡುದೊೞ್ತಾಳ್',
'ಗಂಡುನಡಿಗೆ',
'ಗಂಡುನಾಬಳ್ಳಿ',
'ಗಂಡುಬಿಡಾರ',
'ಗಂಡುಬಿದಿರು',
'ಗಂಡುಬಿದುರು',
'ಗಂಡುಮಲ್ಲಿಗೆ',
'ಗಂಡುಮೇಳಿಗ',
'ಗಂಡುವರಿಜ',
'ಗಂಡುವರಿಜು',
'ಗಂಡುಸಂತತಿ',
'ಗಂಡುಸುತನ',
'ಗಂಡೂಷೀಕೃತ',
'ಗಂದವಟಿಗೆ',
'ಗಂದವಟ್ಟಿಗೆ',
'ಗಂದವಡಿಗ',
'ಗಂಧಕಚೋರ',
'ಗಂಧಕತೈಲ',
'ಗಂಧಕಧೃತಿ',
'ಗಂಧಕಾರಿಕೆ',
'ಗಂಧಗರಗೆ',
'ಗಂಧಗರಿಗೆ',
'ಗಂಧಚಾರಿಣಿ',
'ಗಂಧಚೇಳಿಕೆ',
'ಗಂಧದಾಯಕಿ',
'ಗಂಧನಕುಲ',
'ಗಂಧನಾಕುಲಿ',
'ಗಂಧಮಲರ್',
'ಗಂಧಮಾದನ',
'ಗಂಧಮಾರ್ಜಾರ',
'ಗಂಧಮಾರ್ಜಾಲ',
'ಗಂಧಮೂಷಿಕೆ',
'ಗಂಧಮೆಣಸು',
'ಗಂಧರ್ವಗಾನ',
'ಗಂಧರ್ವಗ್ರಹ',
'ಗಂಧರ್ವದೂರ್ವೆ',
'ಗಂಧರ್ವವಿದ್ಯೆ',
'ಗಂಧರ್ವವೇದ',
'ಗಂಧರ್ವಹಸ್ತ',
'ಗಂಧಲಂಪಟ',
'ಗಂಧಲಂಪಟೆ',
'ಗಂಧವಟಿಕೆ',
'ಗಂಧವಟಿಗೆ',
'ಗಂಧವಟ್ಟಲು',
'ಗಂಧವಟ್ಟಿಗೆ',
'ಗಂಧವಡಿಗ',
'ಗಂಧವಣಿಗ',
'ಗಂಧವಾರಣ',
'ಗಂಧಸರಕು',
'ಗಂಧಸಿಂಧುರ',
'ಗಂಧೇಂಗಾಲಕ',
'ಗಂಭೀರಗೆಡು',
'ಗಂಭೀರನಾಟ',
'ಗಂಭೀರವಡೆ',
'ಗಂಭೀರವಾಣಿ',
'ಗಂಭೀರವೇದಿ',
'ಗಗನಕೇಶ',
'ಗಗನಖಂಡ',
'ಗಗನಗಂಗೆ',
'ಗಗನಗಜ',
'ಗಗನಗತಿ',
'ಗಗನಗಾಮಿ',
'ಗಗನಚರ',
'ಗಗನಚರಿ',
'ಗಗನಚಾಪ',
'ಗಗನಚುಂಬಿ',
'ಗಗನತಳ',
'ಗಗನದನಿ',
'ಗಗನಧುನಿ',
'ಗಗನಧ್ವನಿ',
'ಗಗನಮಣಿ',
'ಗಗನಯಾತ್ರಿ',
'ಗಗನಯಾತ್ರ್ರೆ',
'ಗಗನಲತೆ',
'ಗಗನಲಿಪಿ',
'ಗಗನವಟ್ಟೆ',
'ಗಗನಸಖಿ',
'ಗಗನಸ್ಥಳಿ',
'ಗಗನಸ್ಥಾನ',
'ಗಗನಾಂಗಣ',
'ಗಗನಾಂಗಳ',
'ಗಗನಾಂಬರ',
'ಗಗನಾಂಬರೆ',
'ಗಗನಾಧ್ವಗ',
'ಗಗನಾಭೋಗ',
'ಗಗನೇಚರ',
'ಗಗನೇಚರಿ',
'ಗಗನೋಪಲ',
'ಗಗ್ಗರಗೆಜ್ಜೆ',
'ಗಗ್ಗರಗೋಲು',
'ಗಗ್ಗರದನಿ',
'ಗಗ್ಗರವಱೆ',
'ಗಗ್ಗರವಿಡು',
'ಗಗ್ಗರಸ್ವನ',
'ಗಗ್ಗರಿಗೋಲು',
'ಗಗ್ಗರಿಘಂಟೆ',
'ಗಗ್ಘರಗಂಟೆ',
'ಗಚ್ಚಗೊಳಿಸು',
'ಗಜಕಬಳ',
'ಗಜಕರಣ',
'ಗಜಕರ್ಣಿಕೆ',
'ಗಜಕೇಸರ',
'ಗಜಕ್ರೀಡಿತ',
'ಗಜಗಜಿಸು',
'ಗಜಗಮನ',
'ಗಜಗಮನೆ',
'ಗಜಗಲಿಸು',
'ಗಜಗವಳ',
'ಗಜಗಾಮಿನಿ',
'ಗಜಗಿಪ್ಪಲಿ',
'ಗಜಪಂಡಿತ',
'ಗಜಪರೀಕ್ಷೆ',
'ಗಜಪಿಪ್ಪಲಿ',
'ಗಜಬಂಧನಿ',
'ಗಜಬಜಿಕೆ',
'ಗಜಬಜಿಗ',
'ಗಜಬಜಿಸು',
'ಗಜಮಂಡನ',
'ಗಜಮುದುಡು',
'ಗಜಮೌಕ್ತಿಕ',
'ಗಜರಗಳೆ',
'ಗಜರೋಹಕ',
'ಗಜವದನ',
'ಗಜವಿದ್ವಾಂಸ',
'ಗಜವೆಡಂಗ',
'ಗಜವೇಡೆಯ',
'ಗಜವೈಹಾಳಿ',
'ಗಜಸಾಹಣಿ',
'ಗಜಹಿಪ್ಪಲಿ',
'ಗಜಾರೋಹಕ',
'ಗಜಾರೋಹಣ',
'ಗಜಾರೋಹಿಣಿ',
'ಗಜಾಲುಗಾತಿ',
'ಗಜಾಲುಗಾರ',
'ಗಜೋನ್ಮೀಲನ',
'ಗಜ್ಜರೆಗೆಂಪು',
'ಗಟಕರಿಸು',
'ಗಟಗಟನೆ',
'ಗಟಗಟಿಸು',
'ಗಟಗರಿಸು',
'ಗಟುಕರಿಸು',
'ಗಟ್ಟಗರುಕೆ',
'ಗಟ್ಟಣೆಮಣೆ',
'ಗಟ್ಟಿಕೆಲಸ',
'ಗಟ್ಟಿಗತನ',
'ಗಟ್ಟಿಗೊಳಿಸು',
'ಗಟ್ಟಿಮಗುಳ್ಚು',
'ಗಟ್ಟಿಮಗುೞ್ಚು',
'ಗಟ್ಟಿಮುರವು',
'ಗಟ್ಟಿವಳಿತಿ',
'ಗಟ್ಟಿವಾಳಿಕೆ',
'ಗಡಕುಹಾಕು',
'ಗಡಗಡನೆ',
'ಗಡಗಡಿಸು',
'ಗಡಗುಟಿಸು',
'ಗಡಚಿಕ್ಕಿಸು',
'ಗಡಚುತನ',
'ಗಡಣಂಗೊಳ್',
'ಗಡಣಂಬಡೆ',
'ಗಡಣಗೊಳ್',
'ಗಡಣಗೊಳ್ಳು',
'ಗಡಣೆಗೊಳ್',
'ಗಡಬಡಿಕೆ',
'ಗಡಬಡಿಸು',
'ಗಡಮಂಚಿಕೆ',
'ಗಡರಗಪ್ಪೆ',
'ಗಡವತನ',
'ಗಡಸುಗಾತಿ',
'ಗಡಸುಗಾರ',
'ಗಡಸುಗಾಱ',
'ಗಡಸುತನ',
'ಗಡಿಕೊಳಗ',
'ಗಡಿಗುತ್ತಿಗೆ',
'ಗಡಿಗೆಗುಮ್ಮ',
'ಗಡಿಗೆಭಾವಿ',
'ಗಡಿಗೆಮೋಡ',
'ಗಡಿಮನ್ನೆಯ',
'ಗಡಿವಿವಾದ',
'ಗಡಿಸರಕು',
'ಗಡಿಸುವಲೆ',
'ಗಡುಚುನೀರು',
'ಗಡುಸುಗಾರ್ತಿ',
'ಗಡುಸುಗಾಱ',
'ಗಡುಸುತನ',
'ಗಡುಸುನೀರು',
'ಗಡೆಬಡಿಗ',
'ಗಡ್ಡೆಕಟ್ಟಿಸು',
'ಗಡ್ಡೆಮೊಸರ್',
'ಗಡ್ಡೆಮೊಸರು',
'ಗಡ್ಡೆಹಾಗಲ',
'ಗಣಕಯಂತ್ರ',
'ಗಣಗಣಿಸು',
'ಗಣಗ್ರಹಣ',
'ಗಣದೀಪಕ',
'ಗಣದೇವತೆ',
'ಗಣನಯಂತ್ರ',
'ಗಣನಶಾಸ್ತ್ರ',
'ಗಣನಸಂಖ್ಯೆ',
'ಗಣನಾತೀತ',
'ಗಣನಾಯಕ',
'ಗಣನಿಯಮ',
'ಗಣನೀಯತೆ',
'ಗಣನೆಗೆಡು',
'ಗಣನೆವಡೆ',
'ಗಣಪದವಿ',
'ಗಣಪ್ರವರ',
'ಗಣಸಿದ್ಧಾಂತ',
'ಗಣಾಡಂಬರ',
'ಗಣಾಧಿನಾಥ',
'ಗಣಾರಾಧನೆ',
'ಗಣಿಕಾಂಗನೆ',
'ಗಣಿಕಾಜನ',
'ಗಣಿಕಾರಿಕೆ',
'ಗಣಿಕಾವಾಟ',
'ಗಣಿಕಾವಾಟಿ',
'ಗಣಿಕೆಗಣ್ಣು',
'ಗಣಿಕೆಹುಣ್ಣು',
'ಗಣಿಗಾರಿಕೆ',
'ಗಣಿತತರ್ಕ',
'ಗಣಿತವಂತ',
'ಗಣಿತಶಾಸ್ತ್ರ',
'ಗಣಿದಂಗೆಯ್',
'ಗಣಿದಂಗೊಳ್',
'ಗಣೆಯೋದಿಸು',
'ಗಣೇಶನಹೂ',
'ಗತಕವೆಟ್ಟು',
'ಗತಜೀವಿತ',
'ಗತಲೋಚನ',
'ಗತವೈಭವ',
'ಗತಾಯುಸ್ಥಿತಿ',
'ಗತಿಕಾಣಿಸು',
'ಗತಿಕೆಡಿಸು',
'ಗತಿಗಾಣಿಸು',
'ಗತಿಗಿಡಿಸು',
'ಗತಿಗೆಡಿಸು',
'ಗತಿಗೇಡಿಗ',
'ಗತಿಗೊಳಿಸು',
'ಗತಿಚೈತನ್ಯ',
'ಗತಿಮಂಡಲ',
'ಗತಿಲಾಘವ',
'ಗತಿವಿಜ್ಞಾನ',
'ಗತಿವಿಭ್ರಮ',
'ಗತಿವಿಹೀನ',
'ಗತಿವಿಹೀನೆ',
'ಗತಿಶೀಲತೆ',
'ಗತಿಶೂನ್ಯತೆ',
'ಗತ್ತುಕಾರಿಕೆ',
'ಗತ್ತುಗಾರಿಕೆ',
'ಗತ್ಯುಪಗ್ರಹ',
'ಗದಗದನೆ',
'ಗದಗದಿತ',
'ಗದಗದಿಸು',
'ಗದಗಮಿಸು',
'ಗದಗಾಪಿಸು',
'ಗದಬದಿಸು',
'ಗದರುದನಿ',
'ಗದುಗದಿಕೆ',
'ಗದುಗದಿತ',
'ಗದುಗದಿಸು',
'ಗದುಗದುಕೆ',
'ಗದೆಗುದುಗೆ',
'ಗದ್ಗದಸ್ವನ',
'ಗದ್ಗದಸ್ವರ',
'ಗದ್ದಿಗೆಕಲ್ಲು',
'ಗದ್ದುಗೆಕಲ್ಲು',
'ಗದ್ದುಗೆಗೊಳ್',
'ಗದ್ದೆಗೊರವ',
'ಗದ್ದೆತೆವರು',
'ಗದ್ದೆಬಯಲು',
'ಗದ್ದೆಬೆದ್ದಲು',
'ಗದ್ದೆವರಗ',
'ಗನಿಗಾರಿಕೆ',
'ಗನ್ನಗತಕ',
'ಗನ್ನಗದಕ',
'ಗನ್ನಗದಿಕು',
'ಗನ್ನಗದುಕು',
'ಗನ್ನಘಾತಕ',
'ಗನ್ನಘಾತಕಿ',
'ಗನ್ನನಿಟ್ಟುರ',
'ಗಪಗಪನೆ',
'ಗಬಗಬನೆ',
'ಗಬ್ಬವಿಕ್ಕಿಸು',
'ಗಬ್ಬುಜವ್ವನೆ',
'ಗಬ್ಬುತಿಗಣೆ',
'ಗಬ್ಬುವಾಸನೆ',
'ಗಬ್ಬುಶಾವಂತಿ',
'ಗಬ್ಬುಳಗಂದಿ',
'ಗಭಸ್ತಿಕರ',
'ಗಭಸ್ತಿಮಾಲಿ',
'ಗಭೀರಗುಣ',
'ಗಭೀರಧ್ವಾನ',
'ಗಭೀರವೃತ್ತಿ',
'ಗಮಕಕಲೆ',
'ಗಮಕರಿಸು',
'ಗಮಕವೆಱು',
'ಗಮಗಮಿಸು',
'ಗಮನಗೇಡಿ',
'ಗಮನಶ್ರಮ',
'ಗಮನಾರ್ಹತೆ',
'ಗಮನಾಲಸ',
'ಗಮನಾಲಸೆ',
'ಗಯಾಳವಿದ್ಯ',
'ಗಯ್ಯಾಳಿತನ',
'ಗರಕರಣ',
'ಗರಗತಿಗ',
'ಗರಗರನೆ',
'ಗರಗರಿಕೆ',
'ಗರಗರಿಸು',
'ಗರಗುವಡೆ',
'ಗರಗೊಬ್ಬಟ್ಟು',
'ಗರಡಬೇವು',
'ಗರಡಿಮನೆ',
'ಗರಡಿಯಾಳು',
'ಗರಣೆಗಟ್ಟು',
'ಗರಣೆಗೊಳ್',
'ಗರತಿತನ',
'ಗರತಿಪ್ಪಾಳೆ',
'ಗರತಿಹಾಡು',
'ಗರಲಗ್ರೀವ',
'ಗರಲಧರ',
'ಗರಲಪೂರ',
'ಗರಲಭುಕ್ಕು',
'ಗರಲಾಶನ',
'ಗರವಟಿಗ',
'ಗರವಟಿಗೆ',
'ಗರವಟಿಸು',
'ಗರವಟ್ಟಿಗೆ',
'ಗರವರಿಸು',
'ಗರವೊಟ್ಟಿಗೆ',
'ಗರಹರಣ',
'ಗರಳಗ್ರೀವ',
'ಗರಳಧರ',
'ಗರಳಪೂರ',
'ಗರಳಪ್ರಾಣಿ',
'ಗರಳಭುಕ್ಕು',
'ಗರಳಾಶನ',
'ಗರಿಗಣ್ವಕ್ಕಿ',
'ಗರಿಗರಿಲ್',
'ಗರಿಗೆದರ್',
'ಗರಿಗೆದರು',
'ಗರಿಮಾವಾಪ್ತಿ',
'ಗರಿಯವರೆ',
'ಗರಿಷ್ಠಬಿಂದು',
'ಗರುಡಕಂಭ',
'ಗರುಡಕಾರೆ',
'ಗರುಡಗಂಬ',
'ಗರುಡಗಂಭ',
'ಗರುಡಗಣ್ಣು',
'ಗರುಡಗಾಮಿ',
'ಗರುಡದೃಷ್ಟಿ',
'ಗರುಡಧ್ವಜ',
'ಗರುಡಧ್ವನಿ',
'ಗರುಡಪಕ್ಷ',
'ಗರುಡಪಚ್ಚೆ',
'ಗರುಡಪಾಳಿ',
'ಗರುಡಪೀಠ',
'ಗರುಡಪ್ಲುತ',
'ಗರುಡಫಲ',
'ಗರುಡಬಾಣ',
'ಗರುಡಬೇವು',
'ಗರುಡಮಂತ್ರ',
'ಗರುಡಮಚ್ಚೆ',
'ಗರುಡಮಣಿ',
'ಗರುಡಮುದ್ರೆ',
'ಗರುಡಮೂಗು',
'ಗರುಡರಥ',
'ಗರುಡರೇಖೆ',
'ಗರುಡವಾಯು',
'ಗರುಡವೇಗ',
'ಗರುಡವ್ಯೂಹ',
'ಗರುಡಾಗಮ',
'ಗರುಡಾಸನ',
'ಗರುಡಿಕಾರ',
'ಗರುಡಿಕಾಱ',
'ಗರುಡಿಗೆಯ್ತ',
'ಗರುಡಿಗೆಯ್ಮೆ',
'ಗರುಡಿಮನೆ',
'ಗರುಡಿಶ್ರಮ',
'ಗರುಡಿಸಾಮು',
'ಗರುಡೋದ್ಗಾರ',
'ಗರುತ್ಮಶರ',
'ಗರುವತನ',
'ಗರ್ಗರಿಗೋಲು',
'ಗರ್ಜನೆಗುಡು',
'ಗರ್ನಾಲುಬಿಡು',
'ಗರ್ಬಂಗೆಡಿಸು',
'ಗರ್ಭಕರಗು',
'ಗರ್ಭಗಳಿತ',
'ಗರ್ಭದೋಹಳ',
'ಗರ್ಭಧಾರಣ',
'ಗರ್ಭಧಾರಣೆ',
'ಗರ್ಭನಾಶನ',
'ಗರ್ಭನಿರೋಧ',
'ಗರ್ಭನಿವಾಸ',
'ಗರ್ಭಪತನ',
'ಗರ್ಭಮೋಚನ',
'ಗರ್ಭವಿಜ್ಞಾನ',
'ಗರ್ಭವಿನ್ಯಾಸ',
'ಗರ್ಭವಿಭ್ರಮ',
'ಗರ್ಭವೇಷ್ಟನ',
'ಗರ್ಭಶಯನ',
'ಗರ್ಭಶೋಧನೆ',
'ಗರ್ಭಶ್ರೀಮಂತ',
'ಗರ್ಭಸಂಸ್ಕಾರ',
'ಗರ್ಭಸ್ಖಲನ',
'ಗರ್ಭಸ್ತಂಭನ',
'ಗರ್ಭಸ್ರಾವಕ',
'ಗರ್ಭಾಡಳಿತ',
'ಗರ್ಭಾವತಾರ',
'ಗರ್ಭೀಕರಿಸು',
'ಗರ್ವವಿನಾಶಿ',
'ಗರ್ವವಿಭ್ರಮ',
'ಗರ್ವವಿಳಿಸು',
'ಗರ್ವಿತತನ',
'ಗರ್ವೀಕರಿಸು',
'ಗಲಕಂಬಲ',
'ಗಲಕುಹುಲ್ಲು',
'ಗಲಗಲನೆ',
'ಗಲಗಲಿಸು',
'ಗಲಗೆಸಗು',
'ಗಲಗ್ರಾಹಿತ್ವ',
'ಗಲಬರಿಕೆ',
'ಗಲಬರಿಸು',
'ಗಲಬಲಿಸು',
'ಗಲಭೆಕಾಱ',
'ಗಲಭೆಕೋರ',
'ಗಲವಿದ್ರಧಿ',
'ಗಲಸಾಧನೆ',
'ಗಲುಗಲನೆ',
'ಗಲ್ಲಗುಗ್ಗುರಿ',
'ಗಲ್ಲಗೈಗೊಳ್',
'ಗಲ್ಲಾಪೆಟ್ಟಿಗೆ',
'ಗಲ್ಲೆಮೊಸರ್',
'ಗವಡುಮಾಡು',
'ಗವನಗೊಂಗೆ',
'ಗವಸಣಿಕೆ',
'ಗವಸಣಿಗೆ',
'ಗವಸಣಿಸು',
'ಗವಳಗಿತ್ತಿ',
'ಗವಳಿಗಿತ್ತಿ',
'ಗವಱೆಗಂಡ',
'ಗವಾಧಿಪತಿ',
'ಗವಿಗತ್ತಲೆ',
'ಗವಿಗೞ್ತಲೆ',
'ಗವುಂಡತನ',
'ಗವುಂಡಬಳ',
'ಗವುಂಡವಳ',
'ಗವುಡಗಿತ್ತಿ',
'ಗವುಡತನ',
'ಗವುಡಸಾನಿ',
'ಗವುಸಣಿಗೆ',
'ಗಸಗಸನೆ',
'ಗಸಣಿಗೆಯ್',
'ಗಸಣಿಗೊಳ್',
'ಗಸಣಿದಾರಿ',
'ಗಸಬಸನೆ',
'ಗಸ್ತಿಪಹರೆ',
'ಗಸ್ತುತಿರುಗು',
'ಗಹಗಹನೆ',
'ಗಹಗಹಿಕೆ',
'ಗಹಗಹಿಸು',
'ಗಹನಪಥ',
'ಗಹನಮಾಡು',
'ಗಹನಮಾರ್ಗ',
'ಗಹನೋದರ',
'ಗಳಕಂಬಳ',
'ಗಳಗರ್ಜನ',
'ಗಳಗರ್ಜನೆ',
'ಗಳಗರ್ತರಿ',
'ಗಳಗಳನೆ',
'ಗಳಗಳಿಸು',
'ಗಳಗುಸನ್ನಿ',
'ಗಳಗ್ರಾಹಿತ್ವ',
'ಗಳದೊಡಿಗೆ',
'ಗಳಪುವಕ್ಕಿ',
'ಗಳಬಳಿಸು',
'ಗಳವತ್ತಿಗೆ',
'ಗಳವೊತ್ತಿಗೆ',
'ಗಳಸುಂಟಗೆ',
'ಗಳಸುಂಟಿಗೆ',
'ಗಳಹತನ',
'ಗಳಿಗೆಮುರಿ',
'ಗಳಿತಕೋಪ',
'ಗಳಿತಧಾರೆ',
'ಗಳಿತಮದ',
'ಗಳಿತಶರ',
'ಗಱಿಗಱಿನೆ',
'ಗಱಿಗೆದಱು',
'ಗಱಿನಾಲಗೆ',
'ಗಱಿಯವರೆ',
'ಗೞಗೞನೆ',
'ಗೞಗೞಿಸು',
'ಗೞೆಗೂಸಾಡು',
'ಗೞೆಯೆತ್ತಿಸು',
'ಗೞೆವಟಿಗ',
'ಗಾಂಡುಗುದಿಗೆ',
'ಗಾಂಧರ್ವವಿದ್ಯೆ',
'ಗಾಂಧರ್ವವಿಧಿ',
'ಗಾಂಧರ್ವವೇದ',
'ಗಾಂಧರ್ವಶಾಸ್ತ್ರ',
'ಗಾಂಧರ್ವಸೃಷ್ಟಿ',
'ಗಾಂಧಾರಗುಪ್ತ',
'ಗಾಂಧಾರಗ್ರಾಮ',
'ಗಾಂಪುಗಾಣಿಸು',
'ಗಾಂವಟಿಸಾಲೆ',
'ಗಾಂವಠೀಹಂಚು',
'ಗಾಜರಗೆಡ್ಡೆ',
'ಗಾಜುಕಾಗದ',
'ಗಾಜುಗುಪ್ಪಿಗೆ',
'ಗಾಜುದೊಡಿಗೆ',
'ಗಾಜುಲವಣ',
'ಗಾಡಿಕತ್ತರಿ',
'ಗಾಡಿಕಾಱತಿ',
'ಗಾಡಿಗತನ',
'ಗಾಢಪ್ರತಿಜ್ಞೆ',
'ಗಾಢಪ್ರವೇಶ',
'ಗಾಢಾಂಧಕಾರ',
'ಗಾಢಾನುರಕ್ತ',
'ಗಾಢಾನುರಕ್ತೆ',
'ಗಾಢಾಲಿಂಗನ',
'ಗಾಣಗಂಟಲ',
'ಗಾಣಗತ್ತರಿ',
'ಗಾಣಗುದ್ದಿಗೆ',
'ಗಾಣತಿಗೆಯ್',
'ಗಾಣನಾಯಕಿ',
'ಗಾಣವಾಡಿಸು',
'ಗಾತ್ರಸಂಕೋಚಿ',
'ಗಾತ್ರಸ್ಫುರಣ',
'ಗಾನದೇವತೆ',
'ಗಾನಪದ್ಧತಿ',
'ಗಾನಪ್ರಬಂಧ',
'ಗಾಬರಿಗೊಳು',
'ಗಾಬರಿಗೊಳ್ಳು',
'ಗಾಬರಿಪಡು',
'ಗಾಮದೆವರು',
'ಗಾಮುಂಡುಗೆಯ್',
'ಗಾಯಕತನ',
'ಗಾಯಗಾಣಿಸು',
'ಗಾಯಗೊಳಿಸು',
'ಗಾಯದೊಪ್ಪಲು',
'ಗಾಯನಕಲೆ',
'ಗಾಯನಗಾರ',
'ಗಾಯಾಳಿತನ',
'ಗಾರುಗೆಡಿಸು',
'ಗಾರುಗೊಳಿಸು',
'ಗಾರುಡಕರ್ಮ',
'ಗಾರುಡಗೊಡು',
'ಗಾರುಡತನ',
'ಗಾರುಡಮಂತ್ರ',
'ಗಾರುಡಮಣಿ',
'ಗಾರುಡರಕ್ಷೆ',
'ಗಾರುಡವಾದಿ',
'ಗಾರುಡವಿದ್ಯ',
'ಗಾರುಡಿಗಿತ್ತಿ',
'ಗಾರುಡೌಷಧ',
'ಗಾರುಹಪತ್ಯ',
'ಗಾರೆಕೆಲಸ',
'ಗಾವಕಾರಿಕೆ',
'ಗಾವದಿತನ',
'ಗಾವರಂಬೊರೆ',
'ಗಾವರದುಂಬಿ',
'ಗಾವಳಿವಡೆ',
'ಗಾವಿಲತನ',
'ಗಾವಿಲನುಡಿ',
'ಗಾವುಂಡಪಂತ',
'ಗಾವುಂಡವೃತ್ತಿ',
'ಗಾವುಂಡಸುಂಕ',
'ಗಾವುಂಡುಗೆಯ್',
'ಗಾವುಂಡುತನ',
'ಗಾಸಿಗೊಳಿಸು',
'ಗಾಹುಗತಕ',
'ಗಾಹುಗದುಕು',
'ಗಾಳಗಟ್ಟಿಗೆ',
'ಗಾಳಿಗಂಟಲು',
'ಗಾಳಿಗಿರಣಿ',
'ಗಾಳಿಗುದುರೆ',
'ಗಾಳಿಗೂಳ್ಗೊಳ್',
'ಗಾಳಿಗೋಪುರ',
'ಗಾಳಿತುಂಬಿಸು',
'ಗಾಳಿಬಂದೂಕು',
'ಗಾಳಿಬಯಲು',
'ಗಾಳಿಬಿಡಿಸು',
'ಗಾಳಿಬುರುಡೆ',
'ಗಾಳಿಮಂಟಪ',
'ಗಾಳಿವಟ್ಟೆಗ',
'ಗಾಳಿಸವಾರಿ',
'ಗಾಳುಗಂಡಿಕೆ',
'ಗಾಳುಗಜಱು',
'ಗಾಱುಗತ್ತಲೆ',
'ಗಾಱುಗೆಡಿಸು',
'ಗಾಱುಗೆದಱು',
'ಗಾಱುಗೆಲಸ',
'ಗಿಂಡಿಯೂಳಿಗ',
'ಗಿಜಿಗಿಜಿಸು',
'ಗಿಟಕುಹಾಯು',
'ಗಿಡಕಾವಲು',
'ಗಿಡಗಕಣ್ಣು',
'ಗಿಡಗಾವಲು',
'ಗಿಡತಿಗಣೆ',
'ಗಿಡಪತ್ರಿಕೆ',
'ಗಿಡಮೂಲಿಕೆ',
'ಗಿಡುಗದೃಷ್ಟಿ',
'ಗಿಡುದೇರಣ',
'ಗಿಡ್ಡಕುಂಪಣಿ',
'ಗಿಡ್ಡಹಾಗಲ',
'ಗಿಣಗಣಿಕೆ',
'ಗಿಣಿಗಡಕ',
'ಗಿಣಿಗಿಣಿಕೆ',
'ಗಿಣಿಗಿಣಿಲು',
'ಗಿರಕಿಮೆಟ್ಟು',
'ಗಿರಕಿರೋಗ',
'ಗಿರಕಿಹೊಡೆ',
'ಗಿರಕೆಹೊಡೆ',
'ಗಿರಗಿರನೆ',
'ಗಿರಣಿಬಂಬು',
'ಗಿರಣಿರೋಗ',
'ಗಿರಲುಮೀಸೆ',
'ಗಿರವಿದಾರ',
'ಗಿರಿಕರ್ಣಿಕೆ',
'ಗಿರಿಕಾರೂಢ',
'ಗಿರಿಗಿಟ್ಟಲೆ',
'ಗಿರಿಜಾಕಾಂತ',
'ಗಿರಿಜಾಮೀಸೆ',
'ಗಿರಿನಂದನ',
'ಗಿರಿನಿತಂಬ',
'ಗಿರಿಪರ್ಣಿಕೆ',
'ಗಿರಿಮಲ್ಲಿಕೆ',
'ಗಿರಿಮಲ್ಲಿಗೆ',
'ಗಿರಿಯಾರತಿ',
'ಗಿರಿಲುಮೀಸೆ',
'ಗಿರುಗಾಣಿಸು',
'ಗಿಲಕುಮರ',
'ಗಿಲಿಗಿಲಿಸು',
'ಗಿಲೀಟುಗಾರ',
'ಗಿಲೀಟುಮಾಲು',
'ಗಿಲುಕೆಮಂಚ',
'ಗಿಳಂಕರಿಸು',
'ಗಿಳಿಗಡಿಕ',
'ಗಿಳಿಗೆಡಪ',
'ಗಿಳಿಬಾಗಿಲು',
'ಗಿಳಿಬಾವಲಿ',
'ಗಿಳಿವಾಗಿಲು',
'ಗಿಱಿಕುಗುಟ್ಟು',
'ಗೀಜಗವಕ್ಕಿ',
'ಗೀಜಿಗವಕ್ಕಿ',
'ಗೀತನಾಟಕ',
'ಗೀತಪ್ರಬಂಧ',
'ಗೀತರೂಪಕ',
'ಗೀರಕಸಾಲೆ',
'ಗೀರಲಂಕಾರ',
'ಗೀರ್ವಾಣನಾಥ',
'ಗೀರ್ವಾಣಭಾಷೆ',
'ಗೀರ್ವಾಣವಾಣಿ',
'ಗೀಳು ಹಿಡಿಸು',
'ಗುಂಗಂಗಿಹಂಬು',
'ಗುಂಗುದಿಗೊಳು',
'ಗುಂಗುದಿಬಡು',
'ಗುಂಗುರುತಲೆ',
'ಗುಂಗುರುದಲೆ',
'ಗುಂಗುಹಿಡಿಸು',
'ಗುಂಜನ್ಮದಾಳಿ',
'ಗುಂಜಾಪ್ರಮಾಣ',
'ಗುಂಜಾಭರಣ',
'ಗುಂಜಿದೊಡವು',
'ಗುಂಡಗಪ್ಪರ',
'ಗುಂಡಗಳಿಗೆ',
'ಗುಂಡವಟ್ಟಲ್',
'ಗುಂಡಿಗೆಗೊಳ್',
'ಗುಂಡಿಗೆನೋವು',
'ಗುಂಡಿಗೊಸರು',
'ಗುಂಡಿತಿಗಣೆ',
'ಗುಂಡಿಪುಷ್ಪಕ',
'ಗುಂಡಿವಟ್ಟಲ್',
'ಗುಂಡುಕಟ್ಟಿಗೆ',
'ಗುಂಡುಗಪ್ಪರ',
'ಗುಂಡುಗಾಳಗ',
'ಗುಂಡುಬಂದಿಗೆ',
'ಗುಂಡುಬಟ್ಟಲು',
'ಗುಂಡುಮಲ್ಲಿಗೆ',
'ಗುಂಡುಮುಳುಗ',
'ಗುಂಡುಮುೞುಕ',
'ಗುಂಡುವಟ್ಟಲ್',
'ಗುಂಡುಹಾರಿಸು',
'ಗುಂಪುಗಾರಿಕೆ',
'ಗುಂಬದಂಬಟೆ',
'ಗುಗ್ಗರಗೋಲು',
'ಗುಗ್ಗರಿಗಟ್ಟು',
'ಗುಗ್ಗರಿಗಲ್',
'ಗುಗ್ಗರಿಗೊಳ್ಳು',
'ಗುಗ್ಗರಿಗೋಲು',
'ಗುಗ್ಗರಿಸೆಟ್ಟಿ',
'ಗುಗ್ಗುರಿಗಟ್ಟು',
'ಗುಗ್ಗುರಿಗಲ್',
'ಗುಗ್ಗುರಿಗೊಳ್ಳು',
'ಗುಗ್ಗುರಿಸೆಟ್ಟಿ',
'ಗುಗ್ಗುರುಗೊಳ್ಳು',
'ಗುಗ್ಗುಲುಧೂಪ',
'ಗುಗ್ಗುಳಧೂಪ',
'ಗುಜಗುಂಪಲು',
'ಗುಜಗುಜನೆ',
'ಗುಜರಾಯಿಸು',
'ಗುಜರಿಹೊತ್ತು',
'ಗುಜುಗುಂಪಲು',
'ಗುಜುಗುಜನೆ',
'ಗುಜುಗುಜಿಸು',
'ಗುಜುಱುಗೊಂಬು',
'ಗುಜುಱುದಲೆ',
'ಗುಜುಱುಮಂಡೆ',
'ಗುಜುಱುಮೀಸೆ',
'ಗುಜ್ಜರಪಟ್ಟೆ',
'ಗುಜ್ಜರಿಮಂಚ',
'ಗುಜ್ಜರಿವೇಣಿ',
'ಗುಜ್ಜಿರುಮಂಡೆ',
'ಗುಜ್ಜುಗುಱುಕು',
'ಗುಜ್ಜುಗೊರಲ್',
'ಗುಜ್ಜಗೊರಲು',
'ಗುಜ್ಜುತಡಮೆ',
'ಗುಟಕರಿಸು',
'ಗುಟಗರಿಸು',
'ಗುಟುಕರಿಸು',
'ಗುಟುಕುನೀರು',
'ಗುಟುಕುಪ್ರಾಣ',
'ಗುಟುಗುಟನೆ',
'ಗುಟುರುಹಾಕು',
'ಗುಟ್ಟುಗಾಣಿಸು',
'ಗುಡಜೀರಿಗೆ',
'ಗುಡಮೇಟಕೆ',
'ಗುಡವಿಕಾರ',
'ಗುಡಾರಗಟ್ಟು',
'ಗುಡಿಕಟ್ಟಿಸು',
'ಗುಡಿಗಟ್ಟಿಸು',
'ಗುಡಿಗಾರಿಕೆ',
'ಗುಡಿಗಿಚಣ್ಣ',
'ಗುಡಿಗುಂಡಾರ',
'ಗುಡಿಗೂಡಾರ',
'ಗುಡಿಗೊಳಿಸು',
'ಗುಡಿಗೋಪುರ',
'ಗುಡಿದುಂಬಿಸು',
'ಗುಡಿಪಾಡವ',
'ಗುಡಿಪಾಡಿವ',
'ಗುಡಿಬಳಸು',
'ಗುಡಿವಿಡಿಸು',
'ಗುಡುಗಾಡಿಸು',
'ಗುಡುಗುಡನೆ',
'ಗುಡುಗುಡಿಸು',
'ಗುಡುಗುಹಾಕು',
'ಗುಡುಗೂರಿಸು',
'ಗುಡ್ಡಗೆಣಸು',
'ಗುಡ್ಡನೇರಳೆ',
'ಗುಡ್ಡುಬಿಳಿದು',
'ಗುಣಂಗೊಳಿಸು',
'ಗುಣಕಥನ',
'ಗುಣಗಣನೆ',
'ಗುಣಗುಣಿಸು',
'ಗುಣಗೆಡಿಸು',
'ಗುಣಗ್ರಹಣ',
'ಗುಣಚ್ಛೇದಕ',
'ಗುಣನಿಧಾನ',
'ಗುಣನಿಲಯ',
'ಗುಣನಿಲಯೆ',
'ಗುಣನಿಳಯ',
'ಗುಣನಿಳಯೆ',
'ಗುಣಪಡಿಸು',
'ಗುಣಪ್ರಕೃತಿ',
'ಗುಣಬ್ರಾಹ್ಮಣ',
'ಗುಣರಹಿತ',
'ಗುಣರಹಿತೆ',
'ಗುಣರಾಹಿತ್ಯ',
'ಗುಣವಚನ',
'ಗುಣವಜ್ಜನ',
'ಗುಣವಾಚಕ',
'ಗುಣವಿವೇಕ',
'ಗುಣವೃಕ್ಷಕ',
'ಗುಣವ್ಯಸನ',
'ಗುಣಶಾಲಿನಿ',
'ಗುಣಸಂಪನ್ನ',
'ಗುಣಸಂಪನ್ನೆ',
'ಗುಣಸಾಗರ',
'ಗುಣಸೌಂದರ',
'ಗುಣಹೀನತೆ',
'ಗುಣಾನುರೂಪ',
'ಗುಣಾಭಿರಾಮ',
'ಗುಣಾಭಿರಾಮೆ',
'ಗುಣಾವಲಂಬಿ',
'ಗುಣುಗುಣಿಸು',
'ಗುಣೋದೀರ್ಣತೆ',
'ಗುತ್ತಕಾಗದ',
'ಗುತ್ತಿಗೆಕಾರ',
'ಗುತ್ತಿಗೆಕಾಱ',
'ಗುತ್ತಿಗೆಕಾತಿ',
'ಗುತ್ತಿಗೆದಾರ',
'ಗುತ್ತಿಗೆವಿಡಿ',
'ಗುತ್ತಿಗೆಹಿಡಿ',
'ಗುತ್ತುಬಾಳಿಕೆ',
'ಗುತ್ತೆದಾರಿಕೆ',
'ಗುದಕೀಲಕ',
'ಗುದಗುಲಿಕಿ',
'ಗುದಮುರಿಗೆ',
'ಗುದಮೈಥುನ',
'ಗುದಸ್ತಬಾಕಿ',
'ಗುದಸ್ತಾಬಾಕಿ',
'ಗುದಿಗೆಕಲ್ಲು',
'ಗುದಿಗೆಶಾಸ್ತ್ರ',
'ಗುದಿಗೆಹೊಯ್ಲು',
'ಗುದುಗುದಿಸು',
'ಗುದುಮರಿಗೆ',
'ಗುದುಮುರಿಗೆ',
'ಗುದ್ದಲಿಗೊಳ್',
'ಗುದ್ದಲಿಪೂಜೆ',
'ಗುದ್ದಲಿವಲ್',
'ಗುದ್ದುಗೆಡಹು',
'ಗುದ್ದುಮುರಿಗೆ',
'ಗುನ್ನಿಲುಗೂಡು',
'ಗುಪಿತಕಾಱ',
'ಗುಪಿತವೇಷಿ',
'ಗುಪ್ತಗಾಮಿನಿ',
'ಗುಪ್ತಚಾರಿಕೆ',
'ಗುಪ್ತಚಾರಿಣಿ',
'ಗುಪ್ತಪಾತಕ',
'ಗುಪ್ತಪಾತಕಿ',
'ಗುಪ್ತಪ್ರವಾಹ',
'ಗುಪ್ತಯೋಜನೆ',
'ಗುಪ್ತಸಲಿಲ',
'ಗುಪ್ತಹೇರಿಗ',
'ಗುಬರುಗಟ್ಟು',
'ಗುಬರುಗಿಡ',
'ಗುಬರುದಲೆ',
'ಗುಬರುಹಾಕು',
'ಗುಬುಟುಬೇರು',
'ಗುಬುರುಪೊದೆ',
'ಗುಬುರುಭಂಡಿ',
'ಗುಬುರುಹಾಕು',
'ಗುಬ್ಬಚ್ಚಿಗಿಡ',
'ಗುಬ್ಬಚ್ಚಿಗೂಡು',
'ಗುಬ್ಬಚ್ಚಿಬಾಳೆ',
'ಗುಬ್ಬಿ ಎಂಜಲು',
'ಗುಬ್ಬಿಗಂಕಣ',
'ಗುಬ್ಬಿಚಿಮಣಿ',
'ಗುಬ್ಬಿಬಾಗಿಲು',
'ಗುಬ್ಬಿಬಾವುಲಿ',
'ಗುಬ್ಬಿಬೆಳಸೆ',
'ಗುಮಟೆಪಾಗು',
'ಗುಮಾನಿಪಟ್ಟಿ',
'ಗುಮಾಸ್ತಗಿರಿ',
'ಗುಮಿಗೊಳಿಸು',
'ಗುಮುಕುಹಾಕು',
'ಗುಮುಗುಮಿಸು',
'ಗುಮ್ಮಕಾಂಭೋಜ',
'ಗುಮ್ಮಟೇಶ್ವರ',
'ಗುಮ್ಮುಗುಸುಕು',
'ಗುರಕಾಯಿಸು',
'ಗುರಿಗಾರಿಕೆ',
'ಗುರಿಪಡಿಸು',
'ಗುರಿಯರಿಲ್',
'ಗುರುಂಘಾತಕ',
'ಗುರುಂಘಾತಕಿ',
'ಗುರುಕಾಣಿಕೆ',
'ಗುರುಕಾಯಿಸು',
'ಗುರುಗುಮ್ಮನೆ',
'ಗುರುಗುರಿಸು',
'ಗುರುಗೇಹಿನಿ',
'ಗುರುತಪ್ಪುಗ',
'ಗುರುತಲ್ಪಗ',
'ಗುರುತುಚೀಟಿ',
'ಗುರುತುಪಟ್ಟಿ',
'ಗುರುತುಹಚ್ಚು',
'ಗುರುತುಹತ್ತು',
'ಗುರುತುಹಿಡಿ',
'ಗುರುತೆವೆಱು',
'ಗುರುತ್ವಕೇಂದ್ರ',
'ಗುರುತ್ವಮಧ್ಯ',
'ಗುರುತ್ವವಡೆ',
'ಗುರುತ್ವಶಕ್ತಿ',
'ಗುರುದಕ್ಷಿಣೆ',
'ಗುರುಪಾತಕ',
'ಗುರುಮುಖೇನ',
'ಗುರುಯೋಷಿತೆ',
'ಗುರುಲಾಘವ',
'ಗುರುವಚನ',
'ಗರುವರಿಯ',
'ಗುರುವಿಘಾತಿ',
'ಗುರುವಿಯೋಗ',
'ಗುರ್ವನುಗ್ರಹ',
'ಗುಲಾಬಜಾಮ',
'ಗುಲಾಬದಾಣಿ',
'ಗುಲಾಬದಾನಿ',
'ಗುಲಾಮಗಿರಿ',
'ಗುಲ್ಫದ್ವಯಸ',
'ಗುಲ್ಲುಹಬ್ಬಿಸು',
'ಗುಸುಗುಂಪಲು',
'ಗುಹಾನಿಹಿತ',
'ಗುಹ್ಯಕೇಶ್ವರ',
'ಗುಹ್ಯಲಂಪಟ',
'ಗುಹ್ಯಲಂಪಟೆ',
'ಗುಳಗಿಚಣ್ಣ',
'ಗುಳಗಿಚೊಣ್ಣ',
'ಗುಳಪಪ್ಪಡಿ',
'ಗುಳಪಾವಟೆ',
'ಗುಳಿಕಕಾಲ',
'ಗುಳಿಗೆಗಟ್ಟು',
'ಗುಳಿಗೆಯಿಕ್ಕು',
'ಗುಳಿಗೆಯಿಡು',
'ಗುಳಿಗೆಯೆತ್ತು',
'ಗುಳುಂಗಾರಿಕೆ',
'ಗುಳುಗುಳನೆ',
'ಗುಳುಗುಳಿಸು',
'ಗುಳೆಕಿತ್ತಿಸು',
'ಗುಳೆಯೆಬ್ಬಿಸು',
'ಗುಳ್ಳಪಾವಟೆ',
'ಗುಳ್ಳಬದನೆ',
'ಗುಱಿಮಾಡಿಸು',
'ಗುಱಿಮಾನಿಸ',
'ಗುಱಿಯೇಱಿಸು',
'ಗುಱಿವಾನಿಸ',
'ಗುಱುಗುಮ್ಮಗೆ',
'ಗುಱುಗುಮ್ಮನೆ',
'ಗುಱುಗುಮ್ಮೆನ್',
'ಗುಱುಗುಱಿಸು',
'ಗುೞುಗುೞನೆ',
'ಗುೞುಗುೞಿಸು',
'ಗೂಡಂಬುಗೊಳ್',
'ಗೂಡಗೊಳಿಸು',
'ಗೂಡಾರವಿಡು',
'ಗೂಡುಗೊಳವೆ',
'ಗೂಡುಗೊಳಿಸು',
'ಗೂಢಚತುರ್ಥ',
'ಗೂಢಚಾರಿಕೆ',
'ಗೂಢಚಾರಿಣಿ',
'ಗೂಢಪುರುಷ',
'ಗೂಢಪ್ರಣಿಧಿ',
'ಗೂದೆಹರುಕ',
'ಗೂಬೆಗತ್ತಲು',
'ಗೂಬೆಶಂಕರ',
'ಗೂಬೆಶಂಕರಿ',
'ಗೂವಗುತ್ತಿಗೆ',
'ಗೂಳಿಕಾಳಗ',
'ಗೂಳಿಸೆಣಸು',
'ಗೂಳೆಯಂತೆಗೆ',
'ಗೂಳೆಯಂದೆಗೆ',
'ಗೂಳೆಯತೆಗೆ',
'ಗೂಳೆಯದೆಗೆ',
'ಗೂಳೆಯಹೋಗು',
'ಗೂಱುಮಾಱಾಳಿ',
'ಗೃಹಕಲಹ',
'ಗೃಹಕೋಕಿಳ',
'ಗೃಹಗೋಧಿಕ',
'ಗೃಹಗೋಧಿಕೆ',
'ಗೃಹಗ್ರಾಮಣಿ',
'ಗೃಹಚಾಮರ',
'ಗೃಹದೀರ್ಘಿಕೆ',
'ಗೃಹದೇವತೆ',
'ಗೃಹಪ್ರತಿಷ್ಠೆ',
'ಗೃಹಪ್ರವೇಶ',
'ಗೃಹಬಂಧನ',
'ಗೃಹರಕ್ಷಕ',
'ಗೃಹವಿಜ್ಞಾನ',
'ಗೃಹಶಾರಿಕೆ',
'ಗೃಹಸಂಸ್ಕಾರ',
'ಗೃಹಸಚಿವ',
'ಗೃಹಸ್ಥಧರ್ಮ',
'ಗೃಹಸ್ಥಾಶ್ರಮ',
'ಗೃಹಾಂತರಾಳ',
'ಗೃಹಾಭ್ಯಂತರ',
'ಗೃಹಿಣೀಪದ',
'ಗೃಹೀತದಿಶ',
'ಗೃಹೋಪಕಂಠ',
'ಗೃಹೋಪಸ್ಕರ',
'ಗೆಂಟಿಗೆಗಟ್ಟು',
'ಗೆಜ್ಜಲುಭೂಮಿ',
'ಗೆಜ್ಜೆ ಅಡ್ಡಿಕೆ',
'ಗೆಜ್ಜೆಕರಡ',
'ಗೆಜ್ಜೆಗೊಲಸು',
'ಗೆಜ್ಜೆಪಾಡಗ',
'ಗೆಜ್ಜೆಪೈಜಣ',
'ಗೆಜ್ಜೆಯಂದುಗೆ',
'ಗೆಜ್ಜೆಯಲಬು',
'ಗೆಡ್ಡೆಗೆಣಸು',
'ಗೆಡ್ಡೆಮೊಸರ್',
'ಗೆಡ್ಡೆಮೊಸರು',
'ಗೆಣತಿಕಸ',
'ಗೆಲವುಗಂಬ',
'ಗೆಲುವುಗಂಬ',
'ಗೆಲ್ಲಗೆಡಹು',
'ಗೆಲ್ಲುಮಂಕರಿ',
'ಗೆಳತಿಯೆಳ್ಳು',
'ಗೇಯನಾಟಕ',
'ಗೇಹೋಪವನ',
'ಗೈರ್‍ಹಾಜರ್',
'ಗೈರ್‍ಹಾಜರಿ',
'ಗೈರಮಾಹಿತಿ',
'ಗೈರವಾಜಬಿ',
'ಗೈರಹಂಗಾಮ',
'ಗೈರುಮಾಮೂಲು',
'ಗೈರುವಾಜಿಬಿ',
'ಗೈರುಹಾಜರಿ',
'ಗೈರುಹಾಜರು',
'ಗೊಂಕರುಕಪ್ಪೆ',
'ಗೊಂಕಱಕೞ್ತೆ',
'ಗೊಂಕಱುಕಪ್ಪೆ',
'ಗೊಂಕುಱುಗೞ್ತೆ',
'ಗೊಂಚಲುಗುಂಡು',
'ಗೊಂಚಲುದೀಪ',
'ಗೊಂಟುಗೊಳಿಸು',
'ಗೊಂಡಾಳೆಗಟ್ಟು',
'ಗೊಂದಣಂಗೂಡು',
'ಗೊಂದಣಂಗೊಳ್',
'ಗೊಂದಣಗೊಳ್',
'ಗೊಂದಣಿಗೊಳ್',
'ಗೊಂದಲಗೆಡು',
'ಗೊಂದಲಗೊಳ್ಳು',
'ಗೊಂದಲವೇಳು',
'ಗೊಂದಲಹಾಕು',
'ಗೊಂದೆಗೊರವ',
'ಗೊಂಬೆಮದುವೆ',
'ಗೊಂಬೆಹಚ್ಚಡ',
'ಗೊಜರಗಳೆ',
'ಗೊಟಗೊಟನೆ',
'ಗೊಟರುಕಪ್ಪೆ',
'ಗೊಟ್ಟಿಗಾಳೆಗ',
'ಗೊಡಚಿಗಣ್ಣು',
'ಗೊಡವೆಗೊಳ್',
'ಗೊಡ್ಡುಹರಟೆ',
'ಗೊಡ್ಡುಹುಣಸೆ',
'ಗೊಣಗುಮಾತು',
'ಗೊತ್ತುಪಡಿಸು',
'ಗೊದುರಹುಲ್ಲು',
'ಗೊನೆಹಕಾತಿ',
'ಗೊಬ್ಬರಮಚ್ಛಿ',
'ಗೊಬ್ಬೆಸೆರಗು',
'ಗೊಬ್ರನೇರಳೆ',
'ಗೊಮ್ಮಟೇಶ್ವರ',
'ಗೊರಗೊರಸು',
'ಗೊರಜೆಪಲ್ಯ',
'ಗೊರಲೆಬಡಿ',
'ಗೊರವವಾಡ',
'ಗೊಲೆಗೊಳಿಸು',
'ಗೊಲ್ಲವಾಳಿಗ',
'ಗೊಳಕೊಳಿಕೆ',
'ಗೊಳಗುಳಿಕೆ',
'ಗೊಳಗೊಳಕೆ',
'ಗೊಳಗೊಳಿಕೆ',
'ಗೊಳೆಗುದುರೆ',
'ಗೋಕುಲಾಷ್ಟಮಿ',
'ಗೋಗುಲಧೂಪ',
'ಗೋಚಾರಫಲ',
'ಗೋಟುಗೊಳಿಸು',
'ಗೋಡರಗಪ್ಪೆ',
'ಗೋಡಾರಗಪ್ಪೆ',
'ಗೋಡುಗೊಬ್ಬರ',
'ಗೋಣಡಿಗೊಳ್',
'ಗೋಣಿದಡಾರ',
'ಗೋಣ್ಮುರಿಗೊಳ್',
'ಗೋತ್ರಕಲಹ',
'ಗೋತ್ರಧವಳ',
'ಗೋತ್ರನಿಧಾನೆ',
'ಗೋತ್ರನಿಸ್ತಾರ',
'ಗೋತ್ರಪ್ರವರ',
'ಗೋತ್ರವಿರೋಧ',
'ಗೋತ್ರಸ್ಖಲನೆ',
'ಗೋತ್ರಸ್ಖಲಿತ',
'ಗೋದಣೆವಲ್',
'ಗೋದಾನವ್ರತ',
'ಗೋದಾರಣಕ',
'ಗೋದಿನಾಗರ',
'ಗೋದಿಬರ್ಸನ',
'ಗೋದಿಬಾರ್ಮೊಂಡ',
'ಗೋದುಮೆಹಾವು',
'ಗೋಧೂಮವರ್ಣ',
'ಗೋಧೂಲಿಲಗ್ನ',
'ಗೋಧೂಳಿಲಗ್ನ',
'ಗೋಪಶಕಲ',
'ಗೋಪಾಕರ್ಕಟಿ',
'ಗೋಪಾನಸೀಕ',
'ಗೋಪಾಲಪುಟ್ಟಿ',
'ಗೋಪಾಲಬುಟ್ಟಿ',
'ಗೋಪಾಳಬುಟ್ಟಿ',
'ಗೋಪಿಚಂದನ',
'ಗೋಪಿತಕ್ರಿಯ',
'ಗೋಪೀಚಂದನ',
'ಗೋಪುರಾರತಿ',
'ಗೋಪ್ಯಮಾನತೆ',
'ಗೋಪ್ಯರಕ್ಷಣೆ',
'ಗೋಮಂಡಳಿಗ',
'ಗೋಮಟೇಶ್ವರ',
'ಗೋಮತಲ್ಲಿಕೆ',
'ಗೋಮಾರಿಕಬ್ಬು',
'ಗೋಮುಖವ್ಯಾಘ್ರ',
'ಗೋಮುಖಸೂತ್ರ',
'ಗೋಮುಖಾಸನ',
'ಗೋರಕ್ಷದುಗ್ಧಿ',
'ಗೋರಕ್ಷಮೂಲಿ',
'ಗೋರಖಮುಂಡಿ',
'ಗೋರಾಗುಮ್ಮಟ',
'ಗೋರಾಡ್ಗಮನ',
'ಗೋರಿಗೊಳಿಸು',
'ಗೋರುಗರೀಬ',
'ಗೋರುಗೊಳಿಸು',
'ಗೋರೋಚಿಸೊಪ್ಪು',
'ಗೋಲಗುಮಟ',
'ಗೋಲಗುಮುಟ',
'ಗೋವಳಗೋಲ್',
'ಗೋವಳಿಗೋಲ್',
'ಗೋವಿಂದಗತಿ',
'ಗೋವಿಂದಫಲ',
'ಗೋವಿಂದಬಕ್ಷಿ',
'ಗೋವಿಂದರಾಜಿ',
'ಗೋವಿಂದವೀಳೈ',
'ಗೋಷಾಪದ್ಧತಿ',
'ಗೋಷ್ಠೀಕಲ್ಯಾಣ',
'ಗೋಷ್ಪದಜಲ',
'ಗೋಸಣೆಗಳೆ',
'ಗೋಸಣೆಗೊಳ್',
'ಗೋಸಣೆಮಾಡು',
'ಗೋಸಣೆಯಿಡು',
'ಗೋಸಣೆವೆಱು',
'ಗೋಸನೆಗಳೆ',
'ಗೋಸನೆಗೊಳು',
'ಗೋಸನೆಯಿಡು',
'ಗೋಸನೆವೋಗು',
'ಗೋಸಮುದಾಯ',
'ಗೋಳಕತ್ರಯ',
'ಗೋಳಕಾಕಾರ',
'ಗೋಳಗುಮುಟ',
'ಗೋಳಗುಮ್ಮಟ',
'ಗೋಳಸಂಪಿಗೆ',
'ಗೋಳುಂಡೆಗುಟ್ಟು',
'ಗೋಳುಂಡೆಗೊಳ್',
'ಗೋಳುಗುಟ್ಟಿಸು',
'ಗೋಳ್ಮುರಿಗೊಳ್',
'ಗೋೞುಂಡೆಗೊಳ್',
'ಗೋೞ್ಮುರಿಗೊಳ್',
'ಗೌಟಿಕೆಗಟ್ಟು',
'ಗೌಡಬಂಗಾಲ',
'ಗೌಡಬಂಗಾಲಿ',
'ಗೌಡಬಂಗಾಳ',
'ಗೌರವಗ್ರಂಥ',
'ಗೌರವತ್ರಯ',
'ಗೌರವದೋಷ',
'ಗೌರವಧನ',
'ಗೌರವರಕ್ಷೆ',
'ಗೌರವರ್ಣಿನಿ',
'ಗೌರವಾನ್ವಿತ',
'ಗೌರವಾನ್ವಿತೆ',
'ಗೌರಿತದಿಗೆ',
'ಗೌರಿಹುಣ್ಣಿಮೆ',
'ಗೌರಿಹುಣ್ಣಿವೆ',
'ಗೌರೀತೃತೀಯೆ',
'ಗೌರೀಪಾಷಾಣ',
'ಗೌರೀಪ್ರವರ',
'ಗೌರೀಶಂಕರ',
'ಗೌರುಗಹಳೆ',
'ಗೌಳಶಾರೀರ',
'ಗೌಳಿನುಡಿತ',
'ಗೌಳಿಶಕುನ',
'ಗೌಱಗಹಳೆ',
'ಗೌಱುಗಹಳೆ',
'ಗ್ರಂಥಕೀಟಕ',
'ಗ್ರಂಥಭಂಡಾರ',
'ಗ್ರಂಥಭಂಡಾರಿ',
'ಗ್ರಂಥರಚನೆ',
'ಗ್ರಂಥಲೇಖಕ',
'ಗ್ರಹಣಶಕ್ತಿ',
'ಗ್ರಾಮಕೂಟಕ',
'ಗ್ರಾಮತಕ್ಕಾಮೆ',
'ಗ್ರಾಮತೆರಿಗೆ',
'ಗ್ರಾಮದೇವತೆ',
'ಗ್ರಾಮಪ್ರತಿಷ್ಠೆ',
'ಗ್ರಾಮಭೃತಕ',
'ಗ್ರಾಮಭೋಜಕ',
'ಗ್ರಾಮಮರ್ಯಾದೆ',
'ಗ್ರಾಮಶಾರ್ದೂಲ',
'ಗ್ರಾಮಸೂಕರ',
'ಗ್ರಾಮಾಧಿಪತಿ',
'ಗ್ರಾಮ್ಯವಿಷಯ',
'ಗ್ರಾಹಕಶಕ್ತಿ',
'ಗ್ರೀವಾರೇಚಕ',
'ಗ್ರೀವಾರೇಚಿತ',
'ಘಂಟಾಗೋಪುರ',
'ಘಂಟಾಪಾಟಲಿ',
'ಘಂಟಿಕಾಜಾಲ',
'ಘಂಟಿಕಾರವ',
'ಘಟಚೇಟಿಕೆ',
'ಘಟನಾವಳಿ',
'ಘಟನಾಸಭೆ',
'ಘಟನಾಸ್ರೋತ',
'ಘಟಪ್ರೋದ್ಭವ',
'ಘಟಾನುಘಟಿ',
'ಘಟಿಕಾಯಂತ್ರ',
'ಘಟಿಕಾಸ್ಥಳ',
'ಘಟಿಕಾಸ್ಥಾನ',
'ಘಟಿಕೋತ್ಸವ',
'ಘಟ್ಟಿಗತನ',
'ಘಟ್ಟಿಗೊಳಿಸು',
'ಘಟ್ಟಿಮಗುಳ್ಚು',
'ಘಟ್ಟಿಮಗುೞ್ಚು',
'ಘಟ್ಟಿವಳಿತಿ',
'ಘಟ್ಟಿವಾಳಿಕೆ',
'ಘಣಘಣಲ್',
'ಘಣಘಣಿಸು',
'ಘಣಲುಗುಟ್ಟು',
'ಘನಗರ್ಜಿತ',
'ಘನಜ್ವಲಿತ',
'ಘನನಿನದ',
'ಘನಶ್ಯಾಮಳ',
'ಘನಸಮಯ',
'ಘನಾಂಧಕಾರ',
'ಘನೀಕರಣ',
'ಘನೀಭವಿಸು',
'ಘಮಘಮಾಟ',
'ಘಮಘಮಿಸು',
'ಘರಖಾಯಿಸು',
'ಘರಘರನೆ',
'ಘರವಟಿಗ',
'ಘರವಟಿಗೆ',
'ಘರವಟ್ಟಿಗೆ',
'ಘರವಟ್ಟಿಸು',
'ಘರಿಘರಿನೆ',
'ಘರ್ಮಸಮಯ',
'ಘಲಘಲನೆ',
'ಘಲಘಲಿಸು',
'ಘಸಣಿಗೊಳ್',
'ಘಸಣಿವಳೆ',
'ಘಳಘಳನೆ',
'ಘಾತಕತನ',
'ಘಾತಕಬುದ್ಧಿ',
'ಘಾತಕಸ್ಥಾನ',
'ಘಾತನಸ್ಥಾನ',
'ಘಾತುಕತನ',
'ಘಾಸಿಗೊಳಿಸು',
'ಘಿಲಘಿಲಿಸು',
'ಘುಟಿಕಾಸಿದ್ಧ',
'ಘುಟಿಕಾಸಿದ್ಧಿ',
'ಘುಡಿಘುಡಿಸು',
'ಘುಡುಘುಡನೆ',
'ಘುಡುಘುಡಿಸು',
'ಘುರುಘುರಿಸು',
'ಘುಳುಘುಳಿಸು',
'ಘುೞುಘುೞಿಸು',
'ಘೂರ್ಜರನಾಟಿ',
'ಘೃಷ್ಟಕಾಶ್ಲೇಷ',
'ಘೋರಂಬಡಿಸು',
'ಘೋರದರ್ಶನ',
'ಘೋಷಣೆವಡೆ',
'ಘೋಷಾಪದ್ಧತಿ',
'ಘ್ರಾಣಗಹ್ವರ',
'ಘ್ರಾಣತರ್ಪಣ',
'ಚಂಗಣಗಿಲೆ',
'ಚಂಗಣಿಗಿಲ್',
'ಚಂಗಣಿಗಿಲು',
'ಚಂಗಲಕೋಷ್ಟ',
'ಚಂಗಲಕೋಷ್ಠ',
'ಚಂಗಿಯಲ್ಲಮ್ಮ',
'ಚಂಚಲತನ',
'ಚಂಡಕನರಿ',
'ಚಂಡಣೆಗುಡು',
'ಚಂಡದೇವತೆ',
'ಚಂಡಭೈರವ',
'ಚಂಡಮರೀಚಿ',
'ಚಂಡಮಾರುತ',
'ಚಂಡಾಟಗಾರ',
'ಚಂಡಿಕೆಗಟ್ಟು',
'ಚಂಡಿಕೆಬಿಡು',
'ಚಂಡುಮಲ್ಲಿಗೆ',
'ಚಂಡುಹಾರಿಸು',
'ಚಂದಕಾಣಿಸು',
'ಚಂದಗೆಡಿಸು',
'ಚಂದನಕ್ಷೋದ',
'ಚಂದನಚರ್ಚೆ',
'ಚಂದನವಕ್ಕಿ',
'ಚಂದನಸಾರ',
'ಚಂದನೋಪಲ',
'ಚಂದಿರವಟ್ಟು',
'ಚಂದ್ರಕಬಲ',
'ಚಂದ್ರಕಬಳ',
'ಚಂದ್ರಕಬಳ್ಳಿ',
'ಚಂದ್ರಕಲಾಪ',
'ಚಂದ್ರಕಳಾಪ',
'ಚಂದ್ರಕಿರಣ',
'ಚಂದ್ರಗ್ರಹಣ',
'ಚಂದ್ರಚಾವಡಿ',
'ಚಂದ್ರಪ್ರಜ್ಞಪ್ತಿ',
'ಚಂದ್ರಬಾವುಲಿ',
'ಚಂದ್ರಭುವನ',
'ಚಂದ್ರಮಂಡಲ',
'ಚಂದ್ರಮಯೂಖ',
'ಚಂದ್ರಮಲ್ಲಿಗೆ',
'ಚಂದ್ರಮುರುಗು',
'ಚಂದ್ರಮುರುವು',
'ಚಂದ್ರವಿಮಾನ',
'ಚಂದ್ರವೆಳಕು',
'ಚಂದ್ರಶೇಖರ',
'ಚಂದ್ರಹಲಸು',
'ಚಂದ್ರಿಕಾಸ್ರಾವ',
'ಚಂಪಕಚ್ಛಾಯೆ',
'ಚಂಪಕಮಾಲೆ',
'ಚಂಪಕಸ್ರಜ',
'ಚಂಪನಚೂರು',
'ಚಂಬಕವರೆ',
'ಚಂಬಕವಱೆ',
'ಚಂಬಕಹಱೆ',
'ಚಂಬುಕುಟ್ಟಗ',
'ಚಂಬುಕುಟ್ಟಿಗ',
'ಚಕಚಕನೆ',
'ಚಕಚಕಿತ',
'ಚಕಚಕಿಸು',
'ಚಕಣಾಚೂರು',
'ಚಕಮಕಿಸು',
'ಚಕೋರಾಸನ',
'ಚಕ್ಕಂಬಟ್ಟಲು',
'ಚಕ್ಕಣಚೂರು',
'ಚಕ್ಕಬೆರಳು',
'ಚಕ್ಕಲಗುಲಿ',
'ಚಕ್ಕಲಗುಳಿ',
'ಚಕ್ಕಳಗುಳಿ',
'ಚಕ್ಕಳಗೊಂಬೆ',
'ಚಕ್ಕಳಿಗುಳಿ',
'ಚಕ್ಕುಲಗುಲಿ',
'ಚಕ್ಕುಳಿಗುಳಿ',
'ಚಕ್ಕೊತ್ತಿಸೊಪ್ಪು',
'ಚಕ್ಕೋತಪಲ್ಯ',
'ಚಕ್ಕೋತಸೊಪ್ಪು',
'ಚಕ್ರಕಾಣಿಕೆ',
'ಚಕ್ರಕಾರಕ',
'ಚಕ್ರಧರತ್ವ',
'ಚಕ್ರಮಂಡಲ',
'ಚಕ್ರಮರ್ದಕ',
'ಚಕ್ರಮರ್ದನ',
'ಚಕ್ರಮಾರುತ',
'ಚಕ್ರಮಿಥುನ',
'ಚಕ್ರವರಿಸೆ',
'ಚಕ್ರವರ್ತನ',
'ಚಕ್ರವರ್ತಿತ್ವ',
'ಚಕ್ರವರ್ತಿನಿ',
'ಚಕ್ರವಲ್ಲಭ',
'ಚಕ್ರವಾಡಿಕೆ',
'ಚಕ್ರಸಂಭವ',
'ಚಕ್ರಾಧಿನಾಥ',
'ಚಕ್ರಾಧಿಪತಿ',
'ಚಕ್ರಾಧಿಪತ್ಯ',
'ಚಕ್ರಿಕಾವರ್ತಿ',
'ಚಕ್ಷುರಿಂದ್ರಿಯ',
'ಚಕ್ಷುರ್ವಿಜ್ಞಾನ',
'ಚಕ್ಷುಶ್ರವಣ',
'ಚಚ್ಚರಂದಳೆ',
'ಚಚ್ಚಹುಚ್ಚಗೆ',
'ಚಚ್ಚೌಕಮೈಲು',
'ಚಟಕಶ್ರಾದ್ಧ',
'ಚಟಕಾಯಿಸು',
'ಚಟಚಟನೆ',
'ಚಟಪಟಿಕೆ',
'ಚಟಪಟಿಸು',
'ಚಟಮಟಿಸು',
'ಚಟವಟಿಕೆ',
'ಚಟಿಕಾಶಿರ',
'ಚಟುಪಡಿಕೆ',
'ಚಟುಲಗಾರ',
'ಚಟುಲಗಾರ್ತಿ',
'ಚಟುವಟಿಕೆ',
'ಚಟ್ಟಸಮಯ',
'ಚಡಪಡನೆ',
'ಚಡಪಡಿಕೆ',
'ಚಡಪಡಿಸು',
'ಚಡಪುಡನೆ',
'ಚಡುಪಡನೆ',
'ಚಡುಪುಡನೆ',
'ಚಡುಹುಡನೆ',
'ಚಡೆಪವುಜು',
'ಚಡೆಸವಾರಿ',
'ಚಢಸವಾಲು',
'ಚತುಃಕಷಾಯ',
'ಚತುಃಕಷಾಯಿ',
'ಚತುರಂಗುಲ',
'ಚತುರಂಗುಳ',
'ಚತುರಕಳ್ಳಿ',
'ಚತುರತನ',
'ಚತುರತರ',
'ಚತುರಭಾವ',
'ಚತುರಮತಿ',
'ಚತುರವೃತ್ತಿ',
'ಚತುರಶೀತಿ',
'ಚತುರಸ್ರಕ',
'ಚತುರಹಸ್ತ',
'ಚತುರಾಂತರ',
'ಚತುರಾಘಾಟ',
'ಚತುರಾನನ',
'ಚತುರಾಶ್ರಮ',
'ಚತುರಾಶ್ರಯ',
'ಚತುರಾಸೀತಿ',
'ಚತುರಾಸ್ಯತೆ',
'ಚತುರಾಸ್ಯತ್ವ',
'ಚತುರಿಂದ್ರಿಯ',
'ಚತುರುಪಾಯ',
'ಚತುರೋಪಾಯ',
'ಚತುರ್ಗುಣಿತ',
'ಚತುರ್ಜಾತಕ',
'ಚತುರ್ಥಧ್ಯಾನ',
'ಚತುರ್ಥಸ್ನಾನ',
'ಚತುರ್ವಿಂಶತಿ',
'ಚತುರ್ಹಾಯಣಿ',
'ಚತುಷ್ಕಷಾಯ',
'ಚತುಸ್ಸಮಯ',
'ಚತುಸ್ಸಾಗರ',
'ಚದರಗಳ್ಳಿ',
'ಚದರಮೈಲು',
'ಚದುರತನ',
'ಚದುರವಚ್ಚ',
'ಚದುರಸಿಕೆ',
'ಚದುರಸಿಗೆ',
'ಚದುರುಗೆಡು',
'ಚದುರುನುಡಿ',
'ಚದುರುಬಿಲ್ಲೆ',
'ಚದುರುವಾತು',
'ಚದುರೆವೆಣ್',
'ಚನ್ನಂಗಿನರಿ',
'ಚನ್ನಂಗಿಬೇಳೆ',
'ಚನ್ನಿಗತನ',
'ಚನ್ನಿಗರಾಯ',
'ಚಪಕಾರಕ',
'ಚಪಗೊಡಲಿ',
'ಚಪಲಚಿತ್ತ',
'ಚಪಲಚಿತ್ತೆ',
'ಚಪಲತನ',
'ಚಪಲವೇಗಿ',
'ಚಪಲಾವೇಗ',
'ಚಪಳತನ',
'ಚಪ್ಪಕೊಡಲಿ',
'ಚಪ್ಪಗೊಡಲಿ',
'ಚಪ್ಪಟಿಕಲ್ಲು',
'ಚಪ್ಪಟೆಕಳ್ಳಿ',
'ಚಪ್ಪಟೆಹಂಬು',
'ಚಪ್ಪಡಿಕಲ್ಲು',
'ಚಪ್ಪಡೆವೆಱು',
'ಚಪ್ಪರದ್ರಾಕ್ಷಿ',
'ಚಪ್ಪರದ್ರಾಕ್ಷೆ',
'ಚಪ್ಪರಮಂಚ',
'ಚಪ್ಪಲಿಚೆಂಡು',
'ಚಪ್ಪಳಿಗೊಳ್',
'ಚಪ್ಪಳೆಗುಟ್ಟು',
'ಚಪ್ಪಳೆಯಿಕ್ಕು',
'ಚಪ್ಪಾಣಿಮುತ್ತು',
'ಚಪ್ಪಾಳೆಯಿಕ್ಕು',
'ಚಬುಕಂಗೆಯ್',
'ಚಬುಕಾಯಿಸು',
'ಚಮಕಾಯಿಸು',
'ಚಮಕಿಬಟ್ಟು',
'ಚಮಚಾಗಿರಿ',
'ಚಮತುಕೃತಿ',
'ಚಮತ್ಕರಿಸು',
'ಚಮತ್ಕಾರಿತ',
'ಚಮರಗಾರ್ತಿ',
'ಚಮರರುಹ',
'ಚಮರಿಮೃಗ',
'ಚಮರೀಮೃಗ',
'ಚಮರೀರುಹ',
'ಚಮೂವಲ್ಲಭ',
'ಚಮ್ಮಣಿವಟ್ಟು',
'ಚಮ್ಮವಟ್ಟಿಗೆ',
'ಚಮ್ಮವಾವುಗೆ',
'ಚಯ್ಯಾಡಂಬರ',
'ಚರಂಡಿತನ',
'ಚರಚರನೆ',
'ಚರಜಿಂದಗಿ',
'ಚರಜಿಂದಿಗಿ',
'ಚರಜಿಂದಿಗೆ',
'ಚರಣಗ್ರಂಥ',
'ಚರಣತಲ',
'ಚರಣತಳ',
'ಚರಣಧೂಳಿ',
'ಚರಣನ್ಯಾಸ',
'ಚರಣವಿಡಿ',
'ಚರಣಹತಿ',
'ಚರಣಹೇತಿ',
'ಚರಣಾಯುಧ',
'ಚರಪುರುಷ',
'ಚರಮಗೀತ',
'ಚರಮಗೀತೆ',
'ಚರಮತನು',
'ಚರಮದೇಹ',
'ಚರಮಧ್ಯಾನ',
'ಚರಮವಾಕ್ಯ',
'ಚರಮಶ್ಲೋಕ',
'ಚರಮಾಕ್ಷರ',
'ಚರಮಾದರ್ಶ',
'ಚರಸೋಪಾನ',
'ಚರಿಗೆಗುಡು',
'ಚರಿಗೆಗೊಡು',
'ಚರಿಗೆದೋಱು',
'ಚರಿಗೆವೊಗು',
'ಚರಿತಾರ್ಥತ್ವ',
'ಚರಿತ್ರಕಾರ',
'ಚರಿತ್ರವಂತ',
'ಚರಿತ್ರವತಿ',
'ಚರ್ಮಘರ್ಘರ',
'ಚರ್ಮಪಟ್ಟಿಕೆ',
'ಚರ್ಮಪಾದುಕ',
'ಚರ್ಮಪಾದುಕೆ',
'ಚರ್ಮಯಷ್ಪಿಕೆ',
'ಚರ್ಮವಾವುಗೆ',
'ಚರ್ಮಸೇವನ',
'ಚರ್ಯಾಧಿಕಾರ',
'ಚಲಚಲಿಸು',
'ಚಲನಚಿತ್ರ',
'ಚಲನಶಕ್ತಿ',
'ಚಲಪ್ರತಿಮೆ',
'ಚಲಮಲಿಸು',
'ಚಲಿಮಿಲಿಸು',
'ಚಲಿವಿಂದರ',
'ಚಲುವತನ',
'ಚಲುವರಾಯ',
'ಚಲುವೆಪುಡಿ',
'ಚಲ್ಲಂಬೆರಸು',
'ಚಲ್ಲಣಂದೆಗೆ',
'ಚಲ್ಲಾಟಂಬೆರು',
'ಚವರಿಕೊಟ್ಟು',
'ಚವರಿಕೊಪ್ಪು',
'ಚವರಿಮಿಗ',
'ಚವರೀಮೃಗ',
'ಚವಲಿತುಂಬು',
'ಚವಳಿಕಾಯಿ',
'ಚವುಕಛತ್ರಿ',
'ಚವುಕವಳಿ',
'ಚವುರಿಹುಲ್ಲು',
'ಚವುಳುಭೂಮಿ',
'ಚವುಳುಮಣ್ಣು',
'ಚಷಕಭಾಂಡ',
'ಚಹಡತನ',
'ಚಳಚಳನೆ',
'ಚಳಚಳಿಸು',
'ಚಳನತಳ',
'ಚಳಪಳನೆ',
'ಚಳಬಳಿಕೆ',
'ಚಳಬಳಿಸು',
'ಚಳಮಿಳನೆ',
'ಚಳಯಂಗುಡು',
'ಚಳಯಂಗೊಡು',
'ಚಳಯಂಬೆಱು',
'ಚಳಯಗೊಡು',
'ಚಳವಳಿಸು',
'ಚಳಿಗದಿರ್',
'ಚಳಿಗದಿರ',
'ಚಳೆಯಂಗುಡು',
'ಚಳೆಯಂಗೆಯ್',
'ಚಳೆಯಂಗೊಡು',
'ಚಳ್ಳೆಕೆಂದಾಳ',
'ಚಾಂಡಾಲಗಿತ್ತಿ',
'ಚಾಕಚಕ್ಯತೆ',
'ಚಾಕರವಾನ',
'ಚಾಕರಿದಾರ',
'ಚಾಕರಿವಾನ',
'ಚಾಟಕತನ',
'ಚಾಟುಕಾರಿಸು',
'ಚಾಟುಭಣಿತಿ',
'ಚಾಟುಲತನ',
'ಚಾಟುವಚನ',
'ಚಾಡಿಬುರಕ',
'ಚಾಣಕ್ಯತನ',
'ಚಾಣಕ್ಯನೀತಿ',
'ಚಾಣಕ್ಯಮೂಲ',
'ಚಾಣಾಕ್ಷತನ',
'ಚಾತುರತನ',
'ಚಾತುರ್ಜಾತಕ',
'ಚಾತುರ್ಭಾಗಾರ್ಧ',
'ಚಾಪಲಾಘವ',
'ಚಾಪಳಮತಿ',
'ಚಾಪಳವೃತ್ತಿ',
'ಚಾಪಾಗಮಜ್ಞ',
'ಚಾಬೂಕಸ್ವಾರ',
'ಚಾಮರಮಿಕ್ಕು',
'ಚಾಮರರೇಖೆ',
'ಚಾಮರವಿಕ್ಕು',
'ಚಾಮರಿಗಿತಿ',
'ಚಾರಣಋದ್ಧಿ',
'ಚಾರಣಕವಿ',
'ಚಾರಣಕ್ರಮ',
'ಚಾರಣಗೀತ',
'ಚಾರಿತ್ರಂಗೆಡು',
'ಚಾರಿತ್ರಗೆಡು',
'ಚಾರಿತ್ರವಂತ',
'ಚಾರಿತ್ರವತಿ',
'ಚಾರಿತ್ರಿಕತೆ',
'ಚಾರಿತ್ರ್ಯವಧೆ',
'ಚಾರುಚರಿತ್ರ',
'ಚಾರುರೂಪಿಣಿ',
'ಚಾರುಹಾಸಿನಿ',
'ಚಾರ್ವಾಕಮತ',
'ಚಾರ್ವಾಕವಾದ',
'ಚಾಲತಿಖಾತೆ',
'ಚಾಲತಿಠೇವು',
'ಚಾಲತಿಮಾಲು',
'ಚಾಲತಿಸಾಲು',
'ಚಾಲಾಕಿತನ',
'ಚಾವಡಿಕಾರ',
'ಚಾವಡಿವನೆ',
'ಚಾಳಿಸಪತ್ರಿ',
'ಚಾಳೀಸಪತ್ರೆ',
'ಚಿಂತನಪರ',
'ಚಿಂತನಶೀಲ',
'ಚಿಂತನಶೀಲೆ',
'ಚಿಂತನೆಗೊಳು',
'ಚಿಂತಾಕುಲತೆ',
'ಚಿಂತಾಕುಲಿತ',
'ಚಿಂತಾಕುಲಿತೆ',
'ಚಿಂತಾಕುಳತೆ',
'ಚಿಂತಾಪರತೆ',
'ಚಿಂತಾಮಗ್ನತೆ',
'ಚಿಕಟಾಯಿಸು',
'ಚಿಕಿತ್ಸಾಕ್ರಮ',
'ಚಿಕಿತ್ಸಾಲಯ',
'ಚಿಕ್ಕಕಲ್ಲುರಿ',
'ಚಿಕ್ಕಕವಲಿ',
'ಚಿಕ್ಕಕಾಡತ್ತಿ',
'ಚಿಕ್ಕಕಾವಲಿ',
'ಚಿಕ್ಕಣಪಾಕ',
'ಚಿಕ್ಕತೂಬರ',
'ಚಿಕ್ಕತೊಗರಿ',
'ಚಿಕ್ಕನೇರಳೆ',
'ಚಿಕ್ಕನೇರಿಲು',
'ಚಿಕ್ಕನೇಱಿಲ್',
'ಚಿಕ್ಕಮರುಳು',
'ಚಿಕ್ಕಸಿವನ್ನಿ',
'ಚಿಕ್ಕಸುರಟಿ',
'ಚಿಕ್ಲೀತಾಸೊಪ್ಪು',
'ಚಿಗಟಾಯಿಸು',
'ಚಿಗರಿಗಣ್',
'ಚಿಗರಿಗಣ್ಣು',
'ಚಿಗರೆಗಣ್ಣು',
'ಚಿಗುರುದೋಱು',
'ಚಿಗುರುಪ್ರಾಯ',
'ಚಿಗುರುಬೋಣಿ',
'ಚಿಗುರುಮೀಸೆ',
'ಚಿಗುಳಿದುಳಿ',
'ಚಿಗುಳಿದುೞಿ',
'ಚಿಟಕಿಹಂಬು',
'ಚಿಟಕಿಹೊಡೆ',
'ಚಿಟಕುದೆಗೆ',
'ಚಿಟಕುಮುಳ್ಳು',
'ಚಿಟಕೊಳಗು',
'ಚಿಟಣವೀಸ',
'ಚಿಟಬರಿಸು',
'ಚಿಟಿಕಿವಾಲೆ',
'ಚಿಟಿಕಿಹಾಕು',
'ಚಿಟಿಕೆಗುಂಡಿ',
'ಚಿಟಿಕೆಗುಬ್ಬಿ',
'ಚಿಟಿಕೆತಾಳ',
'ಚಿಟಿಕೆಹಾಕು',
'ಚಿಟಿಕೆಹೊಡೆ',
'ಚಿಟಿಕೊಳಗು',
'ಚಿಟಿಗೊಳಗು',
'ಚಿಟುಕುಗಳೆ',
'ಚಿಟುಕುಮುರಿ',
'ಚಿಟುಕುಮುಳ್ಳು',
'ಚಿಟುಕುವನಿ',
'ಚಿಟುಕುವಾರೆ',
'ಚಿಟುಗುಮುಳ್ಳು',
'ಚಿಟುಚಟಕ',
'ಚಿಟ್ಟನಬೇರು',
'ಚಿಟ್ಟವುಡಲ',
'ಚಿಟ್ಟಹಲಗೆ',
'ಚಿಟ್ಟುಹರಳು',
'ಚಿಟ್ಟುಹಿಡಿಸು',
'ಚಿತ್ತಂಗೊಳಿಸು',
'ಚಿತ್ತಚಾಂಚಲ್ಯ',
'ಚಿತ್ತಜಕೇಳಿ',
'ಚಿತ್ತಜಾಂತಕ',
'ಚಿತ್ತನಿರೋಧ',
'ಚಿತ್ತಪಲ್ಲಟ',
'ಚಿತ್ತಭ್ರಮಣೆ',
'ಚಿತ್ತಮುರಕ',
'ಚಿತ್ತಮೋಹನ',
'ಚಿತ್ತರಗಂಬ',
'ಚಿತ್ತವಿಕಲ',
'ಚಿತ್ತವಿಕಲೆ',
'ಚಿತ್ತವಿಕೃತಿ',
'ಚಿತ್ತವಿಕ್ಷೇಪ',
'ಚಿತ್ತವಿಭ್ರಮ',
'ಚಿತ್ತವಿಶುದ್ಧಿ',
'ಚಿತ್ತವಿಹ್ವಲ',
'ಚಿತ್ತವಿಹ್ವಲೆ',
'ಚಿತ್ತವೈಕಲ್ಯ',
'ಚಿತ್ತಸಂಭವ',
'ಚಿತ್ತಸ್ಖಲನೆ',
'ಚಿತ್ತಾಕರ್ಷಕ',
'ಚಿತ್ತಾರಿವಿದ್ಯೆ',
'ಚಿತ್ತಾವಧಾನ',
'ಚಿತ್ತೈಕಾಗ್ರತೆ',
'ಚಿತ್ರಕವಿತ್ವ',
'ಚಿತ್ರಕಶಕ್ತಿ',
'ಚಿತ್ರಗೆಲಸ',
'ಚಿತ್ರಗೆಲಸಿ',
'ಚಿತ್ರಚರಿತ',
'ಚಿತ್ರಚರಿತ್ರ',
'ಚಿತ್ರತಂಡುಲೆ',
'ಚಿತ್ರಪಟ್ಟಿಕೆ',
'ಚಿತ್ರಪುತ್ತಳಿ',
'ಚಿತ್ರಪುತ್ರಿಕೆ',
'ಚಿತ್ರಪ್ರಪಂಚ',
'ಚಿತ್ರಪ್ರಬಂಧ',
'ಚಿತ್ರಫಲಕ',
'ಚಿತ್ರಬಲ್ಲಾರೆ',
'ಚಿತ್ರಮಂಜರಿ',
'ಚಿತ್ರಮಂದಿರ',
'ಚಿತ್ರಮೂಲಿಕೆ',
'ಚಿತ್ರಲತಿಕೆ',
'ಚಿತ್ರವಿಚಿತ್ರ',
'ಚಿತ್ರವಿಹಂಗ',
'ಚಿತ್ರವಿಹಗ',
'ಚಿತ್ರಶಿಖಂಡಿ',
'ಚಿತ್ರಸಂಪುಟ',
'ಚಿತ್ರಾಯತನ',
'ಚಿತ್ರಾಲಂಕಾರ',
'ಚಿದಕವರೆ',
'ಚಿನಕಡಿಗೈ',
'ಚಿನಕುರಳಿ',
'ಚಿನಡಿಸೀರೆ',
'ಚಿನವರದ',
'ಚಿನಿಕಹಳೆ',
'ಚಿನಿಖಂಡಗೈ',
'ಚಿನಿವರದ',
'ಚಿನಿವಾರ್ದಿಕೆ',
'ಚಿನುಕುರಳಿ',
'ಚಿನ್ನ ಅಗಿಲು',
'ಚಿನ್ನಗೆಯಿಕ',
'ಚಿನ್ನಗೆಲಸ',
'ಚಿನ್ನಗೇದಗೆ',
'ಚಿನ್ನದಾವರೆ',
'ಚಿನ್ನನೇರಿಳೆ',
'ಚಿನ್ನನೇಱಿಲ್',
'ಚಿನ್ನಲಾಮಜ್ಜ',
'ಚಿನ್ನವರದ',
'ಚಿನ್ನವಲಗೆ',
'ಚಿನ್ನಸಕ್ಕರೆ',
'ಚಿಪುಲಿಮುಳ್ಳು',
'ಚಿಪ್ಪಳೇಱಿಸು',
'ಚಿಬುಕುಗುಟ್ಟು',
'ಚಿಮಣಿ ಎಣ್ಣೆ',
'ಚಿಮಿಚಿಮಿಸು',
'ಚಿರಂತಹಿಂಸೆ',
'ಚಿರಕಾಲಿಕ',
'ಚಿರಜೀವಿತೆ',
'ಚಿರತೆ ಬೆಕ್ಕು',
'ಚಿರನವತೆ',
'ಚಿರನವೀನ',
'ಚಿರನಿಮಿಷ',
'ಚಿರನೂತನ',
'ಚಿರಮುತ್ತೈದೆ',
'ಚಿರವಿಚ್ಛೇದ',
'ಚಿರಸ್ಥಾಯಿಸು',
'ಚಿರಸ್ಮರಣೆ',
'ಚಿರಾಯುಷ್ಮತಿ',
'ಚಿಲಕಧೂಪ',
'ಚಿಲಕುರುಳಿ',
'ಚಿಲಿಮಿಲಿಗ',
'ಚಿಲಿಮಿಲಿಸು',
'ಚಿಲುಕವರೆ',
'ಚಿಲುಮಿಲಿಗ',
'ಚಿಲ್ಕುಹರಿವೆ',
'ಚಿಲ್ಲರೆಕರ್ಚು',
'ಚಿಲ್ಲರೆಖರ್ಚು',
'ಚಿಲ್ಲರೆಜನ',
'ಚಿಲ್ಲರೆಧಾನ್ಯ',
'ಚಿಲ್ಲರೆಮಾತು',
'ಚಿಲ್ಲರೆವೆಚ್ಚ',
'ಚಿವರುಗಾಯ',
'ಚಿವುರುಗಾಯ',
'ಚೀಕರಿವೋಗು',
'ಚೀನಗರ್ಪುರ',
'ಚೀನವಸನ',
'ಚೀನಿಕಪ್ಪುರ',
'ಚೀನಿಕರ್ಪೂರ',
'ಚೀನಿಕೊಳವೆ',
'ಚೀನೋತ್ತರೀಯ',
'ಚೀಬೀರಸೊಪ್ಪು',
'ಚೀರಿಕಾರುತ',
'ಚೀಲಣೆಗೆಯ್',
'ಚುಂಗಡಿಲಾಭ',
'ಚುಂಗಡಿಹಣ',
'ಚುಂಗುವಾಸನೆ',
'ಚುಂಚುಱುದಲೆ',
'ಚುಂಬಕಗಾಳಿ',
'ಚುಂಬನಗೊಡು',
'ಚುಕ್ಕಾಣಿಚಕ್ರ',
'ಚುಕ್ಕಿಗುವರ',
'ಚುಚ್ಚುಕಳಿಲೆ',
'ಚುಚ್ಚುಕೂದಲು',
'ಚುಚ್ಚುಗೊಂಡೆಯ',
'ಚುಟಿಕೆಹಾಕು',
'ಚುನಾಯಿತಾಣ',
'ಚುರಚುರನೆ',
'ಚುರುಕುತನ',
'ಚುರುಚುರಕೆ',
'ಚುರುಚುರೆನ್ನು',
'ಚುರುಳೊಮುದು',
'ಚುಲಕತನ',
'ಚುಲುಕುಗಾಣು',
'ಚುಲುಕುತನ',
'ಚುಲುಕೋದಕ',
'ಚುಳಕಪಾನ',
'ಚುಳಕುತನ',
'ಚುಳುಕೋದಕ',
'ಚುಱುಚುಱಿಗ',
'ಚೂಡಾಕರಣ',
'ಚೂಣಿಗಾಳಗ',
'ಚೂಣಿಗಾಳೆಗ',
'ಚೂಪುಗಾರಿಕೆ',
'ಚೂಪುಗೊಳಿಸು',
'ಚೂರ್ಚಗೊಳಿಸು',
'ಚೂರ್ಣಕುಂತಲ',
'ಚೂರ್ಣಕುಂತಳ',
'ಚೂರ್ಣೀಕರಿಸು',
'ಚೂಳಾಕರಣ',
'ಚೂಳಿಗಲಹ',
'ಚೆಂಗಣಗಿಲೆ',
'ಚೆಂಗಣಿಗಲು',
'ಚೆಂಗಣಿಗಿಲ್',
'ಚೆಂಗಣಿಗಿಲು',
'ಚೆಂಗಣಿಗಿಲೆ',
'ಚೆಂಡಕನರಿ',
'ಚೆಂಡಾಟಗಾರ',
'ಚೆಂಡುಮಲ್ಲಿಗೆ',
'ಚೆಂದಕಾಣಿಸು',
'ಚೆಂದವಗಲ್',
'ಚೆಂಬಕವಱೆ',
'ಚೆಂಬುಕುಟಿಗ',
'ಚೆಂಬುಳುಕಾಯಿ',
'ಚೆಕ್ಕುಲಗುಲಿ',
'ಚೆಟ್ಟಬೆಳಗು',
'ಚೆನ್ನಭಾಷಿತ',
'ಚೆನ್ನಿಗತನ',
'ಚೆನ್ನಿಗರಾಯ',
'ಚೆಪ್ಪಗೊಡಲಿ',
'ಚೆಮ್ಮಣಿವಟ್ಟು',
'ಚೆಲುವುತನ',
'ಚೆಲ್ಲಂಬೆರಸು',
'ಚೆಲ್ವಿಕೆದಾಳು',
'ಚೆಲ್ವೊಡರಿಸು',
'ಚೇತನಂಗುಂದು',
'ಚೇತನಂಗೆಡು',
'ಚೇತನಂಬಡೆ',
'ಚೇತನಗಿಡು',
'ಚೇತನಗುಂದು',
'ಚೇತನದಪ್ಪು',
'ಚೇತನಧರ್ಮ',
'ಚೇತನಭಾವ',
'ಚೇತನವಡೆ',
'ಚೇತನೆಗೊಳ್',
'ಚೇತನೆವಡೆ',
'ಚೇತೋನಂದನ',
'ಚೇಪರಿಸಿನ',
'ಚೇರುನಾರಂಗ',
'ಚೇಳುರಿಗಿಡ',
'ಚೈತನ್ಯಂಬಡೆ',
'ಚೈತನ್ಯಗೆಡು',
'ಚೈತನ್ಯದಾಯಿ',
'ಚೈತನ್ಯಭಾವಿ',
'ಚೈತನ್ಯವಡೆ',
'ಚೈತನ್ಯವಾದ',
'ಚೈತನ್ಯಾತುಮ',
'ಚೈತ್ಯಪುರುಷ',
'ಚೈತ್ಯಾವಸಥ',
'ಚೈತ್ರ್ರಪವಿತ್ರ',
'ಚೊಕ್ಕಟಂಬಡೆ',
'ಚೊಕ್ಕಳಕಾತಿ',
'ಚೊಕ್ಕಳತನ',
'ಚೊಕ್ಕೆಯತನ',
'ಚೊಟ್ಯಾಲುಗುಬ್ಬಿ',
'ಚೊಲ್ಲೆಯಂಗೆಯ್',
'ಚೊಲ್ಲೆಹಗಾರ',
'ಚೋಜಿಗಂಬಡು',
'ಚೋದನಾತ್ಮಕ',
'ಚೋದಹಲಸು',
'ಚೋದಿಗಂಬಡು',
'ಚೋದಿಗಗೊಳ್',
'ಚೋದಿಗವಡು',
'ಚೋರಕಂಡಿಕ',
'ಚೋರಕವೃತ್ತಿ',
'ಚೋರಖಂಡಿಕ',
'ಚೋರಪಹರೆ',
'ಚೋರಮಾಳಿಗೆ',
'ಚೋರುಪಹರೆ',
'ಚೌಕಸತ್ತಿಗೆ',
'ಚೌಪಟಂಬರಿ',
'ಚೌಪಟಮಲ್ಲ',
'ಚೌಪದುಳಿಗ',
'ಚೌರಂಗದಳ',
'ಚೌರಬೊಂಬಾಳ',
'ಚೌರಿಕುಪ್ಪಿಗೆ',
'ಚೌರ್ಯವ್ಯಸನಿ',
'ಚೌವಟಂಬರಿ',
'ಚೌವಟಮಲ್ಲ',
'ಚೌಷಷ್ಟಿಕಲೆ',
'ಚೌಷಷ್ಟಿಕಳೆ',
'ಚೌಷಷ್ಟಿವಿದ್ಯೆ',
'ಚ್ಯುತಸಂಸ್ಕಾರ',
'ಛಂದೋವತಂಸ',
'ಛಂದೋವಿಚ್ಛಿತ್ತಿ',
'ಛಟಛಟನೆ',
'ಛಟಛಟಿಸು',
'ಛಡಸವಾಲು',
'ಛಢಾಳತನ',
'ಛಢಾಳನೋಟ',
'ಛತ್ರಧಾರಕ',
'ಛತ್ರವಳಯ',
'ಛದ್ಮಬಾಲಿಶ',
'ಛದ್ಮಸ್ಥಕಾಲ',
'ಛಪ್ಪಳೆಯಿಡು',
'ಛಲಗ್ರಾಹಕ',
'ಛಲಚೇತನ',
'ಛವಿಕಲ್ಯಾಣ',
'ಛಳಛಳಿಸು',
'ಛಳೆಯಂಗೊಡು',
'ಛಾತ್ರಪಾಲಕ',
'ಛಾನಸಗಾರ',
'ಛಾನಸತನ',
'ಛಾಪಾಕಾಗದ',
'ಛಾಪೆಕಾಗದ',
'ಛಾಯಗಾರಿಕೆ',
'ಛಾಯಾಕರಣ',
'ಛಾಯಾಗ್ರಹಣ',
'ಛಾಯಾಗ್ರಾಹಕ',
'ಛಾಯಾಗ್ರಾಹಕಿ',
'ಛಾಯಾದ್ವಿತೀಯ',
'ಛಾಯಾನಾಟಕ',
'ಛಾಯಾನುವರ್ತಿ',
'ಛಾಯಾನುವಾದ',
'ಛಾಯಾಲಕ್ಷಣ',
'ಛಾಯಾಶರೀರ',
'ಛಾಯೆಗಾಣಿಸು',
'ಛಾವಣಿಹೊಡೆ',
'ಛಿದ್ರಕದ್ವಾರ',
'ಛಿದ್ರಗೊಳಿಸು',
'ಛಿದ್ರನಿವಾಸ',
'ಛಿದ್ರಪಾಷಾಣ',
'ಛಿದ್ರಾನ್ವೇಷಣ',
'ಛಿಳಿಛಿಟಿಲ್',
'ಛೇಕಾನುಪ್ರಾಸ',
'ಛೇಕಾಪಹ್ನುತಿ',
'ಜಂಗಮಗಿತ್ತಿ',
'ಜಂಗಮಲಿಂಗ',
'ಜಂಗಮವಾಡಿ',
'ಜಂಗಮವಿಷ',
'ಜಂಗಮೇತರ',
'ಜಂಗಲಿಜ್ವರ',
'ಜಂಗಳಂಬರಿ',
'ಜಂಗೀಲಢಾಯಿ',
'ಜಂಗುಳಿಜಸ',
'ಜಂಗುಳಿಮೋಹಿ',
'ಜಂಘಚಾರಣ',
'ಜಂಘಾಕರಿಕ',
'ಜಂಘಾಲಂಘಿತ',
'ಜಂಘಾಳತನ',
'ಜಂಜರಬಂದಿ',
'ಜಂಝಾಮಾರುತ',
'ಜಂಟಿವರಸೆ',
'ಜಂತವಣಿಗೆ',
'ಜಂತೆವಲಗೆ',
'ಜಂತ್ರಗೊಳಿಸು',
'ಜಂತ್ರದೇವತೆ',
'ಜಂಪತಿವಕ್ಕಿ',
'ಜಂಪಳವಿಡು',
'ಜಂಬುನೇರಳೆ',
'ಜಂಬುನೇರಿಲು',
'ಜಂಬುನೇರಿಲೆ',
'ಜಂಬುನೇಱಿಲೆ',
'ಜಂಬೂಸವಾರಿ',
'ಜಕಜಕನೆ',
'ಜಕಾತಿಕಟ್ಟಿ',
'ಜಕಾತಿದಾರ',
'ಜಕ್ಕಂದೊಳಲಿ',
'ಜಕ್ಕಂದೊೞಲಿ',
'ಜಕ್ಕಜವ್ವನ',
'ಜಕ್ಕಣಿತಾಳ',
'ಜಕ್ಕಿಕೊಳಗ',
'ಜಕ್ಕುಲಿಗಾರೆ',
'ಜಕ್ಕುಳಿಮಾತು',
'ಜಗಜಗನೆ',
'ಜಗಜಗಿಸು',
'ಜಗಜ್ಜಾಹೀರ',
'ಜಗಜ್ಜಾಹೀರು',
'ಜಗತೀತಲ',
'ಜಗತೀತಳ',
'ಜಗತೀಧರ',
'ಜಗತ್ಪಾವನ',
'ಜಗದಂಬಕ',
'ಜಗದಾರಾಧ್ಯ',
'ಜಗದಿಂಡೆಯ',
'ಜಗದುದರ',
'ಜಗದ್ವಲಯ',
'ಜಗದ್ವಳಯ',
'ಜಗದ್ವ್ಯಾಪಾರ',
'ಜಗನುಮಯ',
'ಜಗನುಮಾತೆ',
'ಜಗನ್ನಯನ',
'ಜಗನ್ಮಂಡನ',
'ಜಗಮಗನೆ',
'ಜಗಮಗಿಸು',
'ಜಗಲಿಗಟ್ಟು',
'ಜಗಳಕೋರ',
'ಜಗಳಗಂಟ',
'ಜಗಳಗಂಟಿ',
'ಜಗಳಗಾತಿ',
'ಜಗೞಂಬೊಗು',
'ಜಗೞಗಂಟ',
'ಜಗೞಗಂಟಿ',
'ಜಗೞವಂತ',
'ಜಗುಲಿಕಟ್ಟೆ',
'ಜಗುಳುದಲೆ',
'ಜಗುಳುನುಡಿ',
'ಜಘನಫೇಲೆ',
'ಜಘನ್ಯಪಾತ್ರ',
'ಜಘುನೇಫತಿ',
'ಜಜ್ಜಾರತನ',
'ಜಜ್ಝಾರತನ',
'ಜಟಕರಿಸು',
'ಜಟಕಾಗಾಡಿ',
'ಜಟಕಾಬಂಡಿ',
'ಜಟಕಾಯಿಸು',
'ಜಟಕಾಹೊಡೆ',
'ಜಟಪಟನೆ',
'ಜಟಮಟಿಕ',
'ಜಟಮಟಿಗ',
'ಜಟಮಟಿಸು',
'ಜಟಾಕಲಾಪ',
'ಜಟಾಖರ್ಜೂರ',
'ಜಟಾಮುಕುಟ',
'ಜಟಿಗಮನೆ',
'ಜಟ್ಟಿಕಾಳಗ',
'ಜಟ್ಟಿಗತನ',
'ಜಟ್ಟಿಗರುಡಿ',
'ಜಠರರಸ',
'ಜಠರವಹ್ನಿ',
'ಜಡಜಂಗಮ',
'ಜಡಜಗರ್ಭ',
'ಜಡಜೀವನ',
'ಜಡಪ್ರಕೃತಿ',
'ಜಡಪ್ರಪಂಚ',
'ಜಡಭರತ',
'ಜಡಹೃದಯ',
'ಜಡಾಕರತ್ವ',
'ಜಡಿಗೊರಡು',
'ಜಡೆಕಾಳಿಂಗ',
'ಜಡೆಕೋಲಾಟ',
'ಜಡೆದಲೆಯ',
'ಜಡೆದುಱುಂಬು',
'ಜಡೆದೇವರು',
'ಜಡೆನಾಗರ',
'ಜಡೆಬಂಗಾರ',
'ಜಡೆಬಸರಿ',
'ಜಡೆಯಡ್ಡಿಕೆ',
'ಜಡೆಹೊಲಿಗೆ',
'ಜಡ್ಡುಗಟ್ಟಿಸು',
'ಜಣಜಣಿಸು',
'ಜಣತ್ಕರಿಸು',
'ಜತನಗೆಯ್',
'ಜತನದಪ್ಪು',
'ಜತನಪೊದ್ದು',
'ಜತನವಂತ',
'ಜತಿವಗೆಯ',
'ಜತ್ತಕುಱಾಡು',
'ಜತ್ತುಕುಱಾಡು',
'ಜನಕರಾಗ',
'ಜನಕುಕ್ಕಲು',
'ಜನಗಣತಿ',
'ಜನಗಣನೆ',
'ಜನಜಂಗುಳಿ',
'ಜನಜನಿತ',
'ಜನಜಾಗೃತಿ',
'ಜನನಗ್ರಂಥಿ',
'ಜನನಬೀಜ',
'ಜನನಹರ',
'ಜನನಿಬಿಡ',
'ಜನನೇಂದ್ರಿಯ',
'ಜನಪತನ',
'ಜನಪ್ರಿಯತೆ',
'ಜನಬಳಕೆ',
'ಜನಭರಿತ',
'ಜನರಂಜನ',
'ಜನರಂಜನಿ',
'ಜನರಂಜನೆ',
'ಜನಸಂದಣಿ',
'ಜನಸಾಂದ್ರತೆ',
'ಜನಸೂಡಿಗ',
'ಜನಹರಲೆ',
'ಜನಾಂಗೀಯತೆ',
'ಜನಾನಖಾನೆ',
'ಜನಾನಾಖಾನೆ',
'ಜನಾನುರಾಗ',
'ಜನಾನುರಾಗಿ',
'ಜನಾಪವಾದ',
'ಜನೋಪಕಾರ',
'ಜನೋಪಕಾರಿ',
'ಜನ್ನಗುದಿರೆ',
'ಜನ್ನಗುದುರೆ',
'ಜನ್ನವಗೆಯ',
'ಜನ್ನಿಗೆಯಾವು',
'ಜನ್ಮಕಲ್ಯಾಣ',
'ಜನ್ಮಕುಂಡಲಿ',
'ಜನ್ಮಧಾರಣೆ',
'ಜನ್ಮನಕ್ಷತ್ರ',
'ಜನ್ಮನಿವೃತ್ತಿ',
'ಜನ್ಮ ಪತ್ರಿಕೆ',
'ಜನ್ಮ ಶತಾಬ್ಧಿ',
'ಜನ್ಮಸವನ',
'ಜನ್ಮಸಾಫಲ್ಯ',
'ಜನ್ಮಸಾರ್ಥಕ',
'ಜನ್ಮಸಾರ್ಥಕ್ಯ',
'ಜನ್ಮಾಂತರೀಯ',
'ಜನ್ಮಾನುಬಂಧಿ',
'ಜನ್ಮಾಭಿಷವ',
'ಜನ್ಮಾಭಿಷೇಕ',
'ಜಪತಾವಾಡ',
'ಜಪಮಾಲಿಕೆ',
'ಜಪಾಕುಸುಮ',
'ಜಪಾಕ್ಷಿತಿಜ',
'ಜಪಾನುಷ್ಠಾನ',
'ಜಪಾಪ್ರಸವ',
'ಜಪಾಪ್ರಸೂನ',
'ಜಬರಜಂಗಿ',
'ಜಬರಜಂಗು',
'ಜಬರದಸ್ತ್',
'ಜಬರದಸ್ತಿ',
'ಜಬರದಸ್ತು',
'ಜಬರುಜಂಗಿ',
'ಜಬರುಜಂಗು',
'ಜಬ್ಬಲು ಬೀಳು',
'ಜಭರಜಂಗಿ',
'ಜಮಕಾಯಿಸು',
'ಜಮೀನದಾರ',
'ಜಮೀನುದಾರ',
'ಜಮೀನ್ದಾರಿಣಿ',
'ಜಯನಶಾಲೆ',
'ಜಯನಸಾಲೆ',
'ಜರಗುಗರ್ಚು',
'ಜರಟಿಹುಲ್ಲು',
'ಜರಡಿಹುಣ್ಣು',
'ಜರಿವೊನಲ್',
'ಜರೀಪಟಕ',
'ಜರೀಬುಗಾರ',
'ಜರುಗುಪಟ್ಟಿ',
'ಜರುಗುಪೀಠ',
'ಜಲಕಂಟಕ',
'ಜಲಕಣಿಕೆ',
'ಜಲಕನ್ನಿಕೆ',
'ಜಲಕನ್ಯಕೆ',
'ಜಲಖಾತಿಕೆ',
'ಜಲಗಾಡಿಸು',
'ಜಲಗಾರಿಕೆ',
'ಜಲಚಿಕಿತ್ಸೆ',
'ಜಲಜಗಂಧಿ',
'ಜಲಜನಕ',
'ಜಲಜಲಿಸು',
'ಜಲಜಶಿಲೆ',
'ಜಲತರಂಗ',
'ಜಲತರ್ಪಣ',
'ಜಲದಮಾರ್ಗ',
'ಜಲದವರ್ಣ',
'ಜಲದಾಗಮ',
'ಜಲದೇವತೆ',
'ಜಲನೀಲಿಕೆ',
'ಜಲಪದ್ಧತಿ',
'ಜಲಪಿಪ್ಪಲಿ',
'ಜಲಪುರುಷ',
'ಜಲಪುಷ್ಪಿಕೆ',
'ಜಲಪೊಟ್ಟಣ',
'ಜಲಪ್ರಣಾಲ',
'ಜಲಪ್ರಲಯ',
'ಜಲಪ್ರಳಯ',
'ಜಲಪ್ರಾಂಗಣ',
'ಜಲಭೃಚ್ಚಾಪ',
'ಜಲಮಾನುಷಿ',
'ಜಲಮಾಲಿನ್ಯ',
'ಜಲವಸತಿ',
'ಜಲವಾಯಸ',
'ಜಲವಿಕಳ',
'ಜಲವಿದ್ಯುತ್ತು',
'ಜಲವಿಹಂಗ',
'ಜಲಶೀಕರ',
'ಜಲಶೂಲಕ',
'ಜಲಸಮಾಧಿ',
'ಜಲಸೇಚನ',
'ಜಲಸ್ತ್ತಂಭನ',
'ಜಲಹಿಪ್ಪಲಿ',
'ಜಲಾಂತರ್ಗಾಮಿ',
'ಜಲಾಂತರ್ನೌಕೆ',
'ಜಲಾಧಿವಾಸ',
'ಜಲಾನಯನ',
'ಜಲಾಯತಾಸ್ತ್ರ',
'ಜಲೋದ್ಧರತ',
'ಜಲ್ಲಿಕಾಗದ',
'ಜವಂಗಿಡಿಸು',
'ಜವಂಗುಂದಿಸು',
'ಜವಗಿಡಿಸು',
'ಜವಗೆಡಿಸು',
'ಜವಜವನೆ',
'ಜವಜವಾಗು',
'ಜವತ್ಸವಡಿ',
'ಜವರುಜಂಗಿ',
'ಜವವಳಿಸು',
'ಜವವೊಳಲ್',
'ಜವಳಂಗೊಳ್',
'ಜವಳಿಕಾಯಿ',
'ಜವಳಿಗಾರ',
'ಜವಳಿಗದ',
'ಜವಳಿಗೊಳ್',
'ಜವಳಿದೆಗೆ',
'ಜವಳಿನುಡಿ',
'ಜವಳಿಪಡಿ',
'ಜವಳಿವಡಿ',
'ಜವಳಿವಾತು',
'ಜವಳಿವಿಡಿ',
'ಜವಾಜಿಮಿಗ',
'ಜವಾದಿಮಿಗ',
'ಜವಾಬದಾರ',
'ಜವಾಬದಾರಿ',
'ಜವಾಬುದಾರ',
'ಜವಾಬುದಾರಿ',
'ಜವಾಬ್ದಾರಿಕೆ',
'ಜವಾಯ್ಲತನ',
'ಜವಾರಿಮಂದಿ',
'ಜವುಗೇೞಿಸು',
'ಜವ್ವನಿಗಿತಿ',
'ಜಹಲ್ಲಕ್ಷಣೆ',
'ಜಳಕಂಬೊಗು',
'ಜಳಜಳಿಸು',
'ಜಳದಾಗಮ',
'ಜಳದೇವತೆ',
'ಜಳನೀಳಿಕೆ',
'ಜಳಿಕತನ',
'ಜಳ್ಳುಜರಗು',
'ಜಾಗತಿಕತೆ',
'ಜಾಗರೂಕತೆ',
'ಜಾಗರೂಕತ್ವ',
'ಜಾಗವರಲ್',
'ಜಾಗೀರದಾರ',
'ಜಾಗೀರುದಾರ',
'ಜಾಗೃತಗೊಳ್ಳು',
'ಜಾಗ್ರಗಾವಲು',
'ಜಾಗ್ರತವಿಡು',
'ಜಾಗ್ರತೆಗೊಳ್ಳು',
'ಜಾಜಿಮಲ್ಲಿಗೆ',
'ಜಾಜ್ವಲ್ಯಮಾನ',
'ಜಾಡಣಿಹೊಡೆ',
'ಜಾಡಿಗೆದಱು',
'ಜಾಣಕಿವುಡು',
'ಜಾಣಗಿವುಡು',
'ಜಾಣಸೇಕರ',
'ಜಾಣಾಯ್ಲತನ',
'ಜಾಣ್ಣುಡಿಕಾರ್ತಿ',
'ಜಾಣ್ಣುಡಿಕಾಱ',
'ಜಾಣ್ನುಡಿಗಾತಿ',
'ಜಾಣ್ನುಡಿಗಾರ',
'ಜಾತನಿರ್ವೇದ',
'ಜಾತವೇದಸ',
'ಜಾತಸೂತಕ',
'ಜಾತಿಜಾಣಿಕೆ',
'ಜಾತಿಪದ್ಧತಿ',
'ಜಾತಿಭ್ರಂಶಕ',
'ಜಾತಿಭ್ರಷ್ಟತೆ',
'ಜಾತಿವಾಚಕ',
'ಜಾತಿಸಂಕರ',
'ಜಾತಿಸೂತಕ',
'ಜಾತಿಸ್ಮರತೆ',
'ಜಾತಿಸ್ಮರತ್ವ',
'ಜಾತಿಹಗಿನು',
'ಜಾತ್ಯತೀತತೆ',
'ಜಾದೂಗಾರಿಕೆ',
'ಜಾನಪದಿಕ',
'ಜಾನಪದೀಯ',
'ಜಾನುಮಂಜರಿ',
'ಜಾಪಾಳಮಾತ್ರೆ',
'ಜಾಮೀನುದಾರ',
'ಜಾಮೀನುಪತ್ರ',
'ಜಾಯಜಖಮಿ',
'ಜಾರಮಜಟ್ಟೆ',
'ಜಾರವನಿತೆ',
'ಜಾರಿಇನಾಂತಿ',
'ಜಾರು ಕವಾಟ',
'ಜಾರುಗೊರಡು',
'ಜಾರುನಡಿಗೆ',
'ಜಾರುಹೊಡೆತ',
'ಜಾಲಪಟಲ',
'ಜಾಲರಿತಾಳ',
'ಜಾಲಿಜಪ್ಪಟೆ',
'ಜಾವಗಹಳೆ',
'ಜಾವಗುತ್ತಿಗೆ',
'ಜಾವಡಿಕಾರ',
'ಜಾವನಿಗಾಲೆ',
'ಜಾವಿಗೆಬತ್ತಿ',
'ಜಾಸ್ತಿಮಲ್ಲಿಗೆ',
'ಜಾಹೀರನಾಮೆ',
'ಜಾಹೀರುನಾಮೆ',
'ಜಾಹೀರುಮಾಡು',
'ಜಾಳಂದ್ರಗೆಯ್',
'ಜಾಳರಂಬೋಗು',
'ಜಾಳುಗೊಳಿಸು',
'ಜಾಳುಮನುಜ',
'ಜಿಂಜಿತಂಗೆಯ್',
'ಜಿಗಟುಮಣ್ಣು',
'ಜಿಗುಟುತನ',
'ಜಿಗುಪ್ಸೆಪಡು',
'ಜಿಗುಳಿಗಂಟು',
'ಜಿಗುಳುಗಂಟು',
'ಜಿತತ್ರಿತಾಪ',
'ಜಿತಪಡಿಸು',
'ಜಿತಮನಸ್ಕ',
'ಜಿತಮನಸ್ಕೆ',
'ಜಿತಸಂಸಾರಿ',
'ಜಿತೇಂದ್ರಿಯತ್ವ',
'ಜಿದ್ದುತೀರಿಸು',
'ಜಿನಗಹಳೆ',
'ಜಿನಶಾಸನ',
'ಜಿನಸಮಯ',
'ಜಿನಸಮಯಿ',
'ಜಿನುಗುಮತಿ',
'ಜಿನುಗುವಳೆ',
'ಜಿನುಗುವಾತು',
'ಜಿನುಮಿನುಗು',
'ಜಿಪುಣತನ',
'ಜಿಬಡುತನ',
'ಜಿಲಿಬಿಲಿಗ',
'ಜಿಲೀಬಿದಾರ',
'ಜಿಲ್ಲಾಧಿಕಾರಿ',
'ಜಿಲ್ಲಾಮಂಡಲಿ',
'ಜಿವುಳಿದುೞಿ',
'ಜಿಹ್ವಾಚಾಪಲ್ಯ',
'ಜಿಹ್ವಾಪ್ರತ್ಯಕ್ಷ',
'ಜಿಹ್ವಾಮೂಲೀಯ',
'ಜೀನಗೆಲಸ',
'ಜೀಮೂತಪಥ',
'ಜೀರಿಗೆಬೆಲ್ಲ',
'ಜೀರಿಗೆಮಾವು',
'ಜೀರಿಗೆಸಣ್ಣ',
'ಜೀರಿಗೆಸಾಲೆ',
'ಜೀರುಗುಣಿಕೆ',
'ಜೀರ್ಣೀಕರಿಸು',
'ಜೀವಂಗಿಡಿಸು',
'ಜೀವಂಜೀವಕ',
'ಜೀವಂತತನ',
'ಜೀವಂತಸಾಕ್ಷಿ',
'ಜೀವಂಬೆರಸು',
'ಜೀವಚೇಷ್ಟಕ',
'ಜೀವಧಾರಣ',
'ಜೀವನಜಾತ',
'ಜೀವನತತ್ತ್ವ',
'ಜೀವನದಾಯಿ',
'ಜೀವನದೃಷ್ಟಿ',
'ಜೀವನಮಟ್ಟ',
'ಜೀವನಯಂತ್ರ',
'ಜೀವನರಂಗ',
'ಜೀವನವಕ್ಕಿ',
'ಜೀವನವಾಹ',
'ಜೀವನಸತ್ವ',
'ಜೀವನಸಾಲೆ',
'ಜೀವನಾದರ್ಶ',
'ಜೀವನೋದ್ಯೋಗ',
'ಜೀವನೋಪಾಯ',
'ಜೀವನೋಪಾಯಿ',
'ಜೀವನೌಷಧ',
'ಜೀವನ್ಮತಕ',
'ಜೀವಪಂಚಕ',
'ಜೀವಪದಾರ್ಥ',
'ಜೀವಪುರುಷ',
'ಜೀವವಂಚಕ',
'ಜೀವವಿಕಾರಿ',
'ಜೀವವಿಕಾಸ',
'ಜೀವವಿಜ್ಞಾನ',
'ಜೀವಸಂಕುಲ',
'ಜೀವಸಂಕುಳ',
'ಜೀವಸಮಾಧಿ',
'ಜೀವಸಮಾಸ',
'ಜೀವಸಮಾಸೆ',
'ಜೀವಾಕರ್ಷಣ',
'ಜೀವಾಚರಣ',
'ಜೀವಾಚ್ಛಾದಿಕ',
'ಜೀವಾನುಕಂಪ',
'ಜೀವಾನುಕಂಪಿ',
'ಜೀವಾಪಹಾರ',
'ಜೀವಾಭಿಲಾಷ',
'ಜೀವಿತಂಬಡೆ',
'ಜೀವಿತಕಾಲ',
'ಜೀವಿತಗಾಱ',
'ಜೀವಿತಾತ್ಯಯ',
'ಜೀವಿತೇಶ್ವರ',
'ಜೀವಿತೇಶ್ವರಿ',
'ಜುಂಜುಱುದಲೆ',
'ಜುಕ್ಕಜುಲುಮೆ',
'ಜುಕ್ಕುಜುಲುಮೆ',
'ಜುಗಾರಖೋರ',
'ಜುಗಾರಿಕೋರ',
'ಜುಗಾರಿಮಂಡ',
'ಜುಗುಪ್ಸನೀಯ',
'ಜುಜ್ಝಾರತನ',
'ಜುಟ್ಟುಬಿಡಿಸು',
'ಜುಟ್ಟುವೆಬಳ್ಳಿ',
'ಜುಟ್ಟುಸುತ್ತಿಗೆ',
'ಜುಮ್ಮಮಸೀದಿ',
'ಜುಮ್ಮಾಮಸಿತ್ತಿ',
'ಜುಮ್ಮಾಮಸೀದಿ',
'ಜುಮ್ಮುದಟ್ಟಿಸು',
'ಜುಱುಜುಱಿಸು',
'ಜೂದುಗಾಳೆಗ',
'ಜೂದುಬೆಸನಿ',
'ಜೂಲುಕೂದಲು',
'ಜೂಲುಗೂದಲು',
'ಜೆಂದ್‍ಅವಸ್ತ',
'ಜೆಲಕೆಂಜೋಳ',
'ಜೇನುಕಾತಾಳ',
'ಜೇನುಕಿಟ್ಟಲು',
'ಜೇನುಕೃಷಿಕ',
'ಜೇನುಗನಸು',
'ಜೇನುಗೊಳಿಸು',
'ಜೇನುಪೆಟ್ಟಿಗೆ',
'ಜೇನುಸಾಕಣೆ',
'ಜೇರುಮುತ್ತಿಗೆ',
'ಜೇವಡೆಗೆಯ್',
'ಜೇವಣಶಾಲೆ',
'ಜೇವಣಸಾಲೆ',
'ಜೇವಳಿಗೆಯ್',
'ಜೇವೊಡೆಗೆಯ್',
'ಜೇವೊಡೆವೊಯ್',
'ಜೈನಶಾಸನ',
'ಜೈನಸಮಯ',
'ಜೈವಿಕಕ್ರಿಯೆ',
'ಜೊತೆಗಾರಿಕೆ',
'ಜೊತೆಗೊಳಿಸು',
'ಜೊಲ್ಲುಸುರಿಸು',
'ಜೋಗವಟ್ಟಿಗೆ',
'ಜೋಗಳಿವಾಡು',
'ಜೋಗಳೆವಾಡು',
'ಜೋಗಿನಿತನ',
'ಜೋಗಿಯರಳೆ',
'ಜೋಗಿವರಾಳಿ',
'ಜೋಗುಳಂಪಾಡು',
'ಜೋಗುಳಂಬಾಡು',
'ಜೋಗುಳಗೀತ',
'ಜೋಗುಳಗೀತೆ',
'ಜೋಗುಳಪಾಡು',
'ಜೋಗುಳವಾಡು',
'ಜೋಗುಳೆವಾಡು',
'ಜೋಡಣೆಗೆಯ್',
'ಜೋಡಿಗೆದಱು',
'ಜೋಡುದಗಲೆ',
'ಜೋದಗಾಳೆಗ',
'ಜೋನೆಗಪಳಿ',
'ಜೋನೆಗವಳಿ',
'ಜೋಪಾನಗೆಯ್',
'ಜೋಬದ್ರತನ',
'ಜೋಮಾಲೆಸರ',
'ಜೋಯಿಸಿತನ',
'ಜೋಲುಬಾವಲಿ',
'ಜೋಲುಮನಸ್ಸು',
'ಜೋಳವಾಳಗೆ',
'ಜ್ಞಾತಿಕಲಹ',
'ಜ್ಞಾತಿಸಂಬಂಧ',
'ಜ್ಞಾನಲಕ್ಷಣಿ',
'ಜ್ಞಾನಲಾಲಸೆ',
'ಜ್ಞಾನವಂತಿಕೆ',
'ಜ್ಞಾನವಾಹಿನಿ',
'ಜ್ಞಾನಶೂನ್ಯತೆ',
'ಜ್ಞಾನಸಾರಾಯ',
'ಜ್ಞಾನಹೀನತೆ',
'ಜ್ಞಾನಾವರಣ',
'ಜ್ಞಾಪಕಪತ್ರ',
'ಜ್ಞಾಪಕಶಕ್ತಿ',
'ಜ್ಞಾಪಕಹೇತು',
'ಜ್ಞಾಪನಾಪತ್ರ',
'ಜ್ಞೇಯನಿಷ್ಠತೆ',
'ಜ್ಯೋತಿಃಪಟಲ',
'ಜ್ಯೋತಿಪುರುಷ',
'ಜ್ಯೋತಿರಿಂಗಣ',
'ಜ್ಯೋತಿರ್ಮಂಡಲ',
'ಜ್ಯೋತಿರ್ವಿಜ್ಞಾನ',
'ಜ್ಯೋತಿರ್ವಿಜ್ಞಾನಿ',
'ಜ್ಯೋತಿವತ್ಸರ',
'ಜ್ಯೋತಿಷ್ಯಶಾಸ್ತ್ರ',
'ಜ್ವರಮಾಪಕ',
'ಜ್ವರಾತಿಸಾರ',
'ಜ್ವಲಂತಸಾಕ್ಷಿ',
'ಜ್ವಲತ್ಪರ್ವತ',
'ಜ್ವಾಲೆಗೊಳಿಸು',
'ಝಂಝಾಮಾರುತ',
'ಝಗಝಗನೆ',
'ಝಗಝಗಿಸು',
'ಝಗಿಝಗಿಸು',
'ಝಜ್ಜರಂಪೋಗು',
'ಝಣಝಣಿತ',
'ಝಣಝಣಿಸು',
'ಝಣತುಕೃತಿ',
'ಝಣಮಣಿಸು',
'ಝನಾನಾಖಾನೆ',
'ಝಳಂಬಾಯತ',
'ಝಳಕಂಬೊಗು',
'ಝಳಪುಗೊಳ್ಳು',
'ಝಾಡಿಗೆದಱು',
'ಝಿಲ್ಲಿಕಾನಾದ',
'ಟಂಕಣಕಾರ',
'ಟಂಕಣಖಾರ',
'ಟಕಟಕನೆ',
'ಟಕ್ಕರಿಗಳೆ',
'ಟಕ್ಕುಟವಳಿ',
'ಟಪಾಲುಗಾಡಿ',
'ಟಪ್ಪಾಲುಕಟ್ಟೆ',
'ಟರ್ಕಿಟವಲು',
'ಟವಟವಿಸು',
'ಟವಣೆಕೋಲ್',
'ಟವಣೆಹಾಕು',
'ಟವಳಿಗಾರ',
'ಟವಳಿಗಾಱ',
'ಟವುಳಿಕಾಱ',
'ಟವುಳಿನುಡಿ',
'ಟಾಠಡಾಢಣ',
'ಟಾಣದೀವಿಗೆ',
'ಟಾಣಾದೀವಿಗೆ',
'ಟಿಕ್ಕರಿಗಳೆ',
'ಟಿಪ್ಪಣಿಗಾರ',
'ಟಿಸಿಲುದಾರಿ',
'ಟೀಕೆಟಿಪ್ಪಣಿ',
'ಟೆಕ್ಕರಿಗಳೆ',
'ಟೆಪ್ಪರಿಸುಹ',
'ಟಿಲಿವಿಷನ್',
'ಟೆಲಿವಿಷನ್ನು',
'ಟೆಲಿಸಂಪರ್ಕ',
'ಟೇಪ್‍ರಿಕಾರ್ಡು',
'ಟೈಪ್‍ರೈಟರ್',
'ಟೈಪ್‍ರೈಟರು',
'ಟೋಕುಮಾರಾಟ',
'ಠಕಾರಿಮಾಲು',
'ಠಕ್ಕುಗಾರಿಕೆ',
'ಠಕ್ಕುಗೊಳಿಸು',
'ಠಕ್ಕುಠವಣೆ',
'ಠಕ್ಕುಠವಳಿ',
'ಠಕ್ಕುಠವುಳಿ',
'ಠಣಂಕರಿಸು',
'ಠಣಕರಿಸು',
'ಠಮಾಳತನ',
'ಠವಣಿಗಾಣ್',
'ಠವಣೆಕೋಲ್',
'ಠವಣೆಗೆಯ್',
'ಠವಣೆಯಿಕ್ಕು',
'ಠವಾಳತನ',
'ಠವುಳಿಕಾಱ',
'ಠಾಣದೀವಿಗೆ',
'ಠಾಣೆಯದೆಗೆ',
'ಠಾಣೆಯಮಿಡು',
'ಠಾಣೆಯವಿಕ್ಕು',
'ಠಾವಣೆಕೋಲು',
'ಠಾವುಗೊಳಿಸು',
'ಠಿಕ್ಕರಿಗಳೆ',
'ಠೇವಣಿದಾರ',
'ಠೋಕಮಾರಾಟ',
'ಠೋಕವ್ಯಾಪಾರಿ',
'ಡಂಕುತಪ್ಪಿಸು',
'ಡಂಗಪಾಲಕ',
'ಡಂಗರಿಸೌತೆ',
'ಡಂಗುರಂಪೊಯ್',
'ಡಂಗುರಂಬೊಯ್',
'ಡಂಗುರಂಬೊಯ್ಸು',
'ಡಂಗುರಕಾರ',
'ಡಂಗುರವಾರ್ತೆ',
'ಡಂಗುರವೊಯ್',
'ಡಂಗುರಸಾರು',
'ಡಂಗುರಸಾಱು',
'ಡಂಗುರಹಾಕು',
'ಡಂಗುರಹುಯ್ಯು',
'ಡಂಗುರಹೊಡೆ',
'ಡಂಗುರಹೊಯ್',
'ಡಂಗುರಹೊಯ್ಸು',
'ಡಂಗುಱುಚಾಟ',
'ಡಂಗೆಯಂಗೊಳ್',
'ಡಂಬಕತನ',
'ಡಂಬಕವಿದ್ಯೆ',
'ಡಂಭಕಭಕ್ತಿ',
'ಡಕ್ಕೆವಾಜಿಸು',
'ಡಣಡಣಿಸು',
'ಡಣತುಕಾರ',
'ಡಣಾಡಂಗುರ',
'ಡಣಾಯಕಿತಿ',
'ಡಬಡಬಿಸು',
'ಡಬಡ್ಡಾಳಿಕೆ',
'ಡಬಡ್ಢಾಳಿಕೆ',
'ಡಬ್ಬಣಕಾಟಿ',
'ಡಬ್ಬಣಸಾಲೆ',
'ಡಬ್ಬಿಗಡಿಗೆ',
'ಡಬ್ಬುಮಲಗು',
'ಡಮಡಮಿಸು',
'ಡಮುರುರೇಖೆ',
'ಡರಿಕೆಹಾಕು',
'ಡವಕೆವಿಡಿ',
'ಡವಡವನೆ',
'ಡವಡವಿಕೆ',
'ಡವಡವಿಸು',
'ಡವಲುಕಟ್ಟು',
'ಡವಾಲಿಯಾಳು',
'ಡವುಲಂದಾಜು',
'ಡವುಲುಕೊಚ್ಚು',
'ಡವುಲುದಾರ',
'ಡವುಲುದಾರಿ',
'ಡಳಮಳಿಸು',
'ಡಾಂಭಿಕತನ',
'ಡಾಕಿನಿದೋಷ',
'ಡಾಕಿನೀತಂತ್ರ',
'ಡಾಣಾಡಂಗರ',
'ಡಾಣಾಡಂಗುರ',
'ಡಾವಣಿಕಟ್ಟು',
'ಡಾವಣೆನೇಣ್',
'ಡಾವುತೀರಿಸು',
'ಡಾಳಕತನ',
'ಡಾಳಕವಂತ',
'ಡಾಳೆವೆಂಡತಿ',
'ಡಿಕ್ಕಿಹೊಡಿಸು',
'ಡಿಯ್ಯನಹಕ್ಕಿ',
'ಡುರಕಿಹಾಕು',
'ಡುರಕಿಹೊಡೆ',
'ಡುರುಕುಹಾಕು',
'ಡುರುಕೆಹಾಕು',
'ಡೆಂಕಣಿಕಾಱ',
'ಡೆಂಕಣಿಗುಮ್ಮ',
'ಡೆಂಕಣಿಜಂತ್ರ',
'ಡೊಂಕಣಿಕಾರ',
'ಡೊಂಕಣಿಕಾಱ',
'ಡೊಕ್ಕರಂಗೊಳ್',
'ಡೊಕ್ಕರಪಟ್ಟು',
'ಡೊಕ್ಕರವಾಯ್',
'ಡೊಕ್ಕರವಿಕ್ಕು',
'ಡೊಗರುಬೀಳು',
'ಡೊಗರುಹಾಯು',
'ಡೊಗ್ಗಾಲುಸಲಾಂ',
'ಡೊಗ್ಗುಸಲಾಮು',
'ಡೊಣೆನಾಯಕ',
'ಡೊಣ್ಣೆಸಣ್ಣಕ್ಕಿ',
'ಡೊಳ್ಳಾಸಕಾತಿ',
'ಡೊಳ್ಳಾಸವಿದ್ಯೆ',
'ಡೊಳ್ಳುಹೊಟ್ಟೆಗ',
'ಡೋಕಾರಿತನ',
'ಡೋಲಾಯಮಾನ',
'ಡೌಗಿಕೊಳವಿ',
'ಢಕಲಪಟ್ಟಿ',
'ಢಕಲಾಯಿಸು',
'ಢಕ್ಕೆವಾಜಿಸು',
'ಢಣಢಣಿಸು',
'ಢಣಾಡಂಗುರ',
'ಢಮಢಮಿಸು',
'ಢರಕಿಹಾಕು',
'ಢಾಕುಗೆಡಿಸು',
'ಢಾಣಾಡಂಗುರ',
'ಢಾಳಕತನ',
'ತಂಗದಿರ್ಗಲ್',
'ತಂಗದಿರ್ವಕ್ಕಿ',
'ತಂಗದಿರ್ವಟ್ಟ',
'ತಂಗಳುತತ್ವ',
'ತಂಗುಬೆಳಕು',
'ತಂಟಲಮಾರಿ',
'ತಂಟಲುಮಾರಿ',
'ತಂಡಲಾಲಿಕ',
'ತಂಡುಲೀಯಕ',
'ತಂಡುವಾದಿಸು',
'ತಂಡುಳಜಳ',
'ತಂಡುಳೀಯಕ',
'ತಂಡೋಪತಂಡ',
'ತಂತಿಕಚೇರಿ',
'ತಂತಿಜಾಲರಿ',
'ತಂತಿಟಪ್ಪಾಲು',
'ತಂತಿಹಱುಕ',
'ತಂತುಹಾರುವ',
'ತಂತ್ರಂಬೆರಸು',
'ತಂತ್ರಗಾರಿಕೆ',
'ತಂತ್ರಪ್ರಯೋಗ',
'ತಂತ್ರವಿಜ್ಞಾನ',
'ತಂತ್ರಹಾಳಿಕೆ',
'ತಂದನತಾನ',
'ತಂದಾನತಾನ',
'ತಂಬಟಜಾಲಿ',
'ತಂಬಟ್ಟೆಜಾಲಿ',
'ತಂಬತ್ತಿಜಾಲಿ',
'ತಂಬಲಗಱೆ',
'ತಂಬುಲಂಗುಡು',
'ತಂಬುಲಂಗೊಡು',
'ತಂಬುಲಪೆಚ್ಚ',
'ತಕತಕನೆ',
'ತಕಪಕನೆ',
'ತಕಾವಿಸಾಲ',
'ತಕ್ಕಮಟ್ಟಿಗೆ',
'ತಕ್ಕುಗೆಡಿಸು',
'ತಕ್ಕುಮೆಗಿಡು',
'ತಕ್ಕುಮೆವಡೆ',
'ತಕ್ಕೂರ್ಮೆಗೆಡು',
'ತಕ್ಕೆಗೊಳಿಸು',
'ತಕ್ಕೋಲಪುಟ್ಟಿ',
'ತಕ್ಷೀರುವಂತ',
'ತಕ್ಷೋಪಜೀವ್ಯ',
'ತಕ್ಸೀರುದಾರ',
'ತಕ್ಸೀರುನಾಮೆ',
'ತಗಡುಗೋಳಿ',
'ತಗಣಿಗಂಡ',
'ತಗಲುಗಾರ',
'ತಗಲುತನ',
'ತಗಲುಮೊಳೆ',
'ತಗಲುಸಾಕ್ಷಿ',
'ತಗಾದೆಗಾರ',
'ತಗಾಯಿಸಾಲ',
'ತಗಾಯಿಸೂಟು',
'ತಗುಣೆಕಾಟ',
'ತಗುಲುಬೀಳ್',
'ತಗುಲುಬೀಳು',
'ತಗ್ಗುಗನ್ನಡಿ',
'ತಟತಟನೆ',
'ತಟತಟಿಸು',
'ತಟಪುಟನೆ',
'ತಟಪೊಟನೆ',
'ತಟಹಾಯಿಸು',
'ತಟಹೊಟನೆ',
'ತಟಿಚ್ಚಂಚಳೆ',
'ತಟಿದ್ವಲ್ಲರಿ',
'ತಟ್ಟಿಮೆಡಱು',
'ತಟ್ಟುಗೆಡಹು',
'ತಟ್ಟುಸೆಱಗು',
'ತಡಂಗಲಸು',
'ತಡಂಗಲಿಸು',
'ತಡಂಗಾಲ್ಗೊಳ್',
'ತಡಂಗಾಲ್ಪೊಯ್',
'ತಡಂಗಾಲ್ವೊಯ್',
'ತಡಂಗಿಡಿಸು',
'ತಡಂತಡಿಸು',
'ತಡಂತೊಡರ್',
'ತಡಂಬಡಿಸು',
'ತಡಂಬುಗಿಸು',
'ತಡಗುಂಡಿಗೆ',
'ತಡಗೆಡಿಸು',
'ತಡತಡಿಸು',
'ತಡದಡಿಸು',
'ತಡಪಡಿಸು',
'ತಡಫಡಿಸು',
'ತಡಬಡನೆ',
'ತಡಬಡಿಸು',
'ತಡಮಡಿಸು',
'ತಡವಡಿಸು',
'ತಡವರಿಸು',
'ತಡವುಗೊಡು',
'ತಡಿಕೆಗೋಡೆ',
'ತಡಿಕೆಪಡಿ',
'ತಡಿಕೆಬಲೆ',
'ತಡಿಕೆವಲೆ',
'ತಡಿಗೆಹಣ',
'ತಡೆಕವಾಟ',
'ತಡೆಗಡಿಸು',
'ತಡೆಗಾಲ್ಪೊಡೆ',
'ತಡೆಗಾಲ್ವೊಯ್',
'ತಡೆಗೆಡವು',
'ತಡೆಗೆಡಹು',
'ತಡೆಗೊಳಿಸು',
'ತಡೆಪಂಬೆಱು',
'ತಡೆಯುಸಿರ್',
'ತಣಿಯುಣಿಸು',
'ತಣ್ಗದಿರಱೆ',
'ತಣ್ಗದಿರಾಡಿ',
'ತಣ್ಗದಿರ್ಗಲ್',
'ತಣ್ಗದಿರ್ವಕ್ಕಿ',
'ತಣ್ಣಲೆಯಿಸು',
'ತಣ್ಣಸಗಯ್',
'ತಣ್ಣಸಗಯ್ಯ',
'ತಣ್ಣಸಗೆಯ್',
'ತಣ್ಣಸಗೆಯ್ಯ',
'ತಣ್ಣೀರೆರಚು',
'ತಣ್ಣೊಱೆಗಲ್ಲು',
'ತಣ್ಪುಗೊಳಿಸು',
'ತತ್ತರಂಗೊಳ್',
'ತತ್ತರಂಗೊಳು',
'ತತ್ತರಂದರಿ',
'ತತ್ತರಂಬಡು',
'ತತ್ತರಂಬಡೆ',
'ತತ್ತರಗಡಿ',
'ತತ್ತರಗುಟ್ಟು',
'ತತ್ತರಗೊಳು',
'ತತ್ತರಗೊಳ್ಳು',
'ತತ್ತರದರಿ',
'ತತ್ತರನಡೆ',
'ತತ್ತರಪಡು',
'ತತ್ತರಬಡು',
'ತತ್ತಲಮಗ',
'ತತ್ತಲಗೂಸು',
'ತತ್ತಳಂಗೊಳು',
'ತತ್ತಳಂಬೀಳು',
'ತತ್ತಳಗೆಯ್',
'ತತ್ತಳಗೈಸು',
'ತತ್ತಳಗೊಳ್',
'ತತ್ತಳಗೊಳ್ಳು',
'ತತ್ತಱಂತಱಿ',
'ತತ್ತಱಂದಱಿ',
'ತತ್ತಱಗೊಳ್',
'ತತ್ತಱತಱಿ',
'ತತ್ತಱದಱಿ',
'ತತ್ತಱಿದಱಿ',
'ತತ್ತೞಗುದಿ',
'ತತ್ತೞದುೞಿ',
'ತತ್ತುಗಾಱಿಕೆ',
'ತತ್ತ್ವಕಾರಣ',
'ತತ್ತ್ವಪ್ರಣಾಲಿ',
'ತತ್ತ್ವರಹಸ್ಯ',
'ತತ್ತ್ವಸುವಾಲೆ',
'ತತ್ತ್ವಾಧಿಕಾರಿ',
'ತತ್ತ್ವಾನುಭಾವ',
'ತತ್ವಸುವ್ವಾಲಿ',
'ತದನಂತರ',
'ತದನಂತರಂ',
'ತದನುಸಾರ',
'ತದಿಗಿಣಿಸು',
'ತದೇಕಚಿತ್ತ',
'ತದೇಕಚಿತ್ತೆ',
'ತದ್ವಿಪರೀತ',
'ತನಗುಟ್ಟಿಸು',
'ತನನವೆನು',
'ತನನವೆನ್ನು',
'ತನಯಸ್ಥಾನ',
'ತನಿಕಛೇರಿ',
'ತನಿಗೆರಳ್',
'ತನಿಜವ್ವನ',
'ತನಿಮಸಗು',
'ತನಿಮಾಡಿಸು',
'ತನಿಯೆರೆತ',
'ತನಿವಡಹ',
'ತನಿವದನು',
'ತನಿವೊಗರ್',
'ತನುಗ್ರಹಣ',
'ತನುತ್ವಂಬಡೆ',
'ತನುದಂಡನೆ',
'ತನುಧಾತುಜ್ಞ',
'ತನುಮಾನಸ',
'ತನುವಿಕಾರ',
'ತನುವೆರಸು',
'ತನುಸಂಭವ',
'ತನುಸಂಭವೆ',
'ತನೂನಪಾತು',
'ತಪಂಬಡುಹ',
'ತಪಃಪ್ರಭಾವ',
'ತಪತಪನೆ',
'ತಪನಕರ',
'ತಪನಾತಪ',
'ತಪನೋದಯ',
'ತಪನೋಪಲ',
'ತಪಶ್ಚರಣ',
'ತಪಶ್ಚರಣೆ',
'ತಪಶ್ಚರಿತ',
'ತಪಶ್ಚರಿತೆ',
'ತಪಶ್ಚರಿಯ',
'ತಪಸುದಾಳ್',
'ತಪಸುದಾಳು',
'ತಪಾಸಗೆಡು',
'ತಪಾಸಗೇಡಿ',
'ತಪ್ಪಡಿಯಿಡು',
'ತಪ್ಪಣೆಗುಟ್ಟು',
'ತಪ್ಪರಿಸಾಡು',
'ತಪ್ಪರೆಹುಲ್ಲು',
'ತಪ್ಪಳೆಗುಟ್ಟು',
'ತಪ್ಪುಕಾಣಿಕೆ',
'ತಪ್ಪುಗಾಣಿಕೆ',
'ತಪ್ಪುದಂಡಣೆ',
'ತಪ್ಪುಸಾಧಿಸು',
'ತಪ್ಪುಹೊರಿಸು',
'ತಬ್ಬಿಬ್ಬಿಗೊಳ್',
'ತಬ್ಬಿಬ್ಬುಗೊಳ್',
'ತಬ್ಬಿಬ್ಬುಗೊಳು',
'ತಬ್ಬಿಬ್ಬುಗೊಳ್ಳು',
'ತಬ್ಬಿಬ್ಬುವೋಗು',
'ತಮಟೆಬಳ್ಳಿ',
'ತಮಸ್ಸಂಹೃತಿ',
'ತಮಾಲಪತ್ರ',
'ತಮಾಲಪತ್ರೆ',
'ತಮಾಷೆಗಾರ',
'ತಮಾಳಪತ್ರ',
'ತಮ್ಮಡವಳ್ಳಿ',
'ತಮ್ಮಣಿಗೊಳು',
'ತಮ್ಮಣಿಗೊಳ್ಳು',
'ತಮ್ಮುಟುಕಾಱ',
'ತರಂಗವಾದ',
'ತರಂಗೊಳಿಸು',
'ತರಕಟಾಲು',
'ತರಕಲಾಂಟಿ',
'ತರಗುಪೇಟೆ',
'ತರಟುತಲೆ',
'ತರಟುದಲೆ',
'ತರಣಪಣ್ಯ',
'ತರಣಿಕರ',
'ತರಣಿಕಾಂತ',
'ತರಣಿವೀಧಿ',
'ತರತರನೆ',
'ತರತರಿಸು',
'ತರತರೀತ',
'ತರತ್ತರಂಗ',
'ತರತ್ತರಂಗೆ',
'ತರತ್ತರಳ',
'ತರಬಿಯತ್ತು',
'ತರಲೆಕಾರ',
'ತರಲೆತನ',
'ತರಹರಣೆ',
'ತರಹರಿಕೆ',
'ತರಹರಿಸು',
'ತರಹೆವಾರಿ',
'ತರಹೇವಾರಿ',
'ತರಳತನ',
'ತರಳತರ',
'ತರಳೀಕೃತ',
'ತರಿಚುಗೆಡೆ',
'ತರಿಳಕೆಂಡ',
'ತರಿಳಗೆಂಡ',
'ತರುಕೋಟರ',
'ತರುಣಜ್ವರ',
'ತರುಣಾರುಣ',
'ತರೆಕಂಗಲಿ',
'ತರ್ಕಸಂಬಂಧಿ',
'ತರ್ಕಸಾಧಕ',
'ತರ್ಕಸಿದ್ದಾಂತ',
'ತರ್ಪಣಗೈಸು',
'ತರ್ಪಣಗೊಡು',
'ತರ್ವಾಯಗೊಳ್',
'ತಲಚೌಪದಿ',
'ತಲಪರಗಿ',
'ತಲಪರಿಗೆ',
'ತಲವಿನ್ಯಾಸ',
'ತಲಹುವಿಡಿ',
'ತಲಾಮಲಕ',
'ತಲಿಮಿಲಿತ',
'ತಲೆಕರಡು',
'ತಲೆಕೂದಲು',
'ತಲೆಕೆಡಿಸು',
'ತಲೆಕೆದರು',
'ತಲೆಕೊಡವು',
'ತಲೆಕೊಡಹು',
'ತಲೆಗಂದಾಯ',
'ತಲೆಗಟ್ಟಿಸು',
'ತಲೆಗಡಿಕ',
'ತಲೆಗಡಿತ',
'ತಲೆಗಡುಕ',
'ತಲೆಗಡ್ಡಣೆ',
'ತಲೆಗಳಚು',
'ತಲೆಗುತ್ತಿಸು',
'ತಲೆಗುತ್ತುಗೆ',
'ತಲೆಗೂದಲ್',
'ತಲೆಗೂದಲು',
'ತಲೆಗೆಡಹು',
'ತಲೆಗೆಡಿಸು',
'ತಲೆಗೆದರ',
'ತಲೆಗೆದರಿ',
'ತಲೆಗೆದರು',
'ತಲೆಗೆದಱು',
'ತಲೆಗೊಡಪು',
'ತಲೆಗೊಡಹು',
'ತಲೆಗೊಯಿಕ',
'ತಲೆಗೊಯಿಲು',
'ತಲೆಚಮರ',
'ತಲೆಚಮರಿ',
'ತಲೆಜಡಾರಿ',
'ತಲೆಜಾದರಿ',
'ತಲೆಡವುಗೆ',
'ತಲೆತಗ್ಗಿಸು',
'ತಲೆತಪ್ಪಿಸು',
'ತಲೆತಿರುಕ',
'ತಲೆತಿರುಗು',
'ತಲೆತುರಿಸು',
'ತಲೆದಡವು',
'ತಲೆದಡಹು',
'ತಲೆದಿರುವು',
'ತಲೆದಿವಸ',
'ತಲೆದುಡಿಗೆ',
'ತಲೆದುಡುಗೆ',
'ತಲೆದೂಗಾಟ',
'ತಲೆದೂಗಿಸು',
'ತಲೆದೆವಸ',
'ತಲೆದೊಡವು',
'ತಲೆದೋಱಿಸು',
'ತಲೆನವಿರ್',
'ತಲೆನವಿರು',
'ತಲೆನಾಯಕ',
'ತಲೆಪರವು',
'ತಲೆಪೆಣ್ಮಣಿ',
'ತಲೆಪೊಱೆಯ',
'ತಲೆಪ್ರತಿಷ್ಠೆ',
'ತಲೆಬರಹ',
'ತಲೆಬರೆಹ',
'ತಲೆಬಳಚು',
'ತಲೆಬಳಿಚು',
'ತಲೆಬಾಗಿಲು',
'ತಲೆಬಾಗಿಸು',
'ತಲೆಬುರುಡೆ',
'ತಲೆಬೆಲಗು',
'ತಲೆಬೇಸರ',
'ತಲೆಬೇಸರು',
'ತಲೆಬೋಳಿಸು',
'ತಲೆಮರಡು',
'ತಲೆಮರಸು',
'ತಲೆಮರೆಸು',
'ತಲೆಮಲಂಗು',
'ತಲೆಮಾಣಿಕ',
'ತಲೆಮುಸುಕು',
'ತಲೆಮುಸುಗು',
'ತಲೆಮೆಟ್ಟಿಸು',
'ತಲೆಮೆರಕ',
'ತಲೆಮೊಱಡು',
'ತಲೆಯಳಿಗ',
'ತಲೆಯಾಡಿಸು',
'ತಲೆಯುಡಿಗೆ',
'ತಲೆಯೆಱಕ',
'ತಲೆಯೊಡೆತ',
'ತಲೆಯೊಡೆಯ',
'ತಲೆಯೋಡಿಸು',
'ತಲೆವಡಿಕೆ',
'ತಲೆವರಿಗೆ',
'ತಲೆವಲಗೆ',
'ತಲೆವಾಗಿಲ್',
'ತಲೆವಾಗಿಲು',
'ತಲೆವಾಗಿಸು',
'ತಲೆವಾಗುಹ',
'ತಲೆವೇಸಱು',
'ತಲೆಸವರು',
'ತಲೆಹರಟೆ',
'ತಲೆಹರಿಗೆ',
'ತಲೆಹರುಕ',
'ತಲೆಹಾರಿಸು',
'ತಲೆಹಿಡಿಕ',
'ತಲೆಹಿಡಿಕಿ',
'ತಲೆಹಿಡುಕ',
'ತಲೆಹಿಡುಕಿ',
'ತಲೆಹುಳುಕ',
'ತಲೆಹೊಗಿಸು',
'ತಲೆಹೊಡಕ',
'ತಲೆಹೊರೆಗ',
'ತಲೆಹೋಗಿಸು',
'ತಲ್ಲಖನಾಮಾ',
'ತಲ್ಲಣಂಗೊಳ್',
'ತಲ್ಲಣಗೊಳ್',
'ತಲ್ಲಣಗೊಳ್ಳು',
'ತಲ್ಲಣದೋಱ್',
'ತಲ್ಲೞಂಗೊಳ್',
'ತಲ್ಲಾಖನಾಮಾ',
'ತವಕಂಗೊಳ್',
'ತವಕಂಬೆಱು',
'ತವಕಕಾರ',
'ತವಕಕಾರ್ತಿ',
'ತವಕಕಾಱ',
'ತವಕಗೊಳ್',
'ತವಕಗೊಳು',
'ತವಕಗೊಳ್ಳು',
'ತವಕಪಡು',
'ತವಕವಾಡು',
'ತವಗವಿಡು',
'ತವರುಮನೆ',
'ತವಿಲ್ಪಗಲ್',
'ತಸ್ಕರಶಾಸ್ತ್ರ',
'ತಹತಹಿಸು',
'ತಹಶೀಲ್ದಾರ',
'ತಹಸೀಲ್ದಾರ',
'ತಳಚಪ್ಪಟೆ',
'ತಳತಳನೆ',
'ತಳತಳಿಕೆ',
'ತಳತಳಿಸು',
'ತಳತ್ತಳಿಸು',
'ತಳಥಳಿಸು',
'ತಳದಳಿಸು',
'ತಳಧಾರಣೆ',
'ತಳಪಗಣ್ಣು',
'ತಳಪವೆಱು',
'ತಳಪಳನೆ',
'ತಳಪುಗಣ್',
'ತಳಪೆತ್ತಿಸು',
'ತಳಪ್ರಹಾರಿ',
'ತಳಭಿತ್ತರಿ',
'ತಳಭೇದನ',
'ತಳಮಗುಚು',
'ತಳಮಳಗು',
'ತಳಮಳಲ್',
'ತಳಮಳಿಸು',
'ತಳಮುಟ್ಟಿಸು',
'ತಳರ್ನಾಲಗೆ',
'ತಳಲುಗಾವಿ',
'ತಳಲುಗೆಂಡ',
'ತಳಲುಶ್ರಮ',
'ತಳವಳಗು',
'ತಳವಳಿಸು',
'ತಳವಱಿಕೆ',
'ತಳವಱಿತಿ',
'ತಳವಾರಿಕೆ',
'ತಳವಾಸುಗೆ',
'ತಳವಾಱಿಕೆ',
'ತಳವಿಘಾತಿ',
'ತಳವುಕಾರ',
'ತಳವೆಳಗು',
'ತಳಸಂಕಲೆ',
'ತಳಸಮಸ್ತ',
'ತಳಹೊಳಗ',
'ತಳಹೊಳಿಸು',
'ತಳಾಮಳಕ',
'ತಳಾರತನ',
'ತಳಾಱಗೂಲಿ',
'ತಳಾಱಗೆಯಿ',
'ತಳಾಱುಗೆಯ್',
'ತಳಿಕೆಬೀಳು',
'ತಳಿಗೆಗಾರ್ತಿ',
'ತಳಿಗೆಗುಡು',
'ತಳಿಯಲಾರ',
'ತಳಿರುಗಾವಿ',
'ತಳಿರುಗಾಸೆ',
'ತಳಿರುಗೆಂಪು',
'ತಳಿರುದೋಱು',
'ತಳಿರುವಾಡ',
'ತಳಿರೋವರಿ',
'ತಳಿರ್ಗಳಸ',
'ತಳಿರ್ಗೊಂಚಲು',
'ತಳಿರ್ದೊಂಗಲ್',
'ತಳಿರ್ದೋರಣ',
'ತಳಿರ್ವಂದರ್',
'ತಳಿರ್ವಂದರ',
'ತಳಿರ್ವಡಿಸು',
'ತಳಿರ್ವಾಸಿಕೆ',
'ತಳಿರ್ವಾಸಿಗೆ',
'ತಳ್ತಳಗುದಿ',
'ತಳ್ತಱದಱಿ',
'ತಳ್ಳಂಕಂಗುಟ್ಟು',
'ತಳ್ಳಂಕಂಗೊಳ್',
'ತಳ್ಳಂಕಗುಟ್ಟು',
'ತಳ್ಳಂಕಗೊಳ್',
'ತಳ್ಳಂಕಗೊಳು',
'ತಳ್ಳಂಕಗೊಳ್ಳು',
'ತಳ್ಳಂಕುಗುಟ್ಟು',
'ತಳ್ಳಂಕುಗೊಳ್',
'ತಳ್ಳವಾರಿಕೆ',
'ತಳ್ಳಿಕಾರಿಕೆ',
'ತಱಂಟುದಲೆ',
'ತಱಟಿದಲೆ',
'ತಱಟುದಲೆ',
'ತಱಟುಮಂಡೆ',
'ತಱತಱನೆ',
'ತಱತಱಿಸು',
'ತಱಬಱಿಕ',
'ತಱವಳಿಕೆ',
'ತಱವಾಳಿಕೆ',
'ತಱಿಗೊಱಡು',
'ತಱಿಚುಗೆಡೆ',
'ತಱಿಸಲವು',
'ತಱಿಸಲಿಸು',
'ತೞತೞನೆ',
'ತೞತೞಿಸು',
'ತೞತ್ತೞಿಸು',
'ತೞಪೞನೆ',
'ತೞ್ತೞಗುದಿ',
'ತಾಂಡವನೃತ್ಯ',
'ತಾಂಡವವಾಡು',
'ತಾಂಡವೇಶ್ವರ',
'ತಾಂತ್ರಿಕಾಗಮ',
'ತಾಂದನಿತಾನೊ',
'ತಾಂಬಟಗಾರ',
'ತಾಂಬೂಲವಲ್ಲಿ',
'ತಾಂಬ್ರಶಾಸನ',
'ತಾಜಾಕಲಮು',
'ತಾಜಾಸನದು',
'ತಾಜಾಸನ್ನದು',
'ತಾಟಕಿತನ',
'ತಾಡಿಕವಡೆ',
'ತಾಣದೀವಿಗೆ',
'ತಾಣಾದೀವಿಗೆ',
'ತಾತ್ಪರ್ಯಕಾರ್ಯ',
'ತಾತ್ಪರ್ಯಗೊಳ್ಳು',
'ತಾತ್ಪರ್ಯಜ್ಞಾನ',
'ತಾತ್ಸಾರಭಾವ',
'ತಾದಾತ್ಮ್ಯಭಾವ',
'ತಾನವಿತಾನ',
'ತಾಪಗೊಳಿಸು',
'ತಾಪಣೆಗೂಡು',
'ತಾಪನಕ್ರಿಯೆ',
'ತಾಪಮಾಪಕ',
'ತಾಪಾಳುಮರ',
'ತಾಬಡತೋಬ',
'ತಾಮಸಗಾರ',
'ತಾಮಸಗಾರ್ತಿ',
'ತಾಮಸವೃತ್ತಿ',
'ತಾಮ್ರಕುಟ್ಟಕ',
'ತಾಮ್ರಪಟ್ಟಿಕೆ',
'ತಾಮ್ರಫಲಕ',
'ತಾಮ್ರಮರ್ದಕ',
'ತಾಮ್ರಮರ್ದನ',
'ತಾಮ್ರಲೋಚನ',
'ತಾಮ್ರಶಾಸನ',
'ತಾಯಿಮಳಲು',
'ತಾಯ್ಮೞಲ್ದೆಱೆ',
'ತಾಯ್ಮೞಲ್ದೋಱು',
'ತಾರಕಮಂತ್ರ',
'ತಾರಗೆದಾಣ',
'ತಾರಗೆವಟ್ಟೆ',
'ತಾರತಕ್ಕಡಿ',
'ತಾರತಮತೆ',
'ತಾರತಿಗಡಿ',
'ತಾರಾತಂಟಲು',
'ತಾರಾತಿಕಡಿ',
'ತಾರಾತಿಕ್ಕಡಿ',
'ತಾರಾತಿಗಡಿ',
'ತಾರಾಮಂಡಲ',
'ತಾರೀಫು ಮಾಡು',
'ತಾರುಣ್ಯವತಿ',
'ತಾರ್ಕಣೆಗಾಣ್',
'ತಾರ್ಕಣೆಗೊಡು',
'ತಾರ್ಕಿಕಶಾಸ್ತ್ರ',
'ತಾಲಬದ್ಧತೆ',
'ತಾಲಮೂಲಿಕೆ',
'ತಾಲವೃಂತಕ',
'ತಾಲೀಮಖಾನೆ',
'ತಾಲೀಸಪತ್ರಿ',
'ತಾಲುಪುಪ್ಪುಟ',
'ತಾಲೂಕುದಾರ',
'ತಾವಡಕೋಲು',
'ತಾವಡಗೋಲ್',
'ತಾವಡಹಾರ',
'ತಾವಡಿಗೊಳ್',
'ತಾವರೆಗಣ್ಣ',
'ತಾವರೆಗಣ್ಣು',
'ತಾವರೆಗೆಡ್ಡೆ',
'ತಾವರೆಬಿತ್ತು',
'ತಾವರೆಯಕ್ಕಿ',
'ತಾವರೆಹಕ್ಕಿ',
'ತಾಶೆಬಜಂತ್ರಿ',
'ತಾಸೀಲುದಾರ',
'ತಾಸೆಬಜಂತ್ರಿ',
'ತಾಳಬದ್ಧತೆ',
'ತಾಳಮದ್ದಲೆ',
'ತಾಳಮದ್ದಳೆ',
'ತಾಳಲಗಾಯಿ',
'ತಾಳಿಚೌಕುಳಿ',
'ತಾಳಿಸಪತ್ರ',
'ತಾಳಿಸಪತ್ರೆ',
'ತಾಳೀಸಪತ್ರ',
'ತಾಳೀಸಪತ್ರೆ',
'ತಾಳೇಶಪತ್ರೆ',
'ತಾಳೇಸಪತ್ರ',
'ತಾಳೇಸಪತ್ರೆ',
'ತಾಳೋದ್ಘಾಟಿನಿ',
'ತಾಱುಂಗಾಮಾಲೆ',
'ತಾಱುಂಬೞಿಯಂ',
'ತಿಂಗಳವರೆ',
'ತಿಂತಿಣಿಗೊಳ್',
'ತಿಂತಿಣಿವಡೆ',
'ತಿಂತಿಣಿವರಿ',
'ತಿಕ್ಕುಮುಕ್ಕಾಟ',
'ತಿಗುಣಂಗೊಳ್',
'ತಿಗುರೇಱಿಸು',
'ತಿಗುಳನಾಡು',
'ತಿಜೂರಿವರ್ಗ',
'ತಿಣ್ಣಗೆಡಿಸು',
'ತಿಣ್ಣಜವ್ವನ',
'ತಿತಿಕ್ಷಾನ್ವಿತ',
'ತಿತ್ತಿರವಕ್ಕಿ',
'ತಿತ್ತಿರಿಗಾಳೆ',
'ತಿತ್ತಿರಿವಕ್ಕಿ',
'ತಿನಸುಗೂಳಿ',
'ತಿನಸುಬಕ್ಕ',
'ತಿನಿಸುಗಾತಿ',
'ತಿನಿಸುಬಕ್ಕ',
'ತಿಪ್ಪರಲಾಗ',
'ತಿಪ್ಪಲುಗಾರ',
'ತಿಮಿರಗಣ್ಣು',
'ತಿಮಿರಪೂಗ',
'ತಿಮಿರರೋಗ',
'ತಿಮಿರಾಪಹ',
'ತಿಮಿಸರಟ',
'ತಿಮುೞನಾಡು',
'ತಿರಸ್ಕರಣ',
'ತಿರಸ್ಕರಣಿ',
'ತಿರಸ್ಕರಿಣಿ',
'ತಿರಸ್ಕರಿಸು',
'ತಿರಿಕಲ್ಲಾಟ',
'ತಿರಿಕಲ್ಲಾಡು',
'ತಿರಿಭುವನ',
'ತಿರಿಮುರಿವು',
'ತಿರಿಯಙ್ಮುಖ',
'ತಿರುಕತನ',
'ತಿರುಕುಳಿಗ',
'ತಿರುಗಾಡಿಸು',
'ತಿರುಗುಬಾಣ',
'ತಿರುತುಳಸಿ',
'ತಿರುನಕ್ಷತ್ರ',
'ತಿರುಪುಗೊಳು',
'ತಿರುಪೆಕೂಳು',
'ತಿರುಪೆಗಾರ',
'ತಿರುಪೆಗಾರ್ತಿ',
'ತಿರುಪ್ರಬಂಧ',
'ತಿರುಮಾಳಿಗೆ',
'ತಿರುಮುರುವು',
'ತಿರುಮುರುಹು',
'ತಿರುವಧ್ಯಾನ',
'ತಿರುವಮುದು',
'ತಿರುವಾಸಿಲು',
'ತಿರುವುವೊಯ್',
'ತಿರುವೆಳಕು',
'ತಿರುವೇರಿಸು',
'ತಿರುಸುಗಣ್ಣು',
'ತಿರುಳುಣಿಸು',
'ತಿರುಳ್ಗನ್ನಡ',
'ತಿರೋಧಾನತೆ',
'ತಿರೋಧಾನಿಸು',
'ತಿರ್ಯಕ್ಕುಂಚಿತ',
'ತಿರ್ಯಗುನ್ನತ',
'ತಿಲಕಪ್ರಾಯ',
'ತಿಲಕಾಲಕ',
'ತಿಲಗಾತಕ',
'ತಿಲಘಾತಕ',
'ತಿಲತರ್ಪಣ',
'ತಿವುಡುಗಳೆ',
'ತಿಳಿಗನ್ನಡ',
'ತಿಳಿಗೊಳಿಸು',
'ತಿಳಿಬೆಳಕು',
'ತಿಳಿಬೆಳಗು',
'ತಿಳಿಮಜ್ಜಿಗೆ',
'ತಿಳಿಮೆಗೊಳ್ಳು',
'ತಿಳಿಲುಮಾತು',
'ತಿಳಿವಟ್ಟಲ್',
'ತಿಳಿವಳಿಕೆ',
'ತಿಳಿವೆಳಕು',
'ತಿಳಿವೆಳಗು',
'ತಿಳಿಹಸುರು',
'ತಿಱಿಕಲ್ಲಾಟ',
'ತಿಱಿಕಲ್ಲಾಡು',
'ತಿಱಿಸೇವಗೆ',
'ತಿಱುವಂಗಡಿ',
'ತೀಕ್ಷ್ಣಗಂಧಕ',
'ತೀಕ್ಷ್ಣಗೊಳಿಸು',
'ತೀಕ್ಷ್ಣೀಕರಣ',
'ತೀಕ್ಷ್ಣೀಕರಿಸು',
'ತೀಟಕಸಂದಿ',
'ತೀಟಕಸಂಧಿ',
'ತೀಟಕಸಿಂಧ',
'ತೀಟಕೋಗಿಲ',
'ತೀಟೆಜಗಳ',
'ತೀರ್ಥಕರತ್ವ',
'ತೀರ್ಥಸ್ವರೂಪ',
'ತೀವ್ರಗೊಳಿಸು',
'ತೀವ್ರಮದಿರೆ',
'ತುಂಗತೆವೆಱು',
'ತುಂಟಕತನ',
'ತುಂಟಕುದುರೆ',
'ತುಂಡಮಾಣಿಕ',
'ತುಂಡುಕೊಡಲಿ',
'ತುಂಡುಗೂದಲು',
'ತುಂಡುದೇವರು',
'ತುಂಡುಮನ್ನೆಯ',
'ತುಂಡುವಕೀಲ',
'ತುಂತುರುಮಳೆ',
'ತುಂತುರುಹನಿ',
'ತುಂದಿಲೋದರ',
'ತುಂಬಿಗುರುಳ್',
'ತುಂಬಿಗುರುಳು',
'ತುಂಬಿಗುರುಳೆ',
'ತುಂಬುಜವ್ವನ',
'ತುಂಬುಜವ್ವನೆ',
'ತುಂಬುರವೀಣೆ',
'ತುಂಬುವರಯ',
'ತುಕ್ಕಡಿದಾರ',
'ತುಗಿಸುಜ್ಜಲು',
'ತುಚ್ಛೀಕರಿಸು',
'ತುಟಿಬೆರಳು',
'ತುಟಿವೆರಳು',
'ತುಟುಕರಿಸು',
'ತುಡುಕುಗಾಯ',
'ತುಡುಗತನ',
'ತುಡುಗದನ',
'ತುಡುಗುಗೊಳ್',
'ತುಡುಗುಣಿಕ',
'ತುಡುಗುತಿನ್',
'ತುಡುಗುತಿನ್ನು',
'ತುಡುಜೊಡರ್',
'ತುಣಗೆಣಸು',
'ತುದಿಕಳಿಸು',
'ತುದಿಗಾಣಿಸು',
'ತುದಿನಾಲಗೆ',
'ತುದಿನಾಲಿಗೆ',
'ತುದಿಮುಟ್ಟಿಸು',
'ತುಪಾಕಸೇನೆ',
'ತುಪ್ಪಗಾರಿಗೆ',
'ತುಪ್ಪಗೆಣಸು',
'ತುಪ್ಪಟಮಣ್',
'ತುಪ್ಪುಳಾಸನ',
'ತುಬ್ಬರಿವಿಕೆ',
'ತುಬ್ಬುಗಾರಿಕೆ',
'ತುಮುಲಯುದ್ಧ',
'ತುಮುಳಯುದ್ಧ',
'ತುರಂಗದಳ',
'ತುರಂಗಪ್ರಾಸ',
'ತುರಕಬೇವು',
'ತುರಗಥಟ್ಟು',
'ತುರಗದಳ',
'ತುರಗದೞ',
'ತುರಗಮೇಧ',
'ತುರಗರತ್ನ',
'ತುರಗರಶ್ಮಿ',
'ತುರಚಿಬಳ್ಳಿ',
'ತುರಬಿಮುದ್ರೆ',
'ತುರಾಷಾಟ್ಖಡ್ಗ',
'ತುರಿಪಂಗೆಯ್',
'ತುರಿಪಕಾಱ',
'ತುರಿಬೀಳ್ಬಳ್ಳಿ',
'ತುರಿಯಾತೀತ',
'ತುರಿಯಾವಸ್ಥೆ',
'ತುರೀಯವರ್ಣ',
'ತುರೀಯವೃತ್ತಿ',
'ತುರೀಯಾವಸ್ಥೆ',
'ತುರೀಯೋಪಾಂತ್ಯ',
'ತುರುಕ ಬೇವು',
'ತುರುಗಾವಲು',
'ತುರುಚುಗಬ್ಬ',
'ತುರುಚುಮಾತು',
'ತುರುತುರನೆ',
'ತುರುವುಮಣೆ',
'ತುರುಷ್ಕಗೌಳ',
'ತುರುಷ್ಕತೋಡಿ',
'ತುಲನಾತ್ಮಕ',
'ತುಲಸಿಕಟ್ಟೆ',
'ತುಲಸಿಮಾಲೆ',
'ತುಲಸೀಮಣಿ',
'ತುಲಾಪುರುಷ',
'ತುವರಮಣ್ಣು',
'ತುಷ್ಟಿಗೊಳಿಸು',
'ತುಷ್ಟಿಬಡಿಸು',
'ತುಷ್ಟಿವಡಿಸು',
'ತುಳಸಿಕಟ್ಟೆ',
'ತುಳಸಿಮಾಲೆ',
'ತುಳಸೀಮಣಿ',
'ತುಳಸೀಮಾಲೆ',
'ತುಳಾಪುರುಷ',
'ತುಳಿಲುಗೆಯ್',
'ತುಳಿಲುಗೆಯಿ',
'ತುಳುಂಕುಗುಟ್ಟು',
'ತುಳುಂಕುದುಂಬು',
'ತುಳುವಕತ್ತಿ',
'ತುಳುವಗಾಯಿ',
'ತುಱಿಪಗಾರ್ತಿ',
'ತುಱಿಪಗಾಱ',
'ತುೞಿಲಾಳ್ತನ',
'ತೂಂಕಡಂಗಾಣ್',
'ತೂಂಕಡುಗೆಡು',
'ತೂಗುಕುದುರೆ',
'ತೂಗುತೊಟ್ಟಿಲು',
'ತೂಗುವೊಡಲು',
'ತೂಗುಸೇತುವೆ',
'ತೂಗುಹಲಗೆ',
'ತೂನಗೆಣಸು',
'ತೂಲಿಕಾತಲ್ಪ',
'ತೂಷ್ಣೀಕರಿಸು',
'ತೂಳವೇಳಿಸು',
'ತೃಣಕುಂಕುಮ',
'ತೃಣಕುಟೀರ',
'ತೃಣಪುರುಷ',
'ತೃಣಮನುಜ',
'ತೃಣೀಕರಿಸು',
'ತೃತೀಯದರ್ಜೆ',
'ತೃತೀಯವೃತ್ತಿ',
'ತೃತೀಯಾಕ್ಷರ',
'ತೃಪತಿಪಡು',
'ತೃಪ್ತಿಗೊಳಿಸು',
'ತೃಪ್ತಿಪಡಿಸು',
'ತೃಪ್ತಿವಡಿಸು',
'ತೆಂಪಡುವಲು',
'ತೆಕ್ಕನೆವೋಗು',
'ತೆಕ್ಕೆಗೆಡಿಸು',
'ತೆಕ್ಕೆತುರುಬು',
'ತೆಕ್ಕೆದುಱುಂಬು',
'ತೆಕ್ಕೆದುಱುಬು',
'ತೆಗಲಮುಗು',
'ತೆಗಳುಗಾರ',
'ತೆಗಳುನಗೆ',
'ತೆಗೆತರಿಸು',
'ತೆಗೆಲಮುಗು',
'ತೆನೆಬಿದಿರು',
'ತೆನೆಮಗುೞ್',
'ತೆರಣಿಗಟ್ಟು',
'ತೆರಪುಕೊಡು',
'ತೆರಪುಗೆಯ್',
'ತೆರಪುಗೊಡು',
'ತೆರಪುಮೊಳೆ',
'ತೆರಹುಗುಡು',
'ತೆರಳ್ಕೆಯಿಡು',
'ತೆರಳ್ಕೆವೆಱು',
'ತೆರಿಗೆದಾರ',
'ತೆರೆಗೊರಳು',
'ತೆರೆವಳಿಗೆ',
'ತೆರೇದಗಿಡ',
'ತೆಲುಗಾಣೆಯ',
'ತೆಲ್ಲಂಟಿಗೊಡು',
'ತೆಲ್ಲಟಿಗೊಳ್',
'ತೆಲ್ಲಿಗಗೇರಿ',
'ತೆವಡೆಗಟ್ಟು',
'ತೆವಲುಗಾರ',
'ತೆವಳೆಗಾಣ್',
'ತೆಳುಗನ್ನಡ',
'ತೆಳುಮಜ್ಜಿಗೆ',
'ತೆಳುವೆಳಗು',
'ತೆಱಪುಗಾಣ್',
'ತೆಱಪುಗುಡು',
'ತೆಱಪುಗೊಡು',
'ತೆಱಹುಕೊಡು',
'ತೆಱಹುಗಾಣ್',
'ತೆಱಹುಗುಡು',
'ತೆಱಹುಗೊಡು',
'ತೇಜಂಗಿಡಿಸು',
'ತೇಪೆಕೆಲಸ',
'ತೇರಹಪಂಥ',
'ತೇಲುನೋಟಕ',
'ತೈಲನಿಕ್ಷೇಪ',
'ತೈಲಪರ್ಣಿಕ',
'ತೈಲಪಾಯಿಕೆ',
'ತೈಲಸ್ಫಟಿಕ',
'ತೈಲಾಭ್ಯಂಗನ',
'ತೈಲಾಭ್ಯಂಜನ',
'ತೊಂಡಬಡಿಗೆ',
'ತೊಂಡಮಂಡಲ',
'ತೊಂಡಿಕೆಗೆಯ್',
'ತೊಂಡುಗೆಡೆತ',
'ತೊಂಡುಗೆಡೆಪ',
'ತೊಂಡುತೊಳಸು',
'ತೊಂದರೆಪಡು',
'ತೊಂಬೆಗಡುಕು',
'ತೊಗಟೆಮರ',
'ತೊಗಟೆವೀರ',
'ತೊಗಲುಗೊಂಬೆ',
'ತೊಗಲುಪಟ್ಟಿ',
'ತೊಗಲುಬೊಂಬೆ',
'ತೊಗಲುಹಕ್ಕಿ',
'ತೊಟ್ಟಿಲತಾಯಿ',
'ತೊಟ್ಟಿಲಬಳ್ಳಿ',
'ತೊಟ್ಟಿಲಮುಳ್ಳು',
'ತೊಟ್ಟಿಲುಹಬ್ಬ',
'ತೊಟ್ಟುಕಳಚು',
'ತೊಟ್ಟುಕೞಲ್',
'ತೊಡಂಕುವಲೆ',
'ತೊಡಂದುಡಿಸು',
'ತೊಡಂಬೆಪಱಿ',
'ತೊಡಂಬೆವಿಡು',
'ತೊಡಕುಗಾಲು',
'ತೊಡಕುನೇಣು',
'ತೊಡಕುಬೇರು',
'ತೊಡಕುಮೆಳೆ',
'ತೊಡಕುವಲೆ',
'ತೊಡಕುವಳೆ',
'ತೊಡಕುವೀಥಿ',
'ತೊಡರುಗಟ್ಟು',
'ತೊಡರುಗಾಲು',
'ತೊಡರುಗೊಳ್ಳು',
'ತೊಡರುಮುಳ್ಳು',
'ತೊಡರ್ಚುವಲೆ',
'ತೊಡವಣಿಕ',
'ತೊಡಹತರ್',
'ತೊಡೆಚಲ್ಲಣ',
'ತೊಡೆಯೇಱಿಸು',
'ತೊಡೆಸಂಕಲೆ',
'ತೊತ್ತಳಂತುಳಿ',
'ತೊತ್ತಳಂದುಳಿ',
'ತೊತ್ತಳತುಳಿ',
'ತೊತ್ತಳದುಳಿ',
'ತೊತ್ತಳಿಗೊಳ್',
'ತೊತ್ತೞಂದುೞಿ',
'ತೊತ್ತೞದುೞಿ',
'ತೊತ್ತುಗೆಲಸ',
'ತೊತ್ತುಬಡಕ',
'ತೊತ್ತುಬಡಿಕ',
'ತೊತ್ತುವೆಗ್ಗಡೆ',
'ತೊದಲುನುಡಿ',
'ತೊದಲುಮಾಡು',
'ತೊದಲುಮಾತು',
'ತೊದಳುನುಡಿ',
'ತೊನ್ನುಬಡಕ',
'ತೊನ್ನುಬಡಕಿ',
'ತೊಪತೊಪನೆ',
'ತೊರಕೆಬಲೆ',
'ತೊರೆದವನ',
'ತೊರೆನೇರಲು',
'ತೊರೆಬಿಡಿಸು',
'ತೊರೆಯಿಂಗುಳ',
'ತೊರೆಯಿೞುಪು',
'ತೊವರಮಣ್ಣು',
'ತೊಳಲಿಕಲ್ಲು',
'ತೊಳಸಂಬಟ್ಟೆ',
'ತೊಳಸುಗೊಳ್ಳು',
'ತೊಳ್ತಳಂದುಳಿ',
'ತೊಳ್ತುಗೆಲಸ',
'ತೊಱೆಕಡವ',
'ತೊಱೆದವನ',
'ತೊಱೆಮಲ್ಲಿಗೆ',
'ತೊಱೆಯಿಂಗುಳ',
'ತೊಱ್ತುಳಿದುಳಿ',
'ತೊೞಲಿಕಲ್',
'ತೊೞಲಿಕಲ್ಲು',
'ತೊೞ್ತುಗೆಲಸ',
'ತೊೞ್ತುತೊಡರ್',
'ತೊೞ್ತುೞಿದುೞಿ',
'ತೋಂಟವಾಳತಿ',
'ತೋಂಟವಾಳಿಗ',
'ತೋಂಟಿಗವೆಸ',
'ತೋಂಡಿಪರೀಕ್ಷೆ',
'ತೋಟಗಾರಿಕೆ',
'ತೋಟತುಡಿಕೆ',
'ತೋಟವಾಳಿಗ',
'ತೋಡುಗೆಲಸ',
'ತೋಡುಹಾವುಗೆ',
'ತೋಯನಿಧಾನ',
'ತೋಯಪಿಪ್ಪಲಿ',
'ತೋಯಿಪಿಪ್ಪಲು',
'ತೋರಣಂಗಟ್ಟು',
'ತೋರಣಗಂಬ',
'ತೋರಣಗಂಭ',
'ತೋರಣಗಟ್ಟು',
'ತೋರಣಗಲ್ಲು',
'ತೋರಣದ್ವಾರ',
'ತೋರಣವಿಡು',
'ತೋರುಗದ್ದಿಗೆ',
'ತೋರುಗಾಣಿಕೆ',
'ತೋರುಬೆರಳು',
'ತೋರ್ತಳದುಳಿ',
'ತೋಲನಯಂತ್ರ',
'ತೋಹುಗಾಣಿಸು',
'ತೋಳುಕಯ್ಗೊಡು',
'ತೋಳುತೊಡಿಗೆ',
'ತೋಱಿಕೆವೆಱು',
'ತೌಂಬುರವೀಣೆ',
'ತೌಲನಿಕತೆ',
'ತ್ಯಾಗಶೀಲತೆ',
'ತ್ರಯಿರಾಶಿಕ',
'ತ್ರಸಕಾಯಿಕ',
'ತ್ರಸ್ತರಿಯಾಡು',
'ತ್ರಸ್ತಾಲಸಕ',
'ತ್ರಾಣಗುಂದಿಸು',
'ತ್ರಾಸದಾಯಕ',
'ತ್ರಾಸುಗಟ್ಟಳೆ',
'ತ್ರಿಕಟುಚೂರ್ಣ',
'ತ್ರಿಕಾಲಜ್ಞಾನ',
'ತ್ರಿಕಾಲಜ್ಞಾನಿ',
'ತ್ರಿಕಾಲದರ್ಶಿ',
'ತ್ರಿಕಾಳಭೋಗ',
'ತ್ರಿಕೂಟಸ್ಥಾನ',
'ತ್ರಿಕೂಟಾಚಲ',
'ತ್ರಿಕೋಣಪಾತ',
'ತ್ರಿಕೋಣಮಿತಿ',
'ತ್ರಿಗುಣಕೂಟ',
'ತ್ರಿಗುಣತತ್ವ',
'ತ್ರಿಗುಣಾತ್ಮಕ',
'ತ್ರಿಗುಪ್ತಿಗುಪ್ತ',
'ತ್ರಿಪದೋನ್ನತಿ',
'ತ್ರಿಪೀಡಿದಾನ',
'ತ್ರಿಪುಟಿಜ್ಞಾನ',
'ತ್ರಿಪುಟೀಜ್ಞಾನ',
'ತ್ರಿಪುಟೀಸ್ಥಾನ',
'ತ್ರಿಪುರವಾಸ',
'ತ್ರಿಭಂಗಭಂಗಿ',
'ತ್ರಿಮಾತ್ರಾಗಣ',
'ತ್ರಿಶಂಕುರಾಶಿ',
'ತ್ರಿಶಂಕುಸ್ಥಾನ',
'ತ್ರಿಶಂಕುಸ್ಥಿತಿ',
'ತ್ರಿಶಂಕುಸ್ವರ್ಗ',
'ತ್ರಿಸಂಧಿಗ್ರಾಹಿ',
'ತ್ವಗಸ್ಥಿಭೂತ',
'ತ್ವಗಸ್ಥೀಭೂತ',
'ತ್ವಗ್ಗತಜ್ವರ',
'ತ್ವರೆಪಡಿಸು',
'ಥಟ್ಟುಗೆಡಹು',
'ಥರಥರನೆ',
'ಥರಥರಿಸು',
'ಥರ್ಮಾಮೀಟರು',
'ಥಳಕುಗಾತಿ',
'ಥಳಕುಗಾರ',
'ಥಳತ್ಥಳಿಸು',
'ಥಳಥಳನೆ',
'ಥಳಥಳಿಕೆ',
'ಥಳಥಳಿಲು',
'ಥಳಥಳಿಸು',
'ಥಳಪಳನೆ',
'ಥಳುಕುಬಟ್ಟು',
'ದಂಗುಬಡಿಸು',
'ದಂಡಕಾರಣ್ಯ',
'ದಂಡಧಾರಣ',
'ದಂಡನಮನ',
'ದಂಡನಾಯಕ',
'ದಂಡಪಾರುಷ್ಯ',
'ದಂಡಪಾಶಕ',
'ದಂಡಪ್ರಣಮ',
'ದಂಡಪ್ರಣಾಮ',
'ದಂಡಭ್ರಮರಿ',
'ದಂಡರೇಚಿತ',
'ದಂಡವಿಘಾತಿ',
'ದಂಡಾಧಿನಾಥ',
'ದಂಡಾಧ್ಯಕ್ಷಕ',
'ದಂಡಿಗೆಗೋಲು',
'ದಂಡುೞಿಗೊಳ್',
'ದಂಡೆಗೊಳಿಸು',
'ದಂಡೆತೊಂಡಿಲ್',
'ದಂಡೆದೊಂಡಿಲ್',
'ದಂತಗೋಪುರ',
'ದಂತಚಲನ',
'ದಂತದಂಶಿತ',
'ದಂತಧಾವನ',
'ದಂತಪೀಠಿಕೆ',
'ದಂತಪುಪ್ಪುಟ',
'ದಂತಮಂಜನ',
'ದಂತಮೂಲೀಯ',
'ದಂತಾವರಣ',
'ದಂತಿಗಮನೆ',
'ದಂತುರಚಕ್ರ',
'ದಂದರಹಾಳ',
'ದಂದರಹಾಳಿ',
'ದಂದಹ್ಯಮಾನ',
'ದಂದುಗಂಬಡು',
'ದಂದುಗಂಬೊಱು',
'ದಂದುಗಗೊಳ್',
'ದಂದುಗಬಡು',
'ದಂಧನಂಗೆಯ್',
'ದಂಪತಿಕ್ಷೇತ್ರ',
'ದಂಪತಿಗೆಯ್',
'ದಂಪತಿಪೂಜೆ',
'ದಂಪತಿವಕ್ಕಿ',
'ದಕ್ಕಡತನ',
'ದಕ್ಕಾಲಿಬೀಳು',
'ದಕ್ಕಾಲಿಬೀೞ್',
'ದಕ್ಕಾಲಿವೀೞ್',
'ದಕ್ಕುಗೊಳಿಸು',
'ದಕ್ಷಿಣಧ್ರುವ',
'ದಕ್ಷಿಣಪತಿ',
'ದಕ್ಷಿಣಾಗತಿ',
'ದಕ್ಷಿಣಾಪಥ',
'ದಕ್ಷಿಣಾಯಣ',
'ದಕ್ಷಿಣಾಯನ',
'ದಗದಗನೆ',
'ದಗದಗಿಸು',
'ದಗಲಬಾಜಿ',
'ದಟದಟಿಸು',
'ದಟ್ಟಡಿಕಾಱ',
'ದಟ್ಟಡಿಯಿಡು',
'ದಟ್ಟದರಿದ್ರ',
'ದಟ್ಟದಾರಿದ್ರ್ಯ',
'ದಟ್ಟಿಗಟ್ಟಿಸು',
'ದಡಂದಡಿಸು',
'ದಡದಡನೆ',
'ದಡದಡಿಸು',
'ದಡಪಡಿಸು',
'ದಡಪಶಾಹಿ',
'ದಡಬಡನೆ',
'ದಡಬಡಲು',
'ದಡಬಡಿಸು',
'ದಡಿಧೋತರ',
'ದಣಿವಾರಿಸು',
'ದಣಿವುದೋರು',
'ದಣಿವುಹತ್ತು',
'ದಣಿವೈದಿಸು',
'ದಣುವಾರಿಸು',
'ದಣ್ಣಾಯಕಿತ್ತಿ',
'ದತ್ತಮಂಡಲ',
'ದತ್ತಾಪಹಾರ',
'ದತ್ತಾವಧಾನ',
'ದತ್ತುಮಗಳು',
'ದಧಿವಿಘಾತ',
'ದನಗಾರಿಕೆ',
'ದನಗಾಹಿಗ',
'ದನಗೊಡಿಗೆ',
'ದನಗೊಬ್ಬರ',
'ದನಬದುಕು',
'ದನಿಗೂಡಿಸು',
'ದನಿದೋಱಿಸು',
'ದನುಜಕುಲ',
'ದಪದಪನೆ',
'ದಪ್ತರಖಾನೆ',
'ದಪ್ತರದಾರ',
'ದಪ್ಪವೇಱಿಸು',
'ದಫನಕ್ರಿಯೆ',
'ದಫನಭೂಮಿ',
'ದಫ್ತರ್‍ಬಂದಿ',
'ದಫ್ತರ್‍ಶಾಹಿ',
'ದಫ್ತರಖಾನೆ',
'ದಫ್ತರದಾರ',
'ದಫ್ತರಬಂದಿ',
'ದಬಡ್ಡಾಳಿಕೆ',
'ದಬ್ಬಣಕಾಟಿ',
'ದಬ್ಬಣಕಾಠಿ',
'ದಬ್ಬಣಸಾಲಿ',
'ದಮಗೊಳಗ',
'ದಮನಗೊಳ್ಳು',
'ದಮನಶೀಲ',
'ದಮನಶೀಲೆ',
'ದಮ್ಮಗಹಳೆ',
'ದಮ್ಮಶಾಸನ',
'ದಯನೀಯತೆ',
'ದಯಪಾಲಿಸು',
'ದಯಮಾಡಿಸು',
'ದಯಾಕಟಾಕ್ಷ',
'ದಯಾದರಿದ್ರ',
'ದಯಾಮರಣ',
'ದಯೆಪಾಲಿಸು',
'ದಯೆವೆರಸು',
'ದರಕದಾರ',
'ದರಕುದನಿ',
'ದರಜವಾಬು',
'ದರದರನೆ',
'ದರದಳಿತ',
'ದರದುಹುಂಡಿ',
'ದರಹಮೇಷ',
'ದರಹಸನ',
'ದರಹಸಿತ',
'ದರಹುಣಿಸೆ',
'ದರಿಗೆಡಹು',
'ದರಿದ್ರತನ',
'ದರಿದ್ರಲಕ್ಷ್ಮಿ',
'ದರಿಯಾಮಾರ್ಗ',
'ದರುವುಹಿಡಿ',
'ದರುಶನಾರ್ಥಿ',
'ದರೋಡೆಕಾತಿ',
'ದರೋಡೆಕಾರ',
'ದರೋಡೆಕೋರ',
'ದರೋಡೆಖೋರ',
'ದರ್ಪಣನ್ಯಾಯ',
'ದರ್ಪಣಲಿಪಿ',
'ದರ್ಬಾರ್‍ಭಕ್ಷಿ',
'ದರ್ಮಶಾಸನ',
'ದರ್ಶನಕಾರ',
'ದರ್ಶನಧ್ವನಿ',
'ದರ್ಶನಹುಂಡಿ',
'ದಲ್ಲಾಳಿತನ',
'ದಲ್ಲಾಳಿವಟ್ಟ',
'ದವಡತನ',
'ದವದಹನ',
'ದವಸಗಿತಿ',
'ದವಸಾದಾಯ',
'ದವಾಲಿಬಿಲ್ಲೆ',
'ದಶಕರಣ',
'ದಶನಚ್ಛದ',
'ದಶನವಾಸ',
'ದಶರೂಪಕ',
'ದಶಾವತಾರ',
'ದಸಬುಸನೆ',
'ದಸ್ತ ಐವಜ',
'ದಸ್ತರ್‍ಹಾನು',
'ದಹನಕರ್ಮ',
'ದಹನಕುಂಡ',
'ದಹನಕ್ರಿಯೆ',
'ದಹನೋಪಲ',
'ದಹನೋಪಳ',
'ದಳಂಗೊಳಿಸು',
'ದಳದಳನೆ',
'ದಳದಳಿತ',
'ದಳದಳಿಸು',
'ದಳದುಳನೆ',
'ದಳದುಳಿಗ',
'ದಳದುಳಿಸು',
'ದಳನಾಯಕ',
'ದಳವಡಿಗ',
'ದಳವಾಯ್ತನ',
'ದಳವುಳಿಸು',
'ದಳವೇಱಿಸು',
'ದಳ್ಳಾಳತನ',
'ದಳ್ಳಾಳಿತನ',
'ದಱದಱನೆ',
'ದಾಂಗುಡಿಬಿಡು',
'ದಾಂಗುಡಿಯಿಡು',
'ದಾಂಗುಡಿವಡೆ',
'ದಾಂಗುಡಿವರಿ',
'ದಾಂಗುಡಿವಿಡು',
'ದಾಂಡಾಜಿನಿಕ',
'ದಾಂಡಿಗತನ',
'ದಾಂಪತ್ಯಧರ್ಮ',
'ದಾಕ್ಷಿಣಾತ್ಯಕ',
'ದಾಕ್ಷಿಣ್ಯಪರ',
'ದಾಕ್ಷಿಣ್ಯವಂತ',
'ದಾಕ್ಷಿಣ್ಯವರ್ತಿ',
'ದಾಖಲೆ ಗ್ರಾಮ',
'ದಾಟುವರಸೆ',
'ದಾಟುಹೊಲಿಗೆ',
'ದಾಡಾಬಂಧನ',
'ದಾಡಿಮಾಷ್ಟಕ',
'ದಾಣಧಡಿಯ',
'ದಾನವವಿದ್ಯೆ',
'ದಾನಶಾಸನ',
'ದಾನಶೀಲತೆ',
'ದಾಪುನಡಗೆ',
'ದಾಯಂಗಿಡಿಸು',
'ದಾಯಗಾಱಿಕೆ',
'ದಾಯಗೆಡಿಸು',
'ದಾಯನಿರ್ಣಯ',
'ದಾಯವಿಭಾಗ',
'ದಾಯಾದಿತನ',
'ದಾಯಾಧಿಕಾರ',
'ದಾಯಿಗತನ',
'ದಾರಣೆವಾಸಿ',
'ದಾರವಟ್ಟಗ',
'ದಾರವಟ್ಟಲು',
'ದಾರಸಂಗ್ರಹ',
'ದಾರಿಗಾಣಿಸು',
'ದಾರಿಗುಟಿಗ',
'ದಾರಿತೋರಿಕೆ',
'ದಾರಿತೋರಿಸು',
'ದಾರಿದ್ರ್ಯಲಕ್ಷ್ಮಿ',
'ದಾರಿಸಂಗಡ',
'ದಾರಿಸವೆಸು',
'ದಾರುಕದಂಬ',
'ದಾರುಜೀವನ',
'ದಾರುಣವಡೆ',
'ದಾರುಹಸ್ತಕ',
'ದಾರೆಗಡಗ',
'ದಾರೆಬಟ್ಟಲು',
'ದಾರೆವಟ್ಟಲ್',
'ದಾರೆವಟ್ಟಲು',
'ದಾವಂತಬಡು',
'ದಾವಣಿಗಟ್ಟು',
'ದಾವಣಿಗೊಳ್',
'ದಾವತಿಗೊಳ್',
'ದಾವತಿಗೊಳ್ಳು',
'ದಾವತಿಪಡು',
'ದಾವತಿಬಡು',
'ದಾವತಿವೆಱು',
'ದಾವತಿಹಿಡಿ',
'ದಾವದಹನ',
'ದಾವಪಾವಕ',
'ದಾಷ್ಟಿಕತನ',
'ದಾಸಗೆಲಸ',
'ದಾಸಪದ್ಧತಿ',
'ದಾಸರಕಬ್ಬು',
'ದಾಸರಹಾವು',
'ದಾಸರಿಕಬ್ಬು',
'ದಾಸರಿಹಾವು',
'ದಾಸವಾಙ್ಮಯ',
'ದಾಸಸಾಹಿತ್ಯ',
'ದಾಸೋಹತನ',
'ದಾಸೋಹಿತನ',
'ದಾಸ್ಯಪದ್ಧತಿ',
'ದಾಸ್ಯಶೃಂಖಲೆ',
'ದಾಹಕಶಕ್ತಿ',
'ದಾಹನಕರ್ಮ',
'ದಾಹಿಕಾಶಕ್ತಿ',
'ದಾಳಿಂಬಾಷ್ಟಕ',
'ದಾಳಿಯಿಡಿಸು',
'ದಾಳಿವರಿಸು',
'ದಿಂಡಿಗತನ',
'ದಿಂಡುಗೆಡಪು',
'ದಿಂಡುಗೆಡಹು',
'ದಿಂಡುದಳಿರು',
'ದಿಂಡುದಿರುಹು',
'ದಿಂಡುರುಳಿಕೆ',
'ದಿಂಡುರುಳಿಚು',
'ದಿಂಡುರುಳಿಸು',
'ದಿಂಡುರುಳ್ಚಿಸು',
'ದಿಂಡೆಬಸವಿ',
'ದಿಂಡೆಯತನ',
'ದಿಕ್ಕುತಪ್ಪಿಸು',
'ದಿಕ್ಪಾಲತನ',
'ದಿಕ್ಪ್ರದರ್ಶನ',
'ದಿಗಂಬರತ್ವ',
'ದಿಗಡುದಿಮ್ಮಿ',
'ದಿಗದಿಗನೆ',
'ದಿಗಿಲುಗೊಳ್',
'ದಿಗಿಲುಗೊಳು',
'ದಿಗಿಲುಗೊಳ್ಳು',
'ದಿಗಿಲುಬಡಿ',
'ದಿಗಿಲುಬೀಳು',
'ದಿಗಿಲುಹಾರು',
'ದಿಗುಪಾಲಕ',
'ದಿಗುಬಂಧನ',
'ದಿಗುಮಂಡಲ',
'ದಿಗುರುತ್ತರ',
'ದಿಗ್ಭ್ರಮೆಗೊಳ್ಳು',
'ದಿಙ್ಮೂಢನಾಗು',
'ದಿಟಕರಿಸು',
'ದಿಟ್ಟಂಗೊಳಿಸು',
'ದಿಟ್ಟಪಡಿಸು',
'ದಿಟ್ಟಾಯ್ಲತನ',
'ದಿಟ್ಟಿಗೊಳಿಸು',
'ದಿಟ್ಟಿವೆಳಗು',
'ದಿಟ್ಟಿಹಾಯಿಸು',
'ದಿಡ್ಡಿಬಾಗಿಲು',
'ದಿತಿಜಕುಲ',
'ದಿಧಿಷೂಪತಿ',
'ದಿನಚರಿಯ',
'ದಿನಚಲನ',
'ದಿನಪತ್ರಿಕೆ',
'ದಿನಬಳಕೆ',
'ದಿನಮಾಳಿಕೆ',
'ದಿನವರ್ತನೆ',
'ದಿನಾಚರಣೆ',
'ದಿನಾವಸಾನ',
'ದಿಬ್ಬಣಂಬೋಗು',
'ದಿಮಿದಿಮಿಸು',
'ದಿಮ್ಮದಿರುಗ',
'ದಿಮ್ಮದಿರುಗು',
'ದಿಮ್ಮುದಿರುಗ',
'ದಿಮ್ಮುದಿರುಗು',
'ದಿಲ್ದಾರತನ',
'ದಿಲ್ಭರವಸಾ',
'ದಿವಸಕರ',
'ದಿವಸಮುಖ',
'ದಿವಾಕೀರ್ತಿಕ',
'ದಿವಾಣಖಾನೆ',
'ದಿವಾಣಗಿರಿ',
'ದಿವಾಣಿಕಜೆ',
'ದಿವಾಣಿಕೋರ್ಟು',
'ದಿವಾಣಿಬಾಬು',
'ದಿವಾನ್‍ಖಾನೆ',
'ದಿವಾನಖಾನ',
'ದಿವಾನಖಾನೆ',
'ದಿವಾನಗಿರಿ',
'ದಿವಾವಸಾನ',
'ದಿವಾಳಿಖೋರ',
'ದಿವಾಳಿತನ',
'ದಿವಿಜಕುಜ',
'ದಿವಿಜಚಾಪ',
'ದೀಕ್ಷಾಗ್ರಹಣ',
'ದೀಕ್ಷಿತತನ',
'ದೀಕ್ಷೆಬೆಳಸು',
'ದೀಕ್ಷೆವಹಿಸು',
'ದೀಡಪಂಡಿತ',
'ದೀನ್‍ಇಲಾಹಿ',
'ದೀಪಕನೃತ್ಯ',
'ದೀಪಕಲಿಕೆ',
'ದೀಪಕಳಿಕೆ',
'ದೀಪಧಾರಿಣಿ',
'ದೀಪನಚೂರ್ಣ',
'ದೀಪಾಂಕುರಂಗೈ',
'ದೀಪಾರಾಧನೆ',
'ದೀಪಾಲಂಕಾರ',
'ದೀಪಾಲೆಕಂಭ',
'ದೀಪಾವಳಿಕೆ',
'ದೀಪಿಕಾಂಕುರ',
'ದೀರ್ಘಕಾಲಿಕ',
'ದೀರ್ಘಕೋಶಿಕೆ',
'ದೀರ್ಘಗೊಳಿಸು',
'ದೀರ್ಘತ್ರಿಭುಜೆ',
'ದೀರ್ಘದರ್ಶಿನಿ',
'ದೀರ್ಘನಿದ್ರಿತ',
'ದೀರ್ಘಪ್ರಣಾಮ',
'ದೀರ್ಘವರ್ತುಲ',
'ದೀರ್ಘವೃತ್ತೀಯ',
'ದೀರ್ಘಾಲೋಚನೆ',
'ದೀರ್ಘೀಕರಣ',
'ದೀವಸಂಗೆಡು',
'ದೀವಿಹಲಸು',
'ದುಂಡುಕಡಗ',
'ದುಂಡುತಗಸಿ',
'ದುಂಡುಮಲ್ಲಿಗೆ',
'ದುಂಡುಸಂಪಿಗೆ',
'ದುಂದುಗಾರಿಕೆ',
'ದುಂದುಗೆಡಿಸು',
'ದುಂದುಭಿಪದ',
'ದುಂದುಮಿಪದ',
'ದುಂಬಾಲಗೊಳ್',
'ದುಂಬಾಲಬೀಳು',
'ದುಂಬಾಲುಬೀಳು',
'ದುಃಖಪಡಿಸು',
'ದುಃಖಭಾಜನ',
'ದುಃಖಭಾಜನೆ',
'ದುಕಾನುದಾರ',
'ದುಗುಪಯಣ',
'ದುಗ್ಧಪಾಷಾಣ',
'ದುಗ್ಧವಾರಿಧಿ',
'ದುಗ್ಧವಾಹಿನಿ',
'ದುಡದುಡನೆ',
'ದುಡಿತಗಾರ',
'ದುಡಿಮೆಗಾರ',
'ದುಡುಕುತನ',
'ದುಡುಕುದೋರು',
'ದುಡುದುಡನೆ',
'ದುಡುದುಡಿಕೆ',
'ದುಪ್ಪೆಗೆಣಸು',
'ದುಬ್ಬಗಾಣಿಸು',
'ದುಮದುಮಿಸು',
'ದುಮುದುಮನೆ',
'ದುಮುದುಮಿಸು',
'ದುಮ್ಮಾನಗೊಳು',
'ದುಮ್ಮಾನವಡು',
'ದುರದುರನೆ',
'ದುರಭಿಪ್ರಾಯ',
'ದುರಭಿಮತ',
'ದುರಭಿಮಾನ',
'ದುರಭಿಮಾನಿ',
'ದುರಭಿಸಂಧಿ',
'ದುರಭಿಹಿತ',
'ದುರವಗಾಹ',
'ದುರವಗಾಹ್ಯ',
'ದುರಹಂಕಾರ',
'ದುರಹಂಕಾರಿ',
'ದುರಹಂಕೃತ',
'ದುರಾಕ್ರಮಣ',
'ದುರಾಚರಣ',
'ದುರಾಚರಣೆ',
'ದುರಾಚಾರಿಣಿ',
'ದುರಾಭಿಮಾನ',
'ದುರಾಲೋಕನ',
'ದುರಾಲೋಚನೆ',
'ದುರಾಲೋಚಿಸು',
'ದುರಾಶೆಗಾರ',
'ದುರಿತಕಾರಿ',
'ದುರಿತಯುತ',
'ದುರುದುಂಡಗೆ',
'ದುರುದುಂಬಿಗ',
'ದುರುದುರನೆ',
'ದುರುದುರಿಸು',
'ದುರುಪಯೋಗ',
'ದುರುಳತನ',
'ದುರುಳುತನ',
'ದುರ್ಗತಿವಡೆ',
'ದುರ್ಗತಿವೋಗು',
'ದುರ್ಗಪ್ರಾಕಾರ',
'ದುರ್ಗಪ್ರಾಚೀರ',
'ದುರ್ಗಸಂಚರ',
'ದುರ್ಗೀವರಹ',
'ದುರ್ಗುಣವತಿ',
'ದುರ್ದಮನೀಯ',
'ದುರ್ನಿರೀಕ್ಷಣ',
'ದುರ್ನೀತಿಕಾರ',
'ದುರ್ಬಲಗೊಳ್',
'ದುರ್ಬಲಗೊಳ್ಳು',
'ದುರ್ಭಾವಚಾರಿ',
'ದುರ್ವಿನಿಯೋಗ',
'ದುರ್ವಿನೀತತೆ',
'ದುರ್ವಿಲಸಿತ',
'ದುರ್ವಿಳಸನ',
'ದುರ್ವೃತ್ತತನ',
'ದುರ್ವ್ಯವಸಾಯ',
'ದುರ್ವ್ಯವಹಾರ',
'ದುರ್ವ್ಯಸನಿಗ',
'ದುರ್ವ್ಯಾಪಾರಿಸು',
'ದುಶ್ಶೀಲವಂತ',
'ದುಷ್ಕೃತಕಾರಿ',
'ದುಷ್ಟಾನುಮಾನ',
'ದುಷ್ಪರಿಣಾಮ',
'ದುಷ್ಪ್ರತಿಕಾರ',
'ದುಸ್ತಾಪಚತ್ವ',
'ದುಸ್ಸಹವಾಸ',
'ದುಸ್ಸಾಹಸಿಕ',
'ದುಳಿರವಟ',
'ದುಱದುಱಿಸು',
'ದುಱುದುಂಬಿಗ',
'ದುಱುದುಂಬಿನಿ',
'ದುಱುದುಱನೆ',
'ದುಱುದುಱಿಸು',
'ದೂಬರದಿಂಡಿ',
'ದೂರತೋಪಾಸ್ತ',
'ದೂರದರ್ಶಕ',
'ದೂರದರ್ಶನ',
'ದೂರದರ್ಶಿತ್ವ',
'ದೂರದರ್ಶಿನಿ',
'ದೂರಮಾಡಿಸು',
'ದೂರಮಾಪಕ',
'ದೂರಮಾಪನ',
'ದೂರಮುದ್ರಕ',
'ದೂರಮುದ್ರಣ',
'ದೂರಲೇಖನ',
'ದೂರವೀಕ್ಷಣ',
'ದೂರಶ್ರವಣ',
'ದೂರಸಂಪರ್ಕ',
'ದೂರಾಲೋಚನೆ',
'ದೂರೀಕರಿಸು',
'ದೂಸರಂದಾಳ್',
'ದೂಳಿಕಡವ',
'ದೂಳಿಗಡವ',
'ದೂಳಿದರ್ಶನ',
'ದೂಳಿಸೆಜ್ಜರ',
'ದೂಳುಗೆದರು',
'ದೂಳುಗೆದಱು',
'ದೂಳುಪಾವಡ',
'ದೃಕ್ರಿಯಾತ್ಮಕ',
'ದೃಢಚಿತ್ತತೆ',
'ದೃಢಪಡಿಸು',
'ದೃಢಪ್ರತ್ಯಯ',
'ದೃಢಾಯತಿಕೆ',
'ದೃಢೀಕರಣ',
'ದೃಢೀಕರಿಸು',
'ದೃಷ್ಟಿಗೋಚರ',
'ದೃಷ್ಟಿದ್ರವಣ',
'ದೃಷ್ಟಿದ್ರಾವಣ',
'ದೃಷ್ಟಿಪಟಲ',
'ದೃಷ್ಟಿಪಾಟವ',
'ದೃಷ್ಟಿಮಾಪಕ',
'ದೃಷ್ಟಿವಲಯ',
'ದೃಷ್ಟಿವಾಳಿಕೆ',
'ದೃಷ್ಟಿವಿಕ್ಷೇಪ',
'ದೃಷ್ಟಿವೈಶಾಲ್ಯ',
'ದೃಷ್ಟಿವ್ಯಪೇತ',
'ದೃಷ್ಟಿಸಾಕಲ್ಯ',
'ದೃಷ್ಟಿಹೀನತೆ',
'ದೆಖ್ಖಾಯ್ಲತನ',
'ದೆಖ್ಖಾಳಂಗುಡು',
'ದೆಖ್ಖಾಳತನ',
'ದೆಖ್ಖಾಳಭೂಮಿ',
'ದೆವಸವಳ',
'ದೆವ್ವಬಿಡಿಸು',
'ದೆಸೆಗಿಡಿಸು',
'ದೆಸೆಗೆಡಿಸು',
'ದೆಸೆದೇವತೆ',
'ದೇಗುಲಕಾಱ',
'ದೇದೀಪ್ಯಮಾನ',
'ದೇನಗವಳೆ',
'ದೇವಕಂಟಕ',
'ದೇವಕಣಿಶ',
'ದೇವಕನ್ನಿಕೆ',
'ದೇವಕಾಂಚನ',
'ದೇವಕುವರ',
'ದೇವಕುಸುಮ',
'ದೇವಗಣಿಕೆ',
'ದೇವಗರಿಗೆ',
'ದೇವಗಾಂಧಾರ',
'ದೇವಗಾಂಧಾರಿ',
'ದೇವಗೊಳಗ',
'ದೇವಜಗ್ಧಕ',
'ದೇವಜನಿತ',
'ದೇವಜಾರಿಗೆ',
'ದೇವಡಂಗರ',
'ದೇವತಾತತ್ವ',
'ದೇವತಾಮುೂಢ',
'ದೇವತಾರ್ಚನೆ',
'ದೇವತಾಲಯ',
'ದೇವತಾಸ್ಥಾನ',
'ದೇವತ್ರಿತಯ',
'ದೇವದಾವರೆ',
'ದೇವದುರ್ಲಭ',
'ದೇವನಗರಿ',
'ದೇವನಹುಳಿ',
'ದೇವನಾಗರಿ',
'ದೇವಪುತ್ರಕ',
'ದೇವಬಾಭೂಳ',
'ದೇವಬ್ರಾಹ್ಮಣ',
'ದೇವಮಂದಿರ',
'ದೇವಮಾತೃಕ',
'ದೇವಮಾತೃಕೆ',
'ದೇವಮಾದಳ',
'ದೇವಮಾನವ',
'ದೇವಮೂಢತ್ವ',
'ದೇವಯಜನ',
'ದೇವವಾನಸ',
'ದೇವಸಪರ್ಯೆ',
'ದೇವಸಾಸವೆ',
'ದೇವಸಾಸಿವೆ',
'ದೇವಸುರಭಿ',
'ದೇವಸ್ವನಿಧಿ',
'ದೇವಾಂಗವಸ್ತ್ರ',
'ದೇವಾನಾಂಪ್ರಿಯ',
'ದೇವಾಯತನ',
'ದೇವಿಹಾಕಿಸು',
'ದೇವುಳಿಗಿತಿ',
'ದೇವೇಂದ್ರಚಾಪ',
'ದೇವೋಪಘಾತ',
'ದೇಶಪದ್ಧತಿ',
'ದೇಶಬಾಂಧವ',
'ದೇಶಬಾದಾಮಿ',
'ದೇಶಭ್ರಷ್ಟತೆ',
'ದೇಶಭ್ರಷ್ಟತ್ವ',
'ದೇಶವಾಚಕ',
'ದೇಶವಿರತ',
'ದೇಶವಿರತಿ',
'ದೇಶಸಂಚಾರ',
'ದೇಶಾಂತರಿಗ',
'ದೇಶಾಭಿಮಾನ',
'ದೇಶಾಭಿಮಾನಿ',
'ದೇಶಿಕತೋಡಿ',
'ದೇಶಿಕೋತ್ತಮ',
'ದೇಶ್ಯಕಾಂಭೋಜಿ',
'ದೇಸಾಯಿಗೌಡ',
'ದೇಸಾಯಿತನ',
'ದೇಸಿಗತನ',
'ದೇಸಿಗಾಱಿಕೆ',
'ದೇಹದಂಡಣೆ',
'ದೇಹದಂಡನೆ',
'ದೇಹಧಾರಣ',
'ದೇಹನಿಸ್ಪೃಹ',
'ದೇಹಪೋಷಣೆ',
'ದೇಹಪ್ರಕೃತಿ',
'ದೇಹವಂಚಕ',
'ದೇಹವಾಸನೆ',
'ದೇಹಶೋಧನೆ',
'ದೇಹಸಂಬಂಧ',
'ದೇಹಸಂಬಂಧಿ',
'ದೇಹಾಂತಶಿಕ್ಷೆ',
'ದೇಹಾತಿರಿಕ್ತ',
'ದೇಹಾಭಿಲಾಷೆ',
'ದೇಹಾರಂಗೆಯ್',
'ದೇಹಾರಗೃಹ',
'ದೇಹಾರಾಗಾರ',
'ದೇಹಾವಸಾನ',
'ದೇಹೋಪಚಾರ',
'ದೈನಾಸಪಡು',
'ದೈನಾಸಬಡು',
'ದೈನ್ಯವದನ',
'ದೈನ್ಯವದನೆ',
'ದೈವಘಟನೆ',
'ದೈವಪುರುಷ',
'ದೈವಪ್ರೇರಣೆ',
'ದೈವವಶಾತ್',
'ದೈವವಿಮುಖ',
'ದೈವವಿಸೂತ್ರ',
'ದೈವವಿಹೀನ',
'ದೈವವಿಹೀನೆ',
'ದೈವಸಂಕಲ್ಪ',
'ದೈವಸಹಾಯ',
'ದೈವಹೊಡಕ',
'ದೈವಾನುಕೂಲ',
'ದೈವಾನುಕೂಲ್ಯ',
'ದೈವಾನುಗ್ರಹ',
'ದೈವೀಕರಣ',
'ದೈವೀಘಟನೆ',
'ದೈವೀಪ್ರವೃತ್ತಿ',
'ದೈವೀಪ್ರೇರಣೆ',
'ದೈವೀವಿರುದ್ಧ',
'ದೈವೀಸಂಪತ್ತು',
'ದೈವೋಪಹತ',
'ದೈವೋಪಹತೆ',
'ದೈಹಿಕಸುಖ',
'ದೊಂಡೆಗೆಸಱು',
'ದೊಂಬಿಜಗಳ',
'ದೊಂಬಿದುಳುಕು',
'ದೊಕ್ಕರಿಗೊಳ್',
'ದೊಗ್ಗಾಲು ಹಾಕು',
'ದೊಗ್ಗಾಲು ಸಲಾಂ',
'ದೊಠಾರತನ',
'ದೊಡ್ಡ ಏಲಕ್ಕಿ',
'ದೊಡ್ಡಕಡಗ',
'ದೊಡ್ಡಕಡವ',
'ದೊಡ್ಡಕರುಳು',
'ದೊಡ್ಡಕವಳಿ',
'ದೊಡ್ಡಕಳವಿ',
'ದೊಡ್ಡಕಾವಲಿ',
'ದೊಡ್ಡಕ್ಕಿಹುಲ್ಲು',
'ದೊಡ್ಡಖಚೋರ',
'ದೊಡ್ಡಖರ್ಜೂರ',
'ದೊಡ್ಡಗರಿಕೆ',
'ದೊಡ್ಡಗಾದಾರಿ',
'ದೊಡ್ಡಗಿರಸ',
'ದೊಡ್ಡಗೋರಂಟಿ',
'ದೊಡ್ಡಗೋರಂಟೆ',
'ದೊಡ್ಡಗೋರಟೆ',
'ದೊಡ್ಡಜೀರಗೆ',
'ದೊಡ್ಡಜೀರಿಗೆ',
'ದೊಡ್ಡತಂಗಡಿ',
'ದೊಡ್ಡತಗಚಿ',
'ದೊಡ್ಡತಗಚೆ',
'ದೊಡ್ಡತಿಪ್ಪಲಿ',
'ದೊಡ್ಡದಗರೆ',
'ದೊಡ್ಡದತ್ತೆಲೆ',
'ದೊಡ್ಡ ನಲಾಂಚಿ',
'ದೊಡ್ಡನಾಳಲು',
'ದೊಡ್ಡ ನೀರಂಜಿ',
'ದೊಡ್ಡನೆಕ್ಕರೆ',
'ದೊಡ್ಡನೆಗ್ಗಿಲು',
'ದೊಡ್ಡಪಟಿಕ',
'ದೊಡ್ಡಪರೀಕ್ಷೆ',
'ದೊಡ್ಡಪುರಲೆ',
'ದೊಡ್ಡಬಸಲೆ',
'ದೊಡ್ಡಬಸಳೆ',
'ದೊಡ್ಡಬಸುರಿ',
'ದೊಡ್ಡರಾಜಾನ್ನ',
'ದೊಡ್ಡರಿಸಿನ',
'ದೊಡ್ಡವರಹ',
'ದೊಡ್ಡಶಣಬು',
'ದೊಡ್ಡಸಂಪಿಗೆ',
'ದೊಡ್ಡsಸಣಬು',
'ದೊಡ್ಡಸಾಸುವೆ',
'ದೊಡ್ಡಸಿವನ್ನಿ',
'ದೊಡ್ಡಸುರುಟಿ',
'ದೊಡ್ಡಸೂರಂಟೆ',
'ದೊಡ್ಡಹನಿಚೆ',
'ದೊಡ್ಡಹರಳು',
'ದೊಡ್ಡಹಾಲ್ಮೆಕ್ಕೆ',
'ದೊಡ್ಡಹಿಪ್ಪಲಿ',
'ದೊಡ್ಡಹುಳಕ',
'ದೊಡ್ಡಹೆಂಡತಿ',
'ದೊಡ್ಡಹೆಮ್ಮರ',
'ದೊಡ್ಡಿವೆಸರ್',
'ದೊಡ್ಡೆಲೆನಾಗ',
'ದೊಡ್ಡೆಲೆಭೋಗಿ',
'ದೊಣೆನಾಯಕ',
'ದೊದ್ದೆಗವಿಸು',
'ದೊರೆಕೊಳಿಸು',
'ದೊರೆಗಿಡಿಸು',
'ದೊರೆಗೆಡಿಸು',
'ದೊರೆನಿರಿಗೆ',
'ದೊರೆಮಗಳು',
'ದೊರೆಯಕ್ಕರ',
'ದೊರೆಯೆಣಿಸು',
'ದೋಖಂಡೇರಾಯ',
'ದೋಣಿಕಡವು',
'ದೋಧಕವೃತ್ತ',
'ದೋಷಗರ್ಭಿತ',
'ದೋಷದೂರತೆ',
'ದೋಷದೂಷಿತ',
'ದೋಷದೂಷಿತೆ',
'ದೋಷಮೂರ್ಛಿತ',
'ದೋಷರಹಿತ',
'ದೋಷರಹಿತೆ',
'ದೋಷವಿದೂರ',
'ದೋಷವಿದೂರೆ',
'ದೋಷಹೊರಿಸು',
'ದೋಷಾತಿಸಾರ',
'ದೋಷಾನ್ವೇಷಣ',
'ದೋಷಾನ್ವೇಷಣೆ',
'ದೋಷಾರೋಪಣ',
'ದೋಷಾರೋಪಣೆ',
'ದೋಷೈಕದೃಷ್ಟಿ',
'ದೋಹದವತಿ',
'ದೌರ್ಜನ್ಯಕಾರಿ',
'ದೌಲತ್‍ಜಾದಾ',
'ದ್ಯುತಿಗ್ರಾಹಕ',
'ದ್ಯುತಿಮಾಪಕ',
'ದ್ಯುತಿಮಾಪನ',
'ದ್ಯೂತಕಾರಕ',
'ದ್ಯೋತಿರಿಂಗಣ',
'ದ್ರವಗೊಳಿಸು',
'ದ್ರವಪಾವುಡ',
'ದ್ರವಸೇಚಕ',
'ದ್ರವಿಡಭಾಷೆ',
'ದ್ರವಿಡವೇದ',
'ದ್ರವಿಡಾಮ್ನಾಯ',
'ದ್ರವಿಳಗಣ',
'ದ್ರವಿಳದೇಶ',
'ದ್ರವಿಳಸಂಘ',
'ದ್ರವಿೞಗಣ',
'ದ್ರವೀಕರಣ',
'ದ್ರವೀಕರಿಸು',
'ದ್ರವ್ಯವಿವಕ್ಷೆ',
'ದ್ರವ್ಯವ್ಯಸನ',
'ದ್ರವ್ಯಸ್ಥಾಪನೆ',
'ದ್ರವ್ಯಾನುಕೂಲ',
'ದ್ರವ್ಯಾನುಯೋಗ',
'ದ್ರಾಮಿಡಗೌಳ',
'ದ್ರಾವಿಡವೇದ',
'ದ್ರಾವಿಡಸಂಘ',
'ದ್ರೋಹಚಿಂತನ',
'ದ್ವಂದ್ವಪ್ರಕೃತಿ',
'ದ್ವಂದ್ವಪ್ರಯತ್ನ',
'ದ್ವಂದ್ವಸಮಾಸ',
'ದ್ವಾಪರಯುಗ',
'ದ್ವಾಮುಷ್ಯಾಯಣ',
'ದ್ವಾರನಿಯುಕ್ತ',
'ದ್ವಾರಪಾಲಕ',
'ದ್ವಾರಪಾಳಕ',
'ದ್ವಾರವಾಟಿಕೆ',
'ದ್ವಿಗುಣಂಗೆಯ್',
'ದ್ವಿಗುಣಗೊಳ್ಳು',
'ದ್ವಿಗುಣೀಕೃತ',
'ದ್ವಿಗುಣೀಭೂತ',
'ದ್ವಿಗುಸಮಾಸ',
'ದ್ವಿಗೃಹೀತಕ',
'ದ್ವಿಚರಮತ್ವ',
'ದ್ವಿತ್ವವಿಕಲ್ಪ',
'ದ್ವಿದಳಧಾನ್ಯ',
'ದ್ವಿದಳಪದ್ಮ',
'ದ್ವಿಪದೀಖಂಡ',
'ದ್ವಿವೇದನೀಯ',
'ದ್ವಿಶಾಲಾಲಯ',
'ದ್ವಿಸಂಧಿಗ್ರಾಹಿ',
'ದ್ವೈತದರ್ಶನ',
'ದ್ವೈತಭಾವನೆ',
'ದ್ವೈತವೇದಾಂತ',
'ದ್ವೈತಸಿದ್ಧಾಂತ',
'ದ್ವ್ಯಾಮುಷ್ಯಾಯಣ',
'ದ್ವ್ಯಾಹಿಕಜ್ವರ',
'ಧಕ್ಕಡತನ',
'ಧಗದ್ಧಗಿತ',
'ಧಗದ್ಧಗಿಸು',
'ಧಗಧಗನೆ',
'ಧಗಧಗಿಲ್',
'ಧಗಧಗಿಸು',
'ಧಡಧಡನೆ',
'ಧಡಧಡಿಸು',
'ಧಡಧಡೀತ',
'ಧಡಪಡಿಸು',
'ಧಡಿಗತನ',
'ಧಡದೋತರ',
'ಧನಪದಿಕ್ಕು',
'ಧನವಂತಿಕೆ',
'ಧನವಿಕಾರ',
'ಧನವಿಕಾರಿ',
'ಧನವಿಹೀನ',
'ಧನವ್ಯಸನ',
'ಧನಸಹಾಯ',
'ಧನುರಾಗಮ',
'ಧನುರಾಸನ',
'ಧಮ್ಮಸವಿಕ್ಕು',
'ಧರಣಿಧರ',
'ಧರಣಿಪತಿ',
'ಧರಣೀಕಂದ',
'ಧರಣೀಚಕ್ರ',
'ಧರಣೀತಲ',
'ಧರಣೀಧರ',
'ಧರಣೀಭಾರ',
'ಧರಾಮರತ್ವ',
'ಧರ್ಮಕಂಟಕ',
'ಧರ್ಮಕರಕ',
'ಧರ್ಮಕಹಳೆ',
'ಧರ್ಮಗಹಳೆ',
'ಧರ್ಮಗಾರಿಯ',
'ಧರ್ಮಗೊಳಗ',
'ಧರ್ಮಗೌರವ',
'ಧರ್ಮಘಾತಕ',
'ಧರ್ಮಚರಿತ',
'ಧರ್ಮನಿರ್ಣಯ',
'ಧರ್ಮನಿವಾಸ',
'ಧರ್ಮನಿಶ್ಚಯ',
'ಧರ್ಮಪತ್ತನ',
'ಧರ್ಮಬಾಹಿರ',
'ಧರ್ಮಬೋಧನೆ',
'ಧರ್ಮಭ್ರಷ್ಟತೆ',
'ಧರ್ಮವತ್ತಳೆ',
'ಧರ್ಮವತ್ಥಳೆ',
'ಧರ್ಮವಿಪ್ಲವ',
'ಧರ್ಮಶಾಸನ',
'ಧರ್ಮಶಾಸ್ತ್ರಜ್ಞ',
'ಧರ್ಮಸಂಕಟ',
'ಧರ್ಮಸಂತತಿ',
'ಧರ್ಮಸಂತಾನ',
'ಧರ್ಮಸಂಹಿತೆ',
'ಧರ್ಮಸಾಧನ',
'ಧರ್ಮಾಚರಣೆ',
'ಧರ್ಮಾಧಿಕಾರಿ',
'ಧರ್ಮೋಪದೇಶ',
'ಧವಡತನ',
'ಧವಲಾಗಾರ',
'ಧವಲಾಯತ',
'ಧವಳಾಗಾರ',
'ಧವಳಾಯತ',
'ಧಳಧಳನೆ',
'ಧಾಡಸೀತನ',
'ಧಾತುಕ್ಷೀಣತೆ',
'ಧಾತುಗೆಡಿಸು',
'ಧಾತುನಾಶನ',
'ಧಾತುಪುಷ್ಪಿಕೆ',
'ಧಾತುಮಾಕ್ಷಿಕ',
'ಧಾತುಲಕ್ಷಣ',
'ಧಾತುವಿಜ್ಞಾನ',
'ಧಾತ್ರೀಹವನ',
'ಧಾನ್ಯಕೋಷ್ಟಕ',
'ಧಾನ್ಯಮಂಜರಿ',
'ಧಾನ್ಯವ್ಯಸನ',
'ಧಾನ್ಯಶೀರ್ಷಕ',
'ಧಾಯಿಟಿಹೂವು',
'ಧಾರಣಯೋಗ',
'ಧಾರಣಶಕ್ತಿ',
'ಧಾರಣಾವಂತ',
'ಧಾರಣಾವ್ರತ',
'ಧಾರಣೆವಾಸಿ',
'ಧಾರಪೂರ್ಬಕ',
'ಧಾರಾಗೃಹೀತ',
'ಧಾರಾಪಂಚಕ',
'ಧಾರಾಪೂರ್ಬಕಂ',
'ಧಾರಾಪೂರ್ವಕ',
'ಧಾರಾಪೂರ್ವಕಂ',
'ಧಾರಾವರುಷ',
'ಧಾರಾವರ್ಷಕಂ',
'ಧಾರಾಸಂಪಾತ',
'ಧಾರಾಳತನ',
'ಧಾರಿಣೀಧವ',
'ಧಾರುಜಕಾರೆ',
'ಧಾರೆಕಂಕಣ',
'ಧಾರೆಮುಹೂರ್ತ',
'ಧಾರೆವಟ್ಟಲು',
'ಧಾರ್ಮಿಕತನ',
'ಧಾವಂತಪಡು',
'ಧಾವತಿಗೆಡು',
'ಧಾವತಿಗೊಳ್',
'ಧಾವತಿಗೊಳು',
'ಧಾವತಿಗೊಳ್ಳು',
'ಧಾವತಿಪಡು',
'ಧಾವತಿಬಡು',
'ಧಾಳಿನಡೆಸು',
'ಧಿಮ್ಮಾಲೆ ರಂಗ',
'ಧೀರತೆಗಿಡು',
'ಧೀರತೆವಿಡಿ',
'ಧೀರಪ್ರಶಾಂತ',
'ಧೀವಸತನ',
'ಧುತ್ತೂರಮತ್ತ',
'ಧುರಂಧರತೆ',
'ಧುರಂಧರಿಕೆ',
'ಧುರಧರಣಿ',
'ಧುಳಧುಳನೆ',
'ಧೂಪಗುಂಡಿಗೆ',
'ಧೂಪಭಾಜನ',
'ಧೂಪಮಾನೆಯ',
'ಧೂಪವರ್ತಿಕೆ',
'ಧೂಪವಿಕ್ಕಿಸು',
'ಧೂಮಕುಣಿಕೆ',
'ಧೂಮಕೇತನ',
'ಧೂಳಿಕಡವ',
'ಧೂಳಿಕಾಕೇಳಿ',
'ಧೂಳಿಗಡವ',
'ಧೂಳಿಗೆದಱು',
'ಧೂಳಿಮಾಕಾಳ',
'ಧೃತಮಂಡಲಿ',
'ಧೃತಿಗಿಡಿಸು',
'ಧೃತಿಗುಂದಿಸು',
'ಧೃತಿಗೆಡಿಸು',
'ಧೇನುಕರಣ',
'ಧೈರ್ಯಗೆಡಿಸು',
'ಧೈರ್ಯಗೊಳಿಸು',
'ಧೈರ್ಯದರಿದ್ರ',
'ಧೈರ್ಯದರಿದ್ರೆ',
'ಧೈರ್ಯಸ್ಖಲನ',
'ಧೈರ್ಯಾವಲಂಬೆ',
'ಧ್ಯಾನಗೋಚರ',
'ಧ್ಯಾನಗೋಚರೆ',
'ಧ್ಯೇಯಸಾಧನೆ',
'ಧ್ರುವನಕ್ಷತ್ರ',
'ಧ್ರುವಮಂಡಲ',
'ಧ್ರುವರಕ್ಷಕ',
'ಧ್ರುವವುಂಡಿಗೆ',
'ಧ್ರುವೀಕರಣ',
'ಧ್ವಜವಂದನೆ',
'ಧ್ವಜಾರೋಹಣ',
'ಧ್ವಜಿನೀಪತಿ',
'ಧ್ವನನಮಾರ್ಗ',
'ಧ್ವನಿಪೆಟ್ಟಿಗೆ',
'ಧ್ವನಿಪ್ರಸ್ಥಾನ',
'ಧ್ವನಿಮಾರ್ಪಾಟು',
'ಧ್ವನಿಮುದ್ರಕ',
'ಧ್ವನಿಮುದ್ರಣ',
'ಧ್ವನಿಮುದ್ರಿಕೆ',
'ಧ್ವನಿಮುದ್ರಿಸು',
'ಧ್ವನಿವರ್ಧಕ',
'ಧ್ವನಿವಾಹಕ',
'ಧ್ವನಿವಿಕಾರ',
'ಧ್ವನಿವಿಜ್ಞಾನ',
'ಧ್ವನಿಸಂಕೇತ',
'ಧ್ವನಿಸಂಪತ್ತು',
'ಧ್ವನಿಸಾಂಗತ್ಯ',
'ಧ್ವನಿಸುರುಳಿ',
'ಧ್ವನ್ಯಾತ್ಮಕತೆ',
'ನಂಜವಟ್ಟಲು',
'ನಂಜುಗೊರಲ',
'ನಂಜುಗೊರಲು',
'ನಂಜುಗೊರಳ',
'ನಂಜುಗೊರಳು',
'ನಂಜುಬಟ್ಟಲು',
'ನಂಜುರಂಜಿಕೆ',
'ನಂಜುರೋಧಕ',
'ನಂಟುಗಾಣಿಸು',
'ನಂಟುಗೊಳುಹ',
'ನಂದಗೋಕುಲ',
'ನಂದದೀವಿಗೆ',
'ನಂದನವನ',
'ನಂದಾದೀವಿಗೆ',
'ನಂದಾಬೆಳಕು',
'ನಂದಾವೆಳಕು',
'ನಂದಿಯ ಕೋಲು',
'ನಂದಿಕೇಶ್ವರ',
'ನಂದಿಬಟ್ಟಲು',
'ನಂದಿಮೆಟ್ಟಲು',
'ನಂದಿವಟ್ಟಲು',
'ನಂದಿವರ್ಧನ',
'ನಂದಿಸೋಪಾನ',
'ನಂಬಿಕಸ್ತಿಕೆ',
'ನಂಬಿಕೆದ್ರೋಹ',
'ನಂಬಿಕೆದ್ರೋಹಿ',
'ನಂಬಿಗಸ್ತಿಕೆ',
'ನಂಬುಗೆಗೆಡು',
'ನಂಬುಗೆಗೆಯ್',
'ನಂಬುಗೆಗೇಡು',
'ನಂಬುಗೆಗೇಳು',
'ನಂಬುಗೆಗೊಡು',
'ನಂಬುಗೆಗೊಳ್',
'ನಂಬುಗೆಗೊಳ್ಳು',
'ನಂಬುಗೆದಾಳು',
'ನಂಬುಗೆದೋರು',
'ನಂಬುಗೆದೋಱು',
'ನಂಬುಗೆವಂತ',
'ನಂಬುಗೆವಡೆ',
'ನಂಬುಗೆವಿಡಿ',
'ನಂಬುಗೆವೆರೆ',
'ನಂಬುಗೆವೆಱು',
'ನಕಲಿಗಾರ',
'ನಕಾರಾತ್ಮಕ',
'ನಕಾಶತೆಗೆ',
'ನಕಾಶೆತೆಗೆ',
'ನಕ್ಕಿಕೆಲಸ',
'ನಕ್ತಭೋಜನ',
'ನಕ್ತೇಕಭುಕ್ತ',
'ನಕ್ತೋಪವಾಸ',
'ನಕ್ಷತ್ರಕೂಟ',
'ನಕ್ಷತ್ರಚಿಹ್ನೆ',
'ನಕ್ಷತ್ರಪಾದ',
'ನಕ್ಷತ್ರಪುಂಜ',
'ನಕ್ಷತ್ರಬಾಣ',
'ನಕ್ಷತ್ರಮಾನ',
'ನಕ್ಷತ್ರಮಾಲೆ',
'ನಕ್ಷತ್ರಮೀನು',
'ನಕ್ಷತ್ರರಾಶಿ',
'ನಕ್ಷತ್ರಲೋಕ',
'ನಕ್ಷತ್ರವೀಧಿ',
'ನಕ್ಷತ್ರವ್ಯೂಹ',
'ನಕ್ಷತ್ರೋದಯ',
'ನಖಪಂಜರ',
'ನಖರಂಜಿನಿ',
'ನಖರಾಯುಧ',
'ನಖಶಿಖಾಂತ',
'ನಗದಿಬುಕ್ಕು',
'ನಗದಿಮಾಡು',
'ನಗದಿಲೆಕ್ಕ',
'ನಗದುಗುತ್ತ',
'ನಗದುಗುತ್ತೆ',
'ನಗದುಗೇಣಿ',
'ನಗದುಬೆಲೆ',
'ನಗದುಲಾಭ',
'ನಗದುಲೆಕ್ಕ',
'ನಗದುವಟ್ಟ',
'ನಗದುಸಾಲ',
'ನಗದುಸಿಲ್ಕು',
'ನಗದುಹಣ',
'ನಗರಂಜಿತ',
'ನಗರಪಿತೃ',
'ನಗರರಾಜ್ಯ',
'ನಗರವಾಸ',
'ನಗರವಾಸಿ',
'ನಗರಸಭೆ',
'ನಗಾರಖಾನೆ',
'ನಗುಪಾಟಲು',
'ನಗೆಚಾಟಿಕೆ',
'ನಗೆನಾಟಕ',
'ನಗೆಪಾಟಲು',
'ನಗೆಬರಹ',
'ನಗೆಮಚ್ಛರ',
'ನಗೆವೆಳಗು',
'ನಗೆಹಾಯಿಸು',
'ನಗೋಪಕಂಠ',
'ನಗೋಪತ್ಯಕ',
'ನಚ್ಚಣವಕ್ಕಿ',
'ನಚ್ಚುಗಿಡಿಸು',
'ನಜರಚೂಕ್',
'ನಜರಾಣಿಕೆ',
'ನಜರುಕೈದಿ',
'ನಜರುಕೈದು',
'ನಟಣೆಗೊಳ್',
'ನಟನಕಲೆ',
'ನಟಭೈರವಿ',
'ನಟಮಂದಿರ',
'ನಟಾಭರಣ',
'ನಟಿಕೆತೆಗೆ',
'ನಟಿಕೆಮುರಿ',
'ನಟ್ಟನಡುವು',
'ನಟ್ಟನಡುವೆ',
'ನಟ್ಟವಗಲ್',
'ನಟ್ಟವಿಗಿತ್ತಿ',
'ನಟ್ಟವಿಸಿಲ್',
'ನಟ್ಟಿಸಮಯ',
'ನಟ್ಟುಕಡಿಸು',
'ನಟ್ಟುವಗಾತಿ',
'ನಟ್ಟುವಗಾರ',
'ನಟ್ಟುವಗಿತ್ತಿ',
'ನಟ್ಟೆವಗಲ್',
'ನಟ್ಟೆವಗಲು',
'ನಡಗೆದಾರ',
'ನಡತೆಕಾಱ',
'ನಡತೆಗೆಡು',
'ನಡತೆವಂತ',
'ನಡನಡನೆ',
'ನಡಪಾಡಿಸು',
'ನಡಪಾಡುಹ',
'ನಡವಳಿಕೆ',
'ನಡವಳಿತ',
'ನಡಾವಳಿಕೆ',
'ನಡಾಳಮಿಣಿ',
'ನಡುಕಂಗಿಡು',
'ನಡುಕಂಗೊಳ್',
'ನಡುಕಂಬೆರು',
'ನಡುಕವೆರು',
'ನಡುಗನ್ನಡ',
'ನಡುಗಾನಲ್',
'ನಡುಗುಂಪೆಣ',
'ನಡುಗುಱುವ',
'ನಡುಜವ್ವನ',
'ನಡುನೇಸಱ್',
'ನಡುನೇಸಱು',
'ನಡುಪಗಲ್',
'ನಡುಬೆರಳು',
'ನಡುವಂತರ',
'ನಡುವಗಲ್',
'ನಡುವಗಲು',
'ನಡುವಿರುಳ್',
'ನಡುವಿರುಳು',
'ನಡುಸರಕು',
'ನಡುಹಗಲು',
'ನಡುಹರೆಯ',
'ನಡೆಗಲಿಸು',
'ನಡೆಗೆಡಿಸು',
'ನಡೆಗೊಳಿಸು',
'ನಡೆಚಪ್ಪರ',
'ನಡೆಡೆಂಕಣಿ',
'ನಡೆತೋರಣ',
'ನಡೆದೇಗುಲ',
'ನಡೆದೋರಣ',
'ನಡೆಪಾಡಿಸು',
'ನಡೆಬೆಡಗು',
'ನಡೆಯುಡುಗು',
'ನಡೆವಗಲ್',
'ನಡೆವಣಿಗ',
'ನಡೆವಳಿಕೆ',
'ನಡೆವಳಿಗ',
'ನಡೆಸೊಡರು',
'ನತನಾಸಿಕ',
'ನದರುಗೆಡು',
'ನದಿಯಮರ',
'ನದೀಮಾತೃಕ',
'ನದೀಮಾತೃಕೆ',
'ನದುಳುನಗೆ',
'ನನವರಿಕೆ',
'ನನಸುಗಿರು',
'ನನೆವೆರಸು',
'ನಪುಂಸಕತ್ವ',
'ನಭಃಪಯೋಜ',
'ನಭಸಂಗಮ',
'ನಭೋಗಮನ',
'ನಭೋಮಂಡಲ',
'ನಮಃಶಿವಾಯ',
'ನಮಲುಗರೆ',
'ನಮಶ್ಶಿವಾಯ',
'ನಮಸ್ಕರಣ',
'ನಮಸ್ಕರಿಸು',
'ನಮೋಸ್ತುಗೆಯ್',
'ನಮ್ರವಚನ',
'ನಯಕಾರಿಣಿ',
'ನಯಗಾರಿಕೆ',
'ನಯನಕರ್ಣ',
'ನಯನಚ್ಛದ',
'ನಯನಜಲ',
'ನಯನತ್ರಯ',
'ನಯನಮಧ್ಯ',
'ನಯನವೀಧಿ',
'ನಯನಾತಿಥಿ',
'ನಯನಿಪುಣ',
'ನಯನಿಪುಣೆ',
'ನಯಪಂಡಿತ',
'ನಯಪಂಡಿತೆ',
'ನಯವಂಚಕ',
'ನಯವಂಚಕಿ',
'ನಯವಂತಿಕೆ',
'ನಯವಿದೂರ',
'ನಯವಿದೂರೆ',
'ನಯವಿಹೀನ',
'ನಯವಿಹೀನೆ',
'ನಯಶಾ ಲಿನಿ',
'ನರಂಜಿಬಳ್ಳಿ',
'ನರಕಕರ್ಮ',
'ನರಕಕುಂಡ',
'ನರಕಕೂಪ',
'ನರಕಕೊಂಡ',
'ನರಕಗತಿ',
'ನರಕಗಾಮಿ',
'ನರಕಚೋರ',
'ನರಕಜೀವಿ',
'ನರಕಬಾಧೆ',
'ನರಕಬಿಲ',
'ನರಕಭಯ',
'ನರಕವಾಸ',
'ನರಕವಾಸಿ',
'ನರಕೀಟಕ',
'ನರಕೇಸರಿ',
'ನರಗಬ್ಬಿಗ',
'ನರಗುರಿಗ',
'ನರಗೊರಲ್',
'ನರಟುಗಾರ',
'ನರಪುಂಗವ',
'ನರಪೇತಲ',
'ನರಪೇತಲು',
'ನರಭಕ್ಷಕ',
'ನರಭಕ್ಷಕಿ',
'ನರಭಕ್ಷಣ',
'ನರಮಂಡಲ',
'ನರಮನುಷ್ಯ',
'ನರಮಹಿತ',
'ನರರಾಕ್ಷಸ',
'ನರರಾಕ್ಷಸಿ',
'ನರವಂಟಿಗೆ',
'ನರವಾನರ',
'ನರವಾಹನ',
'ನರಿಮಂಗಲ',
'ನರಿಹೆಸರು',
'ನರುಟುಗೊಳ್',
'ನರುಟುಗೊಳ್ಳು',
'ನರೆದಲೆಗ',
'ನರ್ತನಗಾತಿ',
'ನರ್ತನಗಾರ',
'ನರ್ತನಗಾರ್ತಿ',
'ನರ್ತನಗಾಱ',
'ನರ್ತನಗೃಹ',
'ನರ್ತನವಿದ್ಯೆ',
'ನರ್ತನಶಾಲೆ',
'ನರ್ಮಸಚಿವ',
'ನರ್ಮಸಹಾಯ',
'ನಲವರಕೆ',
'ನಲವರಿಕೆ',
'ನಲವುಗೆಡು',
'ನಲವುದಾಳು',
'ನಲವುಮತ್ತು',
'ನಲವೇರಿಸು',
'ನಲವೇಱಿಸು',
'ನಲಿದಾಡಿಸು',
'ನಲಿನಗರ್ಭ',
'ನಲಿನನಾಭ',
'ನಲಿನಮಿತ್ರ',
'ನಲಿನಮುಖಿ',
'ನಲಿವಾಱಿಸು',
'ನಲ್ಮೆಕಾರಿಕೆ',
'ನಲ್ಮೆಕಾಱಿಕೆ',
'ನಲ್ಲಿತಿರುಪು',
'ನವಕಂಬಡೆ',
'ನವಣಿವಡೆ',
'ನವಣೆಯಕ್ಕಿ',
'ನವತರುಣ',
'ನವತರುಣಿ',
'ನವತಾರುಣ್ಯ',
'ನವದಂಪತಿ',
'ನವನಲಿನ',
'ನವನವತಿ',
'ನವನೀತಕ',
'ನವನೂತನ',
'ನವಪುಷ್ಪಿತೆ',
'ನವಮಲ್ಲಿಕೆ',
'ನವಮಲ್ಲಿಗೆ',
'ನವಮಾಲಿಕೆ',
'ನವಯವ್ವನ',
'ನವಯವ್ವನೆ',
'ನವಯುವಕ',
'ನವಯುವತಿ',
'ನವಯೌವನ',
'ನವಯೌವನೆ',
'ನವರತುನ',
'ನವಸೂತಿಕೆ',
'ನವಾಯಿಕಾಱ',
'ನವಾಯಿಗೆಡು',
'ನವಾರಮಂಚ',
'ನವಾಸಾಗರ',
'ನವಿರುಗಣ್ಣು',
'ನವಿರುಗೂಡು',
'ನವಿರುಹಾಸ್ಯ',
'ನವಿಲುಕೋಸು',
'ನವಿಲುಗರಿ',
'ನವಿಲುಯಾಲೆ',
'ನವಿಲುಯ್ಯಲ್',
'ನವಿಲುಯ್ಯಲು',
'ನವಿಲುಯ್ಯಲೆ',
'ನವಿಲುಯ್ಯಾಲೆ',
'ನವೀಕರಣ',
'ನವೀಕರಿಸು',
'ನವೀನಪ್ರಜ್ಞೆ',
'ನವುರಕಟ್ಟೆ',
'ನವುರುಕಟ್ಟೆ',
'ನವೋನವತೆ',
'ನಶೀಬಗೇಡಿ',
'ನಷ್ಟಚೇತನ',
'ನಷ್ಟಚೇಷ್ಟತೆ',
'ನಸರಿತುಪ್ಪ',
'ನಸರಿಹುಳ',
'ನಸರುಬಂದಿ',
'ನಸೀಬವಾನ',
'ನಸಕುಹರಿ',
'ನಸುವರೆಯ',
'ನಸುವಿರಿದು',
'ನಸುವೆಳಗು',
'ನಸೆಯೇರಿಸು',
'ನಸೆಯೇಱಿಸು',
'ನಸೆವಣಕ',
'ನಸೆವಣಿಕ',
'ನಳನಳನೆ',
'ನಳನಳಿಕೆ',
'ನಳನಳಿಸು',
'ನಳಿನಕಾಂತಿ',
'ನಳಿನಗರ್ಭ',
'ನಳಿನನಾಭ',
'ನಳಿನಮುಖಿ',
'ನಳ್ಳಿಕೈವಾರ',
'ನಱುಜವ್ವನ',
'ನಱುಜೌವನ',
'ನಾಂದೀದೇವತೆ',
'ನಾಂದೀಸ್ಥಾಪನೆ',
'ನಾಕಚಿಕುರ',
'ನಾಕರಗುಂಟೆ',
'ನಾಕ್ಷತ್ರಮಾನ',
'ನಾಗಂಬುವಳ್ಳಿ',
'ನಾಗಕಂಕಣ',
'ನಾಗಕಟಕ',
'ನಾಗಕನ್ನಿಕೆ',
'ನಾಗಕರಣ',
'ನಾಗಕುಂಡಲ',
'ನಾಗಕೇಶರ',
'ನಾಗಕೇಸರ',
'ನಾಗಕೇಸರಿ',
'ನಾಗಗಾಂಧಾರಿ',
'ನಾಗಚಂಪಕ',
'ನಾಗಚತುರ್ಥಿ',
'ನಾಗಜಿಹ್ವಿಕೆ',
'ನಾಗತಗರೆ',
'ನಾಗದಮನಿ',
'ನಾಗದಾವರೆ',
'ನಾಗನಿವಾಸ',
'ನಾಗಪಂಚಮಿ',
'ನಾಗಪ್ರತಿಷ್ಠೆ',
'ನಾಗಭೂಷಣ',
'ನಾಗಮಂಗಲ',
'ನಾಗಮಂಡಲ',
'ನಾಗಮಲ್ಲಿಗೆ',
'ನಾಗಮುರಿಗೆ',
'ನಾಗರಖಂಡ',
'ನಾಗರಗರೆ',
'ನಾಗರಮುಸ್ತೆ',
'ನಾಗರಮೋತ',
'ನಾಗರಮೋತಿ',
'ನಾಗರಮೋಥ',
'ನಾಗರಮೋಥೆ',
'ನಾಗರಲಿಪಿ',
'ನಾಗರಿಕತೆ',
'ನಾಗರೀಕತೆ',
'ನಾಗರೀಲಿಪಿ',
'ನಾಗವಂದಿಗೆ',
'ನಾಗವಟ್ಟಿಗೆ',
'ನಾಗವತ್ತಿಗೆ',
'ನಾಗವರಾಲಿ',
'ನಾಗವರಾಳಿ',
'ನಾಗವಾಸಿಗ',
'ನಾಗಸಂಪಗೆ',
'ನಾಗಸಂಪಿಗೆ',
'ನಾಗಾನಂದಿನಿ',
'ನಾಗಾಭರಣ',
'ನಾಚಿಕೆಗಿಡ',
'ನಾಚಿಕೆಗೆಡು',
'ನಾಚಿಕೆಗೇಡಿ',
'ನಾಚಿಕೆಗೇಡು',
'ನಾಚಿಕೆಗೊಳು',
'ನಾಚಿಕೆಗೊಳ್ಳು',
'ನಾಚಿಕೆಪಡು',
'ನಾಚಿಕೆಮುಳ್ಳು',
'ನಾಚಿಕೆವಡು',
'ನಾಚುಬುರುಕ',
'ನಾಚುಬುರುಕಿ',
'ನಾಜಲುಹಣ',
'ನಾಜೂಕದಾರ',
'ನಾಜೂಕುಗಾರ',
'ನಾಜೂಕುತನ',
'ನಾಟಕ ಮಾಡು',
'ನಾಟಕಕಾರ',
'ನಾಟಕಧರ',
'ನಾಟಕಧಾರಿ',
'ನಾಟಕಪ್ರಿಯ',
'ನಾಟಕರಂಗ',
'ನಾಟಕವಾಡು',
'ನಾಟಕಶಾಲೆ',
'ನಾಟಕಶಿಲ್ಪ',
'ನಾಟಕೀಯತೆ',
'ನಾಟಕುರಂಜಿ',
'ನಾಟಿ ಅಂಜೂರ',
'ನಾಟಿ ಅಕ್ರೋಟು',
'ನಾಟಿ ಔಷಧ',
'ನಾಟಿಕುರಂಜಿ',
'ನಾಟಿಬಾದಾಮಿ',
'ನಾಟುಮುದಿಮೆ',
'ನಾಟ್ಯನಿಲಯ',
'ನಾಟ್ಯಮಂಟಪ',
'ನಾಟ್ಯಮಂದಿರ',
'ನಾಟ್ಯೀಕರಿಸು',
'ನಾಡಚೀಲೈತ',
'ನಾಡಿಗತನ',
'ನಾಡಿಪರೀಕ್ಷೆ',
'ನಾಡೀಪರೀಕ್ಷೆ',
'ನಾಡೀಶೋಧನ',
'ನಾಡು ಅಕ್ರೋಟು',
'ನಾಡುನೇಗಿಲು',
'ನಾಡುಬಾದಾಮಿ',
'ನಾಣಿಲಿತನ',
'ನಾಣಿಸೆಪಡು',
'ನಾಣುಗೆಡಿಸು',
'ನಾಣುಗೇಡಿಗ',
'ನಾಣುನಸಿಕ',
'ನಾತಿನಿಕಟ',
'ನಾತಿಮಾನಿತೆ',
'ನಾದಪ್ರಪಂಚ',
'ನಾಧಭರಿತ',
'ನಾದಸಂಪುಟ',
'ನಾದಾರಚೀಟಿ',
'ನಾದೋಪಾಸಕ',
'ನಾದೋಪಾಸನೆ',
'ನಾನಾವಚನ',
'ನಾನ್ಪರ್ವರಿಷ್',
'ನಾಭಿಕಮಲ',
'ನಾಭಿಖಂಡನ',
'ನಾಭಿಮಂಡಲ',
'ನಾಭಿಸರೋಜ',
'ನಾಭೀನಳಿನ',
'ನಾಮಕರಣ',
'ನಾಮಕಾಣಿಕೆ',
'ನಾಮಕಾವಾಸ್ತೆ',
'ನಾಮಕೀರ್ತನ',
'ನಾಮಕೀರ್ತನೆ',
'ನಾಮಕೇವಾಸ್ತೆ',
'ನಾಮಗ್ರಹಣ',
'ನಾಮದ ಬೇರು',
'ನಾಮದ ಸೊಪ್ಪು',
'ನಾಮಧಾರಕ',
'ನಾಮನಕ್ಷತ್ರ',
'ನಾಮರಾಯಣಿ',
'ನಾಮನಿರ್ದೇಶ',
'ನಾಮಪ್ರಕೃತಿ',
'ನಾಮಫಲಕ',
'ನಾಮವಾಚಕ',
'ನಾಮವಿಭಕ್ತಿ',
'ನಾಮಸ್ಮರಣ',
'ನಾಮಸ್ಮರಣೆ',
'ನಾಮಾಂತರಿತ',
'ನಾಮಾನುಕ್ರಮ',
'ನಾಮಾವಶೇಷ',
'ನಾಯಕಗ್ಗಲಿ',
'ನಾಯಕತಃಖ್ತೆ',
'ನಾಯಕತನ',
'ನಾಯಕವಾಡಿ',
'ನಾಯಕಸಾನಿ',
'ನಾಯಚಿಲಕೆ',
'ನಾಯಚುಂಚಲು',
'ನಾಯತುಳಸಿ',
'ನಾಯತೊಳಸಿ',
'ನಾಯನರಕ',
'ನಾಯನೇಱಿಲ್',
'ನಾಯಿಂದಗತ್ತಿ',
'ನಾಯಿಂದಗಿತ್ತಿ',
'ನಾಯಿಕಡಂಬೆ',
'ನಾಯಿಕಸಾನಿ',
'ನಾಯಿಕೆತನ',
'ನಾಯಿಕೆಮ್ಮಲು',
'ನಾಯಿಗೆಮ್ಮಲು',
'ನಾಯಿತಕ್ಕಿಲೆ',
'ನಾಯಿತುಳಸಿ',
'ನಾಯಿತೊಣಚಿ',
'ನಾಯಿತೊಳಸಿ',
'ನಾಯಿನಾಲಿಗೆ',
'ನಾಯಿನಿಸೊಪ್ಪು',
'ನಾಯಿನೇರಳೆ',
'ನಾಯಿನೇಱಿಲ್',
'ನಾಯಿನೊಳಲೆ',
'ನಾಯಿಬದುಕು',
'ನಾಯಿಯೆಲವ',
'ನಾಯಿಹಲಸು',
'ನಾಯಿಸಾಸಿವೆ',
'ನಾಯಿಸೊಣಗು',
'ನಾರದವೀಣೆ',
'ನಾರದಸ್ಮೃತಿ',
'ನಾರಾಚಚೂರ್ಣ',
'ನಾರಾಯಣಾಸ್ತ್ರ',
'ನಾರುಕಚೂರ',
'ನಾರುಭೂತಾಳ',
'ನಾರ್ನಪರ್ವರ್ಶಿ',
'ನಾಲಗೆಕಚ್ಚು',
'ನಾಲಗೆಕಲ್ಲು',
'ನಾಲಗೆಕೆಡು',
'ನಾಲಗೆಗಲಿ',
'ನಾಲಗೆತೀಂಟೆ',
'ನಾಲಗೆತೀಂತೆ',
'ನಾಲಗೆತೀಟೆ',
'ನಾಲಗೆದೀಂಟೆ',
'ನಾಲಗೆಬೀಳು',
'ನಾಲಗೆಶೂರ',
'ನಾಲಗೆಹೋಗು',
'ನಾಲುಬಡಿಸು',
'ನಾಲುಸಾಸಿರ',
'ನಾಲ್ವತ್ತನೆಯ',
'ನಾವಲಗೆಡ್ಡೆ',
'ನಾವಿಕಕಳ್ಳ',
'ನಾವಿದಗೆಯ್',
'ನಾವಿದಗೆಯಿ',
'ನಾವಿದದೆಱೆ',
'ನಾವಿದಬಾಳು',
'ನಾವಿದವಾರಿ',
'ನಾವಿದವಾಳ್',
'ನಾಶಗೊಳಿಸು',
'ನಾಶಪಡಿಸು',
'ನಾಸಾಭರಣ',
'ನಾಸಿಕಾಚೂರ್ಣ',
'ನಾಸ್ತಿಕತನ',
'ನಾಸ್ತಿಕಧರ್ಮ',
'ನಾಸ್ತಿಕಮತ',
'ನಾಸ್ತಿಕವಾದ',
'ನಾಸ್ತಿಕವಾದಿ',
'ನಾಳಲುವಾಸೆ',
'ನಾಳವಾಸಿಗೆ',
'ನಾಳಸಂಕೋಲೆ',
'ನಾಳಿಕಾಪಾತ್ರ',
'ನಾಳಿಕಾಯಂತ್ರ',
'ನಾಳಿಕಾವಾದ್ಯ',
'ನಾಳೀಕಭವ',
'ನಾಳ್ಕಡಿವೋಗು',
'ನಾೞವಾಸಗೆ',
'ನಾೞ್ಕಡಿಗೞಿ',
'ನಾೞ್ಕಡಿಗೞೆ',
'ನಾೞ್ಕಡಿಪೋಗು',
'ನಾೞ್ಕಡಿವೋಗು',
'ನಾೞ್ಕಡೆಗೞೆ',
'ನಾೞ್ಕಡೆವೋಗು',
'ನಾೞ್ಗಡಿಗೞೆ',
'ನಿಂಬೆತುಳಸಿ',
'ನಿಃಪಕ್ಷಪಾತ',
'ನಿಃಪ್ರಾದೇಶಿಕ',
'ನಿಃಶರೀರತ್ವ',
'ನಿಕಟಗೊಳ್ಳು',
'ನಿಕಟವರ್ತಿ',
'ನಿಕಟಾರ್ಥಕ',
'ನಿಕಶೋಪಳ',
'ನಿಕಷೋಪಲ',
'ನಿಕಾರವಟ್ಟೆ',
'ನಿಕುಂಚಹಸ್ತ',
'ನಿಕುರುಂಬಕ',
'ನಿಕ್ಷೇಪನಿಧಿ',
'ನಿಖಾತನಿಧಿ',
'ನಿಗಡಬದ್ಧ',
'ನಿಗನಿಗನೆ',
'ನಿಗಿನಿಗಿಸು',
'ನಿಗಮಕರ',
'ನಿಗಮಲಿಪಿ',
'ನಿಗಳವಿಕ್ಕು',
'ನಿಗುರುತೆಗೆ',
'ನಿಗುರುದೆಗೆ',
'ನಿಗ್ಗಂಠಧಮ್ಮ',
'ನಿಗ್ಗೆಡಿತನ',
'ನಿಗ್ರಹಂಗೆಯ್',
'ನಿಗ್ರಹಂಬಡು',
'ನಿಗ್ರಹಸ್ಥಾನ',
'ನಿಚ್ಚಗಟ್ಟಳೆ',
'ನಿಚ್ಚತಗೊಳ್',
'ನಿಚ್ಚಪ್ರಸಾದಿ',
'ನಿಚ್ಚಯಗೆಯ್',
'ನಿಚ್ಚಯವಿಡಿ',
'ನಿಚ್ಚಲವಕ್ಕಿ',
'ನಿಚ್ಚಳಗೊಳ್ಳು',
'ನಿಜಾಮಶಾಹಿ',
'ನಿಟ್ಟಯ್ದೆತನ',
'ನಿಟ್ಟುಪವಾಸ',
'ನಿಟ್ಟೆಗೊಳಿಸು',
'ನಿಟ್ಟೆಯ್ದೆತನ',
'ನಿಟ್ಟೆಲೆಹೊನ್ನೆ',
'ನಿಟ್ಟೆವಡಿಸು',
'ನಿಡುಸುಯಲ್',
'ನಿಡುಸುಯಿಲ್',
'ನಿಡುಸುಯಿಲು',
'ನಿತಂಬಹಸ್ತ',
'ನಿತ್ಯಕಟ್ಟಲೆ',
'ನಿತ್ಯಗಟ್ಟಲೆ',
'ನಿತ್ಯಗಟ್ಟಳೆ',
'ನಿತ್ಯದರಿದ್ರ',
'ನಿತ್ಯದಾರಿದ್ರ್ಯ',
'ನಿತ್ಯನಾರಕಿ',
'ನಿತ್ಯನೂತನ',
'ನಿತ್ಯಪ್ರಸಾದಿ',
'ನಿತ್ಯಮಲ್ಲಿಗೆ',
'ನಿತ್ಯಮುತ್ತಯ್ದೆ',
'ನಿತ್ಯಮುತ್ತೈದೆ',
'ನಿದಾನಂಗೆಯ್',
'ನಿದಾನವಂತ',
'ನಿದಾನಶಾಸ್ತ್ರ',
'ನಿದಿಧ್ಯಾಸನ',
'ನಿದ್ದೆಗುಳಿಗೆ',
'ನಿದ್ದೆಗೆಡಿಸು',
'ನಿದ್ರಾಜನಕ',
'ನಿಧನವೆತ್ತು',
'ನಿಧಿನಾಗರ',
'ನಿನದಂಗೆಯ್',
'ನಿನಾದಗೊಳ್ಳು',
'ನಿಪುಣತನ',
'ನಿಪುತ್ರಿಕತೆ',
'ನಿಪ್ಪಸರಿಸು',
'ನಿಬದ್ದಿವಿಡಿ',
'ನಿಬಿಡಂಬೆಱು',
'ನಿಬಿಡೀಕೃತ',
'ನಿಬ್ಬಣಗಿತ್ತಿ',
'ನಿಮಚಿಕಣಿ',
'ನಿಮಿತ್ತಮಾತ್ರ',
'ನಿಮಿತ್ತವಾದ',
'ನಿಮಿತ್ತವಿದ',
'ನಿಮಿರ್ಕೆಗುಂದು',
'ನಿಮಿರ್ಕೆವಡೆ',
'ನಿಮಿರ್ಕೆವೆಱು',
'ನಿಮಿಷಮಾತ್ರ',
'ನಿಮೇಷಶೂನ್ಯ',
'ನಿಮ್ನಮಸೂರ',
'ನಿಯತಕಾಲ',
'ನಿಯತದ್ವಿತ್ವ',
'ನಿಯತವಿಧಿ',
'ನಿಯತಿಕೃತ',
'ನಿಯತಿವಾದ',
'ನಿಯತಿವಾದಿ',
'ನಿಯಮಬದ್ಧ',
'ನಿಯಮಸ್ಥಿತಿ',
'ನಿಯಮಿತತೆ',
'ನಿಯೋಗಕಾತಿ',
'ನಿಯೋಗಿತನ',
'ನಿರಂಕುಶತೆ',
'ನಿರಂತರಾಯ',
'ನಿರಂತರಿತ',
'ನಿರಕ್ಷದೇಶ',
'ನಿರಕ್ಷೀಕೃತ',
'ನಿರತಿಕ್ರಮ',
'ನಿರತಿಚಾರ',
'ನಿರತಿಶಯ',
'ನಿರತಿಶಯೆ',
'ನಿರಧಿಷ್ಠಾನ',
'ನಿರನುಕ್ರೋಶ',
'ನಿರನುಕ್ರೋಶೆ',
'ನಿರನುಭವಿ',
'ನಿರಪರಾಧ',
'ನಿರಪರಾಧಿ',
'ನಿರಪವಾದ',
'ನಿರಪೇಕ್ಷಕ',
'ನಿರಪೇಕ್ಷತೆ',
'ನಿರಬಯಲು',
'ನಿರಭಿಧಾನ',
'ನಿರಭಿಮಾನ',
'ನಿರಭಿಮಾನಿ',
'ನಿರಯಗಾಮಿ',
'ನಿರರ್ಗಳಿತ',
'ನಿರರ್ಥಕರ',
'ನಿರಲಂಕಾರ',
'ನಿರಲಂಕಾರೆ',
'ನಿರವಗ್ರಹ',
'ನಿರವಧಿಕ',
'ನಿರವಮಾನ',
'ನಿರವಯಲು',
'ನಿರವಯವ',
'ನಿರವಶೇಷ',
'ನಿರವಶೇಷಂ',
'ನಿರವಸರ',
'ನಿರಸ್ತಮಿತ',
'ನಿರಹಂಕರ',
'ನಿರಹಂಕಾರ',
'ನಿರಹಂಕಾರಿ',
'ನಿರಹಂಕಾರೆ',
'ನಿರಹಂಕೃತ',
'ನಿರಹಂಭಾವ',
'ನಿರಳಂಕಾರ',
'ನಿರಳಂಕೃತಿ',
'ನಿರಾಕರಣ',
'ನಿರಾಕರಣೆ',
'ನಿರಾಕರಿಷ್ಣು',
'ನಿರಾಕರಿಸು',
'ನಿರಾಕುಲತೆ',
'ನಿರಾಕುಳತೆ',
'ನಿರಾಕುಳಿತ',
'ನಿರಾಕ್ಷೇಪಣೆ',
'ನಿರಾಜಿಕಂಬಿ',
'ನಿರಾಡಂಬರ',
'ನಿರಾದರಣೆ',
'ನಿರಾಪರಾಧಿ',
'ನಿರಾಬಾಧಕ',
'ನಿರಾಭರಣ',
'ನಿರಾಭರಣೆ',
'ನಿರಾಲಂಬನ',
'ನಿರಾವರಣ',
'ನಿರಾವರಣೆ',
'ನಿರಾವಸಥ',
'ನಿರಾಶಾಪದ',
'ನಿರಾಶಾವಾದ',
'ನಿರಾಶಾವಾದಿ',
'ನಿರಾಶೆಗೊಳು',
'ನಿರಾಶೆಗೊಳ್ಳು',
'ನಿರಾಸಮತಿ',
'ನಿರಾಸಮಾರ್ಗ',
'ನಿರಿಗೆಗಟ್ಟು',
'ನಿರಿಗೆಗೊಳ್ಳು',
'ನಿರಿಡಿಬಿತ್ತ',
'ನಿರಿಹಪರ',
'ನಿರುಂಬಳತೆ',
'ನಿರುಕ್ತಿಕಾರ',
'ನಿರುಗೆಕಾರ',
'ನಿರುತ್ಸುಕತೆ',
'ನಿರುದ್ಧೇಂದ್ರಿಯ',
'ನಿರುದ್ಧೇಂದ್ರಿಯೆ',
'ನಿರುಪಕ್ರಮ',
'ನಿರುಪದ್ರವ',
'ನಿರುಪದ್ರವಿ',
'ನಿರುಪಧಾವಿ',
'ನಿರುಪಪ್ಲವ',
'ನಿರುಪಮಾತ್ಮ',
'ನಿರುಪಮಾನ',
'ನಿರುಪಮಾನೆ',
'ನಿರುಪಮಿತ',
'ನಿರುಪಯುಕ್ತ',
'ನಿರುಪಯೋಗ',
'ನಿರುಪಯೋಗಿ',
'ನಿರುಪಹತಿ',
'ನಿರುಪದಾನ',
'ನಿರುಪಾಧಿಕ',
'ನಿರುಪಾಯತೆ',
'ನಿರುಹರಣ',
'ನಿರೂಹದ್ರವ್ಯ',
'ನಿರೋಧಂಗೊಳ್',
'ನಿರೋಧರತಿ',
'ನಿರ್ಕುಳಿಗೊಳ್',
'ನಿರ್ಗಂಧವಾಯು',
'ನಿರ್ಗುಣಬ್ರಹ್ಮ',
'ನಿರ್ಗುಣಭಕ್ತಿ',
'ನಿರ್ಗುಣವಾಯು',
'ನಿರ್ಗುಣಶ್ರುತಿ',
'ನಿರ್ಗ್ರಂಥಧರ್ಮ',
'ನಿರ್ಗ್ರಂಥಯತಿ',
'ನಿರ್ಗ್ರಂಥಸಾಧು',
'ನಿರ್ಘೃಣಾತ್ಮಕ',
'ನಿರ್ಜಿತೇಂದ್ರಿಯ',
'ನಿರ್ಜೀವತತ್ತ್ವ',
'ನಿರ್ಣಯಂಬಡೆ',
'ನಿರ್ಣಯಕಾರ',
'ನಿರ್ದಯತನ',
'ನಿರ್ದುಷ್ಟತನ',
'ನಿರ್ದೇಶಚಿಹ್ನೆ',
'ನಿರ್ದೇಶಾರ್ಥಕ',
'ನಿದ್ರ್ವಂದ್ವಭಾವ',
'ನಿರ್ಧಾಮಧೂಮ',
'ನಿರ್ಧಾನಧೂಮಂ',
'ನಿರ್ಧಾಸ್ತತನ',
'ನಿರ್ನಾಳಗ್ರಂಥಿ',
'ನಿರ್ಬುದ್ಧಿಗೊಳ್',
'ನಿರ್ಬುದ್ಧಿತನ',
'ನಿರ್ಭಾಗಧೇಯ',
'ನಿರ್ಭೀತಚಿತ್ತ',
'ನಿರ್ಭೀತಚಿತ್ತೆ',
'ನಿರ್ಮಂಥಕಾಷ್ಠ',
'ನಿರ್ಮತ್ಸರತೆ',
'ನಿರ್ಮಮಕಾರ',
'ನಿರ್ಮಲಚಿತ್ತ',
'ನಿರ್ಮಲಚಿತ್ತೆ',
'ನಿರ್ಮಲೀಕೃತ',
'ನಿರ್ಮಳಚಿತ್ತ',
'ನಿರ್ಮಳಚಿತ್ತೆ',
'ನಿರ್ಮಳೀಕೃತ',
'ನಿರ್ಮಾಣಕಾಯ',
'ನಿರ್ಮಾಣಕಾರ',
'ನಿರ್ಮಾಣತಂತ್ರ',
'ನಿರ್ಯಾಣಘಟ್ಟ',
'ನಿರ್ಯಾಣಹೊಂದು',
'ನಿರ್ಯಾತಶುಲ್ಕ',
'ನಿರ್ಯಾತಸುಂಕ',
'ನಿರ್ಲಿಂಗಹೂವು',
'ನಿರ್ಲಿಪ್ತವೃತ್ತಿ',
'ನಿರ್ವಂಚಕತ್ವ',
'ನಿರ್ವಾಣಗೊಡು',
'ನಿರ್ವಾಣಧಾತು',
'ನಿರ್ವಾಣನಾಥ',
'ನಿರ್ವಾಣಪದ',
'ನಿರ್ವಾಣಹೊಂದು',
'ನಿರ್ವಿಕಲ್ಪಕ',
'ನಿರ್ವಿಕಲ್ಪನೆ',
'ನಿರ್ವಿಕಲ್ಪಿತ',
'ನಿರ್ವಿಕಳತೆ',
'ನಿರ್ವಿಕಾರತೆ',
'ನಿರ್ವಿಚಿಕಿತ್ಸೆ',
'ನಿರ್ವಿಣ್ಣಮನ',
'ನಿರ್ವಿಣ್ಣಮನೆ',
'ನಿರ್ವಿಷಸಿದ್ಧ',
'ನಿರ್ವಿಷಿಹುಲ್ಲು',
'ನಿರ್ವೃತಿಕರ',
'ನಿರ್ವೃತಿಪದ',
'ನಿರ್ವೇಗರಸ',
'ನಿರ್ವೇದಂಗೊಳ್',
'ನಿವ್ರ್ಯಭಿಚಾರ',
'ನಿಲಗಾಯಿಸು',
'ನಿಲಯಕಾಣು',
'ನಿಲುಕುಗಾಲ್',
'ನಿಲುಕುಗಾಲು',
'ನಿಲುಗನ್ನಡಿ',
'ನಿಲುಗೊಳವೆ',
'ನಿಲುಪಯಣ',
'ನಿವಳಿವಟ್ಟ',
'ನಿವಾಸಸ್ಥಾನ',
'ನಿವಾಳಿತೆಗೆ',
'ನಿವಾಳಿದೆಗೆ',
'ನಿವಾಳಿಯೆತ್ತು',
'ನಿವಾಳಿವಟ್ಟ',
'ನಿವಾಳಿವಟ್ಟು',
'ನಿವಾಳಿಸುಹ',
'ನಿವಾಳಿಹಾಕು',
'ನಿವಾಳೆಯಿಡು',
'ನಿವೃತ್ತಿಧರ್ಮ',
'ನಿವೃತ್ತಿಮಾರ್ಗ',
'ನಿವೇದ್ಯಂಗೊಡು',
'ನಿಶಿತಧಾರೆ',
'ನಿಶಿತಮತಿ',
'ನಿಶ್ಚಂಚಲಿತ',
'ನಿಶ್ಚಯಂಗೆಯ್',
'ನಿಶ್ಚಯಂಬಡೆ',
'ನಿಶ್ಚಯಗುಣ',
'ನಿಶ್ಚಯಗುಣಿ',
'ನಿಶ್ಚಯಬುದ್ಧ',
'ನಿಶ್ಚಯಬುದ್ಧಿ',
'ನಿಶ್ಚಳಚಿತ್ತ',
'ನಿಶ್ಚಳಚಿತ್ತೆ',
'ನಿಶ್ಚಿಂತವಾಸಿ',
'ನಿಶ್ಚಿತಮನ',
'ನಿಶ್ಚಿತಮನೆ',
'ನಿಶ್ಚೇಷ್ಟಸ್ಥಿತಿ',
'ನಿಶ್ಶಬ್ದವೇದಿ',
'ನಿಷಾನಿಖಲು',
'ನಿಷಿದ್ಧಗೊಳ್',
'ನಿಷೇಕಪ್ರಸ್ತ',
'ನಿಷೇಧನ್ಯೂನ',
'ನಿಷೇಧರೂಪ',
'ನಿಷೇಧಾಕ್ಷರಿ',
'ನಿಷೇಧಾತ್ಮಕ',
'ನಿಷೇಧಾಭಾಸ',
'ನಿಷೇಧಾರ್ಥಕ',
'ನಿಷೇವಣೀಯ',
'ನಿಷ್ಕಂಟಕತ್ವ',
'ನಿಷ್ಕಂಟಕಿತ',
'ನಿಷ್ಕಪಟತೆ',
'ನಿಷ್ಪಪಟಸ್ಥ',
'ನಿಷ್ಕರುಣತೆ',
'ನಿಷ್ಕಷಾಯತ್ವ',
'ನಿಷ್ಕಳಂಕಿನಿ',
'ನಿಷ್ಕಳತತ್ವ',
'ನಿಷ್ಕಾಮಕರ್ಮ',
'ನಿಷ್ಕಾಮಯೋಗ',
'ನಿಷ್ಕಿಂಚನತ್ವ',
'ನಿಷ್ಕುಟಿಲತೆ',
'ನಿಷ್ಕೃತಿತನ',
'ನಿಷ್ಕ್ರಿಯಾತ್ಮಕ',
'ನಿಷ್ಕ್ರಿಯಾವಾದ',
'ನಿಷ್ಕ್ರಿಯಾವಾದಿ',
'ನಿಷ್ಠಾವಂತಿಕೆ',
'ನಿಷ್ಠುರಜಿಹ್ವ',
'ನಿಷ್ಠುರತನ',
'ನಿಷ್ಠುರವಾದಿ',
'ನಿಷ್ಠುರವೃತ್ತಿ',
'ನಿಷ್ಠುರಾಕ್ಷರ',
'ನಿಷ್ಠೂರತನ',
'ನಿಷ್ಠೂರವಾದಿ',
'ನಿಷ್ಪಕ್ಷಪಾತ',
'ನಿಷ್ಪಕ್ಷಪಾತಿ',
'ನಿಷ್ಪತಿಪದ',
'ನಿಷ್ಪನ್ನರೂಪ',
'ನಿಷ್ಪನ್ನಶಬ್ದ',
'ನಿಷ್ಪಾಕ್ಷಿಕತೆ',
'ನಿಷ್ಪೀಡನಂಗೈ',
'ನಿಷ್ಪ್ರಕಂಪನ',
'ನಿಷ್ಪ್ರಯೋಜಕ',
'ನಿಷ್ಪ್ರಯೋಜಕಿ',
'ನಿಷ್ಪ್ರಯೋಜನ',
'ನಿಷ್ಪ್ರಯೋಜನಂ',
'ನಿಷ್ಪ್ರವೀಚಾರ',
'ನಿಸರ್ಗತತ್ವ',
'ನಿಸರ್ಗದತ್ತ',
'ನಿಸರ್ಗಭೀರು',
'ನಿಸರ್ಗವಾದ',
'ನಿಸರ್ಗವಾದಿ',
'ನಿಸಿದಿಕಲ್ಲು',
'ನಿಸಿದಿಗಲ್ಲು',
'ನಿಸೂರುಗಾರ',
'ನಿಸೃಷ್ಟಿಲೇಖ',
'ನಿಸ್ತರಂಗಿತ',
'ನಿಸ್ತೇಜಂಬೆಱು',
'ನಿಸ್ತ್ರಿಂಶಕರ್ಮ',
'ನಿಸ್ತ್ರಿಂಶಭಾವ',
'ನಿಸ್ಪೃಹಾತ್ಮಕ',
'ನಿಸ್ಸಂಕುಚಿತ',
'ನಿಸ್ಸಂದಿಗ್ಧತೆ',
'ನಿಸ್ಸಹಾಯಕ',
'ನಿಸ್ಸಹಾಯಕಿ',
'ನಿಸ್ಸೀಮತನ',
'ನಿಹಟದಂಶು',
'ನಿಳಯವೇದಿ',
'ನಿಱಿಗೆಗಾಣು',
'ನಿಱಿಸುಂಗಲ್ಲು',
'ನಿಱಸುಕೆಯ್',
'ನಿಱಿಸುಗೆಯ್',
'ನಿಱುವುಂಗಲ್',
'ನೀಚಪ್ರವೃತ್ತಿ',
'ನೀಟಪಡಿಸು',
'ನೀಟುಗಾರಿಕೆ',
'ನೀಟುಗಾಱಿಕೆ',
'ನೀತವಿಭೂತಿ',
'ನೀತಿಕೋವಿದ',
'ನೀತಿಕೋವಿದೆ',
'ನೀತಿಗೆಡಿಸು',
'ನೀತಿಚಾಣಕ್ಯ',
'ನೀತಿನಿಪುಣ',
'ನೀತಿನಿಪುಣೆ',
'ನೀತಿನೈಪುಣ್ಯ',
'ನೀತಿಬಾಹಿರ',
'ನೀತಿಬಾಹಿರೆ',
'ನೀತಿಬೋಧಕ',
'ನೀತಿಭ್ರಷ್ಟತೆ',
'ನೀತಿವಚನ',
'ನೀತಿವಿರುದ್ಧ',
'ನೀತಿವಿಹೀನ',
'ನೀತಿವಿಹೀನೆ',
'ನೀತಿಸಮ್ಮತ',
'ನೀಮ್ತಾನದಾರ',
'ನೀರಕೆಸವು',
'ನೀರಕ್ಕಿ ಅನ್ನ',
'ನೀರದರವ',
'ನೀರದೋಪಳ',
'ನೀರಪಾವಡಿ',
'ನೀರವತ್ತಿಗೆ',
'ನೀರಸಂಗೆಯ್',
'ನೀರಹಿಪ್ಪಲಿ',
'ನೀರಹೊಂಬಾಳೆ',
'ನೀರೞ್ಕೆಗೊಳ್',
'ನೀರಾಟಗೊಳ್',
'ನೀರಾಟವಾಡು',
'ನೀರಾಣಿವರಿ',
'ನೀರಾಮೆಬಳ್ಳಿ',
'ನೀರಾವರಿಕೆ',
'ನೀರಿಸ್‍ಹುಳು',
'ನೀರಿೞಿಯಿಸು',
'ನೀರುಅಕ್ರೋಟು',
'ನೀರುಕಂದಾಯ',
'ನೀರುಕಾಲುವೆ',
'ನೀರುಕುಟಿಗ',
'ನೀರುಕುಡಿಸು',
'ನೀರುಕುದುರೆ',
'ನೀರುಕೊಕ್ಕರೆ',
'ನೀರುಕೋಗಿಲೆ',
'ನೀರುಕೋಟಲೆ',
'ನೀರುಗಂಗಾಳ',
'ನೀರುಗಂಟಿಗ',
'ನೀರುಗಂದಾಯ',
'ನೀರುಗರಗ',
'ನೀರುಗೊಬ್ಬಳಿ',
'ನೀರುಗೊರವ',
'ನೀರುಗೋಡಗ',
'ನೀರುಚಂಚಲಿ',
'ನೀರುಚೆಂಚಲಿ',
'ನೀರುಜಪ್ಪಲಿ',
'ನೀರುಣಿಸುಂಕ',
'ನೀರುತರ್ಕಾರಿ',
'ನೀರುತುಳಸಿ',
'ನೀರುದಲೆಯ',
'ನೀರುನವಿಲು',
'ನೀರುನೇರಳೆ',
'ನೀರುಪಲಸು',
'ನೀರುಪಾರಿವ',
'ನೀರುಮಜ್ಜಿಗೆ',
'ನೀರುಹಲಸು',
'ನೀರುಹಾಯಿಸು',
'ನೀರುಹಿಪ್ಪಲಿ',
'ನೀರುಳ್ಳಿಗೆಡ್ಡೆ',
'ನೀರೆರಕಲು',
'ನೀರೆಱಕಲು',
'ನೀರ್ಕುಳಿಗೊಳ್',
'ನೀರ್ಗಡಿಯಾರ',
'ನೀರ್ಗನಿಗಲು',
'ನೀರ್ನೆಲೆದಪ್ಪು',
'ನೀರ್ವಾಡಮಲ್ಲಿ',
'ನೀಲಕಂಧರ',
'ನೀಲಕರುಕೆ',
'ನೀಲಕಱುಕೆ',
'ನೀಲಗೋರಟೆ',
'ನೀಲಪ್ರಸ್ತರ',
'ನೀಲಲೋಹಿತ',
'ನೀಲಿಗವಾರ್ತೆ',
'ನೀಲಿಜುಮಕಿ',
'ನೀಲಿಸ್ಫಟಿಕ',
'ನೀವಳಿಗರೆ',
'ನೀವಳಿವಟ್ಟ',
'ನೀವಾರಬಲಿ',
'ನೀವಾಳಿತೆಗೆ',
'ನೀವಾಳಿಯೆತ್ತು',
'ನೀವೀಬಂಧನ',
'ನೀಹಾರಕರ',
'ನೀಹಾರಕಾಲ',
'ನೀಳ್ಕುಳಿಗೊಳ್',
'ನೀಱೆರಕಲು',
'ನುಗುತೆಗಂಡಿ',
'ನುಗುಳುಗಂಡಿ',
'ನುಚ್ಚುನುಗ್ಗಡಿ',
'ನುಚ್ಚುಮಲ್ಲಿಗೆ',
'ನುಡಿಗಲಿಸು',
'ನುಡಿಗಾರಿಕೆ',
'ನುಡಿಗಾಱಿಕೆ',
'ನುಡಿಗೇಡುಗ',
'ನುಡಿಯುಡುಗು',
'ನುಡಿವಳಿಕೆ',
'ನುಣ್ದನಿವಿಡು',
'ನುನುನ್ನಿಗಿಡ',
'ನುರುಜುಕಲ್ಲು',
'ನುರುಜುಗಲ್ಲು',
'ನುಲಿಗಡಗ',
'ನುಲಿಗೆಕಪ್ಪ',
'ನುಸಿಗುಳಿಗೆ',
'ನುಸುಲುಮಣ್ಣು',
'ನುಸುಳುಗಂಡಿ',
'ನೂತನಂಬಡೆ',
'ನೂತನವಡೆ',
'ನೂಪುರಪಾದ',
'ನೂಲುಕಂಡಿಕೆ',
'ನೂಲುಮದುವೆ',
'ನೂಲೆಗೆಣಸು',
'ನೃತ್ಯನಾಟಕ',
'ನೃತ್ಯಮಂದಿರ',
'ನೆಗರ್ತೆವಡೆ',
'ನೆಗರ್ತೆವೆಱು',
'ನೆಗಳ್ತೆವಡೆ',
'ನೆಗಳ್ತೆವೆಱು',
'ನೆಗೞ್ತೆಗೆಯ್',
'ನೆಗೞ್ತೆವಡೆ',
'ನೆಗೞ್ತೆವೆಱು',
'ನೆಟಕೆಮುರಿ',
'ನೆಟಗೆತೆಗೆ',
'ನೆಟಿಕೆಮುರಿ',
'ನೆಟಿಗೆ ಮುರಿ',
'ನೆಟ್ಟಗರುಳು',
'ನೆಟ್ಟರುಹೊನ್ನೆ',
'ನೆಟ್ಟಿಗೆಮುರಿ',
'ನೆತ್ತಗೊಡಗೆ',
'ನೆತ್ತರುಕುರು',
'ನೆತ್ತರುಗಯ್',
'ನೆತ್ತರುಗೆಂಪು',
'ನೆತ್ತರುಗೆಯ್',
'ನೆತ್ತರುಣಿಗ',
'ನೆತ್ತರುನಂಜು',
'ನೆತ್ತರುಮಾನ್ಯ',
'ನೆತ್ತರುಗೊಡೆ',
'ನೆತ್ತವಲಗೆ',
'ನೆತ್ತಿವಟ್ಟಿಗೆ',
'ನೆನಗಡಲೆ',
'ನೆನವರಿಕೆ',
'ನೆನವರಿಸು',
'ನೆನಹುಗೆಡು',
'ನೆನಹುಡುಗು',
'ನೆನೆಗಡಲೆ',
'ನೆಮ್ಮದಿಪರ',
'ನೆಮ್ಮದಿವಂತ',
'ನೆಮ್ಮದಿವಂತೆ',
'ನೆಮ್ಮದಿವಡೆ',
'ನೆಮ್ಮದಿವೆರು',
'ನೆಮ್ಮುಗೆಗೊಳ್',
'ನೆಮ್ಮುಗೆವಿಡಿ',
'ನೆರಕೆಗಟ್ಟು',
'ನೆರವಂಬಳೆ',
'ನೆರವಣಿಗೆ',
'ನೆರವಿವಡೆ',
'ನೆರವುತಪ್ಪು',
'ನೆರವುನೀಡು',
'ನೆರವುಪಡೆ',
'ನೆರವುಬರು',
'ನೆರವುಹಾಕು',
'ನೆರವೇರಿಕೆ',
'ನೆರವೇರಿಸು',
'ನೆರಹುಗೂಡು',
'ನೆರಳುಕೊಡು',
'ನೆರಳುತರೆ',
'ನೆರಳುನೀಡು',
'ನೆರಿಕೆಮನೆ',
'ನೆರಿಗೆಬೀಳು',
'ನೆರೆಕಾಲುವೆ',
'ನೆರೆಪೊನಲ್',
'ನೆರೆಬೆಳ್ಳಕ್ಕಿ',
'ನೆರೆವೊನಲ್',
'ನೆರೆಹಾವಳಿ',
'ನೆರೆಹೊರಕೆ',
'ನೆಲಅಣಬೆ',
'ನೆಲಕಡಲೆ',
'ನೆಲಗಂದಾಯ',
'ನೆಲಗಟ್ಟಿಸು',
'ನೆಲಗಡಚ',
'ನೆಲಗಡಲೆ',
'ನೆಲಗಡವ',
'ನೆಲಗಡವು',
'ನೆಲಗರ್ನಾಲು',
'ನೆಲಗಿರುಬ',
'ನೆಲಗುಂಬಳ',
'ನೆಲಗುಡುಗು',
'ನೆಲಗೆದರು',
'ನೆಲಗೋರಟೆ',
'ನೆಲತಗಚೆ',
'ನೆಲತಗಸಿ',
'ನೆಲತಾವರೆ',
'ನೆಲದಾವರೆ',
'ನೆಲದುರುವೆ',
'ನೆಲದೊಗಟೆ',
'ನೆಲನಡುಗು',
'ನೆಲನಾರಂಗಿ',
'ನೆಲನೆಕ್ಕರೆ',
'ನೆಲನೇರಳೆ',
'ನೆಲನೇಱಿಲ್',
'ನೆಲನೇಱಿಲು',
'ನೆಲಬದರಿ',
'ನೆಲಬಲಿಕೆ',
'ನೆಲಬುರುಕಿ',
'ನೆಲಬೈರಿಗೆ',
'ನೆಲಮಹಡಿ',
'ನೆಲಮಾಗಳಿ',
'ನೆಲಮಾಗುಳಿ',
'ನೆಲಮಾಳಿಗೆ',
'ನೆಲಮುಚ್ಚಳ',
'ನೆಲಮುಚ್ಚಳು',
'ನೆಲಮುಚ್ಚಿಲು',
'ನೆಲರುದ್ರಾಕ್ಷಿ',
'ನೆಲವತ್ತಿಗ',
'ನೆಲವಾಳಿಗೆ',
'ನೆಲವೊರೆಗ',
'ನೆಲಸಂಪಗೆ',
'ನೆಲಸಂಪಿಗೆ',
'ನೆಲಸರಿಸ',
'ನೆಲಸುನಾಡು',
'ನೆಲಸುಹಕ್ಕು',
'ನೆಲಸೆಣಬು',
'ನೆಲಹಲಸು',
'ನೆಲಹಾದರಿ',
'ನೆಲಹಿಪ್ಪಲಿ',
'ನೆಲಹೇರಿಳೆ',
'ನೆಲಾವರಿಕೆ',
'ನೆಲೆಗಾಣಿಸು',
'ನೆಲೆಗಿಡಿಸು',
'ನೆಲೆಗೆಡಿಸು',
'ನೆಲೆಗೊಳಿಸು',
'ನೆಲೆತಪ್ಪಿಸು',
'ನೆಲೆದಪ್ಪಿಸು',
'ನೆಲೆದೀವಿಗೆ',
'ನೆಲೆದೇಗುಲ',
'ನೆಲೆವಟ್ಟಣ',
'ನೆಲೆವಟ್ಟೆಗ',
'ನೆಲೆವಡಿಸು',
'ನೆಲೆವಾಗಿಲ್',
'ನೆಲೆವಾಳಿಗ',
'ನೆಲೆವೆರ್ಚಿಸು',
'ನೆಲೆಸೊಡರ್',
'ನೆಲ್ಲಕ್ಕಿಬಾಡೆ',
'ನೆಳಲಪಾಣಿ',
'ನೆಱತೆವಡೆ',
'ನೆಱವಣಿಗೆ',
'ನೆಱವಳಿಗೆ',
'ನೆಱೆವಣಿಗೆ',
'ನೆಱೆವಳಿಗೆ',
'ನೇಕನಿಯತ್ತು',
'ನೇಕುನಿಯತ್ತು',
'ನೇಗಲಿಹೊಡೆ',
'ನೇಗಿಲುಹೊಡೆ',
'ನೇಟಾಲ್ ಹುಲ್ಲು',
'ನೇತ್ರಚಿಕಿತ್ಸೆ',
'ನೇತ್ರಪಲ್ಲವಿ',
'ನೇತ್ರಪಾಟವ',
'ನೇತ್ರಪುತ್ರಿಕೆ',
'ನೇತ್ರಲಾಂಛನ',
'ನೇತ್ರಶ್ರವಣ',
'ನೇತ್ರ್ರಾಭ್ಯಂಗನ',
'ನೇಪಥ್ಯಗೀತ',
'ನೇಪಥ್ಯಗೃಹ',
'ನೇಪಾಳಬೇವು',
'ನೇಮಂಬೆರಸು',
'ನೇಮಕಗೊಳ್ಳು',
'ನೇಮಕಪತ್ರ',
'ನೇಮಕಹೊಂದು',
'ನೇಮಗಾರಿಕೆ',
'ನೇಮಬಿಡಿಸು',
'ನೇಮಿವತ್ತಿಗೆ',
'ನೇಯಾರ್ಥದೋಷ',
'ನೇರಗೊಳಿಸು',
'ನೇರಲುತಾರೆ',
'ನೇರಸಂಧಾನ',
'ನೇರಳುತಾರೆ',
'ನೇರಳೆಬಳ್ಳಿ',
'ನೇರಳೆಮಾವು',
'ನೇರ್ಪಡುಗಿಡು',
'ನೇರ್ಪುಗೆಡಿಸು',
'ನೇರ್ಪುಗೊಳಿಸು',
'ನೇಸರ್ಗದಿರ್',
'ನೇಸರ್ಮಯಣ',
'ನೇಸಱಬಟ್ಟು',
'ನೇಸಱವಟ್ಟು',
'ನೇಹಗಲಹ',
'ನೈತಿಕಜಯ',
'ನೈತಿಕಧೈರ್ಯ',
'ನೈತಿಕಪ್ರಜ್ಞೆ',
'ನೈತಿಕಬಲ',
'ನೈತಿಕಸ್ಥೈರ್ಯ',
'ನೈಪಥ್ಯಂಗೆಯ್',
'ನೈಪಥ್ಯಂದಳೆ',
'ನೈಪಥ್ಯಂಬಡೆ',
'ನೈಪಥ್ಯಗೃಹ',
'ನೈಮಿತ್ತಕರ್ಮ',
'ನೈರಾಜ್ಯಪಂಥಿ',
'ನೈರಾತ್ಮ್ಯವಾದ',
'ನೈಲಾನುಬಟ್ಟೆ',
'ನೈಷ್ಟಿಕಮಠ',
'ನೈಷ್ಟಿಕಸ್ಥಾನ',
'ನೈಷ್ಠಿಕತನ',
'ನೈಷ್ಠಿಕಭಕ್ತಿ',
'ನೈಷ್ಠಿಕಸ್ಥಾನ',
'ನೊಣಸುತ್ತಕ',
'ನೊರಜುಗಲ್ಲು',
'ನೊರಜುವುೞು',
'ನೊರೆಯಡಕೆ',
'ನೊಸಲಕ್ಕರ',
'ನೊಸಲಗಣ್ಣ',
'ನೊಸಲಗಣ್ಣು',
'ನೊಸಲೆಗಲ್ಲು',
'ನೋಟುಪುಸ್ತಕ',
'ನೋಡುಗಬೇರು',
'ನೋಡುಗುದುರೆ',
'ನೌಕರವರ್ಗ',
'ನೌಕರಶಾಹಿ',
'ನೌಕರಷಾಹಿ',
'ನೌಕಾನಯನ',
'ನೌಕಾವಹನ',
'ನೌಬತುಖಾನೆ',
'ನ್ಯಾಯದರ್ಶನ',
'ನ್ಯಾಯನಿಯುಕ್ತ',
'ನ್ಯಾಯನಿರ್ಣಯ',
'ನ್ಯಾಯನಿಷ್ಠುರ',
'ನ್ಯಾಯನಿಷ್ಠುರಿ',
'ನ್ಯಾಯನಿಷ್ಠುರೆ',
'ನ್ಯಾಯಪದ್ಧತಿ',
'ನ್ಯಾಯಪರತೆ',
'ನ್ಯಾಯಪಾಲಿಕೆ',
'ನ್ಯಾಯಪುರುಷ',
'ನ್ಯಾಯಪ್ರಕ್ರಿಯೆ',
'ನ್ಯಾಯಬಾಹಿರ',
'ನ್ಯಾಯವಿರುದ್ಧ',
'ನ್ಯಾಯಸಮ್ಮತ',
'ನ್ಯಾಯಾಧಿಪತಿ',
'ನ್ಯಾಯಾನಪೇತ',
'ನ್ಯಾವಳಸರ',
'ನ್ಯಾಸಪೂರಿತ',
'ನ್ಯಾಸಾಪಹಾರ',
'ನ್ಯೂನಪೋಷಣೆ',
'ನ್ಯೂನಾಭಿವೃದ್ಧ',
'ನ್ಯೂನಾಭಿವೃದ್ಧಿ',
'ಪಂಕಜಗರ್ಭ',
'ಪಂಕಜನಾಭ',
'ಪಂಕಜನೇತ್ರೆ',
'ಪಂಕಜಮುಖಿ',
'ಪಂಕ್ತಿಭೋಜನ',
'ಪಂಗಣಿಗಪ್ಪು',
'ಪಂಚಕಚೇರಿ',
'ಪಂಚಕಜ್ಜಾಯ',
'ಪಂಚಕಠಾರಿ',
'ಪಂಚಕರಣ',
'ಪಂಚಕಲಶ',
'ಪಂಚಕಲ್ಯಾಣ',
'ಪಂಚಕಲ್ಯಾಣಿ',
'ಪಂಚಕಳಶ',
'ಪಂಚಕಾರುಕ',
'ಪಂಚಗಜ್ಜಾಯ',
'ಪಂಚಗುಂಡಿಗೆ',
'ಪಂಚತನ್ಮಾತ್ರ',
'ಪಂಚತನ್ಮಾತ್ರೆ',
'ಪಂಚತಾವಡ',
'ಪಂಚದ್ರಾವಿಡ',
'ಪಂಚಫಕೀರ',
'ಪಂಚಮಕಾರ',
'ಪಂಚಮವೇದ',
'ಪಂಚಮಶ್ರುತಿ',
'ಪಂಚಮಿಗಿತಿ',
'ಪಂಚರಾಶಿಕ',
'ಪಂಚವಲ್ಕಲ',
'ಪಂಚವಾರ್ಷಿಕ',
'ಪಂಚವಿಂಶತಿ',
'ಪಂಚಸಂಸ್ಕಾರ',
'ಪಂಚಾಂಗಚೂರ್ಣ',
'ಪಂಚಾಂಗರಾಯ',
'ಪಂಚಾಂಗಶುದ್ಧಿ',
'ಪಂಚಾಣುವ್ರತ',
'ಪಂಚಾಭಿಷೇಕ',
'ಪಂಚಾಯತನ',
'ಪಂಚಾಯತಿಕೆ',
'ಪಂಚಾಯಿತಿಕೆ',
'ಪಂಚಾಯ್ತಿಮನೆ',
'ಪಂಚಾರಶೀಲ',
'ಪಂಚಾವರಣ',
'ಪಂಚಿಕರಣ',
'ಪಂಚೀಕರಣ',
'ಪಂಚೀಕರಿಸು',
'ಪಂಚುಗೆಗೊಳ್',
'ಪಂಚೋಪಚಾರ',
'ಪಂಚೌದುಂಬರ',
'ಪಂಜರಂಬಡೆ',
'ಪಂಜರವಳ್ಳಿ',
'ಪಂಜರಾಖೇಟ',
'ಪಂಟಿಮಾರಿಗ',
'ಪಂಡಿತಂಮನ್ಯ',
'ಪಂಡಿತಪುತ್ರ',
'ಪಂಡಿತವಕ್ಕಿ',
'ಪಂಡಿತವರ',
'ಪಂಡಿತವರ್ಯ',
'ಪಂತಿಗೊಳಿಸು',
'ಪಂತಿಯುಣಿಸು',
'ಪಂತುವರಾಲಿ',
'ಪಂತುವರಾಳಿ',
'ಪಕಪಕನೆ',
'ಪಕ್ಕರೆಯಿಕ್ಕು',
'ಪಕ್ಕವಾದಕ',
'ಪಕ್ಕಾಖರಡೆ',
'ಪಕ್ಕಿಯರಸ',
'ಪಕ್ಕಿಯೆಱೆಯ',
'ಪಕ್ಕವಡಿಕ',
'ಪಕ್ವಚೇತನ',
'ಪಕ್ಷಪ್ರದೋಷ',
'ಪಕ್ಷವಂಚಿತ',
'ಪಕ್ಷಿವಾಹನ',
'ಪಕ್ಷೀಕರಿಸು',
'ಪಕ್ಷೋಪವಾಸಿ',
'ಪಕ್ಷ್ಮಪಾತನ',
'ಪಕ್ಷ್ಮಸ್ಪಂದನ',
'ಪಗಂಡುಗೊಳ್',
'ಪಗಡೆಕಾಯಿ',
'ಪಗಡೆಕೋಟೆ',
'ಪಗಡೆಚಾರಿ',
'ಪಗಡೆದಾಳ',
'ಪಗಡೆವಾಸು',
'ಪಗಡೆಹಾಸು',
'ಪಗಡೆ ನೆತ್ತ',
'ಪಗಡೆ ಪಟ್ಟ',
'ಪಗಡೆ ಮನೆ',
'ಪಗರಣಿಗ',
'ಪಗಲುಗೋಲು',
'ಪಗಲೆರೆಯ',
'ಪಗಲೆಱೆಯ',
'ಪಗಲ್ಗನಸು',
'ಪಗುಡಿತನ',
'ಪಚಗಾರಿಸು',
'ಪಚನಂಗೆಯ್',
'ಪಚನಕ್ರಿಯೆ',
'ಪಚನರಸ',
'ಪಚ್ಚಕರ್ಪುರ',
'ಪಚ್ಚಕರ್ಪೂರ',
'ಪಚ್ಚಗಪ್ಪುರ',
'ಪಚ್ಚಗರ್ಪುರ',
'ಪಚ್ಚಡಿಗೆಯ್ಯು',
'ಪಚ್ಚನೆಗಳ್ಳಿ',
'ಪಚ್ಚವಡತಿ',
'ಪಚ್ಚವಡಿಕೆ',
'ಪಚ್ಚವಡಿಗೆ',
'ಪಚ್ಚವಡಿಸು',
'ಪಚ್ಚುಗೆಗಾರ್ತಿ',
'ಪಚ್ಚೆಕರ್ಪುರ',
'ಪಚ್ಚೆಕರ್ಪೂರ',
'ಪಚ್ಚೆಗಪ್ಪುರ',
'ಪಚ್ಚೆಗಬ್ಬುನ',
'ಪಚ್ಚೆಗರ್ಬುನ',
'ಪಚ್ಚೆಯತೆನೆ',
'ಪಚ್ಚೆವಡಿಸು',
'ಪಚ್ಚೆವರಲ್',
'ಪಚ್ಚೆವಾಕಲು',
'ಪಚ್ಚೆಹೆಸರು',
'ಪಚ್ಚೋಲೆವಚ್ಚ',
'ಪಜ್ಜಳಂಬೆಱು',
'ಪಟಕುಟೀರ',
'ಪಟಪಟನೆ',
'ಪಟಪಟಿಸು',
'ಪಟಮಂಟಪ',
'ಪಟಮಂಡಪ',
'ಪಟಮಂದಿರ',
'ಪಟಲಕಣ್ಣು',
'ಪಟಲಗಣ್ಣು',
'ಪಟಲಪ್ರಾಂತ',
'ಪಟವಾಸಕ',
'ಪಟವಿಳಿಸು',
'ಪಟವೆಗಾರ',
'ಪಟವೈಶ್ಯಜ',
'ಪಟಹಡಕ್ಕೆ',
'ಪಟಿಂಗತನ',
'ಪಟಲುಗಟ್ಟು',
'ಪಟೀರದ್ರುಮ',
'ಪಟುಕರಣ',
'ಪಟುಕರಣೆ',
'ಪಟುಗೊಳಿಸು',
'ಪಟುಪಟಹ',
'ಪಟ್ಟಂಗಟ್ಟಿಸು',
'ಪಟ್ಟಂಪಾಱಿಸು',
'ಪಟ್ಟಂಬಾಱಿಸು',
'ಪಟ್ಟಗದ್ದುಗೆ',
'ಪಟ್ಟಗರ್ದುಗೆ',
'ಪಟ್ಟಡಿಮನೆ',
'ಪಟ್ಟಡಿಮರ',
'ಪಟ್ಟಣಗಾಱ',
'ಪಟ್ಟಣವಾಸ',
'ಪಟ್ಟಣವಾಸಿ',
'ಪಟ್ಟಣಶೆಟ್ಟಿ',
'ಪಟ್ಟಣಸಾಮಿ',
'ಪಟ್ಟಣಸಾವಿ',
'ಪಟ್ಟಣಸಾಳಿ',
'ಪಟ್ಟಣಸೆಟ್ಟಿ',
'ಪಟ್ಟಣಸ್ವಾಮಿ',
'ಪಟ್ಟತ್ತಿಮೂಡಿ',
'ಪಟ್ಟಪುಣಿಜ',
'ಪಟ್ಟಬಂಧನ',
'ಪಟ್ಟಬಾಸಿಗ',
'ಪಟ್ಟಮಹಿಷಿ',
'ಪಟ್ಟಮಾದೇವಿ',
'ಪಟ್ಟಮುಡುಹು',
'ಪಟ್ಟವರ್ಧನ',
'ಪಟ್ಟಸಾಲಿಗ',
'ಪಟ್ಟಸಾಲಿತಿ',
'ಪಟ್ಟಳೆಕಂಭ',
'ಪಟ್ಟಾಭಿಷಿಕ್ತ',
'ಪಟ್ಟಾಭಿಷೇಕ',
'ಪಟ್ಟಿಕಾಲೋಧ್ರ',
'ಪಟ್ಟೆಗಂಡೆಯ',
'ಪಟ್ಟೆಗಾಣಿಕೆ',
'ಪಠನಾಗಾರ',
'ಪಠ್ಯಪುಸ್ತಕ',
'ಪಡಜಮೀನು',
'ಪಡಪಡಿಸು',
'ಪಡಪವಳ',
'ಪಡಪುಗಾರ',
'ಪಡಪುಗಾರ್ತಿ',
'ಪಡಪುಗುಂದು',
'ಪಡಪುಗೆಡು',
'ಪಡಪುದೋಱು',
'ಪಡಲಿಡಿಸು',
'ಪಡಲಿರಿಸು',
'ಪಡಲ್ಪಡಿಸು',
'ಪಡಲ್ವಡಿಸು',
'ಪಡವಂದಿಗ',
'ಪಡವಳತಿ',
'ಪಡವಳಿತಿ',
'ಪಡಿಕಮಣ',
'ಪಡಿಕೆನಾಱು',
'ಪಡಿಗಟ್ಟಳೆ',
'ಪಡಿಗಣಿಸು',
'ಪಡಿಗಯಿಸು',
'ಪಡಿತಳಿಸು',
'ಪಡಿದವಸ',
'ಪಡಿನಾಯಕ',
'ಪಡಿನೆಳಲ್',
'ಪಡಿನೆಳಲು',
'ಪಡಿನೆೞಲ್',
'ಪಡಿಪದಾರ್ಥ',
'ಪಡಿಪದ್ಧತಿ',
'ಪಡಿಪಾಟಲ',
'ಪಡಿಪಾಟಲು',
'ಪಡಿಬರಿಸು',
'ಪಡಿಬಲಿಸು',
'ಪಡಿಬಾಹಿರ',
'ಪಡಿಮಟ್ಟೆಯ',
'ಪಡಿಮಠೆಯ',
'ಪಡಿಮೂಡಿಸು',
'ಪಡಿಯಗಳು',
'ಪಡಿಯಗೞ್',
'ಪಡಿಯರತಿ',
'ಪಡಿಯಱತಿ',
'ಪಡಿಯಱಿಕೆ',
'ಪಡಿಯಱಿತಿ',
'ಪಡಿಯುತ್ತರ',
'ಪಡಿಲೇಖಿಸು',
'ಪಡಿವಟ್ಟಿಗೆ',
'ಪಡಿವರಿಜು',
'ಪಡಿಸಣಗೈ',
'ಪಡಿಸಣಿಗ',
'ಪಡಿಸಣಿಸು',
'ಪಡಿಸಲಿಕೆ',
'ಪಡಿಸಲಿಸು',
'ಪಡಿಸಾರಿಕೆ',
'ಪಡಿಹಾಱಿಕೆ',
'ಪಡುಕೆನಾಱು',
'ಪಡುನೇಸರ್',
'ಪಡುನೇಸಱ್',
'ಪಡುಪಾಟಲು',
'ಪಡೆಪುಗಾತಿ',
'ಪಡೆಪುಗಾರ್ತಿ',
'ಪಡೆಪುಗಿಡು',
'ಪಣತಖಾನೆ',
'ಪಣಭಾಮಿನಿ',
'ಪಣಾಚಾರಿಯ',
'ಪಣಿದಂಬೊಯ್',
'ಪಣಿದಕಾಱ',
'ಪಣಿಯವಟ್ಟ',
'ಪಣೆಯಕ್ಕರ',
'ಪಣ್ಣವನಿತೆ',
'ಪತಂಗವೃತ್ತಿ',
'ಪತಕರಿಸು',
'ಪತತ್ಪ್ರಕರ್ಷ',
'ಪತನಕಾರಿ',
'ಪತನಕಾಲ',
'ಪತನಗತಿ',
'ಪತನಮಾರ್ಗ',
'ಪತರಗುಟ್ಟು',
'ಪತರುಗುಟ್ಟು',
'ಪತಾಕಕರ',
'ಪತಾಕಹಸ್ತ',
'ಪತಾಕಾಪಟ',
'ಪತಾಕಾಹಸ್ತ',
'ಪತಿಕರಣೆ',
'ಪತಿಕರಿಸು',
'ಪತಿಬ್ರತಿಕೆ',
'ಪತಿವ್ರತತ್ವ',
'ಪತಿವ್ರತಸ್ಥೆ',
'ಪತಿವ್ರತಿಕೆ',
'ಪತಿವ್ರತಿಕ್ಕೆ',
'ಪತ್ತಳೆಗೂಳು',
'ಪತ್ತುಗೆಗಾಱ',
'ಪತ್ತುಗೆಗುಂದು',
'ಪತ್ತುಗೆಗುಡು',
'ಪತ್ತುಗೆಗೊಳ್',
'ಪತ್ತುಗೆವಿಡು',
'ಪತ್ತುಗೆವೆಱು',
'ಪತ್ತುವಿಡಿಸು',
'ಪತ್ರನಾಡಿಕೆ',
'ಪತ್ರಪರಶು',
'ಪತ್ರಪುಟಿಕೆ',
'ಪತ್ರಪುಟ್ಟಿಗೆ',
'ಪತ್ರಭಾಜನ',
'ಪತ್ರವಾಹಕ',
'ಪತ್ರವಾಹನ',
'ಪತ್ರಸಾಸನ',
'ಪತ್ರಿಕಾಕರ್ತ',
'ಪತ್ರಿಕಾಗೋಷ್ಠಿ',
'ಪತ್ರಿಕಾವೃತ್ತಿ',
'ಪತ್ರಿಕೆಹುಲ್ಲು',
'ಪತ್ರಿಕೋದ್ಯಮ',
'ಪತ್ರಿಕೋದ್ಯಮಿ',
'ಪತ್ರಿಕೋದ್ಯೋಗ',
'ಪತ್ರಿಕೋದ್ಯೋಗಿ',
'ಪಥಕಲ್ಪನೆ',
'ಪಥಗೊಳಿಸು',
'ಪದಂಗೊಳಿಸು',
'ಪದಂಬಡಿಸು',
'ಪದಕಟಕ',
'ಪದಕವಾದ',
'ಪದಗೆಡಿಸು',
'ಪದಗೊಳಿಸು',
'ಪದಗ್ರಹಣ',
'ಪದಘಟ್ಟಣೆ',
'ಪದದಲನ',
'ಪದದಳಿತ',
'ಪದನಖರ',
'ಪದನಿಮಿತ್ತ',
'ಪದನಿವಾಸ',
'ಪದನ್ಯಸನ',
'ಪದಪದ್ಧತಿ',
'ಪದಪಲ್ಲಟ',
'ಪದಪುಗಾರ',
'ಪದಪುಗಾರ್ತಿ',
'ಪದಪುಗೊಳ್',
'ಪದಪೂರಕ',
'ಪದಪೂರಣ',
'ಪದಪ್ರಹಾರ',
'ಪದಬಂಧನ',
'ಪದಭಜಕ',
'ಪದಮಂಜರಿ',
'ಪದಮಸ್ತಕ',
'ಪದರಗಲ್ಲು',
'ಪದರಚನೆ',
'ಪದರುಗಟ್ಟು',
'ಪದರುಗಲ್ಲು',
'ಪದರುಗುಂಡು',
'ಪದರುಬಂಡೆ',
'ಪದರೇಚಿತ',
'ಪದವಡಿಸು',
'ಪದವಿನತ',
'ಪದವಿನ್ಯಾಸ',
'ಪದವಿಭಾಗ',
'ಪದವಿಸೃತಿ',
'ಪದವೀದಾನ',
'ಪದವೀಧರ',
'ಪದವೀಧರೆ',
'ಪದವೀಪತ್ರ',
'ಪದಸಂಪತ್ತು',
'ಪದಸ್ತತನ',
'ಪದಹೇಳಿಸು',
'ಪದಾಂತಸಂಧಿ',
'ಪದಾಂತ್ಯಸಂಧಿ',
'ಪದಾಗಮಜ್ಞ',
'ಪದಾಗಮಜ್ಞೆ',
'ಪದಾಧಿಕಾರಿ',
'ಪದಾನುಗುಣ್ಯ',
'ಪದಾವನತಿ',
'ಪದಿಟೆಗೆಯ್',
'ಪದಿಟೆಗೆಯ್ಸು',
'ಪದಿರುವಾತು',
'ಪದಿರ್ಕಂಡುಗ',
'ಪದಿರ್ಕೊಳಗ',
'ಪದಿರ್ಮಡಿಸು',
'ಪದಿಷ್ಟೆಗೆಯ್',
'ಪದಿಷ್ಟೆಗೆಯ್ಸು',
'ಪದುಮನಾಭ',
'ಪದುಮರಾಗ',
'ಪದುಮವ್ಯೂಹ',
'ಪದುಮಸಖ',
'ಪದುಮಾಲಯೆ',
'ಪದುಳಂಗೆಡು',
'ಪದುಳಂಗೆಯ್',
'ಪದುಳಂಬಡೆ',
'ಪದುಳಂಬೆಱು',
'ಪದುಳಂಬೋಗು',
'ಪದುಳಗೊಳು',
'ಪದುಳಮಿರ್',
'ಪದುಳವಡೆ',
'ಪದುಳವಿಡು',
'ಪದುಳವಿರು',
'ಪದೆಪುಗಾರ',
'ಪದೆಪುಗಾರ್ತಿ',
'ಪದೆಪುಗೆಡು',
'ಪದ್ಮಕರ್ಣಿಕೆ',
'ಪದ್ಮಕೇಸರ',
'ಪದ್ಮಕೇಸರಿ',
'ಪದ್ಮಚಾರಿಣಿ',
'ಪದ್ಮಪುರಾಣ',
'ಪದ್ಮಪುಷ್ಕರ',
'ಪದ್ಮಭೂಷಣ',
'ಪದ್ಮಮಂದಿರೆ',
'ಪದ್ಮಲೋಚನ',
'ಪದ್ಮಲೋಚನೆ',
'ಪದ್ಮಸಂಹಿತೆ',
'ಪದ್ಮಾಸನಸ್ಥ',
'ಪದ್ಮಾಸನಸ್ಥೆ',
'ಪದ್ಯನಾಟಕ',
'ಪನಂಬುಬಳ್ಳಿ',
'ಪನಿದಂದಲ್',
'ಪನೋಪಂಭೋಗಿ',
'ಪನ್ನಗವಿದ್ಯೆ',
'ಪನ್ನಾಳಗಿಂಡಿ',
'ಪನ್ನಿಕ್ಕಂಡುಗ',
'ಪನ್ನಿಚ್ಛಾಸಿರ',
'ಪನ್ನಿರ್ಕಂಡುಗ',
'ಪನ್ನಿಚ್ರ್ಛಾಶಿರ',
'ಪನ್ನೀರುದಾನಿ',
'ಪನ್ನೆಗಪ್ಪುರ',
'ಪನ್ನೆರಳ್ಕೋಟಿ',
'ಪಪ್ಪಡಕಾರ',
'ಪಪ್ಪಳಿಬಟ್ಟೆ',
'ಪಪ್ಪುರಶುಂಠಿ',
'ಪಯಃಕಣಿಕೆ',
'ಪಯಣಂಗೆಯ್',
'ಪಯಣಂಬರ್',
'ಪಯಣಂಬೋಗು',
'ಪಯಣಗತಿ',
'ಪಯಣನಡೆ',
'ಪಯನೇರಳೆ',
'ಪಯಿಂಛಾಸಿರ',
'ಪಯಿಂಜಾಸಿರ',
'ಪಯಿಸರಿಸು',
'ಪಯ್ಸರವೋಗು',
'ಪರಂಕಲಿಸು',
'ಪರಂಗಿಚೆಕ್ಕೆ',
'ಪರಂಗಿಜಾಲಿ',
'ಪರಂಗಿಮಣೆ',
'ಪರಂಗಿರೋಗ',
'ಪರಂಗಿಹುಣ್ಣು',
'ಪರಂತಪನ',
'ಪರಂಪರಾಕ',
'ಪರಂಪರಿತ',
'ಪರಂಬರಿಕೆ',
'ಪರಂಬರಿಸು',
'ಪರಕರಿಸು',
'ಪರಕರ್ತೃಕ',
'ಪರಕಲಿಸು',
'ಪರಕಳತ್ರ',
'ಪರಕೀಯತೆ',
'ಪರಕೆಗಟ್ಟು',
'ಪರಕೆಗುಡು',
'ಪರಕೆಗೊಡು',
'ಪರಕೆಗೊಳ್',
'ಪರಕೆವಿಡಿ',
'ಪರಕೆವೆಱು',
'ಪರಗಮನ',
'ಪರಜಿಕಲ್ಲು',
'ಪರಟತಲೆ',
'ಪರಟೆದಲೆ',
'ಪರದುಗೆಯ್',
'ಪರದುವೋಗು',
'ಪರದೇವತೆ',
'ಪರದೇಶಸ್ಥ',
'ಪರದೈವತ',
'ಪರನಿಂದಕ',
'ಪರನಿರ್ವೃತಿ',
'ಪರಪಮುದು',
'ಪರಪಾಟಕ',
'ಪರಪಾಷ್ಟಕ',
'ಪರಪಿಂಡಾದ',
'ಪರಪೀಡನ',
'ಪರಪೀಡನೆ',
'ಪರಪುರುಷ',
'ಪರಪ್ರಕಾಶ',
'ಪರಪ್ರತ್ಯಯ',
'ಪರಪ್ರೇಷಿತ',
'ಪರಬೊಮ್ಮಿಣಿ',
'ಪರಭಾವಜ್ಞ',
'ಪರಭೃತಿಕೆ',
'ಪರಮಂಡಲ',
'ಪರಮಂಡಳ',
'ಪರಮಕಾಷ್ಠೆ',
'ಪರಮಕೋಟಿ',
'ಪರಮಗತಿ',
'ಪರಮಪದ',
'ಪರಮಬ್ರಹ್ಮ',
'ಪರಮಹಂಸ',
'ಪರಮಾತುಮ',
'ಪರಮಾಯಿಷಿ',
'ಪರಮಾಯುಷ್ಯ',
'ಪರಮಾರ್ಥತೆ',
'ಪರಮಾವಧಿ',
'ಪರಮಾಶ್ರಯ',
'ಪರಮೇಶ್ವರ',
'ಪರಮೇಶ್ವರಿ',
'ಪರಮೇಸ್ವರ',
'ಪರಮೋತ್ಕೃಷ್ಟ',
'ಪರಮೋದನ',
'ಪರಮೋದಯ',
'ಪರವಂಚನೆ',
'ಪರವಣಿಗೆ',
'ಪರವನಿತೆ',
'ಪರವರಶಿ',
'ಪರವರಸಿ',
'ಪರವರಿಸು',
'ಪರವರ್ತನ',
'ಪರವಶತೆ',
'ಪರವಶತ್ವ',
'ಪರವಸತೆ',
'ಪರವಾನಗಿ',
'ಪರವಾನಿಗಿ',
'ಪರವಾನಿಗೆ',
'ಪರವೇಶ್ಮಸ್ಥ',
'ಪರವ್ಯಾಪಾದ',
'ಪರಶುಧರ',
'ಪರಶುಧಾರ',
'ಪರಶುರಾಮ',
'ಪರಸಗಟ್ಟೆ',
'ಪರಸಮಯ',
'ಪರಸಮಯಿ',
'ಪರಸಿಗಲ್ಲು',
'ಪರಸ್ತ್ರೀಲೋಲ',
'ಪರಸ್ಪರತೆ',
'ಪರಸ್ಮೈಪದ',
'ಪರಹಿತಾರ್ಥ',
'ಪರಳುಗಲ್ಲು',
'ಪರಳುಗಾಯಿ',
'ಪರಳುನೆಲ',
'ಪರಾಂಬರಿಕೆ',
'ಪರಾಂಬರಿಸು',
'ಪರಾಕಕೃಚ್ಛ್ರ',
'ಪರಾಕಾಷ್ಠತೆ',
'ಪರಾಕಿತನ',
'ಪರಾಕ್ರಮಣ',
'ಪರಾಗಕೋಶ',
'ಪರಾಗದಂಡ',
'ಪರಾಗಪಾಂಸು',
'ಪರಾಗಸ್ಪರ್ಶ',
'ಪರಾಙ್ಮುಖತೆ',
'ಪರಾಙ್ಮುಖಿಸು',
'ಪರಾತ್ಪರತೆ',
'ಪರಾಧೀನತೆ',
'ಪರಾಭವಿಸು',
'ಪರಾಮರಿಕೆ',
'ಪರಾಮರಿಸು',
'ಪರಾಮರ್ಶಕ',
'ಪರಾಮರ್ಶಿಸು',
'ಪರಾಮ್ಮರಿಸು',
'ಪರಾಯಣತೆ',
'ಪರಾಯಣತ್ವ',
'ಪರಾರ್ತಿಹರ',
'ಪರಾರ್ಥಪರ',
'ಪರಾರ್ಥಸ್ಪೃಹೆ',
'ಪರಾರ್ಥಹರ',
'ಪರಾವರ್ತಕ',
'ಪರಾವರ್ತನ',
'ಪರಾವರ್ತನೆ',
'ಪರಾವರ್ತಿತ',
'ಪರಾವರ್ತಿಸು',
'ಪರಾವಲಂಬಿ',
'ಪರಕರಣ',
'ಪರಿಕರಣೆ',
'ಪರಿಕರಿತ',
'ಪರಿಕರಿಸು',
'ಪರಿಕಲಿತ',
'ಪರಿಕಲಿಸು',
'ಪರಿಕಲ್ಪನೆ',
'ಪರಿಕಲ್ಪಿತ',
'ಪರಿಕಲ್ಪಿಸು',
'ಪರಿಕಳಿತ',
'ಪರಿಕಳ್ಪನೆ',
'ಪರಿಕಾಂಕ್ಷಿತ',
'ಪರಿಕಾಂಕ್ಷಿತೆ',
'ಪರಿಕಾಲುವೆ',
'ಪರಿಕ್ರಮಣ',
'ಪರಿಕ್ರಮಿಸು',
'ಪರಿಕ್ಷಯಿಸು',
'ಪರಿಖಂಡಿತ',
'ಪರಿಖಚಿತ',
'ಪರಿಗಣನ',
'ಪರಿಗಣನೆ',
'ಪರಿಗಣಿತ',
'ಪರಿಗಣಿತೆ',
'ಪರಿಗಣಿಸು',
'ಪರಿಗಲಿತ',
'ಪರಿಗಳಿತ',
'ಪರಿಗಾಲುವೆ',
'ಪರಿಗೃಹೀತ',
'ಪರಿಗೆಕಾಱ',
'ಪರಿಗ್ರಹಣ',
'ಪರಿಗ್ರಹಿಸು',
'ಪರಿಘಾತನ',
'ಪರಿಘೂರ್ಣಿತ',
'ಪರಿಘೂರ್ಣಿಸು',
'ಪರಿಚಯಸ್ಥ',
'ಪರಿಚಯಸ್ಥೆ',
'ಪರಿಚಯಿಸು',
'ಪರಚರಣ',
'ಪರಿಚರಿಯ',
'ಪರಿಚರಿಯೆ',
'ಪರಿಚರಿಸು',
'ಪರಿಚಲನ',
'ಪರಿಚಲನೆ',
'ಪರಿಚಾರಕ',
'ಪರಿಚಾರಕಿ',
'ಪರಿಚಾರಿಕೆ',
'ಪರಿಚಾರಿಣಿ',
'ಪರಿಚಾರಿಸು',
'ಪರಿಚುಂಬಿಸು',
'ಪರಿಚೋದನ',
'ಪರಿಚೋದಿಸು',
'ಪರಿಚ್ಛಾದನೆ',
'ಪರಿಚ್ಛೇದನ',
'ಪರಿಚ್ಛೇದಿಸು',
'ಪರಿಜಪನ',
'ಪರಿಣಮಿಸು',
'ಪರಿಣಯನ',
'ಪರಿಣಾಮತೆ',
'ಪರಿಣಾಮಿಸು',
'ಪರಿಣಾಶಗೈ',
'ಪರಿಣಿಚಿತ',
'ಪರಿತಪನ',
'ಪರಿತಪಿಸು',
'ಪರಿತರ್ಪಿಸು',
'ಪರಿತಾಪಿಕಿ',
'ಪರಿತಾಪಿತ',
'ಪರಿತೋಷಿತ',
'ಪರಿತೋಷಿತೆ',
'ಪರಿತೋಷಿಸು',
'ಪರಿತ್ಯಜನ',
'ಪರಿತ್ಯಜಿಸು',
'ಪರಿದಲಿತ',
'ಪರಿದಳಿತ',
'ಪರಿದೇವನ',
'ಪರಿಧಾನಕ',
'ಪರಿಧಾನಿಸು',
'ಪರಿಧಾವನ',
'ಪರಿಧಾವನೆ',
'ಪರಿಧಾವಿಸು',
'ಪರಿಧಿಗೊಳ್',
'ಪರಿನಾಳಿಕೆ',
'ಪರಿನಾಳಿಗೆ',
'ಪರಿನಿರ್ಮಲ',
'ಪರಿನಿರ್ಮಾಣ',
'ಪರಿನಿರ್ಮಿತ',
'ಪರಿನಿರ್ಮಿಸು',
'ಪರಿನಿರ್ವಾಣ',
'ಪರಿನಿರ್ವೃತಿ',
'ಪರಿನಿರ್ವೇಗ',
'ಪರಿನಿಷ್ಕ್ರಾಂತಿ',
'ಪರಿನಿಷ್ಠಿತ',
'ಪರಿಪಂಥಿನಿ',
'ಪರಿಪಕ್ವತೆ',
'ಪರಿಪಠನ',
'ಪರಿಪಠಿತ',
'ಪರಿಪಥಿಕೆ',
'ಪರಿಪಾಂಡುರ',
'ಪರಿಪಾಟಲು',
'ಪರಿಪಾತನ',
'ಪರಿಪಾಲಕ',
'ಪರಿಪಾಲನ',
'ಪರಿಪಾಲನೆ',
'ಪರಿಪಾಲಿತ',
'ಪರಿಪಾಲಿಸು',
'ಪರಿಪಾಳನ',
'ಪರಿಪಾಳಿಸು',
'ಪರಿಪೀಡನ',
'ಪರಿಪೂಜಿಸು',
'ಪರಿಪೂರಿತ',
'ಪರಿಪೂರಿಸು',
'ಪರಿಪೂರ್ಣತೆ',
'ಪರಿಪೂರ್ಣತ್ವ',
'ಪರಿಪೇಲವ',
'ಪರಿಪೋಷಕ',
'ಪರಿಪೋಷಣ',
'ಪರಿಪೋಷಣೆ',
'ಪರಿಪೋಷಿತ',
'ಪರಿಪೋಷಿಸು',
'ಪರಿಪ್ರಮಾಣ',
'ಪರಿಪ್ಲಾವಿತ',
'ಪರಿಬಂಧನ',
'ಪರಿಬಂಧಿಸು',
'ಪರಿಬವಣೆ',
'ಪರಿಭಂಗಿಸು',
'ಪರಿಭವಗೈ',
'ಪರಿಭವಿಸು',
'ಪರಿಭಾಗಿಸು',
'ಪರಿಭಾವನೆ',
'ಪರಿಭಾವಿತ',
'ಪರಿಭಾವಿಸು',
'ಪರಿಭಾಷಣ',
'ಪರಿಭಾಷಿತ',
'ಪರಿಭ್ರಮಣ',
'ಪರಿಭ್ರಮಿಸು',
'ಪರಿಮಂಡನ',
'ಪರಿಮಂಡಲ',
'ಪರಿಮಂಡಳ',
'ಪರಿಮರ್ದನ',
'ಪರಿಮರ್ದಿಸು',
'ಪರಿಮಲಿತ',
'ಪರಿಮಸಕ',
'ಪರಿಮಳಿತ',
'ಪರಿಮಳಿಸು',
'ಪರಿಮಾಣತೆ',
'ಪರಿಮಾಣಿಸು',
'ಪರಿಮಾರ್ಜನ',
'ಪರಿಮಾರ್ಜನೆ',
'ಪರಿಮಿತತೆ',
'ಪರಿಮಿಲಿತ',
'ಪರಿಮಿಶ್ರಿತ',
'ಪರಿಮಿಳಿತ',
'ಪರಿಮ್ಲಾನತೆ',
'ಪರಿಯಂತರ',
'ಪರಿಯಂತರಂ',
'ಪರಿಯಟಣ',
'ಪರಿಯಟನ',
'ಪರಿಯುಡುಗು',
'ಪರಿಯೋಗಿಸು',
'ಪರಿರಂಜಿತ',
'ಪರಿರಂಜಿಸು',
'ಪರಿರಂಭಣ',
'ಪರಿರಂಭಿಸು',
'ಪರಿರಕ್ಷಕ',
'ಪರಿರಕ್ಷಣ',
'ಪರಿರಕ್ಷಿತ',
'ಪರಿರಕ್ಷಿಸು',
'ಪರಿರಚಿಸು',
'ಪರಿರಮಿಸು',
'ಪರಿರಾಜಿಸು',
'ಪರಿಲಕ್ಷಿತ',
'ಪರಿಲುಲಿತ',
'ಪರಿಲುಳಿತ',
'ಪರಿವತ್ಸರ',
'ಪರಿವರಿಸು',
'ಪರಿವರ್ಜನ',
'ಪರಿವರ್ಜಿತ',
'ಪರಿವರ್ಜಿತೆ',
'ಪರಿವರ್ಜಿಸು',
'ಪರಿವರ್ತನ',
'ಪರಿವರ್ತನೆ',
'ಪರಿವರ್ತಿತ',
'ಪರಿವರ್ತಿಸು',
'ಪರಿವರ್ಧಕ',
'ಪರಿವರ್ಧಿತ',
'ಪರಿವರ್ಧಿನಿ',
'ಪರಿವರ್ಧಿಸು',
'ಪರಿವಳಯ',
'ಪರಿವಾದಕ',
'ಪರಿವಾದಕಿ',
'ಪರಿವಾದಿನಿ',
'ಪರಿವಾಪಿತ',
'ಪರಿವಾರಿಕೆ',
'ಪರಿವಾರಿತ',
'ಪರಿವಾರಿಸು',
'ಪರಿವಾಹಿತ',
'ಪರಿವಿಸರ',
'ಪರಿವಿಸ್ತರ',
'ಪರಿವೀಕ್ಷಕ',
'ಪರಿವೀಕ್ಷಣೆ',
'ಪರಿವೀಕ್ಷಿಸು',
'ಪರಿವೀಜನ',
'ಪರಿವೃತ್ತತೆ',
'ಪರಿವೆರಸು',
'ಪರಿವೇದಕ',
'ಪರಿವೇದನ',
'ಪರಿವೇದನೆ',
'ಪರಿವೇಷಣ',
'ಪರಿವೇಷ್ಟನ',
'ಪರಿವೇಷ್ಟಿತ',
'ಪರಿವೇಷ್ಟಿಸು',
'ಪರಿವೊನಲ್',
'ಪರಿವೊನಲು',
'ಪರಿವ್ರಜನ',
'ಪರಿವ್ರಜಿಸು',
'ಪರಿವ್ರಾಜಕ',
'ಪರಿವ್ರಾಜಿಕೆ',
'ಪರಿಶಂಕಿಸು',
'ಪರಿಶಮನ',
'ಪರಿಶಮಿಸು',
'ಪರಿಶಾಸನ',
'ಪರಿಶೀಲಕ',
'ಪರಿಶೀಲನ',
'ಪರಿಶೀಲನೆ',
'ಪರಿಶೀಲಿಸು',
'ಪರಿಶುದ್ಧತೆ',
'ಪರಿಶೇಚನ',
'ಪರಿಶೇಚನೆ',
'ಪರಿಶೋಧಕ',
'ಪರಿಶೋಧನ',
'ಪರಿಶೋಧನಿ',
'ಪರಿಶೋಧನೆ',
'ಪರಿಶೋಧಿತ',
'ಪರಿಶೋಧಿಸು',
'ಪರಿಶೋಭನ',
'ಪರಿಶೋಭಿತ',
'ಪರಿಶೋಭಿಸು',
'ಪರಿಷಂಚನೆ',
'ಪರಿಷಜ್ಜನ',
'ಪರಿಷಿಂಚನೆ',
'ಪರಿಷೇಚನ',
'ಪರಿಷೇಚನೆ',
'ಪರಿಷ್ಕರಣ',
'ಪರಿಷ್ಕರಣೆ',
'ಪರಿಷ್ಕರಿಸು',
'ಪರಿಷ್ಕಾರಕ',
'ಪರಿಷ್ವಂಗಿಸು',
'ಪರಿಸಂಭ್ರಮ',
'ಪರಿಸಂಸ್ಕಾರ',
'ಪರಿಸಮಾಪ್ತ',
'ಪರಿಸಮಾಪ್ತಿ',
'ಪರಿಸರಣ',
'ಪರಿಸರ್ಪಣ',
'ಪರಿಸೇಕಿಸು',
'ಪರಿಸ್ಖಲನ',
'ಪರಿಸ್ಖಲಿತ',
'ಪರಿಸ್ಖಳಿತ',
'ಪರಿಸ್ತರಣ',
'ಪರಿಸ್ಪಂದನ',
'ಪರಿಸ್ಫುರಣ',
'ಪರಿಸ್ಫುರಿತ',
'ಪರಿಸ್ಫುಲಿಂಗ',
'ಪರಿಸ್ರವಣ',
'ಪರಿಸ್ರವಣಿ',
'ಪರಿಹರಣ',
'ಪರಿಹರಿಸು',
'ಪರಿಹರ್ತವ್ಯ',
'ಪರಿಹಸಿತ',
'ಪರಿಹಾರಕ',
'ಪರಿಹಾಸಕ',
'ಪರಿಹಾಸಗೈ',
'ಪರಿಕ್ಷಾಕೇಂದ್ರ',
'ಪರೀಕ್ಷಿಸುಹ',
'ಪರೀಕ್ಷೆಗಾರ',
'ಪರೀಕ್ಷೆವಂತ',
'ಪರೀರಂಭಣ',
'ಪರುಟವಣೆ',
'ಪರುಟವಿಸು',
'ಪರುಠವಣೆ',
'ಪರುಠವಿಸು',
'ಪರುಷಕಣಿ',
'ಪರುಷರಸ',
'ಪರುಷವಾಕ್ಯ',
'ಪರುಷವೃತ್ತಿ',
'ಪರುಷವೇದಿ',
'ಪರುಷಾಕ್ಷರ',
'ಪರುಷೇಕ್ಷಣ',
'ಪರುಸಕಲ್ಲು',
'ಪರುಸಗಣಿ',
'ಪರುಸಗಲ್',
'ಪರುಸಗಲ್ಲು',
'ಪರುಸವೇದಿ',
'ಪರೆಗಳಚು',
'ಪರೆಪೊತ್ತಱ್',
'ಪರೇಂಗಿತಜ್ಞ',
'ಪರೇಂಗಿತಜ್ಞೆ',
'ಪರೇತಕೃತ್ಯ',
'ಪರೇತಪತಿ',
'ಪರೇತವನ',
'ಪರೇರಬೇರು',
'ಪರೋಕ್ಷಕ್ರಿಯೆ',
'ಪರೋಕ್ಷಜ್ಞಾನ',
'ಪರೋಕ್ಷಸಾಕ್ಷ್ಯ',
'ಪರೋಪಕಾರ',
'ಪರೋಪಕಾರಿ',
'ಪರೋಪಜೀವಿ',
'ಪರ್ಜನ್ಯಜಪ',
'ಪರ್ಣಕುಟೀರ',
'ಪರ್ಣಪುಟಿಕೆ',
'ಪರ್ಣಭವನ',
'ಪರ್ಣಭೋಜನ',
'ಪರ್ಣಮಂದಿರ',
'ಪರ್ಣವಿಕ್ರಯಿ',
'ಪರ್ನಕರ್ಪೂರ',
'ಪರ್ಯಂಕಾಸನ',
'ಪರ್ಯನುಯೋಗ',
'ಪರ್ಯವಸಾನ',
'ಪರ್ಯವಸಾಯಿ',
'ಪರ್ಯವಸಿತ',
'ಪರ್ಯಷ್ಟಿಗೆಯ್',
'ಪರ್ಯಾಪ್ತಜೀವ',
'ಪರ್ಯಾಪ್ತಯೋಗ',
'ಪರ್ಯಾಯಂದಪ್ಪು',
'ಪರ್ಯಾಯದ್ವೀಪ',
'ಪರ್ಯಾಯನಾಮ',
'ಪರ್ಯಾಯಪದ',
'ಪರ್ಯಾಯಪಾಠ',
'ಪರ್ಯಾಯವಚ',
'ಪರ್ಯಾಯಶಬ್ದ',
'ಪರ್ಯಾಲೋಚನ',
'ಪರ್ಯಾಲೋಚನೆ',
'ಪರ್ಯಾಲೋಚಿತ',
'ಪರ್ಯಾಲೋಚಿಸು',
'ಪರ್ಯಾಳೋಚನ',
'ಪರ್ಯಾಳೋಚನೆ',
'ಪರ್ಯಾಳೋಚಿತ',
'ಪರ್ಯಾಳೋಚಿಸು',
'ಪರ್ಯುದಂಚನ',
'ಪರ್ವಣಿಕಾಲ',
'ಪರ್ವತಕಾಕ',
'ಪರ್ವತನಾಥ',
'ಪರ್ವತಬಾಣ',
'ಪರ್ವತಬಾಳೆ',
'ಪರ್ವತರಾಜ',
'ಪರ್ವತವಳಿ',
'ಪರ್ವತಶ್ರೇಣಿ',
'ಪರ್ವತಾರೋಹಿ',
'ಪರ್ವತಾವಳಿ',
'ಪರ್ವುಗೆದೋಱು',
'ಪರ್ವುಗೆವೆಱು',
'ಪಲಗೆಗಟ್ಟು',
'ಪಲಗೆಜೂಜು',
'ಪಲವುಕಾಲಂ',
'ಪಲಾಯನಗೈ',
'ಪಲಾಶವೃತ್ತಿ',
'ಪಲಾಶಶಾಲೆ',
'ಪಲಿತಕಾಯ',
'ಪಲಿತಾಂಕುರ',
'ಪಲಿತೌಷಧ',
'ಪಲ್ಯಂಕಾಸನ',
'ಪಲ್ಲಟಂಬಾಯ್',
'ಪಲ್ಲಟಂಬೀೞ್',
'ಪಲ್ಲಟಂಬೋಗು',
'ಪಲ್ಲಟವಾಗು',
'ಪಲ್ಲಣಂಗಟ್ಟು',
'ಪಲ್ಲವಗ್ರಾಹಿ',
'ಪಲ್ಲವಹಸ್ತ',
'ಪಲ್ಲಿಲಿಬಾಯಿ',
'ಪಲ್ಲಿಲಿವಾಯ್',
'ಪಲ್ಲಿಲಿವಾಯ',
'ಪಲ್ಲಿಶಕುನ',
'ಪವಣಂಬಟ್ಟೆ',
'ಪವನಗತಿ',
'ಪವನತ್ರಯ',
'ಪವನದಿಶೆ',
'ಪವನಪಥ',
'ಪವನಯೋಗ',
'ಪವನಯೋಗಿ',
'ಪವನವಾರ್ತೆ',
'ಪವನವೇಗ',
'ಪವನಸಖ',
'ಪವನಹತಿ',
'ಪವನಾಶನ',
'ಪವನಾಹಾರ',
'ಪವಳದೊಂಡೆ',
'ಪವಿತ್ರಾಂಗುಲಿ',
'ಪವತ್ರೀಭೂತ',
'ಪವಿತ್ರೋದಕ',
'ಪವಿಪಂಜರ',
'ಪವಿಭೃನ್ಮಣಿ',
'ಪಶುಗೆಡಹಿ',
'ಪಶುನೈವೇದ್ಯ',
'ಪಶುಪಾಲಕ',
'ಪಶುಪಾಲನ',
'ಪಶುಪಾಲನೆ',
'ಪಶುಪ್ರದಾನ',
'ಪಶುವರ್ತನೆ',
'ಪಶ್ಚಾತ್ಕರಿಸು',
'ಪಶ್ಚಾದ್ವಿವೇಕ',
'ಪಶ್ಚಿಮಘಟ್ಟ',
'ಪಶ್ಚಿಮದೇಶ',
'ಪಶ್ಚಿಮನಾಡಿ',
'ಪಶ್ಚಿಮಬುದ್ಧಿ',
'ಪಶ್ಚಿಮಾಚಲ',
'ಪಶ್ಯತೋಹರ',
'ಪಸಗರಿಕೆ',
'ಪಸಗೆಡಿಸು',
'ಪಸಗೊಳಿಸು',
'ಪಸದನಂಗೈ',
'ಪಸದನಗೈ',
'ಪಸರಂಗೆಯ್',
'ಪಸರಂಗೊಡು',
'ಪಸರಂಗೊಳ್',
'ಪಸರಂಬಡೆ',
'ಪಸರಂಬೆಱು',
'ಪಸಲುವೋಗು',
'ಪಸಾಯಂಗೊಡು',
'ಪಸಾಯಂಬಡೆ',
'ಪಸಾಯದಾನ',
'ಪಸಾಯಿತಿಕೆ',
'ಪಸಿಯೊರಟು',
'ಪಸುಗೆಗೆಡು',
'ಪಸುಗೆಗೊಳ್',
'ಪಸುಗೆದಪ್ಪು',
'ಪಸುಗೆನೀಡು',
'ಪಸುಗೆವಡೆ',
'ಪಸುಗೆವೋಗು',
'ಪಸುಬೆವಳ',
'ಪಸುರಾದಾಯ',
'ಪಸುರುಗೂಳು',
'ಪಸುರುವಣಿ',
'ಪಸುರ್ಗವಿಲ',
'ಪಸುರ್ಪುವಡೆ',
'ಪಸುರ್ಪುವೆಱು',
'ಪಸುರ್ವರಲ್',
'ಪಸುಳೆಗಾಳಿ',
'ಪಸುಳೆತನ',
'ಪಸೆಗೊಳಿಸು',
'ಪಸೆಜಗತಿ',
'ಪಸೆವರಿಸು',
'ಪಹಣಿಕಲ್ಲು',
'ಪಹಣಿಲೆಕ್ಕ',
'ಪಹರೆಕೊಡು',
'ಪಹರೆಗಾರ',
'ಪಹರೆಯಿರು',
'ಪಹರೇಕರಿ',
'ಪಹಿಲವಾನ',
'ಪಳಪಳನೆ',
'ಪಳಪಳಿಸು',
'ಪಳಮಂಜರಿ',
'ಪಳಿಗಪ್ಪುರ',
'ಪಳಿಗರ್ಪುರ',
'ಪಳಿತಾಂಕುರ',
'ಪಳೆಗನ್ನಡ',
'ಪಳೆಗರ್ಪಡ',
'ಪಳೆಯುಳಿಕೆ',
'ಪಱಪಱಿಸು',
'ಪಱಿಪಡಿಸು',
'ಪಱಿಯಾಳ್ತನ',
'ಪಱಿವಡಿಸು',
'ಪಱಿವಾಯ್ತನ',
'ಪಱೆವಡಿಗ',
'ಪೞಗನ್ನಡ',
'ಪೞಪೞನೆ',
'ಪೞಿನುಡಿಹ',
'ಪೞುವಗಿಚ್ಚು',
'ಪೞುವೞಲ್',
'ಪಾಉಗ್ಗಮಣ',
'ಪಾಂಚಭೌತಿಕ',
'ಪಾಂಚರಾತ್ರಿಕ',
'ಪಾಂಚವಾರ್ಷಿಕ',
'ಪಾಂಜರಪೋಳ',
'ಪಾಂಡುಕಂಬಲ',
'ಪಾಂಡುಕಂಬಲಿ',
'ಪಾಂಡುಕಂಬಳ',
'ಪಾಂಡುಕಂಬಳಿ',
'ಪಾಂಡುಕವನ',
'ಪಾಂಡುಕಾಮಾಲೆ',
'ಪಾಂಡುರಕುಷ್ಠ',
'ಪಾಕಗೊಳಿಸು',
'ಪಾಕುಳಿಗಿತಿ',
'ಪಾಖಂಡಮತ',
'ಪಾಗರಗೋಡೆ',
'ಪಾಚಕರಸ',
'ಪಾಚಕಶಕ್ತಿ',
'ಪಾಟಲೀಪುತ್ರ',
'ಪಾಟಿಗೆಡಿಸು',
'ಪಾಟಿಸವಾಲು',
'ಪಾಟೀಸವಾಲು',
'ಪಾಟುಗೆಡಿಸು',
'ಪಾಟುಬಡಿಸು',
'ಪಾಠಕರಣ',
'ಪಾಠಕಲಿಸು',
'ಪಾಠನಿರ್ಣಯ',
'ಪಾಠಮಿಶ್ರಣ',
'ಪಾಠಯೋಜನೆ',
'ಪಾಠಶೋಧಕ',
'ಪಾಠಶೋಧನೆ',
'ಪಾಡುಂಗಾಱಿಕೆ',
'ಪಾಡುಗವಿತೆ',
'ಪಾಡುಗಾಱಿಕೆ',
'ಪಾಡುಗೆಡಿಸು',
'ಪಾಡುಪಡಿಸು',
'ಪಾಡುವಡಿಸು',
'ಪಾಣಿಗ್ರಹಣ',
'ಪಾಣಿಚಳನ',
'ಪಾಣಿಪೀಡನ',
'ಪಾಣಿಬಟ್ಟಲು',
'ಪಾಣಿಯದರ',
'ಪಾಣಿಯದಾರ',
'ಪಾಣಿಯಬಾರ',
'ಪಾಣಿಯವಣ್ಣ',
'ಪಾಣಿಯವಾಸ',
'ಪಾಣಿಲಾಘವ',
'ಪಾಣಿವಟ್ಟಲು',
'ಪಾಣೀಯದರ',
'ಪಾಣೀಯದಾರ',
'ಪಾಣೀಯಬಾರ',
'ಪಾತರಗಿತ್ತಿ',
'ಪಾತಾಲಗಂಗೆ',
'ಪಾತಾಲಗಡ',
'ಪಾತಾಲಲೋಕ',
'ಪಾತಾಳಗಂಗೆ',
'ಪಾತಾಳಗಂಧಿ',
'ಪಾತಾಳಗಡ',
'ಪಾತಾಳಗಡೆ',
'ಪಾತಾಳಮರ',
'ಪಾತಾಳಯಂತ್ರ',
'ಪಾತಾಳಯಂತ್ರಿ',
'ಪಾತಾಳಲೋಕ',
'ಪಾತಾಳಸುಳಿ',
'ಪಾತುರೆಕೋಲು',
'ಪಾತ್ರಚಿತ್ರಣ',
'ಪಾತ್ರತ್ರಿತಯ',
'ಪಾತ್ರನಿರ್ಮಾಣ',
'ಪಾತ್ರನಿರ್ಮಿತಿ',
'ಪಾತ್ರಪೋಷಣೆ',
'ಪಾತ್ರವಹಿಸು',
'ಪಾತ್ರವಿಕೀರ್ಣ',
'ಪಾತ್ರಾಭಿಗರ',
'ಪಾತ್ರಾಭಿಗಾರ',
'ಪಾತ್ರೀಕರಿಸು',
'ಪಾಥೇಯಧನ',
'ಪಾದಕಟಕ',
'ಪಾದಕಡಗ',
'ಪಾದಕಾಣಿಕೆ',
'ಪಾದಗಾಣಿಕೆ',
'ಪಾದತಿಱುಗೆ',
'ಪಾದಪಲ್ಲಟ',
'ಪಾದಪಾಶನ',
'ಪಾದಪೂರಕ',
'ಪಾದಪೂರಣ',
'ಪಾದಪೆಂಡೆಯ',
'ಪಾದಪ್ರಣತ',
'ಪಾದಪ್ರಣಾಮ',
'ಪಾದರಕ್ಷಣ',
'ಪಾದರಗಿತ್ತಿ',
'ಪಾದವಟ್ಟಿಗೆ',
'ಪಾದವಲ್ಮೀಕ',
'ಪಾದವಿನ್ಯಾಸ',
'ಪಾದವಿಷ್ಟರ',
'ಪಾದಶೃಂಖಲೆ',
'ಪಾದಸಂಕಲೆ',
'ಪಾದಾಭಿಘಾತ',
'ಪಾದಾಭ್ಯಂಗನ',
'ಪಾದುಕಾಕಾರ',
'ಪಾದುಕಾಸಿದ್ಧಿ',
'ಪಾದೆಲವಣ',
'ಪಾನ್‍ಸುಪಾರಿ',
'ಪಾನಕಪೂಜೆ',
'ಪಾನಕ್ರೀಡನ',
'ಪಾನಗೋಷ್ಠಿಕೆ',
'ಪಾನನಿರೋಧ',
'ಪಾನಭಾಜನ',
'ಪಾನವ್ಯಸನ',
'ಪಾನಸುಪಾರಿ',
'ಪಾನಿವಟ್ಟಲು',
'ಪಾನೀಯಖೇಯ',
'ಪಾನೀಯಶಾಲೆ',
'ಪಾನುಸುಪಾರಿ',
'ಪಾಪಡಖಾರ',
'ಪಾಪನಾಶನ',
'ಪಾಪಭೀರುತ್ವ',
'ಪಾಪರ್‍ಚೀಟಿ',
'ಪಾಪರಹಿತ',
'ಪಾಪರುಚೀಟಿ',
'ಪಾಪವಿಘಾತಿ',
'ಪಾಪವಿನಾಶ',
'ಪಾಪಾಲೋಕನ',
'ಪಾಪಾಸಕಳ್ಳಿ',
'ಪಾಪಾಸುಕಳ್ಳಿ',
'ಪಾಯನ್ನೇರಳೆ',
'ಪಾಯಭರಣಿ',
'ಪಾಯವಧಾರು',
'ಪಾಯಸಂಕಲೆ',
'ಪಾರಂಗಮತೆ',
'ಪಾರಂತಗುಳ್',
'ಪಾರಕಾವಣೆ',
'ಪಾರಖಾವಣಿ',
'ಪಾರಖಾವಣೆ',
'ಪಾರಗಾಣಿಸು',
'ಪಾರಣಾವಿಧಿ',
'ಪಾರಣೆಗೆಯ್',
'ಪಾರತಂತ್ರತೆ',
'ಪಾರದರಿಗ',
'ಪಾರದರಿಗೆ',
'ಪಾರದರ್ಶಕ',
'ಪಾರದಾರಿಕ',
'ಪಾರದೇಶಿಕ',
'ಪಾರಮಾರ್ಥಿಕ',
'ಪಾರಮೈಶ್ವರ್ಯ',
'ಪಾರಲೌಕಿಕ',
'ಪಾರವೆಯ್ದಿಸು',
'ಪಾರಾವಾರಗ',
'ಪಾರಿಜಾತಕ',
'ಪಾರಿಣಾಮಿಕ',
'ಪಾರಿತೋಷಕ',
'ಪಾರಿತೋಷಿಕ',
'ಪಾರಿಪಂಥಿಕ',
'ಪಾರಿಪಾರ್ಶ್ವಕ',
'ಪಾರಿಪಾರ್ಶ್ವಿಕ',
'ಪಾರಿಬರುಹ',
'ಪಾರಿಬ್ರಾಜಕ',
'ಪಾರಿಭದ್ರಕ',
'ಪಾರಿಭಾಷಿಕ',
'ಪಾರಿಭೋಗಿಕ',
'ಪಾರಿಯಪಟ್ಟೆ',
'ಪಾರಿಯವಟ್ಟೆ',
'ಪಾರಿಯಾತ್ರಕ',
'ಪಾರಿಯಾತ್ರಿಕ',
'ಪಾರಿಯಾನಿಕ',
'ಪಾರಿರಕ್ಷಕ',
'ಪಾರಿವೂಳಿಗ',
'ಪಾರಿವ್ರಾಜಕ',
'ಪಾರಿಶುದ್ಧತೆ',
'ಪಾರಿಶೇಷಕ',
'ಪಾರಿಹಾರಿಕ',
'ಪಾರುಖಾಣಯ',
'ಪಾರುಖಾಣೆಯ',
'ಪಾರುಗಟ್ಟಿಗೆ',
'ಪಾರುಗಾಣಿಸು',
'ಪಾರುವೀಳೆಯ',
'ಪಾರ್ಥಿವಗುಣ',
'ಪಾರ್ಪತ್ತೆಗಾರ',
'ಪಾರ್ಪತ್ಯಗಾರ',
'ಪಾರ್ಶ್ವಭಾಗಿನಿ',
'ಪಾರ್ಶ್ವೋತ್ತಾಸನ',
'ಪಾಷ್ರ್ಣಿಗಮನ',
'ಪಾಷ್ರ್ಣಿಗ್ರಾಹಕ',
'ಪಾಲಿತಪುತ್ರ',
'ಪಾಲಿತಪುತ್ರಿ',
'ಪಾಲುಗಡಲು',
'ಪಾಲುಗಾರತಿ',
'ಪಾಲುಗಾರಿಕೆ',
'ಪಾಲುದಾರಿಣಿ',
'ಪಾಲುಮಾರಿಕೆ',
'ಪಾಲುಮುಖಂಡ',
'ಪಾಲುವಂತಿಗೆ',
'ಪಾಲೆಗೊರವಿ',
'ಪಾವಕದಿಕ್ಕು',
'ಪಾವಟಣಿಗೆ',
'ಪಾವಡಿಗಿತಿ',
'ಪಾವತಿದಾರ',
'ಪಾವಸೆಗೊಳ್',
'ಪಾವುಕೊರಡು',
'ಪಾವುಗೊಱಡು',
'ಪಾವುವೆಡಗೆ',
'ಪಾಶಕಶಾರಿ',
'ಪಾಶುಪತಾಸ್ತ್ರ',
'ಪಾಷಂಡಮತ',
'ಪಾಷಾಣಬೇರು',
'ಪಾಷಾಣಭೇದಿ',
'ಪಾಷಾಣಸಂಧಿ',
'ಪಾಸಱೆಗಲ್ಲು',
'ಪಾಸಿಕೆಗೆಯ್ಮೆ',
'ಪಾಸುಂದೊವಲ್',
'ಪಾಳತಿಗಾರ',
'ಪಾಳಯಗಾರ',
'ಪಾಳಯಪಟ್ಟು',
'ಪಾಳೆಗಾರಿಕೆ',
'ಪಾಳೆಗೊರವಿ',
'ಪಾಳೆಯಂಗೆಯ್',
'ಪಾಳೆಯಗಾರ',
'ಪಾಳೆಯಗಾರ್ತಿ',
'ಪಾಳೆಯದೆಗೆ',
'ಪಾಳೆಯಪಟ್ಟು',
'ಪಾಳೆಯಮಿಳಿ',
'ಪಾಳೆಯವಿಕ್ಕು',
'ಪಾಳ್ಮನೆಹಾಳ',
'ಪಾಱುವೆಂಡತಿ',
'ಪಾೞ್ಮನೆಪಾೞ',
'ಪಾೞ್ವಕ್ಕೆವಡು',
'ಪಿಂಗದಾಲ್ಚಿನ್ನಿ',
'ಪಿಂಗಳನಾಡಿ',
'ಪಿಂಚಣಿದಾರ',
'ಪಿಂಛಾತಪತ್ರ',
'ಪಿಂಜರಾಪೋಲು',
'ಪಿಂಡಖರ್ಜೂರ',
'ಪಿಂಡಪ್ರದಾನ',
'ಪಿಂಡಮರ್ದನ',
'ಪಿಂಡೀತಗರ',
'ಪಿಂಡುಗಂಕಣ',
'ಪಿಂಡುಗಜ್ಜರ',
'ಪಿಂತಡಿಯಿಡು',
'ಪಿಂದಡಿಯಿಡು',
'ಪಿಕ್‍ಪಾಕೆಟ್ಟು',
'ಪಿಚುತೂಳಕ',
'ಪಿಚ್ಚವುಣಿಗ',
'ಪಿಟಕಾರ್ಬುದ',
'ಪಿಟ್ಟುಪಿಡುಗು',
'ಪಿಡಿಕೆವಱು',
'ಪಿಡಿತರಿಸು',
'ಪಿಡಿಯಾಳ್ಗೈಸು',
'ಪಿತಸಾಲಕ',
'ಪಿತೂರಿಗಾರ',
'ಪಿತೃಕಾನನ',
'ಪಿತೃತರ್ಪಣ',
'ಪಿತೃಭಕ್ತತೆ',
'ಪಿತ್ತಗ್ರಹಣಿ',
'ಪಿತ್ತಪಾಪಟ',
'ಪಿತ್ತಪಾಪಡಾ',
'ಪಿತ್ತಪ್ರಕೃತಿ',
'ಪಿತ್ತಪ್ರಕೋಪ',
'ಪಿತ್ತವಿಕಾರ',
'ಪಿತ್ತವೇರಿಸು',
'ಪಿತ್ತಶಮನ',
'ಪಿತ್ತಾತಿಸಾರ',
'ಪಿತ್ತಾವರಣ',
'ಪಿನಾಕಪಾಣಿ',
'ಪಿನಾಕಿವೀಣೆ',
'ಪಿಪೀಲಿಕಾಸ್ತ್ರ',
'ಪಿರಿಕಿತನ',
'ಪಿರಿತಿನಿಸು',
'ಪಿರಿಯಕ್ಕರ',
'ಪಿರಿಯರಸಿ',
'ಪಿರಿಯೀಚಲ್',
'ಪಿಲಿಕಂದೋಡಿ',
'ಪಿಲಿಚಾಮುಂಡಿ',
'ಪಿಲ್ಲಿಪೆಸರ',
'ಪಿಲ್ಲಿಯುಂಗುರ',
'ಪಿಲ್ಲಿಹೆಸರು',
'ಪಿಶಾಚಗ್ರಹ',
'ಪಿಶಾಚಪ್ರಾಪ್ತ',
'ಪಿಶಾಚಬಾಧೆ',
'ಪಿಶಾಚಲಿಪಿ',
'ಪಿಶಾಚಶೈಲ',
'ಪಿಶಾಚಸತ್ವ',
'ಪಿಶಿತಾಶನ',
'ಪಿಷ್ಟಪಚನ',
'ಪಿಷ್ಟಪದಾರ್ಥ',
'ಪಿಷ್ಟಪೇಷಣ',
'ಪಿಷ್ಟಾತಕೇಳಿ',
'ಪಿಷ್ಟಾತಚೂರ್ಣ',
'ಪಿಸರುಗಣ್ಣು',
'ಪಿಸುಣತನ',
'ಪಿಸುಣಾಡುಹ',
'ಪಿಳಪಿಳನೆ',
'ಪಿಳ್ಳಾರಿಗೀತ',
'ಪಿಳ್ಳಾರಿಗೀತೆ',
'ಪೀಚುಗೊಳವಿ',
'ಪೀಠಮರ್ದಕ',
'ಪೀಠಮರ್ದನ',
'ಪೀಠವರ್ಧನ',
'ಪೀಠಾಧಿಪತಿ',
'ಪೀಠಿಕಾಸಂಧಿ',
'ಪೀತವಸನ',
'ಪೀತಸಾಲಕ',
'ಪೀನಸಿಹುಲ್ಲು',
'ಪೀಯೂಷಪಿಂಡ',
'ಪೀಯೂಷಹಸ್ತ',
'ಪುಂಖಾನುಪುಂಖ',
'ಪುಂಖಾನುಪುಂಖಂ',
'ಪುಂಜೀಕರಣ',
'ಪುಂಜೀಕರಿಸು',
'ಪುಂಡಗಂದಾಯ',
'ಪುಂಡುಗಂದಾಯ',
'ಪುಂಡುಪೋಕರಿ',
'ಪುಂಡ್ರೇಕ್ಷುದಂಡ',
'ಪುಕಪುಕನೆ',
'ಪುಕ್ಕಲತನ',
'ಪುಕ್ಕಲುತನ',
'ಪುಗಿಲುವೆಱು',
'ಪುಚ್ಚವಣಿಸು',
'ಪುಚ್ಚುಂಗೊಳಿಸು',
'ಪುಚ್ಚುಗೊಳಿಸು',
'ಪುಟಗೊಳಿಸು',
'ಪುಟಪಾಕಂಗೈ',
'ಪುಟಪುಟನೆ',
'ಪುಟಪುಟಿಸು',
'ಪುಟಭೇದನ',
'ಪುಟವೇರಿಸು',
'ಪುಟಸಂಸ್ಕಾರ',
'ಪುಟಸನ್ನೆಗೈ',
'ಪುಟುಪುಟಿಸು',
'ಪುಟ್ಟಂಪುರಳೆ',
'ಪುಟ್ಟುಂಗುರುಡ',
'ಪುಟ್ಟುಂಗುರುಡಿ',
'ಪುಟ್ಟುಗುರುಡ',
'ಪುಟ್ಟುಗುರುಡಿ',
'ಪುಡಪುಡನೆ',
'ಪುಡಿಗಿರಾಕಿ',
'ಪುಡಿಗುಟ್ಟಿಸು',
'ಪುಡಿಗೂಡಿಸು',
'ಪುಡಿಚಂಡಿಕೆ',
'ಪುಡಿಸಕ್ಕರೆ',
'ಪುಡುಂಕುನೀರ್',
'ಪುಡುಕುನೀರ್',
'ಪುಡುತಕೇಶ',
'ಪುಡುಪುಡನೆ',
'ಪುಢಾರಿತನ',
'ಪುಣಪುಣನೆ',
'ಪುಣುಗುಕಾಪು',
'ಪುಣ್ಣಮಿವೆಱೆ',
'ಪುಣ್ಣಮೆವೆಱೆ',
'ಪುಣ್ಣಿಮೆವೆಱೆ',
'ಪುಣ್ಯಚರಿತ',
'ಪುಣ್ಯಚರಿತೆ',
'ಪುಣ್ಯಪಾಠಕ',
'ಪುಣ್ಯಪ್ರವರ',
'ಪುಣ್ಯಾತಗಿತ್ತಿ',
'ಪುತಪುತನೆ',
'ಪುತ್ತಲಿಗೊಂಬೆ',
'ಪುತ್ತಳಿಗೊಂಬೆ',
'ಪುತ್ತಳಿಬಣ್ಣ',
'ಪುತ್ತಳಿಬೊಂಬೆ',
'ಪುತ್ರಕಾಮೇಷ್ಟಿ',
'ಪುತ್ರಘಾತಿನಿ',
'ಪುತ್ರದೋಹದ',
'ಪುತ್ರದೋಹಳ',
'ಪುತ್ರದೌಹೃದ',
'ಪುತ್ರಮಂದಿರ',
'ಪುತ್ರಸಂತತಿ',
'ಪುತ್ರಸ್ವೀಕಾರ',
'ಪುದುಂಗುಳಿಸು',
'ಪುದುಂಗೊಳಿಸು',
'ಪುದುಗೊಳಿಸು',
'ಪುದುವಯಕೆ',
'ಪುದುವಾಳ್ತನ',
'ಪುನರಾವೃತ್ತಿ',
'ಪುನರುಚ್ಚಾರ',
'ಪುನರುತ್ಥಾನ',
'ಪುನರುತ್ಪತ್ತಿ',
'ಪುನರ್ಗಮನ',
'ಪುನರ್ಘಟನೆ',
'ಪುನರ್ಭವಿಸು',
'ಪುನರ್ಮಿಲನ',
'ಪುನರ್ರಚನೆ',
'ಪುನರ್ರಚಿಸು',
'ಪುನರ್ವಸತಿ',
'ಪುನರ್ವಿಮರ್ಶೆ',
'ಪುನರ್ವಿವಾಹ',
'ಪುನವ್ರ್ಯವಸ್ಥೆ',
'ಪುನಶ್ಚರಣೆ',
'ಪುನಶ್ಚೇತನ',
'ಪುನಾರಚನೆ',
'ಪುನಾರಚಿಸು',
'ಪುನ್ನಾಗತೋಡಿ',
'ಪುಪ್ಪುಸರೋಗ',
'ಪುರವಸನ',
'ಪುರದಾನಿಕೆ',
'ಪುರದೇವತೆ',
'ಪುರನಯನ',
'ಪುರಮಥನ',
'ಪುರವಂತಿಕೆ',
'ಪುರಶ್ಚರಣ',
'ಪುರಶ್ಚರಣೆ',
'ಪುರಸ್ಕರಿಸು',
'ಪುರಸ್ಸರತೆ',
'ಪುರಾಣಕವಿ',
'ಪುರಾಣಜ್ವರ',
'ಪುರಾಣಮಾರ್ಗ',
'ಪುರಾತವಳಿ',
'ಪುರಿಗಡಲೆ',
'ಪುರಿಗಡುಬು',
'ಪುರಿಗೊಳಿಸು',
'ಪುರಿಸಂಡಗೆ',
'ಪುರುಡುಗೆಯ್',
'ಪುರುಡುದೋಱು',
'ಪುರುಷಕಾರ',
'ಪುರುಷಗುಣ',
'ಪುರುಷತನ',
'ಪುರುಷತುಂಗ',
'ಪುರುಷತ್ರಯ',
'ಪುರುಷದೋಷ',
'ಪುರುಷದ್ವೇಷ',
'ಪುರುಷದ್ವೇಷಿ',
'ಪುರುಷಧರ್ಮ',
'ಪುರುಷನಾಡಿ',
'ಪುರುಷಬ್ರತ',
'ಪುರುಷಭಿಕ್ಷ',
'ಪುರುಷಭಿಕ್ಷೆ',
'ಪುರುಷಭೈಕ್ಷ',
'ಪುರುಷೆಭೈಕ್ಷೆ',
'ಪುರುಷಮಿಗ',
'ಪುರುಷಮೃಗ',
'ಪುರುಷಮೇಧ',
'ಪುರುಷರತ್ನ',
'ಪುರುಷರಾಗ',
'ಪುರುಷರಾಶಿ',
'ಪುರುಷವೇದ',
'ಪುರುಷವ್ಯಾಧಿ',
'ಪುರುಷವ್ರತ',
'ಪುರುಷವ್ರತಿ',
'ಪುರುಷಶೀಲೆ',
'ಪುರುಷಸೂಕ್ತ',
'ಪುರುಷಾಂತರ',
'ಪುರುಷಾದಕ',
'ಪುರುಷಾಮೃಗ',
'ಪುರುಷಾಮ್ರಕ',
'ಪುರುಷೋತ್ತಮ',
'ಪುರುಳುಗಾಣು',
'ಪುರುಳುಗೆಡು',
'ಪುರುಳುಗೊಳ್',
'ಪುರೋಪಕಂಠ',
'ಪುರೋಭಿವೃದ್ಧಿ',
'ಪುರ್ಚುಕುಣಿತ',
'ಪುರ್ವಸಿದ್ಧಾಯ',
'ಪುಲಂಪರಚಿ',
'ಪುಲಕಂಗೂಡು',
'ಪುಲಕವೇರು',
'ಪುಲಕಾಂಕುರ',
'ಪುಲಿದೊಗಲ್',
'ಪುಲಿದೊಗಲು',
'ಪುಲಿದೊವಲ್',
'ಪುಲಿದೊವಲು',
'ಪುಲಿಯುಗುರ್',
'ಪುಲಿಯುಗುರು',
'ಪುಲಿಸುದಳೆ',
'ಪುಲುಗುಡಿತೆ',
'ಪುಲ್ಗುಡಿಸಲ್',
'ಪುಲ್ಗುಡಿಸಿಲ್',
'ಪುಲ್ಗುಡಿಸಿಲು',
'ಪುಲ್ಮಾನಸಿಗ',
'ಪುಲ್ಲಂಪುರಚಿ',
'ಪುಲ್ಲಂಪುರುಚಿ',
'ಪುಲ್ಲವಟುಕ',
'ಪುಲ್ಲವಡಿಗ',
'ಪುಲ್ಲವಡಿತಿ',
'ಪುಲ್ಲಸೊಜ್ಜಿಗೆ',
'ಪುಲ್ಲಿದೊಂಗಲ್',
'ಪುಷ್ಕರಮೂಲ',
'ಪುಷ್ಕಲಾವರ್ತ',
'ಪುಷ್ಕಳಾವರ್ತ',
'ಪುಷ್ಟಿಕಾರಕ',
'ಪುಷ್ಟಿಗೊಳಿಸು',
'ಪುಷ್ಟೀಕರಿಸು',
'ಪುಷ್ಪಕರಂಡ',
'ಪುಷ್ಪಕಾಸೀಸ',
'ಪುಷ್ಪದರ್ಶನ',
'ಪುಷ್ಪಮಂಜರಿ',
'ಪುಷ್ಪಲಾವಕ',
'ಪುಷ್ಪಲಾವಿಕೆ',
'ಪುಷ್ಪವಾಟಿಕ',
'ಪುಷ್ಪವಾಟಿಕೆ',
'ಪುಷ್ಪಶಯನ',
'ಪುಷ್ಪಸಮಯ',
'ಪುಷ್ಪಾಪಚಯ',
'ಪುಸಲಾಯಿಸು',
'ಪುಸಲಾವಣೆ',
'ಪುಸಿಗಾಳೆಗ',
'ಪುಸಿಚರಿತ್ರ',
'ಪುಸಿಬಸನ',
'ಪುಸಿಯುಸುಬು',
'ಪುಸಿವಾತುಗ',
'ಪುಸ್ತಕಕೀಟ',
'ಪುಸ್ತಕಕ್ರಿಮಿ',
'ಪುಸ್ತಕಗಚ್ಛ',
'ಪುಸ್ತಕನೋಟ',
'ಪುಸ್ತಕಪಟ್ಟಿ',
'ಪುಸ್ತಕವಲ್ಲಿ',
'ಪುಳಂಪುಣಿಸು',
'ಪುಳಂಪುರುಚೆ',
'ಪುಳಕಬಿಂದು',
'ಪುಳಕಾಂಕುರ',
'ಪುಳಗುಕಾಪು',
'ಪುಳಿನತಳ',
'ಪುಳಿನಸ್ಥಳ',
'ಪುಳಿಯೋಗರೆ',
'ಪುಳುಗುಗಾಪು',
'ಪುಳ್ಗಿಯೋಗರ',
'ಪುಳ್ಳಂಪುರಚಿ',
'ಪುಳ್ಳಂಪುರಸಿ',
'ಪುಳ್ಳಂಪುರಿಚೆ',
'ಪುಳ್ಳಪುರಸಿ',
'ಪುಳ್ಳಿಂಪುರಸೆ',
'ಪುಳ್ಳುಂಪುಣಿಸೆ',
'ಪುೞುವಾನಸ',
'ಪುೞುವಾನಿಸ',
'ಪೂಗರಡಿಗ',
'ಪೂಜಾಮಂದಿರ',
'ಪೂಜಾರಾಧನ',
'ಪೂಜೆಗೊಡಗಿ',
'ಪೂಜ್ಯಪಾದತೆ',
'ಪೂಜ್ಯಭಾವನೆ',
'ಪೂಟಲಾಯರಿ',
'ಪೂಟಲಾಯರು',
'ಪೂಟುಲಾಯರು',
'ಪೂಣಿಗತನ',
'ಪೂತಕ್ರತಾಯಿ',
'ಪೂತಾಚರಣ',
'ಪೂತಿಕರಜ',
'ಪೂತಿನಾಶಕ',
'ಪೂಮಳೆಗಱೆ',
'ಪೂಮಾಲೆಗಾರ್ತಿ',
'ಪೂರಂಬರಿಸು',
'ಪೂರಣಂಗೆಯ್',
'ಪೂಣಗಡುಬು',
'ಪೂರಾಯಗೆಯ್',
'ಪೂರಿತಗೆಯ್',
'ಪೂರುಷತನ',
'ಪೂರ್ಣಗರ್ಭಿಣಿ',
'ಪೂರ್ಣಗೊಳಿಸು',
'ಪೂರ್ಣಗ್ರಹಣ',
'ಪೂರ್ಣಚಂದ್ರಿಕೆ',
'ಪೂರ್ಣಪಂಚಮ',
'ಪೂರ್ಣಲಲಿತ',
'ಪೂರ್ಣವಿರಾಮ',
'ಪೂರ್ಣಸಮಾಧಿ',
'ಪೂರ್ಣಾವತಾರ',
'ಪೂರ್ತಗೊಳಿಸು',
'ಪೂರ್ತಿಗೊಳಿಸು',
'ಪೂರ್ಬಮರ್ಯಾದೆ',
'ಪೂರ್ವಕಂಕಣ',
'ಪೂರ್ವಕಲ್ಪನೆ',
'ಪೂರ್ವಕಲ್ಯಾಣಿ',
'ಪೂರ್ವಗ್ರಹಿಕೆ',
'ಪೂರ್ವಚಿಂತನೆ',
'ಪೂರ್ವದಿಕ್ಪತಿ',
'ಪೂರ್ವದ್ರಾವಿಡ',
'ಪೂರ್ವನಿರ್ದಿಷ್ಟ',
'ಪೂರ್ವನಿಶ್ಚಯ',
'ಪೂರ್ವನಿಶ್ಚಿತ',
'ಪೂರ್ವಪರ್ವತ',
'ಪೂರ್ವಪ್ರಕೃತಿ',
'ಪೂರ್ವಪ್ರತ್ಯಯ',
'ಪೂರ್ವಫಲ್ಗುನಿ',
'ಪೂರ್ವಮರ್ಯಾದೆ',
'ಪೂರ್ವಮೀಮಾಂಸೆ',
'ಪೂರ್ವಲಕ್ಷಣ',
'ಪೂರ್ವಲಿಖಿತ',
'ಪೂರ್ವವಯಸ್ಸು',
'ಪೂರ್ವವರಾಳಿ',
'ಪೂರ್ವವಾಸನೆ',
'ಪೂರ್ವವಿದೇಹ',
'ಪೂರ್ವವಿರೋಧ',
'ಪೂರ್ವವಿರೋಧಿ',
'ಪೂರ್ವವೈಭವ',
'ಪೂರ್ವಸಂಚಿತ',
'ಪೂರ್ವಸಾಸನ',
'ಪೂರ್ವಸಿದ್ಧತೆ',
'ಪೂರ್ವಸಿದ್ಧಾಯ',
'ಪೂರ್ವಸೂಚನೆ',
'ಪೂರ್ವಸ್ಮರಣ',
'ಪೂರ್ವಸ್ಮರಣೆ',
'ಪೂರ್ವಾನುಭವ',
'ಪೂರ್ವಾನುರಾಗ',
'ಪೂರ್ವಾಭಿಮುಖ',
'ಪೂರ್ವಾಭಿಮುಖಿ',
'ಪೂರ್ವಾರ್ಧಗೋಳ',
'ಪೂರ್ವೇತಿಹಾಸ',
'ಪೂರ್ವೋಪಾರ್ಜಿತ',
'ಪೂವಡಿಗಿತಿ',
'ಪೂವೞೆಗಱೆ',
'ಪೂವಾಡಗಿತ್ತಿ',
'ಪೂವಿಲುಗಾಱ',
'ಪೃಥಕ್ಕರಣ',
'ಪೃಥಕ್ಕರಣೆ',
'ಪೃಥಕ್ಕರಿಸು',
'ಪೃಥಿವಿಪತಿ',
'ಪೃಥಿವಿಪಾಲ',
'ಪೃಥಿವೀಚಕ್ರ',
'ಪೃಥಿವೀಧಾತು',
'ಪೃಥಿವೀಪತಿ',
'ಪೃಥಿವೀಪಾಲ',
'ಪೃಥಿವೀಪಾಳ',
'ಪೃಥುಳಗತಿ',
'ಪೃಥ್ವಿಮಂಡಲ',
'ಪೃಥ್ವಿವಲ್ಲಭ',
'ಪೃಥ್ವೀವಲ್ಲಭ',
'ಪೃಷ್ಠಸ್ವಸ್ತಿಕ',
'ಪೃಷ್ಠಾನುಸಾರಿ',
'ಪೆಂಕುಳಿಗಲಿ',
'ಪೆಂಕುಳಿಗೊಳ್',
'ಪೆಂಕುಳಿನಾಯ್',
'ಪೆಂಡತಿವಂತ',
'ಪೆಕ್ಕಣಂಗೆಯ್',
'ಪೆಟಲುಯಂತ್ರ',
'ಪೆಟ್ಟಿಗೆಗಾಡಿ',
'ಪೆಟ್ಟಿಗೆಬಂಡಿ',
'ಪೆಟ್ರೋಲಿಯಮ್ಮು',
'ಪೆಟ್ಲುತುರುವೆ',
'ಪೆಡಂಗಲಸು',
'ಪೆಡಂಮಗುಳ್',
'ಪೆಡಂಮಗುೞ್',
'ಪೆಡಕೆಡಪು',
'ಪೆಡಗೆಪಾಗು',
'ಪೆಡಗೆಯ್ಗಟ್ಟು',
'ಪೆಡಗೈಗಟ್ಟು',
'ಪೆಡಮಗುೞ್',
'ಪೆಡಮೆಟ್ಟಿಸು',
'ಪೆಡಸುಗಟ್ಟು',
'ಪೆಡಸುಗೊಳ್ಳು',
'ಪೆಡಸುದಾರಿ',
'ಪೆಡೆವರಲ್',
'ಪೆಣಗೆಡಹು',
'ಪೆಣೆಗೊಳಿಸು',
'ಪೆಣೆದಾವಣಿ',
'ಪೆನ್ಶನ್ನುದಾರ',
'ಪೆನ್ಷನ್ನುದಾರ',
'ಪೆಪ್ಪರಮೆಂಟ್',
'ಪೆಪ್ಪರಮೆಂಟು',
'ಪೆರುಮಾಳ್ತನ',
'ಪೆರೆಗೊಡೆಯ',
'ಪೆರೆದಲೆಯ',
'ಪೆರೆನೊಸಲು',
'ಪೆರ್ಕಳಂಗೊಳ್',
'ಪೆರ್ಗಡೆತನ',
'ಪೆರ್ಗಣಿಗಿಲ್',
'ಪೆರ್ಗುರುಂಟಿಗೆ',
'ಪೆರ್ಗೆಡೆತನ',
'ಪೆರ್ಚುಗೆಗೊಳ್',
'ಪೆರ್ಚುಗೆವಡೆ',
'ಪೆರ್ಮಡಿವಟ್ಟ',
'ಪೆರ್ಮೆಯೊಡೆಯ',
'ಪೆಸರುಗೊಳ್',
'ಪೆಸರುವಡೆ',
'ಪೆಸರುವೆಱು',
'ಪೆರಸರ್ಗಿಡು',
'ಪೆಹೆಲವಾನ್',
'ಪೆಳವಳಿಸು',
'ಪೆಳಱುಗೊಳ್',
'ಪೆಱಗುಗುಡು',
'ಪೆಱಗುಗೆಯ್',
'ಪೆಱಪಿಂಗಿಸು',
'ಪೆಱಸಾರಿಸು',
'ಪೆಱೆಗೊಡೆಯ',
'ಪೆಱೆದಲೆಯ',
'ಪೆಱೆನೊಸಲ್',
'ಪೆಱೆನೊಸಲು',
'ಪೆೞವುಗೊಳ್',
'ಪೇಕುಳಿಗೊಳ್',
'ಪೇಕುಳಿನಾಯ್',
'ಪೇಸರಗತ್ತೆ',
'ಪೇಸುಗೆಗೆಡು',
'ಪೈತೃಕಕರ್ಮ',
'ಪೈತೃಕಧನ',
'ಪೈಯಿವಸ್ತಿಕೆ',
'ಪೈಲಾನಂಬರು',
'ಪೈಶೂನ್ಯದೂರ',
'ಪೈಸರಂಗೊಳ್',
'ಪೈಸರಂಬೋಗು',
'ಪೈಸರಗೊಡು',
'ಪೈಸರಗೊಳ್',
'ಪೈಸರಗೋಲು',
'ಪೈಸರವೋಗು',
'ಪೊಂಕಂಗಿಡಿಸು',
'ಪೊಂಪುಳಿಗೊಳ್',
'ಪೊಂಪುಳಿಯೋಗು',
'ಪೊಂಪುಳಿವಡೆ',
'ಪೊಂಪುಳಿವೆಱು',
'ಪೊಂಪುಳಿವೋಗು',
'ಪೊಂಪುೞಿಗೊಳ್',
'ಪೊಂಪುೞಿಯೇಱು',
'ಪೊಂಪುೞಿಯೋಗು',
'ಪೊಂಪುೞಿವಡೆ',
'ಪೊಂಪುೞಿವೆಱು',
'ಪೊಂಪುೞಿವೋಗು',
'ಪೊಕ್ಕುಳವಕ್ಕಿ',
'ಪೊಕ್ಕುಳಿವಕ್ಕಿ',
'ಪೊಗರಿಳಿಸು',
'ಪೊಗರಿೞಿಸು',
'ಪೊಗರುವೆಱು',
'ಪೊಗರ್ತೆವಡೆ',
'ಪೊಗರ್ತೆವೆಱು',
'ಪೊಗಳ್ತೆವಡೆ',
'ಪೊಗೞ್ಕೆವಡೆ',
'ಪೊಗೞ್ಕೆವೆಱು',
'ಪೊಗೞ್ತೆವಡೆ',
'ಪೊಗೞ್ತೆವೆಱು',
'ಪೊಗೆಗೊಳಿಸು',
'ಪೊಚ್ಚಱತನ',
'ಪೊಚ್ಚಱುಗಿಡು',
'ಪೊಚ್ಚಱುಗೆಡು',
'ಪೊಟ್ಟಣಂಗಟ್ಟು',
'ಪೊಟ್ಟಣಗಟ್ಟು',
'ಪೊಟ್ಟಳಂಗಟ್ಟು',
'ಪೊಟ್ಟೆಗುಡುಮ',
'ಪೊಡಕರಿಸು',
'ಪೊಡಮಡಿಸು',
'ಪೊಡಮಡುಹ',
'ಪೊಡಮಡುಹು',
'ಪೊಡರ್ಪುಗಿಡು',
'ಪೊಡರ್ಪುಗುಂದು',
'ಪೊಡರ್ಪುಗೆಡು',
'ಪೊಡರ್ಪುಗೆಯ್',
'ಪೊಡರ್ಪುಡುಗು',
'ಪೊಡರ್ಪುದೋಱ್',
'ಪೊಡರ್ಪುದೋಱು',
'ಪೊಡರ್ಪುವೆಱು',
'ಪೊಡವಡಿಕೆ',
'ಪೊಡವಡಿಸು',
'ಪೊಡವಿಪತಿ',
'ಪೊಡವಿವನೆ',
'ಪೊಡವಿವೆಣ್',
'ಪೊಡೆಮಗುಚು',
'ಪೊಡೆಮಗುಳ್',
'ಪೊಡೆಮಗುೞ್',
'ಪೊಡೆಮಡಿಸು',
'ಪೊಡೆಮರಳ್',
'ಪೊಡೆಯರಲ',
'ಪೊಡೆಯಲರ',
'ಪೊಡೆವಡಿಸು',
'ಪೊತ್ತುವೆಳಗು',
'ಪೊದರ್ಕೊದಳ್',
'ಪೊದಳ್ಕೆವಡೆ',
'ಪೊದಱುಗಟ್ಟು',
'ಪೊದಱುಗೊಳ್',
'ಪೊದೞ್ಕೆಗುಂದು',
'ಪೊದೞ್ಕೆವಡೆ',
'ಪೊದೞ್ಕೆವೆಱು',
'ಪೊದೆಗೆದಱು',
'ಪೊನಲುವರಿ',
'ಪೊಮ್ಮಡೆಬೆಳ್ಳಿ',
'ಪೊಮ್ಮಡೆವೆಳ್ಳಿ',
'ಪೊರಕೆಕಡ್ಡಿ',
'ಪೊರಳನ್ವಯ',
'ಪೊರೆಕಳಚು',
'ಪೊರೆನೆತ್ತರ್',
'ಪೊರೆನೆತ್ತರ',
'ಪೊರೆಪುದೋಱು',
'ಪೊರೆಯೇಱಿಸು',
'ಪೊರ್ಕುಳಿಗೊಳ್',
'ಪೊರ್ಕುಳಿಯಿಕ್ಕು',
'ಪೊರ್ಕುಳಿವಕ್ಕಿ',
'ಪೊರ್ಕುಳಿವುಗು',
'ಪೊರ್ದುಗೆವಡೆ',
'ಪೊರ್ದುಗೆವೆಱು',
'ಪೊಲಂಬುಗಿಡು',
'ಪೊಲಂಬುಗೆಡು',
'ಪೊಲಂಬುದಪ್ಪು',
'ಪೊಲಂಬುದೋಱು',
'ಪೊಲಗಿಡಿಸು',
'ಪೊಲಗೆಡಿಸು',
'ಪೊಲಬುಗೆಡು',
'ಪೊಲಬುದಪ್ಪು',
'ಪೊಲಬುದೋಱು',
'ಪೊಲವಾನಸೆ',
'ಪೊಲಸುಗೊಳ್',
'ಪೊಲಸುನಾಱು',
'ಪೊಲೆಗಲಸು',
'ಪೊಲೆಗಿಡಿಸು',
'ಪೊಲೆಗೆಡಿಸು',
'ಪೊಲ್ಲದುಗೆಯ್',
'ಪೊಲ್ಲಮೆಗೆಯ್',
'ಪೊಸಕನ್ನಡ',
'ಪೊಸಕಿಟ್ಟಳ',
'ಪೊಸಗನ್ನಡ',
'ಪೊಸಜವ್ವನ',
'ಪೊಸಜವ್ವನೆ',
'ಪೊಸಜೌವನ',
'ಪೊಸಜೌವನೆ',
'ಪೊಸಪರೆಯ',
'ಪೊಸಪೊೞ್ತರ್',
'ಪೊಸವರೆಯ',
'ಪೊಸ್ತಕಗಚ್ಛ',
'ಪೊಸ್ತಕಗಛ',
'ಪೊಳೆಪುವಡೆ',
'ಪೊಱಕಾಲ್ಗೊಳ್',
'ಪೊಱಮಡಿಸು',
'ಪೊಱಮಿಂಚುಗೈ',
'ಪೊಱವಲಯ',
'ಪೊಱವಳಯ',
'ಪೊಱವೊಳಲ್',
'ಪೊಱವೊಱಸು',
'ಪೊಱವೊೞಲ್',
'ಪೋಕರಿತನ',
'ಪೋಟಖರಾಬು',
'ಪೋಟುಗಾರಿಕೆ',
'ಪೋತವಣಿಜ',
'ಪೋರಟೆವೆಱು',
'ಪೋರ್ಕುಳಿಗೊಳ್',
'ಪೋರ್ಕುಳಿಯಿಕ್ಕು',
'ಪೋರ್ಕುಳಿವಕ್ಕಿ',
'ಪೋೞ್ಗಡಿವೋಗು',
'ಪೌಜುಗಂದಾಯ',
'ಪೌರುಷವಂತ',
'ಪೌರುಷವಾದಿ',
'ಪೌರ್ಣಮೀದಿನ',
'ಪೌವನೆವೋಗು',
'ಪೌಷ್ಟಿಕಕರ್ಮ',
'ಪೌಳಿಗವಾಕ್ಷಿ',
'ಪ್ರಕಟಂಬೆಱು',
'ಪ್ರಕಟಗೊಳ್ಳು',
'ಪ್ರಕಟೀಕೃತ',
'ಪ್ರಕಾರಾಂತರ',
'ಪ್ರಕಾಶಗೊಳ್ಳು',
'ಪ್ರಕೃತಿಭಾವ',
'ಪ್ರಕೃತಿಶಾಸ್ತ್ರ',
'ಪ್ರಕೃತಿಸ್ವರ',
'ಪ್ರಕ್ಷಿಪ್ತಪಾಠ',
'ಪ್ರಕ್ಷುಬ್ಧಗೊಳ್',
'ಪ್ರಕ್ಷುಬ್ಧಗೊಳ್ಳು',
'ಪ್ರಕ್ಷೇಪಕಾರ',
'ಪ್ರಖ್ಯಾತಿವೆಱು',
'ಪ್ರಗತಿಪಥ',
'ಪ್ರಗತಿಪರ',
'ಪ್ರಗತಿಪರೆ',
'ಪ್ರಗತಿವಾದಿ',
'ಪ್ರಗತಿಶೀಲ',
'ಪ್ರಗತಿಶೀಲೆ',
'ಪ್ರಚಲಾಯಿತ',
'ಪ್ರಚಾರಕಾರ್ಯ',
'ಪ್ರಚುರಗೊಳ್ಳು',
'ಪ್ರಚುರದುಗ್ಧೆ',
'ಪ್ರಚ್ಛದಪಟ',
'ಪ್ರಚ್ಛನ್ನವೇಷ',
'ಪ್ರಜಾಧಿಪತ್ಯ',
'ಪ್ರಜಾಪಕ್ಷೀಯ',
'ಪ್ರಜಾಪತಿತ್ವ',
'ಪ್ರಜಾಪಾಲನ',
'ಪ್ರಜಾಪಾಲನೆ',
'ಪ್ರಜಾಪ್ರಭುತ್ವ',
'ಪ್ರಜಾಸರ್ಕಾರ',
'ಪ್ರಜಾಸ್ವಾತಂತ್ರ್ಯ',
'ಪ್ರಜ್ಞಪ್ತಿವಿದ್ಯೆ',
'ಪ್ರಜ್ಞಾಗ್ರಾಹಿತ್ವ',
'ಪ್ರಜ್ಞಾಶೂನ್ಯತೆ',
'ಪ್ರಜ್ಞಾಹೀನತೆ',
'ಪ್ರಣಮಮೂಲ',
'ಪ್ರಣಯಕೇಲಿ',
'ಪ್ರಣಯಕೇಳಿ',
'ಪ್ರಣಯಚೇಷ್ಟೆ',
'ಪ್ರಣಯದೌತ್ಯ',
'ಪ್ರಣಯಭಿಕ್ಷೆ',
'ಪ್ರಣವನ್ಯಾಸ',
'ಪ್ರಣವಬೀಜ',
'ಪ್ರಣವಮಂತ್ರ',
'ಪ್ರತರಕ್ರಿಯೆ',
'ಪ್ರತಾಪವಂತ',
'ಪ್ರತಾಪಶಾಲಿ',
'ಪ್ರತಾಪೋದಯ',
'ಪ್ರತಿಕೂಲತೆ',
'ಪ್ರತಿಕೃತಿಸು',
'ಪ್ರತಿಕ್ರಮಣ',
'ಪ್ರತಿಗ್ರಹಿಸು',
'ಪ್ರತಿಗ್ರಾಹಕ',
'ಪ್ರತಿಘಾತನ',
'ಪ್ರತಿಚ್ಛಂದತೆ',
'ಪ್ರತಿಜಾಗರ',
'ಪ್ರತಿಜ್ಞಾಭಾರ',
'ಪ್ರತಿಜ್ಞಾರೂಢ',
'ಪ್ರತಿಜ್ಞಾವಾಕ್ಯ',
'ಪ್ರತಿಜ್ಞೆಗೆಯ್',
'ಪ್ರತಿಟ್ಟೆಗೆಯ್',
'ಪ್ರತಿದಿವಸ',
'ಪ್ರತಿದಿವಸಂ',
'ಪ್ರತಿಧ್ವನಿತ',
'ಪ್ರತಿಧ್ವನಿಸು',
'ಪ್ರತಿನಾದಿತ',
'ಪ್ರತಿನಾಯಕ',
'ಪ್ರತಿನಾಯಿಕೆ',
'ಪ್ರತಿನಿಧಿತ್ವ',
'ಪ್ರತಿನಿಧಿಸು',
'ಪ್ರತಿನಿಯತ',
'ಪ್ರತಿಪದ್ದಿನ',
'ಪ್ರತಿಪನ್ನತೆ',
'ಪ್ರತಿಪನ್ನಿಕೆ',
'ಪ್ರತಿಪಾದಕ',
'ಪ್ರತಿಪಾದನ',
'ಪ್ರತಿಪಾದನೆ',
'ಪ್ರತಿಪಾದಿತ',
'ಪ್ರತಿಪಾದಿಸು',
'ಪ್ರತಿಪಾದುಕ',
'ಪ್ರತಿಪಾನಕ',
'ಪ್ರತಿಪಾಲನ',
'ಪ್ರತಿಪಾಲನೆ',
'ಪ್ರತಿಪಾಲಿತ',
'ಪ್ರತಿಪಾಲಿಸು',
'ಪ್ರತಿಪಾಳಿಸು',
'ಪ್ರತಿಫಲನ',
'ಪ್ರತಿಫಲಿತ',
'ಪ್ರತಿಫಲಿಸು',
'ಪ್ರತಿಫಳಿತ',
'ಪ್ರತಿಬಂಧಕ',
'ಪ್ರತಿಬಂಧನ',
'ಪ್ರತಿಬಂಧಿನಿ',
'ಪ್ರತಿಬಂಧಿಸು',
'ಪ್ರತಿಬಿಂಬನ',
'ಪ್ರತಿಬಿಂಬಿತ',
'ಪ್ರತಿಬಿಂಬಿಸು',
'ಪ್ರತಿಬೋಧಕ',
'ಪ್ರತಿಬೋಧನ',
'ಪ್ರತಿಬೋಧಿಸು',
'ಪ್ರತಿಭಟನೆ',
'ಪ್ರತಿಭಟಿಸು',
'ಪ್ರತಿಭಾನ್ವಿತ',
'ಪ್ರತಿಭಾನ್ವಿತೆ',
'ಪ್ರತಿಭಾವಂತ',
'ಪ್ರತಿಭಾವಂತೆ',
'ಪ್ರತಿಭಾವತ್ವ',
'ಪ್ರತಿಭಾಶಾಲಿ',
'ಪ್ರತಿಮಧ್ಯಮ',
'ಪ್ರತಿಮಾತಂತ್ರ',
'ಪ್ರತಿಮಾನಿಷ್ಠ',
'ಪ್ರತಿಮಾನಿಷ್ಠೆ',
'ಪ್ರತಿಮಾಮಾನ',
'ಪ್ರತಿಮಾಯೋಗ',
'ಪ್ರತಿಮಾಯೋಗಿ',
'ಪ್ರತಿಮಾರೂಪ',
'ಪ್ರತಿಮಾಶಾಸ್ತ್ರ',
'ಪ್ರತಿಮಾಶಿಲ್ಪ',
'ಪ್ರತಿಮಾಸೃಷ್ಟಿ',
'ಪ್ರತಿಮುಖರ',
'ಪ್ರತಿಯಾತನ',
'ಪ್ರತಿಯಾತನೆ',
'ಪ್ರತಿರಕ್ಷಣ',
'ಪ್ರತಿರಕ್ಷಣೆ',
'ಪ್ರತಿರಣನೆ',
'ಪ್ರತಿರಣಿತ',
'ಪ್ರತಿರಹಿತ',
'ಪ್ರತಿರೋಧಕ',
'ಪ್ರತಿರೋಧಿಸು',
'ಪ್ರತಿಲೇಖನ',
'ಪ್ರತಿಲೇಖನೆ',
'ಪ್ರತಿಲೇಖಿಸು',
'ಪ್ರತಿಲೋಮಜ',
'ಪ್ರತಿವಾದಿಸು',
'ಪ್ರತಿವಿಹೀನ',
'ಪ್ರತಿವಿಹೀನೆ',
'ಪ್ರತಿವೆರಸು',
'ಪ್ರತಿಶಾಸನ',
'ಪ್ರತಿಷೇಧಿಸು',
'ಪ್ರತಿಷ್ಠಾಪಕ',
'ಪ್ರತಿಷ್ಠಾಪನ',
'ಪ್ರತಿಷ್ಠಾಪನೆ',
'ಪ್ರತಿಷ್ಠಾಪಿಸು',
'ಪ್ರತಿಷ್ಠೆಗೆಯ್',
'ಪ್ರತಿಸಂಧಿಸು',
'ಪ್ರತಿಸಮಯಂ',
'ಪ್ರತಿಸಾರಣ',
'ಪ್ರತಿಸ್ಪಂದನ',
'ಪ್ರತಿಸ್ಪಂದಿಸು',
'ಪ್ರತಿಹರಣ',
'ಪ್ರತಿಹಾರಕ',
'ಪ್ರತೀಕಂಗೊಳ್ಳು',
'ಪ್ರತೀಕವಾದ',
'ಪ್ರತೀಯಮಾನ',
'ಪ್ರತೀಹಾರಕ',
'ಪ್ರತೀಹಾರಕಿ',
'ಪ್ರತೀಹಾರಿಕೆ',
'ಪ್ರತ್ಯಂತವಾಸ',
'ಪ್ರತ್ಯಂತವಾಸಿ',
'ಪ್ರತ್ಯಂತಸೀಮೆ',
'ಪ್ರತ್ಯಕ್ಷಜ್ಞಾನ',
'ಪ್ರತ್ಯಕ್ಷಫಲ',
'ಪ್ರತ್ಯಗಾತುಮ',
'ಪ್ರತ್ಯಭಿಜ್ಞಾನ',
'ಪ್ರತ್ಯಭಿಯೋಗ',
'ಪ್ರತ್ಯಯಂಗೆಯ್',
'ಪ್ರತ್ಯಯೋಪಮೆ',
'ಪ್ರತ್ಯವಸಾನ',
'ಪ್ರತ್ಯವಸಿತ',
'ಪ್ರತ್ಯಾಗಮನ',
'ಪ್ರತ್ಯಾಹರಿಸು',
'ಪ್ರತ್ಯುದ್ಗಮನ',
'ಪ್ರತ್ಯುಪಕಾರ',
'ಪ್ರತ್ಯುಪಕೃತಿ',
'ಪ್ರತ್ಯುಪಾಯನ',
'ಪ್ರತ್ಯೂಷಕೃತ್ಯ',
'ಪ್ರಥಮಋತು',
'ಪ್ರಥಮಕಲ್ಪ',
'ಪ್ರಥಮದ್ವೀಪ',
'ಪ್ರಥಮಸ್ತನ್ಯ',
'ಪ್ರದೀಪಕತೆ',
'ಪ್ರದೇಶಬಂಧ',
'ಪ್ರದೋಷಕಾಲ',
'ಪ್ರದೋಷಪೂಜೆ',
'ಪ್ರದೋಷವ್ರತ',
'ಪ್ರಧಾನಕ್ರಿಯೆ',
'ಪ್ರಧಾನಮಂತ್ರಿ',
'ಪ್ರಧ್ವಂಸಾಭಾವ',
'ಪ್ರನಾಳಶಿಶು',
'ಪ್ರಪಂಚಜ್ಞಾನ',
'ಪ್ರಪಂಚುಜೀವಿ',
'ಪ್ರಪಂಚುಭಾವ',
'ಪ್ರಪತ್ತಿಮಾರ್ಗ',
'ಪ್ರಪಾಕಳಾಪ',
'ಪ್ರಪಾಪಾಲಿಕೆ',
'ಪ್ರಪಾಪ್ರಮದೆ',
'ಪ್ರಪಾಮಂಟಪ',
'ಪ್ರಪಾಸದನ',
'ಪ್ರಪಿತಾಮಹ',
'ಪ್ರಪಿತಾಮಹಿ',
'ಪ್ರಪೌಂಡರೀಕ',
'ಪ್ರಬಂಧಧ್ವನಿ',
'ಪ್ರಬಂಧಲಿಪಿ',
'ಪ್ರಭಾತ್‍ಫೇರಿ',
'ಪ್ರಭಾತಕಾಲ',
'ಪ್ರಭಾತಫೇರಿ',
'ಪ್ರಭಾತವಿಧಿ',
'ಪ್ರಭಾಮಂಡಲ',
'ಪ್ರಭಾಮಂಡಳ',
'ಪ್ರಭಾವಕಾರಿ',
'ಪ್ರಭಾವಮುದ್ರೆ',
'ಪ್ರಭಾವಲಯ',
'ಪ್ರಭಾವಲ್ಲಭ',
'ಪ್ರಭಾವಶಾಲಿ',
'ಪ್ರಭುಗೊಡಗೆ',
'ಪ್ರಭುವಳಿತ',
'ಪ್ರಭುಸಮ್ಮಿತ',
'ಪ್ರಮತ್ತಯೋಗ',
'ಪ್ರಮಥಗಣ',
'ಪ್ರಮಥನಾಥ',
'ಪ್ರಮದಂಬೆಱು',
'ಪ್ರಮದವನ',
'ಪ್ರಮಾಣಗ್ರಂಥ',
'ಪ್ರಮಾಣಪತ್ರ',
'ಪ್ರಮಾಣಪ್ರಜ್ಞೆ',
'ಪ್ರಮಾಣವಾಕ್ಯ',
'ಪ್ರಮಾಣಶಾಸ್ತ್ರ',
'ಪ್ರಮಾಣಶ್ರುತಿ',
'ಪ್ರಮಾದಚರ್ಯೆ',
'ಪ್ರಮಿತಾಕ್ಷರ',
'ಪ್ರಮೀಳಾರಾಜ್ಯ',
'ಪ್ರಯೋಗಶಾಲೆ',
'ಪ್ರಯೋಗಸಿದ್ಧಿ',
'ಪ್ರಲಯಕಾಲ',
'ಪ್ರಲಾಪಂಗೆಯ್',
'ಪ್ರವರಶೈವ',
'ಪ್ರವರಾಕ್ಷರ',
'ಪ್ರವರ್ಧಮಾನ',
'ಪ್ರವಾಸಪತ್ರ',
'ಪ್ರವಾಸಿಕೇಂದ್ರ',
'ಪ್ರವಾಸೋದ್ಯಮ',
'ಪ್ರವಾಳಮಣಿ',
'ಪ್ರವಿಕಸಿತ',
'ಪ್ರವಿಘಟನ',
'ಪ್ರವಿದಾರಣ',
'ಪ್ರವಿನಾಶನ',
'ಪ್ರವೀಚರಣ',
'ಪ್ರವೃತ್ತಿಮಾರ್ಗ',
'ಪ್ರವೇಶದ್ವಾರ',
'ಪ್ರವೇಶಪತ್ರ',
'ಪ್ರಶಂಸಾಪತ್ರ',
'ಪ್ರಶಂಸೋಪಮೆ',
'ಪ್ರಶಸ್ತಿಪತ್ರ',
'ಪ್ರಸಂಗಕಾರ',
'ಪ್ರಸವಕಾಲ',
'ಪ್ರಸವದಿನ',
'ಪ್ರಸವಬಂಧ',
'ಪ್ರಸವವೇಧೆ',
'ಪ್ರಸವಶಾಸ್ತ್ರ',
'ಪ್ರಸವಾಗಾರ',
'ಪ್ರಸವಾಲಯ',
'ಪ್ರಸವೋತ್ಸವ',
'ಪ್ರಸಾದಂಗೆಯ್',
'ಪ್ರಸಾದಕರ್ಮ',
'ಪ್ರಸಾದಕಾಯ',
'ಪ್ರಸಾದಿಸ್ಥಲ',
'ಪ್ರಸೂತಿಕರ್ಮ',
'ಪ್ರಸೂತಿಕ್ರಿಯೆ',
'ಪ್ರಸೂತಿಗೃಹ',
'ಪ್ರಸ್ಥಾನಂಗೆಯ್',
'ಪ್ರಸ್ಥಾನತೂರ್ಯ',
'ಪ್ರಸ್ಥಾನತ್ರಯ',
'ಪ್ರಸ್ಥಾನತ್ರಯಿ',
'ಪ್ರಸ್ಥಾನಪಾಂಶು',
'ಪ್ರಹಾರತ್ರಯ',
'ಪ್ರಹುಡಿಕಾಱ',
'ಪ್ರಳಯಕರ',
'ಪ್ರಳಯಕಾಲ',
'ಪ್ರಳಯಗತ',
'ಪ್ರಳಾಪಸನ್ನಿ',
'ಪ್ರಾಂತರಕ್ಷಕ',
'ಪ್ರಾಂಸರಿನೋಟು',
'ಪ್ರಾಕ್ತನಶಾಸ್ತ್ರ',
'ಪ್ರಾಗ್ಭಾರತೀಯ',
'ಪ್ರಾಚೀನಕರ್ಮ',
'ಪ್ರಾಚೀನಕಾಲ',
'ಪ್ರಾಚೀನಪಾಠ',
'ಪ್ರಾಚೀನಬರ್ಹಿ',
'ಪ್ರಾಚೀನಾವೀತ',
'ಪ್ರಾಚೀನಾವೀತಿ',
'ಪ್ರಾಚ್ಯಶೋಧನೆ',
'ಪ್ರಾಣಕಂಟಕ',
'ಪ್ರಾಣಘಾತಕ',
'ಪ್ರಾಣತಕಲ್ಪ',
'ಪ್ರಾಣದೇವರು',
'ಪ್ರಾಣಧಾರಣೆ',
'ಪ್ರಾಣನಾಶಕ',
'ಪ್ರಾಣಪಂಚಕ',
'ಪ್ರಾಣಪದಕ',
'ಪ್ರಾಣಪವನ',
'ಪ್ರಾಣಪ್ರತಿಷ್ಠೆ',
'ಪ್ರಾಣಯಾತನೆ',
'ಪ್ರಾಣರಕ್ಷಣೆ',
'ಪ್ರಾಣವಂಚಕ',
'ಪ್ರಾಣವಲ್ಲಭ',
'ಪ್ರಾಣವಲ್ಲಭೆ',
'ಪ್ರಾಣವಿಯೋಗ',
'ಪ್ರಾಣಸಂಕಟ',
'ಪ್ರಾಣಸಂಯಮ',
'ಪ್ರಾಣಸ್ನೇಹಿತ',
'ಪ್ರಾಣಸ್ನೇಹಿತೆ',
'ಪ್ರಾಣಸ್ವರೂಪ',
'ಪ್ರಾಣಸ್ವರೂಪೆ',
'ಪ್ರಾಣಾತಿಪಾತ',
'ಪ್ರಾಣಿಶಾಸ್ತ್ರಜ್ಞ',
'ಪ್ರಾಣಿಶಾಸ್ತ್ರಜ್ಞೆ',
'ಪ್ರಾತಃಸವನ',
'ಪ್ರಾತಿನಿಧಿಕ',
'ಪ್ರಾತಿಪದಿಕ',
'ಪ್ರಾತಿಭಾಸಿಕ',
'ಪ್ರಾತಿಹಾರಕ',
'ಪ್ರಾದುರ್ಭವಿಸು',
'ಪ್ರಾಪಂಚಿಕತೆ',
'ಪ್ರಾಪ್ತವಯಸ್ಕ',
'ಪ್ರಾಪ್ತವಯಸ್ಕೆ',
'ಪ್ರಾಮಾಣಿಕತೆ',
'ಪ್ರಾಯೋಗಿಕತೆ',
'ಪ್ರಾಯೋಪವೇಶ',
'ಪ್ರಾರಂಭೋತ್ಸವ',
'ಪ್ರಾರಬ್ಧಕರ್ಮ',
'ಪ್ರಾರ್ಥನಂಗೆಯ್',
'ಪ್ರಾರ್ಥನಾಪತ್ರ',
'ಪ್ರಾಲೇಯಕರ',
'ಪ್ರಾಲೇಯಾಚಲ',
'ಪ್ರಾಳೇಯಾಚಲ',
'ಪ್ರಾಳೇಯಾಚಳ',
'ಪ್ರಿಯಕಾರಿಣಿ',
'ಪ್ರಿಯಕಾವೇರಿ',
'ಪ್ರಿಯವಾದಿನಿ',
'ಪ್ರೀಣನಕರ',
'ಪ್ರೇಕ್ಷಣೀಯತೆ',
'ಪ್ರೇತತರ್ಪಣ',
'ಪ್ರೇತಸಂಸ್ಕಾರ',
'ಪ್ರೇತಸೂತಕ',
'ಪ್ರೇತಾರಾಧನೆ',
'ಪ್ರೇಮಕಲಹ',
'ಪ್ರೇಮವಿವಾಹ',
'ಪ್ರೇಮಾನುಬಂಧ',
'ಪ್ರೋತ್ಸಾಹಗೊಳ್ಳು',
'ಪ್ರೌಢಶಿಕ್ಷಣ',
'ಪ್ಲವಂಗವಿದ್ಯೆ',
'ಫಟಕಿಶಿಕ್ಷೆ',
'ಫಟಾಕಿಶಿಕ್ಷೆ',
'ಫಟಿಂಗತನ',
'ಫಡನವೀಸ',
'ಫಣಗೆಲಸ',
'ಫಣಿನಿಲಯ',
'ಫರಂಗಿಮಣೆ',
'ಫರಮಾಯಿಶಿ',
'ಫರಮಾಯಿಷಿ',
'ಫರಮಾಯಿಸು',
'ಫರಶಿಕಲ್ಲು',
'ಫರಸುಗಲ್ಲು',
'ಫಲಜ್ಯೋತಿಷ',
'ಫಲದಾಯಕ',
'ಫಲಪೂರಕ',
'ಫಲಮಂಜರಿ',
'ಫಲವಳಿಗೆ',
'ಫಲಶೋಭನ',
'ಫಲಹೀನತೆ',
'ಫಲಾನುಮೇಯ',
'ಫಲಾಭಿಸಂಧಿ',
'ಫವುಚಾಯಿಸು',
'ಫಳಫಳನೆ',
'ಫಳಫಳಿಸು',
'ಫಳಮಂಜರಿ',
'ಫಳವಳಿಗೆ',
'ಫಳಹೀನತೆ',
'ಫಳೆಯಕಾಱ',
'ಫಾಜೀಲತನ',
'ಫಾಯದೇಶೀರ',
'ಫಾಯಿದೇಶೀರ',
'ಫಿರಂಗಿಉಬ್ಬೆ',
'ಫಿರಂಗಿಚಕ್ಕಿ',
'ಫಿರಂಗಿಚೆಕ್ಕೆ',
'ಫಿರಂಗಿರೋಗ',
'ಫಿರಂಗಿಶೂಲೆ',
'ಫಿರಂಗಿಹುಣ್ಣು',
'ಫಿರ್ಯಾದುದಾರ',
'ಫುಟ್‍ಲಾಯರಿ',
'ಫುಟ್‍ಲಾಯರು',
'ಫೂಟ್‍ಲಾಯರಿ',
'ಫೂಟ್‍ಲಾಯರು',
'ಫೂಟಮಾಗಣಿ',
'ಫೂಟಲಾಯರಿ',
'ಫೂಟಲಾಯರು',
'ಫೂಟುತೀರ್ಮಾನ',
'ಬಂಕುಡಿಗಾಲು',
'ಬಂಕುಳಿಕಾಲು',
'ಬಂಗಾರಕಡ್ಡಿ',
'ಬಂಗಾರಕಾಯಿ',
'ಬಂಗಾರಚೌಕಿ',
'ಬಂಗಾರಸಣ್ಣ',
'ಬಂಗಾರಹುಡಿ',
'ಬಂಗಾಲಿತನ',
'ಬಂಗಾಳಜಾಲಿ',
'ಬಂಗಾಳಪಚ್ಚೆ',
'ಬಂಗಾಳವಿದ್ಯೆ',
'ಬಂಗಾಳಿತನ',
'ಬಂಜರುಗಾಡು',
'ಬಂಜರುಭೂಮಿ',
'ಬಂಜೆವಡಿಸು',
'ಬಂಜೆವಾಗಲ್',
'ಬಂಜೆವಾಗಲ',
'ಬಂಜೆಹಾಗಲ',
'ಬಂಡವಾಳಿಗ',
'ಬಂಡಾಯಗಾರ',
'ಬಂಡಾಯಗಾರ್ತಿ',
'ಬಂಡಾಱಕಾಱ',
'ಬಂಡಿಬಸವ',
'ಬಂಡುಗಾರಿಕೆ',
'ಬಂದಿಗಟ್ಟಿಸು',
'ಬಂದಿಗೆಕೋಲು',
'ಬಂದಿಗೊಳಿಸು',
'ಬಂದಿಗ್ರಹಣ',
'ಬಂದಿವಿಡಿಹ',
'ಬಂದೀಕರಣ',
'ಬಂದೀಕರಿಸು',
'ಬಂಧನಕಾರಿ',
'ಬಂಧನಾಗಾರ',
'ಬಂಧಮೋಚನ',
'ಬಂಧಮೋಚನಿ',
'ಬಂಧುಜೀವಕ',
'ಬಂಧುರವಡೆ',
'ಬಂಧುರವಿಡಿ',
'ಬಂಧುಹನನ',
'ಬಂಬಲ್ದಗುಳ್',
'ಬಕಬಂಧನ',
'ಬಕಾಳಭಾತು',
'ಬಕ್ಕಂಬಯಲ್',
'ಬಕ್ಕಬಯಲ್',
'ಬಕ್ಕಬಯಲು',
'ಬಕ್ಕಬೋರಲು',
'ಬಕ್ಕುಡಿಗುಟ್ಟು',
'ಬಕ್ಕುಡಿಗೆಯ್',
'ಬಕ್ಕೆವಲಸು',
'ಬಕ್ಕೆಹಲಸು',
'ಬಗನೆಕಳ್ಳು',
'ಬಗಬಗನೆ',
'ಬಗರ್ ಬಡ್ಡಿ',
'ಬಗರ್‍ ಬಡ್ಡಿ',
'ಬಗರ್ ರಜ',
'ಬಗರ್ ಹುಕುಂ',
'ಬಗರ್ ಹುಕ್ಕುಂ',
'ಬಗಲಬಂಡಿ',
'ಬಗಲಬಂಡೆ',
'ಬಗಲುಕಸೆ',
'ಬಗಲುಚೀಲ',
'ಬಗಲುಬಂಡಿ',
'ಬಗಲುಬಂದು',
'ಬಗಸೆಗಣ್',
'ಬಗಸೆಗಣು',
'ಬಗಸೆಗಣ್ಣು',
'ಬಗಳಾಮುಖಿ',
'ಬಗುಳುಸನ್ನಿ',
'ಬಗೆಗಿಡಿಸು',
'ಬಗೆಗುರುಡು',
'ಬಗೆಗೆಡಿಸು',
'ಬಗೆಗೊಳಿಸು',
'ಬಗೆಯಳಲು',
'ಬಗೆಯೞಲ್',
'ಬಗೆಯೆಣಿಕೆ',
'ಬಗೆಯೊಡೆಯ',
'ಬಗೆವುಗಿಸು',
'ಬಗೆವೆರಸು',
'ಬಗೆಹರಿಸು',
'ಬಗ್ಗಂದೊವಲ್',
'ಬಗ್ಗದೊವಲ್',
'ಬಗ್ಗದೊವಲ',
'ಬಗ್ಗದೊವಲು',
'ಬಚ್ಚಣೆವಾತು',
'ಬಚ್ಚಣೆವೆಱು',
'ಬಚ್ಚಬಯಲ್',
'ಬಚ್ಚಬಯಲು',
'ಬಚ್ಚಬರಿದು',
'ಬಚ್ಚಬರಿಯ',
'ಬಚ್ಬಱಿದಾಗು',
'ಬಚ್ಚಬಱಿಯ',
'ಬಚ್ಚಲುಕೋಣೆ',
'ಬಚ್ಚಲುಗೋಣಿ',
'ಬಚ್ಚಲುಮನೆ',
'ಬಜೆಕರಕು',
'ಬಜೆಕರುಕು',
'ಬಟಾಯಿಭೂಮಿ',
'ಬಟ್ಟಗಡಲೆ',
'ಬಟ್ಟಗಡಸು',
'ಬಟ್ಟಬಯಲ್',
'ಬಟ್ಟಬಯಲು',
'ಬಟ್ಟಮಲ್ಲಿಗೆ',
'ಬಟ್ಟಲುಗಣ್ಣು',
'ಬಟ್ಟೆದೋಱಿಸು',
'ಬಟ್ಟೆಹರಜ',
'ಬಡಕರಿಸು',
'ಬಡಕಾಯಿಸು',
'ಬಡಗನಾಡು',
'ಬಡಗಿವೆಸ',
'ಬಡಗಿವೊಲ',
'ಬಡಗೆಲಸ',
'ಬಡಬಗ್ಗರು',
'ಬಡಬಡಕ',
'ಬಡಬಡನೆ',
'ಬಡಬಡಾಗು',
'ಬಡಬಡಿಕೆ',
'ಬಡಬಡಿಸು',
'ಬಡಬಶಿಖಿ',
'ಬಡಬಾನಲ',
'ಬಡಬಾನಳ',
'ಬಡವಾನಳ',
'ಬಡವೊಡಲ್',
'ಬಡವೊಡಲು',
'ಬಡಾಯಿಕೊಚ್ಚು',
'ಬಡಾಯಿಗಾರ',
'ಬಡಾಯಿಹೊಡೆ',
'ಬಡಿಗೆಕಾಱ',
'ಬಡಿಗೆಕೊಳ್',
'ಬಡಿಗೆಗೊಳ್',
'ಬಡಿಗೆಗೋಲು',
'ಬಡಿಗೆವಲೆ',
'ಬಡಿತಂಗಾಸು',
'ಬಡಿತಂಗೊಳ್',
'ಬಡ್ಡಗಿವಾಳ',
'ಬಡ್ಡುಕುದುರೆ',
'ಬಣಂಜುಧರ್ಮ',
'ಬಣಜಿಗಿತಿ',
'ಬಣಜಿಗಿತ್ತಿ',
'ಬಣಜುಧರ್ಮ',
'ಬಣ್ಣಂಬಡಿಸು',
'ಬಣ್ಣಗನಸು',
'ಬಣ್ಣಗಬ್ಬಿಗ',
'ಬಣ್ಣಗಾರಿಕೆ',
'ಬಣ್ಣಗಾಱಿಕೆ',
'ಬಣ್ಣಗುದುರೆ',
'ಬಣ್ಣಗುರುಡ',
'ಬಣ್ಣಗುರುಡಿ',
'ಬಣ್ಣಗುರುಡು',
'ಬಣ್ಣಗೆಡಿಸು',
'ಬಣ್ಣಗೊರಲ್',
'ಬಣ್ಣಗೊಳಿಸು',
'ಬಣ್ಣಗೋರಟೆ',
'ಬಣ್ಣನೆಗಾರ',
'ಬಣ್ಣನೆಗಾರ್ತಿ',
'ಬಣ್ಣನೆನುಡಿ',
'ಬಣ್ಣನೆವಾತು',
'ಬಣ್ಣನೆವೆಱು',
'ಬಣ್ಣಬಣ್ಣಿಗೆ',
'ಬಣ್ಣಬಾಸಿಗ',
'ಬಣ್ಣವಟ್ಟಿಗೆ',
'ಬಣ್ಣವಣ್ಣಿಗೆ',
'ಬಣ್ಣವಾಸಿಗ',
'ಬಣ್ಣವೇರಿಸು',
'ಬಣ್ಣವೇಱಿಸು',
'ಬಣ್ಣಾಬಣ್ಣಿಕೆ',
'ಬಣ್ಣಾವಣ್ಣಿಗೆ',
'ಬಣ್ಣಿಗೆಗಯ್ದು',
'ಬಣ್ಣಿಗೆಗಾಲು',
'ಬಣ್ಣಿಗೆಗೊಳ್',
'ಬಣ್ಣಿಗೆಗೊಳು',
'ಬಣ್ಣಿಗೆದಪ್ಪು',
'ಬಣ್ಣಿಗೆವಡೆ',
'ಬಣ್ಣಿಗೆವೆಱು',
'ಬತಗೆಡಿಸು',
'ಬತ್ತಲೆಗಣ್ಣು',
'ಬತ್ತಲೆಯಿರ್',
'ಬತ್ತೀಸಕಲೆ',
'ಬತ್ತೀಸರಾಗ',
'ಬತ್ತುಂಬಯನ್',
'ಬತ್ತುವಸುರು',
'ಬದಗುಗೆಯ್',
'ಬದಗುಳಿಗ',
'ಬದಣೆಗಾಯಿ',
'ಬದನೆಕಾಯ್',
'ಬದನೆಕಾಯಿ',
'ಬದನೆಕುತ್ತಿ',
'ಬದಲಾಯಿಸು',
'ಬದಲಾವಣೆ',
'ಬದಲಿಭತ್ಯ',
'ಬದಲಿರಜೆ',
'ಬದಲುತ್ತರ',
'ಬದಲುಮಾತು',
'ಬದುಕುವಂತ',
'ಬದ್ದಬದಸು',
'ಬದ್ದುಗೆದಾರ',
'ಬದ್ಧಕಂಕಣ',
'ಬದ್ಧಕತನ',
'ಬದ್ಧಗೊಳಿಸು',
'ಬದ್ಧಭೂಮಿಕ',
'ಬದ್ಧಭೋಜನ',
'ಬದ್ಧಭ್ರುಕುಟಿ',
'ಬದ್ಧವಿರೋಧ',
'ಬದ್ಧವಿರೋಧಿ',
'ಬನಗತ್ತಲೆ',
'ಬನಗಳ್ತಲೆ',
'ಬನಗುತ್ತಿಗೆ',
'ಬನನೇರಳೆ',
'ಬನವಳಿಗ',
'ಬನಶಂಕರಿ',
'ಬನಶಿಗೋದಿ',
'ಬನಸಂಪಗೆ',
'ಬನಸಿಗೋದಿ',
'ಬನಹಿಪ್ಪಲಿ',
'ಬನ್ನಂಬಡಿಸು',
'ಬನ್ನಣೆಗಾರ್ತಿ',
'ಬನ್ನಣೆಗಾಱ',
'ಬನ್ನಣೆಗೆಯ್',
'ಬನ್ನಣೆನುಡಿ',
'ಬನ್ನಣೆವಾತು',
'ಬನ್ನಪಡಿಸು',
'ಬನ್ನಬಡಿಸು',
'ಬನ್ನವಡಿಸು',
'ಬಯಂಬಡಿಸು',
'ಬಯಕೆವೆಣ್',
'ಬಯಲುದೊರೆ',
'ಬಯಲುದೊಱೆ',
'ಬಯಲುನಾಡು',
'ಬಯಲುಬಂಡಿ',
'ಬಯಲುಸೀಮೆ',
'ಬಯಲ್ಗೊಳಿಸು',
'ಬಯಲ್ದಾವರೆ',
'ಬಯಲ್ಪಡಿಸು',
'ಬಯಲ್ವೊನಲ್',
'ಬಯಿಸಣಿಗೆ',
'ಬಯ್ತಲೆದೆಗೆ',
'ಬಯ್ತಲೆವಣಿ',
'ಬರಕಂದಾಜ',
'ಬರಗರಗ',
'ಬರಗರುಗ',
'ಬರಗುಕಡ್ಡಿ',
'ಬರಡುಗಾಲ',
'ಬರಡುತನ',
'ಬರತರಪು',
'ಬರತರಫ್',
'ಬರತರಫು',
'ಬರಬಿಸಿಲು',
'ಬರಲುಕಡ್ಡಿ',
'ಬರಲುಕೊಂಬೆ',
'ಬರಲುಗಿಡ',
'ಬರಲುವೋಗು',
'ಬರವಣಿಗ',
'ಬರವಣಿಗೆ',
'ಬರವಸಿಗ',
'ಬರಸಿಡಿಲು',
'ಬರಹಗಾತಿ',
'ಬರಹಗಾರ',
'ಬರಹಗಾರ್ತಿ',
'ಬರಾಬರಿಗೈ',
'ಬರಿಗಡಗ',
'ಬರಿಯೆಲುವು',
'ಬರಿವದಣೆ',
'ಬರಿವದನೆ',
'ಬರಿವೊನಲ್',
'ಬರಿವೊನಲು',
'ಬರಿಸದೆಱೆ',
'ಬರಿಸವಳ',
'ಬರುಗನಸು',
'ಬರುಮರುಕ',
'ಬರುವದಣೆ',
'ಬರೆಗುಡುಹು',
'ಬರೆಪಂಬೊಗು',
'ಬರೆಪಕಾಱ',
'ಬರೆವಣಿಗೆ',
'ಬರ್ಕುಡಿಗುಟ್ಟು',
'ಬರ್ಕುಡಿಗೆಯ್',
'ಬರ್ಕೆವಲಸು',
'ಬರ್ದಿಲನಾಡು',
'ಬರ್ದಿಲವಟ್ಟೆ',
'ಬರ್ದುಕುಗೆಯ್',
'ಬಲಂಬೆರಸು',
'ಬಲಕಾಯಿಸು',
'ಬಲಕುಟಿಕೆ',
'ಬಲಗರ್ವಿತ',
'ಬಲಗುಣಿಕೆ',
'ಬಲಗೈಬಂಟ',
'ಬಲಗೊಳಿಸು',
'ಬಲದಲನ',
'ಬಲಪಡಿಸು',
'ಬಲಪ್ರಯೋಗ',
'ಬಲಭದ್ರಿಕೆ',
'ಬಲಮತ್ತತೆ',
'ಬಲಮಾಪಕ',
'ಬಲರಕ್ಕಸಿ',
'ಬಲರಾಕ್ಷಸ',
'ಬಲವಂತಿಕೆ',
'ಬಲವತ್ತಿಕೆ',
'ಬಲವರ್ಧಕ',
'ಬಲವರ್ಧನ',
'ಬಲವಿಹೀನ',
'ಬಲವಿಹೀನೆ',
'ಬಲಹೀನತೆ',
'ಬಲಾತ್ಕರಿಸು',
'ಬಲಾಧಿಕೃತ',
'ಬಲಾರಾಕ್ಷಸಿ',
'ಬಲಾವಲೇಪ',
'ಬಲಿಕೆಅನ್ನ',
'ಬಲಿಕೆವಂತ',
'ಬಲಿಗೆದಱ್',
'ಬಲಿಗೊರಡು',
'ಬಲಿಗೊಲಿಸು',
'ಬಲಿಪಾಡ್ಯಮಿ',
'ಬಲಿಬಂದ್ರಾಕ್ಷಿ',
'ಬಲಿಬಂಧಾಕ್ಷಿ',
'ಬಲಿಭೋಜನ',
'ಬಲಿವದನೆ',
'ಬಲಿಸ್ತವನ',
'ಬಲಿಸ್ನಪನ',
'ಬಲಿಹರಣ',
'ಬಲೀಂದ್ರಪೂಜೆ',
'ಬಲುಕರಿಸು',
'ಬಲುಕುಣಿಕೆ',
'ಬಲುಗಡಿಯ',
'ಬಲುಗುಣಿಕೆ',
'ಬಲುಗೈತನ',
'ಬಲುದಗಹು',
'ಬಲುದಡಿಗ',
'ಬಲುಮೆಕಾಱ',
'ಬಲುಮೆಗಾಱ',
'ಬಲುಹುಗುಂದು',
'ಬಲೂತಿದಾರ',
'ಬಲೋನ್ಮತ್ತತೆ',
'ಬಲ್ಕಣಿತನ',
'ಬಲ್ಲರಕ್ಕಸಿ',
'ಬಲ್ಲಲಮರ',
'ಬಲ್ಲಾಳಗಿತ್ತಿ',
'ಬಲ್ಲೆಹಕಾಱ',
'ಬವಕರಣ',
'ಬವಣಿಗೊಳ್',
'ಬವಣಿಗೊಳು',
'ಬವಣಿಗೊಳ್ಳು',
'ಬವಣಿಬಡು',
'ಬವಣಿಯೇಱು',
'ಬವಣೆಗೊಳು',
'ಬವಣೆಗೊಳ್ಳು',
'ಬವಣೆಪಡು',
'ಬವಣೆಬಡು',
'ಬವರಂಗೆಯ್',
'ಬವರಂಗೊಳ್',
'ಬವರಗಾರ',
'ಬವರಮುಖ',
'ಬವರಿಗೊಡು',
'ಬವರಿಗೊಳು',
'ಬವರಿರೇಖೆ',
'ಬವರಿವರಿ',
'ಬವಸೆಗಾಣ್',
'ಬವಸೆಗೆಯ್',
'ಬವಳಿಗೊಳ್ಳು',
'ಬವಳಿವರಿ',
'ಬಸಂಗೊಳಿಸು',
'ಬಸಂತಮಾಸ',
'ಬಸಗಿಡಿಸು',
'ಬಸನಗಾಱ',
'ಬಸಬಸನೆ',
'ಬಸರಿಗೋಳಿ',
'ಬಸವಳಿಸು',
'ಬಸಿರುಬೇನೆ',
'ಬಸಿಱುಬೇನೆ',
'ಬಸುಱಿೞಿಸು',
'ಬಸುಱುದುಂಬು',
'ಬಸುಱುಶೂಲೆ',
'ಬಸ್ಸುನಿಲ್ದಾಣ',
'ಬಹಿಃಕರಣ',
'ಬಹಿಃಕರಿಸು',
'ಬಹಿಕರಿಸು',
'ಬಹಿರಕರ್ಮ',
'ಬಹಿರಿಂದ್ರಿಯ',
'ಬಹಿರ್ಮುಖತೆ',
'ಬಹಿರ್ವರ್ತನೆ',
'ಬಹಿರ್ವಳಿಯ',
'ಬಹಿಷ್ಕರಿಸು',
'ಬಹುಕುಟುಂಬಿ',
'ಬಹುಪತಿತ್ವ',
'ಬಹುಪತ್ನೀತ್ವ',
'ಬಹುಭಾಷಣ',
'ಬಹುಭೂಮಿಕ',
'ಬಹುಮಂಜರಿ',
'ಬಹುಮತಿಸು',
'ಬಹುರೂಪಿಣಿ',
'ಬಹುವಚನ',
'ಬಹುವಾರಕ',
'ಬಹುವಾರ್ಷಿಕ',
'ಬಹುಸಂಖ್ಯಾತ',
'ಬಹುಳಪಕ್ಷ',
'ಬಹುಳವೃತ್ತಿ',
'ಬಹುಳಸ್ಥಿತಿ',
'ಬಹೂಪಮಾನ',
'ಬಳಕೆಗಾರ',
'ಬಳಕೆಗಾಱ',
'ಬಳಕೆದಾರ',
'ಬಳಕೆಮಾತು',
'ಬಳಕೆವಾತು',
'ಬಳಕೆವೆರು',
'ಬಳಗದೊತ್ತು',
'ಬಳಗಧಾರೆ',
'ಬಳಗಮಿಕ್ಕು',
'ಬಳಗವಿಕ್ಕು',
'ಬಳಗುನಿಕೆ',
'ಬಳಪಂಗೊಳ್',
'ಬಳಬಳನೆ',
'ಬಳಬಳಿಕೆ',
'ಬಳಬಳಿಕ್ಕೆ',
'ಬಳಲುನೋಟ',
'ಬಳಲುಮುಡಿ',
'ಬಳಲುಹೆಜ್ಜೆ',
'ಬಳಲ್ಕೆಗಳೆ',
'ಬಳಲ್ಗಾಮಿಲೆ',
'ಬಳಲ್ದುಱುಬು',
'ಬಳವಡಿಕೆ',
'ಬಳವಿವಡೆ',
'ಬಳಸುಗೆಯ್ಮೆ',
'ಬಳಸುದಾರಿ',
'ಬಳಿಗೆಲಸ',
'ಬಳಿಗೆಲಸಿ',
'ಬಳಿನಕಲು',
'ಬಳಿನೀರ್ಗುಡಿ',
'ಬಳಿನೀರ್ಗೊಳ್',
'ಬಳಿಸಲಿಸು',
'ಬಳುಕುನಡೆ',
'ಬಳುಕುನೀರು',
'ಬಳೆಗಾಱಿಕೆ',
'ಬಳೆತೊಡಿಸು',
'ಬಳೆದೊಡಿಸು',
'ಬಳ್ಕುಡಿಗೆಯ್',
'ಬಳ್ಳಾರಿಜಾಲಿ',
'ಬಳ್ಳಿಗವರ್ತೆ',
'ಬಳ್ಳಿಗರಿಕೆ',
'ಬಳ್ಳಿಗಱುಕೆ',
'ಬಳ್ಳಿಗೊಳಿಸು',
'ಬಳ್ಳಿತುಱುಚಿ',
'ಬಳ್ಳಿದುಱುಚೆ',
'ಬಳ್ಳಿದುರ್ಲಬ',
'ಬಳ್ಳಿಹುಸಿಕ',
'ಬಱಡುಗಱೆ',
'ಬಱಲುಗೊಳ್',
'ಬಱಸಿಡಿಲ್',
'ಬಱಸಿಡಿಲು',
'ಬಱುವಾನಿಸ',
'ಬೞಲ್ಕೆಗಳೆ',
'ಬೞಲ್ಕೆವೋಗು',
'ಬೞಸುವಿಡು',
'ಬೞಿನೀರ್ಗುಡಿ',
'ಬೞಿಸಲುಹ',
'ಬಾಕುಳಿಕತ್ವ',
'ಬಾಕುಳಿಗಣ್',
'ಬಾಕುಳಿಗೊಳ್',
'ಬಾಕುಳಿತನ',
'ಬಾಗರಣಿಗ',
'ಬಾಗಿಲುಗಾಹಿ',
'ಬಾಗಿಲುಜೋಳ',
'ಬಾಗಿಲುತಡೆ',
'ಬಾಗಿಲುದಡೆ',
'ಬಾಗಿಲುವಾಡ',
'ಬಾಗುಮರಿಗೆ',
'ಬಾಜನೆಕಾಱ',
'ಬಾಜನೆಗಬ್ಬ',
'ಬಾಜಾಬಜಂತ್ರಿ',
'ಬಾಜಾರಬೀದಿ',
'ಬಾಜಾರಮುಖ್ಯ',
'ಬಾಡಗೆಕಾಱ',
'ಬಾಡಗೆಗೊಳ್',
'ಬಾಡಬಾನಲ',
'ಬಾಡಬಾನಳ',
'ಬಾಡವಾನಳ',
'ಬಾಡಿಗೆದಾರ',
'ಬಾಡಿಗೆಮನೆ',
'ಬಾಣಂತಿಕಾಯಿ',
'ಬಾಣಂತಿಖಾರ',
'ಬಾಣಂತಿಜ್ವರ',
'ಬಾಣಂತಿತನ',
'ಬಾಣಂತಿಬಾಳ',
'ಬಾಣಂತಿಬೋಳ',
'ಬಾಣಂತಿಸನ್ನಿ',
'ಬಾಣದಾಯಕ',
'ಬಾಣಸಂಧಾನ',
'ಬಾಣಸಗಿತ್ತಿ',
'ಬಾಣಸಮನೆ',
'ಬಾಣಸವನೆ',
'ಬಾಣಸವಿದ್ಯೆ',
'ಬಾಣಸವಿರು',
'ಬಾಣಸಿಗಿತಿ',
'ಬಾಣಸಿಗಿತ್ತಿ',
'ಬಾಣಸಿತನ',
'ಬಾಣಸುಗೆಯ್',
'ಬಾತಮಿದಾರ',
'ಬಾತಮೀದಾರ',
'ಬಾದಣಂಗೈಸು',
'ಬಾದಣದೆಗೆ',
'ಬಾದರರೂಪ',
'ಬಾದರಾಯಣ',
'ಬಾದಾಮಿಮಾವು',
'ಬಾದಾಮಿಹಲ್ವ',
'ಬಾದಾಮಿಹಾಲು',
'ಬಾದೆಯ ಹುಲ್ಲು',
'ಬಾಧೆಬಡಿಸು',
'ಬಾನುಲಿಕೇಂದ್ರ',
'ಬಾಯಾಳಿತನ',
'ಬಾಯಿಕಡಾಣಿ',
'ಬಾಯಿಗಾಳೆಗ',
'ಬಾಯಿಗೂಡಿಸು',
'ಬಾಯಿಚಪಲ',
'ಬಾಯಿಜಾಳಿಗೆ',
'ಬಾಯಿಬಡಕ',
'ಬಾಯಿಬಡಿಕ',
'ಬಾಯಿಬಡುಕ',
'ಬಾಯಿಬಡುಕಿ',
'ಬಾಯಿಬಸಳೆ',
'ಬಾಯಿಬೂಟಕ',
'ಬಾಯಿಮುಚ್ಚಿಸು',
'ಬಾಯುಪಚಾರ',
'ಬಾಯ್ಕಡಿಗೊಳ್',
'ಬಾಯ್ಗೇಡಿತನ',
'ಬಾಯ್ವಱೆಗುಟ್ಟು',
'ಬಾರ್‍ಕಛೇರಿ',
'ಬಾರಕಚೇರಿ',
'ಬಾರವಟ್ಟಿಗೆ',
'ಬಾರಸಂಗೆಯ್',
'ಬಾರಾಅಲುತೆ',
'ಬಾರಾಬಂಗಾಲಿ',
'ಬಾರಾಬಂಗಾಳಿ',
'ಬಾರಾಬನೂತಿ',
'ಬಾರಾಬನೋತಿ',
'ಬಾರಾಬಲುತೆ',
'ಬಾರಾಬಲೂತಿ',
'ಬಾರಿಲುಮಕ್ಕಿ',
'ಬಾರುಸಿಪಾಯಿ',
'ಬಾಲಂತಿತನ',
'ಬಾಲಕತನ',
'ಬಾಲತನಯ',
'ಬಾಲದೆರಿಗೆ',
'ಬಾಲದೆಱಿಗೆ',
'ಬಾಲಭಾಷಿತ',
'ಬಾಲಮೆಣಸು',
'ಬಾಲರಾಕ್ಷಸ',
'ಬಾಲರಾಕ್ಷಸಿ',
'ಬಾಲವಿಧವೆ',
'ಬಾಲಸಂಗಡ',
'ಬಾಲಸನ್ಯಾಸಿ',
'ಬಾಲೇಯಶಾಕ',
'ಬಾಲ್ಯವಿನೋದ',
'ಬಾಲ್ಯವಿವಾಹ',
'ಬಾವರಿಗೊಡು',
'ಬಾವರಿಯಿಡು',
'ಬಾವರಿವುಗು',
'ಬಾವುದಿವಸ',
'ಬಾವುದೆವಸ',
'ಬಾವುಲಿಗಾಱ',
'ಬಾಷೀಕಾಗದ',
'ಬಾಷ್ಕಳಂಗೆಯ್',
'ಬಾಷ್ಕಳಗೆಡೆ',
'ಬಾಸಟಮಿಡು',
'ಬಾಸಿಗಂಗಟ್ಟು',
'ಬಾಸಗಹುವ್ವು',
'ಬಾಸುರಕಬ್ಬು',
'ಬಾಸುಳುವಡೆ',
'ಬಾಸ್ತಿಮಲ್ಲಿಗೆ',
'ಬಾಹಿರಗಿತ್ತಿ',
'ಬಾಹಿರತನ',
'ಬಾಹಿರವಟ್ಟ',
'ಬಾಹಿರಸುಂಕ',
'ಬಾಹುಗತ್ತರಿ',
'ಬಾಹಪೂರಕ',
'ಬಾಹಪೂರಯ',
'ಬಾಹುವಳಯ',
'ಬಾಹ್ಯಪ್ರಪಂಚ',
'ಬಾಹ್ಯಪ್ರಮಾಣ',
'ಬಾಹ್ಯವ್ಯಾಪಾರ',
'ಬಾಹ್ಯಸೌಂದರ್ಯ',
'ಬಾಳಂಗೆಣಸೆ',
'ಬಾಳಂತಪಣ',
'ಬಾಳನಯನ',
'ಬಾಳವಾಳಿಗೆ',
'ಬಾಳ್ದಲೆಗೊಳ್',
'ಬಾಳ್ದಲೆವಿಡಿ',
'ಬಾಳ್ವಲಿಗುಡು',
'ಬಾಳ್ವೆಗೆಡಿಸು',
'ಬಾೞ್ದಲೆಗೊಳ್',
'ಬಾೞ್ದಲೆವರ್',
'ಬಾೞ್ದಲೆವಿಡಿ',
'ಬಿಂಕಂಗೆಡಿಸು',
'ಬಿಂಗುಂಡುಗೊಳ್',
'ಬಿಂದುಮಾಧವ',
'ಬಿಂಬಕಲ್ಪನೆ',
'ಬಿಂಬಗ್ರಾಹಕ',
'ಬಿಕರಿದಾರ',
'ಬಿಕಾರಿತನ',
'ಬಿಕ್ಕಲಂದಾರ',
'ಬಿಕ್ಕುಳುಬೇನೆ',
'ಬಿಕ್ಕುಳುರೋಗ',
'ಬಿಗಡಾಯಿಸು',
'ಬಿಗಿಗೊಳಿಸು',
'ಬಿಗಿಪಡಿಸು',
'ಬಿಗಿಯುಡುಪು',
'ಬಿಗುರ್ಪುಗೊಳ್',
'ಬಿಗುರ್ವರಿಜ',
'ಬಿಗುವುಗುಂದು',
'ಬಿಗುಹಡಗು',
'ಬಿಗುಹುಕಾಱ',
'ಬಿಗುಹುಗುಂದು',
'ಬಿಗುಹುಗೊಳ್',
'ಬಿಘಡಾಯಿಸು',
'ಬಿಚ್ಚುಗನ್ನಡ',
'ಬಿಚ್ಚುಫಿರಂಗಿ',
'ಬಿಚ್ಚುಮನಸ್ಸು',
'ಬಿಜಯಂಗೆಯ್',
'ಬಿಜಯಂಗೆಯಿ',
'ಬಿಜಯಂಗೆಯ್ಸು',
'ಬಿಜಯಂಗೈಯಿ',
'ಬಿಜಯಂಗೈಸು',
'ಬಿಜ್ಜಣವಿಕ್ಕು',
'ಬಿಜ್ಜಳವಿಕ್ಕು',
'ಬಿಜ್ಜೆಗಲಿಸು',
'ಬಿಟ್ಟಿಕೆಲಸ',
'ಬಿಟ್ಟಿಗೆಲಸ',
'ಬಿಟ್ಟಿಬೆಸನ',
'ಬಿಟ್ಟಿಯೆಸಗು',
'ಬಿಟ್ಟೇಱುಕಾಱ',
'ಬಿಡಲವಣ',
'ಬಿಡಾರುವಾರ',
'ಬಿಡಿಕಸಬಿ',
'ಬಿಡಿದಿವಸ',
'ಬಿಡಿಬೇಸಱು',
'ಬಿಡಿಯುತ್ತರ',
'ಬಿಡುಗಾವಲಿ',
'ಬಿಡುಗುರುಳು',
'ಬಿಡುಗೂದಲು',
'ಬಿಡುಗೆಗುಡು',
'ಬಿಡುಗೆಗೊಡು',
'ಬಿಡುಗೆಗೊಳ್',
'ಬಿಡುಗೆಚ್ಚಲು',
'ಬಿಡುಗೆಲಸ',
'ಬಿಡುಗೆವಡು',
'ಬಿಡುತೆಗೊಳ್ಳು',
'ಬಿಡುತೆಯಾನ್',
'ಬಿಡುತೆವೆಱು',
'ಬಿಡುದೇವಸ್ವ',
'ಬಿಡುಬಿಸಿಲು',
'ಬಿಡುಲವಣ',
'ಬಿಡುವಯಣ',
'ಬಿಡುವುಗೊಳ್',
'ಬಿಡೆಯಂಬಡೆ',
'ಬಿತ್ತರಂಗೆಯ್',
'ಬಿತ್ತರಂಬಡೆ',
'ಬಿತ್ತರಂಬೆಱು',
'ಬಿತ್ತರವಡೆ',
'ಬಿತ್ತರವೆಱು',
'ಬಿತ್ತರಿತನ',
'ಬಿದಿಗೆವೆಱೆ',
'ಬಿದಿರುಮೆಳೆ',
'ಬಿದಿರುಸೊಪ್ಪು',
'ಬಿದುಗದಿರ್',
'ಬಿದ್ದಣಂಬೇಳು',
'ಬಿದ್ದಿನಂಗೆಯ್',
'ಬಿನದಂಬಡೆ',
'ಬಿನದಗೊಳು',
'ಬಿನದವಾಡು',
'ಬಿನದಸಾಲೆ',
'ಬಿನುಗುತನ',
'ಬಿನ್ನಣಂದೋಱು',
'ಬಿನ್ನಣಂಬಡೆ',
'ಬಿನ್ನಣಕಾತಿ',
'ಬಿನ್ನಣಗಾರ್ತಿ',
'ಬಿನ್ನಣದೋಱು',
'ಬಿನ್ನಣವಡೆ',
'ಬಿನ್ನತಿಗೆಯ್',
'ಬಿನ್ನಪಂಗೆಯ್',
'ಬಿನ್ನವತ್ತಲೆ',
'ಬಿನ್ನವತ್ತಳೆ',
'ಬಿನ್ನಹಗೆಯ್',
'ಬಿನ್ನಾಣಗಾತಿ',
'ಬಿನ್ನಾಣಗಿತ್ತಿ',
'ಬಿನ್ನಾಣವತಿ',
'ಬಿಯಗತನ',
'ಬಿರಬಿರನೆ',
'ಬಿರಿಗಳಿಕೆ',
'ಬಿರುಗತ್ತಲು',
'ಬಿರುಗೂದಲು',
'ಬಿರುಬಿಸಿಲ್',
'ಬಿರುಬಿಸಿಲು',
'ಬಿಲವರಗ',
'ಬಿಲುಜಾಣಿಕೆ',
'ಬಿಲುದಿರುವು',
'ಬಿಲುದೆಗಹು',
'ಬಿಲುವಡಿಕೆ',
'ಬಿಲ್ಗಾರತನ',
'ಬಿಲ್ಗಾಱತನ',
'ಬಿಲ್ಲಪಕ್ಕಣ',
'ಬಿಲ್ಲಾಳುತನ',
'ಬಿಲ್ಲುಂಬೆರಗು',
'ಬಿಲ್ಲುಂಬೆಱಗು',
'ಬಿಲ್ಲುಗಾರಿಕೆ',
'ಬಿಲ್ಲುಬೆರಗು',
'ಬಿಲ್ಲೂರಿಹಾವು',
'ಬಿಲ್ಲೆಜವಾನ',
'ಬಿಸಕಂಠಿಕೆ',
'ಬಿಸಗೊರಲ',
'ಬಿಸಟಂಬರಿ',
'ಬಿಸಪ್ರಸೂನ',
'ಬಿಸಲಡಿಗೆ',
'ಬಿಸಿಗದಿರ್',
'ಬಿಸಿಗದಿರ',
'ಬಿಸಿನೆತ್ತರು',
'ಬಿಸಿಲಡಿಗೆ',
'ಬಿಸಿಲುಕೋಲು',
'ಬಿಸಿಲುಗೋಲು',
'ಬಿಸಿಲುದಕ',
'ಬಿಸಿಲುಮಚ್ಚು',
'ಬಿಸಿಲುಮಳೆ',
'ಬಿಸಿಲುವಳೆ',
'ಬಿಸಿಲುಹಣ್ಣು',
'ಬಿಸಿಲ್ಗುದುರೆ',
'ಬಿಸುಗದಿರ್',
'ಬಿಸುಗದಿರ',
'ಬಿಸುಗದಿರು',
'ಬಿಸುಡುಗಾರ್ತಿ',
'ಬಿಸುಬೂರಿಗೆ',
'ಬಿಸುವೂರಿಗೆ',
'ಬಿಸುಸುಯಿಲ್',
'ಬಿಸುಸುಯಿಲು',
'ಬಿಸುಹೂರಿಗೆ',
'ಬಿಳಲುಬಿಡು',
'ಬಿಳಿಅಗಿಲು',
'ಬಿಳಿಅಭ್ರಾಂಗ',
'ಬಿಳಿಉಮ್ಮತ್ತಿ',
'ಬಿಳಿಔಡಲ',
'ಬಿಳಿಕಗ್ಗಲಿ',
'ಬಿಳಿಕುಂತಲ',
'ಬಿಳಿಕುಂತಾಳು',
'ಬಿಳಿಕೆಸವು',
'ಬಿಳಿಕೇಪಲ',
'ಬಿಳಿಗಣಿಜೆ',
'ಬಿಳಿಗರಿಕೆ',
'ಬಿಳಿಗಱಿಕೆ',
'ಬಿಳಿಗುಂಬಳ',
'ಬಿಳಿಗುಗ್ಗಲ',
'ಬಿಳಿಗೆಣಸು',
'ಬಿಳಿಚಗಚೆ',
'ಬಿಳಿಚಗತೆ',
'ಬಿಳಿಜೀರಿಗೆ',
'ಬಿಳಿಡಾಮರ್',
'ಬಿಳಿತಾವರೆ',
'ಬಿಳಿತಿಗಡೆ',
'ಬಿಳಿತಿರುಪು',
'ಬಿಳಿದತ್ತೂರ',
'ಬಿಳಿದಾವರೆ',
'ಬಿಳಿನೈದಿಲೆ',
'ಬಿಳಿಪಾದರಿ',
'ಬಿಳಿಪೊಳಿಕು',
'ಬಿಳಿಬದ್ಧೆಲೆ',
'ಬಿಳಿಬರಲು',
'ಬಿಳಿಬಸರಿ',
'ಬಿಳಿಬಸುರಿ',
'ಬಿಳಿಬುಡ್ಲಿಗೆ',
'ಬಿಳಿಬೂರಗ',
'ಬಿಳಿಭೂತಾಳೆ',
'ಬಿಳಿಮಂದಾರ',
'ಬಿಳಿಮುತ್ತಲ',
'ಬಿಳಿಮೆಣಸು',
'ಬಿಳಿಯಾಬಲ್',
'ಬಿಳಿಯಾಬಲು',
'ಬಿಳಿರಕ್ಕಸಿ',
'ಬಿಳಿರುದ್ರಾಕ್ಷಿ',
'ಬಿಳಿಲೊದ್ದುಗ',
'ಬಿಳಿವಾರ್ಜಿಪೆ',
'ಬಿಳಿಸಂಪಿಗೆ',
'ಬಿಳಿಸಟ್ಟುಗ',
'ಬಿಳಿಸರಳಿ',
'ಬಿಳಿಸಾಸಿವೆ',
'ಬಿಳಿಸಾಸುವೆ',
'ಬಿಳಿಸುಲಿಗೆ',
'ಬಿಳಿಸೂಲಿಗೆ',
'ಬಿಳಿಸೆರಗು',
'ಬಿಳಿಹರಳು',
'ಬಿಳಿಹರಿವೆ',
'ಬಿಳುಪುಗಟ್ಟು',
'ಬಿಳುಮಲ್ಲಿಗೆ',
'ಬಿಱಿಬಿಱನೆ',
'ಬಿಱುಗತ್ತಲೆ',
'ಬಿಱುಬಿಸಿಲು',
'ಬಿಱುಬಿಱನೆ',
'ಬಿಱುಬುಗೊಳ್',
'ಬಿಱುಬುನುಡಿ',
'ಬಿಱುವಿಸಿಲ್',
'ಬೀಗದಮರ',
'ಬೀಜಗಣಿತ',
'ಬೀಜಪೂರಕ',
'ಬೀಜಾವಾಪನ',
'ಬೀಡಾಡಿತನ',
'ಬೀಡಿಕೆಗೊಳ್ಳು',
'ಬೀಡುಕಬ್ಬಿಣ',
'ಬೀಡುಗಲಹ',
'ಬೀಡುಪ್ರದೇಶ',
'ಬೀದಿಗಲಹ',
'ಬೀದಿಗಾವಲಿ',
'ಬೀದಿಬಸವ',
'ಬೀದಿಬಸವಿ',
'ಬೀದಿ ಭಿಕಾರಿ',
'ಬೀದಿವರಿಗ',
'ಬೀದಿವರಿಸು',
'ಬೀಭತ್ಸರಸ',
'ಬೀಯಗತನ',
'ಬೀರಗೂಳೆತ್ತು',
'ಬೀರಡವುಡೆ',
'ಬೀಸರಂಬೊಂದು',
'ಬೀಸರಂಬೊಗು',
'ಬೀಸರಂಬೋಗು',
'ಬೀಸರಂವೋಗು',
'ಬೀಸರವೋಗು',
'ಬೀಸರುಹೊಗು',
'ಬೀಸುಂಬೂರಿಗೆ',
'ಬೀಸುಗವಣೆ',
'ಬೀಸುಹೂರಿಗೆ',
'ಬೀಳಾನುವೃತ್ತಿ',
'ಬೀಳಿಲುಬಿಡು',
'ಬೀಳುಗತ್ತಲೆ',
'ಬೀಳುಗೆಡವು',
'ಬೀಳುಗೊಳಿಸು',
'ಬೀಱುಗುಣಿಕೆ',
'ಬುಗಬುಗನೆ',
'ಬುಟ್ಟಿಯುಡಿಪ್ಪೆ',
'ಬುಡಬುಡಕೆ',
'ಬುಡಬುಡಿಕೆ',
'ಬುಡವಳತಿ',
'ಬುಡುಬುಡಿಕ',
'ಬುಡುಬುಡಿಕೆ',
'ಬುಡುಬುಡಿಗ',
'ಬುಡುಬುಡಿಸು',
'ಬುಡುಬುಡುಕೆ',
'ಬುತ್ತಿಚಿಗುರು',
'ಬುತ್ತಿಯಡಿಬ್ಬ',
'ಬುದುಬುದಿಸು',
'ಬುದ್ದಿಗಲಿಸು',
'ಬುದ್ಧಜಾತಕ',
'ಬುದ್ಧದರ್ಶನ',
'ಬುದ್ಧವಿಹಾರ',
'ಬುದ್ಧಸಮಯ',
'ಬುದ್ಧಾವತಾರ',
'ಬುದ್ಧಿಕಲಿಸು',
'ಬುದ್ಧಿಗಲಿಸು',
'ಬುದ್ಧಿದರಿದ್ರ',
'ಬುದ್ಧಿದರಿದ್ರೆ',
'ಬುದ್ಧಿದಾರಿದ್ರ್ಯ',
'ಬುದ್ಧಿನಿರೂಪ',
'ಬುದ್ಧಿನಿಷ್ಠತೆ',
'ಬುದ್ಧಿಪೂರ್ವಕ',
'ಬುದ್ಧಿಭ್ರಮಣೆ',
'ಬುದ್ಧಿವಂತಿಕೆ',
'ಬುದ್ಧಿವಾನಸೆ',
'ಬುದ್ಧಿವಿಕಲ',
'ಬುದ್ಧಿವಿಕಲೆ',
'ಬುದ್ಧಿವಿಕಲ್ಪ',
'ಬುದ್ಧಿವಿಕಾರ',
'ಬುದ್ಧಿವಿಕಾಸ',
'ಬುದ್ಧಿವೈಕಲ್ಯ',
'ಬುದ್ಧಿಶಾಲಿನಿ',
'ಬುದ್ಧಿಶೂನ್ಯತೆ',
'ಬುದ್ಧಿಸಹಾಯ',
'ಬುದ್ಧಿಹೀನತೆ',
'ಬುದ್ಬುದನೇತ್ರ',
'ಬುಬ್ಬಣಚಾರ',
'ಬುಬ್ಬಣಾಚಾರ',
'ಬುಬ್ಬುಣಚಾರ',
'ಬುಬ್ಬುಣಾಚಾರ',
'ಬುರಗಿಬೊಟ್ಟು',
'ಬುರಬುರನೆ',
'ಬುರುಗುಕಾಯಿ',
'ಬುರುಗುಗಟ್ಟು',
'ಬುರುಗುಗಾಳೆ',
'ಬುರುಬುರನೆ',
'ಬುರುಬುರಿಸು',
'ಬುರುಸುಗಟ್ಟು',
'ಬುರುಸುಗೆಡು',
'ಬುಳಬುಳನೆ',
'ಬೂಟಕತನ',
'ಬೂಟಕವಾತು',
'ಬೂಟಾಟಂಗೂಡು',
'ಬೂತಗನ್ನಡಿ',
'ಬೂತುವಣಿಗ',
'ಬೂದಗುಂಬಳ',
'ಬೂದಿಕುಂಬಳ',
'ಬೂದಿಗುಂಬಳ',
'ಬೂದಿಬಡಕ',
'ಬೂದಿಬಡಿಕ',
'ಬೂದಿಬಡುಕ',
'ಬೂದುಗುಂಬಳ',
'ಬೂಪಳಗಾತಿ',
'ಬೂರಗಬಂಕ',
'ಬೂರಗೆಬಂಕೆ',
'ಬೂರವಟ್ಟಗೆ',
'ಬೂರುಗಬಂಕ',
'ಬೂರುಗೆಬಂಕೆ',
'ಬೃಂದಾರಕತೆ',
'ಬೃಂದಾವನಸ್ಥ',
'ಬೃಂಹಿತಂಗೆಯ್',
'ಬೃಹತೀಫಲ',
'ಬೃಹತೀಫಳ',
'ಬೃಹತ್ಕರಿಸು',
'ಬೆಂಕಿತುತ್ತೂರಿ',
'ಬೆಂಕಿಪಟ್ಟಣ',
'ಬೆಂಕಿಪೆಟ್ಟಿಗೆ',
'ಬೆಂಕಿಪೊಟ್ಟಣ',
'ಬೆಂಚಿಯಹಂಬು',
'ಬೆಂಡುಖರ್ಜೂರ',
'ಬೆಂಡುಮರಲ್',
'ಬೆಂಬಳಿಗೊಳ್',
'ಬೆಂಬಳಿವಿಡಿ',
'ಬೆಂಬೞಿಗೊಳ್',
'ಬೆಂಬೞಿವಿಡಿ',
'ಬೆಕ್ಕಟಗೆರೆ',
'ಬೆಕ್ಕಸಂಗೊಳ್',
'ಬೆಕ್ಕಸಂಬಡು',
'ಬೆಕ್ಕಸಂಬೆರೆ',
'ಬೆಕ್ಕಸಗೊಳ್',
'ಬೆಕ್ಕಸಬಡು',
'ಬೆಕ್ಕಸಮುಱು',
'ಬೆಕ್ಕಸವಡು',
'ಬೆಕ್ಕುಮಾಳಿಗೆ',
'ಬೆಗಡುಗೊಳ್',
'ಬೆಗಡುಗೊಳು',
'ಬೆಗಡುಗೊಳ್ಳು',
'ಬೆಗಳ್ಗೊಳಿಸು',
'ಬೆಜ್ಜರವಡು',
'ಬೆಜ್ಜರವಡೆ',
'ಬೆಟ್ಟಅವರೆ',
'ಬೆಟ್ಟಕುಂಬಳ',
'ಬೆಟ್ಟಗೆದಲು',
'ಬೆಟ್ಟಗೆದ್ದಲು',
'ಬೆಟ್ಟಗೊಸರಿ',
'ಬೆಟ್ಟತಂಗಡಿ',
'ಬೆಟ್ಟತಾವರೆ',
'ಬೆಟ್ಟದವರೆ',
'ಬೆಟ್ಟದಾವರೆ',
'ಬೆಟ್ಟದಾಲ್ಚಿನ್ನಿ',
'ಬೆಟ್ಟದೊಗಲಿ',
'ಬೆಟ್ಟಬೂರುಗ',
'ಬೆಟ್ಟಬೇಸಗೆ',
'ಬೆಟ್ಟಮಂಗರೆ',
'ಬೆಟ್ಟಮಂಜಡಿ',
'ಬೆಟ್ಟಮಲ್ಲಿಗೆ',
'ಬೆಟ್ಟವೇಸಗೆ',
'ಬೆಟ್ಟಸುಜ್ಜಲು',
'ಬೆಟ್ಟಹರಳು',
'ಬೆಟ್ಟಿಬೆನಕ',
'ಬೆಟ್ಟುವೊನಲ್',
'ಬೆಟ್ಟೆಗಾಣಿಸು',
'ಬೆಡಂಗಡರ್',
'ಬೆಡಂಗುಗಿಡು',
'ಬೆಡಂಗುವಡೆ',
'ಬೆಡಂಗುವೆಱು',
'ಬೆಡಗುಗಾತಿ',
'ಬೆಡಗುಗಾರ',
'ಬೆಣಚಿಕಲ್ಲು',
'ಬೆಣಚಿಗಲ್ಲು',
'ಬೆಣಚುಕಲ್ಲು',
'ಬೆಣಚುಗಲ್ಲು',
'ಬೆಣ್ಣೆಕಾಗದ',
'ಬೆಣ್ಣೆಗಡುಬು',
'ಬೆಣ್ಣೆಗರಗ',
'ಬೆಣ್ಣೆಗರುಗ',
'ಬೆಣ್ಣೆಗಾರಿಗೆ',
'ಬೆಣ್ಣೆದಲೆಗ',
'ಬೆಣ್ಣೆದಱಗು',
'ಬೆಣ್ಣೆಬಳಪ',
'ಬೆಣ್ಣೆಬಿದುರು',
'ಬೆತೆಗೊಳಿಸು',
'ಬೆತ್ತದ ಬಳ್ಳಿ',
'ಬೆತ್ತಲೆಗಣ್ಣು',
'ಬೆತ್ತವರಿಗೆ',
'ಬೆದಂಡೆಗಬ್ಬ',
'ಬೆದಗತನ',
'ಬೆದರುಗಣ್ಣು',
'ಬೆದರುನೋಟ',
'ಬೆದರುಬೊಂಬೆ',
'ಬೆದಱುಗಣ್',
'ಬೆದಱುಗಣ್ಣು',
'ಬೆದಱುನೋಟ',
'ಬೆದೆಗೂಡಿಸು',
'ಬೆದ್ದಲುಗಳೆ',
'ಬೆದ್ದಲುಪೈರು',
'ಬೆದ್ದಲೆಗಳೆ',
'ಬೆನ್ನುಗಾವಲು',
'ಬೆಪ್ಪತಕ್ಕಡಿ',
'ಬೆಪ್ಪುತಕ್ಕಡಿ',
'ಬೆಬ್ಬಳಂಗೊಳ್',
'ಬೆಬ್ಬಳಂಬೋಗು',
'ಬೆಬ್ಬಳಗೊಳ್',
'ಬೆಬ್ಬಳಗೊಳು',
'ಬೆಬ್ಬಳವೋಗು',
'ಬೆಬ್ಬಳಿಗಿತಿ',
'ಬೆಬ್ಬಳಿಗೊಳ್',
'ಬೆಬ್ಬಳಿಯೋಗು',
'ಬೆಬ್ಬಳಿವೋಗು',
'ಬೆಬ್ಬಳುಗೊಳ್ಳು',
'ಬೆಬ್ಬಳೆಯೋಗು',
'ಬೆಬ್ಬಳೆವೋಗು',
'ಬೆರಗುಗೊಳ್',
'ಬೆರಗುಗೊಳ್ಳು',
'ಬೆರಗುಬಡಿ',
'ಬೆರಗುವಡು',
'ಬೆರಲ್ಮಿಡಿಸು',
'ಬೆರಳಲುಕ',
'ಬೆರಳಾಣತಿ',
'ಬೆರಳುಕಾಪು',
'ಬೆರಳುಮುರಿ',
'ಬೆರಳ್ಮಿಡಿಸು',
'ಬೆಲಂಗಯಿಸು',
'ಬೆಲಗುಲಿಕೆ',
'ಬೆಲೆಯಿಡಿಸು',
'ಬೆಲೆವಡಿಸು',
'ಬೆಲೆವೆಂಡತಿ',
'ಬೆಲ್ಲಗಣಿಕೆ',
'ಬೆಲ್ಲಗಾರಿಗೆ',
'ಬೆವರುಗುಳ್ಳೆ',
'ಬೆವರುಸಾಲೆ',
'ಬೆಸನಂಗೊಳ್',
'ಬೆಸಲಳಲ್',
'ಬೆಸಲೆಗೊಳ್',
'ಬೆಸುಗೆಕಾಱ',
'ಬೆಸುಗೆಗಣ್',
'ಬೆಸುಗೆಗಿಡು',
'ಬೆಸುಗೆಗೊಳ್ಳು',
'ಬೆಸುಗೆದಟ್ಟು',
'ಬೆಸುಗೆಬಿಡಿ',
'ಬೆಸುಗೆಯಾನ್',
'ಬೆಸುಗೆಯೊಡೆ',
'ಬೆಸುಗೆವಡೆ',
'ಬೆಳಕುದೋಱು',
'ಬೆಳಗುಂಬೊಳ್ತು',
'ಬೆಳಗುಜಾವ',
'ಬೆಳಗುಪೊಳ್ತು',
'ಬೆಳಗುವಡೆ',
'ಬೆಳಚುಗಟ್ಟು',
'ಬೆಳಡಿಕಿಲು',
'ಬೆಳದಿಂಗಳು',
'ಬೆಳವಡಕ',
'ಬೆಳವಡಿಚ',
'ಬೆಳವಣಿಗೆ',
'ಬೆಳವಳಿಗೆ',
'ಬೆಳಾವಡಿಚ',
'ಬೆಳುಕರಿಸು',
'ಬೆಳುಗದಿರ',
'ಬೆಳುಗದಿರು',
'ಬೆಳುಗನ್ನಡ',
'ಬೆಳುಗವತೆ',
'ಬೆಳುದಿಂಗಳ್',
'ಬೆಳುದಿಂಗಳು',
'ಬೆಳೆಕಾಣಿಕೆ',
'ಬೆಳೆಗೆಯ್ವೊಲ',
'ಬೆಳೆಯಿಡಿಸು',
'ಬೆಳೆವಣಿಗೆ',
'ಬೆಳೆವತ್ತರ್',
'ಬೆಳೆವಳಿಗೆ',
'ಬೆಳೊಡಕಿಲು',
'ಬೆಳ್ಪಳಂಬೋಗು',
'ಬೆಳ್ಬಳೆವೋಗು',
'ಬೆಳ್ಳಂಬೆಳಕು',
'ಬೆಳ್ಳಂಬೆಳಗು',
'ಬೆಳ್ಳಕ್ಕರಿಗ',
'ಬೆಳ್ಳಡಕಿಲ್',
'ಬೆಳ್ಳಡಕಿಲು',
'ಬೆಳ್ಳಡಿಕಿಲು',
'ಬೆಳ್ಳಾರಬಲೆ',
'ಬೆಳ್ಳಾರವಲೆ',
'ಬೆಳ್ಳಾಲವಲೆ',
'ಬೆಱಗಾಗುಹ',
'ಬೆಱಗುಗೊಳ್',
'ಬೆಱಗುವಡು',
'ಬೆಱಗುವಡೆ',
'ಬೆಱಗುವೆಱು',
'ಬೇಂಟೆಗೊಳವಿ',
'ಬೇಕರಿಗೊಳ್',
'ಬೇಕರಿವೋಗು',
'ಬೇಕಾಯ್ದೆಶೀರ',
'ಬೇಗಡವೆಸ',
'ಬೇಗಡೆಗಳೆ',
'ಬೇಗಡೆವೆಸ',
'ಬೇಗುದಿಗೊಳ್',
'ಬೇಗುದಿಗೊಳು',
'ಬೇಗುದಿಗೊಳ್ಳು',
'ಬೇಜವಾಬ್ದಾರಿ',
'ಬೇಟೆಗಾರಿಕೆ',
'ಬೇದಕ್ಕರಿಗ',
'ಬೇದಬಿಜ್ಜೆಗ',
'ಬೇನೆಬಡಿಸು',
'ಬೇಪರವಾಯಿ',
'ಬೇಬಂದಶಾಹಿ',
'ಬೇಯಿತಬಾರಿ',
'ಬೇರುಗಿಡಿಸು',
'ಬೇರ್ನೆಲೆವೋಗು',
'ಬೇರ್ಪಡಿಸುಹ',
'ಬೇವಸಂಗೊಳ್',
'ಬೇವಸಂಬಡು',
'ಬೇವಸಂಬಡೆ',
'ಬೇವಸಗೊಳ್',
'ಬೇವಸಗೊಳು',
'ಬೇವಸದಾಳು',
'ಬೇವಸವಡೆ',
'ಬೇಸಗೆಕಾಲ',
'ಬೇಸಗೆಗಾಲ',
'ಬೇಸಗೆರಜ',
'ಬೇಸರಗೊಳ್ಳು',
'ಬೇಸರುಗೊಳ್ಳು',
'ಬೇಸಱುಗೊಳ್',
'ಬೇಸಾಯಗಾರ',
'ಬೇಸಿಗೆಕಾಲ',
'ಬೇಸಿಗೆರಜ',
'ಬೇಸಿಗೆರಜೆ',
'ಬೇಸ್ತುಬೀಳಿಸು',
'ಬೇಹಕ್ಕುದಾರ',
'ಬೇಹುಗಾರಿಕೆ',
'ಬೇಳುವೆಗೊಳ್',
'ಬೇಳುವೆಗೊಳ್ಳು',
'ಬೇಳುವೆದಳೆ',
'ಬೇಳುವೆಬೂದಿ',
'ಬೇಳುವೆಮಾತು',
'ಬೈಗುಮಲ್ಲಿಗೆ',
'ಬೈಜಿಕದೋಷ',
'ಬೈಠಕ್‍ಕೋಣೆ',
'ಬೈಠಕ್‍ಖಾನೆ',
'ಬೈಠಕಕೋಣೆ',
'ಬೈಠಕಖಾನೆ',
'ಬೈಠಕುಖಾನೆ',
'ಬೈತಲೆದೆಗೆ',
'ಬೈತಲೆಬಟ್ಟು',
'ಬೈತಲೆಬಟ್ಟೆ',
'ಬೈತಲೆಬೊಟ್ಟು',
'ಬೈತಲೆಮಣಿ',
'ಬೈತಲೆಮಾವು',
'ಬೈತಲೆವಟ್ಟೆ',
'ಬೈತಲೆವಣಿ',
'ಬೈರಗಿಬೀೞ್',
'ಬೈರಾಗಿತನ',
'ಬೈರಿಗೆಕೊರೆ',
'ಬೈಲುಗಂಬಾರ',
'ಬೈಲುಗಮ್ಮಾರ',
'ಬೈಸಿಗೆದೆಗೆ',
'ಬೈಸಿಗೆಬಿಡು',
'ಬೊಂಬಲಮರ',
'ಬೊಕ್ಕಬೋರಲು',
'ಬೊಕ್ಕುಬೋರಲು',
'ಬೊಗಸೆಗಣ್',
'ಬೊಗಸೆಗಣ್ಣು',
'ಬೊಗುಳುಸನ್ನಿ',
'ಬೊಜಂಗತನ',
'ಬೊಜಗತನ',
'ಬೊಟ್ಟುತೋರಿಸು',
'ಬೊಟ್ಟುದೋರಿಸು',
'ಬೊಡ್ಡಣಬಾವಿ',
'ಬೊಡ್ಡಣವಾವಿ',
'ಬೊಮ್ಮಚರಿಯ',
'ಬೊಮ್ಮಚರಿಯೆ',
'ಬೊಮ್ಮಬರೆಹ',
'ಬೊಮ್ಮರಕ್ಕಸ',
'ಬೊಮ್ಮವೊಲೆಯ',
'ಬೊಮ್ಮಶಿರೋಸ್ತ್ರ',
'ಬೊಮ್ಮೇತಿಗಾಱ',
'ಬೊಮ್ಮೋಪದೇಶ',
'ಬೋಕಾಣಗಿತ್ತಿ',
'ಬೋಗನ್‍ವಿಲ್ಲ',
'ಬೋಗಾಱದೆಱೆ',
'ಬೋಡುಕಡಲೆ',
'ಬೋದದೋರಗ',
'ಬೋದಿನ ಗಿಡ',
'ಬೋನಗುಡಿಕೆ',
'ಬೋನಗೋಂಟಿಗೆ',
'ಬೋರಗುದುರೆ',
'ಬೋರಲುಪಾಲೆ',
'ಬೋರ್ಗರೆಯಿಸು',
'ಬೋಲಬಗರಿ',
'ಬೋವಗಾಱಿಕೆ',
'ಬೋಸರಗಿತ್ತಿ',
'ಬೋಳಂಗಡಲೆ',
'ಬೋಳಗಡಲೆ',
'ಬೋಳತರಗ',
'ಬೋಳದರಗ',
'ಬೋಳಾಮಸ್ತಕಿ',
'ಬೋಳೆಯತನ',
'ಬೋೞಗಡಲೆ',
'ಬೌದ್ಧಾವತಾರ',
'ಬ್ರಹ್ಮಚಾರಿಣಿ',
'ಬ್ರಹ್ಮಜಿಜ್ಞಾಸೆ',
'ಬ್ರಹ್ಮಣಸ್ಪತಿ',
'ಬ್ರಹ್ಮತಿಕಾಱ',
'ಬ್ರಹ್ಮತೇಜಸ್ಸು',
'ಬ್ರಹ್ಮಪಿಶಾಚಿ',
'ಬ್ರಹ್ಮಪುರಾಣ',
'ಬ್ರಹ್ಮಮೀಮಾಂಸೆ',
'ಬ್ರಹ್ಮರಾಕ್ಷಸ',
'ಬ್ರಹ್ಮಲಿಖಿತ',
'ಬ್ರಹ್ಮವರ್ಚಸ',
'ಬ್ರಹ್ಮವರ್ಚಸ್ಸು',
'ಬ್ರಹ್ಮವಾದಿನಿ',
'ಬ್ರಹ್ಮವೈವರ್ತ',
'ಬ್ರಹ್ಮಸಂಕಲ್ಪ',
'ಬ್ರಹ್ಮಸಮಾಜ',
'ಬ್ರಹ್ಮಸಾಯುಜ್ಯ',
'ಬ್ರಹ್ಮಸಾವರ್ಣಿ',
'ಬ್ರಹ್ಮಸ್ವರೂಪ',
'ಬ್ರಹ್ಮಹರಣ',
'ಬ್ರಹ್ಮೇತಿಕಾಱ',
'ಬ್ರಹ್ಮೋಪದೇಶ',
'ಬ್ರಾಹ್ಮಣಗೇರಿ',
'ಬ್ರಾಹ್ಮಣಬ್ರುವ',
'ಬ್ರಾಹ್ಮಣವಾಡ',
'ಬ್ರಾಹ್ಮಣೇತರ',
'ಬ್ರಾಹ್ಮಮುಹೂರ್ತ',
'ಬ್ರಾಹ್ಮೀಮುಹೂರ್ತ',
'ಭಂಗಚಿಕಿತ್ಸೆ',
'ಭಂಗಪಡಿಸು',
'ಭಂಗಬಡಿಸು',
'ಭಂಗವಡಿಸು',
'ಭಂಗುರಕೇಶಿ',
'ಭಂಗುರವೇಣಿ',
'ಭಂಡಣಾಜಿರ',
'ಭಂಡವಾಳಿಗ',
'ಭಂಡವಿಕ್ರಯ',
'ಭಂಡಾರಾಲಯ',
'ಭಕ್ತಕುಟುಂಬಿ',
'ಭಕ್ತವತ್ಸಲ',
'ಭಕ್ತವತ್ಸಲೆ',
'ಭಕ್ತವತ್ಸಳ',
'ಭಕ್ತವತ್ಸಳೆ',
'ಭಕ್ತವಾತ್ಸಲ್ಯ',
'ಭಕ್ತವ್ಯಸನ',
'ಭಕ್ತವ್ಯಸನಿ',
'ಭಕ್ತಿಪೂರ್ವಕ',
'ಭಕ್ತಿಹೀನತೆ',
'ಭಗಭಗನೆ',
'ಭಗವತ್ಪಾದ',
'ಭಗವತ್ಪಾದಿ',
'ಭಗವದಿಚ್ಛೆ',
'ಭಗವದ್ಗೀತೆ',
'ಭಗಿನೀಪತಿ',
'ಭಗ್ನಾವಶೇಷ',
'ಭಜನೆವಂತ',
'ಭಟಗುತಿಗೆ',
'ಭಟ್ಟಂಗಿತನ',
'ಭಟ್ಟಗುತ್ತಿಕೆ',
'ಭಟ್ಟದರ್ಶನ',
'ಭಟ್ಟಮಂಡಪ',
'ಭಟ್ಟೋಪಧ್ಯಾಯ',
'ಭದ್ರಮುಸ್ತಕ',
'ಭದ್ರಾಕರಣ',
'ಭದ್ರಾರಮಣ',
'ಭಯಂಗೊಳಿಸು',
'ಭಯಂಬೆರಸು',
'ಭಯಗೊಳಿಸು',
'ಭಯಗ್ರಹಣಿ',
'ಭಯಚಕಿತ',
'ಭಯಚಕಿತೆ',
'ಭಯಪಡಿಸು',
'ಭಯರಸಕ',
'ಭಯವಿಹೀನ',
'ಭಯವಿಹೀನೆ',
'ಭಯವಿಹ್ವಲ',
'ಭಯವಿಹ್ವಲೆ',
'ಭಯವಿಹ್ವಳ',
'ಭಯವಿಹ್ವಳೆ',
'ಭಯಾರ್ತಿಗೊಳ್',
'ಭಯೋತ್ಕಟತೆ',
'ಭರಣಂಗೆಯ್',
'ಭರತಕ್ಷೇತ್ರ',
'ಭರತಖಂಡ',
'ಭರತನಾಟ್ಯ',
'ಭರತಪಕ್ಷಿ',
'ಭರತಭೂಮಿ',
'ಭರತವರ್ಷ',
'ಭರತವಾಕ್ಯ',
'ಭರತವಿದ್ಯೆ',
'ಭರತಶಾಸ್ತ್ರ',
'ಭರತಾಗಮ',
'ಭರಭರನೆ',
'ಭರಭರಾಟಿ',
'ಭರಭರಾಟೆ',
'ಭರವಸಿಕೆ',
'ಭರವಸಿಗ',
'ಭರವಹನ',
'ಭರಿಕೆಯ್ವಿಲ',
'ಭರಿತಬೋನ',
'ಭರಿತಾರ್ಪಣ',
'ಭರ್ತೃದಾರಕ',
'ಭರ್ತೃದಾರಿಕೆ',
'ಭರ್ತ್ಸನಂಗೆಯ್',
'ಭವಂಗಬೀಜ',
'ಭವಂಗಿಡಿಸು',
'ಭವಗೆಡಿಸು',
'ಭವತಾರಿಣಿ',
'ಭವನವಾಸಿ',
'ಭವನಾಮರ',
'ಭವನಿರ್ಲೇಪ',
'ಭವಬಂಧನ',
'ಭವಭ್ರಮಣ',
'ಭವಮಥನ',
'ಭವರಾಟಳ',
'ಭವಸ್ಮರಣೆ',
'ಭವಱಾಟಣ',
'ಭವಱಾಟಳ',
'ಭವಱಾಟಾಳ',
'ಭವಿತವ್ಯತೆ',
'ಭವಿಷ್ಯತ್ಕಾಲ',
'ಭವಿಷ್ಯನಿಧಿ',
'ಭವಿಷ್ಯಶಾಸ್ತ್ರ',
'ಭವೋತ್ತಾರಣ',
'ಭಸಿತಧರ',
'ಭಸ್ಮಗಂಧಿನಿ',
'ಭಸ್ಮೀಕರಣ',
'ಭಸ್ಮೀಕರಿಸು',
'ಭಾಂಕಾರಂಗುಡು',
'ಭಾಂಡಸಂಕರ',
'ಭಾಗವತಿಕೆ',
'ಭಾಗವಹಿಸು',
'ಭಾಗ್ಯಶಾಲಿನಿ',
'ಭಾಟ್ಟದರ್ಶನ',
'ಭಾನುಮಂಡಲ',
'ಭಾನುಮಂಡಳ',
'ಭಾಮಿನಿವೃತ್ತ',
'ಭಾರಕಾರಕ',
'ಭಾರತವರ್ಷ',
'ಭಾರತೀಯತೆ',
'ಭಾರತೀಯತ್ವ',
'ಭಾರವಡ್ಡಿಗೆ',
'ಭಾರವಾಹಕ',
'ಭಾರಾವತಾರ',
'ಭಾವಕೃದಂತ',
'ಭಾವಗರ್ಭಿತ',
'ಭಾವತೀವ್ರತೆ',
'ಭಾವನಾಜೀವಿ',
'ಭಾವನಾತ್ಮಕ',
'ಭಾವನಾಮಯಿ',
'ಭಾವನಾಲೋಕ',
'ಭಾವನಾಶಕ್ತಿ',
'ಭಾವನಿಷ್ಠುರ',
'ಭಾವನೆಗೆಯ್',
'ಭಾವಪೂರ್ಣತೆ',
'ಭಾವಪ್ರಧಾನ',
'ಭಾವಬಿದಿಗೆ',
'ಭಾವಮೈದುನ',
'ಭಾವಲಹರಿ',
'ಭಾವವಚನ',
'ಭಾವಶೂನ್ಯತೆ',
'ಭಾವಸಂವರೆ',
'ಭಾವಸಮಾಧಿ',
'ಭಾವಸಾರಾಯ',
'ಭಾವಸೂತಕ',
'ಭಾವಾತಿರೇಕ',
'ಭಾವಾನುವಾದ',
'ಭಾವಾಭಿನಯ',
'ಭಾವಾಭಿವ್ಯಕ್ತಿ',
'ಭಾವಾವಿಷ್ಟತೆ',
'ಭಾವಿಕತನ',
'ಭಾವೋದ್ವಿಗ್ನತೆ',
'ಭಾವೋನ್ಮತ್ತತೆ',
'ಭಾಷಣಕಾರ',
'ಭಾಷಣಕಾರ್ತಿ',
'ಭಾಷಾಂಗಕಾಪಿ',
'ಭಾಷಾಂತರಿಸು',
'ಭಾಷಾಪತ್ರಿಕೆ',
'ಭಾಷಾಮರ್ಯಾದೆ',
'ಭಾಷಾವಿಜ್ಞಾನ',
'ಭಾಷಾವಿಜ್ಞಾನಿ',
'ಭಾಷಾಶಾಸ್ತ್ರಜ್ಞ',
'ಭಾಷಾಶಾಸ್ತ್ರಜ್ಞೆ',
'ಭಾಷಾಸಮಿತಿ',
'ಭಾಷೆತಪ್ಪುಕ',
'ಭಾಸ್ಕರವಾರ',
'ಭಾಸ್ಕರಸ್ವರ',
'ಭಾಸ್ವರಬೋಧ',
'ಭಾಳನಯನ',
'ಭಾಳಲಾಂಛನ',
'ಭಿನ್ನಕರ್ತೃಕ',
'ಭಿನ್ನಕೌಶಿಕಿ',
'ಭಿನ್ನಪಂಚಮ',
'ಭಿನ್ನಭಾಜನ',
'ಭಿನ್ನಮುಹೂರ್ತ',
'ಭಿನ್ನಾಭಿಪ್ರಾಯ',
'ಭಿನ್ನೋಪಪಾಠ',
'ಭಿಲ್ಲಜಕರ',
'ಭೀಕರವಡೆ',
'ಭೀತಿಗೊಳಿಸು',
'ಭೀತಿವಡಿಸು',
'ಭೀಮನಕಡ್ಡಿ',
'ಭೀಮನಸೊಪ್ಪು',
'ಭೀಮನಹಂಚಿ',
'ಭೀಮಭೀಷಣ',
'ಭೀಷ್ಮಪ್ರತಿಜ್ಞೆ',
'ಭುಗಭುಗನೆ',
'ಭುಗಿಭುಗಿಸು',
'ಭುಜಂಗತ್ರಾಸ',
'ಭುಜಂಗಭೋಗ',
'ಭುಜಂಗಲೋಕ',
'ಭುಜಂಗವಳಿ',
'ಭುಜಂಗವೀಣೆ',
'ಭುಜಂಗಾಂಚಿತ',
'ಭುಜಕಿರೀಟ',
'ಭುಜಗತ್ರಸ್ತ',
'ಭುಜಗಧಾರಿ',
'ಭುಜಗಬಂಧ',
'ಭುಜಗಭೂಷ',
'ಭುಜಗಲೋಕ',
'ಭುಜಗುಂಬಳ',
'ಭುಜವಲಯ',
'ಭುಜವಿಕ್ರಮ',
'ಭುಜವಿಕ್ಷೇಪ',
'ಭುಜಶಿಖರ',
'ಭುವನಕುಕ್ಷಿ',
'ಭುವನಪ್ರಾಣ',
'ಭುವನವಾಸ',
'ಭುವನೇಶ್ವರಿ',
'ಭೂಗರ್ಭಜಲ',
'ಭೂಗರ್ಭಶಾಸ್ತ್ರ',
'ಭೂಗೋಳಕಲ್ಪ',
'ಭೂಗೋಳಶಾಸ್ತ್ರ',
'ಭೂಚಕ್ರಬಳ್ಳಿ',
'ಭೂಚೆಕ್ಕೆಗೆಡ್ಡೆ',
'ಭೂಜಾಂಗವೃತ್ತಿ',
'ಭೂತಕರುಳು',
'ಭೂತಗಣೇಶ',
'ಭೂತಗನ್ನಡಿ',
'ಭೂತಚಿಕಿತ್ಸೆ',
'ಭೂತಪಂಚಕ',
'ಭೂತಸಂತುಷ್ಟಿ',
'ಭೂತಸಂಹಾರಿ',
'ಭೂತಾಪಕಾರಿ',
'ಭೂತಾರಾಧನೆ',
'ಭೂತಾಳಕ್ರಿಯೆ',
'ಭೂತೋಚ್ಚಾಟನೆ',
'ಭೂಧರಯಂತ್ರ',
'ಭೂನಾಗಸತ್ವ',
'ಭೂಪತಿಮಾತ್ರೆ',
'ಭೂಪ್ರದಕ್ಷಿಣ',
'ಭೂಪ್ರದಕ್ಷಿಣೆ',
'ಭೂಮಧ್ಯರೇಖೆ',
'ಭೂಮಧ್ಯರೇಷೆ',
'ಭೂಮಾನುಭೂತಿ',
'ಭೂಮಿಗೋಚರ',
'ಭೂಮಿಜಂಬುಕೆ',
'ಭೂಮಿದುಂದುಭಿ',
'ಭೂಮಿವಿಲಗ್ಪ್ನ',
'ಭೂಮಿಹುಣ್ಣಿಮೆ',
'ಭೂರಾಜನೀತಿ',
'ಭೃಗುಪತನ',
'ಭೇದದರ್ಶನ',
'ಭೇರುಂಡವಕ್ಕಿ',
'ಭೈರವಾಂಜನ',
'ಭೈರವಾಸನ',
'ಭೈಷಜ್ಯಶಾಸ್ತ್ರ',
'ಭೋಗಚೌಪದಿ',
'ಭೋಗವಾಡಗೆ',
'ಭೋಗಷಟ್ಪದಿ',
'ಭೋಗಾಂತರಾಯ',
'ಭೋಗಿಕಟಕ',
'ಭೋಗ್ಯಾಧಿಪತ್ರ',
'ಭೋಜನಂಬಡೆ',
'ಭೋಜನಕೂಟ',
'ಭೋಜನಗೃಹ',
'ಭೋಜನಗೆಯ್',
'ಭೋಜನಪ್ರಿಯ',
'ಭೋಜನಭೂಮಿ',
'ಭೋಜನಶಾಲೆ',
'ಭೋಳಾಶಂಕರ',
'ಭೋಳೆಭಾವಿಕ',
'ಭೋಳೆಶಂಕರ',
'ಭೋಳೇಸ್ವಭಾವ',
'ಭೌಗೋಳಿಕತೆ',
'ಭೌತವಿಜ್ಞಾನ',
'ಭೌತವಿಜ್ಞಾನಿ',
'ಭೌತಶರೀರ',
'ಭೌತಶರೀರಿ',
'ಭೌತಶಾಸ್ತ್ರಜ್ಞ',
'ಭೌತಶಾಸ್ತ್ರಜ್ಞೆ',
'ಭೌಮಮಂಡಲ',
'ಭೌಮವಾಸರ',
'ಭ್ರಮರಕೀಟ',
'ಭ್ರಮರಚ್ಛಲ್ಲಿ',
'ಭ್ರಮರವೃತ್ತಿ',
'ಭ್ರಮರಹಿತ',
'ಭ್ರಮರಾಳಕಿ',
'ಭ್ರಮರಿಗುಡು',
'ಭ್ರಮರೀಗೀತ',
'ಭ್ರಮೆಗೊಳಿಸು',
'ಭ್ರಷ್ಟಾಚರಣೆ',
'ಭ್ರಾಂತಿಗೊಳಿಸು',
'ಭ್ರಾಂತಿಜನ್ಯತೆ',
'ಭ್ರಾಮಕಗೊಳ್ಳು',
'ಭ್ರಾಮಕರತಿ',
'ಭ್ರಾಮಕವಡೆ',
'ಭ್ರೂವಿಕ್ಷೇಪಣ',
'ಮಂಕುಗೊಳಿಸು',
'ಮಂಗನಬಳ್ಳಿ',
'ಮಂಗರವಳ್ಳಿ',
'ಮಂಗಲಂಬಾಡು',
'ಮಂಗಲಕಾರ',
'ಮಂಗಲಗಾತ್ರೆ',
'ಮಂಗಲಮಣಿ',
'ಮಂಗಲವತಿ',
'ಮಂಗಲವಾದ್ಯ',
'ಮಂಗಲವಾರ',
'ಮಂಗಲಸೂತ್ರ',
'ಮಂಗಲೋತ್ತರ',
'ಮಂಗಳಂಬಾಡು',
'ಮಂಗಳಕರ',
'ಮಂಗಳಕಷ್ಟ',
'ಮಂಗಳತಾಳಿ',
'ಮಂಗಳಬೇರ',
'ಮಂಗಳವಡೆ',
'ಮಂಗಳವರೆ',
'ಮಂಗಳವಱೆ',
'ಮಂಗಳವಾದ್ಯ',
'ಮಂಗಳವಾರ',
'ಮಂಗಳಸೂತ್ರ',
'ಮಂಗಱವಳ್ಳಿ',
'ಮಂಗಾರೆಮುಳ್ಳು',
'ಮಂಗುರವಳ್ಳಿ',
'ಮಂಗುಱವಳ್ಳಿ',
'ಮಂಗುಱುವಳ್ಳಿ',
'ಮಂಚಿಗೆಗಲ್',
'ಮಂಜರದೃಷ್ಟಿ',
'ಮಂಜಿನನಾರು',
'ಮಂಜಿಷ್ಠಾರಾಗ',
'ಮಂಜುಗತ್ತಲು',
'ಮಂಜುಗತ್ತಲೆ',
'ಮಂಜುಭಾಷಿಣಿ',
'ಮಂಜುವೆಳಗ',
'ಮಂಜುಳಬೀಟೆ',
'ಮಂಡಕುಸುಬಿ',
'ಮಂಡಗೊಳಲೆ',
'ಮಂಡನಾಯೋಗ',
'ಮಂಡರಗಪ್ಪೆ',
'ಮಂಡರಿಸಿನ',
'ಮಂಡಲಂಗೊಳ್ಳು',
'ಮಂಡಲಕಾಯಿ',
'ಮಂಡಲಗತಿ',
'ಮಂಡಲನೃತ್ಯ',
'ಮಂಡಲವರ್ಷ',
'ಮಂಡಲಸೇವೆ',
'ಮಂಡಲಾಧಿಪ',
'ಮಂಡಲೇಶ್ವರ',
'ಮಂಡಹಾರಕ',
'ಮಂಡಳಮಾರಿ',
'ಮಂಡಳಿಗೊಳ್',
'ಮಂಡಳೇಶ್ವರ',
'ಮಂಡೂಕಪರ್ಣ',
'ಮಂಡೂಕಪರ್ಣಿ',
'ಮಂಡೂಕಾಸನ',
'ಮಂಡೆಕರಂಡೆ',
'ಮಂಡೆತೆರಿಗೆ',
'ಮಂಡೆದೆರಿಗೆ',
'ಮಂಡೆಮಲಗು',
'ಮಂಡೆಹಱಿಕ',
'ಮಂತಣಂಗೊಳ್',
'ಮಂತಣಂಗೊಳು',
'ಮಂತಣಂಬಡೆ',
'ಮಂತಣಗಾರ್ತಿ',
'ಮಂತಣಗೆಯ್',
'ಮಂತಣಗೊಳು',
'ಮಂತಣಮಿರ್',
'ಮಂತಣವಟ್ಟೆ',
'ಮಂತಣಶಾಲೆ',
'ಮಂತಣಸಾಲೆ',
'ಮಂತಣಸೂಲ',
'ಮಂತ್ರಕೋವಿದ',
'ಮಂತ್ರಕೋವಿದೆ',
'ಮಂತ್ರಗಾರಿಕೆ',
'ಮಂತ್ರದೀಕ್ಷಿತ',
'ಮಂತ್ರದೇವತೆ',
'ಮಂತ್ರದ್ರಷ್ಟಾರ',
'ಮಂತ್ರಭ್ರಮಿತ',
'ಮಂತ್ರಭ್ರಮಿತೆ',
'ಮಂತ್ರವಾದಿನಿ',
'ಮಂತ್ರಾನುಷ್ಠಾನ',
'ಮಂತ್ರಾಲೋಚನೆ',
'ಮಂತ್ರಾಳೋಚನೆ',
'ಮಂತ್ರಿಮಂಡಲ',
'ಮಂತ್ರಿಮಂಡಳ',
'ಮಂತ್ರಿಸಂಪುಟ',
'ಮಂದಕಬಳ್ಳಿ',
'ಮಂದಕುಂಬಳ',
'ಮಂದಗಮನ',
'ಮಂದಗಮನೆ',
'ಮಂದಗೞ್ತಲೆ',
'ಮಂದಗಾಮಿನಿ',
'ಮಂದಪವನ',
'ಮಂದಪ್ರಕೃತಿ',
'ಮಂದಭಾಗ್ಯತೆ',
'ಮಂದಮರುತ',
'ಮಂದಮಾರುತ',
'ಮಂದರಧರ',
'ಮಂದರಯಾನ',
'ಮಂದರಶ್ರುತಿ',
'ಮಂದರಾಗತೆ',
'ಮಂದರಿಪಪ್ಪು',
'ಮಂದವೇಗಿನಿ',
'ಮಂದಹಸಿತ',
'ಮಂದಾಸುವಲೆ',
'ಮಂದಿರಂಬೊಗು',
'ಮಂದಿರಿಪೊಪ್ಪು',
'ಮಂದಿವಾಳಿಕೆ',
'ಮಂದ್ಯಾಳತನ',
'ಮಕಮಕಿಸು',
'ಮಕರಂದಪ',
'ಮಕರಕಂಠಿ',
'ಮಕರಕೇತು',
'ಮಕರಗುಬ್ಬಿ',
'ಮಕರಡಿಬ್ಬಿ',
'ಮಕರದಿಬ್ಬಿ',
'ಮಕರಧ್ವಜ',
'ಮಕರಪತ್ರ',
'ಮಕರಬಿಬ್ಬಿ',
'ಮಕರಮಾಸ',
'ಮಕರಮುದ್ರೆ',
'ಮಕರರಾಶಿ',
'ಮಕರವೃತ್ತ',
'ಮಕರವ್ಯೂಹ',
'ಮಕರಾಕರ',
'ಮಕರಾಯನ',
'ಮಕುಟಧರ',
'ಮಕುಟಬದ್ಧ',
'ಮಕುಟಭಂಗ',
'ಮಕುಟಮಣಿ',
'ಮಕುಟರತ್ನ',
'ಮಕುಟಸ್ವರ',
'ಮಕ್ಕಿಕಾಮಕ್ಕಿ',
'ಮಕ್ಕಿನೇರಳೆ',
'ಮಖತುರಗ',
'ಮಖಪುರುಷ',
'ಮಖೇಡಿತನ',
'ಮಗಮಗಿಸು',
'ಮಗುವುತನ',
'ಮಗ್ಗತೆಱಿಗೆ',
'ಮಗ್ಗುಲುಹೊಡೆ',
'ಮಗ್ನತೆಗೊಳು',
'ಮಘಮಘಿಸು',
'ಮಚ್ಚರಕಾಱ',
'ಮಚ್ಚರಗೊಳ್ಳು',
'ಮಚ್ಚರದಾಣಿ',
'ಮಚ್ಚರದಾನಿ',
'ಮಚ್ಚುಗೆವೆಱು',
'ಮಚ್ಚುಗೊಳಿಸು',
'ಮಚ್ಚೆನಾಲಿಗೆ',
'ಮಚ್ಛರದಾಣಿ',
'ಮಚ್ಛರದಾನಿ',
'ಮಜಲುಗದ್ದೆ',
'ಮಜಲುಬಿಡು',
'ಮಜ್ಜನಂಗೆಯ್ಸು',
'ಮಜ್ಜನಂಬುಗು',
'ಮಜ್ಜನಂಬೊಗು',
'ಮಜ್ಜನಗೃಹ',
'ಮಜ್ಜನಗೆಯ್',
'ಮಜ್ಜನಗೆಯ್ಸು',
'ಮಜ್ಜನವಳ',
'ಮಜ್ಜನವಳ್ತಿ',
'ಮಜ್ಜನವಳ್ಳ',
'ಮಜ್ಜನವಾಳ',
'ಮಜ್ಜನಶಾಲೆ',
'ಮಜ್ಜನಸಾಲೆ',
'ಮಜ್ಜಗೆಗಡ್ಡೆ',
'ಮಜ್ಜಗೆಗೆಡ್ಡೆ',
'ಮಜ್ಜಿಗೆಬೇರು',
'ಮಜ್ಜಿಗೆಸೊಪ್ಪು',
'ಮಜ್ಜಿಗೆಹುಲ್ಲು',
'ಮಜ್ಜಿಗೆಹುಳಿ',
'ಮಟಮಟಿಸು',
'ಮಟಮಾಲಂಗಿ',
'ಮಟಮೂಲಂಗಿ',
'ಮಟ್ಟನೇರಳೆ',
'ಮಟ್ಟಮಧ್ಯಾಹ್ನ',
'ಮಟ್ಟಮಾಲಂಗಿ',
'ಮಟ್ಟರಗಳೆ',
'ಮಟ್ಟಸುತ್ತಿಗೆ',
'ಮಟ್ಟಿಬಡಕ',
'ಮಟ್ಟಿವಡಿಕೆ',
'ಮಟ್ಟುಗೊಳಿಸು',
'ಮಠಾಧಿಪತಿ',
'ಮಡಗೆದಱು',
'ಮಡಮುರಿವು',
'ಮಡಲುತುಂಬು',
'ಮಡಹಾಗಲ',
'ಮಡಿಕೆಗೆಯ್',
'ಮಡಿಗೊಳಿಸು',
'ಮಡಿಪೆಟ್ಟಿಗೆ',
'ಮಡಿಲುಗಟ್ಟು',
'ಮಡಿಲುತುಂಬು',
'ಮಡಿಲುದುಂಬು',
'ಮಡವಂತಿಕೆ',
'ಮಡಿವಸನ',
'ಮಡಿವಸಲೆ',
'ಮಡಿವಳತಿ',
'ಮಡಿವಳಿತಿ',
'ಮಡಿವಾಗಲ್',
'ಮಡಿವಾಗಲು',
'ಮಡಿವಾಳಿತಿ',
'ಮಡಿವುಳಿಗ',
'ಮಡಿಹಾಗಲ',
'ಮಡಿಹಾಗಲು',
'ಮಡುಗೊಳಿಸು',
'ಮಡುದಲೆಯ',
'ಮಡುವಾಗಲ್',
'ಮಡುವಾಗಲ',
'ಮಡುವಾಗಲು',
'ಮಡುವುಗಟ್ಟು',
'ಮಡೆಹೊರಳು',
'ಮಡ್ಡಿಸಾಂಬ್ರಾಣಿ',
'ಮಣಮಣಿಸು',
'ಮಣಲಕಾಂತಿ',
'ಮಣಿಕಂಕಣ',
'ಮಣಿಕಟಕ',
'ಮಣಿಕುಂಡಲ',
'ಮಣಿಕುಂಡಳ',
'ಮಣಿಕುಟ್ಟಿಮ',
'ಮಣಿಕುರುಜು',
'ಮಣಿಖಚಿತ',
'ಮಣಿಗಡಗ',
'ಮಣಿಗುರುಜು',
'ಮಣಿಚೌಕಟ್ಟು',
'ಮಣಿಪೂರಕ',
'ಮಣಿಪ್ರವಾಳ',
'ಮಣಿಭೂಷಣ',
'ಮಣಿಯಗಾರ',
'ಮಣಿಯಗಾಱ',
'ಮಣಿಯವರೆ',
'ಮಣಿವಸರ',
'ಮಣಿಹಗಾಱ',
'ಮಣಿಹಾಗಲ',
'ಮಣಿಹ್ಯಗಾರ',
'ಮಣೆಗಾರಿಕೆ',
'ಮಣೆಯಗಾಱ',
'ಮಣೆಹಗಾರ',
'ಮಣ್ಣಕ್ಕಿಹುಲ್ಲು',
'ಮಣ್ಣುಗಾಣಿಸು',
'ಮಣ್ಣುಗೂಡಿಸು',
'ಮಣ್ಮಳಿಗೊಳ್',
'ಮಣ್ಮೞಿವೋಗು',
'ಮತಗಣನೆ',
'ಮತಘರ್ಷಣೆ',
'ಮತಪೆಟ್ಟಿಗೆ',
'ಮತಸ್ಥಾಪನೆ',
'ಮತಾಧಿಕಾರ',
'ಮತಾನುಯಾಯಿ',
'ಮತಾವಲಂಬಿ',
'ಮತಿಗಿಡಿಸು',
'ಮತಿಗೆಡಿಸು',
'ಮತಿಪ್ರಕಾಶ',
'ಮತಿಬವಣೆ',
'ಮತಿಭ್ರಮಣೆ',
'ಮತಿಭ್ರಷ್ಟತೆ',
'ಮತಿವಿಕಲ',
'ಮತಿವಿಕಲೆ',
'ಮತಿವಿಕಳ',
'ಮತಿವಿಕಳೆ',
'ಮತಿವಿಹೀನ',
'ಮತಿವಿಹೀನೆ',
'ಮತಿಹೀನತೆ',
'ಮತ್ತಕಗೊಳ್',
'ಮತ್ತಕಾಶಿನಿ',
'ಮತ್ತಕಾಸಿನಿ',
'ಮತ್ತಕೋಕಿಲ',
'ಮತ್ತಮಯೂರ',
'ಮತ್ತವಾರಣ',
'ಮತ್ತವಾರಣಿ',
'ಮತ್ತಸ್ಖಲಿತ',
'ಮತ್ತೋಪಚಾರ',
'ಮತ್ಥವಟ್ಟಿಗೆ',
'ಮತ್ಸ್ಯಪುರಾಣ',
'ಮತ್ಸ್ಯಬಂಧನ',
'ಮತ್ಸ್ಯಬಂಧಿನಿ',
'ಮತ್ಸ್ಯಾವತಾರ',
'ಮದಡತನ',
'ಮದನಕಯ್',
'ಮದನಕಲೆ',
'ಮದನಕಳೆ',
'ಮದನಕಾಯ',
'ಮದನಕೇಳಿ',
'ಮದನಗಂಟೆ',
'ಮದನಗೃಹ',
'ಮದನಗೆಯ್',
'ಮದನಗೇಹ',
'ಮದನಚೇಷ್ಟೆ',
'ಮದನಚ್ಛತ್ರ',
'ಮದನಜಲ',
'ಮದನಜ್ವರ',
'ಮದನತಾಪ',
'ಮದನದ್ರವ',
'ಮದನಫಲ',
'ಮದನಫಳ',
'ಮದನಬಾಧೆ',
'ಮದನಮಸ್ತ',
'ಮದನಮಸ್ತಿ',
'ಮದನಯುದ್ಧ',
'ಮದನರಸ',
'ಮದನವತಿ',
'ಮದನಶರ',
'ಮದನಶಾಸ್ತ್ರ',
'ಮದನಸುಖ',
'ಮದನಾಗಮ',
'ಮದನಾತುರ',
'ಮದನಾತುರೆ',
'ಮದನಾವೇಶ',
'ಮದಲಗಿತ್ತಿ',
'ಮದವಕ್ಕಳ್',
'ಮದವಟ್ಟಿಗೆ',
'ಮದವಣಿಗ',
'ಮದವಣಿಗೆ',
'ಮದವಲಸೆ',
'ಮದವಸಲೆ',
'ಮದವಳಿಗ',
'ಮದವಳಿಗೆ',
'ಮದವಾರಣ',
'ಮದವಿಕಳ',
'ಮದವಿಕಳೆ',
'ಮದವಿಕಾರ',
'ಮದವೆಳಸು',
'ಮದವೇಱಿಸು',
'ಮದಸ್ಖಲಿತ',
'ಮದಿರಾಪಾಂಗ',
'ಮದಿರಾಪಾನ',
'ಮದಿರಾಮದ',
'ಮದಿರಾರಸ',
'ಮದಿರೇಕ್ಷಣ',
'ಮದಿರೇಕ್ಷಣೆ',
'ಮದಿರೋನ್ಮತ್ತ',
'ಮದಿರೋನ್ಮತ್ತೆ',
'ಮದುಮಗಳ್',
'ಮದುಮಗಳು',
'ಮದುವಣಿಗ',
'ಮದುವಳಿಗ',
'ಮದುವಳಿಗೆ',
'ಮದುವೆಗಂಡು',
'ಮದುವೆಗುಡು',
'ಮದುವೆಗೆಯ್',
'ಮದುವೆದೆಱೆ',
'ಮದುವೆನಿಲ್',
'ಮದುವೆನಿಲ್ಲು',
'ಮದುವೆನೆರೆ',
'ಮದುವೆಮನೆ',
'ಮದುವೆಯಾಯ',
'ಮದುವೆಹೆಣ್ಣು',
'ಮದೋದ್ರಿಕ್ತತೆ',
'ಮದೋನ್ಮತ್ತತೆ',
'ಮದ್ದಳಿಕಾಱ',
'ಮದ್ದಳಿಗಿತಿ',
'ಮದ್ದಳೆಕಾರ',
'ಮದ್ದಿನಸೊಪ್ಪು',
'ಮದ್ದುಗಾರಿಕೆ',
'ಮದ್ದುಗುಣಿಕೆ',
'ಮದ್ಯಸಾಕ್ಷಿಕ',
'ಮದ್ಯಸೇವನೆ',
'ಮಧುಕವಳ್ಳಿ',
'ಮಧುಗುಡಿಗ',
'ಮಧುಗುಡುಹಿ',
'ಮಧುಪರ್ಣಿಕೆ',
'ಮಧುಪಿಂಗಳ',
'ಮಧುಪ್ರಸಂಗ',
'ಮಧುಮಕ್ಷಿಕೆ',
'ಮಧುಮಥನ',
'ಮಧುಮರ್ದನ',
'ಮಧುಮಾಧವಿ',
'ಮಧುಮಿತ್ರತೆ',
'ಮಧುಯಷ್ಟಿಕೆ',
'ಮಧುರರಸ',
'ಮಧುರಾಲಾಪ',
'ಮಧುರಾಲಾಪೆ',
'ಮಧುರಾಳಾಪ',
'ಮಧುರಾಳಾಪೆ',
'ಮಧುವಿಹಂಗ',
'ಮಧುಸೂದನ',
'ಮಧುಸೇವನೆ',
'ಮಧ್ಯನಾಡಿಕೆ',
'ಮಧ್ಯಮಗ್ರಾಮ',
'ಮಧ್ಯಮಜ್ವರ',
'ಮಧ್ಯಮಪಾತ್ರ',
'ಮಧ್ಯಮವರ್ತಿ',
'ಮಧ್ಯಮಾವತಿ',
'ಮಧ್ಯವೇಗಿನಿ',
'ಮಧ್ಯಸ್ಥಗಾರ',
'ಮಧ್ಯಾಹ್ನರೇಖೆ',
'ಮನಂಗದಡು',
'ಮನಂಗಿಡಿಸು',
'ಮನಂಗೆಡಿಸು',
'ಮನಂಗೊಳಿಸು',
'ಮನಂಬುಗಿಸು',
'ಮನಃಕಷಾಯ',
'ಮನಃಪರ್ಯಯ',
'ಮನಃಪೂರ್ವಕ',
'ಮನಃಪೂರ್ವಕಂ',
'ಮನಃಪ್ರವೃತ್ತಿ',
'ಮನಃಸ್ಖಲಿತ',
'ಮನಃಸ್ಖಲಿತೆ',
'ಮನಗಾಣಿಕೆ',
'ಮನಗಾಣಿಸು',
'ಮನಗೆಲವು',
'ಮನಗೊಳಿಸು',
'ಮನದಿರುಹು',
'ಮನದೊಡಕು',
'ಮನಬರಡ',
'ಮನಬಱಡ',
'ಮನಬೀಸರ',
'ಮನಮಿಡುಕು',
'ಮನಮುನಿಸು',
'ಮನಮುರುಕ',
'ಮನಮೋಹಕ',
'ಮನಮೋಹಿನಿ',
'ಮನರಂಜನೆ',
'ಮನವರಿಕೆ',
'ಮನವರಿಸು',
'ಮನವಾರತೆ',
'ಮನವಿದಾರ',
'ಮನವಿಭೇದ',
'ಮನವಿಳುಹು',
'ಮನವೆರಗು',
'ಮನವೆಳಸು',
'ಮನವೆಱಗು',
'ಮನವೊಲಿಸು',
'ಮನಶ್ಶಾಸ್ತ್ರಜ್ಞ',
'ಮನಶ್ಶಾಸ್ತ್ರಜ್ಞೆ',
'ಮನಸ್ಕರಿಸು',
'ಮನಸ್ಖಳನ',
'ಮನಹತ್ತುಗೆ',
'ಮನಿಯಾರ್ಡರು',
'ಮನುಚರಿತ',
'ಮನುಜಗತಿ',
'ಮನುಜತನ',
'ಮನುಜಪತಿ',
'ಮನುಪೀಳಿಗೆ',
'ಮನುಷ್ಯಗತಿ',
'ಮನುಷ್ಯಜಾತಿ',
'ಮನುಷ್ಯಧರ್ಮ',
'ಮನುಷ್ಯಯಜ್ಞ',
'ಮನುಷ್ಯಲೋಕ',
'ಮನುಷ್ಯವಾಹ್ಯ',
'ಮನೆಗೆಡುಕ',
'ಮನೆಗೆಲಸ',
'ಮನೆತನಸ್ಥ',
'ಮನೆತನಸ್ಥೆ',
'ಮನೆತುಂಬಿಸು',
'ಮನೆದುಂಬಿಸು',
'ಮನೆದೆರಿಗೆ',
'ಮನೆದೇವತೆ',
'ಮನೆದೇವರು',
'ಮನೆಬದುಕು',
'ಮನೆಬಾಗಿಲು',
'ಮನೆಮಗತಿ',
'ಮನೆಮಗತ್ತಿ',
'ಮನೆಮಗವು',
'ಮನೆಮುರುಕ',
'ಮನೆಮುರುಕಿ',
'ಮನೆವಗತಿ',
'ಮನೆವಯನ್',
'ಮನೆವಾಗಿಲು',
'ಮನೆವಾರತೆ',
'ಮನೆವಾರ್ತೆಗ',
'ಮನೆವಾಳ್ತನ',
'ಮನೆವೆಂಡತಿ',
'ಮನೆವೆಗ್ಗಡೆ',
'ಮನೆವೆರ್ಗಡೆ',
'ಮನೆಹೆಂಡತಿ',
'ಮನೋಗಮನ',
'ಮನೋಜನಿತ',
'ಮನೋಜಾಗಮ',
'ಮನೋದಯಿತೆ',
'ಮನೋನಂದನ',
'ಮನೋನಿಗ್ರಹ',
'ಮನೋನಿವಾಸ',
'ಮನೋನುರಾಗ',
'ಮನೋನೈರ್ಮಲ್ಯ',
'ಮನೋಭವಿತ್ವ',
'ಮನೋಭಿಲಾಷೆ',
'ವುನೋರಂಜನ',
'ಮನೋರಂಜನಿ',
'ಮನೋರಂಜನೆ',
'ಮನೋರಮಣ',
'ಮನೋವಲ್ಲಭ',
'ಮನೋವಲ್ಲಭೆ',
'ಮನೋವಿಕಾರ',
'ಮನೋವಿಕಾಸ',
'ಮನೋವಿಜ್ಞಾನ',
'ಮನೋವಿಜ್ಞಾನಿ',
'ಮನೋವಿರತಿ',
'ಮನೋವೇದನೆ',
'ಮನೋವೇಧಕ',
'ಮನೋವೈಶಾಲ್ಯ',
'ಮನೋವ್ಯಾಪಾರ',
'ಮನೋಹರತೆ',
'ಮನ್ನಣೆಕಾಱ',
'ಮನ್ನಣೆಗೆಡು',
'ಮನ್ನಣೆಗೊಳ್',
'ಮನ್ನಣೆಯಾಳ್',
'ಮನ್ನಣೆವಡೆ',
'ಮನ್ನಣೆವೆಱು',
'ಮನ್ನಳಿಪಟ್ಟು',
'ಮನ್ನೆಯದೆಱೆ',
'ಮನ್ನೆಯವಣ',
'ಮನ್ನೆಯವೊಲ',
'ಮನ್ನೆಯಸಾಮ್ಯ',
'ಮನ್ಮಥಚಕ್ರ',
'ಮನ್ಮಥಭೂಮಿ',
'ಮನ್ಮಥಲೀಲೆ',
'ಮನ್ಮಥಾವಸ್ಥೆ',
'ಮಬ್ಬುಗತ್ತಲು',
'ಮಬ್ಬುಗತ್ತಲೆ',
'ಮಮಕರಿಸು',
'ಮಮಕಾರಗೈ',
'ಮಯಿಂದವಾಳೆ',
'ಮಯಿಲತುತ್ತ',
'ಮಯೂರಕಂಠ',
'ಮಯೂರತುತ್ಥ',
'ಮಯೂರನಾಡಿ',
'ಮಯೂರಪದ',
'ಮಯೂರಪಿಂಛ',
'ಮಯೂರಶಿಖಿ',
'ಮಯೂರಸಿಖೆ',
'ಮಯೂರಸಿಲೆ',
'ಮಯೂರಾಸನ',
'ಮಯ್ದುನತನ',
'ಮರಔಡಲು',
'ಮರಕಾಳಗ',
'ಮರಕುಟಕ',
'ಮರಕುಟಿಕ',
'ಮರಕುಟಿಗ',
'ಮರಕುಟುಗ',
'ಮರಗಡವ',
'ಮರಗತ್ತಲೆ',
'ಮರಗಱುಕೆ',
'ಮರಗೞ್ತಲೆ',
'ಮರಗೆಣಸು',
'ಮರಗೆತ್ತನೆ',
'ಮರಗೆರಸಿ',
'ಮರಗೆಲಸ',
'ಮರಗೊಡಲೆ',
'ಮರಗೊರಲೆ',
'ಮರಗೊಳಲೆ',
'ಮರಣಂಗೆಯ್',
'ಮರಣಕಾಲ',
'ಮರಣಗಾಲ',
'ಮರಣಧರ್ಮ',
'ಮರಣವಡು',
'ಮರಣವಡೆ',
'ಮರಣಹೊಂದು',
'ಮರಣೋತ್ತರ',
'ಮರತುಳಸಿ',
'ಮರದಣಬೆ',
'ಮರದಾವರೆ',
'ಮರದಿಗುಡು',
'ಮರದೇರಣ',
'ಮರಪಟ್ಟಡಿ',
'ಮರಮಂಜಲಿ',
'ಮರಮರನೆ',
'ಮರಮಲ್ಲಿಗೆ',
'ಮರಮಸಕ',
'ಮರಮಿಟಿಲ',
'ಮರಮೆಣಸು',
'ಮರಮೊದಲ್',
'ಮರರೇವಡಿ',
'ಮರವಲಗೆ',
'ಮರವಾಚಲು',
'ಮರವಾನಸ',
'ಮರವಾನಸಿ',
'ಮರವಾನಸೆ',
'ಮರವಾನಿಸ',
'ಮರವಾನಿಸೆ',
'ಮರವೆಗೂಡು',
'ಮರವೆಸನ',
'ಮರಸಂಪಿಗೆ',
'ಮರಸಕಾಗೆ',
'ಮರಸಣಿಗೆ',
'ಮರಸುಕಟ್ಟು',
'ಮರಸುಕೂರು',
'ಮರಸುಬೇಟೆ',
'ಮರಹಮ್ಮತ್',
'ಮಹಹಮ್ಮತ್ತು',
'ಮರಹರಳು',
'ಮರಹರುವೆ',
'ಮರಹಱುವೆ',
'ಮರಹಱುಹೆ',
'ಮರಹುಣಸೆ',
'ಮರಹುಣಿಸೆ',
'ಮರಳುಗಲ್ಲು',
'ಮರಳುಗಾಡು',
'ಮರಳುದಲೆ',
'ಮರಳುದಿಣ್ಣೆ',
'ಮರಳುಭೂಮಿ',
'ಮರಳುಮತ್ತ',
'ಮರಳುಶಾಖ',
'ಮರಾಟಿಮೊಗ್ಗು',
'ಮರಾಠಿಮೊಗ್ಗು',
'ಮರಾಲಪದಿ',
'ಮರಾಲಯಾನೆ',
'ಮರಾಳಗತಿ',
'ಮರಾಳತೂಳ',
'ಮರಾಳನ್ಯಾಯ',
'ಮರಾಳಪದಿ',
'ಮರಾಳಯಾನೆ',
'ಮರಿಗೆಕಲ್ಲು',
'ಮರಿಪುಗೊಳ್',
'ಮರಿಮಗಳು',
'ಮರೀಚಿಮಾಲಿ',
'ಮರುಕಗೊಳ್ಳು',
'ಮರುಕಳಿಕೆ',
'ಮರುಕಳಿಸು',
'ಮರುಕೊಳಿಕೆ',
'ಮರುಕೊಳಿಸು',
'ಮರುಗಳಿಗೆ',
'ಮರುಜೀವಣಿ',
'ಮರುಜೇವಣಿ',
'ಮರುಟಪಾಲು',
'ಮರುತ್ಪಾಲಕ',
'ಮರುದಂಪಟ್ಟೆ',
'ಮರುದಶನ',
'ಮರುದಿಂಡಲು',
'ಮರುದಿವಸ',
'ಮರುನುಡಿಸು',
'ಮರುಪ್ರಯಾಣ',
'ಮರುವಂದನೆ',
'ಮರವಗಲ್',
'ಮರುವಗಲ್',
'ಮರುವಸತಿ',
'ಮರುವಸಲ',
'ಮರುವಾಚಲ',
'ಮರುವಾಚಲು',
'ಮರುಸರಸ್ಸು',
'ಮರುಸವಾಲು',
'ಮರುಳಮತ್ತ',
'ಮರುಳಾಟಿಕೆ',
'ಮರುಳುಗುಟ್ಟು',
'ಮರುಳುಗೊಳ್',
'ಮರುಳುಗೊಳ್ಳು',
'ಮರುಳುತನ',
'ಮರುಳುಮಾತು',
'ಮರುಳುಮ್ಮತ',
'ಮರುಳುವೆಣ್ಣು',
'ಮರುಳ್ಗೊಳಿಸು',
'ಮರುಳ್ಮಸಗು',
'ಮರೆಕನ್ನಡ',
'ಮರೆಗನ್ನಡ',
'ಮರೆಮಾಜಿಕೆ',
'ಮರೆವುಗೇಡಿ',
'ಮರೆಹುಯಿಲು',
'ಮರ್ಕಟತನ',
'ಮರ್ಕಟನಾಡಿ',
'ಮರ್ಕಟಬಂಧ',
'ಮರ್ದಳೆಕಾಱ',
'ಮರ್ದುಗುಣಿಕೆ',
'ಮರ್ಬುವೆಳಗು',
'ಮರ್ಮಕೆಣಕು',
'ಮರ್ಮಘಾತಕ',
'ಮರ್ಮಘಾತುಕ',
'ಮರ್ಮಭೇದಕ',
'ಮರ್ಮಭೇದನ',
'ಮರ್ಮಭೇದಕಿ',
'ಮರ್ಮೋದ್ಘಾಟನ',
'ಮರ್ಮೋದ್ಘಾಟನೆ',
'ಮಲಂಗುಮೂಡೆ',
'ಮಲಕುಗೊಳ್',
'ಮಲಗುದಾಣ',
'ಮಲಗುಮೂಡೆ',
'ಮಲನಾಗರು',
'ಮಲಬದ್ಧತೆ',
'ಮಲಮಗಳು',
'ಮಲಮಲನೆ',
'ಮಲಮಱುಗು',
'ಮಲಮಾತಂಗಿ',
'ಮಲಯರುಹ',
'ಮಲಯಾಚಲ',
'ಮಲರಹಿತ',
'ಮಲಶೋಧನೆ',
'ಮಲಸಂಬಂಧ',
'ಮಲಹರಾದ್ರಿ',
'ಮಲಿನದೃಷ್ಟಿ',
'ಮಲುಮಲನೆ',
'ಮಲೂಲತನ',
'ಮಲೆನಾಡಿಗ',
'ಮಲೆನಾಡುಗ',
'ಮಲೆಪೊಡವಿ',
'ಮಲೆಸಾಗದ',
'ಮಲೆಹಾಕಟು',
'ಮಲ್ಲಕಾಳಗ',
'ಮಲ್ಲಗಂಡಿಕೆ',
'ಮಲ್ಲಗಾಳಗ',
'ಮಲ್ಲಗಾಳೆಗ',
'ಮಲ್ಲಣಿಗೊಳ್',
'ಮಲ್ಲಳಿಕಾಱ',
'ಮಲ್ಲಳಿಗಾರ್ತಿ',
'ಮಲ್ಲಳಿಗಾಱ',
'ಮಲ್ಲಳಿಗೊಳ್',
'ಮಲ್ಲೞಿಗಾರ್ತಿ',
'ಮಲ್ಲೞಿಗಾಱ',
'ಮಲ್ಲೞಿಗೊಳ್',
'ಮಲ್ಲೞಿದಿರಿ',
'ಮಲ್ಲಿಕಾಮಾಲೆ',
'ಮಲ್ಲಿಕಾರ್ಜುನ',
'ಮಲ್ಲಿಗೆಗೋಲ',
'ಮಲ್ಲೆನೇರಳೆ',
'ಮವಾಳಿತನ',
'ಮಶಿಕಾಣಿಕೆ',
'ಮಸಕಂಗುಂದು',
'ಮಸಕಂಗೊಳ್',
'ಮಸಕಂಬೆಱು',
'ಮಸಣಕಬ್ಬೆ',
'ಮಸಣಗಾಡು',
'ಮಸಣಗಾಹಿ',
'ಮಸಣಬಟ್ಟೆ',
'ಮಸಣಯಾತ್ರೆ',
'ಮಸಣವಟ್ಟಿ',
'ಮಸಣವಟ್ಟೆ',
'ಮಸಣವಾಟಿ',
'ಮಸಣಿಕಬ್ಬೆ',
'ಮಸಮಸನೆ',
'ಮಸಲಿಯತು',
'ಮಸವಟಿಗೆ',
'ಮಸಾರಿಭೂಮಿ',
'ಮಸಿಕಪ್ಪಡ',
'ಮಸಿಕಾಣಿಕೆ',
'ಮಸಿಕುಡಿಕೆ',
'ಮಸಿಗತ್ತಲೆ',
'ಮಸಿಗಪ್ಪಡ',
'ಮಸಿಬುರುಡೆ',
'ಮಸಿವಾವಾಳ',
'ಮಸುಳ್ಕೆವೆಱು',
'ಮಸೆಗಾಣಿಸು',
'ಮಸೆವೆಳಗು',
'ಮಸೆವೊಗರ್',
'ಮಸ್ಕರಿಮಾರ್ಗ',
'ಮಸ್ತಕಸ್ನೇಹ',
'ಮಹಂತುಬೋಗು',
'ಮಹಡಿಮನೆ',
'ಮಹತ್ತರಿಕೆ',
'ಮಹತ್ವಾಕಾಂಕ್ಷಿ',
'ಮಹತ್ವಾಕಾಂಕ್ಷೆ',
'ಮಹದುದ್ಯಮ',
'ಮಹನವಮಿ',
'ಮಹಮ್ಮದೀಯ',
'ಮಹರಾಯತಿ',
'ಮಹಲಕರಿ',
'ಮಹಲದಾರ',
'ಮಹವುರುಮೆ',
'ಮಹಳಪಕ್ಷ',
'ಮಹಾಕರಂಭ',
'ಮಹಾಛಂದಸು',
'ಮಹಾಛಂದಸ್ಸು',
'ಮಹಾತಾರಕ',
'ಮಹಾನಕ್ಷತ್ರ',
'ಮಹಾನಖರ',
'ಮಹಾನಗರ',
'ಮಹಾನರಕ',
'ಮಹಾನವಮಿ',
'ಮಹಾನಸಿಕ',
'ಮಹಾನಿಧಾನ',
'ಮಹಾನಿನದ',
'ಮಹಾನುಭಾವ',
'ಮಹಾನುಭಾವಿ',
'ಮಹಾಪಸಾಯ್ತ',
'ಮಹಾಪುರುಷ',
'ಮಹಾಪುರುಷೆ',
'ಮಹಾಪ್ರಧಾನ',
'ಮಹಾಪ್ರಧಾನಿ',
'ಮಹಾಪ್ರಬಂಧ',
'ಮಹಾಪ್ರಲಯ',
'ಮಹಾಪ್ರಸಾದಿ',
'ಮಹಾಪ್ರಸ್ಥಾನ',
'ಮಹಾಪ್ರಳಯ',
'ಮಹಾಪ್ರಾಣತೆ',
'ಮಹಾಭಾರತ',
'ಮಹಾಮಹಿಮ',
'ಮಹಾಮಹಿಮೆ',
'ಮಹಾಮಾತುಶ್ರೀ',
'ಮಹಾಮಾತೃಶ್ರೀ',
'ಮಹಾರಜತ',
'ಮಹಾರಜನ',
'ಮಹಾರಾಜಶ್ರೀ',
'ಮಹಾರಾಜಿಕ',
'ಮಹಾರಾಯತಿ',
'ಮಹಾರಾಯಿತಿ',
'ಮಹಾರಾಷ್ಟ್ರಕ',
'ಮಹಾಲಕರಿ',
'ಮಹಾಲಕ್ಕುಮಿ',
'ಮಹಾಲತಾಂಗ',
'ಮಹಾವಿಭೂತಿ',
'ಮಹಾಸಚಿವ',
'ಮಹಾಸೌಷಿರ',
'ಮಹಾಸ್ರಗ್ಧರ',
'ಮಹಾಸ್ರಗ್ಧರೆ',
'ಮಹಿಪಾಲನೆ',
'ಮಹಿಮಾಕರ',
'ಮಹಿಮಾನತೆ',
'ಮಹಿಮಾವಂತ',
'ಮಹಿಮಾಶಾಲಿ',
'ಮಹಿಮೆವಡೆ',
'ಮಹಿಮೆವೆಱು',
'ಮಹಿಷನಾಥ',
'ಮಹಿಷಾಸುರ',
'ಮಹೀಪಾಲನೆ',
'ಮಹೇಂದ್ರಜಾಲ',
'ಮಹೇಂದ್ರಜಾಳ',
'ಮಹೇಂದ್ರಬಾಳೆ',
'ಮಹೋನ್ನತಿಕ್ಕೆ',
'ಮಹೋಪಕಾರ',
'ಮಳಮಳಿಕೆ',
'ಮಳಮಳಿಸು',
'ಮಳಯರುಹ',
'ಮಳಯಾನಿಲ',
'ಮಳಯಾನಿಳ',
'ಮಳರಹಿತ',
'ಮಳಲಕಂತೆ',
'ಮಳಲಕಾಂತಿ',
'ಮಳಲಸೌತೆ',
'ಮಳೆಗುಟಕ',
'ಮಳೆನಕ್ಷತ್ರ',
'ಮಳೆಮುಗಿಲು',
'ಮಳೆಸಗಡೆ',
'ಮಱಹುಗೊಳ್',
'ಮಱುಕಂದೋಱ್',
'ಮಱುಕಂಬಡೆ',
'ಮಱುಕಣಿಸು',
'ಮಱುಕವಡೆ',
'ಮಱುಕಳಿಕೆ',
'ಮಱುಕಳಿಸು',
'ಮಱುಕೊಳಿಸು',
'ಮಱುಗೞಿಗೆ',
'ಮಱುಜವಣಿ',
'ಮಱುಜೀವಣಿ',
'ಮಱುಜೆವಣ',
'ಮಱುಜೇವಣಿ',
'ಮಱುಜೇವಣೆ',
'ಮಱುದಿವಸ',
'ಮಱುದೆವಸ',
'ಮಱುವಗಲ್',
'ಮಱುವಸಲ',
'ಮಱುವಾನಿಸ',
'ಮಱುವೆಳಸು',
'ಮಱೆಗನ್ನಡ',
'ಮಱೆಗಾಳಗ',
'ಮಱೆವಾಳ್ತನ',
'ಮಱೆವುಯಿಲ್',
'ಮಱೆವೊಗಿಸು',
'ಮಱೆಸನ್ನೆಗೈ',
'ಮಱೆಹಿಡಿಕೆ',
'ಮೞಮೞಿಸು',
'ಮೞೆಗೞ್ತಲೆ',
'ಮಾಂಗಲ್ಯಗೇಯ',
'ಮಾಂಗಲ್ಯಭಾಗ್ಯ',
'ಮಾಂಗಲ್ಯಸೂಕ್ತ',
'ಮಾಂಗಲ್ಯಸೂತ್ರ',
'ಮಾಂಜರಪಾಟ',
'ಮಾಂಜರಪಾಟು',
'ಮಾಂಸಕವಳ',
'ಮಾಂಸಭಕ್ಷಕ',
'ಮಾಂಸಭಕ್ಷಕಿ',
'ಮಾಂಸಭಕ್ಷಣ',
'ಮಾಂಸರೋಹಿಣಿ',
'ಮಾಂಸವಿದಾರಿ',
'ಮಾಂಸಸಂಘಾತ',
'ಮಾಂಸಸೇವನ',
'ಮಾಂಸಸೇವನೆ',
'ಮಾಗಣಿಕಾಱ',
'ಮಾಗಧೀಲತೆ',
'ಮಾಟಗಾರಿಕೆ',
'ಮಾಣಿಕಂಗಾಣ್',
'ಮಾಣಿಕಗಾಣ್',
'ಮಾಣಿಕಗೆಂಟು',
'ಮಾಣಿಕಚಟ್ಟ',
'ಮಾಣಿಕವೆಸ',
'ಮಾಣಿಮಂಥರ',
'ಮಾತಂಗಕುಲ',
'ಮಾತಂಗಿಹುಲ್ಲು',
'ಮಾತಾಳಿತನ',
'ಮಾತುಗಾರಿಕೆ',
'ಮಾತುಗಾಱಿಕೆ',
'ಮಾತುಭಾರಿಕೆ',
'ಮಾತುಲಂಗಕ',
'ಮಾತುಲುಂಗಕ',
'ಮಾತುವಣಿಗ',
'ಮಾತುವಲ್ಲಹ',
'ಮಾತುಹಾರಿಸು',
'ಮಾತೃಕಾಕ್ಷರ',
'ಮಾತೃಶಾಸಿತ',
'ಮಾತೃಷ್ವಸೇಯ',
'ಮಾತೃಷ್ವಸೇಯಿ',
'ಮಾತೃಷ್ವಸ್ರೀಯ',
'ಮಾತ್ರಾನಿಯಮ',
'ಮಾತ್ರಾಸಮಕ',
'ಮಾದಕದ್ರವ್ಯ',
'ಮಾದಕಪೇಯ',
'ಮಾದಕವಸ್ತು',
'ಮಾದಿಗಾವರೆ',
'ಮಾಧ್ಯಸ್ಥವೃತ್ತಿ',
'ಮಾನಂಗೆಡಿಸು',
'ಮಾನಕಷಾಯ',
'ಮಾನಕೆಡಿಸು',
'ಮಾನಗರ್ವಿತೆ',
'ಮಾನನಿಧಾನೆ',
'ಮಾನಭಂಗಿತ',
'ಮಾನಭಂಗಿತೆ',
'ಮಾನಮಠಯ',
'ಮಾನಮರ್ದನ',
'ಮಾನವಜನ್ಮ',
'ಮಾನವಧರ್ಮ',
'ಮಾನವಲೋಕ',
'ಮಾನವಶಾಸ್ತ್ರ',
'ಮಾನವೀಯತೆ',
'ಮಾನಸಗೋಲ',
'ಮಾನಸಜ್ಞಾನ',
'ಮಾನಸದೇರ',
'ಮಾನಸಪುತ್ರ',
'ಮಾನಸಪೂಜೆ',
'ಮಾನಸವಾೞ್',
'ಮಾನಸವೇಗ',
'ಮಾನಸಶಾಸ್ತ್ರ',
'ಮಾನಸಸಿದ್ಧಿ',
'ಮಾನಸಸೃಷ್ಟಿ',
'ಮಾನಸಸ್ನಾನ',
'ಮಾನಸಾತ್ಮಜ',
'ಮಾನಸೌಕಸ',
'ಮಾನಿಸಗಳ್ಳಿ',
'ಮಾನಿಸಗಳ್ಳೆ',
'ಮಾನಿಸದೇರ',
'ಮಾನಿಸವಾೞ್',
'ಮಾನುಷಸಿದ್ಧಿ',
'ಮಾನೆಪುಸ್ತಕ',
'ಮಾನ್ಯಕರಣ',
'ಮಾಪಿಜಿನಿಸು',
'ಮಾಫಿಜಿನಸು',
'ಮಾಮಲೆದಾರ',
'ಮಾಮಲೇದಾರ',
'ಮಾಯಾಬಜಾರ',
'ಮಾಯಾಬಾಜಾರ',
'ಮಾಯಾಮರ್ದನ',
'ಮಾಯಾಮಸ್ತಕ',
'ಮಾಯಾವಿತನ',
'ಮಾಯಿಕಮಲ',
'ಮಾರಣಮಂತ್ರ',
'ಮಾರಣಹೋಮ',
'ಮಾರಿಕಾಜಾಡ್ಯ',
'ಮಾರಿಕಾರೋಗ',
'ಮಾರಿಕೆಹುಂಡಿ',
'ಮಾರಿವುಪದ್ರ',
'ಮಾರುತಪಥ',
'ಮಾರುತಿಮತ',
'ಮಾರುತ್ತರಿಸು',
'ಮಾರುವೆಹುಂಡಿ',
'ಮಾರ್ಕೊರಲ್ವಡೆ',
'ಮಾರ್ಗಣಾಸ್ಥಾನ',
'ಮಾರ್ಗದರ್ಶಕ',
'ಮಾರ್ಗದರ್ಶಕಿ',
'ಮಾರ್ಗದರ್ಶನ',
'ಮಾರ್ಗಹಿಂದೋಲ',
'ಮಾರ್ಗಹಿಂದೋಳ',
'ಮಾರ್ತಾಂಡಪಥ',
'ಮಾರ್ತಾಂಡೋದಯ',
'ಮಾರ್ತಾಂಡೋಪಲ',
'ಮಾರ್ದನಿಗುಡು',
'ಮಾರ್ದನಿಗೊಡು',
'ಮಾರ್ದನಿಗೊಳ್',
'ಮಾರ್ದನಿದೋಱು',
'ಮಾರ್ದನಿಯಿಡು',
'ಮಾರ್ದನಿವಿಡು',
'ಮಾರ್ಪಾಟುಗೊಳು',
'ಮಾರ್ಪಾಟುಗೊಳ್ಳು',
'ಮಾರ್ಪಾಡಿತನ',
'ಮಾಲಕಂಗುಣಿ',
'ಮಾಲಕಾಂಗೋಣಿ',
'ಮಾಲಗುಜಾರ',
'ಮಾಲಗುಜಾರಿ',
'ಮಾಲವಗೌಲ',
'ಮಾಲಾದೀಪಕ',
'ಮಾಲಾಪ್ರಸವ',
'ಮಾಲಾರೂಪಕ',
'ಮಾಲೂರಪತ್ರೆ',
'ಮಾಲೆವಡಿಗ',
'ಮಾಲೆವೂಗಾಱ',
'ಮಾಲೆಹೂಗಿಡ',
'ಮಾಲೋಪಮಾನ',
'ಮಾಲ್ಯಮಹೀಜ',
'ಮಾವಿನಶುಂಠಿ',
'ಮಾವುಕುದುರೆ',
'ಮಾಸಂಕೆಗೊಡು',
'ಮಾಸತಿಕಲ್ಲು',
'ಮಾಸಪತ್ರಿಕೆ',
'ಮಾಸರೋಹಿಣಿ',
'ಮಾಸಲುಕೆಂಪು',
'ಮಾಸಲುಗೆಂಪು',
'ಮಾಹೇಂದ್ರಕಲ್ಪ',
'ಮಾಹೇಶಮಂತ್ರ',
'ಮಾಳವಗೌಳ',
'ಮಾಳಿಗೆಮನೆ',
'ಮಾಱುಗವುಚು',
'ಮಿಂಚುಗಾರಿಕೆ',
'ಮಿಂಚುಗಾಱಿಕೆ',
'ಮಿಂಚುದೀವಿಗೆ',
'ಮಿಂಡಗಾರಿಕೆ',
'ಮಿಂಡಗಾಱಿಕೆ',
'ಮಿಗದೊವಲ್',
'ಮಿಗಲಚ್ಚಣ',
'ಮಿಗವಕ್ಕೆಗ',
'ಮಿಟಕಲಾಡಿ',
'ಮಿಟಗರಿಸು',
'ಮಿಟ್ಟೆವರಹ',
'ಮಿಠಾಯಿಗಾರ',
'ಮಿಠಾಲಕಡಿ',
'ಮಿಡಮಿಡನೆ',
'ಮಿಡಿನಾಗರ',
'ಮಿಡುಕುಗುಂದು',
'ಮಿಡುಕುಗೆಡು',
'ಮಿಡುಮಿಡನೆ',
'ಮಿಣಕುಹುಳ',
'ಮಿಣಿಮಿಣಿಸು',
'ಮಿಣುಕುದೀಪ',
'ಮಿಣುಕುಹುಳು',
'ಮಿಣ್ಣಂಗಿಹುಳು',
'ಮಿತಭಾಷಿಣಿ',
'ಮಿತವಚನ',
'ಮಿತ್ರಸಮ್ಮಿತ',
'ಮಿಥ್ಯಾಚರಣ',
'ಮಿಥ್ಯಾದರ್ಶನ',
'ಮಿಥ್ಯಾಪವಾದ',
'ಮಿಥ್ಯಾಪವಾದಿ',
'ಮಿಥ್ಯಾಪ್ರತ್ಯಯ',
'ಮಿಥ್ಯಾಭಾವನೆ',
'ಮಿಥ್ಯಾಭಿಯೋಗ',
'ಮಿದುಳುಜ್ವರ',
'ಮಿನಿಮಿನಿಸು',
'ಮಿನುಗುತಾರೆ',
'ಮಿನುಗುವಡೆ',
'ಮಿನುಮಿನನೆ',
'ಮಿರಾಸದಾರ',
'ಮಿರಾಸಿದಾರ',
'ಮಿರುಗುಕೊಡು',
'ಮಿರುಗುಹುಳ',
'ಮಿರುತಾರಗೆ',
'ಮಿರುವರಿಕೆ',
'ಮಿಶ್ರಪ್ರಕೃತಿ',
'ಮಿಶ್ರವಿವಾಹ',
'ಮಿಶ್ರವಿಷ್ಕಂಭ',
'ಮಿಷ್ಟಭೋಜನ',
'ಮಿಸುನಿಗಚ್ಚು',
'ಮಿಸುನಿಪಟ್ಟೆ',
'ಮಿಸುನಿವಟ್ಟೆ',
'ಮಿಸುನಿವೆಟ್ಟ',
'ಮಿಸುಪುದೋಱು',
'ಮಿಹಿಕಾಕರ',
'ಮಿಳಮಿಳನೆ',
'ಮಿಳಿಮಿಳಿತ',
'ಮಿಳುಮಿಳನೆ',
'ಮಿಱುಮಿಱನೆ',
'ಮೀಂಗುಲಿವಕ್ಕಿ',
'ಮೀಂಟುಂಜವ್ವನ',
'ಮೀಂಟುಂಜವ್ವನೆ',
'ಮೀಂಟುಜವ್ವನ',
'ಮೀಂಟುಜವ್ವನೆ',
'ಮೀಟುಂಜವ್ವನ',
'ಮೀಟುಂಜವ್ವನೆ',
'ಮೀಟುಜವ್ವನ',
'ಮೀಟುಜವ್ವನೆ',
'ಮೀಟುವರಸೆ',
'ಮೀನುಗಾರಿಕೆ',
'ಮೀಮಾಂಸಕಾರ',
'ಮೀಮಾಂಸಾಶಾಸ್ತ್ರ',
'ಮೀರ್‍ಸಾದಕ್',
'ಮೀರ್‍ಸಾದಕ',
'ಮೀಸಲುಕಟ್ಟು',
'ಮೀಸಲುಗಳೆ',
'ಮೀಸಲುಗೆಡು',
'ಮೀಸಲುನಿಧಿ',
'ಮೀಸಲುಪಡೆ',
'ಮೀಸಲುಮುರಿ',
'ಮೀಸಲುಸ್ಥಾನ',
'ಮೀಸಲೋಗರ',
'ಮೀಸಲ್ವೋಗಿಸು',
'ಮೀಱುಂಜೌವನ',
'ಮುಂಗಡಪತ್ರ',
'ಮುಂಗಡಪ್ರತಿ',
'ಮುಂಗಡಹಣ',
'ಮುಂಗಯ್ಯಮುರಿ',
'ಮುಂಗಲಿತನ',
'ಮುಂಗಾರಿಮಳೆ',
'ಮುಂಗಾರಿರುಳು',
'ಮುಂಗಾರುಮಳೆ',
'ಮುಂಗಾಲ್ಪುಟಿಗೆ',
'ಮುಂಗುಡಿಬಿಡು',
'ಮುಂಗುಡಿವರಿ',
'ಮುಂಗುಡಿವಿಡು',
'ಮುಂಗುಸಿಗಿಡ',
'ಮುಂಗೈಕಂಕಣ',
'ಮುಂಗೈಗಂಕಣ',
'ಮುಂಗೈನಿಗಳ',
'ಮುಂಗೈಮುರಾರಿ',
'ಮುಂಜಿಗಟ್ಟಿಸು',
'ಮುಂಡಜಯವೆ',
'ಮುಂಡಪಾಷಾಣ',
'ಮುಂಡಿಗೆಯಿಕ್ಕು',
'ಮುಂಡುಗಳಿಕ',
'ಮುಂಡುಮಾಳಿಗೆ',
'ಮುಂಡುಮುಳಕ',
'ಮುಂಡುಮುಳ್ಳುಗ',
'ಮುಂಡುಮುೞುಕ',
'ಮುಂತುಗೆಡಿಸು',
'ಮುಂದಡೆಯಿಡು',
'ಮುಂದಲೆಗೊಡು',
'ಮುಂದಲೆಗೊಳ್',
'ಮುಂದಲೆಬೊಟ್ಟು',
'ಮುಂದಲೆವಣಿ',
'ಮುಂದಾಲೋಚನೆ',
'ಮುಂದಾಳುತನ',
'ಮುಂದಿರಿಪಪ್ಪು',
'ಮುಂದಿರುಹಿಸು',
'ಮುಂದುಗತನ',
'ಮುಂದುಗಾಣಿಸು',
'ಮುಂದುಗಿಡಿಸು',
'ಮುಂದುಗೆಡಿಸು',
'ಮುಂದುವರಿಕೆ',
'ಮುಂದುವರಿಸು',
'ಮುಂದುಱುವರಿ',
'ಮುಂಬತ್ತಿ ಮರ',
'ಮುಂಬರಿವರಿ',
'ಮುಂಬಾರೆಕಾಱ',
'ಮುಕುರಂದೋಱು',
'ಮುಕುಳೀಕೃತ',
'ಮುಕ್ಕಡಿಗೆಯ್',
'ಮುಕ್ಕಣ್ಣವೈಜ್ಯ',
'ಮುಕ್ಕನಗಿಡು',
'ಮುಕ್ಕಾಲುಮಣೆ',
'ಮುಕ್ಕುಗೆಲಸ',
'ಮುಕ್ಕುಱುವಲ್',
'ಮುಕ್ತಕಂಚುಕ',
'ಮುಕ್ತಛಂದಸ್ಸು',
'ಮುಕ್ತಾಭರಣ',
'ಮುಕ್ತಾಭರಣೆ',
'ಮುಕ್ತಾಯಗೊಳ್ಳು',
'ಮುಕ್ತಿದಾಯಕ',
'ಮುಕ್ತಿದಾಯಕಿ',
'ಮುಖಚಹರೆ',
'ಮುಖಚಾಳೆಯ',
'ಮುಖಜಭೂಮಿ',
'ಮುಖದರ್ಶನ',
'ಮುಖದಾಕ್ಷಿಣ್ಯ',
'ಮುಖದಿರುಹು',
'ಮುಖದ್ರೋಣಿಕೆ',
'ಮುಖಪತ್ರಿಕೆ',
'ಮುಖಪಾವಡ',
'ಮುಖಪಾವುಡ',
'ಮುಖಪೂರಣ',
'ಮುಖಭಂಗಿತ',
'ಮುಖಭಂಗಿತೆ',
'ಮುಖಮಂಟಪ',
'ಮುಖಮಂಡನ',
'ಮುಖಮಂಡಪ',
'ಮುಖಮಜ್ಜನ',
'ಮುಖಮಾರ್ಜನ',
'ಮುಖಮಾರ್ಜನೆ',
'ಮುಖಮುದ್ರಿತ',
'ಮುಖಮುನಿಸು',
'ಮುಖರಗೊಳ್',
'ಮುಖರಭಾಷೆ',
'ಮುಖಲಕ್ಷಣ',
'ಮುಖಲೇಪನ',
'ಮುಖವರ್ಣಿಕೆ',
'ಮುಖವಾಸನ',
'ಮುಖವಿಕಾರ',
'ಮುಖವಿಕೃತಿ',
'ಮುಖಸುರತ',
'ಮುಖಾಮಿತನ',
'ಮುಖೇಡಿತನ',
'ಮುಖ್ತ್ಯಾರನಾಮೆ',
'ಮುಖ್ಯಮುತ್ತಗೆ',
'ಮುಖ್ಯಾಧಿಕಾರಿ',
'ಮುಗಿಲಟ್ಟಣೆ',
'ಮುಗಿಲಟ್ಟಳೆ',
'ಮುಗಿಲುನೂಲು',
'ಮುಗಿಲುವಟ್ಟೆ',
'ಮುಗಿಲ್ಗಡವು',
'ಮುಗಿಲ್ವಟ್ಟೆಗ',
'ಮುಗಿಲ್ವೆಳಗು',
'ಮುಗುದತನ',
'ಮುಗುದೆತನ',
'ಮುಗುಲುಧೂಪ',
'ಮುಗುಳಲರ್',
'ಮುಗುಳಿಗದ್ದೆ',
'ಮುಗುಳುಗಣ್',
'ಮುಗುಳುಗಣ್ಣು',
'ಮುಗುಳುಗಾಯಿ',
'ಮುಗುಳುನಗು',
'ಮುಗುಳುನಗೆ',
'ಮುಗುಳುವಿಡಿ',
'ಮುಗುಳುಹೊಡೆ',
'ಮುಗ್ಗೆಕೆಲಸ',
'ಮುಗ್ಧಹೃದಯ',
'ಮುಗ್ಧಹೃದಯೆ',
'ಮುಚ್ಚುಗದಹು',
'ಮುಚ್ಚುವಲಗೆ',
'ಮುಜಾರಿಯಾನ್',
'ಮುಟ್ಟಲುಗಾಣ್',
'ಮುಟ್ಟಲುಗಾಣು',
'ಮುಟ್ಟಲೆಗಾಣ್',
'ಮುಟ್ಟಲೆಗಾಣು',
'ಮುಟ್ಟಲೆಗೊಳ್',
'ಮುಟ್ಟವೆಗಾಣ್',
'ಮುಟ್ಟವೆಗಾಣು',
'ಮುಟ್ಟುಗಿಡಿಸು',
'ಮುಡಿಗೆಣಸು',
'ಮುಡಿಗೆದರು',
'ಮುಡಿಗೆಯಿಕ್ಕು',
'ಮುಡಿಗೆಯಿಡು',
'ಮುಡಿಗೊಳಿಸು',
'ಮುಡುಪುಕುಡು',
'ಮುಡುಪುಕೊಡು',
'ಮುಡುಬುಶೂಲೆ',
'ಮುಡುಹುಗೊಳ್',
'ಮುತ್‍ಫರ್ಖತ್',
'ಮುತ್ತಯ್ದೆತನ',
'ಮುತ್ತಗೆದೆಗೆ',
'ಮುತ್ತಿಗೆಯಿಕ್ಕು',
'ಮುತ್ತುಕಾರಾಚಿ',
'ಮುತ್ತುದೊಡವು',
'ಮುತ್ತುವಸರ',
'ಮುತ್ತೈದೆತನ',
'ಮುತ್ತೈದೆವೆಣ್',
'ಮುತ್ತೈದೆಹೆಣ್ಣು',
'ಮುತ್ಸದ್ದಿತನ',
'ಮುದಲಿಯಾರ್',
'ಮುದಲೆಕರು',
'ಮುದಲೆಗರು',
'ಮುದವಲರ್',
'ಮುದವೇಱಿಸು',
'ಮುದುಕತನ',
'ಮುದುಗಣ್ತನ',
'ಮುದುಡುಗೊಳ್',
'ಮುದುಪತನ',
'ಮುದುಮಂಕಡ',
'ಮುದ್ದತಿಠೇವು',
'ಮುದ್ದತಿದರ',
'ಮುದ್ದತಿಪತ್ರ',
'ಮುದ್ದತಿಹುಂಡಿ',
'ಮುದ್ದುಗೆಯಿತ',
'ಮುದ್ರಣಕಾರ',
'ಮುದ್ರಣಯಂತ್ರ',
'ಮುದ್ರಣಾಲಯ',
'ಮುದ್ರಾಧಾರಕ',
'ಮುದ್ರಾಧಾರಣ',
'ಮುದ್ರಾಧಾರಣೆ',
'ಮುದ್ರಾಧಿಕಾರ',
'ಮುದ್ರಾಧಿಕಾರಿ',
'ಮುದ್ರಾಪಂಚಕ',
'ಮುದ್ರಾಪಚಾರ',
'ಮುದ್ರಾಪತ್ರಿಕೆ',
'ಮುದ್ರಾಮಂಜೂಷ',
'ಮುದ್ರಾರಾಕ್ಷಸ',
'ಮುದ್ರೆಕೊಳಗ',
'ಮುದ್ರೆಮನುಷ್ಯ',
'ಮುದ್ರೆಯುಂಗುರ',
'ಮುದ್ರೆಯೂಳಿಗ',
'ಮುನಿಸಪ್ತಕ',
'ಮುನಿಸಿಪಾಲ್ಟಿ',
'ಮುನಿಸುಗುಟ್ಟು',
'ಮುನಿಸುಗೊಳ್ಳು',
'ಮುನ್ನಡಿಯಿಕ್ಕು',
'ಮುನ್ನಡೆಯಿಸು',
'ಮುನ್ನಡೆವಿಡಿ',
'ಮುನ್ನೀರಣುಗ',
'ಮುನ್ನೀರಣುಗಿ',
'ಮುನ್ನೀರೆರೆಯ',
'ಮುನ್ನೀರೆಱೆಯ',
'ಮುನ್ನೀರ್ವಕ್ಕೆಗ',
'ಮುನ್ನೀರ್ವೞ್ಕೆಗ',
'ಮುನ್ನೋಟವಿಕ್ಕು',
'ಮುನ್ಸಿಪಾಲಿಟಿ',
'ಮುನ್ಸೀಫ್‍ಕೋರ್ಟು',
'ಮುಪ್ಪುದಪ್ಪುಗ',
'ಮುಪ್ಪುರಗೊಳ್',
'ಮುಪ್ಪುರಹರ',
'ಮುಪ್ಪುರಿಗೂಡು',
'ಮುಪ್ಪುರಿಗೊಳ್',
'ಮುಪ್ಪುರಿಗೊಳು',
'ಮುಪ್ಪುರಿಗೊಳ್ಳು',
'ಮುಪ್ಪುರಿವಿಡಿ',
'ಮುಮರಿದಂಡ',
'ಮುಮುಕ್ಷುವೃತ್ತಿ',
'ಮುಮ್ಮರಿದಂಡ',
'ಮುಮ್ಮಳಿಗೊಳ್',
'ಮುಮ್ಮಳಿಗೊಳು',
'ಮುಮ್ಮಳಿಯೋಗು',
'ಮುಮ್ಮಳೆಗಾಲ',
'ಮುಮ್ಮಱಿದಂಡ',
'ಮುಮ್ಮುರಿಗಾಯ್',
'ಮುಮ್ಮುರಿಗೊಳ್',
'ಮುಮ್ಮುರಿತಂಡ',
'ಮುಮ್ಮುರಿದಂಡ',
'ಮುಮ್ಮುಳಿಗೊಳ್',
'ಮುಮ್ಮುಳಿಗೊಳು',
'ಮುಮ್ಮೊನೆಗಯ್ದು',
'ಮುಮ್ಮೊನೆಗೈದು',
'ಮುಮ್ಮೊನೆಯಾಳು',
'ಮುಮ್ಮೊನೆವಾಳು',
'ಮಯ್ಯೆರಡಡಿ',
'ಮುರಗಿಕಾಯಿ',
'ಮುರಜಬಂದ',
'ಮುರಲಿಗಾನ',
'ಮುರಲೀಧರ',
'ಮುರಳಿಗಾನ',
'ಮುರಳೀಧರ',
'ಮುರಿಕಿನಾಡು',
'ಮುರಿಗಟ್ಟಿಗೆ',
'ಮುರಿಗನಾಡು',
'ಮುರಿಗೆಗೊಳ್ಳು',
'ಮುರಿಗೆಡಹು',
'ಮುರಿಮಲಕು',
'ಮುರುಗಕಾಯಿ',
'ಮುರುಗಿಕಾಯಿ',
'ಮುರುಟುಗಟ್ಟು',
'ಮುರುಟುರೋಗ',
'ಮುರುಡುಗಲ್ಲು',
'ಮುರುಡುಗೊಳ್ಳು',
'ಮುರುಡುಗೋಲ್',
'ಮುರುಮುರಚಿ',
'ಮುರುಮುರುಚಿ',
'ಮುರುಲುಬೇನೆ',
'ಮುಲಕನಾಡು',
'ಮುಷ್ಕರಕಾರ',
'ಮುಷ್ಟಿಕಾಳಗ',
'ಮುಷ್ಟಿಪ್ರಹಾರ',
'ಮುಷ್ಟಿಮೈಥುನ',
'ಮುಸಂಡಿತನ',
'ಮುಸಲಘಾತ',
'ಮುಸಲಧರ',
'ಮುಸಲಧಾರೆ',
'ಮುಸಲಪಾಣಿ',
'ಮುಸಲಮಾನ್',
'ಮುಸಲಮಾನ',
'ಮುಸಲಸ್ನಾನ',
'ಮುಸಲಹತಿ',
'ಮುಸಲಾಯುಧ',
'ಮುಸಲುಮಾನ',
'ಮುಸಿಗೆಬಿಗಿ',
'ಮುಸಿಲ್ಲಮಾನ',
'ಮುಸುಂಕುಗಳೆ',
'ಮುಸುಂಡಿತನ',
'ಮುಸುಕುವಲೆ',
'ಮುಸುಗುಬ್ಬಸ',
'ಮುಸುಡುದೆಗೆ',
'ಮುಸುಡುದೋಱು',
'ಮುಸುರುಗಟ್ಟು',
'ಮುಸುರ್ಪುಗಟ್ಟು',
'ಮುಸುಲಮಾನ',
'ಮುಸುಲುಮಾನ',
'ಮುಹಚಾಳೆಯ',
'ಮುಹಮ್ಮದೀಯ',
'ಮುಹುರ್ಭವಿಸು',
'ಮುಹೂರ್ತವೇದಿ',
'ಮುಳುಕುನೀರ್',
'ಮುಳುಕೂಗರಿ',
'ಮುಳುಗುಮೀಹ',
'ಮುಳುಗುಹಕ್ಕಿ',
'ಮುಳುಗೆದಱು',
'ಮುಳುದೊಡಕು',
'ಮುಳುಮುತ್ತುಗ',
'ಮುಳುವೊರಜೆ',
'ಮುಳುಹುಗೋಲ್',
'ಮುಳ್ವತ್ತರಿಗೆ',
'ಮುಳ್ಳಿಕಾದಂಟು',
'ಮುಳ್ಳುಅಂಕೋಲೆ',
'ಮುಳ್ಳುಕಠಾರಿ',
'ಮುಳ್ಳುಕತ್ತರಿ',
'ಮುಳ್ಳುಕಾದಂಟು',
'ಮುಳ್ಳುಕೂಗರಿ',
'ಮುಳ್ಳುಕೊಳಿಂಜಿ',
'ಮುಳ್ಳುಗುಱುಚೆ',
'ಮುಳ್ಳುಗೆಣಸು',
'ಮುಳ್ಳುಗೊರಟಿ',
'ಮುಳ್ಳುಗೊರಟೆ',
'ಮುಳ್ಳುಗೋರಂಟ',
'ಮುಳ್ಳುಗೋರಂಟಿ',
'ಮುಳ್ಳುಗೋರಂಟೆ',
'ಮುಳ್ಳುಗೋರಟೆ',
'ಮುಳ್ಳುಚಮಚ',
'ಮುಳ್ಳುಚಮಚೆ',
'ಮುಳ್ಳುಜೀನಂಗಿ',
'ಮುಳ್ಳುನೆಗ್ಗಿಲು',
'ಮುಳ್ಳುಬದನೆ',
'ಮುಳ್ಳುಬಿದಿರು',
'ಮುಳ್ಳುಮರಿಗೆ',
'ಮುಳ್ಳುಮಸ್ತಿಗೆ',
'ಮುಳ್ಳುಮಾಚಿಗೆ',
'ಮುಳ್ಳುಮುತ್ತಗ',
'ಮುಳ್ಳುಮುತ್ತಲ',
'ಮುಳ್ಳುಮುತ್ತುಕ',
'ಮುಳ್ಳುಮುತ್ತುಗ',
'ಮುಳ್ಳುಯಲಗ',
'ಮುಳ್ಳುಸವುತೆ',
'ಮುಳ್ಳುಹರಿವೆ',
'ಮುಳ್ಳುಹಲಸು',
'ಮುಳ್ಳುಹಾಸಿಗೆ',
'ಮುಳ್ಳೋಡುಬಳ್ಳಿ',
'ಮುಱಿಗೆಗಯ್',
'ಮುಱುಕಂಬೆಱು',
'ಮುಱುಕಗಾತಿ',
'ಮುಱುಕದೋಱು',
'ಮೂಕತೆವೆಱು',
'ಮೂಕನಾಟಕ',
'ಮೂಕಬಸವ',
'ಮೂಕವಿಸ್ಮಿತ',
'ಮೂಕವಿಸ್ಮಿತೆ',
'ಮೂಕವೇದನೆ',
'ಮೂಗಾಳಿವಿಡಿ',
'ಮೂಗುತಿಸೊಪ್ಪು',
'ಮೂಗುಬಸವ',
'ಮೂಗುವಡಿಸು',
'ಮೂಗುಹರುಕ',
'ಮೂಗುಳಬುದ್ಧಿ',
'ಮೂಡಗಡಲ್',
'ಮೂಡುನೇಸಱ್',
'ಮೂಢನಂಬಿಕೆ',
'ಮೂತ್ರನಿರೋಧ',
'ಮೂತ್ರಪರೀಕ್ಷೆ',
'ಮೂತ್ರವಿಡ್ಘಾತ',
'ಮೂತ್ರಶರ್ಕರೆ',
'ಮೂಮೊನೆಗೈದು',
'ಮೂರುತಿಗೊಳ್ಳು',
'ಮೂರೆಲೆಯಾಟ',
'ಮೂರೆಲೆವೊನ್ನೆ',
'ಮೂರೆಲೆಹೊನ್ನೆ',
'ಮೂರ್ಛೆಗೊಳಿಸು',
'ಮೂರ್ತಗೊಳಿಸು',
'ಮೂರ್ತಿಭಂಜಕ',
'ಮೂರ್ತಿಭಂಜಕಿ',
'ಮೂರ್ತಿಭಂಜನೆ',
'ಮೂರ್ತೀಭವಿಸು',
'ಮೂರ್ಧಾಭಿಷಿಕ್ತ',
'ಮೂರ್ಧಾಭಿಷೇಕ',
'ಮೂಲಗುತ್ತಿಗೆ',
'ಮೂಲತ್ರಿಕೋಣ',
'ಮೂಲದಂಡಿಗೆ',
'ಮೂಲದೇವರು',
'ಮೂಲದ್ರಾವಿಡ',
'ಮೂಲನಕ್ಷತ್ರ',
'ಮೂಲನಿವಾಸ',
'ಮೂಲನಿವಾಸಿ',
'ಮೂಲಪುರುಷ',
'ಮೂಲಪ್ರಕೃತಿ',
'ಮೂಲಭದ್ರಿಕೆ',
'ಮೂಲಮಾಲಿಕೆ',
'ಮೂಲವಿಗ್ರಹ',
'ಮೂಲಶಿಕ್ಷಣ',
'ಮೂಲಸ್ಥಾಪಕ',
'ಮೂಲಾನಕ್ಷತ್ರ',
'ಮೂಲಿಕಾಸಿದ್ಧಿ',
'ಮೂಲಿಗಗೆಯಿ',
'ಮೂಲಿಗಮಾನ್ಯ',
'ಮೂಲಿಗೆಮದ್ದು',
'ಮೂಲಿಗೆಮರ್ದು',
'ಮೂಲಿಬಿದಿರು',
'ಮೂಲೆಗೊಂಗಡಿ',
'ಮೂಲೋತ್ಪಾಟನ',
'ಮೂಲೋತ್ಪಾಟನೆ',
'ಮೂಲ್ಯಮಾಪನ',
'ಮೂವಡಿನಾಡು',
'ಮೂವಿಟ್ಟಿಗೆಯ್',
'ಮೂವಿಧಿಗಾಣ್',
'ಮೂವಿಧಿಬಡು',
'ಮೂಷಂಡಿತನ',
'ಮೂಷಕಪರ್ಣಿ',
'ಮೂಷಕವಿಷ',
'ಮೂಷಿಕಪರ್ಣಿ',
'ಮೂಸಂಡಿತನ',
'ಮೂಸಕದೇರ',
'ಮೂಸುಂಡಿತನ',
'ಮೂಸುಕೊಳವೆ',
'ಮೂಳಪ್ರತಿಷ್ಠೆ',
'ಮೂಳವಾಸನೆ',
'ಮೂಱೆಲೆಗನ್ನೆ',
'ಮೂಱೆಲೆಹೊನ್ನೆ',
'ಮೃಗಜಾಲಿಕ',
'ಮೃಗಜಾಲಿಕೆ',
'ಮೃಗಜಾಳಿಕೆ',
'ಮೃಗತೃಷ್ಣಿಕೆ',
'ಮೃಗದಂಶಕ',
'ಮೃಗಧೂರ್ತಿಕ',
'ಮೃಗನಯನೆ',
'ಮೃಗನಾಭಿಜ',
'ಮೃಗಪಂಚಕ',
'ಮೃಗಬಂಧಿನಿ',
'ಮೃಗಲಾಂಛನ',
'ಮೃಗಲೋಚನ',
'ಮೃಗಲೋಚನೆ',
'ಮೃಗವಾಹನ',
'ಮೃಗವ್ಯಸನ',
'ಮೃಣಾಳಗುಣ',
'ಮೃತ್ಯುಂಜಯತೆ',
'ಮೃತ್ಯುದೇವತೆ',
'ಮೃತ್ಯುಸೂತಕ',
'ಮೃದುಪತ್ರಕ',
'ಮೃದುವಚನ',
'ಮೃಷ್ಟಭೋಜನ',
'ಮೆಂಡೆಯತನ',
'ಮೆಕ್ಕಲುಮಣ್ಣು',
'ಮೆಚ್ಚವಣಿಗೆ',
'ಮೆಚ್ಚುಗಾಣಿಕೆ',
'ಮೆಚ್ಚುವಣಿಗೆ',
'ಮೆಟರುಗಂಟು',
'ಮೆಟರುಗಟ್ಟು',
'ಮೆಟ್ಟುಗೆಗೆಯ್',
'ಮೆಟ್ಟುಹುರುಳಿ',
'ಮೆಡರುಗಟ್ಟು',
'ಮೆದುಳುಜ್ವರ',
'ಮೆಯಿಜೀವಿತ',
'ಮೆಯ್ಗಲಿತನ',
'ಮೆಯ್ಗಾವಲಿಗ',
'ಮೆಯ್ವಳಿಗೊಳ್',
'ಮೆರವಣಿಗೆ',
'ಮೆರವಳಿಗೆ',
'ಮೆರೆವಣಿಗೆ',
'ಮೆಲಕಾಡಿಸು',
'ಮೆಲುಕಸ್ತೂರಿ',
'ಮೆಲುಕಾಡಿಸು',
'ಮೆಹರ್ಬಾನಗಿ',
'ಮೆಳೆದಾಗುಡಿ',
'ಮೆಱವಣಿಗೆ',
'ಮೇಂಟಿಖಂಡುಗ',
'ಮೇಂಟಿಗಂಡುಗ',
'ಮೇಗವಣ್ಣಿಗೆ',
'ಮೇಗಾಲ್ದುಡಿಗೆ',
'ಮೇಗುಸಿರಿಡು',
'ಮೇಗುಸಿರ್ಗೊಳ್',
'ಮೇಗುಸಿರ್ವಡು',
'ಮೇಗುಸಿರ್ವಿಡು',
'ಮೇಘಕುಸುಮ',
'ಮೇಘಡಂಬರ',
'ಮೇಘನಿನದ',
'ಮೇಘನಿರ್ಘೋಷ',
'ಮೇಘನಿಸ್ವನ',
'ಮೇಘಪರ್ವತ',
'ಮೇಘಪಾಷಾಣ',
'ಮೇಘಮಲ್ಲಾರ',
'ಮೇಘಮಲ್ಹಾರ್',
'ಮೇಘಮಲ್ಹಾರ',
'ಮೇಘರಂಜಿನಿ',
'ಮೇಘವಣ್ಣಿಗೆ',
'ಮೇಘವಾಹನ',
'ಮೇಘಶ್ಯಾಮಳ',
'ಮೇಟಿಕೊಳವೆ',
'ಮೇಟಿಗಂಡುಗ',
'ಮೇಟಿಗೊಳಗ',
'ಮೇಟಿಹುರುಳಿ',
'ಮೇಟುಕೊಳಗ',
'ಮೇಟುಹುರುಳಿ',
'ಮೇಟೆಗೊಳಗ',
'ಮೇಣಗಪಟ',
'ಮೇಣಗಪ್ಪಟ',
'ಮೇಣಗಪ್ಪಡ',
'ಮೇದಿನೀಪತಿ',
'ಮೇದಿನೀಪಾಲ',
'ಮೇದೋಜೀರಕ',
'ಮೇಧಾವಿತನ',
'ಮೇರುಪರ್ವತ',
'ಮೇರುಪ್ರಸ್ತಾರ',
'ಮೇರುಮಂದರ',
'ಮೇರೆಗೆಡಿಸು',
'ಮೇಲಧಿಕಾರ',
'ಮೇಲಧಿಕಾರಿ',
'ಮೇಲಾಟಗಾರ',
'ಮೇಲುಗರಿಕೆ',
'ಮೇಲುಗವಚ',
'ಮೇಲುಗಾಲುವೆ',
'ಮೇಲುಗಾವಲು',
'ಮೇಲುಗಾಳಗ',
'ಮೇಲುಗಾಳೆಗ',
'ಮೇಲುಗೆಲಸ',
'ಮೇಲುತೆರಿಗೆ',
'ಮೇಲುದುಪ್ಪಟ',
'ಮೇಲುದುವ್ವಟ',
'ಮೇಲುಪಚ್ಚಳ',
'ಮೇಲುಪ್ಪರಿಗೆ',
'ಮೇಲುಮಾಳಿಗೆ',
'ಮೇಲುಮುಚುಳಿ',
'ಮೇಲುಮುಚ್ಚಳ',
'ಮೇಲುಮುಸುಕು',
'ಮೇಲುವತ್ತಿಗೆ',
'ಮೇಲುವಲಗೆ',
'ಮೇಲುಶೋಧನೆ',
'ಮೇಲುಸಿರಿಡು',
'ಮೇಲುಸುರಿಡು',
'ಮೇಲುಸೆರಗು',
'ಮೇಲುಸೆಱಗು',
'ಮೇಲುಸ್ತುವಾರಿ',
'ಮೇಲುಹಾಸಿಗೆ',
'ಮೇಲುಹೊದಕೆ',
'ಮೇಲುಹೊದಿಕೆ',
'ಮೇಲ್ತರಗತಿ',
'ಮೇಲ್ಮಲೆಗೆಯ್',
'ಮೇಲ್ವಿಚಾರಕ',
'ಮೇಲ್ವಿಚಾರಕಿ',
'ಮೇಲ್ವಿಚಾರಣೆ',
'ಮೇಷಕಂಬಲ',
'ಮೇಷಕಂಬಳ',
'ಮೇಹನದ್ವಾರ',
'ಮೇಹರ್ಬಾನಗಿ',
'ಮೇಳಗಾಳೆಗ',
'ಮೇಳಣೆವಡೆ',
'ಮೈಗಲಿತನ',
'ಮೈಗಳ್ಳತನ',
'ಮೈತ್ರಾಯಣೀಯ',
'ಮೈತ್ರಾವರುಣ',
'ಮೈತ್ರಾವರುಣಿ',
'ಮೈದುನತನ',
'ಮೈಲಿಗೆಗಣ್ಣು',
'ಮೈಲಿಗೆಸಂತೆ',
'ಮೈಲುವಾಹನ',
'ಮೈಸಾಲಪತ್ರ',
'ಮೈಸೂರ್‍ಪಾಕ್',
'ಮೈಸೂರುಪಾಕು',
'ಮೈಸೋಂಬತನ',
'ಮೈಹಳುವಾಯಿ',
'ಮೊಂಗರವಳ್ಳಿ',
'ಮೊಕರವಲೆ',
'ಮೊಕರೂರಾತಿ',
'ಮೊಕ್ತೇಸರಿಕೆ',
'ಮೊಖರಿಗಾಳೆ',
'ಮೊಖ್ತ್ಯಾರ್‍ನಾಮೆ',
'ಮೊಗಂಗಿಡಿಸು',
'ಮೊಗಂದಿರಿಪು',
'ಮೊಗಂದಿರುಪು',
'ಮೊಗಂದಿರುವು',
'ಮೊಗಂದಿರುಹು',
'ಮೊಗಂಬುಗಿಸು',
'ಮೊಗಗತ್ತಲೆ',
'ಮೊಗಗನ್ನಡಿ',
'ಮೊಗಗಳ್ತಲೆ',
'ಮೊಗಗೆಡಿಸು',
'ಮೊಗದಿರುಹು',
'ಮೊಗದುಗುಲ',
'ಮೊಗದುಮ್ಮಾನ',
'ಮೊಗನಾಯಕ',
'ಮೊಗಪಾವಡ',
'ಮೊಗಮಂಟಪ',
'ಮೊಗಮಜ್ಜನ',
'ಮೊಗಮರಸು',
'ಮೊಗವಟ್ಟವ',
'ಮೊಗವರಿಕೆ',
'ಮೊಗವರಿಗೆ',
'ಮೊಗವಱಿಕೆ',
'ಮೊಗವಱಿತ',
'ಮೊಗವಾವುಡ',
'ಮೊಗಸೂನಿಗೆ',
'ಮೊಗ್ಗರಂಬಾಯ್',
'ಮೊಗ್ಗರಂಬೊಯ್',
'ಮೊಗ್ಗರವಾಯ್',
'ಮೊಟ್ಟಮೊದಲು',
'ಮೊಣ್ಣಮಂಡಲ',
'ಮೊತ್ತಗೊಳಿಸು',
'ಮೊತ್ತಮೊದಲ್',
'ಮೊತ್ತಮೊದಲು',
'ಮೊದಲಗಿತ್ತಿ',
'ಮೊದಲನೆಯ',
'ಮೊದಲಿಯಾರ್',
'ಮೊದಲುಗಣ್',
'ಮೊದಲುಗೆಡು',
'ಮೊದಲುಗೊಂಬು',
'ಮೊದಲುಗೊಳ್',
'ಮೊದಲುಱುಬು',
'ಮೊದಲ್ಗೆಡಿಸು',
'ಮೊದಲ್ಗೊಳುಹ',
'ಮೊದಿರಕಣ್ಣಿ',
'ಮೊದ್ದುಗಳಹು',
'ಮೊನಚುಗೊಳ್ಳು',
'ಮೊನೆಗಡಲೆ',
'ಮೊನೆಗಾಣಿಸು',
'ಮೊನೆಗಾಱಿಕೆ',
'ಮೊರಟುತನ',
'ಮೊರಡುಗೋಡು',
'ಮೊರಡುತನ',
'ಮೊರಡುಬೀಳು',
'ಮೊಲಗತ್ತಲೆ',
'ಮೊಲಗರ್ತಲೆ',
'ಮೊಲಗಳೆಯ',
'ಮೊಲಗಳ್ತಲೆ',
'ಮೊಲಗೞ್ತಲೆ',
'ಮೊಲಗಿವಿಗ',
'ಮೊಲನಾಗರ',
'ಮೊಲನಾಗರು',
'ಮೊಲೆಕುಡಿಸು',
'ಮೊಲೆಗೊಳಿಸು',
'ಮೊಲೆನಾಗರ',
'ಮೊಲೆನಾಗರು',
'ಮೊಲೆಯುಣಿಸು',
'ಮೊಸರುತನ',
'ಮೊಸರುವಡೆ',
'ಮೊಸರೋಗರ',
'ಮೊಳಕೆಗಾಣು',
'ಮೊಳೆಗಾಣಿಸು',
'ಮೊಳೆಜವ್ವನ',
'ಮೊಳೆದೋಱಿಸು',
'ಮೊಳೆವರೆಯ',
'ಮೊಱಹುಗುಂದು',
'ಮೊಱೆಗಿಡಿಸು',
'ಮೋಂಬತ್ತಿಬೀಜ',
'ಮೋಕ್ಷದಾಯಕ',
'ಮೋಕ್ಷದಾಯಕಿ',
'ಮೋಚನಿರ್ಯಾಸ',
'ಮೋಜಣಿದಾರ',
'ಮೋಜಣೀಖಾತೆ',
'ಮೋಜಣೀದಾರ',
'ಮೋಜಿನಿದಾರ',
'ಮೋಜಿನೀದಾರ',
'ಮೋಜುಗಾರಿಕೆ',
'ಮೋಡಗತ್ತಲೆ',
'ಮೋಡೆರಕ್ಕಣಿ',
'ಮೋದೋಪಚಾರ',
'ಮೋನಾನುಷ್ಟಾನ',
'ಮೋನಾನುಷ್ಠಾಣ',
'ಮೋನಾನುಷ್ಠಾನ',
'ಮೋನಗುರುವ',
'ಮೋನಿಗೊರವ',
'ಮೋನಿಭಟಾರ',
'ಮೋಪುಗಾರಿಕೆ',
'ಮೋಸಗಾರಿಕೆ',
'ಮೋಸಗೊಳಿಸು',
'ಮೋಹಗೊಳಿಸು',
'ಮೋಹತಮೀಮ',
'ಮೋಹನಗಾರ',
'ಮೋಹನಗೌಳ',
'ಮೋಹನಚೂರ್ಣ',
'ಮೋಹನನಾಟ',
'ಮೋಹನಮಾಲೆ',
'ಮೋಹನವಡೆ',
'ಮೋಹನಶಾಸ್ತ್ರ',
'ಮೋಹರಂಬಡೆ',
'ಮೋಹರಂಬೆಱು',
'ಮೋಹರದೆಗೆ',
'ಮೋಹಿನಿಅಟ್ಟಂ',
'ಮೋಹೋಪಮಿತ',
'ಮೌಂಜೀಧಾರಣ',
'ಮೌಂಜೀಬಂಧನ',
'ಮೌನಕಲಹ',
'ಮೌನಸಮ್ಮತಿ',
'ಮೌರವಪಾಶ',
'ಮೌಲ್ಯನಿರ್ಣಯ',
'ಮೌಲ್ಯಮಾಪನ',
'ಮ್ಯಾಣಗಪ್ಪಟ',
'ಮ್ಯಾನೇಜರಿಕೆ',
'ಯಂತ್ರಚಾಲಕ',
'ಯಂತ್ರಚಾಲಿತ',
'ಯಂತ್ರಪ್ರಯೋಗ',
'ಯಂತ್ರವಾಹಕ',
'ಯಂತ್ರಶಿಲ್ಪಜ್ಞ',
'ಯಂತ್ರಶಿಲ್ಪಜ್ಞೆ',
'ಯಂತ್ರೋದ್ಧರಣ',
'ಯಕ್ಷಕರ್ದಮ',
'ಯಕ್ಷಿಣಿಗಾರ',
'ಯಕ್ಷಿಣಿವಿದ್ಯ',
'ಯಕ್ಷಿಣಿವಿದ್ಯೆ',
'ಯಜಮಾನತಿ',
'ಯಜಮಾನಿಕೆ',
'ಯಜಮಾನಿತಿ',
'ಯಜ್ಞಕಂಕಣ',
'ಯಜ್ಞತುರಗ',
'ಯಜ್ಞದೀಕ್ಷಿತ',
'ಯಜ್ಞಪುರುಷ',
'ಯಜ್ಞಸಾಧನ',
'ಯಜ್ಞೋಪವೀತ',
'ಯಡಾನಿಬಳ್ಳಿ',
'ಯತ್ನೀಕರಿಸು',
'ಯಥಾಕಥಿತ',
'ಯಥಾಪ್ರಕಾರ',
'ಯಥಾಪ್ರಯೋಗ',
'ಯಥಾರ್ಥಜ್ಞಾನ',
'ಯಥಾರ್ಥವರ್ಣ',
'ಯಥಾರ್ಥವಾದಿ',
'ಯಥಾರ್ಹವರ್ಣ',
'ಯಥಾವಕಾಶ',
'ಯಥಾಸಂಭವ',
'ಯಥೋಪದ್ದಿಷ್ಟ',
'ಯಮದಶಮ',
'ಯಮದ್ವಿತೀಯೆ',
'ಯಮನಗರಿ',
'ಯಮಪಾಕುಳ',
'ಯಮಯಾತನೆ',
'ಯಮಸಮಾಧಿ',
'ಯಮಸಾಧನೆ',
'ಯವಯೋಗಜ',
'ಯವ್ವನಶಾಲಿ',
'ಯಶಃಪಟಹ',
'ಯಶೋದುಂದುಭಿ',
'ಯಶೋನಿಳಯ',
'ಯಷ್ಟಿಮಧುಕ',
'ಯಾಗಮಂಟಪ',
'ಯಾತನೆಗೊಳ್',
'ಯಾದಸಾಂನಾಥ',
'ಯಾದಸಾಂಪತಿ',
'ಯಾನಯುಗಳ',
'ಯಾಪ್ಯಯಾನಕ',
'ಯಾಮಿಕವೃತ್ತಿ',
'ಯಾಮಿನೀಪತಿ',
'ಯಾಲಕ್ಕಿಬಾಳೆ',
'ಯಾವಕಕೃಚ್ಛ್ರ',
'ಯಾವಕದ್ರವ',
'ಯಾವಕರಸ',
'ಯುಕುತಿವಿದ',
'ಯುಕುತಿವಿದೆ',
'ಯುಕ್ತಯಾರಿಕೆ',
'ಯುಕ್ತರೂಪಕ',
'ಯುಕ್ತ್ಯಲಂಕಾರ',
'ಯುಗಕೀಲಕ',
'ಯುಗಪತ್ರಕ',
'ಯುಗಪುರುಷ',
'ಯುಗಾವಸಾನ',
'ಯುದ್ಧವಿರಾಮ',
'ಯುದ್ಧಸನ್ನದ್ಧ',
'ಯುವರಾಜತೆ',
'ಯೋಗತೆವಡೆ',
'ಯೋಗದರ್ಶನ',
'ಯೋಗನಿರೋಧ',
'ಯೋಗಪಟ್ಟಿಕೆ',
'ಯೋಗಪಟ್ಟಿಗೆ',
'ಯೋಗಪುರುಷ',
'ಯೋಗವಂಟಿಗೆ',
'ಯೋಗವಟ್ಟಿಗೆ',
'ಯೋಗವರ್ತನ',
'ಯೋಗವಿಜ್ಞಾನ',
'ಯೋಗಸಮಾಧಿ',
'ಯೋಗಿಪ್ರತ್ಯಕ್ಷ',
'ಯೋಗ್ಯತಾಪತ್ರ',
'ಯೋಚನಾಪರ',
'ಯೋಚನಾಶಕ್ತಿ',
'ಯೋಜನಗಂಧಿ',
'ಯೋಜನವಲ್ಲಿ',
'ಯೋನಿನೆತ್ತರು',
'ಯೋನಿಶಿಶ್ನಿಕೆ',
'ಯೌವನಗಾರ್ತಿ',
'ಯೌವನಮದ',
'ಯೌವನಾವಸ್ಥೆ',
'ಯೌವನೋದಯ',
'ರಂಕರಾಟಣ',
'ರಂಗತಾಲೀಮು',
'ರಂಗಪಂಚಮಿ',
'ರಂಗಭೂಮಿಕೆ',
'ರಂಗಮಂಟಪ',
'ರಂಗಮಂಡಪ',
'ರಂಗಮಲರ್',
'ರಂಗಮಹಲು',
'ರಂಗಸಜ್ಜಿಕೆ',
'ರಂಗಸಾಮಗ್ರಿ',
'ರಂಗಸಿದ್ಧತೆ',
'ರಂಗೀಬಸರಿ',
'ರಂಗುಗೊಳಿಸು',
'ರಂಗುಮಾಣಿಕ',
'ರಂಜನೆವಡೆ',
'ರಂಡೆಹುಣ್ಣಿಮೆ',
'ರಂಡೆಹುಣ್ಣಿವೆ',
'ರಂದಣಗಿತ್ತಿ',
'ರಂಧ್ರಾನ್ವೇಷಣ',
'ರಂಧ್ರೀಕರಣ',
'ರಂಭಾಶೂಲ್ಯಕ',
'ರಕ್ಕಸಂಕಲೆ',
'ರಕ್ಕಸಬೇರ',
'ರಕ್ಕಸವಕ್ಕಿ',
'ರಕ್ಕಸವಗೆ',
'ರಕ್ಕಸವೇರ',
'ರಕ್ಕಸಿದಬ್ಬೆ',
'ರಕ್ತಕಂಬಲ',
'ರಕ್ತಕಂಬಳ',
'ರಕ್ತಕರ್ದಮ',
'ರಕ್ತಕೊಡಗೆ',
'ರಕ್ತಕೊಡಿಗೆ',
'ರಕ್ತಗೊಡಿಗೆ',
'ರಕ್ತಗ್ರಹಣಿ',
'ರಕ್ತಚಂದನ',
'ರಕ್ತಚಂಪಕ',
'ರಕ್ತಚಲನೆ',
'ರಕ್ತಜಿಹ್ವಕ',
'ರಕ್ತಪರೀಕ್ಷೆ',
'ರಕ್ತಪವಳ',
'ರಕ್ತಪಿಂಡಾಲ',
'ರಕ್ತಪಿಂಡಾಲು',
'ರಕ್ತಪಿಪಾಸಿ',
'ರಕ್ತಪಿಪಾಸು',
'ರಕ್ತಪಿಪಾಸೆ',
'ರಕ್ತಪ್ರಕೋಪ',
'ರಕ್ತಪ್ರಮೇಹ',
'ರಕ್ತಮಂಜರಿ',
'ರಕ್ತಮಂಡಲ',
'ರಕ್ತಮೇಹನ',
'ರಕ್ತರೋಹಿತ',
'ರಕ್ತವಮನ',
'ರಕ್ತಶೋಷಣ',
'ರಕ್ತಸಂಬಂಧ',
'ರಕ್ತಸಂಬಂಧಿ',
'ರಕ್ತಸಿಂಹಾಣ',
'ರಕ್ತಾತಿಸಾರ',
'ರಕ್ಷಣಾಖಾತೆ',
'ರಕ್ಷಣಾದಳ',
'ರಕ್ಷಣೋಪಾಯ',
'ರಕ್ಷಾಕವಚ',
'ರಕ್ಷಾಬಂಧನ',
'ರಕ್ಷಿಸುವಣ',
'ರಕ್ಷೋಗಾಂಧಾರಿ',
'ರಖಂವ್ಯಾಪಾರ',
'ರಖಾವುದಾರ',
'ರಗಣಲಿಪಿ',
'ರಘಟಾಬಂಧ',
'ರಚನಂಗೆಯ್',
'ರಚನಕಾರ',
'ರಚನೆವಂತ',
'ರಚಯಿತಾರ',
'ರಜತಗಿರಿ',
'ರಜತೋತ್ಸವ',
'ರಜನಿಕರ',
'ರಜನಿಚರ',
'ರಜನೀಗಂಧ',
'ರಜನೀಚರ',
'ರಜನೀಚರಿ',
'ರಜನೀಚಾರಿ',
'ರಜನೀಮುಖ',
'ರಜನೀರಜ',
'ರಜಪೊರಿಗೆ',
'ರಜೋದರ್ಶನ',
'ರಜೋವಿಕೃತಿ',
'ರಟ್ಟೆಪಲ್ಲವಿ',
'ರಣಕಹಳೆ',
'ರಣಗಹಳೆ',
'ರಣಬಿಸಿಲು',
'ರಣರಣಕ',
'ರಣರಣಿಕೆ',
'ರಣಲಂಪಟ',
'ರಣವೀಳಯ',
'ರಣವ್ಯಸನಿ',
'ರಣಹಲಗೆ',
'ರಣಹೂಣಿಗ',
'ರಣಹೇಡಿಗ',
'ರತಕೂಜಿತ',
'ರತ್ನಕಂಕಣ',
'ರತ್ನಕಂಬಳಿ',
'ರತ್ನಗಂಬಳಿ',
'ರಥಗೋಪನ',
'ರಥಚರಣ',
'ರಥಚೋದಕ',
'ರಥಸಪ್ತಮಿ',
'ರಥಾಂಗಧರ',
'ರಥಿನೀನಾಥ',
'ರದನಚ್ಛದ',
'ರದಬದಲಿ',
'ರದ್ದುಗೊಳಿಸು',
'ರದ್ದುಪಡಿಸು',
'ರನ್ನಗಂಬಳಿ',
'ರನ್ನದಲೆಗ',
'ರನ್ನವರದ',
'ರನ್ನವಸರ',
'ರನ್ನವಸಿಱ್',
'ರಪರಪನೆ',
'ರಫ್ತುವ್ಯಾಪಾರ',
'ರಬ್ಬರುಮರ',
'ರಮಣೀಯತೆ',
'ರಮಾರಮಣ',
'ರಮ್ಯತೆವಡೆ',
'ರವಾನೆದಾರ',
'ರವಿಚಂದ್ರಿಕೆ',
'ರವಿಮಂಡಲ',
'ರವಿಮಂಡಳ',
'ರವಿಸಿದ್ಧಾಂತ',
'ರಶ್ಮಿಕಲಾಪ',
'ರಸಕವಳ',
'ರಸಗಂಧಕ',
'ರಸಗಂದಾಯ',
'ರಸಗತ್ತಲೆ',
'ರಸಗವಳ',
'ರಸಗಳಿಗೆ',
'ರಸಗ್ರಹಣ',
'ರಸಘಳಿಗೆ',
'ರಸಘುಟಿಕೆ',
'ರಸತವರ',
'ರಸದರ್ಶನ',
'ರಸನಾಂಚಲ',
'ರಸನಿಮಿಷ',
'ರಸನೇಂದ್ರಿಯ',
'ರಸಪಂಚಕ',
'ರಸಬುರುಡೆ',
'ರಸಭರಿತ',
'ರಸಮಂಜರಿ',
'ರಸಮಗ್ನತೆ',
'ರಸಮೂಲಿಕೆ',
'ರಸವಂತಿಕೆ',
'ರಸವಣಿಗ',
'ರಸವದ್ಗೇಯ',
'ರಸವಾದಕ',
'ರಸವಿಕಾರ',
'ರಸವಿಜ್ಞಾನ',
'ರಸವಿಜ್ಞಾನಿ',
'ರಸಶಾಸ್ತ್ರಜ್ಞ',
'ರಸಶಾಸ್ತ್ರಜ್ಞೆ',
'ರಸಸಿಂಧೂರ',
'ರಸಹೀನತೆ',
'ರಸಾತ್ಮಕತೆ',
'ರಸಾಧಿಭೂತೆ',
'ರಸಾನುಭವ',
'ರಸಾನುಭೂತಿ',
'ರಸಾಭಿವ್ಯಕ್ತಿ',
'ರಸಾಯನಜ್ಞ',
'ರಸಾಯನಜ್ಞೆ',
'ರಸಾಲಂಕಾರ',
'ರಸಿಕತನ',
'ರಸಿಕಪ್ರಿಯ',
'ರಸಗಳ್ತಲೆ',
'ರಸೋಪಾಸಕ',
'ರಸ್ತುಮುಸ್ತೈದೆ',
'ರಸ್ತೆಸಾರಿಗೆ',
'ರಹಣಿಕಾಱ',
'ರಾಕ್ಷಸಗಣ',
'ರಾಕ್ಷಸಬಾಳೆ',
'ರಾಕ್ಷಸಭೇದಿ',
'ರಾಕ್ಷಸಮೌನ',
'ರಾಕ್ಷಸಸತ್ವ',
'ರಾಕ್ಷಸಾಂಶಕೆ',
'ರಾಕ್ಷಸೀಯತೆ',
'ರಾಗಪಂಜರ',
'ರಾಗಮಾಲಿಕೆ',
'ರಾಗಮಾಲಿನಿ',
'ರಾಗರಸಿಕ',
'ರಾಗವರ್ಧಿನಿ',
'ರಾಗಾಲಾಪನ',
'ರಾಗಾಲಾಪನೆ',
'ರಾಗಾವಲಂಬ',
'ರಾಗಿಪಿಪ್ಪಲು',
'ರಾಜಋಷಿತ್ವ',
'ರಾಜಕದಲಿ',
'ರಾಜಕದಳ',
'ರಾಜಕದಳಿ',
'ರಾಜಕಪೋತ',
'ರಾಜಕಶೇರು',
'ರಾಜಕಳಾಪ',
'ರಾಜಕಾರಣ',
'ರಾಜಕಾರಣಿ',
'ರಾಜಕಾರಿಯ',
'ರಾಜಕೀರಕ',
'ರಾಜಕುಮಾರ',
'ರಾಜಕುಮಾರಿ',
'ರಾಜಕುವರ',
'ರಾಜಕುವರಿ',
'ರಾಜಖರ್ಜೂರ',
'ರಾಜಗಾಂಭೀರ್ಯ',
'ರಾಜಗಾಮಿತ',
'ರಾಜಗುವರ',
'ರಾಜತಂತ್ರಜ್ಞ',
'ರಾಜತಂತ್ರಜ್ಞೆ',
'ರಾಜದಂಡನೆ',
'ರಾಜದರ್ಬಾರು',
'ರಾಜದಾರಕ',
'ರಾಜನೀತಿಜ್ಞ',
'ರಾಜಪುರುಷ',
'ರಾಜಪೂಜಿತ',
'ರಾಜಪೂಜಿತೆ',
'ರಾಜಪ್ರಕೃತಿ',
'ರಾಜಪ್ರಮುಖ',
'ರಾಜಬಿತ್ತರಿ',
'ರಾಜಭವನ',
'ರಾಜಮಂಡಲ',
'ರಾಜಮಂಡಳ',
'ರಾಜಮಂದಿರ',
'ರಾಜಮನ್ನಣೆ',
'ರಾಜಮರಾಲ',
'ರಾಜಮರಾಳ',
'ರಾಜಮರ್ಯಾದೆ',
'ರಾಜಮಹಿಷಿ',
'ರಾಜಮಾತಂಗಿ',
'ರಾಜರಹಸ್ಯ',
'ರಾಜವಂಶೀಯ',
'ರಾಜವಾಹನ',
'ರಾಜವಿಡ್ಡೂರ',
'ರಾಜಶಾಸನ',
'ರಾಜಶೇಖರ',
'ರಾಜಸದನ',
'ರಾಜಸರ್ಷಪ',
'ರಾಜಸಿಕತೆ',
'ರಾಜಾಂಗವಸ್ತ್ರ',
'ರಾಜಾಧಿರಾಜ',
'ರಾಜಾಭರಣ',
'ರಾಜಾರೂಪಾಯಿ',
'ರಾಜಿಕಬೂಲಿ',
'ರಾಜೋಪಚಾರ',
'ರಾಜ್ಞೀನಿವಾಸ',
'ರಾಜ್ಯಕಾರಿಯ',
'ರಾಜ್ಯನೀತಿಜ್ಞ',
'ರಾಜ್ಯವಿಚ್ಯುತ',
'ರಾಜ್ಯವಿಚ್ಯುತೆ',
'ರಾಜ್ಯವಿಭ್ರಂಶ',
'ರಾಜ್ಯಾಭಿಷೇಕ',
'ರಾಣಿಗೆದ್ದಲು',
'ರಾತ್ರಿಜಾಗರ',
'ರಾತ್ರಿಪ್ರತಿಮೆ',
'ರಾತ್ರಿವಿರಾಮ',
'ರಾಮಣೀಯಕ',
'ರಾಮಣೀಯತೆ',
'ರಾಮತುಳಸಿ',
'ರಾಮನಡಿಕೆ',
'ರಾಮನವಮಿ',
'ರಾಮರಗಡು',
'ರಾಮವಚನ',
'ರಾಮಸುಪಾರಿ',
'ರಾಮಾಭಿರಾಮಿ',
'ರಾಮಾವತಾರ',
'ರಾಯಗಿಡಿಗ',
'ರಾಯಗೆಲಸ',
'ರಾಯಗೋಪುರ',
'ರಾಯಡೆಂಕಣಿ',
'ರಾಯತವಸಿ',
'ರಾಯದವಸಿ',
'ರಾಯನೇರಿಲು',
'ರಾಯಬೊಗರಿ',
'ರಾಯಭೇರಿಗೆ',
'ರಾಯವಜೀರ',
'ರಾಯವತ್ತಳೆ',
'ರಾಯಸಭಾರಿ',
'ರಾಯಸವಿದ್ಯೆ',
'ರಾವಣಕರ',
'ರಾವಣಗೆಡ್ಡೆ',
'ರಾವಣವೀಣೆ',
'ರಾವಣಶಾಖ',
'ರಾವಣಹಸ್ತ',
'ರಾವಸಾಹೇಬ',
'ರಾವುಕನಡಿ',
'ರಾವುಗನ್ನಡಿ',
'ರಾವುತಪಡೆ',
'ರಾಶಿಕುಂಡಲಿ',
'ರಾಶಿಚಿಕ್ಕಣಿ',
'ರಾಶಿಭವಿಷ್ಯ',
'ರಾಷ್ಟ್ರಾಭಿಮಾನ',
'ರಾಷ್ಟ್ರಾಭಿಮಾನಿ',
'ರಾಷ್ಟ್ರೀಕರಣ',
'ರಾಸಾಯನಿಕ',
'ರಾಹುಗ್ರಹಣ',
'ರಾಹುಸಂಸ್ಪರ್ಶ',
'ರಾಳಕರ್ಪುರ',
'ರಿಂಗಣಂಗುಣಿ',
'ರಿಂಗಣಕುಣಿ',
'ರಿಂಗಣಗುಣಿ',
'ರಿಕ್ತಗೊಳಿಸು',
'ರಿಬ್ಬನ್‍ಹುಲ್ಲು',
'ರಿಭುವಿಮಾನ',
'ರಿಸಾಲುದಾರ',
'ರೀತಿಗೆಡಿಸು',
'ರೀತಿವಿಫಲ',
'ರುಂಡಾಭರಣ',
'ರುಚಕಾಯೋಗ',
'ರುಚಿಗೊಳಿಸು',
'ರುಚಿವಡಿಸು',
'ರುಜುಪಡಿಸು',
'ರುಣಪಾತಕ',
'ರುಣಪಾತಕಿ',
'ರುತುವದನ',
'ರುದ್ರನರ್ತನ',
'ರುದ್ರನಾಟಕ',
'ರುದ್ರನಾಯಕ',
'ರುದ್ರರೂಪಕ',
'ರುದ್ರಾಕ್ಷಿ ಸರ',
'ರುದ್ರಾಟ್ಟಹಾಸ',
'ರುದ್ರಾವತಾರ',
'ರುಧಿರೋದ್ಗಾರಿ',
'ರೂಢಗೊಳಿಸು',
'ರೂಢಿಲಕ್ಷಣೆ',
'ರೂಪಕತಾಲ',
'ರೂಪಕತಾಳ',
'ರೂಪಗೊಳಿಸು',
'ರೂಪಜೀವನೆ',
'ರೂಪನಿದಾನ',
'ರೂಪನಿಷ್ಪತ್ತಿ',
'ರೂಪಪಲ್ಲಟ',
'ರೂಪವಿಕ್ರಯಿ',
'ರೂಪವಿಹೀನ',
'ರೂಪವಿಹೀನೆ',
'ರೂಪಾಂತರಿಸು',
'ರೂಪುಗಾಣಿಸು',
'ರೂಪುಗೊಳಿಸು',
'ರೂಪುದೋರಿಸು',
'ರೂಪುದೋಱಿಸು',
'ರೂಹುಗಾಣಿಸು',
'ರೂಹುಗೆಡಿಸು',
'ರೂಹುಗೊಳಿಸು',
'ರೆಕ್ಕೆಇರುವೆ',
'ರೆಳ್ಳುಗಸುವು',
'ರೇಂಜರುಗಿರಿ',
'ರೇಕವಿಸರ',
'ರೇಖಾಗಣಿತ',
'ರೇಖೆಗೊಳಿಸು',
'ರೇಚಕಯಂತ್ರ',
'ರೇಚಕವಾತ',
'ರೇಣುಕಬೀಜ',
'ರೇವಡಿಗಾರ',
'ರೇವಡಿಗಿಡ',
'ರೇವುಪಟ್ಟಣ',
'ರೇಷ್ಮೆಕಾಯಿಲೆ',
'ರೊಟ್ಟಿಹಲಸು',
'ರೊಟ್ಟೀಹಲಸು',
'ರೋಗತಂಡುಲ',
'ರೋಗನಿದಾನ',
'ರೋಗರುಜಿನ',
'ರೋಚಿಮಂಡಲ',
'ರೋಜುಗಾರಿಕೆ',
'ರೋದನೆಗೆಯ್',
'ರೋದೋವಿವರ',
'ರೋಪಣಾಸನ',
'ರೋಮಕಂದರ',
'ರೋಮಕರ್ಣಕ',
'ರೋಮಪುಳಕ',
'ರೋಮಹರ್ಷಣ',
'ರೋಮಹರ್ಷಿತ',
'ರೋಮಹರ್ಷಿತೆ',
'ರೋಮಾಂಚಕಾರಿ',
'ರೋಮಾಂಚಗೊಳ್ಳು',
'ರೋಮಾಂಚನಿಸು',
'ರೋಮಾಂಚವೇಳು',
'ರೋಷಭೀಷಣ',
'ರೋಷಭೀಷಣೆ',
'ರೋಹಣದ್ರುಮ',
'ರೋಹಿಣಿಸಂಧಿ',
'ರೌದ್ರತೆವೆಱು',
'ರೌದ್ರಾವತಾರ',
'ಲಂಕಿಣಿವಿದ್ಯೆ',
'ಲಂಗರಖಾನೆ',
'ಲಂಗರುಸುಂಕ',
'ಲಂಘನಂಗೆಯ್',
'ಲಂಚಗಾರಿಕೆ',
'ಲಂಚಬಡಕ',
'ಲಂಚವಣಿಗ',
'ಲಂದಣಗಿತ್ತಿ',
'ಲಂಪಟತನ',
'ಲಂಬಣಮಾಲೆ',
'ಲಕಲಕನೆ',
'ಲಕಲಕಿಸು',
'ಲಕ್ಕಡಿಕೋಟೆ',
'ಲಕ್ಷಣಗೇಡಿ',
'ಲಕ್ಷಣಗ್ರಂಥ',
'ಲಕ್ಷಣದೋಷ',
'ಲಕ್ಷಣವಂತ',
'ಲಕ್ಷಣವಂತೆ',
'ಲಕ್ಷಣವಿಧಿ',
'ಲಕ್ಷಣಶಾಸ್ತ್ರ',
'ಲಕ್ಷಣಾವೃತ್ತಿ',
'ಲಕ್ಷವರಾಳಿ',
'ಲಕ್ಷಾಧಿಕಾರಿ',
'ಲಕ್ಷಾಧಿಪತಿ',
'ಲಕ್ಷಾಧೀಶ್ವರ',
'ಲಕ್ಷ್ಮಣಕಂದ',
'ಲಕ್ಷ್ಮಣರೇಖೆ',
'ಲಕ್ಷ್ಮೀಕಟಾಕ್ಷ',
'ಲಕ್ಷ್ಯಲಕ್ಷಿತ',
'ಲಕ್ಷ್ಯಲಕ್ಷಿತೆ',
'ಲಗಳಿಚಣ್ಣ',
'ಲಗಾಡಿತೆಗೆ',
'ಲಗುಡಪಾತ',
'ಲಗುಬದರಿ',
'ಲಗ್ಗೆದಂಬಟ',
'ಲಗ್ನನಿಷ್ಕರ್ಷೆ',
'ಲಗ್ನಪತ್ರಿಕೆ',
'ಲಗ್ನಾಧಿಪತಿ',
'ಲಘುಉದ್ಯೋಗ',
'ಲಘುಟಿಪ್ಪಣಿ',
'ಲಘುಬದರಿ',
'ಲಘುಶರೀರಿ',
'ಲಘುಸಂಗೀತ',
'ಲಘೂಕರಣ',
'ಲಘೂಪಾಗಮ',
'ಲಚ್ಚಣಗಂಬ',
'ಲಜ್ಜಾವಿಹೀನ',
'ಲಜ್ಜಾವಿಹೀನೆ',
'ಲಜ್ಜಾಹೀನತೆ',
'ಲಜ್ಜೆವೆರಸು',
'ಲಟಕಟಿಸು',
'ಲಟಕರಿಸು',
'ಲಟಕಾಯಿಸು',
'ಲಟಕಿಮುರಿ',
'ಲಟಕುಮುರಿ',
'ಲಟಲಟನೆ',
'ಲಟಲಟಿಸು',
'ಲಟಿಕಿಹೊಡೆ',
'ಲಟಿಕೆಮುರಿ',
'ಲಟಿಗೆಮುರಿ',
'ಲಟಿಗೆಹೊಡೆ',
'ಲಟ್ಟೆಕಾರಿಕೆ',
'ಲತಾವೃಶ್ಚಿಕ',
'ಲತಾವೇಷ್ಟನ',
'ಲತಿಕಾಗೃಹ',
'ಲತ್ತಾಪ್ರಹಾರ',
'ಲಪಟಾಯಿಸು',
'ಲಫಂಗಗಿರಿ',
'ಲಬಲಬನೆ',
'ಲಬಲಬಿಸು',
'ಲಬ್ಧಿಗ್ರಹಣ',
'ಲಬ್ಧೋಪದೇಶ',
'ಲಯಕೃತಾಂತ',
'ಲಯವಡಿಸು',
'ಲಯವಿನ್ಯಾಸ',
'ಲಯವಿಹೀನ',
'ಲಯವಿಹೀನೆ',
'ಲಯಸಮಯ',
'ಲಲಾಟನೇತ್ರ',
'ಲಲಾಟಪಟ್ಟ',
'ಲಲಾಟರೇಖೆ',
'ಲಲಾಟಶೂನ್ಯ',
'ಲಲಾಟೇಕ್ಷಣ',
'ಲಲಿತಕಲೆ',
'ಲಲಿತಗತಿ',
'ಲಲಿತಪದ',
'ಲಲಿತವೃತ್ತ',
'ಲಲಿತಾಕಾರ',
'ಲಲಿತೋತ್ತರ',
'ಲಲ್ಲೆಗಾರಿಕೆ',
'ಲವಂಗಚೂರು',
'ಲವಂಗಚೆಕ್ಕೆ',
'ಲವಂಗತೊಕ್ಕು',
'ಲವಂಗಪಟ್ಟೆ',
'ಲವಂಗಪತ್ರೆ',
'ಲವಂಗಲತೆ',
'ಲವಣಿಸಾರ',
'ಲವಣೋತ್ತಮ',
'ಲವಣೋದಕ',
'ಲವಣೋದಧಿ',
'ಲವಲವಿಕೆ',
'ಲವಲವಿಸು',
'ಲವುಡಿಮಗ',
'ಲಹಣಿಗೊಡು',
'ಲಳಿತಾಕಾರ',
'ಲಳಿಮಸಗು',
'ಲಾಂಗಲದಂಡ',
'ಲಾಂಗಲಹಸ್ತ',
'ಲಾಂಛನಾಸ್ಪದ',
'ಲಾಕುಲಾಗಮ',
'ಲಾಕುಳಾಗಮ',
'ಲಾಕ್ಷಾಪ್ರದೀಪ',
'ಲಾಘವದೋಷ',
'ಲಾಟರಿಹೊಡೆ',
'ಲಾಭದಾಯಕ',
'ಲಾಭಬಡಕ',
'ಲಾಭಬಡುಕ',
'ಲಾಭಾಂತರಾಯ',
'ಲಾಲ್‍ತಿಗಡೆ',
'ಲಾವಣಿಕಾರ',
'ಲಾವಣಿಗಾರ',
'ಲಾವಣಿದಾರ',
'ಲಾವಣಿಪತ್ರ',
'ಲಾವಣ್ಯಂದಳೆ',
'ಲಾವಣ್ಯವತಿ',
'ಲಾಹಿರಿಗೊಳ್',
'ಲಾಳಗುಂಡಿಗೆ',
'ಲಾಳಗುಡಿಗೆ',
'ಲಾಳವಂಡಿಗೆ',
'ಲಾಳವಿಂಡಿಗೆ',
'ಲಾಳವುಂಡಿಗೆ',
'ಲಿಂಗಧಾರಕ',
'ಲಿಂಗಧಾರಣ',
'ಲಿಂಗಧಾರಣೆ',
'ಲಿಂಗಮೆಣಸು',
'ಲಿಂಗವರ್ಜಿತ',
'ಲಿಂಗವಿಕಾರಿ',
'ಲಿಂಗವಿವಕ್ಷೆ',
'ಲಿಂಗವ್ಯವಸ್ಥೆ',
'ಲಿಂಗವ್ಯಸನಿ',
'ಲಿಂಗಶರೀರ',
'ಲಿಂಗಶರೀರಿ',
'ಲಿಂಗಸಾರಾಯ',
'ಲಿಂಗಸ್ವಾಯತ',
'ಲಿಂಗಾವತಾರ',
'ಲಿಪಿವಿಜ್ಞಾನ',
'ಲಿಪಿವಿಜ್ಞಾನಿ',
'ಲಿಪಿವಿಧಾನ',
'ಲಿಪಿಶಾಸ್ತ್ರಜ್ಞ',
'ಲಿಪಿಶಾಸ್ತ್ರಜ್ಞೆ',
'ಲಿಲಾವುದಾರ',
'ಲಿವಿಸಾಧನೆ',
'ಲುಂಠನಕಾರಿ',
'ಲುಳಿವಡಿಸು',
'ಲುಳಿವಡೆಸು',
'ಲೆಕ್ಕವಣಿಗೆ',
'ಲೆಖ್ಖತನಿಖೆ',
'ಲೇಖನಕಲೆ',
'ಲೇಖನಶಾಲೆ',
'ಲೇಖವಾಹಕ',
'ಲೇಖವಾಹನ',
'ಲೇಖೀದಾಖಲೆ',
'ಲೇಪಾಳಿಗಿತಿ',
'ಲೇಶಾಲಂಕಾರ',
'ಲೇಶಿನಿಹುಲ್ಲು',
'ಲೇಶ್ಯಾವಿಶುದ್ಧಿ',
'ಲೈಂಗಪುರಾಣ',
'ಲೊಕ್ಕಿಗದ್ಯಾಣ',
'ಲೊಟಲೊಟನೆ',
'ಲೊಟಿಗುಡಿಹಿ',
'ಲೊಟ್ಟೆಲೊಸಕು',
'ಲೊಡಕಜಿಹ್ಮ',
'ಲೋಕಕಂಟಕ',
'ಲೋಕಕಲ್ಯಾಣ',
'ಲೋಕಜನನಿ',
'ಲೋಕನಯನ',
'ಲೋಕನಾಯಕ',
'ಲೋಕಪಾಲಕ',
'ಲೋಕಪಾವನ',
'ಲೋಕಪಾವನೆ',
'ಲೋಕಪೂರಣ',
'ಲೋಕಪ್ರತೀತ',
'ಲೋಕಪ್ರತೀತಿ',
'ಲೋಕಪ್ರವಾದ',
'ಲೋಕಪ್ರಸಿದ್ಧ',
'ಲೋಕಪ್ರಸಿದ್ಧಿ',
'ಲೋಕಬಾಂಧವ',
'ಲೋಕಮರ್ಯಾದೆ',
'ಲೋಕಮೂಢತ್ವ',
'ಲೋಕರಕ್ಷಕ',
'ಲೋಕಲುಂಟಾಕ',
'ಲೋಕವಿಖ್ಯಾತ',
'ಲೋಕವಿಖ್ಯಾತಿ',
'ಲೋಕವಿಖ್ಯಾತೆ',
'ಲೋಕವಿಮೂಢ',
'ಲೋಕವಿರುದ್ಧ',
'ಲೋಕವಿರೋಧಿ',
'ಲೋಕಸಂಗ್ರಹ',
'ಲೋಕಾಂತರಿತ',
'ಲೋಕಾಂತರಿತೆ',
'ಲೋಕಾನುಗ್ರಹ',
'ಲೋಕಾನುಪ್ರೇಕ್ಷೆ',
'ಲೋಕಾನುಭವ',
'ಲೋಕಾಪವಾದ',
'ಲೋಕಾಭಿರಾಮ',
'ಲೋಕಾಯತಿಕ',
'ಲೋಕೈಕಚಕ್ಷು',
'ಲೋಕೋದ್ಧಾರಕ',
'ಲೋಕೋಪಕಾರ',
'ಲೋಕೋಪಕಾರಿ',
'ಲೋಕೋಪಯೋಗಿ',
'ಲೋಚನಜಲ',
'ಲೋಚನಜಳ',
'ಲೋಚನಶ್ರುತಿ',
'ಲೋಚಮಸ್ತಕ',
'ಲೋಮಹರ್ಷಣ',
'ಲೋಲಚಿಬುಕ',
'ಲೋಲಲೋಚನೆ',
'ಲೋಷ್ಟಭೇದನ',
'ಲೋಹಕವಚ',
'ಲೋಹಚುಂಬಕ',
'ಲೋಹಜಬಂಧ',
'ಲೋಹಪರೀಕ್ಷೆ',
'ಲೋಹಮಂಡೂರ',
'ಲೋಹವಕ್ಕರೆ',
'ಲೋಹಶ್ರವಣೆ',
'ಲೋಹಸಿಂಧೂರ',
'ಲೋಹಾಭಿಸಾರ',
'ಲೋಹಾಭಿಹಾರ',
'ಲೋಹಿತಜಳ',
'ಲೋಳೆಸುರಿಕ',
'ಲೌಲ್ಯವಾಹಿತ',
'ವಂಕವಾಗಿಲು',
'ವಂಗಡದೆಗೆ',
'ವಂಚಕತನ',
'ವಂಚನೆಗಾತಿ',
'ವಂಚನೆಗಾರ',
'ವಂದನಂಗೆಯ್',
'ವಂದನಮಾಲೆ',
'ವಂದನಮಾಳೆ',
'ವಂದನಾರ್ಪಣೆ',
'ವಂದನೆಗೆಯ್',
'ವಂಶರೋಚನ',
'ವಂಶರೋಚನೆ',
'ವಂಶಲೋಚನ',
'ವಂಶವಿಹೀನ',
'ವಂಶಾವತಾರ',
'ವಂಶೋದ್ಧಾರಕ',
'ವಕೀಲತನ',
'ವಕೀಲಿವೃತ್ತಿ',
'ವಕ್ಖಣಶಾಲೆ',
'ವಕ್ರನಾಸಿಕ',
'ವಕ್ರಭಣಿತಿ',
'ವಕ್ರಭಣಿತೆ',
'ವಕ್ರವಚನ',
'ವಕ್ರೀಕರಣ',
'ವಕ್ರೀಕರಿಸು',
'ವಕ್ರೀಭವನ',
'ವಕ್ಷಸ್ವಸ್ತಿಕ',
'ವಚನಕಾರ',
'ವಚನಕಾರ್ತಿ',
'ವಚನಗುಪ್ತಿ',
'ವಚನತ್ರಯ',
'ವಚನದೋಷ',
'ವಚನಭಂಗ',
'ವಚನಭ್ರಷ್ಟ',
'ವಚನಭ್ರಷ್ಟೆ',
'ವಚನಶಾಸ್ತ್ರ',
'ವಚನಶೂರ',
'ವಚನೀಯತೆ',
'ವಚೋಲಹರಿ',
'ವಚೋವಲ್ಲಭ',
'ವಚೋವಿಲಾಸ',
'ವಚೋವಿಳಾಸ',
'ವಚೋವಿಸ್ತರ',
'ವಚ್ಚಕಪ್ಪರ',
'ವಜನದಾರ',
'ವಜನುದಾರ',
'ವಜ್ರಕವಚ',
'ವಜ್ರಕವಾಟ',
'ವಜ್ರಕೀಟಕ',
'ವಜ್ರಘುಟಿಕೆ',
'ವಜ್ರಾಭಿಘಾತ',
'ವಜ್ರಾಸನಸ್ಥ',
'ವಟಪೂರ್ಣಿಮೆ',
'ವಟವಟಿಸು',
'ವಡಬಶಿಖಿ',
'ವಡಬಾನಲ',
'ವಡಬಾನಳ',
'ವಡಬಾಮುಖ',
'ವಡೆಮೂಡಿಗೆ',
'ವಡ್ಡಳಭತ್ತ',
'ವಡ್ಡಾರಾಧನೆ',
'ವಣಿಕಾತೃಣ',
'ವತನದಾರ',
'ವತನಪತ್ರ',
'ವದನಕೂಟ',
'ವದನರಸ',
'ವಧೂದಕ್ಷಿಣೆ',
'ವನಕುಸುಮ',
'ವನಜನಾಭ',
'ವನಜಭವ',
'ವನಜವಲ್ಲಿ',
'ವನತಿಕ್ತಕೆ',
'ವನದೇವತೆ',
'ವನಪಾಲಕ',
'ವನಪಾಲಿಕೆ',
'ವನಭೋಜನ',
'ವನಮಂಜರಿ',
'ವನಮಯೂರ',
'ವನಮಲ್ಲಿಗೆ',
'ವನವಿಹಾರ',
'ವನಶೃಂಗಾಟ',
'ವನಸಂಪಗೆ',
'ವನಸಂಪಿಗೆ',
'ವನಸೂರಣ',
'ವನಾವರಿಕೆ',
'ವನಾವಸಥ',
'ವನಿತಾವೃತ್ತಿ',
'ವನಿತಾಸಕ್ತಿ',
'ವಮನಕಾರಿ',
'ವಮನದ್ರವ್ಯ',
'ವಯಿರಮುಡಿ',
'ವಯೋನುರೂಪ',
'ವರಣಭೇದ',
'ವರಣಮಾಲೆ',
'ವರದಕ್ಷಿಣೆ',
'ವರದಹಸ್ತ',
'ವರದಿಕಾರ',
'ವರದಿಗಾರ',
'ವರದಿಗಾರ್ತಿ',
'ವರದಿಗೊಡು',
'ವರಪ್ರದಾನ',
'ವರಪ್ರಸಾದ',
'ವರಮಲ್ಲಿಗೆ',
'ವರವರ್ಣಿನಿ',
'ವರಸೆದಪ್ಪು',
'ವರಸೆದಾರ',
'ವರಹಮುದ್ರೆ',
'ವರಹಾಕಟ್ಟು',
'ವರಹಾಕೂರ್ಚ',
'ವರಹಾಬಣ್ಣ',
'ವರಾತಹಿಡಿ',
'ವರಾಹಕೇಶ',
'ವರಾಹಗಿರಿ',
'ವರಾಹಮತ',
'ವರಾಹಮುದ್ರೆ',
'ವರಿವಸಿತ',
'ವರಿವಸ್ಯತ',
'ವರುಣಪಾಶ',
'ವರುಣಬಾಣ',
'ವರುಣಾತ್ಮಜೆ',
'ವರುಣಾಲಯ',
'ವರುಷಂಗರೆ',
'ವರೋಪಚಾರ',
'ವರ್ಗಕಲಹ',
'ವರ್ಗಣಿದಾರ',
'ವರ್ಗಪರೀಕ್ಷೆ',
'ವರ್ಗೀಕರಣ',
'ವರ್ಗೀಕರಿಸು',
'ವರ್ಣಕವಿತ್ವ',
'ವರ್ಣಚತುಷ್ಕ',
'ವರ್ಣಚ್ಯುತಕ',
'ವರ್ಣಛಂದಸ್ಸು',
'ವರ್ಣನಾತ್ಮಕ',
'ವರ್ಣಪಂಚಕ',
'ವರ್ಣಪಲ್ಲಟ',
'ವರ್ಣವಿಕಾರ',
'ವರ್ಣವಿಭಾಗ',
'ವರ್ಣವಿಲಾಸ',
'ವರ್ಣವಿಳಾಸ',
'ವರ್ಣವ್ಯತ್ಯಯ',
'ವರ್ಣವ್ಯವಸ್ಥೆ',
'ವರ್ಣಸಂಕರ',
'ವರ್ಣಸಾಂಕರ್ಯ',
'ವರ್ಣಾನುಕ್ರಮ',
'ವರ್ಣಾಭಿಮಾನ',
'ವರ್ಣಿಕಾಭಂಗ',
'ವರ್ಣೋತ್ತಮತ್ವ',
'ವರ್ತಕತನ',
'ವರ್ತಕಧರ್ಮ',
'ವರ್ತಕನಾವೆ',
'ವರ್ತುಲನೃತ್ಯ',
'ವರ್ತುಲವಾಯು',
'ವರ್ತುಳವಾಯು',
'ವರ್ಧಮಾನಕ',
'ವರ್ಷಗಟ್ಟಳೆ',
'ವರ್ಷಜಧನು',
'ವರ್ಷತೊಡಕು',
'ವರ್ಷಪರ್ವತ',
'ವರ್ಷವರ್ಧಂತಿ',
'ವಲಭಿಚ್ಛಂದ',
'ವಲಯಾಕಾರ',
'ವಲಯಾಕೃತಿ',
'ವಲಸೆಗಾರ',
'ವಲಸೆದೆಗೆ',
'ವಲ್ಲಕೀಯೋಗ',
'ವಲ್ಲಿದುರುವೆ',
'ವಶಪಡಿಸು',
'ವಶೀಕರಣ',
'ವಶೀಕರಿಸು',
'ವಶೀಕಾರಿಣಿ',
'ವಶ್ಯತಿಲಕ',
'ವಶ್ಯಮೂಲಿಕೆ',
'ವಶ್ಯಮೂಳಿಕೆ',
'ವಸಂತಗೌಳ',
'ವಸಂತದೂತ',
'ವಸಂತದೂತಿ',
'ವಸಂತದ್ರುಮ',
'ವಸಂತಮಾಲು',
'ವಸಂತಮಾಸ',
'ವಸಂತರಾಜ್ಯ',
'ವಸಂತವಾಡು',
'ವಸಂತೋತ್ಸವ',
'ವಸತಿಗೃಹ',
'ವಸನಚ್ಛತ್ರ',
'ವಸನಾಶ್ರಯ',
'ವಸಿಷ್ಠದ¨sರ್',
'ವಸಿಷ್ಠಸ್ಮೃತಿ',
'ವಸೀಲುದಾರ',
'ವಸುಜಾಲಕ',
'ವಸುಧಾಚಕ್ರ',
'ವಸುಧಾತಳ',
'ವಸುಧಾಮರ',
'ವಸುಧಾಯೋಗ',
'ವಸೂಲ್‍ಬಾಕಿ',
'ವಸೂಲುಬಾಕಿ',
'ವಸ್ತಿಕುಹರ',
'ವಸ್ತಿಶೋಧನ',
'ವಸ್ತುಕಮಾರ್ಗ',
'ವಸ್ತುಪುರುಷ',
'ವಸ್ತುವಾಚಕ',
'ವಸ್ತುಸ್ವಭಾವ',
'ವಸ್ತೂಪಮಾನ',
'ವಸ್ತ್ರಗಾಣಿಕೆ',
'ವಸ್ತ್ರಗಾಲಿತ',
'ವಸ್ತ್ರಗಾಳಿತ',
'ವಸ್ತ್ರಗಾಳಿಸು',
'ವಸ್ತ್ರಭಿನ್ನಕ',
'ವಹನಕರ್ಮ',
'ವಹನಪತ್ರ',
'ವಹಮಾನಿಕೆ',
'ವಹಿಲತನ',
'ವಹಿವಾಟಿಸು',
'ವಹ್ನಿರೇತಸ್ಸು',
'ವಳಕ್ಷಪಕ್ಷ',
'ವಳಭಿಚ್ಛಂದ',
'ವಳಭಿಚ್ಛದೆ',
'ವಳಯಾಕಾರ',
'ವಳಯಾಕೃತಿ',
'ವಳಕ್ರ್ಷದೇಹ',
'ವಳಿಗೊಳಿಸು',
'ವಾಂತ್ಯತಿಸಾರ',
'ವಾಕ್ಚಮತ್ಕೃತಿ',
'ವಾಕ್ಪರಿಣತ',
'ವಾಕ್ಯದೀಪಕ',
'ವಾಕ್ಯರಚನೆ',
'ವಾಕ್ಯವೇಷ್ಟನ',
'ವಾಗಗೋಚರ',
'ವಾಗತ್ಯಪಡು',
'ವಾಗಾಡಂಬರ',
'ವಾಗ್ಗೇಯಕಾರ',
'ವಾಗ್ಬಂಧನಂಗೈ',
'ವಾಗ್ವಿದಗ್ಧತೆ',
'ವಾಚಕಗುರು',
'ವಾಚಕದೋಷ',
'ವಾಚಕವಾಣಿ',
'ವಾಚನಾಲಯ',
'ವಾಚಾಮತೀತ',
'ವಾಚಾಲಗಿತ್ತಿ',
'ವಾಚಾಳತನ',
'ವಾಚಾಳಿತನ',
'ವಾಚ್ಯಲಿಂಗಕ',
'ವಾಜಸನೇಯ',
'ವಾಜಿದಂತಕ',
'ವಾಜಿದಂತಿಕೆ',
'ವಾಜಿಭೋಜನ',
'ವಾಜಿವದನ',
'ವಾಜಿವಲ್ಲಭ',
'ವಾಜೀಕರಣ',
'ವಾಟಿಗಿಲಿಕೆ',
'ವಾಟೆಹಿಂಡಲು',
'ವಾಡವಾನಲ',
'ವಾಡುವರಡು',
'ವಾಡೆಮಡಿಗೆ',
'ವಾಣಿಜತನ',
'ವಾಣಿಜ್ಯತನ',
'ವಾಣಿಜ್ಯವೀಧಿ',
'ವಾಣಿಜ್ಯೋದ್ಯಮಿ',
'ವಾತಗವಾಕ್ಷ',
'ವಾತನಯನ',
'ವಾತನಾಶನ',
'ವಾತಪಾತಕ',
'ವಾತಪೂರಣ',
'ವಾತಪ್ರಕೃತಿ',
'ವಾತಪ್ರಕೋಪ',
'ವಾತಪ್ರಮೇಹ',
'ವಾತಬರ್ಬರ',
'ವಾತಮಂಡಳಿ',
'ವಾತವಲಯ',
'ವಾತಶರೀರ',
'ವಾತಶರೀರಿ',
'ವಾತಶೋಣಿತ',
'ವಾತಾತಪಿಕ',
'ವಾತಾತಿಸಾರ',
'ವಾತಾಯನಿತ',
'ವಾತಾವರಣ',
'ವಾತುಲಾಗಮ',
'ವಾತುಳಾಗಮ',
'ವಾತೂಲಾಗಮ',
'ವಾತ್ಸಲ್ಯಮಯ',
'ವಾತ್ಸಲ್ಯಮಯಿ',
'ವಾದನದಂಡ',
'ವಾದವಿವಾದ',
'ವಾದಸರಣಿ',
'ವಾದ್ಯವಿತಾನ',
'ವಾನಬ್ಯಂತರ',
'ವಾನರವಿದ್ಯೆ',
'ವಾನರಸೇನೆ',
'ವಾನವ್ಯಂತರ',
'ವಾಮನಮುದ್ರೆ',
'ವಾಮನಾಕಾರ',
'ವಾಯದೆಜ್ವರ',
'ವಾಯವಡಂಗ',
'ವಾಯವಿಡಂಗ',
'ವಾಯವ್ಯಸ್ನಾನ',
'ವಾಯಸಾದಿನಿ',
'ವಾಯಸಾರಾತಿ',
'ವಾಯಿದೆಜ್ವರ',
'ವಾಯಿವಡಂಗ',
'ವಾಯಿವಿಡಂಗ',
'ವಾಯಿವಿಳಂಗ',
'ವಾಯುಪಂಚಕ',
'ವಾಯುಪಥಿಕ',
'ವಾಯುಪುರಾಣ',
'ವಾಯುಪ್ರಕೋಪ',
'ವಾಯುಭುಗ್ಭೂಷ',
'ವಾಯುಮಂಡಲ',
'ವಾಯುಮಾಲಿನ್ಯ',
'ವಾಯುವರಣ',
'ವಾಯುವಿಕಾರ',
'ವಾಯುವಿಹಾರ',
'ವಾಯುವಿಳಂಗ',
'ವಾಯುಸಂಚಾರ',
'ವಾಯುಸೇವನೆ',
'ವಾಯುಸ್ತಂಭನ',
'ವಾರಗುತ್ತಿಗೆ',
'ವಾರಣಬುಸೆ',
'ವಾರಣಮುಖ',
'ವಾರಣಶಿಕ್ಷೆ',
'ವಾರಣಸೈನ್ಯ',
'ವಾರಣಹಸ್ತ',
'ವಾರತೆಕೇಳು',
'ವಾರತೆಗೇಳು',
'ವಾರನಾಯಕ',
'ವಾರಪತ್ರಿಕೆ',
'ವಾರಮಲ್ಲಿಗೆ',
'ವಾರವಾಣಕ',
'ವಾರಸದಾರ',
'ವಾರಸುದಾರ',
'ವಾರಾಶಿದುರ್ಗ',
'ವಾರಿಜಗಂಧಿ',
'ವಾರಿಜನಾಭ',
'ವಾರಿಜನೇತ್ರ',
'ವಾರಿಜನೇತ್ರೆ',
'ವಾರಿಜಸದ್ಮೆ',
'ವಾರಿದಪಥ',
'ವಾರಿಧಿಗರ್ಭ',
'ವಾರಿನಿಧಾನ',
'ವಾರಿನಿರ್ಗಮ',
'ವಾರಿಬೆಂಡಕ',
'ವಾರಿವಿಹಾರ',
'ವಾರಿಸೇಚನ',
'ವಾರುಣಪತ್ರಿ',
'ವಾರುಣಸ್ನಾನ',
'ವಾರುಣಿನಾಡಿ',
'ವಾರುವದಳ',
'ವಾರುವಸೇನೆ',
'ವಾರ್ತಾಇಲಾಖೆ',
'ವಾರ್ತಾಪ್ರಪಂಚ',
'ವಾರ್ತಾವಾಹಕ',
'ವಾರ್ತಿಕಕಾರ',
'ವಾರ್ಷಿಕೋತ್ಸವ',
'ವಾಲಗಂಗೊಳ್',
'ವಾಲವ್ಯಜನ',
'ವಾಲುಕಪ್ರಭೆ',
'ವಾಲುಕಾಪ್ರಭೆ',
'ವಾಲುಕಾಸ್ತಂಭ',
'ವಾವಿವರ್ತನ',
'ವಾವಿವರ್ತನೆ',
'ವಾವೆವರ್ತನ',
'ವಾವೆವರ್ತನೆ',
'ವಾಶ್ಚರಲಗ್ನ',
'ವಾಸನಾಕ್ಷಯ',
'ವಾಸನಾಬಲ',
'ವಾಸನೆಗಟ್ಟು',
'ವಾಸನೆಮರ',
'ವಾಸನೆವಿಡಿ',
'ವಾಸನೆಹಿಡಿ',
'ವಾಸನೆಹುಲ್ಲು',
'ವಾಸಭವನ',
'ವಾಸರಂಬರಂ',
'ವಾಸರಕರ',
'ವಾಸರಪತಿ',
'ವಾಸರಾಧಿಪ',
'ವಾಸವಗಜ',
'ವಾಸವಚಾಪ',
'ವಾಸವಾಸನ',
'ವಾಸಿವಾಳಿಕೆ',
'ವಾಸುಗಿವಕ್ಕಿ',
'ವಾಸ್ತವಿಕತೆ',
'ವಾಸ್ತುವಿಧೇಯ',
'ವಾಹಕತನ',
'ವಾಹಳಿತನ',
'ವಾಹಾರೋಹಣ',
'ವಾಹನೀಪತಿ',
'ವಾಹಿನೀಪ್ರಿಯ',
'ವಾಳಕಾಭಾತು',
'ವಾಳವಾಯುಜ',
'ವಾಳುಕಾಪ್ರಭೆ',
'ವಿಕಟಕವಿ',
'ವಿಕಟಗಾತ್ರ',
'ವಿಕಟಗಾತ್ರೆ',
'ವಿಕಟನೃತ್ತ',
'ವಿಕಟಬಂಧ',
'ವಿಕಟಮುಖಿ',
'ವಿಕಟಾಕ್ಷರ',
'ವಿಕಲಭಾಷೆ',
'ವಿಕಲಾಂಗತೆ',
'ವಿಕಲಾಕ್ಷರ',
'ವಿಕಲೇಕ್ಷಣ',
'ವಿಕಲ್ಪಗತಿ',
'ವಿಕಲ್ಪಬಿಂದು',
'ವಿಕಲ್ಪವಿದ',
'ವಿಕಳಗೊಳ್',
'ವಿಕಳತನ',
'ವಿಕಳಧ್ವನಿ',
'ವಿಕಳಪಾಣಿ',
'ವಿಕಳಮತಿ',
'ವಿಕಳವ್ರತ',
'ವಿಕಳಹಸ್ತ',
'ವಿಕಳೇಂದ್ರಿಯ',
'ವಿಕಾರಚೇಷ್ಟೆ',
'ವಿಕಾರಿತನ',
'ವಿಕಾಸಗೊಳ್ಳು',
'ವಿಕಾಸಲಕ್ಷ್ಮಿ',
'ವಿಕಾಸವಾದ',
'ವಿಕೃತಕಾಮ',
'ವಿಕೃತಕಾಮಿ',
'ವಿಕೃತದಂತ',
'ವಿಕೃತವೇಷಿ',
'ವಿಕೃತಿದೋಱು',
'ವಿಕೃತಿಸ್ವರ',
'ವಿಕೋಶದೃಷ್ಟಿ',
'ವಿಕ್ರಮವರ್ಷ',
'ವಿಕ್ರಮಶಕ',
'ವಿಕ್ರಮಹೀನ',
'ವಿಕ್ರಯಶಾಲೆ',
'ವಿಕ್ರಿಯಾಬಲ',
'ವಿಕ್ರಿಯಾಬಳ',
'ವಿಕ್ರಿಯಾಶಕ್ತಿ',
'ವಿಕ್ಷಿಪ್ತತನ',
'ವಿಕ್ಷೇಪಶಕ್ತಿ',
'ವಿಖ್ಯಾತಿವೆಱು',
'ವಿಗಡತನ',
'ವಿಗ್ರಹಂಗೆಯ್',
'ವಿಗ್ರಹವಾಕ್ಯ',
'ವಿಚಾರಕ್ಷಮ',
'ವಿಚಾರಗೇಡಿ',
'ವಿಚಾರಗೋಷ್ಠಿ',
'ವಿಚಾರಣೀಯ',
'ವಿಚಾರದೂರ',
'ವಿಚಾರಧಾರೆ',
'ವಿಚಾರಪರ',
'ವಿಚಾರಪರೆ',
'ವಿಚಾರಭ್ರಷ್ಟ',
'ವಿಚಾರಭ್ರಷ್ಟೆ',
'ವಿಚಾರವಂತ',
'ವಿಚಾರವಂತೆ',
'ವಿಚಾರವಾದ',
'ವಿಚಾರವಾದಿ',
'ವಿಚಾರವ್ಯಾಪ್ತಿ',
'ವಿಚಾರಶಕ್ತಿ',
'ವಿಚಾರಶಾಸ್ತ್ರ',
'ವಿಚಾರಸ್ಥಾನ',
'ವಿಚಾರಿಸುಹ',
'ವಿಚಿಂತಾಂತರ',
'ವಿಚಿಕಿತ್ಸನ',
'ವಿಚ್ಛಳಾಕಾರ',
'ವಿಜಯಂಗೆಯ್',
'ವಿಜಯಚ್ಛಂದ',
'ವಿಜಯದ್ವಿಪ',
'ವಿಜಯಧ್ವಜ',
'ವಿಜಯಪಱೆ',
'ವಿಜಯಬೀಜ',
'ವಿಜಯಭೇರಿ',
'ವಿಜಯಯಾತ್ರೆ',
'ವಿಜಯಲಕ್ಷ್ಮಿ',
'ವಿಜಯಶಾಲಿ',
'ವಿಜಯಸೇಸೆ',
'ವಿಜಯಸ್ತಂಭ',
'ವಿಜಯಾಗಮ',
'ವಿಜಯಾನಂದ',
'ವಿಜಯಾನಕ',
'ವಿಜಯೋತ್ಸವ',
'ವಿಜಯೋತ್ಸಾಹಿ',
'ವಿಜಿಗೀಷಣ',
'ವಿಜಿಗೀಷುತೆ',
'ವಿಜಿಗೀಷುತ್ವ',
'ವಿಜಿತಬಲ',
'ವಿಜಿತೇಂದ್ರಿಯ',
'ವಿಜುಗುಪ್ಸತೆ',
'ವಿಜ್ಜನಸತಿ',
'ವಿಜ್ಞಾನದೃಷ್ಟಿ',
'ವಿಜ್ಞಾನಮಯ',
'ವಿಜ್ಞಾನವಿದ',
'ವಿಜ್ಞಾನಶಕ್ತಿ',
'ವಿಜ್ಞಾನಾಂತರ',
'ವಿಟಂಕಪಾಳಿ',
'ವಿಟಗಾರಿಕೆ',
'ವಿಡಂಬನೆಗೈ',
'ವಿಡಲವಣ',
'ವಿಡಾಯಕಾರ್ತಿ',
'ವಿಡಾಯಕಾಱ',
'ವಿಡಾಯಗೆಡು',
'ವಿಡಾಯದೋಱು',
'ವಿಡಾಯವಂತ',
'ವಿಡಾಯವೆಱು',
'ವಿಡಾಯಿಗೆಡು',
'ವಿಡೂರಮಣಿ',
'ವಿತಂಡವಾದ',
'ವಿತಂಡಾವಾದ',
'ವಿತಾಡ್ಯಮಾನ',
'ವಿತ್ತಭವನ',
'ವಿತ್ತವಣಿಜ',
'ವಿದಗ್ಧತನ',
'ವಿದಗ್ಧವೃತ್ತಿ',
'ವಿದಳಹುಗ್ಗಿ',
'ವಿದಾರಿಗಂಧೆ',
'ವಿದೇಶಿಕಾಱ',
'ವಿದೇಶೀಯತೆ',
'ವಿದ್ಧಕಾಮಾಲೆ',
'ವಿದ್ಧಪದ್ಧತಿ',
'ವಿದ್ಯಾಇಲಾಖೆ',
'ವಿದ್ಯಾಡಂಬರ',
'ವಿದ್ಯಾದೇವತೆ',
'ವಿದ್ಯಾಧಿಕಾರಿ',
'ವಿದ್ಯಾನಿಧಾನ',
'ವಿದ್ಯಾನಿಲಯ',
'ವಿದ್ಯಾನಿಳಯ',
'ವಿದ್ಯಾಪಾರಗ',
'ವಿದ್ಯಾಪಾರಗೆ',
'ವಿದ್ಯಾಪ್ರತ್ಯಯ',
'ವಿದ್ಯಾಪ್ರಸಾರ',
'ವಿದ್ಯಾಭಿಮಾನಿ',
'ವಿದ್ಯಾಭಿವೃದ್ಧಿ',
'ವಿದ್ಯಾರಾಧನ',
'ವಿದ್ಯಾರಾಧನೆ',
'ವಿದ್ಯಾರ್ಥಿದೆಶೆ',
'ವಿದ್ಯಾವಾರಿಧಿ',
'ವಿದ್ಯಾವಿತ್ತಮ',
'ವಿದ್ಯಾವರೋಧ',
'ವಿದ್ಯಾವಿಹೀನ',
'ವಿದ್ಯಾವಿಹೀನೆ',
'ವಿದ್ಯಾವ್ಯಸನ',
'ವಿದ್ಯುಚ್ಚಿಕಿತ್ಸೆ',
'ವಿದ್ಯುಜ್ಜನಕ',
'ವಿದ್ಯುತ್ಕಾಂತತೆ',
'ವಿದ್ಯುತ್ಪ್ರವಾಹ',
'ವಿದ್ಯುತ್ಸಂಚಾರ',
'ವಿದ್ಯುದ್ವಿಚ್ಛೇದ್ಯ',
'ವಿದ್ಯುನ್ಮಾಪಕ',
'ವಿದ್ಯುಲ್ಲೇಪನ',
'ವಿದ್ಯುಲ್ಲೇಪಿಸು',
'ವಿದ್ಯೆವಾರಿಧಿ',
'ವಿದ್ರುಮದ್ರುಮ',
'ವಿದ್ರುಮಲತೆ',
'ವಿದ್ರುಮಾಧರ',
'ವಿದ್ರುಮಾಧರೆ',
'ವಿದ್ವತ್ಪರೀಕ್ಷೆ',
'ವಿದ್ವಿಷ್ಟಬಲ',
'ವಿದ್ವೇಷಕಾರಿ',
'ವಿಧಾತೃಯೋಗ',
'ವಿಧಾತೃವಶ',
'ವಿಧಾತ್ರವಶ',
'ವಿಧಾತ್ರಾಧೀನ',
'ವಿಧಾನಸಭೆ',
'ವಿಧಾನಸೌಧ',
'ವಿಧಿಪೂರ್ವಕ',
'ವಿಧಿಪೂರ್ವಕಂ',
'ವಿಧಿಪ್ರೇರಿತ',
'ವಿಧಿಲಿಖಿತ',
'ವಿಧಿವಾಹನ',
'ವಿಧಿವಿಜ್ಞಾನ',
'ವಿಧಿವಿಹಿತ',
'ವಿಧುಮಂಡಲ',
'ವಿಧುರಗತಿ',
'ವಿಧುರತನ',
'ವಿಧೂತಕಾಲ',
'ವಿಧ್ಯಲಂಕಾರ',
'ವಿನಯಗುಂದು',
'ವಿನಯಂಗೆಡು',
'ವಿನಯಂಗೆಯ್',
'ವಿನಯಪರ',
'ವಿನಯಪರೆ',
'ವಿನಯವಂತ',
'ವಿನಯವತಿ',
'ವಿನಯಶೀಲ',
'ವಿನಯಶೀಲೆ',
'ವಿನಾಕರಿಸು',
'ವಿನಾಕಾರಣ',
'ವಿನಾಶಕತೆ',
'ವಿನಾಶಕರ',
'ವಿನಾಶಕಾರಿ',
'ವಿನಾಶಕಾಲ',
'ವಿನಿಪತತ್ತು',
'ವಿನಿಮಯಿಸು',
'ವಿನಿಮೀಲಿತ',
'ವಿನಿಮೀಳಿತ',
'ವಿನಿಯೋಗಸ್ತ',
'ವಿನಿಯೋಗಿಸು',
'ವಿನಿರ್ಮಲತೆ',
'ವಿನಿವರ್ತನ',
'ವಿನಿವರ್ತಿತ',
'ವಿನುತಂಬೆಱು',
'ವಿನುತಪ್ರಾಸ',
'ವಿನೋದಂಬೆಱು',
'ವಿನೋದಗಾತಿ',
'ವಿನೋದಗಾರ',
'ವಿನೋದಶೀಲ',
'ವಿನೋದಶೀಲೆ',
'ವಿನೋದ್ಯಮಾನೆ',
'ವಿನ್ನಪಂಗೆಯ್',
'ವಿನ್ನಪ್ಪಂಕೆಯ್',
'ವಿನ್ನಪ್ಪಂಕೆಯ್ಸು',
'ವಿನ್ನಪ್ಪಂಗೆಯ್',
'ವಿಪಕ್ಷಪಾತ',
'ವಿಪಣಿಪದ',
'ವಿಪಣಿಮಾರ್ಗ',
'ವಿಪರೀತಕ',
'ವಿಪರೀತತೆ',
'ವಿಪರೀತಾರ್ಥ',
'ವಿಪರ್ಯಯೋಕ್ತ',
'ವಿಪರ್ಯಸ್ತಿಕ',
'ವಿಪಶ್ಚಿಜ್ಜನ',
'ವಿಪಾಕಸೂತ್ರ',
'ವಿಪಿನಚರ',
'ವಿಪಿನೌಕಸ',
'ವಿಪುಲಮತಿ',
'ವಿಪುಲಾಸನ',
'ವಿಪುಳಗುಣಿ',
'ವಿಪುಳಪ್ರಜ್ಞ',
'ವಿಪುಳವರ್ಷಿ',
'ವಿಪುಳವೃತ್ತಿ',
'ವಿಪ್ರಕಾರಣ',
'ವಿಪ್ರತಿಪತ್ತಿ',
'ವಿಪ್ರತಿಷಿದ್ಧ',
'ವಿಪ್ರತಿಸಾರ',
'ವಿಪ್ರತೀಸಾರ',
'ವಿಪ್ಲವಕಾರ',
'ವಿಪ್ಲವಮೂರ್ತಿ',
'ವಿಬಂತಿಭೇದ',
'ವಿಭಂಗಜ್ಞಾನ',
'ವಿಭಂಗಜ್ಞಾನಿ',
'ವಿಭಕ್ತಿಸ್ವರ',
'ವಿಭವಂಬೆಱು',
'ವಿಭಾವನತೆ',
'ವಿಭೂತಿಕಲ್ಪ',
'ವಿಭೂತಿಪಟ್ಟ',
'ವಿಭೂತಿಪಟ್ಟೆ',
'ವಿಭೂತಿಪೂಜೆ',
'ವಿಭೂತಿವೀಳ್ಯ',
'ವಿಭ್ರಮವತಿ',
'ವಿಭ್ರಾಂತಚಿತ್ತ',
'ವಿಭ್ರಾಂತಿಗೊಳ್',
'ವಿಮಲಮತಿ',
'ವಿಮಲಸ್ವಾಂತ',
'ವಿಮಲಸ್ವಾಂತೆ',
'ವಿಮಾನವಾಸಿ',
'ವಿಮುಕ್ತಾಹಾರ',
'ವಿಮುಖವೃತ್ತಿ',
'ವಿಯತ್ಪುರುಷ',
'ವಿಯನ್ನಂದನ',
'ವಿರಕ್ತಮಠ',
'ವಿರಸಕರ',
'ವಿರಹಜ್ವರ',
'ವಿರಹತಾಪ',
'ವಿರಹಾನಲ',
'ವಿರಹಾವಸ್ಥೆ',
'ವಿರಳದಂತ',
'ವಿರಳದಂತೆ',
'ವಿರಳವೇಗಿ',
'ವಿರಳಾಕ್ಷರ',
'ವಿರಾಟ್ಪುರುಷ',
'ವಿರಾಮಚಿಹ್ನ',
'ವಿರಾಮಚಿಹ್ನೆ',
'ವಿರಾಮಜ್ವರ',
'ವಿರುದ್ಧವೃತ್ತ',
'ವಿರುದ್ಧಾತ್ಮಕ',
'ವಿರುದ್ಧಾಭಾಸ',
'ವಿರುದ್ದಾರ್ಥಕ',
'ವಿರುಪಲಸು',
'ವಿರೂಪಸಂಧಿ',
'ವಿರೋಧಪಕ್ಷ',
'ವಿರೋಧಾಭಾಸ',
'ವಿರೋಧಿಕೃತು',
'ವಿಲಯಕಾಲ',
'ವಿಲಯರುದ್ರ',
'ವಿಲಯಾಂಬುದ',
'ವಿಲಯಾನಲ',
'ವಿಲಯಾನಿಲ',
'ವಿಲವಿಲನೆ',
'ವಿಲಾಪಗೀತ',
'ವಿಲಾಪಗೀತೆ',
'ವಿಲಾಸಂದಾಳ್',
'ವಿಲಾಸಂದೋಱು',
'ವಿಲಾಸಂಬೆಱು',
'ವಿಲಾಸಗಾರ',
'ವಿಲಾಸವತಿ',
'ವಿಲಾಸವಾಸ',
'ವಿಲಾಸಾವಾಸ',
'ವಿಲಿವಿಲಿಸು',
'ವಿಲೀನಗೊಳ್ಳು',
'ವಿಲೂನನಾಸೆ',
'ವಿಲೋಲುಪತೆ',
'ವಿವಕ್ಷೆಗೆಯ್',
'ವಿವರಗೇಡಿ',
'ವಿವರ್ತವಾದ',
'ವಿವಾಹಪ್ರಾಪ್ತ',
'ವಿವಾಹಪ್ರಾಪ್ತೆ',
'ವಿವಾಹವೇದಿ',
'ವಿವಿಕ್ತಾವಾಸ',
'ವಿವಿಕ್ತಿಗೆಯ್',
'ವಿವೇಕಂಬಡೆ',
'ವಿವೇಕಶಾಲಿ',
'ವಿವೇಕಶೂನ್ಯ',
'ವಿವೇಕಶೂನ್ಯೆ',
'ವಿಶದೋದಕ',
'ವಿಶಾಖಾಕರ',
'ವಿಶಾಲಮತಿ',
'ವಿಶಿಖಾಸನ',
'ವಿಶಿಷ್ಟಾದ್ವೈತ',
'ವಿಶಿಷ್ಟಾದ್ವೈತಿ',
'ವಿಶುದ್ಧಿಚಕ್ರ',
'ವಿಶುದ್ಧಿಲಬ್ಧಿ',
'ವಿಶೇಷಗುಣ',
'ವಿಶೇಷಧೂಪ',
'ವಿಶ್ರಮಂದಳೆ',
'ವಿಶ್ರಾಂತಿಗೃಹ',
'ವಿಶ್ರಾಂತಿಪದ',
'ವಿಶ್ವಕುಟುಂಬಿ',
'ವಿಶ್ವಚೇಷ್ಟಕ',
'ವಿಶ್ವಚೈತನ್ಯ',
'ವಿಶ್ವದೇವತೆ',
'ವಿಶ್ವಧಾರಿಣಿ',
'ವಿಶ್ವಪ್ರಯತ್ನ',
'ವಿಶ್ವಭೇಷಜ',
'ವಿಶ್ವಮಾನವ',
'ವಿಶ್ವಲೋಚನ',
'ವಿಶ್ವವಿಕರ್ಣ',
'ವಿಶ್ವವಿನುತ',
'ವಿಶ್ವವಿನುತೆ',
'ವಿಶ್ವವ್ಯಾಪಕ',
'ವಿಶ್ವಸಂಗ್ರಾಮ',
'ವಿಶ್ವಸಂದಳೆ',
'ವಿಶ್ವಸಂಹೃತ',
'ವಿಶ್ವಸನೀಯ',
'ವಿಶ್ವಸ್ತವ್ರತ',
'ವಿಶ್ವಾಸಂಗೆಯ್',
'ವಿಶ್ವಾಸಘಾತ',
'ವಿಶ್ವಾಸಘಾತಿ',
'ವಿಶ್ವಾಸತನ',
'ವಿಶ್ವಾಸಪಾತ್ರ',
'ವಿಶ್ವಾಸಪಾತ್ರೆ',
'ವಿಶ್ವಾಸಭೂಮಿ',
'ವಿಶ್ವೇದೇವತೆ',
'ವಿಶ್ವೋಜ್ಜನನ',
'ವಿಷಉಮ್ಮತ್ತಿ',
'ವಿಷಕಂಧರ',
'ವಿಷಕನ್ನಿಕೆ',
'ವಿಷಕಪರ್ದ',
'ವಿಷಕುಂಬಳ',
'ವಿಷಗಳಿಗೆ',
'ವಿಷಗುಂಬಳ',
'ವಿಷಗುಳಿಗೆ',
'ವಿಷಗೊಳಿಸು',
'ವಿಷಘಳಿಗೆ',
'ವಿಷಚಿಕಿತ್ಸೆ',
'ವಿಷಣ್ಣಮನ',
'ವಿಷಣ್ಣಮನೆ',
'ವಿಷಣ್ಣಾನನ',
'ವಿಷಣ್ಣಾನನೆ',
'ವಿಷತೊಗರಿ',
'ವಿಷನಾಶಕ',
'ವಿಷನಿಮಿಷ',
'ವಿಷಪತ್ರಿಕೆ',
'ವಿಷಪರೀಕ್ಷೆ',
'ವಿಷಪ್ರಯೋಗ',
'ವಿಷಪ್ರಾಶನ',
'ವಿಷಬಿದಿರು',
'ವಿಷಬಿದುರು',
'ವಿಷಭಿಷಕ್ಕು',
'ವಿಷಭೋಜಕ',
'ವಿಷಮಕಾಲ',
'ವಿಷಮಚಕ್ರ',
'ವಿಷಮಚ್ಛದ',
'ವಿಷಮಜ್ವರ',
'ವಿಷಮದಾರಿ',
'ವಿಷಮಪಾದ',
'ವಿಷಮಬಾಣ',
'ವಿಷಮಮತಿ',
'ವಿಷಮಯತಿ',
'ವಿಷಮರತಿ',
'ವಿಷಮವೃತ್ತ',
'ವಿಷಮಶೀಲ',
'ವಿಷಮಶೀಲೆ',
'ವಿಷಮಸಂಧಿ',
'ವಿಷಮಸನ್ನಿ',
'ವಿಷಮಸೂಚಿ',
'ವಿಷಮಾವಸ್ಥೆ',
'ವಿಷಮುಂಗುಲಿ',
'ವಿಷಮೂಂಗಿಲಿ',
'ವಿಷಮೂಲಿಕೆ',
'ವಿಷಯಕಾಂಕ್ಷೆ',
'ವಿಷಯರತಿ',
'ವಿಷಯಲಿಪ್ಸೆ',
'ವಿಷಯಲೋಭ',
'ವಿಷಯಸಂಧಿ',
'ವಿಷಯಸುಖ',
'ವಿಷಯಸೂಚಿ',
'ವಿಷಯಾಂತರ',
'ವಿಷಯಾತುರ',
'ವಿಷಯಾತುರೆ',
'ವಿಷಯಾಸಕ್ತ',
'ವಿಷಯಾಸಕ್ತಿ',
'ವಿಷಯಾಸಕ್ತೆ',
'ವಿಷಯೇಂದ್ರಿಯ',
'ವಿಷವರ್ತುಲ',
'ವಿಷವರ್ತುಳ',
'ವಿಷಸೇವನೆ',
'ವಿಷಹೆತ್ತುತ್ತಿ',
'ವಿಷಾದಗರ್ಭ',
'ವಿಷಾದಚಿತ್ತ',
'ವಿಷಾದಚಿತ್ತ್ತೆ',
'ವಿಷಾದನೀಯ',
'ವಿಷಾದಯೋಗ',
'ವಿಷಾಪಹಾರ',
'ವಿಷಾಪಹಾರಿ',
'ವಿಷುವದ್ಬಿಂದು',
'ವಿಷುವದ್ರೇಖೆ',
'ವಿಷುವದ್ವೃತ್ತ',
'ವಿಷ್ಟಪಜನ',
'ವಿಷ್ಟಬ್ಧಾಜೀರ್ಣ',
'ವಿಷ್ಟರಶ್ರವ',
'ವಿಷ್ಣುತುಳಸಿ',
'ವಿಷ್ಣುನಕ್ಷತ್ರ',
'ವಿಷ್ಣುಪುರಾಣ',
'ವಿಷ್ಣುವಾಹನ',
'ವಿಸಂಯೋಜಿಸು',
'ವಿಸಕಂಠಿಕೆ',
'ವಿಸಟಂಬರ್',
'ವಿಸಟಂಬರಿ',
'ವಿಸಟಂಬರು',
'ವಿಸಟಂಬಾಯ್',
'ವಿಸರ್ಜನಾಂಗ',
'ವಿಸರ್ಜನೀಯ',
'ವಿಸರ್ಪರೋಗ',
'ವಿಸಸನಗೈ',
'ವಿಸಿಖಾಸನ',
'ವಿಸ್ತಾರಪಣ',
'ವಿಸ್ಫುಲಿಂಗಿತ',
'ವಿಸ್ಫುಲಿಂಗಿಸು',
'ವಿಸ್ಮಯಂಗೊಳ್',
'ವಿಸ್ಮಯಂಬಡು',
'ವಿಸ್ಮಯಂಬಡೆ',
'ವಿಸ್ಮಯಕಾರಿ',
'ವಿಸ್ಮಯಗೊಳ್',
'ವಿಸ್ಮಯಗೊಳ್ಳು',
'ವಿಸ್ಮಯಪಡು',
'ವಿಸ್ಮಯಪದ',
'ವಿಸ್ಮಯಬಡು',
'ವಿಸ್ಮಯವೊಂದು',
'ವಿಸ್ಮಯಾಕ್ರಾಂತ',
'ವಿಸ್ಮಯಾಕ್ರಾಂತೆ',
'ವಿಸ್ಮಯಾವೃತ',
'ವಿಸ್ಮಯಾವೃತೆ',
'ವಿಸ್ರಂಭದೋಷ',
'ವಿಹಂಗಧ್ವಾನ',
'ವಿಹಂಗನಾಡಿ',
'ವಿಹಂಗರಾಜ',
'ವಿಹಂಗಾಧಿಪ',
'ವಿಹಗಯೋಗ',
'ವಿಹಗರಾಜ',
'ವಿಹಗಸ್ವನ',
'ವಿಹಾಯೋಗತಿ',
'ವಿಹಿತಕರ್ಮ',
'ವಿಹೃತದೋಷ',
'ವಿಳಯಕಾಲ',
'ವಿಳಯಕಾಳ',
'ವಿಳಯಾನಳ',
'ವಿಳಯಾನಿಳ',
'ವಿಳಾಸಂಗೆಡು',
'ವಿಳಾಸದಾರ',
'ವಿಳಾಸಯಾನ',
'ವಿಳಾಸೋಲ್ಲಾಸಿ',
'ವೀಣಾಕರ್ಕಟಿ',
'ವೀಣಾನುಗಂತ',
'ವೀಣಾವಾದಕ',
'ವೀಣಾವಾದಕಿ',
'ವೀಣಾವಾದನ',
'ವೀರಕಂಕಣ',
'ವೀರಗಾಣಿಕೆ',
'ವೀರಗೆಲಸ',
'ವೀರಚರಿತ',
'ವೀರಚಿಮ್ಮುರಿ',
'ವೀರಚೌಪದಿ',
'ವೀರತಾಂಬೂಲ',
'ವೀರದಂಬುಲ',
'ವೀರದಾಸತ್ವ',
'ವೀರನೂಪುರ',
'ವೀರನೇವುರ',
'ವೀರಪಟಹ',
'ವೀರಪಲಗೆ',
'ವೀರಪವಾಡ',
'ವೀರಪೆಂಡೆಯ',
'ವೀರಬಣಂಜ',
'ವೀರಬಣಂಜು',
'ವೀರಬಣಜ',
'ವೀರಬಣಜು',
'ವೀರಬಳಂಜ',
'ವೀರಬಳಂಜು',
'ವೀರಬೞಂಜು',
'ವೀರಭೃತ್ಯತ್ವ',
'ವೀರಮಂಟಿಗೆ',
'ವೀರಮರಣ',
'ವೀರಮುದ್ರಿಕೆ',
'ವೀರವಸಂತ',
'ವೀರವೈಷ್ಣವ',
'ವೀರಸಂಕಲ್ಪ',
'ವೀರಸೇನತೆ',
'ವೀರಾಧಿವೀರ',
'ವೀರಾವತಾರ',
'ವೀರ್ಯಪ್ರವೃತ್ತಿ',
'ವೀರ್ಯವರ್ಧನ',
'ವೀರ್ಯಸ್ಖಲನ',
'ವೀರ್ಯಸ್ತಂಭನ',
'ವೀರ್ಯಾಂತರಾಯ',
'ವೀಳಯದೆಲೆ',
'ವೀಳಿಗೆಗೊಡು',
'ವೀಳೆಯಂಗೊಡು',
'ವೀಳೆಯಕೊಡು',
'ವೀಳೆಯಗೊಡು',
'ವೀಳೆಯಗೊಳ್',
'ವೀಳೆಯದೆಲೆ',
'ವೃಂತಾಕಪಾಕ',
'ವೃಂತಾಕಫಲ',
'ವೃಂದಾರಕತ್ವ',
'ವೃಂದಾವನಸ್ಥ',
'ವೃಕ್ಷವಾಟಿಕೆ',
'ವೃಕ್ಷಶೋಷಣ',
'ವೃಕ್ಷಾರೋಹಣ',
'ವೃಜಿನಬಂಧ',
'ವೃತ್ತತಂಡುಲ',
'ವೃತ್ತಪತ್ರಿಕೆ',
'ವೃತ್ತಪರಿಧಿ',
'ವೃತ್ತಪಾಠಕ',
'ವೃತ್ತರೇಖಕ',
'ವೃತ್ತವಿಹಂಗ',
'ವೃತ್ತಸಂಪನ್ನ',
'ವೃತ್ತಸಂಪನ್ನೆ',
'ವೃತ್ತಾಧ್ಯಯನ',
'ವೃತ್ತಿಜೀವನ',
'ವೃತ್ತಿರಹಸ್ಯ',
'ವೃತ್ತಿಶಿಕ್ಷಣ',
'ವೃತ್ತ್ಯನುಪ್ರಾಸ',
'ವೃಥಾಭಿಯೋಗ',
'ವೃದ್ಧಸೇವಿತ್ವ',
'ವೃದ್ಧಾನುಕ್ರಮ',
'ವೃದ್ಧಿಜೀವನ',
'ವೃದ್ಧಿಜೀವಿಕೆ',
'ವೃದ್ಧಿಪಡಿಸು',
'ವೃಶ್ಚಿಕಮಾಸ',
'ವೃಶ್ಚಿಕರಾಶಿ',
'ವೃಶ್ಚಿಕಾಸನ',
'ವೃಷಭಧ್ವಜ',
'ವೃಷಭಧ್ವಾಂಕ್ಷಿ',
'ವೃಷಭನಾಥ',
'ವೃಷಭಪತಿ',
'ವೃಷಭಪಾಳಿ',
'ವೃಷಭಪ್ರಾಸ',
'ವೃಷಭಮಾಸ',
'ವೃಷಭರಾಶಿ',
'ವೃಷಭಲಕ್ಷ್ಮ',
'ವೃಷಭಾಚಲ',
'ವೃಷಭಾಚಳ',
'ವೃಷಭಾಯತ',
'ವೃಷಲಾಂಛನ',
'ವೃಷಲೀಪತಿ',
'ವೃಷವಾಹನ',
'ವೃಷಳೀಪತಿ',
'ವೃಷ್ಟಿಕಾರಕ',
'ವೆಂಕಟಗಿರಿ',
'ವೆಂಕಟಪತಿ',
'ವೆಂಕಟಾಚಲ',
'ವೆಕ್ಕಸಗೆಯ್',
'ವೆಗ್ಗಳವಂತ',
'ವೆಚ್ಚಬಿರಾಡ',
'ವೆವಹರಿಸು',
'ವೇಗವರ್ಧಕ',
'ವೇಗವರ್ಧನೆ',
'ವೇಗಾಯ್ಲತನ',
'ವೇಡೆಗೆದಱು',
'ವೇಢೆಯವಡೆ',
'ವೇಣೀಬಂಧಕ',
'ವೇಣೀಸಂಹಾರ',
'ವೇಣುಗೋಪಾಲ',
'ವೇಣುದಾರಿತ',
'ವೇಣುವಾದನ',
'ವೇತಂಡಯಾನ',
'ವೇತ್ರಖೇಟಕ',
'ವೇತ್ರಧಾರಕ',
'ವೇತ್ರವಿಷ್ಟರ',
'ವೇದಗೋಚರ',
'ವೇದಘೋಟಕ',
'ವೇದನಂಗೊಳ್',
'ವೇದನೀಯತೆ',
'ವೇದನೆಗೊಳ್',
'ವೇದಪುರುಷ',
'ವೇದಪ್ರಾಮಾಣ್ಯ',
'ವೇದಬಾಹಿರ',
'ವೇದಹನಸು',
'ವೇದಾಂತವಾದ',
'ವೇದಾಂತವೇದಿ',
'ವೇದಾಂತವೇದ್ಯ',
'ವೇದಾಧ್ಯಯನ',
'ವೇಧಮುಖ್ಯಕ',
'ವೇಧಸ್ಸಂಕಲ್ಪ',
'ವೇರಡಗಿತ್ತಿ',
'ವೇರಡಿಗಿತಿ',
'ವೇಶ್ಯಾವಾಟಿಕೆ',
'ವೇಷಡಂಭಕ',
'ವೇಸರಗತ್ತೆ',
'ವೇಸರಗೞ್ತೆ',
'ವೇಸರಮಾರ್ಗ',
'ವೇಸರಶಿಲ್ಪ',
'ವೇಸರಿಗೞ್ತೆ',
'ವೇಳಾಪತ್ರಿಕೆ',
'ವೇಳೆವಡಿಚ',
'ವೇಳೆವಡಿಯ',
'ವೇಱುಪಾವಡೆ',
'ವೈಕುಂಠಪತಿ',
'ವೈಕುಂಠಪದ',
'ವೈಕುಂಠಯಾತ್ರೆ',
'ವೈಕುಂಠವಾಸಿ',
'ವೈಕೊಂತವಾಸಿ',
'ವೈಚಾರಿಕತೆ',
'ವೈಜಯಂತಿಕೆ',
'ವೈಜ್ಞಾನಿಕತೆ',
'ವೈತಂಡವಾದ',
'ವೈತಸವೃತ್ತಿ',
'ವೈತಾಳವಿದ್ಯೆ',
'ವೈದಗ್ಧ್ಯಜನ',
'ವೈದರ್ಭಮಾರ್ಗ',
'ವೈದರ್ಭರೀತಿ',
'ವೈದರ್ಭೀರೀತಿ',
'ವೈದಿಕಕ್ರಿಯೆ',
'ವೈದಿಕಗಾನ',
'ವೈದಿಕಗೊಡ್ಡು',
'ವೈದಿಕಮತ',
'ವೈದಿಕವಿದ್ಯೆ',
'ವೈದಿಕವೃತ್ತಿ',
'ವೈದಿಕಾಗಮ',
'ವೈದ್ಯಪರೀಕ್ಷೆ',
'ವೈನಾಯಕಾಸ್ತ್ರ',
'ವೈಭವಂಬಡೆ',
'ವೈಯಕ್ತಿಕತೆ',
'ವೈಯಾಕರಣ',
'ವೈಯಾಕರಣಿ',
'ವೈಶಂಪಾಯನ',
'ವೈಶಾಖವೃತ್ತಿ',
'ವೈಶಾಖಸ್ಥಾನ',
'ವೈಷ್ಣವಮತ',
'ವೈಷ್ಣವವೇದ',
'ವೈಷ್ಣವಾಗಮ',
'ವ್ಯಂಜನಸಂಧಿ',
'ವ್ಯಂಜನಾವೃತ್ತಿ',
'ವ್ಯಕ್ತಪಡಿಸು',
'ವ್ಯಕ್ತಿಗೌರವ',
'ವ್ಯಕ್ತಿನಿಷ್ಠತೆ',
'ವ್ಯಕ್ತಿವೈಶಿಷ್ಟ್ಯ',
'ವ್ಯಕ್ತಿಸ್ವಾತಂತ್ರ್ಯ',
'ವ್ಯಕ್ತೀಕರಣ',
'ವ್ಯಕ್ತೀಕರಿಸು',
'ವ್ಯಗ್ರಚಿತ್ತತೆ',
'ವ್ಯತಿಕರಣ',
'ವ್ಯತಿಕ್ರಮಣ',
'ವ್ಯತಿರಿಕ್ತತೆ',
'ವ್ಯಧಿಕರಣ',
'ವ್ಯಪೇಕ್ಷೆಗೆಯ್',
'ವ್ಯಭಿಚರಿಸು',
'ವ್ಯಭಿಚಾರಿಣಿ',
'ವ್ಯವಕಲನ',
'ವ್ಯವಸ್ಥಾಪಕ',
'ವ್ಯವಸ್ಥಾಪಕಿ',
'ವ್ಯವಹರಣ',
'ವ್ಯವಹರಣೆ',
'ವ್ಯವಹರಿಸು',
'ವ್ಯವಹಾರಿಕ',
'ವ್ಯಸನಕರ',
'ವ್ಯಸ್ತರೂಪಕ',
'ವ್ಯಾಕರಣಜ್ಞ',
'ವ್ಯಾಖ್ಯಾನಂಗೆಯ್',
'ವ್ಯಾಖ್ಯಾನಕಾರ',
'ವ್ಯಾಖ್ಯಾನಶಾಲೆ',
'ವ್ಯಾಖ್ಯಾಪ್ರಜ್ಞಪ್ತಿ',
'ವ್ಯಾಜರೂಪಕ',
'ವ್ಯಾಜಸಂಸ್ತವ',
'ವ್ಯಾಪಾರಕೆಂದ್ರ',
'ವ್ಯಾಪಾರಗಾರ',
'ವ್ಯಾಪಾರಚಿಹ್ನೆ',
'ವ್ಯಾಪಾರದಾರ',
'ವ್ಯಾಪಾರನೀತಿ',
'ವ್ಯಾಪಾರಸಂಸ್ಥೆ',
'ವ್ಯಾಪಾರೀಬುದ್ಧಿ',
'ವ್ಯಾಯಾಮಶಾಲೆ',
'ವ್ಯಾಲಂಬಿವಾಲ',
'ವ್ಯಾಲಕುಂಡಲ',
'ವ್ಯಾವಸಾಯಿಕ',
'ವ್ಯಾವಹಾರಿಕ',
'ವ್ಯಾಳಕಂಕಣ',
'ವ್ಯುಚ್ಛೇದಕಾಲ',
'ವ್ಯುತ್ಪತ್ತಿಶಾಸ್ತ್ರ',
'ವ್ಯೋಮಕಪರ್ದಿ',
'ವ್ರಣಚಿಕಿತ್ಸೆ',
'ವ್ರಣವಿದ್ರಧಿ',
'ವ್ರತಗೆಡಿಸು',
'ವ್ರತನಿಯಮ',
'ವ್ರತಾಚರಣ',
'ವ್ರತಾಚರಣೆ',
'ಶಂಕರಗಂಡ',
'ಶಂಕರಪಾಳಿ',
'ಶಂಕರಪಾಳೆ',
'ಶಂಕರಪೋಳಿ',
'ಶಂಕಾಕುಲಿತ',
'ಶಂಕಾಕುಲಿತೆ',
'ಶಂಕಾಕುಳಿತ',
'ಶಂಕಾಕುಳಿತೆ',
'ಶಂಕುಸ್ಥಾಪನೆ',
'ಶಂಖಪಾಷಾಣ',
'ಶಂಖಪ್ರಣಾದ',
'ಶಂಖವಟಕ',
'ಶಂಬರಕೇಳಿ',
'ಶಂಬರಜಾತ',
'ಶಂಭುನಟನ',
'ಶಕಟಭಂಗ',
'ಶಕಟಭವ',
'ಶಕಟರೇಖೆ',
'ಶಕಟರೇಫ',
'ಶಕಟವ್ಯೂಹ',
'ಶಕಪುರುಷ',
'ಶಕವರುಷ',
'ಶಕುಂತವರ',
'ಶಕುತಿಗೆಡು',
'ಶಕುನಜ್ಞಾನ',
'ಶಕುನಶಾಸ್ತ್ರ',
'ಶಕುಲಾದನಿ',
'ಶಕ್ತಿಗಿಡಿಸು',
'ಶಕ್ತಿಗುಂದಿಸು',
'ಶಕ್ತಿಗೆಡಿಸು',
'ಶಕ್ತಿದಾಯಕ',
'ಶಕ್ತಿದಾಯಕಿ',
'ಶಕ್ತಿಪರಿಷೆ',
'ಶಕ್ತ್ಯನುರೂಪ',
'ಶಕ್ತ್ಯನುಸಾರ',
'ಶಕ್ತ್ಯಾನುಸಾರ',
'ಶಠಪ್ರಕೃತಿ',
'ಶಠಾಗಮಿಕ',
'ಶಣಪುಷ್ಪಿಕೆ',
'ಶತಂಪಾತಾಳ',
'ಶತಪತ್ರಕ',
'ಶತಪರ್ವಿಕೆ',
'ಶತಪ್ರಯತ್ನ',
'ಶತಮೂಲಿಕೆ',
'ಶತರುದ್ರೀಯ',
'ಶತಸಹಸ್ರ',
'ಶತಸಾವಿರ',
'ಶತಸಾಸಿರ',
'ಶತಾಪರಾಧ',
'ಶತಾರಕಲ್ಪ',
'ಶತಾವಧಾನ',
'ಶತಾವಧಾನಿ',
'ಶತ್ರುದಲ್ಲಣ',
'ಶನಿಪ್ರದೋಷ',
'ಶಪಥಂಗೆಯ್',
'ಶಬ್ದಪ್ರಮಾಣ',
'ಶಬ್ದಮಾಧುರ್ಯ',
'ಶಬ್ದವೇಧಿತ್ವ',
'ಶಬ್ದಸಂದರ್ಭ',
'ಶಬ್ದಸಂಪತ್ತು',
'ಶಬ್ದಸಂಸ್ಕಾರ',
'ಶಬ್ದಸಾಮಥ್ರ್ಯ',
'ಶಬ್ದಸಾರಾಯ',
'ಶಬ್ದಸೌಕರ್ಯ',
'ಶಬ್ದಸೌಷ್ಠವ',
'ಶಬ್ದಾಡಂಬರ',
'ಶಬ್ದಾನುವಿದ್ಧ',
'ಶಬ್ದಾಲಂಕಾರ',
'ಶಬ್ದಾಳಂಕಾರ',
'ಶಮನಕಾರಿ',
'ಶಯನಂಗೆಯ್',
'ಶಯನಗೃಹ',
'ಶಯನಾಗಾರ',
'ಶಯನೋತ್ಸವ',
'ಶಯ್ಯಾವಸಥ',
'ಶಯ್ಯಾಸದನ',
'ಶರಚೌಪದಿ',
'ಶರಚ್ಛಂದ್ರಿಕೆ',
'ಶರಣಂಬಡೆ',
'ಶರಣಾಗತ',
'ಶರಣಾಗತಿ',
'ಶರಣಾಗತೆ',
'ಶರಣಾಯಾತ',
'ಶರಣಾರ್ಥಿಸು',
'ಶರಣುಗತಿ',
'ಶರಣುಪೊಗು',
'ಶರಣುವೊಗು',
'ಶರಣುಹೊಗು',
'ಶರತ್ಸಮಯ',
'ಶರಧಿಸುತೆ',
'ಶರಪಂಜರ',
'ಶರಭಪ್ರಾಸ',
'ಶರಭರಿಪು',
'ಶರಭವನ',
'ಶರಷಟ್ಪದಿ',
'ಶರಸಂಧಾನ',
'ಶರಸಂನ್ಯಾಸ',
'ಶರಸನ್ಯಾಸ',
'ಶರಿರಗುಂದು',
'ಶರೀರಗುಣ',
'ಶರೀರತಪ',
'ಶರೀರತ್ಯಾಗ',
'ಶರೀರದಂಡ',
'ಶರೀರಧರ್ಮ',
'ಶರ್ಕರಾದಂತ',
'ಶರ್ಕರಾಪ್ರಭೆ',
'ಶರ್ಕರಾವರ್ತ',
'ಶರ್ಕರೆವಿಲ್ಲು',
'ಶಲ್ಯಚಿಕಿತ್ಸೆ',
'ಶವಪರೀಕ್ಷೆ',
'ಶವವಾಹಕ',
'ಶವಸಂಪುಟ',
'ಶವಸಂಸ್ಕಾರ',
'ಶಶಕವಲೆ',
'ಶಶಕಸರ್ಪ',
'ಶಶಪುರುಷ',
'ಶಶಲಾಂಛನ',
'ಶಶವಿಷಾಣ',
'ಶಶಾಂಕಧರ',
'ಶಶಾಂಕಲೇಖೆ',
'ಶಶಾಂಕವಂಶ',
'ಶಶಿಕಿರಣ',
'ಶಶಿಭೂಷಣ',
'ಶಶಿಮಂಡಲ',
'ಶಶಿವದನ',
'ಶಶಿವದನೆ',
'ಶಶಿಶೇಖರ',
'ಶಶ್ವಜ್ಜೀವನ',
'ಶಷ್ಕುಲಿಕರ್ಣ',
'ಶಸ್ತ್ರಕೌಶಲ',
'ಶಸ್ತ್ರಚಿಕಿತ್ಸೆ',
'ಶಸ್ತ್ರಪ್ರಯೋಗ',
'ಶಸ್ತ್ರಮರಣ',
'ಶಸ್ತ್ರಸಂನ್ಯಾಸ',
'ಶಸ್ತ್ರಸಜ್ಜಿತ',
'ಶಸ್ತ್ರಸಜ್ಜಿತೆ',
'ಶಸ್ತ್ರಸನ್ಯಾಸ',
'ಶಹಾಜೀರಿಗೆ',
'ಶಾಂತಕಲ್ಯಾಣಿ',
'ಶಾಂತಮಾನಸ',
'ಶಾಂತಮಾನಸೆ',
'ಶಾಂತಾಂತರಂಗ',
'ಶಾಂತಾಂತರಂಗೆ',
'ಶಾಂತಿಕಕ್ರಿಯೆ',
'ಶಾಂತಿಪಾಲಕ',
'ಶಾಂತಿಪಾಲನೆ',
'ಶಾಂತಿಮಜ್ಜನ',
'ಶಾಂತಿವಲಯ',
'ಶಾಂತಿವಾಚನ',
'ಶಾಂತೋಪನತ',
'ಶಾಂಭವೀಮುದ್ರೆ',
'ಶಾಕವರ್ತಿಕ',
'ಶಾಕವರ್ತಿಗೆ',
'ಶಾಕಶಾಕಟ',
'ಶಾಕಶಾಕಿನ',
'ಶಾಕಿನೀತಂತ್ರ',
'ಶಾಕಿನೀದೋಷ',
'ಶಾಖಾನಗರ',
'ಶಾಣಾರಿಕಾಸು',
'ಶಾಣೆಯತನ',
'ಶಾನಭೋಗಿಕೆ',
'ಶಾನಾಯಮುದ್ರೆ',
'ಶಾನುಭೋಗಿಕೆ',
'ಶಾಪಮೋಕ್ಷಿತ',
'ಶಾಪಮೋಕ್ಷಿತೆ',
'ಶಾಪವಿಮುಕ್ತ',
'ಶಾಪವಿಮುಕ್ತಿ',
'ಶಾಪವಿಮುಕ್ತೆ',
'ಶಾಪವಿಮೋಕ್ಷ',
'ಶಾಬರಯಂತ್ರ',
'ಶಾಬರಲೋಧ್ರ',
'ಶಾಬಾಸ್‍ಗಿರಿ',
'ಶಾಬ್ದವೇತ್ತೃತೆ',
'ಶಾಭಾಸ್‍ಗಿರಿ',
'ಶಾಮೀಲುದಾರ',
'ಶಾಯಿಕುಡಿಕೆ',
'ಶಾಯಿದಿದಾರ',
'ಶಾರದಾಲಿಪಿ',
'ಶಾಲಭಂಜಿಕೆ',
'ಶಾಲಾಕ್ಯತಂತ್ರ',
'ಶಾಲಿಪಾಲಿಕೆ',
'ಶಾಲಿವಾಹನ',
'ಶಾಲಿಸೌಂದರ',
'ಶಾವಿಗೆಬಳ್ಳಿ',
'ಶಾಶ್ವತಪದ',
'ಶಾಸನಕವಿ',
'ಶಾಸನತಜ್ಞ',
'ಶಾಸನತಜ್ಞೆ',
'ಶಾಸನಪಾಠ',
'ಶಾಸನಸಭೆ',
'ಶಾಸನಹರ',
'ಶಾಸ್ತ್ರಪದ್ಧತಿ',
'ಶಾಸ್ತ್ರಪಾರಗ',
'ಶಾಸ್ತ್ರಪಾರಗೆ',
'ಶಾಸ್ತ್ರಪ್ರಮಾಣ',
'ಶಾಸ್ತ್ರವಚನ',
'ಶಾಸ್ತ್ರವಿರುದ್ಧ',
'ಶಾಸ್ತ್ರವ್ಯುತ್ಪತ್ತಿ',
'ಶಾಸ್ತ್ರಶ್ರವಣ',
'ಶಾಸ್ತ್ರಸಿದ್ಧಾಂತ',
'ಶಾಸ್ತ್ರಾಧ್ಯಯನ',
'ಶಾಹಣೆತನ',
'ಶಾಹೀರ್‍ಗಾಣ',
'ಶಾಹೀರ್‍ಗೀತ',
'ಶಾಹೀರಗಾನ',
'ಶಾಳಭಂಜಿಕೆ',
'ಶಿಂಶುಮಾರಕ',
'ಶಿಕಲಗಾರ',
'ಶಿಕಲಿಗಾರ',
'ಶಿಕ್ಕಲಿಗಾರ',
'ಶಿಕ್ಕಾಮೋರ್ತಬ್ಬ',
'ಶಿಕ್ಷಣಕೇಂದ್ರ',
'ಶಿಕ್ಷಣತಜ್ಞ',
'ಶಿಕ್ಷಣತಜ್ಞೆ',
'ಶಿಕ್ಷಣವೇತ್ತ',
'ಶಿಕ್ಷಣಶಾಖೆ',
'ಶಿಕ್ಷಣಶುಲ್ಕ',
'ಶಿಕ್ಷಾಸವನ',
'ಶಿಕ್ಷಿತಾಯುಧ',
'ಶಿಖಂಡಮಣಿ',
'ಶಿಖಂಡರತ್ನ',
'ಶಿಖಾಮೊಹರು',
'ಶಿಖಿನಯನ',
'ಶಿತಿಸಾರಕ',
'ಶಿಥಿಲಗೊಳ್ಳು',
'ಶಿಥಿಲದ್ವಿತ್ವ',
'ಶಿಥಿಲಮತಿ',
'ಶಿಥಿಲವೃತ್ತಿ',
'ಶಿಥಿಲಾಕ್ಷರ',
'ಶಿರಃಪ್ರಧಾನಿ',
'ಶಿರಗೊಡಹು',
'ಶಿರಚ್ಛೇದನ',
'ಶಿರಪೊಟ್ಟಲಿ',
'ಶಿರಪ್ರಧಾನ',
'ಶಿರಮುಂಡನ',
'ಶಿರಶ್ಛೇದನ',
'ಶಿರಸ್ತೆದಾರ',
'ಶಿರಸ್ತೇದಾರ',
'ಶಿರೋದಳನ',
'ಶಿರೋಭೂಷಣ',
'ಶಿರೋಭ್ಯಂಜನ',
'ಶಿರೋಭ್ರಮಣ',
'ಶಿರೋಭ್ರಮಣೆ',
'ಶಿರೋಮಂಡನ',
'ಶಿರೋವಲಯ',
'ಶಿರೋವೇದನೆ',
'ಶಿರೋವೇಷ್ಟನ',
'ಶಿಲಾಪಟ್ಟಕ',
'ಶಿಲಾಪರೀಕ್ಷೆ',
'ಶಿಲಾಪ್ರತಿಮೆ',
'ಶಿಲಾಫಲಕ',
'ಶಿಲಾಬಾಲಿಕೆ',
'ಶಿಲಾಮಂದಿರ',
'ಶಿಲಾಮುದ್ರಣ',
'ಶಿಲಾಲಿಖಿತ',
'ಶಿಲಾವಿಗ್ರಹ',
'ಶಿಲಾಶಾಸನ',
'ಶಿಲಾಸಂಕಟ',
'ಶಿಲೋಂಛಜೀವಿ',
'ಶಿಲೋಂಛವೃತ್ತಿ',
'ಶಿವತಾಂಡವ',
'ಶಿವದೊಡಕು',
'ಶಿವಪಸಾಯ',
'ಶಿವಪುರಾಣ',
'ಶಿವಬ್ರಾಹ್ಮಣ',
'ಶಿವಲಾಂಛನ',
'ಶಿವಲಿಂಗೈಕ್ಯ',
'ಶಿವಶರಣ',
'ಶಿವಶರಣೆ',
'ಶಿವಸಮಯ',
'ಶಿವಸಾಯುಜ್ಯ',
'ಶಿವಾನುಭವಿ',
'ಶಿವಾನುಭಾವ',
'ಶಿವಾಯಿಜಮೆ',
'ಶಿವಾರಮಣ',
'ಶಿಶಿರಕರ',
'ಶಿಶುಘಾತಕ',
'ಶಿಶುವಿಹಾರ',
'ಶಿಷ್ಯವೇತನ',
'ಶಿಸ್ತುಗಾರಿಕೆ',
'ಶಿಳಾಪಟ್ಟಕ',
'ಶೀಗೆಹುಣ್ಣಿಮೆ',
'ಶೀತಕಿರಣ',
'ಶೀತಮರೀಚಿ',
'ಶೀತಮಾರುತ',
'ಶೀತವಲಯ',
'ಶೀತಾಶ್ರುಬೀಜ',
'ಶೀಪಾರಿಮೂಲಿ',
'ಶೀಬಾರಿಬೇರು',
'ಶೀರಂಗೆಬಳ್ಳಿ',
'ಶೀರ್ಷಾಭರಣ',
'ಶೀಲಸಂಪನ್ನ',
'ಶೀಲಸಂಪನ್ನೆ',
'ಶೀಲಹರಣ',
'ಶುಕಕಾಮಿನಿ',
'ಶುಕ್ತಿಸಂಪುಟ',
'ಶುಕ್ರನಕ್ಷತ್ರ',
'ಶುಕ್ರಾಚಾರ‍್ಯ',
'ಶುಕ್ಲಪ್ರಮೇಹ',
'ಶುಚಿಗೊಳಿಸು',
'ಶುಚಿರ್ಭೂತತೆ',
'ಶುದ್ಧಗೊಳಿಸು',
'ಶುದ್ಧಧೈವತ',
'ಶುದ್ಧಬಂಗಾಳ',
'ಶುದ್ಧಮುಖಾರಿ',
'ಶುದ್ಧವಸಂತ',
'ಶುದ್ಧವಾಹಿನಿ',
'ಶುದ್ಧವಿಷ್ಕಂಭ',
'ಶುದ್ಧಸಾವೇರಿ',
'ಶುದ್ಧಿಗೊಳಿಸು',
'ಶುದ್ಧೀಕರಣ',
'ಶುದ್ಧೀಕರಿಸು',
'ಶುಭಚರಿತ',
'ಶುಭಚರಿತೆ',
'ಶುಭದಾಯಕ',
'ಶುಭದಾಯಕಿ',
'ಶುಭಸ್ವೀಕಾರ',
'ಶುಭ್ರವಾಹನ',
'ಶುರುಳುಮುದು',
'ಶುಶ್ರೂಷೆಗೆಯ್',
'ಶುಷಿರವಾದ್ಯ',
'ಶುಷ್ಕತಾರ್ಕಿಕ',
'ಶುಷ್ಕಪಾಂಡಿತ್ಯ',
'ಶುಷ್ಕಹೃದಯ',
'ಶುಷ್ಕಹೃದಯೆ',
'ಶೂನ್ಯದೈವತ',
'ಶೂನ್ಯಮನಸ್ಸು',
'ಶುಲಾರೋಹಣ',
'ಶೃಂಖಲಾಬಂಧ',
'ಶೃಂಖಲಾಬದ್ಧ',
'ಶೃಂಗರವಡು',
'ಶೃಂಗಾರಂಗೆಯ್',
'ಶೃಂಗಾರಂಬೆಱು',
'ಶೃಂಗಾರಗೊಳ್ಳು',
'ಶೃಂಗಾರಚೇಷ್ಟೆ',
'ಶೃಂಗಾರದೃಷ್ಟಿ',
'ಶೃಂಗಾರಯೋನಿ',
'ಶೃಂಗಾರಸಾರ',
'ಶೃಂಗಾರಾಜಿತೆ',
'ಶೆಂಕೞುನೀರ್',
'ಶೇಕಡಾವಾರು',
'ಶೇಕದಾರಿಕೆ',
'ಶೇಖದಾರಿಕೆ',
'ಶೇನಭಾವಿಕೆ',
'ಶೇಷಭೂಷಣ',
'ಶೇಷಶಯನ',
'ಶೇಷಾದ್ರಿವಾಸ',
'ಶೈತ್ಯೋಪಚಾರ',
'ಶೈಲಜಾಧವ',
'ಶೈಲಜಾಧಿಪ',
'ಶೈವಪುರಾಣ',
'ಶೈವಸಂಪನ್ನ',
'ಶೈವಸಿದ್ಧಾಂತ',
'ಶೈಶವಭಾವ',
'ಶೋಕವರಾಳಿ',
'ಶೋಕಾಕುಲಿತ',
'ಶೋಕಾಕುಲಿತೆ',
'ಶೋಚನೀಯತೆ',
'ಶೋಣತೆವೆಱು',
'ಶೋಣಸಲಿಲ',
'ಶೋಣಿತಮೇಹಿ',
'ಶೋಭನಂಬೆಱು',
'ಶೋಭನಗಾನ',
'ಶೋಭನಗೂಡು',
'ಶೋಭಾಡಂಬರ',
'ಶೋಭಾಯಮಾನ',
'ಶೋಭೆವಡಿಸು',
'ಶೋಷರುಜಾರ್ತ',
'ಶೋಷರುಜಾರ್ತೆ',
'ಶ್ಮಶಾನಮೌನ',
'ಶ್ಮಶಾನವಾಸಿ',
'ಶ್ಯಾನುಭೋಗಿಕೆ',
'ಶ್ಯಾಮಕಂಧರ',
'ಶ್ಯಾಮತೆವೆಱು',
'ಶ್ರಮದಾಯಕ',
'ಶ್ರಮಪಡಿಸು',
'ಶ್ರಮರಹಿತ',
'ಶ್ರಮಸಹಿಷ್ಣು',
'ಶ್ರಮಾಪಹಾರಿ',
'ಶ್ರವಣಪಟು',
'ಶ್ರವಣಶೂಲ',
'ಶ್ರವಣಸುಖ',
'ಶ್ರವಣಾಮಯ',
'ಶ್ರವಣೇಂದ್ರಿಯ',
'ಶ್ರಾವಕತನ',
'ಶ್ರಾವಕಧರ್ಮ',
'ಶ್ರಾವಕಬ್ರತ',
'ಶ್ರಾವಕಮಾನಿ',
'ಶ್ರಾವಕವ್ರತ',
'ಶ್ರಾವಕಾಚಾರ',
'ಶ್ರಾವಣಮಾಸ',
'ಶ್ರೀನಿಕೇತನ',
'ಶ್ರೀಮದ್ಗಾಂಭೀರ್ಯ',
'ಶ್ರೀಮುದ್ರೆಗಿಡ',
'ಶ್ರೀವಿಲಾಸಿನಿ',
'ಶ್ರುತಕೇವಲಿ',
'ಶ್ರುತಕೇವಳಿ',
'ಶ್ರುತದೇವತೆ',
'ಶ್ರುತಪಂಚಮಿ',
'ಶ್ರುತಪಡಿಸು',
'ಶ್ರುತಪಾರಗ',
'ಶ್ರುತವಿಹೀನ',
'ಶ್ರುತಾನುಗಂತ',
'ಶ್ರುತಿಕಠೋರ',
'ಶ್ರುತಿಗಮಕ',
'ಶ್ರುತಿಗೊಳಿಸು',
'ಶ್ರುತಿಪುರುಷ',
'ಶ್ರುತಿಪೆಟ್ಟಿಗೆ',
'ಶ್ರುತಿಬಾಹಿರ',
'ಶ್ರುತಿಬಾಹಿರೆ',
'ಶ್ರುತಿಮುಖೇನ',
'ಶ್ರುತಿವಚನ',
'ಶ್ರುತಿವಿರಸ',
'ಶ್ರುತಿಶರಣ',
'ಶ್ರುತಿಸುಭಗ',
'ಶ್ರೇಯೋಭಿಲಾಷಿ',
'ಶ್ರೋತ್ರಗುತ್ತಗೆ',
'ಶ್ರೋತ್ರವಿಜ್ಞಾನ',
'ಶ್ರೋತ್ರಿಯಮಾನ್ಯ',
'ಶ್ಲಿಷ್ಟರೂಪಕ',
'ಶ್ಲೇಷರೂಪಕ',
'ಶ್ಲೇಷಾಲಂಕಾರ',
'ಶ್ಲೇಷ್ಮಗ್ರಹಣಿ',
'ಶ್ಲೇಷ್ಮಾತಿಸಾರ',
'ಶ್ವಸನಪಥ',
'ಶ್ವಸನಾಶನ',
'ಶ್ವಾಸಧಾರಣೆ',
'ಶ್ವೇತಕಡವ',
'ಶ್ವೇತಕಪೋತ',
'ಶ್ವೇತಚಂದನ',
'ಶ್ವೇತತುರಗ',
'ಶ್ವೇತಮಂದಾರ',
'ಶ್ವೇತಮರಿಚ',
'ಶ್ವೇತವಾಹನ',
'ಶ್ವೇತಶಿರಸ್ಸು',
'ಶ್ವೇತಾತಪತ್ರ',
'ಶ್ವೇತಾಶ್ವತರ',
'ಷಂಡಕವೇದ',
'ಷಟ್ಕೋಣಕುಂಭ',
'ಷಡಂಗಬಲ',
'ಷಡಂಗಸೇನೆ',
'ಷಡಾಯತನ',
'ಷಡುದರ್ಶನ',
'ಷಡುಲಪನ',
'ಷಡ್ಡಕತನ',
'ಷಡ್ಭುಜಸೌತೆ',
'ಷಣ್ಮುಖಪ್ರಿಯ',
'ಷಷ್ಟಿಕಾವ್ರೀಹಿ',
'ಷಷ್ಟ್ಯಬ್ದಪೂರ್ತಿ',
'ಷಷ್ಠೋಪವಾಸ',
'ಷಷ್ಠೋಪವಾಸಿ',
'ಷಿಕಮಿದಾರ',
'ಷಿರಸ್ತೆದಾರ',
'ಷುತಾರ್‍ನಲ್',
'ಷೋಡಶವರ್ಣ',
'ಸಂಕಟಗೊಳ್',
'ಸಂಕಟಪಡು',
'ಸಂಕಡಿಗೊಳ್',
'ಸಂಕರಗೊಳ್',
'ಸಂಕರಜಾತಿ',
'ಸಂಕರರೋಗ',
'ಸಂಕರುಷಣ',
'ಸಂಕಲೆಯಿಕ್ಕು',
'ಸಂಕಲೆವಂದ',
'ಸಂಕಲೆವಡೆ',
'ಸಂಕಲೆವನೆ',
'ಸಂಕಲೆವೀಡು',
'ಸಂಕಲ್ಪಜನ್ಮ',
'ಸಂಕಲ್ಪಭಂಗ',
'ಸಂಕಲ್ಪಭ್ರಮೆ',
'ಸಂಕಲ್ಪಮಂತ್ರ',
'ಸಂಕಲ್ಪಶಕ್ತಿ',
'ಸಂಕಲ್ಪಸಿದ್ಧಿ',
'ಸಂಕಲ್ಪಹಾನಿ',
'ಸಂಕಲ್ಪಹಿಂಸೆ',
'ಸಂಕೀರ್ಣಜಾತಿ',
'ಸಂಕೀರ್ತಿತವ್ಯ',
'ಸಂಕುಗೊರಲ್',
'ಸಂಕುಚಿತತೆ',
'ಸಂಕುಚಿತತ್ವ',
'ಸಂಕೇತಭಾಷೆ',
'ಸಂಕೇತಭೂಮಿ',
'ಸಂಕೇತಲಿಪಿ',
'ಸಂಕೇತಸದ್ಮ',
'ಸಂಕೇತಸ್ಥಲ',
'ಸಂಕೇತಸ್ಥಳ',
'ಸಂಕೇತಸ್ಥಾನ',
'ಸಂಕೋಚದೃಷ್ಟಿ',
'ಸಂಕ್ರಾಂತಿವೃತ್ತ',
'ಸಂಕ್ರಾಂತಿಹಬ್ಬ',
'ಸಂಕ್ಲೇಶಂಬಡು',
'ಸಂಕ್ಲೇಶಕರ',
'ಸಂಖ್ಯಾಪೂರಣ',
'ಸಂಖ್ಯಾಪ್ರಕೃತಿ',
'ಸಂಖ್ಯಾರಹಿತ',
'ಸಂಖ್ಯಾವಾಚಕ',
'ಸಂಗಟದಳೆ',
'ಸಂಗಡಂಗೊಳ್',
'ಸಂಗಡಗಿತ್ತಿ',
'ಸಂಗಡದಾಳ',
'ಸಂಗಡಮಿೞ್ತು',
'ಸಂಗಡವರ್',
'ಸಂಗಡಿಗಾರ',
'ಸಂಗಡಿಗಿತ್ತಿ',
'ಸಂಗತಿರಕ್ತ',
'ಸಂಗತಿವಡೆ',
'ಸಂಗತಿವೆಱು',
'ಸಂಗಮವರಿ',
'ಸಂಗರರಂಗ',
'ಸಂಗರಹಿತ',
'ಸಂಗರಹಿತೆ',
'ಸಂಗಸೂತಕಿ',
'ಸಂಗಾತಿವೆಣ್',
'ಸಂಗೀತಗಾತಿ',
'ಸಂಗೀತಗಾರ',
'ಸಂಗೀತಗಾರ್ತಿ',
'ಸಂಗೀತಶಾಸ್ತ್ರ',
'ಸಂಗೀತಸಭೆ',
'ಸಂಗ್ರಹಾಲಯ',
'ಸಂಘಾತ ತಾಳ',
'ಸಂಚಕರಿಸು',
'ಸಂಚಲಚಿತ್ತ',
'ಸಂಚಲಚಿತ್ತೆ',
'ಸಂಚಲನಾಡಿ',
'ಸಂಚಲಾಂಬಕಿ',
'ಸಂಚಳಕಾರ',
'ಸಂಚಳಖಾರ',
'ಸಂಚಳಚಿತ್ತ',
'ಸಂಚಳಚಿತ್ತೆ',
'ಸಂಚಾರಮಾರ್ಗ',
'ಸಂಚಾರಿಭಾವ',
'ಸಂಚಾರೀಭಾವ',
'ಸಂಚಿತಕರ್ಮ',
'ಸಂಚಿತಪಾಪ',
'ಸಂಚಿತಪುಣ್ಯ',
'ಸಂಚುಗಾಣಿಸು',
'ಸಂಚುಗಾರಿಕೆ',
'ಸಂಜನಿಯಿಸು',
'ಸಂಜೆಗತ್ತಲು',
'ಸಂಜೆಗತ್ತಲೆ',
'ಸಂಜೆಗಿರಣ',
'ಸಂಜೆದೀವಿಗೆ',
'ಸಂಜೆಬಿಸಿಲು',
'ಸಂಜೆಮಲ್ಲಿಗೆ',
'ಸಂಜೆವಾರಿಸು',
'ಸಂಜೆಸಮಾಧಿ',
'ಸಂಜ್ಞಾಪ್ರಕೃಷ್ಟ',
'ಸಂಡಿಗೆಗೆಯ್',
'ಸಂತತಿಗೆಡು',
'ಸಂತತಿಚ್ಛೇದ',
'ಸಂತತಿವೆಱು',
'ಸಂತತಿಹೀನ',
'ಸಂತತಿಹೀನೆ',
'ಸಂತವಡಿಸು',
'ಸಂತಸಂಗಿಡು',
'ಸಂತಸಂಗೊಳ್',
'ಸಂತಸಂದಳೆ',
'ಸಂತಸಂಬಡು',
'ಸಂತಸಂಬಡೆ',
'ಸಂತಸಂಬೆಱು',
'ಸಂತಸಗೆಡು',
'ಸಂತಸಗೊಳು',
'ಸಂತಸಗೊಳ್ಳು',
'ಸಂತಸದೀವು',
'ಸಂತಸಪಡು',
'ಸಂತಸಬಡು',
'ಸಂತಸಮಿರ್',
'ಸಂತಸಮೊಂದು',
'ಸಂತಸವಡು',
'ಸಂತಸವಡೆ',
'ಸಂತಸವಾನ್',
'ಸಂತಸವೆಱು',
'ಸಂತಸವೊಂದು',
'ಸಂತಾನಕ್ಷಯ',
'ಸಂತಾನಗಾಮಿ',
'ಸಂತಾನಬೀಜ',
'ಸಂತಾನರೇಖೆ',
'ಸಂತಾನಲಕ್ಷ್ಮಿ',
'ಸಂತಾನವಡೆ',
'ಸಂತಾಪಂಗೊಳ್',
'ಸಂತಾಪಗೊಳ್ಳು',
'ಸಂತುಷ್ಟಿವಡೆ',
'ಸಂತೃಪ್ತಿಗೊಳು',
'ಸಂತೃಪ್ತಿಗೊಳ್ಳು',
'ಸಂತೆಪಸಿಗೆ',
'ಸಂತೋಷಂಬಡು',
'ಸಂತೋಷಕರ',
'ಸಂತೋಷಕೂಟ',
'ಸಂತೋಷಗೊಳು',
'ಸಂತೋಷಗೊಳ್ಳು',
'ಸಂತೋಷನಾಡಿ',
'ಸಂತೋಷಪಡು',
'ಸಂತೋಷಬಡು',
'ಸಂತೋಷಬಾಷ್ಪ',
'ಸಂತೋಸಂಬಡು',
'ಸಂತ್ರಾಸಕರ',
'ಸಂಥೆಗಂಟಳೆ',
'ಸಂದಣಿಗೊಳ್',
'ಸಂದಣಿಗೊಳು',
'ಸಂದಣಿವಡೆ',
'ಸಂದರುಶನ',
'ಸಂದರುಸನ',
'ಸಂದರ್ಶನೀಯ',
'ಸಂದಳಿಗೊಳ್',
'ಸಂದಳಿಗೊಳು',
'ಸಂದಾನಿತಕ',
'ಸಂದಿಗುಸುಕ',
'ಸಂದುಕೂಡಿಸು',
'ಸಂದುಗುಸುಕ',
'ಸಂದುಗೂಡಿಸು',
'ಸಂದುಸೇರುವೆ',
'ಸಂದೆಗಂಬಡು',
'ಸಂದೆಮಸಕು',
'ಸಂದೆಯಂಬಡು',
'ಸಂದೆಯವಡು',
'ಸಂದೆಸಮಯ',
'ಸಂದೇಶಹರ',
'ಸಂದೇಹಗ್ರಸ್ತ',
'ಸಂದೇಹಗ್ರಸ್ತೆ',
'ಸಂದೇಹಪಡು',
'ಸಂದೇಹವಳಿ',
'ಸಂದೇಹವಾದಿ',
'ಸಂದೇಹಾಸ್ಪದ',
'ಸಂಧಾನಂಗೆಯ್',
'ಸಂಧಾನಕಾರ',
'ಸಂಧಿವಿಕಲ್ಪ',
'ಸಂಧಿವಿಗ್ರಹ',
'ಸಂಧಿವಿಗ್ರಹಿ',
'ಸಂಧಿಸಾಧಿಸು',
'ಸಂಧುಕ್ಷ್ಯಮಾಣ',
'ಸಂಧ್ಯಾತಪನ',
'ಸಂಧ್ಯಾರುಣಿತ',
'ಸಂಧ್ಯಾವಂದನ',
'ಸಂಧ್ಯಾವಂದನೆ',
'ಸಂಧ್ಯೋಪಾಸನೆ',
'ಸಂನ್ಯಾಸಾಶ್ರಮ',
'ಸಂಪಗೆಗಾವಿ',
'ಸಂಪದವರು',
'ಸಂಪದವೇಱು',
'ಸಂಪನ್ನತನ',
'ಸಂಪರ್ಕಭಾಷೆ',
'ಸಂಪಾದಕತ್ವ',
'ಸಂಪಾದಕೀಯ',
'ಸಂಪುಷ್ಟಫಲ',
'ಸಂಪ್ರಜ್ವಲಿತ',
'ಸಂಪ್ರಣಿಧಾನ',
'ಸಂಪ್ರತಿಕಾರ',
'ಸಂಪ್ರತಿಪತ್ತಿ',
'ಸಂಪ್ರತಿಪನ್ನ',
'ಸಂಪ್ರದಾಯಸ್ಥ',
'ಸಂಪ್ರದಾಯಸ್ಥೆ',
'ಸಂಪ್ರದಾಯಿನಿ',
'ಸಂಪ್ರಸಾರಣೆ',
'ಸಂಪ್ರಸ್ಕಂದನ',
'ಸಂಪ್ರಹರಣ',
'ಸಂಪ್ರಾರ್ಥನಂಗೈ',
'ಸಂಬಂಧಪಡು',
'ಸಂಬಂಧಭಕ್ತಿ',
'ಸಂಬಂಧಾಕ್ಷರ',
'ಸಂಬರಕಾಗೆ',
'ಸಂಬಳಂಗೊಡು',
'ಸಂಬಳಂಗೊಳ್',
'ಸಂಬಳಂಬಡೆ',
'ಸಂಬಳಕೊಡು',
'ಸಂಬಳಗಾರ',
'ಸಂಬಳಗೋಲ್',
'ಸಂಬಳಿಗೋಲ್',
'ಸಂಬಳಿಗೋಲು',
'ಸಂಬಾರಕಾಗೆ',
'ಸಂಬಾರಗಾಗೆ',
'ಸಂಬಾರದ್ರವ್ಯ',
'ಸಂಬಾರಬಳ್ಳಿ',
'ಸಂಬಾರಬೀಜ',
'ಸಂಬಾರವಳ್ಳಿ',
'ಸಂಬಾರವಿಕ್ಕು',
'ಸಂಬಾರಸೊಪ್ಪು',
'ಸಂಬೋಳಿಗೋಲ್',
'ಸಂಭವನೀಯ',
'ಸಂಭಾವನಾರ್ಥ',
'ಸಂಭಾವಿತಾತ್ಮ',
'ಸಂಭೃತತೋಷ',
'ಸಂಭೃತತೋಷೆ',
'ಸಂಭೋಗಕಾಯ',
'ಸಂಭ್ರಮಕಾಱ',
'ಸಂಭ್ರಮಮಾನ್',
'ಸಂಭ್ರಮವಡೆ',
'ಸಂಭ್ರಮವಾನ್',
'ಸಂಭ್ರಮವೆಱು',
'ಸಂಭ್ರಮವೊಗೆ',
'ಸಂಭ್ರಮಾಕುಳ',
'ಸಂಭ್ರಮಾಕುಳೆ',
'ಸಂಯುಕ್ತಾಕ್ಷರ',
'ಸಂಯುತಹಸ್ತ',
'ಸಂಯೋಗಾಕ್ಷರ',
'ಸಂರಂಭವತಿ',
'ಸಂವರ್ತಕಾಲ',
'ಸಂವರ್ತಸ್ಮೃತಿ',
'ಸಂವಳಯಿತ',
'ಸಂವಾದಿತನ',
'ಸಂವಿಜ್ಜೀವನ',
'ಸಂವಿಧಾಯಕ',
'ಸಂವಿಧಾಯಕಿ',
'ಸಂವೀಕ್ಷಣಂಗೈ',
'ಸಂವೃತಾಕಾರ',
'ಸಂಶಯಾಕ್ಷೇಪ',
'ಸಂಶಯಾಸ್ಪದ',
'ಸಂಶ್ಲೇಷತತ್ವ',
'ಸಂಸತ್ಸದಸ್ಯ',
'ಸಂಸರ್ಗದೋಷ',
'ಸಂಸರ್ಗವಶ',
'ಸಂಸಾರಚಕ್ರ',
'ಸಂಸಾರಪಾಶ',
'ಸಂಸಾರಭೀರು',
'ಸಂಸಾರಸಂಗ',
'ಸಂಸಾರಹೇಹ',
'ಸಂಸಾರಿಜೀವ',
'ಸಂಸೃತಿಗತಿ',
'ಸಂಸೃತಿಚಕ್ರ',
'ಸಂಸೃತಿಧರ್ಮ',
'ಸಂಸೃತಿವಿಧಿ',
'ಸಂಸ್ಕಾರಹೀನ',
'ಸಂಸ್ಕಾರಹೀನೆ',
'ಸಂಸ್ತೂಯಮಾನ',
'ಸಂಸ್ಥಾಪತ್ತನ',
'ಸಂಸ್ಮಾರಕತ್ವ',
'ಸಂಹಾರಕಾಲ',
'ಸಂಹಾರನ್ಯಾಸ',
'ಸಂಹಾರರುದ್ರ',
'ಸಕಲಂಕತೆ',
'ಸಕಲದತ್ತಿ',
'ಸಕಲರ್ತುಕ',
'ಸಕಲಾಕ್ಷರ',
'ಸಕಳದತ್ತಿ',
'ಸಕಳರ್ತುಕ',
'ಸಕುನಗಾರ',
'ಸಕುನವಕ್ಕಿ',
'ಸಕೃತ್ಪ್ರಸೂತೆ',
'ಸಕೇಶಿತನ',
'ಸಕೌಕ್ಷೇಯಕ',
'ಸಕ್ಕರೆಕಂಚಿ',
'ಸಕ್ಕರೆಕಡ್ಡಿ',
'ಸಕ್ಕರೆಗಾಯಿ',
'ಸಕ್ಕರೆಗುತ್ತಿ',
'ಸಕ್ಕರೆಗುತ್ತು',
'ಸಕ್ಕರೆನೆಲ್ಲಿ',
'ಸಕ್ಕರೆಬಿಲ್ಲ',
'ಸಕ್ಕರೆಬಿಲ್ಲು',
'ಸಕ್ಕರೆಬೀರು',
'ಸಕ್ಕರೆಬೆಂಡೆ',
'ಸಕ್ಕರೆಮಾವು',
'ಸಕ್ಕರೆವಾಳೆ',
'ಸಕ್ಕರೆವಿಲ್ಲ',
'ಸಕ್ಕರೆವಿಲ್ಲು',
'ಸಕ್ರಿಯಕಾರಿ',
'ಸಗಡೆಸೊಪ್ಪು',
'ಸಗಣನೀರ್',
'ಸಗಣಪಿಂಡ',
'ಸಗಣಿನೀರ್',
'ಸಗಣಿನೀರು',
'ಸಗಣಿಪಿಂಡ',
'ಸಗರಿಕಂಟಿ',
'ಸಗರಿಮುಳ್ಳು',
'ಸಗರ್ಭಾಂಗನೆ',
'ಸಗಾಢತನ',
'ಸಗಾನತನ',
'ಸಗುಣಬ್ರಹ್ಮ',
'ಸಗ್ಗವೊನಲು',
'ಸಗ್ಗಸಲಿಗೆ',
'ಸಗ್ಗಿಗದೊಱೆ',
'ಸಗ್ಗಿಗವಟ್ಟೆ',
'ಸಗ್ಗಿಗವೆಣ್',
'ಸಘಾನತನ',
'ಸಚಿವಾಯತ್ತ',
'ಸಚಿವಾಲಯ',
'ಸಚೇಲಸ್ನಾನ',
'ಸಚ್ಚಿದಾನಂದ',
'ಸಜಯವಡೆ',
'ಸಜಾತಿವಾಸ',
'ಸಜ್ಜನಗಿತ್ತಿ',
'ಸಜ್ಜನತನ',
'ಸಜ್ಜಾತಿಕ್ರಿಯೆ',
'ಸಜ್ಜಾಮಂದಿರ',
'ಸಜ್ಜಿಗಿಖಾರ',
'ಸಜ್ಜುಕಂಬಡೆ',
'ಸಜ್ಜುಗೊಳಿಸು',
'ಸಜ್ಜುಬಡಿಸು',
'ಸಜ್ಜೆಗೊಳಿಸು',
'ಸಜ್ಜೆನಿವಾಸ',
'ಸಜ್ಜೈವಾತೃಕ',
'ಸಜ್ಞಾನಚಕ್ಷು',
'ಸಟ್ಟಾವ್ಯಾಪಾರ',
'ಸಟ್ಟುಗಂಬಿಡಿ',
'ಸಡಗರಣೆ',
'ಸಡಗರಿಕೆ',
'ಸಡಗರಿಸು',
'ಸಡಪುಡನೆ',
'ಸಡಸಡೀತ',
'ಸಡಹುಡನೆ',
'ಸಡಿಲುಗಚ್ಚೆ',
'ಸಡಿಲುಬಾಯಿ',
'ಸಡ್ಡುಗತನ',
'ಸಡ್ಡುವತನ',
'ಸಣ್ಣಅಲುಬು',
'ಸಣ್ಣಈಚಲು',
'ಸಣ್ಣಉಡಿಪ್ಪೆ',
'ಸಣ್ಣಕಡಂಬೆ',
'ಸಣ್ಣಕರುಳು',
'ಸಣ್ಣಕರ್ಪೂರ',
'ಸಣ್ಣಕೆಲಸ',
'ಸಣ್ಣಕ್ಕಿಹುಲ್ಲು',
'ಸಣ್ಣಖಚೋರ',
'ಸಣ್ಣಗಳಮೆ',
'ಸಣ್ಣಗುರಕಿ',
'ಸಣ್ಣಗೊಳಿಸು',
'ಸಣ್ಣತುರುಚಿ',
'ಸಣ್ಣನೆಗ್ಗಿಲು',
'ಸಣ್ಣಪರೀಕ್ಷೆ',
'ಸಣ್ಣಬೆರಲು',
'ಸಣ್ಣಬೆರಳು',
'ಸಣ್ಣಮಲ್ಲಿಗೆ',
'ಸಣ್ಣಮೆಣಸು',
'ಸಣ್ಣವಡಂಜಿ',
'ಸಣ್ಣವಿಷಯ',
'ಸಣ್ಣಸಾಸಿವೆ',
'ಸಣ್ಣಸೆಣಬು',
'ಸಣ್ಣಸೇವಗೆ',
'ಸಣ್ಣಹುಳಕ',
'ಸಣ್ಣಹೆಕ್ಕರ',
'ಸಣ್ಣಹೆಸ್ಸರೆ',
'ಸಣ್ಣೆಲೆಬೋಗಿ',
'ಸತತಗತಿ',
'ಸತುಕರಿಸು',
'ಸತ್ಕಾರ್ಯವಾದ',
'ಸತ್ಕಾಲಕ್ಷೇಪ',
'ಸತ್ಕುಳವಂತ',
'ಸತ್ಕುಳವಂತೆ',
'ಸತ್ತಿಗೆತ್ತಿಸು',
'ಸತ್ತಿಗೆವಂತ',
'ಸತ್ತ್ವನಿಧಾನ',
'ಸತ್ತ್ವಶಾಲಿನಿ',
'ಸತ್ಪಥಕೇಶ',
'ಸತ್ಪಥಭಾವಿ',
'ಸತ್ಪಥಾತಿಥಿ',
'ಸತ್ಪಾತ್ರದಾನ',
'ಸತ್ಯನಿಷ್ಠುರ',
'ಸತ್ಯನಿಷ್ಠುರೆ',
'ಸತ್ಯಪ್ರತಿಜ್ಞ',
'ಸತ್ಯಪ್ರತಿಜ್ಞೆ',
'ಸತ್ಯವಂತಿಕೆ',
'ಸತ್ಯವಾದಿನಿ',
'ಸತ್ಯವೇದಿತ್ವ',
'ಸತ್ಯಶುದ್ಧತೆ',
'ಸತ್ಯಸಂಧತೆ',
'ಸತ್ಯಹೀನತೆ',
'ಸತ್ಯಾಚರಣೆ',
'ಸತ್ಯಾನ್ವೇಷಣೆ',
'ಸತ್ರನಿಳಯ',
'ಸತ್ವಪರೀಕ್ಷೆ',
'ಸತ್ವಾತಿಶಯ',
'ಸತ್ಸಂಪ್ರದಾಯ',
'ಸತ್ಸಮಾಗಮ',
'ಸತ್ಸಹವಾಸ',
'ಸದಗಪ್ರಾಣಿ',
'ಸದನ್ಯಗ್ರಹ',
'ಸದಭಿಪ್ರಾಯ',
'ಸದಭಿಮತ',
'ಸದರಗೊಡು',
'ಸದರುಕಟ್ಟೆ',
'ಸದವಕಾಶ',
'ಸದಸನ್ಮತಿ',
'ಸದಾನುವರ್ತಿ',
'ಸದಾಮಲ್ಲಿಗೆ',
'ಸದುಕಾರಣ',
'ಸದುಬಕುತಿ',
'ಸದುವಿನಯ',
'ಸದುಹೃದಯ',
'ಸದುಹೃದಯೆ',
'ಸದೆಕೂಡಿಸು',
'ಸದೆಗೂಡಿಸು',
'ಸದೆಬಡಿಗ',
'ಸದೆಮೆಣಸು',
'ಸದ್ಗುಣಂದಳೆ',
'ಸದ್ಗುಣವೆಱು',
'ಸದ್ದಿಲಿತನ',
'ಸದ್ಬಗೆವೆಱು',
'ಸದ್ಭಾವಂಬೆಱು',
'ಸದ್ಯಾಜಮಾನ',
'ಸದ್ಯಾನುಭವ',
'ಸದ್ವಿನಿಯೋಗ',
'ಸದ್ವ್ಯವಹಾರ',
'ಸಧಾರತನ',
'ಸನತ್ಕುಮಾರ',
'ಸನದುಪತ್ರ',
'ಸನಿಯಂಬೋಗು',
'ಸನುಮಾನಿಸು',
'ಸನ್ನಣಂಗೆಯ್',
'ಸನ್ನಣಂಗೊಳ್',
'ಸನ್ನಣಂದುಡು',
'ಸನ್ನಣಂದೊಡು',
'ಸನ್ನಣಂಬೊಗು',
'ಸನ್ನದುಕೊಡು',
'ಸನ್ನಾಹಗೆಯ್',
'ಸನ್ನಾಹಭೇರಿ',
'ಸನ್ನಿಕರ್ಷಣ',
'ಸನ್ನಿಪಾತಕ',
'ಸನ್ನಿರೋಧನ',
'ಸನ್ನೀಲಪಟ',
'ಸನ್ನುತಿಗೆಯ್',
'ಸನ್ನುತಿವಡೆ',
'ಸನ್ನೆಗಟ್ಟಿಗೆ',
'ಸನ್ನೆಗಹಳೆ',
'ಸನ್ನೆಯಗಾರ',
'ಸನ್ಮಂತ್ರಲಕ್ಷ',
'ಸನ್ಮತಿವೆಱು',
'ಸನ್ಮಾನಂಬೆಱು',
'ಸನ್ಮೋಹನಾಸ್ತ್ರ',
'ಸಪತ್ನೋತ್ಕರ',
'ಸಪಾದಭಕ್ಷ್ಯ',
'ಸಪ್ತಕೂಪಾರ',
'ಸಪ್ತಪದಾರ್ಥ',
'ಸಪ್ತಪಾತಾಲ',
'ಸಪ್ತಭೂಮಿಕ',
'ಸಪ್ತಮತಾನ',
'ಸಪ್ತಮಾತೃಕೆ',
'ಸಪ್ತರಸನ',
'ಸಪ್ತರಾಶಿಕ',
'ಸಪ್ತವಾರಿಧಿ',
'ಸಪ್ತವಾರುವ',
'ಸಪ್ತವಾಸರ',
'ಸಪ್ತವಿಭಕ್ತಿ',
'ಸಪ್ತವ್ಯಸನ',
'ಸಪ್ತವ್ಯಸನಿ',
'ಸಪ್ತಶರಧಿ',
'ಸಪ್ತಸಮುದ್ರ',
'ಸಪ್ತಸೋಪಾನ',
'ಸಪ್ತಾನೀಕಿನಿ',
'ಸಪ್ರವೀಚಾರ',
'ಸಫಲೀಕೃತ',
'ಸಬಳಗೋಲು',
'ಸಬಾಲವೃದ್ಧ',
'ಸಬಾಳವೃದ್ಧ',
'ಸಬ್ಬಮಂಗಳೆ',
'ಸಬ್ಬವಕಾರ',
'ಸಬ್ಬವಕಾರ್ತಿ',
'ಸಬ್ಬವಕಾಱ',
'ಸಬ್ಬವಗಾಱ',
'ಸಬ್ಬವಗಾರ್ತಿ',
'ಸಭಾತಾಂಬೂಲ',
'ಸಭಾನಿಳಯ',
'ಸಭಾಭವನ',
'ಸಭಾಮಂಟಪ',
'ಸಭಾಮಂಡಲ',
'ಸಭಾಮಂಡಳ',
'ಸಭಾಮರ್ಯಾದೆ',
'ಸಭಾವಂದನೆ',
'ಸಭಾಸದನ',
'ಸಭೆಕೂಡಿಸು',
'ಸಭೆವೆರಸು',
'ಸಭೆಸೇರಿಸು',
'ಸಮಂಜಸತೆ',
'ಸಮಂಜಸತ್ವ',
'ಸಮಂಜಸಿಕೆ',
'ಸಮಂತದುಗ್ಧೆ',
'ಸಮಂತಾಗಿಸು',
'ಸಮಕರಣ',
'ಸಮಕಾಲಿಕ',
'ಸಮಕಾಲೀನ',
'ಸಮಕಾಲೀನೆ',
'ಸಮಕೊಳಿಸು',
'ಸಮಗಾಣಿಸು',
'ಸಮಗೊಳಿಸು',
'ಸಮಗ್ರಾಹಕ',
'ಸಮಚಡಿಸು',
'ಸಮಚ್ಛಿದುರ',
'ಸಮಜಾಯಿಷಿ',
'ಸಮಜಾಯಿಸು',
'ಸಮಜೋಡಿಸು',
'ಸಮತಳಿಸು',
'ಸಮತಾಭಾವ',
'ಸಮತಾವಾದ',
'ಸಮತಿಕ್ರಾಂತ',
'ಸಮತೆಗಟ್ಟು',
'ಸಮತೆಗೆಡು',
'ಸಮತೆವೆಱು',
'ಸಮತೋಲನ',
'ಸಮತೋಲನೆ',
'ಸಮತ್ವೀಭಾವ',
'ಸಮತ್ಸರತ್ವ',
'ಸಮದಂಡಿಗೆ',
'ಸಮದರ್ಶನ',
'ಸಮದರ್ಶಿತ್ವ',
'ಸಮಧಿಕೃತ',
'ಸಮಧಿಗತ',
'ಸಮಧ್ಯಾಸಿತ',
'ಸಮನಂತರ',
'ಸಮನಂತರಂ',
'ಸಮಪಾತಳಿ',
'ಸಮಭಾಜಕ',
'ಸಮಭಿಮತ',
'ಸಮಭ್ಯರ್ಥಿಸು',
'ಸಮಯಂದಪ್ಪು',
'ಸಮಯಬಾಹ್ಯ',
'ಸಮಯಸ್ಫೂರ್ತಿ',
'ಸಮಯಾಚಾರ',
'ಸಮಯಾಚಾರಿ',
'ಸಮಯಚಾರ್ಯ',
'ಸಮಯೋಚಿತ',
'ಸಮರಕರ',
'ಸಮರರಂಗ',
'ಸಮರಾಂಕಣ',
'ಸಮರಾಂಗಣ',
'ಸಮರಾಕರ',
'ಸಮರ್ಚನೆಗೈ',
'ಸಮರ್ಪಕತೆ',
'ಸಮವಕಾರ',
'ಸಮವತೀರ್ಣ',
'ಸಮವಯಸ',
'ಸಮವಯಸ್ಕ',
'ಸಮವಯಸ್ಕೆ',
'ಸಮವಸೃತಿ',
'ಸಮವೇದನೆ',
'ಸಮವೇದಿಸು',
'ಸಮಶೀತೋಷ್ಣ',
'ಸಮಷ್ಟಿಪ್ರಜ್ಞೆ',
'ಸಮಷ್ಟಿಹಿತ',
'ಸಮಸಂಸ್ಕೃತ',
'ಸಮಸಪ್ತಕ',
'ಸಮಸ್ಖಲಿತ',
'ಸಮಸ್ತಪದ',
'ಸಮಸ್ಯಾತ್ಮಕ',
'ಸಮಾಂಸಮೀನೆ',
'ಸಮಾಕರ್ಣಿತ',
'ಸಮಾಕಲಿತ',
'ಸಮಾಗಮನ',
'ಸಮಾಚರಣ',
'ಸಮಾಚರಿಸು',
'ಸಮಾಜಜೀವಿ',
'ಸಮಾಜದ್ರೋಹ',
'ಸಮಾಜದ್ರೋಹಿ',
'ಸಮಾಜವಾದ',
'ಸಮಾಜವಾದಿ',
'ಸಮಾಜಶಾಸ್ತ್ರ',
'ಸಮಾಜಸೇವೆ',
'ಸಮಾಧಾನಿಸು',
'ಸಮಾಧಿಧ್ಯಾನ',
'ಸಮಾಧಿನಿಷ್ಠ',
'ಸಮಾಧಿಯೋಗ',
'ಸಮಾಧಿವಿಧಿ',
'ಸಮಾಧಿಸ್ಥಿತಿ',
'ಸಮಾನತಳ',
'ಸಮಾನವಾಯು',
'ಸಮಾನಸ್ಕಂಧ',
'ಸಮಾನಸ್ಕಂಧೆ',
'ಸಮಾನಾಂತರ',
'ಸಮಾನೋದರ್ಯ',
'ಸಮಾಪದಿಸು',
'ಸಮಾರಾಧನೆ',
'ಸಮಾರೋಪಿತ',
'ಸಮಾಲಂಕಾರ',
'ಸಮಾಲಂಭನ',
'ಸಮಾಲಕ್ಷಿತ',
'ಸಮಾಲಿಂಗಿತ',
'ಸಮಾಲೋಕನ',
'ಸಮಾಲೋಚಕ',
'ಸಮಾಲೋಚಕಿ',
'ಸಮಾಲೋಚನೆ',
'ಸಮಾಲೋಚಿಸು',
'ಸಮಾವರ್ತನ',
'ಸಮಾವರ್ತನೆ',
'ಸಮಾಶ್ವಾಸಿಸು',
'ಸಮಾಸಲಿಂಗ',
'ಸಮಾಸಾದಿತ',
'ಸಮಾಳೋಚನ',
'ಸಮಾಳೋಚಿಸು',
'ಸಮೀಕರಣ',
'ಸಮೀಕರಿಸು',
'ಸಮೀಚೀನತೆ',
'ಸಮೀಚೀನತ್ವ',
'ಸಮೀಪಗತ',
'ಸಮೀಪಪ್ರಾಸ',
'ಸಮೀಪವರ್ತಿ',
'ಸಮೀಪಸ್ಥಿತ',
'ಸಮುಚಿತತೆ',
'ಸಮುಚ್ಚಳಿತ',
'ಸಮುಜ್ವಳಿಸು',
'ಸಮುತ್ಕಂಟಕ',
'ಸಮುತ್ಕಟತೆ',
'ಸಮುತ್ತರಣ',
'ಸಮುತ್ತಾನಿತ',
'ಸಮುತ್ತಾರಕ',
'ಸಮುತ್ತಾರಿಸು',
'ಸಮುತ್ಥಾಪಿತ',
'ಸಮುತ್ಪಾಟನ',
'ಸಮುತ್ಪಾದಿಸು',
'ಸಮುತ್ಸಾರಿತ',
'ಸಮುತ್ಸುಕತೆ',
'ಸಮುದಾಚಾರ',
'ಸಮುದ್ಘೋಷಣ',
'ಸಮುದ್ಧರಣ',
'ಸಮುದ್ಧರಿಸು',
'ಸಮುದ್ಭವಿಸು',
'ಸಮುದ್ರಕಳ್ಳ',
'ಸಮುದ್ರಕಾಂತೆ',
'ಸಮುದ್ರಘೋಷ',
'ಸಮುದ್ರಪಾಲ',
'ಸಮುದ್ರಪಾಳ',
'ಸಮುದ್ರಪೋರ',
'ಸಮುದ್ರಫಲ',
'ಸಮುದ್ರಫೇನ',
'ಸಮುದ್ರಬಳ್ಳಿ',
'ಸಮುದ್ರಬಾಳೆ',
'ಸಮುದ್ರಮಾರ್ಗ',
'ಸಮುದ್ರಯಾನ',
'ಸಮುದ್ರಯೋಗ',
'ಸಮುದ್ರವಹ್ನಿ',
'ಸಮುದ್ರಶೋಕೆ',
'ಸಮುದ್ರಸೋಗೆ',
'ಸಮುದ್ರಸೌತೆ',
'ಸಮುದ್ರಹಾಲೆ',
'ಸಮುದ್ರಾತ್ಮಜೆ',
'ಸಮುದ್ವರ್ತನ',
'ಸಮುನ್ನತಿಕೆ',
'ಸಮುನ್ಮಿಲಿತ',
'ಸಮುಪಜಾತ',
'ಸಮುಪಸ್ಥಿತ',
'ಸಮುಪಹಿತ',
'ಸಮುಪಾಹೃತ',
'ಸಮುಲ್ಬಣಾಂಬು',
'ಸಮುಲ್ಲಸಿತ',
'ಸಮುಲ್ಲಾಸಿತ',
'ಸಮೂಹಸನ್ನಿ',
'ಸಮ್ಮಣಂಬಡೆ',
'ಸಮ್ಮವಾವುಗೆ',
'ಸಮ್ಮಾನಗೆಯ್',
'ಸಮ್ಮಾರ್ಜನಂಗೈ',
'ಸಮ್ಮುಖಂಗೆಯ್',
'ಸಮ್ಮುಖವೆಱು',
'ಸಮ್ಮುಖಾಗತ',
'ಸಮ್ಮುದವೆಱು',
'ಸಮ್ಮೇಳವಿಸು',
'ಸಮ್ಮೋದಗೊಳ್',
'ಸಮ್ಮೋಹನಾಸ್ತ್ರ',
'ಸಮ್ಯಕ್ಕರುಣ',
'ಸಮ್ಯಕ್ಚರಿತ್ರ',
'ಸಮ್ಯಕ್ಚಾರಿತ್ರ',
'ಸಮ್ಕಕ್ಛೀಲತೆ',
'ಸಮ್ಯಕ್ತ್ವಶುದ್ಧಿ',
'ಸಮ್ಯಗ್ದರ್ಶನ',
'ಸಮ್ಯಧಿಗಮ್ಯ',
'ಸಯಸಂಭ್ರಮ',
'ಸಯಸತ್ತಮ',
'ಸರಂಗೊಳಿಸು',
'ಸರಂಜಾನಿಸು',
'ಸರಕಂಗಟ್ಟು',
'ಸರಕಟಿಸು',
'ಸರಕರಣ',
'ಸರಕುಗಟ್ಟು',
'ಸರಕುಗೊಳ್',
'ಸರಕುಗೊಳ್ಳು',
'ಸರಕುದೆಗೆ',
'ಸರಗಡಲು',
'ಸರಗರಮು',
'ಸರಗುಣಿಕೆ',
'ಸರಗೊಳಿಸು',
'ಸರಣವೃತ್ತಿ',
'ಸರಣಾಗತ',
'ಸರಣಿಗೆಡು',
'ಸರಣಿದೋಱು',
'ಸರಣುಹೋಗು',
'ಸರದಾವಣಿ',
'ಸರಪರಾಜು',
'ಸರಫರಾಜ',
'ಸರಫರಾಜಿ',
'ಸರಫರಾಜು',
'ಸರಬರಾಜು',
'ಸರಬರಾಯ',
'ಸರಬರಾಯಿ',
'ಸರಮಂಡಲ',
'ಸರಲದ್ರವ',
'ಸರಲಷ್ಕರ',
'ಸರವಂದಿಗ',
'ಸರವಣಿಗ',
'ಸರವತಿಗೆ',
'ಸರವತ್ತಿಗೆ',
'ಸರವಳಿಗೆ',
'ಸರವಿವೋಗು',
'ಸರವುಗಟ್ಟು',
'ಸರವೇಱಿಸು',
'ಸರಸಂಬೆಱು',
'ಸರಸಗಾತಿ',
'ಸರಸಗಾರ',
'ಸರಸಗಾಱ',
'ಸರಸರನೆ',
'ಸರಸರಿಸು',
'ಸರಸವತಿ',
'ಸರಸಿಜಾಕ್ಷ',
'ಸರಸಿಜಾಕ್ಷಿ',
'ಸರಸಿಜಾತ',
'ಸರಸಿರುಹ',
'ಸರಸೀರುಹ',
'ಸರಳತನ',
'ಸರಳದಾರು',
'ಸರಳಬಡ್ಡಿ',
'ಸರಳರೇಖೆ',
'ಸರಳೆಸುಗೆ',
'ಸರಾಫಗಟ್ಟೆ',
'ಸರಾಫುಕಟ್ಟೆ',
'ಸರಾಯಿಗಾರ',
'ಸರಿಕತನ',
'ಸರಿಗಟ್ಟಿಸು',
'ಸರಿಗೆದೆಗೆ',
'ಸರಿಗೆಪಂಚೆ',
'ಸರಿಗೆಳತಿ',
'ಸರಿಗೊಳಿಸು',
'ಸರಿತುಪತಿ',
'ಸರಿತೂಗಿಸು',
'ಸರಿದೂಗಿಸು',
'ಸರಿದೊರೆಯ',
'ಸರಿಪಡಿಸು',
'ಸರಿಬಿತ್ತರಿ',
'ಸರಿಮಿಗಿಲ್',
'ಸರಿಮಿಗಿಲು',
'ಸರಿಯಿದಿರು',
'ಸರಿವರಯ',
'ಸರಿವರೆಯ',
'ಸರಿಸಂಗಾತಿ',
'ಸರಿಸಂಗೊಳ್',
'ಸರಿಸಂಬರಿ',
'ಸರಿಸಮಾನ',
'ಸರಿಹರೆಯ',
'ಸರಿಹಸುಗೆ',
'ಸರಿಹುದುಗು',
'ಸರಿಹೋಲಿಸು',
'ಸರೀಕತನ',
'ಸರುವುಗಟ್ಟು',
'ಸರೋಜಮಿತ್ರ',
'ಸರೋಜಾಂಬಕ',
'ಸರೋಜಾಕರ',
'ಸರೋಜಾಸನ',
'ಸರೋಷಮತಿ',
'ಸರ್ಜಿಕಾಕ್ಷಾರ',
'ಸರ್ಟಿಫಿಕೇಟು',
'ಸರ್ದೇಶಮುಖಿ',
'ಸರ್ಪಕಂಕಣ',
'ಸರ್ಪಕಾವಲು',
'ಸರ್ಪಗಮನ',
'ಸರ್ಪಗಾವಲು',
'ಸರ್ಪತುಂಡಿಕ',
'ಸರ್ಪನಕ್ಷತ್ರ',
'ಸರ್ಪಭೂಷಣ',
'ಸರ್ಪಮತ್ಸರ',
'ಸರ್ಪಸೂರಣ',
'ಸರ್ಪೋಪಜೀವಿ',
'ಸರ್ಬನಮಸ್ಯ',
'ಸರ್ವಕರ್ತೃತ್ವ',
'ಸರ್ವಗವತೆ',
'ಸರ್ವಗ್ರಹಣಿ',
'ಸರ್ವಜ್ಞಪೀಠ',
'ಸರ್ವತಃಶ್ರೋತ್ರ',
'ಸರ್ವತಶ್ಚಕ್ಷು',
'ಸರ್ವತೋಭದ್ರ',
'ಸರ್ವತೋಭದ್ರೆ',
'ಸರ್ವತೋಮುಖ',
'ಸರ್ವತ್ರಗಾಮಿ',
'ಸರ್ವದಮನ',
'ಸರ್ವನಮಸ್ಯ',
'ಸರ್ವನಿರ್ವಾಣಿ',
'ಸರ್ವನಿವಾಸಿ',
'ಸರ್ವಮಂಗಲೆ',
'ಸರ್ವಮಂಗಳೆ',
'ಸರ್ವವ್ಯಾಪಕ',
'ಸರ್ವಸಮ್ಮತ',
'ಸರ್ವಸಮ್ಮತಿ',
'ಸರ್ವಸಾಧನಿ',
'ಸರ್ವಸಾಮಾನ್ಯ',
'ಸರ್ವಸ್ವತಂತ್ರ',
'ಸರ್ವಾಂಗಯಜ್ಞ',
'ಸರ್ವಾಂಗರೋಮ',
'ಸರ್ವಾಂಗಾಸನ',
'ಸರ್ವಾಂತರ್ಯಾಮಿ',
'ಸರ್ವಾತಿಸಾರ',
'ಸರ್ವಾಧಿಕಾರ',
'ಸರ್ವಾಧಿಕಾರಿ',
'ಸರ್ವಾನುಮತ',
'ಸರ್ವಾನುಮತಿ',
'ಸರ್ವಾಭ್ಯಂತರ',
'ಸರ್ವಾರ್ಥಸಿದ್ಧ',
'ಸರ್ವಾರ್ಥಸಿದ್ಧಿ',
'ಸರ್ವೇಸಾಮಾನ್ಯ',
'ಸಲಂಡುಗೊಳ್',
'ಸಲಕರಣೆ',
'ಸಲಹಾಕಾರ',
'ಸಲಹೆಗಾರ',
'ಸಲಾದಿಸೊಪ್ಪು',
'ಸಲಿಂಗಕಾಮ',
'ಸಲಿಂಗಕಾಮಿ',
'ಸಲಿಂಗರತಿ',
'ಸಲಿಗೆವಂತ',
'ಸಲಿಗೆವಾಳ',
'ಸಲಿಲಕ್ರೀಡೆ',
'ಸಲಿಲಖಾತಿ',
'ಸಲಿಲಪೂರ',
'ಸಲಿಲಬಾಣ',
'ಸಲಿಲವಾಸ',
'ಸಲಿಲವ್ಯಾಳ',
'ಸಲಿಲಸ್ತಂಭ',
'ಸಲಿಲಾಂಜಲಿ',
'ಸಲಿಲಾಂಜುಳಿ',
'ಸಲಿಲಾಶಯ',
'ಸಲುಗೆಯಾಳ್',
'ಸಲುಗೆವಂತ',
'ಸಲುಗೆವಡೆ',
'ಸಲುಗೆವಾಳ',
'ಸಲುವಳಿಕೆ',
'ಸಲ್ಗೆವೆರಸು',
'ಸಲ್ಲಲಿತಾಂಗ',
'ಸಲ್ಲಲಿತಾಂಗಿ',
'ಸಲ್ಲಿಕೆಚೀಟಿ',
'ಸವಂಗಂಬುಗು',
'ಸವಕರಿಸು',
'ಸವಡಿಗಟ್ಟು',
'ಸವಡಿನುಡಿ',
'ಸವಡುಮರ',
'ಸವಡುಮ್ಮತ್ತ',
'ಸವಡುರೊಟ್ಟಿ',
'ಸವನಕರ್ತು',
'ಸವನೋತ್ಸವ',
'ಸವರ್ಣಸ್ವರ',
'ಸವವಯಸ',
'ಸವಹರೆಯ',
'ಸವಳುನೀರು',
'ಸವಾರಿಗಾಡಿ',
'ಸವಾರಿಲಾಖೆ',
'ಸವಾಲುದಾರ',
'ಸವಾಲುಪದ',
'ಸವಿಕಲ್ಪಕ',
'ಸವಿಗಡಲ್',
'ಸವಿಗಡಲು',
'ಸವಿಗನಸು',
'ಸವಿಗಾಣಿಸು',
'ಸವಿಗಾರಿಕೆ',
'ಸವಿಗುಂಬಳ',
'ಸವಿಗೊರಳು',
'ಸವಿಗೊಳಿಸು',
'ಸವಿಗೋಲ್ವಿಲ್',
'ಸವಿನಯಸ್ಥ',
'ಸವಿನೆನಪು',
'ಸವಿನೆನಹು',
'ಸವಿಮುನಿಸು',
'ಸವಿವಕ್ಷಿತ',
'ಸವುಡುಮ್ಮತ್ತ',
'ಸವುಳುನೀರು',
'ಸವುಳುನೆಲ',
'ಸವುಳುಭೂಮಿ',
'ಸವುಳುಮಣ್ಣು',
'ಸವುಳುಮಾಳೆ',
'ಸವ್ಯಸಾಚಿಸು',
'ಸವ್ಯಾಪಸವ್ಯ',
'ಸವ್ವಳಿವಿಡಿ',
'ಸಸಿಗದಿರ್',
'ಸಸಿದಲೆಯ',
'ಸಸಿನಂಗೆಯ್',
'ಸಸಿನಗೆಯ್',
'ಸಸಿನಿರಿಸು',
'ಸಸಿನೆಗೆಯ್',
'ಸಸಿನೆವರಿ',
'ಸಸಿನೆಳಲು',
'ಸಸಿಯೊಡಲ್',
'ಸಸಿವೆಳಗು',
'ಸಸಿಸೇಖರ',
'ಸಸ್ಫುಟವೃತ್ತಿ',
'ಸಸ್ಯಮಂಜರಿ',
'ಸಸ್ಯಮೂಲಿಕೆ',
'ಸಸ್ಯವಿಜ್ಞಾನ',
'ಸಸ್ಯವಿಜ್ಞಾನಿ',
'ಸಸ್ಯಶಾಸ್ತ್ರಜ್ಞ',
'ಸಸ್ಯಶಾಸ್ತ್ರಜ್ಞೆ',
'ಸಸ್ಯಸಂವರ',
'ಸಹಕರಿಸು',
'ಸಹಗಮನ',
'ಸಹಗಮಿಸು',
'ಸಹಜೀವನ',
'ಸಹಜೀವಿತೆ',
'ಸಹಧರ್ಮಿಣಿ',
'ಸಹನಶಕ್ತಿ',
'ಸಹನಶೀಲ',
'ಸಹನಶೀಲೆ',
'ಸಹನಾತೀತ',
'ಸಹಭಾಗಿತ್ವ',
'ಸಹಭೋಗಿನಿ',
'ಸಹಭೋಜನ',
'ಸಹಶಿಕ್ಷಣ',
'ಸಹಸಂಭವ',
'ಸಹಸಂಭವೆ',
'ಸಹಸಂಭೂತ',
'ಸಹಸಂಭೂತೆ',
'ಸಹಸಿತನ',
'ಸಹಸ್ರಕರ',
'ಸಹಸ್ರಗುಣ',
'ಸಹಸ್ರ್ರದಂಷ್ಟ್ರ',
'ಸಹಸ್ರದಲ',
'ಸಹಸ್ರದಳ',
'ಸಹಸ್ರದೃಕ್ಕು',
'ಸಹಸ್ರನಾಮ',
'ಸಹಸ್ರಪತ್ರ',
'ಸಹಸ್ರಪದಿ',
'ಸಹಸ್ರಪಾದ',
'ಸಹಸ್ರಫಣ',
'ಸಹಸ್ರಮಡಿ',
'ಸಹಸ್ರವೀರ್ಯೆ',
'ಸಹಸ್ರವೇಧಿ',
'ಸಹಸ್ರಶೀರ್ಷ',
'ಸಹಸ್ರಾಗಮ',
'ಸಹಹೇತುಕ',
'ಸಹಾನುಭೂತಿ',
'ಸಹಾಯದ್ರವ್ಯ',
'ಸಹಾಯಧನ',
'ಸಹೃದಯತೆ',
'ಸಳಮಳನೆ',
'ಸಳಸಳನೆ',
'ಸಳಿಗದಿರ',
'ಸಳಿಯಟ್ಟುಗ',
'ಸಱಸಱನೆ',
'ಸಱಸಱಿಕೆ',
'ಸಱಸಱಿಸು',
'ಸಱಿದೊಡಕು',
'ಸಱಿಹೃದಯ',
'ಸಱುಸೈತನೆ',
'ಸಾಂಖ್ಯದರ್ಶನ',
'ಸಾಂಗಗೊಳಿಸು',
'ಸಾಂತ್ವನಗುಟ್ಟು',
'ಸಾಂತ್ವವಚನ',
'ಸಾಂದ್ರಾಣಿಕಲ್ಲು',
'ಸಾಂಪರಾಯಿಕ',
'ಸಾಂಪ್ರದಾಯಿಕ',
'ಸಾಂಬ್ರಾಜ್ಯಂಗೆಯ್',
'ಸಾಂಬ್ರಾಣಿತೇಜಿ',
'ಸಾಂಬ್ರಾಣಿತೈಲ',
'ಸಾಂಬ್ರಾಣಿಧೂಪ',
'ಸಾಂಬ್ರಾಣಿಮರ',
'ಸಾಂವತ್ಸರಿಕ',
'ಸಾಕಲ್ಯಸಂಜ್ಞೆ',
'ಸಾಕಾರಮುಕ್ತ',
'ಸಾಕಾರಮುಕ್ತೆ',
'ಸಾಕುಮಗಳು',
'ಸಾಕ್ಷಾತ್ಕರಿಸು',
'ಸಾಕ್ಷಿಗೊಳಿಸು',
'ಸಾಗರದಾನ',
'ಸಾಗರನಾಥ',
'ಸಾಗರಬೀಳು',
'ಸಾಗರವರಿ',
'ಸಾಗರಶಾಯಿ',
'ಸಾಗರೋತ್ತರ',
'ಸಾಗರೋಪಮ',
'ಸಾಗಾರಧರ್ಮ',
'ಸಾಗಾರವ್ರತ',
'ಸಾಟಿವ್ಯಾಪಾರ',
'ಸಾಟೆವ್ಯಾಪಾರ',
'ಸಾಣೆಖಂಡೆಯ',
'ಸಾಣೆಗಾಣಿಸು',
'ಸಾಣೆಹಿಡಿಸು',
'ಸಾತಿಶಯೋಕ್ತಿ',
'ಸಾತ್ತ್ವಿಕಭಾವ',
'ಸಾತ್ಯವತೇಯ',
'ಸಾತ್ವಿಕಭಾವ',
'ಸಾದದಂಡಿಗೆ',
'ಸಾದಾಖ್ಯತತ್ವ',
'ಸಾಧನಂಗುಡು',
'ಸಾಧನಗಾತಿ',
'ಸಾಧನಸಿದ್ಧಿ',
'ಸಾಧನೆಗೆಯ್ಯು',
'ಸಾಧನೆವೋಗು',
'ಸಾಧಿತಧಾತು',
'ಸಾಧಿತಶಬ್ದ',
'ಸಾಧುಪುರುಷ',
'ಸಾಧ್ವಸಭಯ',
'ಸಾನುನಾಸಿಕ',
'ಸಾನುಪ್ರದೇಶ',
'ಸಾಪೇಕ್ಷವಾದ',
'ಸಾಪ್ತಪದೀನ',
'ಸಾಮಯಿಕತೆ',
'ಸಾಮರ್ಥ್ಯ ಪಣ',
'ಸಾಮವರಾಲಿ',
'ಸಾಮವರಾಳಿ',
'ಸಾಮಾಜಿಕತೆ',
'ಸಾಮಾನ್ಯಗತಿ',
'ಸಾಮಾನ್ಯಜನ',
'ಸಾಮಾನ್ಯಧರ್ಮ',
'ಸಾಮಾನ್ಯಶೈವ',
'ಸಾಮಾನ್ಯಾರ್ಥಕ',
'ಸಾಮೀಪ್ಯಂಬಡೆ',
'ಸಾಮೀಪ್ಯಪದ',
'ಸಾಮುಗಲಿಸು',
'ಸಾಮುದಾಯಿಕ',
'ಸಾಮುದ್ರಿಕಜ್ಞ',
'ಸಾಮೋಪಚಾರ',
'ಸಾಮ್ರಾಜ್ಯಶಾಹಿ',
'ಸಾಮ್ರಾಜ್ಯಷಾಹಿ',
'ಸಾಮ್ರಾಣಿಧೂಪ',
'ಸಾಯರಬಾಬು',
'ಸಾಯರುಬಾಬು',
'ಸಾಯಸಂಗೊಳ್',
'ಸಾಯಸಗೊಳ್',
'ಸಾಯಸಗೊಳ್ಳು',
'ಸಾಯಸವಡು',
'ಸಾಯಜ್ಯಪದ',
'ಸಾರಂಗಕಾಪಿ',
'ಸಾರಂಗಜಟಿ',
'ಸಾರಂಗಧರ',
'ಸಾರಂಗನಾಟ',
'ಸಾರಂಗಪದ',
'ಸಾರಂಗಪದ್ಮ',
'ಸಾರಂಗಪಾಣಿ',
'ಸಾರಂಗಮಣಿ',
'ಸಾರಂಗಮೌಲಿ',
'ಸಾರಂಗವೀಣೆ',
'ಸಾರಜನಕ',
'ಸಾರಣಂಗೆಯ್',
'ಸಾರಣವೆಣ್',
'ಸಾರಣೆಗರೆ',
'ಸಾರಣೆಗೆಯ್',
'ಸಾರಣೆದೆಗೆ',
'ಸಾರತಿಗೆಯ್ಮೆ',
'ಸಾರಥಿತನ',
'ಸಾರಧಾತುಜ್ಞ',
'ಸಾರಸಂಗ್ರಹ',
'ಸಾರಸಂಗ್ರಹಿ',
'ಸಾರಸರ್ವಸ್ವ',
'ಸಾರಸವಕ್ಕಿ',
'ಸಾರಸೋದ್ಭವ',
'ಸಾರಹೃದಯ',
'ಸಾರಹೃದಯೆ',
'ಸಾರಾಯಪದ',
'ಸಾರಾಯಮತಿ',
'ಸಾರಾಯಿಬೇಲ',
'ಸಾರಾಲಂಕಾರ',
'ಸಾರಿಗೆದೇರ',
'ಸಾರಿಗೆಸಂಸ್ಥೆ',
'ಸಾರಿಗೊಳಿಸು',
'ಸಾರುವಭೌಮ',
'ಸಾರೂಪ್ಯಪದ',
'ಸಾರ್ಥವಾಹಕ',
'ಸಾರ್ಥಾಧಿಪತಿ',
'ಸಾದ್ರ್ರಹೃದಯ',
'ಸಾದ್ರ್ರಹೃದಯೆ',
'ಸಾದ್ರ್ರಾಂತರಂಗ',
'ಸಾರ್ವಕಾಲಿಕ',
'ಸಾರ್ವಜನಿಕ',
'ಸಾರ್ವತ್ರಿಕತೆ',
'ಸಾರ್ವದೇಶಿಕ',
'ಸಾರ್ವಭೌಮಕ',
'ಸಾರ್ವಭೌಮತೆ',
'ಸಾರ್ವಭೌಮಿಕ',
'ಸಾರ್ವಭೌಮಿಕೆ',
'ಸಾರ್ವಲೌಕಿಕ',
'ಸಾಲ್‍ಬಸಾಲ್',
'ಸಾಲಗಾರಿಕೆ',
'ಸಾಲಗುಳಿಕ',
'ಸಾಲಬಂದಿಕೆ',
'ಸಾಲಬಡಕ',
'ಸಾಲಭಂಜಿಕೆ',
'ಸಾಲವಣಿಗ',
'ಸಾಲಾಮಿಸಿರಿ',
'ಸಾಲಿಪೈಠಣೆ',
'ಸಾಲುಗುಜಸ್ತಾ',
'ಸಾಲುಗುದಸ್ತಾ',
'ಸಾಲುಗೊಳಿಸು',
'ಸಾಲುಮಂಟಿಗೆ',
'ಸಾಲೋಕ್ಯಪದ',
'ಸಾವಕಾರಿಕೆ',
'ಸಾವದ್ಯಭೀರು',
'ಸಾವಧಗಿರಿ',
'ಸಾವಿತ್ರೀವ್ರತ',
'ಸಾವಿತ್ರೀಸೂತ್ರ',
'ಸಾವೆಬಿದಿರು',
'ಸಾಸನಂಗೊಡು',
'ಸಾಸಿರಕಣ್ಣ',
'ಸಾಸಿರಗಣ್ಣ',
'ಸಾಸಿರಗೆಯ್ಯ',
'ಸಾಸಿರ್ದಲೆಯ',
'ಸಾಸಿರ್ಮಡಿಸು',
'ಸಾಸಿವೆಹಾಲು',
'ಸಾಹಸಮಲ್ಲ',
'ಸಾಹಸವಂತ',
'ಸಾಹಸಿತನ',
'ಸಾಹಿತ್ಯಕಾರ',
'ಸಾಹಿತ್ಯಕ್ಷೇತ್ರ',
'ಸಾಹಿತ್ಯಜ್ಞಾನ',
'ಸಾಹಿತ್ಯಭಾಷೆ',
'ಸಾಹಿತ್ಯರಂಗ',
'ಸಾಹುಕಾರಿಕೆ',
'ಸಾಹೇಬಗಿರಿ',
'ಸಿಂಗಕೇಸರಿ',
'ಸಿಂಗಗಟ್ಟಿಗೆ',
'ಸಿಂಗರಂಗೆಯ್',
'ಸಿಂಗರಂಬೆಱು',
'ಸಿಂಗರಗೆಯ್',
'ಸಿಂಗರಗೊಳ್',
'ಸಿಂಗರಗೊಳು',
'ಸಿಂಗರಗೊಳ್ಳು',
'ಸಿಂಗರಜೋಗಿ',
'ಸಿಂಗರಜೋಯಿ',
'ಸಿಂಗರವಡು',
'ಸಿಂಗರವಡೆ',
'ಸಿಂಗರವಾನ್',
'ಸಿಂಗವಟ್ಟಿಗೆ',
'ಸಿಂಗವಡ್ಡಿಗೆ',
'ಸಿಂಗವಿಟ್ಟರ',
'ಸಿಂಗಾಡಶಂಭ',
'ಸಿಂಗಾಡಿಗಟ್ಟು',
'ಸಿಂಗಾರಕಾಱ',
'ಸಿಂಗಾರಗಿಡ',
'ಸಿಂಗಾರಬಳ್ಳಿ',
'ಸಿಂಗಾರಬೀಜ',
'ಸಿಂಗಾರವಡೆ',
'ಸಿಂಗಿಗೊರಳ',
'ಸಿಂಘವಿಷ್ಟರ',
'ಸಿಂಜಾನಮಣಿ',
'ಸಿಂಜಿನಿಗೆಯ್',
'ಸಿಂಡುವಾಸನೆ',
'ಸಿಂದುರಂಬೊರೆ',
'ಸಿಂಧುಲವಣ',
'ಸಿಂಧುವರಾಳಿ',
'ಸಿಂಧುಶಯನ',
'ಸಿಂಪಿಗಹಕ್ಕಿ',
'ಸಿಂಪಿಣಿಯಾಡು',
'ಸಿಂಪಿನೇರಳೆ',
'ಸಿಂಹಕಡಗ',
'ಸಿಂಹಕ್ರೀಡಿತ',
'ಸಿಂಹಗರ್ಜನೆ',
'ಸಿಂಹಘಂಟಿಕೆ',
'ಸಿಂಹತಾಳತ',
'ಸಿಂಹನಂದನ',
'ಸಿಂಹಲಲಾಟ',
'ಸಿಂಹವಾಹನೆ',
'ಸಿಂಹವಾಹಿನಿ',
'ಸಿಂಹವಿಕ್ರಮ',
'ಸಿಂಹವಿಷ್ಟರ',
'ಸಿಂಹಾಸನಸ್ಥ',
'ಸಿಂಹಾಸನಸ್ಥೆ',
'ಸಿಂಹಿಕಾತ್ಮಜ',
'ಸಿಕಲಗಾರ',
'ಸಿಕಲಿಗಾರ',
'ಸಿಕಲಿಗೆಯ್',
'ಸಿಕ್ಕಲಗಾರ',
'ಸಿಕ್ಕೆಮೋರ್ತಬ',
'ಸಿಕ್ಕೆಯುಂಗುರ',
'ಸಿಖರದೀಪ್ತಿ',
'ಸಿಖಿಮಂಥಣಿ',
'ಸಿಗ್ಗುಗೆಡಿಸು',
'ಸಿಡಿಗುಲಿಗೆ',
'ಸಿಡಿಮಜ್ಜಿಗೆ',
'ಸಿಡಿಲಿಂಗಳ',
'ಸಿಡಿಲುಮರಿ',
'ಸಿಡಿಲುವಲೆ',
'ಸಿಡಿಲುಹೊಡೆ',
'ಸಿಡಿಲೆರಗು',
'ಸಿಡಿಲೆಱಗು',
'ಸಿಡಿಲೇಳಿಗೆ',
'ಸಿಡುಕುತನ',
'ಸಿಡುಕುಮುಖ',
'ಸಿಡುಕುಮೋರೆ',
'ಸಿಡುಕುವಲೆ',
'ಸಿಡುಬುಡಾಕು',
'ಸಿತಗತನ',
'ಸಿತಚಂದನ',
'ಸಿತತುರಗ',
'ಸಿತದೀಧಿತಿ',
'ಸಿತಪ್ರಮೇಹ',
'ಸಿತವಾಹನ',
'ಸಿತಸರ್ಷಪ',
'ಸಿದ್ಧಉಡುಪು',
'ಸಿದ್ಧಗೊಳಿಸು',
'ಸಿದ್ಧನಿಲಯ',
'ಸಿದ್ಧಪಡಿಸು',
'ಸಿದ್ಧಪುರುಷ',
'ಸಿದ್ಧಪ್ರಯೋಗ',
'ಸಿದ್ಧಪ್ರಸಾದ',
'ಸಿದ್ಧಪ್ರಸಾದಿ',
'ಸಿದ್ಧಮಾತ್ರಿಕ',
'ಸಿದ್ಧಮೂಲಿಕೆ',
'ಸಿದ್ಧವಟಕ',
'ಸಿದ್ಧಸಹಣಿ',
'ಸಿದ್ಧಾಯತನ',
'ಸಿದ್ಧಾರ್ಥವೃಕ್ಷ',
'ಸಿನಿಕತನ',
'ಸಿನಿಮಾತಾರೆ',
'ಸಿನಿಮೀಯತೆ',
'ಸಿಪಾಯಿಗಿರಿ',
'ಸಿಪಾಯಿತನ',
'ಸಿಪಾಸಾಲಾರ',
'ಸಿಬ್ಬದಿಗೊಳು',
'ಸಿಬ್ಬಿದಿಗೊಳ್',
'ಸಿಬ್ಬುದಿಗೊಳು',
'ಸಿಮಿಸಿಮಿತ',
'ಸಿಮಿಸಿಮಿಸು',
'ಸಿರಣವಟ್ಟಿ',
'ಸಿರಸ್ತೇದಾರ',
'ಸಿರಿಗೇಡುಗ',
'ಸಿರಿಗೊರಳು',
'ಸಿರಿತುಳಸಿ',
'ಸಿರಿಬಿಡಿತ್ತ',
'ಸಿರಿಯದರ',
'ಸಿರಿಯದಾರ',
'ಸಿರಿಯಬಾರ',
'ಸಿರಿಯರಸ',
'ಸಿರಿಯರಸಿ',
'ಸಿರಿಯೊಡೆಯ',
'ಸಿರಿವಂತಿಕೆ',
'ಸಿರಿವಂತಿಗೆ',
'ಸಿರಿವದನ',
'ಸಿರಿವಾಗಿಲ್',
'ಸಿರಿಹೆಟ್ಟಿಗೆ',
'ಸಿರ್ಕುಂಗೊಳಿಸು',
'ಸಿಲ್ಕುಗುದುರೆ',
'ಸಿಲ್ಪಿನೀತೃಣ',
'ಸಿವಡುಕಟ್ಟು',
'ಸಿವಡುಗಟ್ಟು',
'ಸಿವುಡುಕಟ್ಟು',
'ಸಿವುಡುಗಟ್ಟು',
'ಸಿಸ್ತುಗಾರಿಕೆ',
'ಸಿಹಿಕರಣೆ',
'ಸಿಹಿಗುಂಬಳ',
'ಸಿಹಿಗೆಣಸು',
'ಸಿಹಿಮೊಸರು',
'ಸಿಹಿಲವಂಗ',
'ಸಿಹಿಸೊಡರಿ',
'ಸಿಱುಂಬಳಾಡು',
'ಸಿಱುಂಬುಲಾಡು',
'ಸಿಱುಂಬುಳಾಡು',
'ಸೀಕರಿಗಳೆ',
'ಸೀಕರಿಗೊಳ್',
'ಸೀಕರಿಗೊಳು',
'ಸೀಕರಿಯೋಗು',
'ಸೀಕರಿವೋಗು',
'ಸೀಗಡಿಸತ್ತೆ',
'ಸೀಗುರಿಕಾಱ',
'ಸೀಗುರಿಯಿಕ್ಕು',
'ಸೀಗೆಹುಣ್ಣಿವೆ',
'ಸೀಡಕತನ',
'ಸೀತಱುದಾಳು',
'ಸೀತಾಪೇರಲ',
'ಸೀತುಱುಗೊಳ್',
'ಸೀತೆಚವರಿ',
'ಸೀದಾಸಾಮಗ್ರಿ',
'ಸೀಪಿಯಗೆಡ್ಡೆ',
'ಸೀಬಿಯಗೆಡ್ಡೆ',
'ಸೀಮಂತಪುತ್ರ',
'ಸೀಮಂತಪುತ್ರಿ',
'ಸೀಮಂತಮಣಿ',
'ಸೀಮಂತರತ್ನ',
'ಸೀಮಂತರೇಖೆ',
'ಸೀಮಾಪುರುಷ',
'ಸೀಮಾಲಂಘನ',
'ಸೀಮೆಅಗಸೆ',
'ಸೀಮೆಅವರೆ',
'ಸೀಮೆಈರುಳ್ಳಿ',
'ಸೀಮೆಕಚೋರ',
'ಸೀಮೆಕತ್ತಾಳೆ',
'ಸೀಮೆಕಮಲ',
'ಸೀಮೆಗೆಡಿಸು',
'ಸೀಮೆಗೊಳಿಸು',
'ಸೀಮೆಗೋರಂಟಿ',
'ಸೀಮೆತಂಗಡಿ',
'ಸೀಮೆನೇಗಿಲು',
'ಸೀಮೆನೌಲಾದಿ',
'ಸೀಮೆಬದನೆ',
'ಸೀಮೆಯಗಸೆ',
'ಸೀಮೆರಬ್ಬರ್',
'ಸೀಮೆಸದಾಪು',
'ಸೀಮೆಹರಳು',
'ಸೀಮೆಹಲಸು',
'ಸೀಮೆಹಾಳಿಂಬೆ (?)',
'ಸೀಮೆಹುಣಸೆ',
'ಸೀಮೋಲ್ಲಂಘನ',
'ಸೀರುಂಬುಳಾಡು',
'ಸೀರೆಉಡುಕೆ',
'ಸೀವಳಿವಿಲ್ಲು',
'ಸೀಸಕಂದಾಳ್',
'ಸೀಸಪತ್ರಕ',
'ಸೀಸವಟಕ',
'ಸೀಳುನಾಲಿಗೆ',
'ಸೀಱುಂಬುಳಾಡು',
'ಸುಂಕವಗ್ಗಡೆ',
'ಸುಂಕವೆರ್ಗಡೆ',
'ಸುಂಕಾಧಿಕಾರಿ',
'ಸುಂಟಗೆಗೋಲ್',
'ಸುಂಟಗೆಗೋಲು',
'ಸುಂಟರಗಾಳಿ',
'ಸುಂಟರುಗಾಳಿ',
'ಸುಂಟಿಗೆಗಳೆ',
'ಸುಂಟಿಗೆಗೋಲು',
'ಸುಂಟುರುಗಾಳಿ',
'ಸುಂದರರೂಪ',
'ಸುಂದರವೃತ್ತ',
'ಸುಂದರವೇಷ',
'ಸುಂಬಳಕಾರ',
'ಸುಕಾಸುಮ್ಮನೆ',
'ಸುಕುಮಾರಕ',
'ಸುಕುಮಾರತೆ',
'ಸುಕುಮಾರಿಕೆ',
'ಸುಕೃತಂಬೆಱು',
'ಸುಕ್ಕುಂಗುರುಳು',
'ಸುಕ್ಕುಗುರುಳು',
'ಸುಖಕರತೆ',
'ಸುಖಕಾರಿಣಿ',
'ಸುಖದಾಯಿನಿ',
'ಸುಖಪಡಿಸು',
'ಸುಖಪ್ರವೃತ್ತಿ',
'ಸುಖಪ್ರಸವ',
'ಸುಖಪ್ರಸೂತಿ',
'ಸುಖಬಡಿಸು',
'ಸುಖಮುಱುಕ',
'ಸುಖಲೋಲಾಪ್ತಿ',
'ಸುಖಲೋಲುಪ',
'ಸುಖಲೋಲುಪೆ',
'ಸುಖವಡಿಸು',
'ಸುಖವರ್ಚಕ',
'ಸುಖವಿರೇಕ',
'ಸುಖಸಂಪನ್ನ',
'ಸುಖಸಂಪನ್ನೆ',
'ಸುಖಾಗಮನ',
'ಸುಖಾಸುಮ್ಮನೆ',
'ಸುಖಿಯನುಂಡೆ',
'ಸುಗಂಧತೈಲ',
'ಸುಗಂಧದ್ರವ್ಯ',
'ಸುಗಂಧಪಾಲ',
'ಸುಗಂಧಬಳ್ಳಿ',
'ಸುಗಂಧಬೇರು',
'ಸುಗಂಧಮುಸ್ತೆ',
'ಸುಗಂಧರಾಜ',
'ಸುಗಂಧವಾಚಿ',
'ಸುಗಂಧವಾಳೆ',
'ಸುಗಂಧಿಪಾಲ',
'ಸುಗತಮತ',
'ಸುಗತಿವಡೆ',
'ಸುಗುಂಟುತನ',
'ಸುಗುಡುತನ',
'ಸುಗುಣಾಪೋಹ',
'ಸುಗ್ಗಿಗೆಳೆಯ',
'ಸುಗ್ಗಿದಾಸೋಹ',
'ಸುಚರಿತ್ರತೆ',
'ಸುಟ್ಟರಗಾಳಿ',
'ಸುಟ್ಟುಂಬೆರಲ್',
'ಸುಟ್ಟುಬೆರಳು',
'ಸುಟ್ಟುರೆಗಾಳಿ',
'ಸುಟ್ಟುರೆವಿಡು',
'ಸುಟ್ಟುವೆರಲ್',
'ಸುಡಗೆಣಸು',
'ಸುಡುಗಾಡಿಕ್ಕೆ',
'ಸುಡುಗೆಣಸು',
'ಸುಡುಬಿಸಿಲು',
'ಸುಡುವಿಸಿಲ್',
'ಸುಡುಸುಡನೆ',
'ಸುಣ್ಣಗೆಲಸ',
'ಸುಣ್ಣವರಲ್',
'ಸುತ್ತಮುತ್ತಲು',
'ಸುತ್ತಿಗುತ್ತಿಗೆ',
'ಸುತ್ತುಂಬರಿಸು',
'ಸುತ್ತುಕೆಲಸ',
'ಸುತ್ತುಗೆಲಸ',
'ಸುತ್ತುತಾನಕ',
'ಸುತ್ತುಬಳಸು',
'ಸುತ್ತುಮುತ್ತಲು',
'ಸುತ್ತುವಲಗೆ',
'ಸುತ್ತುವಳಯ',
'ಸುತ್ತುವಳಸು',
'ಸುತ್ತುವೀಳೆಯ',
'ಸುತ್ತುಹಾಕಿಸು',
'ಸುತ್ರಗುತ್ತಿಗೆ',
'ಸುತ್ರೆಗುತ್ತಗೆ',
'ಸುದರುಶನ',
'ಸುದ್ದಗನ್ನಡ',
'ಸುದ್ದಿವಾಹಕ',
'ಸುಧಾಂಶುಬಿಂಬ',
'ಸುಧಾಂಶುಲೇಖೆ',
'ಸುನಫಾಯೋಗ',
'ಸುನಾರಖಾನೆ',
'ಸುನಿಷಣ್ಣಕ',
'ಸುಪ್ತಚೇತನ',
'ಸುಪ್ಪಾಣಿಮಣಿ',
'ಸುಪ್ಪಾಣಿಮುತ್ತು',
'ಸುಪ್ರತಿಷ್ಠಕ',
'ಸುಪ್ರತಿಷ್ಠಿತ',
'ಸುಪ್ರತಿಷ್ಠಿತೆ',
'ಸುಪ್ರವ್ಯಾಜರ',
'ಸುಪ್ರಸನ್ನತೆ',
'ಸುಬಂತಪದ',
'ಸುಬಟ್ಟಕ್ಕರ',
'ಸುಬುದ್ಧಿಗೆಡು',
'ಸುಬೇದಾರಿಕೆ',
'ಸುಬ್ಬನಸೂರೆ',
'ಸುಬ್ಬರಸೂಱೆ',
'ಸುಬ್ಬಾನಸೂರೆ',
'ಸುಬ್ಬುರವಡೆ',
'ಸುಬ್ಬುರುವಾಲ',
'ಸುಭಟವೃತ್ತಿ',
'ಸುಭಟಾಯತ',
'ಸುಭಿಕ್ಷಶೂಲೆ',
'ಸುಮನಸಾರ',
'ಸುಮನೋಬಾಣ',
'ಸುಮನೋಹರ',
'ಸುಮಾನಗಾತಿ',
'ಸುಮಾನುಕೊಡು',
'ಸುಮುಖವೃತ್ತಿ',
'ಸುಮ್ಮಾನಂಗುಂದು',
'ಸುಮ್ಮಾನಗಾತಿ',
'ಸುಮ್ಮಾನಗೊಳ್ಳು',
'ಸುಮ್ಮಾನವಳಿ',
'ಸುಯ್ಯಾಣವೆಸ',
'ಸುರಂಗಮಾರ್ಗ',
'ಸುರಕ್ಷಿತತೆ',
'ಸುರಗಿಕಾಱ',
'ಸುರತಕ್ರೀಡೆ',
'ಸುರತರಸ',
'ಸುರತಿಚೆಕ್ಕೆ',
'ಸುರಪದವಿ',
'ಸುರಪರ್ಣಿಕೆ',
'ಸುರಪುನ್ನಿಕೆ',
'ಸುರಭಿತನ',
'ಸುರಮಹೀಜ',
'ಸುರವರ್ಧಕಿ',
'ಸುರವಲ್ಲರಿ',
'ಸುರವಾರುಣಿ',
'ಸುರಸುರಕೆ',
'ಸುರಸುರನೆ',
'ಸುರಸುರಿಕೆ',
'ಸುರಿಗೆಕಾರ',
'ಸುರುಗುಜಾಡ್ಯ',
'ಸುರುಗುಮಾನ್ಯ',
'ಸುರುಚಿರಾಂಗ',
'ಸುರುಸುರಿಕೆ',
'ಸುರುಸುರಿಗೆ',
'ಸುರುಸುರುಕೆ',
'ಸುರುಳಿಸುತ್ತು',
'ಸುರುಳ್ಗೊಳಿಸು',
'ಸುರೂಪವೃತ್ತಿ',
'ಸುರೆಗುಡಿಹಿ',
'ಸುರೆಗುಡುಹಿ',
'ಸುರ್ಕುಗೊಳಿಸು',
'ಸುರ್ಬುರುಗೊಳ್',
'ಸುರ್ಬುರುಬಾಲ',
'ಸುಲಭಕೋಪಿ',
'ಸುಲಭಗ್ರಾಹ್ಯ',
'ಸುಲಭವಲೆ',
'ಸುಲಭಸಾಧ್ಯ',
'ಸುಲಭೋಪಾಯ',
'ಸುಲಿಗೆಗಾರ',
'ಸುಲಿಗೆವಡೆ',
'ಸುಲಿಗೆವೆಱು',
'ಸುಲಿಹಕಾಱ',
'ಸುಲಿಹಗೊಳ್ಳು',
'ಸುಲ್ತಾನದ್ರಾಕ್ಷಿ',
'ಸುಲ್ತಾನದ್ರಾಕ್ಷೆ',
'ಸುಲ್ತಾನಿತೋಟ',
'ಸುವರ್ಣಕಾರ',
'ಸುವರ್ಣಕಾಲ',
'ಸುವರ್ಣಗಡ್ಡೆ',
'ಸುವರ್ಣಚೂರ್ಣ',
'ಸುವರ್ಣದಿನ',
'ಸುವರ್ಣಪೞಿ',
'ಸುವರ್ಣವರ್ಣ',
'ಸುವರ್ಣಶಾಖ',
'ಸುವರ್ಣಸಂಧಿ',
'ಸುವರ್ಣಾಂಬರ',
'ಸುವರ್ಣಾಂಬುಜ',
'ಸುವರ್ಣಾಕ್ಷರ',
'ಸುವರ್ಣೋತ್ಸವ',
'ಸುವಿಚಾರಿತ',
'ಸುವಿತರಣ',
'ಸುವ್ಯವಸ್ಥಿತ',
'ಸುಶಿಕ್ಷಿತತೆ',
'ಸುಷಿರವಾದ್ಯ',
'ಸುಷುಪ್ತಿಗೊಳ್',
'ಸುಷುಮ್ನನಾಳ',
'ಸುಷುಮ್ನಾನಾಡಿ',
'ಸುಷುಮ್ನಾನಾಳ',
'ಸುಸಂಬದ್ಧತೆ',
'ಸುಸಜ್ಜಿತತೆ',
'ಸುಸರತನ',
'ಸುಸಿಲೆಸಗು',
'ಸುಳಿದಿರುಗು',
'ಸುಳಿವಟ್ಟೆಗ',
'ಸುಳುವುಗೇಡಿ',
'ಸುಳುಹುದಾರಿ',
'ಸುಳುಹುದೆಗೆ',
'ಸುಳ್ಳುನಾಲಗೆ',
'ಸೂಂಟಗೆಗೊಳ್',
'ಸೂಂತಿಕಾಗಾರ',
'ಸೂಕರಕಂದ',
'ಸೂಕರಗಂಧ',
'ಸೂಕ್ಷ್ಮಗ್ರಾಹಿತ್ವ',
'ಸೂಕ್ಷ್ಮದರ್ಶಕ',
'ಸೂಕ್ಷ್ಮಪ್ರಕೃತಿ',
'ಸೂಕ್ಷ್ಮಶರೀರ',
'ಸೂಚನೆಗೆಯ್',
'ಸೂಚಿಕಾರೋಗ',
'ಸೂಚೀಕರಣ',
'ಸೂಚೀಕರ್ಮಾಯ್ತೆ',
'ಸೂಜಿಕಾಯಕ',
'ಸೂಜಿಚಿಕಿತ್ಸೆ',
'ಸೂಜಿಮಲ್ಲಿಗೆ',
'ಸೂತಿಕಸೂಲೆ',
'ಸೂತಿಕಾಗೃಹ',
'ಸೂತಿಕಾಗೇಹ',
'ಸೂತಿಕಾರೋಗ',
'ಸೂತಿಮಸಗು',
'ಸೂತ್ರಧಾರಕ',
'ಸೂತ್ರಬದ್ಧತೆ',
'ಸೂತ್ರವೇಷ್ಟನ',
'ಸೂತ್ರೀಕರಣ',
'ಸೂತ್ರೀಕರಿಸು',
'ಸೂದಕರ್ಮಜ್ಞ',
'ಸೂನಗಿಗತ್ತಿ',
'ಸೂಪಕಾರಕಿ',
'ಸೂಪಕಾರತೆ',
'ಸೂಪಧೂಪನ',
'ಸೂಬಹ್‍ದಾರ್',
'ಸೂರಕತ್ತಿಗೆ',
'ಸೂರಣಕಂದ',
'ಸೂರಣಗಡ್ಡೆ',
'ಸೂರಿಯಕಾಂತಿ',
'ಸೂರಿಯಪುಟ',
'ಸೂರುಳಿಡಿಸು',
'ಸೂರುಳುಗೆಯ್',
'ಸೂರೆಗೊಳಿಸು',
'ಸೂರ್ತಿರೂಪಾಯಿ',
'ಸೂರ್ತಿವರಹ',
'ಸೂರ್ಯಗ್ರಹಣ',
'ಸೂರ್ಯನದಂಟು',
'ಸೂರ್ಯಪ್ರಜ್ಞಪ್ತಿ',
'ಸೂರ್ಯಪ್ರತಿಮೆ',
'ಸೂರ್ಯಭೇದನ',
'ಸೂರ್ಯಮಂಡಲ',
'ಸೂರ್ಯವಿಮಾನ',
'ಸೂರ್ಯಸಿದ್ಧಾಂತ',
'ಸೂರ್ಯಾವರ್ತನ',
'ಸೂರ್ಯೋಪರಾಗ',
'ಸೂವಾರವಿದ್ಯೆ',
'ಸೂಸುಜವ್ವನ',
'ಸೂಸುಡೆಂಕಣಿ',
'ಸೂಳುಗಂಕಣ',
'ಸೂಳುಗಹಳೆ',
'ಸೂಳೆಗಾರಿಕೆ',
'ಸೂಳೆವೆಂಡತಿ',
'ಸೂೞುಲಿಗಲೆ',
'ಸೃಜನಶಕ್ತಿ',
'ಸೃಜನಶೀಲ',
'ಸೃಜನಶೀಲೆ',
'ಸೃಜನಾತ್ಮಕ',
'ಸೃಷ್ಟನೆಸಾಕ್ಷಿ',
'ಸೃಷ್ಟಿಕಾರಿತೆ',
'ಸೃಷ್ಟಿಶೀಲತೆ',
'ಸೆಂಬಳಿಗೋಲ್',
'ಸೆಂಬುಳಿಗೋಲ್',
'ಸೆಕೆಕಡುಬು',
'ಸೆಕೆಯುಸಿರ್',
'ಸೆಗಣಿನೀರು',
'ಸೆಜ್ಜಾನಿಳಯ',
'ಸೆಜ್ಜೆಗೊಳಿಸು',
'ಸೆಜ್ಜೆಯೋವರಿ',
'ಸೆಜ್ಜೆವಳಿಗೆ',
'ಸೆಜ್ಜೆವಾಳುಕ',
'ಸೆಟ್ಟಿಕಾಱಿತಿ',
'ಸೆಡಕುಗಾತಿ',
'ಸೆಡಕುಗಾರ',
'ಸೆಡಕುಗಾರ್ತಿ',
'ಸೆಣಸುಕಾಱ',
'ಸೆಣಿಯ ಹುಲ್ಲು',
'ಸೆರಗುಹಿಡಿ',
'ಸೆರವಟ್ಟಣಿ',
'ಸೆರೆಗುಡುಕ',
'ಸೆರೆಗೈಗಣ್',
'ಸೆರೆಬಡಕ',
'ಸೆರೆಬಿಡಿಸು',
'ಸೆರೆಯಂಗಡಿ',
'ಸೆಲದಿಪುಳು',
'ಸೆಲವುಗೊಳ್',
'ಸೆಳವುರೋಗ',
'ಸೆಱಗುವಿಡಿ',
'ಸೇಕಿನಗೆಡ್ಡೆ',
'ಸೇಡುತೀರಿಸು',
'ಸೇತುಬಂಧನ',
'ಸೇದೆಗೆಡಿಸು',
'ಸೇನಬೋಯಿಕೆ',
'ಸೇನಾಧಿಪತಿ',
'ಸೇನಾಯಮುದ್ರೆ',
'ಸೇನೆದಿವಾನ',
'ಸೇರುವೆಗಾರ',
'ಸೇರುವೆಗಾಱ',
'ಸೇರುವೆಗೆಯ್',
'ಸೇರುವೆದಪ್ಪು',
'ಸೇವಕತನ',
'ಸೇವನೆಗೆಯ್',
'ಸೇಶ್ವರಸಾಂಖ್ಯ',
'ಸೈಕತಮಣಿ',
'ಸೈನ್ಯಾಧಿನಾಥ',
'ಸೈನ್ಯಾಧಿಪತಿ',
'ಸೈರಂಧ್ರಿತನ',
'ಸೈರಣೆಗುಂದು',
'ಸೈರಣೆಗೆಡು',
'ಸೈರಣೆಗೊಳ್',
'ಸೈರಣೆವಂತ',
'ಸೈವಳೆಗಱೆ',
'ಸೊಂಡಿಲಗಿಡ',
'ಸೊಂಪುಕೇಪಲ',
'ಸೊಂಪುದೋಱಿಸು',
'ಸೊಕಗೊಳಿಸು',
'ಸೊಕ್ಕಿನಗಿಡ',
'ಸೊಕ್ಕುಂಜವ್ವನ',
'ಸೊಕ್ಕುಂಜವ್ವನೆ',
'ಸೊಕ್ಕುಗತ್ತಲೆ',
'ಸೊಕ್ಕುಜವ್ವನ',
'ಸೊಕ್ಕುಜವ್ವನೆ',
'ಸೊಗಂಬಡಿಸು',
'ಸೊಗಗಂಬನಿ',
'ಸೊಗಡಗಿಲು',
'ಸೊಗಡುಗಂಪು',
'ಸೊಗಡುಗೂಡು',
'ಸೊಗಡುಗೊಳ್ಳು',
'ಸೊಗಡುದೋರು',
'ಸೊಗವಡಿಸು',
'ಸೊಗಸುಕಾಱ',
'ಸೊಗಸುಗಾತಿ',
'ಸೊಗಸುಗಾರ',
'ಸೊಗಸುಗಾರ್ತಿ',
'ಸೊಗಸುಗಾಱ',
'ಸೊಗಸುಗುಂದು',
'ಸೊಗಸುದೋಱು',
'ಸೊಗಸುವಡೆ',
'ಸೊಗಸುವೆರು',
'ಸೊಗಸುವೆಱು',
'ಸೊಟ್ಟುಕೊಯಿನೆ',
'ಸೊಟ್ಟುಹೊರಿಸು',
'ಸೊಡರುಹಬ್ಬ',
'ಸೊಡಲೆಗುಡ್ಡೆ',
'ಸೊಡಲೆಸಿದ್ಧ',
'ಸೊದೆಗಡಲ್',
'ಸೊದೆಗದಿರ',
'ಸೊನ್ನಗದಿರ',
'ಸೊನ್ನಗಾರಿಕೆ',
'ಸೊನ್ನಗೇದಗೆ',
'ಸೊನ್ನಾರವಿದ್ಯೆ',
'ಸೊನ್ನೆಗೇದಗೆ',
'ಸೊನ್ನೆವರದ',
'ಸೊಪ್ಪುಗುಟ್ಟುಹ',
'ಸೊಬಗುಗಾತಿ',
'ಸೊಬಗುಗಾರ',
'ಸೊಬಗುವಡು',
'ಸೊಬಗುವಡೆ',
'ಸೊಬಗುವೆಱು',
'ಸೊಮ್ಮುಗಿಡಿಸು',
'ಸೊರಹುಗಾಱ',
'ಸೊರ್ಕುಂಜವ್ವನ',
'ಸೊರ್ಕುಂಜವ್ವನೆ',
'ಸೊರ್ಕುಜವ್ವನ',
'ಸೊರ್ಕುಜವ್ವನೆ',
'ಸೊಸನಕಬ್ಬೆ',
'ಸೊಳ್ಳೆಪರದೆ',
'ಸೋಂಬೇರಿತನ',
'ಸೋಗಲುದುಂಬು',
'ಸೋಗಿಲುತುಂಬು',
'ಸೋಗೆನವಿಲ್',
'ಸೋಗೆನವಿಲು',
'ಸೋಜಿಗಗೊಳ್ಳು',
'ಸೋಜಿಗಪಡು',
'ಸೋಜಿಗವಡು',
'ಸೋಜಿಗವಡೆ',
'ಸೋಡಂಬಾಡನೆ',
'ಸೋಡಮುಂಜಿವಿ',
'ಸೋಣತಿಂಗಳು',
'ಸೋಣೆತಿಂಗಳು',
'ಸೋತ್ರಗುತ್ತಿಗೆ',
'ಸೋದರಅತ್ತೆ',
'ಸೋದರತಂಗೆ',
'ಸೋದರತಮ್ಮ',
'ಸೋದರಭಾವ',
'ಸೋದರಮಾವ',
'ಸೋದರಸೊಸೆ',
'ಸೋದರಸ್ನೇಹ',
'ಸೋದರಳಿಯ',
'ಸೋದಾಹರಣ',
'ಸೋಧನೆವೋಗು',
'ಸೋಪಾನಪಂಕ್ತಿ',
'ಸೋಬನವಾಡು',
'ಸೋಬಾನಹಾಡು',
'ಸೋಮಕಾಯಿಕ',
'ಸೋಮಗ್ರಹಣ',
'ಸೋಮಲಕಾರ',
'ಸೋಮಲಾಂಛನ',
'ಸೋಮವಲ್ಲರಿ',
'ಸೋಮವಾಹನ',
'ಸೋಮಾರಿತನ',
'ಸೋಮೋಪರಾಗ',
'ಸೋರೆಗುಂಡಿಗೆ',
'ಸೋರೆಬುರುಡೆ',
'ಸೋವಿಲುದುಂಬು',
'ಸೌಂದರ್ಯಪ್ರಜ್ಞೆ',
'ಸೌಂದರ್ಯಪ್ರಿಯ',
'ಸೌಂದರ್ಯಪ್ರಿಯೆ',
'ಸೌಂದರ್ಯಶಾಲಿ',
'ಸೌಂದರ್ಯಶಾಸ್ತ್ರ',
'ಸೌಧರ್ಮಕಲ್ಪ',
'ಸೌಭಾಗ್ಯಂದಳೆ',
'ಸೌಭಾಗ್ಯವತಿ',
'ಸೌಭಾಗ್ಯಶಾಲಿ',
'ಸೌಭಾಗ್ಯಶಾಳಿ',
'ಸೌರಪುರಾಣ',
'ಸೌರಭಂಗೆಡು',
'ಸೌರಭವೆಱು',
'ಸೌರಭ್ಯವಡು',
'ಸೌರಭ್ಯವಡೆ',
'ಸೌರಸಂಕ್ರಾಂತಿ',
'ಸೌವೀರಾಂಜನ',
'ಸ್ಕಂಧವಾಹಕ',
'ಸ್ಕಂಧೋಪನಯ',
'ಸ್ಕಾಂದಪುರಾಣ',
'ಸ್ಖಲಿತಗತಿ',
'ಸ್ತಂಭಕ್ರೀಡಿತ',
'ಸ್ತಂಭದೀಪಿಕೆ',
'ಸ್ತಂಭನಾಕ್ಷರ',
'ಸ್ತನಿತಾಮರ',
'ಸ್ತಳನಿಬಂಧಿ',
'ಸ್ತಾನೀಕತನ',
'ಸ್ತಿಮಿತಕಾಯ',
'ಸ್ತಿಮಿತಕಾಯೆ',
'ಸ್ತಿಮಿತಗಾತ್ರ',
'ಸ್ತಿಮಿತಗಾತ್ರೆ',
'ಸ್ತಿಮಿತದೃಷ್ಟಿ',
'ಸ್ತುತಿಪಾಠಕ',
'ಸ್ತೇನಪ್ರಯೋಗ',
'ಸ್ತೋಭನಕಾರಿ',
'ಸ್ತೋಭನಾಕ್ಷರ',
'ಸ್ತ್ರೀಪಕ್ಷಿಗ್ರಹ',
'ಸ್ತ್ರೀಪ್ರತಿಬದ್ಧ',
'ಸ್ತ್ರೀಮಲೆಯಾಳ',
'ಸ್ತ್ರೀಸಂಗಸನ್ನಿ',
'ಸ್ಥಪತಿರತ್ನ',
'ಸ್ಥಲಕಮಲ',
'ಸ್ಥಲಪಲ್ಲಟ',
'ಸ್ಥಲವಂದಿಗ',
'ಸ್ಥಲಶೃಂಗಾಟ',
'ಸ್ಥವಿರವಾದ',
'ಸ್ಥಳಪದ್ಮಿನಿ',
'ಸ್ಥಳಪಲ್ಲಟ',
'ಸ್ಥಳಮರ್ಯಾದೆ',
'ಸ್ಥಳವಂದಿಗ',
'ಸ್ಥಳಶೃಂಗಾಟ',
'ಸ್ಥಳಸಂಕೋಚ',
'ಸ್ಥಳಾವಕಾಶ',
'ಸ್ಥಾನಪಲ್ಲಟ',
'ಸ್ಥಾನಭ್ರಷ್ಟತೆ',
'ಸ್ಥಾಪನಂಗೆಯ್',
'ಸ್ಥಾಪನವಡೆ',
'ಸ್ಥಾಪನಚಾರ್ಯ',
'ಸ್ಥಾಯಿಭಾವಕ',
'ಸ್ಥಾಯೀಸಮಿತಿ',
'ಸ್ಥಾವರಲಿಂಗ',
'ಸ್ಥಾವರವಿಷ',
'ಸ್ಥಿತಿಸ್ಥಾಪಕ',
'ಸ್ಥಿರಕರಿಸು',
'ಸ್ಥಿರಗೊಳಿಸು',
'ಸ್ಥಿರಪಡಿಸು',
'ಸ್ಥಿರಮನಸ್ಕ',
'ಸ್ಥಿರಮನಸ್ಕೆ',
'ಸ್ಥಿರವಚನ',
'ಸ್ಥಿರೀಕರಣ',
'ಸ್ಥಿರೀಕರಿಸು',
'ಸ್ಥೂಲಕಾಯತೆ',
'ಸ್ಥೂಲಶರೀರ',
'ಸ್ಥೂಲಶರೀರಿ',
'ಸ್ನಾತಕಪೂರ್ವ',
'ಸ್ನಾತಕವ್ರತ',
'ಸ್ನಾತಕವ್ರತಿ',
'ಸ್ನಾತಕೋತ್ತರ',
'ಸ್ಪರ್ಶಾನುಮೇಯ',
'ಸ್ಪಷ್ಟೀಕರಣ',
'ಸ್ಫಟಿಕಮಣಿ',
'ಸ್ಫಾಟಿಕಗ್ರಾವ',
'ಸ್ಫುಟೀಕರಣ',
'ಸ್ಫುಟೀಕರಿಸು',
'ಸ್ಫುರಣೆಗೆಡು',
'ಸ್ಫುರದ್ರೂಪಿಣಿ',
'ಸ್ಮಯರಹಿತ',
'ಸ್ಮರಕೇತನ',
'ಸ್ಮರಗಲಹ',
'ಸ್ಮರಮಂದಿರ',
'ಸ್ಮರಸಂಗ್ರಾಮ',
'ಸ್ಮಶಾನಮೌನ',
'ಸ್ಮಾರಕಸ್ತಂಭ',
'ಸ್ಮೃತಿವಿಭ್ರಮ',
'ಸ್ಯಂದನಾರೋಹ',
'ಸ್ರೋತ್ರಗುತ್ತಿಗೆ',
'ಸ್ವಚ್ಛಂದಗಾಮಿ',
'ಸ್ವಚ್ಛಂದಚಾರಿ',
'ಸ್ವಚ್ಛಂದಛಂದ',
'ಸ್ವಚ್ಛಂದಮಿೞ್ತು',
'ಸ್ವತಃಪ್ರಮಾಣ',
'ಸ್ವಪ್ರಯೋಜನ',
'ಸ್ವಭಾವಜ್ಞತೆ',
'ಸ್ವಭಾವಧರ್ಮ',
'ಸ್ವಭಾವವಾದ',
'ಸ್ವಭಾವಸಿದ್ಧ',
'ಸ್ವಯಂಕರ್ತೃಕ',
'ಸ್ವಯಂಚಾಲಿತ',
'ಸ್ವಯಂಪ್ರಕಾಶ',
'ಸ್ವಯಂಭುಸ್ವರ',
'ಸ್ವಯಂಸೇವಕ',
'ಸ್ವಯಂಸೇವಕಿ',
'ಸ್ವಯಂಸೇವಿಕೆ',
'ಸ್ವಯಸಂಪರ್ಕ',
'ಸ್ವರಕರಣ',
'ಸ್ವರಪ್ರಸ್ತಾರ',
'ಸ್ವರಮಂಡಲ',
'ಸ್ವರವಿನ್ಯಾಸ',
'ಸ್ವರಸಂವಾದ',
'ಸ್ವರಸಪ್ತಕ',
'ಸ್ವರೂಪಜ್ಞಾನ',
'ಸ್ವರೂಪದೇಹ',
'ಸ್ವರ್ಣಪಾಷಾಣ',
'ಸ್ವರ್ಣಮಲ್ಲಿಗೆ',
'ಸ್ವಸ್ತಿಕಾಸನ',
'ಸ್ವಸ್ತಿವಾಚನ',
'ಸ್ವಹಸ್ತಾಕ್ಷರ',
'ಸ್ವಾತಿವರುಷ',
'ಸ್ವಾತ್ಮವಿಚಾರ',
'ಸ್ವಾತ್ಮವಿಮರ್ಶೆ',
'ಸ್ವಾದುಕಂಟಕ',
'ಸ್ವಾದುಪನಸ',
'ಸ್ವಾಭಾವಿಕತೆ',
'ಸ್ವಾಮಿದ್ರೋಹಿಕೆ',
'ಸ್ವಾಯತಲಿಂಗ',
'ಸ್ವಾಯತ್ತಸಂಸ್ಥೆ',
'ಸ್ವಾರಕಚೇರಿ',
'ಸ್ವಾರಿಫಿರಂಗಿ',
'ಸ್ವಾವಲಂಬನ',
'ಸ್ವಾವಲಂಬನೆ',
'ಸ್ವೀಕರಣೀಯ',
'ಸ್ವೀಕಾರಬಡು',
'ಸ್ವೀಕೃತಪುತ್ರ',
'ಸ್ವೀಕೃತಪುತ್ರಿ',
'ಸ್ವೇಚ್ಛಾಚಾರಿಣಿ',
'ಸ್ವೇಚ್ಛಾಮರಣ',
'ಸ್ವೇಚ್ಛಾಮರಣಿ',
'ಸ್ವೇದನವಿಧಿ',
'ಹಂಕಣಿದಲೆ',
'ಹಂಜರಕೂಳಿ',
'ಹಂಜರಗಟ್ಟು',
'ಹಂಜರಗೂಳಿ',
'ಹಂಜರವಕ್ಕಿ',
'ಹಂತಿಗೂಡಿಸು',
'ಹಂತಿದಾವಣಿ',
'ಹಂದಿಗೆಣಸು',
'ಹಂದಿವಳಯ',
'ಹಂಪುಗೊಳಿಸು',
'ಹಂಬುಆಲದ',
'ಹಂಬುಗರಿಕೆ',
'ಹಂಬುಗಱುಕೆ',
'ಹಂಬುದುರುಚ',
'ಹಂಬುಮಲ್ಲಿಗೆ',
'ಹಂಬುರಬ್ಬರ್',
'ಹಂಬುಹಾರಕ',
'ಹಂಸಗಮನೆ',
'ಹಂಸಮಂಡಳಿ',
'ಹಂಸವಾಹನ',
'ಹಂಸವಾಹನೆ',
'ಹಕಾರಿಹೊಡೆ',
'ಹಕ್ಕರೆಪಲ್ಯ',
'ಹಕ್ಕಲಗೈಸು',
'ಹಕ್ಕಳೆಯೇಳು',
'ಹಕ್ಕಾಸರಲಿ',
'ಹಕ್ಕಿವಾಹನ',
'ಹಕ್ಕಿಶಕುನ',
'ಹಕ್ಕುದಾರಿಕೆ',
'ಹಕ್ಕುಬಾಧ್ಯತೆ',
'ಹಗರಣಿಗ',
'ಹಗರಣಿಸು',
'ಹಗಲುಗನ್ನ',
'ಹಗಲುಗಳ್ಳ',
'ಹಗಲುಗೊಲೆ',
'ಹಗಲುಪಂಜು',
'ಹಗಲುಳುಕು',
'ಹಗಲೆಱೆಯ',
'ಹಗಲೊಡೆಯ',
'ಹಗುರುನೀರು',
'ಹಗೆತೀರಿಸು',
'ಹಗೆಸಾಧಿಸು',
'ಹಚ್ಚವಡಿಗೆ',
'ಹಚ್ಚವಡಿಸು',
'ಹಚ್ಚಹಸಿರು',
'ಹಚ್ಚಹಸುರು',
'ಹಚ್ಚಹಸುಳೆ',
'ಹಚ್ಚೆಚುಚ್ಚಿಸು',
'ಹಚ್ಚೆತಿಲಕ',
'ಹಜಾಮಗಿರಿ',
'ಹಜ್ಜೆಗೊಳಿಸು',
'ಹಟ್ಟಿಕೊಟ್ಟಗೆ',
'ಹಡಕುನಾತ',
'ಹಡಗುಕಟ್ಟೆ',
'ಹಡಗುದಂಡು',
'ಹಡಣಿಗಿತ್ತಿ',
'ಹಡಪಂಬಿಡಿ',
'ಹಡಪಗಿತ್ತಿ',
'ಹಡಪವಳ',
'ಹಡಬೆಬೀಳು',
'ಹಡವಳತಿ',
'ಹಡಿಕುನಾರು',
'ಹಡಿಕೆಗೈನೆ',
'ಹಡಿಕೆನಾತ',
'ಹಡಿಕೆನಾಱು',
'ಹಡಿಮೈಲಿಗೆ',
'ಹಡಿಸಲಿಸು',
'ಹಡುಕುನಾತ',
'ಹಡುಗಾರುಕ್ಕೆ',
'ಹಡುಬೀಳಿಸು',
'ಹಡೆವಳಿತಿ',
'ಹಡೆಹುಡುಗ',
'ಹಣಚಿಬಟ್ಟು',
'ಹಣಮಟೆಂಕಿ',
'ಹಣಮಣಿಹ',
'ಹಣಿದಂಬೊಯ್',
'ಹಣಿವರಡು',
'ಹಣುಮಣಿಕ್ಯ',
'ಹಣೆಬರಹ',
'ಹಣೆಬರೆಹ',
'ಹಣ್ಣುಗೊಳಿಸು',
'ಹಣ್ಣುಮುದುಕ',
'ಹಣ್ಣುಮುದುಕಿ',
'ಹಣ್ಣುಹಂಪಲು',
'ಹತಕಲ್ಮಷ',
'ಹತಪ್ರತಿಜ್ಞ',
'ಹತಭಗಿನಿ',
'ಹತ್ತಕಡಕು',
'ಹತ್ತಕಡುಕು',
'ಹತ್ತಿಹರಳು',
'ಹತ್ತುಕಡಕು',
'ಹತ್ತುಕಡುಕು',
'ಹದಗಡಲೆ',
'ಹದಗಳಲೆ',
'ಹದಗೆಡಿಸು',
'ಹದಗೊಳಿಸು',
'ಹದಹದಿಕು',
'ಹದಹದಿಸು',
'ಹದಿಟೆಗೆಯ್',
'ಹದಿನೆರಡು',
'ಹದಿರುಗಾರ',
'ಹದಿರುಗಾಱ',
'ಹದಿರುವಾತು',
'ಹದಿಹರೆಯ',
'ಹದುಳವಿಡು',
'ಹದುಳವಿರು',
'ಹದುಳಹೋಗು',
'ಹದುಳಿರಿಸು',
'ಹದ್ದುಗೈಯಿಡು',
'ಹನಸವಾತು',
'ಹನಿಗವನ',
'ಹನುಮಗತಿ',
'ಹನುಮಾಸನ',
'ಹನುಮಾಯಣ',
'ಹನ್ನವಣಿಗ',
'ಹನ್ನಿಚ್ರ್ಛಾಸಿರ',
'ಹನ್ನೆಸೆಣಬು',
'ಹಪಹಪಿಸು',
'ಹಪಾಪಿತನ',
'ಹಪ್ಪಳಕಾರ',
'ಹಪ್ಪಳಖಾರ',
'ಹಪ್ಪುಸವಗ',
'ಹಪ್ಪುಸಾವಿಗೆ',
'ಹಬ್ಬಹುಣ್ಣಿಮೆ',
'ಹಬ್ಬುಕಱುಕೆ',
'ಹಬ್ಬುಗರಿಕೆ',
'ಹಬ್ಬುಬದನೆ',
'ಹಮಹಮಿಕೆ',
'ಹಮಾಂಜಿನಸು',
'ಹಮಾಮ್‍ಸ್ನಾನ',
'ಹಮೀರತನ',
'ಹಮೀರನಾಟಿ',
'ಹಮ್ಮದಂಬೊಂದು',
'ಹಮ್ಮದಂಬೋಗು',
'ಹಮ್ಮದವೋಗು',
'ಹಮ್ಮೀರತಾತಿ',
'ಹಮ್ಮುಖವೋಗು',
'ಹಯನುಗದ್ದೆ',
'ಹಯನುಬತ್ತ',
'ಹಯನುಬೆಳೆ',
'ಹಯಪರ್ಯಾಣ',
'ಹಯಮಂದಿರ',
'ಹಯಮಾರಕ',
'ಹಯಮೋಹರ',
'ಹಯವಲ್ಗನ',
'ಹಯವೈಹಾಳಿ',
'ಹಯಶಿಕ್ಷಕ',
'ಹಯಹೇಷಿತ',
'ಹಯ್ಯಂಗವೀನ',
'ಹರಕರಿಸು',
'ಹರಕಲಿಸು',
'ಹರಕೆಕಟ್ಟು',
'ಹರಕೆಕೊಡು',
'ಹರಕೆಗೊಳ್',
'ಹರಕೆಗೊಳ್ಳು',
'ಹರಕೆದೆಗೆ',
'ಹರಕೆಹೊರು',
'ಹರಕೋಟೀರ',
'ಹರಗುವಾಳ್',
'ಹರಟೆಕೊಚ್ಚು',
'ಹರಟೆಗಾತಿ',
'ಹರಟೆಗಾರ',
'ಹರಟೆಗುಟ್ಟು',
'ಹರಟೆಬಡಿ',
'ಹರಟೆಮಲ್ಲ',
'ಹರಟೆಮಲ್ಲಿ',
'ಹರಟೆಹೊಡೆ',
'ಹರಣಂಗಳೆ',
'ಹರಣಂಗೆಯ್',
'ಹರಣಂಬಾರು',
'ಹರಣಂಬಿಡಿ',
'ಹರಣಗಳಿ',
'ಹರಣಗೊಳ್',
'ಹರಣಗೊಳು',
'ಹರಣವಳಿ',
'ಹರಣವಿಡಿ',
'ಹರತಾಳಕ',
'ಹರದಮಾತು',
'ಹರದವಾತು',
'ಹರದುಗೆಯ್',
'ಹರದುಗೆಯ್ಯು',
'ಹರನಯನ',
'ಹರನಾಳಿಗೆ',
'ಹರಯಗಳಿ',
'ಹರವಣಿಗೆ',
'ಹರವರಿಸು',
'ಹರವಸತೆ',
'ಹರವಿಗೂಳು',
'ಹರಸಮಯಿ',
'ಹರಹುಗೆಡು',
'ಹರಳಿಗಿತ್ತಿ',
'ಹರಳಿವಟ್ಟ',
'ಹರಳುಕಲ್ಲು',
'ಹರಳುಗಟ್ಟು',
'ಹರಳುಗಲ್ಲು',
'ಹರಳುಟೆಕ್ಕೆ',
'ಹರಳುದುಂಬು',
'ಹರಳುವೀರೆ',
'ಹರಳುಹೊನ್ನೆ',
'ಹರಾಮಖೋರ',
'ಹರಿಕರಿಸು',
'ಹರಿಕಳವಿ',
'ಹರಿಕಾಂಭೋಜಿ',
'ಹರಿಕೀಲಾದ್ರಿ',
'ಹರಿಕೆಕುಳಿ',
'ಹರಿಗಾಲುವೆ',
'ಹರಿಗೆಕಾರ',
'ಹರಿಚಂದನ',
'ಹರಿಣತ್ರಾಸ',
'ಹರಿಣಧರ',
'ಹರಿಣಪಕ್ಷ',
'ಹರಿಣಪ್ಲುತ',
'ಹರಿಣರೋಗ',
'ಹರಿಣಾಜಿನ',
'ಹರಿಣೀಪ್ಲುತ',
'ಹರಿತಗೆಡು',
'ಹರಿತಮಣಿ',
'ಹರಿತಾಂಕುರ',
'ಹರಿತಾಲಕ',
'ಹರಿನಾಳಿಗೆ',
'ಹರಿಬಂಧನ',
'ಹರಿಬಕಾಱ',
'ಹರಿಭೂಧರ',
'ಹರಿಮೇಖಲೆ',
'ಹರಿಮೇಖಳೆ',
'ಹರಿಯಂತರ',
'ಹರಿವರುಷ',
'ಹರಿವಾಲುಕ',
'ಹರಿವಾಸರ',
'ಹರಿವಿಷ್ಟರ',
'ಹರಿಸಲುಗೆ',
'ಹರಿಹಂಚಿಕೆ',
'ಹರಿಹಲಗೆ',
'ಹರಿಹುಗಿಲ್',
'ಹರಿಹುಗಿಲು',
'ಹರಿಹೊನಲು',
'ಹರುಷಂದೋಱು',
'ಹರುಷಂಬಡು',
'ಹರುಷಂಬೊಱು',
'ಹರುಷಗೆಡು',
'ಹರುಷತಿಕೆ',
'ಹರುಷಪಡು',
'ಹರುಷವೇಱು',
'ಹರುಷೋನ್ನತಿ',
'ಹರುಸಂಗೆಡು',
'ಹರುಹುಗೆಡು',
'ಹರೆಗಡಿಸು',
'ಹರೆಯಂಬಡೆ',
'ಹರೆಯಂಬೋಗು',
'ಹರೆಯವಡೆ',
'ಹರೆಹಂದಲ್',
'ಹರ್ತರದೂದು',
'ಹರ್ಷನಿರ್ಭರ',
'ಹಲಗಾರತಿ',
'ಹಲಗೆಕಾಱ',
'ಹಲಗೆಗಲ್ಲು',
'ಹಲಗೆಮಂಚ',
'ಹಲಗೆಸೇವೆ',
'ಹಲಚಿಗಲ್ಲು',
'ಹಲಮಾಣಿಕ',
'ಹಲಾಲುಖೋರ',
'ಹಲಾಲುಟೋಪಿ',
'ಹಲಿಗಾರತಿ',
'ಹಲುಗಿರಿಸು',
'ಹಲುಗಿರುಕ',
'ಹಲುದೆಱಹಿ',
'ಹಲುಮಾಣಿಕ',
'ಹಲುಮಾಣಿಕ್ಯ',
'ಹಲ್ಲಿಲಿಬಾಯಿ',
'ಹಲ್ಲಿಶಕುನ',
'ಹಲ್ಲಿಸಕುನ',
'ಹಲ್ಲುಗಿರಿಕ',
'ಹಲ್ಲುಬಾರಿಸು',
'ಹವಳತೊಂಡೆ',
'ಹವಳದೊಂಡೆ',
'ಹವಾಲ್‍ಸೀಮೆ',
'ಹವಾಲದಾರ',
'ಹವಾಲುದಾರ',
'ಹವಾಲ್ತೆದಾರ',
'ಹವಾಶೀತಕ',
'ಹವ್ಯವಾಹನ',
'ಹಷಾಮುಬಾರು',
'ಹಸಗೆಡಿಸು',
'ಹಸಗೊಳಿಸು',
'ಹಸನಂಗೆಯ್',
'ಹಸನುಪ್ರತಿ',
'ಹಸರಗಣ್ಣಿ',
'ಹಸರವಿಕ್ಕು',
'ಹಸರವಿಡಿ',
'ಹಸರುಗುಬ್ಬಿ',
'ಹಸರುವಾಣಿ',
'ಹಸಲುಗೂಳು',
'ಹಸಿಕಟ್ಟಿಗೆ',
'ಹಸಿಗೆಪಟ್ಟಿ',
'ಹಸಿಬೆಚೀಲ',
'ಹಸಿಮಜ್ಜಿಗೆ',
'ಹಸಿಮಡಕೆ',
'ಹಸಿಯಡಕೆ',
'ಹಸಿರುಗಟ್ಟು',
'ಹಸಿರುಗಣಿ',
'ಹಸಿರುಗನ್ನಿ',
'ಹಸಿರುಜಾಲಿ',
'ಹಸಿರುತುಂಬೆ',
'ಹಸಿರುಬಂದ',
'ಹಸಿರುಮುರಿ',
'ಹಸಿರುವಾಣಿ',
'ಹಸಿರುಸೋಣಿ',
'ಹಸುಕುನಾರು',
'ಹಸುಕುನಾಱು',
'ಹಸುಗೆಗೆಯ್',
'ಹಸುಗೆಗೊಳ್',
'ಹಸುಗೆಪಟ್ಟಿ',
'ಹಸುಮಗಳು',
'ಹಸುರುಕಲ್ಲು',
'ಹಸುರುಗಟ್ಟು',
'ಹಸುರುಗನ್ನಿ',
'ಹಸುರುಗುಂದು',
'ಹಸುರುಗುಬ್ಬಿ',
'ಹಸುರುಗೆಂಪು',
'ಹಸುರುತುಂಬೆ',
'ಹಸುರುಮದ್ದು',
'ಹಸುರುಮುರಿ',
'ಹಸುರುಮೇವು',
'ಹಸುರುವಾಣಿ',
'ಹಸುರುಹಾವು',
'ಹಸುರುಹುಲ್ಲು',
'ಹಸುರೌಷಧ',
'ಹಸುಹರಣ',
'ಹಸುಹುಡುಗ',
'ಹಸುಳೆಗಾಯ್',
'ಹಸುಳೆತನ',
'ಹಸುಳೆವೆಱೆ',
'ಹಸೆಜಗಲಿ',
'ಹಸ್ತಕಂಕಣ',
'ಹಸ್ತಕಟಕ',
'ಹಸ್ತಕಡಗ',
'ಹಸ್ತಕೌಶಲ',
'ಹಸ್ತಪತ್ರಿಕೆ',
'ಹಸ್ತಪರೀಕ್ಷೆ',
'ಹಸ್ತಪರುಷ',
'ಹಸ್ತಪಾವಡ',
'ಹಸ್ತಪಾವುಡ',
'ಹಸ್ತಭೂಷಣ',
'ಹಸ್ತಮರ್ದನ',
'ಹಸ್ತಮುದ್ರಿಕೆ',
'ಹಸ್ತಮೈಥುನ',
'ಹಸ್ತಲಾಘವ',
'ಹಸ್ತವಿಸ್ತಾರ',
'ಹಸ್ತವ್ಯಾಪಾರ',
'ಹಸ್ತಾಂತರಣ',
'ಹಸ್ತಾಂಧೋಲನ',
'ಹಸ್ತಾಪಜೇಯ',
'ಹಸ್ತಾಭಿನಯ',
'ಹಸ್ತಾಮಲಕ',
'ಹಸ್ತಾಮಳಕ',
'ಹಸ್ತಾಹಸ್ತಿಕೆ',
'ಹಸ್ತಿವಾವುಜ',
'ಹಳಗನ್ನಡ',
'ಹಳದಿಗಾರ',
'ಹಳದಿಗುಳ್ಳ',
'ಹಳದಿಜ್ವರ',
'ಹಳದಿವಣ್ಣ',
'ಹಳಬಾಳಗೆ',
'ಹಳವಂಡಗೈ',
'ಹಳವಳಿಸು',
'ಹಳಹಳನೆ',
'ಹಳಹಳಿಕೆ',
'ಹಳಹಳಿಸು',
'ಹಳೆಗನ್ನಡ',
'ಹಳೆಯಪೈಕ',
'ಹಳೆಯುಳಿಕೆ',
'ಹಳಿ್ಳಕಾತಿ',
'ಹಳ್ಳಿಗತನ',
'ಹಳ್ಳಿಗಮಾರ',
'ಹಱಿಕುಗಡ್ಡ',
'ಹಱಿದಲೆಯ',
'ಹಱುಕುಗಡ್ಡ',
'ಹೞಮೈಲಗೆ',
'ಹಾಜರ್‍ಭಾಷಿ',
'ಹಾಜರುಪಟ್ಟಿ',
'ಹಾಜರುಭಾಷಿ',
'ಹಾಟಕವೆಟ್ಟು',
'ಹಾಟಕಾಂಬರ',
'ಹಾಟಕೇಶ್ವರ',
'ಹಾಡಹಗಲು',
'ಹಾಡುಗಾರಿಕೆ',
'ಹಾಡುಹಗಲು',
'ಹಾತರುಮಾಲು',
'ಹಾದರಗಿತ್ತಿ',
'ಹಾದರವಿದ್ದೆ',
'ಹಾದರಿಗಿತಿ',
'ಹಾದಿಗೊಳಿಸು',
'ಹಾದಿಜಗಳ',
'ಹಾದಿತಪ್ಪಿಸು',
'ಹಾದಿಬಿಡಿಸು',
'ಹಾಯಿಹಡಗು',
'ಹಾರಕಟ್ಟಿಗೆ',
'ಹಾರಗಟ್ಟಿಗೆ',
'ಹಾರವಲಯ',
'ಹಾರಿಕೆಮಾತು',
'ಹಾರಿಕೆಸುದ್ದಿ',
'ಹಾರುಅಳಿಲು',
'ಹಾರುಗಟ್ಟಿಗೆ',
'ಹಾರುವತನ',
'ಹಾರೆಗಟ್ಟಿಗೆ',
'ಹಾರ್ಮೊನಿಯಮ್ಮು',
'ಹಾಲಂಗೊಳಲೆ',
'ಹಾಲಗುಂಬಳ',
'ಹಾಲಬಲಗೆ',
'ಹಾಲಬಲಿಗೆ',
'ಹಾಲಹವಾಲೆ',
'ಹಾಲಾಹವಾಲು',
'ಹಾಲಾಹವಾಲೆ',
'ಹಾಲಿಬಚ್ಚೆಲೆ',
'ಹಾಲುಕುಂಬಳ',
'ಹಾಲುಗಡಲು',
'ಹಾಲುಗುಂಬಳ',
'ಹಾಲುಗೆಚ್ಚಲು',
'ಹಾಲುಗೆಣಸು',
'ಹಾಲುಪಾಯಸ',
'ಹಾಲುಬಳಪ',
'ಹಾಲುಮಂಡಗೆ',
'ಹಾಲುಮುಲ್ಲಂಗಿ',
'ಹಾಲುಮೂಲಂಗಿ',
'ಹಾಲುಸಂಪಿಗೆ',
'ಹಾಲುಹಸುಳೆ',
'ಹಾಲೊಡಕಲು',
'ಹಾಲ್ಕೊರಟಿಗೆ',
'ಹಾಲ್ಹಕ್ಕರಿಕೆ',
'ಹಾವಸೆಮನ',
'ಹಾವಸೆಹಿಡಿ',
'ಹಾವುದಲೆಗ',
'ಹಾವೇರಿನ್ಯಾಯ',
'ಹಾಸಗೆಹಿಡಿ',
'ಹಾಸರಗತ್ತೆ',
'ಹಾಸರೆಗಲ್ಲು',
'ಹಾಸಱೆಗಲು',
'ಹಾಸಱೆಗಲ್ಲು',
'ಹಾಸಿಗೆಗಲ್ಲು',
'ಹಾಸಿಗೆಮರ',
'ಹಾಸಿಗೆಹತ್ತು',
'ಹಾಸಿಗೆಹಾವು',
'ಹಾಸಿಗೆಹಿಡಿ',
'ಹಾಸಿಗೆಹುಣ್ಣು',
'ಹಾಸುಗೆಗಲ್ಲು',
'ಹಾಸುಗೆಹಿಡಿ',
'ಹಾಸುವಲಗೆ',
'ಹಾಸುಹಲಗೆ',
'ಹಾಸ್ಯಚಟಾಕಿ',
'ಹಾಸ್ಯನಾಟಕ',
'ಹಾಹಾಕ್ರಂದನ',
'ಹಾಳಿಹಸುಗೆ',
'ಹಾಳುಗೆಡವು',
'ಹಾಳುಗೆಡಹು',
'ಹಾಳುಹರಟೆ',
'ಹಿಂಗಟ್ಟುಕಟ್ಟು',
'ಹಿಂಗಟ್ಟುಮುರಿ',
'ಹಿಂಗಾರಿಮಳೆ',
'ಹಿಂಗಾರುಮಳೆ',
'ಹಿಂಡಲಕಾಯಿ',
'ಹಿಂಡುಗಂಕಣ',
'ಹಿಂಡುಗಟ್ಟಲೆ',
'ಹಿಂಡುಗಟ್ಟಿಗೆ',
'ಹಿಂಡುಗಲಕ',
'ಹಿಂಡುಹೊಳಹು',
'ಹಿಂದಡಿಯಿಡು',
'ಹಿಂದಿರುಗಿಸು',
'ಹಿಂದುವರಾಳಿ',
'ಹಿಂಬಾಲತನ',
'ಹಿಕ್ಕವತ್ತಿಗೆ',
'ಹಿಕ್ಕಾಡಲ್ಲಿಕೆ',
'ಹಿಕ್ಕುಹಣಿಗೆ',
'ಹಿಕ್ಮತ್ತುಗಾರ',
'ಹಿಜರಿಶಕೆ',
'ಹಿಜರೀಶಕ',
'ಹಿಟಕವಾಡು',
'ಹಿಟ್ಟುಬಳಪ',
'ಹಿಡಹಿಡಕೆ',
'ಹಿಡಿಂಬಾಸುರ',
'ಹಿಡಿಗವಡೆ',
'ಹಿಡಿತರಿಸು',
'ಹಿಡಿದಪ್ಪಲೆ',
'ಹಿಡಿಹಿಡಿಕೆ',
'ಹಿಡಿಹುಗೊಡು',
'ಹಿಡಿಹುಗೊಳ್',
'ಹಿಡುವಳಿಗೆ',
'ಹಿಡುಹಿಡಿಕೆ',
'ಹಿಡುಹಿಡುಕೆ',
'ಹಿತಕಾರಿಣಿ',
'ಹಿತಚಿಂತಕ',
'ಹಿತಚಿಂತಕಿ',
'ಹಿತವಂತಿಕೆ',
'ಹಿತವಂದಿಗ',
'ಹಿತವಚನ',
'ಹಿತಶತ್ರುತ್ವ',
'ಹಿತೋಪದೇಶ',
'ಹಿತ್ತಲವರೆ',
'ಹಿತ್ತಿಲವರೆ',
'ಹಿದುಕವರೆ',
'ಹಿಪ್ಪನೇರಿಳೆ',
'ಹಿಪ್ಪಲಿಬೇರು',
'ಹಿಪ್ಪಲಿಮೂಲ',
'ಹಿಪ್ಪುನೇರಳೆ',
'ಹಿಮಪಂಚಕ',
'ಹಿಮಕಿರಣ',
'ಹಿಮಗದಿರ',
'ಹಿಮವದ್ಗಿರಿ',
'ಹಿಮವನ್ನಗ',
'ಹಿಮಸಮಯ',
'ಹಿಮಾಂಶುಗೃಹ',
'ಹಿಮ್ಮಣಿಚೀಲ',
'ಹಿಮ್ಮಣ್ಣಿಚೀಲ',
'ಹಿಮ್ಮೇಳಕಾರ',
'ಹಿಮ್ಮೇಳಗಾನ',
'ಹಿರಣ್ಯಗರ್ಭ',
'ಹಿರಣ್ಯದಾನ',
'ಹಿರಣ್ಯದಾನಿ',
'ಹಿರಣ್ಯಬಾಹು',
'ಹಿರಣ್ಯರೇತ',
'ಹಿರಿತಂಗಡಿ',
'ಹಿರಿಬರಸ',
'ಹಿರಿಬಸಳೆ',
'ಹಿರಿಬೋವಿಗೆ',
'ಹಿರಿಮುತ್ತುಕ',
'ಹಿರಿಯಗಿಡು',
'ಹಿರಿಯಚುಂಚು',
'ಹಿರಿಯಬೆಲ್ಲ',
'ಹಿರಿಯಮದ',
'ಹಿರಿಯಮದ್ದು',
'ಹಿರಿಯಮರ್ದು',
'ಹಿರಿಯಮೆಕ್ಕೆ',
'ಹಿರಿಯರಸಿ',
'ಹಿರಿಯೀಚಲು',
'ಹಿರಿಯುದ್ದರಿ',
'ಹಿರಿಯೆಲವ',
'ಹಿರಿಯೇಲಕ್ಕಿ',
'ಹಿರಿಹುದ್ದರಿ',
'ಹಿಲಮೋಚಿಕೆ',
'ಹಿಲ್ಲುಗಾಱಿಕೆ',
'ಹಿವಗದಿರ',
'ಹಿಳುಕುಗಱಿ',
'ಹಿಳ್ಳೊಡೆಯಿಸು',
'ಹೀನಾಯಪಡು',
'ಹೀನಾಯವಾಡು',
'ಹೀಲೆಗೆದಱು',
'ಹೀಹಾಳಿತನ',
'ಹುಂಡಗುತ್ತಿಗೆ',
'ಹುಂಡಸಂಸ್ಥಾನ',
'ಹುಂಡಹಾಳತ',
'ಹುಂಡಿಗಡಿಗೆ',
'ಹುಂಬಗುತಿಗೆ',
'ಹುಂಬಗುತ್ತಿಗೆ',
'ಹುಂಬಗುದ್ದಿಕೆ',
'ಹುಂಬುಗುತ್ತಿಗೆ',
'ಹುಕುಮನಾಮೆ',
'ಹುಗಿಲುಗಳೆ',
'ಹುಗಿಲುತೆಗೆ',
'ಹುಗಿಲುದೆಗೆ',
'ಹುಚ್ಚುಕಗ್ಗಲಿ',
'ಹುಚ್ಚುಕುಣಿತ',
'ಹುಚ್ಚುಗೊಳಿಸು',
'ಹುಚ್ಚುಚಕ್ಕೋತ',
'ಹುಚ್ಚುನರವೆ',
'ಹುಚ್ಚುಬಿಡಿಸು',
'ಹುಚ್ಚುಸಾಸಿವೆ',
'ಹುಚ್ಚುಸಾಸುವೆ',
'ಹುಚ್ಚುಹತ್ತಿಸು',
'ಹುಚ್ಚುಹರಟೆ',
'ಹುಚ್ಚುಹರಳು',
'ಹುಚ್ಚುಹಿಡಿಸು',
'ಹುಜೂರರಿಕೆ',
'ಹುಟ್ಟಡಗಿಸು',
'ಹುಟ್ಟುಕಟ್ಟಳೆ',
'ಹುಟ್ಟುಕಸುಬು',
'ಹುಟ್ಟುಕಿವುಡ',
'ಹುಟ್ಟುಕಿವುಡಿ',
'ಹುಟ್ಟುಕುರುಡ',
'ಹುಟ್ಟುಕುರುಡಿ',
'ಹುಟ್ಟುಕೂದಲು',
'ಹುಟ್ಟುಗುರುಡ',
'ಹುಟ್ಟುಗುರುಡಿ',
'ಹುಟ್ಟುಗೂದಲು',
'ಹುಟ್ಟುಗೆಡಿಸು',
'ಹುಟ್ಟುದರಿದ್ರ',
'ಹುಟ್ಟುಮರುಳ',
'ಹುಟ್ಟುಮರುಳು',
'ಹುಡಿಕೀಲುಗ',
'ಹುಡಿಕೆವಲೆ',
'ಹುಡಿಕ್ಕೆಬತ್ತ',
'ಹುಡಿಗೂಡಿಸು',
'ಹುಡಿಗೆದರು',
'ಹುಡಿಗೆದಱು',
'ಹುಡಿಮಸಗು',
'ಹುಡಿಹಾರಿಸು',
'ಹುಡುಕಳನಿ',
'ಹುಡುಕುದಿಟ್ಟಿ',
'ಹುಡುಕುನೀರ್',
'ಹುಡುಕುನೋಟ',
'ಹುಡುಗತನ',
'ಹುಡುಗಪಾಳ್ಯ',
'ಹುಡುಗಾಟಿಕೆ',
'ಹುಡುಗುಚೇಷ್ಟೆ',
'ಹುಡುಗುಬುದ್ಧಿ',
'ಹುಡುಹುಡನೆ',
'ಹುಣಸೆಬಳ್ಳಿ',
'ಹುಣಸೆಸರಿ',
'ಹುಣಸೆಸಾರು',
'ಹುತಾಶರಸ',
'ಹುದಿಯೇರಿಸು',
'ಹುದಿಹೊಸಲು',
'ಹುದುವಾಳಿಕೆ',
'ಹುದುವಾಳಿಗೆ',
'ಹುಯಿಗಡುಬು',
'ಹುಯಿಲೆಬ್ಬಿಸು',
'ಹುಯ್ಯಲೇಳಿಸು',
'ಹುರಪಳಿಸು',
'ಹುರಳಿಕಾಯಿ',
'ಹುರಿಕಟಿಸು',
'ಹುರಿಕಾಯಿಸು',
'ಹುರಿಕೊಳಿಸು',
'ಹುರಿಗಡಲೆ',
'ಹುರಿಗಡುಬು',
'ಹುರಿಗಾವಲಿ',
'ಹುರಿಗೊಳಿಸು',
'ಹುರಿದುಂಬಿಸು',
'ಹುರಿನಬಳ್ಳಿ',
'ಹುರಿಯುರುಳು',
'ಹುರಿಯೇರಿಸು',
'ಹುರಿಯೇಱಿಸು',
'ಹುರಿಸಂಡಗೆ',
'ಹುರಿಸಕ್ಕರೆ',
'ಹುರುಡುಕಟ್ಟು',
'ಹುರುಪಳಿಸು',
'ಹುರುಪುತುಂಬು',
'ಹುರುಳಿಕಟ್ಟು',
'ಹುರುಳಿಕಾಯಿ',
'ಹುರುಳುಗೆಡು',
'ಹುರುಳುಗೊಳ್ಳು',
'ಹುರುಳುತಲೆ',
'ಹುಲಿಗರುಕೆ',
'ಹುಲಿದೇವರು',
'ಹುಲಿದೊಗಲು',
'ಹುಲಿದೊವಲು',
'ಹುಲಿಯುಗುರ್',
'ಹುಲಿಯುಗುರು',
'ಹುಲುಗುಡಿತೆ',
'ಹುಲುಗುಡಿಲು',
'ಹುಲುಚಲವ',
'ಹುಲುಸರವಿ',
'ಹುಲ್ಲುಈಚಲ',
'ಹುಲ್ಲುಗಾವಲು',
'ಹುಲ್ಲುಬಂದಿಗ',
'ಹುಲ್ಲುಮಣಿಯ',
'ಹುಲ್ಲುಸರಡು',
'ಹುಲ್ಲುಹಕ್ಕಲು',
'ಹುಲ್ಲುಹಸಲೆ',
'ಹುಸಿಕಾಳಗ',
'ಹುಸಿಡಂಭಕ',
'ಹುಳಹಿಡಕ',
'ಹುಳಿಆಚಲು',
'ಹುಳಿಈಚಲು',
'ಹುಳಿಚಕ್ಕೋತ',
'ಹುಳಿದಾಳಲು',
'ಹುಳಿದಾಳಿದ',
'ಹುಳಿನಾಣಲು',
'ಹುಳಿಬಸಲೆ',
'ಹುಳಿಬೇಳಲ',
'ಹುಳಿಮಜ್ಜಿಗೆ',
'ಹುಳಿಸೊಡಲಿ',
'ಹುಳಿಹರುಹೆ',
'ಹುಳಿಹುಣಸೆ',
'ಹುಳಿಹುಣಿಚೆ',
'ಹುಳುಕುನೀತಿ',
'ಹುಳುಕು ಬುದ್ಧಿ',
'ಹುಳುಕುಮತ',
'ಹುಳಕುವಾಲೆ',
'ಹುಳುಕುಹಲ್ಲು',
'ಹೂಂಕಾರಂಗೆಯ್',
'ಹೂಂಕೃತಿಗೆಯ್',
'ಹೂಜೆಗತನ',
'ಹೂಟಿಗುಣಕು',
'ಹೂಣಿಗತನ',
'ಹೂತಕದುಂಬೆ',
'ಹೂಮಳೆಕರೆ',
'ಹೂಮಳೆಗರೆ',
'ಹೂವಡಿಗಿತಿ',
'ಹೂವಡಿಗಿತ್ತಿ',
'ಹೂವಾಡಗಿತ್ತಿ',
'ಹೂವುಮುಡಿಸು',
'ಹೂಸುಬುರುಕ',
'ಹೃತ್ಸಮೂದ್ಭೂತ',
'ಹೃದಯಂಗಮ',
'ಹೃದಯಂಗುಡು',
'ಹೃದಯಂಗೊಳ್',
'ಹೃದಯಕ್ರಿಯೆ',
'ಹೃದಯಗಾಣ್',
'ಹೃದಯಗೊಡು',
'ಹೃದಯಜೀವಿ',
'ಹೃದಯಜ್ವರ',
'ಹೃದಯದಟ್ಟು',
'ಹೃದಯಬೇನೆ',
'ಹೃದಯಭಾರ',
'ಹೃದಯರೋಗ',
'ಹೃದಯವಂತ',
'ಹೃದಯವಂತೆ',
'ಹೃದಯಶೂನ್ಯ',
'ಹೃದಯಶೂನ್ಯೆ',
'ಹೃದಯಶೂಲೆ',
'ಹೃದಯಸ್ಥಿತ',
'ಹೃದಯಸ್ಪರ್ಶಿ',
'ಹೃದಯಹಾರಿ',
'ಹೃದಯಹೀನ',
'ಹೃದಯಹೀನೆ',
'ಹೃದಯಾಘಾತ',
'ಹೃದಯೇಶ್ವರ',
'ಹೃದಯೇಶ್ವರಿ',
'ಹೃದ್ಯೋಗಂಬಡು',
'ಹೆಂಗುಸುತನ',
'ಹೆಂಟೆಗೊಡತಿ',
'ಹೆಂಡೆಗೊಡತಿ',
'ಹೆಂಡೆಮೂಗಕ್ಕಿ',
'ಹೆಂದಗತ್ತಲೆ',
'ಹೆಂಬೇಡಿತನ',
'ಹೆಕ್ಕಳಂಗೊಳ್',
'ಹೆಕ್ಕಳಂಗೊಳು',
'ಹೆಕ್ಕಳಗೊಳ್',
'ಹೆಕ್ಕಳಗೊಳು',
'ಹೆಕ್ಕಳಗೊಳ್ಳು',
'ಹೆಕ್ಕಳದೋಱು',
'ಹೆಕ್ಕಳಬಡು',
'ಹೆಕ್ಕೆರಕಲು',
'ಹೆಗಲುಗೇಡು',
'ಹೆಗಲುಗೇಣಿ',
'ಹೆಗಲುಚೀಲ',
'ಹೆಗಲ್ಗೊಳಿಸು',
'ಹೆಗ್ಗಡೆತನ',
'ಹೆಗ್ಗಣಿಗಲು',
'ಹೆಗ್ಗುರಂಟಿಕೆ',
'ಹೆಗ್ಗುರಟಿಗೆ',
'ಹೆಗ್ಗುರಟಗೆ',
'ಹೆಗ್ಗುರಟಿಕೆ',
'ಹೆಚ್ಚಾಳುತನ',
'ಹೆಚ್ಚುಗಟ್ಟಲೆ',
'ಹೆಚ್ಚುಗಾರಿಕೆ',
'ಹೆಚ್ಚುಗಾಱಿಕೆ',
'ಹೆಚ್ಚುಗುಣಿಸು',
'ಹೆಚ್ಚುಲಿಹುಲ್ಲು',
'ಹೆಜ್ಜೆಕುಣಿತ',
'ಹೆಜ್ಜೆದೆಗಹು',
'ಹೆಟ್ಟರಿಮುಳ್ಳು',
'ಹೆಟ್ಟೇರನಿಕೆ',
'ಹೆಡ್‍ಗುಮಾಸ್ತ',
'ಹೆಡ್‍ಗುಮಾಸ್ತೆ',
'ಹೆಡ್‍ಮುನಶಿ',
'ಹೆಡಗೆಬಳ್ಳಿ',
'ಹೆಡಗೆವಳ್ಳಿ',
'ಹೆಡಮುರಿಕೆ',
'ಹೆಡಮುರಿಗೆ',
'ಹೆಡಿಗೆಜಾತ್ರೆ',
'ಹೆಣಗೆಡಹು',
'ಹೆಣದಿನಿಹಿ',
'ಹೆಣದಿನುಹಿ',
'ಹೆಣ್ಣಿಗತನ',
'ಹೆಣ್ಣುಕರಟ',
'ಹೆಣ್ಣುಕಾಳಿಂಗ',
'ಹೆಣ್ಣುಕುಣಿಕೆ',
'ಹೆಣ್ಣುಗರುಳು',
'ಹೆಣ್ಣುಗವಡೆ',
'ಹೆಣ್ಣುಗೊರವಿ',
'ಹೆಣ್ಣುಗೊರಿವಿ',
'ಹೆಣ್ಣುಸಂಪಿಗೆ',
'ಹೆತ್ತರಾಣಿಕೆ',
'ಹೆತ್ತರಿಮುಳ್ಳು',
'ಹೆದರುಗುಳಿ',
'ಹೆದರುಪುಕ್ಕ',
'ಹೆದೆಗೊಳಿಸು',
'ಹೆದಯೇರಿಸು',
'ಹೆದೆಯೇಱಿಸು',
'ಹೆದ್ದಳಕಲು',
'ಹೆನೆಯಕುಳ',
'ಹೆಪ್ಪುಗೊಳಿಸು',
'ಹೆಬ್ಬಗೆತನ',
'ಹೆಬ್ಬಳಬೆಕ್ಕು',
'ಹೆಬ್ಬಿಬ್ಬಿಗೊಳು',
'ಹೆಬ್ಬುಗೆಣಸು',
'ಹೆಬ್ಬುಬ್ಬುಗೊಳು',
'ಹೆಬ್ಬುಹುಲಿವೆ',
'ಹೆಬ್ಬುಹುಲುವೆ',
'ಹೆಮ್ಮುಟ್ಟುಪಡ',
'ಹೆಮ್ಮೆಕಾಱಿಕೆ',
'ಹೆಮ್ಮೆಗಾರಿಕೆ',
'ಹೆಮ್ಮೆಗೆಡಿಸು',
'ಹೆಮ್ಮೆಗೊಳಿಸು',
'ಹೆರೆಗೆಹಾಲೆ',
'ಹೆರೆನೊಸಲು',
'ಹೆಸರುಕೆಡು',
'ಹೆಸರುಗೊಳ್',
'ಹೆಸರುಗೊಳ್ಳು',
'ಹೆಸರುವಡೆ',
'ಹೆಸರುವಾಸಿ',
'ಹೆಸರುಹಿಡಿ',
'ಹೆಱತೊಲಗು',
'ಹೆಱದೆಗಹ',
'ಹೆಱೆದಲೆಗ',
'ಹೆಱೆದಲೆಯ',
'ಹೆಱೆನೊಸಲು',
'ಹೆಱೆಸರಳು',
'ಹೇಕುಳಿಗೊಳ್',
'ಹೇತ್ವಲಂಕಾರ',
'ಹೇತ್ವಲಂಕೃತಿ',
'ಹೇಮಕಂಕುಷ್ಠ',
'ಹೇಮಕಂಕೋಷ್ಠ',
'ಹೇಮಕಾರಕ',
'ಹೇಮಗೈರಿಕ',
'ಹೇಮತಾಟಕ',
'ಹೇಮದುಗ್ಧಕ',
'ಹೇಮಪುಷ್ಪಕ',
'ಹೇಮಪುಷ್ಪಿಕೆ',
'ಹೇಮಮಾಕ್ಷಿಕ',
'ಹೇಮವಸನ',
'ಹೇವಗೆಡಿಸು',
'ಹೇವಗೊಳಿಸು',
'ಹೇವಹಱುಕ',
'ಹೇಷಾರಸಿತ',
'ಹೇಸರಕತ್ತೆ',
'ಹೇಸರಗತ್ತೆ',
'ಹೇಸರಗಳ್ತೆ',
'ಹೇಸಿಕೆಪಡು',
'ಹೈದರಾಬಾದಿ',
'ಹೈಯಂಗವೀನ',
'ಹೈರಾಣಗೊಳ್ಳು',
'ಹೊಂಗಣಜಿಲು',
'ಹೊಂಗಣಿಜಿಲು',
'ಹೊಂಗಣಿಜಿಲೆ',
'ಹೊಂತಗಾರಿಕೆ',
'ಹೊಂತುಗಾರಿಕೆ',
'ಹೊಂದಿಕೆಗೆಡು',
'ಹೊಂದುಗೂಡಿಕೆ',
'ಹೊಂದುಗೂಡಿಸು',
'ಹೊಂಪುಳಿಯೋಗು',
'ಹೊಂಪುಳಿವೋಗು',
'ಹೊಂಪುಳಿಹೋಗು',
'ಹೊಂಬಾಳೆಹುಲ್ಲು',
'ಹೊಕ್ಕಳುಬಳ್ಳಿ',
'ಹೊಕ್ಕುಬಳಕೆ',
'ಹೊಕ್ಕುಳಿಗೊಳ್',
'ಹೊಕ್ಕುಳುಕುಡಿ',
'ಹೊಕ್ಕುಳುಬಳ್ಳಿ',
'ಹೊಗರುಗೆಡು',
'ಹೊಗಳುಭಟ್ಟ',
'ಹೊಗೆಕೊಳವಿ',
'ಹೊಗೆಕೊಳವೆ',
'ಹೊಗೆಗೊಳಿಸು',
'ಹೊಗೆನಳಿಗೆ',
'ಹೊಟ್ಟೆಉರಿಸು',
'ಹೊಟ್ಟೆಕಡಿತ',
'ಹೊಟ್ಟೆಕಡುಬು',
'ಹೊಟ್ಟೆಕಳಿತ',
'ಹೊಟ್ಟೆಗೊಬ್ಬಳಿ',
'ಹೊಟ್ಟೆಜಾಡಿಸು',
'ಹೊಟ್ಟೆತೊಳಸು',
'ಹೊಟ್ಟೆನೆತ್ತರು',
'ಹೊಟ್ಟೆಯುಬ್ಬರ',
'ಹೊಟ್ಟೆಯುರಕ',
'ಹೊಟ್ಟೆಯುರಿಸು',
'ಹೊಟ್ಟೆಸಂಕಟ',
'ಹೊಟ್ಟೆಹೊರಕ',
'ಹೊಟ್ಟೆಹೊರೆಕ',
'ಹೊಡಕರಿಸು',
'ಹೊಡಕೆಹುಲ್ಲು',
'ಹೊಡಗೆಡಹು',
'ಹೊಡಮಗುಚು',
'ಹೊಡಮರಳು',
'ಹೊಡವಂಡಿಸು',
'ಹೊಡವಡಿಸು',
'ಹೊಡೆಗೆಡಹು',
'ಹೊಡೆಗೆಡಿಸು',
'ಹೊಡೆಗೊಳಿಸು',
'ಹೊಡೆಮಗುಚು',
'ಹೊಡೆಮರಳ್',
'ಹೊಡೆಮರಳು',
'ಹೊಡ್ಡೆಲೆನಗೆ',
'ಹೊಣಕೆಗಾರ',
'ಹೊಣಕೆವಿಡಿ',
'ಹೊಣೆಗಾರಿಕೆ',
'ಹೊತ್ತುತಿರುಗ',
'ಹೊದಕುಳಿಸು',
'ಹೊದಱುಗಟ್ಟು',
'ಹೊದಱುಗೊಳ್',
'ಹೊದಱುದೆಗೆ',
'ಹೊದಿಕೆಹುಲ್ಲು',
'ಹೊನಲುವರಿ',
'ಹೊನಹುಗಾರ್ತಿ',
'ಹೊನ್ನಾವರಿಕೆ',
'ಹೊನ್ನೆಹೂಹುಲ್ಲು',
'ಹೊಮ್ಮಂಚಗಾರ',
'ಹೊಮ್ಮವರಿಕೆ',
'ಹೊಯಿಗಡುಬು',
'ಹೊಯ್ಮಾಲಿತನ',
'ಹೊರಕೆಡವು',
'ಹೊರಕೆಲಸ',
'ಹೊರಗಣಿಸು',
'ಹೊರಗೆಡವು',
'ಹೊರಗೆಡಹು',
'ಹೊರಗೆಲಸ',
'ಹೊರಜೆಗಟ್ಟು',
'ಹೊರತೋರಿಕೆ',
'ಹೊರಪಡಿಸು',
'ಹೊರಮುಚುಕ',
'ಹೊರಮುಚ್ಚಗ',
'ಹೊರಹೊಮ್ಮಿಸು',
'ಹೊರಹೊರಡು',
'ಹೊರಳಾಡಿಸು',
'ಹೊರಳಿಗಟ್ಟು',
'ಹೊರಳಿದೆಗೆ',
'ಹೊರಳಿಮಂಡಿ',
'ಹೊರಳಿಯೊಡೆ',
'ಹೊರಿಗೆವಾಳ',
'ಹೊರೆಗೆಲಸ',
'ಹೊರೆಗೊಳಿಸು',
'ಹೊಲದಚೆಲ್ಲ',
'ಹೊಲದವರೆ',
'ಹೊಲಬುಕೆಡು',
'ಹೊಲಬುಗಾಱ',
'ಹೊಲಬುಗಿಡು',
'ಹೊಲಬುಗೆಡು',
'ಹೊಲಬುಗೇಡಿ',
'ಹೊಲಬುಗೊಳ್',
'ಹೊಲಬುತಪ್ಪು',
'ಹೊಲಬುದಪ್ಪು',
'ಹೊಲಸುಗಾತಿ',
'ಹೊಲಸುಗಾರ',
'ಹೊಲಸುನಾತ',
'ಹೊಲಸುನಾರು',
'ಹೊಲಸುನಾಱು',
'ಹೊಲಸುಮಾತು',
'ಹೊಲಿಗೆಯಂತ್ರ',
'ಹೊಲಿಗೆರಾಟೆ',
'ಹೊಲೆಗಲಸು',
'ಹೊಲೆಗೆಡಿಸು',
'ಹೊಲೆಗೆಲಸ',
'ಹೊಲೆಹಲಸು',
'ಹೊಲ್ಲಹಂದೋಱು',
'ಹೊಲ್ಲೆಹಂಗೆಯ್',
'ಹೊಲ್ಲೆಹಗೆಯ್',
'ಹೊಸಇಕ್ಕೇರಿ',
'ಹೊಸಗನ್ನಡ',
'ಹೊಸಜವ್ವನ',
'ಹೊಸಬತನ',
'ಹೊಸಹರೆಯ',
'ಹೊಳಕೆಗಟ್ಟು',
'ಹೊಳಕೆಗಾಱ',
'ಹೊಳಪುಗುಂದು',
'ಹೊಳಲುಕೊಡು',
'ಹೊಳಲುಗೊಡು',
'ಹೊಳಹುಗಳಿ',
'ಹೊಳಹುಗಾಣು',
'ಹೊಳಹುದೋಱು',
'ಹೊಳೆಗಾಲುವೆ',
'ಹೊಳೆತುಮರಿ',
'ಹೊಳೆನಾಣೆಲೆ',
'ಹೊಳ್ಳುವಾಱಿಸು',
'ಹೊಱಕಾಲ್ಗೊಳು',
'ಹೊಱಗೆಲಸ',
'ಹೊಱಡುಬೆನ್ನು',
'ಹೊಱಮಡಿಸು',
'ಹೊಱವಂಡಿಸು',
'ಹೊಱವಡಿಸು',
'ಹೊಱವಳಯ',
'ಹೊಱವೊಳಲು',
'ಹೊಱಹೊಮ್ಮಿಸು',
'ಹೊಱಹೊಱಡು',
'ಹೊಱಿಗೆಕಾಱ',
'ಹೊಱಿಗೆವಾಳ',
'ಹೋಗಣಗಿಲು',
'ಹೋತುದಱಿಹಿ',
'ಹೋಬಳಿದಾರ',
'ಹೋಮಿಯೋಪತಿ',
'ಹೋರಟೆಗುಟ್ಟು',
'ಹೋರಟೆಗೊಳ್',
'ಹೋರಟೆಗೊಳ್ಳು',
'ಹೋರಾಟಗೆಯ್',
'ಹೋರಾಟಗೊಳ್',
'ಹೋರುಗೆಡವು',
'ಹೋಳಿಗೆವಡೆ',
'ಹ್ಯಸ್ತನದಿನ',
'ಱಟ್ಟಡಿಕಾತಿ',
'ಱಟ್ಟಡಿಕಾರ್ತಿ',
'ಱಟ್ಟಡಿಕಾಱ',
'ಱಟ್ಟಡಿತನ',
'ಱಪಱಪನೆ',
'ಱೋಡಗತನ',
'ಱೋಡಗವಂತ'
]
